import deleteEntity from 'modules/entities/actions/deleteEntity';
import routes from 'modules/routing/routes';
import emailTemplateSchema from '../schema';

/**
 * Creates an action for deleting an email template
 * @param {Object} payload
 * @param {Number} payload.id The email template ID.
 */
export default ({ id }) =>
  deleteEntity({
    id: id,
    entityType: emailTemplateSchema.key,
    url: routes.api_v1_email_template({ id: id }),
  });

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import Form from '@thrivetrm/ui/components/Form';
import Card from '@thrivetrm/ui/components/Card';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import ConnectionForm from 'modules/connections/components/ConnectionForm';
import QuickViewPanelHeader from 'modules/quick-view/QuickViewPanelHeader';
import { markAsStale } from 'modules/recordIndex/recordIndexSlice';
import { fetchContact } from 'modules/contacts/actions/contacts/index';
import { fetchReferenceList } from 'modules/references/actions';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { useGetCandidacyReferencesQuery } from 'services/apiV1/references';
import selectedContact from 'selectors/contact/selectedContact';
import {
  useGetConnectionQuery,
  useUpdateConnectionMutation,
} from 'services/apiV1/connections';
import { useGetContactQuery } from 'services/apiV1/contact';
import { fetchNotifications } from 'actions/contacts';
import useQuickView from '../useQuickView';
import { QuickViewErrorState } from '../QuickViewState';
import ContactQvpHeader from '../ContactQvpHeader';

const ConnectionEditForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { navigateTo, pathParams } = useQuickView();
  const { connectionId, contactId } = pathParams;
  const queryParams = new URLSearchParams(location.search);
  const previousPath = queryParams.get('from');

  const { pathname } = window.location;
  const candidacyId = pathname.includes('searches')
    ? pathname.split('/')[4]
    : null;

  const legacyContactData = useSelector(state => selectedContact(state));
  const referenceNotification =
    legacyContactData
      ?.getIn(['notifications', 'data'])
      ?.find(
        notification => notification.getIn(['data', 'type']) === 'reference',
      ) ?? null;

  const {
    data: contactData,
    error: contactLoadError,
    isFetching: isLoadingContact,
  } = useGetContactQuery(contactId);

  const {
    data: connectionInfo,
    error: connectionLoadError,
    isFetching: isLoadingConnection,
  } = useGetConnectionQuery({
    contactId: contactId,
    connectionId: connectionId,
  });
  const hasReferenceConnectionV2 = useFeatureCheck(
    'development.references_connection_v2',
  );

  const {
    refetch: loadCandidacyReferences,
  } = useGetCandidacyReferencesQuery(candidacyId, { skip: true });

  const connectedOn = new Date(connectionInfo?.connectedOn);

  const initialValues = {
    connectionId: connectionInfo?.id,
    connectedRecord: {
      id: connectionInfo?.connectedToContact
        ? connectionInfo.connectedToContact.id
        : connectionInfo?.connectedToUser?.id,
      name: connectionInfo?.connectedToContact
        ? connectionInfo.connectedToContact.fullName
        : connectionInfo?.connectedToUser?.name,
    },
    connectionType: connectionInfo?.connectionType?.id,
    month: connectedOn.getMonth(),
    year: connectedOn.getFullYear(),
    referralCompany: connectionInfo?.referralCompany ?? null,
    strength: connectionInfo?.connectionStrength?.id,
    notes: connectionInfo?.notes,
  };

  const [
    updateConnection,
    { error: updateConnectionError },
  ] = useUpdateConnectionMutation();

  const handleSubmit = formState => {
    const payload = {
      connected_contact_id: connectionInfo.connectedToContact?.id,
      connected_on: moment.utc({
        year: formState.year,
        month: formState.month,
      }),
      connected_user_id: connectionInfo.connectedToUser?.id,
      connection_strength_id: formState.strength,
      connection_type_id: formState.connectionType,
      notes: formState.notes,
      contact_id: contactId,
      referral_company_id: formState.referralCompany?.id || null,
    };

    updateConnection({
      contactId: contactId,
      connectionId: connectionInfo.id,
      payload: payload,
    })
      .unwrap()
      .then(() => {
        navigateTo(previousPath || `/contact/${contactId}/connections`);
        dispatch(toastSuccess('Successfully updated connection'));
        dispatch(markAsStale());
        dispatch(fetchContact({ id: contactId }));
        // updates the list of references on the references side panel
        if (connectionInfo.reference && candidacyId) {
          if (hasReferenceConnectionV2) {
            loadCandidacyReferences();
          } else {
            dispatch(
              fetchReferenceList({
                parentId: candidacyId,
                parentType: 'candidacy',
              }),
            );
          }
        }
        // updates contact activity timeline if the reference type of notification exists
        if (referenceNotification?.size) {
          dispatch(
            fetchNotifications({
              limit: legacyContactData.getIn(['notifications', 'data']).size,
              contactId: contactId,
              filters: legacyContactData
                .getIn(['notifications', 'meta', 'filters'])
                .toJS(),
            }),
          );
        }
      });
  };

  const handleBack = () => {
    navigateTo(previousPath || `/contact/${contactId}/connections`);
  };

  return (
    <>
      <ContactQvpHeader contactId={contactId} onBack={handleBack} />
      <QuickViewPanelHeader title='Edit Connection' />
      <Form onSubmit={handleSubmit}>
        <SidePanel.Body className='u-paddingTop-n'>
          <LoadingContainer
            className='u-paddingTop-12'
            isLoading={isLoadingContact || isLoadingConnection}
            minHeight='100%'
          >
            {isLoadingContact || !contactData || !connectionInfo ? null : (
              <ConnectionForm
                connectionRecordType={
                  connectionInfo?.connectedToContact ? 'contact' : 'user'
                }
                contactData={contactData}
                initialValues={initialValues}
              />
            )}
            {connectionLoadError || contactLoadError ? (
              <QuickViewErrorState />
            ) : null}
            {updateConnectionError ? (
              <Card type='error'>{updateConnectionError?.data?.errors}</Card>
            ) : null}
          </LoadingContainer>
        </SidePanel.Body>

        <SidePanel.Footer>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={handleBack} />
          <Form.SubmitButton
            isLoading={isLoadingContact || isLoadingConnection}
            label='Save'
          />
        </SidePanel.Footer>
      </Form>
    </>
  );
};

export default ConnectionEditForm;

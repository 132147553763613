/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withPropsOnChange,
} from 'recompose';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

/**
 * A component for making popover-wrapped components simpler.
 *
 * Instead of having to use the OverlayTrigger and Popover manually (ensuring that we have a
 * unique ID for the popover), this lets you simply wrap an element in a PopoverTrigger:
 *
 * <PopoverTrigger title='This is my title' content='Body text or nodes go here!'>
 *   <MyElement />
 * </PopoverTrigger>
 *
 * All other props except for `title`, `content`, and `popoverProps` will be passed through to the
 * `Overlaytrigger` component.
 */
export default compose(
  setDisplayName('PopoverTrigger'),
  setPropTypes({
    /**
     * The popover title
     */
    title: PropTypes.string,

    /**
     * The body content of the popover
     */
    content: PropTypes.node,

    /**
     * Optional additional props that will be passed to the popover component.
     */
    popoverProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }),
  defaultProps({
    animation: false,
    placement: 'left',
  }),
  withPropsOnChange(
    () => false,
    () => ({
      popoverId: uniqueId('PopoverTrigger'),
    }),
  ),
  withPropsOnChange(
    ['content', 'title', 'popoverId', 'popoverProps'],
    ({ content, popoverId, popoverProps, title }) => ({
      overlay: (
        <Popover title={title} {...popoverProps} id={popoverId}>
          {content}
        </Popover>
      ),
    }),
  ),
)(OverlayTrigger);

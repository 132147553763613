import React, { useEffect } from 'react';
import { useApiGet } from 'modules/core/hooks/useApi';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import routes from 'modules/routing/routes';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';

const AppointmentMethodSelectField = props => {
  const [
    loadAppointmentMethods,
    isLoadingAppointmentMethods,
    _appointmentMethodsLoadError,
    appointmentMethods,
  ] = useApiGet(routes.api_v1_appointment_methods());

  useEffect(() => {
    loadAppointmentMethods();
  }, [loadAppointmentMethods]);

  const appointmentMethodOptions =
    appointmentMethods &&
    appointmentMethods.appointment_methods.map(appointmentMethod => ({
      label: appointmentMethod.name,
      value: appointmentMethod.id,
    }));

  return (
    <LoadingContainer isLoading={isLoadingAppointmentMethods}>
      <ReactSelectField
        {...props}
        className='js-AppointmentMethodSelectField'
        options={appointmentMethodOptions}
        simpleValue={true}
      />
    </LoadingContainer>
  );
};

AppointmentMethodSelectField.defaultProps = {
  label: 'Method',
  placeholder: 'Method',
};

AppointmentMethodSelectField.createFieldState =
  ReactSelectField.createFieldState;

export default AppointmentMethodSelectField;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import FilterableCheckboxList from '@thrivetrm/ui/components/FilterableCheckboxList';
import Modal from '@thrivetrm/ui/components/Modal';
import ReorderableList from 'modules/core/components/ReorderableList';
import ScrollableContainer from '@thrivetrm/ui/components/ScrollableContainer';
import {
  addColumn,
  loadAvailableColumns,
  removeAllColumns,
  removeColumn,
  reorderColumns,
  resetColumnsToDefault,
  restoreColumnsToOriginal,
  saveColumns,
} from 'modules/configure-columns/configureColumnsSlice';

const ConfigureColumnsModal = ({ isOpen, onClose, onSave, recordType }) => {
  const dispatch = useDispatch();

  const {
    availableColumnKeys,
    columnDefinitions,
    isLoadingAvailableColumns,
    isSavingSelectedColumns,
    loadAvailableColumnsError,
    pinnedColumnKeys,
    saveColumnsError,
    selectedColumnKeys,
  } = useSelector(state => state.configureColumns);

  useEffect(() => {
    if (!availableColumnKeys.length) {
      dispatch(loadAvailableColumns(recordType));
    }
  }, [availableColumnKeys, dispatch, recordType]);

  // Used to manage the semi-controlled state of <ReorderableList> when its items change due to
  // external checkbox selections. A detailed explanation exists in <ReorderableList>.
  const [reorderableListKey, setReorderableListKey] = useState(Date.now);

  useEffect(() => {
    setReorderableListKey(Date.now);
  }, [availableColumnKeys, pinnedColumnKeys, selectedColumnKeys]);

  const handleColumnSelectionChange = (columnKey, isSelected) => {
    if (isSelected) {
      dispatch(addColumn({ columnKey: columnKey }));
    } else {
      dispatch(removeColumn({ columnKey: columnKey }));
    }
  };

  const handleColumnOrderChange = columns => {
    const columnKeys = columns.map(column => column.value);
    dispatch(reorderColumns({ columnKeys: columnKeys }));
  };

  const handleCancel = () => {
    dispatch(restoreColumnsToOriginal());
    onClose();
  };

  const availableColumns = availableColumnKeys.map(columnKey => ({
    ...columnDefinitions[columnKey],
    isSelected: selectedColumnKeys.includes(columnKey),
  }));

  const unfilteredSelectedColumns = selectedColumnKeys.map(
    key => columnDefinitions[key],
  );
  const pinnedColumns = pinnedColumnKeys.map(key => columnDefinitions[key]);
  const availableColumnLabels = availableColumns
    .map(a => a?.label)
    .filter(Boolean);
  const selectedColumns = unfilteredSelectedColumns.filter(col =>
    availableColumnLabels.includes(col?.label),
  );

  return isOpen ? (
    <Modal
      className='ConfigureColumnsModal'
      data-testid='configure-columns-modal'
      isOpen={isOpen}
      onClose={handleCancel}
      size='large'
      title='Configure Columns'
    >
      <Modal.Body className='ConfigureColumnsModal__body'>
        {loadAvailableColumnsError ? (
          <Card className='u-margin-24' type='error'>
            {loadAvailableColumnsError}
          </Card>
        ) : (
          <>
            {saveColumnsError && (
              <Card className='u-marginTop-8' type='error'>
                {saveColumnsError}
              </Card>
            )}
            <LoadingContainer isLoading={isLoadingAvailableColumns}>
              {isLoadingAvailableColumns ? null : (
                <div className='ConfigureColumnsModal__columns'>
                  <ScrollableContainer className='ConfigureColumnsModal__availableColumns'>
                    <h3>Available Columns:</h3>
                    <FilterableCheckboxList
                      filterPlaceholderText='Filter by column name...'
                      items={availableColumns}
                      onItemChange={handleColumnSelectionChange}
                    />
                  </ScrollableContainer>

                  <ScrollableContainer className='ConfigureColumnsModal__selectedColumns'>
                    <div className='ConfigureColumnsModal__headerContainer'>
                      <h3>
                        Selected Columns: (
                        {pinnedColumns.length + selectedColumns.length})
                      </h3>
                      <div>
                        <button
                          className='ConfigureColumnsModal__button ConfigureColumnsModal__deselectBtn'
                          disabled={isLoadingAvailableColumns}
                          onClick={() => dispatch(removeAllColumns())}
                          type='button'
                        >
                          Deselect All
                        </button>
                        <button
                          className='ConfigureColumnsModal__button'
                          disabled={isLoadingAvailableColumns}
                          onClick={() => dispatch(resetColumnsToDefault())}
                          type='button'
                        >
                          Reset to Default
                        </button>
                      </div>
                    </div>
                    <ReorderableList
                      key={reorderableListKey}
                      onChange={handleColumnOrderChange}
                      pinnedItems={pinnedColumns}
                      reorderableItems={selectedColumns}
                    />
                  </ScrollableContainer>
                </div>
              )}
            </LoadingContainer>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary isOutline={true} label='Cancel' onClick={handleCancel} />
        <ButtonPrimary
          isDisabled={isLoadingAvailableColumns}
          isLoading={isSavingSelectedColumns}
          label='Save'
          onClick={() => dispatch(saveColumns(recordType, onSave))}
        />
      </Modal.Footer>
    </Modal>
  ) : null;
};

ConfigureColumnsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  recordType: PropTypes.string.isRequired,
};

export default ConfigureColumnsModal;

import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@thrivetrm/ui/components/Modal';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import { useGetNetworksQuery } from 'services/apiV1/networks';
import {
  useAddContactToNetworkMutation,
  useGetContactNetworksQuery,
} from 'services/apiV1/contactNetworks';
import Form from '@thrivetrm/ui/components/Form';
import { useDispatch } from 'react-redux';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import './AddContactToNetworkButton.scss';

const AddContactToNetworkButton = ({ contactId }) => {
  const dispatch = useDispatch();
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const { data: networks } = useGetNetworksQuery('contact');

  const [addContactToNetwork] = useAddContactToNetworkMutation();
  const { data: contactsNetworks } = useGetContactNetworksQuery(contactId);

  const contactNetworkIds = contactsNetworks?.map(({ id }) => id);
  const networkOptions = [
    ...(networks?.user_networks || []),
    ...(networks?.public_networks || []),
    ...(networks?.private_network || []),
  ]
    // exclude the current networks that the contact belongs to from the dropdown
    .filter(({ id }) => !contactNetworkIds?.includes(id))
    .map(network => ({
      label: network.name,
      value: network.id,
    }));

  const handleSubmit = formData => {
    addContactToNetwork({
      networkId: formData.network,
      contactId: contactId,
    })
      .unwrap()
      .then(() => {
        closeModal();
        dispatch(toastSuccess('Contact added to a network.'));
      })
      .catch(() => {
        dispatch(toastError('Could not add contact to a network.'));
      });
  };

  return (
    <>
      <ButtonPrimary
        icon='add'
        isOutline={true}
        label='Add to Network'
        onClick={openModal}
        size='small'
      />
      <Modal
        className='AddContactToNetworkButton__modal'
        isOpen={isModalOpen}
        onClose={closeModal}
        size='small'
        title='Add Contact to a Network'
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Body className='ModalBody--noClip'>
            <Form.AdvancedSelect
              label='Select a Network'
              name='network'
              options={networkOptions}
              placeholder='Select a network...'
              rules={{
                required: {
                  value: true,
                },
              }}
              width='full'
            />
          </Modal.Body>
          <Modal.Footer>
            <ButtonPrimary
              isOutline={true}
              label='Cancel'
              onClick={closeModal}
            />
            <Form.SubmitButton label='Add' />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

AddContactToNetworkButton.propTypes = {
  contactId: PropTypes.number.isRequired,
};

export default AddContactToNetworkButton;

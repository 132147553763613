import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import { PARENT_CONTACT } from 'modules/comments/constants';
import CommentsPanel from 'modules/comments/components/CommentsPanel';

/**
 * Renders a panel of comments (with adding and editing capabilities) related to a particular
 * contact.
 */
const ContactCommentsPanel = compose(
  setDisplayName('ContactCommentsPanel'),
  setPropTypes({
    /**
     * The ID of the contact whose comments are shown
     */
    contactId: PropTypes.number,
  }),
  withProps(({ contactId }) => ({
    parentType: PARENT_CONTACT,
    parentId: contactId,
  })),
)(CommentsPanel);

export default ContactCommentsPanel;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import UserName from 'modules/users/components/UserName';

import getEmailTemplateCreatorId from '../selectors/getEmailTemplateCreatorId';

/**
 * Renders the name of the user who created an email template.
 */
export default compose(
  setDisplayName('EmailTemplateCreatedBy(enhanced)'),
  setPropTypes({
    /**
     * The template ID of the template to show the user name of the
     * created by user for.
     */
    emailTemplateId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { emailTemplateId }) => ({
      userId: getEmailTemplateCreatorId(state, emailTemplateId),
    }),
    {},
  ),

  // emailTemplateId is not used by UserName.
  mapProps(({ emailTemplateId: _, ...props }) => ({
    ...props,
  })),
)(UserName);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import mapCandidacyIdToCandidacy from 'modules/candidacies/components/mapCandidacyIdToCandidacy';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import { PARENT_TYPES, PARENT_CONTACT } from '../constants';

import withNoteListFetched from './withNoteListFetched';
import mapNoteIdsToNotes from './mapNoteIdsToNotes';
import NoteForm from './NoteForm';
import NoteList from './NoteList';

import withFormToggleButton from '../../../components/forms/withFormToggleButton';

const AddNoteButton = withFormToggleButton(NoteForm);

const SEARCH_RELATED = 'Search Related';
const OTHER = 'Other Notes';

// It's a map, not an array!
/* eslint-disable react/no-array-index-key */

/**
 * Renders a panel for displaying a candidacies notes.
 * These are grouped by whether the note is also associated with the current search (vs just
 * associated with the contact itself).
 */
const CandidacyNotesPanel = ({
  candidacyId,
  handleRefreshNotes,
  noteList,
  notes,
  parentId,
  parentType,
  searchId,
}) => {
  const isFetching = noteList && noteList.getIn(['_meta', 'isFetching']);
  const error = noteList && noteList.getIn(['_meta', 'error']);
  const groups =
    notes &&
    notes
      .groupBy(note =>
        note.get('searches').includes(searchId) ? SEARCH_RELATED : OTHER,
      )
      .sortBy((_, group) => (group === SEARCH_RELATED ? 0 : 1));
  const pinnedNote =
    notes &&
    notes
      .filter(
        note => note.get('pinned') && note.get('searches').includes(searchId),
      )
      .first();
  const pinnedNoteId = pinnedNote && pinnedNote.get('id');
  return (
    <div className='notes--candidacy-notes-panel'>
      {error && !isFetching && (
        <ErrorAlert
          onRetry={handleRefreshNotes}
          title='There was an error fetching notes'
        />
      )}
      <AddNoteButton
        draftStoragePath={{
          search: searchId,
          candidacy: candidacyId,
          note: 'new',
        }}
        parentId={parentId}
        parentType={parentType}
        pinnedNoteId={pinnedNoteId}
        searchId={searchId}
      >
        Add Note
      </AddNoteButton>
      {groups &&
        groups
          .map((groupNotes, group) => (
            <div className='notes-candidacy-notes-panel-group' key={group}>
              <h3>{group}</h3>
              <NoteList
                candidacyId={candidacyId}
                notes={groupNotes}
                pinnedNoteId={pinnedNoteId}
                renderRelatedSearches={group === OTHER}
                searchId={searchId}
              />
            </div>
          ))
          .toArray()}
      {isFetching && <LoadingIndicator />}
    </div>
  );
};

CandidacyNotesPanel.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  handleRefreshNotes: PropTypes.func.isRequired,

  /**
   * The assessments state for the current candidacy, used to display a
   * loading indicator if we are currently fetching.
   */
  noteList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  notes: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number,
    }),
  ),

  parentId: PropTypes.number.isRequired,

  parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,

  searchId: PropTypes.number.isRequired,
};

export default compose(
  setDisplayName('CandidacyNotesPanel(enhanced)'),

  // The connected version of this component only requires a candidacyId,
  // everything else will be fetched from state.
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),

  // Get the contactId for the candidacy supplied, because we're actually loading all of the
  // contact's notes.
  mapCandidacyIdToCandidacy,

  // Add a prop so we can manually trigger refreshes if desired.
  withProps(({ candidacy }) => {
    const parentId = candidacy && candidacy.get('contact');
    const parentType = PARENT_CONTACT;

    return {
      parentId: parentId,
      parentType: parentType,
      searchId: candidacy && candidacy.get('search_id'),
    };
  }),

  // If the note for the contact have not been loaded or are stale,
  // fetch them.
  withNoteListFetched,

  withProps(({ fetchNoteList, noteList }) => ({
    noteIds: noteList && noteList.get('ids'),
    handleRefreshNotes: fetchNoteList,
  })),

  mapNoteIdsToNotes,
)(CandidacyNotesPanel);

import React from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import { connect } from 'react-redux';
import selectn from 'selectn';
import Overlay from 'react-bootstrap/lib/Overlay';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import classnames from 'classnames';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withOverlayTrigger from 'modules/core/componentsLegacy/withOverlayTrigger';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import withTransaction from 'modules/transactions/components/withTransaction';
import CandidacyRankingMenuList from './CandidacyRankingMenuList';
import updateCandidacyRankAction from '../../actions/ranking/updateCandidacyRank';

const CandidacyRankingButton = ({
  allowRanking,
  candidacyId,
  componentId,
  getScrollContainer,
  getTargetRef,
  handleHideForm,
  handleMoveCandidacy,
  isSubmitting,
  overlayTriggerProps,
  setTargetRef,
  showOverlay,
  submitError,
}) => {
  const overlayComponent = allowRanking && (
    <Popover
      className='CandidacyRankingPopover'
      id={componentId}
      title='Rank Candidate'
    >
      <CandidacyRankingMenuList
        candidacyId={candidacyId}
        handleMoveCandidacy={handleMoveCandidacy}
      />
      {submitError && (
        <ErrorAlert error={submitError} title='Unable to move candidate:' />
      )}
      {isSubmitting && <LoadingIndicator />}
    </Popover>
  );

  return (
    <div
      className={classnames('CandidacyRankButton', {
        'CandidacyRankButton--disabled': !allowRanking,
      })}
    >
      <OverlayTrigger
        overlay={
          <Tooltip id={componentId}>
            {allowRanking
              ? 'Rank Candidate'
              : 'To rank candidates, sort by Rank'}
          </Tooltip>
        }
        placement='top'
      >
        <div className='CandidacyRankButton__buttonWrapper'>
          <button
            className='btn btn-default btn-block'
            disabled={!allowRanking}
            ref={setTargetRef}
            type='button'
            {...overlayTriggerProps}
          >
            <i className='fa fa-sort' />
          </button>
        </div>
      </OverlayTrigger>
      <Overlay
        container={getScrollContainer}
        onHide={handleHideForm}
        placement='left'
        rootClose={true}
        show={showOverlay}
        target={getTargetRef}
      >
        {overlayComponent}
      </Overlay>
    </div>
  );
};

CandidacyRankingButton.propTypes = {
  /**
   * determines whether or not to render a disabled button
   */
  allowRanking: PropTypes.bool.isRequired,

  /**
   * id of the candidacy we are ranking
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * unique ID for the tooltip or popover
   */
  componentId: PropTypes.string.isRequired,

  /**
   * ID of first candidacy in list or group
   */
  firstCandidacyId: PropTypes.number,

  /**
   * Called to get the scroll container this component is rendered in
   * (needed for displaying tooltips)
   */
  getScrollContainer: PropTypes.func.isRequired,

  /**
   * Called to get the DOM element reference to the component that the tooltip should be attached to
   */
  getTargetRef: PropTypes.func.isRequired,

  /**
   * Function for closing rank popover
   */
  handleHideForm: PropTypes.func,

  /**
   * Function that gets called when one of the rank actions is clicked
   */
  handleMoveCandidacy: PropTypes.func.isRequired,

  /**
   * The height of the item in pixels when rendering.
   */
  height: PropTypes.number,

  /**
   * True when currently submitting the rank change.
   */
  isSubmitting: PropTypes.bool.isRequired,

  /**
   * ID of last candidacy in list or group
   */
  lastCandidacyId: PropTypes.number,

  /**
   * ID of next candidacy in list or group
   */
  nextCandidacyId: PropTypes.number,

  /**
   * The props to pass to the element that will act as the overlay trigger
   * when in readOnly mode.
   */
  overlayTriggerProps: PropTypes.objectOf(PropTypes.func.isRequired),
  /**
   * ID of previous candidacy in list or group
   */
  previousCandidacyId: PropTypes.number,

  /**
   * A function that sets the DOM element reference to the component that the tooltip should
   * be attached to
   */
  setTargetRef: PropTypes.func.isRequired,

  /**
   * Overlay is visible when true
   */
  showOverlay: PropTypes.bool.isRequired,

  /**
   * If a submit error occurs, it will be here.
   * @type {Error}
   */
  submitError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default compose(
  defaultProps({
    allowRanking: true,
  }),
  setDisplayName('CandidacyRankingButton(enhanced)'),
  setPropTypes({
    /**
     * determines whether or not to render a disabled button
     */
    allowRanking: PropTypes.bool.isRequired,

    /**
     * unique ID for the tooltip
     */
    componentId: PropTypes.string,
  }),
  connect(null, {
    updateCandidacyRank: updateCandidacyRankAction,
  }),
  connectTransactions,
  withComponentId(),
  withState('isSubmitting', 'setIsSubmitting', false),
  withState('submitError', 'setSubmitError'),
  branch(
    ({ allowRanking }) => allowRanking,
    withOverlayTrigger({ triggers: ['click'] }),
    withOverlayTrigger(),
  ),
  withScrollContainerRef,
  withHandlers({
    onTransactionComplete: ({
      setIsSubmitting,
      setShowOverlay,
      setSubmitError,
    }) => transaction => {
      setIsSubmitting(false);
      const error = selectn('payload.error', transaction);
      if (error) {
        setSubmitError(error);
      } else {
        setShowOverlay(false);
      }
    },
  }),
  withTransaction,
  withHandlers({
    handleMoveCandidacy: ({
      candidacyId,
      firstCandidacyId,
      lastCandidacyId,
      nextCandidacyId,
      previousCandidacyId,
      setIsSubmitting,
      setShowOverlay,
      startTransaction,
      updateCandidacyRank,
    }) => action => {
      const transactionId = startTransaction();
      const actionCandidacies = {
        up: previousCandidacyId,
        down: nextCandidacyId,
        top: firstCandidacyId,
        bottom: lastCandidacyId,
      };

      const otherCandidacyId = actionCandidacies[action];

      if (otherCandidacyId && candidacyId !== otherCandidacyId) {
        updateCandidacyRank({
          transactionId: transactionId,
          id: candidacyId,
          otherCandidacyId: actionCandidacies[action],
        });
        setIsSubmitting(true);
      } else {
        setShowOverlay(false);
      }
    },
    handleHideForm: ({ setShowOverlay }) => () => setShowOverlay(false),
  }),
)(CandidacyRankingButton);

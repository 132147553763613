import React from 'react';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import PluralText from '@thrivetrm/ui/components/PluralText';
import {
  useGetOffLimitsHistoricalReasonsQuery,
  useGetOffLimitsReasonsQuery,
} from 'services/apiV1/offLimits';
import OffLimitsCard from './OffLimitsCard';
import useQuickView from '../useQuickView';
import QuickViewPanelHeader from '../QuickViewPanelHeader';
import { QuickViewEmptyState, QuickViewErrorState } from '../QuickViewState';
import ContactQvpHeader from '../ContactQvpHeader';

const OffLimitsList = () => {
  const { close, navigateTo, pathParams, title } = useQuickView();
  const { recordId, recordType } = pathParams;

  const isCompany = recordType === 'company';

  const {
    data: historicalReasons,
    error: loadHistoricalReasonsError,
    isLoading: isLoadingHistoricalReasons,
  } = useGetOffLimitsHistoricalReasonsQuery({
    recordId: recordId,
    recordType: recordType,
  });

  const {
    data: reasons,
    error: loadReasonsError,
    isLoading: isLoadingReasons,
  } = useGetOffLimitsReasonsQuery({
    recordId: recordId,
    recordType: recordType,
  });

  const getReasonsContent = () => {
    if (reasons?.length === 0 && !isLoadingReasons) {
      return (
        <QuickViewEmptyState
          heading={`${title} doesn’t have any custom reason yet.`}
          image='rocketShip'
          subHeading={
            <>
              To apply a Custom Reason for {title} click on the{' '}
              <b className='u-noWrap'>+ Apply Custom Reason</b> button above
            </>
          }
        />
      );
    } else {
      return reasons?.map(reason => (
        <OffLimitsCard
          key={reason.id}
          reason={reason}
          recordId={recordId}
          recordName={title}
          recordType={recordType}
        />
      ));
    }
  };

  return (
    <>
      {isCompany ? (
        <SidePanel.Header
          className='u-marginBottom-12'
          onClose={close}
          title={title}
        />
      ) : (
        <ContactQvpHeader
          contactId={recordId}
          onBack={() => navigateTo(`/contacts/${recordId}`)}
        />
      )}
      <QuickViewPanelHeader
        button={
          <ButtonSecondary
            className='OffLimitsList__addCustomReasonButton'
            icon='add'
            label='Apply Custom'
            onClick={() =>
              navigateTo(`/${recordType}/${recordId}/off-limits/apply-reason`)
            }
            size='small'
          />
        }
        title='Off Limits Reasons'
      />
      <SidePanel.Body className='u-paddingTop-n'>
        {reasons?.length ? (
          <PluralText
            className='OffLimitsList__activeReasonCount'
            quantity={reasons.length}
            shouldIncludeQuantity={true}
            text='Active Reason'
          />
        ) : null}
        <LoadingContainer
          className='u-paddingTop-32'
          isLoading={isLoadingReasons && isLoadingHistoricalReasons}
          minHeight={300}
        >
          {loadReasonsError || loadHistoricalReasonsError ? (
            <QuickViewErrorState />
          ) : (
            <>
              {getReasonsContent()}
              <div className='u-marginTop-24 u-paddingTop-24 u-borderTop'>
                {historicalReasons?.length ? (
                  <ButtonSecondary
                    className='OffLimitsList__viewHistoricalButton'
                    icon='schedule'
                    isFullWidth={true}
                    label='View Historical Reasons'
                    onClick={() =>
                      navigateTo(
                        `/${recordType}/${recordId}/off-limits/historical`,
                      )
                    }
                  />
                ) : (
                  <div className='u-flex u-flexJustify-c u-marginTop-12'>
                    No historical reasons
                  </div>
                )}
              </div>
            </>
          )}
        </LoadingContainer>
      </SidePanel.Body>
    </>
  );
};

export default OffLimitsList;

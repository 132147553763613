import routes from 'modules/routing/routes';
import { PARENT_SEARCH, PARENT_CONTACT } from '../constants';

export default ({ parentId, parentType }) => {
  switch (parentType) {
    case PARENT_CONTACT: {
      return routes.api_v1_contact_notes({ contactId: parentId });
    }
    case PARENT_SEARCH: {
      return routes.api_v1_search_notes({ searchId: parentId });
    }
    default: {
      throw new Error(`Unknown parentType for notes: ${parentType}`);
    }
  }
};

import routes from 'modules/routing/routes';
import createQueryActions from '../../../../actions/createQueryActions';
import {
  QUERY_CREATE,
  QUERY_DESTROY,
  QUERY_INVALIDATE,
  QUERY_CLEAR,
  QUERY_FETCH_START,
  QUERY_FETCH_SUCCESS,
  QUERY_FETCH_FAILURE,
} from './actionTypes';
import { contactSchema } from '../../schema';

// These actions use the newer /api/v1 endpoint and should be preferred over the
// `./query.js` actions which use the older /api/contacts/search endpoint. At some point
// those should be removed entirely in favor of this, but they are still being used by various
// components.
const {
  create: createContactsQuery,
  destroy: destroyContactsQuery,
  invalidate: invalidateContactsQuery,
  query: fetchContactsQuery,
} = createQueryActions({
  getUrl: ({ keyword, query, queryId: _queryId, ...rest }) =>
    routes.api_v1_contacts({
      // The query string object.
      query: {
        term: rest?.term,
        // The search keyword, passed via the URL as `?keyword=whatever`
        keyword: keyword,
        // Additional query params that this action was called with (if any).
        ...query,
      },
      ...rest,
    }),
  schema: { contacts: [contactSchema] },
  create: QUERY_CREATE,
  destroy: QUERY_DESTROY,
  invalidate: QUERY_INVALIDATE,
  start: QUERY_FETCH_START,
  success: QUERY_FETCH_SUCCESS,
  failure: QUERY_FETCH_FAILURE,
});

const clearContactsQuery = ({ queryId }) => ({
  type: QUERY_CLEAR,
  payload: { queryId: queryId },
});

export {
  createContactsQuery,
  destroyContactsQuery,
  fetchContactsQuery,
  invalidateContactsQuery,
  clearContactsQuery,
};

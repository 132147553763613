import { compose, setDisplayName, withProps } from 'recompose';

/**
 * A Higher order component that reverse the sort of a list of jobApplicationIds.
 */
export default compose(
  setDisplayName('withJobApplicationIdsSortedDescending'),
  withProps(({ jobApplicationIds }) => ({
    jobApplicationIds: jobApplicationIds && jobApplicationIds.reverse(),
  })),
);

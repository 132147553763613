import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  componentFromProp,
  compose,
  onlyUpdateForKeys,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import fetchUserOptionsIfNeeded from 'modules/user/components/fetchUserOptionsIfNeeded';
import getTimezone from 'modules/user/selectors/getTimezone';

import CandidacyStatusEmpty from './CandidacyStatusEmpty';
import CandidacyStatusInterview from './CandidacyStatusInterview';
import CandidacyStatusDefault from './CandidacyStatusDefault';
import withCandidacyStatusFetched from './withCandidacyStatusFetched';

/**
 * Renders the status information for a candidacy record.
 * Ultimately this just defers the rendering to the specific status
 * component that applies, given the status data.
 */
export default compose(
  setDisplayName('CandidacyStatus'),

  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),

  fetchUserOptionsIfNeeded,
  withCandidacyStatusFetched(),

  connect(state => ({
    timezone: getTimezone(state),
  })),

  onlyUpdateForKeys(['candidacyStatus', 'timezone']),

  // Determine which specific status component to use.
  withProps(({ candidacyStatus }) => {
    if (!candidacyStatus || !candidacyStatus.get('created_at')) {
      return { component: CandidacyStatusEmpty };
    }

    if (candidacyStatus && candidacyStatus.get('type') === 'interview') {
      return { component: CandidacyStatusInterview };
    }

    return { component: CandidacyStatusDefault };
  }),
)(componentFromProp('component'));

import RichTextField from '../../forms/richtext/RichTextField';
import createContactForm from '../createContactForm';

const createRootFieldState = props =>
  RichTextField.createFieldState(
    'special_needs',
    props.contact ? props.contact.getIn(['data', 'special_needs']) : '',
    null,
    value => ({ special_needs: value }),
  );

const SpecialNeedsForm = createContactForm(
  RichTextField,
  createRootFieldState,
  'special-needs-form',
);

export default SpecialNeedsForm;

import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  setStatic,
  withHandlers,
} from 'recompose';
import { fromJS } from 'immutable';

import ContactSelectField from 'modules/contacts/components/ContactSelectField';
import getContactProperty from 'modules/contacts/selectors/contacts/getContactProperty';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import * as validators from 'modules/forms/validators';
import SearchSelectField from 'modules/searches/components/SearchSelectField';
import SelectMenuField from 'modules/contacts/components/contactConnection/SelectMenuField';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import {
  useGetConnectionTypesQuery,
  CONNECTION_TYPE_GROUPS,
  getConnectionTypeLabel,
} from 'services/apiV1/connections';
import RichTextField from '../../../components/forms/richtext/RichTextField';
import getReference from '../selectors/getReference';

/**
 * Identifies the wrapper classes to apply to each field for different "layouts", so that we
 * can arrange fields differently as needed.
 */
const FIELD_LAYOUTS = {
  wide: {
    name: 'col-md-6 col-12',
    referee: 'col-md-6 col-12',
    search: 'col-md-6 col-12',
    company: 'col-md-6 col-12',
    relationship: 'col-md-6 col-12',
    email: 'col-md-6 col-12',
    phone: 'col-md-6 col-12',
    notes: 'col-12',
    description: 'col-md-12',
  },
  narrow: {
    name: 'col-12',
    referee: 'col-12',
    search: 'col-12',
    company: 'col-12',
    relationship: 'col-12',
    email: 'col-12',
    phone: 'col-12',
    description: 'col-12',
  },
};

/**
 * Displays a form for editing or creating a reference
 */
const ReferenceField = ({
  contactId,
  draft,
  excludeSearchField,
  fieldLayout,
  fieldState,
  handleConnectionTypeChange,
  handleNestedFieldChange,
  handleRefereeFieldChanged,
  hasConnectionsFeature,
  hasReferencesConnectionsFeature,
  // prevent onChange from being passed through to the input.
  // eslint-disable-next-line no-unused-vars
  onChange,
  onDraftChange,
  referenceId,
  ...otherProps
}) => {
  const fieldClasses = FIELD_LAYOUTS[fieldLayout];
  const contactName = useSelector(
    state =>
      getContactProperty(state, contactId, 'first_name') ||
      getContactProperty(state, contactId, 'full_name'),
  );

  const { connection: referenceConnection, referee } =
    useSelector(state => getReference(state, referenceId))?.toJS() || {};

  const { data: connectionTypes } = useGetConnectionTypesQuery();
  const getConnectionTypesItems = () => {
    const items = [];
    CONNECTION_TYPE_GROUPS.forEach(connectionTypeGroup => {
      const groupItems = connectionTypes?.filter(
        item =>
          item.group === connectionTypeGroup &&
          item.name !== 'Relationship Manager',
      );
      if (groupItems?.length) {
        items.push(
          <SelectMenu.Heading key={connectionTypeGroup}>
            {connectionTypeGroup}
          </SelectMenu.Heading>,
        );
        groupItems.forEach(connectionTypeOption => {
          items.push(
            <SelectMenu.Item
              key={connectionTypeOption.id}
              value={connectionTypeOption.id}
            >
              {getConnectionTypeLabel(connectionTypeOption, contactName)}
            </SelectMenu.Item>,
          );
        });
      }
    });
    return items;
  };

  const recentRefereeConnectionTypeId = useSelector(state =>
    getContactProperty(
      state,
      fieldState.getNestedFieldValue('referee'),
      'recent_connection_type_id',
    ),
  );

  /* disables the connection type field when the user is editing a reference
  or a connection already exists between selected contacts */
  const shouldDisableConnectionField = Boolean(
    (referenceConnection?.id &&
      referee === fieldState.getNestedFieldValue('referee')) ||
      recentRefereeConnectionTypeId,
  );

  return (
    <div className='ReferenceField'>
      {fieldState.getNestedFieldValue('name') && (
        <div className='row'>
          <div className={fieldClasses.name}>
            <InputField
              {...otherProps}
              disabled={true}
              fieldState={fieldState.getNestedField('name')}
              key='name'
              label='Name*'
              onChange={handleNestedFieldChange}
              placeholder='* Name'
            />
            <div className='ReferenceField__nameDeprecationMessage text-danger'>
              Updating this reference will remove the legacy &quot;Name&quot;
              field data.
            </div>
          </div>
        </div>
      )}
      <div className='row'>
        <div className={fieldClasses.referee}>
          <ContactSelectField
            {...otherProps}
            allowCreate={true}
            fieldState={fieldState.getNestedField('referee')}
            label='Contact'
            onChange={newRefereeFieldState =>
              handleRefereeFieldChanged({
                connectionTypes: connectionTypes,
                refereeFieldState: newRefereeFieldState,
              })
            }
            placeholder='Select contact...'
            queryParams={{
              connected_contact_id: contactId,
              excluded_ids: contactId,
            }}
          />
        </div>
        {!excludeSearchField && (
          <div className={fieldClasses.search}>
            <SearchSelectField
              {...otherProps}
              fieldState={fieldState.getNestedField('search')}
              key='search'
              label='Related To'
              onChange={handleNestedFieldChange}
              placeholder='Related to a Search'
            />
          </div>
        )}
      </div>
      <div className='row'>
        <div className={fieldClasses.company}>
          <InputField
            {...otherProps}
            fieldState={fieldState.getNestedField('company')}
            key='company'
            label='Company'
            onChange={handleNestedFieldChange}
            placeholder='Company'
          />
        </div>
        <div className={fieldClasses.relationship}>
          {hasConnectionsFeature && hasReferencesConnectionsFeature ? (
            <SelectMenuField
              fieldState={fieldState.getNestedField('connection_type_id')}
              inputWidth='full'
              isDisabled={shouldDisableConnectionField}
              label={
                shouldDisableConnectionField
                  ? 'Connection -  a connection already exists between these two contacts*'
                  : 'Connection Type*'
              }
              name='Select Connection type'
              onChange={newFieldStateValue =>
                handleConnectionTypeChange({
                  connectionsFieldState: newFieldStateValue,
                  connectionTypes: connectionTypes,
                })
              }
              placeholder='Select connection type'
            >
              {getConnectionTypesItems()}
            </SelectMenuField>
          ) : (
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('relationship')}
              key='relationship'
              label='Relationship*'
              onChange={handleNestedFieldChange}
              placeholder='* Relationship'
            />
          )}
        </div>
        <div className={fieldClasses.email}>
          <InputField
            {...otherProps}
            fieldState={fieldState.getNestedField('email')}
            key='email'
            label='Email'
            onChange={handleNestedFieldChange}
            placeholder='Email'
          />
        </div>
        <div className={fieldClasses.phone}>
          <InputField
            {...otherProps}
            fieldState={fieldState.getNestedField('phone')}
            key='phone'
            label='Phone'
            onChange={handleNestedFieldChange}
            placeholder='Phone'
          />
        </div>
      </div>
      <div className='row'>
        <div className={fieldClasses.description}>
          <RichTextField
            {...otherProps}
            fieldState={fieldState.getNestedField('description')}
            key='description'
            label='Reference Notes'
            lastUpdatedTime={draft?.lastUpdatedTime}
            onChange={handleNestedFieldChange}
            onDraftChange={onDraftChange}
            placeholder='Enter notes...'
          />
        </div>
      </div>
    </div>
  );
};

ReferenceField.propTypes = {
  contactId: PropTypes.number,

  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),
  excludeSearchField: PropTypes.bool,

  /**
   * How to layout the form fields (which col classes to apply)
   */
  fieldLayout: PropTypes.oneOf(Object.keys(FIELD_LAYOUTS)),

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  handleConnectionTypeChange: PropTypes.func.isRequired,

  handleNestedFieldChange: PropTypes.func.isRequired,

  handleRefereeFieldChanged: PropTypes.func.isRequired,

  hasConnectionsFeature: PropTypes.bool,

  hasReferencesConnectionsFeature: PropTypes.bool,
  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,
  onDraftChange: PropTypes.func,
  referenceId: PropTypes.number,
};

ReferenceField.defaultProps = {
  draft: null,
  fieldLayout: 'narrow',
};

export default compose(
  setDisplayName('ReferenceField(enhanced)'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic(
    'createFieldState',
    (
      name = 'connections',
      reference,
      hasConnectionsFeature,
      hasReferencesConnectionsFeature,
      ...rest
    ) => {
      const values = fromJS({
        name: '',
        company: '',
        relationship: '',
        email: '',
        phone: '',
        description: '',
        connection_type_id: '',
      }).merge(reference);

      return FieldState.createNested(
        name,
        [
          InputField.createFieldState('name', values.get('name')),
          SearchSelectField.createFieldState('search', values.get('search')),
          InputField.createFieldState('company', values.get('company')),
          InputField.createFieldState(
            'relationship',
            values.get('relationship'),
            validators.requiredField('Relationship'),
          ),
          ContactSelectField.createFieldState(
            'referee',
            values.get('referee'),
            validators.requiredField('Contact'),
            // Make sure we always assign just the ID -- the contact select can return a contact object.
            contact => (contact && contact.id) || contact,
          ),
          InputField.createFieldState('email', values.get('email')),
          InputField.createFieldState('phone', values.get('phone')),
          RichTextField.createFieldState(
            'description',
            values.get('description'),
          ),
          SelectMenuField.createFieldState(
            'connection_type_id',
            values.getIn(['connection', 'id']) || '',
            hasConnectionsFeature && hasReferencesConnectionsFeature
              ? validators.requiredField('Connection Type')
              : null,
          ),
        ],
        ...rest,
      );
    },
  ),
  withNestedFieldChangeHandler,
  connect(
    state => ({
      // In order to access state in the handleRefereeFieldChanged callback, we need to expose store
      // state in the prop chain.
      state: state,
    }),
    {},
  ),
  withHandlers({
    handleConnectionTypeChange: ({ fieldState, onChange }) => ({
      connectionTypes,
      connectionsFieldState,
    }) => {
      const connectionTypeId = connectionsFieldState.getValue();

      onChange(
        fieldState
          .setNestedFieldValue('connection_type_id', connectionTypeId)
          .setNestedFieldValue(
            'relationship',
            connectionTypes?.find(({ id }) => id === connectionTypeId).name,
          ),
      );
    },
    handleRefereeFieldChanged: ({
      fieldState,
      hasConnectionsFeature,
      onChange,
      state,
    }) => ({ connectionTypes, refereeFieldState }) => {
      // Whenever a contact is selected for the `refereeId` field, we want to autofill
      // the company, email, and phone fields with their information, but only if they are
      // empty.
      let newFieldState = fieldState.setNestedField(refereeFieldState);
      const refereeId = refereeFieldState.getValue();

      if (refereeId) {
        if (!fieldState.getNestedFieldValue('company')) {
          const companyName = getContactProperty(
            state,
            refereeId,
            'primary_company_name',
          );
          if (companyName) {
            newFieldState = newFieldState.setNestedFieldValue(
              'company',
              companyName,
            );
          }
        }

        if (!fieldState.getNestedFieldValue('email')) {
          const email = getContactProperty(state, refereeId, 'preferred_email');
          if (email) {
            newFieldState = newFieldState.setNestedFieldValue('email', email);
          }
        }

        if (!fieldState.getNestedFieldValue('phone')) {
          const phone = getContactProperty(state, refereeId, 'phone');
          if (phone) {
            newFieldState = newFieldState.setNestedFieldValue('phone', phone);
          }
        }

        if (hasConnectionsFeature) {
          const recentConnectionTypeId = getContactProperty(
            state,
            refereeId,
            'recent_connection_type_id',
          );

          if (recentConnectionTypeId) {
            newFieldState = newFieldState
              .setNestedFieldValue('connection_type_id', recentConnectionTypeId)
              .setNestedFieldValue(
                'relationship',
                connectionTypes?.find(({ id }) => id === recentConnectionTypeId)
                  .name,
              );
          }
        }
      }
      onChange(newFieldState);
    },
  }),
  // No longer need the state so don't send it down on props.
  mapProps(({ state: _state, ...props }) => props),
)(ReferenceField);

import { connect } from 'react-redux';
import * as filterUtils from 'modules/core/filterUtils';
import getTagTextForFiltering from '../selectors/getTagTextForFiltering';

/**
 * A Higher order component that filters a list of tagIds based on some text value.
 * It also adds a prop indicating whether this component has actually done any filtering so the
 * base component can be aware, if needed.
 */
export default ({
  filterTextPropName = 'tagFilterText',
  filteredTagIdsPropName = 'tagIds',
  tagIdsPropName = 'tagIds',
} = {}) =>
  connect((state, props) => {
    const filterText = props[filterTextPropName];
    const tagIds = props[tagIdsPropName];

    if (!filterText || !filterText.trim() || !tagIds || tagIds.size < 1) {
      return {};
    }

    const filterRegex = filterUtils.createMatchAllKeywordsRegex(filterText);
    return {
      [filteredTagIdsPropName]: tagIds.filter(tagId =>
        filterRegex.test(
          getTagTextForFiltering(
            state,
            tagId,
            props.filterByCreatorName,
            props.searchType,
          ),
        ),
      ),
    };
  }, {});

import { schema } from 'normalizr';
import { contactSchema } from 'modules/contacts/schema';

/**
 * The schema for the recommendation record itself.
 */
const recommendationsSchema = new schema.Entity('recommendations', {
  contact: contactSchema,
});

export default recommendationsSchema;

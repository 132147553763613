import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import AssessmentBuilder from 'modules/assessment_templates/AssessmentBuilder';
import CandidateAssessment from 'modules/assessments/components/CandidateAssessment';
import TrueBrowserData from 'modules/core/components/TrueBrowserData';
import GuestAssessmentLinkExpired from 'modules/assessments/components/GuestAssessmentLinkExpired';
import store, { history } from '../store';

/**
 * The typical convention for adding pages to thrive-app is to add a new route to <AppPages />
 * however is not possible for this feature due to extremely aggressive regex route matching
 * in the components found in modules/search-views/searchPage
 *
 */
const AssessmentPagesContainer = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route
            component={AssessmentBuilder}
            exact={true}
            path='/assessment_templates/new'
          />
          <Route
            component={AssessmentBuilder}
            exact={true}
            path='/searches/:id/assessment_templates/new'
          />
          <Route
            exact={true}
            path='/talent_pools/:id/assessment_templates/new'
            render={() => <AssessmentBuilder isTalentPool={true} />}
          />
          <Route
            component={AssessmentBuilder}
            exact={true}
            path='/assessment_templates/:assessment_template_id'
          />
          <Route
            component={AssessmentBuilder}
            exact={true}
            path='/assessment_templates/:assessment_template_id/edit'
          />
          <Route
            component={AssessmentBuilder}
            exact={true}
            path='/searches/:id/assessment_templates/new'
          />
          <Route
            exact={true}
            path='/talent_pools/:id/assessment_templates/new'
            render={() => <AssessmentBuilder isTalentPool={true} />}
          />
          <Route
            component={AssessmentBuilder}
            exact={true}
            path='/searches/:id/assessment_templates/:assessment_template_id'
          />
          <Route
            exact={true}
            path='/talent_pools/:id/assessment_templates/:assessment_template_id'
            render={() => <AssessmentBuilder isTalentPool={true} />}
          />
          <Route
            component={AssessmentBuilder}
            exact={true}
            path='/searches/:id/assessment_templates/:assessment_template_id/edit'
          />
          <Route
            exact={true}
            path='/talent_pools/:id/assessment_templates/:assessment_template_id/edit'
            render={() => <AssessmentBuilder isTalentPool={true} />}
          />
          <Route
            exact={true}
            path='/searches/:id/candidates/:candidacy_id/candidacy_assessments/new'
            render={({ location, match }) => {
              const params = new URLSearchParams(location.search);
              const candidacyId = parseInt(match.params.candidacy_id);
              const searchId = parseInt(match.params.id);
              const assessmentType = params.get('type');
              return (
                <TrueBrowserData
                  dataPath={{
                    search: searchId,
                    candidacy: candidacyId,
                    assessmentType: assessmentType,
                  }}
                >
                  <CandidateAssessment
                    assessmentType={new URLSearchParams(location.search).get(
                      'type',
                    )}
                    candidacyId={candidacyId}
                    searchId={searchId}
                  />
                </TrueBrowserData>
              );
            }}
          />
          <Route
            exact={true}
            path='/talent_pools/:id/candidates/:candidacy_id/candidacy_assessments/new'
            render={({ location, match }) => {
              const params = new URLSearchParams(location.search);
              const candidacyId = parseInt(match.params.candidacy_id);
              const searchId = parseInt(match.params.id);
              const assessmentType = params.get('type');
              return (
                <TrueBrowserData
                  dataPath={{
                    search: searchId,
                    candidacy: candidacyId,
                    assessmentType: assessmentType,
                  }}
                >
                  <CandidateAssessment
                    assessmentType={new URLSearchParams(location.search).get(
                      'type',
                    )}
                    candidacyId={candidacyId}
                    isTalentPool={true}
                    searchId={searchId}
                  />
                </TrueBrowserData>
              );
            }}
          />
          <Route
            exact={true}
            path='/searches/:id/candidates/:candidacy_id/candidacy_assessments/:candidacy_assessment_id'
            render={({ match }) => (
              <CandidateAssessment
                assessmentId={parseInt(match.params.candidacy_assessment_id)}
                candidacyId={parseInt(match.params.candidacy_id)}
                searchId={parseInt(match.params.id)}
              />
            )}
          />
          <Route
            exact={true}
            path='/talent_pools/:id/candidates/:candidacy_id/candidacy_assessments/:candidacy_assessment_id'
            render={({ match }) => (
              <CandidateAssessment
                assessmentId={parseInt(match.params.candidacy_assessment_id)}
                candidacyId={parseInt(match.params.candidacy_id)}
                isTalentPool={true}
                searchId={parseInt(match.params.id)}
              />
            )}
          />
          <Route
            exact={true}
            path='/searches/:id/candidates/:candidacy_id/candidacy_assessments/:candidacy_assessment_id/edit'
            render={({ match }) => {
              const assessmentId = parseInt(
                match.params.candidacy_assessment_id,
              );
              const candidacyId = parseInt(match.params.candidacy_id);
              const searchId = parseInt(match.params.id);
              return (
                <TrueBrowserData
                  dataPath={{
                    search: searchId,
                    candidacy: candidacyId,
                    assessment: assessmentId,
                  }}
                >
                  <CandidateAssessment
                    assessmentId={parseInt(
                      match.params.candidacy_assessment_id,
                    )}
                    candidacyId={candidacyId}
                    searchId={searchId}
                  />
                </TrueBrowserData>
              );
            }}
          />
          <Route
            exact={true}
            path='/talent_pools/:id/candidates/:candidacy_id/candidacy_assessments/:candidacy_assessment_id/edit'
            render={({ match }) => {
              const assessmentId = parseInt(
                match.params.candidacy_assessment_id,
              );
              const candidacyId = parseInt(match.params.candidacy_id);
              const searchId = parseInt(match.params.id);
              return (
                <TrueBrowserData
                  dataPath={{
                    search: searchId,
                    candidacy: candidacyId,
                    assessment: assessmentId,
                  }}
                >
                  <CandidateAssessment
                    assessmentId={parseInt(
                      match.params.candidacy_assessment_id,
                    )}
                    candidacyId={candidacyId}
                    isTalentPool={true}
                    searchId={searchId}
                  />
                </TrueBrowserData>
              );
            }}
          />
          <Route
            exact={true}
            path='/guest_candidacy_assessments/new'
            render={({ location }) => {
              const params = new URLSearchParams(location.search);
              const candidacyId = parseInt(params.get('candidacy_id'));
              const searchId = parseInt(params.get('search_id'));
              const assessmentType = params.get('type');
              return (
                <TrueBrowserData
                  dataPath={{
                    search: searchId,
                    candidacy: candidacyId,
                    assessmentType: assessmentType,
                  }}
                >
                  <CandidateAssessment
                    assessmentType={assessmentType}
                    candidacyId={candidacyId}
                    searchId={searchId}
                  />
                </TrueBrowserData>
              );
            }}
          />
          <Route
            component={GuestAssessmentLinkExpired}
            exact={true}
            path='/guest_candidacy_assessments/invalid'
          />
          <Route
            component={GuestAssessmentLinkExpired}
            exact={true}
            path='/guest_assessments/invalid'
          />
        </Switch>
      </Router>
    </Provider>
  );
};

export default AssessmentPagesContainer;

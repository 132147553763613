import getUserPermission from 'modules/auth/selectors/getUserPermission';

/**
 * Gets a value indicating whether the current user can add more than one assessment
 * of type TYPE_CLIENT per candidacy.
 * @param {Object} state
 * @return {Boolean} True if the current user can add more than one client assessment
 *   per candidacy.
 */
export default state =>
  getUserPermission(state, 'add_many_client_assessments', false);

import PropTypes from 'prop-types';
import React from 'react';
import SearchCandidacyLink from './SearchCandidacyLink';

/**
 * A higher order component that wraps a base component in a `SearchCandidacyLink`
 * @param {Function} getSearchCandidacyLinkProps A function that is called with
 *   the base component's props and should return the props to be passed into
 *   the `SearchCandidacyLink`
 * @return {Function} A higher order function that will wrap a components
 *   in a `SearchCandidacyLink`
 */
const wrapInSearchCandidacyLink = getSearchCandidacyLinkProps => BaseComponent => {
  const WrapInSearchCandidacyLink = props => (
    <SearchCandidacyLink {...getSearchCandidacyLinkProps(props)}>
      <BaseComponent {...props} />
    </SearchCandidacyLink>
  );

  WrapInSearchCandidacyLink.propTypes = {
    candidacyId: PropTypes.number,
  };

  return WrapInSearchCandidacyLink;
};

export default wrapInSearchCandidacyLink;

import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import classnames from 'classnames';
import AboveBoardIcon from './social/AboveBoardIcon';
import ContactSourceIcon from './social/ContactSourceIcon';

/**
 * The icon classes to use for the predefined link types.
 * @type {Object}
 */
const ICONS_CLASSES = {
  aboveboard: null,
  email: 'fa-envelope-square',
  github: 'fa-github-square',
  google: 'fa-google',
  linkedin: 'fa-linkedin-square',
  other: 'fa-link',
  phone: 'fa-phone-square',
  skype: 'fa-skype',
  twitter: 'fa-twitter-square',
  contactsource: null,
};

/**
 * The icon class to use when no link type was specified, or the specified
 * link type was not valid.
 */
const DEFAULT_ICON_CLASS = 'fa-external-link';

// Custom Font Awesome Icons
const customFAIcons = {
  aboveboard: <AboveBoardIcon />,
};

const getIcon = (linkType, contactId, tooltipPosition) => {
  if (contactId) {
    return (
      <ContactSourceIcon
        contactId={contactId}
        tooltipPosition={tooltipPosition}
      />
    );
  }
  return (
    customFAIcons[linkType] ?? (
      <i
        className={classnames(
          'fa',
          (linkType && ICONS_CLASSES[linkType]) || DEFAULT_ICON_CLASS,
        )}
      />
    )
  );
};

/**
 * Renders a contact-related link as an icon.
 */
const ContactIconLink = ({
  contactId,
  linkType,
  tooltipPosition,
  ...props
}) => {
  const icon = getIcon(linkType, contactId, tooltipPosition);
  return (
    <a className='contact-icon-link' {...props}>
      {icon}
    </a>
  );
};

ContactIconLink.propTypes = {
  /**
   * Used by `ContactSourceIcon` to retrieve contact data, specfiically the `contact_source` data
   */
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The type of link, which determines the icon that is rendered.
   */
  linkType: PropTypes.oneOf(Object.keys(ICONS_CLASSES)),
  tooltipPosition: PropTypes.string,
};

export default pure(ContactIconLink);

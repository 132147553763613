import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CandidacyStageTag from 'modules/candidacy-views/searchPage/CandidacyStageTag';
import ConditionalTooltipTrigger from 'modules/core/componentsLegacy/ConditionalTooltipTrigger';
import withDeletedTagIdsFiltered from 'modules/tags/components/withDeletedTagIdsFiltered';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import withTagListFetched from 'modules/tags/components/withTagListFetched';
import getSearchProperty from 'modules/searches/selectors/getSearchProperty';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import smartTagSchema from 'modules/tags/schemas/smartTag';
import mapCandidacyIdToProperty from '../mapCandidacyIdToProperty';

const SMART_TAG_NAME_LIMIT = 9;
/**
 * A utility function to add "..." between the word when the length of the word is more than 9
 */
const formatSmartTagName = tagName => {
  if (tagName.length > SMART_TAG_NAME_LIMIT) {
    return `${tagName.substr(0, 3)}...${tagName.substr(
      tagName.length - 3,
      tagName.length,
    )}`;
  }
  return tagName;
};

const CandidacySmartTagList = ({
  candidacySmartTagIds,
  pipelineId,
  tagLimit,
}) => {
  const pipelineSmartTagIds =
    useSelector(state => getSearchProperty(state, pipelineId, 'smart_tags'))
      ?.toJS()
      ?.map(({ id }) => id) || [];

  // list of smart tags that match the pipeline smart tag
  const matchedCandidacySmartTags = useSelector(state =>
    candidacySmartTagIds
      ?.map(tagId => ({
        id: tagId,
        name: getEntityProperty(state, smartTagSchema.key, tagId, 'name', ''),
        color: getEntityProperty(state, smartTagSchema.key, tagId, 'color', ''),
      }))
      .filter(
        ({ id, name }) => Boolean(name) && pipelineSmartTagIds?.includes(id),
      ),
  );

  const additionalMatchedSmartTags =
    matchedCandidacySmartTags?.length > tagLimit ? (
      <ConditionalTooltipTrigger
        nested={true}
        placement='top'
        size='large'
        tooltip={matchedCandidacySmartTags
          ?.slice(tagLimit)
          .map(({ name }) => name)
          .join(', ')}
      >
        <CandidacyStageTag
          className='u-paddingHorizontal-4 u-marginRight-4'
          color='#dee0e8'
          name={`+${matchedCandidacySmartTags?.slice(tagLimit).length}`}
        />
      </ConditionalTooltipTrigger>
    ) : null;

  // list of candidacy smart tags that do not match the pipeline smart tags
  const unmatchedCandidacySmartTags = useSelector(state =>
    candidacySmartTagIds
      ?.map(tagId => ({
        id: tagId,
        name: getEntityProperty(state, smartTagSchema.key, tagId, 'name', ''),
      }))
      .filter(
        ({ id, name }) => Boolean(name) && !pipelineSmartTagIds?.includes(id),
      ),
  );

  return (
    <div className='u-flex'>
      {matchedCandidacySmartTags
        ?.slice(0, tagLimit)
        .map(({ color, id, name }) => (
          <ConditionalTooltipTrigger
            key={name}
            nested={true}
            placement='top'
            size='large'
            tooltip={name.length > SMART_TAG_NAME_LIMIT ? name : null}
          >
            <CandidacyStageTag
              className='u-paddingHorizontal-4 u-marginRight-8'
              color={pipelineSmartTagIds?.includes(id) ? color : '#dee0e8'}
              name={formatSmartTagName(name)}
            />
          </ConditionalTooltipTrigger>
        ))}
      {additionalMatchedSmartTags}
      {unmatchedCandidacySmartTags?.length && tagLimit > 1 ? (
        <ConditionalTooltipTrigger
          nested={true}
          placement='top'
          size='large'
          tooltip={unmatchedCandidacySmartTags
            ?.map(({ name }) => name)
            .join(', ')}
        >
          <CandidacyStageTag
            className='u-paddingHorizontal-4 u-marginRight-8'
            color='#dee0e8'
            name={`${unmatchedCandidacySmartTags.length} Other Criteria`}
          />
        </ConditionalTooltipTrigger>
      ) : null}
    </div>
  );
};

CandidacySmartTagList.defaultProps = {
  tagLimit: 4,
};

CandidacySmartTagList.propTypes = {
  candidacySmartTagIds: PropTypes.arrayOf(PropTypes.number),
  pipelineId: PropTypes.number.isRequired,
  tagLimit: PropTypes.number,
};

export default compose(
  setDisplayName('CandidacySmartTagList'),
  setPropTypes({
    /**
     * The ID of the candidacy whose tags are to be shown.
     */
    candidacyId: PropTypes.number.isRequired,
    pipelineId: PropTypes.number.isRequired,
  }),
  withTagListFetched(),
  mapCandidacyIdToProperty('smart_tag_ids', 'smartTagIds'),
  mapProps(
    ({
      candidacyId: _candidacyId,
      smartTagIds,
      tagList: _tagList,
      ...rest
    }) => ({
      ...rest,
      candidacySmartTagIds: smartTagIds?.toArray() ?? [],
    }),
  ),
  withDeletedTagIdsFiltered,
)(CandidacySmartTagList);

import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  withProps,
  mapProps,
  setPropTypes,
} from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import mapCompanyIdToCompany from 'modules/companies/components/mapCompanyIdToCompany';
import withEmployeeCountListFetched from './withCompanyEmployeeCountListFetched';
import CompanyEmployeeCountListView from './CompanyEmployeeCountListView';
import CompanyEmployeeCountListForm from './CompanyEmployeeCountListForm';
import CompanyEmployeeCountLegacyView from './CompanyEmployeeCountLegacyView';

/**
 * Renders an EditableSection that displays and allows editing of a company's employees count.
 */
export default compose(
  setDisplayName('CompanyEmployeeCountEditableSection(enhanced)'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  withEmployeeCountListFetched,
  mapCompanyIdToCompany,
  withProps(({ company, companyId }) => ({
    displayLegacyData: company.get('number_of_employees') ? (
      <CompanyEmployeeCountLegacyView companyId={companyId} />
    ) : null,
  })),
  mapProps(({ companyId, displayLegacyData, employeeCountList }) => ({
    companyId: companyId,
    isFetching:
      employeeCountList && employeeCountList.getIn(['_meta', 'isFetching']),
    children:
      employeeCountList &&
      employeeCountList.get('ids') &&
      employeeCountList.get('ids').count() ? (
        <CompanyEmployeeCountListView companyId={companyId} />
      ) : (
        displayLegacyData
      ),
    className: 'CompanyEmployeeCountEditableSection',
    formComponent: CompanyEmployeeCountListForm,
    title: 'Number of Employees',
  })),
)(EditableSection);

import React from 'react';
import PropTypes from 'prop-types';
import EditableSection from 'modules/forms/components/EditableSection';
import CompanyBasicsForm from './CompanyBasicsForm';
import CompanyBasicsView from './CompanyBasicsView';
import CompanyName from '../CompanyName';
import CompanyWebsiteLink from '../CompanyWebsiteLink';

const CompanyBasicsEditableSection = ({ companyId }) => {
  const title = (
    <CompanyWebsiteLink companyId={companyId} indicator={true}>
      <CompanyName companyId={companyId} />
    </CompanyWebsiteLink>
  );
  return (
    <EditableSection
      className='CompanyBasicsEditableSection u-paddingLeft-2'
      companyId={companyId}
      formComponent={CompanyBasicsForm}
      title={title}
    >
      <CompanyBasicsView companyId={companyId} />
    </EditableSection>
  );
};

CompanyBasicsEditableSection.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default CompanyBasicsEditableSection;

import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonSecondary';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import { useRemoveContactFromNetworkMutation } from 'services/apiV1/contactNetworks';
import { useDispatch } from 'react-redux';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';

const RemoveContactFromNetworkButton = ({ contactId, networkId }) => {
  const dispatch = useDispatch();
  const [isDialogOpen, openDialog, closeDialog] = useToggle(false);
  const [removeContactFromNetwork] = useRemoveContactFromNetworkMutation();

  const handleConfirm = () => {
    removeContactFromNetwork({
      contactId: contactId,
      networkId: networkId,
    })
      .unwrap()
      .then(() => {
        dispatch(toastSuccess('Contact removed from a network.'));
      })
      .catch(() => {
        dispatch(toastError('Could not remove contact from a network.'));
      });
  };

  return (
    <>
      <ButtonPrimary
        icon='close'
        iconColor='gray'
        onClick={openDialog}
        size='small'
      />
      <ConfirmationDialog
        cancelLabel='No, Keep Contact'
        confirmLabel='Yes, Remove Contact'
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onConfirm={handleConfirm}
        title='Remove Contact from a Network'
      >
        <p>Are you sure you want to remove this contact from network?</p>
      </ConfirmationDialog>
    </>
  );
};

RemoveContactFromNetworkButton.propTypes = {
  contactId: PropTypes.number.isRequired,
  networkId: PropTypes.number.isRequired,
};

export default RemoveContactFromNetworkButton;

import getRecommendationsList from './getRecommendationsList';

/**
 * Determines if the list of available recommendations for a given search should be fetched.
 * @param {Object} state
 * @param {Number} searchId The searchId of the recommendations.
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default (state, searchId) => {
  if (!searchId) {
    return false;
  }

  const recommendationsList = getRecommendationsList(state, searchId);

  if (!recommendationsList) {
    return true;
  }

  if (recommendationsList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (recommendationsList.hasIn(['_meta', 'error'])) {
    return false;
  }

  if (recommendationsList.get('results')) {
    return false;
  }

  return (
    !recommendationsList.has('ids') ||
    Boolean(recommendationsList.getIn(['_meta', 'isInvalidated']))
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import HelpText from '@thrivetrm/ui/components/HelpText';
import TooltipTrigger from './TooltipTrigger';

/**
 * Renders a list of items inline and delimited by commas.
 * This should be used with a collection of `<li>` children.
 *
 * @example
 *
 * ```js
 * <CommaDelimitedList>
 *   <li key='1'>First item</li>
 *   <li key='2'>Second item</li>
 *   <li key='3'>Last item</li>
 * </CommaDelimitedList>
 *
 * // Renders: "First item, Seond item, Last item"
 * ```
 */
const CommaDelimitedList = ({ children, className, limit, ...props }) => {
  let listItems = children;
  let additionalListItems = null;
  let tooltip = null;
  if (limit && children.length > limit) {
    listItems = listItems.slice(0, limit);
    additionalListItems = children.slice(limit);
    tooltip = <ul className='CommaDelimitedList'>{additionalListItems}</ul>;
  }

  return (
    <ul {...props} className={classnames('CommaDelimitedList', className)}>
      {listItems}
      {additionalListItems && (
        <li>
          <TooltipTrigger tooltip={tooltip}>
            <HelpText>+{additionalListItems.length} more</HelpText>
          </TooltipTrigger>
        </li>
      )}
    </ul>
  );
};

CommaDelimitedList.defaultProps = {
  children: [],
  className: null,
  limit: null,
};

CommaDelimitedList.propTypes = {
  /**
   * The child list items. These should render <li> elements!
   */
  children: PropTypes.node,

  /**
   * An optional class name to apply to the list.
   */
  className: PropTypes.string,

  /**
   * The maximum number of elements to show before using a tooltip
   * (optional)
   */
  limit: PropTypes.number,
};

export default CommaDelimitedList;

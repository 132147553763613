import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { onlyUpdateForPropTypes } from 'recompose';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import SortByDropdownButton from 'modules/core/componentsLegacy/SortByDropdownButton';
import {
  SORT_BY_TYPES,
  SORT_BY_LABELS,
  SORT_BY_DEFAULT,
  SORT_ASCENDING_DEFAULT,
} from '../constants';

class SortAndFilterJobApplicationToolbar extends Component {
  handleFilterTextChange = event => {
    const { onChange, sortAscending, sortBy } = this.props;
    onChange({
      sortBy: sortBy,
      sortAscending: sortAscending,
      filterText: event.currentTarget.value,
    });
  };

  handleSortChange = sortBy => {
    const {
      filterText,
      onChange,
      sortAscending,
      sortBy: previousSortBy,
    } = this.props;

    onChange({
      sortBy: sortBy,
      sortAscending: previousSortBy === sortBy ? !sortAscending : true,
      filterText: filterText,
    });
  };

  render() {
    const { filterText, sortAscending, sortBy } = this.props;

    return (
      <form
        className='form target-companies--sort-and-filter-toolbar'
        onSubmit={preventDefaultHandler}
        role='search'
      >
        <div className='input-group'>
          <input
            className='form-control'
            onChange={this.handleFilterTextChange}
            placeholder='Filter by name or email'
            type='text'
            value={filterText}
          />
          <SortByDropdownButton
            className='input-group-btn'
            isAscending={sortAscending}
            onChange={this.handleSortChange}
            options={SORT_BY_LABELS}
            value={sortBy}
          />
        </div>
      </form>
    );
  }
}

SortAndFilterJobApplicationToolbar.propTypes = {
  /**
   * The current text being filtered on and displayed in the input
   */
  filterText: PropTypes.string,

  /**
   * Called when an of the filter or sorting values are changed, where the first parameter is
   * an object of filter values:
   *   `onChange({ filterText, sortBy })`
   */
  onChange: PropTypes.func.isRequired,

  /**
   * True if sorting should be in ascending order, false to sort in descending order.
   */
  sortAscending: PropTypes.bool,

  /**
   * The value to sort on
   */
  sortBy: PropTypes.oneOf(SORT_BY_TYPES),
};

SortAndFilterJobApplicationToolbar.defaultProps = {
  filterText: '',
  sortAscending: SORT_ASCENDING_DEFAULT,
  sortBy: SORT_BY_DEFAULT,
};

export default onlyUpdateForPropTypes(SortAndFilterJobApplicationToolbar);

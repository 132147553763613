import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import talentPoolsSchema from '../schema';

/**
 * Gets the ID of the company which is a talent pool's client company
 * @param {Object} state
 * @param {Number} id The talent pool ID
 * @return {Number} The ID of the talent pool's client company
 */
export default (state, id) =>
  getEntityProperty(state, talentPoolsSchema.key, id, 'client_company');

import getEntity from 'modules/entities/selectors/getEntity';
import residencySchema from '../schema';

/**
 * Gets a residency record from state by it's ID
 * @param {Object} state
 * @param {Number} id The ID of the residency record
 * @returns {Map} The residency record, if found.
 */
export default (state, id) => getEntity(state, residencySchema.key, id);

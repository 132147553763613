/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Pagination from '@thrivetrm/ui/components/Pagination';
import SegmentedControl from '@thrivetrm/ui/components/SegmentedControl';
import EmptyState from './EmptyState';
import ErrorAlert from './ErrorAlert';
import LoadingIndicator from './LoadingIndicator';

// eslint-disable-next-line no-magic-numbers
const RESULTS_PER_PAGE_OPTIONS = [5, 25, 100];
const DEFAULT_RESULTS_PER_PAGE = 25;

/**
 * A composite component for rendering some sort of data set, which includes:
 * * Any child component (a table, list, etc... something that renders the data)
 * * A Loading indicator while fetching data.
 * * An error alert message displayed when the data was unable to be fetched
 * * An empty state displayed when there is no data.
 * * Page navigation
 */
const DatasetView = ({
  children,
  className,
  currentPage,
  emptyState,
  errorAlert,
  hasData,
  limit,
  loadingIndicator,
  onLimitChange,
  onPageChange,
  overlayLoadingIndicator,
  totalCount,
  totalPages,
}) => (
  <div
    className={classnames('DatasetView', className, {
      'DatasetView--overlayLoadingIndicator': overlayLoadingIndicator,
    })}
    data-total-row-count={totalCount}
  >
    <div className='DatasetView__dataset'>{children}</div>
    {loadingIndicator && (!hasData || overlayLoadingIndicator) && (
      // The main loading indicator only gets shown if we don't have any current data, or if we
      // are using an overlayed loading indicator.
      <LoadingIndicator {...loadingIndicator} />
    )}
    {errorAlert && <ErrorAlert {...errorAlert} />}
    {emptyState && <EmptyState {...emptyState} />}
    {onPageChange || onLimitChange ? (
      <div
        className='u-flex u-flexAlign-c u-flexJustify-spaceBetween u-marginVertical-24'
        data-testid='page controls'
      >
        {onPageChange ? (
          <Pagination
            currentPage={currentPage}
            onPageChange={onPageChange}
            resultsPerPage={limit}
            totalPages={totalPages}
            totalResults={totalCount}
          />
        ) : null}
        {onLimitChange ? (
          <SegmentedControl
            onChange={onLimitChange}
            segments={RESULTS_PER_PAGE_OPTIONS}
            selectedSegmentLabel={limit}
            size='small'
          />
        ) : null}
      </div>
    ) : null}
  </div>
);

DatasetView.defaultProps = {
  overlayLoadingIndicator: false,
};

DatasetView.defaultProps = {
  children: null,
  className: null,
  currentPage: 1,
  emptyState: null,
  errorAlert: null,
  limit: DEFAULT_RESULTS_PER_PAGE,
  loadingIndicator: null,
  onLimitChange: null,
  onPageChange: null,
  overlayLoadingIndicator: null,
  totalCount: 0,
  totalPages: 0,
};

DatasetView.propTypes = {
  /**
   * The underlying data rendering component (List, Table, etc)
   */
  children: PropTypes.node,

  /**
   * Optional class name to apply to the container.
   */
  className: PropTypes.string,

  /**
   * When paging results, the current page being shown.
   */
  currentPage: PropTypes.number,

  /**
   * When truthy, an EmptyState will be rendered in the table footer, passing this
   * props value into the EmptyState component as it's props.
   */
  emptyState: PropTypes.oneOfType([
    PropTypes.shape(EmptyState.propTypes),
    PropTypes.bool,
  ]),

  /**
   * When truthy, an ErrorAlert will be rendered in the table footer, passing this
   * props value into the ErrorAlert component as it's props.
   */
  errorAlert: PropTypes.oneOfType([
    PropTypes.shape(ErrorAlert.propTypes),
    PropTypes.bool,
  ]),

  hasData: PropTypes.bool.isRequired,
  /**
   * The number of records per page selected.
   */
  limit: PropTypes.number,

  /**
   * When truthy, an LoadingIndicator will be rendered in the table footer, passing this
   * props value into the LoadingIndicator component as it's props.
   */
  loadingIndicator: PropTypes.oneOfType([
    PropTypes.shape(LoadingIndicator.propTypes),
    PropTypes.bool,
  ]),

  /**
   * Called when the number of records per page should be changed.
   */
  onLimitChange: PropTypes.func,

  /**
   * Called when the page number should be changing.Providing this parameter enabled paging
   * support, so if the table shouldn't support paging, this should not be set.
   */
  onPageChange: PropTypes.func,

  /**
   * If true, the loading indicator will be rendered as an overlay of the dataset,
   * otherwise it is rendered below the dataset.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  overlayLoadingIndicator: PropTypes.bool,

  /**
   * The total number of items in the table.
   */
  totalCount: PropTypes.number,

  /**
   * The total number of pages available (if paging).
   */
  totalPages: PropTypes.number,
};

export default DatasetView;

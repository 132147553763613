import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import RevenueMaximumSelectField from './RevenueMaximumSelectField';
import RevenueMinimumSelectField from './RevenueMinimumSelectField';

/**
 * A composite field for selecting from a pre-set list of revenue range minimum and maximum values.
 */
const RevenueRangeSelectField = ({
  fieldState,
  handleNestedFieldChange,
  maximumFieldLabel,
  maximumFieldProps,
  minimumFieldLabel,
  ...otherProps
}) => (
  <div className='RevenueRangeSelectField row'>
    <RevenueMinimumSelectField
      {...otherProps}
      className='col-6'
      fieldState={fieldState.getNestedField('minimum')}
      label={minimumFieldLabel}
      onChange={handleNestedFieldChange}
    />
    <RevenueMaximumSelectField
      {...otherProps}
      {...maximumFieldProps}
      className='col-6'
      fieldState={fieldState.getNestedField('maximum')}
      label={maximumFieldLabel}
      onChange={handleNestedFieldChange}
    />
  </div>
);

RevenueRangeSelectField.propTypes = {
  /**
   * The field state containing the nested value.
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * Called when a nested field is changed.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * The label for the "maximum" field.
   */
  maximumFieldLabel: PropTypes.string,

  /**
   * Additional props to pass to the "maximum" select field.
   */
  maximumFieldProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types

  /**
   * The label for the "minimum" field.
   */
  minimumFieldLabel: PropTypes.string,

  /**
   * Additional props to pass to the "minimum" select field.
   */
  minimumFieldProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

RevenueRangeSelectField.defaultProps = {
  maximumFieldLabel: 'Maximum Revenue',
  maximumFieldProps: {},
  minimumFieldLabel: 'Minimum Revenue',
  minimumFieldProps: {},
};

export default compose(
  setDisplayName('RevenueRangeSelectField(enhanced)'),
  setPropTypes({
    /**
     * A FieldState representing the value selected, created with
     * RevenueRangeSelectField.createFieldState
     */
    fieldState: fieldStatePropType.isRequired,

    /**
     * Called when the fieldState is changed.
     */
    onChange: PropTypes.func.isRequired,
  }),
  setStatic(
    'createFieldState',
    (name = 'revenue_range', { maximum, minimum } = {}, ...rest) =>
      FieldState.createNested(
        name,
        [
          RevenueMinimumSelectField.createFieldState('minimum', minimum),
          RevenueMaximumSelectField.createFieldState('maximum', maximum),
        ],
        ...rest,
      ),
  ),
  withNestedFieldChangeHandler,
  withFormGroup,
)(RevenueRangeSelectField);

import { connect } from 'react-redux';
import getMeeting from '../selectors/getMeeting';

/**
 * A higher order component which maps an `meetingId` prop value to its meeting record.
 */
export default connect(
  (state, { meetingId }) => ({
    meeting: getMeeting(state, meetingId),
  }),
  {},
);

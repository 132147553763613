import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, withHandlers, withState } from 'recompose';
import GenerateReportButton from 'modules/reports/components/GenerateReportButton';
import mapContactIdToTalentPoolId from '../mapContactIdToTalentPoolId';
import TalentPoolReportFormModal from './TalentPoolReportFormModal';

/**
 * Renders a button that, when clicked, displays the
 * TalentPoolReportModal dialog allowing a user to request a talent pool report.
 */
const TalentPoolReportButton = ({
  contactId,
  handleHideModal,
  handleShowModal,
  isModalOpen,
  talentPoolId,
}) => (
  <div className='TalentPoolReportButton'>
    <GenerateReportButton onClick={handleShowModal}>
      Generate Report
    </GenerateReportButton>
    {isModalOpen && (
      <TalentPoolReportFormModal
        contactId={contactId}
        onHide={handleHideModal}
        show={isModalOpen}
        talentPoolId={talentPoolId && talentPoolId}
      />
    )}
  </div>
);

TalentPoolReportButton.propTypes = {
  /**
   * The ID of the talent pool incumbent.
   */
  contactId: PropTypes.number.isRequired,

  /**
   * Called when the modal should be hidden
   * (provided by withProps HOC, below)
   */
  handleHideModal: PropTypes.func.isRequired,

  /**
   * Called when the modal should be shown
   * (provided by withProps HOC, below)
   */
  handleShowModal: PropTypes.func.isRequired,

  /**
   * Determines whether the modal form is shown
   * (managed by withState HOC, below)
   */
  isModalOpen: PropTypes.bool.isRequired,

  /**
   * The ID of the talent pool to generate the report for.
   */
  talentPoolId: PropTypes.number,
};

export default compose(
  setDisplayName('TalentPoolReportButton(enhanced)'),
  // Manage the state of the modal.
  withState('isModalOpen', 'setIsModalOpen', false),
  withHandlers({
    handleShowModal: ({ setIsModalOpen }) => () => setIsModalOpen(true),
    handleHideModal: ({ setIsModalOpen }) => () => setIsModalOpen(false),
  }),
  mapContactIdToTalentPoolId,
)(TalentPoolReportButton);

import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import fetchContactIfNeeded from '../fetchContactIfNeeded';
import ContactRatingList from './ContactRatingList';
import ContactRatingForm from './ContactRatingForm';
import ContactAverageRating from './ContactAverageRating';
import isContactRatedByCurrentUser from '../../selectors/ratings/isContactRatedByCurrentUser';

/**
 * Used to identity that the "add" form should be shown without the attributed to field.
 */
const WITHOUT_ATTRIBUTED_TO = 1;

/**
 * Used to identity that the "add" form should be shown with the attributed to field.
 */
const WITH_ATTRIBUTED_TO = 2;

/**
 * A panel for viewing/manging a contact's ratings
 * Renders:
 * * The overall/average rating;
 * * A selector button allowing to add a new rating;
 * * The list of all current ratings for the contact.
 */
const ContactRatingsPanel = ({
  componentId,
  contactId,
  handleRatingFormClose,
  handleRatingFormSelect,
  isRatedByCurrentUser,
  showAddRatingForm,
}) => (
  <div className='ContactRatingsPanel'>
    <div className='ContactRatingsPanel__averageRating'>
      <h3>Overall Rating</h3>
      <ContactAverageRating contactId={contactId} />
    </div>

    <div className='ContactRatingsPanel__allRatings'>
      <h3>
        Ratings
        {!showAddRatingForm && (
          <DropdownButton
            bsStyle='link'
            id={`${componentId}-NewRatingDropdown`}
            noCaret={true}
            onSelect={handleRatingFormSelect}
            pullRight={true}
            title={
              <div>
                <i className='fa fa-plus-circle' /> Add Rating
              </div>
            }
          >
            {!isRatedByCurrentUser && (
              <MenuItem eventKey={WITHOUT_ATTRIBUTED_TO}>My Rating</MenuItem>
            )}
            <MenuItem eventKey={WITH_ATTRIBUTED_TO}>On Behalf Of</MenuItem>
          </DropdownButton>
        )}
      </h3>

      {showAddRatingForm && (
        <div className='ContactRatingsPanel__addRating'>
          <h3>Add Rating</h3>
          <ContactRatingForm
            contactId={contactId}
            includeAttributedTo={showAddRatingForm === WITH_ATTRIBUTED_TO}
            onCancel={handleRatingFormClose}
            onSaved={handleRatingFormClose}
          />
        </div>
      )}

      <ContactRatingList contactId={contactId} />
    </div>
  </div>
);

ContactRatingsPanel.propTypes = {
  componentId: PropTypes.string.isRequired,
  contactId: PropTypes.number.isRequired,
  handleRatingFormClose: PropTypes.func.isRequired,
  handleRatingFormSelect: PropTypes.func.isRequired,
  isRatedByCurrentUser: PropTypes.bool.isRequired,
  showAddRatingForm: PropTypes.oneOf([
    false,
    WITHOUT_ATTRIBUTED_TO,
    WITH_ATTRIBUTED_TO,
  ]).isRequired,
};

export default compose(
  setDisplayName('ContactRatingsPanel(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),

  fetchContactIfNeeded({ requireFull: true }),

  // Determine whether the current user has rated this contact, which will determine
  // if the "Add my rating" option is available.
  connect(
    (state, { contactId }) => ({
      isRatedByCurrentUser: isContactRatedByCurrentUser(state, contactId),
    }),
    {},
  ),

  // Manage the state of the "add rating" form. This will be false when the form should not be
  // shown, and will be either `WITHOUT_ATTRIBUTED_TO` or `WITH_ATTRIBUTED_TO` when the form
  // should be shown, further indicating whether to include the "attributed to" field.
  withStateHandlers(
    {
      showAddRatingForm: false,
    },
    {
      handleRatingFormSelect: () => withAttributedTo => ({
        showAddRatingForm: withAttributedTo,
      }),
      handleRatingFormClose: () => () => ({ showAddRatingForm: false }),
    },
  ),
  withComponentId(),
)(ContactRatingsPanel);

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import { arc } from 'd3-shape';
import { VictoryPie, VictoryLabel } from 'victory';
import Overlay from 'react-bootstrap/lib/Overlay';
import chartTheme from 'modules/charts/chartTheme';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';

import withCandidacyPerStageStats from './withCandidacyPerStageStats';
import CandidaciesPerStageDonutChartPopover from './CandidaciesPerStageDonutChartPopover';

const EMPTY_STAGE_ID = -1;

/**
 * Controls the thickness of the chart. Larger values result in thinner slices, because this
 * actually controlls the inner blank circle size, so expanding that reduces the size of the
 * outer part of the chart which is what represents our data/slices.
 */
const INNER_RADIUS = 65;
// Nudge the tooltop towards the outer edge of the slice for better readability
const TOOLTIP_INNER_RADIUS = 70;

// Victory doesn't provide an easy way to disable labels on pie charts, so we
// need this silly component to "render nothing". We can't just provide
// `null` as a value directly because it fails Victory's propType validation :(
const HiddenLabelComponent = () => null;

/**
 * Renders a donut chart that shows the breakdown of the number of candidates in each
 * search stage.
 *
 * The primary chart is rendered using a `VictoryPie` component. The additional `VictoryLabel`
 * is used to render the total number of candidates in the center of the donut.
 *
 * Notes about tooltips:
 *
 * Tooltips are rendered using react-bootstrap so that we can more easily customize their look.
 * Using `VictoryTooltips` we would have to render them as SVG elements -- and they would be
 * confined to the parent SVG bounding box, which means that some tooltips could be clipped.
 * It's also much more difficult to customize the look of VictoryTooltips.
 *
 * The problem with using react-bootstrap overlay's is that they require a `target` element in
 * which to position themselves against. In the case of this chart that would be a <path> element
 * inside our <svg> -- but we don't have any (easy) way to get that element.
 *
 * In order to get around that limition, we instead are rendering an absolutely positioned <div>
 * element (`CandidaciesPerStageDonutChart__popoverTarget`). Whenever the mouse enters a pie slice,
 * we can calculate the "center" of that slice and note the position (which will be relative to
 * the center of the chart). This gets saved as `xOffset` and `yOffset` on the `activeChartItem`
 * item. We can then use this relative center point to determine where to position our popover
 * target.
 */
const CandidaciesPerStageDonutChart = ({
  activeChartItem,
  chartData,
  componentId,
  getScrollContainer,
  getStageColor,
  // getPopoverTarget,
  getStageVisibility,
  getTargetRef,
  // setPopoverTarget,
  isFetching,

  // withScrollContainerRef props
  setActiveChartItem,
  setTargetRef,
  totalCandidates,
}) => (
  <div
    className='CandidaciesPerStageDonutChart'
    style={{ position: 'relative' }}
  >
    {(!chartData || !chartData.length) && isFetching && <LoadingIndicator />}
    <div
      className='CandidaciesPerStageDonutChart__popoverTarget'
      ref={setTargetRef}
      // This is what we attach our popover to. xOffset and yOffset are relative to the center
      // of the SVG chart. Additionally, they use the SVG scale (200x200 in our case). So we
      // adjust this point to be relative to the upper-left instead of the center point (by
      // adding 100), and then converting to a percentage (by dividing by 2).
      style={{
        position: 'absolute',
        left: `${activeChartItem && (activeChartItem.xOffset + 100) / 2}%`,
        top: `${activeChartItem && (activeChartItem.yOffset + 100) / 2}%`,
      }}
    />
    <Overlay
      animation={false}
      container={getScrollContainer}
      // `yOffset` is relative to the center of the chart. When this is positive, that means
      // it's in the bottom half of the chart, so we position the popover below the slice,
      // otherwise it's in the top half of the chart, so we render above. This keeps as much of
      // the pie chart as we can visible (not being hidden by the popover).
      placement={
        activeChartItem && activeChartItem.yOffset > 0 ? 'bottom' : 'top'
      }
      show={activeChartItem && activeChartItem.count !== null}
      // This targets our absolutely positioned DIV, which ultimately gets positioned based on the
      // current slice centroid.
      target={getTargetRef}
    >
      <CandidaciesPerStageDonutChartPopover
        color={activeChartItem && activeChartItem.color}
        count={activeChartItem && activeChartItem.count}
        id={componentId}
        isEmptyPlaceholder={
          Boolean(activeChartItem) && activeChartItem.id === EMPTY_STAGE_ID
        }
        label={activeChartItem && activeChartItem.label}
        totalCandidates={totalCandidates}
      />
    </Overlay>
    <svg viewBox='0 0 200 200'>
      {chartData.length && (
        // A white circle that acts as the background for the center of the pie
        <circle
          cx='100'
          cy='100'
          r={INNER_RADIUS}
          style={{ fill: '#ffffff' }}
        />
      )}
      <VictoryLabel
        // VictoryLabel doesn't currently take a theme prop like other components, so this is
        // a bit of a workaround.
        style={{
          ...chartTheme.labels,
          fontSize: 25,
          fontWeight: 800,
        }}
        text={totalCandidates}
        textAnchor='middle'
        x={100}
        y={100}
      />
      <VictoryPie
        animate={{
          duration: 300,
          delay: 0,
          easing: 'cubicInOut',
        }}
        data={chartData}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onMouseOver: () => [
                {
                  target: 'labels',
                  mutation: () => ({ active: true }),
                },
                {
                  target: 'data',
                  mutation: ({ cornerRadius, datum, radius, slice, style }) => {
                    // Since we're using custom tooltips, we must manually calculate
                    // the tooltip position so that it can render at the center of
                    // the arc of the currently-hovered donut slice.
                    const [xOffset, yOffset] = arc()
                      .innerRadius(TOOLTIP_INNER_RADIUS)
                      .cornerRadius(cornerRadius)
                      .outerRadius(radius)
                      .startAngle(slice.startAngle)
                      .endAngle(slice.endAngle)
                      .centroid();

                    setActiveChartItem({
                      ...datum,
                      xOffset: xOffset,
                      yOffset: yOffset,
                    });

                    // Increase the stroke width of the slice, making it "expand" a bit when hovered.
                    return {
                      style: {
                        ...style,
                        strokeWidth: 3,
                      },
                    };
                  },
                },
              ],
              onMouseOut: () => [
                {
                  target: 'labels',
                  mutation: () => ({ active: false }),
                },
                {
                  target: 'data',
                  mutation: () => {
                    setActiveChartItem(null);

                    // Reset the stroke width we set in `onMouseOver`
                    return {};
                  },
                },
              ],
            },
          },
        ]}
        height={200}
        innerRadius={INNER_RADIUS}
        labelComponent={<HiddenLabelComponent />}
        standalone={false}
        style={{
          data: {
            strokeWidth: 1,
            stroke: getStageColor,
            fill: getStageColor,
            visibility: getStageVisibility,
          },
        }}
        theme={chartTheme}
        width={200}
        x='id'
        y='count'
      />
    </svg>
  </div>
);

CandidaciesPerStageDonutChart.propTypes = {
  /**
   * The chart item currently being hovered
   */
  activeChartItem: PropTypes.shape({
    color: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    xOffset: PropTypes.number.isRequired,
    yOffset: PropTypes.number.isRequired,
  }),

  /**
   * An array of all items to display in the chart.
   * Note that chartData is _required_! When initially undefined, the chart may not render
   * once it actually gets data (might be a VictoryChart issue?)
   */
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,

  /**
   * A unique componentId for this instance. This is needed by the popover.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * Gets the container that the popover should be shown in.
   */
  getScrollContainer: PropTypes.func.isRequired,

  /**
   * A function that can be called with a chart data item and will return the stage color
   * for that item.
   */
  getStageColor: PropTypes.func.isRequired,

  /**
   * A function that returns the desired visibility of a particular chart data item.
   */
  getStageVisibility: PropTypes.func.isRequired,

  /**
   * A function that can be called to get the current target for the popover
   */
  getTargetRef: PropTypes.func.isRequired,

  /**
   * True if the stage list is currently being fetched.
   */
  isFetching: PropTypes.bool.isRequired,

  /**
   * Sets the currently active chart item
   */
  setActiveChartItem: PropTypes.func.isRequired,

  /**
   * Sets the element that the popover should position itself relative to.
   */
  setTargetRef: PropTypes.func.isRequired,

  /**
   * The total number of candidates across all stages being rendered.
   */
  totalCandidates: PropTypes.number,
};

export default compose(
  setDisplayName('CandidaciesPerStageDonutChart(enhanced)'),
  setPropTypes({
    activeOnly: PropTypes.bool,
    searchId: PropTypes.number,
  }),
  withComponentId(),
  withCandidacyPerStageStats,

  // Manage the currently "active" item (whichever slice is currently hovered)
  withState('activeChartItem', 'setActiveChartItem', null),

  withHandlers({
    // This needs to be a function so we can dynamically set the fill color of each slice.
    getStageColor: () => ({ datum }) => datum?.color,

    // We need to actually completely hide slices that have a count of 0, otherwise we will still
    // see a tiny sliver and they will still have tooltips.
    getStageVisibility: () => ({ datum }) =>
      datum?.count ? 'visible' : 'hidden',
  }),

  // Generate an array of raw data we can pass into the charts
  // for it to render.
  withProps(
    ({
      activeOnly,
      stageCounts,
      stageList,
      stages,
      totalActiveCandidates,
      totalCandidates: totalAllCandidates,
    }) => {
      const totalCandidates = activeOnly
        ? totalActiveCandidates
        : totalAllCandidates;
      return {
        // Currently there is a bug in VictoryPie with transition animations
        // (@see https://github.com/FormidableLabs/victory/issues/374)
        // The items are animated based on their *index*, and not their unique x value, which causes
        // things to animate very strangely. So instead we always pass in ALL stages, and when
        // we're only rendering active stages, we set the `count` on non-active stages to 0.
        // Then we use the `getStageVisibility` handler (above) to hide any slices with a count of 0.
        chartData:
          stages && stageCounts
            ? stages
                .map(stage => ({
                  id: stage.get('id'),
                  count:
                    activeOnly && !stage.get('active')
                      ? 0
                      : stageCounts.get(`${stage.get('id')}`, 0),
                  color: stage.get('color'),
                  label: stage.get('name'),
                }))
                .toJS()
                .concat({
                  // This is an "empty" slice that only gets displayed when the totalCandidates count is 0.
                  // Normally the pie chart would render nothing when all `count` items sum to 0, but
                  // we want it to show a light gray ring that takes up the entire donut, and when hovered
                  // we display "No candidates".
                  id: EMPTY_STAGE_ID,
                  count: totalCandidates === 0 ? 1 : 0,
                  color: '#cecece',
                  label: `No ${activeOnly ? 'active' : ''} candidates`,
                })
            : [],
        totalCandidates: totalCandidates,
        isFetching: Boolean(
          stageList && stageList.getIn(['_meta', 'isFetching']),
        ),
      };
    },
  ),

  // This adds an `onData` callback that gets called whenever the chartData changes.
  // This is really kind of a hacky way for the SidebarSearchContentHeader to initialize it's
  // 'activeOnly' toggle state -- it needs to be initialized based on whether there are actually
  // any active candidates, but that's not giong to be known until the data itself is loaded.
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const { chartData, onData } = this.props;

      if (onData && chartData.length) {
        onData(this.props);
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const { chartData, onData } = nextProps;

      if (onData && chartData !== this.props.chartData && chartData.length) {
        onData(nextProps);
      }
    },
  }),
  withScrollContainerRef,
)(CandidaciesPerStageDonutChart);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@thrivetrm/ui/components/Card';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import DateComponent from '@thrivetrm/ui/components/Date';
import Menu from '@thrivetrm/ui/components/Menu';
import iso8601PropType from '@thrivetrm/ui/propTypes/iso8601PropType';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import { dateToISODateString } from '@thrivetrm/ui/utilities/dateTimeUtils';
import OffLimitsStatus from 'modules/off-limits/OffLimitsStatus';
import {
  OffLimitsRecordTypes,
  useUpdateOffLimitsCustomReasonMutation,
} from 'services/apiV1/offLimits';
import useQuickView from '../useQuickView';

const SEVERITY_TO_CATEGORY_MAP = {
  Minor: 'info',
  Moderate: 'warning',
  Major: 'danger',
};

const OffLimitsCard = ({ reason, recordId, recordName, recordType }) => {
  const { navigateTo } = useQuickView();
  const candidacyId = window.location.pathname.includes('searches')
    ? window.location.pathname.split('/')[4]
    : null;
  const [
    updateOffLimitsCustomReason,
  ] = useUpdateOffLimitsCustomReasonMutation();

  const {
    company,
    endDate,
    id,
    isActive,
    isArchivable,
    isCustom,
    muted,
    notes,
    owner,
    reasonName,
    search,
    severity,
    startDate,
  } = reason;

  const [
    isArchiveStatusConfirmationOpen,
    openArchiveStatusConfirmation,
    closeArchiveStatusConfirmation,
  ] = useToggle(false);

  const handleArchiveStatusConfirm = () => {
    /**
     * To archive a Custom Reason we have to set
     * the date to either today or a past date
     */
    const payload = { end_date: dateToISODateString(new Date()) };
    updateOffLimitsCustomReason({
      reasonData: payload,
      reasonId: id,
      recordId: recordId,
      recordType: recordType,
      candidacyId: candidacyId,
    });
  };

  const cardClass = classnames('OffLimitsCard', 'u-paddingTop-n', {
    'OffLimitsCard--isMuted': Boolean(muted),
  });

  const canEdit = isCustom && !(recordType === 'contact' && company);

  return (
    <Card
      className={cardClass}
      data-testid='off-limits-card'
      isCentered={false}
    >
      <div className='OffLimitsCard__header'>
        <OffLimitsStatus
          severity={SEVERITY_TO_CATEGORY_MAP[severity]}
          status={reasonName}
        />
        {search?.stage ? (
          <span className='u-marginLeft-16 u-fontSize-small'>
            {search.stage}
          </span>
        ) : null}
        {canEdit || isArchivable ? (
          <div className='OffLimitsCard__menu'>
            <Menu isPinnedRight={true}>
              {canEdit ? (
                <Menu.Item
                  icon='edit'
                  onClick={() =>
                    navigateTo(`/${recordType}/${recordId}/off-limits/${id}`)
                  }
                >
                  Edit
                </Menu.Item>
              ) : null}
              {isArchivable || isCustom ? (
                <Menu.Item
                  icon='schedule'
                  isDisabled={!isActive}
                  onClick={() => {
                    if (isArchivable) {
                      navigateTo(
                        `/${recordType}/${recordId}/archive-off-limits/${id}`,
                      );
                    } else {
                      openArchiveStatusConfirmation();
                    }
                  }}
                >
                  Archive
                </Menu.Item>
              ) : null}
            </Menu>
          </div>
        ) : null}
      </div>
      <div className='OffLimitsCard__data u-borderTop u-paddingTop-4'>
        {search && (
          <h4 className='u-marginTop-4 u-marginBottom-4'>
            <a href={`/searches/${search.id}/candidates`}>{search.name}</a>
          </h4>
        )}
        {!search && company && (
          <h4 className='u-marginTop-4 u-marginBottom-4'>
            <a href={`/companies/${company.id}`}>{company.name}</a>
          </h4>
        )}
        {owner && (
          <div className='u-marginTop-4 u-fontSize-small'>
            <strong>Contact: </strong>
            <a href={`mailto:${owner.email}`}>{owner.name}</a>
          </div>
        )}
        <div className='u-fontSize-small'>
          <strong>Applied On:</strong> <DateComponent date={startDate} />
        </div>
        {endDate && (
          <div className='u-fontSize-small'>
            <strong>Expires On:</strong> <DateComponent date={endDate} />
          </div>
        )}
        {muted && (
          <div className='u-fontSize-small u-marginTop-8'>
            <div>
              <strong>Muted By:</strong>{' '}
              <a href={`mailto:${muted.user.email}`}>{muted.user.name}</a>
            </div>
            <div>
              <strong>Muted On:</strong>{' '}
              <DateComponent date={muted.startDate} />
            </div>
            <div>
              <strong>Reason:</strong> {muted.reason}
            </div>
          </div>
        )}
        {notes && (
          <div className='u-fontSize-small u-marginTop-8'>
            <strong>Notes:</strong> {notes}
          </div>
        )}
      </div>
      <ConfirmationDialog
        cancelLabel='No, Keep Working'
        confirmLabel='Yes, Archive This Reason'
        isOpen={isArchiveStatusConfirmationOpen}
        onClose={closeArchiveStatusConfirmation}
        onConfirm={handleArchiveStatusConfirm}
        title='Archive Custom Reason'
      >
        <p>
          Archiving applies an end date to the reason and adds it to the list of
          historical reasons for {recordName}.
        </p>
        <div>
          <b>Do you want to continue?</b>
        </div>
      </ConfirmationDialog>
    </Card>
  );
};

/** (ISO 8601 date/time string) */
OffLimitsCard.propTypes = {
  reason: PropTypes.shape({
    company: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    endDate: iso8601PropType,
    id: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    isArchivable: PropTypes.bool.isRequired,
    isCustom: PropTypes.bool.isRequired,
    muted: PropTypes.shape({
      reason: PropTypes.string.isRequired,
      startDate: iso8601PropType.isRequired,
      user: PropTypes.shape({
        email: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
    notes: PropTypes.string,
    owner: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    reasonName: PropTypes.string.isRequired,
    search: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      stage: PropTypes.string,
    }),
    severity: PropTypes.oneOf(Object.keys(SEVERITY_TO_CATEGORY_MAP)),
    startDate: iso8601PropType,
  }),
  recordId: PropTypes.number.isRequired,
  recordName: PropTypes.string.isRequired,
  recordType: PropTypes.oneOf(Object.values(OffLimitsRecordTypes)).isRequired,
};

export default OffLimitsCard;

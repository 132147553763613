import { PARENT_TYPES } from '../constants';
import getMeetings from './getMeetings';

/**
 * Determines if a list of meetings should be fetched.
 * @param {Object} state
 * @param {String} parentType The parent type
 * @param {Number} parentId The parent ID
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
const shouldFetchMeetings = (state, parentType, parentId) => {
  if (!PARENT_TYPES.includes(parentType)) {
    throw new Error(`Invalid parentType: ${parentType}`);
  }

  const meetings = getMeetings(state, parentType, parentId);

  if (!meetings) {
    return true;
  }

  if (meetings.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (meetings.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !meetings.has('ids') || Boolean(meetings.getIn(['_meta', 'isInvalidated']))
  );
};

export default shouldFetchMeetings;

import { connect } from 'react-redux';
import getEmployeeCountYear from '../selectors/getEmployeeCountYear';

/**
 * A Higher order component that sorts a list of
 * employeeCountIds in reverse chronological order by
 * year.
 */
export default connect(
  (state, { employeeCountIds }) => ({
    employeeCountIds:
      employeeCountIds &&
      employeeCountIds
        .sortBy(employeeCountId => [
          getEmployeeCountYear(state, employeeCountId),
        ])
        .reverse(),
  }),
  {},
);

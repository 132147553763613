const currencySymbol = currency => {
  if (currency === 'GBP') {
    return '£';
  } else if (currency === 'EUR') {
    return '€';
  } else {
    return '$';
  }
};

const chartTitle = view => {
  if (view === 'outstanding') {
    return 'Outstanding';
  } else {
    return 'Paid';
  }
};

export { chartTitle, currencySymbol };

import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';

import RejectionReasonSelectField from './RejectionReasonSelectField';

/**
 * A field for creating a rejection.
 * Includes a "rejection reason" dropdown selector and a comment field.
 */
class RejectionField extends PureComponent {
  static createFieldState(
    name = 'candidacy',
    { defaultRejectionReason, rejection },
  ) {
    const values = fromJS({
      rejection_reason: null,
      rejection_comment: '',
    }).merge(rejection);

    return FieldState.createNested(name, [
      RejectionReasonSelectField.createFieldState(
        'rejection_reason',
        values.get('rejection_reason') || defaultRejectionReason,
      ),
      TextAreaField.createFieldState(
        'rejection_comment',
        values.get('rejection_comment'),
      ),
    ]);
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const { fieldState, ...rest } = this.props;

    return (
      <div className='RejectionField'>
        <RejectionReasonSelectField
          {...rest}
          fieldState={fieldState.getNestedField('rejection_reason')}
          key='rejection_reason'
          onChange={this.handleFieldChange}
        />
        <TextAreaField
          {...rest}
          fieldState={fieldState.getNestedField('rejection_comment')}
          key='rejection_comment'
          label='Comments'
          onChange={this.handleFieldChange}
        />
      </div>
    );
  }
}

RejectionField.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RejectionField;

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import TagList from 'modules/core/components/TagList';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import GenderName from 'modules/tenant/components/GenderName';
import SexualOrientationName from 'modules/tenant/components/SexualOrientationName';
import DisabilityStatusName from 'modules/tenant/components/DisabilityStatusName';
import VeteranStatusName from 'modules/tenant/components/VeteranStatusName';
import isCandidateUser from 'modules/auth/selectors/isCandidateUser';
import SourceIndicator from 'modules/contact-diversity/components/SourceIndicator';
import SelfReportedIndicator from 'modules/contact-diversity/components/SelfReportedIndicator';
import { getSelfReportedTooltip } from 'modules/contact-diversity/components/ContactDiversityField';

/**
 * Renders a contact's diversity related fields.
 */
const ContactDiversityView = ({
  contact,
  hasDemographicSource,
  hasDiversity,
  hasDiversityBirthYear,
}) => {
  const candidateName = contact.get('full_name');
  const isCandidate = useSelector(state => isCandidateUser(state));
  const currentYear = new Date().getFullYear();
  const latestUpdates = contact.get('latest_updates')?.toJS() ?? {};
  const {
    disability_status_id,
    gender_id,
    race_ethnicity_updated_at,
    sexual_orientation_id,
    veteran_status_id,
  } = latestUpdates;

  const genderSelfReported = contact.get('gender_self_reported');
  const raceEthnicitySelfReported = contact.get('race_ethnicity_self_reported');
  const disabilityStatusSelfReported = contact.get(
    'disability_status_self_reported',
  );
  const sexualOrientationSelfReported = contact.get(
    'sexual_orientation_self_reported',
  );
  const veteranStatusSelfReported = contact.get('veteran_status_self_reported');

  const raceEthnicityNames = useSelector(state =>
    contact
      .get('race_ethnicities')
      .map(raceEthnicityId =>
        getEntityProperty(state, 'raceEthnicity', raceEthnicityId, 'name'),
      ),
  )
    .toArray()
    .filter(name => name !== undefined);

  const genderSourceNames = useSelector(state =>
    contact
      .get('gender_sources')
      ?.map(genderSourceId =>
        getEntityProperty(state, 'demographicSources', genderSourceId, 'name'),
      ),
  )?.toArray();

  const raceEthnicitySourceNames = useSelector(state =>
    contact
      .get('race_ethnicity_sources')
      ?.map(raceEthnicitySourceId =>
        getEntityProperty(
          state,
          'demographicSources',
          raceEthnicitySourceId,
          'name',
        ),
      ),
  )?.toArray();

  const veteranStatusSourceNames = useSelector(state =>
    contact
      .get('veteran_status_sources')
      ?.map(veteranStatusSourceId =>
        getEntityProperty(
          state,
          'demographicSources',
          veteranStatusSourceId,
          'name',
        ),
      ),
  )?.toArray();

  return (
    <div className='ContactDiversityView row'>
      {hasDiversity && Number.isFinite(contact.get('gender')) && (
        <div className='col-sm-6'>
          <LabeledContent
            className='ContactDiversityView__gender'
            label='Gender'
          >
            <div>
              {!isCandidate && genderSelfReported ? (
                <SelfReportedIndicator
                  tooltip={getSelfReportedTooltip(
                    candidateName,
                    isCandidate,
                    genderSelfReported,
                    'gender',
                    gender_id?.created_at,
                  )}
                />
              ) : null}
              <GenderName genderId={contact.get('gender')} />
            </div>
            {!isCandidate &&
            hasDemographicSource &&
            genderSourceNames?.length > 0 ? (
              <div className='u-flex u-flexAlign-c'>
                <SourceIndicator />
                <TagList
                  className='ContactDiversityView__race_ethnicity-list'
                  tags={genderSourceNames}
                />
              </div>
            ) : null}
          </LabeledContent>
        </div>
      )}
      {hasDiversity && raceEthnicityNames.length > 0 ? (
        <div className='col-sm-6'>
          <LabeledContent
            className='ContactDiversityView__raceEthnicity'
            label='Race-Ethnicity'
          >
            <div className='u-flex u-flexAlign-c'>
              {!isCandidate && raceEthnicitySelfReported ? (
                <SelfReportedIndicator
                  tooltip={getSelfReportedTooltip(
                    candidateName,
                    isCandidate,
                    raceEthnicitySelfReported,
                    'race/ethnicity',
                    race_ethnicity_updated_at?.created_at,
                  )}
                />
              ) : null}
              <TagList tags={raceEthnicityNames} />
            </div>
            {!isCandidate &&
            hasDemographicSource &&
            raceEthnicitySourceNames?.length > 0 ? (
              <div className='u-flex u-flexAlign-c'>
                <SourceIndicator />
                <TagList tags={raceEthnicitySourceNames} />
              </div>
            ) : null}
          </LabeledContent>
        </div>
      ) : null}

      {hasDiversity && contact.get('sexual_orientation') && (
        <div className='col-sm-6'>
          <LabeledContent
            className='ContactDiversityView__sexualOrientation'
            label='Sexual Orientation'
          >
            {!isCandidate && sexualOrientationSelfReported ? (
              <SelfReportedIndicator
                tooltip={getSelfReportedTooltip(
                  candidateName,
                  isCandidate,
                  sexualOrientationSelfReported,
                  'sexual orientation',
                  sexual_orientation_id?.created_at,
                )}
              />
            ) : null}
            <SexualOrientationName id={contact.get('sexual_orientation')} />
          </LabeledContent>
        </div>
      )}

      {hasDiversityBirthYear && contact.get('birth_year') && (
        <div className='col-sm-6'>
          <LabeledContent
            className='ContactDiversityView__birthYear'
            label='Birth Year'
          >
            {`${contact.get('birth_year')} (${
              currentYear - contact.get('birth_year')
            } years old)`}
          </LabeledContent>
        </div>
      )}

      {hasDiversity && Number.isFinite(contact.get('disability_status')) && (
        <div className='col-sm-6'>
          <LabeledContent
            className='ContactDiversityView__disabilityStatus'
            label='Disability Status'
          >
            {!isCandidate && disabilityStatusSelfReported ? (
              <SelfReportedIndicator
                tooltip={getSelfReportedTooltip(
                  candidateName,
                  isCandidate,
                  disabilityStatusSelfReported,
                  'disability status',
                  disability_status_id?.created_at,
                )}
              />
            ) : null}
            <DisabilityStatusName
              disabilityStatusId={contact.get('disability_status')}
            />
          </LabeledContent>
        </div>
      )}

      {hasDiversity && Number.isFinite(contact.get('veteran_status')) && (
        <div className='col-sm-6'>
          <LabeledContent
            className='ContactDiversityView__veteranStatus'
            label='Veteran Status'
          >
            <div className='u-flex u-flexAlign-c'>
              {!isCandidate && veteranStatusSelfReported ? (
                <SelfReportedIndicator
                  tooltip={getSelfReportedTooltip(
                    candidateName,
                    isCandidate,
                    veteranStatusSelfReported,
                    'veteran status',
                    veteran_status_id?.created_at,
                  )}
                />
              ) : null}
              <VeteranStatusName
                veteranStatusId={contact.get('veteran_status')}
              />
            </div>
            {!isCandidate &&
            hasDemographicSource &&
            veteranStatusSourceNames?.length > 0 ? (
              <div className='u-flex u-flexAlign-c'>
                <SourceIndicator />
                <TagList
                  className='ContactDiversityView__race_ethnicity-list'
                  tags={veteranStatusSourceNames}
                />
              </div>
            ) : null}
          </LabeledContent>
        </div>
      )}
    </div>
  );
};

ContactDiversityView.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    birth_year: PropTypes.number,
    disabilityStatus: PropTypes.number,
    gender: PropTypes.number,
    raceEthnicity: PropTypes.number,
    veteranStatus: PropTypes.number,
  }),

  /**
   * True to display demographic source fields
   */
  hasDemographicSource: PropTypes.bool,
  /**
   * True to display diversity section on contact page
   */
  hasDiversity: PropTypes.bool,

  /**
   * True to display diversity section on contact page
   */
  hasDiversityBirthYear: PropTypes.bool,
};

/**
 * Determines if the component will render anything when given the contact specified.
 * @param {Object} options
 * @param {Map} contact The contact record.
 */
ContactDiversityView.willRender = ({ contact }) => {
  return Boolean(
    contact &&
      (contact.get('gender') ||
        contact.get('gender_sources')?.size > 0 ||
        contact.get('race_ethnicities').size > 0 ||
        contact.get('race_ethnicity_sources')?.size > 0 ||
        contact.get('sexual_orientation') ||
        contact.get('disability_status') ||
        contact.get('veteran_status') ||
        contact.get('veteran_status_sources')?.size > 0 ||
        contact.get('birth_year')),
  );
};

export default compose(
  setDisplayName('ContactDiversityView(enhanced)'),
  setStatic('willRender', ContactDiversityView.willRender),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  withFeatureCheck('feature.demographic_source', 'hasDemographicSource'),
  withFeatureCheck('feature.diversity.birth_year', 'hasDiversityBirthYear'),
  withFeatureCheck('feature.diversity', 'hasDiversity'),
  branch(
    ({ contact }) => !ContactDiversityView.willRender({ contact: contact }),
    renderNothing,
  ),
)(ContactDiversityView);

import { normalize } from 'normalizr';
import notificationSchema from 'modules/notifications/schema';
import url from 'url';
import {
  NOTIFICATIONS_FETCH_START,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH_FAILURE,
  NOTIFICATIONS_CATEGORY_ALL,
  BASE_URL,
} from '../../domains/notifications/constants';
import createAjaxAction from '../createAjaxAction';

const getUrl = ({ after, before, category }) => {
  const query = {
    global: true,
  };

  if (before) {
    query.before = before;
  }

  if (after) {
    query.after = after;
  }

  if (category !== NOTIFICATIONS_CATEGORY_ALL) {
    query[category] = true;
  }

  return url.format({
    pathname: BASE_URL,
    query: query,
  });
};

const fetchNotifications = createAjaxAction({
  getUrl: getUrl,
  start: NOTIFICATIONS_FETCH_START,
  success: NOTIFICATIONS_FETCH_SUCCESS,
  failure: NOTIFICATIONS_FETCH_FAILURE,
  createSuccessPayload: (payload, data) => ({
    ...payload,
    ...normalize(data.notifications || [], [notificationSchema]),
    data: data,
  }),
});

export default fetchNotifications;

import apiV1 from './index';

const connectionOption = apiV1.injectEndpoints({
  endpoints: builder => ({
    getConnectionOption: builder.query({
      query: () => ({
        url: 'connection_options',
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetConnectionOptionQuery } = connectionOption;

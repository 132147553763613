import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
  branch,
  renderNothing,
} from 'recompose';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';

/**
 * Renders the date for an interview record, based on the `date`, `start_time` and `timezone`
 * values.
 */
const InterviewDate = ({ date, time }) => (
  <div className='InterviewDate'>
    <span className='InterviewDate__date' key='date'>
      {date}{' '}
    </span>
    <span className='InterviewDate__time' key='time'>
      <span className='start-time' key='start'>
        {'at '}
        {time}
      </span>
    </span>
  </div>
);

InterviewDate.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('InterviewDate(enhanced)'),
  setPropTypes({
    interview: ImmutablePropTypes.mapContains({
      start_time: PropTypes.string.isRequired,
    }).isRequired,
  }),
  branch(({ interview }) => !interview.get('start_time'), renderNothing),
  withProps(({ interview }) => ({
    time: moment(interview.get('start_time'), moment.ISO_8601).format('LT'),
    date: moment(interview.get('start_time'), moment.ISO_8601).format('L'),
  })),
)(InterviewDate);

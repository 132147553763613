import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withProps,
  withStateHandlers,
} from 'recompose';
import TableView from 'modules/core/componentsLegacy/Table/TableView';
import { SORT_BY_COUNTRY } from '../constants';
import withAddressesListFetched from './withAddressesListFetched';
import withAddressIdsSorted from './withAddressIdsSorted';

/**
 * Renders a table of addresses
 * {@see columnDefinitions} for available column names, or to add additional columns to be
 * rendered by this table.
 */
export default compose(
  setDisplayName('AddressesTable'),
  setPropTypes({
    /**
     * The names of the columns to render.
     */
    columns: PropTypes.arrayOf(
      PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    ).isRequired,
    /**
     * The ID of the parent
     */
    parentId: PropTypes.number.isRequired,

    /**
     * The ID of the parent
     */
    parentType: PropTypes.string.isRequired,

    /**
     * The primary address ID for the parent
     */
    primaryAddressId: PropTypes.number,
  }),
  // Handle fetching of the addresses and pulling out the IDs as `addressIds`
  withAddressesListFetched,
  withProps(({ addressesList }) => ({
    addressIds: addressesList && addressesList.get('ids'),
    isFetching: addressesList && addressesList.getIn(['_meta', 'isFetching']),
  })),

  // Sorting, currently the table will initially sort by primary, then by country.
  // Users are not able to sort anything in the table for now
  withStateHandlers(
    {
      sortBy: SORT_BY_COUNTRY,
      sortAscending: true,
    },
    {
      onSortChange: () => (sortBy, sortAscending) => ({
        sortBy: sortBy,
        sortAscending: sortAscending,
      }),
    },
  ),
  withAddressIdsSorted,

  // Pull out the properties needed for the TableView component.
  mapProps(
    ({
      addressIds,
      columns,
      isFetching,
      onSortChange,
      parentId,
      primaryAddressId,
      sortAscending,
      sortBy,
      theme,
    }) => ({
      columns: columns,
      data: addressIds,

      // Render an empty state component when we don't have any addresses.
      emptyState: !isFetching &&
        addressIds &&
        addressIds.count() === 0 && {
          message: 'No addresses found',
        },

      // Show a loading indicator if we are fetching.
      loadingIndicator: isFetching && {},
      onSortChange: onSortChange,
      parentId: parentId,
      primaryAddressId: primaryAddressId,
      sortAscending: sortAscending,
      sortBy: sortBy,
      theme: theme,
    }),
  ),
)(TableView);

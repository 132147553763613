import React from 'react';
import PropTypes from 'prop-types';
import './QuickViewState.scss';

const IMAGE_URL = {
  avatarWithEnvelope: '/avatar_envelope.svg',
  rocketShip: '/rocketship.svg',
  starWithMagnifier: '/star_magnifier.svg',
  envelope: '/envelope.svg',
  screwAndWrench: '/screw_wrench.svg',
  cardAndScreen: '/card_screen.svg',
};

const QuickViewState = ({ heading, image, subHeading }) => (
  <div className='u-textAlign-c u-padding-32'>
    <img alt='' className='QuickViewState__image' src={IMAGE_URL[image]} />
    <h2 className='u-marginBottom-16'>{heading}</h2>
    <p className='u-textColor-gray60'>{subHeading}</p>
  </div>
);

QuickViewState.propTypes = {
  heading: PropTypes.node,
  image: PropTypes.oneOf(Object.keys(IMAGE_URL)),
  subHeading: PropTypes.node,
};

export const QuickViewEmptyState = props => <QuickViewState {...props} />;
QuickViewEmptyState.propTypes = QuickViewState.propTypes;

export const QuickViewErrorState = props => <QuickViewState {...props} />;
QuickViewErrorState.propTypes = QuickViewState.propTypes;

QuickViewErrorState.defaultProps = {
  heading: (
    <>
      Sorry, it’s not you. <br />
      It’s us.
    </>
  ),
  image: 'screwAndWrench',
  subHeading: (
    <>
      We are experiencing an internal server issue. <br /> You can try reloading
      this page or get in touch with us.
    </>
  ),
};

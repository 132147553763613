import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import EmptyState from 'modules/core/componentsLegacy/EmptyState';
import getJobListingUrlBySearchId from 'modules/job-listings/selectors/getJobListingUrlBySearchId';
import ExternalLink from 'modules/links/ExternalLink';

/**
 * A specialized empty state component to use when there are no pending job applicants to display.
 */
export default compose(
  setDisplayName('JobApplicationListEmptyState'),
  setPropTypes({
    searchId: PropTypes.number,
  }),
  connect(
    (state, { searchId }) => ({
      jobListingUrl: getJobListingUrlBySearchId(state, searchId),
    }),
    {},
  ),
  withProps(({ jobListingUrl }) => ({
    message: (
      <div>
        <p className='lead'>No new job applications</p>
        {jobListingUrl && (
          <p className='small'>
            To promote your Thrive Apply listing, visit
            <span>
              {' '}
              <ExternalLink href={jobListingUrl}>
                {jobListingUrl}
              </ExternalLink>{' '}
            </span>
            and select one of the share options available to spread the word via
            LinkedIn, Facebook, Twitter or email.
          </p>
        )}
      </div>
    ),
  })),
)(EmptyState);

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BottomPanel from '@thrivetrm/ui/components/BottomPanel';
import ButtonDestroy from '@thrivetrm/ui/components/ButtonDestroy';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import PluralText from '@thrivetrm/ui/components/PluralText';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import routes from 'modules/routing/routes';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import { useApiDelete } from '@thrivetrm/ui/hooks/useApi';
import {
  requestSearchRequestList,
  setCurrentPage,
  setSelectedRecords,
} from '../searchRequestSlice';

const SearchRequestIndexBulkActions = () => {
  const dispatch = useDispatch();

  const {
    currentPage,
    recordList,
    selectedRecordIds,
    totalResults,
  } = useSelector(state => state.searchRequest);

  const [
    isConfirmationDialogOpen,
    openConfirmationDialog,
    closeConfirmationDialog,
  ] = useToggle(false);

  const selectedRecordCount = selectedRecordIds.length || 0;
  const formattedRecordLabel =
    selectedRecordIds.length > 1 ? 'Search Requests' : 'Search Request';
  const formattedButtonLabel =
    selectedRecordIds.length > 1 ? 'Requests' : 'Request';

  const [deleteRecords, isDeletingRecords] = useApiDelete(
    routes.bulk_api_v1_search_requests(),
  );

  const handleDeleteRecords = () => {
    const hasSelectedAllRecordsOnPage =
      selectedRecordCount === recordList?.length;
    const hasSelectedAllTotalRecords = selectedRecordCount === totalResults;

    deleteRecords(
      { ids: selectedRecordIds },
      {
        onSuccess: () => {
          dispatch(
            toastSuccess(
              `${selectedRecordCount} ${formattedRecordLabel} deleted successfully`.toLowerCase(),
            ),
          );
          // When deleting all records on the last page of results, reset the current
          // page to the penultimate page to prevent "no records found" message
          if (
            hasSelectedAllRecordsOnPage &&
            !hasSelectedAllTotalRecords &&
            currentPage > 1
          ) {
            dispatch(setCurrentPage(currentPage - 1));
          }
          dispatch(setSelectedRecords([]));
          dispatch(requestSearchRequestList());
        },
        onError: error => {
          dispatch(toastError(error));
        },
      },
    );
  };

  return (
    <BottomPanel className='u-flexJustify-spaceBetween' isOpen={true}>
      <div>
        <PluralText
          case='lowerCase'
          quantity={selectedRecordCount}
          shouldIncludeQuantity={true}
          text={formattedRecordLabel}
        />{' '}
        selected
      </div>
      <>
        <ButtonDestroy
          isLoading={isDeletingRecords}
          label={`Delete ${formattedButtonLabel}`}
          onClick={openConfirmationDialog}
        />
        <ConfirmationDialog
          cancelLabel='No, Keep Working'
          confirmLabel={`Yes, Delete ${formattedButtonLabel}`}
          isOpen={isConfirmationDialogOpen}
          onClose={closeConfirmationDialog}
          onConfirm={handleDeleteRecords}
          title={`Delete ${formattedRecordLabel}?`}
        >
          <div>
            You are about to delete{' '}
            <PluralText
              case='lowerCase'
              quantity={selectedRecordCount}
              shouldIncludeQuantity={true}
              text={formattedRecordLabel}
            />
            .
          </div>
          <div>
            <b>This cannot be undone</b>
          </div>
          <div>Are you sure you want to proceed?</div>
        </ConfirmationDialog>
      </>
    </BottomPanel>
  );
};

export default SearchRequestIndexBulkActions;

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ContactAverageRatingModalButton from 'modules/candidacies/components/ratings/ContactAverageRatingModalButton';
import withContactActions from 'modules/contacts/components/withContactActions';
import ensureTenantOptionsFetched from 'modules/tenant/components/ensureTenantOptionsFetched';
import { withStateProvider } from './app/StateProvider';

/**
 * A container component for rendering a ContactRatingsModalButton, which shows a contact's
 * average rating, and when clicked shows the individual ratings that make up that average.
 * If the contact also has candidacy assessments, there is a link shown in the footer of the
 * modal that, when clicked, will show the individual assessments.
 */
export default compose(
  setDisplayName('ContactRatingsModalButton(container)'),
  setPropTypes({
    /**
     * The data to inject into our state - this is the minimum amount of information needed
     * to render the button. When the modal is opened, additional data will be fetched as needed.
     */
    contact: PropTypes.shape({
      average_rating: PropTypes.number,
      id: PropTypes.number.isRequired,
      isUserRated: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      ratings_count: PropTypes.number.isRequired,
    }).isRequired,
  }),
  // Attach our store/state to the react context tree.
  withStateProvider,
  // Needed for calling `initializeContact`, below.
  withContactActions,
  ensureTenantOptionsFetched,
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const { contact, contactActions } = this.props;
      contactActions.initializeContact({ contact: contact });
    },
  }),
  mapProps(({ contact }) => ({ contactId: contact.id })),
)(ContactAverageRatingModalButton);

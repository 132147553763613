import getOptions from './getOptions';

/**
 * Determines if the assessments options for the current tenant need to be fetched from the server
 * @param {*} state
 * @returns true if the assessments options need to be fetched from the server; Othewise, false.
 */
export default state => {
  const options = getOptions(state);

  if (!options) {
    return true;
  }

  if (options.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  return !options.has('ids') && !options.hasIn(['_meta', 'error']);
};

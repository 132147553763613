import PropTypes from 'prop-types';
import React from 'react';
import { GOOGLE_HIRE_LINK_PREFIX } from '../constants';

import ContactExternalLink from './links/ContactExternalLink';

/**
 * Renders the external_id link icon of given linkType for a contact.
 */
const ContactExternalIdLink = ({ externalId, icon, prefix }) => (
  <ContactExternalLink
    icon={icon}
    target='_blank'
    url={`${prefix}${externalId}`}
  />
);

ContactExternalIdLink.propTypes = {
  /**
   * the externalId to render in the URL
   */
  externalId: PropTypes.string.isRequired,

  /**
   * the icon that ContactIconLink will render
   */
  icon: PropTypes.string,

  /**
   * the prefix of the URL before the ID
   */
  prefix: PropTypes.string,
};

ContactExternalIdLink.defaultProps = {
  icon: 'fa-link',
  prefix: GOOGLE_HIRE_LINK_PREFIX,
};

export default ContactExternalIdLink;

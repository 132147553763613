import { connect } from 'react-redux';
import getOutreach from '../selectors/getOutreach';

/**
 * A higher order component which maps an `outreachId` prop value to it's outreach record.
 */
export default connect(
  (state, { outreachId }) => ({
    outreach: getOutreach(state, outreachId),
  }),
  {},
);

import { connect } from 'react-redux';
import getCandidacyStageId from '../../selectors/getCandidacyStageId';

/**
 * A higher order component which maps a `candidacyId` prop value to the ID of it's current stage.
 */
export default connect(
  (state, { candidacyId }) => ({
    stageId: getCandidacyStageId(state, candidacyId, 'candidacy_stage'),
  }),
  {},
);

import createEntity from 'modules/entities/actions/createEntity';
import routes from 'modules/routing/routes';
import jobListingSchema from '../schema';

/**
 * An action for creating a job listing.
 * @param {Object} payload
 * @param {Number} payload.searchId The ID of the search this listing belongs to.
 * @param {Object} payload.job_listing The job listing properties
 * @param {Number} payload.job_listing.jobListingId The job listing ID
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 * @return {Object} The create action.
 */
export default ({
  job_listing: { jobListingId, ...jobListing },
  searchId,
  ...rest
}) =>
  createEntity({
    ...rest,
    body: {
      job_listing: {
        ...jobListing,
        id: jobListingId,
      },
    },
    entityType: jobListingSchema.key,
    id: searchId,
    responseSchema: { job_listing: jobListingSchema },
    url: routes.api_v1_search_job_listings({ searchId: searchId }),
  });

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import { useDeleteReferenceMutation } from 'services/apiV1/references';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import invalidateCandidacyStatus from 'modules/candidacies/actions/invalidateCandidacyStatus';

const DeleteReferenceDialog = ({ candidacyId, referenceId }) => {
  const dispatch = useDispatch();
  const [deleteReference] = useDeleteReferenceMutation();
  const [isDialogOpen, openDialog, closeDialog] = useToggle(false);

  const handleDelete = () => {
    deleteReference(referenceId)
      .unwrap()
      .then(() => {
        closeDialog();
        dispatch(toastSuccess('Successfully deleted reference'));
        if (candidacyId) {
          dispatch(invalidateCandidacyStatus({ id: candidacyId }));
        }
      })
      .catch(() => {
        closeDialog();
        dispatch(toastError('Could not delete the reference'));
      });
  };
  return (
    <>
      <ButtonLink label='Delete' onClick={openDialog} size='small' />
      <ConfirmationDialog
        cancelLabel='No, Keep Reference'
        confirmLabel='Yes, Delete Reference'
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onConfirm={handleDelete}
        title='Delete Reference'
      >
        <p>Are you sure you want to delete this reference?</p>
        <b>This action cannot be undone.</b>
      </ConfirmationDialog>
    </>
  );
};

DeleteReferenceDialog.propTypes = {
  candidacyId: PropTypes.number,
  referenceId: PropTypes.number.isRequired,
};

export default DeleteReferenceDialog;

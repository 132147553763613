import debug from '../lib/debug';
import addRemoveToggle from './add_remove_toggle';
import autocomplete from './autocomplete';
import buttons from './buttons';
import datepicker from './datepicker';
import filters from './filters';
import globalSearch from './global_search';
import intercom from './intercom';
import invite from './invite';
import tooltips from './tooltips';

const logger = {
  once: debug('thrive:init-once'),
  always: debug('thrive:init-always'),
};

const initializers = {
  add_remove_toggle: addRemoveToggle,
  autocomplete: autocomplete,
  buttons: buttons,
  datepicker: datepicker,
  filters: filters,
  global_search: globalSearch,
  intercom: intercom,
  invite: invite,
  tooltips: tooltips,
};

const initialize = initializerType => {
  const initializedModules = [];
  Object.keys(initializers).forEach(initializerKey => {
    const initializerModule = initializers[initializerKey];
    if (initializerModule[initializerType]) {
      initializerModule[initializerType]();
      initializedModules.push(initializerKey);
    }
  });
  logger[initializerType](initializedModules.join(', '));
};

// We have a distinction between initializers that should fire 'once' and 'always'
// as a holdover from Turbolinks, where some JS would need to run exactly one time
// for each full page refresh, and some JS would need to run every time a Turbolinks
// 'load' event was fired (which meant a new blob of HTML was just injected into the
// DOM). Now that we don't use Turbolinks, 'once' and 'always' are equivalent, and
// we call them both at the same time on page load. Ideally we should consolidate
// 'once' and 'always' into a single initializer function in all of these modules,
// but that is outside of the scope of the current task at hand.
document.addEventListener('DOMContentLoaded', () => {
  initialize('once');
  initialize('always');
});

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import DateTime from 'modules/datetime/components/DateTime';
import mapResidencyIdToResidency from './mapResidencyIdToResidency';
import deleteResidencyActionCreator from '../actions/deleteResidency';

/**
 * Renders a single residency record as a list item.
 * Includes a button that allows deleting the residency token.
 */
const ResidencyListItem = ({
  className,
  handleDelete,
  residency,
  residencyType,
}) => {
  return residencyType === residency.get('type') ? (
    <li
      className={classnames('ResidencyListItem', 'list-group-item', className, {
        'ResidencyListItem--deleted': residency.getIn(['_meta', 'isDeleted']),
        'ResidencyListItem--deleting': residency.getIn(['_meta', 'isDeleting']),
      })}
    >
      {!residency.getIn(['_meta', 'isDeleted']) && (
        <button
          className='ResidencyListItem__deleteButton btn btn-link btn-xs revoke'
          disabled={residency.getIn(['_meta', 'isDeleting'])}
          onClick={handleDelete}
          type='button'
        >
          <i
            className={classnames('fa', {
              'fa-trash': !residency.getIn(['_meta', 'isDeleting']),
              'fa-spin': residency.getIn(['_meta', 'isDeleting']),
              'fa-spinner': residency.getIn(['_meta', 'isDeleting']),
              'fa-ban': residency.getIn(['_meta', 'isDeleted']),
            })}
          />{' '}
          Remove
        </button>
      )}
      <div>{residency.get('name')}</div>
      created on <DateTime format='L LT' value={residency.get('created_at')} />
    </li>
  ) : null;
};

ResidencyListItem.propTypes = {
  /**
   * Optional additional classname to apply to the outer <li> element
   */
  className: PropTypes.string,

  /**
   * Called when the delete button is clicked
   * */
  handleDelete: PropTypes.func.isRequired,

  /**
   * The residency record.
   */
  residency: ImmutablePropTypes.mapContains({
    name: PropTypes.string,
  }),

  residencyType: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('ResidencyListItem(enhanced)'),
  setPropTypes({
    /**
     * The ID of the residency record to render.
     */
    residencyId: PropTypes.number.isRequired,
  }),
  mapResidencyIdToResidency,
  connect(null, dispatch => ({
    deleteResidency: bindActionCreators(deleteResidencyActionCreator, dispatch),
  })),
  withHandlers({
    handleDelete: ({ deleteResidency, residencyId }) => e => {
      e.preventDefault();
      deleteResidency({ id: residencyId });
    },
  }),
)(ResidencyListItem);

import React from 'react';
import { compose, setDisplayName, setStatic, withProps } from 'recompose';
import ImageUploadField from 'modules/forms/components/ImageUploadField';
import CompanyLogo from './CompanyLogo';

export default compose(
  setDisplayName('CompanyLogoField'),
  setStatic('createFieldState', ImageUploadField.createFieldState),
  withProps(() => ({
    imageLabel: 'Logo',
    // eslint-disable-next-line react/prop-types
    imageComponent: ({ src }) => <CompanyLogo src={src} thumbnail={true} />,
    emptyComponent: () => <i className='fa fa-building' />,
  })),
)(ImageUploadField);

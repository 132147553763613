import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import EditableSection from 'modules/forms/components/EditableSection';
import ContactConnectionsForm from './ContactConnectionsForm';
import ContactConnectionsView from './ContactConnectionsView';

/**
 * Renders an EditableSection that displays and allows editing of a contact's "Connections" fields.
 */
export default compose(
  setDisplayName('ContactConnectionsEditableSection'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  mapProps(({ contact, contactId }) => ({
    contactId: contactId,
    children: ContactConnectionsView.willRender({ contact: contact }) && (
      <ContactConnectionsView contactId={contactId} />
    ),
    className: 'ContactConnectionsEditableSection',
    formComponent: ContactConnectionsForm,
    title: 'Connections',
  })),
)(EditableSection);

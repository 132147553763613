import debug from '../lib/debug';
import companiesPage from './companies';
import contactsPage from './contacts';
import dashboardsPage from './dashboards';
import searchesPage from './searches';
import targetCompaniesPage from './target_companies';
import networksPage from './networks';
import usersPage from './users';
import introductionsPage from './introductions';
import offersPage from './offers';
import sessionsPage from './sessions';
import searchesCommissionManagementIndexPage from './searches/commission_management/index';
import searchesCommissionManagementShowPage from './searches/commission_management/show';
import searchesCommissionDetailsIndexPage from './searches/commission_details/index';
import candidatesPage from './candidates';

const logger = {
  prepared: debug('thrive:prepare'),
  initialized: debug('thrive:pages'),
};

// This will become Thrive.pages
const pages = {
  companies: companiesPage,
  contacts: contactsPage,
  'contacts/saved_views': contactsPage,
  dashboards: dashboardsPage,
  searches: searchesPage,
  talent_pools: searchesPage,
  pipelines: searchesPage,
  target_companies: targetCompaniesPage,
  networks: networksPage,
  users: usersPage,
  introductions: introductionsPage,
  offers: offersPage,
  sessions: sessionsPage,
  'searches/commission_management/index': searchesCommissionManagementIndexPage,
  'searches/commission_management/show': searchesCommissionManagementShowPage,
  'searches/commission_details/index': searchesCommissionDetailsIndexPage,
  candidates: candidatesPage,
};

// Prepare functions only ever run once
const prepare = (initializer, page, args) => {
  if (initializer.prepare && !initializer.hasBeenPrepared) {
    logger.prepared(page);
    initializer.prepare(...args);
    initializer.hasBeenPrepared = true;
  }
};

// Initialize functions run every time the page is visited
const initialize = (initializer, page, args) => {
  if (initializer.initialize) {
    logger.initialized(page);
    initializer.initialize(...args);
  }
};

// Setup a page visit based on data attributes
const initializePage = function (data) {
  const initializer = pages[data.page];
  if (initializer) {
    prepare(initializer, data.page, data.args);
    return initialize(initializer, data.page, data.args);
  } else {
    return debug(`${data.page} not found!`);
  }
};

document.addEventListener('DOMContentLoaded', () =>
  $('[data-page]').each((_, element) => {
    return initializePage($(element).data());
  }),
);

export default pages;

import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import {
  CONTACT_METHOD_LIST_FETCH_START,
  CONTACT_METHOD_LIST_FETCH_SUCCESS,
  CONTACT_METHOD_LIST_FETCH_FAILURE,
} from './actionTypes';
import { contactMethodSchema } from '../../schema';

/**
 * Creates an action for fetching a list of all contact methods
 * @param {Object} payload
 */
export default compose(
  fetchAction,
  normalizeResponse({ contact_methods: [contactMethodSchema] }),
  createAsyncActions({
    startActionType: CONTACT_METHOD_LIST_FETCH_START,
    successActionType: CONTACT_METHOD_LIST_FETCH_SUCCESS,
    failureActionType: CONTACT_METHOD_LIST_FETCH_FAILURE,
  }),
  withOptions({
    url: routes.api_v1_outreach_contact_methods(),
  }),
);

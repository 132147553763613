import routes from 'modules/routing/routes';
import createEntity from 'modules/entities/actions/createEntity';
import residencySchema from '../schema';

/**
 * An action for creating a residency
 * @param {Object} payload
 * @param {Object} payload.residency The residency record to create
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ residency, transactionId }) =>
  createEntity({
    body: { residency: residency },
    entityType: residencySchema.key,
    responseSchema: { residency: residencySchema },
    url: routes.api_residencies(),
    transactionId: transactionId,
  });

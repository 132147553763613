import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OffLimitsIndicator from 'modules/off-limits/OffLimitsIndicator';

const CompanySnapshot = ({
  className,
  id,
  name,
  offLimitsReason,
  offLimitsSeverity,
  url,
  ...props
}) => {
  const containerClass = classNames(className, 'u-flex', 'u-flexAlign-c');

  return (
    <div {...props} className={containerClass} data-testid='CompanySnapshot'>
      <div>
        <b>{url ? <a href={url}>{name}</a> : <span>{name}</span>}</b>

        {offLimitsReason && (
          <OffLimitsIndicator
            className='u-block'
            reason={offLimitsReason}
            recordId={id}
            recordName={name}
            recordType='company'
            severity={offLimitsSeverity}
          />
        )}
      </div>
    </div>
  );
};

CompanySnapshot.defaultProps = {
  className: null,
  offLimitsReason: null,
  offLimitsSeverity: null,
  url: null,
};

CompanySnapshot.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  offLimitsReason: PropTypes.string,
  offLimitsSeverity: PropTypes.string,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CompanySnapshot;

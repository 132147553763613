import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  pure,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';
import SortByDropdownMenu from 'modules/core/componentsLegacy/SortByDropdownMenu';
import canViewLastContactedDate from '../selectors/canViewLastContactedDate';
import {
  ASCENDING_SORT_ONLY,
  SORT_BY_TYPES,
  SORT_BY_LABELS,
  SORT_BY_LAST_CONTACTED,
} from '../constants';

/**
 * Renders a dropdown menu for sorting a list of candidacies.
 */
export default compose(
  setDisplayName('CandidacySortByDropdownMenu'),
  setPropTypes({
    /**
     * True when current sort is ascending; false if descending
     */
    isAscending: PropTypes.bool.isRequired,

    /**
     * Called when the sort type or direction is changed.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * An array of available sort options to display
     */
    sortOptions: PropTypes.arrayOf(PropTypes.oneOf(SORT_BY_TYPES)).isRequired,

    /**
     * The current sort value.
     */
    value: PropTypes.oneOf(SORT_BY_TYPES).isRequired,
  }),
  connect(
    state => ({
      allowLastContacted: canViewLastContactedDate(state),
    }),
    {},
  ),
  mapProps(
    ({ allowLastContacted, isAscending, onChange, sortOptions, value }) => {
      const values = sortOptions.filter(
        sortBy => allowLastContacted || sortBy !== SORT_BY_LAST_CONTACTED,
      );

      return {
        hasSortIndicator: !ASCENDING_SORT_ONLY.includes(value),
        isAscending: isAscending,
        onChange: onChange,

        // Conver the flat array of sort options to key-label pairs
        options: values.reduce(
          (options, val) => ({
            ...options,
            [val]: SORT_BY_LABELS[val],
          }),
          {},
        ),

        // Ensure that the value is one of the available sort options.
        value: values.indexOf(value) >= 0 ? value : sortOptions[0],
      };
    },
  ),
  pure,
)(SortByDropdownMenu);

import * as candidacyConstants from 'modules/candidacies/constants';

/**
 * The mode for rendering a list not grouped by stage
 */
export const LIST_MODE_NOT_GROUPED = 'LIST_MODE_NOT_GROUPED';

/**
 * The mode for rendering the list grouped by stage
 */
export const LIST_MODE_GROUPED = 'LIST_MODE_GROUPED';

/**
 * The mode for rendering the large candidacy card
 */
export const LARGE_CARD_MODE = 'LARGE_CARD_MODE';

/**
 * The mode for rendering the small candidacy card
 */
export const SMALL_CARD_MODE = 'SMALL_CARD_MODE';

/**
 * The default list mode to render.
 */
export const LIST_MODE_DEFAULT = LIST_MODE_GROUPED;

/**
 * The default card mode to render.
 */
export const CARD_MODE_DEFAULT = LARGE_CARD_MODE;

/**
 * All possible valid list modes.
 */
export const LIST_MODES = [LIST_MODE_GROUPED, LIST_MODE_NOT_GROUPED];

/**
 * All possible valid card modes.
 */
export const CARD_MODES = [SMALL_CARD_MODE, LARGE_CARD_MODE];

/**
 * The labels used to describe each list mode.
 */
export const LIST_MODE_LABELS = {
  [LIST_MODE_GROUPED]: 'Grouped by Stage',
  [LIST_MODE_NOT_GROUPED]: 'View in List',
};

/**
 * The labels used to describe each card mode.
 */
export const CARD_MODE_LABELS = {
  [SMALL_CARD_MODE]: 'Compact Card Design',
  [LARGE_CARD_MODE]: 'Expanded Card Design',
};

/**
 * The available sort types for each list mode.
 * (The "full" list mode, for example, does not allow sorting by stage)
 */
export const LIST_MODES_SORT_BY_TYPES = {
  [LIST_MODE_NOT_GROUPED]: [
    candidacyConstants.SORT_BY_RANK,
    candidacyConstants.SORT_BY_STAGE,
    candidacyConstants.SORT_BY_NAME,
    candidacyConstants.SORT_BY_PRIORITY,
    candidacyConstants.SORT_BY_COMPANY_NAME,
    candidacyConstants.SORT_BY_LOCATION,
    candidacyConstants.SORT_BY_AGE,
    candidacyConstants.SORT_BY_LAST_CONTACTED,
  ],
  [LIST_MODE_GROUPED]: [
    candidacyConstants.SORT_BY_RANK,
    candidacyConstants.SORT_BY_NAME,
    candidacyConstants.SORT_BY_PRIORITY,
    candidacyConstants.SORT_BY_COMPANY_NAME,
    candidacyConstants.SORT_BY_LOCATION,
    candidacyConstants.SORT_BY_AGE,
    candidacyConstants.SORT_BY_LAST_CONTACTED,
  ],
};

/**
 * THe default sorting for each list mode.
 */
export const LIST_MODES_SORT_DEFAULTS = {
  [LIST_MODE_NOT_GROUPED]: candidacyConstants.SORT_BY_RANK,
  [LIST_MODE_GROUPED]: candidacyConstants.SORT_BY_RANK,
};

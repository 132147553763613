import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  defaultProps,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import classnames from 'classnames';
import SidebarExpandToggle from './SidebarExpandToggle';
import { ensureContainerExpandedClass } from './layout';

const Sidebar = ({
  canExpand,
  children,
  className,
  isExpanded,
  onExpandChange,
  popover,
}) => (
  <div
    className={classnames('Sidebar', className, {
      'Sidebar--expanded': isExpanded,
    })}
  >
    {canExpand && (
      <SidebarExpandToggle isExpanded={isExpanded} onChange={onExpandChange} />
    )}
    <div className='Sidebar__content'>{children}</div>
    {popover && <div className='Sidebar__popover'>{popover}</div>}
  </div>
);

Sidebar.propTypes = {
  canExpand: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
  popover: PropTypes.node,
};

Sidebar.defaultProps = {
  canExpand: true,
  isExpanded: false,
};

export default compose(
  setDisplayName('Sidebar(enhanced)'),
  setPropTypes({
    canExpand: PropTypes.bool.isRequired,
    children: PropTypes.node,
    popover: PropTypes.node,
  }),
  defaultProps(Sidebar.defaultProps),

  // Manage the `isExpanded` state
  withState('isExpanded', 'setIsExpanded', false),
  withHandlers({
    onExpandChange: ({ setIsExpanded }) => isExpanded =>
      setIsExpanded(isExpanded),
  }),

  // Synchronize the container expanded class presence with the actual expanded state.
  lifecycle({
    UNSAFE_componentWillMount: function () {
      ensureContainerExpandedClass(this.props.isExpanded);
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      if (nextProps.isExpanded !== this.props.isExpanded) {
        ensureContainerExpandedClass(nextProps.isExpanded);
      }
    },
  }),
)(Sidebar);

import { compose, setDisplayName } from 'recompose';
import CommissionsReportModalButton from 'modules/commissions/components/report/CommissionsReportModalButton';
import { withStateProvider } from './app/StateProvider';

/**
 * A container component for rendering a CommissionsReportButton, which shows shows
 * a form allowing the user to generate a report on commissions
 */
export default compose(
  setDisplayName('CommissionsReportModalButton(container)'),
  // Attach our store/state to the react context tree.
  withStateProvider,
)(CommissionsReportModalButton);

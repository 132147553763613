import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { TargetCompanyCandidateCount } from './TargetCompanyCandidateCount';

export default compose(
  setDisplayName('NoTargetCompanyCandidateCount'),
  setPropTypes({
    value: PropTypes.number,
    onClick: PropTypes.func,
  }),
  withHandlers({
    // Override the onclick so that it gets called with no arguments.
    onClick: ({ onClick }) => () => {
      if (onClick) {
        onClick();
      }
    },
  }),
  mapProps(({ onClick, value }) => ({
    className: 'NoTargetCompanyCandidateCount',
    // Don't show the indicator here.
    indicatorPosition: null,
    onClick: onClick,
    value: value,
  })),
)(TargetCompanyCandidateCount);

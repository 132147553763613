import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  defaultProps,
  renderComponent,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { AutoSizer, List as VirtualList } from 'react-virtualized';

import withJobApplicationListFetched from '../withJobApplicationListFetched';
import mapSearchIdToJobApplicationList from '../mapSearchIdToJobApplicationList';
import withJobApplicationIdsFiltered from '../withJobApplicationIdsFiltered';
import withJobApplicationIdsSorted from '../withJobApplicationIdsSorted';

import JobApplicationListLoadingIndicator from './JobApplicationListLoadingIndicator';
import JobApplicationListErrorAlert from './JobApplicationListErrorAlert';
import JobApplicationListEmptyState from './JobApplicationListEmptyState';
import JobApplicationListFilteredEmptyState from './JobApplicationListFilteredEmptyState';
import JobApplicationListItem from './JobApplicationListItem';

import {
  SORT_ASCENDING_DEFAULT,
  SORT_BY_DEFAULT,
  SORT_BY_TYPES,
} from '../../constants';

/**
 * Renders a list of job applications using virtual scrolling
 */
const JobApplicationList = ({ jobApplicationIds, renderRow }) => (
  <div className='JobApplicationList'>
    <AutoSizer>
      {({ height, width }) => (
        <VirtualList
          height={height}
          // Passing in the IDs will cause the list to rerender when the list of IDs change.
          jobApplicationIds={jobApplicationIds}
          rowCount={jobApplicationIds ? jobApplicationIds.count() : 0}
          rowHeight={110}
          rowRenderer={renderRow}
          tabIndex={0}
          width={width}
        />
      )}
    </AutoSizer>
  </div>
);

JobApplicationList.propTypes = {
  /**
   * The list of IDs of the job applications to render.
   */
  jobApplicationIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),

  /**
   * A function that renders a single row in the list.
   */
  renderRow: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('JobApplicationList(enhanced)'),
  setPropTypes({
    /**
     * The current text the list is being filtered on.
     */
    filterText: PropTypes.string,

    /**
     * The search to render the candidacies for.
     */
    searchId: PropTypes.number.isRequired,

    /**
     * True to sort in ascending order; false to sort in descending order.
     * @type {[type]}
     */
    sortAscending: PropTypes.bool.isRequired,

    /**
     * The sort mode.
     */
    sortBy: PropTypes.oneOf(SORT_BY_TYPES).isRequired,
  }),

  defaultProps({
    filterText: null,
    sortAscending: SORT_ASCENDING_DEFAULT,
    sortBy: SORT_BY_DEFAULT,
  }),

  // Get the jobApplicationList and fetch it if needed.
  mapSearchIdToJobApplicationList,
  withJobApplicationListFetched,

  // Pull the jobApplicationIds off of the jobApplicationList and supply them as a prop for
  // sorting/filtering.
  withProps(({ jobApplicationList }) => ({
    jobApplicationIds: jobApplicationList && jobApplicationList.get('ids'),
    isFetching:
      jobApplicationList &&
      Boolean(jobApplicationList.getIn(['_meta', 'isFetching'])),
    hasError:
      jobApplicationList &&
      Boolean(jobApplicationList.getIn(['_meta', 'error'])),
  })),

  // Show a loading indicator while we're fetching the list.
  branch(
    ({ isFetching, jobApplicationIds }) =>
      isFetching && (!jobApplicationIds || jobApplicationIds.count() < 1),
    renderComponent(JobApplicationListLoadingIndicator),
  ),

  // If there was an error, render error info instead of the list
  branch(
    ({ hasError, jobApplicationIds }) =>
      hasError && (!jobApplicationIds || jobApplicationIds.count() < 1),
    renderComponent(JobApplicationListErrorAlert),
  ),

  // No applicants in the list -- render empty state instead of list.
  branch(
    ({ isFetching, jobApplicationIds, jobApplicationList }) =>
      !isFetching &&
      jobApplicationIds &&
      jobApplicationIds.count() === 0 &&
      jobApplicationList.getIn(['_meta', 'lastFetched']),
    renderComponent(JobApplicationListEmptyState),
  ),

  // Apply any filters.
  withJobApplicationIdsFiltered,

  // Any filters applied have filtered out all job listings.
  branch(
    ({ jobApplicationIds }) =>
      jobApplicationIds && jobApplicationIds.count() === 0,
    renderComponent(JobApplicationListFilteredEmptyState),
  ),

  // Sort the IDs.
  withJobApplicationIdsSorted,

  withHandlers({
    renderRow: ({ jobApplicationIds, searchId }) => row => (
      <JobApplicationListItem
        jobApplicationId={jobApplicationIds.get(row.index)}
        key={row.key}
        row={row}
        searchId={searchId}
      />
    ),
  }),
)(JobApplicationList);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { searchSchema } from '../schema';

export default (state, searchId) =>
  getEntityProperty(
    state,
    searchSchema.key,
    searchId,
    'use_desired_compensation',
  );

import { connect } from 'react-redux';
import getCandidateName from '../selectors/getCandidateName';

/**
 * A Higher order component that sorts a list of interviewIds in chronological order
 * of the interview's candidate name.
 */
export default connect(
  (state, { interviewIds }) => ({
    interviewIds:
      interviewIds &&
      interviewIds.sortBy(id =>
        (getCandidateName(state, id) || '').toLowerCase(),
      ),
  }),
  {},
);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import interviewSchema from '../schema';

/**
 * Gets the property from an interview record by the interview ID
 * @param {Object} state
 * @param {Number} interviewId The interviewId ID
 * @return {*} The property of the interview record.
 */
export default (state, interviewId, property) =>
  getEntityProperty(state, interviewSchema.key, interviewId, property);

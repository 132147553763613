export default selector => {
  const message = 'You have unsaved changes.';
  let submitting = false;

  $(selector).each((_i, element) => {
    const $form = $(element);

    $form.off('submit').on('submit', ({ target }) => {
      submitting = true;
      $(target).parents('form').data('dirty', false);
    });

    $form
      .off('input change', 'input, textarea, select')
      .on('input change', 'input, textarea, select', () => {
        $form.data('dirty', true);
      });
  });

  const pageHasDirtyForms = () => {
    let returnval = false;

    $(selector).each((_i, element) => {
      if ($(element).data('dirty')) {
        returnval = true;
      }
    });

    return returnval;
  };

  /* eslint-disable consistent-return */
  $(window)
    .off('beforeunload')
    .on('beforeunload', event => {
      if (!submitting && pageHasDirtyForms()) {
        // FF
        event.preventDefault();
        // Chromium
        event.returnValue = message;
        // Most browsers that support a custom message
        return message;
      }
    });

  $('button.resume').on('click', () => $(selector).data('dirty', false));

  $('a:contains("Cancel")').on('click', ({ element }) => {
    $(element).parents('form').data('dirty', false);
  });
};

import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';
import TimeLabel from 'modules/datetime/components/TimeLabel';
import getCompanyProperty from '../selectors/getCompanyProperty';

/**
 * Renders the last time a company record was updated.
 */
export default compose(
  setDisplayName('CompanyLastUpdatedTime'),
  setPropTypes({
    companyId: PropTypes.number,
  }),
  connect(
    (state, { companyId }) => ({
      time: getCompanyProperty(state, companyId, 'updated_at'),
    }),
    {},
  ),
  branch(({ time }) => !time, renderNothing),
  mapProps(({ companyId: _, ...rest }) => rest),
)(TimeLabel);

import fetchNextPage from './fetchNextPage';

function shouldFetchNextPage(state) {
  if (!state) {
    return false;
  }

  if (state.getIn(['meta', 'isFetching'])) {
    return false;
  }

  return state.getIn(['meta', 'hasMore']);
}

export default function fetchNextPageIfHasMore(notifications) {
  return dispatch => {
    if (shouldFetchNextPage(notifications)) {
      return dispatch(fetchNextPage(notifications));
    }

    return false;
  };
}

import React, { useState, useEffect } from 'react';
import Checkbox from 'modules/core/componentsLegacy/Checkbox';
import getTenantType from 'modules/tenant/selectors/getTenantType';
import { useSelector } from 'react-redux';
import { TENANT_TYPE_EXECUTIVE_SEARCH } from 'modules/tenant/constants';
import isAdmin from 'modules/auth/selectors/isAdmin';
import isSuperAdmin from 'modules/auth/selectors/isSuperAdmin';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { withStateProvider } from './app/StateProvider';

const filterOption = [
  { value: 'LeadInternalTeamMembership', label: 'Lead' },
  { value: 'OriginationTeamMembership', label: 'Origination team' },
  { value: 'ExecutionTeamMembership', label: 'Execution team' },
  { value: 'ConversionTeamMembership', label: 'Conversion team' },
];

function doesArraysContainSameValues(arr1, arr2) {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  // Check if sets have the same size and all elements of set1 are in set2
  return set1.size === set2.size && [...set1].every(value => set2.has(value));
}

const DashboardFilter = () => {
  const tenantType = useSelector(getTenantType);
  const isAdminUser = useSelector(isAdmin);
  const isSuperAdminUser = useSelector(isSuperAdmin);
  const isPartnerOrRecruiter = useSelector(state =>
    state.user.get('partner_or_recruiter'),
  );
  const searchTeamRolesFeature = useFeatureCheck(
    'development.search_team_roles',
  );

  const canSeeDashboardFilter =
    tenantType === TENANT_TYPE_EXECUTIVE_SEARCH &&
    searchTeamRolesFeature &&
    (isAdminUser || isSuperAdminUser || isPartnerOrRecruiter);

  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    if (!canSeeDashboardFilter) return;
    const currentQueryParams = new URLSearchParams(window.location.search);
    const currentMembershipParam = currentQueryParams.getAll('memberships[]');

    const storedUrl = localStorage.getItem('dashboardMembershipFilter');
    const storedMembershipParam = new URLSearchParams(
      storedUrl?.split('?')[1],
    ).getAll('memberships[]');

    if (currentMembershipParam.length > 0) {
      if (
        !doesArraysContainSameValues(
          storedMembershipParam,
          currentMembershipParam,
        )
      ) {
        // If current URL has memberships params but it did not match with the localStorage URL params then we need to redirect user to the localStorage URL.
        if (storedUrl) {
          window.location.href = storedUrl;
        }
        // If localStorage URL is not present set the current URL params in localStorage
        else {
          const newUrl = `?${currentQueryParams.toString()}`;
          localStorage.setItem('dashboardMembershipFilter', newUrl);
        }
      }
      setSelectedFilters(currentMembershipParam.filter(value => value !== ''));
    }
    // If current URL does not have memberships params redirect to the storeUrl which will have the memberships params
    else if (storedUrl && storedMembershipParam.length > 0) {
      window.location.href = storedUrl;
    }
    // To set default value to the filters.
    else {
      currentQueryParams.delete('memberships[]');

      if (selectedFilters.length > 0) {
        selectedFilters?.forEach(value => {
          currentQueryParams.append('memberships[]', value);
        });
      } else {
        currentQueryParams.append('memberships[]', '');
      }

      const newUrl = `?${currentQueryParams.toString()}`;
      localStorage.setItem('dashboardMembershipFilter', newUrl);
      window.location.href = newUrl;
    }
  }, []);

  const handleFilterChange = (filterValue, isChecked) => {
    let updatedFilters = [];

    updatedFilters = isChecked
      ? [...selectedFilters, filterValue]
      : selectedFilters.filter(value => value !== filterValue);

    setSelectedFilters(updatedFilters);

    const currentQueryParams = new URLSearchParams(window.location.search);

    currentQueryParams.delete('memberships[]');
    if (updatedFilters.length > 0) {
      updatedFilters?.forEach(value => {
        currentQueryParams.append('memberships[]', value);
      });
    } else {
      currentQueryParams.append('memberships[]', '');
    }

    const newUrl = `?${currentQueryParams.toString()}`;
    localStorage.setItem('dashboardMembershipFilter', newUrl);
    window.location.href = newUrl;
  };

  return canSeeDashboardFilter ? (
    <div className='u-flex u-flexJustify-r u-paddingVertical-4'>
      <OverlayTrigger
        overlay={<Tooltip id='dashboardFilter'>Filter Searches</Tooltip>}
        placement='top'
      >
        <Dropdown
          className='CandidacyListModeSelect'
          id='dashboardFilter'
          pullRight={true}
        >
          <Dropdown.Toggle
            className='CandidacyListModeSelect__button u-backgroundColor-white'
            noCaret={true}
          >
            <div className='CandidacyListModeSelect__button_icons'>
              <i className='fa fa-bars' />
              <span>&nbsp;</span>
              <i className='fa fa-caret-down' />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='CandidacyListModeSelect__menu'>
            <li className='dropdown-header'>Filter Searches</li>
            <li className='divider' />
            <li className='dropdown-header'>Show where I&apos;m on:</li>
            {filterOption.map(filter => (
              <li className='CandidacyListModeSelect__item' key={filter.value}>
                <div className='CandidacyListModeSelect__checkbox checkbox'>
                  <label htmlFor={filter.value}>
                    <Checkbox
                      checked={selectedFilters.includes(filter.value)}
                      className='inline-form-control'
                      id={filter.value}
                      name={filter.value}
                      onChange={event =>
                        handleFilterChange(filter.value, event.target.checked)
                      }
                      value={filter.value}
                    />
                    {filter.label}
                  </label>
                </div>
              </li>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>
    </div>
  ) : null;
};

export default withStateProvider(DashboardFilter);

import PropTypes from 'prop-types';
import DateTime from 'modules/datetime/components/DateTime';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import mapInvestmentIdToProperty from './mapInvestmentIdToProperty';

/**
 * Renders the date of an investment from it's ID
 */
export default compose(
  setDisplayName('InvestmentDate'),
  setPropTypes({
    investmentId: PropTypes.number.isRequired,
  }),
  mapInvestmentIdToProperty('date', 'value'),
  branch(({ value }) => !value, renderNothing),
)(DateTime);

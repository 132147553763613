import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import withPositionListFetched from './withPositionListFetched';
import PositionList from './PositionList';
import ContactPositionForm from './ContactPositionForm';

import withFormToggleButton from '../../../../components/forms/withFormToggleButton';

const AddPositionFormToggleButton = withFormToggleButton(ContactPositionForm);

/**
 * Renders a list of positions for a particular contact.
 */
export const ContactPositionsPanel = ({
  contactId,
  positionList,
  ...props
}) => (
  <div className='ContactPositionsPanel'>
    <AddPositionFormToggleButton
      allowPrimarySelect={true}
      btnClassName='ContactPositionsPanel__add_position'
      btnIcon='add'
      btnLabel='New Position'
      className='u-flex u-flexJustify-r'
      contactId={contactId}
      shouldRenderV4Button={true}
    />
    {positionList && (
      <PositionList
        {...props}
        allowPrimarySelect={true}
        positionIds={positionList.get('ids')}
        showEditPositionHeader={true}
      />
    )}
    {positionList && positionList.getIn(['_meta', 'isFetching']) && (
      <LoadingIndicator />
    )}
  </div>
);

ContactPositionsPanel.propTypes = {
  /**
   * The ID of the contact the positions belong to.
   */
  contactId: PropTypes.number.isRequired,

  /**
   * The list state of the positions to render.
   */
  positionList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),
};

export default compose(
  setDisplayName('connect(ContactPositionsPanel)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  withPositionListFetched,
)(ContactPositionsPanel);

import React from 'react';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Tag from 'modules/core/components/Tag';
import useQuickView from 'modules/quick-view/useQuickView';
import { useGetReportingRelationshipsQuery } from 'services/apiV1/reportingRelationships';
import pluralize from 'pluralize';
import ContactReportingCards from './ContactReportingCards';
import ContactReportingReportsTo from './ContactReportingReportsTo';
import { QuickViewEmptyState, QuickViewErrorState } from '../QuickViewState';

const ContactReportingRelationships = props => {
  const { pathParams, title } = useQuickView();
  const { contactId } = pathParams;

  const {
    data: reportingRelationshipData,
    error: reportingRelationshipError,
    isLoading: isReportingRelationshipLoading,
  } = useGetReportingRelationshipsQuery(contactId);

  const managesEmployeesDepthOne =
    reportingRelationshipData?.manages?.treeDepthOneEmployees;
  const managesEmployeesDepthTwo =
    reportingRelationshipData?.manages?.treeDepthTwoEmployees;
  const reportsTo = reportingRelationshipData?.reportsTo;
  const noData =
    !managesEmployeesDepthOne?.length &&
    !managesEmployeesDepthTwo?.length &&
    !reportsTo?.length;

  const contactFirstName = title.split(' ')[0];

  return (
    <SidePanel.Body className='u-paddingTop-24' {...props}>
      <LoadingContainer
        isLoading={isReportingRelationshipLoading}
        minHeight='80%'
        size='large'
      >
        {!isReportingRelationshipLoading && !noData ? (
          <>
            {reportsTo?.length ? (
              <>
                <h3 className='u-margin-n u-marginBottom-8'>{`${title} Currently Reports To`}</h3>
                {reportsTo.map(employee => {
                  return (
                    <ContactReportingReportsTo
                      employee={employee}
                      key={employee.employeeName}
                    />
                  );
                })}
              </>
            ) : null}
            {managesEmployeesDepthOne?.length ? (
              <>
                <div className='u-flex u-flexAlign-c u-marginBottom-8 u-marginTop-32'>
                  <h3 className='u-margin-n u-marginRight-8'>
                    {title} Currently Manages
                  </h3>
                  <Tag
                    className='u-textColor-gray60 u-noWrap'
                    name={pluralize(
                      'Direct Report',
                      managesEmployeesDepthOne.length,
                      true,
                    )}
                  />
                </div>
                {managesEmployeesDepthOne.map(employee => (
                  <ContactReportingCards
                    employee={employee}
                    employeeTreeDepthOfTwo={managesEmployeesDepthTwo}
                    key={employee.employeeName}
                  />
                ))}
              </>
            ) : null}
          </>
        ) : null}
        {!isReportingRelationshipLoading &&
        noData &&
        !reportingRelationshipError ? (
          <QuickViewEmptyState
            heading={`${contactFirstName} doesn’t have any reporting relationships.`}
            image='avatarWithEnvelope'
            subHeading={
              <>
                Add a reporting relationship by clicking on the{' '}
                <b className='u-noWrap'>+ Add Connection</b> button.
              </>
            }
          />
        ) : null}
        {!isReportingRelationshipLoading && reportingRelationshipError ? (
          <QuickViewErrorState />
        ) : null}
      </LoadingContainer>
    </SidePanel.Body>
  );
};

export default ContactReportingRelationships;

import { compose, defaultProps, setDisplayName } from 'recompose';
import EmptyState from 'modules/core/componentsLegacy/EmptyState';

/**
 * A specialized empty state component to use when there are no pending job applicants to display
 * because any filters applied have filtered them all out.
 */
export default compose(
  setDisplayName('JobApplicationListFilteredEmptyState'),
  defaultProps({
    message: 'No job applications match your current filters.',
  }),
)(EmptyState);

import { compose } from 'recompose';
import CandidatePortalConsentPage from 'modules/contacts/components/candidatePortalConsent/CandidatePortalConsentPage';
import withConnection from './withConnection';
import withContactLoaded from './withContactLoaded';

const CandidatePortalConsentPageEnhanced = compose(
  withConnection,
  withContactLoaded,
)(CandidatePortalConsentPage);

export default CandidatePortalConsentPageEnhanced;

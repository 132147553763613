import getUser from './getUser';

/**
 * Gets the displayable user email for a given user record.
 */
export default (state, userId) => {
  const user = getUser(state, userId);

  return user && user.get('email');
};

import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import positionFunctionSchema from '../schemas/positionFunctions';

/**
 * Renders the display name for a given contact position function ID.
 */
const PositionFunctionName = ({ id }) => (
  <EntityProperty
    entityId={id}
    entityType={positionFunctionSchema.key}
    propertyName='name'
  />
);

PositionFunctionName.propTypes = {
  id: PropTypes.number.isRequired,
};

export default PositionFunctionName;

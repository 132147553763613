import routes from 'modules/routing/routes';
import { OWNER_COMPANY, OWNER_CONTACT, OWNER_SEARCH } from '../constants';

/**
 * Gets a URL for the list of documents
 * @param {Object} params
 * @param {String} params.ownerType The type of owner ("Company", "Contact", or "Search")
 * @param {Number} params.ownerId The owner ID (companyID, contactID or searchID)
 */
export default ({ ownerId, ownerType }) => {
  switch (ownerType) {
    case OWNER_COMPANY: {
      return routes.api_v1_company_documents({ companyId: ownerId });
    }
    case OWNER_CONTACT: {
      return routes.api_v1_contact_documents({ contactId: ownerId });
    }
    case OWNER_SEARCH: {
      return routes.api_v1_search_documents({ searchId: ownerId });
    }
    default: {
      throw new Error(`Unknown ownerType for documents: ${ownerType}`);
    }
  }
};

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, setDisplayName, setPropTypes } from 'recompose';
import ContactName from 'modules/contacts/components/ContactName';
import UserName from 'modules/users/components/UserName';
import wrapInSearchCandidacyLink from 'modules/candidacies/components/wrapInSearchCandidacyLink';
import mapIntroductionIdToIntroduction from './mapIntroductionIdToIntroduction';
/**
 * Renders the title of an introduction item.
 */
export const IntroductionTitle = ({
  className,
  introduction,
  povContactId,
}) => {
  const isIntroducedTo = povContactId === introduction.get('introduced_to');
  const isContact = povContactId === introduction.get('contact');

  return (
    <div className={className}>
      {!isIntroducedTo && !isContact ? (
        <>
          <ContactName contactId={introduction.get('contact')} />
          <span> was introduced to </span>
          <ContactName contactId={introduction.get('introduced_to')} />
          <span> by </span>
          <UserName userId={introduction.get('introduced_by')} />
        </>
      ) : (
        <>
          <span> Introduced to </span>
          <ContactName
            contactId={introduction.get(
              isContact ? 'introduced_to' : 'contact',
            )}
          />
        </>
      )}
    </div>
  );
};

IntroductionTitle.propTypes = {
  className: PropTypes.string,
  /**
   * The introduction to render.
   */
  introduction: ImmutablePropTypes.mapContains({
    introduced_to: PropTypes.number.isRequired,
    contact: PropTypes.number.isRequired,
  }).isRequired,

  /**
   * The contact who's "point of view" we're seeing this from.
   * For example, if "Arther Dent" was introduced to "Ford Prefect", but we're rendering this
   * from the point of view of "Arther Dent", we would just display the name "Ford Prefect"
   * (and vice versa). If not displaying from the point of view of either person, we'd then
   *  display "Arther Dent was introduced to Ford Prefect"
   */
  povContactId: PropTypes.number,
};

/**
 * The default export requires only a `introductionId`, while
 * the named export requires the introduction record.
 */
export default compose(
  setDisplayName('IntroductionTitle(enhanced)'),
  setPropTypes({
    introductionId: PropTypes.number.isRequired,
    linkToCandidacy: PropTypes.bool,
  }),
  mapIntroductionIdToIntroduction,
  branch(
    ({ linkToCandidacy }) => linkToCandidacy,
    wrapInSearchCandidacyLink(({ introduction }) => ({
      candidacyId: introduction && introduction.get('candidacy_id'),
      tab: 'introductions',
    })),
  ),
)(IntroductionTitle);

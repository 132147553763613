import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import TableView from 'modules/core/componentsLegacy/Table/TableView';
import {
  COLUMN_LOCATION_NAME,
  COLUMN_INDUSTRY,
  COLUMN_COMPANY_NAME_WITH_CANDIDATE_COUNT,
  COLUMN_LATEST_EMPLOYEE_COUNT,
} from '../../constants';
import columnDefinitions from './columnDefinitions';
import getActive from '../../selectors/targetCompanies/getActive';
/**
 * Renders a table of searches
 * {@see columnDefinitions} for available column names, or to add additional columns to be
 * rendered by this table.
 */
export default compose(
  setDisplayName('TargetCompanyTable'),
  setPropTypes({
    /**
     * The names of the columns to render.
     */
    columns: PropTypes.arrayOf(
      PropTypes.oneOf(Object.keys(columnDefinitions)).isRequired,
    ).isRequired,

    onTargetCompanySelect: PropTypes.func,

    targetCompanyIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
  }),
  defaultProps({
    columns: [
      COLUMN_COMPANY_NAME_WITH_CANDIDATE_COUNT,
      COLUMN_LOCATION_NAME,
      COLUMN_INDUSTRY,
      COLUMN_LATEST_EMPLOYEE_COUNT,
    ],
  }),

  connect(
    (state, { targetCompanyIds }) => ({
      inactiveTargetCompanyIds:
        targetCompanyIds &&
        targetCompanyIds.filter(id => !getActive(state, id)),
    }),
    {},
  ),

  withHandlers({
    getRowClassNames: ({ inactiveTargetCompanyIds }) => targetCompanyId => ({
      'TargetCompanyTable__row--inactive':
        inactiveTargetCompanyIds &&
        inactiveTargetCompanyIds.includes(targetCompanyId),
    }),
  }),

  // Pull out the properties needed for the Table component.
  mapProps(
    ({
      columns,
      getRowClassNames,
      onSortChange,
      onTargetCompanySelect,
      orphanCount,
      sortAscending,
      sortBy,
      targetCompanyIds,
    }) => ({
      className: 'TargetCompanyTable',

      // Map the column names to the actual column definitions (which includes the titles,
      // how to render the values, etc)
      columns: columns
        .map(columnName => columnDefinitions[columnName])
        .filter(column => column),
      data: targetCompanyIds && targetCompanyIds.insert(0, 0),

      getRowClassNames: getRowClassNames,

      // Pass through sorting properties
      onSortChange: onSortChange,
      sortBy: sortBy,
      sortAscending: sortAscending,

      // Addition props get passed into the cell components so we can handle callbacks.
      orphanCount: orphanCount,
      onTargetCompanySelect: onTargetCompanySelect,
      tableScrollContainerClass: 'TargetCompanyTable__scrollContainer',
    }),
  ),
)(TableView);

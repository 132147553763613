import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import { withProps } from 'recompose';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import CompanyInvestmentStageNames from 'modules/tenant/components/CompanyInvestmentStageNames';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import BoardMemberInfoForm from './BoardMemberInfoForm';
import RevenueRange from '../RevenueRange';
import withEditableSection from '../withEditableSection';

function BoardMemberInfo({
  clearTransaction,
  contact,
  contactActions,
  isEditing,
  toggleEditing,
  transactions,
}) {
  if (!isEditing) {
    const isInterested = contact.getIn([
      'data',
      'preferred_board_member_interest',
    ]);
    const stages = contact.getIn([
      'data',
      'preferred_board_member_company_stages',
    ]);
    const notes = contact.getIn(['data', 'preferred_board_member_notes']);
    const preferredRevenueMinimum = contact.getIn([
      'data',
      'preferred_board_member_revenue_minimum',
    ]);
    const preferredRevenueMaximum = contact.getIn([
      'data',
      'preferred_board_member_revenue_maximum',
    ]);

    return (
      <div>
        {isInterested && (
          <div className='row'>
            <div className='col-sm-12'>
              <LabeledContent label='Interested?'>Yes</LabeledContent>
            </div>
          </div>
        )}
        {stages && stages.count() > 0 && (
          <div className='row'>
            <div className='col-sm-12'>
              <LabeledContent label='Company Stages?'>
                <CompanyInvestmentStageNames ids={stages.toArray()} />
              </LabeledContent>
            </div>
          </div>
        )}
        {(preferredRevenueMinimum || preferredRevenueMaximum) && (
          <div className='row'>
            <div className='col-sm-6'>
              <RevenueRange
                hideIfEmpty={true}
                maxRevenue={preferredRevenueMaximum}
                minRevenue={preferredRevenueMinimum}
              />
            </div>
          </div>
        )}
        {notes && (
          <div className='row'>
            <div className='col-sm-12'>
              <LabeledContent label='Notes'>{notes}</LabeledContent>
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <BoardMemberInfoForm
      clearTransaction={clearTransaction}
      contact={contact}
      contactActions={contactActions}
      initialValues={contact}
      onCancel={toggleEditing}
      onSaved={toggleEditing}
      onSubmit={toggleEditing}
      transactions={transactions}
    />
  );
}

BoardMemberInfo.propTypes = {
  clearTransaction: PropTypes.func.isRequired,

  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      id: PropTypes.number,
      preferred_board_member_company_stages: ImmutablePropTypes.listOf(
        PropTypes.number.isRequired,
      ),
      preferred_board_member_interest: PropTypes.bool,
      preferred_board_member_notes: PropTypes.string,
      preferred_board_member_revenue_minimum: PropTypes.number,
      preferred_board_member_revenue_maximum: PropTypes.number,
    }),
  }).isRequired,

  contactActions: PropTypes.shape({
    updateContact: PropTypes.func.isRequired,
  }).isRequired,

  isEditing: PropTypes.bool.isRequired,

  toggleEditing: PropTypes.func.isRequired,

  transactions: transactionsState.isRequired,
};

const BOARD_MEMBER_FIELDS = [
  'preferred_board_member_interest',
  'preferred_board_member_revenue_minimum',
  'preferred_board_member_revenue_maximum',
  'preferred_board_member_notes',
  'preferred_board_member_company_stages',
];

const withSectionNotPresent = withProps(({ contact }) => ({
  sectionNotPresent:
    !contact ||
    BOARD_MEMBER_FIELDS.every(fieldName => {
      const value = contact.getIn(['data', fieldName]);
      return List.isList(value) ? value.size === 0 : !value && value !== 0;
    }),
}));

export default withSectionNotPresent(
  withEditableSection(BoardMemberInfo, {
    sectionId: 'boardMemberInfo',
    title: 'Board Member',
    titleClassName: 'u-margin-n',
  }),
);

import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import FieldState from 'modules/forms/FieldState';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import interactionLevels from 'modules/tenant/schemas/interactionLevels';

const InteractionLevelsField = ({
  fieldState,
  handleNestedFieldChange,
  ...otherProps
}) => (
  <TenantOptionsSelectField
    {...otherProps}
    className='InteractionLevelsField__interaction_level'
    clearable={true}
    fieldState={fieldState.getNestedField('interaction_level_id')}
    onChange={handleNestedFieldChange}
    placeholder='Select Level...'
    renderIfNoOptions={false}
    schema={interactionLevels}
    sortProperty='name'
  />
);

InteractionLevelsField.propTypes = {
  fieldState: fieldStatePropType.isRequired,
  handleNestedFieldChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('InteractionLevelsField(enhanced)'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic(
    'createFieldState',
    (name = 'interactionLevels', contact, ...rest) => {
      const values = new Map({
        interaction_level: null,
      }).merge(contact);

      return FieldState.createNested(
        name,
        [
          TenantOptionsSelectField.createFieldState(
            'interaction_level_id',
            values.get('interaction_level'),
          ),
        ],
        ...rest,
      );
    },
  ),
  withNestedFieldChangeHandler,
)(InteractionLevelsField);

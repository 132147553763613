import { NAME } from '../constants';

/**
 * Gets the current tenant's tenantType, one of: TENANT_TYPE_ENTERPRISE,
 * TENANT_TYPE_VENTURE_CAPITAL, or TENANT_TYPE_EXECUTIVE_SEARCH
 * @param {Object} state
 * @return {String} The current tenant type value constant.
 */
const getTenantType = state => state[NAME].get('tenant_type');

export default getTenantType;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose } from 'recompose';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';

import DirectReportsManagementButton from 'modules/contacts/components/directReports/DirectReportsManagementButton';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import ContactAvatar from 'modules/contacts/components/ContactAvatar';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import CompanyName from './CompanyName';
import IncumbentLink from './IncumbentLink';
import JobTitle from './JobTitle';
import ViewOrCreateTalentPoolLink from './ViewOrCreateTalentPoolLink';

import TalentPoolReportButton from './talentPoolReport/TalentPoolReportButton';

// TODO: isFetching.

/**
 * Renders the "small" style contact/talent pool card, which is shown at the top of the talent
 * pool listing when a contact is selected.
 */
export const TalentPoolCardSmall = ({
  contact,
  contactId,
  onCreateTalentPool,
  showTalentPoolReport,
}) => {
  if (contact && contact.getIn(['_meta', 'isFetching'])) {
    return <LoadingIndicator />;
  }

  return (
    <div className='talent-pools--talent-pool-card talent-pools--talent-pool-card-small'>
      <div className='talent-pools--talent-pool-card-left talent-pools--talent-pool-card-section'>
        <ContactAvatar contactId={contactId} useLegacyStyles={true} />
        <DirectReportsManagementButton contactId={contactId} />
      </div>
      <div className='talent-pools--talent-pool-card-right talent-pools--talent-pool-card-section'>
        <IncumbentLink contactId={contactId} />
        <JobTitle contactId={contactId} />
        <CompanyName contactId={contactId} />
        <ViewOrCreateTalentPoolLink
          contactId={contactId}
          onCreateTalentPool={onCreateTalentPool}
        />
        {showTalentPoolReport && (
          <TalentPoolReportButton contactId={contactId} />
        )}
      </div>
    </div>
  );
};

TalentPoolCardSmall.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  contactId: PropTypes.number.isRequired,

  onCreateTalentPool: PropTypes.func.isRequired,

  /**
   * Whether or not the talent pool flipper has been turned on.
   */
  showTalentPoolReport: PropTypes.bool,
};

export default compose(
  mapContactIdToContact,
  withFeatureCheck('report.talent_pool_report', 'showTalentPoolReport'),
)(TalentPoolCardSmall);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { TYPE_JOB_SEARCH, TYPE_PIPELINE } from 'modules/searches/constants';
import getSearchProperty from 'modules/searches/selectors/getSearchProperty';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import CandidacySortByDropdownMenu from './CandidacySortByDropdownMenu';
import CandidacyTagSelectDropdownButton from './CandidacyTagSelectDropdownButton';
import {
  SORT_ASCENDING_DEFAULTS,
  SORT_BY_DEFAULT,
  SORT_BY_RANK,
  SORT_BY_TYPES,
} from '../constants';
import mapCandidacyIdToSearchType from './mapCandidacyIdToSearchType';
/**
 * A form for filtering and sorting a list of candidacies:
 * - A text field for entering keywords to filter on
 * - A button to toggle display of "visible only" candidacies
 * - A button to toggle display of "priority only" candidacies
 * - A sort-by dropdown menu
 */
class CandidacyFilterForm extends PureComponent {
  handleFilterTextChange = event => {
    const {
      isPriorityOnly,
      isVisibleOnly,
      onChange,
      selectedTagIds,
      sortAscending,
      sortBy,
    } = this.props;
    onChange({
      filterText: event.currentTarget.value,
      isPriorityOnly: isPriorityOnly,
      isVisibleOnly: isVisibleOnly,
      selectedTagIds: selectedTagIds,
      sortAscending: sortAscending,
      sortBy: sortBy,
    });
  };

  handleSortChange = sortBy => {
    const {
      filterText,
      isPriorityOnly,
      isVisibleOnly,
      onChange,
      selectedTagIds,
      sortAscending,
      sortBy: previousSortBy,
    } = this.props;
    const sortByChanged = sortBy !== previousSortBy;
    const sortByRank = sortBy === SORT_BY_RANK;

    onChange({
      filterText: filterText,
      isVisibleOnly: isVisibleOnly,
      selectedTagIds: selectedTagIds,
      sortBy: sortBy,
      // If the SORT_BY_RANK option is selected, we always want to sort descending.
      // If clicked consecutively, the sort should remain the same
      sortAscending:
        !sortByRank && !sortByChanged
          ? !sortAscending
          : SORT_ASCENDING_DEFAULTS[sortBy],
      isPriorityOnly: isPriorityOnly,
    });
  };

  handleSelectedTagIdsChange = ({ _, checked, tagId }) => {
    const {
      filterText,
      isPriorityOnly,
      isVisibleOnly,
      onChange,
      selectedTagIds,
      sortAscending,
      sortBy,
    } = this.props;

    const tagIds = checked
      ? selectedTagIds.concat([tagId])
      : selectedTagIds.filter(id => id !== tagId);

    onChange({
      filterText: filterText,
      isPriorityOnly: isPriorityOnly,
      isVisibleOnly: isVisibleOnly,
      selectedTagIds: tagIds,
      sortAscending: sortAscending,
      sortBy: sortBy,
    });
  };

  handleIsPriorityOnlyChange = () => {
    const {
      filterText,
      isPriorityOnly,
      isVisibleOnly,
      onChange,
      selectedTagIds,
      sortAscending,
      sortBy,
    } = this.props;
    onChange({
      filterText: filterText,
      isPriorityOnly: !isPriorityOnly,
      isVisibleOnly: isVisibleOnly,
      selectedTagIds: selectedTagIds,
      sortAscending: sortAscending,
      sortBy: sortBy,
    });
  };

  handleIsVisibleOnlyChange = () => {
    const {
      filterText,
      isPriorityOnly,
      isVisibleOnly,
      onChange,
      selectedTagIds,
      sortAscending,
      sortBy,
    } = this.props;
    onChange({
      filterText: filterText,
      isPriorityOnly: isPriorityOnly,
      isVisibleOnly: !isVisibleOnly,
      selectedTagIds: selectedTagIds,
      sortAscending: sortAscending,
      sortBy: sortBy,
    });
  };

  render() {
    const {
      candidacyTags,
      candidacyVisibility,
      componentId,
      employeeUser,
      filterText,
      isPriorityOnly,
      isVisibleOnly,
      searchId,
      searchType,
      selectedTagIds,
      setSelectedTagIds,
      sortAscending,
      sortBy,
      sortOptions,
    } = this.props;

    const tooltipMessage = `Priority ${
      searchType === TYPE_PIPELINE ? 'People' : 'Candidates'
    } Only`;

    return (
      <form
        className='CandidacyFilterForm form'
        onSubmit={preventDefaultHandler}
        role='search'
      >
        <div className='input-group'>
          <input
            className='form-control'
            onChange={this.handleFilterTextChange}
            placeholder='Filter by name, company, or email'
            type='search'
            value={filterText}
          />
          <div className='input-group-btn'>
            {employeeUser &&
              candidacyVisibility &&
              searchType === TYPE_JOB_SEARCH && (
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`${componentId}-tooltip-hidden`}>
                      Visible Candidates Only
                    </Tooltip>
                  }
                  placement='top'
                >
                  <button
                    className={classnames(
                      'CandidacyFilterForm__visible_button btn btn-default btn-toggle',
                      {
                        active: isVisibleOnly,
                      },
                    )}
                    onClick={this.handleIsVisibleOnlyChange}
                    title='Visible Candidates Only'
                    type='button'
                  >
                    <i className='fa fa-eye' />
                  </button>
                </OverlayTrigger>
              )}
            {employeeUser && candidacyTags && (
              <CandidacyTagSelectDropdownButton
                onChange={this.handleSelectedTagIdsChange}
                searchId={searchId}
                selectedTagIds={selectedTagIds}
                setSelectedTagIds={setSelectedTagIds}
              />
            )}
            <OverlayTrigger
              overlay={
                <Tooltip id={`${componentId}-tooltip-sort`}>Sort by</Tooltip>
              }
              placement='top'
            >
              <button
                aria-expanded='false'
                aria-haspopup='true'
                className='btn btn-default'
                data-toggle='dropdown'
                title='Sort By'
                type='button'
              >
                <i className='fa fa-sort-amount-asc' />
              </button>
            </OverlayTrigger>
            <CandidacySortByDropdownMenu
              isAscending={!sortAscending}
              onChange={this.handleSortChange}
              sortOptions={sortOptions}
              value={sortBy}
            />
            <OverlayTrigger
              overlay={
                <Tooltip id={`${componentId}-tooltip-priority`}>
                  {tooltipMessage}
                </Tooltip>
              }
              placement='top'
            >
              <button
                className={classnames('btn', 'btn-default', 'btn-toggle', {
                  active: isPriorityOnly,
                })}
                onClick={this.handleIsPriorityOnlyChange}
                title={tooltipMessage}
                type='button'
              >
                <i className='fa fa-thumbs-o-up' />
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </form>
    );
  }
}

CandidacyFilterForm.propTypes = {
  /**
   * True if the flipper is on to show candidacy tagging feature and false
   * if it isn't
   */
  candidacyTags: PropTypes.bool,

  /**
   * True if the flipper is on to hide candidacies and false if it
   * isn't
   */
  candidacyVisibility: PropTypes.bool,

  /**
   * A unique ID for the instance of the component.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * True user is an employee user.
   */
  employeeUser: PropTypes.bool,

  /**
   * The text/keywords being filtered on
   */
  filterText: PropTypes.string,

  /**
   * Whether the "priority" only button is checked.
   */
  isPriorityOnly: PropTypes.bool,

  /**
   * Whether the "visible" only button is checked.
   */
  isVisibleOnly: PropTypes.bool,

  /**
   * Called when any of the filter/sort options change
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Id of the candidacy's search
   */
  searchId: PropTypes.number.isRequired,

  /**
   * Need search type because visibility filter is only for JobSearch
   */
  searchType: PropTypes.string,

  /**
   * The current selected tags to filter the candidacy list on
   */
  selectedTagIds: PropTypes.arrayOf(PropTypes.number),

  /**
   * Call this function when last tag checkbox is unchecked on Candidacy tags modal
   */
  setSelectedTagIds: PropTypes.func,

  /**
   * Whether sorting is descending (true) or descending (false)
   */
  sortAscending: PropTypes.bool,

  /**
   * The value to sort on.
   */
  sortBy: (props, propName, componentName) => {
    const { sortOptions, [propName]: value } = props;
    if (value && sortOptions && !sortOptions.includes(value)) {
      return new Error(
        `Invalid prop \`sortBy\` supplied to \`${componentName}\`, ` +
          `value must match a key in the prop \`sortOptions\`. \`${value}\` ` +
          `not found in: ["${sortOptions.join('", "')}"]`,
      );
    }

    return null;
  },

  /**
   * An array of sort options to show in the dropdown.
   */
  sortOptions: PropTypes.arrayOf(PropTypes.oneOf(SORT_BY_TYPES)).isRequired,
};

CandidacyFilterForm.defaultProps = {
  isPriorityOnly: false,
  isVisibleOnly: false,
  selectedTagIds: [],
  sortAscending: SORT_ASCENDING_DEFAULTS[SORT_BY_DEFAULT],
  sortBy: SORT_BY_DEFAULT,
};

export default compose(
  withComponentId(),
  withFeatureCheck('feature.candidacy_visibility', 'candidacyVisibility'),
  withFeatureCheck('feature.candidacy_tags', 'candidacyTags'),
  mapCandidacyIdToSearchType,
  connect(
    (state, { searchId }) => ({
      employeeUser: isEmployeeUser(state),
      searchType: getSearchProperty(state, searchId, 'type'),
    }),
    {},
  ),
)(CandidacyFilterForm);

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import { useSelector } from 'react-redux';

const ContactAddressForm = ({ contactDetails }) => {
  const tenant = useSelector(state => state.tenant);
  const countries = tenant.get('countries');
  const states = tenant.get('us_states');

  return (
    <Card className='u-marginTop-32 u-border-n u-padding-n' isCentered={false}>
      <h2 className='u-marginTop-n'>Address</h2>
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.addressLine1}
        inputWidth='full'
        label='Address'
        name='address_line_1'
        size='small'
      />
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.addressLine2}
        inputWidth='full'
        label='Address Line 2'
        name='address_line_2'
        size='small'
      />
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.city}
        inputWidth='full'
        label='City'
        name='city'
        size='small'
      />
      <Form.FieldDependentContent
        shouldRender={fields => fields.country_code === 'US'}
      >
        <Form.SelectMenu
          className='u-marginBottom-8'
          initialValue={contactDetails?.state}
          inputWidth='full'
          label='State'
          name='state'
          placeholder='Select a State'
          size='small'
        >
          {states?.map(({ id, name }) => (
            <SelectMenu.Item key={id} value={id}>
              {name}
            </SelectMenu.Item>
          ))}
        </Form.SelectMenu>
      </Form.FieldDependentContent>
      <Form.FieldDependentContent
        shouldRender={fields => fields.country_code !== 'US'}
      >
        <Form.TextInput
          className='u-marginBottom-8'
          initialValue={
            contactDetails?.countryCode === 'US' ? '' : contactDetails?.state
          }
          inputWidth='full'
          label='Province'
          name='state'
          size='small'
        />
      </Form.FieldDependentContent>
      <Form.SelectMenu
        className='u-marginBottom-8'
        initialValue={contactDetails?.countryCode}
        inputWidth='full'
        label='Country'
        name='country_code'
        placeholder='Select a Country'
        size='small'
      >
        {countries?.map(({ id, name }) => (
          <SelectMenu.Item key={id} value={id}>
            {name}
          </SelectMenu.Item>
        ))}
      </Form.SelectMenu>
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.postalCode}
        inputWidth='full'
        label='Postal Code'
        name='postal_code'
        size='small'
      />
    </Card>
  );
};

ContactAddressForm.propTypes = {
  contactDetails: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
  }),
};

export default ContactAddressForm;

import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { compose, lifecycle, setDisplayName, withHandlers } from 'recompose';
import * as actions from '../actions';
import shouldFetchLocationListSelector from '../selectors/shouldFetchLocationList';
import getLocationList from '../selectors/getLocationList';

/**
 * Ensures that the list of locations are fetched.
 */

export default compose(
  setDisplayName('withLocationListFetched'),
  connect(
    state => ({
      shouldFetchLocationList: shouldFetchLocationListSelector(state),
      locationList: getLocationList(state),
    }),
    dispatch => ({ locationActions: bindActionCreators(actions, dispatch) }),
  ),
  withHandlers({
    /**
     * A handler that, when called, only fetches the location list if needed.
     */
    fetchLocationListIfNeeded: ({
      locationActions,
      shouldFetchLocationList,
    }) => () => {
      if (shouldFetchLocationList) {
        locationActions.fetchLocationList();
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchLocationListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchLocationListIfNeeded();
    },
  }),
);

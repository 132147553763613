import { connect } from 'react-redux';
import getIntroduction from '../selectors/getIntroduction';

/**
 * A higher order component which maps a `introductionId` prop value to it's introduction record.
 */
export default connect(
  (state, { introductionId }) => ({
    introduction: getIntroduction(state, introductionId),
  }),
  {},
);

import { connect } from 'react-redux';
import isIntroductionRelatedToSearch from '../selectors/isIntroductionRelatedToSearch';

/**
 * A Higher order component that groups introductionIds by those that are related
 * to a search (true), and those that aren't (false).
 */
export default connect(
  (state, { introductionIds, searchId }) => ({
    introductionGroups:
      introductionIds &&
      introductionIds.groupBy(introductionId =>
        isIntroductionRelatedToSearch(state, introductionId, searchId),
      ),
  }),
  {},
);

import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import {
  JOB_APPLICATION_LIST_FETCH_START,
  JOB_APPLICATION_LIST_FETCH_SUCCESS,
  JOB_APPLICATION_LIST_FETCH_FAILURE,
} from './actionTypes';
import jobApplicationSchema from '../schema';

/**
 * Fetches the job applications for a search
 * @param {Number} searchId The ID of the search to fetch job applications for.
 */
export default compose(
  fetchAction,
  normalizeResponse({
    job_applications: [jobApplicationSchema],
  }),
  createAsyncActions({
    startActionType: JOB_APPLICATION_LIST_FETCH_START,
    successActionType: JOB_APPLICATION_LIST_FETCH_SUCCESS,
    failureActionType: JOB_APPLICATION_LIST_FETCH_FAILURE,
  }),
  withOptions(({ searchId }) => ({
    url: routes.api_v1_search_job_applications({ searchId: searchId }),
  })),
);

import { connect } from 'react-redux';
import getDirectReports from '../../selectors/directReports/getDirectReports';

/**
 * A higher order component which maps a contact ID to it's direct reports list state.
 */
export default connect(
  (state, { contactId }) => ({
    directReports: contactId && getDirectReports(state, contactId),
  }),
  {},
);

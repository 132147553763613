import { connect } from 'react-redux';
import getEntity from 'modules/entities/selectors/getEntity';
import { PARENT_COMPANY, PARENT_CONTACT } from '../constants';

/**
 * A higher order component which maps `parentId` to it's parent record.
 */
export default connect((state, { parentId, parentType }) => {
  switch (parentType) {
    case PARENT_COMPANY: {
      return {
        parent: getEntity(state, 'companies', parentId),
      };
    }
    case PARENT_CONTACT: {
      return {
        parent: getEntity(state, 'contacts', parentId),
      };
    }
    default: {
      return { parent: null };
    }
  }
}, {});

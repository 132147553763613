import getTagList from './getTagList';

/**
 * Determines if the list of tags for the current tenant should be fetched from the server.
 */
export default state => {
  const tagList = getTagList(state);

  if (!tagList) {
    return true;
  }

  // Don't fetch if we're already fetching, or if we fetched and got an error.
  if (tagList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (tagList.getIn(['_meta', 'error'])) {
    return false;
  }

  // Fetch if we don't have IDs (meaning we haven't fetched yet) or we've been invalidated.
  return !tagList.has('ids') || tagList.getIn(['_meta', 'isInvalidated']);
};

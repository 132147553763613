import React from 'react';
import PropTypes from 'prop-types';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import Icon from '@thrivetrm/ui/components/Icon';

const ContactAddressAs = ({ addressAs, className }) => {
  if (addressAs) {
    return (
      <div className={className}>
        <TooltipTrigger placement='bottom' tooltip={addressAs}>
          <Icon color='black' size='medium' type='addressAs' />
        </TooltipTrigger>
      </div>
    );
  }
  return null;
};

ContactAddressAs.defaultProps = {
  addressAs: null,
  className: null,
};

ContactAddressAs.propTypes = {
  addressAs: PropTypes.string,
  className: PropTypes.string,
};

export default ContactAddressAs;

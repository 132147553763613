import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ConditionalTooltipTrigger from 'modules/core/componentsLegacy/ConditionalTooltipTrigger';

const SourceIndicator = ({ isDisabled }) => (
  <ConditionalTooltipTrigger nested={true} placement='left' tooltip='Source'>
    <i
      className={classNames('fa fa-crosshairs u-marginRight-4', {
        'u-textColor-gray30': isDisabled,
      })}
    />
  </ConditionalTooltipTrigger>
);

SourceIndicator.propTypes = {
  isDisabled: PropTypes.bool,
};

SourceIndicator.defaultPropTypes = {
  isDisabled: false,
};

export default SourceIndicator;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Card from '@thrivetrm/ui/components/Card';
import DateInput from '@thrivetrm/ui/components/DateInput';
import Icon from '@thrivetrm/ui/components/Icon';
import Loader from '@thrivetrm/ui/components/Loader';
import Menu from '@thrivetrm/ui/components/Menu';
import MultiValueInput from 'modules/core/components/MultiValueInput';
import { useApiGet } from 'modules/core/hooks/useApi';
import {
  clearFilters,
  changeFilters,
  removeFilter,
} from 'modules/search-requests/searchRequestSlice';
import routes from 'modules/routing/routes';
import {
  FILTERS_CONFIG,
  FILTER_DEFAULT_VALUE,
  FILTER_TYPE,
} from '../constants';

const SearchRequestSidebarFilters = () => {
  const dispatch = useDispatch();

  const { filters } = useSelector(state => state.searchRequest);

  const [
    loadTenantOptions,
    _isLoadingTenantOptions,
    _tenantOptionsLoadError,
    tenantOptionsResponse,
  ] = useApiGet(routes.api_tenant_options());

  useEffect(() => {
    loadTenantOptions();
  }, [loadTenantOptions]);

  // Options can be hard-coded in the config, or provided by the async load of
  // tenant options. This function normalizes the lookup for consumers and
  // structures the options in the shape expected by <MultiValueInput>.
  const getFilterOptions = filterKey => {
    const { options, tenantOptionsKey } = FILTERS_CONFIG[filterKey];
    const filterOptions =
      options || tenantOptionsResponse?.tenant_options[tenantOptionsKey];
    return filterOptions?.map(option => ({
      label: option.name,
      value: option.id,
    }));
  };

  const handleFilterValueChange = (filterKey, value) => {
    dispatch(changeFilters({ [filterKey]: value }));
  };

  const handleFromDateFilterValueChange = (filterKey, fromDate) => {
    const dateRangeValue = {
      ...filters[filterKey],
      from: fromDate,
    };
    handleFilterValueChange(filterKey, dateRangeValue);
  };

  const handleToDateFilterValueChange = (filterKey, toDate) => {
    const dateRangeValue = {
      ...filters[filterKey],
      to: toDate,
    };
    handleFilterValueChange(filterKey, dateRangeValue);
  };

  const hasFilters = Object.keys(filters).length > 0;

  const getFilterContent = () => {
    if (!tenantOptionsResponse) {
      return (
        <div className='u-paddingTop-24 u-flex u-flexJustify-c'>
          <Loader size='small' />
        </div>
      );
    } else if (hasFilters) {
      return Object.entries(filters).map(([filterKey, filterValue]) => {
        const filterConfig = FILTERS_CONFIG[filterKey];
        return (
          <Card className='u-marginTop-12' isCentered={false} key={filterKey}>
            <h3 className='u-marginTop-n'>{filterConfig.label}</h3>
            {filterConfig.type === FILTER_TYPE.TEXT ? (
              <MultiValueInput
                canUseCustomOptions={filterConfig.canUseCustomOptions}
                onChange={newValue =>
                  handleFilterValueChange(filterKey, newValue)
                }
                options={getFilterOptions(filterKey)}
                placeholder={`Add ${filterConfig.label}…`}
                value={filterValue}
              />
            ) : null}

            {filterConfig.type === FILTER_TYPE.DATE_RANGE ? (
              <>
                <DateInput
                  label='From'
                  onChange={newValue =>
                    handleFromDateFilterValueChange(filterKey, newValue)
                  }
                  placeholderText='Select a date'
                  value={filterValue.from}
                />
                <DateInput
                  label='To'
                  onChange={newValue =>
                    handleToDateFilterValueChange(filterKey, newValue)
                  }
                  placeholderText='Select a date'
                  value={filterValue.to}
                />
              </>
            ) : null}

            <div className='u-textAlign-c'>
              <ButtonSecondary
                className='u-marginTop-8'
                label='Remove Entire Filter'
                onClick={() => dispatch(removeFilter(filterKey))}
                size='small'
              />
            </div>
          </Card>
        );
      });
    }
    return (
      <Card
        className='u-marginTop-12 u-textAlign-c'
        isCentered={false}
        type='gray'
      >
        <Icon
          className='u-marginVertical-16'
          color='purple'
          size='large'
          type='filter'
        />
        <h4>Add Filters</h4>
        <p className='u-fontSize-small'>
          Quickly narrow down your list of search requests
        </p>
      </Card>
    );
  };

  return (
    <>
      <div className='u-flex u-flexAlign-c'>
        <h3 className='u-flexItem-grow u-margin-n'>Filters</h3>

        {hasFilters ? (
          <ButtonLink
            className='u-marginRight-12'
            label='Clear Filters'
            onClick={() => dispatch(clearFilters())}
            size='small'
          />
        ) : null}

        <Menu
          button={
            <ButtonSecondary
              aria-label='search-request-filters'
              icon='add'
              size='small'
            />
          }
          isPinnedRight={true}
        >
          {Object.entries(FILTERS_CONFIG).map(([filterKey, filterConfig]) => (
            <Menu.Item
              isDisabled={Object.keys(filters).includes(filterKey)}
              key={filterKey}
              onClick={() =>
                handleFilterValueChange(
                  filterKey,
                  FILTER_DEFAULT_VALUE[filterConfig.type],
                )
              }
            >
              {filterConfig.label}
            </Menu.Item>
          ))}
        </Menu>
      </div>

      {getFilterContent()}
    </>
  );
};

export default SearchRequestSidebarFilters;

const MAPPINGS = {
  error: 'danger',
  success: 'success',
};

const createMessage = (content, className) =>
  $('<div></div>').html(content).addClass(`alert hello alert-${className}`);

const createCloseButton = () =>
  $('<a class="close" data-dismiss="alert"> ×</a>');

export default function notify(content, type = 'success') {
  const className = MAPPINGS[type];
  const message = createMessage(content, className);
  const close = createCloseButton();

  close.on('click', () => message.remove());
  setTimeout(() => message.remove(), 8000);

  $('#messages').append(message.append(close));
}

import { connect } from 'react-redux';
/**
 * A higher order component that sorts a List of addressIds with the primary address coming first
 */
export default connect(
  (state, { addressIds, primaryAddressId }) => ({
    addressIds:
      addressIds &&
      addressIds.sortBy(id => (id === primaryAddressId ? '0' : '1')),
  }),
  {},
);

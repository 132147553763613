import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { moveItem } from '@thrivetrm/ui/utilities/arrayUtils';

/**
 * This component implements Drag and Drop using the native HTML API. It's a hybrid of the API
 * and React state: we only use the "drag" portion; we don't implement the "drop" portion
 * because React updates the order of the items while we drag, so they are naturally in place
 * already when the drag ends.
 *
 * Anti-pattern alert: this component is semi-controlled. Our requirements fall into the very small
 * window of valid use cases:
 *
 *   - This component needs to be told what its initial items are
 *.  - The consumer should not have to manage the item order _while items are being dragged_, as
 *.    this will cause the consumer to re-render while dragging
 *.  - The consumer needs to be able to update this component's items and their order _after mount_
 *
 * This component has been implemented using the recommended semi-controlled pattern of a) seed
 * state from props on mount and then b) if the consumer updates data externally, it must use a
 * `key` prop on this component to trigger a re-mount when external data is changed.
 * https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
 */
const ReorderableList = ({
  className,
  onChange,
  pinnedItems,
  reorderableItems,
  ...props
}) => {
  const [items, setItems] = useState(reorderableItems);
  // This value is tracked across renders as items are re-arranged. Use a ref instead of state to
  // avoid triggering a re-render when the value changes.
  const draggedItem = useRef(null);

  const handleItemDragStart = (event, draggedItemIndex) => {
    draggedItem.current = items[draggedItemIndex];
    event.dataTransfer.effectAllowed = 'move';
    // Must be set for Firefox to allow dragging
    event.dataTransfer.setData('text', draggedItem.current.value);
  };

  const handleItemDragOver = draggedOverItemIndex => {
    const draggedItemIndex = items.findIndex(
      item => item === draggedItem.current,
    );
    const isItemDraggedOverItself = draggedItemIndex === draggedOverItemIndex;
    if (isItemDraggedOverItself) {
      return;
    }
    const newItems = moveItem(items, draggedItemIndex, draggedOverItemIndex);
    setItems(newItems);
  };

  const handleItemDragEnd = () => {
    draggedItem.current = null;
    onChange(items);
  };

  const handleItemRemove = itemValue => {
    const newItems = items.filter(item => item.value !== itemValue);
    onChange(newItems);
  };

  const handleListDragOver = event => {
    // Prevent occassionally-awkward animation of items when they are dropped
    event.preventDefault();
  };

  const handleListDrop = event => {
    // Prevent FireFox from redirecting to a new browser tab when items are dropped
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <ul
      {...props}
      className={classnames('ReorderableList', className)}
      data-testid='reorderable list'
      onDragOver={handleListDragOver}
      onDrop={handleListDrop}
    >
      {pinnedItems?.map(item => (
        <li
          className='ReorderableList__item ReorderableList__item--isPinned'
          key={item.value}
        >
          {item.label}
        </li>
      ))}

      {items?.map((item, index) => (
        <li
          className='ReorderableList__item'
          draggable={true}
          key={item.value}
          onDragEnd={handleItemDragEnd}
          onDragOver={() => handleItemDragOver(index)}
          onDragStart={event => handleItemDragStart(event, index)}
        >
          <span
            className='ReorderableList__label'
            data-testid={`reorderable item ${item.label}`}
          >
            {item.label}
          </span>
          <button
            className='ReorderableList__remove'
            data-testid={`reorderable item delete button ${item.label}`}
            onClick={() => handleItemRemove(item.value)}
            title='Remove Column'
            type='button'
          >
            ×
          </button>
        </li>
      ))}
    </ul>
  );
};

ReorderableList.defaultProps = {
  className: '',
};

ReorderableList.propTypes = {
  /** Additional className to render on the root node */
  className: PropTypes.string,
  /** Function called with the updated list of items when their order changes. */
  onChange: PropTypes.func.isRequired,
  /** Items that are pinned to the beginning of the list and cannot be reordered. */
  pinnedItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    }),
  ).isRequired,
  /** Re-orderable items to render in the list (semi-controlled) */
  reorderableItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    }),
  ).isRequired,
};

export default ReorderableList;

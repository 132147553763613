import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import noteSchema from '../schema';

/**
 * Creates an action for updating a note
 * @param {Object} payload
 * @param {Object} payload.note The note properties
 * @param {Number} payload.note.id The note ID
 * @param {Number[]} payload.note.searches The array of search IDs the note is associated with
 */
export default ({ note: { searches, ...note }, ...rest }) =>
  updateEntity({
    ...rest,
    body: {
      note: {
        search_ids: searches,
        ...note,
      },
    },
    entityType: noteSchema.key,
    id: note.id,
    responseSchema: { note: noteSchema },
    url: routes.api_v1_note({ id: note.id }),
  });

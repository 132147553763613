import PropTypes from 'prop-types';
import { withProps, setDisplayName, setPropTypes, compose } from 'recompose';
import Avatar from 'modules/core/componentsLegacy/Avatar';
import mapUserIdToUser from './mapUserIdToUser';

/**
 * Renders the avatar image for a user.
 */
export default compose(
  setDisplayName('UserAvatar'),
  setPropTypes({
    userId: PropTypes.number.isRequired,
  }),
  mapUserIdToUser,
  withProps(({ user }) => ({
    url: user.get('avatar_url'),
    className: 'UserAvatar',
  })),
)(Avatar);

import React from 'react';
import Card from '@thrivetrm/ui/components/Card';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import { useGetOffLimitsHistoricalReasonsQuery } from 'services/apiV1/offLimits';
import useQuickView from '../useQuickView';
import QuickViewPanelHeader from '../QuickViewPanelHeader';
import OffLimitsCard from './OffLimitsCard';
import ContactQvpHeader from '../ContactQvpHeader';

const OffLimitsHistoricalList = () => {
  const { close, navigateTo, pathParams, title } = useQuickView();
  const { recordId, recordType } = pathParams;

  const isCompany = recordType === 'company';

  const {
    data: historicalReasons,
    error: loadReasonsError,
    isLoading: isLoadingReasons,
  } = useGetOffLimitsHistoricalReasonsQuery({
    recordId: recordId,
    recordType: recordType,
  });

  const handleBack = () => navigateTo(`/${recordType}/${recordId}/off-limits`);

  const errorCard = (
    <Card isCentered={false}>
      <p>Unable to retrieve Historical Reasons, please try again</p>
    </Card>
  );

  const historicalReasonCards = historicalReasons.map(reason => (
    <OffLimitsCard
      key={reason.id}
      reason={reason}
      recordId={recordId}
      recordName={title}
      recordType={recordType}
    />
  ));

  return (
    <>
      {isCompany ? (
        <SidePanel.Header
          className='u-marginBottom-12'
          onBack={handleBack}
          onClose={close}
          title={title}
        />
      ) : (
        <ContactQvpHeader contactId={recordId} onBack={handleBack} />
      )}
      <QuickViewPanelHeader title='Historical Off Limits Reasons' />
      <SidePanel.Body className='u-paddingTop-n'>
        <LoadingContainer
          className='u-paddingTop-24'
          isLoading={isLoadingReasons}
          minHeight={300}
        >
          {loadReasonsError && !isLoadingReasons
            ? errorCard
            : historicalReasonCards}
          <div className='u-marginTop-24 u-borderTop u-paddingTop-24'>
            <ButtonSecondary
              className='OffLimitsHistoricalList__viewActiveReasonButton'
              icon='schedule'
              isFullWidth={true}
              label='View Active Reasons'
              onClick={handleBack}
            />
          </div>
        </LoadingContainer>
      </SidePanel.Body>
    </>
  );
};

export default OffLimitsHistoricalList;

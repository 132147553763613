import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import TabbedHeaderTabsCollapsed from './TabbedHeaderTabsCollapsed';
import TabbedHeaderTabsInline from './TabbedHeaderTabsInline';

/**
 * Renders the tabs selection at the top of the TabbedHeaderContainer
 *
 * When `collapseTabs` is true this renders a dropdown menu, otherwise the tabs will
 * be rendered inline, horizontally.
 */
const TabbedHeaderTabs = ({ collapseTabs, selectedTab, tabs }) => {
  const Wrapper = collapseTabs
    ? TabbedHeaderTabsCollapsed
    : TabbedHeaderTabsInline;
  const currentTab = tabs.find(({ id }) => id === selectedTab);

  return (
    <Wrapper title={currentTab && currentTab.title}>
      {tabs.map(({ id, linkComponent }) => (
        <li
          className={classnames({
            active: selectedTab === id,
          })}
          key={id}
          role='presentation'
        >
          {linkComponent}
        </li>
      ))}
    </Wrapper>
  );
};

TabbedHeaderTabs.propTypes = {
  /**
   * True to collapse the tabs into a dropdown menu, false to display them inline horizontally.
   */
  collapseTabs: PropTypes.bool,

  /**
   * The key of the currently selected tab.
   */
  selectedTab: PropTypes.string.isRequired,

  /**
   * The array ofthe currently available tabs to render.
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      linkComponent: PropTypes.node.isRequired,
      title: PropTypes.node.isRequired,
    }).isRequired,
  ).isRequired,
};

TabbedHeaderTabs.defaultProps = {
  collapseTabs: false,
};

export default TabbedHeaderTabs;

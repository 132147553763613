import { List } from 'immutable';
import { compose, setDisplayName, setStatic } from 'recompose';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';

import ContactMultiSelect from './ContactMultiSelect';

/**
 * An enhanced ContactMultiSelect that takes a fieldState and handles converting
 * that into an appropriate value.
 */
export default compose(
  setDisplayName('ContactMultiSelectField'),
  setStatic(
    'createFieldState',
    (name, value, validator, convertToRaw, ...args) =>
      FieldState.create(
        name,

        // Make sure the value is an array and not an immutable list.
        List.isList(value) ? value.toJS() : value || [],

        validator,

        // This convertToRaw implementation makes sure that this field value is an array of IDs
        fieldValue => fieldValue.map(idOrObject => idOrObject.id || idOrObject),

        ...args,
      ),
  ),
  withFormGroup,
  asField(),
)(ContactMultiSelect);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setStatic } from 'recompose';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import QuerySelect, { defaultCreateOptions } from '../forms/QuerySelect';
import NewContactButton from './NewContactButton';

/**
 * This will notify the server that the contact does not yet exist.
 */
const NEW_OPTION_ID = 0;

/**
 * Creates the fake "Create new contact..." option that gets displayed in the list of
 * matches when the component has `isCreatable` set to true.
 * @param {String} [value=''] The text that was entered in the input.
 * @return {Object} A result object that can be passed as an option to the QuerySelect.
 */
const newOptionCreator = (value = '') => ({
  id: NEW_OPTION_ID,
  name: value ? `Create new contact "${value}"...` : 'Create new contact...',
  value: value,
  className: 'Select-create-option-placeholder',
});

/**
 * Takes the query results from the server and appends a fake "Create new contact..." option
 * to the end.
 */
const appendCreateContactToOptions = ({
  data,
  isLoading,
  isMinimumLength,
  term,
}) => {
  if (!data || !isMinimumLength || isLoading) {
    return [];
  }

  // Takes the results returned from ther server and adds a fake "Create new contact"
  // item to the list. When this is selected we'll show the dialog (see `handleChange`)
  return data.push(newOptionCreator(term)).toJS();
};

export default class ContactSelect extends Component {
  constructor(props) {
    super(props);

    this.componentId = uniqueId();
  }

  componentDidMount() {
    const { contactActions, contacts, onChange, value } = this.props;

    if (value && value.id && !value.name) {
      // If the initial value is simply an ID, then we need to try to lookup the name to display
      // in the dropdown. If we don't have that contact record loaded we'll have to fetch it.
      const contactRecord = contacts.getIn(['byId', value.id]);
      if (contactRecord) {
        onChange({
          ...contactRecord.toJS(),
          name: contactRecord.get('name', contactRecord.get('full_name')),
        });
      } else {
        contactActions.fetchContact({ contactId: value.id });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { contacts, onChange, value: nextValue } = nextProps;
    const { value } = this.props;

    if (nextValue && nextValue === value && value.id && !value.name) {
      // We have a value with an ID but no name (therefore no label). We are probably waiting for
      // the fetchContact call for that contact to complete, so check to see if we now have a
      // contact record matching that ID.
      const contactRecord = contacts.getIn(['byId', value.id]);
      if (contactRecord) {
        onChange({
          ...contactRecord.toJS(),
          name: contactRecord.get('name', contactRecord.get('full_name')),
        });
      }
    }
  }

  setNewContactButtonRef = newContactButton => {
    this.newContactButton = newContactButton;
  };

  handleContactCreated = contact => {
    const { onChange } = this.props;
    onChange({
      id: contact.id,
      name: contact.full_name,
      title: contact.primary_title,
      company: contact.primary_company_name,
      company_id: contact.primary_company_id,
      data: contact,
    });
  };

  handleChange = selectedValue => {
    const { onChange } = this.props;

    // If `newContactButton` has a value, that means this ContactSelect is "Creatable", allowing
    // new contacts to be created in a modal. In that case, see if the fake "Create new contact..."
    // option was the one selected, and if so, show the create modal.
    if (
      this.newContactButton &&
      selectedValue &&
      selectedValue.id === NEW_OPTION_ID
    ) {
      // Really dumb "name parser" (if it can be called that). This splits the entered
      // string value on the first space found, then prepopulates the new contact form
      // where the first half of the split string is the first name, and the second half is the
      // last name.
      const name = selectedValue.value;
      const splitIndex = name.indexOf(' ');
      this.newContactButton.show({
        first_name: splitIndex ? name.substring(0, splitIndex).trim() : name,
        last_name: splitIndex ? name.substring(splitIndex).trim() : null,
      });
      return;
    }

    if (onChange) {
      onChange(selectedValue);
    }
  };

  render() {
    const {
      clearTransaction,
      companies,
      companyActions,
      contactActions,
      contacts,
      disabled,
      isCreatable,
      // Prevent props from being passed through to the QuerySelect
      /* eslint-disable-next-line no-unused-vars */
      onChange,
      transactions,
      value,
      ...props
    } = this.props;

    const isFetchingSelectedContact = value && value.id && !value.name;

    return (
      <div
        className={classnames('contact-select', {
          'is-creatable': isCreatable,
        })}
      >
        <QuerySelect
          createOptions={
            isCreatable ? appendCreateContactToOptions : defaultCreateOptions
          }
          disabled={disabled}
          isCreatable={false}
          isLoading={isFetchingSelectedContact}
          newOptionCreator={newOptionCreator}
          onChange={this.handleChange}
          onCreate={contactActions.createContactsQuery}
          onDestroy={contactActions.destroyContactsQuery}
          onQuery={contactActions.queryContacts}
          queriesById={contacts.get('queriesById')}
          value={value}
          {...props}
        />
        {isCreatable && !disabled && (
          <NewContactButton
            allowUpload={false}
            className='contact-select-create'
            clearTransaction={clearTransaction}
            companies={companies}
            companyActions={companyActions}
            contactActions={contactActions}
            contacts={contacts}
            onCreated={this.handleContactCreated}
            ref={this.setNewContactButtonRef}
            transactions={transactions}
          >
            <OverlayTrigger
              overlay={
                <Tooltip id={this.componentId}>
                  <div>Create New Contact</div>
                </Tooltip>
              }
              placement='top'
            >
              <i className='fa fa-user-plus' />
            </OverlayTrigger>
          </NewContactButton>
        )}
      </div>
    );
  }
}

ContactSelect.propTypes = {
  /**
   * When allowing new contacts to be created (`isCreatable` is true), we need to track the save
   * operation, which means we need to be able to dispatch the clearTransaction action.
   */
  clearTransaction: requiredIf(PropTypes.func, props => props.isCreatable),

  /**
   * The companies search results state. @see companyActions.
   */
  companies: ImmutablePropTypes.map, // eslint-disable-line react/forbid-prop-types

  /**
   * These are only needed when adding a new contact where we want to allow the user to be able
   * to also specify a company for that new contact. If these are not provided (along with
   * `companies`), no company input will be displayed when creating new contacts.
   */
  companyActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }),

  /**
   * The actions to dispatch for querying contacts.
   */
  contactActions: PropTypes.shape({
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    queryContacts: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * The contacts state which contains the contain queries so we can load the results of our
   * search.
   */
  contacts: ImmutablePropTypes.mapContains({
    byId: ImmutablePropTypes.mapOf(
      ImmutablePropTypes.mapContains({
        full_name: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      PropTypes.number,
    ).isRequired,
    queriesById: ImmutablePropTypes.map.isRequired,
  }).isRequired,

  disabled: PropTypes.bool,

  /**
   * True to allow new contacts to be created from this input via a modal dialog.
   */
  isCreatable: PropTypes.bool,

  /**
   * Called when the selected contact is changed, with the selected contact object as the
   * first parameter value.
   */
  onChange: PropTypes.func,

  /**
   * The transactions store. This is only needed when allowing the user to create new contacts
   * using this input because the save operation needs to be tracked.
   */
  transactions: requiredIf(ImmutablePropTypes.map, props => props.isCreatable),

  value: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

ContactSelect.defaultProps = {
  disabled: false,
  isCreatable: true,
};

/**
 * Generates an appropriate `FieldState` for the ContactSelect.
 * This ensures that the underlying value is an object with a
 * `name` and `id`.
 * @param {[type]} fieldName The name of the field
 * @param {String|{ name: String }|Number|Immutable.Map({ name: String })|null} value A string
 *   representing the company name, or a object or Immutable Map which has a name
 *   property representing the company name, or a contact ID.
 * @param {[type]} args Additional arguments passed to FieldState.create after the value.
 * @return {[type]} [description]
 * @throws {Error} The value is not a string or an object with a name property (which is a string)
 */
const createFieldState = (fieldName, value, ...args) => {
  if (!value) {
    return FieldState.create(fieldName, null, ...args);
  }
  if (typeof value === 'number') {
    return FieldState.create(fieldName, { id: value }, ...args);
  }
  if (typeof value === 'string') {
    return FieldState.create(fieldName, { name: value }, ...args);
  }
  if (typeof value.name === 'string') {
    return FieldState.create(fieldName, value, ...args);
  }

  throw new Error(`Invalid FieldState value for ContactSelect.
    Expected a string or an object with a name property of type string, or a numeric ID, but got
    ${value.toString()}`);
};

export const ContactSelectField = compose(
  setStatic('createFieldState', createFieldState),
  withFormGroup,
  asField(),
)(ContactSelect);

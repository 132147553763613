import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import AppointmentField from './AppointmentField';
import handleSubmit from './handleSubmit';
import connectAppointmentForm from './connectAppointmentForm';
import createRootFieldState from './createRootFieldState';
import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';
import withDraftStorage from '../../../components/forms/withDraftStorage';

export const propTypes = {
  interview: ImmutablePropTypes.mapContains({
    id: PropTypes.number,
  }),
  interviewId: PropTypes.number,
  candidacyId: requiredIf(
    PropTypes.number,
    ({ contactId, interview, interviewId, meeting, meetingId }) =>
      !(meeting || meetingId || contactId || interview || interviewId),
  ),
  contactId: requiredIf(
    PropTypes.number,
    ({ candidacyId, interview, interviewId, meeting, meetingId }) =>
      !(interview || interviewId || meeting || meetingId || candidacyId),
  ),
};

export const omitProps = [
  'candidacyId',
  'currentUserId',
  'formState',
  'onSaved',
  'interview',
  'interviewId',
  'meeting',
  'meetingId',
  'interviewActions',
  'meetingActions',
  'searchId',
  'transactionActions',
  'recruiterName',
  'userActions',
  'shouldFetchUserOptions',
  'isFetchingUserOptions',
];

export default compose(
  setPropTypes(propTypes),
  connectAppointmentForm,
  withDraftStorage(),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'appointment-form',
    omitProps: omitProps,
  }),
)(AppointmentField);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import isAdmin from 'modules/auth/selectors/isAdmin';
import isCrmUser from 'modules/auth/selectors/isCrmUser';
import ContactConnectionsField from 'modules/contact-connections/components/ContactConnectionsField';
import ContactDiversityField from 'modules/contact-diversity/components/ContactDiversityField';
import FieldState from 'modules/forms/FieldState';
import EducationListField from 'modules/contacts/components/educations/EducationListField';
import ContactPositionListField from 'modules/contacts/components/positions/ContactPositionListField';
import ContactConnectionListField from 'modules/contacts/components/contactConnection/ContactConnectionListField';
import SearchMultiSelectField from 'modules/searches/components/SearchMultiSelectField';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import PluralText from '@thrivetrm/ui/components/PluralText';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import NetworkMultiSelectField from 'modules/networks/components/NetworkMultiSelectField';
import getDefaultCompany from 'modules/tenant/selectors/getDefaultCompany';
import { scrollToElement } from 'modules/core/scrollUtils';
import SkillTagSelectField from 'modules/tenant/components/SkillTagSelectField';
import Tag from 'modules/core/components/Tag';
import ContactHeaderField from './header/ContactHeaderField';
import ContactSocialField from './social/ContactSocialField';
import AvatarUploaderField from './AvatarUploaderField';
import ContactDuplicates from './basic/ContactDuplicates';
import { getDuplicateContacts } from '../../domains/contacts/duplicateRecognition';

class ContactReviewField extends Component {
  static createFieldState = (
    name = 'contact',
    {
      connectionList,
      contact,
      contactReviews,
      educations,
      existingContactLinkedInSkills,
      hasSkyminyrFeature,
      match,
      positions,
    },
  ) => {
    const contactReviewId = parseInt(match.params.contactReviewId);
    const contactReviewsLinkedInSkills =
      contactReviews
        ?.getIn(['data', contactReviewId, 'data', 'contact_linkedin_skills'])
        ?.toJS()
        .map(skill => skill.attributes.skill_name) ?? [];
    const linkedInSkillsToUse = contactReviewsLinkedInSkills.length
      ? contactReviewsLinkedInSkills
      : existingContactLinkedInSkills;

    return FieldState.createNested(name, [
      ContactHeaderField.createFieldState('header', contact),
      ContactSocialField.createFieldState(
        'social',
        contact.get('data').withMutations(map => {
          map.set('contact_source', map.getIn(['contact_source', 'id']));
        }),
      ),
      ContactDiversityField.createFieldState(
        'diversity',
        contact.get('data').withMutations(map => {
          map.set('gender', map.getIn(['gender', 'id']));
          map.set('birth_year', map.getIn(['birth_year']));
          map.set('disability_status', map.getIn(['disability_status', 'id']));
          map.set(
            'sexual_orientation',
            map.getIn(['sexual_orientation', 'id']),
          );
          map.set('veteran_status', map.getIn(['veteran_status', 'id']));
        }),
      ),
      ContactConnectionsField.createFieldState(
        'connections',
        contact.get('data').withMutations(map => {
          // Until this data gets normalized we have to fake it.
          map.set('reports_to', map.getIn(['reports_to', 'id']));
          map.set('referred_by', map.getIn(['referred_by', 'id']));
          map.set('referred_by_firm', map.getIn(['referred_by_firm', 'id']));
          map.set(
            'relationship_manager',
            map.getIn(['relationship_manager', 'id']),
          );
          map.set('interaction_level', map.getIn(['interaction_level', 'id']));
        }),
      ),
      ContactConnectionListField.createFieldState(
        'connectionList',
        connectionList,
      ),
      ContactPositionListField.createFieldState(
        'positions',
        positions,
        hasSkyminyrFeature,
      ),
      SkillTagSelectField.createFieldState(
        'skills',
        contact
          .getIn(['data', 'skills'])
          ?.toJS()
          .map(skill => skill.id),
      ),
      // NOTE - this is a non-editable field, we're simply mapping data in so it saves correctly
      SkillTagSelectField.createFieldState(
        'contact_linkedin_skills',
        linkedInSkillsToUse,
      ),
      EducationListField.createFieldState('educations', educations),
      SearchMultiSelectField.createFieldState(
        'searches',
        contact.getIn(['data', 'searches'])?.toJS(),
      ),
      NetworkMultiSelectField.createFieldState(
        'networks',
        contact.getIn(['data', 'networks'])?.toJS(),
      ),
      SearchMultiSelectField.createFieldState(
        'pipelines',
        contact.getIn(['data', 'pipelines'])?.toJS(),
      ),
      AvatarUploaderField.createFieldState(
        'avatar',
        contact.getIn(['data', 'new_avatar_url']),
      ),
    ]);
  };

  duplicateDetectionId = uniqueId();

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      adminUser,
      companies,
      companyActions,
      contact,
      contactActions,
      contactPositions,
      contacts,
      crmUser,
      defaultCompanyId,
      existingContactLinkedInSkills,
      fieldState,
      hasConnectionsFeature,
      hasDiversity,
      hasLinkedInSkillsFeature,
      hasPendingContactConnectionsFeature,
      hasPipelineFeature,
      hasThriveSkillsFeature,
      hideDiversityIfActiveCandidate,
      hideDiversityIfCurrentCompanyIsDefault,
      isLoadingExistingLinkedInSkills,
      onUpdate,
      potentialDuplicatePositionsCount,
      showErrors,
      user,
      ...otherProps
    } = this.props;

    const candidateName = contact.getIn(['data', 'full_name']);
    const candidate = user.get('is_candidate');
    const userType = candidate ? 'candidate' : 'noncandidate';
    const contactId = contact.getIn(['data', 'id']);
    const duplicates = getDuplicateContacts(
      this.duplicateDetectionId,
      contacts,
    );
    const currentCompanyIds = contactPositions
      .filter(position => position.get('to') === null)
      .map(position => position.get('company'));

    const currentCompanyIsDefaultCompany =
      defaultCompanyId && currentCompanyIds.includes(defaultCompanyId);

    const hideForDefaultCompany =
      currentCompanyIsDefaultCompany &&
      hideDiversityIfCurrentCompanyIsDefault &&
      !adminUser;

    const hideForActiveCandidate =
      contact.getIn(['data', 'active_candidate']) &&
      hideDiversityIfActiveCandidate &&
      !adminUser;

    const showDiversityField =
      hasDiversity && !hideForDefaultCompany && !hideForActiveCandidate;

    const addressAs = contact.getIn(['data', 'address_as']);
    // get the contact review ID from the route via react-router props
    const contactReviewId = parseInt(otherProps.match.params.contactReviewId);
    // duplicate positions display the source strategy (e.g. "New from LinkedIn")
    const strategy = otherProps.contactReviews.getIn([
      'data',
      contactReviewId,
      'data',
      'strategy',
    ]);

    const contactReviewsLinkedInSkills =
      otherProps.contactReviews
        ?.getIn(['data', contactReviewId, 'data', 'contact_linkedin_skills'])
        ?.toJS()
        .map(skill => skill.attributes.skill_name) ?? [];
    const linkedInSkillsToUse = contactReviewsLinkedInSkills.length
      ? contactReviewsLinkedInSkills
      : existingContactLinkedInSkills;
    const contactLinkedInSkills = linkedInSkillsToUse.map(skill => (
      <Tag key={skill} name={skill} />
    ));

    const renderLinkedInSkills = () => {
      const message = isLoadingExistingLinkedInSkills ? (
        <i>Loading LinkedIn skills</i>
      ) : (
        'There were no skills on this LinkedIn profile.'
      );
      return (
        <>
          <h3>LinkedIn Skills</h3>
          {contactLinkedInSkills?.length ? (
            <>
              <p>
                The skills below will be imported from LinkedIn. They will be
                searchable and filterable.
              </p>
              {contactLinkedInSkills}
            </>
          ) : (
            message
          )}
        </>
      );
    };

    return (
      <div className='container contact-form contact-form-edit-mode'>
        <div className='contact-form-edit-mode-main'>
          {!user.get('limited_access_roles') && (
            <div className='contact-form-edit-mode-section'>
              <div className='row'>
                {crmUser ? null : (
                  <div className='col-sm-6'>
                    <SearchMultiSelectField
                      {...otherProps}
                      fieldState={fieldState.getNestedField('searches')}
                      isCreatable={false}
                      label='Add Contact To Searches'
                      multi={true}
                      noValueText='No Searches Selected'
                      onChange={this.handleFieldChange}
                      placeholder='Enter a Search Name'
                      searchType='JobSearch'
                      summaryLabel='{0} Searches Selected'
                    />
                  </div>
                )}
                <div className='col-sm-6'>
                  <NetworkMultiSelectField
                    {...otherProps}
                    fieldState={fieldState.getNestedField('networks')}
                    isCreatable={false}
                    label='Add Contact to Networks'
                    multi={true}
                    onChange={this.handleFieldChange}
                    placeholder='Enter a Network Name'
                    type='contact'
                  />
                </div>
                {hasPipelineFeature && !crmUser ? (
                  <div className='col-sm-6'>
                    <SearchMultiSelectField
                      {...otherProps}
                      fieldState={fieldState.getNestedField('pipelines')}
                      isCreatable={false}
                      label='Add Contact To Pipelines'
                      multi={true}
                      noValueText='No Pipelines Selected'
                      onChange={this.handleFieldChange}
                      placeholder='Enter a Pipeline Name'
                      searchType='Pipeline'
                      summaryLabel='{0} Pipelines Selected'
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )}
          <div className='contact-form-edit-mode-section'>
            <ContactDuplicates
              duplicates={duplicates}
              hideProfileLink={user.get('limited_access_roles')}
              onUpdate={onUpdate}
              showUpdateButton={true}
            />

            {potentialDuplicatePositionsCount ? (
              <div className='u-flex u-flexJustify-c u-marginTop-12'>
                <Card
                  className='u-inlineBlock u-padding-24'
                  data-testid='duplicate position warning card'
                  isCentered={false}
                  type='warning'
                >
                  <div className='u-marginBottom-16'>
                    <StatusIndicator
                      className='u-marginRight-8'
                      status='warning'
                    />
                    Hold on! We found{' '}
                    <b>
                      {potentialDuplicatePositionsCount} potential duplicate
                    </b>{' '}
                    <PluralText
                      quantity={potentialDuplicatePositionsCount}
                      text='positions'
                    />
                  </div>
                  <ButtonPrimary
                    isFullWidth={true}
                    label='Resolve Duplicates'
                    // scroll to first potential duplicate
                    onClick={() => {
                      const positionElement = document.querySelector(
                        '.duplicatePositions__heading',
                      );
                      scrollToElement({
                        element: positionElement,
                        topOffset: 120,
                      });
                    }}
                  />
                </Card>
              </div>
            ) : null}

            <h2 className='contact-form-section-header u-marginBottom-24'>
              Basic Information
            </h2>
            <div className='row'>
              <div className='col-md-3'>
                <AvatarUploaderField
                  fieldState={fieldState.getNestedField('avatar')}
                  initialPreview={contact.getIn(['data', 'avatar_url'])}
                  onChange={this.handleFieldChange}
                />
              </div>

              <div className='col-md-9'>
                <ContactHeaderField
                  addressAs={addressAs}
                  companies={companies}
                  companyActions={companyActions}
                  contactActions={contactActions}
                  contacts={contacts}
                  duplicateDetectionId={this.duplicateDetectionId}
                  fieldState={fieldState.getNestedField('header')}
                  hasGridView={true}
                  onChange={this.handleFieldChange}
                  queryDuplicates={!contactId}
                  showEmailFields={false}
                  showErrors={showErrors}
                  showOffLimitsOptionsFields={false}
                />
              </div>
            </div>
          </div>

          <div className='contact-form-edit-mode-section'>
            <h2 className='contact-form-section-header u-marginBottom-24'>
              Contact Details
            </h2>

            <ContactSocialField
              contactActions={contactActions}
              contacts={contacts}
              duplicateDetectionId={this.duplicateDetectionId}
              fieldState={fieldState.getNestedField('social')}
              onChange={this.handleFieldChange}
              queryDuplicates={!contactId}
              showErrors={showErrors}
              userType={userType}
            />
            {hasThriveSkillsFeature ? (
              <>
                <h3>Skills</h3>
                <p>
                  Select and add related skill tags managed by your Thrive
                  admin.
                </p>
                <SkillTagSelectField
                  contactId={contactId}
                  fieldState={fieldState.getNestedField('skills')}
                  onChange={this.handleFieldChange}
                />
              </>
            ) : null}
            {hasLinkedInSkillsFeature ? renderLinkedInSkills() : null}
          </div>

          {showDiversityField ? (
            <div className='contact-form-edit-mode-section'>
              <h2 className='contact-form-section-header u-marginBottom-24'>
                Demographics
              </h2>

              <ContactDiversityField
                candidateName={candidateName}
                disabilityStatusSelfReported={contact.getIn([
                  'data',
                  'disability_status_self_reported',
                ])}
                fieldState={fieldState.getNestedField('diversity')}
                genderSelfReported={contact.getIn([
                  'data',
                  'gender_self_reported',
                ])}
                onChange={this.handleFieldChange}
                raceEthnicitySelfReported={contact.getIn([
                  'data',
                  'race_ethnicity_self_reported',
                ])}
                sexualOrientationSelfReported={contact.getIn([
                  'data',
                  'sexual_orientation_self_reported',
                ])}
                showErrors={showErrors}
                veteranStatusSelfReported={contact.getIn([
                  'data',
                  'veteran_status_self_reported',
                ])}
              />
            </div>
          ) : null}

          {hasConnectionsFeature && hasPendingContactConnectionsFeature ? (
            <div className='contact-form-edit-mode-section'>
              <ContactConnectionListField
                fieldState={fieldState.getNestedField('connectionList')}
                onChange={this.handleFieldChange}
              />
            </div>
          ) : null}

          {hasConnectionsFeature ? null : (
            <div className='contact-form-edit-mode-section'>
              <h2 className='contact-form-section-header u-marginBottom-24'>
                Connections
              </h2>
              <ContactConnectionsField
                fieldState={fieldState.getNestedField('connections')}
                onChange={this.handleFieldChange}
                showErrors={showErrors}
              />
            </div>
          )}

          <div className='contact-form-edit-mode-section'>
            <ContactPositionListField
              fieldState={fieldState.getNestedField('positions')}
              onChange={this.handleFieldChange}
              positionIdsRenderStructure={
                this.props?.positionIdsRenderStructure
              }
              setPositionIdsRenderStructure={
                this.props?.setPositionIdsRenderStructure
              }
              showErrors={showErrors}
              strategy={strategy}
            />
          </div>

          <div className='contact-form-edit-mode-section'>
            <EducationListField
              fieldState={fieldState.getNestedField('educations')}
              onChange={this.handleFieldChange}
              showErrors={showErrors}
            />
          </div>
        </div>
      </div>
    );
  }
}

ContactReviewField.propTypes = {
  adminUser: PropTypes.bool.isRequired,

  companies: ImmutablePropTypes.mapContains({
    queriesById: ImmutablePropTypes.map.isRequired,
  }).isRequired,

  companyActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }).isRequired,

  contact: ImmutablePropTypes.mapContains({
    data: PropTypes.shape({
      avatar_url: PropTypes.string,
    }),
  }),

  contactActions: PropTypes.shape({
    createDuplicateContactsQuery: PropTypes.func.isRequired,
    destroyDuplicateContactsQuery: PropTypes.func.isRequired,
    queryDuplicateContacts: PropTypes.func.isRequired,
  }).isRequired,

  contactPositions: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      to: PropTypes.string,
      company: PropTypes.number,
    }).isRequired,
  ).isRequired,

  contacts: ImmutablePropTypes.mapContains({
    duplicatesById: ImmutablePropTypes.map.isRequired,
  }),

  crmUser: PropTypes.bool.isRequired,

  defaultCompanyId: PropTypes.number,

  existingContactLinkedInSkills: PropTypes.arrayOf(PropTypes.string).isRequired,

  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  hasConnectionsFeature: PropTypes.bool.isRequired,
  hasDiversity: PropTypes.bool.isRequired,
  hasLinkedInSkillsFeature: PropTypes.bool.isRequired,
  hasPendingContactConnectionsFeature: PropTypes.bool.isRequired,
  hasPipelineFeature: PropTypes.bool.isRequired,
  hasThriveSkillsFeature: PropTypes.bool.isRequired,
  hideDiversityIfActiveCandidate: PropTypes.bool.isRequired,

  hideDiversityIfCurrentCompanyIsDefault: PropTypes.bool.isRequired,

  isLoadingExistingLinkedInSkills: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,

  onUpdate: PropTypes.func,

  positionIdsRenderStructure: PropTypes.arrayOf(PropTypes.node),
  potentialDuplicatePositionsCount: PropTypes.number,

  setPositionIdsRenderStructure: PropTypes.func,

  showErrors: PropTypes.bool,

  user: ImmutablePropTypes.mapContains({
    limited_access_roles: PropTypes.bool.isRequired,
  }).isRequired,
};

export default compose(
  withFeatureCheck('feature.diversity', 'hasDiversity'),
  withFeatureCheck('feature.connections_gate', 'hasConnectionsFeature'),
  withFeatureCheck(
    'feature.pending_contacts_connections',
    'hasPendingContactConnectionsFeature',
  ),
  withFeatureCheck(
    'feature.diversity.hide_for_active_candidates',
    'hideDiversityIfActiveCandidate',
  ),
  withFeatureCheck(
    'feature.diversity.hide_for_default_company',
    'hideDiversityIfCurrentCompanyIsDefault',
  ),
  withFeatureCheck('feature.pipeline_mvp', 'hasPipelineFeature'),
  withFeatureCheck(
    'development.skills_on_contact_review',
    'hasThriveSkillsFeature',
  ),
  withFeatureCheck('feature.linkedin_skills', 'hasLinkedInSkillsFeature'),
  connect(
    state => ({
      adminUser: isAdmin(state),
      crmUser: isCrmUser(state),
      defaultCompanyId: getDefaultCompany(state),
    }),
    {},
  ),
)(ContactReviewField);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose, setDisplayName, setStatic } from 'recompose';
import FieldState from 'modules/forms/FieldState';
import * as validators from 'modules/forms/validators';
import EmailField from '../../../../components/forms/EmailField';

import ContactSummaryReportContactInfoField from './ContactSummaryReportContactInfoField';

/**
 * Renders a field for selecting the options for generating the contact summary report.
 */
class ContactSummaryReportField extends PureComponent {
  static createFieldState(name = 'candidacy', currentUserEmail) {
    const validator = null;
    const childFields = [
      ContactSummaryReportContactInfoField.createFieldState(
        'contactInfo',
        undefined,
      ),
      EmailField.createFieldState(
        'emails',
        currentUserEmail,
        validators.requiredField('An email address'),
      ),
    ];
    const convertToRaw = ({ contactInfo, ...rest }) => ({
      fields: [...contactInfo],
      ...rest,
    });

    return FieldState.createNested(name, childFields, validator, convertToRaw);
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    let newFieldState = fieldState.setNestedField(childFieldState);
    const contactInfoFieldState = newFieldState
      .getNestedField('contactInfo')
      .setValidator(null);
    newFieldState = newFieldState.setNestedField(contactInfoFieldState);
    onChange(newFieldState);
  };

  render() {
    const {
      fieldState,

      ...rest
    } = this.props;

    return (
      <div className='ContactSummaryReportField'>
        <div>
          <ContactSummaryReportContactInfoField
            {...rest}
            fieldState={fieldState.getNestedField('contactInfo')}
            isExpanded={true}
            onChange={this.handleFieldChange}
          />
        </div>

        <div>
          <h2>Recipients</h2>
          <EmailField
            {...rest}
            clearable={false}
            fieldState={fieldState.getNestedField('emails')}
            label='Emails*'
            onChange={this.handleFieldChange}
            wrapperClassName='email-field'
          />
        </div>
      </div>
    );
  }
}

ContactSummaryReportField.propTypes = {
  /**
   * The ID of the contact to generate the report for
   */
  contactId: PropTypes.number,

  /**
   *  Returns true if the current user is an employee user (Partner, Recruiter, Lead and Employee).
   */
  employeeUser: PropTypes.bool,

  /**
   * The current value of this field.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the `fieldState` value changes.
   */
  onChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('ContactSummaryReportField(enhanced)'),
  setStatic('createFieldState', ContactSummaryReportField.createFieldState),
)(ContactSummaryReportField);

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import serialize from 'form-serialize';
import routes from 'modules/routing/routes';
import url from 'modules/routing/url';
import FeatureOptInDialog from 'modules/contacts/components/FeatureOptInDialog';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { setRecordTypeConfig } from './recordIndexSlice';
import { generateContactFilterSummary } from '../../lib/filterUtils';
import { initializeFilters } from '../../pages/contacts';
import ReportModal from '../../lib/report_modal';
import NewContactButton from '../../containers/NewContactButton';
import { BulkActionTypes, BulkTagCategoryTypes } from './constants';
import RecordIndexPageContainer from './RecordIndexPageContainer';

const {
  ADD_TO_NETWORK,
  ADD_TO_PIPELINE,
  ADD_TO_SEARCH,
  BULK_EDIT_TAGS,
  DELETE,
} = BulkActionTypes;
const { LANGUAGES, SKILLS } = BulkTagCategoryTypes;

const CreateButton = () => (
  <NewContactButton className='u-noWrap' icon='add'>
    Add Contact
  </NewContactButton>
);

// Endpoints that return data from /api
const endpoints = {
  bulkActions: recordIds => url('/api/v1/contacts/bulk', { ids: recordIds }),
  records: query => routes.api_v1_contacts({ query: query }),
  savedViews: () => routes.api_v1_contacts_saved_views(),
  savedView: id => routes.api_v1_contacts_saved_view({ id: id }),
  customReport: routes.custom_report_api_v1_contacts(),
  customReportTemplates: null,
};

// The filter summary is an array of objects containing the data necessary
// to render a user-facing summary:
// [{
//   fieldName: 'languages',
//   label: 'Languages'
//   value: ['Cantonese'],
// }]
//
// Notably, it contains the labels of associated values (extracted from the
// modal DOM), whereas the URL and JSON serializations of the form only include
// the IDs of associations... and we can't show the user "Languages: 37, 92"
const generateFilterSummaryFromJQueryModal = () => {
  const reportModal = new ReportModal();
  generateContactFilterSummary(reportModal);
  return reportModal.filters;
};

// Filter JSON is just the form input values serialized to JSON. We ultimately
// use this to combine with other values to serialize into a URL query string.

const generateFilterParamsFromJQueryModal = () => {
  const form = document.querySelector('.form.filters-form');
  const newFilterParams = serialize(form, { hash: true }).filters || {};
  // The form in the filter modal always sets the sort_field with a value of 'relevance' via
  // a hidden input. We manage sort_field as sortField in redux and want to ignore the form value.
  if (newFilterParams.sort_field === 'relevance') {
    delete newFilterParams.sort_field;
  }
  return newFilterParams;
};

const shouldSortByRelevance = (savedViewFilters = {}) =>
  Boolean(
    savedViewFilters.company?.length ||
      savedViewFilters.keyword?.length ||
      savedViewFilters.name?.length ||
      savedViewFilters.phoneNormalized?.length ||
      savedViewFilters.position?.length,
  );

// Our mechanism for interacting with the legacy jQuery filter modal
const filterAdapter = {
  closeModal: () => $('#filters').modal('hide'),
  formEl: () => document.querySelector('.form.filters-form'),
  generateFilterParams: generateFilterParamsFromJQueryModal,
  generateFilterSummary: generateFilterSummaryFromJQueryModal,
  keywordInputEl: () => document.getElementById('filter_keyword_advanced'),
  resetLinkEl: () => document.getElementById('filters__reset-link'),
  shouldSortByRelevance: shouldSortByRelevance,
  clearForm: () => {
    //  window.clearContactFilters() is a bridge exposed via app/views/contacts/_advanced_search.html.slim
    window.clearContactFilters();
    initializeFilters();
  },
  openModal: () => $('#filters').modal('show'),
};

// Urls for the browser
const urls = {
  savedView: id => routes.saved_view_contacts({ id: id.toString() }),
};

const payloadKeys = {
  bulkTagRecordIdsKey: 'contact_ids',
  userDefaultSavedViewId: 'contacts_default_id',
};

const additionalCandidateDetailsOptions = [
  { label: 'Candidate Stage', value: 'candidate_stage' },
  {
    label: 'Highest Stage Reached (This Search)',
    value: 'highest_stage_reached',
  },
  { label: 'Rejection Reason', value: 'rejection_reason' },
  { label: 'Rejection Comments', value: 'rejection_comments' },
  { label: 'Date Added to Search', value: 'date_added_to_search' },
  { label: 'Added By', value: 'candidacy_created_by' },
];

const ContactRecordIndexPage = () => {
  const dispatch = useDispatch();

  const { recordType } = useSelector(state => state.recordIndex);

  const defaultSavedViewId = useSelector(state =>
    state.user.get('contacts_default_saved_view_id'),
  );

  const hasBetaOptInFeature = useFeatureCheck('beta_optin.filters_v5');

  const canExportCustomReports = useFeatureCheck(
    'feature.contacts_custom_report_generator',
  );

  // On mount, set the record type config in the store.
  useEffect(() => {
    if (canExportCustomReports) {
      endpoints.customReportTemplates = routes.custom_report_templates_api_v1_contacts();
    }

    const config = {
      candidateExportFields: additionalCandidateDetailsOptions,
      CreateButton: CreateButton,
      defaultSavedViewId: defaultSavedViewId,
      endpoints: endpoints,
      filterAdapter: filterAdapter,
      isSidebarExpandedKey: 'isSidebarExpanded--contacts',
      networkType: 'ContactNetwork',
      payloadKeys: payloadKeys,
      recordLabel: 'Contact',
      recordLabelPlural: 'Contacts',
      recordType: 'contacts',
      supportedBulkActions: [
        ADD_TO_NETWORK,
        ADD_TO_PIPELINE,
        ADD_TO_SEARCH,
        BULK_EDIT_TAGS,
        DELETE,
      ],
      supportedBulkTagCategories: [SKILLS, LANGUAGES],
      urls: urls,
    };
    dispatch(setRecordTypeConfig(config));
  }, [defaultSavedViewId, canExportCustomReports, dispatch]);

  if (!recordType) {
    return null;
  }

  return (
    <>
      <RecordIndexPageContainer recordType={recordType} />
      {hasBetaOptInFeature ? <FeatureOptInDialog /> : null}
    </>
  );
};

export default ContactRecordIndexPage;

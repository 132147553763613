import React from 'react';
import PropTypes from 'prop-types';
import SeekoutIntegrationCopyThriveAPI from './SeekoutIntegrationCopyThriveAPI';

const SeekoutIntegrationPage = ({ initialSeekoutToken }) => {
  return (
    <>
      <h2 className='u-marginBottom-16'>Seekout + Thrive Integration</h2>
      <p>
        Once configured, this integration will allow you to take candidates from
        Seekout and add them into Thrive.
      </p>
      <SeekoutIntegrationCopyThriveAPI
        initialSeekoutToken={initialSeekoutToken}
      />
      <h3 className='u-marginBottom-16'>2. Set up integration in Seekout</h3>
      <p>
        Next, use the Thrive API Token above to{' '}
        <a
          href='https://support.seekout.io/hc/en-us/articles/6878957720845'
          rel='noopener noreferrer'
          target='_blank'
        >
          set up the integration in Seekout
        </a>
        .
      </p>
    </>
  );
};

SeekoutIntegrationPage.propTypes = {
  initialSeekoutToken: PropTypes.string,
};

export default SeekoutIntegrationPage;

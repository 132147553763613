import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReferenceListItem from './ReferenceListItem';
import withReferenceIdsSortedByDate from './withReferenceIdsSortedByDate';

/**
 * Renders a list of references, with the ability to edit or delete them
 * inline if the user has permission (based on `can_user_edit` and `can_user_delete`
 * values of the reference item).
 */
const ReferenceList = ({
  candidacyId,
  contactId,
  referenceIds,
  searchId,
  ...props
}) => (
  <ul className='list-generic list-references'>
    {referenceIds
      .map(referenceId => (
        <ReferenceListItem
          {...props}
          candidacyId={candidacyId}
          contactId={contactId}
          key={referenceId}
          referenceId={referenceId}
          searchId={searchId}
        />
      ))
      .toArray()}
  </ul>
);

ReferenceList.propTypes = {
  candidacyId: PropTypes.number,
  contactId: PropTypes.number,
  referenceIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
  searchId: PropTypes.number,
};

export default withReferenceIdsSortedByDate(ReferenceList);

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Date from '@thrivetrm/ui/components/Date';
import { useDispatch } from 'react-redux';

import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import DateInput from '@thrivetrm/ui/components/DateInput';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import invalidateContact from 'actions/contacts/invalidateContact';
import {
  PrimaryDocumentTypeIds,
  OWNER_CONTACT,
  LABEL_RESUME,
} from '../constants';
import documentShape from '../documentShape';
import DocumentLink from './DocumentLink';

import { updateDocument } from '../actions';

const SHARE_IN_SEARCHES_MSG =
  'Allow document to be displayed in the Candidate profile in Searches';
const PRIMARY_RESUME_MSG =
  'Set this resume/ reference as the default in the Candidate profile in Searches';

const PRIMARY_DOCUMENT_TYPES = ['Resume', 'References'];

export const DocumentTableEditRow = ({
  document,
  documentCountForCurrentLabel,
  fetchDocuments,
  isUpdating,
  labelItems,
  onEditComplete,
  shouldShowExpirationDateColumn,
  shouldShowShareInSearchesColumn,
}) => {
  const dispatch = useDispatch();
  const [documentForm, setDocumentForm] = useState(document);

  const updateDocumentForm = documentAttributes => {
    const documentLabel = labelItems.find(
      label => label.id === documentAttributes.document_label_id,
    );
    if (documentLabel) {
      documentAttributes.document_label = {
        id: documentLabel.id,
        name: documentLabel.name,
        expirable: documentLabel.expirable,
      };
    }
    setDocumentForm({ ...documentForm, ...documentAttributes });
  };

  const isOnlyPrimaryDocument =
    PRIMARY_DOCUMENT_TYPES.includes(document.document_label.name) &&
    documentCountForCurrentLabel === 1;

  const handleUpdate = () => {
    dispatch(
      updateDocument({
        document: documentForm,
      }),
    )
      .then(() => fetchDocuments())
      .then(() => {
        onEditComplete();
        if (
          (document.primary ||
            documentForm.document_label.name === LABEL_RESUME) &&
          documentForm.owner_type === OWNER_CONTACT
        ) {
          dispatch(invalidateContact({ id: document.owner_id }));
        }
      });
  };

  // Conditional rendering objects
  const displayPrimary = Object.values(PrimaryDocumentTypeIds).includes(
    documentForm.document_label_id,
  );

  return (
    <>
      <td className='documents--document-table-row'>
        <DocumentLink documentUuid={document.uuid}>
          {document.file_name}
        </DocumentLink>
      </td>
      <td className='documents--document-table-row'>
        <SelectMenu
          onChange={value => updateDocumentForm({ document_label_id: value })}
          value={documentForm.document_label_id}
        >
          {labelItems.map(label => (
            <SelectMenu.Item key={label.id} value={label.id}>
              {label.name}
            </SelectMenu.Item>
          ))}
        </SelectMenu>
      </td>
      {shouldShowShareInSearchesColumn ? (
        <td className='documents--document-table-row'>
          <Tooltip content={SHARE_IN_SEARCHES_MSG} size='medium'>
            <Checkbox
              isChecked={documentForm.share_in_searches}
              isDisabled={displayPrimary && documentForm.primary}
              label='Share In Searches'
              onChange={val => updateDocumentForm({ share_in_searches: val })}
            />
          </Tooltip>
          {displayPrimary && documentForm.share_in_searches && (
            <Tooltip
              content={PRIMARY_RESUME_MSG}
              position='bottom'
              size='medium'
            >
              <Checkbox
                isChecked={documentForm.primary}
                isDisabled={isOnlyPrimaryDocument}
                label='Set As Primary'
                onChange={val => updateDocumentForm({ primary: val })}
              />
            </Tooltip>
          )}
        </td>
      ) : null}
      <td className='documents--document-table-row'>
        <Date date={document.created_at} />
      </td>
      {shouldShowExpirationDateColumn ? (
        <td className='documents--document-table-row'>
          <DateInput
            isDisabled={!documentForm.document_label.expirable}
            onChange={val => updateDocumentForm({ expiration_date: val })}
            value={documentForm.expiration_date}
          />
        </td>
      ) : null}
      <td className='documents--document-table-row documents__documentTableRow--edit'>
        <ButtonPrimary
          isLoading={isUpdating}
          label='Save'
          onClick={handleUpdate}
          size='small'
        />
        <ButtonPrimary
          isOutline={true}
          label='Cancel'
          onClick={onEditComplete}
          size='small'
        />
      </td>
    </>
  );
};

DocumentTableEditRow.propTypes = {
  document: PropTypes.shape(documentShape),
  documentCountForCurrentLabel: PropTypes.number,
  fetchDocuments: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  labelItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onEditComplete: PropTypes.func.isRequired,
  shouldShowExpirationDateColumn: PropTypes.bool.isRequired,
  shouldShowShareInSearchesColumn: PropTypes.bool.isRequired,
};

export default DocumentTableEditRow;

import PropTypes from 'prop-types';
import {
  componentFromProp,
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import mapCompanyIdToCompany from './mapCompanyIdToCompany';

/**
 * Renders the location name for a company.
 */
export default compose(
  setDisplayName('CompanyLocationName'),
  setPropTypes({
    /**
     * The ID of the company to render the location for.
     */
    companyId: PropTypes.number.isRequired,
  }),
  defaultProps({
    component: 'span',
  }),
  mapCompanyIdToCompany,
  mapProps(({ company, component }) => ({
    className: 'CompanyLocationName',
    children: company && company.get('location'),
    component: component,
  })),
)(componentFromProp('component'));

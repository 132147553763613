import React from 'react';
import { compose, setStatic } from 'recompose';
import RadioSelectField from 'modules/forms/components/RadioSelectField';
import { PORTRAIT_LAYOUT, LANDSCAPE_LAYOUT } from './constants';

/**
 * A field for selecting the layout of the report
 * ("Portrait" vs "Landscape") for the search summary report.
 */

const SearchSummaryReportLayoutField = ({ ...props }) => {
  const options = [
    {
      label: 'Portrait',
      value: PORTRAIT_LAYOUT,
    },
    {
      label: 'Landscape',
      value: LANDSCAPE_LAYOUT,
    },
  ];

  return (
    <div className='SearchSummaryReportLayoutField'>
      <h3>Layout</h3>
      <RadioSelectField {...props} options={options} />
    </div>
  );
};

SearchSummaryReportLayoutField.createFieldState = (
  name = 'layout',
  value = PORTRAIT_LAYOUT,
  ...rest
) => RadioSelectField.createFieldState(name, value, ...rest);

export default compose(
  setStatic(
    'createFieldState',
    SearchSummaryReportLayoutField.createFieldState,
  ),
)(SearchSummaryReportLayoutField);

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import AboveBoardIcon from 'components/contact/social/AboveBoardIcon';

const ContactSocialForm = ({ contactDetails }) => {
  const hasAboveBoardFeature = useFeatureCheck('feature.aboveboard');

  return (
    <Card className='u-marginTop-32 u-border-n u-padding-n' isCentered={false}>
      <h2 className='u-marginTop-n'>Social</h2>
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.linkedinUrl}
        inputWidth='full'
        label='LinkedIn Profile'
        name='linkedin_url'
        prepend={<i className='fa fa-linkedin' />}
        size='small'
      />
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.githubUrl}
        inputWidth='full'
        label='Github Profile URL'
        name='github_url'
        prepend={<i className='fa fa-github' />}
        size='small'
      />
      {hasAboveBoardFeature ? (
        <Form.TextInput
          className='u-marginBottom-8'
          initialValue={contactDetails?.aboveboardUrl}
          inputWidth='full'
          label='AboveBoard URL'
          name='aboveboard_url'
          prepend={<AboveBoardIcon backgroundColor='#f6f8fc' color='black' />}
          size='small'
        />
      ) : null}
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.otherUrl}
        inputWidth='full'
        label='Other Link/URL'
        name='other_url'
        prepend={<i className='fa fa-link' />}
        size='small'
      />
    </Card>
  );
};

ContactSocialForm.propTypes = {
  contactDetails: PropTypes.shape({
    aboveboardUrl: PropTypes.string,
    githubUrl: PropTypes.string,
    linkedinUrl: PropTypes.string,
    otherUrl: PropTypes.string,
    twitterHandle: PropTypes.string,
  }),
};

export default ContactSocialForm;

import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import EntityProperty from 'modules/entities/components/EntityProperty';
import companyInvestmentStageSchema from 'modules/tenant/schemas/companyInvestmentStages';
import mapInvestmentIdToProperty from './mapInvestmentIdToProperty';

/**
 * Renders the stage name for the round of an investment.
 */
export default compose(
  setDisplayName('InvestmentRoundStageName'),
  setPropTypes({
    investmentId: PropTypes.number.isRequired,
  }),

  // Get the round as `companyStageId` from the investmentId
  mapInvestmentIdToProperty('round', 'companyStageId'),

  // In some cases there is no companyStageId (not sure _why_, though), so don't render anything
  branch(({ companyStageId }) => !companyStageId, renderNothing),

  // Only pass down the companyStageId to CompanyInvestmentStageName
  mapProps(({ companyStageId }) => ({
    companyStageId: companyStageId,
    entityType: companyInvestmentStageSchema.key,
    entityId: companyStageId,
    propertyName: 'name',
  })),
)(EntityProperty);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import classnames from 'classnames';

import getUserPermission from 'modules/auth/selectors/getUserPermission';
import hasFeature from 'modules/auth/selectors/hasFeature';
import mapCandidacyIdToCandidacy from 'modules/candidacies/components/mapCandidacyIdToCandidacy';
import mapCandidacyIdToContactId from 'modules/candidacies/components/mapCandidacyIdToContactId';
import CandidacyCommentsPanel from 'modules/candidacy-comments/components/CandidacyCommentsPanel';
import EmptyState from 'modules/core/componentsLegacy/EmptyState';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import InterviewsPanel from 'modules/interviews/components/InterviewsPanel';
import IntroductionsPanel from 'modules/introductions/components/IntroductionsPanel';
import CandidacyNotesPanel from 'modules/notes/components/CandidacyNotesPanel';
import CandidacyOutreachEmailPanel from 'modules/outreaches/components/CandidacyOutreachEmailPanel';
import CandidacyOutreachesPanel from 'modules/outreaches/components/CandidacyOutreachesPanel';
import ReferencesPanel from 'modules/references/components/ReferencesPanel';
import { TYPE_JOB_SEARCH, TYPE_PIPELINE } from 'modules/searches/constants';
import mapSearchIdToSearchType from 'modules/searches/components/mapSearchIdToSearchType';
import SearchSidebarLink from 'modules/searches/components/SearchSidebarLink';
import CandidacyAssessmentsPanel from 'modules/candidacy-assessments/CandidacyAssessmentsPanel';
import isClientUser from 'modules/auth/selectors/isClientUser';
import isClientNoCompUser from 'modules/auth/selectors/isClientNoCompUser';
import isHiringManagerUser from 'modules/auth/selectors/isHiringManagerUser';
import ReferencesPanelV2 from 'modules/references/components/ReferencesPanelV2';
import fetchContactIfNeeded from 'modules/contacts/components/fetchContactIfNeeded';

import CandidacyAssessmentsPanelLegacy from './CandidacyAssessmentsPanelLegacy';
import CandidacyListItemLink from './CandidacyListItemLink';
import CandidacySearchSidebarActivity from './CandidacySearchSidebarActivity';
import CandidacySearchSidebarEvents from './CandidacySearchSidebarEvents';
import CandidacySearchSidebarHeader from './CandidacySearchSidebarHeader';
import CandidacySearchSidebarOverview from './CandidacySearchSidebarOverview';

/**
 * All possible tabs that can be rendered, and the order they should be
 * rendered in. The `visibleTabs` prop will eventually dictate which of these
 * tabs are actually shown in the component.
 */
const TABS = [
  {
    id: 'overview',
    icon: 'icon-nav-overview',
    title: 'Overview',
    Component: CandidacySearchSidebarOverview,
  },
  {
    id: 'comments',
    icon: 'icon-nav-comments',
    title: 'Comments',
    Component: CandidacyCommentsPanel,
  },
  {
    id: 'interviews',
    icon: 'icon-nav-interviews',
    title: 'Interviews',
    Component: InterviewsPanel,
  },
  {
    id: 'candidacy_assessments',
    icon: 'icon-nav-assessments',
    title: 'Assessments',
    Component: CandidacyAssessmentsPanel,
  },
  {
    id: 'assessments',
    icon: 'icon-nav-assessments',
    title: 'Assessments',
    Component: CandidacyAssessmentsPanelLegacy,
  },
  {
    id: 'references',
    icon: 'fa fa-file-text-o',
    title: 'References',
    Component: ReferencesPanel,
  },
  {
    id: 'referencesV2',
    icon: 'fa fa-file-text-o',
    title: 'References',
    Component: ReferencesPanelV2,
  },
  {
    id: 'outreach',
    icon: 'icon-nav-outreach',
    title: 'Outreach',
    Component: CandidacyOutreachesPanel,
  },
  {
    id: 'email',
    icon: 'fa fa-send',
    title: 'Email',
    Component: CandidacyOutreachEmailPanel,
  },
  {
    id: 'notes',
    icon: 'fa fa-edit',
    title: 'Notes',
    Component: CandidacyNotesPanel,
  },
  {
    id: 'introductions',
    icon: 'fa fa-handshake-o',
    title: 'Introductions',
    Component: IntroductionsPanel,
  },
  {
    id: 'activities',
    icon: 'icon-nav-activities',
    title: 'Activity',
    Component: CandidacySearchSidebarActivity,
  },
  {
    id: 'events',
    icon: 'fa fa-calendar',
    title: 'Events',
    Component: CandidacySearchSidebarEvents,
  },
];

/**
 * Renders the candidacy sidebar for the search page.
 */
const CandidacySearchSidebar = ({
  candidacyId,
  contactId,
  isCandidacyNotFound,
  searchId,
  searchPage,
  selectedTab,
  visibleTabs,
}) => {
  const tabs = TABS.filter(tab => visibleTabs[tab.id] === true);
  const activeTab = tabs.find(tab => tab.id === selectedTab);
  const { Component } = activeTab || tabs[0] || {};

  if (isCandidacyNotFound) {
    return (
      <div className='CandidacySearchSidebar'>
        <div className='CandidacySearchSidebar__ReturnToSummary'>
          <SearchSidebarLink searchId={searchId} searchPage={searchPage}>
            <i className='fa fa-chevron-left' /> Summary
          </SearchSidebarLink>
        </div>
        <EmptyState
          icon={<i className='fa fa-exclamation-circle fa-3x' />}
          message='Candidate not available'
        />
      </div>
    );
  }

  return (
    <div className='CandidacySearchSidebar'>
      <ul className='CandidacySearchSidebar__Tabs'>
        <li
          className='CandidacySearchSidebar__ReturnToSearch'
          key='return'
          role='presentation'
        >
          <SearchSidebarLink searchId={searchId} searchPage={searchPage}>
            <i className='fa fa-chevron-left' /> Summary
          </SearchSidebarLink>
        </li>
        {tabs.map(tab => (
          <CandidacyListItemLink
            candidacyId={candidacyId}
            key={tab.id}
            tab={tab.id}
          >
            <i className={classnames('icon', tab.icon)} />
            {tab.title}
          </CandidacyListItemLink>
        ))}
      </ul>
      <div className='CandidacySearchSidebar__Content'>
        <CandidacySearchSidebarHeader candidacyId={candidacyId} />
        <div className='CandidacySearchSidebar__TabContent'>
          {Component &&
            // Show a loading indicator if we don't have a contactId or searchId yet (which
            // should really only be the case when the candidacy record hasn't been loaded yet,
            // since that is how we are mapping them in!)
            (contactId && searchId ? (
              <Component
                candidacyId={candidacyId}
                contactId={contactId}
                searchId={searchId}
              />
            ) : (
              <LoadingIndicator />
            ))}
        </div>
      </div>
    </div>
  );
};

CandidacySearchSidebar.propTypes = {
  /**
   * The ID of the candidacy shown in the sidebar.
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * The candidacy's contact ID
   */
  contactId: PropTypes.number,

  /**
   * True if a matching candidacy record was not found for the candidacyId.
   */
  isCandidacyNotFound: PropTypes.bool,

  /**
   * The search ID the candidacy belongs to.
   */
  searchId: PropTypes.number.isRequired,

  /**
   * The current search page selected (the "content" area being shown for the search page --
   * usually "candidates", "applicants", or "target_companies")
   */
  searchPage: PropTypes.string.isRequired,

  /**
   * The currently selected sidebar tab.
   */
  selectedTab: PropTypes.string,

  /**
   * The list of tabs that should be visible. This is a map of tab names
   * mapped to a boolean value: true to indicate the tab should be visible,
   * false to indicate it should not be visible.
   */
  visibleTabs: PropTypes.objectOf(PropTypes.bool.isRequired),
};

CandidacySearchSidebar.defaultProps = {
  selectedTab: 'overview',
  visibleTabs: {
    overview: true,
    activities: true,
  },
};

export default compose(
  setDisplayName('CandidacySearchSidebar(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
    searchId: PropTypes.number.isRequired,
  }),
  mapCandidacyIdToContactId,
  mapCandidacyIdToCandidacy,
  mapSearchIdToSearchType,
  fetchContactIfNeeded({ requireFull: true }),
  // Determine which tabs should be displayed
  // (talent pools, for example, show only a limited subset of tabs)
  connect((state, { candidacy, searchType }) => {
    const isJobSearch = searchType === TYPE_JOB_SEARCH;
    const isPipeline = searchType === TYPE_PIPELINE;
    const candidacyError = candidacy && candidacy.getIn(['_meta', 'error']);
    const isCandidacyDeleted = Boolean(
      candidacy && candidacy.getIn(['_meta', 'isDeleted']),
    );

    return {
      isCandidacyNotFound:
        (candidacyError && candidacyError.status === 404) || isCandidacyDeleted,
      visibleTabs: {
        overview: true,
        comments: true,
        interviews: isJobSearch,
        assessments:
          !hasFeature(state, 'feature.assessment_templates') && !isPipeline,
        candidacy_assessments:
          hasFeature(state, 'feature.assessment_templates') && !isPipeline,
        references:
          (isJobSearch || isPipeline) &&
          !hasFeature(state, 'development.references_connection_v2'),
        referencesV2:
          (isJobSearch || isPipeline) &&
          hasFeature(state, 'development.references_connection_v2'),
        outreach:
          (isJobSearch || isPipeline) &&
          Boolean(getUserPermission(state, 'can_view_outreach')),
        email:
          (isJobSearch || isPipeline) &&
          (hasFeature(state, 'integration.email.gmail') ||
            hasFeature(state, 'integration.email.outlook.365')) &&
          getUserPermission(state, 'can_view_outreach'),
        notes:
          (isJobSearch || isPipeline) &&
          Boolean(getUserPermission(state, 'can_view_note')),
        introductions:
          (isJobSearch || isPipeline) &&
          Boolean(getUserPermission(state, 'can_view_introductions')),
        activities: true,
        events:
          !isPipeline &&
          !isClientUser(state) &&
          !isClientNoCompUser(state) &&
          !isHiringManagerUser(state) &&
          hasFeature(state, 'feature.events'),
      },
    };
  }, {}),
)(CandidacySearchSidebar);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { singular } from 'pluralize';
import getCurrentUserEmail from 'modules/auth/selectors/getCurrentUserEmail';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { useApiPost, useApiGet } from 'modules/core/hooks/useApi';
import Modal from '@thrivetrm/ui/components/Modal';
import Form from '@thrivetrm/ui/components/Form';
import Card from '@thrivetrm/ui/components/Card';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Integer from '@thrivetrm/ui/components/Integer';
import PluralText from '@thrivetrm/ui/components/PluralText';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import classnames from 'classnames';
import { convertToSnakeCase } from 'modules/core/jsonUtils';
import { isValidEmail } from 'modules/core/validators';

// The ExportModal.spec.js tests fail when the provided `id` is a string.
const CSV_REPORT_TEMPLATE_ID = 0;

const CSV_REPORT_TEMPLATE = {
  id: CSV_REPORT_TEMPLATE_ID,
  name: 'CSV',
};

const ExportModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const {
    availableFilterInputs,
    candidateExportFields,
    csvExportRowLimit,
    endpoints,
    recordLabelPlural,
    recordType,
    savedView,
    totalResults,
  } = useSelector(state => state.recordIndex);
  const currentUserEmail = useSelector(getCurrentUserEmail);
  const [exportCsvReport, isExportingCsvReport, csvExportError] = useApiPost(
    '/api/spreadsheet_reports',
  );

  const hasCustomTemplates =
    endpoints.customReport && endpoints.customReportTemplates;

  const [templateOptions, setTemplateOptions] = useState([CSV_REPORT_TEMPLATE]);

  const [loadCustomTemplates] = useApiGet(endpoints.customReportTemplates);

  useEffect(() => {
    if (hasCustomTemplates) {
      loadCustomTemplates(null, {
        onSuccess: customReportTemplates =>
          setTemplateOptions([CSV_REPORT_TEMPLATE, ...customReportTemplates]),
      });
    }
  }, [hasCustomTemplates, loadCustomTemplates]);

  const [
    exportCustomReport,
    isExportingCustomReport,
    customExportError,
  ] = useApiPost(endpoints.customReport);

  const handleExportSuccess = () => {
    dispatch(toastSuccess('Generating report'));
    onClose();
  };

  const isUsingV5UI = Object.keys(availableFilterInputs || {}).length > 0;

  const handleDefaultExport = data => {
    const { recipients, ...candidateFields } = data;
    const selectedCandidateFields = Object.keys(candidateFields).filter(
      value => candidateFields[value],
    );

    exportCsvReport(
      convertToSnakeCase({
        emails: recipients,
        fields: [...savedView.columns, ...selectedCandidateFields],
        filters: savedView.filters,
        networkId: savedView.networkId,
        resource: singular(recordType),
        sortDirection: savedView.sortDirection,
        sortField: savedView.sortField,
        version: isUsingV5UI ? 'v5' : 'v4',
      }),
      {
        onSuccess: handleExportSuccess,
      },
    );
  };

  const handleCsvExport = data =>
    exportCustomReport(
      convertToSnakeCase({
        id: savedView.id,
        emails: data.recipients,
        filters: savedView.filters,
        networkId: savedView.networkId,
        reportTemplateId: data.templateId,
        sortDirection: savedView.sortDirection,
        sortField: savedView.sortField,
        view: 'table_view',
        version: isUsingV5UI ? 'v5' : 'v4',
        reportExport: true,
      }),
      { onSuccess: handleExportSuccess },
    );

  const handleSubmit = formData =>
    formData.templateId === CSV_REPORT_TEMPLATE.id
      ? handleDefaultExport(formData)
      : handleCsvExport(formData);

  const numberRecordsToExport =
    totalResults >= csvExportRowLimit ? csvExportRowLimit : totalResults;

  const showCandidateDetailsOptions =
    candidateExportFields && savedView.filters?.searchIds?.length === 1;

  // The Form.SelectMenu is conditionally hidden w/ css to allow the info text to be
  // driven by a form value.  When a user has the custom report export feature, the menu renders
  const exportReportTypeClass = classnames('u-marginBottom-32', {
    'u-hidden': !hasCustomTemplates,
  });

  return (
    <Modal
      className='ExportModal'
      isOpen={true}
      onClose={onClose}
      size='small'
      title={`Export ${recordLabelPlural}`}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Body className='ExportModal__body'>
          {totalResults > csvExportRowLimit ? (
            <Card
              className='u-marginBottom-32'
              isCentered={false}
              type='warning'
            >
              Sorry, that&apos;s too many rows. Only the first{' '}
              <Integer value={csvExportRowLimit} /> out of{' '}
              <Integer value={totalResults} /> rows will be exported.
            </Card>
          ) : null}
          <Form.SelectMenu
            className={exportReportTypeClass}
            initialValue={templateOptions[0].id}
            inputWidth='full'
            isDisabled={templateOptions.length === 1}
            label='Report Type'
            name='templateId'
          >
            {templateOptions.map(template => (
              <SelectMenu.Item key={template.id} value={template.id}>
                {template.name}
              </SelectMenu.Item>
            ))}
          </Form.SelectMenu>
          <div className='u-marginBottom-32'>
            <Form.FieldDependentContent
              shouldRender={fields =>
                fields.templateId === CSV_REPORT_TEMPLATE.id
              }
            >
              You’re about to export a CSV of{' '}
              <PluralText
                className='u-fontWeight-bold'
                quantity={numberRecordsToExport}
                shouldIncludeQuantity={true}
                text={recordType}
              />
              . The data will be based on your current filters, network, and
              table columns.
            </Form.FieldDependentContent>
            <Form.FieldDependentContent
              shouldRender={fields =>
                fields.templateId !== CSV_REPORT_TEMPLATE.id
              }
            >
              You’re about to export a Custom Report of{' '}
              <PluralText
                className='u-fontWeight-bold'
                quantity={numberRecordsToExport}
                shouldIncludeQuantity={true}
                text={recordType}
              />
              . The data exported will align with what’s required by the report.
            </Form.FieldDependentContent>
          </div>
          {showCandidateDetailsOptions ? (
            <div className='u-marginBottom-32'>
              <h3>Additional Candidate Details</h3>
              <div>
                {candidateExportFields.map(option => (
                  <div key={option.value} style={{ width: '200px' }}>
                    <Form.Checkbox
                      className='u-inlineBlock'
                      label={option.label}
                      name={option.value}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <Form.EmailRecipientsInput
            autoComplete='email'
            data-testid='export recipients'
            initialValue={[currentUserEmail]}
            label='Send To (Email Addresses)'
            name='recipients'
            rules={{
              required: {
                value: true,
                message: 'An email address is required',
              },
              validate: values => {
                const invalidEmails = values.filter(
                  value => !isValidEmail(value),
                );
                return invalidEmails.length
                  ? `Invalid emails: ${invalidEmails.join(', ')}`
                  : null;
              },
            }}
          />
          {csvExportError || customExportError ? (
            <Card className='u-marginTop-32' type='error'>
              {csvExportError || customExportError}
            </Card>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
          <Form.SubmitButton
            icon='mail'
            isLoading={isExportingCsvReport || isExportingCustomReport}
            label='Send'
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ExportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ExportModal;

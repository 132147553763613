/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import classnames from 'classnames';

import identity from '@thrivetrm/ui/utilities/identity';
import RowSelectCheckbox from './RowSelectCheckbox';
import {
  Alignments,
  columns as columnsPropType,
  data as dataPropType,
  selection as selectionPropType,
} from './propTypes';

/**
 * The <tbody /> element of a <Table /> component.
 */
export const TableBody = ({
  columns,
  data,
  getDatumKey,
  getRowClassNames,
  onRowSelectCheckboxChange,
  selectable,
  selection,

  // Additional props get passed into the Cell
  ...props
}) => (
  <tbody>
    {data &&
      data.map(datum => (
        <tr
          className={
            getRowClassNames ? classnames(getRowClassNames(datum)) : ''
          }
          data-datum={datum}
          key={getDatumKey(datum)}
        >
          {selectable && (
            <td className='table__selection-cell'>
              <RowSelectCheckbox
                checked={Boolean(selection && selection.includes(datum))}
                datum={datum}
                onChange={onRowSelectCheckboxChange}
              />
            </td>
          )}

          {columns.map((column, index) => (
            <td
              className={classnames(column.className, {
                'table__cell--isFixed': index === 0,
                // left alignment is the default, so no need to override
                'u-textAlign-c': column.alignment === Alignments.CENTER,
                'u-textAlign-r': column.alignment === Alignments.RIGHT,
                'u-noWrap': column.shouldWrapText === false,
                'u-truncate': column.shouldTruncateText === true,
              })}
              key={column.key}
            >
              <column.Cell {...props} datum={datum} />
            </td>
          ))}
        </tr>
      ))}
  </tbody>
);

TableBody.defaultProps = {
  getRowClassNames: null,
};

TableBody.propTypes = {
  /**
   * The table columns to render.
   */
  columns: columnsPropType.isRequired,

  /**
   * The dataset to render
   */
  data: dataPropType.isRequired,

  /**
   * A function that will be called for each row and should return a unique key that can be
   * used to identify the row (used as the react `key`). By default it simply uses the datum
   * value as the key, which is sufficient in most cases.
   */
  getDatumKey: PropTypes.func,

  /**
   * An optional function that will be called for each row (with the row datum as a parameter) and
   * should return class names (which will be passed directly to `classnames()`, so may be a string,
   * object, array, etc) which will be applied to each row.
   */
  getRowClassNames: PropTypes.func,

  /**
   * Called when a row's selection checkbox checked state is changed.
   */
  onRowSelectCheckboxChange: PropTypes.func.isRequired,

  /**
   * True to show checkboxes in the first column allowing rows to be selected.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  selectable: PropTypes.bool,

  /**
   * The list of items in the dataset that are in a "selected" state
   * (this should be a subset of the `data` prop)
   */
  selection: selectionPropType.isRequired,
};

TableBody.defaultProps = {
  getDatumKey: identity,
  selectable: false,
};

export default compose(
  setDisplayName('TableBody(enhanced)'),
  setPropTypes({
    ...TableBody.propTypes,

    // Provided by this HOC so isn't needed.
    onRowSelectCheckboxChange: PropTypes.any,

    /**
     * Called when the `selection` prop should be updated.
     */
    onSelectionChange: PropTypes.func.isRequired,
  }),
  withHandlers({
    /**
     * Handles the toggle of a particular row's checkbox/selection state.
     */
    onRowSelectCheckboxChange: ({ onSelectionChange, selection }) => (
      checked,
      datum,
    ) => {
      // When checked, add the datum to the current selection, when unchecked,
      // remove it.
      const newSelection = checked
        ? selection.concat(datum)
        : selection.filter(selectedDatum => selectedDatum !== datum);

      onSelectionChange(newSelection);
    },
  }),
)(TableBody);

import { PUT } from 'modules/api/methods';
import {
  CANDIDACY_TAG_ADD_START,
  CANDIDACY_TAG_ADD_SUCCESS,
  CANDIDACY_TAG_ADD_FAILURE,
} from './actionTypes';
import createCandidacyTagAction from './createCandidacyTagAction';

/**
 * An action creator for adding a tag to a candidacy record.
 * @param {Object} options
 * @param {String} options.candidacyId The ID of the candidacy to add the tag to
 * @param {String} options.tagId The ID of the tag to add to the candidacy
 */
export default createCandidacyTagAction({
  startActionType: CANDIDACY_TAG_ADD_START,
  successActionType: CANDIDACY_TAG_ADD_SUCCESS,
  failureActionType: CANDIDACY_TAG_ADD_FAILURE,
  method: PUT,
});

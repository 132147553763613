import { connect } from 'react-redux';
import getCandidacyProperty from '../selectors/getCandidacyProperty';

/**
 * A Higher order component that filters a list of candidacyIds to only include candidacies
 * for the specified search type (TYPE_TALENT_POOL or TYPE_JOB_SEARCH)
 */
export default connect(
  (state, { candidacyIds, isFiltered, searchType }) => ({
    isFiltered: isFiltered || Boolean(searchType),
    candidacyIds:
      candidacyIds && searchType
        ? candidacyIds.filter(
            id => getCandidacyProperty(state, id, 'search_type') === searchType,
          )
        : candidacyIds,
  }),
  {},
);

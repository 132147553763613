import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import getTransaction from '../selectors/getTransaction';
import clearTransactionActionCreator from '../actions/clearTransaction';

/**
 * A higher order componenet function that tracks the state of a collection of transaction.
 *
 * Expected incoming props:
 *   * `onTransactionFinished` (customizable via the `onTransactionFinishedPropName` option) - a
 *     callback that will be called whenever a transaction created with `trackTransaction` (see
 *     below) has been finished. Will be called with the transaction ID.
 *
 * Provided outgoing props:
 *   * `trackTransaction` (customizable via the `trackTransactionPropName` option) - Call with a
 *     transaction ID to begin tracking a specific transaction.
 *   * `transactionIds` An array of transaction IDs that are currently being tracked.
 *
 * @example
 * ```jsx
 * // This example tracks a single transaction at a time, but `onTransactionFinished` is called
 * // with the transactionId, so that could be used to track multiple transactions that could
 * // be in-flight simultaneously.
 * const MyEnhancedComponent = compose(
 *   // `isUpdating` could be used for show a loader, disable a button, etc...
 *   withStateHandlers({ isUpdating: false }, {
 *     setIsUpdating: () => ({ isUpdating: true }),
 *
 *     // `withTransactionTracking` will call this method when any transaction that was
 *     // provided to `trackTransaction` is finished.
 *     // In this case we reset `isUpdating`, which possibly hide a loader, re-enables a button,
 *     // closes a modal, etc.
 *     // We could use `transactionId` here if we have multiple transactions being tracked.
 *     onTransactionFinished: () => (transactionId) => ({
 *       isUpdating: false,
 *     }),
 *   })
 *
 *   // `onTransactionFinished` prop must be available...
 *   withTransactionTracking(),
 *   // ...will provide `trackTransaction` as a prop.
 *
 *   // some sort of action that uses a transaction
 *   connect({}, { someAction: someActionCreator }),
 *
 *   withHandlers({
 *     onToggleClicked: ({ someAction, trackTransaction }) => (e) => {
 *       const transactionId = uniqueId();
 *
 *       // Trigger the action. Doesn't matter what it does as long as it uses transactions.
 *       someAction({ id: e.target.value, transactionId });
 *
 *       // update our state (maybe this shows a loader while we're updating a record)
 *       setIsUpdating(true);
 *
 *       // By using `withTransactionTracking`, `onTransactionFinished` will be automatically
 *       // called when this transaction is finished.
 *       trackTransaction(transactionId);
 *     }
 *   }),
 * )(MyNotEnhancedComponent);
 *
 * ```
 */
export default ({
  /**
   * The name of the callback prop that will be called when any transaction that is being tracked
   * has completed.
   */
  onTransactionFinishedPropName = 'onTransactionFinished',

  /**
   * The name of the prop that will be provided that can be used to beging tracking a transaction.
   */
  trackTransactionPropName = 'trackTransaction',

  /**
   * The name of the prop that will be provided that will contain the list of transaction IDs
   * that are currently being tracked.
   */
  transactionIdsPropName = 'transactionIds',
} = {}) =>
  compose(
    setDisplayName('withTransactionTracking'),
    setPropTypes({
      /**
       * Called when a transaction is finished.
       */
      [onTransactionFinishedPropName]: PropTypes.func.isRequired,
    }),

    // Store incoming props so they can be restored.
    mapProps(withTransactionsIncomingProps => ({
      onTransactionFinished:
        withTransactionsIncomingProps[onTransactionFinishedPropName],
      withTransactionsIncomingProps: withTransactionsIncomingProps,
    })),

    withStateHandlers(
      { transactionIds: [] },
      {
        trackTransaction: ({ transactionIds }) => transactionId => ({
          transactionIds: transactionIds.concat(transactionId),
        }),
        removeTransaction: ({ transactionIds }) => transactionId => ({
          transactionIds: transactionIds.filter(id => id !== transactionId),
        }),
      },
    ),

    connect(
      (state, { transactionIds }) => ({
        transactions: transactionIds.map(id => getTransaction(state, id)),

        // Find the next transaction in our list that's finished (if any)
        // Only handle one finished transaction at a time - the componentDidUpdate lifecycle
        // method below will remove the transaction and cause another state update, which
        // will cause additional finished transactions to get handled here as well until there
        // are no more.
        finishedTransaction: transactionIds
          .map(id => getTransaction(state, id))
          .find(transaction => transaction && transaction.isFinished),
      }),
      {
        clearTransaction: clearTransactionActionCreator,
      },
    ),

    lifecycle({
      componentDidUpdate: function (prevProps) {
        const {
          clearTransaction,
          finishedTransaction,
          onTransactionFinished,
          removeTransaction,
        } = this.props;

        // If we have a finished transaction, call `onTransactionFinished`, then clear it from
        // our store and remove it from state.
        if (
          finishedTransaction &&
          finishedTransaction !== prevProps.finishedTransaction
        ) {
          const { id } = finishedTransaction;
          onTransactionFinished(id, finishedTransaction);
          clearTransaction(id);
          removeTransaction(id);
        }
      },
    }),

    // Restore incoming props and add the `trackTransaction` and `transactionIdsPropName` props.
    mapProps(
      ({
        trackTransaction,
        transactionIds,
        withTransactionsIncomingProps,
      }) => ({
        ...withTransactionsIncomingProps,
        [trackTransactionPropName]: trackTransaction,
        [transactionIdsPropName]: transactionIds,
      }),
    ),
  );

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Renders some content below a label.
 * By default the label is displayed above the content, but by setting inline=true, the label
 * will be rendered to the left of the content.
 */
const LabeledContent = ({
  children,
  className,
  inline,
  label,
  shouldIndentOnWrap,
}) => (
  <div
    className={classnames('LabeledContent', className, {
      'LabeledContent--inline': inline,
      'LabeledContent--indent': shouldIndentOnWrap,
    })}
  >
    {label && <div className='LabeledContent__label'>{label}</div>}
    <div className='LabeledContent__value'>{children}</div>
  </div>
);

LabeledContent.defaultProps = {
  children: null,
  className: null,
  inline: false,
  label: null,
  shouldIndentOnWrap: false,
};

LabeledContent.propTypes = {
  /**
   * The content to render
   */
  children: PropTypes.node,

  /**
   * An optional class name to add to the wrapper.
   */
  className: PropTypes.string,

  /**
   * True to render the label inline (to the left of the content), otherwise the label
   * will be rendered above the content.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  inline: PropTypes.bool,

  /**
   * The label/title of the field.
   */
  label: PropTypes.string,

  /**
   * true if we want the text to indent when it wraps
   */
  shouldIndentOnWrap: PropTypes.bool,
};

export default LabeledContent;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AdvancedSelect from '@thrivetrm/ui/components/AdvancedSelect';
import TextArea from '@thrivetrm/ui/components/TextArea';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Modal from '@thrivetrm/ui/components/Modal';
import PluralText from '@thrivetrm/ui/components/PluralText';
import BulkUpdateCandidacySummary from './BulkUpdateCandidacyStageSummary';
import CandidacyStageTag from './CandidacyStageTag';
import {
  processNextSelectedCandidacy,
  reset,
  selectHasStartedBulkUpdate,
  selectIsBulkUpdateComplete,
  selectTargetStage,
} from './candidacyBulkActionsSlice';
import { selectRejectionReasons } from '../legacySearchDataSelectors';

const BulkUpdateCandidacyStageModal = () => {
  const dispatch = useDispatch();

  const rejectionReasons = useSelector(selectRejectionReasons);
  const defaultRejectionReason = useSelector(
    state => selectRejectionReasons(state)?.[0],
  );

  const [rejectedReason, setRejectionReason] = useState(defaultRejectionReason);
  const [rejectionComment, setRejectionComment] = useState(null);

  const {
    isUpdateInProgress,
    selectedCandidacyIds,
    updatedCandidacyIds,
  } = useSelector(state => state.candidacyBulkActions);

  const handleClose = () => {
    dispatch(reset());
  };

  const targetStage = useSelector(selectTargetStage);

  const handleSubmit = () => {
    const payload = {};
    if (targetStage.rejected) {
      payload.rejection_reason = rejectedReason || defaultRejectionReason;
      payload.rejection_comment = rejectionComment;
    }
    dispatch(processNextSelectedCandidacy(payload));
  };

  const hasCompletedBulkUpdate = useSelector(selectIsBulkUpdateComplete);
  const hasStartedBulkUpdate = useSelector(selectHasStartedBulkUpdate);

  const getSubTitle = () =>
    hasStartedBulkUpdate ? (
      <p className='u-textColor-gray60 u-marginTop-16'>
        Moving Candidate <strong>{updatedCandidacyIds.length + 1}</strong> of{' '}
        <strong>{selectedCandidacyIds.length}</strong> to{' '}
        <CandidacyStageTag
          className='u-padding-4'
          color={targetStage?.color}
          name={targetStage?.name}
        />{' '}
        stage.
      </p>
    ) : (
      <p className='u-textColor-gray60 u-marginTop-16'>
        Move{' '}
        <strong>
          <PluralText
            quantity={selectedCandidacyIds?.length}
            shouldIncludeQuantity={true}
            text='candidates'
          />
        </strong>{' '}
        to the{' '}
        <CandidacyStageTag
          className='u-padding-4'
          color={targetStage?.color}
          name={targetStage?.name}
        />{' '}
        stage?
      </p>
    );

  return (
    <Modal
      isOpen={Boolean(targetStage)}
      onClose={handleClose}
      subTitle={hasCompletedBulkUpdate ? <h3>All Done!</h3> : getSubTitle()}
      title='Bulk Move Candidate Stage'
    >
      <Modal.Body>
        {targetStage?.rejected ? (
          <div>
            <AdvancedSelect
              className='u-marginBottom-16'
              defaultValue={defaultRejectionReason}
              label='Rejection Reason'
              onChange={setRejectionReason}
              options={rejectionReasons?.map(reason => ({
                label: reason,
                value: reason,
              }))}
              value={rejectedReason}
            />
            <TextArea
              label='Comments'
              onChange={setRejectionComment}
              value={rejectionComment}
              width='full'
            />
          </div>
        ) : null}
        <BulkUpdateCandidacySummary />
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          isDisabled={hasStartedBulkUpdate}
          isOutline={true}
          label='Cancel'
          onClick={handleClose}
        />
        <ButtonPrimary
          isDisabled={isUpdateInProgress}
          isLoading={isUpdateInProgress}
          label={hasStartedBulkUpdate ? 'Close' : 'Confirm'}
          onClick={hasCompletedBulkUpdate ? handleClose : handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default BulkUpdateCandidacyStageModal;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import Modal from '@thrivetrm/ui/components/Modal';
import { useDispatch } from 'react-redux';
import { useApiPost } from '@thrivetrm/ui/hooks/useApi';
import routes from 'modules/routing/routes';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import Card from '@thrivetrm/ui/components/Card';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import TextInput from '@thrivetrm/ui/components/TextInput';
import CopyButton from '@thrivetrm/ui/components/CopyButton';

const EmailIntegrationCopyBCCEmail = ({ outreachEmailAddress }) => {
  const dispatch = useDispatch();
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const [outreachThriveEmail, setOutreachThriveEmail] = useState(
    outreachEmailAddress,
  );

  const dispatchSuccess = () => {
    dispatch(toastSuccess('Successfully generated Outreach Email Address.'));
  };

  const onTokenChange = response => {
    const customEvent = document.createEvent('CustomEvent');
    customEvent.initCustomEvent('thrive:app', true, true, response);
    document.dispatchEvent(customEvent);
  };

  const [
    generateThriveEmail,
    isGeneratingThriveEmail,
    generateThriveEmailError,
  ] = useApiPost(routes.api_integrations_email_residencies());

  const handleGenerateEmail = () => {
    generateThriveEmail(
      {},
      {
        onSuccess: response => {
          onTokenChange(response);
          openModal();
          setOutreachThriveEmail(response.bcc);
        },
      },
    );
  };

  useEffect(() => {
    document.addEventListener('thrive:chrome:received', dispatchSuccess);
    return () => {
      document.removeEventListener('thrive:chrome:received', dispatchSuccess);
    };
  });

  return (
    <>
      <Card className='u-backgroundColor-gray5' isCentered={false}>
        <div className='u-flex u-flexAlign-b'>
          <TextInput
            className='u-flexItem-grow'
            data-testid='thrive-trm-bcc-email'
            inputWidth='full'
            label='Thrive TRM BCC Email'
            onChange={() => {}}
            placeholder={outreachThriveEmail}
            value={outreachThriveEmail}
          />
          <CopyButton
            className='u-marginLeft-16'
            textToCopy={outreachThriveEmail}
          />
          <ButtonSecondary
            data-testid='regenerate-thrive-email-button'
            icon='refresh'
            isLoading={isGeneratingThriveEmail}
            label='Regenerate Thrive TRM Email'
            onClick={handleGenerateEmail}
          />
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          size='small'
          title='New Thrive TRM BCC Email'
        >
          <Modal.Body>
            <h3 className='text-center u-marginTop-n'>
              <StatusIndicator className='u-marginRight-8' status='success' />
              Success!
            </h3>
            <p>Below is your newly generated BCC email address:</p>
            <Card className='u-backgroundColor-gray5' isCentered={false}>
              <div className='u-flex u-flexAlign-b'>
                <TextInput
                  className='u-flexItem-grow'
                  data-testid='new-thrive-trm-bcc-email'
                  inputWidth='full'
                  label='Thrive TRM BCC Email'
                  onChange={() => {}}
                  placeholder={outreachThriveEmail}
                  value={outreachThriveEmail}
                />
                <CopyButton
                  className='u-marginLeft-8'
                  textToCopy={outreachThriveEmail}
                />
              </div>
            </Card>
          </Modal.Body>
        </Modal>
      </Card>
      {generateThriveEmailError ? (
        <Card className='u-marginBottom-12' isCentered={false} type='error'>
          <h4 className='u-marginTop-n'>
            There was an error creating Thrive TRM Email.
          </h4>
          {generateThriveEmailError}
        </Card>
      ) : null}
    </>
  );
};

EmailIntegrationCopyBCCEmail.defaultProps = {
  outreachEmailAddress: null,
};

EmailIntegrationCopyBCCEmail.propTypes = {
  outreachEmailAddress: PropTypes.string,
};

export default EmailIntegrationCopyBCCEmail;

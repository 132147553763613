import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import withTransactionTrackedForm from '../forms/withTransactionTrackedForm';

/**
 * Creates a higher order component for editing a contact record.
 * @param {Component} Component The Field Component to wrap
 * @param {[type]} createRootFieldState The method that is called to
 *   create the root FieldState object.
 * @param {String} formName The class name of the form.
 * @return {Component} A higher order component wrapping the Field
 *   Component in a form.
 */
export default (
  Component,
  createRootFieldState,
  formName,
  transformFieldValues = values => values,
) =>
  withTransactionTrackedForm(
    createRootFieldState,
    (formState, props) => {
      const { contact, contactActions } = props;

      const transactionId = uniqueId();

      contactActions.updateContact({
        contactId: contact.getIn(['data', 'id']) || contact.get('id'),
        contact: transformFieldValues(formState.getFieldValue()),
        transactionId: transactionId,
      });

      return formState.startSubmit(transactionId);
    },
    {
      formClassName: formName || 'contact-form',
      omitProps: ['onSaved', 'formState', 'contact'],
    },
  )(Component);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import { debounce } from 'lodash';

import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';

import ContactDuplicates from './ContactDuplicates';
import connectContactActions from './connectContactActions';

const { caseInsensitiveEquals, trim } = stringUtils;

export class ContactNameDuplicates extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.queryDebounced = debounce(this.query, props.debounceDelay);
  }

  componentDidMount() {
    const { componentId, contactActions } = this.props;
    contactActions.createContactsQuery(componentId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { firstName, lastName } = this.props;
    const { firstName: nextFirstName, lastName: nextLastName } = nextProps;

    if (
      !caseInsensitiveEquals(firstName, nextFirstName) ||
      !caseInsensitiveEquals(lastName, nextLastName)
    ) {
      this.queryDebounced(nextFirstName, nextLastName);
    }
  }

  componentWillUnmount() {
    const { componentId, contactActions } = this.props;
    contactActions.destroyContactsQuery(componentId);

    // Cancel any debounced queries
    this.queryDebounced.cancel();
  }

  query(firstName, lastName) {
    const { componentId, contactActions } = this.props;

    if (!trim(firstName) || !trim(lastName)) {
      contactActions.clearContactsQuery({ queryId: componentId });
    } else {
      contactActions.fetchContactsQuery({
        queryId: componentId,
        term: `${firstName} ${lastName}`,
      });
    }
  }

  render() {
    const {
      componentId,
      contactActions: _contactActions,
      firstName,
      lastName,
      ...props
    } = this.props;

    return trim(firstName) && trim(lastName) ? (
      <ContactDuplicates fieldType='name' queryId={componentId} {...props} />
    ) : null;
  }
}

ContactNameDuplicates.propTypes = {
  /**
   * A unique ID for the component, used as the queryId
   */
  componentId: PropTypes.string.isRequired,

  /**
   * The actions for querying contacts
   */
  contactActions: PropTypes.shape({
    clearContactsQuery: PropTypes.func.isRequired,
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    fetchContactsQuery: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * The delay (in milliseconds) to debounce queries.
   */
  debounceDelay: PropTypes.number,

  /**
   * The first name value that has been entered and will be checked against for possible
   * duplicates (along with `lastName`)
   */
  firstName: PropTypes.string,

  /**
   * The last name value that has been entered and will be checked against for possible
   * duplicates (along with `firstName`)
   */
  lastName: PropTypes.string,
};

ContactNameDuplicates.defaultProps = {
  debounceDelay: 300,
};

export default compose(
  setDisplayName('ContactNameDuplicates(enhanced)'),
  setPropTypes({
    firstName: ContactNameDuplicates.propTypes.firstName,
    lastName: ContactNameDuplicates.propTypes.lastName,
  }),
  withComponentId(),
  connectContactActions,
)(ContactNameDuplicates);

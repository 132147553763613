import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import ModalButton from 'modules/core/componentsLegacy/ModalButton';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import ContactRatingsModal from './ContactRatingsModal';

/**
 * Renders a button that, when clicked, shows a modal with details about the contact's individual
 * ratings.
 */
export default compose(
  setDisplayName('ContactRatingsModalButton'),
  setPropTypes({
    /**
     * The contents of the button
     */
    children: PropTypes.node.isRequired,

    /**
     * The ID of the contact whose ratings should be shown when the modal is opened.
     */
    contactId: PropTypes.number.isRequired,
  }),
  withProps({
    modal: ContactRatingsModal,
  }),
  withClassName('ContactRatingsModalButton'),
)(ModalButton);

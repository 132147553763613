import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, withStateHandlers } from 'recompose';

import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import EmailTemplateFormModal from './EmailTemplateFormModal';

/**
 * Renders a button that, when clicked, displays a modal form for creating an email template.
 */
const EmailTemplateFormModalButton = ({
  hideModal,
  isModalShown,
  showModal,
}) => (
  <>
    <ButtonPrimary
      icon='add'
      key='button'
      label='Add Email Template'
      onClick={showModal}
    />
    <EmailTemplateFormModal
      key='modal'
      onClose={hideModal}
      show={isModalShown}
    />
  </>
);

EmailTemplateFormModalButton.propTypes = {
  /**
   * A function that will be called when the create modal should be hidden.
   */
  hideModal: PropTypes.func.isRequired,

  /**
   * True if the modal should be visible/rendered, otherwise false.
   */
  isModalShown: PropTypes.bool,

  /**
   * A function that will be called when the modal should be shown.
   */
  showModal: PropTypes.func.isRequired,
};

EmailTemplateFormModalButton.defaultProps = {
  isModalShown: false,
};

/**
 * Enhanced version provides mangement of the modal show/hide state.
 */
export default compose(
  setDisplayName('EmailTemplateFormModalButton(enhanced)'),
  withStateHandlers(
    {
      isModalShown: false,
    },
    {
      showModal: () => () => ({ isModalShown: true }),
      hideModal: () => () => ({ isModalShown: false }),
    },
  ),
)(EmailTemplateFormModalButton);

import { normalize } from 'normalizr';
import routes from 'modules/routing/routes';
import { selectContactReview } from '../contacts/selectContact';
import createAjaxAction from '../createAjaxAction';
import { contactReviewSchema } from '../../modules/contacts/schema';
import {
  CONTACT_REVIEW_FETCH_START,
  CONTACT_REVIEW_FETCH_SUCCESS,
  CONTACT_REVIEW_FETCH_FAILURE,
} from './constants';

export const getUrl = ({ contactId, contactReviewId: id }) => {
  if (contactId) {
    return routes.api_v1_contact_contact_review({
      contactId: contactId,
      id: id,
    });
  }

  return routes.api_v1_contact_review({ id: id });
};

export const createStartPayload = ({ contactId, contactReviewId }) => ({
  contactReview: {
    id: contactReviewId,
    contact: {
      id: contactId,
    },
  },
});

const fetchContactReview = createAjaxAction({
  getUrl: getUrl,
  createStartPayload: createStartPayload,
  createSuccessPayload: (payload, data) => ({
    ...payload,
    ...normalize(data, contactReviewSchema),
    data: data,
  }),
  start: CONTACT_REVIEW_FETCH_START,
  success: CONTACT_REVIEW_FETCH_SUCCESS,
  failure: CONTACT_REVIEW_FETCH_FAILURE,
});

const fetchAndSelectContactReview = payload => dispatch => {
  dispatch(selectContactReview(payload.contactReviewId));
  return dispatch(fetchContactReview(payload));
};

export default fetchAndSelectContactReview;

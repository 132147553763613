import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import tagSchema from '../schemas/tag';

/**
 * Gets a property of a tag entity by it's ID.
 * @param {*} state
 * @param {Number} id The ID of the tag.
 * @param {String} propertyName The name of the property.
 * @returns {*} The property value for the tag, if found.
 */
export default (state, id, propertyName) =>
  getEntityProperty(state, tagSchema.key, id, propertyName);

import React from 'react';
import PropTypes from 'prop-types';
import AttachmentsListItem from 'modules/documents/components/AttachmentsListItem';
import ExpandableActivityView from './ExpandableActivityView';

const OutreachActivityView = ({ documents, notes, subject }) => (
  <div className='u-marginTop-4 u-fontSize-small'>
    {subject ? (
      <p className='u-margin-n'>
        <strong>Subject:</strong> {subject}
      </p>
    ) : null}
    <ExpandableActivityView content={notes} />
    {documents?.map(({ fileName, id, url }) => (
      <AttachmentsListItem fileName={fileName} key={id} url={url} />
    ))}
  </div>
);

OutreachActivityView.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  ),
  notes: PropTypes.string,
  subject: PropTypes.string,
};

export default OutreachActivityView;

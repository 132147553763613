import { NAME } from '../constants';

/**
 * Gets the list of references that belong to a particular parent (parent type + id)
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CONTACT or PARENT_CANDIDACY)
 * @param {Number} parentId The parent ID -- either a contact ID when
 *   parentType === PARENT_CONTACT, or a candidacy ID when parentType === PARENT_CANDIDACY
 * @return {Immutable.Map|undefined} The references state, if it's been created (it won't be created
 *   until an action related to it has been dispatched)
 */
export default (state, parentType, parentId) =>
  state[NAME].getIn(['referencesByParentType', parentType, parentId]);

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import RevenueRangeSelectField from 'modules/currency/components/RevenueRangeSelectField';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import CompanyInvestmentStageTagSelectField from 'modules/tenant/components/CompanyInvestmentStageTagSelectField';

/**
 * A field for editing a contact's investor-related values
 */
const ContactInvestorField = ({
  fieldState,
  handleNestedFieldChange,
  ...otherProps
}) => (
  <div className='ContactInvestorField row'>
    <div className='col-sm-12'>
      <CheckboxField
        checked={fieldState
          .getNestedField('preferred_investor_interest')
          .getValue()}
        fieldState={fieldState.getNestedField('preferred_investor_interest')}
        label='Interested?'
        onChange={handleNestedFieldChange}
        text='Yes'
      />
    </div>
    <div className='col-12'>
      <CompanyInvestmentStageTagSelectField
        fieldState={fieldState.getNestedField(
          'preferred_investor_company_stage_ids',
        )}
        label='Company Stages'
        onChange={handleNestedFieldChange}
      />
    </div>
    <div className='col-12'>
      <RevenueRangeSelectField
        {...otherProps}
        clearable={true}
        fieldState={fieldState.getNestedField('revenue_range')}
        maximumFieldLabel='Company Revenue Maximum'
        minimumFieldLabel='Company Revenue Minimum'
        onChange={handleNestedFieldChange}
      />
    </div>
    <div className='col-12'>
      <TextAreaField
        {...otherProps}
        fieldState={fieldState.getNestedField('preferred_investor_notes')}
        label='Notes'
        onChange={handleNestedFieldChange}
      />
    </div>
  </div>
);

ContactInvestorField.propTypes = {
  /**
   * The field state containing the nested value.
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * Called when a nested field is changed.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('ContactInvestorField(enhanced)'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic(
    'createFieldState',
    (name = 'connections', contact, validator, _, ...rest) => {
      const values = fromJS({
        preferred_investor_company_stages: [],
        preferred_investor_interest: false,
      }).merge(contact);

      return FieldState.createNested(
        name,
        [
          CheckboxField.createFieldState(
            'preferred_investor_interest',
            values.get('preferred_investor_interest'),
          ),
          CompanyInvestmentStageTagSelectField.createFieldState(
            'preferred_investor_company_stage_ids',
            values.get('preferred_investor_company_stages').toArray(),
          ),
          RevenueRangeSelectField.createFieldState('revenue_range', {
            maximum: values.get('preferred_investor_revenue_maximum'),
            minimum: values.get('preferred_investor_revenue_minimum'),
          }),
          TextAreaField.createFieldState(
            'preferred_investor_notes',
            values.get('preferred_investor_notes') || '',
          ),
        ],
        validator,
        ({ revenue_range, ...otherValues }) => ({
          ...otherValues,
          // reconstruct revenue range values to expected properties.
          preferred_investor_revenue_maximum: revenue_range.maximum,
          preferred_investor_revenue_minimum: revenue_range.minimum,
        }),
        ...rest,
      );
    },
  ),
  withNestedFieldChangeHandler,
)(ContactInvestorField);

import routes from 'modules/routing/routes';
import { compose } from 'recompose';
import { PATCH } from 'modules/api/methods';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import defaultMethod from 'modules/api/actionModifiers/defaultMethod';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import withOptions from 'modules/api/actionModifiers/withOptions';
import revenueSchema from '../schema';
import {
  REVENUE_LIST_UPDATE_START,
  REVENUE_LIST_UPDATE_SUCCESS,
  REVENUE_LIST_UPDATE_FAILURE,
} from './actionTypes';

/**
 * The revenue for a company for a specific year
 * @typedef {Object} CompanyRevenueRecord
 * @property {Number} id id of record (if an existing record)
 * @property {Number} amount the revenue value.
 * @property {String} currency the currency type that the revenue value is provided in.
 * @property {Number} year The year this record applies to
 * @property {Boolean} _destroy If true, this record will be deleted.
 */

/**
 * Creates an action for bulk updating a list of revenue records for a company
 * @param {Object} payload
 * @param {String} payload.companyId The ID of the company to update the revenue records for
 * @param {CompanyRevenueRecord[]} payload.revenues The revenue records to bulk update.
 * @param {String} [payload.transactionId] An optional unique ID for tracking the submission.
 */
export default compose(
  fetchAction,
  normalizeResponse({ revenues: [revenueSchema] }),
  createAsyncActions({
    startActionType: REVENUE_LIST_UPDATE_START,
    successActionType: REVENUE_LIST_UPDATE_SUCCESS,
    failureActionType: REVENUE_LIST_UPDATE_FAILURE,
  }),
  withOptions(({ companyId, revenues }) => ({
    body: { revenues: revenues },
    url: routes.api_v1_company_revenues({ companyId: companyId }),
  })),
  defaultMethod(PATCH),
);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import emailTemplateSchema from '../schema';

/**
 *
 * @param {*} state
 * @param {Number} id The email template ID.
 * @return {String} The content of the email template.
 */
export default (state, id) =>
  getEntityProperty(state, emailTemplateSchema.key, id, 'content');

import { addErrorToPayload } from '../createAjaxAction';

// Generates error from server response, overrides
// default error logic in `createAjaxAction`

export const reduceFieldErrors = (payload, error) => {
  if (
    error.body &&
    error.body.errors &&
    Object.keys(error.body.errors).length
  ) {
    const { errors } = error.body;
    const errorMessage = Object.keys(errors)
      .map(key => `${key} ${errors[key].join(',')}`)
      .join(';');
    return { error: new Error(errorMessage), ...payload };
  }

  return addErrorToPayload(payload, error);
};

export default reduceFieldErrors;

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import { isValidEmail } from 'modules/core/validators';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import './ContactDetailsForm.scss';

const preferredEmailTypes = {
  work: 'WORK',
  personal: 'PERSONAL',
  none: 'NONE',
};

const ContactDetailsForm = ({
  contactDetails,
  preferredEmail,
  setPreferredEmail,
}) => {
  const isWorkEmailPreferred = preferredEmail === preferredEmailTypes.work;
  const isPersonalEmailPreferred =
    preferredEmail === preferredEmailTypes.personal;

  const validateEmail = value =>
    value && !isValidEmail(value) ? 'Please enter a valid email address' : null;

  return (
    <Card className='u-marginTop-32 u-border-n u-padding-n' isCentered={false}>
      <h2 className='u-marginTop-n'>Contact Details</h2>
      <div className='ContactDetailsForm__emailField u-marginBottom-8'>
        <Form.TextInput
          initialValue={contactDetails?.email}
          inputWidth='full'
          label='Personal Email'
          name='email'
          prepend={<i className='fa fa-envelope' />}
          rules={{ validate: validateEmail }}
          size='small'
        />
        <Checkbox
          className='ContactDetailsForm__preferredCheckbox u-margin-n u-fontWeight-normal'
          data-testid='personal-email-preferred-checkbox'
          isChecked={isPersonalEmailPreferred}
          label='Preferred'
          onChange={() => {
            setPreferredEmail(
              isPersonalEmailPreferred
                ? preferredEmailTypes.none
                : preferredEmailTypes.personal,
            );
          }}
        />
      </div>
      <div className='ContactDetailsForm__emailField u-marginBottom-8'>
        <Form.TextInput
          className='u-marginBottom-4'
          initialValue={contactDetails?.workEmail}
          inputWidth='full'
          label='Work Email'
          name='work_email'
          prepend={<i className='fa fa-envelope' />}
          rules={{ validate: validateEmail }}
          size='small'
        />
        <Checkbox
          className='ContactDetailsForm__preferredCheckbox u-margin-n u-fontWeight-normal'
          data-testid='work-email-preferred-checkbox'
          isChecked={isWorkEmailPreferred}
          label='Preferred'
          onChange={() => {
            setPreferredEmail(
              isWorkEmailPreferred
                ? preferredEmailTypes.none
                : preferredEmailTypes.work,
            );
          }}
        />
      </div>
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.phone}
        inputWidth='full'
        label='Phone'
        name='phone'
        prepend={<i className='fa fa-phone' />}
        size='small'
      />
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.workPhone}
        inputWidth='full'
        label='Work Phone'
        name='work_phone'
        prepend={<i className='fa fa-phone' />}
        size='small'
      />
      <Form.TextInput
        className='u-marginBottom-8'
        initialValue={contactDetails?.mobilePhone}
        inputWidth='full'
        label='Mobile Phone'
        name='mobile_phone'
        prepend={<i className='fa fa-mobile' />}
        size='small'
      />
    </Card>
  );
};

ContactDetailsForm.propTypes = {
  contactDetails: PropTypes.shape({
    email: PropTypes.string,
    mobilePhone: PropTypes.string,
    phone: PropTypes.string,
    workEmail: PropTypes.string,
    workEmailPreferred: PropTypes.bool,
    workPhone: PropTypes.string,
  }),
  preferredEmail: PropTypes.oneOf(Object.values(preferredEmailTypes)),
  setPreferredEmail: PropTypes.func,
};

export default ContactDetailsForm;

import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import hasFeature from 'modules/auth/selectors/hasFeature';
import getLocationIds from 'modules/contacts/selectors/contacts/getLocationIds';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import mapLocationIdsToLocations from 'modules/locations/components/mapLocationIdsToLocations';
import ContactLevelName from 'modules/tenant/components/ContactLevelName';
import transactionsState from 'modules/transactions/propTypes/transactionsState';

import CompanyPreferencesForm from './CompanyPreferencesForm';
import RevenueRange from '../RevenueRange';
import withEditableSection from '../withEditableSection';
import { COMMUTE_RADIUS_OPTIONS } from '../constants';

function CompanyPreferences({
  clearTransaction,
  contact,
  contactActions,
  isEditing,
  locations,
  toggleEditing,
  transactions,
  userType,
  viewRelocationPreferences,
}) {
  if (!isEditing) {
    const commRadius = contact.getIn(['data', 'preferred_commute_radius']);
    const commuteRadius = COMMUTE_RADIUS_OPTIONS.find(
      option => option.value === commRadius,
    );
    const commuteRadiusLabel = commuteRadius ? commuteRadius.label : null;
    const levelId = contact.getIn(['data', 'level']);
    const preferredRevenueMaximum = contact.getIn([
      'data',
      'preferred_revenue_maximum',
    ]);
    const preferredRevenueMinimum = contact.getIn([
      'data',
      'preferred_revenue_minimum',
    ]);
    const relocateDomestically = contact.getIn([
      'data',
      'preferred_relocate_domestically',
    ]);
    const relocateInternationally = contact.getIn([
      'data',
      'preferred_relocate_internationally',
    ]);

    return (
      <div className='CompanyPreferences'>
        <div className='row'>
          {relocateDomestically && (
            <div className='col-sm-6'>
              <LabeledContent label='Relocate Domestically?'>
                Yes
              </LabeledContent>
            </div>
          )}
          {relocateInternationally && (
            <div className='col-sm-6'>
              <LabeledContent label='Relocate Internationally?'>
                Yes
              </LabeledContent>
            </div>
          )}
          {Boolean(commuteRadiusLabel) && (
            <div className='col-sm-6'>
              <LabeledContent label='Commute Radius'>
                {commuteRadiusLabel}
              </LabeledContent>
            </div>
          )}
          {viewRelocationPreferences && locations.size > 0 && (
            <div className='col-sm-6'>
              <LabeledContent label='Relocation Preferences'>
                {locations.map(loc => loc.get('name')).join(', ')}
              </LabeledContent>
            </div>
          )}
          {(preferredRevenueMinimum || preferredRevenueMaximum) && (
            <div className='col-sm-6'>
              <RevenueRange
                hideIfEmpty={true}
                maxRevenue={preferredRevenueMaximum}
                minRevenue={preferredRevenueMinimum}
              />
            </div>
          )}
          {Boolean(levelId) && (
            <div className='col-sm-6'>
              <LabeledContent label='Level'>
                <ContactLevelName id={levelId} />
              </LabeledContent>
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <CompanyPreferencesForm
      clearTransaction={clearTransaction}
      contact={contact}
      contactActions={contactActions}
      initialValues={contact}
      onCancel={toggleEditing}
      onSaved={toggleEditing}
      onSubmit={toggleEditing}
      transactions={transactions}
      userType={userType}
      viewRelocationPreferences={viewRelocationPreferences}
    />
  );
}

CompanyPreferences.propTypes = {
  clearTransaction: PropTypes.func.isRequired,
  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      id: PropTypes.number,
      actively_looking: PropTypes.bool,
      passively_looking: PropTypes.bool,
      preferred_relocate_domestically: PropTypes.bool,
      preferred_relocate_internationally: PropTypes.bool,
      preferred_revenue_minimum: PropTypes.number,
      preferred_revenue_maximum: PropTypes.number,
      preferred_commute_radius: PropTypes.string,
      level: PropTypes.number,
    }),
  }).isRequired,
  contactActions: PropTypes.shape({
    updateContact: PropTypes.func.isRequired,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  locations: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number,
      name: PropTypes.string,
    }).isRequired,
  ).isRequired,
  toggleEditing: PropTypes.func.isRequired,
  transactions: transactionsState.isRequired,
  userType: PropTypes.string,
  viewRelocationPreferences: PropTypes.bool,
};

const COMPANY_PREFERENCES_FIELDS = [
  'level',
  'locations',
  'preferred_commute_radius',
  'preferred_relocate_domestically',
  'preferred_relocate_internationally',
  'preferred_revenue_maximum',
  'preferred_revenue_minimum',
];

const withSectionNotPresent = withProps(({ contact }) => ({
  sectionNotPresent:
    !contact ||
    COMPANY_PREFERENCES_FIELDS.every(fieldName => {
      const value = contact.getIn(['data', fieldName]);
      return List.isList(value) ? value.size === 0 : !value && value !== 0;
    }),
}));

export default compose(
  connect(
    (state, { contact }) => ({
      locationIds: getLocationIds(state, contact.getIn(['data', 'id'])),
      viewRelocationPreferences: hasFeature(state, 'field.contact.location'),
    }),
    {},
  ),
  mapLocationIdsToLocations,
)(
  withSectionNotPresent(
    withEditableSection(CompanyPreferences, {
      sectionId: 'companyPreferences',
      title: 'Company and Relocation Preferences',
    }),
  ),
);

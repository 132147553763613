import getPermittedCalendarIntegration from './getPermittedCalendarIntegration';

/**
 * Gets the integration status of the permitted calendar (i.e., whether the user has
 * chosen to integrate with this calendar, and remains connected).
 * @param  {*} state
 * @return {Boolean}    True if the calendar is integrated; false otherwise.
 */
export default state => {
  const calendar = getPermittedCalendarIntegration(state);

  if (!calendar) {
    return null;
  }

  // If the calendar list is empty, the user's integration is disconnected.
  // We need to check because at the beginning of a request -- even if we discover
  // during the request that the token isn't valid, and subsequently delete it --
  // they did have a token and will show up as "integrated".
  // A healthy integration will never have an empty list.
  const isCalendarListPresent =
    Boolean(calendar.get('list')) &&
    Boolean(Object.keys(calendar.get('list')).length);
  return Boolean(isCalendarListPresent && calendar.get('integrated'));
};

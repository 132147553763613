import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getFullName from '../selectors/contacts/getFullName';

/**
 * A Higher order component that sorts a list of contactIds by the contact's names.
 */
export default compose(
  connect(
    (state, { contactIds }) => ({
      contactIds:
        contactIds &&
        contactIds.sortBy(contactId =>
          stringUtils.toSortableString(getFullName(state, contactId)),
        ),
    }),
    {},
  ),
);

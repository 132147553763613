import { connect } from 'react-redux';
import getInterviewType from '../selectors/getInterviewType';

/**
 * A Higher order component that filters a list of interviewIds by an interview type.
 * This expects an `interviewIds` prop. If a `filterByInterviewType` prop is also
 * provided, the interviewIds will be filtered to only include interviews whose `interview_type`
 * value equals the `filterByInterviewType` value. If no `filterByInterviewType` value is given,
 * the interviewIds are simply passed through.
 */
export default connect(
  (state, { filterByInterviewType, interviewIds }) => ({
    interviewIds:
      interviewIds && filterByInterviewType
        ? interviewIds.filter(
            id => getInterviewType(state, id) === filterByInterviewType,
          )
        : interviewIds,
  }),
  {},
);

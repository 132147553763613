import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import { fromJS } from 'immutable';
import { TYPE_MEETING, PARENT_CANDIDACY, PARENT_CONTACT } from '../constants';

const handleInterviewSubmit = (formState, props) => {
  const {
    candidacyId,
    contactId,
    interview,
    interviewActions: { createInterview, updateInterview },
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (
    interview ||
    fromJS({
      contact_id: contactId,
      candidacy_id: candidacyId,
    })
  ).merge(fieldValue);
  const isScheduling = values.get('type') === 'scheduling';

  const action = values.get('id') ? updateInterview : createInterview;
  action({
    id: values.get('id'),
    parentId: values.getIn(['candidacy_id', 'value']) || candidacyId,
    parentType: PARENT_CANDIDACY,
    interview: {
      ...values.toJS(),
      attendee_ids: values.get('attendees'),
      search_ids: values.get('searches'),
      scheduling: isScheduling,
      start_time: !isScheduling && values.get('start_time'),
      end_time: !isScheduling && values.get('end_time'),
    },
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

const handleMeetingSubmit = (formState, props) => {
  const {
    contactId,
    meeting,
    meetingActions: { createMeeting, updateMeeting },
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (meeting || fromJS({ contact_id: contactId })).merge(
    fieldValue,
  );

  const action = values.get('id') ? updateMeeting : createMeeting;

  const meetingValues = values.toJS();

  action({
    parentId: contactId,
    parentType: PARENT_CONTACT,
    meeting: {
      search_ids: meetingValues.searches,
      attendee_ids: meetingValues.attendees,
      ...meetingValues,
    },
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

const handleSubmit = (formState, props) => {
  const isMeeting =
    formState.getFieldState().getNestedField('type').getValue() ===
    TYPE_MEETING;
  const action = isMeeting ? handleMeetingSubmit : handleInterviewSubmit;
  return action(formState, props);
};

export default handleSubmit;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import DateTime from 'modules/datetime/components/DateTime';
import UserName from 'modules/users/components/UserName';

import ExpandableText from '@thrivetrm/ui/components/ExpandableText';

import mapTaskIdToTask from './mapTaskIdToTask';

/**
 * Has the task has been assigned to a user who is not its creator?
 */
const showAssigned = task => {
  const assignedId = task.get('assigned_to');
  const createdId = task.get('created_by');
  return assignedId !== createdId;
};

/**
 * Renders the view mode of a task.
 */

export const TaskView = ({ task }) => (
  <div className='task-item task-view'>
    <div className='task-subject'>
      <strong>{task.get('subject')}</strong>
    </div>
    {showAssigned(task) && (
      <div className='task-assigned-to'>
        <span>Assigned to: </span>
        <UserName userId={task.get('assigned_to')} />
      </div>
    )}
    <div className='task-due-date'>
      <i className='fa fa-calendar' />
      <DateTime format='L' value={task.get('reminder')} />
    </div>
    <div className='task-due-time'>
      <i className='fa fa-clock-o' />
      <DateTime format='LT' value={task.get('reminder')} />
    </div>
    <ExpandableText
      characterLimit={150}
      content={task.get('notes')}
      isSanitizedHtml={true}
    />
  </div>
);

TaskView.propTypes = {
  /**
   * The task to render.
   */
  task: ImmutablePropTypes.mapContains({
    assigned_to: PropTypes.number.isRequired,
    created_by: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    notes: PropTypes.string,
    notes_partial: PropTypes.string,
    subject: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  setDisplayName('TaskView(enhanced)'),
  setPropTypes({
    taskId: PropTypes.number.isRequired,
  }),
  mapTaskIdToTask,
)(TaskView);

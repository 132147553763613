import PropTypes from 'prop-types';
import React from 'react';
import { compose, onlyUpdateForPropTypes, setDisplayName } from 'recompose';
import ContactSelect from 'modules/contacts/components/ContactSelect';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';

/* eslint-disable jsx-a11y/label-has-associated-control */

/**
 * Renders the panel that contains the filters which can be used to filter which contacts/talent
 * pools are displayed in the dashboard view.
 * Currently we only display a dropdown that allows a user to select a contact (aka "incumbent") --
 * we then limit the display to only showing that contact's direct reportsr.
 */
export const TalentPoolsFilters = ({ componentId, contactId, onChange }) => (
  <div>
    <h3>
      <i className='fa fa-filter' /> Filters
    </h3>
    <div>
      <label htmlFor={`${componentId}-contact`}>Incumbent</label>
      <ContactSelect
        autoBlur={true}
        clearable={true}
        id={`${componentId}-contact`}
        isCreatable={false}
        onChange={onChange}
        placeholder='Search by name'
        value={contactId}
      />
    </div>
  </div>
);

TalentPoolsFilters.propTypes = {
  componentId: PropTypes.string.isRequired,

  /**
   * The ID of the currently selected contact in the "incumbent" dropdown.
   * @type {[type]}
   */
  contactId: PropTypes.number,

  /**
   * Called when the filter changes.
   * Returns the ID of the selected contact, or null if none is seleected.
   */
  onChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('connect(TalentPoolsFilters)'),
  withComponentId(),
  onlyUpdateForPropTypes,
)(TalentPoolsFilters);

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import fetchJobListingActionCreator from '../actions/fetchJobListing';
import shouldFetchJobListingForSearch from '../selectors/shouldFetchJobListingForSearch';
import getJobListingBySearchId from '../selectors/getJobListingBySearchId';

/**
 * Fetches a job listing record by it's search ID
 * Provides the following prop:
 * * `shouldFetchJobListing` {Boolean} indicates whether the job listing should be fetched
 * * `fetchJobListing` {Function} a function that can be called without params to fetch the
 *   job listing
 * * `fetchJobListingIfNeeded` {Function} a function that can be called without params to fetch the
 *   job listing only if it is needed from the server.
 */
export default compose(
  setDisplayName('withJobListingFetched'),
  setPropTypes({
    /**
     * The ID of the search to fetch the job listing for.
     */
    searchId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { searchId }) => ({
      shouldFetchJobListing: shouldFetchJobListingForSearch(state, searchId),
      jobListing: getJobListingBySearchId(state, searchId),
    }),
    {
      fetchJobListing: fetchJobListingActionCreator,
    },
  ),
  withHandlers({
    fetchJobListingIfNeeded: ({
      fetchJobListing,
      searchId,
      shouldFetchJobListing,
    }) => () => {
      if (shouldFetchJobListing) {
        fetchJobListing({ searchId: searchId });
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchJobListingIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchJobListingIfNeeded();
    },
  }),
);

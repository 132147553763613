import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import SearchStageListMenuItem from './SearchStageListMenuItem';

/**
 * An enhanced version of a `SearchStageListMenuItem` for rendering
 * a search stage as a list item.
 */
export default compose(
  setDisplayName('SearchStageListStageMenuItem'),
  setPropTypes({
    /**
     * Called when the item is selected. The callback will include the `stage`
     * as the first parameter in the callback.
     */
    onClick: PropTypes.func.isRequired,

    /**
     * The stage to display and which will be passed back when
     * onClick is called.
     */
    stage: ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  mapProps(({ stage, ...rest }) => ({
    children: stage.get('name'),
    value: stage,
    ...rest,
  })),
)(SearchStageListMenuItem);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import Notification from './Notification';

const NotificationItemTypeVote = ({ notification, ...props }) => {
  const vote = fromJS(notification.getIn(['data', 'resource']));

  return (
    <Notification {...props} notification={notification}>
      <ExpandableText
        characterLimit={150}
        content={vote.getIn(['data', 'formatted_comment'])}
        isSanitizedHtml={true}
      />
    </Notification>
  );
};

NotificationItemTypeVote.propTypes = {
  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          formatted_comment: PropTypes.string,
          truncated_comment: PropTypes.string,
        }),
      }),
      type: PropTypes.oneOf(['candidacy_vote']),
    }),
  }).isRequired,
};

export default NotificationItemTypeVote;

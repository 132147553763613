import PropTypes from 'prop-types';
import React from 'react';
import { withProps } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import SpecialNeedsForm from './SpecialNeedsForm';
import withEditableSection from '../withEditableSection';

/* eslint-disable react/no-danger */
function SpecialNeedsCandidate({
  clearTransaction,
  contact,
  contactActions,
  isEditing,
  toggleEditing,
  transactions,
}) {
  const data = contact.get('data');
  if (!isEditing) {
    return (
      <ExpandableText
        characterLimit={300}
        content={data.get('special_needs')}
        isSanitizedHtml={true}
      />
    );
  }

  return (
    <div>
      <SpecialNeedsForm
        clearTransaction={clearTransaction}
        contact={contact}
        contactActions={contactActions}
        onCancel={toggleEditing}
        onSaved={toggleEditing}
        transactions={transactions}
      />
    </div>
  );
}
/* eslint-enable react/no-danger */

SpecialNeedsCandidate.propTypes = {
  clearTransaction: PropTypes.func.isRequired,

  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      career_highlights: PropTypes.string,
    }),
  }).isRequired,

  contactActions: PropTypes.shape({
    updateContact: PropTypes.func.isRequired,
  }).isRequired,

  isEditing: PropTypes.bool.isRequired,

  toggleEditing: PropTypes.func.isRequired,

  transactions: transactionsState.isRequired,
};

const LOCATION_FIELDS = ['special_needs'];

const withSectionNotPresent = withProps(({ contact }) => ({
  sectionNotPresent:
    !contact ||
    LOCATION_FIELDS.every(fieldName => !contact.getIn(['data', fieldName])),
}));

export default withSectionNotPresent(
  withEditableSection(SpecialNeedsCandidate, {
    sectionId: 'special_needs',
    title: 'Describe your ideal job, location, and timing.',
    sectionClassName: 'SpecialNeedsCandidate',
  }),
);

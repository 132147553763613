import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import pluralize from 'pluralize';
import BottomPanel from '@thrivetrm/ui/components/BottomPanel';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import Icon from '@thrivetrm/ui/components/Icon';
import FullPageTakeoverHeader from '@thrivetrm/ui/components/FullPageTakeoverHeader';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import PluralText from '@thrivetrm/ui/components/PluralText';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import DuplicateMergeAttributeRow from './DuplicateMergeAttributeRow';
import { loadAttributes, mergeRecords } from '../duplicateMergeSlice';

const DuplicateMergeAttributes = ({
  attributesEndpoint,
  autoMergeAttributes,
  avatarShape,
  mergeEndpoint,
  recordAIcon,
  recordAProfileUrl,
  recordBIcon,
  recordLabel,
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const POST_MERGE_REDIRECT_DELAY_MS = 2500;
  const hasLinkedInSkillsFeature = useFeatureCheck('feature.linkedin_skills');

  const {
    attributesByCategory,
    hasMadeAllSelections,
    isLoadingAttributes,
    isMergeComplete,
    isMerging,
    loadAttributesError,
    mergeRecordsError,
  } = useSelector(state => state.duplicateMerge);

  if (isMergeComplete) {
    setTimeout(() => {
      window.location = recordAProfileUrl;
    }, POST_MERGE_REDIRECT_DELAY_MS);
  }
  useEffect(() => {
    dispatch(loadAttributes(attributesEndpoint));
  }, [dispatch, attributesEndpoint]);

  const mergeMessage = isMerging
    ? 'Merging...'
    : `Merged! Redirecting to the updated profile of ${recordLabel} A...`;

  return (
    <div className='DuplicateMergeAttributes'>
      <FullPageTakeoverHeader
        backUrl={document.referrer || recordAProfileUrl}
        title='Doppelgänger Wrangler'
      />
      <div className='container-full-width u-marginTop-64 u-marginBottom-32 u-paddingBottom-64'>
        {isMerging || isMergeComplete ? (
          <div style={{ minHeight: '80vh' }}>
            <div className='DuplicateMergeAttributes__loading'>
              <div className='DuplicateMergeAttributes__loadingIcon DuplicateMergeAttributes__loadingIcon--left'>
                {recordAIcon}
              </div>
              <div className='DuplicateMergeAttributes__loadingIcon DuplicateMergeAttributes__loadingIcon--right'>
                {recordBIcon}
              </div>
            </div>
            <h2 className='DuplicateMergeAttributes__loadingMessage'>
              {mergeMessage}
            </h2>
          </div>
        ) : (
          <LoadingContainer
            isLoading={isLoadingAttributes}
            isSticky={true}
            minHeight='80vh'
            overlayColor='gray20'
            size='large'
          >
            {loadAttributesError ? (
              <Card
                className='DuplicateMergeAttributes__errorCard'
                type='error'
              >
                {loadAttributesError}
              </Card>
            ) : (
              <table className='DuplicateMergeAttributes__table'>
                <thead>
                  <tr className='DuplicateMergeAttributes__row'>
                    <th
                      aria-label='Attributes'
                      className='DuplicateMergeAttributes__header'
                    />
                    <th className='DuplicateMergeAttributes__header DuplicateMergeAttributes__header--tooltip'>
                      {recordLabel} A
                      <div className='DuplicateMergeAttributes__tooltipContainer'>
                        <span className='DuplicateMergeAttributes__tooltipText'>
                          Pick A or B
                        </span>
                      </div>
                    </th>
                    <th className='DuplicateMergeAttributes__header'>
                      {recordLabel} B
                    </th>
                    <th className='DuplicateMergeAttributes__header'>
                      Final Result
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {attributesByCategory &&
                    attributesByCategory.map((category, index) => (
                      <React.Fragment key={category.category_name}>
                        {/* The first section should not render a category heading */}
                        {index > 0 && (
                          <tr className='DuplicateMergeAttributes__row--category'>
                            <td className='u-backgroundColor-transparent u-border-n' />
                            <td
                              className='DuplicateMergeAttributes__categoryCell'
                              colSpan='3'
                            >
                              {category.category_name}
                            </td>
                          </tr>
                        )}
                        {category.attributes.map(attribute => (
                          <DuplicateMergeAttributeRow
                            attribute={attribute}
                            avatarShape={avatarShape}
                            className='DuplicateMergeAttributes__row'
                            data-testid={`${attribute.label}_row`}
                            key={attribute.name}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  <tr className='DuplicateMergeAttributes__row--category'>
                    <td className='u-backgroundColor-transparent u-border-n' />
                    <td
                      className='DuplicateMergeAttributes__categoryCell'
                      colSpan='3'
                    >
                      A-LA-KA-ZAM!
                    </td>
                  </tr>
                  <tr className='DuplicateMergeAttributes__row'>
                    <td className='u-backgroundColor-transparent u-border-n' />
                    <td
                      className='u-backgroundColor-transparent u-border-n'
                      colSpan='3'
                    >
                      <Card className='u-marginBottom-16' isCentered={false}>
                        <div className='u-flex'>
                          <Icon
                            backgroundColor='yellow'
                            className='u-margin-8'
                            color='purple'
                            size='medium'
                            type='magicWand'
                          />
                          <div className='DuplicateMergeAttributes__autoMergeAttributes'>
                            <h3 className='u-marginSquish-8'>
                              We&apos;ll combine all of the following data from
                              both{' '}
                              <PluralText
                                case='lowerCase'
                                quantity={2}
                                text={recordLabel}
                              />
                              , automatically
                            </h3>
                            <ul className='DuplicateMergeAttributes__autoMergeAttributesList'>
                              {autoMergeAttributes.map(category => (
                                <li key={category}>{category}</li>
                              ))}
                            </ul>
                            {hasLinkedInSkillsFeature &&
                            recordLabel === 'Contact' ? (
                              <h3 className='u-margin-12'>
                                If applicable, LinkedIn Skills will default to
                                the most recently imported set of skills
                              </h3>
                            ) : null}
                          </div>
                        </div>
                      </Card>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {mergeRecordsError && (
              <Card
                className='DuplicateMergeAttributes__errorCard'
                type='error'
              >
                {mergeRecordsError}
              </Card>
            )}
          </LoadingContainer>
        )}
      </div>
      {isMerging || isMergeComplete ? null : (
        <BottomPanel className='u-inlineFlex u-flexJustify-r' isOpen={true}>
          <ButtonPrimary
            className='u-margin-8'
            isOutline={true}
            label='Cancel'
            url={recordAProfileUrl}
          />
          <Tooltip
            content={
              hasMadeAllSelections ? null : 'Additional selections are required'
            }
            position='top'
          >
            <ButtonPrimary
              className='u-margin-8'
              icon='flash'
              isDisabled={!hasMadeAllSelections || Boolean(loadAttributesError)}
              label="Final result looks good, let's merge!"
              onClick={() => setIsConfirmDialogOpen(true)}
            />
          </Tooltip>
        </BottomPanel>
      )}
      <ConfirmationDialog
        cancelLabel='No, Keep Working'
        confirmLabel={`Yes, Merge ${pluralize(recordLabel)}`}
        isOpen={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={() => dispatch(mergeRecords(mergeEndpoint))}
        title={`Merge ${pluralize(recordLabel)}?`}
      >
        <p className='u-paddingHorizontal-24'>
          {recordLabel} B will be deleted after its data is merged into{' '}
          {recordLabel} A. This cannot be undone.
        </p>
        <div>Do you want to continue?</div>
      </ConfirmationDialog>
    </div>
  );
};

DuplicateMergeAttributes.propTypes = {
  attributesEndpoint: PropTypes.string.isRequired,
  autoMergeAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  avatarShape: PropTypes.string.isRequired,
  mergeEndpoint: PropTypes.string.isRequired,
  recordAIcon: PropTypes.node.isRequired,
  recordAProfileUrl: PropTypes.string.isRequired,
  recordBIcon: PropTypes.node.isRequired,
  recordLabel: PropTypes.string.isRequired,
};

export default DuplicateMergeAttributes;

import updateEntity from 'modules/entities/actions/updateEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import { PARENT_SEARCH } from 'modules/candidacies/constants';
import invalidateCandidacyList from 'modules/candidacies/actions/invalidateCandidacyList';
import recommendationsSchema from '../recommendationsSchema';
import {
  MOVE_TO_CANDIDATES,
  NOT_A_FIT_LABEL,
  NOT_A_FIT,
  RECOMMEND,
  RECOMMENDED_LABEL,
} from '../constants';
import createRecommendedCandidacies from './createRecommendedCandidacies';

/**
 * Creates an action for moving a recommendation to Not a Fit or Candidates tab/Add Search
 * @param {Object} payload
 * @param {String} payload.id The ID of the recommendation to update
 * @param {Object} payload.search_id The searchID of the recommendation
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 * @param {Boolean} isMovetoSearch the PROP to differentiate between Move to Search/Reject call
 */
export default ({
  id,
  isMoveToRecommendations,
  isMovetoSearch,
  searchId,
  transactionId,
}) => dispatch => {
  if (isMovetoSearch) {
    dispatch(
      createRecommendedCandidacies({
        transactionId: transactionId,
        url: routes.convert_api_v1_search_recommended_candidacy({
          search_id: searchId,
          id: id,
        }),
      }),
    ).then(action => {
      if (action.type === entityActionTypes.CREATE_SUCCESS) {
        dispatch({
          type: MOVE_TO_CANDIDATES,
          contactId: Number(id),
          searchId: Number(searchId),
        });
        dispatch(
          invalidateCandidacyList({
            parentId: Number(searchId),
            parentType: PARENT_SEARCH,
          }),
        );
      }
      return action;
    });
  } else {
    dispatch(
      updateEntity({
        transactionId: transactionId,
        entityType: recommendationsSchema.key,
        responseSchema: { recommendations: recommendationsSchema },
        url: routes.api_v1_search_recommended_candidacy({
          search_id: searchId,
          id: id,
        }),
        body: {
          recommended_candidacy: {
            stage: isMoveToRecommendations
              ? RECOMMENDED_LABEL
              : NOT_A_FIT_LABEL,
          },
        },
      }),
    ).then(action => {
      if (action.type === entityActionTypes.UPDATE_SUCCESS) {
        const contactId = action.payload.result.recommended_candidacy.id;
        const resultSearchId =
          action.payload.result.recommended_candidacy.search_id;
        dispatch({
          type: isMoveToRecommendations ? RECOMMEND : NOT_A_FIT,
          contactId: contactId,
          searchId: resultSearchId,
        });
      }
      return action;
    });
  }
};

/**
 * A Sidebar is rendered to the left of the page and can optionally be in an expanded state.
 * Currently, when expanded, a class needs to be added to a container div that lives outside
 * of the scope of the Sidebar component itself (because, right now, the sidebar is rendered
 * as a completely separate React instance from the page content). So we basically have
 * something like this:
 *
 * <div class='page-with-sidebar'>
 *   <Sidebar />
 *   <div>[page content is here]</div>
 * </div>
 *
 * And we need to add or remove the `sidebar-expanded` class to the wrapper div based on the
 * current expanded state of the sidebar. This is handled by calling ``.
 */

/**
 * The selector for the container element to toggle the sidebar's expanded state class
 * (`SIDEBAR_EXPANDED_STATE_CLASS`)
 */
export const SIDEBAR_EXPANDED_STATE_ELEMENT_SELECTOR = '.page-with-sidebar';

/**
 * The class to add to the element selected by `SIDEBAR_EXPANDED_STATE_ELEMENT_SELECTOR` which
 * indicates that the sidebar is currently expanded. When collapsed, this class gets removed.
 */
export const SIDEBAR_EXPANDED_STATE_CLASS = 'sidebar-expanded';

/**
 * Ensures that the container element has or does not have the `SIDEBAR_EXPANDED_STATE_CLASS`,
 * depending on the expanded state.
 * @param {Boolean} isExpanded True if the sidebar is expanded, false if it is collapsed.
 */
export const ensureContainerExpandedClass = isExpanded => {
  const containerElement = document.querySelector(
    SIDEBAR_EXPANDED_STATE_ELEMENT_SELECTOR,
  );

  if (containerElement) {
    if (isExpanded) {
      containerElement.classList.add(SIDEBAR_EXPANDED_STATE_CLASS);
    } else {
      containerElement.classList.remove(SIDEBAR_EXPANDED_STATE_CLASS);
    }
  }
};

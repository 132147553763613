import fetchEntity from 'modules/entities/actions/fetchEntity';
import routes from 'modules/routing/routes';
import noteSchema from '../schema';

/**
 * Creates an action for fetching a note
 * @param {Object} payload
 * @param {String} payload.id The ID of the note
 */
export default ({ id }) =>
  fetchEntity({
    entityType: noteSchema.key,
    id: id,
    responseSchema: { note: noteSchema },
    url: routes.api_v1_note({ id: id }),
  });

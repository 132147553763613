import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, withProps, setPropTypes } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import withCompanyEmployeeCountListFetched from './withCompanyEmployeeCountListFetched';
import withSortedEmployeeCountIds from './withSortedEmployeeCountIds';
import mapEmployeeCountIdsToEmployeeCounts from './mapEmployeeCountIdsToEmployeeCounts';

/**
 * Renders a list of employeeCounts associated with a company.
 */
export const CompanyEmployeeCountListView = ({ employeeCounts, isLoading }) => (
  <div className='CompanyEmployeeCountListView'>
    {employeeCounts &&
      employeeCounts
        .map(employeeCount => (
          <LabeledContent
            className='CompanyEmployeeCountListView__item'
            key={employeeCount.get('id')}
            label={String(employeeCount.get('year')) || 'Unknown'}
          >
            {typeof employeeCount.get('amount') === 'number'
              ? employeeCount.get('amount').toLocaleString()
              : employeeCount.get('amount')}
          </LabeledContent>
        ))
        .toArray()}
    {isLoading && <LoadingIndicator />}
  </div>
);

CompanyEmployeeCountListView.propTypes = {
  /**
   * The employeeCounts to render.
   */
  employeeCounts: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      year: PropTypes.number,
      amount: PropTypes.number,
    }),
  ),

  /**
   * EmployeeCount list is still in the proccess of fetching.
   */
  isLoading: PropTypes.bool,
};

export default compose(
  setDisplayName('CompanyEmployeeCountListView(enhanced)'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  withCompanyEmployeeCountListFetched,
  withProps(({ employeeCountList }) => ({
    employeeCountIds: employeeCountList && employeeCountList.get('ids'),
    isLoading:
      employeeCountList && employeeCountList.getIn(['_meta', 'isFetching']),
  })),
  withSortedEmployeeCountIds,
  mapEmployeeCountIdsToEmployeeCounts,
)(CompanyEmployeeCountListView);

import mapEntityIdsToEntities from 'modules/entities/components/mapEntityIdsToEntities';
import locationSchema from '../schema';

/**
 * A higher order component which maps a list of `locationIds` to
 * their location records.
 */
export default mapEntityIdsToEntities(locationSchema.key, {
  idsProp: 'locationIds',
  entitiesProp: 'locations',
});

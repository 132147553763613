import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ExternalLink from './ExternalLink';

/**
 * Ensures that an external link is rendered with an `href` that includes a scheme.
 *
 * This is basically a way to make sure we don't end up with links like:
 *   http://true.thrivetrm.com/searches/123/www.linkedin.com/inprofile/MyLinkedInUser
 * when we really wanted:
 *   http://www.linkedin.com/inprofile/MyLinkedInUser
 *
 * which is caused by the server not giving us a complete URL.
 *
 * This isn't totally ideal, and it's possible there _could_ be a false positive if we are given
 * an href like: "www.example.com/my/path/has/://a/colon/and/slash/slash/but/is/not/absolute"
 * So we should either validate these on the server side, or find a more robust library to deal
 * with this. But for now I think this handles the vast majority of cases.
 */
export default compose(
  setDisplayName('SchemeEnsuredExternalLink'),
  setPropTypes({
    /**
     * The href to link to.
     */
    href: PropTypes.string.isRequired,

    /**
     * If there is no scheme, the schema to apply to it.
     */
    preferredScheme: PropTypes.string.isRequired,
  }),
  defaultProps({
    preferredScheme: 'http',
  }),
  mapProps(({ href, preferredScheme, ...props }) => {
    let prefixedHref = href;

    // A URL like '//www.example.com' is valid and will use the host scheme
    if (href.indexOf('//') !== 0 && href.indexOf('://') === -1) {
      prefixedHref = `${preferredScheme}://${href}`;
    }

    return {
      href: prefixedHref,
      ...props,
    };
  }),
)(ExternalLink);

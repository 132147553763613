import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import { connect, useDispatch } from 'react-redux';
import pluralize from 'pluralize';
import canEditCandidacies from 'modules/candidacies/selectors/canEditCandidacies';
import {
  PARENT_SEARCH,
  PARENT_PIPELINE,
  SORT_BY_TYPES,
  SORT_ASCENDING_DEFAULTS,
} from 'modules/candidacies/constants';
import CandidacyFilterForm from 'modules/candidacies/components/CandidacyFilterForm';
import TabbedHeaderAction from 'modules/layouts/tabbedHeader/TabbedHeaderAction';
import TabbedHeaderContainer from 'modules/layouts/tabbedHeader/TabbedHeaderContainer';
import canViewHiringTeam from 'modules/searches/selectors/canViewHiringTeam';
import SearchPageViewLink from 'modules/searches/components/SearchPageViewLink';
import updateUserPreferenceFromProp from 'modules/user/components/updateUserPreferenceFromProp';
import getPreference from 'modules/user/selectors/getPreference';
import setPreference from 'modules/user/actions/setPreference';
import { loadCandidacyAssessments } from 'modules/candidacy-assessments/candidacyAssessmentsSlice';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import canEditSearchDetails from 'modules/searches/selectors/canEditSearchDetails';
import BulkUpdateCandidacyStageBottomPanel from './BulkUpdateCandidacyStageBottomPanel';
import BulkUpdateCandidacyStageModal from './BulkUpdateCandidacyStageModal';

import {
  LIST_MODES,
  CARD_MODES,
  LIST_MODE_GROUPED,
  LIST_MODE_DEFAULT,
  CARD_MODE_DEFAULT,
  LIST_MODES_SORT_BY_TYPES,
  LIST_MODES_SORT_DEFAULTS,
} from '../candidacyList/constants';

import CandidacyList from '../candidacyList/CandidacyList';
import CandidacyListModeSelect from '../candidacyList/CandidacyListModeSelect';

/**
 * The preference key to store the visible/hidden toggle selected.
 */
const SHOW_VISIBLE_CANDIDACIES_ONLY = 'showVisibleCandidaciesOnly';

/**
 * The preference key to use to store the last list mode selected.
 */
const APP_PREFERENCE_KEY_LIST_MODE = 'CandidaciesSearchPage.ListMode';

/**
 * The preference key to use to store the last card mode selected.
 */
const APP_PREFERENCE_KEY_CARD_MODE = 'CandidaciesSearchPage.CardMode';

/**
 * The search page/tab for displaying the list of candidacies.
 */
const CandidaciesSearchPage = ({
  allowAddRemoveCandidacies,
  allowShareAction,
  cardMode,
  filterText,
  isPriorityOnly,
  isVisibleOnly,
  listMode,
  onFilterChange,
  searchId,
  selectedTagIds,
  setCardMode,
  setListMode,
  setSelectedTagIds,
  sortAscending,
  sortBy,
  ...rest
}) => {
  const dispatch = useDispatch();
  const hasAssessmentTemplates = useFeatureCheck(
    'feature.assessment_templates',
  );
  const hasBulkActions = useFeatureCheck('feature.bulk_candidate_actions');
  const location = useLocation();
  const base =
    location.pathname.split('/')[1] === 'pipelines' ? 'People' : 'Candidate';
  const parentType = base === 'People' ? PARENT_PIPELINE : PARENT_SEARCH;

  useEffect(() => {
    if (hasAssessmentTemplates) {
      dispatch(loadCandidacyAssessments(searchId));
    }
  }, [searchId, dispatch, hasAssessmentTemplates]);

  return (
    <>
      <TabbedHeaderContainer
        {...rest}
        actions={[
          allowAddRemoveCandidacies && base !== 'People' ? (
            <TabbedHeaderAction
              key='add-remove'
              label={`Add/Remove ${pluralize(base)}`}
            >
              <SearchPageViewLink
                page={`${pluralize(base.toLowerCase())}/edit`}
                pageOnly={true}
                useDefaultLinkBehavior={true}
              >
                <i className='fa fa-pencil-square-o' />
              </SearchPageViewLink>
            </TabbedHeaderAction>
          ) : null,
          allowShareAction && (
            <TabbedHeaderAction key='share' label='Edit Search Team'>
              <SearchPageViewLink
                page='edit#search-team'
                pageOnly={true}
                useDefaultLinkBehavior={true}
              >
                <i className='fa fa-share-alt' />
              </SearchPageViewLink>
            </TabbedHeaderAction>
          ),
        ]}
        filter={
          <div className='CandidaciesSearchPage__filter'>
            <CandidacyFilterForm
              filterText={filterText}
              isPriorityOnly={isPriorityOnly}
              isVisibleOnly={isVisibleOnly}
              onChange={onFilterChange}
              searchId={searchId}
              selectedTagIds={selectedTagIds}
              setSelectedTagIds={setSelectedTagIds}
              sortAscending={sortAscending}
              sortBy={sortBy}
              sortOptions={LIST_MODES_SORT_BY_TYPES[listMode]}
            />
            <CandidacyListModeSelect
              candidacyType={base}
              cardModeValue={cardMode}
              listModeValue={listMode}
              onCardChange={setCardMode}
              onListChange={setListMode}
            />
          </div>
        }
      >
        <CandidacyList
          cardMode={cardMode}
          filter={filterText}
          groupByStage={listMode === LIST_MODE_GROUPED}
          parentType={parentType}
          priorityOnly={isPriorityOnly}
          searchId={searchId}
          selectedTagIds={selectedTagIds}
          sortAscending={sortAscending}
          sortBy={sortBy}
          visibleOnly={isVisibleOnly}
        />
      </TabbedHeaderContainer>
      {hasBulkActions ? (
        <>
          <BulkUpdateCandidacyStageBottomPanel searchId={searchId} />
          <BulkUpdateCandidacyStageModal />
        </>
      ) : null}
    </>
  );
};

CandidaciesSearchPage.propTypes = {
  /**
   * Determine whether the "add/remove candidacies" action is rendered in the header.
   */
  allowAddRemoveCandidacies: PropTypes.bool,

  /**
   * Determines whether the "share" action is rendered in the header.
   */
  allowShareAction: PropTypes.bool,

  cardMode: PropTypes.oneOf(CARD_MODES),

  /**
   * The filter text that is currently filtering the list of candidacies shown
   */
  filterText: PropTypes.string,

  /**
   * When true, only "priority" candidacies will be shown (candidates with a priority value > 0).
   */
  isPriorityOnly: PropTypes.bool,

  /**
   * When true, only "visible" candidacies will be shown (candidates with hidden = true).
   */
  isVisibleOnly: PropTypes.bool,

  /**
   * The current display mode for the list (grouped by stage or not)
   */
  listMode: PropTypes.oneOf(LIST_MODES).isRequired,

  /**
   * Called when the selected filters has changed.
   */
  onFilterChange: PropTypes.func.isRequired,

  /**
   * The ID of the search to display the candidacies for.
   */
  searchId: PropTypes.number.isRequired,

  /**
   * The current selected tags to filter the candidacy list on
   */
  selectedTagIds: PropTypes.arrayOf(PropTypes.number),

  setCardMode: PropTypes.func,

  /**
   * Sets the currently selected list mode (the size of the list items).
   */
  setListMode: PropTypes.func.isRequired,

  /**
   * Call this function when last tag checkbox is unchecked on Candidacy tags modal
   */
  setSelectedTagIds: PropTypes.func,

  /**
   * Wether to sort in descending order (true) or ascending order (false).
   */
  sortAscending: PropTypes.bool.isRequired,

  /**
   * The current sort order type.
   */
  sortBy: PropTypes.string,
};

CandidaciesSearchPage.defaultProps = {
  allowAddRemoveCandidacies: false,
  allowShareAction: false,
  filterText: '',
  isPriorityOnly: false,
  isVisibleOnly: false,
  selectedTagIds: [],
};

/**
 * The enhanced version of the `CandidaciesSearchPage` provides all necessary props except for
 * `searchId`
 */
export default compose(
  setDisplayName('CandidaciesSearchPage(enhanced)'),
  setPropTypes({
    /**
     * Determines the initial state of the list (filters, sorting, "mode", etc)
     * when the component is first mounted.
     */
    initialState: PropTypes.shape({
      cardMode: PropTypes.oneOf(CARD_MODES),
      isPriorityOnly: PropTypes.bool,
      isVisibleOnly: PropTypes.bool,
      listMode: PropTypes.oneOf(LIST_MODES),
      selectedTagIds: PropTypes.arrayOf(PropTypes.number),
      sortAscending: PropTypes.bool,
      sortBy: PropTypes.oneOf(SORT_BY_TYPES),
    }),

    /**
     * The page's search ID
     */
    searchId: PropTypes.number.isRequired,
  }),

  // Map in some permission variables and initialize the list mode from the previously
  // used list mode.
  connect(
    state => ({
      allowAddRemoveCandidacies: canEditCandidacies(state),
      allowShareAction: canViewHiringTeam(state) && canEditSearchDetails(state),
      userPreferenceListMode: getPreference(
        state,
        APP_PREFERENCE_KEY_LIST_MODE,
        LIST_MODE_DEFAULT,
      ),
      userPreferenceCardMode: getPreference(
        state,
        APP_PREFERENCE_KEY_CARD_MODE,
        CARD_MODE_DEFAULT,
      ),
      userPreferenceIsVisibleOnly: getPreference(
        state,
        SHOW_VISIBLE_CANDIDACIES_ONLY,
        false,
      ),
    }),
    {
      setPreference: setPreference,
    },
  ),

  // Add a listMode state property and initialize it based on either the previous list mode
  // used (if avaiable), or the default. This also ensures a valid list mode is provided.
  withState(
    'listMode',
    'setListMode',
    ({ initialState = {}, userPreferenceListMode }) => {
      const mode = initialState.listMode || userPreferenceListMode;
      return LIST_MODES.includes(mode) ? mode : LIST_MODE_DEFAULT;
    },
  ),

  // Add a cardMode state property and initialize it based on either the previous card mode
  // used (if avaiable), or the default. This also ensures a valid card mode is provided.
  withState(
    'cardMode',
    'setCardMode',
    ({ initialState = {}, userPreferenceCardMode }) => {
      const mode = initialState.cardMode || userPreferenceCardMode;
      return CARD_MODES.includes(mode) ? mode : CARD_MODE_DEFAULT;
    },
  ),

  // Initialize the filtering state props.
  withState(
    'filterText',
    'setFilterText',
    ({ initialState = {} }) => initialState.filterText || '',
  ),
  withState('isPriorityOnly', 'setIsPriorityOnly', ({ initialState = {} }) =>
    Boolean(initialState.isPriorityOnly),
  ),
  withState(
    'isVisibleOnly',
    'setIsVisibleOnly',
    ({ initialState = {}, userPreferenceIsVisibleOnly }) =>
      typeof initialState.isVisibleOnly === 'boolean'
        ? initialState.isVisibleOnly
        : Boolean(userPreferenceIsVisibleOnly),
  ),
  withState(
    'selectedTagIds',
    'setSelectedTagIds',
    ({ initialState = {} }) => initialState.selectedTagIds || [],
  ),
  withState('sortBy', 'setSortBy', ({ initialState = {}, listMode }) => {
    if (
      initialState.sortBy &&
      LIST_MODES_SORT_BY_TYPES[listMode].includes(initialState.sortBy)
    ) {
      return initialState.sortBy;
    }
    return LIST_MODES_SORT_DEFAULTS[listMode];
  }),
  withState(
    'sortAscending',
    'setSortAscending',
    ({ initialState = {}, sortBy }) =>
      initialState.sortAscending || SORT_ASCENDING_DEFAULTS[sortBy],
  ),
  withHandlers({
    onFilterChange: ({
      setFilterText,
      setIsPriorityOnly,
      setIsVisibleOnly,
      setSelectedTagIds,
      setSortAscending,
      setSortBy,
    }) => ({
      filterText,
      isPriorityOnly,
      isVisibleOnly,
      selectedTagIds,
      sortAscending,
      sortBy,
    }) => {
      setFilterText(filterText);
      setIsPriorityOnly(isPriorityOnly);
      setIsVisibleOnly(isVisibleOnly);
      setSelectedTagIds(selectedTagIds);
      setSortBy(sortBy);
      setSortAscending(sortAscending);
    },
  }),
  updateUserPreferenceFromProp('listMode', APP_PREFERENCE_KEY_LIST_MODE),
  updateUserPreferenceFromProp('cardMode', APP_PREFERENCE_KEY_CARD_MODE),
  updateUserPreferenceFromProp('isVisibleOnly', SHOW_VISIBLE_CANDIDACIES_ONLY),
  lifecycle({
    componentWillUnmount: function () {
      if (this.props.onStoreState) {
        const {
          cardMode,
          filterText,
          isPriorityOnly,
          isVisibleOnly,
          listMode,
          onStoreState,
          selectedTab,
          selectedTagIds,
          sortAscending,
          sortBy,
        } = this.props;

        // Tell the parent component to save our state before we unmount.
        // This allows switching search page "tabs" and letting us restore the last
        // filter/sort state when this tab is revisited.
        onStoreState(selectedTab, {
          filterText: filterText,
          isPriorityOnly: isPriorityOnly,
          isVisibleOnly: isVisibleOnly,
          listMode: listMode,
          cardMode: cardMode,
          selectedTagIds: selectedTagIds,
          sortBy: sortBy,
          sortAscending: sortAscending,
        });
      }
    },
  }),
)(CandidaciesSearchPage);

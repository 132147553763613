import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import serialize from 'form-serialize';
import routes from 'modules/routing/routes';
import url from 'modules/routing/url';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { setRecordTypeConfig } from './recordIndexSlice';
import { generateSearchFilterSummary } from '../../lib/filterUtils';
import { initializeFilters } from '../../pages/searches';
import ReportModal from '../../lib/report_modal';
import { BulkActionTypes, BulkTagCategoryTypes } from './constants';
import RecordIndexPageContainer from './RecordIndexPageContainer';

const { ADD_TO_NETWORK, BULK_EDIT_TAGS, DELETE } = BulkActionTypes;
const { INDUSTRIES, SECTORS } = BulkTagCategoryTypes;

const CreateSearchButton = () => (
  <ButtonPrimary
    className='u-noWrap'
    icon='add'
    label='Add Search'
    url='/searches/new'
  />
);

const CreateSearchRequestButton = () => (
  <ButtonPrimary
    className='u-noWrap'
    icon='add'
    label='Create Search Request'
    url='/search_request_form/request_details'
  />
);

// Endpoints that return data from /api
const endpoints = {
  bulkActions: recordIds => url('/api/v1/searches/bulk', { ids: recordIds }),
  records: query => routes.api_v1_searches({ query: query }),
  savedViews: () => routes.api_v1_searches_saved_views(),
  savedView: id => routes.api_v1_searches_saved_view({ id: id }),
  customReport: routes.custom_report_api_v1_searches(),
  customReportTemplates: null,
};

const generateFilterSummaryFromJQueryModal = () => {
  const reportModal = new ReportModal();
  generateSearchFilterSummary(reportModal);
  return reportModal.filters;
};

const filterFormElement = () => document.querySelector('.form.filters-form');
const generateFilterParamsFromJQueryModal = () => {
  const newFilterParams =
    serialize(filterFormElement(), { hash: true }).filters || {};

  // The s key is the legacy form way of indicating the current sort field.
  // For the recordIndex module the sortField is handled in react.
  // This keeps saved view data consistent across record types, however,
  // requires that we delete the s key here.
  if (newFilterParams.s) {
    delete newFilterParams.s;
  }
  return newFilterParams;
};

const shouldSortByRelevance = () => {
  const RELEVANCE_SORT_DIRECTION_FIELDS = [
    'company',
    'job_title',
    'keyword',
    'search_number',
  ];

  return Object.keys(generateFilterParamsFromJQueryModal()).some(filterKey =>
    RELEVANCE_SORT_DIRECTION_FIELDS.includes(filterKey),
  );
};

// Our mechanism for interacting with the legacy jQuery filter modal
const filterAdapter = {
  closeModal: () => $('#filters').modal('hide'),
  formEl: filterFormElement,
  generateFilterParams: generateFilterParamsFromJQueryModal,
  generateFilterSummary: generateFilterSummaryFromJQueryModal,
  keywordInputEl: () => document.getElementById('filter_keyword_advanced'),
  resetLinkEl: () => document.getElementById('filters__reset-link'),
  shouldSortByRelevance: shouldSortByRelevance,
  clearForm: () => {
    //  window.clearFilters() is a bridge exposed via app/views/searches/_advanced_search.html.slim
    window.clearFilters();
    initializeFilters();
  },
  openModal: () => $('#filters').modal('show'),
};

// Urls for the browser
const urls = {
  savedView: id => `/searches/views/${id}`,
};

const payloadKeys = {
  bulkTagRecordIdsKey: 'search_ids',
  userDefaultSavedViewId: 'searches_default_id',
};

const SearchRecordIndexPage = () => {
  const dispatch = useDispatch();

  const defaultSavedViewId = useSelector(state =>
    state.user.get('searches_default_saved_view_id'),
  );

  const { recordType } = useSelector(state => state.recordIndex);

  const canExportCustomReports = useFeatureCheck(
    'feature.searches_custom_report_generator',
  );

  const requireSearchRequestCreation = useFeatureCheck(
    'feature.only_allow_search_request_creation',
  );

  // On mount, set the record type config in the store.
  useEffect(() => {
    if (canExportCustomReports) {
      endpoints.customReportTemplates = routes.custom_report_templates_api_v1_searches();
    }

    const config = {
      CreateButton: requireSearchRequestCreation
        ? CreateSearchRequestButton
        : CreateSearchButton,
      defaultSavedViewId: defaultSavedViewId,
      endpoints: endpoints,
      filterAdapter: filterAdapter,
      isSidebarExpandedKey: 'isSidebarExpanded--searches',
      networkType: 'SearchNetwork',
      payloadKeys: payloadKeys,
      recordLabel: 'Search',
      recordLabelPlural: 'Searches',
      recordType: 'searches',
      supportedBulkActions: [ADD_TO_NETWORK, BULK_EDIT_TAGS, DELETE],
      supportedBulkTagCategories: [INDUSTRIES, SECTORS],
      urls: urls,
    };

    // TODO: Move to pages/searches/index in initialize;
    initializeFilters();
    dispatch(setRecordTypeConfig(config));
  }, [
    requireSearchRequestCreation,
    canExportCustomReports,
    defaultSavedViewId,
    dispatch,
  ]);

  // We use the presence of recordType to determine whether the record type
  // config has been set in the store.
  return recordType ? (
    <RecordIndexPageContainer recordType={recordType} />
  ) : null;
};

export default SearchRecordIndexPage;

import React from 'react';
import PropTypes from 'prop-types';
import routes from 'modules/routing/routes';
import DuplicateMergeRecordSelection from './DuplicateMergeRecordSelection';

const CompanyDuplicateMergeRecordSelection = ({ match }) => {
  const companyId = Number(match.params.companyId);

  return (
    <DuplicateMergeRecordSelection
      avatarShape='square'
      recordId={companyId}
      recordLabel='Company'
      recordProfileUrl={routes.company({ id: companyId })}
      recordType='company'
    />
  );
};

CompanyDuplicateMergeRecordSelection.propTypes = {
  // provided by react-router
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CompanyDuplicateMergeRecordSelection;

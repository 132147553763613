import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { fetchContact as fetchContactActionCreator } from '../actions/contacts';
import shouldFetchContactSelector from '../selectors/contacts/shouldFetchContact';
import getContactSelector from '../selectors/contacts/getContact';

/**
 * Fetches a contact record if it needs to be fetched from the server, given it's contactId.
 * @param {Object} options
 * @param {String} [contactIdPropName="contactId"] The name of the incoming prop that will
 *   contain the ID of the contact that should be fetched.
 * @param {String} [contactRecordPropName="contact"] The name of the outgoing prop that will
 *   containt the contain record once it's been fetched.
 * @param {String} [fetchContactOptions] Any additional options to be passed to the `fetchContact`
 *   action and `shouldFetchContact` selector (i.e. `{ requireFull: true }`)
 * @return {Function} A higher order component that will fetch a contact if needed, and supply
 *   it's record as a prop.
 *
 * @example
 * ```jsx
 * const MyContactProp = ({ contact }) => (
 *   <span>{contact && contact.get('name')}</span>
 * )
 *
 * const MyEnhancedContactProp = withContactFetched()(MyContactProp);
 *
 * const MyOtherComponent = ({ contactId }) => (
 *   <MyEnhancedContactProp contactId={contactId} />
 * )
 * ```
 * */
export default ({
  contactIdPropName = 'contactId',
  contactRecordPropName = 'contact',
  fetchContactOptions = {},
} = {}) =>
  compose(
    setDisplayName('withContactFetched'),
    setPropTypes({
      /**
       * The ID of the contact to fetch.
       */
      [contactIdPropName]: PropTypes.number,
    }),

    mapProps(props => ({
      // Save incoming props so they aren't clobbered by our own props during our work.
      incomingProps: props,

      // Use a consistent prop name for contactId while we are in the context of our HoC.
      contactId: props[contactIdPropName],
    })),

    // Connect necessary store-related things.
    connect(
      (state, { contactId }) => ({
        shouldFetchContact: shouldFetchContactSelector(
          state,
          contactId,
          fetchContactOptions,
        ),
        contact: getContactSelector(state, contactId),
      }),
      {
        fetchContact: fetchContactActionCreator,
      },
    ),

    // Add handlers to make it easier to initiate actions without having to pass params each time.
    withHandlers({
      fetchContactIdNeeded: ({
        contactId,
        fetchContact,
        shouldFetchContact,
      }) => () => {
        if (shouldFetchContact) {
          fetchContact({ ...fetchContactOptions, id: contactId });
        }
      },
    }),

    // Whenever props change (or on initial mount) we want to make sure we fetch the contact if
    // we need to.
    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchContactIdNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchContactIdNeeded();
      },
    }),

    // Restore any original incoming props and supply the single contact record as an additional
    // outgoing prop.
    mapProps(({ contact, incomingProps }) => ({
      ...incomingProps,
      [contactRecordPropName]: contact,
    })),
  );

import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import SearchSectorNameList from 'modules/searches/components/SearchSectorNameList';
import mapDatumToCandidacyId from './mapDatumToCandidacyId';
import mapCandidacyIdToSearchId from '../mapCandidacyIdToSearchId';

/**
 * A candidacy table cell that renders the list of sectors for the search that a candidacy record
 * is associated with.
 */
export default compose(
  setDisplayName('CandidacySearchSectorListCell'),
  setPropTypes({
    /**
     * The candidacy ID
     */
    datum: PropTypes.number.isRequired,
  }),
  mapDatumToCandidacyId,
  mapCandidacyIdToSearchId,
)(SearchSectorNameList);

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchRecommend as fetchRecommendedActionCreator } from '../actions';
import shouldFetchRecommendedList from '../selectors/shouldFetchRecommendedList';

/**
 * When provided a searchId, fetches the new set of recommendations for that search if they need to be
 * fetched so they are available for a component.
 */
export default compose(
  setDisplayName('withRecommendedFetched'),
  setPropTypes({
    searchId: PropTypes.number,
  }),
  connect(
    (state, { searchId }) => ({
      shouldFetchFromServer: shouldFetchRecommendedList(state, searchId),
    }),
    dispatch => ({
      fetchRecommendedList: bindActionCreators(
        fetchRecommendedActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    /**
     * A handler for fetching the recommendation list specified by the `searchId` prop.
     * This allows the component to simply call `fetchRecommendedList()` without having to
     * supply params.
     */
    fetchRecommendedList: ({ fetchRecommendedList, searchId }) => () =>
      fetchRecommendedList({ searchId: searchId }),

    /**
     * A handler that, when called, only fetches the recommendation list if needed.
     */
    fetchRecommendedListIfNeeded: ({
      fetchRecommendedList,
      searchId,
      shouldFetchFromServer,
    }) => () =>
      shouldFetchFromServer && fetchRecommendedList({ searchId: searchId }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchRecommendedListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchRecommendedListIfNeeded();
    },
  }),
);

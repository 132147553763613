import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import getCandidacyStatus from 'modules/candidacies/selectors/getCandidacyStatus';
import isClientUser from 'modules/auth/selectors/isClientUser';
import isClientNoCompUser from 'modules/auth/selectors/isClientNoCompUser';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import CandidacyStatusContainer from './CandidacyStatusContainer';
import SearchCandidacyLink from '../SearchCandidacyLink';

/**
 * The candidacy status to render when there is no actual
 * underlying status. This simply renders the date the candidacy
 * was added to the search.
 */
const CandidacyStatusEmpty = ({ candidacyId }) => {
  const candidacyStatus = useSelector(state =>
    getCandidacyStatus(state, candidacyId),
  )?.get('candidacy_status');

  const hasCandidateMetadataFeature = useFeatureCheck(
    'feature.display_candidate_metadata',
  );

  const canViewOutreachCount = useSelector(
    state =>
      !isClientUser(state) &&
      !isClientNoCompUser(state) &&
      hasCandidateMetadataFeature,
  );

  const canViewAddedBy = status => {
    const containsAddedTo = inputString => {
      const regexPattern = /Added by/i;
      return regexPattern.test(inputString);
    };

    if (!canViewOutreachCount && containsAddedTo(status)) {
      return false;
    }

    return true;
  };

  return (
    <CandidacyStatusContainer className='CandidacyStatusEmpty'>
      {canViewAddedBy(candidacyStatus) ? (
        <SearchCandidacyLink candidacyId={candidacyId} tab='overview'>
          {candidacyStatus}
        </SearchCandidacyLink>
      ) : null}
    </CandidacyStatusContainer>
  );
};

CandidacyStatusEmpty.propTypes = {
  candidacyId: PropTypes.number.isRequired,
};

export default CandidacyStatusEmpty;

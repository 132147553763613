import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import fetchAddressActionCreator from '../actions/fetchAddress';
import shouldFetchAddressSelector from '../selectors/shouldFetchAddress';
import getAddressSelector from '../selectors/getAddress';
import isAddressFetchedSelector from '../selectors/isAddressFetched';

/**
 * Fetches an address record from the server if it needs to be fetched.
 * @param {Object} options
 * @param {String} [options.idPropName="addressId"] The incoming propName that contains the
 * ID of the address to fetch.
 * @param {String} [options.dataPropName="address"] The outgoing propName to use for supplying
 *   the address record value.
 * @param {?String} [options.isDataReadyPropName] The propName to use for supplying a boolean value
 *   that indicates whether the address has been fetched and is ready to be used or not.
 *   This is optional and if not given the underlying prop value will not be provided.
 */
export default ({
  dataPropName = 'address',
  idPropName = 'addressId',
  isDataReadyPropName,
} = {}) =>
  compose(
    setDisplayName('withAddressFetched'),

    setPropTypes({
      /**
       * The ID of the address is required.
       */
      [idPropName]: PropTypes.number.isRequired,
    }),

    mapProps(props => ({
      addressId: props[idPropName],
      withAddressFetchedProps: props,
    })),

    connect(
      (state, { addressId }) => ({
        shouldFetchAddress: shouldFetchAddressSelector(state, addressId),
        address: getAddressSelector(state, addressId),
        isAddressFetched: isAddressFetchedSelector(state),
      }),
      {
        fetchAddress: fetchAddressActionCreator,
      },
    ),

    withHandlers({
      fetchAddressIfNeeded: ({
        addressId,
        fetchAddress,
        shouldFetchAddress,
      }) => () => shouldFetchAddress && fetchAddress({ id: addressId }),
    }),

    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchAddressIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchAddressIfNeeded();
      },
    }),

    mapProps(
      ({
        address,
        isAddressFetched,
        shouldFetchAddress,
        withAddressFetchedProps,
      }) => ({
        ...withAddressFetchedProps,
        [dataPropName]: address,

        // Only add the `isDataReadyPropName` prop if that prop name was supplied.
        ...(isDataReadyPropName
          ? {
              // The data is ready only if the address has been fetched (it's in state) and
              // `shouldFetchAddress` is false. This allows a component to, for example,
              // disable a field or show a loader until the address data is available --
              // and `shouldFetchAddress` alone is insufficient since it will be false
              // while loading is in progress.
              [isDataReadyPropName]: !shouldFetchAddress && isAddressFetched,
            }
          : null),
      }),
    ),
  );

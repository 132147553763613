import PropTypes from 'prop-types';
import selectn from 'selectn';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withFormState from 'modules/forms/components/withFormState';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withTransaction from 'modules/transactions/components/withTransaction';

import mapCandidacyIdToCandidacy from './mapCandidacyIdToCandidacy';
import updateCandidacyAction from '../actions/updateCandidacy';

/**
 * Provides props for managing a form that submits a candidacy update.
 *
 * Provides the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying `RootField` fieldstate
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default RootField =>
  compose(
    setDisplayName('withCandidacyUpdateForm'),
    setPropTypes({
      /**
       * The ID of the candidacy being updated.
       */
      candidacyId: PropTypes.number,

      /**
       * An optional formstate to initial the form with (handled by withFormState)
       */
      initialFormState: formStatePropType,

      /**
       * Called when the form has been successfully saved.
       */
      onSaved: PropTypes.func.isRequired,
    }),

    // Create a temporary prop (`withCandidacyUpdateFormProps`) that holds any incoming props
    // that aren't required by us. This ensures we don't overwrite/delete/clobber any props that may
    // need to be passed through.
    withProps(
      ({
        candidacy: _candidacy,
        candidacyId: _candidacyId,
        initialFormState: _initialFormState,
        onSaved: _onSaved,
        ...withCandidacyUpdateFormProps
      }) => ({
        withCandidacyUpdateFormProps: withCandidacyUpdateFormProps,
      }),
    ),

    mapCandidacyIdToCandidacy,

    /**
     * Create a FormState, initializing it with an empty comment field.
     * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
     */
    withFormState(props => RootField.createFieldState('candidacy', props)),

    /**
     * Watch for any save transaction to complete
     */
    withHandlers({
      /**
       * This gets called by `withTransaction`, below, any time our transaction started
       * with `startTransaction` is called.
       */
      onTransactionComplete: ({
        formState,
        onFormStateChange,
        onResetFormState,
        onSaved,
      }) => transaction => {
        const error = selectn('payload.error', transaction);
        onFormStateChange(formState.endSubmit(error));

        if (!error) {
          onResetFormState();

          // If all was good, call onSaved with the candidacy ID
          onSaved(selectn('payload.result.candidacy', transaction));
        }
      },
    }),

    /**
     * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
     * when the transaction used with `startTransaction` is finished.
     */
    withTransaction,

    /**
     * Include connected version of `updateCandidacy` that is needed to submit.
     */
    connect(null, {
      updateCandidacy: updateCandidacyAction,
    }),

    /**
     * Add a callback to handle submitting the action form.
     */
    withHandlers({
      // Called when the form should be submitted.
      onSubmit: ({
        candidacyId,
        formState,
        onFormStateChange,
        startTransaction,
        updateCandidacy,
      }) => e => {
        const transactionId = startTransaction();

        updateCandidacy({
          id: candidacyId,
          candidacy: formState.getFieldValue(),
          transactionId: transactionId,
        });

        onFormStateChange(formState.startSubmit(transactionId));

        // Prevent default browser behavior, which could cause the browser to attempt
        // to submit a form on it's own.
        return preventDefaultHandler(e);
      },
    }),

    // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
    mapProps(
      ({
        candidacy,
        formState,
        onCancel,
        onFieldStateChange,
        onFormStateChange,
        onResetFormState,
        onSubmit,
        withCandidacyUpdateFormProps,
      }) => ({
        ...withCandidacyUpdateFormProps,
        candidacy: candidacy,
        formState: formState,
        onFieldStateChange: onFieldStateChange,
        onFormStateChange: onFormStateChange,
        onResetFormState: onResetFormState,
        onSubmit: onSubmit,
        onCancel: onCancel,
      }),
    ),
  );

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TableView from 'modules/core/componentsLegacy/Table/TableView';
import Card from '@thrivetrm/ui/components/Card';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import subsidiaryCompaniesTableColumns from './subsidiaryCompaniesTableColumns';
import {
  loadSubsidiaryCompanies,
  setResultsPerPage,
  setCurrentPage,
  setSortFields,
} from './subsidiaryCompaniesSlice';

const SubsidiaryCompaniesTableView = ({ companyId }) => {
  const dispatch = useDispatch();
  const {
    currentPage,
    isLoadingSubsidiaryCompanies,
    loadSubsidiaryCompaniesError,
    removeSubsidiaryCompanyError,
    resultsPerPage,
    sortDirection,
    sortField,
    subsidiaryCompanies,
    totalPages,
    totalResults,
  } = useSelector(state => state.subsidiaryCompanies);

  useEffect(() => {
    dispatch(loadSubsidiaryCompanies(companyId));
  }, [
    companyId,
    dispatch,
    currentPage,
    resultsPerPage,
    sortDirection,
    sortField,
  ]);

  const handlePageChange = page => {
    dispatch(setCurrentPage(page));
  };

  const handleResultsPerPageChange = newResultsPerPage => {
    dispatch(setResultsPerPage(newResultsPerPage));
  };

  const handleSortChange = (newSortField, shouldSortAscending) => {
    dispatch(
      setSortFields({
        sortDirection: shouldSortAscending ? 'asc' : 'desc',
        sortField: newSortField,
      }),
    );
  };

  const subsidiaryCompanyError =
    loadSubsidiaryCompaniesError || removeSubsidiaryCompanyError;

  return (
    <LoadingContainer isLoading={isLoadingSubsidiaryCompanies} minHeight={212}>
      {subsidiaryCompanies?.length ? (
        <TableView
          columns={subsidiaryCompaniesTableColumns}
          currentPage={currentPage}
          data={subsidiaryCompanies}
          getDatumKey={datum => datum.id}
          limit={resultsPerPage}
          onLimitChange={handleResultsPerPageChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          parentCompanyId={companyId}
          sortAscending={sortDirection === 'asc'}
          sortBy={sortField}
          totalCount={totalResults}
          totalPages={totalPages}
        />
      ) : null}
      {!subsidiaryCompanies?.length && !isLoadingSubsidiaryCompanies ? (
        <div className='text-center u-fontSize-large u-marginTop-32 u-marginBottom-64'>
          No subsidiary companies found
        </div>
      ) : null}
      {subsidiaryCompanyError && !isLoadingSubsidiaryCompanies ? (
        <Card type='error'>{subsidiaryCompanyError}</Card>
      ) : null}
    </LoadingContainer>
  );
};

SubsidiaryCompaniesTableView.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default SubsidiaryCompaniesTableView;

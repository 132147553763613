const generateContactFilterSummary = reportModal => {
  reportModal.text('.js-called-to-the-bar-filter', 'Called to the Bar');
  reportModal.array('.js-casl-status-filter', 'casl_status', 'CASL Status');
  reportModal.array('.js-citizenship-filter', 'citizenship', 'Citizenship');
  reportModal.array('.js-cities-filter', 'Cities');
  reportModal.array('.js-company-revenue-max-filter', 'Revenue Max');
  reportModal.array('.js-company-revenue-min-filter', 'Revenue Min');
  reportModal.array('.js-company-stages-filter', 'Company Stages');
  reportModal.array('.js-contact-source-filter', 'Source');
  reportModal.array('.js-countries-filter', 'countries');
  reportModal.array('.js-created-by-filter', 'Created By');
  reportModal.array(
    '.js-created-by-office-location-filter',
    'Created By Office Location',
  );
  reportModal.array('.js-disability-status-filter', 'Disability Status');
  reportModal.array('.js-gdpr-status-filter', 'gdpr_status', 'GDPR Status');
  reportModal.array('.js-gender-filter', 'Gender');
  reportModal.array('.js-has-document-type-filter', 'Document Types');
  reportModal.array(
    '.js-has-missing-document-type-filter',
    'Missing Document Type',
  );
  reportModal.array('.js-industries-filter', 'industries');
  reportModal.array('.js-industry-preferences-filter', 'Industry Preferences');
  reportModal.array('.js-product-areas-filter', 'Product Areas');
  reportModal.array('.js-interaction-level-filter', 'Interaction Levels');
  reportModal.array('.js-languages-filter', 'languages');
  reportModal.array('.js-level-filter', 'level');
  reportModal.array(
    '.js-minimum-stage-reached-filter',
    'minimum_stage_reached',
    'Minimum Stage Reached',
  );
  reportModal.array(
    '.js-contact-networks-filter',
    'contact_networks',
    'Contact Networks',
  );
  reportModal.array(
    '.js-contact-relationship_manager-filter',
    'relationship_manager',
    'Relationship Manager',
  );
  reportModal.array(
    '.js-company-networks-filter',
    'company_networks',
    'Company Networks',
  );
  reportModal.array(
    '.js-has-off-limits-status-filter',
    'off_limits_status',
    'Is Off Limits',
  );
  reportModal.array(
    '.js-connection-strengths-filter',
    'connection_strengths',
    'Connection Strengths',
  );
  reportModal.array(
    '.js-connection-types-filter',
    'connection_types',
    'Connection Types',
  );
  reportModal.array(
    '.js-connected-to-user-filter',
    'connected_to_users',
    'Connected To Users',
  );
  reportModal.array(
    '.js-connected-to-contact-filter',
    'connected_to_contacts',
    'Connected To Contacts',
  );
  reportModal.array(
    '.js-connected-to-company-contact-filter',
    'connected_to_company_contact',
    'Connected To Companies',
  );
  reportModal.array(
    '.js-off-limits-reasons-filter',
    'off_limits_reasons',
    'Off Limits Reasons',
  );
  reportModal.array('.js-portfolio-companies-filter', 'Portfolio Companies');
  reportModal.array('.js-position-functions-filter', 'Position Functions');
  reportModal.array(
    '.js-primary-position-level-filter',
    'Primary Position Level(s)',
  );
  reportModal.array(
    '.js-rejection-reasons-filter',
    'rejection_reasons',
    'Rejection Reasons',
  );
  reportModal.array('.js-race-ethnicity-filter', 'Race Ethnicities');
  reportModal.array('.js-affiliations-filter', 'affiliation', 'Affiliations');
  reportModal.array('.js-relationship-manager-filter', 'Relationship Manager');
  reportModal.array('.js-relocation-preferences-filter', 'Locations');
  reportModal.array('.js-roles-filter', 'roles');
  reportModal.array(
    '.js-search-networks-filter',
    'search_networks',
    'Search Networks',
  );
  reportModal.array('.js-pipelines-filter', 'pipelines');
  reportModal.array('.js-searches-filter', 'searches');
  reportModal.array('.js-sector-preferences-filter', 'Sector Preferences');
  reportModal.array('.js-sectors-filter', 'sectors');
  reportModal.array(
    '.js-seeking-job-reasons-filter',
    'seeking_job_reason',
    'Reason',
  );
  reportModal.array(
    '.js-seeking-job-statuses-filter',
    'seeking_job_status',
    'Status',
  );
  reportModal.array('.js-sexual-orientation-filter', 'Sexual Orientations');
  reportModal.array('.js-skills-filter', 'skills');
  reportModal.array('.js-linkedin-skills-filter', 'Linkedin Skills');
  reportModal.array('.js-us-regions-filter', 'us_region', 'US Region(s)');
  if ($("input[name='filters[has_assessment]']").is(':checked')) {
    reportModal.appendFilter('Yes', 'Has Assessment');
  }
  if ($("input[name='filters[has_candidacy_assessment]']").is(':checked')) {
    reportModal.appendFilter('Yes', 'Has Assessment');
  }
  reportModal.array('.js-assessment-type-filter', 'Assessment Types');
  reportModal.appendRangeFilter(
    '.js-assessed-start-date',
    '.js-assessed-end-date',
    'Assessed Between',
  );
  reportModal.array('.js-candidacy-assessment-type-filter', 'Assessment Types');
  reportModal.appendRangeFilter(
    '.js-candidacy-assessed-start-date',
    '.js-candidacy-assessed-end-date',
    'Assessed Between',
  );
  reportModal.array('.js-veteran-status-filter', 'Veteran Status');
  reportModal.array(
    '.js-state-province-filter',
    'state_or_province',
    'State(s)/Province(s)',
  );
  reportModal.checked(
    "input[name*='filters[relocate']:checked",
    'relocation_preferences',
    'Relocation Preferences',
  );
  reportModal.checked(
    "input[name='filters[board_member]']:checked, input[name='filters[investor]']:checked",
    'Interests',
  );
  reportModal.checked(
    "input[name='filters[portfolio_company]']:checked",
    'Company Type',
  );
  reportModal.checked(
    "input[name='filters[us_visa_required]']:checked, input[name='filters[us_visa_not_required]']:checked",
    'US Visa Required',
  );
  reportModal.text('#filters_thrive_ids', 'Thrive IDs');
  reportModal.text('#filters_ghire_ids', 'Ghire IDs');
  reportModal.text('#filters_name', 'Name');
  reportModal.text('#filters_position', 'Job Title');
  reportModal.text('input#filters_company', 'company');
  reportModal.text('input#filters_keyword', 'Keyword');
  reportModal.text('#filter_keyword_advanced', 'Keyword');
  reportModal.text('input#filters_phone_normalized', 'Phone');
  reportModal.text('input#filters_position_name', 'position_name', 'Position');
  reportModal.appendRangeFilter(
    '.js-average-rating-min',
    '.js-average-rating-max',
    'Overall Rating',
  );
  reportModal.appendRangeFilter(
    '.js-birth-age-min',
    '.js-birth-age-max',
    'Age Between',
  );
  reportModal.appendRangeFilter(
    '.js-preferred-total-compensation-min',
    '.js-preferred-total-compensation-max',
    'Desired Total Compensation',
  );
  reportModal.appendRangeFilter(
    '.js-preferred-base-min',
    '.js-preferred-base-max',
    'Desired Base Range',
  );
  reportModal.appendRangeFilter(
    '.js-preferred-bonus-min',
    '.js-preferred-bonus-max',
    'Desired Bonus Range',
  );
  reportModal.appendRangeFilter(
    '.js-preferred-equity-min',
    '.js-preferred-equity-max',
    'Desired Equity Range',
  );
  reportModal.appendRangeFilter(
    '.js-base-min',
    '.js-base-max',
    'Current Base Range',
  );
  reportModal.appendRangeFilter(
    '.js-bonus-min',
    '.js-bonus-max',
    'Current Bonus Range',
  );
  reportModal.appendRangeFilter(
    '.js-equity-min',
    '.js-equity-max',
    'Current Equity Range',
  );
  reportModal.appendRangeFilter(
    '#filters_grad_date_range_start',
    '#filters_grad_date_range_end',
    'Graduation Year',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-created-start-date',
    '.js-created-end-date',
    'Created',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-primary-position-start-date',
    '.js-primary-position-end-date',
    'Primary Position',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-updated-start-date',
    '.js-updated-end-date',
    'Updated',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-document-expiration-start-date',
    '.js-document-expiration-end-date',
    'Expired',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-document-uploaded-start-date',
    '.js-document-uploaded-end-date',
    'Uploaded',
  );
};

const generateCompanyFilterSummary = reportModal => {
  reportModal.text('.board-of-directors-filter-elastic', 'Board of Directors');
  reportModal.text('.company-filter-elastic', 'Name');
  reportModal.text('.company-keyword-filter-elastic', 'Keyword');
  reportModal.text('.company-phone-filter-elastic', 'Phone');
  reportModal.text('#filters_thrive_ids', 'Thrive IDs');
  reportModal.array('.js-cities-filter', 'Cities');
  reportModal.array(
    '.js-company-networks-filter',
    'company_networks',
    'Company Networks',
  );
  reportModal.array(
    '.js-company-search-networks-filter',
    'search_networks',
    'Search Networks',
  );
  reportModal.array('.js-filter-company-types', 'Company Types');
  reportModal.array('.js-filter-country-codes', 'Countries');
  reportModal.array('.js-filter-funding-amounts', 'Total Funding Amounts');
  reportModal.array('.js-filter-funding-rounds', 'Funding Rounds');
  reportModal.array('.js-filter-industries', 'Industries');
  reportModal.array('.js-filter-parent-companies', 'Parent Companies');
  reportModal.array('.js-filter-partners', 'Partners');
  reportModal.array('.js-filter-priority-list', 'Company Priorities');
  reportModal.array('.js-filter-revenue-growth', 'Revenue Growths');
  reportModal.array('.js-filter-searches', 'Searches');
  reportModal.array('.js-filter-sectors', 'Sectors');
  reportModal.array('.js-filter-state-province', 'State/Province');
  reportModal.array('.js-filter-statuses', 'Company Statuses');
  reportModal.array('.js-filter-target-functions', 'Target Functions');
  reportModal.array('.js-filter-target-markets', 'Target Markets');
  reportModal.array('.js-filter-us-regions', 'US Regions');
  reportModal.array('.js-has-document-type-filter', 'Document Types');
  reportModal.array(
    '.js-has-missing-document-type-filter',
    'Missing Document Type',
  );
  reportModal.array(
    '.js-has-off-limits-status-filter',
    'off_limits_status',
    'Is Off Limits',
  );
  reportModal.array(
    '.js-off-limits-reasons-filter',
    'off_limits_reasons',
    'Off Limits Reasons',
  );
  reportModal.appendRangeFilter(
    '.js-market-cap-amount-min',
    '.js-market-cap-amount-max',
    'Market Cap',
  );
  if ($('#filters_control_deal').is(':checked')) {
    reportModal.appendFilter('Yes', 'Control Deal');
  }
  if ($('#filters_not_control_deal').is(':checked')) {
    reportModal.appendFilter('No', 'Control Deal');
  }
  reportModal.appendRangeFilter(
    '.js-latest-revenue-min',
    '.js-latest-revenue-max',
    'Latest Revenue',
  );
  reportModal.appendRangeFilter(
    '.js-employee-count-min',
    '.js-employee-count-max',
    'Latest Employee Count',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-created-start-date',
    '.js-created-end-date',
    'Created',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-updated-start-date',
    '.js-updated-end-date',
    'Updated',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-document-expiration-start-date',
    '.js-document-expiration-end-date',
    'Expired',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-document-uploaded-start-date',
    '.js-document-uploaded-end-date',
    'Uploaded',
  );
  const dataEnriched = $("input[name='filters[data_enriched]']").is(':checked');
  const dataNotEnriched = $("input[name='filters[data_not_enriched]']").is(
    ':checked',
  );
  if (dataEnriched !== dataNotEnriched) {
    reportModal.appendFilter(
      dataEnriched ? 'Yes' : 'No',
      'Data Enriched Companies',
    );
  }
};

const generateSearchFilterSummary = reportModal => {
  reportModal.searchDateRange('.js-use-start-or-end-date:checked', {
    start: [
      '#filters_es_status_change_date_gt',
      '#filters_es_status_change_date_lt',
    ],
    end: [
      '#filters_es_status_change_date_gt',
      '#filters_es_status_change_date_lt',
    ],
    active: [
      '#filters_es_status_change_date_gt',
      '#filters_es_status_change_date_lt',
    ],
  });

  if ($('.js-critical-filter').is(':checked')) {
    reportModal.appendFilter('Yes', 'critical');
  }
  reportModal.text('input#filter_keyword_advanced', 'Keyword');
  reportModal.array(
    '.js-assessment-criteria-filter',
    'assessment_criteria',
    'Assessment Criteria',
  );
  reportModal.array('.js-asset-class-filter', 'asset_class', 'Asset Class');
  reportModal.array(
    '.js-targeted-search-filter',
    'targeted_search',
    'Targeted Search',
  );
  reportModal.array('.js-cost-centers-filter', 'cost_center', 'Cost Center');
  reportModal.array('.js-external-id-filter', 'external_id', 'Job Code ID');
  reportModal.array(
    '.js-search-reasons-filter',
    'search_reason',
    'Search Reason',
  );
  reportModal.array('.js-search-states-filter', 'search_state', 'Search Stage');
  reportModal.array(
    '.js-search-priority-filter',
    'search_priority',
    'Search Priority',
  );
  reportModal.array('.js-cities-filter', 'Cities');
  reportModal.array('.js-purchase-order-number-filter', 'PO#');
  reportModal.array(
    '.js-company-contact-filter',
    'company_contact',
    'Hiring Manager',
  );
  reportModal.array('.js-countries-filter', 'country');
  reportModal.array(
    '.js-department-head-filter',
    'department_head',
    'Department Head',
  );
  reportModal.array(
    '.js-has-missing-document-type-filter',
    'document_labels',
    'Missing Document Type',
  );
  reportModal.array(
    '.js-document-label-filter',
    'document_labels',
    'Has Document Type',
  );
  reportModal.array(
    '.js-investor-source-of-business-filter',
    'primary_investor',
    'Investor Source of Business',
  );
  reportModal.array('.js-job-function-filter', 'job_function', 'Job Function');
  reportModal.array(
    '.js-search-networks-filter',
    'search_networks',
    'Search Networks',
  );
  reportModal.array(
    '.js-office-location-filter',
    'office_locations',
    'Office Location',
  );
  reportModal.array(
    '.js-search-placement-type-filter',
    'placement_type_name',
    'Placement Type',
  );
  reportModal.array('.js-organization-filter', 'organization', 'Organization');
  reportModal.array(
    '.js-other-investors-filter',
    'other_investor',
    'Other Investor',
  );

  const contractOptions = [];
  if ($('.js-redo-search').is(':checked')) {
    contractOptions.push('Redo Search');
  }
  if ($('.js-additional-shadow-hire').is(':checked')) {
    contractOptions.push('Additional (Shadow) Hires');
  }
  if (contractOptions.length) {
    reportModal.appendFilter(contractOptions, 'Contract');
  }

  reportModal.array('.js-product-area-filter', 'product_area', 'Product Area');
  reportModal.array(
    '.js-search-client-company-revenue-filter',
    'client_company_revenue',
    'Company Revenue',
  );
  reportModal.array(
    '.js-search-company-industries-filter',
    'industry_category',
    'Company Industry',
  );
  reportModal.array('.js-company-sectors-filter', 'sector', 'Company Sector');
  reportModal.array(
    '.js-search-execution-verticals-filter',
    'execution_vertical_id',
    'Execution Practice',
  );
  reportModal.array('.js-search-level-filter', 'level');
  reportModal.array(
    '.js-search-origination-verticals-filter',
    'origination_vertical_id',
    'Origination Practice',
  );
  reportModal.array(
    '.js-search-partners-filter',
    `${window.thv.tenant.tenant_name} Partners`,
  );
  reportModal.array(
    '.js-search-regions-filter',
    'search_region',
    'Search Region',
  );
  reportModal.array(
    '.js-search-industries-filter',
    'industry_category',
    'Search Industry',
  );
  reportModal.array('.js-search-sectors-filter', 'sector', 'Search Sector');
  reportModal.array(
    '.js-search-state-province-filter',
    'state',
    'State/Province',
  );
  reportModal.array('.js-search-status-filter', 'status');
  reportModal.array(
    '.js-search-target-functions-filter',
    'target_functions',
    'Target Functions',
  );
  reportModal.array(
    '.js-external-search-firm-milestone-filter',
    'external_search_firm_milestones',
    'External Search Firm Milestones',
  );
  reportModal.array(
    '.js-search-target-markets-filter',
    'target_markets',
    'Target Markets',
  );
  reportModal.array('.js-search-team-filter', 'search_team', 'Search Team');
  reportModal.array(
    '.js-search-team-roles-filter',
    'search_team_roles',
    'Search Team Roles',
  );
  reportModal.array(
    '.js-source-of-business-filter',
    'source_of_business',
    'Source of Business',
  );
  reportModal.array('.js-us-regions-filter', 'us_region', 'US Region(s)');
  reportModal.text('.js-lead-internal-team-member-filter', 'lead');
  reportModal.text(
    '.js-primary-recruiter-filter',
    'primary_recruiter',
    'External Primary Recruiter',
  );
  reportModal.text('.js-search-firm-filter', 'search_firm', 'Search Firm');
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-created-start-date',
    '.js-created-end-date',
    'Created',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-updated-start-date',
    '.js-updated-end-date',
    'Updated',
  );
  reportModal.text('#search-company', 'company');
  reportModal.text('#search-job-title', 'job title');
  reportModal.text('#search-search-number', 'search number');
  reportModal.text('#filters_thrive_ids', 'Thrive IDs');
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-document-expiration-start-date',
    '.js-document-expiration-end-date',
    'Expired',
  );
  reportModal.appendOpenEndedDateRangeFilter(
    '.js-document-uploaded-start-date',
    '.js-document-uploaded-end-date',
    'Uploaded',
  );
};

export {
  generateCompanyFilterSummary,
  generateContactFilterSummary,
  generateSearchFilterSummary,
};

import { USER_STATE_KEY } from '../constants';

/**
 * Returns a value indicating if the current user is a client user.
 * @param {*} state
 * @returns {Boolean} true if the current user is in the "client" role; otherwise, returns false.
 */
const isClientUser = state => state[USER_STATE_KEY].get('client', false);

export default isClientUser;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import DirectReportsListItem from './DirectReportsListItem';
import mapContactIdToDirectReports from './mapContactIdToDirectReports';
import withContactDirectReportsFetched from './withContactDirectReportsFetched';
import withSortedContactIds from '../withSortedContactIds';

/**
 * Renders a list of names of a collection of contacts.
 */
const DirectReportsList = ({ contactIds }) => (
  <ul className='DirectReportsList list-group'>
    {contactIds &&
      contactIds
        .map(contactId => (
          <DirectReportsListItem contactId={contactId} key={contactId} />
        ))
        .toArray()}
  </ul>
);

DirectReportsList.propTypes = {
  contactIds: ImmutablePropTypes.listOf(PropTypes.number),
};

export default compose(
  setDisplayName('DirectReportsList(enhanced)'),
  setPropTypes({
    /**
     * The ID of the contact to list direct reports for.
     */
    contactId: PropTypes.number.isRequired,
  }),
  withContactDirectReportsFetched,
  mapContactIdToDirectReports,
  mapProps(({ directReports }) => ({
    contactIds: directReports && directReports.get('ids'),
  })),
  withSortedContactIds,
)(DirectReportsList);

import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import RadioSelectField from './RadioSelectField';

export default compose(
  setDisplayName('BooleanRadioSelectField'),

  // Use the RadioSelectField.createFieldState but ensure that the value is a boolean.
  setStatic('createFieldState', (name, value, ...rest) =>
    RadioSelectField.createFieldState(name, Boolean(value), ...rest),
  ),

  setPropTypes({
    /**
     * The label to display for the false value
     */
    falseLabel: PropTypes.string.isRequired,

    /**
     * The label to display for the true value.
     */
    trueLabel: PropTypes.string.isRequired,

    /**
     * Which option value should be shown first.
     */
    firstOption: PropTypes.bool.isRequired,
  }),
  defaultProps({
    firstOption: true,
    falseLabel: 'False',
    trueLabel: 'True',
  }),

  mapProps(({ falseLabel, firstOption, trueLabel, ...props }) => {
    const options = [
      {
        label: trueLabel,
        value: true,
      },
      {
        label: falseLabel,
        value: false,
      },
    ];

    return {
      ...props,
      options: firstOption ? options : options.reverse(),
    };
  }),
)(RadioSelectField);

import { DELETE } from 'modules/api/methods';
import {
  CANDIDACY_TAG_REMOVE_START,
  CANDIDACY_TAG_REMOVE_SUCCESS,
  CANDIDACY_TAG_REMOVE_FAILURE,
} from './actionTypes';
import createCandidacyTagAction from './createCandidacyTagAction';

/**
 * An action creator for removing a tag from a candidacy record.
 * @param {Object} options
 * @param {String} options.candidacyId The ID of the candidacy to remove the tag from
 * @param {String} options.tagId The ID of the tag to remove from the candidacy
 */
export default createCandidacyTagAction({
  startActionType: CANDIDACY_TAG_REMOVE_START,
  successActionType: CANDIDACY_TAG_REMOVE_SUCCESS,
  failureActionType: CANDIDACY_TAG_REMOVE_FAILURE,
  method: DELETE,
});

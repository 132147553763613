import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Pagination from '@thrivetrm/ui/components/Pagination';
import SegmentedControl from '@thrivetrm/ui/components/SegmentedControl';
import Table from 'modules/core/components/Table';
import { useGetIndexTableRecordsQuery } from 'services/apiV1/indexTableRecords';
import isExecutiveSearchTenant from 'modules/tenant/selectors/isExecutiveSearchTenant';

const RESULTS_PER_PAGE_OPTIONS = [5, 25, 100];

const SearchTable = ({ companyId }) => {
  const isExecutiveSearchTenantType = useSelector(isExecutiveSearchTenant);
  const [sort, setSort] = useState({
    direction: 'asc',
    field: 'primary_identifier',
  });
  const [resultsPerPage, setResultsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const defaultColumns = [
    'location',
    'lead_internal_team_member_name',
    'search_firm_name',
    'status',
    'current_start_date',
    'current_end_date',
  ];
  const executiveSearchColumns = [
    'location',
    'lead_internal_team_member_name',
    'source_of_business_contact_last_name',
    'status',
    'current_start_date',
    'active_start_date',
    'current_end_date',
  ];

  const filters = {
    company_id: companyId,
  };

  const {
    data: searchesData,
    error: _searchesLoadError,
    isFetching: isFetchingSearches,
    isLoading: isLoadingSearches,
  } = useGetIndexTableRecordsQuery({
    columns: isExecutiveSearchTenantType
      ? executiveSearchColumns
      : defaultColumns,
    filters: filters,
    page: currentPage,
    recordType: 'searches',
    resultsPerPage: resultsPerPage,
    sort: sort,
  });

  const handleSortDirectionChange = sortField => {
    // Default to ascending sort order
    let newSortDirection = 'asc';
    // If sorting on the same field, "flip" the sort direction
    if (sortField === sort.field) {
      newSortDirection = sort.direction === 'asc' ? 'desc' : 'asc';
    }

    setSort({
      direction: newSortDirection,
      field: sortField,
    });
  };

  const handleResultsPerPageChange = newResultsPerPage => {
    setCurrentPage(1);
    setResultsPerPage(newResultsPerPage);
  };

  return (
    <LoadingContainer
      className='SearchTable'
      isLoading={isFetchingSearches}
      minHeight='120px'
    >
      {searchesData?.rows?.length && !isLoadingSearches ? (
        <>
          <Table
            headers={searchesData.columns}
            isLoading={isLoadingSearches}
            onSortChange={handleSortDirectionChange}
            rows={searchesData.rows}
            sortDirection={sort.direction}
            sortKey={sort.field}
          />
          <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween u-marginTop-24'>
            <Pagination
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              resultsPerPage={resultsPerPage}
              totalPages={searchesData.totalPages}
              totalResults={searchesData.totalResults}
            />
            <SegmentedControl
              onChange={handleResultsPerPageChange}
              segments={RESULTS_PER_PAGE_OPTIONS}
              selectedSegmentLabel={resultsPerPage}
              size='small'
            />
          </div>
        </>
      ) : null}
      {searchesData?.rows?.length === 0 && !isLoadingSearches ? (
        <h3 className='u-marginVertical-32 u-textAlign-c u-textColor-gray50'>
          No Searches Found
        </h3>
      ) : null}
    </LoadingContainer>
  );
};

SearchTable.propTypes = {
  companyId: PropTypes.number,
};

export default SearchTable;

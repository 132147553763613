import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useDispatch, useSelector } from 'react-redux';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ReferenceForm from 'modules/references/components/ReferenceForm';
import ReferenceView from 'modules/references/components/ReferenceView';
import ReferenceEditFormV2 from 'modules/references/components/ReferenceEditFormV2';
import { convertToCamelCase } from 'modules/core/jsonUtils';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { deleteReference } from 'modules/references/actions';
import ReferenceViewV2 from 'modules/references/components/ReferenceViewV2';
import selectedContact from 'selectors/contact/selectedContact';
import { fetchNotifications } from 'actions/contacts';
import { fetchContact } from 'modules/contacts/actions';
import Notification from './Notification';

/**
 * Renders a notification item of type `reference`
 */
const NotificationItemTypeReference = ({
  allowDelete,
  allowEdit,
  excludeSearchField,
  notification,
  showDetails,
  ...props
}) => {
  const dispatch = useDispatch();
  const [isEditing, showEditing, closeEditing] = useToggle(false);
  const hasReferenceConnectionV2 = useFeatureCheck(
    'development.references_connection_v2',
  );
  const contactNotifications = useSelector(state =>
    selectedContact(state),
  )?.get('notifications');

  const handleDelete = () => {
    dispatch(
      deleteReference({
        id: notification.getIn(['data', 'resource', 'data', 'id']),

        // This ultimately shouldn't be needed, but for now it's required
        // in order for the action to make it's way down to the appropriate
        // contact reducer. Once contact records are normalized we should
        // be able to get rid of this.
        contactId: notification.getIn([
          'data',
          'resource',
          'data',
          'contact_id',
        ]),
      }),
    );
  };

  const reference = notification.getIn(['data', 'resource']);
  const referenceId = reference.getIn(['data', 'id']);
  const contactId = reference.getIn(['data', 'contact_id']);

  const handleReferenceSuccess = () => {
    closeEditing();

    if (contactNotifications) {
      // invalidates notifications on contact activity
      dispatch(
        fetchNotifications({
          limit: contactNotifications.get('data').size,
          contactId: contactId,
          filters: contactNotifications.getIn(['meta', 'filters']).toJS(),
        }),
      );
    }
    if (contactId) {
      dispatch(fetchContact({ id: contactId }));
    }
  };

  return (
    <Notification
      canDelete={
        allowDelete && reference.getIn(['data', 'permissions', 'delete'])
      }
      canEdit={allowEdit && reference.getIn(['data', 'permissions', 'edit'])}
      notification={notification}
      onDelete={handleDelete}
      onEdit={showEditing}
      {...props}
    >
      {showDetails && !isEditing && !hasReferenceConnectionV2 ? (
        <ReferenceView
          contactId={contactId}
          noDate={true}
          referenceId={referenceId}
        />
      ) : null}
      {showDetails && !isEditing && hasReferenceConnectionV2 ? (
        <ReferenceViewV2
          canDelete={false}
          canEdit={false}
          reference={convertToCamelCase(reference?.toJS())?.data}
          shouldRenderUpdatedAt={false}
        />
      ) : null}
      {isEditing && hasReferenceConnectionV2 ? (
        <ReferenceEditFormV2
          formHeader='Update Reference'
          layout='wide'
          onCancel={closeEditing}
          onSuccess={handleReferenceSuccess}
          referenceId={referenceId}
          submitButtonLabel='Update Reference'
        />
      ) : null}
      {isEditing && !hasReferenceConnectionV2 ? (
        <ReferenceForm
          asRichTextForm={true}
          contactId={contactId}
          draftStoragePath={{
            contact: contactId,
            reference: referenceId,
          }}
          errorDisplay='tooltip'
          excludeSearchField={excludeSearchField}
          fieldLayout='wide'
          formActionProps={{
            submitButtonIcon: 'fa-file-text-o',
            submitLabel: 'Update Reference',
            fillWidth: false,
          }}
          onCancel={closeEditing}
          onSaved={closeEditing}
          referenceId={referenceId}
        />
      ) : null}
    </Notification>
  );
};

NotificationItemTypeReference.propTypes = {
  /**
   * True to allow the reference item to be deleted if the user is permitted.
   */
  allowDelete: PropTypes.bool,

  /**
   * True to allow the reference item to be edited inline if the user is permitted.
   */
  allowEdit: PropTypes.bool,

  excludeSearchField: PropTypes.bool,

  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          company: PropTypes.string,
          description: PropTypes.string,
          email: PropTypes.string,
          name: PropTypes.string,
          phone: PropTypes.string,
          relationship: PropTypes.string.isRequired,
          referee: PropTypes.oneOfType([
            ImmutablePropTypes.mapContains({
              full_name: PropTypes.string,
              id: PropTypes.number,
            }),
            PropTypes.number,
          ]),
        }),
      }),
      type: PropTypes.oneOf(['reference']),
    }),
  }).isRequired,

  /**
   * When true, the note details will be displayed.
   * @type {[type]}
   */
  showDetails: PropTypes.bool,
};

export default NotificationItemTypeReference;

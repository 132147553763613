import { fromJS } from 'immutable';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

const handleSubmit = (formState, props) => {
  const {
    contactId,
    lbiNoticeSent,
    outreach,
    outreachActions: { createOutreach, updateOutreach },
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (outreach || fromJS({ contact_id: contactId })).merge(
    fieldValue,
  );

  const action = values.get('id') ? updateOutreach : createOutreach;
  action({
    outreach: values.delete('email').toJS(),
    email: values.get('email') ? values.get('email').toJS() : null,
    lbiNoticeSent: lbiNoticeSent,
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export default handleSubmit;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { branch, compose, setDisplayName, setStatic } from 'recompose';
import { Creatable } from 'react-select-legacy';
import KeyCode from 'keycode-js';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import * as validators from 'modules/forms/validators';
import { emailAddresses } from 'modules/forms/validators/emailAddress';

/**
 * A field for editing a value using a standard `<select>` element.
 *
 * Works the same as a regular React `<select>`, in that it should
 * be given `children` components that are `<option>` elements.
 */
class EmailField extends Component {
  /**
   * Creates a new FieldState that can be passed to this field.
   * Defers to the default FieldState.create implementation.
   */
  static createFieldState = (name, value, validator, ...rest) =>
    FieldState.create(
      name,
      value ? [].concat(value) : [],
      validator
        ? validators.combineValidators(validator, emailAddresses)
        : emailAddresses,
      ...rest,
    );

  handleChange = value => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setValue(value.map(item => item.label)));
  };

  handleBlur = event => {
    const { fieldState, onChange } = this.props;

    // When the field blurs, if there is any text already entered, considered that an
    // email address and add it to our array of values.
    // Normally, react-select will just clear out any remaining text. Which is weird behaviour
    // when using this as an email address input.
    if (event.target.value) {
      onChange(
        fieldState.setValue(fieldState.getValue().concat(event.target.value)),
      );
    }
  };

  shouldKeyDownEventCreateNewOption = ({ keyCode }) =>
    [
      KeyCode.KEY_SPACE,
      KeyCode.KEY_ENTER,
      KeyCode.KEY_TAB,
      KeyCode.KEY_COMMA,
    ].indexOf(keyCode) >= 0;

  render() {
    const {
      fieldState,
      id,
      inputProps,
      // prevent onChange from being passed through to the input.
      // eslint-disable-next-line no-unused-vars
      onChange,
      wrapperClassName,
      ...otherProps
    } = this.props;

    return (
      <div className={classnames('react-select', wrapperClassName)}>
        <Creatable
          {...otherProps}
          inputProps={{
            ...inputProps,
            id: id,
          }}
          menuRenderer={() => null}
          multi={true}
          name={fieldState.getName()}
          noResultsText={false}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          options={[]}
          placeholder='Enter emails ...'
          promptTextCreator={label => label}
          shouldKeyDownEventCreateNewOption={
            this.shouldKeyDownEventCreateNewOption
          }
          value={fieldState
            .getValue()
            .map(email => ({ label: email, value: email }))}
        />
      </div>
    );
  }
}

EmailField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  id: PropTypes.string.isRequired,

  inputProps: PropTypes.shape({
    id: PropTypes.string,
  }),

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * An optional className for the wrapper div, so the email field can be styled.
   * Defaults to 'email-field' because most of the time we'll want default styling
   * where we hide the dropdown arrow.
   */
  wrapperClassName: PropTypes.string,
};

EmailField.defaultPropTypes = {
  wrapperClassName: 'email-field',
};

export default compose(
  setDisplayName('EmailField'),
  setStatic('createFieldState', EmailField.createFieldState),
  withFormGroup,

  // Generate a unique ID if one wasn't supplied.
  branch(({ id }) => !id, withComponentId('EmailField', 'id')),
)(EmailField);

const Clipboard = require('clipboard');
const formUserModal = require('../lib/form_user_modal').default;
const initSelect2 = require('../lib/select2');
const initSelectsInModal = require('../lib/modal_selects');

exports.replaceUserModal = formUserModal.replaceUserModal;

const showTooltip = function (el) {
  el.tooltip({ trigger: 'manual', title: 'Copied!' });
  el.tooltip('show');
  const hide = () => el.tooltip('hide');
  return setTimeout(hide, 2000);
};

const showTooltipSafari = function (el) {
  el.tooltip({ trigger: 'manual', title: 'Press ⌘+C to copy' });
  el.tooltip('show');
  const hide = () => el.tooltip('hide');
  return setTimeout(hide, 5000);
};

exports.handleCopyInviteLink = () => {
  const $modal = $('#invite-new-user');
  const clipboard = new Clipboard('.copy-link-btn');
  $modal.find('.copy-link-btn').on('click', e => e.preventDefault());
  return clipboard.on('success', () => $modal.find('.copied-message').fadeIn());
};

exports.initialize = () => {
  formUserModal.bindCopyLinkButton();
  formUserModal.handleOfficeLocation();

  new Clipboard('.copy-to-clipboard').on('success', event =>
    showTooltip($(event.trigger)),
  );

  new Clipboard('.copy-to-clipboard').on('error', event =>
    showTooltipSafari($(event.trigger)),
  );

  initSelect2('.user-account-section', [
    '.js-timezone-select',
    '.js-user-role-select',
  ]);
  initSelect2('.user-account-section', '.js-office-location', {
    placeholder: 'Select an office location',
  });
  initSelect2('.user-account-section', '.js-user-default-currency-select', {
    placeholder: 'Select a currency',
  });
  initSelect2('.new_user_filter', ['.js-user-role-select']);
  initSelectsInModal('#create-user-modal');

  const notificationCheckboxes = [
    $('.notification-preferences-assessment-email'),
    $('.notification-preferences-comment-email'),
    $('.notification-preferences-introductionrequest-email'),
    $('.notification-preferences-jobapplication-email'),
    $('.notification-preferences-searchrequest-email'),
    $('.notification-preferences-searchplacement-email'),
    $('.notification-preferences-searchpaymentmilestone-email'),
  ];

  const initNotificationPreferences = el => {
    return $(el).on('change', ({ target }) => {
      return $.post(
        '/api/notification_preferences',
        {
          notification_preference: {
            resource_type: target.dataset.resourceType,
            user_id: target.dataset.userId,
            email: target.checked,
          },
        },
        data => {
          const $notice = $('.notification-preferences .notice');
          $notice.text(data.notice || data.error);
          $notice.fadeIn();
          return setTimeout(() => $notice.fadeOut(), 2000);
        },
      );
    });
  };

  return Array.from(notificationCheckboxes).map(checkbox =>
    initNotificationPreferences(checkbox),
  );
};

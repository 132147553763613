import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import TableScrollContainer from '@thrivetrm/ui/components/TableScrollContainer';
import { SortDirections } from './tableConstants';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const Table = ({
  hasRowHighlight,
  headers,
  isCompact,
  isLoading,
  isPlain,
  onSortChange,
  rows,
  sortDirection,
  sortKey,
}) => {
  const tableClassName = classnames({
    'table--rowHighlight': hasRowHighlight,
    'table--isCompact': isCompact,
  });
  return (
    <LoadingContainer isLoading={isLoading} minHeight={500}>
      <TableScrollContainer isPlain={isPlain}>
        <table className={tableClassName}>
          <TableHeader
            headers={headers}
            isLoading={isLoading}
            onSortChange={onSortChange}
            sortDirection={sortDirection}
            sortKey={sortKey}
          />
          <tbody>
            {rows.map(({ attributes, id }) => (
              <TableRow
                attributes={attributes}
                id={id}
                isLoading={isLoading}
                key={id}
              />
            ))}
          </tbody>
        </table>
      </TableScrollContainer>
    </LoadingContainer>
  );
};

Table.defaultProps = {
  hasRowHighlight: true,
  isCompact: false,
  isLoading: false,
  isPlain: false,
  onSortChange: null,
  sortDirection: null,
  sortKey: null,
};

Table.propTypes = {
  hasRowHighlight: PropTypes.bool,
  // Ideally this is not disabled, fix is to define the header object shape
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCompact: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPlain: PropTypes.bool,
  onSortChange: PropTypes.func,
  // Ideally this is not disabled, fix is to define the row object shape
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortDirection: PropTypes.oneOf(Object.values(SortDirections)),
  sortKey: PropTypes.string,
};

export default Table;

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import SearchSidebarLink from 'modules/searches/components/SearchSidebarLink';

/**
 * A container for showing popover content from the sidebar.
 * Primarily provides a "close" button in the upper-right hand corner.
 */
const SearchPageSidebarPopover = ({
  children,
  className,
  searchId,
  searchPage,
}) => (
  <div
    className={classnames('SearchPageSidebarPopover', className)}
    data-testid='search page sidebar popover'
  >
    <SearchSidebarLink
      aria-label='Close'
      className='btn btn-link close'
      searchId={searchId}
      searchPage={searchPage}
    >
      <span aria-hidden='true'>&times;</span>
    </SearchSidebarLink>
    {children}
  </div>
);

SearchPageSidebarPopover.propTypes = {
  /**
   * The child nodes to render inside the popover
   */
  children: PropTypes.node,

  /**
   * An optional classname to apply to the outer component.
   */
  className: PropTypes.string,

  /**
   * The ID of the search being displayed.
   */
  searchId: PropTypes.number.isRequired,

  /**
   * The current search page selected and displayed in the content portion of the search page
   * ("candidates", "applicants", "target_companies", etc)
   */
  searchPage: PropTypes.string.isRequired,
};

export default SearchPageSidebarPopover;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName } from 'recompose';
import classnames from 'classnames';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import SortIndicator from '@thrivetrm/ui/components/SortIndicator';
import AssociatedContact from './AssociatedContact';
import TaskTitle from './TaskTitle';
import TaskDueDate from './TaskDueDate';
import withFilteredTaskIds from './withFilteredTaskIds';
import withSortedTaskIds from './withSortedTaskIds';
/**
 * The list of columns to display in the table. `key` is the field name -- pass in the callback
 * when that column is to be sorted on; `title` is the column's header title; `component` is
 * the component that is rendered for that column.
 */
const COLUMNS = [
  {
    key: 'title',
    title: 'Task',
    component: TaskTitle,
  },
  {
    key: 'associatedRecord',
    title: 'Associated Contact',
    component: AssociatedContact,
  },
  {
    key: 'dueDate',
    title: 'Due Date',
    component: TaskDueDate,
  },
];

/**
 * Renders a table listing of talent pools/contact records.
 */
class TaskTableView extends PureComponent {
  state = { hovered: {} };

  onHover = e => {
    const id = e.currentTarget.getAttribute('data-id');
    const hovered = {};
    if (id) {
      hovered[id] = true;
      this.setState({ hovered: hovered });
    }
  };

  resetHover = () => {
    this.setState({ hovered: {} });
  };

  handleHeaderClick = e => {
    const { onSortChange } = this.props;
    onSortChange(e.currentTarget.dataset.sortField);
  };

  handleEditClick = id => () => {
    const { onEdit } = this.props;
    onEdit(id);
  };

  handleDeleteClick = id => () => {
    const { onDelete } = this.props;
    onDelete(id);
  };

  render() {
    const {
      isFetching,
      pendingIds,
      sortAscending,
      sortBy,
      taskIds,
    } = this.props;
    const { hovered } = this.state;

    return (
      <div className='tasks-table'>
        {isFetching ? (
          <LoadingIndicator />
        ) : (
          <table className='table table-striped table--rowHighlight'>
            <thead>
              <tr>
                {COLUMNS.map(({ key, title }) => (
                  /* eslint-disable jsx-a11y/no-static-element-interactions */
                  <th
                    className={classnames(`tasks-table-col-${key}`, {
                      'th--isSortKey': sortBy === key,
                    })}
                    colSpan={key === 'dueDate' ? 2 : 1}
                    data-sort-field={key}
                    key={key}
                    onClick={this.handleHeaderClick}
                  >
                    <span>
                      {title}
                      <SortIndicator
                        isAscending={Boolean(sortAscending)}
                        isSorted={sortBy === key}
                      />
                    </span>
                  </th>
                  /* eslint-enable jsx-a11y/no-static-element-interactions */
                ))}
              </tr>
            </thead>
            <tbody>
              {taskIds.map(taskId => (
                <tr
                  className={classnames({
                    hovered: hovered[taskId],
                    pending: pendingIds.includes(taskId),
                  })}
                  data-id={taskId}
                  key={taskId}
                  onMouseEnter={this.onHover}
                  onMouseLeave={this.resetHover}
                >
                  {COLUMNS.map(({ component: ColumnComponent, key }) => (
                    <td className={`tasks-table-col-${key}`} key={key}>
                      <ColumnComponent {...this.props} taskId={taskId} />
                    </td>
                  ))}
                  <td className='task-edit-and-delete-btn'>
                    <button
                      className='tasks-edit btn btn-link'
                      onClick={this.handleEditClick(taskId)}
                      type='button'
                    >
                      <i className='fa fa-pencil' />
                    </button>
                    <ConfirmationPopoverButton
                      className='btn btn-link'
                      disabled={false}
                      iconClass='fa fa-trash'
                      key='delete'
                      onConfirm={this.handleDeleteClick(taskId)}
                      title='Are you sure you want to delete this task?'
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

TaskTableView.propTypes = {
  /**
   * True if the contact records are currently being fetched, in which case a loading indicator
   * is shown.
   */
  isFetching: PropTypes.bool.isRequired,

  onDelete: PropTypes.func.isRequired,

  onEdit: PropTypes.func.isRequired,

  onSortChange: PropTypes.func.isRequired,

  pendingIds: PropTypes.arrayOf(PropTypes.number),

  /**
   * Whether the currently sorted field is sorted in ascending order.
   */
  sortAscending: PropTypes.bool.isRequired,

  /**
   * The currently sorted field.
   */
  sortBy: PropTypes.string.isRequired,

  taskIds: ImmutablePropTypes.listOf(PropTypes.number),
};

export default compose(
  setDisplayName('TaskTableView(enhanced)'),
  withFilteredTaskIds,
  withSortedTaskIds,
)(TaskTableView);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import GenerateReportModal from 'modules/reports/components/GenerateReportModal';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';

import TalentPoolReportField from './TalentPoolReportField';
import withTalentPoolReportForm from './withTalentPoolReportForm';

export default compose(
  setDisplayName('TalentPoolReportFormModal'),
  setPropTypes({
    contactId: PropTypes.number,
    talentPoolId: PropTypes.number,
  }),
  withTalentPoolReportForm,
  withPropsRemoved('talentPoolId'),
  withProps(({ formState, handleFieldStateChange, isSubmitting }) => ({
    children: (
      <TalentPoolReportField
        disabled={isSubmitting}
        fieldState={formState.getFieldState()}
        onChange={handleFieldStateChange}
      />
    ),
    sentTo: formState.getFieldState().getNestedField('emails').getValue(),
    title: 'Generate Report',
    className: 'TalentPoolReportFormModal',
  })),
)(GenerateReportModal);

import updateAllAsRead from './updateAllAsRead';

/**
 * Creates an action which should
 * @param {Immutable.Map} notifications The collection of notifications that have been "seen"
 * @param {Immutable.List} notifications.data The collection of notifications.
 * @return {[type]} [description]
 */
export default function updateSeenAsRead(notifications) {
  return dispatch => {
    if (!notifications.has('data') || notifications.get('data').size <= 0) {
      return false;
    }

    // Find the newest sequential ID that exists in the collection to use as the "before"
    // value.
    const newest = notifications
      .get('data')
      .sortBy(notification => notification.getIn(['data', 'sequential_id']))
      .last();

    return dispatch(
      updateAllAsRead({
        lteq: newest.getIn(['data', 'sequential_id']),
      }),
    );
  };
}

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import routes from 'modules/routing/routes';
import { isEqual } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { convertToSnakeCase } from 'modules/core/jsonUtils';
import { useApiPut, useApiDelete } from 'modules/core/hooks/useApi';
import { toastError } from 'modules/toast-notifications/toastNotificationsSlice';
import { clearLegacyFilters, resetView } from './recordIndexSlice';

/**
 * A custom hook that handles saved view actions.
 */
const useRecordIndexSavedViews = () => {
  const dispatch = useDispatch();
  const {
    defaultSavedViewId,
    endpoints,
    initialSavedView,
    payloadKeys,
    savedView: currentSavedView,
    urls,
  } = useSelector(state => state.recordIndex);
  const params = useParams();
  const history = useHistory();
  const savedViewId = parseInt(params.savedViewId);

  const { filterSummary, persistentFilterSetIds } = useSelector(
    state => state.recordIndex,
  );

  const changeSavedView = useCallback(
    newSavedViewId => window.location.assign(urls.savedView(newSavedViewId)),
    [urls],
  );

  const [updateSavedViewRecord, isUpdatingSavedView] = useApiPut(
    endpoints.savedView(savedViewId),
  );

  const updateSavedView = () => {
    updateSavedViewRecord(convertToSnakeCase({ savedView: currentSavedView }), {
      onSuccess: () => {
        changeSavedView(currentSavedView.id);
      },
      onError: () => {
        dispatch(toastError('Could not update the saved view.'));
      },
    });
  };

  const [updateUser, isUpdatingUser] = useApiPut(routes.api_v1_users());

  const handleSetAsDefaultView = async () =>
    updateUser(
      {
        user: {
          [payloadKeys.userDefaultSavedViewId]: savedViewId,
        },
      },
      {
        onSuccess: () => window.location.reload(),
      },
    );

  const isUsingLegacyFilters = persistentFilterSetIds?.length === 0;

  const resetSavedView = () => {
    if (isUsingLegacyFilters && filterSummary?.length > 0) {
      dispatch(clearLegacyFilters());
    }
    // used replace as need to update url without reload
    history.replace({ pathname: history.location.pathname });

    if (!isUsingLegacyFilters) {
      dispatch(resetView());
    }
  };

  const [deleteSavedViewRecord] = useApiDelete(
    endpoints.savedView(savedViewId),
  );

  const deleteSavedView = () => {
    deleteSavedViewRecord(
      {},
      {
        onSuccess: () => {
          window.location.assign(urls.savedView(defaultSavedViewId));
        },
        onError: errorMessage => {
          dispatch(toastError(errorMessage));
        },
      },
    );
  };
  const hasChanges = !isEqual(currentSavedView, initialSavedView);

  return {
    hasChanges: hasChanges,
    deleteSavedView: deleteSavedView,
    defaultSavedViewId: defaultSavedViewId,
    resetSavedView: resetSavedView,
    updateSavedView: updateSavedView,
    isUpdatingUser: isUpdatingUser,
    isUpdatingSavedView: isUpdatingSavedView,
    changeSavedView: changeSavedView,
    setAsDefaultView: handleSetAsDefaultView,
  };
};

export default useRecordIndexSavedViews;

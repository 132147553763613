import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import classnames from 'classnames';
import getStage from '../selectors/getStage';

/**
 * Renders the name of a stage. The rejected stage will be colored red.
 */
const SearchStageName = ({ missingValue, renderIfMissing, stage }) =>
  stage || renderIfMissing ? (
    <span
      className={classnames('SearchStageName', {
        'SearchStageName--rejected': Boolean(stage && stage.get('rejected')),
      })}
    >
      {stage ? stage.get('name') : missingValue}
    </span>
  ) : null;

SearchStageName.propTypes = {
  /**
   * The value to render if there is no stage and `renderIfMissing` is true
   */
  missingValue: PropTypes.string,

  /**
   * True to render `missingValue` if `stage` is missing
   */
  renderIfMissing: PropTypes.bool,

  stage: ImmutablePropTypes.mapContains({
    name: PropTypes.string.isRequired,
    rejected: PropTypes.bool.isRequired,
  }),
};

SearchStageName.defaultProps = {
  missingValue: '(unassigned)',
  renderIfMissing: false,
};

export default compose(
  setDisplayName('SearchStageName(enhanced)'),
  setPropTypes({
    stageId: PropTypes.number.isRequired,
  }),
  connect((state, { stageId }) => ({
    stage: getStage(state, stageId),
  })),
  onlyUpdateForPropTypes,
)(SearchStageName);

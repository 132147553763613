import BoardMemberInfoField from './BoardMemberInfoField';
import createContactForm from '../createContactForm';

const createRootFieldState = props =>
  BoardMemberInfoField.createFieldState('contact', props.contact);

const BoardMemberInfoForm = createContactForm(
  BoardMemberInfoField,
  createRootFieldState,
  'contact-header-form',
);

export default BoardMemberInfoForm;

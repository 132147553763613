import PropTypes from 'prop-types';
import { compose, defaultProps, setDisplayName, setPropTypes } from 'recompose';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import { TYPE_TALENT_POOL } from 'modules/searches/constants';
import ContactCandidacyListTableView from './ContactCandidacyListTableView';
import columns from './CandidaciesTableView.columns';

/**
 * A listing of Talent Pool candidacies in a table view for a specific contact.
 */
export default compose(
  setDisplayName('ContactTalentPoolCandidaciesTableView'),
  setPropTypes({
    /**
     * The ID of the contact to show candidacies for.
     */
    contactId: PropTypes.number.isRequired,
  }),

  defaultProps({
    columns: [
      columns.SearchClientCompanyName,
      columns.SearchTitle,
      columns.SearchCurrentContact,
      columns.SearchJobFunctionName,
      columns.StageDetails,
      columns.MoveStage,
      columns.DateAdded,
    ],
    searchType: TYPE_TALENT_POOL,
  }),

  withClassName('ContactTalentPoolCandidacyListTableView'),
)(ContactCandidacyListTableView);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import SearchPageViewLink from 'modules/searches/components/SearchPageViewLink';
import mapTargetCompanyIdToTargetCompany from './mapTargetCompanyIdToTargetCompany';
import TargetCompanyIndicatorIcon from './TargetCompanyIndicatorIcon';

export const TargetCompanyCandidateCount = ({
  className,
  indicatorPosition,
  onClick,
  value,
}) => (
  <SearchPageViewLink
    className={classnames('TargetCompanyCandidateCount', className, {
      'TargetCompanyCandidateCount--icon-left': indicatorPosition === 'left',
      'TargetCompanyCandidateCount--icon-right': indicatorPosition === 'right',
    })}
    onClick={onClick}
    page='candidates'
  >
    {indicatorPosition === 'left' && <TargetCompanyIndicatorIcon />}
    <small className='target-company-count-value'>{value}</small>
    {indicatorPosition === 'right' && <TargetCompanyIndicatorIcon />}
  </SearchPageViewLink>
);

TargetCompanyCandidateCount.propTypes = {
  className: PropTypes.string,
  indicatorPosition: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
  value: PropTypes.number,
};

export default compose(
  setDisplayName('TargetCompanyCandidateCount(enhanced)'),
  setPropTypes({
    indicatorPosition: TargetCompanyCandidateCount.propTypes.indicatorPosition,
    onClick: PropTypes.func,
    targetCompanyId: PropTypes.number.isRequired,
  }),
  mapTargetCompanyIdToTargetCompany,
  withHandlers({
    // Override the onclick so that it gets called with the target company record.
    onClick: ({ onClick, targetCompany }) => () => {
      if (onClick) {
        onClick(targetCompany);
      }
    },
  }),
  mapProps(({ className, indicatorPosition, onClick, targetCompany }) => ({
    className: className,
    indicatorPosition: indicatorPosition,
    onClick: onClick,
    value: targetCompany && targetCompany.get('candidates_count'),
  })),
)(TargetCompanyCandidateCount);

import PropTypes from 'prop-types';
import { compose, lifecycle, setPropTypes } from 'recompose';
import connectContactActions from '../connectContactActions';

const fetchDirectReportsIfNeeded = ({ contactActions, contactId }) => {
  if (contactId) {
    contactActions.fetchDirectReportsIfNeeded({ contactId: contactId });
  }
};

/**
 * A higher order component that takes a `contact` record as a prop and
 * ensures that it's direct reports are fetched for the component.
 */
export default compose(
  setPropTypes({
    contactId: PropTypes.number,
  }),
  connectContactActions,
  lifecycle({
    UNSAFE_componentWillMount: function () {
      fetchDirectReportsIfNeeded(this.props);
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      fetchDirectReportsIfNeeded(nextProps);
    },
  }),
);

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import Currency from '@thrivetrm/ui/components/Currency';
import DateTime from 'modules/datetime/components/DateTime';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import mapCompanyIdToCompany from '../mapCompanyIdToCompany';
import CompanyName from '../CompanyName';

const willRender = ({ company }) =>
  company &&
  (company.get('acquired') ||
    company.get('publicly_traded') ||
    company.get('ticker'));

const CompanyFinancialsView = ({ company }) =>
  willRender({ company: company }) ? (
    <div className='CompanyFinancialsView row'>
      {company.get('acquired') && (
        <div className='CompanyFinancialsView__acquired col-sm-6 col-12'>
          <LabeledContent
            className='CompanyFinancialsView__acquired_by_company'
            label={
              company.get('acquired_by_company') ? 'Acquired by' : 'Acquired'
            }
          >
            {company.get('acquired_by_company') ? (
              <CompanyName
                companyId={company.get('acquired_by_company')}
                linkToCompany={true}
              />
            ) : (
              'Yes'
            )}
          </LabeledContent>
          {(company.get('acquisition_amount') ||
            company.get('date_acquired')) && (
            <LabeledContent
              className='CompanyFinancialsView__acquisition_details'
              label='Acquisition Details'
            >
              {company.get('acquisition_amount') && (
                <Currency
                  className='CompanyFinancialsView__acquisition_amount'
                  currency={company.get('acquisition_currency')}
                  value={company.get('acquisition_amount')}
                />
              )}
              {company.get('acquisition_amount') &&
              company.get('date_acquired') ? (
                <span> on </span>
              ) : null}
              {company.get('date_acquired') ? (
                <DateTime
                  className='CompanyFinancialsView__date_acquired'
                  value={company.get('date_acquired')}
                />
              ) : null}
            </LabeledContent>
          )}
        </div>
      )}

      {company.get('publicly_traded') ? (
        <div className='CompanyFinancialsView__publicly_traded col-sm-6 col-12'>
          <LabeledContent label='Publicly Traded'>Yes</LabeledContent>
          {company.get('ipo_date') ? (
            <LabeledContent
              className='CompanyFinancialsView__ipo_date'
              label='IPO Date'
            >
              <DateTime value={company.get('ipo_date')} />
            </LabeledContent>
          ) : null}
          {company.get('ticker') ? (
            <LabeledContent
              className='CompanyFinancialsView__ticker col-sm-6 col-12'
              label='Ticker'
            >
              {company.get('ticker')}
            </LabeledContent>
          ) : null}
        </div>
      ) : null}
    </div>
  ) : null;

CompanyFinancialsView.propTypes = {
  company: ImmutablePropTypes.mapContains({
    publicly_traded: PropTypes.bool,
    acquired: PropTypes.bool,
    ipo_date: PropTypes.string,
    acquired_by_company: PropTypes.number,
    acquisition_currency: PropTypes.string,
    acquisition_amount: PropTypes.number,
    ticker: PropTypes.string,
  }),
};

export default compose(
  setStatic('willRender', willRender),
  setDisplayName('CompanyFinancialsView(enhanced)'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
)(CompanyFinancialsView);

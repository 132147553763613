import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Assessment from './types/Assessment';
import Candidacy from './types/Candidacy';
import CandidacyAssessment from './types/CandidacyAssessment';
import Comment from './types/Comment';
import Event from './types/Event';
import Interview from './types/Interview';
import Introduction from './types/Introduction';
import IntroductionRequest from './types/IntroductionRequest';
import Meeting from './types/Meeting';
import Note from './types/Note';
import Notification from './types/Notification';
import Outreach from './types/Outreach';
import Task from './types/Task';
import Reference from './types/Reference';
import Vote from './types/Vote';

const NOTIFICATION_TYPES = {
  assessment: Assessment,
  recruiter_assessment: Assessment,
  client_assessment: Assessment,
  client_candidacy_assessment: CandidacyAssessment,
  researcher_assessment: Assessment,
  recruiter_candidacy_assessment: CandidacyAssessment,
  researcher_candidacy_assessment: CandidacyAssessment,
  candidacy: Candidacy,
  candidacy_comment: Comment,
  comment: Comment,
  introduction: Introduction,
  introduction_request: IntroductionRequest,
  interview: Interview,
  meeting: Meeting,
  note: Note,
  outreach: Outreach,
  task: Task,
  event_contact: Event,
  reference: Reference,
  candidacy_vote: Vote,
  vote: Vote,
};

/**
 * A component that renders a notification item based on it's
 * type (`notification.type`).
 */
const NotificationItem = ({ notification, ...props }) => {
  const NotificationComponent =
    NOTIFICATION_TYPES[notification.getIn(['data', 'type'])] || Notification;

  return <NotificationComponent {...props} notification={notification} />;
};

NotificationItem.propTypes = {
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      isLatestId: PropTypes.bool,
      created_at: PropTypes.string,
      icon: PropTypes.string.isRequired,
      id: PropTypes.number,
      message: PropTypes.string.isRequired,
      search: PropTypes.object,
      type: PropTypes.string,
    }).isRequired,
  }).isRequired,

  /* eslint-disable react/no-unused-prop-types */
  /**
   * This prop is passed to the IntroductionRequest component although
   * the block of code it is used in is currently commented out.  This
   * is a good candidate for removal/refactoring.
   */
  onNotificationAction: PropTypes.func,

  onUpdateRead: PropTypes.func,
  /* eslint-enable react/no-unused-prop-types */
};

export default NotificationItem;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  onlyUpdateForKeys,
  renderNothing,
  setDisplayName,
  setPropTypes,
  setStatic,
  withProps,
} from 'recompose';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import mapCandidacyIdToRejectionReason from 'modules/candidacies/components/mapCandidacyIdToRejectionReason';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';

const InterviewTableRejectionReasonColumn = ({
  componentId,
  rejectionReason,
}) => (
  <OverlayTrigger
    overlay={<Tooltip id={componentId}>{rejectionReason}</Tooltip>}
    placement='left'
  >
    <span>R</span>
  </OverlayTrigger>
);

InterviewTableRejectionReasonColumn.propTypes = {
  componentId: PropTypes.string.isRequired,
  rejectionReason: PropTypes.string.isRequired,
};

export default compose(
  setStatic('key', 'rejection_reason'),
  setPropTypes({
    interview: ImmutablePropTypes.mapContains({
      candidacy: PropTypes.number.isRequired,
    }).isRequired,
  }),
  setDisplayName('InterviewTableRejectionReasonColumn'),
  withProps(({ interview }) => ({
    candidacyId: interview.get('candidacy'),
  })),
  mapCandidacyIdToRejectionReason,
  onlyUpdateForKeys(['rejectionReason']),
  branch(({ rejectionReason }) => !rejectionReason, renderNothing),
  withComponentId(),
)(InterviewTableRejectionReasonColumn);

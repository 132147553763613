import classnames from 'classnames';
import { withProps } from 'recompose';
import Modal from './Modal';

/**
 * A modal with the SidebarModal class applied which styles it to look like it's attached
 * to the right side of the screen.
 * Can also be accomplished by just setting the className directly on a modal:
 *
 * ```jsx
 *   <Modal className='SidebarModal' />
 * ```
 */
export default withProps(({ className }) => ({
  className: classnames('SidebarModal', className),
}))(Modal);

import filterFetchParams from 'modules/routing/selectors/filterFetchParams';
import routes from 'modules/routing/routes';
import bulkImportSchema from '../schema';
import createAjaxAction from '../../../actions/createAjaxAction';
import { FETCH_PARAMS } from '../constants';
import {
  BULK_IMPORT_LIST_FETCH_START,
  BULK_IMPORT_LIST_FETCH_SUCCESS,
  BULK_IMPORT_LIST_FETCH_FAILURE,
} from './actionTypes';

/**
 * Creates the URL given the payload.
 * @param {Object} payload The action payload/paramters
 * @param {Number} [payload.limit]
 * @param {Number} [payload.page]
 * @param {String} [payload.sortAscending] True to sort ascending, false tosort descending
 * @param {String} [payload.sortBy] The value to sort the results on
 * @returns {Object} The query parameters to send to the server.
 */
export const getUrl = (payload = {}) => {
  const { limit, page, sortAscending, sortBy } = payload;

  const query = {
    limit: limit || 25,
    page: page || 1,
    sort_order: sortAscending ? 'asc' : 'desc',
    sort_field: sortBy,
  };

  return routes.api_v1_csv_uploads({ query: query });
};

/**
 * Creates an action that fetches a list of bulk imports
 * @param {Object} options
 * @param {Number} options.page An optional page number, if results are being paged.
 * @param {Number} options.limit The number of results to return per page.
 * @param {String} options.sortBy The field to sort on
 * @param {Boolean} options.sortAscending True to sort in ascending order, false to sort in
 *   descending order.
 * @returns {Function} A thunk that can dispatch actions for fetching a list of bulk imports.
 */
const fetchBulkImportList = createAjaxAction({
  getUrl: getUrl,
  createStartPayload: ({ ...payload } = {}) => ({
    params: filterFetchParams(payload, FETCH_PARAMS),
  }),
  // The backend still refers to bulk imports as csv uploads, so we have to reference
  // the `csv_uploads` key from the API response
  schema: { csv_uploads: [bulkImportSchema] },
  start: BULK_IMPORT_LIST_FETCH_START,
  success: BULK_IMPORT_LIST_FETCH_SUCCESS,
  failure: BULK_IMPORT_LIST_FETCH_FAILURE,
});

export default fetchBulkImportList;

import { connect } from 'react-redux';
import getRatingProperty from '../../selectors/ratings/getRatingProperty';

export default connect((state, { ratingIds }) => {
  // Return unless there are ratings.
  if (!ratingIds || !ratingIds.size) {
    return { ratingIds: ratingIds };
  }

  return {
    ratingIds: ratingIds
      .sortBy(id => getRatingProperty(state, id, 'created_at'))
      .reverse(),
  };
}, {});

import selectn from 'selectn';
import {
  compose,
  mapProps,
  setDisplayName,
  withHandlers,
  withProps,
} from 'recompose';
import withFormState from 'modules/forms/components/withFormState';
import withTransaction from 'modules/transactions/components/withTransaction';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import ResidencyField from './ResidencyField';
import createResidencyActionCreator from '../actions/createResidency';

/**
 * Provides props for managing a form for creating residency tokens.
 *
 * Takes the following incoming props:
 * * `onSaved` {Function} Will be called  when the form has been successfully saved. The first
 *   argument will be the ID of the residency that was created.
 *
 * Creates the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying ResidencyField FieldState
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 *
 */
export default compose(
  setDisplayName('withResidencyForm'),

  // Create a temporary prop (`withResidencyFormProps`) that holds any incoming props
  // so we don't overwrite/delete any incoming props that may need to be passed through.
  withProps(props => ({
    withResidencyFormProps: props,
  })),

  /**
   * Create a FormState. We are never editing an existing residency, so we can always
   * just create a new, clean, empty field state
   * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
   */
  withFormState(props =>
    ResidencyField.createFieldState('residency', props.residencyType),
  ),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onResetFormState,
      onSaved,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();
        // Call onSaved with the ID of the new residency.
        onSaved(selectn('payload.result.residency', transaction));
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  /**
   * Include a dispatch-connected version of `createResidency`
   */
  connect(null, dispatch => ({
    createResidency: bindActionCreators(createResidencyActionCreator, dispatch),
  })),
  /**
   * Add a callback to handle submitting the form.
   */
  withHandlers({
    onSubmit: ({
      createResidency,
      formState,
      onFormStateChange,
      startTransaction,
    }) => e => {
      // Prevent default browser behavior, which could cause the browser to attempt
      // to submit a form on it's own.
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }

      const transactionId = startTransaction();
      const residency = formState.getFieldValue();

      createResidency({ residency: residency, transactionId: transactionId });
      onFormStateChange(formState.startSubmit(transactionId));
    },
  }),

  // Prevent any props we used/created from bleeding down to the child.
  mapProps(
    ({
      formState,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      onSubmit,
      withResidencyFormProps,
    }) => ({
      ...withResidencyFormProps,
      formState: formState,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onResetFormState: onResetFormState,
      onSubmit: onSubmit,
    }),
  ),
);

const notify = require('./notify').default;

module.exports.initializeTooltip = resourceType =>
  $(`.js-add-${resourceType}`, `.js-remove-${resourceType}`).tooltip();

module.exports.initializeAddRemoveToggle = function initAddRemove(
  resourceType,
  selector,
) {
  $(selector).on(
    'ajax:before ajax:complete',
    `.js-add-${resourceType}, .js-remove-${resourceType}`,
    function disableIcon(xhr, status) {
      const icon = $(this).hasClass(`js-add-${resourceType}`)
        ? 'plus'
        : 'minus';
      $(this).find('.fa').toggleClass(`fa-${icon} fa-circle-o-notch fa-spin`);
      return $(this).attr('disabled', status == null);
    },
  );

  $(selector).on(
    'ajax:success',
    `.js-add-${resourceType}, .js-remove-${resourceType}`,
    function toggleIconClass(_xhr, _status, _error) {
      const [show, hide] = $(this).hasClass(`js-add-${resourceType}`)
        ? ['remove', 'add']
        : ['add', 'remove'];
      const id = $(this).data('id');
      return $(`.js-${hide}-${resourceType}[data-id=${id}]`).fadeOut(100, () =>
        $(`.js-${show}-${resourceType}[data-id=${id}]`).fadeIn(100),
      );
    },
  );

  $(selector).on(
    'ajax:error',
    `.js-add-${resourceType}, .js-remove-${resourceType}`,
    () => notify('There was a problem sending your request', 'error'),
  );

  if (window.location.hash) {
    return $(`a[href="${window.location.hash}"]`).click();
  }

  return null;
};

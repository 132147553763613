import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';
import CurrencyTypeSelectField from 'modules/currency/components/CurrencyTypeSelectField';
import CurrencyInputField from 'modules/currency/components/CurrencyInputField';
import InputGroupField from 'modules/forms/components/InputGroupField';
import * as validators from 'modules/forms/validators';
import { DEFAULT_CURRENCY } from 'modules/user/constants';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import { Map } from 'immutable';

/* eslint-disable jsx-a11y/label-has-associated-control */

class ContactDesiredInterimCompensationField extends PureComponent {
  static createFieldState = (
    name = 'contact-desired-interim-compensation',
    interimCompensation,
  ) => {
    const values = new Map({
      currency: null,
      notes: null,
      length_of_term: null,
      day_rate_min: null,
      day_rate_max: null,
    }).merge(interimCompensation);

    return FieldState.createNested(name, [
      CurrencyTypeSelectField.createFieldState(
        'currency',
        values.get('currency') || DEFAULT_CURRENCY,
        validators.requiredField('Currency'),
      ),
      TextAreaField.createFieldState('notes', values.get('notes')),
      InputGroupField.createFieldState(
        'length_of_term',
        values.get('length_of_term'),
      ),
      CurrencyInputField.createFieldState(
        'day_rate_min',
        values.get('day_rate_min'),
      ),
      CurrencyInputField.createFieldState(
        'day_rate_max',
        values.get('day_rate_max'),
      ),
    ]);
  };

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const { fieldState } = this.props;
    return (
      <>
        <p className='u-fontSize-small u-marginVertical-4 u-textColor-gray60'>
          <i className='fa fa-exclamation-circle' /> Refer to local legislation
          prior to recording candidate compensation.
        </p>
        <div className='row'>
          <div className='col-sm-6 ContactInterimCompensationForm__currency'>
            <CurrencyTypeSelectField
              clearable={true}
              fieldState={fieldState.getNestedField('currency')}
              label='Currency'
              name='currency'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className='col-sm-6'>
            <div className='row'>
              <div className='col-sm-12'>
                <label className='u-fontSize-small'>Day Rate</label>
              </div>
              <div className='col'>
                <CurrencyInputField
                  currency={fieldState.getNestedField('currency').getValue()}
                  data-testid='interim-compensation-min-day-rate-field'
                  fieldState={fieldState.getNestedField('day_rate_min')}
                  onChange={this.handleFieldChange}
                />
              </div>
              <div className='col-sm-1 u-flex u-flexJustify-c u-marginTop-4'>
                <span>to</span>
              </div>
              <div className='col'>
                <CurrencyInputField
                  currency={fieldState.getNestedField('currency').getValue()}
                  data-testid='interim-compensation-max-day-rate-field'
                  fieldState={fieldState.getNestedField('day_rate_max')}
                  onChange={this.handleFieldChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <InputGroupField
              appendComponent={<span>Months</span>}
              data-testid='interim-compensation-length-of-term-field'
              fieldState={fieldState.getNestedField('length_of_term')}
              key='length_of_term'
              label='Length of Term'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <TextAreaField
              data-testid='interim-compensation-notes-field'
              fieldState={fieldState.getNestedField('notes')}
              key='notes'
              label='Notes'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
      </>
    );
  }
}

ContactDesiredInterimCompensationField.propTypes = {
  /**
   * The field state containing the nested value.
   */
  fieldState: fieldStatePropType.isRequired,
  /**
   * Called when a nested field is changed.
   */
  onChange: PropTypes.func.isRequired,
};

export default ContactDesiredInterimCompensationField;

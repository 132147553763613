/**
 * The list of all fields to display in the "Contact Information"
 * section. Note that order matters.
 */
export const CONTACT_INFORMATION_FIELDS = [
  'avatar',
  'name',
  'job_title',
  'company',
  'aliases',
  'location',
  'professional_networks',
  'thrive_profile',
  'email',
  'phone',
  'mobile',
  'career_highlights',
  'documents',
  'experience',
  'education',
  'references',
  'additional_detail',
];

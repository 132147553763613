import PropTypes from 'prop-types';
import React from 'react';
import SearchNotesPanel from 'modules/notes/components/SearchNotesPanel';
import SearchPageSidebarPopover from './SearchPageSidebarPopover';

/**
 * Renders the "notes" popover content, which shows all notes related to the given search.
 */
const NotesPopover = ({ searchId, searchPage }) => {
  const title = searchPage === 'people' ? 'All Notes' : 'All Search Notes';

  return (
    <SearchPageSidebarPopover
      className='NotesPopover'
      searchId={searchId}
      searchPage={searchPage}
    >
      <SearchNotesPanel
        createButtonLabel={
          <div className='add-note'>
            <i className='fa fa-plus-circle' />
            <span className='add-note-text'> Note</span>
          </div>
        }
        searchId={searchId}
        title={title}
      />
    </SearchPageSidebarPopover>
  );
};

NotesPopover.propTypes = {
  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,

  /**
   * The current search page selected and displayed in the content portion of the search page
   * ("candidates", "applicants", "target_companies", etc)
   */
  searchPage: PropTypes.string.isRequired,
};

export default NotesPopover;

import { connect } from 'react-redux';

/**
 * A higher order component which takes a List of rating records (as a `ratings` props)
 * and generates a Map where the keys are the option ID and the value is the rating record
 * (provided as the prop `ratingsByOptionId`).
 */
export default connect(
  (state, { ratings }) => ({
    ratingsByOptionId:
      ratings &&
      ratings
        .toMap()
        .mapKeys((_, rating) => rating.get('assessment_option_id')),
  }),
  {},
);

exports.initialize = function initialize() {
  const INPUT_ERROR_CLASS = 'has-error';
  const INPUT_WARNING_CLASS = 'has-warning';

  const getFormattedNumber = valueToFormat => {
    const numberValue = Number(valueToFormat.replaceAll(',', ''));
    if (valueToFormat === '' || Number.isNaN(numberValue)) {
      return valueToFormat;
    } else {
      return numberValue.toLocaleString('en-US');
    }
  };

  const getRawNumber = formattedNumber => {
    return Number(formattedNumber.replaceAll(',', ''));
  };

  const $acceptedAndTerminatedOffers = $(
    "input[name='offer[accepted]']:checked, input[name='offer[terminated]']:checked",
  );
  $acceptedAndTerminatedOffers.each((index, element) => {
    const dataTargetClassName = $(element).data('target');
    $(dataTargetClassName).addClass('in');
  });

  // NUMBER FORMATTED FIELDS
  // Set up number formatting for fields that are annotated as such
  // Focusing the field allows the user to type unformatted numbers
  // Blurring the field formats the number with thousands separated by commas
  const $formattedNumberFields = $('.js-formatted-number');
  $formattedNumberFields.each((_index, inputElement) => {
    const formattedValue = getFormattedNumber(inputElement.value);
    inputElement.value = formattedValue;
  });

  $formattedNumberFields.on('focus', event => {
    const { value } = event.target;
    if (value !== '') {
      const rawNumber = getRawNumber(value);
      if (Number.isFinite(rawNumber)) {
        event.target.value = rawNumber;
      }
    }
  });
  $formattedNumberFields.on('blur', event => {
    const { value } = event.target;
    event.target.value = getFormattedNumber(value);
  });

  // Validation for number formatted fields
  // Min/max for percentage
  // Warning min/max based on data attributes:
  // data-warning-max-threshold / data-warning-min-threshold
  $formattedNumberFields.on('input', event => {
    // The slim template must have an associated message element that matches the
    // ID of the element with "-message" appended.
    const $messageElement = $(`#${event.target.id}-message`);
    const { dataset, value } = event.target;
    const maxValue = dataset.maxValue ?? null;
    const minValue = dataset.minValue ?? null;
    const warningMaxThreshold = dataset.warningMaxThreshold ?? null;
    const warningMinThreshold = dataset.warningMinThreshold ?? null;
    const rawNumber = getRawNumber(value);
    let errorMessage = '';
    let warningMessage = '';

    // Check for errors / warnings
    if (Number.isNaN(rawNumber)) {
      errorMessage = 'Non-numerical characters are not allowed.';
    } else if (minValue && rawNumber < minValue) {
      errorMessage = `This value cannot be less than ${minValue}.`;
    } else if (maxValue && rawNumber > maxValue) {
      errorMessage = `This value cannot be greater than ${maxValue}.`;
    } else if (warningMaxThreshold && rawNumber > warningMaxThreshold) {
      warningMessage = 'Are you sure? That looks like it might be too high.';
    } else if (warningMinThreshold && rawNumber < warningMinThreshold) {
      warningMessage = 'Are you sure? That looks like it might be too low.';
    }

    // Manage error / warning message display
    const $messageContainer = $messageElement.parent();
    $messageContainer
      .removeClass(INPUT_ERROR_CLASS)
      .removeClass(INPUT_WARNING_CLASS);
    if (errorMessage) {
      $messageContainer.addClass(INPUT_ERROR_CLASS);
      $messageElement.html(errorMessage);
    } else if (warningMessage) {
      $messageContainer.addClass(INPUT_WARNING_CLASS);
      $messageElement.html(warningMessage);
    } else {
      $messageElement.empty();
    }
  });

  // Manage form submit disabling when there are errors
  const $forms = $('form.offer-form');
  $forms.each((_index, form) => {
    $(form).on('input', () => {
      const $form = $(form);
      const hasErrors = Boolean(
        $form.find('.has-error > .help-block:parent').length,
      );
      $form.find('input:submit').prop('disabled', hasErrors);
    });
  });

  return null;
};

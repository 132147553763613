import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import interactionLevelSchema from 'modules/tenant/schemas/interactionLevels';

const InteractionLevelsView = ({ interactionLevelId }) => (
  <EntityProperty
    entityId={interactionLevelId}
    entityType={interactionLevelSchema.key}
    propertyName='name'
  />
);

InteractionLevelsView.propTypes = {
  interactionLevelId: PropTypes.number.isRequired,
};

export default InteractionLevelsView;

import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import SearchProperty from 'modules/searches/components/SearchProperty';
import mapDatumToCandidacyId from './mapDatumToCandidacyId';
import mapCandidacyIdToSearchId from '../mapCandidacyIdToSearchId';

/**
 * A candidacy table cell that renders the current status of the search that a candidacy record
 * is associated with.
 */
export default compose(
  setDisplayName('CandidacySearchStatusCell'),
  setPropTypes({
    /**
     * The candidacy ID
     */
    datum: PropTypes.number.isRequired,
  }),
  mapDatumToCandidacyId,
  mapCandidacyIdToSearchId,
  mapProps(({ searchId }) => ({
    propertyName: 'status',
    searchId: searchId,
  })),
)(SearchProperty);

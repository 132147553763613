import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  defaultProps,
  renderComponent,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { AutoSizer, List as VirtualList } from 'react-virtualized';
import { PARENT_SEARCH, SORT_BY_RANK } from 'modules/candidacies/constants';
import withCandidacyIdsFiltered from 'modules/candidacies/components/withCandidacyIdsFiltered';
import withCandidacyIdsSorted from 'modules/candidacies/components/withCandidacyIdsSorted';
import withCandidacyListFetched from 'modules/candidacies/components/withCandidacyListFetched';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import fetchStageListIfNeeded from 'modules/search-stages/components/fetchStageListIfNeeded';
import mapStageTypeToStageList from 'modules/search-stages/components/mapStageTypeToStageList';
import mapSearchIdToSearchProperty from 'modules/searches/components/mapSearchIdToSearchProperty';
import Card from '@thrivetrm/ui/components/Card';

import { connect } from 'react-redux';
import { CARD_MODES, CARD_MODE_DEFAULT } from './constants';

import withListItemsGroupedByStage from './withListItemsGroupedByStage';
import mapCandidacyIdsToListItems from './mapCandidacyIdsToListItems';

import CandidacyListLoadingIndicator from './CandidacyListLoadingIndicator';
import CandidacyListItem, {
  getCandidacyListItemHeight,
} from './items/CandidacyListItem';

/**
 * Renders a list of candidacies.
 * The candidacy list is rendered either in "full" mode, or "simple" mode
 * (full mode was previously called the "card" list, while simple mode
 * was previously called "list"). Full mode shows more details than simple mode.
 * Some items in full mode can additionally show an assessment block. These were
 * previously referred to as "large cards" (vs, i guess, the standard "small" cards).
 *
 * A third "redesign" mode indicates that the list render a new version in the process
 * of being implemented. This is controlled by a feature flag. Ultimately this mode should
 * replace all other modes as the singular item type.
 *
 * The candidacy list is actually given a list of `listItems` to render. Each list item
 * may represent either a candidacy row, or a group header row.
 */
class CandidacyList extends Component {
  componentDidUpdate(prevProps) {
    const { listItems } = this.props;
    if (prevProps.listItems !== listItems && this.virtualListRef) {
      // Whenever the list changew we need to force the list to recompute the row
      // heights -- particularly important when groups are expanded/collapsed because
      // a group header row may get replaced with a candidacy row, which has a different height.
      this.virtualListRef.recomputeRowHeights();
    }
  }

  setVirtualListRef = ref => {
    this.virtualListRef = ref;
  };

  render() {
    const {
      candidacyFetchError,
      fetchCandidacyList,
      getRowHeight,
      isFetchingCandidacies,
      isLoadingCandidacyAssessments,
      listItems,
      loadCandidacyAssessmentsError,
      renderRow,
    } = this.props;

    return (
      <div className='CandidacyList'>
        {loadCandidacyAssessmentsError && !isLoadingCandidacyAssessments ? (
          <Card type='error'>{loadCandidacyAssessmentsError}</Card>
        ) : null}
        {candidacyFetchError && !isFetchingCandidacies && (
          <ErrorAlert
            error={candidacyFetchError}
            onRetry={fetchCandidacyList}
            title='Error loading candidates:'
          />
        )}
        <AutoSizer>
          {({ height, width }) => (
            <VirtualList
              height={height}
              ref={this.setVirtualListRef}
              rowCount={listItems ? listItems.count() : 0}
              rowHeight={getRowHeight}
              rowRenderer={renderRow}
              tabIndex={0}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

CandidacyList.propTypes = {
  /**
   * Any Error that occured fetching the candidacy list.
   * @type {Error}
   */
  candidacyFetchError: PropTypes.object, // eslint-disable-line react/forbid-prop-types

  /**
   * A function that will fetch (or refetch) the candidacy list
   */
  fetchCandidacyList: PropTypes.func,

  /**
   * A function that should return the height of a particular row.
   */
  getRowHeight: PropTypes.func.isRequired,

  /**
   * True if currently fetching the candidacy list; otherwise, false.
   */
  isFetchingCandidacies: PropTypes.bool,

  /**
   * True if currently loading the candidacy assessments; otherwise, false.
   */
  isLoadingCandidacyAssessments: PropTypes.bool,

  /**
   * The list of list items being rendered.
   */
  listItems: ImmutablePropTypes.listOf(
    PropTypes.shape({
      isGroup: PropTypes.bool,
    }),
  ),

  /**
   * Any Error that occured loading the candidacy assessments.
   */
  loadCandidacyAssessmentsError: PropTypes.string,

  /**
   * A function that renders a single row in the list.
   * @type {[type]}
   */
  renderRow: PropTypes.func.isRequired,
};

CandidacyList.defaultProps = {
  isFetchingCandidacies: false,
};

export default compose(
  setPropTypes({
    /**
     * The current list mode: LARGE_CARD_MODE or SMALL_CARD_MODE
     */
    cardMode: PropTypes.oneOf(CARD_MODES).isRequired,

    /**
     * The current text the list is being filtered on.
     */
    filterText: PropTypes.string,

    /**
     * True to only show "priority" candidacies; otherwise, false.
     */
    priorityOnly: PropTypes.bool.isRequired,

    /**
     * True to only show "visible" candidacies; otherwise, false.
     */
    visibleOnly: PropTypes.bool.isRequired,

    /**
     * The search to render the candidacies for.
     */
    searchId: PropTypes.number.isRequired,
  }),

  defaultProps({
    cardMode: CARD_MODE_DEFAULT,
    filterText: null,
    parentType: PARENT_SEARCH,
    priorityOnly: false,
    visibleOnly: false,
    groupByStage: true,
  }),

  connect(state => ({ candidacyAssessments: state.candidacyAssessments }), {}),
  // Get the candidacyList and fetch it if needed.
  withCandidacyListFetched({ parentIdPropName: 'searchId' }),

  // Make sure we have the stage list of this search.
  mapSearchIdToSearchProperty('stage_type', 'stageType'),
  mapStageTypeToStageList,
  fetchStageListIfNeeded,

  // Pull the candidacyIds off of the candidacyList and supply them as a prop for
  // sorting/filtering/grouping.
  withProps(({ candidacyAssessments, candidacyList, stageList }) => ({
    candidacyIds: candidacyList && candidacyList.get('ids'),
    isFetchingCandidacies: Boolean(
      candidacyList && candidacyList.getIn(['_meta', 'isFetching']),
    ),
    isFetchingStages: Boolean(
      stageList && stageList.getIn(['_meta', 'isFetching']),
    ),
    candidacyFetchError:
      candidacyList && candidacyList.getIn(['_meta', 'error']),
    isLoadingCandidacyAssessments:
      candidacyAssessments.isLoadingCandidacyAssessments,
    loadCandidacyAssessmentsError:
      candidacyAssessments.loadCandidacyAssessmentsError,
  })),

  // Show a loading indicator while we're fetching candidacies or stages
  branch(
    ({ candidacyList, isFetchingCandidacies, isFetchingStages }) =>
      !candidacyList || isFetchingCandidacies || isFetchingStages,
    renderComponent(CandidacyListLoadingIndicator),
  ),

  // Apply any filters.
  withCandidacyIdsFiltered,

  // Sort the IDs.
  withCandidacyIdsSorted,

  mapCandidacyIdsToListItems,

  // Optionally group them by stage if `groupByStage` is true.
  // Also convert the groups and IDs into a flattened list of `listItems`. Each item has
  // a `type` which identifies what should be rendered in that position of the list (a candidacy
  // item or a group header).
  branch(
    ({ groupByStage, listItems }) => Boolean(groupByStage && listItems),
    withListItemsGroupedByStage,
  ),

  withProps(({ searchId, sortBy }) => ({
    allowRanking: sortBy === SORT_BY_RANK,
    searchId: searchId,
  })),

  withHandlers({
    renderRow: ({
      allowRanking,
      cardMode,
      listItems,
      searchId,
      toggleGroup,
    }) => row => (
      <CandidacyListItem
        allowRanking={allowRanking}
        cardMode={cardMode}
        index={row.index}
        key={row.key}
        listItem={listItems.get(row.index)}
        row={row}
        searchId={searchId}
        style={row.style}
        toggleGroup={toggleGroup}
      />
    ),
    getRowHeight: ({ cardMode, listItems }) => row =>
      getCandidacyListItemHeight({
        row: row,
        listItem: listItems.get(row.index),
        cardMode: cardMode,
      }),
  }),
)(CandidacyList);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import NotificationsScrollList from './NotificationsScrollList';

/**
 * A component for displaying notifications inside a popover panel.
 */
class NotificationsPopover extends Component {
  UNSAFE_componentWillMount() {
    const { onFetchNotifications } = this.props;
    onFetchNotifications();
  }

  componentDidMount() {
    // We have to add the event handler on the next tick because if this popover
    // is triggered by a click event itself (as it is with the NotificationIndicator),
    // that click event will be caught here and cause the popover to simply close
    // immediately.
    setTimeout(() => {
      // Our click listener MUST be done in the CAPTURE phase beause otherwise
      // we may not see our click event until after a rerender, in which case
      // the target is no longer attached to the DOM, and we have no way of
      // identifying whether it is inside our component or not!
      document.addEventListener('click', this.handleDocumentClick, true);
      document.addEventListener('keyup', this.handleDocumentKeypress);
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
    document.removeEventListener('keyup', this.handleDocumentKeypress);
  }

  setListRef = element => {
    this._listElement = element;
  };

  handleDocumentClick = event => {
    const { closeOnOutsideClick, onClose } = this.props;
    if (closeOnOutsideClick && onClose && this._listElement) {
      let willClose = !this._listElement.getRef().contains(event.target);

      if (typeof closeOnOutsideClick === 'function') {
        willClose = closeOnOutsideClick(event, willClose);
      }

      if (willClose) {
        onClose(event);
      }
    }
  };

  handleDocumentKeypress = event => {
    const { closeOnEscapeKey, onClose } = this.props;
    if (closeOnEscapeKey && event.keyCode === 27 && onClose) {
      onClose();
    }
  };

  handleMarkAllRead = event => {
    const { onUpdateAllRead } = this.props;
    event.preventDefault();
    onUpdateAllRead();
  };

  handleClose = event => {
    const { onClose } = this.props;

    event.preventDefault();

    if (onClose) {
      onClose();
    }
  };

  handleRefresh = event => {
    const { onRefreshNotifications } = this.props;
    event.preventDefault();
    onRefreshNotifications();
  };

  handleWheel = event => {
    // Intercept the scroll events so that scrolling in the popover does not
    // cause scrolling in the parent. This causes problems when we're showing
    // tooltips in the notification window -- if the parent window scrolls,
    // the tooltip scrolls with it (since it's attached to the body).
    const listEl = this._listElement.getRef();
    if (
      event.deltaY > 0 &&
      listEl.scrollHeight - listEl.offsetHeight - listEl.scrollTop <= 0
    ) {
      // Scrolling down. If we're at the bottom of the list, just ignore any more scroll events
      // so that they don't allow the parent (body) to scroll.
      event.preventDefault();
    } else if (event.deltaY < 0 && listEl.scrollTop === 0) {
      // Scrolling up, we're at the top, prevent scrolling.
      event.preventDefault();
    }
  };

  render() {
    const {
      notifications,
      onFetchNextPage,
      onNotificationAction,
      onUpdateRead,
      showCloseButton,
      style,
    } = this.props;
    const hasUnread = Boolean(notifications.getIn(['meta', 'count']));
    const isFetching = notifications.getIn(['meta', 'isFetching']);
    const isDirty =
      notifications.getIn(['meta', 'isInvalidated']) && !isFetching;
    const error = notifications.getIn(['meta', 'error']);

    return (
      <div
        className='notifications notifications-popover'
        onWheel={this.handleWheel}
        style={style}
      >
        <h4 className='notifications-popover__header' key='header'>
          Notifications
          {isDirty ? (
            <a
              href='#refresh'
              key='refresh-button'
              onClick={this.handleRefresh}
            >
              <i className='fa fa-refresh' />
            </a>
          ) : null}
          {isFetching ? (
            <span key='refresh-indicator'>
              <i className='fa fa-refresh fa-spin' />
            </span>
          ) : null}
          {showCloseButton ? (
            <a className='pull-right' href='#close' onClick={this.handleClose}>
              <i className='fa fa-close' />
            </a>
          ) : null}
        </h4>
        {error && !isFetching ? (
          <ErrorAlert
            onRetry={this.handleRefresh}
            title='There was an error fetching notifications.'
          />
        ) : null}
        <NotificationsScrollList
          actionPosition='bottom'
          label='unread'
          notifications={notifications}
          onFetchNextPage={onFetchNextPage}
          onNotificationAction={onNotificationAction}
          onUpdateRead={onUpdateRead}
          ref={this.setListRef}
        />
        <div className='notifications-popover-footer' key='footer'>
          <div className='u-flex' role='group'>
            <ButtonLink
              className='btn u-width-100'
              key='view_all'
              label='View all'
              onClick={() => {
                window.location.href = '/notifications';
              }}
            />
            <ButtonLink
              className={classnames('btn u-width-100', {
                disabled: !hasUnread,
              })}
              isDisabled={!hasUnread}
              key='mark_all_read'
              label='Mark all as read'
              onClick={this.handleMarkAllRead}
            />
          </div>
        </div>
      </div>
    );
  }
}

NotificationsPopover.propTypes = {
  /**
   * Whether the popover should be closed when the user presses the ESC key
   */
  closeOnEscapeKey: PropTypes.bool,

  /**
   * Whether the popover should be closed when the user clicks outside
   * of the popover panel (elsewhere in the document).
   * If a function is specified, it will be called with the click event and
   * whether the click was detected outside of the component. That function
   * should return true if the popover should be closed, or false if it should
   * not be. Example:
   * ```
   * closeOnOutsideClick={(event, willClose) => {
   *  return willClose && event.target !== someTargetToIgnore;
   * }}
   */
  closeOnOutsideClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

  /**
   * The notifications dataset to display in this popover. It should
   * be an Immutable Map (as returned by the notificationsByCategory reducer)
   */
  notifications: ImmutablePropTypes.mapContains({
    meta: ImmutablePropTypes.mapContains({
      count: PropTypes.number,
      error: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
          message: PropTypes.string,
        }),
      ]),
      isFetching: PropTypes.bool,
      isInvalidated: PropTypes.bool,
    }),
  }).isRequired,

  /**
   * A callback that is called when the popove should be closed.
   */
  onClose: PropTypes.func.isRequired,

  /**
   * Called to fetch the next page of notifications.
   */
  onFetchNextPage: PropTypes.func.isRequired,

  /**
   * Called to fetch notifications (if needed)
   */
  onFetchNotifications: PropTypes.func.isRequired,

  onNotificationAction: PropTypes.func.isRequired,

  /**
   * Called to completely refresh notifications from the server
   */
  onRefreshNotifications: PropTypes.func.isRequired,

  /**
   * Called to mark all currently seen notifications as read.
   */
  onUpdateAllRead: PropTypes.func.isRequired,

  /**
   * Called when a notifications "read" status should be toggled
   */
  onUpdateRead: PropTypes.func,

  /**
   * Whether to show the close button in the header of the popover
   */
  showCloseButton: PropTypes.bool,

  /**
   * Inline styles to pass through to the popover panel
   */
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

NotificationsPopover.defaultProps = {
  closeOnEscapeKey: true,
  closeOnOutsideClick: true,
  showCloseButton: false,
};

export default NotificationsPopover;

import { connect } from 'react-redux';
import { compose } from 'recompose';
import getIntroductionDate from '../selectors/getIntroductionDate';

/**
 * A Higher order component that sorts a list of introductionIds in reverse chronological order
 * of the date field.
 */
export default compose(
  connect((state, { introductionIds }) => ({
    introductionIds:
      introductionIds &&
      introductionIds.sortBy(id => getIntroductionDate(state, id)).reverse(),
  })),
);

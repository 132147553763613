import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import Currency from '@thrivetrm/ui/components/Currency';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import mapCompanyIdToCompany from '../mapCompanyIdToCompany';

const CompanyMarketCapView = ({ company }) => (
  <div className='CompanyMarketCapView'>
    {company && company.get('market_cap_amount') && (
      <LabeledContent
        className='CompanyMarketCapView_market_cap_details'
        label='Current Market Cap'
      >
        <Currency
          className='CompanyMarketCapView_market_cap'
          currency={company.get('market_cap_currency')}
          value={company.get('market_cap_amount')}
        />
      </LabeledContent>
    )}
  </div>
);

CompanyMarketCapView.propTypes = {
  company: ImmutablePropTypes.mapContains({
    market_cap: PropTypes.number,
    market_cap_currency: PropTypes.string,
  }),
};

export default compose(
  setDisplayName('CompanyMarketCapView(enhanced)'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
)(CompanyMarketCapView);

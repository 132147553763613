import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import routes from 'modules/routing/routes';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Card from '@thrivetrm/ui/components/Card';
import Avatar from '@thrivetrm/ui/components/Avatar';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';

const CandidacyInfoCard = ({ candidacyId, guestToken, isGuestAssessment }) => {
  const [
    loadCandidacy,
    isLoadingCandidacy,
    candidacyLoadError,
    candidacyData,
  ] = useApiGet(
    routes.candidacy_assessment_api_v1_candidacy({
      id: candidacyId,
      query: { guest_token: guestToken },
    }),
  );

  useEffect(() => {
    loadCandidacy();
  }, [candidacyId, loadCandidacy]);

  const candidacy = candidacyData?.candidacy;

  const renderCompanyName = () => {
    if (candidacy.primary_company_id && candidacy.primary_company_name) {
      if (isGuestAssessment) {
        return <p>{candidacy.primary_company_name}</p>;
      }
      return (
        <a
          href={`/companies/${candidacy.primary_company_id}`}
          rel='noopener noreferrer'
          target='_blank'
        >
          {candidacy.primary_company_name}
        </a>
      );
    }
    return null;
  };

  return (
    <LoadingContainer isLoading={isLoadingCandidacy} minHeight='12vh'>
      {candidacy && !isLoadingCandidacy ? (
        <Card
          className='u-flex u-flexJustify-spaceEvenly u-marginVertical-16'
          isCentered={false}
        >
          <div className='u-flex u-flexAlign-c'>
            <Avatar
              className='u-marginRight-16'
              image={candidacy.avatar_url}
              name={candidacy.full_name}
              shape='circle'
              size='medium'
            />
            <h2 className='u-margin-n'>{candidacy.full_name}</h2>
          </div>
          {candidacy.primary_position_name ? (
            <>
              <div className='u-paddingVertical-32 u-marginHorizontal-16 u-borderRight' />
              <div className='u-flex u-flexAlign-c u-flexJustify-c'>
                <div>
                  <p className='u-margin-n'>
                    {candidacy.primary_position_name}
                  </p>
                  {renderCompanyName()}
                </div>
              </div>
            </>
          ) : null}
          {candidacy.location ? (
            <>
              <div className='u-paddingVertical-32 u-marginHorizontal-16 u-borderRight' />
              <div className='u-flex u-flexAlign-c u-flexJustify-c'>
                <p className='u-margin-n'>{candidacy.location}</p>
                <i className='fa fa-map-marker u-textColor-blue u-fontSize-large u-marginLeft-8' />
              </div>
            </>
          ) : null}
          {candidacy.linkedin_url || (candidacy.resume && !guestToken) ? (
            <>
              <div className='u-paddingVertical-32 u-marginHorizontal-16 u-borderRight' />
              <div className='u-flex u-flexAlign-c u-flexJustify-r u-flexWrap-w'>
                {candidacy.linkedin_url ? (
                  <ButtonSecondary
                    className='u-noWrap'
                    icon='linkedIn'
                    label='LinkedIn'
                    rel='noopener noreferrer'
                    size='small'
                    target='_blank'
                    url={candidacy.linkedin_url}
                  />
                ) : null}
                {candidacy.resume?.uuid && !guestToken ? (
                  <ButtonSecondary
                    className='u-marginRight-2 u-noWrap'
                    icon='download'
                    label='Resume'
                    rel='noopener noreferrer'
                    size='small'
                    target='_blank'
                    url={routes.document({ uuid: candidacy.resume?.uuid })}
                  />
                ) : null}
              </div>
            </>
          ) : null}
        </Card>
      ) : null}
      {candidacyLoadError && !isLoadingCandidacy ? (
        <Card
          className='u-marginVertical-16'
          data-testid='candidacy-load-error-message'
          type='error'
        >
          {candidacyLoadError}
        </Card>
      ) : null}
    </LoadingContainer>
  );
};

CandidacyInfoCard.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  guestToken: PropTypes.string,
  isGuestAssessment: PropTypes.bool,
};

export default CandidacyInfoCard;

import invalidateCompany from 'modules/companies/actions/invalidateCompany';
import deleteEntity from 'modules/entities/actions/deleteEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import getInvestmentProperty from 'modules/company-investments/selectors/getInvestmentProperty';
import investmentSchema from 'modules/company-investments/schema';
import routes from 'modules/routing/routes';

/**
 * Creates an action for deleting a funding round
 * @param {Object} payload
 * @param {Number} payload.id The ID of the funding round to delete
 */
export default ({ id, ...rest }) => (dispatch, getState) =>
  dispatch(
    deleteEntity({
      ...rest,
      id: id,
      entityType: investmentSchema.key,
      url: routes.api_v1_funding_round({ id: id }),
    }),
  ).then(action => {
    // If the delete was success, the company needs to be refreshed because it wil
    // have an updated total funding amount.
    if (action.type === entityActionTypes.DELETE_SUCCESS) {
      const companyId = getInvestmentProperty(getState(), id, 'venture');
      if (companyId) {
        dispatch(invalidateCompany({ id: companyId }));
      }
    }

    return action;
  });

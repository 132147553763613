import PropTypes from 'prop-types';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import { compose, setPropTypes } from 'recompose';
import TextAreaField from 'modules/forms/components/TextAreaField';
import * as validators from 'modules/forms/validators';
import connectTransactions from 'modules/transactions/components/connectTransactions';

import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';
import connectTargetCompanyActions from './connectTargetCompanyActions';

const createRootFieldState = props =>
  TextAreaField.createFieldState(
    'comment',
    (props.initialFields && props.initialFields.comment) || '',
    validators.requiredField('A comment'),
  );

const handleSubmit = (formState, props) => {
  const { targetCompanyActions, targetCompanyId } = props;
  const transactionId = uniqueId();

  targetCompanyActions.voteTargetCompany({
    targetCompanyId: targetCompanyId,
    comment: formState.getFieldValue(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export default compose(
  setPropTypes({
    onCancel: PropTypes.func.isRequired,
    onSaved: PropTypes.func.isRequired,
    targetCompanyId: PropTypes.number.isRequired,
  }),
  connectTransactions,
  connectTargetCompanyActions,
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'target-companies--target-company-vote-form',
    omitProps: [
      'formState',
      'initialFields',
      'targetCompanyActions',
      'targetCompanyId',
      'transactionActions',
    ],
  }),
)(TextAreaField);

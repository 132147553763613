import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import ScrollableContainer from '@thrivetrm/ui/components/ScrollableContainer';
import RecordIndexSidebarNetworks from './RecordIndexSidebarNetworks';
import SelectableFilterInputs from './v5-filters/SelectableFilterInputs';
import { clearFilters } from '../recordIndexSlice';

const RecordIndexSidebarV5 = () => {
  const dispatch = useDispatch();

  const { isSidebarExpandedKey } = useSelector(state => state.recordIndex);

  const isSidebarExpandedInitialValue =
    JSON.parse(window.localStorage.getItem(isSidebarExpandedKey)) ?? true;

  const [isSidebarExpanded, _open, _close, toggleSidebar] = useToggle(
    isSidebarExpandedInitialValue,
  );

  useEffect(() => {
    window.localStorage.setItem(isSidebarExpandedKey, isSidebarExpanded);
  }, [isSidebarExpanded, isSidebarExpandedKey]);

  const containerClass = classnames(
    'RecordIndexPage__sidebar',
    'u-marginLeft-16 u-paddingTop-n',
    {
      'RecordIndexPage__sidebar--collapsed': !isSidebarExpanded,
    },
  );

  const handleClearFilters = () => {
    dispatch(clearFilters());
  };

  const appHeaderHeight = '48px';
  const pageHeaderHeight = '98px';
  const appFooterHeight = '72px';
  const clearFiltersAndCollapseButtonHeight = '34px';

  const maxSidebarHeight = `calc(100vh - ${appHeaderHeight} - ${pageHeaderHeight} - ${appFooterHeight} - ${clearFiltersAndCollapseButtonHeight})`;

  return (
    <div className={containerClass}>
      {isSidebarExpanded ? (
        <>
          <div className='u-flex u-flexJustify-r u-flexAlign-c u-paddingBottom-4'>
            <div>
              <ButtonSecondary
                label='Clear Filters'
                onClick={handleClearFilters}
                size='small'
              />
              <Tooltip
                content={
                  <p className='u-margin-n u-noWrap'>Collapse Sidebar</p>
                }
                position='bottomRight'
              >
                <ButtonSecondary
                  className='u-marginLeft-8'
                  icon='chevronLeft'
                  onClick={toggleSidebar}
                  size='small'
                />
              </Tooltip>
            </div>
          </div>
          <ScrollableContainer
            contentClassName='RecordIndexPage__sidebarScrollContent'
            maxHeight={maxSidebarHeight}
            minHeight={maxSidebarHeight}
          >
            <RecordIndexSidebarNetworks
              className='u-borderBottom u-paddingBottom-12 u-marginBottom-12'
              pageName='contacts'
            />
            <SelectableFilterInputs />
          </ScrollableContainer>
        </>
      ) : (
        <ButtonSecondary
          icon='chevronRight'
          onClick={toggleSidebar}
          size='small'
          title='Expand'
        />
      )}
    </div>
  );
};

export default RecordIndexSidebarV5;

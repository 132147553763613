import PropTypes from 'prop-types';
import React from 'react';
import ModalButton from 'modules/core/componentsLegacy/ModalButton';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import CandidacyTagModal from './CandidacyTagModal';
import CandidacySearchTagModal from './CandidacySearchTagModal';

/**
 * Renders a button that, when clicked, shows a modal for managing a candidacy's tags.
 */
const CandidacyTagModalButton = ({
  candidacyId,
  searchId,
  searchType,
  ...props
}) => (
  <ModalButton
    candidacyId={candidacyId}
    candidateIds={[candidacyId]}
    className='CandidacyTagModalButton'
    modal={
      searchType === TYPE_PIPELINE ? CandidacyTagModal : CandidacySearchTagModal
    }
    searchId={searchId}
    {...props}
  >
    <i className='fa fa-tags' />
  </ModalButton>
);

CandidacyTagModalButton.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  searchId: PropTypes.number.isRequired,
  searchType: PropTypes.string,
};

export default CandidacyTagModalButton;

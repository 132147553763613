import getFundingRoundList from './getFundingRoundList';

/**
 * Determines if a fundingRounds list needs to be fetched from the server.
 * @param {*} state
 * @param {Number} companyId
 * @returns {Boolean} true if the funding rounds for the given company should be fetched from the
 *   server; otherwise, false.
 */
export default (state, companyId) => {
  if (!companyId) {
    return false;
  }

  const fundingRoundList = getFundingRoundList(state, companyId);

  if (!fundingRoundList) {
    return true;
  }

  if (
    fundingRoundList.getIn(['_meta', 'isFetching']) ||
    fundingRoundList.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return Boolean(fundingRoundList.getIn(['_meta', 'isInvalidated']));
};

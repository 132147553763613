import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, mapProps, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import hideIfFeatureNotEnabled from 'modules/auth/components/hideIfFeatureNotEnabled';
import CompanyMarketCapForm from './CompanyMarketCapForm';
import CompanyMarketCapView from './CompanyMarketCapView';
import mapCompanyIdToCompany from '../mapCompanyIdToCompany';

/**
 * Renders an EditableSection that displays and allows editing of a company's market cap.
 */
export default compose(
  setDisplayName('CompanyMarketCapEditableSection(enhanced)'),
  setPropTypes({
    /**
     * The ID of the company whose market cap will be shown/edited.
     */
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
  hideIfFeatureNotEnabled('field.company.market_cap'),
  mapProps(({ company, companyId }) => ({
    companyId: companyId,
    children:
      company && company.get('market_cap_amount') ? (
        <CompanyMarketCapView companyId={companyId} />
      ) : null,
    className: 'CompanyMarketCapEditableSection',
    formComponent: CompanyMarketCapForm,
    title: 'Market Cap',
  })),
)(EditableSection);

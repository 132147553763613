import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose, setDisplayName } from 'recompose';
import connectRouting from 'modules/routing/components/connectRouting';
import getQuery from 'modules/routing/selectors/getQuery';
import PageHeader from 'modules/core/components/PageHeader';
import url from 'url';
import { stringifyQueryObject } from 'modules/core/urlUtils';
import BulkImportTable from './BulkImportTable';
import BulkImportModal from './BulkImportModal';
import * as bulkImportConstants from '../constants';

const BulkImportPage = ({
  currentPageNumber,
  resultsPerPage,
  routingActions,
}) => {
  const updateUrlQueryParams = params => {
    routingActions.push(
      url.format({
        pathname: window.location.pathname,
        search: stringifyQueryObject({
          limit: resultsPerPage,
          page: currentPageNumber,
          ...params,
        }),
      }),
    );
  };

  const handlePageChange = pageNumber => {
    updateUrlQueryParams({ page: pageNumber });
  };

  const handleResultsPerPageChange = newResultsPerPage => {
    updateUrlQueryParams({ limit: newResultsPerPage });
  };

  const handleSortChange = (sortBy, sortAscending) => {
    updateUrlQueryParams({
      sortBy: sortBy,
      sortOrder: sortAscending ? 'asc' : 'desc',
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModalButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModalButtonClick = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='BulkImportPage'>
      <Helmet>
        <title>Bulk Import</title>
      </Helmet>
      <PageHeader title='Bulk Import'>
        <div className='BulkImportModalButton'>
          <button
            className='btn btn-primary'
            onClick={handleOpenModalButtonClick}
            type='button'
          >
            Start New Import
          </button>
        </div>
      </PageHeader>

      <div className='container-full-width'>
        <h2>Import History</h2>
        <div className='BulkImportTable'>
          <BulkImportTable
            columns={[
              bulkImportConstants.COLUMN_FILE_NAME,
              bulkImportConstants.COLUMN_RESOURCE_TYPE,
              bulkImportConstants.COLUMN_UPLOAD_STATUS,
              bulkImportConstants.COLUMN_USER_NAME,
              bulkImportConstants.COLUMN_START_TIME,
              bulkImportConstants.COLUMN_END_TIME,
              bulkImportConstants.COLUMN_STATUS_FILE,
            ]}
            onLimitChange={handleResultsPerPageChange}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
          />
        </div>
      </div>

      {isModalOpen && <BulkImportModal onClose={handleCloseModalButtonClick} />}
    </div>
  );
};

BulkImportPage.propTypes = {
  currentPageNumber: PropTypes.number,
  resultsPerPage: PropTypes.number,
  routingActions: PropTypes.objectOf(PropTypes.func),
};

export default compose(
  setDisplayName('BulkImportPage(container)'),
  connect(state => {
    const urlQuery = getQuery(state);
    return {
      currentPageNumber: parseInt(urlQuery.get('page')) || null,
      resultsPerPage: parseInt(urlQuery.get('limit')) || null,
    };
  }),
  connectRouting,
)(BulkImportPage);

import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import ReactSelect from 'react-select-legacy';
import asFormGroupField from './asFormGroupField';
import FieldState from '../FieldState';

/**
 * ReactSelect requires one single input for value so we are sending in time as mintues
 * since midnight. For the label we hvae to confort it back into hour and mintues.
 */

const TIME_INTERVAL_OPTIONS = [...Array(96)]
  .map((_, index) => index * 15)
  .map(minutes => ({
    value: minutes,
    label: moment
      .utc({ hour: Math.floor(minutes / 60), minute: minutes % 60 })
      .format('LT'),
  }));

/**
 * A dropdown component for selecting a minute interval from a day's range of minutes.
 */

export const TimeIntervalSelect = ({ value, ...props }) => (
  <ReactSelect
    clearable={false}
    options={TIME_INTERVAL_OPTIONS}
    simpleValue={true}
    value={value}
    {...props}
  />
);

TimeIntervalSelect.createFieldState = (name, { hours, minutes }, validator) =>
  FieldState.create(name, hours * 60 + minutes, validator, value => ({
    hours: Math.floor(value / 60),
    minutes: value % 60,
  }));

TimeIntervalSelect.propTypes = {
  /**
   * The selected time value.
   */
  value: PropTypes.number.isRequired,
};

export default asFormGroupField(TimeIntervalSelect);

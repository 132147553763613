import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import taskSchema from '../schema';

/**
 * Gets the due date of a task by it's ID
 * @param {*} state
 * @param {Number} taskId The ID of the task.
 * @return {String} The ISO8601-formatted due date for the task
 */
export default (state, taskId) =>
  getEntityProperty(state, taskSchema.key, taskId, 'reminder');

import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { compose, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import Checkbox from 'modules/core/componentsLegacy/Checkbox';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';

import {
  CARD_MODE_LABELS,
  CARD_MODES,
  LARGE_CARD_MODE,
  LIST_MODE_GROUPED,
  LIST_MODE_LABELS,
  LIST_MODE_NOT_GROUPED,
  LIST_MODES,
  SMALL_CARD_MODE,
} from './constants';

const CandidacyListModeSelect = ({
  candidacyType,
  cardModeChange,
  cardModeValue,
  componentId,
  listModeChange,
  listModeValue,
}) => (
  <OverlayTrigger
    overlay={
      <Tooltip id={`${componentId}-tooltip`}>
        {candidacyType} Card Preferences
      </Tooltip>
    }
    placement='top'
  >
    <Dropdown
      className='CandidacyListModeSelect'
      id={componentId}
      pullRight={true}
    >
      <Dropdown.Toggle
        className='CandidacyListModeSelect__button'
        noCaret={true}
      >
        <div className='CandidacyListModeSelect__button_icons'>
          <i className='fa fa-bars' />
          <span>&nbsp;</span>
          <i className='fa fa-caret-down' />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className='CandidacyListModeSelect__menu'>
        <li className='dropdown-header' key='header'>
          {candidacyType} Card Settings
        </li>
        <li className='divider' key='seperator' role='separator' />
        {LIST_MODES.map(listMode => (
          <li className='CandidacyListModeSelect__item' key={listMode}>
            <div className='CandidacyListModeSelect__checkbox checkbox'>
              <label
                className={`CandidacyListModeSelect__label CandidacyListModeSelect__${listMode}`}
                htmlFor={listMode}
              >
                <Checkbox
                  checked={listMode === listModeValue}
                  className='inline-form-control'
                  id={listMode}
                  name={listModeValue}
                  onChange={listModeChange}
                  value={listMode}
                />
                {LIST_MODE_LABELS[listMode]}
              </label>
            </div>
          </li>
        ))}
        <li className='divider' key='cardModeSeperator' role='separator' />
        {CARD_MODES.map(cardMode => (
          <li className='CandidacyListModeSelect__item' key={cardMode}>
            <div className='CandidacyListModeSelect__checkbox checkbox'>
              <label
                className={`CandidacyListModeSelect__label CandidacyListModeSelect__${cardMode}`}
                htmlFor={cardMode}
              >
                <Checkbox
                  checked={cardMode === cardModeValue}
                  className='inline-form-control'
                  id={cardMode}
                  onChange={cardModeChange}
                  value={cardMode}
                />
                {CARD_MODE_LABELS[cardMode]}
              </label>
            </div>
          </li>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </OverlayTrigger>
);

CandidacyListModeSelect.propTypes = {
  candidacyType: PropTypes.string,

  /**
   * Callback called when the selected card mode changes.
   */
  cardModeChange: PropTypes.func,
  /**
   * The currently selected card mode
   */
  cardModeValue: PropTypes.oneOf(CARD_MODES).isRequired,

  componentId: PropTypes.string.isRequired,

  /**
   * Callback called when the selected list mode changes.
   */
  listModeChange: PropTypes.func,

  /**
   * The currently selected list mode
   */
  listModeValue: PropTypes.oneOf(LIST_MODES).isRequired,
};

export default compose(
  setDisplayName('CandidacyListModeSelect(enhanced)'),
  setPropTypes({
    cardModeChange: PropTypes.func,
    cardModeValue: PropTypes.oneOf(CARD_MODES).isRequired,
    onListChange: PropTypes.func,
    listModeValue: PropTypes.oneOf(LIST_MODES).isRequired,
  }),
  withComponentId(),
  withHandlers({
    listModeChange: ({ listModeValue, onListChange }) => event => {
      if (event.currentTarget.value !== listModeValue) {
        onListChange(
          event.currentTarget.value === LIST_MODE_GROUPED
            ? LIST_MODE_GROUPED
            : LIST_MODE_NOT_GROUPED,
        );
      }
    },
    cardModeChange: ({ cardModeValue, onCardChange }) => event => {
      if (event.currentTarget.value !== cardModeValue) {
        onCardChange(
          event.currentTarget.value === LARGE_CARD_MODE
            ? LARGE_CARD_MODE
            : SMALL_CARD_MODE,
        );
      }
    },
  }),
)(CandidacyListModeSelect);

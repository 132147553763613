import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import FilterCount from './FilterCount';

/**
 * Renders the panel that contains the filters which can be used to filter which
 * tasks are displayed in the dashboard view.
 */

const TASK_FILTERS = [
  {
    title: 'Open Tasks',
    key: 'open',
  },
  {
    title: 'Due Today',
    key: 'dueToday',
  },
  {
    title: 'Overdue',
    key: 'overdue',
  },
  {
    title: 'Completed Tasks',
    key: 'completed',
  },
];

class TaskFilters extends Component {
  handleFilterClick = event => {
    const { onFilterClick } = this.props;
    onFilterClick(event.currentTarget.getAttribute('data-filter'));
  };

  handleHeaderClick = e => {
    const { onSortChange } = this.props;
    onSortChange(e.target.dataset.sortField);
  };

  render() {
    const { currentFilter, currentView, filterText, taskIds } = this.props;
    return (
      <div className='tasks-filters'>
        <div className='tasks-filter-header'>
          <h3>Status</h3>
        </div>
        {TASK_FILTERS.map(filter => {
          const isSelected = filter.key === currentFilter;
          return (
            <div
              className={classnames('tasks-filter-row', {
                selected: isSelected,
              })}
              key={filter.key}
            >
              <button
                className='btn btn-link'
                data-filter={filter.key}
                onClick={this.handleFilterClick}
                type='button'
              >
                <span className='pull-left'>{filter.title}</span>
                <span className='tasks-filter-count pull-right'>
                  <FilterCount
                    currentFilter={filter.key}
                    currentView={currentView}
                    filterText={filterText}
                    taskIds={taskIds}
                  />
                </span>
              </button>
            </div>
          );
        })}
      </div>
    );
  }
}

TaskFilters.propTypes = {
  /**
   * The currently selected filter.
   */
  currentFilter: PropTypes.string.isRequired,

  currentView: PropTypes.string.isRequired,

  filterText: PropTypes.string.isRequired,

  /**
   * What to do when a filter is clicked.
   */
  onFilterClick: PropTypes.func.isRequired,

  onSortChange: PropTypes.func.isRequired,

  taskIds: ImmutablePropTypes.listOf(PropTypes.number),
};

export default TaskFilters;

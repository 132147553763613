import fetchNotifications from './fetchNotifications';

export default function fetchLatest(notifications) {
  return dispatch => {
    if (notifications.getIn(['meta', 'isFetchingNew'])) {
      return false;
    }

    const category = notifications.getIn(['meta', 'category']);

    if (notifications.has('data')) {
      const newest = notifications
        .get('data')
        .sortBy(notification => notification.getIn(['data', 'sequential_id']))
        .last();

      if (newest) {
        return dispatch(
          fetchNotifications({
            category: category,
            after: newest.getIn(['data', 'id']),
          }),
        );
      }
    }

    // Nothing loaded yet at all, so get the first page if it isn't already being fetched.
    if (!notifications.getIn(['meta', 'isFetching'])) {
      return dispatch(fetchNotifications({ category: category }));
    }

    return false;
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import TextAreaField from 'modules/forms/components/TextAreaField';
import * as validators from 'modules/forms/validators';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import AddressField from 'modules/addresses/components/AddressField';
import DomainField from 'components/forms/DomainField';
import EmailField from '../../../components/forms/EmailField';
/**
 * A field for updating the customer configuration for a tenant.
 */

// converts the input to a number so that the isInteger validator evaluates the input
// as a number instead of a string.
const convertInputToNumber = value => {
  if (typeof value === 'string') {
    return value.trim().length ? parseInt(value) : null;
  }

  return value;
};

const CompanyDetailsFields = ({
  disabled,
  fieldState,
  handleNestedFieldChange,
  showErrors,
}) => {
  const hasCandidatePortalEnabled = useFeatureCheck('feature.candidate_portal');
  const hasCaslEnabled = useFeatureCheck('feature.casl');
  const hasDemographicsEnabled = useFeatureCheck('feature.diversity');

  const candidatePortalOrCasl = hasCandidatePortalEnabled || hasCaslEnabled;

  return (
    <div className='CustomerDetailsFields'>
      <InputField
        disabled={disabled}
        fieldState={fieldState.getNestedField('name')}
        label='*Company Name'
        onChange={handleNestedFieldChange}
        readOnly={true}
        showErrors={showErrors}
      />
      <AddressField
        fieldState={fieldState.getNestedField('address_attributes')}
        onChange={handleNestedFieldChange}
        shouldRequireFields={hasCaslEnabled}
      />
      <InputField
        disabled={disabled}
        fieldState={fieldState.getNestedField('phone')}
        label={`${hasCaslEnabled ? '*' : ''}Phone`}
        onChange={handleNestedFieldChange}
        showErrors={showErrors}
      />
      <h3> Website Links </h3>
      <InputField
        disabled={disabled}
        fieldState={fieldState.getNestedField('website_url')}
        label={`${candidatePortalOrCasl ? '*' : ''}Company URL`}
        onChange={handleNestedFieldChange}
        showErrors={showErrors}
      />
      <InputField
        disabled={disabled}
        fieldState={fieldState.getNestedField('privacy_policy_url')}
        label={`${candidatePortalOrCasl ? '*' : ''}Privacy Policy URL`}
        onChange={handleNestedFieldChange}
        showErrors={showErrors}
      />
      {hasCandidatePortalEnabled && (
        <>
          <h3>Candidate Portal</h3>
          <InputField
            disabled={disabled}
            fieldState={fieldState.getNestedField('invitation_subject')}
            label='Invitation Subject'
            onChange={handleNestedFieldChange}
            showErrors={showErrors}
          />
          <TextAreaField
            disabled={disabled}
            fieldState={fieldState.getNestedField(
              'candidate_portal_invite_message',
            )}
            label='Invitation Message'
            onChange={handleNestedFieldChange}
            showErrors={showErrors}
          />
          {hasDemographicsEnabled && (
            <TextAreaField
              disabled={disabled}
              fieldState={fieldState.getNestedField(
                'demographics_justification_message',
              )}
              label='Demographics Justification Message'
              onChange={handleNestedFieldChange}
              showErrors={showErrors}
            />
          )}
        </>
      )}
      <h3>Export Notification</h3>
      <p>
        Get email notifications when a user exports Contact, Company, or Search
        data more times than the threshold you set below.
      </p>
      <div className='row'>
        <div className='col-sm-3'>
          <InputField
            disabled={disabled}
            fieldState={fieldState.getNestedField(
              'excessive_exports_notification_threshold',
            )}
            label='Export Threshold'
            onChange={handleNestedFieldChange}
            showErrors={showErrors}
          />
        </div>
        <p className='u-paddingTop-24'>CSV exports within 24 hours.</p>
      </div>
      <EmailField
        clearable={false}
        fieldState={fieldState.getNestedField(
          'excessive_exports_notification_emails',
        )}
        label='Notification Recipients'
        onChange={handleNestedFieldChange}
        showErrors={true}
        wrapperClassName='CustomerDetailsFields__notificationEmails'
      />
      <DomainField
        clearable={false}
        fieldState={fieldState.getNestedField(
          'excessive_exports_notification_whitelisted_email_domains',
        )}
        label='Whitelisted Email Domains'
        onChange={handleNestedFieldChange}
        showErrors={true}
      />
    </div>
  );
};

CompanyDetailsFields.propTypes = {
  disabled: PropTypes.bool,
  fieldState: fieldStatePropType.isRequired,
  handleNestedFieldChange: PropTypes.func.isRequired,
  showErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default compose(
  setDisplayName('CompanyDetailsFields(enhanced'),
  setPropTypes({
    fieldState: CompanyDetailsFields.propTypes.fieldState,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic(
    'createFieldState',
    (
      name,
      { customerConfiguration, hasCandidatePortalEnabled, hasCaslEnabled },
      validator,
    ) => {
      const values = fromJS({
        name: '',
        privacy_policy_url: '',
        website_url: '',
        phone: '',
        address: [],
        invitation_subject: '',
        candidate_portal_invite_message: '',
        demographics_justification_message: '',
      }).merge(customerConfiguration);

      const candidatePortalOrCasl = hasCandidatePortalEnabled || hasCaslEnabled;

      const childFields = [
        InputField.createFieldState('name', values.get('name')),
        AddressField.createFieldState(
          'address_attributes',
          values.get('address'),
          null,
          hasCaslEnabled,
        ),
        InputField.createFieldState(
          'website_url',
          values.get('website_url') || '',
          candidatePortalOrCasl && validators.isUrl,
        ),
        InputField.createFieldState(
          'phone',
          values.get('phone'),
          hasCaslEnabled && validators.requiredField('Phone'),
        ),
        InputField.createFieldState(
          'privacy_policy_url',
          values.get('privacy_policy_url'),
          candidatePortalOrCasl &&
            validators.requiredField('privacy_policy_url'),
        ),
        InputField.createFieldState(
          'invitation_subject',
          values.get('invitation_subject'),
        ),
        TextAreaField.createFieldState(
          'candidate_portal_invite_message',
          values.get('candidate_portal_invite_message'),
        ),
        TextAreaField.createFieldState(
          'demographics_justification_message',
          values.get('demographics_justification_message'),
        ),
        EmailField.createFieldState(
          'excessive_exports_notification_emails',
          values.get('excessive_exports_notification_emails')?.toArray(),
        ),
        DomainField.createFieldState(
          'excessive_exports_notification_whitelisted_email_domains',
          values
            .get('excessive_exports_notification_whitelisted_email_domains')
            ?.toArray(),
        ),
        InputField.createFieldState(
          'excessive_exports_notification_threshold',
          values.get('excessive_exports_notification_threshold'),
          validators.combineValidators(
            validators.isInteger,
            validators.greaterThan(0),
          ),
          convertInputToNumber,
        ),
      ];

      return FieldState.createNested(name, childFields, validator);
    },
  ),

  withNestedFieldChangeHandler,
)(CompanyDetailsFields);

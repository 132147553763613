import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import skillSchema from '../schemas/skills';

/**
 * A tag select component for choosing tags from the list of skills.
 */

const SkillTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField {...props} schema={skillSchema} />
);

SkillTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default SkillTagSelectField;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import withAssessmentListFetched from 'modules/assessments/components/withAssessmentListFetched';
import {
  PARENT_CANDIDACY,
  TYPE_CLIENT,
  TYPE_RECRUITER,
  TYPE_RESEARCHER,
} from 'modules/assessments/constants';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import canCreateGuestAssessmentInviteSelector from 'modules/candidacies/selectors/canCreateGuestAssessmentInvite';
import mapCandidacyIdToSearchType from 'modules/candidacies/components/mapCandidacyIdToSearchType';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import { TYPE_JOB_SEARCH, TYPE_TALENT_POOL } from 'modules/searches/constants';

import CandidacyAssessmentsGroup from './CandidacyAssessmentsGroup';
import CandidacyCreateGuestAssessmentLinkButton from '../guestAssessments/CandidacyCreateGuestAssessmentLinkButton';

/**
 * Renders a panel for displaying a candidacies assessments.
 * These are grouped by assessment type (TYPE_RECRUITER, then
 * TYPE_CLIENT) and also renders a button for inviting guest assessments
 * if the current user has permission.
 */
const CandidacyAssessmentsPanelLegacy = ({
  assessmentList,
  canCreateGuestAssessmentInvite,
  candidacyId,
  handleRefreshAssessments,
  hasResearcherAssessments,
  searchId,
  searchType,
}) => {
  const isFetching =
    assessmentList && assessmentList.getIn(['_meta', 'isFetching']);
  const error = assessmentList && assessmentList.getIn(['_meta', 'error']);

  return (
    <div className='CandidacyAssessmentsPanelLegacy'>
      {error && !isFetching && (
        <ErrorAlert
          onRetry={handleRefreshAssessments}
          title='There was an error fetching assessments'
        />
      )}
      {canCreateGuestAssessmentInvite && searchType === TYPE_JOB_SEARCH && (
        <CandidacyCreateGuestAssessmentLinkButton
          candidacyId={candidacyId}
          searchId={searchId}
        />
      )}
      {hasResearcherAssessments &&
        // Don't show researcher assessments for talent pools
        searchType === TYPE_JOB_SEARCH && (
          <CandidacyAssessmentsGroup
            candidacyId={candidacyId}
            searchId={searchId}
            type={TYPE_RESEARCHER}
          />
        )}
      <CandidacyAssessmentsGroup
        candidacyId={candidacyId}
        searchId={searchId}
        type={TYPE_RECRUITER}
      />
      {searchType === TYPE_JOB_SEARCH && (
        <CandidacyAssessmentsGroup
          candidacyId={candidacyId}
          searchId={searchId}
          type={TYPE_CLIENT}
        />
      )}
      {isFetching && <LoadingIndicator />}
    </div>
  );
};

CandidacyAssessmentsPanelLegacy.propTypes = {
  /**
   * The assessments state for the current candidacy, used to display a
   * loading indicator if we are currently fetching.
   */
  assessmentList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  /**
   * Whether the current user has permission to create guest assessment invites.
   */
  canCreateGuestAssessmentInvite: PropTypes.bool.isRequired,

  candidacyId: PropTypes.number.isRequired,

  handleRefreshAssessments: PropTypes.func.isRequired,

  hasResearcherAssessments: PropTypes.bool.isRequired,

  searchId: PropTypes.number,

  /**
   * The type of search the candidacy is associated with.
   * For talent pools, we do not show client assessments.
   * Note: this is not required so that we can render even if we are still waiting for the
   * current search to load.
   * @type {[type]}
   */
  searchType: PropTypes.oneOf([TYPE_JOB_SEARCH, TYPE_TALENT_POOL]),
};

export default compose(
  setDisplayName('CandidacyAssessmentsPanelLegacy(enhanced)'),

  // The connected version of this component only requires a candidacyId,
  // everything else will be fetched from state.
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),

  // Look up whether the user can create guest assessment invites.
  connect(
    state => ({
      canCreateGuestAssessmentInvite: canCreateGuestAssessmentInviteSelector(
        state,
      ),

      // Used by `withAssessmentListFetched`, below.
      parentType: PARENT_CANDIDACY,
    }),
    {},
  ),

  // Will provide (and fetch, if needed) the `assessmentsList` prop, given a `candidacyId` and
  // `parentType` (above).
  withAssessmentListFetched({
    parentIdPropName: 'candidacyId',
    fetchAssessmentsListPropName: 'handleRefreshAssessments',
  }),
  withFeatureCheck(
    'feature.researcher_assessments',
    'hasResearcherAssessments',
  ),

  // Look up the current search type (TYPE_JOB_SEARCH or TYPE_TALENT_POOL)
  // from the candidacy ID.
  mapCandidacyIdToSearchType,
)(CandidacyAssessmentsPanelLegacy);

import PropTypes from 'prop-types';
import React from 'react';

function EditButton({ onClick }) {
  return (
    <button
      className='editable-section-toggle btn btn-link'
      key='add-edit'
      onClick={onClick}
      type='button'
    >
      <i className='fa fa-pencil' />
    </button>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EditButton;

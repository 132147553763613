import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import classnames from 'classnames';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';

import TargetCompanyVoteForm from './TargetCompanyVoteForm';

const TargetCompanyVoteButton = ({
  className,
  componentId,
  disabled,
  formState,
  getScrollContainer,
  getTargetRef,
  handleVoteCancel,
  handleVoteSaved,
  isFormOpen,
  onClick,
  onClose,
  onFormChange,
  priorityCount,

  // withScrollContainerRef props
  setTargetRef,
  size,
  targetCompanyId,
}) => (
  <div className={className}>
    <button
      aria-expanded={isFormOpen}
      aria-haspopup='true'
      className={classnames('btn', {
        'btn-default': priorityCount === 0,
        'btn-toggle': priorityCount > 0,
        active: priorityCount > 0,
        [`btn-${size}`]: Boolean(size),
      })}
      disabled={Boolean(disabled)}
      onClick={onClick}
      ref={setTargetRef}
      type='button'
    >
      <i className='fa fa-thumbs-up btn-icon' />
      <span className='btn-label'>Priority</span>
      <span className='btn-label'>
        {' '}
        {priorityCount > 0 ? '+' : ''}
        {priorityCount}
      </span>
    </button>
    <Overlay
      animation={false}
      container={getScrollContainer}
      containerPadding={20}
      id={`${componentId}-overlay`}
      onHide={onClose}
      placement='bottom'
      rootClose={true}
      show={isFormOpen}
      target={getTargetRef}
    >
      <Popover
        className='candidacy-vote-button-popover'
        id={`${componentId}-popover`}
        title='Add a comment'
      >
        <TargetCompanyVoteForm
          errorDisplay='tooltip'
          initialFormState={formState}
          onCancel={handleVoteCancel}
          onChange={onFormChange}
          onSaved={handleVoteSaved}
          targetCompanyId={targetCompanyId}
        />
      </Popover>
    </Overlay>
  </div>
);

TargetCompanyVoteButton.propTypes = {
  /**
   * Props provided by withScrollContainerRef (getScrollContainer, getTargetRef, setTargetRef)
   */
  ...withScrollContainerRef.propTypes,

  className: PropTypes.string,

  componentId: PropTypes.string.isRequired,

  disabled: PropTypes.bool,

  /**
   * True when the user is actively voting
   * (to display the popover form)
   */
  isFormOpen: PropTypes.bool,

  /**
   * Called when the vote button is clicked.
   */
  onClick: PropTypes.func,

  /**
   * Called when the overlay is closed.
   */
  onClose: PropTypes.func.isRequired,

  /**
   * Called when the underlying FormState is changed.
   */
  onFormChange: PropTypes.func.isRequired,

  /**
   * The total number of priority votes for this company.
   */
  priorityCount: PropTypes.number.isRequired,

  /**
   * The button size to render
   */
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),

  targetCompanyId: PropTypes.number.isRequired,
};

export default compose(
  setDisplayName('TargetCompanyVoteButton(enhanced)'),
  setPropTypes({
    onClose: PropTypes.func.isRequired,
  }),
  withStateHandlers(
    { formState: null },
    {
      onFormChange: () => formState => ({ formState: formState }),
      handleVoteCancel: (_, { onClose }) => formState => {
        onClose();
        return { formState: formState };
      },
      handleVoteSaved: (_, { onClose }) => () => {
        onClose();
        return { formState: null };
      },
    },
  ),
  withComponentId(),
  withScrollContainerRef,
)(TargetCompanyVoteButton);

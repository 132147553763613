import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ExternalLink from 'modules/links/ExternalLink';
import mapContactIdToContact from './mapContactIdToContact';

const PROPERTY_NAMES = {
  references: 'references_doc',
  resume: 'resume',
};

const DEFAULT_LABELS = {
  references: 'References',
  resume: 'Resume',
};

export default compose(
  setDisplayName('ContactDocumentLink'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['resume', 'references']).isRequired,
  }),
  mapContactIdToContact,
  mapProps(
    ({
      children,
      className,
      contact,
      contactId: _contactId,
      type,
      ...props
    }) => ({
      ...props,
      className: `ContactDocumentLink ContactDocumentLink--${type} ${className}`,
      children: children || DEFAULT_LABELS[type],
      href: contact && contact.getIn([PROPERTY_NAMES[type], 'url']),
    }),
  ),
  branch(({ href }) => !href, renderNothing),
)(ExternalLink);

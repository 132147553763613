import React from 'react';
import NotificationTaskStatusButton from './NotificationTaskStatusButton';
import NotificationIntegrationStatusIcon from './NotificationIntegrationStatusIcon';

const NotificationStatusButton = props => [
  <NotificationTaskStatusButton key='notification-task-status' {...props} />,
  <NotificationIntegrationStatusIcon
    key='notification-integration-status'
    {...props}
  />,
];

export default NotificationStatusButton;

import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import React from 'react';
import PropTypes from 'prop-types';
import Notification from './Notification';

const Introduction = ({ notification }) => {
  const introductionMessage = (
    <a href={`/contacts/${notification?.resource?.introduced_to_id}`}>
      {notification?.message}
    </a>
  );
  return (
    <Notification
      introMessage={introductionMessage}
      notification={notification}
    >
      <ExpandableText
        characterLimit={150}
        content={notification?.resource?.notes}
        isSanitizedHtml={true}
      />
    </Notification>
  );
};

Introduction.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string,
    resource: PropTypes.shape({
      introduced_to_id: PropTypes.number,
      notes: PropTypes.string,
    }),
  }),
};

export default Introduction;

import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import Avatar from '@thrivetrm/ui/components/Avatar';
import getUserPermission from 'modules/auth/selectors/getUserPermission';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import getCandidacyProperty from 'modules/candidacies/selectors/getCandidacyProperty';
import isFetchingCandidacy from 'modules/candidacies/selectors/isFetchingCandidacy';
import CandidacyActionButtons from 'modules/candidacies/components/CandidacyActionButtons';
import CandidacyStageDropdown from 'modules/candidacies/components/CandidacyStageDropdown';
import CandidacyJobApplicationIndicator from 'modules/candidacies/components/CandidacyJobApplicationIndicator';
import ConnectionsButtonOverlay from 'modules/connections/components/ConnectionsButtonOverlay';
import mapCandidacyIdToContactId from 'modules/candidacies/components/mapCandidacyIdToContactId';
import mapCandidacyIdToJobApplicationId from 'modules/candidacies/components/mapCandidacyIdToJobApplicationId';
import withCandidacyFetched from 'modules/candidacies/components/withCandidacyFetched';
import ContactIconLinkList from 'modules/contacts/components/ContactIconLinkList';
import ContactLink from 'modules/contacts/components/ContactLink';
import ContactName from 'modules/contacts/components/ContactName';
import ContactAddressAs from 'modules/contacts/components/ContactAddressAs';
import ContactPrimaryPosition from 'modules/contacts/components/positions/ContactPrimaryPosition';
import ContactRelationshipManagerLink from 'modules/contacts/components/ContactRelationshipManagerLink';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import TargetCompanyIndicatorIcon from 'modules/target-companies/components/TargetCompanyIndicatorIcon';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import OffLimitsIndicator from 'modules/off-limits/OffLimitsIndicator';
import getOffLimits from 'modules/contacts/selectors/contacts/getOffLimits';
import getFullName from 'modules/contacts/selectors/contacts/getFullName';
import getCandidacy from 'modules/candidacies/selectors/getCandidacy';
import canDownloadCandidacySummary from 'modules/candidacies/selectors/canDownloadCandidacySummary';
import isHiringManagerUser from 'modules/auth/selectors/isHiringManagerUser';
import getContactProperty from 'modules/contacts/selectors/contacts/getContactProperty';
import getContact from 'modules/contacts/selectors/contacts/getContact';
import useQuickView from 'modules/quick-view/useQuickView';
import getAvatarUrl from 'modules/contacts/selectors/contacts/getAvatarUrl';
import CandidacySummaryReportModal from './CandidacySummaryReportModal';

/**
 * The header component that is always shown in the candidacy sidebar, regardless
 * of which tab is selected.
 */
const CandidacySearchSidebarHeader = ({
  canViewProfile,
  candidacyId,
  contactId,
  isFetching,
  isFullAccessUser,
  isPrimaryCompanyTargeted,
  jobApplicationId,
}) => {
  const [
    showCandidacyReportModal,
    _turnOnCandidacyReportModal,
    _turnOffCandidacyReportModal,
    toggleCandidacyReportModal,
  ] = useToggle(false);

  const offLimits = useSelector(state => getOffLimits(state, contactId));
  const isFullAccessOrAdminUser = useSelector(state => isEmployeeUser(state));
  const isCrmUser = useSelector(isCrmUserSelector);

  const contactFullName = useSelector(state => getFullName(state, contactId));

  const candidacy = useSelector(state => getCandidacy(state, candidacyId));

  const addressAs = useSelector(state =>
    getContactProperty(state, contactId, 'address_as'),
  );

  const canDownloadSummary = useSelector(state =>
    canDownloadCandidacySummary(state),
  );

  const canDownloadCandidateSummary = isHiringManagerUser || canDownloadSummary;

  const shouldUseQuickView = isFullAccessOrAdminUser || isCrmUser;
  const displayOffLimitsStatus =
    offLimits &&
    candidacy &&
    candidacy.get('display_off_limits_reason') &&
    shouldUseQuickView;

  const { navigateTo } = useQuickView();

  const connectionsCount = useSelector(state =>
    getContact(state, contactId)?.get('connections_count'),
  );

  const contactImageUrl = useSelector(state => getAvatarUrl(state, contactId));

  const contactAvatar = (
    <Avatar
      hoverIcon={shouldUseQuickView ? 'flash' : null}
      image={contactImageUrl}
      name={contactFullName}
      onClick={
        shouldUseQuickView
          ? () => {
              navigateTo(`/contacts/${contactId}`, { title: contactFullName });
            }
          : null
      }
      shape='circle'
      size='small'
    />
  );

  return (
    <div className='CandidacySearchSidebarHeader'>
      {contactId && (
        <div className='CandidacySearchSidebarHeader__top'>
          <div className='CandidacySearchSidebarHeader__avatar_job_icon'>
            {connectionsCount && shouldUseQuickView ? (
              <ConnectionsButtonOverlay
                connectionsCount={connectionsCount}
                contactId={contactId}
              >
                {contactAvatar}
              </ConnectionsButtonOverlay>
            ) : (
              contactAvatar
            )}
            {jobApplicationId && <CandidacyJobApplicationIndicator />}
          </div>
          <div className='CandidacySearchSidebarHeader__titles u-marginHorizontal-8'>
            <h4 className='u-marginVertical-4'>
              <ContactName contactId={contactId} />
              <ContactAddressAs
                addressAs={addressAs}
                className='u-marginLeft-4 u-inlineBlock'
              />
            </h4>
            <ContactPrimaryPosition
              companyPrefix={
                isPrimaryCompanyTargeted && <TargetCompanyIndicatorIcon />
              }
              contactId={contactId}
            />
            {displayOffLimitsStatus && (
              <OffLimitsIndicator
                reason={offLimits.get('status')}
                recordId={contactId}
                recordName={contactFullName}
                recordType='contact'
                severity={offLimits.get('category')}
              />
            )}
          </div>
          {canDownloadCandidateSummary && (
            <TooltipTrigger
              placement='top'
              tooltip='Download Candidate Summary Report'
            >
              <button
                className='CandidacySearchSidebarHeader__summaryDownloadButton btn btn-sm btn-link'
                data-testid='candidacy-search-summary-download-button'
                onClick={toggleCandidacyReportModal}
                type='button'
              >
                <i className='fa fa-2x fa-download' />
              </button>
            </TooltipTrigger>
          )}
        </div>
      )}
      {contactId && (
        <div className='CandidacySearchSidebarHeader__bottom u-inlineBlock'>
          <CandidacyActionButtons candidacyId={candidacyId} />
        </div>
      )}
      <div className='CandidacySearchSidebarHeader__ContactLinks'>
        {contactId && (
          <ContactIconLinkList
            className='u-inlineBlock u-paddingTop-8'
            contactId={contactId}
            shouldShowContactSource={true}
          />
        )}
        {contactId && canViewProfile && (
          <ContactLink
            className='CandidacySearchSidebarHeader__ContactProfileLink'
            contactId={contactId}
            target='_blank'
            useDefaultLinkBehavior={true}
          >
            <i className='fa fa-user' />
          </ContactLink>
        )}
      </div>
      {contactId && <CandidacyStageDropdown candidacyId={candidacyId} />}
      {isFullAccessUser && contactId && (
        <ContactRelationshipManagerLink
          className='u-marginBottom-8'
          contactId={contactId}
          label='Relationship Manager:'
        />
      )}
      {isFetching && !contactId && <LoadingIndicator />}
      {canDownloadCandidateSummary && showCandidacyReportModal && (
        <CandidacySummaryReportModal
          candidacyId={candidacyId}
          onHide={toggleCandidacyReportModal}
        />
      )}
    </div>
  );
};

CandidacySearchSidebarHeader.propTypes = {
  /**
   * The ID of the candidacy we're rendering.
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * Whether the current user is able to see the candidacy profile
   * Determines if the profile link is rendered.
   */
  canViewProfile: PropTypes.bool,

  /**
   * The contact ID that the candidacy belongs to.
   */
  contactId: PropTypes.number,

  /**
   * Whether we are currently rendering data (determines if the
   * LoadingIndicator is shown)
   */
  isFetching: PropTypes.bool,

  isFullAccessUser: PropTypes.bool,

  /**
   * Whether the candidacy's primary company is a "target" company for the search
   * it belongs to.
   */
  isPrimaryCompanyTargeted: PropTypes.bool,

  jobApplicationId: PropTypes.number,
};

CandidacySearchSidebarHeader.defaultProps = {
  canViewProfile: false,
  isFetching: false,
  isPrimaryCompanyTargeted: false,
};

export default compose(
  setDisplayName('CandidacySearchSidebarHeader(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  withCandidacyFetched,
  mapCandidacyIdToContactId,
  mapCandidacyIdToJobApplicationId,
  connect(
    (state, { candidacyId }) => ({
      canViewProfile: getUserPermission(state, 'can_view_contact'),
      isFetching: isFetchingCandidacy(state, candidacyId),
      isFullAccessUser: isEmployeeUser(state),
      isPrimaryCompanyTargeted: getCandidacyProperty(
        state,
        candidacyId,
        'is_primary_company_targeted',
      ),
    }),
    {},
  ),
)(CandidacySearchSidebarHeader);

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApiGet } from 'modules/core/hooks/useApi';
import routes from 'modules/routing/routes';

import CandidacyPositionsPanel from 'modules/candidacies/components/CandidacyPositionsPanel';

import getContact from 'modules/contacts/selectors/contacts/getContact';
import getCandidacySearchId from 'modules/candidacies/selectors/getCandidacySearchId';
import getSearchProperty from 'modules/searches/selectors/getSearchProperty';
import isFetchingCandidacy from 'modules/candidacies/selectors/isFetchingCandidacy';
import isFetchingContact from 'modules/contacts/selectors/contacts/isFetchingContact';

import ContactCareerHighlights from 'modules/contacts/components/ContactCareerHighlights';
import ContactCompensationSummary from 'modules/contacts/components/ContactCompensationSummary';
import ContactDocumentLink from 'modules/contacts/components/ContactDocumentLink';
import ContactEducations from 'modules/contacts/components/educations/ContactEducations';
import ContactEmailLink from 'modules/contacts/components/links/ContactEmailLink';
import ContactPhoneLink from 'modules/contacts/components/links/ContactPhoneLink';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import CandidacyExtraDocumentsMenu from './CandidacyExtraDocumentsMenu';

/**
 * Renders the "Overview" tab of the candidacy search sidebar.
 */
const CandidacySearchSidebarOverview = ({ candidacyId, contactId }) => {
  // Object Selectors
  const searchId = useSelector(state =>
    getCandidacySearchId(state, candidacyId),
  );
  const contact = useSelector(state => getContact(state, contactId)).toJS();
  const useDesiredCompensation = useSelector(state =>
    getSearchProperty(state, searchId, 'use_desired_compensation'),
  );

  // Boolean Selectors
  const isCandidacyFetching = useSelector(state =>
    isFetchingCandidacy(state, candidacyId),
  );
  const isContactFetching = useSelector(state =>
    isFetchingContact(state, contactId),
  );
  const isEmployee = useSelector(state => isEmployeeUser(state));
  const isFetching = isCandidacyFetching || isContactFetching;

  const [
    loadDocuments,
    isLoadingDocuments,
    _documentLoadError,
    documents,
  ] = useApiGet(
    routes.api_v1_contact_documents({
      contact_id: contactId,
      query: {
        shared: true,
      },
    }),
  );

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  // Primary documents have dedicated buttons. The rest get shoved in a [...] menu.
  const extraDocuments = documents?.documents?.filter(
    document => !document.primary,
  );

  return contactId ? (
    <div className='CandidacySearchSidebarOverview'>
      <h3>Contact Information</h3>
      <ul className='CandidacySearchSidebarOverview__contactLinks fa-ul'>
        <ContactEmailLink
          componentClass='li'
          contactId={contactId}
          fallbackToPersonalEmail={isEmployee}
          iconClassName='fa-li'
          tooltip={false}
          type='preferred_email'
        />
        <ContactPhoneLink
          componentClass='li'
          contactId={contactId}
          iconClassName='fa-li'
          tooltip={false}
          type='phone'
        />
        <ContactPhoneLink
          componentClass='li'
          contactId={contactId}
          iconClassName='fa-li fa-lg'
          tooltip={false}
          type='mobile_phone'
        />
      </ul>
      {isLoadingDocuments ? <LoadingIndicator /> : null}{' '}
      {documents?.documents?.length ? (
        <>
          <h3>Documents</h3>
          <div
            className='CandidacySearchSidebarOverview__documents'
            key='documents'
          >
            <ContactDocumentLink
              className='CandidacySearchSidebarOverview__resume btn btn-sm btn-secondary'
              contactId={contactId}
              type='resume'
            />
            <ContactDocumentLink
              className='btn btn-sm btn-secondary-outline'
              contactId={contactId}
              type='references'
            />
            {contact && extraDocuments?.length ? (
              <CandidacyExtraDocumentsMenu
                documents={extraDocuments}
                isPinnedRight={Boolean(contact.references_doc)}
              />
            ) : null}
          </div>
        </>
      ) : null}
      <ContactCompensationSummary
        contactId={contactId}
        useDesiredCompensation={useDesiredCompensation}
      />
      <ContactCareerHighlights
        contactId={contactId}
        wrapHighlightsWithCard={true}
      />
      <CandidacyPositionsPanel
        contactId={contactId}
        readOnly={true}
        renderIfNoData={false}
        searchId={searchId}
      />
      <ContactEducations
        allowCreate={false}
        contactId={contactId}
        readOnly={true}
        renderDescriptions={false}
        renderIfNoData={false}
        renderInsideACard={true}
        renderSchools={true}
        renderTitles={false}
        title={<h3>Education</h3>}
      />
      {isFetching ? <LoadingIndicator /> : null}
    </div>
  ) : null;
};

CandidacySearchSidebarOverview.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
};

export default CandidacySearchSidebarOverview;

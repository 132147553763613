import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FieldState from 'modules/forms/FieldState';
import ProvinceField from './ProvinceField';
import StateSelectField from './StateSelectField';
import CanadaProvinceSelectField from './CanadaProvinceSelectField';
import { COUNTRY_CODE_US, COUNTRY_CODE_CA } from '../constants';

/**
 * A field for editing either a state or province, depending on the value of the `isUS` prop.
 * When `isUS` is true, a drop down selector of US states will be shown; otherwise a text input
 * will be used.
 */
export default class StateOrProvinceField extends PureComponent {
  static createFieldState = FieldState.create;

  render() {
    const { countryCode, ...props } = this.props;
    if (!countryCode || countryCode === COUNTRY_CODE_US) {
      return <StateSelectField {...props} />;
    }
    if (countryCode === COUNTRY_CODE_CA) {
      return <CanadaProvinceSelectField {...props} />;
    }
    return <ProvinceField {...props} />;
  }
}

StateOrProvinceField.propTypes = {
  countryCode: PropTypes.string,
};

StateOrProvinceField.defaultProps = {
  countryCode: COUNTRY_CODE_US,
};

import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import compensationSchema from '../schema';

/**
 * Creates an action for updating a compensation record.
 * @param {Object} payload
 * @param {Number} contactId The ID of the contact this record belongs to.
 * @param {Object} payload.compensation The compensation record to update
 * @param {Number} payload.compensation.id The ID of the comment to update
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ compensation, contactId, transactionId }) =>
  updateEntity({
    body: { compensation: compensation },
    transactionId: transactionId,
    entityType: compensationSchema.key,
    responseSchema: compensationSchema,
    url: routes.api_v1_contact_compensation({
      id: compensation.id,
      contactId: contactId,
    }),
  });

import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@thrivetrm/ui/components/Icon';

const TabButton = ({
  count,
  icon,
  isFocused,
  isSelected,
  label,
  onClick,
  setFocus,
}) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (isFocused) {
      buttonRef.current.focus();
    }
  }, [isFocused]);

  const buttonClass = classnames('Tab__button', {
    'Tab__button--isSelected': isSelected,
  });

  const buttonLabelClass = classnames('Tab__buttonLabel', {
    'Tab__buttonLabel--isSelected': isSelected,
  });

  const buttonBadgeClass = classnames('Tab__buttonBadge', {
    'Tab__buttonBadge--isSelected': isSelected,
  });

  const buttonIconClass = classnames('Tab__buttonIcon', {
    'Tab__buttonIcon--isSelected': isSelected,
  });

  return (
    <button
      aria-controls={`tabpanel-${label}`}
      aria-selected={isSelected}
      className={buttonClass}
      id={`tab-${label}`}
      key={label}
      onClick={() => onClick(label)}
      onKeyDown={e => setFocus(e, label)}
      ref={buttonRef}
      role='tab'
      type='button'
    >
      {icon ? <Icon className={buttonIconClass} type={icon} /> : null}
      {label ? <span className={buttonLabelClass}>{label}</span> : null}
      {!icon && Number.isFinite(count) ? (
        <span className={buttonBadgeClass}>{count}</span>
      ) : null}
    </button>
  );
};

TabButton.defaultProps = {
  count: null,
  icon: null,
  isFocused: false,
  isSelected: false,
  setFocus: null,
};

TabButton.propTypes = {
  count: PropTypes.number,
  icon: PropTypes.string,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  setFocus: PropTypes.func,
};

export default TabButton;

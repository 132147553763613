import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  compose,
  withState,
  setStatic,
  onlyUpdateForPropTypes,
} from 'recompose';

import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';

import MultiSelect from '../../../components/forms/multiselect/MultiSelect';

import mapUserIdsToUsers from './mapUserIdsToUsers';
import withUserListFetched from './withUserListFetched';
import getName from '../selectors/getName';

export class UserMultiSelect extends Component {
  state = {
    searchTerm: '',
  };

  handleSearchValueChange = searchTerm => {
    this.setState({ searchTerm: searchTerm.trim().toLowerCase() });
  };

  render() {
    const {
      noResultsText,
      userList,
      users,

      ...props
    } = this.props;

    const isLoading = userList.getIn(['_meta', 'isFetching']);
    const { searchTerm } = this.state;

    const filteredUsers =
      searchTerm && users
        ? users.filter(user =>
            user.get('name').toLowerCase().includes(searchTerm),
          )
        : users;

    return (
      <MultiSelect
        {...props}
        isLoading={isLoading}
        noSearchResultsText={isLoading ? 'Loading...' : noResultsText}
        noValueText='No selection'
        onSearchValueChange={this.handleSearchValueChange}
        options={filteredUsers ? filteredUsers.toJS() : []}
        showWithoutSearching={true}
      />
    );
  }
}

UserMultiSelect.propTypes = {
  noResultsText: PropTypes.string,

  onChange: PropTypes.func.isRequired,

  userList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  users: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

UserMultiSelect.defaultProps = {
  noResultsText: 'No matching users found',
};

export default compose(
  setStatic(
    'createFieldState',
    (name, value, validator, convertToRaw, ...args) =>
      FieldState.create(
        name,

        // Make sure the value is an array and not an immutable list.
        List.isList(value) ? value.toJS() : value || [],

        validator,

        // This convertToRaw implementation makes sure that this field value is an array of IDs
        fieldValue => fieldValue.map(idOrObject => idOrObject.id || idOrObject),

        ...args,
      ),
  ),
  withUserListFetched,
  mapUserIdsToUsers,
  withFormGroup,
  asField(),
  connect((state, { value }) => ({
    // queriesById: getQueriesById(state),
    // optionsFromData: (data => (data ? data.map(id => getSearch(state, id)).toJS() : [])),

    // Make sure the value being passed into the control is an array of objects with `{ id, name }`
    // and not just an array of IDs (which it likely is initially) -- without the id/name pair
    // the select control won't know how to render the user's name.
    value: value
      ? value.map(id =>
          typeof id === 'number' ? { id: id, name: getName(state, id) } : id,
        )
      : [],
  })),
  withState('searchTerm', 'updateSearchTerm'),
  onlyUpdateForPropTypes,
)(UserMultiSelect);

import { connect } from 'react-redux';
import { compose, defaultProps, setDisplayName } from 'recompose';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  updateAvatar as updateAvatarCreator,
  updateContact as updateCreator,
} from '../../../actions/contacts';
import AvatarUploaderButton from './AvatarUploaderButton';

const handleSubmit = (
  { contactActions, contactId },
  { avatarFile },
  transactionId,
) => {
  const avatarPayload = {
    contact: {
      avatar: avatarFile,
    },
    contactId: contactId,
    transactionId: transactionId,
  };

  if (avatarFile) {
    contactActions.updateAvatar(avatarPayload);
  } else {
    contactActions.updateContact({
      ...avatarPayload,
      contact: { remove_avatar: true },
    });
  }
};

export default compose(
  setDisplayName('ContactAvatarUploaderButton'),
  defaultProps({
    onSubmit: handleSubmit,
  }),
  connectTransactions,
  connect(null, dispatch => ({
    contactActions: bindActionCreators(
      {
        updateContact: updateCreator,
        updateAvatar: updateAvatarCreator,
      },
      dispatch,
    ),
  })),
)(AvatarUploaderButton);

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchIntroductionList as fetchIntroductionListActionCreator } from '../actions';
import { PARENT_TYPES } from '../constants';
import getIntroductionList from '../selectors/getIntroductionList';
import getshouldFetchIntroductionList from '../selectors/shouldFetchIntroductionList';

/**
 * When provided a parentId, parentType via props, fetches the introductions
 * for that parent if they need to be fetched so they are available for a component.
 */
export default compose(
  setDisplayName('withIntroductionListFetched'),
  setPropTypes({
    parentId: PropTypes.number,
    parentType: PropTypes.oneOf(PARENT_TYPES),
  }),
  connect(
    (state, { parentId, parentType }) => ({
      shouldFetchIntroductionList: getshouldFetchIntroductionList(
        state,
        parentType,
        parentId,
      ),
      introductionList: getIntroductionList(state, parentType, parentId),
    }),
    dispatch => ({
      fetchIntroductionList: bindActionCreators(
        fetchIntroductionListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    /**
     * A handler for fetching the introduction list specified by the `parentId` and `parentType`
     * props. This allows the component to simply call `fetchIntroductionList()` without having to
     * supply params.
     */
    fetchIntroductionList: ({
      fetchIntroductionList,
      parentId,
      parentType,
    }) => () => {
      fetchIntroductionList({ parentId: parentId, parentType: parentType });
    },
    /**
     * A handler that, when called, only fetches the introduction list if needed.
     */
    fetchIntroductionListIfNeeded: ({
      fetchIntroductionList,
      parentId,
      parentType,
      shouldFetchIntroductionList,
    }) => () =>
      shouldFetchIntroductionList &&
      fetchIntroductionList({ parentId: parentId, parentType: parentType }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchIntroductionListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchIntroductionListIfNeeded();
    },
  }),
);

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';

import AddressField from 'modules/addresses/components/AddressField';
import RadioSelectField from 'modules/forms/components/RadioSelectField';
import BooleanRadioSelectField from 'modules/forms/components/BooleanRadioSelectField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';
import ExternalLink from 'modules/links/ExternalLink';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import placementTypes from 'modules/tenant/schemas/placementTypes';
import jobFunctions from 'modules/tenant/schemas/jobFunctions';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';

import getJobListingUrl from '../selectors/getJobListingUrl';
import RichTextField, {
  requiredField as requiredRichTextField,
} from '../../../components/forms/richtext/RichTextField';

const PUBLISH_WARNING = 'This will publish the job listing to Thrive Apply.';
const UNPUBLISH_WARNING = 'This will remove the job listing from Thrive Apply.';

class JobListingField extends Component {
  static createFieldState(name = 'job-listing', { jobListing, search }) {
    // When there is no jobListing.id, fallback to pre-populating the FieldState with the
    // attributes from the search record.
    const hasJobListing = jobListing && jobListing.get('id');

    const values = new Map({
      description: '',
      hidden: null,
      job_function: null,
      job_title: '',
      placement_type_id: '',
      priority: 'medium',
      published_status: 'unpublished',
    }).merge(hasJobListing ? jobListing : search);

    return FieldState.createNested(
      name,
      [
        InputField.createFieldState(
          'job_title',
          values.get('job_title'),
          validators.requiredField('Job Title'),
        ),
        RichTextField.createFieldState(
          'description',
          values.get('description'),
          requiredRichTextField('Description', 'is'),
        ),
        TenantOptionsSelectField.createFieldState(
          'job_function',
          values.get('job_function'),
          validators.requiredField('Job Function'),
        ),
        TenantOptionsSelectField.createFieldState(
          'placement_type_id',
          values.get('placement_type_id'),
          validators.requiredField('Placement Type'),
        ),
        AddressField.createFieldState('address', values),
        RadioSelectField.createFieldState(
          'published_status',
          values.get('published_status'),
        ),
        RadioSelectField.createFieldState('priority', values.get('priority')),
        BooleanRadioSelectField.createFieldState(
          'voluntary_identification_status',
          values.get('voluntary_identification_status'),
        ),
      ],
      null,
      ({ address, job_function, ...rest }) => ({
        job_function_category_id: job_function,
        ...rest,
        ...address,
      }),
    );
  }

  handleFieldChange = nestedFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(nestedFieldState));
  };

  render() {
    const {
      draft,
      fieldState,
      hasDiversity,
      jobListing,
      jobListingUrl,
      onDraftChange,
      ...otherProps
    } = this.props;

    const publishedDate = jobListing && jobListing.get('published_date');
    const isUnpublished =
      fieldState.getNestedFieldValue('published_status') === 'unpublished';
    const isPublishedAndVisible =
      fieldState.getNestedFieldValue('published_status') === 'published';
    const isPublishedAndHidden =
      fieldState.getNestedFieldValue('published_status') === 'published_hidden';
    const isPublished = isPublishedAndVisible || isPublishedAndHidden;
    const showPublishWarning = !publishedDate && isPublished;
    const showUnpublishWarning = publishedDate && !isPublished;
    const showWarning = showPublishWarning || showUnpublishWarning;
    const warning = showPublishWarning ? PUBLISH_WARNING : UNPUBLISH_WARNING;
    const disablePrioritySelect = isUnpublished || isPublishedAndHidden;

    return (
      <div className='section'>
        <h2>Job Details</h2>
        <div className='row'>
          <div className='col-sm-6'>
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('job_title')}
              key='job_title'
              label='*Job Title'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className='col-sm-6'>
            <TenantOptionsSelectField
              {...otherProps}
              className='PlacementTypeSelectField'
              fieldState={fieldState.getNestedField('placement_type_id')}
              key='placement_type_id'
              label='*Placement Type'
              name='placement_type_id'
              onChange={this.handleFieldChange}
              schema={placementTypes}
            />
          </div>
          <div className='col-sm-6'>
            <TenantOptionsSelectField
              {...otherProps}
              fieldState={fieldState.getNestedField('job_function')}
              key='job_function'
              label='*Job Function'
              onChange={this.handleFieldChange}
              schema={jobFunctions}
            />
          </div>
          <div className='col-sm-12'>
            <AddressField
              {...otherProps}
              fieldState={fieldState.getNestedField('address')}
              onChange={this.handleFieldChange}
            />
          </div>
          <div className='col-sm-12'>
            <RichTextField
              {...otherProps}
              fieldState={fieldState.getNestedField('description')}
              label='*Job Description'
              lastUpdatedTime={draft?.lastUpdatedTime}
              onChange={this.handleFieldChange}
              onDraftChange={onDraftChange}
              placeholder='Enter job description...'
              shouldShowLinkOption={true}
            />
          </div>
        </div>
        <h2>Thrive Apply Posting Status</h2>
        <div className='row'>
          <div className='col-sm-12'>
            <RadioSelectField
              className='JobListingField-select'
              fieldState={fieldState.getNestedField('published_status')}
              inline={false}
              onChange={this.handleFieldChange}
              options={[
                { label: 'Unpublished', value: 'unpublished' },
                { label: 'Published', value: 'published' },
                {
                  label: 'Published (Hidden - applicant needs link to view)',
                  value: 'published_hidden',
                },
              ]}
              warning={showWarning && warning}
            />
            {isPublished && publishedDate && (
              <p className='help-block'>
                Published to{' '}
                <ExternalLink href={jobListingUrl}>
                  {jobListingUrl && jobListingUrl.replace(/^https?:\/\//, '')}
                </ExternalLink>
              </p>
            )}
          </div>
        </div>
        <h4>Job Listing Order</h4>
        <div className='row'>
          <div className='col-sm-12'>
            <RadioSelectField
              className='JobListingField-select'
              disabled={disablePrioritySelect}
              fieldState={fieldState.getNestedField('priority')}
              inline={false}
              onChange={this.handleFieldChange}
              options={[
                {
                  label: 'Standard (ordered by publish date)',
                  value: 'medium',
                },
                { label: 'High Priority (pinned to top)', value: 'high' },
                { label: 'Low Priority (pinned to bottom)', value: 'low' },
              ]}
            />
          </div>
        </div>
        {hasDiversity ? (
          <>
            <h4>Voluntary Self Identification</h4>
            <div className='row'>
              <div className='col-sm-12'>
                <BooleanRadioSelectField
                  className='JobListingField-select'
                  falseLabel='Off'
                  fieldState={fieldState.getNestedField(
                    'voluntary_identification_status',
                  )}
                  firstOption={false}
                  inline={false}
                  onChange={this.handleFieldChange}
                  trueLabel='On'
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

JobListingField.propTypes = {
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  hasDiversity: PropTypes.bool,

  jobListing: ImmutablePropTypes.mapContains({
    published_date: PropTypes.string,
  }),

  jobListingUrl: PropTypes.string,

  onChange: PropTypes.func.isRequired,

  onDraftChange: PropTypes.func,
};

JobListingField.defaultProps = {
  draft: null,
};

export default compose(
  setDisplayName('JobListingField(enhanced)'),
  setPropTypes({
    jobListing: ImmutablePropTypes.mapContains({
      id: PropTypes.number,
    }),
  }),
  withFeatureCheck('feature.diversity', 'hasDiversity'),
  connect(
    (state, { jobListing }) => ({
      jobListingUrl: getJobListingUrl(state, jobListing.get('id')),
    }),
    {},
  ),
)(JobListingField);

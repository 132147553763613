import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';

import getPrimaryCompanyName from 'modules/contacts/selectors/contacts/getPrimaryCompanyName';
import getCompanyName from 'modules/companies/selectors/getCompanyName';

import getClientCompanyId from '../selectors/getClientCompanyId';
import mapContactIdToTalentPoolId from './mapContactIdToTalentPoolId';

/**
 * Renders the company name for a contact's talent pool; or the contact's primary company name
 * if it has no associated talent pool.
 */
export const CompanyName = ({ companyName }) => (
  <span className='talent-pools--company-name'>{companyName}</span>
);

CompanyName.propTypes = {
  companyName: PropTypes.string,
};

export default compose(
  setDisplayName('connect(CompanyName)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToTalentPoolId,
  connect(
    (state, { contactId, talentPoolId }) => ({
      companyName: talentPoolId
        ? getCompanyName(state, getClientCompanyId(state, talentPoolId))
        : getPrimaryCompanyName(state, contactId),
    }),
    {},
  ),
  onlyUpdateForPropTypes,
)(CompanyName);

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@thrivetrm/ui/components/Icon';
import Avatar from '@thrivetrm/ui/components/Avatar';
import Card from '@thrivetrm/ui/components/Card';

const DuplicateMergeRecordCard = ({
  avatarShape,
  avatarUrl,
  fullName,
  isLoading,
  onClear,
  primaryCompanyName,
  primaryTitle,
}) => (
  <Card
    className='DuplicateMergeRecordCard'
    data-testid={`${fullName}-DuplicateMergeRecordCard`}
    isCentered={false}
  >
    {!isLoading && fullName && (
      <>
        <Avatar
          className='u-marginRight-16'
          image={avatarUrl}
          name={fullName}
          shape={avatarShape}
          size='large'
        />
        <div className='DuplicateMergeRecordCard__contactInfo'>
          <h3 className='u-margin-n'>{fullName}</h3>
          <div className='u-marginTop-8'>
            <div className='DuplicateMergeRecordCard__infoItem'>
              {primaryTitle}
            </div>
            <div className='DuplicateMergeRecordCard__infoItem'>
              {primaryCompanyName}
            </div>
          </div>
        </div>
        {onClear && (
          <Icon
            className='DuplicateMergeRecordCard__closeIcon'
            onClick={onClear}
            size='small'
            type='close'
          />
        )}
      </>
    )}
  </Card>
);

DuplicateMergeRecordCard.defaultProps = {
  avatarUrl: null,
  fullName: null,
  isLoading: false,
  onClear: null,
  primaryCompanyName: null,
  primaryTitle: null,
};

DuplicateMergeRecordCard.propTypes = {
  avatarShape: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  fullName: PropTypes.string,
  isLoading: PropTypes.bool,
  onClear: PropTypes.func,
  primaryCompanyName: PropTypes.string,
  primaryTitle: PropTypes.string,
};

export default DuplicateMergeRecordCard;

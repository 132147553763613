import { NAME } from '../constants';

/**
 * Gets a value indicating whether we should fetch the locations list
 * @param {Object} state
 * @return {Boolean} True if the locations should be requested from the server; otherwise, false.
 */
export default state => {
  const locations = state[NAME];

  if (!locations.size) {
    return true;
  }

  if (locations.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (locations.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !locations.has('ids') ||
    Boolean(locations.getIn(['_meta', 'isInvalidated']))
  );
};

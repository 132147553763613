import { normalize } from 'normalizr';
import routes from 'modules/routing/routes';
import createAjaxAction from '../createAjaxAction';
import { contactSchema } from '../../modules/contacts/schema';
import {
  CONTACTS_FETCH_START,
  CONTACTS_FETCH_SUCCESS,
  CONTACTS_FETCH_FAILURE,
} from './constants';

export const getUrl = ({ filter, includes }) =>
  routes.api_v1_contacts({
    query: { includes: includes, filter: filter, results_per_page: 100 },
  });

const fetchContact = createAjaxAction({
  getUrl: getUrl,
  createSuccessPayload: (payload, data) => ({
    ...payload,
    ...normalize(data, { contacts: [contactSchema] }),
  }),
  start: CONTACTS_FETCH_START,
  success: CONTACTS_FETCH_SUCCESS,
  failure: CONTACTS_FETCH_FAILURE,
});

export default fetchContact;

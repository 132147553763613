import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import SchemeEnsuredExternalLink from 'modules/links/SchemeEnsuredExternalLink';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import CompanySnapshot from 'modules/companies/components/CompanySnapshot';
import ContactIconLinkList from 'modules/contacts/components/ContactIconLinkList';
import BooleanText from '@thrivetrm/ui/components/BooleanText';
import CommaSeparatedList from '@thrivetrm/ui/components/CommaSeparatedList';
import Currency from '@thrivetrm/ui/components/Currency';
import CurrencyRange from '@thrivetrm/ui/components/CurrencyRange';
import Date from '@thrivetrm/ui/components/Date';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import Integer from '@thrivetrm/ui/components/Integer';
import Percentage from '@thrivetrm/ui/components/Percentage';
import PercentageRange from '@thrivetrm/ui/components/PercentageRange';
import Rating from '@thrivetrm/ui/components/Rating';
import SearchEditButton from 'modules/searches/components/SearchEditButton';
import AddRecruitmentRatingButton from 'modules/company-searches/AddRecruitmentRatingButton';
import ContactCurrentlyReportsTo from 'modules/contacts/components/ContactCurrentlyReportsTo';
import CommaSeparatedLinkList from './CommaSeparatedLinkList';
import PopoverTrigger from '../componentsLegacy/PopoverTrigger';
import TagList from './TagList';
import Tag from './Tag';

const TableCell = ({ data, isFixed }) => {
  const getContent = () => {
    switch (data.type) {
      case 'boolean':
        return <BooleanText value={data.value} />;
      case 'contact_primary_identifier':
        return (
          <ContactSnapshot
            addressAs={data.address_as}
            avatarUrl={data.avatar}
            connectionsCount={data.connections_count}
            id={data.id}
            name={data.value}
            offLimitsReason={data.off_limits.status}
            offLimitsSeverity={data.off_limits.category}
            shouldUseQuickView={true}
            url={data.path}
          />
        );
      case 'contact_average_rating':
        return (
          <Rating
            totalReviewers={data.number_of_ratings}
            value={data.average_rating}
          />
        );
      case 'company_primary_identifier':
        return (
          <CompanySnapshot
            id={data.id}
            name={data.value}
            offLimitsReason={data.off_limits.status}
            offLimitsSeverity={data.off_limits.category}
            url={data.path}
          />
        );
      case 'contact_social_media':
        return <ContactIconLinkList contactData={data} />;
      case 'pipeline_primary_identifier':
      case 'search_primary_identifier':
        return (
          <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween'>
            <a className='TableCell__content--link' href={data.path}>
              {data.value}
            </a>
            <SearchEditButton url={data.edit_path} />
          </div>
        );
      case 'currency':
        return <Currency currency={data.currency} value={data.value} />;
      case 'currency_range':
        return (
          <CurrencyRange
            currency={data.currency}
            maxValue={data.maximum_value}
            minValue={data.minimum_value}
            value={data.value}
          />
        );
      case 'currently_reports_to':
        return (
          <ContactCurrentlyReportsTo
            contactId={data.id}
            contactName={data.full_name}
            reportsTo={data.value}
          />
        );
      case 'date':
        return <Date date={data.value} />;
      case 'document_popover':
        return (
          data.value.length > 0 && (
            <PopoverTrigger
              content={data.value.map(value => (
                <div key={value.label}>
                  <span>{value && value.label}</span>
                  <div>
                    {value.documents &&
                      value.documents.map(document => (
                        <ul
                          className='document-container__list u-margin-n'
                          key={document.file_name}
                        >
                          <li>
                            <div className='u-flex'>
                              <a
                                className='u-block u-truncate'
                                href={document.href}
                                rel='noopener noreferrer'
                                target='_blank'
                                title={document.file_name}
                              >
                                {document.file_name}
                              </a>
                              {document.expired ? (
                                <span className='u-paddingLeft-2'>
                                  (expired)
                                </span>
                              ) : null}
                            </div>
                          </li>
                        </ul>
                      ))}
                  </div>
                </div>
              ))}
              placement='bottom'
              rootClose={true}
              title='Documents'
              trigger='click'
            >
              <span>
                <i className='fa fa-file-text-o u-marginRight-4' />
                <span className='btn-link'>
                  (
                  {data?.value
                    .map(documentType => documentType.documents.length)
                    .reduce(
                      (runningTotal, documentCount) =>
                        runningTotal + documentCount,
                    )}
                  )
                </span>
              </span>
            </PopoverTrigger>
          )
        );
      case 'email':
        return (
          <a
            className='TableCell__content--link'
            href={`mailto:${data.value}`}
            rel='noopener noreferrer'
            target='_blank'
          >
            {data.value}
          </a>
        );
      case 'external_link':
        return (
          data.value && (
            <SchemeEnsuredExternalLink href={data.value}>
              {data.value}
            </SchemeEnsuredExternalLink>
          )
        );
      case 'internal_link':
        return <a href={data.path}>{data.value}</a>;
      case 'link_list':
        return (
          <CommaSeparatedLinkList
            className='search-documents-popover'
            items={data.value}
            limit={data.number_of_list_items}
          />
        );
      case 'list':
      case 'search_team':
        return (
          <CommaSeparatedList
            items={data.value}
            limit={data.number_of_list_items}
            tooltipPosition={data.tooltip_position}
            tooltipSize={data.tooltip_size}
          />
        );
      case 'numeric':
        return <Integer value={data.value} />;
      case 'phone_link':
        return (
          <a
            className='TableCell__content--link'
            href={`tel:${data.value}`}
            rel='noopener noreferrer'
            target='_blank'
          >
            {data.value}
          </a>
        );
      case 'percentage':
        return <Percentage value={data.value} />;
      case 'percentage_range':
        return (
          <PercentageRange
            maxValue={data.maximum_value}
            minValue={data.minimum_value}
          />
        );
      case 'primary_company':
        return data.path ? (
          <a href={data.path}>{data.value}</a>
        ) : (
          <p>{data.value}</p>
        );
      case 'recruitment_rating':
        return data.search_firm_id ? (
          <AddRecruitmentRatingButton
            rating={{ id: data.rating_id, value: data.value }}
            searchFirm={{
              id: data.search_firm_id,
              name: data.search_firm_name,
            }}
            searchId={data.id}
          />
        ) : null;
      case 'string':
      case 'external_id':
        return data.value;
      case 'tag':
        return data.value && <Tag name={data.value} />;
      case 'tag_list':
        return <TagList limit={data.number_of_list_items} tags={data.value} />;
      case 'text':
        return (
          data.value && (
            <ExpandableText characterLimit={32} content={data.value} />
          )
        );
      case 'user_email':
        return (
          <a
            className='TableCell__content--link'
            href={data.path}
            rel='noopener noreferrer'
            target='_blank'
          >
            {data.value}
          </a>
        );
      default:
        return null;
    }
  };
  const containerClass = classnames('TableCell', {
    'table__cell--isFixed': isFixed,
  });

  const contentClass = classnames(
    'TableCell__content',
    `TableCell__content--${data.type}`,
    `TableCell__content--${data.name}`,
  );

  return (
    <td className={containerClass}>
      <div className={contentClass}>{getContent()}</div>
    </td>
  );
};

TableCell.defaultProps = {
  isFixed: false,
};

TableCell.propTypes = {
  data: PropTypes.shape({
    address_as: PropTypes.string,
    avatar: PropTypes.string,
    average_rating: PropTypes.number,
    connections_count: PropTypes.number,
    currency: PropTypes.string,
    edit_path: PropTypes.string,
    full_name: PropTypes.string,
    id: PropTypes.number,
    maximum_value: PropTypes.number,
    minimum_value: PropTypes.number,
    name: PropTypes.string.isRequired,
    number_of_list_items: PropTypes.number,
    number_of_ratings: PropTypes.number,
    off_limits: PropTypes.shape({
      category: PropTypes.string,
      status: PropTypes.string,
    }),
    path: PropTypes.string,
    rating_id: PropTypes.number,
    search_firm_id: PropTypes.number,
    search_firm_name: PropTypes.string,
    tooltip_position: PropTypes.string,
    tooltip_size: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.bool,
      ImmutablePropTypes.mapContains({
        label: PropTypes.string,
        documents: PropTypes.array,
      }),
    ]),
  }).isRequired,
  isFixed: PropTypes.bool,
};

export default TableCell;

import React from 'react';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import Form from '@thrivetrm/ui/components/Form';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import { useGetRecruitmentRatingsQuery } from 'services/apiV1/recruitmentRatings';
import { useGetCompanyQuery } from 'services/apiV1/companies';
import RecruitmentRatingCard from './RecruitmentRatingCard';
import RecruitmentRatingOverallRating from './RecruitmentRatingOverallRating';
import useQuickView from '../useQuickView';
import QuickViewPanelHeader from '../QuickViewPanelHeader';
import { QuickViewEmptyState, QuickViewErrorState } from '../QuickViewState';

const RecruitmentRatingsList = () => {
  const { close, pathParams, title } = useQuickView();
  const { companyId } = pathParams;
  const {
    data: recruitmentRatingsList,
    error: ratingError,
    isLoading: isLoadingRecruitmentRating,
  } = useGetRecruitmentRatingsQuery(companyId);
  const {
    data: companyData,
    error: loadCompanyError,
    isLoading: isLoadingCompany,
  } = useGetCompanyQuery(companyId);
  const isLoading = isLoadingCompany || isLoadingRecruitmentRating;

  return (
    <>
      <SidePanel.Header
        className='u-marginBottom-12'
        onClose={close}
        title={title}
      />
      <QuickViewPanelHeader title='Recruitment Rating' />
      <Form onSubmit={_formState => {}}>
        <SidePanel.Body className='u-paddingTop-n' data-testid='sidepanel-body'>
          <LoadingContainer
            className='u-paddingBottom-24'
            isLoading={isLoadingRecruitmentRating || isLoadingCompany}
          >
            <RecruitmentRatingOverallRating
              overallRatingValue={companyData?.overallRating || 0}
              totalReviewersCount={recruitmentRatingsList?.length}
            />
            {!isLoading && recruitmentRatingsList?.length > 0
              ? recruitmentRatingsList.map(rating => (
                  <RecruitmentRatingCard
                    key={rating.id}
                    recruitmentRating={rating}
                  />
                ))
              : null}

            {!isLoading && (ratingError || loadCompanyError) ? (
              <QuickViewErrorState image='envelope' />
            ) : null}

            {!isLoading && !recruitmentRatingsList?.length && companyData ? (
              <QuickViewEmptyState
                heading={`${companyData?.name} doesn’t have any Recruitment Ratings yet.`}
                image='starWithMagnifier'
                subHeading={
                  <>
                    Add a Recruitment Rating for {companyData?.name} by closing
                    this side panel and clicking on{' '}
                    <b className='u-noWrap'>+ Add Rating</b> on a search listed
                    under Recruitment Activity
                  </>
                }
              />
            ) : null}
          </LoadingContainer>
        </SidePanel.Body>
      </Form>
    </>
  );
};

export default RecruitmentRatingsList;

import PropTypes from 'prop-types';
import React from 'react';
import Measure from 'react-measure';
import selectn from 'selectn';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import mapCandidacyIdToProperty from 'modules/candidacies/components/mapCandidacyIdToProperty';
import CandidacyActionButtonsV2 from 'modules/candidacies/components/CandidacyActionButtonsV2';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { useSelector } from 'react-redux';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import CandidacyListItemSectionAssessmentLegacy from './sections/CandidacyListItemSectionAssessmentLegacy';
import CandidacyListItemSectionCore from './sections/CandidacyListItemSectionCore';
import CandidacyListItemSectionAssessments from './sections/CandidacyListItemSectionAssessments';

/**
 * The height of this item when rendered in the list.
 * @const {Number}
 */
export const HEIGHT = 160;

/**
 * The width in which we should hide the assessment section -
 * when the sidebar is expanded.
 * @const {Number}
 */
const ASSESSMENT_MINIMUM_WIDTH = 500;

/**
 * Renders the redesigned candidacy list item, which is broken out into 2 parts: the "core"
 * content, and an optional assessment section which is shown when `show_large_card` is true
 * for the current candidacy's stage and the candidate has a recruiter_assessment.
 */
const CandidacyListItemV2 = ({
  allowRanking,
  assessmentId,
  candidacyId,
  firstCandidacyId,
  height,
  lastCandidacyId,
  nextCandidacyId,
  previousCandidacyId,
  searchId,
}) => {
  const hasAssessmentTemplates = useFeatureCheck(
    'feature.assessment_templates',
  );
  const searchType = useSelector(state => getSearchType(state, searchId));

  return (
    <Measure bounds={true}>
      {({ contentRect, measureRef }) => (
        <div
          className='CandidacyListItemV2'
          ref={measureRef}
          style={{ height: height }}
        >
          <CandidacyListItemSectionCore
            candidacyId={candidacyId}
            className='CandidacyListItemV2__core'
            searchId={searchId}
          />
          {selectn('bounds.width', contentRect) > ASSESSMENT_MINIMUM_WIDTH &&
          searchType !== TYPE_PIPELINE &&
          assessmentId &&
          !hasAssessmentTemplates ? (
            <CandidacyListItemSectionAssessmentLegacy
              candidacyId={candidacyId}
              className='CandidacyListItemV2__assessment'
              maxOptions={5}
              showMoreTop={true}
            />
          ) : null}

          {hasAssessmentTemplates && searchType !== TYPE_PIPELINE ? (
            <CandidacyListItemSectionAssessments candidacyId={candidacyId} />
          ) : null}

          <CandidacyActionButtonsV2
            allowRanking={allowRanking}
            candidacyId={candidacyId}
            className='CandidacyListItemV2__action_buttons'
            firstCandidacyId={firstCandidacyId}
            lastCandidacyId={lastCandidacyId}
            nextCandidacyId={nextCandidacyId}
            previousCandidacyId={previousCandidacyId}
            searchType={searchType}
            shouldRenderAssessmentActions={searchType !== TYPE_PIPELINE}
          />
        </div>
      )}
    </Measure>
  );
};

CandidacyListItemV2.propTypes = {
  /**
   * whether or not to enable candidate ranking
   */
  allowRanking: PropTypes.bool,

  /**
   * The ID of the candidacy's recruiter_assessment, used to
   * determine whether or not to show the assessment section.
   */
  assessmentId: PropTypes.number,

  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * ID of first candidacy in list or group
   */
  firstCandidacyId: PropTypes.number,

  /**
   * The height of the item in pixels when rendering.
   */
  height: PropTypes.number,

  /**
   * ID of last candidacy in list or group
   */
  lastCandidacyId: PropTypes.number,

  /**
   * ID of next candidacy in list or group
   */
  nextCandidacyId: PropTypes.number,

  /**
   * ID of previous candidacy in list or group
   */
  previousCandidacyId: PropTypes.number,
  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,
};

CandidacyListItemV2.defaultProps = {
  height: HEIGHT,
};

CandidacyListItemV2.HEIGHT = HEIGHT;

export default compose(
  setDisplayName('CandidacyListItemV2(enhanced)'),
  setPropTypes({
    /**
     * The ID of the candidacy
     */
    candidacyId: PropTypes.number.isRequired,
  }),
  mapCandidacyIdToProperty('recruiter_assessment', 'assessmentId'),
)(CandidacyListItemV2);

//---------------------------------------------------------
// Parent Types
//---------------------------------------------------------

export const PARENT_CONTACT = 'contact';
export const PARENT_CANDIDACY = 'candidacy';

//---------------------------------------------------------
// Appointment types
//---------------------------------------------------------

export const TYPE_MEETING = 'meeting';
export const TYPE_INTERVIEW_SCHEDULING = 'scheduling';
export const TYPE_INTERVIEW_SCHEDULED = 'scheduled';

export const ALL_PERMITTED_TYPES = [
  TYPE_MEETING,
  TYPE_INTERVIEW_SCHEDULING,
  TYPE_INTERVIEW_SCHEDULED,
];

export const PERMITTED_TYPES = ALL_PERMITTED_TYPES;

export const INTERVIEW_PERMITTED_TYPES = [
  TYPE_INTERVIEW_SCHEDULING,
  TYPE_INTERVIEW_SCHEDULED,
];
export const MEETING_PERMITTED_TYPES = [TYPE_MEETING];

//---------------------------------------------------------
// ??
//---------------------------------------------------------

export const INTERVIEW_TYPE_CLIENT = 'Client';

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import getContact from 'modules/contacts/selectors/contacts/getContact';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';

const ContactSourceIcon = ({
  backgroundColor,
  color,
  contactId,
  tooltipPosition,
}) => {
  const contact = useSelector(state => getContact(state, contactId));
  const contactSource = useSelector(state =>
    getEntityProperty(
      state,
      'contactSources',
      contact.get('contact_source'),
      'name',
    ),
  );

  const icon = (
    <svg
      fill='none'
      height='14'
      style={{
        position: 'relative',
        top: '1px',
      }}
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill={backgroundColor} height='16' rx='2' width='16' />
      <path
        d='M14.5357 7.14258H13.7224C13.3497 4.63343 11.3663 2.64997 8.85711 2.27733V1.46401C8.85711 1.2865 8.71319 1.14258 8.53568 1.14258H7.46425C7.28674 1.14258 7.14282 1.2865 7.14282 1.46401V2.27733C4.63367 2.64997 2.65022 4.63343 2.27757 7.14258H1.46425C1.28674 7.14258 1.14282 7.2865 1.14282 7.46401V8.53544C1.14282 8.71294 1.28674 8.85686 1.46425 8.85686H2.27757C2.65022 11.366 4.63367 13.3495 7.14282 13.7221V14.5354C7.14282 14.7129 7.28674 14.8569 7.46425 14.8569H8.53568C8.71319 14.8569 8.85711 14.7129 8.85711 14.5354V13.7221C11.3663 13.3495 13.3497 11.366 13.7224 8.85686H14.5357C14.7132 8.85686 14.8571 8.71294 14.8571 8.53544V7.46401C14.8571 7.2865 14.7132 7.14258 14.5357 7.14258ZM8.85711 11.981V10.8926C8.85711 10.7151 8.71319 10.5711 8.53568 10.5711H7.46425C7.28674 10.5711 7.14282 10.7151 7.14282 10.8926V11.981C5.58459 11.6488 4.3513 10.4169 4.0187 8.85686H5.10711C5.28462 8.85686 5.42854 8.71294 5.42854 8.53544V7.46401C5.42854 7.2865 5.28462 7.14258 5.10711 7.14258H4.0187C4.35089 5.58435 5.58274 4.35105 7.14282 4.01845V5.10686C7.14282 5.28437 7.28674 5.42829 7.46425 5.42829H8.53568C8.71319 5.42829 8.85711 5.28437 8.85711 5.10686V4.01845C10.4153 4.35065 11.6486 5.5825 11.9812 7.14258H10.8928C10.7153 7.14258 10.5714 7.2865 10.5714 7.46401V8.53544C10.5714 8.71294 10.7153 8.85686 10.8928 8.85686H11.9812C11.649 10.4151 10.4172 11.6484 8.85711 11.981ZM8.85711 7.99972C8.85711 8.4731 8.47335 8.85686 7.99997 8.85686C7.52658 8.85686 7.14282 8.4731 7.14282 7.99972C7.14282 7.52634 7.52658 7.14258 7.99997 7.14258C8.47335 7.14258 8.85711 7.52634 8.85711 7.99972Z'
        fill={color}
      />
    </svg>
  );

  /**
   * In case this icon appears on a page where this data does not exist in state,
   * don't render a tooltip with only "Source:" in it
   */
  return contactSource ? (
    <Tooltip
      content={
        <>
          <p className='u-fontSize-small u-margin-n u-marginTop-4'>
            <b>Source:</b>
          </p>
          <p className='u-fontSize-small u-margin-n u-marginBottom-4'>
            {contactSource}
          </p>
        </>
      }
      position={tooltipPosition}
    >
      {icon}
    </Tooltip>
  ) : (
    icon
  );
};

ContactSourceIcon.defaultProps = {
  backgroundColor: '#666',
  color: '#FFF',
  tooltipPosition: 'top',
};

ContactSourceIcon.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipPosition: Tooltip.propTypes.position,
};

export default ContactSourceIcon;

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  withProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  branch,
  renderNothing,
} from 'recompose';
import isAdmin from 'modules/auth/selectors/isAdmin';
import isCandidate from 'modules/auth/selectors/isCandidateUser';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import withContactCurrentCompanyIds from 'modules/contacts/components/withContactCurrentCompanyIds';
import EditableSection from 'modules/forms/components/EditableSection';
import getDefaultCompany from 'modules/tenant/selectors/getDefaultCompany';
import ContactDiversityForm from './ContactDiversityForm';
import ContactDiversityView from './ContactDiversityView';

/**
 * Renders an EditableSection that displays and allows editing of a contact's
 * diversity related fields.
 */
export default compose(
  setDisplayName('ContactDiversityEditableSection'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  withFeatureCheck(
    'feature.diversity.hide_for_active_candidates',
    'hideDiversityIfActiveCandidate',
  ),
  withFeatureCheck(
    'feature.diversity.hide_for_default_company',
    'hideDiversityIfCurrentCompanyIsDefault',
  ),
  connect(
    state => ({
      adminUser: isAdmin(state),
      defaultCompanyId: getDefaultCompany(state),
      isCandidateUser: isCandidate(state),
    }),
    {},
  ),
  withContactCurrentCompanyIds,
  withProps(({ currentCompanyIds, defaultCompanyId }) => ({
    currentCompanyIsDefaultCompany:
      currentCompanyIds &&
      Boolean(defaultCompanyId) &&
      currentCompanyIds.filter(
        currentCompanyId => currentCompanyId === defaultCompanyId,
      ).size > 0,
  })),
  withProps(
    ({
      adminUser,
      contact,
      currentCompanyIsDefaultCompany,
      hideDiversityIfActiveCandidate,
      hideDiversityIfCurrentCompanyIsDefault,
      isCandidateUser,
    }) => ({
      hideForDefaultCompany:
        currentCompanyIsDefaultCompany &&
        hideDiversityIfCurrentCompanyIsDefault &&
        !adminUser,
      hideForActiveCandidate:
        contact.get('active_candidate') &&
        hideDiversityIfActiveCandidate &&
        !adminUser &&
        !isCandidateUser,
    }),
  ),
  branch(
    ({ hideForActiveCandidate, hideForDefaultCompany }) =>
      hideForDefaultCompany || hideForActiveCandidate,
    renderNothing,
  ),
  mapProps(({ contact, contactId, lastUpdated }) => {
    const latestUpdates = contact.get('latest_updates')?.toJS() ?? {};
    const {
      disability_status_id,
      gender_id,
      race_ethnicity_updated_at,
      sexual_orientation_id,
      veteran_status_id,
    } = latestUpdates;

    const demographicsLastUpdatedAt = {
      gender: contact.get('gender_self_reported')
        ? gender_id?.created_at
        : null,
      raceEthnicity: contact.get('race_ethnicity_self_reported')
        ? race_ethnicity_updated_at?.created_at
        : null,
      veteranStatus: contact.get('veteran_status_self_reported')
        ? veteran_status_id?.created_at
        : null,
      disabilityStatus: contact.get('disability_status_self_reported')
        ? disability_status_id?.created_at
        : null,
      sexualOrientation: contact.get('sexual_orientation_self_reported')
        ? sexual_orientation_id?.created_at
        : null,
    };

    return {
      children: ContactDiversityView.willRender({ contact: contact }) && (
        <ContactDiversityView contactId={contactId} />
      ),
      className: 'ContactDiversityEditableSection',
      contactId: contactId,
      candidateName: contact.get('full_name'),
      demographicsLastUpdatedAt: demographicsLastUpdatedAt,
      disabilityStatusSelfReported: contact.get(
        'disability_status_self_reported',
      ),
      formComponent: ContactDiversityForm,
      genderSelfReported: contact.get('gender_self_reported'),
      lastUpdated: lastUpdated,
      raceEthnicitySelfReported: contact.get('race_ethnicity_self_reported'),
      title: 'Demographics',
      sexualOrientationSelfReported: contact.get(
        'sexual_orientation_self_reported',
      ),
      veteranStatusSelfReported: contact.get('veteran_status_self_reported'),
    };
  }),
)(EditableSection);

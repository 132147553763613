import extend from 'lodash/extend';

export default ($field, options) => {
  const $hint = $('<div class="autocomplete-hint"></div>')
    .html('Please enter 3 or more characters')
    .get(0);
  $field.autocomplete(
    extend(
      {
        serviceUrl: $field.data('autocomplete-path'),
        minChars: 3,
        triggerSelectOnValidInput: false,
        groupBy: $field.data('autocomplete-groupby') || 'type',
        // recommended for remote data calls
        deferRequestBy: 500,
        showNoSuggestionNotice: true,
        noSuggestionNotice:
          $field.data('autocomplete-notice') || 'No results found',
        onSelect: suggestion => {
          if (suggestion.data != null) {
            const hiddenFieldSelector = $field.data('autocomplete-target');
            let $hiddenField = $(hiddenFieldSelector);
            // hiddenFieldSelector is either a real CSS selector or
            // it's the string nextHidden
            // When it is nextHidden it will use the very next hidden element
            // as the target.  This is necessary for has_many relationship as the target
            // id is not static
            if (hiddenFieldSelector === 'nextHidden') {
              $hiddenField = $field
                .closest('.row')
                .find('input[type=hidden]:first');
            }
            $hiddenField.val(suggestion.data.id);

            // hide has-error message and create new company checkbox from search firm name
            // once company selected
            const hasSearchFirmErrors = $('.js-new-search-firm')
              .parent()
              .hasClass('has-error');
            if (hasSearchFirmErrors && $field.val()) {
              $('.js-new-search-firm')
                .fadeOut(300)
                .parent()
                .removeClass('has-error');
              $('#create_new_company').prop('checked', false);
            }
            const secondaryTarget = $field.data(
              'autocomplete-secondary-target',
            );
            const tertiaryTarget = $field.data('autocomplete-tertiary-target');

            if (secondaryTarget && $(secondaryTarget).val() === '') {
              $(secondaryTarget).val(suggestion.data.primary_company_name);
            }

            if (tertiaryTarget && $(tertiaryTarget).val() === '') {
              $(tertiaryTarget).val(suggestion.data.primary_company_id);
            }
          }
        },
        onInvalidateSelection: ({ target }) => {
          const $target = $(target);
          const hiddenFieldSelector = $target.data('autocomplete-target');
          let $hiddenField = $(hiddenFieldSelector);

          if (hiddenFieldSelector === 'nextHidden') {
            $hiddenField = $target
              .closest('.row')
              .find('input[type=hidden]:first');
          }

          $hiddenField.val('');
        },
      },
      options || {},
    ),
  );

  const displayOrHideHint = () => {
    if ($field.val().length < 3) {
      $field.after($hint);
    } else {
      $field.nextAll('.autocomplete-hint:first').detach();
    }
  };

  $field.on('focus', ({ target }) => {
    $(target).autocomplete().onValueChange();
  });

  $field.on('blur', () => {
    $field.nextAll('.autocomplete-hint:first').detach();
  });

  $field.on('keyup change input focus', () => {
    displayOrHideHint();
  });
};

export const destroy = $field => $field.autocomplete('dispose');

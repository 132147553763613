import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@thrivetrm/ui/components/Card';
import Icon from '@thrivetrm/ui/components/Icon';
import TagList from 'modules/core/components/TagList';
import { clearLegacyFilters } from '../recordIndexSlice';

const RecordIndexSidebarFilters = ({ filterButton }) => {
  const dispatch = useDispatch();

  const { filterSummary, recordType } = useSelector(state => state.recordIndex);

  const hasFiltersApplied = filterSummary?.length > 0;

  return (
    <div
      className='u-marginVertical-24'
      data-testid='record-index-sidebar-filters'
    >
      <div className='u-flex u-flexAlign-c'>
        <h3 className='u-flexItem-grow u-padding-n u-margin-n'>Filters</h3>
        {hasFiltersApplied && (
          <button
            className='btn btn-link u-marginRight-8'
            data-testid='record index reset button'
            onClick={() => dispatch(clearLegacyFilters())}
            type='button'
          >
            Clear Filters
          </button>
        )}
        {filterButton}
      </div>
      {filterSummary?.map(filter => {
        const values = Array.isArray(filter.value)
          ? filter.value
          : [filter.value];
        return (
          <Card
            className='u-marginTop-12'
            data-testid='record index filter card'
            isCentered={false}
            key={filter.fieldName}
          >
            <h3 className='u-marginTop-n'>{filter.label}</h3>
            <TagList tags={values} />
          </Card>
        );
      })}
      {!hasFiltersApplied && (
        <Card
          className='u-marginTop-12 u-textAlign-c'
          data-backdrop='static'
          data-target='#filters'
          data-toggle='modal'
          isCentered={false}
          style={{ cursor: 'pointer' }}
          type='gray'
        >
          <Icon
            backgroundColor='white'
            className='u-marginTop-8 u-marginBottom-12'
            color='blue'
            size='medium'
            type='filter'
          />
          <h4 className='u-marginTop-n u-marginBottom-8'>Add Filters</h4>
          <p className='u-fontSize-small'>
            Quickly narrow down your list of {recordType}
          </p>
        </Card>
      )}
    </div>
  );
};

RecordIndexSidebarFilters.propTypes = {
  filterButton: PropTypes.node.isRequired,
};

export default RecordIndexSidebarFilters;

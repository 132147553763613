import PropTypes from 'prop-types';
import { compose, lifecycle, setDisplayName, setPropTypes } from 'recompose';
import withUserActions from 'modules/users/components/withUserActions';
import UserAvatarButton from 'modules/users/components/UserAvatarButton';
import { withStateProvider } from './app/StateProvider';

export default compose(
  setDisplayName('UserAvatarUploader(container)'),
  setPropTypes({
    userId: PropTypes.number,
    avatarUrl: PropTypes.string,
  }),
  withStateProvider,
  withUserActions,
  lifecycle({
    componentDidMount: function () {
      const { avatarUrl, userActions, userId } = this.props;
      if (avatarUrl && userId) {
        userActions.initializeUser({
          user: {
            id: userId,
            avatar_url: avatarUrl,
          },
        });
      }
    },
  }),
)(UserAvatarButton);

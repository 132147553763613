import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import DateRangeField from 'modules/datetime/components/DateRangeField';
import FieldState from 'modules/forms/FieldState';
import SelectField from 'modules/forms/components/SelectField';
import { FILTER_WITH_INTRODUCTION } from 'modules/companies/constants';
import CompanyMultiSelectField from 'modules/companies/components/CompanyMultiSelectField';
import { FILTER_WITH_INTRODUCTIONS } from 'modules/users/constants';
import UserSelectField from 'modules/users/components/UserSelectField';
import { ContactSelectField } from '../contact/ContactSelect';
import EmailField from '../forms/EmailField';
import { CONTACT_FILTER_WITH_INTRODUCTIONS } from '../../actions/contacts/constants';

class IntroductionReportField extends Component {
  static createFieldState(name = 'introductionReport', currentUserEmail) {
    return FieldState.createNested(name, [
      SelectField.createFieldState('format', 'csv'),
      UserSelectField.createFieldState('users'),
      ContactSelectField.createFieldState('contacts'),
      CompanyMultiSelectField.createFieldState('companies'),
      DateRangeField.createFieldState('dateRange', {
        startDate: moment().startOf('year').format('MM/DD/YYYY'),
        endDate: moment().format('MM/DD/YYYY'),
      }),
      EmailField.createFieldState('emails', currentUserEmail),
    ]);
  }

  constructor(props) {
    super(props);
    this.componentId = uniqueId();
  }

  handleFieldChange = nestedFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(nestedFieldState));
  };

  render() {
    const {
      companies,
      companiesActions,
      contacts,
      contactsActions,
      dateRangeInnerClassName,
      dateRangeWrapperClassName,
      emailWrapperClassName,
      fieldState,
    } = this.props;

    return (
      <div className='react-select'>
        <h3>Filters</h3>
        <div className='row'>
          <div className='col-sm-6'>
            <SelectField
              fieldState={fieldState.getNestedField('format')}
              key='format'
              label='Format'
              onChange={this.handleFieldChange}
            >
              <option key='csv' value='csv'>
                CSV
              </option>
              <option key='xlsx' value='xlsx'>
                XLSX
              </option>
            </SelectField>
          </div>
          <div className='col-sm-6'>
            <UserSelectField
              fieldState={fieldState.getNestedField('users')}
              filter={FILTER_WITH_INTRODUCTIONS}
              key='lead_internal_team_member_id'
              label='Users'
              multi={true}
              onChange={this.handleFieldChange}
              placeholder='Select users'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <ContactSelectField
              autoBlur={true}
              clearable={false}
              contactActions={contactsActions}
              contacts={contacts}
              fieldState={fieldState.getNestedField('contacts')}
              isCreatable={false}
              label='Contact'
              multi={true}
              onChange={this.handleFieldChange}
              placeholder='Select contacts'
              queryParams={{ filter: CONTACT_FILTER_WITH_INTRODUCTIONS }}
            />
          </div>
          <div className='col-sm-6 react-select'>
            <CompanyMultiSelectField
              companies={companies}
              companyActions={companiesActions}
              fieldState={fieldState.getNestedField('companies')}
              label='Company'
              onChange={this.handleFieldChange}
              placeholder='Select companies'
              queryParams={{
                filter: FILTER_WITH_INTRODUCTION,
              }}
            />
          </div>
        </div>
        <DateRangeField
          fieldState={fieldState.getNestedField('dateRange')}
          innerClassName={dateRangeInnerClassName}
          onChange={this.handleFieldChange}
          wrapperClassName={dateRangeWrapperClassName}
        />
        <h3>Recipients</h3>
        <EmailField
          clearable={false}
          fieldState={fieldState.getNestedField('emails')}
          label='Emails*'
          onChange={this.handleFieldChange}
          wrapperClassName={emailWrapperClassName}
        />
      </div>
    );
  }
}

IntroductionReportField.propTypes = {
  companies: ImmutablePropTypes.mapContains({
    queriesById: ImmutablePropTypes.map,
  }),

  companiesActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }),

  contacts: ImmutablePropTypes.mapContains({
    queriesById: ImmutablePropTypes.map,
  }),

  contactsActions: PropTypes.shape({
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    queryContacts: PropTypes.func.isRequired,
  }),

  dateRangeInnerClassName: PropTypes.string,

  dateRangeWrapperClassName: PropTypes.string,

  emailWrapperClassName: PropTypes.string,

  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  onChange: PropTypes.func.isRequired,
};

export default IntroductionReportField;

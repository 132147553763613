import PropTypes from 'prop-types';
import selectn from 'selectn';
import { fromJS } from 'immutable';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import withFormState from 'modules/forms/components/withFormState';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import mapInvestmentIdToInvestment from 'modules/company-investments/components/mapInvestmentIdToInvestment';
import withTransaction from 'modules/transactions/components/withTransaction';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import FundingRoundField from './FundingRoundField';
import createFundingRoundActionCreator from '../actions/createFundingRound';
import updateFundingRoundActionCreator from '../actions/updateFundingRound';

/**
 * Provides props for managing a form for creating/editing a funding round.
 *
 * Creates the following props for the child component:
 * * `isNewRecord` {Boolean} true if creating a new record, false if editing an existing record.
 * * `formState` {FormState} a FormState object with an underlying FundingRoundField FieldState
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default compose(
  setDisplayName('withFundingRoundForm'),
  setPropTypes({
    /**
     * The companyId is required for creating new records if investmentId is not provided.
     */
    companyId: requiredIf(PropTypes.number, props => !props.investmentId),

    /**
     * The investmentId is required for editing existing records.
     */
    investmentId: requiredIf(PropTypes.number, props => !props.companyId),

    /**
     * An optional formstate to initial the form with (handled by withFormState)
     */
    initialFormState: formStatePropType,

    /**
     * Called when the form has been successfully saved.
     */
    onSaved: PropTypes.func.isRequired,
  }),

  // Create a temporary prop (`incomingProps`) that holds any incoming props
  // that aren't required by us. This ensures we don't overwrite/delete/clobber any props that may
  // need to be passed through.
  withProps(
    ({
      companyId: _companyId,
      initialFormState: _initialFormState,
      investmentId: _investmentId,
      onSaved: _onSaved,
      ...incomingProps
    }) => ({
      incomingProps: incomingProps,
    }),
  ),

  /**
   * If we were given a fundingRound in order to edit an existing round, map that to it's
   * underlying record.
   */
  mapInvestmentIdToInvestment,
  withProps(({ investment }) => ({
    initialValue: investment,
    isNewRecord: !(investment && investment.get('id')),
  })),

  withFeatureCheck('feature.skyminyr', 'hasSkyminyrFeature'),
  /**
   * Create a FormState, initializing it with the value from `initialValue`,
   * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
   */
  withFormState(({ hasSkyminyrFeature, initialValue }, isReset) => {
    return FundingRoundField.createFieldState(
      'fundingRound',
      isReset ? null : initialValue,
      hasSkyminyrFeature,
    );
  }),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onResetFormState,
      onSaved,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();

        // If all was good, call onSaved with the record's ID
        onSaved(selectn('payload.result.funding_round', transaction));
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  /**
   * Include connected versions of `createFundingRound` and `updateFundingRound` that
   * are needed to submit.
   */
  connect(null, dispatch => ({
    createFundingRound: bindActionCreators(
      createFundingRoundActionCreator,
      dispatch,
    ),
    updateFundingRound: bindActionCreators(
      updateFundingRoundActionCreator,
      dispatch,
    ),
  })),

  /**
   * Add a callback to handle submitting the action form.
   */
  withHandlers({
    // Called when the form should be submitted.
    onSubmit: ({
      companyId,
      createFundingRound,
      formState,
      investment,
      onFormStateChange,
      startTransaction,
      updateFundingRound,
    }) => e => {
      const transactionId = startTransaction();
      const fieldValue = formState.getFieldValue();
      const values = (investment || fromJS({ venture: companyId })).merge(
        fieldValue,
      );
      const action = values.get('id') ? updateFundingRound : createFundingRound;

      action({
        fundingRound: values.toJS(),
        transactionId: transactionId,
      });

      onFormStateChange(formState.startSubmit(transactionId));

      // Prevent default browser behavior, which could cause the browser to attempt
      // to submit a form on it's own.
      return preventDefaultHandler(e);
    },
  }),

  // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
  mapProps(
    ({
      formState,
      hasSkyminyrFeature: _hasSkyminyrFeature,
      incomingProps,
      isNewRecord,
      onCancel,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      onSubmit,
    }) => ({
      ...incomingProps,
      formState: formState,
      isNewRecord: isNewRecord,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onResetFormState: onResetFormState,
      onSubmit: onSubmit,
      onCancel: onCancel,
    }),
  ),
);

import getEntity from 'modules/entities/selectors/getEntity';
import jobListingSchema from '../schema';

/**
 * Gets a job listing record by it's search ID
 * @param {Object} state
 * @param {Number} searchId The underlying search ID
 * @return {Immutable.Map|undefined} The job listing record, if found
 */
export default (state, searchId) =>
  getEntity(state, jobListingSchema.key, searchId);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import GenerateReportModal from 'modules/reports/components/GenerateReportModal';
import ContactSummaryReportField from './ContactSummaryReportField';
import withContactSummaryReportForm from './withContactSummaryReportForm';

export default compose(
  setDisplayName('ContactSummaryReportModal'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  withContactSummaryReportForm,
  withProps(
    ({ contactId, formState, handleFieldStateChange, isSubmitting }) => ({
      children: (
        <ContactSummaryReportField
          className='form'
          contactId={contactId}
          disabled={isSubmitting}
          fieldState={formState.getFieldState()}
          onChange={handleFieldStateChange}
        />
      ),
      sentTo: formState.getFieldState().getNestedField('emails').getValue(),
      title: 'Generate Summary Report',
    }),
  ),
)(GenerateReportModal);

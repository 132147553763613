/* eslint-disable react/prop-types */
// ^ we're defining components as keys on an object, so prop-types don't apply
import React from 'react';

import {
  COLUMN_END_TIME,
  COLUMN_FILE_NAME,
  COLUMN_RESOURCE_TYPE,
  COLUMN_START_TIME,
  COLUMN_STATUS_FILE,
  COLUMN_UPLOAD_STATUS,
  COLUMN_USER_NAME,
} from '../constants';

import BulkImportDateTime from './BulkImportDateTime';
import BulkImportSource from './BulkImportSource';
import BulkImportResourceType from './BulkImportResourceType';
import BulkImportResultLink from './BulkImportResultLink';
import BulkImportStatus from './BulkImportStatus';
import BulkImportUserName from './BulkImportUserName';

export default {
  [COLUMN_END_TIME]: {
    key: COLUMN_END_TIME,
    title: 'End Time',
    Cell: ({ datum: bulkImportId }) => (
      <BulkImportDateTime
        bulkImportId={bulkImportId}
        timestampProp='end_time'
      />
    ),
  },
  [COLUMN_FILE_NAME]: {
    key: COLUMN_FILE_NAME,
    title: 'Source Data',
    Cell: ({ datum: bulkImportId }) => (
      <BulkImportSource bulkImportId={bulkImportId} />
    ),
  },
  [COLUMN_START_TIME]: {
    key: COLUMN_START_TIME,
    title: 'Start Time',
    isSortable: true,
    Cell: ({ datum: bulkImportId }) => (
      <BulkImportDateTime
        bulkImportId={bulkImportId}
        timestampProp='start_time'
      />
    ),
  },
  [COLUMN_STATUS_FILE]: {
    key: COLUMN_STATUS_FILE,
    title: 'Results',
    Cell: ({ datum: bulkImportId }) => (
      <BulkImportResultLink bulkImportId={bulkImportId} />
    ),
  },
  [COLUMN_UPLOAD_STATUS]: {
    key: COLUMN_UPLOAD_STATUS,
    title: 'Status',
    Cell: ({ datum: bulkImportId }) => (
      <BulkImportStatus bulkImportId={bulkImportId} />
    ),
  },
  [COLUMN_RESOURCE_TYPE]: {
    key: COLUMN_RESOURCE_TYPE,
    title: 'Type',
    Cell: ({ datum: bulkImportId }) => (
      <BulkImportResourceType bulkImportId={bulkImportId} />
    ),
  },
  [COLUMN_USER_NAME]: {
    key: COLUMN_USER_NAME,
    title: 'Imported By',
    Cell: ({ datum: bulkImportId }) => (
      <BulkImportUserName bulkImportId={bulkImportId} />
    ),
  },
};

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import emailTemplateSchema from '../schema';

/**
 * Gets the date an email template record was last modified
 * @param {*} state
 * @param {Number} id The email template ID.
 * @return {String} The ISO8601-formatted last modified date
 */
export default (state, id) =>
  getEntityProperty(state, emailTemplateSchema.key, id, 'updated_at');

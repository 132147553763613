import getUserPermission from 'modules/auth/selectors/getUserPermission';

/**
 * Gets a value indicating whether the current user can add assessments of
 * type TYPE_CLIENT.
 * @param {Object} state
 * @return {Boolean} True if the current user can add client assessments
 */
export default state =>
  getUserPermission(state, 'add_client_assessment', false);

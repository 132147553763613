import getTimezoneList from './getTimezoneList';

export default state => {
  const timezoneList = getTimezoneList(state);

  // Don't fetch if we're already fetching, or if we fetched and got an error.
  if (timezoneList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (timezoneList.getIn(['_meta', 'error'])) {
    return false;
  }

  // Fetch if we don't have IDs (meaning we haven't fetched yet) or we've been invalidated.
  return (
    !timezoneList.has('ids') || timezoneList.getIn(['_meta', 'isInvalidated'])
  );
};

import url from 'modules/routing/url';
import createQueryActions, {
  transformSuggestions,
} from '../createQueryActions';

import {
  CONTACT_QUERY_CREATE,
  CONTACT_QUERY_DESTROY,
  CONTACT_QUERY_INVALIDATE,
  CONTACT_QUERY_FETCH_START,
  CONTACT_QUERY_FETCH_SUCCESS,
  CONTACT_QUERY_FETCH_FAILURE,
  CONTACT_QUERY_URL,
  CONTACT_FILTER_WITH_INTRODUCTIONS,
  CONTACT_FILTER_WITH_PARTNERSHIPS,
} from './constants';

export const getUrl = ({ exclude_contacts, filter, includes, term: query }) => {
  const params = {
    query: query,
    includes: includes,
    exclude_contacts: exclude_contacts,
  };
  if (filter === CONTACT_FILTER_WITH_INTRODUCTIONS) {
    params.with_introductions = true;
  }
  if (filter === CONTACT_FILTER_WITH_PARTNERSHIPS) {
    params.with_partnerships = true;
  }

  return url(CONTACT_QUERY_URL, params);
};

const actions = createQueryActions({
  create: CONTACT_QUERY_CREATE,
  destroy: CONTACT_QUERY_DESTROY,
  invalidate: CONTACT_QUERY_INVALIDATE,
  start: CONTACT_QUERY_FETCH_START,
  success: CONTACT_QUERY_FETCH_SUCCESS,
  failure: CONTACT_QUERY_FETCH_FAILURE,
  transformResponse: transformSuggestions,
  getUrl: getUrl,
});

export const queryContacts = actions.query;
export const createContactsQuery = actions.create;
export const destroyContactsQuery = actions.destroy;
export const invalidateContactsQuery = actions.invalidate;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from 'modules/core/components/PageHeader';
import SearchInput from '@thrivetrm/ui/components/SearchInput';
import { setLegacyFilters } from '../recordIndexSlice';

const RecordIndexHeader = () => {
  const dispatch = useDispatch();

  const { CreateButton, filterAdapter, recordLabelPlural } = useSelector(
    state => state.recordIndex,
  );

  const keywordFilterValue = useSelector(
    state => state.recordIndex.savedView.filters?.keyword || '',
  );

  const handleKeywordSubmit = keyword => {
    // Set the keyword input in the legacy form modal to the current value.
    // This is needed to ensure that when `setLegacyFilters` is called
    // that the sortField is set correctly('relevance' if keyword is present)
    filterAdapter.keywordInputEl().value = keyword;
    dispatch(setLegacyFilters());
  };

  const [keywordInputValue, setKeywordInputValue] = useState(
    keywordFilterValue,
  );

  // Use to prevent multiple submissions of the same value
  const previousKeywordInputValue = useRef(keywordFilterValue);

  // Seed the local keyword state when the keyword filter changes outside
  // of this component (e.g. on page load or via jQuery filter modal)
  useEffect(() => {
    setKeywordInputValue(keywordFilterValue);
    previousKeywordInputValue.current = keywordFilterValue;
  }, [keywordFilterValue]);

  const handleClear = () => {
    if (keywordInputValue !== '') {
      previousKeywordInputValue.current = '';
      handleKeywordSubmit('');
    }
  };

  const handleSubmit = () => {
    if (keywordInputValue !== previousKeywordInputValue.current) {
      previousKeywordInputValue.current = keywordInputValue;
      handleKeywordSubmit(keywordInputValue);
    }
  };

  return (
    <PageHeader className='RecordIndexHeader' title={recordLabelPlural}>
      <SearchInput
        className='RecordIndexHeader__searchInput'
        onChange={setKeywordInputValue}
        onClear={handleClear}
        onSubmit={handleSubmit}
        placeholder='Search by keyword...'
        value={keywordInputValue}
      />
      <CreateButton />
    </PageHeader>
  );
};

export default RecordIndexHeader;

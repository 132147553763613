import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';

const ActionButton = ({ isPrimary, label, notification, ...otherProps }) => {
  const isUpdating = notification.getIn(['meta', 'isUpdating']);

  const classNames = classnames('btn', 'btn-sm', {
    'btn-secondary-outline': Boolean(isPrimary),
    'btn-secondary': !isPrimary,
    disabled: isUpdating,
  });

  if (otherProps.href) {
    return (
      <a disabled={isUpdating} {...otherProps} className={classNames}>
        {label}
      </a>
    );
  }

  return (
    <button
      disabled={isUpdating}
      type='button'
      {...otherProps}
      className={classNames}
    >
      {label}
    </button>
  );
};

ActionButton.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  href: PropTypes.string,

  isPrimary: PropTypes.bool,

  label: PropTypes.string.isRequired,

  notification: ImmutablePropTypes.mapContains({
    meta: ImmutablePropTypes.mapContains({
      isUpdating: PropTypes.bool,
    }),
  }),

  onClick: PropTypes.func,
  /* eslint-enable react/no-unused-prop-types */
};

export default ActionButton;

import React from 'react';
import { compose, setDisplayName, withProps } from 'recompose';
import ModalButton from 'modules/core/componentsLegacy/ModalButton';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import CommissionsReportModal from './CommissionsReportModal';

/**
 * Renders a button that, when clicked, shows a modal to generate a report of commissions
 */

export default compose(
  setDisplayName('CommissionsReportModalButton'),
  withProps({
    modal: CommissionsReportModal,
    children: (
      <span>
        <i className='fa fa-file-pdf-o' /> Generate Report
      </span>
    ),
  }),
  withClassName('CommissionsReportModalButton btn btn-primary'),
)(ModalButton);

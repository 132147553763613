import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Date from '@thrivetrm/ui/components/Date';
import Icon from '@thrivetrm/ui/components/Icon';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import useQuickView from 'modules/quick-view/useQuickView';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import OffLimitsStatus from 'modules/off-limits/OffLimitsStatus';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import routes from 'modules/routing/routes';
import { useGetContactQuery } from 'services/apiV1/contact';
import PluralText from '@thrivetrm/ui/components/PluralText';
import { displayDate } from '@thrivetrm/ui/utilities/dateTimeUtils';
import QuickViewNavItem from '../QuickViewNavItem';
import { QuickViewErrorState } from '../QuickViewState';
import ContactQvpHeader from '../ContactQvpHeader';

// height of one activity row
const ACTIVITY_HEIGHT = 74.8;
// fetching 7 activities and showing list based on screen hight without scroll
const ACTIVITY_LIMIT = 7;

const ContactProfilePanel = () => {
  const { pathParams, title } = useQuickView();
  const { contactId } = pathParams;
  const { navigateTo } = useQuickView();
  const hasConnectionsFeature = useFeatureCheck('feature.connections_gate');
  const isFullAccessOrAdminUser = useSelector(isEmployeeUser);
  const isCrmUser = useSelector(isCrmUserSelector);
  const [
    contactDetailsElementHeight,
    setContactDetailsElementHeight,
  ] = useState();
  const contactDetailsRef = useRef();

  const {
    data: contact,
    error: contactError,
    isFetching: isLoadingContact,
  } = useGetContactQuery(contactId);

  const [loadActivities, _isError, _isLoading, activities] = useApiGet(
    routes.api_notifications({
      query: {
        contact_id: contactId,
        limit: ACTIVITY_LIMIT,
        limited_visibility: true,
      },
    }),
  );
  const contactActivity = activities?.notifications;

  useEffect(() => {
    loadActivities();
    setContactDetailsElementHeight(contactDetailsRef?.current?.clientHeight);
  }, [contactId]);

  const openConnections = () => {
    navigateTo(`/contact/${contactId}/connections`, {
      title: title,
    });
  };

  const showContactPersonalInformationPanel = () => {
    navigateTo(`/contacts/${contactId}/edit`, {
      title: title,
    });
  };

  // we have done this logic as we don't want scroll on contact profile panel.
  // we have to show the activity list without scroll so for that calculating count here

  // sidepanel body heighty
  const sidePanelBodyHeight = document.querySelector('.SidePanel__body')
    ?.clientHeight;
  const remainingHeightToShowActivity =
    sidePanelBodyHeight - contactDetailsElementHeight;
  const showableActivityCount = parseInt(
    remainingHeightToShowActivity / ACTIVITY_HEIGHT,
  );

  const contactNumber = contact?.phone || contact?.mobilePhone;

  return (
    <>
      <ContactQvpHeader contactId={contactId} />
      <SidePanel.Body className='u-paddingTop-n u-paddingBottom-n u-marginBottom-24 ContactProfilePanel__sidePanelBody'>
        <LoadingContainer
          isLoading={isLoadingContact}
          minHeight='80%'
          size='large'
        >
          <div ref={contactDetailsRef}>
            <div className='u-flex u-flexJustify-spaceBetween'>
              <div className='u-padding-2'>
                {contactNumber ? (
                  <p className='u-marginBottom-2' data-testid='contact-number'>
                    <a href={`tel:${contactNumber}`}>{contactNumber}</a>{' '}
                    {contact?.phone ? '(Phone)' : '(Mobile)'}
                  </p>
                ) : null}
                {contact?.email ? (
                  <p className='u-marginBottom-2' data-testid='contact-email'>
                    <a href={`mailto:${contact.email}`}>{contact.email}</a>{' '}
                    {contact.email === contact.preferredEmail
                      ? '(Preferred)'
                      : null}
                  </p>
                ) : null}
                {contact?.location ? (
                  <div className='u-flex u-flexAlign-c'>
                    <span className='u-marginRight-4'>{contact.location}</span>
                    <Icon color='blue' size='small' type='mapMarker' />
                  </div>
                ) : null}
              </div>
              {isFullAccessOrAdminUser || isCrmUser ? (
                <ButtonSecondary
                  icon='edit'
                  onClick={showContactPersonalInformationPanel}
                  size='small'
                />
              ) : null}
            </div>
            <div className='u-flex u-flexJustify-spaceBetween u-marginVertical-12'>
              <div className='u-flex'>
                {contact?.linkedinUrl ? (
                  <ButtonSecondary
                    icon='linkedIn'
                    rel='noopener noreferrer'
                    size='small'
                    target='_blank'
                    url={contact.linkedinUrl}
                  />
                ) : null}
                {contact?.resume?.url ? (
                  <div className='u-marginLeft-12'>
                    <ButtonSecondary
                      icon='download'
                      rel='noopener noreferrer'
                      size='small'
                      target='_blank'
                      url={contact.resume.url}
                    />
                  </div>
                ) : null}
              </div>
              <ButtonSecondary
                className='ContactProfilePanel__viewProfile'
                icon='person'
                label='View Profile'
                size='small'
                url={`/contacts/${contactId}`}
              />
            </div>
            {hasConnectionsFeature && (isFullAccessOrAdminUser || isCrmUser) ? (
              <QuickViewNavItem
                className='u-borderBottom'
                count={contact?.connectionsCount}
                icon='connectionsOpen'
                label='Connections'
                onClick={openConnections}
                subLabel={
                  contact?.reportingRelationshipsCount ? (
                    <div className='u-flex u-marginTop-8 u-marginLeft-24'>
                      <PluralText
                        className='ContactProfilePanel__reportingRelationshipCount'
                        quantity={contact.reportingRelationshipsCount}
                        shouldIncludeQuantity={true}
                        text='Reporting Relationship'
                      />
                      {contact?.directReportsCount ? (
                        <PluralText
                          className='ContactProfilePanel__reportingRelationshipCount u-marginLeft-8'
                          quantity={contact.directReportsCount}
                          shouldIncludeQuantity={true}
                          text='Direct Report'
                        />
                      ) : null}
                    </div>
                  ) : null
                }
              />
            ) : null}
            {isFullAccessOrAdminUser ? (
              <QuickViewNavItem
                className='u-marginLeft-n u-borderBottom'
                icon='alertTriangle'
                label='Off Limits Reasons'
                onClick={() =>
                  navigateTo(`/contact/${contactId}/off-limits`, {
                    title: title,
                  })
                }
                subLabel={
                  contact?.offLimits?.category ? (
                    <div className='u-flex u-marginTop-8 u-marginLeft-24'>
                      <OffLimitsStatus
                        severity={contact.offLimits.category}
                        status={contact.offLimits.status}
                      />
                    </div>
                  ) : null
                }
              />
            ) : null}
            <QuickViewNavItem
              className='u-marginLeft-n'
              icon='connectionsOpen'
              label='Activity Timeline'
              onClick={() =>
                navigateTo(`/contact/${contactId}/activity`, {
                  title: title,
                })
              }
            />
          </div>

          {contactActivity?.length
            ? contactActivity
                .slice(0, showableActivityCount)
                .map(
                  ({
                    assessed_on: assessedOn,
                    created_at: createdAt,
                    icon,
                    id,
                    key,
                    params,
                    quickview_message: quickviewMessage,
                    resource_date: resourceDate,
                    type,
                  }) => {
                    const interviewHeading = `${quickviewMessage} ${displayDate(
                      params?.start_time,
                    )}`;
                    return (
                      <div
                        className='u-flex u-flexAlign-t u-marginTop-16 u-paddingLeft-8'
                        key={id}
                      >
                        {/*
                         * All of the Activity Timeline types use ui icons EXCEPT for the legacy
                         * activity type which is represented by a Font Awesome bell-o icon
                         */}
                        {icon === 'bell-o' ? (
                          <i
                            className={`fa fa-${icon} ContactProfilePanel__activityIcon`}
                          />
                        ) : (
                          <Icon size='large' type={icon} />
                        )}
                        <div className='u-marginLeft-8'>
                          <h4 className='u-margin-n u-marginBottom-8 u-wordBreak'>
                            {(type === 'interview' || type === 'meeting') &&
                            key !== 'in_process'
                              ? interviewHeading
                              : quickviewMessage}
                          </h4>
                          <Date
                            className='u-fontSize-small'
                            date={resourceDate || assessedOn || createdAt}
                            format='dateTime'
                          />
                        </div>
                      </div>
                    );
                  },
                )
            : null}
          {!isLoadingContact && contactError ? <QuickViewErrorState /> : null}
        </LoadingContainer>
      </SidePanel.Body>
    </>
  );
};

export default ContactProfilePanel;

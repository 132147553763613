import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, withProps } from 'recompose';
import mapSearchIdsToSearches from 'modules/searches/components/mapSearchIdsToSearches';
import DateTime from 'modules/datetime/components/DateTime';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import CommaSeparatedList from '@thrivetrm/ui/components/CommaSeparatedList';
import { useSelector } from 'react-redux';
import getContact from 'modules/contacts/selectors/contacts/getContact';

/**
 * Renders a single meeting record.
 */
const AppointmentView = ({
  appointment,
  attendees,
  params,
  searches,
  showRelatedSearches,
}) => {
  const attendeeNames = useSelector(state => {
    if (typeof attendees[0] === 'number') {
      return attendees?.map(attendee =>
        getContact(state, attendee).get('full_name'),
      );
    }
    return null;
  });

  return (
    <div className='AppointmentView'>
      {showRelatedSearches && searches && searches.size > 0 && (
        <div className='AppointmentView__RelatedSearches'>
          Related to:{' '}
          {searches
            .map(search =>
              search.get('confidential')
                ? search.get('code_name')
                : search.get('name'),
            )
            .toArray()
            .join(', ')}
        </div>
      )}
      {appointment.get('subject') && (
        <div className='AppointmentView__Subject'>
          <strong>Event Title:</strong> {appointment.get('subject')}
        </div>
      )}
      {appointment.get('location') && (
        <div className='AppointmentView__Location'>
          <strong>Location:</strong> {appointment.get('location')}
        </div>
      )}
      {appointment.get('start_time') || params.get('start_time') ? (
        <div className='AppointmentView__StartTime'>
          <strong>When:</strong>{' '}
          <DateTime
            format='L LT'
            value={appointment.get('start_time') || params.get('start_time')}
          />
        </div>
      ) : null}
      {attendees.length > 0 ? (
        <div>
          <strong>Attendees:</strong>{' '}
          <CommaSeparatedList
            items={
              attendeeNames || attendees.map(attendee => attendee?.full_name)
            }
          />
        </div>
      ) : null}
      {appointment.get('description') ? (
        <ExpandableText
          characterLimit={150}
          content={appointment.get('description')}
          isSanitizedHtml={true}
        />
      ) : null}
    </div>
  );
};

export const propTypes = {
  /**
   * The appointment to render.
   */
  appointment: ImmutablePropTypes.mapContains({
    description: PropTypes.string,
    subject: PropTypes.string,
    start_time: PropTypes.string,
  }).isRequired,

  attendees: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        full_name: PropTypes.string,
        id: PropTypes.number,
      }),
    ),
    PropTypes.arrayOf(PropTypes.number),
  ]),

  params: ImmutablePropTypes.mapContains({
    start_time: PropTypes.string,
  }),

  /**
   * The search records of the searches related to the appointment.
   */
  searches: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      name: PropTypes.string.isRequired,
    }),
  ),

  /**
   * Whether to show a list of related searches. Prevents duplication when other
   * components provide this functionality
   */
  showRelatedSearches: PropTypes.bool,
};

AppointmentView.defaultProps = {
  showRelatedSearches: false,
};

AppointmentView.propTypes = propTypes;

/**
 * Requires the meeting record.
 */
export default compose(
  // The 'appointment' record is required.
  withProps(({ appointment }) => ({
    searchIds: appointment && appointment.get('searches'),
  })),
  mapSearchIdsToSearches,
)(AppointmentView);

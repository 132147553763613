/* eslint-disable consistent-return */
import displayModalErrors from '../lib/display_modal_errors';
import validation, {
  dateRange,
  diversityRequirements,
  equityRange,
  range,
  ratingRange,
} from '../lib/validation';

export default {
  always: function always() {
    // Contact validations on filters
    const requiredForDiversityFiltering = [
      '.contact-filter-keyword',
      '#filters_position',
      '.js-industries-filter',
      '.js-position-functions-filter',
      '.js-search-networks-filter',
      '.js-affiliations-filter',
      '.js-searches-filter',
      '.js-sectors-filter',
      '.js-skills-filter',
      '.js-linkedin-skills-filter',
    ];
    const $filterModal = $('#filters');
    const filterValidator = validation($filterModal);
    filterValidator.add(
      '.date-range-error',
      dateRange({
        start: '.js-graduation-start-date',
        end: '.js-graduation-end-date',
      }),
    );

    filterValidator.add(
      '.created-date-range-error',
      dateRange({
        start: '.js-created-start-date',
        end: '.js-created-end-date',
      }),
    );

    filterValidator.add(
      '.primary-position-date-range-error',
      dateRange({
        start: '.js-primary-position-start-date',
        end: '.js-primary-position-end-date',
      }),
    );

    filterValidator.add(
      '.assessed-date-range-error',
      dateRange({
        start: '.js-assessed-start-date',
        end: '.js-assessed-end-date',
      }),
    );

    filterValidator.add(
      '.candidacy-assessed-date-range-error',
      dateRange({
        start: '.js-candidacy-assessed-start-date',
        end: '.js-candidacy-assessed-end-date',
      }),
    );

    filterValidator.add(
      '.updated-date-range-error',
      dateRange({
        start: '.js-updated-start-date',
        end: '.js-updated-end-date',
      }),
    );

    filterValidator.add(
      '.document-uploaded-date-range-error',
      dateRange({
        start: '.js-document-uploaded-start-date',
        end: '.js-document-uploaded-end-date',
      }),
    );

    filterValidator.add(
      '.document-expiration-date-range-error',
      dateRange({
        start: '.js-document-expiration-start-date',
        end: '.js-document-expiration-end-date',
      }),
    );

    filterValidator.add(
      '.search-timeframe-date-range-error',
      dateRange({
        start: '.search-date-gt',
        end: '.search-date-lt',
      }),
    );

    filterValidator.add(
      '.rating-range-error',
      ratingRange({
        min: '.js-average-rating-min',
        max: '.js-average-rating-max',
      }),
    );

    filterValidator.add(
      '.birth-year-range-error',
      range({
        min: '.js-birth-age-min',
        max: '.js-birth-age-max',
      }),
    );

    filterValidator.add(
      '.preferred-total-compensation-error',
      range({
        min: '.js-preferred-total-compensation-min',
        max: '.js-preferred-total-compensation-max',
      }),
    );

    filterValidator.add(
      '.preferred-equity-error',
      equityRange({
        min: '.js-preferred-equity-min',
        max: '.js-preferred-equity-max',
      }),
    );

    filterValidator.add(
      '.preferred-bonus-error',
      range({
        min: '.js-preferred-bonus-min',
        max: '.js-preferred-bonus-max',
      }),
    );

    filterValidator.add(
      '.preferred-base-error',
      range({
        min: '.js-preferred-base-min',
        max: '.js-preferred-base-max',
      }),
    );

    filterValidator.add(
      '.bonus-error',
      range({
        min: '.js-bonus-min',
        max: '.js-bonus-max',
      }),
    );

    filterValidator.add(
      '.base-error',
      range({
        min: '.js-base-min',
        max: '.js-base-max',
      }),
    );

    filterValidator.add(
      '.equity-error',
      equityRange({
        min: '.js-equity-min',
        max: '.js-equity-max',
      }),
    );

    filterValidator.add(
      '.race-ethnicity-error',
      diversityRequirements({
        diversityField: '.js-race-ethnicity-filter',
        affiliationsError: $('.affiliations-error').length,
        positionRelatedField: requiredForDiversityFiltering,
      }),
    );

    filterValidator.add(
      '.affiliation-status-error',
      diversityRequirements({
        diversityField: '.js-affiliations-filter',
        affiliationsError: $('.affiliations-error').length,
        positionRelatedField: requiredForDiversityFiltering,
      }),
    );

    filterValidator.add(
      '.sexual-orientation-error',
      diversityRequirements({
        diversityField: '.js-sexual-orientation-filter',
        affiliationsError: $('.affiliations-error').length,
        positionRelatedField: requiredForDiversityFiltering,
      }),
    );

    filterValidator.add(
      '.veteran-status-error',
      diversityRequirements({
        diversityField: '.js-veteran-status-filter',
        affiliationsError: $('.affiliations-error').length,
        positionRelatedField: requiredForDiversityFiltering,
      }),
    );

    filterValidator.add(
      '.disability-status-error',
      diversityRequirements({
        diversityField: '.js-disability-status-filter',
        affiliationsError: $('.affiliations-error').length,
        positionRelatedField: requiredForDiversityFiltering,
      }),
    );

    filterValidator.add(
      '.gender-error',
      diversityRequirements({
        diversityField: '.js-gender-filter',
        affiliationsError: $('.affiliations-error').length,
        positionRelatedField: requiredForDiversityFiltering,
      }),
    );

    $('#filters-form-submit').on('click', event => {
      const errors = filterValidator.run();
      displayModalErrors(event, $filterModal, errors);
    });
    // Open any sections that have active filters
    if ($('.filters-form input:checked').length > 0) {
      $.each(
        $(
          '.filters-form input:checked:not(.radio_buttons):not(.collapse-aware-checkboxes input)',
        ),
        function showChecked() {
          $(this).closest('.collapse').not('.in').addClass('in');
        },
      );
    }

    if ($('.filters-form option:selected').length > 0) {
      $.each($('.filters-form option:selected'), function showSelected() {
        if (this.value.length) {
          $(this).closest('.collapse').not('.in').addClass('in');
        }
      });
    }

    if ($('.js-chosen-select option:selected').length > 0) {
      $.each($('.js-chosen-select option:selected'), function showJsSelected() {
        if (this.value.length) {
          $(this).closest('.collapse').not('.in').addClass('in');
        }
      });
    }

    if ($('.filters-form input:text').length > 0) {
      $.each($('.filters-form input:text'), function showText() {
        if ($(this).val().length > 0) {
          $(this).closest('.collapse').not('.in').addClass('in');
        }
      });
    }

    $('input[data-autocomplete-field]').each(function handleAutocomplete() {
      const $this = $(this);

      // recommended for remote data calls
      $this.autocomplete({
        serviceUrl: $this.data('autocomplete-path'),
        onSelect: function onSelect(suggestion) {
          if (suggestion.data != null) {
            $('#contact_filter_contact').val(suggestion.data);
          }
        },
        minChars: 3,
        deferRequestBy: 500,
        showNoSuggestionNotice: true,
      });
    });

    // Slide toggle .current-past-filter
    $('.current-past-filter').each(function showCurrentPastFilters() {
      const checks = $(this);
      const input = $(checks.data('input'));
      if (input.val()) {
        checks.show();
      }

      input.on('input', () => {
        if (input.val()) {
          checks.slideDown(100);
        }
        if (!input.val()) {
          checks.slideUp(100);
        }
      });
    });
  },
};

import selectn from 'selectn';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import withTransaction from 'modules/transactions/components/withTransaction';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import getStageListForStageType from 'modules/search-stages/selectors/getStageListForStageType';
import {
  STAGE_TYPE_JOB_SEARCH,
  STAGE_TYPE_TALENT_POOL,
} from 'modules/search-stages/constants';
import withFormState from '../../../../components/forms/withFormState';
import getSearchProperty from '../../selectors/getSearchProperty';
import SearchSummaryReportField from './SearchSummaryReportField';
import withSearchActions from '../withSearchActions';
import {
  REPORT_SEARCH_SUMMARY,
  REPORT_LEADERSHIP,
  TYPE_JOB_SEARCH,
} from '../../constants';
import { LAYOUT_LEADERSHIP, TYPE_EXPANDED } from './constants';

/**
 * A higher-order component generator providing props for creating a component that takes
 * a SearchSummaryReportField's fieldState and provides its underlying form functionality
 */
export default compose(
  withSearchActions,
  withFeatureCheck('feature.assessment_templates', 'hasAssessmentTemplates'),
  withFeatureCheck('report.leadership_report', 'showLeadershipReport'),
  // Create a formState from the `SearchSummaryReportField`'s fieldState creator.
  connect((state, { searchId }) => {
    const searchType = getSearchProperty(state, searchId, 'type');
    const stageType =
      searchType === TYPE_JOB_SEARCH
        ? STAGE_TYPE_JOB_SEARCH
        : STAGE_TYPE_TALENT_POOL;
    return {
      stages: getStageListForStageType(state, stageType),
    };
  }),

  withFormState(props =>
    SearchSummaryReportField.createFieldState(
      'searchSummaryReport',
      props.currentUserEmail,
      props.stages.get('ids').toArray(),
      props.reportType,
      props.hasAssessmentTemplates,
    ),
  ),

  withHandlers({
    // Handles the underlying fieldState change.
    handleFieldStateChange: ({ formState, onChange }) => fieldState =>
      onChange(formState.setFieldState(fieldState)),

    // Called by withTransaction when a transaction we started (in `handleSubmit`)
    // has been completed.
    onTransactionComplete: ({ formState, onChange }) => transaction =>
      onChange(formState.endSubmit(selectn('payload.error', transaction))),
  }),

  // provides `startTransaction` and `onTransactionComplete` props to track our submission
  withTransaction,

  withHandlers({
    // Submits the request by displaying the `createReport` action with the values of the fieldState
    handleSubmit: ({
      formState,
      onChange,
      searchActions,
      searchId,
      startTransaction,
    }) => e => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }

      const transactionId = startTransaction();
      const fieldValues = formState.getFieldValue();
      const { emails, type, ...options } = fieldValues;

      const valuesForStorage = {
        ...fieldValues,
        format: type === TYPE_EXPANDED ? fieldValues.format : 'pdf',
        stages: fieldValues.stages.map(stage => parseInt(stage)),
      };

      localStorage.setItem('SearchSummaryReportLastType', type);
      localStorage.setItem(
        `${type}SearchSummaryReportValues`,
        JSON.stringify(valuesForStorage),
      );

      searchActions.createReport({
        report:
          type === LAYOUT_LEADERSHIP
            ? REPORT_LEADERSHIP
            : REPORT_SEARCH_SUMMARY,
        options: {
          type: type,
          ...options,
        },
        emails: emails,
        searchId: searchId,
        transactionId: transactionId,
      });

      onChange(formState.startSubmit(transactionId));
    },
  }),

  // Some helper props that can be used by the wrapped component.
  withProps(({ formState }) => ({
    isComplete:
      formState.wasSubmitted() &&
      !formState.getError() &&
      !formState.isSubmitting(),
    isSubmitting: formState.isSubmitting(),
    wasSubmitted: formState.wasSubmitted(),
    error: formState.getError(),
  })),
);

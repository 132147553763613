import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  defaultProps,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import canViewCurrentCompensation from 'modules/compensations/selectors/canViewCurrentCompensation';
import mapSearchIdToSearchProperty from './mapSearchIdToSearchProperty';
import getSearchAverageCompensation from '../selectors/getSearchAverageCompensation';

const EM_DASH = '\u2014';

const SearchCompensationAveragesList = ({ compensation, title }) => (
  <div className='SearchCompensationAveragesList'>
    <h4>{title}</h4>
    <ul className='line-item-list'>
      {compensation
        .map(
          (value, key) =>
            value && (
              /* eslint-disable react/no-array-index-key */
              <li
                className={`SearchCompensationAveragesList__${key}`}
                key={key}
              >
                <span className='description'>{key}</span>
                <span className='value'>{value || EM_DASH}</span>
              </li>
              /* eslint-enable react/no-array-index-key */
            ),
        )
        .toArray()}
    </ul>
  </div>
);

SearchCompensationAveragesList.propTypes = {
  compensation: ImmutablePropTypes.mapContains({
    base: PropTypes.string,
    bonus: PropTypes.string,
    equity: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('SearchCompensationAveragesList(enhanced)'),
  setPropTypes({
    /**
     * True to only show average compensations for active candidates; false to show average for all.
     */
    activeOnly: PropTypes.bool.isRequired,

    /**
     * True if the user can view 'hidden' candidates
     */
    canViewHiddenCandidates: PropTypes.bool.isRequired,

    /**
     * True to check compensations viewing permission, and suppress rendering this component
     * if the user doesn't have valid permissions.
     */
    checkPermissions: PropTypes.bool.isRequired,

    /**
     * The search ID to show average compensation for.
     */
    searchId: PropTypes.number.isRequired,
  }),
  defaultProps({
    activeOnly: false,
    checkPermissions: true,
  }),

  mapSearchIdToSearchProperty(
    'use_desired_compensation',
    'useDesiredCompensation',
  ),

  connect(
    (
      state,
      { activeOnly, canViewHiddenCandidates, checkPermissions, searchId },
    ) => ({
      compensation:
        (!checkPermissions || canViewCurrentCompensation(state)) &&
        getSearchAverageCompensation(
          state,
          searchId,
          activeOnly,
          canViewHiddenCandidates,
        ),
    }),
  ),

  // Remove any null/zero values
  mapProps(({ compensation, useDesiredCompensation }) => ({
    compensation: compensation && compensation.filter(value => Boolean(value)),
    title: useDesiredCompensation
      ? 'Average Desired Compensation'
      : 'Average Compensation',
  })),

  // Don't render if there are no values.
  branch(
    ({ compensation }) => !compensation || !compensation.size,
    renderNothing,
  ),
)(SearchCompensationAveragesList);

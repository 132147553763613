import React from 'react';
import PropTypes from 'prop-types';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import EmailIntegrationCopyBCCEmail from './EmailIntegrationCopyBCCEmail';
import EmailIntegrationWithAccount from './EmailIntegrationWithAccount';
import EmailSignatureForm from './EmailSignatureForm';

const EmailIntegrationPage = ({
  chromeExtensionUrl,
  gmailEmailIntegration,
  outlookEmailIntegration,
  outreachEmailAddress,
}) => {
  const emailFeature = useFeatureCheck('integration.email.gmail');
  const outlookFeature = useFeatureCheck('integration.email.outlook.365');
  const emailSignature = useFeatureCheck('integration.email_signature');

  return (
    <>
      <h2 className='u-marginBottom-16'>BCC Email</h2>
      <p>
        When you send an email from your mail client, add the Thrive TRM email
        address to the BCC: line of the email. The email content will be
        automatically logged as an outreach on the contact&apos;s profile in
        Thrive.
      </p>
      <ul className='activities'>
        <li className='activity u-fontSize-medium'>
          If you use Gmail, we recommend you download the{' '}
          <a href={chromeExtensionUrl}>Chrome Extension</a> to automatically log
          outreach.
        </li>
        <li className='activity u-fontSize-medium'>
          If you use Outlook, we recommend you download the{' '}
          <a href='https://appsource.microsoft.com/en-us/product/office/WA200001408'>
            Outlook Add-in
          </a>{' '}
          to automatically log outreach.
        </li>
      </ul>
      <EmailIntegrationCopyBCCEmail
        outreachEmailAddress={outreachEmailAddress}
      />
      {outlookEmailIntegration ? (
        <EmailIntegrationWithAccount
          buttonName='Connect to your Outlook email account'
          description='Connect to your Outlook email account to send emails from Thrive. When sending emails in Thrive you’ll make use of your Outlook account to send those emails.'
          iconClassName='btn-social-outlook_email'
          integrationInfo={outlookEmailIntegration}
          title='Connect to an Outlook email account'
        />
      ) : null}
      {gmailEmailIntegration ? (
        <EmailIntegrationWithAccount
          buttonName='Connect to your Gmail account'
          description='Connect to your Gmail account to send emails from Thrive. When sending emails in Thrive you’ll make use of your Gmail account to send those emails.'
          googleDataPolicy={
            <p>
              Thrive TRM&apos;s use and transfer to any other app of information
              received from Google APIs will adhere to{' '}
              <a href='https://developers.google.com/terms/api-services-user-data-policy'>
                Google API Services User Data Policy
              </a>
              , including the Limited Use Requirements.
            </p>
          }
          iconClassName='btn-social-gmail'
          integrationInfo={gmailEmailIntegration}
          title='Connect to a Gmail account'
        />
      ) : null}
      {(emailFeature || outlookFeature) && emailSignature ? (
        <EmailSignatureForm
          draftStoragePath={{
            emailSignature: 'emailSignature',
          }}
        />
      ) : null}
    </>
  );
};

EmailIntegrationPage.defaultProps = {
  outreachEmailAddress: null,
};

EmailIntegrationPage.propTypes = {
  chromeExtensionUrl: PropTypes.string,
  gmailEmailIntegration: PropTypes.shape({
    oauthPath: PropTypes.string,
    token: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
  outlookEmailIntegration: PropTypes.shape({
    oauthPath: PropTypes.string,
    token: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
  outreachEmailAddress: PropTypes.string,
};

export default EmailIntegrationPage;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApiGet, useApiPost } from 'modules/core/hooks/useApi';
import Modal from '@thrivetrm/ui/components/Modal';
import Form from '@thrivetrm/ui/components/Form';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Card from '@thrivetrm/ui/components/Card';
import Grid from '@thrivetrm/ui/components/Grid';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const SearchCustomReportModal = ({
  initialRecipients,
  onClose,
  searchId,
  stages,
}) => {
  const [
    loadTemplates,
    isLoadingTemplates,
    loadTemplatesError,
    templates,
  ] = useApiGet(`/api/v1/searches/${searchId}/custom_report_templates`);

  const [reportRecipients, setReportRecipients] = useState(initialRecipients);
  const [hasSubmittedReportRequest, setHasSubmittedReportRequest] = useState(
    false,
  );

  const [createReport, isCreatingReport, createReportError] = useApiPost(
    `/api/v1/searches/${searchId}/custom_report`,
  );

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  const handleSubmit = formData => {
    const {
      includeHiddenCandidacies,
      recipients,
      reportTemplateId,
      sortBy,
      ...searchStages
    } = formData;

    setReportRecipients(recipients);

    const selectedStageIds = Object.keys(searchStages)
      .filter(stringId => searchStages[stringId])
      .map(stringId => parseInt(stringId));

    if (hasSubmittedReportRequest) {
      onClose();
    } else {
      // The js-report microservice expects the payload to have this exact shape
      // All keys are required.
      createReport(
        {
          emails: recipients,
          stages: selectedStageIds,
          sort_by: sortBy,
          include_hidden_candidacies: includeHiddenCandidacies,
          report_template_id: reportTemplateId,
        },
        { onSuccess: () => setHasSubmittedReportRequest(true) },
      );
    }
  };

  return (
    <Modal
      className='SearchCustomReportModal'
      isOpen={true}
      onClose={onClose}
      size='small'
      title='Generate Custom Report'
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Body className='SearchCustomReportModal__body'>
          <LoadingContainer isLoading={isLoadingTemplates} minHeight={512}>
            {templates?.length && !hasSubmittedReportRequest ? (
              <>
                <h1 className='u-margin-n'>Report Options</h1>
                <Form.SelectMenu
                  className='u-marginTop-16'
                  initialValue={templates[0]?.id}
                  inputWidth='full'
                  label='Type'
                  name='reportTemplateId'
                  placeholder='Select a type...'
                  rules={{
                    required: { value: true, message: 'A type is required' },
                  }}
                  size='small'
                >
                  {templates.map(type => (
                    <SelectMenu.Item key={type.id} value={type.id}>
                      {type.name}
                    </SelectMenu.Item>
                  ))}
                </Form.SelectMenu>
                <Form.SelectMenu
                  className='u-marginTop-16'
                  initialValue='name'
                  inputWidth='full'
                  label='Sort By'
                  name='sortBy'
                  placeholder='Sort by...'
                  size='small'
                >
                  <SelectMenu.Item value='name'>Name</SelectMenu.Item>
                  <SelectMenu.Item value='rank'>Rank</SelectMenu.Item>
                  <SelectMenu.Item value='priority'>Priority</SelectMenu.Item>
                  <SelectMenu.Item value='company'>Company</SelectMenu.Item>
                </Form.SelectMenu>
                <Form.Checkbox
                  className='u-inlineBlock u-marginTop-16'
                  label='Include Hidden Candidates'
                  name='includeHiddenCandidacies'
                />
                <div>
                  <h1>Stages</h1>
                  <Grid className='u-paddingHorizontal-n'>
                    {stages?.map(stage => (
                      <Grid.Column
                        className='u-inlineFlex'
                        key={stage.id}
                        size={6}
                      >
                        <Form.Checkbox
                          initialValue={!stage.rejected}
                          label={stage.name}
                          name={String(stage.id)}
                        />
                      </Grid.Column>
                    ))}
                  </Grid>
                </div>
                <div>
                  <h1>Recipients</h1>
                  <Form.EmailRecipientsInput
                    className='u-marginTop-16'
                    initialValue={initialRecipients}
                    label='Send To (Email Addresses)'
                    name='recipients'
                    rules={{
                      required: {
                        value: true,
                        message: 'An email address is required',
                      },
                      validate: values => {
                        const invalidEmails = values.filter(
                          value => !EMAIL_REGEX.test(value),
                        );
                        return invalidEmails.length
                          ? `Invalid emails: ${invalidEmails.join(', ')}`
                          : null;
                      },
                    }}
                  />
                </div>
                {createReportError ? (
                  <Card
                    className='u-marginVertical-16'
                    isCentered={false}
                    type='error'
                  >
                    <p className='u-textAlign-c'>
                      We are having trouble generating your custom report.
                      Please try again. If you continue to see this error,
                      please reach out to{' '}
                      <a
                        href='mailto:support@thrivetrm.com'
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        support@thrivetrm.com
                      </a>
                    </p>
                    <p className='u-textAlign-c'>{createReportError}</p>
                  </Card>
                ) : null}
              </>
            ) : null}

            {!loadTemplatesError && templates?.length === 0 ? (
              <div
                className='u-margin-64'
                data-test-id='search-custom-report-no-templates'
              >
                <p className='u-textAlign-c u-fontSize-medium u-fontWeight-bold'>
                  We are unable to find any templates for custom reports.
                </p>
                <p className='u-textAlign-c u-fontSize-small'>
                  If you continue to see this error, please reach out to{' '}
                  <a
                    href='mailto:support@thrivetrm.com'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    support@thrivetrm.com
                  </a>
                </p>
              </div>
            ) : null}

            {loadTemplatesError ? (
              <div className='u-margin-64'>
                <p className='u-textAlign-c u-fontSize-medium u-fontWeight-bold'>
                  We are having trouble loading your custom report templates.
                  Please try again.
                </p>
                <p className='u-textAlign-c u-fontSize-small'>
                  If you continue to see this error, please reach out to{' '}
                  <a
                    href='mailto:support@thrivetrm.com'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    support@thrivetrm.com
                  </a>
                </p>
              </div>
            ) : null}
            {hasSubmittedReportRequest ? (
              <div className='u-margin-64 u-textAlign-c'>
                <p className=' u-fontWeight-bold'>We‘re on it.</p>
                <p className='u-marginTop-16'>
                  We will generate your report and send it to{' '}
                  {reportRecipients.join(', ')}
                </p>
              </div>
            ) : null}
          </LoadingContainer>
        </Modal.Body>
        <Modal.Footer>
          {hasSubmittedReportRequest ? null : (
            <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
          )}
          <Form.SubmitButton
            isLoading={isCreatingReport}
            label={hasSubmittedReportRequest ? 'Finish' : 'Create Report'}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SearchCustomReportModal.propTypes = {
  initialRecipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  searchId: PropTypes.number.isRequired,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      rejected: PropTypes.bool.isRequired,
    }).isRequired,
  ),
};

export default SearchCustomReportModal;

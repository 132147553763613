import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import activitySchema from '../schema';

/**
 * Gets a property from an activity record
 * @param {*} state
 * @param {Number} id The ID of the activity record
 * @param {String} propertyName the key on the property record to get
 * @returns {*} The property, if found (the record exists and the property exists on that record).
 */
export default (state, id, propertyName) =>
  getEntityProperty(state, activitySchema.key, id, propertyName);

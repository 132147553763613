import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';

import EmailField from '../../../../components/forms/EmailField';

/**
 * Renders a field for selecting the options for generating the talent pool report.
 */
class TalentPoolReportField extends PureComponent {
  static createFieldState(
    name = 'talent_pool',
    { incumbentName, incumbentPrimaryTitle },
  ) {
    return FieldState.createNested(name, [
      InputField.createFieldState(
        'reportName',
        incumbentPrimaryTitle || incumbentName,
      ),
      EmailField.createFieldState(
        'emails',
        null,
        validators.requiredField('An email address'),
      ),
    ]);
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const { fieldState, ...rest } = this.props;

    return (
      <div className='TalentPoolReportField'>
        <div key='reportName'>
          <h2>Report Name</h2>
          <InputField
            {...rest}
            className='TalentPoolReportField__report_name'
            fieldState={fieldState.getNestedField('reportName')}
            key='reportName'
            onChange={this.handleFieldChange}
            type='reportName'
          />
        </div>
        <div key='recipients'>
          <h2>Recipients</h2>
          <EmailField
            clearable={false}
            fieldState={fieldState.getNestedField('emails')}
            id='TalentPoolReportField__recipients'
            label='Emails*'
            onChange={this.handleFieldChange}
          />
        </div>
      </div>
    );
  }
}

TalentPoolReportField.propTypes = {
  /**
   * The current value of this field.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the `fieldState` value changes.
   */
  onChange: PropTypes.func.isRequired,
};

export default TalentPoolReportField;

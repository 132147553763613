import PropTypes from 'prop-types';
import selectn from 'selectn';
import { connect } from 'react-redux';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
  branch,
  renderComponent,
} from 'recompose';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import withFormState from 'modules/forms/components/withFormState';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withTransaction from 'modules/transactions/components/withTransaction';
import withRevenueListFetched from './withRevenueListFetched';
import CompanyRevenueListField from './CompanyRevenueListField';
import updateRevenueListActionCreator from '../actions/updateRevenueList';
import getRevenue from '../selectors/getRevenue';
import withSortedRevenueIds from './withSortedRevenueIds';

/**
 * Provides props for managing a form for creating/editing a list of revenue records for a company.
 *
 * Creates the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying
 * CompanyRevenueListField FieldState
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default compose(
  setDisplayName('withCompanyRevenueListForm'),
  setPropTypes({
    /**
     * The ID of the company that the revenue's are associated with.
     */
    companyId: PropTypes.number.isRequired,

    /**
     * An optional formstate to initial the form with (handled by withFormState)
     */
    initialFormState: formStatePropType,

    /**
     * Called when the form has been successfully saved.
     */
    onSaved: PropTypes.func.isRequired,
  }),

  withProps(
    ({
      companyId: _companyId,
      initialFormState: _initialFormState,
      onSaved: _onSaved,
      ...incomingProps
    }) => ({
      incomingProps: incomingProps,
    }),
  ),

  // Fetch the revenue list and show a loading indicator until we have them. We don't want to
  // go any further because we want to delay creating the FieldState until we have the data we need.
  withRevenueListFetched,
  branch(
    ({ revenueList }) => !(revenueList && revenueList.has('ids')),
    renderComponent(LoadingIndicator),
  ),

  // Extract the IDs from the list state and sort them.
  withProps(({ revenueList }) => ({
    revenueIds: revenueList && revenueList.get('ids'),
  })),
  withSortedRevenueIds,

  // Get the underlying records associated with each ID.
  connect((state, { revenueIds }) => ({
    revenues: revenueIds ? revenueIds.map(id => getRevenue(state, id)) : [],
  })),

  /**
   * Create a FormState, initializing it with the value from `revenues`
   */
  withFormState(({ revenues }) =>
    CompanyRevenueListField.createFieldState('revenues', revenues),
  ),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onResetFormState,
      onSaved,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();

        // If all was good, call onSaved with the record's ID
        onSaved(selectn('payload.result.revenues', transaction));
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  /**
   * Include connected versions of `createRevenue` and `updateRevenue` that
   * are needed to submit.
   */
  connect(null, {
    updateRevenueList: updateRevenueListActionCreator,
  }),

  /**
   * Add a callback to handle submitting the action form.
   */
  withHandlers({
    // Called when the form should be submitted.
    onSubmit: ({
      companyId,
      formState,
      onFormStateChange,
      startTransaction,
      updateRevenueList,
    }) => e => {
      e.preventDefault();
      const transactionId = startTransaction();
      const fieldValue = formState.getFieldValue();

      updateRevenueList({
        companyId: companyId,
        revenues: fieldValue,
        transactionId: transactionId,
      });

      onFormStateChange(formState.startSubmit(transactionId));

      return preventDefaultHandler(e);
    },
  }),

  // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
  mapProps(
    ({
      formState,
      incomingProps,
      onCancel,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      onSaved: _onSaved,
      onSubmit,
      revenueId: _revenueId,
    }) => ({
      ...incomingProps,
      formState: formState,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onResetFormState: onResetFormState,
      onSubmit: onSubmit,
      onCancel: onCancel,
    }),
  ),
);

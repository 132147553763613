/* eslint-disable camelcase */
// ^ accommodate API response format
import url from 'modules/routing/url';
import createQueryActions, {
  transformSuggestions,
} from '../createQueryActions';
import {
  CONTACT_QUERY_URL,
  CONTACT_DUPLICATE_QUERY_INVALIDATE,
  CONTACT_DUPLICATE_QUERY_FETCH_START,
  CONTACT_DUPLICATE_QUERY_FETCH_SUCCESS,
  CONTACT_DUPLICATE_QUERY_FETCH_FAILURE,
  CONTACT_DUPLICATE_QUERY_CREATE,
  CONTACT_DUPLICATE_QUERY_DESTROY,
} from './constants';

export const getUrl = ({ term }) => url(CONTACT_QUERY_URL, term);

const {
  create: createDuplicateContactsQuery,
  destroy: destroyDuplicateContactsQuery,
  invalidate: invalidateDuplicateContactsQuery,
  query: queryDuplicateContacts,
} = createQueryActions({
  create: CONTACT_DUPLICATE_QUERY_CREATE,
  destroy: CONTACT_DUPLICATE_QUERY_DESTROY,
  invalidate: CONTACT_DUPLICATE_QUERY_INVALIDATE,
  start: CONTACT_DUPLICATE_QUERY_FETCH_START,
  success: CONTACT_DUPLICATE_QUERY_FETCH_SUCCESS,
  failure: CONTACT_DUPLICATE_QUERY_FETCH_FAILURE,
  transformResponse: response =>
    transformSuggestions(response).map(
      ({ data, full_name, primary_company_name, primary_title, ...rest }) => ({
        id: data.id,
        name: full_name,
        title: primary_title,
        company: primary_company_name,
        data: data,
        ...rest,
      }),
    ),
  getUrl: getUrl,
});

export {
  createDuplicateContactsQuery,
  destroyDuplicateContactsQuery,
  invalidateDuplicateContactsQuery,
  queryDuplicateContacts,
};

import { compose } from 'recompose';
import { POST } from 'modules/api/methods';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import defaultMethod from 'modules/api/actionModifiers/defaultMethod';
import {
  CREATE_START,
  CREATE_SUCCESS,
  CREATE_FAILURE,
} from 'modules/entities/actions/entityActionTypes';

/**
 * Creates an action for creating a candidacy (adding a contact to a search).
 * @param {Object} payload
 * @param {String|String[]} payload.contactId The ID of the contact to add to the search, or an
 *   array of contact IDs to create multiple candidacies at once.
 * @param {String} payload.searchId The ID of the search to add the contact to.
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default compose(
  fetchAction,
  createAsyncActions({
    startActionType: CREATE_START,
    successActionType: CREATE_SUCCESS,
    failureActionType: CREATE_FAILURE,
  }),
  defaultMethod(POST),
);

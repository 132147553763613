import PropTypes from 'prop-types';
import React from 'react';
import SearchOutreachesPanel from 'modules/outreaches/components/SearchOutreachesPanel';
import SearchPageSidebarPopover from './SearchPageSidebarPopover';

/**
 * Renders the "outreaches" popover content, which shows all outreach related to the given search.
 */
const OutreachPopover = ({ searchId, searchPage }) => (
  <SearchPageSidebarPopover
    className='OutreachPopover'
    searchId={searchId}
    searchPage={searchPage}
  >
    <SearchOutreachesPanel
      readOnly={true}
      renderRelatedSearches={false}
      searchId={searchId}
      title='All Search Outreach'
    />
  </SearchPageSidebarPopover>
);

OutreachPopover.propTypes = {
  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,

  /**
   * The current search page selected and displayed in the content portion of the search page
   * ("candidates", "applicants", "target_companies", etc)
   */
  searchPage: PropTypes.string.isRequired,
};

export default OutreachPopover;

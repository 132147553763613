import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { ratingSchema } from '../../schema';

/**
 * Gets a property from a rating record by the record's ID.
 * @param {*} state
 * @param {Number} id The ID of the contact rating record.
 * @param {String} propertyName The name of the property.
 * @returns {*} The property value for that rating, if found.
 */
export default (state, id, propertyName) =>
  getEntityProperty(state, ratingSchema.key, id, propertyName);

import PropTypes from 'prop-types';
import React from 'react';
import { onlyUpdateForPropTypes } from 'recompose';

/**
 * Renders a "Create" button that triggers `onCreateTalentPool` when clicked. The event's target
 * value (`event.target.value`) the contactId for which the talent pool should be created.
 */
export const CreateTalentPoolButton = ({
  contactId,
  label,
  onCreateTalentPool,
}) => (
  <button
    className='btn btn-primary btn-sm'
    onClick={onCreateTalentPool}
    type='button'
    value={contactId}
  >
    {label}
  </button>
);

CreateTalentPoolButton.propTypes = {
  /**
   * The contact ID that will be provided in the onCreateTalentPool callback.
   */
  contactId: PropTypes.number.isRequired,

  /**
   * The label to display for the button
   */
  label: PropTypes.node,

  /**
   * Called when the "Create" button is clicked. `event.target.value` will contain the contact ID.
   */
  onCreateTalentPool: PropTypes.func.isRequired,
};

CreateTalentPoolButton.defaultProps = {
  label: 'Create',
};

export default onlyUpdateForPropTypes(CreateTalentPoolButton);

import PropTypes from 'prop-types';
import React from 'react';
import RecentSearchIntroductions from 'modules/introductions/components/RecentSearchIntroductions';
import SearchPageSidebarPopover from './SearchPageSidebarPopover';

/**
 * Renders the introductions popover content, showing all introductions related to the given search.
 */
const IntroductionsPopover = ({ searchId, searchPage }) => (
  <SearchPageSidebarPopover
    className='IntroductionsPopover'
    searchId={searchId}
    searchPage={searchPage}
  >
    <RecentSearchIntroductions
      readOnly={true}
      searchId={searchId}
      title={<h2>All Search Introductions</h2>}
    />
  </SearchPageSidebarPopover>
);

IntroductionsPopover.propTypes = {
  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,

  /**
   * The current search page selected and displayed in the content portion of the search page
   * ("candidates", "applicants", "target_companies", etc)
   */
  searchPage: PropTypes.string.isRequired,
};

export default IntroductionsPopover;

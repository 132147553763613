import { connect } from 'react-redux';
import getInvestmentProperty from '../selectors/getInvestmentProperty';

/**
 * A Higher order component that sorts a list of investmentIds in order of their dates
 */
export default connect(
  (state, { investmentIds }) => ({
    investmentIds:
      investmentIds &&
      investmentIds
        .sortBy(id => getInvestmentProperty(state, id, 'date'))
        .reverse(),
  }),
  {},
);

import { NAME } from '../../constants';

export const CANDIDACY_TAGS_FETCH_START = `${NAME}/tags/FETCH_START`;
export const CANDIDACY_TAGS_FETCH_SUCCESS = `${NAME}/tags/FETCH_SUCCESS`;
export const CANDIDACY_TAGS_FETCH_FAILURE = `${NAME}/tags/FETCH_FAILURE`;

export const CANDIDACY_TAG_ADD_START = `${NAME}/tags/ADD_START`;
export const CANDIDACY_TAG_ADD_SUCCESS = `${NAME}/tags/ADD_SUCCESS`;
export const CANDIDACY_TAG_ADD_FAILURE = `${NAME}/tags/ADD_FAILURE`;

export const CANDIDACY_TAG_CREATE_START = `${NAME}/tags/CREATE_START`;
export const CANDIDACY_TAG_CREATE_SUCCESS = `${NAME}/tags/CREATE_SUCCESS`;
export const CANDIDACY_TAG_CREATE_FAILURE = `${NAME}/tags/CREATE_FAILURE`;

export const CANDIDACY_TAG_REMOVE_START = `${NAME}/tags/REMOVE_START`;
export const CANDIDACY_TAG_REMOVE_SUCCESS = `${NAME}/tags/REMOVE_SUCCESS`;
export const CANDIDACY_TAG_REMOVE_FAILURE = `${NAME}/tags/REMOVE_FAILURE`;

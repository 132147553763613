import PropTypes from 'prop-types';
import selectn from 'selectn';
import { connect } from 'react-redux';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
  branch,
  renderComponent,
} from 'recompose';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import withFormState from 'modules/forms/components/withFormState';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withTransaction from 'modules/transactions/components/withTransaction';
import withEmployeeCountListFetched from './withCompanyEmployeeCountListFetched';
import CompanyEmployeeCountListField from './CompanyEmployeeCountListField';
import updateEmployeeCountListActionCreator from '../actions/updateEmployeeCountList';
import mapEmployeeCountIdsToEmployeeCounts from './mapEmployeeCountIdsToEmployeeCounts';
import withSortedEmployeeCountIds from './withSortedEmployeeCountIds';

/**
 * Provides props for managing a form for creating/editing a employeeCount.
 *
 * Creates the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying
 * CompanyEmployeeCountListField FieldState
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default compose(
  setDisplayName('withCompanyEmployeeCountListForm'),
  setPropTypes({
    /**
     * The companyId is required for creating new records.
     */
    companyId: PropTypes.number.isRequired,

    /**
     * An optional formstate to initial the form with (handled by withFormState)
     */
    initialFormState: formStatePropType,

    /**
     * Called when the form has been successfully saved.
     */
    onSaved: PropTypes.func.isRequired,
  }),

  withProps(
    ({
      companyId,
      initialFormState: _initialFormState,
      onSaved: _onSaved,
      ...incomingProps
    }) => ({
      incomingProps: incomingProps,
      companyId: companyId,
    }),
  ),
  withEmployeeCountListFetched,
  branch(
    ({ employeeCountList }) =>
      !(employeeCountList && employeeCountList.has('ids')),
    renderComponent(LoadingIndicator),
  ),
  withProps(({ employeeCountList }) => ({
    employeeCountIds: employeeCountList && employeeCountList.get('ids'),
  })),
  withSortedEmployeeCountIds,
  mapEmployeeCountIdsToEmployeeCounts,

  /**
   * Create a FormState, initializing it with the value from `employeeCount`
   */
  withFormState(({ employeeCounts }) =>
    CompanyEmployeeCountListField.createFieldState(
      'employee_counts',
      employeeCounts,
    ),
  ),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onResetFormState,
      onSaved,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();

        // If all was good, call onSaved with the record's ID
        onSaved(selectn('payload.result.employee_statisitics', transaction));
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  /**
   * Include connected versions of `createEmployeeCount` and `updateEmployeeCount` that
   * are needed to submit.
   */
  connect(null, {
    updateEmployeeCountList: updateEmployeeCountListActionCreator,
  }),

  /**
   * Add a callback to handle submitting the action form.
   */
  withHandlers({
    // Called when the form should be submitted.
    onSubmit: ({
      companyId,
      formState,
      onFormStateChange,
      startTransaction,
      updateEmployeeCountList,
    }) => e => {
      e.preventDefault();
      const transactionId = startTransaction();
      const fieldValue = formState.getFieldValue();

      updateEmployeeCountList({
        companyId: companyId,
        employeeCounts: fieldValue,
        transactionId: transactionId,
      });

      onFormStateChange(formState.startSubmit(transactionId));

      return preventDefaultHandler(e);
    },
  }),

  // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
  mapProps(
    ({
      companyId,
      employeeCountId: _employeeCountId,
      // These were used above, no need to pass them down.
      formState,
      incomingProps,
      onCancel,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      onSaved: _onSaved,
      onSubmit,
    }) => ({
      ...incomingProps,
      companyId: companyId,
      formState: formState,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onResetFormState: onResetFormState,
      onSubmit: onSubmit,
      onCancel: onCancel,
    }),
  ),
);

import routes from 'modules/routing/routes';
import { compose } from 'recompose';
import { PATCH } from 'modules/api/methods';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import defaultMethod from 'modules/api/actionModifiers/defaultMethod';
import withOptions from 'modules/api/actionModifiers/withOptions';
import {
  QUESTION_ANSWER_LIST_UPDATE_START,
  QUESTION_ANSWER_LIST_UPDATE_SUCCESS,
  QUESTION_ANSWER_LIST_UPDATE_FAILURE,
} from './actionTypes';

/**
 * Creates an action for updating a list of question_answers
 * @param {Object} payload
 * @param {Number} payload.parentId
 * @param {String} payload.parentType
 * @param {questionAnswerRecord[]} payload.questionAnswers The question
 * answer records to update on the server.
 * @param {String} [payload.transactionId] An optional transaction ID to track the state
 * of the request.
 */
export default compose(
  fetchAction,
  createAsyncActions({
    startActionType: QUESTION_ANSWER_LIST_UPDATE_START,
    successActionType: QUESTION_ANSWER_LIST_UPDATE_SUCCESS,
    failureActionType: QUESTION_ANSWER_LIST_UPDATE_FAILURE,
  }),
  withOptions(({ parentId, parentType, questionAnswers }) => ({
    body: {
      question_answers: questionAnswers,
      parent_id: parentId,
      parent_type: parentType,
    },
    url: routes.api_v1_question_answers(),
  })),
  defaultMethod(PATCH),
);

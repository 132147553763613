import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableHeader from 'modules/core/components/TableHeader';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Icon from '@thrivetrm/ui/components/Icon';
import Card from '@thrivetrm/ui/components/Card';

const RecordIndexNoDataTable = ({ headers, isLoading }) => {
  const { networks, recordLabel, recordLabelPlural } = useSelector(
    state => state.recordIndex,
  );
  const { filters, networkId } = useSelector(
    state => state.recordIndex.savedView,
  );

  const selectedNetwork =
    networks?.networks?.find(network => network.id === networkId) || null;

  const isNetworkEmpty = selectedNetwork?.network_members_count === 0;

  const hasFilters = filters && Object.entries(filters).length !== 0;

  const getCardData = () => {
    if (isNetworkEmpty && !isLoading) {
      return {
        icon: 'relations',
        title: `Add ${recordLabelPlural} to “${selectedNetwork.name}”`,
        text: `Remove the network filter to see all available ${recordLabelPlural} and add ${recordLabelPlural} to this network`,
      };
    } else if (filters?.keyword && !isLoading) {
      return {
        icon: 'search',
        title: `We couldn’t find any results that match “${filters.keyword}”`,
        text: 'Try another keyword',
      };
    } else if (hasFilters && !isNetworkEmpty && !isLoading) {
      return {
        icon: 'filter',
        title: 'We couldn’t find any results that match your filters',
        text: 'Try removing a few filters',
      };
    } else if (!hasFilters && !isLoading) {
      return {
        icon: 'add',
        title: `No ${recordLabelPlural} Exist Yet`,
        text: `There aren’t any ${recordLabelPlural} in your database yet. Add a ${recordLabel} by clicking the “Add ${recordLabel}” button`,
      };
    } else {
      return null;
    }
  };

  const cardData = getCardData();

  return (
    <div className='RecordIndexNoDataTable'>
      <table className='RecordIndexNoDataTable__table'>
        <TableHeader
          hasSelectedAll={false}
          hasSelectedSome={false}
          headers={headers}
          onSelect={() => {}}
          onSortChange={() => {}}
        />
      </table>
      <LoadingContainer isLoading={isLoading} minHeight={420}>
        {cardData ? (
          <Card
            className='RecordIndexNoDataTable__card'
            role='alert'
            type='gray'
          >
            <Icon
              backgroundColor='white'
              color='blue'
              size='large'
              type={cardData.icon}
            />
            <h3>{cardData.title}</h3>
            <p>{cardData.text}</p>
          </Card>
        ) : null}
      </LoadingContainer>
    </div>
  );
};

RecordIndexNoDataTable.defaultProps = {
  headers: [],
  isLoading: false,
};

RecordIndexNoDataTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      isPinned: PropTypes.bool.isRequired,
      isSortable: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
    }),
  ),
  isLoading: PropTypes.bool,
};

export default RecordIndexNoDataTable;

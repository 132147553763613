import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import CancelButton from 'modules/forms/components/CancelButton';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withContactCareerHighlightsForm from './withContactCareerHighlightsForm';
import RichTextField from '../../../../components/forms/richtext/RichTextField';
import withDraftStorage from '../../../../components/forms/withDraftStorage';

/**
 * A form for editing a contact's "career highlights", whic consists of a single
 * rich text edit field.
 */
const ContactCareerHighlightsForm = ({
  draft,
  formState,
  onCancel,
  onDraftChange,
  onFieldStateChange,
  onSubmit,
  ...fieldProps
}) => {
  return (
    <Form formState={formState} onSubmit={onSubmit}>
      <RichTextField
        {...fieldProps}
        disabled={formState.isSubmitting()}
        fieldState={formState.getFieldState()}
        lastUpdatedTime={draft?.content && draft?.lastUpdatedTime}
        onChange={onFieldStateChange}
        onDraftChange={onDraftChange}
        showErrors={formState.wasSubmitted() || 'blurred'}
      />
      <FormErrorMessage formState={formState} />
      <div className='Form__footer'>
        <CancelButton onClick={onCancel} />
        <SubmitButton formState={formState} onClick={onSubmit} />
      </div>
    </Form>
  );
};

ContactCareerHighlightsForm.propTypes = {
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),

  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the cancel button is clicked.
   */
  onCancel: PropTypes.func.isRequired,
  onDraftChange: PropTypes.func,
  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,
};

/**
 * The connected version of this component includes a FormState/FieldState and
 * handlers for submitting the form.
 */
export default compose(
  setDisplayName('ContactCareerHighlightsForm(enhanced))'),
  setPropTypes({
    /**
     * The ID of the contact for which the career highlights will be edited.
     */
    contactId: PropTypes.number,

    /**
     * Called when the cancel button on the form is clicked.
     */
    onCancel: PropTypes.func.isRequired,

    /**
     * Called when the form has been successfully saved.
     */
    onSaved: PropTypes.func.isRequired,
  }),
  withDraftStorage(),
  // Provides the field state, form state, and submission handling.
  withContactCareerHighlightsForm,

  /**
   * Prevent props from being passed on to the underlying fieldState
   */
  mapProps(
    ({
      /* eslint-disable no-unused-vars */ contactId,
      onFormStateChange,
      onResetFormState,
      onSaved /* eslint-enable no-unused-vars */,
      ...rest
    }) => ({
      ...rest,
    }),
  ),
)(ContactCareerHighlightsForm);

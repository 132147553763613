import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Pagination from '@thrivetrm/ui/components/Pagination';
import SegmentedControl from '@thrivetrm/ui/components/SegmentedControl';
import Table from 'modules/core/components/Table';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { useGetIndexTableRecordsQuery } from 'services/apiV1/indexTableRecords';

const RESULTS_PER_PAGE_OPTIONS = [5, 25, 100];

const CompanyContactsTable = ({ companyId, filters }) => {
  const hasConnectionsFeature = useFeatureCheck('feature.connections_gate');
  const [sort, setSort] = useState({
    direction: 'asc',
    field: 'primary_identifier',
  });
  const [resultsPerPage, setResultsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    'name',
    'primary_position_name',
    'phone',
    'preferred_email',
    ...(hasConnectionsFeature ? ['currently_reports_to'] : []),
    'social_link',
    'location',
    'last_contacted_on',
  ];

  // We use the presence of filter set data in the DOM to decide which version of the index page to show
  const isUsingV5UI = Boolean(document.querySelector('[data-filter-sets]'));

  const {
    data: contactsData,
    isFetching: isLoadingContacts,
  } = useGetIndexTableRecordsQuery({
    columns: columns,
    filters: { company_id: companyId, ...filters },
    page: currentPage,
    recordType: 'contacts',
    resultsPerPage: resultsPerPage,
    sort: sort,
    version: isUsingV5UI ? 'v5' : 'v4',
  });

  const handleSortDirectionChange = sortField => {
    // Default to ascending sort order
    let newSortDirection = 'asc';
    // If sorting on the same field, "flip" the sort direction
    if (sortField === sort.field) {
      newSortDirection = sort.direction === 'asc' ? 'desc' : 'asc';
    }

    setSort({
      direction: newSortDirection,
      field: sortField,
    });
  };

  const handleResultsPerPageChange = newResultsPerPage => {
    setCurrentPage(1);
    setResultsPerPage(newResultsPerPage);
  };

  return (
    <LoadingContainer isLoading={isLoadingContacts} minHeight='120px'>
      {contactsData?.rows?.length ? (
        <>
          <Table
            headers={contactsData.columns}
            onSortChange={handleSortDirectionChange}
            rows={contactsData.rows}
            sortDirection={sort.direction}
            sortKey={sort.field}
          />
          <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween u-marginTop-24'>
            <Pagination
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              resultsPerPage={resultsPerPage}
              totalPages={contactsData.totalPages}
              totalResults={contactsData.totalResults}
            />
            <SegmentedControl
              onChange={handleResultsPerPageChange}
              segments={RESULTS_PER_PAGE_OPTIONS}
              selectedSegmentLabel={resultsPerPage}
              size='small'
            />
          </div>
        </>
      ) : null}
      {contactsData?.rows?.length === 0 && !isLoadingContacts ? (
        <h3 className='u-marginVertical-32 u-textAlign-c u-textColor-gray50'>
          No Contacts Found
        </h3>
      ) : null}
    </LoadingContainer>
  );
};

CompanyContactsTable.propTypes = {
  companyId: PropTypes.number.isRequired,
  filters: PropTypes.shape({
    country_code: PropTypes.arrayOf(PropTypes.string),
    filter_current_company: PropTypes.string,
    filter_current_position: PropTypes.string,
    filter_past_company: PropTypes.string,
    filter_past_position: PropTypes.string,
    filter_positions: PropTypes.string,
    position: PropTypes.string,
    us_state: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default CompanyContactsTable;

import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import CompanyLocationName from 'modules/companies/components/CompanyLocationName';
import mapTargetCompanyIdToCompanyId from './mapTargetCompanyIdToCompanyId';

export default compose(
  setDisplayName('TargetCompanyLocationName'),
  setPropTypes({
    targetCompanyId: PropTypes.number.isRequired,
  }),
  mapTargetCompanyIdToCompanyId,
)(CompanyLocationName);

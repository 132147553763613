import React from 'react';
import routes from 'modules/routing/routes';
import PropTypes from 'prop-types';
import DuplicateMergeAttributes from './DuplicateMergeAttributes';

const AUTO_MERGE_ATTRIBUTES = [
  'Addresses',
  'Aliases',
  'Comments',
  'Contact Positions',
  'Documents',
  'Enriched Sectors',
  'Events',
  'Funding Rounds',
  'Introductions',
  'Investments',
  'Networks',
  'Number of Employees',
  'Off Limits Reasons',
  'Parent Companies',
  'Recruitment Activity',
  'Revenues',
  'Searches',
  'Tags',
];

const companyAIcon = (
  <svg xmlns='http://www.w3.org/2000/svg'>
    <path d='M180 283V132.778c0-10.37 6.268-18.778 14-18.778h56c7.732 0 14 8.407 14 18.778V283M180 170.5h84M180 226.5h84M193 114l28.5-56 28.5 56M20 282V57.2L81 1l61 56.2V282M20 86l122 66M20 217l122 66M20 217l122-66M20 67.5h122M282.625 283.625H1.375' />
  </svg>
);

const companyBIcon = (
  <svg xmlns='http://www.w3.org/2000/svg'>
    <path d='M180 283V132.778c0-10.37 6.268-18.778 14-18.778h56c7.732 0 14 8.407 14 18.778V283M180.75 185h33.5M214.75 114l-.25-13.5M227.75 114l-.25-25.5M142 135.5H58.808M180.75 213h51.5M142 107.5H89.5' />
    <path d='M193 114l28.5-56 28.5 56M20 282V57.2L81 1l61 56.2V282M20 67.5h122M282.625 283.625H1.375' />
    <path
      d='M64 283.625h37.5v-37.5c0-10.355-8.395-18.75-18.75-18.75S64 235.77 64 246.125v37.5z'
      fill='#EEEEF3'
    />
  </svg>
);

const CompanyDuplicateMergeAttributes = ({ match }) => {
  const { companyAId, companyBId } = match.params;
  const companyAttributesEndpoint = routes.edit_api_v1_company_merge({
    company_id: companyAId,
    id: companyBId,
  });
  const companyMergeEndpoint = routes.api_v1_company_merge({
    company_id: companyAId,
    id: companyBId,
  });

  return (
    <DuplicateMergeAttributes
      attributesEndpoint={companyAttributesEndpoint}
      autoMergeAttributes={AUTO_MERGE_ATTRIBUTES}
      avatarShape='square'
      mergeEndpoint={companyMergeEndpoint}
      recordAIcon={companyAIcon}
      recordAProfileUrl={routes.company({ id: companyAId })}
      recordBIcon={companyBIcon}
      recordLabel='Company'
    />
  );
};

CompanyDuplicateMergeAttributes.propTypes = {
  // provided by react-router
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyAId: PropTypes.string.isRequired,
      companyBId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CompanyDuplicateMergeAttributes;

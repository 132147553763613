import companySchema from 'modules/companies/companySchema';
import { contactSchema } from 'modules/contacts/schema';
import fundSchema from 'modules/funds/schema';

companySchema.define({
  partners: [contactSchema],
  funds: [fundSchema],
});

export * from './components';

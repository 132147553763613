import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';

import withDefaultRejectionReason from 'modules/search-stages/components/withDefaultRejectionReason';
import JobApplicationActionListPopover from './JobApplicationActionListPopover';
import JobApplicationRejectionForm from './JobApplicationRejectionForm';
import mapJobApplicationIdToJobApplication from './mapJobApplicationIdToJobApplication';
import withJobApplicationFetched from './withJobApplicationFetched';
import withContactReviewCreation from './withContactReviewCreation';

/**
 * Used to signify that the "actions list" popover should be shown
 * @const {String}
 */
const POPOVER_ACTIONS_LIST = 'POPOVER_ACTIONS_LIST';

/**
 * Used to signify that the "rejection form" popover should be shown
 * @const {String}
 */
const POPOVER_REJECT_FORM = 'POPOVER_REJECT_FORM';

/**
 * Renders a button that can be used to perform actions against a job application.
 * Initially shows a list of actions. Ff the "rejected" stage is selected, a rejection
 * form is shown requiring the user to fill out a rejection reason and comment before
 * submitting the rejection.
 */
const JobApplicationActionButton = ({
  componentId,
  defaultRejectionReason,
  getScrollContainer,
  getTargetRef,
  handleAddToSearch,
  handleHidePopover,
  handleShowRejectionForm,
  handleTogglePopover,
  jobApplication,
  popoverForm,
  setTargetRef,
}) => {
  const isUpdating =
    jobApplication && jobApplication.getIn(['_meta', 'isUpdating']);
  return [
    <button
      aria-expanded={Boolean(popoverForm)}
      aria-haspopup='true'
      className='JobApplicationActionButton btn btn-default'
      disabled={isUpdating || !jobApplication}
      key='button'
      onClick={handleTogglePopover}
      ref={setTargetRef}
      type='button'
    >
      <i
        className={classnames('fa', {
          'fa-share': !isUpdating,
          'fa-refresh': isUpdating,
          'fa-spin': isUpdating,
        })}
      />
    </button>,
    jobApplication && (
      <Overlay
        animation={false}
        container={getScrollContainer}
        containerPadding={20}
        id={componentId}
        key='overlay'
        onHide={handleHidePopover}
        placement='bottom'
        rootClose={true}
        shouldUpdatePosition={true}
        show={Boolean(popoverForm)}
        target={getTargetRef}
      >
        {popoverForm === POPOVER_ACTIONS_LIST ? (
          <JobApplicationActionListPopover
            id={`${componentId}-JobApplicationActionListPopover`}
            isJobApplicationRejected={Boolean(
              jobApplication.get('rejection_reason'),
            )}
            onAddToSearch={handleAddToSearch}
            onReject={handleShowRejectionForm}
          />
        ) : (
          <Popover
            className='JobApplicationActionButton__rejectionPopover'
            id={`${componentId}-reject-form-popover`}
            title='Rejection Reason'
          >
            <JobApplicationRejectionForm
              defaultRejectionReason={defaultRejectionReason}
              jobApplicationId={jobApplication.get('id')}
              onCancel={handleHidePopover}
              onSaved={handleHidePopover}
            />
          </Popover>
        )}
      </Overlay>
    ),
  ];
};

JobApplicationActionButton.propTypes = {
  /**
   * Props provided by withScrollContainerRef (getScrollContainer, getTargetRef, setTargetRef)
   */
  ...withScrollContainerRef.propTypes,

  /**
   * A unique ID for the component instance (provided by `withComponentId`)
   */
  componentId: PropTypes.string.isRequired,

  /**
   * Called when the "add to search" menu item has been selected
   */
  handleAddToSearch: PropTypes.func.isRequired,

  /**
   * Called when the popover should be hidden (causing `popoverForm` to be set to `null`)
   */
  handleHidePopover: PropTypes.func.isRequired,

  /**
   * Called when the rejection form popover should be displayed.
   */
  handleShowRejectionForm: PropTypes.func.isRequired,

  /**
   * Called when the button is clicked, causing the `popoverForm` value to be toggled
   * between null and POPOVER_ACTIONS_LIST,
   */
  handleTogglePopover: PropTypes.func.isRequired,

  /**
   * The job application record.
   */
  jobApplication: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isUpdating: PropTypes.bool,
    }),
  }),

  /**
   * Which popover should be rendered (if any).
   */
  popoverForm: PropTypes.oneOf([POPOVER_ACTIONS_LIST, POPOVER_REJECT_FORM]),
};

export default compose(
  setDisplayName('JobApplicationActionButton(enhanced)'),
  setPropTypes({
    jobApplicationId: PropTypes.number.isRequired,

    /**
     * The search ID is needed by `withContactReviewCreation`. It really shouldn't be, since
     * that can be easily figured out given the jobApplicationId. But until that is fixed
     * we're stuck with this.
     */
    searchId: PropTypes.number.isRequired,
  }),

  mapJobApplicationIdToJobApplication,
  withJobApplicationFetched,

  withComponentId(),
  withScrollContainerRef,

  withState('popoverForm', 'setPopoverForm'),
  withContactReviewCreation,
  withDefaultRejectionReason,
  withHandlers({
    /**
     * Used by the main button, toggles the visibility of the popover
     */
    handleTogglePopover: ({ popoverForm, setPopoverForm }) => () =>
      setPopoverForm(popoverForm ? null : POPOVER_ACTIONS_LIST),

    /**
     * Hides the popover entirely
     */
    handleHidePopover: ({ setPopoverForm }) => () => setPopoverForm(null),

    /**
     * Shows the rejection for popover
     */
    handleShowRejectionForm: ({ setPopoverForm }) => () =>
      setPopoverForm(POPOVER_REJECT_FORM),

    handleAddToSearch: ({
      handleCreateContactReview,
      jobApplicationId,
    }) => () =>
      handleCreateContactReview({ jobApplicationId: jobApplicationId }),
  }),
)(JobApplicationActionButton);

import { PARENT_TYPES, DATE_GROUPS } from '../constants';
import getInterviewList from './getInterviewList';

/**
 * Determines if a list of interviews should be fetched.
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CANDIDACY or PARENT_SEARCH)
 * @param {Number} parentId The parent ID (a candidacy ID or search ID, depending on
 *   the value of `parentType`)
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default (state, parentType, parentId, filter) => {
  if (!PARENT_TYPES.includes(parentType)) {
    throw new Error(
      `Invalid parentType for shouldFetchInterviewList: ${parentType}`,
    );
  }

  if (filter && !DATE_GROUPS.includes(filter)) {
    throw new Error(`Invalid filter for shouldFetchInterviewList: ${filter}`);
  }

  if (!parentId) {
    return false;
  }

  const interviewList = getInterviewList(state, parentType, parentId, filter);

  if (!interviewList) {
    return true;
  }

  if (interviewList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (interviewList.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !interviewList.has('ids') ||
    Boolean(interviewList.getIn(['_meta', 'isInvalidated']))
  );
};

import fetchNotifications from './fetchNotifications';

export default function fetchNextPage(notifications) {
  return dispatch => {
    if (!notifications.has('data')) {
      return false;
    }

    const oldest = notifications
      .get('data')
      .sortBy(notification => notification.getIn(['data', 'sequential_id']))
      .first();

    if (oldest) {
      return dispatch(
        fetchNotifications({
          category: notifications.getIn(['meta', 'category']),
          before: oldest.getIn(['data', 'sequential_id']),
        }),
      );
    }

    return false;
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Menu from '@thrivetrm/ui/components/Menu';
import DateElement from '@thrivetrm/ui/components/Date';
import ExpandableCard from '@thrivetrm/ui/components/ExpandableCard';
import Tag from 'modules/core/components/Tag';
import StrengthIndicator from 'modules/core/components/StrengthIndicator';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import EmailLink from '@thrivetrm/ui/components/EmailLink';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import isAdmin from 'modules/auth/selectors/isAdmin';
import isCrmUser from 'modules/auth/selectors/isCrmUser';
import useQuickView from 'modules/quick-view/useQuickView';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import { markAsStale } from 'modules/recordIndex/recordIndexSlice';
import { fetchContact } from 'modules/contacts/actions/contacts/index';
import { fetchReferenceList } from 'modules/references/actions';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import selectedContact from 'selectors/contact/selectedContact';
import { useGetCandidacyReferencesQuery } from 'services/apiV1/references';
import {
  useDeleteConnectionMutation,
  RecordTypesWithConnections,
  getConnectionTypeLabel,
} from 'services/apiV1/connections';
import { fetchNotifications } from 'actions/contacts';

import ConnectionReferenceView from './ConnectionReferenceView';

export const ConnectionStrengthAndMenu = ({
  connectionName,
  connectionStrength,
  currentContactName,
  isStrengthVisible,
  onEdit,
  onRemove,
}) => {
  const [
    isDeleteConfirmationDialogOpen,
    openDeleteConfirmationDialogOpen,
    closeDeleteConfirmationDialogOpen,
  ] = useToggle(false);
  const menuHasActions = Boolean(onEdit) || Boolean(onRemove);

  return (
    <div className='u-flex'>
      {isStrengthVisible ? (
        <Tooltip
          className='u-fontSize-small u-noWrap ConnectionsCard__strengthIndicatorTooltip'
          content={`Connection Strength: ${connectionStrength || 'Not Set'}`}
          position='bottomLeft'
        >
          <StrengthIndicator
            className='u-marginHorizontal-12 u-marginVertical-2'
            data-testid='strength-indicator'
            strength={connectionStrength?.toLowerCase()}
          />
        </Tooltip>
      ) : null}
      {menuHasActions ? (
        // flex styling messes with the Menu's styles so adding this div ensures things appear correctly
        <div>
          <Menu
            button={
              <ButtonSecondary
                aria-label='Connection Options'
                icon='options'
                size='small'
              />
            }
            isPinnedRight={true}
          >
            {onEdit ? (
              <Menu.Item icon='edit' onClick={onEdit}>
                Edit
              </Menu.Item>
            ) : null}
            {onRemove ? (
              <Menu.Item
                icon='delete'
                onClick={openDeleteConfirmationDialogOpen}
              >
                Remove
              </Menu.Item>
            ) : null}
          </Menu>
          {isDeleteConfirmationDialogOpen && (
            <ConfirmationDialog
              cancelLabel='No, Keep Connection'
              confirmLabel='Yes, Remove Connection'
              isOpen={isDeleteConfirmationDialogOpen}
              onClose={closeDeleteConfirmationDialogOpen}
              onConfirm={onRemove}
              title='Remove Connection?'
            >
              <p className='u-textAlign-l u-fontSize-medium'>
                Are you sure you want to remove the Connection between{' '}
                {currentContactName} and {connectionName}?
              </p>
              <p className='u-textAlign-l u-margin-n u-fontSize-medium'>
                Notes associated with this Connection will be deleted.
              </p>
              <h4 className='u-margin-n u-textAlign-l'>
                This action cannot be undone.
              </h4>
            </ConfirmationDialog>
          )}
        </div>
      ) : null}
    </div>
  );
};

ConnectionStrengthAndMenu.defaultProps = {
  isStrengthVisible: true,
};

ConnectionStrengthAndMenu.propTypes = {
  connectionName: PropTypes.string,
  connectionStrength: PropTypes.string,
  currentContactName: PropTypes.string,
  isStrengthVisible: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

export const ConnectionsCard = ({ connection, contactFirstName }) => {
  const {
    connectedOn,
    connectionInfo,
    connectionStrength,
    connectionType,
    createdBy,
    id,
    notes,
    reference,
    referralCompany,
    updatedAt,
  } = connection;
  const dispatch = useDispatch();
  const { navigateTo, pathParams } = useQuickView();
  const { contactId } = pathParams;
  const currentUserId = useSelector(getCurrentUserId);
  const isAdminUser = useSelector(isAdmin);
  const canDeleteConnection = isAdminUser || createdBy.id === currentUserId;
  const { pathname } = window.location;
  const candidacyId = pathname.includes('searches')
    ? pathname.split('/')[4]
    : null;

  const legacyContactData = useSelector(state => selectedContact(state));
  const referenceNotification =
    legacyContactData
      ?.getIn(['notifications', 'data'])
      ?.find(
        notification => notification.getIn(['data', 'type']) === 'reference',
      ) ?? null;
  const hasReferencesConnectionsFeature = useFeatureCheck(
    'feature.references_connections',
  );
  const hasReferenceConnectionV2 = useFeatureCheck(
    'development.references_connection_v2',
  );
  const [deleteConnection] = useDeleteConnectionMutation();

  const {
    refetch: loadCandidacyReferences,
  } = useGetCandidacyReferencesQuery(candidacyId, { skip: true });

  /**
   * Only certain user roles are allowed to edit a connection
   *  - Admin -> isAdmin
   *  - Lead -> isEmployeeUser
   *  - Employee -> isEmployeeUser
   *  - Recruiter -> isEmployeeUser
   *  - Partner -> isEmployeeUser
   *  - CRM User -> isCrmUser
   */
  const canEditConnection =
    useSelector(state => isEmployeeUser(state) || isCrmUser(state)) ||
    isAdminUser;
  const onEdit = canEditConnection
    ? () => {
        navigateTo(`/contact/${contactId}/connections/${id}/edit`);
      }
    : undefined;

  const connectionTag = (
    <Tag
      className='ConnectionsCard__headerTag'
      name={getConnectionTypeLabel(connectionType, contactFirstName)}
    />
  );
  const handleRemoveConnection = canDeleteConnection
    ? () => {
        deleteConnection({
          contactId: contactId,
          connectionId: id,
        })
          .unwrap()
          .then(() => {
            dispatch(markAsStale());
            dispatch(fetchContact({ id: contactId }));
            dispatch(toastSuccess('Successfully removed connection'));
            // updates the list of references on the references side panel
            if (reference && candidacyId) {
              if (hasReferenceConnectionV2) {
                loadCandidacyReferences();
              } else {
                dispatch(
                  fetchReferenceList({
                    parentId: candidacyId,
                    parentType: 'candidacy',
                  }),
                );
              }
            }
            // updates contact activity timeline if the reference type of notification exists
            if (reference && referenceNotification?.size) {
              dispatch(
                fetchNotifications({
                  limit: legacyContactData.getIn(['notifications', 'data'])
                    .size,
                  contactId: contactId,
                  filters: legacyContactData
                    .getIn(['notifications', 'meta', 'filters'])
                    .toJS(),
                }),
              );
            }
          });
      }
    : undefined;

  const formattedConnectedOnDate = moment(connectedOn).format('MMMM YYYY');

  const hasInverseReference = Boolean(connection?.inverseReference?.createdAt);
  const expandedContent = (
    <div>
      {hasReferencesConnectionsFeature &&
      (connection?.reference || connection?.inverseReference) ? (
        <ConnectionReferenceView
          contactFirstName={contactFirstName}
          contactId={contactId}
          hasInverseReference={hasInverseReference}
          refereeFirstName={
            hasInverseReference
              ? connection.inverseReference.contactFirstName
              : connection.reference.refereeFirstName
          }
          refereeId={
            hasInverseReference
              ? connection.inverseReference.contactId
              : connection.reference.refereeId
          }
          reference={connection?.reference ?? connection?.inverseReference}
        />
      ) : null}
      {notes ? <p className='u-marginVertical-12'>{notes}</p> : null}
      {referralCompany?.id ? (
        <p className='u-textColor-gray60 u-fontWeight-light u-marginVertical-12'>
          Referral Company:{' '}
          <a href={`/companies/${referralCompany.id}`}>
            {referralCompany.name}
          </a>
        </p>
      ) : null}

      <h6 className='u-marginBottom-2 u-fontWeight-normal'>
        <b>Connection Date:</b> {formattedConnectedOnDate}
      </h6>
      <div className='u-flex u-flexJustify-spaceBetween u-marginVertical-2'>
        <h6 className='u-fontWeight-normal'>
          <b>Added By:</b>{' '}
          <EmailLink emailAddress={createdBy.email}>
            {createdBy.fullName || createdBy.email}
          </EmailLink>
        </h6>
        <h6 className='u-textColor-gray40 u-fontWeight-normal'>
          Updated <DateElement date={updatedAt} />
        </h6>
      </div>
    </div>
  );

  const connectionName = connectionInfo.fullName || connectionInfo.email;
  const connectionUrl =
    connectionInfo.type?.toLowerCase() === RecordTypesWithConnections.USER
      ? `mailto:${connectionInfo.email}`
      : `/contacts/${connectionInfo.id}`;

  return (
    <ExpandableCard
      className='u-marginBottom-16'
      expandedContent={expandedContent}
      type='shadow'
    >
      <div className='u-flex u-flexJustify-spaceBetween'>
        <ContactSnapshot
          avatarUrl={connectionInfo.avatarUrl}
          company={{ name: connectionInfo.currentCompany }}
          id={connectionInfo.id}
          name={
            connectionInfo.disabled
              ? `${connectionName} (deactivated)`
              : connectionName
          }
          title={connectionInfo.currentPositionTitle}
          url={connectionInfo.disabled ? null : connectionUrl}
        />
        <ConnectionStrengthAndMenu
          connectionName={connectionName}
          connectionStrength={connectionStrength?.name}
          currentContactName={contactFirstName}
          onEdit={onEdit}
          onRemove={handleRemoveConnection}
        />
      </div>
      <div className='u-marginLeft-32 u-paddingLeft-24'>{connectionTag}</div>
    </ExpandableCard>
  );
};

export const ConnectionPropType = PropTypes.shape({
  connectedOn: PropTypes.string,
  connectionInfo: PropTypes.shape({
    avatarUrl: PropTypes.string,
    currentCompany: PropTypes.string,
    currentPositionTitle: PropTypes.string,
    disabled: PropTypes.bool,
    email: PropTypes.string,
    fullName: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  connectionStrength: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  connectionType: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  createdBy: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    id: PropTypes.number,
  }),
  id: PropTypes.number,
  inverseReference: PropTypes.shape({
    candidacyId: PropTypes.number,
    clientCompanyName: PropTypes.string,
    contactFirstName: PropTypes.string.isRequired,
    contactId: PropTypes.number.isRequired,
    createdAt: PropTypes.string,
    searchId: PropTypes.number,
    searchName: PropTypes.string,
  }),
  notes: PropTypes.string,
  reference: PropTypes.shape({
    candidacyId: PropTypes.number,
    clientCompanyName: PropTypes.string,
    createdAt: PropTypes.string,
    refereeFirstName: PropTypes.string.isRequired,
    refereeId: PropTypes.number.isRequired,
    searchId: PropTypes.number,
    searchName: PropTypes.string,
  }),
  referralCompany: PropTypes.shape({
    company: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  updatedAt: PropTypes.string,
});

ConnectionsCard.propTypes = {
  connection: ConnectionPropType,
  contactFirstName: PropTypes.string,
};

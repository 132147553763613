import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import searchStageSchema from '../schema';

/**
 * Determines if a particular stage ID is a rejected stage.
 * @param {Object} state
 * @param {Number} stageId The stage ID
 * @return {Boolean} True if the stage with the given ID is a rejected stage; otherwise false.
 */
export default (state, id) =>
  Boolean(getEntityProperty(state, searchStageSchema.key, id, 'rejected'));

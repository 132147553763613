import fetchNotifications from './fetchNotifications';

function shouldFetchNotifications(state) {
  if (!state) {
    return false;
  }

  if (!state.hasIn('data') && !state.hasIn(['meta', 'error'])) {
    return true;
  }

  return state.getIn(['meta', 'isInvalidated']);
}

export default function fetchNotificationsIfNeeded(notifications) {
  return dispatch => {
    if (shouldFetchNotifications(notifications)) {
      return dispatch(
        fetchNotifications({
          category: notifications.getIn(['meta', 'category']),
        }),
      );
    }

    return false;
  };
}

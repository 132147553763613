import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import connectIntroductionActions from 'modules/introductions/components/connectIntroductionActions';
import IntroductionForm from 'modules/introductions/components/IntroductionForm';
import Notification from './Notification';

/**
 * Renders a notification with a resource type of `introduction`
 */
class NotificationItemTypeIntroduction extends Component {
  state = {
    /**
     * True when showing the edit form.
     * @type {Boolean}
     */
    isEditing: false,
  };

  handleEdit = () => {
    this.setState({ isEditing: true });
  };

  handleDelete = () => {
    const { introductionActions, notification } = this.props;

    const introduction = notification.getIn(['data', 'resource', 'data']);

    introductionActions.deleteIntroduction({
      contactId: introduction.get('contact_id'),
      otherContactId: introduction.getIn(['introduced_to', 'id']),
      id: introduction.get('id'),
    });
  };

  handleCancel = () => {
    this.setState({ isEditing: false });
  };

  handleSaved = () => {
    this.setState({ isEditing: false });
  };

  /* eslint-disable react/no-danger */
  render() {
    const {
      allowDelete,
      allowEdit,
      contact,
      excludeSearchField,
      notification,
      showDetails,
      ...props
    } = this.props;
    const introduction = notification.getIn(['data', 'resource']);
    const introductionId = notification.getIn([
      'data',
      'resource',
      'data',
      'id',
    ]);
    const notes = introduction.getIn(['data', 'notes']);
    const { isEditing } = this.state;

    const currentContact = contact.getIn(['data', 'id']);
    const introducedToId = introduction.getIn(['data', 'introduced_to_id']);
    const contactId = introduction.getIn(['data', 'contact_id']);
    const id = currentContact === contactId ? introducedToId : contactId;
    const introductionMessage = (
      <a href={`/contacts/${id}`}>{notification.getIn(['data', 'message'])}</a>
    );

    return (
      <Notification
        canDelete={
          allowDelete && introduction.getIn(['data', 'user_can_delete'])
        }
        canEdit={allowEdit && introduction.getIn(['data', 'user_can_edit'])}
        introMessage={introductionMessage}
        notification={notification}
        onDelete={this.handleDelete}
        onEdit={this.handleEdit}
        {...props}
      >
        {showDetails && !isEditing && notes && (
          <ExpandableText
            characterLimit={150}
            content={notes}
            isSanitizedHtml={true}
          />
        )}
        {isEditing && (
          <IntroductionForm
            asRichTextForm={true}
            draftStoragePath={{
              contact: contactId,
              introduction: introductionId,
            }}
            errorDisplay='tooltip'
            excludeContactField={true}
            excludeSearchField={excludeSearchField}
            formActionProps={{
              submitButtonIcon: 'fa-handshake-o',
              submitLabel: 'Update Introduction',
              fillWidth: false,
            }}
            introductionId={introductionId}
            onCancel={this.handleCancel}
            onSaved={this.handleSaved}
          />
        )}
      </Notification>
    );
  }
  /* eslint-enable react/no-danger */
}

NotificationItemTypeIntroduction.propTypes = {
  /**
   * True to allow the introduction item to be deleted if the user is permitted.
   */
  allowDelete: PropTypes.bool,

  /**
   * True to allow the introduction item to be edited inline if the user is permitted.
   */
  allowEdit: PropTypes.bool,

  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }).isRequired,

  excludeSearchField: PropTypes.bool,

  introductionActions: requiredIf(
    PropTypes.shape({
      deleteIntroduction: PropTypes.func,
    }),
    props => props.allowEdit || props.allowDelete,
  ),

  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          contact_id: PropTypes.number.isRequired,
          introduced_to_id: PropTypes.number.isRequired,
          date: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
          introduced_to: PropTypes.oneOfType([
            PropTypes.number,
            ImmutablePropTypes.mapContains({
              company: ImmutablePropTypes.mapContains({
                id: PropTypes.number,
                name: PropTypes.string,
              }),
              full_name: PropTypes.string,
            }),
          ]),
          notes: PropTypes.string,
        }),
      }),
      type: PropTypes.oneOf(['introduction']),
    }),
  }).isRequired,

  /**
   * When true, the note details will be displayed.
   * @type {[type]}
   */
  showDetails: PropTypes.bool,
};

export default connectIntroductionActions(NotificationItemTypeIntroduction);

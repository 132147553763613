import PropTypes from 'prop-types';
import {
  branch,
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';

import connectJobApplicationActions from './connectJobApplicationActions';
import shouldFetchJobApplicationSelector from '../selectors/shouldFetchJobApplication';

export const fetchJobApplicationIfNeeded = ({
  jobApplicationActions,
  jobApplicationId,
  shouldFetchJobApplication,
}) => {
  if (jobApplicationId && shouldFetchJobApplication) {
    jobApplicationActions.fetchJobApplication({ id: jobApplicationId });
  }
};

/**
 * Fetches a job application record if it needs to be fetched from the server.
 * */
export default compose(
  setDisplayName('withJobApplicationFetched'),
  setPropTypes({
    jobApplicationId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { jobApplicationId }) => ({
      shouldFetchJobApplication: shouldFetchJobApplicationSelector(
        state,
        jobApplicationId,
      ),
    }),
    {},
  ),
  branch(
    ({ jobApplicationActions }) => !jobApplicationActions,
    connectJobApplicationActions,
  ),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      fetchJobApplicationIfNeeded(this.props);
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      fetchJobApplicationIfNeeded(nextProps);
    },
  }),
);

import deleteEntity from 'modules/entities/actions/deleteEntity';
import routes from 'modules/routing/routes';
import residencySchema from '../schema';

/**
 * Creates an action for deleting a residency
 * @param {Object} payload
 * @param {Number} payload.id The residency ID
 */
export default ({ id, ...rest }) =>
  deleteEntity({
    ...rest,
    id: id,
    entityType: residencySchema.key,
    url: routes.api_residency({ id: id }),
  });

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import Form from '@thrivetrm/ui/components/Form';
import Grid from '@thrivetrm/ui/components/Grid';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import PageHeader from 'modules/core/components/PageHeader';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import { useApiGet } from 'modules/core/hooks/useApi';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import {
  LABEL_OTHER,
  LABEL_VERIFICATION_OF_APPROVAL,
} from 'modules/documents/constants';
import routes from 'modules/routing/routes';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import {
  ADDITIONAL_TEAM_MEMBER_TYPE,
  CANCELED_STATUS,
  CONVERTED_STATUS,
  EMPLOYEE_ROLES,
  HOLD_STATUS,
  LEAD_TEAM_MEMBER_TYPE,
  SEARCH_REQUEST_STATUS_CHANGE_MAP,
  SUBMITTED_STATUS,
  TEAM_LEAD_ROLES,
} from '../constants';
import { editSearchRequest } from '../searchRequestSlice';
import { createSearch } from '../../searches/searchSlice';

const formatAutocompleteOption = option => <div>{option.name}</div>;

const StatusAndAllocation = ({ searchRequest }) => {
  const dispatch = useDispatch();
  const { isCreatingComplete } = useSelector(state => state.searchRequest);
  const { isCreatingSearchComplete, searchId } = useSelector(
    state => state.search,
  );

  const [additionalTeamMembers, setAdditionalTeamMembers] = useState(
    searchRequest.internal_team_memberships,
  );

  useEffect(() => {
    setAdditionalTeamMembers(searchRequest.internal_team_memberships);
  }, [searchRequest]);

  const [formState, setFormState] = useState();
  const [isSubmitting, startSubmitting, stopSubmitting] = useToggle(false);

  const [
    isConvertingSearch,
    startConvertingSearch,
    stopConvertingSearch,
  ] = useToggle(false);

  const [payload, setPayload] = useState(null);
  const [shouldCreateSearch, setShouldCreateSearch] = useState(true);

  const companyRules = shouldCreateSearch
    ? {
        required: {
          value: true,
          message: 'Company name is required to create search',
        },
      }
    : {};

  const leadRules = shouldCreateSearch
    ? {
        required: {
          value: true,
          message: 'Lead is required to create search',
        },
      }
    : {};

  const roleRules = shouldCreateSearch
    ? {
        required: {
          value: true,
          message: 'Role is required to create search',
        },
      }
    : {};

  useEffect(() => {
    if (isCreatingSearchComplete && searchId && isSubmitting) {
      payload.search_id = searchId;
      payload.status = CONVERTED_STATUS;
      payload.search_request_stage_id = null;
      dispatch(editSearchRequest(payload));
      stopSubmitting();
      window.location = `/searches/${searchId}/edit`;
    } else if (isSubmitting && !isConvertingSearch) {
      dispatch(editSearchRequest(payload));
      stopSubmitting();
      setShouldCreateSearch(true);
    }
  }, [
    dispatch,
    isCreatingSearchComplete,
    isConvertingSearch,
    isSubmitting,
    payload,
    searchId,
    stopSubmitting,
  ]);

  const [
    isConfirmationDialogOpen,
    openConfirmationDialog,
    closeConfirmationDialog,
  ] = useToggle(false);

  const [
    loadDocumentLabels,
    _isLoadingDocumentLabels,
    _documentLabelLoadError,
    documentLabels,
  ] = useApiGet(
    routes.api_v1_document_labels({
      query: { record_type: 'search' },
    }),
  );

  useEffect(() => {
    loadDocumentLabels();
  }, [loadDocumentLabels]);

  const searchOtherLabelId = documentLabels?.find(
    label => label.name === LABEL_OTHER,
  )?.id;

  const searchVerificationLabelId = documentLabels?.find(
    label => label.name === LABEL_VERIFICATION_OF_APPROVAL,
  )?.id;

  const [loadUsers, isLoadingUsers, _usersLoadError, users] = useApiGet(
    routes.api_v1_users(),
  );
  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const [loadRoles, _isLoadingRoles, _rolesLoadError, roles] = useApiGet(
    routes.api_v1_search_team_roles(),
  );
  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const [
    loadStages,
    _isLoadingStages,
    _StagesLoadError,
    searchRequestStages,
  ] = useApiGet(routes.api_v1_search_request_stages());
  useEffect(() => {
    loadStages();
  }, [loadStages]);

  const leadUsers = users?.users?.filter(user =>
    TEAM_LEAD_ROLES.includes(user.role),
  );
  const additionalUsers = users?.users?.filter(user =>
    EMPLOYEE_ROLES.includes(user.role),
  );
  const leadRoles = roles?.search_team_roles?.filter(
    role => LEAD_TEAM_MEMBER_TYPE === role.team_member_type,
  );
  const additionalRoles = roles?.search_team_roles?.filter(role =>
    ADDITIONAL_TEAM_MEMBER_TYPE.includes(role.team_member_type),
  );

  const getSelectMenuUserItems = userItems =>
    userItems.map(item => (
      <SelectMenu.Item key={item.id} value={item.id}>
        {item.full_name_or_email}
      </SelectMenu.Item>
    ));

  const getSelectMenuItems = collection =>
    collection.map(item => (
      <SelectMenu.Item key={item.id} value={item.id}>
        {item.name}
      </SelectMenu.Item>
    ));

  const stagesFilteredByStatus = status =>
    searchRequestStages ? searchRequestStages[status.toLowerCase()] : null;

  const canceledStages = stagesFilteredByStatus(CANCELED_STATUS);
  const convertedStages = stagesFilteredByStatus(CONVERTED_STATUS);
  const holdStages = stagesFilteredByStatus(HOLD_STATUS);
  const submittedStages = stagesFilteredByStatus(SUBMITTED_STATUS);

  // This scopes the selectable statuses to only those allowed.
  // A user should not be able to change from Canceled to Converted, eg.
  const allowedStatuses =
    SEARCH_REQUEST_STATUS_CHANGE_MAP[searchRequest.status];

  const handleAddMemberClick = () => {
    setAdditionalTeamMembers([
      ...additionalTeamMembers,
      { team_member_id: null, search_team_role_id: null },
    ]);
  };

  const canCreateSearch =
    searchRequest.status === SUBMITTED_STATUS &&
    !isConvertingSearch &&
    !searchRequest.search_id;

  useEffect(() => {
    if (isCreatingComplete) {
      dispatch(toastSuccess('Search Request was successfully updated'));
    }
  }, [dispatch, isCreatingComplete]);

  const onSubmitWithSearchCreation = () => {
    // Remove the ids of these associated records (team_memberships and documents)
    // before passing them to the createSearch action. Otherwise the search#create controller action
    // will attempt to find and update these records, instead of just creating them new (which is what we want).
    const preparedTeamMembershipAttributes = cloneDeep(
      payload.team_memberships_attributes,
    );
    const preparedVerificationOfApproval =
      cloneDeep(searchRequest.verification_of_approval) || {};
    delete preparedVerificationOfApproval.id;
    const preparedOtherDocument = cloneDeep(searchRequest.other_document) || {};
    delete preparedOtherDocument.id;
    preparedVerificationOfApproval.document_label_id = searchOtherLabelId;
    preparedOtherDocument.document_label_id = searchVerificationLabelId;

    preparedTeamMembershipAttributes.forEach(membership => {
      delete membership.id;
    });

    const searchParams = {
      client_company_id: payload.company_id,
      city: searchRequest.address?.city,
      code_name: searchRequest.code_name,
      company_contact_id: payload.hiring_manager_id,
      confidential: searchRequest.confidential,
      cost_center_id: searchRequest.cost_center?.id,
      country_code: searchRequest.address?.country_code,
      currency: 'USD',
      documents_attributes: [
        preparedVerificationOfApproval,
        preparedOtherDocument,
      ],
      external_id: searchRequest.job_code,
      incumbent_id: payload.incumbent_id,
      internal_team_memberships_attributes: preparedTeamMembershipAttributes.slice(
        1,
      ),
      job_function_category_id: searchRequest.job_function_category?.id,
      job_title: searchRequest.job_title,
      lead_internal_team_membership_attributes:
        preparedTeamMembershipAttributes[0],
      level_id: searchRequest.level?.id,
      organization_id: searchRequest.organization?.id,
      placement_type_id: searchRequest.placement_type?.id,
      product_area_id: searchRequest.product_area?.id,
      qualifications: searchRequest.qualifications,
      search_number: payload.search_number,
      search_reason_id: searchRequest.search_reason?.id,
      search_region_id: searchRequest.search_region?.id,
      state: searchRequest.address?.state,
    };
    startSubmitting();
    dispatch(createSearch(searchParams));
  };

  useEffect(() => {
    setFormState({
      allocation_notes: searchRequest?.allocation_notes || null,
      lead_team_member:
        searchRequest?.lead_internal_team_membership?.user?.id || null,
      lead_team_member_role:
        searchRequest?.lead_internal_team_membership?.search_team_role_id ||
        null,
      search_number: searchRequest?.search_number || null,
      search_request_stage_id: searchRequest?.current_stage?.id || null,
      status: searchRequest?.status || null,
    });
  }, [searchRequest]);

  const handleSubmit = data => {
    const mergedData = { ...formState, ...data };

    // If the SearchRequestStage has not been changed, check to see that the status still matches & if not nullify.
    const isStageUnchanged =
      searchRequest.current_stage?.id === mergedData.search_request_stage_id;
    const doesCurrentStageBelongtoRequestedStatus =
      searchRequest.current_stage?.status === mergedData.status;
    if (isStageUnchanged && !doesCurrentStageBelongtoRequestedStatus) {
      mergedData.search_request_stage_id = null;
    }

    // Collect all additional_team_member_x fields from the form, and transform them into an
    // array of team_membership_attributes objects before submission.
    const teamMemberFields = Object.keys(mergedData)
      .filter(key => key.includes('additional_team_member'))
      .reduce((obj, key) => {
        obj[key] = mergedData[key];
        return obj;
      }, {});
    const teamMembersTransformedForUpdate = [];
    Object.keys(teamMemberFields)
      .filter(key => !key.includes('role'))
      .forEach((key, index) =>
        teamMembersTransformedForUpdate.push({
          team_member_id: teamMemberFields[key],
          type: 'InternalTeamMembership',
          search_team_role_id: teamMemberFields[`${key}_role`],
          id: searchRequest?.internal_team_memberships[index]?.id || null,
        }),
      );

    const newPayload = {
      allocation_notes: mergedData.allocation_notes,
      company_id: mergedData.company?.id,
      hiring_manager_id: mergedData.hiring_manager?.id,
      id: searchRequest?.id,
      incumbent_id: mergedData.incumbent?.id,
      search_number: mergedData.search_number,
      search_request_stage_id: mergedData.search_request_stage_id,
      status: mergedData.status,
      team_memberships_attributes: [
        {
          team_member_id: mergedData.lead_team_member,
          type: 'LeadInternalTeamMembership',
          search_team_role_id: mergedData.lead_team_member_role,
          id: searchRequest?.lead_internal_team_membership?.id || null,
        },
        ...teamMembersTransformedForUpdate,
      ].filter(team => team.team_member_id !== null),
    };
    setPayload(newPayload);

    if (shouldCreateSearch) {
      startConvertingSearch();
      openConfirmationDialog();
    } else {
      stopConvertingSearch();
      startSubmitting();
    }
  };

  const handleCreateClick = () => {
    setShouldCreateSearch(false);
  };

  // The current stage should render by default. If the stage
  // is no longer an option for the status the user would like
  // to change to, however, that stage may no longer be an option.
  const currentStageIdIfAvailableByStatus = status =>
    searchRequest?.current_stage?.status === status
      ? searchRequest?.current_stage?.id
      : null;

  return (
    <LoadingContainer isLoading={isLoadingUsers} overlayColor='gray5'>
      <PageHeader title='Status & Allocation' />
      <div className='u-paddingLeft-n'>
        {formState ? (
          <div className='u-paddingHorizontal-32 StatusAndAllocation__Container'>
            <Form initialValues={formState} onSubmit={handleSubmit}>
              <h2>Search Request Status</h2>
              <Grid className='u-paddingHorizontal-n'>
                <Grid.Column size={6}>
                  <Form.SelectMenu
                    className='u-marginBottom-24'
                    inputWidth='full'
                    name='status'
                    placeholder='Select a Status…'
                    rules={{
                      required: {
                        value: true,
                        message: 'Status is required',
                      },
                    }}
                  >
                    {allowedStatuses?.map(status => (
                      <SelectMenu.Item key={status} value={status}>
                        {status}
                      </SelectMenu.Item>
                    ))}
                  </Form.SelectMenu>
                </Grid.Column>
                <Grid.Column size={6}>
                  <Form.FieldDependentContent
                    shouldRender={fields => fields.status === CANCELED_STATUS}
                  >
                    {canceledStages?.length > 0 ? (
                      <Form.SelectMenu
                        className='u-marginBottom-24'
                        inputWidth='full'
                        name='search_request_stage_id'
                        placeholder='Select a Search Request Stage…'
                        rules={{
                          required: {
                            value: true,
                            message:
                              'Stage is required when Status is Canceled',
                          },
                        }}
                        value={currentStageIdIfAvailableByStatus(
                          CANCELED_STATUS,
                        )}
                      >
                        {canceledStages && getSelectMenuItems(canceledStages)}
                      </Form.SelectMenu>
                    ) : null}
                  </Form.FieldDependentContent>
                  <Form.FieldDependentContent
                    shouldRender={fields => fields.status === CONVERTED_STATUS}
                  >
                    {convertedStages?.length > 0 ? (
                      <Form.SelectMenu
                        className='u-marginBottom-24'
                        inputWidth='full'
                        name='search_request_stage_id'
                        placeholder='Select a Search Request Stage…'
                        value={currentStageIdIfAvailableByStatus(
                          CONVERTED_STATUS,
                        )}
                      >
                        {convertedStages && getSelectMenuItems(convertedStages)}
                      </Form.SelectMenu>
                    ) : null}
                  </Form.FieldDependentContent>
                  <Form.FieldDependentContent
                    shouldRender={fields => fields.status === HOLD_STATUS}
                  >
                    {holdStages?.length > 0 ? (
                      <Form.SelectMenu
                        className='u-marginBottom-24'
                        inputWidth='full'
                        name='search_request_stage_id'
                        placeholder='Select a Search Request Stage…'
                        rules={{
                          required: {
                            value: true,
                            message: 'Stage is required when Status is Hold',
                          },
                        }}
                        value={currentStageIdIfAvailableByStatus(HOLD_STATUS)}
                      >
                        {holdStages && getSelectMenuItems(holdStages)}
                      </Form.SelectMenu>
                    ) : null}
                  </Form.FieldDependentContent>
                  <Form.FieldDependentContent
                    shouldRender={fields => fields.status === SUBMITTED_STATUS}
                  >
                    {submittedStages?.length > 0 ? (
                      <Form.SelectMenu
                        className='u-marginBottom-24'
                        inputWidth='full'
                        name='search_request_stage_id'
                        placeholder='Select a Search Request Stage…'
                        value={currentStageIdIfAvailableByStatus(
                          SUBMITTED_STATUS,
                        )}
                      >
                        {submittedStages && getSelectMenuItems(submittedStages)}
                      </Form.SelectMenu>
                    ) : null}
                  </Form.FieldDependentContent>
                </Grid.Column>
              </Grid>

              <h2>Additional Details</h2>
              <Grid className='u-paddingHorizontal-n'>
                <Grid.Column>
                  <div className='StatusAndAllocation__AutocompleteField'>
                    <Form.Autocomplete
                      formatOption={option => formatAutocompleteOption(option)}
                      initialValue={searchRequest?.company}
                      label='Company Name'
                      name='company'
                      onSelect={() => {}}
                      placeholder='Select a Company…'
                      rules={companyRules}
                      url={query =>
                        routes.api_v1_autocomplete_query({
                          query: {
                            query: {
                              resource: 'company',
                              term: query,
                            },
                          },
                        })
                      }
                    />
                  </div>
                  <div className='StatusAndAllocation__AutocompleteField'>
                    <Form.Autocomplete
                      formatOption={option => formatAutocompleteOption(option)}
                      initialValue={searchRequest?.incumbent}
                      label='Incumbent'
                      name='incumbent'
                      onSelect={() => {}}
                      placeholder='Select a Contact…'
                      url={query =>
                        routes.api_v1_autocomplete_query({
                          query: {
                            query: {
                              resource: 'contact',
                              term: query,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className='StatusAndAllocation__AutocompleteField'>
                    <Form.Autocomplete
                      formatOption={option => formatAutocompleteOption(option)}
                      initialValue={searchRequest?.hiring_manager}
                      label='Hiring Manager'
                      name='hiring_manager'
                      onSelect={() => {}}
                      placeholder='Select a Contact…'
                      url={query =>
                        routes.api_v1_autocomplete_query({
                          query: {
                            query: {
                              resource: 'contact',
                              term: query,
                            },
                          },
                        })
                      }
                    />
                  </div>
                  <Form.TextInput
                    className='u-marginBottom-24'
                    inputWidth='full'
                    label='Search Number'
                    name='search_number'
                    placeholder='Enter Search Number…'
                  />
                </Grid.Column>
              </Grid>

              <div className='StatusAndAllocation__TextArea'>
                <Form.TextArea
                  className='u-marginBottom-24'
                  label='Allocation Notes'
                  name='allocation_notes'
                  width='full'
                />
              </div>

              <h2>Search Team Allocation</h2>
              {leadUsers?.length > 0 ? (
                <Grid className='u-paddingHorizontal-n'>
                  <Grid.Column>
                    <Form.SelectMenu
                      className='u-marginBottom-24'
                      inputWidth='full'
                      label='Lead'
                      name='lead_team_member'
                      placeholder='Select a Lead…'
                      rules={leadRules}
                    >
                      {leadUsers && getSelectMenuUserItems(leadUsers)}
                    </Form.SelectMenu>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.SelectMenu
                      className='u-marginBottom-24'
                      inputWidth='full'
                      label='Search Team Role'
                      name='lead_team_member_role'
                      placeholder='Select a Search Team Role…'
                      rules={roleRules}
                    >
                      {leadRoles && getSelectMenuItems(leadRoles)}
                    </Form.SelectMenu>
                  </Grid.Column>
                </Grid>
              ) : null}

              <h4>Additional Team Members</h4>
              {additionalTeamMembers.length > 0
                ? additionalTeamMembers.map((member, index) => {
                    return (
                      <Grid
                        className='u-paddingHorizontal-n'
                        key={member.id || index}
                      >
                        <Grid.Column>
                          <Form.SelectMenu
                            className='u-marginBottom-24'
                            initialValue={member.team_member_id}
                            inputWidth='full'
                            name={`additional_team_member_${index + 1}`}
                            placeholder='Select a Team Member…'
                          >
                            {additionalUsers &&
                              getSelectMenuUserItems(additionalUsers)}
                          </Form.SelectMenu>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.SelectMenu
                            className='u-marginBottom-24'
                            initialValue={member.search_team_role_id}
                            inputWidth='full'
                            name={`additional_team_member_${index + 1}_role`}
                            placeholder='Select a Search Team Role…'
                          >
                            {additionalRoles &&
                              getSelectMenuItems(additionalRoles)}
                          </Form.SelectMenu>
                        </Grid.Column>
                      </Grid>
                    );
                  })
                : null}

              <ButtonSecondary
                icon='add'
                label='Add Team Member'
                onClick={handleAddMemberClick}
                size='small'
              />
              <div className='StatusAndAllocation__Footer'>
                <div className='u-fontSize-small'>
                  * Required to Create Search
                </div>
                <div className='StatusAndAllocation__SubmitButtons u-marginRight-16'>
                  {/*
                    The core Form component is not built to allow for multiple submit buttons with differing behavior.
                    On this page however, the Save button and the Save & Create Search button need to behave differently.
                    To accomodate this, we're adding another button here which will behave the same as a FormSubmitButton,
                    but with an onClick handler to set shouldCreateSearch to false. When the form is submitted, this value
                    is then checked to determine whether we need to just save the search request, or save and also create a search.
                  */}
                  <ButtonPrimary
                    buttonType='submit'
                    isOutline={true}
                    label='Save'
                    onClick={handleCreateClick}
                  />
                </div>
                {canCreateSearch ? (
                  <Form.SubmitButton label='Save & Create Search' />
                ) : null}
              </div>
            </Form>
          </div>
        ) : null}
      </div>
      <ConfirmationDialog
        cancelLabel='No, Continue Editing'
        confirmLabel='Yes, Create Search'
        isOpen={isConfirmationDialogOpen}
        onClose={closeConfirmationDialog}
        onConfirm={onSubmitWithSearchCreation}
        title='Submit Search Request?'
      >
        <p className='u-paddingHorizontal-24'>
          This will create a search, and notify the allocated users.
        </p>
        <div>Do you want to continue?</div>
      </ConfirmationDialog>
    </LoadingContainer>
  );
};

StatusAndAllocation.propTypes = {
  searchRequest: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      country_code: PropTypes.string,
      id: PropTypes.number,
      state: PropTypes.string,
    }),
    allocation_notes: PropTypes.string,
    code_name: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    confidential: PropTypes.bool,
    cost_center: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    current_stage: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.string,
    }),
    hiring_manager: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    id: PropTypes.number,
    incumbent: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    internal_team_memberships: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        search_team_role_id: PropTypes.number,
        team_member_id: PropTypes.number,
      }),
    ),
    job_code: PropTypes.string,
    job_function_category: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    job_title: PropTypes.string,
    lead_internal_team_membership: PropTypes.shape({
      id: PropTypes.number,
      search_team_role_id: PropTypes.number,
      user: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    }),
    level: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    organization: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    other_document: PropTypes.shape({
      external_url: PropTypes.string,
      id: PropTypes.number,
    }),
    placement_type: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    product_area: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    qualifications: PropTypes.string,
    search_id: PropTypes.number,
    search_number: PropTypes.string,
    search_reason: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    search_region: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    search_request_stage: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.string,
    }),
    status: PropTypes.string,
    verification_of_approval: PropTypes.shape({
      external_url: PropTypes.string,
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default StatusAndAllocation;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'modules/core/componentsLegacy/Modal';

/**
 * A generic modal used for generating a report. Contents (e.g. a form)
 * is passed to it as children
 */

class ReportModal extends Component {
  handleSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };

  render() {
    const { children, onHide, title } = this.props;

    return (
      <Modal aria-labelledby='modal-title' onHide={onHide} show={true}>
        <Modal.Header closeButton={true}>
          <Modal.Title id='modal-title'>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    );
  }
}

ReportModal.propTypes = {
  children: PropTypes.element,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};

export default ReportModal;

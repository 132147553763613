import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import pluralize from 'pluralize';
import selectn from 'selectn';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import Form from 'modules/forms/components/Form';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import withFormState from 'modules/forms/components/withFormState';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import withTransaction from 'modules/transactions/components/withTransaction';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import QuestionAnswerListField from './QuestionAnswerListField';
import updateQuestionAnswerListActionCreator from '../actions/updateQuestionAnswerList';

/**
 * Renders a form for editing/updating question answers
 */
const QuestionAnswerListForm = ({
  formState,
  onCancel,
  onFieldStateChange,
  onSubmit,
  questionAnswers,
  ...fieldProps
}) => (
  <Form
    className='QuestionAnswerListForm'
    formState={formState}
    onSubmit={onSubmit}
  >
    <QuestionAnswerListField
      {...fieldProps}
      disabled={formState.isSubmitting()}
      fieldState={formState.getFieldState()}
      onChange={onFieldStateChange}
      questionAnswers={questionAnswers}
      showErrors={formState.wasSubmitted() || 'blurred'}
    />
    <FormErrorMessage formState={formState} />
    <div className='Form__footer'>
      <CancelButton onClick={onCancel} />
      <SubmitButton formState={formState} onClick={onSubmit} />
    </div>
  </Form>
);

QuestionAnswerListForm.propTypes = {
  formState: formStatePropType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFieldStateChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  questionAnswers: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  submitButtonLabel: PropTypes.node,
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('QuestionAnswerListForm(enhanced))'),
  setPropTypes({
    parentId: PropTypes.number.isRequired,
    parentType: PropTypes.oneOf(['assessment', 'interview']).isRequired,
    onSaved: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }),
  connect((state, { parentId, parentType }) => ({
    questionAnswers: getEntityProperty(
      state,
      pluralize(parentType),
      parentId,
      'question_answers',
      [],
    ),
  })),
  /**
   * Create a FormState, initializing it with the value from `questionAnswers`
   */
  withFormState(({ questionAnswers }) =>
    QuestionAnswerListField.createFieldState(
      'question_answers',
      questionAnswers,
    ),
  ),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onResetFormState,
      onSaved,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();
        // If all was good, call onSaved with the record's IDs
        onSaved(selectn('payload.result.question_answers', transaction));
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  /**
   * Include connected version of `updateQuestionAnswerList` needed to submit.
   * The parent must be oneOf(['assessment', 'interview']) or an error is thrown
   */
  connect(null, {
    updateQuestionAnswerList: updateQuestionAnswerListActionCreator,
  }),

  /**
   * Add a callback to handle submitting the action form.
   */
  withHandlers({
    // Called when the form should be submitted.
    onSubmit: ({
      formState,
      onFormStateChange,
      parentId,
      parentType,
      startTransaction,
      updateQuestionAnswerList,
    }) => e => {
      e.preventDefault();
      const transactionId = startTransaction();
      const fieldValue = formState.getFieldValue();

      updateQuestionAnswerList({
        parentId: parentId,
        parentType: parentType,
        questionAnswers: fieldValue,
        transactionId: transactionId,
      });

      onFormStateChange(formState.startSubmit(transactionId));

      return preventDefaultHandler(e);
    },
  }),

  withHandlers({
    onCancel: ({ formState, onCancel }) => () => onCancel(formState),
  }),

  withPropsRemoved(
    'clearTransaction',
    'dispatch',
    'onFormStateChange',
    'onResetFormState',
    'onSaved',
    'onTransactionComplete',
    'parentId',
    'parentType',
    'startTransaction',
    'transactionActions',
    'transactionId',
    'updateQuestionAnswerList',
  ),
)(QuestionAnswerListForm);

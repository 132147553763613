import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import QuickViewPanel from 'modules/quick-view/QuickViewPanel';
import StateProvider from './app/StateProvider';

const QuickViewPanelContainer = () => (
  <StateProvider>
    <MemoryRouter>
      <QuickViewPanel />
    </MemoryRouter>
  </StateProvider>
);

export default QuickViewPanelContainer;

import React from 'react';
import PropTypes from 'prop-types';
import CandidacyRankingListItem from './CandidacyRankingListItem';

const CandidacyRankingMenuList = ({ handleMoveCandidacy }) => (
  <ul className='CandidacyRankingList'>
    <CandidacyRankingListItem
      name='Move Up'
      onClick={handleMoveCandidacy}
      value='up'
    />
    <CandidacyRankingListItem
      name='Move Down'
      onClick={handleMoveCandidacy}
      value='down'
    />
    <CandidacyRankingListItem
      name='Move To Top'
      onClick={handleMoveCandidacy}
      value='top'
    />
    <CandidacyRankingListItem
      name='Move To Bottom'
      onClick={handleMoveCandidacy}
      value='bottom'
    />
  </ul>
);

CandidacyRankingMenuList.propTypes = {
  /**
   * Function that gets called when an item is clicked
   */
  handleMoveCandidacy: PropTypes.func.isRequired,
};
export default CandidacyRankingMenuList;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { fromJS } from 'immutable';
import { compose, setStatic, setDisplayName } from 'recompose';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import withFormGroup from 'modules/forms/components/withFormGroup';
import * as validators from 'modules/forms/validators';

export const DEFAULT_VALUES = fromJS({
  first_name: '',
  last_name: '',
});

class ContactNamesField extends Component {
  static createFieldState = (name = 'contact', contact) => {
    const values = DEFAULT_VALUES.merge(contact);

    return FieldState.createNested(name, [
      InputField.createFieldState(
        'first_name',
        values.get('first_name'),
        validators.requiredField('First name'),
      ),
      InputField.createFieldState(
        'last_name',
        values.get('last_name'),
        validators.requiredField('Last name'),
      ),
    ]);
  };

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const { fieldColumnClass, fieldState } = this.props;

    return (
      <div className='ContactNamesField row'>
        <div className={fieldColumnClass}>
          <InputField
            fieldState={fieldState.getNestedField('first_name')}
            label='*First name'
            name='first_name'
            onChange={this.handleFieldChange}
          />
        </div>

        <div className={fieldColumnClass}>
          <InputField
            fieldState={fieldState.getNestedField('last_name')}
            label='*Last name'
            name='last_name'
            onChange={this.handleFieldChange}
          />
        </div>
      </div>
    );
  }
}

ContactNamesField.propTypes = {
  fieldColumnClass: PropTypes.string,

  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  onChange: PropTypes.func.isRequired,
};

ContactNamesField.defaultProps = {
  fieldColumnClass: 'col-12',
};

export default compose(
  setDisplayName('connect(ContactNamesField'),
  setStatic('createFieldState', ContactNamesField.createFieldState),
  withFormGroup,
)(ContactNamesField);

import React from 'react';
import PropTypes from 'prop-types';
import BulkImportCard from '../BulkImportCard';
import * as bulkImportConstants from '../../constants';

const ChooseFileFormat = ({ onChoose }) => {
  const handleCsvUploadTypeClick = () => {
    onChoose(bulkImportConstants.Modes.UPLOAD_CSV);
  };

  return (
    <div className='u-paddingHorizontal-24'>
      <h3 className='u-marginBottom-32'>
        How would you like to provide the data?
      </h3>

      <div className='row'>
        <div className='col-sm-4'>
          <BulkImportCard
            className='BulkImportModal__uploadCsvCard'
            iconClass='fa-file-text-o'
            onClick={handleCsvUploadTypeClick}
            title='Upload a CSV File'
          />
        </div>
      </div>
    </div>
  );
};

ChooseFileFormat.propTypes = {
  onChoose: PropTypes.func.isRequired,
};

export default ChooseFileFormat;

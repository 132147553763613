import { connect } from 'react-redux';
import getLegacyInterviewers from '../selectors/getLegacyInterviewers';

/**
 * A higher order component that sorts a list of interviewIds in alphabetical order
 * of the interview `interviewers` value.
 */
export default connect(
  (state, { interviewIds }) => ({
    interviewIds:
      interviewIds &&
      interviewIds.sortBy(id =>
        (getLegacyInterviewers(state, id) || '').toLowerCase(),
      ),
  }),
  {},
);

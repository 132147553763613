import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useQuickView from 'modules/quick-view/useQuickView';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import TextInput from '@thrivetrm/ui/components/TextInput';
import Icon from '@thrivetrm/ui/components/Icon';
import FilterSetSection from './FilterSetSection';

const SelectedFilterSetList = () => {
  const { close } = useQuickView();
  const { filterSetSectionNames } = useSelector(state => state.recordIndex);

  const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      <SidePanel.Header className='u-border-n' onClose={close}>
        <div className='u-flexItem-grow'>
          <h2 className='u-margin-n'>Manage Filters</h2>
          <p className='u-margin-n u-textColor-gray50'>
            Select the filters to be shown in your current view
          </p>
        </div>
      </SidePanel.Header>
      <SidePanel.Body className='u-paddingTop-16'>
        <TextInput
          append={
            <Icon
              className='u-margin-8'
              color='gray'
              size='small'
              type='search'
            />
          }
          inputWidth='full'
          onChange={setSearchTerm}
          placeholder='Search for filter type...'
          size='small'
          value={searchTerm}
        />

        {filterSetSectionNames.map(sectionName => (
          <FilterSetSection
            key={sectionName}
            name={sectionName}
            query={searchTerm}
          />
        ))}
      </SidePanel.Body>
    </>
  );
};

export default SelectedFilterSetList;

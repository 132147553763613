import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import introductionSchema from '../schema';

/**
 * Gets the date field value for a introduction by it's ID
 * @param {Object} state
 * @param {Number} introductionId The introduction ID
 * @return {String} The date field value
 */
export default (state, introductionId) =>
  getEntityProperty(state, introductionSchema.key, introductionId, 'date');

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import Form from '@thrivetrm/ui/components/Form';
import RecruitmentRatingsForm from 'modules/quick-view/recruitment-rating-panels/RecruitmentRatingForm';
import getCurrentUserId from 'modules/user/selectors/getCurrentUserId';
import fetchUser from 'modules/users/actions/fetchUser';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { useGetSearchQuery } from 'services/apiV1/searches';
import {
  useGetRecruitmentRatingsQuery,
  useUpdateRecruitmentRatingMutation,
} from 'services/apiV1/recruitmentRatings';
import useQuickView from '../useQuickView';
import QuickViewPanelHeader from '../QuickViewPanelHeader';

const RecruitmentRatingsEditForm = () => {
  const { close, navigateTo, pathParams, title } = useQuickView();
  const { companyId, recruitmentRatingId } = pathParams;
  const dispatch = useDispatch();
  const {
    data: recruitmentRatingsList,
    isLoading: isLoadingRecruitmentRatingList,
  } = useGetRecruitmentRatingsQuery(companyId);
  const [updateRecruitmentRating] = useUpdateRecruitmentRatingMutation();

  /**
   * On some pages, not all of the current user's data is available in Redux.
   */
  const currentUserId = useSelector(getCurrentUserId);
  useEffect(() => {
    if (currentUserId) {
      dispatch(fetchUser({ id: currentUserId }));
    }
  }, [currentUserId, dispatch]);

  const rating = recruitmentRatingsList?.find(obj => {
    return obj.id === recruitmentRatingId;
  });

  const searchId = rating?.search?.id;

  const goToListView = () => {
    navigateTo(`/company/${companyId}/recruitment_ratings`);
  };

  const {
    data: searchData,
    error: _loadSearchError,
    isLoading: isLoadingSearch,
  } = useGetSearchQuery(searchId);

  const handleSubmit = formState => {
    const payload = {
      search_id: rating.search.id,
      recruitment_rating_id: recruitmentRatingId,
      rating: formState.rating,
      notes: formState.notes,
      company_id: companyId,
      rated_by_id: formState.ratedBy.id,
      updated_by_id: currentUserId,
    };
    updateRecruitmentRating({
      companyId: payload.company_id,
      ratingId: payload.recruitment_rating_id,
      payload: payload,
    }).then(() => {
      goToListView();
      dispatch(toastSuccess('Successfully edited recruitment rating'));
    });
  };

  return (
    <>
      <SidePanel.Header
        className='u-marginBottom-12'
        onBack={goToListView}
        onClose={close}
        title={title}
      />
      <QuickViewPanelHeader title='Edit Rating' />
      <Form onSubmit={handleSubmit}>
        <SidePanel.Body className='u-paddingTop-n'>
          <LoadingContainer
            className={classnames('u-marginTop-16', {
              'u-marginTop-64':
                isLoadingRecruitmentRatingList || isLoadingSearch,
            })}
            isLoading={isLoadingRecruitmentRatingList || isLoadingSearch}
            size='large'
          >
            <RecruitmentRatingsForm
              endDate={searchData?.endDate}
              initialValues={{
                ratedBy: rating?.ratedBy ?? null,
                notes: rating?.notes,
                rating: rating?.rating,
              }}
              milestone={searchData?.milestone}
              searchId={searchId}
              searchName={rating?.search?.name}
            />
          </LoadingContainer>
        </SidePanel.Body>
        <SidePanel.Footer>
          <ButtonPrimary
            isOutline={true}
            label='Cancel'
            onClick={goToListView}
          />
          <Form.SubmitButton isLoading={false} label='Save' />
        </SidePanel.Footer>
      </Form>
    </>
  );
};
export default RecruitmentRatingsEditForm;

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { List } from 'immutable';
import getFullName from 'modules/contacts/selectors/contacts/getFullName';
import getName from 'modules/users/selectors/getName';
import * as filterUtils from 'modules/core/filterUtils';

import getAssignedTo from '../selectors/getAssignedTo';
import getContactIdFromTask from '../selectors/getContactIdFromTask';
import getCompleted from '../selectors/getCompleted';
import getTitle from '../selectors/getTitle';
import isOverdue from '../selectors/isOverdue';
import isDueToday from '../selectors/isDueToday';
import { MY_TASKS } from '../constants';

const taskIdsForView = (state, props, ids) => {
  const { currentView } = props;
  const currentUserId = state.user.get('current_user_id');

  if (currentView === MY_TASKS) {
    return ids.filter(id => {
      const assignedTo = getAssignedTo(state, id);
      return assignedTo === currentUserId;
    });
  }
  return ids.filter(id => {
    const assignedTo = getAssignedTo(state, id);
    return assignedTo !== currentUserId;
  });
};

const taskIdsByFilter = (state, props, ids) => {
  const { currentFilter } = props;

  switch (currentFilter) {
    case 'open':
      return ids.filter(id => !getCompleted(state, id));
    case 'overdue':
      return ids.filter(id => isOverdue(state, id));
    case 'dueToday':
      return ids.filter(id => isDueToday(state, id));
    case 'completed':
      return ids.filter(id => getCompleted(state, id));
    default:
      return [];
  }
};

const taskIdsByText = (state, props, ids) => {
  const { filterText } = props;

  if (!filterText || !filterText.trim() || !ids || ids.size < 1) {
    return ids;
  }

  const filterRegex = filterUtils.createMatchAllKeywordsRegex(filterText);

  return ids.filter(id => {
    const contactId = getContactIdFromTask(state, id);
    const contactName = getFullName(state, contactId);
    const taskTitle = getTitle(state, id);
    const assignedToId = getAssignedTo(state, id);
    const assignedToName = getName(state, assignedToId);

    return filterRegex.test([contactName, assignedToName, taskTitle].join(' '));
  });
};

const filterTaskIds = (state, props) => {
  const { taskIds } = props;
  const pendingIds = props.pendingIds || [];

  if (!taskIds.size > 0) {
    return taskIds;
  }

  const initialIds = taskIdsForView(state, props, taskIds);
  // If the current view or filter has pending ids, add them back.
  const newIds = taskIdsByFilter(state, props, initialIds).concat(pendingIds);
  // Dedupes the list. Because the update action (and also technically setState)
  // runs async, sometimes a task will be pended before it is removed from the
  // view, causing duplicate ids.
  const uniqueIds = [...new Set(newIds)];
  const finalIds = taskIdsByText(state, props, uniqueIds);
  return new List(finalIds);
};

// The second argument to mapStateToProps is our own incoming props
// which includes currentFilter (open, overdue, dueToday, completed),
// currentView (MY_TASKS, ASSIGNED_TASKS) and filterText.
export default compose(
  connect((state, incomingProps) => ({
    taskIds: filterTaskIds(state, incomingProps),
  })),
);

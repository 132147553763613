import React from 'react';
import PropTypes from 'prop-types';
import Card from '@thrivetrm/ui/components/Card';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';

const DuplicatePositionCard = ({
  children,
  deleteButtonLabel,
  onDelete,
  positionId,
  title,
  ...props
}) => (
  <Card
    className='DuplicatePositionCard u-padding-n'
    data-testid='duplicate position card'
    isCentered={false}
    {...props}
  >
    <div className='u-backgroundColor-gray10 u-flex u-flexAlign-c u-paddingHorizontal-24'>
      <h3 className='u-flexItem-grow u-marginVertical-24'>{title}</h3>
      <ConfirmationPopoverButton
        className='Button Button--secondary Button--medium'
        key='delete'
        label={deleteButtonLabel}
        onConfirm={onDelete}
        title={`Are you sure you want to ${deleteButtonLabel.toLowerCase()} this position?`}
        value={positionId}
      />
    </div>
    <div className='u-paddingVertical-16 u-paddingHorizontal-24'>
      {children}
    </div>
  </Card>
);

DuplicatePositionCard.propTypes = {
  children: PropTypes.node,
  deleteButtonLabel: PropTypes.node,
  onDelete: PropTypes.func.isRequired,
  positionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  title: PropTypes.string.isRequired,
};

DuplicatePositionCard.defaultProps = {
  deleteButtonLabel: 'Discard',
};

export default DuplicatePositionCard;

import React from 'react';
import { useSelector } from 'react-redux';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import Form from '@thrivetrm/ui/components/Form';
import { dateToISODateString } from '@thrivetrm/ui/utilities/dateTimeUtils';
import {
  useCreateOffLimitsCustomReasonMutation,
  useGetOffLimitsReasonsQuery,
  useGetOffLimitsReasonsTypesQuery,
} from 'services/apiV1/offLimits';
import Card from '@thrivetrm/ui/components/Card';
import QuickViewPanelHeader from '../QuickViewPanelHeader';
import useQuickView from '../useQuickView';
import { QuickViewErrorState } from '../QuickViewState';
import ContactQvpHeader from '../ContactQvpHeader';

const OffLimitsApplyForm = () => {
  const candidacyId = window.location.pathname.includes('searches')
    ? window.location.pathname.split('/')[4]
    : null;
  const { close, navigateTo, pathParams, title } = useQuickView();
  const { recordId, recordType } = pathParams;
  const currentUserId = useSelector(getCurrentUserId);
  const isCompany = recordType === 'company';

  const {
    data: reasons,
    error: loadReasonsError,
    isLoading: isLoadingReasons,
  } = useGetOffLimitsReasonsQuery({
    recordId: recordId,
    recordType: recordType,
  });

  const appliedCustomOfflimitReasons = reasons
    ?.filter(({ isCustom }) => isCustom)
    .map(({ reasonName }) => reasonName);

  const [
    createOffLimitsCustomReason,
    {
      error: isCreateOffLimitsCustomReasonError,
      isLoading: isCreateOffLimitsCustomReasonLoading,
    },
  ] = useCreateOffLimitsCustomReasonMutation();

  const {
    data: customReasonTypes,
    isLoading: isLoadingCustomReasonTypes,
  } = useGetOffLimitsReasonsTypesQuery(recordType);

  const navigateBack = () => {
    navigateTo(`/${recordType}/${recordId}/off-limits`);
  };

  const remainingOfflimitReasons = customReasonTypes?.filter(
    ({ name }) => !appliedCustomOfflimitReasons?.includes(name),
  );

  const startDate = dateToISODateString(new Date());

  const handleSubmit = formState => {
    createOffLimitsCustomReason({
      reasonData: {
        end_date: formState.endDate,
        notes: formState.notes,
        off_limits_reason_id: formState.offLimitReason,
        start_date: formState.startDate,
        user_id: currentUserId,
      },
      recordId: recordId,
      recordType: recordType,
      candidacyId: candidacyId,
    }).then(() => {
      navigateBack();
    });
  };

  const hasError = isCreateOffLimitsCustomReasonError || loadReasonsError;

  return (
    <>
      {isCompany ? (
        <SidePanel.Header
          className='u-marginBottom-12'
          onBack={navigateBack}
          onClose={close}
          title={title}
        />
      ) : (
        <ContactQvpHeader contactId={recordId} onBack={navigateBack} />
      )}
      <QuickViewPanelHeader title='Apply Off Limits Reason' />
      <Form onSubmit={handleSubmit}>
        <SidePanel.Body className='u-paddingTop-n'>
          <LoadingContainer
            className='u-paddingTop-24'
            isLoading={isLoadingCustomReasonTypes || isLoadingReasons}
          >
            {hasError ? (
              <QuickViewErrorState />
            ) : (
              <>
                <p className='u-fontSize-small'>
                  Select a Custom Reason from the drop-down menu below. If you
                  donʼt see the reason you are looking for reach out to your
                  Admin.
                </p>
                {remainingOfflimitReasons?.length ? null : (
                  <Card
                    className='u-marginBottom-12'
                    isCentered={false}
                    type='warning'
                  >
                    {isCompany
                      ? 'This company currently has all Custom Reasons active.'
                      : 'This contact currently has all Custom Reasons active.'}
                  </Card>
                )}
                <Form.SelectMenu
                  initialValue={
                    remainingOfflimitReasons?.length
                      ? remainingOfflimitReasons[0].id
                      : null
                  }
                  inputWidth='full'
                  label='Off Limits Reason'
                  menuPanelClassName='OffLimitsCard__menuPanel'
                  name='offLimitReason'
                  rules={{
                    required: {
                      value: true,
                    },
                  }}
                >
                  {customReasonTypes?.map(({ id, name }) =>
                    appliedCustomOfflimitReasons?.includes(name) ? (
                      <Tooltip
                        className='u-width-100'
                        content={
                          isCompany
                            ? 'This company currently has this active off limits reason'
                            : 'This contact currently has this active off limits reason'
                        }
                        key={id}
                        position='top'
                        size='large'
                      >
                        <SelectMenu.Item isDisabled={true} value={id}>
                          {name}
                        </SelectMenu.Item>
                      </Tooltip>
                    ) : (
                      <SelectMenu.Item key={id} value={id}>
                        {name}
                      </SelectMenu.Item>
                    ),
                  )}
                </Form.SelectMenu>
                <div className='u-flex u-marginVertical-16'>
                  <Form.DateInput
                    className='u-marginRight-8'
                    initialValue={startDate}
                    inputWidth='full'
                    isDisabled={true}
                    label='Applied On'
                    name='startDate'
                  />
                  <Form.DateInput
                    className='u-marginLeft-8'
                    inputWidth='full'
                    isPinnedBottom={true}
                    isPinnedRight={true}
                    label='Expires On'
                    minDate={startDate}
                    name='endDate'
                    placeholderText='Select a date'
                  />
                </div>
                <Form.TextArea label='Notes' name='notes' width='full' />
              </>
            )}
          </LoadingContainer>
        </SidePanel.Body>
        <SidePanel.Footer>
          <ButtonPrimary
            isOutline={true}
            label='Cancel'
            onClick={navigateBack}
          />
          <Form.SubmitButton
            isLoading={isCreateOffLimitsCustomReasonLoading}
            label='Submit'
          />
        </SidePanel.Footer>
      </Form>
    </>
  );
};

export default OffLimitsApplyForm;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SketchPicker from 'react-color/lib/components/sketch/Sketch';

/**
 * Renders a SketchPicker as a Popover component that can be used with a react-bootstrap Overlay.
 */
const ColorPickerPopover = ({ className, placement, style, ...props }) => (
  <div
    className={classnames(
      'ColorPickerPopover',
      'ColorPickerPopover__popover',
      'popover',
      placement,
      className,
    )}
    role='tooltip'
    style={{
      ...style,
      position: 'absolute',
      display: 'block',
    }}
  >
    <div className='arrow' />
    <SketchPicker {...props} />
  </div>
);

ColorPickerPopover.propTypes = {
  /**
   * An optional additional class name to apply to the container.
   */
  className: PropTypes.string,

  /**
   * Where the popover was placed relative to it's target. Typically provided by the
   * `Overlay` component.
   */
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),

  /**
   * The style to apply to the container. This is usually provided by the `Overlay` component
   * that would normally wrap this component.
   */
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default ColorPickerPopover;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Tooltip from '@thrivetrm/ui/components/Tooltip';

class NotificationItemToggleReadButton extends PureComponent {
  render() {
    const { isUpdating, onClick, unread } = this.props;
    const tooltipContent = unread ? 'Mark as read' : 'Mark unread';

    return (
      <Tooltip content={tooltipContent} position='bottomLeft' size='medium'>
        <button
          className={classnames(
            'notification-read-toggle-button',
            'btn btn-default',
            {
              disabled: isUpdating,
            },
          )}
          disabled={isUpdating}
          onClick={onClick}
          type='button'
        >
          <i
            className={classnames('fa', {
              'fa-flag-o': !isUpdating && !unread,
              'fa-check': !isUpdating && unread,
              'fa-refresh': isUpdating,
              'fa-spin': isUpdating,
            })}
          />
        </button>
      </Tooltip>
    );
  }
}

NotificationItemToggleReadButton.propTypes = {
  isUpdating: PropTypes.bool,

  /**
   * Called when the button is clicked with the synthetic React click event.
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The current unread state
   * @type {Boolean}
   */
  unread: PropTypes.bool.isRequired,
};

export default NotificationItemToggleReadButton;

import React from 'react';
import { compose, setStatic } from 'recompose';
import PropTypes from 'prop-types';
import { CompositeDecorator, EditorState } from 'draft-js';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import RichTextArea from './RichTextArea';
import { fromHtml, toHtml } from './convert';

export const requiredField = (fieldLabel, verb = 'is') => fieldState => {
  const editorState = fieldState.getValue();
  const value = toHtml(editorState.getCurrentContent());

  if (value.trim().length > 0) {
    return null;
  }

  return `${fieldLabel} ${verb} required`;
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

Link.propTypes = {
  children: PropTypes.node,
  contentState: PropTypes.shape({
    getEntity: PropTypes.func,
  }),
  entityKey: PropTypes.string,
};

export default compose(
  setStatic(
    'createFieldState',
    (name, value, validator, transformResult = result => result) => {
      const decorator = new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link,
        },
      ]);
      return FieldState.create(
        name,
        value
          ? EditorState.createWithContent(fromHtml(value), decorator)
          : EditorState.createEmpty(decorator),
        validator,
        editorState => transformResult(toHtml(editorState.getCurrentContent())),
      );
    },
  ),
  withFormGroup,
  asField(),
)(RichTextArea);

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

/**
 * A component for indicating there is no data to display.
 * It contains:
 *   * An optional icon
 *   * A message ("no data found", etc...)
 *   * An optional button (this can be used to reload the dataset, or perhaps trigger some
 *     action for adding an item to the dataset). The button is only rendered if `buttonText`
 *     and `onButtonClick` props are provided.
 */
const EmptyState = ({
  buttonClassName,
  buttonText,
  className,
  icon,
  message,
  onButtonClick,
}) => (
  <div className={classnames('EmptyState', className)}>
    {icon}
    <div className='EmptyState__message'>{message}</div>
    {buttonText && onButtonClick && (
      <button
        className={classnames('EmptyState__button', 'btn', buttonClassName)}
        onClick={onButtonClick}
        type='button'
      >
        {buttonText}
      </button>
    )}
  </div>
);

EmptyState.defaultProps = {
  buttonClassName: 'btn-secondary-outline',
  buttonText: null,
  className: null,
  icon: null,
  onButtonClick: null,
};

EmptyState.propTypes = {
  /**
   * Class names to apply to the button (if `buttonText` and `onButtonClick` are provided)
   */
  buttonClassName: PropTypes.string,

  /**
   * Text to display in the "action" button - `onButtonClick` is also required for the button to be
   * displayed.
   */
  buttonText: PropTypes.node,

  /**
   * Optional class name to apply to the component.
   */
  className: PropTypes.string,

  /**
   * An optional icon to render
   */
  icon: PropTypes.node,

  /**
   * The message to display ("No data found", etc...)
   */
  message: PropTypes.node.isRequired,

  /**
   * Called when the button is clicked. This must be provided in conjunction with the `buttonText`
   * property for the button to be rendered.
   */
  onButtonClick: PropTypes.func,
};

export default EmptyState;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import getCompanyProperty from 'modules/companies/selectors/getCompanyProperty';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import ExternalLink from 'modules/links/ExternalLink';
import { useApiPatch, useApiPost } from 'modules/core/hooks/useApi';
import routes from 'modules/routing/routes';
import invalidateCompany from 'modules/companies/actions/invalidateCompany';
import fetchAddressesList from 'modules/addresses/actions/fetchAddressesList';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import SkyminyrCompanySelectModal from './SkyminyrCompanySelectModal';
import './SkyminyrCompanyEnrich.scss';
import { getSkyminyrCompanyCreatePayload } from './getSkyminyrCompanyCreatePayload';

const SkyminyrCompanyEnrichButton = ({ companyId, onEnrichmentStart }) => {
  const dispatch = useDispatch();
  const [
    isEnrichCompanyModalOpen,
    openEnrichCompanyModal,
    closeEnrichCompanyModal,
  ] = useToggle(false);
  const isCompanyEnriched = useSelector(state =>
    getCompanyProperty(state, companyId, 'data_enriched'),
  );

  const [updateCompany, isUpdatingCompany] = useApiPatch(
    routes.api_v1_company({ id: companyId }),
  );
  const [reenrichCompany, isEnrichingCompany] = useApiPost(
    routes.reenrich_api_v1_company({ id: companyId }),
  );

  const handleRefreshButton = () => {
    onEnrichmentStart();
    reenrichCompany(
      {},
      {
        onSuccess: () => {
          dispatch(toastSuccess('Company data re-enriched successfully'));
          dispatch(invalidateCompany({ id: companyId }));
          dispatch(
            fetchAddressesList({ parentId: companyId, parentType: 'Company' }),
          );
        },
        onError: () => {
          dispatch(toastError('Could not re-enrich company'));
        },
      },
    );
  };

  const handleSubmit = ({ company }) => {
    updateCompany(
      { company: getSkyminyrCompanyCreatePayload(company.data) },
      {
        onSuccess: () => {
          closeEnrichCompanyModal();
          dispatch(invalidateCompany({ id: companyId }));
          dispatch(
            fetchAddressesList({ parentId: companyId, parentType: 'Company' }),
          );
          dispatch(
            toastSuccess(
              'The company is now enriched and will automatically update with latest metadata.',
              { title: 'Success!' },
            ),
          );
        },
        onError: errorMessage => {
          dispatch(
            toastError(errorMessage, { title: 'Could not enrich company' }),
          );
        },
      },
    );
  };

  return (
    <>
      {isCompanyEnriched ? (
        <>
          <ButtonSecondary
            className='SkyminyrCompanyEnrich__button u-marginLeft-8 u-textColor-darkBlue'
            isDisabled={true}
            label={
              <div>
                Company Data Enriched
                <StatusIndicator
                  className='u-marginLeft-8'
                  size='medium'
                  status='success'
                />
              </div>
            }
          />
          <Tooltip
            className='u-marginLeft-8'
            content='Re-Enrich company details'
            position='right'
            size='medium'
          >
            <ButtonSecondary
              icon='refresh'
              isDisabled={isEnrichingCompany}
              isLoading={isEnrichingCompany}
              onClick={handleRefreshButton}
              size='small'
            />
          </Tooltip>
        </>
      ) : (
        <Tooltip
          content='Link company with a golden record that will automatically sync and update with new metadata.'
          position='bottom'
          size='large'
        >
          <ButtonPrimary
            className='SkyminyrCompanyEnrich__button u-marginLeft-8'
            label='Enrich Company Data'
            onClick={openEnrichCompanyModal}
          />
        </Tooltip>
      )}
      <SkyminyrCompanySelectModal
        actionConfirmationText={
          <>
            <StatusIndicator
              className='u-marginTop-4 u-marginRight-4'
              status='info'
            />
            <p className='u-margin-n u-textColor-gray60'>
              Confirm company record to link with. Once company is enriched, it
              will overwrite company record with the most up to date
              information. Click <b>Unselect</b> to select again.
            </p>
          </>
        }
        companySearchGuidance={
          <p className='u-textAlign-c u-textColor-blue'>
            Search for a company to link <br /> with your company record.
            <br />
            <ExternalLink
              className='SkyminyrCompanySelect__learnMore'
              href='https://helpcenter.thrivetrm.com/en/'
            >
              Learn more
            </ExternalLink>
          </p>
        }
        isLoading={isUpdatingCompany}
        isOpen={isEnrichCompanyModalOpen}
        onClose={closeEnrichCompanyModal}
        onSubmit={handleSubmit}
        submitButtonLabel='Link Company'
        title='Enrich company'
      />
    </>
  );
};
SkyminyrCompanyEnrichButton.propTypes = {
  companyId: PropTypes.number.isRequired,
  onEnrichmentStart: PropTypes.func,
};

export default SkyminyrCompanyEnrichButton;

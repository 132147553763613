import routes from 'modules/routing/routes';
import { PARENT_CANDIDACY, PARENT_CONTACT } from '../constants';

/**
 * Gets the URL for a list of references related to a parent.
 * @param {Object} params
 * @param {String} params.parentId
 * @param {String} params.parentType
 */
export default ({ parentId, parentType }) => {
  switch (parentType) {
    case PARENT_CANDIDACY: {
      return routes.api_v1_candidacy_references({ candidacyId: parentId });
    }
    case PARENT_CONTACT: {
      return routes.api_v1_contact_references({ contactId: parentId });
    }
    default: {
      throw new Error(`Unknown parentType for references: ${parentType}`);
    }
  }
};

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { compose, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import ReactSelect from 'react-select-legacy';
import Icon from '@thrivetrm/ui/components/Icon';

import asFormGroupField from 'modules/forms/components/asFormGroupField';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import fetchRejectionReasonsIfNeeded from './fetchRejectionReasonsIfNeeded';
import getRejectionReasons from '../selectors/getRejectionReasons';
import getRejectionReasonsMeta from '../selectors/getRejectionReasonsMeta';

/**
 * A component that displays a dropdown for selecting a rejection reason.
 */
const id = uniqueId();

export const RejectionReasonSelect = ({
  isFetching,
  onChange,
  rejectionReasons,
  shouldShowLabel,
  value,
}) => {
  useEffect(() => {
    if (!value && rejectionReasons?.length) {
      // Default the selection to the first option
      onChange(rejectionReasons[0].value);
    }
  }, [value, rejectionReasons]);

  return (
    <>
      {shouldShowLabel ? (
        <label className='control-label' htmlFor={id}>
          Rejection Reason
        </label>
      ) : null}
      <ReactSelect
        arrowRenderer={() => (
          <Icon
            aria-hidden={true}
            className='SelectMenu__buttonIcon'
            data-testid='select menu button icon'
            type='dropdown'
          />
        )}
        clearable={false}
        id={id}
        isLoading={isFetching}
        onChange={onChange}
        options={rejectionReasons}
        placeholder='Rejection reason...'
        simpleValue={true}
        value={value}
      />
    </>
  );
};

RejectionReasonSelect.propTypes = {
  isFetching: PropTypes.bool,
  onChange: PropTypes.func,
  rejectionReasons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  shouldShowLabel: PropTypes.bool,
  value: PropTypes.string,
};

RejectionReasonSelect.defaultProps = {
  isFetching: false,
  shouldShowLabel: false,
};

export default compose(
  setDisplayName('RejectionReasonSelectField(enhanced)'),
  asFormGroupField,
  fetchRejectionReasonsIfNeeded,
  connect(state => {
    const reasons = getRejectionReasons(state);
    const meta = getRejectionReasonsMeta(state);
    const rejectionReasons =
      reasons && reasons.map(label => ({ label: label, value: label })).toJS();

    return {
      isFetching: meta && meta.get('isFetching'),
      rejectionReasons: rejectionReasons,
    };
  }, {}),
)(RejectionReasonSelect);

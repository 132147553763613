import { defaultTo } from 'lodash';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import revenueGrowthSchema from '../schemas/revenueGrowths';

/**
 * Gets a number indicating the order that a revenue growth should be sorted in, within a list
 * of other revenue growth records..
 * @param {*} state
 * @param {Number} id The revenue growth entity ID
 * @return {Number} The order of the revenue growth record for sorting.
 */
export default (state, id) =>
  defaultTo(
    getEntityProperty(state, revenueGrowthSchema.key, id, 'order'),
    Number.MAX_SAFE_INTEGER,
  );

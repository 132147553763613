import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {
  compose,
  setDisplayName,
  setStatic,
  withHandlers,
  withState,
} from 'recompose';

import RecommendationListItem from './RecommendationListItem';
import recommendedCandidatePropType from '../recommendedCandidatePropType';

/**
 * The height of this item when rendered in the list.
 * IMPORTANT: This must match the `height` style defined `.RecommendationListItemGroupHeader`
 * in `RecommendationListItemGroupHeader.scss`!
 * @const {Number}
 */
export const HEIGHT = 50;

/**
 * A component that renders the group header for a "Not a Fit" section of Recommendation items
 */
const RecommendationListItemGroupHeader = ({
  candidacyData,
  handleToggleExpanded,
  isExpanded,
  itemCount,
}) => (
  <div>
    <button
      className={classnames(
        'RecommendationListItemGroupHeader RecommendationListItemGroupHeader__btnNotAFit',
        {
          'RecommendationListItemGroupHeader--collapsed': !isExpanded,
          'RecommendationListItemGroupHeader--isExpanded': isExpanded,
        },
      )}
      onClick={handleToggleExpanded}
      style={{ height: HEIGHT }}
      type='button'
    >
      <span className='RecommendationListItemGroupHeader__Name' key='name'>
        Not a Fit
      </span>
      <span
        className='RecommendationListItemGroupHeader__ItemCount'
        key='count'
      >
        {itemCount}
      </span>
      <span
        className='RecommendationListItemGroupHeader__ExpandedIndicator'
        key='indicator'
      >
        <i
          className={classnames('fa', 'fa-fw', 'fa-sm', {
            'icon-expand': !isExpanded,
            'icon-collapse': isExpanded,
          })}
        />
      </span>
    </button>
    {isExpanded &&
      candidacyData.map(result => {
        return <RecommendationListItem key={result.id} result={result} />;
      })}
  </div>
);

RecommendationListItemGroupHeader.propTypes = {
  /**
   * List to be grouped under a expansion header
   */
  candidacyData: PropTypes.arrayOf(recommendedCandidatePropType).isRequired,
  /**
   * Called when the group's isExpanded state should be toggled.
   */
  handleToggleExpanded: PropTypes.func.isRequired,
  /**
   * Whether this group is currently isExpanded (true) or collapsed (false)
   */

  isExpanded: PropTypes.bool.isRequired,

  /**
   * The number of child items within this group.
   */
  itemCount: PropTypes.number.isRequired,
};

RecommendationListItemGroupHeader.getHeight = () => HEIGHT;

export default compose(
  setDisplayName('RecommendationListItemGroupHeader(enhanced)'),
  setStatic('HEIGHT', HEIGHT),
  withState('isExpanded', 'setIsExpanded', ({ isExpanded }) =>
    Boolean(isExpanded),
  ),
  withHandlers({
    handleToggleExpanded: ({ isExpanded, setIsExpanded }) => e => {
      e.preventDefault();
      setIsExpanded(!isExpanded);
    },
  }),
)(RecommendationListItemGroupHeader);

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import pluralize from 'pluralize';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import mapCompanyIdToCompany from 'modules/companies/components/mapCompanyIdToCompany';
import ContactNameList from 'modules/contacts/components/ContactNameList';
import CommaDelimitedList from 'modules/core/componentsLegacy/CommaDelimitedList';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import Percentage from '@thrivetrm/ui/components/Percentage';
import Currency from '@thrivetrm/ui/components/Currency';
import DateTime from 'modules/datetime/components/DateTime';
import momentPropType from 'modules/datetime/propTypes/momentPropType';
import FundName from 'modules/funds/components/FundName';
import CompanyPriorityName from 'modules/tenant/components/CompanyPriorityName';
import RevenueGrowthName from 'modules/tenant/components/RevenueGrowthName';
import withTenantName from 'modules/tenant/components/withTenantName';
import { STAGES, STAGE_PORTFOLIO, STAGE_EXITED } from '../constants';

const willRender = ({ company }) =>
  company && STAGES.includes(company.get('off_limits_status'));

/**
 * Renders the properties pertaining to a company's "portfolio company" status.
 */
const CompanyPortfolioDetailsView = ({
  allowBoardOfDirectors,
  allowRevenueGrowth,
  company,
  tenantName,
}) => {
  const stage = company.get('off_limits_status');
  const isPortfolioStage = stage === STAGE_PORTFOLIO || stage === STAGE_EXITED;
  const isExitStage = stage === STAGE_EXITED;

  return (
    <div className='CompanyPortfolioDetailsView row'>
      {STAGES.includes(stage) && (
        <LabeledContent
          className='CompanyPortfolioDetailsView__off_limits_status col-sm-6 col-12'
          key='off_limits_status'
          label='Investment Stage'
        >
          {company.get('off_limits_status')}
        </LabeledContent>
      )}
      {isPortfolioStage &&
        company.get('funds') &&
        company.get('funds').count() > 0 && (
          <LabeledContent
            className='CompanyPortfolioDetailsView__funds col-sm-6 col-12'
            key='funds'
            label={pluralize('Fund', company.get('funds').count())}
          >
            <CommaDelimitedList>
              {company
                .get('funds')
                .map(fundId => (
                  <li key={fundId}>
                    <FundName fundId={fundId} />
                  </li>
                ))
                .toArray()}
            </CommaDelimitedList>
          </LabeledContent>
        )}
      {isPortfolioStage && company.get('percent_owned') && (
        <LabeledContent
          className='CompanyPortfolioDetailsView__percent_owned col-sm-6 col-12'
          key='percent_owned'
          label='Ownership'
        >
          <Percentage value={company.get('percent_owned')} />
        </LabeledContent>
      )}
      {isPortfolioStage && company.get('investment_value') && (
        <LabeledContent
          className='CompanyPortfolioDetailsView__investment_value col-sm-6 col-12'
          key='investment_value'
          label='Projected Value'
        >
          <Currency value={company.get('investment_value')} />
        </LabeledContent>
      )}
      {isPortfolioStage && company.get('initial_investment_date') && (
        <LabeledContent
          className='CompanyPortfolioDetailsView__initial_investment_date col-sm-6 col-12'
          key='initial_investment_date'
          label='Initial Investment Date'
        >
          <DateTime value={company.get('initial_investment_date')} />
        </LabeledContent>
      )}
      {isExitStage && company.get('exit_date') && (
        <LabeledContent
          className='CompanyPortfolioDetailsView__exit_date col-sm-6 col-12'
          key='exit_date'
          label='Exit Date'
        >
          <DateTime value={company.get('exit_date')} />
        </LabeledContent>
      )}
      {isPortfolioStage &&
        company.get('partners') &&
        company.get('partners').count() > 0 && (
          <LabeledContent
            className='CompanyPortfolioDetailsView__partners col-sm-6 col-12'
            key='partners'
            label={`${tenantName} ${pluralize(
              'Partner',
              company.get('partners').count(),
            )}`}
          >
            <ContactNameList
              contactIds={company.get('partners')}
              linkToContactPage={true}
              useDefaultLinkBehavior={true}
            />
          </LabeledContent>
        )}
      {isPortfolioStage &&
        allowBoardOfDirectors &&
        company.get('board_of_directors') && (
          <LabeledContent
            className='CompanyPortfolioDetailsView__board_of_directors col-sm-6 col-12'
            key='board_of_directors'
            label={`${tenantName} Board of Directors`}
          >
            {company.get('board_of_directors')}
          </LabeledContent>
        )}
      {isPortfolioStage && typeof company.get('control_deal') === 'boolean' && (
        <LabeledContent
          className='CompanyPortfolioDetailsView__control_deal col-sm-6 col-12'
          key='control_deal'
          label='Control Deal'
        >
          {company.get('control_deal') ? 'Yes' : 'No'}
        </LabeledContent>
      )}
      {isPortfolioStage && Number.isFinite(company.get('company_priority')) && (
        <LabeledContent
          className='CompanyPortfolioDetailsView__company_priority col-sm-6 col-12'
          key='company_priority'
          label='Priority'
        >
          <CompanyPriorityName
            companyPriorityId={company.get('company_priority')}
          />
        </LabeledContent>
      )}
      {isPortfolioStage &&
        allowRevenueGrowth &&
        Number.isFinite(company.get('revenue_growth')) && (
          <LabeledContent
            className='CompanyPortfolioDetailsView__revenue_growth col-sm-6 col-12'
            key='revenue_growth'
            label='Revenue Growth'
          >
            <RevenueGrowthName
              revenueGrowthId={company.get('revenue_growth')}
            />
          </LabeledContent>
        )}
    </div>
  );
};

CompanyPortfolioDetailsView.propTypes = {
  /**
   * Whether the board of directors field should be shown if it has a value.
   */
  allowBoardOfDirectors: PropTypes.bool,

  /**
   * Whether the revenue growth field should be shown if it has a value.
   */
  allowRevenueGrowth: PropTypes.bool,

  /**
   * The company record that has the portfolio details properties loaded.
   */
  company: ImmutablePropTypes.mapContains({
    board_of_directors: PropTypes.string,
    company_priority: PropTypes.number,
    control_deal: PropTypes.bool,
    exit_date: momentPropType.iso8601,
    funds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
    initial_investment_date: momentPropType.iso8601,
    investment_value: PropTypes.number,
    off_limits_status: PropTypes.oneOf(STAGES),
    partners: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
    percent_owned: PropTypes.number,
    revenue_growth: PropTypes.number,
  }).isRequired,

  /**
   * The name of the current tenant.
   */
  tenantName: PropTypes.string,
};

CompanyPortfolioDetailsView.defaultProps = {
  allowBoardOfDirectors: false,
  allowRevenueGrowth: false,
};

export default compose(
  setStatic('willRender', willRender),
  setDisplayName('CompanyPortfolioDetailsView(enhanced)'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
  branch(({ company }) => !company, renderNothing),
  withFeatureCheck('field.company.board_of_directors', 'allowBoardOfDirectors'),
  withFeatureCheck('field.company.revenue_growth', 'allowRevenueGrowth'),
  withTenantName(),
)(CompanyPortfolioDetailsView);

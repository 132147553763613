import getUser from './getUser';

/**
 * Gets the displayable user name for a given user record.
 */
export default (state, userId) => {
  const user = getUser(state, userId);

  // This should be cleaned up, but we've got various properties being returned, so check
  // for any of them.
  return (
    user &&
    (user.get('name') ||
      user.get('full_name') ||
      user.get('full_name_or_email'))
  );
};

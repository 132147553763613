import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import EntitySelect from 'modules/entities/components/EntitySelect';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import withTimezoneListFetched from './withTimezoneListFetched';
import timezoneSchema from '../schemas/timezones';
import { DEFAULT_TIMEZONE } from '../constants';

/**
 * A function for creating a tag select component for a list of IDs stored in tenantOptions
 * @param {Object} options
 * @param {String} optionsKey The key on the tenantOptions state that contains the IDs.
 * @param {String} entityType The entity type the IDs are for.
 * @param {String} [entityLabel] The label value to pass to the EntityTagSelect
 * @param {String} [sortByProperty='name'] The value to sort the IDs by.
 */
export default compose(
  setDisplayName('PortfolioCompaniesTagSelectField'),
  setPropTypes({
    /**
     * A FieldState with an underlying value that is an array of target market IDs.
     */
    fieldState: fieldStatePropType.isRequired,

    /**
     * Called when the fieldState is changed by the user adding/removing target markets.
     */
    onChange: PropTypes.func.isRequired,
  }),

  setStatic('createFieldState', (name, value, ...rest) =>
    FieldState.create(name, value || DEFAULT_TIMEZONE, ...rest),
  ),

  withTimezoneListFetched,

  mapProps(({ timezoneList, ...rest }) => ({
    ...rest,
    entityIds: timezoneList.has('ids') ? timezoneList.get('ids').toArray() : [],
    entityType: timezoneSchema.key,
    isLoading: timezoneList.getIn(['meta', 'isFetching']),
    labelPropertyName: 'label',
  })),

  // Wrap in a formgroup and provide conversion between our fieldState and it's underlying value.
  withFormGroup,
  asField(),
)(EntitySelect);

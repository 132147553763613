import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import merge from 'modules/entities/actions/merge';
import CandidaciesPerStageDonutChart from 'modules/searches/components/candidacyStats/CandidaciesPerStageDonutChart';
import { searchSchema } from 'modules/searches/schema';
import { withStateProvider } from './app/StateProvider';

export default compose(
  setDisplayName('CandidaciesPerStageDonutChart(container)'),
  setPropTypes({
    search: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }),
  // Attach our store/state to the react context tree.
  withStateProvider,
  connect(null, {
    mergeEntity: merge,
  }),

  // The search is passed in from the server side so that we don't have to refetch it with
  // an ajax call. This injects that data into our state.
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const { mergeEntity, search } = this.props;
      if (search) {
        mergeEntity({
          data: search,
          schema: searchSchema,
        });
      }
    },
  }),
  mapProps(({ search }) => ({ searchId: search.id })),
)(CandidaciesPerStageDonutChart);

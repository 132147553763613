import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setStatic } from 'recompose';
import { useSelector } from 'react-redux';
import { fromJS } from 'immutable';
import { isNumber } from 'lodash';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import * as validators from 'modules/forms/validators';
import AliasListField from 'modules/aliases/AliasListField';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import getCompanyProperty from 'modules/companies/selectors/getCompanyProperty';
import Tooltip from '@thrivetrm/ui/components/Tooltip';

import CompanySelectField from '../CompanySelectField';
import { PARENT_SUGGESTION } from '../../constants';
import SkyminyrLegacyCompanySelect from '../skyminyr/SkyminyrLegacyCompanySelect';
import { getSkyminyrCompanyCreatePayload } from '../skyminyr/getSkyminyrCompanyCreatePayload';

/**
 * A field for editing the basic information about a company
 */
const CompanyBasicsField = ({
  companyId,
  fieldState,
  handleNestedFieldChange,
  hasExternalUrlField,

  // prevent onChange from being passed through to the input.
  onChange: _onChange,
  title,

  ...otherProps
}) => {
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const enrichedFields = useSelector(state =>
    getCompanyProperty(state, companyId, 'enriched_fields'),
  )?.toJS();

  return (
    <div className='CompanyBasicsField'>
      {title ? <h3>{title}</h3> : null}
      <div className='row'>
        <div className='col-sm-12'>
          <Tooltip
            className='u-block'
            content={
              hasSkyminyrFeature && enrichedFields?.includes('name')
                ? 'This field is automatically enriched, and in sync with the golden record.'
                : null
            }
            size='large'
          >
            <InputField
              {...otherProps}
              className='CompanyBasicsField__name'
              disabled={hasSkyminyrFeature && enrichedFields?.includes('name')}
              fieldState={fieldState.getNestedField('name')}
              label='Name'
              name='name'
              onChange={handleNestedFieldChange}
            />
          </Tooltip>
        </div>
        <div className='col-sm-12'>
          <Tooltip
            className='u-block'
            content={
              hasSkyminyrFeature && enrichedFields?.includes('aliases')
                ? 'This field is automatically enriched, and in sync with the golden record.'
                : null
            }
            size='large'
          >
            <AliasListField
              {...otherProps}
              className='CompanyBasicsField__aliases'
              disabled={
                hasSkyminyrFeature && enrichedFields?.includes('aliases')
              }
              fieldState={fieldState.getNestedField('aliases_attributes')}
              label='Aliases'
              name='aliases_attributes'
              onChange={handleNestedFieldChange}
              recordType='company'
            />
          </Tooltip>
        </div>
        <div className='col-sm-12'>
          <Tooltip
            className='u-block'
            content={
              hasSkyminyrFeature && enrichedFields?.includes('website_url')
                ? 'This field is automatically enriched, and in sync with the golden record.'
                : null
            }
            size='large'
          >
            <InputField
              {...otherProps}
              className='CompanyBasicsField__website_url'
              disabled={
                hasSkyminyrFeature && enrichedFields?.includes('website_url')
              }
              fieldState={fieldState.getNestedField('website_url')}
              label='Website'
              name='website_url'
              onChange={handleNestedFieldChange}
            />
          </Tooltip>
        </div>
        {hasExternalUrlField ? (
          <div className='col-sm-12'>
            <InputField
              {...otherProps}
              className='CompanyBasicsField__external_url'
              fieldState={fieldState.getNestedField('external_url')}
              label='External URL'
              name='external_url'
              onChange={handleNestedFieldChange}
            />
          </div>
        ) : null}
        <div className='col-sm-12'>
          {hasSkyminyrFeature ? (
            <SkyminyrLegacyCompanySelect
              fieldState={fieldState.getNestedField('parent_company')}
              label='Parent Company'
              onChange={handleNestedFieldChange}
              queryParams={{
                parentCompanyId: companyId,
              }}
            />
          ) : (
            <CompanySelectField
              {...otherProps}
              allowCreate={true}
              className='CompanyBasicsField__parent_company'
              fieldState={fieldState.getNestedField('parent_company')}
              filter={PARENT_SUGGESTION}
              label='Parent Company'
              onChange={handleNestedFieldChange}
              parentCompanyId={companyId}
            />
          )}
        </div>
        <div className='col-sm-12'>
          <InputField
            {...otherProps}
            className='CompanyBasicsField__main_phone'
            fieldState={fieldState.getNestedField('main_phone')}
            label='Phone'
            name='main_phone'
            onChange={handleNestedFieldChange}
          />
        </div>
        <div className='col-sm-12'>
          <InputField
            {...otherProps}
            className='CompanyBasicsField__work_phone'
            fieldState={fieldState.getNestedField('work_phone')}
            label='Alternative Phone'
            name='work_phone'
            onChange={handleNestedFieldChange}
          />
        </div>
      </div>
    </div>
  );
};

CompanyBasicsField.propTypes = {
  /**
   * Id to be used later to fetch subsidiary companies.
   */
  companyId: PropTypes.number,

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when a nested field has changed
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  hasExternalUrlField: PropTypes.bool.isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  /**
   * An optional title to display at the top of the field.
   */
  title: PropTypes.node,
};

const createFieldState = (name = 'company', company) => {
  const values = fromJS({
    name: '',
    website_url: '',
    parent_company: null,
    external_url: '',
    main_phone: '',
    work_phone: '',
    aliases: [],
  }).merge(company);

  const hasSkyminyrFeature = values.get('hasSkyminyrFeature');

  return FieldState.createNested(
    name,
    [
      InputField.createFieldState(
        'name',
        values.get('name'),
        validators.requiredField('Name'),
      ),
      InputField.createFieldState('website_url', values.get('website_url')),
      InputField.createFieldState('external_url', values.get('external_url')),
      hasSkyminyrFeature
        ? SkyminyrLegacyCompanySelect.createFieldState(
            'parent_company',
            values.get('parent_company'),
          )
        : CompanySelectField.createFieldState(
            'parent_company',
            values.get('parent_company'),
          ),
      InputField.createFieldState('main_phone', values.get('main_phone')),
      InputField.createFieldState('work_phone', values.get('work_phone')),
      AliasListField.createFieldState(
        'aliases_attributes',
        values.get('aliases').toArray(),
      ),
    ],
    null,
    ({ parent_company: parentCompany, ...formState }) => {
      const parentCompanyId =
        (isNumber(parentCompany) ? parentCompany : parentCompany?.data?.id) ||
        null;
      return {
        parent_company: hasSkyminyrFeature ? parentCompanyId : parentCompany,
        parent_company_attributes:
          hasSkyminyrFeature && parentCompany?.data?.skyminyr_id
            ? getSkyminyrCompanyCreatePayload(parentCompany.data)
            : null,
        ...formState,
      };
    },
  );
};

export default compose(
  setDisplayName('CompanyBasicsField(enhanced'),
  setStatic('createFieldState', createFieldState),
  withNestedFieldChangeHandler,
  withFeatureCheck('field.company.external_url', 'hasExternalUrlField'),
)(CompanyBasicsField);

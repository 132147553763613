import PropTypes from 'prop-types';
import React from 'react';
import { OrderedMap } from 'immutable';
import { compose, setDisplayName, setStatic } from 'recompose';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import FieldState from 'modules/forms/FieldState';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import DuplicatePositionCard from './DuplicatePositionCard';

import ContactPositionField from './ContactPositionField';

/**
 * A custom validator for the ContactPositionListField that only allows a single
 * underlying position to be selected as the "primary" position.
 * @param {FieldState} fieldState The `ContactPositionListField`'s FieldState
 * @return {String} An error message if invalid, or null if valid.
 */
const primaryPositionValidator = fieldState => {
  const primaryPositions = fieldState.getNestedFields().filter(position => {
    return position.getNestedField('primary').getValue();
  });

  if (primaryPositions.size > 1) {
    return 'Only one primary position can be selected.';
  }

  return null;
};

/**
 * This is a field for editing a collection of multiple positions at once.
 * This is used when we can't submit positions as they are created (in the case of, for example,
 * when an underlying contact record doesn't exist yet).
 */
const ContactPositionListField = ({
  fieldState,
  onChange,
  positionIdsRenderStructure,
  setPositionIdsRenderStructure,
  strategy,
  title,
  ...otherProps
}) => {
  /**
   * Creates a fieldState for the ContactPositionListField.
   * @param {String} [name='positions'] The field name
   * @param {Immutable.List} positions The list of position objects. Each position in the list
   *   should be a position record itself (an Immutable.Map) with a unique ID.
   */

  const handleFieldChange = childFieldState => {
    onChange(fieldState.setNestedField(childFieldState));
  };
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const handleAdd = () => {
    // When adding a  new ID, we generate a random unique ID and in order to ensure it shows
    // at the top of the list, we create a new OrderedMap and concat it to the existing one.
    const id = uniqueId('NEW');
    const newMap = new OrderedMap([
      [id, ContactPositionField.createFieldState(id, null, hasSkyminyrFeature)],
    ]);
    onChange(fieldState.setValue(newMap.concat(fieldState.getValue())));
    setPositionIdsRenderStructure([id, ...positionIdsRenderStructure]);
  };

  const handleRemove = (event, positionId) => {
    // Call change handler with fieldState with deleted item removed
    onChange(fieldState.setValue(fieldState.getValue().delete(positionId)));

    // remove the ID from the structured array
    const removeItemFromArray = (items, itemToRemove) => {
      const newArray = [];
      items.forEach(item => {
        if (Array.isArray(item)) {
          const newSubArray = removeItemFromArray(item, itemToRemove);
          if (newSubArray.length) {
            newArray.push(newSubArray);
          }
        } else if (item !== itemToRemove) {
          newArray.push(item);
        }
      });
      return newArray;
    };
    setPositionIdsRenderStructure(
      removeItemFromArray(positionIdsRenderStructure, positionId),
    );
  };

  const getDuplicatePositions = duplicatePositionIdList => {
    return (
      <li className='u-borderTop' key={duplicatePositionIdList.join('')}>
        <h2
          className='duplicatePositions__heading'
          data-testid='duplicate position heading'
        >
          <StatusIndicator
            className='u-marginRight-8'
            size='large'
            status='warning'
          />
          Potential Duplicates
        </h2>
        <p>You can keep, discard, or edit the potential duplicate positions.</p>
        <div className='DuplicatePositionsList'>
          {duplicatePositionIdList.map(duplicatePositionId => {
            const isExistingPositionRecord = Boolean(
              parseInt(duplicatePositionId),
            );
            return (
              <DuplicatePositionCard
                deleteButtonLabel={
                  isExistingPositionRecord ? 'Delete' : 'Discard'
                }
                id={`position-${duplicatePositionId}`}
                key={duplicatePositionId}
                onDelete={handleRemove}
                positionId={duplicatePositionId}
                title={
                  isExistingPositionRecord
                    ? 'Currently in Thrive'
                    : `New from ${strategy}`
                }
              >
                <ContactPositionField
                  allowPrimarySelect={true}
                  fieldState={fieldState.getNestedField(duplicatePositionId)}
                  layout='narrow'
                  onChange={handleFieldChange}
                  primaryPositionError={fieldState.getError()}
                  {...otherProps}
                />
              </DuplicatePositionCard>
            );
          })}
        </div>
      </li>
    );
  };

  const getSinglePosition = positionId => {
    return (
      <li
        className='ContactPositionListField__list-item u-padding-24'
        id={`position-${positionId}`}
        key={positionId}
      >
        <div className='u-textAlign-r'>
          <ConfirmationPopoverButton
            className='Button Button--secondary Button--medium Button--hasIconOnly'
            iconClass='fa fa-trash'
            key='delete'
            onConfirm={handleRemove}
            title='Are you sure you want to delete this position?'
            value={positionId}
          />
        </div>
        <ContactPositionField
          allowPrimarySelect={true}
          fieldState={fieldState.getNestedField(positionId)}
          onChange={handleFieldChange}
          primaryPositionError={fieldState.getError()}
          {...otherProps}
        />
      </li>
    );
  };

  return (
    <div className='ContactPositionListField'>
      <div className='u-flex u-flexAlign-c u-marginBottom-16'>
        <h2 className='u-flexItem-grow'>{title}</h2>
        <div className='u-paddingTop-8'>
          <ButtonSecondary
            icon='add'
            label='New Position'
            onClick={handleAdd}
            size='small'
          />
        </div>
      </div>
      <ul className='ContactPositionListField__list list-unstyled'>
        {positionIdsRenderStructure.map(positionOrDuplicates =>
          Array.isArray(positionOrDuplicates)
            ? getDuplicatePositions(positionOrDuplicates)
            : getSinglePosition(positionOrDuplicates),
        )}
      </ul>
    </div>
  );
};

ContactPositionListField.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  onChange: PropTypes.func.isRequired,

  positionIdsRenderStructure: PropTypes.arrayOf(PropTypes.node),
  potentialDuplicatePositionsCount: PropTypes.number,
  setPositionIdsRenderStructure: PropTypes.func,

  strategy: PropTypes.string.isRequired,

  title: PropTypes.string,
};

ContactPositionListField.defaultProps = {
  title: 'Experience',
};

ContactPositionListField.createFieldState = (
  name = 'positions',
  positions,
  hasSkyminyrFeature,
) => {
  if (!positions || positions.size < 1) {
    return FieldState.create(name, new OrderedMap());
  }

  let positionsMap = new OrderedMap(
    positions.map(position => [position.get('id'), position]),
  );
  positions.map(position => {
    const duplicatePositionsMap = new OrderedMap(
      position
        .get('duplicate_positions', [])
        .map(duplicatePosition => [
          duplicatePosition.get('id'),
          duplicatePosition,
        ]),
    );
    // Flatten duplicate positions
    positionsMap = positionsMap.merge(duplicatePositionsMap);
    return duplicatePositionsMap;
  });

  return FieldState.create(
    name,
    // The underlying fieldstate value is an ordered map -- we need to make sure we show
    // positions with the most recent one first.
    positionsMap.map((position, id) =>
      ContactPositionField.createFieldState(id, position, hasSkyminyrFeature),
    ),
    primaryPositionValidator,
  );
};

export default compose(
  setDisplayName('ContactPositionListField(enhanced)'),
  setStatic('createFieldState', ContactPositionListField.createFieldState),
)(ContactPositionListField);

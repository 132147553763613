import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@thrivetrm/ui/components/Icon';
import classNames from 'classnames';

const QuickViewNavItem = ({
  className,
  count,
  icon,
  label,
  onClick,
  subLabel,
}) => {
  return (
    <button
      className={classNames('btn u-padding-n QuickViewNavItem', className)}
      onClick={onClick}
      type='button'
    >
      <div className='u-paddingVertical-16'>
        <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween'>
          <div className='u-flex u-flexAlign-c'>
            <Icon className='ContactProfilePanel__panelIcon' type={icon} />
            <h2 className='QuickViewNavItem__label u-margin-n u-marginLeft-8'>
              {label}
            </h2>
            {count ? (
              <span className='QuickViewNavItem__count u-marginLeft-8 u-fontSize-small u-backgroundColor-gray10'>
                {count}
              </span>
            ) : null}
          </div>
          <Icon type='chevronRight' />
        </div>
        {subLabel}
      </div>
    </button>
  );
};

QuickViewNavItem.defaultProps = {
  className: null,
  subLabel: null,
};

QuickViewNavItem.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subLabel: PropTypes.node,
};

export default QuickViewNavItem;

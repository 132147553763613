import getTenantFlag from 'modules/tenant/selectors/getTenantFlag';

/**
 * Determines if the current tenant should show fully diluted ownership related fields.
 * @param {*} state
 * @returns {Boolean} true if fully diluted ownership-related fields should be shown;
 *   Otherwise, false.
 */
export default state =>
  getTenantFlag(state, 'show_fully_diluted_ownership', false);

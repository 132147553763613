import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ContactName from 'modules/contacts/components/ContactName';
import mapSearchIdToSearchProperty from 'modules/searches/components/mapSearchIdToSearchProperty';
import mapCandidacyIdToSearchId from '../mapCandidacyIdToSearchId';
import mapDatumToCandidacyId from './mapDatumToCandidacyId';

/**
 * A table cell for rendering the name of the "current contact" (i.e. incumbent) for a search
 * (applies to Talent Pool searches), and links to the search page.
 */
export default compose(
  setDisplayName('CandidacySearchCurrentContactCell'),
  setPropTypes({
    /**
     * The candidacy ID
     */
    datum: PropTypes.number.isRequired,
  }),
  mapDatumToCandidacyId,
  mapCandidacyIdToSearchId,
  mapSearchIdToSearchProperty('current_contact', 'contactId'),
  branch(({ contactId }) => !contactId, renderNothing),
  mapProps(({ contactId }) => ({
    contactId: contactId,
    linkToContactPage: true,
  })),
)(ContactName);

import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';

import mapEmailTemplateIdToEmailTemplate from '../mapEmailTemplateIdToEmailTemplate';
import connectEmailTemplateActions from '../connectEmailTemplateActions';
import EmailTemplateFormModal from '../EmailTemplateFormModal';
import InlineEditActions from '../../../../components/forms/InlineEditActions';

/**
 * Renders the content of the table cell that shows an edit and delete button
 * That will trigger editing or deleting of the email template.
 */
export const EmailTemplatesTableEditDeleteCell = ({
  emailTemplate,
  handleDelete,
  hideEditModal,
  isEditModalShown,
  showEditModal,
}) => (
  <div className='EmailTemplatesTableEditDeleteCell'>
    <InlineEditActions
      canDelete={true}
      canEdit={true}
      deleteConfirmation='Are you sure you want to delete this template?'
      deleteLabel={<i className='fa fa-trash' />}
      disabled={
        emailTemplate.getIn(['_meta', 'isDeleting']) ||
        emailTemplate.getIn(['_meta', 'isUpdating'])
      }
      editLabel={<i className='fa fa-pencil' />}
      onDelete={handleDelete}
      onEdit={showEditModal}
    />
    {isEditModalShown && (
      <EmailTemplateFormModal
        emailTemplateId={emailTemplate.get('id')}
        onClose={hideEditModal}
        show={isEditModalShown}
      />
    )}
  </div>
);

EmailTemplatesTableEditDeleteCell.propTypes = {
  /**
   * The email template record the actions should be taken against.
   */
  emailTemplate: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isDeleting: PropTypes.bool,
      isUpdating: PropTypes.bool,
    }),
    id: PropTypes.number.isRequired,
  }),

  /**
   * Called when the delete button is clicked:
   */
  handleDelete: PropTypes.func.isRequired,

  /**
   * Called when the edit modal should be hidden.
   */
  hideEditModal: PropTypes.func.isRequired,

  /**
   * True if the edit modal should be visible; false otherwise.
   */
  isEditModalShown: PropTypes.bool.isRequired,

  /**
   * Called when the edit modal should be showwn.
   */
  showEditModal: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('EmailTemplatesTableEditDeleteCell(enhanced)'),
  setPropTypes({
    datum: PropTypes.number.isRequired,
  }),
  mapProps(({ datum }) => ({
    emailTemplateId: datum,
  })),
  connectEmailTemplateActions,

  // From the emailTemplateId get the underlying record. We need this for it's _meta status
  // so we can disable the edit/delete buttons while an operation is in progress
  mapEmailTemplateIdToEmailTemplate,

  // Add some props for managing the state of the edit modal and handling the callback from
  // clicking the delete button.
  withStateHandlers(
    {
      isEditModalShown: false,
    },
    {
      showEditModal: () => () => ({ isEditModalShown: true }),
      hideEditModal: () => () => ({ isEditModalShown: false }),
      handleDelete: (_, { emailTemplateActions, emailTemplateId }) => () =>
        emailTemplateActions.deleteEmailTemplate({ id: emailTemplateId }),
    },
  ),
)(EmailTemplatesTableEditDeleteCell);

import getUserPermission from 'modules/auth/selectors/getUserPermission';

/**
 * Gets a value indicating whether the current user can add/edit assessments of
 * type TYPE_RECRUITER.
 * @param {Object} state
 * @return {Boolean} True if the current user can add recruiter type assessments
 */
export default state =>
  getUserPermission(state, 'edit_recruiter_assessment', false);

import { schema } from 'normalizr';
import { searchSchema } from 'modules/searches/schema';
import jobFunctionSchema from 'modules/tenant/schemas/jobFunctions';

const jobListingSchema = new schema.Entity(
  'jobListings',
  { job_function: jobFunctionSchema },
  {
    // job_listings have a one-to-one relationship with a search, so we're going to
    // key job_listings on their underlying search_id. Otherwise we'd need an additional
    // map to lookup job listings from a search id, which adds complication for no good reason
    // (particularly since the endpoints for job listings are all based around the underlying
    // search_id). Basically, we currently have no use for the job listing ID.
    idAttribute: 'search_id',
  },
);

searchSchema.define({
  job_listing: jobListingSchema,
});

export default jobListingSchema;

import React, { useReducer, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import FullPageTakeoverHeader from '@thrivetrm/ui/components/FullPageTakeoverHeader';
import { useApiGet, useApiPost, useApiPatch } from '@thrivetrm/ui/hooks/useApi';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Card from '@thrivetrm/ui/components/Card';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import TextInput from '@thrivetrm/ui/components/TextInput';
import TextArea from '@thrivetrm/ui/components/TextArea';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import routes from 'modules/routing/routes';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import Drawer from '@thrivetrm/ui/components/Drawer';
import Form from '@thrivetrm/ui/components/Form';
import Modal from '@thrivetrm/ui/components/Modal';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import { scrollToElement } from 'modules/core/scrollUtils';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import AddExistingCriteriaButton from './AddExistingCriteriaButton';
import AssessmentCriteriaCard from './AssessmentCriteriaCard';
import CustomCriteriaModal from './CustomCriteriaModal';
import AssessmentCategoryDrawerAction from './AssessmentCategoryDrawerAction';

/**
 * We set criteriaId to -1 because we're creating a new custom criteria &
 * do not have an id yet and need truthy boolean logic to continue to work
 */
const UNSAVED_CRITERIA_ID = -1;

/**
 * This state object is used when isEditMode is true and the user is editing
 * criteria - it represents the base state where no criteria are currently being edited
 */
const EDIT_CRITERIA_BASE_STATE = {
  criteriaId: null,
  categoryId: null,
};

const templateReducer = (state, action) => ({ ...state, ...action });

const initialTemplate = {
  hasFinishedLoading: false,
  has_categories: null,
  disabled: false,
  name: null,
  description: null,
  data: [],
  search_id: null,
};

const AssessmentBuilder = ({ isTalentPool }) => {
  const location = useLocation();
  const [template, setTemplate] = useReducer(templateReducer, initialTemplate);
  const [isInEditMode, changeToEditMode, changeToViewMode] = useToggle(true);
  const [canCallApi] = useToggle(
    Boolean(
      location.pathname.endsWith('edit') || location.pathname.endsWith('new'),
    ),
  );

  const { assessment_template_id: savedTemplateId, id } = useParams();

  const searchId = parseInt(id) || null;

  // Sync search id to draft template state
  useEffect(() => {
    setTemplate({ search_id: searchId });
  }, [searchId]);

  const [
    loadCategories,
    isLoadingCategories,
    _categoriesError,
    categoriesData,
  ] = useApiGet(routes.api_v1_assessment_categories());

  const [
    loadCriteria,
    isLoadingCriteria,
    _criteriaError,
    criteriaData,
  ] = useApiGet(
    routes.api_v1_assessment_criteria({
      query: { search_id: searchId },
    }),
  );
  const [
    loadTemplates,
    isLoadingTemplates,
    _templatesError,
    assessmentTemplates,
  ] = useApiGet(routes.api_v1_assessment_templates());

  useEffect(() => {
    if (canCallApi) {
      loadCategories();
      loadCriteria();
      loadTemplates();
    }
  }, [loadCategories, loadCriteria, loadTemplates]);

  const [
    loadSavedTemplate,
    isLoadingSavedTemplate,
    _savedError,
    savedTemplateData,
  ] = useApiGet(`/api/v1/assessment_templates/${savedTemplateId}`);

  useEffect(() => {
    if (savedTemplateId) {
      loadSavedTemplate();
    }
  }, [loadSavedTemplate, savedTemplateId]);

  useEffect(() => {
    if (savedTemplateId) {
      if (location.pathname.endsWith('edit')) {
        changeToEditMode();
      } else {
        changeToViewMode();
      }
    }
  }, [
    changeToEditMode,
    changeToViewMode,
    location.pathname,
    savedTemplateId,
    template,
  ]);

  const hasSeededTemplateDataRef = useRef(!savedTemplateId);
  useEffect(() => {
    // If a saved template exists then the template state is seeded with the persisted data.
    // A ref is used to only set template data once when both criteria and template data is available.
    if (!hasSeededTemplateDataRef?.current && savedTemplateData) {
      const { assessment_template: savedTemplate } = savedTemplateData;
      const hasCategories =
        savedTemplate.categories.length === 0 &&
        savedTemplate.criteria.length === 0
          ? null
          : Boolean(savedTemplate.categories.length);
      setTemplate({
        id: savedTemplate.id,
        disabled: savedTemplate.disabled,
        hasFinishedLoading: true,
        has_categories: hasCategories,
        name: savedTemplate.name,
        display_name: savedTemplate.display_name,
        locked: savedTemplate.locked,
        description: savedTemplate.description,
        data: hasCategories
          ? savedTemplate.categories.map(category => ({
              ...category,
              criteria: category.criteria.map(criterion => criterion.id),
            }))
          : savedTemplate.criteria.map(criterion => criterion.id),
      });
      hasSeededTemplateDataRef.current = true;
    } else if (
      criteriaData &&
      !isLoadingSavedTemplate &&
      !template.hasFinishedLoading
    ) {
      setTemplate({
        ...template,
        has_categories: null,
        hasFinishedLoading: true,
      });
    }
    /**
     * Disabling exhaustive-deps because we don't want this hook to run
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteriaData, savedTemplateData, template]);

  const [createAssessmentTemplate] = useApiPost(
    searchId
      ? routes.api_v1_search_assessment_templates({
          search_id: searchId,
        })
      : routes.api_v1_assessment_templates(),
  );

  const [updateAssessmentTemplate] = useApiPatch(
    searchId
      ? routes.api_v1_search_assessment_template({
          search_id: searchId,
          id: savedTemplateId,
        })
      : routes.api_v1_assessment_template({ id: savedTemplateId }),
  );

  const isDuplicateTemplateName = templateName => {
    if (savedTemplateId) {
      return assessmentTemplates?.data
        .filter(
          assessmentTemplate =>
            assessmentTemplate.id !== parseInt(savedTemplateId),
        )
        .find(
          assessmentTemplate =>
            assessmentTemplate?.name?.toLowerCase() ===
            templateName?.trim().toLowerCase(),
        );
    }

    return assessmentTemplates?.data.find(
      assessmentTemplate =>
        assessmentTemplate?.name?.toLowerCase() ===
        templateName?.trim().toLowerCase(),
    );
  };

  const isTemplateValid = () => {
    const hasValidName =
      template.name && !isDuplicateTemplateName(template.name);

    return Boolean(searchId || hasValidName);
  };

  const getTemplateNameErrorMessage = () => {
    if (isDuplicateTemplateName(template.name)) {
      return 'The name you entered is already taken. Please enter a unique name.';
    } else if (template.has_categories !== null && !template.name) {
      return 'A template name is required';
    } else {
      return null;
    }
  };

  const getCategory = categoryId =>
    categoriesData?.categories.find(category => category.id === categoryId);
  const getCriterion = criteriaId =>
    criteriaData?.criteria.find(criterion => criterion.id === criteriaId);
  const getCriterionFromSavedTemplateData = criteriaId =>
    savedTemplateData?.assessment_template?.criteria.find(
      criterion => criterion.id === criteriaId,
    );
  const getLabelFromCategoryData = (categoryId, criteriaId) => {
    const category = getCategory(categoryId);
    const criterion = category?.criteria.find(
      childCriteria => childCriteria.id === criteriaId,
    );

    return criterion?.label;
  };

  const [
    isCategoriesModalOpen,
    openCategoriesModal,
    closeCategoriesModal,
  ] = useToggle(false);

  const removeCategoryFromTemplate = categoryId => {
    const newData = template.data.filter(
      templateCategory => templateCategory.id !== categoryId,
    );
    /**
     * We're explicitly setting has_categories to null here to give the user
     * the option of switching the assessment template to use criteria or categories
     */
    setTemplate({
      data: newData,
      has_categories: newData.length === 0 ? null : true,
    });
  };

  const addCategoryToTemplate = categoryId => {
    const newTemplateCategory = getCategory(categoryId);
    setTemplate({
      data: [
        ...template.data,
        {
          ...newTemplateCategory,
          criteria: newTemplateCategory.criteria.map(criterion => criterion.id),
        },
      ],
    });
    closeCategoriesModal();
    scrollToElement({
      element: document.getElementById(categoryId),
    });
  };

  const goBackWithSuccessToast = () => {
    const redirectUrl = isTalentPool
      ? `/talent_pools/${searchId}/edit?editTalentPoolSuccess=true#assessments`
      : `/searches/${searchId}/edit?editSearchSuccess=true#assessments`;

    window.location.href = redirectUrl;
  };

  const goBack = () => {
    window.location.href = searchId
      ? `/${
          isTalentPool ? 'talent_pools' : 'searches'
        }/${searchId}/edit#assessments`
      : '/admin/assessment_templatev2s';
  };

  const addCriterionToTemplateCategory = (criterionId, categoryId) => {
    const categoryIndex = template.data.findIndex(
      category => category.id === categoryId,
    );
    // spread template.data to create a copy and allow us to use splice non-mutably
    const newCategories = [...template.data];
    newCategories.splice(categoryIndex, 1, {
      ...template.data[categoryIndex],
      criteria: [
        ...(template.data[categoryIndex]?.criteria ?? []),
        criterionId,
      ],
    });
    setTemplate({ data: newCategories });
  };

  const removeCriterionFromCategory = (criterionId, categoryId) => {
    const categoryIndex = template.data.findIndex(
      category => category.id === categoryId,
    );
    const newCategory = {
      ...template.data[categoryIndex],
      criteria: [...template.data[categoryIndex].criteria].filter(
        criteriaId => criteriaId !== criterionId,
      ),
    };
    // spread template.data to create a copy and allow us to use splice non-mutably
    const newCategories = [...template.data];
    newCategories.splice(categoryIndex, 1, newCategory);
    setTemplate({ data: newCategories });
  };

  const addCriterionToTemplateCategoryAndRemoveGlobal = ({
    categoryId,
    criteriaIdToAdd,
    criteriaIdToRemove,
  }) => {
    const categoryIndex = template.data.findIndex(
      category => category.id === categoryId,
    );

    const criteriaIndex = template.data[categoryIndex].criteria.findIndex(
      criteria => criteria === criteriaIdToRemove,
    );

    const newCriteria = [...template.data[categoryIndex].criteria];

    // replace id of criteria being removed with id of criteria being added to maintain order
    newCriteria.splice(criteriaIndex, 1, criteriaIdToAdd);
    const newCategory = {
      ...template.data[categoryIndex],
      criteria: newCriteria,
    };
    // spread template.data to create a copy and allow us to use splice non-mutably
    const newCategories = [...template.data];
    newCategories.splice(categoryIndex, 1, newCategory);
    setTemplate({ data: newCategories });
  };

  const templateCategoryIds = template.data.map(category => category.id);

  const categoriesOptions = categoriesData?.categories
    .filter(option => !templateCategoryIds.includes(option.id))
    .map(({ id: categoryId, name }) => ({
      label: name,
      value: categoryId,
    }));

  const [
    isDiscardChangesModalOpen,
    openDiscardChangesModal,
    closeDiscardChangesModal,
  ] = useToggle(false);

  const discardChangesAndGoBack = () => {
    closeDiscardChangesModal();
    goBack();
  };

  const saveTemplateAndExit = () => {
    if (isTemplateValid()) {
      const {
        hasFinishedLoading: _hasFinishedLoading,
        name,
        ...restOfTemplate
      } = template;
      if (savedTemplateId) {
        updateAssessmentTemplate(
          {
            assessment_template: {
              ...restOfTemplate,
              name: name?.trim() ?? null,
              search_id: searchId,
            },
          },
          {
            onSuccess: searchId ? goBackWithSuccessToast : goBack,
          },
        );
      } else {
        createAssessmentTemplate(
          {
            assessment_template: {
              ...restOfTemplate,
              name: name?.trim() ?? null,
              search_id: searchId,
            },
          },
          {
            onSuccess: searchId ? goBackWithSuccessToast : goBack,
          },
        );
      }
    }
  };

  const getPageTitle = () => {
    if (savedTemplateId) {
      if (isInEditMode) {
        return `Edit ${template.display_name || ''}`;
      } else {
        return `View ${template.display_name || ''}`;
      }
    } else {
      return 'Assessment Template Builder';
    }
  };

  const [criterionBeingEdited, setCriterionBeingEdited] = useState(
    EDIT_CRITERIA_BASE_STATE,
  );

  const isConvertingGlobalToCustomCriteria =
    searchId &&
    criterionBeingEdited.criteriaId > 0 &&
    !getCriterion(criterionBeingEdited.criteriaId)?.search_id;

  const customCriteriaButtonAndModalLabel = searchId
    ? 'Create Custom Criteria'
    : 'Create New Criteria';

  /**
   * Anyone can edit a criteria BEFORE the assessment has been used to assess a candidate
   *    i.e. when savedTemplateData.assessment_template.locked === false
   *
   * AFTER an assessment has been used to assess a candidate, only the following may
   * edit a criteria
   *    (Exec) Partner
   *    (Exec) Recruiter
   *    (VC/PE) Lead
   *    (VC/PE) Employee
   *
   * The isEmployeeUser selector returns true for each of these
   */
  const isEmployee = useSelector(isEmployeeUser);
  const canEditInUseCriteria =
    !savedTemplateData?.assessment_template?.locked || isEmployee;

  return (
    <>
      <FullPageTakeoverHeader
        actionButtons={
          <>
            {isInEditMode ? (
              <Tooltip
                content={
                  isTemplateValid() ? null : 'Template needs a valid name.'
                }
                position='bottomLeft'
                size='large'
              >
                <ButtonPrimary
                  isDisabled={!isTemplateValid()}
                  label='Save'
                  onClick={saveTemplateAndExit}
                  size='small'
                />
              </Tooltip>
            ) : null}
            {!isInEditMode && !isLoadingSavedTemplate ? (
              <ButtonPrimary
                label='Edit'
                size='small'
                url={
                  searchId
                    ? `/${
                        isTalentPool ? 'talent_pools' : 'searches'
                      }/${searchId}/assessment_templates/${savedTemplateId}/edit`
                    : `/assessment_templates/${savedTemplateId}/edit`
                }
              />
            ) : null}
          </>
        }
        onBackClick={isInEditMode ? openDiscardChangesModal : goBack}
        title={getPageTitle()}
      />
      <ConfirmationDialog
        cancelLabel='No, Keep Editing'
        confirmLabel='Yes, Discard Changes'
        isOpen={isDiscardChangesModalOpen}
        onClose={closeDiscardChangesModal}
        onConfirm={discardChangesAndGoBack}
        title='Discard Changes?'
      >
        <p className='u-textAlign-c'>
          If you leave this page without saving, all unsaved changes will be
          lost. <b>This action cannot be undone.</b>
        </p>
        <p className='u-textAlign-c'>
          Are you sure you want to discard changes?
        </p>
      </ConfirmationDialog>
      <div className='container u-paddingBottom-24'>
        {searchId ? null : (
          <LoadingContainer
            isLoading={isLoadingTemplates || isLoadingSavedTemplate}
          >
            <h2 className='u-marginTop-32'>Template Details</h2>
            <Card className='u-marginBottom-32' isCentered={false}>
              {isInEditMode ? (
                <TextInput
                  className='u-marginTop-12'
                  errorMessage={getTemplateNameErrorMessage()}
                  inputWidth='full'
                  isDisabled={isLoadingTemplates || isLoadingSavedTemplate}
                  label='* Name'
                  onChange={newName => setTemplate({ name: newName })}
                  value={template.name}
                />
              ) : (
                <div>
                  <h4>Name</h4>
                  <p>{template.name}</p>
                </div>
              )}
              {isInEditMode ? (
                <TextArea
                  className='u-marginTop-12'
                  isDisabled={isLoadingSavedTemplate}
                  label='Description'
                  onChange={newDescription =>
                    setTemplate({ description: newDescription })
                  }
                  value={template.description}
                  width='full'
                />
              ) : (
                <div>
                  <h4>Description</h4>
                  <p>{template.description}</p>
                </div>
              )}
              {isInEditMode ? (
                <div className='u-flex u-flexAlign-c'>
                  <Checkbox
                    isChecked={template.disabled}
                    label='Disabled'
                    onChange={newValue => setTemplate({ disabled: newValue })}
                  />
                  <span className='u-fontSize-small'>
                    (When a template is disabled, it cannot be used on any new
                    searches. Any searches that are using this template will not
                    be impacted.)
                  </span>
                </div>
              ) : null}
            </Card>
          </LoadingContainer>
        )}
        {/* When user has not chosen whether to use categories */}
        {template.hasFinishedLoading && template.has_categories === null ? (
          <Card className='u-marginVertical-32 '>
            <div className='u-textAlign-c'>
              <img
                alt=''
                className='u-marginVertical-25'
                src='/card_screen.svg'
              />
              {isInEditMode ? (
                <>
                  <h2>How would you like to structure this template?</h2>
                  <div className='u-flex u-flexJustify-c u-margin-24'>
                    <div className='u-padding-32 u-textAlign-c u-borderRight'>
                      <ButtonPrimary
                        className='u-noWrap'
                        isDisabled={!categoriesData?.categories?.length}
                        isLoading={isLoadingCategories || !categoriesData}
                        label='Use Categories'
                        onClick={() => setTemplate({ has_categories: true })}
                      />
                      <p className='u-marginVertical-16'>
                        Organize your criteria into groups
                        <br /> by theme, focus area etc.
                      </p>
                    </div>
                    <div className='u-padding-32 u-textAlign-c'>
                      <ButtonSecondary
                        className='u-noWrap'
                        isDisabled={isLoadingCriteria || !criteriaData}
                        isLoading={isLoadingCriteria || !criteriaData}
                        label='Use Criteria Only'
                        onClick={() => setTemplate({ has_categories: false })}
                      />
                      <p className='u-marginVertical-16'>
                        Present your criteria in a single list
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h2>
                    This assessment does not have any categories or criteria.
                  </h2>
                  <p>
                    All assessments have a Summary Notes field you can provide
                    feedback in.
                  </p>
                  <p>
                    If you prefer to add categories or criteria, click the Edit
                    button.
                  </p>
                </>
              )}
            </div>
          </Card>
        ) : null}
        {/* When building an assessment template with only criteria */}
        {template.has_categories === false ? (
          <div>
            <h2>Assessment</h2>
            {template.data.length ? (
              template.data.map((criterionId, criterionIndex) => {
                let criterion;
                if (isInEditMode) {
                  criterion = getCriterion(criterionId);
                } else {
                  criterion = getCriterionFromSavedTemplateData(criterionId);
                }
                return (
                  <AssessmentCriteriaCard
                    className='u-marginVertical-16'
                    criteriaName={criterion?.name}
                    dependentAssessmentsCount={
                      criterion?.dependent_assessments_count
                    }
                    description={criterion?.description}
                    isCriteriaLocked={criterion?.locked}
                    key={criterionId}
                    label={criterion?.label}
                    notes={criterion?.notes}
                    onEdit={
                      isInEditMode && canEditInUseCriteria
                        ? () =>
                            setCriterionBeingEdited({
                              ...criterionBeingEdited,
                              criteriaId: criterion?.id,
                            })
                        : null
                    }
                    onRemove={
                      isInEditMode
                        ? () => {
                            const newData = [...template.data];
                            newData.splice(criterionIndex, 1);
                            /**
                             * We're explicitly setting has_categories to null here to give the user
                             * the option of switching the assessment template to use criteria or categories
                             */
                            setTemplate({
                              data: newData,
                              has_categories:
                                newData.length === 0 ? null : false,
                            });
                          }
                        : null
                    }
                    ratingDefinitions={criterion?.rating_definitions}
                  />
                );
              })
            ) : (
              <Card className='u-marginVertical-32' isCentered={false}>
                {/*  Manually set the border style and radius  to dotted style and $gray-30 #c0c4d3  */}
                <div
                  className='u-textAlign-c u-padding-1'
                  style={{ border: '1px dashed #c0c4d3', borderRadius: '4px' }}
                >
                  <h2>Add Criteria</h2>
                  <p>
                    Build your assessment by adding criteria.
                    <br />
                    Start by clicking the buttons below to{' '}
                    <b className='u-textColor-gray60'>
                      Create New Criteria
                    </b> or{' '}
                    <b className='u-textColor-gray60'>Add Existing Criteria</b>.
                  </p>
                </div>
              </Card>
            )}
            {isInEditMode ? (
              <div className='u-textAlign-r u-marginVertical-24 u-noWrap'>
                <ButtonSecondary
                  icon='add'
                  label={customCriteriaButtonAndModalLabel}
                  onClick={() =>
                    setCriterionBeingEdited({
                      ...criterionBeingEdited,
                      criteriaId: UNSAVED_CRITERIA_ID,
                    })
                  }
                />
                <CustomCriteriaModal
                  closeModal={() =>
                    setCriterionBeingEdited(EDIT_CRITERIA_BASE_STATE)
                  }
                  criteriaDataToEdit={
                    criterionBeingEdited.criteriaId
                      ? getCriterion(criterionBeingEdited.criteriaId)
                      : null
                  }
                  existingCriteria={criteriaData?.criteria}
                  isOpen={Boolean(criterionBeingEdited.criteriaId)}
                  isTalentPool={isTalentPool}
                  modalTitle={customCriteriaButtonAndModalLabel}
                  onAdd={newCriterionResponse => {
                    loadCriteria(null, {
                      onSuccess: () => {
                        const newId =
                          newCriterionResponse.assessment_criterium.id;
                        const criterionIndex = template.data.findIndex(
                          criteria =>
                            criteria === criterionBeingEdited.criteriaId,
                        );
                        if (isConvertingGlobalToCustomCriteria) {
                          const newData = [...template.data];
                          newData.splice(criterionIndex, 1, newId);
                          setTemplate({ data: newData });
                        } else if (newId !== criterionBeingEdited.criteriaId) {
                          setTemplate({ data: [...template.data, newId] });
                        }
                      },
                    });
                  }}
                  searchId={searchId}
                />
                <AddExistingCriteriaButton
                  criteria={criteriaData?.criteria?.filter(
                    option => !template.data.includes(option.id),
                  )}
                  onAdd={criteriaId => {
                    setTemplate({ data: [...template.data, criteriaId] });
                  }}
                />
              </div>
            ) : null}
          </div>
        ) : null}

        {/* When building an assessment template with categories */}
        {template.has_categories === true ? (
          <>
            <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween'>
              <h2>Assessment</h2>
              {isInEditMode ? (
                <ButtonSecondary
                  icon='add'
                  label='Add Category'
                  onClick={openCategoriesModal}
                />
              ) : null}
            </div>

            {/* When no categories have been added then show now data state */}
            {template.data.length === 0 ? (
              // Manually set background color to $brand-dark-blue: #2b2b4f;
              <Card
                className='u-marginVertical-32'
                isCentered={false}
                style={{ backgroundColor: '#2b2b4f' }}
              >
                {/*  Manually set the border style and radius  to dotted style and $white #ffffff  */}
                <div
                  className='u-textAlign-c u-padding-1'
                  style={{ border: '1px dashed #ffffff', borderRadius: '4px' }}
                >
                  <h2 className='u-textColor-white'>Add Your First Category</h2>
                  <p className='u-textColor-white'>
                    Use Categories to organize your criteria by theme, focus
                    area etc.
                    <br />
                    Click <b>“Add Category”</b> above to get started.
                  </p>
                </div>
              </Card>
            ) : null}
            {template.data.length
              ? template.data.map(
                  (templateCategory, _templateCategoryIndex) => (
                    <Drawer
                      actionButton={
                        isInEditMode ? (
                          <AssessmentCategoryDrawerAction
                            dependentAssessmentsCount={
                              templateCategory?.dependent_assessments_count
                            }
                            id={templateCategory.id}
                            isLocked={templateCategory.locked}
                            name={templateCategory.name}
                            onRemove={categoryId =>
                              removeCategoryFromTemplate(categoryId)
                            }
                          />
                        ) : null
                      }
                      className='u-marginVertical-12'
                      id={templateCategory.id}
                      key={templateCategory.id}
                      label={templateCategory.name}
                    >
                      {templateCategory.criteria.map(criterionId => {
                        let criterion;
                        if (isInEditMode) {
                          criterion = criteriaData?.criteria.find(
                            item => item.id === criterionId,
                          );
                        } else {
                          savedTemplateData?.assessment_template?.categories.some(
                            category => {
                              criterion = category?.criteria.find(
                                item => item.id === criterionId,
                              );
                              return Boolean(criterion);
                            },
                          );
                        }
                        return (
                          <AssessmentCriteriaCard
                            className='u-marginVertical-16'
                            criteriaName={criterion?.name}
                            dependentAssessmentsCount={
                              criterion?.dependent_assessments_count
                            }
                            description={criterion?.description}
                            isCriteriaLocked={criterion?.locked}
                            key={criterionId}
                            label={
                              getLabelFromCategoryData(
                                templateCategory.id,
                                criterion?.id,
                              ) || criterion?.label
                            }
                            notes={criterion?.notes}
                            onEdit={
                              isInEditMode && canEditInUseCriteria
                                ? () => {
                                    setCriterionBeingEdited({
                                      categoryId: templateCategory.id,
                                      criteriaId: criterion?.id,
                                    });
                                  }
                                : null
                            }
                            onRemove={
                              isInEditMode
                                ? () => {
                                    removeCriterionFromCategory(
                                      criterionId,
                                      templateCategory.id,
                                    );
                                  }
                                : null
                            }
                            ratingDefinitions={criterion?.rating_definitions}
                          />
                        );
                      })}

                      {templateCategory.criteria.length === 0 ? (
                        <Card
                          className='u-marginVertical-32'
                          isCentered={false}
                        >
                          {/*  Manually set the border style and radius  to dotted style and $gray-30 #c0c4d3  */}
                          <div
                            className='u-textAlign-c u-padding-1'
                            style={{
                              border: '1px dashed #c0c4d3',
                              borderRadius: '4px',
                            }}
                          >
                            <h2>Add Criteria</h2>
                            <p>
                              Build your assessment by adding criteria.
                              <br />
                              Start by clicking the buttons below to{' '}
                              <b className='u-textColor-gray60'>
                                Create New Criteria
                              </b>{' '}
                              or{' '}
                              <b className='u-textColor-gray60'>
                                Add Existing Criteria
                              </b>
                              .
                            </p>
                          </div>
                        </Card>
                      ) : null}

                      {isInEditMode ? (
                        <div className='u-textAlign-r u-marginVertical-24 u-noWrap'>
                          {/* setting criteriaId to -1 because we're creating a new custom criteria & do not have an id yet */}
                          <ButtonSecondary
                            icon='add'
                            label={customCriteriaButtonAndModalLabel}
                            onClick={() =>
                              setCriterionBeingEdited({
                                criteriaId: UNSAVED_CRITERIA_ID,
                                categoryId: templateCategory.id,
                              })
                            }
                          />
                          <AddExistingCriteriaButton
                            // Prevent criteria duplicates in the same category
                            criteria={criteriaData?.criteria?.filter(
                              option =>
                                !templateCategory.criteria.includes(option.id),
                            )}
                            onAdd={criterionId => {
                              addCriterionToTemplateCategory(
                                criterionId,
                                templateCategory.id,
                              );
                            }}
                          />
                        </div>
                      ) : null}
                    </Drawer>
                  ),
                )
              : null}
            <CustomCriteriaModal
              categoryId={criterionBeingEdited.categoryId}
              closeModal={() =>
                setCriterionBeingEdited(EDIT_CRITERIA_BASE_STATE)
              }
              criteriaDataToEdit={
                criterionBeingEdited.criteriaId
                  ? getCriterion(criterionBeingEdited.criteriaId)
                  : null
              }
              existingCriteria={criteriaData?.criteria}
              isOpen={Boolean(criterionBeingEdited.criteriaId)}
              isTalentPool={isTalentPool}
              modalTitle={customCriteriaButtonAndModalLabel}
              onAdd={newCriterionResponse => {
                loadCriteria(null, {
                  onSuccess: () => {
                    const newCriterionId =
                      newCriterionResponse.assessment_criterium.id;
                    if (isConvertingGlobalToCustomCriteria) {
                      addCriterionToTemplateCategoryAndRemoveGlobal({
                        criteriaIdToAdd: newCriterionId,
                        criteriaIdToRemove: criterionBeingEdited.criteriaId,
                        categoryId: criterionBeingEdited.categoryId,
                      });
                    } else if (criterionBeingEdited.criteriaId < 0) {
                      addCriterionToTemplateCategory(
                        newCriterionId,
                        criterionBeingEdited.categoryId,
                      );
                    }
                  },
                });
              }}
              searchId={searchId}
            />

            {isCategoriesModalOpen ? (
              <Modal
                isOpen={true}
                onClose={closeCategoriesModal}
                size='small'
                title='Add Category'
              >
                <Form
                  onSubmit={formState => {
                    addCategoryToTemplate(formState.categoryId);
                  }}
                >
                  <Modal.Body className='ModalBody--noClip'>
                    <Form.AdvancedSelect
                      label='Select Category'
                      name='categoryId'
                      options={categoriesOptions}
                      placeholder='Select from existing categories'
                      rules={{
                        required: {
                          value: true,
                        },
                      }}
                      width='full'
                    />

                    <div className='u-paddingVertical-64'>
                      {/* 
                        * This div is here to make sure the modal's height is
                        big enough to clear the autocomplete's options list
                       */}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonPrimary
                      isOutline={true}
                      label='Cancel'
                      onClick={closeCategoriesModal}
                    />
                    <Form.SubmitButton label='Add' />
                  </Modal.Footer>
                </Form>
              </Modal>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
};

AssessmentBuilder.defaultProps = {
  isTalentPool: false,
};

AssessmentBuilder.propTypes = {
  isTalentPool: PropTypes.bool,
};

export default AssessmentBuilder;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, mapProps, setDisplayName } from 'recompose';
import withCandidacyPerStageStats from './withCandidacyPerStageStats';
import CandidaciesPerStageListItem from './CandidaciesPerStageListItem';

/**
 * Renders a list of stages and the number of candidates in each stage (as both an absolute
 * value and a percentage).
 */
const CandidaciesPerStageList = ({
  rejectionReasons,
  stageCounts,
  stages,
  totalCandidates,
}) => (
  <div className='CandidaciesPerStageList'>
    <ul className='line-item-list'>
      {stages &&
        stages.map(stage => (
          <CandidaciesPerStageListItem
            count={(stageCounts && stageCounts.get(`${stage.get('id')}`)) || 0}
            key={stage.get('id')}
            rejectionReasons={rejectionReasons}
            stage={stage}
            totalCount={totalCandidates}
          />
        ))}
    </ul>
  </div>
);

CandidaciesPerStageList.propTypes = {
  /**
   * The summary of rejection reasons for candicaces in the rejected stage.
   */
  rejectionReasons: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      reason: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }).isRequired,
  ),

  /**
   * A map whose keys are stage names and whose values are the number of candidates in that stage.
   */
  stageCounts: ImmutablePropTypes.mapOf(
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ),

  /**
   * The list of all stages.
   */
  stages: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),

  /**
   * The total number of candidates in all stages given.
   */
  totalCandidates: PropTypes.number,
};

export default compose(
  setDisplayName('CandidaciesPerStageList(enhanced)'),
  withCandidacyPerStageStats,
  mapProps(
    ({
      activeOnly,
      activeStages,
      rejectionReasons,
      stageCounts,
      stages,
      totalActiveCandidates,
      totalCandidates,
    }) => ({
      rejectionReasons: rejectionReasons,
      stageCounts: stageCounts,
      stages: activeOnly ? activeStages : stages,
      totalCandidates: activeOnly ? totalActiveCandidates : totalCandidates,
    }),
  ),
)(CandidaciesPerStageList);

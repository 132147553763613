import createEntity from 'modules/entities/actions/createEntity';
import routes from 'modules/routing/routes';
import emailTemplateSchema from '../schema';

/**
 * An action for creating an email template.
 * @param {Object} payload
 * @param {Object} payload.emailTemplate The template record to create
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ emailTemplate, transactionId }) =>
  createEntity({
    body: { email_template: emailTemplate },
    transactionId: transactionId,
    entityType: emailTemplateSchema.key,
    responseSchema: { email_template: emailTemplateSchema },
    url: routes.api_v1_email_templates(),
  });

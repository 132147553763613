import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import routes from 'modules/routing/routes';
import { useApiGet, useApiDelete } from '@thrivetrm/ui/hooks/useApi';
import Card from '@thrivetrm/ui/components/Card';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Date from '@thrivetrm/ui/components/Date';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import { useSelector } from 'react-redux';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_TALENT_POOL } from 'modules/searches/constants';

const SearchAssessmentTemplateCard = ({
  activeSearchAssessmentId,
  searchId,
}) => {
  const searchType = useSelector(state => getSearchType(state, searchId));
  const isTalentPool = searchType === TYPE_TALENT_POOL;
  const [
    isEditTemplateDialogOpen,
    openEditTemplateDialog,
    closeEditTemplateDialog,
  ] = useToggle(false);

  const [
    loadAssessmentTemplateData,
    isLoadingAssessmentTemplateData,
    assessmentTemplateDataLoadError,
    assessmentTemplateData,
  ] = useApiGet(
    routes.api_v1_search_assessment_template({
      id: activeSearchAssessmentId,
      searchId: searchId,
    }),
  );

  const [deleteAssessmentTemplate] = useApiDelete(
    routes.api_v1_search_assessment_template({
      searchId: searchId,
      id: activeSearchAssessmentId,
    }),
  );

  useEffect(() => {
    if (activeSearchAssessmentId) {
      loadAssessmentTemplateData();
    }
  }, [activeSearchAssessmentId, loadAssessmentTemplateData]);

  const handleDelete = () =>
    deleteAssessmentTemplate(null, {
      // we need to reload the page to update the slim template
      // as AssessmentTemplate component is being rendered by the slim template
      onSuccess: () => {
        const redirectUrl = isTalentPool
          ? `/talent_pools/${searchId}/edit?editTalentPoolSuccess=true#assessments`
          : `/searches/${searchId}/edit?editSearchSuccess=true#assessments`;

        window.location.href = redirectUrl;
      },
    });

  const assessmentTemplate = assessmentTemplateData?.assessment_template;
  const editTemplateUrl = `/${
    isTalentPool ? 'talent_pools' : 'searches'
  }/${searchId}/assessment_templates/${assessmentTemplate?.id}/edit`;

  const handleEdit = () => {
    if (assessmentTemplate?.show_edit_template_modal) {
      openEditTemplateDialog();
    } else {
      window.location.href = editTemplateUrl;
    }
  };

  return (
    <LoadingContainer
      isLoading={isLoadingAssessmentTemplateData}
      minHeight='32vh'
    >
      {!isLoadingAssessmentTemplateData &&
      !assessmentTemplateDataLoadError &&
      assessmentTemplate ? (
        <>
          <p>
            The following template has been saved for this{' '}
            {isTalentPool ? 'talent pool' : 'search'}:
          </p>
          <Card data-testid='assessment-template-card' isCentered={false}>
            <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c u-borderBottom u-paddingBottom-12'>
              <h3 className='u-margin-n' data-testid='assessment-template-name'>
                {assessmentTemplate.display_name}
              </h3>
              <Tooltip
                content={
                  assessmentTemplate.locked
                    ? 'This template cannot be removed because it has already been used for an assessment'
                    : null
                }
                position='topLeft'
                size='large'
              >
                <ButtonSecondary
                  data-testid='assessment-template-remove'
                  icon='close'
                  isDisabled={assessmentTemplate.locked}
                  onClick={handleDelete}
                />
              </Tooltip>
            </div>
            {assessmentTemplate.description ? (
              <>
                <h4 className='u-marginBottom-4'>Description</h4>
                <p
                  className='u-borderBottom u-paddingBottom-12 u-marginTop-n'
                  data-testid='assessment-template-description'
                >
                  {assessmentTemplate.description}
                </p>
              </>
            ) : null}
            <div className='u-borderBottom u-paddingBottom-12 u-marginTop-12'>
              {assessmentTemplate.created_by ? (
                <p
                  className='u-marginBottom-n'
                  data-testid='assessment-template-added-by'
                >
                  Added by <strong>{assessmentTemplate.created_by}</strong> on{' '}
                  <Date date={assessmentTemplate.created_at} />
                </p>
              ) : null}
              {assessmentTemplate.updated_by ? (
                <p
                  className='u-marginBottom-n'
                  data-testid='assessment-template-updated-by'
                >
                  Updated by <strong>{assessmentTemplate.updated_by}</strong> on{' '}
                  <Date date={assessmentTemplate.updated_at} />
                </p>
              ) : null}
            </div>
            <div className='u-flex u-flexJustify-r u-marginTop-12'>
              <ButtonPrimary
                isOutline={true}
                label='Preview'
                size='small'
                url={`/${
                  isTalentPool ? 'talent_pools' : 'searches'
                }/${searchId}/assessment_templates/${assessmentTemplate.id}`}
              />
              <ButtonPrimary
                className='u-marginLeft-16'
                label='Edit'
                onClick={handleEdit}
                size='small'
              />
            </div>
          </Card>
          <ConfirmationDialog
            cancelLabel='No, Keep Original Template'
            confirmLabel='Yes, Edit Template'
            isOpen={isEditTemplateDialogOpen}
            onClose={closeEditTemplateDialog}
            onConfirm={() => {
              window.location.href = editTemplateUrl;
            }}
            title='Edit Template?'
          >
            Editing this existing template will create a custom template which
            will save only for this search.
          </ConfirmationDialog>
        </>
      ) : null}
      {assessmentTemplateDataLoadError && !isLoadingAssessmentTemplateData ? (
        <Card className='u-marginTop-12' type='error'>
          {assessmentTemplateDataLoadError}
        </Card>
      ) : null}
    </LoadingContainer>
  );
};

SearchAssessmentTemplateCard.propTypes = {
  activeSearchAssessmentId: PropTypes.number.isRequired,
  searchId: PropTypes.number.isRequired,
};

export default SearchAssessmentTemplateCard;

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import TimeLabel from 'modules/datetime/components/TimeLabel';
import isClientUser from 'modules/auth/selectors/isClientUser';
import isClientNoCompUser from 'modules/auth/selectors/isClientNoCompUser';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';

import CandidacyStatusContainer from './CandidacyStatusContainer';
import SearchCandidacyLink from '../SearchCandidacyLink';

/**
 * The generic status implementation, renders the description
 * provided by the status and the time that status was
 * created.
 */
const CandidacyStatusDefault = ({ candidacyId, candidacyStatus }) => {
  const hasCandidateMetadataFeature = useFeatureCheck(
    'feature.display_candidate_metadata',
  );

  const canViewOutreachInfo = useSelector(
    state =>
      !isClientUser(state) &&
      !isClientNoCompUser(state) &&
      hasCandidateMetadataFeature,
  );

  const canViewAddedBy = description => {
    const containsAddedTo = inputString => {
      const regexPattern = /added to/i;
      return regexPattern.test(inputString);
    };

    if (!canViewOutreachInfo && containsAddedTo(description)) {
      return false;
    }

    return true;
  };

  return (
    <CandidacyStatusContainer className='CandidacyStatusDefault'>
      {canViewAddedBy(candidacyStatus.get('description')) ? (
        <SearchCandidacyLink candidacyId={candidacyId} tab='overview'>
          <span>{candidacyStatus.get('description')} </span>
          <TimeLabel time={candidacyStatus.get('created_at')} />
        </SearchCandidacyLink>
      ) : null}
    </CandidacyStatusContainer>
  );
};

CandidacyStatusDefault.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  candidacyStatus: ImmutablePropTypes.mapContains({
    created_at: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};

export default CandidacyStatusDefault;

import { USER_STATE_KEY } from '../constants';

/**
 * Determines if the current user is an admin user.
 * @param {*} state
 * @returns {Boolean} true if the current user is an admin; otherwise, false
 */
const isAdmin = state => state[USER_STATE_KEY].get('admin', false);

export default isAdmin;

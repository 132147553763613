import PropTypes from 'prop-types';
import React from 'react';
import SearchSidebarLink from 'modules/searches/components/SearchSidebarLink';

/**
 * Renders the "section title" for each  content section in the search sidebar.
 * Each title, when clicked, toggles a particular popover state.
 */
const SidebarSearchContentSectionTitle = ({
  popover,
  searchId,
  searchPage,
  title,
}) => (
  <h3
    className='SidebarSearchContentSectionTitle'
    data-testid='sidebar search content section title'
  >
    <SearchSidebarLink
      isToggle={true}
      popover={popover}
      searchId={searchId}
      searchPage={searchPage}
    >
      <span className='SidebarSearchContentSectionTitle__label'>{title}</span>{' '}
      <i className='fa fa-chevron-circle-right' />
    </SearchSidebarLink>
  </h3>
);

SidebarSearchContentSectionTitle.propTypes = {
  popover: PropTypes.string.isRequired,
  searchId: PropTypes.number.isRequired,
  searchPage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SidebarSearchContentSectionTitle;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';

import DatasetView from 'modules/core/componentsLegacy/DatasetView';

import { SEARCH_TYPES, TYPE_PIPELINE } from 'modules/searches/constants';
import canViewNotesSelector from '../selectors/canViewNotes';
import withNoteListFetched from './withNoteListFetched';
import mapNoteIdsToNotes from './mapNoteIdsToNotes';
import NoteList from './NoteList';

const RecentNotes = ({
  hideIfEmpty,
  limit,
  noteList,
  notes,
  readOnly,
  searchType,
  title,
}) => {
  const pinnedNoteId = notes?.toJSON().find(note => note.pinned)?.id;
  /**
   * Default text display when no search note exists
   */
  const emptyStateMessage =
    searchType === TYPE_PIPELINE
      ? 'No Notes have been added'
      : 'No Search Notes have been added';

  return hideIfEmpty ? null : (
    <div className='notes--recent-notes' data-testid='recent notes'>
      {title}
      <DatasetView
        emptyState={
          notes && notes.count() === 0
            ? {
                message: <i>{emptyStateMessage}</i>,
              }
            : false
        }
        hasData={Boolean(notes && notes.count())}
        loadingIndicator={noteList && noteList.getIn(['_meta', 'isFetching'])}
      >
        {notes && (
          <NoteList
            limit={limit}
            notes={notes}
            pinnedNoteId={pinnedNoteId}
            readOnly={readOnly}
            renderRelatedSearches={false}
          />
        )}
      </DatasetView>
    </div>
  );
};

RecentNotes.propTypes = {
  hideIfEmpty: PropTypes.bool,

  limit: PropTypes.number,

  noteList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  notes: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),
  ),

  readOnly: PropTypes.bool,
  searchType: PropTypes.oneOf(SEARCH_TYPES),
  /**
   * An optional title to render.
   */
  title: PropTypes.node,
};

RecentNotes.defaultProps = {
  hideIfEmpty: false,
  limit: 3,
  readOnly: false,
};

export default compose(
  setDisplayName('RecentNotes(enhanced)'),

  // The connected version of this component requires a parentId and parentType,
  // everything else will be fetched from state.
  setPropTypes({
    checkPermissions: PropTypes.bool.isRequired,
    parentId: PropTypes.number,
    parentType: PropTypes.string.isRequired,
  }),

  defaultProps({
    checkPermissions: true,
  }),

  connect(
    (state, { checkPermissions }) => ({
      canViewNotes: !checkPermissions || canViewNotesSelector(state),
    }),
    {},
  ),

  // Bail if the user doesn't have permission.
  branch(({ canViewNotes }) => !canViewNotes, renderNothing),

  // If the notes for the candidacy have not been loaded or are stale,
  // fetch them.
  withNoteListFetched,

  // Pull off the noteIds as a prop so they can be mapped by mapNoteIdsToNotes
  withProps(({ fetchNoteList, noteList }) => ({
    noteIds: noteList && noteList.get('ids'),
    handlRefreshNotes: fetchNoteList,
  })),

  mapNoteIdsToNotes,
)(RecentNotes);

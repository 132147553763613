import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import withSearchCandidacyHref from 'modules/candidacies/components/withSearchCandidacyHref';
import isPathActive from 'modules/routing/selectors/isPathActive';
import Link from 'modules/routing/components/Link';

/**
 * Renders a link to search candidacy (opening an optional tab), but wrapped in a <li>
 * which will get an `active` class when that link is active.
 */
const CandidacyListItemLink = ({ children, isActive, ...props }) => (
  <li className={classnames({ active: isActive })} role='presentation'>
    <Link {...props}>{children}</Link>
  </li>
);

CandidacyListItemLink.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
};

CandidacyListItemLink.defaultProps = {
  isActive: false,
};

/**
 * Renders a link, optionally to a specific tab, on the current search page.
 */
export default compose(
  withSearchCandidacyHref,
  connect(
    (state, { href }) => ({
      isActive: isPathActive(state, href),
    }),
    {},
  ),
  pure,
)(CandidacyListItemLink);

import getPrimaryCompanyName from 'modules/contacts/selectors/contacts/getPrimaryCompanyName';
import getTalentPoolId from 'modules/contacts/selectors/contacts/getTalentPoolId';
import getCompanyName from 'modules/companies/selectors/getCompanyName';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getClientCompanyId from '../selectors/getClientCompanyId';

const { toSortableString } = stringUtils;

export default state => contactId => {
  const talentPoolId = getTalentPoolId(state, contactId);

  if (talentPoolId) {
    const companyId = getClientCompanyId(state, talentPoolId);
    return companyId ? toSortableString(getCompanyName(state, companyId)) : '';
  }

  return toSortableString(getPrimaryCompanyName(state, contactId));
};

import shouldFetchEntity from 'modules/entities/selectors/shouldFetchEntity';
import addressSchema from '../addressSchema';

/**
 * Determines if an address should be fetched from the server.
 * @param {Object} state
 * @param {Number} addressId The ID of the address
 * @return {Boolean} True if the address needs to be fetched from the server; otherwise,
 *   false
 */
export default (state, addressId) =>
  shouldFetchEntity(state, addressSchema.key, addressId);

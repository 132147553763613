import getEntity from 'modules/entities/selectors/getEntity';
import taskSchema from '../schema';

/**
 * Gets a task record by it's ID
 * @param {*} state
 * @param {Number} taskId The ID of the task.
 * @return {Map} The task record
 */
export default (state, taskId) => getEntity(state, taskSchema.key, taskId);

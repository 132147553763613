import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

/**
 * Renders an indicator "icon" (the letter "a" in brackets) that is used to indicate that
 * a candidacy record was created from a job application.
 */
const CandidacyJobApplicationIndicator = () => (
  <div className='CandidacyJobApplicationIndicator'>
    <OverlayTrigger
      overlay={
        <Tooltip id={uniqueId()}>
          <span>Candidate sourced from ThriveApply</span>
        </Tooltip>
      }
      placement='top'
    >
      <span>[a]</span>
    </OverlayTrigger>
  </div>
);

export default CandidacyJobApplicationIndicator;

import { compose, setDisplayName, withProps } from 'recompose';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

/**
 * A specialized loading indicator just for the job application list.
 * Enahnaces the standard LoadingIndicator with a some default props.
 */
export default compose(
  setDisplayName('JobApplicationListLoadingIndicator'),
  withProps({
    size: 64,
    message: 'Loading job applications',
  }),
)(LoadingIndicator);

/**
 * A customized Victory Charts theme for Thrive.
 * Taken from the default "grayscale" theme and modified with our colors, font, and some other
 * changes.
 * @see https://github.com/FormidableLabs/victory-core/blob/master/src/victory-theme/grayscale.js
 */

// *
// * Thrive Colors
// *
const colorPrimary = '#e74c3c';
const colorSecondary = '#0097c0';
// const colorSecondaryLight = '#00c2f6';
const colorBlack = '#1f2d3b';
// const colorGrayDark = '#70767f';
const colorGray = '#adb5c2';
// const colorGrayLight = '#d6dde8';
const colorGrayLighter = '#f6f8fc';
const colorWhite = '#ffffff';
const colorGreen = '#5cb85c';
const colorBlue = '#5bc0de';
const colorOrange = '#f0ad4e';
const colorRed = '#f91111';
const colorLightBlue = '#d9ecf3';
const textColor = colorBlack;

// *
// * Base color scale
// *
const colorScale = [
  colorPrimary,
  colorSecondary,
  colorGreen,
  colorOrange,
  colorRed,
  colorBlue,
  colorLightBlue,
];

// *
// * Typography
// *
const fontFamily = 'Whitney, sans-serif';
const letterSpacing = 'normal';
const fontSize = 14;

// *
// * Layout
// *
const baseProps = {
  width: 450,
  height: 300,
  padding: 50,
  colorScale: colorScale,
};

// *
// * Labels
// *
const baseLabelStyles = {
  fontFamily: fontFamily,
  fontSize: fontSize,
  letterSpacing: letterSpacing,
  padding: 10,
  fill: textColor,
  stroke: 'transparent',
};

const centeredLabelStyles = {
  ...baseLabelStyles,
  textAnchor: 'middle',
};

// *
// * Strokes
// *
const strokeLinecap = 'round';
const strokeLinejoin = 'round';

export default {
  area: {
    ...baseProps,
    style: {
      data: {
        fill: textColor,
      },
      labels: centeredLabelStyles,
    },
  },
  axis: {
    ...baseProps,
    style: {
      axis: {
        fill: 'transparent',
        stroke: textColor,
        strokeWidth: 1,
        strokeLinecap: strokeLinecap,
        strokeLinejoin: strokeLinejoin,
      },
      axisLabel: {
        ...centeredLabelStyles,
        padding: 25,
      },
      grid: {
        fill: 'transparent',
        stroke: 'transparent',
        pointerEvents: 'none',
      },
      ticks: {
        fill: 'transparent',
        size: 1,
        stroke: 'transparent',
      },
      tickLabels: baseLabelStyles,
    },
  },
  bar: {
    ...baseProps,
    style: {
      data: {
        fill: colorGray,
        padding: 8,
        strokeWidth: 0,
      },
      labels: baseLabelStyles,
    },
  },
  candlestick: {
    ...baseProps,
    style: {
      data: {
        stroke: colorGray,
        strokeWidth: 1,
      },
      labels: centeredLabelStyles,
    },
    candleColors: {
      positive: colorWhite,
      negative: colorGray,
    },
  },
  chart: baseProps,
  errorbar: {
    ...baseProps,
    style: {
      data: {
        fill: 'transparent',
        stroke: colorGray,
        strokeWidth: 2,
      },
      labels: centeredLabelStyles,
    },
  },
  group: {
    ...baseProps,
    colorScale: colorScale,
  },
  labels: {
    ...baseLabelStyles,
  },
  line: {
    ...baseProps,
    style: {
      data: {
        fill: 'transparent',
        stroke: colorGray,
        strokeWidth: 2,
      },
      labels: centeredLabelStyles,
    },
  },
  pie: {
    style: {
      data: {
        padding: 10,
        stroke: 'transparent',
        strokeWidth: 1,
      },
      labels: {
        ...baseLabelStyles,
        padding: 5,
      },
    },
    colorScale: colorScale,
    width: 400,
    height: 400,
    padding: 10,
  },
  scatter: {
    ...baseProps,
    style: {
      data: {
        fill: colorGray,
        stroke: 'transparent',
        strokeWidth: 0,
      },
      labels: centeredLabelStyles,
    },
  },
  stack: {
    ...baseProps,
    colorScale: colorScale,
  },
  tooltip: {
    style: {
      ...centeredLabelStyles,
      padding: 0,
      pointerEvents: 'none',
    },
    flyoutStyle: {
      stroke: colorGrayLighter,
      strokeWidth: 1,
      fill: colorWhite,
      pointerEvents: 'none',
    },
    cornerRadius: 0,
    pointerLength: 0,
  },
  voronoi: {
    ...baseProps,
    style: {
      data: {
        fill: 'transparent',
        stroke: 'transparent',
        strokeWidth: 0,
      },
      labels: {
        ...centeredLabelStyles,
        padding: 5,
        pointerEvents: 'none',
      },
      flyout: {
        stroke: colorGray,
        strokeWidth: 1,
        fill: colorGrayLighter,
        pointerEvents: 'none',
      },
    },
  },
  legend: {
    colorScale: colorScale,
    style: {
      data: {
        type: 'circle',
      },
      labels: baseLabelStyles,
    },
  },
};

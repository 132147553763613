import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import addressSchema from '../addressSchema';

/**
 * Returns a value indicating whether a particular address has been fetched from the server or not.
 *
 * @param {*} state
 * @param {Number} addressId The ID of the address to check.
 * @return {Boolean} True if the address has been fetched, otherwise false.
 */
export default (state, addressId) =>
  Boolean(getEntityProperty(state, addressSchema.key, addressId, 'id'));

import { USER_STATE_KEY } from '../constants';

/**
 * Determines if the current user is an super admin user.
 * @param {*} state
 * @returns {Boolean} true if the current user is an super admin; otherwise, false
 */
const isSuperAdmin = state => state[USER_STATE_KEY].get('super_admin', false);

export default isSuperAdmin;

import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import SearchClientCompanyName from 'modules/searches/components/SearchClientCompanyName';
import mapCandidacyIdToSearchId from '../mapCandidacyIdToSearchId';
import mapDatumToCandidacyId from './mapDatumToCandidacyId';

/**
 * Renders the name of the client company for a search from it's candidacy record, linking
 * to the company profile page.
 */
export default compose(
  setDisplayName('CandidacySearchClientCompanyNameCell'),
  setPropTypes({
    /**
     * The candidacy ID
     */
    datum: PropTypes.number.isRequired,
  }),
  mapDatumToCandidacyId,
  mapCandidacyIdToSearchId,
  mapProps(({ searchId }) => ({
    searchId: searchId,
    linkToCompany: true,
  })),
)(SearchClientCompanyName);

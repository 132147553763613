import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  mapProps,
} from 'recompose';
import addressSchema from '../addressSchema';

/**
 * Renders a property value for an address, given the property name and address ID
 */
export default compose(
  setDisplayName('CompanyProperty'),
  setPropTypes({
    addressId: PropTypes.number.isRequired,
    component: EntityProperty.propTypes.component,
    formatter: EntityProperty.propTypes.formatter,
    propertyName: EntityProperty.propTypes.propertyName,
  }),
  defaultProps(EntityProperty.defaultProps),
  mapProps(({ addressId, ...rest }) => ({
    ...rest,
    entityType: addressSchema.key,
    entityId: addressId,
  })),
)(EntityProperty);

/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import ReactRating from 'react-rating';
import Popover from 'react-bootstrap/lib/Popover';
import Overlay from 'react-bootstrap/lib/Overlay';
import withComponentId from './withComponentId';
import withScrollContainerRef from './withScrollContainerRef';
import withOverlayTrigger from './withOverlayTrigger';

const DEFAULT_MAX_RATING = 5;

/**
 * The Symbol to render for an unselected rating value.
 * This are precreated to prevent them from causing rerenders of the underlying
 * ReactRating component whenever any of the props for the `Rating` component change.
 */
const EMPTY_SYMBOL = <i className='fa fa-star-o' />;

/**
 * The Symbol to render for an unselected rating value.
 */
const FULL_SYMBOL = <i className='fa fa-star' />;

/**
 * Renders a generic rating, optionally showing content
 * under the rating, and tooltips when hovering over rating values.
 */
const RatingLegacy = ({
  activeTooltipValue,
  children,
  className,
  componentId,
  getScrollContainer,
  getTargetRef,
  maxRating,
  onMouseLeave,
  onRatingHover,
  overlayTriggerProps,
  ratingStarsClassName,
  readOnly,
  setTargetRef,
  tooltipPlacement,
  tooltips,
  value,
  ...props
}) => (
  <div
    className={classnames('RatingLegacy', className)}
    onMouseLeave={onMouseLeave}
    ref={setTargetRef}
    {...overlayTriggerProps}
  >
    {tooltips &&
      tooltips.map(tooltip => (
        // By rendering distinct overlays for each possible popover, we prevent reuse of the popover
        // which can cause the arrow to get positioned incorrectly when the height of the underlying
        // popover changes while hovering through the ratings
        <Overlay
          container={getScrollContainer}
          key={`${componentId}-overlay-${tooltip.value}`}
          placement={tooltipPlacement}
          show={activeTooltipValue === tooltip.value}
          target={getTargetRef}
        >
          <Popover id={`${componentId}-popover-${tooltip.value}`}>
            {tooltip.content}
          </Popover>
        </Overlay>
      ))}
    <ReactRating
      className={classnames('RatingLegacy__stars', ratingStarsClassName)}
      emptySymbol={EMPTY_SYMBOL}
      fullSymbol={FULL_SYMBOL}
      initialRating={value}
      onHover={onRatingHover}
      // react-rating insists on using non-standard casing
      readonly={readOnly}
      stop={maxRating}
      {...props}
    />
    {children && <div className='Rating__children'>{children}</div>}
  </div>
);

RatingLegacy.defaultProps = {
  activeTooltipValue: null,
  children: null,
  className: null,
  maxRating: DEFAULT_MAX_RATING,
  overlayTriggerProps: null,
  ratingStarsClassName: '',
  readOnly: false,
  tooltipPlacement: 'right',
  tooltips: [],
};

RatingLegacy.propTypes = {
  /**
   * The rating value for the current tooltip that should be rendered
   */
  activeTooltipValue: PropTypes.number,

  /**
   * Some optional content to display after the rating (a label, description, etc)
   */
  children: PropTypes.node,

  /**
   * Optional additional class name to apply.
   */
  className: PropTypes.string,

  /**
   * A unique ID for this component instance
   * (provided by withComponentId),
   */
  componentId: PropTypes.string.isRequired,

  /**
   * Called to get the scroll container this component is rendered in
   * (needed for displaying tooltips)
   */
  getScrollContainer: PropTypes.func.isRequired,

  /**
   * Called to get the DOM element reference to the component that the tooltip should be attached to
   */
  getTargetRef: PropTypes.func.isRequired,

  /**
   * The total number of stars that will be shown, and the maximum value that can be selected
   */
  maxRating: PropTypes.number,

  /**
   * Called when the mouse leaves the entire rating component.
   */
  onMouseLeave: PropTypes.func.isRequired,

  /**
   * Called when a rating value is hovered (and readOnly is false)
   */
  onRatingHover: PropTypes.func.isRequired,

  /**
   * The props to pass to the element that will act as the overlay trigger
   * when in readOnly mode.
   */
  overlayTriggerProps: PropTypes.objectOf(PropTypes.func.isRequired),

  ratingStarsClassName: PropTypes.string,

  /**
   * True to render the component in readOnly mode (will not be editable/changable)
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  readOnly: PropTypes.bool,

  /**
   * A function that sets the DOM element reference to the component that the tooltip should
   * be attached to
   */
  setTargetRef: PropTypes.func.isRequired,

  /**
   * The position of the tooltips shown when hovering over ratings (when `tooltips` is provided).
   */
  tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),

  /**
   * The tooltips that are shown when hovering over the various rating values.
   */
  tooltips: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  ),

  /**
   * The current rating value.
   */
  value: PropTypes.number.isRequired,
};

/**
 * Modified the Rating component to:
 * 1. Provide popovers when hovering over values
 * 2. Act more like a "controlled" input by taking a value and
 *    triggering an onChange.
 */
export default compose(
  setDisplayName('Rating'),
  setPropTypes({
    /**
     * Optional additional content to render below the rating stars.
     */
    children: PropTypes.node,

    /**
     * The maximum number of rating stars
     */
    maxRating: PropTypes.number.isRequired,

    /**
     * Called when the rating is changed.
     * NOTE: that this doesn't work _exactly_ the way
     * a typical input does. The `value` should still
     * be changed when onChange is called, but because of how
     * react-rating works, it's not totally "controlled" and
     * the control itself will still display the new rating
     * even if the `value` prop is not updated!
     */
    onChange: PropTypes.func,

    /**
     * Whether the rating can be changed.
     */
    readOnly: PropTypes.bool.isRequired,

    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
      .isRequired,

    /**
     * Optional tooltips to show when the component is hovered
     * (when readOnly is true) or when hovering over indivudual
     * ratings tp change the value (when readOnly is false).
     */
    tooltips: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.node.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    ),

    /**
     * The rating value (0...maxRating)
     */
    value: PropTypes.number.isRequired,
  }),
  defaultProps({
    children: null,
    className: null,
    maxRating: DEFAULT_MAX_RATING,
    overlayTriggerProps: null,
    readOnly: false,
    tooltipPlacement: 'right',
    tooltips: [],
  }),

  // We need a unique ID for overlays/popovers
  withComponentId(),

  // In edit mode, we show tooltips when each individual value is hovered.
  // and provide `onChange` callback.
  withStateHandlers(
    {
      hoveredRating: null,
    },
    {
      onRatingHover: () => hoveredRating => ({ hoveredRating: hoveredRating }),
      onMouseLeave: () => () => ({ hoveredRating: null }),
    },
  ),

  // Determine which container to show overlays in.
  withScrollContainerRef,

  // When we're in readOnly mode, we want to show tooltips when the entire
  // component is hovered.
  withOverlayTrigger(),

  /**
   * Based on readOnly value, determine which tooltip we should be showing
   */
  // When readOnly=false, the currently hovered value.
  mapProps(({ hoveredRating, readOnly, showOverlay, value, ...props }) => ({
    // When readOnly=true, whether to show the tooltip
    activeTooltipValue: readOnly
      ? (showOverlay && Math.floor(value)) || null
      : hoveredRating,

    // Passthrough readOnly and value props, along with the rest.
    readOnly: readOnly,
    value: value,
    ...props,
  })),
)(RatingLegacy);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import Modal from '@thrivetrm/ui/components/Modal';
import { useDispatch } from 'react-redux';
import { useApiPost } from '@thrivetrm/ui/hooks/useApi';
import routes from 'modules/routing/routes';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import Card from '@thrivetrm/ui/components/Card';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import TextInput from '@thrivetrm/ui/components/TextInput';
import CopyButton from '@thrivetrm/ui/components/CopyButton';

const SeekoutIntegrationCopyThriveAPI = ({ initialSeekoutToken }) => {
  const dispatch = useDispatch();
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const [seekoutApiToken, setSeekoutApiToken] = useState(initialSeekoutToken);

  const [
    generateSeekoutAPIToken,
    isGeneratingSeekoutAPIToken,
    generateSeekoutAPITokenError,
  ] = useApiPost(routes.api_integrations_seekout_residencies());

  const handleGenerateToken = () => {
    generateSeekoutAPIToken(
      {},
      {
        onSuccess: response => {
          openModal();
          setSeekoutApiToken(response.seekout_residency_token);
          dispatch(toastSuccess('Successfully generated Thrive API Token.'));
        },
      },
    );
  };

  return (
    <>
      <h3 className='u-marginTop-32 u-marginBottom-8'>1. Generate API Token</h3>
      <p className='u-marginBottom-12'>
        First, generate a Thrive API token that will let Seekout talk to Thrive
        and send over candidates.
      </p>
      {seekoutApiToken ? (
        <Card className='u-backgroundColor-gray5' isCentered={false}>
          <div className='u-flex u-flexAlign-b'>
            <TextInput
              className='u-flexItem-grow'
              inputWidth='full'
              label='Thrive API Token'
              onChange={() => {}}
              value={seekoutApiToken}
            />
            <CopyButton
              className='u-marginLeft-16'
              textToCopy={seekoutApiToken}
            />
            <ButtonSecondary
              icon='refresh'
              isLoading={isGeneratingSeekoutAPIToken}
              label='Regenerate Thrive API Token'
              onClick={handleGenerateToken}
            />
          </div>
        </Card>
      ) : (
        <ButtonSecondary
          icon='add'
          isLoading={isGeneratingSeekoutAPIToken}
          label='Generate API Token'
          onClick={handleGenerateToken}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size='small'
        title='Thrive API Token'
      >
        <Modal.Body>
          <h3 className='text-center u-marginTop-n'>
            <StatusIndicator className='u-marginRight-8' status='success' />
            Success!
          </h3>
          <p>Below is your newly generated Thrive API Token</p>
          <Card className='u-backgroundColor-gray5' isCentered={false}>
            <div className='u-flex u-flexAlign-b'>
              <TextInput
                className='u-flexItem-grow'
                inputWidth='full'
                label='Thrive API Token'
                onChange={() => {}}
                value={seekoutApiToken}
              />
              <CopyButton
                className='u-marginLeft-8'
                textToCopy={seekoutApiToken}
              />
            </div>
          </Card>
        </Modal.Body>
      </Modal>
      {generateSeekoutAPITokenError ? (
        <Card className='u-marginBottom-12' isCentered={false} type='error'>
          <h4 className='u-marginTop-n'>
            There was an error creating Thrive API Token.
          </h4>
          {generateSeekoutAPITokenError}
        </Card>
      ) : null}
    </>
  );
};

SeekoutIntegrationCopyThriveAPI.defaultProps = {
  initialSeekoutToken: null,
};

SeekoutIntegrationCopyThriveAPI.propTypes = {
  initialSeekoutToken: PropTypes.string,
};

export default SeekoutIntegrationCopyThriveAPI;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  setStatic,
  withHandlers,
} from 'recompose';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import CompanySelectField from 'modules/companies/components/CompanySelectField';
import ContactSelectField from 'modules/contacts/components/ContactSelectField';
import getContactProperty from 'modules/contacts/selectors/contacts/getContactProperty';
import DateInputField from 'modules/datetime/components/DateInputField';
import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import UserSelectField from 'modules/users/components/UserSelectField';
import { FILTER_NON_CANDIDATES } from 'modules/users/constants';

const relationshipManagerLabel = (
  <>
    <span>Relationship Manager&nbsp;&nbsp;</span>
    <OverlayTrigger
      overlay={
        <Tooltip id='ContactConnectionsField__tooltip'>
          <span>
            Relationship Managers are used to maintain contact relationships.
            Reach out to Relationship Managers prior to contact.
          </span>
        </Tooltip>
      }
      placement='right'
    >
      <i className='fa fa-info-circle btn-tooltip' />
    </OverlayTrigger>
  </>
);

/**
 * A field for editing contact "connections" related values:
 *   * Contact Source
 *   * Referral Source
 *   * Referred By Contact
 *   * Referred By Company
 *   * Reports To
 *   * Referred Date
 *   * Referred By Notes
 */
const ContactConnectionsField = ({
  fieldState,
  handleNestedFieldChange,
  handleReferredByIdChange,
  hasRelationshipManagerEnabled,
  ...otherProps
}) => (
  <div className='ContactConnectionsField row'>
    <div className='col-sm-6'>
      <div className='row'>
        <ContactSelectField
          {...otherProps}
          allowCreate={true}
          className='col-sm-12 ContactConnectionsField__referred_by'
          fieldState={fieldState.getNestedField('referred_by_id')}
          label='Referred By Contact'
          onChange={handleReferredByIdChange}
        />
        <CompanySelectField
          {...otherProps}
          allowCreate={false}
          className='col-sm-12 ContactConnectionsField__referred_by_firm'
          fieldState={fieldState.getNestedField('referred_by_firm_id')}
          label='Referred By Company'
          onChange={handleNestedFieldChange}
        />
        <DateInputField
          {...otherProps}
          className='col-sm-12 ContactConnectionsField__referred_date'
          fieldState={fieldState.getNestedField('referred_date')}
          label='Referred Date'
          onChange={handleNestedFieldChange}
        />
        <TextAreaField
          {...otherProps}
          className='col-sm-12 ContactConnectionsField__referred_by_notes'
          fieldState={fieldState.getNestedField('referred_by_notes')}
          label='Referred By Notes'
          onChange={handleNestedFieldChange}
        />
      </div>
    </div>
    <div className='col-sm-6'>
      <div className='row'>
        <ContactSelectField
          {...otherProps}
          allowCreate={true}
          className='col-sm-12 ContactConnectionsField__reports_to'
          fieldState={fieldState.getNestedField('reports_to_id')}
          label='Reports to'
          onChange={handleNestedFieldChange}
        />
        {hasRelationshipManagerEnabled && (
          <UserSelectField
            {...otherProps}
            className='col-sm-12 ContactConnectionsField__relationship_manager'
            fieldState={fieldState.getNestedField('relationship_manager_id')}
            filter={FILTER_NON_CANDIDATES}
            label={relationshipManagerLabel}
            name='relationship_manager'
            onChange={handleNestedFieldChange}
          />
        )}
      </div>
    </div>
  </div>
);

ContactConnectionsField.propTypes = {
  /**
   * The field state containing the nested value.
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * Called when a nested field is changed (except for the "referred_by_id" field, which is
   * handled by `handleReferredByIdChange`.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * Called when the "referred_by_id" field value is changed.
   */
  handleReferredByIdChange: PropTypes.func.isRequired,

  hasRelationshipManagerEnabled: PropTypes.bool,
};

export default compose(
  setDisplayName('ContactConnectionsField(enhanced)'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', (name = 'connections', contact, ...rest) => {
    const values = new Map({
      contact_source: null,
      referral_source: '',
      referred_by: null,
      referred_by_firm: null,
      referred_by_notes: '',
      referred_date: null,
      relationship_manager: null,
      reports_to: null,
    }).merge(contact);

    return FieldState.createNested(
      name,
      [
        ContactSelectField.createFieldState(
          'reports_to_id',
          values.get('reports_to'),
        ),
        ContactSelectField.createFieldState(
          'referred_by_id',
          values.get('referred_by'),
        ),
        CompanySelectField.createFieldState(
          'referred_by_firm_id',
          values.get('referred_by_firm'),
        ),
        DateInputField.createFieldState(
          'referred_date',
          values.get('referred_date'),
        ),
        TextAreaField.createFieldState(
          'referred_by_notes',
          values.get('referred_by_notes'),
        ),
        UserSelectField.createFieldState(
          'relationship_manager_id',
          values.get('relationship_manager'),
        ),
      ],
      ...rest,
    );
  }),
  withNestedFieldChangeHandler,
  connect(
    state => ({
      // In order to access state in the handleReferredByIdChange callback, we need to expose store
      // state in the prop chain.
      state: state,
    }),
    {},
  ),
  withHandlers({
    /**
     * Special case for when the "referred by" contact is changed. If there is no "Referred by firm"
     * set yet, then try to auto-fill that with the "referred by"'s primary company, if they have
     * one.
     */
    handleReferredByIdChange: ({
      fieldState,
      onChange,
      state,
    }) => referredByIdFieldState => {
      const referredById = referredByIdFieldState.getValue();
      let newFieldState = fieldState.setNestedField(referredByIdFieldState);

      if (
        referredById &&
        !fieldState.getNestedFieldValue('referred_by_firm_id')
      ) {
        // If a contact was selected and there is currently no "referred by firm"
        // value, then set that to the contact's primary company (if there is one..
        const companyId = getContactProperty(
          state,
          referredById,
          'primary_company',
        );
        if (companyId) {
          newFieldState = newFieldState.setNestedFieldValue(
            'referred_by_firm_id',
            companyId,
          );
        }
      }

      onChange(newFieldState);
    },
  }),
  // No longer need state so don't send it down on props.
  mapProps(({ state: _state, ...props }) => props),
  withFeatureCheck(
    'field.contact.relationship_manager',
    'hasRelationshipManagerEnabled',
  ),
)(ContactConnectionsField);

import PropTypes from 'prop-types';

const recommendedCandidatePropType = PropTypes.shape({
  contact: PropTypes.shape({
    avatar_url: PropTypes.string,
    candidacies_count: PropTypes.number,
    compensation: PropTypes.shape({
      base: PropTypes.number,
      bonus: PropTypes.number,
      equity: PropTypes.number,
    }),
    created_at: PropTypes.string,
    full_name: PropTypes.string,
    github_url: PropTypes.string,
    highest_stage_reached: PropTypes.string,
    id: PropTypes.number,
    in_target_company: PropTypes.bool,
    linkedin_url: PropTypes.string,
    location: PropTypes.string,
    mobile_phone: PropTypes.string,
    other_url: PropTypes.string,
    phone: PropTypes.string,
    preferred_base_maximum: PropTypes.number,
    preferred_base_minimum: PropTypes.number,
    preferred_bonus_maximum: PropTypes.number,
    preferred_bonus_minimum: PropTypes.number,
    preferred_currency: PropTypes.string,
    preferred_email: PropTypes.string,
    preferred_equity_maximum: PropTypes.number,
    preferred_equity_minimum: PropTypes.number,
    // Ideally this is not disabled, fix is to define the object shape
    // eslint-disable-next-line react/forbid-prop-types
    preferred_industry_categories: PropTypes.arrayOf(PropTypes.object),
    preferred_name: PropTypes.string,
    preferred_relocate_domestically: PropTypes.bool,
    preferred_relocate_internationally: PropTypes.bool,
    preferred_sectors: PropTypes.arrayOf(PropTypes.string),
    preferred_total_compensation: PropTypes.number,
    primary_company: PropTypes.shape({
      created_at: PropTypes.string,
      id: PropTypes.number,
      main_phone: PropTypes.string,
      name: PropTypes.string,
      updated_at: PropTypes.string,
      website_url: PropTypes.string,
      work_phone: PropTypes.string,
    }),
    primary_title: PropTypes.string,

    skype_handle: PropTypes.string,
    twitter_handle: PropTypes.string,
    updated_at: PropTypes.string,
    work_phone: PropTypes.string,
  }),
  created_at: PropTypes.string,
  id: PropTypes.number,
  recommended_by: PropTypes.string,
  score: PropTypes.number,
  search_id: PropTypes.number,
  stage: PropTypes.string,
  updated_at: PropTypes.string,
});

export default recommendedCandidatePropType;

import React from 'react';
import { compose, setDisplayName } from 'recompose';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useSelector } from 'react-redux';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import ExternalLink from 'modules/links/ExternalLink';
import withResidencyListFetched from './withResidencyListFetched';
import ResidencyCreateModalButton from './ResidencyCreateModalButton';
import ResidencyList from './ResidencyList';
import { RESIDENCY_TYPE_CONSTANTS as residencyTypeConstants } from '../constants';

/**
 * Renders a page that shows the user their current list of residency tokens,
 * and allows them to delete them and create new ones.
 */
const ResidencyTokenManagementPanel = ({ residencyList }) => {
  const user = useSelector(state => state.user);
  // replace the subdomain with 'api' so users can successfully
  // make requests from the API docs page
  const apiHost = window.location.host.replace(/^.+?\./, 'api.services.');
  const apiDocumentationUrl = `https://${apiHost}/api/docs/v2/`;
  const hasApiV2 = useFeatureCheck('feature.api_v2') && user.get('admin');
  const hasOutlookAddinEnabled = useFeatureCheck(
    'feature.outlook_addin_access_token',
  );

  return (
    <div className='user-account-section'>
      {hasApiV2 && (
        <>
          <h2>API Access Tokens</h2>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='u-marginBottom-64'>
                <h3>Generate New API Token</h3>
                <p>
                  Build custom integrations for Thrive TRM, using our publicly
                  available APIs for contact profiles. You can generate unique
                  tokens for each custom integration and manage all of your
                  organization&apos;s integrations from this page.
                </p>
                <p>
                  Learn more about the available public APIs for Thrive TRM:{' '}
                  <ExternalLink href={apiDocumentationUrl}>
                    Review API Documentation
                  </ExternalLink>
                </p>
                <ResidencyCreateModalButton label='Generate API Token' />
              </div>
            </div>
            <div className='col-sm-6'>
              {residencyList.has('ids') && (
                <ResidencyList
                  residencyIds={residencyList && residencyList.get('ids')}
                  residencyType='Residency::APIPublic'
                />
              )}
              {residencyList.getIn(['_meta', 'isFetching']) && (
                <LoadingIndicator />
              )}
            </div>
          </div>
        </>
      )}
      {hasOutlookAddinEnabled && (
        <>
          <h2>Outlook Access Tokens</h2>
          <div className='row'>
            <div className='col-sm-6'>
              <h3>Generate New Outlook Access Token</h3>
              <p>Generate an Access Token to log in to the Outlook Add-in.</p>
              <ResidencyCreateModalButton
                icon='outlook_calendar'
                label='Generate Outlook Access Token'
                residencyType={residencyTypeConstants.OUTLOOK_ADDIN}
              />
            </div>
            <div className='col-sm-6'>
              {residencyList.has('ids') && (
                <ResidencyList
                  residencyIds={residencyList && residencyList.get('ids')}
                  residencyType='Residency::OutlookAddin'
                />
              )}
              {residencyList.getIn(['_meta', 'isFetching']) && (
                <LoadingIndicator />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ResidencyTokenManagementPanel.propTypes = {
  /**
   * The state of the residency list, which contains the underlying residency IDs and it's
   * fetching state.
   */
  residencyList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
    residencyIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
  }).isRequired,
};

/**
 * The connected version needs no props and connects to the residencyList, fetching it if needed.
 */
export default compose(
  setDisplayName('ResidencyTokenManagementPanel(enhanced'),
  withResidencyListFetched,
)(ResidencyTokenManagementPanel);

import React from 'react';
import PropTypes from 'prop-types';
import useQuickView from 'modules/quick-view/useQuickView';
import ContactAvatarWithQuickView from 'modules/contacts/components/ContactAvatarWithQuickView';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { useSelector } from 'react-redux';
import getContactProperty from 'modules/contacts/selectors/contacts/getContactProperty';
import { CONNECTION_TYPE_GROUP_WITH_NAME } from 'services/apiV1/connections';
import ExpandableActivityView from './ExpandableActivityView';

const ReferenceActivityView = ({
  company,
  connection,
  contactFirstName,
  creator,
  description,
  email,
  name,
  phone,
  referee,
  relationship,
}) => {
  const { pathParams } = useQuickView();
  const { contactId } = pathParams;
  const hasReferenceConnectionV2 = useFeatureCheck(
    'development.references_connection_v2',
  );
  const hasConnectionsFeature = useFeatureCheck('feature.connections_gate');
  const hasReferencesConnectionsFeature = useFeatureCheck(
    'feature.references_connections',
  );
  const contactName = useSelector(
    state =>
      getContactProperty(state, contactId, 'first_name') ||
      getContactProperty(state, contactId, 'full_name'),
  );

  return (
    <div className='u-fontSize-small u-marginTop-4'>
      {hasReferenceConnectionV2 ? (
        <div className='u-flex u-flexAlign-c u-marginTop-8'>
          {referee?.id ? (
            <ContactAvatarWithQuickView
              avatarUrl={referee.avatarUrl}
              className='u-marginRight-12'
              connectionsCount={referee.connectionsCount}
              contactId={referee.id}
              contactName={referee.fullName}
              shouldUseQuickView={true}
            />
          ) : null}
          <div className='u-wordBreak'>
            {referee?.fullName ? (
              <span className='u-textColor-gray60'>{referee.fullName}</span>
            ) : null}
            {hasConnectionsFeature && connection?.id ? (
              <p className='u-margin-n'>
                {connection.name}
                {CONNECTION_TYPE_GROUP_WITH_NAME.includes(connection?.group)
                  ? ` ${contactFirstName}`
                  : null}
              </p>
            ) : null}
            {!hasConnectionsFeature && relationship ? (
              <p className='u-margin-n'>{relationship}</p>
            ) : null}
            <ExpandableActivityView content={description} />
          </div>
        </div>
      ) : (
        <>
          <p className='u-marginBottom-n'>
            {referee?.id ? (
              <a href={`contacts/${referee.id}`}>{referee.fullName}</a>
            ) : (
              name
            )}
          </p>
          <p className='u-marginBottom-n'>
            {hasConnectionsFeature && hasReferencesConnectionsFeature
              ? connection?.name
              : relationship}
            {hasConnectionsFeature &&
            CONNECTION_TYPE_GROUP_WITH_NAME.includes(connection?.group || '')
              ? ` ${contactName}`
              : null}
            {company ? (
              <span>
                {' at '}
                {company}
              </span>
            ) : null}
          </p>
          <p className='u-marginBottom-n'>{email}</p>
          <p className='u-marginBottom-n'>{phone}</p>
          {creator?.fullNameOrEmail ? (
            <p className='u-marginBottom-n'>
              Added by: {creator.fullNameOrEmail}
            </p>
          ) : null}
          <ExpandableActivityView content={description} />
        </>
      )}
    </div>
  );
};

ReferenceActivityView.propTypes = {
  company: PropTypes.string,
  connection: PropTypes.shape({
    group: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  contactFirstName: PropTypes.string,
  creator: PropTypes.shape({
    fullNameOrEmail: PropTypes.string,
  }),
  description: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  referee: PropTypes.shape({
    avatarUrl: PropTypes.string,
    connectionsCount: PropTypes.number,
    fullName: PropTypes.string,
    id: PropTypes.number,
  }),
  relationship: PropTypes.string,
};

export default ReferenceActivityView;

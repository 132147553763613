import isClientUser from 'modules/auth/selectors/isClientUser';

/**
 * Gets a value indicating whether the current user has permission to view the last
 * contacted date of a candidacy
 * @param {Object} state
 * @return {Boolean} True if the current user is allowed to sort by last contacted date;
 *   otherwise, false.
 */
export default state => !isClientUser(state);

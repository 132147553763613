import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import userSchema from '../schema';

/**
 * Gets a user's avatar URL from the user ID
 * @param {Object} state
 * @param {Number} introductionId The user ID
 * @return {String} The user's avatar URL.
 */
export default (state, userId) =>
  getEntityProperty(state, userSchema.key, userId, 'avatar_url');

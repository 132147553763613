import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import DocumentTableViewRow from './DocumentTableViewRow';
import DocumentTableEditRow from './DocumentTableEditRow';
import documentShape from '../documentShape';

export const DocumentTableRow = ({
  document,
  documentCountForCurrentLabel,
  fetchDocuments,
  labelItems,
  readOnly,
  shouldShowExpirationDateColumn,
  shouldShowShareInSearchesColumn,
}) => {
  const [isEditing, startEditing, stopEditing] = useToggle(false);
  /* eslint-disable no-underscore-dangle */
  const isDeleting = !readOnly && document._meta?.isDeleting;
  const isUpdating = !readOnly && document._meta?.isUpdating;
  const isDeleted = !readOnly && document._meta?.isDeleted;

  return (
    <tr
      className={classnames('documents--document-table-row', {
        'is-deleting': isDeleting,
        'is-deleted': isDeleted,
        'is-updating': isUpdating,
      })}
    >
      {isEditing ? (
        <DocumentTableEditRow
          document={document}
          documentCountForCurrentLabel={documentCountForCurrentLabel}
          fetchDocuments={fetchDocuments}
          isUpdating={isUpdating}
          labelItems={labelItems}
          onEditComplete={stopEditing}
          readOnly={readOnly}
          shouldShowExpirationDateColumn={shouldShowExpirationDateColumn}
          shouldShowShareInSearchesColumn={shouldShowShareInSearchesColumn}
        />
      ) : (
        <DocumentTableViewRow
          document={document}
          fetchDocuments={fetchDocuments}
          isDeleted={isDeleted}
          isDeleting={isDeleting}
          isUpdating={isUpdating}
          onEditClick={startEditing}
          readOnly={readOnly}
          shouldShowExpirationDateColumn={shouldShowExpirationDateColumn}
          shouldShowShareInSearchesColumn={shouldShowShareInSearchesColumn}
        />
      )}
    </tr>
  );
};
DocumentTableRow.defaultProps = {
  documentCountForCurrentLabel: 0,
  readOnly: false,
};

DocumentTableRow.propTypes = {
  document: PropTypes.shape(documentShape).isRequired,
  documentCountForCurrentLabel: PropTypes.number,
  fetchDocuments: PropTypes.func.isRequired,
  labelItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  readOnly: PropTypes.bool,
  shouldShowExpirationDateColumn: PropTypes.bool.isRequired,
  shouldShowShareInSearchesColumn: PropTypes.bool.isRequired,
};

export default DocumentTableRow;

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';

import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import DateTime from 'modules/datetime/components/DateTime';
import { ACTIVELY_LOOKING, PASSIVELY_LOOKING } from '../constants';

/**
 * A contact-connected component that simply takes the contactId and looks up
 * the contact's seeking_job information, to display "Looking as of {Date}"
 */

const JobOpportunityStatus = ({ className, contact }) => {
  const seekingJobStatus = contact.get('seeking_job_status');
  const seekingJobSince = contact.get('seeking_job_status_start_date');

  return (
    seekingJobStatus &&
    seekingJobSince && (
      <div className={classnames('JobOpportunityStatus', className)}>
        <strong>
          {seekingJobStatus === 'active' ? ACTIVELY_LOOKING : PASSIVELY_LOOKING}
        </strong>
        <span>
          as of <DateTime value={seekingJobSince} />
        </span>
      </div>
    )
  );
};

JobOpportunityStatus.propTypes = {
  className: PropTypes.string,
  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.map,
    meta: ImmutablePropTypes.map,
  }),
};

export default compose(
  setDisplayName('ContactJobOpportunityStatus'),
  setPropTypes({
    className: PropTypes.string,
    contactId: PropTypes.number,
  }),
  mapContactIdToContact,
)(JobOpportunityStatus);

import deleteEntity from 'modules/entities/actions/deleteEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import { outreachSchema } from '../../schema';
import getOutreach from '../../selectors/getOutreach';
import invalidateOutreachCandidacyStatuses from './invalidateOutreachCandidacyStatuses';

/**
 * Creates an action for deleting an outreach
 * @param {Object} payload
 * @param {Number} payload.id The outreach ID.
 */
export default ({ id, ...rest }) => (dispatch, getState) => {
  const outreach = getOutreach(getState(), id);

  return dispatch(
    deleteEntity({
      ...rest,
      id: id,
      entityType: outreachSchema.key,
      url: routes.api_v1_outreach({
        id: id,
        query: {
          include_notifications: true,
        },
      }),
    }),
  ).then(action => {
    if (action.type === entityActionTypes.DELETE_SUCCESS && outreach) {
      dispatch(invalidateOutreachCandidacyStatuses(outreach.toJS()));
    }

    return action;
  });
};

// This code is currently used to manage the add/remove functionality when adding
// candidates to a search or adding members to a network
const {
  initializeAddRemoveToggle,
  initializeTooltip,
} = require('../lib/add_remove_toggle');

const resourceSelectorMap = {
  candidacy: '#contacts',
  company: '#companies',
  search: '#searches',
};

exports.once = () =>
  Object.keys(resourceSelectorMap).forEach(resourceType =>
    initializeTooltip(resourceType),
  );

exports.always = () =>
  Object.entries(resourceSelectorMap).forEach(([type, selector]) =>
    initializeAddRemoveToggle(type, selector),
  );

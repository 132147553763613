import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

/**
 * Connects the note actions to the current dispatched and adds
 * them to props as `noteActions`
 */
export default connect(null, dispatch => ({
  noteActions: bindActionCreators(actions, dispatch),
}));

const initSelect2 = require('../../lib/select2');
const initEmail = require('../../lib/select2_email');
const handleSimpleSearchSubmit = require('../../lib/handle_simple_search_submit');
const select2Autocomplete = require('../../lib/select2_autocomplete');
const initSelectsInModal = require('../../lib/modal_selects');
const displayModalErrors = require('../../lib/display_modal_errors').default;
const validation = require('../../lib/validation');
const introductions = require('../introductions');

// Non AJAX selects
const selects = [
  '.js-acquisition-currency',
  '.js-funding-rounds',
  '.js-industries',
  '.js-employee-count',
  '.js-revenue-currency',
  '.js-revenue-year',
  '.js-sectors',
  '.js-select-company-funds',
  '.js-select-investment-stage',
  '.js-select-priority',
  '.js-select-revenue-growth',
  '.js-target-functions',
  '.js-target-markets',
  'select.js-state-select',
  'select.js-province-select',
];

const handleInvestmentFields = investmentStage => {
  if (investmentStage === 'Portfolio Company') {
    $('.js-investment-fields').collapse('show');
    return $('.js-exited').collapse('hide');
  }
  if (investmentStage === 'Portfolio Company (Exited)') {
    $('.js-investment-fields').collapse('show');
    return $('.js-exited').collapse('show');
  }
  if (['Pipeline Company', ''].includes(investmentStage)) {
    $('.js-investment-fields').collapse('hide');
    return $('.js-exited').collapse('hide');
  }
  return {};
};

const bindSelects = () => {
  select2Autocomplete('.js-select2-select-ajax');
  return initSelect2('.company-form', selects);
};

const bindEvents = () => {
  $(document).on('nested:fieldAdded', () => bindSelects());
  // Conditionally show investment stage fields based on dropdown selection
  $('.js-select-investment-stage').change(() =>
    handleInvestmentFields($(this).val()),
  );
  return $('.basic-title .view-state').on('click', () =>
    handleInvestmentFields($('.js-select-investment-stage').val()),
  );
};

const initializeFilters = () => {
  initSelectsInModal('.advanced-search-modal');

  select2Autocomplete('.js-filter-parent-companies', {
    dropdownParent: $('#company-filter-parent-companies'),
  });
  return select2Autocomplete('.js-cities-filter', {
    dropdownParent: $('#company-filter-cities'),
  });
};

exports.initializeFilters = initializeFilters;

exports.initialize = function initialize() {
  // Initialize introductions
  introductions.initialize();

  bindEvents();
  bindSelects();
  handleSimpleSearchSubmit('#company_filter_name', '#new_company_filter');

  initSelectsInModal('.advanced-search-modal');

  select2Autocomplete('.js-cities-filter', {
    dropdownParent: $('#company-filter-cities'),
  });
  select2Autocomplete('.js-filter-parent-companies', {
    dropdownParent: $('#company-filter-parent-companies'),
  });

  // For checkboxes with dependent fields, show them if the checkbox is checked
  if ($("input[name='company[publicly_traded]']:checked").length > 0) {
    $('.company_ipo_date_us_format').addClass('in');
  }

  if ($("input[name='company[acquired]']:checked").length > 0) {
    [
      '.company_acquired_by',
      '.company_acquisition_amount',
      '.company_acquisition_currency',
    ].forEach(field => {
      $(field).addClass('in');
    });
  }

  const $filterModal = $('#filters');
  const filterValidator = validation.default($filterModal);
  filterValidator.add(
    '.market-cap-amount-error',
    validation.positiveRange({
      min: '.js-market-cap-amount-min',
      max: '.js-market-cap-amount-max',
    }),
  );
  filterValidator.add(
    '.latest-revenue-error',
    validation.positiveRange({
      min: '.js-latest-revenue-min',
      max: '.js-latest-revenue-max',
    }),
  );
  filterValidator.add(
    '.employee-count-error',
    validation.positiveRange({
      min: '.js-employee-count-min',
      max: '.js-employee-count-max',
    }),
  );
  filterValidator.add(
    '.created-date-range-error',
    validation.dateRange({
      start: '.js-created-start-date',
      end: '.js-created-end-date',
    }),
  );
  filterValidator.add(
    '.updated-date-range-error',
    validation.dateRange({
      start: '.js-updated-start-date',
      end: '.js-updated-end-date',
    }),
  );
  filterValidator.add(
    '.document-expiration-date-range-error',
    validation.dateRange({
      start: '.js-document-expiration-start-date',
      end: '.js-document-expiration-end-date',
    }),
  );
  filterValidator.add(
    '.document-uploaded-date-range-error',
    validation.dateRange({
      start: '.js-document-uploaded-start-date',
      end: '.js-document-uploaded-end-date',
    }),
  );
  $('#filters-form-submit').on('click', event => {
    const errors = filterValidator.run();
    return displayModalErrors(event, $filterModal, errors);
  });

  initEmail($('#report_emails'));

  initializeFilters();
};

// Used in app/views/companies/update.js
exports.replace = (selector, content) => {
  $(selector).replaceWith(content);
  $('[data-section-toggle-container]').sectionToggle();
  bindSelects();
  return bindEvents();
};

import { connect } from 'react-redux';
import { branch, compose, lifecycle } from 'recompose';
import connectTalentPoolActions from './connectTalentPoolActions';
import shouldFetchAllTalentPoolsSelector from '../selectors/shouldFetchAllTalentPools';

/**
 * When provided a parentId, parentType, and optional filter via props, fetches the interviews
 * for that parent if they need to be fetched so they are available for a component.
 */
export default compose(
  branch(
    ({ talentPoolActions }) => !talentPoolActions,
    connectTalentPoolActions,
  ),
  connect(
    state => ({
      shouldFetchAllTalentPools: shouldFetchAllTalentPoolsSelector(state),
    }),
    {},
  ),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const { shouldFetchAllTalentPools, talentPoolActions } = this.props;
      if (shouldFetchAllTalentPools) {
        talentPoolActions.fetchTalentPools();
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const { shouldFetchAllTalentPools, talentPoolActions } = nextProps;
      if (shouldFetchAllTalentPools) {
        talentPoolActions.fetchTalentPools();
      }
    },
  }),
);

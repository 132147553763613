import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import AppointmentView from 'modules/appointments/components/AppointmentView';
import mapInterviewIdToInterview from './mapInterviewIdToInterview';

/**
 * Renders a single interview record.
 */
export default compose(
  setDisplayName('InterviewNotificationView'),
  setPropTypes({
    interviewId: PropTypes.number.isRequired,
  }),
  mapInterviewIdToInterview,
  mapProps(({ interview, ...props }) => ({
    appointment: interview,
    ...props,
  })),
)(AppointmentView);

import routes from 'modules/routing/routes';
import { contactSchema } from 'modules/contacts/schema';
import createCrudActions from '../../../actions/createCrudActions';
import {
  CONTACTS_FETCH_START,
  CONTACTS_FETCH_SUCCESS,
  CONTACTS_FETCH_FAILURE,
  CONTACTS_INVALIDATE,
  CONTACT_ADD_START,
  CONTACT_ADD_SUCCESS,
  CONTACT_ADD_FAILURE,
  CONTACT_REMOVE_START,
  CONTACT_REMOVE_SUCCESS,
  CONTACT_REMOVE_FAILURE,
} from './actionTypes';
import shouldFetchContacts from '../selectors/shouldFetchContacts';

const {
  create: addContact,
  delete: removeContact,
  fetchAll: fetchContacts,
  invalidateAll: invalidateContacts,
} = createCrudActions({
  getUrl: routes.api_v1_network_contact,
  fetchAll: {
    getUrl: routes.api_v1_network_contacts,
    schema: { contacts: [contactSchema] },
    start: CONTACTS_FETCH_START,
    success: CONTACTS_FETCH_SUCCESS,
    failure: CONTACTS_FETCH_FAILURE,
  },
  invalidateAll: CONTACTS_INVALIDATE,
  create: {
    getUrl: routes.api_v1_network_contacts,
    createStartPayload: ({ contactId, networkId }) => ({
      contactId: contactId,
      networkId: networkId,
      // The server need this!
      contact_id: contactId,
    }),
    start: CONTACT_ADD_START,
    success: CONTACT_ADD_SUCCESS,
    failure: CONTACT_ADD_FAILURE,
  },
  delete: {
    getUrl: ({ contactId: id, networkId }) =>
      routes.api_v1_network_contact({ networkId: networkId, id: id }),
    start: CONTACT_REMOVE_START,
    success: CONTACT_REMOVE_SUCCESS,
    failure: CONTACT_REMOVE_FAILURE,
  },
});

export const fetchContactsIfNeeded = params => (dispatch, getState) => {
  const { networkId } = params;

  if (shouldFetchContacts(getState(), networkId)) {
    dispatch(fetchContacts(params));
  }
};

export { fetchContacts, invalidateContacts, addContact, removeContact };

import fetchEntity from 'modules/entities/actions/fetchEntity';
import routes from 'modules/routing/routes';
import tagSchema from '../schemas/tag';

/**
 * Creates an action for fetching a tag
 * @param {Object} payload
 * @param {String} payload.id The ID of the tag
 */
export default ({ id }) =>
  fetchEntity({
    entityType: tagSchema.key,
    id: id,
    responseSchema: { tag: tagSchema },
    url: routes.api_v1_tag({ id: id }),
  });

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchTargetCompanyCommentList as fetchTargetCompanyCommentsListActionCreator } from '../actions';
import getTargetCompanyCommentList from '../selectors/comments/getTargetCompanyCommentList';
import shouldFetchTargetCompanyCommentListSelector from '../selectors/comments/shouldFetchTargetCompanyCommentList';

/**
 * When provided a targetCompanyId, fetches the target companies comments
 */
export default compose(
  setDisplayName('withTargetCompanyCommentListFetched'),
  setPropTypes({
    targetCompanyId: PropTypes.number,
  }),
  connect(
    (state, { targetCompanyId }) => ({
      targetCompanyCommentList: getTargetCompanyCommentList(
        state,
        targetCompanyId,
      ),
      shouldFetchTargetCompanyCommentList: shouldFetchTargetCompanyCommentListSelector(
        state,
        targetCompanyId,
      ),
    }),
    dispatch => ({
      fetchTargetCompanyCommentList: bindActionCreators(
        fetchTargetCompanyCommentsListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    /**
     * A handler for fetching the comment list specified by the `targetCompanyId` prop.
     * This allows the component to simply call `fetchTargetCompanyCommentList()` without having to
     * supply params.
     */
    fetchTargetCompanyCommentList: ({
      fetchTargetCompanyCommentList,
      targetCompanyId,
    }) => () =>
      fetchTargetCompanyCommentList({ targetCompanyId: targetCompanyId }),

    /**
     * A handler that, when called, only fetches the comment list if needed.
     */
    fetchTargetCompanyCommentListIfNeeded: ({
      fetchTargetCompanyCommentList,
      shouldFetchTargetCompanyCommentList,
      targetCompanyId,
    }) => () =>
      shouldFetchTargetCompanyCommentList &&
      fetchTargetCompanyCommentList({ targetCompanyId: targetCompanyId }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchTargetCompanyCommentListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchTargetCompanyCommentListIfNeeded();
    },
  }),
);

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';

import {
  compose,
  lifecycle,
  setDisplayName,
  setStatic,
  withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { EditorState } from 'draft-js';

import hasFeature from 'modules/auth/selectors/hasFeature';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import AttachmentsField from 'modules/documents/components/AttachmentsField';
import getEmailTemplateContent from 'modules/email-templates/selectors/getEmailTemplateContent';
import getEmailTemplateSubject from 'modules/email-templates/selectors/getEmailTemplateSubject';
import withAllEmailTemplates from 'modules/email-templates/components/withAllEmailTemplates';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import TitleDropdownField from 'modules/forms/components/TitleDropdownField';
import * as validators from 'modules/forms/validators';
import SearchMultiSelectField from 'modules/searches/components/SearchMultiSelectField';
import fetchUserOptionsIfNeeded from 'modules/user/components/fetchUserOptionsIfNeeded';
import getGmailAddress from 'modules/user/selectors/getGmailAddress';
import getOutlookAddress from 'modules/user/selectors/getOutlookAddress';
import ContactEmailSelectField from 'modules/contacts/components/contactEmailSelect/ContactEmailSelectField';
import isCrmUser from 'modules/auth/selectors/isCrmUser';

import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_JOB_SEARCH, TYPE_PIPELINE } from 'modules/searches/constants';
import canViewAssignedSearchesOnly from '../selectors/canViewAssignedSearchesOnly';
import { fromHtml } from '../../../components/forms/richtext/convert';
import RichTextField from '../../../components/forms/richtext/RichTextField';

/**
 * Identifies the wrapper classes to apply to each field for different "layouts", so that we
 * can arrange fields differently as needed.
 */
const FIELD_LAYOUTS = {
  wide: {
    cc: 'col-12',
    bcc: 'col-12',
    from: 'col-6',
    searches: 'col-6',
    subject: 'col-12',
    notes: 'col-12',
  },
  narrow: {
    cc: 'col-12',
    bcc: 'col-12',
    from: 'col-12',
    searches: 'col-12',
    subject: 'col-12',
    notes: 'col-12',
  },
};

/**
 * A field that can be used for creating an Outreach record and sending it's contents as an email.
 */
class OutreachEmailField extends PureComponent {
  static createFieldState(
    name = 'outreach',
    { lbiTemplate, outreach },
    validator,
  ) {
    const values = fromJS({
      notes: '',
      subject: '',
      searches: [],
      documents: [],
    }).merge(outreach);

    const lbiTemplateId = lbiTemplate && lbiTemplate.get('id');
    const lbiTemplateSubject = lbiTemplate && lbiTemplate.get('subject');
    const lbiTemplateContent = lbiTemplate && lbiTemplate.get('content');

    return FieldState.createNested(
      name,
      [
        RichTextField.createFieldState(
          'notes',
          lbiTemplateContent || values.get('notes'),
        ),
        SearchMultiSelectField.createFieldState(
          'searches',
          values.get('searches'),
        ),
        InputField.createFieldState(
          'subject',
          lbiTemplateSubject || values.get('subject'),
        ),
        InputField.createFieldState('from', values.get('from')),
        ContactEmailSelectField.createFieldState(
          'to',
          values.get('to') && values.get('to').toJS(),
          validators.requiredField('To address'),
        ),
        ContactEmailSelectField.createFieldState('cc', values.get('cc')),
        ContactEmailSelectField.createFieldState('bcc', values.get('bcc')),
        TitleDropdownField.createFieldState(
          'emailTemplate',
          lbiTemplateId || '',
        ),
        AttachmentsField.createFieldState('documents', values.get('documents')),
      ],
      validator,
      ({ bcc, cc, from, to, ...rest }) => ({
        email: {
          from: from,
          to: to.map(({ email }) => email),
          cc: cc.map(({ email }) => email),
          bcc: bcc.map(({ email }) => email),
        },
        contact_method: '',
        ...rest,
      }),
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      allowRelatedSearchesEdit,
      bccVisible,
      ccVisible,
      disableAttachments,
      draft,
      emailTemplates,
      excludeNotesField,
      fieldLayout,
      fieldState,
      onChange: _onChange,
      onDraftChange,
      searchType,
      showBcc,
      showCc,
      ...otherProps
    } = this.props;

    const fieldClasses = FIELD_LAYOUTS[fieldLayout];
    const isPipeline = searchType === TYPE_PIPELINE;

    const templateOptions = () => {
      const emailFieldValue = fieldState
        .getNestedField('emailTemplate')
        .getValue();

      let options = emailTemplates.toJS().map(({ id, name }) => ({
        id: id,
        label: name,
      }));
      if (emailTemplates.size > 0 && emailFieldValue > 0) {
        options = [{ id: 0, label: 'Clear Template' }].concat(options);
      }

      return options;
    };

    return (
      <div className='OutreachEmailField'>
        <AttachmentsField
          {...otherProps}
          className='AttachmentsField'
          disabled={disableAttachments}
          fieldState={fieldState.getNestedField('documents')}
          label='Attachments'
          onChange={this.handleFieldChange}
        >
          <div className='row'>
            <div className='col-12'>
              <div className='EmailTemplateSelect'>
                <TitleDropdownField
                  fieldState={fieldState.getNestedField('emailTemplate')}
                  isLoading={
                    !emailTemplates ||
                    emailTemplates.getIn(['_meta', 'isFetching'])
                  }
                  labelKey='label'
                  noResultsText='No Templates Available'
                  onChange={this.handleFieldChange}
                  options={emailTemplates && templateOptions()}
                  placeholder={
                    <div className='placeholder'>
                      <span>Templates</span>
                      &nbsp;
                      <i className='fa fa-caret-down' />
                    </div>
                  }
                  valueKey='id'
                />
              </div>
              <div className='OutreachEmailField__To'>
                <ContactEmailSelectField
                  clearable={false}
                  fieldState={fieldState.getNestedField('to')}
                  label='To*'
                  multi={true}
                  onChange={this.handleFieldChange}
                />
                {(!ccVisible || !bccVisible) && (
                  <div className='OutreachEmailField__To__CcToggle'>
                    {!ccVisible && (
                      <button
                        className='btn btn-link'
                        onClick={showCc}
                        type='button'
                      >
                        Cc
                      </button>
                    )}
                    {!bccVisible && (
                      <button
                        className='btn btn-link'
                        onClick={showBcc}
                        type='button'
                      >
                        Bcc
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row'>
            {ccVisible && (
              <div className={fieldClasses.cc}>
                <ContactEmailSelectField
                  clearable={false}
                  fieldState={fieldState.getNestedField('cc')}
                  label='Cc'
                  onChange={this.handleFieldChange}
                />
              </div>
            )}
            {bccVisible && (
              <div className={fieldClasses.bcc}>
                <ContactEmailSelectField
                  clearable={false}
                  fieldState={fieldState.getNestedField('bcc')}
                  label='Bcc'
                  onChange={this.handleFieldChange}
                />
              </div>
            )}
            <div className={fieldClasses.from} key='from'>
              <InputField
                {...otherProps}
                fieldState={fieldState.getNestedField('from')}
                label='From'
                onChange={this.handleFieldChange}
                readOnly={true}
              />
            </div>
            <div className={fieldClasses.searches}>
              {allowRelatedSearchesEdit ? (
                <SearchMultiSelectField
                  {...otherProps}
                  fieldState={fieldState.getNestedField('searches')}
                  label={isPipeline ? 'Related Pipelines' : 'Related Searches'}
                  noValueText={
                    isPipeline
                      ? 'No Pipelines Selected'
                      : 'No Searches Selected'
                  }
                  onChange={this.handleFieldChange}
                  placeholder={
                    isPipeline
                      ? 'Optionally Relate to a Pipeline'
                      : 'Optionally Relate to a Search'
                  }
                  searchType={isPipeline ? TYPE_PIPELINE : TYPE_JOB_SEARCH}
                  summaryLabel={
                    isPipeline
                      ? '{0} Pipelines Selected'
                      : '{0} Searches Selected'
                  }
                />
              ) : null}
            </div>
          </div>
          <div className='row'>
            <div className={fieldClasses.subject}>
              <InputField
                {...otherProps}
                fieldState={fieldState.getNestedField('subject')}
                key='subject'
                label='Subject'
                onChange={this.handleFieldChange}
                placeholder='Add a Subject'
              />
            </div>
          </div>
          {!excludeNotesField && (
            <div className='row'>
              <div className={fieldClasses.notes}>
                <RichTextField
                  {...otherProps}
                  fieldState={fieldState.getNestedField('notes')}
                  label='Notes'
                  lastUpdatedTime={draft?.lastUpdatedTime}
                  onChange={this.handleFieldChange}
                  onDraftChange={onDraftChange}
                  placeholder='Enter email...'
                  shouldShowImageOption={true}
                  shouldShowLinkOption={true}
                />
              </div>
            </div>
          )}
        </AttachmentsField>
      </div>
    );
  }
}

OutreachEmailField.propTypes = {
  /**
   * Whether to display and allow the "Related Searches" field to be edited.
   */
  allowRelatedSearchesEdit: PropTypes.bool,

  /**
   * True to show the "BCC" field, false to hide it.
   */
  bccVisible: PropTypes.bool,

  /**
   * True to show the "CC" field, false to hide it.
   */
  ccVisible: PropTypes.bool,

  /**
   * True to disable the attachments functionality.
   */
  disableAttachments: PropTypes.bool,
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),
  /**
   * List of available email templates
   */
  emailTemplates: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),

  /**
   * When true, the notes field is not rendered
   */
  excludeNotesField: PropTypes.bool,

  /**
   * How to layout the form fields (which col classes to apply)
   */
  fieldLayout: PropTypes.oneOf(Object.keys(FIELD_LAYOUTS)),

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,
  onDraftChange: PropTypes.func,
  searchType: PropTypes.string,
  /**
   * Called when the "BCC" field should be made visible.
   */
  showBcc: PropTypes.func.isRequired,

  /**
   * Called when the "CC" field should be made visible
   */
  showCc: PropTypes.func.isRequired,
};

OutreachEmailField.defaultProps = {
  allowRelatedSearchesEdit: false,
  bccVisible: false,
  ccVisible: false,
  draft: null,
  excludeNotesField: false,
  fieldLayout: 'narrow',
};

export default compose(
  setDisplayName('OutreachEmailField(enhanced)'),
  setStatic('createFieldState', OutreachEmailField.createFieldState),
  withAllEmailTemplates,
  withPropsRemoved(
    'emailTemplateActions',
    'shouldFetchEmailTemplatesList',
    'emailTemplatesList',
    'fetchEmailTemplatesList',
    'fetchEmailTemplatesListIfNeeded',
    'emailTemplateList',
    'emailTemplateIds',
  ),
  fetchUserOptionsIfNeeded,
  connect(
    (state, { fieldState, searchId }) => ({
      searchType: getSearchType(state, searchId),
      fromEmailAddress: getGmailAddress(state) || getOutlookAddress(state),
      allowRelatedSearchesEdit:
        !canViewAssignedSearchesOnly(state) && !isCrmUser(state),
      currentEmailTemplateSubject: getEmailTemplateSubject(
        state,
        fieldState.getNestedFieldValue('emailTemplate'),
      ),
      currentEmailTemplateContent: getEmailTemplateContent(
        state,
        fieldState.getNestedFieldValue('emailTemplate'),
      ),
      disableAttachments:
        !hasFeature(state, 'integration.email.gmail') &&
        !hasFeature(state, 'integration.email.outlook.365'),
      emailSignature: state.user.toJS().email_signature?.content || '',
    }),
    {},
  ),

  lifecycle({
    componentDidMount: function () {
      const { fieldState, fromEmailAddress, onChange } = this.props;

      if (fromEmailAddress) {
        onChange(fieldState.setNestedFieldValue('from', fromEmailAddress));
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const {
        emailSignature,
        fieldState,
        fromEmailAddress,
        onChange,
      } = nextProps;

      let nextFieldState = fieldState;

      if (
        fromEmailAddress &&
        fromEmailAddress !== this.props.fromEmailAddress
      ) {
        nextFieldState = nextFieldState.setNestedFieldValue(
          'from',
          fromEmailAddress,
        );
      }

      if (
        nextProps.currentEmailTemplateContent &&
        nextProps.currentEmailTemplateContent !==
          this.props.currentEmailTemplateContent
      ) {
        const subject = fieldState.getNestedField('subject');
        const notes = fieldState.getNestedField('notes');
        const notesContent = fromHtml(
          `${nextProps.currentEmailTemplateContent} ${emailSignature}` || '',
        );
        nextFieldState = nextFieldState
          .setNestedField(
            subject.setValue(nextProps.currentEmailTemplateSubject),
          )
          .setNestedField(
            notes.setValue(EditorState.createWithContent(notesContent)),
          );
      }
      // To avoid calling onChange w/ every render and potentially creating a memory leak
      // the handler is only called when the fieldState is being
      if (JSON.stringify(fieldState) !== JSON.stringify(nextFieldState)) {
        onChange(nextFieldState);
      }
    },
  }),

  withPropsRemoved(
    'currentEmailTemplateContent',
    'currentEmailTemplateSubject',
    'fromEmailAddress',
    'userActions',
    'shouldFetchUserOptions',
    'isFetchingUserOptions',
    'searchId',
  ),

  /**
   * Manages the visiblity state of the "CC" and "BCC" fields"
   */
  withStateHandlers(
    {
      ccVisible: false,
      bccVisible: false,
    },
    {
      showCc: () => () => ({ ccVisible: true }),
      showBcc: () => () => ({ bccVisible: true }),
    },
  ),
)(OutreachEmailField);

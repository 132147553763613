import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@thrivetrm/ui/components/Avatar';
import useQuickView from 'modules/quick-view/useQuickView';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { useSelector } from 'react-redux';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import ConnectionsButtonOverlay from 'modules/connections/components/ConnectionsButtonOverlay';

const ContactAvatarWithQuickVIew = ({
  avatarUrl,
  className,
  connectionsCount,
  contactId,
  contactName,
  shouldUseQuickView,
  size,
}) => {
  const { navigateTo } = useQuickView();
  const hasConnectionsFeature = useFeatureCheck('feature.connections_gate');
  const isFullAccessOrAdminUser = useSelector(state => isEmployeeUser(state));
  const isCrmUser = useSelector(isCrmUserSelector);

  const showContactInQuickView = () => {
    navigateTo(`/contacts/${contactId}`, { title: contactName });
  };

  const contactAvatar = (
    <Avatar
      hoverIcon={shouldUseQuickView ? 'flash' : null}
      image={avatarUrl}
      name={contactName}
      onClick={shouldUseQuickView ? showContactInQuickView : null}
      shape='circle'
      size={size}
    />
  );

  return (
    <div className={className}>
      {hasConnectionsFeature &&
      (isFullAccessOrAdminUser || isCrmUser) &&
      connectionsCount ? (
        <ConnectionsButtonOverlay
          connectionsCount={connectionsCount}
          contactId={contactId}
          contactName={contactName}
        >
          {contactAvatar}
        </ConnectionsButtonOverlay>
      ) : (
        contactAvatar
      )}
    </div>
  );
};
ContactAvatarWithQuickVIew.defaultProps = {
  size: 'small',
};

ContactAvatarWithQuickVIew.propTypes = {
  avatarUrl: PropTypes.string,
  className: PropTypes.string,
  connectionsCount: PropTypes.number,
  contactId: PropTypes.number.isRequired,
  contactName: PropTypes.string.isRequired,
  shouldUseQuickView: PropTypes.bool,
  size: PropTypes.string,
};

export default ContactAvatarWithQuickVIew;

import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import { useCreateContactMutation } from 'services/apiV1/contact';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Form from '@thrivetrm/ui/components/Form';
import Modal from '@thrivetrm/ui/components/Modal';
import ScrollableContainer from '@thrivetrm/ui/components/ScrollableContainer';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import CreateContactFormV2 from './CreateContactFormV2';

/**
 * Renders the Contact form in a modal
 */
const CreateContactModalV2 = ({ onCreated }) => {
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const dispatch = useDispatch();
  const [
    createContact,
    { error: createContactError, isLoading: isCreatingContact },
  ] = useCreateContactMutation();

  const handleSubmit = formState => {
    const payload = {
      contact: {
        first_name: formState.firstName,
        last_name: formState.lastName,
        email: formState.personalEmail,
        work_email: formState.workEmail,
        work_email_preferred: !formState.preferred,
      },
    };

    createContact({
      payload: payload,
    })
      .unwrap()
      .then(response => {
        closeModal();
        dispatch(toastSuccess('Successfully created contact'));
        onCreated?.(response?.contact.id);
      })
      .catch(() => {
        dispatch(toastError('Could not create the contact'));
      });
  };

  return (
    <>
      <ButtonSecondary
        className=' u-textColor-gray50'
        icon='person'
        onClick={openModal}
        size='medium'
      />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size='large'
        title='Create Contact'
      >
        <Form
          onSubmit={formState => {
            handleSubmit(formState);
          }}
        >
          <Modal.Body className='ModalBody--noClip'>
            <ScrollableContainer maxHeight='55vh'>
              <CreateContactFormV2
                createContactError={createContactError}
                onDuplicateSelect={contactId => {
                  onCreated?.(contactId);
                  closeModal();
                }}
              />
            </ScrollableContainer>
          </Modal.Body>

          <Modal.Footer>
            <ButtonPrimary
              className='u-marginRight-2'
              isOutline={true}
              label='Cancel'
              onClick={closeModal}
              size='medium'
            />
            <Form.SubmitButton
              isLoading={isCreatingContact}
              label='Create Contact'
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

CreateContactModalV2.propTypes = {
  onCreated: PropTypes.func,
};

export default CreateContactModalV2;

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Grid from '@thrivetrm/ui/components/Grid';
import Autocomplete from '@thrivetrm/ui/components/Autocomplete';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Form from '@thrivetrm/ui/components/Form';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import useFormValues from '@thrivetrm/ui/hooks/useFormValues';
import useUpdateFormField from '@thrivetrm/ui/hooks/useUpdateFormField';
import { useGetContactQuery } from 'services/apiV1/contact';
import routes from 'modules/routing/routes';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import ContactBaseballCard from 'modules/quick-view/contact-profile-panels/ContactBaseballCard';
import {
  useGetConnectionTypesQuery,
  CONNECTION_TYPE_GROUPS,
  getConnectionTypeLabel,
} from 'services/apiV1/connections';

import CreateContactModalV2 from 'modules/contacts/components/CreateContactModalV2';
import ContactCreateModalButton from 'modules/contacts/components/ContactCreateModalButton';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_PIPELINE } from 'modules/searches/constants';

export const FORM_LAYOUT = { wide: 'wide', narrow: 'narrow' };

export const REFERENCE_TYPES = {
  official: 'Official Reference',
  backchannel: 'Backchannel Reference',
};

const ReferenceFormV2 = ({
  contactId,
  formHeader,
  initialValues,
  isCreatingOrUpdating,
  layout,
  onCancel,
  onSubmit,
  searchId,
  submitButtonLabel,
}) => {
  const formState = useFormValues();
  const updateFormField = useUpdateFormField();
  const hasConnectionsFeature = useFeatureCheck('feature.connections_gate');
  const shouldUseV2ContactModal = useFeatureCheck(
    'development.create_contact_modal',
  );
  const isCrmUser = useSelector(isCrmUserSelector);
  const searchType = useSelector(state => getSearchType(state, searchId));
  const {
    data: contactDetails,
    isFetching: isLoadingContact,
  } = useGetContactQuery(contactId);

  const [referee, setReferee] = useState(initialValues?.referee ?? null);
  const {
    data: refereeDetails,
    isFetching: isLoadingReferee,
  } = useGetContactQuery(referee?.id, { skip: !referee?.id });

  const {
    data: connectionTypes,
    isFetching: isLoadingConnectionTypes,
  } = useGetConnectionTypesQuery();

  const referralConnectionTypeIds = connectionTypes
    ?.filter(({ group }) => group === 'Referrals')
    .map(({ id }) => id);

  useEffect(() => {
    // auto-populate the referral company and connection type if exist
    if (referee?.recent_connection_type_id) {
      updateFormField('connectionTypeId', referee?.recent_connection_type_id);
    }
    if (referee?.referral_company_id) {
      updateFormField('referralCompany', {
        id: referee?.referral_company_id,
        name: referee?.referral_company_name,
      });
    }
  }, [referee, formState.connectionTypeId]);

  const getConnectionTypesItems = () => {
    const items = [];
    CONNECTION_TYPE_GROUPS.forEach(connectionTypeGroup => {
      const groupItems = connectionTypes?.filter(
        item =>
          item.group === connectionTypeGroup &&
          item.name !== 'Relationship Manager',
      );
      if (groupItems?.length) {
        items.push(
          <SelectMenu.Heading key={connectionTypeGroup}>
            {connectionTypeGroup}
          </SelectMenu.Heading>,
        );
        groupItems.forEach(connectionTypeOption => {
          items.push(
            <SelectMenu.Item
              key={connectionTypeOption.id}
              value={connectionTypeOption.id}
            >
              {getConnectionTypeLabel(
                connectionTypeOption,
                contactDetails?.firstName,
              )}
            </SelectMenu.Item>,
          );
        });
      }
    });
    return items;
  };

  const isFormValid = Boolean(
    referee?.id && (formState.connectionTypeId || formState.relationship),
  );

  /* disables the connection type field when the user is editing a reference
  or a connection already exists between selected contacts */
  const shouldDisableConnectionField = Boolean(
    (initialValues.connectionTypeId &&
      initialValues.referee?.id === referee?.id) ||
      referee?.recent_connection_type_id,
  );

  const relatedSearchFieldLabel =
    searchType === TYPE_PIPELINE ? 'Related to Pipeline' : 'Related to Search';

  return (
    <LoadingContainer
      isLoading={
        isLoadingReferee || isLoadingConnectionTypes || isLoadingContact
      }
      size='medium'
    >
      <h4 className='u-margin-n'>{formHeader}</h4>
      <Grid>
        {layout === FORM_LAYOUT.wide ? (
          <Grid.Column className='u-padding-n' size={12}>
            <p className='u-margin-n u-marginBottom-8'>
              The Connection field will create a connection record between the
              two contacts
            </p>
          </Grid.Column>
        ) : null}
        <Grid.Column
          className={classNames('u-padding-n', {
            'u-paddingRight-8': layout === FORM_LAYOUT.wide,
            'u-paddingTop-8': layout === FORM_LAYOUT.narrow,
          })}
          size={layout === FORM_LAYOUT.wide ? 6 : 12}
        >
          {referee?.id && refereeDetails ? (
            <>
              <span className='u-fontSize-medium u-fontWeight-bold'>
                Contact
              </span>
              <ContactBaseballCard
                canRemoveCard={true}
                className='u-marginBottom-16'
                contactDetails={refereeDetails}
                onRemove={() => setReferee(null)}
                shouldShowAddress={false}
                shouldShowDocuments={false}
                shouldShowEmploymentDetails={false}
              />
            </>
          ) : (
            <div className='u-flex u-flexAlign-c'>
              <Autocomplete
                className='u-marginBottom-16'
                formatOption={({ company_name: companyName, name }) =>
                  companyName ? `${name} (${companyName})` : name
                }
                inputWidth='full'
                isRequired={true}
                label='Select a contact'
                onClear={() => setReferee(null)}
                onSelect={newReferee => setReferee(newReferee)}
                placeholder='Select Contact...'
                selectedValueDisplayPropertyFallbacks={['name', 'email']}
                url={query =>
                  routes.api_v1_autocomplete_query({
                    query: {
                      query: {
                        // Exclude the current contact from search results
                        excluded_ids: contactId,
                        resource: 'contact',
                        term: query,
                        connected_contact_id: contactId,
                      },
                    },
                  })
                }
                value={referee}
              />
              <div className='u-marginTop-4'>
                {shouldUseV2ContactModal ? (
                  <CreateContactModalV2
                    onCreated={newContactId => setReferee({ id: newContactId })}
                  />
                ) : (
                  <ContactCreateModalButton
                    btnClassName='Button Button--hasIconOnly Button--secondary u-paddingHorizontal-12'
                    onCreated={newContactId => setReferee({ id: newContactId })}
                  />
                )}
              </div>
            </div>
          )}
        </Grid.Column>
        <Grid.Column
          className={classNames('u-padding-n', {
            'u-paddingLeft-8': layout === FORM_LAYOUT.wide,
          })}
          size={layout === FORM_LAYOUT.wide ? 6 : 12}
        >
          {hasConnectionsFeature ? (
            <Form.SelectMenu
              className='u-marginBottom-16'
              initialValue={initialValues.connectionTypeId}
              inputWidth='full'
              isDisabled={shouldDisableConnectionField}
              label={
                shouldDisableConnectionField
                  ? 'Connection -  a connection already exists between these two contacts'
                  : 'Connection'
              }
              name='connectionTypeId'
              placeholder='Select a connection type'
              rules={{
                required: {
                  value: true,
                  message: 'Selecting a connection type is required',
                },
              }}
            >
              {getConnectionTypesItems()}
            </Form.SelectMenu>
          ) : (
            <Form.TextInput
              className='u-marginBottom-16'
              initialValue={initialValues.relationship}
              inputWidth='full'
              label='Relationship'
              name='relationship'
              placeholder='Enter relationship'
              rules={{
                required: {
                  value: true,
                  message: 'Relationship is required',
                },
              }}
            />
          )}
          <Form.AdvancedSelect
            className='u-marginBottom-16'
            initialValue={initialValues.referenceType}
            isClearable={true}
            label='Reference Type'
            name='referenceType'
            options={Object.keys(REFERENCE_TYPES).map(referenceType => ({
              label: REFERENCE_TYPES[referenceType],
              value: referenceType,
            }))}
            placeholder='Select Reference Type'
          />
          <Form.FieldDependentContent
            shouldRender={fields =>
              referralConnectionTypeIds?.includes(fields.connectionTypeId)
            }
          >
            <Form.Autocomplete
              className='u-marginBottom-16'
              formatOption={option => option.name}
              initialValue={initialValues.referralCompany}
              inputWidth='full'
              label='Referral Company'
              name='referralCompany'
              onSelect={() => {}}
              placeholder='Select referral company'
              url={query =>
                routes.api_v1_autocomplete_query({
                  query: {
                    query: {
                      resource: 'company',
                      term: query,
                    },
                  },
                })
              }
            />
          </Form.FieldDependentContent>
          {isCrmUser ? null : (
            <Form.Autocomplete
              className='u-marginBottom-16'
              formatOption={option => option.name}
              initialValue={initialValues.relatedSearch}
              inputWidth='full'
              label={relatedSearchFieldLabel}
              name='relatedSearch'
              onSelect={() => {}}
              placeholder={relatedSearchFieldLabel}
              url={query =>
                routes.api_v1_autocomplete_query({
                  query: {
                    query: {
                      resource:
                        searchType === TYPE_PIPELINE ? 'pipeline' : 'search',
                      term: query,
                    },
                  },
                })
              }
            />
          )}
          <Form.RichTextField
            className='u-block u-marginBottom-16'
            initialValue={initialValues.description}
            label='Reference Notes'
            name='description'
            placeholder='Enter reference notes...'
          />
        </Grid.Column>
      </Grid>
      <div className='u-flex u-flexJustify-r'>
        <ButtonPrimary
          className='u-marginRight-2'
          isOutline={true}
          label='Cancel'
          onClick={onCancel}
          size='small'
        />
        <ButtonPrimary
          isDisabled={!isFormValid || isCreatingOrUpdating}
          isLoading={isCreatingOrUpdating}
          label={submitButtonLabel}
          onClick={() =>
            onSubmit({
              contactName: contactDetails?.firstName,
              referee: referee,
              ...formState,
            })
          }
          size='small'
        />
      </div>
    </LoadingContainer>
  );
};

ReferenceFormV2.defaultProps = {
  formHeader: 'Add a Reference',
  isCreatingOrUpdating: false,
  layout: FORM_LAYOUT.narrow,
  submitButtonLabel: 'Add Reference',
};

ReferenceFormV2.propTypes = {
  contactId: PropTypes.number,
  formHeader: PropTypes.string,
  initialValues: PropTypes.shape({
    connectionTypeId: PropTypes.number,
    description: PropTypes.string,
    referee: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    referenceType: PropTypes.oneOf(Object.keys(REFERENCE_TYPES)),
    referralCompany: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    relatedSearch: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    relationship: PropTypes.string,
  }),
  isCreatingOrUpdating: PropTypes.bool,
  layout: PropTypes.oneOf(Object.values(FORM_LAYOUT)),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  searchId: PropTypes.number,
  submitButtonLabel: PropTypes.string,
};

export default ReferenceFormV2;

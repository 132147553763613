import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import mapCompanyIdToCompany from '../mapCompanyIdToCompany';
import withCompanyFetched from '../withCompanyFetched';

export const CompanyLogo = ({ src, thumbnail, ...props }) => (
  <div
    className={classnames('CompanyLogo', {
      'CompanyLogo--thumbnail': thumbnail,
    })}
  >
    {src ? (
      <img
        alt='Company Logo'
        src={src}
        {...props}
        className='CompanyLogo__image'
        role='presentation'
      />
    ) : (
      <i className='CompanyLogo__placeholder fa fa-building fa-3x' />
    )}
  </div>
);

CompanyLogo.propTypes = {
  src: PropTypes.string,
  thumbnail: PropTypes.bool,
};

export default compose(
  setDisplayName('CompanyLogo(enhanced)'),
  setPropTypes({
    /**
     * The ID of the company to show the logo for.
     */
    companyId: requiredIf(PropTypes.number, props => !props.src),

    /**
     * True to render a thumbnail, false (default) to render the full image
     */
    thumbnail: PropTypes.bool.isRequired,

    /**
     * Sometimes we are sending in the src as a prop, and other times we are
     * getting the src from the company
     */
    src: PropTypes.string,
  }),
  defaultProps({
    thumbnail: false,
  }),
  withCompanyFetched,
  mapCompanyIdToCompany,
  mapProps(({ company, src, thumbnail }) => ({
    alt: company && company.get('name'),
    thumbnail: thumbnail,
    src: src || (company && company.get('logo_url')),
  })),
)(CompanyLogo);

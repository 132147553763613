import PropTypes from 'prop-types';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import EntitySelect from 'modules/entities/components/EntitySelect';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import withTenantOptionsEntityIdsFetched from './withTenantOptionsEntityIdsFetched';

/**
 * A component for rendering a select/dropdown field from a set of tenant option IDs.
 */
export default compose(
  setDisplayName('TenantOptionsSelectField'),
  setPropTypes({
    /**
     * A FieldState with an underlying value that is an array of target market IDs.
     */
    fieldState: fieldStatePropType.isRequired,

    /**
     * Called when the fieldState is changed by the user adding/removing target markets.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * When false, the component will not render when there are no options provided.
     */
    renderIfNoOptions: PropTypes.bool.isRequired,

    /**
     * The schema that this tag select field will be displaying/selecting.
     */
    schema: PropTypes.shape({
      key: PropTypes.string.isRequired,
    }).isRequired,

    /**
     * An optional property name on the entity to sort the list on.
     */
    sortProperty: PropTypes.string,

    /**
     * An optional selector function that can be used for sorting the list of entities.
     * This is called with the current state and the entityId, and should return a value that
     * can be used by Immutable.List.sortBy.
     * `sortSelector(state, id)`
     */
    sortSelector: PropTypes.func,
  }),
  setStatic('createFieldState', FieldState.create),
  defaultProps({
    renderIfNoOptions: true,
  }),
  withTenantOptionsEntityIdsFetched(),

  // If `renderIfNoOptions` is true, don't don't render the component if there are no entities
  // and we aren't currently loading.
  branch(
    ({ entityIds, isLoading, renderIfNoOptions }) =>
      !renderIfNoOptions && !isLoading && (!entityIds || !entityIds.length),
    renderNothing,
  ),

  // Wrap in a formgroup and provide conversion between our fieldState and it's underlying value.
  withFormGroup,
  asField(),
)(EntitySelect);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import jobApplicationSchema from '../schema';

/**
 * Gets the created_date from a job application record.
 * @param {Object} state
 * @param {Number} id The job application ID
 * @return {?Date} The created_at date.
 */
export default (state, jobApplicationId) =>
  getEntityProperty(
    state,
    jobApplicationSchema.key,
    jobApplicationId,
    'created_at',
  );

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FieldState from 'modules/forms/FieldState';
import EmailField from '../../../../components/forms/EmailField';

/**
 * A field for entering preferences for generating a compensation report.
 */
class CompensationReportField extends Component {
  static createFieldState(name = 'compensationReport', currentUserEmail) {
    return FieldState.createNested(name, [
      EmailField.createFieldState('emails', currentUserEmail),
    ]);
  }

  handleFieldChange = nestedFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(nestedFieldState));
  };

  render() {
    const { emailWrapperClassName, fieldState } = this.props;

    return (
      <div className='react-select'>
        <h2>Recipients</h2>
        <EmailField
          clearable={false}
          fieldState={fieldState.getNestedField('emails')}
          label='Emails*'
          onChange={this.handleFieldChange}
          wrapperClassName={emailWrapperClassName}
        />
      </div>
    );
  }
}

CompensationReportField.propTypes = {
  emailWrapperClassName: PropTypes.string,
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CompensationReportField;

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import PrimaryAddressSelectField from 'modules/addresses/components/PrimaryAddressSelectField';
import withAddressesListFetched from 'modules/addresses/components/withAddressesListFetched';
import withCompanyUpdateForm from '../withCompanyUpdateForm';
import CompanyAddressModalButton from './CompanyAddressModalButton';

/**
 * Renders a form for editing a company's primary address.
 */
const CompanyPrimaryAddressForm = ({
  // props provided by `withCompanyUpdateForm`
  formState,
  onAddressAdded,
  onCancel,

  // Props passed through/expected from parent component.
  onFieldStateChange,
  onSubmit,
  submitButtonLabel,

  // Any other props are passed through to the PrimaryAddressSelectField component.
  ...fieldProps
}) => (
  <div className='CompanyPrimaryAddressForm'>
    <CompanyAddressModalButton
      className='pull-right'
      companyId={fieldProps.parentId}
      onSaved={onAddressAdded}
      primaryAddressId={fieldProps.addressId}
    >
      <i className='fa fa-plus-circle' />
      <span> Add Address</span>
    </CompanyAddressModalButton>
    <Form
      className='CompanyPrimaryAddressForm__form'
      formState={formState}
      onSubmit={onSubmit}
    >
      <PrimaryAddressSelectField
        {...fieldProps}
        fieldState={formState.getFieldState()}
        onChange={onFieldStateChange}
      />
      <FormErrorMessage formState={formState} />
      <div className='Form__footer'>
        <CancelButton onClick={onCancel} />
        <SubmitButton formState={formState} onClick={onSubmit}>
          {submitButtonLabel}
        </SubmitButton>
      </div>
    </Form>
  </div>
);

CompanyPrimaryAddressForm.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  onAddressAdded: PropTypes.func,

  /**
   * Called when the modal should be closed.
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * The label for the submit button.
   */
  submitButtonLabel: PropTypes.node,

  /**
   * The text to display in the header.
   */
  title: PropTypes.node,
};

CompanyPrimaryAddressForm.defaultProps = {
  submitButtonLabel: 'Save',
  title: null,
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('CompanyPrimaryAddressForm(enhanced))'),
  setPropTypes({
    ...withCompanyUpdateForm.propTypes,

    /**
     * Called when the record was saved.
     */
    onSaved: PropTypes.func.isRequired,

    /**
     * Called when the cancel button is clicked. Called with the current formState
     */
    onCancel: PropTypes.func.isRequired,
  }),

  withProps(({ onSaved }) => ({
    onAddressAdded: onSaved,
  })),

  withCompanyUpdateForm(PrimaryAddressSelectField),

  // Handle fetching of the addresses and pulling out the IDs as `addressIds`
  withAddressesListFetched,
  withProps(({ addressesList }) => ({
    addressIds: addressesList && addressesList.get('ids'),
    isFetching: addressesList && addressesList.getIn(['_meta', 'isFetching']),
  })),
  withHandlers({
    onCancel: ({ formState, onCancel }) => () => onCancel(formState),
  }),

  /**
   * Prevent props from being passed on to the modal
   */
  /* eslint-disable no-unused-vars */
  mapProps(({ companyId, onFormStateChange, onResetFormState, ...rest }) => ({
    ...rest,
  })),
  /* eslint-enable no-unused-vars */
)(CompanyPrimaryAddressForm);

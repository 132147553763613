import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  branch,
  mapProps,
  renderComponent,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import { SEARCH_TYPES, TYPE_PIPELINE } from 'modules/searches/constants';
import TagTableRow from './TagTableRow';

/**
 * Renders a table of tags
 */
const TagTable = ({
  disabledTagIds,
  onCheckedChange,
  searchType,
  selectedTagIds,
  tagIds,
}) => (
  <table className='Table TagTable'>
    <thead>
      <tr>
        <th className='TagTable__tag-column'>Tag</th>
        <th colSpan={2}>Color & Label</th>
        {searchType === TYPE_PIPELINE ? null : (
          <>
            <th>Created By</th>
            <th aria-label='actions' />
          </>
        )}
      </tr>
    </thead>
    <tbody>
      {tagIds.map(tagId => (
        <TagTableRow
          checked={selectedTagIds.includes(tagId)}
          disabled={disabledTagIds.includes(tagId)}
          key={tagId}
          loading={disabledTagIds.includes(tagId)}
          onCheckedChange={onCheckedChange}
          searchType={searchType}
          tagId={tagId}
          value={tagId}
        />
      ))}
    </tbody>
  </table>
);

TagTable.propTypes = {
  /**
   * The tag IDs of the tags that are in a disabled state.
   */
  disabledTagIds: PropTypes.arrayOf(PropTypes.number.isRequired),

  /**
   * Called when a checkbox's checked value is changed.
   */
  onCheckedChange: PropTypes.func.isRequired,

  /**
   * The type of search (talent pool or job search or pipeline)
   */
  searchType: PropTypes.oneOf(SEARCH_TYPES),
  /**
   * The tag IDs of the tags that are currently assigned.
   */
  selectedTagIds: PropTypes.arrayOf(PropTypes.number.isRequired),

  /**
   * The list of tag IDs to render
   */
  tagIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
};

export default compose(
  setDisplayName('TagTable(enhanced)'),
  setPropTypes({
    isFetching: PropTypes.bool,
  }),
  branch(({ isFetching }) => isFetching, renderComponent(LoadingIndicator)),
  mapProps(({ isFetching: _, ...props }) => ({ ...props })),
)(TagTable);

/* eslint-disable camelcase */
// ^ accommodate API response format
import React, { useState } from 'react';
import ContactAvatar from 'modules/contacts/components/ContactAvatar';
import ContactLink from 'modules/contacts/components/ContactLink';
import classnames from 'classnames';
import Currency from '@thrivetrm/ui/components/Currency';
import RelativeTimeLabel from 'modules/datetime/components/RelativeTimeLabel';
import Date from '@thrivetrm/ui/components/Date';
import TagList from 'modules/core/components/TagList';
import Integer from '@thrivetrm/ui/components/Integer';
import CurrencyRange from '@thrivetrm/ui/components/CurrencyRange';
import Percentage from '@thrivetrm/ui/components/Percentage';
import PercentageRange from '@thrivetrm/ui/components/PercentageRange';
import LocationName from 'modules/addresses/components/LocationName';
import { fromJS } from 'immutable';
import RecommendPopoverIconLink from './UpdateRecommendCandidate/RecommendPopoverIconLink';
import ContactIconLinkList from '../../../components/contact/ContactIconLinkList';
import { NOT_A_FIT_LABEL, THRIVE_AI } from '../constants';

import recommendedCandidatePropType from '../recommendedCandidatePropType';

const RecommendationListItem = ({ result }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { contact } = result;
  const {
    compensation,
    preferred_industry_categories,
    preferred_sectors,
    primary_company,
  } = result.contact;
  return (
    <div className='RecommendationListItem' key={result.id}>
      <div className='RecommendationListItem__core RecommendationListItem__coreContent'>
        <div className='RecommendationListItem__coreDetails'>
          <div className='RecommendationListItem__coreIconStrip'>
            <ContactAvatar
              size={30}
              url={contact.avatar_url || null}
              useLegacyStyles={true}
            />
          </div>

          <div className='RecommendationListItem__primaryDetails'>
            {contact.full_name && (
              <span className='RecommendationListItem__contactName'>
                {contact.full_name}
              </span>
            )}
            <div>
              {contact.primary_title && (
                <div className='RecommendationListItem__positionName u-truncate'>
                  {contact.primary_title}
                </div>
              )}
              {primary_company && primary_company.name && (
                <div className='RecommendationListItem__companyName u-truncate'>
                  {primary_company.name}
                </div>
              )}
            </div>
            <div>
              <div className='u-flex u-truncate'>
                <div className='RecommendationListItem__locationName'>
                  <LocationName iconPosition='right' name={contact.location} />{' '}
                </div>
                {contact.preferred_relocate_internationally ? (
                  <div className='RecommendationListItem__willingToRelocate u-truncate'>
                    (willing to relocate)
                  </div>
                ) : null}
              </div>
              <div className='u-flex'>
                <ContactIconLinkList contact={fromJS({ data: contact })} />
                <div className='RecommendationListItem__contactProfileLink'>
                  <ContactLink
                    contactId={contact.id}
                    target='_blank'
                    useDefaultLinkBehavior={true}
                  >
                    <i className='fa fa-lg fa-user' />
                  </ContactLink>
                </div>
              </div>
            </div>
          </div>
          <div className='RecommendationListItem__secondaryDetails'>
            <div className='RecommendationListItem__searchStageName'>
              {result.recommended_by === THRIVE_AI ? 'Recommended ' : 'Added '}
              <RelativeTimeLabel time={result.created_at} /> by{' '}
              {result.recommended_by}
            </div>
            <RecommendPopoverIconLink
              candidacyId={result.id.toString()}
              notAFit={result.stage === NOT_A_FIT_LABEL}
              searchId={result.search_id.toString()}
            />
          </div>
        </div>
        <div className='RecommendationListItem__status u-flex u-flexJustify-spaceBetween'>
          <button
            className='btn btn-link'
            onClick={() => setIsExpanded(prevIsExpanded => !prevIsExpanded)}
            type='button'
          >
            View More Details
            <i
              className={classnames('fa', 'RecommendationListItem__pointer', {
                'fa-angle-down': !isExpanded,
                'fa-angle-up': isExpanded,
              })}
            />
          </button>

          {result.score ? (
            <div className='RecommendationListItem__score'>
              <span className='RecommendationListItem__scoreLabel'>Score:</span>{' '}
              {result.score}
            </div>
          ) : null}
        </div>
        <div
          className={classnames({
            'RecommendationListItem--collapsed': !isExpanded,
            'RecommendationListItem--expanded': isExpanded,
          })}
        >
          {isExpanded ? (
            <div>
              <div className='RecommendationListItem__status RecommendationListItem__dataRecency'>
                <span className='RecommendationListItem__dataTitle'>
                  <strong> Data Recency</strong>
                </span>
                <div className='RecommendationListItem__recencyInfo'>
                  <span className='RecommendationListItem__contactCreated'>
                    Contact Created:{' '}
                    <Date date={contact.created_at} format='dateTime' />
                  </span>
                  <span>
                    {' '}
                    Last Updated:{' '}
                    <Date date={contact.updated_at} format='dateTime' />
                  </span>
                </div>
              </div>

              {preferred_industry_categories.length > 0 ||
              preferred_sectors.length > 0 ||
              contact.candidacies_count ||
              contact.highest_stage_reached ||
              (compensation &&
                (compensation.base ||
                  compensation.bonus ||
                  compensation.equity)) ||
              contact.preferred_base_minimum ||
              contact.preferred_base_maximum ||
              contact.preferred_bonus_minimum ||
              contact.preferred_bonus_maximum ||
              contact.preferred_equity_minimum ||
              contact.preferred_equity_maximum ||
              contact.preferred_total_compensation ? (
                <div className='RecommendationListItem__status RecommendationListItem__fitQuality'>
                  <span className='RecommendationListItem__dataTitle'>
                    <strong>Fit / Quality</strong>
                  </span>
                  <div className='RecommendationListItem__fitQuality'>
                    {preferred_industry_categories.length > 0 && (
                      <div className='RecommendationListItem__relIndustries'>
                        <span className='RecommendationListItem__label'>
                          <strong>Relevant Industries:</strong>
                        </span>
                        <TagList
                          tags={preferred_industry_categories.map(
                            category => category.name,
                          )}
                        />
                      </div>
                    )}

                    {preferred_sectors.length > 0 && (
                      <div className='RecommendationListItem__relSectors'>
                        <span className='RecommendationListItem__label'>
                          <strong>Relevant Sectors:</strong>
                        </span>
                        <TagList tags={preferred_sectors} />
                      </div>
                    )}

                    {contact.candidacies_count && (
                      <div className='RecommendationListItem__noCand '>
                        <span className='RecommendationListItem__label'>
                          <strong>
                            Number of Candidacies (over all time):
                          </strong>
                        </span>
                        <span className='RecommendationListItem__fitContent RecommendationListItem__value'>
                          <Integer value={contact.candidacies_count} />
                        </span>
                      </div>
                    )}

                    {contact.highest_stage_reached && (
                      <div className='RecommendationListItem__stage'>
                        <span className='RecommendationListItem__label'>
                          <strong>
                            Highest Stage Reached (across all searches):
                          </strong>
                        </span>
                        <span className='RecommendationListItem__fitContent RecommendationListItem__value'>
                          {contact.highest_stage_reached}
                        </span>
                      </div>
                    )}

                    {compensation &&
                    (compensation.base ||
                      compensation.bonus ||
                      compensation.equity) ? (
                      <div className='RecommendationListItem__currentCompensation'>
                        <div className='RecommendationListItem__label'>
                          <strong>Current Compensation:</strong>
                        </div>
                        <div className='RecommendationListItem__fitContent RecommendationListItem__compensation RecommendationListItem__value'>
                          {contact.preferred_currency &&
                            compensation &&
                            compensation.base && (
                              <div>
                                Base:{' '}
                                <Currency
                                  currency={contact.preferred_currency}
                                  value={compensation.base}
                                />
                              </div>
                            )}

                          {contact.preferred_currency &&
                            compensation &&
                            compensation.bonus && (
                              <div>
                                Bonus:{' '}
                                <Currency
                                  currency={contact.preferred_currency}
                                  value={compensation.bonus}
                                />
                              </div>
                            )}

                          {compensation.equity ? (
                            <div>
                              Equity: <Percentage value={compensation.equity} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    {contact.preferred_base_minimum ||
                    contact.preferred_base_maximum ||
                    contact.preferred_bonus_minimum ||
                    contact.preferred_bonus_maximum ||
                    contact.preferred_equity_minimum ||
                    contact.preferred_equity_maximum ||
                    contact.preferred_total_compensation ? (
                      <div className='RecommendationListItem__desiredCompensation'>
                        <div className='RecommendationListItem__label'>
                          <strong>Desired Compensation:</strong>
                        </div>
                        <div className='RecommendationListItem__fitContent RecommendationListItem__compensation RecommendationListItem__value'>
                          {contact.preferred_currency &&
                            (contact.preferred_base_minimum ||
                              contact.preferred_base_maximum) && (
                              <div>
                                Base:{' '}
                                <CurrencyRange
                                  maxValue={contact.preferred_base_maximum}
                                  minValue={contact.preferred_base_minimum}
                                />
                              </div>
                            )}

                          {contact.preferred_currency &&
                            (contact.preferred_bonus_minimum ||
                              contact.preferred_bonus_maximum) && (
                              <div>
                                Bonus:{' '}
                                <CurrencyRange
                                  maxValue={contact.preferred_bonus_maximum}
                                  minValue={contact.preferred_bonus_minimum}
                                />
                              </div>
                            )}

                          {(contact.preferred_equity_minimum ||
                            contact.preferred_equity_maximum) && (
                            <div>
                              Equity:{' '}
                              <PercentageRange
                                maxValue={contact.preferred_equity_maximum}
                                minValue={contact.preferred_equity_minimum}
                              />
                            </div>
                          )}

                          {contact.preferred_currency &&
                            contact.preferred_total_compensation && (
                              <div>
                                Total Compensation:{' '}
                                <Currency
                                  currency={contact.preferred_currency}
                                  value={contact.preferred_total_compensation}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

RecommendationListItem.propTypes = {
  result: recommendedCandidatePropType,
};
RecommendationListItem.defaultProps = {
  result: null,
};
export default RecommendationListItem;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose, setStatic } from 'recompose';

import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import { useGetNetworksQuery } from 'services/apiV1/networks';
import MultiSelect from '../../../components/forms/multiselect/MultiSelect';

export const NetworkMultiSelect = ({ noResultsText, type, ...props }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data, isFetching: isLoadingNetworks } = useGetNetworksQuery(type);
  const networks = [
    ...(data?.user_networks || []),
    ...(data?.public_networks || []),
    ...(data?.private_network || []),
  ];

  const filteredNetworks =
    searchTerm && networks
      ? networks.filter(network =>
          stringUtils.toSortableString(network?.name).includes(searchTerm),
        )
      : networks;
  const handleSearchValueChange = newSearchTerm => {
    setSearchTerm(stringUtils.toSortableString(newSearchTerm));
  };

  return (
    <MultiSelect
      {...props}
      isLoading={isLoadingNetworks}
      noSearchResultsText={isLoadingNetworks ? 'Loading...' : noResultsText}
      noValueText='No networks selected'
      onSearchValueChange={handleSearchValueChange}
      options={filteredNetworks || []}
      showWithoutSearching={true}
    />
  );
};

NetworkMultiSelect.propTypes = {
  noResultsText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

NetworkMultiSelect.defaultProps = {
  noResultsText: 'No matching networks found',
};

export default compose(
  setStatic('createFieldState', (name, value, validator) =>
    FieldState.create(name, value ? [].concat(value) : [], validator, values =>
      values.map(network => network.id || network),
    ),
  ),
  withFormGroup,
  asField(),
)(NetworkMultiSelect);

import PropTypes from 'prop-types';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import IntroductionReportField from './IntroductionReportField';
import withTransactionTrackedForm from '../forms/withTransactionTrackedForm';

const createRootFieldState = props =>
  IntroductionReportField.createFieldState(
    'introductionReport',
    props.currentUserEmail,
  );

const handleSubmit = (formState, props) => {
  const transactionId = uniqueId();
  const {
    dateRange: { endDate, startDate },
    ...values
  } = formState.getFieldValue();

  props.createIntroductionsReport({
    startDate: startDate,
    endDate: endDate,
    ...values,
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

const IntroductionReportForm = withTransactionTrackedForm(
  createRootFieldState,
  handleSubmit,
  {
    formClassName: 'introduction-report-form',
    formActionProps: { submitLabel: 'Send Report' },
    omitProps: ['reportsActions', 'formState'],
  },
)(IntroductionReportField);

IntroductionReportForm.propTypes = {
  createIntroductionsReport: PropTypes.func.isRequired,
};

export default IntroductionReportForm;

import PropTypes from 'prop-types';
import React from 'react';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';
import ContactIconLink from './ContactIconLink';

/**
 * Renders a contact icon link that displays a popover with the link
 * information instead of linking directly to the underlying href.
 *
 * Optionally, the popover can be displayed on hover as well.
 *
 * If the `popoverTitle` is provided, the overlay will have
 * a title with that value. If there are child components, they
 * will be rendered as the popover content. Otherwise a link
 * will be rendered as the body with the text that is specified
 * by the `linkTitle` property -- or if that is not provided,
 * the `href` itself.
 */
const ContactPopoverIconLink = ({
  children,
  componentId,
  getScrollContainer,
  getTargetRef,
  handleClick,
  handleHidePopover,
  href,
  isPopoverShown,

  // withScrollContainerRef props
  linkTitle,
  onClick: _onClick,
  popoverTitle,

  // Exclude these items from being included in `otherProps` and
  // being passed on to the ContactIconLink
  setTargetRef,

  ...otherProps
}) => (
  <span className='contact-popover-icon-link' ref={setTargetRef}>
    <ContactIconLink href={href} onClick={handleClick} {...otherProps} />
    <Overlay
      container={getScrollContainer}
      onHide={handleHidePopover}
      placement='bottom'
      rootClose={true}
      show={isPopoverShown}
      target={getTargetRef}
    >
      <Popover id={componentId} title={popoverTitle}>
        {children || <a href={href}>{linkTitle || href}</a>}
      </Popover>
    </Overlay>
  </span>
);

ContactPopoverIconLink.propTypes = {
  /**
   * Props provided by withScrollContainerRef (getScrollContainer, getTargetRef, setTargetRef)
   */
  ...withScrollContainerRef.propTypes,

  /**
   * Optional content to display in the popover. If not supplied,
   * the link itself will be used (optionally using the `linkTitle` prop
   * as the link text, if provided).
   */
  children: PropTypes.node,

  /**
   * A unique ID for this instance of this component (from 'withComponentId' HOC)
   */
  componentId: PropTypes.string.isRequired,

  /**
   * Called when the link is clicked (to toggle the display of the popover)
   */
  handleClick: PropTypes.func.isRequired,

  /**
   * Called when the popover should be hidden
   */
  handleHidePopover: PropTypes.func.isRequired,

  /**
   * The URL the link should navigate to. If no URL is provided
   * and no `show` prop is provided, the component will not be
   * rendered at all.
   */
  href: PropTypes.string,

  /**
   * Whether the popover should be rendered.
   */
  isPopoverShown: PropTypes.bool.isRequired,

  /**
   * The title of the link, displayed in the popover body as the link
   * itself. If not provided, the href will be shown.
   */
  linkTitle: PropTypes.string,

  /**
   * The title to show in the popover header. If not provided, no header
   * will be shown.
   */
  popoverTitle: PropTypes.string,
};

export default compose(
  setDisplayName('ContactPopoverIconLink(enhanced)'),
  setPropTypes({
    /**
     * Optional callback called when the link is clicked.
     */
    onClick: PropTypes.func,

    childen: ContactPopoverIconLink.propTypes.children,
    href: ContactPopoverIconLink.propTypes.href,
    linkTitle: ContactPopoverIconLink.propTypes.linkTitle,
    popoverTitle: ContactPopoverIconLink.propTypes.popoverTitle,
  }),
  withStateHandlers(
    { isPopoverShown: false },
    {
      handleClick: ({ isPopoverShown }, { onClick }) => event => {
        event.preventDefault();

        if (onClick) {
          onClick(event);
        }

        return { isPopoverShown: !isPopoverShown };
      },
      handleHidePopover: () => () => ({ isPopoverShown: false }),
    },
  ),
  withComponentId(),
  withScrollContainerRef,
)(ContactPopoverIconLink);

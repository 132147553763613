import React from 'react';
import PropTypes from 'prop-types';
import { compose, setStatic, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import RadioSelectField from 'modules/forms/components/RadioSelectField';
import getRecruiterName from 'modules/tenant/selectors/getRecruiterName';
import getClientTypeName from 'modules/tenant/selectors/getClientTypeName';

export const InterviewLabelRadioField = ({
  clientTypeName,
  hideLabel,
  recruiterName,
  ...otherProps
}) => (
  <RadioSelectField
    {...otherProps}
    className='interviews--interview-type-field'
    label={!hideLabel && 'Interview Type'}
    options={[
      {
        label: `${recruiterName} Interview`,
        value: recruiterName,
      },
      {
        label: `${clientTypeName} Interview`,
        value: 'Client',
      },
    ]}
  />
);

InterviewLabelRadioField.propTypes = {
  clientTypeName: PropTypes.string.isRequired,

  /** Whether to display the "Interview Type" label */
  hideLabel: PropTypes.bool,

  recruiterName: PropTypes.string.isRequired,
};

InterviewLabelRadioField.defaultProps = {
  hideLabel: false,
};

export default compose(
  setDisplayName('InterviewLabelRadioField(enhanced)'),
  setStatic('createFieldState', RadioSelectField.createFieldState),
  connect(
    state => ({
      // Needed for wording of the InterviewType component label.
      recruiterName: getRecruiterName(state),
      clientTypeName: getClientTypeName(state),
    }),
    {},
  ),
)(InterviewLabelRadioField);

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, defaultProps, setDisplayName, setPropTypes } from 'recompose';
import NoteListItem from './NoteListItem';
import withNotesSortedByMostRecent from './withNotesSortedByMostRecent';

/**
 * Renders a list of notes, with the ability to edit or delete them
 * inline if the user has permission (based on `permissions.edit` and `permissions.delete`
 * values of the note item).
 */
export const NoteList = ({
  candidacyId,
  limit,
  notes,
  pinnedNoteId,
  readOnly,
  renderRelatedSearches,
  searchId,
}) => (
  <ul className='list-generic notes--note-list'>
    {(limit ? notes.take(limit) : notes)
      .map(note => (
        <NoteListItem
          candidacyId={candidacyId}
          key={note.get('id')}
          note={note}
          pinnedNoteId={pinnedNoteId}
          readOnly={readOnly}
          renderRelatedSearches={renderRelatedSearches}
          searchId={searchId}
        />
      ))
      .toArray()}
  </ul>
);

NoteList.defaultProps = {
  readOnly: false,
  renderRelatedSearches: true,
};

NoteList.propTypes = {
  candidacyId: PropTypes.number,

  limit: PropTypes.number,

  /**
   * The notes to be rendered
   */
  notes: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      noted_on: PropTypes.string,
      created_at: PropTypes.string,
    }),
  ).isRequired,

  /**
   * If note is pinned, sets pinned note's ID.
   */
  pinnedNoteId: PropTypes.number,

  /**
   * When true, the underlying list items cannot be edited/deleted, regardless
   * of the note record permissions.
   */
  readOnly: PropTypes.bool,

  /**
   * True to render a list of searches related to the note, false to exclude them.
   */
  renderRelatedSearches: PropTypes.bool,

  /**
   * ID of related search note is pinned to.
   */
  searchId: PropTypes.number,
};

export default compose(
  setDisplayName('NoteList(sorted)'),
  setPropTypes(NoteList.propTypes),
  defaultProps(NoteList.defaultProps),
  withNotesSortedByMostRecent,
)(NoteList);

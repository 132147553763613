import { convertToCamelCase } from 'modules/core/jsonUtils';
import apiV1 from './index';

const searches = apiV1.injectEndpoints({
  endpoints: builder => ({
    getSearch: builder.query({
      query: searchId => ({
        url: `/searches/${searchId}`,
        method: 'GET',
      }),
      transformResponse: response => convertToCamelCase(response?.search),
    }),
  }),
});

export const { useGetSearchQuery } = searches;

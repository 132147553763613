import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import {
  makeSelectFilterSetIdsInSection,
  removeFilterSetAndSyncView,
  addFilterSetToView,
} from '../../recordIndexSlice';

const FilterSetSection = ({ name, query }) => {
  const dispatch = useDispatch();
  // Use redux docs recommended pattern for selector with different arguments
  // https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
  const selectFilterSetIdsInSection = useMemo(makeSelectFilterSetIdsInSection, [
    name,
  ]);

  const filterSetIds = useSelector(state =>
    selectFilterSetIdsInSection(state, name),
  );

  const selectedFilterSetIds = useSelector(
    state => state.recordIndex.savedView.filters.selectedFilterSetIds,
  );

  const filterIdsToShow = filterSetIds.filter(filterSetId =>
    filterSetId.toLowerCase().includes(query.toLowerCase()),
  );

  if (filterIdsToShow.length === 0) {
    return null;
  }

  return (
    <div>
      <p className='u-marginTop-12 u-fontWeight-bold u-fontSize-small u-textColor-gray60 u-borderBottom'>
        {name}
      </p>
      {filterIdsToShow.map(filterSetId => (
        <div key={filterSetId}>
          <Checkbox
            className='u-inlineBlock u-fontWeigh-normal u-textColor-gray50'
            isChecked={selectedFilterSetIds.includes(filterSetId)}
            label={filterSetId}
            onChange={
              selectedFilterSetIds.includes(filterSetId)
                ? () => {
                    dispatch(removeFilterSetAndSyncView(filterSetId));
                  }
                : () => {
                    dispatch(addFilterSetToView(filterSetId));
                  }
            }
          />
        </div>
      ))}
    </div>
  );
};

FilterSetSection.defaultProps = { query: '' };

FilterSetSection.propTypes = {
  name: PropTypes.string.isRequired,
  query: PropTypes.string,
};

export default FilterSetSection;

import { connect } from 'react-redux';
import getTargetCompany from '../selectors/targetCompanies/getTargetCompany';

/**
 * A higher order component which maps a `targetCompanyId` prop value to it's target company
 * record.
 */
export default connect((state, { targetCompanyId }) => ({
  targetCompany: getTargetCompany(state, targetCompanyId),
}));

import { connect } from 'react-redux';
import isRatingByCurrentUser from '../../selectors/ratings/isRatingByCurrentUser';

/**
 * Takes a list of `ratingIds` and ensures that the current user's rating is first in the list
 */
export default connect((state, { ratingIds }) => {
  // Return unless there are ratings.
  if (!ratingIds || !ratingIds.size) {
    return {};
  }

  // Find the index of the rating made by the current user.
  const index = ratingIds.findIndex(ratingId =>
    isRatingByCurrentUser(state, ratingId),
  );

  if (index !== -1) {
    // If found, delete the item at that index and add it to the front of the List
    return {
      ratingIds: ratingIds.delete(index).unshift(ratingIds.get(index)),
    };
  }

  // Not in the list, don't modify the props.
  return {};
}, {});

import { connect } from 'react-redux';
import getEntity from '../selectors/getEntity';

/**
 * A higher-order component creator which maps a Collection of entity IDs to a Collection
 * of underlying entities.
 * @param {String} entityType The entity type identifier (schema key)
 * @param {Object} options
 * @param {String} options.idsProp The name of the (incoming) prop that provides the IDs
 * @param {String} options.entitiesProp The name of the (outgoing) prop that provides the entities
 * @return {Function} A higher-order component for mapping the idsProp to the entitiesProp
 */
export default (entityType, { entitiesProp, idsProp }) =>
  connect(
    (state, { [idsProp]: ids }) => ({
      [entitiesProp]: ids && ids.map(id => getEntity(state, entityType, id)),
    }),
    {},
  );

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import {
  useCreateNetworkMutation,
  useUpdateNetworkMutation,
} from 'services/apiV1/networks';
import { ALL_RECORDS_NETWORK_ID } from '../recordIndexSlice';

const getErrorMessages = errors =>
  errors.data?.errors?.map(error => <li key={error}>{error}</li>) || null;

const NetworkSidePanel = ({ onClose, selectedNetwork, title }) => {
  const dispatch = useDispatch();
  const { networkType, recordLabel, recordType } = useSelector(
    state => state.recordIndex,
  );

  const [
    createNetwork,
    { error: networkCreateError, isLoading: isCreatingNetwork },
  ] = useCreateNetworkMutation();
  const [
    updateNetwork,
    { error: networkUpdateError, isLoading: isUpdatingNetwork },
  ] = useUpdateNetworkMutation();

  const handleNetworkSubmit = formData => {
    const networkParams = {
      public: formData.isNetworkPublic,
      name: formData.networkName,
      type: `${networkType}`,
    };

    if (selectedNetwork) {
      updateNetwork({
        networkId: selectedNetwork?.id || ALL_RECORDS_NETWORK_ID,
        payload: networkParams,
      })
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch(() => {
          dispatch(toastError('Could not update the network.'));
        });
    } else {
      createNetwork({
        recordType: recordLabel,
        payload: networkParams,
      })
        // unwrap to access the success payload immediately after a mutation.
        .unwrap()
        .then(newNetwork => {
          onClose(newNetwork?.network?.id);
          dispatch(
            toastSuccess(
              `You can now select ${recordType} from your database to add to this network.`,
              { title: `${formData.networkName} network successfully created` },
            ),
          );
        })
        .catch(() => {
          dispatch(toastError('Could not create the network.'));
        });
    }
  };

  const header = document.querySelector('#global-header');
  const globalNavHeight = header ? getComputedStyle(header)?.height : 0;
  const sidePanelHeight = `calc(100% - ${globalNavHeight})`;

  return (
    <SidePanel
      isOpen={true}
      style={{ height: sidePanelHeight, top: 'initial' }}
    >
      <SidePanel.Header onClose={onClose} title={title} />
      <Form onSubmit={handleNetworkSubmit}>
        <SidePanel.Body>
          <Form.TextInput
            initialValue={selectedNetwork?.name || ''}
            inputWidth='full'
            isRequired={true}
            label='Network Name'
            name='networkName'
            rules={{
              required: {
                value: true,
                message: 'Please enter a network name.',
              },
            }}
          />
          <Form.RadioButtonGroup
            className='u-marginTop-4'
            initialValue={Boolean(selectedNetwork?.public)}
            label='Sharing'
            name='isNetworkPublic'
            options={[
              {
                label: (
                  <Tooltip
                    content='Private networks can only be accessed by you.'
                    position='top'
                    size='medium'
                  >
                    Private
                  </Tooltip>
                ),
                value: false,
              },
              {
                label: (
                  <Tooltip
                    content='Public networks can be accessed by all Thrive users.'
                    position='top'
                    size='medium'
                  >
                    Public
                  </Tooltip>
                ),
                value: true,
              },
            ]}
          />
          {networkUpdateError || networkCreateError ? (
            <Card isCentered={false} type='error'>
              {`There was an error ${
                selectedNetwork ? 'editing' : 'creating'
              } this network.`}
              <ul>
                {getErrorMessages(networkCreateError || networkUpdateError)}
              </ul>
            </Card>
          ) : null}
        </SidePanel.Body>
        <SidePanel.Footer>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
          <Form.SubmitButton
            isLoading={isUpdatingNetwork || isCreatingNetwork}
            label='Save'
          />
        </SidePanel.Footer>
      </Form>
    </SidePanel>
  );
};

const networkShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  public: PropTypes.bool,
});

NetworkSidePanel.defaultProps = {
  selectedNetwork: null,
};

NetworkSidePanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedNetwork: networkShape,
  title: PropTypes.string.isRequired,
};

export default NetworkSidePanel;

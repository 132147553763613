import PropTypes from 'prop-types';
import React from 'react';
import CommaDelimitedList from 'modules/core/componentsLegacy/CommaDelimitedList';
import DateTime from 'modules/datetime/components/DateTime';
import SearchLink from 'modules/searches/components/SearchLink';
import UserName from 'modules/users/components/UserName';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import Notification from './Notification';

const Notes = ({
  notification,
  renderDate = false,
  renderRelatedSearches = true,
}) => {
  const { searches } = notification.resource;
  const { pinned } = notification.resource;
  return (
    <Notification notification={notification}>
      <div className='notes--note-view'>
        <div className='notes--note-view-creator'>
          {pinned && (
            <i
              aria-hidden='true'
              className='fa fa-thumb-tack notes--note-view-pinned'
            />
          )}
          <UserName userId={notification.resource.created_by_id} />
        </div>
        {renderRelatedSearches && searches && searches.size > 0 && (
          <div className='notes--note-view-related-searches'>
            Related to:{' '}
            <CommaDelimitedList>
              {searches.map(search => (
                <li key={search.get('id')}>
                  <SearchLink
                    rel='noopener noreferrer'
                    searchId={search.get('id')}
                    target='_blank'
                  >
                    {search.get('confidential')
                      ? search.get('code_name')
                      : search.get('name')}
                  </SearchLink>
                </li>
              ))}
            </CommaDelimitedList>
          </div>
        )}
        {renderDate && (
          <div>
            <div className='notes--note-view-date' key='date'>
              <DateTime
                format='L LT'
                value={
                  notification.resource.noted_on ||
                  notification.resource.created_at
                }
              />
            </div>
            {notification.resource.leadership_report && (
              <div className='notes--note-view-leadership'>
                <p>Status Note</p>
              </div>
            )}
          </div>
        )}
        {notification.resource.subject && (
          <div className='notes--note-view-subject'>
            <strong>Subject:</strong> {notification.resource.subject}
          </div>
        )}
        <div className='notes--note-view-content'>
          <ExpandableText
            characterLimit={150}
            content={notification.resource.content}
            isSanitizedHtml={true}
          />
        </div>
      </div>
    </Notification>
  );
};

Notes.propTypes = {
  notification: PropTypes.shape({
    resource: PropTypes.shape({
      content: PropTypes.string,
      created_at: PropTypes.string,
      created_by_id: PropTypes.number,
      leadership_report: PropTypes.bool,
      noted_on: PropTypes.string,
      pinned: PropTypes.bool,
      searches: PropTypes.arrayOf(
        PropTypes.shape({
          code_name: PropTypes.string,
          confidential: PropTypes.bool,
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      subject: PropTypes.string,
    }),
  }),
  renderDate: PropTypes.bool,
  renderRelatedSearches: PropTypes.bool,
};

export default Notes;

import createContact from './createContact';
import updateContact from './updateContact';
import updateAvatar from './updateAvatar';

const saveContact = ({
  _ajax,
  contact,
  contactId,
  contactReviewId,
  transactionId,
}) => dispatch => {
  if (!contactReviewId) {
    throw new Error('A `contactReviewId` is required!');
  }

  const payload = {
    ...contact.header,
    ...contact.social,
    ...contact.connections,
    ...contact.diversity,
    contact_review_id: contactReviewId === '__test' ? null : contactReviewId,
    educations: Object.values(contact.educations || {}),
    positions: Object.values(contact.positions || {}),
    skill_ids: contact.skills || [],
    network_ids: contact.networks,
    search_ids: contact.searches || [],
    pipeline_ids: contact.pipelines || [],
    connections: Object.values(contact.connectionList || {}),
  };

  if (contact.avatar && contact.avatar.removeAvatar) {
    payload.remove_avatar = true;
  }

  if (contact.avatar && contact.avatar.url) {
    payload.remote_avatar_url = contact.avatar.url;
  }

  const save = (contactId ? updateContact : createContact)({
    _ajax: _ajax,
    contactId: contactId,
    transactionId: transactionId,
    contact: payload,
  });

  // If we aren't changing the avatar, just update the contact.
  if (!contact.avatar || !contact.avatar.file) {
    return dispatch(save);
  }

  return dispatch(save).then(({ payload: { data: { id } } }) =>
    dispatch(
      updateAvatar({
        contactId: id,
        transactionId: transactionId,
        contact: { avatar: contact.avatar.file },
        _ajax: _ajax,
      }),
    ),
  );
};

export default saveContact;

import { connect } from 'react-redux';
import isInterviewPast from '../selectors/isInterviewPast';
import { DATE_GROUP_PAST, DATE_GROUP_UPCOMING } from '../constants';

/**
 * A Higher order component that groups interviewIds into DATE_GROUP_PAST and DATE_GROUP_UPCOMING
 * groups and passes them along in an `interviewGroups` prop
 */
export default connect(
  (state, { interviewIds }) => ({
    interviewGroups:
      interviewIds &&
      interviewIds
        .groupBy(id =>
          isInterviewPast(state, id) ? DATE_GROUP_PAST : DATE_GROUP_UPCOMING,
        )
        .sortBy((_, group) => (group === DATE_GROUP_UPCOMING ? 0 : 1)),
  }),
  {},
);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  withHandlers,
} from 'recompose';
import FieldState from 'modules/forms/FieldState';
import RadioSelectField from 'modules/forms/components/RadioSelectField';
import * as validators from 'modules/forms/validators';
import PositionView from 'modules/positions/components/PositionView';
import withPositionIdsSortedByDate from 'modules/positions/components/withPositionIdsSortedByDate';

/**
 * Displays a list of positions for selecting a contact's primary position
 */
const PrimaryPositionField = ({ fieldState, onChange, positionIds }) => {
  const value = fieldState.getNestedFieldValue('primary_position_id');

  return (
    <div className='PrimaryPositionField list-group'>
      {positionIds.map(positionId => (
        <div className='radio list-group-item' key={positionId}>
          <label htmlFor={`PrimaryPositionField-${positionId}`}>
            <input
              checked={positionId === value}
              id={`PrimaryPositionField-${positionId}`}
              name='primary_position_id'
              onChange={onChange}
              type='radio'
              value={positionId}
            />
            <PositionView
              format='titleAtCompany'
              positionId={positionId}
              renderDescription={false}
            />
          </label>
        </div>
      ))}
    </div>
  );
};

PrimaryPositionField.createFieldState = (name = 'primary_position', value) =>
  FieldState.createNested(name, [
    RadioSelectField.createFieldState(
      'primary_position_id',
      value.positionId,
      validators.requiredField('Primary Position'),
    ),
  ]);

PrimaryPositionField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The list of positions to show to select from.
   */
  positionIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
};

export default compose(
  setDisplayName('PrimaryPositionField(enhanced)'),
  setPropTypes(PrimaryPositionField.propTypes),
  setStatic('createFieldState', PrimaryPositionField.createFieldState),
  withPositionIdsSortedByDate,
  withHandlers({
    // Intercept the input onChange event and turn it into a fieldState onChange event.
    onChange: ({ fieldState, onChange }) => e => {
      onChange(
        fieldState.setNestedFieldValue(
          'primary_position_id',
          parseInt(e.target.value),
        ),
      );
    },
  }),
)(PrimaryPositionField);

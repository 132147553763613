import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * A checkbox input that adds support for an `indeterminate` prop.
 * The "indeterminate" attribute on an input can't be set with a DOM property -- it can only
 * be set using javascript. This adds support for passing an indeterminate property in as
 * a react prop.
 */
class Checkbox extends PureComponent {
  componentDidMount() {
    const { indeterminate } = this.props;
    if (this.inputEl && indeterminate) {
      this.inputEl.indeterminate = indeterminate;
    }
  }

  componentDidUpdate(prevProps) {
    const { indeterminate } = this.props;
    if (this.inputEl && prevProps.indeterminate !== indeterminate) {
      this.inputEl.indeterminate = indeterminate;
    }
  }

  setInputRef = el => {
    this.inputEl = el;
  };

  render() {
    const { indeterminate: _, ...props } = this.props;
    return <input ref={this.setInputRef} type='checkbox' {...props} />;
  }
}

Checkbox.defaultProps = {
  indeterminate: false,
};

Checkbox.propTypes = {
  /**
   * True to show the checkbox in an indeterminate state, otherwise false.
   *
   * NOTE: Indeterminacy does NOT affect a checkbox's `checked` state!
   * It merely affects how the checkbox is DISPLAYED. And it varies by browser.
   * MOST browsers will render the checkbox in an indeterminate display state regardless of the
   * input's `checked` value:
   *
   *   | `checked` | `indeterminate` | display |
   *   |-----------|-----------------|---------|
   *   | true      | true            |   [o]   |
   *   | false     | true            |   [o]   |
   *   | true      | false           |   [x]   |
   *   | false     | false           |   [ ]   |
   *
   * iOS Safari, IE11 Mobile, and Opera Mini don't support this at all,
   * so will just render based on the checked state.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  indeterminate: PropTypes.bool,
};

export default Checkbox;

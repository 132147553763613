import React from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  defaultProps,
  renderComponent,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import mapContactIdToRatingIds from './mapContactIdToRatingIds';
import withRatingsIdsSortCurrentUserRatingFirst from './withRatingsIdsSortCurrentUserRatingFirst';
import withRatingIdsSortedByCreatedDate from './withRatingIdsSortedByCreatedDate';
import ContactRatingListItem from './ContactRatingListItem';
import fetchContactIfNeeded from '../fetchContactIfNeeded';

/**
 * Rendering a list of ContactRatingListItem items from an array of rating IDs.
 */
export const ContactRatingList = ({ ratingIds, readOnly }) => (
  <ul className='list-generic'>
    {ratingIds
      .map(ratingId => (
        <ContactRatingListItem
          key={ratingId}
          ratingId={ratingId}
          readOnly={readOnly}
        />
      ))
      .toArray()}
  </ul>
);

ContactRatingList.propTypes = {
  /**
   * The list of rating IDs to render in the list
   */
  ratingIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired).isRequired,

  /**
   * True to enable editing of the ratings in the list.
   */
  readOnly: PropTypes.bool,
};

ContactRatingList.defaultProps = {
  readOnly: false,
};

export default compose(
  setDisplayName('ContactRatingList(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number,
    readOnly: PropTypes.bool.isRequired,
  }),
  defaultProps(ContactRatingList.defaultProps),
  fetchContactIfNeeded({ requireFull: true }),
  mapContactIdToRatingIds,
  branch(({ ratingIds }) => !ratingIds, renderComponent(LoadingIndicator)),
  withRatingIdsSortedByCreatedDate,
  withRatingsIdsSortCurrentUserRatingFirst,
)(ContactRatingList);

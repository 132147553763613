import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import Card from '@thrivetrm/ui/components/Card';

import InlineEditActions from '../../../components/forms/InlineEditActions';

import ReferenceView from './ReferenceView';
import connectReferenceActions from './connectReferenceActions';
import mapReferenceIdToReference from './mapReferenceIdToReference';
import ReferenceForm from './ReferenceForm';

/**
 * Renders single reference list item.
 */
class ReferenceListItem extends Component {
  state = {
    /**
     * True when the item is being edited inline, false when being viewed.
     * @type {Boolean}
     */
    isEditing: false,
  };

  /**
   * Called when the 'Yes' button of the delete confirmation is clicked.
   * Calls the action to delete the reference and resets the action.
   */
  handleDeleteClick = () => {
    const { reference, referenceActions, referenceId } = this.props;
    this.setState({
      isEditing: false,
    });

    referenceActions.deleteReference({
      id: referenceId,

      // These are needed at the moment for the action to make it's way to the appropriate
      // reducers, but once everything is normalized we shouldn't need this anymore:
      searchId: reference.get('search_id'),
      candidacyId: reference.get('candidacy_id'),
    });
  };

  /**
   * Called when the edit link is clicked, shows the edit form in place of
   * the reference view.
   */
  handleEditClick = () => {
    this.setState({
      isEditing: true,
    });
  };

  /**
   * Called when the edit form is closed, removes the edit form and shows
   * the reference view instead.
   */
  handleFormClose = () => {
    this.setState({
      isEditing: false,
    });
  };

  render() {
    const {
      candidacyId,
      contactId,
      readOnly,
      reference,
      referenceId,
      searchId,
    } = this.props;
    const { isEditing } = this.state;
    const isDeleting = reference.getIn(['_meta', 'isDeleting']);
    const canDelete = reference.getIn(['permissions', 'delete']);
    const canEdit = reference.getIn(['permissions', 'edit']);

    return (
      <li className='list-item-reference'>
        <Card isCentered={false}>
          {isEditing ? (
            <ReferenceForm
              alwaysShowFieldErrors={true}
              contactId={contactId}
              draftStoragePath={{
                search: searchId,
                candidate: candidacyId,
                reference: referenceId,
              }}
              errorDisplay='tooltip'
              excludeSearchField={true}
              onCancel={this.handleFormClose}
              onSaved={this.handleFormClose}
              reference={reference}
              referenceId={referenceId}
              title='Edit Reference'
            />
          ) : (
            <ReferenceView
              contactId={contactId}
              referenceId={reference.get('id')}
              showCreatedBy={true}
            />
          )}
          <InlineEditActions
            canDelete={!isEditing && !readOnly && canDelete}
            canEdit={!isEditing && !readOnly && canEdit}
            deleteConfirmation='Delete this reference?'
            disabled={isDeleting}
            onDelete={this.handleDeleteClick}
            onEdit={this.handleEditClick}
          />
        </Card>
      </li>
    );
  }
}

ReferenceListItem.propTypes = {
  candidacyId: PropTypes.number,
  contactId: PropTypes.number,
  /**
   * True to not allow editing of the reference.
   */
  readOnly: PropTypes.bool,

  /**
   * The reference to render.
   */
  reference: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isDeleting: PropTypes.bool,
    }),
    permissions: ImmutablePropTypes.mapContains({
      delete: PropTypes.bool.isRequired,
      edit: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,

  /**
   * Search actions for updating and deleting references (only
   * required when `readOnly` is false).
   */
  referenceActions: requiredIf(
    PropTypes.shape({
      deleteReference: PropTypes.func.isRequired,
      updateReference: PropTypes.func.isRequired,
    }),
    props => !props.readOnly,
  ),

  referenceId: PropTypes.number.isRequired,
  searchId: PropTypes.number,
};

ReferenceListItem.defaultProps = {
  readOnly: false,
};

export default compose(
  setPropTypes({
    referenceId: PropTypes.number.isRequired,
  }),
  mapReferenceIdToReference,
  connectReferenceActions,
)(ReferenceListItem);

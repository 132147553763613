import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  setDisplayName,
  setStatic,
  withHandlers,
  withPropsOnChange,
} from 'recompose';

import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';

import SelectAllCheckbox from './SelectAllCheckbox';

/**
 * A field for selecting which stages to include in the search summary report.
 */
const SearchSummaryReportStagesField = ({
  disabled,
  fieldState,
  handleFieldChange,
  handleToggleAll,
  isFetching,
  onBlur,
  onFocus,
  stages,
}) => {
  const isAllSelected =
    stages &&
    stages.count() &&
    stages.every(stage => fieldState.getNestedFieldValue(stage.get('id')));

  return (
    <div className='SearchSummaryReportStagesField'>
      <h2>
        Stages
        <SelectAllCheckbox
          disabled={disabled || !stages || !stages.count()}
          onBlur={onBlur}
          onChange={handleToggleAll}
          onFocus={onFocus}
          value={Boolean(isAllSelected)}
        />
      </h2>
      <div className='row'>
        {stages &&
          stages
            .map(stage => {
              const field =
                fieldState.getNestedField(stage.get('id')) ||
                CheckboxField.createFieldState(stage.get('id'), false);

              return (
                <CheckboxField
                  checked={field.getValue()}
                  className='col-4'
                  disabled={disabled}
                  fieldState={field}
                  key={stage.get('id')}
                  onBlur={onBlur}
                  onChange={handleFieldChange}
                  onFocus={onFocus}
                  text={stage.get('name')}
                />
              );
            })
            .toArray()}
      </div>
      {isFetching && !stages && <LoadingIndicator />}
    </div>
  );
};

SearchSummaryReportStagesField.propTypes = {
  /**
   * True to disable the entire field.
   */
  disabled: PropTypes.bool,

  /**
   * The current value of this field.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the fieldState changes for a nested field of the `fieldState`.
   */
  handleFieldChange: PropTypes.func.isRequired,

  /**
   * Called when the "all" option is toggled. Argument is the event which triggered the
   * toggle, in which the `checked` property of the target is set to the desired value.
   */
  handleToggleAll: PropTypes.func.isRequired,

  /**
   * True if the list of stages or stage counts are currently being fetched.
   */
  isFetching: PropTypes.bool.isRequired,

  /**
   * Called when the field is blurred
   */
  onBlur: PropTypes.func.isRequired,

  /**
   * Called when the field is focused.
   */
  onFocus: PropTypes.func.isRequired,

  /**
   * The list of stage names available to select from.
   */
  stages: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains({})),
};

SearchSummaryReportStagesField.defaultProps = {
  disabled: false,
};

SearchSummaryReportStagesField.createFieldState = (
  name = 'stages',
  { selectedStages = [], allStageIds = [] },
  validator,
  _convertToRaw,
  ...rest
) =>
  FieldState.createNested(
    name,
    allStageIds.map(stageId => {
      const checked = Boolean(selectedStages.includes(stageId));
      return CheckboxField.createFieldState(stageId, checked);
    }),
    validator,

    // convertToRaw implementation just returns an array of all selected stage names.
    valueMap => Object.keys(valueMap).filter(stageName => valueMap[stageName]),
    ...rest,
  );

export default compose(
  setDisplayName('SearchSummaryReportStagesField(enhanced)'),
  setStatic(
    'createFieldState',
    SearchSummaryReportStagesField.createFieldState,
  ),
  withPropsOnChange(['stageCounts', 'stages'], ({ stageCounts, stages }) => ({
    // Only include stages that have candidacies in them for the search in question
    stages:
      stages &&
      stageCounts &&
      stages.filter(stage => stageCounts.get(`${stage.get('id')}`) > 0),
  })),
  withHandlers({
    handleFieldChange: ({ fieldState, onChange }) => childFieldState =>
      onChange(fieldState.setNestedField(childFieldState)),

    handleToggleAll: ({ fieldState, onChange, stages }) => e => {
      if (stages) {
        onChange(
          fieldState.setNestedFields(
            stages.map(stage =>
              CheckboxField.createFieldState(stage.get('id'), e.target.checked),
            ),
          ),
        );
      }
    },
  }),
  withFormGroup,
)(SearchSummaryReportStagesField);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, setDisplayName, renderNothing } from 'recompose';

import AttachmentsListItem from './AttachmentsListItem';

const AttachmentsView = ({ documents }) => (
  <div className='AttachmentsView'>
    {documents.map(document => (
      <AttachmentsListItem
        destroy={document.get('destroy')}
        fileName={document.get('file_name')}
        fileSize={document.get('file_size')}
        key={document.get('id')}
        url={document.get('url')}
      />
    ))}
  </div>
);

AttachmentsView.propTypes = {
  documents: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      file_name: PropTypes.string,
      file_size: PropTypes.number,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      url: PropTypes.string,
    }),
  ),
};

export default compose(
  setDisplayName('AttachmentsView(enhanced)'),
  branch(({ documents }) => !documents, renderNothing),
)(AttachmentsView);

import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import ScrollableContainer from '@thrivetrm/ui/components/ScrollableContainer';
import Avatar from '@thrivetrm/ui/components/Avatar';
import Drawer from '@thrivetrm/ui/components/Drawer';
import routes from 'modules/routing/routes';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import DateComponent from '@thrivetrm/ui/components/Date';
import { dateTimeToISODateString } from '@thrivetrm/ui/utilities/dateTimeUtils';
import Grid from '@thrivetrm/ui/components/Grid';
import Rating from '@thrivetrm/ui/components/Rating';
import useFormValues from '@thrivetrm/ui/hooks/useFormValues';
import { useSelector } from 'react-redux';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import FormAsyncSelect from 'modules/core/components/FormAsyncSelect';
import AssessmentCriteriaCard from '../../assessment_templates/AssessmentCriteriaCard';

const ViewTypes = {
  SPLIT_SCREEN: 'Split Screen',
  RAW_NOTES_ONLY: 'Raw Notes Only',
  ASSESSMENT_ONLY: 'Assessment Only',
};

const CandidateAssessmentFormContent = ({
  assessment,
  categories,
  criteria,
  hasBeenSubmitted,
  isGuestAssessment,
  isLoadingAssessment,
  viewType,
}) => {
  const containerRef = useRef(null);
  const formState = useFormValues();
  const [containerHeight, setContainerHeight] = useState();
  const TODAY = dateTimeToISODateString(new Date());

  const isFullAccessOrAdminUser = useSelector(isEmployeeUser);
  const isCrmUser = useSelector(isCrmUserSelector);
  const tenant = useSelector(state => state.tenant);
  const ratingStars = tenant.get('rating_stars');
  const hasAssessmentV2SummaryFeature = tenant.get(
    'has_assessment_v2_summary_view',
  );

  /**
   * This page, when in split-screen view, has to side-by-side containers,
   * one scrollable and one not.  In order to make the height of these
   * containers consistent & responsive, we calculate the min & max height
   * of each to be the same size and then set the inline style  or the
   * provided props of the containing elements
   */
  const computeContainerHeight = (adjustment = 0) => {
    return `calc(100vh - ${
      containerHeight + adjustment || window.innerHeight
    }px)`;
  };

  // used useEffect as this component rendering on condition so ref was not updating
  useEffect(() => {
    setContainerHeight(containerRef?.current?.getBoundingClientRect().top);
  }, []);

  const { candidacy_assessment_answers_attributes: criteriaAnswers } =
    assessment ?? {};

  const getCriterionAnswer = (criterionId, categoryId = null) =>
    criteriaAnswers?.find(
      answer =>
        answer.assessment_criterium_id === criterionId &&
        answer.assessment_category_id === categoryId,
    );

  const getCriterionNotes = (criterionId, categoryId = null) => {
    const answer = getCriterionAnswer(criterionId, categoryId);
    return answer?.notes;
  };

  const getCriterionRating = (criterionId, categoryId = null) =>
    getCriterionAnswer(criterionId, categoryId)?.rating_value;

  const initialDate = isLoadingAssessment
    ? assessment?.assessed_on
    : assessment?.assessed_on || TODAY;

  // useMemo is used to avoid reinitialize of component value on each re-render
  const initialAssessedBy = useMemo(
    () =>
      assessment?.assessed_by
        ? {
            value: assessment?.assessed_by.id,
            label:
              assessment?.assessed_by.full_name ||
              assessment?.assessed_by.email,
          }
        : {
            value: assessment?.assessed_by_name
              ? null
              : window.thv.user.current_user_id,
            label: assessment?.assessed_by_name || window.thv.user.email,
          },
    [assessment],
  );

  const validateUserName = user => {
    // eslint-disable-next-line no-underscore-dangle
    return user?.__isNew__ && !user.label?.trim()
      ? 'Enter valid user name'
      : null;
  };

  const getAverageCategoryRating = categoryData => {
    if (hasBeenSubmitted) {
      return (
        criteriaAnswers.find(
          answer => answer.assessment_category_id === categoryData.id,
        )?.criteria_average || 0
      ).toFixed(2);
    }

    const criteriaIds = categoryData.criteria.map(
      criteriaDetails => criteriaDetails.id,
    );
    const ratings = criteriaIds
      .map(criteriaId =>
        hasBeenSubmitted
          ? getCriterionRating(criteriaId, categoryData.id)
          : formState[`${categoryData.id}_${criteriaId}_rating`],
      )
      .filter(rating => rating);

    return (ratings.length
      ? ratings.reduce((partialSum, rating) => partialSum + rating, 0) /
        ratings.length
      : 0
    ).toFixed(2);
  };

  const getFormattedLabel = option => {
    const value = option?.option;
    return value ? (
      <div className='u-flex u-flexAlign-c'>
        <Avatar
          className='u-marginRight-12'
          image={value.image}
          name={value.name || value.email}
          size='small'
        />
        <p className='u-margin-n'>
          {value.name ? `${value.name} (${value.email})` : value.email}
        </p>
      </div>
    ) : (
      option.label
    );
  };

  const renderAssessedByAndOn = () => {
    if (isLoadingAssessment) {
      return null;
    }
    return isGuestAssessment ? (
      <Grid>
        <Grid.Column className='u-paddingHorizontal-n' size={7}>
          <Form.TextInput
            className='u-width-100'
            data-testid='candidate-assessment-assessed-by'
            initialValue={assessment?.assessed_by_name || ''}
            inputWidth='full'
            label='Assessed By'
            name='assessed_by_name'
            rules={{
              required: {
                value: true,
                message: `You must enter your name before submitting the assessment`,
              },
            }}
          />
        </Grid.Column>
        <Grid.Column className='u-paddingHorizontal-n' size={5}>
          <Form.DateInput
            className='u-marginLeft-16'
            format='MM/dd/yyyy hh:mm a'
            initialValue={initialDate}
            inputWidth='full'
            isPinnedRight={true}
            label='Assessed On'
            name='assessed_on'
          />
        </Grid.Column>
      </Grid>
    ) : (
      <Grid>
        <Grid.Column className='u-paddingHorizontal-n' size={7}>
          <FormAsyncSelect
            className='u-wordBreak'
            formatCreateLabel={text => `'${text}' isn’t a Thrive User`}
            formatOption={option => ({
              label: option.name
                ? `${option.name} (${option.email})`
                : option.email,
              value: option.id,
              option: option,
            })}
            formatOptionLabel={getFormattedLabel}
            initialValue={initialAssessedBy}
            isCreatable={true}
            label='Assessed By'
            name='assessed_by'
            placeholder='Select User'
            rules={{
              validate: validateUserName,
              required: {
                value: true,
                message: `A user must be selected`,
              },
            }}
            url={query =>
              routes.api_v1_autocomplete_query({
                query: {
                  query: {
                    resource: 'user',
                    term: query || window.thv.user.email,
                  },
                },
              })
            }
          />
          {
            // eslint-disable-next-line no-underscore-dangle
            (formState.assessed_by?.__isNew__ &&
              formState.assessed_by.label.trim()) ||
            (formState.assessed_by && !formState.assessed_by?.value) ? (
              <p className='u-marginTop-4 u-marginBottom-n u-fontSize-small'>
                {formState.assessed_by?.label} isn’t a Thrive User
              </p>
            ) : null
          }
        </Grid.Column>
        <Grid.Column className='u-paddingHorizontal-n' size={5}>
          <Form.DateInput
            className='u-marginLeft-16'
            format='MM/dd/yyyy hh:mm a'
            initialValue={initialDate}
            inputWidth='full'
            isPinnedRight={true}
            label='Assessed On'
            name='assessed_on'
          />
        </Grid.Column>
      </Grid>
    );
  };

  const renderSummaryNotes = () => {
    if (hasBeenSubmitted) {
      return (
        <>
          <label
            className='u-textColor-gray60 u-fontSize-medium u-fontWeight-bold'
            htmlFor='assessment-notes'
          >
            Summary Notes
          </label>
          <HtmlText
            className='assessment-notes'
            htmlString={assessment?.notes}
          />
        </>
      );
    }
    return (
      <Form.RichTextField
        className='u-marginBottom-16'
        initialValue={assessment?.notes}
        label='Summary Notes'
        name='summary_notes'
        rules={{
          required: {
            value: true,
            message: 'Provide some notes',
          },
        }}
      />
    );
  };

  const renderRawNotes = () =>
    hasBeenSubmitted ? (
      <Card isCentered={false}>
        <label
          className='u-block u-margin-n u-marginBottom-8'
          htmlFor='assessment_notes'
        >
          {!isGuestAssessment && (isFullAccessOrAdminUser || isCrmUser)
            ? 'Raw Notes (not visible to clients)'
            : 'Raw Notes'}
        </label>
        <HtmlText className='raw-notes' htmlString={assessment?.raw_notes} />
      </Card>
    ) : (
      <Form.RichTextField
        id='assessment_notes'
        initialValue={assessment?.raw_notes}
        label={
          !isGuestAssessment && (isFullAccessOrAdminUser || isCrmUser)
            ? 'Raw Notes (not visible to clients)'
            : 'Raw Notes'
        }
        name='raw_notes'
        shouldAllowFullHeight={true}
      />
    );

  return (
    <div className='u-flex u-paddingBottom-16' ref={containerRef}>
      <ScrollableContainer
        className={`CandidateAssessmentFormContent${
          viewType === ViewTypes.RAW_NOTES_ONLY ? '--rawNotesOnly' : ''
        }`}
        maxHeight={computeContainerHeight(146)}
        minHeight={computeContainerHeight(146)}
      >
        <LoadingContainer
          isLoading={!categories && !criteria}
          overlayColor='gray5'
        >
          <Card className='u-margin-n' isCentered={false}>
            {renderSummaryNotes()}
            {hasBeenSubmitted ? (
              <>
                <label
                  className='u-textColor-gray60 u-block u-fontSize-medium u-fontWeight-bold u-marginBottom-4'
                  htmlFor='assessor-name'
                >
                  Assessed By
                </label>
                <p id='assessor-name'>
                  {assessment?.assessed_by_name ||
                    assessment?.assessed_by.full_name ||
                    assessment?.assessed_by.email}
                </p>
                {assessment?.assessed_on ? (
                  <>
                    <label
                      className='u-textColor-gray60 u-block u-fontSize-medium u-fontWeight-bold u-marginBottom-4'
                      htmlFor='assessor-name'
                    >
                      Assessed On
                    </label>
                    <DateComponent
                      date={assessment.assessed_on}
                      format='dateTime'
                    />
                  </>
                ) : null}
              </>
            ) : (
              renderAssessedByAndOn()
            )}
            {assessment?.migrated_from_legacy_assessments && assessment?.id ? (
              <p className='u-marginTop-4 u-margin-n'>
                This assessment was submitted using the old candidate assessment
                feature, so the Assessed By field is not an active Thrive user.
              </p>
            ) : null}
          </Card>

          {criteria?.map(criterion => (
            <AssessmentCriteriaCard
              className='u-marginVertical-16'
              criteriaName={criterion?.name}
              description={criterion?.description}
              isRecommended={!criterion?.label}
              key={criterion.id}
              notes={
                hasBeenSubmitted
                  ? getCriterionNotes(criterion.id)
                  : criterion?.notes
              }
              notesInput={
                hasBeenSubmitted ? null : (
                  <Form.RichTextField
                    initialValue={getCriterionNotes(criterion.id)}
                    label='Notes'
                    name={`${criterion.id}_notes`}
                  />
                )
              }
              rating={
                hasBeenSubmitted
                  ? getCriterionRating(criterion.id)
                  : criterion?.rating
              }
              ratingDefinitions={criterion?.rating_definitions}
              ratingInput={
                hasBeenSubmitted ? null : (
                  <Form.Rating
                    initialValue={getCriterionRating(criterion.id)}
                    maxRating={criterion?.rating_definitions?.length}
                    name={`${criterion.id}_rating`}
                  />
                )
              }
            />
          ))}
          {categories?.map(category => {
            const avgRatingValue = getAverageCategoryRating(category);
            return (
              <Drawer
                actionButton={
                  hasAssessmentV2SummaryFeature ? (
                    <div className='u-flex u-flexAlign-c'>
                      <Rating
                        maxRating={ratingStars}
                        value={Math.round(avgRatingValue * 2) / 2}
                      />
                      <h2 className='u-margin-n u-marginHorizontal-16 u-textColor-white'>
                        {avgRatingValue} Average
                      </h2>
                    </div>
                  ) : null
                }
                className={classnames(
                  'u-marginVertical-16 CandidateAssessmentFormContent__drawer',
                  {
                    'CandidateAssessmentFormContent__drawer--isSplitView':
                      viewType === ViewTypes.SPLIT_SCREEN,
                  },
                )}
                description={category.description}
                isOpenByDefault={false}
                key={category.id}
                label={category.name}
              >
                {category.criteria.map(criterion => (
                  <AssessmentCriteriaCard
                    className='u-marginVertical-16'
                    criteriaName={criterion?.name}
                    description={criterion?.description}
                    isRecommended={!criterion?.label}
                    key={criterion.id}
                    notes={
                      hasBeenSubmitted
                        ? getCriterionNotes(criterion.id, category.id)
                        : criterion?.notes
                    }
                    notesInput={
                      hasBeenSubmitted ? null : (
                        <Form.RichTextField
                          initialValue={getCriterionNotes(
                            criterion.id,
                            category.id,
                          )}
                          label='Notes'
                          name={`${category.id}_${criterion.id}_notes`}
                        />
                      )
                    }
                    rating={
                      hasBeenSubmitted
                        ? getCriterionRating(criterion.id, category.id)
                        : null
                    }
                    ratingDefinitions={criterion?.rating_definitions}
                    ratingInput={
                      hasBeenSubmitted ? null : (
                        <Form.Rating
                          initialValue={getCriterionRating(
                            criterion.id,
                            category.id,
                          )}
                          maxRating={criterion?.rating_definitions?.length}
                          name={`${category.id}_${criterion.id}_rating`}
                        />
                      )
                    }
                  />
                ))}
              </Drawer>
            );
          })}
        </LoadingContainer>
      </ScrollableContainer>
      {viewType === ViewTypes.SPLIT_SCREEN ? (
        <div className='u-paddingVertical-64 u-marginHorizontal-16' />
      ) : null}
      {viewType === ViewTypes.RAW_NOTES_ONLY ||
      viewType === ViewTypes.SPLIT_SCREEN ? (
        <div
          className={classnames('RawNotesContainer', {
            'RawNotesContainer--rawNotesOnly':
              viewType === ViewTypes.RAW_NOTES_ONLY,
          })}
          style={{
            maxHeight: computeContainerHeight(146),
            minHeight: computeContainerHeight(146),
          }}
        >
          {renderRawNotes()}
        </div>
      ) : null}
    </div>
  );
};

CandidateAssessmentFormContent.propTypes = {
  assessment: PropTypes.shape({
    assessed_by: PropTypes.shape({
      email: PropTypes.string,
      full_name: PropTypes.string,
      id: PropTypes.number,
    }),
    assessed_by_name: PropTypes.string,
    assessed_on: PropTypes.string,
    candidacy_assessment_answer_attributes: PropTypes.arrayOf(
      PropTypes.shape({
        assessment_category_id: PropTypes.number,
        assessment_criterium_id: PropTypes.number,
        notes: PropTypes.string,
        rating_value: PropTypes.number,
      }),
    ),
    concent_given: PropTypes.bool,
    id: PropTypes.number,
    migrated_from_legacy_assessments: PropTypes.bool,
    notes: PropTypes.string,
    raw_notes: PropTypes.string,
  }),
  // Ideally this is not disabled, fix is to define the category object shape
  // eslint-disable-next-line react/forbid-prop-types
  categories: PropTypes.arrayOf(PropTypes.object),
  // Ideally this is not disabled, fix is to define the criteria object shape
  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.arrayOf(PropTypes.object),
  hasBeenSubmitted: PropTypes.bool,
  isGuestAssessment: PropTypes.bool,
  isLoadingAssessment: PropTypes.bool,
  viewType: PropTypes.string,
};

export { ViewTypes };

export default CandidateAssessmentFormContent;

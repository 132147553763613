import { NAME, DEFAULT_RECRUITER_NAME } from '../constants';

/**
 * Gets the current tenant's "recruiter name" -- usually either "True" or
 * simply "Recruiter"
 * @param {Object} state
 * @return {String} The name of the current recruiter.
 */
const getRecruiterName = state =>
  state[NAME].get('recruiter_name', DEFAULT_RECRUITER_NAME);

export default getRecruiterName;

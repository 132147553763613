import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@thrivetrm/ui/components/Avatar';
import Card from '@thrivetrm/ui/components/Card';
import Icon from '@thrivetrm/ui/components/Icon';

const ContactInfoCard = ({ company, contact, location }) => {
  return contact || company || location ? (
    <Card
      className='u-flex u-flexJustify-spaceEvenly u-marginVertical-16'
      isCentered={false}
    >
      {company ? (
        <div className='u-flex u-flexAlign-c'>
          {company.avatarUrl ? (
            <div className='avatar avatar-contact u-marginRight-16'>
              <img alt='logo' src={company.avatarUrl} />
            </div>
          ) : null}
          <div>
            {company.primaryCompanyName ? (
              <h4 className='u-margin-n'>{company.primaryCompanyName}</h4>
            ) : null}
            {company.searchTitle ? (
              <p className='u-margin-n'>{company.searchTitle}</p>
            ) : null}
          </div>
        </div>
      ) : null}
      {contact ? (
        <>
          <div className='u-paddingVertical-32 u-marginHorizontal-16 u-borderRight' />
          <div className='u-flex u-flexAlign-c u-flexJustify-c'>
            <Avatar
              className='u-marginRight-16'
              image={contact.avatarUrl}
              name={contact.fullName}
              shape='circle'
              size='medium'
            />
            <div>
              <h4 className='u-margin-n'>{contact.fullName}</h4>
              {contact.primaryPositionName ? (
                <p>{contact.primaryPositionName}</p>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      {location ? (
        <>
          <div className='u-paddingVertical-32 u-marginHorizontal-16 u-borderRight' />
          <div className='u-flex u-flexAlign-c u-flexJustify-c'>
            <p className='u-margin-n'>{location}</p>
            <Icon className='u-marginLeft-8' color='blue' type='mapMarker' />
          </div>
        </>
      ) : null}
    </Card>
  ) : null;
};

ContactInfoCard.propTypes = {
  company: PropTypes.shape({
    avatarUrl: PropTypes.string,
    primaryCompanyName: PropTypes.string,
    searchTitle: PropTypes.string,
  }),
  contact: PropTypes.shape({
    avatarUrl: PropTypes.string,
    fullName: PropTypes.string,
    primaryPositionName: PropTypes.string,
  }),
  location: PropTypes.string,
};

export default ContactInfoCard;

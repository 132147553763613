import getContacts from './getContacts';

/**
 * Gets a value indicating whether we should fetch the documents list for a particular
 * owner type and ID.
 * @param {Object} state
 * @param {String} ownerType One of the owner type constants (@see OWNER_TYPES)
 * @param {Number} ownerId The owner unique ID value.
 * @return {Boolean} True if the documents should be requested from the server; otherwise, false.
 */
export default (state, networkId) => {
  if (!networkId) {
    return false;
  }

  const contacts = getContacts(state, networkId);

  if (!contacts) {
    return true;
  }

  if (contacts.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (contacts.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !contacts.has('ids') || Boolean(contacts.getIn(['_meta', 'isInvalidated']))
  );
};

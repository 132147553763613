import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { fromJS } from 'immutable';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  mapProps,
} from 'recompose';
import ColorField from 'modules/color/components/ColorField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import * as validators from 'modules/forms/validators';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';

/**
 * A field for creating or editing a tag.
 */
const TagField = ({
  className,
  fieldState,
  handleNestedFieldChange,
  label,
  ...props
}) => (
  <div className={classnames('TagField', className)}>
    {label && <span className='TagField__label'>{label}</span>}
    <ColorField
      {...props}
      className='TagField__color'
      fieldState={fieldState.getNestedField('color')}
      onChange={handleNestedFieldChange}
      placeholder='Color'
    />
    <InputField
      {...props}
      className='TagField__name'
      fieldState={fieldState.getNestedField('name')}
      onChange={handleNestedFieldChange}
      placeholder='Enter Tag Label'
    />
  </div>
);

TagField.propTypes = {
  /**
   * An optional class name to apply to the component.
   */
  className: PropTypes.string,

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * An optional label to include for the entire field.
   */
  label: PropTypes.string,
};

export default compose(
  setDisplayName('TagField(enhanced'),
  setPropTypes({
    fieldState: TagField.propTypes.fieldState,
    label: TagField.propTypes.label,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', (name, { tag, tagNames }, ...rest) => {
    const values = fromJS({
      name: '',
      color: '#DBDBDB',
    }).merge(tag);

    return FieldState.createNested(
      name,
      [
        InputField.createFieldState(
          'name',
          values.get('name'),
          validators.combineValidators(
            validators.uniqueValue(
              tagNames,
              'Tag with this label already exists',
            ),
            validators.requiredField('Label'),
          ),
        ),
        FieldState.create('color', values.get('color')),
      ],
      ...rest,
    );
  }),
  mapProps(({ tagList: _tagList, tagNames: _tagNames, ...props }) => props),

  withNestedFieldChangeHandler,
)(TagField);

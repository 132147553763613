import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import jobListingSchema from '../schema';

/**
 * Creates an action for updating a job listing
 * @param {Object} payload
 * @param {Number} payload.searchId The ID of the search this listing belongs to.
 * @param {Object} payload.job_listing The job listing properties
 * @param {Number} payload.job_listing.jobListingId The job listing ID
 * @return {Object} The update action.
 */
export default ({
  job_listing: { jobListingId, ...jobListing },
  searchId,
  ...rest
}) =>
  updateEntity({
    ...rest,
    body: {
      job_listing: {
        ...jobListing,
        id: jobListingId,
      },
    },
    entityType: jobListingSchema.key,
    id: searchId,
    responseSchema: { job_listing: jobListingSchema },
    url: routes.api_v1_search_job_listings({ searchId: searchId }),
  });

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import CompanyName from 'modules/companies/components/CompanyName';
import getSearchProperty from '../selectors/getSearchProperty';

/**
 * Renders the name of the client company that belongs to a search.
 */
export default compose(
  setDisplayName('SearchClientCompanyName'),
  setPropTypes({
    /**
     * The search ID of the search to show the client company name of.
     */
    searchId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { searchId }) => ({
      companyId: getSearchProperty(state, searchId, 'client_company'),
      className: 'SearchClientCompanyName',
    }),
    {},
  ),

  // Don't pass the searchId into the CompanyName
  mapProps(({ searchId: _, ...props }) => ({
    ...props,
  })),

  // Don't render anything if there's no ID (CompanyName requires a companyId prop)
  branch(({ companyId }) => !companyId, renderNothing),
)(CompanyName);

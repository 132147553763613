import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from 'modules/core/urlUtils';
import { setInitialColumns } from 'modules/configure-columns/configureColumnsSlice';
import { convertToCamelCase } from 'modules/core/jsonUtils';
import RecordIndexPageV5 from './RecordIndexPageV5';
import RecordIndexPage from './RecordIndexPage';
import {
  markAsStale,
  setCurrentPage,
  setInitialSavedView,
  setSavedView,
  setupFilterSets,
  validateSavedViewFilters,
} from './recordIndexSlice';

const RecordIndexPageContainer = () => {
  const dispatch = useDispatch();

  // We use the presence of filter set data in the DOM to decide which version of the index page to show
  const hasV5Enabled = Boolean(document.querySelector('[data-filter-sets]'));
  const urlLocation = useLocation();

  useEffect(() => {
    if (hasV5Enabled) {
      dispatch(
        setupFilterSets(
          JSON.parse(
            document.querySelector('[data-filter-sets]').dataset.filterSets,
          ),
        ),
      );
    }
  }, [dispatch, hasV5Enabled]);

  useEffect(() => {
    // The current saved view DB data is provided as an HTML element
    // We use this data to set the initial saved view and for the current saved view
    // that is the initial view with query string params merged on top.
    const savedViewDataElement = document.querySelector('[data-saved-view]');

    // The saved view element will always be in the DOM before this effect runs.
    // For the tests to pass, this presence check is necessary.
    if (!savedViewDataElement) {
      return;
    }
    const initialSavedViewFromDOM = convertToCamelCase(
      JSON.parse(savedViewDataElement.dataset.savedView),
    );

    const urlParams = parseQueryString(urlLocation.search);

    /**
     * We track the view state in the URL.  Unfortunately when stringifying boolean filter data
     * empty arrays are stripped out.  For exampled: { values: [], operator: 'OR' } => { operator: 'OR'}
     *
     * To align the view state we need to add back an empty 'values' to the boolean data.
     */
    if (urlParams?.filters) {
      if (
        urlParams.filters.keyword_boolean &&
        !urlParams.filters.keyword_boolean.values
      ) {
        urlParams.filters.keyword_boolean.values = [];
      }
      if (
        urlParams.filters.position_boolean &&
        !urlParams.filters.position_boolean.values
      ) {
        urlParams.filters.position_boolean.values = [];
      }
      if (
        urlParams.filters.company_boolean &&
        !urlParams.filters.company_boolean.values
      ) {
        urlParams.filters.company_boolean.values = [];
      }
      if (
        urlParams.filters.industry_boolean &&
        !urlParams.filters.industry_boolean.values
      ) {
        urlParams.filters.industry_boolean.values = [];
      }
      if (
        urlParams.filters.sectors_boolean &&
        !urlParams.filters.sectors_boolean.values
      ) {
        urlParams.filters.sectors_boolean.values = [];
      }
    }

    const { page: pageUrlParam, ...savedViewUrlParams } = convertToCamelCase(
      urlParams,
    );

    const savedView = {
      ...initialSavedViewFromDOM,
      ...savedViewUrlParams,
    };

    if (savedView.networkId) {
      savedView.networkId = parseInt(savedView.networkId);
    }

    if (savedView.resultsPerPage) {
      savedView.resultsPerPage = parseInt(savedView.resultsPerPage);
    }

    if (pageUrlParam) {
      dispatch(setCurrentPage(parseInt(pageUrlParam)));
    }
    if (hasV5Enabled) {
      const filterSetsDataElement = document.querySelector(
        '[data-filter-sets]',
      );
      const { selectedFilterSetIds } = JSON.parse(
        filterSetsDataElement?.dataset.filterSets,
      );
      initialSavedViewFromDOM.filters ??= {};
      savedView.filters ??= {};
      initialSavedViewFromDOM.filters.selectedFilterSetIds = selectedFilterSetIds;
      savedView.filters.selectedFilterSetIds = selectedFilterSetIds;
    }

    dispatch(setInitialSavedView(initialSavedViewFromDOM));
    dispatch(setSavedView(savedView));
    dispatch(setInitialColumns(savedView.columns));

    if (hasV5Enabled) {
      dispatch(validateSavedViewFilters());
    }
    dispatch(markAsStale());
  }, [dispatch, hasV5Enabled, urlLocation]);

  return hasV5Enabled ? <RecordIndexPageV5 /> : <RecordIndexPage />;
};

export default RecordIndexPageContainer;

import getEntity from 'modules/entities/selectors/getEntity';
import interviewSchema from '../schema';

/**
 * Gets an interview record by it's ID
 * @param {Object} state
 * @param {Number} interviewId The interviewId ID
 * @return {Immutable.Map|undefined} The interview record, if found.
 */
export default (state, interviewId) =>
  getEntity(state, interviewSchema.key, interviewId);

import { connect } from 'react-redux';
import NotificationsPage from '../../../components/notifications/NotificationsPage';
import * as notificationActions from '../../../actions/notifications';
import { NOTIFICATIONS_CATEGORY_ALL } from '../../../domains/notifications/constants';

export default connect(
  state => ({
    notifications: state.notifications.get(NOTIFICATIONS_CATEGORY_ALL),
  }),
  notificationActions,
)(NotificationsPage);

import { connect } from 'react-redux';
import getStage from '../selectors/getStage';

/**
 * A higher order component which maps a `stageIds` prop value to it's stage records as
 * a `stages` prop.`
 */
export default connect(
  (state, { stageIds }) => ({
    stages: stageIds && stageIds.map(stageId => getStage(state, stageId)),
  }),
  {},
);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import pluralize from 'pluralize';
import ContactDuplicate from './ContactDuplicate';

const humanize = str => str.replace('_', ' ');
const getDuplicateClassName = fieldName =>
  `alert alert-${fieldName === 'email' ? 'danger' : 'info'}`;

const ContactDuplicates = props => {
  const { duplicates } = props;
  return (
    <div className='contact-duplicates'>
      {Object.keys(duplicates)
        .filter(fieldName => duplicates[fieldName].size)
        .map(fieldName => (
          <div className={getDuplicateClassName(fieldName)} key={fieldName}>
            <div key='title'>
              Hold on! We found{' '}
              {pluralize('contact', duplicates[fieldName].size)}
              &nbsp;associated with the same {humanize(fieldName)}:
            </div>
            <br />

            <ul className='list-unstyled'>
              {duplicates[fieldName]
                .map(duplicate => (
                  <li className='dup-found' key={duplicate.get('id')}>
                    <ContactDuplicate duplicate={duplicate} {...props} />
                  </li>
                ))
                .toArray()}
            </ul>
          </div>
        ))}
    </div>
  );
};

const duplicatePropType = ImmutablePropTypes.mapContains({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  links: ImmutablePropTypes.mapContains({
    show: PropTypes.string.isRequired,
  }),
});

ContactDuplicates.propTypes = {
  duplicates: PropTypes.shape({
    email: ImmutablePropTypes.listOf(duplicatePropType),
    name: ImmutablePropTypes.listOf(duplicatePropType),
    work_email: ImmutablePropTypes.listOf(duplicatePropType),
  }),
};

export default ContactDuplicates;

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';

const PersonalInformationForm = ({ contactDetails }) => (
  <Card className='u-border-n u-padding-n' isCentered={false}>
    <h2 className='u-marginTop-n'>Personal Information</h2>
    <Form.TextInput
      className='u-marginBottom-8'
      initialValue={contactDetails?.firstName}
      inputWidth='full'
      label='First Name'
      name='first_name'
      placeholder='Enter First Name...'
      rules={{
        required: { value: true, message: 'First name is required.' },
      }}
      size='small'
    />
    <Form.TextInput
      className='u-marginBottom-8'
      initialValue={contactDetails?.middleName}
      inputWidth='full'
      label='Middle Name'
      name='middle_name'
      placeholder='Enter Middle Name...'
      size='small'
    />
    <Form.TextInput
      className='u-marginBottom-8'
      initialValue={contactDetails?.lastName}
      inputWidth='full'
      label='Last Name'
      name='last_name'
      placeholder='Enter Last Name...'
      rules={{
        required: { value: true, message: 'Last name is required.' },
      }}
      size='small'
    />
    <Form.TextInput
      className='u-marginBottom-8'
      initialValue={contactDetails?.preferredName}
      inputWidth='full'
      label='Preferred Name'
      name='preferred_name'
      placeholder='Enter Preferred Name..'
      size='small'
    />
  </Card>
);

PersonalInformationForm.propTypes = {
  contactDetails: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    preferredName: PropTypes.string,
  }),
};

export default PersonalInformationForm;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select-legacy';
import { useDispatch, useSelector } from 'react-redux';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Modal from '@thrivetrm/ui/components/Modal';
import RadioButtonGroup from '@thrivetrm/ui/components/RadioButtonGroup';
import Card from '@thrivetrm/ui/components/Card';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { selectRejectionReasons } from 'modules/candidacy-views/legacySearchDataSelectors';
import TextArea from '@thrivetrm/ui/components/TextArea';
import AdvancedSelect from '@thrivetrm/ui/components/AdvancedSelect';
import { fetchRejectionReasonsIfNeeded } from 'modules/search-stages/actions';
import {
  loadExistingCandidacies,
  resetExistingCandidacies,
  loadStages,
  loadSearches,
  addToSearch,
  resetAddToSearch,
} from '../addToSearchSlice';

const MIN_QUERY_LENGTH = 3;
const AddToSearchModal = ({
  className,
  contactIds,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [rejectionComment, setRejectionComment] = useState(null);
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const shouldDefaultCandidateToHidden = useFeatureCheck(
    'feature.candidacy_visibility.default_stage_invisible',
  );
  const [isCandidateHidden, setIsCandidateHidden] = useState(
    shouldDefaultCandidateToHidden,
  );
  const [searchQuery, setSearchQuery] = useState('');
  const {
    addToSearchError,
    existingCandidacies,
    isAdding,
    isAddingComplete,
    isLoadingSearches,
    isLoadingStages,
    searchOptions,
    stageOptions,
  } = useSelector(state => state.addToSearch);

  const rejectedStage =
    stageOptions?.filter(stage => stage.rejected === true) || [];

  const isRejectedReason = rejectedStage?.some(
    stage => selectedStage.value === stage.id,
  );

  const rejectionReasons = useSelector(selectRejectionReasons);
  const defaultRejectionReason = useSelector(
    state => selectRejectionReasons(state)?.[0],
  );
  const [rejectedReason, setRejectionReason] = useState(defaultRejectionReason);

  useEffect(() => {
    if (isAddingComplete && !addToSearchError) {
      dispatch(resetAddToSearch());
      onSuccess(selectedSearch);
    }
  }, [addToSearchError, dispatch, isAddingComplete, onSuccess, selectedSearch]);

  useEffect(() => {
    dispatch(loadStages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadSearches(searchQuery, MIN_QUERY_LENGTH));
  }, [dispatch, searchQuery]);

  useEffect(() => {
    dispatch(fetchRejectionReasonsIfNeeded());
  }, []);

  const hasMadeAllSelections = selectedSearch && selectedStage;

  const handleSearchSelect = search => {
    setSelectedSearch(search);
    if (search) {
      dispatch(loadExistingCandidacies(search.value, contactIds));
    } else {
      dispatch(resetExistingCandidacies());
    }
  };

  const handleClick = () => {
    dispatch(
      addToSearch(
        contactIds,
        selectedSearch.value,
        selectedStage.value,
        isCandidateHidden,
        rejectedReason || defaultRejectionReason,
        rejectionComment,
        isRejectedReason,
      ),
    );
  };

  const hasExistingCandidacies = existingCandidacies?.count > 0;

  const noResultsText = isLoadingSearches
    ? 'Loading...'
    : `Type at least ${MIN_QUERY_LENGTH} characters`;

  return (
    <Modal
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      size='small'
      title='Add to Search'
    >
      <Modal.Body className='ModalBody--noClip'>
        <LoadingContainer isLoading={isLoadingStages} size='large'>
          <h3>Search</h3>
          {hasExistingCandidacies && (
            <Card
              className='u-marginBottom-12'
              isCentered={false}
              type='warning'
            >
              One or more of the selected contacts are already in this search.
              If applicable, Click submit to add the other selected contacts.
            </Card>
          )}
          <Select
            filterOptions={false}
            noResultsText={noResultsText}
            onChange={handleSearchSelect}
            onInputChange={setSearchQuery}
            options={searchOptions}
            placeholder='Select search...'
            value={selectedSearch}
          />
          <h3>Stage</h3>
          <Select
            isSearchable={true}
            onChange={setSelectedStage}
            options={stageOptions?.map(stage => ({
              value: stage.id,
              label: stage.name,
            }))}
            placeholder='Select stage...'
            value={selectedStage}
          />
          {isRejectedReason ? (
            <>
              <AdvancedSelect
                className='u-marginTop-16'
                defaultValue={defaultRejectionReason}
                label='Rejection Reason'
                onChange={setRejectionReason}
                options={rejectionReasons?.map(reason => ({
                  label: reason,
                  value: reason,
                }))}
                value={rejectedReason}
              />
              <TextArea
                className='u-marginTop-16'
                label='Comments'
                onChange={setRejectionComment}
                value={rejectionComment}
                width='full'
              />
            </>
          ) : null}
          <h3>Candidate Visibility</h3>
          <RadioButtonGroup
            name='CandidateVisibility'
            onChange={setIsCandidateHidden}
            // NB: the visibility value is `false` when the candidate should be visible,
            // because this maps to a boolean attribute of `hidden` on the candidacy model
            options={[
              { label: 'Visible', value: false },
              { label: 'Hidden', value: true },
            ]}
            value={isCandidateHidden}
          />
          {addToSearchError && (
            <Card className='u-marginBottom-12' isCentered={false} type='error'>
              There was an error adding these contacts to this search.
            </Card>
          )}
        </LoadingContainer>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
        <ButtonPrimary
          isDisabled={!hasMadeAllSelections}
          isLoading={isAdding}
          label='Submit'
          onClick={handleClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

AddToSearchModal.defaultProps = {
  className: null,
  isOpen: false,
};

AddToSearchModal.propTypes = {
  className: PropTypes.string,
  contactIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddToSearchModal;

import { NAME } from '../constants';

/**
 * Gets the list of filtered interviews that belong to a particular parent (parent type + id) and
 * filter.
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CONTACT or PARENT_CANDIDACY)
 * @param {Number} parentId The parent ID -- either a contact ID when
 *   parentType === PARENT_CONTACT, or a candidacy ID when parentType === PARENT_CANDIDACY
 * @param {Filter} filter The filter type (DATE_GROUP_PAST or DATE_GROUP_UPCOMING)
 * @return {Immutable.Map|undefined} The interviews state, if it's been created (it won't be created
 *   until an action related to it has been dispatched)
 */
export default (state, parentType, parentId, filter) =>
  state[NAME].getIn([
    'filteredInterviewsByParentType',
    parentType,
    parentId,
    filter,
  ]);

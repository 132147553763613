import { connect } from 'react-redux';
import getIntroductionList from '../selectors/getIntroductionList';

/**
 * A higher order component which maps a `parentType` and `parentId` to it's
 * introductions list state.
 */
export default connect(
  (state, { parentId, parentType }) => ({
    introductionList:
      parentId &&
      parentType &&
      getIntroductionList(state, parentType, parentId),
  }),
  {},
);

import React from 'react';
import PropTypes from 'prop-types';
import routes from 'modules/routing/routes';
import DuplicateMergeRecordSelection from './DuplicateMergeRecordSelection';

const ContactDuplicateMergeRecordSelection = ({ match }) => {
  const contactId = Number(match.params.contactId);

  return (
    <DuplicateMergeRecordSelection
      avatarShape='circle'
      recordId={contactId}
      recordLabel='Contact'
      recordProfileUrl={routes.contact({ id: contactId })}
      recordType='contact'
    />
  );
};

ContactDuplicateMergeRecordSelection.propTypes = {
  // provided by react-router
  match: PropTypes.shape({
    params: PropTypes.shape({
      contactId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ContactDuplicateMergeRecordSelection;

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import connectAssessmentActions from 'modules/assessments/components/connectAssessmentActions';
import { TYPE_CLIENT } from 'modules/assessments/constants';
import getGuestAssessment from 'modules/assessments/selectors/getGuestAssessment';
import AssessmentForm from 'modules/candidacies/components/assessments/AssessmentForm';
import AssessmentView from 'modules/candidacies/components/assessments/AssessmentView';

import GuestAssessmentPageHeader from './GuestAssessmentPageHeader';

const GuestAssessmentPage = ({
  candidate,
  formState,
  guestAssessment,
  isPreviewing,
  searchId,
  searchName,
  setFormState,
  toggleEditing,
  token,
}) => {
  const { isSubmitted, result } = guestAssessment ? guestAssessment.toJS() : {};

  return (
    <div
      className={classnames('GuestAssessmentPage', 'container', {
        'GuestAssessmentPage--is-previewing': isPreviewing,
        'GuestAssessmentPage--is-editing': !isPreviewing,
      })}
    >
      <GuestAssessmentPageHeader
        candidateAvatar={candidate.avatar}
        candidateCompany={candidate.company}
        candidateName={candidate.name}
        candidateTitle={candidate.title}
        searchName={searchName}
      />
      <div className='row'>
        <div className='col-sm-6'>
          {isSubmitted ? (
            <div className='alert alert-success'>
              {result || 'Assessment saved!'}
            </div>
          ) : (
            <div>
              {isPreviewing && formState && (
                <div>
                  <h3>Preview Assessment</h3>
                  <AssessmentView
                    assessment={fromJS(formState.getFieldValue()).merge({
                      candidacy_id: candidate.id,
                      search_id: searchId,
                    })}
                    ratings={formState
                      .getFieldState()
                      .getNestedField('ratings')
                      .getValue()}
                    renderDate={false}
                  />
                </div>
              )}
              <AssessmentForm
                assessmentType={TYPE_CLIENT}
                candidacyId={candidate.id}
                formActionProps={{
                  onCancelClick: formState && toggleEditing,
                  cancelLabel: isPreviewing ? 'Edit' : 'Preview',
                  cancelButtonClass: classnames('btn-primary-outline', {
                    hidden: formState && formState.hasFieldErrors(),
                  }),
                  submitLabel: 'Submit',
                  submitButtonClass: isPreviewing ? 'btn-primary' : 'hidden',
                  confirmOnSubmit: true,
                  submitConfirmationTitle: 'Ready to Submit?',
                  submitConfirmationText:
                    'Are you sure you want to submit? Once submitted, you will not be able to make edits.',
                  submitPopoverPlacement: 'right',
                }}
                onChange={setFormState}
                searchId={searchId}
                title='Create Assessment'
                token={token}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

GuestAssessmentPage.propTypes = {
  candidate: PropTypes.shape({
    avatar: PropTypes.string,
    company: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  formState: PropTypes.shape({
    getFieldState: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    hasFieldErrors: PropTypes.func.isRequired,
  }),
  guestAssessment: ImmutablePropTypes.mapContains({
    isSubmitted: PropTypes.bool,
    result: PropTypes.string,
  }),
  isPreviewing: PropTypes.bool.isRequired,
  searchId: PropTypes.number.isRequired,
  searchName: PropTypes.string.isRequired,
  setFormState: PropTypes.func.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('GuestAssessmentPage(enhanced)'),
  setPropTypes({
    assessmentAttributes: PropTypes.arrayOf(
      PropTypes.shape({
        assessment_option: PropTypes.shape({
          description: PropTypes.string,
          id: PropTypes.number,
          name: PropTypes.string.isRequired,
        }).isRequired,
        id: PropTypes.number.isRequired,
        position: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    searchId: PropTypes.number.isRequired,
  }),
  withStateHandlers(
    { isPreviewing: false },
    {
      toggleEditing: ({ isPreviewing }) => () => ({
        isPreviewing: !isPreviewing,
      }),
      setFormState: () => formState => ({ formState: formState }),
    },
  ),
  connectAssessmentActions,
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.assessmentActions.initializeGuestAssessment(this.props);
    },
  }),
  connect(
    (state, { token }) => ({
      guestAssessment: getGuestAssessment(state, token),
    }),
    {},
  ),
)(GuestAssessmentPage);

import { connect } from 'react-redux';
import getRating from '../selectors/getRating';

/**
 * A higher order component which takes a List of `ratingIds` and maps
 * them to their rating records (provided as a `ratings` prop)
 */
export default connect(
  (state, { ratingIds }) => ({
    ratings: ratingIds && ratingIds.map(id => getRating(state, id)),
  }),
  {},
);

import {
  CONTACT_AVATAR_UPDATE_START,
  CONTACT_AVATAR_UPDATE_SUCCESS,
  CONTACT_AVATAR_UPDATE_FAILURE,
} from './constants';
import { ajax } from '../ajax';
import { contactSchema } from '../../modules/contacts/schema';
import createAjaxAction from '../createAjaxAction';

export const getUrl = ({ contactId }) => {
  if (!contactId) {
    throw new Error('updateAvatar must be called with a `contactId`');
  }

  return `/api/v1/contacts/${contactId}`;
};

export const getRequestOptions = ({ contact: { avatar } }) => {
  const formData = new FormData();
  formData.append('contact[avatar]', avatar);

  return {
    method: 'PATCH',
    body: formData,
  };
};

const updateAvatar = createAjaxAction({
  ajax: ajax,
  getUrl: getUrl,
  getRequestOptions: getRequestOptions,
  schema: { contact: contactSchema },
  start: CONTACT_AVATAR_UPDATE_START,
  success: CONTACT_AVATAR_UPDATE_SUCCESS,
  failure: CONTACT_AVATAR_UPDATE_FAILURE,
});

export default updateAvatar;

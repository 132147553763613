import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import mapContactIdToContact from '../../mapContactIdToContact';
import ContactCaslStatusForm from './ContactCaslStatusForm';

/**
 * Renders an EditableSection that displays and allows editing of
 * a contact's "CASL Status".
 */
export default compose(
  setDisplayName('ContactCaslStatusEditableSection'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
    statusOptions: PropTypes.array.isRequired,
  }),
  mapContactIdToContact,
  mapProps(({ contact, contactId, lastUpdated, statusOptions, title }) => ({
    contactId: contactId,
    children: contact.get('casl_status'),
    formComponent: ContactCaslStatusForm,
    statusOptions: statusOptions,
    lastUpdated: lastUpdated,
    title: title || 'CASL Status',
  })),
)(EditableSection);

import { stringifyQueryObject } from '@thrivetrm/ui/utilities/urlUtils';
import { convertToCamelCase } from 'modules/core/jsonUtils';
import apiV1 from './index';
import queryTags from './queryTags';

export const RecordTypesWithConnections = { CONTACT: 'contact', USER: 'user' };

export const CONNECTION_TYPE_GROUPS = ['General', 'Referrals', 'Reporting'];

export const CONNECTION_TYPE_GROUP_WITH_NAME = ['Referrals', 'Reporting'];

/**
 * @param {object} connectionType   - the name, id and group of the connection type
 * @param {string} name             - the name to display next to the type
 * @returns
 */
export const getConnectionTypeLabel = (connectionType, name) =>
  CONNECTION_TYPE_GROUP_WITH_NAME.includes(connectionType.group)
    ? `${connectionType.name} ${name}`
    : connectionType.name;

const {
  CONNECTIONS,
  INDEX_TABLE_RECORDS,
  REFERENCES,
  REPORTING_RELATIONSHIPS,
} = queryTags;

const connections = apiV1.injectEndpoints({
  endpoints: builder => ({
    /**
     * Loads the connections of a contact
     * @param {number} contactId        - The contact for which to load connections
     * @param {Object} params           - The parameters to include in the query string
     * @param {string} params.degree    - Specifies which degree connections should be filtered on
     * @param {number} params.limit     - Restricts the number of possible results to be returned
     * @param {string} params.filters   - ? TBD
     */
    getConnections: builder.query({
      query: ({ contactId, params }) => ({
        url: `/contacts/${contactId}/connections`,
        method: 'GET',
        params: stringifyQueryObject(params),
      }),
      providesTags: [CONNECTIONS],
      transformResponse: response => {
        return {
          data: convertToCamelCase(response?.data),
          totalCount: response?.metadata?.total_connections_count ?? null,
        };
      },
    }),
    getConnection: builder.query({
      query: ({ connectionId, contactId }) => ({
        url: `/contacts/${contactId}/connections/${connectionId}`,
        method: 'GET',
      }),
      providesTags: [CONNECTIONS],
      transformResponse: response => convertToCamelCase(response?.connection),
    }),
    createConnection: builder.mutation({
      query: ({ contactId, payload }) => ({
        url: `/contacts/${contactId}/connections`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        CONNECTIONS,
        INDEX_TABLE_RECORDS,
        REPORTING_RELATIONSHIPS,
      ],
    }),
    updateConnection: builder.mutation({
      query: ({ connectionId, contactId, payload }) => ({
        url: `/contacts/${contactId}/connections/${connectionId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [
        CONNECTIONS,
        INDEX_TABLE_RECORDS,
        REFERENCES,
        REPORTING_RELATIONSHIPS,
      ],
    }),
    deleteConnection: builder.mutation({
      query: ({ connectionId, contactId }) => ({
        url: `/contacts/${contactId}/connections/${connectionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        CONNECTIONS,
        INDEX_TABLE_RECORDS,
        REFERENCES,
        REPORTING_RELATIONSHIPS,
      ],
    }),
    getConnectionStrengths: builder.query({
      query: () => ({
        url: '/connection_strengths',
        method: 'GET',
      }),
      transformResponse: response =>
        convertToCamelCase(response?.connection_strengths),
    }),
    getConnectionTypes: builder.query({
      query: () => ({
        url: '/connection_types',
        method: 'GET',
      }),
      transformResponse: response =>
        convertToCamelCase(response?.connection_types),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateConnectionMutation,
  useDeleteConnectionMutation,
  useGetConnectionQuery,
  useGetConnectionStrengthsQuery,
  useGetConnectionTypesQuery,
  useGetConnectionsQuery,
  useUpdateConnectionMutation,
} = connections;

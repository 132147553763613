import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import canViewIntroductionsSelector from '../selectors/canViewIntroductions';
import withIntroductionListFetched from './withIntroductionListFetched';
import IntroductionList from './IntroductionList';
import { PARENT_SEARCH } from '../constants';

/**
 * Renders a list of only the most recent introductions.
 */
const RecentIntroductions = ({
  introductionList,
  limit,
  parentId,
  parentType,
  readOnly,
  title,
}) => (
  <div className='introductions--recent-introduction'>
    {title}
    {introductionList.get('ids') && introductionList.get('ids').count() && (
      <IntroductionList
        introductionIds={introductionList.get('ids')}
        limit={limit}
        linkToCandidacy={true}
        readOnly={readOnly}
        renderRelatedSearches={false}
        searchId={parentType === PARENT_SEARCH ? parentId : null}
      />
    )}
    {introductionList && introductionList.getIn(['_meta', 'isFetching']) && (
      <LoadingIndicator />
    )}
  </div>
);

RecentIntroductions.propTypes = {
  introductionList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
  }).isRequired,

  /**
   * The maximum number of introductions to render.
   */
  limit: PropTypes.number,

  /**
   * The id of the introduction's parent, either a contact or a search.
   */
  parentId: PropTypes.number,

  /**
   * The parent type of the introduction, either a contact or a search.
   */
  parentType: PropTypes.string.isRequired,

  /**
   * True to not allow editing of the introduction.
   */
  readOnly: PropTypes.bool,

  /**
   * An optional title to render.
   */
  title: PropTypes.node,
};

RecentIntroductions.defaultProps = {
  readOnly: false,
};

export default compose(
  setDisplayName('RecentIntroductions(enhanced)'),

  // The connected version of this component requires a parentId and parentType,
  // everything else will be fetched from state.
  setPropTypes({
    checkPermissions: PropTypes.bool.isRequired,
    hideIfEmpty: PropTypes.bool,
    parentId: PropTypes.number,
    parentType: PropTypes.string.isRequired,
  }),

  defaultProps({
    checkPermissions: true,
  }),

  connect(
    (state, { checkPermissions }) => ({
      canViewIntroductions:
        !checkPermissions || canViewIntroductionsSelector(state),
    }),
    {},
  ),

  // Bail if the user doesn't have permission.
  branch(({ canViewIntroductions }) => !canViewIntroductions, renderNothing),

  // If the introductions have not been loaded or are stale, fetch them.
  withIntroductionListFetched,

  // Pull off the introductionIds as a prop so they can be
  // mapped by mapIntroductionIdsToIntroductions
  withProps(({ fetchIntroductionList, introductionList }) => ({
    handleRefreshIntroductions: fetchIntroductionList,
    introductionIds: introductionList && introductionList.get('ids'),
  })),

  branch(
    ({ hideIfEmpty, introductionList }) =>
      hideIfEmpty &&
      (!introductionList ||
        !introductionList.get('ids') ||
        !introductionList.get('ids').count()),
    renderNothing,
  ),
)(RecentIntroductions);

import React from 'react';
import PropTypes from 'prop-types';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import TextInput from '@thrivetrm/ui/components/TextInput';
import ClipboardTrigger from 'modules/core/componentsLegacy/ClipboardTrigger';

const MailchimpIntegrationCopyWebhookUrl = ({ isDisabled, residencyToken }) => {
  const dispatch = useDispatch();

  const webhookUrl = residencyToken
    ? `${window.location.origin}/api/integrations/mailchimp/webhooks/${residencyToken}`
    : '';

  return (
    <div
      className={classnames({
        'MailchimpIntegrationPage--isDisabled': isDisabled,
      })}
    >
      <h3 className='u-marginTop-32 u-marginBottom-8'>
        3. Add the Thrive Webhook URL in Mailchimp
      </h3>
      <p className='u-marginBottom-8'>
        Copy the following Webhook URL and paste it in Mailchimp.{' '}
        <a
          href='https://mailchimp.com/developer/marketing/guides/sync-audience-data-webhooks/#create-a-new-webhook'
          rel='noreferrer'
          target='_blank'
        >
          Show me how.
        </a>
        .
      </p>

      <div className='u-flex u-flexAlign-b'>
        <TextInput
          className='u-marginRight-16'
          data-testid='mailchimp-webhook-url'
          onChange={() => {}}
          placeholder={`${window.location.origin}/api/integrations/mailchimp/webhooks/token`}
          style={{ width: 400 }}
          value={webhookUrl}
        />
        <ClipboardTrigger
          onSuccess={() => dispatch(toastSuccess('Copied!'))}
          text={webhookUrl}
        >
          <ButtonSecondary icon='copy' label='Copy' />
        </ClipboardTrigger>
      </div>
    </div>
  );
};

MailchimpIntegrationCopyWebhookUrl.defaultProps = {
  isDisabled: false,
  residencyToken: '',
};

MailchimpIntegrationCopyWebhookUrl.propTypes = {
  isDisabled: PropTypes.bool,
  residencyToken: PropTypes.string,
};

export default MailchimpIntegrationCopyWebhookUrl;

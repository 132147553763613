import PropTypes from 'prop-types';
import React from 'react';
import { withHandlers } from 'recompose';
import classnames from 'classnames';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';

const SidebarExpandToggle = ({ isExpanded, ...props }) => (
  <TooltipTrigger tooltip={isExpanded ? 'Collapse' : 'Expand'}>
    <button className='SidebarExpandToggle' type='button' {...props}>
      <i
        className={classnames('fa', {
          'fa-expand': !isExpanded,
          'fa-compress': isExpanded,
        })}
      />
    </button>
  </TooltipTrigger>
);

SidebarExpandToggle.propTypes = {
  isExpanded: PropTypes.bool,
};

SidebarExpandToggle.defaultProps = {
  isExpanded: false,
};

export default withHandlers({
  onClick: ({ isExpanded, onChange }) => () => onChange(!isExpanded),
})(SidebarExpandToggle);

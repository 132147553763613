import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import Modal from 'modules/core/componentsLegacy/Modal';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withCandidatePortalInviteForm from './withCandidatePortalInviteForm';
import CandidatePortalInviteField from './CandidatePortalInviteField';
import { fetchContact as fetchContactActionCreator } from '../../actions/contacts/index';

const CandidatePortalInviteModal = ({
  contact,
  formState,
  onFieldStateChange,
  onHide,
  onSubmit,
  show,
  ...fieldProps
}) => {
  const user = contact?.get('user');
  const contactEmail = contact?.get('email') || contact?.get('work_email');
  const hasInviteBeenSent = Boolean(user?.get('invitation_created_at'));

  const modalTitle = hasInviteBeenSent
    ? 'Resend Invitation'
    : 'Invite to Candidate Portal';
  const submitText = hasInviteBeenSent ? 'Yes, Send Invitation' : 'Send Invite';
  const cancelText = hasInviteBeenSent ? 'Nevermind' : 'Cancel';

  return (
    <Modal className='CandidatePortalInviteModal' onHide={onHide} show={show}>
      <Form formState={formState} onSubmit={onSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {hasInviteBeenSent ? (
            <span>
              Re-send a candidate portal invitation to {contactEmail}?
            </span>
          ) : (
            <CandidatePortalInviteField
              contact={contact}
              {...fieldProps}
              fieldState={formState.getFieldState()}
              onChange={onFieldStateChange}
            />
          )}
          <FormErrorMessage className='u-marginTop-12' formState={formState} />
        </Modal.Body>
        <Modal.Footer>
          <CancelButton bsSize='lg' onClick={onHide}>
            {cancelText}
          </CancelButton>
          <SubmitButton
            bsSize='lg'
            formState={formState}
            icon='fa-paper-plane'
            onClick={onSubmit}
          >
            {submitText}
          </SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CandidatePortalInviteModal.propTypes = {
  /**
   * The contact record to whom we are sending an invitation
   */
  contact: ImmutablePropTypes.mapContains({
    email: PropTypes.string,
    user: ImmutablePropTypes.mapContains({
      invitation_created_at: PropTypes.string,
    }),
  }),
  /**
   * THe form state for the modal's form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the root fieldstate of the formState is changed
   * (handled by withInviteCreateForm)
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Called when the user clicks the cancel button or otherwise wants to close the modal
   */
  onHide: PropTypes.func.isRequired,

  /**
   * Called when the form should be submitted (handled by withInviteCreateForm)
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * True if the modal should be displayed, otherwise false.
   */
  show: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('CandidatePortalInviteModal(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
    /**
     * Called when the modal should be closed.
     */
    onHide: PropTypes.func.isRequired,

    /**
     * Whether the modal should be rendered.
     */
    show: PropTypes.bool.isRequired,
  }),
  connect(null, dispatch => ({
    fetchContact: bindActionCreators(fetchContactActionCreator, dispatch),
  })),

  withHandlers({
    onSuccess: ({ contactId, fetchContact }) => () => {
      fetchContact({ id: contactId });
    },
  }),

  withCandidatePortalInviteForm,

  /**
   * Prevent props from being passed on to the underlying fieldState
   */
  /* eslint-disable no-unused-vars */
  mapProps(
    ({
      contactId,
      fetchContact,
      onFormStateChange,
      onResetFormState,
      onSaved,
      onSuccess,
      ...rest
    }) => ({
      ...rest,
    }),
  ),
  /* eslint-enable no-unused-vars */
)(CandidatePortalInviteModal);

import createEntity from 'modules/entities/actions/createEntity';
import routes from 'modules/routing/routes';
import compensationSchema from '../schema';

/**
 * An action for creating a compensation record.
 * @param {Object} payload
 * @param {Number} contactId The ID of the contact this record belongs to.
 * @param {Object} payload.compensation The compensation record to create
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ compensation, contactId, transactionId }) =>
  createEntity({
    body: { compensation: compensation },
    // Needed by the contacts reducer so it can add the new record as a reference.
    contactId: contactId,
    transactionId: transactionId,
    entityType: compensationSchema.key,
    responseSchema: compensationSchema,
    url: routes.api_v1_contact_compensations({ contactId: contactId }),
  });

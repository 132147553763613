import { ajax } from 'actions/ajax';
import initSelect2 from '../../../lib/select2';

const commissionDetailSelects = [
  '.js-commission-detail-users-select',
  '.js-payment-type-select',
];

const paymentDetailSelects = ['.js-currency-select'];

const pageForms = [
  '.contract-form',
  '.invoice-form',
  '.commission-detail-form',
];

const initCommissionSelects = () => {
  initSelect2('#payment-details-form', paymentDetailSelects);
  initSelect2('#commission-details-form', commissionDetailSelects);
};

const makeHeaderInactive = () =>
  $('.nav.nav-tabs.nav-main li').removeClass('active');

const showAmountField = () => {
  const splits = document.querySelectorAll('.fields.commission-detail-fields');
  splits.forEach(split => {
    const paymentType = $(split).find('.payment-type-select').val();
    if (paymentType === 'Fixed' || paymentType === 'Special') {
      $(split).find('.commission-amount').removeClass('hidden');
    }
  });
};

const hasSentInvoice = user => {
  const field = user.closest('.remove-commission-detail');
  return $(field).find('.js-commission-invoice-sent').val() === 'true';
};

const confirmDeletion = resource =>
  $(`#${resource}-save`).on('click', () => {
    const checked = $(`.${resource}-fields .checkbox input.optional:checked`);

    if (checked.length > 0) {
      if (resource === 'commission-detail') {
        if (Array.from(checked).some(hasSentInvoice)) {
          return $(`#${resource}-save`).data(
            'confirm',
            'The selected users have splits that have been sent. Are you sure you want to delete?',
          );
        } else {
          return $(`#${resource}-save`).data(
            'confirm',
            'Are you sure you want to delete these invoice splits? This action may impact the other payouts for the associated invoices.',
          );
        }
      } else {
        return $(`#${resource}-save`).data(
          'confirm',
          'Are you sure you want to delete the selected record(s)?',
        );
      }
    } else {
      return $(`#${resource}-save`).removeData('confirm');
    }
  });

const styleFileButton = () =>
  $('.invoice-form').on('nested:fieldAdded', () => {
    $('.file-button').last().css('margin-top', '23px');
  });

const disablePercentageAmounts = () =>
  $('.commission-detail-fields')
    .find('.commission-percentage')
    .not('.hidden')
    .siblings('.commission-amount')
    .find('input.numeric')
    .prop('disabled', true);

const manageFormModificationsFor = function (paymentTypeSelects, userSelects) {
  userSelects.change(event => {
    const fields = event.target.closest('.commission-detail-fields');
    const currency = $(fields).find('.commission-currency');
    const userId = event.target.value;
    return $.get(`/api/users/${userId}`, data => {
      currency.find('input').val(data.currency);
      return currency.removeClass('hidden');
    });
  });

  return paymentTypeSelects.change(event => {
    const selection = event.target.value;
    const fields = event.target.closest('.commission-detail-fields');
    const commissionAmounts = $(fields)
      .find('.commission-fields')
      .find('.commission-amount');
    $(fields).find('.commission-fields').removeClass('hidden');
    const percentages = $(fields).find('.commission-percentage');

    if (selection === 'Percentage' || selection === 'Referral') {
      percentages.removeClass('hidden');
      percentages.find('input').removeClass('hidden');
      commissionAmounts.find('input.numeric').prop('disabled', true);
      return commissionAmounts.addClass('hidden');
    } else {
      percentages.addClass('hidden');
      commissionAmounts.removeClass('hidden');
      return commissionAmounts
        .find('input.numeric')
        .each((index, element) =>
          $(element).prop(
            'disabled',
            Boolean($('.invoice-date-paid')[index].innerText),
          ),
        );
    }
  });
};

const editCommissionDetailFormModifiers = function () {
  const paymentTypeSelects = $(
    '.fields.commission-detail-fields .payment-type-select',
  );
  const userSelects = $(
    '.fields.commission-detail-fields .js-commission-detail-users-select',
  );

  return manageFormModificationsFor(paymentTypeSelects, userSelects);
};

const newCommissionDetailFormModifiers = () =>
  $(document).on('nested:fieldAdded', event => {
    const paymentTypeSelect = event.field.find('.payment-type-select');
    const userSelect = event.field.find('.js-commission-detail-users-select');

    return manageFormModificationsFor(paymentTypeSelect, userSelect);
  });

const handleFormSubmit = async event => {
  event.preventDefault();
  const form = event.target.closest('form');
  const errorBox = form.querySelector('.js-error-box');
  const submitBtn = form.querySelector('button[type=submit]');
  const payload = new FormData(form);

  errorBox.textContent = '';
  errorBox.classList.add('hidden');
  submitBtn.disabled = true;

  try {
    await ajax(form.action, {
      method: 'POST',
      body: payload,
    });

    // Yah, it seems odd to reload after successfully submitting this form via AJAX. This AJAX logic
    // was added to replace a hacky UJS solution that existed previously. The prior solution
    // required the remotipart gem, which was a dead gem ATTOW. So, we opted to remove remotipart,
    // along with the janky UJS behavior, and swap it in with almost identical behavior. This
    // retains the same reloading that the previously functionality had.
    window.location.reload();
  } catch (error) {
    errorBox.textContent = error.message;
    errorBox.classList.remove('hidden');
    errorBox.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    submitBtn.disabled = false;
  }
};

const initialize = () => {
  makeHeaderInactive();
  disablePercentageAmounts();
  initCommissionSelects();
  $('.commission-detail-form').on('nested:fieldAdded', initCommissionSelects);
  editCommissionDetailFormModifiers();
  newCommissionDetailFormModifiers();
  showAmountField();
  styleFileButton();
  $(pageForms.join(',')).on('submit', handleFormSubmit);
  ['commission-detail', 'invoice'].forEach(confirmDeletion);
};

export default {
  initialize: initialize,
};

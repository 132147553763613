import { compose, mapProps } from 'recompose';

export default compose(
  mapProps(({ ...props }) => ({
    fieldLabels: {
      aliases: 'Aliases',
      additional_detail: 'Additional Detail',
      avatar: 'Avatar',
      career_highlights: 'Career Highlights',
      company: 'Primary Company',
      documents: 'Resume & Reference Docs',
      education: 'Education',
      email: 'Email',
      experience: 'Experience',
      job_title: 'Job Title',
      location: 'Location',
      mobile: 'Mobile Phone',
      name: 'Name',
      phone: 'Phone',
      professional_networks: 'Professional Network Links',
      references: 'References',
      thrive_profile: 'Thrive Profile Link',
    },

    ...props,
  })),
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import asFormGroupField from './asFormGroupField';
import FieldState from '../FieldState';
import combineValidators from '../validators/combineValidators';
import isNumber from '../validators/isNumber';
import greaterThanOrEqualTo from '../validators/greaterThanOrEqualTo';
import lessThanOrEqualTo from '../validators/lessThanOrEqualTo';

const convertOptionalFloat = value => {
  if (typeof value === 'string') {
    return value.trim().length ? parseFloat(value.replace(/,/g, '')) : null;
  }

  return value;
};
/**
 * A component that displays an input for entering a percentage
 */
export class PercentageInput extends Component {
  handleChange = e => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  render() {
    const { type, ...props } = this.props;

    return (
      <div className='input-group'>
        <input
          {...props}
          className='form-control'
          onChange={this.handleChange}
          type={type || 'text'}
        />
        <span className='input-group-addon'>%</span>
      </div>
    );
  }
}

PercentageInput.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.string,
};

PercentageInput.defaultProps = {
  // use 'number'?
  type: 'text',
};

PercentageInput.createFieldState = (
  name,
  value,
  { maxValue, minValue } = {},
) => {
  const validators = [];

  if (typeof minValue === 'number') {
    validators.push(greaterThanOrEqualTo(minValue));
  }

  if (typeof maxValue === 'number') {
    validators.push(lessThanOrEqualTo(maxValue));
  }

  return FieldState.create(
    name,
    value === null || typeof value === 'undefined' ? '' : String(value),
    combineValidators(isNumber, ...validators),
    convertOptionalFloat,
  );
};

export default asFormGroupField(PercentageInput);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import CommaDelimitedList from 'modules/core/componentsLegacy/CommaDelimitedList';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import mapSearchIdsToSearches from 'modules/searches/components/mapSearchIdsToSearches';
import SearchLink from 'modules/searches/components/SearchLink';
import AttachmentsView from 'modules/documents/components/AttachmentsView';

import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import mapOutreachIdToOutreach from './mapOutreachIdToOutreach';
import OutreachTitle from './OutreachTitle';

/**
 * Renders a single outreach record.
 */
const OutreachView = ({
  outreach,
  renderDate,
  renderDescription,
  renderRelatedSearches,
  renderTitleAsLink,
  searchId,
  searches,
}) => (
  <div className='outreaches--outreach-view'>
    {(renderDate || renderDescription) && (
      <OutreachTitle
        outreach={outreach}
        renderAsLink={renderTitleAsLink}
        renderDate={renderDate}
        renderDescription={renderDescription}
        searchId={searchId}
      />
    )}
    {renderRelatedSearches && searches && searches.size > 0 && (
      <div className='outreaches--outreach-view-related-searches'>
        Related to:{' '}
        <CommaDelimitedList>
          {searches.map(search => (
            <li key={search.get('id')}>
              <SearchLink
                rel='noopener noreferrer'
                searchId={search.get('id')}
                target='_blank'
              >
                {search.get('confidential')
                  ? search.get('code_name')
                  : search.get('name')}
              </SearchLink>
            </li>
          ))}
        </CommaDelimitedList>
      </div>
    )}
    {outreach.get('subject') ? (
      <div className='outreaches--outreach-view-subject'>
        <strong>Subject:</strong> {outreach.get('subject')}
      </div>
    ) : null}
    {outreach.get('notes') && (
      <ExpandableText
        characterLimit={150}
        content={outreach.get('notes')}
        isSanitizedHtml={true}
      />
    )}
    <AttachmentsView documents={outreach.get('documents')} />
  </div>
);

export const propTypes = {
  /**
   * The outreach to render.
   */
  outreach: ImmutablePropTypes.mapContains({
    contacted_on: PropTypes.string.isRequired,
    long_description: PropTypes.string.isRequired,
    subject: PropTypes.string,
    notes_partial: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,

  /**
   * True to render the date field, false to omit it.
   */
  renderDate: PropTypes.bool,

  /**
   * True to render the description field in the title of the outreach; false to omit it.
   */
  renderDescription: PropTypes.bool,

  /**
   * If true, any related searches will be listed; If false this will be omitted.
   */
  renderRelatedSearches: PropTypes.bool,

  /**
   * When true, the title portion of the outreach display will be rendered as a link to the
   * page displaying the candidacy's outreach tab.
   */
  renderTitleAsLink: PropTypes.bool,

  /**
   * The search records of the searches related to the outreach.
   */
  searches: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      name: PropTypes.string.isRequired,
    }),
  ),

  /**
   * If displaying this list in the context of a specific search, the ID of that search.
   */
  searchId: PropTypes.number,
};

export const defaultProps = {
  renderDate: true,
  renderDescription: true,
  renderRelatedSearches: true,
  renderTitleAsLink: true,
};

OutreachView.propTypes = propTypes;
OutreachView.defaultProps = defaultProps;

/**
 * The default export requires only a `outreachId`, while
 * the named export requires the outreach record.
 */
export default compose(
  setDisplayName('OutreachView(enhanced)'),

  // Either `outreach` or `outreachId` is required. If only an `outreachId`
  // is specified, the outreach will be fetched from state.
  setPropTypes({
    outreach: requiredIf(
      ImmutablePropTypes.mapContains({
        id: PropTypes.number.isRequired,
      }),
      ({ outreachId }) => !outreachId,
    ),

    outreachId: requiredIf(PropTypes.number, ({ outreach }) => !outreach),

    renderDate: PropTypes.bool,
  }),
  branch(props => props.outreachId && !props.outreach, mapOutreachIdToOutreach),
  withProps(({ outreach }) => ({
    searchIds: outreach && outreach.get('searches'),
  })),
  branch(props => props.renderRelatedSearches, mapSearchIdsToSearches),
)(OutreachView);

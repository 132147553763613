import PropTypes from 'prop-types';
import { branch, compose, lifecycle, setPropTypes } from 'recompose';

import { PARENT_TYPES } from '../constants';
import connectMeetingActions from './connectMeetingActions';

/**
 * When provided a parentId and parentType via props, fetches the meetings
 * for that parent if they need to be fetched so they are available for a component.
 */
export default compose(
  setPropTypes({
    parentId: PropTypes.number,
    parentType: PropTypes.oneOf(PARENT_TYPES),
  }),
  branch(({ meetingActions }) => !meetingActions, connectMeetingActions),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const { meetingActions, parentId, parentType } = this.props;
      if (parentType && parentId) {
        meetingActions.fetchMeetingsIfNeeded({
          parentId: parentId,
          parentType: parentType,
        });
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const { meetingActions, parentId, parentType } = nextProps;
      if (parentType && parentId) {
        meetingActions.fetchMeetingsIfNeeded({
          parentId: parentId,
          parentType: parentType,
        });
      }
    },
  }),
);

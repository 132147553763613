import getRecommendationsList from './getRecommendationsList';

/**
 * Determines if a new list of Recommended Candidates should be fetched
 * when there are no recommendations available for a search.
 * @param {Object} state
 * @param {Number} searchId The searchId of the recommendations.
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default (state, searchId) => {
  const recommendationsList = getRecommendationsList(state, searchId);
  if (recommendationsList) {
    const shouldCallAPI = recommendationsList.get('shouldCallRecommendAPI');

    if (recommendationsList.hasIn(['_meta', 'error'])) {
      return false;
    }

    if (shouldCallAPI) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

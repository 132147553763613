import { mapProps } from 'recompose';
import { PARENT_SEARCH } from '../constants';

/**
 * A higher-order component that takes `searchId` and maps it to the appropriate
 * `parentType` and `parentId` values that can be used to fetch/display
 * outreaches that belong to that search.
 */
export default mapProps(({ searchId, ...props }) => ({
  ...props,
  parentType: PARENT_SEARCH,
  parentId: searchId,
}));

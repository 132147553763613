import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import veteranStatusSchema from '../schemas/veteranStatuses';

/**
 * Renders the display name for a given veteran status ID.
 */
const VeteranStatusName = ({ veteranStatusId }) => (
  <EntityProperty
    entityId={veteranStatusId}
    entityType={veteranStatusSchema.key}
    propertyName='name'
  />
);

VeteranStatusName.propTypes = {
  veteranStatusId: PropTypes.number.isRequired,
};

export default VeteranStatusName;

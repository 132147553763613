import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ResidencyListItem from './ResidencyListItem';

/**
 * Renders a collection of residency records in a list.
 */
const ResidencyList = ({ residencyIds, residencyType }) => (
  <TransitionGroup className='list-group' component='ul'>
    {residencyIds.map(residencyId => (
      <CSSTransition
        classNames='fade'
        key={residencyId}
        timeout={{ enter: 500, exit: 250 }}
      >
        <ResidencyListItem
          key={residencyId}
          residencyId={residencyId}
          residencyType={residencyType}
        />
      </CSSTransition>
    ))}
  </TransitionGroup>
);

ResidencyList.propTypes = {
  /**
   * A list containing the IDs of the residencies to render.
   */
  residencyIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired)
    .isRequired,
  residencyType: PropTypes.string.isRequired,
};

export default ResidencyList;

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import contactSourceSchema from '../schemas/contactSources';

/**
 * Determines if a particular contact_source ID is a details contact_source.
 * @param {Object} state
 * @param {Number} id The contact_source ID
 * @return {Boolean} True if the contact_source with the given ID is a details contact_source;
 * otherwise false.
 */
export default (state, id) =>
  Boolean(getEntityProperty(state, contactSourceSchema.key, id, 'details'));

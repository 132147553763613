import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import { formatCurrency } from '@thrivetrm/ui/utilities/numberUtils';
import getRevenue from '../selectors/getRevenue';

const CompanyRevenueView = ({ revenueId }) => {
  const revenue = useSelector(state => getRevenue(state, revenueId)).toJS();

  return (
    <LabeledContent
      className='CompanyRevenueListView__item'
      label={revenue.year || 'Unknown'}
    >
      {revenue && revenue?.amount
        ? formatCurrency(revenue.amount, revenue.currency || 'USD', {
            notation: 'compact',
          })
        : null}
    </LabeledContent>
  );
};

CompanyRevenueView.propTypes = {
  revenueId: PropTypes.number.isRequired,
};

export default CompanyRevenueView;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import DateTime from 'modules/datetime/components/DateTime';

import mapTaskIdToTask from './mapTaskIdToTask';

const TaskDueDate = ({ task }) => (
  <div className='tasks-due-date'>
    <i className='fa fa-calendar' />
    &nbsp;
    <DateTime format='L [at] LT' value={task.get('reminder')} />
  </div>
);

TaskDueDate.propTypes = {
  task: ImmutablePropTypes.mapContains({
    reminder: PropTypes.string,
  }),
};

export default compose(
  setDisplayName('TaskDueDate(enhanced)'),
  setPropTypes({
    taskId: PropTypes.number.isRequired,
  }),
  mapTaskIdToTask,
)(TaskDueDate);

import { compose, setDisplayName } from 'recompose';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import RejectionField from 'modules/search-stages/components/RejectionField';

import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';

import connectJobApplicationActions from './connectJobApplicationActions';
import handleSubmitRejection from './handleSubmitRejection';
import mapJobApplicationIdToJobApplication from './mapJobApplicationIdToJobApplication';

const createRootFieldState = ({ defaultRejectionReason, jobApplication }) =>
  RejectionField.createFieldState('job_application', {
    jobApplication: jobApplication,
    defaultRejectionReason: defaultRejectionReason,
  });

/**
 * The form displayed when rejecting a job application from a search.
 */
export default compose(
  setDisplayName('JobApplicationRejectionForm'),
  connectJobApplicationActions,
  connectTransactions,
  mapJobApplicationIdToJobApplication,
  withTransactionTrackedForm(createRootFieldState, handleSubmitRejection, {
    formClassName: 'JobApplicationRejectionForm',
    omitProps: [
      'defaultRejectionReason',
      'jobApplication',
      'jobApplicationId',
      'jobApplicationActions',
      'transactionActions',
    ],
  }),
)(RejectionField);

import PropTypes from 'prop-types';
import React from 'react';
import RocketReachLinkList from './RocketReachLinkList';
import RocketReachListItem from './RocketReachListItem';

const PhoneTypes = {
  MOBILE: 'mobile',
  WORK: 'professional',
};

const EmailTypes = {
  PERSONAL: 'personal',
  WORK: 'professional',
};

const EmailValidationTypes = {
  INVALID: 'invalid',
  VALID: 'valid',
};

// DO NOT ALPHA SORT - property order determines display order
const basicAttributes = {
  current_title: 'Title',
  current_employer: 'Employer',
  location: 'Location',
  region: 'Region',
  city: 'City',
  postal_code: 'Postal Code',
  country_code: 'Country Code',
};

const groupEmailAddressesByType = data => {
  const personalEmail = data.current_personal_email;
  const workEmail = data.current_work_email;

  return {
    personal: personalEmail,
    work: workEmail,
    other: data.emails
      .filter(emailData => {
        const isValid = emailData.smtp_valid === EmailValidationTypes.VALID;
        const isOther = ![personalEmail, workEmail].includes(emailData.email);
        return isValid && isOther;
      })
      .map(emailData => emailData.email),
  };
};

// Unlike with emails where we receive a "current personal/work" value, phone numbers are only
// provided as a flat list. We are provided a "type" but no notion of "current", so we infer
// "current" by plucking the first personal phone and the first work phone out of the list.
// While there is 0 documentation about this in the RocketReach API docs, it aligns with how
// they present the data within the RocketReach UI, so we are mirroring that behavior here.
const groupPhoneNumbersByType = (phones = []) => {
  const mobilePhoneNumbers = phones
    .filter(phone => phone.type === PhoneTypes.MOBILE)
    .map(phone => phone.number);
  const workPhoneNumbers = phones
    .filter(phone => phone.type === PhoneTypes.WORK)
    .map(phone => phone.number);
  const unknownPhoneNumbers = phones
    .filter(phone => !Object.values(PhoneTypes).includes(phone.type))
    .map(phone => phone.number);

  return {
    mobile: mobilePhoneNumbers[0],
    work: workPhoneNumbers[0],
    other: [
      ...mobilePhoneNumbers.slice(1),
      ...workPhoneNumbers.slice(1),
      ...unknownPhoneNumbers,
    ],
  };
};

const RocketReachBody = ({ data }) => {
  const emailAddresses = groupEmailAddressesByType(data);
  const phoneNumbers = groupPhoneNumbersByType(data.phones);

  return (
    <div className='rocketreach-body'>
      <div className='avatar avatar-contact'>
        {data.profile_pic ? (
          <img alt='Profile' role='presentation' src={data.profile_pic} />
        ) : (
          <i className='fa fa-user fa-5x' />
        )}
      </div>
      <h4 className='rocketreach-contact-name'>{data.name}</h4>
      <div className='rocketreach-contact-attributes-ul'>
        <ul className='rocketreach-ul'>
          {Object.entries(basicAttributes).map(([attributeKey, label]) =>
            data[attributeKey] ? (
              <RocketReachListItem
                key={attributeKey}
                label={label}
                value={data[attributeKey]}
              />
            ) : null,
          )}
        </ul>
      </div>

      {emailAddresses.personal || emailAddresses.work ? (
        <div className='rocketreach-contact-attributes-ul'>
          <ul className='rocketreach-ul'>
            {emailAddresses.personal ? (
              <RocketReachListItem
                label='Personal Email'
                value={emailAddresses.personal}
              />
            ) : null}
            {emailAddresses.work ? (
              <RocketReachListItem
                label='Work Email'
                value={emailAddresses.work}
              />
            ) : null}
          </ul>
        </div>
      ) : null}

      {phoneNumbers.mobile || phoneNumbers.work ? (
        <div className='rocketreach-contact-attributes-ul'>
          <ul className='rocketreach-ul'>
            {phoneNumbers.mobile ? (
              <RocketReachListItem
                label='Mobile Phone'
                value={phoneNumbers.mobile}
              />
            ) : null}
            {phoneNumbers.work ? (
              <RocketReachListItem
                label='Work Phone'
                value={phoneNumbers.work}
              />
            ) : null}
          </ul>
        </div>
      ) : null}

      {emailAddresses.other.length ? (
        <div className='rocketreach-contact-attributes-ul'>
          <span className='rocketreach-title'>Other Emails:</span>
          <ul className='rocketreach-ul'>
            {emailAddresses.other.map(emailAddress => (
              <RocketReachListItem key={emailAddress} value={emailAddress} />
            ))}
          </ul>
        </div>
      ) : null}

      {phoneNumbers.other.length ? (
        <div className='rocketreach-contact-attributes-ul'>
          <span className='rocketreach-title'>Other Phone Numbers:</span>
          <ul className='rocketreach-ul'>
            {phoneNumbers.other.map(phoneNumber => (
              <RocketReachListItem key={phoneNumber} value={phoneNumber} />
            ))}
          </ul>
        </div>
      ) : null}

      {data.links ? <RocketReachLinkList data={data.links} /> : null}
    </div>
  );
};

RocketReachBody.propTypes = {
  data: PropTypes.shape({
    city: PropTypes.string,
    country_code: PropTypes.string,
    current_employer: PropTypes.string,
    current_personal_email: PropTypes.string,
    current_title: PropTypes.string,
    current_work_email: PropTypes.string,
    emails: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        smtp_valid: PropTypes.oneOf(Object.values(EmailValidationTypes)),
        type: PropTypes.oneOf(Object.values(EmailTypes)),
      }),
    ),
    links: PropTypes.objectOf(PropTypes.string),
    location: PropTypes.string,
    name: PropTypes.string,
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string,
        type: PropTypes.oneOf(Object.values(PhoneTypes)),
      }),
    ),
    postal_code: PropTypes.string,
    profile_pic: PropTypes.string,
    region: PropTypes.string,
  }).isRequired,
};

export default RocketReachBody;

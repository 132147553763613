import PropTypes from 'prop-types';
import React, { Component } from 'react';
import KeyCode from 'keycode-js';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import OptionList from './OptionList';
import VirtualOptionList from './VirtualOptionList';

/**
 * Renders the dropdown component of the MultiSelect, which shows a search input. When no
 * search text is entered, the currently selected values are shown. When search text is entered,
 * available options that match that search value are shown.
 */
class MultiSelectDropDown extends Component {
  constructor(props) {
    super(props);

    const { idFromOption, options, searchValue, value } = this.props;
    const displayOptions = searchValue ? options : value;

    this.state = {
      /**
       * The list of options currently being displayed.
       */
      displayOptions: displayOptions, // eslint-disable-line react/no-unused-state

      /**
       * The ID of the focused option.
       */
      focusedId:
        displayOptions && displayOptions.length
          ? idFromOption(displayOptions[0])
          : null,
    };
  }

  componentDidMount() {
    // We have to add the event handler on the next tick because if this popover
    // is triggered by a click event itself (as it is with the NotificationIndicator),
    // that click event will be caught here and cause the popover to simply close
    // immediately.
    setTimeout(() => {
      // Our click listener MUST be done in the CAPTURE phase beause otherwise
      // we may not see our click event until after a rerender, in which case
      // the target is no longer attached to the DOM, and we have no way of
      // identifying whether it is inside our component or not!
      document.addEventListener('click', this.handleDocumentClick, true);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      idFromOption,
      options,
      searchValue,
      showWithoutSearching,
      value,
    } = nextProps;
    // Updates the `displayOptions` and `focusedId` state values when we have new props.
    const { focusedId } = this.state;
    const displayOptions =
      (searchValue || showWithoutSearching ? options : value) || [];
    const newState = { displayOptions: displayOptions };

    if (
      !focusedId ||
      displayOptions.findIndex(opt => idFromOption(opt) === focusedId) < 0
    ) {
      // If there is no focusedId specified yet, or the current focusedId no longer exists
      // in the options list, then focus the first option, if any.
      newState.focusedId = displayOptions.length
        ? idFromOption(displayOptions[0])
        : null;
    }

    this.setState(newState);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
  }

  setContainerRef = el => {
    this.containerEl = el;
  };

  /**
   * Called when the search input is mounted, allowing us to focus the input.
   */
  setInputRef = input => {
    this.inputEl = input;

    if (input) {
      // Tether repositions this almost immediately after rendering at the bottom of the DOM --
      // so we need to wait until the next tick before we focus, otherwise the browser will
      // try to scroll the element into view and that will cause it to scroll to the bottom of
      // the page.
      setTimeout(() => {
        input.focus();
      });
    }
  };

  handleDocumentClick = event => {
    const { onClose } = this.props;
    if (this.containerEl && !this.containerEl.contains(event.target)) {
      onClose(event);
    }
  };

  handleInputKeyDown = event => {
    const { focusedId } = this.state;

    // TODO: Handle page up/page down? Allow focusing multiple options by holding ctrl?
    switch (event.keyCode) {
      case KeyCode.KEY_RETURN:
      case KeyCode.KEY_ENTER:
        return this.toggleId(focusedId);
      case KeyCode.KEY_UP:
        event.preventDefault();
        return this.focusPrevious();
      case KeyCode.KEY_DOWN:
        event.preventDefault();
        return this.focusNext();
      default:
        return false;
    }
  };

  /**
   * Called when an option in the list is selected.
   * @param {String} id The option's ID
   */
  handleOptionSelect = id => {
    this.toggleId(id);
  };

  /**
   * Called when an option should be focused.
   * @param {[type]} id [description]
   * @return {[type]} [description]
   */
  handleOptionFocus = id => {
    this.setState({ focusedId: id });
  };

  /**
   * Focuses the next sequential option in the list of displayed options by a given delta value.
   * @param {Number} delta The number of positions to move the focus.
   */
  focusNext(delta = 1) {
    const {
      idFromOption,
      options: propOptions,
      searchValue,
      showWithoutSearching,
      value,
    } = this.props;
    const options = searchValue || showWithoutSearching ? propOptions : value;
    const { focusedId } = this.state;
    let newIndex = 0;

    if (focusedId) {
      // First find the index of currently selected option.
      const focusedIndex = options.findIndex(
        option => idFromOption(option) === focusedId,
      );

      if (focusedIndex >= 0) {
        // Modify that by the delta value -- but make sure it's not less than 0 or larger
        // than the available number of options.
        newIndex = Math.max(
          0,
          Math.min(focusedIndex + delta, options.length - 1),
        );
      }
    }

    // Given the newIndex, look up the underlying ID value for that option.
    this.setState({
      focusedId:
        options.length && options[newIndex]
          ? idFromOption(options[newIndex])
          : null,
    });
  }

  /**
   * Focuses the previous item in the list of options.
   */
  focusPrevious() {
    this.focusNext(-1);
  }

  /**
   * Toggles the selected state of the option with the given ID.
   */
  toggleId(id) {
    const { idFromOption, onChange, options, value } = this.props;

    // First check `value` to see if that ID is currently selected.
    const valueIndex = value.findIndex(option => idFromOption(option) === id);
    if (valueIndex >= 0) {
      // It's a selected value, so we want to remove it from the value array.
      onChange(value.filter((el, index) => index !== valueIndex));
    } else {
      // Not currently selected, so find the underlying option based on the ID, and add it
      // to the current value array.
      const opt = options.find(option => idFromOption(option) === id);
      if (opt) {
        onChange(value.concat(opt));
      }
    }
  }

  render() {
    const {
      idFromOption,
      isLoading,
      noSearchResultsText,
      noValueText,
      onSearchValueChange,
      options,
      renderOptionLabel,
      searchValue,
      showWithoutSearching,
      style,
      useVirtualRendering,
      value,
      width,
    } = this.props;
    const { focusedId } = this.state;
    const isSearching = Boolean(searchValue) || showWithoutSearching;
    const displayOptions = isSearching ? options : value;
    const emptyText = isSearching ? noSearchResultsText : noValueText;
    const OptionListComponent = useVirtualRendering
      ? VirtualOptionList
      : OptionList;

    return (
      <div
        className='multi-select-dropdown'
        ref={this.setContainerRef}
        style={{
          position: 'absolute',
          zIndex: 2,
          width: width,
          ...style,
        }}
      >
        <div className='multi-select-dropdown-header'>
          <input
            className='form-control'
            name='MultiSelectDropdownSearch'
            onChange={onSearchValueChange}
            onKeyDown={this.handleInputKeyDown}
            ref={this.setInputRef}
            tabIndex={-1}
            type='search'
            value={searchValue}
          />
          {isLoading ? <LoadingIndicator /> : null}
        </div>
        {displayOptions && displayOptions.length > 0 ? (
          <OptionListComponent
            focusedId={focusedId}
            idFromOption={idFromOption}
            onFocus={this.handleOptionFocus}
            onSelect={this.handleOptionSelect}
            options={displayOptions}
            renderOptionLabel={renderOptionLabel}
            value={value}
          />
        ) : (
          <div className='multi-select-empty-text'>{emptyText}</div>
        )}
      </div>
    );
  }
}

MultiSelectDropDown.propTypes = {
  /**
   * A function that returns the unique ID for a given option value. This function is
   * called with the option itself, and must return a string.
   */
  idFromOption: PropTypes.func.isRequired,

  /**
   * True to show the loading indicator, otherwise false.
   */
  isLoading: PropTypes.bool,

  /**
   * The text to display when there are no options to display in the dropdown when search text has
   * been entered.
   */
  noSearchResultsText: PropTypes.node,

  /**
   * The text to display in the dropdown when there is no search text entered and there are no
   * values selected. Note that this is different from the `placeholder` value -- the placeholder
   * is displayed in the button, while `noValueText` is displayed in the dropdown.
   */
  noValueText: PropTypes.string,

  /**
   * Called when the selected values are changed. Called with the array of selected values.
   */
  onChange: PropTypes.func,

  /**
   * Called when the dropdown should be closed.
   */
  onClose: PropTypes.func.isRequired,

  /**
   * Called when the search intput value is changed.
   */
  onSearchValueChange: PropTypes.func,

  /**
   * The list of available options to select from. Displayed when the user has entered a search
   * value.
   */
  options: PropTypes.array, // eslint-disable-line react/forbid-prop-types

  /**
   * Renders the label for a single option. The function is given the option/value itself,
   * and should return a renderable node (string, Node, number, etc) that will be displayed for that
   * option
   */
  renderOptionLabel: PropTypes.func.isRequired,

  /**
   * The value of the search input
   */
  searchValue: PropTypes.string,

  /**
   * Shows options in multi-select dropdown without having to search.
   */
  showWithoutSearching: PropTypes.bool,

  /**
   * The style to apply to the dropdown component. Typically passed in from the parent
   * MultiSelect component.
   */
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types

  /**
   * True to use virtualized rendering for option items; false to render all items to the DOM
   */
  useVirtualRendering: PropTypes.bool,

  /**
   * The currently selected options. This must always be an array, and should match the shape
   * of the options.
   */
  value: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types

  /**
   * The desired width of the dropdown.
   */
  width: PropTypes.number,
};

MultiSelectDropDown.defaultProps = {
  noSearchResultsText: 'No results',
  noValueText: 'No items selected',
  useVirtualRendering: true,
};

export default MultiSelectDropDown;

import getEntity from 'modules/entities/selectors/getEntity';
import { candidacyStatusSchema } from '../schemas';

/**
 * Gets the candidacy record status for a candidacy
 * @param {Object} state
 * @param {Number} candidacyId The ID of the candidacy
 * @return {Immutable.Map|undefined} The status record, if found.
 */
export default (state, candidacyId) =>
  getEntity(state, candidacyStatusSchema.key, candidacyId);

import React from 'react';
import PropTypes from 'prop-types';
import DateRange from '@thrivetrm/ui/components/DateRange';
import ExpandableActivityView from './ExpandableActivityView';

const EventContactActivityView = ({
  description,
  endTime,
  eventContactNotes,
  role,
  startTime,
  status,
  timezone,
}) => {
  const eventDescription = description
    ? `<b class='u-textColor-gray60'>Event Description: </b>
    ${description}`
    : null;
  const spacing = eventDescription ? '<br />' : null;
  const guestNotes = eventContactNotes
    ? `${spacing} <b class='u-textColor-gray60'>Guest Notes: </b> ${eventContactNotes}`
    : null;

  return (
    <div className='u-marginTop-4 u-fontSize-small'>
      {startTime || endTime ? (
        <p className='u-margin-n'>
          <b className='u-textColor-gray60'>Event Date: </b>
          <DateRange
            endDate={endTime}
            format='dateTime'
            startDate={startTime}
            timezone={timezone}
          />
        </p>
      ) : null}
      {role ? (
        <p className='u-margin-n'>
          <b className='u-textColor-gray60'>Role: </b>
          {role}
        </p>
      ) : null}
      {status ? (
        <p className='u-margin-n'>
          <b className='u-textColor-gray60'>Status: </b>
          {status}
        </p>
      ) : null}
      {eventDescription || guestNotes ? (
        <ExpandableActivityView content={`${eventDescription} ${guestNotes}`} />
      ) : null}
    </div>
  );
};

EventContactActivityView.propTypes = {
  description: PropTypes.string,
  endTime: PropTypes.string,
  eventContactNotes: PropTypes.string,
  role: PropTypes.string,
  startTime: PropTypes.string,
  status: PropTypes.string,
  timezone: PropTypes.string,
};

export default EventContactActivityView;

import { connect } from 'react-redux';
import getAssessmentProperty from '../selectors/getAssessmentProperty';

/**
 * A Higher order component that filters a list of assessment IDs to only include assessments
 * that have a specific `type` value specified, as specified by a particular prop (the prop named
 * as the `filterTypePropName` option).
 * @param {Object} options
 * @param {String} [options.assessmentIdsPropName="assessmentIds"] The name of the prop that will
 *   hold the list of IDs.
 * @param {String} options.filterTypePropName The name of the prop that will be used to supply the
 *   type to filter on.
 */
export default ({
  assessmentIdsPropName = 'assessmentIds',
  filterTypePropName,
} = {}) =>
  connect((state, props) => {
    const type = props[filterTypePropName];
    const ids = props[assessmentIdsPropName];

    return {
      [assessmentIdsPropName]:
        ids && type
          ? ids.filter(id => getAssessmentProperty(state, id, 'type') === type)
          : ids,
    };
  }, {});

import { List } from 'immutable';
import {
  compose,
  mapProps,
  setDisplayName,
  setStatic,
  withProps,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import mapFundIdsToFunds from './mapFundIdsToFunds';
import withFundListFetched from './withFundListFetched';
import MultiSelect from '../../../components/forms/multiselect/MultiSelect';

const createFieldState = (name, value, validator, _, ...rest) =>
  FieldState.create(
    name,
    // If am Immutable.List was passed in, convert it to an array
    List.isList(value) ? value.toArray() : value || [],
    validator,
    // Make sure any values returned are simply IDs.
    outputValue => outputValue.map(item => item.id || item),
    ...rest,
  );

/**
 * A multi-select for selecting from a list of funds
 */
export default compose(
  setDisplayName('FundMultiSelectField'),
  setStatic('createFieldState', createFieldState),
  withFormGroup,
  asField(),

  // Make sure we have the list of funds.
  withFundListFetched,

  // Pull out the IDs and loading state
  withProps(({ fundList }) => ({
    fundIds: fundList && fundList.get('ids'),
    isLoading: fundList && fundList.getIn(['_meta', 'isFetching']),
  })),

  // Get the underlying fund records from the fundIds.
  mapFundIdsToFunds,

  // Keep track of any search value entered into the MultiSelect
  withStateHandlers(
    {
      filterText: '',
    },
    {
      onSearchValueChange: () => filterText => ({ filterText: filterText }),
    },
  ),

  // Filter the fund list if any filterText was entered (and we have the funds loaded).
  // No filter text or no funds, just pass them through.
  withPropsOnChange(['funds', 'filterText'], ({ filterText, funds }) => ({
    filteredFunds:
      funds && funds.count() && stringUtils.trim(filterText)
        ? funds.filter(fund =>
            stringUtils.caseInsensitiveIncludes(fund.get('name'), filterText),
          )
        : funds,
  })),

  mapProps(
    ({
      filteredFunds,
      funds,
      isLoading,
      onChange,
      onSearchValueChange,
      value,
    }) => ({
      isLoading: isLoading,
      onChange: onChange,
      onSearchValueChange: onSearchValueChange,
      options: filteredFunds ? filteredFunds.toJS() : [],
      placeholder: 'No funds selected',
      renderOptionLabel: option => {
        // The `value` is a list of IDs, so in the case that we are rendering something that is just
        // an ID, lookup the related fund name.
        if (typeof option === 'number' && funds) {
          const fund = funds.find(
            fundRecord => fundRecord.get('id') === option,
          );
          return fund ? fund.get('name') : '';
        }

        return option.name;
      },
      showWithoutSearching: true,
      summaryLabel: '{0} funds selected',
      useVirtualRendering: false,
      value: value,
    }),
  ),
)(MultiSelect);

import getEntity from 'modules/entities/selectors/getEntity';
import referenceSchema from '../schema';

/**
 * Gets a reference record by it's ID
 * @param {Object} state
 * @param {Number} id The reference ID
 * @return {Immutable.Map|undefined} The reference record, if found
 */
export default (state, id) => getEntity(state, referenceSchema.key, id);

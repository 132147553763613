import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import locationSchema from '../schema';
import {
  LOCATIONS_LIST_FETCH_START,
  LOCATIONS_LIST_FETCH_SUCCESS,
  LOCATIONS_LIST_FETCH_FAILURE,
} from './actionTypes';

/**
 * Creates an action for fetching a list of locations
 * @param {Object} payload
 */

export default compose(
  fetchAction,
  normalizeResponse({ locations: [locationSchema] }),
  createAsyncActions({
    startActionType: LOCATIONS_LIST_FETCH_START,
    successActionType: LOCATIONS_LIST_FETCH_SUCCESS,
    failureActionType: LOCATIONS_LIST_FETCH_FAILURE,
  }),
  withOptions(() => ({
    url: routes.api_v1_locations(),
  })),
);

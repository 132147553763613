import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import fundSchema from '../schema';

/**
 * Renders the name of a fund given it's ID.
 */
export default compose(
  setDisplayName('FundName'),
  setPropTypes({
    fundId: PropTypes.number.isRequired,
  }),
  defaultProps(EntityProperty.defaultProps),
  mapProps(({ fundId, ...rest }) => ({
    ...rest,
    propertyName: 'name',
    entityType: fundSchema.key,
    entityId: fundId,
  })),
)(EntityProperty);

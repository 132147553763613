import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { pure, compose, branch, setPropTypes, withProps } from 'recompose';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import withDraftStorage from '../../../components/forms/withDraftStorage';
import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';
import handleSubmit from './handleSubmit';
import TaskField from './TaskField';
import mapTaskIdToTask from './mapTaskIdToTask';
import * as taskActionCreators from '../actions';

export const createRootFieldState = ({
  contactId,
  currentUserId,
  draft,
  task,
}) => {
  // Heads Up! This component differs from the usual pattern of providing an immutable map as the second argument
  // for the static method `createRootFieldState`.  To render the value a JS object needs to be provided.
  let taskValue;
  if (task) {
    taskValue = task.toJS();
    if (draft?.content) {
      taskValue.notes = draft.content;
    }
  } else {
    // When creating a new task
    taskValue = {
      contact: contactId,
      notes: draft?.content,
      assigned_to: currentUserId,
    };
  }

  return TaskField.createFieldState('task', taskValue);
};

const propTypes = {
  /**
   * Called when the "cancel" button is clicked on the form.
   */
  onCancel: PropTypes.func,

  /**
   * Called when the form has been successfully submitted.
   */
  onSaved: PropTypes.func,

  /**
   * If editing an existing task, the record that is being edited.
   */
  task: ImmutablePropTypes.map,
};

export default compose(
  setPropTypes(propTypes),
  pure,
  connect(
    state => ({ currentUserId: state.user.get('current_user_id') }),
    dispatch => ({
      taskActions: bindActionCreators(taskActionCreators, dispatch),
    }),
  ),
  connectTransactions,
  branch(({ task, taskId }) => !task && taskId, mapTaskIdToTask),
  withDraftStorage(),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'task-form',
    omitProps: [
      'currentUserId',
      'contactId',
      'formState',
      'task',
      'taskActions',
    ],
  }),
  withProps({ showContactSelect: true }),
)(TaskField);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import mapContactIdToTalentPoolId from './mapContactIdToTalentPoolId';
import TalentPoolLink from './TalentPoolLink';
import JobTitle from './JobTitle';

/**
 * When the contact has an associated talent pool, renders a link to the talent pool with the
 * talent pool name as the contact; Otherwise simply renders the contact's job title.
 */
export const TalentPoolNameLink = ({ contactId, talentPoolId }) => {
  if (!talentPoolId) {
    return (
      <span className='talent-pool-name'>
        <JobTitle contactId={contactId} />
      </span>
    );
  }

  return (
    <TalentPoolLink className='talent-pool-link' talentPoolId={talentPoolId}>
      <JobTitle contactId={contactId} />
    </TalentPoolLink>
  );
};

TalentPoolNameLink.propTypes = {
  contactId: PropTypes.number.isRequired,

  talentPoolId: PropTypes.number,
};

export default compose(
  setDisplayName('connect(TalentPoolNameLink)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToTalentPoolId,
)(TalentPoolNameLink);

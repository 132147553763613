import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setStatic, withHandlers } from 'recompose';

import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import withSearchSummaryReportFieldLabels from './withSearchSummaryReportFieldLabels';
/**
 * A field for selecting add-on options for the search summary report.
 */
const SearchSummaryReportAddOnsField = ({
  fieldLabels,
  fieldState,
  handleFieldChange,
  ...props
}) => {
  const hasAssessmentTemplates = useFeatureCheck(
    'feature.assessment_templates',
  );

  return (
    <div className='SearchSummaryReportAddOnsField'>
      <h3>Add-ons</h3>
      {hasAssessmentTemplates ? null : (
        <CheckboxField
          {...props}
          checked={fieldState
            .getNestedField('assessment_attribute_report')
            .getValue()}
          fieldState={fieldState.getNestedField('assessment_attribute_report')}
          key='assessment_attribute_report'
          onChange={handleFieldChange}
          text={fieldLabels.assessment_attribute_report}
        />
      )}
      <CheckboxField
        {...props}
        checked={fieldState.getNestedField('notes').getValue()}
        fieldState={fieldState.getNestedField('notes')}
        key='notes'
        onChange={handleFieldChange}
        text={fieldLabels.notes}
      />
    </div>
  );
};

SearchSummaryReportAddOnsField.propTypes = {
  /**
   * a mapping of search summary report field names to their labels
   */
  fieldLabels: PropTypes.objectOf(PropTypes.string),

  /**
   * The current value of this field.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the fieldState changes for a nested field of the `fieldState`.
   */
  handleFieldChange: PropTypes.func.isRequired,
};

SearchSummaryReportAddOnsField.createFieldState = (
  name = 'addons',
  values = {},
  validator,
  _convertToRaw,
  ...rest
) =>
  FieldState.createNested(
    name,
    [
      CheckboxField.createFieldState(
        'assessment_attribute_report',
        Boolean(values.assAttrChecked),
      ),
      CheckboxField.createFieldState('notes', Boolean(values.notesChecked)),
    ],
    validator,

    // convertToRaw implementation returns an array of strings of the checked options.
    fieldValues =>
      Object.keys(fieldValues).filter(fieldName => fieldValues[fieldName]),
    ...rest,
  );

export default compose(
  setDisplayName('SearchSummaryReportAddOnsField(enhanced)'),
  setStatic(
    'createFieldState',
    SearchSummaryReportAddOnsField.createFieldState,
  ),
  withHandlers({
    handleFieldChange: ({ fieldState, onChange }) => childFieldState =>
      onChange(fieldState.setNestedField(childFieldState)),
  }),
  withSearchSummaryReportFieldLabels,
)(SearchSummaryReportAddOnsField);

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AddressView from 'modules/addresses/components/AddressView';
import getAddress from 'modules/addresses/selectors/getAddress';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import EditableSection from 'modules/forms/components/EditableSection';
import CompanyPrimaryAddressForm from './CompanyPrimaryAddressForm';

const CompanyAddressEditableSection = ({ addressId, companyId }) => {
  const address = useSelector(state => getAddress(state, addressId));
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const isEnriched = useSelector(state =>
    getEntityProperty(state, 'addresses', addressId, 'enriched'),
  );

  return (
    <EditableSection
      addressId={addressId}
      className='CompanyAddressEditableSection u-marginTop-24'
      companyId={companyId}
      formComponent={CompanyPrimaryAddressForm}
      isEnriched={hasSkyminyrFeature && isEnriched}
      parentId={companyId}
      parentType='Company'
      title='Address'
    >
      {address && <AddressView hideLocationIcon={true} value={address} />}
    </EditableSection>
  );
};

CompanyAddressEditableSection.defaultProps = {
  addressId: null,
};

CompanyAddressEditableSection.propTypes = {
  addressId: PropTypes.number,
  companyId: PropTypes.number.isRequired,
};

export default CompanyAddressEditableSection;

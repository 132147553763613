import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Modal from 'modules/core/componentsLegacy/Modal';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import isAdmin from 'modules/auth/selectors/isAdmin';
import { push } from 'modules/routing/routingActions';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import ResumeUpdateForm from './ResumeUpdateForm';
import RocketReachPopover from '../../rocketreach/RocketReachPopover';

const SOVREN = 'sovren';
const ROCKETREACH = 'rocketreach';

const ContactUpdateDropdown = ({
  clearTransaction,
  contact,
  contactReviewActions,
  rocketreach,
  transactions,
}) => {
  const [parser, setParser] = useState(null);
  const [showRocketReach, setShowRocketReach] = useState(false);
  const dispatch = useDispatch();
  const hasAdminPermissions = useSelector(isAdmin);
  const hasMergePermissions = useFeatureCheck('feature.contact_merge');
  const canMergeContact = hasAdminPermissions || hasMergePermissions;

  const resetState = () => {
    setParser(null);
    setShowRocketReach(false);
  };

  const handleChange = action => {
    if (action === ROCKETREACH) {
      setShowRocketReach(true);
    } else {
      setParser(action);
    }
  };

  const contactId = contact.getIn(['data', 'id']);
  /**
   * Redirect to the edit page for the contact review, applied to the
   * selected contact
   */
  const handleSaved = ({ data }) => {
    dispatch(push(`/contacts/${contactId}/pending/${data.id}`));
  };

  return (
    <>
      <DropdownButton
        bsSize='sm'
        id='contact-resume-uploader-select'
        onSelect={handleChange}
        title='Update Contact From'
      >
        <MenuItem eventKey={SOVREN}>Resume or Linkedin PDF</MenuItem>
        {rocketreach && <MenuItem divider={true} />}
        {rocketreach && <MenuItem eventKey={ROCKETREACH}>RocketReach</MenuItem>}
        {canMergeContact && (
          <>
            <MenuItem divider={true} />
            <MenuItem href={`/contacts/${contactId}/merge`}>
              Another Contact (Merge)
            </MenuItem>
          </>
        )}
      </DropdownButton>

      {showRocketReach && (
        <RocketReachPopover
          contact={contact.get('data').toJS()}
          onClose={resetState}
        />
      )}

      <Modal onHide={resetState} show={Boolean(parser)}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Resume Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {parser && (
            <ResumeUpdateForm
              clearTransaction={clearTransaction}
              contactId={contact.getIn(['data', 'id'])}
              contactReviewActions={contactReviewActions}
              multiple={false}
              onCancel={resetState}
              onSaved={handleSaved}
              parser={parser}
              transactions={transactions}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

ContactUpdateDropdown.propTypes = {
  clearTransaction: PropTypes.func.isRequired,

  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      user: ImmutablePropTypes.shape({
        id: PropTypes.number,
        invitation_accepted_at: PropTypes.string,
        invitation_created_at: PropTypes.string,
      }),
    }),
  }),

  contactReviewActions: PropTypes.shape({
    createContactReview: PropTypes.func.isRequired,
  }).isRequired,
  rocketreach: PropTypes.bool,
  transactions: transactionsState.isRequired,
};

export default ContactUpdateDropdown;

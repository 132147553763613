import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import DirectReportsManagementModal from './DirectReportsManagementModal';
import withContactFetched from '../withContactFetched';

/**
 * Renders a button that, when clicked, displays the
 * DirectReportsManagementModal dialog,
 */
const DirectReportsManagementButton = ({
  contact,
  contactId,
  hideModal,
  isModalOpen,
  showModal,
}) => (
  <div className='DirectReportsManagementButton'>
    <i className='fa fa-sitemap' />
    <span>
      {' '}
      {contact &&
        contact.get('direct_reports_count') > 0 &&
        contact.get('direct_reports_count')}{' '}
    </span>
    <button className='btn btn-sm btn-link' onClick={showModal} type='button'>
      <i className='fa fa-plus-circle' />
    </button>
    {isModalOpen && (
      <DirectReportsManagementModal
        contactId={contactId}
        onHide={hideModal}
        show={isModalOpen}
      />
    )}
  </div>
);

DirectReportsManagementButton.propTypes = {
  /**
   * The list state of the direct reports belonging to the contact.
   */
  contact: ImmutablePropTypes.mapContains({
    direct_reports_count: PropTypes.number,
  }),

  /**
   * The ID of the contact to show the direct reports for.
   */
  contactId: PropTypes.number.isRequired,

  /**
   * Called when the modal should be hidden
   * (provided by withProps HOC, below)
   */
  hideModal: PropTypes.func.isRequired,

  /**
   * Determines whether the modal form is shown
   * (managed by withState HOC, below)
   */
  isModalOpen: PropTypes.bool.isRequired,

  /**
   * Called when the modal should be shown
   * (provided by withProps HOC, below)
   */
  showModal: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('connect(DirectReportsManagementButton)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  withState('isModalOpen', 'setIsModalOpen', false),
  withHandlers({
    showModal: ({ setIsModalOpen }) => () => setIsModalOpen(true),
    hideModal: ({ setIsModalOpen }) => () => setIsModalOpen(false),
  }),
  withContactFetched(),
)(DirectReportsManagementButton);

import { connect } from 'react-redux';
import getTalentPoolIdForContactId from '../selectors/getTalentPoolIdForContactId';

/**
 * A higher order component which maps a `contactId` prop value to it's `talentPoolId` value.
 */
export default connect(
  (state, { contactId }) => ({
    talentPoolId: getTalentPoolIdForContactId(state, contactId),
  }),
  {},
);

import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import {
  ADDRESSES_FETCH_START,
  ADDRESSES_FETCH_SUCCESS,
  ADDRESSES_FETCH_FAILURE,
} from './actionTypes';
import addressSchema from '../addressSchema';
import getAddressesListUrl from './getAddressesListUrl';

/**
 * Fetches the list of addresses for a parent.
 * @param {Object} options
 * @param {String} options.parentId The ID of the parent to fetch the addresses for.
 * @param {String} options.parentType The type of the parent ("Company", "Contact")
 */
export default compose(
  fetchAction,
  normalizeResponse({ addresses: [addressSchema] }),
  createAsyncActions({
    startActionType: ADDRESSES_FETCH_START,
    successActionType: ADDRESSES_FETCH_SUCCESS,
    failureActionType: ADDRESSES_FETCH_FAILURE,
  }),
  withOptions(({ parentId, parentType }) => ({
    url: getAddressesListUrl({ parentId: parentId, parentType: parentType }),
  })),
);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PopoverTrigger from 'modules/core/componentsLegacy/PopoverTrigger';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import SearchStageIndicatorDot from 'modules/search-stages/components/SearchStageIndicatorDot';
import RejectionReasonsList from '../RejectionReasonsList';

/**
 * Renders a single list item for the `CandidaciesPerStageList`
 * If the stage is a rejected stage, then a link is displayed that, when clicked,
 * shows a popover displaying the breakdown of rejection reasons.
 */
const CandidaciesPerStageListItem = ({
  count,
  rejectionReasons,
  stage,
  totalCount,
}) => (
  <li key={stage.get('id')}>
    <span className='description'>
      <SearchStageIndicatorDot stage={stage} />
      <span>
        {stage.get('name')}{' '}
        {stage.get('rejected') &&
          rejectionReasons &&
          rejectionReasons.count() > 0 && (
            <PopoverTrigger
              content={
                <RejectionReasonsList rejectionReasons={rejectionReasons} />
              }
              placement='top'
              rootClose={true}
              title='Reasons for Rejection'
              trigger='click'
            >
              <a
                href='#reasons'
                onClick={preventDefaultHandler}
                style={{ textTransform: 'none' }}
              >
                (details)
              </a>
            </PopoverTrigger>
          )}
      </span>
    </span>
    <span className='value'>
      <span className='amount'>{count}</span>
      <span className='percentage'>
        {' '}
        ({!totalCount ? '0' : ((count / totalCount) * 100).toFixed()}
        %)
      </span>
    </span>
  </li>
);

CandidaciesPerStageListItem.propTypes = {
  count: PropTypes.number.isRequired,

  /**
   * The summary of rejection reasons for candicaces in the rejected stage.
   */
  rejectionReasons: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      reason: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }).isRequired,
  ),

  /**
   * The stage being rendered.
   */
  stage: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    rejected: PropTypes.bool.isRequired,
  }).isRequired,

  /**
   * The count of all candidates in all stages being rendered (used to determine the percentage
   * to display for the current count)
   */
  totalCount: PropTypes.number.isRequired,
};

export default CandidaciesPerStageListItem;

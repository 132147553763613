import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import CandidacyLocationName from 'modules/candidacies/components/CandidacyLocationName';
import mapCandidacyIdToContactId from 'modules/candidacies/components/mapCandidacyIdToContactId';
import SearchCandidacyLink from 'modules/candidacies/components/SearchCandidacyLink';
import withCandidacyTagPermissions from 'modules/candidacies/components/tags/withCandidacyTagPermissions';
import CandidacyTagColorSquareListEditable from 'modules/candidacies/components/tags/CandidacyTagColorSquareListEditable';

import ContactName from 'modules/contacts/components/ContactName';
import ContactPrimaryPosition from 'modules/contacts/components/positions/ContactPrimaryPosition';
import { useSelector } from 'react-redux';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_PIPELINE } from 'modules/searches/constants';

/**
 * The "primary" details section of the candidacy list item.
 * For "large" card renders:
 *   * Candidacy name
 *   * Primary company and position name
 *   * Current location
 * For "small" card renders:
 *   * Candidacy name
 *   * Primary company and position name
 */
const CandidacyListItemSectionDetailsPrimary = ({
  candidacyId,
  className,
  contactId,
  searchId,
  showTags,
  smallCard,
  ...props
}) => {
  const { activeSection } = useParams();
  const searchType = useSelector(state => getSearchType(state, searchId));

  return (
    <div
      className={classnames(
        className,
        'CandidacyListItemSectionDetailsPrimary',
      )}
      {...props}
    >
      <SearchCandidacyLink
        candidacyId={candidacyId}
        tab={activeSection ?? null}
      >
        <ContactName contactId={contactId} />
      </SearchCandidacyLink>
      <ContactPrimaryPosition contactId={contactId} />
      {smallCard ? null : (
        <div>
          <CandidacyLocationName
            candidacyId={candidacyId}
            iconPosition='right'
          />
          {showTags && searchType !== TYPE_PIPELINE ? (
            <CandidacyTagColorSquareListEditable
              candidacyId={candidacyId}
              searchId={searchId}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

CandidacyListItemSectionDetailsPrimary.propTypes = {
  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * An optional class name to apply to the container div
   */
  className: PropTypes.string,

  /**
   * The ID of the contact that the candidacy belongs to
   */
  contactId: PropTypes.number.isRequired,

  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,
  /**
   * True to show the candidacy tags (color squares + edit button)
   */
  showTags: PropTypes.bool,

  /**
   * Weather or not to show content on the 'small'
   * or regular candidacy card.
   */
  smallCard: PropTypes.bool,
};

CandidacyListItemSectionDetailsPrimary.defaultProps = {
  showTags: false,
};

export default compose(
  setDisplayName('CandidacyListItemSectionDetailsPrimary(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  mapCandidacyIdToContactId,
  withCandidacyTagPermissions({ isPermittedPropName: 'showTags' }),
)(CandidacyListItemSectionDetailsPrimary);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import { connect } from 'react-redux';
import RecommendationListItem from './RecommendationListItem';
import withRecommendationsListFetched from './withRecommendationsListFetched';
import withRecommendedFetched from './withRecommendedFetched';
import withRecommendationsSorted from './withRecommendationsSorted';
import getSearchReadyForRecommendations from '../../searches/selectors/getSearchReadyForRecommendations';
import {
  SORT_BY_TYPES,
  NOT_A_FIT_LABEL,
  RECOMMENDED_LABEL,
} from '../constants';
import filteredByText from './filteredByText';
import RecommendationListItemGroupHeader from './RecommendationListItemGroupHeader';
import recommendedCandidatePropType from '../recommendedCandidatePropType';

export const RecommendationsList = ({
  fetchRecommendationsList,
  hasError,
  hasRecommendations,
  isFetching,
  results,
}) => (
  <div className='recommendations--recommendations-view'>
    {!isFetching && hasError && (
      <ErrorAlert
        onRetry={fetchRecommendationsList}
        title='There was an error fetching recommendations'
      />
    )}

    {!hasRecommendations && (
      <ErrorAlert title='No recommendations are available for this search. Please update your Job Title and try again.' />
    )}

    {!isFetching &&
      results &&
      results.recommendedCandidates &&
      results.recommendedCandidates.map(result => {
        return <RecommendationListItem key={result.id} result={result} />;
      })}

    {!isFetching && results && results.notAFitCandidates && (
      <RecommendationListItemGroupHeader
        candidacyData={results.notAFitCandidates}
        isExpanded={false}
        itemCount={results.notAFitCandidates.length}
      />
    )}

    {isFetching && (
      <LoadingIndicator message='Loading Recommendations' size={64} />
    )}
  </div>
);

RecommendationsList.propTypes = {
  fetchRecommendationsList: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  hasRecommendations: PropTypes.bool,
  isFetching: PropTypes.bool,

  results: PropTypes.shape({
    notAFitCandidates: PropTypes.arrayOf(recommendedCandidatePropType),
    recommendedCandidates: PropTypes.arrayOf(recommendedCandidatePropType),
  }),
};

RecommendationsList.defaultProps = {
  hasError: false,
  hasRecommendations: true,
  isFetching: false,
  results: null,
};
function mapStateToProps(state, ownProps) {
  return {
    hasRecommendations: getSearchReadyForRecommendations(
      state,
      ownProps.searchId,
    ),
  };
}
export default compose(
  setDisplayName('RecommendationsList(enhanced)'),
  setPropTypes({
    searchId: PropTypes.number,
    sortBy: PropTypes.oneOf(SORT_BY_TYPES),
    onSortChange: PropTypes.func.isRequired,
    isSortAscending: PropTypes.bool,
    filterText: PropTypes.string,
  }),
  connect(mapStateToProps),
  withRecommendationsListFetched,
  withProps(({ recommendationsList }) => ({
    isFetching:
      recommendationsList && recommendationsList.getIn(['_meta', 'isFetching']),
    hasError:
      recommendationsList && recommendationsList.getIn(['_meta', 'error']),
    results: recommendationsList && recommendationsList.get('results'),
  })),
  withRecommendedFetched,
  connect((state, { results = [] }) => {
    if (!results.length) {
      return { results: {} };
    }

    const groupedResults = {
      notAFitCandidates: results.filter(
        candidate => candidate.stage === NOT_A_FIT_LABEL,
      ),
      recommendedCandidates: results.filter(
        candidate => candidate.stage === RECOMMENDED_LABEL,
      ),
    };

    return { results: groupedResults };
  }),
  filteredByText,
  withRecommendationsSorted,
)(RecommendationsList);

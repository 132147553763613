import { connect } from 'react-redux';
import getInterviewStartTime from '../selectors/getInterviewStartTime';

/**
 * A Higher order component that sorts a list of interviewIds in reverse chronological order
 * of the interview `start_time` (really a datetime).
 * Optionally empty dates can be sorted first or last, depending on the `sortEmptyDatesLast`
 * or `sortEmptyDatesFirst` values. Note that when this is used by the HOC `withInterviewIdsSorted`,
 * the order could potentially be reversed by the `sortDescending` prop value, which means
 * `sortEmptyDatesLast` could actually cause empty dates to be sorted FIRST, and
 * `sortEmptyDatesFirst` could actually cause empty dates to be sorted LAST, so that should be
 * taken in to account when providing these prop values.
 */
export default connect(
  (state, { interviewIds, sortEmptyDatesFirst, sortEmptyDatesLast }) => ({
    interviewIds:
      interviewIds &&
      interviewIds.sortBy(id => [
        sortEmptyDatesLast && !getInterviewStartTime(state, id) ? 1 : 0,
        sortEmptyDatesFirst && !getInterviewStartTime(state, id) ? 0 : 1,
        // Now sort by the date
        getInterviewStartTime(state, id),
      ]),
  }),
  {},
);

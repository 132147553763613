import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import ModalButton from 'modules/core/componentsLegacy/ModalButton';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import CompanyAddressModalForm from './CompanyAddressModalForm';

/**
 * Renders a button that, when clicked, shows a modal for creating/editing a company's addresses
 */
export default compose(
  setDisplayName('CompanyAddressModalButton'),
  setPropTypes({
    children: PropTypes.node.isRequired,

    /**
     * The ID of the company
     */
    companyId: PropTypes.number,

    /**
     * The primary address ID of the parent
     */
    primaryAddressId: PropTypes.number,
  }),

  defaultProps({
    children: (
      <>
        <i className='fa fa-plus-circle' />
        <span> Add Address</span>
      </>
    ),
  }),
  connect((state, { addressId }) => ({
    isEnriched: getEntityProperty(state, 'addresses', addressId, 'enriched'),
  })),
  withFeatureCheck('feature.skyminyr', 'hasSkyminyrFeature'),
  branch(
    ({ hasSkyminyrFeature, isEnriched }) => hasSkyminyrFeature && isEnriched,
    renderNothing,
  ),
  withProps(({ children, className, hasSkyminyrFeature, isEnriched }) => ({
    className: classnames('btn', 'btn-link', className),
    disabled: hasSkyminyrFeature && isEnriched,
    children: children,
    modal: CompanyAddressModalForm,
  })),
  withClassName('CompanyAddressModalButton'),
)(ModalButton);

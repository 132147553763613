import PropTypes from 'prop-types';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';

import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import mapPropsToParentIdAndParentType from './mapPropsToParentIdAndParentType';
import mapParentToInterviewList from './mapParentToInterviewList';

/**
 * Renders a loading indicator while fetching interview for a particular parentType, parentId,
 * and optional filter.
 */
export default compose(
  setDisplayName('InterviewsLoadingIndicator'),
  setPropTypes({
    searchId: PropTypes.number,
  }),
  mapPropsToParentIdAndParentType,
  mapParentToInterviewList,
  branch(
    ({ interviewList }) =>
      !interviewList || !interviewList.getIn(['_meta', 'isFetching']),
    renderNothing,
  ),
)(LoadingIndicator);

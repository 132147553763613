import PropTypes from 'prop-types';
import React from 'react';
import { compose, onlyUpdateForPropTypes } from 'recompose';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';

const PriorityOnlyToggleButton = ({
  componentId,
  isSelected,
  onToggle,
  title,
  tooltip,
}) => (
  <OverlayTrigger
    overlay={<Tooltip id={componentId}>{tooltip}</Tooltip>}
    placement='top'
  >
    <button
      className={classnames(
        'btn',
        'btn-default',
        'btn-toggle',
        'u-inlineFlex',
        'u-flexAlign-c',
        'u-flexJustify-c',
        {
          active: isSelected,
        },
      )}
      onClick={onToggle}
      title={title}
      type='button'
    >
      <i className='fa fa-thumbs-o-up' />
    </button>
  </OverlayTrigger>
);

PriorityOnlyToggleButton.propTypes = {
  /**
   * A unique component ID for this instance.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * True if the toggle button is currently selected; otherwise, false.
   */
  isSelected: PropTypes.bool,

  /**
   * Called when the `isSelected` value should be toggled.
   */
  onToggle: PropTypes.func.isRequired,

  /**
   * The title to assign to the button
   */
  title: PropTypes.string,

  /**
   * The contents of the tooltip that is displayed when hovering over the button.
   */
  tooltip: PropTypes.node,
};

PriorityOnlyToggleButton.defaultProps = {
  isSelected: false,
  title: 'Priority Companies Only',
  tooltip: 'Priority Companies Only',
};

export default compose(
  withComponentId(),
  onlyUpdateForPropTypes,
)(PriorityOnlyToggleButton);

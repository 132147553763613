import getCandidacyInviteToken from './getCandidacyInviteToken';

/**
 * Determines if a guest assessment invite token needs to be created/fetched from the server for
 * a particular candidacy
 * @param {*} state
 * @param {Number} candidacyId The ID of the candidacy to check
 * @returns {Boolean} true if an invite token needs to be requested from the server; Otherwise,
 *   false.
 */
export default (state, candidacyId) => {
  if (!candidacyId) {
    return false;
  }

  const inviteTokenState = getCandidacyInviteToken(state, candidacyId);

  if (!inviteTokenState) {
    return true;
  }

  if (inviteTokenState.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  return (
    !inviteTokenState.has('token') &&
    !inviteTokenState.hasIn(['_meta', 'error'])
  );
};

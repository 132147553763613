import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import TelephoneLink from 'modules/links/TelephoneLink';
import mapCompanyIdToProperty from './mapCompanyIdToProperty';

/**
 * Renders a link to a company's phone number, if available. If no number exists for the given
 * field, nothing is rendered (including children)
 *
 * @example
 * ```js
 * <CompanyTelephoneLink companyId={123} propertyName='main_phone' >
 *   <CompanyProperty companyId={123} propertyName='main_phone' />
 * </CompanyTelephoneLink>
 * ```
 */
export default compose(
  setDisplayName('CompanyTelephoneLink'),
  setPropTypes({
    /**
     * The ID of the company
     */
    companyId: PropTypes.number.isRequired,

    /**
     * Which phone number field on the company record to use
     */
    propertyName: PropTypes.oneOf(['main_phone', 'work_phone']).isRequired,
  }),
  defaultProps({
    propertyName: 'main_phone',
  }),
  mapCompanyIdToProperty('main_phone'),
  mapCompanyIdToProperty('work_phone'),
  mapProps(
    ({
      companyId: _companyId,
      main_phone: main,
      propertyName,
      work_phone: work,
      ...rest
    }) => ({
      ...rest,
      number: propertyName === 'work_phone' ? work : main,
    }),
  ),
)(TelephoneLink);

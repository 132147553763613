import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { CALENDAR_INTEGRATION_TYPES } from 'modules/user/constants';
import fetchUserOptionsIfNeeded from 'modules/user/components/fetchUserOptionsIfNeeded';
import getPermittedCalendarIntegration from 'modules/user/selectors/getPermittedCalendarIntegration';
import getCalendarIntegrationStatus from 'modules/user/selectors/getCalendarIntegrationStatus';
import isOptionLoaded from 'modules/user/selectors/isOptionLoaded';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  renderComponent,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';

const LABELS = {
  google: 'Google',
  outlook: 'Outlook',
};

const NotificationIntegrationStatusIcon = ({ calendarType, isIntegrated }) => (
  <div
    className={classnames('NotificationIntegrationStatusIcon', {
      'NotificationIntegrationStatusIcon--connected': isIntegrated,
    })}
  >
    <a href='/users/integrations/calendar'>
      <TooltipTrigger
        placement='right'
        tooltip={`${isIntegrated ? 'Synced to' : 'Unsynced with'} ${
          LABELS[calendarType]
        } Calendar`}
      >
        <i className='fa fa-2x fa-exchange' />
      </TooltipTrigger>
    </a>
  </div>
);

/**
 * Function to decide if we should render the NotificationIntegrationStatusIcon
 * @param  {Immutable.Map} notification   The notification object
 * @param  {Number} integrationEventId    The id of a remote calendar event, indicating
 *  that the notification's resource has been synced.
 * @return {Boolean}                      True if we should prevent rendering the component,
 *  false if we should continue
 */
const shouldRenderNothing = ({
  createdById,
  currentUserId,
  integrationEventId,
  notification,
}) => {
  const isEvent =
    notification.get('type') === 'interview' ||
    notification.get('type') === 'meeting';

  const isCreatedByCurrentUser = currentUserId === createdById;

  if (!integrationEventId || !isEvent || !isCreatedByCurrentUser) {
    return true;
  }
  return false;
};

NotificationIntegrationStatusIcon.propTypes = {
  // A string (either 'google' or 'outlook') denoting the type of calendar integration
  calendarType: PropTypes.oneOf(CALENDAR_INTEGRATION_TYPES),

  // Whether the user's calendar integration is active
  isIntegrated: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('NotificationIntegrationStatusIcon'),
  setPropTypes({
    notification: ImmutablePropTypes.shape({
      owner_id: PropTypes.number,
      resource: ImmutablePropTypes.shape({
        data: ImmutablePropTypes.shape({
          integration_event_id: PropTypes.string,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }),
  withProps(({ notification }) => ({
    integrationEventId: notification.getIn([
      'resource',
      'data',
      'integration_event_id',
    ]),
    createdById: notification.getIn(['owner_id']),
  })),
  connect(
    state => ({
      calendar: getPermittedCalendarIntegration(state),
      isIntegrated: getCalendarIntegrationStatus(state),
      isLoaded: isOptionLoaded(state, 'integrations'),
      currentUserId: getCurrentUserId(state),
    }),
    {},
  ),
  withProps(({ calendar }) => ({
    calendarType: calendar && calendar.get('type'),
  })),
  branch(shouldRenderNothing, renderNothing),
  fetchUserOptionsIfNeeded,
  branch(({ isLoaded }) => !isLoaded, renderComponent(LoadingIndicator)),
)(NotificationIntegrationStatusIcon);

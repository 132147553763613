import getPathName from 'modules/routing/selectors/getPathName';

/**
 * Gest a link to a search page's content view -- usually "candidates", "applicants", or
 * "target_companies",
 * This is a hacky-kind of way to replace the current page in the URL with the desired page,
 * but until we fix the routing situation it's all we got.
 * NOTE: This can only be used when the _current_ path is already a "Search Page"! This should
 * really get fixed when we get the routes working better.
 * @param {Object} state root state
 * @param {String} page The page to create a URL for.
 * @param {Boolean} pageOnly True to remove the path parts not related to the page itself.
 *   This will remove any path parts related to the sidebar view.
 *   This is necessary in certain cases when redirecting to an entirely different page, or a page
 *   with a has, i.e. page='candidates/edit#search-team', because just replacing the page portion
 *   would screw up the hash value.
 * @return {String} The URL to the search page.
 */
const getSearchPageViewLink = (state, page, pageOnly = false) => {
  const path = getPathName(state);
  const pathSegments = path.split('/');

  // path is usually: "/:search_type/:search_id/:page/otherstuff...",
  // and we just want to replace the ":page" part (and possinly remove the otherstuff, if pageOnly
  // is true).
  pathSegments.splice(3, 1, page);

  if (pageOnly) {
    return pathSegments.slice(0, 4).join('/');
  }

  return pathSegments.join('/');
};

export default getSearchPageViewLink;

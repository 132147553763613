import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

import YearSelectField from 'modules/datetime/components/YearSelectField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';

const convertInputToNumber = value => {
  if (typeof value === 'string') {
    return value.trim().length ? parseFloat(value.replace(/,/g, '')) : null;
  }

  return value;
};

/**
 * A field for editing the company employee
 */
class CompanyEmployeeCountField extends PureComponent {
  static createFieldState(name = 'employee_count', employee, ...rest) {
    const values = fromJS({
      year: null,
      amount: '',
      _destroy: false,
    }).merge(employee);

    return FieldState.createNested(
      name,
      [
        FieldState.create('id', values.get('id')),
        FieldState.create('_destroy', values.get('_destroy')),
        YearSelectField.createFieldState(
          'year',
          values.get('year'),
          validators.requiredField('Year'),
        ),
        InputField.createFieldState(
          'amount',
          values.get('amount'),
          validators.combineValidators(
            validators.requiredField('Employee count'),
            validators.isNumber,
          ),
          convertInputToNumber,
        ),
      ],
      ...rest,
    );
  }

  handleFieldChange = value => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(value));
  };

  render() {
    const {
      fieldState,

      // prevent onChange from being passed through to the input.
      onChange: _onChange,
      title,

      ...otherProps
    } = this.props;

    return (
      <div className='CompanyEmployeeField'>
        {title && <h3>{title}</h3>}
        <div className='row'>
          <div className='col-sm-6'>
            <YearSelectField
              {...otherProps}
              fieldState={fieldState.getNestedField('year')}
              label='Year'
              name='year'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className='col-sm-6'>
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('amount')}
              isRequired={true}
              key='amount'
              label='Employee Count'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

CompanyEmployeeCountField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  /**
   * An optional title to display at the top of the field.
   */
  title: PropTypes.node,
};

export default CompanyEmployeeCountField;

const BulkActionTypes = {
  ADD_TO_NETWORK: 'addToNetwork',
  ADD_TO_PIPELINE: 'addToPipeline',
  ADD_TO_SEARCH: 'addToSearch',
  BULK_EDIT_TAGS: 'bulkEditTags',
  DELETE: 'delete',
};

const BulkTagCategories = {
  INDUSTRIES: {
    apiAssociationKey: 'industry_category_ids',
    label: 'Industries',
    tenantOptionsKey: 'industries',
  },
  LANGUAGES: {
    apiAssociationKey: 'language_ids',
    label: 'Languages',
    tenantOptionsKey: 'languages',
  },
  SECTORS: {
    apiAssociationKey: 'sector_ids',
    label: 'Sectors',
    tenantOptionsKey: 'sectors',
  },
  SKILLS: {
    apiAssociationKey: 'skill_category_ids',
    label: 'Skills',
    tenantOptionsKey: 'skills',
  },
  TARGET_FUNCTIONS: {
    apiAssociationKey: 'target_function_ids',
    label: 'Target Functions',
    tenantOptionsKey: 'target_functions',
  },
  TARGET_MARKETS: {
    apiAssociationKey: 'target_market_ids',
    label: 'Target Markets',
    tenantOptionsKey: 'target_markets',
  },
};

const BulkTagCategoryTypes = Object.keys(BulkTagCategories)
  // Converts category keys into an "enum" where key and value is the category key
  // This makes it easier for a consumer to destructure select keys (not so with array)
  // and allows adding additional categories in one place.
  .reduce((typeEnum, currentType) => {
    typeEnum[currentType] = currentType;
    return typeEnum;
  }, {});

export { BulkActionTypes, BulkTagCategories, BulkTagCategoryTypes };

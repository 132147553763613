import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Menu from '@thrivetrm/ui/components/Menu';
import { RecordTypesWithConnections } from 'services/apiV1/connections';
import Tab from 'modules/core/components/Tab';
import getContact from 'modules/contacts/selectors/contacts/getContact';
import { useSelector } from 'react-redux';
import { useGetConnectionOptionQuery } from 'services/apiV1/connectionOption';
import { useGetContactQuery } from 'services/apiV1/contact';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import useQuickView from '../useQuickView';
import ContactQvpHeader from '../ContactQvpHeader';
import ConnectionsList from './ConnectionsList';
import ContactReportingRelationships from '../contact-reporting-relationships/ContactReportingRelationships';
import ConnectionFilterDropDown from './ConnectionFilterDropDown';
import ContactSuggestedConnections from './ContactSuggestedConnections';

const ContactConnections = () => {
  const [connectionFilterOptions, setConnectionFilterOptions] = useState({});
  const { navigateTo, pathParams } = useQuickView();
  const { contactId } = pathParams;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultSelectedTab = queryParams.get('selectedTab');
  const createdSuggestedConnection = queryParams.get(
    'createdSuggestedConnection',
  );
  const suggestedConnectionsFeature = useFeatureCheck(
    'feature.suggested_connections',
  );

  const [selectedTab, setSelectedTab] = useState('CONNECTIONS');

  const {
    data: connectionOptions,
    isFetching: isLoadingConnectionOptions,
  } = useGetConnectionOptionQuery();

  const { data: contact } = useGetContactQuery(contactId);

  useEffect(() => {
    setSelectedTab(defaultSelectedTab || 'CONNECTIONS');
  }, [defaultSelectedTab]);

  useEffect(() => {
    if (connectionOptions) {
      setConnectionFilterOptions(connectionOptions);
    }
  }, [connectionOptions]);

  const contactData = useSelector(state => getContact(state, contactId));

  const quickViewPanelHeaderButton = (
    <div>
      <Menu
        button={
          <ButtonSecondary
            className='ContactConnections__addConnectionButton'
            icon='add'
            label='Add Connection'
            size='small'
          />
        }
        isPinnedRight={true}
      >
        <Menu.Item
          onClick={() =>
            navigateTo(
              `/contact/${contactId}/connections/add-${RecordTypesWithConnections.USER}/?selectedTab=${selectedTab}`,
            )
          }
        >
          To User
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            navigateTo(
              `/contact/${contactId}/connections/add-${RecordTypesWithConnections.CONTACT}/?selectedTab=${selectedTab}`,
            )
          }
        >
          To Contact
        </Menu.Item>
      </Menu>
    </div>
  );

  const renderSuggestedOrRelationshipsConnections = () => {
    if (suggestedConnectionsFeature) {
      return (
        <ContactSuggestedConnections
          aria-labelledby='tab-SUGGESTED CONNECTIONS'
          createdSuggestedConnection={createdSuggestedConnection}
          id='tabpanel-SUGGESTED CONNECTIONS'
          role='tabpanel'
        />
      );
    }

    return (
      <ContactReportingRelationships
        aria-labelledby='tab-REPORTING RELATIONSHIPS'
        id='tabpanel-REPORTING RELATIONSHIPS'
        role='tabpanel'
      />
    );
  };

  return (
    <>
      <ContactQvpHeader
        contactId={contactId}
        onBack={() => navigateTo(`/contacts/${contactId}`)}
      />
      <Tab
        className='u-noWrap'
        onTabChange={setSelectedTab}
        selectedTabLabel={selectedTab}
        tabs={[
          {
            label: 'CONNECTIONS',
            count:
              contactData?.get('connections_count') ||
              contact?.connectionsCount,
          },
          {
            label: suggestedConnectionsFeature
              ? 'SUGGESTED CONNECTIONS'
              : 'REPORTING RELATIONSHIPS',
            count: suggestedConnectionsFeature
              ? null
              : contactData?.get('reporting_relationships_count') ||
                contact?.reportingRelationshipsCount,
          },
        ]}
        tabsListClass='u-marginHorizontal-32'
      />
      <div className='u-flex u-marginTop-16 u-flexJustify-spaceBetween ContactConnections__menuButton'>
        {quickViewPanelHeaderButton}
        {selectedTab === 'CONNECTIONS' ? (
          <ConnectionFilterDropDown
            connectionFilterOptions={connectionFilterOptions}
            isLoadingConnectionOptions={isLoadingConnectionOptions}
            setConnectionFilterOptions={setConnectionFilterOptions}
          />
        ) : null}
      </div>
      {selectedTab === 'CONNECTIONS' ? (
        // using aria-labelledby, id and role for user accessibility
        <ConnectionsList
          aria-labelledby='tab-CONNECTIONS'
          connectionFilterOptions={connectionFilterOptions}
          id='tabpanel-CONNECTIONS'
          role='tabpanel'
        />
      ) : (
        renderSuggestedOrRelationshipsConnections()
      )}
    </>
  );
};

export default ContactConnections;

import { connect } from 'react-redux';
import { withProps, compose } from 'recompose';
import fetchUserOptionsIfNeeded from 'modules/user/components/fetchUserOptionsIfNeeded';
import getCalendarIntegrations from 'modules/user/selectors/getCalendarIntegrations';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';

const connectCalendarPermissions = compose(
  fetchUserOptionsIfNeeded,
  connect(state => {
    const calendarIntegrations = getCalendarIntegrations(state);
    const permittedCalendar =
      calendarIntegrations &&
      calendarIntegrations.find(item => item.get('permitted'));
    return {
      currentUserId: getCurrentUserId(state),
      calendar: permittedCalendar,
    };
  }, {}),
  withProps(({ calendar, currentUserId, interview, meeting }) => {
    const appointment = meeting || interview;
    const creatorIsViewing = appointment
      ? appointment.get('created_by') === currentUserId
      : true;

    // Does the customer have a permitted integration? If yes,
    // display the calendar component. If no, hide it.
    return {
      showCalendarIntegration: Boolean(calendar) && creatorIsViewing,
    };
  }),
);

export default connectCalendarPermissions;

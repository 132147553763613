import PropTypes from 'prop-types';
import React from 'react';
import { compose, onlyUpdateForPropTypes, setDisplayName } from 'recompose';
import mapTalentPoolIdToCandidacyCount from './mapTalentPoolIdToCandidacyCount';
import mapContactIdToTalentPoolId from './mapContactIdToTalentPoolId';

/**
 * Renders the number of candidacies that a talent pool contains.
 */
export const CandidacyCount = ({ candidacyCount }) => (
  <span className='talent-pools--candidacy-count'>{candidacyCount || 0}</span>
);

CandidacyCount.propTypes = {
  candidacyCount: PropTypes.number,
};

export default compose(
  setDisplayName('connect(CandidacyCount)'),
  mapContactIdToTalentPoolId,
  mapTalentPoolIdToCandidacyCount,
  onlyUpdateForPropTypes,
)(CandidacyCount);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Date from '@thrivetrm/ui/components/Date';
import Icon from '@thrivetrm/ui/components/Icon';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import DocumentEditor from 'modules/document-editor/DocumentEditor';
import getSearchTitle from 'modules/searches/selectors/getSearchTitle';
import { useGetDocumentTypesQuery } from 'services/apiV1/documents';
import getSearchProperty from 'modules/searches/selectors/getSearchProperty';
import offerShape from './offerShape';

const OfferList = ({
  companyLogoUrl,
  companyName,
  offers,
  onEditOffer,
  searchId,
}) => {
  const [editDocumentsForOfferId, setEditDocumentsForOfferId] = useState(null);

  const companySearchTitle = useSelector(state =>
    getSearchTitle(state, searchId),
  );

  const getStatus = status => {
    if (status === 'accepted') {
      return 'Accepted';
    } else if (status === 'declined') {
      return 'Declined';
    }
    return 'Pending';
  };

  const isInterimPlacementSearch = useSelector(state =>
    getSearchProperty(state, searchId, 'placement_type')?.get('interim'),
  );

  const { data } = useGetDocumentTypesQuery('offer');
  const documentTypes = data?.length ? data : [];

  const documentTypesForSearch =
    isInterimPlacementSearch && documentTypes?.length
      ? documentTypes
      : documentTypes?.filter(type => type.name !== 'Insurance');

  return offers.length ? (
    <table>
      <thead>
        <tr>
          <th>Candidate</th>
          <th>Offer Status</th>
          <th>Offer Date</th>
          <th style={{ width: '100px' }}>
            <span className='u-visually-hidden'>Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {offers.map(offer => (
          <tr key={offer.id}>
            <td>{offer.candidacy.fullName}</td>
            <td>{getStatus(offer.status)}</td>
            <td>
              <Date date={offer.extendedDate} />
            </td>
            <td>
              <Tooltip content='Edit Offers' position='topLeft' size='medium'>
                <ButtonLink
                  className='u-paddingHorizontal-8'
                  label={<Icon type='edit' />}
                  onClick={() => onEditOffer(offer.id)}
                />
              </Tooltip>
              <Tooltip
                content='Offer Documents'
                position='topLeft'
                size='medium'
              >
                <ButtonLink
                  className='u-paddingHorizontal-8'
                  label={<Icon type='itemNote' />}
                  onClick={() => setEditDocumentsForOfferId(offer.id)}
                />
              </Tooltip>
            </td>
            {editDocumentsForOfferId === offer.id ? (
              <DocumentEditor
                company={{
                  avatarUrl: companyLogoUrl,
                  primaryCompanyName: companyName,
                  searchTitle: companySearchTitle,
                }}
                contact={{
                  avatarUrl: offer.candidacy.avatarUrl,
                  fullName: offer.candidacy.fullName,
                  primaryPositionName: offer.candidacy.positionName,
                }}
                documentTypes={documentTypesForSearch}
                isOpen={true}
                location={offer.location}
                onClose={() => setEditDocumentsForOfferId(null)}
                ownerId={editDocumentsForOfferId}
                ownerType='offer'
              />
            ) : null}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>There are no candidate offers for this search</p>
  );
};

OfferList.defaultProps = {
  offers: [],
  onEditOffer: null,
};

OfferList.propTypes = {
  companyLogoUrl: PropTypes.string,
  companyName: PropTypes.string,
  offers: PropTypes.arrayOf(PropTypes.shape(offerShape)),
  onEditOffer: PropTypes.func,
  searchId: PropTypes.number,
};

export default OfferList;

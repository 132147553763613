import { LABEL_LINKEDIN_PDF, LABEL_RESUME } from 'modules/documents/constants';
import routes from 'modules/routing/routes';
import createAjaxAction from '../createAjaxAction';
import { ajax } from '../ajax';
import {
  CONTACT_REVIEW_CREATE_START,
  CONTACT_REVIEW_CREATE_SUCCESS,
  CONTACT_REVIEW_CREATE_FAILURE,
} from './constants';

export const getRequestOptions = ({
  contactId,
  file,
  jobApplicationId,
  network,
  parser,
  search,
}) => {
  const data = new FormData();
  data.append('contact_review[strategy]', 'Resume');
  data.append('contact_review[document][file]', file);
  data.append(
    'contact_review[document][label]',
    parser === 'sovren' ? LABEL_RESUME : LABEL_LINKEDIN_PDF,
  );
  data.append('contact_review[document][parse_with]', parser);

  if (contactId) {
    data.append('contact_review[contact_id]', contactId);
  }

  if (network && network.id) {
    data.append('contact_review[network_id]', network.id);
  }

  if (search) {
    data.append('contact_review[search_id]', search.id);
  }

  if (jobApplicationId) {
    data.append('contact_review[job_application_id]', jobApplicationId);
  }

  return {
    method: 'POST',
    body: data,
  };
};

const createContactReview = createAjaxAction({
  start: CONTACT_REVIEW_CREATE_START,
  success: CONTACT_REVIEW_CREATE_SUCCESS,
  failure: CONTACT_REVIEW_CREATE_FAILURE,
  ajax: ajax,
  getUrl: routes.api_v1_contact_reviews,
  getRequestOptions: getRequestOptions,
});

export default createContactReview;

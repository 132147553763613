import { connect } from 'react-redux';
import getCandidacySearchId from '../selectors/getCandidacySearchId';

/**
 * A higher order component which maps a `candidacyId` prop value to it's search ID.
 */
export default connect(
  (state, { candidacyId }) => ({
    searchId: getCandidacySearchId(state, candidacyId),
  }),
  {},
);

/* eslint-disable func-names */

module.exports = function (el) {
  el.select2({
    tags: true,
    selectOnClose: true,
    matcher: () => false,
    theme: 'bootstrap',
    tokenSeparators: [',', ' '],
    width: '100%',
    placeholder: 'Enter emails...',
    language: {
      noResults: () => 'Enter a recipient email...',
    },
  });
  el.val(el.children().first().val());
  el.trigger('change.select2');

  // HACK: This fixes a select2 bug until v4.0.3
  // See bug: https://github.com/select2/select2/issues/4012
  // Fixes: https://github.com/select2/select2/issues/3320
  // Implemented fix below helps, but doesn't always solve the bug.
  el.on('select2:unselect', e => {
    if (e.params.originalEvent) {
      e.params.originalEvent.stopPropagation();
    }
  });

  // Hack for known Select2 issue (https://github.com/select2/select2/issues/4384)
  // to re-allow tab-key navigation between the fields.
  el.on('select2:close', function () {
    return $(this).focus();
  });
};

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import NotificationItem from './items';

export default function NotificationsList({
  notifications,
  shouldManageReadStatus,
  ...props
}) {
  const latestNotificationIds = notifications.reduce((acc, notification) => {
    const resourceId = notification.getIn(['data', 'resource', 'data', 'id']);
    if (
      !acc[resourceId] ||
      notification.getIn(['data', 'id']) > acc[resourceId]
    ) {
      acc[resourceId] = notification.getIn(['data', 'id']);
    }
    return acc;
  }, {});

  const checkIsLatestId = notification => {
    const notificationId = notification.getIn(['data', 'id']);
    const resourceId = notification.getIn(['data', 'resource', 'data', 'id']);
    return notificationId === latestNotificationIds[resourceId];
  };

  return (
    <ul className='list-group' key='notifications'>
      {notifications
        .map(notification => (
          <li
            className={classnames(
              'list-group-item',
              'clearfix',
              'notification-list-item',
              {
                'notification-list-item--unread':
                  shouldManageReadStatus &&
                  notification.getIn(['data', 'unread']),
                'notification-list-item--read':
                  shouldManageReadStatus &&
                  !notification.getIn(['data', 'unread']),
              },
            )}
            key={notification.getIn(['data', 'id'])}
          >
            <NotificationItem
              {...props}
              isLatestId={checkIsLatestId(notification)}
              notification={notification}
              shouldManageReadStatus={shouldManageReadStatus}
            />
          </li>
        ))
        .toArray()}
    </ul>
  );
}

NotificationsList.propTypes = {
  /**
   * The notifications that should be displayed by this list
   */
  notifications: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.mapContains({
      data: ImmutablePropTypes.mapContains({
        id: PropTypes.number,
        unread: PropTypes.bool,
      }),
    }),
  ),

  shouldManageReadStatus: PropTypes.bool,

  /**
   * All other props are passed down to the NotificationItem
   */
};

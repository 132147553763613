import { connect } from 'react-redux';
import getSearch from '../selectors/getSearch';

/**
 * A higher order component which maps a list of `searchIds` to
 * their search records.
 */
export default connect(
  (state, { searchIds }) => ({
    searches: searchIds && searchIds.map(id => getSearch(state, id)),
  }),
  {},
);

import PropTypes from 'prop-types';
import React from 'react';
import Card from '@thrivetrm/ui/components/Card';

const ReportCard = ({ format, icon, isFontAwesome, onClick, title }) => (
  <Card className='report-card u-textAlign-c u-padding-n'>
    <div className='u-flexItem-grow'>
      <div className='report-card-logo u-textColor-blue'>
        {isFontAwesome ? (
          <i className={`fa ${icon} fa-3x`} />
        ) : (
          <i data-icon={icon} />
        )}
      </div>
      <h4 className='u-marginBottom-4'>{title}</h4>
      <div className='u-fontSize-small u-marginBottom-8 u-textColor-gray50'>
        Format: {format}
      </div>
      <div className='u-padding-8 u-backgroundColor-gray20'>
        <button
          className='btn btn-default btn-small'
          onClick={onClick}
          type='button'
        >
          Generate
        </button>
      </div>
    </div>
  </Card>
);

ReportCard.propTypes = {
  /**
   * A string of comma and space separated formats
   */
  format: PropTypes.string,

  /**
   * Font Awesome icon
   */
  icon: PropTypes.string,

  /*
   * Boolean: whether to use Font Awesome icon
   */
  isFontAwesome: PropTypes.bool,

  onClick: PropTypes.func,

  /**
   * The title of the report being generated
   */
  title: PropTypes.string,
};

ReportCard.defaultProps = {
  format: 'CSV',
  icon: '',
  isFontAwesome: true,
  title: 'Default Report',
};

export default ReportCard;

import PropTypes from 'prop-types';
import React from 'react';
import {
  branch,
  compose,
  defaultProps,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import mapSearchIdToSearch from './mapSearchIdToSearch';

/* eslint-disable react/no-danger */
const SearchQualifications = ({ qualifications }) => (
  <div className='SearchQualifications'>
    <h2>Top Qualifications</h2>
    <HtmlText htmlString={qualifications} />
  </div>
);

SearchQualifications.propTypes = {
  qualifications: PropTypes.string,
};

export default compose(
  setDisplayName('SearchQualifications(enhanced)'),
  setPropTypes({
    hideIfEmpty: PropTypes.bool.isRequired,
    /**
     * The search ID to show the qualifications text for.
     */
    searchId: PropTypes.number.isRequired,
  }),
  defaultProps({
    hideIfEmpty: false,
  }),
  mapSearchIdToSearch,
  mapProps(({ hideIfEmpty, search }) => ({
    hideIfEmpty: hideIfEmpty,
    qualifications: search && search.get('qualifications'),
  })),
  branch(
    ({ hideIfEmpty, qualifications }) => hideIfEmpty && !qualifications,
    renderNothing,
  ),
)(SearchQualifications);

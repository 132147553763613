import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import Modal from 'modules/core/componentsLegacy/Modal';
import FormState from 'modules/forms/FormState';
import CancelButton from 'modules/forms/components/CancelButton';

/**
 * Renders a modal form that allows the user to request a search summary report via email.
 */
const GenerateReportModal = ({
  children,
  className,
  error,
  formState,
  handleSubmit,
  isComplete,
  isOpen,
  isSubmitting,
  onHide,
  sentTo,
  submitButtonIcon,
  submitButtonTitle,
  title,
}) => (
  <Modal
    // Prevent clicking on backdrop from closing modal.
    backdrop='static'
    className={classnames(className, 'GenerateReportModal')}
    onHide={onHide}
    show={isOpen}
  >
    <Modal.Header closeButton={true}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {isComplete ? (
        <p
          className='alert alert-success'
          data-testid='generate-report-success-message'
        >
          <span>A report will be emailed to </span>
          <strong>
            {(sentTo && sentTo.length
              ? sentTo
              : ['the email address provided']
            ).join(', ')}
          </strong>
          .
        </p>
      ) : (
        <div>
          {children}
          <div>{!isSubmitting && error && <ErrorAlert error={error} />}</div>
        </div>
      )}
    </Modal.Body>
    <Modal.Footer>
      <CancelButton bsSize='lg' onClick={onHide}>
        {isComplete ? 'Close' : 'Cancel'}
      </CancelButton>
      {!isComplete && (
        <button
          className='btn btn-lg btn-primary'
          disabled={!formState.canSubmit()}
          onClick={handleSubmit}
          type='button'
        >
          <i
            className={classnames('fa', {
              'fa-spin': isSubmitting,
              'fa-spinner': isSubmitting,
              [submitButtonIcon]: !isSubmitting,
            })}
          />
          &nbsp;
          {submitButtonTitle}
        </button>
      )}
    </Modal.Footer>
  </Modal>
);

GenerateReportModal.propTypes = {
  /**
   * The field to render.
   */
  children: PropTypes.node.isRequired,

  /**
   * The field to render.
   */
  className: PropTypes.string,

  error: PropTypes.shape({
    message: PropTypes.string,
  }),

  formState: PropTypes.instanceOf(FormState),

  /**
   * Called to submit the form.
   */
  handleSubmit: PropTypes.func.isRequired,

  /**
   * True if the report request has been successfully completed.
   */
  isComplete: PropTypes.bool.isRequired,

  /**
   * True to render the modal; otherwise, false.
   */
  isOpen: PropTypes.bool,

  /**
   * True if the report request is currently being submitted.
   */
  isSubmitting: PropTypes.bool.isRequired,

  /**
   * Called when the modal should be hidden (when the close button is clicked)
   */
  onHide: PropTypes.func.isRequired,

  /**
   * An array of email addresses the report will be sent to.
   */
  sentTo: PropTypes.arrayOf(PropTypes.string),

  /**
   * The icon to show on the submit button.
   */
  submitButtonIcon: PropTypes.string,

  /**
   * The title to show on the submit button.
   */
  submitButtonTitle: PropTypes.node,

  /**
   * A title for the modal window
   */
  title: PropTypes.node,
};

GenerateReportModal.defaultProps = {
  isOpen: true,
  submitButtonIcon: 'fa-email',
  submitButtonTitle: 'Create Report',
};

export default GenerateReportModal;

import getJobListingBySearchId from './getJobListingBySearchId';

/**
 * Determines if a job listing should be fetched.
 * @param {Object} state
 * @param {Number} searchId The underlying search ID
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default (state, searchId) => {
  const jobListing = getJobListingBySearchId(state, searchId);
  if (!jobListing) {
    return true;
  }

  if (jobListing.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (jobListing.hasIn(['_meta', 'error'])) {
    return false;
  }

  return Boolean(jobListing.getIn(['_meta', 'isInvalidated']));
};

import { connect } from 'react-redux';
import getInvestmentProperty from '../selectors/getInvestmentProperty';

/**
 * A Higher order component that sorts a list of investmentIds in order of the investment amount
 */
export default connect(
  (state, { investmentIds }) => ({
    investmentIds:
      investmentIds &&
      investmentIds.sortBy(id => getInvestmentProperty(state, id, 'amount')),
  }),
  {},
);

import routes from 'modules/routing/routes';
import url from 'modules/routing/url';
import createAjaxAction from '../../../../actions/createAjaxAction';
import {
  EXISTING_CANDIDACIES_COUNT_FETCH_START,
  EXISTING_CANDIDACIES_COUNT_FETCH_SUCCESS,
  EXISTING_CANDIDACIES_COUNT_FETCH_FAILURE,
} from './actionTypes';

export default createAjaxAction({
  getUrl: ({ contactIds, searchId }) =>
    url(
      routes.api_v1_search_existing_candidacies_count({ searchId: searchId }),
      {
        contactIds: contactIds,
      },
    ),
  start: EXISTING_CANDIDACIES_COUNT_FETCH_START,
  success: EXISTING_CANDIDACIES_COUNT_FETCH_SUCCESS,
  failure: EXISTING_CANDIDACIES_COUNT_FETCH_FAILURE,
});

import createEntity from 'modules/entities/actions/createEntity';
import routes from 'modules/routing/routes';
import tagSchema from '../schemas/tag';

/**
 * An action for creating a tag.
 * @param {Object} options
 * @param {Object} options.tag The tag to create
 * @param {String} options.tag.name The name of the tag
 * @param {String} options.tag.color The color of the tag
 * @param {String} [options.transactionId] A unique ID used to track the request.
 */
export default ({ tag, transactionId }) =>
  createEntity({
    body: { tag: tag },
    transactionId: transactionId,
    entityType: tagSchema.key,
    responseSchema: { tag: tagSchema },
    url: routes.api_v1_tags(),
  });

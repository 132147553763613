import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * An icon for editing something. By default displays a pencil
 * icon as a link.
 */
const EditIconButton = ({ className, iconClassName, ...props }) => (
  <button
    className={classnames('EditIconButton', 'btn', className)}
    type='button'
    {...props}
  >
    <i className={classnames('fa', iconClassName)} />
  </button>
);

EditIconButton.defaultProps = {
  className: 'btn-link',
  iconClassName: 'fa-pencil',
};

EditIconButton.propTypes = {
  /**
   * The class name(s) to apply to the button. ("btn-link" by default)
   */
  className: PropTypes.string,

  /**
   * The class name of the font-awesome icon to render ("fa-pencil" by default)
   */
  iconClassName: PropTypes.string,
};

export default EditIconButton;

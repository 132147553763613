import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setStatic, setDisplayName } from 'recompose';
import moment from 'moment';
import FieldState from 'modules/forms/FieldState';
import * as validators from 'modules/forms/validators';
import withFormGroup from 'modules/forms/components/withFormGroup';

import TimeInputField from './TimeInputField';
import DateInputField from './DateInputField';
import timeRange from '../validators/timeRange';

class DateTimeRangeField extends Component {
  static createFieldState(name = 'date_range', initialValues = new Map()) {
    const now = moment();
    // Round up to the next 5 minute interval
    now.minute(Math.ceil(now.minute() / 5) * 5);

    /* If no start time, use the rounded current time */
    const defaultTime = initialValues.get('start_time')
      ? moment(initialValues.get('start_time'), moment.ISO_8601)
      : now;

    /* If no end time, add an hour to start time */
    const endTime = initialValues.get('end_time')
      ? moment(initialValues.get('end_time'), moment.ISO_8601)
      : defaultTime.clone().add(1, 'hours');

    return FieldState.createNested(
      name,
      [
        DateInputField.createFieldState(
          'date',
          initialValues.get('date') || defaultTime,
          validators.requiredField('date'),
        ),
        TimeInputField.createFieldState(
          'start_time',
          defaultTime.format('LT'),
          validators.requiredField('Start time'),
        ),
        TimeInputField.createFieldState(
          'end_time',
          endTime.format('LT'),
          validators.requiredField('End time'),
        ),
      ],
      timeRange('start_time', 'end_time'),
    );
  }

  handleFieldChange = nestedFieldState => {
    const { fieldState, onChange } = this.props;

    onChange(fieldState.setNestedField(nestedFieldState));
  };

  render() {
    const {
      dateClass,
      endTimeClass,
      fieldState,
      startTimeClass,
      wrapperClass,
      ...otherProps
    } = this.props;

    return (
      <div className={wrapperClass}>
        <div className={dateClass}>
          <DateInputField
            {...otherProps}
            fieldState={fieldState.getNestedField('date')}
            label='*Date'
            onChange={this.handleFieldChange}
          />
        </div>
        <div className={startTimeClass}>
          <TimeInputField
            {...otherProps}
            fieldState={fieldState.getNestedField('start_time')}
            label='*Start time'
            onChange={this.handleFieldChange}
          />
        </div>
        <div className={endTimeClass}>
          <TimeInputField
            {...otherProps}
            fieldState={fieldState.getNestedField('end_time')}
            label='*End time'
            onChange={this.handleFieldChange}
          />
        </div>
      </div>
    );
  }
}

DateTimeRangeField.propTypes = {
  className: PropTypes.string,
  dateClass: PropTypes.string,
  endTimeClass: PropTypes.string,
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  onChange: PropTypes.func.isRequired,
  startTimeClass: PropTypes.string,
  wrapperClass: PropTypes.string,
};

DateTimeRangeField.defaultProps = {
  dateClass: 'col-md-4 col-4',
  endTimeClass: 'col-md-4 col-4',
  startTimeClass: 'col-md-4 col-4',
  wrapperClass: 'row',
};

export default compose(
  setDisplayName('DateTimeRangeField'),
  setStatic('createFieldState', DateTimeRangeField.createFieldState),
  withFormGroup,
)(DateTimeRangeField);

import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import routes from 'modules/routing/routes';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import withTransactionTrackedForm from '../forms/withTransactionTrackedForm';
import ContactReviewField from './ContactReviewField';
import withConnection from '../../containers/app/pages/contact/withConnection';
import withContactLoaded from '../../containers/app/pages/contact/withContactLoaded';
import { withReviewRedirection } from '../../containers/app/pages/contact/withFormRedirection';

const createRootFieldState = ({
  connectionList,
  contact,
  contactEducations,
  contactPositions,
  contactReviews,
  existingContactLinkedInSkills,
  hasSkyminyrFeature,
  match,
}) =>
  ContactReviewField.createFieldState('contact', {
    contact: contact,
    connectionList: connectionList,
    positions: contactPositions,
    educations: contactEducations,
    contactReviews: contactReviews,
    existingContactLinkedInSkills: existingContactLinkedInSkills,
    match: match,
    hasSkyminyrFeature: hasSkyminyrFeature,
  });

const handleSubmit = (formState, { contact, contactActions, match }) => {
  const transactionId = uniqueId();

  contactActions.saveContact({
    contactReviewId: match.params.contactReviewId,
    contactId: contact.getIn(['data', 'id']),
    contact: formState.getFieldValue(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

const TrackedFormContactReviewField = compose(
  withFeatureCheck('feature.skyminyr', 'hasSkyminyrFeature'),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    showErrors: true,
    includeTransactionProps: true,
    fieldComponentTop: false,
    formActionClass: 'review-buttons',
    buttonsClass: 'container',
    formClassName: 'review-fixed-buttons',
    formActionProps: {
      submitLabel: 'Create Contact',
    },
  }),
)(ContactReviewField);

const ContactForm = ({ contactPositions, ...props }) => {
  const initialPositionIdsRenderStructure = [];
  contactPositions.forEach(positionImmutableMap => {
    const position = positionImmutableMap.toJS();
    // This transforms the hierarchical duplicate positions into an array of duplicate IDs
    if (position.duplicate_positions?.length) {
      const duplicatePositionIds = [position.id];
      position.duplicate_positions.forEach(duplicatePosition => {
        const dupePositionId = duplicatePosition.id;
        // When displaying duplicate positions, we want the incoming positions to be listed before
        // the existing positions. So we insert new positions into the front of the array
        // Unsaved imported position IDs are a string ID in the form `NEW-{hash}`
        // Saved positions are integers
        if (parseInt(dupePositionId)) {
          duplicatePositionIds.push(dupePositionId);
        } else {
          duplicatePositionIds.unshift(dupePositionId);
        }
      });
      initialPositionIdsRenderStructure.push(duplicatePositionIds);
    } else {
      initialPositionIdsRenderStructure.push(position.id);
    }
  });

  const [positionIdsRenderStructure, setPositionIdsRenderStructure] = useState(
    initialPositionIdsRenderStructure,
  );

  const potentialDuplicatePositionsCount = positionIdsRenderStructure.filter(
    intOrAry => Array.isArray(intOrAry) && intOrAry.length > 1,
  ).length;

  const { id } = props.contact.get('data').toJS();
  const hasLinkedInSkillsFeature = useFeatureCheck('feature.linkedin_skills');
  const [
    loadLinkedInSkills,
    loadingLinkedInSkills,
    _linkedInSkillsError,
    linkedInSkillsData,
  ] = useApiGet(
    routes.api_v1_contacts_contact_linkedin_skills({
      query: { contact_id: id, limit: 50 },
    }),
  );

  useEffect(() => {
    if (id && hasLinkedInSkillsFeature) {
      loadLinkedInSkills();
    }
  }, [hasLinkedInSkillsFeature, loadLinkedInSkills]);

  const contactLinkedInSkills =
    linkedInSkillsData?.linkedin_skills.map(
      ({ skill_name: skillName }) => skillName,
    ) ?? [];

  return (
    <TrackedFormContactReviewField
      {...props}
      contactPositions={contactPositions}
      existingContactLinkedInSkills={contactLinkedInSkills}
      formActionProps={{
        confirmOnSubmit: Boolean(potentialDuplicatePositionsCount),
        submitConfirmationText: (
          <>
            <p>
              There are potential duplicate positions that have not been
              resolved.
            </p>
            <p>
              If you proceed, the potential duplicate positions will be saved.
            </p>
          </>
        ),
        submitConfirmationTitle: 'Potential Duplicate Positions',
      }}
      isLoadingExistingLinkedInSkills={loadingLinkedInSkills}
      positionIdsRenderStructure={positionIdsRenderStructure}
      potentialDuplicatePositionsCount={potentialDuplicatePositionsCount}
      setPositionIdsRenderStructure={setPositionIdsRenderStructure}
    />
  );
};

ContactForm.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    data: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  contactPositions: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      to: PropTypes.string,
      company: PropTypes.number,
    }).isRequired,
  ),
  positionIdsRenderStructure: PropTypes.arrayOf(PropTypes.node),
  setPositionIdsRenderStructure: PropTypes.func,
};

export default compose(
  withConnection,
  withContactLoaded,
  withReviewRedirection,
)(ContactForm);

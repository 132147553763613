/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import React from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import Checkbox from '../Checkbox';
import withComponentId from '../withComponentId';

/* eslint-disable jsx-a11y/label-has-associated-control */

/**
 * The component that is rendered in the Table for selecting a row
 * (also used by the TableHead for the "select all" header for that column.)
 */
const RowSelectCheckbox = ({ id, onCheckChange, ...props }) => (
  <Checkbox {...props} id={id} onChange={onCheckChange} />
);

RowSelectCheckbox.propTypes = {
  id: PropTypes.string.isRequired,

  /**
   * Called when the checkbox's checked state is changed
   */
  onCheckChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('RowSelectCheckbox(enhanced)'),
  setPropTypes({
    /**
     * True if the checkbox should be checked, false if it should be unchecked.
     */
    checked: PropTypes.bool.isRequired,

    /**
     * The datum associated with the current row
     * (Note: for the "select all" row this may not be set)
     */
    datum: PropTypes.any,

    /**
     * Called when the checked state is changed, with the signature:
     * ```
     * onChange(isChecked, datum)
     * ```
     */
    onChange: PropTypes.func.isRequired,
  }),
  branch(({ id }) => !id, withComponentId('RowSelectCheckbox', 'id')),
  withHandlers({
    // Takes the `input` onChange, which is called with the change event, and
    // and creates a simplified onChange callback that is called with the
    // checked state and the row's datum.
    onCheckChange: ({ datum, onChange }) => e => {
      onChange(e.target.checked, datum);
    },
  }),
)(RowSelectCheckbox);

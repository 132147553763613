import { connect } from 'react-redux';
import getEntityProperty from '../selectors/getEntityProperty';

/**
 * A higher order component which maps an entity ID prop value to it's entity record.
 * @param {String} entityType The entity type identifier (schema key)
 * @param {String|String[]} propertyNameOrKeyPath The name of the property to retrieve (or keypath
 *   of the property)
 * @param {Object} options
 * @param {String} options.idProp The name of the (incoming) prop that provides the ID
 * @param {String} options.valueProp The name of the (outgoing) prop that provides the property
 *   value for the entity.
 * @param {*} options.notFoundValue An optional value to use if the entity does not have the
 *   property in question.
 * @return {Function} A higher-order compoment for mapping the idProp to the valueProp
 */
export default (
  entityType,
  propertyNameOrKeyPath,
  { idProp = 'id', valueProp = propertyNameOrKeyPath, notFoundValue },
) =>
  connect(
    (state, { [idProp]: id }) => ({
      [valueProp]: getEntityProperty(
        state,
        entityType,
        id,
        propertyNameOrKeyPath,
        notFoundValue,
      ),
    }),
    {},
  );

import { List } from 'immutable';
import {
  compose,
  mapProps,
  setDisplayName,
  setStatic,
  withProps,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import MultiSelect from '../../../components/forms/multiselect/MultiSelect';
import mapLocationIdsToLocations from './mapLocationIdsToLocations';
import withLocationListFetched from './withLocationListFetched';

const createFieldState = (name, value, validator, _, ...rest) =>
  FieldState.create(
    name,
    // If am Immutable.List was passed in, convert it to an array
    List.isList(value) ? value.toArray() : value || [],
    validator,
    // Make sure any values returned are simply IDs.
    outputValue => outputValue.map(item => item.id || item),
    ...rest,
  );

/**
 * A multi-select for selecting from a list of locations
 */
export default compose(
  setDisplayName('LocationMultiSelectField'),
  setStatic('createFieldState', createFieldState),
  withFormGroup,
  asField(),

  // Make sure we have the list of locations.
  withLocationListFetched,

  // Pull out the IDs and loading state
  withProps(({ locationList }) => ({
    locationIds: locationList && locationList.get('ids'),
    isLoading: locationList && locationList.getIn(['_meta', 'isFetching']),
  })),

  // Get the underlying location records from the locationIds.
  mapLocationIdsToLocations,

  // Keep track of any search value entered into the MultiSelect
  withStateHandlers(
    {
      filterText: '',
    },
    {
      onSearchValueChange: () => filterText => ({ filterText: filterText }),
    },
  ),

  // Filter the location list if any filterText was entered (and we have the locations loaded).
  withPropsOnChange(
    ['locations', 'filterText'],
    ({ filterText, locations }) => ({
      filteredLocations:
        locations && locations.count() && stringUtils.trim(filterText)
          ? locations.filter(location =>
              stringUtils.caseInsensitiveIncludes(
                location.get('name'),
                filterText,
              ),
            )
          : locations,
      // No filter text or no locations, just pass them through.
    }),
  ),

  mapProps(
    ({
      filteredLocations,
      isLoading,
      locations,
      onChange,
      onSearchValueChange,
      value,
    }) => ({
      isLoading: isLoading,
      onChange: onChange,
      onSearchValueChange: onSearchValueChange,
      options: filteredLocations ? filteredLocations.toJS() : [],
      placeholder: 'Select Relocation Preferences',
      renderOptionLabel: option => {
        // The `value` is a list of IDs, so in the case that we are rendering something that is just
        // an ID, lookup the related location name.
        if (typeof option === 'number' && locations) {
          const location = locations.find(
            locationRecord => locationRecord.get('id') === option,
          );
          return location ? location.get('name') : '';
        }

        return option.name;
      },
      showWithoutSearching: true,
      summaryLabel: '{0} locations selected',
      summaryThreshold: 4,
      useVirtualRendering: false,
      value: value,
    }),
  ),
)(MultiSelect);

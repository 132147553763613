import { connect } from 'react-redux';
import getInvestmentProperty from '../selectors/getInvestmentProperty';

/**
 * A higher order component which maps a `investmentId` prop value to
 * one of it's data properties.
 * @param {String|String[]} propertyName The property name or keypath to the property
 *   on the investment entity.
 * @param {String} propName The name of the prop to supply to the component. By default
 *   the property name is used.
 */
export default (propertyName, propName) =>
  connect(
    (state, { investmentId }) => ({
      [propName || propertyName]: getInvestmentProperty(
        state,
        investmentId,
        propertyName,
      ),
    }),
    {},
  );

import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import withFormToggleButton from '../../../components/forms/withFormToggleButton';
import FundingRoundForm from './FundingRoundForm';

/**
 * A button that will be replaced with a FundingRoundForm when clicked, and
 * return when the form is successfully submitted or it's cancel button is clicked.
 */
export default compose(
  setDisplayName('FundingRoundFormToggleButton'),
  setPropTypes({
    ...FundingRoundForm.propTypes,

    // Inherit all FundingRoundForm props except for onSaved and onCancel, which is
    // taken care of by `withFormToggleButton`
    onSaved: PropTypes.any,
    onCancel: PropTypes.any,
  }),
  withFormToggleButton,
)(FundingRoundForm);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import CommaDelimitedList from 'modules/core/componentsLegacy/CommaDelimitedList';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import DateTime from 'modules/datetime/components/DateTime';
import mapSearchIdsToSearches from 'modules/searches/components/mapSearchIdsToSearches';
import SearchLink from 'modules/searches/components/SearchLink';
import UserName from 'modules/users/components/UserName';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import mapNoteIdToNote from './mapNoteIdToNote';

/**
 * Renders a single search note
 */
const NoteView = ({
  note,
  pinned,
  renderDate,
  renderRelatedSearches,
  searches,
}) => (
  <div className='notes--note-view'>
    <div className='notes--note-view-creator'>
      {pinned && (
        <i
          aria-hidden='true'
          className='fa fa-thumb-tack notes--note-view-pinned'
        />
      )}
      <UserName userId={note.get('created_by_id')} />
    </div>
    {renderRelatedSearches && searches && searches.size > 0 && (
      <div className='notes--note-view-related-searches'>
        Related to:{' '}
        <CommaDelimitedList>
          {searches.map(search => (
            <li key={search.get('id')}>
              <SearchLink
                rel='noopener noreferrer'
                searchId={search.get('id')}
                target='_blank'
              >
                {search.get('confidential')
                  ? search.get('code_name')
                  : search.get('name')}
              </SearchLink>
            </li>
          ))}
        </CommaDelimitedList>
      </div>
    )}
    {renderDate && (
      <div>
        <div className='notes--note-view-date' key='date'>
          <DateTime
            format='L LT'
            value={note.get('noted_on') || note.get('created_at')}
          />
        </div>
        {note.get('leadership_report') && (
          <div className='notes--note-view-leadership'>
            <p>Status Note</p>
          </div>
        )}
      </div>
    )}
    {note.get('subject') && (
      <div className='notes--note-view-subject'>
        <strong>Subject:</strong> {note.get('subject')}
      </div>
    )}
    <div className='notes--note-view-content'>
      <ExpandableText
        characterLimit={150}
        content={note.get('content')}
        isSanitizedHtml={true}
      />
    </div>
  </div>
);

NoteView.propTypes = {
  /**
   * The search note to render.
   */
  note: ImmutablePropTypes.mapContains({
    content: PropTypes.string.isRequired,
    content_summary: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    noted_on: PropTypes.string,
  }).isRequired,

  /**
   * Renders pinned icon by note if true.
   */
  pinned: PropTypes.bool,

  /**
   * True to render the date field, false to omit it.
   */
  renderDate: PropTypes.bool,

  /**
   * If true, any related searches will be listed; If false this will be omitted.
   */
  renderRelatedSearches: PropTypes.bool,

  /**
   * The search records of the searches related to the note.
   */
  searches: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

NoteView.defaultProps = {
  renderDate: true,
  renderRelatedSearches: true,
};

/**
 * The default export requires only a `interviewId`, while
 * the named export requires the interview record.
 */
export default compose(
  setDisplayName('NoteView(enhanced)'),

  // Either `note` or `noteId` is required. If only an `noteId`
  // is specified, the note will be fetched from state.
  setPropTypes({
    note: requiredIf(
      ImmutablePropTypes.mapContains({
        id: PropTypes.number.isRequired,
      }),
      ({ noteId }) => !noteId,
    ),

    noteId: requiredIf(PropTypes.number, ({ note }) => !note),

    renderDate: PropTypes.bool,
  }),
  branch(props => props.noteId && !props.note, mapNoteIdToNote),
  withProps(({ note }) => ({
    searchIds: note && note.get('searches'),
  })),
  branch(props => props.renderRelatedSearches, mapSearchIdsToSearches),
)(NoteView);

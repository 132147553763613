import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getJobApplicationEmail from '../selectors/getJobApplicationEmail';

/**
 * A Higher order component that sorts a list of jobApplicationIds in order of the applicant's
 * email address.
 */
export default compose(
  setDisplayName('withJobApplicationIdsSortedByEmail'),
  connect(
    (state, { jobApplicationIds }) => ({
      jobApplicationIds:
        jobApplicationIds &&
        jobApplicationIds.sortBy(id =>
          stringUtils.toSortableString(getJobApplicationEmail(state, id)),
        ),
    }),
    {},
  ),
);

import { connect } from 'react-redux';
import getStage from '../selectors/getStage';

/**
 * A higher order component which maps a `stageId` prop value to it's stage record as
 * a `stage` prop.`
 */
export default connect(
  (state, { stageId }) => ({
    stage: getStage(state, stageId),
  }),
  {},
);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * A "section" of a `ProfilePage`. May have optional "sidebar" content that is rendered to the
 * left of the main content.
 *
 * @example
 * ```jsx
 * <ProfilePage>
 *   <ProfilePageSection
 *     sidebar={<div>This will be rendered to the left!</div>}
 *   >
 *     This will be rendered to the right!
 *   </ProfilePageSection>
 *   <ProfilePageSection>This will have no sidebar area</ProfilePageSection>
 * </ProfilePage>
 * ```
 */
const ProfilePageSection = ({ children, className, sidebar }) => (
  <div
    className={classnames('ProfilePageSection', className, {
      'ProfilePageSection--with-sidebar': Boolean(sidebar),
    })}
  >
    {sidebar && <div className='ProfilePageSection__sidebar'>{sidebar}</div>}
    <div className='ProfilePageSection__content'>{children}</div>
  </div>
);

ProfilePageSection.propTypes = {
  /**
   * The main content to render.
   */
  children: PropTypes.node,

  /**
   * An optional classname to apply to the outer component.
   */
  className: PropTypes.string,

  /**
   * Optional content to be displayed in an sidebar area within the page section.
   */
  sidebar: PropTypes.node,
};

export default ProfilePageSection;

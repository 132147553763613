import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { scrollToElement } from 'modules/core/scrollUtils';
import Option from './Option';

/**
 * Renders the list of available options that can be selected from the MultiSelectDropDown.
 */
class OptionList extends PureComponent {
  constructor(props) {
    super(props);

    /**
     * Maintains a list of option components by option ID value so that we can ensure
     * focused items are scrolled into view.
     * @type {Object}
     */
    this.optionComponents = {};
  }

  componentDidUpdate(prevProps) {
    const { focusedId, options } = this.props;
    if (prevProps.focusedId !== focusedId || prevProps.options !== options) {
      this.ensureOptionVisible(focusedId);
    }
  }

  componentWillUnmount() {
    // Probably not necessary, but doesn't hurt.
    this.optionComponents = null;
  }

  setOptionContainerRef = el => {
    // We need a reference to the containerEl so we can figure out if a given element is
    // visibile inside of it.
    this.containerEl = el;
  };

  setOptionRef = el => {
    if (el) {
      this.optionComponents[el.props.value] = el;
    }
  };

  handleOptionChange = event => {
    const { onSelect } = this.props;
    onSelect(event.currentTarget.value);
  };

  handleOptionMouseOver = (event, optionProps) => {
    const { onFocus } = this.props;
    onFocus(optionProps.value);
  };

  /**
   * Ensures that the option with specified ID is scrolled into view if need be.
   */
  ensureOptionVisible(id) {
    if (!this.optionComponents[id]) {
      return;
    }

    const optionEl = this.optionComponents[id].domElement;
    const scrollEl = this.containerEl;

    if (optionEl && scrollEl && scrollEl.scrollHeight > scrollEl.clientHeight) {
      // If the scrolling container is overflowing (scrolling is in effect),
      // check to make sure the option element is visible in the scroll container.
      let top = false;
      const optionBottom = optionEl.offsetTop + optionEl.clientHeight;

      if (optionEl.offsetTop < scrollEl.scrollTop) {
        // The option element is above the current scroll window, so we want to scroll so
        // that the top of the element is visible.
        top = 0;
      } else if (optionBottom > scrollEl.scrollTop + scrollEl.clientHeight) {
        // The option element is below the current scorll window, so we want to scroll so
        // that the bottom of the element is visible.
        top = 1;
      }

      if (top !== false) {
        scrollToElement({
          element: optionEl,
          scrollContainer: scrollEl,
        });
      }
    }
  }

  render() {
    const {
      focusedId,
      idFromOption,
      options,
      renderOptionLabel,
      value,
    } = this.props;

    const selectedOptions = value.map(option => idFromOption(option));

    return (
      <div
        className='multi-select-option-list'
        ref={this.setOptionContainerRef}
      >
        {options.map((option, index) => {
          const id = idFromOption(option);
          return (
            <Option
              isFocused={focusedId === id}
              isSelected={selectedOptions.indexOf(id) !== -1}
              key={id}
              label={renderOptionLabel(option, index)}
              onChange={this.handleOptionChange}
              onFocus={this.handleOptionMouseOver}
              onMouseOver={this.handleOptionMouseOver}
              ref={this.setOptionRef}
              value={id}
            />
          );
        })}
      </div>
    );
  }
}

OptionList.propTypes = {
  /**
   * The ID of the currently focused option.
   */
  focusedId: PropTypes.string,

  /**
   * A function that returns the ID for a single option value.
   */
  idFromOption: PropTypes.func.isRequired,

  /**
   * Called when an option should be focused. Called with the option ID as the first parameter.
   */
  onFocus: PropTypes.func.isRequired,

  /**
   * Called when an option's selected value is changed. Called with the option ID as the first
   * parameter.
   */
  onSelect: PropTypes.func.isRequired,

  /**
   * The list of options to display.
   */
  options: PropTypes.array, // eslint-disable-line react/forbid-prop-types

  /**
   * A function that renders the label for an option.
   */
  renderOptionLabel: PropTypes.func.isRequired,

  /**
   * The currently selected values
   */
  value: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default OptionList;

import { connect } from 'react-redux';
import { compose } from 'recompose';
import getRejectionReasons from '../selectors/getRejectionReasons';
import getRejectionReasonsMeta from '../selectors/getRejectionReasonsMeta';
import fetchRejectionReasonsIfNeeded from './fetchRejectionReasonsIfNeeded';

export default compose(
  fetchRejectionReasonsIfNeeded,
  connect(state => {
    const reasons = getRejectionReasons(state);
    const meta = getRejectionReasonsMeta(state);
    const isFetching = meta && meta.get('isFetching');

    return {
      defaultRejectionReason: !isFetching && reasons.first(),
    };
  }, {}),
);

import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import CompanyName from 'modules/companies/components/CompanyName';
import CommaDelimitedList from 'modules/core/componentsLegacy/CommaDelimitedList';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import mapInvestmentIdToProperty from './mapInvestmentIdToProperty';

/**
 * Renders a list of investors (company names), separated by commas
 */
const InvestmentInvestorList = ({ investors, label }) => (
  <div className='InvestmentInvestorList'>
    {label}
    <CommaDelimitedList>
      {investors.map(investorId => (
        <li key={investorId}>
          <CompanyName companyId={investorId} />
        </li>
      ))}
    </CommaDelimitedList>
  </div>
);

InvestmentInvestorList.propTypes = {
  /**
   * The list of company IDs (investors)
   */
  investors: ImmutablePropTypes.listOf(PropTypes.number.isRequired).isRequired,

  /**
   * An optional label to show at the top of the list
   */
  label: PropTypes.node,
};

export default compose(
  setDisplayName('InvestmentInvestorList(enhanced)'),
  setPropTypes({
    /**
     * If provided, a company ID that will not be shown in the list if it exists.
     * This is useful for displaying this list in the context of a company: If showing a list
     * of investments for a copmany, we can exlude that company here, and label this
     * as "Other investors"
     */
    excludedCompanyId: PropTypes.number,

    /**
     * The ID of the investment to render the investor names for.
     */
    investmentId: PropTypes.number.isRequired,

    label: PropTypes.node,
  }),
  mapInvestmentIdToProperty('investors'),
  mapProps(({ excludedCompanyId, investors, label }) => ({
    investors:
      excludedCompanyId && investors
        ? investors.filter(id => id !== excludedCompanyId)
        : investors,
    label: label,
  })),

  // Don't render anything if there are no investors.
  branch(({ investors }) => !investors || !investors.count(), renderNothing),
)(InvestmentInvestorList);

import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import documentSchema from '../schema';

/**
 * Creates an action for updating a document
 * @param {Object} payload
 * @param {Object} payload.document The document properties
 * @param {Object} payload.document.id The document ID
 */
export default ({ document, ...rest }) =>
  updateEntity({
    ...rest,

    body: { document: document },
    entityType: documentSchema.key,
    id: document.id,
    responseSchema: { document: documentSchema },
    url: routes.api_v1_document({ uuid: document.uuid }),
  });

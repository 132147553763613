import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import seekingJobReasonsSchema from '../schemas/seekingJobReasons';

/**
 * Renders the name of a seekingJobReason from it's ID.
 */

const SeekingJobReasonName = ({ id }) => (
  <EntityProperty
    entityId={id}
    entityType={seekingJobReasonsSchema.key}
    propertyName='name'
  />
);

SeekingJobReasonName.propTypes = {
  id: PropTypes.number.isRequired,
};

export default SeekingJobReasonName;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import { useUpdateDocumentMutation } from 'services/apiV1/documents';
import documentShape, { OwnerTypes } from '../documentShape';
import DocumentTypeSelect from './DocumentTypeSelect';

const EditDocument = ({
  document,
  documentTypes,
  onClose,
  ownerId,
  ownerType,
}) => {
  const dispatch = useDispatch();
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState(
    document?.document_label_id,
  );
  const [
    updateDocument,
    { isLoading: isUpdating },
  ] = useUpdateDocumentMutation();

  const handleUpdate = () => {
    updateDocument({
      recordId: ownerId,
      recordType: pluralize(ownerType),
      documentTypeId: selectedDocumentTypeId,
      documentId: document.id,
    })
      .unwrap()
      .then(() => {
        onClose();
        dispatch(toastSuccess('Successfully updated document'));
      })
      .catch(() => {
        dispatch(toastError('An error has occurred!'));
      });
  };
  return (
    <div className='u-flex u-borderBottom u-flexJustify-spaceBetween u-paddingBottom-12 u-marginBottom-12'>
      <div className='u-flex u-flexAlign-c'>
        <DocumentTypeSelect
          className='u-marginRight-16'
          documentTypes={documentTypes}
          onChange={setSelectedDocumentTypeId}
          value={selectedDocumentTypeId}
        />
        <span>{document.file_name}</span>
      </div>
      <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c'>
        <ButtonPrimary
          isLoading={isUpdating}
          label='Save'
          onClick={handleUpdate}
          size='small'
        />
        <ButtonPrimary
          isOutline={true}
          label='Cancel'
          onClick={() => onClose()}
          size='small'
        />
      </div>
    </div>
  );
};

EditDocument.propTypes = {
  document: PropTypes.objectOf(PropTypes.shape(documentShape)),
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      interim: PropTypes.bool,
      name: PropTypes.string,
    }),
  ),
  onClose: PropTypes.func,
  ownerId: PropTypes.number.isRequired,
  ownerType: PropTypes.oneOf(OwnerTypes).isRequired,
};

export default EditDocument;

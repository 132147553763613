import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import citizenshipSchema from '../schemas/citizenships';

/**
 * A tag select component for choosing tags from the list of citizenships.
 */
const CitizenshipTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField {...props} schema={citizenshipSchema} />
);

CitizenshipTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default CitizenshipTagSelectField;

import PropTypes from 'prop-types';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';
import getClientTypeName from 'modules/tenant/selectors/getClientTypeName';
import getRecruiterName from 'modules/tenant/selectors/getRecruiterName';
import { TYPE_CLIENT, TYPE_RECRUITER, TYPE_RESEARCHER } from '../constants';

/**
 * Renders the display name (or title) for the assessments of a certain type.
 */
export const AssessmentTypeGroupName = ({
  clientTypeName,
  recruiterName,
  type,
}) => {
  switch (type) {
    case TYPE_CLIENT:
      return `${clientTypeName} Assessments`;
    case TYPE_RECRUITER:
      return `${recruiterName} Assessment`;
    case TYPE_RESEARCHER:
      return 'Researcher Assessment';
    default:
      return null;
  }
};

AssessmentTypeGroupName.propTypes = {
  /**
   * The current client type name ("Client", "Hiring Manager", etc.)
   */
  clientTypeName: PropTypes.string.isRequired,

  /**
   * The current recruiter name ("True", etc)
   */
  recruiterName: PropTypes.string.isRequired,

  /**
   * The assessment type to render the display name for.
   */
  type: PropTypes.oneOf([TYPE_CLIENT, TYPE_RECRUITER, TYPE_RESEARCHER])
    .isRequired,
};

export default compose(
  setDisplayName('AssessmentTypeGroupName(enhanced)'),

  // The connected version of this component only requires the
  // `type` prop -- `clientTypeName` and `recruiterName` will be
  // loaded from state.
  setPropTypes({
    type: AssessmentTypeGroupName.propTypes.type,
  }),
  connect(
    state => ({
      clientTypeName: getClientTypeName(state),
      recruiterName: getRecruiterName(state),
    }),
    {},
  ),
  onlyUpdateForPropTypes,
)(AssessmentTypeGroupName);

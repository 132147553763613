import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Rating from '@thrivetrm/ui/components/Rating';
import ExpandableCard from '@thrivetrm/ui/components/ExpandableCard';
import DateElement from '@thrivetrm/ui/components/Date';
import Menu from '@thrivetrm/ui/components/Menu';
import PropTypes from 'prop-types';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import isAdmin from 'modules/auth/selectors/isAdmin';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { useDeleteRecruitmentRatingMutation } from 'services/apiV1/recruitmentRatings';
import { useGetSearchQuery } from 'services/apiV1/searches';
import RecruitmentRatingExpandedDetails from './RecruitmentRatingExpandedDetails';
import useQuickView from '../useQuickView';
import RecruitmentRatingDeleteModal from './RecruitmentRatingDeleteModal';

const RecruitmentRatingCard = ({ recruitmentRating }) => {
  const { milestone } = recruitmentRating.search;
  const dispatch = useDispatch();
  const { navigateTo, pathParams } = useQuickView();
  const { companyId } = pathParams;
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useToggle(
    false,
  );
  const { data: searchData } = useGetSearchQuery(recruitmentRating.search.id);
  const currentUserId = useSelector(getCurrentUserId);
  const isAdminUser = useSelector(isAdmin);
  const [deleteRecruitmentRating] = useDeleteRecruitmentRatingMutation();

  const deleteRating = () => {
    deleteRecruitmentRating({
      companyId: companyId,
      ratingId: recruitmentRating.id,
    }).then(() => {
      closeDeleteModal();
      dispatch(toastSuccess('Successfully deleted recruitment rating'));
    });
  };

  const editRating = () => {
    const url = `/company/${companyId}/recruitment_ratings/${recruitmentRating.id}/edit`;
    navigateTo(url);
  };

  const menu = (
    <Menu
      button={
        <ButtonSecondary
          aria-label='edit/delete rating option'
          icon='options'
          size='small'
        />
      }
      isPinnedRight={true}
    >
      <Menu.Item onClick={editRating}>Edit</Menu.Item>
      <Menu.Item onClick={openDeleteModal}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <>
      <ExpandableCard
        className='u-paddingHorizontal-24 u-paddingTop-24 u-marginTop-24'
        expandedContent={
          <RecruitmentRatingExpandedDetails record={recruitmentRating} />
        }
      >
        <div className='u-flex u-flexJustify-spaceBetween'>
          <Rating value={recruitmentRating.rating} />
          {isAdminUser ||
          recruitmentRating.createdById === currentUserId ||
          recruitmentRating.ratedBy.id === currentUserId
            ? menu
            : null}
        </div>
        <strong className='u-marginBottom-8'>
          <a href={`/searches/${recruitmentRating.search.id}/candidates`}>
            {searchData?.name}
          </a>
        </strong>

        <div
          className={classnames('u-textColor-gray60 u-fontWeight-bold', {
            'u-marginBottom-4': Boolean(milestone),
          })}
        >
          {searchData?.status}{' '}
          {searchData?.endDate ? (
            <DateElement date={searchData.endDate} />
          ) : null}
        </div>

        {milestone ? (
          <div className='u-textColor-gray60 u-fontWeight-bold'>
            {milestone}
          </div>
        ) : null}
      </ExpandableCard>
      <RecruitmentRatingDeleteModal
        closeDeleteModal={closeDeleteModal}
        deleteRating={deleteRating}
        isDeleteModalOpen={isDeleteModalOpen}
        searchName={recruitmentRating.search.name}
      />
    </>
  );
};

export default RecruitmentRatingCard;

RecruitmentRatingCard.propTypes = {
  recruitmentRating: PropTypes.shape({
    createdAt: PropTypes.string,
    createdById: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    notes: PropTypes.string,
    ratedBy: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    rating: PropTypes.number,
    search: PropTypes.shape({
      endDate: PropTypes.string,
      externalPrimaryRecruiter: PropTypes.string,
      id: PropTypes.number,
      milestone: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
  }),
};

import selectn from 'selectn';
import {
  compose,
  mapProps,
  setDisplayName,
  withHandlers,
  withProps,
} from 'recompose';
import withFormState from 'modules/forms/components/withFormState';
import withTransaction from 'modules/transactions/components/withTransaction';
import CompanyLogoField from './CompanyLogoField';
import withCompanyActions from '../withCompanyActions';
import mapCompanyIdToCompany from '../mapCompanyIdToCompany';

/**
 * Provides props for managing a form for creating/editing an company logo.
 */
export default compose(
  setDisplayName('withCompanyLogoForm'),
  withProps(props => ({
    withCompanyLogoFormProps: props,
  })),
  mapCompanyIdToCompany,
  withFormState(({ company }) =>
    CompanyLogoField.createFieldState('logo', company.get('logo_url')),
  ),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onResetFormState,
      onSaved,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();
        onSaved(selectn('payload.result.company', transaction));
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  /**
   * Include connected versions of `companyActions.updateCompany` and
   * `companyActions.updateLogo`
   */
  withCompanyActions,

  /**
   * Add a callback to handle submitting the action form.
   */
  withHandlers({
    onSubmit: ({
      company,
      companyActions: { updateCompany, updateLogo },
      companyId,
      formState,
      onCancel,
      onFormStateChange,
      startTransaction,
    }) => e => {
      // Prevent default browser behavior, which could cause the browser to attempt
      // to submit a form on it's own.
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }

      const logo = formState.getFieldValue();
      const currentUrl = company.get('logo_url');

      if (logo === currentUrl) {
        // If the user hasn't changed anything, no need to submit, call onCancel instead.
        onCancel();
        return;
      }

      let transactionId;

      if (logo && logo[0] !== null) {
        // If there is a logo, it MUST be a new file (either that or it is the
        // existing URL, but we would have bailed if that's the case when
        // we checked above if logo === currentUrl)
        transactionId = startTransaction();
        updateLogo({
          logo: logo,
          companyId: companyId,
          transactionId: transactionId,
        });
      } else if (!logo) {
        // If logo has no value, then the user removed the current logo.
        transactionId = startTransaction();
        updateCompany({
          companyId: companyId,
          transactionId: transactionId,
          company: { remove_logo: true, id: companyId },
        });
      }

      if (transactionId) {
        onFormStateChange(formState.startSubmit(transactionId));
      }
    },
  }),

  // Prevent any props we used/created from bleeding down to the child.
  mapProps(
    ({
      formState,
      onFieldStateChange,
      onFormStateChange,
      onSubmit,
      withCompanyLogoFormProps,
    }) => ({
      ...withCompanyLogoFormProps,
      formState: formState,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onSubmit: onSubmit,
    }),
  ),
);

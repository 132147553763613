import React from 'react';
import PropTypes from 'prop-types';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import Card from '@thrivetrm/ui/components/Card';
import classnames from 'classnames';
import Icon from '@thrivetrm/ui/components/Icon';
import PluralText from '@thrivetrm/ui/components/PluralText';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import { employeePropType } from 'services/apiV1/reportingRelationships';
import ContactReportingCardsDepthTwo from './ContactReportingCardsDepthTwo';

import './ContactReporting.scss';

const ContactReportingCards = ({ employee, employeeTreeDepthOfTwo }) => {
  const [isDrawerOpen, _expand, _collapse, toggleDrawer] = useToggle(false);

  const handleKeyDown = event => {
    // handle spacebar and enter to open/close group
    if (event.key === ' ' || event.key === 'Enter') {
      toggleDrawer();
    }
  };

  const filterManages = (dTwoTree, dOneEmployee) => {
    const matches = dTwoTree.filter(
      dTwoEmployee => dTwoEmployee.managerId === dOneEmployee.employeeId,
    );
    return matches;
  };

  return (
    <div className='u-marginVertical-8'>
      <Card className='u-marginBottom-8' isCentered={false}>
        <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c'>
          <ContactSnapshot
            avatarUrl={employee.avatarUrl}
            company={{ name: employee.employeeCompany }}
            name={employee.employeeName}
            title={employee.employeePosition}
            url={`/contacts/${employee.employeeId}`}
          />
        </div>
        {filterManages(employeeTreeDepthOfTwo, employee).length ? (
          <div className='u-flex u-flexJustify-c'>
            <div
              className={classnames('directReportsRevealTrigger', {
                'u-marginVertical-12': isDrawerOpen,
                'u-marginTop-12': !isDrawerOpen,
              })}
              onClick={toggleDrawer}
              onKeyDown={handleKeyDown}
              role='button'
              tabIndex={0}
            >
              <h6 className='u-margin-n u-marginRight-8'>
                {isDrawerOpen ? 'Hide' : 'Show'}{' '}
                {filterManages(employeeTreeDepthOfTwo, employee).length} Direct{' '}
                <PluralText
                  quantity={
                    filterManages(employeeTreeDepthOfTwo, employee).length
                  }
                  text='Report'
                />
              </h6>
              <Icon
                size='small'
                type={isDrawerOpen ? 'chevronUp' : 'chevronDown'}
              />
            </div>
          </div>
        ) : null}
        {filterManages(employeeTreeDepthOfTwo, employee).length &&
        isDrawerOpen ? (
          <ContactReportingCardsDepthTwo
            employeeTreeDepthOfTwo={filterManages(
              employeeTreeDepthOfTwo,
              employee,
            )}
          />
        ) : null}
      </Card>
    </div>
  );
};

export default ContactReportingCards;

ContactReportingCards.propTypes = {
  employee: employeePropType.isRequired,
  employeeTreeDepthOfTwo: PropTypes.arrayOf(employeePropType).isRequired,
};

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import * as sortUtils from 'modules/core/sortUtils';
import getActive from '../selectors/targetCompanies/getActive';
import getSortByCandidatesCount from '../selectors/targetCompanies/getSortByCandidatesCount';
import getSortByCompanyName from '../selectors/targetCompanies/getSortByCompanyName';
import getSortByCompanyLocationName from '../selectors/targetCompanies/getSortByCompanyLocationName';
import getSortByPriorityVoteCount from '../selectors/targetCompanies/getSortByPriorityVoteCount';

import {
  SORT_BY_VALUES,
  SORT_BY_DEFAULT,
  SORT_ASCENDING_DEFAULT,
  SORT_BY_COMPANY,
  SORT_BY_PRIORITY_VOTE_COUNT,
  SORT_BY_CANDIDATES_COUNT,
  SORT_BY_COMPANY_LOCATION_NAME,
} from '../constants';

/**
 * A higher order component that provides sorting of a list of target company IDs provided by an
 * `targetCompanyIds` prop, based on the prop values of `sortBy` and `sortAscending`.
 */
export default compose(
  setDisplayName('withTargetCompanyIdsSorted'),
  setPropTypes({
    sortActiveLast: PropTypes.bool.isRequired,
    sortAscending: PropTypes.bool.isRequired,
    sortBy: PropTypes.oneOf(SORT_BY_VALUES).isRequired,
    targetCompanyIds: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  defaultProps({
    sortActiveLast: true,
    sortAscending: SORT_ASCENDING_DEFAULT,
    sortBy: SORT_BY_DEFAULT,
  }),
  connect((state, { sortBy, targetCompanyIds }) => {
    // Bail early if we've got nothing to sort.
    if (!targetCompanyIds || targetCompanyIds.count() <= 1) {
      return {};
    }

    switch (sortBy) {
      case SORT_BY_COMPANY: {
        return {
          targetCompanyIds: targetCompanyIds.sortBy(id =>
            getSortByCompanyName(state, id),
          ),
        };
      }
      case SORT_BY_PRIORITY_VOTE_COUNT: {
        return {
          targetCompanyIds: targetCompanyIds.sortBy(
            id => getSortByPriorityVoteCount(state, id),
            sortUtils.arrayComparator,
          ),
        };
      }
      case SORT_BY_CANDIDATES_COUNT: {
        return {
          targetCompanyIds: targetCompanyIds.sortBy(
            id => getSortByCandidatesCount(state, id),
            sortUtils.arrayComparator,
          ),
        };
      }
      case SORT_BY_COMPANY_LOCATION_NAME: {
        return {
          targetCompanyIds: targetCompanyIds.sortBy(id =>
            getSortByCompanyLocationName(state, id),
          ),
        };
      }
      default: {
        return {};
      }
    }
  }, {}),

  // Reverse the sort if needed based on the `sortAscending` prop value.
  withProps(({ sortAscending, targetCompanyIds }) => ({
    targetCompanyIds:
      targetCompanyIds && !sortAscending
        ? targetCompanyIds.reverse()
        : targetCompanyIds,
  })),

  // Show all active items before inactive items.
  connect((state, { sortActiveLast, targetCompanyIds }) => ({
    targetCompanyIds:
      targetCompanyIds && sortActiveLast
        ? targetCompanyIds
            .groupBy(id => getActive(state, id))
            // Sort the groups so active is first
            .sortBy(active => (active ? 0 : 1))
            // Reduce back to a flattened list
            .reduce((reduced, group) => reduced.concat(group))
        : targetCompanyIds,
  })),
);

import React from 'react';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { PARENT_CONTACT } from 'modules/assessments/constants';
import withAssessmentListFetched from 'modules/assessments/components/withAssessmentListFetched';
import ContactRatingsPanel from 'modules/contacts/components/ratings/ContactRatingsPanel';
import mapContactIdToFullName from 'modules/contacts/components/mapContactIdToFullName';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import Modal from 'modules/core/componentsLegacy/Modal';
import CandidacyAssessmentModalButton from '../assessments/CandidacyAssessmentModalButton';

/**
 * Renders a `ContactRatingsPanel` inside a modal. If the contact also has candidacy assessments,
 * the number of assessments are shown the footer of the modal, and clicking them will display
 * those. If we're loading we'll show a message in the footer instead, and if we've loaded
 * them and there are no assessments, we just don't render the footer at all.
 */
const ContactRatingsModal = ({
  assessmentList,
  className,
  contactId,
  modalTitle,
  sidebar,
  ...props
}) => (
  <Modal
    className={classnames('ContactRatingsModal', className, {
      SidebarModal: sidebar,
    })}
    {...props}
  >
    <Modal.Header closeButton={true}>
      <Modal.Title>{modalTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ContactRatingsPanel contactId={contactId} />
    </Modal.Body>
    {assessmentList &&
      assessmentList.has('ids') &&
      assessmentList.get('ids').size > 0 && (
        <Modal.Footer key='has-assessments'>
          <CandidacyAssessmentModalButton
            className='btn btn-link'
            contactId={contactId}
          >
            <i className='fa fa-star-half-o' /> View{' '}
            {assessmentList.get('ids').size} Search Related Assessments
          </CandidacyAssessmentModalButton>
        </Modal.Footer>
      )}
    {!(assessmentList && assessmentList.has('ids')) && (
      <Modal.Footer key='loading-assessments'>
        <div className='ContactRatingsModal__loadingAssessments'>
          <LoadingIndicator size={16} /> Checking for Search Related
          Assessments...
        </div>
      </Modal.Footer>
    )}
  </Modal>
);

ContactRatingsModal.propTypes = {
  /**
   * The list of assessments belonging to the contact. Used to determine what to display (if
   * anything) in the footer
   */
  assessmentList: ImmutablePropTypes.mapContains({
    ids: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
  }),

  /**
   * Optional additional classes to apply
   */
  className: PropTypes.string,

  /**
   * The id of the contact to show rating information for.
   */
  contactId: PropTypes.number.isRequired,

  /**
   * The title of the modal
   */
  modalTitle: PropTypes.string.isRequired,

  /**
   * true to show as a "sidebar" modal, false to show as a standard modal.
   */
  sidebar: PropTypes.bool,
};

ContactRatingsModal.defaultProps = {
  sidebar: true,
};

export default compose(
  setDisplayName('ContactRatingsModal(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),

  // Lookup the contact's full name to display in the title of the modal.
  mapContactIdToFullName,
  mapProps(({ fullName, ...props }) => ({
    ...props,

    modalTitle: `${stringUtils.possessivize(fullName)} Ratings`,

    // Required by with `withAssessmentListFetched`
    parentType: PARENT_CONTACT,
  })),

  // In order to display the number of assessments this contact has (and show the
  // CandidacyAssessmentModalButton in the footer based on that), we need to fetch all
  // assessments.
  withAssessmentListFetched({
    parentIdPropName: 'contactId',
  }),

  // Remove the parentType prop name, it's no longer needed.
  mapProps(({ parentType: _, ...props }) => props),
)(ContactRatingsModal);

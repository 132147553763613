import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import TimeLabel from 'modules/datetime/components/TimeLabel';

import Icon from '@thrivetrm/ui/components/Icon';
import NotificationStatusButton from '../NotificationStatusButton';
import ToggleReadButton from '../ToggleReadButton';
import SearchTitles from '../SearchTitles';
import notificationHandler from '../notificationHandler';

const isLegacy = notification =>
  notification.getIn(['data', 'key']) === 'legacy';

const shouldManageReadStatus = props =>
  props.shouldManageReadStatus && !isLegacy(props.notification);

/**
 * A component for displaying a single notification
 */
class NotificationItemTypeNotification extends Component {
  handleToggleRead = () => {
    const { notification, onUpdateRead } = this.props;
    onUpdateRead(notification, notification.getIn(['data', 'unread']));
  };

  render() {
    const {
      actions,
      canDelete,
      canEdit,
      children,
      introMessage,
      notification,
      onDelete,
      onEdit,
      onPrimaryAction,
      primaryAction,
      shouldManageReadStatus: shouldManageReadStatusProp,
      showStatusButton,
      showTitleLink,
    } = this.props;

    const notificationData = notification.get('data');
    const search =
      notificationData.get('search') ||
      notificationData.getIn(['resource', 'data', 'search']);
    const searches = notificationData.getIn(['resource', 'data', 'searches']);
    const showRelatedSearches = search || Boolean(searches && searches.size);
    const isUnread = Boolean(notificationData.get('unread'));
    const interviewCreatedByName =
      notificationData.get('type') === 'interview' &&
      notificationData.getIn(['resource', 'data', 'created_by', 'name']);
    const notificationDate =
      notificationData.get('resource_date') ||
      notificationData.get('assessed_on') ||
      notificationData.get('created_at');
    const isEvent = notificationData.get('type') === 'event_contact';
    const titleProps = {
      href: primaryAction || '#',
      onClick: isEvent && !shouldManageReadStatusProp ? null : onPrimaryAction,
      target: isEvent && !shouldManageReadStatusProp ? '_blank' : '_self',
    };

    if (typeof primaryAction === 'string') {
      titleProps.href = primaryAction;
    } else if (notification.hasIn(['data', 'links', 'self'])) {
      titleProps.href = notification.getIn(['data', 'links', 'self']);
    }

    const itemClasses = {
      'notification-item': true,
      'notification-item--is-legacy': isLegacy(notification),
      [`notification-item--type-${notificationData.get('type')}`]: true,
    };

    if (shouldManageReadStatus(this.props)) {
      itemClasses['notification-item--read'] = !isUnread;
      itemClasses['notification-item--unread'] = isUnread;
    }

    const message = introMessage || notificationData.get('message');

    // Flag to determine whether the <Icon> component should be used versus the Font Awesome icons.
    // Currently, this only supports Mailchimp, but other instances may crop up in the future.
    const shouldUseIconComponent = notificationData.getIn(
      ['resource', 'data', 'contact_method', 'is_mailchimp'],
      false,
    );

    const renderIcon = () => {
      if (shouldUseIconComponent) {
        // NOTE: The Icon component was pulled in initially for usage with Mailchimp. Below will
        // need to be updated to support additional icons in the future, if the need arises.
        return <Icon size='large' type='mailchimp' />;
      }
      /**
       * All of the Activity Timeline types use ui icons EXCEPT for the legacy
       * activity type which is represented by a Font Awesome bell-o icon
       */
      const icon = notificationData.get('icon');
      return icon === 'bell-o' ? (
        <i className={classnames('fa', 'fa-fw', 'fa-2x', `fa-${icon}}`)} />
      ) : (
        <Icon size='large' type={icon} />
      );
    };

    return (
      <div className={classnames(itemClasses)}>
        {(canEdit || canDelete) && (
          <div className='notification-item-edit-actions pull-right'>
            {canEdit && (
              <button
                className='btn btn-link'
                data-testid='Edit Button'
                onClick={onEdit}
                type='button'
              >
                <i className='fa fa-pencil' />
              </button>
            )}
            {canDelete && (
              <ConfirmationPopoverButton
                className='btn btn-link'
                disabled={notification.getIn(['meta', 'isDeleting'])}
                iconClass='fa fa-trash'
                key='delete'
                onConfirm={onDelete}
                title={`Are you sure you want to delete this ${notificationData.get(
                  'type',
                )}?`}
              />
            )}
          </div>
        )}
        <div className='notification-icon' key='icon'>
          {renderIcon()}
        </div>
        {showStatusButton && (
          <NotificationStatusButton notification={notificationData} />
        )}
        <div className='notification-content' key='content'>
          <div
            className='notification-heading list-group-item-heading'
            key='heading'
          >
            <h4 className='notification-title u-marginBottom-n'>
              {showTitleLink ? <a {...titleProps}>{message}</a> : message}
            </h4>
            {interviewCreatedByName && (
              <div className='notification-organizer-name'>
                Organized by: {interviewCreatedByName}
              </div>
            )}
            {showRelatedSearches && (
              <SearchTitles
                notification={notification}
                search={search}
                searches={searches}
                searchLinks={notificationData.get('links')}
              />
            )}
            <small className='notification-timestamp' key='timestamp'>
              <TimeLabel
                adjustTime={6000}
                data-placement='right'
                data-toggle='tooltip'
                time={notificationDate}
              />
            </small>
          </div>
          <div className='notification-details' key='more'>
            {children}
          </div>
          <div className='notification-actions' key='actions'>
            {actions}
          </div>
        </div>
        {shouldManageReadStatus(this.props) && notificationData.has('unread') && (
          <div className='notification-toggle-read'>
            <ToggleReadButton
              isUpdating={notification.getIn(['meta', 'isUpdating'])}
              key='toggleRead'
              onClick={this.handleToggleRead}
              unread={isUnread}
            />
          </div>
        )}
      </div>
    );
  }
}

NotificationItemTypeNotification.propTypes = {
  /**
   * Additional action buttons to add to the notification
   */
  actions: PropTypes.arrayOf(PropTypes.node),

  /**
   * True to show the delete icon/confirmation popover. Actual delete should be handled
   * using the onDelete prop/callback.
   */
  canDelete: PropTypes.bool,

  /**
   * True to show the edit icon button. Handle edit move by
   * using the onEdit prop/callback.
   */
  canEdit: PropTypes.bool,

  /**
   * Additional content to render as the details of this notification item.
   */
  children: PropTypes.node,

  /**
   * Introduction messages render as links
   */

  introMessage: PropTypes.node,

  /**
   * The notification state
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      created_at: PropTypes.string,
      icon: PropTypes.string.isRequired,
      id: PropTypes.number,
      key: PropTypes.string,
      links: ImmutablePropTypes.mapContains({
        self: PropTypes.string,
      }),
      message: PropTypes.string.isRequired,
      resource: ImmutablePropTypes.mapContains({
        meta: ImmutablePropTypes.map,
        data: ImmutablePropTypes.map,
      }),
      search: PropTypes.object,
      type: PropTypes.string,
      unread: PropTypes.bool,
    }).isRequired,
    meta: ImmutablePropTypes.mapContains({
      isUpdating: PropTypes.bool,
    }).isRequired,
  }).isRequired,

  /**
   * Called when the delete confirmation button has been clicked. (@see canDelete)
   */
  onDelete: PropTypes.func,

  /**
   * Called when the edit button is clicked (@see canEdit)
   */
  onEdit: PropTypes.func,

  onPrimaryAction: PropTypes.func,

  onUpdateRead: requiredIf(PropTypes.func, shouldManageReadStatus),

  primaryAction: PropTypes.string,

  /**
   * Determines whether the notification can be marked/unmarked as read, and whether the read state
   * of the item is used to style the notification.
   * Note: eslint is currently not correctly detecting this propType because of how it is being
   * accessed.
   */
  shouldManageReadStatus: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types

  showStatusButton: PropTypes.bool,

  /**
   * Optional prop to display link on the notification title (otherwise plain text
   * is displayed)
   */
  showTitleLink: PropTypes.bool,
};

NotificationItemTypeNotification.defaultProps = {
  showStatusButton: false,
};

export default notificationHandler(NotificationItemTypeNotification);

import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import {
  DOCUMENTS_FETCH_START,
  DOCUMENTS_FETCH_SUCCESS,
  DOCUMENTS_FETCH_FAILURE,
} from './actionTypes';
import documentSchema from '../schema';
import getDocumentListUrl from './getDocumentListUrl';

/**
 * Creates an action for fetching a list of documents
 * @param {Object} payload
 * @param {String} payload.ownerType The type of owner ("Search", "Contact")
 * @param {Number} payload.ownerId The owner ID (contact ID or search ID, depending on the
 *   ownerType)
 */
export default compose(
  fetchAction,
  normalizeResponse({ documents: [documentSchema] }),
  createAsyncActions({
    startActionType: DOCUMENTS_FETCH_START,
    successActionType: DOCUMENTS_FETCH_SUCCESS,
    failureActionType: DOCUMENTS_FETCH_FAILURE,
  }),
  withOptions(({ ownerId, ownerType }) => ({
    url: getDocumentListUrl({ ownerType: ownerType, ownerId: ownerId }),
  })),
);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import AppointmentForm from 'modules/appointments/components/AppointmentForm';
import * as appointmentConstants from 'modules/appointments/constants';
import connectInterviewActions from 'modules/interviews/components/connectInterviewActions';
import InterviewNotificationView from 'modules/interviews/components/InterviewNotificationView';
import Notification from './Notification';

/**
 * Renders a notification with a resource type of `interview`
 */
const NotificationItemTypeInterview = ({
  allowDelete,
  allowEdit,
  handleCancel,
  handleChange,
  handleDelete,
  handleEdit,
  handleSaved,
  isEditing,
  isSendingUpdateToAttendees,
  notification,
  ...props
}) => {
  const interview = notification.getIn(['data', 'resource']);
  const interviewId = interview.getIn(['data', 'id']);
  const contactId = notification.getIn(['data', 'contact_id']);
  const canClearCalendarSelection = !interview.getIn([
    'data',
    'integration_id',
  ]);

  const getSubmitLabel = () => {
    if (!canClearCalendarSelection || isSendingUpdateToAttendees) {
      return 'Update Invite';
    }
    return 'Update Interview';
  };

  return (
    <Notification
      canDelete={
        allowDelete && interview.getIn(['data', 'permissions', 'delete'])
      }
      canEdit={allowEdit && interview.getIn(['data', 'permissions', 'edit'])}
      notification={notification}
      onDelete={handleDelete}
      onEdit={handleEdit}
      {...props}
    >
      {isEditing ? (
        <AppointmentForm
          allowRelatedSearchEdit={true}
          asRichTextForm={true}
          canClearCalendarSelection={canClearCalendarSelection}
          contactId={contactId}
          draftStoragePath={{
            contact: contactId,
            interview: interviewId,
          }}
          errorDisplay='tooltip'
          fieldLayout='wide'
          formActionProps={{
            submitButtonIcon: 'fa-send u-marginRight-4',
            submitLabel: getSubmitLabel(),
            fillWidth: false,
          }}
          hideLabel={true}
          interviewId={interviewId}
          isEditing={isEditing}
          onCancel={handleCancel}
          onChange={handleChange}
          onSaved={handleSaved}
          permittedTypes={appointmentConstants.INTERVIEW_PERMITTED_TYPES}
        />
      ) : (
        <InterviewNotificationView
          attendees={interview.getIn(['data', 'attendees']).toJS()}
          interviewId={interviewId}
          params={notification.getIn(['data', 'params'])}
        />
      )}
    </Notification>
  );
};

NotificationItemTypeInterview.propTypes = {
  /**
   * True to allow the interview item to be deleted if the user is permitted.
   */
  allowDelete: PropTypes.bool,

  /**
   * True to allow the interview item to be edited inline if the user is permitted.
   */
  allowEdit: PropTypes.bool,

  handleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,

  handleDelete: PropTypes.func.isRequired,

  handleEdit: PropTypes.func.isRequired,

  handleSaved: PropTypes.func.isRequired,

  interviewActions: requiredIf(
    PropTypes.shape({
      deleteInterview: PropTypes.func,
    }),
    props => props.allowEdit || props.allowDelete,
  ),
  /**
   * True to show the interview form, false to show the interview view.
   */
  isEditing: PropTypes.bool,
  /**
   * True when an integrated calendar has been selected and udpates will be sent to all attendees
   */
  isSendingUpdateToAttendees: PropTypes.bool,
  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      type: PropTypes.oneOf(['interview']),
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          id: PropTypes.number.isRequired,
        }),
      }),
    }),
  }).isRequired,
};

export default compose(
  withState('isEditing', 'setEditing', false),
  withState(
    'isSendingUpdateToAttendees',
    'setIsSendingUpdateToAttendees',
    false,
  ),
  withHandlers({
    handleEdit: ({ setEditing }) => () => setEditing(true),
    handleCancel: ({ setEditing }) => () => setEditing(false),
    handleChange: ({ setIsSendingUpdateToAttendees }) => formState =>
      setIsSendingUpdateToAttendees(
        Boolean(formState.getFieldValue().integration_id),
      ),
    handleSaved: ({ setEditing }) => () => setEditing(false),
  }),
  connectInterviewActions,
  withHandlers({
    handleDelete: ({ interviewActions, notification }) => () => {
      const interview = notification.getIn(['data', 'resource', 'data']);
      interviewActions.deleteInterview({
        contactId: interview.get('contact_id'),
        candidacyId: interview.get('candidacy_id'),
        id: interview.get('id'),
      });
    },
  }),
)(NotificationItemTypeInterview);

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import { useApiGet } from 'modules/core/hooks/useApi';
import isCandidateUser from 'modules/auth/selectors/isCandidateUser';
import routes from 'modules/routing/routes';
import withContactForm from 'modules/contacts/components/withContactForm';
import CancelButton from 'modules/forms/components/CancelButton';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import ContactDiversityField from './ContactDiversityField';

/**
 * A form for editing a contact's diversity related fields.
 */
const ContactDiversityForm = ({
  candidateName,
  demographicsLastUpdatedAt,
  disabilityStatusSelfReported,
  formState,
  genderSelfReported,
  onCancel,
  onFieldStateChange,
  onSubmit,
  raceEthnicitySelfReported,
  sexualOrientationSelfReported,
  veteranStatusSelfReported,
  ...fieldProps
}) => {
  const [
    loadCustomersConfiguration,
    _isLoadingCustomersConfiguration,
    _customersConfigurationLoadError,
    customersConfiguration,
  ] = useApiGet(routes.api_v1_customers_configuration());

  useEffect(() => {
    loadCustomersConfiguration();
  }, [loadCustomersConfiguration]);

  const demographicJustificationMessage =
    customersConfiguration?.customer_configuration
      ?.demographics_justification_message || null;

  const shouldShowDemographicsJustificationMessage =
    useSelector(state => isCandidateUser(state)) &&
    Boolean(demographicJustificationMessage);

  return (
    <Form formState={formState} onSubmit={onSubmit}>
      {shouldShowDemographicsJustificationMessage && (
        <p className='ContactDiversityForm__demographics-justification-message help-block'>
          {demographicJustificationMessage}
        </p>
      )}
      <ContactDiversityField
        {...fieldProps}
        candidateName={candidateName}
        demographicsLastUpdatedAt={demographicsLastUpdatedAt}
        disabilityStatusSelfReported={disabilityStatusSelfReported}
        disabled={formState.isSubmitting()}
        fieldState={formState.getFieldState()}
        genderSelfReported={genderSelfReported}
        onChange={onFieldStateChange}
        raceEthnicitySelfReported={raceEthnicitySelfReported}
        sexualOrientationSelfReported={sexualOrientationSelfReported}
        showErrors={formState.wasSubmitted() || 'blurred'}
        veteranStatusSelfReported={veteranStatusSelfReported}
      />
      <FormErrorMessage formState={formState} />
      <div className='Form__footer'>
        <CancelButton onClick={onCancel} />
        <SubmitButton formState={formState} onClick={onSubmit} />
      </div>
    </Form>
  );
};

ContactDiversityForm.propTypes = {
  /**
   * Candidate name
   */
  candidateName: PropTypes.string,
  /**
   * Last updated time of Demographics field
   */
  demographicsLastUpdatedAt: PropTypes.shape({
    disabilityStatus: PropTypes.string,
    gender: PropTypes.string,
    raceEthnicity: PropTypes.string,
    sexualOrientation: PropTypes.string,
    veteranStatus: PropTypes.string,
  }),

  /**
   * The current FormState of the form.
   */
  disabilityStatusSelfReported: PropTypes.bool.isRequired,

  formState: formStatePropType.isRequired,

  genderSelfReported: PropTypes.bool.isRequired,

  /**
   * Called when the cancel button is clicked.
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  raceEthnicitySelfReported: PropTypes.bool.isRequired,

  sexualOrientationSelfReported: PropTypes.bool.isRequired,
  veteranStatusSelfReported: PropTypes.bool.isRequired,
};

/**
 * The connected version of this component includes a FormState/FieldState and
 * handlers for submitting the form.
 */
export default compose(
  setDisplayName('ContactDiversityForm(enhanced)'),
  setPropTypes({
    /**
     * The ID of the contact for which the job opportunity info will be edited.
     */
    contactId: PropTypes.number,

    /**
     * Called when the cancel button on the form is clicked.
     */
    onCancel: PropTypes.func.isRequired,

    /**
     * Called when the form has been successfully saved.
     */
    onSaved: PropTypes.func.isRequired,
  }),

  // Provides the field state, form state, and submission handling.
  withContactForm(ContactDiversityField),

  /**
   * Prevent props from being passed on to the underlying fieldState
   */
  /* eslint-disable no-unused-vars */
  mapProps(
    ({ contactId, onFormStateChange, onResetFormState, onSaved, ...rest }) => ({
      ...rest,
    }),
  ),
  /* eslint-enable no-unused-vars */
)(ContactDiversityForm);

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import getEmployeeCountListSelector from '../selectors/getEmployeeCountList';
import shouldFetchEmployeeCountListSelector from '../selectors/shouldFetchEmployeeCountList';
import fetchEmployeeCountListActionCreator from '../actions/fetchEmployeeCountList';

/**
 * Provides a `employeeCountList` prop -- the state of a list of employees
 * for a particular company ID. Fetches the list if needed.
 */
export default compose(
  setDisplayName('withEmployeeCountListFetched'),
  setPropTypes({
    companyId: PropTypes.number,
  }),
  connect(
    (state, props) => ({
      shouldFetchEmployeeCountList: shouldFetchEmployeeCountListSelector(
        state,
        props.companyId,
      ),
      employeeCountList: getEmployeeCountListSelector(state, props.companyId),
    }),
    dispatch => ({
      fetchEmployeeCountList: bindActionCreators(
        fetchEmployeeCountListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchEmployeeCountListIfNeeded: ({
      companyId,
      fetchEmployeeCountList,
      shouldFetchEmployeeCountList,
    }) => () => {
      if (shouldFetchEmployeeCountList) {
        fetchEmployeeCountList({ companyId: companyId });
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchEmployeeCountListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchEmployeeCountListIfNeeded();
    },
  }),
);

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import Rating from '@thrivetrm/ui/components/Rating';
import Card from '@thrivetrm/ui/components/Card';
import Date from '@thrivetrm/ui/components/Date';
import Grid from '@thrivetrm/ui/components/Grid';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import Menu from '@thrivetrm/ui/components/Menu';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import isAdmin from 'modules/auth/selectors/isAdmin';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import classnames from 'classnames';
import useQuickView from 'modules/quick-view/useQuickView';
import {
  useDeleteRecruitmentRatingMutation,
  useGetRecruitmentRatingsQuery,
} from 'services/apiV1/recruitmentRatings';
import QuickViewPanelHeader from '../QuickViewPanelHeader';
import RecruitmentRatingDeleteModal from './RecruitmentRatingDeleteModal';
import { QuickViewErrorState } from '../QuickViewState';

const RecruitmentRatingDetails = () => {
  const dispatch = useDispatch();
  const { close, navigateTo, pathParams, title } = useQuickView();
  const { companyId, recruitmentRatingId } = pathParams;
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useToggle(
    false,
  );

  const currentUserId = useSelector(getCurrentUserId);
  const isAdminUser = useSelector(isAdmin);

  const [deleteRecruitmentRating] = useDeleteRecruitmentRatingMutation();
  const {
    data: recruitmentRatingsList,
    error: recruitmentRatingLoadError,
    isFetching: isLoadingRecruitmentRating,
  } = useGetRecruitmentRatingsQuery(companyId);
  const recruitmentRating = recruitmentRatingsList?.find(
    rating => rating.id === recruitmentRatingId,
  );

  const hasMenu =
    isAdminUser ||
    recruitmentRating?.createdById === currentUserId ||
    recruitmentRating?.ratedBy.id === currentUserId;

  const searchId = recruitmentRating?.searchId;

  const deleteRating = () => {
    deleteRecruitmentRating({
      companyId: companyId,
      ratingId: recruitmentRating?.id,
    }).then(() => {
      closeDeleteModal();
      dispatch(toastSuccess('Successfully deleted recruitment rating'));
      const url = `/company/${companyId}/recruitment_ratings`;
      navigateTo(url);
    });
  };

  const editRating = () => {
    const url = `/company/${companyId}/recruitment_ratings/${recruitmentRating.id}/edit`;
    navigateTo(url);
  };

  const menu = (
    <Menu isPinnedRight={true}>
      <Menu.Item onClick={editRating}>Edit</Menu.Item>
      <Menu.Item onClick={openDeleteModal}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <>
      <SidePanel.Header
        className='u-marginBottom-12'
        onBack={() => navigateTo(`/company/${companyId}/recruitment_ratings`)}
        onClose={close}
        title={title}
      />
      <QuickViewPanelHeader title='Recruitment Rating' />
      <SidePanel.Body className='u-paddingTop-16' data-testid='sidepanel-body'>
        <LoadingContainer
          className={classnames({
            'u-marginTop-64': isLoadingRecruitmentRating,
          })}
          isLoading={isLoadingRecruitmentRating}
          size='large'
        >
          {recruitmentRating && !isLoadingRecruitmentRating ? (
            <Card data-testid='recruitment_rating_detail' isCentered={false}>
              <div className='u-flex u-flexJustify-spaceBetween'>
                <Rating value={recruitmentRating.rating} />
                {hasMenu ? menu : null}
              </div>
              <div className='u-marginVertical-8' data-testid='rating-title'>
                <a href={`/searches/${searchId}/candidates`}>
                  {recruitmentRating?.search?.name}
                </a>
              </div>
              <p data-testid='rating-search-status-and-search-end-date'>
                <strong>
                  {recruitmentRating?.search?.status}{' '}
                  {recruitmentRating?.search?.endDate ? (
                    <Date date={recruitmentRating?.search.endDate} />
                  ) : null}
                </strong>
              </p>
              {recruitmentRating?.search?.milestone ? (
                <p className='u-marginTop-2' data-testid='rating-milestone'>
                  <strong>{recruitmentRating?.search?.milestone}</strong>
                </p>
              ) : null}
              {recruitmentRating?.search?.externalPrimaryRecruiter ? (
                <p
                  className='u-marginVertical-8'
                  data-testid='rating-external-recruiter'
                >
                  <strong>External Primary Recruiter: </strong>
                  {recruitmentRating?.search?.externalPrimaryRecruiter}
                </p>
              ) : null}
              <div
                className='u-marginBottom-8 u-textColor-gray60'
                data-testid='rating-notes'
              >
                {recruitmentRating?.notes}
              </div>
              <Grid>
                <Grid.Column className='u-paddingHorizontal-n' size={6}>
                  <p
                    className='u-fontSize-small u-marginBottom-n u-wordBreak'
                    data-testid='rating-rated-by'
                  >
                    <strong>Rated By: </strong>
                    {recruitmentRating?.ratedBy?.name}
                  </p>
                  <p
                    className='u-fontSize-small'
                    data-testid='rating-date-rated'
                  >
                    <strong>Date Rated: </strong>
                    <Date date={recruitmentRating?.createdAt} />
                  </p>
                </Grid.Column>
                <Grid.Column className='u-paddingHorizontal-n' size={6}>
                  <p
                    className='u-fontSize-small u-marginBottom-n u-wordBreak'
                    data-testid='rating-updated-by'
                  >
                    <strong>Updated By: </strong>
                    {recruitmentRating?.updatedBy
                      ? recruitmentRating?.updatedBy
                      : recruitmentRating?.ratedBy?.name}
                  </p>
                  <p
                    className='u-fontSize-small'
                    data-testid='rating-date-updated'
                  >
                    <strong>Date Updated: </strong>
                    <Date
                      date={
                        recruitmentRating?.updatedAt
                          ? recruitmentRating?.updatedAt
                          : recruitmentRating?.createdAt
                      }
                    />
                  </p>
                </Grid.Column>
              </Grid>
            </Card>
          ) : null}
          {recruitmentRatingLoadError && !isLoadingRecruitmentRating ? (
            <QuickViewErrorState image='envelope' />
          ) : null}
        </LoadingContainer>
        {recruitmentRating?.search && !isLoadingRecruitmentRating ? (
          <RecruitmentRatingDeleteModal
            closeDeleteModal={closeDeleteModal}
            deleteRating={deleteRating}
            isDeleteModalOpen={isDeleteModalOpen}
            searchName={recruitmentRating?.search?.name}
          />
        ) : null}
      </SidePanel.Body>
    </>
  );
};

export default RecruitmentRatingDetails;

import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import SearchesPageSidebar from 'modules/search-views/searchPageSidebar/SearchPageSidebar';
import store, { history } from '../store';

const SearchPageSidebar = () => (
  <Provider store={store}>
    <Router history={history}>
      <SearchesPageSidebar />
    </Router>
  </Provider>
);

export default SearchPageSidebar;

import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import routes from 'modules/routing/routes';
import { useApiDelete } from '@thrivetrm/ui/hooks/useApi';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { loadCandidacyAssessments } from 'modules/candidacy-assessments/candidacyAssessmentsSlice';
import getCandidacySearchId from 'modules/candidacies/selectors/getCandidacySearchId';
import invalidateCandidacy from 'modules/candidacies/actions/invalidateCandidacy';

const DeleteCandidacyAssessmentModal = ({
  assessmentId,
  candidacyId,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const searchId = useSelector(state =>
    getCandidacySearchId(state, candidacyId),
  );
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const [deleteAssessment] = useApiDelete(
    routes.api_v1_candidacy_assessment({ id: assessmentId }),
  );

  const handleDelete = () => {
    deleteAssessment(null, {
      onSuccess: () => {
        dispatch(toastSuccess('Successfully deleted assessment'));
        dispatch(loadCandidacyAssessments(searchId));
        dispatch(invalidateCandidacy({ id: candidacyId }));
        onSuccess();
      },
    });
  };
  return (
    <>
      <ButtonLink
        className='u-marginLeft-12'
        data-testid='candidacy-assessment-delete-button'
        label='Delete'
        onClick={openModal}
        size='small'
      />
      <ConfirmationDialog
        cancelLabel='No, Keep Assessment'
        confirmLabel='Yes, Delete Assessment'
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDelete}
        title='Delete Assessment'
      >
        <p>Are you sure you want to delete this assessment?</p>
        <b>This action cannot be undone.</b>
      </ConfirmationDialog>
    </>
  );
};

DeleteCandidacyAssessmentModal.propTypes = {
  assessmentId: PropTypes.number.isRequired,
  candidacyId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default DeleteCandidacyAssessmentModal;

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import talentPoolsSchema from '../schema';

/**
 * Gets the ID of the contact assigned to a talent pool.
 * @param {Object} state
 * @param {Number} id The talent pool ID
 * @return {Number} The ID of the contact assigned to the talent pool.
 */
export default (state, id) =>
  getEntityProperty(state, talentPoolsSchema.key, id, 'current_contact');

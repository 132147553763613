/* eslint-disable camelcase */
// ^ accommodate API exchange format
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from 'modules/core/urlUtils';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import Icon from '@thrivetrm/ui/components/Icon';
import PageHeader from 'modules/core/components/PageHeader';
import Pagination from '@thrivetrm/ui/components/Pagination';
import SegmentedControl from '@thrivetrm/ui/components/SegmentedControl';
import BulkActionTable from 'modules/core/components/BulkActionTable';
import Table from 'modules/core/components/Table';
import ClipboardTrigger from 'modules/core/componentsLegacy/ClipboardTrigger';
import {
  requestSearchRequestList,
  saveResultsPerPage,
  setCurrentPage,
  setFilters,
  setSelectedRecords,
  setSortParams,
} from 'modules/search-requests/searchRequestSlice';
import routes from 'modules/routing/routes';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import isAdmin from 'modules/auth/selectors/isAdmin';
import SearchRequestSidebarFilters from './SearchRequestSidebarFilters';
import SearchRequestIndexBulkActions from './SearchRequestIndexBulkActions';

const RESULTS_PER_PAGE_OPTIONS = ['5', '25', '100'];

// parseQueryString does not automatically coerce integer values.
// Loop over array filter values and replace with an integer if the
// value can be parsed as an integer. Leave it as-is otherwise.
const transformFilterIntegerValues = filters => {
  Object.entries(filters).forEach(([filterKey, filterValue]) => {
    if (Array.isArray(filterValue)) {
      filters[filterKey] = filterValue.map(item => parseInt(item) || item);
    }
  });
};

const SearchRequestIndexPage = () => {
  const { search: queryString } = useLocation();
  const dispatch = useDispatch();
  const {
    currentPage,
    isLoadingSearchRequestList,
    recordList,
    resultsPerPage,
    selectedColumns,
    selectedRecordIds,
    sortDirection,
    sortKey,
    totalPages,
  } = useSelector(state => state.searchRequest);
  const isAdminUser = useSelector(isAdmin);

  useEffect(() => {
    if (queryString) {
      const queryObject = parseQueryString(queryString);

      const {
        filters,
        page,
        results_per_page,
        sort_direction,
        sort_field,
      } = queryObject;

      if (RESULTS_PER_PAGE_OPTIONS.includes(results_per_page)) {
        dispatch(saveResultsPerPage(results_per_page));
      }

      if (filters) {
        transformFilterIntegerValues(filters);
        dispatch(setFilters(filters));
      }

      // eslint-disable-next-line camelcase
      if (sort_field && sort_direction) {
        dispatch(
          setSortParams({ sortDirection: sort_direction, sortKey: sort_field }),
        );
      }
      if (page) {
        dispatch(setCurrentPage(parseInt(page)));
      }
    }
  }, [dispatch, queryString]);

  useEffect(() => {
    dispatch(requestSearchRequestList());
  }, [dispatch]);

  // To be removed after new loading state is established
  if (!recordList) return null;

  const handleSortDirectionChange = (newSortKey, newSortDirection) => {
    dispatch(
      setSortParams({ sortDirection: newSortDirection, sortKey: newSortKey }),
    );
    dispatch(requestSearchRequestList());
  };

  const handlePageChange = page => {
    dispatch(setCurrentPage(page));
    dispatch(requestSearchRequestList());
  };

  const handleResultsPerPageChange = newResultsPerPage => {
    dispatch(saveResultsPerPage(newResultsPerPage));
    dispatch(requestSearchRequestList());
  };

  const handleCopyUrlConfirm = () => {
    dispatch(toastSuccess('Search Request URL Copied to Clipboard'));
  };

  const onSelect = newSelections => {
    dispatch(setSelectedRecords(newSelections));
  };

  return (
    <div className='RecordIndexPage'>
      <PageHeader title='Search Requests'>
        <div className='SearchRequestIndexPage__titleTableSide'>
          <ClipboardTrigger
            text={`${
              window.location.origin
            }${routes.request_details_search_request_form_index()}`}
          >
            <ButtonPrimary
              className='RecordIndexHeader__action u-noWrap'
              icon='copy'
              isDarkMode={true}
              isOutline={true}
              label='Copy Search Request URL'
              onClick={handleCopyUrlConfirm}
              size='medium'
            />
          </ClipboardTrigger>
          <ButtonPrimary
            className='RecordIndexHeader__action u-noWrap u-marginLeft-16'
            icon='add'
            label='Create Search Request'
            size='medium'
            url='search_request_form/request_details'
          />
        </div>
      </PageHeader>
      <div className='RecordIndexPage__content'>
        <div className='RecordIndexPage__sidebar'>
          <SearchRequestSidebarFilters />
        </div>
        {recordList.length ? (
          <div className='RecordIndexTable'>
            {isAdminUser ? (
              <BulkActionTable
                headers={selectedColumns}
                isLoading={isLoadingSearchRequestList}
                onSelect={onSelect}
                onSortChange={handleSortDirectionChange}
                rows={recordList}
                selections={selectedRecordIds}
                sortDirection={sortDirection}
                sortKey={sortKey}
              />
            ) : (
              <Table
                headers={selectedColumns}
                isLoading={isLoadingSearchRequestList}
                onSortChange={handleSortDirectionChange}
                rows={recordList}
                sortDirection={sortDirection}
                sortKey={sortKey}
              />
            )}
            <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c u-marginVertical-24'>
              <Pagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                totalPages={totalPages}
              />
              <SegmentedControl
                onChange={handleResultsPerPageChange}
                segments={RESULTS_PER_PAGE_OPTIONS}
                selectedSegmentLabel={resultsPerPage}
                size='small'
              />
            </div>
            {selectedRecordIds?.length ? (
              <SearchRequestIndexBulkActions />
            ) : null}
          </div>
        ) : (
          <Card
            className='SearchRequestIndexPage__noDataCard u-marginVertical-64'
            type='gray'
          >
            <div>
              <Icon color='blue' size='large' type='add' />
              <h2>Create Search Request</h2>
              <p>
                Create your first Search Request by clicking the button below.
              </p>
              <ButtonPrimary
                className='RecordIndexHeader__action u-noWrap'
                label='Create Search Request'
                size='medium'
                url='search_request_form/request_details'
              />
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default SearchRequestIndexPage;

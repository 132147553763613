import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import SectorsTagSelectField from 'modules/tenant/components/SectorsTagSelectField';
import connectCompanyActions from '../connectCompanyActions';
import mapCompanyIdToProperty from '../mapCompanyIdToProperty';

/**
 * Renders a form for tagging a company with sectors.
 * This form auto-submits whenever the underlying fieldState is changed, so there is no
 * "submit" button here, and it doesn't have/need a formState as is typical of a "standard"
 * form.
 */
export default compose(
  setDisplayName('CompanySectorsForm'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToProperty('sectors'),
  connectCompanyActions,
  withStateHandlers(
    ({ sectors }) => ({
      fieldState: SectorsTagSelectField.createFieldState('sectors', sectors),
    }),
    {
      onChange: (_, { companyActions, companyId }) => fieldState => {
        // Whenever the fieldState is changed, we update the company with the new IDs immediately.
        companyActions.updateCompany({
          company: {
            id: companyId,
            sector_ids: fieldState.getValue(),
          },
        });

        return { fieldState: fieldState };
      },
    },
  ),
)(SectorsTagSelectField);

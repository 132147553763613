import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import jobFunctionSchema from '../schemas/jobFunctions';

/**
 * Gets the name of a job function from it's ID.
 * @param {*} state
 * @param {Number} id The job function ID
 * @return {String} The job function name.
 */
export default (state, id) =>
  getEntityProperty(state, jobFunctionSchema.key, id, 'name');

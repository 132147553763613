import React from 'react';
import { useSelector } from 'react-redux';
import ErrorBoundary from 'modules/core/components/ErrorBoundary';
import Integer from '@thrivetrm/ui/components/Integer';
import PluralText from '@thrivetrm/ui/components/PluralText';
import FilterSetAsString from './FilterSetAsString';

const FilterSummary = () => {
  const { availableFilterInputs, availableFilterSets } = useSelector(
    state => state.recordIndex,
  );
  const { filters } = useSelector(state => state.recordIndex?.savedView);

  const { totalResults } = useSelector(state => state.recordIndex);

  const hasCriticalFilterError = useSelector(
    state => state.recordIndex.filterSetErrors?.Gender?.length > 0,
  );

  const hasV5Enabled = Boolean(document.querySelector('[data-filter-sets]'));

  const MAX_RESULTS_TO_DISPLAY = 9999;

  // This component requires filter sets to be provided from the backend to render
  if (!hasV5Enabled) return null;

  if (!availableFilterSets || !availableFilterInputs || !filters) return null;

  return (
    <div className='u-flex u-flexAlign-c u-backgroundColor-gray10 '>
      <div className='u-marginRight-12 u-noWrap u-textColor-gray60 u-fontSize-large'>
        {hasCriticalFilterError ||
        totalResults > MAX_RESULTS_TO_DISPLAY ? null : (
          <>
            <Integer value={totalResults} />{' '}
            <PluralText quantity={totalResults} text='Contact' />
          </>
        )}
      </div>
      <div>
        <FilterSetAsString filterSetId='Keywords' />
        <FilterSetAsString filterSetId='Name' />
        <FilterSetAsString filterSetId='Job Title' />
        <FilterSetAsString filterSetId='Company' />
        {filters?.selectedFilterSetIds?.map(filterSetId => (
          <ErrorBoundary key={filterSetId}>
            <FilterSetAsString filterSetId={filterSetId} />
          </ErrorBoundary>
        ))}
      </div>
    </div>
  );
};

export default FilterSummary;

import PropTypes from 'prop-types';
import selectn from 'selectn';
import {
  branch,
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import withFormState from 'modules/forms/components/withFormState';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withTransactionTracking from 'modules/transactions/components/withTransactionTracking';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import createAddressActionCreator from '../actions/createAddress';
import updateAddressActionCreator from '../actions/updateAddress';
import withAddressFetched from './withAddressFetched';

/**
 * Provides props for managing a form for creating or editing an address.
 *
 * Creates the following props for the child component:
 * * `formState` {FormState} a FormState object containing an underlying FieldState created from
 *    the `RootField` parameter (by calling RootField.createFieldState).
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default RootField =>
  compose(
    setDisplayName('withAddressForm'),
    setPropTypes({
      /**
       * If editing an existing address, it's ID.
       */
      addressId: PropTypes.number,

      /**
       * Called when the form has been successfully saved.
       */
      onSaved: PropTypes.func,

      /**
       * An optional action to call when the form is submitted.
       * If this function is supplied it is called instead of the
       * default action (`createAddress` or `updateAddress`)
       */
      onSubmitAction: PropTypes.func,

      /**
       * ID of the parent resource
       */
      parentId: PropTypes.number,

      /**
       * type of the parent resource (Company, Contact)
       */
      parentType: PropTypes.string.isRequired,
    }),

    // Store incoming props to prevent cloberring and restore then when we're done.
    withProps(
      ({
        onSaved: _onSaved,
        onSubmitAction: _onSubmitAction,
        ...withAddressFormProps
      }) => ({
        withAddressFormProps: withAddressFormProps,
      }),
    ),

    // If editing an existing address, make sure it is fetched.
    branch(
      ({ addressId }) => typeof addressId === 'number',
      withAddressFetched(),
    ),

    /**
     * Create a FormState, initializing it with the value from a `address` prop (which is supplied
     * by the above `branch` if a `addressId` was set), if available.
     * If addressId is undefined, initialize the FormState with the
     * addressable_id and addressable_type so that we can assign the newly created
     * address to its addressable (parent)
     * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
     */
    withFormState(({ address, addressId, primaryAddressId }) =>
      RootField.createFieldState(
        'address',
        addressId
          ? {
              address: address,
              addressId: addressId,
              primaryAddressId: primaryAddressId,
            }
          : { address: address },
      ),
    ),

    /**
     * Watch for any save transaction to complete
     */
    withHandlers({
      /**
       * This gets called by `withTransaction`, below, any time our transaction started
       * with `startTransaction` is called.
       */
      onTransactionFinished: ({
        formState,
        onFormStateChange,
        onResetFormState,
        onSaved,
      }) => (_, transaction) => {
        const error = selectn('payload.error', transaction);
        onFormStateChange(formState.endSubmit(error));

        if (!error) {
          onResetFormState();

          // If all went well and `onSaved` was provided, call it with the new record's ID
          if (onSaved) {
            onSaved(selectn('payload.result.address', transaction));
          }
        }
      },
    }),

    withTransactionTracking(),
    /**
     * Include connected versions of `createAddress` and `updateAddress` that
     * are needed to submit.
     */
    connect(null, {
      createAddress: createAddressActionCreator,
      updateAddress: updateAddressActionCreator,
    }),

    /**
     * Add a callback to handle submitting the action form.
     */
    withHandlers({
      // Called when the form should be submitted.
      onSubmit: ({
        addressId,
        createAddress,
        formState,
        onFormStateChange,
        onSubmitAction,
        parentId,
        parentType,
        trackTransaction,
        updateAddress,
      }) => e => {
        const transactionId = uniqueId();

        trackTransaction(transactionId);
        const address = formState.getFieldValue();
        const action =
          onSubmitAction || (addressId ? updateAddress : createAddress);
        action({
          address: address,
          addressId: addressId,
          parentId: parentId,
          parentType: parentType,
          transactionId: transactionId,
        });

        onFormStateChange(formState.startSubmit(transactionId));

        // Prevent default browser behavior, which could cause the browser to attempt
        // to submit a form on it's own.
        return preventDefaultHandler(e);
      },
    }),

    // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
    mapProps(
      ({
        formState,
        onCancel,
        onFieldStateChange,
        onFormStateChange,
        onSubmit,
        onSubmitAction: _onSubmitAction,
        withAddressFormProps,
      }) => ({
        ...withAddressFormProps,
        formState: formState,
        onFieldStateChange: onFieldStateChange,
        onFormStateChange: onFormStateChange,
        onSubmit: onSubmit,
        onCancel: onCancel,
      }),
    ),
  );

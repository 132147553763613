import React from 'react';
import PropTypes from 'prop-types';
import InputField from 'modules/forms/components/InputField';

/**
 * An input field for editing a province.
 * This just adds a class and default label.
 */
const ProvinceField = ({ shouldRequireFields, ...props }) => (
  <div className='ProvinceField'>
    <InputField
      label={`${shouldRequireFields ? '*' : ''}Province`}
      {...props}
    />
  </div>
);

ProvinceField.propTypes = {
  shouldRequireFields: PropTypes.bool,
};

export default ProvinceField;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApiGet, useApiPost, useApiPatch } from '@thrivetrm/ui/hooks/useApi';
import { useTemporaryBrowserData } from '@thrivetrm/ui/components/TemporaryBrowserData';
import { useLocation } from 'react-router-dom';
import Form from '@thrivetrm/ui/components/Form';
import routes from 'modules/routing/routes';
import CandidacyInfoCard from 'modules/candidacy-assessments/CandidacyInfoCard';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import Card from '@thrivetrm/ui/components/Card';
import { isValidEmail } from 'modules/core/validators';
import TextInput from '@thrivetrm/ui/components/TextInput';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import CandidateAssessmentFormContent, {
  ViewTypes,
} from './CandidateAssessmentFormContent';
import EditCandidateAssessmentFormHeader from './EditCandidateAssessmentFormHeader';
import ViewCandidateAssessmentHeader from './ViewCandidateAssessmentHeader';
import GuestAssessmentConsent from './GuestAssessmentConsent';

const getAverageCategoryRating = (categoryData, formState) => {
  const criteriaIds = categoryData.criteria.map(
    criteriaDetails => criteriaDetails.id,
  );
  const ratings = criteriaIds
    .map(criteriaId => formState[`${categoryData.id}_${criteriaId}_rating`])
    .filter(rating => rating);

  return (ratings.length
    ? ratings.reduce((partialSum, rating) => partialSum + rating, 0) /
      ratings.length
    : 0
  ).toFixed(2);
};

/**
 * Other ideas
 *  - Convert data into similar structure to simplify comparison operation
 *  - Comparison function for each key?
 */

export const convertFrontendAssessmentToBackendDataObject = (
  formState = {},
  criteria = [],
  categories = [],
) => ({
  assessed_on: formState?.assessed_on,
  notes: formState?.summary_notes,
  raw_notes: formState?.raw_notes,
  candidacy_assessment_answers_attributes: [
    ...criteria.map(criterion => ({
      assessment_category_id: null,
      assessment_criterium_id: criterion.id,
      criteria_average: null,
      notes: formState[`${criterion.id}_notes`],
      rating_value: formState[`${criterion.id}_rating`],
    })),
    ...categories
      .map(category => {
        const avgRating = getAverageCategoryRating(category, formState);
        return category.criteria.map(criterion => ({
          assessment_category_id: category.id,
          assessment_criterium_id: criterion.id,
          criteria_average: avgRating,
          notes: formState[`${category.id}_${criterion.id}_notes`],
          rating_value: formState[`${category.id}_${criterion.id}_rating`],
        }));
      })
      .flat(),
  ],
});

export const isDraftAssessmentDataSameAsSaved = (
  draftData = {},
  assessmentData = {},
  criteria = [],
  categories = [],
) => {
  /**
   * Currently there is an issue where a local storage item will be created even when the user
   * has not interacted with the assessment form.  This check will determine if every value in
   * local storage is the same as every matching field on the saved assessment.  When they are
   * all equal, we don't want to show the draft status message.  In the event a new assessment
   * is being created, we want to check & see if more than just assessed_on & assessed_by have
   * been populated because these two fields are always given a default value with the exception
   * of guest assessment where only assessed_on is populated
   */
  const {
    assessed_by: draftAssessedBy,
    assessed_by_name: draftAssessedByName,
    assessed_on: draftAssessedOn,
  } = draftData;

  /**
   * This isn't the most robust solution, but we can infer the user is editing an existing assessment
   * when the following fields are present on the assessmentData object:
   *   - candidacy_assessment_answers_attributes
   *   - id
   *   - formatted_raw_notes
   *
   * Each of these fields will be undefined when creating a new assessment.  And the id field will be
   * present when the user is viewing the output of this comparison on the search sidebar under the
   * assessments panel
   */
  const isEditingExistingAssessment =
    Boolean(assessmentData?.id) &&
    Boolean(assessmentData?.candidacy_assessment_answers_attributes) &&
    Boolean(assessmentData?.formatted_raw_notes);

  /**
 * Under the following circumstances, we consider the draft data to be functionally equivalent
 * to the saved data (or unsaved in the case of a new assessment)
 *   - draft & saved values are equal (when draft !== undefined)
 *   - draft data is undefined AND saved data is null or has a value
 *   - draft data & saved data are both undefined

 * When an assessment is being edited, we also need to return true in the following case
 *   - draft data is defined AND saved data is not
 */
  const compareDraftToSaved = (draft, saved) => {
    if (isEditingExistingAssessment) {
      return (
        (typeof draft !== 'undefined' && draft === saved) ||
        (typeof draft === 'undefined' && (Boolean(saved) || saved === null)) ||
        (typeof draft === 'undefined' && typeof saved === 'undefined') ||
        (typeof draft !== 'undefined' && typeof saved === 'undefined')
      );
    }
    return (
      (typeof draft !== 'undefined' && draft === saved) ||
      (typeof draft === 'undefined' && (Boolean(saved) || saved === null)) ||
      (typeof draft === 'undefined' && typeof saved === 'undefined') ||
      ([null, false, '', 0].includes(draft) && typeof saved === 'undefined')
    );
  };

  const assessedOnAreTheSame = compareDraftToSaved(
    draftAssessedOn,
    assessmentData?.assessed_on,
  );

  /**
   * Guest assessments only populate the assessed_by_name field while all other types
   * will also contain an assessed_by field that aligns with another user.  Check if values
   * match in either case
   */
  const assessedByIdAreTheSame = compareDraftToSaved(
    draftAssessedBy?.id,
    assessmentData?.assessed_by?.id,
  );
  const assessedByNameAreTheSame = compareDraftToSaved(
    draftAssessedByName,
    assessmentData?.assessed_by_name,
  );
  const assessedByIdAndNameAreTheSame =
    assessedByIdAreTheSame && assessedByNameAreTheSame;

  const { assessed_on: _assessedOn, ...fieldsStoredInDraft } =
    convertFrontendAssessmentToBackendDataObject(
      draftData,
      criteria,
      categories,
    ) ?? {};

  const draftAndStoredDataAreSame = Object.keys(fieldsStoredInDraft).every(
    fieldName => {
      const draftFieldValue = fieldsStoredInDraft[fieldName];

      if (Array.isArray(draftFieldValue)) {
        // Category/Criteria answers are stored as an array of objects, we need to make sure each is the same
        return (
          !draftFieldValue.length ||
          draftFieldValue.every((fieldValue, index) => {
            const {
              assessment_category_id: _categoryId,
              assessment_criterium_id: _criteriaId,
              id: _id,
              ...remainingKeys
            } = fieldValue;
            return Object.keys(remainingKeys).every(fieldValueKey => {
              const draftValue = fieldValue[fieldValueKey];
              const assessmentValue =
                assessmentData?.[fieldName]?.[index]?.[fieldValueKey];
              return compareDraftToSaved(draftValue, assessmentValue);
            });
          })
        );
      }
      return compareDraftToSaved(draftFieldValue, assessmentData?.[fieldName]);
    },
  );
  return (
    assessedByIdAndNameAreTheSame &&
    assessedOnAreTheSame &&
    draftAndStoredDataAreSame
  );
};

const AssessmentTypes = {
  RECRUITER: 'RecruiterCandidacyAssessment',
  CLIENT: 'ClientCandidacyAssessment',
  RESEARCHER: 'ResearcherCandidacyAssessment',
};

const CandidateAssessment = ({
  assessmentId,
  assessmentType,
  candidacyId,
  isTalentPool,
  searchId,
}) => {
  /**
   * when a guest token exists, the candidate assessment handles things a little differently:
   *  - Do not show a back button in the header
   *  - Replace Assessed By Autocomplete with TextInput
   *  - Links to ThriveApp are disabled / replaced w/ plain text
   *
   * See THRIVE-9840 for additional information
   *
   * Also see the react-router-dom & Mozilla docs for how to pull out query params
   * https://v5.reactrouter.com/web/example/query-parameters
   * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
   */
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  // isConsentChecked true means user has checked consent checkbox
  const [
    isConsentChecked,
    _checkConsent,
    _unCheckConsent,
    changeConsent,
  ] = useToggle(false);

  // isConsentUpdated true means user has clicked continue
  const [isConsentUpdated, _update, _remove, updateConsent] = useToggle(false);

  const tenant = useSelector(state => state.tenant);
  const hasAssessmentTemplates = tenant.get('has_assessment_templates');

  const fromActiveAdmin = new URLSearchParams(search).get('from_active_admin');
  const isFromActiveAdmin = Boolean(fromActiveAdmin);
  const guestToken = new URLSearchParams(search).get('guest_token');
  const isGuestAssessment = Boolean(guestToken);
  const isReadOnly = pathname.match(/.*\d+$/);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, deleteData } = useTemporaryBrowserData();

  const shouldShowConsentPage = hasAssessmentTemplates && isGuestAssessment;

  const [loadSearch, _loadingSearch, _searchError, searchData] = useApiGet(
    routes.api_v1_search_assessment_templates({
      searchId: searchId,
      query: { guest_token: guestToken },
    }),
  );

  const [
    loadTemplate,
    _loadingTemplate,
    _templateError,
    templateData,
  ] = useApiGet(
    routes.api_v1_assessment_template({
      id: searchData?.search.assessment_template_id,
      query: { guest_token: guestToken },
    }),
  );

  useEffect(() => {
    loadSearch();
  }, [loadSearch]);

  useEffect(() => {
    if (searchData?.search.assessment_template_id) {
      loadTemplate();
    }
  }, [loadTemplate, searchData]);

  const { categories, criteria } = templateData?.assessment_template || {};

  const [viewType, setViewType] = useState(() => ViewTypes.ASSESSMENT_ONLY);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const [guestEmail, setGuestEmail] = useState();
  const [guestEmailError, setGuestEmailError] = useState();

  const [submitAssessment, _loading, _error, submittedAssessment] = useApiPost(
    routes.api_v1_candidacy_assessments(),
  );
  const [
    submitGuestAssessment,
    _isSubmittingGuestAssessment,
    _submitAssessmentError,
    guestAssessment,
  ] = useApiPost(routes.api_v1_guest_candidacy_assessments());
  const [
    updateAssessment,
    _updating,
    _updateError,
    updatedAssessment,
  ] = useApiPatch(routes.api_v1_candidacy_assessment({ id: assessmentId }));

  const [
    loadAssessment,
    isLoadingAssessment,
    _assessmentError,
    assessmentData,
  ] = useApiGet(routes.api_v1_candidacy_assessment({ id: assessmentId }));

  const assessmentDetails =
    submittedAssessment || updatedAssessment || assessmentData;

  const [
    sendGuestAssessmentCopy,
    isSendingGuestAssessment,
    _sendAssessmentError,
    guestAssessmentCopy,
  ] = useApiPatch(
    routes.api_v1_guest_candidacy_assessment({
      id: guestAssessment?.assessment?.id,
    }),
  );

  useEffect(() => {
    if (assessmentId) {
      loadAssessment();
    }
  }, [assessmentId, loadAssessment]);

  useEffect(() => {
    if (isReadOnly) {
      setHasBeenSubmitted(true);
    }
  }, [isReadOnly]);

  const isBrowserDataSameAsSaved = isDraftAssessmentDataSameAsSaved(
    data?.content,
    assessmentData?.assessment,
    criteria,
    categories,
  );

  const handleGuestEmailSubmit = () => {
    sendGuestAssessmentCopy({ guest_assessor_email: guestEmail });
  };

  const handleSubmit = formState => {
    if (isReadOnly) {
      return;
    }
    /**
     * We only ever set this to true and DO NOT revert it to false to prevent users from submitting
     * multiple copies of the assessment if they click on the submit button multiple times
     * before they are redirected to a read-only version of this page OR the search page they
     * originally came from.
     *
     * This is different than hasBeenSubmitted which is used to hide the submit button on the read-only
     * version of the assessment page
     */
    setIsSubmitting(true);
    const sharedAssessmentProperties = {
      ...convertFrontendAssessmentToBackendDataObject(
        formState,
        criteria,
        categories,
      ),
      candidacy_id: candidacyId,
      assessment_template_id: searchData?.search.assessment_template_id,
    };

    const returnToSearchPage = () => {
      window.location.href = isFromActiveAdmin
        ? '/admin/candidacy_assessments'
        : `/${
            isTalentPool ? 'talent_pools' : 'searches'
          }/${searchId}/candidates/${candidacyId}/candidacy_assessments`;
    };

    if (isGuestAssessment) {
      const payload = {
        token: guestToken,
        candidacy_assessment: {
          assessed_by_name: formState.assessed_by_name,
          type: AssessmentTypes.CLIENT,
          ...sharedAssessmentProperties,
        },
      };
      submitGuestAssessment(payload, {
        onSuccess: () => {
          setHasBeenSubmitted(true);
          dispatch(toastSuccess('Successfully added assessment'));
          deleteData?.();
        },
      });
    } else {
      // __isNew__ will be true when user creates new user name via create option
      // eslint-disable-next-line no-underscore-dangle
      const isNewUser = formState.assessed_by?.__isNew__;
      const payload = {
        candidacy_assessment: {
          assessed_by_name: isNewUser
            ? formState.assessed_by.label
            : formState.assessed_by_name,
          assessed_by_id: isNewUser ? null : formState.assessed_by?.value,
          type: assessmentId ? assessmentData?.assessment.type : assessmentType,
          ...sharedAssessmentProperties,
        },
      };

      if (assessmentId) {
        updateAssessment(payload, {
          onSuccess: () => {
            deleteData?.();
            returnToSearchPage();
          },
        });
      } else {
        submitAssessment(payload, {
          onSuccess: () => {
            deleteData?.();
            returnToSearchPage();
          },
        });
      }
    }
  };

  return (
    <Form className='CandidateAssessment' onSubmit={handleSubmit}>
      {isReadOnly ? (
        <ViewCandidateAssessmentHeader
          assessment={assessmentData?.assessment}
          candidacyId={candidacyId}
          isFromActiveAdmin={isFromActiveAdmin}
          isTalentPool={isTalentPool}
          onViewTypeChange={setViewType}
          search={searchData?.search}
          viewType={viewType}
        />
      ) : (
        <EditCandidateAssessmentFormHeader
          assessment={assessmentData?.assessment}
          assessmentId={assessmentId}
          candidacyId={candidacyId}
          categories={categories}
          criteria={criteria}
          hasBeenSubmitted={hasBeenSubmitted}
          isBrowserDataSameAsSaved={isBrowserDataSameAsSaved}
          isConsentChecked={isConsentChecked}
          isFromActiveAdmin={isFromActiveAdmin}
          isGuestAssessment={isGuestAssessment}
          isSubmitting={isSubmitting}
          isTalentPool={isTalentPool}
          onSubmit={handleSubmit}
          onViewTypeChange={setViewType}
          search={searchData?.search}
          shouldShowConsentPage={shouldShowConsentPage && !isConsentUpdated}
          updateConsent={updateConsent}
          viewType={viewType}
        />
      )}
      <div className='container'>
        {shouldShowConsentPage && !isConsentUpdated ? (
          <GuestAssessmentConsent
            candidacyId={candidacyId}
            changeConsent={changeConsent}
            guestToken={guestToken}
            isConsentChecked={isConsentChecked}
          />
        ) : (
          <>
            <div className='u-padding-16'>
              <CandidacyInfoCard
                candidacyId={parseInt(candidacyId)}
                guestToken={guestToken}
                isGuestAssessment={isGuestAssessment}
              />
            </div>
            {isGuestAssessment && hasBeenSubmitted ? (
              <Card
                className='u-marginHorizontal-16 u-marginBottom-16'
                isCentered={false}
              >
                {guestAssessmentCopy ? (
                  <h4 className='u-margin-n'>
                    A copy of this Candidate Assessment has been sent to your
                    email
                  </h4>
                ) : (
                  <div className='u-flex'>
                    <TextInput
                      errorMessage={guestEmailError}
                      inputWidth='full'
                      label='Enter your email to have a copy of this Candidate Assessment sent to you'
                      onChange={email => {
                        setGuestEmail(email);
                        setGuestEmailError(
                          isValidEmail(email)
                            ? null
                            : 'Enter valid email address',
                        );
                      }}
                      placeholder='example@email.com'
                      value={guestEmail}
                    />
                    <div className='u-marginTop-16 u-paddingTop-4 u-marginLeft-16'>
                      <ButtonPrimary
                        isDisabled={Boolean(
                          guestEmailError ||
                            !guestEmail ||
                            isSendingGuestAssessment,
                        )}
                        label='Submit'
                        onClick={handleGuestEmailSubmit}
                      />
                    </div>
                  </div>
                )}
              </Card>
            ) : null}
            <CandidateAssessmentFormContent
              assessment={
                isGuestAssessment && hasBeenSubmitted
                  ? guestAssessment?.assessment
                  : assessmentDetails?.assessment
              }
              categories={categories}
              criteria={criteria}
              hasBeenSubmitted={hasBeenSubmitted}
              isGuestAssessment={isGuestAssessment}
              isLoadingAssessment={isLoadingAssessment}
              viewType={viewType}
            />
          </>
        )}
      </div>
    </Form>
  );
};

CandidateAssessment.defaultProps = {
  isTalentPool: false,
};

CandidateAssessment.propTypes = {
  assessmentId: PropTypes.number,
  assessmentType: PropTypes.oneOf(Object.values(AssessmentTypes)),
  candidacyId: PropTypes.number.isRequired,
  isTalentPool: PropTypes.bool,
  searchId: PropTypes.number,
};

export { AssessmentTypes };

export default CandidateAssessment;

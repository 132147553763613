import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import CurrencyInputField from 'modules/currency/components/CurrencyInputField';
import CurrencyTypeSelectField from 'modules/currency/components/CurrencyTypeSelectField';
import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';
import PercentageInputField from 'modules/forms/components/PercentageInputField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import * as validators from 'modules/forms/validators';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import { DEFAULT_CURRENCY } from 'modules/user/constants';

/* eslint-disable jsx-a11y/label-has-associated-control */

/**
 * A field for editing contact "Desired Compensation" related values:
 * Preferred currency
 * Preferred salary range
 * Preferred bonus range
 * Preferred equity range
 * Preferred total compensation
 * Desired Compensation Notes
 */
const ContactDesiredCompensationField = ({
  fieldState,
  handleNestedFieldChange,
}) => (
  <div className='ContactDesiredCompensationField'>
    <div className='row'>
      <div className='col-sm-3 ContactDesiredCompensationField__currency'>
        <CurrencyTypeSelectField
          clearable={true}
          fieldState={fieldState.getNestedField('preferred_currency')}
          label='Currency'
          name='preferred_currency'
          onChange={handleNestedFieldChange}
        />
      </div>
    </div>
    <div className='ContactDesiredCompensationField__base'>
      <div className='row'>
        <div className='col-sm-12'>
          <label className='control-label'>Base</label>
        </div>
      </div>
      <div className='row'>
        <div className='ContactDesiredCompensationField__base_minimum col-sm-4'>
          <CurrencyInputField
            currency={fieldState
              .getNestedField('preferred_currency')
              .getValue()}
            fieldState={fieldState.getNestedField('preferred_base_minimum')}
            key='preferred_base_minimum'
            onChange={handleNestedFieldChange}
          />
        </div>
        <div className='ContactDesiredCompensationField__to col-sm-1'>
          <span>to</span>
        </div>
        <div className='ContactDesiredCompensationField__base_maximum col-sm-4'>
          <CurrencyInputField
            currency={fieldState
              .getNestedField('preferred_currency')
              .getValue()}
            fieldState={fieldState.getNestedField('preferred_base_maximum')}
            key='preferred_base_maximum'
            onChange={handleNestedFieldChange}
          />
        </div>
      </div>
    </div>
    <div className='ContactDesiredCompensationField__bonus'>
      <div className='row'>
        <div className='col-sm-12'>
          <label className='control-label'>Bonus</label>
        </div>
      </div>
      <div className='row'>
        <div className='ContactDesiredCompensationField__bonus_minimum col-sm-4'>
          <CurrencyInputField
            currency={fieldState
              .getNestedField('preferred_currency')
              .getValue()}
            fieldState={fieldState.getNestedField('preferred_bonus_minimum')}
            key='preferred_bonus_minimum'
            onChange={handleNestedFieldChange}
          />
        </div>
        <div className='ContactDesiredCompensationField__to col-sm-1'>
          <span>to</span>
        </div>
        <div className='ContactDesiredCompensationField__bonus_maximum col-sm-4'>
          <CurrencyInputField
            currency={fieldState
              .getNestedField('preferred_currency')
              .getValue()}
            fieldState={fieldState.getNestedField('preferred_bonus_maximum')}
            key='preferred_bonus_maximum'
            onChange={handleNestedFieldChange}
          />
        </div>
      </div>
    </div>
    <div className='ContactDesiredCompensationField__equity'>
      <div className='row'>
        <div className='col-sm-12'>
          <label className='control-label'>Equity</label>
        </div>
      </div>
      <div className='row'>
        <div className='ContactDesiredCompensationField__equity_minimum col-sm-4'>
          <PercentageInputField
            fieldState={fieldState.getNestedField('preferred_equity_minimum')}
            key='preferred_equity_minimum'
            onChange={handleNestedFieldChange}
          />
        </div>
        <div className='ContactDesiredCompensationField__to col-sm-1'>
          <span>to</span>
        </div>
        <div className='ContactDesiredCompensationField__equity_maximum col-sm-4'>
          <PercentageInputField
            fieldState={fieldState.getNestedField('preferred_equity_maximum')}
            key='preferred_equity_maximum'
            onChange={handleNestedFieldChange}
          />
        </div>
      </div>
    </div>

    <div className='row'>
      <div className='col-sm-6 ContactDesiredCompensationField__total_compensation'>
        <CurrencyInputField
          currency={fieldState.getNestedField('preferred_currency').getValue()}
          fieldState={fieldState.getNestedField('preferred_total_compensation')}
          key='preferred_total_compensation'
          label='Total Compensation'
          onChange={handleNestedFieldChange}
        />
      </div>

      <div className='col-sm-12 ContactDesiredCompensationField__notes'>
        <TextAreaField
          fieldState={fieldState.getNestedField('desired_compensation_notes')}
          key='notes'
          label='Notes'
          onChange={handleNestedFieldChange}
        />
      </div>
    </div>
  </div>
);

ContactDesiredCompensationField.propTypes = {
  /**
   * The field state containing the nested value.
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * Called when a nested field is changed.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('ContactDesiredCompensationField(enhanced)'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic(
    'createFieldState',
    (name = 'desired-compensation', contact, ...rest) => {
      const values = new Map({
        desired_compensation_notes: null,
        preferred_bonus_maximum: null,
        preferred_bonus_minimum: null,
        preferred_currency: null,
        preferred_equity_minimum: null,
        preferred_equity_maximum: null,
        preferred_base_minimum: null,
        preferred_base_maximum: null,
        preferred_total_compensation: null,
      }).merge(contact);

      return FieldState.createNested(
        name,
        [
          CurrencyInputField.createFieldState(
            'preferred_total_compensation',
            values.get('preferred_total_compensation'),
            { minValue: 0 },
          ),
          CurrencyTypeSelectField.createFieldState(
            'preferred_currency',
            values.get('preferred_currency') || DEFAULT_CURRENCY,
            validators.requiredField('Currency'),
          ),
          CurrencyInputField.createFieldState(
            'preferred_base_minimum',
            values.get('preferred_base_minimum'),
            { minValue: 0 },
          ),
          CurrencyInputField.createFieldState(
            'preferred_base_maximum',
            values.get('preferred_base_maximum'),
            { minValue: 0 },
          ),
          CurrencyInputField.createFieldState(
            'preferred_bonus_maximum',
            values.get('preferred_bonus_maximum'),
            { minValue: 0 },
          ),
          CurrencyInputField.createFieldState(
            'preferred_bonus_minimum',
            values.get('preferred_bonus_minimum'),
            { minValue: 0 },
          ),
          PercentageInputField.createFieldState(
            'preferred_equity_maximum',
            values.get('preferred_equity_maximum'),
            { minValue: 0, maxValue: 100 },
          ),
          PercentageInputField.createFieldState(
            'preferred_equity_minimum',
            values.get('preferred_equity_minimum'),
            { minValue: 0, maxValue: 100 },
          ),
          TextAreaField.createFieldState(
            'desired_compensation_notes',
            values.get('desired_compensation_notes'),
          ),
        ],
        ...rest,
      );
    },
  ),
  withNestedFieldChangeHandler,
)(ContactDesiredCompensationField);

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

/**
 * A component which displays a loading indicator while notifications are
 * loading.
 */
const NotificationsLoader = ({ isFetching }) => (
  <div className='notifications-loader well text-center'>
    <div className='.label'>
      {isFetching ? 'Loading' : 'Scroll down to load more'}
    </div>
    <i
      className={classnames('fa', 'fa-4x', {
        'fa-arrow-circle-down': !isFetching,
        'fa-spinner': isFetching,
        'fa-pulse': isFetching,
      })}
    />
  </div>
);

NotificationsLoader.propTypes = {
  isFetching: PropTypes.bool,
};

export default NotificationsLoader;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { compose, setDisplayName, setStatic, withHandlers } from 'recompose';

/**
 * The height of this item when rendered in the list.
 * IMPORTANT: This must match the `height` style defined `.CandidacyListItemStageGroupHeader`
 * in `CandidacyListItemStageGroupHeader.scss`!
 * @const {Number}
 */
export const HEIGHT = 50;

/**
 * A candidacy list item component that renders the group header for a list of candidacy items
 * grouped by stage.
 */
const CandidacyListItemStageGroupHeader = ({
  expanded,
  handleToggleExpanded,
  itemCount,
  stage,
}) => (
  <a
    className={classnames('CandidacyListItemStageGroupHeader', {
      'CandidacyListItemStageGroupHeader--collapsed': !expanded,
      'CandidacyListItemStageGroupHeader--expanded': expanded,
    })}
    href={`#${stage ? stage.get('name') : 'unassigned'}`}
    onClick={handleToggleExpanded}
    style={{ height: HEIGHT }}
  >
    <span className='CandidacyListItemStageGroupHeader__Name' key='name'>
      {stage ? stage.get('name') : 'Unassigned'}
    </span>
    <span className='CandidacyListItemStageGroupHeader__ItemCount' key='count'>
      {itemCount}
    </span>
    <span
      className='CandidacyListItemStageGroupHeader__ExpandedIndicator'
      key='indicator'
    >
      <i
        className={classnames('fa', 'fa-fw', 'fa-sm', {
          'icon-expand': !expanded,
          'icon-collapse': expanded,
        })}
      />
    </span>
  </a>
);

CandidacyListItemStageGroupHeader.propTypes = {
  /**
   * Whether this group is currently expanded (true) or collapsed (false)
   */
  expanded: PropTypes.bool.isRequired,

  /**
   * Called when the group's expanded state should be toggled.
   */
  handleToggleExpanded: PropTypes.func.isRequired,

  /**
   * The number of child items within this group.
   */
  itemCount: PropTypes.number.isRequired,

  /**
   * The stage record for this grouping
   */
  stage: ImmutablePropTypes.mapContains({
    name: PropTypes.string.isRequired,
  }),
};

CandidacyListItemStageGroupHeader.getHeight = () => HEIGHT;

export default compose(
  setDisplayName('CandidacyListItemStageGroupHeader(enhanced)'),
  setStatic('HEIGHT', HEIGHT),
  withHandlers({
    handleToggleExpanded: ({ stage, toggleGroup }) => e => {
      e.preventDefault();
      toggleGroup(stage && stage.get('id'));
    },
  }),
)(CandidacyListItemStageGroupHeader);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import getClientTypeName from 'modules/tenant/selectors/getClientTypeName';
import { compose, onlyUpdateForPropTypes, setStatic } from 'recompose';
import InterviewTypeAbbreviation from './InterviewTypeAbbreviation';
import { SORT_BY_INTERVIEWERS, INTERVIEW_TYPE_CLIENT } from '../constants';
import getInterviewerNames from '../selectors/getInterviewerNames';

const InterviewTableInterviewersColumn = ({
  attendeeNames,
  interview,
  label,
}) => (
  <span>
    <InterviewTypeAbbreviation interviewType={label} />
    {attendeeNames || interview.get('interviewers')}
  </span>
);

InterviewTableInterviewersColumn.propTypes = {
  attendeeNames: PropTypes.string,
  interview: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
    label: PropTypes.string,
    interviewers: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
};

export default compose(
  setStatic('columnTitle', 'Interviewer(s)'),
  setStatic('sortBy', SORT_BY_INTERVIEWERS),
  setStatic('key', SORT_BY_INTERVIEWERS),
  connect(
    (state, { interview }) => ({
      attendeeNames: getInterviewerNames(state, interview.get('id')),
      label:
        interview.get('label') === INTERVIEW_TYPE_CLIENT
          ? getClientTypeName(state)
          : interview.get('label'),
    }),
    {},
  ),
  onlyUpdateForPropTypes,
)(InterviewTableInterviewersColumn);

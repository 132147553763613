import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';

import SortIndicator from '@thrivetrm/ui/components/SortIndicator';

import {
  SORT_BY_DATE,
  SORT_BY_INTERVIEWERS,
  SORT_BY_CANDIDATE_NAME,
} from '../constants';
import withInterviewListFetched from './withInterviewListFetched';
import InterviewTableCandidateColumn from './InterviewTableCandidateColumn';
import InterviewTableDateColumn from './InterviewTableDateColumn';
import InterviewTableInterviewersColumn from './InterviewTableInterviewersColumn';
import InterviewTableRejectionReasonColumn from './InterviewTableRejectionReasonColumn';
import mapInterviewIdsToInterviews from './mapInterviewIdsToInterviews';
import mapInterviewListToInterviewIds from './mapInterviewListToInterviewIds';
import mapPropsToParentIdAndParentType from './mapPropsToParentIdAndParentType';
import mapParentToInterviewList from './mapParentToInterviewList';
import withInterviewIdsSorted from './withInterviewIdsSorted';
import withInterviewIdsFilteredByDateGroup from './withInterviewIdsFilteredByDateGroup';
import withInterviewIdsFilteredByInterviewType from './withInterviewIdsFilteredByInterviewType';

const COLUMNS = [
  InterviewTableDateColumn,
  InterviewTableCandidateColumn,
  InterviewTableInterviewersColumn,
  InterviewTableRejectionReasonColumn,
];

/**
 * Renders a sortable (optioanlly) table of interviews
 * Note that the `onSortChanged` prop must be supplied and handled in order
 * for this table to actually be sortable (see propType definitions, below)
 */
class InterviewTable extends Component {
  /**
   * Called when a column header is clicked; adjusts the sorting.
   */
  handleHeaderClick = event => {
    const { onSortChanged, sortBy: currentSortBy, sortDescending } = this.props;
    if (onSortChanged) {
      const { sortBy } = event.currentTarget.dataset;
      onSortChanged(sortBy, sortBy === currentSortBy ? !sortDescending : true);
    }
  };

  render() {
    const {
      interviews,
      // eslint-disable-next-line no-unused-vars
      onSortChanged,
      sortBy,
      sortDescending,
    } = this.props;

    return (
      <table className='interviews--interview-table table table-index'>
        <thead className='TableHead--sortable'>
          <tr>
            {COLUMNS.map(
              component =>
                component !== InterviewTableRejectionReasonColumn && (
                  /* eslint-disable jsx-a11y/no-static-element-interactions */
                  <th
                    className={`interviews--interview-table-header-${component.key}`}
                    colSpan={
                      component === InterviewTableInterviewersColumn ? 2 : 1
                    }
                    data-sort-by={component.sortBy}
                    key={component.key}
                    onClick={this.handleHeaderClick}
                  >
                    {component.columnTitle}
                    <SortIndicator
                      isAscending={!sortDescending}
                      isSorted={sortBy === component.key}
                    />
                  </th>
                  /* eslint-enable jsx-a11y/no-static-element-interactions */
                ),
            )}
          </tr>
        </thead>
        <tbody>
          {interviews &&
            interviews
              .map(interview => (
                <tr key={interview.get('id')}>
                  {COLUMNS.map(ColumnComponent => (
                    <td key={ColumnComponent.key}>
                      <ColumnComponent {...this.props} interview={interview} />
                    </td>
                  ))}
                </tr>
              ))
              .toArray()}
        </tbody>
        {interviews && (
          <tfoot>
            <tr key='total-interviews'>
              <td colSpan='4'>Total Interviews: {interviews.size}</td>
            </tr>
            <tr key='total-candidacies'>
              <td colSpan='4'>
                Total Candidates:{' '}
                {
                  interviews.countBy(interview => interview.get('candidate'))
                    .size
                }
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    );
  }
}

InterviewTable.propTypes = {
  /**
   * The interviews to render in the table
   */
  interviews: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      candidate: PropTypes.number.isRequired,
    }).isRequired,
  ),

  /**
   * A function that is called when the sorting is changed.
   * If this is not provided, the table will not be sortable.
   * This acts similar to a controller component, in that the `sortedBy` and
   * `isSortedAscending` properties are not automatically updated, but must
   * be update in this callback, if desired.
   * This funciton is called with the signature:
   * `onSortChanged (newSortByColumn, newIsSortedAscendingValue)`
   * @type {Function}
   */
  onSortChanged: PropTypes.func,

  /**
   * The column to sort on.
   */
  sortBy: PropTypes.oneOf([
    SORT_BY_DATE,
    SORT_BY_INTERVIEWERS,
    SORT_BY_CANDIDATE_NAME,
  ]),

  /**
   * True if the sort is descending, or false for ascending.
   * @type {Boolean}
   */
  sortDescending: PropTypes.bool,
};

export default compose(
  setDisplayName('InterviewTable(enhanced)'),
  mapPropsToParentIdAndParentType,
  mapParentToInterviewList,
  mapInterviewListToInterviewIds,
  withInterviewListFetched,
  withInterviewIdsFilteredByDateGroup,
  withInterviewIdsFilteredByInterviewType,
  withInterviewIdsSorted,
  mapInterviewIdsToInterviews,
)(InterviewTable);

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes } from 'recompose';

export default compose(
  connect((state, { filterText = '', results = {} }) => {
    if (Object.keys(results).length === 0) {
      return state;
    }

    const query = filterText.toLowerCase();
    const filteredResults = {};
    filteredResults.recommendedCandidates = results.recommendedCandidates.filter(
      result => {
        const { full_name, preferred_email, primary_company } = result.contact;
        const fullName = full_name?.toLowerCase() || '';
        const companyName = primary_company?.name?.toLowerCase() || '';
        const email = preferred_email?.toLowerCase() || '';

        return (
          fullName.includes(query) ||
          companyName.includes(query) ||
          email.includes(query)
        );
      },
    );

    filteredResults.notAFitCandidates = results.notAFitCandidates.filter(
      result => {
        const { full_name, preferred_email, primary_company } = result.contact;
        const fullName = full_name?.toLowerCase() || '';
        const companyName = primary_company?.name?.toLowerCase() || '';
        const email = preferred_email?.toLowerCase() || '';

        return (
          fullName.includes(query) ||
          companyName.includes(query) ||
          email.includes(query)
        );
      },
    );

    return { results: filteredResults };
  }),
  setPropTypes({
    filterText: PropTypes.string,
    results: PropTypes.any,
  }),
);

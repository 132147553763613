import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Date from '@thrivetrm/ui/components/Date';
import Tag from 'modules/core/components/Tag';
import invalidateContact from 'actions/contacts/invalidateContact';
import { deleteDocument } from 'modules/documents/actions';
import { OWNER_CONTACT, LABEL_RESUME } from '../constants';
import documentShape from '../documentShape';
import InlineEditActions from '../../../components/forms/InlineEditActions';
import DocumentLink from './DocumentLink';

export const DocumentTableViewRow = ({
  document,
  fetchDocuments,
  isDeleted,
  isDeleting,
  isUpdating,
  onEditClick,
  readOnly,
  shouldShowExpirationDateColumn,
  shouldShowShareInSearchesColumn,
}) => {
  const isShared = document.share_in_searches;
  const isPrimary = document.primary;

  const dispatch = useDispatch();
  const handleDelete = () =>
    dispatch(
      deleteDocument({
        id: document.id,
        uuid: document.uuid,
      }),
    ).then(() => {
      fetchDocuments();
      if (
        isPrimary &&
        document.document_label.name === LABEL_RESUME &&
        document.owner_type === OWNER_CONTACT
      ) {
        dispatch(invalidateContact({ id: document.owner_id }));
      }
    });

  return (
    <>
      <td className='documents--document-table-row'>
        <DocumentLink documentUuid={document.uuid}>
          {document.file_name}
        </DocumentLink>
      </td>
      <td className='documents--document-table-row'>
        <Tag name={document.document_label.name} />
      </td>
      {shouldShowShareInSearchesColumn ? (
        <td className='documents--document-table-row'>
          {isShared ? 'Yes' : 'No'} {isShared && isPrimary && '(Primary)'}
        </td>
      ) : null}
      <td className='documents--document-table-row'>
        <Date date={document.created_at} />
      </td>
      {shouldShowExpirationDateColumn && (
        <td className='documents--document-table-row'>
          <Date date={document.expiration_date} />
        </td>
      )}
      <td className='documents--document-table-row'>
        {!readOnly && (
          <InlineEditActions
            actionClassName='pull-right'
            canDelete={!isDeleted && document.permissions.delete}
            canEdit={!isDeleted && document.permissions.edit}
            deleteConfirmation={`Are you sure you want to delete ${document.file_name}?`}
            deleteLabel={<i className='fa fa-trash' />}
            disabled={isDeleting || isUpdating}
            editLabel={<i className='fa fa-pencil' />}
            onDelete={handleDelete}
            onEdit={onEditClick}
          />
        )}
      </td>
    </>
  );
};

DocumentTableViewRow.defaultProps = {
  readOnly: false,
};

DocumentTableViewRow.propTypes = {
  document: PropTypes.shape(documentShape).isRequired,
  fetchDocuments: PropTypes.func.isRequired,
  isDeleted: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  shouldShowExpirationDateColumn: PropTypes.bool.isRequired,
  shouldShowShareInSearchesColumn: PropTypes.bool.isRequired,
};

export default DocumentTableViewRow;

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import tagSchema from '../schemas/tag';

/**
 * Returns a value indicating whether a particular tag has been fetched from the server or not.
 *
 * @param {*} state
 * @param {Number} tagId The ID of the tag to check.
 * @return {Boolean} True if the tag has been fetched, otherwise false.
 */
export default (state, tagId) =>
  Boolean(getEntityProperty(state, tagSchema.key, tagId, 'id'));

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  defaultProps,
  setPropTypes,
  withProps,
} from 'recompose';

import withInvestmentIdsSortedByDate from './withInvestmentIdsSortedByDate';
import withInvestmentIdsSortedByRoundStageName from './withInvestmentIdsSortedByRoundStageName';
import withInvestmentIdsSortedByAmount from './withInvestmentIdsSortedByAmount';

import {
  SORT_BY_ROUND_DATE,
  SORT_BY_COMPANY_STAGE,
  SORT_BY_ROUND_AMOUNT,
} from '../constants';

/**
 * A higher order component that provides sorting of a list of investment IDs provided by a
 * `investmentIds` prop, based on the prop values of `sortBy` and `sortAscending`.
 */
export default compose(
  defaultProps({
    sortBy: SORT_BY_ROUND_DATE,
    sortAscending: true,
  }),
  setPropTypes({
    sortBy: PropTypes.oneOf([
      SORT_BY_ROUND_DATE,
      SORT_BY_COMPANY_STAGE,
      SORT_BY_ROUND_AMOUNT,
    ]).isRequired,
    sortAscending: PropTypes.bool.isRequired,
    investmentIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
  }),

  // These sorts require a bit more sorting logic.
  branch(
    ({ sortBy }) => sortBy === SORT_BY_ROUND_DATE,
    withInvestmentIdsSortedByDate,
  ),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_COMPANY_STAGE,
    withInvestmentIdsSortedByRoundStageName,
  ),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_ROUND_AMOUNT,
    withInvestmentIdsSortedByAmount,
  ),

  // Reverse the sort if needed based on the `sortAscending` prop value.
  withProps(({ investmentIds, sortAscending }) => ({
    investmentIds:
      investmentIds && !sortAscending ? investmentIds.reverse() : investmentIds,
  })),
);

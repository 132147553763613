import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import referenceSchema from '../schema';

/**
 * Gets the created_at field value for a reference by it's ID
 * @param {Object} state
 * @param {Number} id The reference ID
 * @return {String} The ISO8601 formated created at date.
 */
export default (state, id) =>
  getEntityProperty(state, referenceSchema.key, id, 'created_at');

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Measure from 'react-measure';
import selectn from 'selectn';
import { compose, setDisplayName, withProps } from 'recompose';
import CandidacyListItemSectionDetailsPrimary from './CandidacyListItemSectionDetailsPrimary';
import CandidacyListItemSectionDetailsSecondary from './CandidacyListItemSectionDetailsSecondary';
import CandidacyListItemSectionDetailsTertiary from './CandidacyListItemSectionDetailsTertiary';

/**
 * The details section of the candidacy list item.
 * Split into "primary" and "secondary" and "tertiary" (only small card)
 * sections, displayed as side-by-side columns.
 *
 * +------------------------------+------------------------------+
 * | primary                      | secondary                    |
 * |                              |                              |
 * |                              |                              |
 * |                              |                              |
 * |                              |                              |
 * |                              |                              |
 * +------------------------------+------------------------------+
 */
const CandidacyListItemDetails = ({
  candidacyId,
  className,
  searchId,
  smallCard,
  smallCardMinWidth,
  ...props
}) => (
  <Measure bounds={true}>
    {({ contentRect, measureRef }) => (
      <div
        className={classnames(className, 'CandidacyListItemSectionDetails')}
        ref={measureRef}
        {...props}
      >
        <CandidacyListItemSectionDetailsPrimary
          candidacyId={candidacyId}
          className={classnames('CandidacyListItemSectionDetails__primary', {
            CandidacyListItemSmallSectionDetails__primary: smallCard,
          })}
          searchId={searchId}
          smallCard={smallCard}
        />
        {selectn('bounds.width', contentRect) > smallCardMinWidth && (
          <CandidacyListItemSectionDetailsSecondary
            candidacyId={candidacyId}
            className={classnames(
              'CandidacyListItemSectionDetails__secondary',
              {
                CandidacyListItemSmallSectionDetails__secondary: smallCard,
              },
            )}
            searchId={searchId}
            smallCard={smallCard}
          />
        )}
        {smallCard && (
          <CandidacyListItemSectionDetailsTertiary
            candidacyId={candidacyId}
            className='CandidacyListItemSectionDetails__tertiary'
          />
        )}
      </div>
    )}
  </Measure>
);

CandidacyListItemDetails.propTypes = {
  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * An optional class name to apply to the container div
   */
  className: PropTypes.string,

  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,
  /**
   * Weather or not to show content on the 'small'
   * or 'large' candidacy card.
   */
  smallCard: PropTypes.bool,

  /**
   * The size in which the secondary details section should
   * be hidden.
   */
  smallCardMinWidth: PropTypes.number,
};

export default compose(
  setDisplayName('CandidacyListItemDetails(enhanced)'),
  withProps(({ smallCard }) => ({
    smallCardMinWidth: smallCard ? 500 : 0,
  })),
)(CandidacyListItemDetails);

import {
  CONTACT_UPDATE_START,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAILURE,
} from './constants';
import { contactSchema } from '../../modules/contacts/schema';
import createAjaxAction from '../createAjaxAction';
import reduceFieldErrors from './reduceFieldErrors';

const updateContact = createAjaxAction({
  getUrl: ({ contactId }) => `/api/v1/contacts/${contactId}`,
  getRequestOptions: ({ contact }) => ({
    method: 'PATCH',
    body: JSON.stringify({ contact: contact }),
  }),
  schema: { contact: contactSchema },
  start: CONTACT_UPDATE_START,
  success: CONTACT_UPDATE_SUCCESS,
  failure: CONTACT_UPDATE_FAILURE,
  createErrorPayload: reduceFieldErrors,
});

export default updateContact;

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useApiGet } from 'modules/core/hooks/useApi';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import routes from 'modules/routing/routes';
import pluralize from 'pluralize';

import { OWNER_TYPES } from '../constants';
import DocumentTable from './DocumentTable';
import DocumentUploader from './DocumentUploader';

/**
 * The DocumentTableView is a wrapper that encapsulates a DocumentTable, adding some additional
 * view elements like an (optional) uploader, loading indicator, and error alert when fetching
 * fails.
 */
const DocumentTableView = ({ defaultLabel, ownerId, ownerType, readOnly }) => {
  const recordType = ownerType.toLowerCase();
  const recordTypePlural = pluralize(recordType);

  const [
    loadDocuments,
    isLoadingDocuments,
    documentsLoadError,
    documents,
  ] = useApiGet(`/api/v1/${recordTypePlural}/${ownerId}/documents`);

  const [
    loadDocumentLabels,
    isLoadingDocumentLabels,
    documentLabelsLoadError,
    documentLabels,
  ] = useApiGet(
    routes.api_v1_document_labels({
      query: { record_type: recordType },
    }),
  );

  useEffect(() => {
    loadDocuments();
    loadDocumentLabels();
  }, [loadDocuments, loadDocumentLabels]);

  const defaultLabelId = documentLabels?.find(
    label => label.name === defaultLabel,
  )?.id;

  const isLoading = isLoadingDocuments || isLoadingDocumentLabels;
  const hasError = documentsLoadError || documentLabelsLoadError;

  return (
    <div className='documents--document-list'>
      {!readOnly ? (
        <DocumentUploader
          documentLabelId={defaultLabelId}
          fetchDocuments={loadDocuments}
          ownerId={ownerId}
          ownerType={ownerType}
        />
      ) : null}

      {hasError && !isLoading ? (
        <ErrorAlert
          onRetry={loadDocuments}
          title='An error occured while loading documents.'
        />
      ) : null}

      <LoadingContainer isLoading={isLoading}>
        {documents && documentLabels ? (
          <DocumentTable
            documentLabels={documentLabels}
            documents={documents?.documents}
            fetchDocuments={loadDocuments}
            ownerType={ownerType}
            readOnly={readOnly}
          />
        ) : null}
      </LoadingContainer>
    </div>
  );
};

DocumentTableView.propTypes = {
  defaultLabel: PropTypes.string,
  ownerId: PropTypes.number.isRequired,
  ownerType: PropTypes.oneOf(OWNER_TYPES).isRequired,
  readOnly: PropTypes.bool,
};

DocumentTableView.defaultProps = {
  defaultLabel: null,
  readOnly: false,
};

export default DocumentTableView;

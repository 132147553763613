import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import InterviewListItem from './InterviewListItem';
import withInterviewIdsSorted from './withInterviewIdsSorted';

/**
 * Renders a list of interviews, with the ability to edit or delete them
 * inline if the user has permission (based on `permissions.edit` and `permissions.delete`
 * values of the interviews item).
 */
const InterviewList = ({
  interviewIds,
  limit,
  readOnly,
  renderCandidateNames,
  renderDescriptions,
  renderInterviewEditLink,
  renderSubjects,
  renderTitles,
  renderTitlesAsLinks,
  renderWhere,
}) => (
  <ul className='list-generic'>
    {(limit ? interviewIds.take(limit) : interviewIds)
      .map(interviewId => (
        <InterviewListItem
          interviewId={interviewId}
          key={interviewId}
          readOnly={readOnly}
          renderCandidateName={renderCandidateNames}
          renderCreatedBy={true}
          renderDescription={renderDescriptions}
          renderInterviewEditLink={renderInterviewEditLink}
          renderSubject={renderSubjects}
          renderTitle={renderTitles}
          renderTitleAsLink={renderTitlesAsLinks}
          renderWhere={renderWhere}
        />
      ))
      .toArray()}
  </ul>
);

InterviewList.defaultProps = {
  readOnly: false,
  renderCandidateNames: false,
  renderDescriptions: false,
  renderInterviewEditLink: false,
  renderTitlesAsLinks: false,
  renderWhere: false,
};

InterviewList.propTypes = {
  interviewIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,

  /**
   * Optional maximum number of interviews to render
   */
  limit: PropTypes.number,

  readOnly: PropTypes.bool,

  renderCandidateNames: PropTypes.bool,

  renderDescriptions: PropTypes.bool,

  renderInterviewEditLink: PropTypes.bool,

  renderSubjects: PropTypes.bool.isRequired,

  renderTitles: PropTypes.bool.isRequired,

  renderTitlesAsLinks: PropTypes.bool,

  renderWhere: PropTypes.bool,
};

export default withInterviewIdsSorted(InterviewList);

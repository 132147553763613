import PropTypes from 'prop-types';
import selectn from 'selectn';
import {
  branch,
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import withFormState from 'modules/forms/components/withFormState';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withTransactionTracking from 'modules/transactions/components/withTransactionTracking';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import createTagActionCreator from '../actions/createTag';
import updateTagActionCreator from '../actions/updateTag';
import withTagFetched from './withTagFetched';
import withTagListFetched from './withTagListFetched';
import getTagProperty from '../selectors/getTagProperty';

/**
 * Provides props for managing a form for creating or editing a tag.
 *
 * Creates the following props for the child component:
 * * `formState` {FormState} a FormState object containing an underlying FieldState created from
 *    the `RootField` parameter (by calling RootField.createFieldState).
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default RootField =>
  compose(
    setDisplayName('withTagForm'),
    setPropTypes({
      /**
       * If editing an existing tag, it's ID.
       */
      tagId: PropTypes.number,

      /**
       * Called when the form has been successfully saved.
       */
      onSaved: PropTypes.func,

      /**
       * An optional action to call when the form is submitted.
       * If this function is supplied it is called instead of the
       * default action (`createTag` or `updateTag`)
       */
      onSubmitAction: PropTypes.func,
    }),

    // Store incoming props to prevent cloberring and restore then when we're done.
    withProps(
      ({
        onSaved: _onSaved,
        onSubmitAction: _onSubmitAction,
        ...withTagFormProps
      }) => ({
        withTagFormProps: withTagFormProps,
      }),
    ),

    // If editing an existing tag, make sure it is fetched.
    branch(({ tagId }) => typeof tagId === 'number', withTagFetched()),
    withTagListFetched(),
    connect(
      (state, { tagId, tagList }) => ({
        tagNames:
          tagList && tagList.has('ids')
            ? tagList
                .get('ids')
                .filter(id => id !== tagId)
                .map(id => getTagProperty(state, id, 'name'))
            : null,
      }),
      {},
    ),

    /**
     * Create a FormState, initializing it with the value from a `tag` prop (which is supplied
     * by the above `branch` if a `tagId` was set), if available.
     * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
     */
    withFormState(({ tag, tagNames }, isReset) =>
      RootField.createFieldState(
        'tag',
        isReset
          ? { tag: null, tagNames: tagNames }
          : { tag: tag, tagNames: tagNames },
      ),
    ),

    /**
     * Watch for any save transaction to complete
     */
    withHandlers({
      /**
       * This gets called by `withTransaction`, below, any time our transaction started
       * with `startTransaction` is called.
       */
      onTransactionFinished: ({
        formState,
        onFormStateChange,
        onResetFormState,
        onSaved,
      }) => (_, transaction) => {
        const error = selectn('payload.error', transaction);
        onFormStateChange(formState.endSubmit(error));

        if (!error) {
          onResetFormState();

          // If all went well and `onSaved` was provided, call it with the new record's ID
          if (onSaved) {
            onSaved(selectn('payload.result.tag', transaction));
          }
        }
      },
    }),

    withTransactionTracking(),
    /**
     * Include connected versions of `createTag` and `updateTag` that
     * are needed to submit.
     */
    connect(null, {
      createTag: createTagActionCreator,
      updateTag: updateTagActionCreator,
    }),

    /**
     * Add a callback to handle submitting the action form.
     */
    withHandlers({
      // Called when the form should be submitted.
      onSubmit: ({
        createTag,
        formState,
        onFormStateChange,
        onSubmitAction,
        tagId,
        trackTransaction,
        updateTag,
      }) => e => {
        const transactionId = uniqueId();

        trackTransaction(transactionId);
        const tag = formState.getFieldValue();

        const action = onSubmitAction || (tagId ? updateTag : createTag);
        action({ tag: { ...tag, id: tagId }, transactionId: transactionId });

        onFormStateChange(formState.startSubmit(transactionId));

        // Prevent default browser behavior, which could cause the browser to attempt
        // to submit a form on it's own.
        return preventDefaultHandler(e);
      },
    }),

    // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
    mapProps(
      ({
        formState,
        onCancel,
        onFieldStateChange,
        onFormStateChange,
        onSubmit,
        // exclude this from being passed down.
        onSubmitAction: _onSubmitAction,
        withTagFormProps,
      }) => ({
        ...withTagFormProps,
        formState: formState,
        onFieldStateChange: onFieldStateChange,
        onFormStateChange: onFormStateChange,
        onSubmit: onSubmit,
        onCancel: onCancel,
      }),
    ),
  );

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import Menu from '@thrivetrm/ui/components/Menu';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import { useApiGet } from 'modules/core/hooks/useApi';
import EditSavedViewsSidePanel from './EditSavedViewSidePanel';
import NewSavedViewModal from './NewSavedViewModal';
import useRecordIndexSavedViews from '../useRecordIndexSavedViews';

const RecordIndexSidebarSavedViews = ({ isLegacyView }) => {
  const { endpoints, savedView: currentSavedView } = useSelector(
    state => state.recordIndex,
  );

  const [
    loadSavedViews,
    _isLoadingSavedViews,
    _savedViewsLoadError,
    savedViews,
  ] = useApiGet(endpoints.savedViews());

  useEffect(() => {
    loadSavedViews();
  }, [loadSavedViews]);

  const params = useParams();
  const savedViewId = parseInt(params.savedViewId);

  const {
    changeSavedView,
    defaultSavedViewId,
    deleteSavedView,
    hasChanges,
    isUpdatingSavedView,
    isUpdatingUser,
    resetSavedView,
    setAsDefaultView,
    updateSavedView,
  } = useRecordIndexSavedViews();

  const [
    isDeleteConfirmationDialogOpen,
    openDeleteConfirmationDialogOpen,
    closeDeleteConfirmationDialogOpen,
  ] = useToggle(false);

  const [
    isRenameSavedViewPanelOpen,
    openRenameSavedViewPanelOpen,
    closeRenameSavedViewPanelOpen,
  ] = useToggle(false);

  const [
    isNewSavedViewModalOpen,
    openNewSavedViewModal,
    closeNewSavedViewModal,
  ] = useToggle(false);

  const ELLIPSIS_LENGTH = 3;
  const MAX_TEXT_LENGTH = 55;

  const truncateText = text => {
    return text.length > MAX_TEXT_LENGTH
      ? `${text.substring(0, MAX_TEXT_LENGTH - ELLIPSIS_LENGTH)}...`
      : text;
  };

  return (
    <div
      className={
        isLegacyView ? 'u-borderBottom u-paddingBottom-24' : 'u-inlineFlex'
      }
    >
      <div className={isLegacyView ? 'u-flex' : 'u-inlineFlex'}>
        <SelectMenu
          className='u-flexItem-grow u-marginRight-8'
          inputWidth='full'
          onChange={changeSavedView}
          size={isLegacyView ? 'small' : 'medium'}
          style={{ minWidth: '236px' }}
          value={savedViewId}
        >
          {savedViews?.map(savedView => {
            const savedViewLabel =
              savedView.id === defaultSavedViewId
                ? `${truncateText(savedView.name, MAX_TEXT_LENGTH)} (Default)`
                : truncateText(savedView.name, MAX_TEXT_LENGTH);
            return (
              <SelectMenu.Item
                key={`savedView-${savedView.id}`}
                /**
                 * The SelectMenuItem does not accept a className so we set display to 'flex' so options
                 * are visible. */
                style={{ display: 'flex' }}
                value={savedView.id}
              >
                {savedViewLabel}
              </SelectMenu.Item>
            );
          })}
        </SelectMenu>
        {hasChanges && !isLegacyView ? (
          <div className='u-inlineBlock'>
            <ButtonSecondary
              className='u-paddingHorizontal-12'
              isLoading={isUpdatingSavedView}
              isOutline={true}
              label='Update'
              onClick={updateSavedView}
              size='medium'
            />
            <ButtonPrimary
              className='u-marginLeft-8 u-paddingHorizontal-12'
              isDarkMode={true}
              isOutline={true}
              label='Reset View'
              onClick={resetSavedView}
              size='medium'
            />
            <ButtonPrimary
              className='u-paddingHorizontal-12 u-marginHorizontal-8'
              isDarkMode={true}
              isOutline={true}
              label='Save As New'
              onClick={openNewSavedViewModal}
              size='medium'
            />
          </div>
        ) : null}
        <Menu
          button={
            !isLegacyView && hasChanges ? (
              <ButtonPrimary
                aria-label='saved views options'
                icon='options'
                isDarkMode={true}
                isOutline={true}
                size='medium'
              />
            ) : (
              <ButtonSecondary
                aria-label='saved views options'
                className='u-marginRight-8'
                icon='options'
                size={isLegacyView ? 'small' : 'medium'}
              />
            )
          }
          isPinnedRight={true}
        >
          <Menu.Item icon='edit' onClick={openRenameSavedViewPanelOpen}>
            Rename
          </Menu.Item>
          <Menu.Item
            icon='house'
            isDisabled={savedViewId === defaultSavedViewId || isUpdatingUser}
            onClick={setAsDefaultView}
          >
            Set as Default View
          </Menu.Item>
          <Menu.Item
            icon='delete'
            isDisabled={savedViewId === defaultSavedViewId}
            onClick={openDeleteConfirmationDialogOpen}
          >
            {savedViewId === defaultSavedViewId ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id='delete-menu-option'>
                    Default views cannot be deleted.
                  </Tooltip>
                }
              >
                <div>Delete</div>
              </OverlayTrigger>
            ) : (
              'Delete'
            )}
          </Menu.Item>
        </Menu>
      </div>
      {hasChanges && isLegacyView ? (
        <div className='u-paddingTop-16'>
          <ButtonPrimary
            className='u-paddingHorizontal-12'
            isOutline={true}
            label='Clear Changes'
            onClick={resetSavedView}
            size='small'
          />
          <ButtonPrimary
            className='u-paddingHorizontal-12 u-marginLeft-8'
            isOutline={true}
            label='Save As New'
            onClick={openNewSavedViewModal}
            size='small'
          />
          <ButtonPrimary
            className='u-paddingHorizontal-12 u-marginLeft-8'
            isLoading={isUpdatingSavedView}
            label='Update'
            onClick={updateSavedView}
            size='small'
          />
        </div>
      ) : null}

      {savedViews?.length ? (
        <EditSavedViewsSidePanel
          isOpen={isRenameSavedViewPanelOpen}
          onClose={closeRenameSavedViewPanelOpen}
          savedViewId={savedViewId}
          savedViews={savedViews}
        />
      ) : null}
      {isNewSavedViewModalOpen ? (
        <NewSavedViewModal
          onClose={closeNewSavedViewModal}
          onSuccess={newView => changeSavedView(newView.id)}
          savedViews={savedViews}
        />
      ) : null}

      {isDeleteConfirmationDialogOpen && (
        <ConfirmationDialog
          cancelLabel='No, Keep Working'
          confirmLabel='Yes, Delete Saved View'
          isOpen={isDeleteConfirmationDialogOpen}
          onClose={closeDeleteConfirmationDialogOpen}
          onConfirm={deleteSavedView}
          title='Delete Saved View?'
        >
          <p>You are about to delete the “{currentSavedView?.name}” view.</p>
          <p>This cannot be undone</p>
          <b>Do you wish to continue?</b>
        </ConfirmationDialog>
      )}
    </div>
  );
};

RecordIndexSidebarSavedViews.propTypes = {
  isLegacyView: PropTypes.bool,
};

RecordIndexSidebarSavedViews.defaultProps = {
  isLegacyView: true,
};

export default RecordIndexSidebarSavedViews;

import { convertToCamelCase } from 'modules/core/jsonUtils';
import apiV1 from './index';
import queryTags from './queryTags';

const { CANDIDATE_TAGS } = queryTags;

const candidateTags = apiV1.injectEndpoints({
  endpoints: builder => ({
    getCandidateTags: builder.query({
      query: searchId => ({ url: `/searches/${searchId}/tags`, method: 'GET' }),
      providesTags: [CANDIDATE_TAGS],
      transformResponse: response => convertToCamelCase(response),
    }),
    getTags: builder.query({
      query: params => ({
        url: '/tags',
        method: 'GET',
        params: params,
      }),
      providesTags: [CANDIDATE_TAGS],
      transformResponse: response => ({
        isCreatedByCurrentUser:
          response?.meta.is_created_by_current_user || false,
        tags: convertToCamelCase(response?.tags || []),
        totalCount: response?.meta.count || 0,
      }),
    }),
    deleteTag: builder.mutation({
      query: ({ tagId }) => ({ url: `/tags/${tagId}`, method: 'DELETE' }),
      invalidatesTags: [CANDIDATE_TAGS],
    }),
    updateTag: builder.mutation({
      query: ({ payload, tagId }) => ({
        url: `/tags/${tagId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [CANDIDATE_TAGS],
    }),
    updateCandidateTag: builder.mutation({
      query: ({ payload }) => ({
        url: '/candidacies/bulk_update_candidacy_tags',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [CANDIDATE_TAGS],
    }),
    createTag: builder.mutation({
      query: ({ payload }) => ({ url: '/tags', method: 'POST', body: payload }),
      transformResponse: response => convertToCamelCase(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateTagMutation,
  useDeleteTagMutation,
  useGetCandidateTagsQuery,
  useGetTagsQuery,
  useUpdateCandidateTagMutation,
  useUpdateTagMutation,
} = candidateTags;

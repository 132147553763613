import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import provideData from '../actions/provideData';

/**
 * A component for providing application data to our store via a prop.
 *
 * This allows the server to mount a component with preloaded data, so additional ajax calls are
 * not needed.
 *
 * @example
 * ```jsx
 * <DataProvider data={{ company: { id: 42, name: 'Megadodo Publications' }}}
 * ```
 */
export default compose(
  setDisplayName('DataProvider'),
  setPropTypes({
    /**
     * The data to provide to the store
     * NOTE: This must match the schema used in `actions/provideData`! If you add or change this,
     * be sure to update the schema as well!
     */
    data: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
  }),

  // Bind the `provideData` action to the dispatcher.
  connect(null, { provideData: provideData }),

  /**
   * If a `data` prop was supplied, dispatch the provideData action with the data provided.
   */
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const { data, provideData: dispatchProvideData } = this.props;
      if (data) {
        dispatchProvideData(data);
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const { data: previousData } = this.props;
      const { data, provideData: dispatchProvideData } = nextProps;
      if (data && data !== previousData) {
        dispatchProvideData(data);
      }
    },
  }),
)(renderNothing());

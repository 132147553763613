import 'legacy';
import { config as reactTransitionGroupConfig } from 'react-transition-group';
import mountComponents from './lib/mountComponents';
import './plugins';
import './initializers/index';
import * as components from './containers';
import notify from './lib/notify';
import handleErrorWithAppsignal from './lib/appsignal';
import pages from './pages';

// IMPORTANT: we need to import app.scss after SCSS imported via the above imports. This seems odd,
// but is currently required, to get around selector order issues. ATTOW, not all of our
// utility helpers utilized `!import`, so they must appear very last. Importing `app.scss` last will
// mirror the CSS order that existed prior to migrating all FE SCSS to Webpack.
import '../stylesheets/app.scss';

window.Thrive = {
  notify: notify,
  pages: pages,
};

document.addEventListener('DOMContentLoaded', () => {
  mountComponents(components);
});

if (process.env.NODE_ENV === 'production') {
  window.onError = handleErrorWithAppsignal;
}

// Disable React transitions for Feature Specs so we don't have to `wait` for them
if (process.env.NODE_ENV === 'test') {
  reactTransitionGroupConfig.disabled = true;
}

if (module.hot) {
  module.hot.accept('./containers', () => {
    // eslint-disable-next-line global-require
    const nextContainers = require('./containers');
    mountComponents(nextContainers);
  });
}

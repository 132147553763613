import { connect } from 'react-redux';
import { compose, mapProps, setDisplayName } from 'recompose';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';

/**
 * Sets a prop that indicates whether the current user is permitted to see/use candidacy tags.
 *
 * In order for tags to be shown:
 * 1. The feature must be anebld (`feature.candidacy_tags`)
 * 2. The user must be a "non-restricted" user (an employee)
 *
 * @param {Object} options
 * @param {String} options.isPermittedPropName The name of the prop to set that indicates
 *   whether the user has permission to see/use candidacy tags.
 */
export default ({ isPermittedPropName = 'isCandidacyTagsPermitted' } = {}) =>
  compose(
    setDisplayName('withCandidacyTagPermissions'),
    mapProps(withTagPermissionsProps => ({
      withTagPermissionsProps: withTagPermissionsProps,
    })),
    withFeatureCheck('feature.candidacy_tags', 'hasTagFeature'),
    connect(
      (state, { hasTagFeature }) => ({
        isTagsPermitted: Boolean(hasTagFeature && isEmployeeUser(state)),
      }),
      {},
    ),
    mapProps(({ isTagsPermitted, withTagPermissionsProps }) => ({
      ...withTagPermissionsProps,
      [isPermittedPropName]: isTagsPermitted,
    })),
  );

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import { connect } from 'react-redux';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import * as validators from 'modules/forms/validators';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import {
  EXPANDED_SORT_OPTIONS,
  FORMAT_DOCX,
  FORMAT_PDF,
  LAYOUT_LEADERSHIP,
  SORT_OPTIONS,
  TYPE_BRIEF,
  TYPE_EXPANDED,
  TYPE_OVERVIEW,
} from './constants';

import { TYPE_JOB_SEARCH } from '../../constants';
import getSearchProperty from '../../selectors/getSearchProperty';
import EmailField from '../../../../components/forms/EmailField';
import withCandidacyPerStageStats from '../candidacyStats/withCandidacyPerStageStats';

import SearchSummaryReportFormatField from './SearchSummaryReportFormatField';
import SearchSummaryReportTypeField from './SearchSummaryReportTypeField';
import SearchSummaryReportLayoutField from './SearchSummaryReportLayoutField';
import SearchSummaryReportAddOnsField from './SearchSummaryReportAddOnsField';
import SearchSummaryReportCandidateInfoField from './SearchSummaryReportCandidateInfoField';
import SearchSummaryReportSortField from './SearchSummaryReportSortField';
import SearchSummaryReportStagesField from './SearchSummaryReportStagesField';
import SearchSummaryReportRejectionInfoField from './SearchSummaryReportRejectionInfoField';

/**
 * Renders a field for selecting the options for generating the search summary report.
 */
class SearchSummaryReportField extends PureComponent {
  static createFieldState(
    name = 'candidacy',
    currentUserEmail,
    stages,
    reportType,
    hasAssessmentTemplates,
  ) {
    const isLeadershipReport = reportType === LAYOUT_LEADERSHIP;
    const isExpandedReport = reportType === TYPE_EXPANDED;

    const storedSelections =
      JSON.parse(
        window.localStorage.getItem(`${reportType}SearchSummaryReportValues`),
      ) || {};

    const defaultSort =
      isLeadershipReport || isExpandedReport ? 'stage' : 'rank';

    return FieldState.createNested(
      name,
      [
        CheckboxField.createFieldState(
          'include_hidden_candidacies',
          Boolean(storedSelections.include_hidden_candidacies),
        ),
        SearchSummaryReportFormatField.createFieldState(
          'format',
          storedSelections.format || FORMAT_PDF,
        ),
        SearchSummaryReportTypeField.createFieldState(
          'type',
          reportType || TYPE_OVERVIEW,
        ),
        SearchSummaryReportLayoutField.createFieldState(
          'layout',
          storedSelections.layout,
        ),
        SearchSummaryReportAddOnsField.createFieldState('addons', {
          assAttrChecked: Boolean(
            storedSelections.fields?.includes('assessment_attribute_report') &&
              !hasAssessmentTemplates,
          ),
          notesChecked: Boolean(storedSelections.fields?.includes('notes')),
        }),
        SearchSummaryReportCandidateInfoField.createFieldState(
          'candidateInfo',
          isLeadershipReport
            ? { type: reportType }
            : {
                type: reportType,
                fieldNames: storedSelections.fields || [],
              },
          isLeadershipReport ? null : validators.hasMinimumLength(),
        ),
        SearchSummaryReportSortField.createFieldState(
          'sort',
          storedSelections.sort || defaultSort,
          validators.requiredField('Sort'),
        ),
        SearchSummaryReportStagesField.createFieldState(
          'stages',
          {
            selectedStages: storedSelections.stages || [],
            allStageIds: stages,
          },
          isLeadershipReport ? null : validators.hasMinimumLength('stage'),
        ),
        SearchSummaryReportRejectionInfoField.createFieldState(
          'rejectionInfo',
          {
            commentsChecked: Boolean(
              storedSelections.fields?.includes('rejection_comments'),
            ),
            reasonChecked: Boolean(
              storedSelections.fields?.includes('rejection_reason'),
            ),
            stageAtRejectionChecked: Boolean(
              storedSelections.fields?.includes('stage_at_rejection'),
            ),
          },
        ),
        EmailField.createFieldState(
          'emails',
          storedSelections.emails || currentUserEmail,
          validators.requiredField('An email address'),
        ),
      ],

      // validator (none)
      null,

      // convertToRaw implementation to generate an appropriate representation of options that
      // can be sent to the server. All fields are merged into a single 'fields' property,
      // `type`, `stages` and `emails` are sent on the root object.
      ({ addons, candidateInfo, rejectionInfo, ...rest }) => ({
        fields: [...addons, ...candidateInfo, ...rejectionInfo],
        ...rest,
      }),
    );
  }

  handleFieldChange = childFieldState => {
    const { currentUserEmail, fieldState, onChange, stages } = this.props;

    let newFieldState = fieldState.setNestedField(childFieldState);

    if (childFieldState.getName() === 'type') {
      const stageIds = stages.map(stage => stage.get('id')).toArray();

      newFieldState = SearchSummaryReportField.createFieldState(
        'searchSummaryReport',
        currentUserEmail,
        stageIds,
        childFieldState.getValue(),
      );
    }

    onChange(newFieldState);
  };

  render() {
    const {
      candidacyVisibility,
      currentUserEmail: _currentUserEmail,
      employeeUser,
      fieldState,
      hasDocxReportLandscapeViewFeature,
      hasPdfReportLandscapeViewFeature,
      searchId,
      searchType,
      stageCounts,
      stageList,
      stages,

      ...rest
    } = this.props;

    const stagesField = fieldState.getNestedFieldValue('stages');
    const selectedStageIds = stagesField
      .filter(field => field.getValue())
      .keySeq();
    const hasRejectedStageSelected =
      stages &&
      stages.some(
        stage =>
          stage.get('rejected') && selectedStageIds.includes(stage.get('id')),
      );

    const isLeadershipReport =
      fieldState.getNestedField('type').getValue() === LAYOUT_LEADERSHIP;
    const isExpandedReport =
      fieldState.getNestedField('type').getValue() === TYPE_EXPANDED;
    const isBriefReport =
      fieldState.getNestedField('type').getValue() === TYPE_BRIEF;
    const isDocxReport =
      fieldState.getNestedField('format').getValue() === FORMAT_DOCX;
    const isPdfReport =
      fieldState.getNestedField('format').getValue() === FORMAT_PDF;

    const isDocxLayoutConfigurable =
      isDocxReport && hasDocxReportLandscapeViewFeature;
    const isPdfLayoutConfigurable =
      isPdfReport && hasPdfReportLandscapeViewFeature;
    const isLayoutConfigurable =
      (isDocxLayoutConfigurable || isPdfLayoutConfigurable) &&
      !(isLeadershipReport || isBriefReport);

    return (
      <div className='SearchSummaryReportField'>
        <div key='options'>
          <h2>Report Options</h2>
          <SearchSummaryReportTypeField
            {...rest}
            fieldState={fieldState.getNestedField('type')}
            onChange={this.handleFieldChange}
          />

          <SearchSummaryReportSortField
            {...rest}
            fieldState={fieldState.getNestedField('sort')}
            onChange={this.handleFieldChange}
            options={
              isExpandedReport || isLeadershipReport || isBriefReport
                ? EXPANDED_SORT_OPTIONS
                : SORT_OPTIONS
            }
          />

          {candidacyVisibility &&
            employeeUser &&
            searchType === TYPE_JOB_SEARCH && (
              <CheckboxField
                {...rest}
                checked={fieldState
                  .getNestedField('include_hidden_candidacies')
                  .getValue()}
                fieldState={fieldState.getNestedField(
                  'include_hidden_candidacies',
                )}
                key='include_hidden_candidacies'
                onChange={this.handleFieldChange}
                text='Include Hidden Candidates'
              />
            )}

          {isExpandedReport && (
            <SearchSummaryReportFormatField
              {...rest}
              fieldState={fieldState.getNestedField('format')}
              onChange={this.handleFieldChange}
            />
          )}

          {isLayoutConfigurable && (
            <SearchSummaryReportLayoutField
              {...rest}
              fieldState={fieldState.getNestedField('layout')}
              onChange={this.handleFieldChange}
            />
          )}

          {!(isLeadershipReport || isBriefReport) && (
            <SearchSummaryReportAddOnsField
              {...rest}
              fieldState={fieldState.getNestedField('addons')}
              onChange={this.handleFieldChange}
            />
          )}
        </div>

        {!isLeadershipReport && (
          <div>
            <SearchSummaryReportCandidateInfoField
              {...rest}
              fieldState={fieldState.getNestedField('candidateInfo')}
              onChange={this.handleFieldChange}
              type={fieldState.getNestedField('type').getValue()}
            />

            <SearchSummaryReportStagesField
              {...rest}
              fieldState={fieldState.getNestedField('stages')}
              isFetching={stageList && stageList.getIn(['_meta', 'isFetching'])}
              onChange={this.handleFieldChange}
              searchId={searchId}
              stageCounts={stageCounts}
              stages={stages}
            />

            {hasRejectedStageSelected && !isBriefReport && (
              <SearchSummaryReportRejectionInfoField
                {...rest}
                fieldState={fieldState.getNestedField('rejectionInfo')}
                onChange={this.handleFieldChange}
              />
            )}
          </div>
        )}

        <div key='recipients'>
          <h2>Recipients</h2>
          <EmailField
            {...rest}
            clearable={false}
            fieldState={fieldState.getNestedField('emails')}
            label='Emails*'
            onChange={this.handleFieldChange}
            wrapperClassName='email-field'
          />
        </div>
      </div>
    );
  }
}

SearchSummaryReportField.propTypes = {
  /**
   *  Returns true if the Hide Candidacies flipper flag is active.
   */
  candidacyVisibility: PropTypes.bool,

  /**
   *  Returns the current user's email address
   */
  currentUserEmail: PropTypes.string.isRequired,

  /**
   *  Returns true if the current user is an employee user (Partner, Recruiter, Lead and Employee).
   */
  employeeUser: PropTypes.bool,

  /**
   * The current value of this field.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  hasAssessmentTemplates: PropTypes.bool,
  hasDocxReportLandscapeViewFeature: PropTypes.bool.isRequired,
  hasPdfReportLandscapeViewFeature: PropTypes.bool.isRequired,

  /**
   * Called when the `fieldState` value changes.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The ID of the search to generate the report for
   */
  searchId: PropTypes.number.isRequired,

  searchType: PropTypes.string,

  /**
   * The map with stage names as keys and the number of candidates in each stage as values.
   */
  stageCounts: ImmutablePropTypes.mapOf(
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ),

  /**
   * The state of the list of stages for the current search. Used to determine if the list
   * is currently being fetched.
   */
  stageList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  /**
   * The list of all stages for the current search
   */
  stages: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      rejected: PropTypes.bool.isRequired,
    }).isRequired,
  ),
};

export default compose(
  setDisplayName('SearchSummaryReportField(enhanced)'),
  setPropTypes({}),
  setStatic('createFieldState', SearchSummaryReportField.createFieldState),
  withCandidacyPerStageStats,
  withFeatureCheck('feature.candidacy_visibility', 'candidacyVisibility'),
  withFeatureCheck(
    'feature.docx_report_landscape_configurable',
    'hasDocxReportLandscapeViewFeature',
  ),
  withFeatureCheck(
    'feature.pdf_report_landscape_configurable',
    'hasPdfReportLandscapeViewFeature',
  ),
  connect(
    (state, { searchId }) => ({
      currentUserEmail: state.user.get('email'),
      employeeUser: isEmployeeUser(state),
      searchType: getSearchProperty(state, searchId, 'type'),
    }),
    {},
  ),
  withPropsRemoved(
    'activeOnly',
    'activeStages',
    'candidacyStats',
    'fetchCandidacyStats',
    'fetchCandidacyStatsIfNeeded',
    'fetchSearch',
    'fetchSearchIfNeeded',
    'rejectionReasons',
    'reportType',
    'search',
    'searchStageActions',
    'shouldFetchCandidacyStats',
    'shouldFetchSearch',
    'stageIds',
    'stageType',
    'totalActiveCandidates',
    'totalCandidates',
  ),
)(SearchSummaryReportField);

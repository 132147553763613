import routes from 'modules/routing/routes';
import { PARENT_SEARCH, PARENT_CONTACT, LIST_RECENT } from '../../constants';

/**
 * Gets the URL for a list of outreaches related to a parent.
 * @param {Object} params
 * @param {String} params.parentId
 * @param {String} params.parentType
 * @param {String} [params.listType]
 */
export default ({ listType, parentId, parentType }) => {
  const query = { include_notifications: true };

  if (listType === LIST_RECENT) {
    query.recent = true;
  }

  switch (parentType) {
    case PARENT_CONTACT: {
      return routes.api_v1_contact_outreaches({
        contactId: parentId,
        query: query,
      });
    }
    case PARENT_SEARCH: {
      return routes.api_v1_search_outreaches({
        searchId: parentId,
        query: query,
      });
    }
    default: {
      throw new Error(`Unknown parentType for outreaches: ${parentType}`);
    }
  }
};

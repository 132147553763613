import { branch, compose, renderNothing, setDisplayName } from 'recompose';
import mapEntityIdToEntityProperty from 'modules/entities/components/mapEntityIdToEntityProperty';
import { searchSchema } from 'modules/searches/schema';
import JobFunctionName from 'modules/tenant/components/JobFunctionName';
import mapContactIdToTalentPoolId from './mapContactIdToTalentPoolId';

export default compose(
  setDisplayName('connect(JobFunction)'),
  mapContactIdToTalentPoolId,
  mapEntityIdToEntityProperty(searchSchema.key, 'job_function', {
    idProp: 'talentPoolId',
    valueProp: 'jobFunctionId',
  }),
  branch(({ jobFunctionId }) => !jobFunctionId, renderNothing),
)(JobFunctionName);

import { NAME } from '../constants';

/**
 * Gets the query value of a particular key from the current routing location.
 * @param {Object} state
 * @param {String} key The key to get the value for
 * @param {[*]} notFoundValue The value to return when the provided key doesn't exist.
 * @return {String} The value for the given key, or `notFoundValue` (undefined by default) if the
 *   key does not exist
 */
export default (state, key, notFoundValue) =>
  state[NAME].getIn(['location', 'query', key], notFoundValue);

import { connect } from 'react-redux';
import { compose } from 'recompose';
import getFullName from 'modules/contacts/selectors/contacts/getFullName';
import getPrimaryCompanyName from 'modules/contacts/selectors/contacts/getPrimaryCompanyName';
import getPrimaryTitle from 'modules/contacts/selectors/contacts/getPrimaryTitle';
import getCompanyName from 'modules/companies/selectors/getCompanyName';
import getJobFunctionName from 'modules/tenant/selectors/getJobFunctionName';
import * as filterUtils from 'modules/core/filterUtils';

import getClientCompanyId from '../selectors/getClientCompanyId';
import getJobFunctionId from '../selectors/getJobFunctionId';
import getJobTitle from '../selectors/getJobTitle';
import getTalentPoolIdForContactId from '../selectors/getTalentPoolIdForContactId';

export default compose(
  connect((state, { contactIds, filterText }) => {
    if (
      !filterText ||
      !filterText.trim() ||
      !contactIds ||
      contactIds.size < 1
    ) {
      return {};
    }

    const filterRegex = filterUtils.createMatchAllKeywordsRegex(filterText);

    return {
      contactIds: contactIds.filter(contactId => {
        const talentPoolId = getTalentPoolIdForContactId(state, contactId);
        const contactName = getFullName(state, contactId);
        const jobFunctionId =
          talentPoolId && getJobFunctionId(state, talentPoolId);
        const jobFunctionName =
          jobFunctionId && getJobFunctionName(state, jobFunctionId);
        const companyId =
          talentPoolId && getClientCompanyId(state, talentPoolId);
        const companyName = talentPoolId
          ? getCompanyName(state, companyId)
          : getPrimaryCompanyName(state, contactId);
        const jobTitle = talentPoolId
          ? getJobTitle(state, talentPoolId)
          : getPrimaryTitle(state, contactId);

        return filterRegex.test(
          [contactName, jobFunctionName, companyName, jobTitle].join(' '),
        );
      }),
    };
  }, {}),
);

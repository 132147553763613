import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import getUserEmail from 'modules/users/selectors/getUserEmail';
import getUserDisplayName from 'modules/users/selectors/getUserDisplayName';
import getContactProperty from '../selectors/contacts/getContactProperty';
import ContactExternalLink from './links/ContactExternalLink';

const ContactRelationshipManagerLink = ({ className, contactId, label }) => {
  const relationshipManagerId = useSelector(state =>
    getContactProperty(state, contactId, 'relationship_manager'),
  );

  const relationshipManagerEmail = useSelector(state =>
    getUserEmail(state, relationshipManagerId),
  );

  const relationshipManagerDisplayName = useSelector(state =>
    getUserDisplayName(state, relationshipManagerId),
  );

  return (
    Boolean(relationshipManagerId) && (
      <div className={`ContactRelationshipManagerLink ${className}`}>
        {label && (
          <div>
            <strong>{label}</strong>
          </div>
        )}
        <ContactExternalLink
          icon={false}
          target='_blank'
          url={`mailto:${relationshipManagerEmail}`}
        >
          {relationshipManagerDisplayName}
        </ContactExternalLink>
      </div>
    )
  );
};

ContactRelationshipManagerLink.propTypes = {
  className: PropTypes.string,
  contactId: PropTypes.number.isRequired,
  label: PropTypes.string,
};

export default ContactRelationshipManagerLink;

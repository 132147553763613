import { NAME } from '../constants';

/**
 * Gets a value from the tenant state.
 * @param {*} state
 * @param {String} flag The key to get from the state.
 * @return {*} The flag value on the tenant state
 */
export default (state, flag, defaultValue) =>
  state[NAME].get(flag, defaultValue);

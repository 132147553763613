import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import Modal from 'modules/core/componentsLegacy/Modal';
import AddDirectReportForm from './AddDirectReportForm';
import DirectReportsList from './DirectReportsList';
import mapContactIdToContact from '../mapContactIdToContact';
import mapContactIdToDirectReports from './mapContactIdToDirectReports';

/* eslint-disable jsx-a11y/label-has-for */

/**
 * Renders a modal form that shows a list of all current direct reports
 * for a given contact and allows other contacts to be added as direct
 * reports.
 */
const DirectReportsManagementModal = ({
  contact,
  contactId,
  directReports,
  isOpen,
  onHide,
}) => (
  <Modal className='DirectReportsManagementModal' onHide={onHide} show={isOpen}>
    <Modal.Header closeButton={true}>
      <Modal.Title>
        {contact.get('name') || contact.get('full_name')}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <AddDirectReportForm contactId={contactId} label='Add Direct Report' />
      {directReports &&
        directReports.has('ids') &&
        directReports.get('ids').size > 0 && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label>Direct Reports ({directReports.get('ids').size})</label>
        )}
      <DirectReportsList contactId={contactId} />
    </Modal.Body>
    <Modal.Footer>
      <button
        className='btn btn-secondary-outline btn-sm'
        onClick={onHide}
        type='button'
      >
        Finish
      </button>
    </Modal.Footer>
  </Modal>
);

DirectReportsManagementModal.propTypes = {
  /**
   * The record of the contact whose direct reports are being shown
   */
  contact: ImmutablePropTypes.mapContains({
    name: PropTypes.string,
    full_name: PropTypes.string,
  }).isRequired,

  /**
   * The ID of the contact whose direct reports are being shown
   */
  contactId: PropTypes.number.isRequired,

  /**
   * The list of contact IDs of the direct reports of the contact.
   */
  directReports: ImmutablePropTypes.mapContains({
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),

  /**
   * True if the modal is open/displayed, false if it is closed.
   */
  isOpen: PropTypes.bool,

  /**
   * Called when the modal should be hidden (that is, `isOpen` should be changed to false)
   */
  onHide: PropTypes.func.isRequired,
};

DirectReportsManagementModal.defaultProps = {
  isOpen: true,
};

export default compose(
  setDisplayName('connect(DirectReportsManagementModal)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  mapContactIdToDirectReports,
)(DirectReportsManagementModal);

import { isValidEmailAddress } from 'modules/forms/validators/emailAddress';

/**
 * Validates a fieldState that contains an array of email addresses, where the email
 * address is provided on each item in the array as an `email` property, i.e.:
 * [{ email: 'me@thrivetrm.com' }, { email: 'foo@wherever.com' }]
 */
const emailAddressesValidator = fieldState => {
  const invalidEmails = fieldState
    .getValue()
    .map(({ email }) => email)
    .filter(email => !isValidEmailAddress(email));

  if (invalidEmails.length) {
    return `Invalid email addresses: ${invalidEmails.join(', ')}`;
  }

  return null;
};

export default emailAddressesValidator;

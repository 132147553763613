import shouldFetchEntity from 'modules/entities/selectors/shouldFetchEntity';
import getTenantId from 'modules/tenant/selectors/getTenantId';
import customerConfigurationSchema from '../schemas/customerConfiguration';

/**
 * Determines if the customer configuration needs to be fetched from the server for the current
 * tenant.
 */
export default state =>
  shouldFetchEntity(state, customerConfigurationSchema.key, getTenantId(state));

import { schema } from 'normalizr';
import { assessmentSchema } from 'modules/assessments/schema';
import commentSchema from 'modules/comments/commentSchema';
import introductionSchema from 'modules/introductions/schema';
import noteSchema from 'modules/notes/schema';
import { outreachSchema } from 'modules/outreaches/schema';
import referenceSchema from 'modules/references/schema';
import taskSchema from 'modules/tasks/schema';
import meetingSchema from 'modules/meetings/schema';
import interviewSchema from 'modules/interviews/schema';

const TYPE_MAP = {
  outreach: 'outreaches',
  recruiter_assessment: 'assessments',
  client_assessment: 'assessments',
  researcher_assessment: 'assessments',
  candidacy_comment: 'comments',
};

export const resourceSchema = new schema.Union(
  {
    assessments: assessmentSchema,
    comments: commentSchema,
    introductions: introductionSchema,
    interviews: interviewSchema,
    meetings: meetingSchema,
    notes: noteSchema,
    outreaches: outreachSchema,
    references: referenceSchema,
    tasks: taskSchema,
  },
  (input, parent) => TYPE_MAP[parent.type] || `${parent.type}s`,
);

export default new schema.Entity('notifications', {
  resource: resourceSchema,
});

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import CandidacyStatus from 'modules/candidacies/components/status/CandidacyStatus';
import { toggleCandidacySelection } from 'modules/candidacy-views/searchPage/candidacyBulkActionsSlice';
import CandidacyTagColorSquareListEditable from 'modules/candidacies/components/tags/CandidacyTagColorSquareListEditable';
import canUpdateCandidacyStage from 'modules/candidacies/selectors/canUpdateCandidacyStage';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import getCandidacyStatus from 'modules/candidacies/selectors/getCandidacyStatus';
import SearchCandidacyLink from 'modules/candidacies/components/SearchCandidacyLink';
import isClientUser from 'modules/auth/selectors/isClientUser';
import isClientNoCompUser from 'modules/auth/selectors/isClientNoCompUser';
import CandidacyListItemSectionIconStrip from './CandidacyListItemSectionIconStrip';
import CandidacyListItemSectionDetails from './CandidacyListItemSectionDetails';

/**
 * The "core" section of the candidacy list item.
 * This encapsultes the bulk of the card data, with the exception of the assessment addon:
 *
 * +----------+-----------+----------------------------------------------------------+
 * | Checkbox | iconStrip | content                                                  |
 * | (flipper |           | +------------------------------------------------------+ |
 * |dependent)|           | | details                                              | |
 * |          |           | |                                                      | |
 * |          |           | |                                                      | |
 * |          |           | |                                                      | |
 * |          |           | +------------------------------------------------------+ |
 * |          |           | +------------------------------------------------------+ |
 * |          |           | | Candidacy badge list (flipper dependant)             | |
 * |          |           | +------------------------------------------------------+ |
 * |          |           | | status                                               | |
 * |          |           | +------------------------------------------------------+ |
 * +----------+-----------+----------------------------------------------------------+
 */
const CandidacyListItemSectionCore = ({
  candidacyId,
  className,
  searchId,
  smallCard,
  ...props
}) => {
  const dispatch = useDispatch();
  const candidacy = useSelector(state =>
    getCandidacyStatus(state, candidacyId),
  )?.toJS();

  const hasBulkActionsEnabled = useFeatureCheck(
    'feature.bulk_candidate_actions',
  );

  const canChangeCandidacyStages = useSelector(canUpdateCandidacyStage);

  const isCandidacyChecked = useSelector(state =>
    state.candidacyBulkActions.selectedCandidacyIds.includes(candidacyId),
  );

  const searchType = useSelector(state => getSearchType(state, searchId));

  const hasCandidateMetadataFeature = useFeatureCheck(
    'feature.display_candidate_metadata',
  );

  const canViewOutreachInfo = useSelector(
    state =>
      !isClientUser(state) &&
      !isClientNoCompUser(state) &&
      hasCandidateMetadataFeature,
  );

  return (
    <div
      className={classnames(className, 'CandidacyListItemSectionCore')}
      {...props}
    >
      {hasBulkActionsEnabled && canChangeCandidacyStages ? (
        <Checkbox
          className='u-marginHorizontal-8 u-marginTop-8'
          isChecked={isCandidacyChecked}
          onChange={() => {
            dispatch(toggleCandidacySelection(candidacyId));
          }}
        />
      ) : null}
      <CandidacyListItemSectionIconStrip
        candidacyId={candidacyId}
        className={classnames('CandidacyListItemSectionCore__iconStrip', {
          CandidacyListItemSmallSectionDetails__iconStrip: smallCard,
        })}
        smallCard={smallCard}
      />
      <div
        className={classnames(
          className,
          'CandidacyListItemSectionCore__content',
        )}
      >
        <CandidacyListItemSectionDetails
          candidacyId={candidacyId}
          className='CandidacyListItemSectionCore__details'
          searchId={searchId}
          smallCard={smallCard}
        />

        {!smallCard && searchType === TYPE_PIPELINE ? (
          <CandidacyTagColorSquareListEditable
            candidacyId={candidacyId}
            searchId={searchId}
            tagButtonClassName='u-paddingHorizontal-4 u-padding-n'
          />
        ) : null}

        {!smallCard && (
          <div className='CandidacyListItemSectionCore__status'>
            <CandidacyStatus candidacyId={candidacyId} />
            <div className='CandidacyStatusContainer'>
              {candidacy?.outreach_message && canViewOutreachInfo ? (
                <SearchCandidacyLink candidacyId={candidacyId} tab='outreach'>
                  {candidacy.outreach_message}
                </SearchCandidacyLink>
              ) : null}
              {candidacy?.description &&
              candidacy?.candidacy_status &&
              canViewOutreachInfo ? (
                <div>
                  <SearchCandidacyLink candidacyId={candidacyId} tab='overview'>
                    {candidacy.candidacy_status}
                  </SearchCandidacyLink>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CandidacyListItemSectionCore.propTypes = {
  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * An optional class name to apply to the container div
   */
  className: PropTypes.string,

  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,
  /**
   * Weather or not to show content on the 'small'
   * or 'large' candidacy card.
   */
  smallCard: PropTypes.bool,
};

export default CandidacyListItemSectionCore;

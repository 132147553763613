import paramsEqual from 'modules/routing/selectors/paramsEqual';
import getBulkImportList from './getBulkImportList';
import { FETCH_PARAMS } from '../constants';

/**
 * Determines if the list of bulk imports for the current tenant should be fetched from the server.
 */
export default (state, props) => {
  const bulkImportList = getBulkImportList(state);

  if (!bulkImportList) {
    return false;
  }

  const isFetching = bulkImportList.getIn(['_meta', 'isFetching']);
  const hasError = Boolean(bulkImportList.getIn(['_meta', 'error']));
  if (isFetching || hasError) {
    return false;
  }

  const hasData = bulkImportList.has('ids');
  const isInvalidated = bulkImportList.getIn(['_meta', 'isInvalidated']);
  const params = bulkImportList.get('params');
  const haveDisplayPreferencesChanged = !paramsEqual(
    params && params.toJS(),
    props,
    FETCH_PARAMS,
  );

  return !hasData || isInvalidated || haveDisplayPreferencesChanged;
};

import getCompanyProperty from './getCompanyProperty';

/**
 * Gets the off limits state of a company
 * @param {*} state
 * @param {Number} companyId the company's ID
 * @returns {?Immutable.Map} The off limits status of the company which, if it is defined, typically
 *   includes one or more of the following properties:
 *   `category`, `notes`, and/or `status`
 */
export default (state, companyId) =>
  getCompanyProperty(state, companyId, 'off_limits');

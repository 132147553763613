import getTargetCompanyCommentList from './getTargetCompanyCommentList';

/**
 * Determines if a list of target company comments should be fetched.
 * @param {Object} state
 * @param {Number} targetCompanyId The target company whose comments we may want to fetch.
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default (state, targetCompanyId) => {
  if (!targetCompanyId) {
    return false;
  }

  const commentList = getTargetCompanyCommentList(state, targetCompanyId);

  if (!commentList) {
    return true;
  }

  if (commentList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (commentList.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !commentList.has('ids') ||
    Boolean(commentList.getIn(['_meta', 'isInvalidated']))
  );
};

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, setPropTypes, mapProps } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import AppointmentView from '../../appointments/components/AppointmentView';
import mapMeetingIdToMeeting from './mapMeetingIdToMeeting';
import fetchMeetingsIfNeeded from './fetchMeetingsIfNeeded';

/**
 * Renders a single meeting record.
 */
export default compose(
  // Either `meeting` or `meetingId` is required. If only an `meetingId`
  // is specified, the meeting will be fetched from state.
  setPropTypes({
    meeting: requiredIf(
      ImmutablePropTypes.mapContains({
        id: PropTypes.number.isRequired,
      }),
      ({ meetingId }) => !meetingId,
    ),
    meetingId: requiredIf(PropTypes.number, ({ meeting }) => !meeting),
  }),
  fetchMeetingsIfNeeded,
  branch(props => props.meetingId && !props.meeting, mapMeetingIdToMeeting),
  mapProps(({ meeting, ...props }) => ({
    appointment: meeting,
    ...props,
  })),
)(AppointmentView);

import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import ContactName from 'modules/contacts/components/ContactName';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import getContactProperty from 'modules/contacts/selectors/contacts/getContactProperty';
import { CONNECTION_TYPE_GROUP_WITH_NAME } from 'services/apiV1/connections';
import mapReferenceIdToReference from './mapReferenceIdToReference';

/**
 * Renders the view mode of a reference item.
 */
/* eslint-disable react/no-danger */

const description = reference => reference.get('description');
const creatorName = reference =>
  reference.getIn(['creator', 'full_name_or_email']);

const ReferenceView = ({
  contactId,
  linkToContactPage,
  reference,
  showCreatedBy,
}) => {
  const hasConnectionsFeature = useFeatureCheck('feature.connections_gate');
  const hasReferencesConnectionsFeature = useFeatureCheck(
    'feature.references_connections',
  );
  const contactName = useSelector(
    state =>
      getContactProperty(state, contactId, 'first_name') ||
      getContactProperty(state, contactId, 'full_name'),
  );
  return (
    <div className='reference-item reference-view'>
      <div className='field-name title'>
        {reference.get('referee') ? (
          // The name field has been deprecated in favor of the "referee" field --
          // only one or the other should be specified, so if the referee contact exists,
          // we render a link to the referee's profile, otherwise fallback to the legacy name field.
          // user must be full access to see the link, controlled by `employeeUser` prop
          <ContactName
            contactId={reference.get('referee')}
            linkToContactPage={linkToContactPage}
          />
        ) : (
          reference.get('name')
        )}
      </div>
      <div className='field-relationship'>
        {hasConnectionsFeature && hasReferencesConnectionsFeature
          ? reference.getIn(['connection', 'name'])
          : reference.get('relationship')}
        {hasConnectionsFeature &&
        CONNECTION_TYPE_GROUP_WITH_NAME.includes(
          reference.getIn(['connection', 'group']) || '',
        )
          ? ` ${contactName}`
          : null}
        {reference.get('company') ? (
          <span>
            {' at '}
            {reference.get('company')}
          </span>
        ) : null}
      </div>
      <div className='field-email'>{reference.get('email')}</div>
      <div className='field-phone'>{reference.get('phone')}</div>
      {description(reference) && (
        <ExpandableText
          characterLimit={150}
          content={description(reference)}
          isSanitizedHtml={true}
        />
      )}
      {showCreatedBy && creatorName(reference) && (
        <div className='field-created-by' key='created-by'>
          Added by: {creatorName(reference)}
        </div>
      )}
    </div>
  );
};

ReferenceView.propTypes = {
  contactId: PropTypes.number,
  linkToContactPage: PropTypes.bool.isRequired,
  /**
   * The reference to render.
   */
  reference: ImmutablePropTypes.mapContains({
    name: PropTypes.string,
    relationship: PropTypes.string.isRequired,
    company: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    description: PropTypes.string,
    referee: PropTypes.number,
  }).isRequired,

  showCreatedBy: PropTypes.bool,
};

ReferenceView.defaultProps = {
  showCreatedBy: false,
};

/**
 * The default export requires only a `referenceId`, while
 * the named export requires the reference record.
 */
export default compose(
  setDisplayName('ReferenceView(enhanced)'),
  setPropTypes({
    referenceId: PropTypes.number.isRequired,
    showCreatedBy: PropTypes.bool,
  }),
  mapReferenceIdToReference,
  connect(
    state => ({
      linkToContactPage: isEmployeeUser(state),
    }),
    {},
  ),
)(ReferenceView);

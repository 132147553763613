import { compose, lifecycle, setDisplayName, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import shouldFetchEmailTemplatesListSelector from '../selectors/shouldFetchEmailTemplatesList';
import getEmailTemplatesListSelector from '../selectors/getEmailTemplatesList';
import fetchEmailTemplatesListActionCreator from '../actions/fetchEmailTemplatesList';

export default compose(
  setDisplayName('withEmailTemplatesListFetched'),
  connect(
    (state, props) => ({
      shouldFetchEmailTemplatesList: shouldFetchEmailTemplatesListSelector(
        state,
        props,
      ),
      emailTemplatesList: getEmailTemplatesListSelector(state),
    }),
    dispatch => ({
      fetchEmailTemplatesList: bindActionCreators(
        fetchEmailTemplatesListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchEmailTemplatesList: ({ fetchEmailTemplatesList, ...props }) => () =>
      fetchEmailTemplatesList(props),
  }),
  withHandlers({
    fetchEmailTemplatesListIfNeeded: ({
      fetchEmailTemplatesList,
      shouldFetchEmailTemplatesList,
    }) => () => {
      if (shouldFetchEmailTemplatesList) {
        fetchEmailTemplatesList();
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchEmailTemplatesListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchEmailTemplatesListIfNeeded();
    },
  }),
);

import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import CompanyName from 'modules/companies/components/CompanyName';
import mapTargetCompanyIdToCompanyId from './mapTargetCompanyIdToCompanyId';

export default compose(
  setDisplayName('TargetCompanyName'),
  setPropTypes({
    targetCompanyId: PropTypes.number.isRequired,
  }),
  mapTargetCompanyIdToCompanyId,
)(CompanyName);

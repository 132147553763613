import { connect } from 'react-redux';
import getCandidacyJobApplicationId from '../selectors/getCandidacyJobApplicationId';

/**
 * A higher order component which maps a `candidacyId` prop value to it's job application ID.
 */
export default connect(
  (state, { candidacyId }) => ({
    jobApplicationId: getCandidacyJobApplicationId(state, candidacyId),
  }),
  {},
);

import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { capitalize } from '@thrivetrm/ui/utilities/stringUtils';
import Modal from '@thrivetrm/ui/components/Modal';
import { useCreateDocumentMutation } from 'services/apiV1/documents';
import {
  toastSuccess,
  toastError,
} from 'modules/toast-notifications/toastNotificationsSlice';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import ContactInfoCard from './components/ContactInfoCard';
import DocumentList from './components/DocumentList';
import DocumentTypeSelect from './components/DocumentTypeSelect';
import { OwnerTypes } from './documentShape';
import './DocumentEditor.scss';

const DocumentEditor = ({
  company,
  contact,
  documentTypes,
  isOpen,
  location,
  onClose,
  ownerId,
  ownerType,
}) => {
  const dispatch = useDispatch();
  const inputId = useRef(uniqueId('input'));
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState(null);
  const [createDocument] = useCreateDocumentMutation();

  const handleFileInput = event => {
    if (selectedDocumentTypeId) {
      const file = event.target.files[0];

      createDocument({
        recordId: ownerId,
        recordType: pluralize(ownerType),
        file: file,
        documentTypeId: selectedDocumentTypeId,
      })
        .unwrap()
        .then(() => {
          setSelectedDocumentTypeId(null);
          dispatch(toastSuccess('Successfully added document'));
        })
        .catch(error => {
          if (
            error?.data?.errors?.file &&
            error.data.errors.file[0] === 'size must be less than 7.5 MB'
          ) {
            dispatch(toastError('File size must be less than 7.5 MB'));
          } else {
            dispatch(toastError('An error has occurred!'));
          }
        });
    }
    // reset file input
    event.target.value = null;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='large'
      title={`${capitalize(ownerType)} Document`}
    >
      <Modal.Body>
        <ContactInfoCard
          company={company}
          contact={contact}
          location={location}
        />
        <h3>Upload a Document</h3>
        <div className='u-flex'>
          <DocumentTypeSelect
            documentTypes={documentTypes}
            isPinnedBottom={true}
            onChange={setSelectedDocumentTypeId}
            ownerType={ownerType}
            value={selectedDocumentTypeId}
          />
          <div className='u-marginLeft-12'>
            <label htmlFor={inputId.current}>File</label>
            <input
              // TODO: turn this accept list into a prop for future needs, if needed
              accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
              data-testid='file-upload'
              disabled={!selectedDocumentTypeId}
              id={inputId.current}
              name='documentUploader'
              onChange={handleFileInput}
              type='file'
            />
          </div>
        </div>
        <DocumentList
          documentTypes={documentTypes}
          ownerId={ownerId}
          ownerType={ownerType}
        />
      </Modal.Body>
    </Modal>
  );
};

DocumentEditor.propTypes = {
  company: PropTypes.shape({
    avatarUrl: PropTypes.string,
    primaryCompanyName: PropTypes.string,
    searchTitle: PropTypes.string,
  }),
  contact: PropTypes.shape({
    avatarUrl: PropTypes.string,
    fullName: PropTypes.string,
    primaryPositionName: PropTypes.string,
  }),
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      interim: PropTypes.bool,
      name: PropTypes.string,
    }),
  ),
  isOpen: PropTypes.bool,
  location: PropTypes.string,
  onClose: PropTypes.func,
  ownerId: PropTypes.number.isRequired,
  ownerType: PropTypes.oneOf(OwnerTypes).isRequired,
};

export default DocumentEditor;

import { branch, compose, lifecycle } from 'recompose';
import connectContactActions from '../connectContactActions';

/**
 * When provided a contactId, fetches the direct reports whenever that prop value changes.
 * @param {String[]} [fields] An optional array of additional fields to request.
 */
export default ({ fields }) =>
  compose(
    branch(({ contactActions }) => !contactActions, connectContactActions),
    lifecycle({
      UNSAFE_componentWillMount: function () {
        const { contactActions, contactId } = this.props;
        if (contactId) {
          contactActions.fetchDirectReports({
            contactId: contactId,
            fields: fields,
          });
        }
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        const { contactActions, contactId } = nextProps;
        if (contactId && contactId !== this.props.contactId) {
          contactActions.fetchDirectReports({
            contactId: contactId,
            fields: fields,
          });
        }
      },
    }),
  );

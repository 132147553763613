import React from 'react';
import PropTypes from 'prop-types';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';

const DocumentTypeSelect = ({
  className,
  documentTypes,
  isPinnedBottom,
  onChange,
  value,
}) => {
  return (
    <SelectMenu
      className={className}
      inputWidth='large'
      isPinnedBottom={isPinnedBottom}
      label='Document Type'
      onChange={onChange}
      placeholder='Select document type...'
      value={value}
    >
      {documentTypes?.map(type => (
        <SelectMenu.Item key={type.id} value={type.id}>
          {type.name}
        </SelectMenu.Item>
      ))}
    </SelectMenu>
  );
};

DocumentTypeSelect.propTypes = {
  className: PropTypes.string,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      interim: PropTypes.bool,
      name: PropTypes.string,
    }),
  ),
  isPinnedBottom: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

DocumentTypeSelect.defaultProps = {
  isPinnedBottom: false,
};

export default DocumentTypeSelect;

import getEntity from 'modules/entities/selectors/getEntity';
import { contactMethodSchema } from '../schema';

/**
 * Gets a contact method record by it's ID.
 * @param {Object} state
 * @param {Number} id The contact method ID.
 * @return {Immutable.Map|undefined} The contact method record, or undefined if not found or not
 *   fetched.
 */
export default (state, id) => getEntity(state, contactMethodSchema.key, id);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { compose, lifecycle, setDisplayName, setPropTypes } from 'recompose';
import { connect } from 'react-redux';
import pluralize from 'pluralize';

import getQuery from '../selectors/contacts/getQuery';
import ContactDuplicate from './ContactDuplicate';

export const ContactDuplicates = ({
  className,
  contactIds,
  fieldType,
  isQuerying,
  ...props
}) =>
  contactIds && contactIds.size ? (
    <div className={classnames('ContactDuplicates', 'alert', className)}>
      {isQuerying && (
        <i className='ContactDuplicates__query-indicator fa fa-spinner fa-spin' />
      )}
      <div key='title'>
        Hold on! We found {pluralize('contact', contactIds.size, true)}
        &nbsp;associated with the same {fieldType}:
      </div>
      <ul className='ContactDuplicates__list list-unstyled'>
        {contactIds
          .map(contactId => (
            <li className='ContactDuplicates__list-item' key={contactId}>
              <ContactDuplicate contactId={contactId} {...props} />
            </li>
          ))
          .toArray()}
      </ul>
    </div>
  ) : null;

ContactDuplicates.propTypes = {
  /**
   * The class name to apply to the container
   */
  className: PropTypes.string,

  /**
   * The IDs of the possible duplicate contacts
   */
  contactIds: ImmutablePropTypes.listOf(PropTypes.number),

  /**
   * The name of the field type we're showing duplicates for; i.e.: 'name', 'email', 'work email'
   */
  fieldType: PropTypes.string.isRequired,

  /**
   * Whether a duplicate query is currently pending.
   */
  isQuerying: PropTypes.bool,
};

ContactDuplicates.defaultProps = {
  className: 'alert-info',
  isQuerying: false,
};

export default compose(
  setDisplayName('ContactDuplicates(enhanced)'),
  setPropTypes({
    /**
     * Called the matches change, with the array of matching contact IDs (which may be empty if
     * there are no matches)
     */
    onMatch: PropTypes.func,

    /**
     * The query ID that is being used to search for matches.
     */
    queryId: PropTypes.string.isRequired,
  }),
  connect((state, { queryId }) => {
    const query = getQuery(state, queryId);
    return {
      isQuerying: query && query.getIn(['meta', 'isFetching']),
      contactIds: query && query.get('data') && query.get('data'),
    };
  }, {}),
  lifecycle({
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      // Call onMatch whwnever we get a new query response.
      if (nextProps.onMatch && nextProps.contactIds !== this.props.contactIds) {
        nextProps.onMatch(nextProps.contactIds);
      }
    },
  }),
)(ContactDuplicates);

import { compose } from 'recompose';
import { fromJS } from 'immutable';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import ContactRatingField from './ContactRatingField';
import connectRatingActions from './connectRatingActions';
import mapContactIdToRatings from './mapContactIdToRatings';
import mapRatingIdToRating from './mapRatingIdToRating';
import withTransactionTrackedForm from '../../../../components/forms/withTransactionTrackedForm';

const createRootFieldState = ({ contactId, includeAttributedTo, rating }) =>
  ContactRatingField.createFieldState('rating', {
    rating: rating,
    contactId: contactId,
    includeAttributedTo: includeAttributedTo,
  });

const handleSubmit = (formState, props) => {
  const {
    contactId,
    rating,
    ratingActions: { createRating, updateRating },
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (rating || fromJS({ contact_id: contactId })).merge(
    fieldValue,
  );

  let action = createRating;

  action = rating && rating.get('id') ? updateRating : createRating;

  action({
    rating: {
      ...values.toJS(),
      contact_id: contactId,
    },
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export const omitProps = [
  'btnClassName',
  'contact',
  'contactId',
  'ratingActions',
  'rating',
  'ratingId',
  'ratingIds',
  'ratings',
  'transactionActions',
];

const ContactRatingForm = compose(
  connectTransactions,
  connectRatingActions,
  mapContactIdToRatings,
  mapRatingIdToRating,
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'ContactRatingForm',
    title: 'Ratings',
    omitProps: omitProps,
  }),
)(ContactRatingField);

export default ContactRatingForm;

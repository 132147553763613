import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import routes from 'modules/routing/routes';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import isAdmin from 'modules/auth/selectors/isAdmin';
import { relativeDate } from '@thrivetrm/ui/utilities/dateTimeUtils';
import { useSelector } from 'react-redux';
import { useApiGet } from 'modules/core/hooks/useApi';
import getName from 'modules/contacts/selectors/contacts/getName';
import Card from '@thrivetrm/ui/components/Card';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import classnames from 'classnames';
import moment from 'moment-timezone';

const CandidacySearchSidebarEvents = ({ contactId }) => {
  const [loadEvents, isLoadingEvents, eventsError, events] = useApiGet(
    routes.api_v1_contact_events({ contact_id: contactId }),
  );

  useEffect(() => {
    loadEvents();
  }, [loadEvents, contactId]);

  const isAdminUser = useSelector(isAdmin);
  const userName = useSelector(state => getName(state, contactId));
  const eventsList = events?.events || [];

  const headingClasses = 'u-marginTop-12 u-marginBottom-n';
  const valueClasses = 'u-fontSize-medium u-textColor-gray60';

  const renderEventsList = (event, index) => {
    const eventTimezone = event.timezone;
    // These strings will convert to the timezone coming from the eventData object,
    // and will not change based on the timezone of the browser
    const startTimeMoment = moment(event.start_time)
      .tz(eventTimezone)
      .format('MMM D, YYYY [at] h:mma');
    const endTimeMoment = moment(event.end_time)
      .tz(eventTimezone)
      .format('MMM D, YYYY [at] h:mma z');

    return (
      <Card
        className={classnames('u-marginVertical-16', {
          'u-borderBottom': index !== eventsList.length - 1,
        })}
        isCentered={false}
        key={event.id}
      >
        <h4 className={headingClasses}>
          {userName} was invited to{' '}
          {isAdminUser ? (
            <a
              href={`/admin/events/${event.id}`}
              rel='noopener noreferrer'
              target='_blank'
            >
              {event.name}
            </a>
          ) : (
            event.name
          )}
        </h4>
        <p className='u-fontSize-small u-textColor-gray40'>
          {relativeDate(event.event_contact_created_on).toUpperCase()}
        </p>

        <h4 className={headingClasses}>Event Date:</h4>
        <span className={valueClasses}>
          {startTimeMoment} - {endTimeMoment}
        </span>

        {event.role ? (
          <>
            <h4 className={headingClasses}>Role:</h4>
            <span className={valueClasses}>{event.role}</span>
          </>
        ) : null}

        <h4 className={headingClasses}>Status:</h4>
        <span className={valueClasses}>{event.status}</span>

        <h4 className={headingClasses}>Event Description:</h4>
        <ExpandableText
          characterLimit={150}
          className='u-fontSize-medium u-textColor-gray60'
          content={event.description}
        />

        {event.event_contact_notes ? (
          <>
            <h4 className={headingClasses}>Guest Notes:</h4>
            <ExpandableText
              characterLimit={150}
              className='u-fontSize-medium u-textColor-gray60'
              content={event.event_contact_notes}
            />
          </>
        ) : null}
      </Card>
    );
  };

  return (
    <>
      {/* list state */}
      {!isLoadingEvents && !eventsError && eventsList.length
        ? eventsList.map((event, index) => renderEventsList(event, index))
        : null}

      {/* empty state */}
      {!isLoadingEvents && !eventsError && !eventsList.length ? (
        <div className='u-textAlign-c u-backgroundColor-gray20 u-paddingVertical-64'>
          <img alt='No upcoming events' src='/no_calendar_data.svg' />
          <h2 className='u-marginTop-8'>No Upcoming Events</h2>
        </div>
      ) : null}

      {/* error state */}
      {!isLoadingEvents && eventsError ? (
        <Card isCentered={false} type='error'>
          <p>There was an error fetching events</p>
          <ButtonPrimary label='Retry' onClick={loadEvents} />
        </Card>
      ) : null}

      {/* loading state */}
      {isLoadingEvents ? <LoadingIndicator /> : null}
    </>
  );
};

CandidacySearchSidebarEvents.propTypes = {
  contactId: PropTypes.number.isRequired,
};

export default CandidacySearchSidebarEvents;

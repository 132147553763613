import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import {
  TYPE_CLIENT,
  TYPE_RECRUITER,
  TYPE_RESEARCHER,
} from 'modules/assessments/constants';
import mapAssessmentPermissions from './mapAssessmentPermissions';

export const canCreateAssessment = ({
  assessments,
  canAddClientAssessment,
  canAddManyClientAssessments,
  canEditRecruiterAssessment,
  currentUserId,
  type,
}) => {
  if (type === TYPE_CLIENT && canAddManyClientAssessments) {
    // No restrictions, and no need to actually load the assessments before determining
    // whether the user can add more.
    return true;
  }

  if (!assessments) {
    // They haven't been fetched yet for the type in question, and we need to know about any
    // existing assessments before we can determine whether any may be added.
    return false;
  }

  if (type === TYPE_RECRUITER) {
    // We can only have a single recruiter assessment, so if one exists we can't create any more.
    return (
      canEditRecruiterAssessment &&
      !assessments.find(
        assessment => assessment && assessment.get('type') === TYPE_RECRUITER,
      )
    );
  }

  if (type === TYPE_RESEARCHER) {
    // We can only have a single researcher assessment, so if one exists we can't create any more.
    return !assessments.find(
      assessment => assessment && assessment.get('type') === TYPE_RESEARCHER,
    );
  }

  if (canAddClientAssessment) {
    // At this point an assessment (TYPE_CLIENT) can only be added if the current user hasn't
    // already created one for this candidacy.
    return !assessments.find(
      assessment =>
        assessment && assessment.get('updated_by_id') === currentUserId,
    );
  }

  return false;
};

/**
 * A higher-order component that provides a `canCreateAssessment` prop for
 * a particular candidacy and assessment type.
 */
export default compose(
  setDisplayName('withCanCreateAssessment'),
  setPropTypes({
    /**
     * The actual assessment reecords for the type provided. The records themselves
     * are needed when type is TYPE_CLIENT if the current user is only allowed
     * to add a single assessment per candidacy.
     */
    assessments: ImmutablePropTypes.listOf(
      ImmutablePropTypes.mapContains({
        type: PropTypes.oneOf([TYPE_CLIENT, TYPE_RECRUITER, TYPE_RESEARCHER]),
        updated_by_id: PropTypes.number,
      }),
    ),

    type: PropTypes.oneOf([TYPE_CLIENT, TYPE_RECRUITER, TYPE_RESEARCHER])
      .isRequired,
  }),
  mapAssessmentPermissions,
  withProps(props => ({ canCreateAssessment: canCreateAssessment(props) })),
);

import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import connectCandidacyActions from 'modules/candidacies/components/connectCandidacyActions';
import Modal from 'modules/core/componentsLegacy/Modal';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import routes from 'modules/routing/routes';
import { SEARCH_TYPE_LABELS } from 'modules/searches/constants';
import BasicForm, { createRootFieldState } from './basic/BasicForm';
import ResumeCreateForm from './resume/ResumeCreateForm';

class NewContactButton extends Component {
  duplicateDetectionId = uniqueId();

  state = {
    isOpen: false,
    // When the form is closed using the "Cancel" button, the state is retained so it can
    // be restored when the form is reopened.
    lastFormState: null,
  };

  toggleOpen = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  /**
   * Called when a contact has been successfully saved using the basic contact entry form.
   */
  handleSaved = payload => {
    const { network, onCreated, search } = this.props;
    if (onCreated) {
      onCreated(payload.data, search, network);
    }

    this.resetFormState();
  };

  resetFormState = () => {
    // Make sure to clear out the form state so if the form is shown again it is empty.
    this.setState({
      fieldValues: null,
      isOpen: false,
      lastFormState: null,
    });
  };

  handleDuplicateSubmit = ({ contactId, networkId, searchId }) => {
    const {
      candidacyActions,
      handleDupAddedRedirect,
      networkActions,
      search,
    } = this.props;

    if (networkId) {
      networkActions.addContact({ contactId: contactId, networkId: networkId });
    }
    if (searchId) {
      candidacyActions.createCandidacy({
        contactId: contactId,
        searchId: searchId,
      });
    }

    if (handleDupAddedRedirect) {
      handleDupAddedRedirect({ networkId: networkId, search: search });
    }

    this.resetFormState();
  };

  /**
   * Called when the "Cancel" button is clicked to hide the form.
   */
  handleCancel = () => {
    // Unfortunately, we don't have access to the formState, so we can't save it for restoring
    // it in this case. However, we still need to clear out the fieldValues in case the form
    // was opened with some initial value
    this.resetFormState();
  };

  /**
   * Called when the "X" button in the upper-right corner of the modal is clicked to hide the
   * form.
   */
  handleHideModal = () => {
    // Unfortunately we don't have access to the formState and so we can't save it for restoring
    // it in this case. But we still need to clear out the fieldValues in case the form was
    // opened with some initial values.
    this.setState({
      fieldValues: null,
      isOpen: false,
      parser: null,
    });
  };

  /**
   * Called when the user selects the upload Sovren parser.
   */
  handleChooseSovren = () => {
    this.setState({ parser: 'sovren' });
  };

  /**
   * Called when the form is currently displaying the resume drop/upload form, and the
   * user clicks cancel. This resets the form to the "basic" contact form mode.
   */
  handleResetParserChoice = () => {
    this.setState({ parser: null });
  };

  render() {
    const {
      allowUpload,
      children,
      className,
      contactActions,
      contacts,
      handleUploadRedirect,
      icon,
      isAdmin,
      network,
      search,
    } = this.props;

    const { fieldValues, isOpen, lastFormState, parser } = this.state;

    // If we need to restore the form state, or pre-initialize the form with existing values
    // (i.e. show() has been called with fieldValues to preload), we need to pass these additional
    // values to the form. But we only want to pass them when provided, otherwise we may
    // unintentionally override passed in props by setting them to null.
    const formProps = {};
    if (fieldValues) {
      formProps.initialFieldState = createRootFieldState(fieldValues);
    } else if (lastFormState) {
      formProps.initialFormState = lastFormState;
    }

    return (
      <div className={className}>
        <ButtonPrimary icon={icon} label={children} onClick={this.toggleOpen} />
        {isOpen && (
          <Modal onHide={this.handleHideModal} show={isOpen}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Create Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {[
                [SEARCH_TYPE_LABELS[search?.type], search],
                ['network', network],
              ]
                .filter(([_, record]) => Boolean(record))
                .map(([label, record]) => (
                  <p className='alert alert-info' key={label}>
                    <strong>
                      {'Adding to '}
                      {label}:
                    </strong>
                    <br />
                    {record.name}
                  </p>
                ))}

              {parser ? (
                <ResumeCreateForm
                  {...this.props}
                  formActionProps={{
                    submitLabel: 'Create Contact',
                  }}
                  multiple={true}
                  onCancel={this.handleResetParserChoice}
                  onSaved={handleUploadRedirect}
                  parser={parser}
                />
              ) : (
                <div>
                  <BasicForm
                    {...this.props}
                    {...formProps}
                    contactActions={contactActions}
                    contacts={contacts}
                    duplicateDetectionId={this.duplicateDetectionId}
                    formActionProps={{
                      submitLabel: 'Create Contact',
                    }}
                    handleDuplicateSubmit={this.handleDuplicateSubmit}
                    isEditingContact={false}
                    network={network}
                    onCancel={this.handleCancel}
                    onSaved={this.handleSaved}
                    search={search}
                    showAddButton={Boolean(search || network)}
                    showAliasesField={false}
                    showDuplicates={true}
                    showOffLimitsOptionsFields={false}
                    showPreferredNameField={false}
                    showPronounsField={false}
                  />

                  {allowUpload && (
                    <div className='contact-resume-uploader-select text-center'>
                      <div className='new-contact-modal-line'>
                        <span className='new-contact-modal-text'>OR</span>
                      </div>
                      <button
                        className='btn btn-default'
                        onClick={this.handleChooseSovren}
                        type='button'
                      >
                        <i className='fa fa-file-text' /> Create from Resume or
                        Linkedin PDF
                      </button>
                    </div>
                  )}
                </div>
              )}
              {isAdmin ? (
                <div className='u-textAlign-c u-marginVertical-16'>
                  Creating a bunch of contacts? Try{' '}
                  <a href={routes.bulk_import()}>Bulk Import</a>
                </div>
              ) : null}
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

NewContactButton.propTypes = {
  /**
   * When true, contacts can be created by uploading a resume. Note that this will cause a page
   * redirect.
   */
  allowUpload: PropTypes.bool,

  candidacyActions: PropTypes.shape({
    createCandidacy: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * The content displayed in the button. By default a plus sign with the text "New Contact" is
   * used.
   */
  children: PropTypes.node,

  /**
   * The className to apply to the outer component wrapper.
   */
  className: PropTypes.string,

  contactActions: PropTypes.shape({
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    queryContacts: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * BasicField requires the entire contact state, thus it is getting passed through
   * See {@link duplicateRecognition.js}
   */
  contacts: ImmutablePropTypes.mapContains({
    queriesById: ImmutablePropTypes.map.isRequired,
  }).isRequired,

  /**
   * Called when a duplicate contact is added to a search or network from the
   * new contact modal.
   */
  handleDupAddedRedirect: PropTypes.func,

  /**
   * Called when a contact review is created through the resume uploader.
   */
  handleUploadRedirect: requiredIf(PropTypes.func, props => props.allowUpload),

  /**
   * Icon name
   */
  icon: PropTypes.string,

  /**
   * True when user has admin privileges
   */
  isAdmin: PropTypes.bool,

  /**
   * Used to add the contact to a network, after the contact has been created.
   * This prop is used dynamically, hence the disabling of eslint.
   */
  /* eslint-disable react/no-unused-prop-types */
  network: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  /* eslint-enable react/no-unused-prop-types */

  networkActions: PropTypes.shape({
    addContact: PropTypes.func,
  }),

  /**
   * Called when a new contact is created using this button. Note that this will not be called
   * when a contact is created using a resume upload!
   */
  onCreated: PropTypes.func,

  /**
   * Used to add the contact to a search, after the contact has been created.
   * This prop is used dynamically, hence the disabling of eslint.
   */
  /* eslint-disable react/no-unused-prop-types */
  search: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  /* eslint-enable react/no-unused-prop-types */
};

NewContactButton.defaultProps = {
  allowUpload: true,
  children: 'New Contact',
  icon: 'person',
  isAdmin: false,
};

export default compose(
  setDisplayName('NewContactButton(enhanced)'),
  connectCandidacyActions,
  connect(
    state => ({
      isAdmin: state.user.get('admin'),
    }),
    null,
  ),
)(NewContactButton);

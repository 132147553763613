import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
  withStateHandlers,
} from 'recompose';
import mapCandidacyIdToCandidacy from 'modules/candidacies/components/mapCandidacyIdToCandidacy';
import OutreachEmailForm from './OutreachEmailForm';
import withFormToggleButton from '../../../components/forms/withFormToggleButton';

const AddOutreachEmailButton = withFormToggleButton(OutreachEmailForm);

/**
 * Renders a panel for sending an outreach "email".
 */
const CandidacyOutreachEmailPanel = ({
  candidacyId,
  contactId,
  onFormSaved,
  onFormToggle,
  searchId,
  wasSubmitted,
}) => (
  <div className='outreaches--candidacy-outreach-email-panel'>
    {wasSubmitted && (
      <div className='alert alert-success'>
        Your message has been sent. An outreach activity has been logged in
        Thrive.
      </div>
    )}
    <AddOutreachEmailButton
      contactId={contactId}
      draftStoragePath={{
        contactId: contactId,
        searchId: searchId,
        candidacyId: candidacyId,
        outreachEmail: 'new',
      }}
      fieldLayout='narrow'
      formActionProps={{
        submitLabel: 'Send Email',
      }}
      initiallyOpenForm={true}
      onSaved={onFormSaved}
      onToggle={onFormToggle}
      searchId={searchId}
    >
      {wasSubmitted ? 'Send another email' : 'Send an Email'}
    </AddOutreachEmailButton>
  </div>
);

CandidacyOutreachEmailPanel.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,

  /**
   * Called when the underlying form has been successfully submitted.
   */
  onFormSaved: PropTypes.func.isRequired,

  /**
   * Called when the form toggle button is clicked to show or hide the email form.
   */
  onFormToggle: PropTypes.func.isRequired,

  searchId: PropTypes.number.isRequired,

  /**
   * Whether an email has been successfully sent yet in the lifetime of this component.
   */
  wasSubmitted: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('CandidacyOutreachEmailPanel(enhanced)'),

  // The connected version of this component only requires a candidacyId,
  // everything else will be fetched from state.
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),

  // Get the contactId for the candidacy supplied, because we're actually loading all of the
  // contact's outreaches.
  mapCandidacyIdToCandidacy,

  // Keep track of whether an email has been sent or not (so we know when to display the
  // success message). When the form is re-opened, we reset it to clear out the message.
  withStateHandlers(
    { wasSubmitted: false },
    {
      onFormSaved: () => () => ({ wasSubmitted: true }),
      onFormToggle: () => () => ({
        wasSubmitted: false,
      }),
    },
  ),

  // Add a prop so we can manually trigger refreshes if desired.
  withProps(({ candidacy }) => ({
    contactId: candidacy && candidacy.get('contact'),
    searchId: candidacy && candidacy.get('search_id'),
  })),
)(CandidacyOutreachEmailPanel);

import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'modules/datetime/components/DateTime';
import Icon from '@thrivetrm/ui/components/Icon';
import ExpandableActivityView from './ExpandableActivityView';

const TaskActivityView = ({ assignedTo, notes, reminder, subject }) => (
  <div className='u-marginTop-4 u-fontSize-small'>
    <strong>{subject}</strong>
    {assignedTo ? (
      <div>
        <span>Assigned to: </span>
        <span>{assignedTo.name}</span>
      </div>
    ) : null}
    <div className='u-flex u-flexAlign-c'>
      <Icon className='u-marginRight-4' size='small' type='calendar' />
      <DateTime format='L' value={reminder} />
      <Icon
        className='u-marginLeft-16 u-marginRight-4'
        size='small'
        type='schedule'
      />
      <DateTime format='LT' value={reminder} />
    </div>
    <ExpandableActivityView content={notes} />
  </div>
);

TaskActivityView.propTypes = {
  assignedTo: PropTypes.shape({
    name: PropTypes.string,
  }),
  notes: PropTypes.string,
  reminder: PropTypes.string,
  subject: PropTypes.string,
};

export default TaskActivityView;

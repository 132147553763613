import { connect } from 'react-redux';
import getCandidacyProperty from '../selectors/getCandidacyProperty';

/**
 * A higher order component which takes a `candidacyId` prop
 * and provies the `searchType` (TYPE_JOB_SEARCH or TYPE_TALENT_POOL)
 * of the search it belongs to
 */
export default connect(
  (state, { candidacyId }) => ({
    searchType: getCandidacyProperty(state, candidacyId, 'search_type'),
  }),
  {},
);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import addressSchema from '../addressSchema';

/**
 * Gets an address record property by its ID and property name
 * @param {Object} state
 * @param {Number} addressId The address ID
 * @param {String|String[]} propertyName The property name or key path
 * @return {*} The property value.
 */
export default (state, addressId, propertyName) =>
  getEntityProperty(state, addressSchema.key, addressId, propertyName);

import selectn from 'selectn';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Overlay from 'react-bootstrap/lib/Overlay';
import Measure from 'react-measure';
import { compose, setDisplayName } from 'recompose';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';
import MultiSelectButton from './MultiSelectButton';
import MultiSelectDropDown from './MultiSelectDropDown';

class MultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: Boolean(props.defaultIsOpen),
      searchValue: '',
    };
  }

  setSearchValue(searchValue = '') {
    const { onSearchValueChange } = this.props;
    onSearchValueChange(searchValue);
    this.setState({ searchValue: searchValue });
  }

  handleButtonClick = event => {
    event.preventDefault();
    this.toggleDropDown();
  };

  handleButtonKeyDown = event => {
    if (event.key !== 'Tab') {
      event.preventDefault();
      this.openDropDown();

      if (
        event.key &&
        event.key.toUpperCase() === String.fromCharCode(event.keyCode)
      ) {
        this.setSearchValue(event.key);
      }
    }
  };

  /**
   * Called when the search value in the dropdown is changed.
   * @param {SyntheticEvent} event The input's onChange event.
   */
  handleSearchValueChange = event => {
    this.setSearchValue(event.currentTarget.value);
  };

  handleDropDownClose = () => {
    this.closeDropDown();
    this.setSearchValue();
  };

  toggleDropDown() {
    this.setState((prevState, props) => {
      if (props.clearSearchValueOnClose) {
        return {
          isOpen: !prevState.isOpen,
          searchValue: '',
        };
      }

      return { isOpen: !prevState.isOpen };
    });
  }

  closeDropDown() {
    const { clearSearchValueOnClose } = this.props;
    if (clearSearchValueOnClose) {
      this.setState({ isOpen: false, searchValue: '' });
    } else {
      this.setState({ isOpen: false });
    }
  }

  openDropDown() {
    this.setState({ isOpen: true });
  }

  render() {
    const { isOpen, searchValue } = this.state;
    const {
      getScrollContainer,
      getTargetRef,
      idFromOption,
      isLoading,
      noSearchResultsText,
      noValueText,
      onChange,
      options,
      placeholder,
      renderOptionLabel,
      setTargetRef,
      showWithoutSearching,
      summaryLabel,

      // withScrollContainerRef props
      summaryThreshold,
      useVirtualRendering,
      value,
    } = this.props;

    return (
      <Measure bounds={true} innerRef={setTargetRef}>
        {({ contentRect, measureRef }) => (
          <div className='MultiSelect' style={{ position: 'relative' }}>
            <MultiSelectButton
              buttonRef={measureRef}
              isOpen={isOpen}
              onKeyDown={this.handleButtonKeyDown}
              onMouseUp={this.handleButtonClick}
              placeholder={placeholder}
              renderOptionLabel={renderOptionLabel}
              summaryLabel={summaryLabel}
              summaryThreshold={summaryThreshold}
              value={value}
            />
            <Overlay
              container={getScrollContainer}
              onHide={this.handleDropDownClose}
              placement='bottom'
              show={isOpen}
              target={getTargetRef}
            >
              <MultiSelectDropDown
                idFromOption={idFromOption}
                isLoading={isLoading}
                noSearchResultsText={noSearchResultsText}
                noValueText={noValueText}
                onChange={onChange}
                onClose={this.handleDropDownClose}
                onSearchValueChange={this.handleSearchValueChange}
                options={options}
                placeholder={placeholder}
                renderOptionLabel={renderOptionLabel}
                searchValue={searchValue}
                showWithoutSearching={showWithoutSearching}
                useVirtualRendering={useVirtualRendering}
                value={value}
                width={selectn('bounds.width', contentRect)}
              />
            </Overlay>
          </div>
        )}
      </Measure>
    );
  }
}

MultiSelect.propTypes = {
  /**
   * True to clear the search input when the dropdown is closed, false to keep the value in place
   * when the dropdown in opened and closed.
   */
  clearSearchValueOnClose: PropTypes.bool,

  /**
   * Whether the dropdown should initially be opened.
   */
  defaultIsOpen: PropTypes.bool,

  getScrollContainer: PropTypes.func.isRequired,

  getTargetRef: PropTypes.func.isRequired,

  /**
   * A function that returns the unique ID for a given option value. This function is
   * called with the option itself, and must return a string.
   */
  idFromOption: PropTypes.func,

  /**
   * Indicates that results are currently being loaded for the dropdown, and will cause a loading
   * spinner to be rendered in the dropdown.
   */
  isLoading: PropTypes.bool,

  /**
   * The text to display when there are no options to display in the dropdown when search text has
   * been entered.
   */
  noSearchResultsText: PropTypes.node,

  /**
   * The text to display in the dropdown when there is no search text entered and there are no
   * values selected. Note that this is different from the `placeholder` value -- the placeholder
   * is displayed in the button, while `noValueText` is displayed in the dropdown.
   */
  noValueText: PropTypes.string,

  /**
   * Called when the selected values are changed. Called with the array of selected values.
   */
  onChange: PropTypes.func,

  /**
   * Called when the search value is changed. Called with the `SyntheticEvent` triggered by the
   * underlying input itself -- so the value can be obtained using `event.currentTarget.value`, or
   * the change can be cancelled by calling `event.preventDefault()`.
   */
  onSearchValueChange: PropTypes.func,

  /**
   * The list of available options to select from. Displayed when the user has entered a search
   * value.
   */
  options: PropTypes.array, // eslint-disable-line react/forbid-prop-types

  /**
   * The text to display when there is no values currently selected.
   */
  placeholder: PropTypes.string,

  /**
   * Renders the label for a single option. The function is given the option/value itself,
   * and should return a renderable node (string, Node, number, etc) that will be displayed for that
   * option
   */
  renderOptionLabel: PropTypes.func,

  setTargetRef: PropTypes.func.isRequired,

  /**
   * If this is set to true, the options in the multi-select will show up without
   * you having to search for them.
   */
  showWithoutSearching: PropTypes.bool,

  /**
   * The label to display when the number of values selected exceeds the `summaryThreshold` value.
   * The token `{0}` will be replaced with the number of selected elements.
   * Example: `{0} selected` would result in something like "3 selected" being displayed.
   */
  summaryLabel: PropTypes.string,

  /**
   * The threshold at which the button will start displaying the summaryLabel instead of the
   * label of the values selected.
   */
  summaryThreshold: PropTypes.number,

  /**
   * True to use virtualized rendering for option items; false to render all items to the DOM
   */
  useVirtualRendering: PropTypes.bool,

  /**
   * The currently selected options. This must always be an array, and should match the shape
   * of the options.
   */
  value: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

MultiSelect.defaultProps = {
  clearSearchValueOnClose: true,
  idFromOption: option => String(option.id || option),
  placeholder: 'No items selected',
  renderOptionLabel: option => option.display_name || option.name || option,
  showWithoutSearching: false,
  summaryLabel: '{0} items selected',
  summaryThreshold: 2,
  useVirtualRendering: true,
  value: [],
};

export default compose(
  setDisplayName('MultiSelect(enhanced)'),
  withScrollContainerRef,
)(MultiSelect);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';

import CountrySelectField from './CountrySelectField';
import StateOrProvinceField from './StateOrProvinceField';

/**
 * Displays a field for editing an address.
 */
class AddressField extends PureComponent {
  static createFieldState(
    name = 'address',
    address,
    validator,
    validationRequired,
  ) {
    const values = new Map({
      address_line_1: '',
      address_line_2: '',
      city: '',
      country_code: '',
      label: '',
      postal_code: '',
      state: '',
    }).merge(address);

    return FieldState.createNested(
      name,
      [
        CountrySelectField.createFieldState(
          'country_code',
          values.get('country_code'),
          validationRequired && validators.requiredField('Country'),
        ),
        InputField.createFieldState(
          'address_line_1',
          values.get('address_line_1'),
          validationRequired && validators.requiredField('Address Line One'),
        ),
        InputField.createFieldState(
          'address_line_2',
          values.get('address_line_2'),
        ),
        InputField.createFieldState(
          'city',
          values.get('city'),
          validationRequired && validators.requiredField('City'),
        ),
        InputField.createFieldState('label', values.get('label')),
        InputField.createFieldState(
          'postal_code',
          values.get('postal_code'),
          validationRequired && validators.requiredField('Postal Code'),
        ),
        StateOrProvinceField.createFieldState(
          'state',
          values.get('state'),
          validationRequired && validators.requiredField('State'),
        ),
      ],
      validator,
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    let nextFieldState = fieldState.setNestedField(childFieldState);

    // when the country_code value changes, wipe the value of the state input
    const isCountryCodeField = childFieldState.getName() === 'country_code';
    const currentCountryCode = fieldState.getNestedFieldValue('country_code');
    if (isCountryCodeField) {
      const newCountryCode = childFieldState.getValue();
      const didCountryCodeChange = currentCountryCode !== newCountryCode;
      if (didCountryCodeChange) {
        const stateField = fieldState.getNestedField('state');
        nextFieldState = nextFieldState.setNestedField(
          stateField.setValue(null),
        );
      }
    }

    // when the state field changes, if the country field is blank, fill in "US"
    const isStateField = childFieldState.getName() === 'state';
    if (isStateField && !currentCountryCode) {
      const countryCodeField = fieldState.getNestedField('country_code');
      nextFieldState = nextFieldState.setNestedField(
        countryCodeField.setValue('US'),
      );
    }

    onChange(nextFieldState, childFieldState.getName());
  };

  render() {
    const {
      fieldState,

      // Ignored fields to exclude from ...otherProps
      /* eslint-disable no-unused-vars */
      onChange,
      /* eslint-enable no-unused-vars */
      shouldRequireFields,
      showLabelField,
      ...otherProps
    } = this.props;

    const requiredFieldIndicator = shouldRequireFields ? '*' : '';

    return (
      <div className='AddressField'>
        {showLabelField && (
          <div className='row'>
            <div className='col-sm-6'>
              <InputField
                {...otherProps}
                fieldState={fieldState.getNestedField('label')}
                key='label'
                label='Label'
                onChange={this.handleFieldChange}
              />
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-sm-6'>
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('address_line_1')}
              key='address_line_1'
              label={`${requiredFieldIndicator}Address`}
              onChange={this.handleFieldChange}
            />
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('address_line_2')}
              key='address_line_2'
              label='Address Line 2'
              onChange={this.handleFieldChange}
            />
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('city')}
              key='city'
              label={`${requiredFieldIndicator}City`}
              onChange={this.handleFieldChange}
            />
          </div>
          <div className='col-sm-6'>
            <StateOrProvinceField
              {...otherProps}
              countryCode={fieldState.getNestedFieldValue('country_code')}
              fieldState={fieldState.getNestedField('state')}
              onChange={this.handleFieldChange}
              shouldRequireFields={shouldRequireFields}
            />
            <CountrySelectField
              {...otherProps}
              fieldState={fieldState.getNestedField('country_code')}
              label={`${requiredFieldIndicator}Country`}
              onChange={this.handleFieldChange}
            />
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('postal_code')}
              label={`${requiredFieldIndicator}Postal code`}
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

AddressField.defaultProps = {
  shouldRequireFields: false,
  showLabelField: false,
};

AddressField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  shouldRequireFields: PropTypes.bool,

  /**
   * controls whether or not we show the label field
   */
  showLabelField: PropTypes.bool,
};

export default AddressField;

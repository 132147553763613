const initSelect2 = require('./select2');

// Select2 loses its mind when rendered in Bootstrap modals. The workaround is to provide a
// `dropdownParent` option that provides the modal DOM node as the rendering scope for the dropdown.
module.exports = selector => {
  const $modal = $(selector);
  const $dialog = $modal.find('.modal-dialog');
  // We assume that every <select> element inside of the target modal should be initialized
  initSelect2($modal, ['select'], {
    dropdownParent: $dialog,
  });
};

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DateTime from 'modules/datetime/components/DateTime';
import Link from 'modules/routing/components/Link';
import Card from '@thrivetrm/ui/components/Card';

import mapActivityIdToActivity from './mapActivityIdToActivity';

/**
 * Renders single activity item in the activitylist.
 */
const ActivityListItem = ({ activity }) => (
  <li className='ActivityListItem'>
    <Card className='u-marginVertical-16' isCentered={false}>
      <Link href={activity.get('url')}>
        <div className='ActivityListItem__date'>
          <DateTime format='L LT' value={activity.get('created_at')} />
        </div>
        <div className='ActivityListItem__description'>
          {activity.get('description')}
        </div>
      </Link>
      {activity.get('type') === 'interview' &&
        activity.getIn(['owner', 'name']) && (
          <div className='ActivityListItem__owner'>
            Organized by: {activity.getIn(['owner', 'name'])}
          </div>
        )}
    </Card>
  </li>
);

ActivityListItem.propTypes = {
  /**
   * The activity to render.
   */
  activity: ImmutablePropTypes.mapContains({
    created_at: PropTypes.string.isRequired,
    description: PropTypes.string,
    owner: ImmutablePropTypes.mapContains({
      name: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default mapActivityIdToActivity(ActivityListItem);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import taskSchema from '../schema';

/**
 * Gets a value indicating whether a task has been completed.
 * @param {*} state
 * @param {Number} taskId The ID of the task.
 * @return {Boolean} True if the task has been completed, otherwise false.
 */
export default (state, taskId) =>
  Boolean(getEntityProperty(state, taskSchema.key, taskId, 'completed'));

import { TYPE_PIPELINE } from 'modules/searches/constants';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  withHandlers,
} from 'recompose';
import fetchTagListActionCreator from '../actions/fetchTagList';
import getTagList from '../selectors/getTagList';
import isTagListFetchedSelector from '../selectors/isTagListFetched';
import shouldFetchTagListSelector from '../selectors/shouldFetchTagList';

/**
 * Ensures that the current tenant's tag list has been loaded.
 * @param {Object} options
 * @param {String} [options.tagListPropName="tagList"] The propName to use for supplying
 *   the tag list record value.
 * @param {?String} options.isDataReadyPropName The propName to use for supplying a boolean value
 *   that indicates whether the configuration has been fetched and is ready to be used or not.
 *   This is optional and if not given the underlying prop value will not be provided.
 *
 * NOTE: the `tagListPropName` (`circle`, by default) may be undefined! The `isDataReadyPropName`
 * should be used to determine if we have attempted to load the circle. If it is null but the
 * `isDataReadyPropName` value is true, then the feature is not enabled.
 */
export default ({ isDataReadyPropName, tagListPropName = 'tagList' } = {}) =>
  compose(
    setDisplayName('withTagListFetched'),
    mapProps(incomingProps => ({
      withTagListFetchedProps: incomingProps,
      searchType: incomingProps.searchType,
    })),

    connect(
      (state, { searchType }) => ({
        shouldFetchTagList: shouldFetchTagListSelector(state),
        tagList:
          searchType === TYPE_PIPELINE ? state.smart_tags : getTagList(state),
        isTagListFetched: isTagListFetchedSelector(state),
      }),
      {
        fetchTagList: fetchTagListActionCreator,
      },
    ),

    withHandlers({
      fetchTagListIfNeeded: ({
        fetchTagList,
        searchType,
        shouldFetchTagList,
      }) => () =>
        shouldFetchTagList && fetchTagList({ searchType: searchType }),
    }),

    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchTagListIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchTagListIfNeeded();
      },
    }),

    mapProps(
      ({
        isTagListFetched,
        shouldFetchTagList,
        tagList,
        withTagListFetchedProps,
      }) => ({
        ...withTagListFetchedProps,
        [tagListPropName]: tagList,

        // Only add the `isDataReadyPropName` prop if that prop name was supplied.
        ...(isDataReadyPropName
          ? {
              // The data is ready only the tag list has been fetched, and it does NOT need to be refetched
              [isDataReadyPropName]: !shouldFetchTagList && isTagListFetched,
            }
          : null),
      }),
    ),
  );

/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import React from 'react';

const DEFAULT_DELAY_MS = 5000;

/**
 * A higher order component to manage a temporary message that is automatically removed after
 * a certain delay.
 *
 * @param {Object} options
 * @param {Number} [defaultDelay=5000] The default time in milliseconds before clearing a message.
 * @param {String} [messageProp="temporaryMessage"] The name of the prop that will contain the
 *   temporary message.
 * @param {String} [setMessageProp="setTemporaryMessage"] The name of the prop that can be used to
 *   set the temporary message.
 */
export default ({
  defaultDelay = DEFAULT_DELAY_MS,
  messageProp = 'temporaryMessage',
  setMessageProp = 'setTemporaryMessage',
} = {}) => BaseComponent =>
  class withTemporaryMessage extends React.Component {
    state = {
      message: null,
    };

    componentWillUnmount() {
      clearTimeout(this.timeoutId);
    }

    setMessage = (message, clearDelay = defaultDelay) => {
      clearTimeout(this.timeoutId);
      this.setState({ message: message });

      if (message && clearDelay) {
        this.timeoutId = setTimeout(() => {
          this.setState({ message: null });
        }, clearDelay);
      }
    };

    render() {
      const { message } = this.state;
      const withTemporaryMessageProps = {
        [messageProp]: message,
        [setMessageProp]: this.setMessage,
      };

      return <BaseComponent {...this.props} {...withTemporaryMessageProps} />;
    }
  };

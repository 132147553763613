import { compose, setDisplayName } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import {
  RECOMMENDATIONS_LIST_FETCH_START,
  RECOMMENDATIONS_LIST_FETCH_SUCCESS,
  RECOMMENDATIONS_LIST_FETCH_FAILURE,
} from './actionTypes';
import recommendationsSchema from '../recommendationsSchema';

/**
 * Fetches the available recommendations for a given search.
 * @param {Object} payload
 * @param {Number} payload.searchId The search ID for which we need to fetch the recommendations.
 */
export default compose(
  setDisplayName('fetchRecommendationsList'),
  fetchAction,
  normalizeResponse({
    recommendations: [recommendationsSchema],
  }),
  createAsyncActions({
    startActionType: RECOMMENDATIONS_LIST_FETCH_START,
    successActionType: RECOMMENDATIONS_LIST_FETCH_SUCCESS,
    failureActionType: RECOMMENDATIONS_LIST_FETCH_FAILURE,
  }),
  withOptions(({ searchId }) => ({
    url: routes.api_v1_search_recommended_candidacies({
      searchId: searchId,
    }),
  })),
);

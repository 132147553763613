import ContactHeaderField from './ContactHeaderField';
import createContactForm from '../createContactForm';

const createRootFieldState = props =>
  ContactHeaderField.createFieldState('contact', props.contact);

const ContactHeaderForm = createContactForm(
  ContactHeaderField,
  createRootFieldState,
  'contact-header-form',
);

export default ContactHeaderForm;

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { targetCompanyCommentSchema } from '../../schema';

/**
 * Gets the date of a target company comment by it's ID.
 * @param {Object} state
 * @param {Number} targetCompanyCommentId The ID of the target company comment.
 * @return {String} The date string (ISO8601 formatted) of the last time the comment was updated.
 */
export default (state, targetCompanyCommentId) =>
  getEntityProperty(
    state,
    targetCompanyCommentSchema.key,
    targetCompanyCommentId,
    'updated_at',
  );

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, withProps, setDisplayName, setPropTypes } from 'recompose';

import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import mapSearchIdToSearchType from 'modules/searches/components/mapSearchIdToSearchType';
import { TYPE_PIPELINE } from 'modules/searches/constants';

import withFormToggleButton from '../../../components/forms/withFormToggleButton';

import IntroductionForm from './IntroductionForm';
import IntroductionListGroupedBySearchRelated from './IntroductionListGroupedBySearchRelated';
import withIntroductionListFetched from './withIntroductionListFetched';
import mapParentToIntroductionList from './mapParentToIntroductionList';
import { PARENT_CONTACT } from '../constants';

const AddIntroductionFormToggleButton = withFormToggleButton(IntroductionForm);

/**
 * A panel that displays a collection of introductions, allowing for editing, deleting, replying,
 * and creating new introductions.
 */
const IntroductionsPanel = ({
  allowCreate,
  candidacyId,
  contactId,
  handleRefresh,
  introductionList,
  popoverPlacement,
  searchId,
  searchType,
}) => {
  const isFetching =
    introductionList && introductionList.getIn(['_meta', 'isFetching']);
  const hasError =
    introductionList && Boolean(introductionList.getIn(['_meta', 'error']));
  const searchTypeForDisplay =
    searchType === TYPE_PIPELINE ? 'Pipeline' : 'Search';

  return (
    <div className='introductions--introductions-panel'>
      {allowCreate && (
        <AddIntroductionFormToggleButton
          contactId={contactId}
          draftStoragePath={{
            search: searchId,
            candidate: candidacyId,
            introduction: 'new',
          }}
          errorDisplay='tooltip'
          excludeContactField={true}
          searchId={searchId}
          title='Add introduction'
        >
          New Introduction
        </AddIntroductionFormToggleButton>
      )}
      <div className='introductions--introductions-panel-container'>
        <div className='introductions--introductions-panel-scroll-container'>
          {introductionList && introductionList.get('ids') && (
            <IntroductionListGroupedBySearchRelated
              candidacyId={candidacyId}
              introductionIds={introductionList.get('ids')}
              popoverPlacement={popoverPlacement}
              povContactId={contactId}
              searchId={searchId}
              searchType={searchTypeForDisplay}
            />
          )}
          {hasError && !isFetching && (
            <ErrorAlert
              onRetry={handleRefresh}
              title='There was an error fetching introductions'
            />
          )}
          {isFetching && <LoadingIndicator />}
        </div>
      </div>
    </div>
  );
};

export const propTypes = {
  allowCreate: PropTypes.bool,

  candidacyId: PropTypes.number,

  contactId: PropTypes.number.isRequired,

  handleRefresh: PropTypes.func.isRequired,

  introductionList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      error: PropTypes.any,
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),

  /**
   * Optional string to set orientation of delete confirm popovers (if none is
   * passed it defaults to top)
   */
  popoverPlacement: PropTypes.string,

  searchId: PropTypes.number,

  searchType: PropTypes.string.isRequired,
};

export const defaultProps = {
  allowCreate: true,
};

IntroductionsPanel.propTypes = propTypes;
IntroductionsPanel.defaultProps = defaultProps;

export default compose(
  setDisplayName('IntroductionsPanel(enhanced)'),
  setPropTypes({
    searchId: PropTypes.number,
    contactId: PropTypes.number.isRequired,
  }),
  withProps(({ contactId, introductionActions }) => ({
    parentType: PARENT_CONTACT,
    parentId: contactId,
    handleRefresh: () =>
      introductionActions.fetchIntroductionList({
        contactId: contactId,
        parentType: PARENT_CONTACT,
      }),
  })),
  mapParentToIntroductionList,
  mapSearchIdToSearchType,
  withIntroductionListFetched,
)(IntroductionsPanel);

import getInterviewProperty from './getInterviewProperty';

/**
 * Gets the candidate ID for an interview by the interview ID
 * @param {Object} state
 * @param {Number} interviewId The interviewId ID
 * @return {Number} The candidate ID.
 */
export default (state, interviewId) =>
  getInterviewProperty(state, interviewId, 'candidate');

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import companySchema from '../companySchema';

/**
 * Gets a company's name from it's ID
 * @param {*} state
 * @param {Number} id the company ID
 * @returns {String} The company's name.
 */
export default (state, id) =>
  getEntityProperty(state, companySchema.key, id, 'name');

import React, { cloneElement, createElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import classnames from 'classnames';
import RowSelectCheckbox from './RowSelectCheckbox';
import TableHeadCell from './TableHeadCell';
import {
  columns as columnsPropType,
  data as dataPropType,
  selection as selectionPropType,
} from './propTypes';

const SELECTION_HEADER_COL_SPAN = 2;
/**
 * Renders the header component of a Table (<thead />).
 */
const TableHead = ({
  columns,
  data,
  onHeaderClick,
  onSelectAllChange,
  selectable,
  selection,
  selectionHeader: SelectionHeader,
  sortAscending,
  sortBy,
  sortable: tableSortable,
}) => {
  const hasSelection = Boolean(selectable && selection && selection.length);
  const allChecked =
    hasSelection &&
    Boolean(data) &&
    data.every(datum => selection.includes(datum));
  const someChecked = hasSelection && !allChecked && selection.length > 0;

  // When the table is being displayed with checkboxes for selecting rows, the header
  // toggles between "regular" column header display (when no selection is made), and
  // the "bulk actions" header display (when any rows are selected).
  // In order to prevent the columns from resizing, the "regular" column is still always
  // rendered, but it's `visibility` is set to "collapse" when then bulk actions actions
  // are shown. The problem is that Bootstrap styles target `thead > tr:first-child` in
  // ways that cause the header height to increase when a second <tr> is rendered in the
  // <thead>. To deal with this we render a collapsed placeholder row first if we're allowing
  // row selection. This way, whichever header style is currently visible, it's always
  // the "second child" and they both get the same stlyes/same heights supplied.
  // I've tried tweaking the stlyes instead, but that was much more complicated and didn't
  // always work correctly, so while this seems "hacky", it's better than the alternative.
  return (
    <thead
      className={classnames('TableHead', {
        'TableHead--sortable': tableSortable,
        'TableHead--withSelection': hasSelection,
        'TableHead--withoutSelection': !hasSelection,
      })}
    >
      <tr
        className='TableHead__row TableHead__row--columns'
        key='TableHead__row--columns'
      >
        {selectable && (
          <th className='table__selection-cell'>
            <RowSelectCheckbox
              checked={someChecked || allChecked}
              indeterminate={someChecked && !allChecked}
              onChange={onSelectAllChange}
            />
          </th>
        )}
        {columns.map((column, index) => (
          <TableHeadCell
            alignment={column.alignment}
            className={column.className}
            component={column.Header || column.title}
            isFixed={index === 0}
            key={column.key}
            onClick={onHeaderClick}
            sortable={tableSortable && column.isSortable}
            sorted={sortBy === (column.sortKey || column.key)}
            sortedAscending={sortAscending}
            sortKey={column.sortKey || column.key}
            width={column.width}
          />
        ))}
      </tr>
      {selectable && Boolean(SelectionHeader) && (
        <tr
          className='TableHead__row TableHead__row--selection'
          key='TableHead__row--selection'
        >
          <th className='table__selection-cell'>
            <RowSelectCheckbox
              checked={someChecked || allChecked}
              indeterminate={someChecked && !allChecked}
              onChange={onSelectAllChange}
            />
          </th>
          <TableHeadCell
            className='LegacyTableHead__selectionHeader table__cell--isFixed'
            // SelectionHeader can be an element or a component. If it's an element, we have to
            // clone it to provide the additional `selection` prop, if it's a component, we can just
            // create an element from it with the prop.
            colSpan={SELECTION_HEADER_COL_SPAN}
            component={(isValidElement(SelectionHeader)
              ? cloneElement
              : createElement)(SelectionHeader, {
              selection: selection,
            })}
            key='TableHead__selectionHeader'
          />
          <th colSpan={columns.length - SELECTION_HEADER_COL_SPAN}>
            <div />
          </th>
        </tr>
      )}
    </thead>
  );
};

TableHead.defaultProps = {
  onHeaderClick: null,
  onSelectAllChange: null,
  selectable: false,
  selection: null,
  selectionHeader: null,
  sortable: true,
  sortAscending: false,
  sortBy: null,
};

TableHead.propTypes = {
  /**
   * The columns to render in the table.
   */
  columns: columnsPropType.isRequired,

  /**
   * The data to display in the table. Typically this is an array or list of some sort,
   * but anything that implements a `map` function that allows mapping a collection will work
   * here (i.e. Immutable.List, or some custom implementation)
   */
  data: dataPropType.isRequired,

  /**
   * Called when one of the headers are clicked, indicating a sort change is desired.
   * Gets called with the click event generated. event.currentTarget.dataset.value contains
   * the `sortkey` of the column, or the `key` if the sortKey was not set.
   */
  onHeaderClick: PropTypes.func,

  /**
   * Called when the "select all" checkbox value is changed by the user.
   */
  onSelectAllChange: PropTypes.func,

  /**
   * True to show checkboxes in the first column allowing rows to be selected.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  selectable: PropTypes.bool,

  /**
   * The list of items in the dataset that are in a "selected" state
   * (this should be a subset of the `data` prop)
   */
  selection: selectionPropType,

  /**
   * The component to display when a selection has been made.
   * If provided, this component will be rendered instead of the standard
   * column headers when ANY rows have been selected.
   */
  selectionHeader: PropTypes.oneOfType([
    // An existing React Element
    PropTypes.element,
    // A React component definition
    PropTypes.func,
  ]),

  /**
   * True to enable sorting by clicking on the table headers.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  sortable: PropTypes.bool,

  /**
   * True if the current sorting of the table is in ascending order.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  sortAscending: PropTypes.bool,

  /**
   * The current column being sorted on. Columns are identifited by their `sortKey` (or `key` if no
   * sort key is provided), and that is used to determine which column is currently being sorted on.
   */
  // eslint-disable-next-line react/forbid-prop-types
  sortBy: PropTypes.any,
};

export default compose(
  setDisplayName('TableHead(enhanced)'),
  setPropTypes({
    ...TableHead.propTypes,

    // These are not needed for the HOC.
    onHeaderClick: PropTypes.any,
    onSelectAllChange: PropTypes.any,

    /**
     * Called when the `selection` prop should be updated.
     */
    onSelectionChange: PropTypes.func.isRequired,

    /**
     * Called when the sorted column (`sortBy`) and/or sort direction (`sortAscending`)
     * should be changed.
     */
    onSortChange: PropTypes.func.isRequired,
  }),
  withHandlers({
    onSelectAllChange: ({ data, onSelectionChange }) => checked => {
      onSelectionChange(checked ? Array.from(data) : []);
    },

    // Adds an `onSortChange` callback that is called with the desired
    // sort and whether sorting should be ascending:
    // `onSortChange(sortBy, isSortAscending);`
    onHeaderClick: ({ onSortChange, sortAscending, sortBy }) => event => {
      const { value } = event.currentTarget.dataset;
      onSortChange(value, value === sortBy ? !sortAscending : true);
    },
  }),
)(TableHead);

import getCandidatesCount from './getCandidatesCount';
import getSortByCompanyName from './getSortByCompanyName';

/**
 * Gets a value to sort on a target companies candidates_count, and secondarily sorting on
 * the company name, given a target company ID
 */
export default (state, targetCompanyId) => [
  -getCandidatesCount(state, targetCompanyId),
  getSortByCompanyName(state, targetCompanyId),
];

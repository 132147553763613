import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import FilterableTagTable from 'modules/tags/components/FilterableTagTable';
import withTransactionTracking from 'modules/transactions/components/withTransactionTracking';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import addCandidacyTagActionCreator from '../../actions/tags/addCandidacyTag';
import removeCandidacyTagActionCreator from '../../actions/tags/removeCandidacyTag';
import mapCandidacyIdToProperty from '../mapCandidacyIdToProperty';

/**
 * A table for selecting tags applied to a candidacy.
 */
export default compose(
  setDisplayName('CandidacyTagTable'),
  setPropTypes({
    /**
     * The ID of the candidacy whose tags are to be managed.
     */
    candidacyId: PropTypes.number.isRequired,

    /**
     * indicates whether the tag filter should use creator name
     * in addition to tag label
     */
    filterByCreatorName: PropTypes.bool,
  }),

  // The tag_ids from the candidacy record are supplied to the CandidacyTagTable as it's
  // `selectedTagIds` prop.
  mapCandidacyIdToProperty('tag_ids', 'selectedTagIds'),
  mapCandidacyIdToProperty('smart_tag_ids', 'selectedSmartTagIds'),

  // Bind the action creators that will make the network requests.
  connect(null, {
    addCandidacyTag: addCandidacyTagActionCreator,
    removeCandidacyTag: removeCandidacyTagActionCreator,
  }),

  withStateHandlers(
    {
      // maps `transactionId`s to the `tagId` it is changin -- for use in the `onTransactionFinished`
      // callback in otder to determine which tag ID was finished operating on.
      transactionIdToTagIdMap: {},

      // Keep track of pending adds and removes. Anything in these gets disabled, and `selectedTagIds`
      // also gets modified based on these so the UI is optimistically updated.
      addingTagIds: [],
      removingTagIds: [],
    },
    {
      addPendingTagUpdate: ({
        addingTagIds,
        removingTagIds,
        transactionIdToTagIdMap,
      }) => (tagId, transactionId, adding) => ({
        transactionIdToTagIdMap: {
          ...transactionIdToTagIdMap,
          [transactionId]: tagId,
        },
        addingTagIds: adding ? addingTagIds.concat(tagId) : addingTagIds,
        removingTagIds: adding ? removingTagIds : removingTagIds.concat(tagId),
      }),

      onTransactionFinished: ({
        addingTagIds,
        removingTagIds,
        transactionIdToTagIdMap,
      }) => transactionId => {
        const tagId = transactionIdToTagIdMap[transactionId];
        return {
          addingTagIds: addingTagIds.filter(id => id !== tagId),
          removingTagIds: removingTagIds.filter(id => id !== tagId),
        };
      },
    },
  ),

  // Provides `trackTransaction` and calls `onTransactionFinished` for us.
  withTransactionTracking(),

  withHandlers({
    onCheckboxChange: ({
      addCandidacyTag,
      addPendingTagUpdate,
      candidacyId,
      contactId,
      removeCandidacyTag,
      searchType,
      trackTransaction,
    }) => ({ checked, tagId }) => {
      const action = checked ? addCandidacyTag : removeCandidacyTag;
      const transactionId = uniqueId();

      action({
        contactId: contactId,
        candidacyId: candidacyId,
        id: tagId,
        searchType: searchType,
        transactionId: transactionId,
      });
      trackTransaction(transactionId);
      addPendingTagUpdate(tagId, transactionId, checked);
    },
  }),

  // Remove extra props.
  mapProps(
    ({
      addCandidacyTag: _addCandidacyTag,
      addPendingTagUpdate: _addPendingTagUpdate,
      addingTagIds,
      candidacyId: _candidacyId,
      onTransactionFinished: _onTransactionFinished,
      removeCandidacyTag: _removeCandidacyTag,
      removingTagIds,
      selectedSmartTagIds,
      selectedTagIds,

      trackTransaction: _trackTransaction,
      transactionIdToTagIdMap: _transactionIdToTagIdMap,
      transactionIds: _transactionIds,
      ...props
    }) => ({
      ...props,

      disabledTagIds: addingTagIds.concat(removingTagIds),

      // selectedTagIds will be an Immutable.List, but TagCheckboxList expects an array
      // Then we add to the collection any tags that are in the process of being added,
      // and remove any tags that are in the process of being removed.
      selectedTagIds: (selectedTagIds ? selectedTagIds.toArray() : [])
        .concat(addingTagIds)
        .filter(id => !removingTagIds.includes(id)),
      selectedSmartTagIds: (selectedSmartTagIds
        ? selectedSmartTagIds.toArray()
        : []
      )
        .concat(addingTagIds)
        .filter(id => !removingTagIds.includes(id)),
    }),
  ),
)(FilterableTagTable);

import { connect } from 'react-redux';
import { compose } from 'recompose';
import getCreatedAt from '../selectors/getCreatedAt';

/**
 * A Higher order component that sorts a list of referenceIds in reverse chronological order
 * of the updated_at date.
 */
export default compose(
  connect((state, { referenceIds }) => ({
    referenceIds:
      referenceIds &&
      referenceIds.sortBy(id => getCreatedAt(state, id)).reverse(),
  })),
);

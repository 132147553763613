import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import SearchCandidacyLink from 'modules/candidacies/components/SearchCandidacyLink';
import MissingCandidacyContactLink from './MissingCandidacyContactLink';

/**
 * A higher order component that wraps a base component in a `SearchCandidacyLink` if there is a
 * candidacy related to the current search (that is, the contact current exists in the related
 * search); or wraps it in a ContactLink otherwise. The contact link, when clicked, actually
 * shows an overlay with a message indicating that the user is no longer a candidate in the
 * search, at which case the user may click through to their profile.
 */
const wrapInCandidacyOrContactLink = BaseComponent => {
  const CandidacyOrContactLinkWrappedComponent = props => {
    const { outreach, searchId } = props;
    const candidacyId = outreach.getIn(['candidacy_ids', String(searchId)]);

    if (candidacyId) {
      return (
        <SearchCandidacyLink candidacyId={candidacyId} tab='outreach'>
          <BaseComponent {...props} />
        </SearchCandidacyLink>
      );
    }

    return (
      <MissingCandidacyContactLink contactId={outreach.get('contact_id')}>
        <BaseComponent {...props} />
      </MissingCandidacyContactLink>
    );
  };

  CandidacyOrContactLinkWrappedComponent.propTypes = {
    outreach: ImmutablePropTypes.mapContains({
      candidacy_ids: ImmutablePropTypes.mapOf(
        // Values are candidacy IDs
        PropTypes.number.isRequired,
        // Keys are search IDs (note: these are strings!!)
        PropTypes.string.isRequired,
      ).isRequired,
      contact_id: PropTypes.number.isRequired,
    }),

    searchId: PropTypes.number.isRequired,
  };

  return CandidacyOrContactLinkWrappedComponent;
};

export default wrapInCandidacyOrContactLink;

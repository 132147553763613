import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import Modal from '@thrivetrm/ui/components/Modal';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import routes from 'modules/routing/routes';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import SkyminyrCompanySelectModal from 'modules/companies/components/skyminyr/SkyminyrCompanySelectModal';
import { getSkyminyrCompanyCreatePayload } from 'modules/companies/components/skyminyr/getSkyminyrCompanyCreatePayload';
import ExternalLink from 'modules/links/ExternalLink';
import { addSubsidiaryCompany } from './subsidiaryCompaniesSlice';

const AddSubsidiaryCompanyModal = ({ companyId }) => {
  const dispatch = useDispatch();
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const { addSubsidiaryCompanyError, isAddingSubsidiaryCompany } = useSelector(
    state => state.subsidiaryCompanies,
  );

  const handleSubmit = formState => {
    const subsidiaryCompany = formState.connectedRecord;
    dispatch(
      addSubsidiaryCompany({
        parentCompanyId: companyId,
        subsidiaryCompanyId: hasSkyminyrFeature
          ? formState.company.data?.id
          : subsidiaryCompany.id,
        subsidiaryCompanyName: hasSkyminyrFeature
          ? formState.company.data?.name
          : subsidiaryCompany.name,
        ...(hasSkyminyrFeature && formState.company.data?.skyminyr_id
          ? getSkyminyrCompanyCreatePayload(formState.company.data)
          : null),
        onSuccess: closeModal,
      }),
    );
  };

  return (
    <>
      <div className='u-flex u-flexJustify-r'>
        <ButtonSecondary
          className='u-marginBottom-16'
          data-testid='add-subsidiary-company-button'
          icon='add'
          label='Add Subsidiary Company'
          onClick={openModal}
          size='small'
        />
      </div>
      {hasSkyminyrFeature ? (
        <SkyminyrCompanySelectModal
          actionConfirmationText={
            <>
              <StatusIndicator
                className='u-marginTop-4 u-marginRight-4'
                status='info'
              />
              <p className='u-margin-n u-textColor-gray60'>
                This company will be added to your database, which will
                automatically update with latest metadata.
              </p>
            </>
          }
          canCreateNewCompany={true}
          canSelectThriveRecords={true}
          companySearchGuidance={
            <p className='u-textAlign-c u-textColor-blue'>
              Search for a company, which <br /> automatically populates with
              <br /> enriched data.{' '}
              <ExternalLink
                className='SkyminyrCompanySelect__learnMore'
                href='https://helpcenter.thrivetrm.com/en/'
              >
                Learn more
              </ExternalLink>
            </p>
          }
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleSubmit}
          queryParams={{
            resource: 'subsidiary_company',
            company_id: companyId,
          }}
          submitButtonLabel='Add Company'
          title='Add Subsidiary Company'
        />
      ) : (
        <Modal
          data-testid='add-subsidiary-company-modal'
          isOpen={isModalOpen}
          onClose={closeModal}
          size='small'
          title='Add Subsidiary Company'
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Body className='ModalBody--noClip'>
              <Form.Autocomplete
                formatOption={option => option.name}
                label='Company'
                name='connectedRecord'
                noResultsText='No results found'
                onSelect={() => {}}
                placeholder='Search for Company...'
                rules={{
                  required: {
                    value: true,
                    message: 'Selecting a company is required',
                  },
                }}
                url={query =>
                  routes.api_v1_autocomplete_query({
                    query: {
                      query: {
                        resource: 'subsidiary_company',
                        company_id: companyId,
                        term: query,
                      },
                    },
                  })
                }
              />
              {addSubsidiaryCompanyError ? (
                <Card className='u-marginTop-8' type='error'>
                  {addSubsidiaryCompanyError}
                </Card>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <ButtonPrimary
                isOutline={true}
                label='Cancel'
                onClick={closeModal}
              />
              <Form.SubmitButton
                isLoading={isAddingSubsidiaryCompany}
                label='Save'
              />
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
};

AddSubsidiaryCompanyModal.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default AddSubsidiaryCompanyModal;

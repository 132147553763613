import fetchEntity from 'modules/entities/actions/fetchEntity';
import routes from 'modules/routing/routes';
import jobApplicationSchema from '../schema';

export default ({ id }) =>
  fetchEntity({
    entityType: jobApplicationSchema.key,
    id: id,
    responseSchema: { job_application: jobApplicationSchema },
    url: routes.api_v1_job_application({ id: id }),
  });

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';

import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import { DATE_GROUPS } from '../constants';
import connectInterviewActions from './connectInterviewActions';
import withInterviewListFetched from './withInterviewListFetched';
import InterviewList from './InterviewList';
import mapParentToInterviewList from './mapParentToInterviewList';
import mapPropsToParentIdAndParentType from './mapPropsToParentIdAndParentType';

/**
 * Renders a list of only the most recent interviews
 */
const RecentInterviews = ({
  filter,
  hideIfEmpty,
  interviewIds,
  interviewList,
  readOnly,
  title,
  ...props
}) =>
  hideIfEmpty && (!interviewIds || interviewIds.size < 1) ? null : (
    <div
      className={`interviews--recent-interviews interviews--recent-interviews--${filter}`}
    >
      {title}
      {interviewIds && (
        <InterviewList
          {...props}
          filter={filter}
          interviewIds={interviewIds}
          readOnly={readOnly}
          renderCandidateNames={true}
          renderDescriptions={false}
          renderSubjects={false}
          renderTitles={true}
          renderTitlesAsLinks={true}
        />
      )}
      {interviewList && interviewList.getIn(['_meta', 'isFetching']) && (
        <LoadingIndicator />
      )}
    </div>
  );

RecentInterviews.propTypes = {
  filter: PropTypes.oneOf(DATE_GROUPS).isRequired,

  hideIfEmpty: PropTypes.bool,

  interviewIds: ImmutablePropTypes.listOf(PropTypes.number),

  interviewList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  readOnly: PropTypes.bool,

  /**
   * An optional title to render.
   */
  title: PropTypes.node,
};

RecentInterviews.defaultProps = {
  hideIfEmpty: false,
  readOnly: false,
};

export default compose(
  setDisplayName('RecentInterviews(enhanced)'),

  // The connected version of this component requires a parentId and parentType,
  // everything else will be fetched from state.
  setPropTypes({
    candidacyId: PropTypes.number,
    searchId: PropTypes.number,
    filter: PropTypes.oneOf(DATE_GROUPS).isRequired,
  }),

  mapPropsToParentIdAndParentType,

  // Get the interviews list state based on the parentId and parentType
  mapParentToInterviewList,

  // Connect the interview actions so we can fetch them if needed.
  connectInterviewActions,

  // If the interviews have not been loaded or are stale, fetch them.
  withInterviewListFetched,

  // Add a prop so we can manually trigger refreshes if desired.
  withProps(({ interviewList }) => ({
    interviewIds: interviewList && interviewList.get('ids'),
  })),
)(RecentInterviews);

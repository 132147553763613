import createEntity from 'modules/entities/actions/createEntity';
import routes from 'modules/routing/routes';
import bulkImportSchema from '../schema';

/**
 * Creates an action for uploading a CSV file.
 * @param {Object} payload
 * @param {File} payload.file The file to upload
 * @param {String} [payload.resourceType] The resource type of the csvUpload ("Contact")
 * @param {String} payload.transactionId A unique ID used to track the request.
 */
export default ({ file, resourceType, transactionId }) => {
  // Binary data is submitted via FormData. This also requires us to set contentType to false
  // so that it will get automatically generated with the correct boundary information when the
  // file is encoded and sent to the server.
  const body = new FormData();
  body.append('csv_upload[file]', file);
  body.append('csv_upload[resource_type]', resourceType);

  return createEntity({
    transactionId: transactionId,
    entityType: bulkImportSchema.key,
    responseSchema: { csv_upload: bulkImportSchema },

    url: routes.api_v1_csv_uploads(),
    body: body,

    // Let content-type header be generated automatically so it contains the correct
    // multi-part boundary ID
    contentType: false,
  });
};

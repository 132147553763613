import getInterviewProperty from './getInterviewProperty';

/**
 * Gets the `start_time` field value for an interview by it's ID.
 * @param {*} state
 * @param {Number} interviewId
 * @return {String} Start date and time as an ISO 8601 formatted string
 */
export default (state, interviewId) =>
  getInterviewProperty(state, interviewId, 'start_time');

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import ActivityList from './ActivityList';
import withActivityListFetched from './withActivityListFetched';

/**
 * Renders a list of activities, optionally showing a title, loading indicator, and error alert,
 * depending on the fetch state and props.
 */
const ActivityListView = ({
  activityList,
  errorAlert,
  fetchActivityList,
  limit,
  loadingIndicator,
  title,
}) => {
  const isFetching =
    activityList && activityList.getIn(['_meta', 'isFetching']);
  const error = activityList && activityList.getIn(['_meta', 'error']);

  return (
    <div className='ActivityListView'>
      {title}
      {activityList && activityList.get('ids') && (
        <ActivityList activityIds={activityList.get('ids')} limit={limit} />
      )}
      {loadingIndicator && activityList && isFetching && <LoadingIndicator />}
      {errorAlert && activityList && error && !isFetching && (
        <ErrorAlert
          onRetry={fetchActivityList}
          title='There was an error fetching activities'
        />
      )}
    </div>
  );
};

ActivityListView.propTypes = {
  /**
   * The activies list collection to render.
   */
  activityList: ImmutablePropTypes.mapContains({
    ids: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
  }),

  /**
   * Whether an error alert should be displayed if the activity list failed to be fetched.
   */
  errorAlert: PropTypes.bool,

  /**
   * A function that triggers the fetching of the activity list.
   */
  fetchActivityList: PropTypes.func,

  /**
   * An optional number of activity items to limit this list to.
   */
  limit: PropTypes.number,

  /**
   * Whether the  loading indicator should be displayed while fetching.
   */
  loadingIndicator: PropTypes.bool,

  /**
   * An optional panel title to render.
   */
  title: PropTypes.node,
};

ActivityListView.defaultProps = {
  errorAlert: true,
  loadingIndicator: true,
};

export default compose(
  setDisplayName('ActivityListView(enhanced)'),
  setPropTypes({
    /**
     * Whether to display the error alert or not.
     */
    errorAlert: PropTypes.bool,

    /**
     * True to only render when there are activities available to render.
     */
    hideIfEmpty: PropTypes.bool.isRequired,

    /**
     * Optionally limit the number of activities to render.
     */
    limit: PropTypes.number,

    /**
     * Whether to display the loading indicator while fetching activities.
     */
    loadingIndicator: PropTypes.bool,

    /**
     * An optional title to render in the panel.
     */
    title: PropTypes.node,
  }),
  defaultProps({
    hideIfEmpty: false,
  }),

  withActivityListFetched,

  branch(
    ({ activityList, hideIfEmpty }) =>
      hideIfEmpty &&
      (!activityList ||
        !activityList.has('ids') ||
        !activityList.get('ids').count()),
    renderNothing,
  ),
)(ActivityListView);

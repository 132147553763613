import {
  USER_AVATAR_UPDATE_START,
  USER_AVATAR_UPDATE_SUCCESS,
  USER_AVATAR_UPDATE_FAILURE,
} from './actionTypes';
import { ajax } from '../../../actions/ajax';
import userSchema from '../schema';
import createAjaxAction from '../../../actions/createAjaxAction';

export const getUrl = ({ userId }) => {
  if (!userId) {
    throw new Error('updateAvatar must be called with a `userId`');
  }

  return `/users/${userId}.json`;
};

export const getRequestOptions = ({ user: { avatar } }) => {
  const formData = new FormData();
  formData.append('user[avatar]', avatar);

  return {
    method: 'PATCH',
    body: formData,
  };
};

const updateAvatar = createAjaxAction({
  ajax: ajax,
  getUrl: getUrl,
  getRequestOptions: getRequestOptions,
  schema: { user: userSchema },
  start: USER_AVATAR_UPDATE_START,
  success: USER_AVATAR_UPDATE_SUCCESS,
  failure: USER_AVATAR_UPDATE_FAILURE,
});

export default updateAvatar;

import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import EntityPropertyList from 'modules/entities/components/EntityPropertyList';
import industrySchema from 'modules/tenant/schemas/industries';
import mapSearchIdToSearch from './mapSearchIdToSearch';

/**
 * Renders a list of the industries related to a search
 */
export default compose(
  setDisplayName('SearchIndustryNameList'),
  setPropTypes({
    /**
     * The ID of the search to show the industries for.
     */
    searchId: PropTypes.number.isRequired,
  }),
  mapSearchIdToSearch,
  mapProps(({ search }) => ({
    entityIds: search && search.get('industries'),
    entityType: industrySchema.key,
  })),
  branch(({ entityIds }) => !entityIds, renderNothing),
)(EntityPropertyList);

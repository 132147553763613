import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, onlyUpdateForPropTypes, setDisplayName } from 'recompose';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import {
  DASHBOARD_VIEW_TYPES,
  DASHBOARD_VIEW_LABELS,
  DASHBOARD_VIEW_ICONS,
  DASHBOARD_VIEW_DEFAULT,
} from '../constants';

/**
 * Renders a group of buttons that can be used to select the view type to displaying a collection
 * of talent pools/contacts.
 */
class ViewTypeSelect extends Component {
  /**
   * Called when a view type button is clicked.
   */
  handleChange = event => {
    const { onChange } = this.props;
    onChange(event.currentTarget.value);
  };

  render() {
    const { componentId, value } = this.props;

    return (
      <div className='talent-pools--view-type-select btn-group btn-group-sm'>
        {DASHBOARD_VIEW_TYPES.map(viewType => (
          <OverlayTrigger
            key={viewType}
            overlay={
              <Tooltip id={`${componentId}-${viewType}-tooltip`}>
                {DASHBOARD_VIEW_LABELS[viewType]}
              </Tooltip>
            }
            placement='top'
          >
            <button
              className={classnames('btn', 'btn-default', 'btn-toggle', {
                active: value === viewType,
              })}
              onClick={this.handleChange}
              title={DASHBOARD_VIEW_LABELS[viewType]}
              type='button'
              value={viewType}
            >
              <i className={classnames('fa', DASHBOARD_VIEW_ICONS[viewType])} />
            </button>
          </OverlayTrigger>
        ))}
      </div>
    );
  }
}

ViewTypeSelect.propTypes = {
  componentId: PropTypes.string.isRequired,

  /**
   * Callback called when the selected value changes.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The currently selected value
   */
  value: PropTypes.oneOf(DASHBOARD_VIEW_TYPES),
};

ViewTypeSelect.defaultProps = {
  value: DASHBOARD_VIEW_DEFAULT,
};

export default compose(
  setDisplayName('connect(ViewTypeSelect)'),
  withComponentId(),
  onlyUpdateForPropTypes,
)(ViewTypeSelect);

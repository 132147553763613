import PropTypes from 'prop-types';
import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import PrimaryPositionModal from './PrimaryPositionModal';
import EditButton from '../../../../components/contact/header/EditButton';

const PrimaryPositionModalButton = ({
  contactId,
  isModalOpen,
  onCloseModal,
  onOpenModal,
  positionId,
}) => (
  <div className='PrimaryPositionModalButton'>
    <EditButton onClick={onOpenModal} />
    {isModalOpen && (
      <PrimaryPositionModal
        contactId={contactId}
        onClose={onCloseModal}
        positionId={positionId}
      />
    )}
  </div>
);

PrimaryPositionModalButton.propTypes = {
  contactId: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  positionId: PropTypes.number,
};

export default compose(
  withState('isModalOpen', 'setModalOpen', false),
  withHandlers({
    onOpenModal: ({ setModalOpen }) => () => setModalOpen(true),
    onCloseModal: ({ setModalOpen }) => () => setModalOpen(false),
  }),
)(PrimaryPositionModalButton);

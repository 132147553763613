import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import NotificationsList from './NotificationsList';
import NotificationsLoader from './NotificationsLoader';
import NotificationsEmpty from './NotificationsEmpty';

/**
 * Find's the closest scrolling parent to given node, returns the window
 * if the document body is scroll parent.)
 */
function findScrollParent(node) {
  if (node === null || node.tagName === 'BODY') {
    return window;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }

  return findScrollParent(node.parentNode);
}

class NotificationsScrollList extends Component {
  constructor(props) {
    super(props);

    this.setContainerRef = this.setContainerRef.bind(this);
    this.setScrollDetectorRef = this.setScrollDetectorRef.bind(this);
    this.attachScrollListener = this.attachScrollListener.bind(this);
    this.detachScrollListener = this.detachScrollListener.bind(this);
    this.scrollListener = this.scrollListener.bind(this);
  }

  componentDidMount() {
    setTimeout(this.attachScrollListener);
  }

  componentDidUpdate() {
    this.attachScrollListener();
  }

  componentWillUnmount() {
    this.detachScrollListener();
  }

  setContainerRef(element) {
    this._containerElement = element;
  }

  getRef() {
    return this._containerElement;
  }

  setScrollDetectorRef(element) {
    this._scrollDetectorElement = element;
  }

  attachScrollListener() {
    const { notifications } = this.props;
    if (
      notifications.getIn(['meta', 'isFetching']) ||
      !notifications.getIn(['meta', 'hasMore'])
    ) {
      // No need to listen for events while we're loading or if we know
      // we don't have any more results to load.
      return;
    }

    this.scrollParent = findScrollParent(this._containerElement);

    if (this.scrollParent) {
      this.scrollParent.addEventListener('scroll', this.scrollListener);
      this.scrollParent.addEventListener('resize', this.scrollListener);
      this.scrollListener();
    }
  }

  detachScrollListener() {
    if (this.scrollParent) {
      this.scrollParent.removeEventListener('scroll', this.scrollListener);
      this.scrollParent.removeEventListener('resize', this.scrollListener);
      delete this.scrollParent;
    }
  }

  /**
   * Fired when a scroll (or resize) event occurs
   */
  scrollListener() {
    if (this.scrollParent) {
      // This seems to be the most consistent way to determine if we've scrolled
      // to the bottom of our list. By calling `document.elementFromPoint`, we
      // can get the actual element that is in the browser's viewport.
      // This seems to work much better than trying to calculate the position of
      // an element relative to it's parent (which also includes the problem of
      // determing whether our element is actually even visisble, since it may
      // be covered by some other element, such as when it's scroll parent is
      // some other element)
      const scrollDetectorRect = this._scrollDetectorElement.getBoundingClientRect();
      const bottomElement = document.elementFromPoint(
        scrollDetectorRect.left,
        scrollDetectorRect.top,
      );
      if (bottomElement === this._scrollDetectorElement) {
        const { onFetchNextPage } = this.props;
        this.detachScrollListener();
        onFetchNextPage();
      }
    }
  }

  render() {
    const {
      label,
      notifications,
      onNotificationAction,
      onUpdateRead,
      scrollDetectionBuffer,
    } = this.props;

    const isFetching = notifications.getIn(['meta', 'isFetching']);
    const error = notifications.getIn(['meta', 'error']);
    const hasMore = notifications.getIn(['meta', 'hasMore']);
    const items = notifications.get('data');

    // While setting styles inline is generally not preferred, in this case
    // they are functionally needed by our code to detect when our scrollDetector
    // element has scrolled into view. The scrollDetector MUST have at least a
    // 1px height to be detected by the `elementFromPoint` call.
    return (
      <div className='notifications-list' ref={this.setContainerRef}>
        {!items || !items.size ? null : (
          <NotificationsList
            notifications={items}
            onNotificationAction={onNotificationAction}
            onUpdateRead={onUpdateRead}
            shouldManageReadStatus={true}
            showTitleLink={true}
          />
        )}
        {notifications && notifications.length === 0 ? (
          <NotificationsEmpty label={label} />
        ) : null}
        {isFetching || (!error && hasMore) ? (
          <NotificationsLoader isFetching={isFetching} />
        ) : null}
        <div key='scrollDetectionWrapper' style={{ position: 'relative' }}>
          <div
            ref={this.setScrollDetectorRef}
            style={{
              height: `${scrollDetectionBuffer + 1}px`,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
        </div>
      </div>
    );
  }
}

NotificationsScrollList.propTypes = {
  /**
   * An optional label to distinguish this particular list of notifications.
   * This is passed through to the 'empty' state to qualify which notifications
   * are empty.
   */
  label: PropTypes.string,

  /**
   * The notifications that should be displayed by this list
   */
  notifications: ImmutablePropTypes.mapContains({
    meta: ImmutablePropTypes.mapContains({
      count: PropTypes.number,
      isFetching: PropTypes.bool,
      isInvalidated: PropTypes.bool,
      error: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
          message: PropTypes.string,
        }),
      ]),
    }),
  }).isRequired,

  onFetchNextPage: PropTypes.func.isRequired,

  onNotificationAction: PropTypes.func.isRequired,

  onUpdateRead: PropTypes.func,

  /**
   * The buffer (number of pixels) from the bottom of the list to extend the
   * scroll detection buffer. This reduces the amount that must be scrolled
   * down before more notifications are loaded.
   */
  scrollDetectionBuffer: PropTypes.number,
};

NotificationsScrollList.defaultProps = {
  scrollDetectionBuffer: 20,
};

export default NotificationsScrollList;

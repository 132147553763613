/* eslint-disable func-names */
import initSelect2 from '../lib/select2';
import notify from '../lib/notify';

export default {
  prepare: () =>
    $(document).on('click', '.searches-dashboard-sort a', function (e) {
      e.preventDefault();
      return $.get($(this).attr('href'), data => {
        return $(this).closest('table').replaceWith(data);
      });
    }),
  initialize: function () {
    $('.add-remove-candidate, .dashboard-buttons > .btn').tooltip({
      container: 'body',
    });

    const viewMoreLess = function (
      elements,
      className = 'show-more',
      lessText = 'View Less',
      moreText = 'View More',
    ) {
      const toggleState = function ($el, $trigger) {
        if ($el.hasClass(className)) {
          $el.removeClass(className);
          return $trigger.html(moreText);
        } else {
          $el.addClass(className);
          return $trigger.html(lessText);
        }
      };

      const initialize = function (key, el) {
        const $el = $(el);
        const $trigger = $el.find('.view-more-less');
        return $trigger.on('click', toggleState.bind(this, $el, $trigger));
      };

      return $(elements).each(initialize);
    };

    viewMoreLess('.searches-by-company');

    $('#dashboard-companies').on(
      'ajax:before ajax:complete',
      '.js-add-company,.js-remove-company',
      function (xhr, status) {
        const icon = $(this).hasClass('js-add-company') ? 'plus' : 'minus';
        $(this).find('.fa').toggleClass(`fa-${icon} fa-circle-o-notch fa-spin`);
        return $(this).attr('disabled', status == null);
      },
    );

    $('#dashboard-companies').on(
      'ajax:success',
      '.js-add-company, .js-remove-company',
      function (_xhr, _status, _error) {
        const id = $(this).data('company-id');
        return $(`[data-company-id=${id}]`).toggle();
      },
    );

    $('#dashboard-companies').on(
      'ajax:error',
      '.js-add-company,.js-remove-company',
      () => notify('There was a problem sending your request', 'error'),
    );

    // Thrive IQ and Research Library dashboards page initialize select2
    initSelect2('#select', ['.js-select2']);

    // Thrive IQ dashboards page submits form with dropdown selection to change the SisenseChart displayed in the iframe
    $('#thrive-iq-dashboard__chart-select').on('select2:select', _e =>
      document.getElementById('thrive-iq-dashboard__chart-form').submit(),
    );

    // Research Library dashboards page submits form with dropdown selection to change the SisenseChart displayed in the iframe
    $('#research-library-dashboard__chart-select').on('select2:select', _e =>
      document
        .getElementById('research-library-dashboard__chart-form')
        .submit(),
    );
  },
};

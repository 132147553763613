import shouldFetchEntity from 'modules/entities/selectors/shouldFetchEntity';
import jobApplicationSchema from '../schema';

/**
 * Determines if a job application record needs to be fetched from the server.
 * @param {Object} state
 * @param {Number} jobApplicationId The ID of the job applications.
 * @return {Boolean} True if the job application record needs to be fetched from the server;
 *   Otherwise, false.
 */
export default (state, jobApplicationId) =>
  shouldFetchEntity(state, jobApplicationSchema.key, jobApplicationId);

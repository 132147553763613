/* eslint-disable react/jsx-handler-names */
// ^ legacy violations
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import ClipboardTrigger from './ClipboardTrigger';
import Modal from './Modal';

/**
 * Renders a button that, when clicked, displayed a modal that has an input field with a text
 * value that is automatically copied to the clipboard.
 * A message gets displayed for short period of time (1000 ms) indicating that the text has
 * been copied.
 * TODO: This is a very weird component with a lot of confusing props that can likely be
 * cleaned up quite a bit.
 */
class ClipboardModalButton extends Component {
  constructor(props) {
    super(props);
    this.messageIds = [];
    this.componentId = uniqueId('ClipboardModalButton');
    this.state = {
      showModal: false,
    };
  }

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  render() {
    const {
      buttonClassName,
      children,
      className,
      inputClassName,
      inputValue,
      placement,
      text,
      title,
      tooltip,
      triggerToast,
    } = this.props;

    const { showModal } = this.state;

    return (
      <div>
        <OverlayTrigger
          overlay={<Tooltip id={this.componentId}>{tooltip}</Tooltip>}
          placement={placement || 'left'}
        >
          <button
            className={buttonClassName}
            onClick={this.toggleModal}
            type='button'
          >
            <ClipboardTrigger text={text}>{children}</ClipboardTrigger>
          </button>
        </OverlayTrigger>

        <Modal
          className={classnames('clipboard-modal', className)}
          onHide={this.toggleModal}
          show={showModal}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              className={classnames(inputClassName, 'form-control')}
              readOnly={true}
              type='text'
              value={inputValue}
            />
          </Modal.Body>
          <Modal.Footer>
            <ClipboardTrigger onSuccess={triggerToast} text={text}>
              <button
                className='btn btn-secondary-outline'
                onClick={this.toggleModal}
                type='button'
              >
                Copy Link & Close
              </button>
            </ClipboardTrigger>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ClipboardModalButton.defaultProps = {
  buttonClassName: null,
  className: null,
  inputClassName: null,
  inputValue: null,
  placement: null,
  text: null,
};

ClipboardModalButton.propTypes = {
  /**
   * The class name to apply to the button.
   */
  buttonClassName: PropTypes.string,

  /**
   * The content to display in the button
   */
  children: PropTypes.node.isRequired,

  /**
   * The class name to apply to the modal.
   */
  className: PropTypes.string,

  /**
   * The class name to apply to the input component.
   */
  inputClassName: PropTypes.string,

  /**
   * The value to display in the input component.
   * Typically this would be the same as text?
   */
  inputValue: PropTypes.string,

  /**
   * The placement of the tooltip that is shown when hovering over the button.
   */
  placement: PropTypes.string,

  /**
   * The text to copy to the clipboard.
   */
  text: PropTypes.string,

  /**
   * The title of the modal.
   */
  title: PropTypes.string.isRequired,

  /**
   * The tooltip to show when hovering over the button
   */
  tooltip: PropTypes.string.isRequired,
  /**
   * The redux action that renders a success toast when the url is copied.
   * when clicked.
   */
  triggerToast: PropTypes.func.isRequired,
};

const mapDispatch = dispatch => ({
  triggerToast: () => dispatch(toastSuccess('Copied!')),
});

export default connect(null, mapDispatch)(ClipboardModalButton);

import { connect } from 'react-redux';
import getPositionList from '../../selectors/positions/getPositionList';

/**
 * A higher order component which maps a contact ID to it's positions list.
 */
export default connect(
  (state, { contactId }) => ({
    positionList: contactId && getPositionList(state, contactId),
  }),
  {},
);

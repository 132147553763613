import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '@thrivetrm/ui/components/Card';
import SubmitButton from 'modules/forms/components/SubmitButton';
import Form from 'modules/forms/components/Form';
import FieldState from 'modules/forms/FieldState';
import FormState from 'modules/forms/FormState';

const CandidatePortalConsentRevokeConfirmation = () => {
  const { contactId } = useParams();

  const fieldState = new FieldState();
  const [formState, setFormState] = useState(FormState.create(fieldState));

  const handleRevokeConsent = async event => {
    event.preventDefault();
    setFormState(prev => prev.startSubmit(contactId));

    try {
      window.location = `/contacts/${contactId}/revoke_consent_success?portal=true`;
    } catch (error) {
      console.error('Error revoking consent:', error);
      setFormState(prev => prev.endSubmit(error));
    }
  };

  const handleGiveConsent = event => {
    event.preventDefault();
    window.location = `/contacts/${contactId}/consent?give_consent=true`;
  };

  return (
    <div className='CandidatePortalConsentRevokeConfirmation container'>
      <Card
        className='u-marginTop-24 u-padding-24 d-flex flex-column'
        type='shadow'
      >
        <div className='u-textAlign-l mb-3'>
          <h3 className='u-textColor-blue u-textAlign-c'>Are You Sure?</h3>
          <p>
            We understand that you do not want to give consent for the use of
            your personal data.
          </p>
          <p>
            Once your data is removed, you will no longer be considered for any
            current or future job opportunities. This action cannot be undone.
          </p>
          <p>Are you sure you want to remove your data?</p>

          <div
            className='button-container'
            style={{ display: 'flex', gap: '10px', marginTop: '20px' }}
          >
            <Form formState={formState} onSubmit={handleRevokeConsent}>
              <SubmitButton formState={formState}>
                Yes, Remove My Data
              </SubmitButton>
            </Form>
            <Form formState={formState} onSubmit={handleGiveConsent}>
              <SubmitButton
                formState={formState}
                style={{
                  backgroundColor: 'white',
                  color: 'blue',
                }}
              >
                No, I Want To Give Consent
              </SubmitButton>
            </Form>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CandidatePortalConsentRevokeConfirmation;

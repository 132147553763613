import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import emailTemplateSchema from '../schema';

/**
 * Gets the ID of the user who created an email template.
 * @param {*} state
 * @param {Number} id The email template ID.
 * @return {Number} The ID of the user who created the email template
 */
export default (state, id) =>
  getEntityProperty(state, emailTemplateSchema.key, id, 'creator');

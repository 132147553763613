import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import Icon from '@thrivetrm/ui/components/Icon';
import { camelCaseToWords } from 'modules/core/jsonUtils';

const ConnectionFilterDropDown = ({
  connectionFilterOptions,
  isLoadingConnectionOptions,
  setConnectionFilterOptions,
}) => {
  const handleChange = (section, id, value) => {
    setConnectionFilterOptions(oldValue => {
      return {
        ...oldValue,
        [section]: oldValue[section].map(option =>
          option.value === id ? { ...option, isChecked: value } : option,
        ),
      };
    });
  };

  return (
    <Dropdown id='connectionFilter'>
      <Dropdown.Toggle className='ContactConnections__filterLabel connectionFilterDropDown'>
        Filter by Type or Strength
        <Icon className='u-paddingLeft-8' type='dropdown' />
      </Dropdown.Toggle>

      <Dropdown.Menu className='ContactConnections__filterList'>
        {isLoadingConnectionOptions ? (
          <div className='u-paddingHorizontal-8'>Loading...</div>
        ) : (
          Object.keys(connectionFilterOptions).map(section => (
            <React.Fragment key={section}>
              <div className='u-fontWeight-bold u-paddingHorizontal-8 u-paddingTop-4 '>
                {camelCaseToWords(section)}
              </div>
              <div className='divider u-marginVertical-4' />
              {connectionFilterOptions[section]?.map(option => (
                <div
                  className='checkbox u-paddingHorizontal-8'
                  key={`${section}-${option.value}`}
                >
                  <label htmlFor={`${section}-${option.value}`}>
                    <input
                      checked={option?.isChecked || false}
                      id={`${section}-${option.value}`}
                      onChange={event =>
                        handleChange(
                          section,
                          option.value,
                          event.target.checked,
                        )
                      }
                      type='checkbox'
                    />
                    {option.label}
                  </label>
                </div>
              ))}
            </React.Fragment>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const connectionFilterOptionShape = PropTypes.shape({
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number,
});

ConnectionFilterDropDown.propTypes = {
  connectionFilterOptions: PropTypes.shape({
    ConnectionStrength: PropTypes.arrayOf(connectionFilterOptionShape),
    ConnectionType: PropTypes.arrayOf(connectionFilterOptionShape),
  }),
  isLoadingConnectionOptions: PropTypes.bool,
  setConnectionFilterOptions: PropTypes.func,
};

export default ConnectionFilterDropDown;

import { compose, mapProps, setDisplayName, withProps } from 'recompose';
import withFormState from 'modules/forms/components/withFormState';
import RichTextField from 'components/forms/richtext/RichTextField';
import { connect } from 'react-redux';

export default compose(
  setDisplayName('withEmailSignatureForm'),
  withProps(props => ({
    withEmailSignatureFormProps: props,
  })),

  connect(state => ({
    emailSignature: state.user.toJS().email_signature?.content,
  })),

  withFormState(({ draft, emailSignature }) =>
    RichTextField.createFieldState(
      'email_signature',
      draft?.content || emailSignature,
    ),
  ),

  mapProps(
    ({
      formState,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      withEmailSignatureFormProps,
    }) => ({
      ...withEmailSignatureFormProps,
      formState: formState,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onResetFormState: onResetFormState,
    }),
  ),
);

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import Form from '@thrivetrm/ui/components/Form';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import ConnectionForm from 'modules/connections/components/ConnectionForm';
import { markAsStale } from 'modules/recordIndex/recordIndexSlice';
import QuickViewPanelHeader from 'modules/quick-view/QuickViewPanelHeader';
import { fetchContact } from 'modules/contacts/actions/contacts/index';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import {
  RecordTypesWithConnections,
  useCreateConnectionMutation,
  useGetConnectionStrengthsQuery,
  useGetConnectionTypesQuery,
} from 'services/apiV1/connections';
import { useGetContactQuery } from 'services/apiV1/contact';
import ContactQvpHeader from '../ContactQvpHeader';
import useQuickView from '../useQuickView';

const ConnectionAddForm = ({ connectionRecordType }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { close, navigateTo, pathParams } = useQuickView();
  const { contactId } = pathParams;

  const queryParams = new URLSearchParams(location.search);
  const selectedTab = queryParams.get('selectedTab');
  const closePanelOnCancel = queryParams.get('closePanelOnCancel');
  const strength = queryParams.get('strength');
  const connectionType = queryParams.get('connectionType');
  const suggestedUser = queryParams.get('suggestedUser');
  const {
    data: contactData,
    isFetching: isLoadingContact,
  } = useGetContactQuery(contactId);

  const {
    data: connectionTypes,
    isFetching: isLoadingConnectionTypes,
  } = useGetConnectionTypesQuery();
  const {
    isFetching: isLoadingConnectionStrengths,
  } = useGetConnectionStrengthsQuery();

  const [
    createConnection,
    { error: createConnectionError },
  ] = useCreateConnectionMutation();

  const handleBack = createdSuggestedConnection => {
    navigateTo(
      `/contact/${contactId}/connections/?selectedTab=${selectedTab}&&createdSuggestedConnection=${
        createdSuggestedConnection ? 1 : 0
      }`,
    );
  };

  const handleSubmit = formState => {
    const payload = {
      connected_contact_id:
        connectionRecordType === 'contact'
          ? formState.connectedRecord.id
          : null,
      connected_on: moment.utc({
        year: formState.year,
        month: formState.month,
      }),
      connected_user_id:
        connectionRecordType === 'user' ? formState.connectedRecord.id : null,
      connection_strength_id: formState.strength,
      connection_type_id: formState.connectionType,
      notes: formState.notes,
      contact_id: contactId,
      referral_company_id: formState.referralCompany?.id || null,
    };

    // dispatch(createConnection(payload));
    createConnection({ contactId: contactId, payload: payload })
      .unwrap()
      .then(() => {
        dispatch(toastSuccess('Connection Created'));
        handleBack(true);
        dispatch(markAsStale());
        dispatch(fetchContact({ id: contactId }));
      });
  };

  const initialValues = {
    connectionId: null,
    connectedRecord: null,
    connectionType: closePanelOnCancel
      ? connectionTypes?.find(({ name }) => name === 'Currently Manages')?.id
      : connectionTypes?.find(({ name }) => name === connectionType)?.id,
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    referralCompany: {},
    suggestedUser: suggestedUser ? JSON.parse(suggestedUser) : null,
    strength: parseInt(strength) || null,
    notes: null,
  };

  return (
    <>
      <ContactQvpHeader contactId={contactId} onBack={handleBack} />
      <QuickViewPanelHeader title='Add Connection' />
      <Form onSubmit={handleSubmit}>
        <SidePanel.Body className='u-paddingTop-n'>
          <LoadingContainer
            className='u-paddingTop-12'
            isLoading={
              isLoadingContact ||
              isLoadingConnectionTypes ||
              isLoadingConnectionStrengths
            }
            minHeight='100%'
          >
            {isLoadingContact || !contactData ? null : (
              <ConnectionForm
                connectionRecordType={connectionRecordType}
                contactData={contactData}
                initialValues={initialValues}
              />
            )}
            {createConnectionError ? (
              <Card type='error'>{createConnectionError?.data?.errors}</Card>
            ) : null}
          </LoadingContainer>
        </SidePanel.Body>

        <SidePanel.Footer>
          <ButtonPrimary
            isOutline={true}
            label='Cancel'
            onClick={closePanelOnCancel ? close : handleBack}
          />
          <Form.SubmitButton isLoading={isLoadingContact} label='Create' />
        </SidePanel.Footer>
      </Form>
    </>
  );
};

ConnectionAddForm.propTypes = {
  connectionRecordType: PropTypes.oneOf(
    Object.values(RecordTypesWithConnections),
  ),
};

export default ConnectionAddForm;

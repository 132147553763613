import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';

import ContactSelectField from 'modules/contacts/components/ContactSelectField';
import getFullName from 'modules/contacts/selectors/contacts/getFullName';
import DateInputField from 'modules/datetime/components/DateInputField';
import TimeInputField from 'modules/datetime/components/TimeInputField';
import FieldState from 'modules/forms/FieldState';
import * as validators from 'modules/forms/validators';
import SearchSelectField from 'modules/searches/components/SearchSelectField';
import UserSelectField from 'modules/users/components/UserSelectField';
import { FILTER_CRM_USER_AND_EMPLOYEES } from 'modules/users/constants';

import RichTextField from '../../../components/forms/richtext/RichTextField';

/**
 * Identifies the wrapper classes to apply to each field for different "layouts", so that we
 * can arrange fields differently as needed.
 */
const FIELD_LAYOUTS = {
  wide: {
    introducedBy: 'col-md-6 col-12',
    introducedTo: 'col-md-6 col-12',
    date: 'col-md-2 col-6',
    time: 'col-md-2 col-6',
    search: 'col-md-8 col-12',
    notes: 'col-12',
  },
  narrow: {
    introducedBy: 'col-12',
    introducedTo: 'col-12',
    date: 'col-6',
    time: 'col-6',
    search: 'col-12',
    notes: 'col-12',
  },
};

/**
 * Displays a form for editing or creating a introduction
 */
class IntroductionField extends PureComponent {
  static createFieldState(name = 'introduction', introduction, currentUserId) {
    const values = fromJS({
      notes: '',
    }).merge(introduction);

    const introductionDate = values.get('date')
      ? moment(values.get('date'), moment.ISO_8601)
      : moment();

    return FieldState.createNested(
      name,
      [
        FieldState.create('id', values.get('id')),
        ContactSelectField.createFieldState(
          'contact',
          values.get('contact'),
          validators.requiredField('Contact'),
          // Make sure we always assign just the ID -- the contact select can return a contact object.
          contact => (contact && contact.id) || contact,
        ),
        ContactSelectField.createFieldState(
          'introduced_to',
          values.get('introduced_to'),
          validators.requiredField('Introduced to'),
          introducedTo => (introducedTo && introducedTo.id) || introducedTo,
        ),
        SearchSelectField.createFieldState(
          'search',
          values.get('searches') && values.get('searches').first(),
        ),
        DateInputField.createFieldState('date', introductionDate),
        TimeInputField.createFieldState('time', introductionDate.format('LT')),
        RichTextField.createFieldState('notes', values.get('notes')),
        UserSelectField.createFieldState(
          'introduced_by_id',
          values.get('introduced_by_id') || currentUserId,
          validators.requiredField('Introduced by'),
        ),
      ],
      null,
      ({ date, time, ...other }) => {
        // Merges the `date` and `time` fields into `noted_on`
        const timeMoment = moment(time, 'LT');
        const dateMoment = moment(date, moment.ISO_8601);
        dateMoment.set({
          hour: timeMoment.get('hour'),
          minute: timeMoment.get('minute'),
          second: timeMoment.get('second'),
        });

        return {
          ...other,
          date: dateMoment.format(),
        };
      },
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      allowContactChange,
      contactName,
      draft,
      excludeContactField,
      excludeSearchField,
      fieldLayout,
      fieldState,
      introducedToName,
      // prevent onChange from being passed through to the input.
      onChange: _onChange,
      onDraftChange,
      povContactId,
      ...otherProps
    } = this.props;

    // When editing an existing introduction, if the povContat is the `introduced_to`
    // contact, then swap the fields so that the povContact is shown first.
    const swapContactFields =
      fieldState.getNestedField('id').getValue() &&
      povContactId === fieldState.getNestedField('introduced_to').getValue();

    const fieldClasses = FIELD_LAYOUTS[fieldLayout];

    return (
      <div className='introductions--introduction-field'>
        <div className='row'>
          {!excludeContactField && (
            <div
              className={classnames('contact', fieldClasses.introducedTo)}
              key='contact'
            >
              <ContactSelectField
                {...otherProps}
                allowCreate={true}
                disabled={
                  Boolean(fieldState.getNestedField('id').getValue()) ||
                  !allowContactChange
                }
                fieldState={fieldState.getNestedField(
                  swapContactFields ? 'introduced_to' : 'contact',
                )}
                label='Introduced*'
                onChange={this.handleFieldChange}
                placeholder={`* Introduced ${introducedToName}...`}
              />
            </div>
          )}
          <div
            className={classnames('introducedTo', fieldClasses.introducedTo)}
            key='introduced_to'
          >
            <ContactSelectField
              {...otherProps}
              allowCreate={true}
              disabled={Boolean(fieldState.getNestedField('id').getValue())}
              fieldState={fieldState.getNestedField(
                swapContactFields ? 'contact' : 'introduced_to',
              )}
              label={`${contactName} was introduced to*`}
              onChange={this.handleFieldChange}
            />
          </div>
          <div
            className={classnames('introducedBy', fieldClasses.introducedBy)}
          >
            <UserSelectField
              className='IntroductionField__introducedBy'
              fieldState={fieldState.getNestedField('introduced_by_id')}
              filter={FILTER_CRM_USER_AND_EMPLOYEES}
              label='By*'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className={classnames('date', fieldClasses.date)} key='date'>
            <DateInputField
              fieldState={fieldState.getNestedField('date')}
              label='*Date'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className={classnames('time', fieldClasses.time)} key='time'>
            <TimeInputField
              fieldState={fieldState.getNestedField('time')}
              label='*Time'
              onChange={this.handleFieldChange}
            />
          </div>
          {!excludeSearchField && (
            <div
              className={classnames('search', fieldClasses.search)}
              key='search'
            >
              <SearchSelectField
                {...otherProps}
                fieldState={fieldState.getNestedField('search')}
                key='search'
                label='Related To'
                onChange={this.handleFieldChange}
                placeholder='Related to a Search'
              />
            </div>
          )}
        </div>
        <div className='row' key='notes'>
          <div className='col-12 notes'>
            <RichTextField
              {...otherProps}
              fieldState={fieldState.getNestedField('notes')}
              label='Notes'
              lastUpdatedTime={draft?.lastUpdatedTime}
              onChange={this.handleFieldChange}
              onDraftChange={onDraftChange}
              placeholder='Enter notes...'
            />
          </div>
        </div>
      </div>
    );
  }
}

IntroductionField.propTypes = {
  allowContactChange: PropTypes.bool,

  contactName: PropTypes.string,
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),
  excludeContactField: PropTypes.bool,

  excludeNotesField: PropTypes.bool,

  excludeSearchField: PropTypes.bool,

  /**
   * How to layout the form fields (which col classes to apply)
   */
  fieldLayout: PropTypes.oneOf(Object.keys(FIELD_LAYOUTS)),

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  introducedToName: PropTypes.string,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  onDraftChange: PropTypes.func,

  povContactId: PropTypes.number,
};

IntroductionField.defaultProps = {
  allowContactChange: false,
  draft: null,
  excludeContactField: false,
  excludeNotesField: false,
  excludeSearchField: false,
  fieldLayout: 'narrow',
};

export default connect((state, { fieldState }) => {
  const contactField = fieldState.getNestedField('contact');
  const introducedToField = fieldState.getNestedField('introduced_to');
  const contact = contactField && contactField.getValue();
  const introducedTo = introducedToField && introducedToField.getValue();
  const contactId = contact && (contact.id || contact);
  const introducedToId = introducedTo && (introducedTo.id || introducedTo);

  return {
    contactId: contactId,
    introducedToId: introducedToId,
    contactName: contactId && getFullName(state, contactId),
    introducedToName: introducedToId && getFullName(state, introducedToId),
  };
}, null)(IntroductionField);

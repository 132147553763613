import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  pure,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import Link from 'modules/routing/components/Link';
import { SEARCH_PAGE_BASE_PATHS } from '../constants';
import mapSearchIdToSearchType from './mapSearchIdToSearchType';

/**
 * Renders a link to a search page.
 */
export default compose(
  setDisplayName('SearchLink'),
  setPropTypes({
    searchId: PropTypes.number.isRequired,
    subPage: PropTypes.oneOf(['candidates', 'edit']).isRequired,
  }),
  defaultProps({
    subPage: 'candidates',
    searchType: 'JobSearch',
  }),
  mapSearchIdToSearchType,
  mapProps(({ searchId, searchType, subPage, ...props }) => ({
    href: `${SEARCH_PAGE_BASE_PATHS[searchType]}/${searchId}/${subPage}`,
    useDefaultLinkBehavior: true,
    ...props,
  })),
  pure,
)(Link);

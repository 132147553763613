import candidacyCount from './candidacyCount';
import companyName from './companyName';
import incumbentName from './incumbentName';
import jobFunction from './jobFunction';
import jobTitle from './jobTitle';

import {
  SORT_BY_CANDIDACY_COUNT,
  SORT_BY_COMPANY,
  SORT_BY_INCUMBENT_NAME,
  SORT_BY_JOB_FUNCTION,
  SORT_BY_JOB_TITLE,
} from '../constants';

export default {
  [SORT_BY_CANDIDACY_COUNT]: candidacyCount,
  [SORT_BY_COMPANY]: companyName,
  [SORT_BY_INCUMBENT_NAME]: incumbentName,
  [SORT_BY_JOB_FUNCTION]: jobFunction,
  [SORT_BY_JOB_TITLE]: jobTitle,
};

import PropTypes from 'prop-types';
import React from 'react';
import { compose, withProps } from 'recompose';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import Modal from 'modules/core/componentsLegacy/Modal';
import withCompanyLogoForm from './withCompanyLogoForm';
import CompanyLogoField from './CompanyLogoField';

/**
 * Renders the CompanyLogoForm in a modal window for creating or editing a logo.
 */
const CompanyLogoEditModal = ({
  formState,
  onFieldStateChange,
  onHide,
  onSubmit,
  show,
  submitButtonLabel,
  title,
  ...fieldProps
}) => (
  <Modal className='CompanyLogoEditModal' onHide={onHide} show={show}>
    <Form
      className='CompanyLogoEditModal__Form'
      formState={formState}
      onSubmit={onSubmit}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CompanyLogoField
          {...fieldProps}
          disabled={formState.isSubmitting()}
          fieldState={formState.getFieldState()}
          onChange={onFieldStateChange}
          showErrors={formState.wasSubmitted() || 'blurred'}
        />
        <FormErrorMessage formState={formState} />
      </Modal.Body>
      <Modal.Footer>
        <CancelButton bsSize='lg' onClick={onHide} />
        <SubmitButton bsSize='lg' formState={formState} onClick={onSubmit}>
          {submitButtonLabel}
        </SubmitButton>
      </Modal.Footer>
    </Form>
  </Modal>
);

CompanyLogoEditModal.propTypes = {
  formState: formStatePropType.isRequired,
  onFieldStateChange: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  title: PropTypes.string,
};

CompanyLogoEditModal.defaultProps = {
  show: false,
  submitButtonLabel: 'Save',
  title: 'Company Logo Upload',
};

export default compose(
  withProps(({ onHide }) => ({
    onSaved: onHide,
    onCancel: onHide,
  })),
  withCompanyLogoForm,
)(CompanyLogoEditModal);

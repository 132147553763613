import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import routes from 'modules/routing/routes';
import Card from '@thrivetrm/ui/components/Card';
import DateElement from '@thrivetrm/ui/components/Date';
import Form from '@thrivetrm/ui/components/Form';
import getCurrentUserId from 'modules/user/selectors/getCurrentUserId';

import { useGetSearchQuery } from 'services/apiV1/searches';

const RecruitmentRatingsForm = ({
  endDate,
  initialValues,
  milestone,
  searchId,
  searchName,
}) => {
  const currentUserId = useSelector(getCurrentUserId);
  const { data: searchData } = useGetSearchQuery(searchId);

  return (
    <>
      {/*
      The label is being added manually here instead of on Form.Rating because
      designs explicitly need the label outside of the card
      */}
      <label
        className='RecruitmentRatingsAddForm__ratingLabel'
        htmlFor='recruitment-rating'
      >
        * Rating
      </label>
      <Card className='u-padding-24 u-marginBottom-24' isCentered={false}>
        <Form.Rating
          className='u-block u-marginBottom-16'
          id='recruitment-rating'
          initialValue={initialValues?.rating}
          name='rating'
          rules={{
            required: {
              value: true,
              message: 'A rating must be selected for this search',
            },
          }}
        />
        <strong>
          <a href={`/searches/${searchId}/candidates`}>{searchName}</a>
        </strong>
        <p className='u-margin-n'>
          <strong>
            {searchData?.jobTitle} <DateElement date={endDate} />
          </strong>
        </p>
        <p className='u-margin-n'>
          <strong>{milestone}</strong>
        </p>
      </Card>
      <Form.TextArea
        className='u-block u-marginBottom-24'
        initialValue={initialValues?.notes}
        label='Notes'
        name='notes'
        placeholder='Enter notes'
        rules={{
          required: {
            value: true,
            message: 'Notes are required when adding a rating',
          },
        }}
        width='full'
      />
      <Form.Autocomplete
        className='u-marginBottom-16 u-block'
        formatOption={option =>
          option.name ? `${option.name} (${option.email})` : option.email
        }
        initialValue={initialValues?.ratedBy}
        label='Rated By'
        name='ratedBy'
        onSelect={_val => {}}
        placeholder='Who is submitting this rating?'
        rules={{
          required: {
            value: true,
            message: 'A user must be selected for this rating',
          },
        }}
        selectedValueDisplayPropertyFallbacks={['name', 'email']}
        url={query =>
          routes.api_v1_autocomplete_query({
            query: {
              query: {
                resource: 'user',
                excluded_user_roles: ['External Recruiter'],
                term: query || currentUserId,
              },
            },
          })
        }
      />
    </>
  );
};

RecruitmentRatingsForm.propTypes = {
  endDate: PropTypes.string,
  initialValues: PropTypes.shape({
    notes: PropTypes.string,
    ratedBy: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    rating: PropTypes.number,
  }).isRequired,
  milestone: PropTypes.string,
  searchId: PropTypes.number,
  searchName: PropTypes.string,
};

export default RecruitmentRatingsForm;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import FieldState from 'modules/forms/FieldState';
import AddressField from 'modules/addresses/components/AddressField';
import PrimaryAddressCheckboxField from 'modules/addresses/components/PrimaryAddressCheckboxField';

/**
 * Displays a field for editing a company's address.
 */
class CompanyAddressField extends PureComponent {
  static createFieldState(
    name = 'company-address',
    { address, addressId, primaryAddressId },
  ) {
    return FieldState.createNested(
      name,
      [
        PrimaryAddressCheckboxField.createFieldState(
          'primary',
          addressId && addressId === primaryAddressId,
        ),
        AddressField.createFieldState('address', address),
      ],
      null,
      ({ address: addressAttributes, primary }) => ({
        primary: primary,
        ...addressAttributes,
      }),
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      addressId,
      companyId,
      fieldState,
      // Ignored fields to exclude from ...otherProps
      /* eslint-disable no-unused-vars */
      onChange,
      primaryAddressId,
      /* eslint-enable no-unused-vars */
    } = this.props;

    return (
      <div className='CompanyAddressField'>
        <PrimaryAddressCheckboxField
          addressId={addressId}
          checked={fieldState.getNestedField('primary').getValue()}
          fieldState={fieldState.getNestedField('primary')}
          onChange={this.handleFieldChange}
          parentId={companyId}
          parentType='Company'
        />
        <AddressField
          fieldState={fieldState.getNestedField('address')}
          onChange={this.handleFieldChange}
          showLabelField={true}
        />
      </div>
    );
  }
}

CompanyAddressField.propTypes = {
  addressId: PropTypes.number,
  companyId: PropTypes.number,
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  /**
   * the primary address ID of the parent
   */
  primaryAddressId: PropTypes.number,
};

export default CompanyAddressField;

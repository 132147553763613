import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';

import NoTargetCompanyCard from './NoTargetCompanyCard';
import TargetCompanyCard from './TargetCompanyCard';

// TODO: Remove the need for id='target-companies'
const TargetCompanyGrid = ({
  cardsPerRow,
  onTargetCompanySelect,
  orphanCount,
  targetCompanyIds,
}) => (
  <div className='target-companies--target-company-grid container-fluid'>
    <div className='row' id='target-companies'>
      <div
        className={classnames({
          'col-md-12 col-lg-6': cardsPerRow === 2,
          'col-sm-4': cardsPerRow === 3,
          'col-sm-3': cardsPerRow === 4,
        })}
        key='none'
      >
        <NoTargetCompanyCard
          onOrphanCountClick={onTargetCompanySelect}
          orphanCount={orphanCount}
        />
      </div>
      {targetCompanyIds
        .map(targetCompanyId => (
          <div
            className={classnames({
              'col-md-12 col-lg-6': cardsPerRow === 2,
              'col-sm-4': cardsPerRow === 3,
              'col-sm-3': cardsPerRow === 4,
            })}
            key={targetCompanyId}
          >
            <TargetCompanyCard
              onCandidacyCountClick={onTargetCompanySelect}
              targetCompanyId={targetCompanyId}
            />
          </div>
        ))
        .toArray()}
    </div>
  </div>
);

TargetCompanyGrid.propTypes = {
  cardsPerRow: PropTypes.oneOf([2, 3, 4]),
  onTargetCompanySelect: PropTypes.func.isRequired,
  orphanCount: PropTypes.number.isRequired,
  targetCompanyIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
};

TargetCompanyGrid.defaultProps = {
  cardsPerRow: 3,
};

export default TargetCompanyGrid;

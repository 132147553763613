import getTenantType from 'modules/tenant/selectors/getTenantType';
import { FEATURE_ENABLED_TENANT_TYPES } from '../constants';

/**
 * Determines if the current tenant has the portfolio companies feature available.
 * @param {*} state
 * @returns {Boolean} True if the current tenant has the portfolio details feature.
 */
export default state =>
  FEATURE_ENABLED_TENANT_TYPES.includes(getTenantType(state));

import { fromJS } from 'immutable';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

const handleSubmit = (formState, props) => {
  const {
    note,
    noteActions: { createNote, updateNote },
    parentId,
    parentType,
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (
    note || fromJS({ notable_type: parentType, notable_id: parentId })
  ).merge(fieldValue);

  const action = values.get('id') ? updateNote : createNote;

  action({
    note: values.toJS(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export default handleSubmit;

import { convertToCamelCase } from 'modules/core/jsonUtils';
import apiV1 from './index';

const candidateAssessment = apiV1.injectEndpoints({
  endpoints: builder => ({
    getCandidateAssessment: builder.query({
      query: assessmentId => ({
        url: `/candidacy_assessments/${assessmentId}`,
        method: 'GET',
      }),
      transformResponse: response => convertToCamelCase(response.assessment),
    }),
    getTemplate: builder.query({
      query: templateId => ({
        url: `/assessment_templates/${templateId}`,
        method: 'GET',
      }),
      transformResponse: response =>
        convertToCamelCase(response.assessment_template),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCandidateAssessmentQuery,
  useGetTemplateQuery,
} = candidateAssessment;

import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import CompanyLogo from './CompanyLogo';
import CompanyLogoEditModal from './CompanyLogoEditModal';

/**
 * Renders a button that displays the company's current logo. When the button is clicked a
 * `CompanyLogoEditModal` is displayed allowing the logo to be changed or removed.
 */
const CompanyLogoEditButton = ({
  companyId,
  onHideModal,
  onShowModal,
  showModal,
  thumbnail,
}) => [
  <button
    className='CompanyLogoEditButton btn btn-link'
    key='button'
    onClick={onShowModal}
    type='button'
  >
    <i className='CompanyLogoEditButton__indicator fa fa-pencil' />
    <CompanyLogo companyId={companyId} thumbnail={thumbnail} />
  </button>,
  showModal && (
    <CompanyLogoEditModal
      companyId={companyId}
      key='modal'
      onHide={onHideModal}
      show={true}
    />
  ),
];

CompanyLogoEditButton.propTypes = {
  companyId: PropTypes.number.isRequired,
  onHideModal: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  thumbnail: PropTypes.bool,
};

export default compose(
  setDisplayName('CompanyLogoEditButton(enhanced)'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
    thumbnail: PropTypes.bool,
  }),

  withStateHandlers(
    {
      showModal: false,
    },
    {
      onShowModal: () => () => ({ showModal: true }),
      onHideModal: () => () => ({ showModal: false }),
    },
  ),
)(CompanyLogoEditButton);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import EditableSection from 'modules/forms/components/EditableSection';
import ContactInvestorForm from './ContactInvestorForm';
import ContactInvestorView from './ContactInvestorView';

/**
 * Renders an EditableSection that displays and allows editing of a contact's investor-related
 * fields.
 */
export default compose(
  setDisplayName('ContactInvestorEditableSection'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  mapProps(({ contact, contactId }) => ({
    contactId: contactId,
    children: ContactInvestorView.willRender({ contact: contact }) && (
      <ContactInvestorView contactId={contactId} />
    ),
    formComponent: ContactInvestorForm,
    title: 'Investor',
  })),
)(EditableSection);

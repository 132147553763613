import PropTypes from 'prop-types';
import React from 'react';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import { TYPE_JOB_SEARCH } from 'modules/searches/constants';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import withTransaction from 'modules/transactions/components/withTransaction';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import classnames from 'classnames';
import mapCandidacyIdToCandidacy from './mapCandidacyIdToCandidacy';
import mapCandidacyIdToSearchType from './mapCandidacyIdToSearchType';
import updateCandidacyAction from '../actions/updateCandidacy';

/**
 * Renders a button that when clicked will mark the candidate as hidden and hide
 * the candidate from certain users.
 */

const CandidacyHiddenToggleButton = ({
  candidacyVisibility,
  componentId,
  employeeUser,
  handleHiddenToggle,
  isHidden,
  isUpdating,
  searchType,
}) =>
  candidacyVisibility &&
  employeeUser &&
  searchType === TYPE_JOB_SEARCH && (
    <div className='CandidacyHiddenToggleButton'>
      <OverlayTrigger
        overlay={
          <Tooltip id={`${componentId}-hidden`}>
            {isHidden ? 'Make Visible to Hiring Team' : 'Hide from Hiring Team'}
          </Tooltip>
        }
        placement='top'
        shouldUpdatePosition={true}
      >
        <button
          className={classnames(
            'CandidacyHidenToggleButton__button btn-link btn',
            {
              disabled: isUpdating,
              'CandidacyHiddenToggleButton--hidden': isHidden,
            },
          )}
          disabled={isUpdating}
          onClick={handleHiddenToggle}
          type='button'
        >
          <i
            className={classnames(
              'CandidacyHiddenToggleButton__icon',
              'fa',
              'btn-icon',
              {
                'fa-spin': isUpdating,
                'fa-spinner': isUpdating,
                'fa-eye-slash': !isUpdating && isHidden,
                'fa-eye': !isUpdating && !isHidden,
              },
            )}
          />
        </button>
      </OverlayTrigger>
    </div>
  );

CandidacyHiddenToggleButton.propTypes = {
  candidacyVisibility: PropTypes.bool,
  /**
   * A unique component ID used for identifying the tooltip
   */
  componentId: PropTypes.string.isRequired,

  employeeUser: PropTypes.bool,
  /**
   * Function to handle the toggling of the hidden button
   */
  handleHiddenToggle: PropTypes.func,

  /**
   * True if the candidate is marked as hidden and false
   * if it is not.
   */
  isHidden: PropTypes.bool.isRequired,

  /**
   * Whether or not the candidate is being updated or not
   */
  isUpdating: PropTypes.bool,

  searchType: PropTypes.string,
};

export default compose(
  setDisplayName('CandidacyHiddenToggleButton(enhanced)'),
  setPropTypes({
    /**
     * The ID of the candidacy to render the actions for.
     */
    candidacyId: PropTypes.number.isRequired,
  }),
  withComponentId(),
  mapCandidacyIdToCandidacy,
  mapCandidacyIdToSearchType,
  connect(null, {
    updateCandidacy: updateCandidacyAction,
  }),
  connectTransactions,

  withTransaction,
  withHandlers({
    handleHiddenToggle: ({
      candidacy,
      startTransaction,
      updateCandidacy,
    }) => () => {
      const transactionId = startTransaction();
      const isHidden = candidacy.get('hidden');
      updateCandidacy({
        transactionId: transactionId,
        id: candidacy.get('id'),
        candidacy: {
          hidden: !isHidden,
        },
      });
    },
  }),
  withProps(({ candidacy }) => ({
    isHidden: candidacy.get('hidden'),
    isUpdating: candidacy && candidacy.getIn(['_meta', 'isUpdating']),
  })),
  withFeatureCheck('feature.candidacy_visibility', 'candidacyVisibility'),
  connect(
    state => ({
      employeeUser: isEmployeeUser(state),
    }),
    {},
  ),
)(CandidacyHiddenToggleButton);

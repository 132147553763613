import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import Notification from './Notification';

/**
 * Renders a notification with a resource type of `comment`
 */
const NotificationItemTypeComment = ({ notification, ...props }) => (
  <Notification {...props} notification={notification}>
    <ExpandableText
      characterLimit={150}
      content={notification.getIn([
        'data',
        'resource',
        'data',
        'formatted_comment',
      ])}
      isSanitizedHtml={true}
    />
  </Notification>
);

NotificationItemTypeComment.propTypes = {
  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          created_at: PropTypes.string,
          formatted_comment: PropTypes.string,
          formatted_title: PropTypes.string.isRequired,
          parent_comment_id: PropTypes.number,
          rejection: PropTypes.bool,
          rejection_reason: PropTypes.string,
          updated_at: PropTypes.string,
          user_name: PropTypes.string.isRequired,
          vote_type: PropTypes.string,
        }),
      }),
      type: PropTypes.oneOf(['candidacy_comment']),
    }),
  }).isRequired,
};

export default NotificationItemTypeComment;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fromJS } from 'immutable';

import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import CurrencyInputField from 'modules/currency/components/CurrencyInputField';
import CurrencyTypeSelectField from 'modules/currency/components/CurrencyTypeSelectField';
import DateInputField from 'modules/datetime/components/DateInputField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import PercentageInputField from 'modules/forms/components/PercentageInputField';
import RadioSelectField from 'modules/forms/components/RadioSelectField';
import TextAreaField from 'modules/forms/components/TextAreaField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import * as validators from 'modules/forms/validators';
import { DEFAULT_CURRENCY } from 'modules/user/constants';
import BonusFieldHelper from 'modules/compensations/components/BonusFieldHelper';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import hasFullyDilutedOwnershipFieldsSelector from '../selectors/hasFullyDilutedOwnershipFields';

const MAX_EXCEEDED_WARNING_MESSAGE =
  'Are you sure? That looks like it might be too high.';

/**
 * A field for editing a compensation record.
 */
const CompensationField = ({
  fieldState,
  handleNestedFieldChange,
  hasFullyDilutedOwnershipFields,
  legalDisclaimer,
  onChange,
  showTargetOptionProceeds,
  ...otherProps
}) => {
  const [equityWarningMessage, setEquityWarningMessage] = useState('');
  const [bonusPercentWarningMessage, setBonusPercentWarningMessage] = useState(
    '',
  );
  const hasDevelopmentOffersFeature = useFeatureCheck(
    'development.search_offers',
  );
  const percentageOfBonus = Number(
    fieldState.getNestedFieldValue('percentage_bonus'),
  );
  const base = Number(fieldState.getNestedFieldValue('base').replace(/,/g, ''));
  const bonus = fieldState.getNestedFieldValue('bonus');

  const handleBonusTypeChange = childFieldState => {
    let nextFieldState = fieldState.setNestedField(childFieldState);
    const bonusField = fieldState.getNestedField('bonus');
    const percentageOfBonusField = fieldState.getNestedField(
      'percentage_bonus',
    );
    const fixedBonus = Number(
      typeof bonus === 'string' ? bonus.replace(/,/g, '') : bonus,
    );
    const shouldTrackBonusAsPercentage =
      childFieldState.getValue() === 'percentage';
    // when the user toggles bonus type between a Fixed Amount and % of Base,
    // we have to calculate the bonus according to the selected bonus type
    //
    // Eg: Base salary is 12000 and Bonus is 1200
    // scenario 1: when user toggles to % of Base from fixed amount, value of % of Base field should be 10%
    //  - calculation: (1200 / 12000) * 100 = 10
    //
    // scenario 2: when user toggles to Fixed Amount from % of Base, value of % of Base field should be 1200
    // - calculation: (12000 * 10) / 100 = 1200
    if (base && (fixedBonus || percentageOfBonus)) {
      if (shouldTrackBonusAsPercentage) {
        nextFieldState = nextFieldState
          .setNestedField(
            percentageOfBonusField.setValue(
              Number(((fixedBonus / base) * 100).toFixed(2)),
            ),
          )
          // when the user enters a float value in Fixed Bonus field then form validator will give an error "Value must be a whole number",
          // and then the user toggles bonus type to % of bonus from Fixed Amount we have to remove that error so user can submit the form
          // so to remove that validation error we have to round that Fixed Amount to nearest integer
          .setNestedField(bonusField.setValue(Math.round(fixedBonus)));
      } else {
        nextFieldState = nextFieldState.setNestedField(
          bonusField.setValue(Math.round((base * percentageOfBonus) / 100)),
        );
      }
    }
    onChange(nextFieldState);
  };

  const handleBonusPercentChange = bonusPercentFieldState => {
    const warning =
      bonusPercentFieldState.getRawValue() > 100
        ? MAX_EXCEEDED_WARNING_MESSAGE
        : '';
    setBonusPercentWarningMessage(warning);
    handleNestedFieldChange(bonusPercentFieldState);
  };

  const handleEquityChange = equityFieldState => {
    const warning =
      equityFieldState.getRawValue() > 5 ? MAX_EXCEEDED_WARNING_MESSAGE : '';
    setEquityWarningMessage(warning);
    handleNestedFieldChange(equityFieldState);
  };

  return (
    <div className='CompensationField'>
      {legalDisclaimer && (
        <p className='CompensationField__legal-disclaimer help-block'>
          <i className='fa fa-exclamation-circle' /> {legalDisclaimer}
        </p>
      )}
      <div className='row'>
        <div className='CompensationField__currency col-sm-2 u-paddingHorizontal-8'>
          <CurrencyTypeSelectField
            {...otherProps}
            clearable={true}
            fieldState={fieldState.getNestedField('currency')}
            label='Currency'
            name='currency'
            onChange={handleNestedFieldChange}
          />
        </div>
        <div className='col-sm-10'>
          <div className='row'>
            <div className='CompensationField__base col-sm-6 u-paddingHorizontal-8'>
              <CurrencyInputField
                {...otherProps}
                currency={fieldState.getNestedField('currency').getValue()}
                fieldState={fieldState.getNestedField('base')}
                label='Base'
                onChange={handleNestedFieldChange}
              />
            </div>
            <div className='CompensationField__bonus col-sm-6 u-paddingHorizontal-8'>
              {fieldState.getNestedFieldValue('bonus_type') === 'fixed' ? (
                <CurrencyInputField
                  {...otherProps}
                  className='u-marginBottom-4'
                  currency={fieldState.getNestedField('currency').getValue()}
                  fieldState={fieldState.getNestedField('bonus')}
                  label='Bonus'
                  onChange={handleNestedFieldChange}
                />
              ) : (
                <>
                  <PercentageInputField
                    {...otherProps}
                    className='u-marginBottom-4'
                    currency={fieldState.getNestedField('currency').getValue()}
                    fieldState={fieldState.getNestedField('percentage_bonus')}
                    label='Bonus'
                    onChange={handleBonusPercentChange}
                    type='number'
                    warning={bonusPercentWarningMessage}
                  />
                  {percentageOfBonus ? (
                    <BonusFieldHelper fieldState={fieldState} />
                  ) : null}
                </>
              )}
              <RadioSelectField
                {...otherProps}
                className='CompensationField__bonusType text-right'
                fieldState={fieldState.getNestedField('bonus_type')}
                inline={true}
                onChange={handleBonusTypeChange}
                options={[
                  { label: 'Fixed Amount', value: 'fixed' },
                  { label: '% of base', value: 'percentage' },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='CompensationField__equity col-sm-6 u-paddingHorizontal-8'>
          <PercentageInputField
            {...otherProps}
            fieldState={fieldState.getNestedField('equity')}
            label='Initial Equity Grant'
            onChange={handleEquityChange}
            warning={equityWarningMessage}
          />
        </div>
        <div className='CompensationField__additional_equity col-sm-6 u-paddingHorizontal-8'>
          <PercentageInputField
            {...otherProps}
            fieldState={fieldState.getNestedField('additional_equity')}
            label='Annual Equity Grant'
            onChange={handleNestedFieldChange}
          />
        </div>
        {hasDevelopmentOffersFeature ? (
          <div className='col-sm-6 u-paddingHorizontal-8 u-paddingHorizontal-8'>
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('initial_equity_shares')}
              label='Initial Number of Equity Shares'
              onChange={handleNestedFieldChange}
              type='number'
            />
          </div>
        ) : null}
        <div className='CompensationField__share_price col-sm-6 u-paddingHorizontal-8'>
          <CurrencyInputField
            {...otherProps}
            currency={fieldState.getNestedField('currency').getValue()}
            fieldState={fieldState.getNestedField('share_price')}
            label='Share Price'
            onChange={handleNestedFieldChange}
          />
        </div>
        <div className='CompensationField__equity_value col-sm-6 u-paddingHorizontal-8'>
          <CurrencyInputField
            {...otherProps}
            currency={fieldState.getNestedField('currency').getValue()}
            fieldState={fieldState.getNestedField('equity_value')}
            label='Initial Equity Grant in Currency'
            onChange={handleNestedFieldChange}
          />
        </div>
        <div className='CompensationField__vesting_date col-sm-6 u-paddingHorizontal-8'>
          <DateInputField
            {...otherProps}
            fieldState={fieldState.getNestedField('vesting_date')}
            label='Vesting Date'
            onChange={handleNestedFieldChange}
          />
        </div>
        {hasDevelopmentOffersFeature ? (
          <div className='col-sm-6 u-paddingHorizontal-8'>
            <CurrencyInputField
              {...otherProps}
              currency={fieldState.getNestedField('currency').getValue()}
              fieldState={fieldState.getNestedField('signing_bonus')}
              label='Signing Bonus'
              onChange={handleNestedFieldChange}
            />
          </div>
        ) : null}
        {hasDevelopmentOffersFeature ? (
          <div className='col-sm-6 u-paddingHorizontal-8'>
            <CurrencyInputField
              {...otherProps}
              currency={fieldState.getNestedField('currency').getValue()}
              fieldState={fieldState.getNestedField('relocation_bonus')}
              label='Relocation Bonus'
              onChange={handleNestedFieldChange}
            />
          </div>
        ) : null}
        {/*
          When the `development.search_offers` flipper is sunset and the
          functionality is enabled for all, the total compensation should always
          display, regardless of flipper, including the extra comp fields flipper.
        */}
        {hasDevelopmentOffersFeature ? (
          <div className='CompensationField__total_compensation col-sm-6 u-paddingHorizontal-8'>
            <CurrencyInputField
              {...otherProps}
              currency={fieldState.getNestedField('currency').getValue()}
              fieldState={fieldState.getNestedField('total_compensation')}
              label='Total Estimated Compensation'
              onChange={handleNestedFieldChange}
            />
          </div>
        ) : null}
      </div>
      <div className='row'>
        {hasFullyDilutedOwnershipFields && (
          <div className='CompensationField__fully_diluted_ownership col-sm-6'>
            <PercentageInputField
              {...otherProps}
              fieldState={fieldState.getNestedField('fully_diluted_ownership')}
              label='Fully Diluted Ownership'
              onChange={handleNestedFieldChange}
            />
          </div>
        )}
        {showTargetOptionProceeds && (
          <div className='CompensationField__target_option_proceeds col-sm-6'>
            <CurrencyInputField
              {...otherProps}
              currency={fieldState.getNestedField('currency').getValue()}
              fieldState={fieldState.getNestedField('target_option_proceeds')}
              label='Target Option Proceeds'
              onChange={handleNestedFieldChange}
            />
          </div>
        )}
      </div>
      <div className='row'>
        <div className='CompensationField__notes col-sm-12 u-paddingHorizontal-8'>
          <TextAreaField
            {...otherProps}
            fieldState={fieldState.getNestedField('notes')}
            label='Notes'
            onChange={handleNestedFieldChange}
          />
        </div>
      </div>
    </div>
  );
};

CompensationField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when a nested field value is changed.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * True to display and allow editing of the `fully_diluted_ownership` value of the compensation
   * record.
   */
  hasFullyDilutedOwnershipFields: PropTypes.bool,

  /**
   * An optional legal disclaimer message to show.
   */
  legalDisclaimer: PropTypes.string,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  /**
   * True to display and allow editing of the `target_option_proceeds` value of the compensation
   * record.
   */
  showTargetOptionProceeds: PropTypes.bool,
};

CompensationField.defaultProps = {
  hasFullyDilutedOwnershipFields: false,
  showTargetOptionProceeds: false,
};

CompensationField.createFieldState = (
  name = 'compensation',
  compensation,
  ...rest
) => {
  const values = fromJS({
    currency: '',
    notes: '',
  }).merge(compensation);

  const vestedDateMoment = values.get('vesting_date')
    ? moment(values.get('vesting_date'), moment.ISO_8601)
    : null;

  const getRawNumber = value => {
    if (typeof value === 'string') {
      return Number(value?.replaceAll(',', ''));
    }
    return value;
  };

  return FieldState.createNested(
    name,
    [
      CurrencyTypeSelectField.createFieldState(
        'currency',
        values.get('currency') || DEFAULT_CURRENCY,
        validators.requiredField('Currency'),
      ),
      CurrencyInputField.createFieldState('base', values.get('base'), {
        minValue: 0,
      }),
      RadioSelectField.createFieldState(
        'bonus_type',
        values.get('bonus_type') || 'fixed',
      ),
      PercentageInputField.createFieldState('equity', values.get('equity'), {
        minValue: 0,
      }),
      PercentageInputField.createFieldState(
        'additional_equity',
        values.get('additional_equity'),
        {
          minValue: 0,
          maxValue: 100,
        },
      ),
      CurrencyInputField.createFieldState('bonus', values.get('bonus'), {
        minValue: 0,
      }),
      PercentageInputField.createFieldState(
        'percentage_bonus',
        values.get('percentage_bonus'),
        {
          minValue: 0,
        },
      ),
      PercentageInputField.createFieldState(
        'fully_diluted_ownership',
        values.get('fully_diluted_ownership'),
        { minValue: 0, maxValue: 100 },
      ),
      CurrencyInputField.createFieldState(
        'target_option_proceeds',
        values.get('target_option_proceeds'),
        { minValue: 0 },
      ),
      TextAreaField.createFieldState('notes', values.get('notes')),
      CurrencyInputField.createFieldState(
        'share_price',
        values.get('share_price'),
        { minValue: 0, float: true },
      ),
      CurrencyInputField.createFieldState(
        'equity_value',
        values.get('equity_value'),
        { minValue: 0, float: true },
      ),
      CurrencyInputField.createFieldState(
        'total_compensation',
        values.get('total_compensation'),
        { minValue: 0, float: true },
      ),
      CurrencyInputField.createFieldState(
        'signing_bonus',
        values.get('signing_bonus'),
        { minValue: 0, float: true },
      ),
      CurrencyInputField.createFieldState(
        'relocation_bonus',
        values.get('relocation_bonus'),
        { minValue: 0, float: true },
      ),
      FieldState.create(
        'initial_equity_shares',
        values.get('initial_equity_shares'),
        validators.combineValidators(
          validators.isInteger,
          validators.greaterThanOrEqualTo(0),
        ),
        getRawNumber,
      ),
      DateInputField.createFieldState('vesting_date', vestedDateMoment),
    ],
    ...rest,
  );
};

export default compose(
  setDisplayName('CompensationField(enhanced)'),
  setPropTypes({
    /**
     * The FieldState that manages the value of the control.
     */
    fieldState: PropTypes.instanceOf(FieldState).isRequired,

    /**
     * An optional legal disclaimer message to show.
     */
    legalDisclaimer: PropTypes.string,

    /**
     * Called when the field is changed with the updated FieldState object.
     */
    onChange: PropTypes.func,

    /**
     * True to display and allow editing of the `target_option_proceeds` value of the compensation
     * record.
     */
    showTargetOptionProceeds: PropTypes.bool,
  }),
  setStatic('createFieldState', CompensationField.createFieldState),
  connect(
    state => ({
      hasFullyDilutedOwnershipFields: hasFullyDilutedOwnershipFieldsSelector(
        state,
      ),
    }),
    {},
  ),
  withNestedFieldChangeHandler,
)(CompensationField);

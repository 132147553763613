import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import TabButton from './TabButton';

const Tab = ({
  className,
  onTabChange,
  selectedTabLabel,
  tabs,
  tabsListClass,
  ...props
}) => {
  const [focusedIndex, setFocusedIndex] = useState();

  useEffect(() => {
    setFocusedIndex(tabs.findIndex(tab => tab.label === selectedTabLabel));
  }, [selectedTabLabel]);

  const handleOnClick = (tabName, index) => {
    if (tabName !== selectedTabLabel) {
      onTabChange(tabName);
      setFocusedIndex(index);
    }
  };

  const handleFocus = (e, tabName) => {
    if (e.key === 'ArrowRight') {
      e.preventDefault();
      setFocusedIndex(focusedIndex === tabs.length - 1 ? 0 : focusedIndex + 1);
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      setFocusedIndex(focusedIndex === 0 ? tabs.length - 1 : focusedIndex - 1);
    } else if (e.key === 'Enter') {
      // will change selected tab on enter press (focused to selected)
      handleOnClick(tabName, focusedIndex);
    } else if (e.key === '') {
      // will change selected tab on space press (focused to selected)
      handleOnClick(tabName, focusedIndex);
    }
  };

  const tabsClass = classnames('Tab', className);

  return (
    <div className={tabsClass} {...props}>
      <div className={classnames('Tab__tabs', tabsListClass)} role='tablist'>
        {tabs.map(({ count, icon, label }, index) => (
          <TabButton
            count={count}
            icon={icon}
            isFocused={focusedIndex === index}
            isSelected={label === selectedTabLabel}
            key={label}
            label={label}
            onClick={tabName => handleOnClick(tabName, index)}
            setFocus={(e, tabName) => handleFocus(e, tabName)}
          />
        ))}
      </div>
    </div>
  );
};

Tab.defaultProps = {
  className: null,
  selectedTabLabel: null,
  tabsListClass: null,
};

Tab.propTypes = {
  className: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
  selectedTabLabel: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      icon: PropTypes.string,
      label: PropTypes.node.isRequired,
    }),
  ).isRequired,
  tabsListClass: PropTypes.string,
};

export default Tab;

import { CONTACT_INVALIDATE } from './constants';

export default function invalidateContact(id) {
  return {
    type: CONTACT_INVALIDATE,
    payload: {
      id: id,
    },
  };
}

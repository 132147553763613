import React from 'react';
import NewCompanyFormModalButton from 'modules/companies/components/NewCompanyFormModalButton';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import SkyminyrCompanyCreateButton from 'modules/companies/components/skyminyr/SkyminyrCompanyCreateButton';
import { withStateProvider } from './app/StateProvider';

const NewCompanyButtonContainer = props => {
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  return hasSkyminyrFeature ? (
    <SkyminyrCompanyCreateButton />
  ) : (
    <NewCompanyFormModalButton {...props} />
  );
};

export default withStateProvider(NewCompanyButtonContainer);

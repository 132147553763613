import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import fetchBulkImportListActionCreator from '../actions/fetchBulkImportList';
import shouldFetchBulkImportListSelector from '../selectors/shouldFetchBulkImportList';
import getBulkImportList from '../selectors/getBulkImportList';

/**
 * Fetches the bulk import list if needed and provides the `bulkImportList` prop.
 * */
export default ({
  bulkImportListPropName = 'bulkImportList',
  fetchBulkImportListPropName = 'fetchBulkImportList',
} = {}) =>
  compose(
    setDisplayName('withBulkImportListFetched'),
    setPropTypes({
      page: PropTypes.number,
      limit: PropTypes.number,
      sortBy: PropTypes.string,
      sortAscending: PropTypes.bool,
    }),
    withProps(props => ({ withBulkImportListFetchedProps: props })),
    connect(
      (state, props) => ({
        shouldFetchBulkImportList: shouldFetchBulkImportListSelector(
          state,
          props,
        ),
        bulkImportList: getBulkImportList(state),
      }),
      {
        fetchBulkImportList: fetchBulkImportListActionCreator,
      },
    ),
    withHandlers({
      fetchBulkImportListIfNeeded: ({
        fetchBulkImportList,
        shouldFetchBulkImportList,
        ...props
      }) => () => {
        if (shouldFetchBulkImportList) {
          fetchBulkImportList(props);
        }
      },
    }),
    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchBulkImportListIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchBulkImportListIfNeeded();
      },
    }),
    // Restores the incoming props (`withBulkImportListFetchedProps`) and adds any
    // additional props that were wanted.
    mapProps(
      ({
        bulkImportList,
        fetchBulkImportList,
        withBulkImportListFetchedProps,
      }) => {
        const props = {
          ...withBulkImportListFetchedProps,
          [bulkImportListPropName]: bulkImportList,
        };

        if (fetchBulkImportListPropName) {
          props[fetchBulkImportListPropName] = fetchBulkImportList;
        }

        return props;
      },
    ),
  );

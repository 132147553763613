import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  onlyUpdateForPropTypes,
  setPropTypes,
  withProps,
} from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import mapContactIdToFullName from 'modules/contacts/components/mapContactIdToFullName';
import getClientTypeName from 'modules/tenant/selectors/getClientTypeName';
import getInterviewerNames from '../selectors/getInterviewerNames';
import isInterviewPastSelector from '../selectors/isInterviewPast';
import InterviewDate from './InterviewDate';
import InterviewTypeAbbreviation from './InterviewTypeAbbreviation';
import wrapInSearchCandidacyInterviewsTabLink from './wrapInSearchCandidacyInterviewsTabLink';
import { INTERVIEW_TYPE_CLIENT } from '../constants';

export const InterviewTitle = ({
  attendeeNames,
  fullName,
  interview,
  isPastInterview,
  label,
  renderCandidateName,
}) => {
  const interviewerNames = interview.get('interviewers') || attendeeNames;

  if (interview.get('scheduling')) {
    return (
      <div className='interviews--interview-title'>
        {renderCandidateName ? (
          <span>
            {interviewerNames && <span>{interviewerNames} </span>}
            <span>
              {'in process of scheduling a '}
              {label}
              {' Interview'}
            </span>
            <span>
              {' with '}
              {fullName}
            </span>
          </span>
        ) : (
          <span>
            {'In process of scheduling a '}
            {label}
            {' Interview'}
            {attendeeNames && (
              <span>
                {' with '}
                {attendeeNames}
              </span>
            )}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className='interviews--interview-title'>
      {renderCandidateName ? (
        <span>
          <InterviewDate interview={interview} />
          <span>
            {fullName}
            {isPastInterview ? ' completed a ' : ' has a '}
            {label}
            {' Interview'}
          </span>
          <span>{isPastInterview ? ' ' : ' scheduled '}</span>
          {interviewerNames && (
            <span>
              {' with '}
              {interviewerNames}
            </span>
          )}
        </span>
      ) : (
        <span>
          <InterviewDate interview={interview} />
          <span>
            {interview.getIn(['appointment_method', 'name']) || 'Interview'}
          </span>
          <span>{isPastInterview ? ' completed ' : ' scheduled '}</span>
          {interviewerNames && (
            <span>
              {' with '}
              {interviewerNames}
            </span>
          )}
          <InterviewTypeAbbreviation interviewType={label} />
        </span>
      )}
    </div>
  );
};

InterviewTitle.defaultProps = {
  renderCandidateName: false,
};

InterviewTitle.propTypes = {
  attendeeNames: PropTypes.string,

  fullName: PropTypes.string,

  interview: ImmutablePropTypes.mapContains({
    appointment_method: ImmutablePropTypes.mapContains({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
    }).isRequired,
    label: PropTypes.string,
    scheduling: PropTypes.bool,
  }).isRequired,

  isPastInterview: PropTypes.bool,

  renderCandidateName: PropTypes.bool,
};

export default compose(
  setPropTypes({
    renderAsLink: PropTypes.bool,
    ...InterviewTitle.propTypes,
  }),
  branch(
    props => Boolean(props.renderAsLink),
    wrapInSearchCandidacyInterviewsTabLink,
  ),
  withProps(({ interview }) => ({
    contactId: interview.get('candidate'),
  })),
  connect(
    (state, { interview }) => ({
      attendeeNames: getInterviewerNames(state, interview.get('id')),
      isPastInterview: isInterviewPastSelector(state, interview.get('id')),
      label:
        interview.get('label') === INTERVIEW_TYPE_CLIENT
          ? getClientTypeName(state)
          : interview.get('label'),
    }),
    {},
  ),
  mapContactIdToFullName,
  onlyUpdateForPropTypes,
)(InterviewTitle);

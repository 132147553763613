import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setStatic } from 'recompose';

import FieldState from 'modules/forms/FieldState';
import asField from 'modules/forms/components/asField';
import withFormGroup from 'modules/forms/components/withFormGroup';

export const propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
};

export default compose(
  setStatic('createFieldState', FieldState.create),
  setPropTypes(propTypes),
  withFormGroup,
  asField(),
)(SelectMenu);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import { connect } from 'react-redux';
import CandidacyMoveStageButton from './stages/CandidacyMoveStageButton';
import CandidacyVoteButton from './voting/CandidacyVoteButton';
import canUpdateCandidacyStage from '../selectors/canUpdateCandidacyStage';

/**
 * Renders three buttons for actions that can be taken against a candidacy:
 * - Vote up
 * - Vote down
 * - Move stage
 */
const CandidacyActionButtons = ({
  canMoveStage,
  candidacyId,
  showMoveStage,
}) => (
  <div className='CandidacyActionButtons btn-group btn-group-justified'>
    <CandidacyVoteButton candidacyId={candidacyId} value={1} />
    <CandidacyVoteButton candidacyId={candidacyId} value={-1} />
    {showMoveStage && canMoveStage && (
      <CandidacyMoveStageButton candidacyId={candidacyId} />
    )}
  </div>
);

CandidacyActionButtons.propTypes = {
  /**
   * The ID of the candidacy that the actions will apply to.
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * True to enable moving the stage the candidacy currently belongs to. When false (the default),
   * the move stage button will not be rendered.
   */
  canMoveStage: PropTypes.bool,

  /**
   * True to show show move stage button
   */
  showMoveStage: PropTypes.bool,
};

CandidacyActionButtons.defaultProps = {
  canMoveStage: false,
  showMoveStage: false,
};

export default compose(
  setDisplayName('CandidacyActionButtons(enhanced)'),
  setPropTypes({
    /**
     * The ID of the candidacy to render the actions for.
     */
    candidacyId: PropTypes.number.isRequired,
  }),
  connect(
    state => ({
      canMoveStage: canUpdateCandidacyStage(state),
    }),
    {},
  ),
)(CandidacyActionButtons);

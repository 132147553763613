import PropTypes from 'prop-types';
import React from 'react';
import { displayDate } from '@thrivetrm/ui/utilities/dateTimeUtils';
import TimeLabel from 'modules/datetime/components/TimeLabel';
import TooltipTrigger from '../componentsLegacy/TooltipTrigger';

const LastUpdatedDate = ({ date, userEmail }) => {
  const formattedDate = displayDate(date);
  const tooltipMessage = userEmail
    ? `Updated by ${userEmail} on ${formattedDate}`
    : formattedDate;

  return date ? (
    <div className='LastUpdatedDate'>
      <i className='fa fa-clock-o' id='LastUpdatedDate__icon' />
      <TooltipTrigger placement='bottom' tooltip={tooltipMessage}>
        <span className='LastUpdatedDate__label'>
          Updated <TimeLabel shouldAddTitleAttribute={false} time={date} />
        </span>
      </TooltipTrigger>
    </div>
  ) : null;
};

LastUpdatedDate.defaultProps = {
  date: null,
  userEmail: null,
};

LastUpdatedDate.propTypes = {
  /** An ISO-8601 datestring representing the last time since something was updated. */
  date: PropTypes.string,
  userEmail: PropTypes.string,
};

export default LastUpdatedDate;

import { NAME } from '../constants';
import getEntity from '../../entities/selectors/getEntity';

/**
 * Gets an email template record from it's ID.
 * @param {*} state
 * @param {Number} id The ID of the email template.
 * @return {Map} The email template record.
 */
export default (state, id) => getEntity(state, NAME, id);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useDispatch } from 'react-redux';
import MultiValueInput from 'modules/core/components/MultiValueInput';
import updateContact from '../../../actions/contacts/updateContact';

const ContactAffiliationsField = ({ contact, tenant }) => {
  const dispatch = useDispatch();
  const contactId = contact.getIn(['data', 'id']);
  const contactAffiliations = contact.getIn(['data', 'affiliations']);
  const contactAffiliationNames = contactAffiliations?.map(affiliation =>
    affiliation.get('name'),
  );

  const tenantAffiliations = tenant.getIn(['tenantOptions', 'affiliations']);

  const [affiliationValues, setAffiliationValues] = useState(
    contactAffiliationNames.toArray() || [],
  );
  const affiliationOptions = tenantAffiliations
    ?.map(affiliation => ({
      label: affiliation.get('name'),
      value: affiliation.get('name'),
    }))
    .toArray();

  const handleAffiliationValueChange = value => {
    setAffiliationValues(value);
    dispatch(
      updateContact({
        contactId: contactId,
        id: contactId,
        contact: {
          affiliations: value || [],
        },
      }),
    );
  };

  return (
    <MultiValueInput
      canUseCustomOptions={true}
      className='ContactAffiliationsSection'
      formatCreateLabel={customValue =>
        `"${customValue}" (Create New Affiliation)`
      }
      label='Affiliations'
      onChange={newValue => handleAffiliationValueChange(newValue)}
      options={affiliationOptions}
      placeholder='+ Add Affiliation'
      shouldConfirmRemove={true}
      shouldRenderAsInput={false}
      value={affiliationValues}
    />
  );
};

ContactAffiliationsField.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      affiliations: ImmutablePropTypes.listOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
    }),
    meta: ImmutablePropTypes.map,
  }),

  tenant: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.map,
    meta: ImmutablePropTypes.map,
  }),
};

export default ContactAffiliationsField;

/* eslint-disable func-names */

const initSelect2 = require('../../lib/select2');

const attribute = '.js-assessment-attribute-select';

const initializeTooltips = function () {
  $('#btn-add-assessment-rating-criteria').tooltip();
  $('.fa-info-circle.btn-tooltip').each(function (_index) {
    $(this).tooltip();
    $(this).css(
      'visibility',
      $(this).data('original-title') ? 'visible' : 'hidden',
    );
  });
};

const saveSortedList = function (el) {
  const ids = $('select.assessment_attribute').map(function () {
    return $(this).attr('data-id');
  });
  const baseUrl = $('form#search-details-form').prop('action');
  $.post(`${baseUrl}/assessment_attributes/sort`, { ids: ids.get() });
  const $saveNotice = $(el).find('.notice-saved');
  return $saveNotice
    .css('display', 'inline-block')
    .fadeIn('slow')
    .fadeOut('slow');
};

const updateDescription = function (text, el) {
  const $tooltip = $(el).parent().siblings('[data-toggle="tooltip"]');
  $tooltip.attr('title', text).tooltip('fixTitle');
  $tooltip.css('visibility', text ? 'visible' : 'hidden');
  return saveSortedList($(el).parents('.fields'));
};

const updatePersistedAssessmentAttribute = (text, el) =>
  updateDescription(text, el);

const updateNewlyCreatedAssessmentAttribute = function (text, num, el) {
  const $container = $(el).parents('.fields');
  $(el).attr('data-id', num);
  $($container.find('.handle')).css('visibility', 'visible');
  return updateDescription(text, el);
};

const updateOrCreateAssessmentAttribute = () =>
  $(document)
    .off('select2:select', 'select.assessment_attribute')
    .on('select2:select', 'select.assessment_attribute', function (e) {
      const params = {
        id: $(this).data('id'),
        assessment_option_id: e.params.data.id,
      };
      const baseUrl = $('form#search-details-form').prop('action');
      if (params.id) {
        return $.ajax({
          method: 'put',
          url: `${baseUrl}/assessment_attributes/${params.id}`,
          data: params,
          success: data =>
            updatePersistedAssessmentAttribute(data.description, this),
          dataType: 'json',
        });
      } else {
        return $.post(
          `${baseUrl}/assessment_attributes`,
          params,
          data =>
            updateNewlyCreatedAssessmentAttribute(
              data.description,
              data.id,
              this,
            ),
          'json',
        );
      }
    });

exports.initializeAssessmentAttributes = function () {
  $(document).on('select2:unselect', 'select.assessment_attribute', function (
    e,
  ) {
    e.params.prevented = true;
    if (
      // eslint-disable-next-line no-restricted-globals, no-alert
      confirm(`Are you sure you want to remove ${e.params.data.text}? \
Removing ${e.params.data.text} will remove this criteria and \
ratings from all completed Assessments on this Search.`)
    ) {
      const baseUrl = $('form#search-details-form').prop('action');
      $.ajax({
        method: 'delete',
        url: `${baseUrl}/assessment_attributes/${$(this).attr('data-id')}`,
        success: () => {
          if ($(this).data('select2')) {
            $(this).select2('destroy');
          }
          return $(this).parents('.fields').remove();
        },
      });
    }
  });

  updateOrCreateAssessmentAttribute();
  initializeTooltips();

  initSelect2('.search-details-form-assessment-attributes', [attribute]);

  $(document).on('nested:fieldAdded', event => {
    const { field } = event;
    const handle = field.find('.handle');
    $(handle).css('visibility', 'hidden');
    updateOrCreateAssessmentAttribute();
    initializeTooltips();
    return initSelect2('.search-details-form-assessment-attributes', [
      attribute,
    ]);
  });

  return $('#assessment_container').sortable({
    containment: '#assessment_attributes',
    handle: '.handle',
    cursor: 'move',
    axis: 'y',
    update: (e, ui) => saveSortedList(ui.item),
  });
};

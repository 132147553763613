import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

/**
 * A higher-order component that provides connected user actions as a prop.
 */
export default connect(null, dispatch => ({
  userActions: bindActionCreators(actions, dispatch),
}));

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import { PARENT_COMPANY } from 'modules/comments/constants';
import CommentsPanel from 'modules/comments/components/CommentsPanel';

/**
 * Renders a panel of comments (with adding and editing capabilities) related to a particular
 * company.
 */
export default compose(
  setDisplayName('CompanyCommentsPanel'),
  setPropTypes({
    /**
     * The ID of the company whose comments are shown
     */
    companyId: PropTypes.number,
  }),
  withProps(({ companyId }) => ({
    parentType: PARENT_COMPANY,
    parentId: companyId,
    popoverPlacement: 'left',
    inlineEditActionLabels: {
      editLabel: <i className='fa fa-pencil' />,
      deleteLabel: <i className='fa fa-trash' />,
      replyLabel: 'Reply',
    },
  })),
)(CommentsPanel);

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useApiPost, useApiPut } from '@thrivetrm/ui/hooks/useApi';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Form from '@thrivetrm/ui/components/Form';
import Modal from '@thrivetrm/ui/components/Modal';
import Rating from '@thrivetrm/ui/components/Rating';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import Card from '@thrivetrm/ui/components/Card';
import routes from 'modules/routing/routes';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';

// created 10 star array as it is max length of rating stars
const RATING_DEFINITION_NAMES = [
  '★',
  '★★',
  '★★★',
  '★★★★',
  '★★★★★',
  '★★★★★★',
  '★★★★★★★',
  '★★★★★★★★',
  '★★★★★★★★★',
  '★★★★★★★★★★',
];

// this will return rating definitions name array as per rating stars value
const getRatingDefinitionNames = ratingStars =>
  RATING_DEFINITION_NAMES.slice(0, ratingStars);

const transformRatingDefinitionsForUpdate = (
  formState,
  criteriaDataToEdit,
  ratingStars,
) =>
  getRatingDefinitionNames(ratingStars).reduce(
    (definitions, ratingName, index) => {
      const matchingRatingDefinition = criteriaDataToEdit.rating_definitions?.find(
        definition => definition.rating_value === index + 1,
      );
      return {
        ...definitions,
        [index]: {
          id: matchingRatingDefinition?.id,
          rating_definition: formState[ratingName],
          rating_value: matchingRatingDefinition?.rating_value,
        },
      };
    },
    {},
  );

const transformRatingDefinitionsForCreate = (formState, ratingStars) =>
  getRatingDefinitionNames(ratingStars).map(ratingName => {
    return {
      rating_value: ratingName.length,
      rating_definition: formState[ratingName],
    };
  });

const getFormattedRatingDefinitions = (
  formState,
  criteriaDataToEdit,
  searchId,
  ratingStars,
) => {
  /**
   * There's a bunch of complexity when defining the assessment_criteria_rating_definitions_attributes
   * field below.
   *
   * The gist is when we are creating a new Custom Criteria we just pass an array of values to the backend
   * and when we are editing a Custom Criteria we want to pass an object with 0-indexed keys that map to the
   * values of each rating with values as an object containing the rating ID & definition.
   */
  return criteriaDataToEdit?.search_id || (criteriaDataToEdit && !searchId)
    ? transformRatingDefinitionsForUpdate(
        formState,
        criteriaDataToEdit,
        ratingStars,
      )
    : transformRatingDefinitionsForCreate(formState, ratingStars);
};

const CustomCriteriaModal = ({
  categoryId,
  closeModal,
  criteriaDataToEdit,
  existingCriteria,
  isOpen,
  isTalentPool,
  modalTitle,
  onAdd,
  searchId,
}) => {
  const starRatingDefinitionContainerId = uniqueId();
  const dispatch = useDispatch();
  const ratingStars = useSelector(state => state.tenant)?.get('rating_stars');

  // When criteria has search_id or is being edited from Active Admin, use PUT endpoint
  const [updateCriteria] = useApiPut(
    routes.api_v1_assessment_criterium({ id: criteriaDataToEdit?.id }),
  );

  // When criteria DOES NOT have search_id use POST endpoint (creating custom from Active Admin OR Search)
  const [createNewCriteria] = useApiPost(routes.api_v1_assessment_criteria());

  // When criteria is being edited from Active Admin

  const handleSuccess = response => {
    onAdd(response);
    closeModal();
  };

  const handleUpdateOrCreate = formState => {
    const ratingDefinitions = getFormattedRatingDefinitions(
      formState,
      criteriaDataToEdit,
      searchId,
      ratingStars,
    );

    const criteriaObj = {
      name: formState.criteriaName.trim(),
      description: formState.criteriaDescription,
      assessment_criteria_rating_definitions_attributes: ratingDefinitions,
    };
    if (criteriaDataToEdit?.search_id) {
      // Criteria is custom for Search & being EDITED from Search
      updateCriteria(
        { assessment_criterium: { ...criteriaObj, search_id: searchId } },
        {
          onSuccess: response => {
            handleSuccess(response);
            dispatch(toastSuccess('The criteria has been updated'));
          },
        },
      );
    } else if (searchId) {
      /**
       * Custom Criteria is being CREATED from Search (edit global criteria requires
       * creation of custom criteria on search)
       */
      createNewCriteria(
        {
          assessment_criterium: {
            ...criteriaObj,
            search_id: searchId,
          },
          old_criteria_id: criteriaDataToEdit?.id,
          category_id: categoryId,
        },
        {
          onSuccess: response => {
            handleSuccess(response);
            dispatch(
              toastSuccess(
                `The criteria has been ${
                  criteriaDataToEdit ? 'converted' : 'created'
                }`,
              ),
            );
          },
        },
      );
    } else if (criteriaDataToEdit && !searchId) {
      // Criteria is being EDITED from Active Admin
      updateCriteria(
        { assessment_criterium: criteriaObj },
        {
          onSuccess: response => {
            handleSuccess(response);
            dispatch(toastSuccess('The criteria has been updated'));
          },
        },
      );
    } else {
      // Criteria is being CREATED from Active Admin
      createNewCriteria(
        { assessment_criterium: criteriaObj },
        {
          onSuccess: response => {
            handleSuccess(response);
            dispatch(toastSuccess('The criteria has been created'));
          },
        },
      );
    }
  };

  const getContextualTextStrings = () => {
    if (criteriaDataToEdit && searchId) {
      /**
       * When criteriaDataToEdit & searchId exist, the user is either
       * updating a custom criteria or converting a global criteria to
       * custom
       */
      return criteriaDataToEdit?.search_id
        ? { submitButtonLabel: 'Update', title: 'Update Criteria' }
        : { submitButtonLabel: 'Convert', title: 'Convert to Custom Criteria' };
    }
    /**
     * The user is either updating global criteria within active admin
     * or creating a new custom criteria
     */
    return criteriaDataToEdit
      ? { submitButtonLabel: 'Update', title: 'Update Criteria' }
      : { submitButtonLabel: 'Add', title: modalTitle };
  };
  const { submitButtonLabel, title } = getContextualTextStrings();

  const validateCriterionName = criteriaName => {
    /**
     * Only include criteria names that ARE NOT the name of the current criteria
     * being edited.  If the current criteria's name is not modified, that's OK!
     *
     * If, however it is modified to match the name of a DIFFERENT criteria, that
     * is NOT OK
     */
    const criteriaNames = existingCriteria.reduce(
      (criterionThatAreNotThisCriterion, criterion) => {
        if (
          criterion.name !== criteriaDataToEdit?.name ||
          // added this condition as we need to add criteria if it is converted into custom criteria
          (criterion.label && !criteriaDataToEdit?.search_id)
        ) {
          return criterionThatAreNotThisCriterion.concat(
            criterion.name.trim().toLowerCase(),
          );
        }
        return criterionThatAreNotThisCriterion;
      },
      [],
    );
    return criteriaNames.includes(criteriaName.trim().toLowerCase())
      ? 'The name you entered is already taken. Please enter a unique name.'
      : null;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      subTitle={
        searchId && criteriaDataToEdit ? (
          <p className='u-marginBottom-8'>
            {criteriaDataToEdit.search_id ? 'This' : 'The converted'} criteria
            will only be available to use for this{' '}
            {isTalentPool ? 'talent pool' : 'search'}
          </p>
        ) : null
      }
      title={title}
    >
      <Form
        className='CustomCriteriaModal__form'
        onSubmit={handleUpdateOrCreate}
      >
        <Modal.Body className='u-flex u-flexJustify-spaceBetween'>
          <div className='u-width-100 u-paddingRight-12'>
            <Form.TextInput
              className='u-marginBottom-16'
              initialValue={criteriaDataToEdit?.name}
              inputWidth='full'
              label='Criteria Name'
              name='criteriaName'
              rules={{
                validate: validateCriterionName,
                required: {
                  value: true,
                  message: `A name is required before creating a new Criteria`,
                },
              }}
            />
            <Form.TextArea
              className='u-marginBottom-24'
              initialValue={criteriaDataToEdit?.description}
              label='Criteria Description'
              name='criteriaDescription'
              width='full'
            />
            {criteriaDataToEdit ? (
              <Card type='warning'>
                {searchId ? (
                  <p>
                    Changes made to this criteria will be reflected on
                    assessments that have already been completed on this{' '}
                    {isTalentPool ? 'talent pool' : 'search'}.
                  </p>
                ) : (
                  <div>
                    <p>
                      Any changes made here will be reflected on all admin
                      templates and the criteria record in active admin. These
                      changes will also be reflected on all non-custom criteria
                      on search level templates.
                    </p>
                    <br />
                    <p>
                      Non-custom criteria are those added as “Existing
                      Criteria”, or any labelled as “Recommended Criteria”.
                    </p>
                  </div>
                )}
              </Card>
            ) : null}
          </div>
          <div className='u-width-100 u-paddingLeft-12'>
            <label
              className='u-fontWeight-bold u-marginBottom-16 u-textColor-gray60'
              htmlFor={starRatingDefinitionContainerId}
            >
              Star Rating Definitions
            </label>
            <div id={starRatingDefinitionContainerId}>
              {getRatingDefinitionNames(ratingStars).map(
                (ratingName, index) => (
                  <div
                    className='u-flex u-flexAlign-c u-marginBottom-8'
                    key={ratingName}
                  >
                    <Rating
                      className='u-marginRight-16'
                      htmlFor={`rating-${index + 1}`}
                      maxRating={ratingStars}
                      value={ratingName.length}
                    />
                    <Form.TextInput
                      className='u-flexItem-grow'
                      data-testid={`rating-${index + 1}`}
                      initialValue={
                        criteriaDataToEdit?.rating_definitions?.find(
                          definition => definition.rating_value === index + 1,
                        )?.rating_definition
                      }
                      inputWidth='full'
                      name={ratingName}
                    />
                  </div>
                ),
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={closeModal} />
          <Form.SubmitButton label={submitButtonLabel} />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CustomCriteriaModal.defaultProps = {
  existingCriteria: [],
  isTalentPool: false,
  searchId: null,
};

CustomCriteriaModal.propTypes = {
  categoryId: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  criteriaDataToEdit: PropTypes.shape({
    description: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.number,
    label: PropTypes.string,
    locked: PropTypes.bool,
    name: PropTypes.string,
    rating_definitions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        rating_definition: PropTypes.string,
        rating_value: PropTypes.number,
      }),
    ),
    search_id: PropTypes.number,
  }),
  existingCriteria: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),
  isOpen: PropTypes.bool.isRequired,
  isTalentPool: PropTypes.bool,
  modalTitle: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  searchId: PropTypes.number,
};

export default CustomCriteriaModal;

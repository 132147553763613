import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import mapContactIdToContact from '../mapContactIdToContact';
import ContactCareerHighlightsForm from './ContactCareerHighlightsForm';
import ContactCareerHighlightsPreview from './ContactCareerHighlightsPreview';

/**
 * Renders an EditableSection that displays and allows editing of
 * a contact's "Career Highlights".
 * Initially the view mode shows a partial preview of the career highlights that
 * can be expanded to show the full career highlights.
 * If the flipper 'label.career_highlights_to_google_insights' is turned on,
 * the label should read "Google Insights"
 */
export default compose(
  setDisplayName('ContactCareerHighlightsEditableSection'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  withFeatureCheck(
    'label.career_highlights_to_google_insights',
    'useGoogleInsightsLabel',
  ),
  mapProps(
    ({
      contact,
      contactId,
      draftStoragePath,
      lastUpdated,
      useGoogleInsightsLabel,
    }) => ({
      contactId: contactId,
      children: contact.get('career_highlights') ? (
        <ContactCareerHighlightsPreview contactId={contactId} />
      ) : null,
      className: 'ContactCareerHighlightsEditableSection',
      formComponent: ContactCareerHighlightsForm,
      title: useGoogleInsightsLabel ? 'Google Insights' : 'Career Highlights',
      draftStoragePath: draftStoragePath,
      lastUpdated: lastUpdated,
    }),
  ),
)(EditableSection);

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  onlyUpdateForPropTypes,
  renderNothing,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import wrapInSearchCandidacyInterviewsTabLink from './wrapInSearchCandidacyInterviewsTabLink';
import InterviewDate from './InterviewDate';
import { SORT_BY_DATE } from '../constants';

const InterviewTableDateColumn = compose(
  setStatic('columnTitle', 'Date & Time'),
  setStatic('sortBy', SORT_BY_DATE),
  setStatic('key', SORT_BY_DATE),
  onlyUpdateForPropTypes,
  setPropTypes({
    interview: ImmutablePropTypes.mapContains({
      start_time: PropTypes.string,
      id: PropTypes.number.isRequired,
    }).isRequired,
  }),
  setDisplayName('InterviewTableDateColumn'),
  wrapInSearchCandidacyInterviewsTabLink,
  branch(({ interview }) => !interview.get('start_time'), renderNothing),
)(InterviewDate);

export default InterviewTableDateColumn;

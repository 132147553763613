import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import PendingContactDiscardButton from './PendingContactDiscardButton';
import { handleAutoDiscardIndicator } from './pendingContactUtils';

const SOURCE_MAPPINGS = {
  CsvUpload: 'CSV Upload',
  Github: 'Github',
  JobApplication: 'Thrive Apply',
  LinkedIn: 'LinkedIn',
  Resume: 'Resume Upload',
};

const PendingContactWithMatches = ({ pendingContact }) => {
  const hasAutoDiscardFeature = useFeatureCheck(
    'feature.auto_discard_pending_contacts',
  );
  const matchingContact = pendingContact?.matches?.[0];

  const matchMessage = matchingContact?.exact ? (
    <>
      An <strong>exact match</strong> has been found.
    </>
  ) : (
    <>
      A <strong>potential match</strong> has been found.
    </>
  );

  return (
    <Card
      className='u-marginBottom-16 u-padding-n'
      data-testid='pending-contact-card'
      isCentered={false}
    >
      <div className='u-flex u-flexJustify-c u-paddingVertical-16'>
        <div
          className='u-borderRight u-paddingRight-32 u-marginRight-24'
          style={{ position: 'relative' }}
        >
          <h3 className='u-marginTop-n u-marginBottom-24 u-noWrap'>
            New Contact from {SOURCE_MAPPINGS[pendingContact.strategy]}
          </h3>
          <ContactSnapshot
            avatarUrl={pendingContact.picture}
            className='PendingContactWithMatches__contactSnapshot'
            company={{ name: pendingContact.company }}
            name={pendingContact.name}
            title={pendingContact.title}
          />
          {hasAutoDiscardFeature ? (
            <div className='u-paddingTop-8'>
              {handleAutoDiscardIndicator(pendingContact)}
            </div>
          ) : null}
          <div
            /**
             * Use the CSS triangle trick and manually position arrow relative
             * to the 'Discard' button. https://css-tricks.com/snippets/css/css-triangle/
             *  */
            style={{
              // #dee0e8 is gray-20
              borderBottom: '16px solid #dee0e8',
              borderLeft: '16px solid transparent',
              borderRight: '16px solid transparent',
              left: 4,
              bottom: -16,
              height: 0,
              position: 'absolute',
              width: 0,
            }}
          />
        </div>
        <div className='u-marginBottom-32'>
          <h3 className='u-marginTop-n u-marginBottom-24 u-noWrap'>
            Existing Contact in Thrive
          </h3>
          <ContactSnapshot
            avatarUrl={matchingContact.picture}
            className='PendingContactWithMatches__contactSnapshot'
            company={{ name: matchingContact.company }}
            name={matchingContact.name}
            title={matchingContact.title}
          />
        </div>
      </div>
      <div className='u-backgroundColor-gray20 u-padding-16'>
        <p
          className='u-fontSize-small u-textColor-gray60 u-textAlign-c u-marginBottom-16'
          data-testid='duplicate contact message'
        >
          {matchMessage} What would you like to do with the duplicate?
        </p>
        <div className='u-flex u-flexJustify-c u-noWrap'>
          <PendingContactDiscardButton id={pendingContact.id} />
          <ButtonPrimary
            isDisabled={matchingContact.exact}
            isOutline={true}
            label='Create Anyway'
            url={`/contacts/pending/${pendingContact.id}`}
          />
          <ButtonPrimary
            label='Merge With Existing'
            url={`/contacts/${matchingContact.contact_id}/pending/${pendingContact.id}`}
          />
        </div>
      </div>
    </Card>
  );
};

PendingContactWithMatches.propTypes = {
  pendingContact: PropTypes.shape({
    company: PropTypes.string,
    id: PropTypes.number.isRequired,
    matches: PropTypes.arrayOf(
      PropTypes.shape({
        company: PropTypes.string,
        contact_id: PropTypes.number,
        exact: PropTypes.bool,
        id: PropTypes.number,
        name: PropTypes.string,
        picture: PropTypes.string,
        title: PropTypes.string,
      }),
    ).isRequired,
    name: PropTypes.string,
    picture: PropTypes.string,
    strategy: PropTypes.oneOf(Object.keys(SOURCE_MAPPINGS)),
    title: PropTypes.string,
  }).isRequired,
};

export default PendingContactWithMatches;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import routes from 'modules/routing/routes';
import getBulkImportProperty from '../selectors/getBulkImportProperty';

const BulkImportResultLink = ({ isPending, resultsCsvUrl }) =>
  isPending ? <i>Pending</i> : <a href={resultsCsvUrl}>Results as CSV</a>;

BulkImportResultLink.propTypes = {
  isPending: PropTypes.bool.isRequired,
  resultsCsvUrl: PropTypes.string,
};

export default compose(
  setDisplayName('BulkImportResultLink(enhanced)'),
  setPropTypes({ bulkImportId: PropTypes.number.isRequired }),
  connect((state, { bulkImportId }) => {
    const uuid = getBulkImportProperty(state, bulkImportId, 'uuid');
    const endTime = getBulkImportProperty(state, bulkImportId, 'end_time');
    return {
      resultsCsvUrl: routes.results_api_v1_csv_upload({ uuid: uuid }),
      isPending: !endTime,
    };
  }),
)(BulkImportResultLink);

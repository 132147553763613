import PropTypes from 'prop-types';
import classnames from 'classnames';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import {
  componentFromProp,
  compose,
  defaultProps,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  setStatic,
  withHandlers,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';
import Select, { Creatable } from 'react-select-legacy';
import KeyCode from 'keycode-js';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import identity from '@thrivetrm/ui/utilities/identity';
import withFormGroup from 'modules/forms/components/withFormGroup';

import * as contactQueryActionsV1 from '../../actions/contacts/query.v1';
import getQuerySelector from '../../selectors/contacts/getQuery';
import getContactSelector from '../../selectors/contacts/getContact';

import ContactEmailSelectValue from './ContactEmailSelectValue';
import ContactEmailSelectOption from './ContactEmailSelectOption';
import createFieldState from './createFieldState';

/**
 * Entering any of these keys will trigger the creation of a "new" option -- which in this
 * case is simply entering an email addres without selecting an existing contact.
 */
export const CREATE_OPTION_KEY_CODES = [
  KeyCode.KEY_ENTER,
  KeyCode.KEY_RETURN,
  KeyCode.KEY_TAB,
  KeyCode.KEY_COMMA,
  KeyCode.KEY_SEMICOLON,
];

/**
 * Used by react-select to determine whether a keypress should cause the generation of a new
 * options
 * @param {Object} params
 * @param {String} params.keyCode The key pressed.
 */
export const shouldKeyDownEventCreateNewOption = ({ keyCode }) =>
  CREATE_OPTION_KEY_CODES.includes(keyCode);

/**
 * Creates a new "option" object when the user presses one of the CREATE_OPTION_KEY_CODES key.
 * @param {Object} param
 * @param {String} param.label The text the user entered.
 */
export const newOptionCreator = ({ label }) => ({ email: label });

/**
 * A component for selecting email addresses. Autocompletes based on the contacts database.
 */
export default compose(
  setDisplayName('ContactEmailSelectField'),
  setPropTypes({
    /**
     * True to allow ANY email address to be enter; when false only email addresses that relate
     * to a contact may be entered (either as `work_email` or `email`.
     */
    allowManualEntry: PropTypes.bool.isRequired,

    /**
     * The minimum length the input must be before the autocomplete is triggered.
     */
    minTermLength: PropTypes.number.isRequired,

    /**
     * Whether to allow a single email address (false) or multiple email addresses (true) to be
     * entered/selected.
     */
    multi: PropTypes.bool.isRequired,

    /**
     * The number of milliseconds to delay the autocomplete query (for debouncing).
     */
    queryDelay: PropTypes.number.isRequired,
  }),
  setStatic('createFieldState', createFieldState),
  defaultProps({
    allowManualEntry: true,

    // prevents the  built-in react-select filtering functionality, which won't work here because
    // our "option" objects ({ email, name, label }), don't match what react-select expects
    // by default ({ key, label }), which causes _everything_ to get filtered out and
    // nothing shown in the autocomplete dropdown once one entry is entered.
    filterOptions: identity,

    minTermLength: 3,
    multi: true,
    optionRenderer: ContactEmailSelectOption,
    queryDelay: 300,
    valueRenderer: ContactEmailSelectValue,
  }),

  // We need a unique componentId for our underlying query actions.
  withComponentId('ContactEmailSelectField'),

  // Manage the current text input by the user that we're searching/autocompleting against.
  // Also centralizes the logic for determining whether our term meets the minimum length
  // requirement for triggering the autocomplete.
  withStateHandlers(
    {
      term: '',
      termMeetsMinLength: false,
    },
    {
      setTerm: (_, { minTermLength }) => (term = '') => ({
        term: term,
        termMeetsMinLength: term.trim().length >= minTermLength,
      }),
    },
  ),

  // Grab the data we need from state and bind our action creators.
  connect(
    (state, { componentId, term }) => {
      const query = getQuerySelector(state, componentId);
      const isLoading = query && query.getIn(['meta', 'isFetching']);

      // Get the contactIds -- but only if the results are for the current term (otherwise
      // the user may see the wrong results if they had results but then changed their search term
      // and the new request has not yet been recieved from the server.
      const contactIds =
        !isLoading &&
        query &&
        query.get('term') === term &&
        query.getIn(['data', 'contacts']);

      return {
        contacts:
          contactIds && contactIds.map(id => getContactSelector(state, id)),
        isLoading: query && query.getIn(['meta', 'isFetching']),
      };
    },
    dispatch => ({
      contactActions: bindActionCreators(contactQueryActionsV1, dispatch),
    }),
  ),

  // Generate the underlying react-select `options` from the contacts that have been fetched.
  withPropsOnChange(
    ['allowManualEntry', 'contacts', 'term'],
    ({ allowManualEntry, contacts, term, termMeetsMinLength }) => {
      // Because react-select doesn't work correctly when Creatable is used asynchronously,
      // we need to generate a "fake" option which, when selected, creates an option from the
      // current input text/search string (term).
      const initialOptions =
        allowManualEntry && termMeetsMinLength ? [{ email: term.trim() }] : [];

      if (!contacts || !termMeetsMinLength) {
        return initialOptions;
      }

      return {
        // Each "contact" record will represent 1 or 2 underlying options: for `email` and
        // `work_email` depending on which are avialable.
        options: contacts.reduce((options, contact) => {
          if (contact) {
            if (contact.get('email') && contact.get('work_email')) {
              options.push({
                id: contact.get('id'),
                email: contact.get('work_email'),
                preferred: contact.get('work_email_preferred') === true,
                label: 'Work',
                name: contact.get('full_name') || contact.get('name'),
              });
              options.push({
                id: contact.get('id'),
                email: contact.get('email'),
                preferred: contact.get('work_email_preferred') === false,
                label: 'Personal',
                name: contact.get('full_name') || contact.get('name'),
              });
            } else {
              options.push({
                id: contact.get('id'),
                email: contact.get('work_email') || contact.get('email'),
                name: contact.get('full_name') || contact.get('name'),
              });
            }
          }

          return options;
        }, initialOptions),
      };
    },
  ),

  // Create a debounced fetchContactsQuery action handler.
  // We do this here so the debounced function only gets recreated when the
  // underlying related props are changed -- otherwise it would get recreated any time
  // ANY prop changes, which basically makes the whole debouncing moot, because
  // a prop changing is what actually triggers the call in the first place.
  withPropsOnChange(
    ['contactActions', 'queryDelay'],
    ({ contactActions, queryDelay }) => ({
      fetchContactsQuery: debounce(
        contactActions.fetchContactsQuery,
        queryDelay,
      ),
    }),
  ),

  // Handle creating/destroying the underlying query state when the component is mounted/unmounted
  lifecycle({
    componentDidMount: function () {
      const { componentId, contactActions } = this.props;
      contactActions.createContactsQuery(componentId);
    },
    componentWillUnmount: function () {
      const { componentId, contactActions } = this.props;
      contactActions.destroyContactsQuery(componentId);
    },
    UNSAFE_componentWillReceiveProps: function ({
      componentId,
      fetchContactsQuery,
      term,
      termMeetsMinLength,
    }) {
      if (termMeetsMinLength && term !== this.props.term) {
        fetchContactsQuery({
          term: term,
          query: {
            // Only returns contacts that have an email address.
            has_email: true,
            skip_es: true,
          },
          queryId: componentId,
        });
      }
    },
  }),

  // Add a form group wrapepr around the component.
  withFormGroup,

  withHandlers({
    // If someone enters text in the field, and then moves focus out of it, react-select
    // will normally clear out the input -- but we want that to trigger the creation of a
    // new item in the list.
    onBlur: ({ allowManualEntry, fieldState, onBlur, onChange }) => event => {
      if (onBlur) {
        // Call any onBlur event that the parent component may have provided.
        onBlur(event);
      }

      // Bail if we're not allowing manually entry (i.e. the user MUST select a contact) or
      // if someone cancelled the eventb.
      if (!allowManualEntry || event.defaultPrevented) return;

      const { value } = event.target;
      if (value) {
        onChange(
          fieldState.setValue(fieldState.getValue().concat({ email: value })),
        );
      }
    },
    // A handler for whe the underlying selection is changed
    onChange: ({ fieldState, onChange, setTerm }) => value => {
      onChange(fieldState.setValue(value));
      // The term needs to get reset because for some reason  react-select does not call
      // onInputChange when the input is cleared due to an item being selected
      setTerm('');
    },
  }),

  mapProps(
    ({
      allowManualEntry,
      className,
      contacts,
      fieldState,
      id,
      inputProps,
      isLoading,
      multi,
      setTerm,
      termMeetsMinLength,
      ...props
    }) => ({
      // These props could potentially get overriden by the consumer of this component
      // providing them.
      name: fieldState.getName(),
      placeholder: multi ? 'Enter emails...' : 'Enter email...',
      promptTextCreator: identity,
      shouldKeyDownEventCreateNewOption: shouldKeyDownEventCreateNewOption,

      ...props,

      // These props shouldn't get overriden
      className: classnames('react-select', className),
      component: allowManualEntry ? Creatable : Select,
      inputProps: {
        ...inputProps,
        id: id,
      },
      isLoading: isLoading,
      multi: multi,
      newOptionCreator: newOptionCreator,
      noResultsText: isLoading
        ? 'Loading...'
        : termMeetsMinLength && contacts && 'No matching contacts found',
      onInputChange: setTerm,
      value: fieldState.getValue(),
    }),
  ),

  // The final underlying component will be either `Createable` or `Select`, depending on
  // the previous value of `allowManualEntry`, above, which sets the `component` prop.
)(componentFromProp('component'));

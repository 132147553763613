import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import EditIconButton from 'modules/forms/components/EditIconButton';
import { convertToCamelCase } from 'modules/core/jsonUtils';
import getContact from 'modules/contacts/selectors/contacts/getContact';
import useQuickView from 'modules/quick-view/useQuickView';
import PluralText from '@thrivetrm/ui/components/PluralText';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';

const ContactConnectionsSection = ({ className, contactId }) => {
  const contact = useSelector(state => getContact(state, contactId));
  const { navigateTo } = useQuickView();

  const {
    connectionsCount,
    firstName,
    fullName,
    reportingRelationshipsCount = 0,
  } = convertToCamelCase(contact.toJS());

  const hasConnections = connectionsCount > 0;
  const suggestedConnectionsFeature = useFeatureCheck(
    'feature.suggested_connections',
  );

  const openConnectionsQuickViewPanel = () =>
    navigateTo(`/contact/${contactId}/connections`, {
      title: fullName,
    });

  const openReportingRelationshipQuickViewPanel = () =>
    navigateTo(
      `/contact/${contactId}/connections/?selectedTab=REPORTING RELATIONSHIPS`,
      {
        title: fullName,
      },
    );

  const openSuggestedConnectionQuickViewPanel = () =>
    navigateTo(
      `/contact/${contactId}/connections/?selectedTab=SUGGESTED CONNECTIONS`,
      {
        title: fullName,
      },
    );

  const getButtonLinkLabel = (text, unit) => (
    <>
      {unit} <PluralText quantity={unit} text={text} />
    </>
  );

  const sectionContent = (
    <div className='u-margin-n u-textColor-gray50'>
      {firstName} has{' '}
      <ButtonLink
        aria-label='view connections link'
        label={getButtonLinkLabel('Connection', connectionsCount)}
        onClick={openConnectionsQuickViewPanel}
      />{' '}
      &{' '}
      <ButtonLink
        aria-label={`view ${
          suggestedConnectionsFeature
            ? 'suggested connections'
            : 'reporting relationship'
        } link`}
        label={
          suggestedConnectionsFeature
            ? getButtonLinkLabel('Suggested connection', null)
            : getButtonLinkLabel(
                'Reporting Relationship',
                reportingRelationshipsCount,
              )
        }
        onClick={
          suggestedConnectionsFeature
            ? openSuggestedConnectionQuickViewPanel
            : openReportingRelationshipQuickViewPanel
        }
      />
    </div>
  );

  /**
   * Using existing classnames for this new section even though the component
   * is no longer aligned with existing `EditableSection` UI to reduce
   * duplicate code/styles while feature are migrated into new UI patterns
   */
  return (
    <div className={classnames('EditableSection', className)}>
      <h4 className='EditableSection__header'>
        <span className='EditableSection__title'>
          {`Connections & ${
            suggestedConnectionsFeature
              ? 'Suggested connections'
              : 'Reporting Relationships'
          }`}
        </span>
        {hasConnections ? null : (
          <EditIconButton
            aria-label='create connection'
            className='EditableSection__toggle btn-link'
            iconClassName='fa-plus-circle'
            onClick={openConnectionsQuickViewPanel}
          />
        )}
      </h4>
      <div className='EditableSection__content'>
        {hasConnections
          ? sectionContent
          : `No Connections & ${
              suggestedConnectionsFeature
                ? 'Suggested connections'
                : 'Reporting Relationships'
            }`}
      </div>
    </div>
  );
};

ContactConnectionsSection.propTypes = {
  className: PropTypes.string,
  contactId: PropTypes.number,
};

export default ContactConnectionsSection;

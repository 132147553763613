import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
  setStatic,
  withProps,
} from 'recompose';
import mapContactIdToFullName from 'modules/contacts/components/mapContactIdToFullName';

import wrapInSearchCandidacyInterviewsTabLink from './wrapInSearchCandidacyInterviewsTabLink';
import { SORT_BY_CANDIDATE_NAME } from '../constants';

const InterviewTableCandidateColumn = compose(
  setStatic('columnTitle', 'Candidate'),
  setStatic('sortBy', SORT_BY_CANDIDATE_NAME),
  setStatic('key', SORT_BY_CANDIDATE_NAME),
  onlyUpdateForPropTypes,
  setPropTypes({
    interview: ImmutablePropTypes.mapContains({
      date: PropTypes.string,
      id: PropTypes.number.isRequired,
    }).isRequired,
  }),
  setDisplayName('InterviewTableCandidateColumn'),
  withProps(({ interview }) => ({
    contactId: interview.get('candidate'),
  })),
  mapContactIdToFullName,
  wrapInSearchCandidacyInterviewsTabLink,
)(({ fullName }) => <span>{fullName}</span>);

export default InterviewTableCandidateColumn;

import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import BooleanText from '@thrivetrm/ui/components/BooleanText';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Date from '@thrivetrm/ui/components/Date';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import Grid from '@thrivetrm/ui/components/Grid';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import isExecutiveSearchTenant from 'modules/tenant/selectors/isExecutiveSearchTenant';
import StatusAndAllocation from './StatusAndAllocation';
import { loadSearchRequest } from '../searchRequestSlice';

const SearchRequestProfilePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchRequestId } = useParams();
  const { searchRequest } = useSelector(state => state.searchRequest);
  const isExecutiveSearchTenantType = useSelector(isExecutiveSearchTenant);

  useEffect(() => {
    dispatch(loadSearchRequest(searchRequestId));
  }, [dispatch, searchRequestId]);

  const verificationUrl = searchRequest?.verification_of_approval?.external_url;
  const otherDocumentUrl = searchRequest?.other_document?.external_url;

  const handleEditClick = () => {
    history.push(`/search_request_form/${searchRequest.id}/request_details`);
  };

  return (
    <Grid>
      <Grid.Column
        className='u-paddingHorizontal-n SearchRequestProfilePage__Container'
        size={4}
      >
        {searchRequest ? (
          <div className='u-paddingHorizontal-24'>
            <div className='SearchRequestProfilePage__Section'>
              <h1>
                Search Request
                <ButtonSecondary
                  className='u-marginLeft-16'
                  icon='edit'
                  isOutline={true}
                  onClick={handleEditClick}
                  size='small'
                />
              </h1>
              <p>
                {`Submitted by ${searchRequest.created_by?.name} on `}{' '}
                <Date date={searchRequest.created_at} />
              </p>
            </div>
            <div className='SearchRequestProfilePage__Section'>
              <h2>Request Details</h2>
              <Grid>
                <Grid.Column className='u-paddingLeft-n' size={6}>
                  {isExecutiveSearchTenantType && searchRequest.company_name ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Company Name'
                    >
                      {searchRequest.company_name}
                    </LabeledContent>
                  ) : null}

                  {searchRequest.organization ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Organization'
                    >
                      {searchRequest.organization?.name}
                    </LabeledContent>
                  ) : null}

                  {searchRequest.cost_center ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Cost Center'
                    >
                      {searchRequest.cost_center?.name}
                    </LabeledContent>
                  ) : null}
                </Grid.Column>
                <Grid.Column size={6}>
                  {searchRequest.hiring_manager_name ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Hiring Manager'
                    >
                      {searchRequest.hiring_manager_name}
                    </LabeledContent>
                  ) : null}

                  {searchRequest.placement_type ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Placement Type'
                    >
                      {searchRequest.placement_type?.name}
                    </LabeledContent>
                  ) : null}
                </Grid.Column>
              </Grid>

              {verificationUrl ? (
                <LabeledContent
                  className='SearchRequestProfilePage__Link'
                  label='Approval Verification'
                >
                  <a
                    href={verificationUrl}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    {verificationUrl}
                  </a>
                </LabeledContent>
              ) : null}
            </div>

            <div className='SearchRequestProfilePage__Section'>
              <h2>Role Details</h2>
              <Grid>
                <Grid.Column className='u-paddingLeft-n' size={6}>
                  <LabeledContent
                    className='SearchRequestProfilePage__Field'
                    label='Job Title'
                  >
                    {searchRequest.job_title}
                  </LabeledContent>
                  {searchRequest.location ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Location'
                    >
                      {searchRequest.location}
                    </LabeledContent>
                  ) : null}

                  {searchRequest.job_code ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Job Code'
                    >
                      {searchRequest.job_code}
                    </LabeledContent>
                  ) : null}

                  {searchRequest.product_area ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Product Area'
                    >
                      {searchRequest.product_area?.name}
                    </LabeledContent>
                  ) : null}

                  <LabeledContent
                    className='SearchRequestProfilePage__Field'
                    label='Confidential?'
                  >
                    <BooleanText value={searchRequest.confidential} />
                  </LabeledContent>
                </Grid.Column>
                <Grid.Column size={6}>
                  {searchRequest.search_region ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Region'
                    >
                      {searchRequest.search_region?.name}
                    </LabeledContent>
                  ) : null}

                  {searchRequest.search_reason ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Search Reason'
                    >
                      {searchRequest.search_reason?.name}
                    </LabeledContent>
                  ) : null}

                  {searchRequest.incumbent_name ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Incumbent'
                    >
                      {searchRequest.incumbent_name}
                    </LabeledContent>
                  ) : null}

                  <LabeledContent
                    className='SearchRequestProfilePage__Field'
                    label='Job Function'
                  >
                    {searchRequest.job_function_category?.name}
                  </LabeledContent>
                  {searchRequest.level ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Level'
                    >
                      {searchRequest.level?.name}
                    </LabeledContent>
                  ) : null}

                  {searchRequest.code_name ? (
                    <LabeledContent
                      className='SearchRequestProfilePage__Field'
                      label='Code Name'
                    >
                      {searchRequest.code_name}
                    </LabeledContent>
                  ) : null}
                </Grid.Column>
              </Grid>

              {searchRequest.confidential_reason_note ? (
                <LabeledContent
                  className='SearchRequestProfilePage__Field'
                  label='Confidential Reason'
                >
                  <ExpandableText
                    characterLimit={300}
                    content={searchRequest.confidential_reason_note}
                  />
                </LabeledContent>
              ) : null}
            </div>

            <h2>Notes & Documents</h2>
            <ExpandableText
              characterLimit={300}
              className='SearchRequestProfilePage__Notes'
              content={searchRequest.qualifications}
            />
            {otherDocumentUrl ? (
              <LabeledContent
                className='SearchRequestProfilePage__Link'
                label='Documents'
              >
                <a
                  href={otherDocumentUrl}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {otherDocumentUrl}
                </a>
              </LabeledContent>
            ) : null}
          </div>
        ) : null}
      </Grid.Column>
      <Grid.Column className='u-paddingHorizontal-n' size={8}>
        {searchRequest ? (
          <StatusAndAllocation searchRequest={searchRequest} />
        ) : null}
      </Grid.Column>
    </Grid>
  );
};

export default SearchRequestProfilePage;

import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import ContactAvatarWithQuickVIew from 'modules/contacts/components/ContactAvatarWithQuickView';
import React from 'react';
import { useSelector } from 'react-redux';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { CONNECTION_TYPE_GROUP_WITH_NAME } from 'services/apiV1/connections';
import { REFERENCE_TYPES } from 'modules/references/components/ReferenceFormV2';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import PropTypes from 'prop-types';
import ContactLink from 'modules/contacts/components/ContactLink';
import Notification from './Notification';

const Reference = ({ notification }) => {
  const isFullAccessOrAdminUser = useSelector(isEmployeeUser);
  const isCrmUser = useSelector(isCrmUserSelector);
  const hasConnectionsFeature = useFeatureCheck('feature.connections_gate');
  const hasReferencesConnectionsFeature = useFeatureCheck(
    'feature.references_connections',
  );
  const reference = notification?.resource;
  const hasReferenceConnectionV2 = useFeatureCheck(
    'development.references_connection_v2',
  );

  return (
    <Notification notification={notification}>
      {hasReferenceConnectionV2 ? (
        <div className='u-flex u-flexAlign-c u-marginTop-8'>
          {reference?.referee?.id ? (
            <ContactAvatarWithQuickVIew
              avatarUrl={reference.referee.avatar_url}
              className='u-marginRight-12'
              connectionsCount={reference.referee.connections_count}
              contactId={reference.referee.id}
              contactName={reference.referee.full_name}
              shouldUseQuickView={isFullAccessOrAdminUser || isCrmUser}
            />
          ) : null}
          <div className='u-wordBreak'>
            {reference?.referee?.full_name ? (
              <span className='u-textColor-gray60'>
                {reference?.referee?.full_name}
              </span>
            ) : null}

            {hasConnectionsFeature && reference.connection?.id ? (
              <p className='u-margin-n'>
                {reference.connection.name}
                {CONNECTION_TYPE_GROUP_WITH_NAME.includes(
                  reference.connection?.group,
                )
                  ? ` ${reference.contactFirstName}`
                  : null}
              </p>
            ) : null}

            {!hasConnectionsFeature && reference?.relationship ? (
              <p className='u-margin-n'>{reference?.relationship}</p>
            ) : null}

            {reference?.reference_type ? (
              <p className='u-margin-n'>
                {REFERENCE_TYPES[reference?.reference_type]}
              </p>
            ) : null}
            <ExpandableText
              characterLimit={100}
              className='u-wordBreak'
              content={reference?.description}
              isSanitizedHtml={true}
            />
          </div>
        </div>
      ) : (
        <div className='reference-item reference-view'>
          <div className='field-name title'>
            {isFullAccessOrAdminUser ? (
              <ContactLink
                className='ContactName'
                contactId={reference.referee.id}
              >
                {reference.referee.full_name}
              </ContactLink>
            ) : (
              <span className='ContactName'>{reference.referee.full_name}</span>
            )}
          </div>
          <div className='field-relationship'>
            {hasConnectionsFeature && hasReferencesConnectionsFeature
              ? reference.connection.name
              : reference.relationship}
            {hasConnectionsFeature &&
            CONNECTION_TYPE_GROUP_WITH_NAME.includes(
              reference.connection.group || '',
            )
              ? ` ${reference.referee.full_name}`
              : null}
            {reference.company ? (
              <span>
                {' at '}
                {reference.company}
              </span>
            ) : null}
          </div>
          <div className='field-email'>{reference.email}</div>
          <div className='field-phone'>{reference.phone}</div>
          {reference?.description && (
            <ExpandableText
              characterLimit={150}
              content={reference?.description}
              isSanitizedHtml={true}
            />
          )}
        </div>
      )}
    </Notification>
  );
};

Reference.propTypes = {
  notification: PropTypes.shape({
    resource: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      referee: PropTypes.shape({
        avatarUrl: PropTypes.string,
        connectionsCount: PropTypes.number,
        fullName: PropTypes.string,
        id: PropTypes.number,
      }),
    }),
  }),
};

export default Reference;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, withStateHandlers } from 'recompose';
import ResidencyCreateModalForm from './ResidencyCreateModalForm';
import { RESIDENCY_TYPE_CONSTANTS as residencyTypeConstants } from '../constants';

/**
 * Renders a button which shows and manages the display state of an associated
 * ResidencyCreateModalForm
 */
const ResidencyCreateModalButton = ({
  handleModalHide,
  handleModalShow,
  icon,
  label,
  residencyType,
  showModal,
}) => (
  <div className='ResidencyCreateModalButton'>
    <button
      className={`btn btn-social btn-social-${icon} u-marginTop-n`}
      onClick={handleModalShow}
      type='button'
    >
      <i className={`fa fa-${icon}`} />
      {label}
    </button>
    {showModal && (
      <ResidencyCreateModalForm
        onClose={handleModalHide}
        residencyType={residencyType}
        show={showModal}
      />
    )}
  </div>
);

ResidencyCreateModalButton.defaultProps = {
  icon: 'code',
  residencyType: residencyTypeConstants.API_PUBLIC,
};

ResidencyCreateModalButton.propTypes = {
  /**
   * Called when the modal should be hidden (showModal should be set to false)
   */
  handleModalHide: PropTypes.func.isRequired,
  /**
   * Called when the modal should be shown (showModal should be set to true)
   */
  handleModalShow: PropTypes.func.isRequired,
  /**
   * FontAwesome icon to display on the button
   */
  icon: PropTypes.string,
  /**
   * Label to display on the button
   */
  label: PropTypes.string.isRequired,
  /**
   * Type of OutlookAddin or API
   */
  residencyType: PropTypes.oneOf(Object.values(residencyTypeConstants)),
  /**
   * Whether the modal should be displayed or not.
   */
  showModal: PropTypes.bool.isRequired,
};

/**
 * Connected version handles the state of the modal. No additional props needed!
 */
export default compose(
  setDisplayName('ResidencyCreateModalButton(enhanced)'),
  withStateHandlers(
    {
      showModal: false,
    },
    {
      handleModalHide: () => () => ({ showModal: false }),
      handleModalShow: () => () => ({ showModal: true }),
    },
  ),
)(ResidencyCreateModalButton);

import PropTypes from 'prop-types';
import {
  branch,
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS, Map } from 'immutable';
import connectAssessmentActions from 'modules/assessments/components/connectAssessmentActions';
import mapAssessmentIdToAssessment from 'modules/assessments/components/mapAssessmentIdToAssessment';
import mapRatingIdsToRatings from 'modules/assessments/components/mapRatingIdsToRatings';
import { ASSESSMENT_TYPES } from 'modules/assessments/constants';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

import AssessmentField from './AssessmentField';
import withTransactionTrackedForm from '../../../../components/forms/withTransactionTrackedForm';
import withDraftStorage from '../../../../components/forms/withDraftStorage';

const createRootFieldState = ({ assessment, draft, ...props }) => {
  let assessmentValue;
  if (assessment) {
    // When editing an existing assessment
    assessmentValue = assessment.withMutations(immutableAssesment => {
      if (draft?.content) {
        immutableAssesment.set('comment', draft?.content);
      }
    });
  } else {
    // When creating a new assessment
    assessmentValue = fromJS({
      comment: draft?.content,
    });
  }
  const assessmentFieldState = {
    assessment: assessmentValue,
    ...props,
  };
  return AssessmentField.createFieldState('assessment', assessmentFieldState);
};

const handleSubmit = (formState, props) => {
  const {
    assessment,
    assessmentActions: {
      createAssessment,
      createGuestAssessment,
      updateAssessment,
    },
    assessmentType,
    candidacyId,
    // only needed/present for GuestAssessments
    token,
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();

  let values = new Map({ candidacy_id: candidacyId, type: assessmentType });
  values = assessment || values;
  values = values.merge(fieldValue);

  let action = createAssessment;

  if (token) {
    // Guest assessments will have an associated token supplied, and use a
    // different endpoint.
    action = createGuestAssessment;
  } else if (assessment && assessment.get('id')) {
    action = updateAssessment;
  }

  action({
    token: token,
    assessment: values.toJS(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export default compose(
  setDisplayName('AssessmentForm'),

  // At least ONE of the following props are required: An `assessment` itself or an `assessmentId`
  // (if editing an existing assessment), otherwise a `candidacyId` is required in order
  // to know which assessment options to show.
  setPropTypes({
    /**
     * The assessment record if editing an existing assessment. Either this or `assessmentId` may be
     * supplied -- it's not necessary to supply both.
     */
    assessment: ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),

    /**
     * The ID of an assessment to edit an existing assessment. Either this or `assessment` may be
     * supplied -- it's not necessary to supply both.
     */
    assessmentId: PropTypes.number,

    /**
     * The assessment type to create. Required when not editing an existing assessment.
     */
    assessmentType: requiredIf(
      PropTypes.oneOf(ASSESSMENT_TYPES),
      ({ assessment, assessmentId }) => !(assessment || assessmentId),
    ),

    /**
     * THe candidacy ID this assessment should be assigned to. Required when not editing an
     * existing assessment.
     */
    candidacyId: requiredIf(
      PropTypes.number,
      ({ assessment, assessmentId }) => !(assessment || assessmentId),
    ),
  }),

  // If no assessment record was given but an assessmentId was, get the assessment record by ID.
  branch(
    props => props.assessmentId && !props.assessment,
    mapAssessmentIdToAssessment,
  ),

  // Make sure the candidacyId is provided as a prop (get it from the assessment record if we're
  // editing an existing assessment). Also pull the ratings out of the record so it can be
  // used by `mapRatingIdsToRatings`
  withProps(({ assessment, candidacyId, ratingIds, token }) => ({
    candidacyId: assessment ? assessment.get('candidacy_id') : candidacyId,
    isGuestAssessment: Boolean(token),
    ratingIds: assessment ? assessment.get('ratings') : ratingIds,
    hasAnswerContent: assessment && assessment.get('has_answer_content'),
  })),
  withFeatureCheck(
    'field.recruiter_assessment.comment_required',
    'hasRecruiterAssessmentCommentRequired',
  ),
  mapRatingIdsToRatings,
  connectAssessmentActions,
  connectTransactions,
  withDraftStorage(),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'assessment-form',
    omitProps: [
      'assessment',
      'assessmentActions',
      'assessmentAttributes',
      'draft?.content',
      'formState',
      'ratingIds',
      'ratings',
      'searchActions',
      'token',
      'transactionActions',
    ],
  }),
)(AssessmentField);

import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@thrivetrm/ui/components/Menu';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import FullPageTakeoverHeader from '@thrivetrm/ui/components/FullPageTakeoverHeader';
import { ViewTypes } from './CandidateAssessmentFormContent';

const ViewCandidateAssessmentHeader = ({
  assessment,
  candidacyId,
  isFromActiveAdmin,
  isTalentPool,
  onViewTypeChange,
  search,
  viewType,
}) => (
  <FullPageTakeoverHeader
    actionButtons={
      assessment?.raw_notes ? (
        <Menu
          button={
            <ButtonPrimary
              className='u-noWrap u-marginRight-16'
              isOutline={true}
              label='Toggle View'
              size='small'
            />
          }
          isPinnedRight={true}
        >
          <Menu.Item
            isDisabled={viewType === ViewTypes.ASSESSMENT_ONLY}
            onClick={() => onViewTypeChange(ViewTypes.ASSESSMENT_ONLY)}
          >
            {ViewTypes.ASSESSMENT_ONLY}
          </Menu.Item>
          <Menu.Item
            isDisabled={viewType === ViewTypes.SPLIT_SCREEN}
            onClick={() => onViewTypeChange(ViewTypes.SPLIT_SCREEN)}
          >
            {ViewTypes.SPLIT_SCREEN}
          </Menu.Item>
          <Menu.Item
            isDisabled={viewType === ViewTypes.RAW_NOTES_ONLY}
            onClick={() => onViewTypeChange(ViewTypes.RAW_NOTES_ONLY)}
          >
            {ViewTypes.RAW_NOTES_ONLY}
          </Menu.Item>
        </Menu>
      ) : null
    }
    onBackClick={() => {
      window.location.href = isFromActiveAdmin
        ? '/admin/candidacy_assessments'
        : `/${isTalentPool ? 'talent_pools' : 'searches'}/${
            search?.id
          }/candidates/${candidacyId}/candidacy_assessments`;
    }}
    subTitle={search?.name || 'Loading...'}
    title='View Assessment'
  />
);

ViewCandidateAssessmentHeader.propTypes = {
  assessment: PropTypes.shape({ raw_notes: PropTypes.string }),
  candidacyId: PropTypes.number,
  isFromActiveAdmin: PropTypes.bool,
  isTalentPool: PropTypes.bool,
  onViewTypeChange: PropTypes.func,
  search: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  viewType: PropTypes.string,
};

export default ViewCandidateAssessmentHeader;

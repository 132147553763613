import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Icon from '@thrivetrm/ui/components/Icon';
import Rating from '@thrivetrm/ui/components/Rating';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import useToggle from '@thrivetrm/ui/hooks/useToggle';

const AssessmentCriteriaStarRatingDefinitions = ({
  definitions,
  rating,
  ratingInput,
}) => {
  const [
    areDefsVisible,
    _showDefs,
    _hideDefs,
    toggleDefsVisibility,
  ] = useToggle(false);

  const showStarRatingDefinitionsButton = (
    <>
      Star Rating Definitions
      <Icon
        className='u-marginLeft-8'
        color='blue'
        size='small'
        type={areDefsVisible ? 'chevronUp' : 'chevronDown'}
      />
    </>
  );
  return (
    <>
      <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween'>
        {ratingInput || (
          <Rating maxRating={definitions.length} value={rating} />
        )}
        {definitions.length ? (
          <ButtonLink
            className='u-marginLeft-8'
            label={showStarRatingDefinitionsButton}
            onClick={toggleDefsVisibility}
          />
        ) : null}
      </div>
      {areDefsVisible ? (
        <>
          <hr className='u-marginTop-8 u-marginBottom-16 u-border-n u-borderBottom' />
          <h4 className='u-margin-n u-marginBottom-4 u-fontWeight-bold'>
            Star Rating Definitions
          </h4>
          {definitions.map(definition => (
            <div className='u-flex' key={uniqueId()}>
              <Rating
                className='u-marginRight-8'
                maxRating={definitions.length}
                value={definition.rating_value}
              />
              <p className='u-textColor-gray50 u-margin-n'>
                {definition.rating_definition}
              </p>
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};
AssessmentCriteriaStarRatingDefinitions.propTypes = {
  definitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      rating_definition: PropTypes.string,
      rating_value: PropTypes.number,
    }),
  ),
  rating: PropTypes.number,
  ratingInput: PropTypes.node,
};

export default AssessmentCriteriaStarRatingDefinitions;

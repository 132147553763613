import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import jobListingSchema from '../schema';

/**
 * Creates an action for fetching a job listing for a search
 * @param {Object} payload
 * @param {String} payload.searchId The ID of the search to fetch the job listing for.
 * @return {Object} The action
 */
export default compose(
  fetchAction,
  normalizeResponse({ job_listing: jobListingSchema }),
  ({ failureAction, searchId, successAction, ...rest }) => ({
    failureAction: ({ error, ...payload }) => {
      // A 404 isn't actually a failure in the normal sense -- it just means that the search
      // in question does not yet have a job listing associated with it, which is expected in many
      // cases..
      if (error.status === 404) {
        return successAction({
          ...payload,
          response: { job_listing: { search_id: searchId } },
        });
      }

      return failureAction({ error: error, payload: payload });
    },
    successAction: successAction,
    searchId: searchId,
    ...rest,
  }),
  createAsyncActions({
    startActionType: entityActionTypes.FETCH_START,
    successActionType: entityActionTypes.FETCH_SUCCESS,
    failureActionType: entityActionTypes.FETCH_FAILURE,
  }),
  ({ searchId, ...rest }) => ({
    entityType: jobListingSchema.key,
    id: searchId,
    url: routes.api_v1_search_job_listings({ searchId: searchId }),
    ...rest,
  }),
);

import React from 'react';
import PropTypes from 'prop-types';

const SearchTitle = ({
  client_company: clientCompany,
  code_name: codeName,
  confidential,
  job_title: jobTitle,
  name,
  tab,
  url,
}) => {
  return (
    <h6 className='notification-search-title'>
      <a href={tab ? url + tab : url}>
        {confidential ? codeName : jobTitle || name}
        {!confidential && clientCompany && (
          <span>
            {' at '}
            {clientCompany.name}
          </span>
        )}
      </a>
    </h6>
  );
};

SearchTitle.propTypes = {
  client_company: PropTypes.shape({
    name: PropTypes.string,
  }),
  code_name: PropTypes.string,
  confidential: PropTypes.bool,
  job_title: PropTypes.string,
  name: PropTypes.string,
  tab: PropTypes.string,
  url: PropTypes.string,
};

export default SearchTitle;

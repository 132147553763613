/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import PropTypes from 'prop-types';
import React from 'react';
import TooltipTrigger from './TooltipTrigger';

/**
 * This component will conditionally render a tooltip around another component.
 * If there is no tooltip it will just render the children.
 * If the component needs to be nested within a span, pass in a prop of 'nested' as true
 * For instance, disabled buttons and other elements must be nested in a span
 * https://stackoverflow.com/questions/13311574/how-to-enable-bootstrap-tooltip-on-disabled-button
 */

const ConditionalTooltipTrigger = ({ children, nested, tooltip, ...props }) => {
  if (tooltip) {
    return nested ? (
      <TooltipTrigger tooltip={tooltip} {...props}>
        <span className='ConditionalTooltipTrigger__disabledWrapper'>
          {children}
        </span>
      </TooltipTrigger>
    ) : (
      <TooltipTrigger tooltip={tooltip} {...props}>
        {children}
      </TooltipTrigger>
    );
  }
  return children;
};

ConditionalTooltipTrigger.defaultProps = {
  nested: true,
  tooltip: null,
};

ConditionalTooltipTrigger.propTypes = {
  /**
   * The button that should be rendered
   */
  children: PropTypes.node.isRequired,

  // Is the underlying button disabled?
  // This prop should be removed, every instance sets this to true and it is
  // prop doesn't conform to standards
  // eslint-disable-next-line react/boolean-prop-naming
  nested: PropTypes.bool,

  // Text of the tooltip to display
  tooltip: PropTypes.node,
};

export default ConditionalTooltipTrigger;

import { createSelector } from '@reduxjs/toolkit';

/**
 * Gets the current date and time.
 * This is used to determine which "date group" a particular interview
 * falls in (those with a `date` value less than this value are "past", otherwise
 * they are "current")
 * @return {String} The current date and time as an ISO 8601 formatted string.
 */
const getCurrentDate = () => new Date().toISOString();

/**
 * Memoize this on state. This means we only recalculate the current date when provided when
 * the state changes.
 */
export default createSelector(getCurrentDate, dateString => dateString);

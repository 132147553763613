import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/**
 * A single item in the CandidacyRankingListMenu
 */
class CandidacyRankingListItem extends PureComponent {
  handleClick = () => {
    const { onClick, value } = this.props;
    onClick(value);
  };

  render() {
    const { disabled, name, value } = this.props;
    return (
      <li className='CandidacyRankingListItem'>
        <button
          className='CandidacyRankingListItem__Button'
          disabled={disabled}
          onClick={this.handleClick}
          type='button'
          value={value}
        >
          {name}
        </button>
      </li>
    );
  }
}

CandidacyRankingListItem.propTypes = {
  /**
   * True to disable selecting this rank option
   */
  disabled: PropTypes.bool,

  name: PropTypes.string.isRequired,

  /**
   * Called when the item is clicked.
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The value of the button
   */
  value: PropTypes.string.isRequired,
};

CandidacyRankingListItem.defaultProps = {
  disabled: false,
};

export default CandidacyRankingListItem;

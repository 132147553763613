import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'modules/core/componentsLegacy/Modal';
import CancelButton from 'modules/forms/components/CancelButton';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import getCandidacyContactId from 'modules/candidacies/selectors/getCandidacyContactId';
import { useSelector } from 'react-redux';
import CandidacyTagTable from './CandidacyTagTable';
import CandidacyName from '../CandidacyName';

/**
 * A modal for managing the tags assigned to a candidacy
 */
const CandidacyTagModal = ({ candidacyId, onHide, show }) => {
  const contactId = useSelector(state =>
    getCandidacyContactId(state, candidacyId),
  );
  return (
    <Modal className='CandidacyTagModal' onHide={onHide} show={show}>
      <Modal.Header closeButton={true}>
        <Modal.Title>People Tags</Modal.Title>
        <CandidacyName candidacyId={candidacyId} />
      </Modal.Header>
      <Modal.Body>
        <CandidacyTagTable
          candidacyId={candidacyId}
          contactId={contactId}
          filterByCreatorName={false}
          filterPlaceholder='Filter by Tag Label'
          searchType={TYPE_PIPELINE}
        />
      </Modal.Body>
      <Modal.Footer>
        <CancelButton bsSize='lg' onClick={onHide}>
          Done
        </CancelButton>
      </Modal.Footer>
    </Modal>
  );
};

CandidacyTagModal.propTypes = {
  /**
   * The ID of the candidacy whose tags are to be managed.
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * Called when the user clicks the "done" button or otherwise wants to close the modal
   */
  onHide: PropTypes.func.isRequired,

  /**
   * True if the modal should be displayed, otherwise false.
   */
  show: PropTypes.bool.isRequired,
};

export default CandidacyTagModal;

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import CommaDelimitedList from 'modules/core/componentsLegacy/CommaDelimitedList';
import ContactName from './ContactName';

/**
 * Renders the names for a collection of contacts.
 * Comma's are added by the associated CSS file.
 */
const ContactNameList = ({ className, contactIds, ...props }) =>
  contactIds && contactIds.count() ? (
    <CommaDelimitedList className={classnames('ContactNameList', className)}>
      {contactIds &&
        contactIds.map(contactId => (
          <li className='ContactNameList__listitem' key={contactId}>
            <ContactName contactId={contactId} {...props} />
          </li>
        ))}
    </CommaDelimitedList>
  ) : null;

ContactNameList.propTypes = {
  /**
   * An optional class name to append to the list component.
   */
  className: PropTypes.string,

  /**
   * A contactId is required when we want to link to their profile.
   */
  contactIds: ImmutablePropTypes.listOf(PropTypes.number),
};

export default ContactNameList;

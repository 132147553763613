import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import Currency from '@thrivetrm/ui/components/Currency';
import PercentageRange from '@thrivetrm/ui/components/PercentageRange';
import mapContactIdToContact from '../mapContactIdToContact';

/**
 * Renders a contact's "Desired Compensation" attributes
 */
const ContactDesiredCompensationView = ({ contact }) => (
  <div className='ContactDesiredCompensationView row'>
    {contact.get('preferred_currency') && (
      <LabeledContent
        className='col-sm-12 ContactDesiredCompensationView__currency'
        label='Currency'
      >
        {contact.get('preferred_currency')}
      </LabeledContent>
    )}
    {(typeof contact.get('preferred_base_minimum') === 'number' ||
      typeof contact.get('preferred_base_maximum') === 'number') && (
      <LabeledContent
        className='col-sm-3 ContactDesiredCompensationView__salary_range'
        label='Base Range'
      >
        <Currency
          currency={contact.get('preferred_currency')}
          fallbackContent='No min'
          value={contact.get('preferred_base_minimum')}
        />
        <span> - </span>
        <Currency
          currency={contact.get('preferred_currency')}
          fallbackContent='No max'
          value={contact.get('preferred_base_maximum')}
        />
      </LabeledContent>
    )}
    {(typeof contact.get('preferred_bonus_minimum') === 'number' ||
      contact.get('preferred_bonus_maximum') === 'number') && (
      <LabeledContent
        className='col-sm-3 ContactDesiredCompensationView__bonus_range'
        label='Bonus Range'
      >
        <Currency
          currency={contact.get('preferred_currency')}
          fallbackContent='No min'
          value={contact.get('preferred_bonus_minimum')}
        />
        <span> - </span>
        <Currency
          currency={contact.get('preferred_currency')}
          fallbackContent='No max'
          value={contact.get('preferred_bonus_maximum')}
        />
      </LabeledContent>
    )}
    {(typeof contact.get('preferred_equity_minimum') === 'number' ||
      typeof contact.get('preferred_equity_maximum') === 'number') && (
      <LabeledContent
        className='col-sm-3 ContactDesiredCompensationView__equity_range'
        label='Equity Range'
      >
        <div>
          <PercentageRange
            maxFractionDigits={2}
            maxValue={contact.get('preferred_equity_maximum')}
            minValue={contact.get('preferred_equity_minimum')}
          />
        </div>
      </LabeledContent>
    )}
    {typeof contact.get('preferred_total_compensation') === 'number' && (
      <LabeledContent
        className='col-sm-3 ContactDesiredCompensationView__total_compensation'
        label='Total Compensation'
      >
        <Currency
          currency={contact.get('preferred_currency')}
          value={contact.get('preferred_total_compensation')}
        />
      </LabeledContent>
    )}
    {contact.get('desired_compensation_notes') && (
      <LabeledContent
        className='col-sm-12 ContactDesiredCompensationView__notes u-marginBottom-n'
        label='Notes'
      >
        {contact.get('desired_compensation_notes')}
      </LabeledContent>
    )}
  </div>
);

ContactDesiredCompensationView.propTypes = {
  /**
   * THe contact record with the desired compenstaion field values to render.
   */
  contact: ImmutablePropTypes.mapContains({
    preferred_base_minimum: PropTypes.number,
    preferred_base_maximum: PropTypes.number,
    preferred_bonus_minimum: PropTypes.number,
    preferred_bonus_maximum: PropTypes.number,
    preferred_equity_minimum: PropTypes.number,
    preferred_equity_maximum: PropTypes.number,
    preferred_currency: PropTypes.string,
    desired_compensation_notes: PropTypes.string,
    preferred_total_compensation: PropTypes.number,
  }).isRequired,
};

/**
 * Determines if the component will render anything when given the contact specified.
 * @param {Object} options
 * @param {Map} contact The contact record.
 */
ContactDesiredCompensationView.willRender = ({ contact }) =>
  Boolean(
    contact &&
      (contact.get('preferred_base_minimum') ||
        contact.get('preferred_base_maximum') ||
        contact.get('preferred_currency') ||
        contact.get('preferred_equity_minimum') ||
        contact.get('preferred_equity_maximum') ||
        contact.get('preferred_bonus_minimum') ||
        contact.get('preferred_bonus_maximum') ||
        contact.get('desired_compensation_notes') ||
        typeof contact.get('preferred_total_compensation') === 'number'),
  );

export default compose(
  setDisplayName('ContactDesiredCompensationView(enhanced)'),
  setStatic('willRender', ContactDesiredCompensationView.willRender),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  branch(
    ({ contact }) =>
      !ContactDesiredCompensationView.willRender({ contact: contact }),
    renderNothing,
  ),
)(ContactDesiredCompensationView);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import RadioSelectField from 'modules/forms/components/RadioSelectField';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';

/**
 * The field for editing the us visa required for a contact.
 * This just wraps a RadioSelectField instance. The createFieldState instance uses a custom
 * converter to return the value as `{ us_visa_required: '[content]' }` instead of returning the
 * content directly, so that this can be used with `withContactForm`.
 */
export default compose(
  setDisplayName('ContactUsVisaRequiredField'),
  setStatic(
    'createFieldState',
    (name = 'us_visa_required', contact, validator, _, ...rest) =>
      RadioSelectField.createFieldState(
        name,
        contact && contact.get('us_visa_required'),
        validator,
        value => ({
          us_visa_required: value,
        }),
        ...rest,
      ),
  ),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
)(props => <RadioSelectField {...props} />);

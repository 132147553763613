import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import classNames from 'classnames';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';

const MAX_ACTIVITY_CONTENT_HEIGHT = 515;

const ExpandableActivityView = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState();
  const activityContentRef = useRef();

  useEffect(() => {
    setContentHeight(activityContentRef?.current?.clientHeight);
  }, [content]);

  return (
    <>
      <div ref={activityContentRef}>
        <HtmlText
          className={classNames(
            'u-marginTop-4 u-fontSize-small u-wordBreak Activity__htmlText',
            {
              'Activity__htmlText--isShortView': !isExpanded,
            },
          )}
          htmlString={content}
        />
      </div>
      {contentHeight === MAX_ACTIVITY_CONTENT_HEIGHT ? (
        <div className='u-flex u-flexJustify-c u-marginTop-8'>
          <ButtonSecondary
            className='Activity__viewDetailsButton'
            label={isExpanded ? 'View Less Details' : 'View Full Details'}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            size='small'
          />
        </div>
      ) : null}
    </>
  );
};

ExpandableActivityView.propTypes = {
  content: PropTypes.string,
};

export default ExpandableActivityView;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose, setDisplayName, setStatic } from 'recompose';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import moment from 'moment';

import DateInputField from 'modules/datetime/components/DateInputField';
import TimeInputField from 'modules/datetime/components/TimeInputField';
import AttachmentsField from 'modules/documents/components/AttachmentsField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import SearchMultiSelectField from 'modules/searches/components/SearchMultiSelectField';
import UserSelectField from 'modules/users/components/UserSelectField';
import { FILTER_CRM_USER_AND_EMPLOYEES } from 'modules/users/constants';

import isCrmUser from 'modules/auth/selectors/isCrmUser';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_JOB_SEARCH, TYPE_PIPELINE } from 'modules/searches/constants';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import canViewAssignedSearchesOnly from '../selectors/canViewAssignedSearchesOnly';
import ContactMethodsSelectField from './ContactMethodsSelectField';

import RichTextField from '../../../components/forms/richtext/RichTextField';

/**
 * @constant {String} The message displayed when no contact method is selected.
 */
const CONTACT_METHOD_ID_REQUIRED = 'A contact method is required';

/**
 * Identifies the wrapper classes to apply to each field for different "layouts", so that we
 * can arrange fields differently as needed.
 */
const FIELD_LAYOUTS = {
  wide: {
    contactMethod: 'col-md-3 col-6',
    searches: 'col-md-5 col-6',
    date: 'col-md-2 col-6',
    time: 'col-md-2 col-6',
    subject: 'col-md-8 col-6',
    attributedTo: 'col-md-4 col-6',
    notes: 'col-12',
  },
  narrow: {
    contactMethod: 'col-12',
    searches: 'col-12',
    date: 'col-6',
    time: 'col-6',
    subject: 'col-12',
    attributedTo: 'col-12',
    notes: 'col-12',
  },
};

/**
 * A field that can be used for editing an Outreach record, but doesn't render a rich text
 * field for the notes. Should be used with a form component that renders it.
 */
class OutreachField extends PureComponent {
  static createFieldState(name = 'outreach', outreach) {
    const values = fromJS({
      attributed_to: '',
      contact_method: 1,
      notes: '',
      subject: '',
      searches: [],
      documents: [],
    }).merge(outreach);

    const contactedOnMoment = values.get('contacted_on')
      ? moment(values.get('contacted_on'), moment.ISO_8601)
      : moment();

    return FieldState.createNested(
      name,
      [
        RichTextField.createFieldState('notes', values.get('notes')),
        SearchMultiSelectField.createFieldState(
          'searches',
          values.get('searches'),
        ),
        ContactMethodsSelectField.createFieldState(
          'contact_method',
          values.get('contact_method'),
          state => (state.getValue() < 1 ? CONTACT_METHOD_ID_REQUIRED : null),
        ),
        UserSelectField.createFieldState(
          'attributed_to_id',
          values.get('attributed_to_id'),
        ),
        InputField.createFieldState('subject', values.get('subject')),
        DateInputField.createFieldState('date', contactedOnMoment),
        TimeInputField.createFieldState('time', contactedOnMoment.format('LT')),
        AttachmentsField.createFieldState('documents', values.get('documents')),
      ],
      null,
      ({ date, time, ...other }) => {
        // Merges the `date` and `time` fields into `noted_on`
        const timeMoment = moment(time, 'LT');
        const dateMoment = moment(date, moment.ISO_8601);
        dateMoment.set({
          hour: timeMoment.get('hour'),
          minute: timeMoment.get('minute'),
          second: timeMoment.get('second'),
        });

        return {
          ...other,
          contacted_on: dateMoment.format(),
        };
      },
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      allowRelatedSearchesEdit,
      draft,
      excludeTimeFields,
      fieldLayout,
      fieldState,
      onChange: _onChange,
      onDraftChange,
      searchType,
      ...otherProps
    } = this.props;

    const fieldClasses = FIELD_LAYOUTS[fieldLayout];
    const isPipeline = searchType === TYPE_PIPELINE;

    return (
      <AttachmentsField
        {...otherProps}
        className='AttachmentsField'
        fieldState={fieldState.getNestedField('documents')}
        label='Outreach Attachments'
        onChange={this.handleFieldChange}
      >
        <div className='outreach-edit outreach-item'>
          <div className='row'>
            <div className={fieldClasses.contactMethod}>
              <ContactMethodsSelectField
                {...otherProps}
                fieldState={fieldState.getNestedField('contact_method')}
                label='*Contact Method'
                onChange={this.handleFieldChange}
                placeholder='* Contact Method'
              />
            </div>
            <div className={fieldClasses.searches}>
              {allowRelatedSearchesEdit ? (
                <SearchMultiSelectField
                  {...otherProps}
                  fieldState={fieldState.getNestedField('searches')}
                  label={isPipeline ? 'Related Pipelines' : 'Related Searches'}
                  noValueText={
                    isPipeline
                      ? 'No Pipelines Selected'
                      : 'No Searches Selected'
                  }
                  onChange={this.handleFieldChange}
                  placeholder={
                    isPipeline ? 'Related to a Pipeline' : 'Related to a Search'
                  }
                  searchType={isPipeline ? TYPE_PIPELINE : TYPE_JOB_SEARCH}
                  summaryLabel={
                    isPipeline
                      ? '{0} Pipelines Selected'
                      : '{0} Searches Selected'
                  }
                />
              ) : null}
            </div>
            {!excludeTimeFields && (
              <div className={fieldClasses.date}>
                <DateInputField
                  fieldState={fieldState.getNestedField('date')}
                  label='*Date'
                  onChange={this.handleFieldChange}
                />
              </div>
            )}
            {!excludeTimeFields && (
              <div className={fieldClasses.time}>
                <TimeInputField
                  fieldState={fieldState.getNestedField('time')}
                  label='*Time'
                  onChange={this.handleFieldChange}
                />
              </div>
            )}
          </div>
          <div className='row'>
            <div className={fieldClasses.subject}>
              <InputField
                {...otherProps}
                fieldState={fieldState.getNestedField('subject')}
                key='subject'
                label='Subject'
                onChange={this.handleFieldChange}
                placeholder='Add a Subject'
              />
            </div>
            <div className={fieldClasses.attributedTo}>
              <UserSelectField
                fieldState={fieldState.getNestedField('attributed_to_id')}
                filter={FILTER_CRM_USER_AND_EMPLOYEES}
                label='On Behalf Of'
                onChange={this.handleFieldChange}
              />
            </div>
          </div>
          <div className='row'>
            <div className={fieldClasses.notes}>
              <RichTextField
                {...otherProps}
                fieldState={fieldState.getNestedField('notes')}
                label='Notes'
                lastUpdatedTime={draft?.lastUpdatedTime}
                onChange={this.handleFieldChange}
                onDraftChange={onDraftChange}
                placeholder='Enter notes...'
              />
            </div>
          </div>
        </div>
      </AttachmentsField>
    );
  }
}

export const propTypes = {
  /**
   * Whether to display and allow the "Related Searches" field to be edited.
   */
  allowRelatedSearchesEdit: PropTypes.bool,
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),
  /**
   * When true, the date and time field are not rendered.
   */
  excludeTimeFields: PropTypes.bool,

  /**
   * How to layout the form fields (which col classes to apply)
   */
  fieldLayout: PropTypes.oneOf(Object.keys(FIELD_LAYOUTS)),

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  onDraftChange: PropTypes.func,
  searchType: PropTypes.string,
};

export const defaultProps = {
  allowRelatedSearchesEdit: false,
  draft: null,
  excludeTimeFields: false,
  fieldLayout: 'narrow',
};

OutreachField.propTypes = propTypes;
OutreachField.defaultProps = defaultProps;

export default compose(
  setDisplayName('OutreachField(enhanced)'),
  setStatic('createFieldState', OutreachField.createFieldState),
  connect(
    (state, { searchId }) => ({
      allowRelatedSearchesEdit:
        !canViewAssignedSearchesOnly(state) && !isCrmUser(state),
      searchType: getSearchType(state, searchId),
    }),
    {},
  ),
  withPropsRemoved('searchId'),
)(OutreachField);

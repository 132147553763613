import { defaultTo } from 'lodash';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import companyPrioritySchema from '../schemas/companyPriorities';

/**
 * Gets a number indicating the order that a company priority should be sorted in.
 * @param {*} state
 * @param {Number} id The company priority ID
 * @return {Number} The order of the priority record for sorting.
 */
export default (state, id) =>
  defaultTo(
    getEntityProperty(state, companyPrioritySchema.key, id, 'order'),
    Number.MAX_SAFE_INTEGER,
  );

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import CompanyName from 'modules/companies/components/CompanyName';
import ContactName from 'modules/contacts/components/ContactName';
import ContactRelationshipManagerLink from 'modules/contacts/components/ContactRelationshipManagerLink';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import DateTime from 'modules/datetime/components/DateTime';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';

/**
 * Renders a contact's "connections" attributes
 */
const ContactConnectionsView = ({ contact, hasRelationshipManagerEnabled }) => (
  <div className='ContactConnectionsView row'>
    <div className='col-6'>
      <div className='row'>
        {contact.get('referred_by') && (
          <LabeledContent
            className='col-12 ContactConnectionsView__referred_by'
            label='Referred By Contact'
          >
            <ContactName
              contactId={contact.get('referred_by')}
              linkToContactPage={true}
            />
          </LabeledContent>
        )}
        {contact.get('referred_by_firm') && (
          <LabeledContent
            className='col-12 ContactConnectionsView__referred_by_firm'
            label='Referred By Company'
          >
            <CompanyName
              companyId={contact.get('referred_by_firm')}
              linkToCompany={true}
            />
          </LabeledContent>
        )}
        {contact.get('referred_date') && (
          <LabeledContent
            className='col-12 ContactConnectionsView__referred_date'
            label='Referred On'
          >
            <DateTime value={contact.get('referred_date')} />
          </LabeledContent>
        )}
        {contact.get('referred_by_notes') && (
          <LabeledContent
            className='col-12 ContactConnectionsView__referred_by_notes'
            label='Referred By Notes'
          >
            <ExpandableText
              characterLimit={300}
              content={contact.get('referred_by_notes')}
            />
          </LabeledContent>
        )}
      </div>
    </div>
    <div className='col-6'>
      <div className='row'>
        {contact.get('reports_to') && (
          <LabeledContent
            className='col-12 ContactConnectionsView__reports_to'
            label='Reports To'
          >
            <ContactName
              contactId={contact.get('reports_to')}
              linkToContactPage={true}
            />
          </LabeledContent>
        )}
        {hasRelationshipManagerEnabled && contact.get('relationship_manager') && (
          <LabeledContent
            className='col-12 ContactConnectionsView__relationship_manager'
            label='Relationship Manager'
          >
            <ContactRelationshipManagerLink contactId={contact.get('id')} />
          </LabeledContent>
        )}
      </div>
    </div>
  </div>
);

ContactConnectionsView.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    contact_source: PropTypes.number,
    referred_by_firm: PropTypes.number,
    referred_by_notes: PropTypes.string,
    referred_by: PropTypes.number,
    referred_date: PropTypes.string,
    relationship_manager: PropTypes.number,
    reports_to: PropTypes.number,
  }).isRequired,

  hasRelationshipManagerEnabled: PropTypes.bool,
};

/**
 * Determines if the component will render anything when given the contact specified.
 * @param {Object} options
 * @param {Map} contact The contact record.
 */
ContactConnectionsView.willRender = ({ contact }) =>
  Boolean(
    contact &&
      (contact.get('contact_source') ||
        contact.get('referral_source') ||
        contact.get('referred_by_firm') ||
        contact.get('referred_by_notes') ||
        contact.get('referred_by') ||
        contact.get('referred_date') ||
        contact.get('relationship_manager') ||
        contact.get('reports_to')),
  );

export default compose(
  setDisplayName('ContactConnectionsView(enhanced)'),
  setStatic('willRender', ContactConnectionsView.willRender),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  branch(
    ({ contact }) => !ContactConnectionsView.willRender({ contact: contact }),
    renderNothing,
  ),
  withFeatureCheck(
    'field.contact.relationship_manager',
    'hasRelationshipManagerEnabled',
  ),
)(ContactConnectionsView);

import routes from 'modules/routing/routes';
import { compose } from 'recompose';
import { PATCH } from 'modules/api/methods';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import defaultMethod from 'modules/api/actionModifiers/defaultMethod';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import withOptions from 'modules/api/actionModifiers/withOptions';
import employeeCountSchema from '../schema';
import {
  EMPLOYEE_COUNT_LIST_UPDATE_START,
  EMPLOYEE_COUNT_LIST_UPDATE_SUCCESS,
  EMPLOYEE_COUNT_LIST_UPDATE_FAILURE,
} from './actionTypes';

/**
 * The number of employees a company has for a specific year
 * @typedef {Object} EmployeeCountRecord
 * @property {Number} id id of record
 * @property {Number} amount number of employees at company
 * @property {Number} year year of record
 * @property {Boolean} _destroy if record will be destroyed
 */

/**
 * Creates an action for updating/creating a list of employee stats for a company
 * @param {Object} payload
 * @param {Number} payload.companyId
 * @param {EmployeeCountRecord[]} payload.employeeCounts The employee count records to update
 * on the server.
 * @param {String} [payload.transactionId] An optional transaction ID to track the state
 * of the request.
 */
export default compose(
  fetchAction,
  normalizeResponse({ employee_counts: [employeeCountSchema] }),
  createAsyncActions({
    startActionType: EMPLOYEE_COUNT_LIST_UPDATE_START,
    successActionType: EMPLOYEE_COUNT_LIST_UPDATE_SUCCESS,
    failureActionType: EMPLOYEE_COUNT_LIST_UPDATE_FAILURE,
  }),
  withOptions(({ companyId, employeeCounts }) => ({
    body: {
      employee_counts: {
        ...employeeCounts,
      },
    },
    url: routes.api_v1_company_employee_counts({ companyId: companyId }),
  })),
  defaultMethod(PATCH),
);

import getNetworksForContact from './getNetworksForContact';

/**
 * Gets a value indicating whether we should fetch the documents list for a particular
 * owner type and ID.
 * @param {Object} state
 * @param {String} ownerType One of the owner type constants (@see OWNER_TYPES)
 * @param {Number} ownerId The owner unique ID value.
 * @return {Boolean} True if the documents should be requested from the server; otherwise, false.
 */
export default (state, contactId) => {
  if (!contactId) {
    return false;
  }

  const networks = getNetworksForContact(state, contactId);

  if (!networks) {
    return true;
  }

  if (networks.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (networks.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !networks.has('ids') || Boolean(networks.getIn(['_meta', 'isInvalidated']))
  );
};

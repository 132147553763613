import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import jobApplicationSchema from '../schema';

/**
 * Gets a value indicating whether a job application was rejected.
 * @param {Object} state
 * @param {Number} id The job application ID
 * @return {Boolean} True if the job application was rejected; otherwise, false.
 */
export default (state, jobApplicationId) =>
  Boolean(
    getEntityProperty(
      state,
      jobApplicationSchema.key,
      jobApplicationId,
      'rejection_reason',
    ),
  );

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classnames from 'classnames';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import {
  NOTIFICATIONS_FILTERABLE_TYPES,
  NOTIFICATIONS_FILTER_SECTIONS,
  NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_SEARCH_ACTIVITY,
  NOTIFICATIONS_STATE__ALL_SELECTED,
  NOTIFICATIONS_STATE__NONE_SELECTED,
  NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_LEGACY_ASSESSMENTS,
  NOTIFICATIONS_FILTER_SECTIONS_FOR_COMPANY_ACTIVITY,
} from '../../domains/notifications/constants';

const SELECT_ALL = 'ALL';

const hasFilter = key => NOTIFICATIONS_FILTERABLE_TYPES[key].filter;

const NotificationsFilterDropdown = ({
  className,
  excludeSearchRelatedValues,
  hasAssessmentTemplates,
  isCompanyActivity,
  onChange,
  placeholder,
  value,
}) => {
  const componentId = uniqueId();
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = (_value, _element, action) => {
    if (action.source === 'select') {
      // This was triggered by a menu item click, which we don't want closing the dropdown,
      // so ignore it
      return;
    }

    setIsOpen(!isOpen);
  };

  const isAllSelected = () => {
    return Object.keys(value)
      ?.map(key => value[key])
      .every(filterValue => filterValue);
  };

  const handleMenuItemSelect = key => {
    if (key === SELECT_ALL) {
      onChange(
        isAllSelected()
          ? NOTIFICATIONS_STATE__NONE_SELECTED.toJS()
          : NOTIFICATIONS_STATE__ALL_SELECTED.toJS(),
      );
      return;
    }

    onChange({ ...value, [key]: !value[key] });
  };

  const handleCheckboxClicked = event => {
    // There's some weird behaviour with checkboxes inside the menuitems in that if you simply
    // ignore the checkbox events -- then clicking directly on the checkbox itself can lead to
    // the checked state can get out of sync. So to rectify we:
    // 1. Set the checkbox's to readOnly
    // 2. Respond to the onClick event directly, but prevent it from bubbling up to the
    //    MenuItem by using `stopPropogation` -- then we can "manually" trigger the MenuItem's
    //    onSelect handler with the key (which we've set as the input's value property)
    event.stopPropagation();
    handleMenuItemSelect(event.target.value);
  };

  const getNotificationsFilterSections = () => {
    if (isCompanyActivity) {
      return NOTIFICATIONS_FILTER_SECTIONS_FOR_COMPANY_ACTIVITY;
    } else if (excludeSearchRelatedValues) {
      return NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_SEARCH_ACTIVITY;
    } else if (hasAssessmentTemplates) {
      return NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_LEGACY_ASSESSMENTS;
    } else {
      return NOTIFICATIONS_FILTER_SECTIONS;
    }
  };

  const notificationFilters = getNotificationsFilterSections();

  return (
    <Dropdown
      className={classnames('notifications-filter-dropdown', className)}
      id={componentId}
      onToggle={handleDropdownToggle}
      open={isOpen}
    >
      <Dropdown.Toggle>{placeholder}</Dropdown.Toggle>
      <Dropdown.Menu>
        <MenuItem
          eventKey={SELECT_ALL}
          key={SELECT_ALL}
          onSelect={handleMenuItemSelect}
        >
          <div className='checkbox'>
            <label htmlFor={`${componentId}--${SELECT_ALL}`}>
              <input
                checked={isAllSelected()}
                id={`${componentId}--${SELECT_ALL}`}
                onClick={handleCheckboxClicked}
                readOnly={true}
                type='checkbox'
                value={SELECT_ALL}
              />
              Select All
            </label>
          </div>
        </MenuItem>
        <MenuItem divider={true} key={`${SELECT_ALL}--divider`} />
        {notificationFilters.map(({ section, types }) => [
          <MenuItem header={true} key={section}>
            {section}
          </MenuItem>,
          types.map(key => {
            const disabled = !hasFilter(key);
            return (
              <MenuItem
                disabled={disabled}
                eventKey={key}
                key={`${section}--${key}`}
                onSelect={handleMenuItemSelect}
              >
                <div className={classnames('checkbox', { disabled: disabled })}>
                  <label htmlFor={`${componentId}--${key}`}>
                    <input
                      checked={value[key]}
                      disabled={disabled}
                      id={`${componentId}--${key}`}
                      onClick={handleCheckboxClicked}
                      readOnly={true}
                      type='checkbox'
                      value={key}
                    />
                    {NOTIFICATIONS_FILTERABLE_TYPES[key].name}
                  </label>
                </div>
              </MenuItem>
            );
          }),
        ])}
      </Dropdown.Menu>
    </Dropdown>
  );
};

NotificationsFilterDropdown.defaultProps = {
  placeholder: (
    <>
      <i className='fa fa-filter dropdown-icon' /> Filter Activity Timeline
    </>
  ),
};

NotificationsFilterDropdown.propTypes = {
  className: PropTypes.string,
  excludeSearchRelatedValues: PropTypes.bool,
  hasAssessmentTemplates: PropTypes.bool,
  isCompanyActivity: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.node,
  value: PropTypes.objectOf(PropTypes.bool),
};

export default NotificationsFilterDropdown;

import deleteEntity from 'modules/entities/actions/deleteEntity';
import routes from 'modules/routing/routes';
import addressSchema from '../addressSchema';

/**
 * Creates an action for deleting an address
 * @param {Object} payload
 * @param {Number} payload.id The address ID.
 */
export default ({ id, ...rest }) =>
  deleteEntity({
    ...rest,
    id: id,
    entityType: addressSchema.key,
    url: routes.api_v1_address({ id: id }),
  });

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { toClass } from 'recompose';

/**
 * The primarily button that is displayed as the main "input" component of the multiselect.
 */
const MultiSelectButton = ({
  buttonRef,
  isOpen,
  placeholder,
  renderOptionLabel,
  summaryLabel,
  summaryThreshold,
  value,
  ...buttonProps
}) => {
  let label = <span className='multi-select-placeholder'>{placeholder}</span>;

  if (value.length) {
    // When values are selected, display either the labels of the values, or
    // the summaryLabel if the number of items selected is greater than the specified threshold.
    label =
      value.length >= summaryThreshold
        ? summaryLabel.replace('{0}', value.length)
        : value.map(option => renderOptionLabel(option)).join(', ');
  }

  // Note: The inner div content wrapper is needed to ensure that things get positioned correctly,
  // particularly with firefox

  return (
    <button
      className='multi-select-button form-control'
      ref={buttonRef}
      type='button'
      {...buttonProps}
    >
      <div className='multi-select-button-content'>
        <span className='multi-select-button-text'>{label}</span>
        <i
          className={classnames('multi-select-button-caret', 'fa', {
            'fa-caret-down': !isOpen,
            'fa-caret-up': isOpen,
          })}
        />
      </div>
    </button>
  );
};

MultiSelectButton.propTypes = {
  /**
   * Sets a ref to the underlying button element directly.
   */
  buttonRef: PropTypes.func,

  /**
   * Whether the attached dropdown is currently opened
   */
  isOpen: PropTypes.bool,

  /**
   * The text to display when there is no values currently selected.
   */
  placeholder: PropTypes.string,

  /**
   * Renders the label for a single option. The function is given the option/value itself,
   * and should return a renderable node (string, Node, number, etc) that will be displayed for that
   * option
   */
  renderOptionLabel: PropTypes.func,

  /**
   * The label to display when the number of values selected exceeds the `summaryThreshold` value.
   * The token `{0}` will be replaced with the number of selected elements.
   * Example: `{0} selected` would result in something like "3 selected" being displayed.
   */
  summaryLabel: PropTypes.string,

  /**
   * The threshold at which the button will start displaying the summaryLabel instead of the
   * label of the values selected.
   */
  summaryThreshold: PropTypes.number,

  /**
   * The currently selected options. This must always be an array, and should match the shape
   * of the options.
   */
  value: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

// `toClass` is needed because the MultiSelect that is rendering this needs to get a `ref` to it,
// which isn't available for functional components.
export default toClass(MultiSelectButton);

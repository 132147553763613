import { connect } from 'react-redux';
import getEntity from '../selectors/getEntity';

/**
 * A higher order component which maps an entity ID prop value to it's entity record.
 * @param {String} entityType The entity type identifier (schema key)
 * @param {Object} options
 * @param {String} options.idProp The name of the (incoming) prop that provides the ID
 * @param {String} options.entityProp The name of the (outgoing) prop that provides the entity
 * @return {Function} A higher-order compoment for mapping the idProp to the entityProp
 */
export default (entityType, { entityProp, idProp }) =>
  connect(
    (state, { [idProp]: id }) => ({
      [entityProp]: getEntity(state, entityType, id),
    }),
    {},
  );

const CANCELED_STATUS = 'Canceled';
const CONVERTED_STATUS = 'Converted';
const HOLD_STATUS = 'Hold';
const SUBMITTED_STATUS = 'Submitted';

const SEARCH_REQUEST_STATUSES = [
  CANCELED_STATUS,
  CONVERTED_STATUS,
  HOLD_STATUS,
  SUBMITTED_STATUS,
];

const SEARCH_REQUEST_STATUS_CHANGE_MAP = {};
SEARCH_REQUEST_STATUS_CHANGE_MAP[CANCELED_STATUS] = [
  CANCELED_STATUS,
  SUBMITTED_STATUS,
];
SEARCH_REQUEST_STATUS_CHANGE_MAP[CONVERTED_STATUS] = [CONVERTED_STATUS];
SEARCH_REQUEST_STATUS_CHANGE_MAP[HOLD_STATUS] = [
  CANCELED_STATUS,
  HOLD_STATUS,
  SUBMITTED_STATUS,
];
SEARCH_REQUEST_STATUS_CHANGE_MAP[SUBMITTED_STATUS] = [
  CANCELED_STATUS,
  HOLD_STATUS,
  SUBMITTED_STATUS,
];
export { SEARCH_REQUEST_STATUS_CHANGE_MAP };

const TEAM_LEAD_ROLES = ['Lead', 'Partner'];
const EMPLOYEE_ROLES = ['Employee', 'Lead', 'Partner', 'Recruiter'];
const LEAD_TEAM_MEMBER_TYPE = 'Lead';
const ADDITIONAL_TEAM_MEMBER_TYPE = 'Additional Team Members';

const FILTER_TYPE = {
  DATE_RANGE: 'dateRange',
  TEXT: 'text',
};

const FILTER_DEFAULT_VALUE = {
  [FILTER_TYPE.DATE_RANGE]: {},
  [FILTER_TYPE.TEXT]: [],
};

const FILTERS_CONFIG = {
  city: {
    canUseCustomOptions: true,
    label: 'City',
    type: FILTER_TYPE.TEXT,
  },
  company_name: {
    canUseCustomOptions: true,
    label: 'Company',
    type: FILTER_TYPE.TEXT,
  },
  country: {
    canUseCustomOptions: true,
    label: 'Country',
    tenantOptionsKey: 'countries',
    type: FILTER_TYPE.TEXT,
  },
  submission_date: {
    label: 'Date Submitted',
    type: FILTER_TYPE.DATE_RANGE,
  },
  hiring_manager_name: {
    canUseCustomOptions: true,
    label: 'Hiring Manager',
    type: FILTER_TYPE.TEXT,
  },
  job_title: {
    canUseCustomOptions: true,
    label: 'Job Title',
    type: FILTER_TYPE.TEXT,
  },
  level: {
    canUseCustomOptions: false,
    label: 'Level',
    tenantOptionsKey: 'levels',
    type: FILTER_TYPE.TEXT,
  },
  product_area: {
    canUseCustomOptions: false,
    label: 'Product Area',
    tenantOptionsKey: 'product_areas',
    type: FILTER_TYPE.TEXT,
  },
  search_region: {
    canUseCustomOptions: false,
    label: 'Region',
    tenantOptionsKey: 'search_regions',
    type: FILTER_TYPE.TEXT,
  },
  stage: {
    canUseCustomOptions: false,
    label: 'Stage',
    tenantOptionsKey: 'search_request_stages',
    type: FILTER_TYPE.TEXT,
  },
  state: {
    canUseCustomOptions: true,
    label: 'State/Province',
    type: FILTER_TYPE.TEXT,
  },
  status: {
    canUseCustomOptions: false,
    label: 'Status',
    options: [
      { id: CANCELED_STATUS, name: CANCELED_STATUS },
      { id: CONVERTED_STATUS, name: CONVERTED_STATUS },
      { id: HOLD_STATUS, name: HOLD_STATUS },
      { id: SUBMITTED_STATUS, name: SUBMITTED_STATUS },
    ],
    type: FILTER_TYPE.TEXT,
  },
};

export {
  ADDITIONAL_TEAM_MEMBER_TYPE,
  CANCELED_STATUS,
  CONVERTED_STATUS,
  EMPLOYEE_ROLES,
  FILTERS_CONFIG,
  FILTER_DEFAULT_VALUE,
  FILTER_TYPE,
  HOLD_STATUS,
  LEAD_TEAM_MEMBER_TYPE,
  SEARCH_REQUEST_STATUSES,
  SUBMITTED_STATUS,
  TEAM_LEAD_ROLES,
};

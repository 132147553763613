import { connect } from 'react-redux';
import getRatingDefinition from '../../selectors/ratings/getRatingDefinition';

/**
 * Maps a `ratingDefinitionsList` prop to `ratingDefinitions` -- an array of the
 * underlying rating definition entities in the list.
 */
export default connect(
  (state, { ratingDefinitionsList }) => ({
    ratingDefinitions:
      ratingDefinitionsList &&
      ratingDefinitionsList.has('ids') &&
      ratingDefinitionsList
        .get('ids')
        .map(id => getRatingDefinition(state, id)),
  }),
  {},
);

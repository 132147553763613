import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import Card from '@thrivetrm/ui/components/Card';

import InlineEditActions from '../../../components/forms/InlineEditActions';
import { IntroductionView } from './IntroductionView';
import connectIntroductionActions from './connectIntroductionActions';
import mapIntroductionIdToIntroduction from './mapIntroductionIdToIntroduction';
import IntroductionForm from './IntroductionForm';

/**
 * Renders single introduction list item.
 */
class IntroductionListItem extends Component {
  state = {
    /**
     * True when the item is being edited inline, false when being viewed.
     * @type {Boolean}
     */
    isEditing: false,
  };

  /**
   * Called when the 'Yes' button of the delete confirmation is clicked.
   * Calls the action to delete the introduction and resets the action.
   */
  handleDeleteClick = () => {
    const { introduction, introductionActions } = this.props;
    this.setState({
      isEditing: false,
    });

    introductionActions.deleteIntroduction({
      introduction: introduction.toJS(),
    });
  };

  /**
   * Called when the edit link is clicked, shows the edit form in place of
   * the introduction view.
   */
  handleEditClick = () => {
    this.setState({
      isEditing: true,
    });
  };

  /**
   * Called when the edit form is closed, removes the edit form and shows
   * the introduction view instead.
   */
  handleFormClose = () => {
    this.setState({
      isEditing: false,
    });
  };

  render() {
    const {
      candidacyId,
      introduction,
      introductionId,
      linkToCandidacy,
      povContactId,
      readOnly,
      searchId,
    } = this.props;
    const { isEditing } = this.state;
    const isDeleting = introduction.getIn(['_meta', 'isDeleting']);
    const canDelete = introduction.getIn(['permissions', 'delete']);
    const canEdit = introduction.getIn(['permissions', 'edit']);

    return (
      <li className='IntroductionListItem'>
        <Card isCentered={false}>
          {isEditing ? (
            <IntroductionForm
              alwaysShowFieldErrors={true}
              draftStoragePath={{
                search: searchId,
                candidacy: candidacyId,
                introduction: introductionId,
              }}
              errorDisplay='tooltip'
              excludeContactField={true}
              introduction={introduction}
              introductionId={introductionId}
              onCancel={this.handleFormClose}
              onSaved={this.handleFormClose}
              povContactId={povContactId}
              title='Edit Introduction'
            />
          ) : (
            <IntroductionView
              canDelete={canDelete}
              canEdit={canEdit}
              introduction={introduction}
              linkToCandidacy={linkToCandidacy}
              povContactId={povContactId}
            />
          )}
          <InlineEditActions
            canDelete={!isEditing && !readOnly && canDelete}
            canEdit={!isEditing && !readOnly && canEdit}
            deleteConfirmation='Delete this introduction?'
            disabled={isDeleting}
            onDelete={this.handleDeleteClick}
            onEdit={this.handleEditClick}
          />
        </Card>
      </li>
    );
  }
}

IntroductionListItem.propTypes = {
  candidacyId: PropTypes.number,
  /**
   * The introduction to render.
   */
  introduction: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isDeleting: PropTypes.bool,
    }),
    permissions: ImmutablePropTypes.mapContains({
      delete: PropTypes.bool.isRequired,
      edit: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,

  /**
   * Search actions for updating and deleting introductions (only
   * required when `readOnly` is false).
   */
  introductionActions: requiredIf(
    PropTypes.shape({
      deleteIntroduction: PropTypes.func.isRequired,
    }),
    props => !props.readOnly,
  ),

  introductionId: PropTypes.number.isRequired,

  /**
   * Wraps the introduction in a link to its associated candidacy.
   */
  linkToCandidacy: PropTypes.bool,

  /**
   * The ID of the contact whose context this item is being displayed in.
   * @see IntroductionView.propTypes.povContactId
   */
  povContactId: PropTypes.number,

  /**
   * True to not allow editing of the introduction.
   */
  readOnly: PropTypes.bool,

  searchId: PropTypes.number,
};

IntroductionListItem.defaultProps = {
  readOnly: false,
};

export default compose(
  setDisplayName('IntroductionListItem(enhanced)'),
  setPropTypes({
    introductionId: PropTypes.number.isRequired,
  }),
  mapIntroductionIdToIntroduction,
  connectIntroductionActions,
)(IntroductionListItem);

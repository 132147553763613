import autocomplete from '../lib/autocomplete';
import autocompleteSplitter from '../lib/autocomplete_splitter';

// When nested forms get added to the DOM
const once = () => {
  $(document).on('nested:fieldAdded', event =>
    event.field.find('input[data-autocomplete-path]').each((_, element) => {
      autocomplete($(element));
    }),
  );
};

const always = () => {
  $('input[data-autocomplete-path]').each((_i, element) => {
    autocomplete($(element));
  });

  $(document).on('change', 'input[data-autocomplete-path]', event => {
    const $element = $(event.target);
    if ($element.val() === '') {
      $($element.data('autocomplete-target')).val('');
    }
  });

  $('input[data-autocomplete-splitter-path]').each((_i, element) => {
    autocompleteSplitter($(element));
  });

  $(document).on('change', 'input[data-autocomplete-splitter-path]', event => {
    const $element = $(event.target);
    if ($element.val() === '') {
      $($element.data('autocomplete-target')).val('');
    }
  });

  $('#search_client_company_name').autocomplete('setOptions', {
    noSuggestionNotice: $('.no-suggestion-notice'),
    onSearchComplete: function (_query, suggestions) {
      if (suggestions.length === 0) {
        const term = $('#search_client_company_name').val();
        $('.js-company-new-button').fadeIn(200).find('strong').text(term);
      }
    },
  });
};

export default {
  once: once,
  always: always,
};

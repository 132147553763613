import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SearchRelatedUserName from 'modules/searches/components/SearchRelatedUserName';
import { USER_RELATION_LEAD_RECRUITER } from 'modules/searches/constants';
import getCandidacySearchId from 'modules/candidacies/selectors/getCandidacySearchId';

/**
 * A candidacy table cell that renders the search lead of the search that a candidacy record
 * is associated with.
 */

const CandidacySearchLeadNameCell = ({ candidacyId }) => {
  const searchId = useSelector(state =>
    getCandidacySearchId(state, candidacyId),
  );

  return (
    <SearchRelatedUserName
      relatedType={USER_RELATION_LEAD_RECRUITER}
      searchId={searchId}
    />
  );
};

CandidacySearchLeadNameCell.propTypes = {
  /**
   * The ID of the candidacy record
   */
  candidacyId: PropTypes.number,
};

export default CandidacySearchLeadNameCell;

import getName from 'modules/contacts/selectors/contacts/getName';
import { toSentence } from '@thrivetrm/ui/utilities/arrayUtils';
import getInterviewers from './getInterviewers';

/**
 * Returns a human friendly list of interviewer names from an interview id
 * to use in a sentence.
 *
 * Note: this does not include the candidate's name.
 *
 * @return {string}  ex. "Jane Smith, Jen Brown, and Peter Parker"
 */
const getInterviewerNames = (state, id) => {
  const interviewerIds = getInterviewers(state, id);
  const interviewerNames =
    interviewerIds &&
    interviewerIds.map(contactId => getName(state, contactId));
  return toSentence(interviewerNames.toJS());
};

export default getInterviewerNames;

import React from 'react';
import EmailTemplateName from '../EmailTemplateName';
import EmailTemplateCreatedBy from '../EmailTemplateCreatedBy';
import EmailTemplateDateCreated from '../EmailTemplateDateCreated';
import EmailTemplateDateModified from '../EmailTemplateDateModified';
import EmailTemplatesTableEditDeleteCell from './EmailTemplatesTableEditDeleteCell';

import {
  COLUMN_NAME,
  COLUMN_CREATED_BY,
  COLUMN_DATE_CREATED,
  COLUMN_DATE_MODIFIED,
  COLUMN_EDIT_DELETE_BUTTONS,
} from '../../constants';

/**
 * Defines all of the available columns that can be used by the EmailTemplates table.
 * Note that each `key` should be unique -- otherwise if two columns that have the same
 * key are used it will cause an error because it used to set the `key` on the react
 * component.
 */

export default {
  [COLUMN_NAME]: {
    className: 'email-template-name',
    key: COLUMN_NAME,
    title: 'Name',
    isSortable: false,
    Cell: (
      { datum: emailTemplateId }, // eslint-disable-line react/prop-types
    ) => <EmailTemplateName emailTemplateId={emailTemplateId} />,
  },

  [COLUMN_CREATED_BY]: {
    key: COLUMN_CREATED_BY,
    title: 'Created By',
    isSortable: false,
    Cell: (
      { datum: emailTemplateId }, // eslint-disable-line react/prop-types
    ) => <EmailTemplateCreatedBy emailTemplateId={emailTemplateId} />,
  },

  [COLUMN_DATE_CREATED]: {
    key: COLUMN_DATE_CREATED,
    title: 'Date Created',
    isSortable: false,
    Cell: (
      { datum: emailTemplateId }, // eslint-disable-line react/prop-types
    ) => <EmailTemplateDateCreated emailTemplateId={emailTemplateId} />,
  },

  [COLUMN_DATE_MODIFIED]: {
    key: COLUMN_DATE_MODIFIED,
    title: 'Date Modified',
    isSortable: false,
    Cell: (
      { datum: emailTemplateId }, // eslint-disable-line react/prop-types
    ) => <EmailTemplateDateModified emailTemplateId={emailTemplateId} />,
  },
  [COLUMN_EDIT_DELETE_BUTTONS]: {
    key: COLUMN_EDIT_DELETE_BUTTONS,
    isSortable: false,
    Cell: EmailTemplatesTableEditDeleteCell,
  },
};

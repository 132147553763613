import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

/**
 * Renders a single option for the multi-select dropdown.
 */
class Option extends PureComponent {
  constructor(props) {
    super(props);

    // We need a unique ID for our <label /> element.
    this.componentId = uniqueId();
  }

  setComponentRef = domElement => {
    // This is needed by the `MultiSelectDropDown` so that it can scroll a particular
    // option into view when it is focused.
    this.domElement = domElement;
  };

  /**
   * Called when the option is hovered, passing along the props so that the option can be
   * identified in the `OptionList`
   */
  handleMouseOver = event => {
    const { onMouseOver } = this.props;

    if (onMouseOver) {
      onMouseOver(event, this.props);
    }
  };

  render() {
    const { isFocused, isSelected, label, onChange, value } = this.props;

    return (
      <div
        className={classnames('multi-select-option', 'checkbox', {
          'is-focused': isFocused,
        })}
        onFocus={this.handleMouseOver}
        onMouseOver={this.handleMouseOver}
        ref={this.setComponentRef}
      >
        <label htmlFor={this.componentId}>
          <input
            checked={isSelected}
            className='multi-select-options-checkbox'
            id={this.componentId}
            onChange={onChange}
            tabIndex={-1}
            type='checkbox'
            value={value}
          />{' '}
          {label}
        </label>
      </div>
    );
  }
}

Option.propTypes = {
  /**
   * Whether this option is currently focused.
   */
  isFocused: PropTypes.bool,

  /**
   * Whether this option is currently selected (should be checked)
   * @type {Boolean}
   */
  isSelected: PropTypes.bool,

  /**
   * The label to display for this option.
   */
  label: PropTypes.node,

  /**
   * Called when the selected value for this option should be changed, with the onChange
   * `SyntheticEvent` as a parameter.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Called when the mouse moves over the option, with the onMouseOver `SyntheticEvent`
   * as the first parameter, and the props that were passed to this option as the second
   * parameter.
   */
  onMouseOver: PropTypes.func,

  /**
   * The value (ID) for this option.
   */
  value: PropTypes.string.isRequired,
};

export default Option;

import getEntity from 'modules/entities/selectors/getEntity';
import { outreachSchema } from '../schema';

/**
 * Gets an outreach by it's ID
 * @param {Object} state
 * @param {Number} id The outreach ID
 * @return {Immutable.Map|undefined} The outreach record, if found.
 */
export default (state, id) => getEntity(state, outreachSchema.key, id);

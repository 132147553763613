import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import jobApplicationSchema from '../schema';

/**
 * Gets the email address from a job application record.
 * @param {Object} state
 * @param {Number} id The job application ID
 * @return {?String} The email address.
 */
export default (state, jobApplicationId) =>
  getEntityProperty(state, jobApplicationSchema.key, jobApplicationId, 'email');

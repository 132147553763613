import getTalentPoolId from 'modules/contacts/selectors/contacts/getTalentPoolId';
import getCandidacyCount from '../selectors/getCandidacyCount';

export default state => contactId => {
  const talentPoolId = getTalentPoolId(state, contactId);

  if (talentPoolId) {
    return getCandidacyCount(state, talentPoolId) || 0;
  }

  return -1;
};

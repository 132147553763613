import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import ActivityListItem from './ActivityListItem';
import withActivityIdsSortedByMostRecent from './withActivityIdsSortedByMostRecent';

/**
 * Renders a list of activities.
 */
const ActivityList = ({ activityIds }) => (
  <ul className='ActivityList list-generic'>
    {activityIds &&
      activityIds
        .map(id => <ActivityListItem activityId={id} key={id} />)
        .toArray()}
  </ul>
);

ActivityList.propTypes = {
  /**
   * The list of activity IDs to render.
   */
  activityIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
};

/**
 * Renders a list of activities, sorted by most-recent first.
 */
export default compose(
  setDisplayName('ActivityList(enhanced)'),
  setPropTypes({
    /**
     * The list of activity IDs to render
     */
    activityIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),

    /**
     * If provided, limits the number of activities rendered to this amount.
     */
    limit: PropTypes.number,
  }),

  withActivityIdsSortedByMostRecent,

  // If `limit` was given, only pass along that many activityIds
  withProps(({ activityIds, limit }) => ({
    activityIds: limit && activityIds ? activityIds.take(limit) : activityIds,
  })),
)(ActivityList);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { fromJS } from 'immutable';
import { compose, setDisplayName, setStatic } from 'recompose';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';

import ContactNamesField from './ContactNamesField';
import ContactNameDuplicates from './ContactNameDuplicates';
import ContactEmailDuplicates from './ContactEmailDuplicates';

export const LAYOUT_GRID = 'LAYOUT_GRID';
export const LAYOUT_STACK = 'LAYOUT_STACK';
export const DEFAULT_VALUES = fromJS({ preferred_name: '' });

export const createFieldState = (name = 'contact', contact) => {
  const values = DEFAULT_VALUES.merge(contact);

  return FieldState.createNested(
    name,
    [
      ContactNamesField.createFieldState('names', contact),
      InputField.createFieldState(
        'preferred_name',
        values.get('preferred_name'),
      ),
      InputField.createFieldState(
        'email',
        values.get('email'),
        validators.emailAddress,
      ),
      InputField.createFieldState(
        'work_email',
        values.get('work_email'),
        validators.emailAddress,
      ),
      CheckboxField.createFieldState(
        'isWorkEmailPreferred',
        values.get('work_email_preferred') === true,
      ),
      CheckboxField.createFieldState(
        'isPersonalEmailPreferred',
        values.get('work_email_preferred') === false,
      ),
    ],
    null,
    ({ isPersonalEmailPreferred, isWorkEmailPreferred, names, ...rest }) => ({
      ...rest,
      ...names,

      // This will be used in the action creator to generate the proper information to send
      // to the server.
      work_email_preferred: isPersonalEmailPreferred
        ? false
        : isWorkEmailPreferred || null,
    }),
  );
};

class ContactField extends Component {
  state = {
    hasNameMatches: false,
    hasEmailMatches: false,
    hasWorkEmailMatches: false,
  };

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;

    const fieldName = childFieldState.getName();
    let nextFieldState = fieldState.setNestedField(childFieldState);

    const workEmailPreferred = fieldState.getNestedField(
      'isWorkEmailPreferred',
    );
    const personalEmailPreferred = fieldState.getNestedField(
      'isPersonalEmailPreferred',
    );

    switch (fieldName) {
      case 'isWorkEmailPreferred': {
        if (workEmailPreferred.getValue() === false) {
          // `isWorkEmailPreferred` is being toggled on, so make sure `isPersonalEmailPreferred`
          // is toggled off.
          nextFieldState = nextFieldState
            .setNestedField(workEmailPreferred.setValue(true))
            .setNestedField(personalEmailPreferred.setValue(false));
        }
        break;
      }
      case 'isPersonalEmailPreferred': {
        if (personalEmailPreferred.getValue() === false) {
          // `isPersonalEmailPreferred` is being toggled on, so make sure `isWorkEmailPreferred`
          // is toggled off.
          nextFieldState = nextFieldState
            .setNestedField(workEmailPreferred.setValue(false))
            .setNestedField(personalEmailPreferred.setValue(true));
        }
        break;
      }
      default: {
        // Nothing to do
        break;
      }
    }

    onChange(nextFieldState, childFieldState.getName());
  };

  handleNameDuplicatesMatch = matches => {
    this.setState({ hasNameMatches: Boolean(matches && matches.size) });
  };

  handleEmailDuplicatesMatch = matches => {
    this.setState({ hasEmailMatches: Boolean(matches && matches.size) });
  };

  handleWorkEmailDuplicatesMatch = matches => {
    this.setState({ hasWorkEmailMatches: Boolean(matches && matches.size) });
  };

  render() {
    const {
      componentId,
      excludeEmailFields,
      excludePreferredNameField,
      fieldState,
      layout,
      onDuplicateSelect,
    } = this.props;
    const { hasEmailMatches, hasNameMatches, hasWorkEmailMatches } = this.state;

    const columnClass = layout === LAYOUT_GRID ? 'col-6' : 'col-12';

    return (
      <div className='ContactField'>
        <ContactNameDuplicates
          canSelect={Boolean(onDuplicateSelect)}
          firstName={fieldState
            .getNestedField('names')
            .getNestedField('first_name')
            .getValue()}
          lastName={fieldState
            .getNestedField('names')
            .getNestedField('last_name')
            .getValue()}
          onMatch={this.handleNameDuplicatesMatch}
          onSelect={onDuplicateSelect}
        />
        <ContactEmailDuplicates
          canSelect={Boolean(onDuplicateSelect)}
          className='alert-danger'
          email={fieldState.getNestedField('email').getValue()}
          emailType='personal'
          key='duplicates-email'
          onMatch={this.handleEmailDuplicatesMatch}
          onSelect={onDuplicateSelect}
        />
        <ContactEmailDuplicates
          canSelect={Boolean(onDuplicateSelect)}
          email={fieldState.getNestedField('work_email').getValue()}
          emailType='work'
          key='duplicates-work_email'
          onMatch={this.handleWorkEmailDuplicatesMatch}
          onSelect={onDuplicateSelect}
        />

        <ContactNamesField
          fieldColumnClass={columnClass}
          fieldState={fieldState.getNestedField('names')}
          name='names'
          onChange={this.handleFieldChange}
          warning={hasNameMatches ? 'Possible duplicate contacts exist' : null}
        />

        {!excludePreferredNameField ? (
          <div className='row'>
            <div className={columnClass}>
              <InputField
                fieldState={fieldState.getNestedField('preferred_name')}
                label='Preferred Name'
                name='preferred_name'
                onChange={this.handleFieldChange}
              />
            </div>
          </div>
        ) : null}

        {!excludeEmailFields && (
          <div className='row'>
            <div className={columnClass}>
              <div className='formgroup-with-label-addon'>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      id={`isPersonalEmailPreferred-tooltip-${componentId}`}
                    >
                      <div>Preferred Email is shared in Searches</div>
                    </Tooltip>
                  }
                  placement='top'
                  trigger={['hover']}
                >
                  <div className='formgroup-label-addon'>
                    <CheckboxField
                      checked={fieldState
                        .getNestedField('isPersonalEmailPreferred')
                        .getValue()}
                      fieldState={fieldState.getNestedField(
                        'isPersonalEmailPreferred',
                      )}
                      key='isPersonalEmailPreferred'
                      onChange={this.handleFieldChange}
                      text='Preferred'
                    />
                  </div>
                </OverlayTrigger>
                <InputField
                  error={
                    hasEmailMatches
                      ? 'A contact exists with this email address already'
                      : null
                  }
                  fieldState={fieldState.getNestedField('email')}
                  label='Personal Email'
                  name='email'
                  onChange={this.handleFieldChange}
                />
              </div>
            </div>
            <div className={columnClass}>
              <div className='formgroup-with-label-addon'>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`isWorkEmailPreferred-tooltip-${componentId}`}>
                      <div>Preferred Email is shared in Searches</div>
                    </Tooltip>
                  }
                  placement='top'
                  trigger={['hover']}
                >
                  <div className='formgroup-label-addon'>
                    <CheckboxField
                      checked={fieldState
                        .getNestedField('isWorkEmailPreferred')
                        .getValue()}
                      fieldState={fieldState.getNestedField(
                        'isWorkEmailPreferred',
                      )}
                      key='isWorkEmailPreferred'
                      onChange={this.handleFieldChange}
                      text='Preferred'
                    />
                  </div>
                </OverlayTrigger>
                <InputField
                  fieldState={fieldState.getNestedField('work_email')}
                  label='Work email'
                  name='work_email'
                  onChange={this.handleFieldChange}
                  warning={
                    hasWorkEmailMatches
                      ? 'Possible duplicate contacts exist with this email address'
                      : null
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ContactField.propTypes = {
  /**
   * A unique ID for the instance of the component. Used to generate IDs for tooltips.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * When true the `email` and `work_email` fields will not be rendered or editable.
   */
  excludeEmailFields: PropTypes.bool,

  /**
   * When true the `preferred_name` field will not be rendered or editable.
   */
  excludePreferredNameField: PropTypes.bool,

  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  layout: PropTypes.oneOf([LAYOUT_GRID, LAYOUT_STACK]),

  onChange: PropTypes.func.isRequired,

  /**
   * When provided and duplicate contacts are found, the duplicate contact will display a
   * "use this contact" link. When that is click, this callback is called with the contact ID
   * of the contact that was selected.
   */
  onDuplicateSelect: PropTypes.func,
};

ContactField.defaultProps = {
  excludeEmailFields: false,
  excludePreferredNameField: false,
  layout: LAYOUT_GRID,
};

export default compose(
  setDisplayName('ContactField(enhanced)'),
  setStatic('createFieldState', createFieldState),
  withComponentId(),
)(ContactField);

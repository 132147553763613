import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import { fromJS } from 'immutable';
import CurrencyTypeSelectField from 'modules/currency/components/CurrencyTypeSelectField';
import CurrencyInputField from 'modules/currency/components/CurrencyInputField';
import YearSelectField from 'modules/datetime/components/YearSelectField';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import FieldState from 'modules/forms/FieldState';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import * as validators from 'modules/forms/validators';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import { DEFAULT_CURRENCY } from 'modules/user/constants';

/**
 * A field for editing a single company revenue record.
 */
const CompanyRevenueField = ({
  fieldState,
  handleNestedFieldChange,

  ...otherProps
}) => (
  <div className='CompanyRevenueField'>
    <div className='row'>
      <div className='col-sm-3'>
        <YearSelectField
          {...otherProps}
          fieldState={fieldState.getNestedField('year')}
          label='Year'
          name='year'
          onChange={handleNestedFieldChange}
        />
      </div>
      <div className='col-sm-3'>
        <CurrencyTypeSelectField
          {...otherProps}
          className='CurrencySelectField'
          clearable={true}
          fieldState={fieldState.getNestedField('currency')}
          label='Currency'
          name='currency'
          onChange={handleNestedFieldChange}
        />
      </div>
      <div className='col-sm-6'>
        <CurrencyInputField
          {...otherProps}
          currency={fieldState.getNestedField('currency').getValue()}
          fieldState={fieldState.getNestedField('amount')}
          label='Amount'
          name='amount'
          onChange={handleNestedFieldChange}
        />
      </div>
    </div>
  </div>
);

CompanyRevenueField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,
};

/**
 * Creates a FieldState for the CompanyRevenueField.
 * @param {String} name The name of the field
 * @param {Object} [revenue] The revenue values to initialize the FieldState with
 * @param {Number|String} [revenue.amount=''] The amount of revenue
 * @param {String} [revenue.currency=''] The currency the amount of revenue is specified in
 * @param {Number} [revenue.year=null] The year this revenue value applies to
 * @param {Boolean} [revenue._destroy=false] Whether this record should be marked for deletion
 *   when submitted.
 * @returns {FieldState} a FieldState that can be passed to the `CompanyRevenueField` component
 */
CompanyRevenueField.createFieldState = (name = 'revenue', revenue, ...rest) => {
  const values = fromJS({
    amount: '',
    currency: '',
    year: null,
    _destroy: false,
  }).merge(revenue);

  // revenue.year comes from the server as a string but
  // YearSelectField expects a number.
  const year = parseInt(values.get('year'));

  return FieldState.createNested(
    name,
    [
      FieldState.create('_destroy', values.get('_destroy')),
      FieldState.create('id', values.get('id')),
      CurrencyInputField.createFieldState('amount', values.get('amount'), {
        float: 0,
        required: 'Amount',
      }),
      CurrencyTypeSelectField.createFieldState(
        'currency',
        values.get('currency') || DEFAULT_CURRENCY,
        validators.requiredField('Currency'),
      ),
      YearSelectField.createFieldState('year', year),
    ],
    ...rest,
  );
};

export default compose(
  setDisplayName('CompanyRevenueField(enhanced)'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', CompanyRevenueField.createFieldState),
  withNestedFieldChangeHandler,
  withPropsRemoved('onChange'),
)(CompanyRevenueField);

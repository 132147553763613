import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { compose, setDisplayName } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PopoverTrigger from 'modules/core/componentsLegacy/PopoverTrigger';
import RelativeTimeLabel from 'modules/datetime/components/RelativeTimeLabel';
import ExternalLink from 'modules/links/ExternalLink';
import ContactSourceName from 'modules/tenant/components/ContactSourceName';
import contactSourceHasDetailsSelector from 'modules/tenant/selectors/contactSourceHasDetails';

import JobApplicationActionButton from '../JobApplicationActionButton';
import JobApplicationIconLinkList from '../JobApplicationIconLinkList';
import mapJobApplicationIdToJobApplication from '../mapJobApplicationIdToJobApplication';

export const JobApplicationListItem = ({
  contactSourceHasDetails,
  jobApplication,
  row,
  searchId,
}) => (
  <div
    className={classnames(
      'JobApplicationListItem',
      `JobApplicationListItem--row-${row.index}`,
      {
        'JobApplicationListItem--rejected': Boolean(
          jobApplication.get('rejection_reason'),
        ),
      },
    )}
    style={row.style}
  >
    <div className='JobApplicationListItem__Header'>
      <div className='JobApplicationListItem__ApplicantName'>
        {jobApplication.get('first_name')} {jobApplication.get('last_name')}
      </div>
      <div className='JobApplicationListItem__AppliedDate'>
        Applied <RelativeTimeLabel time={jobApplication.get('created_at')} />
      </div>
    </div>
    <div className='JobApplicationListItem__Content'>
      <JobApplicationIconLinkList jobApplication={jobApplication} />
      <div className='JobApplicationListItem__DocumentContainer'>
        <div className='JobApplicationListItem__Resume'>
          {jobApplication.getIn(['resume', 'url']) && (
            <ExternalLink href={jobApplication.getIn(['resume', 'url'])}>
              <i className='fa fa-file-text-o' /> View Resume
            </ExternalLink>
          )}
        </div>
        <div className='JobApplicationListItem__CoverLetter'>
          {jobApplication.getIn(['cover_letter', 'url']) && (
            <ExternalLink href={jobApplication.getIn(['cover_letter', 'url'])}>
              <i className='fa fa-file-text-o' /> View Cover Letter
            </ExternalLink>
          )}
        </div>
      </div>
      <div className='JobApplicationListItem__Action'>
        <div className='btn-group'>
          <JobApplicationActionButton
            jobApplicationId={jobApplication.get('id')}
            searchId={searchId}
          />
        </div>
      </div>
    </div>
    <div className='JobApplicationListItem__Footer'>
      <div className='JobApplicationListItem__Source'>
        <strong>Source: </strong>
        <span className='JobApplicationListItem__ContactSource'>
          {contactSourceHasDetails ? (
            jobApplication.get('source_details')
          ) : (
            <ContactSourceName id={jobApplication.get('contact_source')} />
          )}
        </span>
      </div>
      {jobApplication.get('rejection_reason') && (
        <div className='JobApplicationListItem__Rejection'>
          <strong>Rejected: </strong>
          {jobApplication.get('rejection_comment') ? (
            <PopoverTrigger
              content={jobApplication.get('rejection_comment')}
              placement='top'
              title='Rejection comments'
            >
              <span className='JobApplicationListItem__RejectionReason'>
                {jobApplication.get('rejection_reason')}{' '}
                <i className='fa fa-sticky-note' />
              </span>
            </PopoverTrigger>
          ) : (
            <span className='JobApplicationListItem__RejectionReason'>
              {jobApplication.get('rejection_reason')}
            </span>
          )}
        </div>
      )}
    </div>
  </div>
);

JobApplicationListItem.propTypes = {
  /**
   * True if the contact_source has the "details" attribute set to true.
   */
  contactSourceHasDetails: PropTypes.bool,

  jobApplication: ImmutablePropTypes.mapContains({
    contact_source: PropTypes.number,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    email: PropTypes.string,
    linked_in_url: PropTypes.string,
    resume: PropTypes.shape({
      url: PropTypes.string,
    }),
    rejection_reason: PropTypes.string,
  }).isRequired,

  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object.isRequired,
  }).isRequired,

  /**
   * The search ID is currently only needed here because the JobApplicationActionButton
   * needs it for some reason. TODO: remove this when it's no longer needed!
   */
  searchId: PropTypes.number.isRequired,
};

export default compose(
  setDisplayName('JobApplicationListItem(enhanced)'),
  mapJobApplicationIdToJobApplication,
  connect(
    (state, { jobApplication }) => ({
      contactSourceHasDetails: contactSourceHasDetailsSelector(
        state,
        jobApplication.get('contact_source'),
      ),
    }),
    {},
  ),
)(JobApplicationListItem);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import mapCompanyIdToCompany from 'modules/companies/components/mapCompanyIdToCompany';

/**
 * Renders company legacy data number_of_employees.
 */
export const CompanyEmployeeCountLegacyView = ({ company }) => (
  <div className='CompanyEmployeeLegacyView'>
    {company.get('number_of_employees') ? (
      <div>{company.get('number_of_employees')}</div>
    ) : null}
  </div>
);

CompanyEmployeeCountLegacyView.propTypes = {
  company: ImmutablePropTypes.mapContains({
    number_of_employees: PropTypes.string,
  }).isRequired,
};

export default compose(
  setDisplayName('CompanyEmployeeCountLegacyView(enhanced)'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
)(CompanyEmployeeCountLegacyView);

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import { useSelector } from 'react-redux';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { useGetCandidateTagsQuery } from 'services/apiV1/candidateTags';
import CandidacyTagColorSquareList from './CandidacyTagColorSquareList';
import CandidacyTagModalButton from './CandidacyTagModalButton';
import CandidacySmartTagList from './CandidacySmartTagList';

/**
 * A `CandidacyTagColorSquareList` component that includes a `CandidacyTagModalButton`
 * when `readOnly` is false.
 */
const CandidacyTagColorSquareListEditable = ({
  candidacyId,
  className,
  readOnly,
  searchId,
  tagButtonClassName,
  tagLimit,
}) => {
  const searchType = useSelector(state => getSearchType(state, searchId));

  const { data: candidateTags } = useGetCandidateTagsQuery(searchId);

  return (
    <div className={classnames('u-flex u-flexAlign-c', className)}>
      {readOnly ? null : (
        <CandidacyTagModalButton
          candidacyId={candidacyId}
          className={`btn btn-sm btn-link u-marginRight-4 u-padding-n ${tagButtonClassName}`}
          searchId={searchId}
          searchType={searchType}
        />
      )}
      {searchType === TYPE_PIPELINE ? (
        <CandidacySmartTagList
          candidacyId={candidacyId}
          pipelineId={searchId}
          searchType={searchType}
          tagLimit={tagLimit}
        />
      ) : (
        <CandidacyTagColorSquareList
          className='u-flex u-flexAlign-c'
          tagsList={candidateTags?.tags.filter(({ candidacyIds }) =>
            candidacyIds.includes(candidacyId),
          )}
        />
      )}
    </div>
  );
};

CandidacyTagColorSquareListEditable.propTypes = {
  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * An optional class name to apply to the container div
   */
  className: PropTypes.string,

  /**
   * True to not allow editing the tags (that is, don't render the edit button/link)
   */
  readOnly: PropTypes.bool,

  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,

  /**
   * className to apply to the button of candidacy tag modal
   */
  tagButtonClassName: PropTypes.string,

  tagLimit: PropTypes.number,
};

CandidacyTagColorSquareListEditable.defaultProps = {
  readOnly: false,
};

export default CandidacyTagColorSquareListEditable;

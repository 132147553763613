import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';

import Tooltip from '@thrivetrm/ui/components/Tooltip';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import ContactExternalIdLink from './ContactExternalIdLink';
import ContactIconLink from '../../../components/contact/ContactIconLink';
import ContactPopoverIconLink from '../../../components/contact/ContactPopoverIconLink';
import getContact from '../selectors/contacts/getContact';

/**
 * Renders the collection of contact link icons for a contact.
 * Will only render those links that have values provided.
 */
const ContactIconLinkList = ({
  className,
  contactData,
  contactId,
  hideWorkPhone,
  shouldShowContactSource,
  shouldShowEmail,
  shouldShowPhone,
  tooltipPosition,
}) => {
  const contactFromState = useSelector(state => getContact(state, contactId));

  const getContactDate = () => {
    if (contactData) {
      return contactData;
    }

    return contactFromState ? contactFromState.toJS() : {};
  };
  const contact = getContactDate(contactFromState);
  const hasAboveBoard = useFeatureCheck('feature.aboveboard');
  const ghireFlipperEnabled = useFeatureCheck(
    'field.contact.external_id_ghire',
    'ghireFlipperEnabled',
  );
  const isEmployee = useSelector(state => isEmployeeUser(state));

  const preferredEmail = contact?.preferred_email;
  const email = contact?.email;
  const workEmail = contact?.work_email;
  const phone = contact?.phone;
  const mobilePhone = contact?.mobile_phone;
  const workPhone = !hideWorkPhone && contact?.work_phone;
  const linkedInUrl = contact?.linkedin_url;
  const twitterHandle = contact?.twitter_handle;
  const skypeUsername = contact?.skype_handle;
  const githubUrl = contact?.github_url;
  const contactSource = shouldShowContactSource && contact?.contact_source;
  const otherUrl = contact?.other_url;
  const externalId = contact?.external_id;
  const aboveBoardUrl = contact?.aboveboard_url;
  const shouldRenderContactExternalIdLink = externalId && ghireFlipperEnabled;

  return (
    <ul className={classnames('ContactIconLinkList', className)}>
      {((preferredEmail && !isEmployee) ||
        ((email || workEmail) && isEmployee) ||
        (email && isEmployee)) &&
        shouldShowEmail && (
          <li>
            <ContactPopoverIconLink
              href={`mailto:${preferredEmail || email || workEmail}`}
              linkTitle={preferredEmail || email || workEmail}
              linkType='email'
              popoverTitle='Email'
            >
              <div className='list-unstyled'>
                {preferredEmail && (
                  <div>
                    <strong>Preferred Email:</strong>{' '}
                    <div className='u-wordBreak'>
                      <a
                        href={`mailto:${preferredEmail}`}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        {preferredEmail}
                      </a>
                    </div>
                  </div>
                )}
                {workEmail && workEmail !== preferredEmail && isEmployee && (
                  <div>
                    <strong>Work Email:</strong>{' '}
                    <div className='u-wordBreak'>
                      <a
                        href={`mailto:${workEmail}`}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        {workEmail}
                      </a>
                    </div>
                  </div>
                )}
                {email && email !== preferredEmail && isEmployee && (
                  <div>
                    <strong>Personal Email:</strong>{' '}
                    <div className='u-wordBreak'>
                      <a
                        href={`mailto:${email}`}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        {email}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </ContactPopoverIconLink>
          </li>
        )}

      {(((mobilePhone || phone) && !isEmployee) ||
        ((phone || mobilePhone || workPhone) && isEmployee)) &&
        shouldShowPhone && (
          <li>
            <ContactPopoverIconLink
              href={`tel:${phone || mobilePhone || workPhone}`}
              linkType='phone'
              popoverTitle='Phone Numbers'
            >
              <div className='list-unstyled'>
                {phone && (
                  <div>
                    <strong>Phone:</strong>{' '}
                    <div>
                      <a href={`tel:${phone}`}>{phone}</a>
                    </div>
                  </div>
                )}
                {workPhone && isEmployee && (
                  <div>
                    <strong>Work:</strong>{' '}
                    <div>
                      <a href={`tel:${workPhone}`}>{workPhone}</a>
                    </div>
                  </div>
                )}
                {mobilePhone && (
                  <div>
                    <strong>Mobile:</strong>{' '}
                    <div>
                      <a href={`tel:${mobilePhone}`}>{mobilePhone}</a>
                    </div>
                  </div>
                )}
              </div>
            </ContactPopoverIconLink>
          </li>
        )}
      {linkedInUrl && (
        <li>
          <ContactIconLink
            href={linkedInUrl}
            linkType='linkedin'
            rel='noopener noreferrer'
            target='_blank'
          />
        </li>
      )}
      {twitterHandle && (
        <li>
          <ContactIconLink
            href={`https://www.twitter.com/${twitterHandle}`}
            linkType='twitter'
            rel='noopener noreferrer'
            target='_blank'
          />
        </li>
      )}
      {skypeUsername && (
        <li>
          <ContactIconLink
            href={`skype:${skypeUsername}?call`}
            linkType='skype'
          />
        </li>
      )}
      {githubUrl && (
        <li>
          <ContactIconLink
            href={githubUrl}
            linkType='github'
            rel='noopener noreferrer'
            target='_blank'
          />
        </li>
      )}
      {contactSource && (
        <li>
          <ContactIconLink
            contactId={contactId}
            linkType='contactsource'
            tooltipPosition={tooltipPosition}
          />
        </li>
      )}
      {hasAboveBoard && aboveBoardUrl && (
        <li>
          <Tooltip title='AboveBoard URL'>
            <ContactIconLink
              href={aboveBoardUrl}
              linkType='aboveboard'
              rel='noopener noreferrer'
              target='_blank'
            />
          </Tooltip>
        </li>
      )}
      {otherUrl && (
        <li>
          <ContactIconLink
            href={otherUrl}
            linkType='other'
            rel='noopener noreferrer'
            target='_blank'
          />
        </li>
      )}

      {shouldRenderContactExternalIdLink && (
        <li>
          <ContactExternalIdLink externalId={externalId} icon='fa-google' />
        </li>
      )}
    </ul>
  );
};

ContactIconLinkList.propTypes = {
  className: PropTypes.string,
  contactData: PropTypes.shape({
    aboveboard_url: PropTypes.string,
    email: PropTypes.string,
    external_id: PropTypes.string,
    github_url: PropTypes.string,
    linkedin_url: PropTypes.string,
    mobile_phone: PropTypes.string,
    other_url: PropTypes.string,
    phone: PropTypes.string,
    preferred_email: PropTypes.string,
    skype_handle: PropTypes.string,
    twitter_handle: PropTypes.string,
    work_email: PropTypes.string,
    work_phone: PropTypes.string,
  }),
  contactId: PropTypes.number,

  /**
   * hide work phone number from displaying in phone icon popover when specified
   */
  hideWorkPhone: PropTypes.bool,

  /**
   * determines whether or not we show the contact source
   */
  shouldShowContactSource: PropTypes.bool,

  /**
   * determines whether or not we show the email icon
   */
  shouldShowEmail: PropTypes.bool,

  /**
   * determines whether or not we show the phone icon
   */
  shouldShowPhone: PropTypes.bool,
  tooltipPosition: PropTypes.string,
};

ContactIconLinkList.defaultProps = {
  className: null,
  contactData: null,
  contactId: null,
  hideWorkPhone: false,
  shouldShowContactSource: false,
  shouldShowEmail: true,
  shouldShowPhone: true,
};

export default ContactIconLinkList;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import moment from 'moment';
import momentPropType from '../propTypes/momentPropType';

/**
 * How often (in milliseconds) to update the label.
 */
// 30 seconds
const DEFAULT_UPDATE_INTERVAL = 30 * 1000;

/**
 * A PropType representing all possible values that can be provided to
 * indicate a time attribute.
 */
const TimePropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.object,
  PropTypes.array,
  PropTypes.instanceOf(Date),
  momentPropType,
]);

/**
 * Converts a given value to a moment based on it's type.
 * @param  {Mixed} value The value to convert
 * @return {moment} a moment representing the incoming value.
 */
function toMoment(value) {
  if (!value) {
    // If no value was given, use the current time.
    return moment();
  }

  if (moment.isMoment(value)) {
    // Already a moment, nothing to do.
    return value;
  }

  if (typeof value === 'string') {
    return moment(value, [moment.ISO_8601, 'x']);
  }

  return moment(value);
}

/**
 * A component for displaying a relative time ("5 minutes ago", "2 days ago", etc)
 */
class RelativeTimeLabel extends PureComponent {
  constructor(props) {
    super(props);

    const { affix, referenceTime, time } = props;

    this.updateLabel = this.updateLabel.bind(this);

    const timeMoment = time ? toMoment(time) : moment();
    const referenceMoment = referenceTime ? moment(referenceTime) : null;

    this.state = {
      timeMoment: timeMoment,
      referenceMoment: referenceMoment, // eslint-disable-line react/no-unused-state
      label: this.formatLabel(timeMoment, referenceMoment, affix),
    };
  }

  componentDidMount() {
    this.updateTimeout = setInterval(this.updateLabel, DEFAULT_UPDATE_INTERVAL);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    clearInterval(this.updateTimeout);

    const timeMoment = toMoment(nextProps.time);
    if (nextProps.adjustTime !== 0) {
      timeMoment.add(nextProps.adjustTime, 'milliseconds');
    }
    const referenceMoment =
      nextProps.referenceTime && moment(nextProps.referenceTime);

    this.setState({
      timeMoment: timeMoment,
      referenceMoment: referenceMoment, // eslint-disable-line react/no-unused-state
    });

    this.updateLabel();
    this.updateTimeout = setInterval(this.updateLabel, DEFAULT_UPDATE_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.updateTimeout);
  }

  updateLabel() {
    this.setState((prevState, props) => ({
      label: this.formatLabel(
        prevState.timeMoment,
        prevState.referenceMoment,
        props.affix,
      ),
    }));
  }

  formatLabel(timeMoment, referenceMoment, useAffix) {
    const { type } = this.props;

    if (referenceMoment) {
      switch (type) {
        case 'calendar': {
          return timeMoment.calendar(referenceMoment);
        }
        case 'to': {
          return timeMoment.to(referenceMoment, !useAffix);
        }
        case 'from':
        default: {
          return timeMoment.from(referenceMoment, !useAffix);
        }
      }
    }

    switch (type) {
      case 'calendar': {
        return timeMoment.calendar();
      }
      case 'to': {
        return timeMoment.toNow(!useAffix);
      }
      case 'from':
      default: {
        return timeMoment.fromNow(!useAffix);
      }
    }
  }

  render() {
    const {
      /* eslint-disable no-unused-vars */
      adjustTime,
      affix,
      interval,
      referenceTime,
      shouldAddTitleAttribute,
      time,
      title,
      titleFormat,
      type,
      /* eslint-enable no-unused-vars */
      ...otherProps
    } = this.props;
    const { label, timeMoment } = this.state;

    return (
      <time
        dateTime={timeMoment.format()}
        title={
          shouldAddTitleAttribute
            ? title || timeMoment.format(titleFormat)
            : null
        }
        {...otherProps}
      >
        {label}
      </time>
    );
  }
}

RelativeTimeLabel.propTypes = {
  /**
   * Sometimes, server clocks are not quite in sync with client clocks.
   * This ends up displaying humanized strings such as "in a few seconds"
   * rather than "a few seconds ago". To fix this we are adding time on
   * to our time moment so we gardentee the time displays in the
   * future.
   */
  adjustTime: PropTypes.number,

  /**
   * Whether to display the affix or not.
   * When true (default): "4 years ago"
   * When false: "4 years"
   * @type {Boolean=true}
   */
  affix: PropTypes.bool,

  /**
   * How often to update the label (in milliseconds)
   */
  interval: PropTypes.number,

  /**
   * The time to use as the reference time. If not provided,
   * the current time is used. The value, if provided, should be of the
   * types allowed by the `time` property.
   */
  referenceTime: TimePropType,

  /**
   * Whether to add title attribute to label
   */
  shouldAddTitleAttribute: PropTypes.bool,

  /**
   * The time to display. It should be one of:
   * - A moment object
   * - A Date object
   * - A unix timestamp (milliseconds since the epoch)
   * - A string in ISO_8601 format
   * - An object or array that can be parsed by moment (@see http://momentjs.com/docs/#/parsing/)
   */
  time: TimePropType,

  /**
   * A value to use for the `title` attribute.
   */
  title: PropTypes.string,

  /**
   * The format to use for rendering the 'title' attribute.
   * Only used if the `title` property is not provided.
   * (@see http://momentjs.com/docs/#/displaying/format/)
   */
  titleFormat: PropTypes.string,

  /**
   * The type of label to display.
   * "from" displays the time from `referenceTime` ("4 days ago")
   * "to" displays the time to `referenceTime` ("in 4 days")
   * "calendar" displays realtive day from referenceTime ("Last Monday at 2:30 AM")
   */
  type: PropTypes.oneOf(['to', 'from', 'calendar']),
};

RelativeTimeLabel.defaultProps = {
  adjustTime: 0,
  affix: true,
  interval: DEFAULT_UPDATE_INTERVAL,
  shouldAddTitleAttribute: true,
  titleFormat: 'llll',
  type: 'from',
};

export default RelativeTimeLabel;

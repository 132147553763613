/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import classnames from 'classnames';
import TableScrollContainer from '@thrivetrm/ui/components/TableScrollContainer';
import TableHead from './TableHead';
import TableBody from './TableBody';

/**
 * A generic table component for rendering a table given a set of column definitions and
 * data.
 */
export const Table = ({
  className,
  id,
  tableScrollContainerClass,

  // Additional props get passed into the Cell
  ...props
}) => (
  <TableScrollContainer className={tableScrollContainerClass}>
    <table className={classnames('table', 'Table', className)} id={id}>
      <TableHead {...props} />
      <TableBody {...props} />
    </table>
  </TableScrollContainer>
);

Table.defaultProps = {
  className: null,
  id: null,
  striped: true,
  tableScrollContainerClass: null,
};

Table.propTypes = {
  ...TableHead.propTypes,
  ...TableBody.propTypes,

  /**
   * Optional class name to apply to the table element.
   */
  className: PropTypes.string,

  /**
   * @deprecated I have no idea why this was added but there should really be no need for this.
   * Do not use it.
   */
  id: PropTypes.string,

  /**
   * True to render the table rows in alternating colors.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  striped: PropTypes.bool,

  tableScrollContainerClass: PropTypes.string,
};

export default compose(
  setDisplayName('Table(enhanced)'),
  setPropTypes({
    ...Table.propTypes,

    /**
     * Not needed, provided by this HOC.
     */
    onSelectionChange: PropTypes.any,
    selection: PropTypes.any,
  }),

  defaultProps(Table.defaultProps),

  withStateHandlers(
    { selection: [] },
    {
      onSelectionChange: () => selection => ({ selection: selection }),
    },
  ),

  lifecycle({
    /* eslint-disable-next-line func-names */
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      // When the data changes remove any selection.
      if (nextProps.data !== this.props.data) {
        nextProps.onSelectionChange([]);
      }
    },
  }),
)(Table);

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import fetchUserActionCreator from '../actions/fetchUser';
import shouldFetchUserSelector from '../selectors/shouldFetchUser';
import getUserSelector from '../selectors/getUser';
import isUserFetchedSelector from '../selectors/isUserFetched';

/**
 * Fetches a user record from the server if it needs to be fetched.
 * @param {Object} options
 * @param {String} [options.idPropName="userId"] The incoming propName that contains the ID of the
 *   user to fetch.
 * @param {String} [options.dataPropName="user"] The outgoing propName to use for supplying
 *   the user record value.
 * @param {?String} [options.isDataReadyPropName] The propName to use for supplying a boolean value
 *   that indicates whether the user has been fetched and is ready to be used or not.
 *   This is optional and if not given the underlying prop value will not be provided.
 */
export default ({
  dataPropName = 'user',
  idPropName = 'userId',
  isDataReadyPropName,
} = {}) =>
  compose(
    setDisplayName('withUserFetched'),

    setPropTypes({
      /**
       * The ID of the user is required.
       */
      [idPropName]: PropTypes.number.isRequired,
    }),

    mapProps(props => ({
      userId: props[idPropName],
      withUserFetchedProps: props,
    })),

    connect(
      (state, { userId }) => ({
        shouldFetchUser: shouldFetchUserSelector(state, userId),
        user: getUserSelector(state, userId),
        isUserFetched: isUserFetchedSelector(state),
      }),
      {
        fetchUser: fetchUserActionCreator,
      },
    ),

    withHandlers({
      fetchUserIfNeeded: ({ fetchUser, shouldFetchUser, userId }) => () =>
        shouldFetchUser && fetchUser({ id: userId }),
    }),

    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchUserIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchUserIfNeeded();
      },
    }),

    mapProps(
      ({ isUserFetched, shouldFetchUser, user, withUserFetchedProps }) => ({
        ...withUserFetchedProps,
        [dataPropName]: user,

        // Only add the `isDataReadyPropName` prop if that prop name was supplied.
        ...(isDataReadyPropName
          ? {
              // The data is ready only if the user has been fetched (it's in state) and `shouldFetchUser`
              // is false. This allows a component to, for example, disable a field or show a loader until
              // the user data is available -- and `shouldFetchUser` alone is insufficient since it will be
              // false while loading is in progress.
              [isDataReadyPropName]: !shouldFetchUser && isUserFetched,
            }
          : null),
      }),
    ),
  );

import getEntity from 'modules/entities/selectors/getEntity';
import investmentSchema from '../schema';

/**
 * Gets an investment entity from it's ID
 * @param {*} state
 * @param {Number} investmentId The ID of the investment entity.
 * @return {Map} The investment entity record, if in state.
 */
export default (state, investmentId) =>
  getEntity(state, investmentSchema.key, investmentId);

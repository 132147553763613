import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import fetchCandidacyStatsActionCreator from '../../actions/candidacyStats/fetchCandidacyStats';
import shouldFetchCandidacyStatsSelector from '../../selectors/shouldFetchCandidacyStats';

/**
 * Fetches the candidacy stats for a search if it needs to be fetched from the server,
 * given it's searchId.
 * Also provides `fetchCandidacyStatsIfNeeded` and `fetchCandidacyStats` handlers to fetch
 * stats on demand.
 * */
export default compose(
  setDisplayName('withCandidacyStatsFetched'),
  setPropTypes({
    searchId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { searchId }) => ({
      shouldFetchCandidacyStats: shouldFetchCandidacyStatsSelector(
        state,
        searchId,
      ),
    }),
    dispatch => ({
      fetchCandidacyStats: bindActionCreators(
        fetchCandidacyStatsActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchCandidacyStats: ({ fetchCandidacyStats, searchId }) => () =>
      fetchCandidacyStats({ searchId: searchId }),

    fetchCandidacyStatsIfNeeded: ({
      fetchCandidacyStats,
      searchId,
      shouldFetchCandidacyStats,
    }) => () => {
      if (shouldFetchCandidacyStats) {
        fetchCandidacyStats({ searchId: searchId });
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchCandidacyStatsIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchCandidacyStatsIfNeeded();
    },
  }),
);

import React from 'react';
import PropTypes from 'prop-types';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Rating from '@thrivetrm/ui/components/Rating';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import Card from '@thrivetrm/ui/components/Card';
import Icon from '@thrivetrm/ui/components/Icon';
import UtilityCopyButton from '@thrivetrm/ui/components/UtilityCopyButton';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { useSelector } from 'react-redux';
import useQuickView from '../useQuickView';

const ContactBaseballCard = ({
  canRemoveCard,
  className,
  contactDetails,
  onRemove,
  shouldShowAddress,
  shouldShowDocuments,
  shouldShowEmploymentDetails,
}) => {
  const { navigateTo } = useQuickView();
  const isFullAccessOrAdminUser = useSelector(state => isEmployeeUser(state));
  const isCrmUser = useSelector(state => isCrmUserSelector(state));
  const hasContactAliases = useFeatureCheck('feature.contact_aliases');

  const hasPersonalInfo = Boolean(
    contactDetails.email ||
      contactDetails.phone ||
      contactDetails.mobilePhone ||
      contactDetails.location,
  );
  const contactNumber = contactDetails.phone || contactDetails.mobilePhone;

  const showContactPersonalInformationPanel = () => {
    navigateTo(`/contacts/${contactDetails.id}/edit`, {
      title: contactDetails.fullName,
    });
  };

  const aliases = contactDetails?.aliases?.map(({ name }) => name).join(', ');

  return (
    <Card className={className} isCentered={false}>
      <div className='u-flex u-flexJustify-spaceBetween'>
        <ContactSnapshot
          avatarUrl={contactDetails.avatarUrl}
          connectionsCount={contactDetails.connectionsCount}
          id={contactDetails.id}
          isOffLimitsDisabled={true}
          name={contactDetails.fullName}
          offLimitsReason={
            shouldShowEmploymentDetails
              ? contactDetails.offLimits?.status
              : null
          }
          offLimitsSeverity={
            shouldShowEmploymentDetails
              ? contactDetails.offLimits?.category
              : null
          }
        />
        {isFullAccessOrAdminUser || isCrmUser ? (
          <ButtonSecondary
            icon='edit'
            onClick={showContactPersonalInformationPanel}
            size='small'
          />
        ) : null}
      </div>
      <div className='u-marginLeft-32 u-paddingLeft-24'>
        {contactDetails.primaryTitle ? (
          <p className='u-marginVertical-n'>{contactDetails.primaryTitle}</p>
        ) : null}
        {contactDetails.primaryCompanyName ? (
          <p className='u-marginVertical-n'>
            {contactDetails.primaryPosition?.company?.id ? (
              <a
                href={`/companies/${contactDetails.primaryPosition.company.id}`}
              >
                {contactDetails.primaryPosition?.company?.name}
              </a>
            ) : (
              contactDetails.primaryPosition?.companyName
            )}
          </p>
        ) : null}
        {shouldShowEmploymentDetails && contactDetails.ratingsCount ? (
          <Rating
            totalReviewers={contactDetails.ratingsCount}
            value={contactDetails.averageRating}
          />
        ) : null}
      </div>
      {hasPersonalInfo || aliases ? (
        <div className='u-borderTop u-paddingVertical-8 u-marginTop-8'>
          {hasContactAliases && aliases ? (
            <div className='u-wordBreak'>
              <b>AKA: </b>
              {aliases}
            </div>
          ) : null}
          {hasPersonalInfo ? (
            <>
              <h4 className='u-marginBottom-n u-marginTop-8'>
                Personal Contact Info
              </h4>
              {contactNumber ? (
                <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c u-marginTop-2'>
                  <span data-testid='contact-number'>
                    <a href={`tel:${contactNumber}`}>{contactNumber}</a>{' '}
                    {contactDetails.phone ? '(Phone)' : '(Mobile)'}
                  </span>
                  <UtilityCopyButton textToCopy={contactNumber} />
                </div>
              ) : null}
              {contactDetails.email ? (
                <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c u-marginTop-2'>
                  <span data-testid='contact-email'>
                    <a href={`mailto:${contactDetails.email}`}>
                      {contactDetails.email}
                    </a>{' '}
                    {contactDetails.email === contactDetails.preferredEmail
                      ? '(Preferred)'
                      : null}
                  </span>
                  <UtilityCopyButton textToCopy={contactDetails.email} />
                </div>
              ) : null}
              {shouldShowAddress && contactDetails.location ? (
                <div className='u-flex u-flexAlign-c u-marginTop-2'>
                  <span className='u-marginRight-4'>
                    {contactDetails.location}
                  </span>
                  <Icon color='blue' size='small' type='mapMarker' />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
      {canRemoveCard ? (
        <div className='u-flex u-flexJustify-c u-borderTop u-paddingTop-12 u-marginTop-4'>
          <ButtonSecondary label='Remove' onClick={onRemove} size='small' />
        </div>
      ) : null}
      {shouldShowDocuments &&
      (contactDetails.linkedinUrl || contactDetails.resume?.url) ? (
        <div className='u-flex u-flexJustify-spaceBetween u-borderTop u-paddingTop-12 u-marginTop-4'>
          {contactDetails.linkedinUrl ? (
            <ButtonSecondary
              icon='linkedIn'
              isFullWidth={true}
              label='LinkedIn'
              rel='noopener noreferrer'
              size='small'
              target='_blank'
              url={contactDetails.linkedinUrl}
            />
          ) : null}
          {contactDetails.resume?.url ? (
            <ButtonSecondary
              icon='download'
              isFullWidth={true}
              label='Resume'
              rel='noopener noreferrer'
              size='small'
              target='_blank'
              url={contactDetails.resume.url}
            />
          ) : null}
        </div>
      ) : null}
    </Card>
  );
};

ContactBaseballCard.propTypes = {
  canRemoveCard: PropTypes.bool,
  className: PropTypes.string,
  contactDetails: PropTypes.shape({
    aliases: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    avatarUrl: PropTypes.string,
    averageRating: PropTypes.number,
    connectionsCount: PropTypes.number,
    email: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    linkedinUrl: PropTypes.string,
    location: PropTypes.string,
    mobilePhone: PropTypes.string,
    offLimits: PropTypes.shape({
      category: PropTypes.string,
      status: PropTypes.string,
    }),
    phone: PropTypes.string,
    preferredEmail: PropTypes.string,
    primaryCompanyName: PropTypes.string,
    primaryPosition: PropTypes.shape({
      address: PropTypes.shape({
        location: PropTypes.string,
      }),
      company: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      companyName: PropTypes.string,
    }),
    primaryTitle: PropTypes.string,
    ratingsCount: PropTypes.number,
    resume: PropTypes.shape({
      url: PropTypes.string,
    }),
    workEmail: PropTypes.string,
    workPhone: PropTypes.string,
  }),
  onRemove: PropTypes.func,
  shouldShowAddress: PropTypes.bool,
  shouldShowDocuments: PropTypes.bool,
  shouldShowEmploymentDetails: PropTypes.bool,
};

ContactBaseballCard.defaultProps = {
  canRemoveCard: false,
  onRemove: () => {},
  shouldShowAddress: true,
  shouldShowDocuments: true,
  // controls the visibility of off-limit indicator and ratings
  shouldShowEmploymentDetails: true,
};

export default ContactBaseballCard;

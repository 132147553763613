import moment from 'moment';

/**
 * [A function that combines three user inputs in a set of form parameters
 * (date, start time and end time) into two datetime values for the database
 * (date plus start time, date plus end time) and returns the modified
 * parameters. ]
 * @param  {[datetime]} start_time [Appointment start time]
 * @param  {[datetime]} end_time   [Appointment end time]
 * @param  {[datetime]} date      [Appointment date]
 * @return {[object]}           [The correctly formatted dates]
 */
const convertFieldStateToRaw = ({ dateTimeRange, ...other }) => {
  if (!dateTimeRange) {
    return other;
  }

  // Merges the `date` and `time` fields into `start_time`
  const startTimeMoment = moment(dateTimeRange.start_time, 'LT');
  const endTimeMoment = moment(dateTimeRange.end_time, 'LT');
  const startDateMoment = moment(dateTimeRange.date, moment.ISO_8601);
  const endDateMoment = startDateMoment.clone();

  startDateMoment.set({
    hour: startTimeMoment.get('hour'),
    minute: startTimeMoment.get('minute'),
    second: startTimeMoment.get('second'),
  });

  endDateMoment.set({
    hour: endTimeMoment.get('hour'),
    minute: endTimeMoment.get('minute'),
    second: endTimeMoment.get('second'),
  });

  return {
    ...other,
    start_time: startDateMoment.format(),
    end_time: endDateMoment.format(),
  };
};

export default convertFieldStateToRaw;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { debounce } from 'lodash';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import MultiSelect from './MultiSelect';

export default class QueryMultiSelect extends Component {
  constructor(...args) {
    super(...args);
    this.componentId = uniqueId();
    this.handleQuery = debounce(this.handleQuery, 300);

    this.state = {
      term: '',
    };
  }

  componentDidMount() {
    const { onCreate } = this.props;
    onCreate(this.componentId);
  }

  componentWillUnmount() {
    const { onDestroy } = this.props;
    onDestroy(this.componentId);
  }

  handleQuery = (...args) => {
    const { onQuery } = this.props;
    onQuery(...args);
  };

  handleInputChange = searchTerm => {
    const {
      candidacyId,
      contactId,
      filter,
      minQueryLength,
      searchType,
    } = this.props;
    const term = searchTerm.trim();
    if (term.length >= minQueryLength) {
      const params = {
        queryId: this.componentId,
        searchType: searchType,
        term: term,
        contactId: contactId,
        candidacyId: candidacyId,
      };

      if (filter) {
        params.filter = filter;
      }

      this.handleQuery(params);
    }

    this.setState({ term: term });
  };

  render() {
    const {
      isLoading,
      minQueryLength,
      /* eslint-disable no-unused-vars */
      onCreate,
      onDestroy,
      onQuery,
      /* eslint-enable no-unused-vars */
      optionsFromData,
      queriesById,
      ...selectProps
    } = this.props;
    const { term } = this.state;

    const data = queriesById.getIn([this.componentId, 'data']);
    const isFetchingQuery = queriesById.getIn([
      this.componentId,
      'meta',
      'isFetching',
    ]);
    const isMinimumLength = !minQueryLength || term.length >= minQueryLength;
    const noResultsText = isMinimumLength
      ? 'No results found'
      : `Type at least ${minQueryLength} characters`;

    return (
      <MultiSelect
        noSearchResultsText={
          isLoading || isFetchingQuery ? 'Loading...' : noResultsText
        }
        noValueText='No selection'
        onSearchValueChange={this.handleInputChange}
        options={isMinimumLength ? optionsFromData(data) : []}
        {...selectProps}
        isLoading={isLoading || isFetchingQuery}
      />
    );
  }
}

QueryMultiSelect.propTypes = {
  candidacyId: PropTypes.number,

  contactId: PropTypes.number,

  filter: PropTypes.string,

  isLoading: PropTypes.bool,

  minQueryLength: PropTypes.number,

  onCreate: PropTypes.func.isRequired,

  onDestroy: PropTypes.func.isRequired,

  onQuery: PropTypes.func.isRequired,

  /**
   * A function that takes the data in `queriesById` and returns an array of options
   * suitable for passing to the MultiSelect control. Typically this should be an array of
   * objects with an `id` and `name` field. The default implementation simply calls toJS on
   * the result, which is normally (if the underlying data hasn't been normalized yet)
   * an Immutable.List containing Immutable.Maps of the records.
   */
  optionsFromData: PropTypes.func,

  queriesById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.mapContains({
      data: PropTypes.any,
      meta: ImmutablePropTypes.mapContains({
        isFetching: PropTypes.bool,
      }),
    }),
    PropTypes.string,
  ).isRequired,

  searchType: PropTypes.string,
};

QueryMultiSelect.defaultProps = {
  minQueryLength: 3,
  optionsFromData: data => (data ? data.toJS() : []),
  searchType: 'JobSearch',
};

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes, mapProps } from 'recompose';
import CancelButton from 'modules/forms/components/CancelButton';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import Modal from 'modules/core/componentsLegacy/Modal';
import ContactField from './ContactField';
import withContactForm from './withContactForm';

/**
 * Renders the ContactField in a modal window for creating (or editing?) a contact.
 */
const ContactCreateModalForm = ({
  formState,
  onClose,
  onFieldStateChange,
  onSelect,
  onSubmit,
  show,
  sidebarDisplay,
  title,
  ...fieldProps
}) => (
  <Modal
    className={classnames({ SidebarModal: sidebarDisplay })}
    onHide={onClose}
    show={show}
  >
    <Form formState={formState} onSubmit={onSubmit}>
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactField
          {...fieldProps}
          disabled={formState.isSubmitting()}
          fieldState={formState.getFieldState()}
          onChange={onFieldStateChange}
          onDuplicateSelect={onSelect}
          showErrors={formState.wasSubmitted() || 'blurred'}
        />
        <FormErrorMessage formState={formState} />
      </Modal.Body>
      <Modal.Footer>
        <div className='Form__footer'>
          <CancelButton bsSize='lg' onClick={onClose} />
          <SubmitButton bsSize='lg' formState={formState} onClick={onSubmit} />
        </div>
      </Modal.Footer>
    </Form>
  </Modal>
);

ContactCreateModalForm.propTypes = {
  /**
   * A FormState object containing a FieldState for use with the `ContactField`.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the modal should be closed after canceling the form -- no contact was created.
   */
  onClose: PropTypes.func.isRequired,

  /**
   * Called when the FieldState of the formState prop should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Called when an existing contact is select. This generally happens if a duplicate is found
   * and the user decides to select the duplicate contact instead of creating a new one.
   */
  onSelect: PropTypes.func.isRequired,

  /**
   * Called when the form is submit (the submit button is clicked)
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * True if the modal should be rendered/displayed, otherwise false.
   */
  show: PropTypes.bool,

  /**
   * True to display the modal as a "sidebar" modal.
   */
  sidebarDisplay: PropTypes.bool,

  /**
   * The title of displayed in the modal.
   */
  title: PropTypes.string,
};

ContactCreateModalForm.defaultProps = {
  show: false,
  sidebarDisplay: false,
  title: 'Create Contact',
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('ContactCreateModalForm(enhanced))'),
  setPropTypes({
    /**
     * Called when a contact has been successfully created. Called with the payload of the success
     * action
     */
    onSaved: PropTypes.func,
  }),

  // withContactForm will call onSaved when the form has been successfully submitted/saved -
  // which will send the id to ContactCreateModalButton and allow the new contact to be
  // populated in the creatable.
  withContactForm(ContactField),

  /**
   * Prevent props from being passed on to the modal
   */
  /* eslint-disable no-unused-vars */
  mapProps(
    ({ onCancel, onFormStateChange, onResetFormState, onSaved, ...rest }) => ({
      ...rest,
    }),
  ),
  /* eslint-enable no-unused-vars */
)(ContactCreateModalForm);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import Percentage from '@thrivetrm/ui/components/Percentage';
import Currency from '@thrivetrm/ui/components/Currency';
import DateTime from 'modules/datetime/components/DateTime';
import BonusViewHelper from 'modules/compensations/components/BonusViewHelper';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';

/**
 * Renders a display view of a compensation record.
 */
export const CompensationView = ({ compensation }) => {
  const hasDevelopmentOffersFeature = useFeatureCheck(
    'development.search_offers',
  );
  // Once the `development.search_offers` is sunset and the functionality is
  // enabled for all, the total compensation should always display, regardless
  // of flipper, including the extra comp fields flipper.
  const shouldDisplayTotalCompensation = hasDevelopmentOffersFeature;
  return (
    <div className='CompensationView row'>
      {Number.isFinite(compensation.get('base')) ? (
        <LabeledContent
          className='CompensationView__base col-sm-3'
          key='base'
          label='Base'
        >
          <Currency
            className='u-wordBreak'
            currency={compensation.get('currency')}
            value={compensation.get('base')}
          />
        </LabeledContent>
      ) : null}
      {Number.isFinite(
        compensation.get('bonus') || compensation.get('percentage_bonus'),
      ) ? (
        <LabeledContent
          className='CompensationView__bonus col-sm-3'
          key='bonus'
          label='Bonus'
        >
          {compensation.get('bonus_type') === 'fixed' ? (
            <Currency
              className='u-wordBreak'
              currency={compensation.get('currency')}
              value={compensation.get('bonus')}
            />
          ) : (
            <BonusViewHelper compensation={compensation} />
          )}
        </LabeledContent>
      ) : null}
      {Number.isFinite(compensation.get('equity')) ? (
        <LabeledContent
          className='CompensationView__equity col-sm-3'
          key='equity'
          label='Initial Equity Grant'
        >
          <Percentage
            maxFractionDigits={2}
            value={compensation.get('equity')}
          />
        </LabeledContent>
      ) : null}
      {Number.isFinite(compensation.get('additional_equity')) ? (
        <LabeledContent
          className='CompensationView__additional_equity col-sm-3'
          key='additional-equity'
          label='Annual Equity Grant'
        >
          <Percentage
            maxFractionDigits={2}
            value={compensation.get('additional_equity')}
          />
        </LabeledContent>
      ) : null}
      {hasDevelopmentOffersFeature &&
      Number.isFinite(compensation.get('initial_equity_shares')) ? (
        <LabeledContent
          className='CompensationView__initial-equity-shares col-sm-3'
          key='initial_equity_shares'
          label='Initial Number of Equity Shares'
        >
          {compensation.get('initial_equity_shares')}
        </LabeledContent>
      ) : null}
      {compensation.get('share_price') ? (
        <LabeledContent
          className='CompensationView__share_price col-sm-3'
          key='share-price'
          label='Share Price'
        >
          <Currency
            className='u-wordBreak'
            currency={compensation.get('currency')}
            value={compensation.get('share_price')}
          />
        </LabeledContent>
      ) : null}
      {compensation.get('equity_value') ? (
        <LabeledContent
          className='CompensationView__equity_value col-sm-3'
          key='equity-value'
          label='Initial Equity Grant in Currency'
        >
          <Currency
            className='u-wordBreak'
            currency={compensation.get('currency')}
            value={compensation.get('equity_value')}
          />
        </LabeledContent>
      ) : null}
      {compensation.get('vesting_date') ? (
        <LabeledContent
          className='CompensationView__vesting_date col-sm-3'
          key='vesting-date'
          label='Vesting Date'
        >
          <DateTime format='L' value={compensation.get('vesting_date')} />
        </LabeledContent>
      ) : null}
      {hasDevelopmentOffersFeature &&
      Number.isFinite(compensation.get('signing_bonus')) ? (
        <LabeledContent
          className='CompensationView__signing-bonus col-sm-3'
          key='signing_bonus'
          label='Signing Bonus'
        >
          <Currency
            className='u-wordBreak'
            currency={compensation.get('currency')}
            value={compensation.get('signing_bonus')}
          />
        </LabeledContent>
      ) : null}
      {hasDevelopmentOffersFeature &&
      Number.isFinite(compensation.get('relocation_bonus')) ? (
        <LabeledContent
          className='CompensationView__relocation-bonus col-sm-3'
          key='relocation_bonus'
          label='Relocation Bonus'
        >
          <Currency
            className='u-wordBreak'
            currency={compensation.get('currency')}
            value={compensation.get('relocation_bonus')}
          />
        </LabeledContent>
      ) : null}
      {Number.isFinite(compensation.get('equity')) ||
      Number.isFinite(compensation.get('additional_equity')) ? (
        <LabeledContent
          className='CompensationView__total_equity col-sm-3'
          key='total-equity'
          label='Total Equity'
        >
          <Percentage
            maxFractionDigits={2}
            value={
              compensation.get('equity') + compensation.get('additional_equity')
            }
          />
        </LabeledContent>
      ) : null}
      {shouldDisplayTotalCompensation &&
      compensation.get('total_compensation') ? (
        <LabeledContent
          className='CompensationView__total_compensation col-sm-3'
          key='total-compensation'
          label='Total Estimated Compensation'
        >
          <Currency
            className='u-wordBreak'
            currency={compensation.get('currency')}
            value={compensation.get('total_compensation')}
          />
        </LabeledContent>
      ) : null}
      {compensation.get('fully_diluted_ownership') ? (
        <LabeledContent
          className='CompensationView__fully_diluted_ownership col-sm-3'
          key='total-fully_diluted_ownership'
          label='Target Option Proceeds'
        >
          <Currency
            className='u-wordBreak'
            currency={compensation.get('currency')}
            value={compensation.get('fully_diluted_ownership')}
          />
        </LabeledContent>
      ) : null}
      {compensation.get('target_option_proceeds') ? (
        <LabeledContent
          className='CompensationView__target_option_proceeds col-sm-3'
          key='total-target_option_proceeds'
          label='Target Option Proceeds'
        >
          <Currency
            className='u-wordBreak'
            currency={compensation.get('currency')}
            value={compensation.get('target_option_proceeds')}
          />
        </LabeledContent>
      ) : null}
      {compensation.get('notes') ? (
        <LabeledContent
          className='CompensationView__notes col-sm-12'
          key='notes'
          label='Notes'
        >
          {compensation.get('notes')}
        </LabeledContent>
      ) : null}
    </div>
  );
};

CompensationView.propTypes = {
  /**
   * The compensation record.
   */
  compensation: ImmutablePropTypes.mapContains({
    additional_equity: PropTypes.number,
    base: PropTypes.number,
    bonus: PropTypes.number,
    bonus_type: PropTypes.string,
    equity: PropTypes.number,
    equity_value: PropTypes.number,
    notes: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    share_price: PropTypes.number,
    total_compensation: PropTypes.number,
    vesting_date: PropTypes.date,
  }).isRequired,
};

export default CompensationView;

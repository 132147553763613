import getEntity from 'modules/entities/selectors/getEntity';
import fundSchema from '../schema';

/**
 * Gets a fund record from state by it's ID
 * @param {Object} state
 * @param {Number} id The ID of the fund record
 * @returns {Map} The fund record, if found.
 */
export default (state, id) => getEntity(state, fundSchema.key, id);

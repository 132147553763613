import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import CandidacyListItemStageGroupHeader from './CandidacyListItemStageGroupHeader';
import CandidacyListItemSmallV2 from './CandidacyListItemSmallV2';
import CandidacyListItemV2 from './CandidacyListItemV2';
import { CARD_MODES, SMALL_CARD_MODE } from '../constants';

const ITEM_PADDING = 10;

/**
 * Gets the applicable component to render for the given list item
 * and list mode.
 * @param {String} cardMode The constant identifying the card mode that is currently being rendered.
 * @param {Object} listItem The list item to render
 * @param {?Boolean} listItem.isGroup Whether the list item represents a group header.
 * @param {?Immutable.map} listItem.stage The stage record associated with this list item
 * @return {[type]} [description]
 */
const getListItemComponent = (listItem, cardMode) => {
  if (listItem.isGroup) {
    return CandidacyListItemStageGroupHeader;
  }

  if (cardMode === SMALL_CARD_MODE) {
    return CandidacyListItemSmallV2;
  }

  return CandidacyListItemV2;
};

/**
 * Gets the height of a particular list item.
 * @param {String} cardMode The card mode constant identifying the card mode that is being rendered.
 * @param {Object} listItem The list item object.
 * @return {Number} The height of the item in the list.
 */
export const getCandidacyListItemHeight = ({ cardMode, listItem }) =>
  getListItemComponent(listItem, cardMode).HEIGHT + ITEM_PADDING;

/**
 * Renders an item in the the candidacy list.
 */
const CandidacyListItem = ({ cardMode, index, listItem, style, ...props }) => {
  const { selectedTabId: candidateId } = useParams();

  const Component = getListItemComponent(listItem, cardMode);

  const hasFocus = listItem?.candidacyId === Number(candidateId);

  return (
    <div
      className={classnames('CandidacyListItem', `CandidacyListItem-${index}`, {
        'CandidacyListItem--candidate': !listItem.isGroup,
        'CandidacyListItem--group': listItem.isGroup,
      })}
      data-test-id={listItem.candidacyId}
      data-test-stage={listItem.stage && listItem.stage.get('name')}
      style={style}
    >
      <div
        className={classnames('CandidacyListItem__componentContainer', {
          'CandidacyListItem__componentContainer--hasFocus': hasFocus,
        })}
      >
        <Component {...listItem} {...props} />
      </div>
    </div>
  );
};

CandidacyListItem.propTypes = {
  /**
   * The current card mode being rendered.
   */
  cardMode: PropTypes.oneOf(CARD_MODES),

  /**
   * The row index for this list item in the the overall list.
   */
  index: PropTypes.number.isRequired,

  /**
   * The list item object describing this particular list item.
   */
  listItem: PropTypes.shape({
    /**
     * If this list item represents a candidacy row, it's candidacy ID
     */
    candidacyId: PropTypes.number,

    /**
     * True if this list item is a group header.
     * @type {Boolean}     */
    isGroup: PropTypes.bool,

    stage: ImmutablePropTypes.mapContains({
      show_large_card: PropTypes.bool,
    }),
  }),

  /**
   * The item's style (placement in the virtual list)
   */
  style: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default CandidacyListItem;

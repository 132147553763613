import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import canEditSearchDetails from 'modules/searches/selectors/canEditSearchDetails';

const SearchEditButton = ({ url }) => {
  const hasSearchEditAccess = useSelector(canEditSearchDetails);

  return hasSearchEditAccess ? (
    <div className='table__displayOnHover'>
      <ButtonSecondary icon='edit' size='small' url={url} value='Edit' />
    </div>
  ) : null;
};

SearchEditButton.propTypes = {
  url: PropTypes.string,
};

export default SearchEditButton;

import { connect } from 'react-redux';
import getInterviewList from '../selectors/getInterviewList';

/**
 * A higher order component which maps a `parentType`, `parentId` and optional `filter` to it's
 * interviews list state.
 */
export default connect(
  (state, { filter, parentId, parentType }) => ({
    interviewList: getInterviewList(state, parentType, parentId, filter),
  }),
  {},
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RadioButtonGroup from '@thrivetrm/ui/components/RadioButtonGroup';
import {
  registerFilterInput,
  requestRecordList,
  selectFilterInputData,
  selectHasRegisteredFilterInput,
  setAndValidateSingleFilterValue,
} from '../../recordIndexSlice';

const RadioFilter = ({ label, name }) => {
  const dispatch = useDispatch();

  // Convert '0' or '1' to be compatible w/ core component
  const inputValue = useSelector(
    state => state.recordIndex.savedView.filters?.[name],
  );

  const { options } = useSelector(state => selectFilterInputData(state, name));
  const handleChange = value => {
    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: value,
      }),
    );
    return dispatch(requestRecordList());
  };

  const hasRegisteredFilterInput = useSelector(state =>
    selectHasRegisteredFilterInput(state, name),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  return (
    <RadioButtonGroup
      label={label}
      name={name}
      onChange={handleChange}
      options={options}
      value={inputValue}
    />
  );
};

RadioFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

RadioFilter.defaultProps = {
  label: null,
};

export default RadioFilter;

import PropTypes from 'prop-types';
import React from 'react';
import { onlyUpdateForPropTypes } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import EmptyState from 'modules/core/componentsLegacy/EmptyState';

/**
 * Used to indicate an empty collection of talent pool/contact records.
 */
const TalentPoolsEmptyState = ({
  filterText,
  hasUnfiltered,
  onClearFilterText,
  onClearSelectedContact,
  onCreateTalentPool,
  selectedContact,
}) => {
  let props;

  if (filterText && hasUnfiltered) {
    // There's no results being shown, but it's because the filter text has filtered them all
    // out, and not because there's no results at all.
    props = {
      message: (
        <div>
          <div>No talent pools matched your search term</div>
          <div className='talent-pools-empty-state-search-term'>
            &quot;
            <mark>{filterText}</mark>
            &quot;
          </div>
        </div>
      ),
      buttonText: 'Clear search term',
      onButtonClick: onClearFilterText,
    };
  } else if (selectedContact) {
    // If there is a selected contact, we're only showing that contact's direct reports,
    // so the reason there's no results is that the selected contact has no direct reports.
    props = {
      message: (
        <span>
          <span className='talent-pools-empty-state-selected-contact'>
            {(selectedContact.get && selectedContact.get('full_name')) ||
              'The selected contact'}
          </span>
          <span> has no direct reports</span>
        </span>
      ),
      buttonText: 'Show all talent pools',
      onButtonClick: onClearSelectedContact,
    };
  } else {
    // At this point we have no results because there's no talent pools in the system at all.
    props = {
      className: 'well',
      icon: <i className='fa fa-sitemap fa-4x' />,
      buttonClassName: 'btn-primary',
      message: 'No talent pools have been created yet!',
      buttonText: 'Create one now',
      onButtonClick: onCreateTalentPool,
    };
  }

  return <EmptyState className='talent-pools-empty-state' {...props} />;
};

TalentPoolsEmptyState.propTypes = {
  /**
   * The current filter, if any, that may be limiting the current displayed
   * talent pools/contacts
   */
  filterText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  /**
   * Whether there are currently records that can be displayed when the filter is not taken
   * into account.
   */
  hasUnfiltered: PropTypes.bool,

  /**
   * Called whe the current filter text should be cleared.
   */
  onClearFilterText: PropTypes.func,

  /**
   * Called when the selected contact should be removed and the full list of talent pools should
   * be shown instead.
   */
  onClearSelectedContact: PropTypes.func,

  /**
   * Called when the user would like to create a new talent pool.
   */
  onCreateTalentPool: PropTypes.func,

  /**
   * The currently selected contact or contact ID, if any, whose talent pools/direct reports are
   * being displayed. Should be undefined/null when displaying the full list of all talent pools.
   */
  selectedContact: PropTypes.oneOfType([
    PropTypes.number,
    ImmutablePropTypes.mapContains({
      full_name: PropTypes.string,
    }),
  ]),
};

export default onlyUpdateForPropTypes(TalentPoolsEmptyState);

import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../../../actions/contactReviews';

/**
 * Connects the contactReview actions to the current dispatcher and adds
 * them to props as `contactReviewActions`
 */
export default connect(null, dispatch => ({
  contactReviewActions: bindActionCreators(actions, dispatch),
}));

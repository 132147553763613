import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import tagSchema from '../schemas/tag';

/**
 * An action for updating a tag.
 * @param {Object} options
 * @param {Object} options.tag The tag to create
 * @param {Number} options.tag.id The name of the tag
 * @param {String} [options.tag.name] The updated name of the tag
 * @param {String} [options.tag.color] The updated color of the tag
 * @param {String} [options.transactionId] A unique ID used to track the request.
 */
export default ({ tag, transactionId }) =>
  updateEntity({
    body: { tag: tag },
    transactionId: transactionId,
    entityType: tagSchema.key,
    responseSchema: { tag: tagSchema },
    url: routes.api_v1_tag({ id: tag.id }),
  });

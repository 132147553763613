import React from 'react';
import TabbedHeaderContainer from 'modules/layouts/tabbedHeader/TabbedHeaderContainer';

const NotFoundPage = props => (
  <TabbedHeaderContainer {...props}>
    <h2>Not found</h2>
    <p>Page not found</p>
  </TabbedHeaderContainer>
);

export default NotFoundPage;

import { convertToCamelCase } from 'modules/core/jsonUtils';
import PropTypes from 'prop-types';
import apiV1 from './index';
import queryTags from './queryTags';

export const employeePropType = PropTypes.shape({
  connectionDate: PropTypes.string,
  createdBy: PropTypes.string,
  createdByEmail: PropTypes.string,
  emailAddress: PropTypes.string,
  employeeId: PropTypes.number,
  employeeName: PropTypes.string,
  employeePosition: PropTypes.string,
  lastUpdated: PropTypes.string,
});

const { REPORTING_RELATIONSHIPS } = queryTags;

const reportingRelationships = apiV1.injectEndpoints({
  endpoints: builder => ({
    getReportingRelationships: builder.query({
      query: contactId => ({
        url: `/contacts/${contactId}/reporting_relationships`,
        method: 'GET',
      }),
      transformResponse: response => {
        /**
         * Splitting out the employees based on
         * their reporting hierarchy (tree_depth).
         */
        const treeDepthOne = [];
        const treeDepthTwo = [];
        if (response.manages.length) {
          response.manages.forEach(employee => {
            if (employee.tree_depth === 1) {
              treeDepthOne.push(employee);
            }
            if (employee.tree_depth === 2) {
              treeDepthTwo.push(employee);
            }
          });
        }

        return {
          manages: {
            treeDepthOneEmployees: convertToCamelCase(treeDepthOne),
            treeDepthTwoEmployees: convertToCamelCase(treeDepthTwo),
          },
          reportsTo: convertToCamelCase(response.reports_to),
        };
      },
      providesTags: [REPORTING_RELATIONSHIPS],
    }),
  }),
});

export const { useGetReportingRelationshipsQuery } = reportingRelationships;

import routes from 'modules/routing/routes';
import {
  USER_OPTIONS_UPDATE_START,
  USER_OPTIONS_UPDATE_SUCCESS,
  USER_OPTIONS_UPDATE_FAILURE,
} from './actionTypes';
import createCrudActions from '../../../actions/createCrudActions';

const crudActions = createCrudActions({
  getUrl: ({ userId }) => routes.api_user_option({ id: userId }),
  update: {
    start: USER_OPTIONS_UPDATE_START,
    success: USER_OPTIONS_UPDATE_SUCCESS,
    failure: USER_OPTIONS_UPDATE_FAILURE,
  },
});

const updateUserOptions = crudActions.update;

export default updateUserOptions;

import React from 'react';
import PropTypes from 'prop-types';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import Menu from '@thrivetrm/ui/components/Menu';
import ContactSummaryReportModal from './ContactSummaryReportModal';
import ContactCustomReportModal from './ContactCustomReportModal';

const ContactDownloadReportButton = ({ contactId, currentUserEmail }) => {
  const hasCustomReport = useFeatureCheck(
    'feature.contact_custom_report_generator',
  );
  const hasSummaryReport = useFeatureCheck('report.contact_summary_report');

  const [
    isSummaryReportModalOpen,
    openSummaryReportModal,
    closeSummaryReportModal,
  ] = useToggle(false);
  const [
    isCustomReportModalOpen,
    openCustomReportModal,
    closeCustomReportModal,
  ] = useToggle(false);

  if (!hasCustomReport && !hasSummaryReport) {
    return null;
  }

  return (
    <>
      {hasCustomReport && hasSummaryReport ? (
        <Menu
          button={
            <ButtonSecondary
              className='u-noWrap'
              icon='export'
              isFullWidth={true}
              label='Export'
              size='small'
            />
          }
          className='ContactDownloadReportButton__menu u-width-100'
        >
          <Menu.Item onClick={openSummaryReportModal}>Summary Report</Menu.Item>
          <Menu.Item onClick={openCustomReportModal}>Custom Report</Menu.Item>
        </Menu>
      ) : (
        <ButtonSecondary
          className='ContactDownloadReportButton__button u-noWrap'
          icon='export'
          isFullWidth={true}
          label='Export'
          onClick={
            hasCustomReport ? openCustomReportModal : openSummaryReportModal
          }
          size='small'
        />
      )}
      {isSummaryReportModalOpen ? (
        <ContactSummaryReportModal
          contactId={contactId}
          currentUserEmail={currentUserEmail}
          onHide={closeSummaryReportModal}
          show={true}
        />
      ) : null}
      {isCustomReportModalOpen ? (
        <ContactCustomReportModal
          contactId={contactId}
          initialRecipients={[currentUserEmail]}
          onClose={closeCustomReportModal}
        />
      ) : null}
    </>
  );
};

ContactDownloadReportButton.propTypes = {
  /**
   * The ID of the contact to generate the report for.
   */
  contactId: PropTypes.number.isRequired,

  currentUserEmail: PropTypes.string.isRequired,
};

export default ContactDownloadReportButton;

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, withHandlers } from 'recompose';
import classnames from 'classnames';

import FieldState from 'modules/forms/FieldState';

const AttachmentDeleteToggle = ({ fieldState, onClick }) => (
  <button
    className='btn btn-link AttachmentDeleteToggle'
    onClick={onClick}
    type='button'
  >
    <i
      className={classnames('fa', {
        'fa-times': !fieldState.getNestedFieldValue('destroy'),
        'fa-undo': Boolean(fieldState.getNestedFieldValue('destroy')),
      })}
    />
  </button>
);

AttachmentDeleteToggle.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('AttachmentDeleteToggle(enhanced)'),
  withHandlers({
    onClick: ({ fieldState, onChange }) => {
      if (fieldState.getNestedFieldValue('destroy')) {
        onChange(fieldState.setNestedFieldValue('destroy', false));
      } else {
        onChange(fieldState.setNestedFieldValue('destroy', true));
      }
    },
  }),
)(AttachmentDeleteToggle);

import { connect } from 'react-redux';
import { compose, defaultProps, setDisplayName } from 'recompose';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import getUserAvatar from '../selectors/getUserAvatar';
import AvatarUploaderButton from '../../../components/contact/header/AvatarUploaderButton';

const handleSubmit = (
  { userActions, userId },
  { avatarFile },
  transactionId,
) => {
  const avatarPayload = {
    user: {
      avatar: avatarFile,
    },
    userId: userId,
    transactionId: transactionId,
  };

  if (avatarFile) {
    userActions.updateAvatar(avatarPayload);
  } else {
    userActions.updateUser({
      ...avatarPayload,
      user: { remove_avatar: true },
    });
  }
};

export default compose(
  setDisplayName('UserAvatarButton'),
  defaultProps({
    onSubmit: handleSubmit,
  }),
  connectTransactions,
  connect(
    (state, { userId }) => ({
      avatarUrl: getUserAvatar(state, userId),
    }),
    {},
  ),
)(AvatarUploaderButton);

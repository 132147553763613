import isRejectedStage from 'modules/search-stages/selectors/isRejectedStage';
import getCandidacyStageId from './getCandidacyStageId';

/**
 * Determines if a candidacy is in a rejected stage.
 * @param {Object} state
 * @param {Number} candidacyId The candidacy ID
 * @return {Boolean} True if the candidacy is in a rejected stage; otherwise false.
 */
export default (state, candidacyId) =>
  isRejectedStage(state, getCandidacyStageId(state, candidacyId));

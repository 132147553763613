import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import mapInvestmentIdToInvestment from 'modules/company-investments/components/mapInvestmentIdToInvestment';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import FundingRoundFormModal from './FundingRoundFormModal';
import deleteFundingRoundActionCreator from '../actions/deleteFundingRound';

/**
 * A Table Cell component for the funding rounds table that shows edit and delete
 * actions. The edit action triggers the display of a modal for editing the funding
 * round. The delete action shows a confirmation popover, and then deletes the
 * funding round when confirmed.
 */
const FundingRoundEditDeleteTableCell = ({
  handleDelete,
  handleEditClose,
  handleEditOpen,
  investmentId,
  isDeleting,
  showEditModal,
}) => (
  <div className='FundingRoundEditDeleteTableCell'>
    <button
      className='btn btn-link'
      disabled={isDeleting}
      onClick={handleEditOpen}
      type='button'
    >
      <i className='fa fa-pencil' />
    </button>
    {showEditModal && (
      <FundingRoundFormModal
        investmentId={investmentId}
        onClose={handleEditClose}
        show={true}
      />
    )}
    <ConfirmationPopoverButton
      className='btn btn-link'
      disabled={isDeleting}
      iconClass={classnames('fa', {
        'fa-trash': !isDeleting,
        'fa-spinner': isDeleting,
        'fa-spin': isDeleting,
      })}
      onConfirm={handleDelete}
      popoverPlacement='left'
      title='Delete?'
    >
      Are you sure you want to delete this funding round?
    </ConfirmationPopoverButton>
  </div>
);

FundingRoundEditDeleteTableCell.propTypes = {
  /**
   * Called when the delete action is confirmed
   */
  handleDelete: PropTypes.func.isRequired,

  /**
   * Called when editing should stop and the edit form hidden.
   */
  handleEditClose: PropTypes.func.isRequired,

  /**
   * Called when the funding round edit form should be shown so the funding
   * round can be edited.
   */
  handleEditOpen: PropTypes.func.isRequired,

  /**
   * The ID of the funding round for editing/deleting.
   */
  investmentId: PropTypes.number.isRequired,

  /**
   * True if there is currently a delete operation in progress.
   * (allows us to show a spinner while the ajax request is in progress)
   */
  isDeleting: PropTypes.bool,

  /**
   * True if the edit modal should be shown; otherwise, false.
   */
  showEditModal: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('FundingRoundEditDeleteTableCell(enhanced)'),
  setPropTypes({
    /**
     * The Funding round ID, provided by the Table component.
     */
    datum: PropTypes.number.isRequired,
  }),
  mapProps(({ datum: investmentId }) => ({
    investmentId: investmentId,
  })),
  connect(null, dispatch => ({
    deleteFundingRound: bindActionCreators(
      deleteFundingRoundActionCreator,
      dispatch,
    ),
  })),
  mapInvestmentIdToInvestment,

  // Look up whether we're currently deleting the funding round.
  withProps(({ investment }) => ({
    isDeleting: investment && investment.getIn(['_meta', 'isDeleting']),
  })),

  // Provide management of the edit modal, as well as handling the delete action.
  withStateHandlers(
    {
      showEditModal: false,
    },
    {
      handleEditOpen: () => () => ({ showEditModal: true }),
      handleEditClose: () => () => ({ showEditModal: false }),
      handleDelete: (_, { deleteFundingRound, investment }) => () => {
        deleteFundingRound({
          companyId: investment.get('venture'),
          id: investment.get('id'),
        });
      },
    },
  ),
)(FundingRoundEditDeleteTableCell);

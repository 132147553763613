import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import getRevenueListSelector from '../selectors/getRevenueList';
import shouldFetchRevenueListSelector from '../selectors/shouldFetchRevenueList';
import fetchRevenueListActionCreator from '../actions/fetchRevenueList';

/**
 * Provides a `revenueList` prop -- the state of a list of revenues
 * for a particular company ID. Fetches the list if needed.
 */
export default compose(
  setDisplayName('withRevenueListFetched'),
  setPropTypes({
    companyId: PropTypes.number,
  }),
  connect(
    (state, props) => ({
      shouldFetchRevenueList: shouldFetchRevenueListSelector(
        state,
        props.companyId,
      ),
      revenueList: getRevenueListSelector(state, props.companyId),
    }),
    dispatch => ({
      fetchRevenueList: bindActionCreators(
        fetchRevenueListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchRevenueListIfNeeded: ({
      companyId,
      fetchRevenueList,
      shouldFetchRevenueList,
    }) => () => {
      if (shouldFetchRevenueList) {
        fetchRevenueList({ companyId: companyId });
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchRevenueListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchRevenueListIfNeeded();
    },
  }),
);

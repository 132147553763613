import { compose, lifecycle, setDisplayName, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import fetchFundListActionCreator from '../actions/fetchFundList';
import shouldFetchFundListSelector from '../selectors/shouldFetchFundList';
import getFundList from '../selectors/getFundList';

/**
 * Fetches the fund list if needed and provides the `fundList` prop.
 * */
export default compose(
  setDisplayName('withFundlistFetched'),
  connect(
    (state, { companyId }) => ({
      fundList: getFundList(state, companyId),
      shouldFetchFundList: shouldFetchFundListSelector(state, companyId),
    }),
    dispatch => ({
      fetchFundList: bindActionCreators(fetchFundListActionCreator, dispatch),
    }),
  ),
  withHandlers({
    fetchFundListIfNeeded: ({ fetchFundList, shouldFetchFundList }) => () => {
      if (shouldFetchFundList) {
        fetchFundList();
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchFundListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchFundListIfNeeded();
    },
  }),
);

import { PARENT_TYPES, LIST_ALL, LIST_TYPES } from '../constants';
import getOutreaches from './getOutreaches';

/**
 * Determines if a list of outreaches should be fetched.
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CONTACT or PARENT_SEARCH)
 * @param {Number} parentId The parent ID (a contact ID or search ID, depending on
 *   the value of `parentType`)
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default (state, parentType, parentId, listType = LIST_ALL) => {
  if (!PARENT_TYPES.includes(parentType)) {
    throw new Error(`Invalid parentType: ${parentType}`);
  }

  if (!LIST_TYPES.includes(listType)) {
    throw new Error(`Invalid listType: ${listType}`);
  }

  const outreaches = getOutreaches(state, parentType, parentId, listType);

  if (!outreaches) {
    return true;
  }

  if (outreaches.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (outreaches.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !outreaches.has('ids') ||
    Boolean(outreaches.getIn(['_meta', 'isInvalidated']))
  );
};

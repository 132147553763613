import { NAME, LIST_ALL } from '../constants';

/**
 * Gets the list of outreaches that belong to a particular parent (parent type + id)
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CONTACT or PARENT_SEARCH)
 * @param {Number} parentId The parent ID -- either a contact ID when
 *   parentType === PARENT_CONTACT, or a search ID when parentType === PARENT_SEARCH
 * @return {Immutable.Map|undefined} The list state, if it's been created (it won't be created
 *   until an action related to it has been dispatched)
 */
export default (state, parentType, parentId, listType = LIST_ALL) =>
  state[NAME].getIn([
    listType === LIST_ALL
      ? 'allOutreachesByParentType'
      : 'recentOutreachesByParentType',
    parentType,
    parentId,
  ]);

import PropTypes from 'prop-types';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';

import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';

import connectInterviewActions from './connectInterviewActions';
import mapPropsToParentIdAndParentType from './mapPropsToParentIdAndParentType';
import mapParentToInterviewList from './mapParentToInterviewList';

/**
 * Renders a loading indicator while fetching interview for a particular parentType, parentId,
 * and optional filter.
 */
export default compose(
  setDisplayName('InterviewsFetchErrorAlert'),
  setPropTypes({
    searchId: PropTypes.number,
  }),
  defaultProps({
    title: 'There was an error fetching interviews',
  }),
  mapPropsToParentIdAndParentType,
  connectInterviewActions,
  withProps(({ filter, interviewActions, parentId, parentType }) => ({
    onRetry: () =>
      interviewActions.fetchInterviewList({
        parentType: parentType,
        parentId: parentId,
        filter: filter,
      }),
  })),
  mapParentToInterviewList,
  branch(
    ({ interviewList }) =>
      !interviewList ||
      !interviewList.getIn(['_meta', 'error']) ||
      interviewList.getIn(['_meta', 'isFetching']),
    renderNothing,
  ),
)(ErrorAlert);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  defaultProps,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import DateTime from 'modules/datetime/components/DateTime';

import wrapInCandidacyOrContactLink from './wrapInCandidacyOrContactLink';
import HubspotIndicator from './HubspotIndicator';

const OutreachTitle = ({ outreach, renderDate, renderDescription }) => (
  <div className='outreaches--outreach-title'>
    {renderDate && (
      <div className='outreaches--outreach-date'>
        <DateTime format='L LT' value={outreach.get('contacted_on')} />
      </div>
    )}
    {outreach.get('hubspot_engagement_id') && <HubspotIndicator />}
    {renderDescription && (
      <div className='outreaches--outreach-description'>
        {outreach.get('long_description')}
      </div>
    )}
  </div>
);

OutreachTitle.propTypes = {
  outreach: ImmutablePropTypes.mapContains({
    long_description: PropTypes.string.isRequired,
    contacted_on: PropTypes.string.isRequired,
  }).isRequired,

  /**
   * False to exclude the date from the date.
   */
  renderDate: PropTypes.bool,

  /**
   * False to exclude the description from the title.
   */
  renderDescription: PropTypes.bool,
};

OutreachTitle.defaultProps = {
  renderDate: true,
  renderDescription: true,
};

export default compose(
  setDisplayName('OutreachTitle(enhanced)'),
  setPropTypes({
    ...OutreachTitle.propTypes,

    /**
     * When true, renders the title as a link to the candidacy's page outreach
     * tab (or contact page, if they are no longer in the search). Requires
     * that the current `searchId` is specified when true.
     */
    renderAsLink: PropTypes.bool,

    /**
     * In order to render the appropriate candidacy link, the search ID is needed
     * if `renderAsLink` is true.
     */
    searchId: requiredIf(PropTypes.number, ({ renderAsLink }) => renderAsLink),
  }),
  defaultProps({
    renderAsLink: false,
  }),
  branch(props => Boolean(props.renderAsLink), wrapInCandidacyOrContactLink),
  onlyUpdateForPropTypes,
)(OutreachTitle);

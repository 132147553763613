import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import CheckboxField from 'modules/forms/components/CheckboxField';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import { PARENT_TYPES } from '../constants';
import mapParentIdToParent from './mapParentIdToParent';

/**
 * A field for setting an address as the primary address for a parent.
 * This will show a warning when setting this is selected but the parent's current
 * primary address is set to a different address.
 */
export default compose(
  setDisplayName('PrimaryAddressCheckboxField'),
  setStatic('createFieldState', CheckboxField.createFieldState),
  setPropTypes({
    /**
     * The ID of the address record this appllies to (if editing an existing ID)
     */
    addressId: PropTypes.number,

    fieldState: fieldStatePropType.isRequired,

    /**
     * The ID of the parent this address is or will be associated with.
     */
    parentId: PropTypes.number.isRequired,

    /**
     * The type of the parent this address is or will be associated with.
     */
    parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,
  }),
  defaultProps({
    text: 'Save as the primary address',
  }),
  mapParentIdToParent,
  mapProps(
    ({
      addressId,
      fieldState,
      parent,
      parentId: _parentId,
      parentType,
      ...rest
    }) => ({
      ...rest,
      fieldState: fieldState,
      // If the fieldstate is checked (meaning "make this the primary address") and the
      // parent's current primary address is different from this address, then show a warning.
      warning:
        fieldState.getValue() &&
        parent &&
        parent.get('primary_address') &&
        parent.get('primary_address') !== addressId
          ? `This will replace the ${parentType.toLowerCase()}'s current primary address.`
          : null,
    }),
  ),
)(CheckboxField);

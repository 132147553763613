import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import Link from 'modules/routing/components/Link';
import getDashboardUrl from '../selectors/getDashboardUrl';

/**
 * Renders a link to the talent pools dashboard page.
 * If a `contactId` prop is given, that contact will be the selected incumbent.
 */
export default compose(
  setDisplayName('DashboardLink'),
  setPropTypes({
    contactId: PropTypes.number,
  }),
  connect(
    (state, { contactId }) => ({
      href: getDashboardUrl(state, { contactId: contactId }),
    }),
    {},
  ),

  // Remove the contactId prop so it doesn't get added to the <a/> element.
  mapProps(({ contactId, ...props }) => props), // eslint-disable-line no-unused-vars
)(Link);

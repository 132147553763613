import getStage from 'modules/search-stages/selectors/getStage';
import getCandidacyProperty from './getCandidacyProperty';

/**
 * Gets the highest stage record for a particular candidacy.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?Immutable.Map} The record of the highest stage.
 */
export default (state, candidacyId) => {
  const stageId = getCandidacyProperty(
    state,
    candidacyId,
    'highest_candidacy_stage_id',
  );
  return stageId && getStage(state, stageId);
};

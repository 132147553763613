import { connect } from 'react-redux';
import getPreference from '../selectors/getPreference';

/**
 * Fetches a user preference value and passes it along as a prop
 * @param {String} propName The name of the prop
 * @param {String|String[]|Function} key The key, key path, or a function that is called with the
 *   current props and returns a key or key path. This determines where the value will be saved
 *   in the user's preferences state.
 * @param {Function|*} [defaultValue] an optional default value to return if the key does not exist.
 *   If a function is provided, it will be called with the current props.
 */
export default (propName, key, defaultValue) =>
  connect(
    (state, props) => ({
      [propName]: getPreference(
        state,

        // When `key` or `defaultValue` is a function, call it with the current props and use the
        // the result. This allows these values to be determines based on props.
        typeof key === 'function' ? key(props) : key,
        typeof defaultValue === 'function' ? defaultValue(props) : defaultValue,
      ),
    }),
    {},
  );

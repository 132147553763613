import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AdvancedSelect from '@thrivetrm/ui/components/AdvancedSelect';
import classnames from 'classnames';
import {
  registerFilterInput,
  requestRecordList,
  selectHasRegisteredFilterInput,
  setAndValidateSingleFilterValue,
  selectFilterInputData,
} from '../../recordIndexSlice';

const SingleValueSelectFilter = ({ label, name }) => {
  const dispatch = useDispatch();

  const inputValue = useSelector(state =>
    state.recordIndex.savedView.filters?.[name]
      ? Number(state.recordIndex.savedView.filters?.[name])
      : undefined,
  );
  const { isPairedInput, leadsPair, placeholder } = useSelector(state =>
    selectFilterInputData(state, name),
  );

  const options = useSelector(state => {
    const { options: storedOptions } = selectFilterInputData(state, name);
    return storedOptions.map(([optionLabel, value]) => ({
      label: optionLabel,
      value: `${value}`,
    }));
  });

  const handleChange = value => {
    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: value === null ? null : `${value}`,
      }),
    );
    return dispatch(requestRecordList());
  };

  const hasRegisteredFilterInput = useSelector(state =>
    selectHasRegisteredFilterInput(state, name),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  const containerClass = classnames('u-marginTop-8', {
    'u-paddingRight-8': isPairedInput && leadsPair,
    'u-paddingLeft-8': isPairedInput && !leadsPair,
    'u-inlineBlock': isPairedInput,
  });

  return (
    <div
      className={containerClass}
      style={{
        width: isPairedInput ? '50%' : '100%',
      }}
    >
      <AdvancedSelect
        defaultValue={options.find(
          option => `${option.value}` === `${inputValue}`,
        )}
        inputWidth='full'
        isClearable={true}
        label={label}
        name={name}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        size='small'
      />
    </div>
  );
};

SingleValueSelectFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

SingleValueSelectFilter.defaultProps = {
  label: null,
};

export default SingleValueSelectFilter;

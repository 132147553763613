import { compose, mapProps } from 'recompose';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';

export default compose(
  withFeatureCheck(
    'label.career_highlights_to_google_insights',
    'useGoogleInsightsLabel',
  ),
  mapProps(({ useGoogleInsightsLabel, ...props }) => ({
    fieldLabels: {
      aliases: 'Aliases',
      assessment_attribute_report: 'Assessment Criteria Descriptions',
      assessments: 'Assessments',
      avatar: 'Avatar',
      career_highlights: useGoogleInsightsLabel
        ? 'Google Insights'
        : 'Career Highlights',
      comments: 'Comments',
      company: 'Company',
      compensation: 'Compensation',
      documents: 'Documents',
      education: 'Education',
      email: 'Email',
      experience: 'Experience',
      interviews: 'Interviews',
      job_title: 'Job Title',
      location: 'Location',
      mobile: 'Mobile',
      name: 'Name',
      notes: 'Search Notes',
      phone: 'Phone',
      professional_networks: 'Professional Networks',
      recruiter_notes: 'Recruiter Notes',
      references: 'References',
      rejection_comments: 'Rejection Comments',
      rejection_reason: 'Rejection Reason',
      resume: 'Resume',
      stage: 'Stage',
      stage_at_rejection: 'Stage at Rejection',
      thrive_profile: 'Thrive Profile',
    },

    ...props,
  })),
);

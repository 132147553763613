/* eslint-disable camelcase */
// ^ accommodate API exchange format
import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import defaultMethod from 'modules/api/actionModifiers/defaultMethod';
import modifySuccessResponse from 'modules/api/actionModifiers/modifySuccessResponse';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import tagSchema from 'modules/tags/schemas/tag';
import smartTagSchema from 'modules/tags/schemas/smartTag';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import { candidacySchema } from '../../schemas';

/**
 * Creates an action creator for the api_v1_candidacy_tag endpoint.
 *
 * The add/remove/fetch actions for a candidacy tag all share common URL and response,
 * which is defined here. The only differences are the action types and the request method used.
 * This is a helper method that takes those differences as options and creates an action creator.
 *
 * @param {Object} options
 * @param {String} options.method The request method to use
 * @param {String} options.startActionType The URL of the request
 * @param {String} options.successActionType The URL of the request
 * @param {String} options.failureActionType The URL of the request
 * @param {Function} options.route The route creator method used.
 * @returns {Function} An action creator
 */
export default ({
  failureActionType,
  method,
  startActionType,
  successActionType,
  route = routes.api_v1_candidacy_tag,
}) =>
  compose(
    fetchAction,

    // The response returns the list of tags associated with the candidacy, i.e.:
    // ```
    // {
    //    tags: [ { id: 1, name: 'foo', }, { id: 2, .... } ]
    // }
    // ```
    // We want to use this to replace the `tag_ids` value for the candidacy in question, so this
    // adds the candidacy record to the response:
    // ```
    // {
    //   candidacy: { id: 42, tag_ids: [1, 2] },
    //   tags: [ { id: 1, name: 'foo', }, { id: 2, .... } ]
    // }
    // ```
    modifySuccessResponse(({ smart_tags, tags, ...rest }, { candidacyId }) => ({
      ...rest,
      candidacy: {
        id: candidacyId,
        tag_ids: tags?.map(({ id }) => id),
        smart_tag_ids: smart_tags?.map(({ id }) => id),
      },
      tags: tags,
      smart_tags: smart_tags,
    })),

    normalizeResponse({
      candidacy: candidacySchema,
      tags: [tagSchema],
      smart_tags: [smartTagSchema],
    }),

    withOptions(({ searchType, ...params }) => ({
      url:
        searchType === TYPE_PIPELINE
          ? routes.api_v1_contact_smart_tag({
              contactId: params.contactId,
              id: params.id,
            })
          : route(params),
    })),
    createAsyncActions({
      startActionType: startActionType,
      successActionType: successActionType,
      failureActionType: failureActionType,
    }),
    defaultMethod(method),
  );

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import TextInput from '@thrivetrm/ui/components/TextInput';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import { useApiPut } from 'modules/core/hooks/useApi';

const EditSavedViewSidePanel = ({
  isOpen,
  onClose,
  savedViewId,
  savedViews,
}) => {
  const { endpoints } = useSelector(state => state.recordIndex);

  const currentSavedView = savedViews.find(view => view.id === savedViewId);
  const otherSavedViews = savedViews.filter(view => view.id !== savedViewId);
  const { name } = currentSavedView;

  const [textInputValue, setTextInputValue] = useState(name);

  const hasChangedInputValue = () => name !== textInputValue;

  const takenSavedViewNames = otherSavedViews.map(view => view.name);
  const isInputValueTaken = takenSavedViewNames.includes(textInputValue);
  const isNewNameValid = textInputValue?.trim() && !isInputValueTaken;

  const getErrorMessage = () => {
    if (isInputValueTaken) {
      return 'The name you entered is already taken. Please enter a unique name.';
    } else if (textInputValue.trim()) {
      return null;
    } else {
      return 'Please enter a name for this view';
    }
  };

  const handleChange = newValue => {
    setTextInputValue(newValue);
  };

  const handleCancel = () => {
    setTextInputValue(name);
    onClose();
  };

  const [updateSavedView, isUpdatingViewName] = useApiPut(
    endpoints.savedView(savedViewId),
  );

  // Temporarily reloading the page until a slice is installed that stores saved views and can update when needed.
  const handleSave = () =>
    updateSavedView({ name: textInputValue }).then(() =>
      window.location.reload(),
    );

  const header = document.querySelector('#global-header');
  const globalNavHeight = header ? getComputedStyle(header)?.height : 0;
  const sidePanelHeight = `calc(100% - ${globalNavHeight})`;

  return (
    <SidePanel
      isOpen={isOpen}
      style={{ height: sidePanelHeight, top: 'initial' }}
    >
      <SidePanel.Header onClose={handleCancel} title='Rename Saved View' />
      <SidePanel.Body>
        <LoadingContainer
          isLoading={isUpdatingViewName}
          minHeight={350}
          overlayColor='gray10'
        >
          <TextInput
            errorMessage={getErrorMessage()}
            inputWidth='full'
            label='Name'
            onChange={handleChange}
            placeholder='Enter a name for this view'
            value={textInputValue}
          />
        </LoadingContainer>
      </SidePanel.Body>
      <SidePanel.Footer>
        <ButtonPrimary isOutline={true} label='Cancel' onClick={handleCancel} />
        <ButtonPrimary
          isDisabled={!hasChangedInputValue() || !isNewNameValid}
          label='Save'
          onClick={handleSave}
        />
      </SidePanel.Footer>
    </SidePanel>
  );
};

EditSavedViewSidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  savedViewId: PropTypes.number.isRequired,
  // Ideally this is not disabled, fix is to define the saved view object's shape
  // eslint-disable-next-line react/forbid-prop-types
  savedViews: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EditSavedViewSidePanel;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import Date from '@thrivetrm/ui/components/Date';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import ContactAvatarWithQuickView from 'modules/contacts/components/ContactAvatarWithQuickView';
import { invalidateCandidacyStatus } from 'modules/candidacies/actions';
import { fetchContact } from 'modules/contacts/actions/contacts/index';
import { CONNECTION_TYPE_GROUP_WITH_NAME } from 'services/apiV1/connections';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import DeleteReferenceDialog from './DeleteReferenceDialog';
import ReferenceEditFormV2 from './ReferenceEditFormV2';
import { REFERENCE_TYPES } from './ReferenceFormV2';

const ReferenceViewV2 = ({
  canDelete,
  canEdit,
  reference,
  shouldRenderUpdatedAt,
}) => {
  const dispatch = useDispatch();
  const hasConnectionFeature = useFeatureCheck('feature.connections_gate');
  const [isEditing, showEditing, closeEditing] = useToggle(false);

  const isFullAccessOrAdminUser = useSelector(isEmployeeUser);
  const isCrmUser = useSelector(isCrmUserSelector);

  const handleEditSuccess = () => {
    if (reference.candidacyId) {
      dispatch(invalidateCandidacyStatus({ id: reference.candidacyId }));
    }
    if (reference.contactId) {
      dispatch(fetchContact({ id: reference.contactId }));
    }
    closeEditing();
  };

  return isEditing ? (
    <ReferenceEditFormV2
      contactId={reference?.referee?.id}
      onCancel={closeEditing}
      onSuccess={handleEditSuccess}
      referenceId={reference.id}
    />
  ) : (
    <>
      {shouldRenderUpdatedAt ? (
        <Date date={reference.updatedAt} format='dateTime' />
      ) : null}
      <div className='u-flex u-flexAlign-c u-marginTop-8'>
        {reference?.referee?.id ? (
          <ContactAvatarWithQuickView
            avatarUrl={reference.referee.avatarUrl}
            className='u-marginRight-12'
            connectionsCount={reference.referee.connectionsCount}
            contactId={reference.referee.id}
            contactName={reference.referee.fullName}
            shouldUseQuickView={isFullAccessOrAdminUser || isCrmUser}
          />
        ) : null}
        <div className='u-wordBreak'>
          {reference?.referee?.fullName ? (
            <span className='u-textColor-gray60'>
              {reference.referee.fullName}
            </span>
          ) : null}
          {hasConnectionFeature && reference.connection?.id ? (
            <p className='u-margin-n'>
              {reference.connection.name}
              {CONNECTION_TYPE_GROUP_WITH_NAME.includes(
                reference.connection?.group,
              )
                ? ` ${reference.contactFirstName}`
                : null}
            </p>
          ) : null}
          {!hasConnectionFeature && reference.relationship ? (
            <p className='u-margin-n'>{reference.relationship}</p>
          ) : null}
          {reference.referenceType ? (
            <p className='u-margin-n'>
              {REFERENCE_TYPES[reference.referenceType]}
            </p>
          ) : null}
          <ExpandableText
            characterLimit={100}
            className='u-wordBreak'
            content={reference.description}
            isSanitizedHtml={true}
          />
        </div>
      </div>
      {isEditing ? null : (
        <div className='u-flex u-flexJustify-r'>
          {canEdit && reference.permissions.edit ? (
            <ButtonLink
              className='u-marginRight-8'
              label='Edit'
              onClick={showEditing}
              size='small'
            />
          ) : null}
          {canDelete && reference.permissions.delete ? (
            <DeleteReferenceDialog
              candidacyId={reference.candidacyId}
              referenceId={reference.id}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

ReferenceViewV2.defaultProps = {
  canDelete: true,
  canEdit: true,
  shouldRenderUpdatedAt: true,
};

ReferenceViewV2.propTypes = {
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  reference: PropTypes.shape({
    candidacyId: PropTypes.number,
    connection: PropTypes.shape({
      group: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    contactFirstName: PropTypes.string,
    contactId: PropTypes.number,
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    permissions: PropTypes.shape({
      delete: PropTypes.bool,
      edit: PropTypes.bool,
    }),
    referee: PropTypes.shape({
      avatarUrl: PropTypes.string,
      connectionsCount: PropTypes.number,
      fullName: PropTypes.string,
      id: PropTypes.number,
    }),
    referenceType: PropTypes.oneOf(Object.keys(REFERENCE_TYPES)),
    relationship: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
  shouldRenderUpdatedAt: PropTypes.bool,
};

export default ReferenceViewV2;

import { NAME } from '../../constants';

/**
 * Gets the list state of the comments for a target company.
 * @param {Object} state
 * @param {Number} targetCompanyId The target company ID
 * @return {Immutable.Map} The list state of the comments for the target company.
 */
export default (state, targetCompanyId) =>
  state[NAME].getIn(['commentsByTargetCompany', targetCompanyId]);

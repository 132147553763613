import { convertToCamelCase } from 'modules/core/jsonUtils';
import apiV1 from './index';
import queryTags from './queryTags';

const { SUGGESTED_CONNECTIONS } = queryTags;

const suggestedConnection = apiV1.injectEndpoints({
  endpoints: builder => ({
    getSuggestedConnection: builder.query({
      query: ({ contactId, offset }) => ({
        url: `contacts/suggested_connections`,
        method: 'GET',
        params: { contact_id: contactId, offset: offset },
      }),
      providesTags: [SUGGESTED_CONNECTIONS],
      transformResponse: response => convertToCamelCase(response),
    }),
    dismissSuggestedConnection: builder.mutation({
      query: ({ contactId, userId }) => ({
        url: `contacts/${contactId}/dismissed_suggested_connection`,
        method: 'POST',
        params: { user_id: userId },
      }),
      invalidatesTags: [SUGGESTED_CONNECTIONS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDismissSuggestedConnectionMutation,
  useGetSuggestedConnectionQuery,
} = suggestedConnection;

import { NOTIFICATIONS_INITIALIZE_TOTAL_COUNT } from '../../domains/notifications/constants';

export default function initializeTotalCount({ category, count }) {
  return {
    type: NOTIFICATIONS_INITIALIZE_TOTAL_COUNT,
    payload: {
      category: category,
      count: count,
    },
  };
}

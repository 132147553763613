import getEntity from 'modules/entities/selectors/getEntity';
import meetingSchema from '../schema';

/**
 * Gets an meeting record by it's ID
 * @param {Object} state
 * @param {Number} id The meeting ID
 * @return {Immutable.Map|undefined} The meeting record, if found.
 */

const getMeeting = (state, id) => getEntity(state, meetingSchema.key, id);

export default getMeeting;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';

import FieldState from 'modules/forms/FieldState';
import * as validators from 'modules/forms/validators';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import jobFunctions from 'modules/tenant/schemas/jobFunctions';

import RichTextField from '../../../../components/forms/richtext/RichTextField';
import ContactSelectField from '../ContactSelectField';
import ContactRating from './ContactRating';

/**
 * The field for editing or creating a contact rating + comment
 */
class ContactRatingField extends PureComponent {
  /**
   * Creates a new FieldState that can be passed to this field for rendering.
   * @param {String} [name='rating'] The name of the field
   * @param {Map|Object} initialValues The initial values to populate the field with.
   * @returns {FieldState} A new FieldState that can be passed to this field for rendering.
   */
  static createFieldState(
    name = 'rating',
    { contactId, includeAttributedTo, rating },
  ) {
    const values = fromJS({
      contact_id: contactId,
      score: 0,
      job_function: null,
    }).merge(rating);

    const childFields = [
      FieldState.create(
        'score',
        values.get('score'),
        validators.combineValidators(
          validators.isInteger,
          validators.greaterThan(0),
          validators.requiredField('Score'),
        ),
      ),
      RichTextField.createFieldState('description', values.get('description')),
      TenantOptionsSelectField.createFieldState(
        'job_function',
        values.get('job_function'),
      ),
    ];

    // If this is an existing rating then we should only show the attributed to field only if
    // the rating already has an attributed_to value.
    // Otherwise, for new records, defer to the `includeAttributedToField` we were given.
    const hasAttributedTo = values.get('id')
      ? values.get('attributed_to')
      : includeAttributedTo;

    if (hasAttributedTo) {
      childFields.push(
        ContactSelectField.createFieldState(
          'attributed_to_id',
          values.get('attributed_to'),
          validators.requiredField('On behalf of'),
        ),
      );
    }

    return FieldState.createNested(name, childFields);
  }

  handleRatingChange = score => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedFieldValue('score', score));
  };

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const { fieldState, ...otherProps } = this.props;

    return (
      <div className='ContactRatingField'>
        <ContactRating
          onChange={this.handleRatingChange}
          value={fieldState.getNestedFieldValue('score')}
        />

        {fieldState.getNestedField('attributed_to_id') && (
          <ContactSelectField
            allowCreate={true}
            disabled={otherProps.disabled}
            fieldState={fieldState.getNestedField('attributed_to_id')}
            label='Rated By:'
            onChange={this.handleFieldChange}
            sidebarDisplay={true}
          />
        )}

        <TenantOptionsSelectField
          clearable={true}
          fieldState={fieldState.getNestedField('job_function')}
          key='job_function'
          label='Evaluated For:'
          onChange={this.handleFieldChange}
          placeholder='Select Role'
          schema={jobFunctions}
        />

        <RichTextField
          {...otherProps}
          fieldState={fieldState.getNestedField('description')}
          key='description'
          label='Comment'
          onChange={this.handleFieldChange}
        />
      </div>
    );
  }
}

ContactRatingField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,
};

export default ContactRatingField;

import PropTypes from 'prop-types';
import React from 'react';
import AddContactToNetworkButton from './AddContactToNetworkButton';
import ContactNetworksTable from './ContactNetworksTable';

export const ContactNetworksPanel = ({ contactId }) => (
  <>
    <div className='u-flex u-flexJustify-r u-marginBottom-16'>
      <AddContactToNetworkButton contactId={contactId} />
    </div>
    <ContactNetworksTable contactId={contactId} />
  </>
);

ContactNetworksPanel.propTypes = {
  contactId: PropTypes.number.isRequired,
};

export default ContactNetworksPanel;

import getResidencyList from './getResidencyList';

export default state => {
  const residencyList = getResidencyList(state);

  // Don't fetch if we're already fetching, or if we fetched and got an error.
  if (residencyList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (residencyList.getIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !residencyList.has('ids') || residencyList.getIn(['_meta', 'isInvalidated'])
  );
};

import getEntity from 'modules/entities/selectors/getEntity';
import jobApplicationSchema from '../schema';

/**
 * Gets a job application record by it's ID
 * @param {*} state
 * @param {Number} jobApplicationId
 * @return {Map} The job application record, if found in state.
 */
export default (state, jobApplicationId) =>
  getEntity(state, jobApplicationSchema.key, jobApplicationId);

import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import contactSourceSchema from '../schemas/contactSources';

/**
 * Renders the display name for a given contact source ID.
 */
const ContactSourceName = ({ id }) => (
  <EntityProperty
    entityId={id}
    entityType={contactSourceSchema.key}
    propertyName='name'
  />
);

ContactSourceName.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ContactSourceName;

import { compose, setDisplayName, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import ContactCurrentInterimCompensationField from './ContactCurrentInterimCompensationField';
import withTransactionTrackedForm from '../../../../components/forms/withTransactionTrackedForm';
import * as compensationActions from '../../../compensations/actions';
import { CONTACT_INTERIM_COMPENSATION_NAMES } from './constants';

const createRootFieldState = ({ interimCompensation }) =>
  ContactCurrentInterimCompensationField.createFieldState(
    'contact-current-interim-compensation',
    interimCompensation,
  );

const handleSubmit = (formState, props) => {
  const {
    compensationActions: { createCompensation, updateCompensation },
    contactId,
    interimCompensation,
    interimCompensationName,
  } = props;
  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (
    interimCompensation ||
    fromJS({ contact_id: contactId, name: interimCompensationName })
  ).merge(fieldValue);

  const action = values.get('id') ? updateCompensation : createCompensation;

  action({
    contactId: contactId,
    compensation: values.toJS(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export default compose(
  setDisplayName('ContactCurrentInterimCompensationForm'),
  setPropTypes({
    /**
     * The ID of the interim compensation record.
     */
    interimCompensationId: PropTypes.number,
    /**
     * The ID of the contact this compensation should be associated with. This is
     * required when creating a new compensation
     */
    contactId: PropTypes.number.isRequired,
    /**
     * The interim compensation name type.
     */
    interimCompensationName: PropTypes.oneOf(CONTACT_INTERIM_COMPENSATION_NAMES)
      .isRequired,
  }),
  connectTransactions,
  connect(null, dispatch => ({
    compensationActions: bindActionCreators(compensationActions, dispatch),
  })),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'ContactInterimCompensationForm',
    omitProps: [
      'contactId',
      'formState',
      'onSaved',
      'interimCompensation',
      'compensationActions',
      'interimCompensationName',
      'transactionActions',
    ],
  }),
)(ContactCurrentInterimCompensationField);

import getContactProperty from './getContactProperty';

/**
 * Gets the primary title for a contact (from their primary position record).
 * TODO: Is this being used? I think it should be deprecated and the primary position record
 *   used instead?
 * @param {Object} state
 * @param {Number} id The contact ID
 * @returns {String} The primary title for the contact
 */
export default (state, id) => getContactProperty(state, id, 'primary_title');

import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

/**
 * Provides a connected `candidacyActions` prop which can dispatch candidacy actions.
 */
export default connect(null, dispatch => ({
  candidacyActions: bindActionCreators(actions, dispatch),
}));

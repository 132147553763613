import selectn from 'selectn';
import invalidateCandidacyStatus from 'modules/candidacies/actions/invalidateCandidacyStatus';
import createEntity from 'modules/entities/actions/createEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import { fetchContact } from 'modules/contacts/actions';
import referenceSchema from '../schema';
import getReferenceListUrl from './getReferenceListUrl';
import { PARENT_CANDIDACY, PARENT_CONTACT } from '../constants';
import maybeDispatchNotificationsCreated from '../../../actions/notifications/maybeDispatchNotificationsCreated';

/**
 * An action for creating a reference
 * @param {Object} payload
 * @param {Object} payload.reference The reference record to create
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({
  reference: { referee: refereeId, search, ...referenceProps },
  transactionId,
}) => dispatch => {
  dispatch(
    createEntity({
      body: {
        // This renames `search` to `search_id` before submitting to the server.
        reference: {
          ...referenceProps,
          search_id: search,
          referee_id: refereeId,
        },
      },
      entityType: referenceSchema.key,
      responseSchema: { reference: referenceSchema },
      url: referenceProps.candidacy_id
        ? getReferenceListUrl({
            parentType: PARENT_CANDIDACY,
            parentId: referenceProps.candidacy_id,
          })
        : getReferenceListUrl({
            parentType: PARENT_CONTACT,
            parentId: referenceProps.contact_id,
          }),
      transactionId: transactionId,
    }),
  ).then(action => {
    if (action.type === entityActionTypes.CREATE_SUCCESS) {
      // Ick. This is gross. Temporary measure until we normalize notifications.
      // This dispatches a second action but alters the payload in a way that
      // maybeDispatchNotificationsCreated expects.
      const referenceId = selectn('payload.result.reference', action);
      const reference = selectn(
        `payload.entities.${referenceSchema.key}.${referenceId}`,
        action,
      );
      if (reference) {
        if (reference.candidacy_id) {
          dispatch(
            invalidateCandidacyStatus({
              id: reference.candidacy_id,
            }),
          );
        }
        dispatch(
          maybeDispatchNotificationsCreated(
            {
              type: action.type,
              payload: { data: { reference: reference } },
            },
            'reference',
          ),
        );
        if (reference.contact_id) {
          dispatch(fetchContact({ id: reference.contact_id }));
        }
      }
    }

    return action;
  });
};

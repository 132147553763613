import { branch, compose, lifecycle } from 'recompose';
import connectSearchStageActions from './connectSearchStageActions';

/**
 * An HOC that fetches the rejection reasons list if they need to be
 * fetched so they are available for any underlying component.
 */
export default compose(
  // Connec the actions if they aren't provided already.
  branch(
    ({ searchStageActions }) => !searchStageActions,
    connectSearchStageActions,
  ),

  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.searchStageActions.fetchRejectionReasonsIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.searchStageActions.fetchRejectionReasonsIfNeeded();
    },
  }),
);

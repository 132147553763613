import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import CandidacySelectField from 'modules/candidacies/components/CandidacySelectField';
import ContactMultiSelectField from 'modules/contacts/components/ContactMultiSelectField';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import DateTimeRangeField from 'modules/datetime/components/DateTimeRangeField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import TitleDropdownField from 'modules/forms/components/TitleDropdownField';
import * as validators from 'modules/forms/validators';
import SearchMultiSelectField from 'modules/searches/components/SearchMultiSelectField';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import interviewQuestionSets from 'modules/tenant/schemas/interviewQuestionSets';

import CalendarSelectField from './CalendarSelectField';
import InterviewLabelRadioField from './InterviewLabelRadioField';
import AppointmentMethodSelectField from './AppointmentMethodSelectField';

import {
  TYPE_MEETING,
  TYPE_INTERVIEW_SCHEDULED,
  TYPE_INTERVIEW_SCHEDULING,
  INTERVIEW_PERMITTED_TYPES,
} from '../constants';

import convertFieldStateToRaw from './convertFieldStateToRaw';
import RichTextField from '../../../components/forms/richtext/RichTextField';

/**
 * Identifies the wrapper classes to apply to each field for different "layouts", so that we
 * can arrange fields differently as needed.
 */
const FIELD_LAYOUTS = {
  wide: {
    title: 'col-md-6 col-12',
    label: 'col-md-6 col-12',
    attendees: 'col-md-6 col-12',
    dateTime: 'col-md-6 col-12',
    date: 'col-md-4 col-4',
    errorAlert: 'col-md-12 col-12',
    startTime: 'col-md-4 col-4',
    endTime: 'col-md-4 col-4',
    questionSet: 'col-md-6 col-12',
    contactMethod: 'col-md-6 col-12',
    searches: 'col-md-6 col-12',
    subject: 'col-12',
    notes: 'col-12',
    calendars: 'col-12',
    location: 'col-12',
  },
  narrow: {
    title: 'col-12',
    label: 'col-12',
    attendees: 'col-12',
    dateTime: 'col-12',
    date: 'col-12',
    errorAlert: 'col-12',
    startTime: 'col-6',
    endTime: 'col-6',
    questionSet: 'col-12',
    contactMethod: 'col-12',
    searches: 'col-12',
    subject: 'col-12',
    notes: 'col-12',
    calendars: 'col-12',
    location: 'col-12',
  },
};

const menuOptions = {
  [TYPE_INTERVIEW_SCHEDULED]: {
    label: 'Schedule an Interview',
    value: TYPE_INTERVIEW_SCHEDULED,
  },
  [TYPE_MEETING]: {
    label: 'Schedule a Meeting',
    value: TYPE_MEETING,
  },
  [TYPE_INTERVIEW_SCHEDULING]: {
    label: 'Scheduling an Interview (In Process)',
    value: TYPE_INTERVIEW_SCHEDULING,
  },
};

/**
 *  A field that can be used for creating a new appointment record.
 */
class AppointmentField extends PureComponent {
  static createFieldState(
    name = 'appointment',
    { appointment, recruiterName },
  ) {
    const isScheduling = appointment.get('type') === TYPE_INTERVIEW_SCHEDULING;

    const isInterview = INTERVIEW_PERMITTED_TYPES.includes(
      appointment.get('type'),
    );

    const fieldState = FieldState.createNested(
      name,
      [
        TitleDropdownField.createFieldState(
          'type',
          appointment.get('type') || TYPE_MEETING,
        ),
        InterviewLabelRadioField.createFieldState(
          'label',
          appointment.get('label') || recruiterName,
          isInterview ? validators.requiredField('Interview type') : null,
        ),
        ContactMultiSelectField.createFieldState(
          'attendees',
          appointment.get('attendees') || [],
          validators.requiredField('Attendees', 'are'),
        ),
        AppointmentMethodSelectField.createFieldState(
          'appointment_method_id',
          appointment.getIn(['appointment_method', 'id']),
          validators.requiredField('Method'),
        ),
        TenantOptionsSelectField.createFieldState(
          'question_set_id',
          appointment.get('question_set'),
        ),
        SearchMultiSelectField.createFieldState(
          'searches',
          appointment.get('searches') || [],
        ),
        CandidacySelectField.createFieldState(
          'candidacy_id',
          isInterview && appointment.get('candidacy_id'),
          isInterview ? validators.requiredField('Related search') : null,
        ),
        InputField.createFieldState(
          'subject',
          appointment.get('subject'),
          validators.requiredField('Event Title'),
        ),
        InputField.createFieldState('location', appointment.get('location')),
        RichTextField.createFieldState(
          'description',
          appointment.get('description'),
        ),
        CalendarSelectField.createFieldState(
          'integration_id',
          appointment.get('integration_id'),
        ),
      ],
      null,
      convertFieldStateToRaw,
    );

    if (!isScheduling) {
      return fieldState.setNestedField(
        DateTimeRangeField.createFieldState(
          'dateTimeRange',
          fromJS({
            start_time: appointment.get('start_time'),
            end_time: appointment.get('end_time'),
          }),
        ),
      );
    }

    return fieldState;
  }

  state = {
    dateRangeFieldState: null,
  };

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    const { dateRangeFieldState: currentDateRangeFieldState } = this.state;
    let newFieldState = fieldState.setNestedField(childFieldState);

    if (childFieldState.getName() === 'type') {
      const isScheduling =
        childFieldState.getValue() === TYPE_INTERVIEW_SCHEDULING;
      const wasScheduling =
        fieldState.getNestedFieldValue('type') === TYPE_INTERVIEW_SCHEDULING;
      const isInterview = INTERVIEW_PERMITTED_TYPES.includes(
        childFieldState.getValue(),
      );

      if (isScheduling) {
        // Save the date range field state to be added back into the form if the user
        // changes their mind and selects 'Meeting' or 'Schedule an Interview' again.
        const dateRangeFieldState = fieldState.getNestedField('dateTimeRange');
        this.setState({ dateRangeFieldState: dateRangeFieldState });
        newFieldState = newFieldState.removeNestedField('dateTimeRange');
      } else if (wasScheduling) {
        // Date range is absent, and needs to be added back to the form.
        const dateRangeFieldState =
          currentDateRangeFieldState ||
          DateTimeRangeField.createFieldState('dateTimeRange');
        newFieldState = newFieldState.setNestedField(
          dateRangeFieldState,
          'dateTimeRange',
        );
      }

      /**
       * If the appointment is an interview, make the candidacy_id field required.
       * Remove validation if it's a meeting.
       */
      const candidacyFieldState = newFieldState
        .getNestedField('candidacy_id')
        .setValidator(
          isInterview ? validators.requiredField('Related search') : null,
        );

      newFieldState = newFieldState.setNestedField(candidacyFieldState);

      /**
       * If the appointment is "scheduling", clear any previous value from the calendar
       * fields.
       */
      if (isScheduling) {
        newFieldState = newFieldState.setNestedFieldValue(
          'integration_id',
          null,
        );
      }
    }

    onChange(newFieldState);
  };

  render() {
    const {
      allowRelatedSearchEdit,
      allowRelatedSearchesEdit,
      canClearCalendarSelection,
      contact,
      contactId,
      disableInterviewForm,
      draft,
      excludeCalendarField,
      excludeEventDescriptionField,
      fieldLayout,
      fieldState,
      hasAnswerContent,
      hideLabel,
      isEditing,
      onChange: _onChange,
      onDraftChange,
      permittedTypes,
      showCalendarIntegration,
      ...otherProps
    } = this.props;

    const fieldClasses = FIELD_LAYOUTS[fieldLayout];

    const isScheduling =
      fieldState.getNestedField('type').getValue() ===
      TYPE_INTERVIEW_SCHEDULING;

    const isInterview = INTERVIEW_PERMITTED_TYPES.includes(
      fieldState.getNestedField('type').getValue(),
    );

    return isInterview && disableInterviewForm ? (
      <div className='AppointmentField'>
        <div className='row'>
          {permittedTypes.length > 1 && (
            <div className={fieldClasses.title}>
              <TitleDropdownField
                {...otherProps}
                fieldState={fieldState.getNestedField('type')}
                onChange={this.handleFieldChange}
                options={permittedTypes.map(key => menuOptions[key])}
              />
            </div>
          )}
        </div>
        <div className='AppointmentField__not-available-message'>
          <h3 className='header'>
            {contact.get('full_name')}
            {' is not in a search.'}
          </h3>
          <div className='content'>
            {'To schedule an interview, add '}
            {contact.get('first_name')}
            {' to a search.'}
          </div>
        </div>
      </div>
    ) : (
      <div className='AppointmentField'>
        <div className='row'>
          {permittedTypes.length > 1 && (
            <div className={fieldClasses.title}>
              <TitleDropdownField
                {...otherProps}
                fieldState={fieldState.getNestedField('type')}
                onChange={this.handleFieldChange}
                options={permittedTypes.map(key => menuOptions[key])}
              />
            </div>
          )}
          {isInterview && (
            <div className={fieldClasses.label}>
              <InterviewLabelRadioField
                {...otherProps}
                fieldState={fieldState.getNestedField('label')}
                hideLabel={hideLabel}
                onChange={this.handleFieldChange}
              />
            </div>
          )}
        </div>
        {isInterview && disableInterviewForm ? null : (
          <div className='row'>
            <div className={fieldClasses.subject}>
              <InputField
                {...otherProps}
                fieldState={fieldState.getNestedField('subject')}
                key='subject'
                label='*Event Title'
                onChange={this.handleFieldChange}
                placeholder='Add an Event Title'
              />
            </div>
          </div>
        )}
        <div className='row'>
          <div className={fieldClasses.dateTime}>
            {!isScheduling && (
              <DateTimeRangeField
                {...otherProps}
                dateClass={fieldClasses.date}
                endTimeClass={fieldClasses.endTime}
                fieldState={fieldState.getNestedField('dateTimeRange')}
                onChange={this.handleFieldChange}
                placeholder='Select a date'
                startTimeClass={fieldClasses.startTime}
                wrapperClass='row'
              />
            )}
          </div>
          <div className={fieldClasses.attendees}>
            <ContactMultiSelectField
              {...otherProps}
              allowCreate={true}
              fieldState={fieldState.getNestedField('attendees')}
              label='Attendees'
              noValueText='No attendees selected'
              onChange={this.handleFieldChange}
              placeholder='No attendees Selected'
              summaryLabel='{0} attendees selected'
            />
            <p className='help-block'>
              Attendees will only receive an invite if added to a calendar.
            </p>
          </div>
          <div className='clearfix visible-md-block visible-lg-block' />
          <div className={fieldClasses.contactMethod}>
            <AppointmentMethodSelectField
              {...otherProps}
              clearable={true}
              fieldState={fieldState.getNestedField('appointment_method_id')}
              label='*Method'
              onChange={this.handleFieldChange}
              placeholder='Virtual, Phone Call, et al.'
            />
          </div>
          <div className={fieldClasses.questionSet}>
            <TenantOptionsSelectField
              {...otherProps}
              clearable={true}
              disabled={hasAnswerContent}
              fieldState={fieldState.getNestedField('question_set_id')}
              label='Interview Template'
              onChange={this.handleFieldChange}
              renderIfNoOptions={false}
              schema={interviewQuestionSets}
            />
          </div>
          {(allowRelatedSearchEdit || allowRelatedSearchesEdit) && (
            <div className={fieldClasses.searches}>
              {isInterview && allowRelatedSearchEdit && (
                <CandidacySelectField
                  {...otherProps}
                  className='AppointmentField__relatedSearches'
                  contactId={contactId}
                  fieldState={fieldState.getNestedField('candidacy_id')}
                  onChange={this.handleFieldChange}
                />
              )}
              {!isInterview && allowRelatedSearchesEdit && (
                <SearchMultiSelectField
                  {...otherProps}
                  fieldState={fieldState.getNestedField('searches')}
                  label='Related Searches'
                  onChange={this.handleFieldChange}
                  placeholder='Related to a Search'
                />
              )}
            </div>
          )}
        </div>

        {!(isInterview && disableInterviewForm) && (
          <div className='row'>
            <div className={fieldClasses.location}>
              <InputField
                {...otherProps}
                fieldState={fieldState.getNestedField('location')}
                key='location'
                label='Location'
                onChange={this.handleFieldChange}
                placeholder='Enter a location'
              />
            </div>
          </div>
        )}

        {!excludeEventDescriptionField && (
          <div className='row'>
            <div className={fieldClasses.notes}>
              <RichTextField
                {...otherProps}
                fieldState={fieldState.getNestedField('description')}
                key='description'
                label='Event Description'
                lastUpdatedTime={draft?.lastUpdatedTime}
                onChange={this.handleFieldChange}
                onDraftChange={onDraftChange}
                placeholder='Add a description of event'
              />
            </div>
          </div>
        )}

        {!isScheduling && showCalendarIntegration && !excludeCalendarField && (
          <div className='row'>
            <div className={fieldClasses.calendars}>
              <CalendarSelectField
                {...otherProps}
                canClearCalendarSelection={canClearCalendarSelection}
                fieldState={fieldState.getNestedField('integration_id')}
                isEditing={isEditing}
                key='calendar'
                onChange={this.handleFieldChange}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const propTypes = {
  /**
   * Whether to display and allow the "Related Search" field to be edited.
   */
  allowRelatedSearchEdit: PropTypes.bool,

  /**
   * Whether to display and allow the "Related Searches" field to be edited.
   */
  allowRelatedSearchesEdit: PropTypes.bool,
  canClearCalendarSelection: PropTypes.bool,

  contact: ImmutablePropTypes.mapContains({
    first_name: requiredIf(
      PropTypes.string,
      ({ disableInterviewForm, isInterview }) =>
        isInterview && disableInterviewForm,
    ),
    full_name: requiredIf(
      PropTypes.string,
      ({ disableInterviewForm, isInterview }) =>
        isInterview && disableInterviewForm,
    ),
  }),

  contactId: requiredIf(
    PropTypes.number,
    ({ allowRelatedSearchEdit, isInterview }) =>
      isInterview && allowRelatedSearchEdit,
  ),

  /**
   * Whether to hide the form contents and display a message instead.
   * Normally the form is hidden when a contact has no candidacies in
   * any searches, rendering an interview meaningless.
   */
  disableInterviewForm: PropTypes.bool,
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),

  excludeCalendarField: PropTypes.bool,

  /**
   * When true, the notes field is not rendered
   */
  excludeEventDescriptionField: PropTypes.bool,

  /**
   * How to layout the form fields (which col classes to apply)
   */
  fieldLayout: PropTypes.oneOf(Object.keys(FIELD_LAYOUTS)),

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * When true, this prop will disable the question set select input
   */
  hasAnswerContent: PropTypes.bool,

  hideLabel: PropTypes.bool,
  isEditing: PropTypes.bool,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  onDraftChange: PropTypes.func,

  permittedTypes: PropTypes.arrayOf(PropTypes.string),

  /**
   * Whether to show calendar options - ie whether any calendar integration
   * is enabled at the tenant level.
   */
  showCalendarIntegration: PropTypes.bool,
};

export const defaultProps = {
  allowRelatedSearchEdit: false,
  allowRelatedSearchesEdit: false,
  disableInterviewForm: false,
  draft: null,
  excludeCalendarField: false,
  excludeEventDescriptionField: false,
  fieldLayout: 'narrow',
  hideLabel: false,
  isEditing: false,
};

AppointmentField.propTypes = propTypes;
AppointmentField.defaultProps = defaultProps;

export default AppointmentField;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setStatic } from 'recompose';
import { scrollParent } from '@thrivetrm/ui/utilities/domUtils';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';

/**
 * A field for editing a single time value.
 */
class TimeInputField extends Component {
  /**
   * Creates a new FieldState that can be passed to this field.
   * Defers to the default FieldState.create implementation.
   */
  static createFieldState = FieldState.create;

  /**
   * Called when the input is mounted, allows us to initialize the jquery
   * timepicker plugin on the input.
   * @param  {DOMElement} element The input element's DOM reference
   */
  setInputRef = element => {
    this.inputElement = element;
    const { minuteStep, picker: template } = this.props;

    if (element) {
      const parent = scrollParent(element);
      const $element = $(element);

      $element
        .timepicker({
          icons: {
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
          },
          minuteStep: minuteStep,
          template: template,
          showInputs: false,
          defaultTime: false,
          appendWidgetTo: parent,
        })
        .on('changeTime.timepicker', this.handleTimeChange);

      if (template === 'dropdown' && parent !== document.body) {
        // The stupid timepicker doesn't position the popover widget
        // correctly when an `appendWidgetTo` option is given (it
        // assumes it will be the document body)
        // There's an issue here:
        // https://github.com/jdewit/bootstrap-timepicker/issues/285
        // but they don't seem anyone is doing anything to fix it.
        // This library is pretty buggy, anyway, so we really should
        // consider an alternative.
        // Anyway, this fixes that annoyance.
        $element.on('show.timepicker', () => {
          const $parent = $(parent);
          const $widget = $parent.find('.bootstrap-timepicker-widget');

          // Temporarily hide the widget while we reposition to prevent it
          // quickly being displayed in the wrong position and then jumping
          // over to our fixed position.
          $widget.css('visibility', 'hidden');

          setTimeout(() => {
            // We've got to do this on the next tick because the timepicker
            // widget is position, also, so if we don't, it will override
            // whatever we do here.
            const parentOffset = $parent.offset();
            const elementOffset = $element.offset();
            const isTopOriented = $widget.hasClass('timepicker-orient-bottom');
            let top =
              elementOffset.top - parentOffset.top + $parent.scrollTop();
            const left =
              elementOffset.left - parentOffset.left + $parent.scrollLeft();

            if (isTopOriented) {
              top -=
                $widget.outerHeight() + parseInt($widget.css('padding-top'));
            } else {
              top += $element.outerHeight();
            }

            $widget.css({
              top: top,
              left: left,
              visibility: '',
            });
          });
        });
      }
    }
  };

  /**
   * Called when the selected time is changed.
   * @param  {Event} event The event fired by the timepicker jquery plugin
   */
  handleTimeChange = event => {
    if (this.inputElement) {
      this.inputElement.value = event.time.value;
    }
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setValue(event.time.value));
  };

  handleChange = event => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setValue(event.currentTarget.value));
  };

  render() {
    const {
      fieldState,
      // prevent onChange from being passed through to the input.
      /* eslint-disable no-unused-vars */
      minuteStep,
      onChange,
      picker,
      /* eslint-enable no-unused-vars */
      ...otherProps
    } = this.props;

    return (
      <input
        className='form-control'
        name={fieldState.getName()}
        onChange={this.handleChange}
        ref={this.setInputRef}
        type='text'
        value={fieldState.getValue()}
        {...otherProps}
      />
    );
  }
}

TimeInputField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * optional interval between displayed times. defaults to 5
   */
  minuteStep: PropTypes.number,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The type of picker to display.
   * Technically "modal" is supposed to work here but it's clearly totally broken. The
   * bootstrap-timepicker component hasn't been updated since Jan 2016, and as of this writing,
   * has a whoping 87 outstanding issues. We need to get rid of this ASAP.
   */
  picker: PropTypes.oneOf(['dropdown', false]),
};

TimeInputField.defaultProps = {
  minuteStep: 5,
  picker: false,
};

export default compose(
  setStatic('createFieldState', TimeInputField.createFieldState),
  withFormGroup,
)(TimeInputField);

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  compose,
  componentFromProp,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import identity from '@thrivetrm/ui/utilities/identity';
import getEntityProperty from '../selectors/getEntityProperty';

/**
 * Render the property value of an entity, optionally passing it through
 * a formatter first.
 *
 * @example
 * ```js
 * <EntityProperty
 *   component='div'
 *   entityType='widget'
 *   entityId={42}
 *   propertyName='name'
 *  />
 * ```
 */
export default compose(
  setDisplayName('EntityProperty'),
  setPropTypes({
    /**
     * The type of component to render (default to a <span />)
     */
    component: PropTypes.string.isRequired,

    /**
     * A function that can format or modify the entity property value.
     * By default an identity function is used so the property is
     * rendered as-is, unmodified.
     */
    formatter: PropTypes.func.isRequired,

    /**
     * The ID of the entity
     */
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,

    /**
     * The entity type (i.e. schema key)
     */
    entityType: PropTypes.string.isRequired,

    /**
     * The property name to render (after passing through `formatter`)
     */
    propertyName: PropTypes.string.isRequired,
  }),
  defaultProps({
    formatter: identity,
    component: 'span',
  }),
  connect(
    (state, { entityId, entityType, formatter, propertyName }) => ({
      children: formatter(
        getEntityProperty(state, entityType, entityId, propertyName),
      ),
    }),
    {},
  ),
  mapProps(({ children, className, component, entityType, propertyName }) => ({
    className: classnames(
      'EntityProperty',
      `EntityProperty--${entityType}--${propertyName}`,
      className,
    ),
    children: children,
    component: component,
  })),
)(componentFromProp('component'));

import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import Modal from 'modules/core/componentsLegacy/Modal';
import CancelButton from 'modules/forms/components/CancelButton';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import withAddressForm from 'modules/addresses/components/withAddressForm';
import CompanyAddressField from './CompanyAddressField';
import { PARENT_COMPANY } from '../../constants';

/**
 * A modal for creating or updating an address
 */
const CompanyAddressModalForm = ({
  addressId,
  companyId,
  formState,
  onFieldStateChange,
  onHide,
  onSubmit,
  primaryAddressId,
  show,
  ...props
}) => (
  <Modal
    {...props}
    className='CompanyAddressModalForm'
    onHide={onHide}
    show={show}
  >
    <Modal.Header closeButton={true}>
      <Modal.Title>
        {addressId ? 'Update Address' : 'Create Address'}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='AddressForm form'>
        <div className='AddressForm__form'>
          <CompanyAddressField
            addressId={addressId}
            className='AddressForm__field'
            companyId={companyId}
            disabled={formState.isSubmitting()}
            fieldState={formState.getFieldState()}
            key={formState.getFormStateId()}
            onChange={onFieldStateChange}
            primaryAddressId={primaryAddressId}
            showErrors={formState.wasSubmitted() || 'blurred'}
          />
        </div>
        <FormErrorMessage formState={formState} />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <CancelButton bsSize='lg' onClick={onHide} />
      <SubmitButton bsSize='lg' formState={formState} onClick={onSubmit} />
    </Modal.Footer>
  </Modal>
);

CompanyAddressModalForm.propTypes = {
  /**
   * Called when the user clicks the "done" button or otherwise wants to close the modal
   */
  onHide: PropTypes.func.isRequired,

  /**
   * True if the modal should be displayed, otherwise false.
   */
  show: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('CompanyAddressModalForm(enhanced)'),
  setPropTypes({
    addressId: PropTypes.number,
    companyId: PropTypes.number,
    onHide: CompanyAddressModalForm.propTypes.onHide,
    primaryAddressId: PropTypes.number,
    show: CompanyAddressModalForm.propTypes.show,
  }),
  withHandlers({
    onSaved: ({ onHide, onSaved }) => () => {
      onHide();
      if (onSaved) {
        onSaved();
      }
    },
    onCancel: ({ onHide }) => () => {
      onHide();
    },
  }),
  // add props required by `withAddressForm`
  withProps(({ companyId }) => ({
    parentId: companyId,
    parentType: PARENT_COMPANY,
  })),
  withAddressForm(CompanyAddressField),
  // remove props used by `withAddressForm` that are no longer needed
  mapProps(
    ({
      onFormStateChange: _,
      parentId: _parentId,
      parentType: _parentType,
      ...props
    }) => ({ ...props }),
  ),
)(CompanyAddressModalForm);

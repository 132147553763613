import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import mapContactIdToContact from '../mapContactIdToContact';

/**
 * Renderes a preview of a contact's "career highlights". If the content is large
 * it will initially show a partial version of it, which may be expanded to show
 * the full career highlights.
 */
export default compose(
  setDisplayName('ContactCareerHighlightsView'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  mapProps(({ contact }) => ({
    characterLimit: 300,
    content: contact && contact.get('career_highlights'),
    isSanitizedHtml: true,
  })),
)(ExpandableText);

import getEntity from 'modules/entities/selectors/getEntity';
import { ratingDefinitionSchema } from '../../schema';

/**
 * Gets a rating defintion's description by it's ID
 * @param {*} state
 * @param {Number} id The rating defintion ID.
 * @returns {?String} The description of the rating definition
 */
export default (state, id) => getEntity(state, ratingDefinitionSchema.key, id);

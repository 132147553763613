import { normalize } from 'normalizr';
import { flatten } from 'lodash';
import notificationSchema from 'modules/notifications/schema';
import url from 'modules/routing/url';
import createAjaxAction from '../createAjaxAction';
import {
  BASE_URL as NOTIFICATIONS_URL,
  NOTIFICATIONS_CLEAR,
  NOTIFICATIONS_FETCH_START,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH_FAILURE,
  NOTIFICATIONS_FILTERABLE_TYPES,
} from '../../domains/notifications/constants';

// { rejects: true, notes: false, intros: true } -> ['rejects', 'intros']
const activeTypes = types => Object.keys(types || {}).filter(key => types[key]);

const gatherFilters = typeNames =>
  flatten(
    typeNames.map(typeName => NOTIFICATIONS_FILTERABLE_TYPES[typeName].filter),
  );

const ajaxAction = (notificationParams, ajaxUrl) =>
  createAjaxAction({
    getUrl: () => ajaxUrl,
    start: NOTIFICATIONS_FETCH_START,
    success: NOTIFICATIONS_FETCH_SUCCESS,
    failure: NOTIFICATIONS_FETCH_FAILURE,
    createSuccessPayload: (payload, data) => ({
      ...payload,
      ...normalize(data.notifications || [], [notificationSchema]),
      data: data,
    }),
  })(notificationParams);

const assembleAjaxParams = ({
  before,
  contactId,
  filters: { types, ...filtersRest },
  limit,
}) => ({
  contactId: contactId,
  limit: limit,
  before: before,
  types: gatherFilters(activeTypes(types)),
  ...filtersRest,
});

const clearAction = contactId => dispatch =>
  dispatch({
    type: NOTIFICATIONS_CLEAR,
    payload: { contactId: contactId },
  });

export default notificationParams => {
  const ajaxParams = assembleAjaxParams(notificationParams);

  if (ajaxParams.types.length) {
    return ajaxAction(notificationParams, url(NOTIFICATIONS_URL, ajaxParams));
  }

  return clearAction(notificationParams.contactId);
};

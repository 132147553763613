import shouldFetchEntity from 'modules/entities/selectors/shouldFetchEntity';
import userSchema from '../schema';

/**
 * Determines if a user should be fetched from the server.
 * @param {Object} state
 * @param {Number} contactId The ID of the user
 * @return {Boolean} True if the user needs to be fetched from the server; otherwise,
 *   false
 */
export default (state, userId) =>
  shouldFetchEntity(state, userSchema.key, userId);

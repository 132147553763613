import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Card from '@thrivetrm/ui/components/Card';
import { useApiPost } from '../core/hooks/useApi';
import { toastSuccess } from '../toast-notifications/toastNotificationsSlice';

const ChromeIntegrationPage = ({ extensionUrl, hasExistingToken }) => {
  const dispatch = useDispatch();

  const [wasReauthClicked, setWasReauthClicked] = useState(false);

  const dispatchSuccess = () => {
    const authVerb = wasReauthClicked ? 'authorized' : 're-authorized';
    dispatch(toastSuccess(`Successfully ${authVerb} Chrome Extension`));
  };

  const onTokenChange = response => {
    const customEvent = document.createEvent('CustomEvent');
    customEvent.initCustomEvent('thrive:app', true, true, response);
    document.dispatchEvent(customEvent);
  };

  const [
    generateChromeToken,
    isCreatingChromeToken,
    generateChromeTokenError,
  ] = useApiPost('/api/integrations/chrome_extension/residencies');

  useEffect(() => {
    document.addEventListener('thrive:chrome:received', dispatchSuccess);
    return () => {
      document.removeEventListener('thrive:chrome:received', dispatchSuccess);
    };
  });

  const handleSubmit = ({ shouldGenerateNewToken }) => {
    setWasReauthClicked(shouldGenerateNewToken);
    generateChromeToken(
      { generate_new_token: shouldGenerateNewToken },
      {
        onSuccess: response => {
          onTokenChange(response);
        },
      },
    );
  };

  const handleExtensionRoute = e => {
    e.preventDefault();
    window.location.assign(extensionUrl);
  };

  return (
    <div className='ChromeIntegrationPage'>
      <h2 className='u-marginBottom-16'>Chrome Extension</h2>
      <p>
        Save time and be more productive by downloading the Chrome Extension for
        Thrive TRM!
      </p>
      <p>
        Source Candidates with one click! Go to a person&apos;s LinkedIn or
        Github profile, open up our extension, and click on the &quot;Web
        Profile&quot; button. Avoid duplicates! Our extension recognizes if the
        contact is already in your database and allows you to update the
        appropriate contact.
      </p>
      <p>
        Our extension allows Thrive TRM users to log outreach directly from
        Gmail. Simply click on the &apos;Log in Thrive&apos; button when you
        want the email you are sending to be automatically logged in your
        instance of Thrive TRM.
      </p>

      <button
        className='btn btn-social btn-social-chrome u-marginTop-n thrive-chrome-hidden'
        disabled={isCreatingChromeToken}
        onClick={handleExtensionRoute}
        type='button'
      >
        <i className='fa fa-google_calendar' />
        Add to Chrome
      </button>

      {hasExistingToken ? (
        <p className='thrive-chrome-showing'>
          If you are having issues using your Chrome extension on multiple
          computers, use the “Re-authorize your Chrome extension” button.
        </p>
      ) : null}

      <button
        className='btn btn-social btn-social-chrome u-marginTop-n thrive-chrome-showing chrome-authorize'
        disabled={isCreatingChromeToken}
        onClick={() => handleSubmit({ shouldGenerateNewToken: true })}
        type='button'
      >
        <i className='fa fa-google_calendar' />
        Authorize your Chrome extension
      </button>

      {hasExistingToken ? (
        <button
          className='btn btn-social btn-social-chrome u-marginTop-n thrive-chrome-showing chrome-reauthorize'
          disabled={isCreatingChromeToken}
          onClick={() => handleSubmit({ shouldGenerateNewToken: false })}
          type='button'
        >
          <i className='fa fa-google_calendar' />
          Re-authorize your Chrome extension
        </button>
      ) : null}

      {generateChromeTokenError ? (
        <Card className='u-marginBottom-12' isCentered={false} type='error'>
          <h4 className='u-marginTop-n'>
            There was an error authorizing Chrome Extension.
          </h4>
          {generateChromeTokenError}
        </Card>
      ) : null}
    </div>
  );
};

ChromeIntegrationPage.defaultProps = {
  extensionUrl: '',
  hasExistingToken: false,
};

ChromeIntegrationPage.propTypes = {
  extensionUrl: PropTypes.string,
  hasExistingToken: PropTypes.bool,
};

export default ChromeIntegrationPage;

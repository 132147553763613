import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import getBulkImportProperty from '../selectors/getBulkImportProperty';

const BulkImportSource = ({ bulkImportId }) => {
  const fileName = useSelector(state =>
    getBulkImportProperty(state, bulkImportId, 'file_identifier'),
  );
  return fileName;
};

BulkImportSource.propTypes = {
  bulkImportId: PropTypes.number.isRequired,
};

export default BulkImportSource;

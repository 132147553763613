import {
  compose,
  setDisplayName,
  setPropTypes,
  branch,
  mapProps,
  renderNothing,
} from 'recompose';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import TaskStatusButton from 'modules/tasks/components/TaskStatusButton';

export default compose(
  setDisplayName('NotificationTaskStatusButton'),
  setPropTypes({
    notification: ImmutablePropTypes.shape({
      resource: ImmutablePropTypes.shape({
        data: ImmutablePropTypes.shape({
          id: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }),
  branch(
    ({ notification }) => !(notification.get('type') === 'task'),
    renderNothing,
  ),
  mapProps(({ notification }) => ({
    taskId: notification.getIn(['resource', 'data', 'id']),
  })),
)(TaskStatusButton);

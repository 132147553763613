import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
/* eslint-disable no-restricted-imports */
import ReactTagAutocomplete from 'react-tag-autocomplete';
import Tag from './Tag';

const NON_BREAKING_SPACE = '\u00A0';
const SPACES_PER_INDENT = 8;

const TagSelect = ({
  availableTags,
  className,
  onChange,
  placeholder,
  selectedTags,
}) => {
  const handleTagAdd = tag => {
    const newTags = [...selectedTags, tag];
    onChange(newTags);
  };
  const handleTagDelete = index => {
    const newTags = [...selectedTags];
    newTags.splice(index, 1);
    onChange(newTags);
  };

  // react-tag-autocomplete doesn't support hierarchy or indentation, nor does it allow
  // us to provide our own component for the menu or its items, so we can't use CSS
  // margin/padding to indent items. Instead we can cheat by using non-breaking
  // spaces. Unfortunately this means that very long tags look a little
  // awkward when they wrap lines, but that's the tradeoff we've chosen at this time.

  // if the availableTags are not hierarchical, these functions will be ignored
  const getIndentedLeafName = tagName => {
    const tagParts = tagName.split(' > ');
    return tagParts
      .map((tag, index) =>
        index === tagParts.length - 1
          ? tag
          : NON_BREAKING_SPACE.repeat((index + 1) * SPACES_PER_INDENT),
      )
      .join('');
  };

  const selectedTagIds = selectedTags.map(tag => tag.id);

  const getHierarchyTagSuggestions = () =>
    availableTags.map(tag => ({
      disabled: selectedTagIds.includes(tag.id),
      id: tag.id,
      name: getIndentedLeafName(tag.full_ancestral_name || tag.name),
      full_ancestral_name: tag.full_ancestral_name || tag.name,
      has_children: tag.has_children,
      parent_id: tag.parent_id,
    }));

  return (
    <div>
      <ReactTagAutocomplete
        allowBackspace={false}
        autofocus={false}
        autoresize={false}
        classNames={{
          root: classnames('TagSelect', className),
          rootFocused: 'TagSelect--isFocused',
          selected: 'TagSelect__selected',
          selectedTag: 'TagSelect__selectedTag',
          selectedTagName: 'TagSelect__selectedTagName',
          search: 'TagSelect__search',
          searchInput: 'TagSelect__searchInput',
          suggestions: 'TagSelect__suggestions',
          suggestionActive: 'isActive',
          suggestionDisabled: 'isDisabled',
        }}
        maxSuggestionsLength={availableTags.length}
        minQueryLength={0}
        onAddition={handleTagAdd}
        onDelete={handleTagDelete}
        placeholderText={placeholder}
        suggestions={getHierarchyTagSuggestions()}
        tagComponent={({ onDelete, tag }) => (
          <Tag
            name={tag.name}
            onDelete={onDelete}
            shouldConfirmDelete={false}
          />
        )}
        tags={selectedTags}
      />
    </div>
  );
};

TagSelect.defaultProps = {
  className: null,
};

const tagShape = PropTypes.shape({
  // Some tags use a string instead of integer as id.
  // For example, countries use country code (US, GB, MX) for their id
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
});

TagSelect.propTypes = {
  availableTags: PropTypes.arrayOf(tagShape).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedTags: PropTypes.arrayOf(tagShape).isRequired,
};

export default TagSelect;

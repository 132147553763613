import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import PluralText from '@thrivetrm/ui/components/PluralText';
import Modal from '@thrivetrm/ui/components/Modal';
import Form from '@thrivetrm/ui/components/Form';
import routes from 'modules/routing/routes';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';

const makeSelectMenuSection = (menuOptions, sectionLabel) =>
  menuOptions?.length > 0
    ? [
        <SelectMenu.Heading key={sectionLabel}>
          {sectionLabel}
        </SelectMenu.Heading>,
        ...menuOptions.map(option => (
          <SelectMenu.Item key={option.id} value={option.id}>
            {option.name}
          </SelectMenu.Item>
        )),
      ]
    : [];

const CreateContactsModal = ({ onClose, onConfirm, selectionCount }) => {
  const [loadNetworks, _isLoading, _error, networks] = useApiGet(
    routes.api_v1_networks({ query: { type: 'contact' } }),
  );
  const isCrmUser = useSelector(isCrmUserSelector);
  const hasPipelineFeature = useFeatureCheck('feature.pipeline_mvp');

  useEffect(() => {
    loadNetworks();
  }, [loadNetworks]);

  const [loadStages, _isLoadingStages, _errorStages, stagesList] = useApiGet(
    '/api/stages?type=JobSearchStage',
  );
  const stages = stagesList?.filter(
    stage => stage.user_assignable && stage.allow_as_initial,
  );

  useEffect(() => {
    loadStages();
  }, [loadStages]);

  return (
    <Modal
      // The no clip class is needed to render dropdown options outside the modal
      className='ModalBody--noClip'
      isOpen={true}
      onClose={onClose}
      size='small'
      title='Create New Contacts'
    >
      <Form
        onSubmit={formData => {
          onConfirm(formData);
          onClose();
        }}
      >
        <Modal.Body className='ModalBody--noClip'>
          <p className='u-fontWeight-bold u-textAlign-c u-marginBottom-24 u-textColor-gray60 '>
            <PluralText
              quantity={selectionCount}
              shouldIncludeQuantity={true}
              text='contacts'
            />{' '}
            will be created.
          </p>
          <div className='u-flexItem-grow u-marginBottom-8'>
            <Form.SelectMenu
              inputWidth='full'
              isPinnedRight={true}
              label='Add Contacts to a Network'
              name='network_id'
              placeholder='Select a network'
              size='medium'
            >
              {[
                ...makeSelectMenuSection(
                  networks?.user_networks,
                  'My Networks',
                ),
                ...makeSelectMenuSection(
                  networks?.public_networks,
                  'Shared With Me',
                ),
                ...makeSelectMenuSection(
                  networks?.private_network,
                  'Private Networks (SuperAdmin)',
                ),
              ]}
            </Form.SelectMenu>
          </div>
          {hasPipelineFeature && !isCrmUser ? (
            <div
              className='u-marginBottom-8'
              onKeyDown={e => {
                // This is a hack to prevent the Autocomplete component from submitting
                // the form when a selection is made via the keyboard
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              role='presentation'
            >
              <Form.Autocomplete
                formatOption={option => <div>{option.name}</div>}
                label='Add Contacts to a Pipeline'
                name='pipeline'
                noResultsText='No results found'
                onSelect={() => {}}
                placeholder='Select a pipeline'
                url={query => {
                  if (query) {
                    return routes.api_v1_autocomplete_query({
                      query: {
                        query: {
                          resource: 'pipeline',
                          term: query,
                        },
                      },
                    });
                  }
                  return null;
                }}
              />
            </div>
          ) : null}
          <div className='u-marginBottom-8'>
            {!isCrmUser ? (
              <div
                onKeyDown={e => {
                  // This is a hack to prevent the Autocomplete component from submitting
                  // the form when a selection is made via the keyboard
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                role='presentation'
              >
                <Form.Autocomplete
                  className='u-marginBottom-8'
                  formatOption={option => <div>{option.name}</div>}
                  inputWidth='full'
                  label='Add Contacts to a Search'
                  name='search'
                  onSelect={() => {}}
                  placeholder='Select a search'
                  size='medium'
                  url={query => {
                    if (query) {
                      return routes.api_v1_autocomplete_query({
                        query: {
                          query: {
                            resource: 'search',
                            term: query,
                          },
                        },
                      });
                    }
                    return null;
                  }}
                />
                <Form.FieldDependentContent
                  shouldRender={fields => fields.search}
                >
                  <div className='u-flex u-width-100 u-flexAlign-c'>
                    <Form.SelectMenu
                      className='u-marginBottom-8'
                      initialValue={stages?.find(stage => stage.initial)?.id}
                      inputWidth='full'
                      label='Stage'
                      name='stage_id'
                      size='medium'
                      style={{ width: '250px' }}
                    >
                      {stages?.map(stage => (
                        <SelectMenu.Item key={stage.id} value={stage.id}>
                          {stage.name}
                        </SelectMenu.Item>
                      ))}
                    </Form.SelectMenu>
                    <Form.RadioButtonGroup
                      className='u-marginBottom-8 u-marginLeft-16'
                      initialValue={false}
                      label='Candidate Visibility'
                      name='hidden'
                      options={[
                        { label: 'Visible', value: false },
                        { label: 'Hidden', value: true },
                      ]}
                      size='medium'
                    />
                  </div>
                </Form.FieldDependentContent>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
          <Form.SubmitButton label='Create All' />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CreateContactsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectionCount: PropTypes.number.isRequired,
};

export default CreateContactsModal;

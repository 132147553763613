import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import ContactHeaderForm from './ContactHeaderForm';
import withEditableSection from '../withEditableSection';
import ContactAvatarUploaderButton from './ContactAvatarUploaderButton';
import EditButton from './EditButton';

function CandidateContactHeader({
  clearTransaction,
  companies,
  companyActions,
  contact,
  contactActions,
  contacts,
  duplicateDetectionId,
  isEditing,
  toggleEditing,
  transactions,
  userType,
}) {
  const data = contact.get('data');
  const displayName = data.get('full_name');

  if (!isEditing) {
    return (
      <div className='contact-header'>
        <ContactAvatarUploaderButton
          avatarUrl={data.get('avatar_url')}
          contactId={data.get('id')}
        />

        <div className='contact-information'>
          <h3>
            <a href='#contactNameForm' onClick={toggleEditing}>
              {displayName}
            </a>
            <EditButton onClick={toggleEditing} />
          </h3>
          <h4>{data.get('primary_title')}</h4>
          <h5>{data.get('primary_company_name')}</h5>
        </div>
      </div>
    );
  }

  return (
    <ContactHeaderForm
      clearTransaction={clearTransaction}
      companies={companies}
      companyActions={companyActions}
      contact={contact}
      contactActions={contactActions}
      contacts={contacts}
      duplicateDetectionId={duplicateDetectionId}
      onCancel={toggleEditing}
      onSaved={toggleEditing}
      showEmailFields={false}
      showOffLimitsOptionsFields={false}
      transactions={transactions}
      userType={userType}
    />
  );
}

CandidateContactHeader.propTypes = {
  clearTransaction: PropTypes.func.isRequired,

  companies: ImmutablePropTypes.map.isRequired, // eslint-disable-line react/forbid-prop-types

  companyActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }).isRequired,

  contact: ImmutablePropTypes.mapContains({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,

  contactActions: PropTypes.shape({
    updateContact: PropTypes.func.isRequired,
  }).isRequired,

  contacts: ImmutablePropTypes.map.isRequired, // eslint-disable-line react/forbid-prop-types

  duplicateDetectionId: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,

  toggleEditing: PropTypes.func.isRequired,

  transactions: transactionsState.isRequired,

  userType: PropTypes.string.isRequired,
};

export default withEditableSection(CandidateContactHeader, {
  title: 'Basic Information',
  sectionId: 'header',
  sectionClassName: 'editable-section-contact-header',
});

import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from 'modules/core/components/PageHeader';
import EmailTemplatesTable from 'modules/email-templates/components/emailTemplatesTable/EmailTemplatesTable';
import EmailTemplateFormModalButton from 'modules/email-templates/components/EmailTemplateFormModalButton';

const EmailTemplatesPage = () => (
  <>
    <Helmet>
      <title>Email Templates</title>
    </Helmet>
    <PageHeader title='Email Templates'>
      <EmailTemplateFormModalButton />
    </PageHeader>
    <div className='container u-marginTop-16'>
      <EmailTemplatesTable listId='EmailTemplatesTable' />
    </div>
  </>
);

export default EmailTemplatesPage;

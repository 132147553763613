import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import getCandidacy from 'modules/candidacies/selectors/getCandidacy';
import getCandidacyStage from 'modules/candidacies/selectors/getCandidacyStage';
import getCandidacyHighestStage from 'modules/candidacies/selectors/getCandidacyHighestStage';
import PopoverTrigger from 'modules/core/componentsLegacy/PopoverTrigger';
import CandidacyStageName from '../stages/CandidacyStageName';

/**
 * Renders a table cell which contains a candidacy's current stage. If the stage is rejected,
 * or if the current stage differs from the highest stage reached, hovering on the stage will
 * render a popover displaying the highest stage reached, rejection reason, and rejection comment,
 * as applicable.
 */
const CandidacyStageDetailsCell = ({ candidacyId }) => {
  const candidacy = useSelector(state => getCandidacy(state, candidacyId));
  const candidacyStage = useSelector(state =>
    getCandidacyStage(state, candidacyId),
  );
  const candidacyHighestStage = useSelector(state =>
    getCandidacyHighestStage(state, candidacyId),
  );
  const rejectionComment =
    candidacyStage.get('rejected') && candidacy.get('rejection_comment');
  const rejectionReason =
    candidacyStage.get('rejected') && candidacy.get('rejection_reason');
  const currentAndHighestStageDiffer =
    candidacyHighestStage && candidacyStage !== candidacyHighestStage;
  const shouldRenderPopover =
    rejectionComment || rejectionReason || currentAndHighestStageDiffer;

  return (
    <div
      className={classnames('CandidacyStageDetailsCell', {
        'CandidacyStageDetailsCell--has-popover': shouldRenderPopover,
      })}
    >
      {shouldRenderPopover ? (
        <PopoverTrigger
          content={
            <ul className='CandidacyStagePopover list-unstyled u-margin-n'>
              {currentAndHighestStageDiffer ? (
                <>
                  <li className='u-fontWeight-bold'>Highest Stage Reached</li>
                  <li>{candidacyHighestStage.get('name')}</li>
                </>
              ) : null}
              {rejectionReason ? (
                <>
                  <li
                    className={classnames('u-fontWeight-bold', {
                      'u-marginTop-12': currentAndHighestStageDiffer,
                    })}
                  >
                    Rejection Reason
                  </li>
                  <li>{rejectionReason}</li>
                </>
              ) : null}
              {rejectionComment ? (
                <>
                  <li className='u-fontWeight-bold u-marginTop-12'>
                    Rejection Comment
                  </li>
                  <li>{rejectionComment}</li>
                </>
              ) : null}
            </ul>
          }
          placement='bottom'
          triggers={['focus, hover']}
        >
          <div>
            <CandidacyStageName candidacyId={candidacyId} />
          </div>
        </PopoverTrigger>
      ) : (
        <div>
          <CandidacyStageName candidacyId={candidacyId} />
        </div>
      )}
    </div>
  );
};

CandidacyStageDetailsCell.propTypes = {
  /**
   * The ID of the candidacy record
   */
  candidacyId: PropTypes.number,
};

export default CandidacyStageDetailsCell;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import CandidacyStageDropdown from 'modules/candidacies/components/CandidacyStageDropdown';
import mapCandidacyIdToContactId from 'modules/candidacies/components/mapCandidacyIdToContactId';
import ContactIconLinkList from 'modules/contacts/components/ContactIconLinkList';

/**
 * The "tertiary" details section (only used on "small" card)
 * of the candidacy list item.
 * Renders:
 *   * Move stage button rendered with the current stage name
 *   * Contact icons
 */
const CandidacyListItemSectionDetailsTertiary = ({
  candidacyId,
  className,
  contactId,
  ...props
}) => (
  <div
    className={classnames(className, 'CandidacyListItemSectionDetailsTertiary')}
    {...props}
  >
    <CandidacyStageDropdown candidacyId={candidacyId} />
    <ContactIconLinkList
      contactId={contactId}
      hideWorkPhone={true}
      onlyShowOnCard={true}
    />
  </div>
);

CandidacyListItemSectionDetailsTertiary.propTypes = {
  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * An optional class name to apply to the container div
   */
  className: PropTypes.string,

  /**
   * The ID of the contact that the candidacy belongs to
   */
  contactId: PropTypes.number.isRequired,
};

export default compose(
  setDisplayName('CandidacyListItemSectionDetailsTertiary(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  mapCandidacyIdToContactId,
)(CandidacyListItemSectionDetailsTertiary);

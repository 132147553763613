import { NAME } from '../../constants';

/**
 * Gets the fields last updated for a contact. This currently includes the last
 * updated dates for the preferred compensation, current compensation, and board
 * compensation fields.
 * @param {Object} state
 * @param {Number} id The contact ID
 * @returns {Object} The most recently updated date and corresponding field in key value pairs.
 */

export default (state, id) =>
  state[NAME].getIn(['byId', id, 'fields_last_updated']);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, setDisplayName, setStatic } from 'recompose';

import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import transactionsState from 'modules/transactions/propTypes/transactionsState';

import { TYPE_JOB_SEARCH, SEARCH_TYPES } from '../constants';
import QuerySelect, {
  entityMapGetItemById,
} from '../../../components/forms/QuerySelect';

import withSearchActions from './withSearchActions';
import getById from '../selectors/getById';
import getQueriesById from '../selectors/getQueriesById';
import getSearch from '../selectors/getSearch';

export const SearchSelect = ({
  queriesById,
  restricted,
  searchActions,
  searchType,
  searchesById,
  transactionActions,
  transactions,
  value,
  ...props
}) => (
  <QuerySelect
    clearTransaction={transactionActions.clearTransaction}
    filterOptions={false}
    getItemById={entityMapGetItemById}
    isCreatable={false}
    itemsById={searchesById}
    onCreate={searchActions.createQuery}
    onDestroy={searchActions.destroyQuery}
    onQuery={searchActions.fetchQuery}
    queriesById={queriesById}
    queryParams={{ restricted: restricted, searchType: searchType }}
    transactions={transactions}
    value={value && value.toJS ? value.toJS() : value}
    {...props}
  />
);

SearchSelect.propTypes = {
  queriesById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.map,
    PropTypes.string,
  ).isRequired,

  restricted: PropTypes.bool,

  searchActions: PropTypes.shape({
    createQuery: PropTypes.func.isRequired,
    destroyQuery: PropTypes.func.isRequired,
    fetchQuery: PropTypes.func.isRequired,
  }).isRequired,

  searchesById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.map,
    PropTypes.string,
  ).isRequired,

  searchType: PropTypes.oneOf(SEARCH_TYPES),

  transactionActions: PropTypes.shape({
    clearTransaction: PropTypes.func.isRequired,
  }).isRequired,

  // eslint-disable-next-line react/no-typos
  transactions: transactionsState.isRequired,

  value: PropTypes.oneOfType([
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ]),
};

SearchSelect.defaultProps = {
  restricted: false,
  searchType: TYPE_JOB_SEARCH,
};

export default compose(
  setDisplayName('SearchSelectField'),
  setStatic(
    'createFieldState',
    (name, value, validator, convertToRaw, ...args) =>
      FieldState.create(
        name,
        value,
        validator,
        fieldValue => (fieldValue && fieldValue.id) || fieldValue,
        ...args,
      ),
  ),
  withSearchActions,
  connectTransactions,
  withFormGroup,
  asField(),
  connect((state, { value }) => ({
    queriesById: getQueriesById(state),
    searchesById: getById(state),
    value: typeof value === 'number' ? getSearch(state, value) : value,
  })),
)(SearchSelect);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { onlyUpdateForPropTypes } from 'recompose';
import SortByDropdownButton from 'modules/core/componentsLegacy/SortByDropdownButton';

import ViewTypeSelect from './ViewTypeSelect';
import {
  DASHBOARD_VIEW_TYPES,
  SORT_BY_LABELS,
  SORT_BY_OPTIONS,
} from '../constants';

/**
 * Renders the toolbar displayed above the talent pool listing which shows a filter input
 * text box, sort dropdown, view type toggle, and add talent pool button
 */
export class SortAndFilterToolbar extends Component {
  handleFilterTextChange = event => {
    const { onFilterTextChange } = this.props;
    onFilterTextChange(event.target.value);
  };

  render() {
    const {
      filterPlaceholder,
      filterText,
      isAscending,
      onAddTalentPool,
      onSortChange,
      onViewChange,
      sortBy,
      view,
    } = this.props;

    return (
      <div className='talent-pools--sort-filter-toolbar'>
        <div className='talent-pools--filter'>
          <div className='input-group'>
            <input
              className='form-control'
              onChange={this.handleFilterTextChange}
              placeholder={filterPlaceholder}
              type='text'
              value={filterText}
            />
            <SortByDropdownButton
              className='input-group-btn'
              isAscending={isAscending}
              onChange={onSortChange}
              options={SORT_BY_LABELS}
              value={sortBy}
            />
          </div>
        </div>
        <ViewTypeSelect onChange={onViewChange} value={view} />
        <button
          className='talent-pools--add-btn btn btn-primary btn-round pull-right'
          onClick={onAddTalentPool}
          type='button'
        >
          <i className='fa fa-plus' />
        </button>
      </div>
    );
  }
}

SortAndFilterToolbar.propTypes = {
  /**
   * The placeholder text to display in the filter input
   */
  filterPlaceholder: PropTypes.string,

  /**
   * The current filter text
   */
  filterText: PropTypes.string,

  /**
   * True if sorting in ascending order, false if sorting in descending order.
   */
  isAscending: PropTypes.bool,

  /**
   * Called when the "add talent pool" button is clicked"
   */
  onAddTalentPool: PropTypes.func.isRequired,

  /**
   * Called when the filter text is changed, with the first parameter being the text itself:
   *   `onFilterTextChange(newFilterText)`
   */
  onFilterTextChange: PropTypes.func.isRequired,

  /**
   * Called when the sorting is changed, with the name of the field to sort on.
   */
  onSortChange: PropTypes.func.isRequired,

  /**
   * Called when the selected view type is changed, with the new view type selected.
   */
  onViewChange: PropTypes.func.isRequired,

  /**
   * The current sort by value.
   */
  sortBy: PropTypes.oneOf(SORT_BY_OPTIONS),

  /**
   * The current view type.
   */
  view: PropTypes.oneOf(DASHBOARD_VIEW_TYPES),
};

SortAndFilterToolbar.defaultProps = {
  filterPlaceholder: 'Filter by Talent Pool or Incumbent Name',
};

export default onlyUpdateForPropTypes(SortAndFilterToolbar);

import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import iso8601PropType from '@thrivetrm/ui/propTypes/iso8601PropType';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import LbiOutreachEmailModalButton from 'modules/outreaches/components/LbiOutreachEmailModalButton';
import mapContactIdToContact from '../../mapContactIdToContact';
import ContactGdprStatusForm from './ContactGdprStatusForm';

const ContactGdprStatusEditableSection = ({ contact, lastUpdated }) => (
  <EditableSection
    contactId={contact.get('id')}
    formComponent={ContactGdprStatusForm}
    lastUpdated={lastUpdated}
    title='GDPR Status'
  >
    <div className='ContactGdprStatusEditableSection__content u-flex u-flexAlign-l'>
      <TooltipTrigger
        placement='right'
        tooltip={contact.getIn(['gdpr_status', 'description'])}
      >
        <span className='u-helpText'>
          {contact.getIn(['gdpr_status', 'label'])}
        </span>
      </TooltipTrigger>{' '}
      <span className='ContactGdprStatusEditableSection__lbi_notice u-marginLeft-8'>
        <LbiOutreachEmailModalButton contact={contact} />
      </span>
    </div>
  </EditableSection>
);

ContactGdprStatusEditableSection.propTypes = {
  /**
   * The Contact object
   */
  contact: ImmutablePropTypes.mapContains({
    gdpr_status: ImmutablePropTypes.mapContains({
      description: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  /**
   * The time the record was last updated.
   */
  lastUpdated: PropTypes.shape({
    date: iso8601PropType,
    user: PropTypes.string,
  }),
};

/**
 * Renders an EditableSection that displays and allows editing of
 * a contact's "GDPR Status".
 */
export default compose(
  setDisplayName('ContactGdprStatusEditableSection(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
)(ContactGdprStatusEditableSection);

import getEntity from 'modules/entities/selectors/getEntity';
import { ratingSchema } from '../../schema';

/**
 * Gets a contact rating record by it's ID
 * @param {*} state
 * @param {Number} id The ID of the contact rating record.
 * @returns {?Immutable.Map} The contact rating record.
 */
export default (state, id) => getEntity(state, ratingSchema.key, id);

import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import ContactName from 'modules/contacts/components/ContactName';
import mapCandidacyIdToContactId from './mapCandidacyIdToContactId';

export default compose(
  setDisplayName('CandidacyName'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  mapCandidacyIdToContactId,
)(ContactName);

import getInvestmentList from './getInvestmentList';

/**
 * Determines if an investment list needs to be fetched from the server.
 */
export default (state, { companyId }) => {
  if (!companyId) {
    return false;
  }

  const investmentList = getInvestmentList(state, companyId);

  if (!investmentList) {
    return true;
  }

  if (
    investmentList.getIn(['_meta', 'isFetching']) ||
    investmentList.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return Boolean(investmentList.getIn(['_meta', 'isInvalidated']));
};

import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import { companyAliasSchema } from '../../companySchema';

/**
 * Renders the name property for a company alias
 */
export default compose(
  setDisplayName('CompanyAliasName'),
  setPropTypes({
    companyAliasId: PropTypes.number.isRequired,
  }),
  mapProps(({ companyAliasId, ...rest }) => ({
    ...rest,
    entityType: companyAliasSchema.key,
    entityId: companyAliasId,
    propertyName: 'name',
  })),
)(EntityProperty);

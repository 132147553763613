/**
 * The value that indicates the "overview" layout is selected.
 * Note that this value is sent to the server so must match what the server is expecting.
 */
export const TYPE_OVERVIEW = 'short';

/**
 * The value that indicates the "brief" layout is selected.
 * Note that this value is sent to the server so must match what the server is expecting.
 */
export const TYPE_BRIEF = 'brief';

/**
 * The value that indicates the "overview" layout is selected.
 * Note that this value is sent to the server so must match what the server is expecting.
 */
export const TYPE_EXPANDED = 'long';

/**
 * The value that indicates the "leadership" layout is selected.
 */
export const LAYOUT_LEADERSHIP = 'leadership';

/**
 * The value that indicates the PDF format is selected.
 */
export const FORMAT_PDF = 'pdf';
/**
 * The value that indicates the DOCX format is selected.
 */
export const FORMAT_DOCX = 'docx';

/**
 * The value that indicates the landscape direction is selected.
 */
export const LANDSCAPE_LAYOUT = 'landscape';

/**
 * The value that indicates the portrait direction is selected.
 */
export const PORTRAIT_LAYOUT = 'portrait';

/**
 * The list of all fields to display in the "Candidate Information"
 * section. Note that order matters.
 */
export const CANDIDATE_INFORMATION_FIELDS = [
  'avatar',
  'name',
  'job_title',
  'company',
  'aliases',
  'location',
  'professional_networks',
  'thrive_profile',
  'email',
  'phone',
  'mobile',
  'career_highlights',
  'documents',
  'experience',
  'education',
  'compensation',
  'stage',
  'assessments',
  'interviews',
  'references',
  'comments',
  'resume',
  'recruiter_notes',
];

export const CANDIDATE_INFORMATION_OVERVIEW_FIELDS = [
  'avatar',
  'name',
  'job_title',
  'company',
  'aliases',
  'location',
  'professional_networks',
  'thrive_profile',
  'email',
  'phone',
  'mobile',
  'career_highlights',
  'resume',
];

/**
 * The list of fields from `CANDIDATE_INFORMATION_FIELDS` that should only
 * apply to the "expanded" layout.
 */
export const CANDIDATE_INFORMATION_EXPANDED_FIELDS = [
  'avatar',
  'name',
  'job_title',
  'company',
  'aliases',
  'location',
  'professional_networks',
  'thrive_profile',
  'email',
  'phone',
  'mobile',
  'career_highlights',
  'documents',
  'experience',
  'education',
  'compensation',
  'stage',
  'assessments',
  'interviews',
  'references',
  'comments',
];

export const CANDIDATE_INFORMATION_BRIEF_FIELDS = [
  'avatar',
  'name',
  'job_title',
  'company',
  'aliases',
  'location',
  'professional_networks',
  'thrive_profile',
  'email',
  'phone',
  'mobile',
  'career_highlights',
  'experience',
  'education',
  'compensation',
  'stage',
  'references',
  'resume',
  'recruiter_notes',
];

export const reportTypes = {
  BRIEF: 'brief',
  EXPANDED: 'long',
  OVERVIEW: 'short',
};

export const reportTypeFields = {
  brief: CANDIDATE_INFORMATION_BRIEF_FIELDS,
  long: CANDIDATE_INFORMATION_EXPANDED_FIELDS,
  short: CANDIDATE_INFORMATION_OVERVIEW_FIELDS,
  leadership: [],
};

/**
 * The list of options for the 'Sort By' dropdown menu.
 */
export const SORT_OPTIONS = [
  { label: 'Rank', value: 'rank' },
  { label: 'Name', value: 'name' },
  { label: 'Priority', value: 'priority' },
  { label: 'Company', value: 'company' },
  { label: 'Location', value: 'location' },
  { label: 'Date Added', value: 'date_added' },
  { label: 'Last Contacted', value: 'last_contacted' },
];

export const EXPANDED_SORT_OPTIONS = [
  { label: 'Stage', value: 'stage' },
].concat(SORT_OPTIONS);

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { PARENT_TYPES } from '../constants';
import fetchNoteListActionCreator from '../actions/fetchNoteList';
import getNotesForParent from '../selectors/getNotesForParent';
import getshouldFetchNoteList from '../selectors/shouldFetchNotesForParent';

/**
 * When provided a parentId and parentType via props, fetches the notes
 * for that parent if they need to be fetched.
 */
export default compose(
  setDisplayName('withNoteListFetched'),
  setPropTypes({
    parentId: PropTypes.number,
    parentType: PropTypes.oneOf(PARENT_TYPES),
  }),
  connect(
    (state, { parentId, parentType }) => ({
      shouldFetchNoteList: getshouldFetchNoteList(state, parentType, parentId),
      noteList: getNotesForParent(state, parentType, parentId),
    }),
    dispatch => ({
      fetchNoteList: bindActionCreators(fetchNoteListActionCreator, dispatch),
    }),
  ),
  withHandlers({
    /**
     * A handler for fetching the note list specified by the `parentId` and `parentType`
     * props. This allows the component to simply call `fetchNoteList()` without having to
     * supply params.
     */
    fetchNoteList: ({ fetchNoteList, parentId, parentType }) => () =>
      fetchNoteList({ parentType: parentType, parentId: parentId }),

    /**
     * A handler that, when called, only fetches the note list if needed.
     */
    fetchNoteListIfNeeded: ({
      fetchNoteList,
      parentId,
      parentType,
      shouldFetchNoteList,
    }) => () =>
      shouldFetchNoteList &&
      fetchNoteList({ parentType: parentType, parentId: parentId }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchNoteListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchNoteListIfNeeded();
    },
  }),
);

import routes from 'modules/routing/routes';
import { PARENT_COMPANY, PARENT_CONTACT } from '../constants';

/**
 * Gets a URL for the list of addresses
 * @param {Object} params
 * @param {String} params.parentType The type of parent ("Company", "Contact")
 * @param {Number} params.parentId The parent ID (companyID, contactID)
 */
export default ({ parentId, parentType }) => {
  switch (parentType) {
    case PARENT_COMPANY: {
      return routes.api_v1_company_addresses({ companyId: parentId });
    }
    case PARENT_CONTACT: {
      return routes.api_v1_contact_addresses({ contactId: parentId });
    }
    default: {
      throw new Error(`Unknown parentType for addresses: ${parentType}`);
    }
  }
};

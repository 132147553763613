import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import targetFunctionSchema from '../schemas/targetFunctions';

/**
 * A tag select component for choosing from the list of target functions.
 */

const TargetFunctionsTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField {...props} schema={targetFunctionSchema} />
);

TargetFunctionsTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default TargetFunctionsTagSelectField;

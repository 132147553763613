import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import ModalButton from 'modules/core/componentsLegacy/ModalButton';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import CandidacyAssessmentModal from './CandidacyAssessmentModal';

/**
 * Renders a button that, when clicked, will display the candidacy assessments for a particular
 * contact.
 */
export default compose(
  setDisplayName('CandidacyAssessmentModalButton'),
  setPropTypes({
    /**
     * The ID of the contact whose candidacy assessments should be shown.
     */
    contactId: PropTypes.number.isRequired,

    /**
     * The contents of the button itself.
     */
    children: PropTypes.node.isRequired,
  }),
  withProps({
    modal: CandidacyAssessmentModal,
  }),
  withClassName('CandidacyAssessmentModalButton'),
)(ModalButton);

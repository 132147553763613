import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

/**
 * Connects the meeting actions to the current dispatcher and adds
 * them to props as `meetingActions`
 */
export default connect(null, dispatch => ({
  meetingActions: bindActionCreators(actions, dispatch),
}));

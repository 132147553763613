import moment from 'moment';
import getCompleted from './getCompleted';
import getDueDate from './getDueDate';

/**
 * Determines if a particular task is due today (and not completed)
 * @param {*} state
 * @param {Number} taskId
 * @return {Boolean} True if the task is due today; otherwise, false.
 */
export default (state, taskId) =>
  !getCompleted(state, taskId) &&
  moment(getDueDate(state, taskId)).isSame(moment(), 'd');

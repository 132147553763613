import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import getJobApplicationSortName from '../selectors/getJobApplicationSortName';

/**
 * A Higher order component that sorts a list of jobApplicationIds in order of the applicant's
 * name.
 */
export default compose(
  setDisplayName('withJobApplicationIdsSortedByName'),
  connect(
    (state, { jobApplicationIds }) => ({
      jobApplicationIds:
        jobApplicationIds &&
        jobApplicationIds.sortBy(id => getJobApplicationSortName(state, id)),
    }),
    {},
  ),
);

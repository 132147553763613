/* eslint-disable camelcase */
// ^ accommodate API exchange format
import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import customerConfigurationSchema from '../schemas/customerConfiguration';

/**
 * Updates the customer with details from the modal.
 */
export default ({ customer_configuration, transactionId }) => dispatch => {
  dispatch(
    updateEntity({
      body: { customer: customer_configuration },
      id: customer_configuration.id,
      transactionId: transactionId,
      entityType: customerConfigurationSchema.key,
      responseSchema: { customer_configuration: customerConfigurationSchema },
      url: routes.api_v1_customers_configuration(),
    }),
  );
};

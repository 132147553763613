import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
// eslint-disable-next-line no-restricted-imports
import { components, createFilter } from 'react-select';
import Icon from '@thrivetrm/ui/components/Icon';
import Api from 'modules/core/Api';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import Loader from '@thrivetrm/ui/components/Loader';
import classnames from 'classnames';
import { debounce, isEqual } from 'lodash';
import { stringToSnakeCase } from 'modules/core/jsonUtils';
import {
  registerFilterInput,
  selectHasRegisteredFilterInput,
  setAndValidateSingleFilterValue,
  requestRecordList,
  setFilterInputOptions,
} from '../../recordIndexSlice';
import { EMPTY_STYLES } from './common-react-select-constants';

const DEBOUNCE_INTERVAL_MS = 250;

/** Custom components used to replace the react-select defaults
 * See https://react-select.com/components#replaceable-components
 */
const LoadingIndicator = () => (
  <Loader className='u-marginRight-4' size='small' />
);
const DropdownIndicator = () => <Icon type='dropdown' />;
const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon
        className='u-marginLeft-8 '
        color='blue'
        size='small'
        type='close'
      />
    </components.MultiValueRemove>
  );
};

const Control = props => {
  /**
   * Once AsyncSelect has mounted a changing defaultValue does not updated the select component.
   * For react-select to stay in sync with savedView.filters the library provided `setValue()` prop
   *  is used to update the value.
   * See https://react-select.com/components#defining-components for explanation of issues.
   */
  const { filterInputName, getValue, options, setValue } = props;
  const savedViewValue = useSelector(
    state => state.recordIndex.savedView?.filters?.[filterInputName] || [],
  );

  const isFilterInputSyncedWSavedView = isEqual(
    getValue?.()?.map(option => `${option.value}`),
    savedViewValue?.map(String),
  );

  useEffect(() => {
    if (!isFilterInputSyncedWSavedView) {
      setValue(
        savedViewValue.map(stringValue =>
          options.find(
            option => `${option.value}` === (stringValue?.value || stringValue),
          ),
        ),
        'set-value',
      );
    }
  }, [isFilterInputSyncedWSavedView, options, savedViewValue, setValue]);

  return <components.Control {...props} />;
};

Control.propTypes = {
  filterInputName: PropTypes.string.isRequired,
  getValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  setValue: PropTypes.func.isRequired,
};

const SelectFilter = ({ isAsync, label, name }) => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const hasRegisteredFilterInput = useSelector(state =>
    selectHasRegisteredFilterInput(state, name),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  const { isPairedInput, leadsPair, placeholder, urlFormat } = useSelector(
    state => state.recordIndex.availableFilterInputs[stringToSnakeCase(name)],
  );

  const syncOptions = useSelector(state => {
    const { options } = state.recordIndex.availableFilterInputs[
      stringToSnakeCase(name)
    ];
    return options?.map(option => {
      if (option.value) {
        return option;
      } else if (option?.id) {
        return {
          label: option.name,
          value: option.id,
        };
      } else if (Array.isArray(option)) {
        const [optionLabel, optionValue] = option;
        return {
          label: optionLabel,
          value: optionValue,
        };
      } else {
        return {
          label: option,
          value: option,
        };
      }
    });
  });

  const loadAsyncOptions = query =>
    Api.get(urlFormat?.template?.replace(urlFormat.token, query)).then(
      /**
       * To load options from legacy endpoints we need to wrangle the response into an array of objects with `label` and `value`.
       * For unknown reasons the legacy endpoints return an object with `suggestions` as the top level key with bewildering
       * swapping of the label and value properties.
       */
      rawOptions =>
        rawOptions.suggestions
          ? rawOptions.suggestions.map(suggestion => ({
              label: Number(suggestion.data.id)
                ? suggestion.value
                : suggestion.data.id,
              value: Number(suggestion.data.id)
                ? suggestion.data.id
                : suggestion.value,
            }))
          : rawOptions.map(rawOption => ({
              label: rawOption.name,
              value: rawOption.id,
            })),
    );

  const debouncedLoadAsyncOptions = debounce((query, callback) => {
    loadAsyncOptions(query).then(result => callback(result));
  }, DEBOUNCE_INTERVAL_MS);

  const loadOptions = isAsync
    ? debouncedLoadAsyncOptions
    : () => Promise.resolve(syncOptions);

  const handleChange = (newSelections, _actionType) => {
    setIsMenuOpen(false);
    if (isAsync && newSelections?.length > 0) {
      dispatch(setFilterInputOptions({ name: name, options: newSelections }));
    }
    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: newSelections.map(newSelection => `${newSelection.value}`),
      }),
    );
    return dispatch(requestRecordList());
  };

  const inputId = uniqueId();

  const defaultValue = useSelector(state => {
    const {
      initialSavedValue,
      options,
    } = state.recordIndex.availableFilterInputs[stringToSnakeCase(name)];
    const selectedValue = state.recordIndex?.savedView?.filters[name];

    if (selectedValue || initialSavedValue) {
      if (isAsync) {
        return options?.length ? options : initialSavedValue;
      }
      if (Array.isArray(selectedValue || initialSavedValue)) {
        return (selectedValue?.length
          ? selectedValue
          : initialSavedValue
        )?.map(savedValue =>
          syncOptions.find(option => `${option.value}` === `${savedValue}`),
        );
      } else {
        return syncOptions.find(
          option =>
            `${option.value}` === `${selectedValue || initialSavedValue}`,
        );
      }
    }
    return null;
  });

  const containerClass = classnames('u-marginTop-8', {
    'u-paddingRight-8': isPairedInput && leadsPair,
    'u-paddingLeft-8': isPairedInput && !leadsPair,
    'u-inlineBlock': isPairedInput,
  });

  return (
    <div
      className={containerClass}
      style={{
        width: isPairedInput ? '50%' : '100%',
      }}
    >
      <div className='SelectFilter'>
        {label ? (
          <label className='MultiValueInput__label' htmlFor={inputId}>
            {label}
          </label>
        ) : null}
        <AsyncSelect
          cacheOptions={isAsync}
          classNamePrefix='MultiValueInput'
          components={{
            // eslint-disable-next-line react/jsx-props-no-spreading
            Control: props => <Control {...props} filterInputName={name} />,
            DropdownIndicator: DropdownIndicator,
            LoadingIndicator: LoadingIndicator,
            MultiValueRemove: MultiValueRemove,
          }}
          // TODO: MultiAutocomplete will need to provide defaultOptions which are currently in initialSavedValue
          defaultOptions={isAsync ? defaultValue : true}
          defaultValue={defaultValue}
          /** Manually add default filter config to allow for the fixed options use case to filter 
           as the user types into the input https://react-select.com/advanced#custom-filter-logic */
          filterOption={createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            matchFrom: 'any',
            stringify: option => `${option.label} ${option.value}`,
            trim: true,
          })}
          id={inputId}
          isClearable={false}
          isMulti={true}
          loadOptions={loadOptions}
          menuIsOpen={isMenuOpen}
          onChange={handleChange}
          onMenuClose={() => setIsMenuOpen(false)}
          onMenuOpen={() => setIsMenuOpen(true)}
          placeholder={placeholder}
          styles={EMPTY_STYLES}
        />
      </div>
    </div>
  );
};

SelectFilter.propTypes = {
  isAsync: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

SelectFilter.defaultProps = {
  isAsync: false,
  label: null,
};

export default SelectFilter;

import React from 'react';
import PropTypes from 'prop-types';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Card from '@thrivetrm/ui/components/Card';
import { useGetCandidacyReferencesQuery } from 'services/apiV1/references';
import { useSelector } from 'react-redux';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import AddReferenceFormButton from './AddReferenceFormButton';
import ReferenceViewV2 from './ReferenceViewV2';
import GenerateReference from './GenerateReference';

const ReferencesPanelV2 = ({ candidacyId, contactId, searchId }) => {
  const {
    data: references,
    isFetching: isLoadingReferences,
  } = useGetCandidacyReferencesQuery(candidacyId, { skip: !candidacyId });
  const isFullAccessOrAdminUser = useSelector(isEmployeeUser);
  const hasBackChannelFeature = useFeatureCheck(
    'feature.backchannel_references',
  );

  return (
    <LoadingContainer isLoading={isLoadingReferences}>
      {isFullAccessOrAdminUser && hasBackChannelFeature ? (
        <GenerateReference contactId={contactId} />
      ) : null}
      <AddReferenceFormButton
        candidacyId={candidacyId}
        contactId={contactId}
        searchId={searchId}
      />
      {references?.map(reference => (
        <Card className='u-marginTop-12' isCentered={false} key={reference.id}>
          <ReferenceViewV2 reference={reference} />
        </Card>
      ))}
    </LoadingContainer>
  );
};

ReferencesPanelV2.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
  searchId: PropTypes.number,
};

export default ReferencesPanelV2;

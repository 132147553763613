const capitalize = require('lodash/capitalize');

class ReportModal {
  constructor() {
    this.filters = [];
  }

  checked(selector, fieldName, label) {
    // TODO: separate this into two different functions...`checked` and `multipleChecked`
    if (
      fieldName === 'Interests' ||
      label === 'Relocation Preferences' ||
      fieldName === 'US Visa Required'
    ) {
      const choices = [];
      Array.from($(selector)).forEach(value => {
        choices.push($(value).parent().text());
      });
      const values = choices.join(', ');
      return this.appendFilter(values, fieldName, label);
    }
    return this.appendFilter($(selector).parent().text(), fieldName, label);
  }

  text(selector, fieldName, label) {
    return this.appendFilter($(selector).val(), fieldName, label);
  }

  searchDateRange(dateTypeSelector, options) {
    let field;
    const dateType = $(dateTypeSelector).val();
    if (!dateType) {
      return null;
    }

    if (dateType === 'start') {
      field = 'Start Date';
    } else if (dateType === 'end') {
      field = 'End Date';
    } else if (dateType === 'active') {
      field = 'Active Date';
    } else {
      return null;
    }

    const [start, end] = options[dateType].map(option => $(option).val());
    if (!start || !end) {
      return null;
    }
    const value = `${start} - ${end}`;
    return this.appendFilter(value, field);
  }

  appendRangeFilter(startSelector, endSelector, field, separator = '-') {
    const start = $(startSelector).val();
    const end = $(endSelector).val();
    if (!start || !end) {
      return null;
    }
    const value = `${start} ${separator} ${end}`;
    return this.appendFilter(value, field);
  }

  appendOpenEndedDateRangeFilter(
    startSelector,
    endSelector,
    field,
    separator = '-',
  ) {
    let value;
    let fieldLabel;
    const start = $(startSelector).val();
    const end = $(endSelector).val();
    if (!start && !end) {
      return null;
    }
    if (start && end) {
      value = `${start} ${separator} ${end}`;
      fieldLabel = `${field} between`;
      return this.appendFilter(value, fieldLabel);
    }
    if (!end) {
      value = start;
      fieldLabel = `${field} after`;
      return this.appendFilter(value, fieldLabel);
    }
    value = end;
    fieldLabel = `${field} before`;
    return this.appendFilter(value, fieldLabel);
  }

  array(selector, fieldName, label) {
    let values;
    if ($(selector).length && $(selector).select2('data')) {
      values = $(selector)
        .select2('data')
        .map(item => item.text)
        .filter(Boolean);
    }
    return this.appendFilter(values, fieldName, label);
  }

  appendFilter(value, fieldName, label) {
    const isEmptyArray = Array.isArray(value) && value.length === 0;
    if (!value || isEmptyArray) {
      return;
    }

    this.filters.push({
      value: value,
      fieldName: fieldName,
      label: label || capitalize(fieldName),
    });
  }
}

module.exports = ReportModal;

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes, withState } from 'recompose';
import { connect } from 'react-redux';
import CompensationReportButton from 'modules/compensations/components/report/CompensationReportButton';
import SearchDetailsDocumentList from 'modules/searches/components/SearchDetailsDocumentList';
import SearchCustomReportButton from 'modules/searches/components/searchSummaryReport/SearchCustomReportButton';
import {
  TYPE_JOB_SEARCH,
  SEARCH_TYPES,
  TYPE_PIPELINE,
} from 'modules/searches/constants';
import canEditSearchDetailsSelector from 'modules/searches/selectors/canEditSearchDetails';
import CandidaciesPerStageDonutChart from 'modules/searches/components/candidacyStats/CandidaciesPerStageDonutChart';
import CandidaciesPerStageList from 'modules/searches/components/candidacyStats/CandidaciesPerStageList';
import mapSearchIdToSearchType from 'modules/searches/components/mapSearchIdToSearchType';
import SearchClosedTimeStatsList from 'modules/searches/components/SearchClosedTimeStatsList';
import SearchLocation from 'modules/searches/components/SearchLocation';
import SearchQualifications from 'modules/searches/components/SearchQualifications';
import SearchSidebarLink from 'modules/searches/components/SearchSidebarLink';
import SearchStatusTime from 'modules/searches/components/SearchStatusTime';
import SearchSummary from 'modules/searches/components/SearchSummary';
import SearchSummaryReportButton from 'modules/searches/components/searchSummaryReport/SearchSummaryReportButton';
import SearchTitle from 'modules/searches/components/SearchTitle';
import StagesFilterToggleButton from 'modules/search-stages/components/StagesFilterToggleButton';

import SearchPageSidebarPopover from './SearchPageSidebarPopover';

const DetailsPopover = ({
  activeStagesOnly,
  canEditSearchDetails,
  currentUserEmail,
  onActiveStagesOnlyChanged,
  searchId,
  searchPage,
  searchType,
}) => (
  <SearchPageSidebarPopover
    className='DetailsPopover'
    searchId={searchId}
    searchPage={searchPage}
  >
    <h2>
      {searchType === TYPE_JOB_SEARCH ? 'Search Details ' : 'Details '}
      {canEditSearchDetails && (
        <SearchSidebarLink
          className='u-marginLeft-4'
          searchId={searchId}
          searchPage='edit'
          useDefaultLinkBehavior={true}
        >
          <i className='fa fa-cog' />
        </SearchSidebarLink>
      )}
    </h2>

    <div className='clearfix'>
      <div className='DetailsPopover__documents' key='documents'>
        <SearchDetailsDocumentList searchId={searchId} />
      </div>

      <h3 className='u-textColor-gray50 u-margintop-12 u-marginTop-8'>
        <SearchTitle searchId={searchId} />
      </h3>

      <h4 className='u-textColor-gray40 u-marginVertical-8'>
        <SearchLocation searchId={searchId} />
      </h4>
      {searchType !== TYPE_PIPELINE ? (
        <p className='u-textColor-gray50 u-fontSize-small'>
          <SearchStatusTime searchId={searchId} />
        </p>
      ) : null}
    </div>

    <div className='DetailsPopover__summary'>
      <SearchSummary
        hideIfEmpty={true}
        searchId={searchId}
        searchType={searchType}
      />
    </div>

    <SearchClosedTimeStatsList searchId={searchId} />

    <div className='DetailsPopover__reports u-marginBottom-16' key='reports'>
      {searchType !== TYPE_PIPELINE ? (
        <SearchSummaryReportButton
          currentUserEmail={currentUserEmail}
          searchId={searchId}
        />
      ) : null}
      {searchType === TYPE_JOB_SEARCH ? (
        <SearchCustomReportButton
          currentUserEmail={currentUserEmail}
          searchId={searchId}
        />
      ) : null}
      {searchType !== TYPE_PIPELINE ? (
        <CompensationReportButton
          currentUserEmail={currentUserEmail}
          searchId={searchId}
        />
      ) : null}
    </div>
    <div className='DetailsPopover__candidacies row' key='candidacy-stats'>
      <div className='col-5' key='left'>
        <CandidaciesPerStageDonutChart
          activeOnly={activeStagesOnly}
          searchId={searchId}
        />
        {searchType === TYPE_PIPELINE ? null : (
          <StagesFilterToggleButton
            bsSize='xs'
            onChange={onActiveStagesOnlyChanged}
            value={activeStagesOnly}
          />
        )}
      </div>
      <div className='col-7' key='right'>
        <div className='DetailsPopover__candidacies__legend' key='legend'>
          <CandidaciesPerStageList
            activeOnly={activeStagesOnly}
            searchId={searchId}
          />
        </div>
      </div>
    </div>

    <SearchQualifications hideIfEmpty={true} searchId={searchId} />
  </SearchPageSidebarPopover>
);

DetailsPopover.propTypes = {
  /**
   * Whether the donut chart and list of stages with counts should only show active stages (true),
   * or show all stages (false)
   */
  activeStagesOnly: PropTypes.bool.isRequired,

  /**
   * Whether editing the search details is allowed. This controls the display of the link to the
   * edit page.
   */
  canEditSearchDetails: PropTypes.bool,

  currentUserEmail: PropTypes.string.isRequired,

  /**
   * Called when the `activeStagesOnly` value should be changed.
   */
  onActiveStagesOnlyChanged: PropTypes.func.isRequired,

  /**
   * The ID of the search being displayed.
   */
  searchId: PropTypes.number.isRequired,

  /**
   * The current search page being rendered.
   */
  searchPage: PropTypes.string.isRequired,

  /**
   * The type of search (talent pool or job search)
   */
  searchType: PropTypes.oneOf(SEARCH_TYPES),
};

DetailsPopover.defaultProps = {
  canEditSearchDetails: false,
};

export default compose(
  setDisplayName('DetailsPopover(enhanced)'),
  setPropTypes({
    searchId: PropTypes.number.isRequired,
    searchPage: PropTypes.string.isRequired,
  }),
  withState('activeStagesOnly', 'onActiveStagesOnlyChanged', false),
  mapSearchIdToSearchType,
  connect(state => {
    const currentUserEmail = state.user.get('email');
    const canEditSearchDetails = canEditSearchDetailsSelector(state);
    return {
      currentUserEmail: currentUserEmail,
      canEditSearchDetails: canEditSearchDetails,
    };
  }, {}),
)(DetailsPopover);

/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import LastUpdatedDate from 'modules/core/components/LastUpdatedDate';
import EditIconButton from './EditIconButton';

/**
 * A component for creating a "section" which contains a header and content,
 * which can be toggled into edit mode by clicking on an icon in the header.
 * The edit button is either a plus sign (when the `children` prop is empty),
 * or an edit pencil (when the `children` prop has a value).
 *
 * @example
 * ```js
 *
 * // stuffId will be passed on to the StuffForm when it's rendered, along
 * // with onSaved and onCancel props (i.e.:
 * // <StuffForm stuffId={stuffId} onSaved={...} onCancel={...} />)
 * // StuffView will be rendered as is when in view mode, and not rendered when
 * // in edit mode.
 * <EditableComponent
 *   stuffId={stuffId}
 *   title='Stuff'
 *   formComponent={StuffForm}
 * >
 *   <StuffView someId={someId} />
 * </EditableComponent>
 * ```
 */
const EditableSection = ({
  children,
  className,
  formComponent: FormComponent,
  isEditing,
  isEnriched,
  isFetching,
  lastUpdated,
  onEdit,
  onView,
  readOnly,
  title,
  ...props
}) => (
  <div
    className={classnames(
      'EditableSection',
      {
        'EditableSection--is-editing': isEditing,
        'EditableSection--is-viewing': !isEditing,
        'EditableSection--has-content': Boolean(children),
      },
      className,
    )}
  >
    <h4 className='EditableSection__header'>
      <span className='EditableSection__title'>{title}</span>
      {!isFetching ? (
        <Tooltip
          className='u-marginLeft-8'
          content={
            isEnriched ? (
              <p className='u-margin-n u-fontWeight-normal u-fontSize-small'>
                This field is automatically enriched, and in sync with the
                golden record.
              </p>
            ) : null
          }
          placement='top'
          size='large'
        >
          {isEnriched ? (
            <i className='fa fa-lock u-textColor-gray40' />
          ) : (
            <EditIconButton
              className='EditableSection__toggle btn-link'
              disabled={readOnly || isEditing}
              iconClassName={
                children || isEditing ? 'fa-pencil' : 'fa-plus-circle'
              }
              onClick={onEdit}
            />
          )}
        </Tooltip>
      ) : null}
    </h4>
    <div className='pull-right'>
      {lastUpdated && (
        <LastUpdatedDate
          date={lastUpdated.created_at}
          userEmail={lastUpdated.user_email}
        />
      )}
    </div>
    <div className='EditableSection__content'>
      {isEditing ? (
        <FormComponent onCancel={onView} onSaved={onView} {...props} />
      ) : (
        children
      )}
    </div>
  </div>
);

EditableSection.defaultProps = {
  isEnriched: false,
  isFetching: false,
  readOnly: false,
};

EditableSection.propTypes = {
  /**
   * The content to render in view mode. Pass in null when there is no real content to
   * render and the header should display a "+" icon to edit. Otherwise a pencil icon
   * will be displayed.
   */
  children: PropTypes.node,

  /**
   * An optional outer class name to apply to the section
   */
  className: PropTypes.string,

  /**
   * The form component to render when editing. This form will be given an `onCancel`
   * and `onSaved` prop, which when called will cause the EditableSection to return to
   * view mode.
   * Any additional props not specified by the `EditableSection` will be passed along
   * when rendering the form component.
   */
  formComponent: PropTypes.any.isRequired,

  /**
   * True if the component is in edit mode, false if the component is in view mode.
   * In edit mode the `formComponent` will be rendered, and in view mode the children
   * will be rendered.
   */
  isEditing: PropTypes.bool.isRequired,

  /**
   * True if the section is enriched with the golden record
   * */
  isEnriched: PropTypes.bool,
  /**
   * False if the component still fetching data - so dont render the form.
   * True if component is not fetching data - so do render the form.
   */
  isFetching: PropTypes.bool,

  /**
   * Displays how long ago the section was last updated.
   */
  lastUpdated: PropTypes.shape({
    created_at: PropTypes.string,
    user_email: PropTypes.string,
  }),
  /**
   * Called when the component should switch to edit mode (isEditing should become true)
   */
  onEdit: PropTypes.func.isRequired,

  /**
   * Called when the component should switch to view mode (isEditing should become false)
   */
  onView: PropTypes.func.isRequired,

  /**
   * An optional prop that if set to true will not allow the form
   * to be opened/edited.
   */
  readOnly: PropTypes.bool,

  /**
   * The title of the editable section, shown at the top of the component.
   */
  title: PropTypes.node.isRequired,
};

export default compose(
  setDisplayName('EditableSection(enhanced)'),
  setPropTypes({
    children: PropTypes.node,
    formComponent: PropTypes.any.isRequired,
    lastUpdated: PropTypes.shape({
      created_at: PropTypes.string,
      user_email: PropTypes.string,
    }),
  }),
  withStateHandlers(
    { isEditing: false },
    {
      onEdit: () => () => ({ isEditing: true }),
      onView: () => () => ({ isEditing: false }),
    },
  ),
)(EditableSection);

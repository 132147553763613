import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose, setStatic, withProps } from 'recompose';

import AddressField from 'modules/addresses/components/AddressField';
import CompanySelectField from 'modules/companies/components/CompanySelectField';
import ContactSelectField from 'modules/contacts/components/ContactSelectField';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import jobFunctions from 'modules/tenant/schemas/jobFunctions';
import UserMultiSelect from 'modules/users/components/UserMultiSelect';
import UserSelectField from 'modules/users/components/UserSelectField';
import { FILTER_LEADS } from 'modules/users/constants';

/**
 * A field that can be used for editing an note record, but doesn't render a rich text
 * field for the notes. Should be used with a form component that renders it.
 */
export class TalentPoolField extends PureComponent {
  static createFieldState(name = 'talent_pool', talentPool) {
    const values = fromJS({
      job_title: '',
      job_function: null,
    }).merge(talentPool);

    return FieldState.createNested(
      name,
      [
        ContactSelectField.createFieldState(
          'current_contact',
          values.get('current_contact'),
          validators.requiredField('Current Employee'),
          null,
          true,
        ),
        CompanySelectField.createFieldState(
          'client_company',
          values.get('client_company'),
          validators.requiredField('Company'),
        ),
        InputField.createFieldState(
          'job_title',
          values.get('job_title') || '',
          validators.requiredField('Position'),
        ),
        TenantOptionsSelectField.createFieldState(
          'job_function_category_id',
          values.get('job_function'),
          validators.requiredField('Job function'),
          value => parseInt(value),
        ),
        AddressField.createFieldState('address', values.get('address')),
        UserSelectField.createFieldState(
          'lead_internal_team_member',
          values.get('lead_internal_team_member'),
          validators.requiredField('Lead'),
        ),
        UserMultiSelect.createFieldState(
          'internal_team_members',
          values.get('internal_team_members'),
        ),
        // TODO: lead_recruiter_team_member?
        // TODO: client_team_members?
      ],
      null,

      /**
       * convertToRaw
       * Pulls the address fields up to the main record.
       */
      ({ address, ...fields }) => ({
        ...address,
        ...fields,
      }),
    );
  }

  state = {};

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { contact, fieldState, onChange } = nextProps;
    const {
      client_company_changed: clientCompanyChanged,
      job_title_changed: jobTitleChanged,
    } = this.state;
    let updatedFieldState = fieldState;

    if (contact) {
      if (!jobTitleChanged) {
        const jobTitleField = fieldState.getNestedField('job_title');
        updatedFieldState = updatedFieldState.setNestedField(
          jobTitleField.setValue(contact.get('primary_title')),
        );
      }

      if (!clientCompanyChanged) {
        const clientCompanyField = fieldState.getNestedField('client_company');
        const primaryCompany =
          contact.get('primary_company') || contact.get('primary_company_id');
        updatedFieldState = updatedFieldState.setNestedField(
          clientCompanyField.setValue(primaryCompany),
        );
      }
    }

    if (updatedFieldState !== fieldState) {
      onChange(updatedFieldState);
    }
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;

    // This keeps track of which fields have been chenged, and is then used in
    // `componentWillReceiveProps` so that when a contact is selected we can automatically
    // set their job title and company -- but we only do so if those fields haven't been
    // already manually set.
    this.setState(
      {
        [`${childFieldState.getName()}_changed`]: true,
      },
      () => onChange(fieldState.setNestedField(childFieldState)),
    );
  };

  render() {
    const {
      // prevent props from being passed through to the input.
      /* eslint-disable no-unused-vars */
      contact,
      contactId,
      fieldState,
      onChange,
      /* eslint-enable no-unused-vars */
      ...otherProps
    } = this.props;

    return (
      <div className='talent-pools--talent-pool-field'>
        <div className='row'>
          <div className='col-12'>
            <ContactSelectField
              {...otherProps}
              fieldState={fieldState.getNestedField('current_contact')}
              key='current_contact'
              label='Incumbent'
              onChange={this.handleFieldChange}
              placeholder='Enter Name'
            />
          </div>
          <div className='col-12'>
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('job_title')}
              key='job_title'
              label='Job Title (Talent Pool Name)'
              onChange={this.handleFieldChange}
              placeholder='Enter Talent Pool Name'
            />
          </div>
          <div className='col-12'>
            <CompanySelectField
              {...otherProps}
              fieldState={fieldState.getNestedField('client_company')}
              key='client_company'
              label='Company'
              onChange={this.handleFieldChange}
              placeholder='Enter Company Name'
            />
          </div>
          <div className='col-12'>
            <TenantOptionsSelectField
              fieldState={fieldState.getNestedField('job_function_category_id')}
              key='job_function'
              label='Job Function'
              onChange={this.handleFieldChange}
              placeholder='Select Job Function'
              schema={jobFunctions}
            />
          </div>
          <div className='col-12'>
            <UserSelectField
              className='TalentPoolUserSelect'
              fieldState={fieldState.getNestedField(
                'lead_internal_team_member',
              )}
              filter={FILTER_LEADS}
              key='lead_internal_team_member'
              label='Talent Pool Lead'
              onChange={this.handleFieldChange}
              placeholder='Select Lead'
            />
          </div>
          {/* <div className='col-12'>
            <UserMultiSelect
              fieldState={fieldState.getNestedField('internal_team_members')}
              filter={FILTER_EMPLOYEES}
              key='internal_team_members'
              label='Additional Team Members'
              onChange={this.handleFieldChange}
              summaryLabel='{0} team members selected'
            />
          </div> */}
        </div>
      </div>
    );
  }
}

TalentPoolField.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    primary_title: PropTypes.string,
    primary_company: PropTypes.number,
    primary_company_id: PropTypes.number,
  }),

  contactId: PropTypes.number,

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,
};

export default compose(
  setStatic('createFieldState', TalentPoolField.createFieldState),
  withProps(({ fieldState }) => ({
    contactId: fieldState.getNestedField('current_contact').getValue(),
  })),
  mapContactIdToContact,
)(TalentPoolField);

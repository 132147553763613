import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, withProps, setDisplayName, setPropTypes } from 'recompose';
import { PARENT_CONTACT } from 'modules/assessments/constants';
import withAssessmentListFetched from 'modules/assessments/components/withAssessmentListFetched';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import FakeCandidacyAssessmentNotificationView from './FakeCandidacyAssessmentNotificationView';

/**
 * Renders a list of candidacy assessments, displaying them in the style of our "notifications" /
 * "activity timeline" items.
 */
export const CandidacyAssessmentList = ({
  assessmentIds,
  contactId,
  isFetching,
  readOnly,
}) => (
  <ul className='list-generic CandidacyAssessmentList'>
    {assessmentIds &&
      assessmentIds.map(id => (
        <li key={id}>
          <FakeCandidacyAssessmentNotificationView
            assessmentId={id}
            contactId={contactId}
            readOnly={readOnly}
          />
        </li>
      ))}
    {isFetching && (
      <li key='loading'>
        <LoadingIndicator />
      </li>
    )}
  </ul>
);

CandidacyAssessmentList.propTypes = {
  assessmentIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
  contactId: PropTypes.number,
  isFetching: PropTypes.bool,
  readOnly: PropTypes.bool,
};

CandidacyAssessmentList.defaultProps = {
  readOnly: true,
};

export default compose(
  setDisplayName('CandidacyAssessmentList(enhanced)'),
  setPropTypes({
    /**
     * The ID of the contact to show the assessments for.
     */
    contactId: PropTypes.number.isRequired,
  }),

  // We need to provide `withAssessmentListFetched` a `parentType` value so it knows which
  // assessments to fetch.
  withProps({
    parentType: PARENT_CONTACT,
  }),

  // Instead of the default `parentType` prop, just tell `withAssessmentListFetched` to use
  // the `contactId` prop as the parent ID.
  withAssessmentListFetched({
    parentIdPropName: 'contactId',
  }),

  withProps(({ assessmentList }) => ({
    assessmentIds: assessmentList && assessmentList.get('ids'),
    isFetching: assessmentList && assessmentList.getIn(['_meta', 'isFetching']),
  })),
)(CandidacyAssessmentList);

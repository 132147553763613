import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import mapTaskIdToTask from './mapTaskIdToTask';

const TaskMessage = ({ onClick, showLink, task }) => (
  <div className='task-message'>
    <div className='alert alert-success'>
      <span>Task successfully created! </span>
      {showLink(task) && (
        <button className='btn btn-link' onClick={onClick(task)} type='button'>
          Click here to view
        </button>
      )}
    </div>
  </div>
);

TaskMessage.propTypes = {
  onClick: PropTypes.func,
  showLink: PropTypes.func,
  task: ImmutablePropTypes.shape({
    assigned_to: PropTypes.number,
    created_by: PropTypes.number,
  }).isRequired,
};

export default mapTaskIdToTask(TaskMessage);

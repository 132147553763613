import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Form from '@thrivetrm/ui/components/Form';
import Modal from '@thrivetrm/ui/components/Modal';
import useToggle from '@thrivetrm/ui/hooks/useToggle';

const AddExistingCriteriaButton = ({ criteria, onAdd }) => {
  const [isOpen, openModal, closeModal] = useToggle(false);

  const handleAdd = formState => {
    onAdd(formState.criterion);
    closeModal();
  };

  return (
    <>
      <ButtonSecondary
        icon='add'
        label='Add Existing Criteria'
        onClick={openModal}
      />
      {isOpen ? (
        <Modal
          className='AddExistingCriteriaButton__modal'
          isOpen={true}
          onClose={closeModal}
          size='small'
          title='Add Existing Criteria'
        >
          <Form onSubmit={handleAdd}>
            <Modal.Body className='ModalBody--noClip'>
              <Form.AdvancedSelect
                label='Select Existing Criteria'
                name='criterion'
                options={criteria
                  // exclude the disabled criteria
                  .filter(({ disabled }) => !disabled)
                  .map(({ id, name, search_id: searchId }) => ({
                    label: searchId ? `${name} (Custom)` : name,
                    value: id,
                  }))}
                placeholder='Enter existing criteria...'
                rules={{
                  required: {
                    value: true,
                  },
                }}
                width='full'
              />

              <div className='u-paddingVertical-64'>
                {/* 
                 * This div is here to make sure the modal's height is
                 big enough to clear the autocomplete's options list
                */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ButtonPrimary
                isOutline={true}
                label='Cancel'
                onClick={closeModal}
              />
              <Form.SubmitButton label='Add' />
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

AddExistingCriteriaButton.defaultProps = {
  criteria: [],
};

AddExistingCriteriaButton.propTypes = {
  criteria: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),
  onAdd: PropTypes.func.isRequired,
};

export default AddExistingCriteriaButton;

import getAllMeetingsForParent from './getAllMeetingsForParent';

/**
 * Gets the list of meetings that belong to a particular parent (parent type + id)
 * @param {Object} state
 * @param {String} parentType The parent type
 * @param {Number} parentId The parent ID
 * @return {Immutable.Map|undefined} The meetings state, if it's been created (it won't be created
 *   until an action related to it has been dispatched)
 */
export default (state, parentType, parentId) =>
  getAllMeetingsForParent(state, parentType, parentId);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import EditableSection from 'modules/forms/components/EditableSection';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import CompanyDescriptionForm from './CompanyDescriptionForm';
import mapCompanyIdToCompany from '../mapCompanyIdToCompany';

/**
 * Renders an EditableSection that displays and allows editing of a company's description.
 */
export default compose(
  setDisplayName('CompanyDescriptionEditableSection'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
  withFeatureCheck('feature.skyminyr', 'hasSkyminyrFeature'),
  mapProps(({ company, companyId, hasSkyminyrFeature, isEnriched }) => ({
    companyId: companyId,
    children:
      company && company.get('description') ? (
        <HtmlText htmlString={company.get('description')} />
      ) : null,
    className: 'CompanyDescriptionEditableSection',
    draftStoragePath: {
      company: companyId,
      description: 'content',
    },
    formComponent: CompanyDescriptionForm,
    isEnriched: hasSkyminyrFeature && isEnriched,
    title: 'Description',
  })),
)(EditableSection);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import getName from 'modules/contacts/selectors/contacts/getName';
import getContactProfileUrl from 'modules/contacts/selectors/contacts/getContactProfileUrl';

/**
 * Renders a link containing the name of a contact and a link to their contact profile.
 */
export const IncumbentLink = ({ contactName, contactUrl }) => (
  <a className='talent-pools--incumbent-link' href={contactUrl}>
    {contactName || ' '}
  </a>
);

IncumbentLink.propTypes = {
  /**
   * The name to display as the link content.
   */
  contactName: PropTypes.string,

  contactUrl: PropTypes.string,
};

export default compose(
  setDisplayName('connect(IncumbentLink)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { contactId }) => ({
      contactName: getName(state, contactId),
      contactUrl: getContactProfileUrl(contactId),
    }),
    {},
  ),
  onlyUpdateForPropTypes,
)(IncumbentLink);

import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import React from 'react';
import {
  compose,
  setDisplayName,
  withStateHandlers,
  setPropTypes,
} from 'recompose';
import TabbedHeaderContainer from 'modules/layouts/tabbedHeader/TabbedHeaderContainer';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import SortAndFilterToolbar from '../SortAndFilterToolbar';
import RecommendationsList from '../RecommendationsList';
import getSearchReadyForRecommendations from '../../../searches/selectors/getSearchReadyForRecommendations';
import {
  RECOMMEND_MORE,
  SORT_ASCENDING_DEFAULT,
  SORT_BY_DEFAULT,
  SORT_BY_TYPES,
} from '../../constants';

export const RecommandationsSearchPage = ({
  clearFilterText,
  filterText,
  hasRecommendations,
  isSortAscending,
  onFilterChange,
  onSortChange,
  searchId,
  selectedTab,
  sortBy,
  tabs,
}) => {
  const dispatch = useDispatch();
  return (
    <div className='RecommendationSearchPage__container'>
      <TabbedHeaderContainer
        filter={
          <div className='RecommendationSearchPage__filter'>
            <SortAndFilterToolbar
              filterText={filterText}
              isDisabled={!hasRecommendations}
              isSortAscending={isSortAscending}
              onChange={onFilterChange}
              sortBy={sortBy}
            />

            <OverlayTrigger
              overlay={
                <Tooltip id='Tooltip__recommend__more'>
                  Recommend 10 More
                </Tooltip>
              }
              placement='top'
            >
              <button
                className='btn btn-link RecommendationSearchPage__btnMore'
                disabled={!hasRecommendations}
                onClick={() => {
                  clearFilterText();
                  dispatch({
                    type: RECOMMEND_MORE,
                    searchId: searchId,
                  });
                }}
                type='button'
              >
                <i className='fa fa-plus-circle RecommendationSearchPage__plusIcon' />
              </button>
            </OverlayTrigger>
          </div>
        }
        selectedTab={selectedTab}
        tabs={tabs}
      >
        <RecommendationsList
          filterText={filterText}
          isSortAscending={isSortAscending}
          onSortChange={onSortChange}
          searchId={searchId}
          sortBy={sortBy}
        />
      </TabbedHeaderContainer>
    </div>
  );
};

RecommandationsSearchPage.propTypes = {
  clearFilterText: PropTypes.func.isRequired,
  filterText: PropTypes.string,
  hasRecommendations: PropTypes.bool,
  isSortAscending: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  searchId: PropTypes.number,
  selectedTab: PropTypes.string.isRequired,
  sortBy: PropTypes.oneOf(SORT_BY_TYPES),

  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The unique tab ID. One of these should match the `selectedTab` value
       */
      id: PropTypes.string.isRequired,

      /**
       * The link ccomponent that renders as the tab itself.
       */
      linkComponent: PropTypes.node.isRequired,
      /**
       * The title of the tab, used when the tabs have been collapsed into a dropdown menu to
       * display the name of the selected tab.
       */
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

RecommandationsSearchPage.defaultProps = {
  filterText: '',
  hasRecommendations: false,
  isSortAscending: SORT_ASCENDING_DEFAULT,
  searchId: null,
  sortBy: null,
};

function mapStateToProps(state, ownProps) {
  return {
    hasRecommendations: getSearchReadyForRecommendations(
      state,
      ownProps.searchId,
    ),
  };
}

export default compose(
  setDisplayName('RecommendationsSearchPage(enhanced)'),

  setPropTypes({
    searchId: PropTypes.number,
  }),
  connect(mapStateToProps),
  withStateHandlers(
    {
      filterText: '',
      isSortAscending: SORT_ASCENDING_DEFAULT,
      sortBy: SORT_BY_DEFAULT,
    },
    {
      clearFilterText: () => () => ({ filterText: '' }),
      onFilterChange: () => ({ filterText, isSortAscending, sortBy }) => ({
        filterText: filterText,
        isSortAscending: isSortAscending,
        sortBy: sortBy,
      }),
      onSortChange: () => (sortBy, isSortAscending) => ({
        isSortAscending: isSortAscending,
        sortBy: sortBy,
      }),
    },
  ),
)(RecommandationsSearchPage);

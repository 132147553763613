import React from 'react';
import PropTypes from 'prop-types';
import AboveBoardIntegrationCopyThriveAPI from './AboveBoardIntegrationCopyThriveAPI';

const AboveBoardIntegrationPage = ({ initialAboveBoardToken }) => {
  return (
    <>
      <h2 className='u-marginBottom-16'>AboveBoard + Thrive Integration</h2>
      <p>
        Once configured, this integration will allow you to take candidates from
        AboveBoard and add them into Thrive.
      </p>
      <AboveBoardIntegrationCopyThriveAPI
        initialAboveBoardToken={initialAboveBoardToken}
      />
      <h3 className='u-marginBottom-16'>2. Set up integration in AboveBoard</h3>
      <p>
        Next, use the Thrive API Token above to set up the integration in
        AboveBoard.
      </p>
    </>
  );
};

AboveBoardIntegrationPage.propTypes = {
  initialAboveBoardToken: PropTypes.string,
};

export default AboveBoardIntegrationPage;

import routes from 'modules/routing/routes';
import {
  USER_OPTIONS_FETCH_START,
  USER_OPTIONS_FETCH_SUCCESS,
  USER_OPTIONS_FETCH_FAILURE,
} from './actionTypes';
import createAjaxAction from '../../../actions/createAjaxAction';

export default createAjaxAction({
  getUrl: routes.api_user_options,
  start: USER_OPTIONS_FETCH_START,
  success: USER_OPTIONS_FETCH_SUCCESS,
  failure: USER_OPTIONS_FETCH_FAILURE,
});

import { compose, mapProps } from 'recompose';
import withPositionActions from 'modules/positions/components/withPositionActions';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import mapContactIdToPositionList from './mapContactIdToPositionList';
import PrimaryPositionField from './PrimaryPositionField';
import withTransactionTrackedForm from '../../../../components/forms/withTransactionTrackedForm';

export const createRootFieldState = ({ positionId }) =>
  PrimaryPositionField.createFieldState('primary_position', {
    positionId: positionId,
  });

export const handleSubmit = (formState, props) => {
  const {
    contactId,
    positionActions: { updatePosition },
  } = props;

  const transactionId = uniqueId();
  const positionId = formState.getFieldValue().primary_position_id;

  updatePosition({
    transactionId: transactionId,
    position: {
      id: positionId,
      contact_id: contactId,
      primary: true,
    },
  });

  return formState.startSubmit(transactionId);
};

export const connectForm = compose(
  connectTransactions,
  mapContactIdToPositionList,
  withPositionActions,
);

export default compose(
  connectForm,
  mapProps(({ positionList, ...props }) => ({
    ...props,
    positionIds: positionList && positionList.get('ids'),
  })),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'PrimaryPositionForm',
  }),
)(PrimaryPositionField);

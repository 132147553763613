import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { scrollToElement } from 'modules/core/scrollUtils';
import withTemporaryMessage from 'modules/core/componentsLegacy/withTemporaryMessage';
import JobListingForm from 'modules/job-listings/components/JobListingForm';
import ensureTenantOptionsFetched from 'modules/tenant/components/ensureTenantOptionsFetched';
import { withStateProvider } from './app/StateProvider';

const scrollSuccessMessageIntoView = el => {
  if (el) {
    const scrollContainer = document.querySelector('.content-wrapper');
    scrollToElement({
      element: el,
      scrollContainer: scrollContainer,
    });
  }
};
/**
 * Renders a Job listing edit form. When the job listing is saved, a success message is
 * rendered below the form for ~10 seconds.
 */
const JobListingFormWrapper = ({
  clearSuccessMessage,
  // Prevent this from being passed to the JobListingForm
  setSuccessMessage: _setSuccessMessage,
  successMessage,
  ...props
}) => (
  <div>
    {successMessage && (
      <div
        className='alert alert-success'
        ref={scrollSuccessMessageIntoView}
        role='alert'
      >
        <button
          aria-label='Close'
          className='close'
          onClick={clearSuccessMessage}
          type='button'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
        {successMessage}
      </div>
    )}
    <JobListingForm {...props} />
  </div>
);

JobListingFormWrapper.propTypes = {
  clearSuccessMessage: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
};

export default compose(
  setDisplayName('JobListingFormWrapper(container)'),
  setPropTypes({
    search_id: PropTypes.number.isRequired,
  }),
  withStateProvider,
  mapProps(({ search_id, ...props }) => ({
    ...props,
    searchId: parseInt(search_id),
  })),
  ensureTenantOptionsFetched,
  mapProps(
    ({
      fetchTenantOptions: _fetchTenantOptions,
      hasLoadedTenantOptions: _hasLoadedTenantOptions,
      isFetchingTenantOptions: _isFetchingTenantOptions,
      shouldFetchTenantOptions: _shouldFetchTenantOptions,
      ...rest
    }) => ({
      ...rest,
    }),
  ),
  withTemporaryMessage({
    defaultDelay: 10000,
    messageProp: 'successMessage',
    setMessageProp: 'setSuccessMessage',
  }),
  withHandlers({
    onSaved: ({ setSuccessMessage }) => () => {
      setSuccessMessage('Thrive Apply job listing has been saved');
    },
    clearSuccessMessage: ({ setSuccessMessage }) => () =>
      setSuccessMessage(null),
  }),
)(JobListingFormWrapper);

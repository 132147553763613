import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import Modal from 'modules/core/componentsLegacy/Modal';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import ResidencyField from './ResidencyField';
import ResidencyToken from './ResidencyToken';
import withResidencyForm from './withResidencyForm';

/**
 * A modal that can be used to create residency tokens.
 * Displays a form initially, allowing the user to kick of the creation process by enterting
 * a description for the new token. Once created, the modal will display the new token value.
 */
const ResidencyCreateModalForm = ({
  formState,
  onClose,
  onFieldStateChange,

  onSubmit,
  savedResidencyId,
  show,
  ...fieldProps
}) => (
  <Modal onHide={onClose} show={show}>
    <Form formState={formState} onSubmit={onSubmit}>
      <Modal.Header closeButton={true}>
        <Modal.Title>Generate New Token</Modal.Title>
      </Modal.Header>
      <Modal.Body heap-ignore='true'>
        {savedResidencyId ? (
          <ResidencyToken residencyId={savedResidencyId} />
        ) : (
          <div>
            <ResidencyField
              {...fieldProps}
              disabled={formState.isSubmitting()}
              fieldState={formState.getFieldState()}
              onChange={onFieldStateChange}
              showErrors={formState.wasSubmitted() || 'blurred'}
            />
            <FormErrorMessage formState={formState} />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {savedResidencyId ? (
          <button
            className='btn btn-sm btn-default'
            onClick={onClose}
            type='button'
          >
            Done
          </button>
        ) : (
          <>
            <button
              className='btn btn-sm btn-default'
              onClick={onClose}
              type='button'
            >
              Cancel
            </button>
            <SubmitButton
              className='btn btn-sm btn-primary pull-left'
              formState={formState}
              onClick={onSubmit}
            >
              Generate
            </SubmitButton>
          </>
        )}
      </Modal.Footer>
    </Form>
  </Modal>
);

ResidencyCreateModalForm.propTypes = {
  /**
   * THe form state for the modal's form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the user clicks the cancel button or otherwise wants to close the modal
   */
  onClose: PropTypes.func.isRequired,

  /**
   * Called when the root fieldstate of the formState is changed ((handled by withResidencyForm))
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Called when the form should be submitted (handled by withResidencyForm)
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * The ID of the most recently created residency
   */
  savedResidencyId: PropTypes.number,

  /**
   * True if the modal should be displayed, otherwise false.
   */
  show: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('ResidencyCreateModalForm(enhanced)'),
  setPropTypes({
    /**
     * Called when the modal should be closed.
     */
    onClose: PropTypes.func.isRequired,

    /**
     * Whether the modal should be rendered.
     */
    show: PropTypes.bool.isRequired,
  }),

  withStateHandlers(
    {
      savedResidencyId: null,
    },
    {
      /**
       * Override the provided onClose to first reset the savedResidencyId so if the modal is open
       * again it shows the create form again.
       */
      onClose: (_, { onClose }) => () => {
        onClose();
        return { savedResidencyId: null };
      },
      onSaved: () => savedResidencyId => ({
        savedResidencyId: savedResidencyId,
      }),
    },
  ),

  withResidencyForm,
)(ResidencyCreateModalForm);

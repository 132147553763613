import selectn from 'selectn';
import PropTypes from 'prop-types';
import React from 'react';
import Measure from 'react-measure';
import SearchPageViewLink from 'modules/searches/components/SearchPageViewLink';
import {
  TENANT_TYPE_ENTERPRISE,
  TENANT_TYPE_EXECUTIVE_SEARCH,
  TENANT_TYPE_VENTURE_CAPITAL,
} from 'modules/tenant/constants';
import { useSelector } from 'react-redux';
import getTenantType from 'modules/tenant/selectors/getTenantType';
import TabbedHeaderAction from './TabbedHeaderAction';
import TabbedHeaderTabs from './TabbedHeaderTabs';

/**
 * A container for a layout that has a fixed header containing a set
 * of tabs, actions, and a filter component.
 * The chidren are rendered in a scrollable container.
 * The tabs can be collapsed into a dropdown menu by setting `collapseTabs` to true.
 *
 * This component is used to render a particular "Tab" view on the
 * search page (Candidates, Applicants, Talent Pools, etc).
 *
 * Layout:
 * +-----------------------------------------------------------------------+
 * + [tabs (TabbedHeaderTabs)                              ]  [ actions  ] +  <- Fixed to top
 * + [filter                                                             ] +
 * +-----------------------------------------------------------------------+
 * + [children]                                                            +  <- Content scrolls
 * +                                                                       +
 * +                                                                       +
 * +                                                                       +
 * +                                                                       +
 * +                                                                       +
 * +-----------------------------------------------------------------------+
 */
const TabbedHeaderContainer = ({
  actions,
  children,
  collapseTabsWidth,
  filter,
  selectedTab,
  tabs,
}) => {
  const tenantType = useSelector(getTenantType);
  const canPreviewAsClientUser = useSelector(state =>
    state.user.get('can_preview_as_client_user'),
  );
  const clientPreview = useSelector(state => state.user.get('client_preview'));

  const renderClientPreviewLabel = () => {
    switch (tenantType) {
      case TENANT_TYPE_EXECUTIVE_SEARCH:
        return 'Preview as Client';
      case TENANT_TYPE_ENTERPRISE:
      case TENANT_TYPE_VENTURE_CAPITAL:
        return 'Preview as Hiring Manager';
      default:
        return '';
    }
  };
  return (
    <Measure bounds={true}>
      {({ contentRect, measureRef }) => (
        <div className='TabbedHeaderContainer' ref={measureRef}>
          <div className='TabbedHeaderContainer__header'>
            <div className='TabbedHeaderContainer__header__nav'>
              <TabbedHeaderTabs
                collapseTabs={
                  selectn('bounds.width', contentRect) <= collapseTabsWidth
                }
                selectedTab={selectedTab}
                tabs={tabs}
              />
              {actions && (
                <div className='u-flex TabbedHeaderContainer__actions'>
                  {canPreviewAsClientUser && !clientPreview ? (
                    <TabbedHeaderAction
                      key='client-preview'
                      label={renderClientPreviewLabel()}
                    >
                      <SearchPageViewLink
                        page='client_preview'
                        pageOnly={true}
                        useDefaultLinkBehavior={true}
                      >
                        <i className='fa fa-eye' />
                      </SearchPageViewLink>
                    </TabbedHeaderAction>
                  ) : null}
                  <ul className='TabbedHeaderContainer__actions'>{actions}</ul>
                </div>
              )}
            </div>
            <div className='TabbedHeaderContainer__filters'>{filter}</div>
          </div>
          <div className='TabbedHeaderContainer__content'>{children}</div>
        </div>
      )}
    </Measure>
  );
};

TabbedHeaderContainer.propTypes = {
  /**
   * Optional action buttons/links that should be rendered to the right of the tabs.
   * These MUST render a `<li />` element!
   * The `TabbedHeaderAction` component can be used to provide an action with a tooltip
   */
  actions: PropTypes.node,

  /**
   * The primary page component (example: the candidates list for the "Candidates" tab)
   */
  children: PropTypes.node.isRequired,

  /**
   * The width at which the tabs should be collapased into a dropdown.
   */
  collapseTabsWidth: PropTypes.number,

  /**
   * The filter component that is rendered below the list of tabs, but above the primary
   * page content/`children`.
   */
  filter: PropTypes.node.isRequired,

  /**
   * The currently selected tab (the matching key from the `tabs` property)
   */
  selectedTab: PropTypes.string.isRequired,

  /**
   * An array of the tabs that should be displayed
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The unique tab ID. One of these should match the `selectedTab` value
       */
      id: PropTypes.string.isRequired,

      /**
       * The link ccomponent that renders as the tab itself.
       */
      linkComponent: PropTypes.node.isRequired,

      /**
       * The title of the tab, used when the tabs have been collapsed into a dropdown menu to
       * display the name of the selected tab.
       */
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

TabbedHeaderContainer.defaultProps = {
  collapseTabsWidth: 500,
};

export default TabbedHeaderContainer;

import getCompanyName from 'modules/companies/selectors/getCompanyName';
import getSearchProperty from './getSearchProperty';

/**
 * Gets the name of client company that this search belongs to.
 * @param {*} state
 * @param {*} searchId The ID of the search
 */
const getSearchClientCompanyName = (state, searchId) => {
  const companyId = getSearchProperty(state, searchId, 'client_company');
  return companyId && getCompanyName(state, companyId);
};

export default getSearchClientCompanyName;

import PropTypes from 'prop-types';
import React from 'react';
import {
  branch,
  compose,
  mapProps,
  onlyUpdateForPropTypes,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import moment from 'moment';
import mapSearchIdToSearch from './mapSearchIdToSearch';
import { STATUS_CLOSED } from '../constants';
import SearchClosedTimeStatsListItem from './SearchClosedTimeStatsListItem';

const SearchClosedTimeStatsList = ({
  acceptedDate,
  openDate,
  placedDate,
  startDate,
}) => {
  const openMoment = moment.utc(openDate, moment.ISO_8601);

  if (!openMoment.isValid()) {
    return null;
  }

  return (
    <ul className='SearchClosedTimeStatsList line-item-list'>
      <SearchClosedTimeStatsListItem
        key='start'
        label='Days to Start'
        openMoment={openMoment}
        value={startDate}
      />
      <SearchClosedTimeStatsListItem
        key='accepted'
        label='Days to Fill Position'
        openMoment={openMoment}
        value={acceptedDate}
      />
      <SearchClosedTimeStatsListItem
        key='placed'
        label='Days to Present Placed Candidate'
        openMoment={openMoment}
        value={placedDate}
      />
    </ul>
  );
};

SearchClosedTimeStatsList.propTypes = {
  acceptedDate: PropTypes.string,
  openDate: PropTypes.string,
  placedDate: PropTypes.string,
  startDate: PropTypes.string,
};

export default compose(
  setDisplayName('SearchClosedTimeStatsList(enhanced)'),
  setPropTypes({
    /**
     * The search ID of the search to show the location for.
     */
    searchId: PropTypes.number.isRequired,
  }),

  mapSearchIdToSearch,

  // If this search isn't closed, there's nothing to render.
  branch(
    ({ search }) => !search || search.get('status') !== STATUS_CLOSED,
    renderNothing,
  ),

  // Default to displaying "Opened" status.
  mapProps(({ search }) => ({
    openDate: search.get('start_date'),
    startDate: search.get('candidate_start_date'),
    acceptedDate: search.get('offer_accepted_date'),
    placedDate: search.get('placed_candidacy_created_at'),
  })),
  onlyUpdateForPropTypes,
)(SearchClosedTimeStatsList);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@thrivetrm/ui/components/Card';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import getTenantName from 'modules/tenant/selectors/getTenantName';
import { useSelector } from 'react-redux';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import routes from 'modules/routing/routes';

const GuestAssessmentConsent = ({
  candidacyId,
  changeConsent,
  guestToken,
  isConsentChecked,
}) => {
  const tenantName = useSelector(getTenantName);
  const [
    loadCandidacy,
    isLoadingCandidacy,
    candidacyLoadError,
    candidacyData,
  ] = useApiGet(
    routes.candidacy_assessment_api_v1_candidacy({
      id: candidacyId,
      query: { guest_token: guestToken },
    }),
  );

  useEffect(() => {
    loadCandidacy();
  }, [candidacyId, loadCandidacy]);

  const candidacy = candidacyData?.candidacy;

  return (
    <>
      {candidacyLoadError && !isLoadingCandidacy ? (
        <Card className='u-marginTop-32 u-marginHorizontal-64' type='error'>
          {candidacyLoadError}
        </Card>
      ) : null}
      {candidacy ? (
        <Card
          className='u-marginTop-32 u-textAlign-l u-border-n u-marginHorizontal-64'
          isCentered={false}
        >
          <h3>Guest Assessment for {candidacy.full_name}</h3>
          <p className='u-textColor-gray60 u-marginBottom-n u-marginTop-32'>
            You are about to complete a guest assessment which includes written
            summaries as well as ratings about this candidate.
            <br />
            <br />
            Please note:
          </p>
          <ul className='u-textColor-gray60'>
            <li>This link should not be shared.</li>
            <li>
              This link will expire in 45 days. If you need more time, please
              reach out to your contact on the search.
            </li>
            <li>You can submit more than one assessment if needed.</li>
            <li>
              You will be able to receive a copy of all assessment submissions.
            </li>
            <li>
              If you do not complete the assessment, a draft will be saved
              locally in your browser for up to 45 days or until you submit.
            </li>
          </ul>
          <Checkbox
            className='u-textColor-gray60'
            isChecked={isConsentChecked}
            label={
              <p className='u-marginBottom-n'>
                I accept the Thrive{' '}
                <ButtonLink
                  label='Terms of Service'
                  onClick={() => {
                    window.location.href =
                      'https://thrivetrm.com/terms-and-conditions/';
                  }}
                />{' '}
                and{' '}
                <ButtonLink
                  label='Privacy Policy'
                  onClick={() => {
                    window.location.href =
                      'https://thrivetrm.com/privacy-policy/';
                  }}
                />
                , and consent to Thrive disclosing this assessment to{' '}
                {tenantName}. For questions about how {tenantName} will use and
                disclose this assessment, I will contact {tenantName}.
              </p>
            }
            onChange={changeConsent}
          />
        </Card>
      ) : null}
    </>
  );
};

GuestAssessmentConsent.propTypes = {
  candidacyId: PropTypes.number,
  changeConsent: PropTypes.func,
  guestToken: PropTypes.string,
  isConsentChecked: PropTypes.bool,
};

export default GuestAssessmentConsent;

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchRecommendationsList as fetchRecommendationsListActionCreator } from '../actions';
import getRecommendationsList from '../selectors/getRecommendationsList';
import shouldFetchRecommendationsList from '../selectors/shouldFetchRecommendationsList';
import getSearchReadyForRecommendations from '../../searches/selectors/getSearchReadyForRecommendations';

/**
 * When provided a searchId, fetches the available recommendations for that search if they need to be
 * fetched so they are available for a component.
 */
export default compose(
  setDisplayName('withRecommendationsListFetched'),
  setPropTypes({
    searchId: PropTypes.number,
  }),
  connect(
    (state, { searchId }) => ({
      hasRecommendations: getSearchReadyForRecommendations(state, searchId),
      recommendationsList: getRecommendationsList(state, searchId),
      shouldFetchFromServer: shouldFetchRecommendationsList(state, searchId),
    }),
    dispatch => ({
      fetchRecommendationsList: bindActionCreators(
        fetchRecommendationsListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    /**
     * A handler for fetching the recommendation list specified by the `searchId` prop.
     * This allows the component to simply call `fetchRecommendationsList()` without having to
     * supply params.
     */
    fetchRecommendationsList: ({ fetchRecommendationsList, searchId }) => () =>
      fetchRecommendationsList({ searchId: searchId }),

    /**
     * A handler that, when called, only fetches the recommendation list if needed.
     */
    fetchRecommendationsListIfNeeded: ({
      fetchRecommendationsList,
      hasRecommendations,
      searchId,
      shouldFetchFromServer,
    }) => () =>
      hasRecommendations &&
      shouldFetchFromServer &&
      fetchRecommendationsList({ searchId: searchId }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchRecommendationsListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchRecommendationsListIfNeeded();
    },
  }),
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { branch, compose, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Helmet } from 'react-helmet';
import getDirectReports from 'modules/contacts/selectors/directReports/getDirectReports';
import fetchDirectReports from 'modules/contacts/components/directReports/fetchDirectReports';
import fetchContactIfNeeded from 'modules/contacts/components/fetchContactIfNeeded';
import mapContactIdToFullName from 'modules/contacts/components/mapContactIdToFullName';
import ensureTenantOptionsFetched from 'modules/tenant/components/ensureTenantOptionsFetched';

import getContactId from '../selectors/getContactId';
import getDashboardSelectedContactId from '../selectors/getDashboardSelectedContactId';
import getDashboardSelectedView from '../selectors/getDashboardSelectedView';
import getAllTalentPools from '../selectors/getAllTalentPools';
import { DASHBOARD_VIEW_TYPES, DASHBOARD_VIEW_DEFAULT } from '../constants';

import connectTalentPoolActions from './connectTalentPoolActions';
import DashboardView from './DashboardView';
import fetchAllTalentPoolsIfNeeded from './fetchAllTalentPoolsIfNeeded';
import SortAndFilterToolbar from './SortAndFilterToolbar';
import TalentPoolCardSmall from './TalentPoolCardSmall';
import TalentPoolFormModal from './TalentPoolFormModal';
import TalentPoolsFilters from './TalentPoolsFilters';

export class Dashboard extends PureComponent {
  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      /**
       * Indicates whether the "Add Talent Pool" modal should be shown.
       * When this is a number, is will contain the ID of the contact that should be
       * automatically selected by default in the modal. If it's true, just display the
       * modal should be displayed without selecting an initial contact. If it's false,
       * the modal should not be displayed.
       * @type {Boolean|Number}
       */
      showAddTalentPoolModal: false,

      /**
       * The current text in the filtering toolbar.
       * @type {String}
       */
      filterText: '',

      /**
       * The field to sort on.
       * @type {String}
       */
      sortBy: 'name',

      /**
       * True to sort the sortBy value in ascending order; false for descending order.
       * @type {[type]}
       */
      sortAscending: props.defaultSortAscending,
    };
  }

  handleShowAddTalentPoolModal = event => {
    this.setState({
      showAddTalentPoolModal: parseInt(event.target.value) || true,
    });
  };

  handleHideAddTalentPoolModal = () => {
    this.setState({ showAddTalentPoolModal: false });
  };

  handleIncumbentChange = contactId => {
    const { talentPoolActions, view } = this.props;
    talentPoolActions.navigateToDashboard({ contactId: contactId, view: view });
  };

  handleClearIncumbent = () => {
    const { talentPoolActions, view } = this.props;
    talentPoolActions.navigateToDashboard({ view: view });
  };

  handleClearFilterText = () => {
    this.handleFilterTextChange('');
  };

  handleViewTypeChange = view => {
    const { selectedContactId, talentPoolActions } = this.props;
    talentPoolActions.navigateToDashboard({
      contactId: selectedContactId,
      view: view,
    });
  };

  handleFilterTextChange = filterText => {
    this.setState({ filterText: filterText });
  };

  handleSortChange = sortBy => {
    const { sortAscending, sortBy: currentSortBy } = this.state;
    if (sortBy === currentSortBy) {
      this.setState({ sortAscending: !sortAscending });
    } else {
      const { defaultSortAscending } = this.props;
      this.setState({ sortBy: sortBy, sortAscending: defaultSortAscending });
    }
  };

  render() {
    const {
      collection,
      contactIds,
      fullName,
      selectedContactId,
      view,
    } = this.props;
    const {
      filterText,
      showAddTalentPoolModal,
      sortAscending,
      sortBy,
    } = this.state;
    const isFetching = Boolean(
      collection && collection.getIn(['_meta', 'isFetching']),
    );

    return (
      <div className='talent-pools--dashboard'>
        <Helmet>
          <title>
            {fullName ? `${fullName} - Talent Pool` : 'Talent Pools'}
          </title>
        </Helmet>
        {showAddTalentPoolModal && (
          <TalentPoolFormModal
            contactId={
              typeof showAddTalentPoolModal === 'number'
                ? showAddTalentPoolModal
                : null
            }
            onHide={this.handleHideAddTalentPoolModal}
          />
        )}
        <div className='talent-pools--dashboard-sidebar'>
          <TalentPoolsFilters
            contactId={selectedContactId}
            onChange={this.handleIncumbentChange}
          />
        </div>
        <div className='talent-pools--dashboard-content'>
          <SortAndFilterToolbar
            filterText={filterText}
            isAscending={sortAscending}
            onAddTalentPool={this.handleShowAddTalentPoolModal}
            onFilterTextChange={this.handleFilterTextChange}
            onSortChange={this.handleSortChange}
            onViewChange={this.handleViewTypeChange}
            sortBy={sortBy}
            view={view}
          />
          {Boolean(selectedContactId) && (
            <div className='talent-pools--dashboard-incumbent-header'>
              <TalentPoolCardSmall
                contactId={selectedContactId}
                onCreateTalentPool={this.handleShowAddTalentPoolModal}
              />
            </div>
          )}
          <DashboardView
            contactIds={contactIds}
            filterText={filterText}
            isFetching={isFetching}
            onClearFilterText={this.handleClearFilterText}
            onClearSelectedContact={this.handleClearIncumbent}
            onCreateTalentPool={this.handleShowAddTalentPoolModal}
            onSortChange={this.handleSortChange}
            sortAscending={sortAscending}
            sortBy={sortBy}
            view={view}
          />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  /**
   * The collection that the current result set is based on.
   * This will either be allTalentPools, or a specific contact's direct reports.
   * Either way it may contain some _meta data and a list of IDs (when fetched)
   */
  collection: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),

  contactIds: ImmutablePropTypes.listOf(PropTypes.number),

  /**
   * Whether sorting is done in ascending order by default (true), or descending by default (false)
   */
  defaultSortAscending: PropTypes.bool,

  /**
   * If there is a selectedContactId, their full name (so the page title can be set appropriately)
   * This is supplied by `mapContactIdToFullName` from the enhanced component.
   */
  fullName: PropTypes.string,

  /**
   * The ID of the incumbent that is currently select and being filtered on. When provided,
   * only the direct reports for this contact will be shown.
   */
  selectedContactId: PropTypes.number,

  /**
   * The actions for navigating to a different dashboard view.
   */
  talentPoolActions: PropTypes.shape({
    navigateToDashboard: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * The current dashboard view type (DASHBOARD_VIEW_TABLE or DASHBOARD_VIEW_CARDS)
   */
  view: PropTypes.oneOf(DASHBOARD_VIEW_TYPES),
};

Dashboard.defaultProps = {
  defaultSortAscending: true,
  view: DASHBOARD_VIEW_DEFAULT,
};

export default compose(
  setDisplayName('connect(Dashboard)'),
  connectTalentPoolActions,
  ensureTenantOptionsFetched,
  connect(state => {
    const selectedContactId = getDashboardSelectedContactId(state);
    const allTalentPools = !selectedContactId ? getAllTalentPools(state) : null;
    const directReports = selectedContactId
      ? getDirectReports(state, selectedContactId)
      : null;

    return {
      collection: directReports || allTalentPools,

      // fetchContactIfNeeded expects a `contactId` prop.
      contactId: selectedContactId,

      // When there is a selected contact, the contactIds are dictated by the direct reports
      // of that contact. When there is no contact selected, they are dictated by the list of
      // all talent pools (so we map grab the underyling contact ID for each talent pool in that
      // list)
      contactIds: directReports
        ? directReports.get('ids')
        : allTalentPools &&
          allTalentPools.get('ids') &&
          allTalentPools.get('ids').map(id => getContactId(state, id)),

      selectedContactId: selectedContactId,

      view: getDashboardSelectedView(state),
    };
  }, {}),
  mapContactIdToFullName,
  fetchContactIfNeeded({ fields: ['direct_reports', 'talent_pool'] }),
  branch(
    ({ selectedContactId }) => !selectedContactId,
    fetchAllTalentPoolsIfNeeded,
    fetchDirectReports({ fields: ['talent_pool'] }),
  ),
)(Dashboard);

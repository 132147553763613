import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, mapProps } from 'recompose';

export default compose(
  setDisplayName('TelephoneLink'),
  setPropTypes({
    /**
     * The telephone number to link to.
     */
    number: PropTypes.string.isRequired,
  }),
  mapProps(({ number, ...rest }) => ({
    ...rest,
    href: `tel:${number}`,
  })),
)('a');

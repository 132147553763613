import getUserPermission from 'modules/auth/selectors/getUserPermission';

/**
 * Gets a value indicating whether the current user is allowed to only view their assigned
 * searches (that is, they are a "limited access" user").
 * @param {Object} state
 * @return {Boolean} True if the current user is only allowed to view their assigned searches;
 *   otherwise, false if they are allowed to view all searches.
 */
export default state =>
  getUserPermission(state, 'can_only_view_assigned_searches', true);

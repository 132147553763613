import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import ScrollableContainer from '@thrivetrm/ui/components/ScrollableContainer';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import {
  useDeleteDocummentMutation,
  useGetDocumentsQuery,
} from 'services/apiV1/documents';
import Tag from 'modules/core/components/Tag';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import {
  toastSuccess,
  toastError,
} from 'modules/toast-notifications/toastNotificationsSlice';
import EditDocument from './EditDocument';
import { OwnerTypes } from '../documentShape';

const DocumentList = ({ documentTypes, ownerId, ownerType }) => {
  const dispatch = useDispatch();
  const [editDocumentID, setEditDocumentID] = useState(null);

  const [deleteDocument] = useDeleteDocummentMutation();

  const {
    data: documents,
    isFetching: isDocumentsLoading,
  } = useGetDocumentsQuery({
    recordId: ownerId,
    recordType: pluralize(ownerType),
  });

  const handleDeleteConfirm = id => {
    deleteDocument({
      documentId: id,
      recordId: ownerId,
      recordType: pluralize(ownerType),
    })
      .unwrap()
      .then(() => {
        dispatch(toastSuccess('Successfully deleted document'));
      })
      .catch(() => {
        dispatch(toastError('An error has occurred!'));
      });
  };

  const handleEdit = id => {
    setEditDocumentID(id);
  };
  return (
    <LoadingContainer isLoading={isDocumentsLoading}>
      {documents?.length ? (
        <>
          <h3>Offer Documents</h3>
          <ScrollableContainer maxHeight='32vh'>
            <div className='u-paddingBottom-16'>
              {documents?.map(document =>
                editDocumentID === document.id ? (
                  <EditDocument
                    document={document}
                    documentTypes={documentTypes}
                    key={document.id}
                    onClose={() => setEditDocumentID(null)}
                    ownerId={ownerId}
                    ownerType={ownerType}
                  />
                ) : (
                  <div
                    className='u-flex u-borderBottom u-flexJustify-spaceBetween u-paddingBottom-12 u-marginBottom-12'
                    key={document.id}
                  >
                    <div>
                      <a
                        href={document.url}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        {document?.file_name}
                      </a>
                      <p className='u-margin-n'>
                        {moment(document?.created_at).fromNow()}
                      </p>
                    </div>
                    <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c'>
                      <Tag
                        className='u-marginVertical-n'
                        name={document?.document_label?.name}
                      />
                      {document?.permissions?.edit ? (
                        <ButtonSecondary
                          data-testid='edit-document-btn'
                          icon='edit'
                          onClick={() => {
                            handleEdit(document.id);
                          }}
                          size='small'
                        />
                      ) : null}
                      {document?.permissions?.delete ? (
                        <ConfirmationPopoverButton
                          className='Button Button--secondary Button--hasIconOnly Button--small u-marginLeft-8'
                          containerClassName='DocumentEditor__deletePopover'
                          data-testid='delete-document-btn'
                          iconClass='fa fa-trash'
                          onConfirm={() => handleDeleteConfirm(document.id)}
                          title={`Are you sure you want to delete this ${ownerType} document?`}
                        />
                      ) : null}
                    </div>
                  </div>
                ),
              )}
            </div>
          </ScrollableContainer>
        </>
      ) : null}
    </LoadingContainer>
  );
};

DocumentList.propTypes = {
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      interim: PropTypes.bool,
      name: PropTypes.string,
    }),
  ),
  ownerId: PropTypes.number,
  ownerType: PropTypes.oneOf(OwnerTypes),
};

export default DocumentList;

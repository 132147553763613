import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { compose, setPropTypes } from 'recompose';
import classnames from 'classnames';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import mapTargetCompanyIdToTargetCompany from './mapTargetCompanyIdToTargetCompany';
import connectTargetCompanyActions from './connectTargetCompanyActions';

class TargetCompanyStatusToggleButton extends PureComponent {
  state = {};

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Check to see if we have a transaction. If it is finished without an
    // error, than we can close the form (the save was succesful)
    const { transactionId } = this.state;

    if (transactionId) {
      const transaction = nextProps.transactions.get(transactionId);
      if (transaction && transaction.isFinished) {
        nextProps.transactionActions.clearTransaction(transactionId);

        if (transaction.payload.error) {
          // TODO: handle this?
        }

        this.setState({ transactionId: null });
      }
    }
  }

  handleClick = () => {
    const {
      targetCompany,
      targetCompanyActions: { activateTargetCompany, deactivateTargetCompany },
    } = this.props;

    const isActive = targetCompany.get('active');
    const targetCompanyId = targetCompany.get('id');
    const transactionId = uniqueId();
    const action = isActive ? deactivateTargetCompany : activateTargetCompany;
    action({ targetCompanyId: targetCompanyId, transactionId: transactionId });
    this.setState({ transactionId: transactionId });
  };

  render() {
    const { targetCompany } = this.props;

    const isUpdating = targetCompany.getIn(['_meta', 'isUpdating']);
    const isActive = targetCompany.get('active');
    const toolTip = isActive ? 'Deactivate' : 'Activate';

    return (
      <OverlayTrigger
        overlay={
          <Tooltip id={`${this._componentId}-active-status`}>{toolTip}</Tooltip>
        }
        placement='top'
      >
        <button
          className={classnames('btn-link btn-active-status', {
            disabled: isUpdating,
            active: isActive,
            inactive: !isActive,
          })}
          disabled={isUpdating}
          onClick={this.handleClick}
          type='button'
        >
          <i
            className={classnames('fa', 'btn-icon', {
              'fa-spin': isUpdating,
              'fa-spinner': isUpdating,
              'fa-toggle-on': !isUpdating && isActive,
              'fa-toggle-off': !isUpdating && !isActive,
            })}
          />
        </button>
      </OverlayTrigger>
    );
  }
}

TargetCompanyStatusToggleButton.propTypes = {
  targetCompany: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isUpdating: PropTypes.bool.isRequired,
    }),
    active: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }),

  targetCompanyActions: PropTypes.shape({
    activateTargetCompany: PropTypes.func.isRequired,
    deactivateTargetCompany: PropTypes.func.isRequired,
  }).isRequired,

  transactionActions: PropTypes.shape({
    // eslint-disable-line react/no-unused-prop-types
    clearTransaction: PropTypes.func.isRequired,
  }).isRequired,

  // eslint-disable-next-line react/no-unused-prop-types, react/no-typos
  transactions: transactionsState.isRequired,
};

export default compose(
  setPropTypes({
    targetCompanyId: PropTypes.number.isRequired,
  }),
  mapTargetCompanyIdToTargetCompany,
  connectTargetCompanyActions,
  connectTransactions,
)(TargetCompanyStatusToggleButton);

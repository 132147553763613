import React from 'react';
import PropTypes from 'prop-types';
import Address from '@thrivetrm/ui/components/Address';
import Avatar from '@thrivetrm/ui/components/Avatar';
import BooleanText from '@thrivetrm/ui/components/BooleanText';
import TextAlertArrow from '@thrivetrm/ui/components/TextAlertArrow';
import Percentage from '@thrivetrm/ui/components/Percentage';
import Currency from '@thrivetrm/ui/components/Currency';
import Date from '@thrivetrm/ui/components/Date';
import CommuteRadius from 'modules/contacts/components/CommuteRadius';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';

const DuplicateMergeAttributeCell = ({
  avatarShape,
  data,
  dataType,
  isSelectionRequired,
}) => {
  if (isSelectionRequired) {
    return <TextAlertArrow label='Pick A or B' />;
  }

  if (data.value === null) {
    return null;
  }

  switch (dataType) {
    case 'address':
      return (
        <Address
          city={data.value.city}
          country={data.value.country || data.value.country_code}
          line1={data.value.line_1}
          line2={data.value.line_2}
          postalCode={data.value.postal_code}
          state={data.value.state}
        />
      );
    case 'avatar':
      return <Avatar image={data.value} shape={avatarShape} size='small' />;
    case 'boolean':
      return <BooleanText value={data.value} />;
    case 'commute_radius':
      return <CommuteRadius value={data.value} />;
    case 'currency':
      return <Currency currency={data.currency} value={data.value} />;
    case 'date':
      return <Date date={data.value} />;
    case 'html':
      return (
        <ExpandableText
          characterLimit={150}
          content={data.value}
          isSanitizedHtml={true}
        />
      );
    case 'percentage':
      return <Percentage value={data.value} />;
    case 'revenue':
      return Number.isFinite(data.value) ? `${data.value}M` : null;
    case 'text':
      return <ExpandableText characterLimit={150} content={data.value} />;
    case 'multi_string':
      return data.map(newValue => newValue.value).join(', ');
    default:
      return data.value;
  }
};

DuplicateMergeAttributeCell.defaultProps = {
  data: {},
  isSelectionRequired: false,
};

DuplicateMergeAttributeCell.propTypes = {
  avatarShape: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.shape({
      currency: PropTypes.string,
      id: PropTypes.number,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
      ]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        id: PropTypes.number,
        value: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.number,
          PropTypes.string,
          PropTypes.object,
        ]),
      }),
    ),
  ]),
  dataType: PropTypes.string.isRequired,
  isSelectionRequired: PropTypes.bool,
};

export default DuplicateMergeAttributeCell;

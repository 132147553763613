import PropTypes from 'prop-types';
import React from 'react';
import Popover from 'react-bootstrap/lib/Popover';
import SearchStageIndicatorDot from 'modules/search-stages/components/SearchStageIndicatorDot';

/**
 * Renders the Popover for a single item in the CandidaciesPerStageDonutChart.
 * This renders a dot indicating the stage color, with the stage name, number of candidates
 * in that stage, and that number as a percentage of all candidates.
 */
const CandidaciesPerStageDonutChartPopover = ({
  color,
  count,
  isEmptyPlaceholder,
  label,
  style,
  totalCandidates,
  ...props
}) => (
  <Popover
    {...props}
    style={{
      ...style,
      // *VERY* important! This is needed to prevent the popover from responding to mouse
      // events and interfering with the mouse event of the "pie slice" from the chart!
      // Without this, the pie slice will use focus when the popover is hovered, and there will
      // be a lot of flickering as the popover renders, disappears, renders, etc.
      pointerEvents: 'none',
    }}
  >
    {isEmptyPlaceholder ? (
      <div className='CandidaciesPerStageDonutChartPopover__label'>
        <b>{label}</b>
      </div>
    ) : (
      <div className='CandidaciesPerStageDonutChartPopover__content'>
        <SearchStageIndicatorDot color={color} />
        <span className='CandidaciesPerStageDonutChartPopover__label'>
          <b>{label}: </b>
        </span>
        <span className='CandidaciesPerStageDonutChartPopover__value'>
          {count} (
          {totalCandidates ? ((count / totalCandidates) * 100).toFixed() : 0}
          %)
        </span>
      </div>
    )}
  </Popover>
);

CandidaciesPerStageDonutChartPopover.propTypes = {
  /**
   * The color assigned to the stage.
   * Note that this (along with the other data-related props) is not required because it
   * will still get rendered when the chart doesn't have an `activeChartItem`, in which case
   * these will be undefined.
   */
  color: PropTypes.string,

  /**
   * The number of candidates in this stage.
   */
  count: PropTypes.number,

  /**
   * Indicates that this is the tooltip for an empty chart placeholder slice.
   */
  isEmptyPlaceholder: PropTypes.bool,

  /**
   * The label / display name of the stage.
   */
  label: PropTypes.string,

  /**
   * The style to apply to the popover element. This is provided by the parent Overlay.
   */
  style: PropTypes.shape({
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),

  /**
   * The total number of candidates across all stages being rendered.
   */
  totalCandidates: PropTypes.number,
};

CandidaciesPerStageDonutChartPopover.defaultProps = {
  isEmptyPlaceholder: false,
};

export default CandidaciesPerStageDonutChartPopover;

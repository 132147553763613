import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import ContactAvatar from 'modules/contacts/components/ContactAvatar';
import FieldState from 'modules/forms/FieldState';

const getPreview = ({ file, preview, removeAvatar, url }, initialPreview) => {
  // If the user wants to remove the avatar, abort.
  if (removeAvatar) {
    return null;
  }

  // In the case of a linkedin scrape, we snagged the url to the avatar.
  if (url) {
    return url;
  }

  // The user is uploading a file.
  if (file) {
    return preview;
  }

  // Fallback to the avatar_url that came down from the server.
  return initialPreview;
};

class AvatarUploaderField extends Component {
  static createFieldState(name = 'avatar', url) {
    return FieldState.create(name, {
      url: url,
      file: null,
      removeAvatar: false,
    });
  }

  constructor(...args) {
    super(...args);
    this.previews = [];
  }

  componentWillUnmount() {
    this.previews.forEach(preview => URL.revokeObjectURL(preview));
  }

  handleDrop = ([file]) => {
    const { fieldState, onChange } = this.props;
    const preview = URL.createObjectURL(file);
    this.previews.push(preview);

    onChange(
      fieldState.setValue({
        file: file,
        preview: preview,
        url: null,
        removeAvatar: false,
      }),
    );
  };

  handleRemove = e => {
    // prevent the dropzone from handling the event
    e?.stopPropagation();

    const { fieldState, onChange } = this.props;

    onChange(
      fieldState.setValue({
        file: null,
        url: null,
        removeAvatar: true,
      }),
    );
  };

  render() {
    const { fieldState, initialPreview } = this.props;
    const value = fieldState.getFieldValue();
    const preview = getPreview(value, initialPreview);
    const { url } = value;

    return (
      <Dropzone
        activeClassName='contact-file-uploader-dropzone-active'
        className='contact-file-uploader-dropzone'
        multiple={false}
        onDrop={this.handleDrop}
      >
        <div className='text-center'>
          <div className='contact-avatar-uploader-placeholder'>
            <div className='contact-avatar-text-span'>
              <span>Photo Preview</span>
            </div>
          </div>
        </div>
        <div className='contact-photo-uploader'>
          {preview ? (
            <div>
              <ContactAvatar
                // removes linkedin avatar url from the fieldState if it's expired.
                onError={event => {
                  if (url) {
                    this.handleRemove(event);
                  }
                }}
                url={preview}
                useLegacyStyles={true}
              />
              <div className='contact-avatar-text-span'>
                <button
                  className='btn btn-link contact-avatar-remove-link'
                  onClick={this.handleRemove}
                  type='button'
                >
                  Remove
                </button>
              </div>
            </div>
          ) : (
            <div className='contact-avatar-text-span'>
              <ContactAvatar url={preview} useLegacyStyles={true} />
              <span className='btn btn-link'>Choose a file</span>
              <span> or drag / drop document here.</span>
            </div>
          )}
        </div>
      </Dropzone>
    );
  }
}

AvatarUploaderField.propTypes = {
  fieldState: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setValue: PropTypes.func.isRequired,
  }).isRequired,

  initialPreview: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

export default AvatarUploaderField;

import getEntity from 'modules/entities/selectors/getEntity';
import { targetCompanySchema } from '../../schema';

/**
 * Gets the target company record given a target company ID
 * @param {Object} state
 * @param {Number} id The target company ID.
 * @return {Immutable.Map} The target company record.
 */
export default (state, targetCompanyId) =>
  getEntity(state, targetCompanySchema.key, targetCompanyId);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import mapContactIdToContact from '../mapContactIdToContact';
import ContactDesiredCompensationModalForm from './ContactDesiredCompensationModalForm';
import ContactDesiredCompensationView from './ContactDesiredCompensationView';

/**
 * Renders an EditableModalSection that displays and allows editing of a contact's
 * "Desired Compensation" fields in a modal.
 */
export default compose(
  setDisplayName('ContactDesiredCompensationEditableModalSection'),
  setPropTypes({
    /**
     * The ID of the contact to show compensation data for.
     */
    contactId: PropTypes.number.isRequired,

    /**
     * Displays how long ago the section was last updated.
     */
    lastUpdated: PropTypes.shape({
      created_at: PropTypes.string,
      user_email: PropTypes.string,
    }),
    /**
     * The title to display for this editable section.
     */
    title: PropTypes.string.isRequired,
  }),
  mapContactIdToContact,
  mapProps(({ contact, contactId, lastUpdated, title }) => ({
    contactId: contactId,
    children: ContactDesiredCompensationView.willRender({
      contact: contact,
    }) && <ContactDesiredCompensationView contactId={contactId} />,
    className: 'ContactDesiredCompensationEditableModalSection',
    formComponent: ContactDesiredCompensationModalForm,
    lastUpdated: lastUpdated,
    show: true,
    title: title,
  })),
)(EditableSection);

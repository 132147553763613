import PropTypes from 'prop-types';
import React from 'react';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';
import GenerateReportButton from 'modules/reports/components/GenerateReportButton';
import useToggle from '@thrivetrm/ui/hooks/useToggle';

import { TYPE_JOB_SEARCH } from '../../constants';
import mapSearchIdToSearchType from '../mapSearchIdToSearchType';
import SearchSummaryReportModal from './SearchSummaryReportModal';
import canViewSearchSummaryReportSelector from '../../selectors/canViewSearchSummaryReport';
import { TYPE_OVERVIEW } from './constants';

/**
 * Renders a button that, when clicked, displays the
 * SearchSummaryReportModal dialog allowing a user to request a search summary report.
 */
const SearchSummaryReportButton = ({
  currentUserEmail,
  searchId,
  searchType,
}) => {
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const buttonTitle =
    searchType === TYPE_JOB_SEARCH ? 'Search Summary Report' : 'Summary Report';
  const reportType =
    window.localStorage.getItem('SearchSummaryReportLastType') || TYPE_OVERVIEW;

  return (
    <div className='SearchSummaryReportButton'>
      <GenerateReportButton onClick={openModal}>
        {buttonTitle}
      </GenerateReportButton>
      {isModalOpen && (
        <SearchSummaryReportModal
          currentUserEmail={currentUserEmail}
          onHide={closeModal}
          reportType={reportType}
          searchId={searchId}
        />
      )}
    </div>
  );
};

SearchSummaryReportButton.propTypes = {
  currentUserEmail: PropTypes.string.isRequired,
  searchId: PropTypes.number.isRequired,
  searchType: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('SearchSummaryReportButton(enhanced)'),
  setPropTypes({
    /**
     * Whether or not we should check if the user has the required permission for viewing/generating
     * a search summary report. (default: true).
     */
    checkPermissions: PropTypes.bool.isRequired,

    /**
     * The ID of the search to generate the report for.
     */
    searchId: PropTypes.number.isRequired,
  }),

  // By default we check permissions.
  defaultProps({
    checkPermissions: true,
  }),

  // Lookup permission if needed
  connect(
    (state, { checkPermissions }) => ({
      canViewSearchSummaryReport:
        !checkPermissions || canViewSearchSummaryReportSelector(state),
    }),
    {},
  ),

  // Bail if the user doesn't have permission.
  branch(
    ({ canViewSearchSummaryReport }) => !canViewSearchSummaryReport,
    renderNothing,
  ),
  mapSearchIdToSearchType,
)(SearchSummaryReportButton);

import isFetchingEntity from 'modules/entities/selectors/isFetchingEntity';
import { candidacySchema } from '../schemas';

/**
 * Determines if a candidacy is currently being fetched from the server.
 * @param {Object} state
 * @param {Number} candidacyId The candidacy ID
 * @return {Boolean} True if the candidacy record is currently being fetched
 * from the server, otherwise false.
 */
export default (state, candidacyId) =>
  isFetchingEntity(state, candidacySchema.key, candidacyId);

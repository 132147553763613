import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import { STAGES } from '../constants';

const OPTIONS = STAGES.map(value => ({ value: value }));

/**
 * A field for selecting the "investment stage" for a company's portfolio details.
 */
const CompanyPortfolioStageSelectField = ({ className, ...props }) => (
  <ReactSelectField
    className={classnames('CompanyPortfolioStageSelectField', className)}
    {...props}
    labelKey='value'
    options={OPTIONS}
    simpleValue={true}
  />
);

CompanyPortfolioStageSelectField.propTypes = {
  /**
   * An optional class name to apply to the select control
   */
  className: PropTypes.string,

  /**
   * The label to display above the field.
   */
  label: PropTypes.string,

  /**
   * The placeholder text to display when the field is empty.
   */
  placeholder: PropTypes.string,
};

CompanyPortfolioStageSelectField.defaultProps = {
  label: 'Investment Stage',
  placeholder: 'Investment Stage',
};

CompanyPortfolioStageSelectField.createFieldState =
  ReactSelectField.createFieldState;

export default CompanyPortfolioStageSelectField;

import PropTypes from 'prop-types';
import iso8601PropType from '@thrivetrm/ui/propTypes/iso8601PropType';

const documentShape = {
  created_at: iso8601PropType,
  document_label: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  file_name: PropTypes.string.isRequired,
  _meta: PropTypes.shape({
    isDeleted: PropTypes.bool,
    isDeleting: PropTypes.bool,
    isUpdating: PropTypes.bool,
  }),
  expiration_date: PropTypes.string,
  owner_id: PropTypes.number.isRequired,
  owner_type: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export const OwnerTypes = ['contact', 'search', 'offer'];

export default documentShape;

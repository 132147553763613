import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import InlineEditActions from '../../../../components/forms/InlineEditActions';
import ContactRatingView from './ContactRatingView';
import ContactRatingForm from './ContactRatingForm';
import connectRatingActions from './connectRatingActions';
import canEditRating from '../../selectors/ratings/canEditRating';
import isRatingByCurrentUser from '../../selectors/ratings/isRatingByCurrentUser';

export const ContactRatingListItem = ({
  canEditAndDelete,
  handleDelete,
  handleEdit,
  handleRatingFormClose,
  isCurrentUserRating,
  isEditing,
  ratingId,
}) => (
  <li
    className={classnames('ContactRatingListItem', {
      'ContactRatingListItem--RatedByMe': isCurrentUserRating,
      'ContactRatingListItem--RatedByOther': !isCurrentUserRating,
    })}
  >
    {!isEditing && canEditAndDelete && (
      <InlineEditActions
        canDelete={canEditAndDelete}
        canEdit={canEditAndDelete}
        className='pull-right'
        deleteConfirmation='Delete this Rating?'
        deleteLabel={<i className='fa fa-trash' />}
        editLabel={<i className='fa fa-pencil' />}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    )}

    {isEditing ? (
      <div className='ContactRatingListItem__edit'>
        <h3>Edit Rating</h3>
        <ContactRatingForm
          btnClassName='btn-link'
          onCancel={handleRatingFormClose}
          onSaved={handleRatingFormClose}
          ratingId={ratingId}
        />
      </div>
    ) : (
      <ContactRatingView ratingId={ratingId} />
    )}
  </li>
);

ContactRatingListItem.propTypes = {
  canEditAndDelete: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleRatingFormClose: PropTypes.func,
  isCurrentUserRating: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  ratingId: PropTypes.number.isRequired,
};

export default compose(
  setDisplayName('ContactRatingListItem(enhanced)'),
  setPropTypes({
    ratingId: PropTypes.number.isRequired,
    readOnly: PropTypes.bool.isRequired,
  }),
  defaultProps({
    readOnly: false,
  }),
  connectRatingActions,
  connect(
    (state, { ratingId }) => ({
      canEditAndDelete: canEditRating(state, ratingId),
      isCurrentUserRating: isRatingByCurrentUser(state, ratingId),
    }),
    {},
  ),
  withStateHandlers(
    {
      isEditing: false,
    },
    {
      handleEdit: () => () => ({ isEditing: true }),
      handleRatingFormClose: () => () => ({ isEditing: false }),
      handleDelete: (_, { ratingActions, ratingId }) => () => {
        ratingActions.deleteRating({ id: ratingId });
      },
    },
  ),
)(ContactRatingListItem);

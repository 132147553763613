import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ContactEmailSelectValue from './ContactEmailSelectValue';

/**
 * The component for rendering a selectable option in the ContactEmailSelectField
 * dropdown autocomplete.
 */
const ContactEmailSelectOption = ({ email, label, name, preferred }) => (
  <div
    className={classnames('ContactEmailSelectOption', {
      'ContactEmailSelectOption--is-preferred': preferred,
    })}
  >
    <ContactEmailSelectValue email={email} name={name} />
    {label && (
      <span className='ContactEmailSelectOption__label'>
        {label} {preferred && '(Preferred)'}
      </span>
    )}
  </div>
);

ContactEmailSelectOption.propTypes = {
  /**
   * The email address to display/select.
   */
  email: PropTypes.string.isRequired,

  /**
   * An optional label that describes the type of email address (i.e. "Work", "Personal")
   */
  label: PropTypes.string,

  /**
   * The contact's name (if this option has an associated contact)
   */
  name: PropTypes.string,

  /**
   * True if this is the contact's preferred email address.
   */
  preferred: PropTypes.bool,
};

ContactEmailSelectOption.defaultProps = {
  preferred: false,
};

export default ContactEmailSelectOption;

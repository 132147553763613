import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import documentShape from '../documentShape';
import DocumentTableRow from './DocumentTableRow';
import { OWNER_TYPES, OWNER_COMPANY } from '../constants';

export const DocumentTable = ({
  documentLabels,
  documents,
  fetchDocuments,
  ownerType,
  readOnly,
}) => {
  const documentCountForCurrentLabel = label => {
    return documents.filter(
      document => document.document_label.name === label.name,
    ).length;
  };
  const shouldShowShareInSearchesColumn = ownerType !== OWNER_COMPANY;
  const shouldShowExpirationDateColumn = documentLabels?.some(
    documentLabel => documentLabel.expirable === true,
  );

  return (
    <table className='documents--document-table table table--rowHighlight'>
      <thead>
        <tr>
          <th className='documents--document-table-row'>Document</th>
          <th className='documents--document-table-row'>Type</th>
          {shouldShowShareInSearchesColumn ? (
            <th className='documents--document-table-row'>Share In Searches</th>
          ) : null}
          <th className='documents--document-table-row'>Upload Date</th>
          {shouldShowExpirationDateColumn ? (
            <th className='documents--document-table-row'>Expiration Date</th>
          ) : null}
          <th aria-label='action' className='documents--document-table-row' />
        </tr>
      </thead>

      <TransitionGroup component='tbody' enter={false}>
        {documents &&
          documents.map(document => (
            <CSSTransition classNames='fade' key={document.id} timeout={1500}>
              <DocumentTableRow
                document={document}
                documentCountForCurrentLabel={documentCountForCurrentLabel(
                  document.document_label,
                )}
                fetchDocuments={fetchDocuments}
                labelItems={documentLabels}
                readOnly={readOnly}
                shouldShowExpirationDateColumn={shouldShowExpirationDateColumn}
                shouldShowShareInSearchesColumn={
                  shouldShowShareInSearchesColumn
                }
              />
            </CSSTransition>
          ))}
      </TransitionGroup>
    </table>
  );
};

DocumentTable.propTypes = {
  documentLabels: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string }).isRequired,
  ).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape(documentShape)),
  fetchDocuments: PropTypes.func.isRequired,
  ownerType: PropTypes.oneOf(OWNER_TYPES).isRequired,
  readOnly: PropTypes.bool,
};

DocumentTable.defaultProps = {
  readOnly: false,
};

export default DocumentTable;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Renders the value that is displayed in the ContactEmailSelectField.
 * Simply renders the name (if any) and email address of the option.
 */
const ContactEmailSelectValue = ({ email, name }) => (
  <span
    className={classnames('ContactEmailSelectValue', {
      'ContactEmailSelectValue--has-name': name,
    })}
  >
    {name && <span className='ContactEmailSelectValue__name'>{name}</span>}
    <span className='ContactEmailSelectValue__email'>{email}</span>
  </span>
);

ContactEmailSelectValue.propTypes = {
  /**
   * The email address to display.
   */
  email: PropTypes.string.isRequired,

  /**
   * The contact's name (if associated with a contact)
   */
  name: PropTypes.string,
};

ContactEmailSelectValue.defaultProps = {
  name: null,
};

export default ContactEmailSelectValue;

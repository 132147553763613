import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { compose, setStatic } from 'recompose';

import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import transactionsState from 'modules/transactions/propTypes/transactionsState';

import QueryMultiSelect from '../../../components/forms/multiselect/QueryMultiSelect';

import withSearchActions from './withSearchActions';
import { SEARCH_TYPES, TYPE_JOB_SEARCH } from '../constants';
import getSearch from '../selectors/getSearch';
import getQueriesById from '../selectors/getQueriesById';

export const SearchMultiSelectField = ({
  noValueText,
  optionsFromData,
  queriesById,
  searchActions,
  searchType,
  summaryLabel,
  transactionActions,
  transactions,
  ...props
}) => {
  const { contactId } = useParams();
  const { candidacyId } = useParams();

  return (
    <QueryMultiSelect
      {...props}
      candidacyId={candidacyId}
      clearTransaction={transactionActions.clearTransaction}
      contactId={contactId}
      noValueText={noValueText}
      onCreate={searchActions.createQuery}
      onDestroy={searchActions.destroyQuery}
      onQuery={searchActions.fetchQuery}
      optionsFromData={optionsFromData}
      queriesById={queriesById}
      searchType={searchType}
      summaryLabel={summaryLabel}
      transactions={transactions}
    />
  );
};

SearchMultiSelectField.propTypes = {
  noValueText: PropTypes.string,
  /**
   * A function that converts the data from `queriesById` (which is just an array of search IDs)
   * into the options that can be passed into the MultiSelect for display (something with an `id`
   * and `name` property)
   */
  optionsFromData: PropTypes.func.isRequired,

  queriesById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.map,
    PropTypes.string,
  ).isRequired,

  searchActions: PropTypes.shape({
    createQuery: PropTypes.func.isRequired,
    destroyQuery: PropTypes.func.isRequired,
    fetchQuery: PropTypes.func.isRequired,
  }).isRequired,

  searchType: PropTypes.oneOf(SEARCH_TYPES),
  summaryLabel: PropTypes.string,

  transactionActions: PropTypes.shape({
    clearTransaction: PropTypes.func.isRequired,
  }).isRequired,

  // eslint-disable-next-line react/no-typos
  transactions: transactionsState.isRequired,
};

SearchMultiSelectField.defaultProps = {
  noValueText: 'No Searches Selected',
  searchType: TYPE_JOB_SEARCH,
  summaryLabel: '{0} Searches Selected',
};

export default compose(
  setStatic(
    'createFieldState',
    (name, value, validator, convertToRaw, ...args) =>
      FieldState.create(
        name,

        // Make sure the value is an array and not an immutable list.
        List.isList(value) ? value.toJS() : value || [],

        validator,

        // This convertToRaw implementation makes sure that this field value is an array of IDs
        fieldValue => fieldValue.map(idOrObject => idOrObject.id || idOrObject),

        ...args,
      ),
  ),
  withSearchActions,
  connectTransactions,
  withFormGroup,
  asField(),
  connect((state, { value }) => ({
    queriesById: getQueriesById(state),
    optionsFromData: data =>
      data ? data.map(id => getSearch(state, id)).toJS() : [],

    // Make sure the value being passed into the control is an array of objects with `{ id, name }`
    // and not just an array of IDs (which it likely is initially) -- without the id/name pair
    // the select control won't know how to render the item's name.
    value: value
      ? value.map(id =>
          typeof id === 'number' ? getSearch(state, id).toJS() : id,
        )
      : [],
  })),
)(SearchMultiSelectField);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import RatingLegacy from 'modules/core/componentsLegacy/RatingLegacy';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import getMaxRating from 'modules/tenant/selectors/getMaxRating';
import Tooltips from '@thrivetrm/ui/components/Tooltip';

const MAX_RATING = 5;

/**
 * Renders a rating for a single assessment attribute.
 * It displays a the number of stars (or "Not Rated" if the score is 0 and
 * we are in readOnly mode) followed by the assessment attribute name.
 */
class AssessmentRating extends Component {
  /**
   * Called when a rating value is changed.
   */
  handleRateChange = value => {
    const { onChange, option, readOnly } = this.props;
    if (onChange && !readOnly) {
      onChange(value, option);
    }
  };

  render() {
    const { componentId, maxRating, option, rating, readOnly } = this.props;
    const isRated = rating && typeof rating.get('score') === 'number';

    return (
      <div
        className={classnames('AssessmentRating', {
          'AssessmentRating--is-unrated u-marginVertical-4': !isRated,
          'AssessmentRating--is-readonly': readOnly,
        })}
      >
        <span className='AssessmentRating__stars'>
          {readOnly && !isRated ? (
            <span className='AssessmentRating__not-rated'>Not rated</span>
          ) : (
            <>
              <Tooltips
                content={
                  maxRating > MAX_RATING && readOnly
                    ? `${
                        (rating && rating.get('score')) || 0
                      } star out of ${maxRating} star`
                    : ''
                }
                position='topRight'
              >
                {/* this will show only four star and after that ... will come */}
                <RatingLegacy
                  id={componentId}
                  maxRating={
                    maxRating > MAX_RATING && readOnly
                      ? MAX_RATING - 1
                      : maxRating
                  }
                  onChange={this.handleRateChange}
                  ratingStarsClassName={readOnly ? 'u-marginRight-n' : ''}
                  readOnly={readOnly}
                  value={(rating && rating.get('score')) || 0}
                />
              </Tooltips>
              {maxRating > MAX_RATING && readOnly ? (
                <span className='u-marginRight-8'>...</span>
              ) : null}
            </>
          )}
        </span>
        <label htmlFor={componentId}>{option.get('name')}</label>
        {option.get('description') ? (
          <OverlayTrigger
            overlay={
              <Tooltip id={`${componentId}-tooltip`}>
                <span>{option.get('description')}</span>
              </Tooltip>
            }
            placement='right'
          >
            <i className='fa fa-info-circle btn-tooltip' />
          </OverlayTrigger>
        ) : null}
      </div>
    );
  }
}

AssessmentRating.propTypes = {
  /**
   * A unique component ID used for identifying the tooltip displayed if a description is
   * provided for this rating attribute.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * The maximum rating value allowed.
   */
  maxRating: PropTypes.number,

  /**
   * Called when the rating value is changed. Only used when `readOnly` is
   * false. Called with the following signature:
   *
   *   `onChange(newValue, option)`
   *
   * Where:
   *  @param {Number} newValue the new rating value selected,
   *  @param {Map} option The assessment option passed into the `option` prop.
   */
  onChange: PropTypes.func,

  /**
   * The opton that describes what is being rated.
   */
  option: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,

  /**
   * The current rating value (if any)
   */
  rating: ImmutablePropTypes.mapContains({
    score: PropTypes.number,
  }),

  /**
   * If false, the rating can be changed by clikcing on the stars, otherwise
   * the rating will be rendered for display only.
   */
  readOnly: PropTypes.bool,
};

AssessmentRating.defaultProps = {
  maxRating: 5,
  readOnly: false,
};

export default compose(
  setDisplayName('AssessmentRating(enhanced)'),
  withComponentId(),
  connect(
    state => ({
      maxRating: getMaxRating(state),
    }),
    {},
  ),
)(AssessmentRating);

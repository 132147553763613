import { connect } from 'react-redux';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getTagProperty from '../selectors/getTagProperty';

/**
 * A higher order component that sorts a list of tagIds in alphabetical order
 * with the selectedTagIds first
 * @param {*} state
 * @param {List} tagIds the list of all tag IDs in the tenant
 * @param {Array} selectedTagIds the tag IDs currently tagged to the candidacy
 * @return {List} sorted tagIds
 */
export default connect(
  (state, { tagIds, selectedTagIds = [] }) => ({
    // Prefixing the tag's name with '0' or '1' will ensure the selected tags come first
    // in the sort order
    tagIds:
      tagIds &&
      tagIds.sortBy(
        tagId =>
          (selectedTagIds.includes(tagId) ? '0' : '1') +
          stringUtils.toSortableString(getTagProperty(state, tagId, 'name')),
      ),
  }),
  {},
);

import getName from './getName';
import getContactProperty from './getContactProperty';

/**
 * Gets the full name for a contact with their email (in parenthesis). If no email is available,
 * only the name is returned, and if no full name was avavilable, only the email is returned.
 * If neither is found/available, `undefined` is returned.
 * @param {Object} state
 * @param {Number} id The contact ID
 * @returns {String} The name and preferred email address of the contact.
 */
const getNameWithEmail = (state, id, noEmailString) => {
  const name = getName(state, id);
  const email =
    getContactProperty(state, id, 'preferred_email') ||
    getContactProperty(state, id, 'email') ||
    getContactProperty(state, id, 'work_email');

  if (name && email) {
    return `${name} (${email})`;
  }

  if (name && noEmailString) {
    return `${name} (${noEmailString})`;
  }

  return name || email;
};

export default getNameWithEmail;

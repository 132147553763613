import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Clipboard from 'clipboard';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import mapResidencyIdToResidency from './mapResidencyIdToResidency';

const INITIAL_STATE = {
  tooltip: 'Copy Token',
};

class ResidencyToken extends Component {
  state = INITIAL_STATE;

  handleRef = el => {
    if (el) {
      this.clipboard = new Clipboard(el, {
        container: el,
      });

      this.clipboard.on('success', () =>
        this.setState({
          tooltip: 'Copied!',
        }),
      );
    } else {
      this.clipboard.destroy();
    }
  };

  render() {
    const { tooltip } = this.state;
    const { residency } = this.props;
    const token = residency.get('token');

    return (
      <div>
        <div className='alert alert-warning text-center'>
          <span data-clipboard-text={token} ref={this.handleRef}>
            <TooltipTrigger tooltip={tooltip}>
              <button className='btn-link alert-link' type='button'>
                <i className='fa fa-clipboard' />
              </button>
            </TooltipTrigger>
          </span>{' '}
          {token}
        </div>

        <p>
          Make sure to copy your new personal access token now. You won’t be
          able to see it again!
        </p>
        <p>
          This token grants access to your Thrive data. Treat it as you would
          your password.
        </p>
      </div>
    );
  }
}

ResidencyToken.propTypes = {
  residency: ImmutablePropTypes.mapContains({
    name: PropTypes.string,
  }),
};

export default mapResidencyIdToResidency(ResidencyToken);

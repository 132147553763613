import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import canViewOutreachesSelector from '../selectors/canViewOutreaches';
import withOutreachListFetched from './withOutreachListFetched';
import mapOutreachIdsToOutreaches from './mapOutreachIdsToOutreaches';
import OutreachList from './OutreachList';
import { LIST_RECENT, PARENT_SEARCH } from '../constants';

/**
 * Renders a list of only the most recent outreaches (3, although this is
 * driven by a combination of the number of records the server returns
 * when `recent=true` is passed as a query string, in addition to the `limit`
 * prop)
 */
const RecentOutreaches = ({
  hideIfEmpty,
  limit,
  outreachList,
  outreaches,
  parentId,
  parentType,
  readOnly,
  title,
}) =>
  hideIfEmpty && (!outreaches || outreaches.size < 1) ? null : (
    <div className='outreaches--recent-outreach'>
      {title}
      {outreaches && (
        <OutreachList
          limit={limit}
          outreaches={outreaches}
          readOnly={readOnly}
          renderRelatedSearches={false}
          searchId={parentType === PARENT_SEARCH ? parentId : null}
        />
      )}
      {outreachList && outreachList.getIn(['_meta', 'isFetching']) && (
        <LoadingIndicator />
      )}
    </div>
  );

RecentOutreaches.propTypes = {
  hideIfEmpty: PropTypes.bool,

  limit: PropTypes.number,

  outreaches: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),
  ),

  outreachList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  parentId: PropTypes.number,

  parentType: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,

  /**
   * An optional title to render.
   */
  title: PropTypes.node,
};

RecentOutreaches.defaultProps = {
  hideIfEmpty: false,
  readOnly: false,
};

export default compose(
  setDisplayName('RecentOutreaches(enhanced)'),

  // The connected version of this component requires a parentId and parentType,
  // everything else will be fetched from state.
  setPropTypes({
    checkPermissions: PropTypes.bool.isRequired,
    parentId: PropTypes.number,
    parentType: PropTypes.string.isRequired,
  }),

  defaultProps({
    checkPermissions: true,
  }),

  connect(
    (state, { checkPermissions }) => ({
      canViewOutreaches: !checkPermissions || canViewOutreachesSelector(state),
    }),
    {},
  ),

  // Bail if the user doesn't have permission.
  branch(({ canViewOutreaches }) => !canViewOutreaches, renderNothing),

  withProps({ listType: LIST_RECENT }),

  // If the outreaches have not been loaded or are stale, fetch them.
  withOutreachListFetched,

  // Pull off the outreachIds as a prop so they can be mapped by mapOutreachIdsToOutreaches
  withProps(({ fetchOutreachList, outreachList }) => ({
    handleRefreshOutreaches: fetchOutreachList,
    outreachIds: outreachList && outreachList.get('ids'),
  })),

  mapOutreachIdsToOutreaches,
)(RecentOutreaches);

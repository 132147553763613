import { connect } from 'react-redux';
import getBulkImport from '../selectors/getBulkImport';

/**
 * A higher order component which maps a `bulkImportIds` prop value to their bulkImport records as
 * a `bulkImports` prop.`
 */
export default connect(
  (state, { bulkImportIds }) => ({
    bulkImports:
      bulkImportIds &&
      bulkImportIds.map(bulkImportId => getBulkImport(state, bulkImportId)),
  }),
  {},
);

import { NOTE_LIST_INVALIDATE } from './actionTypes';

/**
 * Invalidates a notes list
 * @param {Object} options
 * @param {String} parentType
 * @param {Number} parentId
 */
export default ({ parentId, parentType }) => ({
  type: NOTE_LIST_INVALIDATE,
  parentType: parentType,
  parentId: parentId,
});

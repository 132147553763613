import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import FieldState from 'modules/forms/FieldState';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import RejectionField from 'modules/search-stages/components/RejectionField';
import withCandidacyUpdateForm from '../withCandidacyUpdateForm';

/**
 * Renders a form for moving a candidacy to the rejected stage.
 */
const CandidacyRejectionForm = ({
  // props provided by `withCandidacyUpdateForm`
  formState,
  onCancel,
  onFieldStateChange,

  // Props passed through/expected from parent component.
  onSubmit,
  submitButtonLabel,
}) => (
  <Form
    className='CandidacyRejectionForm'
    formState={formState}
    onSubmit={onSubmit}
  >
    <RejectionField
      disabled={formState.isSubmitting()}
      fieldState={formState.getFieldState()}
      onChange={onFieldStateChange}
      showErrors={formState.wasSubmitted() || 'blurred'}
    />
    <FormErrorMessage formState={formState} />
    <div className='Form__footer'>
      <CancelButton onClick={onCancel} />
      <SubmitButton formState={formState} onClick={onSubmit}>
        {submitButtonLabel}
      </SubmitButton>
    </div>
  </Form>
);

CandidacyRejectionForm.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the modal should be closed.
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * The label for the submit button.
   */
  submitButtonLabel: PropTypes.node,
};

CandidacyRejectionForm.defaultProps = {
  submitButtonLabel: 'Save',
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('CandidacyRejectionForm(enhanced))'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,

    /**
     * Called when the record was saved.
     */
    onSaved: PropTypes.func.isRequired,

    /**
     * Called when the cancel button is clicked. Called wi  th the current formState
     */
    onCancel: PropTypes.func.isRequired,

    /**
     * The ID of the stage
     */
    stageId: PropTypes.number,
  }),
  withCandidacyUpdateForm({
    createFieldState: (
      name,
      { candidacy, defaultRejectionReason, stageId },
    ) => {
      const fieldState = RejectionField.createFieldState(name, {
        rejection: candidacy,
        defaultRejectionReason: defaultRejectionReason,
      });

      return fieldState.setNestedField(FieldState.create('stage_id', stageId));
    },
  }),

  withHandlers({
    onCancel: ({ formState, onCancel }) => () => onCancel(formState),
  }),

  /**
   * Prevent props from being passed on to the underlying field
   */
  /* eslint-disable no-unused-vars */
  mapProps(
    ({
      candidacyId,
      defaultRejectionReason,
      onFormStateChange,
      onResetFormState,
      ...rest
    }) => ({
      ...rest,
    }),
  ),
  /* eslint-enable no-unused-vars */
)(CandidacyRejectionForm);

import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withState,
  withHandlers,
  withStateHandlers,
} from 'recompose';
import Modal from 'modules/core/componentsLegacy/Modal';
import PrimaryPositionForm from './PrimaryPositionForm';
import ContactPositionForm from './ContactPositionForm';
import mapContactIdToContact from '../mapContactIdToContact';

const MODE_EDIT = 'MODE_EDIT';
const MODE_SELECT = 'MODE_SELECT';
const MODE_CREATE = 'MODE_CREATE';

const FORM_MODES = [MODE_EDIT, MODE_SELECT, MODE_CREATE];

const TITLES_BY_MODE = {
  [MODE_EDIT]: 'Edit Primary Position',
  [MODE_SELECT]: 'Select New Primary Position',
  [MODE_CREATE]: 'Create New Primary Position',
};

const PrimaryPositionModal = ({
  contactId,
  formMode,
  hidePrimaryPositionModal,
  onClose,
  onCloseCreateCompanyModal,
  onCreateCompanyClick,
  onModeButtonClick,
  positionId,
}) => {
  const nextMode = FORM_MODES[FORM_MODES.indexOf(formMode) + 1];

  return (
    <Modal
      className='PrimaryPositionModal'
      dialogClassName={classnames('modal-lg', {
        'u-hidden': hidePrimaryPositionModal,
      })}
      enforceFocus={!hidePrimaryPositionModal}
      onHide={onClose}
      show={true}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>{TITLES_BY_MODE[formMode]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formMode === MODE_EDIT && (
          <ContactPositionForm
            contactId={contactId}
            layout='narrow'
            onCancel={onClose}
            onCloseCreateCompanyModal={onCloseCreateCompanyModal}
            onCreateCompanyClick={onCreateCompanyClick}
            onSaved={onClose}
            positionId={positionId}
          />
        )}
        {formMode === MODE_SELECT && (
          <PrimaryPositionForm
            contactId={contactId}
            onCancel={onClose}
            onSaved={onClose}
            positionId={positionId}
          />
        )}
        {formMode === MODE_CREATE && (
          <ContactPositionForm
            contactId={contactId}
            layout='narrow'
            onCancel={onClose}
            onCloseCreateCompanyModal={onCloseCreateCompanyModal}
            onCreateCompanyClick={onCreateCompanyClick}
            onSaved={onClose}
            setAsPrimary={true}
          />
        )}
        {nextMode && (
          <div className='PrimaryPositionModal__footer'>
            <span className='PrimaryPositionModal__footer-text'>OR</span>
          </div>
        )}
        {nextMode && (
          <button
            className='PrimaryPositionModal__nextButton btn btn-default'
            onClick={onModeButtonClick}
            type='button'
            value={nextMode}
          >
            {TITLES_BY_MODE[nextMode]}
          </button>
        )}
      </Modal.Body>
    </Modal>
  );
};

PrimaryPositionModal.propTypes = {
  contactId: PropTypes.number.isRequired,
  formMode: PropTypes.string.isRequired,

  /**
   * True to add a display: none to the modal, false to show the modal.
   */
  hidePrimaryPositionModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,

  /**
   * A function that is called when the create company is saved
   * or closed and determines if this modal should be shown or not.
   */
  onCloseCreateCompanyModal: PropTypes.func,

  /**
   * A function that is called when the create company btn is clicked
   * and determines if this modal should be shown or not.
   */
  onCreateCompanyClick: PropTypes.func,
  onModeButtonClick: PropTypes.func.isRequired,
  positionId: PropTypes.number,
};

export default compose(
  setDisplayName('PrimaryPositionModal(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    positionId: PropTypes.number,
  }),
  mapContactIdToContact,
  withState('formMode', 'setFormMode', ({ contact, positionId }) => {
    if (positionId) {
      return MODE_EDIT;
    }

    if (contact.get('positions').size > 0) {
      return MODE_SELECT;
    }

    return MODE_CREATE;
  }),
  withHandlers({
    onModeButtonClick: ({ setFormMode }) => ({ currentTarget }) =>
      setFormMode(currentTarget.value),
  }),
  withStateHandlers(
    { hidePrimaryPositionModal: false },
    {
      onCreateCompanyClick: () => () => ({
        hidePrimaryPositionModal: true,
      }),
      onCloseCreateCompanyModal: () => () => ({
        hidePrimaryPositionModal: false,
      }),
    },
  ),
)(PrimaryPositionModal);

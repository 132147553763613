import React from 'react';
import PropTypes from 'prop-types';
import SearchTitle from './SearchTitle';

const SearchTitles = ({ notification, search, searchLinks, searches }) => {
  return (
    <div className='notification-related-to-searches'>
      <h6>Related to </h6>
      {search ? (
        <SearchTitle
          url={searchLinks?.candidate_panel || searchLinks?.self}
          {...search}
        />
      ) : (
        searches &&
        searches.map(eachSearch => (
          <SearchTitle
            key={eachSearch?.id}
            tab={notification?.type === 'outreach' ? '/outreach' : null}
            url={eachSearch?.links.self}
            {...eachSearch}
          />
        ))
      )}
    </div>
  );
};

SearchTitles.propTypes = {
  notification: PropTypes.shape({
    type: PropTypes.string,
  }),
  search: PropTypes.shape({
    client_company: PropTypes.shape({
      name: PropTypes.string,
    }),
    job_title: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  searches: PropTypes.arrayOf(
    PropTypes.shape({
      client_company: PropTypes.shape({
        name: PropTypes.string,
      }),
      job_title: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  searchLinks: PropTypes.shape({
    candidate_panel: PropTypes.string,
    self: PropTypes.string,
  }),
};

export default SearchTitles;

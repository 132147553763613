import React from 'react';
import PropTypes from 'prop-types';
import './QuickViewPanelHeader.scss';

const QuickViewPanelHeader = ({ button, subTitle, title }) => {
  const secondaryTitle = subTitle && (
    <>
      <br /> {subTitle}
    </>
  );
  return (
    <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c u-marginVertical-8 QuickViewPanelHeader'>
      <h2 className='u-margin-n'>
        {title} {secondaryTitle}
      </h2>
      {button}
    </div>
  );
};

QuickViewPanelHeader.propTypes = {
  button: PropTypes.node,
  subTitle: PropTypes.node,
  title: PropTypes.node.isRequired,
};

export default QuickViewPanelHeader;

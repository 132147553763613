import { connect } from 'react-redux';
import getAddress from '../selectors/getAddress';

/**
 * A higher order component which maps `addressId` to it's address record.
 */
export default connect(
  (state, { addressId }) => ({
    address: addressId && getAddress(state, addressId),
  }),
  {},
);

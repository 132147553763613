/* eslint-disable react/prop-types */
// ^ This file is doing weird inline component stuff.
import React from 'react';

import AddressProperty from 'modules/addresses/components/AddressProperty';
import PrimaryAddressCheckIcon from 'modules/addresses/components/PrimaryAddressCheckIcon';
import AddressDeleteButton from 'modules/addresses/components/AddressDeleteButton';

import {
  COLUMN_ADDRESS_LINE_1,
  COLUMN_ADDRESS_LINE_2,
  COLUMN_CITY,
  COLUMN_COUNTRY,
  COLUMN_EDIT_DELETE,
  COLUMN_LABEL,
  COLUMN_POSTAL_CODE,
  COLUMN_PRIMARY_ADDRESS,
  COLUMN_STATE,
} from '../../constants';
import CompanyAddressModalButton from './CompanyAddressModalButton';

export default {
  [COLUMN_PRIMARY_ADDRESS]: {
    key: COLUMN_PRIMARY_ADDRESS,
    title: 'Primary',
    isSortable: false,
    Cell: ({ datum: addressId, primaryAddressId }) => (
      <PrimaryAddressCheckIcon
        addressId={addressId}
        primaryAddressId={primaryAddressId}
      />
    ),
  },
  [COLUMN_LABEL]: {
    key: COLUMN_LABEL,
    title: 'Label',
    isSortable: false,
    Cell: ({ datum: addressId }) => (
      <AddressProperty addressId={addressId} propertyName='label' />
    ),
  },
  [COLUMN_ADDRESS_LINE_1]: {
    key: COLUMN_ADDRESS_LINE_1,
    title: 'Address Line 1',
    isSortable: false,
    Cell: ({ datum: addressId }) => (
      <AddressProperty addressId={addressId} propertyName='address_line_1' />
    ),
  },
  [COLUMN_ADDRESS_LINE_2]: {
    key: COLUMN_ADDRESS_LINE_2,
    title: 'Address Line 2',
    isSortable: false,
    Cell: ({ datum: addressId }) => (
      <AddressProperty addressId={addressId} propertyName='address_line_2' />
    ),
  },
  [COLUMN_CITY]: {
    key: COLUMN_CITY,
    title: 'City',
    isSortable: false,
    Cell: ({ datum: addressId }) => (
      <AddressProperty addressId={addressId} propertyName='city' />
    ),
  },
  [COLUMN_STATE]: {
    key: COLUMN_STATE,
    title: 'State',
    isSortable: false,
    Cell: ({ datum: addressId }) => (
      <AddressProperty addressId={addressId} propertyName='state' />
    ),
  },
  [COLUMN_COUNTRY]: {
    key: COLUMN_COUNTRY,
    title: 'Country',
    isSortable: false,
    Cell: ({ datum: addressId }) => (
      <AddressProperty addressId={addressId} propertyName='country_code' />
    ),
  },
  [COLUMN_POSTAL_CODE]: {
    key: COLUMN_POSTAL_CODE,
    title: 'Postal Code',
    isSortable: false,
    Cell: ({ datum: addressId }) => (
      <AddressProperty addressId={addressId} propertyName='postal_code' />
    ),
  },
  [COLUMN_EDIT_DELETE]: {
    key: COLUMN_EDIT_DELETE,
    title: '',
    isSortable: false,
    Cell: ({ datum: addressId, parentId, primaryAddressId }) => (
      <span className='pull-right'>
        <CompanyAddressModalButton
          addressId={addressId}
          companyId={parentId}
          primaryAddressId={primaryAddressId}
        >
          <i className='fa fa-pencil' />
        </CompanyAddressModalButton>
        <AddressDeleteButton addressId={addressId} />
      </span>
    ),
  },
};

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import TableScrollContainer from '@thrivetrm/ui/components/TableScrollContainer';
import { SortDirections } from './tableConstants';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const BulkActionTable = ({
  hasRowHighlight,
  headers,
  isCompact,
  isLoading,
  isPlain,
  onSelect,
  onSortChange,
  rows,
  selections,
  sortDirection,
  sortKey,
}) => {
  const ids = rows.map(row => row.id);
  const hasSelectedAll = selections.length === ids.length;
  const hasSelectedSome = selections.length > 0 && !hasSelectedAll;

  const handleSelectAll = () =>
    selections.length > 0 ? onSelect([]) : onSelect(ids);

  const handleSelect = id => {
    const isSelected = selections.includes(id);
    const newSelections = isSelected
      ? selections.filter(selectionId => selectionId !== id)
      : [...selections, id];

    onSelect(newSelections);
  };

  const tableScrollContainerClassName = classnames({
    'table--isPlain': isPlain,
  });

  const tableClassName = classnames({
    'table--rowHighlight': hasRowHighlight,
    'table--isCompact': isCompact,
  });

  return (
    <LoadingContainer isLoading={isLoading}>
      <TableScrollContainer className={tableScrollContainerClassName}>
        <table className={tableClassName}>
          <TableHeader
            hasSelectedAll={hasSelectedAll}
            hasSelectedSome={hasSelectedSome}
            headers={headers}
            onSelect={handleSelectAll}
            onSortChange={onSortChange}
            sortDirection={sortDirection}
            sortKey={sortKey}
          />
          <tbody>
            {rows.map(({ attributes, id }) => (
              <TableRow
                attributes={attributes}
                id={id}
                isSelected={selections.includes(id)}
                key={id}
                onSelect={handleSelect}
              />
            ))}
          </tbody>
        </table>
      </TableScrollContainer>
    </LoadingContainer>
  );
};

BulkActionTable.defaultProps = {
  hasRowHighlight: true,
  isCompact: false,
  isLoading: false,
  isPlain: false,
  onSortChange: null,
  sortDirection: null,
  sortKey: null,
};

BulkActionTable.propTypes = {
  hasRowHighlight: PropTypes.bool,
  // Ideally this is not disabled, fix is to define the headers object shape
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCompact: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPlain: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSortChange: PropTypes.func,
  // Ideally this is not disabled, fix is to define the row object shape
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  selections: PropTypes.arrayOf(PropTypes.number).isRequired,
  sortDirection: PropTypes.oneOf(Object.values(SortDirections)),
  sortKey: PropTypes.string,
};

export default BulkActionTable;

import moment from 'moment';

/**
 * A function to validate that one time is before the other in a range
 * (as required by Google Calendar, for example).
 *
 * @param  {[string]} startFieldKey The name of the field to validate, representing
 *  the beginning of the time range.
 * @param  {[string]} endFieldKey   The name of the field to validate, representing
 * the end of the time range.
 * @return null (if valid) or a string (if invalid)
 */

const timeRange = (startFieldKey, endFieldKey) => fieldState => {
  const startValue = fieldState.getNestedFieldValue(startFieldKey);
  const endValue = fieldState.getNestedFieldValue(endFieldKey);

  if (moment(startValue, 'LT').isSameOrAfter(moment(endValue, 'LT'))) {
    return 'Start time must be before end time';
  }

  return null;
};

export default timeRange;

import PropTypes from 'prop-types';
import React from 'react';

import ActivityListView from 'modules/activities/components/ActivityListView';
import { PARENT_SEARCH } from 'modules/activities/constants';
import SearchPageSidebarPopover from './SearchPageSidebarPopover';

/**
 * Renders the "activity" popover content, which shows all activity related to the given search.
 */
const ActivityPopover = ({ searchId, searchPage }) => (
  <SearchPageSidebarPopover
    className='ActivityPopover'
    searchId={searchId}
    searchPage={searchPage}
  >
    <ActivityListView
      parentId={searchId}
      parentType={PARENT_SEARCH}
      title={<h2>All Recent Activity</h2>}
    />
  </SearchPageSidebarPopover>
);

ActivityPopover.propTypes = {
  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,

  /**
   * The current search page selected and displayed in the content portion of the search page
   * ("candidates", "applicants", "target_companies", etc)
   */
  searchPage: PropTypes.string.isRequired,
};

export default ActivityPopover;

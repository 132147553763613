/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import React from 'react';
import PropTypes from 'prop-types';
import { componentFromProp } from 'recompose';
import classnames from 'classnames';
import SortIndicator from '@thrivetrm/ui/components/SortIndicator';
import { Alignments, Widths } from './propTypes';

const HeaderWrapper = componentFromProp('component');

/**
 * Renders a single cell within a table header row. Includes a sort indicator
 * if the row is sortable and is currently being sorted on.
 *
 * The underlying compoment is wrapped in a `HeaderWrapper`, which is
 * either rendered as a 'span' element (when it is not sortable), or as an
 * 'a' element (when it is sortable).
 */
const TableHeadCell = ({
  alignment,
  className,
  component: Title,
  isFixed,
  onClick,
  sortKey,
  sortable,
  sorted,
  sortedAscending,
  width,

  // Additional props get passed into the underlying <th /> component
  ...props
}) => (
  <th
    {...props}
    className={classnames(
      'TableHeadCell',
      className,
      {
        'table__cell--isFixed': isFixed,
      },
      {
        'TableHeadCell--alignLeft': alignment === Alignments.LEFT,
        'TableHeadCell--alignCenter': alignment === Alignments.CENTER,
        'TableHeadCell--alignRight': alignment === Alignments.RIGHT,
      },
      {
        'th--xsmall': width === Widths.XSMALL,
        'th--small': width === Widths.SMALL,
        'th--medium': width === Widths.MEDIUM,
        'th--large': width === Widths.LARGE,
        'th--xlarge': width === Widths.XLARGE,
      },
      {
        'th--isSortable': sortable,
        'th--isSortKey': sorted,
      },
    )}
  >
    <HeaderWrapper
      component={sortable ? 'a' : 'span'}
      data-value={sortKey}
      onClick={sortable ? onClick : null}
    >
      {typeof Title === 'function' ? <Title {...props} /> : Title}
      {sortable && (
        <SortIndicator isAscending={sortedAscending} isSorted={sorted} />
      )}
    </HeaderWrapper>
  </th>
);

TableHeadCell.defaultProps = {
  alignment: Alignments.LEFT,
  className: null,
  component: null,
  isFixed: false,
  onClick: null,
  sortable: false,
  sorted: false,
  sortedAscending: false,
  sortKey: null,
  width: null,
};

TableHeadCell.propTypes = {
  alignment: PropTypes.oneOf(Object.values(Alignments)),

  /**
   * Additional class names to apply to the cell.
   */
  className: PropTypes.string,

  /**
   * The component/title to render in the cell.
   */
  component: PropTypes.oneOfType([
    // Something renderable (string, element, etc)
    PropTypes.node,
    // Assumed to be a react Component.
    PropTypes.func,
  ]),

  isFixed: PropTypes.bool,

  /**
   * Called when the cell is clicked, if it is sortable, indicating a sort change is desired.
   * Gets called with the click event generated. event.target.dataset.value contains
   * the `sortkey`.
   */
  onClick: PropTypes.func,

  /**
   * True to make this column sotrable. The header component will be wrapped in a
   * link ('a') element.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  sortable: PropTypes.bool,

  /**
   * True if this column is currently being sorted on. When true, a SortIndicator
   * will be rendered.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  sorted: PropTypes.bool,

  /**
   * True if this column (if sortable) is sorted in ascending order.
   * Determine how the SortIndicator is displayed.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  sortedAscending: PropTypes.bool,

  /**
   * A key identifying this column for sorting. This is passed to the header component
   * via a `data-value` property, and it can then be accessed via the `onClick` event
   * by using `event.target.dataset.value`
   */
  sortKey: PropTypes.string,

  width: PropTypes.oneOf(Object.values(Widths)),
};

export default TableHeadCell;

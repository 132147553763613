import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import {
  requestRecordList,
  setColumns,
} from 'modules/recordIndex/recordIndexSlice';
import RecordIndexSidebarSavedViews from './components/RecordIndexSidebarSavedViews';
import ConfigureColumnsModal from './components/ConfigureColumnsModal';
import ExportModal from './components/ExportModal';

const RecordIndexPageV5Header = () => {
  const dispatch = useDispatch();

  const { CreateButton, recordType } = useSelector(state => state.recordIndex);
  const [isExportModalOpen, openExportModal, closeExportModal] = useToggle(
    false,
  );

  const [
    isConfigureColumnsModalOpen,
    openConfigureColumnsModal,
    closeConfigureColumnsModal,
  ] = useToggle(false);

  const handleConfigureColumnsSave = columnKeys => {
    dispatch(setColumns(columnKeys));
    dispatch(requestRecordList());
    closeConfigureColumnsModal();
  };

  return (
    <div className='RecordIndexPage__v5Header'>
      <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween u-backgroundColor-darkBlue u-padding-16 u-marginBottom-16'>
        <div className='u-noWrap'>
          <h2 className='u-inlineBlock u-margin-n u-marginRight-16 u-textColor-white'>
            View
          </h2>
          <RecordIndexSidebarSavedViews isLegacyView={false} />
        </div>
        <div className='u-flex'>
          <ButtonPrimary
            icon='export'
            isDarkMode={true}
            isOutline={true}
            label='Export'
            onClick={openExportModal}
          />
          <ButtonPrimary
            className='u-marginHorizontal-12 u-noWrap'
            icon='editSpreadsheet'
            isDarkMode={true}
            isOutline={true}
            label='Edit Columns'
            onClick={openConfigureColumnsModal}
          />
          <CreateButton />
        </div>
      </div>
      {isExportModalOpen ? <ExportModal onClose={closeExportModal} /> : null}
      {isConfigureColumnsModalOpen ? (
        <ConfigureColumnsModal
          isOpen={true}
          onClose={closeConfigureColumnsModal}
          onSave={handleConfigureColumnsSave}
          recordType={recordType}
        />
      ) : null}
    </div>
  );
};

export default RecordIndexPageV5Header;

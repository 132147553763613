import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import SearchIndustryNameList from 'modules/searches/components/SearchIndustryNameList';
import mapDatumToCandidacyId from './mapDatumToCandidacyId';
import mapCandidacyIdToSearchId from '../mapCandidacyIdToSearchId';

/**
 * A candidacy table cell that renders the list of industries for the search that the candidacy
 * record is associated with.
 */
export default compose(
  setDisplayName('CandidacySearchIndustryListCell'),
  setPropTypes({
    /**
     * The candidacy ID
     */
    datum: PropTypes.number.isRequired,
  }),
  mapDatumToCandidacyId,
  mapCandidacyIdToSearchId,
)(SearchIndustryNameList);

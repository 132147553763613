import { connect } from 'react-redux';
import getInvestmentProperty from '../selectors/getInvestmentProperty';

/**
 * A Higher order component that groups a list of investmentIds by their "venture" company.
 */
export default connect(
  (state, { investmentIds }) => ({
    investmentIdGroups:
      investmentIds &&
      investmentIds.groupBy(id => getInvestmentProperty(state, id, 'venture')),
  }),
  {},
);

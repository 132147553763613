import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import Card from '@thrivetrm/ui/components/Card';

import connectOutreachActions from './connectOutreachActions';
import OutreachForm from './OutreachForm';
import OutreachView, {
  defaultProps as OutreachViewDefaultProps,
  propTypes as OutreachViewPropTypes,
} from './OutreachView';

import InlineEditActions from '../../../components/forms/InlineEditActions';

/**
 * Renders a single list item from an `OutreachList`
 */
export class OutreachListItem extends Component {
  state = {
    /**
     * True when the item is being edited inline, false when being viewed.
     * @type {Boolean}
     */
    isEditing: false,
  };

  /**
   * Called when the 'Yes' button of the delete confirmation is clicked.
   * Calls the action to delete the outreach and resets the action.
   */
  handleDeleteClick = () => {
    const { outreach, outreachActions } = this.props;
    this.setState({ isEditing: false });
    outreachActions.deleteOutreach({ id: outreach.get('id') });
  };

  /**
   * Called when the edit link is clicked, shows the edit form in place of
   * the outreach view.
   */
  handleEditClick = () => {
    this.setState({ isEditing: true });
  };

  /**
   * Called when the edit form is closed, removes the edit form and shows
   * the outreach view instead.
   */
  handleFormClose = () => {
    this.setState({ isEditing: false });
  };

  render() {
    const {
      candidacyId,
      outreach,
      readOnly,
      renderDate,
      renderDescription,
      renderRelatedSearches,
      renderTitleAsLink,
      searchId,
    } = this.props;
    const { isEditing } = this.state;
    const isDeleting = outreach.getIn(['_meta', 'isDeleting']);
    const canDelete = outreach.getIn(['permissions', 'delete']);
    const canEdit = outreach.getIn(['permissions', 'edit']);

    return (
      <li className='outreaches--outreach-list-item'>
        <Card isCentered={false}>
          {isEditing ? (
            <OutreachForm
              alwaysShowFieldErrors={true}
              draftStoragePath={{
                search: searchId,
                candidate: candidacyId,
                outreach: outreach.get('id'),
              }}
              errorDisplay='tooltip'
              onCancel={this.handleFormClose}
              onSaved={this.handleFormClose}
              outreach={outreach}
              searchId={searchId}
              title='Edit Outreach'
            />
          ) : (
            <OutreachView
              outreach={outreach}
              renderDate={renderDate}
              renderDescription={renderDescription}
              renderRelatedSearches={renderRelatedSearches}
              renderTitleAsLink={renderTitleAsLink}
              searchId={searchId}
            />
          )}
          <InlineEditActions
            canDelete={!isEditing && !readOnly && canDelete}
            canEdit={!isEditing && !readOnly && canEdit}
            deleteConfirmation='Are you sure you want to delete this outreach?'
            disabled={isDeleting}
            onDelete={this.handleDeleteClick}
            onEdit={this.handleEditClick}
          />
        </Card>
      </li>
    );
  }
}

export const propTypes = {
  candidacyId: PropTypes.number,

  outreach: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isDeleting: PropTypes.bool,
    }),
    permissions: ImmutablePropTypes.mapContains({
      delete: PropTypes.bool,
      edit: PropTypes.bool,
    }),
  }).isRequired,

  outreachActions: requiredIf(
    PropTypes.shape({
      deleteOutreach: PropTypes.func.isRequired,
    }),
    props => !props.readOnly,
  ),

  readOnly: PropTypes.bool,

  renderDate: OutreachViewPropTypes.renderDate,
  renderDescription: OutreachViewPropTypes.renderDescription,
  renderRelatedSearches: OutreachViewPropTypes.renderRelatedSearches,
  renderTitleAsLink: OutreachViewPropTypes.renderTitleAsLink,

  /**
   * If displaying this list in the context of a specific search, the ID of that search.
   */
  searchId: OutreachViewPropTypes.searchId,
};

export const defaultProps = {
  readOnly: false,
  renderDate: OutreachViewDefaultProps.renderDate,
  renderDescription: OutreachViewDefaultProps.renderDescription,
  renderRelatedSearches: OutreachViewDefaultProps.renderRelatedSearches,
  renderTitleAsLink: OutreachViewDefaultProps.renderTitleAsLink,
};

OutreachListItem.propTypes = propTypes;
OutreachListItem.defaultProps = defaultProps;

export default compose(
  setDisplayName('OutreachListItem(enhanced)'),
  // The connected version of this component does not require
  // the outreachActions propType.
  setPropTypes({
    outreach: propTypes.outreach,
    readOnly: propTypes.readOnly,
    searchId: propTypes.searchId,
  }),
  connectOutreachActions,
)(OutreachListItem);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PrimaryPositionModalButton from 'modules/contacts/components/positions/PrimaryPositionModalButton';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import withEditableSection from 'modules/contacts/components/withEditableSection';
import ApplyCustomOffLimitsIndicator from 'modules/off-limits/ApplyCustomOffLimitsIndicator';
import OffLimitsIndicator from 'modules/off-limits/OffLimitsIndicator';
import PositionCompanyName from 'modules/positions/components/PositionCompanyName';
import PositionName from 'modules/positions/components/PositionName';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import ContactAddressAs from 'modules/contacts/components/ContactAddressAs';
import ContactAverageRatingModalButton from 'modules/candidacies/components/ratings/ContactAverageRatingModalButton';
import ConnectionsButtonOverlay from 'modules/connections/components/ConnectionsButtonOverlay';
import { useSelector } from 'react-redux';
import getEntity from 'modules/entities/selectors/getEntity';
import contactAliases from 'modules/tenant/schemas/contactAliases';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import ContactHeaderForm from './ContactHeaderForm';
import ContactAvatarUploaderButton from './ContactAvatarUploaderButton';
import EditButton from './EditButton';

const ContactHeader = ({
  clearTransaction,
  companies,
  companyActions,
  contact,
  contactActions,
  contactId,
  contacts,
  duplicateDetectionId,
  isEditing,
  toggleEditing,
  transactions,
}) => {
  const positionId = contact.get('primary_position');
  const displayName = contact.get('full_name');
  const offLimits = contact.get('off_limits');
  const addressAs = contact.get('address_as');
  const connectionsCount = contact.get('connections_count');
  const hasContactAliases = useFeatureCheck('feature.contact_aliases');
  const aliases = useSelector(state =>
    contact
      .get('aliases')
      ?.toJS()
      .map(aliasId =>
        getEntity(state, contactAliases.key, aliasId).get('name'),
      ),
  );

  if (!isEditing) {
    return (
      <div className='contact-header'>
        <ConnectionsButtonOverlay
          buttonWrapperClassName='u-marginRight-4 u-marginBottom-4'
          connectionsCount={connectionsCount}
          contactId={contactId}
        >
          <ContactAvatarUploaderButton
            avatarUrl={contact.get('avatar_url')}
            contactId={contact.get('id')}
            useLegacyStyles={true}
          />
        </ConnectionsButtonOverlay>
        <div className='contact-information'>
          <h2 className='u-marginTop-n u-marginBottom-16'>
            <a
              className='u-textColor-gray60'
              href='#contactNameForm'
              onClick={toggleEditing}
            >
              {displayName}
            </a>
            <ContactAddressAs
              addressAs={addressAs}
              className='u-marginLeft-4 u-inlineBlock'
            />
            <EditButton onClick={toggleEditing} />
          </h2>
          {/*
            Apply Off Limits should only display for V2. Once all tenants have off
            limits v2, this check can be removed and the display of the indicators
            can become a ternary.
          */}
          {offLimits ? (
            <OffLimitsIndicator
              className='u-block'
              reason={offLimits.get('status')}
              recordId={contactId}
              recordName={displayName}
              recordType='contact'
              severity={offLimits.get('category')}
            />
          ) : (
            <ApplyCustomOffLimitsIndicator
              className='u-block'
              recordId={contactId}
              recordName={displayName}
              recordType='contact'
            />
          )}
          {hasContactAliases && aliases?.length > 0 ? (
            <LabeledContent className='u-wordBreak' inline={true} label='AKA'>
              {aliases.join(', ')}
            </LabeledContent>
          ) : null}
          <h4 className='u-marginVertical-8'>
            {positionId ? (
              <PositionName positionId={positionId} />
            ) : (
              '+ Add a Primary Position'
            )}
            <PrimaryPositionModalButton
              contactId={contactId}
              positionId={positionId}
            />
          </h4>
          <h5 className='u-marginBottom-2'>
            {positionId && (
              <PositionCompanyName
                linkToCompany={true}
                positionId={positionId}
              />
            )}
          </h5>
          <ContactAverageRatingModalButton contactId={contactId} />
        </div>
      </div>
    );
  }

  return (
    <ContactHeaderForm
      addressAs={addressAs}
      clearTransaction={clearTransaction}
      companies={companies}
      companyActions={companyActions}
      contact={contact}
      contactActions={contactActions}
      contacts={contacts}
      duplicateDetectionId={duplicateDetectionId}
      onCancel={toggleEditing}
      onSaved={toggleEditing}
      showEmailFields={false}
      transactions={transactions}
    />
  );
};

ContactHeader.propTypes = {
  clearTransaction: PropTypes.func.isRequired,

  companies: ImmutablePropTypes.map.isRequired, // eslint-disable-line react/forbid-prop-types

  companyActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }).isRequired,

  contact: ImmutablePropTypes.mapContains({
    full_name: PropTypes.string.isRequired,
    preferred_name: PropTypes.string,
    primary_position: PropTypes.number,
  }).isRequired,

  contactActions: PropTypes.shape({
    updateContact: PropTypes.func.isRequired,
  }).isRequired,

  contactId: PropTypes.number.isRequired,

  contacts: ImmutablePropTypes.map.isRequired, // eslint-disable-line react/forbid-prop-types

  duplicateDetectionId: PropTypes.string,

  isEditing: PropTypes.bool.isRequired,

  toggleEditing: PropTypes.func.isRequired,

  transactions: transactionsState.isRequired,
};

const ContactHeaderWithEditableSection = mapContactIdToContact(
  withEditableSection(ContactHeader, {
    title: 'Basic Information',
    sectionId: 'header',
    sectionClassName: 'editable-section-contact-header',
  }),
);

export default ContactHeaderWithEditableSection;

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import companySchema from '../companySchema';

/**
 * Gest a company's location field from it's company ID
 * @param {*} state
 * @param {Number} id The company ID
 * @returns {?String} The company location, if found.
 */
export default (state, id) =>
  getEntityProperty(state, companySchema.key, id, 'location');

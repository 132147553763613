import dirtyFormWarning from '../../lib/dirty_form_warning';
import autocomplete from '../../lib/autocomplete';
import displayModalErrors from '../../lib/display_modal_errors';

const { formatCurrency } = require('@thrivetrm/ui/utilities/numberUtils');
const relevanceFilter = require('../../lib/relevance_filter').default;
const formUserModal = require('../../lib/form_user_modal').default;
const assessmentAttributes = require('./assessment_attributes');
const initSelect2 = require('../../lib/select2');
const select2Autocomplete = require('../../lib/select2_autocomplete');
const initSelectsInModal = require('../../lib/modal_selects');
const validation = require('../../lib/validation');
const handleSimpleSearchSubmit = require('../../lib/handle_simple_search_submit');

const { replaceUserModal } = formUserModal;

const getRawNumber = formattedNumber => {
  return Number(formattedNumber.replaceAll(',', ''));
};

function prepare() {
  $(document).on('click', '.js-edit-document', function editDocument(e) {
    e.preventDefault();
    $(this).toggleClass('active');
    const field = $($(this).data('target')).toggle(100);
    $(this)
      .parent()
      .parent()
      .find('.contact-document')
      .toggle(100)
      .after(field);
  });

  $(document).on('click', '.js-delete-document', function deleteDocument(_e) {
    const id = $(this).data('target');
    $(`#document_${id}`).remove();
    $(`input[value=${id}]`).remove();
  });

  $(document).on(
    'click',
    '.js-document-file-type:radio[value=true]',
    function makeFileTrue() {
      const id = $(this).data('id');
      $(`.js_document_external_url_${id}`).removeClass('hidden');
      $(`.js_document_file_${id}`).addClass('hidden');
    },
  );

  $(document).on(
    'click',
    '.js-document-file-type:radio[value=false]',
    function makeExternalUrlTrue() {
      const id = $(this).data('id');
      $(`.js_document_file_${id}`).removeClass('hidden');
      $(`.js_document_external_url_${id}`).addClass('hidden');
    },
  );

  $(document).on(
    'change',
    '.form-control.string.url.optional',
    function displayUrlError(event) {
      const $urlInput = $(this);
      const url = $urlInput.val();
      const documentId = $urlInput.data('id');
      const $documentsSection = $urlInput.closest(
        '.search-details-form-documents',
      );
      const documentsValidator = validation.default($documentsSection);
      documentsValidator.add(
        `#document-external-url-error-${documentId}`,
        validation.validUrl({ url: url }),
      );
      const errors = documentsValidator.run();

      displayModalErrors(event, $documentsSection, errors);
    },
  );

  $(document).on(
    'change',
    '.js-indirect-billings',
    function indirectBillingsChange() {
      if ($(this).val() === 'Percentage of Search Fee') {
        $('.js-indirect-percentage').removeClass('hidden');
        $('.js-indirect-fixed-rate').addClass('hidden');
      } else if ($(this).val() === 'Fixed Rate') {
        $('.js-indirect-percentage').addClass('hidden');
        $('.js-indirect-fixed-rate').removeClass('hidden');
      }
    },
  );

  $(document).on(
    'change',
    '.js-off-limits-duration',
    function offLimitsDurationChange() {
      if ($(this).val() === 0 || $(this).val() === '0') {
        $('.begins-off-limits-input').addClass('hidden');
      } else {
        $('.begins-off-limits-input').removeClass('hidden');
      }
    },
  );

  $(document).on(
    'change',
    '.js-contract-terms-select',
    function contractTermsChange() {
      if ($(this).val() === 'Milestone Based Payment') {
        $('.search-payment-milestones').removeClass('hidden');
      } else {
        $('.search-payment-milestones').addClass('hidden');
      }
    },
  );

  $(document).on(
    'change',
    '.js-placement-type-select',
    function contractTermsChange() {
      const interimPlacementIds = $('#interim_placement_ids').data('id');
      if ($.inArray(parseInt(this.value), interimPlacementIds) >= 0) {
        $('#expected_interim_compensation').removeClass('hidden');
        $('#interim_compensation_offer').removeClass('hidden');
        $('.offer-end-date').show();
        $('#expected_compensation').addClass('hidden');
        $('#compensation_offered').addClass('hidden');
      } else {
        $('#expected_interim_compensation').addClass('hidden');
        $('#interim_compensation_offer').addClass('hidden');
        $('.offer-end-date').hide();
        $('#expected_compensation').removeClass('hidden');
        $('#compensation_offered').removeClass('hidden');
      }
    },
  );
}

const selects = [
  '.js-asset-class-select',
  '.js-begins-off-limits',
  '.js-client-team-members-select',
  '.js-contract_option-select',
  '.js-contract-terms-select',
  '.js-conversion-team-members-select',
  '.js-country-select',
  '.js-cost-center-select',
  '.js-external-id-select',
  '.js-currency-select',
  '.js-document-type-select',
  '.js-execution-team-members-select',
  '.js-execution-vertical-select',
  '.js-expected-fee-currency-select',
  '.js-external-search-firm-milestone-select',
  '.js-indirect-billings',
  '.js-industries',
  '.js-industry-categories',
  '.js-internal-team-members-select',
  '.js-job-function-select',
  '.js-lead-internal-team-member-select',
  '.js-level-select',
  '.js-off-limits-duration',
  '.js-organization-select',
  '.js-origination-team-members-select',
  '.js-origination-vertical-select',
  '.js-placement-type-select',
  '.js-product-area-select',
  '.js-recruiter-team-members-select',
  '.js-search-client-company-revenue-select',
  '.js-search-fee-currency-select',
  '.js-search-offer-currency-select',
  '.js-search-payment-milestone-label-select',
  '.js-search-payment-milestone-probability-select',
  '.js-search-probability-select',
  '.js-search-priority-select',
  '.js-search-prospect-stage-select',
  '.js-search-reason-select',
  '.js-search-region-select',
  '.js-search-state-select',
  '.js-search-status',
  '.js-search-team-role-select',
  '.js-sectors',
  '.js-smart-tags',
  'select.js-province-select',
  'select.js-state-select',
];

const initializeFilters = () => {
  initSelectsInModal('.advanced-search-modal');
  select2Autocomplete('.js-cities-filter', {
    dropdownParent: $('#search-filter-cities'),
  });

  select2Autocomplete('.js-purchase-order-number-filter', {
    dropdownParent: $('#search-filter-po'),
  });

  select2Autocomplete('.js-source-of-business-filter', {
    dropdownParent: $('#search-filter-source-of-business'),
  });
  select2Autocomplete('.js-investor-source-of-business-filter', {
    dropdownParent: $('#search-filter-investor-source-of-business'),
  });
  select2Autocomplete('.js-other-investors-filter', {
    dropdownParent: $('#search-filter-other-investors'),
  });
};

function initialize() {
  $('.external_id').tooltip({
    container: 'body',
  });
  $('.net-promoter-score').tooltip({
    container: 'body',
  });

  handleSimpleSearchSubmit(
    '.advanced-search-simple-search-form [name="filters[keyword]"]',
    '.advanced-search-simple-search-form',
  );

  // When a state is selected, if country is blank, populate the country_code with "US"
  $('select.js-state-select').on('select2:select', () => {
    if (!$('#search_country_code').val()) {
      $('#search_country_code').val('US').trigger('change');
    }
  });

  // Inits select2's in new search form and each search tab
  [
    '.new-search-form',
    '.edit-search-form',
    '.offer-form',
    '#search-team-form',
  ].forEach(form => {
    initSelect2(form, selects);
  });
  dirtyFormWarning('#search-form');

  assessmentAttributes.initializeAssessmentAttributes();

  $("[name='note[content]']").slice(1).css('width', '44em');

  relevanceFilter({
    form: '#search_search',
    search: '.search-filter-elastic',
    sort: '#search-sort-relevance',
  });

  const $canceledSelect = $('.canceled-reasons').remove();
  const $lostSelect = $('.lost-reasons').remove();
  const $closedSelect = $('.closed-reasons').remove();
  const closedReasonSelectSelectors = [
    '.js-search-closed-reasons-select',
    '.js-search-lost-reasons-select',
    '.js-search-canceled-reasons-select',
  ];

  const REASON_TYPE_SELECT_MAP = {
    Lost: $lostSelect,
    Canceled: $canceledSelect,
    Closed: $closedSelect,
  };

  closedReasonSelectSelectors.forEach(selector =>
    $(selector).attr('disabled', true),
  );

  const wipeAllClosedReasons = () =>
    closedReasonSelectSelectors.forEach(selector => $(selector).val(''));

  const showClosedReasonSelect = status => {
    const selectSelector = `.js-search-${status.toLowerCase()}-reasons-select`;
    $(selectSelector).attr('disabled', false);
    $('.closed-only')
      .fadeIn()
      .after(REASON_TYPE_SELECT_MAP[status])
      .after(
        initSelect2('.search-status', [selectSelector], {
          placeholder: `Select ${status} Reason`,
        }),
      );
    $(`.${status.toLowerCase()}-reasons`).fadeIn();
    $('.biz-dev-only').hide();
    const selectors = closedReasonSelectSelectors.filter(
      selector => selector !== selectSelector,
    );
    selectors.forEach(selector => $(selector).attr('disabled', true));
    selectors.forEach(selector => $(selector).val(''));
  };

  const handleStatusChange = (status, bizDevValue) => {
    if (status === bizDevValue) {
      wipeAllClosedReasons();
      $('.biz-dev-only').fadeIn();
      $('.closed-only').hide();
    } else if (Object.keys(REASON_TYPE_SELECT_MAP).includes(status)) {
      showClosedReasonSelect(status);
    } else {
      wipeAllClosedReasons();
      $('.biz-dev-only').fadeOut();
      $('.closed-only').fadeOut();
    }
  };

  // show the correct value
  handleStatusChange(
    $('.js-search-status').val(),
    $('.js-search-status').data('bizDevValue'),
  );

  $('.js-search-status').change(function handleClosedReasonSelects() {
    $lostSelect.remove();
    $canceledSelect.remove();
    $closedSelect.remove();
    $('.js-search-canceled-reasons-select').next('.select2-container').remove();
    $('.js-search-lost-reasons-select').next('.select2-container').remove();
    $('.js-search-closed-reasons-select').next('.select2-container').remove();
    handleStatusChange(this.value, $(this).data('bizDevValue'));
  });

  $('.js-document-type-select').on('change', event => {
    const $dateInput = $('.expiration-date-input');
    const $element = $(event.target);
    const expirableIds = $element.data('expirable-ids') || [];
    const selectedId = $element.val();

    if (expirableIds.includes(parseInt(selectedId))) {
      $dateInput.show();
    } else {
      $dateInput.hide();
    }
  });

  formUserModal.bindCopyLinkButton();
  formUserModal.handleOfficeLocation();

  $('.js-company-new-button').prop('disabled', true);

  if (
    $('#search_company_attributes_name').length &&
    $('#search_company_attributes_name').val().length > 0
  ) {
    $('.js-company-existing, .js-company-new').slideToggle(100);
  }

  $('#search_client_company_name').on('input', () => {
    if ($('#search_client_company_name').val().length < 3) {
      $('.js-company-new-button').fadeOut(300);
    }
  });

  // New Company form within New Search form
  $('.no-suggestion-notice').on('click', '.js-company-new-button', event => {
    event.preventDefault();
    $('#search_client_company_attributes_name').val(
      $('#search_client_company_name').val(),
    );
    $('.js-company-existing, .js-company-new').slideToggle(70, () => {
      $('.autocomplete-suggestions').hide();
    });
  });

  $('.js-search-business-development').on('click', _event =>
    [
      '.search_probability',
      '.search_expected_fee',
      '.search_prospect_stage',
      '.search_expected_fee_currency',
      '.search_anticipated_launch_date',
    ].map(selector => $(selector).toggleClass('hidden')),
  );

  $('.js-panel-contract').on(
    'click',
    '.js-search-additional-hires-clause',
    _event => $('.additional-hires-fee').toggleClass('hidden'),
  );
  $('#search-team').on('nested:fieldAdded', event =>
    event.field.find('.select-2').select2(),
  );

  $('.search-payment-milestones__content').on('nested:fieldAdded', event =>
    event.field.find('.select-2').select2(),
  );

  const $companyName = $(
    'input[data-autocomplete-endpoint]#search_client_company_name',
  );

  autocomplete($companyName, {
    serviceUrl: $companyName.data('autocompleteEndpoint'),
    onSelect: function (suggestion) {
      if (suggestion.data != null) {
        $('.js-company-new-button').fadeOut(300);
        $($companyName.data('autocompleteTarget')).val(suggestion.data.id);
        const addr = suggestion.data.primary_address;
        if (addr) {
          $('#search_address_line_1').val(addr.address_line_1);
          $('#search_address_line_2').val(addr.address_line_2);
          $('#search_city').val(addr.city);
          $('.js-state-select').val(addr.state).trigger('change');
          $('.js-province-select').val(addr.state).trigger('change');
          $('.search-province-input').val(addr.state);
          $('#search_postal_code').val(addr.postal_code);
          $('#search_country_code').val(addr.country_code).trigger('change');
        }

        $('.js-industries')
          .val(suggestion.data.industry_categories.map(i => i.id))
          .trigger('change');
        $('.js-industry-categories')
          .val(suggestion.data.industry_categories.map(i => i.id))
          .trigger('change');
        $('.js-sectors')
          .val(suggestion.data.sectors.map(s => s.id))
          .trigger('change');
      }
    },
    minChars: 3,
    // recommended for remote data calls
    deferRequestBy: 500,
    showNoSuggestionNotice: true,
    onSearchComplete: function (query, suggestions) {
      if (suggestions.length === 0) {
        const term =
          $('#search_client_company_name').val() ||
          $('#talent_pool_client_company_name').val();
        $('#talent_pool_client_company_attributes_name').val(term);
        $('#search_client_company_attributes_name').val(term);
        $('.js-company-new-button').prop('disabled', false);
        $('.js-company-new-button').fadeIn(500).find('strong').text(term);
        return {
          noSuggestionNotice: $('.no-suggestion-notice'),
        };
      }
      return {};
    },
  });

  $('#search_client_company_attributes_website_url').on('blur', () => {
    $.ajax({
      type: 'GET',
      url: '/api/v1/companies/check_for_dupes',
      dataType: 'json',
      data: { query: $('#search_client_company_attributes_website_url').val() },
      success: data => {
        if (data && data.matches.length > 0) {
          $('.website-url-warning').removeClass('hidden');
          $('.search_client_company_website_url').addClass('has-warning');
        } else {
          $('.website-url-warning').addClass('hidden');
          $('.search_client_company_website_url').removeClass('has-warning');
        }
      },
    });
  });

  $('#search_client_company_attributes_name').on('blur', () => {
    $.ajax({
      type: 'GET',
      url: '/api/v1/companies/check_for_dupes/?match_on=name',
      dataType: 'json',
      data: { query: $('#search_client_company_attributes_name').val() },
      success: data => {
        if (data && data.matches.length > 0) {
          $('.company-name-warning').removeClass('hidden');
          $('.search_client_company_name').addClass('has-warning');
        } else {
          $('.company-name-warning').addClass('hidden');
          $('.search_client_company_name').removeClass('has-warning');
        }
      },
    });
  });

  $('#search_firm_name').on('input', function searchFirmName(_e) {
    if (!$(this).val()) {
      $('.js-new-search-firm').fadeOut(300).parent().removeClass('has-error');
      $('#create_new_company').prop('checked', false);
    } else {
      $('.js-new-search-firm').fadeIn(300).parent().addClass('has-error');
    }
  });

  $('.js-update-search').on('click', e => {
    const target = $('li.active a[role="tab"]').data('form');
    $(target).submit();
    e.preventDefault();
  });

  $('.page-container .nav-tabs').stickyTabs();

  $('.search_confidential input').on('change', _event =>
    $('.search-code-name').toggle('show'),
  );

  $('[name="offer[accepted]"]').click(function handleOfferDateDiv() {
    const $button = $(this);
    const buttonId = $button.data('id');
    const buttonVal = $button.val();

    const $offerAcceptedDateDiv = $button
      .closest(`#edit_offer_${buttonId}`)
      .find('.offer-accepted-date');

    const $offerEmailFieldDiv = $button
      .closest(`#edit_offer_${buttonId}`)
      .find('.offer-email-fields');

    if (buttonVal === 'true') {
      $offerAcceptedDateDiv.show(500);
      $offerEmailFieldDiv.show(500);
    } else if (buttonVal === 'false') {
      $offerAcceptedDateDiv.hide(500);
      $offerEmailFieldDiv.hide(500);
      $(`[data-id="email_false_button_${buttonId}"]`).prop('checked', true);
    }

    return null;
  });

  $('[name="offer[compensation_attributes][bonus_type]"]').on(
    'change',
    function handleBonusTypeChange() {
      const candidateId = $(this).data('id');
      const bonusType = $(this).val();
      const bonusAmount = getRawNumber($(`#bonus-${candidateId}`).val());
      const percentageBonusAmount = getRawNumber(
        $(`#percentage-bonus-${candidateId}`).val(),
      );
      const base = getRawNumber($(`#base-${candidateId}`).val());
      if (bonusType === 'percentage') {
        const percentageOfBase =
          bonusAmount && base
            ? Number(((bonusAmount / base) * 100).toFixed(2))
            : bonusAmount;
        $(`#bonus-field-${candidateId}`).hide();
        $(`#percentage-of-base-field-${candidateId}`).show();
        $(`#bonus-helper-${candidateId}`).fadeIn();
        $(`#percentage-bonus-${candidateId}`)
          .val(percentageOfBase)
          .trigger('input');
      } else {
        const fixedBonus =
          percentageBonusAmount && base
            ? Math.round((base * percentageBonusAmount) / 100)
            : percentageBonusAmount;
        $(`#bonus-helper-${candidateId}`).hide();
        $(`#percentage-of-base-field-${candidateId}`).hide();
        $(`#bonus-field-${candidateId}`).show();
        // Update the field with the value and trigger a blur event so that the
        // value is formatted
        $(`#bonus-${candidateId}`).val(fixedBonus).trigger('blur');
      }
    },
  );

  const getBonusHelperText = (bonus, base, currency) => {
    const baseValue = Number(base);
    const bonusValue = Number(bonus);
    const isBasePositiveNumber = Number.isFinite(baseValue) && baseValue > 0;
    const isBonusPositiveNumber = Number.isFinite(bonusValue) && bonusValue > 0;
    if (isBasePositiveNumber && isBonusPositiveNumber) {
      const bonusAmount = Math.round((Number(bonus) * Number(base)) / 100);
      return `${bonus}% OF BASE SALARY = <b>${formatCurrency(
        bonusAmount,
        currency,
      )} BONUS</b>`;
    }
    return null;
  };

  $('[name="offer[compensation_attributes][percentage_bonus]"]').on(
    'input',
    function handleBonusChange() {
      const bonusField = $(this);
      const baseAmount = getRawNumber(
        $(`#base-${bonusField.data('id')}`).val(),
      );
      const bonusHelper = $(`#bonus-helper-${bonusField.data('id')}`);
      const currency = $(`#currency-${bonusField.data('id')}`).val();

      bonusHelper.html(
        getBonusHelperText(bonusField.val(), baseAmount, currency),
      );
    },
  );

  $('[name="offer[compensation_attributes][base]"]').on(
    'input',
    function handleBaseChange() {
      const baseField = $(this);
      const bonusPercentage = $(
        `#percentage-bonus-${baseField.data('id')}`,
      ).val();
      const bonusHelper = $(`#bonus-helper-${baseField.data('id')}`);
      const currency = $(`#currency-${baseField.data('id')}`).val();

      bonusHelper.html(
        getBonusHelperText(bonusPercentage, baseField.val(), currency),
      );
    },
  );

  $('[name="offer[compensation_attributes][currency]"]').on(
    'change',
    function handleCurrencyChange() {
      const id = $(this).data('id');
      $(`#percentage-bonus-${id}`).trigger('input');
    },
  );

  function hideCanadaField() {
    $('.address-form-canada').hide();
    $('.address-form-canada select')
      .val(null)
      .trigger('change')
      .prop('disabled', true);
  }

  function hideInternationalField() {
    $('.address-form-international').hide();
    $('.address-form-international input').val(null).prop('disabled', true);
  }

  function hideUsaField() {
    $('.address-form-usa').hide();
    $('.address-form-usa select')
      .val(null)
      .trigger('change')
      .prop('disabled', true);
  }

  function showUsaField() {
    $('.address-form-usa').show();
    $('.address-form-usa select').prop('disabled', false);
  }

  function showCanadaField() {
    $('.address-form-canada').show();
    $('.address-form-canada select').prop('disabled', false);
  }

  function showInternationalField() {
    $('.address-form-international').show();
    $('.address-form-international input').prop('disabled', false);
  }

  // Manages the state/province inputs. We render three inputs for :state. Ensure that one is always
  // hidden and disabled while the other is showing enabled
  function toggleProvince(element) {
    if (element.value === 'US' || !element.value) {
      hideCanadaField();
      hideInternationalField();
      showUsaField();
    } else if (element.value === 'CA') {
      hideInternationalField();
      hideUsaField();
      showCanadaField();
    } else {
      hideCanadaField();
      hideUsaField();
      showInternationalField();
    }
  }

  const $countryFields = $(
    '#search_country_code, #search_client_company_attributes_country_code',
  );

  // Add event for switching between state or province fields being displayed
  $countryFields.on('change', ({ target }) => toggleProvince(target));

  // Establish the initial state for which field should be shown
  $countryFields.each((_i, element) => toggleProvince(element));

  // Programmatically trigger an input event on the bonus percent field so that
  // code runs to calculate the bonus value based on percent (see getBonusHelperText)
  $(
    '[name="offer[compensation_attributes][percentage_bonus]"]',
  ).each((_index, inputEl) => $(inputEl).trigger('input'));

  initSelectsInModal('#create-user-modal');

  select2Autocomplete('.js-cities-filter', {
    dropdownParent: $('#search-filter-cities'),
  });

  select2Autocomplete('.js-purchase-order-number-filter', {
    dropdownParent: $('#search-filter-po'),
  });

  select2Autocomplete('.js-source-of-business-filter', {
    dropdownParent: $('#search-filter-source-of-business'),
  });

  select2Autocomplete('.js-investor-source-of-business-filter', {
    dropdownParent: $('#search-filter-investor-source-of-business'),
  });
}

export { replaceUserModal, prepare, initializeFilters, initialize };

export default {
  replaceUserModal: replaceUserModal,
  prepare: prepare,
  initializeFilters: initializeFilters,
  initialize: initialize,
};

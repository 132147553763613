import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Form from '@thrivetrm/ui/components/Form';
import Modal from '@thrivetrm/ui/components/Modal';
import { useApiPost } from 'modules/core/hooks/useApi';
import { convertToSnakeCase } from 'modules/core/jsonUtils';

const NewSavedViewModal = ({ onClose, onSuccess, savedViews }) => {
  const { endpoints, savedView } = useSelector(state => state.recordIndex);

  const [save, isCreatingAssessmentTemplate] = useApiPost(
    endpoints.savedViews(),
  );

  const handleSubmit = formData => {
    const savedViewData = convertToSnakeCase({
      savedView: { ...savedView, id: null, name: formData.savedViewName },
    });
    save(savedViewData, {
      onSuccess: newSavedView => {
        onSuccess(newSavedView);
        onClose();
      },
    });
  };

  const validateUniqueName = savedViewName => {
    const savedViewNames = savedViews.map(view => view.name);
    return savedViewNames.includes(savedViewName)
      ? 'The name you entered is already taken. Please enter a unique name.'
      : null;
  };

  return (
    <Modal isOpen={true} onClose={onClose} size='small' title='New Saved View'>
      <Form initialValues={{ savedViewName: '' }} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.TextInput
            inputWidth='full'
            label='View Name'
            name='savedViewName'
            rules={{
              validate: validateUniqueName,
              required: {
                value: true,
                message: 'Please enter a name for this view',
              },
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
          <Form.SubmitButton
            isDisabled={isCreatingAssessmentTemplate}
            isLoading={isCreatingAssessmentTemplate}
            label='Save'
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NewSavedViewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  // Ideally this is not disabled, fix is to define the saved view object's shape
  // eslint-disable-next-line react/forbid-prop-types
  savedViews: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NewSavedViewModal;

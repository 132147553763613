import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import jobFunctionSchema from '../schemas/jobFunctions';

/**
 * Renders the display name for a job function.
 */

const JobFunctionName = ({ jobFunctionId }) => (
  <EntityProperty
    entityId={jobFunctionId}
    entityType={jobFunctionSchema.key}
    propertyName='name'
  />
);

JobFunctionName.propTypes = {
  jobFunctionId: PropTypes.number.isRequired,
};

export default JobFunctionName;

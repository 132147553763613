import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { compose, lifecycle, setDisplayName, withHandlers } from 'recompose';
import fetchContactMethodListActionCreator from '../actions/contactMethods/fetchContactMethodList';
import getShouldFetchContactMethods from '../selectors/shouldFetchContactMethods';
import getContactMethodList from '../selectors/getAllContactMethods';

/**
 * Fetches the list of all contact methods if needed when a component is
 * mounted or it's props change.
 */
export default compose(
  setDisplayName('withContactMethodListFetched'),
  connect(
    state => ({
      shouldFetchContactMethods: getShouldFetchContactMethods(state),
      contactMethodList: getContactMethodList(state),
    }),
    dispatch => ({
      fetchContactMethodList: bindActionCreators(
        fetchContactMethodListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    /**
     * A handler that, when called, only fetches the introduction list if needed.
     */
    fetchContactMethodListIfNeeded: ({
      fetchContactMethodList,
      shouldFetchContactMethods,
    }) => () => shouldFetchContactMethods && fetchContactMethodList(),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchContactMethodListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchContactMethodListIfNeeded();
    },
  }),
);

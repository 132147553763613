import ContactSocialField from './ContactSocialField';
import createContactForm from '../createContactForm';

const createRootFieldState = props =>
  ContactSocialField.createFieldState('social', props.contact.get('data'));

const ContactSocialForm = createContactForm(
  ContactSocialField,
  createRootFieldState,
  'contact-social-form',
);

export default ContactSocialForm;

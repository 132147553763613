import AdvancedSelect from '@thrivetrm/ui/components/AdvancedSelect';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setStatic } from 'recompose';

import FieldState from '../FieldState';
import asField from './asField';
import withFormGroup from './withFormGroup';

export const propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
};

export default compose(
  setStatic('createFieldState', FieldState.create),
  setPropTypes(propTypes),
  withFormGroup,
  asField(),
)(AdvancedSelect);

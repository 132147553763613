import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  branch,
  componentFromProp,
  compose,
  defaultProps,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import DateTime from 'modules/datetime/components/DateTime';
import TimeLabel from 'modules/datetime/components/TimeLabel';
import getContactProperty from '../selectors/contacts/getContactProperty';

/**
 * Renders the date and/or time that a contact record was last modified.
 */
export default compose(
  setDisplayName('ContactDateModified'),
  setPropTypes({
    /**
     * The ID of the contact to show the last modified date for..
     */
    contactId: PropTypes.number.isRequired,

    /**
     * decides whether to render RelativeTimeLabel (when true) or DateTime (when false)
     */
    relative: PropTypes.bool.isRequired,
  }),
  defaultProps({
    relative: false,
  }),
  withProps({
    component: DateTime,
  }),
  connect(
    (state, { contactId }) => ({
      value: getContactProperty(state, contactId, 'updated_at'),
    }),
    {},
  ),
  branch(({ value }) => !value, renderNothing),
  branch(
    ({ relative }) => relative,
    mapProps(({ value, ...rest }) => ({
      ...rest,
      component: TimeLabel,
      time: value,
    })),
  ),
  mapProps(({ contactId: _contactId, relative: _relative, ...props }) => props),
)(componentFromProp('component'));

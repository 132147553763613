import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import CompanyFinancialsForm from './CompanyFinancialsForm';
import CompanyFinancialsView from './CompanyFinancialsView';
import mapCompanyIdToCompany from '../mapCompanyIdToCompany';

/**
 * Renders an EditableSection that displays and allows editing of a company's "financials" fields
 */
export default compose(
  setDisplayName('CompanyFinancialsEditableSection'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
  mapProps(({ company, companyId }) => ({
    companyId: companyId,
    children: CompanyFinancialsView.willRender({ company: company }) && (
      <CompanyFinancialsView companyId={companyId} />
    ),
    className: 'CompanyFinancialsEditableSection',
    formComponent: CompanyFinancialsForm,
    title: 'M&A and IPO',
  })),
)(EditableSection);

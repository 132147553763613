import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import revenueGrowthSchema from '../schemas/revenueGrowths';

/**
 * Renders the display name for a revenue growth option from it's ID.
 */

const RevenueGrowthName = ({ revenueGrowthId }) => (
  <EntityProperty
    entityId={revenueGrowthId}
    entityType={revenueGrowthSchema.key}
    propertyName='name'
  />
);

RevenueGrowthName.propTypes = {
  revenueGrowthId: PropTypes.number.isRequired,
};

export default RevenueGrowthName;

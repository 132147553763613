import {
  REPORTS_TYPE_INTRODUCTIONS,
  REPORTS_CREATE_START,
  REPORTS_CREATE_SUCCESS,
  REPORTS_CREATE_FAILURE,
} from './constants';
import createAjaxAction from '../createAjaxAction';

const createIntroductionsReport = createAjaxAction({
  getUrl: () => 'api/spreadsheet_reports',
  createStartPayload: () => ({
    reportType: REPORTS_TYPE_INTRODUCTIONS,
  }),
  getRequestOptions: ({
    companies,
    contacts,
    emails,
    endDate,
    format,
    startDate,
    users,
  }) => ({
    method: 'POST',
    body: JSON.stringify({
      resource: 'introduction',
      format: format,
      filters: {
        users: users ? users.map(user => user.id || user) : '',
        contacts: contacts ? contacts.map(contact => contact.id) : '',
        companies: companies,
        start_date: startDate,
        end_date: endDate,
      },
      emails: emails,
    }),
  }),
  start: REPORTS_CREATE_START,
  success: REPORTS_CREATE_SUCCESS,
  failure: REPORTS_CREATE_FAILURE,
});

export default createIntroductionsReport;

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setPropTypes,
  setDisplayName,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { PARENT_TYPES, DATE_GROUPS } from '../constants';
import * as interviewActionCreators from '../actions';
import getInterviewList from '../selectors/getInterviewList';
import getShouldfetchInterviewList from '../selectors/shouldFetchInterviewList';

/**
 * When provided a parentId, parentType, and optional filter via props, fetches the interviews
 * for that parent if they need to be fetched so they are available for a component.
 */
export default compose(
  setDisplayName('withInterviewListFetched'),
  setPropTypes({
    parentId: PropTypes.number,
    parentType: PropTypes.oneOf(PARENT_TYPES),
    filter: PropTypes.oneOf(DATE_GROUPS),
  }),

  connect(
    (state, { filter, parentId, parentType }) => ({
      interviewList: getInterviewList(state, parentType, parentId, filter),
      shouldfetchInterviewList: getShouldfetchInterviewList(
        state,
        parentType,
        parentId,
        filter,
      ),
    }),
    dispatch => ({
      interviewActions: bindActionCreators(interviewActionCreators, dispatch),
    }),
  ),

  // Create callbacks which are essentially bound to the parentType and parentId of the underlying
  // props, so that we can just call `fetchInterviewList()` without params from the consuming
  // component.
  withHandlers({
    fetchInterviewList: ({
      filter,
      interviewActions,
      parentId,
      parentType,
    }) => () => {
      interviewActions.fetchInterviewList({
        parentType: parentType,
        parentId: parentId,
        filter: filter,
      });
    },
    fetchInterviewListIfNeeded: ({
      filter,
      interviewActions,
      parentId,
      parentType,
      shouldfetchInterviewList,
    }) => () => {
      if (shouldfetchInterviewList) {
        interviewActions.fetchInterviewList({
          parentType: parentType,
          parentId: parentId,
          filter: filter,
        });
      }
    },
  }),

  // Fetch the activity list (if needed) when mounted or props change.
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchInterviewListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchInterviewListIfNeeded();
    },
  }),
);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import mapBulkImportIdToBulkImport from './mapBulkImportIdToBulkImport';

const BulkImportStatus = ({ isProcessing }) =>
  isProcessing ? <i>Processing</i> : 'Completed';

BulkImportStatus.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('BulkImportStatus(enhanced)'),
  setPropTypes({
    bulkImportId: PropTypes.number.isRequired,
  }),
  mapBulkImportIdToBulkImport,
  mapProps(({ bulkImport }) => ({
    isProcessing: !(bulkImport && bulkImport.get('end_time')),
  })),
)(BulkImportStatus);

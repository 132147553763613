import PluralText from '@thrivetrm/ui/components/PluralText';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import React from 'react';

// Number of days before showing discard alert message
const alertMessageDayLimit = 20;
// Number of days after which contact will be discarded
const contactDiscardDayLimit = 31;

export const handleAutoDiscardIndicator = contact => {
  const currentDate = new Date();
  const createdDate = new Date(contact?.created_at);
  const dayDifference = Math.ceil(
    Math.abs(currentDate - createdDate) / (1000 * 60 * 60 * 24),
  );

  if (
    dayDifference < alertMessageDayLimit ||
    dayDifference >= contactDiscardDayLimit
  ) {
    return null;
  }

  return (
    <div className='u-flex u-flexAlign-t'>
      <StatusIndicator
        className='u-marginTop-2 u-marginRight-8'
        status='error'
      />
      <p className='u-margin-n u-textColor-red'>
        <PluralText
          className='u-fontWeight-bold'
          quantity={contactDiscardDayLimit - dayDifference}
          shouldIncludeQuantity={true}
          text='day'
        />{' '}
        until contact is auto-discarded
      </p>
    </div>
  );
};

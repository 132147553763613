import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  setStatic,
  withProps,
  branch,
  renderComponent,
} from 'recompose';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import Card from '@thrivetrm/ui/components/Card';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import FieldState from 'modules/forms/FieldState';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import fetchUserOptionsIfNeeded from 'modules/user/components/fetchUserOptionsIfNeeded';
import getPermittedCalendarIntegration from 'modules/user/selectors/getPermittedCalendarIntegration';
import getCalendarIntegrationStatus from 'modules/user/selectors/getCalendarIntegrationStatus';
import isOptionLoaded from 'modules/user/selectors/isOptionLoaded';

/**
 * Transforms an immutable List of Maps containing calendar names and ids
 * to the array of objects [{ label, value }] that react-select expects as
 * options.
 */
const transformOptions = (calendars, clearableValue) =>
  calendars &&
  calendars
    .map(item => ({
      label: item.get('name'),
      value: item.get('id'),
      clearableValue: clearableValue,
    }))
    .toJS();

const LABELS = {
  google: 'Google',
  outlook: 'Outlook',
};

const CalendarSelectField = ({
  buttonClassName,
  calendarType,
  canClearCalendarSelection,
  isEditing,
  isIntegrated,
  options,
  url,
  ...props
}) => {
  const calendarHasBeenSelected = Boolean(props.fieldState.getValue());

  const editNotice = (
    <Card
      className='CalendarSelectField__noticeToUser CalendarSelectField__noticeToUser--isEditing'
      isCentered={false}
      type='warning'
    >
      <StatusIndicator size='medium' status='warning' />
      <p className='u-margin-n u-marginLeft-8 u-textColor-gray60'>
        Updates made in this form will be sent to all attendees
      </p>
    </Card>
  );
  const saveNotice = (
    <Card
      className='CalendarSelectField__noticeToUser CalendarSelectField__noticeToUser--isCreating'
      isCentered={false}
      type='general'
    >
      <StatusIndicator size='medium' status='info' />
      <p className='u-margin-n u-marginLeft-8 u-textColor-gray60'>
        Event details will be sent to all attendees
      </p>
    </Card>
  );

  const message = isEditing ? editNotice : saveNotice;

  return (
    <Card className='CalendarSelectField' isCentered={false} type='gray'>
      {isIntegrated ? (
        <>
          {calendarHasBeenSelected ? message : null}
          <ReactSelectField
            {...props}
            clearable={canClearCalendarSelection}
            label={`Add to ${LABELS[calendarType]} Calendar`}
            options={options}
            placeholder='Select a Calendar'
            simpleValue={true}
          />
        </>
      ) : (
        <a className={buttonClassName} href={url}>
          <i /> Integrate With Your Calendar
        </a>
      )}
    </Card>
  );
};

CalendarSelectField.propTypes = {
  buttonClassName: PropTypes.string.isRequired,
  calendarType: PropTypes.string.isRequired,
  canClearCalendarSelection: PropTypes.bool,
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  isEditing: PropTypes.bool,
  /**
   * Whether the user has an active calendar integration.  If not, the link
   * to the integrations page should be displayed instead of the dropdown.
   */
  isIntegrated: PropTypes.bool.isRequired,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  url: requiredIf(PropTypes.string, props => !props.hasIntegration),
};

export default compose(
  setStatic('createFieldState', FieldState.create),
  connect(
    state => ({
      calendar: getPermittedCalendarIntegration(state),
      isIntegrated: getCalendarIntegrationStatus(state),
      isLoaded: isOptionLoaded(state, 'integrations'),
    }),
    {},
  ),
  withProps(({ calendar, canClearCalendarSelection }) => {
    if (!calendar) {
      return {};
    }

    return {
      calendarType: calendar.get('type'),
      options: transformOptions(
        calendar.get('list'),
        canClearCalendarSelection,
      ),
      buttonClassName: `btn btn-social btn-social-${calendar.get(
        'type',
      )}_calendar`,
      url: '/users/integrations/calendar',
    };
  }),
  fetchUserOptionsIfNeeded,
  branch(({ isLoaded }) => !isLoaded, renderComponent(LoadingIndicator)),
)(CalendarSelectField);

/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import CompanyDetailsModalForm from 'modules/company-details-modal/components/CompanyDetailsModalForm';
import { withStateProvider } from './app/StateProvider';

/**
 * Renders a link that, when clicked, displays a modal with a form for configuring
 * Company Details, used for CASL and candidate portal, for the current tenant.
 */
const CompanyDetailModalLink = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLinkClick = event => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleModalClose = () => setIsModalOpen(false);

  return (
    <>
      <a href='#' onClick={handleLinkClick}>
        Company Details
      </a>
      {isModalOpen && (
        <CompanyDetailsModalForm onHide={handleModalClose} show={isModalOpen} />
      )}
    </>
  );
};

export default withStateProvider(CompanyDetailModalLink);

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';
import getPrimaryTitle from 'modules/contacts/selectors/contacts/getPrimaryTitle';

import getJobTitle from '../selectors/getJobTitle';
import mapContactIdToTalentPoolId from './mapContactIdToTalentPoolId';

/**
 * Renders the job title for a contact's talent pool; or if the contact has no talent pool,
 * renders their primary title.
 */
export const JobTitle = ({ jobTitle }) => (
  <span className='talent-pools--job-title'>{jobTitle}</span>
);

JobTitle.propTypes = {
  jobTitle: PropTypes.string,
};

export default compose(
  setDisplayName('connect(JobTitle)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToTalentPoolId,
  connect(
    (state, { contactId, talentPoolId }) => ({
      jobTitle: talentPoolId
        ? getJobTitle(state, talentPoolId)
        : getPrimaryTitle(state, contactId),
    }),
    {},
  ),
  onlyUpdateForPropTypes,
)(JobTitle);

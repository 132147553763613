import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { onlyUpdateForPropTypes } from 'recompose';
import SortByDropdownButton from 'modules/core/componentsLegacy/SortByDropdownButton';
import classnames from 'classnames';
import {
  SORT_BY_LABELS,
  SORT_BY_DEFAULT,
  SORT_ASCENDING_DEFAULT,
} from '../constants';

class SortAndFilterToolbar extends Component {
  handleChange = changes => {
    const {
      filterText,
      isDisabled,
      isSortAscending,
      onChange,
      sortBy,
    } = this.props;
    onChange({
      isDisabled: isDisabled,
      filterText: filterText,
      isSortAscending: isSortAscending,
      sortBy: sortBy,
      ...changes,
    });
  };

  handleFilterTextChange = event => {
    this.handleChange({ filterText: event.currentTarget.value });
  };

  handleSortChange = sortBy => {
    const { isSortAscending, sortBy: prevSortBy } = this.props;

    if (prevSortBy === sortBy) {
      this.handleChange({ isSortAscending: !isSortAscending });
    } else {
      this.handleChange({ sortBy: sortBy });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  render() {
    const {
      filterText,
      isDisabled,
      isSortAscending,
      sortBy,
      sortOptions,
    } = this.props;

    return (
      <form
        className='form recommendations--sort-and-filter-toolbar'
        onSubmit={this.handleSubmit}
        role='search'
      >
        <div className='input-group'>
          <input
            className='form-control'
            disabled={isDisabled}
            onChange={this.handleFilterTextChange}
            placeholder='Filter by name, company or email'
            type='text'
            value={filterText}
          />
          <SortByDropdownButton
            className={classnames('input-group-btn', {
              disabled: isDisabled,
            })}
            isAscending={isSortAscending}
            isDisabled={isDisabled}
            onChange={this.handleSortChange}
            options={sortOptions}
            value={sortBy}
          />
        </div>
      </form>
    );
  }
}

SortAndFilterToolbar.propTypes = {
  /**
   * The current text being filtered on and displayed in the input
   */
  filterText: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  /**
   * True if sorting should be in ascending order, false to sort in descending order.
   */

  isSortAscending: PropTypes.bool,

  /**
   * Called when an of the filter or sorting values are changed, where the first parameter is
   * an object of filter values:
   *   `onChange({ filterText, isPriorityOnly, isSortAscending, sortBy })`
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The value to sort on
   */
  sortBy: (props, propName, componentName) => {
    const { sortOptions, [propName]: value } = props;
    if (!value || (sortOptions && !sortOptions[value])) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`, ` +
          `value must match a key in the prop \`sortOptions\`. \`${value}\` ` +
          `not found in: ["${Object.keys(sortOptions).join('", "')}"]`,
      );
    }

    return null;
  },

  /**
   * An array of valid sort options for the `sortBy` property.
   */
  sortOptions: PropTypes.objectOf(PropTypes.string),
};

SortAndFilterToolbar.defaultProps = {
  filterText: '',
  isSortAscending: SORT_ASCENDING_DEFAULT,
  sortBy: SORT_BY_DEFAULT,
  sortOptions: SORT_BY_LABELS,
};

export default onlyUpdateForPropTypes(SortAndFilterToolbar);

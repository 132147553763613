import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Avatar from 'modules/core/componentsLegacy/Avatar';
import Modal from 'modules/core/componentsLegacy/Modal';
import CancelButton from 'modules/forms/components/CancelButton';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

class AvatarUploaderButton extends Component {
  constructor(props) {
    super(props);

    this.previews = [];

    /**
     * A file provided by the `react-dropzone` component.
     * @typedef {File} DropzoneFile @see {@link https://developer.mozilla.org/en-US/docs/Web/API/File}
     * @property {String} preview - A data URL for a preview of the dropped image, if available.
     *   This is added by the Dropzone component (unless `disablePreview` has been set to true on
     *   the `Dropzone`'s props)
     */

    this.state = {
      preview: null,

      /**
       * The avatar file selected by the user for upload.
       * @type {DropzoneFile}
       */
      avatarFile: null,

      /**
       * Holds the error from the last transaction if present.
       * @type {Error}
       */
      error: null,

      /**
       * Whether there have been changes to the Avatar form.
       * @type {Boolean}
       */
      isDirty: false,

      /**
       * Whether the modal should currently be opened.
       * @type {Boolean}
       */
      showModal: false,

      /**
       * ID of the currently tracked transaction for managing loading state.
       * @type {String}
       */
      transactionId: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { transactions } = this.props;
    const { transactionId } = this.state;
    const nextTransaction = nextProps.transactions.get(transactionId);
    const lastTransaction = transactions.get(transactionId);
    const isNewTransaction =
      nextTransaction && nextTransaction !== lastTransaction;
    if (
      isNewTransaction &&
      nextTransaction.isFinished &&
      !nextTransaction.payload.error
    ) {
      this.modalHideTimeout = setTimeout(() => this.handleModalHide(), 1000);
    } else if (
      isNewTransaction &&
      nextTransaction.isFinished &&
      nextTransaction.payload.error
    ) {
      this.setState({ error: nextTransaction.payload.error });
    }
  }

  componentWillUnmount() {
    if (this.modalHideTimeout) {
      clearTimeout(this.modalHideTimeout);
    }

    this.previews.forEach(preview => URL.revokeObjectURL(preview));
  }

  handleAvatarChange = acceptedFiles => {
    const file = acceptedFiles && acceptedFiles[0];
    const preview = file && URL.createObjectURL(file);
    if (preview) {
      this.previews.push(preview);
    }

    this.setState({
      avatarFile: file,
      preview: preview,
      error: null,
      isDirty: true,
    });
  };

  handleModalHide = () => {
    this.setState({
      avatarFile: null,
      preview: null,
      error: null,
      isDirty: false,
      showModal: false,
    });
  };

  handleModalShow = () => {
    this.setState({
      showModal: true,
    });
  };

  handleSubmit = () => {
    const { isDirty } = this.state;
    const { onSubmit } = this.props;
    const transactionId = uniqueId();

    if (!isDirty) {
      return;
    }

    if (onSubmit) {
      onSubmit(this.props, this.state, transactionId);
    }

    this.setState({
      avatarFile: null,
      isDirty: false,
      transactionId: transactionId,
    });
  };

  handleAvatarRemove = e => {
    /**
     * stopPropagation is used here to allow clicking on the remove avatar link
     * without triggering a click on the dropzone behind it.
     */
    e.stopPropagation();
    this.handleAvatarChange([null]);
  };

  render() {
    const {
      avatarFile,
      error,
      isDirty,
      preview,
      showModal,
      transactionId,
    } = this.state;
    const { avatarUrl, transactions } = this.props;
    const transaction = transactions.get(transactionId);
    const isSubmitting = transaction && !transaction.isFinished;

    return (
      <div>
        <button
          aria-label='Avatar image'
          className='contact-avatar-uploader-button u-padding-n u-backgroundColor-transparent u-border-n'
          onClick={this.handleModalShow}
          type='button'
        >
          <Avatar
            url={avatarUrl}
            useLegacyStyles={this.props.useLegacyStyles}
          />
        </button>

        <Modal
          className='contact-avatar-uploader-button-modal'
          onHide={this.handleModalHide}
          show={showModal}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Profile Photo Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Dropzone
              activeClassName='contact-file-uploader-dropzone-active'
              className='contact-file-uploader-dropzone'
              multiple={false}
              onDrop={this.handleAvatarChange}
            >
              <div className='text-center'>
                <div className='contact-avatar-uploader-placeholder'>
                  <div className='contact-avatar-text-span'>
                    {!isSubmitting ? (
                      <span>
                        {!avatarFile &&
                          !isDirty &&
                          !avatarUrl &&
                          'Choose a Photo'}
                        {!avatarFile &&
                          !isDirty &&
                          avatarUrl &&
                          'Current Photo'}
                        {!avatarFile && isDirty && 'Remove Photo'}
                        {avatarFile && 'Preview Photo'}
                      </span>
                    ) : (
                      <span>Loading</span>
                    )}
                  </div>
                  {isSubmitting ? (
                    <div className='contact-avatar'>
                      <i className='fa fa-spinner fa-spin' />
                    </div>
                  ) : (
                    <Avatar
                      url={(avatarFile && preview) || (!isDirty && avatarUrl)}
                      useLegacyStyles={true}
                    />
                  )}
                  <div className='contact-avatar-text-span'>
                    {!isSubmitting && (avatarUrl || avatarFile) && (
                      <button
                        className='btn btn-link contact-avatar-remove-link'
                        onClick={this.handleAvatarRemove}
                        type='button'
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
                <div className='contact-avatar-text-span'>
                  <span className='btn btn-link'>Choose a file</span>
                  <span> or drag / drop an image here.</span>
                </div>
              </div>
            </Dropzone>
          </Modal.Body>
          <Modal.Footer>
            <CancelButton bsSize='lg' onClick={this.handleModalHide} />
            <button
              className='btn btn-primary btn-lg'
              disabled={!avatarFile && !isDirty}
              onClick={this.handleSubmit}
              type='button'
            >
              {isSubmitting && (
                <i className='fa fa-spinner fa-spin u-marginRight-4' />
              )}
              Save
            </button>
            {error && (
              <span className='alert-danger contact-avatar-uploader-error pull-left'>
                Failed to upload file.
              </span>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

AvatarUploaderButton.propTypes = {
  avatarUrl: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  transactions: transactionsState.isRequired,
  useLegacyStyles: PropTypes.bool,
};

export default AvatarUploaderButton;

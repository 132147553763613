import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EditableSection from 'modules/forms/components/EditableSection';
import InteractionLevelsForm from './InteractionLevelsForm';
import InteractionLevelsView from './InteractionLevelsView';
import getContact from '../../selectors/contacts/getContact';
import getTenantOptionIds from '../../../tenant/selectors/getTenantOptionsIds';

const InteractionLevelsEditableSection = ({ contactId }) => {
  const hasInteractionLevelOptions = useSelector(
    state => getTenantOptionIds(state, 'interactionLevels')?.size,
  );

  const interactionLevelId = useSelector(state =>
    getContact(state, contactId).get('interaction_level'),
  );

  return hasInteractionLevelOptions ? (
    <EditableSection
      className='InteractionLevels__editableSection'
      contactId={contactId}
      formComponent={InteractionLevelsForm}
      title='Interaction Level'
    >
      {/* To render the 'Add New' icon set the children prop value to null */}
      {interactionLevelId ? (
        <InteractionLevelsView interactionLevelId={interactionLevelId} />
      ) : null}
    </EditableSection>
  ) : null;
};

InteractionLevelsEditableSection.propTypes = {
  contactId: PropTypes.number.isRequired,
};

export default InteractionLevelsEditableSection;

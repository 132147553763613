import React from 'react';
import PropTypes from 'prop-types';
import { stringToCamelCase } from 'modules/core/jsonUtils';
import { FilterInputTypes } from 'modules/recordIndex/recordIndexSlice';
import TextFilter from './TextFilter';
import CheckboxFilter from './CheckboxFilter';
import SingleSelectFilter from './SingleValueSelectFilter';
import RadioFilter from './RadioFilter';
import SelectFilter from './SelectFilter';
import DateFilter from './DateFilter';
import TextAreaFilter from './TextAreaFilter';
import MultiValueNumberFilter from './MultiValueNumberFilter';
import MultiValueTextBooleanFilter from './MultiValueTextBooleanFilter';
import MultiValueSelectBooleanFilter from './MultiValueSelectBooleanFilter';

const FilterInput = ({ label, name, type }) => {
  const normalizedName = stringToCamelCase(name);

  switch (type) {
    case 'text_area':
      return <TextAreaFilter label={label} name={normalizedName} />;
    case 'number':
      return (
        <TextFilter isNumeric={true} label={label} name={normalizedName} />
      );
    case 'percent':
      return (
        <TextFilter
          isNumeric={true}
          isPercent={true}
          label={label}
          name={normalizedName}
        />
      );
    case 'date':
      return <DateFilter label={label} name={normalizedName} />;
    case 'multi_autocomplete':
      return (
        <SelectFilter isAsync={true} label={label} name={normalizedName} />
      );

    case 'text':
      return <TextFilter label={label} name={normalizedName} />;
    case 'checkbox':
      return <CheckboxFilter label={label} name={normalizedName} />;
    case 'single_value_select':
      return <SingleSelectFilter label={label} name={normalizedName} />;
    case FilterInputTypes.MINIMUM_STAGED_REACHED:
      return <SingleSelectFilter label={label} name={normalizedName} />;
    case 'multi_value_number':
      return <MultiValueNumberFilter label={label} name={normalizedName} />;
    case 'multi_value_select':
      return (
        <SelectFilter isAsync={false} label={label} name={normalizedName} />
      );

    case 'multi_value_text_boolean':
      return (
        <MultiValueTextBooleanFilter label={label} name={normalizedName} />
      );
    case 'multi_value_select_boolean':
      return (
        <MultiValueSelectBooleanFilter label={label} name={normalizedName} />
      );
    case 'radio':
      return <RadioFilter label={label} name={normalizedName} />;
    default:
      throw new Error(
        `Unknown filter type "${type}" provided to FilterInput "${name}"`,
      );
  }
};

FilterInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'checkbox',
    'date',
    'multi_autocomplete',
    'multi_value_select_boolean',
    'multi_value_number',
    'multi_value_select',
    'multi_value_text_boolean',
    'minimum_stage_reached',
    'number',
    'percent',
    'radio',
    'single_value_select',
    'text_area',
    'text',
  ]).isRequired,
};

FilterInput.defaultProps = {
  label: null,
};
export default FilterInput;

import routes from 'modules/routing/routes';
import { createPostAction } from '../../../../actions/createAjaxAction';
import { contactSchema } from '../../schema';
import {
  DIRECT_REPORTS_CREATE_START,
  DIRECT_REPORTS_CREATE_SUCCESS,
  DIRECT_REPORTS_CREATE_FAILURE,
} from './actionTypes';

/**
 * Creates an action for creating a direct report relationship
 * @param {Object} options
 * @param {Number} options.contactId The ID of the contact who is being reported to
 * @param {Number} options.directReportId The ID of the direct report, who is reporting to the
 *   contact identified by `contactId`.
 */
const createDirectReport = createPostAction({
  getUrl: routes.api_v1_contact_direct_reports,
  start: DIRECT_REPORTS_CREATE_START,
  success: DIRECT_REPORTS_CREATE_SUCCESS,
  failure: DIRECT_REPORTS_CREATE_FAILURE,
  schema: { direct_report: contactSchema, contact: contactSchema },
});

export default createDirectReport;

import apiV1 from './index';
import queryTags from './queryTags';

const { DOCUMENTS } = queryTags;

const documents = apiV1.injectEndpoints({
  endpoints: builder => ({
    getDocumentTypes: builder.query({
      query: recordType => ({
        url: `/document_labels`,
        method: 'GET',
        params: { record_type: recordType },
      }),
    }),
    getDocuments: builder.query({
      query: ({ recordId, recordType }) => ({
        url: `${recordType}/${recordId}/documents`,
        method: 'GET',
      }),
      providesTags: [DOCUMENTS],
      transformResponse: response => response.documents,
    }),
    createDocument: builder.mutation({
      query: ({ documentTypeId, file, recordId, recordType }) => {
        const formData = new FormData();
        formData.append('document[document_label_id]', documentTypeId);
        formData.append('document[file]', file);
        return {
          url: `${recordType}/${recordId}/documents`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [DOCUMENTS],
    }),
    deleteDocumment: builder.mutation({
      query: ({ documentId, recordId, recordType }) => ({
        url: `${recordType}/${recordId}/documents/${documentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [DOCUMENTS],
    }),
    updateDocument: builder.mutation({
      query: ({ documentId, documentTypeId, recordId, recordType }) => {
        const formData = new FormData();
        formData.append('document[document_label_id]', documentTypeId);
        return {
          url: `${recordType}/${recordId}/documents/${documentId}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: [DOCUMENTS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateDocumentMutation,
  useDeleteDocummentMutation,
  useGetDocumentTypesQuery,
  useGetDocumentsQuery,
  useUpdateDocumentMutation,
} = documents;

import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

/**
 * Renders the toolbar displayed above the listing which shows a filter input
 * text box, and add task button
 */
class SortAndFilterToolbar extends Component {
  constructor(props) {
    super(props);
    this.componentId = uniqueId();
  }

  handleFilterTextChange = event => {
    const { onFilterTextChange } = this.props;
    onFilterTextChange(event.target.value);
  };

  render() {
    const { filterPlaceholder, filterText, handleShowTaskModal } = this.props;

    return (
      <div className='tasks-sort-filter-toolbar'>
        <div className='tasks-filter'>
          <input
            className='form-control'
            onChange={this.handleFilterTextChange}
            placeholder={filterPlaceholder}
            type='text'
            value={filterText}
          />
        </div>
        <OverlayTrigger
          overlay={<Tooltip id={this.componentId}>Add Task</Tooltip>}
          placement='top'
        >
          <button
            className='tasks-add-btn btn btn-primary btn-round pull-right'
            onClick={handleShowTaskModal}
            type='button'
          >
            <i className='fa fa-plus' />
          </button>
        </OverlayTrigger>
      </div>
    );
  }
}

SortAndFilterToolbar.defaultProps = {
  filterPlaceholder: 'Filter by Name or Task',
};

SortAndFilterToolbar.propTypes = {
  /**
   * The placeholder text to display in the filter input
   */
  filterPlaceholder: PropTypes.string,

  /**
   * The current filter text
   */
  filterText: PropTypes.string,

  handleShowTaskModal: PropTypes.func.isRequired,

  /**
   * Called when the filter text is changed, with the first parameter being the text itself:
   *   `onFilterTextChange(newFilterText)`
   */
  onFilterTextChange: PropTypes.func,
};

export default SortAndFilterToolbar;

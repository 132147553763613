import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  branch,
  defaultProps,
  setPropTypes,
  withProps,
} from 'recompose';
import withInterviewIdsSortedByDate from './withInterviewIdsSortedByDate';
import withInterviewIdsSortedByInterviewers from './withInterviewIdsSortedByInterviewers';
import withInterviewIdsSortedByCandidateName from './withInterviewIdsSortedByCandidateName';
import {
  SORT_BY_DATE,
  SORT_BY_INTERVIEWERS,
  SORT_BY_CANDIDATE_NAME,
} from '../constants';

/**
 * A higher order component that provides sorting of a list of interview IDs provided by an
 * `interviewIds` prop, based on the prop values of `sortBy` and `sortAscending`.
 */
export default compose(
  defaultProps({
    sortBy: SORT_BY_DATE,
    sortDescending: false,
  }),
  setPropTypes({
    sortBy: PropTypes.oneOf([
      SORT_BY_DATE,
      SORT_BY_INTERVIEWERS,
      SORT_BY_CANDIDATE_NAME,
    ]).isRequired,
    sortDescending: PropTypes.bool.isRequired,
    interviewIds: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  branch(({ sortBy }) => sortBy === SORT_BY_DATE, withInterviewIdsSortedByDate),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_INTERVIEWERS,
    withInterviewIdsSortedByInterviewers,
  ),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_CANDIDATE_NAME,
    withInterviewIdsSortedByCandidateName,
  ),
  withProps(({ interviewIds, sortDescending }) => ({
    interviewIds:
      interviewIds && sortDescending ? interviewIds.reverse() : interviewIds,
  })),
);

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  lifecycle,
  setDisplayName,
  setStatic,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import canViewCurrentCompensation from 'modules/compensations/selectors/canViewCurrentCompensation';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import SelectAllCheckbox from './SelectAllCheckbox';
import {
  CANDIDATE_INFORMATION_FIELDS,
  reportTypes,
  reportTypeFields,
} from './constants';
import withSearchSummaryReportFieldLabels from './withSearchSummaryReportFieldLabels';
/**
 * A field for selecting which candidate information fields to include in the search summary report.
 */
const SearchSummaryReportCandidateInfoField = ({
  allowCompensation,
  disabled,
  fieldLabels,
  fieldState,
  handleFieldChange,
  handleToggleAll,
  hasContactAliases,
  onBlur,
  onFocus,
  type,
}) => {
  const excludeFields = {
    compensation: !allowCompensation,
    aliases: !hasContactAliases,
  };

  // Determine which fields should be displayed based on the report type
  const displayedFields = reportTypeFields[type].filter(
    // filter out the fields based on the flipper status
    fieldName => !excludeFields[fieldName],
  );

  const isAllSelected = displayedFields.every(fieldName =>
    fieldState.getNestedField(fieldName).getValue(),
  );

  const candidateInfoCheckbox = fieldName => (
    <CheckboxField
      checked={fieldState.getNestedField(fieldName).getValue()}
      className='col-4'
      disabled={disabled}
      fieldState={fieldState.getNestedField(fieldName)}
      key={fieldName}
      onBlur={onBlur}
      onChange={handleFieldChange}
      onFocus={onFocus}
      text={fieldLabels[fieldName] || fieldName}
    />
  );

  return (
    <div className='SearchSummaryReportCandidateInfoField'>
      <h2>
        Candidate Information
        <SelectAllCheckbox
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleToggleAll}
          onFocus={onFocus}
          value={Boolean(isAllSelected)}
        />
      </h2>
      <div className='row'>
        {displayedFields.map(fieldName =>
          fieldName === 'recruiter_notes'
            ? fieldState.getNestedField(fieldName) && (
                <TooltipTrigger
                  key={fieldName}
                  placement='top'
                  tooltip='Displays the search related note pinned on the candidate notes section.'
                  trigger={['hover', 'focus']}
                >
                  {candidateInfoCheckbox(fieldName)}
                </TooltipTrigger>
              )
            : fieldState.getNestedField(fieldName) &&
              candidateInfoCheckbox(fieldName),
        )}
      </div>
    </div>
  );
};

SearchSummaryReportCandidateInfoField.propTypes = {
  /**
   * Whether the "compensation" field is available and should be displayed
   * (and allowed to be selected)
   */
  allowCompensation: PropTypes.bool.isRequired,

  /**
   * True to disable the entire field.
   */
  disabled: PropTypes.bool,

  /**
   * a mapping of search summary report field names to their labels
   */
  fieldLabels: PropTypes.objectOf(PropTypes.string),

  /**
   * The current value of this field.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the fieldState changes for a nested field of the `fieldState`.
   */
  handleFieldChange: PropTypes.func.isRequired,

  /**
   * Called when the "all" option is toggled. Argument is the event which triggered the
   * toggle, in which the `checked` property of the target is set to the desired value.
   */
  handleToggleAll: PropTypes.func.isRequired,

  hasContactAliases: PropTypes.bool,
  /**
   * Called when the field is blurred
   */
  onBlur: PropTypes.func.isRequired,

  /**
   * Called when the field is focused.
   */
  onFocus: PropTypes.func.isRequired,

  type: PropTypes.string,
};

SearchSummaryReportCandidateInfoField.createFieldState = (
  name = 'candidateInfo',
  { defaultValue = false, type = reportTypes.OVERVIEW, fieldNames = [] } = {},
  validator,
  // Ignored, we implement our own.
  _convertToRaw,
  ...rest
) =>
  FieldState.createNested(
    name,
    // Create an FieldState that includes all fields initially toggled off,
    // plus our 'type' field.
    CANDIDATE_INFORMATION_FIELDS.map(fieldName =>
      CheckboxField.createFieldState(
        fieldName,
        Boolean(fieldNames.includes(fieldName) || defaultValue),
      ),
    ).concat(FieldState.create('type', type)),
    // Passthrough any validator
    validator,
    fieldMap =>
      CANDIDATE_INFORMATION_FIELDS
        // Filter out only fields that are set to true and match the report type.
        .filter(fieldName =>
          reportTypeFields[fieldMap.type].includes(fieldName),
        )
        .filter(fieldName => fieldMap[fieldName]),

    ...rest,
  );

export default compose(
  setDisplayName('SearchSummaryReportCandidateInfoField(enhanced)'),
  setStatic(
    'createFieldState',
    SearchSummaryReportCandidateInfoField.createFieldState,
  ),
  withFeatureCheck('feature.contact_aliases', 'hasContactAliases'),
  connect(
    state => ({
      allowCompensation: canViewCurrentCompensation(state),
    }),
    {},
  ),
  withHandlers({
    handleFieldChange: ({ fieldState, onChange }) => childFieldState =>
      onChange(fieldState.setNestedField(childFieldState)),

    handleToggleAll: ({ fieldState, hasContactAliases, onChange }) => e => {
      const values = fieldState.getValue();

      // set all values (except 'reportType') to the checked value.
      const newValues = values.map(field =>
        field.getName() === 'type' ||
        // set all values (except 'aliases') to the checked value when feature.contact_aliases is not enabled.
        (!hasContactAliases && field.getName() === 'aliases')
          ? field
          : field.setValue(e.target.checked),
      );

      onChange(fieldState.setValue(newValues));
    },
  }),

  // Make sure the `isExpanded` property matches the underlying child fieldstate value, and
  // ensure that if `allowCompensation` is false that the underlying fieldState value is also
  // false.
  lifecycle({
    componentDidMount: function () {
      const {
        allowCompensation,
        fieldState,
        hasContactAliases,
        onChange,
        type,
      } = this.props;
      const aliasesField = fieldState.getNestedField('aliases');
      const compensationField = fieldState.getNestedField('compensation');
      const reportTypeField = fieldState.getNestedField('type');
      let nextFieldState = fieldState;

      if (reportTypeField.getValue() !== type) {
        nextFieldState = nextFieldState.setNestedField(
          reportTypeField.setValue(type),
        );
      }

      if (!allowCompensation && compensationField.getValue()) {
        nextFieldState = nextFieldState.setNestedField(
          compensationField.setValue(false),
        );
      }

      if (!hasContactAliases && aliasesField.getValue()) {
        nextFieldState = nextFieldState.setNestedField(
          aliasesField.setValue(false),
        );
      }
      if (nextFieldState !== fieldState) {
        onChange(nextFieldState);
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const { allowCompensation, fieldState, onChange, type } = nextProps;
      let nextFieldState = fieldState;
      const reportTypeField = fieldState.getNestedField('type');

      if (reportTypeField.getValue() !== type) {
        nextFieldState = fieldState.setNestedField(
          reportTypeField.setValue(type),
        );
      }

      if (
        allowCompensation !== this.props.allowCompensation &&
        !allowCompensation
      ) {
        // If allowCompensation has changed to false, make sure it's fieldState value is also false.
        const compensationField = fieldState.getNestedField('compensation');
        if (compensationField.getValue()) {
          nextFieldState = fieldState.setNestedField(
            compensationField.setValue(false),
          );
        }
      }

      if (nextFieldState !== nextProps.fieldState) {
        onChange(nextFieldState);
      }
    },
  }),
  withFormGroup,
  withSearchSummaryReportFieldLabels,
)(SearchSummaryReportCandidateInfoField);

import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import FieldState from '../FieldState';
import asField from './asField';
import withFormGroup from './withFormGroup';

export default compose(
  setDisplayName('SelectField'),
  setPropTypes({
    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', FieldState.create),
  withFormGroup,
  asField({
    handleValueChange: event => event.currentTarget.value,
    convertInputValue: value => value || '',
  }),
  withClassName('SelectField form-control'),
)('select');

import getSubdomain from 'modules/tenant/selectors/getSubdomain';
import getJobListingBySearchId from './getJobListingBySearchId';

const getJobListingUrlBySearchId = (state, searchId) => {
  const jobListing = getJobListingBySearchId(state, searchId);

  if (jobListing) {
    const subdomain = getSubdomain(state);
    return `https://${subdomain}.thriveapp.ly/job/${jobListing.get('id')}`;
  }

  return null;
};

export default getJobListingUrlBySearchId;

import { connect } from 'react-redux';
import getTagProperty from '../selectors/getTagProperty';

/**
 * A higher order component that filters out deleted tags based on their
 * _meta.isDeleted value
 * @param {*} state
 * @param {List} tagIds the list of all tag IDs in the tenant
 * @return {List} filtered tagIds
 */
export default connect(
  (state, { tagIds }) => ({
    tagIds:
      tagIds &&
      tagIds.filter(
        tagId => !getTagProperty(state, tagId, ['_meta', 'isDeleted']),
      ),
  }),
  {},
);

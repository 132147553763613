import React from 'react';
import { compose, setDisplayName, withProps } from 'recompose';
import GenerateReportModal from 'modules/reports/components/GenerateReportModal';
import CommissionsReportField from './CommissionsReportField';
import withCommissionsReportForm from './withCommissionsReportForm';

export default compose(
  setDisplayName('CommissionsReportModal'),
  withCommissionsReportForm,
  withProps(({ formState, handleFieldStateChange, isSubmitting }) => ({
    children: (
      <CommissionsReportField
        dateRangeInnerClassName='col-sm-6'
        dateRangeWrapperClassName='row'
        disabled={isSubmitting}
        fieldState={formState.getFieldState()}
        onChange={handleFieldStateChange}
      />
    ),
    sentTo: formState.getFieldState().getNestedField('emails').getValue(),
    title: 'Generate a Commissions Report',
  })),
)(GenerateReportModal);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { branch, compose, renderComponent } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import SortIndicator from '@thrivetrm/ui/components/SortIndicator';
import TalentPoolNameLink from './TalentPoolNameLink';
import IncumbentLink from './IncumbentLink';
import CompanyName from './CompanyName';
import JobFunction from './JobFunction';
import CandidacyCount from './CandidacyCount';
import CreateTalentPoolButton from './CreateTalentPoolButton';
import mapContactIdToTalentPoolId from './mapContactIdToTalentPoolId';

/**
 * The list of columns to display in the table. `key` is the field name -- pass in the callback
 * when that column is to be sorted on; `title` is the column's header title; `component` is
 * the component that is rendered for that column.
 */
const COLUMNS = [
  {
    key: 'company',
    title: 'Company',
    component: CompanyName,
  },
  {
    key: 'name',
    title: 'Position/Talent Pool Name',
    component: TalentPoolNameLink,
  },
  {
    key: 'incumbent_name',
    title: 'Incumbent',
    component: IncumbentLink,
  },
  {
    key: 'job_function',
    title: 'Function',
    component: JobFunction,
  },
  {
    key: 'candidacies_count',
    title: 'Pool Count',
    component: compose(
      mapContactIdToTalentPoolId,
      // This branch HOC renders the create button if no talent pool ID exists, otherwise it
      // renders the base component (CandidacyCount, in this case)
      branch(
        ({ talentPoolId }) => !talentPoolId,
        renderComponent(CreateTalentPoolButton),
      ),
    )(CandidacyCount),
  },
];

/**
 * Renders a table listing of talent pools/contact records.
 */
class TalentPoolsTableView extends PureComponent {
  handleHeaderClick = e => {
    const { onSortChange } = this.props;
    onSortChange(e.target.dataset.sortField);
  };

  render() {
    const { contactIds, isFetching, sortAscending, sortBy } = this.props;

    return (
      <div className='talent-pools-table'>
        {isFetching ? (
          <LoadingIndicator />
        ) : (
          <table className='table table-striped table--rowHighlight'>
            <thead>
              <tr>
                {COLUMNS.map(({ key, title }) => (
                  /* eslint-disable jsx-a11y/no-static-element-interactions */
                  <th
                    className={`column-${key}`}
                    data-sort-field={key}
                    key={key}
                    onClick={this.handleHeaderClick}
                  >
                    {title}
                    <SortIndicator
                      isAscending={Boolean(sortAscending)}
                      isSorted={sortBy === key}
                    />
                  </th>
                  /* eslint-enable jsx-a11y/no-static-element-interactions */
                ))}
              </tr>
            </thead>
            <tbody>
              {contactIds &&
                contactIds
                  .map(contactId => (
                    <tr key={contactId}>
                      {COLUMNS.map(({ component: ColumnComponent, key }) => (
                        <td className={`column-${key}`} key={key}>
                          <ColumnComponent
                            {...this.props}
                            contactId={contactId}
                          />
                        </td>
                      ))}
                    </tr>
                  ))
                  .toArray()}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

TalentPoolsTableView.propTypes = {
  contactIds: ImmutablePropTypes.listOf(PropTypes.number),

  /**
   * True if the contact records are currently being fetched, in which case a loading indicator
   * is shown.
   */
  isFetching: PropTypes.bool,

  /**
   * Called when the new talent pool form should be shown.
   * Not required directly by the table, but by the CreateTalentPoolButton
   */
  onCreateTalentPool: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types

  /**
   * Called when the sorting should be changed:
   *   onSortChange(fieldName, isAscending)
   */
  onSortChange: PropTypes.func.isRequired,

  /**
   * Whether the currently sorted field is sorted in ascending order.
   */
  sortAscending: PropTypes.bool,

  /**
   * The currently sorted field.
   */
  sortBy: PropTypes.string,
};

export default TalentPoolsTableView;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import fetchTagActionCreator from '../actions/fetchTag';
import shouldFetchTagSelector from '../selectors/shouldFetchTag';
import getTagSelector from '../selectors/getTag';
import isTagFetchedSelector from '../selectors/isTagFetched';

/**
 * Fetches a tag record from the server if it needs to be fetched.
 * @param {Object} options
 * @param {String} [options.idPropName="tagId"] The incoming propName that contains the ID of the
 *   tag to fetch.
 * @param {String} [options.dataPropName="tag"] The outgoing propName to use for supplying
 *   the tag record value.
 * @param {?String} [options.isDataReadyPropName] The propName to use for supplying a boolean value
 *   that indicates whether the tag has been fetched and is ready to be used or not.
 *   This is optional and if not given the underlying prop value will not be provided.
 */
export default ({
  dataPropName = 'tag',
  idPropName = 'tagId',
  isDataReadyPropName,
} = {}) =>
  compose(
    setDisplayName('withTagFetched'),

    setPropTypes({
      /**
       * The ID of the tag is required.
       */
      [idPropName]: PropTypes.number.isRequired,
    }),

    mapProps(props => ({
      tagId: props[idPropName],
      withTagFetchedProps: props,
    })),

    connect(
      (state, { tagId }) => ({
        shouldFetchTag: shouldFetchTagSelector(state, tagId),
        tag: getTagSelector(state, tagId),
        isTagFetched: isTagFetchedSelector(state),
      }),
      {
        fetchTag: fetchTagActionCreator,
      },
    ),

    withHandlers({
      fetchTagIfNeeded: ({ fetchTag, shouldFetchTag, tagId }) => () =>
        shouldFetchTag && fetchTag({ id: tagId }),
    }),

    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchTagIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchTagIfNeeded();
      },
    }),

    mapProps(({ isTagFetched, shouldFetchTag, tag, withTagFetchedProps }) => ({
      ...withTagFetchedProps,
      [dataPropName]: tag,

      // Only add the `isDataReadyPropName` prop if that prop name was supplied.
      ...(isDataReadyPropName
        ? {
            // The data is ready only if the tag has been fetched (it's in state) and `shouldFetchTag`
            // is false. This allows a component to, for example, disable a field or show a loader until
            // the tag data is available -- and `shouldFetchTag` alone is insufficient since it will be
            // false while loading is in progress.
            [isDataReadyPropName]: !shouldFetchTag && isTagFetched,
          }
        : null),
    })),
  );

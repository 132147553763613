import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import connectOutreachActions from 'modules/outreaches/components/connectOutreachActions';
import OutreachView from 'modules/outreaches/components/OutreachView';
import OutreachForm from 'modules/outreaches/components/OutreachForm';
import HubspotIndicator from 'modules/outreaches/components/HubspotIndicator';
import Notification from './Notification';

/**
 * Renders a notification with a resource type of `outreach`
 */
class NotificationItemTypeOutreach extends Component {
  state = {
    /**
     * True when showing the edit form.
     * @type {Boolean}
     */
    isEditing: false,
  };

  handleEdit = () => {
    this.setState({ isEditing: true });
  };

  handleDelete = () => {
    const { notification, outreachActions } = this.props;

    outreachActions.deleteOutreach({
      contactId: notification.getIn(['data', 'resource', 'data', 'contact_id']),
      id: notification.getIn(['data', 'resource', 'data', 'id']),
    });
  };

  handleCancel = () => {
    this.setState({ isEditing: false });
  };

  handleSaved = () => {
    this.setState({ isEditing: false });
  };

  render() {
    const {
      allowDelete,
      allowEdit,
      notification,
      showDetails,
      ...props
    } = this.props;
    const outreach = notification.getIn(['data', 'resource']);
    const outreachId = notification.getIn(['data', 'resource', 'data', 'id']);
    const contactId = notification.getIn([
      'data',
      'resource',
      'data',
      'contact_id',
    ]);
    const isHubspotOutreach = outreach.getIn(['data', 'hubspot_engagement_id']);
    const title = notification.getIn([
      'data',
      'resource',
      'data',
      'long_description',
    ]);

    const { isEditing } = this.state;
    return (
      <Notification
        canDelete={
          allowDelete && outreach.getIn(['data', 'permissions', 'delete'])
        }
        canEdit={allowEdit && outreach.getIn(['data', 'permissions', 'edit'])}
        introMessage={
          isHubspotOutreach ? (
            <div>
              {title}
              <HubspotIndicator />
            </div>
          ) : (
            title
          )
        }
        notification={notification}
        onDelete={this.handleDelete}
        onEdit={this.handleEdit}
        {...props}
      >
        {showDetails && !isEditing && (
          <OutreachView
            outreachId={outreachId}
            renderDate={false}
            renderDescription={false}
            renderRelatedSearches={false}
          />
        )}
        {isEditing && (
          <OutreachForm
            allowRelatedSearchesEdit={true}
            asRichTextForm={true}
            draftStoragePath={{
              contact: contactId,
              outreach: outreachId,
            }}
            errorDisplay='tooltip'
            formActionProps={{
              submitButtonIcon: 'fa-send',
              submitLabel: 'Update Outreach',
              fillWidth: false,
            }}
            onCancel={this.handleCancel}
            onSaved={this.handleSaved}
            outreachId={outreachId}
          />
        )}
      </Notification>
    );
  }
}

NotificationItemTypeOutreach.propTypes = {
  /**
   * True to allow the outreach item to be deleted if the user is permitted.
   */
  allowDelete: PropTypes.bool,

  /**
   * True to allow the outreach item to be edited inline if the user is permitted.
   */
  allowEdit: PropTypes.bool,

  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      type: PropTypes.oneOf(['outreach']),
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          candidacy_ids: ImmutablePropTypes.map,
          contacted_on: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
          long_description: PropTypes.string,
          notes: PropTypes.string,
          search_ids: ImmutablePropTypes.list,
          search_names: PropTypes.string,
          subject: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,

  outreachActions: requiredIf(
    PropTypes.shape({
      deleteOutreach: PropTypes.func,
    }),
    props => props.allowEdit || props.allowDelete,
  ),

  /**
   * When true, the note details will be displayed.
   * @type {[type]}
   */
  showDetails: PropTypes.bool,
};

export default connectOutreachActions(NotificationItemTypeOutreach);

import React from 'react';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import PropTypes from 'prop-types';

const RecruitmentRatingDeleteModal = ({
  closeDeleteModal,
  deleteRating,
  isDeleteModalOpen,
  searchName,
}) => {
  return (
    <ConfirmationDialog
      cancelLabel='No, Keep Rating'
      confirmLabel='Yes, Delete Rating'
      isOpen={isDeleteModalOpen}
      onClose={closeDeleteModal}
      onConfirm={deleteRating}
      title='Delete Recruitment Rating?'
    >
      <p>
        Are you sure you want to delete this Recruitment Rating for {searchName}
        ?
      </p>
      <p>Notes associated with this Recruitment Rating will be deleted.</p>
      <br />
      <p>
        <b>This action cannot be undone.</b>
      </p>
    </ConfirmationDialog>
  );
};

export default RecruitmentRatingDeleteModal;

RecruitmentRatingDeleteModal.propTypes = {
  closeDeleteModal: PropTypes.func.isRequired,
  deleteRating: PropTypes.func.isRequired,
  isDeleteModalOpen: PropTypes.bool.isRequired,
  searchName: PropTypes.string.isRequired,
};

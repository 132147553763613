import routes from 'modules/routing/routes';
import { createPostAction } from '../../../actions/createAjaxAction';
import {
  TALENT_POOL_REPORT_CREATE_START,
  TALENT_POOL_REPORT_CREATE_SUCCESS,
  TALENT_POOL_REPORT_CREATE_FAILURE,
} from './actionTypes';

export default createPostAction({
  getUrl: routes.api_v1_talent_pool_report,
  start: TALENT_POOL_REPORT_CREATE_START,
  success: TALENT_POOL_REPORT_CREATE_SUCCESS,
  failure: TALENT_POOL_REPORT_CREATE_FAILURE,
});

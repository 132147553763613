import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import ExternalLink from 'modules/links/ExternalLink';
import routes from 'modules/routing/routes';

export default compose(
  setDisplayName('DocumentLink'),
  setPropTypes({
    documentUuid: PropTypes.string.isRequired,
  }),
  mapProps(({ documentUuid, externalUrl, ...rest }) => ({
    ...rest,
    href: externalUrl || routes.document({ uuid: documentUuid }),
  })),
)(ExternalLink);

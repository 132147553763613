import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setDisplayName,
  onlyUpdateForPropTypes,
  withProps,
} from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  DASHBOARD_VIEW_TYPES,
  DASHBOARD_VIEW_TABLE,
  DASHBOARD_VIEW_DEFAULT,
} from '../constants';
import TalentPoolsCardView from './TalentPoolsCardView';
import TalentPoolsEmptyState from './TalentPoolsEmptyState';
import TalentPoolsTableView from './TalentPoolsTableView';
import withFilteredContactIds from './withFilteredContactIds';
import withSortedContactIds from './withSortedContactIds';

/**
 * Renders the contacts with a specific dashboard view, possibly showing an empty state component
 * if there are no contacts to render.
 */
export const DashboardView = ({
  allContactIds,
  contactIds,
  filterText,
  isFetching,
  onClearFilterText,
  onClearSelectedContact,
  onCreateTalentPool,
  onSortChange,
  selectedContactId,
  sortAscending,
  sortBy,
  view,
}) => {
  const ViewComponent =
    view === DASHBOARD_VIEW_TABLE ? TalentPoolsTableView : TalentPoolsCardView;

  return (
    <div className='talent-pools--dashboard-view'>
      <ViewComponent
        contactIds={contactIds}
        isFetching={isFetching}
        onCreateTalentPool={onCreateTalentPool}
        onSortChange={onSortChange}
        sortAscending={sortAscending}
        sortBy={sortBy}
      />
      {!isFetching && (!contactIds || contactIds.size === 0) && (
        <TalentPoolsEmptyState
          filterText={filterText.trim()}
          hasUnfiltered={allContactIds && allContactIds.size > 0}
          onClearFilterText={onClearFilterText}
          onClearSelectedContact={onClearSelectedContact}
          onCreateTalentPool={onCreateTalentPool}
          selectedContact={selectedContactId}
        />
      )}
    </div>
  );
};

DashboardView.propTypes = {
  /**
   * All contacts IDs, including those that have been filtered out by the filterText.
   */
  allContactIds: ImmutablePropTypes.listOf(PropTypes.number),

  /**
   * The list of contact IDs that should be displayed.
   */
  contactIds: ImmutablePropTypes.listOf(PropTypes.number),

  /**
   * The current search text used to filter allContactsIds to contactIds
   */
  filterText: PropTypes.string,

  /**
   * True if the list of contactIds is currently being fetched.
   */
  isFetching: PropTypes.bool,

  /**
   * Called when the filter text should be cleared
   */
  onClearFilterText: PropTypes.func,

  /**
   * Called when the selected contact should be cleared.
   */
  onClearSelectedContact: PropTypes.func,

  /**
   * Called when the modal allowing a user to create a talent pool should be shown.
   */
  onCreateTalentPool: PropTypes.func,

  /**
   * Called when the sort order is changed.
   */
  onSortChange: PropTypes.func,

  /**
   * The currently selected contact
   */
  selectedContactId: PropTypes.number,

  /**
   * True if contacts are sorted in ascending order, false if descending order.
   */
  sortAscending: PropTypes.bool.isRequired,

  /**
   * The current field that contacts are being sorted on
   */
  sortBy: PropTypes.string.isRequired,

  /**
   * The view type to render
   */
  view: PropTypes.oneOf(DASHBOARD_VIEW_TYPES),
};

DashboardView.defaultProps = {
  filterText: '',
  view: DASHBOARD_VIEW_DEFAULT,
};

export default compose(
  setDisplayName('connect(DashboardView)'),
  withProps(({ contactIds }) => ({ allContactIds: contactIds })),
  withSortedContactIds,
  withFilteredContactIds,
  onlyUpdateForPropTypes,
)(DashboardView);

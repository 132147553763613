import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';

import MultiSelect from '../../../components/forms/multiselect/MultiSelect';

import connectCompanyActions from './connectCompanyActions';
import mapQueryIdToCompanyQuery from './mapQueryIdToCompanyQuery';
import getCompany from '../selectors/getCompany';
import { FILTERS } from '../constants';

const idFromOption = opt => String(opt.get('id'));

const renderOptionLabel = opt => opt.get('name');

/**
 * A field for selecting multiples companies
 */
export class CompanyMultiSelect extends Component {
  state = {
    searchTerm: '',
  };

  componentDidMount() {
    const { companyActions, componentId } = this.props;
    companyActions.createCompaniesQuery(componentId);
  }

  componentWillUnmount() {
    const { companyActions, componentId } = this.props;
    companyActions.destroyCompaniesQuery(componentId);
  }

  handleSearchValueChange = term => {
    const { onSearchValueChange } = this.props;
    if (onSearchValueChange) {
      onSearchValueChange(term);
    }
    const searchTerm = stringUtils.toSortableString(term);
    this.setState({ searchTerm: searchTerm });

    if (searchTerm && searchTerm.length > 2) {
      const { companyActions, componentId } = this.props;
      companyActions.queryCompanies({
        queryId: componentId,
        term: searchTerm,
      });
    }
  };

  handleValueChange = value => {
    const { onChange } = this.props;
    onChange(value.map(item => (item.get ? item.get('id') : item)));
  };

  render() {
    const {
      isFetching,
      noCompaniesFoundElement,
      searchResults,
      selectedCompanies,

      ...props
    } = this.props;

    const { searchTerm } = this.state;

    return (
      <MultiSelect
        {...props}
        idFromOption={idFromOption}
        isLoading={isFetching}
        noSearchResultsText={
          isFetching ? 'Loading...' : noCompaniesFoundElement
        }
        noValueText='No companies selected'
        onChange={this.handleValueChange}
        onSearchValueChange={this.handleSearchValueChange}
        options={
          searchTerm ? searchResults && searchResults : selectedCompanies
        }
        renderOptionLabel={renderOptionLabel}
        value={selectedCompanies}
      />
    );
  }
}

CompanyMultiSelect.propTypes = {
  companyActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }).isRequired,

  componentId: PropTypes.string.isRequired,

  isFetching: PropTypes.bool.isRequired,

  noCompaniesFoundElement: PropTypes.node,

  noResultsText: PropTypes.string,

  onChange: PropTypes.func.isRequired,

  onSearchValueChange: PropTypes.func,

  /**
   * The list of companies that match the current search term.
   */
  searchResults: PropTypes.arrayOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),

  /**
   * The list of companies that are currently selected
   */
  selectedCompanies: PropTypes.arrayOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

CompanyMultiSelect.defaultProps = {
  noCompaniesFoundElement: 'No companies found',
  noResultsText: 'No matching companies found',
};

export default compose(
  setDisplayName('CompanyMultiSelectField'),
  setPropTypes({
    /**
     *  The fieldState supplied should contain a regular javascript array of numbers (company IDs)
     */
    // eslint-disable-next-line consistent-return
    fieldState: (props, propName, componentName) => {
      const fieldState = props[propName];

      if (!fieldState || !(fieldState instanceof FieldState)) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. ` +
            `Expected a FieldState but got ${typeof fieldState}`,
        );
      }

      const value = fieldState.getValue();
      if (!Array.isArray(value)) {
        return new Error(
          `Invalid FieldState value for \`${propName}\` prop supplied to \`${componentName}\`. ` +
            `Expected an array but got ${typeof value}`,
        );
      }

      // See if any elements of the array contain something other than a number.
      const nonNumberIndex = value.findIndex(
        element => typeof element !== 'number',
      );
      if (nonNumberIndex !== -1) {
        return new Error(
          `Invalid FieldState value for \`${propName}\` prop supplied to \`${componentName}\`. ` +
            `Expected an array of numbers but found \`${typeof value}\` at index ${nonNumberIndex}.`,
        );
      }
    },

    onChange: PropTypes.func,

    queryParams: PropTypes.shape({
      filter: PropTypes.oneOf(FILTERS),
    }),
  }),
  setStatic('createFieldState', (name, value, validator) =>
    FieldState.create(name, value ? [].concat(value) : [], validator),
  ),
  withFormGroup,
  asField(),
  connectCompanyActions,
  withComponentId(),
  mapQueryIdToCompanyQuery('componentId'),
  connect((state, { companyQuery, value }) => ({
    isFetching: Boolean(
      companyQuery && companyQuery.getIn(['meta', 'isFetching']),
    ),
    searchResults:
      companyQuery &&
      companyQuery.get('data') &&
      companyQuery
        .get('data')
        .map(id => getCompany(state, id))
        .toArray(),
    selectedCompanies: value && value.map(id => getCompany(state, id)),
  })),
)(CompanyMultiSelect);

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import CandidacyJobApplicationIndicator from 'modules/candidacies/components/CandidacyJobApplicationIndicator';
import CandidacyTargetCompanyCount from 'modules/candidacies/components/CandidacyTargetCompanyCount';
import CandidacyHiddenToggleButton from 'modules/candidacies/components/CandidacyHiddenToggleButton';
import mapCandidacyIdToContactId from 'modules/candidacies/components/mapCandidacyIdToContactId';
import mapCandidacyIdToJobApplicationId from 'modules/candidacies/components/mapCandidacyIdToJobApplicationId';
import ContactAvatar from 'modules/contacts/components/ContactAvatar';

/**
 * The strip of icons shown at the far left of the candidacy list item
 */
const CandidacyListItemIconStrip = ({
  candidacyId,
  className,
  contactId,
  jobApplicationId,
  smallCard,
  ...props
}) => (
  <div
    className={classnames(className, 'CandidacyListItemIconStrip')}
    {...props}
  >
    {!smallCard && <ContactAvatar contactId={contactId} size={30} />}
    <CandidacyHiddenToggleButton candidacyId={candidacyId} />
    <CandidacyTargetCompanyCount candidacyId={candidacyId} />
    {jobApplicationId && !smallCard && <CandidacyJobApplicationIndicator />}
  </div>
);

CandidacyListItemIconStrip.propTypes = {
  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * An optional classname to apply to the container div
   */
  className: PropTypes.string,

  /**
   * The ID of the contact that the candidacy is related to
   */
  contactId: PropTypes.number.isRequired,

  /**
   * If this candidacy was created from a job application via ThriveApply, the ID
   * of that job application record.
   */
  jobApplicationId: PropTypes.number,

  /**
   * Weather or not to show content on the 'small'
   * or 'large' candidacy card.
   */
  smallCard: PropTypes.bool,
};

export default compose(
  setDisplayName('CandidacyListItemIconStrip(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  mapCandidacyIdToContactId,
  mapCandidacyIdToJobApplicationId,
)(CandidacyListItemIconStrip);

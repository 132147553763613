import { NAME } from '../constants';

/**
 * Gets the query part of the current routing location.
 * @param {Object} state root state
 * @return {Immutable.Map} The current query part of the routing location converted to an immutable
 *   map. This is the result of calling `qs.parse` on the query string and deeply converting it
 *   to an immutable object.
 */
export default state => state[NAME].getIn(['location', 'query']);

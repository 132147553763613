import React from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { REVENUE_MAXIMUM_OPTIONS, REVENUE_MINIMUM_OPTIONS } from '../constants';

/**
 * Renders a minimum and optional maxmimum range of revenue values for display.
 */
const RevenueRangeValues = ({ maximumLabel, minimumLabel }) => (
  <div className='RevenueRangeValues'>
    <span className='RevenueRangeValues_minimum'>{minimumLabel}</span>
    {maximumLabel && <span className='RevenueRangeValues_seperator'> - </span>}
    {maximumLabel && (
      <span className='RevenueRangeValues_maximum'>{maximumLabel}</span>
    )}
  </div>
);

RevenueRangeValues.propTypes = {
  /**
   * The label for the maxmimum value.
   */
  maximumLabel: PropTypes.string,

  /**
   * The label for the minimum value.
   */
  minimumLabel: PropTypes.string,
};

RevenueRangeValues.defaultProps = {
  minimumLabel: '0',
};

/**
 * The exported version looks up the appropriate labels from the options constants.
 */
export default compose(
  setDisplayName('RevenueRangeValues(enhanced)'),
  setPropTypes({
    maximum: PropTypes.number,
    minimum: PropTypes.number,
  }),
  branch(
    ({ maximum, minimum }) =>
      typeof maximum !== 'number' && typeof minimum !== 'number',
    renderNothing,
  ),
  mapProps(({ maximum, minimum }) => {
    const maximumOption = REVENUE_MAXIMUM_OPTIONS.find(
      ({ value }) => maximum === value,
    );
    const minimumOption = REVENUE_MINIMUM_OPTIONS.find(
      ({ value }) => minimum === value,
    );

    return {
      maximumLabel:
        (maximumOption && maximumOption.label) ||
        (maximum > 0 ? `${maximum}M` : null),
      minimumLabel:
        (minimumOption && minimumOption.label) ||
        (minimum > 0 ? `${minimum}M` : '0'),
    };
  }),
)(RevenueRangeValues);

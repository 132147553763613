import { connect } from 'react-redux';
import getEmailTemplate from '../selectors/getEmailTemplate';

/**
 * A higher order component which maps a `emailTemplateId` prop value to it's record as
 * a prop `emailTemplate`.
 */
export default connect(
  (state, { emailTemplateId }) => ({
    emailTemplate: getEmailTemplate(state, emailTemplateId),
  }),
  {},
);

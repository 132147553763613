import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import { connect } from 'react-redux';
import NotificationsIndicator from '../components/notifications/NotificationsIndicator';
import * as notificationActions from '../actions/notifications';
import { NOTIFICATIONS_CATEGORY_UNREAD } from '../domains/notifications/constants';
import { withStateProvider } from './app/StateProvider';

export default compose(
  setDisplayName('NotificationsIndicator(container)'),
  setPropTypes({
    unreadCount: PropTypes.number,
  }),
  mapProps(({ unreadCount: initialCount }) => ({
    initialCount: initialCount,
  })),
  withStateProvider,
  connect(
    state => ({
      notifications: state.notifications.get(NOTIFICATIONS_CATEGORY_UNREAD),
    }),
    notificationActions,
  ),
)(NotificationsIndicator);

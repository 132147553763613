import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';
import getContactProperty from '../../selectors/contacts/getContactProperty';
import ContactExternalLink from './ContactExternalLink';

/**
 * Renders a contact's email address as a `mailto:` link
 */
export default compose(
  setDisplayName('ContactEmailLink'),
  setPropTypes({
    /**
     * The contact email address to use
     */
    type: PropTypes.oneOf(['email', 'work_email', 'preferred_email'])
      .isRequired,

    /**
     * True to use the email address itself as the tooltip.
     */
    addressAsTooltip: PropTypes.bool.isRequired,

    /**
     * True to fall back to personal email if an email of the given type is not found.
     */
    fallbackToPersonalEmail: PropTypes.bool,
  }),
  defaultProps({
    addressAsTooltip: false,
    fallbackToPersonalEmail: false,
    icon: 'fa-envelope',
    target: '_blank',
    tooltip: 'Email',
    type: 'preferred_email',
  }),
  connect(
    (
      state,
      { addressAsTooltip, contactId, fallbackToPersonalEmail, tooltip, type },
    ) => {
      const email = fallbackToPersonalEmail
        ? getContactProperty(state, contactId, type) ||
          getContactProperty(state, contactId, 'email')
        : getContactProperty(state, contactId, type);

      return {
        children: email,
        url: email && `mailto:${email}`,
        tooltip: addressAsTooltip ? email : tooltip,
      };
    },
    {},
  ),

  // Prevent these from being passed into the ContactExternalLink and being inadvertantly
  // added to the componentClass as props.
  mapProps(
    ({
      /* eslint-disable no-unused-vars */
      addressAsTooltip,
      contactId,
      fallbackToPersonalEmail,
      /* eslint-enable no-unused-vars */
      ...props
    }) => props,
  ),
)(ContactExternalLink);

import { withStateHandlers } from 'recompose';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 5;
const MAX_LIMIT = 100;

/**
 * A higher-order component creator for managing pagination state (page+limit)
 * @param {Object|Function} options The initial state of the pagination to set. Either an object
 *   with direct value, or a function that will be called with the current props, which should
 *   return an object with the initial values.
 * @param {Number} [options.initialPage=1] The initial page value
 * @param {Number} [options.initialLimit=5] The initial limit value
 * @param {Number} [options.allowNoLimit=true] True to allow the limit to be "unset" (i.e. set to
 *   "All" or unlimited -- fetching of all items at once)
 */
export default options =>
  withStateHandlers(
    props => {
      const {
        allowNoLimit = true,
        initialLimit = INITIAL_LIMIT,
        initialPage = INITIAL_PAGE,
        maxLimit = MAX_LIMIT,
      } = (typeof options === 'function' ? options(props) : options) || {};

      return {
        allowNoLimit: allowNoLimit,
        limit: initialLimit,
        page: Math.min(Math.max(1, initialPage), maxLimit),
      };
    },
    {
      // Ensure page >= 1
      onPageChange: (_, { onPageChange }) => page => {
        const newPage = Math.max(1, page);

        // If a parent component wants to get onPageChange events, notify them.
        if (onPageChange) {
          onPageChange(newPage);
        }

        return { page: newPage };
      },

      onLimitChange: (
        { allowNoLimit, limit: currentLimit },
        { maxLimit, onLimitChange },
      ) => limit => {
        if (!limit) {
          return allowNoLimit ? { limit: null, page: null } : {};
        }

        const newLimit = Math.max(1, limit);

        if (newLimit === currentLimit || newLimit > maxLimit) {
          return {};
        }

        // If a parent component wants to get onLimitChange events, notify them.
        if (onLimitChange) {
          onLimitChange(newLimit);
        }

        // TODO: Consider calculating the ideal page based on the limit change values?
        return {
          // Make sure limit is always >= 1 and when the limit changes reset the page.
          limit: newLimit,
          page: 1,
        };
      },
    },
  );

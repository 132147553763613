import { connect } from 'react-redux';
import getRevenueYear from '../selectors/getRevenueYear';

/**
 * A Higher order component that sorts a list of revenueIds in reverse chronological order by year
 */
export default connect(
  (state, { revenueIds }) => ({
    revenueIds:
      revenueIds &&
      revenueIds
        .sortBy(revenueId => [getRevenueYear(state, revenueId)])
        .reverse(),
  }),
  {},
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { EditorState } from 'draft-js';
import RichTextArea from '../components/forms/richtext/RichTextArea';
import { fromHtml, toHtml } from '../components/forms/richtext/convert';

/**
 * The RichTextEditor is meant to be used as a stand-alone react component that
 * allows editing rich text content with the Draft-js editor.
 * This also creates an associated hidden input whose value is updated in realtime
 * when the rich editor's content is updated. This allows the content to be
 * submitted using a typical <form />.
 */
class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    const { initialValue } = props;

    this.state = {
      editorState: initialValue
        ? EditorState.createWithContent(fromHtml(initialValue))
        : EditorState.createEmpty(),
      htmlValue: initialValue || '',
    };
  }

  handleChange = editorState => {
    this.setState({
      editorState: editorState,
      htmlValue: toHtml(editorState.getCurrentContent()),
    });
  };

  render() {
    const { initialValue, name, ...inputProps } = this.props; // eslint-disable-line no-unused-vars
    const { editorState, htmlValue } = this.state;

    return (
      <div className='rich-text-editor' style={{ position: 'relative' }}>
        <input
          {...inputProps}
          className='rich-text-editor-input'
          name={name}
          type='hidden'
          value={htmlValue}
        />
        <RichTextArea onChange={this.handleChange} value={editorState} />
      </div>
    );
  }
}

RichTextEditor.propTypes = {
  /**
   * The initial value (HTML/text) to populate the rich text input with.
   */
  initialValue: PropTypes.string,

  /**
   * The name to give to the underlying hidden input.
   */
  name: PropTypes.string.isRequired,
};

export default RichTextEditor;

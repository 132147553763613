import { compose, setDisplayName, withHandlers, withProps } from 'recompose';

export default compose(
  setDisplayName('IgnoreClickLink'),
  withProps(({ href }) => ({
    href: href || '#',
  })),
  withHandlers({
    onClick: ({ onClick }) => e => {
      e.preventDefault();

      if (onClick) {
        onClick(e);
      }
    },
  }),
)('a');

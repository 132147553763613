import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Form from '@thrivetrm/ui/components/Form';
import Grid from '@thrivetrm/ui/components/Grid';
import useFormValues from '@thrivetrm/ui/hooks/useFormValues';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import getCurrencySymbol from 'modules/currency/selectors/getCurrencySymbol';
import offerShape from './offerShape';

const InterimOffer = ({ offer }) => {
  const formState = useFormValues();
  const tenants = useSelector(state => state.tenant);
  const currencyCodes = tenants.get('currency_codes');
  const currencySymbol = useSelector(state =>
    getCurrencySymbol(state, formState?.currency),
  );

  return (
    <>
      <h3>Interim Compensation Offered</h3>
      <Grid className='u-marginBottom-16'>
        <Grid.Column className='u-paddingHorizontal-n' size={4}>
          <Form.SelectMenu
            className='u-marginRight-16'
            initialValue={
              offer?.interimPlacementCompensation?.currency || 'USD'
            }
            inputWidth='full'
            label='Currency'
            name='currency'
          >
            {currencyCodes?.map(currency => (
              <SelectMenu.Item key={currency} value={currency}>
                {currency}
              </SelectMenu.Item>
            ))}
          </Form.SelectMenu>
        </Grid.Column>
        <Grid.Column className='u-paddingHorizontal-4' size={8}>
          <Form.TextInput
            initialValue={offer?.interimPlacementCompensation?.dayRate}
            inputWidth='full'
            label='Day Rate'
            name='day_rate'
            prepend={currencySymbol}
          />
        </Grid.Column>
      </Grid>
    </>
  );
};

InterimOffer.propTypes = {
  offer: PropTypes.shape(offerShape),
};

export default InterimOffer;

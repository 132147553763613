/* eslint-disable camelcase */
// ^ accommodate API exchange format
import { push } from 'modules/routing/routingActions';
import routes from 'modules/routing/routes';
import { searchSchema } from 'modules/searches/schema';
import createCrudActions from '../../../actions/createCrudActions';
import {
  TALENT_POOLS_INVALIDATE,
  TALENT_POOLS_FETCH_START,
  TALENT_POOLS_FETCH_SUCCESS,
  TALENT_POOLS_FETCH_FAILURE,
  TALENT_POOL_INVALIDATE,
  TALENT_POOL_FETCH_START,
  TALENT_POOL_FETCH_SUCCESS,
  TALENT_POOL_FETCH_FAILURE,
  TALENT_POOL_CREATE_START,
  TALENT_POOL_CREATE_SUCCESS,
  TALENT_POOL_CREATE_FAILURE,
  TALENT_POOL_UPDATE_START,
  TALENT_POOL_UPDATE_SUCCESS,
  TALENT_POOL_UPDATE_FAILURE,
  TALENT_POOL_DELETE_START,
  TALENT_POOL_DELETE_SUCCESS,
  TALENT_POOL_DELETE_FAILURE,
} from './actionTypes';
import createReport from './createReport';

import getTalentPoolUrl from '../selectors/getTalentPoolUrl';
import getDashboardUrl from '../selectors/getDashboardUrl';

// This denormalizes the fields we normalized when we recieved the talent pool.
const createSubmitPayload = ({
  talent_pool: {
    client_company,
    client_team_members,
    current_contact,
    internal_team_members,
    job_function,
    lead_internal_team_member,
    lead_recruiter_team_member,
    ...talentPoolProps
  },
  ...options
}) => ({
  talent_pool: {
    current_contact_id: current_contact,
    client_company_id: client_company,
    job_function_id: job_function,
    lead_internal_team_member_id: lead_internal_team_member,
    lead_recruiter_team_member_id: lead_recruiter_team_member,
    internal_team_member_ids: internal_team_members,
    client_team_member_ids: client_team_members,
    ...talentPoolProps,
  },
  ...options,
});

const {
  create: createTalentPool,
  delete: deleteTalentPool,
  fetch: fetchTalentPool,
  fetchAll: fetchTalentPools,
  invalidate: invalidateTalentPool,
  invalidateAll: invalidateTalentPools,
  update: updateTalentPool,
} = createCrudActions({
  getUrl: routes.api_v1_talent_pool,
  schema: { talent_pool: searchSchema },
  invalidateAll: TALENT_POOLS_INVALIDATE,
  fetchAll: {
    getUrl: routes.api_v1_talent_pools,
    schema: { talent_pools: [searchSchema] },
    start: TALENT_POOLS_FETCH_START,
    success: TALENT_POOLS_FETCH_SUCCESS,
    failure: TALENT_POOLS_FETCH_FAILURE,
  },
  invalidate: TALENT_POOL_INVALIDATE,
  fetch: {
    start: TALENT_POOL_FETCH_START,
    success: TALENT_POOL_FETCH_SUCCESS,
    failure: TALENT_POOL_FETCH_FAILURE,
  },
  create: {
    getUrl: routes.api_v1_talent_pools,
    createStartPayload: createSubmitPayload,
    start: TALENT_POOL_CREATE_START,
    success: TALENT_POOL_CREATE_SUCCESS,
    failure: TALENT_POOL_CREATE_FAILURE,
  },
  update: {
    createStartPayload: createSubmitPayload,
    start: TALENT_POOL_UPDATE_START,
    success: TALENT_POOL_UPDATE_SUCCESS,
    failure: TALENT_POOL_UPDATE_FAILURE,
  },
  delete: {
    start: TALENT_POOL_DELETE_START,
    success: TALENT_POOL_DELETE_SUCCESS,
    failure: TALENT_POOL_DELETE_FAILURE,
  },
});

export {
  TALENT_POOL_CREATE_SUCCESS,
  fetchTalentPools,
  invalidateTalentPools,
  fetchTalentPool,
  invalidateTalentPool,
  createTalentPool,
  updateTalentPool,
  deleteTalentPool,
  createReport,
};

/**
 * Dispatches an action to navigate to a talent pool page
 * @param {Number} talentPoolId The ID of the talent pool to navigate to.
 * @return {Function} A thunk that will dispatch a navigation action.
 */
export const navigateToTalentPool = talentPoolId => (dispatch, getState) =>
  dispatch(push(getTalentPoolUrl(getState(), talentPoolId)));

/**
 * Dispatches an action to navigate to the dashboard page.
 * @param {Object} params The params for the dashboard page
 * @param {Number} [params.contactId] An optional contact ID to set as the selected incumbent.
 * @param {String} [params.view] The optional view type (one of `DASHBOARD_VIEW_TYPES`)
 * @return {Function} A thunk that will dispatch a navigation action.
 */
export const navigateToDashboard = params => (dispatch, getState) =>
  dispatch(push(getDashboardUrl(getState(), params)));

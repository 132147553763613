/**
 * Truncate any hierarchical tag nodes between the root node and the leaf node.
 *
 * 'Baking' yields 'Baking'
 * 'Baking > Biscuits' yields 'Baking > Biscuits'
 * 'Baking > Biscuits > Savoury' yields 'Baking ... Savoury'
 * 'Baking > Biscuits > Savoury > Packaged' yields 'Baking ... Packaged'
 */
module.exports = tag => {
  const tagNodes = tag.text.split(' > ');
  const rootNode = tagNodes[0];
  const leafNode = tagNodes[tagNodes.length - 1];
  if (tagNodes.length === 1) {
    return leafNode;
  }
  if (tagNodes.length === 2) {
    return $(`<span>${rootNode} > <b>${leafNode}</b></span>`);
  }
  return $(`<span>${rootNode} ... <b>${leafNode}</b></span>`);
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import Card from '@thrivetrm/ui/components/Card';
import hasFeature from 'modules/auth/selectors/hasFeature';
import getTenantFlag from 'modules/tenant/selectors/getTenantFlag';
import TaskForm from 'modules/tasks/components/TaskForm';
import { PARENT_CONTACT } from 'modules/notes/constants';
import isCrmUser from 'modules/auth/selectors/isCrmUser';
import NoteForm from 'modules/notes/components/NoteForm';
import OutreachForm from 'modules/outreaches/components/OutreachForm';
import OutreachEmailForm from 'modules/outreaches/components/OutreachEmailForm';
import AppointmentForm from 'modules/appointments/components/AppointmentForm';
import * as appointmentConstants from 'modules/appointments/constants';

import ReferenceForm from 'modules/references/components/ReferenceForm';
import IntroductionForm from 'modules/introductions/components/IntroductionForm';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import ReferenceAddFormV2 from 'modules/references/components/ReferenceAddFormV2';
import { fetchContact } from 'modules/contacts/actions';
import NotificationsList from '../notifications/NotificationsList';
import NotificationsFilterDropdown from '../notifications/NotificationsFilterDropdown';

// This determines the order of the buttons being displayed on the contact
// profile. Please don't alpha sort.
const ACTIVITY_TYPES = {
  email: {
    icon: 'fa-send',
    title: 'Send an Email',
    submitLabel: 'Send an Email',
  },
  appointment: {
    icon: 'fa-calendar',
    title: 'Schedule a Meeting/Interview',
    hideTitle: true,
  },
  introduction: {
    icon: 'fa-handshake-o',
    title: 'Log an Introduction',
    submitLabel: 'Log Introduction',
  },
  note: {
    icon: 'fa-edit',
    title: 'Add a Note',
    submitLabel: 'Add Note',
  },
  outreach: {
    icon: 'fa-user-circle-o',
    title: 'Log an Outreach',
    submitLabel: 'Log Outreach',
  },
  reference: {
    icon: 'fa-file-text-o',
    title: 'Add a Reference',
    submitLabel: 'Add Reference',
  },
  task: {
    icon: 'fa-list-ul',
    title: 'Add Task',
    submitLabel: 'Add Task',
  },
};

class ContactActivity extends Component {
  constructor(...args) {
    super(...args);

    this.componentId = uniqueId();

    this.state = {
      /**
       * The key of the currently displayed form.
       * @type {String}
       */
      openedForm: null,

      /**
       * Saved form states, keyed on each form type ('note', 'outreach', etc).
       * This stores a copy of the form state when a user hits the cancel key or closes a
       * form, and restores it when it is reopened (so in case a user fills out a form then
       * accidentally hits cancel, closes the form, or switches to a different form).
       * @type {Map}
       */
      formStates: new Map(),

      /**
       * This is only used by the AppointmentForm and is needed to dynamically change the submit
       * button text based on whether an integrated calendar has been selected
       */
      isSendingUpdateToAttendees: false,
      filterValue: this.props.contact
        .getIn(['notifications', 'meta', 'filters', 'types'])
        ?.toJS(),
    };
  }

  componentDidMount() {
    this.updateFilters(false, this.state.filterValue);
  }

  getFormComponent() {
    const { formStates, openedForm } = this.state;
    const {
      clearTransaction,
      contact,
      crmUser,
      hasReferenceConnectionsV2,
      transactions,
    } = this.props;

    const commonProps = openedForm && {
      asRichTextForm: true,
      clearTransaction: clearTransaction,
      contactId: contact.getIn(['data', 'id']),
      errorDisplay: 'tooltip',
      fieldLayout: 'wide',
      formActionProps: {
        // These icons use font awesome, so this is just a className string
        submitButtonIcon: `${ACTIVITY_TYPES[openedForm].icon} u-marginRight-4`,
        submitLabel: ACTIVITY_TYPES[openedForm].submitLabel,
        fillWidth: false,
      },
      key: openedForm,
      initialFormState: formStates.get(openedForm),
      label: null,
      onChange: this.handleFormChange,
      onSaved: this.handleFormSaved,
      onCancel: this.handleFormCancel,
      title: ACTIVITY_TYPES[openedForm].hideTitle
        ? ''
        : ACTIVITY_TYPES[openedForm].title,
      transactions: transactions,
    };

    const contactId = contact.getIn(['data', 'id']);

    switch (openedForm) {
      case 'email':
        return (
          <OutreachEmailForm
            {...commonProps}
            draftStoragePath={{
              contact: contactId,
              outreachEmail: 'new',
            }}
          />
        );
      case 'introduction':
        return (
          <IntroductionForm
            {...commonProps}
            draftStoragePath={{
              contact: contactId,
              introduction: 'new',
            }}
            excludeContactField={true}
            excludeSearchField={crmUser}
          />
        );
      case 'reference': {
        return hasReferenceConnectionsV2 ? (
          <Card className='u-marginBottom-16' isCentered={false}>
            <ReferenceAddFormV2
              contactId={contactId}
              layout='wide'
              onCancel={() => {
                this.setState({ openedForm: null });
              }}
              onSuccess={this.handleReferenceSuccess}
            />
          </Card>
        ) : (
          <ReferenceForm
            {...commonProps}
            contactId={contactId}
            draftStoragePath={{
              contact: contactId,
              reference: 'new',
            }}
            excludeSearchField={crmUser}
          />
        );
      }
      case 'note': {
        return (
          <NoteForm
            {...commonProps}
            draftStoragePath={{
              contact: contactId,
              note: 'new',
            }}
            excludeSearchField={crmUser}
            parentId={contactId}
            parentType={PARENT_CONTACT}
          />
        );
      }
      case 'outreach': {
        return (
          <OutreachForm
            {...commonProps}
            allowRelatedSearchesEdit={true}
            draftStoragePath={{
              contact: contactId,
              outreach: 'new',
            }}
          />
        );
      }
      case 'appointment': {
        return (
          <AppointmentForm
            {...commonProps}
            allowRelatedSearchEdit={!crmUser}
            canClearCalendarSelection={true}
            draftStoragePath={{
              contact: contactId,
              appointment: 'new',
            }}
            formActionProps={{
              ...commonProps.formActionProps,
              submitLabel: this.state.isSendingUpdateToAttendees
                ? 'Send Invite'
                : 'Save',
            }}
            hideLabel={true}
            onChange={this.handleAppointmentFormChange}
            permittedTypes={
              crmUser
                ? appointmentConstants.MEETING_PERMITTED_TYPES
                : appointmentConstants.ALL_PERMITTED_TYPES
            }
          />
        );
      }
      case 'task': {
        return (
          <TaskForm
            {...commonProps}
            draftStoragePath={{
              contact: contactId,
              task: 'new',
            }}
            showContactSelect={false}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  handleReferenceSuccess = () => {
    const { contact, contactActions, invalidateContact, limit } = this.props;

    this.setState({ openedForm: null });
    // invalidates notifications on contact activity
    contactActions.fetchNotifications({
      limit: limit,
      contactId: contact.getIn(['data', 'id']),
      filters: contact.getIn(['notifications', 'meta', 'filters']).toJS(),
    });
    // invalidates the connections count on the contact details section
    invalidateContact(contact.getIn(['data', 'id']));
  };

  handleFilter = filters => {
    this.setState({ filterValue: filters });
    this.updateFilters(false, filters);
  };

  handleFetchNextPage = () => {
    // const notifications = this.props.contact.get('notifications');
    // const before = notifications.getIn(['data', -1, 'sequential_id']);
    // this.updateFilters(true, filters => filters.set('before', before));
    this.updateFilters(true, this.state.filterValue);
  };

  handleFormOpen = appointment => {
    const selectedForm = appointment.currentTarget.value;
    const { openedForm } = this.state;

    this.setState({
      openedForm: selectedForm === openedForm ? null : selectedForm,
    });
  };

  handleFormChange = formState => {
    const { formStates, openedForm } = this.state;
    this.setState({
      formStates: formStates.set(openedForm, formState),
    });
  };

  handleFormSaved = () => {
    const { formStates, openedForm } = this.state;
    this.setState({
      openedForm: null,
      formStates: formStates.delete(openedForm),
    });
  };

  handleFormCancel = formState => {
    const { formStates, openedForm } = this.state;
    this.setState({
      openedForm: null,
      formStates: formStates.set(openedForm, formState),
    });
  };

  handleNotificationAction = () => {
    // no-op for now
  };

  handleAppointmentFormChange = formState => {
    this.setState({
      isSendingUpdateToAttendees: Boolean(
        formState.getFieldValue().integration_id,
      ),
    });
  };

  /**
   * Update the filters that are currently applied. If isLoadingMore
   * is true, the results of the AJAX request will be appended,
   * otherwise, the collection will be reset.
   */
  updateFilters(isLoadingMore, filters) {
    const { contact, contactActions, limit } = this.props;

    const pageParams = {};

    if (isLoadingMore && contact.hasIn(['notifications', 'data'])) {
      const oldest = contact
        .getIn(['notifications', 'data'])
        .sortBy(notification => notification.getIn(['data', 'sequential_id']))
        .first();

      if (oldest) {
        pageParams.before = oldest.getIn(['data', 'sequential_id']);
      }
    }

    contactActions.fetchNotifications({
      ...pageParams,
      limit: limit,
      contactId: contact.getIn(['data', 'id']),
      filters: { types: filters, limited_visibility: true },
    });
  }

  render() {
    const {
      clearTransaction,
      companies,
      companyActions,
      contact,
      contactActions,
      contacts,
      crmUser,
      excludedActivityTypes,
      hasAssessmentTemplates,
      transactions,
    } = this.props;
    const { filterValue, openedForm } = this.state;

    const notifications = contact.getIn(['notifications', 'data']);
    // The order of the buttons is currently being determined by the
    // order of ACTIVITY_TYPES above.
    const enabledActivityTypes =
      excludedActivityTypes.length < 1
        ? Object.keys(ACTIVITY_TYPES)
        : Object.keys(ACTIVITY_TYPES).filter(
            key => !excludedActivityTypes.includes(key),
          );

    return (
      <div className='contact-activity'>
        <div className='contact-activity-header'>
          <div className='row'>
            <div className='col-md-4'>
              <NotificationsFilterDropdown
                className='dropdown-block'
                excludeSearchRelatedValues={crmUser}
                hasAssessmentTemplates={hasAssessmentTemplates}
                onChange={this.handleFilter}
                value={filterValue}
              />
            </div>
            <div className='col-md-8 text-right'>
              <div className='btn-group' role='group'>
                {enabledActivityTypes.map(key => (
                  <OverlayTrigger
                    key={key}
                    overlay={
                      <Tooltip id={`${this.componentId}-tooltip-${key}`}>
                        {key === 'appointment' && crmUser
                          ? 'Schedule a Meeting'
                          : ACTIVITY_TYPES[key].title}
                      </Tooltip>
                    }
                    placement='top'
                  >
                    <button
                      className={classnames(
                        'btn',
                        'btn-default',
                        'btn-toggle',
                        {
                          active: openedForm === key,
                        },
                      )}
                      onClick={this.handleFormOpen}
                      type='button'
                      value={key}
                    >
                      <i
                        className={classnames('fa', ACTIVITY_TYPES[key].icon)}
                      />
                    </button>
                  </OverlayTrigger>
                ))}
              </div>
            </div>
          </div>
        </div>
        {this.getFormComponent()}
        <div className='contact-activity-body'>
          {notifications && (
            <NotificationsList
              allowDelete={true}
              allowEdit={true}
              clearTransaction={clearTransaction}
              companies={companies}
              companyActions={companyActions}
              contact={contact}
              contactActions={contactActions}
              contacts={contacts}
              excludeSearchField={crmUser}
              notifications={notifications}
              onNotificationAction={this.handleNotificationAction}
              showDetails={true}
              showStatusButton={true}
              transactions={transactions}
            />
          )}
          {contact.getIn(['notifications', 'meta', 'hasMore']) && (
            <p className='text-center'>
              <button
                className='btn btn-primary-outline'
                onClick={this.handleFetchNextPage}
                type='button'
              >
                Load More Activity
              </button>
            </p>
          )}
        </div>
      </div>
    );
  }
}

ContactActivity.propTypes = {
  clearTransaction: PropTypes.func.isRequired,

  companies: ImmutablePropTypes.map.isRequired, // eslint-disable-line react/forbid-prop-types

  companyActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }).isRequired,

  contact: ImmutablePropTypes.mapContains({
    notifications: ImmutablePropTypes.mapContains({
      meta: ImmutablePropTypes.mapContains({
        filters: ImmutablePropTypes.map.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,

  contactActions: PropTypes.shape({
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    queryContacts: PropTypes.func.isRequired,
  }).isRequired,

  contacts: ImmutablePropTypes.map.isRequired, // eslint-disable-line react/forbid-prop-types

  crmUser: PropTypes.bool.isRequired,

  /**
   * Activity types to exclude from the
   */
  excludedActivityTypes: PropTypes.arrayOf(
    PropTypes.oneOf(Object.keys(ACTIVITY_TYPES)).isRequired,
  ),

  hasAssessmentTemplates: PropTypes.bool,
  hasReferenceConnectionsV2: PropTypes.bool,

  invalidateContact: PropTypes.func.isRequired,

  limit: PropTypes.number,

  transactions: transactionsState.isRequired,
};

ContactActivity.defaultProps = {
  excludedActivityTypes: ['appointment', 'email'],
};

export default compose(
  setDisplayName('ContactActivity(enhanced)'),
  connect(
    state => {
      const excludedActivityTypes = [];
      if (!getTenantFlag(state, 'has_meetings_enabled')) {
        excludedActivityTypes.push('appointment');
      }
      if (
        !hasFeature(state, 'integration.email.gmail') &&
        !hasFeature(state, 'integration.email.outlook.365')
      ) {
        excludedActivityTypes.push('email');
      }
      return {
        excludedActivityTypes: excludedActivityTypes,
        crmUser: isCrmUser(state),
        hasAssessmentTemplates: hasFeature(
          state,
          'feature.assessment_templates',
        ),
        hasReferenceConnectionsV2: hasFeature(
          state,
          'development.references_connection_v2',
        ),
      };
    },
    dispatch => ({
      invalidateContact: contactId => dispatch(fetchContact({ id: contactId })),
    }),
  ),
)(ContactActivity);

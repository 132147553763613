import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
  mapProps,
} from 'recompose';
import { connect } from 'react-redux';
import classnames from 'classnames';
import mapAssessmentIdToAssessment from 'modules/assessments/components/mapAssessmentIdToAssessment';
import mapRatingIdsToRatings from 'modules/assessments/components/mapRatingIdsToRatings';
import Attributes from 'modules/candidacies/components/assessments/Attributes';
import mapCandidacyIdToProperty from 'modules/candidacies/components/mapCandidacyIdToProperty';
import mapCandidacyIdToSearchType from 'modules/candidacies/components/mapCandidacyIdToSearchType';
import SearchCandidacyLink from 'modules/candidacies/components/SearchCandidacyLink';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import { TYPE_JOB_SEARCH, SEARCH_TYPES } from 'modules/searches/constants';
import getRecruiterName from 'modules/tenant/selectors/getRecruiterName';

/**
 * Renders the read-only view of a single assessment to be shown
 * in the CandidacyListItemWithFullAssessment list item.
 */
export const CandidacyListItemSectionAssessment = ({
  assessment,
  candidacyId,
  className,
  isFetched,
  isFetching,
  maxOptions,
  ratings,
  recruiterName,
  searchType,
  showMoreTop,
  ...props
}) => (
  <div
    className={classnames(
      className,
      'CandidacyListItemSectionAssessmentLegacy',
      {
        'is-missing': isFetched && !assessment,
      },
    )}
    {...props}
  >
    <SearchCandidacyLink candidacyId={candidacyId} tab='assessments'>
      {!isFetched && isFetching && <LoadingIndicator />}
      {!showMoreTop && (
        <h2>
          {searchType === TYPE_JOB_SEARCH ? recruiterName : ''} Assessment
        </h2>
      )}
      {assessment && (
        <Attributes
          candidacyId={candidacyId}
          maxOptions={maxOptions}
          moreText=' - view all {##}'
          ratings={ratings}
          readOnly={true}
          searchId={assessment.get('search_id')}
          searchType={searchType}
          showMoreTop={showMoreTop}
        />
      )}
      {isFetched && !assessment && (
        <div className='add-assessment'>
          <i className='fa fa-2x fa-plus-circle' />
          Add Assessment
        </div>
      )}
    </SearchCandidacyLink>
  </div>
);

CandidacyListItemSectionAssessment.propTypes = {
  /**
   * The assesment record
   */
  assessment: ImmutablePropTypes.mapContains({
    candidacy_id: PropTypes.number.isRequired,
    search_id: PropTypes.number,
  }),

  /**
   * The candidacy ID the assessment is for.
   */
  candidacyId: PropTypes.number,

  /**
   * An optional class name to add to the outer div of the component.
   */
  className: PropTypes.string,

  /**
   * Whether the record has been fetched yet or not.
   */
  isFetched: PropTypes.bool,

  /**
   * Whether the assessment is currently being fetched from the server.
   */
  isFetching: PropTypes.bool,

  /**
   * The maximum number of asseessment options/attributes to render.
   */
  maxOptions: PropTypes.number,

  /**
   * The list of current rating values for this assessment.
   */
  ratings: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),
  ),

  /**
   * The current tenant's "recruiter" name
   */
  recruiterName: PropTypes.string,

  /**
   * The type of search this candidacy is for.
   */
  searchType: PropTypes.oneOf(SEARCH_TYPES),

  /**
   * True if the moreText should be seen at the top.
   */
  showMoreTop: PropTypes.bool,
};

CandidacyListItemSectionAssessment.defaultProps = {
  maxOptions: 5,
};

/**
 * The default export requires only a `interviewId`, while
 * the named export requires the interview record.
 */
export default compose(
  setDisplayName('CandidacyListItemSectionAssessment(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  mapCandidacyIdToSearchType,
  mapCandidacyIdToProperty('recruiter_assessment', 'assessmentId'),
  mapCandidacyIdToProperty(['_meta', 'isFetching'], 'isFetching'),

  // If the entity record has an ID, that indicates it has been fetched (the fact that the entity
  // even exists in our store means it's been fetched),
  mapCandidacyIdToProperty('id', 'isFetched'),

  mapAssessmentIdToAssessment,
  withProps(({ assessment, isFetched }) => ({
    // Extract the rating IDs from the assessment record for mapping to their ratings records.
    ratingIds: assessment && assessment.get('ratings'),

    // isFetched, will be either an integer (the ID of the record) or undefined, but the compoennt
    // is expecting a boolean.
    isFetched: Boolean(isFetched),
  })),
  mapRatingIdsToRatings,
  connect(
    state => ({
      recruiterName: getRecruiterName(state),
    }),
    {},
  ),
  mapProps(
    ({ assessmentId: _assessmentId, ratingIds: _ratingIds, ...props }) => props,
  ),
)(CandidacyListItemSectionAssessment);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select-legacy';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import Card from '@thrivetrm/ui/components/Card';
import Modal from '@thrivetrm/ui/components/Modal';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import { useGetNetworksQuery } from 'services/apiV1/networks';
import { addRecordsToNetwork, resetAddToNetwork } from '../addToNetworkSlice';
import './AddToNetworkModal.scss';

const AddToNetworkModal = ({
  className,
  currentNetworkId,
  isOpen,
  onClose,
  onSuccess,
  recordIds,
}) => {
  const dispatch = useDispatch();
  const { recordLabel } = useSelector(state => state.recordIndex);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const { data: networks, isFetching: isLoadingNetworks } = useGetNetworksQuery(
    recordLabel,
  );

  const {
    addToNetworkError,
    isAddingToNetwork,
    isAddingToNetworkComplete,
  } = useSelector(state => state.addToNetwork);

  useEffect(() => {
    if (isAddingToNetworkComplete && !addToNetworkError) {
      dispatch(resetAddToNetwork());
      onSuccess(selectedNetwork);
    }
  }, [
    addToNetworkError,
    dispatch,
    isAddingToNetworkComplete,
    onSuccess,
    selectedNetwork,
  ]);

  const formattedNetworks = [
    ...(networks?.user_networks || []),
    ...(networks?.public_networks || []),
    ...(networks?.private_network || []),
  ]
    .filter(network => network.id !== currentNetworkId)
    .map(network => ({
      value: network.id,
      label: network.name,
    }));

  const handleAddToNetworkSubmit = () => {
    dispatch(addRecordsToNetwork(selectedNetwork.value, recordIds));
  };

  const modalClassName = classnames('AddToNetworkModal', className);

  return (
    <Modal
      className={modalClassName}
      isOpen={isOpen}
      onClose={onClose}
      size='small'
      title='Add to Network'
    >
      <Modal.Body className='ModalBody--noClip'>
        <h3>Network Name</h3>
        <LoadingContainer isLoading={isLoadingNetworks}>
          <Select
            autoFocus={true}
            className='ModalBody__select'
            isSearchable={true}
            noOptionsMessage='We were unable to locate any networks'
            onChange={setSelectedNetwork}
            options={formattedNetworks}
            placeholder='Select network...'
            value={selectedNetwork}
          />
        </LoadingContainer>
        {addToNetworkError && (
          <Card className='u-marginBottom-12' isCentered={false} type='error'>
            There was an error adding contacts to this network.
            <br />
            {addToNetworkError}
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          isDisabled={
            !selectedNetwork || isAddingToNetwork || isAddingToNetworkComplete
          }
          isLoading={isAddingToNetwork}
          label='Submit'
          onClick={handleAddToNetworkSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};

AddToNetworkModal.defaultProps = {
  className: null,
  currentNetworkId: null,
  isOpen: false,
  recordIds: [],
};

AddToNetworkModal.propTypes = {
  className: PropTypes.string,
  currentNetworkId: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  recordIds: PropTypes.arrayOf(PropTypes.number),
};

export default AddToNetworkModal;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import { useApiGet } from 'modules/core/hooks/useApi';
import formDataPropType from '@thrivetrm/ui/propTypes/formDataPropType';
import routes from 'modules/routing/routes';

const RoleDetails = ({ formData, onSubmit }) => {
  const history = useHistory();

  const [
    loadTenantOptions,
    isLoadingTenantOptions,
    _tenantOptionsLoadError,
    tenantOptions,
  ] = useApiGet(routes.api_tenant_options());

  useEffect(() => {
    loadTenantOptions();
  }, [loadTenantOptions]);

  const options = tenantOptions && tenantOptions.tenant_options;
  const jobFunctions = options && options.job_functions;
  const levels = options && options.levels;
  const productAreas = options && options.product_areas;
  const searchRegions = options && options.search_regions;
  const countries = options && options.countries;
  const provinces = options && options.provinces;
  const states = options && options.states;
  const searchReasons = options && options.search_reasons;

  const getSelectMenuItems = collection => {
    const items = [];
    if (collection?.length) {
      collection.forEach(item =>
        items.push(
          <SelectMenu.Item key={item.id} value={item.id}>
            {item.name}
          </SelectMenu.Item>,
        ),
      );
    }
    return items;
  };

  return (
    <Card className='SearchRequestPage__Card' type='shadow'>
      <LoadingContainer isLoading={isLoadingTenantOptions}>
        <Form initialValues={formData} onSubmit={onSubmit}>
          <h2 className='u-marginBottom-24 u-marginTop-12 u-textAlign-c'>
            Role Details
          </h2>
          <Form.TextInput
            className='u-marginBottom-24'
            inputWidth='full'
            label='What is the job title for this role?'
            name='job_title'
            placeholder='Enter a job title…'
            rules={{
              required: {
                value: true,
                message: 'Job title is required',
              },
            }}
          />

          <Form.RadioButtonGroup
            className='u-marginBottom-24'
            label='Is this search confidential?'
            name='confidential'
            options={[
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 0,
              },
            ]}
            rules={{
              required: {
                value: true,
                message: 'Confidential yes/no is required',
              },
            }}
          />

          <Form.FieldDependentContent
            shouldRender={fields => fields.confidential === 1}
          >
            <Form.TextInput
              className='u-marginBottom-24'
              inputWidth='full'
              label='Please enter a suggested codename.'
              name='code_name'
              placeholder='Enter a codename…'
              rules={{
                required: {
                  value: true,
                  message: 'A codename is required for confidential searches.',
                },
              }}
            />

            <Form.TextArea
              className='u-marginBottom-24'
              label='What is the confidential reasoning for this search?'
              name='confidential_reason_note'
              width='full'
            />
          </Form.FieldDependentContent>

          <div className='u-marginBottom-12'>
            {searchRegions?.length > 0 ? (
              <Form.SelectMenu
                inputWidth='full'
                label='Which region is this search for?'
                name='search_region'
                rules={{
                  required: {
                    value: true,
                    message: 'Search region is required',
                  },
                }}
              >
                {getSelectMenuItems(searchRegions)}
              </Form.SelectMenu>
            ) : null}
          </div>

          <div className='u-flex u-marginBottom-12'>
            <Form.SelectMenu
              inputWidth='full'
              name='country_code'
              placeholder='Select a Country…'
            >
              {getSelectMenuItems(countries)}
            </Form.SelectMenu>

            <div className='u-marginLeft-12'>
              <Form.FieldDependentContent
                shouldRender={fields => fields.country_code === 'US'}
              >
                <Form.SelectMenu
                  inputWidth='full'
                  name='state'
                  placeholder='Select a State'
                >
                  {getSelectMenuItems(states)}
                </Form.SelectMenu>
              </Form.FieldDependentContent>

              <Form.FieldDependentContent
                shouldRender={fields => fields.country_code === 'CA'}
              >
                <Form.SelectMenu
                  inputWidth='full'
                  name='state'
                  placeholder='Select a Province'
                >
                  {getSelectMenuItems(provinces)}
                </Form.SelectMenu>
              </Form.FieldDependentContent>

              <Form.FieldDependentContent
                shouldRender={fields =>
                  !['CA', 'US'].includes(fields.country_code)
                }
              >
                <Form.TextInput
                  className='u-marginBottom-24'
                  inputWidth='full'
                  name='state'
                  placeholder='Enter a province…'
                />
              </Form.FieldDependentContent>
            </div>
          </div>

          <Form.TextInput
            className='u-marginBottom-24'
            inputWidth='full'
            name='city'
            placeholder='Enter a city…'
          />

          {searchReasons?.length > 0 ? (
            <Form.SelectMenu
              className='u-marginBottom-24'
              inputWidth='full'
              label='What type of search is this?'
              name='search_reason'
              placeholder='Select a Search Reason…'
              rules={{
                required: {
                  value: true,
                  message: 'Search reason is required',
                },
              }}
            >
              {getSelectMenuItems(searchReasons)}
            </Form.SelectMenu>
          ) : null}

          <Form.TextInput
            className='u-marginBottom-24'
            inputWidth='full'
            label="If this is a backfill, please enter the incumbent's name."
            name='incumbent_name'
            placeholder='Enter a full name…'
          />

          <Form.TextInput
            className='u-marginBottom-24'
            inputWidth='full'
            label='If known, what is the job code for this role?'
            name='job_code'
            placeholder='Enter a job code…'
          />

          <Form.SelectMenu
            className='u-marginBottom-24'
            inputWidth='full'
            label='What is the function of this role?'
            name='job_function'
            placeholder='Select a Job Function…'
            rules={{
              required: {
                value: true,
                message: 'Job function is required',
              },
            }}
          >
            {getSelectMenuItems(jobFunctions)}
          </Form.SelectMenu>

          {productAreas?.length > 0 ? (
            <Form.SelectMenu
              className='u-marginBottom-24'
              inputWidth='full'
              label='What Product Area is the search for?'
              name='product_area'
              placeholder='Select a Product Area…'
            >
              {getSelectMenuItems(productAreas)}
            </Form.SelectMenu>
          ) : null}

          {levels?.length > 0 ? (
            <Form.SelectMenu
              className='u-marginBottom-24'
              inputWidth='full'
              label='What is the target level for this role?'
              name='level'
              placeholder='Select a Level…'
              rules={{
                required: {
                  value: true,
                  message: 'Target level is required',
                },
              }}
            >
              {getSelectMenuItems(levels)}
            </Form.SelectMenu>
          ) : null}

          <div className='u-flex u-width-100 u-flexJustify-spaceBetween'>
            <ButtonPrimary
              label='‹ Back'
              onClick={() => history.push('request_details')}
            />
            <Form.SubmitButton label='Next ›' />
          </div>
        </Form>
      </LoadingContainer>
    </Card>
  );
};

RoleDetails.defaultProps = {
  formData: null,
};

RoleDetails.propTypes = {
  formData: formDataPropType,
  onSubmit: PropTypes.func.isRequired,
};

export default RoleDetails;

import React from 'react';
import PropTypes from 'prop-types';
import BulkImportCard from '../BulkImportCard';
import * as bulkImportConstants from '../../constants';

const ChooseResourceType = ({ onChoose }) => {
  const handleContactsClick = () => {
    onChoose(bulkImportConstants.Modes.CHOOSE_FILE_FORMAT);
  };

  return (
    <div className='u-paddingHorizontal-24'>
      <h3 className='u-marginBottom-32'>
        What type of data would you like to import?
      </h3>

      <div className='row'>
        <div className='col-sm-4'>
          <BulkImportCard
            className='BulkImportModal__contactsCard'
            iconClass='fa-user'
            onClick={handleContactsClick}
            title='Contacts'
          />
        </div>
        {/*
        Companies is not currently supported.
        Eventually we may render the below,
        so I will leave it here for now
        <div className='col-sm-4'>
          <BulkImportCard
            iconClass='fa-building'
            isComingSoon
            title='Companies'
          />
        </div> */}
      </div>
    </div>
  );
};

ChooseResourceType.propTypes = {
  onChoose: PropTypes.func.isRequired,
};

export default ChooseResourceType;

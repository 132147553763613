import React from 'react';
import Card from '@thrivetrm/ui/components/Card';

const CandidatePortalRevokeConsentSuccess = () => {
  return (
    <div className='CandidatePortalRevokeSuccess container'>
      <Card
        className='u-marginTop-24 u-padding-24 d-flex flex-column'
        type='shadow'
      >
        <div className='u-textAlign-l mb-3'>
          <h3 className='u-textColor-blue u-textAlign-c'>Thank You</h3>
          <p>
            We will begin the process of removing your information from our
            system in accordance with our privacy policy and data protection
            regulations.
          </p>
        </div>
      </Card>
    </div>
  );
};

export default CandidatePortalRevokeConsentSuccess;

import getSearch from './getSearch';
import getCandidacyStats from './getCandidacyStats';

/**
 * Determines if a search records needs to be fetched from the server.
 * @param {Object} state
 * @param {Number} searchId The ID of the search
 * @return {Boolean} True if the search record needs to be fetched from the server;
 *   Otherwise, false.
 */
const shouldFetchCandidacyStats = (state, searchId) => {
  if (!searchId) {
    return false;
  }

  const search = getSearch(state, searchId);
  const stats = getCandidacyStats(state, searchId);

  if (!stats && search && search.getIn(['_meta', 'isFetching'])) {
    // We don't have stats, but we're fetching the search currently, which includes stats,
    // so we don't need to fetch stats separately.
    return false;
  }

  if (!stats) {
    return true;
  }

  if (stats.getIn(['_meta', 'isFetching']) || stats.hasIn(['_meta', 'error'])) {
    return false;
  }

  return Boolean(stats.getIn(['_meta', 'isInvalidated']));
};

export default shouldFetchCandidacyStats;

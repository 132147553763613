import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import companyInvestmentStageSchema from '../schemas/companyInvestmentStages';
import getCompanyInvestmentStageOrder from '../selectors/getCompanyInvestmentStageOrder';

/**
 * A tag select component for choosing from the list of company investment stages.
 */
const CompanyInvestmentStageTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField
    {...props}
    entityLabel='stage'
    schema={companyInvestmentStageSchema}
    sortSelector={getCompanyInvestmentStageOrder}
  />
);

CompanyInvestmentStageTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default CompanyInvestmentStageTagSelectField;

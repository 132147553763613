import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Helmet } from 'react-helmet';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import ensureTenantOptionsFetched from 'modules/tenant/components/ensureTenantOptionsFetched';

// A backdoor into edit mode.
const TEST_MODE = '__test';

const fetchRequirements = lifecycle({
  UNSAFE_componentWillMount: function () {
    const {
      contactActions,
      contactReviewActions,
      match: { params: { contactId, contactReviewId } = {} },
    } = this.props;

    if (contactReviewId && contactReviewId !== TEST_MODE) {
      // We're either building a new contact from a review, or we're
      // applying a contact review's changes to a contact. The action
      // will determine which based on the presence of a contactId.
      contactReviewActions.fetchAndSelectContactReview({
        contactReviewId: parseInt(contactReviewId),
        contactId: contactId && parseInt(contactId),
      });
      if (contactId) {
        contactActions.fetchContact({
          contactId: parseInt(contactId),
        });
      }
    } else if (contactId) {
      // Just a normal contact form
      contactActions.fetchAndSelectContact({
        contactId: parseInt(contactId),
      });
    } else {
      throw new Error(
        'withContactLoaded expects params to include a contactId, contactReviewId, or both.',
      );
    }
  },
});

const isLoaded = ({ contact, contactReviews, match, tenant }) => {
  // Make sure all tenant options are loaded
  // FIXME: We probably shouldn't have this check here. The `WrappedComponent`
  // should be able to render regardless of whether or not the tenant options
  // are loaded.

  if (!tenant.getIn(['tenantOptions', 'meta', 'lastFetched'])) {
    return false;
  }

  // Make sure the contact isn't still loading
  if (
    !contact ||
    contact.getIn(['meta', 'isFetching']) ||
    contactReviews.getIn([
      'data',
      parseInt(match.params.contactReviewId),
      'meta',
      'isFetching',
    ])
  ) {
    return false;
  }

  // On contact pages, make sure we have an existing
  if (match.params.contactId && !contact.get('data')) {
    return false;
  }

  // When this happens, it means you're trying to load a contact page, button
  // the selectedContact hasn't yet changed.
  if (
    !match.params.contactReviewId &&
    contact.getIn(['meta', 'isContactReview'])
  ) {
    return false;
  }

  return true;
};

/* eslint-disable react/prop-types, react/destructuring-assignment */
const withSpinner = WrappedComponent => props => {
  if (isLoaded(props)) {
    return (
      <div>
        {props.contact && (
          <Helmet>
            <title>{props.contact.getIn(['data', 'full_name'])}</title>
          </Helmet>
        )}
        <WrappedComponent {...props} />
      </div>
    );
  }

  return <LoadingIndicator message='Loading contact...' />;
};
/* eslint-enable react/prop-types */

/**
 * Fetches the contact (or contact review) based on the params.
 * Displays a spinner instead of rendering the wrapped component
 * until all required data is loaded.
 */
export default compose(
  fetchRequirements,
  ensureTenantOptionsFetched,
  withSpinner,
);

import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import AddressesTable from 'modules/addresses/components/AddressesTable';
import { COLUMNS, PARENT_COMPANY } from '../../constants';
import columnDefinitions from './columnDefinitions';

/**
 * Renders a table of addresses
 * {@see columnDefinitions} for available column names, or to add additional columns to be
 * rendered by this table.
 */
export default compose(
  setDisplayName('CompanyAddressesTable'),
  setPropTypes({
    /**
     * The ID of the company
     */
    companyId: PropTypes.number.isRequired,

    /**
     * The primary address ID for the company
     */
    primaryAddressId: PropTypes.number,
  }),
  mapProps(({ companyId, ...rest }) => ({
    // Map the column names to the actual column definitions (which includes the titles,
    // how to render the values, etc)
    columns: COLUMNS.map(columnName => columnDefinitions[columnName]).filter(
      column => column,
    ),
    parentId: companyId,
    parentType: PARENT_COMPANY,
    ...rest,
  })),
)(AddressesTable);

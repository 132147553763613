import { connect } from 'react-redux';
import getTask from '../selectors/getTask';

/**
 * A higher order component which maps a `taskId` prop value to it's task record.
 */
export default connect(
  (state, { taskId }) => ({
    task: getTask(state, taskId),
  }),
  {},
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import LocationMultiSelectField from 'modules/locations/components/LocationMultiSelectField';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import contactLevels from 'modules/tenant/schemas/contactLevels';

import labels from '../../../labels/index.json';

import {
  COMMUTE_RADIUS_OPTIONS,
  REVENUE_MAX_OPTIONS,
  REVENUE_MIN_OPTIONS,
} from '../constants';

class CompanyPreferencesField extends PureComponent {
  static createFieldState(name = 'company-preferences-info', contact) {
    return FieldState.createNested(name, [
      CheckboxField.createFieldState(
        'preferred_relocate_domestically',
        contact.getIn(['data', 'preferred_relocate_domestically']),
      ),
      CheckboxField.createFieldState(
        'preferred_relocate_internationally',
        contact.getIn(['data', 'preferred_relocate_internationally']),
      ),
      TenantOptionsSelectField.createFieldState(
        'level_id',
        contact.getIn(['data', 'level']),
      ),
      ReactSelectField.createFieldState(
        'preferred_revenue_minimum',
        contact.getIn(['data', 'preferred_revenue_minimum']),
      ),
      ReactSelectField.createFieldState(
        'preferred_revenue_maximum',
        contact.getIn(['data', 'preferred_revenue_maximum']),
      ),
      ReactSelectField.createFieldState(
        'preferred_commute_radius',
        contact.getIn(['data', 'preferred_commute_radius']),
      ),
      LocationMultiSelectField.createFieldState(
        'location_ids',
        contact.getIn(['data', 'locations']),
      ),
    ]);
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const { fieldState, userType, viewRelocationPreferences } = this.props;
    const commuteRadiusLabel = labels.commuteRadius[userType];
    const relocateDomesticallyLabel = labels.relocateDomestically[userType];
    const relocateInternationallyLabel =
      labels.relocateInternationally[userType];

    return (
      <div className='section'>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <ReactSelectField
                      clearable={true}
                      fieldState={fieldState.getNestedField(
                        'preferred_revenue_minimum',
                      )}
                      key='preferred_revenue_minimum'
                      label='Preferred  Company Revenue Min'
                      name='preferred_revenue_minimum'
                      onChange={this.handleFieldChange}
                      options={REVENUE_MIN_OPTIONS}
                      placeholder='Select Revenue...'
                      simpleValue={true}
                    />
                  </div>
                  <div className='col-sm-6'>
                    <ReactSelectField
                      clearable={true}
                      fieldState={fieldState.getNestedField(
                        'preferred_revenue_maximum',
                      )}
                      key='preferred_revenue_maximum'
                      label='Preferred Company Revenue Max'
                      name='preferred_revenue_maximum'
                      onChange={this.handleFieldChange}
                      options={REVENUE_MAX_OPTIONS}
                      placeholder='Select Revenue...'
                      simpleValue={true}
                    />
                  </div>
                </div>
              </div>
              <div className='col-sm-12'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <TenantOptionsSelectField
                      clearable={true}
                      fieldState={fieldState.getNestedField('level_id')}
                      label='Level'
                      name='level'
                      onChange={this.handleFieldChange}
                      placeholder='Select Level...'
                      schema={contactLevels}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='row'>
              <div className='col-sm-6'>
                <CheckboxField
                  checked={fieldState
                    .getNestedField('preferred_relocate_domestically')
                    .getValue()}
                  fieldState={fieldState.getNestedField(
                    'preferred_relocate_domestically',
                  )}
                  key='relocate_domestically'
                  label={relocateDomesticallyLabel}
                  onChange={this.handleFieldChange}
                  text='Yes'
                />
              </div>
              <div className='col-sm-6'>
                <CheckboxField
                  checked={fieldState
                    .getNestedField('preferred_relocate_internationally')
                    .getValue()}
                  fieldState={fieldState.getNestedField(
                    'preferred_relocate_internationally',
                  )}
                  key='relocate_internationally'
                  label={relocateInternationallyLabel}
                  onChange={this.handleFieldChange}
                  text='Yes'
                />
              </div>
              {viewRelocationPreferences && (
                <div className='col-sm-12'>
                  <div className='row'>
                    <div className='col-sm-9'>
                      <LocationMultiSelectField
                        fieldState={fieldState.getNestedField('location_ids')}
                        label='Relocation Preferences'
                        onChange={this.handleFieldChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className='col-sm-12'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <ReactSelectField
                      clearable={true}
                      fieldState={fieldState.getNestedField(
                        'preferred_commute_radius',
                      )}
                      key='preferred_commute_radius'
                      label={commuteRadiusLabel}
                      name='preferred_commute_radius'
                      onChange={this.handleFieldChange}
                      options={COMMUTE_RADIUS_OPTIONS}
                      placeholder='Select Commute Radius...'
                      simpleValue={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CompanyPreferencesField.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  onChange: PropTypes.func,

  userType: PropTypes.string.isRequired,

  viewRelocationPreferences: PropTypes.bool,
};

export default CompanyPreferencesField;

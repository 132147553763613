import PropTypes from 'prop-types';
import { compose, setStatic, setDisplayName } from 'recompose';
import React, { Component } from 'react';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import DateInputField from './DateInputField';

class DateRangeField extends Component {
  static createFieldState(name = 'dateRange', initialValues = {}, ...rest) {
    return FieldState.createNested(
      name,
      [
        DateInputField.createFieldState('startDate', initialValues.startDate),
        DateInputField.createFieldState('endDate', initialValues.endDate),
      ],
      ...rest,
    );
  }

  handleFieldChange = nestedFieldState => {
    const { fieldState, onBlur, onChange } = this.props;
    onChange(fieldState.setNestedField(nestedFieldState));
    onBlur();
  };

  render() {
    const { fieldState, innerClassName, wrapperClassName } = this.props;
    return (
      <div className={wrapperClassName}>
        <div className={innerClassName}>
          <div className='start-date-field'>
            <DateInputField
              fieldState={fieldState.getNestedField('startDate')}
              label='Start Date'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        <div className={innerClassName}>
          <div className='end-date-field'>
            <DateInputField
              fieldState={fieldState.getNestedField('endDate')}
              label='End Date'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

DateRangeField.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  innerClassName: PropTypes.string,

  onBlur: PropTypes.func.isRequired,

  onChange: PropTypes.func.isRequired,

  wrapperClassName: PropTypes.string,
};

export default compose(
  setDisplayName('DateRangeField(Enhanced)'),
  setStatic('createFieldState', DateRangeField.createFieldState),
  withFormGroup,
)(DateRangeField);

import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CandidacySearchSidebar from 'modules/candidacy-views/searchPageSidebar/CandidacySearchSidebar';
import Sidebar from 'modules/layouts/sidebar/Sidebar';
import SearchPageSidebarHeader from './SearchPageSidebarHeader';
import SidebarSearchContent from './content/SidebarSearchContent';
import ActivityPopover from './popovers/ActivityPopover';
import DetailsPopover from './popovers/DetailsPopover';
import InterviewsPopover from './popovers/InterviewsPopover';
import IntroductionsPopover from './popovers/IntroductionsPopover';
import NotesPopover from './popovers/NotesPopover';
import OutreachPopover from './popovers/OutreachPopover';

const POPOVERS = {
  details: DetailsPopover,
  notes: NotesPopover,
  interviews: InterviewsPopover,
  introductions: IntroductionsPopover,
  activity: ActivityPopover,
  outreach: OutreachPopover,
};

/**
 * Renders the main sidebar component for the search page.
 * This renders the "header", which displays the company name, search name, and logo,
 * and then renders any child content below that.
 */
const SearchPageSidebar = ({
  popoverComponent: PopoverComponent,
  searchId,
  ...rest
}) => (
  <Sidebar
    canExpand={true}
    className='SearchPageSidebar'
    popover={
      PopoverComponent && <PopoverComponent {...rest} searchId={searchId} />
    }
  >
    <SearchPageSidebarHeader searchId={searchId} />
    <div className='SearchPageSidebar__content'>
      <Switch>
        <Route
          path='/(.*)/:searchId/:searchPage/:candidacyId(\d+)/:selectedTab?'
          render={({ match }) => (
            <CandidacySearchSidebar
              candidacyId={parseInt(match.params.candidacyId)}
              searchId={searchId}
              searchPage={match.params.searchPage}
              selectedTab={match.params.selectedTab}
            />
          )}
        />
        <Route render={() => <SidebarSearchContent searchId={searchId} />} />
      </Switch>
    </div>
  </Sidebar>
);

SearchPageSidebar.propTypes = {
  popoverComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

  /**
   * The ID of the current search
   */
  searchId: PropTypes.number.isRequired,
};

export default () => (
  <Route
    path='/(searches|talent_pools|pipelines)/:searchId(\d+)/:searchPage?/:popover?/:filter?'
    render={({
      match: {
        params: { popover, searchId, searchPage, ...params },
      },
    }) => (
      <SearchPageSidebar
        {...params}
        popoverComponent={POPOVERS[popover]}
        searchId={parseInt(searchId)}
        searchPage={searchPage}
      />
    )}
  />
);

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  branch,
  defaultProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import withJobApplicationIdsSortedByName from './withJobApplicationIdsSortedByName';
import withJobApplicationIdsSortedByEmail from './withJobApplicationIdsSortedByEmail';
import withJobApplicationIdsSortedByDateApplied from './withJobApplicationIdsSortedByDateApplied';
import withJobApplicationIdsSortedDescending from './withJobApplicationIdsSortedDescending';
import withJobApplicationIdsRejectedLast from './withJobApplicationIdsRejectedLast';
import {
  SORT_BY_NAME,
  SORT_BY_EMAIL,
  SORT_BY_DEFAULT,
  SORT_BY_DATE_APPLIED,
  SORT_ASCENDING_DEFAULT,
} from '../constants';

/**
 * A higher order component that provides sorting of a list of job applications IDs provided by an
 * `jobApplicationIds` prop, based on the prop values of `sortBy`, `sortAscending`, and
 * `sortRejectedLast`.
 */
export default compose(
  setDisplayName('withJobApplicationIdsSorted'),
  setPropTypes({
    /**
     * The sort type
     */
    sortBy: PropTypes.oneOf([SORT_BY_NAME, SORT_BY_EMAIL, SORT_BY_DATE_APPLIED])
      .isRequired,

    /**
     * True to sort descending, false to sort ascending.
     */
    sortAscending: PropTypes.bool.isRequired,

    /**
     * True to sort all rejected applications last; otherwise false (rejection state will not be
     * considered)
     */
    sortRejectedLast: PropTypes.bool.isRequired,

    /**
     * The job application IDs to sort.
     */
    jobApplicationIds: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  defaultProps({
    sortAscending: SORT_ASCENDING_DEFAULT,
    sortBy: SORT_BY_DEFAULT,
    sortRejectedLast: true,
  }),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_NAME,
    withJobApplicationIdsSortedByName,
  ),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_EMAIL,
    withJobApplicationIdsSortedByEmail,
  ),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_DATE_APPLIED,
    withJobApplicationIdsSortedByDateApplied,
  ),
  branch(
    ({ sortAscending }) => !sortAscending,
    withJobApplicationIdsSortedDescending,
  ),
  branch(
    ({ sortRejectedLast }) => Boolean(sortRejectedLast),
    withJobApplicationIdsRejectedLast,
  ),
);

import selectn from 'selectn';
import { fromJS } from 'immutable';
import {
  compose,
  mapProps,
  setDisplayName,
  withHandlers,
  withProps,
} from 'recompose';
import withFormState from 'modules/forms/components/withFormState';
import withTransaction from 'modules/transactions/components/withTransaction';
import EmailTemplateField from './EmailTemplateField';
import connectEmailTemplateActions from './connectEmailTemplateActions';
import mapEmailTemplateIdToEmailTemplate from './mapEmailTemplateIdToEmailTemplate';

/**
 * Provides props for managing a form for creating/editing an email template.
 *
 * Takes the following incoming props:
 * * `emailTemplateId` {Number} (optional) The ID of an existing email template to edit.
 * * `emailTemplate` {Map} (optional) The actualy recor of an existing email template to edit, or
 *   a set of values to initialize the form with.
 * * `onSaved` {Function} Will be called  when the form has been successfully saved. THe first
 *   argument will be the ID of the email template that was created or updated.
 *
 * Creates the following props for the child component:
 * * `isNewRecord` {Boolean} true if creating a new record, false if editing an existing record.
 * * `formState` {FormState} a FormState object with an underlying EmailTemplateField FieldState
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 *
 */
export default compose(
  setDisplayName('withEmailTemplateForm'),

  // Create a temporary prop (`withEmailTemplateFormProps`) that holds any incoming props
  // so we don't overwrite/delete any incoming props that may need to be passed through.
  withProps(props => ({
    withEmailTemplateFormProps: props,
  })),

  /**
   * If we were given a templateId in order to edit an existing template, map that to it's
   * underlying record.
   */
  mapEmailTemplateIdToEmailTemplate,
  withProps(({ emailTemplate }) => ({
    initialValue: emailTemplate,
    isNewRecord: !(emailTemplate && emailTemplate.get('id')),
  })),

  /**
   * Create a FormState, initializing it with the value from `initialValue`,
   * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
   */
  withFormState(({ initialValue }, isReset) =>
    EmailTemplateField.createFieldState(
      'emailTemplate',
      isReset ? null : initialValue,
    ),
  ),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onResetFormState,
      onSaved,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();
        onSaved(selectn('payload.result.email_template', transaction));
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  /**
   * Include connected versions of `emailTemplateActions.updateEmailTemplate` and
   * `emailTemplateActions.createEmailTemplate`
   */
  connectEmailTemplateActions,

  /**
   * Add a callback to handle submitting the action form.
   */
  withHandlers({
    onSubmit: ({
      emailTemplate,
      emailTemplateActions: { createEmailTemplate, updateEmailTemplate },
      formState,
      onFormStateChange,
      startTransaction,
    }) => e => {
      // Prevent default browser behavior, which could cause the browser to attempt
      // to submit a form on it's own.
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }

      const transactionId = startTransaction();
      const fieldValue = formState.getFieldValue();
      const values = (emailTemplate || fromJS({})).merge(fieldValue);

      const action = values.get('id')
        ? updateEmailTemplate
        : createEmailTemplate;

      action({
        emailTemplate: values.toJS(),
        transactionId: transactionId,
      });

      onFormStateChange(formState.startSubmit(transactionId));
    },
  }),

  // Prevent any props we used/created from bleeding down to the child.
  mapProps(
    ({
      formState,
      isNewRecord,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      onSubmit,
      withEmailTemplateFormProps,
    }) => ({
      ...withEmailTemplateFormProps,
      formState: formState,
      isNewRecord: isNewRecord,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onResetFormState: onResetFormState,
      onSubmit: onSubmit,
    }),
  ),
);

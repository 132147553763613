import selectn from 'selectn';
import invalidateCompany from 'modules/companies/actions/invalidateCompany';
import createEntity from 'modules/entities/actions/createEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import getInvestmentProperty from 'modules/company-investments/selectors/getInvestmentProperty';
import investmentSchema from 'modules/company-investments/schema';
import routes from 'modules/routing/routes';

/**
 * An action for creating an funding round
 * @param {Object} payload
 * @param {Object} payload.fundingRound The funding round (investment) record to create
 * @param {Number} payload.fundingRound.round ID of the company stage that applies to this round.
 * @param {Number} payload.fundingRound.venture ID of the company the funding round is for.
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ fundingRound: { round, ...fundingRound }, ...rest }) => (
  dispatch,
  getState,
) =>
  dispatch(
    createEntity({
      ...rest,
      body: {
        funding_round: {
          round_id: round,
          ...fundingRound,
        },
      },
      entityType: investmentSchema.key,
      responseSchema: { funding_round: investmentSchema },
      url: routes.api_v1_company_funding_rounds({
        companyId: fundingRound.venture,
      }),
    }),
  ).then(action => {
    // If the create was success, the company needs to be refreshed because it will
    // have a new total funding amount.
    if (action.type === entityActionTypes.CREATE_SUCCESS) {
      const investmentId = selectn('payload.result.funding_round', action);
      const companyId = getInvestmentProperty(
        getState(),
        investmentId,
        'venture',
      );
      if (companyId) {
        dispatch(invalidateCompany({ id: companyId }));
      }
    }

    return action;
  });

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import canViewSearchSummaryReportSelector from 'modules/searches/selectors/canViewSearchSummaryReport';
import getStageListForStageType from 'modules/search-stages/selectors/getStageListForStageType';
import { STAGE_TYPE_JOB_SEARCH } from 'modules/search-stages/constants';
import getStage from 'modules/search-stages/selectors/getStage';
import SearchCustomReportModal from './SearchCustomReportModal';

const SearchCustomReportButton = ({ currentUserEmail, searchId }) => {
  const [isModalOpen, openModal, closeModal] = useToggle(false);

  const hasCustomReportFeature = useFeatureCheck(
    'feature.search_custom_report_generator',
  );

  const stages = useSelector(state =>
    getStageListForStageType(state, STAGE_TYPE_JOB_SEARCH)
      ?.get('ids')
      ?.map(id => getStage(state, id).toObject())
      .toArray(),
  );

  const canViewSummaryReport = useSelector(canViewSearchSummaryReportSelector);

  const isCustomReportButtonVisible =
    hasCustomReportFeature && canViewSummaryReport;

  // We use a button element to match the existing Summary Report button
  return isCustomReportButtonVisible ? (
    <>
      <button className='btn btn-link' onClick={openModal} type='button'>
        Custom Report{' '}
        <i className='GenerateReportButton__icon fa fa-file-pdf-o' />
      </button>
      {isModalOpen && (
        <SearchCustomReportModal
          initialRecipients={[currentUserEmail]}
          onClose={closeModal}
          searchId={searchId}
          stages={stages}
        />
      )}
    </>
  ) : null;
};

SearchCustomReportButton.propTypes = {
  currentUserEmail: PropTypes.string.isRequired,
  searchId: PropTypes.number.isRequired,
};

export default SearchCustomReportButton;

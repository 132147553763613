import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';

const BulkImportCard = ({
  className,
  iconClass,
  isComingSoon,
  onClick,
  title,
}) => {
  const buttonClassName = classnames('BulkImportCard', 'card', className, {
    'BulkImportCard--isComingSoon': isComingSoon,
  });

  const iconClassName = classnames('fa', 'BulkImportCard__icon', iconClass, {
    'BulkImportCard__icon--isComingSoon': isComingSoon,
  });

  return (
    <button
      className={buttonClassName}
      disabled={isComingSoon}
      onClick={onClick}
      type='button'
    >
      <i className={iconClassName} />
      <h3 className='u-marginBottom-n u-marginTop-12'>{title}</h3>
      {isComingSoon && <p>(coming soon)</p>}
    </button>
  );
};

BulkImportCard.propTypes = {
  className: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  isComingSoon: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

BulkImportCard.defaultProps = {
  isComingSoon: false,
};

export default BulkImportCard;

import { schema } from 'normalizr';
import { candidacySchema } from 'modules/candidacies/schemas';
import { contactSchema } from 'modules/contacts/schema';
import interviewQuestionSetSchema from 'modules/tenant/schemas/interviewQuestionSets';
import { searchSchema } from 'modules/searches/schema';
import userSchema from 'modules/users/schema';

export default new schema.Entity('interviews', {
  attendees: [contactSchema],
  candidacy: candidacySchema,
  candidate: contactSchema,
  created_by: userSchema,
  contact: contactSchema,
  question_set: interviewQuestionSetSchema,
  searches: [searchSchema],
});

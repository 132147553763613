import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import StepProgress from '@thrivetrm/ui/components/StepProgress';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import {
  LABEL_OTHER,
  LABEL_VERIFICATION_OF_APPROVAL,
} from 'modules/documents/constants';
import { useHistory, useParams } from 'react-router-dom';
import { useApiGet } from 'modules/core/hooks/useApi';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import PageHeader from 'modules/core/components/PageHeader';
import routes from 'modules/routing/routes';
import RoleDetails from 'modules/search-requests/components/RoleDetails';
import RequestDetails from 'modules/search-requests/components/RequestDetails';
import NotesAndDocuments from 'modules/search-requests/components/NotesAndDocuments';
import {
  createSearchRequest,
  editSearchRequest,
  loadSearchRequest,
} from '../searchRequestSlice';

const STEPS = [
  {
    label: 'Request Details',
    value: 'request_details',
  },
  {
    label: 'Role Details',
    value: 'role_details',
  },
  {
    label: 'Notes and Documents',
    value: 'notes_and_documents',
  },
];

const SearchRequestPage = () => {
  const dispatch = useDispatch();
  const { searchRequestId } = useParams();

  const { isCreating, isCreatingComplete, searchRequest } = useSelector(
    state => state.searchRequest,
  );

  useEffect(() => {
    if (parseInt(searchRequestId)) {
      dispatch(loadSearchRequest(searchRequestId));
    }
  }, [dispatch, searchRequestId]);

  const initialFormState = {
    address_id: null,
    code_name: null,
    confidential_reason_note: null,
    confidential: null,
    company_name: null,
    hiring_manager_name: null,
    id: null,
    incumbent_name: null,
    job_code: null,
    job_title: null,
    city: null,
    country_code: null,
    state: null,
    documentation_id: null,
    documentation_url: '',
    verification_of_approval_id: null,
    verification_of_approval_url: '',
    cost_center: null,
    job_function: null,
    level: null,
    placement_type: null,
    organization: null,
    product_area: null,
    qualifications: null,
    search_reason: null,
    search_region: null,
  };

  const [
    isConfirmationDialogOpen,
    openConfirmationDialog,
    closeConfirmationDialog,
  ] = useToggle(false);
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    const existingRecordFormState = {
      address_id: searchRequest?.address?.id || null,
      code_name: searchRequest?.code_name || null,
      confidential_reason_note: searchRequest?.confidential_reason_note || null,
      confidential: searchRequest?.confidential ? 1 : 0,
      company_name: searchRequest?.company_name || null,
      hiring_manager_name: searchRequest?.hiring_manager_name || null,
      id: searchRequest?.id || null,
      incumbent_name: searchRequest?.incumbent_name || null,
      job_code: searchRequest?.job_code || null,
      job_title: searchRequest?.job_title || null,
      city: searchRequest?.address?.city || null,
      country_code: searchRequest?.address?.country_code || null,
      state: searchRequest?.address?.state || null,
      documentation_id: searchRequest?.other_document?.id || null,
      documentation_url: searchRequest?.other_document?.external_url || '',
      verification_of_approval_id:
        searchRequest?.verification_of_approval?.id || null,
      verification_of_approval_url:
        searchRequest?.verification_of_approval?.external_url || '',
      cost_center: searchRequest?.cost_center?.id || null,
      job_function: searchRequest?.job_function_category?.id || null,
      level: searchRequest?.level?.id || null,
      placement_type: searchRequest?.placement_type?.id || null,
      organization: searchRequest?.organization?.id || null,
      product_area: searchRequest?.product_area?.id || null,
      qualifications: searchRequest?.qualifications || null,
      search_reason: searchRequest?.search_reason?.id || null,
      search_region: searchRequest?.search_region?.id || null,
    };

    setFormState(existingRecordFormState);
  }, [searchRequest]);

  const history = useHistory();

  const [currentStepValue] = window.location.pathname.split('/').slice(-1);

  const isEmployee = useSelector(state => isEmployeeUser(state));

  const [
    loadDocumentLabels,
    _isLoadingDocumentLabels,
    _documentLabelLoadError,
    documentLabels,
  ] = useApiGet(
    routes.api_v1_document_labels({
      query: { record_type: 'search_request' },
    }),
  );

  useEffect(() => {
    loadDocumentLabels();
  }, [loadDocumentLabels]);

  const searchRequestOtherLabelId = documentLabels?.find(
    label => label.name === LABEL_OTHER,
  )?.id;

  const searchRequestVerificationLabelId = documentLabels?.find(
    label => label.name === LABEL_VERIFICATION_OF_APPROVAL,
  )?.id;

  const hasSearchRequestsFeature = useFeatureCheck('feature.search_requests');
  const canViewSearchDetailPage = isEmployee && hasSearchRequestsFeature;

  if (isCreatingComplete && canViewSearchDetailPage) {
    window.location = routes.search_request({ id: searchRequest.id });
  }

  const handleFormPageSubmit = dataFromStep => {
    setFormState({
      ...formState,
      ...dataFromStep,
    });
    const currentStepIndex = STEPS.findIndex(
      item => item.value === currentStepValue,
    );
    const isFinalStep = currentStepIndex === STEPS.length - 1;
    if (isFinalStep) {
      openConfirmationDialog();
    } else {
      const nextStep = STEPS[currentStepIndex + 1].value;
      history.push(nextStep);
    }
  };

  useEffect(() => {
    const handler = event => {
      event.returnValue = 'Warning! By leaving, your changes will be lost.';
    };
    if (!isCreating && !isCreatingComplete) {
      window.addEventListener('beforeunload', handler);
    }
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [isCreating, isCreatingComplete]);

  const handleEntireFormSubmit = () => {
    const payload = {
      code_name: formState.code_name,
      confidential_reason_note: formState.confidential_reason_note,
      confidential: formState.confidential === 1,
      company_name: formState.company_name,
      hiring_manager_name: formState.hiring_manager_name,
      id: formState.id,
      incumbent_name: formState.incumbent_name,
      job_code: formState.job_code,
      job_title: formState.job_title,
      address_attributes: {
        city: formState.city,
        country_code: formState.country_code,
        id: formState.address_id,
        state: formState.state,
      },
      documents_attributes: [
        {
          document_label_id: searchRequestOtherLabelId,
          external_url: formState.documentation_url,
          id: formState.documentation_id,
        },
        {
          document_label_id: searchRequestVerificationLabelId,
          external_url: formState.verification_of_approval_url,
          id: formState.verification_of_approval_id,
        },
      ],
      cost_center_id: formState.cost_center,
      job_function_category_id: formState.job_function,
      level_id: formState.level,
      placement_type_id: formState.placement_type,
      organization_id: formState.organization,
      product_area_id: formState.product_area,
      qualifications: formState.qualifications,
      search_reason_id: formState.search_reason,
      search_region_id: formState.search_region,
    };
    if (formState.id) {
      dispatch(editSearchRequest(payload));
    } else {
      dispatch(createSearchRequest(payload));
    }
  };

  const stepComponentMap = {
    request_details: (
      <RequestDetails formData={formState} onSubmit={handleFormPageSubmit} />
    ),
    role_details: (
      <RoleDetails formData={formState} onSubmit={handleFormPageSubmit} />
    ),
    notes_and_documents: (
      <NotesAndDocuments formData={formState} onSubmit={handleFormPageSubmit} />
    ),
  };

  return (
    <>
      <PageHeader title='Create A Search Request' />
      {isCreatingComplete && !canViewSearchDetailPage ? (
        <div className='u-textAlign-c u-marginTop-32'>
          <p>Thank you for submitting a Search Request.</p>
          <ButtonPrimary
            className='RecordIndexHeader__action u-noWrap'
            label='Submit Another Search Request'
            size='medium'
            url='/search_request_form/request_details'
          />
        </div>
      ) : (
        <div className='SearchRequestPage layoutContainer u-marginTop-64'>
          <StepProgress currentStepValue={currentStepValue} steps={STEPS} />
          {stepComponentMap[currentStepValue]}
        </div>
      )}
      <ConfirmationDialog
        cancelLabel='No, Continue Editing'
        confirmLabel='Yes, Submit Search Request'
        isOpen={isConfirmationDialogOpen}
        onClose={closeConfirmationDialog}
        onConfirm={handleEntireFormSubmit}
        title='Submit Search Request?'
      >
        <p className='u-paddingHorizontal-24'>
          Submitting Search Request will send a notification.
        </p>
        <div>Do you want to continue?</div>
      </ConfirmationDialog>
    </>
  );
};

export default SearchRequestPage;

import getName from 'modules/users/selectors/getName';
import getSearchProperty from './getSearchProperty';

/**
 * Gets the name of the "Lead" reccruiter of a search ("lead_internal_team_member").
 * Not to be confused with the "Primary" recruiter, which is actually labeled as
 * "lead_recruiter_team_member" in the database. And yes, that makes no sense.
 * @param {Immutable.Map} state
 * @param {Number} searchId The ID of the search.
 */
const getSearchLeadRecruiterName = (state, searchId) => {
  const userId = getSearchProperty(
    state,
    searchId,
    'lead_internal_team_member',
  );
  return userId && getName(state, userId);
};

export default getSearchLeadRecruiterName;

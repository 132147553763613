const formatTagHierarchy = require('./formatTagHierarchy');

const TagFormats = {
  HIERARCHY: 'hierarchy',
};

const defaultConfig = {
  width: '100%',
};

const makeConfig = (customConfig, tagFormat) => {
  const config = {
    ...defaultConfig,
    ...customConfig,
  };
  if (tagFormat === TagFormats.HIERARCHY) {
    config.templateSelection = formatTagHierarchy;
  }
  return config;
};

// Config can be passed in as an argument. Additionally, config can be declared directly on
// elements via data-attributes in the slim templates (which will override any programattic config)
// aka: f.input :model, input_html: { data: { width: 'Override' } }
module.exports = (scope, selectors, customConfig = {}) => {
  const selectorString = Array.isArray(selectors)
    ? selectors.join(',')
    : selectors;
  const $selects = $(scope).find(selectorString);
  $selects.each((_index, select) => {
    const tagFormat = $(select).data('tagFormat');
    const config = makeConfig(customConfig, tagFormat);
    $(select).select2(config);
  });
  // Hack for known Select2 issue (https://github.com/select2/select2/issues/4384)
  // to re-allow tab-key navigation between the fields.
  $selects.on('select2:close', () => $(this).focus());
  return $selects;
};

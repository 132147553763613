import { compose } from 'recompose';
import { connect } from 'react-redux';
import getEmailTemplatesList from '../selectors/getEmailTemplatesList';

import connectEmailTemplateActions from './connectEmailTemplateActions';
import withEmailTemplatesListFetched from './withEmailTemplatesListFetched';
import mapEmailTemplateIdsToEmailTemplates from './mapEmailTemplateIdsToEmailTemplates';
import withEmailTemplateIdsSortedByName from './withEmailTemplateIdsSortedByName';

/**
 * A higher order component function that ensures that the list of all
 * emailTemplates are fetched and returns them in sorted worker, optionally
 *  excluding specific emailTemplates by ID.
 */
export default compose(
  connectEmailTemplateActions,
  withEmailTemplatesListFetched,
  connect(state => {
    const emailTemplateList = getEmailTemplatesList(state);
    const emailTemplateIds = emailTemplateList && emailTemplateList.get('ids');

    return {
      emailTemplateList: emailTemplateList,
      emailTemplateIds: emailTemplateIds,
    };
  }, {}),
  withEmailTemplateIdsSortedByName,
  mapEmailTemplateIdsToEmailTemplates,
);

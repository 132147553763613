import React from 'react';
import useQuickView from 'modules/quick-view/useQuickView';
import Rating from '@thrivetrm/ui/components/Rating';
import Icon from '@thrivetrm/ui/components/Icon';
import PropTypes from 'prop-types';

const AddRecruitmentRatingButton = ({ rating, searchFirm, searchId }) => {
  const { navigateTo } = useQuickView();

  return (
    <button
      className='btn btn-link'
      data-testid='recruitment-rating-button'
      onClick={() => {
        navigateTo(
          rating?.id
            ? `/company/${searchFirm.id}/recruitment_ratings/${rating.id}`
            : `/company/${searchFirm.id}/search/${searchId}/recruitment_ratings/add`,
          { title: searchFirm.name },
        );
      }}
      type='button'
    >
      <Rating value={rating?.value} />
      {rating?.id ? null : (
        <div className='u-flex u-flexAlign-c u-marginTop-4'>
          <Icon
            className='u-marginRight-4'
            color='blue'
            size='small'
            type='add'
          />
          Add Rating
        </div>
      )}
    </button>
  );
};

AddRecruitmentRatingButton.propTypes = {
  rating: PropTypes.shape({ id: PropTypes.number, value: PropTypes.number }),
  searchFirm: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  searchId: PropTypes.number.isRequired,
};

export default AddRecruitmentRatingButton;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Form from '@thrivetrm/ui/components/Form';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import useFormValues from '@thrivetrm/ui/hooks/useFormValues';
import TextInput from '@thrivetrm/ui/components/TextInput';
import RadioButtonGroup from '@thrivetrm/ui/components/RadioButtonGroup';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import Currency from '@thrivetrm/ui/components/Currency';
import getCurrencySymbol from 'modules/currency/selectors/getCurrencySymbol';
import offerShape from './offerShape';

const bonusWarningMessage =
  'Are you sure? That looks like it might be too high.';

const CompensationOffer = ({ compensationOfferData, offer, onChange }) => {
  const formState = useFormValues();
  const tenant = useSelector(state => state.tenant);
  const currencyCodes = tenant.get('currency_codes');
  const currencySymbol = useSelector(state =>
    getCurrencySymbol(state, formState?.currency),
  );
  const isPercentageBonusType =
    compensationOfferData?.bonusType === 'percentage';

  return (
    <>
      <h3>Compensation Offered</h3>
      <div className='u-flex'>
        <div className='u-marginRight-12'>
          <Form.SelectMenu
            initialValue={offer?.compensation?.currency || 'USD'}
            inputWidth='full'
            label='Currency'
            name='currency'
          >
            {currencyCodes?.map(currency => (
              <SelectMenu.Item key={currency} value={currency}>
                {currency}
              </SelectMenu.Item>
            ))}
          </Form.SelectMenu>
        </div>
        <div className='u-flexItem-grow u-marginRight-12'>
          <TextInput
            format='number'
            inputWidth='full'
            label='Base'
            name='base'
            onChange={newValue => onChange(newValue, 'base')}
            prepend={currencySymbol}
            value={compensationOfferData?.base}
          />
        </div>
        <div className='u-flexItem-grow'>
          {isPercentageBonusType ? (
            <TextInput
              append='%'
              data-testid='offer-percentage-bonus'
              format='number'
              inputWidth='full'
              label='Bonus'
              name='percentageBonus'
              onChange={newValue => onChange(newValue, 'percentageBonus')}
              placeholder='Bonus'
              value={compensationOfferData?.percentageBonus}
            />
          ) : (
            <TextInput
              data-testid='offer-bonus'
              format='number'
              inputWidth='full'
              label='Bonus'
              name='bonus'
              onChange={newValue => onChange(newValue, 'bonus')}
              prepend={currencySymbol}
              value={compensationOfferData?.bonus}
            />
          )}
          {isPercentageBonusType &&
          compensationOfferData?.percentageBonus > 100 ? (
            <div className='u-flex u-flexAlign-c'>
              <StatusIndicator size='medium' status='warning' />
              <p className='u-margin-n u-marginLeft-8 u-fontSize-small has-warning'>
                {bonusWarningMessage}
              </p>
            </div>
          ) : null}
          {isPercentageBonusType &&
          compensationOfferData?.percentageBonus &&
          compensationOfferData?.base ? (
            <div className='text-right'>
              <span className='u-fontSize-small' data-testid='bonus-helper'>
                {compensationOfferData?.percentageBonus}% OF BASE SALARY ={' '}
                <b>
                  <Currency
                    currency={formState?.currency}
                    value={Math.round(
                      (Number(compensationOfferData?.base) *
                        Number(compensationOfferData?.percentageBonus)) /
                        100,
                    )}
                  />{' '}
                  BONUS
                </b>
              </span>
            </div>
          ) : null}
          <RadioButtonGroup
            name='bonusType'
            onChange={newValue => onChange(newValue, 'bonusType')}
            options={[
              { label: 'Fixed Amount', value: 'fixed' },
              { label: '% of base', value: 'percentage' },
            ]}
            value={compensationOfferData?.bonusType}
          />
        </div>
      </div>

      <div className='u-flex u-marginTop-16'>
        <div className='u-marginRight-12 u-flexItem-grow'>
          <Form.TextInput
            append='%'
            format='decimal'
            initialValue={offer?.compensation?.equity}
            inputWidth='full'
            label='Initial Equity Grant'
            name='equity'
          />
          <Form.FieldDependentContent
            shouldRender={fields => fields.equity > 5}
          >
            <div className='u-flex u-flexAlign-c'>
              <StatusIndicator size='medium' status='warning' />
              <p className='u-margin-n u-marginLeft-8 u-fontSize-small has-warning'>
                {bonusWarningMessage}
              </p>
            </div>
          </Form.FieldDependentContent>
        </div>
        <div className='u-flexItem-grow'>
          <Form.TextInput
            format='number'
            initialValue={offer?.compensation?.equityValue}
            inputWidth='full'
            label='Initial Equity in Currency'
            name='equity_value'
            prepend={currencySymbol}
          />
        </div>
      </div>

      <div className='u-flex u-marginTop-16'>
        <div className='u-marginRight-12 u-flexItem-grow'>
          <Form.TextInput
            format='number'
            initialValue={offer?.compensation?.initialEquityShares}
            inputWidth='full'
            label='Initial Number of Equity Shares'
            name='initial_equity_shares'
          />
        </div>
        <div className='u-flexItem-grow'>
          <Form.TextInput
            format='number'
            initialValue={offer?.compensation?.signingBonus}
            inputWidth='full'
            label='Signing Bonus'
            name='signing_bonus'
            prepend={currencySymbol}
          />
        </div>
      </div>

      <div className='u-flex u-marginTop-16'>
        <div className='u-flexItem-grow u-marginRight-12'>
          <Form.TextInput
            format='number'
            initialValue={offer?.compensation?.relocationBonus}
            inputWidth='full'
            label='Relocation Bonus'
            name='relocation_bonus'
            prepend={currencySymbol}
          />
        </div>
        <div className='u-flexItem-grow'>
          <Form.TextInput
            format='number'
            initialValue={offer?.compensation?.totalCompensation}
            inputWidth='full'
            label='Total Estimated Compensation'
            name='total_compensation'
            prepend={currencySymbol}
          />
        </div>
      </div>
    </>
  );
};

CompensationOffer.propTypes = {
  compensationOfferData: PropTypes.shape({
    base: PropTypes.number,
    bonus: PropTypes.number,
    bonusType: PropTypes.string,
    currency: PropTypes.string,
    equity: PropTypes.number,
    initialEquityShares: PropTypes.string,
    percentageBonus: PropTypes.number,
    relocationBonus: PropTypes.string,
    signingBonus: PropTypes.string,
    totalCompensation: PropTypes.string,
  }),
  offer: PropTypes.shape(offerShape),
  onChange: PropTypes.func,
};

export default CompensationOffer;

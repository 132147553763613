import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import CreatableSelect from 'react-select/creatable';
import { useSelector, useDispatch } from 'react-redux';
import { stringToCamelCase } from 'modules/core/jsonUtils';
import {
  registerFilterInput,
  setAndValidateSingleFilterValue,
  selectFilterInputData,
  requestRecordList,
} from '../../recordIndexSlice';
import { EMPTY_STYLES } from './common-react-select-constants';

const MultiValueNumberFilter = ({ label, name, onChange }) => {
  const dispatch = useDispatch();

  const hasRegisteredFilterInput = useSelector(state =>
    Boolean(state.recordIndex?.savedView?.filters?.[stringToCamelCase(name)]),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  const filterInputValues = useSelector(
    state => state.recordIndex?.savedView?.filters?.[name] || [],
  );
  const { placeholder } = useSelector(state =>
    selectFilterInputData(state, name),
  );

  const [inputValue, setInputValue] = useState('');

  const updateValueAndFetchData = () => {
    const nextValues = [
      ...(Array.isArray(filterInputValues)
        ? filterInputValues
        : [filterInputValues]),
      ...inputValue.split(','),
    ];

    setInputValue('');
    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: nextValues || [],
      }),
    );
    dispatch(requestRecordList());
  };

  const handleKeyDown = event => {
    if (!inputValue) return;
    if (
      event.key === 'Enter' ||
      event.key === 'Tab' ||
      event.key === ',' ||
      event.key === ' '
    ) {
      event.preventDefault();
      updateValueAndFetchData();
    }
  };

  function formatValue(input) {
    const numericValues = input.match(/\d+/g);
    if (!numericValues) {
      return '';
    }
    return numericValues.join(',');
  }

  const handleInputChange = (newValue, { action }) => {
    const formattedValue = formatValue(newValue);
    if (action === 'input-blur' && inputValue) {
      updateValueAndFetchData();
    } else if (action !== 'menu-close') {
      setInputValue(formattedValue);
    }
  };

  const handleChange = newValue => {
    const normalizedValue = newValue.map(option => option.value);

    if (onChange) onChange(newValue);

    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: normalizedValue,
      }),
    );
    return dispatch(requestRecordList());
  };

  // Wait for saved view to be seeded before rendering
  if (!hasRegisteredFilterInput) return null;

  return (
    <div>
      {label && (
        <label className='MultiValueTextBooleanFilter__label'>{label}</label>
      )}
      <div className='MultiValueTextBooleanFilter'>
        <CreatableSelect
          className='MultiValueTextBooleanFilter__creatableContainer'
          classNamePrefix='MultiValueTextBooleanFilter'
          components={{
            DropdownIndicator: null,
          }}
          inputValue={inputValue}
          isClearable={false}
          isMulti={true}
          menuIsOpen={false}
          onChange={handleChange}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          styles={EMPTY_STYLES}
          value={(Array.isArray(filterInputValues)
            ? filterInputValues
            : [filterInputValues]
          )?.map(val => ({
            label: val,
            value: val,
          }))}
        />
      </div>
    </div>
  );
};

MultiValueNumberFilter.defaultProps = {
  label: null,
  onChange: null,
};

MultiValueNumberFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default MultiValueNumberFilter;

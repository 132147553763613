import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from 'modules/core/componentsLegacy/Checkbox';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import TagColorSquare from './TagColorSquare';
import TagName from './TagName';

/**
 * A checkbox for toggling the state of tag.
 * Renders the checkbox, tag color square, and tag name horizontally.
 */
const TagCheckbox = ({
  color,
  disabled,
  loading,
  name,
  searchType,
  tagId,
  ...props
}) => (
  <div
    className={classnames('TagCheckbox', 'checkbox', {
      disabled: disabled,
    })}
  >
    <label className='TagCheckbox__label' htmlFor={tagId}>
      <Checkbox
        {...props}
        className='inline-form-control'
        disabled={disabled}
        id={tagId}
      />
      {searchType === TYPE_PIPELINE ? (
        <>
          <TagColorSquare
            className='TagCheckbox__TagColorSquare'
            searchType={searchType}
            tagId={tagId}
          />
          <TagName searchType={searchType} tagId={tagId} />
        </>
      ) : (
        <>
          <span
            className='TagCheckbox__TagColorSquare TagCheckbox__SearchTagColorSquare u-inlineBlock'
            style={{
              background: color,
            }}
          />
          <span>{name}</span>
        </>
      )}
      {loading ? (
        <i className='TagCheckbox__loadingIndicator fa fa-spinner fa-spin' />
      ) : null}
    </label>
  </div>
);

TagCheckbox.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  searchType: PropTypes.string,
  /**
   * The tag ID to render.
   */
  tagId: PropTypes.number.isRequired,
};

export default TagCheckbox;

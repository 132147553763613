import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setPropTypes,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import classnames from 'classnames';

import ContactCreateModalForm from './ContactCreateModalForm';

/**
 * Renders a button that, when clicked, displays a modal form for creating a contact.
 * Will call onSaved with the contact ID when a new contact is successfully created.
 */
const ContactCreateModalButton = ({
  btnClassName,
  children,
  hideModal,
  isModalShown,
  onSaved,
  showModal,
  sidebarDisplay,
  ...modalProps
}) => [
  <button
    className={classnames('ContactCreateModalButton', 'btn', btnClassName)}
    key='trigger'
    onClick={showModal}
    type='button'
  >
    {children}
  </button>,
  <ContactCreateModalForm
    {...modalProps}
    excludePreferredNameField={true}
    key='modal'
    onClose={hideModal}
    onSaved={onSaved}
    onSelect={onSaved}
    show={isModalShown}
    sidebarDisplay={sidebarDisplay}
  />,
];

ContactCreateModalButton.propTypes = {
  /**
   * An additional class name to apply to the button.
   */
  btnClassName: PropTypes.string,

  /**
   * The label/content of the button.
   */
  children: PropTypes.node,

  /**
   * A function that will be called when the create modal should be hidden.
   */
  hideModal: PropTypes.func.isRequired,

  /**
   * True if the modal should be visible/rendered, otherwise false.
   */
  isModalShown: PropTypes.bool,

  /**
   * A function that will be called when a contact has been successfully created.
   */
  onSaved: PropTypes.func.isRequired,

  /**
   * A function that will be called when the modal should be shown.
   */
  showModal: PropTypes.func.isRequired,
};

ContactCreateModalButton.defaultProps = {
  btnClassName: 'btn-default',
  children: <i className='fa fa-user-plus' />,
  isModalShown: false,
};

export default compose(
  setDisplayName('ContactCreateModalButton(enhanced)'),
  setPropTypes({
    /**
     * The class name to apply to the button
     */
    btnClassName: ContactCreateModalButton.propTypes.btnClassName,

    /**
     * The content to render inside the button.
     */
    children: PropTypes.node,

    /**
     * Called when a contact has been successfully created. Called with the contact ID as the
     * first parameter.
     */
    onCreated: PropTypes.func.isRequired,
  }),
  withState('isModalShown', 'setModalShown', false),
  withHandlers({
    showModal: ({ setModalShown }) => () => setModalShown(true),
    hideModal: ({ setModalShown }) => () => setModalShown(false),
    onSaved: ({ onCreated, setModalShown }) => id => {
      setModalShown(false);
      onCreated(id);
    },
  }),
)(ContactCreateModalButton);

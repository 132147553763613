import React from 'react';
import PropTypes from 'prop-types';
import routes from 'modules/routing/routes';
import DocumentUploader from 'modules/documents/components/DocumentUploader';
import { useApiGet } from 'modules/core/hooks/useApi';
import { RESOURCE_TYPE_CONTACT } from '../../constants';

const UploadCsv = ({ uploadCsvFile }) => {
  const [loadDocuments] = useApiGet(routes.api_v1_csv_uploads());
  return (
    <>
      <ol className='BulkImportModal__instructionList'>
        <li className='BulkImportModal__instructionListItem'>
          Download the bulk import{' '}
          <a href={routes.template_api_v1_csv_upload()}>
            CSV Import Template For Contacts
          </a>{' '}
          and add the data you would like to import.
        </li>
        <li className='BulkImportModal__instructionListItem'>
          Upload your CSV file, and we will process it as follows:
        </li>
      </ol>
      <ul className='BulkImportModal__descriptionList'>
        <li className='BulkImportModal__descriptionListItem'>
          Any row with a Thrive ID that matches an existing Contact in Thrive
          will update the contact.
        </li>
        <li className='BulkImportModal__descriptionListItem'>
          Any row with a LinkedIn URL or email address that matches an existing
          contact in Thrive will be recognized as a duplicate and result in a
          contact review being created.
        </li>
        <li className='BulkImportModal__descriptionListItem'>
          Any row that does not match on any criteria will result in a new
          contact getting created.
        </li>
      </ul>

      <div className='BulkImportModal__uploader'>
        <DocumentUploader
          bulkImportResourceType={RESOURCE_TYPE_CONTACT}
          bulkImportUploadCsvFile={uploadCsvFile}
          fetchDocuments={loadDocuments}
        />
      </div>
    </>
  );
};

UploadCsv.propTypes = {
  uploadCsvFile: PropTypes.func.isRequired,
};

export default UploadCsv;

import extend from 'lodash/extend';

export default ($field, options) => {
  $field.autocomplete(
    extend(
      {
        serviceUrl: $field.data('autocomplete-splitter-path'),
        minChars: 3,
        triggerSelectOnValidInput: false,
        // recommended for remote data calls
        deferRequestBy: 500,
        showNoSuggestionNotice: true,
        noSuggestionNotice:
          $field.data('autocomplete-notice') || 'No results found',
        onSelect: suggestion => {
          if (suggestion.data != null) {
            $($field.data('autocomplete-target')).val(suggestion.data.id);
            if ($field.data('autocomplete-secondary-target') != null) {
              $($field.data('autocomplete-secondary-target')).val(
                suggestion.position,
              );
            }
          }
        },
        onInvalidateSelection: ({ target }) => {
          return $($(target).data('autocomplete-target')).val('');
        },
      },
      options || {},
    ),
  );

  return $field.on('focus', ({ target }) => {
    $(target).autocomplete().onValueChange();
  });
};

export const destroy = $field => $field.autocomplete('dispose');

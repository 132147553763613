import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import ExternalLink from 'modules/links/ExternalLink';

/**
 * Renders an external link for a contact, with an optional icon
 * and tooltip.
 */
const ContactExternalLink = ({
  children,
  componentClass: Component,
  icon,
  iconClassName,
  iconSize,
  renderIfEmpty,
  target,
  tooltip,
  url,
  ...props
}) => {
  if (!renderIfEmpty && !url) {
    return null;
  }

  let link = (
    <ExternalLink className='u-wordBreak' href={url} target={target}>
      {icon && (
        <i
          className={classnames('fa', icon, iconClassName, {
            [`fa-${iconSize}`]: Boolean(iconSize),
          })}
        />
      )}
      {children}
    </ExternalLink>
  );

  if (Component) {
    link = <Component {...props}>{link}</Component>;
  }

  return tooltip ? (
    <TooltipTrigger tooltip={tooltip}>{link}</TooltipTrigger>
  ) : (
    link
  );
};

ContactExternalLink.propTypes = {
  /**
   * The content of the link
   */
  children: PropTypes.string,

  /**
   * The component class to surround the link with (i.e. 'span', 'div', 'li', MyComponent)
   * Renders a `<span />` by default.
   */
  componentClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  /**
   * An optional font awesome icon class of an icon to render in the link content.
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,

  /**
   * Additional icon classes that to apply to the icon (if `icon` is given a value)
   * This can be used to set classes like `fa-fw`, `fa-li`, etc.
   */
  iconClassName: PropTypes.string,

  /**
   * An optional fa-* size name (i.e. `lg`, `2x`, `3x`)
   */
  iconSize: PropTypes.oneOf(['lg', '2x', '3x', '4x', '5x']),

  /**
   * By default, if there is no URL passed, nothing will be rendered. Set this to
   * true to render the component regardless.
   */
  renderIfEmpty: PropTypes.bool,

  /**
   * A `target` property to pass to the link (i.e. '_blank', '_self', etc)
   */
  target: PropTypes.string,

  /**
   * Set this to a node or string to render a tooltip that will be displayed when
   * hovering the link
   */
  tooltip: PropTypes.node,

  /**
   * The URL to link to.
   */
  url: PropTypes.string,
};

ContactExternalLink.defaultProps = {
  renderIfEmpty: false,
  target: '_blank',
};

export default ContactExternalLink;

import classnames from 'classnames';
import { withProps } from 'recompose';

/**
 * Adds a className prop using the provided classname, but including any additional classNames
 * that may have been supplied.
 * @param {String} classNameToAdd The class name to include.
 *
 * @example
 * ```js
 * const ComponentAlpha = ({ className }) => (
 *   <div className={className}>Hello!</div>
 * );
 *
 * const ComponentBeta = compose(
 *   withClassName('Beta'),
 * )(ComponentAlpha);
 *
 * const ComponentCharlie = () => (
 *   <ComponentBeta className='Charlie
 * )
 *
 * // ComponentCharlie renders:
 * // <div class='Beta Charlie'>Hello!</div>
 */
export default classNameToAdd =>
  withProps(({ className }) => ({
    className: classnames(classNameToAdd, className),
  }));

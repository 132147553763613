import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import { candidacySchema } from '../../schemas';

/**
 * Creates an action for updating a candidacy rank
 * @param {Object} payload
 * @param {String} payload.id The ID of the candidacy to update
 * @param {Object} payload.other_candidacy_id The candidacy record we are ranking before or after
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ id, otherCandidacyId, transactionId }) => dispatch => {
  dispatch(
    updateEntity({
      transactionId: transactionId,
      entityType: candidacySchema.key,
      responseSchema: {
        candidacy: candidacySchema,
        reranked_candidacies: [candidacySchema],
      },
      url: routes.update_rank_api_v1_candidacy({ id: id }),
      body: {
        other_candidacy_id: otherCandidacyId,
      },
    }),
  ).then(action => action);
};

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';

/**
 * This is a helper component for rendering an action for the `TabbedHeaderContainer`. This isn't
 * strictly necessary -- any component that renders an `<li />` will do, but this component
 * provides a tooltip overlay.
 */
const TabbedHeaderAction = ({ children, componentId, label }) => (
  <li className='TabbedHeaderAction'>
    <OverlayTrigger
      overlay={<Tooltip id={componentId}>{label}</Tooltip>}
      placement='bottom'
    >
      {children}
    </OverlayTrigger>
  </li>
);

TabbedHeaderAction.propTypes = {
  /**
   * The underlying children component(s) to render.
   */
  children: PropTypes.node.isRequired,

  /**
   * A unique componentId for this item (needed for the tooltip)
   */
  componentId: PropTypes.string.isRequired,

  /**
   * The label to display in the tooltip when this item is hovered.
   */
  label: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('TabbedHeaderAction(enhanced)'),
  setPropTypes({
    children: TabbedHeaderAction.propTypes.children,
    label: TabbedHeaderAction.propTypes.label,
  }),
  withComponentId(),
)(TabbedHeaderAction);

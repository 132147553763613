import deleteEntity from 'modules/entities/actions/deleteEntity';
import routes from 'modules/routing/routes';
import tagSchema from '../schemas/tag';

/**
 * Creates an action for deleting a tag record
 * @param {Object} payload
 * @param {Number} payload.id The ID of the tag record to delete
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ id, transactionId }) =>
  deleteEntity({
    transactionId: transactionId,
    // `id` is needed on the payload so we can access it in the reducer.
    id: id,
    entityType: tagSchema.key,
    url: routes.api_v1_tag({ id: id }),
  });

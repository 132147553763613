import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { compose, setDisplayName, setStatic } from 'recompose';
import { connect } from 'react-redux';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';

import withContactMethodListFetched from './withContactMethodListFetched';
import getContactMethod from '../selectors/getContactMethod';

export const ContactMethodsSelectField = ({
  className,
  contactMethodList,
  contactMethods,
  fieldState,
  ...props
}) => (
  <ReactSelectField
    {...props}
    className={classnames('ContactMethodsSelectField', className)}
    disabled={!contactMethods}
    fieldState={fieldState}
    isLoading={contactMethodList.getIn(['_meta', 'isFetching'])}
    labelKey='name'
    options={contactMethods ? contactMethods.toJS() : []}
    placeholder='* Contact Method'
    simpleValue={true}
    valueKey='id'
  />
);

export const propTypes = {
  className: PropTypes.string,

  contactMethodList: ImmutablePropTypes.mapContains({
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),

  contactMethods: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),

  fieldState: fieldStatePropType.isRequired,
};

ContactMethodsSelectField.propTypes = propTypes;

export default compose(
  setDisplayName('ContactMethodsSelectField(enhanced)'),
  setStatic('createFieldState', ReactSelectField.createFieldState),
  withContactMethodListFetched,
  connect(
    (state, { contactMethodList }) => ({
      contactMethodList: contactMethodList,
      contactMethods:
        contactMethodList && contactMethodList.has('ids')
          ? contactMethodList.get('ids').map(id => getContactMethod(state, id))
          : null,
    }),
    {},
  ),
)(ContactMethodsSelectField);

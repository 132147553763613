import apiV1 from './index';
import queryTags from './queryTags';

const { CONTACT_NETWORKS } = queryTags;

const contactNetworks = apiV1.injectEndpoints({
  endpoints: builder => ({
    getContactNetworks: builder.query({
      query: contactId => ({
        url: `/contacts/${contactId}/networks`,
        method: 'GET',
      }),
      providesTags: [CONTACT_NETWORKS],
      transformResponse: response => response.networks,
    }),
    addContactToNetwork: builder.mutation({
      query: ({ contactId, networkId }) => ({
        url: `/networks/${networkId}/contacts`,
        method: 'POST',
        body: { contact_id: contactId },
      }),
      invalidatesTags: [CONTACT_NETWORKS],
    }),
    removeContactFromNetwork: builder.mutation({
      query: ({ contactId, networkId }) => ({
        url: `/networks/${networkId}/contacts/${contactId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CONTACT_NETWORKS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddContactToNetworkMutation,
  useGetContactNetworksQuery,
  useRemoveContactFromNetworkMutation,
} = contactNetworks;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import IntroductionListItem from './IntroductionListItem';
import withIntroductionIdsSortedByDate from './withIntroductionIdsSortedByDate';

/**
 * Renders a list of introductions, with the ability to edit or delete them
 * inline if the user has permission (based on `permissions.edit` and `permssions.delete`
 * values of the introduction item).
 */
const IntroductionList = ({
  introductionIds,
  limit,
  linkToCandidacy,
  povContactId,
  ...props
}) => (
  <ul className='list-generic list-introductions'>
    {(limit ? introductionIds.take(limit) : introductionIds)
      .map(introductionId => (
        <IntroductionListItem
          {...props}
          introductionId={introductionId}
          key={introductionId}
          linkToCandidacy={linkToCandidacy}
          povContactId={povContactId}
        />
      ))
      .toArray()}
  </ul>
);

IntroductionList.propTypes = {
  introductionIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,

  /**
   * The maximum number of introductions to render.
   */
  limit: PropTypes.number,

  /**
   * Wraps the introduction in a link to its associated candidacy.
   */
  linkToCandidacy: PropTypes.bool,

  /**
   * The ID of the contact whose context this list is being displayed in.
   * @see IntroductionView.propTypes.povContactId
   */
  povContactId: PropTypes.number,
};

export default withIntroductionIdsSortedByDate(IntroductionList);

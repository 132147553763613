import { USER_STATE_KEY } from '../constants';

/**
 * Gets the ID of the current user.
 * @param {Object} state
 * @returns {Number} The currently logged in user's ID.
 */
const getCurrentUserId = state => state[USER_STATE_KEY].get('current_user_id');

export default getCurrentUserId;

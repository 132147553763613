import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  withProps,
  withStateHandlers,
} from 'recompose';
import TableView from 'modules/core/componentsLegacy/Table/TableView';
import { SORT_BY_ROUND_DATE } from 'modules/company-investments/constants';
import withInvestmentIdsSorted from 'modules/company-investments/components/withInvestmentIdsSorted';
import withFundingRoundListFetched from './withFundingRoundListFetched';
import fundingRoundTableColumns from './fundingRoundTableColumns';

/**
 * Renders a table of the funding rounds for a company.
 */
export default compose(
  setDisplayName('FundingRoundsTableView'),
  setPropTypes({
    /**
     * The ID off the company to show the funding rounds for.
     */
    companyId: PropTypes.number.isRequired,
  }),
  defaultProps({
    // Always use the same set of columns.
    columns: fundingRoundTableColumns,
  }),
  withStateHandlers(
    {
      sortBy: SORT_BY_ROUND_DATE,
      sortAscending: true,
    },
    {
      onSortChange: () => (sortBy, sortAscending) => ({
        sortBy: sortBy,
        sortAscending: sortAscending,
      }),
    },
  ),
  withFundingRoundListFetched,
  withProps(({ fundingRoundList }) => ({
    investmentIds: fundingRoundList && fundingRoundList.get('ids'),
    isFetching:
      fundingRoundList && fundingRoundList.getIn(['_meta', 'isFetching']),
  })),
  withInvestmentIdsSorted,
  mapProps(
    ({
      columns,
      investmentIds,
      isFetching,
      onSortChange,
      sortAscending,
      sortBy,
      theme,
    }) => ({
      columns: columns,
      data: investmentIds,

      // Render an empty state component when we don't have any funding rounds.
      emptyState: !isFetching &&
        investmentIds &&
        investmentIds.count() === 0 && {
          message: 'No funding rounds found',
        },

      loadingIndicator: isFetching && {},
      theme: theme,

      // Sorting
      sortBy: sortBy,
      sortAscending: sortAscending,
      onSortChange: onSortChange,
    }),
  ),
)(TableView);

import React from 'react';
import PropTypes from 'prop-types';
import { useApiPost } from 'modules/core/hooks/useApi';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Card from '@thrivetrm/ui/components/Card';

const MailchimpIntegrationGenerateToken = ({ isDisabled, onTokenChange }) => {
  const dispatch = useDispatch();

  const [
    generateMailchimpToken,
    isCreatingMailchimpToken,
    generateMailchimpTokenError,
  ] = useApiPost('/api/integrations/mailchimp/residencies');

  const handleSubmit = () => {
    generateMailchimpToken(
      {},
      {
        onSuccess: response => {
          dispatch(
            toastSuccess('Successfully generated Mailchimp Webhook URL.'),
          );

          onTokenChange(response.mailchimp_residency_token);
        },
      },
    );
  };

  return (
    <div
      className={classnames({
        'MailchimpIntegrationPage--isDisabled': isDisabled,
      })}
    >
      <h3 className='u-marginTop-32 u-marginBottom-8'>
        2. Generate Thrive Webhook Url
      </h3>
      <p className='u-marginBottom-12'>
        Next, generate your Webhook URL. This lets Mailchimp notify Thrive when
        you send a Mailchimp communication, so we can log it into your contacts’
        profiles.
      </p>

      <ButtonSecondary
        buttonType='submit'
        icon='refresh'
        isLoading={isCreatingMailchimpToken}
        label='Generate Webhook URL'
        onClick={handleSubmit}
      />

      {generateMailchimpTokenError ? (
        <Card className='u-marginBottom-12' isCentered={false} type='error'>
          <h4 className='u-marginTop-n'>
            There was an error creating Mailchimp Webhook URL.
          </h4>
          {generateMailchimpTokenError}
        </Card>
      ) : null}
    </div>
  );
};

MailchimpIntegrationGenerateToken.defaultProps = {
  isDisabled: false,
};

MailchimpIntegrationGenerateToken.propTypes = {
  isDisabled: PropTypes.bool,
  onTokenChange: PropTypes.func.isRequired,
};

export default MailchimpIntegrationGenerateToken;

import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import industrySchema from '../schemas/industries';

/**
 * A tag select component for choosing from the list of industries.
 */

const IndustriesTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField {...props} schema={industrySchema} />
);

IndustriesTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default IndustriesTagSelectField;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import useQuickView from 'modules/quick-view/useQuickView';
import OffLimitsStatus from './OffLimitsStatus';

const ApplyCustomOffLimitsIndicator = ({
  recordId,
  recordName,
  recordType,
}) => {
  const { navigateTo } = useQuickView();
  const isCrmUser = useSelector(isCrmUserSelector);

  return (
    <button
      className='u-backgroundColor-transparent u-border-n u-padding-n'
      disabled={isCrmUser}
      onClick={() =>
        navigateTo(`/${recordType}/${recordId}/off-limits`, {
          title: recordName,
        })
      }
      type='button'
    >
      <OffLimitsStatus
        isHoverable={true}
        severity='add'
        status='Apply Off Limits'
      />
    </button>
  );
};

ApplyCustomOffLimitsIndicator.propTypes = {
  recordId: PropTypes.number.isRequired,
  recordName: PropTypes.string.isRequired,
  recordType: PropTypes.oneOf(['company', 'contact']).isRequired,
};

export default ApplyCustomOffLimitsIndicator;

import PropTypes from 'prop-types';
import selectn from 'selectn';
import { fromJS } from 'immutable';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withFormState from 'modules/forms/components/withFormState';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withTransaction from 'modules/transactions/components/withTransaction';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import mapCompanyIdToCompany from './mapCompanyIdToCompany';
import * as actions from '../actions';

/**
 * Provides props for managing a form that is submitted using the updateCompany action.
 *
 * Creates the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying CompanyBasicInfoField FieldState
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default RootField =>
  compose(
    setDisplayName('withCompanyUpdateForm'),
    setPropTypes({
      /**
       * If editing an existing company, it's ID
       */
      companyId: PropTypes.number,

      /**
       * An optional formstate to initial the form with (handled by withFormState)
       */
      initialFormState: formStatePropType,

      /**
       * Called when the form has been successfully saved.
       */
      onSaved: PropTypes.func.isRequired,
    }),

    // Create a temporary prop (`incomingProps`) that holds any incoming props
    // that aren't required by us. This ensures we don't overwrite/delete/clobber any props that may
    // need to be passed through.
    withProps(
      ({
        companyId: _companyId,
        initialFormState: _initialFormState,
        onSaved: _onSaved,
        ...incomingProps
      }) => ({
        incomingProps: incomingProps,
      }),
    ),

    /**
     * Load the company into the formstate.
     */
    mapCompanyIdToCompany,

    withFeatureCheck('feature.skyminyr', 'hasSkyminyrFeature'),
    /**
     * Create a FormState, initializing it with the value from `company`,
     * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
     */
    withFormState(({ company, draft, hasSkyminyrFeature }, isReset) => {
      let companyValue = company;
      const emptyFormState = hasSkyminyrFeature
        ? { hasSkyminyrFeature: hasSkyminyrFeature }
        : null;

      if (draft?.content) {
        companyValue = company.withMutations(immutableCompany => {
          immutableCompany.set('description', draft?.content);
        });
      }
      if (hasSkyminyrFeature) {
        companyValue = company.withMutations(immutableCompany => {
          immutableCompany.set('hasSkyminyrFeature', hasSkyminyrFeature);
        });
      }

      return RootField.createFieldState(
        'company',
        isReset ? emptyFormState : companyValue,
      );
    }),

    /**
     * Watch for any save transaction to complete
     */
    withHandlers({
      /**
       * This gets called by `withTransaction`, below, any time our transaction started
       * with `startTransaction` is called.
       */
      onTransactionComplete: ({
        formState,
        onFormStateChange,
        onResetFormState,
        onSaved,
      }) => transaction => {
        const error = selectn('payload.error', transaction);
        onFormStateChange(formState.endSubmit(error));

        if (!error) {
          onResetFormState();

          // If all was good, call onSaved with the record's ID
          onSaved(selectn('payload.result.company', transaction));
        }
      },
    }),

    /**
     * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
     * when the transaction used with `startTransaction` is finished.
     */
    withTransaction,

    /**
     * Include connected version of `updateCompany` that is needed to submit.
     */
    connect(null, dispatch => ({
      updateCompany: bindActionCreators(actions.updateCompany, dispatch),
    })),

    /**
     * Add a callback to handle submitting the action form.
     */
    withHandlers({
      // Called when the form should be submitted.
      onSubmit: ({
        company,
        formState,
        onFormStateChange,
        startTransaction,
        updateCompany,
      }) => e => {
        const transactionId = startTransaction();
        const fieldValue = formState.getFieldValue();

        updateCompany({
          company: fromJS({ id: company.get('id') })
            .merge(fieldValue)
            .toJS(),
          transactionId: transactionId,
        });

        onFormStateChange(formState.startSubmit(transactionId));

        // Prevent default browser behavior, which could cause the browser to attempt
        // to submit a form on it's own.
        return preventDefaultHandler(e);
      },
    }),

    // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
    mapProps(
      ({
        companyId,
        formState,
        hasSkyminyrFeature: _hasSkyminyrFeature,
        incomingProps,
        onCancel,
        onFieldStateChange,
        onFormStateChange,
        onResetFormState,
        onSubmit,
      }) => ({
        ...incomingProps,
        companyId: companyId,
        formState: formState,
        onFieldStateChange: onFieldStateChange,
        onFormStateChange: onFormStateChange,
        onResetFormState: onResetFormState,
        onSubmit: onSubmit,
        onCancel: onCancel,
      }),
    ),
  );

import { connect } from 'react-redux';
import getStagePosition from '../selectors/getStagePosition';

/**
 * A Higher order component that sorts a list of stageIds by their position values.
 */
export default connect(
  (state, { stageIds }) => ({
    stageIds: stageIds && stageIds.sortBy(id => getStagePosition(state, id)),
  }),
  {},
);

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  componentFromProp,
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import UserAvatar from 'modules/users/components/UserAvatar';
import ContactAvatar from '../ContactAvatar';

/**
 * Renders the avatar of the person who made the rating.
 * If attributed to a contact, then the contact's avatar is rendered,
 * otherwise the user avatar of the user this rating belongs to is rendered.
 */
export default compose(
  setDisplayName('ContactRatingAvatar'),
  setPropTypes({
    rating: ImmutablePropTypes.mapContains({
      attributed_to: PropTypes.number,
      user: PropTypes.number,
    }),
  }),
  mapProps(({ rating }) => ({
    component: rating.get('attributed_to') ? ContactAvatar : UserAvatar,
    contactId: rating.get('attributed_to'),
    userId: rating.get('user'),
  })),
)(componentFromProp('component'));

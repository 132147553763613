import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

/**
 * Handles the form action for rejecting a job application.
 * @param {FormState} formState A FormState for a job application rejection.
 * @param {Object} props The props passed to the form component.
 * @param {Number} props.jobApplicationId The ID of the job application being rejected.
 * @param {Object} props.jobApplicationActions The connected job application action creators.
 * @param {Function} props.jobApplicationActions.updateJobApplication The connected action
 *   for dispatching job application update
 * @return {FormState} The updated formstate after initiating a submit.
 */
const handleSubmitRejection = (formState, props) => {
  const { jobApplicationActions, jobApplicationId } = props;
  const transactionId = uniqueId();
  jobApplicationActions.updateJobApplication({
    transactionId: transactionId,
    job_application: {
      ...formState.getFieldValue(),
      id: jobApplicationId,
    },
  });
  return formState.startSubmit(transactionId);
};

export default handleSubmitRejection;

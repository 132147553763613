import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, setDisplayName, setPropTypes } from 'recompose';
import connectAssessmentActions from 'modules/assessments/components/connectAssessmentActions';
import mapAssessmentIdToAssessment from 'modules/assessments/components/mapAssessmentIdToAssessment';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import Card from '@thrivetrm/ui/components/Card';
import AssessmentForm from './AssessmentForm';
import AssessmentView from './AssessmentView';

import InlineEditActions from '../../../../components/forms/InlineEditActions';

/**
 * Renders a single list item from an `AssessmentList`
 */
export class AssessmentListItem extends Component {
  state = {
    /**
     * True when the item is being edited inline, false when being viewed.
     * @type {Boolean}
     */
    isEditing: false,
  };

  /**
   * Called when the 'Yes' button of the delete confirmation is clicked.
   * Calls the action to delete the assessment and resets the action.
   */
  handleDeleteClick = () => {
    const { assessment, assessmentActions } = this.props;
    this.setState({ isEditing: false });
    assessmentActions.deleteAssessment({ id: assessment.get('id') });
  };

  /**
   * Called when the edit link is clicked, shows the edit form in place of
   * the assessment view.
   */
  handleEditClick = () => {
    this.setState({ isEditing: true });
  };

  /**
   * Called when the edit form is closed, removes the edit form and shows
   * the assessment view instead.
   */
  handleFormClose = () => {
    this.setState({ isEditing: false });
  };

  render() {
    const { assessment, candidacyId, readOnly, searchId } = this.props;
    const { isEditing } = this.state;
    const isDeleting = assessment.getIn(['_meta', 'isDeleting']);
    const canDelete = assessment.getIn(['permissions', 'delete']);
    const canEdit = assessment.getIn(['permissions', 'edit']);
    const showForm = isEditing;
    const assessmentType = assessment.get('type');
    return (
      <li className='list-item-assessment'>
        <Card isCentered={false}>
          {showForm ? (
            <AssessmentForm
              alwaysShowFieldErrors={true}
              assessment={assessment}
              assessmentType={assessmentType}
              draftStoragePath={{
                search: searchId,
                candidate: candidacyId,
                [assessmentType]: assessment.get('id'),
              }}
              errorDisplay='tooltip'
              onCancel={this.handleFormClose}
              onSaved={this.handleFormClose}
              title='Edit Assessment'
            />
          ) : (
            <AssessmentView
              assessment={assessment}
              renderQuestionAnswers={true}
            />
          )}
          <InlineEditActions
            canDelete={!showForm && !readOnly && canDelete}
            canEdit={!showForm && !readOnly && canEdit}
            deleteConfirmation='Delete this assessment?'
            disabled={isDeleting}
            onDelete={this.handleDeleteClick}
            onEdit={this.handleEditClick}
          />
        </Card>
      </li>
    );
  }
}

AssessmentListItem.defaultProps = {
  readOnly: false,
};

AssessmentListItem.propTypes = {
  assessment: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isDeleting: PropTypes.bool,
    }),
    permissions: ImmutablePropTypes.mapContains({
      delete: PropTypes.bool,
      edit: PropTypes.bool,
    }),
  }).isRequired,

  assessmentActions: requiredIf(
    PropTypes.shape({
      deleteAssessment: PropTypes.func.isRequired,
    }),
    props => !props.readOnly,
  ),

  readOnly: PropTypes.bool,
};

export default compose(
  setDisplayName('AssessmentListItem(enhanced)'),
  // The connected version of this component does not require
  // the assessment actions propType.
  setPropTypes({
    assessmentId: PropTypes.number,
    readOnly: AssessmentListItem.propTypes.readOnly,
  }),
  connectAssessmentActions,
  branch(
    ({ assessment, assessmentId }) => assessmentId && !assessment,
    mapAssessmentIdToAssessment,
  ),
)(AssessmentListItem);

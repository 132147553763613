import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import TagCheckboxList from 'modules/tags/components/TagCheckboxList';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

const CandidacyTagSelectDropdownButton = ({
  componentId,
  onChange,
  searchId,
  selectedTagIds,
  setSelectedTagIds,
}) => (
  <OverlayTrigger
    overlay={<Tooltip id={`${componentId}-tooltip`}>Filter by Tags</Tooltip>}
    placement='top'
  >
    <Dropdown className='CandidacyTagSelectDropdown' id={componentId}>
      <Dropdown.Toggle
        className='CandidacyTagSelectDropdown__toggle'
        data-testid='filter-button'
        noCaret={true}
      >
        <i className='fa fa-tags' />
      </Dropdown.Toggle>
      <Dropdown.Menu className='CandidacyTagSelectDropdown__menu'>
        <li className='dropdown-header' key='header'>
          Filter By Tags
        </li>
        <li className='divider' key='seperator' role='separator' />
        <TagCheckboxList
          className='CandidacyTagSelectDropdown_list'
          filterPlaceholder='Filter by Tag Label'
          onCheckboxChange={onChange}
          searchId={searchId}
          selectedTagIds={selectedTagIds}
          setSelectedTagIds={setSelectedTagIds}
        />
      </Dropdown.Menu>
    </Dropdown>
  </OverlayTrigger>
);

CandidacyTagSelectDropdownButton.propTypes = {
  componentId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  searchId: PropTypes.number.isRequired,
  selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedTagIds: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CandidacyTagSelectDropdownButton(enhanced)'),
  setPropTypes({
    selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    setSelectedTagIds: PropTypes.func.isRequired,
  }),
  withComponentId(),
)(CandidacyTagSelectDropdownButton);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';

import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import { PARENT_TYPES, PARENT_SEARCH } from '../constants';
import withOutreachListFetched from './withOutreachListFetched';
import mapOutreachIdsToOutreaches from './mapOutreachIdsToOutreaches';
import OutreachList, {
  defaultProps as OutreachListDefaultProps,
  propTypes as OutreachListPropTypes,
} from './OutreachList';

const OutreachesPanel = ({
  candidacyId,
  outreachList,
  outreaches,
  parentId,
  parentType,
  readOnly,
  renderDates,
  renderDescriptions,
  renderRelatedSearches,
  renderTitlesAsLinks,
  title,
}) => (
  <div className='outreaches--outreaches-panel'>
    {title && <h2>{title}</h2>}
    {outreaches && (
      <OutreachList
        candidacyId={candidacyId}
        outreaches={outreaches}
        readOnly={readOnly}
        renderDates={renderDates}
        renderDescriptions={renderDescriptions}
        renderRelatedSearches={renderRelatedSearches}
        renderTitlesAsLinks={renderTitlesAsLinks}
        searchId={parentType === PARENT_SEARCH ? parentId : null}
      />
    )}
    {outreachList && outreachList.getIn(['_meta', 'isFetching']) && (
      <LoadingIndicator />
    )}
  </div>
);

export const propTypes = {
  candidacyId: PropTypes.number,
  outreaches: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),
  ),
  outreachList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),
  parentId: PropTypes.number.isRequired,
  parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,
  readOnly: OutreachListPropTypes.readOnly,
  renderDates: OutreachListPropTypes.renderDates,
  renderDescriptions: OutreachListPropTypes.renderDescriptions,
  renderRelatedSearches: OutreachListPropTypes.renderRelatedSearches,
  renderTitlesAsLinks: OutreachListPropTypes.renderTitlesAsLinks,
  title: PropTypes.node,
};

export const defaultProps = {
  readOnly: OutreachListDefaultProps.readOnly,
  renderDates: OutreachListDefaultProps.renderDates,
  renderDescriptions: OutreachListDefaultProps.renderDescriptions,
  renderRelatedSearches: OutreachListDefaultProps.renderRelatedSearches,
  renderTitlesAsLinks: OutreachListDefaultProps.renderTitlesAsLinks,
};

OutreachesPanel.propTypes = propTypes;
OutreachesPanel.defaultProps = defaultProps;

export default compose(
  setDisplayName('OutreachesPanel(enhanced)'),
  // The connected version of this component requires a parentId and parentType,
  // everything else will be fetched from state.
  setPropTypes({
    parentId: PropTypes.number.isRequired,
    parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,
  }),

  // If the outreaches for the candidacy have not been loaded or are stale, fetch them.
  withOutreachListFetched,

  // Add a prop so we can manually trigger refreshes if desired.
  withProps(({ fetchOutreachList, outreachList }) => ({
    outreachIds: outreachList && outreachList.get('ids'),
    handleRefreshOutreaches: fetchOutreachList,
  })),

  mapOutreachIdsToOutreaches,
)(OutreachesPanel);

import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import { RESIDENCY_TYPE_CONSTANTS as residencyTypeConstants } from '../constants';

const createFieldState = (
  name = 'residency',
  residencyType = residencyTypeConstants.API_PUBLIC,
  residency,
) =>
  FieldState.createNested(name, [
    InputField.createFieldState('name', residency ? residency.get('name') : ''),
    InputField.createFieldState('is_access_token', 'true'),
    InputField.createFieldState('type', residencyType),
  ]);

/**
 * A ResidencyField currency consists of only a single InputField, which uses it's single
 * nested field state.
 */
export default compose(
  setDisplayName('ResidencyField'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
    residencyType: PropTypes.oneOf(Object.values(residencyTypeConstants)),
  }),
  setStatic('createFieldState', createFieldState),
  withNestedFieldChangeHandler,
  mapProps(({ fieldState, handleNestedFieldChange }) => ({
    fieldState: fieldState.getNestedField('name'),
    label: 'Token Name',
    onChange: handleNestedFieldChange,
    placeholder: 'Give your token a descriptive name',
  })),
)(InputField);

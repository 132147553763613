import PropTypes from 'prop-types';

const offerShape = {
  accepted_date: PropTypes.string,
  candidacy: PropTypes.shape({
    avatar_url: PropTypes.string,
    created_at: PropTypes.string,
    full_name: PropTypes.string,
  }),
  compensation: PropTypes.shape({
    base: PropTypes.number,
    bonus: PropTypes.number,
    bonus_type: PropTypes.string,
    currency: PropTypes.string,
    equity: PropTypes.number,
    initial_equity_shares: PropTypes.string,
    relocation_bonus: PropTypes.string,
    signing_bonus: PropTypes.string,
    total_compensation: PropTypes.string,
  }),
  id: PropTypes.number.isRequired,
  is_interim: PropTypes.bool,
  lasted_one_year: PropTypes.bool,
  notes: PropTypes.string,
  start_date: PropTypes.string,
  status: PropTypes.string,
  terminated: PropTypes.bool,
  terminated_date: PropTypes.string,
  terminated_notes: PropTypes.string,
};

export default offerShape;

import { connect } from 'react-redux';
import getFieldsLastUpdated from '../selectors/contacts/getFieldsLastUpdated';

/**
 * A higher order component which maps a `contactId` prop value to the contact's
 * timestamps for fields last updated. It returns most recent date for each
 * field group.
 */

export default connect((state, { contactId }) => {
  const fieldsLastUpdated = getFieldsLastUpdated(state, contactId);
  const updates = fieldsLastUpdated ? fieldsLastUpdated.toJS() : {};

  const fieldGroupsLastUpdated = {
    gdpr: updates.gdpr_last_update,
    casl: updates.casl_last_update,
    careerHighlights: updates.career_highlights_last_update,
    desiredCompensation: updates.preferred_compensation_last_update || null,
    currentCompensation: updates.employee_compensation
      ? updates.employee_compensation.last_update
      : null,
    boardMemberCompensation: updates.board_member_compensation
      ? updates.board_member_compensation.last_update
      : null,
    diversity: updates.diversity_last_update,
  };

  return { fieldGroupsLastUpdated: fieldGroupsLastUpdated };
});

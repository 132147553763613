import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

/**
 * A higher-order component that handles marking notifications as read before
 * performing "actions". An action is something that happens when interacting
 * with a notification -- by default, simply clicking the notifications
 * title and following a link is the primary action.
 */
const notification = ComposedComponent => {
  class NotificationHandlerComponent extends Component {
    constructor(props) {
      super(props);
      this.handlePrimaryAction = this.handlePrimaryAction.bind(this);
      this.state = {
        pendingMarkRead: false,
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { notification: notificationProp } = this.props;
      const { pendingMarkRead } = this.state;
      if (
        pendingMarkRead &&
        notificationProp.getIn(['meta', 'isUpdating']) &&
        !nextProps.notification.getIn(['meta', 'isUpdating'])
      ) {
        this.doAction(pendingMarkRead);

        // If we had previously been waiting for a "mark as read" operation to complete,
        // then we can continue with our primary action.
        this.setState({
          pendingMarkRead: false,
        });
      }
    }

    handlePrimaryAction(event) {
      const {
        notification: notificationProp,
        onUpdateRead,
        primaryAction,
      } = this.props;

      if (!notificationProp.getIn(['data', 'unread'])) {
        return false;
      }

      if (onUpdateRead && notificationProp.getIn(['data', 'unread'])) {
        // When the notification is unread and the link is clicked, we want to first
        // mark the item as read before continuing. In order to do that, we
        // stop the navigation for now and set `pendingMarkRead` to true in our state.
        // Then when our component recieves new props, we can check to see if our
        // asynchronous "mark as read" functionality has completed, and when it has,
        // then we manually set `window.location.href`.
        event.preventDefault();

        this.setState({
          pendingMarkRead:
            primaryAction || notificationProp.getIn(['data', 'links', 'self']),
        });

        return onUpdateRead(
          notificationProp,
          notificationProp.getIn(['data', 'unread']),
        );
      }

      return this.doAction(
        primaryAction || notificationProp.getIn(['data', 'links', 'self']),
      );
    }

    doAction(action) {
      const { notification: notificationProp } = this.props;

      if (typeof action === 'function') {
        action(notificationProp);
      } else {
        window.location.href =
          action || notificationProp.getIn(['data', 'links', 'self']);
      }
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          onPrimaryAction={this.handlePrimaryAction}
        />
      );
    }
  }

  NotificationHandlerComponent.propTypes = {
    notification: ImmutablePropTypes.mapContains({
      data: ImmutablePropTypes.mapContains({
        resource: PropTypes.shape({
          links: PropTypes.shape({
            self: PropTypes.string,
          }),
        }),
        unread: PropTypes.bool,
      }),
      meta: ImmutablePropTypes.mapContains({
        isUpdating: PropTypes.bool,
      }),
    }).isRequired,

    onUpdateRead: PropTypes.func,

    /**
     * The primary action that is taken when the notification's title is clicked.
     * This may be a string, in which case it should be a URL that will be
     * navigated to when the title is clicked.
     * Or it may be a function, which will be called when the title is clicked.
     * If not provided, the URL provided by `notification.resource.links.self`
     * will be used.
     */
    primaryAction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  };

  return NotificationHandlerComponent;
};

export default notification;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastWarning } from 'modules/toast-notifications/toastNotificationsSlice';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import Form from '@thrivetrm/ui/components/Form';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import { dateToISODateString } from '@thrivetrm/ui/utilities/dateTimeUtils';
import {
  useGetOffLimitsReasonsQuery,
  useGetOffLimitsReasonsTypesQuery,
  useUpdateOffLimitsCustomReasonMutation,
  useGetOffLimitsHistoricalReasonsQuery,
} from 'services/apiV1/offLimits';
import QuickViewPanelHeader from '../QuickViewPanelHeader';
import useQuickView from '../useQuickView';
import { QuickViewErrorState } from '../QuickViewState';
import ContactQvpHeader from '../ContactQvpHeader';

const OffLimitsEditForm = () => {
  const dispatch = useDispatch();
  const candidacyId = window.location.pathname.includes('searches')
    ? window.location.pathname.split('/')[4]
    : null;
  const { close, navigateTo, pathParams, title } = useQuickView();
  const { reasonId, recordId, recordType } = pathParams;
  const isCompany = recordType === 'company';
  const [currentReason, setCurrentReason] = useState(null);

  const {
    data: customReasonTypes,
    error: customReasonTypeError,
    isLoading: isLoadingCustomReasonTypes,
  } = useGetOffLimitsReasonsTypesQuery(recordType);

  const {
    data: historicalReasons,
    error: loadHistoricalReasonsError,
    isLoading: isLoadingHistoricalReasons,
  } = useGetOffLimitsHistoricalReasonsQuery({
    recordId: recordId,
    recordType: recordType,
  });

  const {
    data: reasons,
    error: loadReasonsError,
    isLoading: isLoadingReasons,
  } = useGetOffLimitsReasonsQuery({
    recordId: recordId,
    recordType: recordType,
  });

  const [
    updateOffLimitsCustomReason,
    {
      error: hasUpdateOffLimitsCustomReasonError,
      isLoading: isUpdateOffLimitsCustomReasonLoading,
    },
  ] = useUpdateOffLimitsCustomReasonMutation();

  const hasError =
    hasUpdateOffLimitsCustomReasonError ||
    customReasonTypeError ||
    loadHistoricalReasonsError ||
    loadReasonsError;

  useEffect(() => {
    const offLimitReason = [
      ...(historicalReasons || []),
      ...(reasons || []),
    ]?.find(reason => reason.id === reasonId);

    const selectedType = customReasonTypes?.find(
      type => type.name === offLimitReason?.reasonName,
    );

    const localizedStartDate = offLimitReason?.startDate
      ? dateToISODateString(new Date(offLimitReason.startDate))
      : null;

    setCurrentReason({
      offLimitReason: selectedType?.id,
      notes: offLimitReason?.notes || '',
      endDate: offLimitReason?.endDate || null,
      startDate: localizedStartDate,
    });
  }, [customReasonTypes, reasons, historicalReasons]);

  const appliedCustomOfflimitReasons = reasons
    ?.filter(({ id, isCustom }) => isCustom && id !== reasonId)
    .map(({ reasonName }) => reasonName);

  const navigateBack = () => {
    navigateTo(`/${recordType}/${recordId}/off-limits`);
  };

  const handleSubmit = formState => {
    updateOffLimitsCustomReason({
      reasonData: {
        end_date: formState.endDate,
        notes: formState.notes,
        off_limits_reason_id: formState.offLimitReason,
        start_date: formState.startDate,
      },
      reasonId: reasonId,
      recordId: recordId,
      recordType: recordType,
      candidacyId: candidacyId,
    })
      .unwrap()
      .then(() => {
        navigateBack();
      })
      .catch(() => {
        dispatch(toastWarning('An error has occurred!'));
      });
  };

  return (
    <>
      {isCompany ? (
        <SidePanel.Header
          className='u-marginBottom-12'
          onBack={navigateBack}
          onClose={close}
          title={title}
        />
      ) : (
        <ContactQvpHeader contactId={recordId} onBack={navigateBack} />
      )}
      <QuickViewPanelHeader title='Edit Off Limits Reason' />
      <Form onSubmit={handleSubmit}>
        <SidePanel.Body className='u-paddingTop-n'>
          <LoadingContainer
            className='u-paddingTop-24'
            isLoading={
              isLoadingCustomReasonTypes ||
              isLoadingHistoricalReasons ||
              isLoadingReasons
            }
          >
            {hasError ? (
              <QuickViewErrorState />
            ) : (
              <>
                <p className='u-fontSize-small'>
                  Select a Custom Reason from the drop-down menu below. If you
                  donʼt see the reason you are looking for reach out to your
                  Admin.
                </p>
                <Form.SelectMenu
                  initialValue={currentReason?.offLimitReason}
                  inputWidth='full'
                  label='Off Limits Reason'
                  menuPanelClassName='OffLimitsCard__menuPanel'
                  name='offLimitReason'
                  rules={{
                    required: {
                      value: true,
                    },
                  }}
                >
                  {customReasonTypes?.map(({ id, name }) =>
                    appliedCustomOfflimitReasons?.includes(name) ? (
                      <Tooltip
                        className='u-width-100'
                        content={
                          isCompany
                            ? 'This company currently has this active off limits reason'
                            : 'This contact currently has this active off limits reason'
                        }
                        key={id}
                        position='top'
                        size='large'
                      >
                        <SelectMenu.Item isDisabled={true} value={id}>
                          {name}
                        </SelectMenu.Item>
                      </Tooltip>
                    ) : (
                      <SelectMenu.Item key={id} value={id}>
                        {name}
                      </SelectMenu.Item>
                    ),
                  )}
                </Form.SelectMenu>
                <div className='u-flex u-marginVertical-16'>
                  <Form.DateInput
                    className='u-marginRight-8'
                    initialValue={currentReason?.startDate}
                    inputWidth='full'
                    isDisabled={true}
                    label='Applied On'
                    name='startDate'
                  />
                  <Form.DateInput
                    className='u-marginLeft-8'
                    initialValue={currentReason?.endDate}
                    inputWidth='full'
                    isPinnedRight={true}
                    label='Expires On'
                    minDate={currentReason?.startDate}
                    name='endDate'
                    placeholderText='Select a date'
                  />
                </div>
                <Form.TextArea
                  initialValue={currentReason?.notes}
                  label='Notes'
                  name='notes'
                  width='full'
                />
              </>
            )}
          </LoadingContainer>
        </SidePanel.Body>
        <SidePanel.Footer>
          <ButtonPrimary
            isOutline={true}
            label='Cancel'
            onClick={navigateBack}
          />
          <Form.SubmitButton
            isLoading={isUpdateOffLimitsCustomReasonLoading}
            label='Submit'
          />
        </SidePanel.Footer>
      </Form>
    </>
  );
};

export default OffLimitsEditForm;

import { compose, setDisplayName, withHandlers } from 'recompose';
import selectn from 'selectn';
import { stringifyQueryObject } from 'modules/core/urlUtils';
import connectContactReviewActions from 'modules/contact-reviews/components/connectContactReviewActions';
import withTransaction from 'modules/transactions/components/withTransaction';

// A higher order component that provides all the necessary parts to convert
// a job application into a contact review.
//
// It connects contact review actions, provides a transaction (needed to show
// the spinner and take action when the request has finished -- either error
// handling or redirecting to the contact review show page), and creates the
// handler function that creates the contact review.

const withContactReviewCreation = WrappedComponent =>
  compose(
    setDisplayName('withContactReviewCreation'),
    withHandlers({
      onTransactionComplete: ({ onClose }) => transaction => {
        if (transaction.payload.error) {
          // handle error - show message?
          if (onClose) {
            onClose();
          }
        } else {
          const reviewId = selectn('payload.data.id', transaction);
          const params = { redirectTo: window.location.href };
          window.location.href = `/contacts/pending/${reviewId}?${stringifyQueryObject(
            params,
          )}`;
        }
      },
    }),
    withTransaction,
    connectContactReviewActions,
    withHandlers({
      handleCreateContactReview: ({
        contactReviewActions,
        jobApplicationId,
        searchId,
        startTransaction,
      }) => () => {
        const transactionId = startTransaction();
        contactReviewActions.createContactReview({
          search: { id: searchId },
          jobApplicationId: jobApplicationId,
          transactionId: transactionId,
        });
      },
    }),
  )(WrappedComponent);

export default withContactReviewCreation;

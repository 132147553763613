import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';

import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import transactionsState from 'modules/transactions/propTypes/transactionsState';

import QueryMultiSelect from '../../../components/forms/multiselect/QueryMultiSelect';

import ContactCreateModalButton from './ContactCreateModalButton';
import connectContactActions from './connectContactActions';
import getNameWithEmail from '../selectors/contacts/getNameWithEmail';
import getQueriesById from '../selectors/contacts/getQueriesById';
import shouldFetchContact from '../selectors/contacts/shouldFetchContact';
import isFetchingContact from '../selectors/contacts/isFetchingContact';

/**
 * A field for selecting multiple contacts.
 */
export const ContactMultiSelect = ({
  allowCreate,
  contactActions,
  disabled,
  onCreated,
  optionsFromData,
  queriesById,
  transactionActions,
  transactions,
  ...props
}) => (
  <div
    className={classnames('ContactMultiSelect', {
      'ContactMultiSelect--is-creatable': allowCreate,
    })}
  >
    <QueryMultiSelect
      isLoading={true}
      noValueText='No Contacts Selected'
      placeholder='No Contacts Selected'
      summaryLabel='{0} Contacts Selected'
      {...props}
      clearTransaction={transactionActions.clearTransaction}
      disabled={disabled}
      onCreate={contactActions.createContactsQuery}
      onDestroy={contactActions.destroyContactsQuery}
      onQuery={contactActions.fetchContactsQuery}
      optionsFromData={optionsFromData}
      queriesById={queriesById}
      transactions={transactions}
    />
    {allowCreate && !disabled && (
      <ContactCreateModalButton onCreated={onCreated}>
        <TooltipTrigger tooltip='Create New Contact'>
          <i className='fa fa-user-plus' />
        </TooltipTrigger>
      </ContactCreateModalButton>
    )}
  </div>
);
ContactMultiSelect.propTypes = {
  /**
   * Whether to show a button allowing new contacts to be created from the selector.
   */
  allowCreate: PropTypes.bool,

  contactActions: PropTypes.shape({
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    fetchContactsQuery: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * True to disable the input and prevent it from being changed.
   */
  disabled: PropTypes.bool,

  /**
   * Called when a new contact is created from the component. Called with the ID of the newly
   * created contact.
   */
  onCreated: PropTypes.func.isRequired,

  /**
   * A function that converts the data from `queriesById` (which is just an array of search IDs)
   * into the options that can be passed into the MultiSelect for display (something with an `id`
   * and `name` property)
   */
  optionsFromData: PropTypes.func.isRequired,

  /**
   * The state of all contact queries keyed by their unique ID.
   */
  queriesById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.map,
    PropTypes.string,
  ).isRequired,

  /**
   * The dispatch-bound transaction actions.
   */
  transactionActions: PropTypes.shape({
    clearTransaction: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * THe state of all transactions.
   */
  transactions: transactionsState.isRequired,
};

ContactMultiSelect.defaultProps = {
  allowCreate: false,
  disabled: false,
};

export default compose(
  setDisplayName('ContactMultiSelect(enhanced)'),
  setPropTypes({
    allowCreate: PropTypes.bool,

    value: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          id: PropTypes.number,
        }),
      ]),
    ),
  }),
  connectContactActions,
  connectTransactions,
  connect((state, { value }) => {
    // Make sure the value being passed into the control is an array of objects with `{ id, name }`
    // and not just an array of IDs (which it likely is initially, though as things get selected
    // and deselected, it may be a mix of IDs and objects value) -- without the id/name pair
    // the select control won't know how to render the item's name.
    const valueObjects = value
      ? value.map(id =>
          typeof id === 'number'
            ? {
                id: id,
                name: getNameWithEmail(state, id, 'No Email') || '...',
              }
            : {
                // Even if an object is passed in, still look up the name because it may be "..." if we
                // hadn't previously loaded it.
                id: id.id,
                name: getNameWithEmail(state, id.id, 'No Email') || '...',
              },
        )
      : [];

    return {
      queriesById: getQueriesById(state),

      // When performing a search, takes the array of IDs that result and converts them into
      // an "option" object with an `id` and `name` property.
      optionsFromData: data =>
        data
          ? data
              .map(id => ({
                id: id,
                name: getNameWithEmail(state, id, 'No Email') || '',
              }))
              .toJS()
          : [],

      // If _any_ of the selected contacts is being fetched, then set isLoading to true so we show
      // the spinner.
      isLoading: valueObjects.some(({ id }) => isFetchingContact(state, id)),

      // Determine if we need to fetch the record for any of our preselected contacts.
      // For example, we may have an array of IDs [1, 3, 53, 642] and any or all of them may not
      // having underlying contact record data in state (and thus, we have no name to display).
      contactIdsToFetch: valueObjects
        .filter(({ id }) => shouldFetchContact(state, id))
        .map(({ id }) => id),

      value: valueObjects,
    };
  }, {}),
  lifecycle({
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      // Fetch any contacts we need a name for.
      nextProps.contactIdsToFetch.forEach(id =>
        nextProps.contactActions.fetchContact({ id: id }),
      );
    },
  }),
  withHandlers({
    // When a new contact is created, onCreated gets called with the ID. Add this to our value
    // array and call onChange with the updated array.
    onCreated: ({ onChange, value }) => contactId =>
      onChange([contactId].concat(value)),
  }),
)(ContactMultiSelect);

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  mapProps,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import asFormGroupField from 'modules/forms/components/asFormGroupField';
import FieldState from 'modules/forms/FieldState';
import * as validators from 'modules/forms/validators';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import ensureTenantOptionsFetched from 'modules/tenant/components/ensureTenantOptionsFetched';

import { formatCurrency } from '@thrivetrm/ui/utilities/numberUtils';
import getCurrencySymbol from '../selectors/getCurrencySymbol';

/**
 * Parses an input string and converts it to a floating point value.
 * This is actually not a great way to do this because it is region specific
 * (not all regions use commas to separate thousands places)
 * @param {String|*} value The string to convert
 * @returns {Number|*} If the input value was a string, the input parsed as
 *   a number; otherwise the original input is returned.
 */
const convertStringToFloat = value => {
  if (typeof value === 'string') {
    return value.trim().length ? parseFloat(value.replace(/,/g, '')) : null;
  }

  return value;
};

/**
 * A component that displays an input for entering currency values.
 * This is also not a great way to display currency because we are always
 * showing the currency symbol to the left, which is region-specific.
 */
export const CurrencyInput = ({
  currencyLabel,
  isRequired,
  type,
  ...props
}) => (
  <div className='CurrencyInput input-group'>
    <span className='input-group-addon'>{currencyLabel}</span>
    <input
      className='form-control'
      required={isRequired}
      type={type || 'text'}
      {...props}
    />
  </div>
);

CurrencyInput.propTypes = {
  /**
   * The label to display to the left of the currency value.
   */
  currencyLabel: PropTypes.string,

  isRequired: PropTypes.bool,

  /**
   * The type prop to set on the underlying input.
   */
  type: PropTypes.string,
};

CurrencyInput.defaultProps = {
  currencyLabel: '$',
  isRequired: false,
  // use 'number'?
  type: 'text',
};

/**
 * Creates a FieldState that can be used with a CurrencyInputField.
 * @param {String} name The name of the field.
 * @param {Number} value The value to initialize the field with.
 * @param {Object} options
 * @param {Number} [options.minValue] If set, a minimum value validator will be added.
 * @param {Number} [options.float] If true, the value will be validated as a floating point
 *   number; otherwise the value will be validated as an integer (whole number) only.
 * @param {Number} [options.required] If set, a validator will be added requiring a
 *   value to be set.
 */
CurrencyInput.createFieldState = (
  name,
  value,
  { float, minValue, required } = {},
) => {
  const fieldValidators = [];

  if (typeof minValue === 'number') {
    fieldValidators.push(validators.greaterThanOrEqualTo(minValue));
  }

  if (float) {
    fieldValidators.push(validators.isNumber);
  } else {
    fieldValidators.push(validators.isInteger);
  }

  if (required) {
    fieldValidators.push(validators.requiredField(required || name));
  }

  return FieldState.create(
    name,
    formatCurrency(value, undefined, null, ''),
    validators.combineValidators(...fieldValidators),
    convertStringToFloat,
  );
};

export default compose(
  setDisplayName('CurrencyInputField'),
  setStatic('createFieldState', CurrencyInput.createFieldState),
  setPropTypes({
    /**
     * A FieldState created from CurrencyInputField.createFieldState
     */
    fieldState: fieldStatePropType.isRequired,

    /**
     * Called when the field's value is changed.
     */
    onChange: PropTypes.func.isRequired,
  }),
  asFormGroupField,
  ensureTenantOptionsFetched,
  connect(
    (state, { currency }) => ({
      currencyLabel: getCurrencySymbol(state, currency),
    }),
    {},
  ),
  mapProps(
    ({
      currency: _currency,
      fetchTenantOptions: _fetchTenantOptions,
      hasLoadedTenantOptions: _hasLoadedTenantOptions,
      isFetchingTenantOptions: _isFetchingTenantOptions,
      shouldFetchTenantOptions: _shouldFetchTenantOptions,
      ...props
    }) => props,
  ),
  withHandlers({
    onChange: ({ onChange }) => e => onChange(e.currentTarget.value),
  }),
)(CurrencyInput);

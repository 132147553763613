import selectn from 'selectn';
import invalidateCandidacyStatus from 'modules/candidacies/actions/invalidateCandidacyStatus';
import updateEntity from 'modules/entities/actions/updateEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import { fetchContact } from 'modules/contacts/actions';
import referenceSchema from '../schema';

/**
 * Creates an action for updating a reference
 * @param {Object} payload
 * @param {Object} payload.reference The reference properties
 * @param {Object} payload.reference.id The reference ID
 * @param {Object} payload.reference.search The search ID the reference is associated with
 */
export default ({
  reference: { referee: refereeId, search, ...referenceProps },
  ...rest
}) => dispatch =>
  dispatch(
    updateEntity({
      ...rest,
      body: {
        // This renames `search` to `search_id` before submitting to the server.
        reference: {
          ...referenceProps,
          search_id: search,
          referee_id: refereeId,
        },
      },
      entityType: referenceSchema.key,
      id: referenceProps.id,
      responseSchema: { reference: referenceSchema },
      url: routes.api_v1_reference({ id: referenceProps.id }),
    }),
  ).then(action => {
    if (action.type === entityActionTypes.UPDATE_SUCCESS) {
      const id = selectn('payload.result.reference', action);
      const { key } = referenceSchema;
      const candidacyId = selectn(
        `payload.entities.${key}.${id}.candidacy_id`,
        action,
      );
      const contactId = selectn(
        `payload.entities.${key}.${id}.contact_id`,
        action,
      );
      if (candidacyId) {
        dispatch(invalidateCandidacyStatus({ id: candidacyId }));
      }
      if (contactId) {
        dispatch(fetchContact({ id: contactId }));
      }
    }

    return action;
  });

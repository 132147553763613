import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonDestroy from '@thrivetrm/ui/components/ButtonDestroy';
import Modal from '@thrivetrm/ui/components/Modal';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import PluralText from '@thrivetrm/ui/components/PluralText';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_PIPELINE, SEARCH_TYPE_LABELS } from 'modules/searches/constants';
import {
  reset,
  processNextSelectedCandidacy,
  selectHasStartedBulkUpdate,
  selectIsBulkUpdateComplete,
} from './candidacyBulkActionsSlice';

const BulkRemoveFromSearchButton = ({ searchId }) => {
  const dispatch = useDispatch();
  const [isDialogOpen, openDialog, closeDialog] = useToggle(false);
  const searchType = useSelector(state => getSearchType(state, searchId));
  const isPipeline = searchType === TYPE_PIPELINE;

  const { isUpdateInProgress, selectedCandidacyIds } = useSelector(
    state => state.candidacyBulkActions,
  );

  const hasStartedRemovingCandidates = useSelector(selectHasStartedBulkUpdate);
  const hasCompletedRemovingCandidates = useSelector(
    selectIsBulkUpdateComplete,
  );

  const handleClose = () => {
    dispatch(reset());
    closeDialog();
  };

  const handleSubmit = () => {
    if (!hasStartedRemovingCandidates) {
      dispatch(processNextSelectedCandidacy());
    } else if (hasCompletedRemovingCandidates) {
      handleClose();
    }
  };

  return (
    <>
      <ButtonDestroy
        label={`Remove from ${SEARCH_TYPE_LABELS[searchType]}`}
        onClick={openDialog}
      />
      <Modal
        data-testid='ConfirmationDialog'
        isOpen={isDialogOpen}
        onClose={handleClose}
        role='dialog'
        size='small'
        title={
          isPipeline
            ? 'Remove People From Pipeline'
            : `Remove Candidates from ${SEARCH_TYPE_LABELS[searchType]}`
        }
      >
        <Modal.Body className='u-fontSize-large u-textAlign-c'>
          {!hasCompletedRemovingCandidates ? (
            <p className='u-textColor-gray60 u-marginTop-16'>
              Click <strong>Confirm</strong> to remove{' '}
              <strong>
                <PluralText
                  quantity={selectedCandidacyIds.length}
                  shouldIncludeQuantity={true}
                  text={isPipeline ? 'people' : 'candidates'}
                />
              </strong>
              .
            </p>
          ) : null}
          {hasCompletedRemovingCandidates ? (
            <p className='u-textColor-gray60'>
              Removing {isPipeline ? 'People' : 'Candidates'} Complete!
            </p>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary
            isDisabled={hasStartedRemovingCandidates}
            isOutline={true}
            label='Cancel'
            onClick={handleClose}
          />
          <ButtonPrimary
            isDisabled={isUpdateInProgress}
            isLoading={isUpdateInProgress}
            label={hasCompletedRemovingCandidates ? 'Close' : 'Confirm'}
            onClick={handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

BulkRemoveFromSearchButton.propTypes = {
  searchId: PropTypes.number.isRequired,
};

export default BulkRemoveFromSearchButton;

import selectn from 'selectn';
import createEntity from 'modules/entities/actions/createEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import noteSchema from '../schema';
import getNoteListUrl from './getNoteListUrl';
import maybeDispatchNotificationsCreated from '../../../actions/notifications/maybeDispatchNotificationsCreated';

/**
 * An action for creating an note
 * @param {Object} payload
 * @param {Object} payload.note The note record to create
 * @param {Number[]} [payload.searches] The ID of the searches related to the note
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({
  note: { searches, ...noteProps },
  transactionId,
}) => dispatch => {
  dispatch(
    createEntity({
      body: {
        note: {
          search_ids: searches,
          ...noteProps,
        },
      },
      entityType: noteSchema.key,
      responseSchema: { note: noteSchema },
      url: getNoteListUrl({
        parentType: noteProps.notable_type,
        parentId: noteProps.notable_id,
      }),
      parentType: noteProps.notable_type,
      parentId: noteProps.notable_id,
      transactionId: transactionId,
    }),
  ).then(action => {
    if (action.type === entityActionTypes.CREATE_SUCCESS) {
      // Ick. This is gross. Temporary measure until we normalize notifications.
      // This dispatches a second action but alters the payload in a way that
      // maybeDispatchNotificationsCreated expects.
      const noteId = selectn('payload.result.note', action);
      const note = selectn(
        `payload.entities.${noteSchema.key}.${noteId}`,
        action,
      );
      if (note) {
        dispatch(
          maybeDispatchNotificationsCreated(
            {
              type: action.type,
              payload: { data: { note: note } },
            },
            'note',
          ),
        );
      }
    }

    return action;
  });
};

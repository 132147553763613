import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import OffLimitsIndicator from 'modules/off-limits/OffLimitsIndicator';
import ContactAddressAs from 'modules/contacts/components/ContactAddressAs';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import ContactAvatarWithQuickView from './ContactAvatarWithQuickView';

const ContactSnapshot = ({
  addressAs,
  avatarUrl,
  className,
  company,
  connectionsCount,
  id,
  isOffLimitsDisabled,
  name,
  offLimitsReason,
  offLimitsSeverity,
  shouldRenderAsHeadings,
  shouldUseQuickView,
  title,
  url,
  ...props
}) => {
  const containerClass = classNames(className, 'u-flex u-flexAlign-c');
  const isFullAccessOrAdminUser = useSelector(state => isEmployeeUser(state));
  const isCrmUser = useSelector(isCrmUserSelector);

  return (
    <div {...props} className={containerClass} data-testid='ContactSnapshot'>
      <ContactAvatarWithQuickView
        avatarUrl={avatarUrl}
        connectionsCount={connectionsCount}
        contactId={id}
        contactName={name}
        shouldUseQuickView={shouldUseQuickView}
        size={shouldRenderAsHeadings ? 'medium' : 'small'}
      />
      <div className='u-marginLeft-16'>
        <div className='u-wordBreak'>
          {shouldRenderAsHeadings ? (
            <h2 className='u-margin-n u-textAlign-l'>
              {url ? (
                <a href={url}>{name}</a>
              ) : (
                <span className='u-textColor-gray60'>{name}</span>
              )}
            </h2>
          ) : (
            <b>
              {url ? (
                <a href={url}>{name}</a>
              ) : (
                <span className='u-textColor-gray60'>{name}</span>
              )}
            </b>
          )}
          <ContactAddressAs
            addressAs={addressAs}
            className='u-marginLeft-4 u-inlineBlock'
          />
        </div>
        {shouldRenderAsHeadings ? (
          <>
            {title ? <p className='u-margin-n u-textAlign-l'>{title}</p> : null}
            {company ? (
              <p className='u-margin-n u-textAlign-l'>
                {company?.id ? (
                  <a href={`/companies/${company.id}`}>{company.name}</a>
                ) : (
                  company?.name
                )}
              </p>
            ) : null}
          </>
        ) : (
          <h6 className='u-margin-n u-fontWeight-normal'>
            {title}
            {title && company?.name ? ', ' : ''}
            {company?.id ? (
              <a href={`/companies/${company.id}`}>{company.name}</a>
            ) : (
              company?.name
            )}
          </h6>
        )}
        {offLimitsReason && (isFullAccessOrAdminUser || isCrmUser) ? (
          <div>
            <OffLimitsIndicator
              isDisabled={isOffLimitsDisabled}
              reason={offLimitsReason}
              recordId={id}
              recordName={name}
              recordType='contact'
              severity={offLimitsSeverity}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

ContactSnapshot.defaultProps = {
  addressAs: null,
  avatarUrl: null,
  className: null,
  company: null,
  connectionsCount: 0,
  isOffLimitsDisabled: false,
  offLimitsReason: null,
  offLimitsSeverity: null,
  shouldRenderAsHeadings: false,
  shouldUseQuickView: false,
  title: null,
  url: null,
};

ContactSnapshot.propTypes = {
  addressAs: PropTypes.string,
  avatarUrl: PropTypes.string,
  className: PropTypes.string,
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  connectionsCount: PropTypes.number,
  id: PropTypes.number,
  isOffLimitsDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  offLimitsReason: PropTypes.string,
  offLimitsSeverity: PropTypes.string,
  shouldRenderAsHeadings: PropTypes.bool,
  shouldUseQuickView: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
};

export default ContactSnapshot;

import { compose, withHandlers } from 'recompose';
import { parseQueryString } from 'modules/core/urlUtils';
import selectn from 'selectn';
import connectRouting from 'modules/routing/components/connectRouting';
import routes from 'modules/routing/routes';
import { SEARCH_PAGE_BASE_PATHS } from 'modules/searches/constants';

/**
 * When a contact is updated from a contact review record:
 *
 * onSaved - redirect to the ContactPage
 * onCancel - redirect to the ContactPage
 */
const getRedirectUrl = string => {
  const query = parseQueryString(string);
  return query && query.redirectTo;
};

export const withContactRedirection = compose(
  connectRouting,
  withHandlers({
    onSaved: ({ routingActions }) => payload => {
      // If a redirect url was provided in query params, redirect there.
      const redirectUrl = getRedirectUrl(window.location.search);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else if (selectn('result.contact', payload)) {
        // No redirect url; go to the contact show page
        routingActions.push(routes.contact({ id: payload.result.contact }));
      }
    },

    onCancel: ({ match, routingActions }) => () => {
      // If a redirect url was provided in query params, redirect there.
      const redirectUrl = getRedirectUrl(window.location.search);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        // No redirect url; go to the contact show page
        routingActions.push(routes.contact({ id: match.params.contactId }));
      }
    },
  }),
);

/**
 * When a contact is created from a contact review record:
 *
 * onSaved - redirect to the ContactPage
 * onCancel - redirect to the list of contact reviews
 * onUpdate - redirect to the contact review form, but this time for an existing
 *  contact to update
 */
export const withReviewRedirection = compose(
  connectRouting,
  withHandlers({
    onSaved: props => payload => {
      const { contactReviews, match, routingActions } = props;

      const contactReviewId = parseInt(match.params.contactReviewId);
      const contactReview = contactReviews.getIn([
        'data',
        contactReviewId,
        'data',
      ]);
      const searchId = contactReview.getIn(['search', 'id']);
      const searchType = contactReview.getIn(['search', 'type']);
      const networkId = contactReview.getIn(['network', 'id']);

      // If a redirect url was provided in query params, easy peasy: redirect there.
      const redirectUrl = getRedirectUrl(window.location.search);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        // Otherwise we have to guess where to go.
        // If a contact review was initiated from within a search or network,
        // redirect back to the edit page of that search or network.
        if (networkId) {
          window.location.href = `/networks/${networkId}/edit`;
        }
        if (searchId) {
          window.location.href = `${SEARCH_PAGE_BASE_PATHS[searchType]}/${searchId}/candidates/edit`;
        } else {
          // Fall back to base case: redirect to the contact's show page.
          routingActions.push(routes.contact({ id: payload.contactId }));
        }
      }
    },

    onCancel: () => () => {
      // If a redirect url was provided in query params, easy peasy: redirect there.
      const redirectUrl = getRedirectUrl(window.location.search);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        window.location.href = '/contacts/pending';
      }
    },

    onUpdate: ({ match }) => contactId => {
      // If a redirect url was passed to us, pass it on as we forward the form.
      const query = window.location.search;

      window.location.href =
        routes.contact_contact_pending({
          contactId: contactId,
          id: match.params.contactReviewId,
        }) + query;
    },
  }),
);

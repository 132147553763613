import { connect } from 'react-redux';
import getCandidacyProperty from '../selectors/getCandidacyProperty';

/**
 * A higher order component which maps `candidacyId` to an underlying property value on the
 * candidacy record.
 * @param {String} propertyName The name of the candidacy property
 * @param {String} propName The prop name to assign the value to on the component.
 */
export default (propertyName, propName) =>
  connect(
    (state, { candidacyId }) => ({
      [propName]:
        candidacyId && getCandidacyProperty(state, candidacyId, propertyName),
    }),
    {},
  );

import React from 'react';
import PropTypes from 'prop-types';
import Date from '@thrivetrm/ui/components/Date';
import Grid from '@thrivetrm/ui/components/Grid';

const RecruitmentRatingExpandedDetails = ({ record }) => {
  const { createdAt, notes, updatedAt, updatedBy } = record;
  const { externalPrimaryRecruiter } = record.search;
  const ratedBy = record.ratedBy.name;
  const createdDate = <Date date={createdAt} />;
  const updatedDate = <Date date={updatedAt} />;

  const renderRatingDetail = (label, info) => (
    <div className='u-fontSize-small u-textColor-gray60 u-wordBreak'>
      <strong className='u-marginRight-2'>{label}</strong>
      {info}
    </div>
  );

  const recruiter = (
    <div className='u-marginBottom-8'>
      <strong>External Primary Recruiter:</strong>
      <span className='u-marginLeft-2'>{externalPrimaryRecruiter}</span>
    </div>
  );

  const details = (
    <>
      {externalPrimaryRecruiter?.length ? recruiter : null}
      <div className='u-marginBottom-16 u-textColor-gray60'>{notes}</div>
      <Grid>
        <Grid.Column className='u-padding-n' size={6}>
          {renderRatingDetail('Rated By: ', ratedBy)}
          {renderRatingDetail('Date Rated: ', createdDate)}
        </Grid.Column>
        <Grid.Column className='u-padding-n' size={6}>
          {renderRatingDetail('Updated By: ', updatedBy)}
          {renderRatingDetail('Date Updated: ', updatedDate)}
        </Grid.Column>
      </Grid>
    </>
  );

  return record && details;
};

export default RecruitmentRatingExpandedDetails;

RecruitmentRatingExpandedDetails.propTypes = {
  record: PropTypes.shape({
    createdAt: PropTypes.string,
    createdById: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    notes: PropTypes.string,
    ratedBy: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }),
    rating: PropTypes.number,
    search: PropTypes.shape({
      endDate: PropTypes.string,
      externalPrimaryRecruiter: PropTypes.string,
      id: PropTypes.number,
      milestone: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
  }),
};

import React from 'react';
import { Route } from 'react-router-dom';
import { mapProps } from 'recompose';
import CompanyIndexPage from './CompanyIndexPage';
import CompanyPage from './CompanyPage';

const parseParams = mapProps(({ match }) => ({
  companyId: parseInt(match.params.companyId),
}));

/**
 * The outer component that handles any `/companies` routes
 */
export default () => (
  <div>
    <Route
      component={parseParams(CompanyPage)}
      path='/companies/:companyId(\d+)'
    />
    <Route component={CompanyIndexPage} exact={true} path='/companies' />
  </div>
);

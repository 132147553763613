import PropTypes from 'prop-types';
import {
  branch,
  compose,
  renderComponent,
  setPropTypes,
  withProps,
} from 'recompose';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import mapSearchIdToSearch from 'modules/searches/components/mapSearchIdToSearch';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';
import withDraftStorage from '../../../components/forms/withDraftStorage';

import JobListingField from './JobListingField';
import connectJobListingActions from './connectJobListingActions';
import withJobListingFetched from './withJobListingFetched';

const createRootFieldState = props => {
  const { draft, jobListing, search } = props;

  if (jobListing && jobListing.get('id')) {
    const jobListingValue = jobListing.withMutations(immutableJobListing => {
      if (draft?.content) {
        immutableJobListing.set('description', draft?.content);
      }
    });
    return JobListingField.createFieldState('jobListing', {
      ...props,
      jobListing: jobListingValue,
    });
  } else {
    // When there is no jobListing.id, fallback to pre-populating the FieldState with the
    // attributes from the search record. so that here set draft content
    const searchValue = search.withMutations(immutableJobListing => {
      if (draft?.content) {
        immutableJobListing.set('description', draft?.content);
      }
    });
    return JobListingField.createFieldState('jobListing', {
      ...props,
      search: searchValue,
    });
  }
};

const handleSubmit = (formState, props) => {
  const transactionId = uniqueId();
  const values = formState.getFieldValue();
  const jobListingId = props.jobListing && props.jobListing.get('id');
  const { searchId } = props;

  if (jobListingId) {
    props.jobListingActions.updateJobListing({
      jobListingId: jobListingId,
      job_listing: { id: jobListingId, ...values },
      searchId: searchId,
      transactionId: transactionId,
    });
  } else {
    props.jobListingActions.createJobListing({
      job_listing: values,
      searchId: searchId,
      transactionId: transactionId,
    });
  }

  return formState.startSubmit(transactionId);
};

export const connectForm = compose(
  setPropTypes({
    searchId: PropTypes.number,
  }),
  mapSearchIdToSearch,
  connectTransactions,
  connectJobListingActions,
  withJobListingFetched,
);

export default compose(
  connectForm,

  // We need to make sure we don't prematurely show the form before we've actually loaded
  // the job listing record -- otherwise the FormState will get initialized with empty fields.
  // So this shows a loading indicator while we're loading the job listing.
  branch(
    ({ jobListing }) =>
      !jobListing || jobListing.getIn(['_meta', 'isFetching']),
    renderComponent(LoadingIndicator),
  ),
  branch(
    ({ search }) => !search || search.getIn(['_meta', 'isFetching']),
    renderComponent(LoadingIndicator),
  ),

  withProps({
    formActionProps: {
      // There is no "cancelling" of this form, currently. It's always open and always
      // displayed, so there's nothing to cancel out of. Setting this to null will prevent
      // the cancel button from rendering.
      onCancelClick: null,
    },
  }),
  withDraftStorage(),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'jobListing-form',
    scrollToError: true,
    showGenericErrorMessage: true,
    omitProps: [
      'searchId',
      'search',
      'transactionActions',
      'jobListingActions',
      'fetchJobListing',
      'fetchJobListingIfNeeded',
      'shouldFetchJobListing',
      'canSubmitWithErrors',
    ],
  }),
)(JobListingField);

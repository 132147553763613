import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import withTransactionTrackedForm from '../../forms/withTransactionTrackedForm';
import ResumeField from './ResumeField';

const createRootFieldState = () => ResumeField.createFieldState('file', null);

/**
 * When submitted, uploads the document, parses it, and assigns the parsed
 * values to a new contact record.
 */
const handleSubmit = (
  formState,
  { contactReviewActions, network, parser, search },
) => {
  const transactionId = uniqueId();

  contactReviewActions.createContactReviewsBulk({
    parser: parser,
    files: formState.getFieldValue(),
    search: search,
    network: network,
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export default withTransactionTrackedForm(
  createRootFieldState,
  handleSubmit,
  {},
)(ResumeField);

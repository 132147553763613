import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import CancelButton from 'modules/forms/components/CancelButton';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withContactForm from '../withContactForm';
import ContactUsVisaRequiredField from './ContactUsVisaRequiredField';

/**
 * A form for editing a contact's "is us visa required", which is a boolean field
 */
const ContactUsVisaStatusForm = ({
  formState,
  onCancel,
  onFieldStateChange,
  onSubmit,
  ...fieldProps
}) => (
  <Form
    className='ContactUsVisaRequiredForm'
    formState={formState}
    onSubmit={onSubmit}
  >
    <ContactUsVisaRequiredField
      {...fieldProps}
      fieldState={formState.getFieldState()}
      onChange={onFieldStateChange}
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]}
    />
    <FormErrorMessage formState={formState} />
    <div className='Form__footer'>
      <CancelButton onClick={onCancel} />
      <SubmitButton formState={formState} onClick={onSubmit} />
    </div>
  </Form>
);

ContactUsVisaStatusForm.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the cancel button is clicked.
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,
};

/**
 * The connected version of this component includes a FormState/FieldState and
 * handlers for submitting the form.
 */
export default compose(
  setDisplayName('ContactUsVisaStatusForm(enhanced))'),
  setPropTypes({
    /**
     * The ID of the contact for which the gdpr status will be edited.
     */
    contactId: PropTypes.number,

    /**
     * Called when the cancel button on the form is clicked.
     */
    onCancel: PropTypes.func.isRequired,

    /**
     * Called when the form has been successfully saved.
     */
    onSaved: PropTypes.func.isRequired,
  }),

  // Provides the field state, form state, and submission handling.
  withContactForm(ContactUsVisaRequiredField),

  /**
   * Prevent props from being passed on to the underlying fieldState
   */
  /* eslint-disable no-unused-vars */
  mapProps(
    ({ contactId, onFormStateChange, onResetFormState, onSaved, ...rest }) => ({
      ...rest,
    }),
  ),
  /* eslint-enable no-unused-vars */
)(ContactUsVisaStatusForm);

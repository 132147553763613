import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import mapContactIdToContact from '../mapContactIdToContact';
import ContactInternalNotesForm from './ContactInternalNotesForm';
import ContactInternalNotesPreview from './ContactInternalNotesPreview';

/**
 * Renders an EditableSection that displays and allows editing of a contact's "internal notes".
 * Initially the view mode shows a partial preview of the notes that can be expanded to show the
 * full notes
 */
export default compose(
  setDisplayName('ContactInternalNotesEditableSection'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  mapProps(({ contact, contactId }) => ({
    contactId: contactId,
    children: contact.get('preferred_notes') ? (
      <ContactInternalNotesPreview contactId={contactId} />
    ) : null,
    formComponent: ContactInternalNotesForm,
    title: 'Internal Notes',
  })),
)(EditableSection);

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import CompanyLogo from 'modules/companies/components/logo/CompanyLogo';
import CompanyName from 'modules/companies/components/CompanyName';
import DatasetView from 'modules/core/componentsLegacy/DatasetView';
import withInvestmentListFetched from './withInvestmentListFetched';
import withInvestmentIdsSortedByDate from './withInvestmentIdsSortedByDate';
import withInvestmentIdsGroupedByVenture from './withInvestmentIdsGroupedByVenture';
import InvestmentAmount from './InvestmentAmount';
import InvestmentDate from './InvestmentDate';
import InvestmentRoundStageName from './InvestmentRoundStageName';
import InvestmentInvestorList from './InvestmentInvestorList';

/* Exception: eslint incorrectly identifying immutable map as an array */
/* eslint-disable react/no-array-index-key */

/**
 * A grouped table that displays a list of investments, grouped by venture company.
 */
const InvestmentsTableView = ({
  companyId,
  investmentIdGroups,
  isFetching,
}) => (
  <DatasetView
    className='InvestmentsTableView'
    emptyState={
      !isFetching &&
      (!investmentIdGroups || investmentIdGroups.count() === 0) && {
        message: 'No investments found',
      }
    }
    hasData={Boolean(investmentIdGroups && investmentIdGroups.count() > 0)}
    loadingIndicator={isFetching}
  >
    <table className='InvestmentsTableView__table table table-basic'>
      <thead />
      <tbody>
        {investmentIdGroups &&
          investmentIdGroups
            .map((investmentIds, groupId) => [
              <tr
                className='InvestmentsTableView__row InvestmentsTableView__row--company'
                key={groupId}
              >
                <td
                  className='InvestmentsTableView__cell--company-logo'
                  key='logo'
                  rowSpan={investmentIds.count() + 1}
                >
                  <CompanyLogo companyId={groupId} thumbnail={true} />
                </td>
                <td
                  className='InvestmentsTableView__cell--company-name'
                  colSpan={4}
                  key='name'
                >
                  <CompanyName companyId={groupId} linkToCompany={true} />
                </td>
              </tr>,
              investmentIds.map(investmentId => (
                <tr
                  className='InvestmentsTableView__row InvestmentsTableView__row--investment'
                  key={`${groupId}-${investmentId}`}
                >
                  <td key='date'>
                    <InvestmentDate investmentId={investmentId} />
                  </td>
                  <td
                    className='InvestmentsTableView__cell--amount'
                    key='amount'
                  >
                    <InvestmentAmount investmentId={investmentId} />
                  </td>
                  <td key='stage'>
                    <InvestmentRoundStageName investmentId={investmentId} />
                  </td>
                  <td key='investors'>
                    <InvestmentInvestorList
                      excludedCompanyId={companyId}
                      investmentId={investmentId}
                      label={
                        <div className='InvestmentsTableView__cell--investors__label'>
                          Other Investors
                        </div>
                      }
                    />
                  </td>
                </tr>
              )),
            ])
            .toArray()}
      </tbody>
    </table>
  </DatasetView>
);

InvestmentsTableView.propTypes = {
  /**
   * The ID of the company that we are displaying the list of investments for.
   */
  companyId: PropTypes.number.isRequired,

  /**
   * The list of investments grouped by the company ID
   */
  investmentIdGroups: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.listOf(PropTypes.number.isRequired).isRequired,
    PropTypes.number.isRequired,
  ),

  /**
   * True if currently fetching the investments.
   */
  isFetching: PropTypes.bool,
};

export default compose(
  setDisplayName('InvestmentsTableView(enhanced)'),
  setPropTypes({
    /**
     * The ID of the company to show investements for.
     */
    companyId: PropTypes.number.isRequired,
  }),
  withInvestmentListFetched,
  mapProps(({ companyId, investmentList }) => ({
    companyId: companyId,
    investmentIds: investmentList && investmentList.get('ids'),
    isFetching: investmentList && investmentList.getIn(['_meta', 'isFetching']),
  })),
  withInvestmentIdsSortedByDate,
  withInvestmentIdsGroupedByVenture,
)(InvestmentsTableView);

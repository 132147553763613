const initSelectsInModal = require('../lib/modal_selects');
const initAutocomplete = require('../lib/select2_autocomplete');

exports.initialize = function initialize() {
  initSelectsInModal('.candidates .advanced-search-modal');
  initAutocomplete('.js-searches-filter', { dropdownParent: $('#filters') });
  initAutocomplete('.js-pipelines-filter', { dropdownParent: $('#filters') });
  initAutocomplete('.js-linkedin-skills-filter', {
    dropdownParent: $('#filters'),
  });
  initAutocomplete('.js-connected-to-user-filter', {
    dropdownParent: $('#filters'),
  });
  initAutocomplete('.js-connected-to-contact-filter', {
    dropdownParent: $('#filters'),
  });
  initAutocomplete('.js-connected-to-company-contact-filter', {
    dropdownParent: $('#filters'),
  });
  initAutocomplete('.js-relationship-manager-filter', {
    dropdownParent: $('#filters'),
  });
  initAutocomplete('.js-contact-relationship_manager-filter', {
    dropdownParent: $('#filters'),
  });
};

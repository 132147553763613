import getSearchAverageCurrentCompensation from './getSearchAverageCurrentCompensation';
import getSearchAverageDesiredCompensation from './getSearchAverageDesiredCompensation';
import getVisibleSearchAverageCurrentCompensation from './getVisibleSearchAverageCurrentCompensation';
import getVisibleSearchAverageDesiredCompensation from './getVisibleSearchAverageDesiredCompensation';
import getSearchCompensationPreference from './getSearchCompensationPreference';

export default (state, searchId, activeOnly, canViewHiddenCandidates) => {
  if (canViewHiddenCandidates) {
    return getSearchCompensationPreference(state, searchId)
      ? getSearchAverageDesiredCompensation(state, searchId, activeOnly)
      : getSearchAverageCurrentCompensation(state, searchId, activeOnly);
  }
  return getSearchCompensationPreference(state, searchId)
    ? getVisibleSearchAverageDesiredCompensation(state, searchId, activeOnly)
    : getVisibleSearchAverageCurrentCompensation(state, searchId, activeOnly);
};

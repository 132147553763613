import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  setStatic,
  withStateHandlers,
} from 'recompose';
import TableView from 'modules/core/componentsLegacy/Table/TableView';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import {
  SORT_ASCENDING_DEFAULT,
  SORT_BY_DEFAULT,
  SORT_BY_TYPES,
} from '../../constants';
import columnDefinitions from './CandidaciesTableView.columns';

import withCandidacyIdsSorted from '../withCandidacyIdsSorted';

/**
 * Renders a table containing a collection of candidacies.
 */
export default compose(
  setDisplayName('CandidaciesTableView'),
  setPropTypes({
    /**
     * The ID of the candidacies to show.
     */
    candidacyIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),

    /**
     * The columns to render
     */
    columns: TableView.propTypes.columns,

    /**
     * The default sort by value.
     */
    initialSortBy: PropTypes.oneOf(SORT_BY_TYPES).isRequired,

    /**
     * Whether the candidacyIds are currently being fetched.
     */
    isFetching: PropTypes.bool.isRequired,

    /**
     * Any fetch error that may have occured.
     */
    error: PropTypes.shape({ message: PropTypes.string }),

    /**
     * A callback that will refresh the list of candidacyIds.
     */
    onRefresh: PropTypes.func,
  }),

  // Predefined columns (so they can be accessed using CandidaciesTableView.columns, i.e.:
  // `CandidaciesTableView.columns.DateAdded`, etc...)
  setStatic('columns', columnDefinitions),

  defaultProps({
    initialSortAscending: SORT_ASCENDING_DEFAULT,
    initialSortBy: SORT_BY_DEFAULT,
    isFetching: false,
  }),

  withClassName('CandidaciesTableView'),

  // Sorting
  withStateHandlers(
    ({ initialSortAscending, initialSortBy }) => ({
      sortBy: initialSortBy,
      sortAscending: initialSortAscending,
    }),
    {
      onSortChange: () => (sortBy, sortAscending) => ({
        sortBy: sortBy,
        sortAscending: sortAscending,
      }),
    },
  ),
  withCandidacyIdsSorted,

  mapProps(
    ({
      candidacyIds,
      className,
      columns,
      error,
      isFetching,
      onRefresh,
      onSortChange,
      sortAscending,
      sortBy,
    }) => ({
      className: className,
      columns: columns,
      data: candidacyIds,

      // Render an empty state component when we don't have any candidacies.
      emptyState: !isFetching &&
        candidacyIds &&
        candidacyIds.count() === 0 && {
          message: 'No candidacies found',
        },

      // Show an error and allow retrying the fetch is it failed.
      errorAlert: !isFetching &&
        error && {
          error: error,
          onRetry: onRefresh,
          dismissable: false,
          title: 'There was an error fetching candidacies',
        },

      // Show a loading indicator if we are fetching.
      loadingIndicator: isFetching && {},

      onSortChange: onSortChange,
      sortAscending: sortAscending,
      sortBy: sortBy,
    }),
  ),
)(TableView);

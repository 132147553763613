import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import createRecommendedCandidacies from './createRecommendedCandidacies';
import {
  RECOMMENDED_FETCH_START,
  RECOMMENDED_FETCH_SUCCESS,
} from './actionTypes';

/**
 * Fetches the new set of 10 recommendations for a given search ID.
 * @param {Object} payload
 * @param {string} payload.searchId The search ID for which we need to fetch the recommendations.
 */

export default ({ searchId, transactionId }) => dispatch => {
  dispatch({
    type: RECOMMENDED_FETCH_START,
    searchId: Number(searchId),
  });
  dispatch(
    createRecommendedCandidacies({
      transactionId: transactionId,
      url: routes.recommend_api_v1_search_recommended_candidacies({
        searchId: searchId,
      }),
    }),
  ).then(action => {
    if (action.type === entityActionTypes.CREATE_SUCCESS) {
      dispatch({
        type: RECOMMENDED_FETCH_SUCCESS,
        searchId: Number(searchId),
        response: action.payload.response,
      });
    }
    return action;
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@thrivetrm/ui/components/Icon';
import Drawer from '@thrivetrm/ui/components/Drawer';
import Date from '@thrivetrm/ui/components/Date';
import { displayDate } from '@thrivetrm/ui/utilities/dateTimeUtils';
import ActivitySearchDetails from './ActivitySearchDetails';
import ActivityView from './ActivityView';

const Activity = ({
  assessedOn,
  createdAt,
  creatorName,
  icon,
  id,
  notificationKey,
  quickviewMessage,
  resourceDate,
  type,
  ...activityDetails
}) => {
  const interviewHeading = `${quickviewMessage} ${displayDate(
    activityDetails?.params?.startTime,
  )}`;

  const activityData =
    type === 'interview'
      ? {
          ...activityDetails.resource,
          startTime:
            activityDetails.resource?.startTime ||
            activityDetails.params?.startTime,
        }
      : activityDetails.resource;

  return (
    <div className='u-flex u-flexAlign-t u-marginTop-16' key={id}>
      {icon === 'bell-o' ? (
        <i className={`fa fa-${icon} Activity__activityIcon`} />
      ) : (
        <Icon size='large' type={icon} />
      )}
      <div className='u-marginLeft-8 u-flexItem-grow'>
        <Drawer
          className='u-marginBottom-4'
          drawerButtonClass='Activity__drawerButton'
          drawerHeaderClass='u-flexAlign-t u-padding-n u-backgroundColor-transparent'
          header={
            <h4 className='u-margin-n u-wordBreak'>
              {(type === 'interview' || type === 'meeting') &&
              notificationKey !== 'in_process'
                ? interviewHeading
                : quickviewMessage}
            </h4>
          }
          isOpenByDefault={false}
          shouldScrollInToViewOnOpen={true}
        >
          <ActivitySearchDetails type={type} {...activityDetails} />
          <ActivityView activityType={type} {...activityData} />
          {creatorName ? (
            <p className='u-fontSize-small u-marginBottom-n u-marginTop-4'>
              By: {creatorName}
            </p>
          ) : null}
        </Drawer>
        <Date
          className='u-fontSize-small'
          date={resourceDate || assessedOn || createdAt}
          format='dateTime'
        />
      </div>
    </div>
  );
};

Activity.propTypes = {
  assessedOn: PropTypes.string,
  createdAt: PropTypes.string,
  creatorName: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.number.isRequired,
  notificationKey: PropTypes.string,
  quickviewMessage: PropTypes.string.isRequired,
  resourceDate: PropTypes.string,
  type: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
};

export default Activity;

import getEntity from 'modules/entities/selectors/getEntity';
import { targetCompanyCommentSchema } from '../../schema';

/**
 * Gets a target company comment record by it's ID.
 * @param {Object} state
 * @param {Number} targetCompanyCommentId The ID of the target company comment.
 * @return {Immutable.Map} The target company comment record.
 */
export default (state, targetCompanyCommentId) =>
  getEntity(state, targetCompanyCommentSchema.key, targetCompanyCommentId);

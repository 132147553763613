import FieldState from 'modules/forms/FieldState';
import * as validators from 'modules/forms/validators';
import emailAddressesValidator from './emailAddressesValidator';

/**
 * Creates an FieldState for a ContactEmailSelectField.
 * @param {String} name The field name
 * @param {Object|Object[]} value The initial field value. Should be objects with an
 *   `email` property, and optional `id`, `name`, and `label` if it has an associated contact.
 * @param {*} validator Optional validators to run against the field values.
 */
export const createFieldState = (name, value, validator, ...rest) =>
  FieldState.create(
    name,
    value ? [].concat(value) : [],
    validator
      ? validators.combineValidators(validator, emailAddressesValidator)
      : emailAddressesValidator,
    ...rest,
  );

export default createFieldState;

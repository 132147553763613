import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'modules/core/componentsLegacy/Modal';
import QuestionAnswerListForm from './QuestionAnswerListForm';

const QuestionAnswersModalButton = ({ parentId, parentType, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className='QuestionAnswersModalButton'>
      <button className='btn-link' onClick={toggleOpen} type='button'>
        {title}
      </button>
      <Modal
        className='QuestionAnswersModal'
        dialogClassName='modal-lg'
        onHide={toggleOpen}
        show={isOpen}
        title={title}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuestionAnswerListForm
            onCancel={toggleOpen}
            onSaved={toggleOpen}
            parentId={parentId}
            parentType={parentType}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

QuestionAnswersModalButton.propTypes = {
  parentId: PropTypes.number.isRequired,
  parentType: PropTypes.oneOf(['assessment', 'interview']).isRequired,
  title: PropTypes.string.isRequired,
};

export default QuestionAnswersModalButton;

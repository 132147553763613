import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { targetCompanySchema } from '../../schema';

/**
 * Gets the candidates_count value given a target company ID
 */
export default (state, targetCompanyId) =>
  getEntityProperty(
    state,
    targetCompanySchema.key,
    targetCompanyId,
    'candidates_count',
    0,
  );

import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import setPreference from '../actions/setPreference';

/**
 * Sets a user preference value from a prop value whenever that prop value changes.
 * @param {String} propName The name of the prop that holds the value to store.
 * @param {String|String[]|Function} key The key, key path, or a function that returns a key or
 *   key path when called with the current props, which determines the key in the user preferences
 *   state where the property will be saved.
 */
export default (propName, key) =>
  compose(
    connect(null, { setPreference: setPreference }),
    lifecycle({
      UNSAFE_componentWillMount: function () {
        const { props } = this;
        if (props[propName]) {
          props.setPreference(
            typeof key === 'function' ? key(props) : key,
            props[propName],
          );
        }
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        if (nextProps[propName] !== this.props[propName]) {
          nextProps.setPreference(
            typeof key === 'function' ? key(nextProps) : key,
            nextProps[propName],
          );
        }
      },
    }),
  );

import { connect } from 'react-redux';
import getSearchType from '../selectors/getSearchType';

/**
 * A higher order component which maps `searchId` to it's search record.
 */
export default connect(
  (state, { searchId }) => ({
    searchType: searchId && getSearchType(state, searchId),
  }),
  {},
);

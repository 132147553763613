import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PopoverTrigger from 'modules/core/componentsLegacy/PopoverTrigger';
import IgnoreClickLink from 'modules/links/IgnoreClickLink';
import TelephoneLink from 'modules/links/TelephoneLink';
import SchemeEnsuredExternalLink from 'modules/links/SchemeEnsuredExternalLink';
import EmailLink from '@thrivetrm/ui/components/EmailLink';

/**
 * Renders a collection of contact link icons for a job application.
 */
const JobApplicationIconLinkList = ({ jobApplication }) => (
  <ul className='JobApplicationIconLinkList list-unstyled list-inline'>
    {jobApplication.get('email') && (
      <li>
        <PopoverTrigger
          content={<EmailLink emailAddress={jobApplication.get('email')} />}
          placement='bottom'
          rootClose={true}
          title='Email'
          trigger='click'
        >
          <IgnoreClickLink href={`mailto:${jobApplication.get('email')}`}>
            <i className='fa fa-envelope-square' />
          </IgnoreClickLink>
        </PopoverTrigger>
      </li>
    )}
    {jobApplication.get('phone') && (
      <li>
        <PopoverTrigger
          content={
            <TelephoneLink number={jobApplication.get('phone')}>
              {jobApplication.get('phone')}
            </TelephoneLink>
          }
          placement='bottom'
          rootClose={true}
          title='PhoneNumber'
          trigger='click'
        >
          <IgnoreClickLink href={`tel:${jobApplication.get('phone')}`}>
            <i className='fa fa-phone-square' />
          </IgnoreClickLink>
        </PopoverTrigger>
      </li>
    )}
    {jobApplication.get('linked_in_url') && (
      <li>
        <SchemeEnsuredExternalLink href={jobApplication.get('linked_in_url')}>
          <i className='fa fa-linkedin-square' />
        </SchemeEnsuredExternalLink>
      </li>
    )}
  </ul>
);

JobApplicationIconLinkList.propTypes = {
  jobApplication: ImmutablePropTypes.mapContains({
    phone: PropTypes.string,
    email: PropTypes.string,
    linked_in_url: PropTypes.string,
  }).isRequired,
};

export default JobApplicationIconLinkList;

import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import { ratingDefinitionSchema } from '../../schema';
import {
  RATING_DEFINITIONS_LIST_FETCH_START,
  RATING_DEFINITIONS_LIST_FETCH_SUCCESS,
  RATING_DEFINITIONS_LIST_FETCH_FAILURE,
} from './actionTypes';

/**
 * Fetches the defintions/descriptions for rating values.
 * @returns {Function} A thunk that will dispatch actions for fetching the defintions for
 *   ratings values.
 */
export default compose(
  fetchAction,
  normalizeResponse({
    rating_definitions: [ratingDefinitionSchema],
  }),
  createAsyncActions({
    startActionType: RATING_DEFINITIONS_LIST_FETCH_START,
    successActionType: RATING_DEFINITIONS_LIST_FETCH_SUCCESS,
    failureActionType: RATING_DEFINITIONS_LIST_FETCH_FAILURE,
  }),
  withOptions(() => ({
    url: routes.api_v1_rating_definitions(),
  })),
);

import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';

/**
 * A specialized error alert for the job application list.
 * Enahnaces the standard ErrorAlert with a some default props.
 */
export default compose(
  setDisplayName('JobApplicationListErrorAlert'),
  setPropTypes({
    /**
     * When provided the "retry" button will be shown allowing a manual refetch to be triggered
     */
    fetchJobApplicationList: PropTypes.func,
  }),
  defaultProps({
    title: 'There was an error fetching job applications',
  }),
  withProps(({ fetchJobApplicationList }) => ({
    onRetry: fetchJobApplicationList,
  })),
)(ErrorAlert);

import { connect } from 'react-redux';
import { compose, lifecycle, setDisplayName, withHandlers } from 'recompose';
import { bindActionCreators } from '@reduxjs/toolkit';
import shouldFetchOptionsSelector from '../selectors/shouldFetchOptions';
import fetchOptionsAction from '../actions/options/fetchOptions';

/**
 * Fetches the assessment options for the current tenant, if needed, when the
 * component is mounted or it's props change.
 * Provides an `isFetchingOptions` prop -- a boolean value indicating whether
 * the options are currently being fetched.
 */
export default compose(
  setDisplayName('withOptionsFetched'),
  connect(
    state => ({
      shouldFetchOptions: shouldFetchOptionsSelector(state),
    }),
    dispatch => ({
      fetchOptions: bindActionCreators(fetchOptionsAction, dispatch),
    }),
  ),
  withHandlers({
    /**
     * A handler that, when called, only fetches the options if needed.
     */
    fetchOptionsIfNeeded: ({ fetchOptions, shouldFetchOptions }) => () => {
      if (shouldFetchOptions) {
        fetchOptions();
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchOptionsIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchOptionsIfNeeded();
    },
  }),
);

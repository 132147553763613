import PropTypes from 'prop-types';
import React from 'react';

/**
 * A component which displays a message indicating that there are currently
 * no notifications to be displayed in the current view.
 */
const NotificationsEmpty = ({ label }) => (
  <div className='well text-center notifications-empty'>
    <i className='fa fa-bell-slash fa-4x' />
    <h2>
      {'No '}
      {label}
      {' notifications'}
    </h2>
  </div>
);

NotificationsEmpty.propTypes = {
  /**
   * An optional label identifying the type of notifications, and used
   * when displaying the "no notifications" message.
   * @type {String}
   */
  label: PropTypes.string,
};

export default NotificationsEmpty;

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
  withProps,
} from 'recompose';
import ExternalLink from 'modules/links/ExternalLink';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import { SEARCH_TYPES, TYPE_PIPELINE } from 'modules/searches/constants';
import TextInput from '@thrivetrm/ui/components/TextInput';
import TagTable from './TagTable';
import withTagIdsFilteredByText from './withTagIdsFilteredByText';
import withTagIdsSorted from './withTagIdsSorted';
import withTagListFetched from './withTagListFetched';
import withDeletedTagIdsFiltered from './withDeletedTagIdsFiltered';

/**
 * Renders a table of tags along with a filter to narrow the table rows down
 */
const FilterableTagTable = ({
  disabledTagIds,
  filterPlaceholder,
  isFetching,
  onCheckedChange,
  onTagFilterTextChange,
  searchType,
  selectedSmartTagIds,
  selectedTagIds,
  showPlaceholder,
  tagFilterText,
  tagIds,
}) => (
  <>
    <TextInput
      className='u-marginBottom-4'
      inputWidth='full'
      onChange={onTagFilterTextChange}
      placeholder={filterPlaceholder}
      size='small'
      value={tagFilterText}
    />
    <TagTable
      disabledTagIds={disabledTagIds}
      isFetching={isFetching}
      onCheckedChange={onCheckedChange}
      searchType={searchType}
      selectedTagIds={
        searchType === TYPE_PIPELINE ? selectedSmartTagIds : selectedTagIds
      }
      tagIds={tagIds}
    />
    {showPlaceholder && searchType !== TYPE_PIPELINE ? (
      <p className='u-marginTop-12'>
        To create new tags visit the admin portal. For instructions, please
        visit{' '}
        <ExternalLink href='https://helpcenter.thrivetrm.com/search-management/candidate-tagging'>
          https://helpcenter.thrivetrm.com/search-management/candidate-tagging
        </ExternalLink>
        .
      </p>
    ) : null}
  </>
);

FilterableTagTable.propTypes = {
  /**
   * The tag IDs of the tags that are in a disabled state.
   */
  disabledTagIds: PropTypes.arrayOf(PropTypes.number.isRequired),

  /**
   * placeholder for the tag filter
   */
  filterPlaceholder: PropTypes.string,

  /**
   * True if currently fetching the tag list; otherwise, false.
   */
  isFetching: PropTypes.bool,

  /**
   * Called when a checkbox's checked value is changed.
   */
  onCheckedChange: PropTypes.func.isRequired,

  /**
   * Called when the `tagFilterText` value is changed.
   */
  onTagFilterTextChange: PropTypes.func.isRequired,

  /**
   * The type of search (talent pool or job search or pipeline)
   */
  searchType: PropTypes.oneOf(SEARCH_TYPES),
  /**
   * The tag IDs of the tags that are currently assigned.
   */
  selectedTagIds: PropTypes.arrayOf(PropTypes.number.isRequired),

  /**
   * The text that is currently filtering the tag list.
   */
  tagFilterText: PropTypes.string.isRequired,

  /**
   * The list of tag IDs to render
   */
  tagIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
};

FilterableTagTable.defaultProps = {
  disabledTagIds: [],
  filterPlaceholder: 'Filter by Tag Label or Creator',
  selectedTagIds: [],
};

export default compose(
  setDisplayName('FilterableTagTable(enhanced)'),
  setPropTypes({
    disabledTagIds: FilterableTagTable.propTypes.selectedTagIds,
    onCheckboxChange: PropTypes.func.isRequired,
    selectedTagIds: FilterableTagTable.propTypes.selectedTagIds,
  }),
  withTagListFetched(),
  withStateHandlers(
    { tagFilterText: '' },
    {
      onTagFilterTextChange: () => newFilterValue => ({
        tagFilterText: newFilterValue,
      }),
      onCheckedChange: (_, { onCheckboxChange }) => e => {
        onCheckboxChange({
          e: e,
          tagId: parseInt(e.target.value),
          checked: e.target.checked,
        });
      },
    },
  ),
  mapProps(({ onCheckboxChange: _, tagList, ...props }) => ({
    ...props,
    isFetching: Boolean(tagList && tagList.getIn(['_meta', 'isFetching'])),
    tagIds: tagList && tagList.has('ids') ? tagList.get('ids') : null,
  })),
  withDeletedTagIdsFiltered,
  withTagIdsFilteredByText(),
  withTagIdsSorted,
  withFeatureCheck('feature.candidacy_tags.create', 'enableTagCreation'),
  withProps(({ enableTagCreation, tagIds }) => ({
    showPlaceholder: !enableTagCreation && tagIds && tagIds.size < 1,
  })),
)(FilterableTagTable);

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import Modal from 'modules/core/componentsLegacy/Modal';
import ChooseResourceType from './modal-modes/ChooseResourceType';
import ChooseFileFormat from './modal-modes/ChooseFileFormat';
import UploadCsv from './modal-modes/UploadCsv';
import { Modes } from '../constants';
import uploadCsvFileAction from '../actions/uploadCsvFile';

const BulkImportModal = ({ onClose, uploadCsvFile }) => {
  const [mode, setMode] = useState(Modes.CHOOSE_RESOURCE_TYPE);

  const handleModeChange = nextMode => {
    setMode(nextMode);
  };

  const getModeContent = () => {
    switch (mode) {
      case Modes.CHOOSE_RESOURCE_TYPE:
        return <ChooseResourceType onChoose={handleModeChange} />;
      case Modes.CHOOSE_FILE_FORMAT:
        return <ChooseFileFormat onChoose={handleModeChange} />;
      case Modes.UPLOAD_CSV:
        return <UploadCsv uploadCsvFile={uploadCsvFile} />;
      default:
        throw new Error('Unknown mode');
    }
  };

  return (
    <Modal
      className='BulkImportModal'
      dialogClassName='modal-lg'
      onHide={onClose}
      show={true}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Start New Import</Modal.Title>
      </Modal.Header>
      <Modal.Body className='BulkImportModal__modalBody'>
        {getModeContent()}
      </Modal.Body>
    </Modal>
  );
};

BulkImportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  uploadCsvFile: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('BulkImportModal(enhanced)'),
  connect(null, { uploadCsvFile: uploadCsvFileAction }),
)(BulkImportModal);

import PropTypes from 'prop-types';
import React from 'react';
import {
  branch,
  compose,
  defaultProps,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import { SEARCH_TYPES, TYPE_PIPELINE } from 'modules/searches/constants';
import mapSearchIdToSearch from './mapSearchIdToSearch';

const SearchSummary = ({ searchType, summary }) => {
  const title =
    searchType === TYPE_PIPELINE
      ? 'Pipeline Status Summary'
      : 'Search Status Summary';

  return (
    <div>
      <h4>{title}</h4>
      <HtmlText htmlString={summary} />
    </div>
  );
};

SearchSummary.propTypes = {
  searchType: PropTypes.oneOf(SEARCH_TYPES),
  summary: PropTypes.string,
};

export default compose(
  setDisplayName('SearchSummary(enhanced)'),
  setPropTypes({
    hideIfEmpty: PropTypes.bool.isRequired,
    /**
     * The search ID to show the summary text for.
     */
    searchId: PropTypes.number.isRequired,
  }),
  defaultProps({
    hideIfEmpty: false,
  }),
  mapSearchIdToSearch,
  withFeatureCheck('field.search.summary', 'fieldSearchSummaryEnabled'),
  mapProps(({ fieldSearchSummaryEnabled, hideIfEmpty, search, searchType }) => {
    const summary = search && search.get('summary');

    return {
      searchType: searchType,
      summary: summary,
      hideSummary: !fieldSearchSummaryEnabled || (hideIfEmpty && !summary),
    };
  }),
  branch(({ hideSummary }) => hideSummary, renderNothing),
)(SearchSummary);

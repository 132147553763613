import React from 'react';
import Rating from '@thrivetrm/ui/components/Rating';
import PropTypes from 'prop-types';

const RecruitmentRatingOverallRating = ({
  overallRatingValue,
  totalReviewersCount,
}) => {
  return (
    <div className='u-marginBottom-16 u-paddingBottom-24 u-borderBottom'>
      <h2
        className='u-marginTop-8 u-marginBottom-16'
        data-testid='overall-rating-title'
      >
        Overall Rating
      </h2>
      {totalReviewersCount ? (
        <Rating
          data-testid='Overall-Rating'
          totalReviewers={totalReviewersCount}
          value={overallRatingValue}
        />
      ) : (
        <>
          <Rating value={0} />
          <span className='u-paddingLeft-4'>Not Rated</span>
        </>
      )}
    </div>
  );
};

export default RecruitmentRatingOverallRating;

RecruitmentRatingOverallRating.propTypes = {
  overallRatingValue: PropTypes.number,
  totalReviewersCount: PropTypes.number,
};

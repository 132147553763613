import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withProps, setPropTypes } from 'recompose';
import getPosition from 'modules/positions/selectors/getPosition';
import withPositionListFetched from './positions/withPositionListFetched';

/**
 * A Higher order component gets a list of a contact's current positions or positions
 * with no 'to' date.
 */
export default compose(
  setPropTypes({
    contactId: PropTypes.number,
  }),
  withPositionListFetched,
  withProps(({ positionList }) => ({
    positionIds: positionList.get('ids'),
  })),
  connect((state, { positionIds }) => ({
    positions: positionIds ? positionIds.map(id => getPosition(state, id)) : [],
  })),
  withProps(({ positions }) => ({
    currentCompanyIds: positions.map(position =>
      position.get('to') === null ? position.get('company') : null,
    ),
  })),
);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import bulkImportSchema from '../schema';

/**
 * Gets a property of a bulkImport entity by it's ID.
 * @param {*} state
 * @param {Number} id The ID of the bulkImport.
 * @param {String} propertyName The name of the property.
 * @returns {*} The property value for the bulkImport, if found.
 */
export default (state, id, propertyName) =>
  getEntityProperty(state, bulkImportSchema.key, id, propertyName);

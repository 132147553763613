import { withStateHandlers } from 'recompose';

/**
 * A higher-order component generator for dealing with modal states
 *
 * @example
 * ```jsx
 * import MyModal from '...';
 *
 * const MyModalToggleButton = ({ handleHideModal, isModalShown, handleShowModal }) => (
 *   <div>
 *     <button onClick={handleShowModal}>Click here to show the modal!</button>
 *     {isModalShown && (<MyModal show onHide={handleHideModal} />)}
 *   </div>
 * );
 *
 * export default withModalState()(MyModalToggleButton);
 * ```
 *
 * @example
 * ```jsx
 * // An example of customizing the prop names
 * // In this case the modal is always rendered, but only _visible_ when the `isModalVisible` state
 * // is true, where the first example shows that the modal won't even be rendered unless the state
 * // is true.
 * import MyModal from '...';
 *
 * const MyModalToggleButton = ({ hideIt, isModalVisible, toggleIt }) => (
 *   <div>
 *     <button onClick={toggleIt}>Click here to show the modal!</button>
 *     <MyModal={isModalVisible} show onHide={hideIt} />
 *   </div>
 * );
 *
 * export default withModalState({
 *   hideModalProp: 'hideIt',
 *   isModalShownProp: 'isModalVisible',
 *   showModalProp: 'showIt',
 *   toggleModalProp: 'toggleIt',
 * })(MyModalToggleButton);
 * ```
 *
 * @param {Object} options
 * @param {String} [options.isModalShownProp="isModalShown"] the name of the prop that will
 *   indicate whether the modal should be currently shown or not.
 * @param {String} [options.showModalProp="handleShowModal"] the name of the function prop that,
 *   when called, will set the isModalShownProp value to true
 * @param {String} [options.hideModalProp="handleHideModal"] the name of the function prop that,
 *   when called, will set the isModalShownProp value to false
 * @param {String} [options.toggleModalProp="handleToggleModal"] the name of the function prop that,
 *   when called, will toggle the value of the isModalShownProp value to false
 * @param {String} [options.initiallyShowModalProp="initiallyShowModal"]
 */
export default ({
  hideModalProp = 'handleHideModal',
  initiallyShowModalProp = 'initiallyShowModal',
  isModalShownProp = 'isModalShown',
  showModalProp = 'handleShowModal',
  toggleModalProp = 'handleToggleModal',
} = {}) =>
  withStateHandlers(
    props => ({ [isModalShownProp]: props[initiallyShowModalProp] || false }),
    {
      [showModalProp]: () => () => ({ [isModalShownProp]: true }),
      [hideModalProp]: () => () => ({ [isModalShownProp]: false }),
      [toggleModalProp]: state => () => ({
        [isModalShownProp]: !state[isModalShownProp],
      }),
    },
  );

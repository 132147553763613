import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import disabilityStatusSchema from '../schemas/disabilityStatuses';

/**
 * Renders the display name for a given disability status ID.
 */
const DisabilityStatusName = ({ disabilityStatusId }) => (
  <EntityProperty
    entityId={disabilityStatusId}
    entityType={disabilityStatusSchema.key}
    propertyName='name'
  />
);

DisabilityStatusName.propTypes = {
  disabilityStatusId: PropTypes.number.isRequired,
};

export default DisabilityStatusName;

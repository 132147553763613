import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import getSearchName from 'modules/searches/selectors/getSearchName';
import getSearchClientCompanyName from 'modules/searches/selectors/getSearchClientCompanyName';

/* eslint-disable camelcase */
export const SearchTitle = ({
  client_company,
  code_name,
  confidential,
  job_title,
  name,
  tab,
  url,
}) => (
  <h6 className='notification-search-title'>
    <a href={tab ? url + tab : url}>
      {confidential ? code_name : job_title || name}
      {!confidential && client_company && (
        <span>
          {' at '}
          {client_company.name}
        </span>
      )}
    </a>
  </h6>
);
/* eslint-enable camelcase */

SearchTitle.propTypes = {
  client_company: PropTypes.shape({
    name: PropTypes.string,
  }),

  code_name: PropTypes.string,

  confidential: PropTypes.bool,

  job_title: PropTypes.string,

  name: PropTypes.string,

  tab: PropTypes.string,

  url: PropTypes.string.isRequired,
};

/**
 * Maps a resource type to it's related candidacy tab on the search page.
 */
const RESOURCE_TYPE_TAB_MAP = {
  comment: 'comments',
  interview: 'interview',
  assessment: 'assessments',
  outreach: 'outreach',
  reference: 'references',
  note: 'notes',
};

/**
 * Attemps to determine the URL for a search title based on the searchId and the notification
 * record.
 */
const getUrl = ({ notification, searchId }) => {
  const candidacyId =
    notification.getIn(['data', 'candidacy_id']) ||
    notification.getIn([
      'data',
      'resource',
      'data',
      'candidacy_ids',
      String(searchId),
    ]);

  const resourceType = notification.getIn(['data', 'type']);

  if (candidacyId) {
    if (RESOURCE_TYPE_TAB_MAP[resourceType]) {
      return `/searches/${searchId}/candidates/${candidacyId}/${RESOURCE_TYPE_TAB_MAP[resourceType]}`;
    }

    return `/searches/${searchId}/candidates/${candidacyId}`;
  }

  return `/searches/${searchId}`;
};

/**
 * This is TEMPORARY!! until notifications gets refactored into a module and is using
 * normalizr, etc.
 * The problem is that as we normalize the various notification resources, we flatten out
 * the "searches"/"search_ids" properties on them -- so instead of having direct access to the
 * search record (with name, company, job title, etc), it becomes just the search ID.
 * So in the interim, we'll have a connected version of SearchTitles that can just take a searchId
 * and lookup the search and company detaails from state. But this stuff really doesn't belong
 * here in the long run because it's duplicate code. We _could_ use the module components
 * directly here, but I'm trying to minimize that because we could end up with inadvertantly
 * creating circular dependencies that create very weird bugs.
 */

export default connect(
  (state, { notification, searchId }) => ({
    job_title: getSearchName(state, searchId),
    url: getUrl({ notification: notification, searchId: searchId }),
    client_company: {
      name: getSearchClientCompanyName(state, searchId),
    },
  }),
  {},
)(SearchTitle);

import { connect } from 'react-redux';
import getAssessment from '../selectors/getAssessment';

/**
 * A higher order component which maps an `assessmentId` prop value to it's assessment record.
 */
export default connect(
  (state, { assessmentId }) => ({
    assessment: getAssessment(state, assessmentId),
  }),
  {},
);

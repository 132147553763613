import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';

import getUserPermission from 'modules/auth/selectors/getUserPermission';
import TabbedHeaderAction from 'modules/layouts/tabbedHeader/TabbedHeaderAction';
import TabbedHeaderContainer from 'modules/layouts/tabbedHeader/TabbedHeaderContainer';
import SearchPageViewLink from 'modules/searches/components/SearchPageViewLink';

import canEditSearchDetails from 'modules/searches/selectors/canEditSearchDetails';
import * as actions from '../../actions';
import JobApplicationList from '../jobApplicationList/JobApplicationList';
import JobApplicationListSortAndFilterForm from '../JobApplicationListSortAndFilterForm';
import JobApplicationListRefreshButton from '../JobApplicationListRefreshButton';

import {
  SORT_ASCENDING_DEFAULT,
  SORT_BY_DEFAULT,
  SORT_BY_TYPES,
} from '../../constants';

const JobApplicationsSearchPage = ({
  allowShareAction,
  filterText,
  onFilterChange,
  searchId,
  sortAscending,
  sortBy,
  ...rest
}) => (
  <TabbedHeaderContainer
    {...rest}
    actions={[
      allowShareAction && (
        <TabbedHeaderAction key='share' label='Share'>
          <SearchPageViewLink
            page='edit#job-listings'
            pageOnly={true}
            useDefaultLinkBehavior={true}
          >
            <i className='fa fa-share-alt' />
          </SearchPageViewLink>
        </TabbedHeaderAction>
      ),
      <TabbedHeaderAction key='refresh' label='Refresh'>
        <JobApplicationListRefreshButton searchId={searchId} />
      </TabbedHeaderAction>,
    ]}
    filter={
      <JobApplicationListSortAndFilterForm
        filterText={filterText}
        onChange={onFilterChange}
        sortAscending={sortAscending}
        sortBy={sortBy}
      />
    }
  >
    <JobApplicationList
      filterText={filterText}
      searchId={searchId}
      sortAscending={sortAscending}
      sortBy={sortBy}
    />
  </TabbedHeaderContainer>
);

JobApplicationsSearchPage.propTypes = {
  /**
   * Whether the "share" action (which is just a link to the edit page) should be rendered.
   */
  allowShareAction: PropTypes.bool,

  /**
   * The current filter text that may be filtering the aplication list.
   */
  filterText: PropTypes.string,

  /**
   * Called when the filter text or sort values are changed.
   */
  onFilterChange: PropTypes.func.isRequired,

  /**
   * The ID of the search whose application list is being rendered.
   */
  searchId: PropTypes.number.isRequired,

  /**
   * Whether the applications should be sorted in ascending order.
   */
  sortAscending: PropTypes.bool,

  /**
   * The current field being sorted on.
   */
  sortBy: PropTypes.oneOf(SORT_BY_TYPES),
};

JobApplicationsSearchPage.defaultProps = {
  allowShareAction: false,
  filterText: '',
  sortAscending: SORT_ASCENDING_DEFAULT,
  sortBy: SORT_BY_DEFAULT,
};

export default compose(
  setDisplayName('JobApplicationsSearchPage(enhanced)'),
  setPropTypes({
    searchId: PropTypes.number.isRequired,
  }),
  connect(
    state => ({
      allowShareAction:
        getUserPermission(state, 'view_hiring_team') &&
        canEditSearchDetails(state),
    }),
    dispatch => ({
      jobApplicationActions: bindActionCreators(actions, dispatch),
    }),
  ),
  withState('filterText', 'setFilterText', ''),
  withState('sortBy', 'setSortBy', SORT_BY_DEFAULT),
  withState('sortAscending', 'setSortAscending', SORT_ASCENDING_DEFAULT),
  withHandlers({
    onFilterChange: ({
      setFilterText,
      setSortAscending,
      setSortBy,
    }) => filter => {
      setFilterText(filter.filterText);
      setSortBy(filter.sortBy);
      setSortAscending(filter.sortAscending);
    },
  }),
)(JobApplicationsSearchPage);

import PropTypes from 'prop-types';
import {
  branch,
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import { SEARCH_TYPES } from 'modules/searches/constants';
import CandidaciesTableView from './CandidaciesTableView';
import withCandidacyIdsFilteredBySearchType from '../withCandidacyIdsFilteredBySearchType';
import withCandidacyListFetched from '../withCandidacyListFetched';
import { PARENT_TYPES } from '../../constants';

/**
 * A CandidaciesTableView that is powered by the data from an underlying candidacyList
 * collection.
 */
export default compose(
  setDisplayName('CandidacyListTableView'),
  setPropTypes({
    /**
     * The columns to render in the table.
     */
    columns: PropTypes.array.isRequired,

    /**
     * The ID of the parent to show candidacies for.
     */
    parentId: PropTypes.number.isRequired,

    /**
     * The type of parent to show candidacies for.
     */
    parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,

    /**
     * Optionally only show candidacies for a specific search type (either TYPE_JOB_SEARCH or
     * TYPE_TALENT_POOL)
     */
    searchType: PropTypes.oneOf(SEARCH_TYPES),
  }),

  withClassName('CandidacyListTableView'),
  withCandidacyListFetched(),

  // Extract props from the list state.
  withProps(({ candidacyList }) => ({
    candidacyIds: candidacyList && candidacyList.get('ids'),
    isFetching: candidacyList && candidacyList.getIn(['_meta', 'isFetching']),
    error: candidacyList && candidacyList.getIn(['_meta', 'error']),
  })),

  // Filter to only include the search type specified, if provided.
  branch(({ searchType }) => searchType, withCandidacyIdsFilteredBySearchType),
)(CandidaciesTableView);

import getAllTasks from './getAllTasks';

/**
 * Determines if tasks should be fetched from the server.
 * @param {*} State The current application state
 * @return {Boolean} true if tasks should be fetched from the server; otherwise, false.
 */
export default state => {
  const tasksState = getAllTasks(state);

  if (!tasksState) {
    return true;
  }

  if (tasksState.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (tasksState.hasIn(['_meta', 'error'])) {
    return false;
  }

  if (!tasksState.has('ids')) {
    return true;
  }

  return false;
};

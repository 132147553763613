/* eslint-disable func-names */
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

module.exports = (field, options) =>
  $(field).each(function () {
    $(this).select2({
      ajax: {
        url: $(this).data().url || $(this).data('autocomplete-path'),
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return { query: params.term };
        },
        processResults: function (data, _page) {
          // Parse the results into the format expected by Select2
          // eg: [id: id, text: text]
          // Necessary because our jquery autocomplete hits the same endpoint
          // and we are supplying a custom data structure.
          const results = Array.from(data.suggestions).map(suggestion => ({
            id: suggestion.data.id,
            text: suggestion.value,
          }));

          return { results: results };
        },
        cache: true,
      },
      placeholder: $(this).data().placeholder,
      minimumInputLength: 1,
      width: '100%',
      multiple: $(this).data().multiple,
      allowClear: Boolean($(this).data().multiple),
      ...options,
    });

    return $(this).on('select2:close', function () {
      return $(this).focus();
    });
  });

module.exports.destroy = () =>
  $('.select2')
    .siblings('select')
    .each(function () {
      if ($(this).data('select2')) {
        $(this).select2('destroy');
      }
    });

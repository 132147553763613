import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import selectn from 'selectn';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import Measure from 'react-measure';

import getUserPermission from 'modules/auth/selectors/getUserPermission';
import SearchPageViewLink from 'modules/searches/components/SearchPageViewLink';
import TabbedHeaderAction from 'modules/layouts/tabbedHeader/TabbedHeaderAction';
import TabbedHeaderContainer from 'modules/layouts/tabbedHeader/TabbedHeaderContainer';

import canEditSearchDetails from 'modules/searches/selectors/canEditSearchDetails';
import SortAndFilterToolbar from '../SortAndFilterToolbar';
import TargetCompaniesView from '../TargetCompaniesView';
import TargetCompaniesViewModeSelect from '../TargetCompaniesViewModeSelect';
import {
  SORT_ASCENDING_DEFAULT,
  SORT_BY_DEFAULT,
  SORT_BY_VALUES,
  VIEW_MODE_DEFAULT,
  VIEW_MODES,
} from '../../constants';

export const TargetCompaniesSearchPage = ({
  allowAddRemoveTargetCompanies,
  allowShareAction,
  filterText,
  isPriorityOnly,
  onFilterChange,
  onSortChange,
  onTargetCompanySelect,
  onViewModeChange,
  searchId,
  sortAscending,
  sortBy,
  viewMode,
  ...rest
}) => (
  <TabbedHeaderContainer
    {...rest}
    actions={[
      allowAddRemoveTargetCompanies && (
        <TabbedHeaderAction
          key='add-remove'
          label='Add/Remove Target Companies'
        >
          <SearchPageViewLink
            page='target_companies/edit'
            pageOnly={true}
            useDefaultLinkBehavior={true}
          >
            <i className='fa fa-pencil-square-o' />
          </SearchPageViewLink>
        </TabbedHeaderAction>
      ),
      allowShareAction && (
        <TabbedHeaderAction key='share' label='Share'>
          <SearchPageViewLink
            page='edit#search-team'
            pageOnly={true}
            useDefaultLinkBehavior={true}
          >
            <i className='fa fa-share-alt' />
          </SearchPageViewLink>
        </TabbedHeaderAction>
      ),
    ]}
    filter={
      <div className='TargetCompaniesSearchPage__filter'>
        <SortAndFilterToolbar
          filterText={filterText}
          isPriorityOnly={isPriorityOnly}
          onChange={onFilterChange}
          sortAscending={sortAscending}
          sortBy={sortBy}
        />
        <TargetCompaniesViewModeSelect
          onChange={onViewModeChange}
          value={viewMode}
        />
      </div>
    }
  >
    <Measure bounds={true}>
      {({ contentRect, measureRef }) => (
        <div ref={measureRef}>
          <TargetCompaniesView
            cardsPerRow={selectn('bounds.width', contentRect) > 500 ? 3 : 2}
            filterText={filterText}
            isPriorityOnly={isPriorityOnly}
            onSortChange={onSortChange}
            onTargetCompanySelect={onTargetCompanySelect}
            searchId={searchId}
            sortAscending={sortAscending}
            sortBy={sortBy}
            viewMode={viewMode}
          />
        </div>
      )}
    </Measure>
  </TabbedHeaderContainer>
);

TargetCompaniesSearchPage.propTypes = {
  allowAddRemoveTargetCompanies: PropTypes.bool,
  allowShareAction: PropTypes.bool,
  filterText: PropTypes.string,
  isPriorityOnly: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onTargetCompanySelect: PropTypes.func.isRequired,
  onViewModeChange: PropTypes.func.isRequired,
  searchId: PropTypes.number,
  sortAscending: PropTypes.bool,
  sortBy: PropTypes.oneOf(SORT_BY_VALUES),
  targetCompanyList: ImmutablePropTypes.mapContains({
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  viewMode: PropTypes.oneOf(VIEW_MODES).isRequired,
};

TargetCompaniesSearchPage.defaultProps = {
  allowAddRemoveTargetCompanies: false,
  allowShareAction: false,
  isPriorityOnly: false,
  sortAscending: SORT_ASCENDING_DEFAULT,
};

export default compose(
  setDisplayName('TargetCompaniesSearchPage(enhanced)'),
  setPropTypes({
    onTargetCompanySelect: PropTypes.func.isRequired,
    searchId: PropTypes.number,
  }),

  // Lookup permissions for displaying the action buttons
  connect(
    state => ({
      allowAddRemoveTargetCompanies: getUserPermission(
        state,
        'can_edit_target_companies',
      ),
      allowShareAction:
        getUserPermission(state, 'view_hiring_team') &&
        canEditSearchDetails(state),
    }),
    {},
  ),

  // Manage the filter/sorting/view mode state.
  withStateHandlers(
    {
      filterText: '',
      isPriorityOnly: false,
      sortAscending: SORT_ASCENDING_DEFAULT,
      sortBy: SORT_BY_DEFAULT,
      viewMode: VIEW_MODE_DEFAULT,
    },
    {
      onViewModeChange: () => viewMode => ({ viewMode: viewMode }),
      onFilterChange: () => ({
        filterText,
        isPriorityOnly,
        sortAscending,
        sortBy,
      }) => ({
        filterText: filterText,
        isPriorityOnly: isPriorityOnly,
        sortAscending: sortAscending,
        sortBy: sortBy,
      }),
      onSortChange: () => (sortBy, sortAscending) => ({
        sortAscending: sortAscending,
        sortBy: sortBy,
      }),
    },
  ),
)(TargetCompaniesSearchPage);

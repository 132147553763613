import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import Panel from 'react-bootstrap/lib/Panel';
import classnames from 'classnames';
import preventDefaultHandler from './preventDefaultHandler';
import withComponentId from './withComponentId';

/**
 * Renders a panel whose content can be collapsed.
 */
const CollapsiblePanel = ({
  children,
  className,
  componentId,
  isExpanded,
  title,
  toggleExpanded,
}) => (
  <Panel
    className={classnames('CollapsiblePanel panel', className, {
      'CollapsiblePanel--expanded': isExpanded,
      'CollapsiblePanel--collapsed': !isExpanded,
    })}
    expanded={isExpanded}
    id={componentId}
    onToggle={toggleExpanded}
  >
    <Panel.Heading>
      <Panel.Title className='CollapsiblePanel__header' toggle={true}>
        <span className='fa-stack CollapsiblePanel__headerIcon'>
          <i className='fa fa-circle fa-stack-2x' />
          <i
            className={classnames('fa fa-stack-1x', {
              'icon-collapse': isExpanded,
              'icon-expand': !isExpanded,
            })}
          />
        </span>
        <span className='CollapsiblePanel__title'>{title}</span>
      </Panel.Title>
    </Panel.Heading>
    <Panel.Collapse>
      <Panel.Body>
        {isExpanded ? (
          children
        ) : (
          <div className='CollapsiblePanel__placeholder' />
        )}
      </Panel.Body>
    </Panel.Collapse>
  </Panel>
);

CollapsiblePanel.defaultProps = {
  className: null,
};

CollapsiblePanel.propTypes = {
  /**
   * The content to render inside the panel.
   */
  children: PropTypes.node.isRequired,

  /**
   * An optional classname to apply to the panel
   */
  className: PropTypes.string,

  /**
   * A unique ID for this panel instance.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * True if the panel is expanded, false if it is collapsed.
   */
  isExpanded: PropTypes.bool.isRequired,

  /**
   * The title to display in the panel header.
   */
  title: PropTypes.string.isRequired,

  /**
   * A function that toggles the state of the `isExpanded` prop.
   */
  toggleExpanded: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CollapsiblePanel(enhanced)'),
  setPropTypes({
    /**
     * The content to render inside the panel.
     */
    children: PropTypes.node.isRequired,

    /**
     * An optional classname to apply to the panel
     */
    className: PropTypes.string,

    /**
     * False to show the panel initially in a collapsed state.
     */
    isInitiallyExpanded: PropTypes.bool.isRequired,

    /**
     * The title to display in the panel header.
     */
    title: PropTypes.string.isRequired,
  }),
  defaultProps({
    isInitiallyExpanded: false,
  }),
  withComponentId(),
  withStateHandlers(
    ({ isInitiallyExpanded }) => ({
      isExpanded: Boolean(isInitiallyExpanded),
    }),
    {
      toggleExpanded: ({ isExpanded }) => e => {
        preventDefaultHandler(e);
        return { isExpanded: !isExpanded };
      },
    },
  ),
)(CollapsiblePanel);

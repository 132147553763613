import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, mapProps, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import withRevenueListFetched from './withRevenueListFetched';
import CompanyRevenueListView from './CompanyRevenueListView';
import CompanyRevenueListForm from './CompanyRevenueListForm';

/**
 * Renders an EditableSection that displays and allows editing of a company's revenues.
 */
export default compose(
  setDisplayName('CompanyRevenueEditableSection(enhanced)'),
  setPropTypes({
    /**
     * The ID of the company whose revenue will be shown/edited.
     */
    companyId: PropTypes.number.isRequired,
  }),

  // The revenue list needs to be fetched so that we know whether we will be able to render
  // and children or not, and can tell the EditableSection whether we are currently fetching data
  // or not.
  withRevenueListFetched,

  mapProps(({ companyId, revenueList }) => ({
    companyId: companyId,
    isFetching: revenueList && revenueList.getIn(['_meta', 'isFetching']),
    children:
      revenueList &&
      revenueList.get('ids') &&
      revenueList.get('ids').size !== 0 ? (
        <CompanyRevenueListView companyId={companyId} />
      ) : null,
    className: 'CompanyRevenueEditableSection',
    formComponent: CompanyRevenueListForm,
    title: 'Revenue',
  })),
)(EditableSection);

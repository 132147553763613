import React from 'react';
import PropTypes from 'prop-types';
import { COMMUTE_RADIUS_OPTIONS } from '../../../components/contact/constants';

const CommuteRadius = ({ value }) => {
  const commuteRadius = COMMUTE_RADIUS_OPTIONS.find(
    option => option.value === String(value),
  );
  const commuteRadiusLabel = commuteRadius ? commuteRadius.label : null;
  return <span>{commuteRadiusLabel}</span>;
};

CommuteRadius.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CommuteRadius;

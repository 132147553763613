import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import {
  RESIDENCIES_FETCH_START,
  RESIDENCIES_FETCH_SUCCESS,
  RESIDENCIES_FETCH_FAILURE,
} from './actionTypes';
import residencySchema from '../schema';

/**
 * Creates an action for fetching a list of residencies
 */
export default compose(
  fetchAction,
  normalizeResponse({ residencies: [residencySchema] }),
  createAsyncActions({
    startActionType: RESIDENCIES_FETCH_START,
    successActionType: RESIDENCIES_FETCH_SUCCESS,
    failureActionType: RESIDENCIES_FETCH_FAILURE,
  }),
  withOptions({
    url: routes.api_residencies(),
  }),
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';

import RichTextField from '../../../components/forms/richtext/RichTextField';

/**
 * A field that can be used for editing an email template.
 */
class EmailTemplateField extends PureComponent {
  static createFieldState(name = 'emailTemplate', emailTemplate, ...rest) {
    const values = fromJS({
      name: '',
      subject: '',
      content: '',
    }).merge(emailTemplate);

    return FieldState.createNested(
      name,
      [
        InputField.createFieldState(
          'name',
          values.get('name'),
          validators.requiredField('Template name'),
        ),
        InputField.createFieldState('subject', values.get('subject')),
        RichTextField.createFieldState('content', values.get('content')),
      ],
      ...rest,
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const { fieldState, onChange: _onChange, ...otherProps } = this.props;

    return (
      <div className='EmailTemplateField row'>
        <div className='col-12'>
          <InputField
            {...otherProps}
            fieldState={fieldState.getNestedField('name')}
            label='Name'
            onChange={this.handleFieldChange}
          />
        </div>
        <div className='col-12'>
          <InputField
            {...otherProps}
            fieldState={fieldState.getNestedField('subject')}
            key='subject'
            label='Subject'
            onChange={this.handleFieldChange}
          />
        </div>
        <div className='col-12'>
          <RichTextField
            {...otherProps}
            fieldState={fieldState.getNestedField('content')}
            onChange={this.handleFieldChange}
            placeholder='Enter template content...'
          />
        </div>
      </div>
    );
  }
}

EmailTemplateField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,
};

export default EmailTemplateField;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import routes from 'modules/routing/routes';
import Autocomplete from '@thrivetrm/ui/components/Autocomplete';
import Card from '@thrivetrm/ui/components/Card';
import Icon from '@thrivetrm/ui/components/Icon';
import FullPageTakeoverHeader from '@thrivetrm/ui/components/FullPageTakeoverHeader';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Avatar from '@thrivetrm/ui/components/Avatar';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import HighlightedText from '@thrivetrm/ui/components/HighlightedText';
import DuplicateMergeRecordCard from './DuplicateMergeRecordCard';
import { loadBaseRecord } from '../duplicateMergeSlice';

const formatAutocompleteOption = (avatarShape, option, query) => (
  <div className='DuplicateMergeRecordSelection__autocompleteOption'>
    <Avatar
      className='u-marginRight-12'
      image={option.avatar}
      name={option.name}
      shape={avatarShape}
      size='small'
    />
    <div className='DuplicateMergeRecordSelection__autocompleteContent'>
      <h3 className='u-margin-n'>
        <HighlightedText query={query}>{option.name}</HighlightedText>
        <span className='DuplicateMergeRecordSelection__autocompleteId'>
          ID: {option.id}
        </span>
      </h3>
      <div className='u-marginTop-8'>
        <div>{option.position_name}</div>
        <div>{option.company_name}</div>
      </div>
    </div>
  </div>
);

const DuplicateMergeRecordSelection = ({
  avatarShape,
  recordId,
  recordLabel,
  recordProfileUrl,
  recordType,
}) => {
  const dispatch = useDispatch();

  const { baseRecord, isLoadingBaseRecord, loadBaseRecordError } = useSelector(
    state => state.duplicateMerge,
  );

  const [recordB, setRecordB] = useState(null);

  // load Record A data
  useEffect(() => {
    // Cheat and use the autocomplete endpoint to get a single record's data
    // so that it is in the same shape as the autocomplete results
    const autocompleteEndpoint = routes.api_v1_autocomplete_query({
      query: {
        query: {
          id: recordId,
          resource: recordType,
        },
      },
    });
    dispatch(loadBaseRecord(autocompleteEndpoint));
  }, [dispatch, recordId, recordType]);

  const continueButtonUrl = recordB
    ? `${recordProfileUrl}/merge/${recordB.id}`
    : null;

  const isReadyToSubmit = Boolean(continueButtonUrl);

  return (
    <>
      <FullPageTakeoverHeader
        backUrl={recordProfileUrl}
        title='Doppelgänger Wrangler'
      />
      <div className='container-full-width u-marginTop-64 u-marginBottom-32'>
        <LoadingContainer
          isLoading={isLoadingBaseRecord}
          overlayColor='gray10'
          size='large'
        >
          <div style={{ minHeight: '80vh' }}>
            <div>
              {loadBaseRecordError && (
                <Card
                  className='DuplicateMergeRecordSelection__errorCard'
                  type='error'
                >
                  {loadBaseRecordError}
                </Card>
              )}
              {baseRecord && (
                <div className='DuplicateMergeRecordSelection__content container-full-width'>
                  <h1 className='u-textAlign-c u-marginVertical-32 u-paddingTop-64'>
                    Select a {recordLabel.toLowerCase()} to merge into{' '}
                    {baseRecord.name}
                  </h1>
                  <div className='DuplicateMergeRecordSelection__row'>
                    <div className='DuplicateMergeRecordSelection__col'>
                      <h2 className='u-marginTop-n'>{recordLabel} A</h2>
                      <div style={{ minHeight: 40 }}>
                        This {recordLabel.toLowerCase()} will persist
                      </div>
                      <DuplicateMergeRecordCard
                        avatarShape={avatarShape}
                        avatarUrl={baseRecord.avatar}
                        fullName={baseRecord.name}
                        isLoading={isLoadingBaseRecord}
                        primaryCompanyName={baseRecord.company_name}
                        primaryTitle={baseRecord.position_name}
                      />
                    </div>

                    <div className='DuplicateMergeRecordSelection__icon'>
                      <Icon
                        backgroundColor='yellow'
                        color='purple'
                        size='large'
                        type='dashArrow'
                      />
                    </div>

                    <div className='DuplicateMergeRecordSelection__col'>
                      <h2 className='u-marginTop-n'>{recordLabel} B</h2>
                      <div style={{ minHeight: 40 }}>
                        This {recordLabel.toLowerCase()} will be deleted after
                        its data has been merged into {recordLabel} A
                      </div>
                      {recordB ? (
                        <DuplicateMergeRecordCard
                          avatarShape={avatarShape}
                          avatarUrl={recordB.avatar}
                          fullName={recordB.name}
                          isLoading={isLoadingBaseRecord}
                          onClear={() => setRecordB(null)}
                          primaryCompanyName={recordB.company_name}
                          primaryTitle={recordB.position_name}
                        />
                      ) : (
                        <Autocomplete
                          formatOption={(option, query) =>
                            formatAutocompleteOption(avatarShape, option, query)
                          }
                          onSelect={setRecordB}
                          placeholder={`Find a ${recordLabel.toLowerCase()} by name...`}
                          size='large'
                          url={query =>
                            routes.api_v1_autocomplete_query({
                              query: {
                                query: {
                                  excluded_ids: baseRecord.id,
                                  resource: recordType,
                                  term: query,
                                },
                              },
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className='u-marginTop-32 u-flex u-flexJustify-c u-marginBottom-16'>
                    <ButtonPrimary
                      isDisabled={!isReadyToSubmit}
                      label='Continue'
                      style={{ width: 344 }}
                      url={continueButtonUrl}
                    />
                  </div>
                  {isReadyToSubmit && (
                    <p className='u-textAlign-c'>
                      (You&rsquo;ll have a chance to review before the merge is
                      complete)
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </LoadingContainer>
      </div>
    </>
  );
};

DuplicateMergeRecordSelection.propTypes = {
  avatarShape: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired,
  recordLabel: PropTypes.string.isRequired,
  recordProfileUrl: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
};

export default DuplicateMergeRecordSelection;

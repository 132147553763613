import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import CandidacyActionButtonsV2 from 'modules/candidacies/components/CandidacyActionButtonsV2';
import CandidacyListItemSectionCore from './sections/CandidacyListItemSectionCore';

/**
 * The height of this item when rendered in the list.
 * @const {Number}
 */
export const HEIGHT = 53;

/**
 * Renders the redesigned "small" candidacy list item, which is broken out into 2 parts: the "core"
 * content, and an action buttons which only show the rank button and ellipsis button.
 */
const CandidacyListItemSmallV2 = ({
  allowRanking,
  candidacyId,
  firstCandidacyId,
  height,
  lastCandidacyId,
  nextCandidacyId,
  previousCandidacyId,
  searchId,
}) => (
  <div className='CandidacyListItemSmallV2' style={{ height: height }}>
    <CandidacyListItemSectionCore
      candidacyId={candidacyId}
      className='CandidacyListItemSmallV2__core'
      searchId={searchId}
      smallCard={true}
    />
    <CandidacyActionButtonsV2
      allowRanking={allowRanking}
      candidacyId={candidacyId}
      className='CandidacyListItemSmallV2__action_buttons'
      firstCandidacyId={firstCandidacyId}
      lastCandidacyId={lastCandidacyId}
      nextCandidacyId={nextCandidacyId}
      previousCandidacyId={previousCandidacyId}
      smallCard={true}
    />
  </div>
);

CandidacyListItemSmallV2.propTypes = {
  /**
   * whether or not to enable candidate ranking
   */
  allowRanking: PropTypes.bool,

  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * ID of first candidacy in list or group
   */
  firstCandidacyId: PropTypes.number,

  /**
   * The height of the item in pixels when rendering.
   */
  height: PropTypes.number,

  /**
   * ID of last candidacy in list or group
   */
  lastCandidacyId: PropTypes.number,

  /**
   * ID of next candidacy in list or group
   */
  nextCandidacyId: PropTypes.number,

  /**
   * ID of previous candidacy in list or group
   */
  previousCandidacyId: PropTypes.number,

  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,
};

CandidacyListItemSmallV2.defaultProps = {
  height: HEIGHT,
};

CandidacyListItemSmallV2.HEIGHT = HEIGHT;

export default compose(
  setDisplayName('CandidacyListItemSmallV2(enhanced)'),
  setPropTypes({
    /**
     * The ID of the candidacy
     */
    candidacyId: PropTypes.number.isRequired,
  }),
)(CandidacyListItemSmallV2);

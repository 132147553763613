const always = () => {
  $('.js-submit-button').on('click', event => {
    const $element = $(event.target);
    $element.prop('disabled', true);
    const id = $element.data('target');
    $(id).trigger('submit');
    event.preventDefault();
  });
};

export default {
  always: always,
};

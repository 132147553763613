import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getRecruiterName from 'modules/tenant/selectors/getRecruiterName';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setPropTypes, withProps, branch } from 'recompose';
import mapCandidacyIdToContactId from 'modules/candidacies/components/mapCandidacyIdToContactId';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import mapAppointmentIdToAppointment from './mapAppointmentIdToAppointment';
import connectAppointmentActions from './connectAppointmentActions';
import connectCalendarPermissions from './connectCalendarPermissions';

/**
 * Connects meeting and interview actions for the combined Appointment form, which can
 * build either a new meeting or a new appointment.
 */
export const propTypes = {
  /**
   * The ID of the contact this meeting should be associated with. Required when creating a new
   * meeting
   */
  contactId: requiredIf(
    PropTypes.number,
    ({ meeting, meetingId }) => !(meeting || meetingId),
  ),
  /**
   * The meeting record if editing an existing meeting. Either this or `meetingId` may be
   * supplied -- it's not necessary to supply both.
   */
  meeting: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
  }),
  /**
   * The ID of an meeting to edit an existing meeting. Either this or `meeting` may be
   * supplied -- it's not necessary to supply both.
   */
  meetingId: PropTypes.number,

  searchId: PropTypes.number,
};

export const connectAppointmentForm = compose(
  setPropTypes(propTypes),
  connectAppointmentActions,
  mapAppointmentIdToAppointment,
  withProps(({ candidacyId, interview }) => ({
    candidacyId: candidacyId || interview?.get('candidacy_id'),
    hasAnswerContent: interview?.get('has_answer_content'),
  })),
  branch(({ contactId }) => !contactId, mapCandidacyIdToContactId),
  connect(
    state => ({
      // Needed for the default value of the Interview Type ('label') field.
      recruiterName: getRecruiterName(state),
    }),
    {},
  ),
  mapContactIdToContact,
  withProps(({ contact, interview, meeting }) => ({
    canClearCalendarSelection: !(interview || meeting)?.get('integration_id'),
    disableInterviewForm:
      contact && contact.get('candidacies') && !contact.get('candidacies').size,
  })),
  connectCalendarPermissions,
  connectTransactions,
);

export default connectAppointmentForm;

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import { normalize } from 'normalizr';
import routes from 'modules/routing/routes';
import createCrudActions from '../../../actions/createCrudActions';
import {
  TASKS_INVALIDATE,
  TASKS_FETCH_START,
  TASKS_FETCH_SUCCESS,
  TASKS_FETCH_FAILURE,
  TASK_CREATE_START,
  TASK_CREATE_SUCCESS,
  TASK_CREATE_FAILURE,
  TASK_UPDATE_START,
  TASK_UPDATE_SUCCESS,
  TASK_UPDATE_FAILURE,
  TASK_DELETE_START,
  TASK_DELETE_SUCCESS,
  TASK_DELETE_FAILURE,
} from './actionTypes';
import maybeDispatchNotificationsCreated from '../../../actions/notifications/maybeDispatchNotificationsCreated';
import taskSchema from '../schema';

const getUrl = ({ id, task }) => routes.api_task({ id: id || task.id });

const createSubmitPayload = ({
  task: { assigned_to, contact, ...taskProps },
  ...options
}) => ({
  task: {
    assigned_to_id: assigned_to,
    contact_id: contact,
    ...taskProps,
  },
  ...options,
});

const {
  create: createTask,
  delete: deleteTask,
  fetchAll: fetchTasks,
  invalidateAll: invalidateTasks,
  update: updateTask,
} = createCrudActions({
  getUrl: getUrl,
  schema: { task: taskSchema },
  invalidateAll: TASKS_INVALIDATE,
  fetchAll: {
    getUrl: () => routes.api_tasks(),
    start: TASKS_FETCH_START,
    success: TASKS_FETCH_SUCCESS,
    failure: TASKS_FETCH_FAILURE,
    schema: { tasks: [taskSchema] },
  },
  create: {
    getUrl: () => routes.api_tasks(),
    start: TASK_CREATE_START,
    success: TASK_CREATE_SUCCESS,
    failure: TASK_CREATE_FAILURE,
    createStartPayload: createSubmitPayload,
    createSuccessPayload: (payload, data) => ({
      // Notifications needs this!
      data: data,
      ...payload,
      ...normalize(data, { task: taskSchema }),
    }),
    afterResolveSuccess: (dispatch, action) =>
      dispatch(maybeDispatchNotificationsCreated(action, 'task')),
  },
  update: {
    start: TASK_UPDATE_START,
    success: TASK_UPDATE_SUCCESS,
    failure: TASK_UPDATE_FAILURE,
    createStartPayload: createSubmitPayload,
  },
  delete: {
    start: TASK_DELETE_START,
    success: TASK_DELETE_SUCCESS,
    failure: TASK_DELETE_FAILURE,
    schema: null,
  },
});

export { fetchTasks, invalidateTasks, createTask, updateTask, deleteTask };

import { compose, setDisplayName, withHandlers } from 'recompose';

export default compose(
  setDisplayName('withNestedFieldChangeHandler'),
  withHandlers({
    handleNestedFieldChange: ({ fieldState, onChange }) => nestedField => {
      onChange(fieldState.setNestedField(nestedField));
    },
  }),
);

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import TimeLabel from 'modules/datetime/components/TimeLabel';
import Icon from '@thrivetrm/ui/components/Icon';
import SearchTitles from './SearchTitles';

const isLegacy = notification => notification.key === 'legacy';

const Notification = ({ children, introMessage, notification }) => {
  const itemClasses = {
    'notification-item': true,
    'notification-item--is-legacy': isLegacy(notification),
    [`notification-item--type-${notification.type}`]: true,
  };

  const renderIcon = () => {
    const { icon } = notification;
    return icon === 'bell-o' ? (
      <i className={classnames('fa', 'fa-fw', 'fa-2x', `fa-${icon}}`)} />
    ) : (
      <Icon size='large' type={icon} />
    );
  };
  const message = introMessage || notification.message;
  const interviewCreatedByName =
    notification.type === 'interview' && notification.resource.created_by.name;

  const search = notification.search || notification.resource.search;
  const { searches } = notification.resource;
  const showRelatedSearches = search || Boolean(searches && searches.length);

  const notificationDate =
    notification.resource_date ||
    notification.assessed_on ||
    notification.created_at;

  return (
    <div className={classnames(itemClasses)}>
      <div className='notification-icon'>{renderIcon()}</div>
      <div className='notification-content'>
        <div className='notification-heading list-group-item-heading'>
          <h4 className='notification-title u-marginBottom-n'>{message}</h4>
          {interviewCreatedByName && (
            <div className='notification-organizer-name'>
              Organized by: {interviewCreatedByName}
            </div>
          )}
          {showRelatedSearches && (
            <SearchTitles
              notification={notification}
              search={search}
              searches={searches}
              searchLinks={notification?.resource?.links}
            />
          )}
          <small className='notification-timestamp'>
            <TimeLabel
              adjustTime={6000}
              data-placement='right'
              data-toggle='tooltip'
              time={notificationDate}
            />
          </small>
        </div>
        <div className='notification-details'>{children}</div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  children: PropTypes.node,
  introMessage: PropTypes.node,

  notification: PropTypes.shape({
    assessed_on: PropTypes.string,
    created_at: PropTypes.string,

    icon: PropTypes.string,
    message: PropTypes.string,
    resource: PropTypes.shape({
      created_by: PropTypes.shape({
        name: PropTypes.string,
      }),
      links: PropTypes.shape({
        self: PropTypes.string,
      }),
      search: PropTypes.shape({
        client_company: PropTypes.shape({
          name: PropTypes.string,
        }),
        job_title: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
      }),
      searches: PropTypes.arrayOf(
        PropTypes.shape({
          client_company: PropTypes.shape({
            name: PropTypes.string,
          }),
          job_title: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }),
    resource_date: PropTypes.string,
    search: PropTypes.shape({
      client_company: PropTypes.shape({
        name: PropTypes.string,
      }),
      job_title: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
};

export default Notification;

import deleteEntity from 'modules/entities/actions/deleteEntity';
import routes from 'modules/routing/routes';
import documentSchema from '../schema';

/**
 * Creates an action for deleting a document
 * @param {Object} payload
 * @param {Number} payload.id The document ID.
 */
export default ({ id, uuid, ...rest }) =>
  deleteEntity({
    ...rest,
    id: id,
    uuid: uuid,
    entityType: documentSchema.key,
    url: routes.api_v1_document({ uuid: uuid }),
  });

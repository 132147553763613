import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

/**
 * A group of buttons which can be used as radio toggles.
 * This component acts as a controlled component
 * (@see https://facebook.github.io/react/docs/forms.html#controlled-components),
 * so you must handle the `onChange` event and update the `value` property
 * when the selection is changed.
 */
const RadioButtonGroup = ({
  bsSize,
  disabled,
  isJustified,
  onChange,
  options,
  selectedClass,
  unselectedClass,
  value,
  ...props
}) => (
  <div
    className={classnames('btn-toggle-group', 'btn-group', {
      'btn-group-justified': isJustified,
    })}
    role='group'
    {...props}
  >
    {Object.keys(options).map(key => (
      <div
        className={classnames('btn-group', {
          [`btn-group-${bsSize}`]: bsSize,
        })}
        key={key}
        role='group'
      >
        <button
          className={classnames('btn', {
            [selectedClass]: value === key,
            [unselectedClass]: value !== key,
            'btn-active': value === key,
            'btn-inactive': value !== key,
          })}
          disabled={disabled}
          onClick={onChange}
          type='button'
          value={key}
        >
          {options[key]}
        </button>
      </div>
    ))}
  </div>
);

RadioButtonGroup.defaultProps = {
  bsSize: null,
  disabled: false,
  isJustified: true,
  onChange: null,
  selectedClass: 'btn-secondary',
  unselectedClass: 'btn-secondary-outline',
  value: null,
};

RadioButtonGroup.propTypes = {
  /**
   * An optional size specification to be used for the buttons.
   */
  bsSize: PropTypes.oneOf(['lg', 'sm', 'xs']),

  /**
   * True to disable user interaction with this component
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  disabled: PropTypes.bool,

  /**
   * Determines if the button group should be justified ("full width") or not.
   * All buttons will be the sam size when this is true.
   */
  isJustified: PropTypes.bool,

  /**
   * A function that will be called when the selection has been changed. This
   * is called with a single event, whose `currentTarget` property is the
   * button which was selected. The value that was selected (which will be the
   * key value provided in the `options` object) can be obtained using
   * `event.currentTarget.value`.
   */
  onChange: PropTypes.func,

  /**
   * The options to be used. This should be an object mapping a value to a
   * label. The label may be anything that can be rendered.
   */
  options: PropTypes.objectOf(PropTypes.node).isRequired,

  /**
   * The class to apply to the selected button.
   */
  selectedClass: PropTypes.string,

  /**
   * The class to apply to unselected buttons.
   */
  unselectedClass: PropTypes.string,

  /**
   * The selected value, which should match one of the keys provided by `options`.
   */
  value: PropTypes.string,
};

export default RadioButtonGroup;

import { TENANT_TYPE_VENTURE_CAPITAL } from 'modules/tenant/constants';

//---------------------------------------------------------
// Module name
//---------------------------------------------------------

export const NAME = 'company-portfolio-details';

//---------------------------------------------------------
// Tenant types that have portfolio details enabled.
//---------------------------------------------------------
export const FEATURE_ENABLED_TENANT_TYPES = [TENANT_TYPE_VENTURE_CAPITAL];

//---------------------------------------------------------
// Portfolio stages
//---------------------------------------------------------

// NOTE: These MUST be the same as the value returned as the company's "off_limit_status"
export const STAGE_PIPELINE = 'Pipeline Company';
export const STAGE_PORTFOLIO = 'Portfolio Company';
export const STAGE_EXITED = 'Portfolio Company (Exited)';

export const STAGES = [STAGE_PIPELINE, STAGE_PORTFOLIO, STAGE_EXITED];

import getContactProperty from './getContactProperty';

/**
 * Gets the talent pool ID to which the contact is the incumbent.
 * @param {*} state
 * @param {Number} id The contact ID
 * @returns {?Number} The ID of the talent pool for which the contact specified is the incumbent,
 *   if it is defined and has been fetched.
 */
export default (state, id) => getContactProperty(state, id, 'talent_pool');

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import CreatableSelect from 'react-select/creatable';
import SegmentedControl from '@thrivetrm/ui/components/SegmentedControl';
import { useSelector, useDispatch } from 'react-redux';
import { stringToCamelCase } from 'modules/core/jsonUtils';
import {
  registerFilterInput,
  setAndValidateSingleFilterValue,
  selectFilterInputData,
  requestRecordList,
} from '../../recordIndexSlice';
import { EMPTY_STYLES } from './common-react-select-constants';

const MultiValueTextBooleanFilter = ({ label, name, onChange }) => {
  const dispatch = useDispatch();

  const hasRegisteredFilterInput = useSelector(state =>
    Boolean(state.recordIndex?.savedView?.filters?.[stringToCamelCase(name)]),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  const filterInputValues = useSelector(
    state => state.recordIndex?.savedView?.filters?.[name]?.values || [],
  );
  const filterInputOperator = useSelector(
    state => state.recordIndex?.savedView?.filters?.[name]?.operator || 'OR',
  );
  const { placeholder } = useSelector(state =>
    selectFilterInputData(state, name),
  );

  const [inputValue, setInputValue] = useState('');

  const updateValueAndFetchData = () => {
    const nextValues = [...(filterInputValues || []), inputValue];

    setInputValue('');
    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: {
          values: nextValues || [],
          operator: filterInputOperator,
        },
      }),
    );
    dispatch(requestRecordList());
  };

  const handleKeyDown = event => {
    if (!inputValue) return;
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      updateValueAndFetchData();
    }
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-blur' && inputValue) {
      updateValueAndFetchData();
    } else if (action !== 'menu-close') {
      setInputValue(newValue);
    }
  };

  const handleChange = newValue => {
    const normalizedValue = newValue.map(option => option.value);

    if (onChange) onChange(newValue);

    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: {
          values: normalizedValue,
          operator: filterInputOperator,
        },
      }),
    );
    return dispatch(requestRecordList());
  };

  const handleToggle = newBooleanOperator => {
    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: {
          values: filterInputValues,
          operator: newBooleanOperator,
        },
      }),
    );
    return dispatch(requestRecordList());
  };

  // Wait for saved view to be seeded before rendering
  if (!hasRegisteredFilterInput) return null;

  return (
    <div>
      {label && (
        <label className='MultiValueTextBooleanFilter__label'>{label}</label>
      )}
      <div className='MultiValueTextBooleanFilter'>
        <CreatableSelect
          className='MultiValueTextBooleanFilter__creatableContainer'
          classNamePrefix='MultiValueTextBooleanFilter'
          components={{
            DropdownIndicator: null,
          }}
          inputValue={inputValue}
          isClearable={false}
          isMulti={true}
          menuIsOpen={false}
          onChange={handleChange}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder || 'Search by keywords...'}
          styles={EMPTY_STYLES}
          value={filterInputValues?.map(val => ({
            label: val,
            value: val,
          }))}
        />
        <SegmentedControl
          className='MultiValueTextBooleanFilter__booleanOperator'
          onChange={handleToggle}
          segments={['AND', 'OR']}
          selectedSegmentLabel={filterInputOperator}
        />
      </div>
    </div>
  );
};

MultiValueTextBooleanFilter.defaultProps = {
  label: null,
  onChange: null,
};

MultiValueTextBooleanFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default MultiValueTextBooleanFilter;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import { connect } from 'react-redux';
import CompanyLogo from 'modules/companies/components/logo/CompanyLogo';
import CompanyName from 'modules/companies/components/CompanyName';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import withSearchFetched from 'modules/searches/components/withSearchFetched';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';

/**
 * The header portion of the main summary sidebar content. Displays the company
 * logo and name, job title for the search, and the expand/collapse button (if allowed).
 */
const SearchPageSidebarHeader = ({ employeeUser, search }) =>
  !search || (!search.get('id') && search.getIn(['_meta', 'isFetching'])) ? (
    <header className='SearchPageSidebarHeader'>
      <LoadingIndicator />
    </header>
  ) : (
    <header className='SearchPageSidebarHeader'>
      {search.get('client_company') && (
        <CompanyLogo
          companyId={search.get('client_company')}
          thumbnail={true}
        />
      )}
      <div className='SearchPageSidebarHeader__text'>
        <h4
          className='u-marginVertical-n'
          data-testid='search-sidebar-company-name'
        >
          {search.get('client_company') && (
            <CompanyName
              companyId={search.get('client_company')}
              linkToCompany={employeeUser}
            />
          )}
        </h4>
        <h4
          className='u-marginVertical-n u-textColor-gray50'
          data-testid='search-sidebar-job-title'
        >
          {search.get('confidential')
            ? search.get('code_name')
            : search.get('job_title')}
        </h4>
      </div>
    </header>
  );

SearchPageSidebarHeader.propTypes = {
  /**
   * The employeeUser for the check user Acecss type.
   */
  employeeUser: PropTypes.bool,

  /**
   * The record for the search we're currently displaying.
   */
  search: ImmutablePropTypes.mapContains({
    client_company: PropTypes.number,
    confidential: PropTypes.bool,
    code_name: PropTypes.string,
    job_title: PropTypes.string,
  }),
};

export default compose(
  setDisplayName('SearchPageSidebarHeader(enhanced)'),
  setPropTypes({
    /**
     * The ID of the search to display.
     */
    searchId: PropTypes.number.isRequired,
  }),
  connect(state => ({
    employeeUser: isEmployeeUser(state),
  })),
  withSearchFetched,
)(SearchPageSidebarHeader);

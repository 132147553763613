import isClientOrExternalRecruiterUser from 'modules/auth/selectors/isClientOrExternalRecruiterUser';

/**
 * Gets a value indicating whether the current user has permission to change a candidacy's
 * stage
 * @param {Object} state
 * @return {Boolean} True if the current user is allowed to move a candidacy to a different stage;
 *   otherwise, false.
 */
export default state => !isClientOrExternalRecruiterUser(state);

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import Modal from 'modules/core/componentsLegacy/Modal';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import EmailTemplateField from './EmailTemplateField';
import withEmailTemplateForm from './withEmailTemplateForm';

/**
 * Renders the ContactForm in a modal window for creating (or editing?) a contact.
 */
const EmailTemplateFormModal = ({
  // props provided by `withEmailTemplateForm`
  formState,
  onClose,
  onFieldStateChange,

  // Props passed through/expected from parent component.
  onSubmit,
  show,
  submitButtonLabel,
  title,

  // Any other props are passed through to the EmailTemplateField component.
  ...fieldProps
}) => (
  <Modal onHide={onClose} show={show}>
    <Form formState={formState} onSubmit={onSubmit}>
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EmailTemplateField
          {...fieldProps}
          disabled={formState.isSubmitting()}
          fieldState={formState.getFieldState()}
          onChange={onFieldStateChange}
          showErrors={formState.wasSubmitted() || 'blurred'}
        />
        <FormErrorMessage formState={formState} />
      </Modal.Body>
      <Modal.Footer>
        <CancelButton bsSize='lg' onClick={onClose} />
        <SubmitButton bsSize='lg' formState={formState} onClick={onSubmit}>
          {submitButtonLabel}
        </SubmitButton>
      </Modal.Footer>
    </Form>
  </Modal>
);

EmailTemplateFormModal.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the modal should be closed.
   */
  onClose: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * True if the modal should be rendered/displayed, otherwise false.
   */
  show: PropTypes.bool,

  /**
   * The label for the submit button.
   */
  submitButtonLabel: PropTypes.node,

  /**
   * The text to display in the modal header.
   */
  title: PropTypes.node,
};

EmailTemplateFormModal.defaultProps = {
  show: false,
  submitButtonLabel: 'Save',
  title: 'Email Template',
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('EmailTemplateFormModal(enhanced))'),
  setPropTypes({
    /**
     * The ID of an existing email template to be edited (without this, the form will be used for
     * creating a new email template)
     */
    emailTemplateId: PropTypes.number,

    /**
     * Called when the modal should be closed. If the modal was closed because the form was
     * successfully saved, the first argument will be the ID of the new or updated email template.
     */
    onClose: PropTypes.func.isRequired,

    /**
     * True to show the modal, false to hide it.
     */
    show: PropTypes.bool.isRequired,
  }),

  // withEmailTemplateForm will call onSaved when the form has been successfully submitted/saved -
  // we map that directly to the incoming `onClose` prop which will cause the modal to be
  // closed.
  withProps(({ onClose }) => ({
    onSaved: onClose,
  })),

  withEmailTemplateForm,

  /**
   * Prevent props from being passed on to the modal
   */
  /* eslint-disable no-unused-vars */
  mapProps(
    ({
      emailTemplateId,
      isNewRecord,
      onFormStateChange,
      onResetFormState,
      onSaved,
      ...rest
    }) => ({
      title: isNewRecord ? 'Add a Template' : 'Edit Template',
      submitButtonLabel: isNewRecord ? 'Create' : 'Update',
      ...rest,
    }),
  ),
  /* eslint-enable no-unused-vars */
)(EmailTemplateFormModal);

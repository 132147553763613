import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setPropTypes } from 'recompose';

import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import withTargetCompanyCommentListFetched from './withTargetCompanyCommentListFetched';
import TargetCompanyCommentList from './TargetCompanyCommentList';

export const TargetCompanyCommentsView = ({ targetCompanyCommentList }) => {
  const isFetching =
    targetCompanyCommentList &&
    targetCompanyCommentList.getIn(['_meta', 'isFetching']);
  const hasError =
    targetCompanyCommentList &&
    Boolean(targetCompanyCommentList.getIn(['_meta', 'error']));

  return (
    <>
      {hasError && !isFetching && (
        <ErrorAlert title='There was an error fetching comments' />
      )}
      {targetCompanyCommentList && targetCompanyCommentList.get('ids') && (
        <TargetCompanyCommentList
          targetCompanyCommentIds={targetCompanyCommentList.get('ids')}
        />
      )}
      {isFetching && <LoadingIndicator />}
    </>
  );
};

TargetCompanyCommentsView.propTypes = {
  targetCompanyCommentList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),
};

export default compose(
  setPropTypes({
    targetCompanyId: PropTypes.number.isRequired,
  }),
  withTargetCompanyCommentListFetched,
)(TargetCompanyCommentsView);

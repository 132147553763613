const notify = require('../lib/notify').default;
const initAutocomplete = require('../lib/select2_autocomplete');

exports.initialize = () => {
  const introName = '#introduced_to_name';
  const introducedToID = '#log-introduction introduced_to_id';
  // eslint-disable-next-line consistent-return
  $(introName).on('change', () => {
    if (!$(this).val()) {
      return $(introducedToID).removeAttr('value');
    }
  });

  // eslint-disable-next-line func-names
  $('.edit-introduction-link').on('click', function (e) {
    const introduction = $(this).parents('.introduction');
    const show = $(introduction).find('.introduction-show');
    const edit = $(introduction).find('.introduction-edit');
    show.toggle();
    edit.toggle();
    return e.preventDefault();
  });

  $('body').on('click', '.view-note-toggle', e => {
    $(this).siblings('.notes').toggle();
    return e.preventDefault();
  });

  return initAutocomplete('.js-introduction-related-to-select');
};

exports.update = (id, partial, errors) => {
  $(`#introduction-${id}`).replaceWith(partial);

  const introduction = $(`#introduction-${id}`);

  const show = introduction.find('.introduction-show');
  const edit = introduction.find('.introduction-edit');

  // eslint-disable-next-line no-restricted-syntax
  for (const error of Array.from(errors)) {
    notify(error, 'error');
  }

  if (errors.length) {
    show.hide();
    edit.show();
  }

  introduction.find('.edit-introduction-link').on('click', e => {
    show.toggle();
    edit.toggle();
    return e.preventDefault();
  });

  return initAutocomplete('.js-introduction-related-to-select');
};

import PropTypes from 'prop-types';
import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import AppointmentForm from 'modules/appointments/components/AppointmentForm';
import * as appointmentConstants from 'modules/appointments/constants';
import MeetingView from 'modules/meetings/components/MeetingView';
import connectMeetingActions from 'modules/meetings/components/connectMeetingActions';

import Notification from './Notification';

/**
 * Renders a notification with a resource type of `meeting`
 */
const NotificationItemTypeMeeting = ({
  allowDelete,
  allowEdit,
  handleCancel,
  handleChange,
  handleDelete,
  handleEdit,
  handleSaved,
  isEditing,
  isSendingUpdateToAttendees,
  notification,
  ...props
}) => {
  const meeting = notification.getIn(['data', 'resource']);
  const meetingId = meeting.getIn(['data', 'id']);
  const contactId = meeting.getIn(['data', 'contact_id']);
  const canClearCalendarSelection = !meeting.getIn(['data', 'integration_id']);

  const getSubmitLabel = () => {
    if (!canClearCalendarSelection || isSendingUpdateToAttendees) {
      return 'Update Invite';
    }
    return 'Update Meeting';
  };

  return (
    <Notification
      canDelete={
        allowDelete && meeting.getIn(['data', 'permissions', 'delete'])
      }
      canEdit={allowEdit && meeting.getIn(['data', 'permissions', 'edit'])}
      notification={notification}
      onDelete={handleDelete}
      onEdit={handleEdit}
      {...props}
    >
      {isEditing ? (
        <AppointmentForm
          asRichTextForm={true}
          canClearCalendarSelection={canClearCalendarSelection}
          draftStoragePath={{
            contact: contactId,
            meeting: meetingId,
          }}
          errorDisplay='tooltip'
          fieldLayout='wide'
          formActionProps={{
            submitButtonIcon: 'fa-send u-marginRight-4',
            submitLabel: getSubmitLabel(),
            fillWidth: false,
          }}
          isEditing={isEditing}
          meetingId={meetingId}
          onCancel={handleCancel}
          onChange={handleChange}
          onSaved={handleSaved}
          permittedTypes={appointmentConstants.MEETING_PERMITTED_TYPES}
        />
      ) : (
        <MeetingView
          attendees={meeting.getIn(['data', 'attendees']).toJS()}
          meetingId={meetingId}
        />
      )}
    </Notification>
  );
};

NotificationItemTypeMeeting.propTypes = {
  /**
   * True to allow the meeting item to be deleted if the user is permitted.
   */
  allowDelete: PropTypes.bool,

  /**
   * True to allow the meeting item to be edited inline if the user is permitted.
   */
  allowEdit: PropTypes.bool,

  handleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,

  handleDelete: PropTypes.func.isRequired,

  handleEdit: PropTypes.func.isRequired,

  handleSaved: PropTypes.func.isRequired,
  /**
   * True to show the meeting form, false to show the meeting view.
   */
  isEditing: PropTypes.bool,

  /**
   * True when an integrated calendar has been selected and udpates will be sent to all attendees
   */
  isSendingUpdateToAttendees: PropTypes.bool,

  meetingActions: requiredIf(
    PropTypes.shape({
      deleteMeeting: PropTypes.func,
    }),
    props => props.allowEdit || props.allowDelete,
  ),
  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      type: PropTypes.oneOf(['meeting']),
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          id: PropTypes.number.isRequired,
        }),
      }),
    }),
  }).isRequired,
};

export default compose(
  withState('isEditing', 'setEditing', false),
  withState(
    'isSendingUpdateToAttendees',
    'setIsSendingUpdateToAttendees',
    false,
  ),
  withHandlers({
    handleEdit: ({ setEditing }) => () => setEditing(true),
    handleCancel: ({ setEditing }) => () => setEditing(false),
    handleChange: ({ setIsSendingUpdateToAttendees }) => formState =>
      setIsSendingUpdateToAttendees(
        Boolean(formState.getFieldValue().integration_id),
      ),
    handleSaved: ({ setEditing }) => () => setEditing(false),
  }),
  connectMeetingActions,
  withHandlers({
    handleDelete: ({ meetingActions, notification }) => () => {
      const meeting = notification.getIn(['data', 'resource', 'data']);
      meetingActions.deleteMeeting({
        contactId: meeting.get('contact_id'),
        id: meeting.get('id'),
      });
    },
  }),
)(NotificationItemTypeMeeting);

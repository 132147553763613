import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import emailTemplateSchema from '../schema';

/**
 * Creates an action for updating an email template.
 * @param {Object} payload
 * @param {Object} payload.emailTemplate The email template to update
 * @param {Number} payload.emailTemplate.id The ID of the email template to update
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ emailTemplate, transactionId }) =>
  updateEntity({
    body: { email_template: emailTemplate },
    transactionId: transactionId,
    entityType: emailTemplateSchema.key,
    responseSchema: { email_template: emailTemplateSchema },
    url: routes.api_v1_email_template({ id: emailTemplate.id }),
  });

import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import getUserDisplayName from 'modules/users/selectors/getUserDisplayName';
import smartTagSchema from '../schemas/smartTag';
import getTagProperty from './getTagProperty';

/**
 * Gets a string of text for filtering a tag by a keyword
 * Currently includes:
 * - The tag name
 * - The name of the user who created the tag.
 * @param {Object} state
 * @param {Number} tagId The ID of the tag to be filtered.
 * @return {String} A string of joined fields that can be filtered against.
 */
export default (state, tagId, filterByCreatorName, searchType) => {
  const tagName =
    searchType === TYPE_PIPELINE
      ? getEntityProperty(state, smartTagSchema.key, tagId, 'name')
      : getTagProperty(state, tagId, 'name');

  if (filterByCreatorName) {
    return stringUtils.toSortableString(
      [
        tagName,
        getUserDisplayName(state, getTagProperty(state, tagId, 'created_by')),
      ].join(' '),
    );
  }

  return stringUtils.toSortableString(tagName);
};

import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import withCompanyFetched from 'modules/companies/components/withCompanyFetched';
import Currency from '@thrivetrm/ui/components/Currency';

/**
 * Renders the total of all the funding rounds for a company in a currency format.
 */
export default compose(
  setDisplayName('CompanyFundingRoundsTotalAmount'),
  setPropTypes({
    /**
     * The company to render the total funding amount for.
     */
    companyId: PropTypes.number.isRequired,
  }),

  // Make sure the company is fetched and up-to-date.
  withCompanyFetched,

  // Don't render anything at all if we don't have a value yet.
  branch(
    ({ company }) => !company || !company.has('total_funding_amount'),
    renderNothing,
  ),

  // Just pass the amount to the `Currency` component's `value` prop.
  mapProps(({ company }) => ({
    value: company && company.get('total_funding_amount'),
  })),
)(Currency);

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import jobApplicationSchema from '../schema';

/**
 * Creates an action for updating a job application.
 * @param {Object} payload
 * @param {Object} payload.job_application The job application to update
 * @param {Number} payload.job_application.id The ID of the job application to updat.
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ job_application, transactionId }) =>
  updateEntity({
    body: { job_application: job_application },
    transactionId: transactionId,
    entityType: jobApplicationSchema.key,
    responseSchema: { job_application: jobApplicationSchema },
    url: routes.api_v1_job_application({ id: job_application.id }),
  });

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import { compose, setDisplayName } from 'recompose';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import updateRecommendationAction from '../../actions/updateRecommendation';

/**
 *
 * Link to trigger the popover with options Add to Search or Move to Not a Fit
 *
 */
const RecommendPopoverIconLink = ({
  candidacyId,
  componentId,
  getScrollContainer,
  getTargetRef,
  notAFit,
  onClick: _onClick,
  searchId,
  setTargetRef,
  updateRecommendation,
  ...otherProps
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleClick = () => setIsPopoverOpen(!isPopoverOpen);
  const handlePopoverCancel = () => setIsPopoverOpen(false);

  const handleMoveRecommendation = (e, isMovetoSearch) => {
    updateRecommendation({
      id: candidacyId,
      searchId: searchId,
      isMovetoSearch: isMovetoSearch,
      isMoveToRecommendations: notAFit,
    });
    handlePopoverCancel();

    return preventDefaultHandler(e);
  };

  return (
    <span className='RecommendPopoverIconLink' ref={setTargetRef}>
      <button
        className='RecommendationListItem__shareBtn btn-default btn btn-toggle'
        {...otherProps}
        onClick={handleClick}
        type='button'
      >
        <i className='fa fa-share' />
      </button>
      <Overlay
        container={getScrollContainer}
        containerPadding={20}
        onHide={handlePopoverCancel}
        placement='bottom'
        rootClose={true}
        show={isPopoverOpen}
        target={getTargetRef}
      >
        <Popover className='RecommendationListItem__moveStage' id={componentId}>
          <div className='SearchStageListMenuItem'>
            {notAFit ? (
              <button
                className='SearchStageListMenuItem__Button'
                onClick={event => handleMoveRecommendation(event, false)}
                type='button'
              >
                Move to Recommendations
              </button>
            ) : (
              <span>
                <button
                  className='SearchStageListMenuItem__Button'
                  onClick={event => handleMoveRecommendation(event, true)}
                  type='button'
                >
                  Add To Search
                </button>
                <button
                  className='SearchStageListMenuItem__Button'
                  onClick={event => handleMoveRecommendation(event, false)}
                  type='button'
                >
                  Mark as Not a Fit
                </button>
              </span>
            )}
          </div>
        </Popover>
      </Overlay>
    </span>
  );
};

RecommendPopoverIconLink.propTypes = {
  ...withScrollContainerRef.propTypes,
  candidacyId: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,
  searchId: PropTypes.string.isRequired,
  setTargetRef: PropTypes.func.isRequired,
  updateRecommendation: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('RecommendPopoverIconLink(enhanced)'),
  withComponentId(),
  withScrollContainerRef,
  connect(null, {
    updateRecommendation: updateRecommendationAction,
  }),
)(RecommendPopoverIconLink);

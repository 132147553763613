import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import ImmutablePropTypes from 'react-immutable-proptypes';

import CandidacyStatusContainer from './CandidacyStatusContainer';
import SearchCandidacyLink from '../SearchCandidacyLink';

/**
 * The candidacy status that should be rendered when the
 * status type is 'interview'
 */
const CandidacyStatusInterview = ({
  candidacyId,
  candidacyStatus,
  timezone,
}) => {
  const startMoment =
    timezone &&
    candidacyStatus.get('start_time') &&
    moment(candidacyStatus.get('start_time'), moment.ISO_8601).tz(timezone);

  return (
    <CandidacyStatusContainer className='CandidacyStatusInterview'>
      <SearchCandidacyLink candidacyId={candidacyId} tab='interviews'>
        <i className='CandidacyStatusContainer__Icon fa fa-calendar' />
        <span>
          {candidacyStatus.get('description')}
          {startMoment &&
            startMoment.isValid() &&
            startMoment.format('[ on] MM/DD/YYYY [at] LT z')}
        </span>
      </SearchCandidacyLink>
    </CandidacyStatusContainer>
  );
};

CandidacyStatusInterview.propTypes = {
  candidacyId: PropTypes.number.isRequired,

  candidacyStatus: ImmutablePropTypes.mapContains({
    description: PropTypes.string.isRequired,

    // This should only be used when type is `interview`!
    type: PropTypes.oneOf(['interview']).isRequired,
  }).isRequired,

  timezone: PropTypes.string,
};

export default CandidacyStatusInterview;

export default (event, $modal, errors, parent) => {
  $modal.find('.has-error').removeClass('has-error');
  $modal.find('.help-block').text('');

  if (event && Object.keys(errors).length) {
    event.preventDefault();
  }

  return (() => {
    const result = [];

    /* eslint-disable no-restricted-syntax */
    for (const selector of Object.keys(errors || {})) {
      const elementErrors = errors[selector];
      const container = parent
        ? $modal.find(selector).closest(parent)
        : $modal.find(selector).parent();
      container.addClass('has-error');
      container.find('.help-block').text(elementErrors.join(', '));
      result.push(container.parent().collapse('show'));
    }
    return result;
  })();
};

import PropTypes from 'prop-types';
import { compose, defaultProps } from 'recompose';
import React from 'react';
import { useLocation } from 'react-router-dom';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
/**
 * A secialized loading indicator just for the candidacy list.
 * Enahnaces the standard LoadingIndicator with a some default props.
 */
const CandidacyListLoadingIndicator = ({ size }) => {
  const location = useLocation();
  const type = location.pathname.split('/')[3];
  const message = type === 'people' ? 'Loading People' : 'Loading Candidates';

  return <LoadingIndicator message={message} size={size} />;
};

CandidacyListLoadingIndicator.propTypes = {
  size: PropTypes.number.isRequired,
};

export default compose(
  defaultProps({
    size: 64,
  }),
)(CandidacyListLoadingIndicator);

import { connect } from 'react-redux';
import getTargetCompanyComment from '../selectors/comments/getTargetCompanyComment';

/**
 * A higher order component which maps a `targetCompanyCommentId` prop value to it's target
 * company comment record as `targetCompanyComment`.
 */
export default connect((state, { targetCompanyCommentId }) => ({
  targetCompanyComment: getTargetCompanyComment(state, targetCompanyCommentId),
}));

import map from 'lodash/map';
import c3 from 'c3';
import { format } from 'd3-format';
import { shortMonth, longMonth } from './date_helpers';
import { toColumns, generateTicksForColumns } from './c3_helpers';
import { chartTitle, currencySymbol } from './commission_helpers';

const makeTooltip = function (allData, currency, view) {
  const groupedFixedPointFormat = format(',.2f');

  return tipData => {
    const d = allData[tipData[0].index];
    const year = d.year_month.slice(0, 4);
    const month = longMonth[d.year_month.slice(5)];
    const amount =
      view === 'outstanding'
        ? groupedFixedPointFormat(d.commissions_outstanding)
        : groupedFixedPointFormat(d.commissions_paid);
    return `
      <div><b>${month} ${year}</b></div><br>
      <div><b>Commissions ${chartTitle(view)}</b></div>
      <div>${currencySymbol(currency)}${amount}</div><br>
    `;
  };
};

const getMonth = i => shortMonth[i.year_month.slice(5)];

const myCommissionsChart = function (data, el) {
  let columns;
  const categories = map(data, getMonth);

  if ($(el).data('view') === 'outstanding') {
    columns = toColumns(
      data,
      'commissions_outstanding',
      'Commissions Outstanding',
    );
  } else {
    columns = toColumns(data, 'commissions_paid', 'Commissions Paid');
  }

  const ticks = generateTicksForColumns(columns, 6);

  return c3.generate({
    bindto: el,
    data: {
      columns: [columns],
      type: 'bar',
    },
    axis: {
      y: {
        padding: {
          top: 0,
          bottom: 0,
        },
        tick: {
          format: format('.2s'),
          count: 7,
          values: ticks,
        },
      },
      x: {
        categories: categories,
        type: 'category',
      },
    },
    bar: {
      width: {
        ratio: 0.5,
      },
    },
    grid: {
      y: {
        show: true,
      },
      focus: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      contents: makeTooltip(data, $(el).data('currency'), $(el).data('view')),
    },
  });
};

export default myCommissionsChart;

import routes from 'modules/routing/routes';
import { createPostAction } from '../../../actions/createAjaxAction';
import {
  REPORT_CREATE_START,
  REPORT_CREATE_SUCCESS,
  REPORT_CREATE_FAILURE,
} from './actionTypes';

export const createReport = createPostAction({
  getUrl: routes.api_v1_reports,
  start: REPORT_CREATE_START,
  success: REPORT_CREATE_SUCCESS,
  failure: REPORT_CREATE_FAILURE,
});

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose, setDisplayName, setPropTypes, mapProps } from 'recompose';
import { connect } from 'react-redux';
import Modal from 'modules/core/componentsLegacy/Modal';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import getSearchAttributes from 'modules/assessments/selectors/getSearchAttributes';
import getCandidacySearchId from 'modules/candidacies/selectors/getCandidacySearchId';
import isCandidacyRejected from 'modules/candidacies/selectors/isCandidacyRejected';
import canViewCurrentCompensationSelector from 'modules/compensations/selectors/canViewCurrentCompensation';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_JOB_SEARCH } from 'modules/searches/constants';
/**
 * Displayed when no fields are selected.
 * @type {String}
 */
const ERROR_MESSAGE = 'Please select at least 1 item';

/**
 * The value that indicates the landscape direction is selected.
 */
export const LANDSCAPE_LAYOUT = 'landscape';

/**
 * The value that indicates the portrait direction is selected.
 */
export const PORTRAIT_LAYOUT = 'portrait';

/**
 * A model used for generating a candidacy summary report, allowing the user to select
 * the various fields to be used in the report.
 */
class CandidacySummaryReportModal extends PureComponent {
  state = {
    /**
     * The IDs of the currently selected fields.
     * @type {Array.<String>}
     */
    selectedFieldIds: this.getInitiallySelectedIds(),
    selectedLayout: PORTRAIT_LAYOUT,
    error: ERROR_MESSAGE,
    touched: false,
  };

  _componentId = uniqueId();

  /**
   * Gets the options that should be visible to the user.
   */
  getFilteredOptions() {
    const { availableFields } = this.props;
    return availableFields.filter(option => option.isVisible !== false);
  }

  /**
   * Gets the IDs of the fields that should be initially selected when the modal is first shown.
   */
  getInitiallySelectedIds() {
    return this.getFilteredOptions()
      .filter(option => option.isDefault)
      .map(option => option.id);
  }

  /**
   * Gets the URL that will generate the report using the currently selected options.
   */
  getSummaryHref() {
    const { candidacyId, searchId } = this.props;
    const { selectedFieldIds, selectedLayout } = this.state;
    selectedFieldIds.push(selectedLayout);
    const options = selectedFieldIds.map(field => `${field}=on`);
    return (
      `/searches/${searchId}` +
      `/candidates/${candidacyId}` +
      `/summary.pdf?utf8=✓&${options.join('&')}`
    );
  }

  /**
   * Takes an array (of field ids) and an index, and returns a new array without
   * that id.
   */
  removeAtIndex = (array, index) =>
    array.slice(0, index).concat(array.slice(index + 1));

  /**
   * Called when one of the options is clicked, causing it to be toggled on or off.
   */
  handleOptionClick = event => {
    const { selectedFieldIds } = this.state;
    const { value } = event.currentTarget;
    const index = selectedFieldIds.indexOf(value);
    let newFieldIds;

    if (index >= 0) {
      // The index within the selected field IDs, meaning that this option is currently selected,
      // but should be unselected.
      newFieldIds = this.removeAtIndex(selectedFieldIds, index);
    } else {
      // Not currently selected, add the value (field ID) to the currently selected fields.
      newFieldIds = selectedFieldIds.concat(value);
    }
    this.setState(
      {
        selectedFieldIds: newFieldIds,
      },
      this.validate(newFieldIds),
    );
  };

  /**
   * Called when user changes layout option.
   */
  onLayoutChange = event => {
    const { value } = event.currentTarget;
    this.setState({
      selectedLayout: value,
    });
  };

  /**
   * Called when the select all option is clicked, toggling all fields on or off.
   */
  handleSelectAllClick = event => {
    let newFieldIds;
    const allFieldIds = this.getFilteredOptions().map(option => option.id);
    if (event.currentTarget.checked) {
      newFieldIds = allFieldIds;
    } else {
      newFieldIds = [];
    }
    this.setState(
      {
        selectedFieldIds: newFieldIds,
      },
      this.validate(newFieldIds),
    );
  };

  /**
   * Called when the "Create PDF" button is clicked. This is a link that opens in a new window,
   * so this simply closes the modal.
   */
  handleSubmit = event => {
    const { onHide } = this.props;
    const { error, selectedFieldIds, touched } = this.state;
    if (error || !touched) {
      event.preventDefault();
      this.validate(selectedFieldIds);
    } else {
      onHide();
    }
  };

  validate = selectedFieldIds => {
    if (selectedFieldIds.length === 0) {
      this.setState({ error: ERROR_MESSAGE, touched: true });
    } else {
      this.setState({ error: null, touched: true });
    }
  };

  render() {
    const {
      hasPdfReportLandscapeViewFeature,
      layoutOptions,
      onHide,
      show,
    } = this.props;
    const { error, selectedFieldIds, selectedLayout, touched } = this.state;
    const options = this.getFilteredOptions();
    const isAllSelected = options.every(
      option => selectedFieldIds.indexOf(option.id) >= 0,
    );
    const columns = [
      options.slice(0, Math.ceil(options.length / 2)),
      options.slice(Math.ceil(options.length / 2)),
    ];

    const isAnyOptionSelected = options.some(
      option => selectedFieldIds.indexOf(option.id) >= 0,
    );

    /* eslint-disable react/no-array-index-key */
    return (
      <Modal
        aria-labelledby='modal-title'
        data-testid='candidacy-summary-report-modal'
        dialogClassName='CandidacySummaryReportModal'
        onHide={onHide}
        show={Boolean(show)}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title id='modal-title'>Generate Summary Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className='form'>
          {touched && <div className='has-error'>{error}</div>}
          <div className='row' key='subheader'>
            {hasPdfReportLandscapeViewFeature && (
              <div className='CandidacySummaryReportModal__layoutOptions col-sm-12'>
                <h2>Report Options</h2>
                <h3>Layout</h3>
                <div className='row'>
                  {layoutOptions.map(option => (
                    <div className='col-sm-2' key={option.value}>
                      <span className='radio'>
                        <label key={option.value}>
                          <input
                            checked={option.value === selectedLayout}
                            onChange={this.onLayoutChange}
                            type='radio'
                            value={option.value}
                          />
                          <strong>{option.label}</strong>
                        </label>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className='CandidacySummaryReportModal__informationHeader col-sm-12'>
              <h2>
                Candidate Information
                <div className='CandidacySummaryReportModal__allCheckbox checkbox'>
                  <label htmlFor={`${this._componentId}-selectall`}>
                    <input
                      checked={isAllSelected}
                      id={`${this._componentId}-selectall`}
                      onChange={this.handleSelectAllClick}
                      type='checkbox'
                      value='all'
                    />
                    <strong>Select All</strong>
                  </label>
                </div>
              </h2>
            </div>
          </div>
          <div className='row' key='options'>
            {columns.map((column, columnIndex) => (
              <div className='col-sm-6' key={columnIndex}>
                {column.map(option => (
                  <div className='checkbox' key={option.id}>
                    <label htmlFor={`${this._componentId}-option-${option.id}`}>
                      <input
                        checked={selectedFieldIds.indexOf(option.id) >= 0}
                        data-testid={option.id}
                        id={`${this._componentId}-option-${option.id}`}
                        onChange={this.handleOptionClick}
                        type='checkbox'
                        value={option.id}
                      />
                      <strong>{option.label || option.label(this)}</strong>
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-primary-outline'
            onClick={onHide}
            type='button'
          >
            Cancel
          </button>
          <a
            className='btn btn-primary'
            disabled={error || !isAnyOptionSelected}
            href={this.getSummaryHref()}
            onClick={event => {
              if (!isAnyOptionSelected) {
                event.preventDefault();
              } else {
                this.handleSubmit(event);
              }
            }}
            onContextMenu={event => {
              if (!isAnyOptionSelected) {
                event.preventDefault();
              }
            }}
            rel='noopener noreferrer'
            target='_blank'
          >
            Create PDF
          </a>
        </Modal.Footer>
      </Modal>
    );
  }
}

CandidacySummaryReportModal.propTypes = {
  /**
   * The available fields for the report
   */
  availableFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isVisible: PropTypes.bool,
      label: PropTypes.string.isRequired,
    }),
  ),

  /**
   * The ID of the candidacy to generate the report for.
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * a flipper that determines if the use can select landscape/portrait
   */
  hasPdfReportLandscapeViewFeature: PropTypes.bool.isRequired,

  /**
   * the layout options for the report.
   */
  layoutOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),

  /**
   * Called when the modal should be hidden/closed
   */
  onHide: PropTypes.func.isRequired,

  /**
   * The ID of the search to generate the report for.
   */
  searchId: PropTypes.number.isRequired,

  /**
   * True to display the modal, false otherwise
   */
  show: PropTypes.bool,
};

CandidacySummaryReportModal.defaultProps = {
  show: true,
};

export default compose(
  setDisplayName('CandidacySummaryReportModal(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
    onHide: PropTypes.func.isRequired,
  }),
  withFeatureCheck(
    'label.career_highlights_to_google_insights',
    'useGoogleInsightsLabel',
  ),
  withFeatureCheck(
    'feature.pdf_report_landscape_configurable',
    'hasPdfReportLandscapeViewFeature',
  ),
  withFeatureCheck('feature.contact_aliases', 'hasContactAliases'),
  withFeatureCheck('feature.assessment_templates', 'hasAssessmentTemplates'),
  connect((state, { candidacyId, useGoogleInsightsLabel }) => {
    const searchId = getCandidacySearchId(state, candidacyId);
    const searchType = getSearchType(state, searchId);
    const searchAttributes = getSearchAttributes(state, searchId);
    const isJobSearch = searchType === TYPE_JOB_SEARCH;

    return {
      searchId: searchId,
      canViewCurrentCompensation:
        canViewCurrentCompensationSelector(state) && isJobSearch,
      hasAssessmentCriteria:
        searchAttributes && Boolean(searchAttributes.count()),
      isRejected: isCandidacyRejected(state, candidacyId),
      careerHighlightsLabel: useGoogleInsightsLabel
        ? 'Google Insights'
        : 'Career Highlights',
    };
  }),
  mapProps(
    ({
      canViewCurrentCompensation,
      careerHighlightsLabel,
      hasAssessmentCriteria,
      hasAssessmentTemplates,
      hasContactAliases,
      isRejected,
      ...props
    }) => ({
      availableFields: [
        { id: 'avatar', label: 'Avatar' },
        { id: 'name', label: 'Name' },
        { id: 'job_title', label: 'Job Title' },
        { id: 'company', label: 'Primary Company' },
        { id: 'aliases', label: 'Aliases', isVisible: hasContactAliases },
        { id: 'location', label: 'Location' },
        { id: 'professional_networks', label: 'Professional Network Links' },
        { id: 'thrive_profile', label: 'Thrive Profile Link' },
        { id: 'email', label: 'Email' },
        { id: 'phone', label: 'Phone' },
        { id: 'mobile', label: 'Mobile Phone' },
        { id: 'career_highlights', label: careerHighlightsLabel },
        { id: 'documents', label: 'Resume & Reference Docs.' },
        { id: 'experience', label: 'Experience' },
        { id: 'education', label: 'Education' },
        { id: 'stage', label: 'Candidate Stage' },
        { id: 'assessments', label: 'Assessments' },
        { id: 'interviews', label: 'Interviews' },
        { id: 'references', label: 'References' },
        { id: 'comments', label: 'Comments' },
        {
          id: 'compensation',
          label: 'Compensation',
          isVisible: canViewCurrentCompensation,
        },
        {
          id: 'rejection_reason',
          label: 'Rejection Reason',
          isVisible: isRejected,
        },
        {
          id: 'stage_at_rejection',
          label: 'Stage at Rejection',
          isVisible: isRejected,
        },
        {
          id: 'rejection_comments',
          label: 'Rejection Comments',
          isVisible: isRejected,
        },
        {
          id: 'assessment_attribute_report',
          label: 'Assessment Criteria Descriptions',
          isVisible: !hasAssessmentTemplates && hasAssessmentCriteria,
        },
      ],
      layoutOptions: [
        {
          value: PORTRAIT_LAYOUT,
          label: 'Portrait',
        },
        {
          value: LANDSCAPE_LAYOUT,
          label: 'Landscape',
        },
      ],
      ...props,
    }),
  ),
)(CandidacySummaryReportModal);

import { mapProps } from 'recompose';
import {
  SORT_BY_ROUND_DATE,
  SORT_BY_COMPANY_STAGE,
  SORT_BY_ROUND_AMOUNT,
} from 'modules/company-investments/constants';
import InvestmentAmount from 'modules/company-investments/components/InvestmentAmount';
import InvestmentDate from 'modules/company-investments/components/InvestmentDate';
import InvestmentRoundStageName from 'modules/company-investments/components/InvestmentRoundStageName';
import InvestmentInvestorList from 'modules/company-investments/components/InvestmentInvestorList';
import FundingRoundEditDeleteTableCell from './FundingRoundEditDeleteTableCell';

/**
 * Each `Cell` is rendered with a `datum` property which is dependent on whatever
 * the `data`  contained when passed into the table. In the case of funding rounds that is
 * the investment ID. This renames that to investmentId for the underlying
 * component.
 */
const mapDatumToInvestmentId = mapProps(({ datum: investmentId }) => ({
  investmentId: investmentId,
}));

/**
 * The list of column definitions for the funding rounds table.
 */
export default [
  {
    key: SORT_BY_ROUND_DATE,
    className: 'u-noWrap',
    title: 'Date',
    isSortable: true,
    Cell: mapDatumToInvestmentId(InvestmentDate),
  },
  {
    key: SORT_BY_COMPANY_STAGE,
    className: 'FundingRoundTableColumn--round',
    title: 'Round',
    isSortable: true,
    Cell: mapDatumToInvestmentId(InvestmentRoundStageName),
  },
  {
    key: SORT_BY_ROUND_AMOUNT,
    className: 'FundingRoundTableColumn--amount',
    title: 'Amount',
    isSortable: true,
    Cell: mapDatumToInvestmentId(InvestmentAmount),
  },
  {
    key: 'investors',
    title: 'Investors',
    isSortable: false,
    Cell: mapDatumToInvestmentId(InvestmentInvestorList),
  },
  {
    className: 'FundingRoundTableColumn--edit-delete',
    key: 'edit-delete',
    isSortable: false,
    Cell: FundingRoundEditDeleteTableCell,
  },
];

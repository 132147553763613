import React from 'react';
import PropTypes from 'prop-types';
import { useApiPatch } from 'modules/core/hooks/useApi';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { useDispatch } from 'react-redux';

const MailchimpIntegrationApiKey = ({ apiKey, onApiKeyChange }) => {
  const dispatch = useDispatch();

  const [saveApiKey, isSavingApiKey, saveApiKeyError] = useApiPatch(
    '/api/integrations/mailchimp/api_key',
  );

  const handleSubmit = formData => {
    saveApiKey(
      {
        mailchimp_api_key: formData.mailchimpApiKey,
      },
      {
        onSuccess: response => {
          dispatch(toastSuccess('Successfully submitted Mailchimp API Key.'));
          onApiKeyChange(response.mailchimp_api_key);
        },
      },
    );
  };

  return (
    <>
      <h3 className='u-marginTop-32 u-marginBottom-8'>
        1. Enter Mailchimp API Key
      </h3>
      <p className='u-marginBottom-16'>
        First,{' '}
        <a
          href='https://mailchimp.com/help/about-api-keys/'
          rel='noreferrer'
          target='_blank'
        >
          generate an API key in Mailchimp
        </a>
        . The API key lets Thrive talk to Mailchimp and get information about
        your outreaches.
      </p>

      <Form onSubmit={handleSubmit}>
        <div className='u-flex u-flexAlign-b'>
          <Form.TextInput
            className='u-marginRight-16'
            initialValue={apiKey}
            label='Mailchimp API Key'
            name='mailchimpApiKey'
            placeholder='Enter API Key...'
            rules={{
              required: {
                value: true,
                message: 'Please enter a Mailchimp API Key',
              },
            }}
            style={{ width: 400 }}
          />

          <ButtonSecondary
            buttonType='submit'
            isLoading={isSavingApiKey}
            label='Save'
          />
        </div>
      </Form>

      {saveApiKeyError ? (
        <Card
          className='u-marginBottom-12 u-marginTop-4'
          isCentered={false}
          type='error'
        >
          <h4 className='u-marginTop-n'>
            There was an error updating Mailchimp API Key.
          </h4>
          {saveApiKeyError}
        </Card>
      ) : null}
    </>
  );
};

MailchimpIntegrationApiKey.defaultProps = {
  apiKey: '',
};

MailchimpIntegrationApiKey.propTypes = {
  apiKey: PropTypes.string,
  onApiKeyChange: PropTypes.func.isRequired,
};

export default MailchimpIntegrationApiKey;

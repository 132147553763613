import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import { useApiGet, useApiPut } from 'modules/core/hooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@thrivetrm/ui/components/Modal';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import TagSelect from 'modules/core/components/TagSelect';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Card from '@thrivetrm/ui/components/Card';
import PluralText from '@thrivetrm/ui/components/PluralText';
import routes from 'modules/routing/routes';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { BulkTagCategories } from '../constants';

const ActionTypes = {
  ADD: {
    label: 'Add',
    value: 'add',
  },
  REMOVE: {
    label: 'Remove',
    value: 'remove',
  },
};

const BulkEditTagModal = ({ isOpen, onClose, onSuccess, recordIds }) => {
  const {
    endpoints,
    payloadKeys,
    recordLabel,
    recordLabelPlural,
    supportedBulkTagCategories,
  } = useSelector(state => state.recordIndex);
  const dispatch = useDispatch();

  const [
    loadTenantOptions,
    isLoadingTenantOptions,
    _tenantOptionsLoadError,
    tenantOptions,
  ] = useApiGet(routes.api_tenant_options());

  const [updateBulkTags, isUpdatingBulkTags, updateBulkTagsError] = useApiPut(
    endpoints.bulkActions(),
  );

  useEffect(() => {
    if (isOpen && supportedBulkTagCategories.length) {
      loadTenantOptions();
    }
  }, [loadTenantOptions, isOpen, supportedBulkTagCategories]);

  const [selectedTagCategory, setSelectedTagCategory] = useState(
    supportedBulkTagCategories[0],
  );
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedAction, setSelectedAction] = useState(ActionTypes.ADD.value);

  const handleSubmit = () => {
    const tagIds = selectedTags?.map(tag => tag.id);

    const payload = {
      [payloadKeys.bulkTagRecordIdsKey]: recordIds,
      association: BulkTagCategories[selectedTagCategory].apiAssociationKey,
      tag_ids: tagIds,
      operation: selectedAction,
    };

    updateBulkTags(payload, {
      onSuccess: () => {
        dispatch(toastSuccess(`${recordLabelPlural} Updated!`));
        onSuccess();
      },
    });
  };

  const getTagOptions = () => {
    if (tenantOptions) {
      const key = BulkTagCategories[selectedTagCategory]?.tenantOptionsKey;
      return tenantOptions.tenant_options[key] || [];
    }
    return [];
  };

  const handleTagCategoryChange = tagCategory => {
    setSelectedTagCategory(tagCategory);
    setSelectedTags([]);
  };

  return (
    <Modal
      className='BulkEditTagModal'
      isOpen={isOpen}
      onClose={onClose}
      size='small'
      title='Edit Tags'
    >
      <Modal.Body className='ModalBody--noClip'>
        <LoadingContainer isLoading={isLoadingTenantOptions}>
          <div className='u-borderBottom u-flex u-flexJustify-c u-flexAlign-c u-paddingBottom-16 u-margin-10'>
            <div className='u-flexItem--weak'>Iʼd like to</div>
            <SelectMenu
              className='u-marginRight-8 u-marginLeft-8'
              inputWidth='full'
              onChange={setSelectedAction}
              placeholder='Select an action...'
              size='small'
              value={selectedAction}
            >
              {Object.values(ActionTypes).map(({ label, value }) => (
                <SelectMenu.Item key={value} value={value}>
                  {label}
                </SelectMenu.Item>
              ))}
            </SelectMenu>
            <SelectMenu
              className='u-marginRight-8'
              inputWidth='full'
              onChange={handleTagCategoryChange}
              placeholder='Select a category...'
              size='small'
              value={selectedTagCategory}
            >
              {supportedBulkTagCategories?.map(categoryKey => {
                const category = BulkTagCategories[categoryKey];
                return (
                  <SelectMenu.Item key={categoryKey} value={categoryKey}>
                    {category.label}
                  </SelectMenu.Item>
                );
              })}
            </SelectMenu>
            <span className='u-flexItem--weak'>
              {selectedAction === ActionTypes.ADD.value ? 'to' : 'from'}{' '}
              <PluralText
                case='lowerCase'
                quantity={recordIds.length}
                shouldIncludeQuantity={true}
                text={recordLabel}
              />
            </span>
          </div>

          <div className='u-flex u-margin-24'>
            <TagSelect
              availableTags={getTagOptions()}
              onChange={setSelectedTags}
              placeholder='+ select tag...'
              selectedTags={selectedTags}
            />
          </div>
        </LoadingContainer>
        {updateBulkTagsError && (
          <Card className='u-marginBottom-12' isCentered={false} type='error'>
            There was an error
            {selectedAction === ActionTypes.ADD.value
              ? ' adding tags to'
              : ' removing tags from'}{' '}
            {recordLabelPlural.toLowerCase()}
            <br />
            {updateBulkTagsError}
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
        <ButtonPrimary
          isLoading={isUpdatingBulkTags}
          label='Submit'
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};

BulkEditTagModal.defaultProps = {
  isOpen: false,
};

BulkEditTagModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  recordIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default BulkEditTagModal;

import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import getJobApplicationAppliedDate from '../selectors/getJobApplicationAppliedDate';

/**
 * A Higher order component that sorts a list of jobApplicationIds in order of the applicant's
 * created at (applied) date.
 */
export default compose(
  setDisplayName('withJobApplicationIdsSortedByDateApplied'),
  connect(
    (state, { jobApplicationIds }) => ({
      jobApplicationIds:
        jobApplicationIds &&
        jobApplicationIds
          .sortBy(id => getJobApplicationAppliedDate(state, id))
          .reverse(),
    }),
    {},
  ),
);

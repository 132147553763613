import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import { useApiGet } from 'modules/core/hooks/useApi';
import { URL_REGEX } from 'modules/core/urlUtils';
import formDataPropType from '@thrivetrm/ui/propTypes/formDataPropType';
import routes from 'modules/routing/routes';
import isExecutiveSearchTenant from 'modules/tenant/selectors/isExecutiveSearchTenant';

const RequestDetails = ({ formData, onSubmit }) => {
  const isExecutiveSearchTenantType = useSelector(isExecutiveSearchTenant);

  const [
    loadTenantOptions,
    isLoadingTenantOptions,
    _tenantOptionsLoadError,
    tenantOptions,
  ] = useApiGet(routes.api_tenant_options());

  useEffect(() => {
    loadTenantOptions();
  }, [loadTenantOptions]);

  const options = tenantOptions && tenantOptions.tenant_options;
  const costCenters = options && options.cost_centers;
  const organizations = options && options.organizations;
  const placementTypes = options && options.placement_types;

  const getSelectMenuItems = collection => {
    const items = [];
    if (collection?.length) {
      collection.forEach(item =>
        items.push(
          <SelectMenu.Item key={item.id} value={item.id}>
            {item.name}
          </SelectMenu.Item>,
        ),
      );
    }
    return items;
  };

  return (
    <Card className='SearchRequestPage__Card' type='shadow'>
      <LoadingContainer isLoading={isLoadingTenantOptions}>
        <Form initialValues={formData} onSubmit={onSubmit}>
          <h2 className='u-marginBottom-24 u-marginTop-12 u-textAlign-c'>
            Request Details
          </h2>

          {isExecutiveSearchTenantType ? (
            <Form.TextInput
              className='u-marginBottom-24'
              inputWidth='full'
              label='What is the company name for this search?'
              name='company_name'
              placeholder='Enter a company name…'
            />
          ) : null}

          {organizations?.length > 0 ? (
            <Form.SelectMenu
              className='u-marginBottom-24'
              inputWidth='full'
              label='Which organization does this search belong to?'
              name='organization'
              placeholder='Select an Organization…'
            >
              {getSelectMenuItems(organizations)}
            </Form.SelectMenu>
          ) : null}

          {isExecutiveSearchTenantType || (
            <Form.TextInput
              className='u-marginBottom-24'
              inputWidth='full'
              label='Who is the hiring manager for this search?'
              name='hiring_manager_name'
              placeholder='Enter a full name…'
              rules={{
                required: {
                  value: true,
                  message: 'Hiring manager is required',
                },
              }}
            />
          )}

          <Form.SelectMenu
            className='u-marginBottom-24'
            inputWidth='full'
            label='What is the cost center this role will sit in?'
            name='cost_center'
            placeholder='Select a Cost Center…'
          >
            {getSelectMenuItems(costCenters)}
          </Form.SelectMenu>

          <Form.SelectMenu
            className='u-marginBottom-24'
            inputWidth='full'
            label='What type of talent will this search focus on?'
            name='placement_type'
            placeholder='Select a Placement Type…'
            rules={{
              required: {
                value: true,
                message: 'Placement Type is required',
              },
            }}
          >
            {getSelectMenuItems(placementTypes)}
          </Form.SelectMenu>

          {isExecutiveSearchTenantType || (
            <Form.TextInput
              className='u-marginBottom-24'
              inputWidth='full'
              label='Please link to a verification of approval for this search.'
              name='verification_of_approval_url'
              placeholder='Enter a URL…'
              rules={{
                pattern: {
                  value: URL_REGEX,
                  message:
                    'Must be a complete URL such as https://www.example.com',
                },
              }}
            />
          )}
          <div className='u-flex u-width-100 u-flexJustify-r'>
            <div>
              <Form.SubmitButton label='Next ›' />
            </div>
          </div>
        </Form>
      </LoadingContainer>
    </Card>
  );
};

RequestDetails.propTypes = {
  formData: formDataPropType,
  onSubmit: PropTypes.func.isRequired,
};

export default RequestDetails;

import getRevenueList from './getRevenueList';

/**
 *  Determines if the revenue list for a company needs to be fetched from the server.
 * @param {*} state
 * @param {Number} companyId The ID of company whose revenues may need to be fetched.
 * @return {Boolean} true if the revenue list should be fetched from the server for the
 *   given company; otherwise, false.
 */
export default (state, companyId) => {
  if (!companyId) {
    return false;
  }

  const revenueList = getRevenueList(state, companyId);

  if (!revenueList) {
    return true;
  }

  if (
    revenueList.getIn(['_meta', 'isFetching']) ||
    revenueList.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return Boolean(revenueList.getIn(['_meta', 'isInvalidated']));
};

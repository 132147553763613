import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Tooltip from '@thrivetrm/ui/components/Tooltip';

/**
 * A single item in the CandidacyStageListMenu
 */
class SearchStageListMenuItem extends PureComponent {
  handleClick = () => {
    const { onClick, value } = this.props;
    onClick(value);
  };

  render() {
    const { checked, children, disabled } = this.props;
    const allowedStageToolTip = disabled
      ? 'Some stages do not meet the progression requirements'
      : null;

    return (
      <li className='SearchStageListMenuItem'>
        <Tooltip
          className='u-block'
          content={allowedStageToolTip}
          position='bottom'
          size='medium'
        >
          <button
            className={classnames('SearchStageListMenuItem__Button', {
              active: checked,
              disabled: disabled,
            })}
            disabled={checked || disabled}
            onClick={this.handleClick}
            type='button'
          >
            {children}
          </button>
        </Tooltip>
      </li>
    );
  }
}

SearchStageListMenuItem.propTypes = {
  /**
   * True if this stage is the currently "selected" stage.
   * A "checked" stage is highlighted and not selectable.
   */
  checked: PropTypes.bool,

  /**
   * The node(s) or label to render inside the button
   */
  children: PropTypes.node.isRequired,

  /**
   * True to disable selecting this stage
   */
  disabled: PropTypes.bool,

  /**
   * Called when the item is clicked.
   */
  onClick: PropTypes.func.isRequired,

  /**
   * An arbitrary value that will be passed in the `onClick` callback as the
   * first parameter.
   */
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

SearchStageListMenuItem.defaultProps = {
  checked: false,
  disabled: false,
};

export default SearchStageListMenuItem;

import { compose } from 'recompose';
import { connect } from 'react-redux';
import ensureTenantOptionsFetched from 'modules/tenant/components/ensureTenantOptionsFetched';
import getCompanyInvestmentStageOrder from 'modules/tenant/selectors/getCompanyInvestmentStageOrder';
import getInvestmentProperty from '../selectors/getInvestmentProperty';

/**
 * A Higher order component that sorts a list of investmentIds in order of the
 * investment round stage (it's ordinal value)
 */
export default compose(
  ensureTenantOptionsFetched,
  connect(
    (state, { investmentIds }) => ({
      investmentIds:
        investmentIds &&
        investmentIds.sortBy(id =>
          getCompanyInvestmentStageOrder(
            state,
            getInvestmentProperty(state, id, 'round'),
          ),
        ),
    }),
    {},
  ),
);

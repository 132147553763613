import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import userSchema from '../schema';

/**
 * Returns a value indicating whether a particular user has been fetched from the server or not.
 *
 * @param {*} state
 * @param {Number} userId The ID of the user to check.
 * @return {Boolean} True if the user has been fetched, otherwise false.
 */
export default (state, userId) =>
  Boolean(getEntityProperty(state, userSchema.key, userId, 'id'));

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useFormInput from '@thrivetrm/ui/hooks/useFormInput';
import formValidationRulesPropType from '@thrivetrm/ui/propTypes/formValidationRulesPropType';
import SelectDropdown from './SelectDropdown';

const FormSelect = ({
  initialValue,
  isMulti,
  label,
  name,
  rules,
  ...props
}) => {
  const { errorMessage, isRequired, onBlur, onChange, value } = useFormInput({
    name: name,
    initialValue: initialValue,
    label: label,
    rules: rules,
  });

  return (
    <SelectDropdown
      {...props}
      errorMessage={errorMessage}
      isMulti={isMulti}
      isRequired={isRequired}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    />
  );
};

FormSelect.defaultProps = {
  initialValue: null,
  isMulti: false,
  label: null,
  rules: null,
};
FormSelect.propTypes = {
  initialValue: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  isMulti: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  rules: formValidationRulesPropType,
};

export default FormSelect;

// The name of the "resource" is notably unorthodox
// The companies, contacts and searches "table view" endpoints have the same request and response
// shape. These were built in parallel to support the record index pages, which are shared
// data-driven components. The idea was to build a UI template that rendered differently based on data.
// Currently, we have one query to support all 3 record types based on a argument and one "query tag".
// We could have also created 3 queries with hard-coded URLs that each had their own "query tag".
// One endpoint means that the record index pages can use one hook and pass an argument in.
// The downside would be if we're rendering multiple record data tables in one view and invalidation
// is only needed on one of the resources. There's no known instance of this at this time, so we move
// forward with shared query and tag.
import { convertToCamelCase } from 'modules/core/jsonUtils';
import { stringifyQueryObject } from 'modules/core/urlUtils';
import apiV1 from './index';
import queryTags from './queryTags';

const { INDEX_TABLE_RECORDS } = queryTags;

const VALID_RECORD_TYPES = ['companies', 'contacts', 'searches'];

// These formatting functions are copy/pasta from the recordIndexSlice.
// Once this is merged, the record index pages should be refactored to use these RTKQ services, which
// would result in reduced duplication.
const formatColumns = columns => {
  if (columns?.length) {
    return columns.map(column => {
      const { columnSize, key, ...rest } = column;
      return {
        name: key,
        width: columnSize,
        ...rest,
      };
    });
  }
  return [];
};

const formatRows = (data, columns) => {
  if (data?.length && columns?.length) {
    return data.map(record => ({
      id: record[0].id,
      attributes: formatColumns(columns).map(column => ({
        ...record.find(attribute => column.name === attribute.key),
        ...column,
      })),
    }));
  }
  return [];
};

const transformResponse = (response, _metadata, { recordType }) => {
  // Heads up! Note that we convert the column data to camelcase, but not the row data.
  // This is deliberate. Consuming components have a mix of expecting camel and snake case properties
  // Blindly converting all will break some functionality, such as the `edit_path` property.
  // Ideally, we would convert case at the boundary and be consistent for the JS side. Since we currently
  // have a mix, this would require a refactor of the consuming components used on the index pages and the
  // company searches table.
  const formattedRowData = formatRows(
    response[recordType],
    response.metadata?.columns,
  );
  const formattedColumnData = convertToCamelCase(
    formatColumns(response.metadata?.columns),
  );
  return {
    rows: formattedRowData,
    columns: formattedColumnData,
    totalPages: response.metadata?.total_pages,
    totalResults: response.metadata?.total_results,
  };
};

const getParams = ({
  columns,
  filters,
  page,
  resultsPerPage,
  sort,
  ...params
}) => {
  return stringifyQueryObject({
    view: 'table_view',
    page: page,
    columns: columns,
    results_per_page: resultsPerPage,
    sort_direction: sort.direction,
    sort_field: sort.field,
    filters: filters,
    ...params,
  });
};

export const indexTableRecords = apiV1.injectEndpoints({
  endpoints: builder => ({
    getIndexTableRecords: builder.query({
      query: ({
        columns,
        filters,
        page,
        recordType,
        resultsPerPage,
        sort,
        ...params
      }) => {
        if (!VALID_RECORD_TYPES.includes(recordType)) {
          throw new Error(
            `Invalid record type.\nMust be one of ${VALID_RECORD_TYPES.join(
              ', ',
            )} `,
          );
        }
        return {
          url: recordType,
          method: 'GET',
          params: getParams({
            columns: columns,
            filters: filters,
            page: page,
            resultsPerPage: resultsPerPage,
            sort: sort,
            ...params,
          }),
        };
      },
      transformResponse: transformResponse,
      providesTags: [INDEX_TABLE_RECORDS],
    }),
  }),
  overrideExisting: false,
});

export const { useGetIndexTableRecordsQuery } = indexTableRecords;

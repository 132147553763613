export const REVENUE_MIN_OPTIONS = [
  { value: 0, label: '0' },
  { value: 10, label: '10M' },
  { value: 30, label: '30M' },
  { value: 50, label: '50M' },
  { value: 100, label: '100M' },
];

export const REVENUE_MAX_OPTIONS = [
  { value: 10, label: '10M' },
  { value: 30, label: '30M' },
  { value: 50, label: '50M' },
  { value: 100, label: '100M' },
  { value: 999999, label: '100M+' },
];

export const COMMUTE_RADIUS_OPTIONS = [
  { value: '15', label: 'Less than 15 minutes' },
  { value: '30', label: 'Less than 30 minutes' },
  { value: '45', label: 'Less than 45 minutes' },
  { value: '60', label: 'Less than 1 hour' },
  { value: '99', label: 'More than 1 hour' },
];

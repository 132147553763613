import { connect } from 'react-redux';
import { compose } from 'recompose';
import getTargetCompanyCommentDate from '../selectors/comments/getTargetCompanyCommentDate';

/**
 * A Higher order component that sorts a list of targetCompanyCommentIds in reverse chronological
 * order of the date field.
 */
export default compose(
  connect((state, { targetCompanyCommentIds }) => ({
    targetCompanyCommentIds:
      targetCompanyCommentIds &&
      targetCompanyCommentIds
        .sortBy(id => getTargetCompanyCommentDate(state, id))
        .reverse(),
  })),
);

import React from 'react';
import PropTypes from 'prop-types';
import ExpandableActivityView from './ExpandableActivityView';

const CandidacyAssessmentActivityView = ({ formattedComment }) =>
  formattedComment ? (
    <div className='u-marginTop-4 u-fontSize-small'>
      <strong>Summary Notes:</strong>
      <ExpandableActivityView content={formattedComment} />
    </div>
  ) : null;

CandidacyAssessmentActivityView.propTypes = {
  formattedComment: PropTypes.string,
};

export default CandidacyAssessmentActivityView;

import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'modules/core/components/ErrorBoundary';
import { useSelector } from 'react-redux';
import { stringToCamelCase } from 'modules/core/jsonUtils';
import FilterInputAsString from './FilterInputAsString';

const BooleanFilters = {
  KEYWORD: 'keyword_boolean',
  INDUSTRY: 'industry_boolean',
  POSITION: 'position_boolean',
  COMPANY: 'company_boolean',
};

const FilterSetAsString = ({ filterSetId }) => {
  const { availableFilterInputs, availableFilterSets } = useSelector(
    state => state.recordIndex,
  );

  const { label } = availableFilterSets?.[filterSetId] || {};

  const isFilterSetVisible = useSelector(state => {
    const { filters } = state.recordIndex.savedView;

    const filterInputNamesInSet = availableFilterSets[filterSetId]?.filterInputs
      .map(inputName => availableFilterInputs[inputName])
      .filter(input => input.type !== 'radio' && input.type !== 'checkbox')
      .map(input => input.name);

    const filterInputValuesInSet = filterInputNamesInSet?.map(inputName => {
      if (Object.values(BooleanFilters).includes(inputName)) {
        return filters[stringToCamelCase(inputName)]?.values;
      }

      return filters[stringToCamelCase(inputName)];
    });

    const willRenderInput = val => {
      if (!val) return false;
      if (Array.isArray(val)) return val.length > 0;
      return true;
    };

    return filterInputValuesInSet?.some(willRenderInput);
  });

  return isFilterSetVisible ? (
    <ErrorBoundary key={filterSetId}>
      <>
        {label ? (
          <span className='FilterSetAsString__setLabel'>{filterSetId}: </span>
        ) : null}
        {availableFilterSets?.[filterSetId]?.filterInputs.map(filterName => (
          <FilterInputAsString
            key={filterName}
            label={availableFilterInputs[filterName].label}
            name={availableFilterInputs[filterName].name}
            type={availableFilterInputs[filterName].type}
          />
        ))}
      </>
    </ErrorBoundary>
  ) : null;
};

FilterSetAsString.propTypes = {
  filterSetId: PropTypes.string.isRequired,
};

export default FilterSetAsString;

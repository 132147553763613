import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { SEARCH_TYPES, TYPE_PIPELINE } from 'modules/searches/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import smartTagSchema from '../schemas/smartTag';
import getTagProperty from '../selectors/getTagProperty';

/**
 * Renders a square in the color of a particular tag
 */
export default compose(
  setDisplayName('TagColorSquare'),
  setPropTypes({
    /**
     * The size of the colored square (i.e. 10, "1em", "3px", etc...)
     */
    size: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,

    searchType: PropTypes.oneOf(SEARCH_TYPES).isRequired,
    /**
     * The ID of the tag.
     */
    tagId: PropTypes.number.isRequired,
  }),
  defaultProps({
    size: '1em',
  }),
  connect(
    (state, { searchType, tagId }) => ({
      color:
        searchType === TYPE_PIPELINE
          ? getEntityProperty(state, smartTagSchema.key, tagId, 'color')
          : getTagProperty(state, tagId, 'color'),
    }),
    {},
  ),
  mapProps(
    ({
      color,
      searchType: _searchType,
      size,
      style,
      tagId: _tagId,
      ...rest
    }) => ({
      ...rest,
      style: {
        display: 'inline-block',
        height: size,
        width: size,
        ...style,
        backgroundColor: color,
      },
    }),
  ),
)('span');

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import getTenantId from 'modules/tenant/selectors/getTenantId';
import getCurrentUserId from 'modules/user/selectors/getCurrentUserId';

const DRAFT_ID_PREFIX = 'THRIVE_DRAFT';
const DRAFT_TTL_DAYS = 7;
const DRAFT_TTL_MS = DRAFT_TTL_DAYS * 24 * 60 * 60 * 1000;

// Since the same form fields can render in multiple different contexts, we want
// to be hyper-specific in associating draft content to fields. For example, when
// creating a new "candidate note" on the candidate list page, that note is specific
// to the current tenant, user, search, and candidate. While tenant and user might
// seem like overkill, our customers can legitimately have access to multiple tenants,
// and dev/QA logs in as multiple users within the same tenant while testing...
// so let's not make testing harder than it already is :) Example draft IDs:
//
// THRIVE_DRAFT:tenant/3/user/71/search/13/candidacy/72/note/2
// THRIVE_DRAFT:tenant/3/user/71/contact/102/introduction/new
//
// NOTE: In some places where we set the draftStoragePath, it won't always have
// IDs provided for every path, so we filter those out. For example, a "search
// note vs. a "search candidate note"... the <NoteList> component renders both
// of those note types, but doesn't know what parent is rendering it, so when
// a "search note" is rendered its `candidateId` will be undefined.
const makeDraftId = draftStoragePath => {
  const fullPath = Object.entries(draftStoragePath)
    .map(([path, id]) => (id ? `${path}/${id}` : null))
    .filter(Boolean)
    .join('/');

  return `${DRAFT_ID_PREFIX}:${fullPath}`;
};

const getDraftFromStorage = draftId => {
  const draft = localStorage.getItem(draftId);
  try {
    return JSON.parse(draft);
  } catch (error) {
    // JSON.parse throws if the parameter is not a JSON-parseable string.
    // Since this value is coming from localStorage, which can be edited by
    // the user, we handle parsing defensively.
    localStorage.removeItem(draftId);
    return null;
  }
};

const withDraftStorage = () => FormComponent => {
  const WithDraftStorage = ({
    draftStoragePath,
    onCancel,
    onSaved,
    ...props
  }) => {
    const tenantId = useSelector(getTenantId);
    const userId = useSelector(getCurrentUserId);
    const draftId = draftStoragePath
      ? makeDraftId({
          tenant: tenantId,
          user: userId,
          ...draftStoragePath,
        })
      : null;

    const deleteAllExpiredDrafts = () => {
      const allDraftIds = Object.keys(localStorage).filter(key =>
        key.startsWith(DRAFT_ID_PREFIX),
      );
      allDraftIds.forEach(currentDraftId => {
        const lastUpdatedTime = getDraftFromStorage(currentDraftId)
          ?.lastUpdatedTime;
        if (lastUpdatedTime) {
          const isExpired = Date.now() > lastUpdatedTime + DRAFT_TTL_MS;
          if (isExpired) {
            localStorage.removeItem(currentDraftId);
          }
        }
      });
    };
    // Drafts are stored as objects with the below shape:
    // {
    //   content: <html blob representing rich text field content>,
    //   lastUpdatedTime: <timestamp>
    // }
    const [draft, setDraft] = useState(
      draftId ? getDraftFromStorage(draftId) : null,
    );

    const handleDraftChange = value => {
      if (value) {
        const newDraft = {
          content: value,
          lastUpdatedTime: Date.now(),
        };
        setDraft(newDraft);
        localStorage.setItem(draftId, JSON.stringify(newDraft));
      } else {
        setDraft(null);
        localStorage.removeItem(draftId);
      }
    };

    const handleCancel = () => {
      localStorage.removeItem(draftId);
      setDraft(null);
      if (onCancel) {
        onCancel();
      }
    };

    const handleSaved = () => {
      localStorage.removeItem(draftId);
      deleteAllExpiredDrafts();
      setDraft(null);
      if (onSaved) {
        onSaved();
      }
    };

    const clearDraftStorage = () => {
      localStorage.removeItem(draftId);
      setDraft(null);
    };

    return draftId ? (
      <FormComponent
        {...props}
        clearDraftStorage={clearDraftStorage}
        draft={draft}
        onCancel={handleCancel}
        onDraftChange={handleDraftChange}
        onSaved={handleSaved}
      />
    ) : (
      <FormComponent
        {...props}
        clearDraftStorage={clearDraftStorage}
        onCancel={onCancel}
        onSaved={onSaved}
      />
    );
  };

  const storageValueType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]);

  WithDraftStorage.propTypes = {
    draftStoragePath: PropTypes.shape({
      candidacy: storageValueType,
      careerHighlight: storageValueType,
      company: storageValueType,
      contact: storageValueType,
      description: storageValueType,
      introduction: storageValueType,
      jobListing: storageValueType,
      note: storageValueType,
      outreach: storageValueType,
      outreachEmail: storageValueType,
      reference: storageValueType,
      search: storageValueType,
      task: storageValueType,
    }),
    onCancel: PropTypes.func,
    onSaved: PropTypes.func,
  };

  return WithDraftStorage;
};

export default withDraftStorage;

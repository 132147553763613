import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  compose,
  withProps,
  withState,
  withHandlers,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import AppointmentForm from 'modules/appointments/components/AppointmentForm';

import withFormToggleButton from '../../../components/forms/withFormToggleButton';

import { PARENT_CANDIDACY, PARENT_SEARCH, PERMITTED_TYPES } from '../constants';
import canCreateInterview from '../selectors/canCreateInterview';
import DateGroupedInterviewList from './DateGroupedInterviewList';
import withInterviewListFetched from './withInterviewListFetched';
import mapParentToInterviewList from './mapParentToInterviewList';

const AddInterviewFormToggleButton = withFormToggleButton(AppointmentForm);

/**
 * A panel that displays a collection of interviews, allowing for editing, deleting, replying, and
 * creating new interviews.
 */
const InterviewsPanel = ({
  canCreateInterview: allowCreate,
  candidacyId,
  handleChange,
  handleRefresh,
  interviewList,
  isSendingUpdateToAttendees,
  popoverPlacement,
  searchId,
  setIsSendingUpdateToAttendees,
}) => {
  const isFetching =
    interviewList && interviewList.getIn(['_meta', 'isFetching']);
  const hasError =
    interviewList && Boolean(interviewList.getIn(['_meta', 'error']));

  return (
    <div className='interviews--interviews-panel'>
      {allowCreate && (
        <AddInterviewFormToggleButton
          canClearCalendarSelection={true}
          candidacyId={candidacyId}
          draftStoragePath={{
            search: searchId,
            candidate: candidacyId,
            interview: 'new',
          }}
          formActionProps={{
            submitLabel: isSendingUpdateToAttendees ? 'Send Invite' : 'Save',
          }}
          onChange={handleChange}
          onToggle={() => setIsSendingUpdateToAttendees(false)}
          permittedTypes={PERMITTED_TYPES}
          renderTitle={false}
          searchId={searchId}
        >
          Schedule an Interview
        </AddInterviewFormToggleButton>
      )}
      <div className='interviews--interviews-panel-container'>
        <div className='interviews--interviews-panel-scroll-container'>
          {interviewList && interviewList.get('ids') && (
            <DateGroupedInterviewList
              interviewIds={interviewList.get('ids')}
              popoverPlacement={popoverPlacement}
              renderCreatedBy={true}
              renderDescriptions={true}
              renderInterviewEditLink={true}
              renderSubjects={true}
              renderTitles={true}
              renderWhere={true}
            />
          )}
          {hasError && !isFetching && (
            <ErrorAlert
              onRetry={handleRefresh}
              title='There was an error fetching interviews'
            />
          )}
          {isFetching && <LoadingIndicator />}
        </div>
      </div>
    </div>
  );
};

export const propTypes = {
  canCreateInterview: PropTypes.bool,

  candidacyId: requiredIf(
    PropTypes.number,
    ({ parentType }) => parentType === PARENT_CANDIDACY,
  ),

  handleChange: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,

  interviewList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      error: PropTypes.any,
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  isSendingUpdateToAttendees: PropTypes.bool,

  /**
   * Optional string to set orientation of delete confirm popovers (if none is
   * passed it defaults to top)
   */
  popoverPlacement: PropTypes.string,

  searchId: requiredIf(
    PropTypes.number,
    ({ parentType }) => parentType === PARENT_SEARCH,
  ),
  setIsSendingUpdateToAttendees: PropTypes.func,
};

export const defaultProps = {
  canCreateInterview: false,
};

InterviewsPanel.propTypes = propTypes;
InterviewsPanel.defaultProps = defaultProps;

export default compose(
  setDisplayName('InterviewsPanel(enhanced)'),
  setPropTypes({
    candidacyId: requiredIf(PropTypes.number, ({ searchId }) => !searchId),
    searchId: requiredIf(PropTypes.number, ({ candidacyId }) => !candidacyId),
  }),
  withProps(({ candidacyId, interviewActions, searchId }) => {
    const parentType = candidacyId ? PARENT_CANDIDACY : PARENT_SEARCH;
    const parentId = candidacyId || searchId;

    return {
      parentType: parentType,
      parentId: parentId,
      handleRefresh: () =>
        interviewActions.fetchInterviewList({
          parentId: parentId,
          parentType: parentType,
        }),
    };
  }),
  withState(
    'isSendingUpdateToAttendees',
    'setIsSendingUpdateToAttendees',
    false,
  ),
  withHandlers({
    handleChange: ({ setIsSendingUpdateToAttendees }) => formState =>
      setIsSendingUpdateToAttendees(
        Boolean(formState.getFieldValue().integration_id),
      ),
  }),
  connect(
    state => ({
      canCreateInterview: canCreateInterview(state),
    }),
    {},
  ),
  mapParentToInterviewList,
  withInterviewListFetched,
)(InterviewsPanel);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import { debounce } from 'lodash';

import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';

import ContactDuplicates from './ContactDuplicates';
import connectContactActions from './connectContactActions';

const { caseInsensitiveEquals, trim } = stringUtils;

/**
 * Checks for contacts with duplicate email addresses and displays any matches found.
 */
export class ContactEmailDuplicates extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.queryDebounced = debounce(this.query, props.debounceDelay);
  }

  componentDidMount() {
    const { componentId, contactActions } = this.props;
    contactActions.createContactsQuery(componentId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { email } = this.props;
    const { email: nextEmail } = nextProps;

    if (!caseInsensitiveEquals(email, nextEmail)) {
      this.queryDebounced(nextEmail);
    }
  }

  componentWillUnmount() {
    const { componentId, contactActions } = this.props;
    contactActions.destroyContactsQuery(componentId);

    // Cancel any debounced queries
    this.queryDebounced.cancel();
  }

  query(email) {
    const { componentId, contactActions } = this.props;

    if (!trim(email)) {
      contactActions.clearContactsQuery({ queryId: componentId });
    } else {
      contactActions.fetchContactsQuery({
        queryId: componentId,
        term: email,
      });
    }
  }

  render() {
    const {
      componentId,
      contactActions: _contactActions,
      email,
      emailType,
      ...props
    } = this.props;

    return trim(email) ? (
      <ContactDuplicates
        fieldType={`${emailType} email`}
        queryId={componentId}
        {...props}
      />
    ) : null;
  }
}

ContactEmailDuplicates.propTypes = {
  /**
   * A unique ID for the component, used as the queryId
   */
  componentId: PropTypes.string.isRequired,

  /**
   * The actions for querying contacts
   */
  contactActions: PropTypes.shape({
    clearContactsQuery: PropTypes.func.isRequired,
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    fetchContactsQuery: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * The delay (in milliseconds) to debounce queries.
   */
  debounceDelay: PropTypes.number,

  /**
   * The email address value that has been entered and will be checked against for possible
   * duplicates.
   */
  email: PropTypes.string,

  /**
   * The type of email (work, personal, etc). Used for indicating which particular email
   * address a match was found for.
   */
  emailType: PropTypes.string.isRequired,
};

ContactEmailDuplicates.defaultProps = {
  debounceDelay: 300,
};

export default compose(
  setDisplayName('ContactEmailDuplicates(enhanced)'),
  setPropTypes({
    email: ContactEmailDuplicates.propTypes.email,
    emailType: ContactEmailDuplicates.propTypes.emailType,
  }),
  withComponentId(),
  connectContactActions,
)(ContactEmailDuplicates);

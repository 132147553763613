/* eslint-disable react/boolean-prop-naming, react/jsx-props-no-spreading, react/forbid-prop-types */
// ^ Accommodate legacy code. This should be fixed.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import withModalState from './withModalState';

/**
 * A button for launching a modal component.
 */
const ModalButton = ({
  alwaysRenderModal,
  children,
  className,
  disabled,
  handleHideModal,

  // This is provided by `withModalState`, but isn't used here, so pull it out of our props so
  // it doesn't get passed down to the modal component.
  handleShowModal: _handleShowModal,

  handleToggleModal,
  isModalShown,
  modal: ModalComponent,
  ...props
}) => (
  <>
    <button
      className={classnames('ModalButton', className, { disabled: disabled })}
      disabled={disabled}
      onClick={disabled ? null : handleToggleModal}
      type='button'
    >
      {children}
    </button>
    {(alwaysRenderModal || isModalShown) && (
      <ModalComponent {...props} onHide={handleHideModal} show={isModalShown} />
    )}
  </>
);

ModalButton.propTypes = {
  /**
   * Whether the underlying Modal component should be rendered even when it's not being
   * shown. In some cases we want the component to be created even when it's not being displayed,
   * and only actually visible to the user when `isModalShown` is true.
   */
  alwaysRenderModal: PropTypes.bool,

  /**
   * The content of the button that will show the modal.
   */
  children: PropTypes.node.isRequired,

  /**
   * The class names to apply to the button that triggers the modal display
   */
  className: PropTypes.string,

  /**
   * True to disabl the button; otherwise, false.
   */
  disabled: PropTypes.bool,

  /**
   * A handler that, when called, should cause `isModalShown` to be set to false.
   */
  handleHideModal: PropTypes.func.isRequired,

  /**
   * A handler that, when called, should cause `isModalShown` to be set to true.
   */
  handleShowModal: PropTypes.func.isRequired,

  /**
   * A handler that, when called, should toggle the value of `isModalShown`.
   */
  handleToggleModal: PropTypes.func.isRequired,

  /**
   * Whether the modal should be shown.
   */
  isModalShown: PropTypes.bool.isRequired,

  /**
   * The modal component. It should accept:
   * * a boolean `show` propType that indicates whether the modal should be visible.
   * * a function `onHide` propType that should be called when the modal is to be hidden.
   * Any unspecified props are passed through to the modal itself.
   */
  modal: PropTypes.any.isRequired,
};

ModalButton.defaultProps = {
  alwaysRenderModal: false,
  className: 'btn btn-default',
  disabled: false,
};

export default compose(
  setDisplayName('ModalButton(enhanced)'),
  setPropTypes({
    alwaysRenderModal: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    modal: PropTypes.any.isRequired,
  }),
  withModalState(),
)(ModalButton);

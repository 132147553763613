import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import CitizenshipTagSelectField from 'modules/tenant/components/CitizenshipTagSelectField';
import connectContactActions from './connectContactActions';
import mapContactIdToContact from './mapContactIdToContact';

/**
 * Renders a form for tagging a contact with citizenship
 * This form auto-submits whenever the underlying fieldState is changed, so there is no
 * "submit" button here, and it doesn't have/need a formState as is typical of a "standard"
 * form.
 */
export default compose(
  setDisplayName('ContactCitizenshipsForm'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  connectContactActions,
  withStateHandlers(
    ({ contact }) => ({
      fieldState: CitizenshipTagSelectField.createFieldState(
        'citizenships',
        contact.get('citizenships'),
      ),
    }),
    {
      onChange: (_, { contactActions, contactId }) => fieldState => {
        // Whenever the fieldState is changed, we update the contact with the new IDs immediately.
        contactActions.updateContact({
          contactId: contactId,
          id: contactId,
          contact: {
            citizenship_ids: fieldState.getValue(),
          },
        });

        return { fieldState: fieldState };
      },
    },
  ),
)(CitizenshipTagSelectField);

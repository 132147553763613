import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import CompanyAddressesTable from 'modules/companies/components/addresses/CompanyAddressesTable';
import CompanyAddressEditableSection from 'modules/companies/components/addresses/CompanyAddressEditableSection';
import CompanyAddressModalButton from 'modules/companies/components/addresses/CompanyAddressModalButton';
import CompanyBasicsEditableSection from 'modules/companies/components/basics/CompanyBasicsEditableSection';
import CompanyDescriptionEditableSection from 'modules/companies/components/description/CompanyDescriptionEditableSection';
import CompanyFinancialsEditableSection from 'modules/companies/components/financials/CompanyFinancialsEditableSection';
import CompanyIndustriesForm from 'modules/companies/components/industries/CompanyIndustriesForm';
import CompanyLastUpdatedRelativeTime from 'modules/companies/components/CompanyLastUpdatedRelativeTime';
import CompanyLogoEditButton from 'modules/companies/components/logo/CompanyLogoEditButton';
import CompanyMarketCapEditableSection from 'modules/companies/components/marketCap/CompanyMarketCapEditableSection';
import CompanyTargetFunctionsForm from 'modules/companies/components/CompanyTargetFunctionsForm';
import CompanyTargetMarketsForm from 'modules/companies/components/CompanyTargetMarketsForm';
import CompanySectorsForm from 'modules/companies/components/sectors/CompanySectorsForm';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import CompanyFundingRounds from 'modules/company-funding-rounds/components/CompanyFundingRounds';
import InvestmentsTableView from 'modules/company-investments/components/InvestmentsTableView';
import { CompanyPortfolioDetailsEditableSection } from 'modules/company-portfolio-details';
import CompanyCommentsPanel from 'modules/company-comments/components/CompanyCommentsPanel';
import CompanyEmployeeCountEditableSection from 'modules/company-employee-counts/components/CompanyEmployeeCountEditableSection';
import CompanyRevenueEditableSection from 'modules/company-revenues/components/CompanyRevenueEditableSection';
import SearchTable from 'modules/company-searches/SearchTable';
import CollapsiblePanel from 'modules/core/componentsLegacy/CollapsiblePanel';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import Tag from 'modules/core/components/Tag';
import DocumentTableView from 'modules/documents/components/DocumentTableView';
import ProfilePage from 'modules/layouts/profile/ProfilePage';
import ProfilePageSection from 'modules/layouts/profile/ProfilePageSection';
import routes from 'modules/routing/routes';
import getCompanyName from 'modules/companies/selectors/getCompanyName';
import getCompanyProperty from 'modules/companies/selectors/getCompanyProperty';
import hasPortfolioDetailsFeature from 'modules/company-portfolio-details/selectors/hasPortfolioDetailsFeature';
import isAdmin from 'modules/auth/selectors/isAdmin';
import isCrmUser from 'modules/auth/selectors/isCrmUser';
import SubsidiaryCompaniesTableView from 'modules/subsidiary-companies/SubsidiaryCompaniesTableView';
import AddSubsidiaryCompanyModal from 'modules/subsidiary-companies/AddSubsidiaryCompanyModal';
import CompanyContactsTableFilters from 'modules/companies/components/CompanyContactsTableFilters';
import useQuickView from 'modules/quick-view/useQuickView';
import Rating from '@thrivetrm/ui/components/Rating';
import RecruitmentActivityTable from 'modules/company-searches/RecruitmentActivityTable';
import CompanyContactsTable from 'modules/companies/components/CompanyContactsTable';
import SkyminyrCompanyEnrichButton from 'modules/companies/components/skyminyr/SkyminyrCompanyEnrichButton';
import TimeLabel from 'modules/datetime/components/TimeLabel';
import CompanyActivity from './CompanyActivity';

const defaultFilterValue = {
  notes: true,
  outreach: true,
  intros: true,
  references: true,
  meetings: true,
  interviews: true,
};
/**
 * Renders the company profile page.
 */
const CompanyPage = ({ companyId }) => {
  const hasAdminPermissions = useSelector(isAdmin);
  const hasMergePermissions = useFeatureCheck('feature.company_merge');
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const canMergeCompanies = hasAdminPermissions || hasMergePermissions;
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const [contactsTableFilters, setContactsTableFilters] = useState({
    // initial filters set to filter contacts by current position only
    filter_positions: '2',
    filter_past_company: '0',
    filter_current_company: '1',
  });

  const companyName = useSelector(state => getCompanyName(state, companyId));
  const primaryAddressId = useSelector(state =>
    getCompanyProperty(state, companyId, 'primary_address'),
  );
  const searchFirmSearchesCount = useSelector(state =>
    getCompanyProperty(state, companyId, 'search_firm_searches_count'),
  );
  const overallRating = useSelector(state =>
    getCompanyProperty(state, companyId, 'overall_rating'),
  );
  const overallRatingCount = useSelector(state =>
    getCompanyProperty(state, companyId, 'total_reviewers'),
  );
  const enrichedSectors = useSelector(state =>
    getCompanyProperty(state, companyId, 'enriched_sectors'),
  )?.toJS();
  const isDescriptionEnriched = useSelector(state =>
    getCompanyProperty(state, companyId, 'company_enriched_attributes'),
  )?.toJS()?.description_enriched;
  const crmUser = useSelector(isCrmUser);
  const hasPortfolioDetails = useSelector(state =>
    hasPortfolioDetailsFeature(state),
  );
  const lastSyncDate = useSelector(state =>
    getCompanyProperty(state, companyId, 'last_enriched_at'),
  );
  const isCompanyEnriched = useSelector(state =>
    getCompanyProperty(state, companyId, 'data_enriched'),
  );
  const { navigateTo } = useQuickView();
  const openQuickView = () => {
    const url = `/company/${companyId}/recruitment_ratings`;
    navigateTo(url, {
      title: companyName,
    });
  };

  const lastSync = new Date(lastSyncDate);

  function formatDateToString(dateSync) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return dateSync.toLocaleDateString('en-US', options);
  }

  // Created this key state to re-render the form components to invalidate the form fieldState on company enrichment success.
  // We are deleting all the records with ids on company enrich/re-enrich so form values and ids remains same when user does enrich/re-enrich while form is in editable state.
  // Otherwise it shows error on re-enrich as it uses old ids which are not exists in database.
  // https://thrivetrm.atlassian.net/browse/THRIVE-16197
  const [editableSectionKey, setEditableSectionKey] = useState(true);

  return (
    <ProfilePage className='CompanyPage'>
      <Helmet>
        <title>{companyName}</title>
      </Helmet>
      <header className='ProfilePage__header'>
        <div>
          {canMergeCompanies && (
            <DropdownButton
              bsSize='sm'
              id='contact-resume-uploader-select'
              title='Update Company From'
            >
              {canMergeCompanies && (
                <MenuItem
                  data-testid='duplicate-merge-item'
                  href={routes.merge_company({
                    id: companyId,
                  })}
                >
                  Another Company (Merge)
                </MenuItem>
              )}
            </DropdownButton>
          )}
          {hasSkyminyrFeature ? (
            <SkyminyrCompanyEnrichButton
              companyId={companyId}
              onEnrichmentStart={() => setEditableSectionKey(key => !key)}
            />
          ) : null}
        </div>
        <div>
          <LabeledContent
            className='CompanyPage__last-updated'
            inline={true}
            label='Last Updated'
          >
            <CompanyLastUpdatedRelativeTime companyId={companyId} />
          </LabeledContent>
          {hasSkyminyrFeature && isCompanyEnriched ? (
            <LabeledContent inline={true} label='Last Synced'>
              <TimeLabel time={formatDateToString(lastSync)} />
            </LabeledContent>
          ) : null}
        </div>
      </header>

      <ProfilePageSection
        sidebar={
          <div className='CompanyPage__sidebar'>
            <div className='CompanyPage__overview'>
              <CompanyLogoEditButton companyId={companyId} thumbnail={true} />
              <CompanyBasicsEditableSection
                companyId={companyId}
                key={`${editableSectionKey}-basic`}
              />
            </div>
            <CompanyCommentsPanel
              className='CompanyPage__comments'
              companyId={companyId}
            />
          </div>
        }
      >
        <CompanyAddressEditableSection
          addressId={primaryAddressId}
          companyId={companyId}
          key={`${editableSectionKey}-address`}
        />
        <CompanyDescriptionEditableSection
          companyId={companyId}
          isEnriched={hasSkyminyrFeature && isDescriptionEnriched}
          key={`${editableSectionKey}-description`}
        />
        <CompanyRevenueEditableSection
          companyId={companyId}
          key={`${editableSectionKey}-revenue`}
        />
        <CompanyMarketCapEditableSection companyId={companyId} />
        <CompanyEmployeeCountEditableSection
          companyId={companyId}
          key={`${editableSectionKey}-employeeCount`}
        />
        {hasPortfolioDetails && (
          <CompanyPortfolioDetailsEditableSection companyId={companyId} />
        )}
        <CompanyFinancialsEditableSection
          companyId={companyId}
          key={`${editableSectionKey}-financial`}
        />
        <h4>Industries</h4>
        <CompanyIndustriesForm companyId={companyId} />
        <h4>Sectors</h4>
        <CompanySectorsForm
          className={classNames({
            'u-marginBottom-n':
              hasSkyminyrFeature && enrichedSectors?.length > 0,
          })}
          companyId={companyId}
        />
        {hasSkyminyrFeature && enrichedSectors?.length > 0 ? (
          <div className='u-marginTop-2 u-marginBottom-16'>
            <div className='u-flex u-flexAlign-c'>
              <p className='u-textColor-gray40 u-margin-n'>Enriched</p>
              <Tooltip
                content='Enriched tags are in sync with the golden record and non-editable'
                size='large'
              >
                <StatusIndicator
                  className='u-flex u-marginLeft-4'
                  size='small'
                  status='info'
                />
              </Tooltip>
            </div>
            {enrichedSectors.map(enrichedSector => (
              <Tag key={enrichedSector} name={enrichedSector} />
            ))}
          </div>
        ) : null}
        <h4>Target Markets</h4>
        <CompanyTargetMarketsForm companyId={companyId} />
        <h4>Target Functions</h4>
        <CompanyTargetFunctionsForm companyId={companyId} />
      </ProfilePageSection>

      <CollapsiblePanel isInitiallyExpanded={true} title='Activity Timeline'>
        <CompanyActivity
          companyId={companyId}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
        />
      </CollapsiblePanel>

      <CollapsiblePanel
        className='CompanyPage__contacts'
        key='contacts'
        title='Contacts'
      >
        <CompanyContactsTableFilters
          companyId={companyId}
          initialFilters={contactsTableFilters}
          onChange={setContactsTableFilters}
        />
        <CompanyContactsTable
          companyId={companyId}
          filters={contactsTableFilters}
        />
      </CollapsiblePanel>

      <CollapsiblePanel
        className='CompanyPage__addresses'
        key='addresses'
        title='Addresses'
      >
        <CompanyAddressModalButton
          className='pull-right'
          companyId={companyId}
          primaryAddressId={primaryAddressId}
        >
          <i className='fa fa-plus-circle' />
          <span> Add Address</span>
        </CompanyAddressModalButton>
        <CompanyAddressesTable
          companyId={companyId}
          primaryAddressId={primaryAddressId}
        />
      </CollapsiblePanel>

      {crmUser ? null : (
        <CollapsiblePanel
          className='CompanyPage__searches'
          key='searches'
          title='Searches'
        >
          <SearchTable companyId={companyId} />
        </CollapsiblePanel>
      )}

      <CollapsiblePanel
        className='CompanyPage__subsidiaryCompanies'
        title='Subsidiary Companies'
      >
        <AddSubsidiaryCompanyModal companyId={companyId} />
        <SubsidiaryCompaniesTableView companyId={companyId} />
      </CollapsiblePanel>

      {searchFirmSearchesCount > 0 && !crmUser ? (
        <CollapsiblePanel
          className='CompanyPage__recruitment-activity'
          key='recruitment-searches'
          title='Recruitment Activity'
        >
          <div
            className='u-fontWeight-bold u-textAlign-r'
            data-testid='overall-rating-title'
          >
            Overall Rating
          </div>
          {overallRating ? (
            <div className='u-textAlign-r'>
              <Rating
                className='btn btn-link u-inlineFlex'
                data-testid='overall-rating-button'
                onClick={openQuickView}
                totalReviewers={overallRatingCount}
                value={overallRating}
              />
            </div>
          ) : (
            <div className='u-textAlign-r u-marginBottom-8 u-marginTop-4'>
              <Rating
                className='u-inlineBlock'
                data-testid='overall-rating-button'
                value={0}
              />
              <span className='u-paddingLeft-4'>Not Rated</span>
            </div>
          )}
          <RecruitmentActivityTable companyId={companyId} />
        </CollapsiblePanel>
      ) : null}

      <CollapsiblePanel key='investments' title='Investments'>
        <InvestmentsTableView companyId={companyId} />
      </CollapsiblePanel>

      <CollapsiblePanel
        className='CompanyPage__funding-rounds'
        key='funding-rounds'
        title='Funding Rounds'
      >
        <CompanyFundingRounds companyId={companyId} />
      </CollapsiblePanel>

      <CollapsiblePanel
        className='CompanyPage__documents'
        key='documents'
        title='Documents'
      >
        <DocumentTableView ownerId={companyId} ownerType='Company' />
      </CollapsiblePanel>
    </ProfilePage>
  );
};

CompanyPage.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default CompanyPage;

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { URL_REGEX } from 'modules/core/urlUtils';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import formDataPropType from '@thrivetrm/ui/propTypes/formDataPropType';

const NotesAndDocuments = ({ formData, onSubmit }) => {
  const history = useHistory();

  return (
    <Card className='SearchRequestPage__Card' type='shadow'>
      <Form initialValues={formData} onSubmit={onSubmit}>
        <h2 className='u-marginBottom-24 u-marginTop-12 u-textAlign-c'>
          Notes and Documents
        </h2>
        <Form.TextArea
          className='u-marginBottom-24'
          label='Share any additional context about this role.'
          name='qualifications'
          rules={{
            required: {
              value: true,
              message: 'Additional context is required.',
            },
          }}
          width='full'
        />

        <Form.TextInput
          className='u-marginBottom-24'
          inputWidth='full'
          label='Link any additional documentation (job description, position
            profile, etc).'
          name='documentation_url'
          placeholder='Enter a URL…'
          rules={{
            pattern: {
              value: URL_REGEX,
              message: 'Must be a complete URL such as https://www.example.com',
            },
          }}
        />
        <div className='u-flex u-width-100 u-flexJustify-spaceBetween'>
          <ButtonPrimary
            label='‹ Back'
            onClick={() => history.push('role_details')}
          />
          <Form.SubmitButton label='Submit ›' />
        </div>
      </Form>
    </Card>
  );
};

NotesAndDocuments.defaultProps = {
  formData: null,
};

NotesAndDocuments.propTypes = {
  formData: formDataPropType,
  onSubmit: PropTypes.func.isRequired,
};

export default NotesAndDocuments;

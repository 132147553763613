/**
 * The list of minimum revenue options available to select from.
 */
export const REVENUE_MINIMUM_OPTIONS = [
  { value: 0, label: '0' },
  { value: 10, label: '10M' },
  { value: 30, label: '30M' },
  { value: 50, label: '50M' },
  { value: 100, label: '100M' },
];

/**
 * The list of maximum revenue options available to select from.
 */
export const REVENUE_MAXIMUM_OPTIONS = [
  { value: 10, label: '10M' },
  { value: 30, label: '30M' },
  { value: 50, label: '50M' },
  { value: 100, label: '100M' },
  { value: 999999, label: '100M+' },
];

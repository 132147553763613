import React from 'react';
import PropTypes from 'prop-types';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';

const TAG_LIMIT = 5;

const CandidacyTagColorSquareList = ({ className, tagsList }) => (
  <div className={className}>
    {tagsList.slice(0, TAG_LIMIT).map(({ color, id, name }) => (
      <TooltipTrigger key={id} placement='top' tooltip={name}>
        <span
          className='CandidacyTagColorSquareList__colorSquare u-marginRight-4'
          data-testid={name}
          style={{
            background: color,
          }}
        />
      </TooltipTrigger>
    ))}
    {tagsList.length > TAG_LIMIT ? (
      <TooltipTrigger
        placement='top'
        tooltip={tagsList
          ?.slice(TAG_LIMIT)
          .map(({ name }) => name)
          .join(', ')}
      >
        <span
          className='CandidacyTagColorSquareList__moreTag u-paddingHorizontal-4'
          style={{ backgroundColor: '#DBDBDB' }}
        >
          +{tagsList.length - TAG_LIMIT}
        </span>
      </TooltipTrigger>
    ) : null}
  </div>
);

CandidacyTagColorSquareList.propTypes = {
  className: PropTypes.string,
  tagsList: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

CandidacyTagColorSquareList.defaultProps = {
  className: '',
  tagsList: [],
};

export default CandidacyTagColorSquareList;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, setStatic } from 'recompose';
import { Map, List } from 'immutable';
import classnames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';
import PronounTagSelectField from 'modules/tenant/components/PronounTagSelectField';
import contactPrefixSchema from 'modules/tenant/schemas/contactPrefixes';
import pronounSchema from 'modules/tenant/schemas/pronouns';
import ContactAddressAs from 'modules/contacts/components/ContactAddressAs';
import withTenantOptionsEntityIdsFetched from 'modules/tenant/components/withTenantOptionsEntityIdsFetched';
import AliasListField from 'modules/aliases/AliasListField';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';

import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import ContactDuplicates from './ContactDuplicates';
import withContactDuplicates from '../withContactDuplicates';

export const layouts = {
  grid: {
    column: 'col-sm-6 basic-field',
  },

  stacked: {
    column: 'col-12',
  },
};

class BasicField extends Component {
  static createFieldState = (name = 'contact', contact) => {
    const values = new Map({
      first_name: '',
      last_name: '',
      preferred_name: '',
    }).merge(contact.get('data') || contact);

    return FieldState.createNested(
      name,
      [
        AliasListField.createFieldState(
          'aliases_attributes',
          values.get('aliases')?.toArray() || [],
        ),
        CheckboxField.createFieldState(
          'is_work_email_preferred',
          values.get('work_email_preferred') === true,
        ),
        CheckboxField.createFieldState(
          'is_personal_email_preferred',
          values.get('work_email_preferred') === false,
        ),
        InputField.createFieldState(
          'first_name',
          values.get('first_name'),
          validators.requiredField('First Name'),
        ),
        InputField.createFieldState('middle_name', values.get('middle_name')),
        InputField.createFieldState(
          'last_name',
          values.get('last_name'),
          validators.requiredField('Last Name'),
        ),
        InputField.createFieldState(
          'email',
          values.get('email'),
          validators.emailAddress,
        ),
        InputField.createFieldState(
          'work_email',
          values.get('work_email'),
          validators.emailAddress,
        ),
        InputField.createFieldState(
          'contact_prefix_id',
          values.get('contact_prefix_id'),
        ),
        InputField.createFieldState(
          'preferred_name',
          values.get('preferred_name'),
        ),
        PronounTagSelectField.createFieldState(
          'pronoun_ids',
          values.get('pronouns'),
        ),
      ],
      null,
      fieldValue => {
        let workEmailPreferred = null;

        if (fieldValue.is_work_email_preferred) {
          workEmailPreferred = true;
        } else if (fieldValue.is_personal_email_preferred) {
          workEmailPreferred = false;
        }

        const result = {
          ...fieldValue,
          work_email_preferred: workEmailPreferred,
        };

        delete result.is_work_email_preferred;
        delete result.is_personal_email_preferred;

        return result;
      },
    );
  };

  constructor(...args) {
    super(...args);
    this._componentId = uniqueId();
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    const fieldName = childFieldState.getName();
    let nextFieldState = fieldState.setNestedField(childFieldState);

    const personalEmailPreferred = fieldState.getNestedField(
      'is_personal_email_preferred',
    );
    const workEmailPreferred = fieldState.getNestedField(
      'is_work_email_preferred',
    );

    if (fieldName === 'is_work_email_preferred') {
      if (workEmailPreferred.getValue() === false) {
        nextFieldState = nextFieldState
          .setNestedField(workEmailPreferred.setValue(true))
          .setNestedField(personalEmailPreferred.setValue(false));
      }
    }

    if (fieldName === 'is_personal_email_preferred') {
      if (personalEmailPreferred.getValue() === false) {
        nextFieldState = nextFieldState
          .setNestedField(workEmailPreferred.setValue(false))
          .setNestedField(personalEmailPreferred.setValue(true));
      }
    }

    onChange(nextFieldState, childFieldState.getName());
  };

  render() {
    const {
      addressAs,
      contactPrefixIds,
      duplicates,
      fieldState,
      handleDuplicateSubmit,
      hasContactAliases,
      isEditingContact,
      layout,
      pronounIds,
      showAddButton,
      showAliasesField,
      showDuplicates,
      showEmailFields,
      showPreferredNameField,
      showPronounsField,
      user,
    } = this.props;

    const shouldRenderAddressAs =
      isEditingContact && (contactPrefixIds?.length || pronounIds?.length);

    return (
      <div className='new-contact-field'>
        {showDuplicates && (
          <ContactDuplicates
            {...this.props}
            duplicates={duplicates}
            handleDuplicateSubmit={handleDuplicateSubmit}
            hideProfileLink={user && user.get('limited_access_roles')}
            showAddButton={showAddButton}
          />
        )}

        {isEditingContact ? <h3 className='u-marginTop-8'>Name</h3> : null}

        <div className='row'>
          <div className={layout.column}>
            <InputField
              className={classnames({ 'has-error': duplicates.name.size })}
              fieldState={fieldState.getNestedField('first_name')}
              label='* First Name'
              name='first_name'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className={layout.column}>
            <InputField
              className={classnames({ 'has-error': duplicates.name.size })}
              fieldState={fieldState.getNestedField('last_name')}
              label='* Last Name'
              name='last_name'
              onChange={this.handleFieldChange}
            />
          </div>
          {isEditingContact ? (
            <div className={layout.column}>
              <InputField
                fieldState={fieldState.getNestedField('middle_name')}
                label='Middle Name'
                name='middle_name'
                onChange={this.handleFieldChange}
              />
            </div>
          ) : null}
          {showPreferredNameField ? (
            <div className={layout.column}>
              <InputField
                fieldState={fieldState.getNestedField('preferred_name')}
                label='Preferred Name'
                name='preferred_name'
                onChange={this.handleFieldChange}
              />
            </div>
          ) : null}
          {hasContactAliases && showAliasesField ? (
            <div className={layout.column}>
              <AliasListField
                fieldState={fieldState.getNestedField('aliases_attributes')}
                label='Aliases'
                name='aliases_attributes'
                onChange={this.handleFieldChange}
                recordType='contact'
              />
            </div>
          ) : null}
        </div>

        {shouldRenderAddressAs ? (
          <>
            <div className='u-flex u-flexAlign-c u-marginBottom-12'>
              <h3 className='u-margin-n'>Address as</h3>
              <ContactAddressAs
                addressAs={addressAs}
                className='u-marginLeft-4'
              />
            </div>
            <div className='row'>
              {contactPrefixIds && contactPrefixIds.length > 0 ? (
                <TenantOptionsSelectField
                  className={layout.column}
                  clearable={true}
                  fieldState={fieldState.getNestedField('contact_prefix_id')}
                  key='prefix'
                  label='Prefix'
                  onChange={this.handleFieldChange}
                  placeholder='Select...'
                  schema={contactPrefixSchema}
                />
              ) : null}
              {showPronounsField && pronounIds && pronounIds.length > 0 ? (
                <div className={layout.column}>
                  <PronounTagSelectField
                    fieldState={fieldState.getNestedField('pronoun_ids')}
                    label='Pronouns'
                    name='pronoun_ids'
                    onChange={this.handleFieldChange}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : null}

        {showEmailFields ? (
          <div className='row'>
            <div className={layout.column}>
              <div className='formgroup-with-label-addon'>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      id={`tooltip-for-create-contact-email-${this.componentId}`}
                    >
                      <div>Preferred Email is shared in Searches</div>
                    </Tooltip>
                  }
                  placement='top'
                  trigger={['hover']}
                >
                  <div className='formgroup-label-addon'>
                    <CheckboxField
                      checked={fieldState
                        .getNestedField('is_personal_email_preferred')
                        .getValue()}
                      fieldState={fieldState.getNestedField(
                        'is_personal_email_preferred',
                      )}
                      key='is_personal_email_preferred'
                      onChange={this.handleFieldChange}
                      text='Preferred'
                    />
                  </div>
                </OverlayTrigger>
                <InputField
                  className={classnames({ 'has-error': duplicates.email.size })}
                  fieldState={fieldState.getNestedField('email')}
                  label='Personal Email'
                  name='email'
                  onChange={this.handleFieldChange}
                />
              </div>
            </div>
            <div className={layout.column}>
              <div className='formgroup-with-label-addon'>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      id={`tooltip-for-create-contact-work-email-${this.componentId}`}
                    >
                      <div>Preferred Email is shared in Searches</div>
                    </Tooltip>
                  }
                  placement='top'
                  trigger={['hover']}
                >
                  <div className='formgroup-label-addon'>
                    <CheckboxField
                      checked={fieldState
                        .getNestedField('is_work_email_preferred')
                        .getValue()}
                      fieldState={fieldState.getNestedField(
                        'is_work_email_preferred',
                      )}
                      key='is_work_email_preferred'
                      onChange={this.handleFieldChange}
                      text='Preferred'
                    />
                  </div>
                </OverlayTrigger>
                <InputField
                  className={classnames({
                    'has-error': duplicates.work_email.size,
                  })}
                  fieldState={fieldState.getNestedField('work_email')}
                  label='Work email'
                  name='work_email'
                  onChange={this.handleFieldChange}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

BasicField.propTypes = {
  addressAs: PropTypes.string,

  contactPrefixIds: PropTypes.arrayOf(PropTypes.number),

  duplicates: PropTypes.shape({
    email: PropTypes.instanceOf(List),
    name: PropTypes.instanceOf(List),
    work_email: PropTypes.instanceOf(List),
  }),

  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  handleDuplicateSubmit: PropTypes.func,

  hasContactAliases: PropTypes.bool,

  isEditingContact: PropTypes.bool,

  layout: PropTypes.objectOf(PropTypes.string),

  onChange: PropTypes.func.isRequired,

  pronounIds: PropTypes.arrayOf(PropTypes.number),

  /**
   * show the 'Add to Search' button? (visible in New Contact modal)
   */
  showAddButton: PropTypes.bool,

  showAliasesField: PropTypes.bool,

  showDuplicates: PropTypes.bool,

  showEmailFields: PropTypes.bool,

  showPreferredNameField: PropTypes.bool,

  showPronounsField: PropTypes.bool,

  user: ImmutablePropTypes.mapContains({
    limited_access_roles: PropTypes.bool,
  }),
};

BasicField.defaultProps = {
  addressAs: null,

  isEditingContact: true,

  layout: layouts.grid,

  showAliasesField: true,

  showEmailFields: true,

  showPreferredNameField: true,

  showPronounsField: true,
};

export default compose(
  setStatic('createFieldState', BasicField.createFieldState),
  withContactDuplicates(),
  withTenantOptionsEntityIdsFetched({
    schema: pronounSchema,
    entityIdsPropName: 'pronounIds',
  }),
  withTenantOptionsEntityIdsFetched({
    schema: contactPrefixSchema,
    entityIdsPropName: 'contactPrefixIds',
  }),
  withFeatureCheck('feature.contact_aliases', 'hasContactAliases'),
)(BasicField);

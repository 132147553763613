import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import TextInput from '@thrivetrm/ui/components/TextInput';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import PropTypes from 'prop-types';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import isAdmin from 'modules/auth/selectors/isAdmin';
import ColorInput from 'modules/color/components/ColorInput';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Icon from '@thrivetrm/ui/components/Icon';
import {
  useDeleteTagMutation,
  useUpdateTagMutation,
} from 'services/apiV1/candidateTags';
import { convertToCamelCase } from 'modules/core/jsonUtils';

const CandidacySearchTagModalRow = ({
  color,
  createdBy,
  handleSelectTag,
  id,
  isDisabled,
  isIndeterminate,
  isSelected,
  label,
  onDeleteSuccess,
  onEditSuccess,
}) => {
  const currentUserId = useSelector(getCurrentUserId);
  const isAdminUser = useSelector(isAdmin);

  const [isEditing, startEditing, stopEditing] = useToggle(false);
  const [tagColor, setColor] = useState(color);
  // handled tagName manually instead of Form as we need to show error if its coming from backend response
  const [tagName, setTagName] = useState(label);

  const [deleteTag, { isLoading: isDeletingTag }] = useDeleteTagMutation();
  const [
    updateTag,
    { error: tagUpdateError, isLoading: isUpdatingTag },
  ] = useUpdateTagMutation();

  const handleDeleteTag = () => {
    deleteTag({ tagId: id })
      .unwrap()
      .then(() => onDeleteSuccess(id));
  };

  const handleUpdateTag = () => {
    updateTag({
      tagId: id,
      payload: { tag: { color: tagColor, name: tagName } },
    })
      .unwrap()
      .then(tag => {
        stopEditing();
        onEditSuccess(convertToCamelCase(tag));
      });
  };

  const getErrorMessages = error => `Name ${error.data.error?.name[0]}`;

  const actionButtons =
    isAdminUser || currentUserId === createdBy?.id ? (
      <>
        <ButtonSecondary
          className='u-marginRight-8'
          icon='edit'
          onClick={startEditing}
          size='small'
        />
        <ConfirmationPopoverButton
          className='Button Button--secondary Button--hasIconOnly Button--small u-margin-n'
          data-testid={`Delete-${label}`}
          disabled={isDeletingTag}
          isActionDanger={true}
          key='delete'
          label={<Icon size='small' type='delete' />}
          onConfirm={handleDeleteTag}
          popoverPlacement='left'
          title='Are you sure?'
        >
          This tag will be deleted from all candidates.
        </ConfirmationPopoverButton>
      </>
    ) : null;

  return (
    <tr className='CandidacySearchTagModalRow' key={id}>
      <td className='CandidacySearchTagModalRow__tableData u-paddingTop-12'>
        <Checkbox
          data-testid={label}
          isChecked={isSelected}
          isDisabled={isDisabled}
          isIndeterminate={isIndeterminate}
          onChange={() => handleSelectTag(id)}
        />
      </td>
      <td className='CandidacySearchTagModalRow__tableData'>
        {isEditing ? (
          <ColorInput onChange={data => setColor(data.hex)} value={tagColor} />
        ) : (
          <div
            aria-label={color}
            className='CandidacySearchTagModalRow__colorBox'
            style={{ backgroundColor: color }}
          />
        )}
      </td>
      <td className='CandidacySearchTagModalRow__tableData'>
        {isEditing ? (
          <TextInput
            errorMessage={
              tagUpdateError ? getErrorMessages(tagUpdateError) : ''
            }
            inputWidth='full'
            onChange={setTagName}
            placeholder='Tag label'
            value={tagName}
          />
        ) : (
          label
        )}
      </td>
      <td className='CandidacySearchTagModalRow__tableData'>
        {createdBy?.name}
      </td>
      <td className='CandidacySearchTagModalRow__tableData'>
        {isEditing ? (
          <>
            <ConfirmationPopoverButton
              className='Button Button--secondary Button--small'
              disabled={isUpdatingTag || !tagName.trim().length}
              label='Save'
              onConfirm={handleUpdateTag}
              popoverPlacement='left'
              title='Update this tag?'
            >
              This will update the tag for all candidates.
            </ConfirmationPopoverButton>
            <ButtonSecondary
              className='u-marginLeft-8'
              icon='refresh'
              onClick={stopEditing}
              size='small'
            />
          </>
        ) : (
          actionButtons
        )}
      </td>
    </tr>
  );
};

CandidacySearchTagModalRow.defaultProps = {
  isDisabled: false,
  isIndeterminate: false,
  isSelected: false,
};

CandidacySearchTagModalRow.propTypes = {
  color: PropTypes.string,
  createdBy: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  handleSelectTag: PropTypes.func,
  id: PropTypes.number,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  onDeleteSuccess: PropTypes.func,
  onEditSuccess: PropTypes.func,
};

export default CandidacySearchTagModalRow;

import { convertToCamelCase } from 'modules/core/jsonUtils';
import apiV1 from './index';
import queryTags from './queryTags';

const { CONNECTIONS, REFERENCES, REPORTING_RELATIONSHIPS } = queryTags;

const references = apiV1.injectEndpoints({
  endpoints: builder => ({
    getCandidacyReferences: builder.query({
      query: candidacyId => ({
        url: `/candidacies/${candidacyId}/references`,
        method: 'GET',
      }),
      providesTags: [REFERENCES],
      transformResponse: response => convertToCamelCase(response?.references),
    }),
    createCandidacyReference: builder.mutation({
      query: ({ candidacyId, payload }) => ({
        url: `/candidacies/${candidacyId}/references`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [REFERENCES, CONNECTIONS, REPORTING_RELATIONSHIPS],
    }),
    createContactReference: builder.mutation({
      query: ({ contactId, payload }) => ({
        url: `/contacts/${contactId}/references`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [CONNECTIONS, CONNECTIONS, REPORTING_RELATIONSHIPS],
    }),
    getReference: builder.query({
      query: referenceId => ({
        url: `/references/${referenceId}`,
        method: 'GET',
      }),
      providesTags: [REFERENCES],
      transformResponse: response => convertToCamelCase(response?.reference),
    }),
    updateReference: builder.mutation({
      query: ({ payload, referenceId }) => ({
        url: `/references/${referenceId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [REFERENCES, CONNECTIONS, REPORTING_RELATIONSHIPS],
    }),
    deleteReference: builder.mutation({
      query: referenceId => ({
        url: `/references/${referenceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [REFERENCES, CONNECTIONS, REPORTING_RELATIONSHIPS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCandidacyReferenceMutation,
  useCreateContactReferenceMutation,
  useDeleteReferenceMutation,
  useGetCandidacyReferencesQuery,
  useGetReferenceQuery,
  useUpdateReferenceMutation,
} = references;

import PropTypes from 'prop-types';
import React from 'react';
import ContactAvatar from 'modules/contacts/components/ContactAvatar';

const GuestAssessmentPageHeader = ({
  candidateAvatar,
  candidateCompany,
  candidateName,
  candidateTitle,
  searchName,
}) => (
  <div className='GuestAssessmentPageHeader'>
    <h3 className='search-name'>{searchName}</h3>
    <ContactAvatar url={candidateAvatar} useLegacyStyles={true} />
    <div className='GuestAssessmentPageHeader__candidate-info'>
      <div>
        <strong>{candidateName}</strong>
      </div>
      <div>{candidateTitle}</div>
      <div>{candidateCompany}</div>
    </div>
  </div>
);

GuestAssessmentPageHeader.propTypes = {
  /**
   * The URL to the avatar for the candidate being assessed
   */
  candidateAvatar: PropTypes.string,

  /**
   * The name of the company of the candidate being assessed
   */
  candidateCompany: PropTypes.string,

  /**
   * The name of the candidate being assessed
   */
  candidateName: PropTypes.string.isRequired,

  /**
   * The title of the candidate being assessed
   */
  candidateTitle: PropTypes.string,

  /**
   * The name of the search that the canidiate belongs to
   */
  searchName: PropTypes.string.isRequired,
};

export default GuestAssessmentPageHeader;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import AppointmentForm from 'modules/appointments/components/AppointmentForm';
import Card from '@thrivetrm/ui/components/Card';
import { PERMITTED_TYPES } from '../constants';

import InlineEditActions from '../../../components/forms/InlineEditActions';

import connectInterviewActions from './connectInterviewActions';
import { InterviewView } from './InterviewView';
import mapInterviewIdToInterview from './mapInterviewIdToInterview';

class InterviewListItem extends Component {
  state = {
    /**
     * True when the item is being edited inline, false when being viewed.
     * @type {Boolean}
     */
    isEditing: false,
    /**
     * True when a calendar has been selected and updates will be sent to all
     * attendees
     * @type {Boolean}
     */
    isSendingUpdateToAttendees: false,
  };

  getSubmitLabel() {
    const { isEditing, isSendingUpdateToAttendees } = this.state;

    if (isSendingUpdateToAttendees) {
      // if a calendar was already selected, we're updating the invite, otherwise it's a new invite
      const isUpdatingInvite =
        isEditing && this.props.interview.get('integration_id');
      return isUpdatingInvite ? 'Update Invite' : 'Send Invite';
    }
    return 'Save';
  }

  /**
   * Called when the 'Yes' button of the delete confirmation is clicked.
   * Calls the action to delete the interview and resets the action.
   */
  handleDeleteClick = () => {
    const { interview, interviewActions, interviewId } = this.props;
    this.setState({
      isEditing: false,
    });

    interviewActions.deleteInterview({
      id: interviewId,

      // These are needed at the moment for the action to make it's way to the appropriate
      // reducers, but once everything is normalized we shouldn't need this anymore:
      searchId: interview.get('search_id'),
      candidacyId: interview.get('candidacy_id'),
    });
  };

  /**
   * Called when the edit link is clicked, shows the edit form in place of
   * the interview view.
   */
  handleEditClick = () => {
    this.setState({
      isEditing: true,
    });
  };

  /**
   * Called when the edit form is closed, removes the edit form and shows
   * the interview view instead.
   */
  handleFormClose = () => {
    this.setState({
      isEditing: false,
    });
  };

  /**
   * When the form is updated, check to see if a calendar integration has been
   * selected, if so, change the label on the submit button
   */
  handleFormChange = formState => {
    this.setState({
      isSendingUpdateToAttendees: Boolean(
        formState.getFieldValue().integration_id,
      ),
    });
  };

  render() {
    const { interview, interviewId, readOnly, ...interviewProps } = this.props;
    const { isEditing } = this.state;
    const isDeleting = interview.getIn(['_meta', 'isDeleting']);
    const searchId = interview.get('search_id');
    const candidacyId = interview.get('candidacy_id');
    const canDelete = interview.getIn(['permissions', 'delete']);
    const canEdit = interview.getIn(['permissions', 'edit']);
    const canClearCalendarSelection = !interview.get('integration_id');

    return (
      <li className='list-item-interview'>
        <Card isCentered={false}>
          {isEditing ? (
            <AppointmentForm
              alwaysShowFieldErrors={true}
              canClearCalendarSelection={canClearCalendarSelection}
              draftStoragePath={{
                search: searchId,
                candidate: candidacyId,
                interview: interviewId,
              }}
              errorDisplay='tooltip'
              formActionProps={{
                submitLabel: this.getSubmitLabel(),
              }}
              interview={interview}
              interviewId={interviewId}
              isEditing={isEditing}
              onCancel={this.handleFormClose}
              onChange={this.handleFormChange}
              onSaved={this.handleFormClose}
              permittedTypes={PERMITTED_TYPES}
            />
          ) : (
            <InterviewView interview={interview} {...interviewProps} />
          )}
          <InlineEditActions
            canDelete={!isEditing && !readOnly && canDelete}
            canEdit={!isEditing && !readOnly && canEdit}
            deleteConfirmation='Delete this interview?'
            disabled={isDeleting}
            onDelete={this.handleDeleteClick}
            onEdit={this.handleEditClick}
          />
        </Card>
      </li>
    );
  }
}

InterviewListItem.defaultProps = {
  readOnly: false,
  renderCandidateName: false,
  renderCreatedBy: false,
  renderDescription: false,
  renderSubject: false,
  renderTitleAsLink: false,
  renderWhere: false,
};

InterviewListItem.propTypes = {
  interview: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isDeleting: PropTypes.bool,
    }),
    permissions: ImmutablePropTypes.mapContains({
      delete: PropTypes.bool,
      edit: PropTypes.bool,
    }),
  }).isRequired,

  interviewActions: requiredIf(
    PropTypes.shape({
      deleteInterview: PropTypes.func.isRequired,
    }),
    props => !props.readOnly,
  ),

  interviewId: PropTypes.number.isRequired,

  readOnly: PropTypes.bool,

  renderCandidateName: PropTypes.bool,

  renderCreatedBy: PropTypes.bool,

  renderDescription: PropTypes.bool,

  renderSubject: PropTypes.bool,

  renderTitleAsLink: PropTypes.bool,

  renderWhere: PropTypes.bool,
};

export default compose(
  setDisplayName('InterviewListItem(enhanced)'),
  setPropTypes({
    interviewId: PropTypes.number.isRequired,
  }),
  mapInterviewIdToInterview,
  connectInterviewActions,
)(InterviewListItem);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import withContactForm from 'modules/contacts/components/withContactForm';
import CancelButton from 'modules/forms/components/CancelButton';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import InteractionLevelsField from './InteractionLevelsField';

/**
 * A form for editing a contact's "connections" fields.
 * @see InteractionLevelsField
 */
const InteractionLevelsForm = ({
  formState,
  onCancel,
  onFieldStateChange,
  onSubmit,
  ...fieldProps
}) => (
  <Form
    className='InteractionLevelsForm'
    formState={formState}
    onSubmit={onSubmit}
  >
    <InteractionLevelsField
      {...fieldProps}
      disabled={formState.isSubmitting()}
      fieldState={formState.getFieldState()}
      onChange={onFieldStateChange}
      showErrors={formState.wasSubmitted() || 'blurred'}
    />
    <FormErrorMessage formState={formState} />
    <div className='Form__footer'>
      <CancelButton onClick={onCancel} />
      <SubmitButton formState={formState} onClick={onSubmit} />
    </div>
  </Form>
);

InteractionLevelsForm.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the cancel button is clicked.
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('InteractionLevelsForm(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    onSaved: PropTypes.func.isRequired,
  }),

  // Provides the field state, form state, and submission handling.
  withContactForm(InteractionLevelsField),

  /**
   * Prevent props from being passed on to the underlying fieldState
   */
  mapProps(
    ({
      _contactId,
      _onFormStateChange,
      _onResetFormState,
      _onSaved,
      ...rest
    }) => ({
      ...rest,
    }),
  ),
)(InteractionLevelsForm);

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LocationIcon from './LocationIcon';

/**
 * Determines if, given an address record, the AddressView component will render. If there is no
 * available data to render, AddressView will render `null`, and this should return false when
 * called with the same record.
 * @param {Object} params
 * @param {Immutable.Map} [params.value] The record that contains the address fields
 * @param {String} [params.value.address_line_1] The first line of the street address
 * @param {String} [params.value.address_line_2] The second line of the street address
 * @param {String} [params.value.location] The combination of city/region/state/country
 * @param {String} [params.value.postal_code] The postal code ofthe address.
 */
const willRender = ({ value }) =>
  Boolean(
    value &&
      (value.get('address_line_1') ||
        value.get('address_line_2') ||
        value.get('country_code') ||
        value.get('country') ||
        value.get('label') ||
        value.get('location') ||
        value.get('postal_code')),
  );

/**
 * Renders an address from it's component parts.
 * value.get('location') will either be city, state or city, country. In cases where
 * it is city, country, we do not want to render the country twice.
 */
const AddressView = ({ hideLocationIcon, value }) => {
  if (!willRender({ value: value })) {
    return null;
  }

  const isUsAddress =
    value &&
    (value.get('country') === 'United States' ||
      value.get('country_code') === 'US');

  return (
    <div className='AddressView'>
      {!hideLocationIcon && <LocationIcon />}
      <div className='AddressView__location'>
        {value.get('label') && <div>{value.get('label')}</div>}
        {value.get('address_line_1') && (
          <div>{value.get('address_line_1')}</div>
        )}
        {value.get('address_line_2') && (
          <div>{value.get('address_line_2')}</div>
        )}
        <div>
          {value.get('location')} {value.get('postal_code')}
        </div>
        {isUsAddress && (
          <div>{value.get('country') || value.get('country_code')}</div>
        )}
      </div>
    </div>
  );
};

AddressView.willRender = willRender;

AddressView.propTypes = {
  /**
   * Hide the location icon next to the address
   */
  hideLocationIcon: PropTypes.bool,

  /**
   * The record that contains the address fields.
   */
  value: ImmutablePropTypes.mapContains({
    /**
     * The first line of the street address
     */
    address_line_1: PropTypes.string,

    /**
     * The second line of the street address
     */
    address_line_2: PropTypes.string,

    /**
     * The city/state combination
     */
    location: PropTypes.string,

    /**
     * The postal code.
     */
    postal_code: PropTypes.string,
  }),
};

export default AddressView;

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import * as filterUtils from 'modules/core/filterUtils';

import getJobApplicationById from '../selectors/getJobApplicationById';

/**
 * A Higher order component that filters a list of jobApplications based on a `filterText`
 * value.
 */
export default compose(
  setDisplayName('withJobApplicationIdsFiltered'),
  setPropTypes({
    filterText: PropTypes.string,
    jobApplicationIds: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  connect((state, { filterText, jobApplicationIds }) => {
    if (
      !filterText ||
      !filterText.trim() ||
      !jobApplicationIds ||
      jobApplicationIds.count() < 1
    ) {
      return {};
    }

    const filterRegex = filterUtils.createMatchAllKeywordsRegex(filterText);

    return {
      jobApplicationIds: jobApplicationIds.filter(jobApplicationId => {
        const jobApplication = getJobApplicationById(state, jobApplicationId);
        if (!jobApplication) {
          return null;
        }

        // Concatenates the fields we're checking, with spaces between to avoid
        // forming new words.
        const testString = ['first_name', 'last_name', 'email'].reduce(
          (acc, key) => [acc, jobApplication.get(key)].join(' '),
          '',
        );

        return filterRegex.test(testString);
      }),
    };
  }),
);

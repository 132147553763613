import React from 'react';
import PropTypes from 'prop-types';
import ExpandableActivityView from './ExpandableActivityView';

const AssessmentActivityView = ({ addedBy, comment }) => (
  <div className='u-marginTop-4 u-fontSize-small'>
    {addedBy ? <p className='u-margin-n'>Assessed by {addedBy}</p> : null}
    <ExpandableActivityView content={comment} />
  </div>
);

AssessmentActivityView.propTypes = {
  addedBy: PropTypes.string,
  comment: PropTypes.string,
};

export default AssessmentActivityView;

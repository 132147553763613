import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select-legacy';
import getEntityProperty from '../selectors/getEntityProperty';

const NON_BREAKING_SPACE = '\u00A0';
const SPACES_PER_INDENT = 2;

const getIndentedLeafName = tagName => {
  const tagParts = tagName.split(' > ');
  return tagParts
    .map((tag, index) =>
      index === tagParts.length - 1
        ? tag
        : NON_BREAKING_SPACE.repeat((index + 1) * SPACES_PER_INDENT),
    )
    .join('');
};

const EntitySelect = ({
  entityIds,
  entityType,
  labelPropertyName,
  staticOptions,
  ...props
}) => {
  const [isFiltering, setIsFiltering] = useState(false);
  const onInputChange = query => setIsFiltering(Boolean(query));
  const entityIdsArr =
    entityIds && (entityIds.toArray ? entityIds.toArray() : entityIds);

  const options = useSelector(state =>
    entityIdsArr
      ? staticOptions.concat(
          entityIdsArr.map(id => ({
            id: id,
            name: isFiltering
              ? getEntityProperty(state, entityType, id, labelPropertyName)
              : getIndentedLeafName(
                  getEntityProperty(state, entityType, id, labelPropertyName),
                ),
            fullAncestralName: getEntityProperty(
              state,
              entityType,
              id,
              labelPropertyName,
            ),
            disabled: getEntityProperty(state, entityType, id, 'has_children'),
          })),
        )
      : staticOptions,
  );
  const className = isFiltering
    ? 'EntitySelect'
    : 'EntitySelect EntitySelect--isNotFiltering';

  return (
    <ReactSelect
      className={className}
      onInputChange={onInputChange}
      options={options}
      {...props}
    />
  );
};

EntitySelect.defaultProps = {
  className: 'EntitySelect',
  clearable: false,
  labelKey: 'name',
  labelPropertyName: 'name',
  simpleValue: true,
  staticOptions: [],
  valueKey: 'id',
};

EntitySelect.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  /**
   * The list of available entityIds to select from.
   */
  entityIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  ),

  /**
   * The type of entities that are being rendered in the select.
   */
  entityType: PropTypes.string.isRequired,

  labelKey: PropTypes.string,
  /**
   * The name of the property to render as the label for the option.
   */
  labelPropertyName: PropTypes.string,

  /**
   * Called when the selected value changed.
   */
  onChange: PropTypes.func.isRequired,

  simpleValue: PropTypes.bool,
  /**
   * An initial (static) option set (in addition to the entity options)
   * This allows including options that are not actual entities (such as a "default" value, or
   * a value representing no selection).
   */
  staticOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),

  /*
   * The selected value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  valueKey: PropTypes.string,
};

export default EntitySelect;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import { useGetOffersQuery } from 'services/apiV1/offers';
import OfferEdit from './OfferEdit';
import OfferList from './OfferList';

const OffersPanel = ({ companyLogoUrl, companyName, searchId }) => {
  const {
    data: offers,
    error: _offersLoadError,
    isFetching: isLoadingOffers,
  } = useGetOffersQuery(searchId);

  const [editOfferId, setEditOfferId] = useState(null);

  return (
    <LoadingContainer
      className='u-marginTop-16'
      isLoading={isLoadingOffers}
      minHeight='100px'
    >
      {editOfferId ? (
        <OfferEdit
          companyLogoUrl={companyLogoUrl}
          companyName={companyName}
          editOfferId={editOfferId}
          onNavigateBackToOfferList={() => setEditOfferId(null)}
          searchId={searchId}
        />
      ) : null}
      {isLoadingOffers || editOfferId ? null : (
        <OfferList
          companyLogoUrl={companyLogoUrl}
          companyName={companyName}
          offers={offers}
          onEditOffer={setEditOfferId}
          searchId={searchId}
        />
      )}
    </LoadingContainer>
  );
};

OffersPanel.propTypes = {
  companyLogoUrl: PropTypes.string,
  companyName: PropTypes.string,
  searchId: PropTypes.number,
};

export default OffersPanel;

import routes from 'modules/routing/routes';
import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import revenueSchema from '../schema';
import {
  REVENUE_LIST_FETCH_START,
  REVENUE_LIST_FETCH_SUCCESS,
  REVENUE_LIST_FETCH_FAILURE,
} from './actionTypes';

/**
 * Creates an action for fetching a list of revenue records (revenue for a particular year)
 * for a company
 * @param {Object} payload
 * @param {String} payload.companyId The ID of the company to fetch the revenue for.
 */
export default compose(
  fetchAction,
  normalizeResponse({ revenues: [revenueSchema] }),
  createAsyncActions({
    startActionType: REVENUE_LIST_FETCH_START,
    successActionType: REVENUE_LIST_FETCH_SUCCESS,
    failureActionType: REVENUE_LIST_FETCH_FAILURE,
  }),
  withOptions(({ companyId }) => ({
    url: routes.api_v1_company_revenues({ companyId: companyId }),
  })),
);

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import { invalidateCandidacyStatus } from 'modules/candidacies/actions';
import { fetchContact } from 'modules/contacts/actions/contacts/index';
import ReferenceAddFormV2 from './ReferenceAddFormV2';
import canCreateReference from '../selectors/canCreateReference';

const AddReferenceFormButton = ({ candidacyId, contactId, searchId }) => {
  const dispatch = useDispatch();
  const [isFormOpen, openForm, closeForm, _toggleForm] = useToggle();
  const allowCreateReference = useSelector(state => canCreateReference(state));

  const handleSuccess = () => {
    dispatch(invalidateCandidacyStatus({ id: candidacyId }));
    dispatch(fetchContact({ id: contactId }));
    closeForm();
  };

  if (isFormOpen) {
    return (
      <ReferenceAddFormV2
        candidacyId={candidacyId}
        contactId={contactId}
        onCancel={closeForm}
        onSuccess={handleSuccess}
        searchId={searchId}
      />
    );
  }

  return allowCreateReference ? (
    <ButtonPrimary
      className='u-marginLeft-n'
      isFullWidth={true}
      label='New Reference'
      onClick={openForm}
    />
  ) : null;
};

AddReferenceFormButton.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
  searchId: PropTypes.number,
};

export default AddReferenceFormButton;

import PropTypes from 'prop-types';
import { compose, defaultProps, mapProps, setPropTypes } from 'recompose';
import { connect } from 'react-redux';
import isPathActive from 'modules/routing/selectors/isPathActive';
import { SEARCH_PAGE_BASE_PATHS } from '../constants';
import mapSearchIdToSearchType from './mapSearchIdToSearchType';

export default compose(
  setPropTypes({
    /**
     * When `isToggle` is true, this link will act as a link that toggles the
     * `popover` value specified. So if the popover is currently active, the href generated
     * will be the link _without_ the popover segment, otherwise it will include the segment.
     */
    isToggle: PropTypes.bool.isRequired,

    /**
     * The ID of the search to link to.
     * @type {[type]}
     */
    searchId: PropTypes.number.isRequired,

    /**
     * The current search page being displayed ("candidates", "applicants", "target_companies")
     */
    searchPage: PropTypes.string.isRequired,

    /**
     * The popover to link to (or not -- this is optional, no popover indicates linking to a view
     * with no popover open).
     */
    popover: PropTypes.string,
  }),
  defaultProps({
    isToggle: false,
  }),
  mapSearchIdToSearchType,
  connect(
    (
      state,
      { isToggle, popover, searchId, searchPage, searchType, ...rest },
    ) => {
      let href = `${SEARCH_PAGE_BASE_PATHS[searchType]}/${searchId}/${
        searchPage || 'candidates'
      }`;

      if (popover) {
        const hrefWithPopover = `${href}/${popover}`;

        if (!isToggle || !isPathActive(state, hrefWithPopover)) {
          href = hrefWithPopover;
        }
      }

      return {
        ...rest,
        href: href,
      };
    },
    {},
  ),

  /* eslint-disable no-unused-vars */
  // Remove extra params to prevent them from being passed into an `a` element.
  mapProps(
    ({
      href,
      isToggle,
      popover,
      searchId,
      searchPage,
      searchType,
      ...rest
    }) => ({
      ...rest,
      href: href,
    }),
  ),
  /* eslint-enable no-unused-vars */
);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  DATE_GROUP_LABELS,
  DATE_GROUP_PAST,
  DATE_GROUP_UPCOMING,
} from '../constants';
import InterviewList from './InterviewList';
import withDateGroupedInterviewIds from './withDateGroupedInterviewIds';

/**
 * eslint incorrectly identifies our `map` function as being called on an array, but it's actually
 * and Immutable.Map, and what it thinks is the index is actually a key, so we have to disable
 * this rule here:
 */
/* eslint-disable react/no-array-index-key */

/**
 * Renders a list of interviews, grouped by date group values: DATE_GROUP_PAST and
 * DATE_GROUP_UPCOMING.
 */
const DateGroupedInterviewList = ({ interviewGroups, ...props }) => (
  <ul className='list-generic'>
    {interviewGroups &&
      interviewGroups
        .map((interviewIds, group) => (
          <li key={group}>
            <h3>{DATE_GROUP_LABELS[group]}</h3>
            <InterviewList
              {...props}
              interviewIds={interviewIds}
              sortDescending={group === DATE_GROUP_PAST}
              sortEmptyDatesFirst={true}
            />
          </li>
        ))
        .toArray()}
  </ul>
);

DateGroupedInterviewList.propTypes = {
  interviewGroups: ImmutablePropTypes.mapContains({
    [DATE_GROUP_PAST]: ImmutablePropTypes.listOf(PropTypes.number),
    [DATE_GROUP_UPCOMING]: ImmutablePropTypes.listOf(PropTypes.number),
  }).isRequired,
};

export default withDateGroupedInterviewIds(DateGroupedInterviewList);

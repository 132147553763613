import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SortIndicator from '@thrivetrm/ui/components/SortIndicator';
import { SortDirections } from './tableConstants';

const CellWidths = {
  X_SMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_LARGE: 'xlarge',
};

const TableHeaderCell = ({
  isFixed,
  isSortable,
  label,
  name,
  onSortChange,
  sortDirection,
  sortKey,
  width,
}) => {
  const headerClass = classNames(
    'TableHeaderCell',
    {
      'table__cell--isFixed': isFixed,
    },
    {
      'th--isSortable': isSortable,
      [`th--isSortKey${sortDirection}`]: name === sortKey,
      'th--isSortKey': name === sortKey,
    },
    {
      'th--xsmall': width === CellWidths.X_SMALL,
      'th--small': width === CellWidths.SMALL,
      'th--medium': width === CellWidths.MEDIUM,
      'th--large': width === CellWidths.LARGE,
      'th--xlarge': width === CellWidths.X_LARGE,
    },
  );

  const getSortableContent = () =>
    isSortable ? (
      <SortIndicator
        isAscending={sortDirection === SortDirections.ASC}
        isSorted={name === sortKey}
      />
    ) : null;

  const handleSortChange = () => {
    if (!isSortable) {
      return;
    }
    const isCurrentlySortedBy = sortKey === name;
    const nextsortDirection =
      isCurrentlySortedBy && sortDirection === SortDirections.ASC
        ? SortDirections.DESC
        : SortDirections.ASC;
    onSortChange(name, nextsortDirection);
  };

  return (
    <th className={headerClass} onClick={handleSortChange}>
      <div className='TableHeaderCell__label'>{label}</div>
      {getSortableContent()}
    </th>
  );
};

TableHeaderCell.defaultProps = {
  isFixed: false,
  isSortable: false,
  onSortChange: null,
  sortDirection: null,
  sortKey: null,
  width: null,
};

TableHeaderCell.propTypes = {
  isFixed: PropTypes.bool,
  isSortable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSortChange: PropTypes.func,
  sortDirection: PropTypes.oneOf(Object.values(SortDirections)),
  sortKey: PropTypes.string,
  width: PropTypes.oneOf(Object.values(CellWidths)),
};

export default TableHeaderCell;

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { PARENT_TYPES } from '../constants';
import fetchAddressesListActionCreator from '../actions/fetchAddressesList';
import shouldFetchAddressesListSelector from '../selectors/shouldFetchAddressesList';
import getAddressesListSelector from '../selectors/getAddressesList';

/**
 * Fetches an address list if it needs to be fetched from the server,
 * given it's parentType && parentId.
 * */
export default compose(
  setDisplayName('withAddressesListFetched'),
  setPropTypes({
    parentId: PropTypes.number,
    parentType: PropTypes.oneOf(PARENT_TYPES),
  }),
  connect(
    (state, { parentId, parentType }) => ({
      shouldFetchAddressesList: shouldFetchAddressesListSelector(
        state,
        parentType,
        parentId,
      ),
      addressesList: getAddressesListSelector(state, parentType, parentId),
    }),
    { fetchAddressesList: fetchAddressesListActionCreator },
  ),
  withHandlers({
    // Replace `fetchAddressesList` with a version that can be called without passing in
    // any params.
    fetchAddressesList: ({ fetchAddressesList, ...props }) => () =>
      fetchAddressesList(props),
  }),
  withHandlers({
    fetchAddressesListIfNeeded: ({
      fetchAddressesList,
      shouldFetchAddressesList,
    }) => () => {
      if (shouldFetchAddressesList) {
        fetchAddressesList();
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchAddressesListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchAddressesListIfNeeded();
    },
  }),
);

import { connect } from 'react-redux';
import getRating from '../../selectors/ratings/getRating';

/**
 * Maps a `ratingId` to it's `rating` entity record.
 */
export default connect(
  (state, { ratingId }) => ({
    rating: getRating(state, ratingId),
  }),
  {},
);

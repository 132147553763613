import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import EntityPropertyList from 'modules/entities/components/EntityPropertyList';
import sectorSchema from 'modules/tenant/schemas/sectors';
import mapSearchIdToSearch from './mapSearchIdToSearch';

/**
 * Renders a list of the sectors related to a search
 */
export default compose(
  setDisplayName('SearchSectorNameList'),
  setPropTypes({
    /**
     * The ID of the search to show the sectors for.
     */
    searchId: PropTypes.number.isRequired,
  }),
  mapSearchIdToSearch,
  mapProps(({ search }) => ({
    entityIds: search && search.get('sectors'),
    entityType: sectorSchema.key,
  })),
  branch(({ entityIds }) => !entityIds, renderNothing),
)(EntityPropertyList);

import PropTypes from 'prop-types';
import {
  componentFromProp,
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';
import withUserFetched from './withUserFetched';
import getUserDisplayName from '../selectors/getUserDisplayName';

export default compose(
  setDisplayName('UserName'),
  setPropTypes({
    userId: PropTypes.number,
  }),
  defaultProps({
    component: 'span',
  }),
  withUserFetched(),
  connect(
    (state, { userId }) => ({
      children: getUserDisplayName(state, userId) || null,
    }),
    {},
  ),
  mapProps(({ userId: _userId, ...rest }) => ({
    ...rest,
  })),
)(componentFromProp('component'));

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { compose, setDisplayName } from 'recompose';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import mapCompanyIdToCompany from 'modules/companies/components/mapCompanyIdToCompany';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

/**
 * Renders readonly input for company's legacy number_of_employees field
 */
const CompanyEmployeeCountLegacyInput = ({
  company,
  componentId,
  destroyInput,
}) => (
  <div
    className={classnames('CompanyEmployeeCountLegacyInput', {
      'CompanyEmployeeCountLegacyInput--deleted': destroyInput,
    })}
  >
    <input
      className='form-control'
      disabled={true}
      value={company.get('number_of_employees')}
    />
    <OverlayTrigger
      overlay={
        <Tooltip id={`${componentId}-tooltip`}>
          <span>
            This field is deprecated and read-only. Adding a new Employee Count
            will delete this data.
          </span>
        </Tooltip>
      }
      placement='right'
    >
      <i className='fa fa-info-circle btn-tooltip' />
    </OverlayTrigger>
  </div>
);

CompanyEmployeeCountLegacyInput.propTypes = {
  /**
   * Company - needed to show legacy data
   */
  company: ImmutablePropTypes.mapContains({
    number_of_employees: PropTypes.string,
  }).isRequired,

  /**
   * A unique ID for the component
   */
  componentId: PropTypes.string.isRequired,

  /**
   * True if nestedFields have been added to the new form.
   */
  destroyInput: PropTypes.bool,
};

export default compose(
  setDisplayName('CompanyEmployeeCountLegacyForm(enhanced)'),
  mapCompanyIdToCompany,
  withComponentId(),
)(CompanyEmployeeCountLegacyInput);

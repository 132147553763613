import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import contactLevelSchema from '../schemas/contactLevels';

/**
 * Renders the display name for a given contact level ID.
 */
const ContactLevelName = ({ id }) => (
  <EntityProperty
    entityId={id}
    entityType={contactLevelSchema.key}
    propertyName='name'
  />
);

ContactLevelName.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ContactLevelName;

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from 'modules/core/urlUtils';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import Icon from '@thrivetrm/ui/components/Icon';
import PageHeader from 'modules/core/components/PageHeader';
import Pagination from '@thrivetrm/ui/components/Pagination';
import SegmentedControl from '@thrivetrm/ui/components/SegmentedControl';
import Table from 'modules/core/components/Table';
import {
  requestPipelineList,
  saveResultsPerPage,
  setCurrentPage,
  setSortParams,
} from 'modules/pipelines/pipelineSlice';
import isSuperAdmin from 'modules/auth/selectors/isSuperAdmin';
import './PipelineIndexPage.scss';

const RESULTS_PER_PAGE_OPTIONS = ['5', '25', '100'];

const PipelineIndexPage = () => {
  const { search: queryString } = useLocation();
  const dispatch = useDispatch();
  const {
    currentPage,
    isLoadingPipelineList,
    pipelineListRequestError,
    recordList,
    resultsPerPage,
    selectedColumns,
    sortDirection,
    sortKey,
    totalPages,
  } = useSelector(state => state.pipeline);

  const isSuperAdminUser = useSelector(isSuperAdmin);

  useEffect(() => {
    if (queryString) {
      const queryObject = parseQueryString(queryString);

      const {
        page,
        results_per_page,
        sort_direction,
        sort_field,
      } = queryObject;

      if (RESULTS_PER_PAGE_OPTIONS.includes(results_per_page)) {
        dispatch(saveResultsPerPage(results_per_page));
      }

      // eslint-disable-next-line camelcase
      if (sort_field && sort_direction) {
        dispatch(
          setSortParams({ sortDirection: sort_direction, sortKey: sort_field }),
        );
      }
      if (page) {
        dispatch(setCurrentPage(parseInt(page)));
      }
    }
  }, [dispatch, queryString]);

  useEffect(() => {
    dispatch(requestPipelineList());
  }, [dispatch]);

  // To be removed after new loading state is established
  if (!recordList) return null;

  const handleSortDirectionChange = (newSortKey, newSortDirection) => {
    dispatch(
      setSortParams({ sortDirection: newSortDirection, sortKey: newSortKey }),
    );
    dispatch(requestPipelineList());
  };

  const handlePageChange = page => {
    dispatch(setCurrentPage(page));
    dispatch(requestPipelineList());
  };

  const handleResultsPerPageChange = newResultsPerPage => {
    dispatch(saveResultsPerPage(newResultsPerPage));
    dispatch(requestPipelineList());
  };

  return (
    <div className='RecordIndexPage'>
      <PageHeader title='Pipelines'>
        {isSuperAdminUser ? (
          <div className='PipelineIndexPage__titleTableSide'>
            <ButtonPrimary
              className='RecordIndexHeader__action u-noWrap u-marginLeft-16'
              icon='add'
              label='Create Pipeline'
              size='medium'
              url='pipelines/new'
            />
          </div>
        ) : null}
      </PageHeader>
      {!isLoadingPipelineList && pipelineListRequestError ? (
        <Card type='error'>{pipelineListRequestError}</Card>
      ) : null}
      <div className='RecordIndexPage__content'>
        {recordList.length ? (
          <div className='RecordIndexTable'>
            <Table
              headers={selectedColumns}
              isLoading={isLoadingPipelineList}
              onSortChange={handleSortDirectionChange}
              rows={recordList}
              sortDirection={sortDirection}
              sortKey={sortKey}
            />
            <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c u-marginVertical-24'>
              <Pagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                totalPages={totalPages}
              />
              <SegmentedControl
                onChange={handleResultsPerPageChange}
                segments={RESULTS_PER_PAGE_OPTIONS}
                selectedSegmentLabel={resultsPerPage}
                size='small'
              />
            </div>
          </div>
        ) : null}
        {isSuperAdminUser && !recordList?.length ? (
          <Card
            className='PipelineIndexPage__noDataCard u-marginVertical-64'
            type='gray'
          >
            <Icon color='blue' size='large' type='add' />
            <h2>Create Pipeline</h2>
            <p>Create your first Pipeline by clicking the button below.</p>
            <ButtonPrimary
              className='RecordIndexHeader__action u-noWrap'
              label='Create Pipeline'
              size='medium'
              url='pipelines/new'
            />
          </Card>
        ) : null}
        {!isSuperAdminUser && !recordList?.length ? (
          <div className='PipelineIndexPage__noDataCard  u-marginVertical-32'>
            <img alt='no contact networks' src='/user_network.svg' />
            <div className='u-fontWeight-bold u-fontSize-large'>
              You have no pipelines yet. Reach out to Thrive IQ or your Customer
              Success Manager to get a pipeline started.
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PipelineIndexPage;

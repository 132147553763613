import routes from 'modules/routing/routes';
import url from 'modules/routing/url';
import { contactSchema } from 'modules/contacts/schema';
import createAjaxAction from '../createAjaxAction';
import {
  CONTACT_FETCH_START,
  CONTACT_FETCH_SUCCESS,
  CONTACT_FETCH_FAILURE,
} from './constants';

export const getUrl = ({ contactId, includes }) =>
  url(routes.api_v1_contact({ id: contactId }), { includes: includes });

const fetchContact = createAjaxAction({
  getUrl: getUrl,
  schema: { contact: contactSchema },
  start: CONTACT_FETCH_START,
  success: CONTACT_FETCH_SUCCESS,
  failure: CONTACT_FETCH_FAILURE,
});

export default fetchContact;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import PageHeader from 'modules/core/components/PageHeader';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Pagination from '@thrivetrm/ui/components/Pagination';
import SegmentedControl from '@thrivetrm/ui/components/SegmentedControl';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Card from '@thrivetrm/ui/components/Card';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import BottomPanel from '@thrivetrm/ui/components/BottomPanel';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import PluralText from '@thrivetrm/ui/components/PluralText';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import PendingContactWithMatches from './PendingContactWithMatches';
import PendingContactDiscardButton from './PendingContactDiscardButton';
import CreateContactsModal from './components/CreateContactsDialog';

import {
  changeCurrentPage,
  changeResultsPerPage,
  convertSelectedPendingContactsToContacts,
  deleteSelectedPendingContacts,
  loadPendingContacts,
  resetSelectedPendingContacts,
  selectMatchlessPendingContacts,
  toggleSelected,
} from './pendingContactsSlice';
import { handleAutoDiscardIndicator } from './pendingContactUtils';

const PendingContactsPage = () => {
  const dispatch = useDispatch();

  const [
    isDiscardDialogOpen,
    openDiscardDialog,
    closeDiscardDialog,
  ] = useToggle(false);

  const [isCreateModalOpen, openCreateModal, closeCreateModal] = useToggle(
    false,
  );

  const hasAutoDiscardFeature = useFeatureCheck(
    'feature.auto_discard_pending_contacts',
  );

  const {
    bannerDisplayed,
    currentPage,
    data,
    isCreating,
    isDeleting,
    isLoading,
    resultsPerPage,
    selectedIds,
    totalPages,
    totalResults,
  } = useSelector(state => state.pendingContacts);

  useEffect(() => {
    dispatch(loadPendingContacts());
  }, [dispatch]);

  const hasSelectedAll = Boolean(
    data.filter(pendingContact => !pendingContact.matches?.length).length ===
      selectedIds.length,
  );

  const hasStaleData = isLoading || isDeleting || isCreating;
  const hasData = hasStaleData || data?.length > 0;

  return (
    <>
      <PageHeader title='Pending Contacts' />
      <LoadingContainer
        className='container'
        isLoading={hasStaleData}
        overlayColor='gray10'
      >
        {bannerDisplayed ? (
          <Card
            className='u-flex u-marginVertical-8 u-border-n'
            isCentered={false}
            type='warning'
          >
            <StatusIndicator
              className='u-marginTop-2 u-marginRight-8'
              status='warning'
            />
            <p className='u-margin-n u-textColor-gray60'>
              Pending contacts over 30 days old will be automatically discarded
            </p>
          </Card>
        ) : null}

        {hasData ? (
          <>
            <div className='u-inlineFlex u-marginVertical-24'>
              <ButtonLink
                isDisabled={hasSelectedAll || hasStaleData}
                label='Select all on this page'
                onClick={() => dispatch(selectMatchlessPendingContacts())}
              />
              <div className='u-marginHorizontal-16 u-textColor-gray30'>|</div>
              <ButtonLink
                isDisabled={selectedIds.length === 0 || hasStaleData}
                label='Deselect all'
                onClick={() => dispatch(resetSelectedPendingContacts())}
              />
            </div>
            {data.map(pendingContact =>
              pendingContact.matches?.[0] ? (
                <PendingContactWithMatches
                  key={pendingContact.id}
                  pendingContact={pendingContact}
                />
              ) : (
                <Card
                  className='u-marginBottom-16 u-paddingVertical-12 u-marginBottom-16 u-paddingHorizontal-16'
                  data-testid='pending-contact-card'
                  isCentered={false}
                  key={pendingContact.id}
                >
                  <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c '>
                    <div className='u-flex u-flexAlign-c'>
                      <Checkbox
                        className='u-marginRight-16 u-marginLeft-8'
                        isChecked={selectedIds?.includes(pendingContact.id)}
                        onChange={() =>
                          dispatch(toggleSelected(pendingContact.id))
                        }
                      />
                      <ContactSnapshot
                        avatarUrl={pendingContact.picture}
                        company={{ name: pendingContact.company }}
                        id={pendingContact.id}
                        name={pendingContact.name}
                        title={pendingContact.title}
                      />
                    </div>
                    {hasAutoDiscardFeature
                      ? handleAutoDiscardIndicator(pendingContact)
                      : null}
                    <div className='u-flex u-marginVertical-12 u-marginRight-8'>
                      <PendingContactDiscardButton id={pendingContact.id} />
                      <ButtonPrimary
                        className='PendingContactsPage__reviewButton'
                        label='Review & Create'
                        url={`/contacts/pending/${pendingContact.id}`}
                      />
                    </div>
                  </div>
                </Card>
              ),
            )}
            <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c u-marginVertical-24'>
              <Pagination
                currentPage={currentPage}
                onPageChange={newPageNumber =>
                  dispatch(changeCurrentPage(newPageNumber))
                }
                resultsPerPage={resultsPerPage}
                totalPages={totalPages}
                totalResults={totalResults}
              />
              <SegmentedControl
                onChange={newResultsPerPage =>
                  dispatch(changeResultsPerPage(newResultsPerPage))
                }
                segments={[5, 25, 100]}
                selectedSegmentLabel={resultsPerPage}
                size='small'
              />
            </div>
          </>
        ) : (
          <Card
            className='u-marginVertical-32 u-textAlign-c u-border-n'
            isCentered={false}
          >
            <img
              alt='no pending contacts'
              className='u-marginTop-24'
              src='/card_screen.svg'
            />
            <h1>All Caught Up!</h1>
            <p className='u-marginVertical-12 u-textColor-gray60 u-borderBottom u-paddingBottom-16'>
              What’s next? You can import more contacts if you’re up to the
              task.
            </p>
            <a href='/contacts'>View Contacts</a>
          </Card>
        )}
      </LoadingContainer>
      {selectedIds.length ? (
        <BottomPanel className='PendingContactsPage__bottomPanel' isOpen={true}>
          <div className='PendingContactsPage__actionButtons'>
            <PluralText
              case='lowerCase'
              quantity={selectedIds.length}
              shouldIncludeQuantity={true}
              text='pending contacts'
            />{' '}
            selected
          </div>
          <div className='u-textAlign-c PendingContactsPage__actionButtons'>
            <ButtonSecondary
              label='Discard Contacts'
              onClick={openDiscardDialog}
            />
            <ButtonPrimary
              label='Create New Contacts'
              onClick={openCreateModal}
            />
          </div>
        </BottomPanel>
      ) : null}
      {isCreateModalOpen ? (
        <CreateContactsModal
          onClose={closeCreateModal}
          onConfirm={formData =>
            dispatch(convertSelectedPendingContactsToContacts(formData))
          }
          selectionCount={selectedIds.length}
        />
      ) : null}
      {isDiscardDialogOpen ? (
        <ConfirmationDialog
          cancelLabel='Cancel'
          confirmLabel='Discard'
          data-testid='confirmation modal'
          isOpen={true}
          onClose={closeDiscardDialog}
          onConfirm={() => dispatch(deleteSelectedPendingContacts())}
          title='Discard Pending Contacts'
        >
          Are you sure you want to discard{' '}
          <PluralText
            className='u-fontWeight-bold'
            quantity={selectedIds.length}
            shouldIncludeQuantity={true}
            text='Pending Contacts'
          />
          ?
          <div className='u-marginVertical-24 u-textColor-red u-fontWeight-bold'>
            This cannot be undone.
          </div>
        </ConfirmationDialog>
      ) : null}
    </>
  );
};

export default PendingContactsPage;

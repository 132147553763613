import { connect } from 'react-redux';
import getActivityProperty from '../selectors/getActivityProperty';

/**
 * A Higher order component that sorts a list of activityIds in order of date created.
 */
export default connect(
  (state, { activityIds }) => ({
    activityIds:
      activityIds &&
      activityIds
        .sortBy(id => getActivityProperty(state, id, 'created_at'))
        .reverse(),
  }),
  {},
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import ReportCard from './ReportCard';
import ReportModal from './ReportModal';
import IntroductionReportForm from './IntroductionReportForm';

const INTRODUCTION_REPORT = 'Introduction Report';

class ReportsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReport: null,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.messageDismissTimeoutId);
  }

  handleReportModalClose = () => {
    this.setState({
      selectedReport: null,
    });
  };

  handleReportModalSaved = payload => {
    this.setState({
      message:
        payload.data.notice ||
        'A report will be emailed to the provided emails.',
      selectedReport: null,
    });
    clearTimeout(this.messageDismissTimeoutId);
    this.messageDismissTimeoutId = setTimeout(this.handleMessageDismiss, 10000);
  };

  handleMessageDismiss = () => {
    this.setState({
      message: null,
    });
    clearTimeout(this.messageDismissTimeoutId);
  };

  handleIntroductionReportLaunch = event => {
    event.preventDefault();
    this.setState({
      selectedReport: INTRODUCTION_REPORT,
    });
  };

  render() {
    const { message, selectedReport } = this.state;
    const {
      clearTransaction,
      companies,
      companiesActions,
      contacts,
      contactsActions,
      currentUserEmail,
      reportList,
      reportsActions,
      transactions,
    } = this.props;

    let reportForm;
    let reportTitle;

    switch (selectedReport) {
      case INTRODUCTION_REPORT:
        reportForm = (
          <IntroductionReportForm
            clearTransaction={clearTransaction}
            companies={companies}
            companiesActions={companiesActions}
            contacts={contacts}
            contactsActions={contactsActions}
            createIntroductionsReport={reportsActions.createIntroductionsReport}
            currentUserEmail={currentUserEmail}
            dateRangeInnerClassName='col-sm-6'
            dateRangeWrapperClassName='row'
            emailWrapperClassName='email-field'
            onCancel={this.handleReportModalClose}
            onSaved={this.handleReportModalSaved}
            transactions={transactions}
          />
        );
        reportTitle = 'Generate Introductions Report';
        break;
      default:
        reportForm = null;
    }

    return (
      <div className='reports-wrapper'>
        {message && (
          <div className='alert alert-success alert-dismissible' role='alert'>
            <button
              aria-label='Close'
              className='close'
              onClick={this.handleMessageDismiss}
              type='button'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
            {message}
          </div>
        )}
        <div className='row'>
          {reportList.indexOf(INTRODUCTION_REPORT) >= 0 && (
            <div className='col-sm-4'>
              <ReportCard
                format='CSV, XLSX'
                icon='P'
                isFontAwesome={false}
                onClick={this.handleIntroductionReportLaunch}
                title='Introduction Report'
              />
            </div>
          )}
        </div>

        {reportForm ? (
          <ReportModal
            onHide={this.handleReportModalClose}
            onSubmit={this.handleReportFormSubmit}
            title={reportTitle}
          >
            {reportForm}
          </ReportModal>
        ) : null}
      </div>
    );
  }
}

export default ReportsIndex;

ReportsIndex.propTypes = {
  clearTransaction: PropTypes.func,

  companies: ImmutablePropTypes.mapContains({
    queriesById: ImmutablePropTypes.map,
  }),

  companiesActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }).isRequired,

  contacts: ImmutablePropTypes.mapContains({
    queriesById: ImmutablePropTypes.map,
  }),

  contactsActions: PropTypes.shape({
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    queryContacts: PropTypes.func.isRequired,
  }).isRequired,

  currentUserEmail: PropTypes.string.isRequired,

  reportList: PropTypes.arrayOf(PropTypes.string),

  reportsActions: PropTypes.shape({
    createIntroductionsReport: PropTypes.func.isRequired,
  }).isRequired,

  transactions: transactionsState,
};

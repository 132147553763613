import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import {
  REFERENCE_LIST_FETCH_START,
  REFERENCE_LIST_FETCH_SUCCESS,
  REFERENCE_LIST_FETCH_FAILURE,
} from './actionTypes';
import referenceSchema from '../schema';
import getReferenceListUrl from './getReferenceListUrl';

/**
 * Creates an action for fetching the list of references
 * @param {Object} options
 * @param {String} options.parentId The ID of the parent to fetch references for (a contact ID
 *   when PARENT_CONTACT or a candidacy ID when PARENT_CANDIDACY)
 * @param {String} options.parentType The parent type to fetch references for (PARENT_CONTACT or
 *   PARENT_CANDIDACY)
 */
export default compose(
  fetchAction,
  normalizeResponse({ references: [referenceSchema] }),
  createAsyncActions({
    startActionType: REFERENCE_LIST_FETCH_START,
    successActionType: REFERENCE_LIST_FETCH_SUCCESS,
    failureActionType: REFERENCE_LIST_FETCH_FAILURE,
  }),
  withOptions(({ parentId, parentType }) => ({
    url: getReferenceListUrl({ parentId: parentId, parentType: parentType }),
  })),
);

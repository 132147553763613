import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  defaultProps,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import mapAddressIdToAddress from './mapAddressIdToAddress';
import deleteAddressActionCreator from '../actions/deleteAddress';

/**
 * Renders a button that, when clicked, displayed a popover message
 * asking the user to confirm whether or not they want to delete the selected address.
 */

export default compose(
  setDisplayName('AddressDeleteButton'),
  setPropTypes({
    addressId: PropTypes.number.isRequired,
  }),
  defaultProps({
    popoverPlacement: 'left',
  }),
  connect(
    (state, { addressId }) => ({
      isEnriched: getEntityProperty(state, 'addresses', addressId, 'enriched'),
    }),
    {
      deleteAddress: deleteAddressActionCreator,
    },
  ),
  withFeatureCheck('feature.skyminyr', 'hasSkyminyrFeature'),
  withHandlers({
    onConfirm: ({ addressId, deleteAddress }) => () =>
      deleteAddress({ id: addressId }),
  }),
  mapAddressIdToAddress,
  mapProps(
    ({
      address,
      deleteAddress: _deleteAddress,
      hasSkyminyrFeature,
      isEnriched,
      ...rest
    }) => ({
      canDelete:
        !address.getIn(['_meta', 'isDeleted']) &&
        address.getIn(['permissions', 'delete']),
      disabled:
        address.getIn(['_meta', 'isUpdating']) ||
        address.getIn(['_meta', 'isDeleting']) ||
        (hasSkyminyrFeature && isEnriched),
      isDeleting: address.getIn(['_meta', 'isDeleting']),
      hasSkyminyrFeature: hasSkyminyrFeature,
      isEnriched: isEnriched,
      ...rest,
    }),
  ),
  branch(({ canDelete }) => !canDelete, renderNothing),
  mapProps(
    ({
      addressId: _addressId,
      canDelete: _canDelete,
      hasSkyminyrFeature,
      isDeleting,
      isEnriched,
      ...rest
    }) => ({
      ...rest,
      className: 'AddressDeleteButton btn btn-link btn-sm',
      iconClass: classnames('fa', {
        'fa-lock u-textColor-gray40': hasSkyminyrFeature && isEnriched,
        'fa-spin': isDeleting,
        'fa-spinner': isDeleting,
        'fa-trash': !isDeleting && !(hasSkyminyrFeature && isEnriched),
      }),
      title: 'Are you sure you want to delete this address?',
    }),
  ),
)(ConfirmationPopoverButton);

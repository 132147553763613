import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { bindActionCreators } from '@reduxjs/toolkit';
import shouldFetchJobApplicationsListSelector from '../selectors/shouldFetchJobApplicationsList';
import * as actions from '../actions';

/**
 * When provided a searchId, fetches the job applications for that search if they need to be
 * fetched from the server.
 */
export default compose(
  setDisplayName('withJobApplicationListFetched'),
  setPropTypes({
    searchId: PropTypes.number,
  }),
  connect(
    (state, { searchId }) => ({
      shouldFetchJobApplicationsList: shouldFetchJobApplicationsListSelector(
        state,
        searchId,
      ),
    }),
    (dispatch, { jobApplicationActions }) => ({
      jobApplicationActions:
        jobApplicationActions || bindActionCreators(actions, dispatch),
    }),
  ),
  withHandlers({
    fetchJobApplicationList: ({ jobApplicationActions, searchId }) => () =>
      searchId &&
      jobApplicationActions.fetchJobApplicationList({ searchId: searchId }),
  }),
  withHandlers({
    fetchJobApplicationListIfNeeded: ({
      fetchJobApplicationList,
      shouldFetchJobApplicationsList,
    }) => () => shouldFetchJobApplicationsList && fetchJobApplicationList(),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const { fetchJobApplicationListIfNeeded } = this.props;
      fetchJobApplicationListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const { fetchJobApplicationListIfNeeded } = nextProps;
      fetchJobApplicationListIfNeeded();
    },
  }),
);

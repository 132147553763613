import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import SearchClientCompanyName from 'modules/searches/components/SearchClientCompanyName';
import SearchTitle from 'modules/searches/components/SearchTitle';
import mapSearchIdToSearch from 'modules/searches/components/mapSearchIdToSearch';
import mapCandidacyIdToSearchId from '../mapCandidacyIdToSearchId';
import mapDatumToCandidacyId from './mapDatumToCandidacyId';
import SearchCandidacyLink from '../SearchCandidacyLink';

/**
 * A table cell for rendering the full title of a search (company name + job title) for a candidacy
 */
const CandidacySearchFullNameCell = ({
  candidacyId,
  isConfidential,
  searchId,
}) => (
  <SearchCandidacyLink candidacyId={candidacyId} useDefaultLinkBehavior={true}>
    {!isConfidential && (
      <span>
        <SearchClientCompanyName searchId={searchId} />
        <span> - </span>
      </span>
    )}
    <SearchTitle searchId={searchId} />
  </SearchCandidacyLink>
);

CandidacySearchFullNameCell.propTypes = {
  candidacyId: PropTypes.number,
  isConfidential: PropTypes.bool,
  searchId: PropTypes.number,
};

export default compose(
  setDisplayName('CandidacySearchFullNameCell(enhanced)'),
  setPropTypes({
    /**
     * The candidacy ID
     */
    datum: PropTypes.number.isRequired,
  }),
  mapDatumToCandidacyId,
  mapCandidacyIdToSearchId,
  mapSearchIdToSearch,
  withProps(({ search }) => ({
    isConfidential: search && search.get('confidential'),
  })),
)(CandidacySearchFullNameCell);

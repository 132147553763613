import { convertToCamelCase } from 'modules/core/jsonUtils';
import apiV1 from './index';
import queryTags from './queryTags';

const { COMPANY } = queryTags;

const companies = apiV1.injectEndpoints({
  endpoints: builder => ({
    getCompany: builder.query({
      query: companyId => ({
        url: `/companies/${companyId}`,
        method: 'GET',
      }),
      transformResponse: response => convertToCamelCase(response?.company),
      providesTags: [COMPANY],
    }),
  }),
});

export const { useGetCompanyQuery } = companies;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useApiGet, useApiPost } from '@thrivetrm/ui/hooks/useApi';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import routes from 'modules/routing/routes';
import Card from '@thrivetrm/ui/components/Card';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { TYPE_TALENT_POOL } from 'modules/searches/constants';
import SearchAssessmentTemplateCard from './SearchAssessmentTemplateCard';

const SearchAssessmentTemplate = ({ activeSearchAssessmentId, searchId }) => {
  const dispatch = useDispatch();
  const searchType = useSelector(state => getSearchType(state, searchId));
  const isTalentPool = searchType === TYPE_TALENT_POOL;
  const [
    loadAssessmentTemplates,
    isLoadingAssessmentTemplates,
    assessmentTemplatesLoadError,
    assessmentTemplates,
  ] = useApiGet(routes.api_v1_assessment_templates());

  const [
    createAssessmentTemplate,
    isCreatingAssessmentTemplate,
    createAssessmentTemplateError,
  ] = useApiPost(
    routes.api_v1_search_assessment_templates({ searchId: searchId }),
  );

  useEffect(() => {
    loadAssessmentTemplates();
  }, [loadAssessmentTemplates]);

  const handleChange = assessmentId =>
    createAssessmentTemplate(
      {
        copied_from_assessment_template_id: assessmentId,
      },
      {
        // we need to reload the page to update the slim template
        // as AssessmentTemplate component is being rendered by the slim template
        onSuccess: () => {
          const redirectUrl = isTalentPool
            ? `/talent_pools/${searchId}/edit?editTalentPoolSuccess=true#assessments`
            : `/searches/${searchId}/edit?editSearchSuccess=true#assessments`;

          window.location.href = redirectUrl;
        },
      },
    );

  /**
   * Whenever an Assessment Template is added, created, edited, or removed, we want to
   * show a success toast message.  This is triggered by a query param (editSearchSuccess)
   * and this useEffect will dispatch a success toast then remove the query params to clean
   * up the URL
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const removeQueryParams = () =>
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}${window.location.hash}`,
      );

    if (urlParams.get('editSearchSuccess')) {
      dispatch(toastSuccess('Search was successfully updated'));
      removeQueryParams();
    } else if (urlParams.get('editTalentPoolSuccess')) {
      dispatch(toastSuccess('Talent Pool was successfully updated'));
      removeQueryParams();
    }
  }, [dispatch]);

  return (
    <>
      <h2>Assessment Template</h2>
      {activeSearchAssessmentId ? (
        <SearchAssessmentTemplateCard
          activeSearchAssessmentId={activeSearchAssessmentId}
          searchId={searchId}
        />
      ) : (
        <LoadingContainer isLoading={isLoadingAssessmentTemplates}>
          <SelectMenu
            className='u-marginTop-16'
            data-testid='assessment-template-select'
            inputWidth='full'
            label='Select an existing assessment template:'
            onChange={handleChange}
            placeholder='Select an existing template'
          >
            {assessmentTemplates?.data.map(assessmentTemplate => (
              <SelectMenu.Item
                key={assessmentTemplate.id}
                value={assessmentTemplate.id}
              >
                {assessmentTemplate.name}
              </SelectMenu.Item>
            ))}
          </SelectMenu>
          <p className='u-marginTop-12'>
            Or create a custom template that will be saved only for this{' '}
            {isTalentPool ? 'talent pool' : 'search'}:
          </p>
          <ButtonSecondary
            label='Create Custom Template'
            url={`/${
              isTalentPool ? 'talent_pools' : 'searches'
            }/${searchId}/assessment_templates/new`}
          />
        </LoadingContainer>
      )}
      {assessmentTemplatesLoadError && !isLoadingAssessmentTemplates ? (
        <Card className='u-marginTop-12' type='error'>
          {assessmentTemplatesLoadError}
        </Card>
      ) : null}
      {createAssessmentTemplateError && !isCreatingAssessmentTemplate ? (
        <Card className='u-marginTop-12' type='error'>
          {createAssessmentTemplateError}
        </Card>
      ) : null}
    </>
  );
};

SearchAssessmentTemplate.propTypes = {
  activeSearchAssessmentId: PropTypes.number,
  searchId: PropTypes.number.isRequired,
};

export default SearchAssessmentTemplate;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import mapCandidacyIdToCandidacy from 'modules/candidacies/components/mapCandidacyIdToCandidacy';
import mapCandidacyIdToSearchType from 'modules/candidacies/components/mapCandidacyIdToSearchType';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import { PARENT_CONTACT } from '../constants';
import withOutreachListFetched from './withOutreachListFetched';
import mapOutreachIdsToOutreaches from './mapOutreachIdsToOutreaches';
import OutreachForm from './OutreachForm';
import OutreachList from './OutreachList';

import withFormToggleButton from '../../../components/forms/withFormToggleButton';

const AddOutreachButton = withFormToggleButton(OutreachForm);
const OTHER = 'Other Outreach';

// It's a map, not an array!
/* eslint-disable react/no-array-index-key */

/**
 * Renders a panel for displaying a candidacies outreaches.
 * These are grouped by outreaches which are also related to the current search (SEARCH_RELATED)
 * and outreaches which are not (OTHER).
 */
const CandidacyOutreachesPanel = ({
  candidacyId,
  contactId,
  handleRefreshOutreaches,
  outreachList,
  outreaches,
  searchId,
  searchType,
}) => {
  const searchTypeForDisplay =
    searchType === TYPE_PIPELINE ? 'Pipeline' : 'Search';
  const SEARCH_RELATED = `${searchTypeForDisplay} Related`;
  const isFetching =
    outreachList && outreachList.getIn(['_meta', 'isFetching']);
  const error = outreachList && outreachList.getIn(['_meta', 'error']);
  const groups =
    outreaches &&
    outreaches
      .groupBy(outreach =>
        outreach.get('searches').includes(searchId) ? SEARCH_RELATED : OTHER,
      )
      .sortBy((_, group) => (group === SEARCH_RELATED ? 0 : 1));

  return (
    <div className='outreaches--candidacy-outreaches-panel'>
      {error && !isFetching && (
        <ErrorAlert
          onRetry={handleRefreshOutreaches}
          title='There was an error fetching outreaches'
        />
      )}
      <AddOutreachButton
        contactId={contactId}
        draftStoragePath={{
          search: searchId,
          candidate: candidacyId,
          outreach: 'new',
        }}
        searchId={searchId}
      >
        Add Outreach
      </AddOutreachButton>
      {groups &&
        groups
          .map((groupOutreaches, group) => (
            <div
              className='outreaches--candidacy-outreaches-panel-group'
              key={group}
            >
              <h3>{group}</h3>
              <OutreachList
                outreaches={groupOutreaches}
                renderRelatedSearches={group === OTHER}
                renderTitlesAsLinks={false}
                searchId={searchId}
              />
            </div>
          ))
          .toArray()}
      {isFetching && <LoadingIndicator />}
    </div>
  );
};

CandidacyOutreachesPanel.propTypes = {
  candidacyId: PropTypes.number,

  contactId: PropTypes.number.isRequired,

  handleRefreshOutreaches: PropTypes.func.isRequired,

  outreaches: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number,
    }),
  ),

  /**
   * The outreach list state for the current candidacy, used to display a
   * loading indicator if we are currently fetching.
   */
  outreachList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  searchId: PropTypes.number.isRequired,

  searchType: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('CandidacyOutreachesPanel(enhanced)'),

  // The connected version of this component only requires a candidacyId,
  // everything else will be fetched from state.
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),

  // Get the contactId for the candidacy supplied, because we're actually loading all of the
  // contact's outreaches.
  mapCandidacyIdToCandidacy,

  // Get the searchType for the candidacy supplied.
  mapCandidacyIdToSearchType,

  // Add a prop so we can manually trigger refreshes if desired.
  withProps(({ candidacy }) => ({
    parentId: candidacy && candidacy.get('contact'),
    parentType: PARENT_CONTACT,
    contactId: candidacy && candidacy.get('contact'),
    searchId: candidacy && candidacy.get('search_id'),
  })),

  // If the outreaches for the contact have not been loaded or are stale, fetch them.
  withOutreachListFetched,

  withProps(({ fetchOutreachList, outreachList }) => ({
    handleRefreshOutreaches: fetchOutreachList,
    outreachIds: outreachList && outreachList.get('ids'),
  })),

  mapOutreachIdsToOutreaches,
)(CandidacyOutreachesPanel);

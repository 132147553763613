import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import getTenantId from 'modules/tenant/selectors/getTenantId';
import getCurrentUserId from 'modules/user/selectors/getCurrentUserId';
import TemporaryBrowserData from '@thrivetrm/ui/components/TemporaryBrowserData';

const TEMPORARY_DATA_TTL_DAYS = 45;

const TrueBrowserData = ({ children, dataPath }) => {
  const tenantId = useSelector(getTenantId);
  const currentUserId = useSelector(getCurrentUserId);

  return (
    <TemporaryBrowserData
      dataPath={{ tenant: tenantId, user: currentUserId, ...dataPath }}
      temporaryDataLifespan={TEMPORARY_DATA_TTL_DAYS}
    >
      {children}
    </TemporaryBrowserData>
  );
};

TrueBrowserData.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Disabling this rule because there's no generic "key-value" pair
   * prop type and we want to be able to provide any object that matches
   * the following structure to this prop:
   *
   * {
   *    someKey: 'value',
   *    anotherKey: 1,
   * }
   */
  // eslint-disable-next-line react/forbid-prop-types
  dataPath: PropTypes.object.isRequired,
};

export default TrueBrowserData;

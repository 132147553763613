import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, setDisplayName, setPropTypes } from 'recompose';
import ModalButton from 'modules/core/componentsLegacy/ModalButton';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import mapContactIdToContact from '../mapContactIdToContact';
import CandidatePortalInviteModal from './CandidatePortalInviteModal';

const INVITE_STATUS = {
  NONE: 'Invite to Candidate Portal',
  SENT: 'Resend Invitation',
  ACCEPTED: 'Invitation Accepted',
};

const CandidatePortalInviteModalButton = ({ contact, user, ...props }) => {
  const [wasConsentRevoked, setWasConsentRevoked] = useState(false);

  const gdprStatusLabel = contact?.getIn(['gdpr_status', 'label']);

  useEffect(() => {
    if (gdprStatusLabel === 'Consent Revoked') {
      setWasConsentRevoked(true);
    } else {
      setWasConsentRevoked(false);
    }
  }, [gdprStatusLabel]);

  const getInviteStatus = () => {
    if (
      wasConsentRevoked &&
      user &&
      user.get('invitation_accepted_at') &&
      user.get('invitation_created_at')
    ) {
      return INVITE_STATUS.SENT;
    }

    if (
      user &&
      user.get('invitation_accepted_at') &&
      (gdprStatusLabel === 'Legitimate Business Interest' ||
        gdprStatusLabel === 'Consent Provided')
    ) {
      return INVITE_STATUS.ACCEPTED;
    }

    if (
      user &&
      user.get('invitation_created_at') &&
      (gdprStatusLabel === 'Not Applicable' ||
        gdprStatusLabel === 'LBI Notice Needed' ||
        gdprStatusLabel === 'Consent Pending' ||
        gdprStatusLabel === 'LBI Notice Not Sent')
    ) {
      return INVITE_STATUS.SENT;
    }
    return INVITE_STATUS.NONE;
  };

  const getModalButton = () => {
    if (gdprStatusLabel === 'Consent Revoked') {
      return (
        <TooltipTrigger
          placement='left'
          tooltip='This contact has revoked consent, so a Candidate Portal invitation cannot be sent'
          trigger={['hover', 'focus']}
        >
          <span className='CandidatePortalInviteModalButton'>
            <button
              className='CandidatePortalInviteModalButton--disabled btn btn-default'
              disabled={true}
              type='button'
            >
              Consent Revoked
            </button>
          </span>
        </TooltipTrigger>
      );
    }

    return (
      <ModalButton
        {...props}
        className='CandidatePortalInviteModalButton btn btn-default'
        contact={contact}
        modal={CandidatePortalInviteModal}
      >
        {getInviteStatus()}
      </ModalButton>
    );
  };

  return getModalButton();
};

CandidatePortalInviteModalButton.propTypes = {
  contact: PropTypes.shape({
    user_id: PropTypes.number,
  }),
  user: PropTypes.shape({
    invitation_accepted_at: PropTypes.number,
    invitation_created_at: PropTypes.string,
  }),
};

export default compose(
  setDisplayName('CandidatePortalInviteModalButton'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  withProps(({ contact }) => ({
    user: contact.get('user'),
  })),
)(CandidatePortalInviteModalButton);

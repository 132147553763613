import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import * as companyActionCreators from 'modules/companies/actions';
import * as networkActionCreators from 'modules/networks/actions';
import clearTransaction from 'modules/transactions/actions/clearTransaction';
import { SEARCH_PAGE_BASE_PATHS } from 'modules/searches/constants';
import * as contactReviewActionCreators from '../actions/contactReviews';
import * as contactActionCreators from '../actions/contacts';
import NewContactButton from '../components/contact/NewContactButton';
import { withStateProvider } from './app/StateProvider';

const onCreated = (contact, search, network) => {
  let redirectUrl = `/contacts/${contact.id}`;
  if (search) {
    redirectUrl = `${SEARCH_PAGE_BASE_PATHS[search?.type]}/${
      search.id
    }/candidates/edit`;
  }
  if (network) {
    redirectUrl = `/networks/${network.id}/edit`;
  }
  window.location.href = redirectUrl;
};

const handleDupAddedRedirect = ({ networkId, search }) => {
  const networkUrl = `/networks/${networkId}/edit`;
  const searchUrl = `${SEARCH_PAGE_BASE_PATHS[search?.type]}/${
    search.id
  }/candidates/edit`;
  window.location.href = networkId ? networkUrl : searchUrl;
};

// We can't use the router here, because this element will likely exist
// on pages where the router isn't running. Maybe someday, this will improve.
const handleUploadRedirect = ({ ids }) => {
  const isMultiple = ids.length > 1;
  window.location.href = `/contacts/pending/${isMultiple ? '' : ids[0]}`;
};

export default compose(
  setDisplayName('NewContactButton(container)'),
  setPropTypes({
    children: PropTypes.node,

    icon: PropTypes.string,

    network: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),

    search: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  withStateProvider,
  connect(
    (state, { hasPendingContacts }) => ({
      companies: state.companies,
      contacts: state.contacts,
      tenant: state.tenant,
      transactions: state.transactions,
      user: state.user,
      onCreated: onCreated,
      handleDupAddedRedirect: handleDupAddedRedirect,
      handleUploadRedirect: data =>
        handleUploadRedirect(data, hasPendingContacts),
    }),
    dispatch => ({
      contactReviewActions: bindActionCreators(
        contactReviewActionCreators,
        dispatch,
      ),
      contactActions: bindActionCreators(contactActionCreators, dispatch),
      companyActions: bindActionCreators(companyActionCreators, dispatch),
      networkActions: bindActionCreators(networkActionCreators, dispatch),
      clearTransaction: bindActionCreators(clearTransaction, dispatch),
    }),
  ),
)(NewContactButton);

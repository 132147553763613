import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import sexualOrientationSchema from '../schemas/sexualOrientations';

/**
 * Renders the display name for a given sexualOrientation ID.
 */
const SexualOrientationName = ({ id }) => (
  <EntityProperty
    entityId={id}
    entityType={sexualOrientationSchema.key}
    propertyName='name'
  />
);

SexualOrientationName.propTypes = {
  id: PropTypes.number.isRequired,
};

export default SexualOrientationName;

import { compose, setDisplayName, setStatic, withProps } from 'recompose';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import countrySchema from 'modules/tenant/schemas/countries';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';

/**
 * A dropdown/select component for choosing from a list of countries
 */
export default compose(
  setDisplayName('CountrySelectField'),
  setStatic('createFieldState', TenantOptionsSelectField.createFieldState),
  withClassName('CountrySelectField'),
  withProps({
    schema: countrySchema,
    sortProperty: 'name',
  }),
)(TenantOptionsSelectField);

import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import { ConnectionsCard, ConnectionPropType } from './ConnectionsCard';

const ConnectionsCardGroup = ({
  className,
  connections,
  contactFirstName,
  onLoadMore,
  totalCount,
}) => {
  const additionalConnectionsToLoad = totalCount - connections.length;

  return (
    <div className={className} data-testid='ConnectionsCardGroup'>
      {connections.map(connection => (
        <ConnectionsCard
          connection={connection}
          contactFirstName={contactFirstName}
          key={connection.id}
        />
      ))}
      {additionalConnectionsToLoad ? (
        <ButtonLink
          label={`See ${additionalConnectionsToLoad} more...`}
          onClick={() => onLoadMore()}
        />
      ) : null}
    </div>
  );
};

ConnectionsCardGroup.propTypes = {
  className: PropTypes.string,
  connections: PropTypes.arrayOf(ConnectionPropType).isRequired,
  /**
   * This prop is only needed for the 1st degree connections group for
   * rendering the simple connections card
   */
  contactFirstName: PropTypes.string,
  onLoadMore: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default ConnectionsCardGroup;

import { NAME } from '../constants';

/**
 * Gets the list of funding rounds for a company
 * @param {*} state
 * @param {Number} companyId The ID of the company to get the funding rounds for.
 * @returns {?Immutable.Map} The funding rounds list state, if found for the company.
 */
export default (state, companyId) =>
  state[NAME].getIn(['fundingRoundListsByCompanyId', companyId]);

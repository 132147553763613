import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import CandidacyStageDropdown from 'modules/candidacies/components/CandidacyStageDropdown';
import mapCandidacyIdToContactId from 'modules/candidacies/components/mapCandidacyIdToContactId';
import CandidacyLocationName from 'modules/candidacies/components/CandidacyLocationName';
import CandidacyTagColorSquareListEditable from 'modules/candidacies/components/tags/CandidacyTagColorSquareListEditable';
import withCandidacyTagPermissions from 'modules/candidacies/components/tags/withCandidacyTagPermissions';
import ContactIconLinkList from 'modules/contacts/components/ContactIconLinkList';

/**
 * The "secondary" details section of the candidacy list item.
 * For "large" card renders:
 *   * Move stage button rendered with the current stage name
 *   * Contact icons
 * For "small" card renders:
 *  * Contact location
 *  * Tag modal and tags
 */
const CandidacyListItemSectionDetailsSecondary = ({
  candidacyId,
  className,
  contactId,
  searchId,
  showTags,
  smallCard,
  ...props
}) => (
  <div
    className={classnames(
      className,
      'CandidacyListItemSectionDetailsSecondary',
    )}
    {...props}
  >
    {smallCard ? (
      <div>
        <div className='CandidacyListItemSectionDetailsPrimary__location'>
          <CandidacyLocationName
            candidacyId={candidacyId}
            iconPosition='right'
          />
        </div>
        {showTags && (
          <CandidacyTagColorSquareListEditable
            candidacyId={candidacyId}
            searchId={searchId}
            shouldRenderUnmatchedCriteria={false}
            tagLimit={1}
          />
        )}
      </div>
    ) : (
      <div>
        <CandidacyStageDropdown candidacyId={candidacyId} />
        <ContactIconLinkList
          contactId={contactId}
          hideWorkPhone={true}
          onlyShowOnCard={true}
          shouldShowContactSource={true}
          tooltipPosition='bottomLeft'
        />
      </div>
    )}
  </div>
);

CandidacyListItemSectionDetailsSecondary.propTypes = {
  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * An optional class name to apply to the container div
   */
  className: PropTypes.string,

  /**
   * The ID of the contact that the candidacy belongs to
   */
  contactId: PropTypes.number.isRequired,

  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,
  /**
   * True to show the candidacy tags (color squares + edit button)
   */
  showTags: PropTypes.bool,

  /**
   * Weather or not to show content on the 'small'
   * or 'large' candidacy card.
   */
  smallCard: PropTypes.bool,
};

export default compose(
  setDisplayName('CandidacyListItemSectionDetailsSecondary(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  mapCandidacyIdToContactId,
  withCandidacyTagPermissions({ isPermittedPropName: 'showTags' }),
)(CandidacyListItemSectionDetailsSecondary);

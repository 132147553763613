import React from 'react';
import ReactSelectField from 'modules/forms/components/ReactSelectField';

const SearchSummaryReportSortField = props => (
  <div className='SearchSummaryReportSortField'>
    <div className='row'>
      <ReactSelectField
        {...props}
        className='col-4'
        clearable={false}
        simpleValue={true}
      />
    </div>
  </div>
);

SearchSummaryReportSortField.defaultProps = {
  label: 'Sort By',
  placeholder: 'Select...',
};

SearchSummaryReportSortField.createFieldState =
  ReactSelectField.createFieldState;

export default SearchSummaryReportSortField;

import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import getRatingProperty from './getRatingProperty';

/**
 * Returns a value indicating if the current user can edit a particular rating.
 * @param {*} state
 * @param {Number} ratingId
 * @returns {Boolean} True if the current user can edit the rating, false otherwise.
 */
export default (state, ratingId) =>
  getRatingProperty(state, ratingId, 'user') === getCurrentUserId(state);

// Interpret manually entered 2-digit years as being within the current century, rather than in the first century.
// e.g. interpret 1/1/15 as 1/1/2015 (instead of the literal year 15).
exports.once = () => {
  $('[data-provide="datepicker"]')
    .on('input', event => {
      if (!$(event.currentTarget).data('datepicker').picker.is(':visible')) {
        $(event.currentTarget).datepicker('show');
      }
    })
    .datepicker({
      assumeNearbyYear: true,
    });
};

import wrapInSearchCandidacyLink from 'modules/candidacies/components/wrapInSearchCandidacyLink';

/**
 * A higher order component that wraps a base component in a
 * `SearchCandidacyLink` and linking to the "interviews" tab
 * @param {Component} BaseComponent The component to wrap in a linke
 * @return {Component} The BaseComponent wrapped in a link to the interviews
 *   interviews tab for a candidacy on the search page.
 */
export default wrapInSearchCandidacyLink(({ interview }) => ({
  candidacyId: interview && interview.get('candidacy_id'),
  tab: 'interviews',
}));

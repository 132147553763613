import React from 'react';
import PropTypes from 'prop-types';
import ExpandableActivityView from './components/ExpandableActivityView';
import ReferenceActivityView from './components/ReferenceActivityView';
import NoteActivityView from './components/NoteActivityView';
import EventContactActivityView from './components/EventContactActivityView';
import TaskActivityView from './components/TaskActivityView';
import OutreachActivityView from './components/OutreachActivityView';
import InterviewActivityView from './components/InterviewActivityView';
import CandidacyAssessmentActivityView from './components/CandidacyAssessmentActivityView';
import AssessmentActivityView from './components/AssessmentActivityView';

const ActivityView = ({ activityType, ...activityDetails }) => {
  switch (activityType) {
    case 'vote':
      return (
        <ExpandableActivityView content={activityDetails?.formattedComment} />
      );
    case 'candidacy_vote':
      return (
        <ExpandableActivityView content={activityDetails?.formattedComment} />
      );
    case 'comment':
      return (
        <ExpandableActivityView content={activityDetails?.formattedComment} />
      );
    case 'candidacy_comment':
      return (
        <ExpandableActivityView content={activityDetails?.formattedComment} />
      );
    case 'candidacy':
      return (
        <ExpandableActivityView content={activityDetails?.formattedComment} />
      );
    case 'reference':
      return <ReferenceActivityView {...activityDetails} />;
    case 'note':
      return <NoteActivityView {...activityDetails} />;
    case 'event_contact':
      return <EventContactActivityView {...activityDetails} />;
    case 'task':
      return <TaskActivityView {...activityDetails} />;
    case 'outreach':
      return <OutreachActivityView {...activityDetails} />;
    case 'interview':
      return <InterviewActivityView {...activityDetails} />;
    case 'meeting':
      return <InterviewActivityView {...activityDetails} />;
    case 'introduction':
      return <ExpandableActivityView content={activityDetails?.notes} />;
    case 'introduction_request':
      return <ExpandableActivityView content={activityDetails?.notes} />;
    case 'client_candidacy_assessment':
      return <CandidacyAssessmentActivityView {...activityDetails} />;
    case 'recruiter_candidacy_assessment':
      return <CandidacyAssessmentActivityView {...activityDetails} />;
    case 'researcher_candidacy_assessment':
      return <CandidacyAssessmentActivityView {...activityDetails} />;
    case 'assessment':
      return <AssessmentActivityView {...activityDetails} />;
    case 'recruiter_assessment':
      return <AssessmentActivityView {...activityDetails} />;
    case 'client_assessment':
      return <AssessmentActivityView {...activityDetails} />;
    case 'researcher_assessment':
      return <AssessmentActivityView {...activityDetails} />;
    default:
      return null;
  }
};

ActivityView.propTypes = {
  activityType: PropTypes.string,
};

export default ActivityView;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import canViewCurrentCompensation from 'modules/compensations/selectors/canViewCurrentCompensation';
import getSearchAverageCurrentCompensation from '../selectors/getSearchAverageCurrentCompensation';
import getSearchAverageDesiredCompensation from '../selectors/getSearchAverageDesiredCompensation';
import getVisibleSearchAverageCurrentCompensation from '../selectors/getVisibleSearchAverageCurrentCompensation';
import getVisibleSearchAverageDesiredCompensation from '../selectors/getVisibleSearchAverageDesiredCompensation';

const EM_DASH = '\u2014';

const CompensationList = ({ compensation, title }) => (
  <div className='SearchCompensationAveragesList'>
    <h4>{title}</h4>
    <ul className='line-item-list'>
      {compensation &&
        compensation
          .map(
            (value, key) =>
              value && (
                /* eslint-disable react/no-array-index-key */
                <li
                  className={`SearchCompensationAveragesList__${key}`}
                  key={key}
                >
                  <span className='description'>{key}</span>
                  <span className='value'>{value || EM_DASH}</span>
                </li>
                /* eslint-enable react/no-array-index-key */
              ),
          )
          .toArray()}
    </ul>
  </div>
);

const SearchCompensationFullList = ({ compensation, desiredCompensation }) => {
  return (
    <div>
      {compensation?.size > 0 && (
        <CompensationList
          compensation={compensation}
          title='Average Compensation'
        />
      )}
      {desiredCompensation?.size > 0 && (
        <CompensationList
          compensation={desiredCompensation}
          title='Average Desired Compensation'
        />
      )}
    </div>
  );
};

const getCompensationValue = (
  state,
  searchId,
  activeOnly,
  canViewHiddenCandidates,
  checkPermissions,
  compensationType,
) => {
  if (!checkPermissions || canViewCurrentCompensation(state)) {
    if (canViewHiddenCandidates) {
      return compensationType === 'current'
        ? getSearchAverageCurrentCompensation(state, searchId, activeOnly)
        : getSearchAverageDesiredCompensation(state, searchId, activeOnly);
    } else {
      return compensationType === 'current'
        ? getVisibleSearchAverageCurrentCompensation(
            state,
            searchId,
            activeOnly,
          )
        : getVisibleSearchAverageDesiredCompensation(
            state,
            searchId,
            activeOnly,
          );
    }
  }
  return undefined;
};

CompensationList.propTypes = {
  compensation: ImmutablePropTypes.mapContains({
    base: PropTypes.string,
    bonus: PropTypes.string,
    equity: PropTypes.string,
  }),
  title: PropTypes.string,
};

SearchCompensationFullList.propTypes = {
  compensation: ImmutablePropTypes.mapContains({
    base: PropTypes.string,
    bonus: PropTypes.string,
    equity: PropTypes.string,
  }),
  desiredCompensation: ImmutablePropTypes.mapContains({
    base: PropTypes.string,
    bonus: PropTypes.string,
    equity: PropTypes.string,
  }),
};

SearchCompensationFullList.defaultProps = {
  compensation: null,
  desiredCompensation: null,
};

export default compose(
  setDisplayName('SearchCompensationAveragesList(enhanced)'),
  setPropTypes({
    activeOnly: PropTypes.bool.isRequired,
    canViewHiddenCandidates: PropTypes.bool.isRequired,
    checkPermissions: PropTypes.bool.isRequired,
    searchId: PropTypes.number.isRequired,
    showBothCompensations: PropTypes.bool.isRequired,
  }),
  defaultProps({
    activeOnly: false,
    checkPermissions: true,
    showBothCompensations: false,
  }),
  connect(
    (
      state,
      { activeOnly, canViewHiddenCandidates, checkPermissions, searchId },
    ) => ({
      compensation: getCompensationValue(
        state,
        searchId,
        activeOnly,
        canViewHiddenCandidates,
        checkPermissions,
        'current',
      ),
      desiredCompensation: getCompensationValue(
        state,
        searchId,
        activeOnly,
        canViewHiddenCandidates,
        checkPermissions,
        'desired',
      ),
    }),
  ),
  mapProps(({ compensation, desiredCompensation }) => {
    const filteredCompensation =
      compensation && compensation.filter(value => Boolean(value));
    const filteredDesiredCompensation =
      desiredCompensation &&
      desiredCompensation.filter(value => Boolean(value));

    return {
      compensation: filteredCompensation || null,
      desiredCompensation: filteredDesiredCompensation || null,
    };
  }),
)(SearchCompensationFullList);

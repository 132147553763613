import PropTypes from 'prop-types';
import React from 'react';

const TabbedHeaderTabsInline = ({ children }) => (
  <ul className='TabbedHeaderTabsInline'>{children}</ul>
);

TabbedHeaderTabsInline.propTypes = {
  children: PropTypes.node,
};

export default TabbedHeaderTabsInline;

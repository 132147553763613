import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import isRatingByUser from './isRatingByUser';

/**
 * Returns a value indicating if the rating with the given ID is attributed to the
 * current user.
 * @param {*} state
 * @param {Number} ratingId The ID of the rating
 * @returns {Boolean} true if the rating with the given ID was rated by the current user;
 *   Otherwise, false.
 */
export default (state, ratingId) =>
  isRatingByUser(state, ratingId, getCurrentUserId(state));

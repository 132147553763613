import React from 'react';
import TabbedHeaderAction from 'modules/layouts/tabbedHeader/TabbedHeaderAction';
import SearchPageViewLink from 'modules/searches/components/SearchPageViewLink';
import getTenantType from 'modules/tenant/selectors/getTenantType';
import {
  TENANT_TYPE_ENTERPRISE,
  TENANT_TYPE_EXECUTIVE_SEARCH,
  TENANT_TYPE_VENTURE_CAPITAL,
} from 'modules/tenant/constants';
import { useSelector } from 'react-redux';
import { withStateProvider } from './app/StateProvider';

const EditActionContainer = () => {
  const tenantType = useSelector(getTenantType);
  const canPreviewAsClientUser = useSelector(state =>
    state.user.get('can_preview_as_client_user'),
  );
  const clientPreview = useSelector(state => state.user.get('client_preview'));

  const renderClientPreviewLabel = () => {
    switch (tenantType) {
      case TENANT_TYPE_EXECUTIVE_SEARCH:
        return 'Preview as Client';
      case TENANT_TYPE_ENTERPRISE:
      case TENANT_TYPE_VENTURE_CAPITAL:
        return 'Preview as Hiring Manager';
      default:
        return '';
    }
  };

  return canPreviewAsClientUser && !clientPreview ? (
    <div className='TabbedHeaderContainer__actions u-paddingRight-8'>
      <TabbedHeaderAction
        key='client-preview'
        label={renderClientPreviewLabel()}
      >
        <SearchPageViewLink
          page='client_preview'
          pageOnly={true}
          useDefaultLinkBehavior={true}
        >
          <i className='fa fa-eye' />
        </SearchPageViewLink>
      </TabbedHeaderAction>
    </div>
  ) : null;
};

export default withStateProvider(EditActionContainer);

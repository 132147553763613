import routes from 'modules/routing/routes';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import createCrudActions from '../../../actions/createCrudActions';
import {
  MEETINGS_FETCH_START,
  MEETINGS_FETCH_SUCCESS,
  MEETINGS_FETCH_FAILURE,
  MEETINGS_INVALIDATE,
  MEETING_FETCH_START,
  MEETING_FETCH_SUCCESS,
  MEETING_FETCH_FAILURE,
  MEETING_INVALIDATE,
  MEETING_CREATE_START,
  MEETING_CREATE_SUCCESS,
  MEETING_CREATE_FAILURE,
  MEETING_UPDATE_START,
  MEETING_UPDATE_SUCCESS,
  MEETING_UPDATE_FAILURE,
  MEETING_DELETE_START,
  MEETING_DELETE_SUCCESS,
  MEETING_DELETE_FAILURE,
} from './actionTypes';
import { PARENT_CONTACT, PARENT_CANDIDACY } from '../constants';
import meetingSchema from '../schema';
import shouldFetchMeetings from '../selectors/shouldFetchMeetings';
import maybeDispatchNotificationsCreated from '../../../actions/notifications/maybeDispatchNotificationsCreated';

const getCollectionUrl = ({ parentId, parentType }) => {
  if (parentType === PARENT_CANDIDACY) {
    return routes.api_v1_candidacy_meetings({ candidacy_id: parentId });
  }
  if (parentType === PARENT_CONTACT) {
    return routes.api_v1_contact_meetings({ contact_id: parentId });
  }
  throw new Error(`Unknown parentType for meetings: ${parentType}`);
};

const getEntityUrl = ({ id, meeting }) =>
  routes.api_v1_meeting({ id: id || meeting.id });

const {
  create: createMeeting,
  delete: deleteMeeting,
  fetch: fetchMeeting,
  fetchAll: fetchMeetings,
  invalidate: invalidateMeeting,
  invalidateAll: invalidateMeetings,
  update: updateMeeting,
} = createCrudActions({
  getUrl: getEntityUrl,
  schema: { meeting: meetingSchema },
  fetchAll: {
    getUrl: getCollectionUrl,
    schema: { meetings: [meetingSchema] },
    start: MEETINGS_FETCH_START,
    success: MEETINGS_FETCH_SUCCESS,
    failure: MEETINGS_FETCH_FAILURE,
  },
  invalidateAll: MEETINGS_INVALIDATE,
  fetch: {
    start: MEETING_FETCH_START,
    success: MEETING_FETCH_SUCCESS,
    failure: MEETING_FETCH_FAILURE,
  },
  invalidate: MEETING_INVALIDATE,
  create: {
    getUrl: getCollectionUrl,
    start: MEETING_CREATE_START,
    success: MEETING_CREATE_SUCCESS,
    failure: MEETING_CREATE_FAILURE,
    afterResolveSuccess: (dispatch, action) => {
      // Temporary measure until we normalize notifications.
      // This dispatches a second action but alters the payload in a way that
      // maybeDispatchNotificationsCreated expects.
      const meetingId = action.payload.result.meeting;
      const meeting = action.payload.entities.meetings[meetingId];
      dispatch(
        maybeDispatchNotificationsCreated(
          {
            type: action.type,
            payload: { data: { meeting: meeting } },
          },
          'meeting',
        ),
      );

      if (action.payload.meeting.integration_id) {
        dispatch(
          toastSuccess(
            'The event has been successfully sent to all attendees',
            { title: 'Invitation Sent' },
          ),
        );
      }
    },
  },
  update: {
    start: MEETING_UPDATE_START,
    success: MEETING_UPDATE_SUCCESS,
    failure: MEETING_UPDATE_FAILURE,
    afterResolveSuccess: (dispatch, action) => {
      if (action.payload.meeting.integration_id) {
        dispatch(
          toastSuccess(
            'The event has been successfully updated for all attendees',
            { title: 'Event Updated' },
          ),
        );
      }
    },
  },
  delete: {
    schema: null,
    start: MEETING_DELETE_START,
    success: MEETING_DELETE_SUCCESS,
    failure: MEETING_DELETE_FAILURE,
  },
});

export const fetchMeetingsIfNeeded = ({ parentId, parentType }) => (
  dispatch,
  getState,
) => {
  const shouldFetch = shouldFetchMeetings(getState(), parentType, parentId);
  if (shouldFetch) {
    dispatch(fetchMeetings({ parentId: parentId, parentType: parentType }));
  }
};

export {
  fetchMeetings,
  invalidateMeetings,
  fetchMeeting,
  invalidateMeeting,
  createMeeting,
  updateMeeting,
  deleteMeeting,
};

import selectn from 'selectn';
import invalidateCandidacyStatus from 'modules/candidacies/actions/invalidateCandidacyStatus';
import routes from 'modules/routing/routes';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import { fetchNotifications } from 'actions/contacts';
import selectedContact from 'selectors/contact/selectedContact';
import createCrudActions from '../../../actions/createCrudActions';
import {
  INTERVIEW_LIST_FETCH_START,
  INTERVIEW_LIST_FETCH_SUCCESS,
  INTERVIEW_LIST_FETCH_FAILURE,
  INTERVIEW_LIST_INVALIDATE,
  INTERVIEW_FETCH_START,
  INTERVIEW_FETCH_SUCCESS,
  INTERVIEW_FETCH_FAILURE,
  INTERVIEW_INVALIDATE,
  INTERVIEW_CREATE_START,
  INTERVIEW_CREATE_SUCCESS,
  INTERVIEW_CREATE_FAILURE,
  INTERVIEW_UPDATE_START,
  INTERVIEW_UPDATE_SUCCESS,
  INTERVIEW_UPDATE_FAILURE,
  INTERVIEW_DELETE_START,
  INTERVIEW_DELETE_SUCCESS,
  INTERVIEW_DELETE_FAILURE,
} from './actionTypes';
import {
  PARENT_CANDIDACY,
  PARENT_SEARCH,
  DATE_GROUP_PAST,
  DATE_GROUP_UPCOMING,
  SORT_BY_DATE,
} from '../constants';
import getInterview from '../selectors/getInterview';
import interviewSchema from '../schema';
import maybeDispatchNotificationsCreated from '../../../actions/notifications/maybeDispatchNotificationsCreated';

/**
 * Gets the query that should be used for the server request for a particular set of interviews.
 * @param {String} filter The interviews filter (DATE_GROUP_PAST or DATE_GROUP_UPCOMING)
 * @return {Object} The query value for the filter.
 */
const queryForFilter = filter => {
  switch (filter) {
    case DATE_GROUP_PAST: {
      return {
        filter: 'past',
        limit: 3,
        sort_by: SORT_BY_DATE,
        sort_dir: 'desc',
        hidden: true,
      };
    }
    case DATE_GROUP_UPCOMING: {
      return {
        filter: 'upcoming',
        limit: 3,
        sort_by: SORT_BY_DATE,
        sort_dir: 'asc',
        hidden: true,
      };
    }
    default: {
      return {};
    }
  }
};

const getCollectionUrl = ({ filter, interview, parentId, parentType }) => {
  const query = queryForFilter(filter);

  switch (parentType) {
    case PARENT_CANDIDACY: {
      return routes.api_v1_candidacy_interviews({
        candidacyId: parentId,
        query: query,
      });
    }
    case PARENT_SEARCH: {
      return routes.api_v1_search_interviews({
        searchId: parentId,
        query: query,
      });
    }
    default: {
      if (interview) {
        if (interview.candidacy_id) {
          return routes.api_v1_candidacy_interviews({
            candidacyId: interview.candidacy_id,
          });
        }

        throw new Error('Interview is missing candidacy_id');
      }

      throw new Error(`Unknown parentType for interviews: ${parentType}`);
    }
  }
};

const getEntityUrl = ({ id, interview }) =>
  routes.api_v1_interview({ id: id || interview.id });

const invalidateCandidacyStatusIfNeeded = action => (dispatch, getState) => {
  if (
    action.type === INTERVIEW_CREATE_SUCCESS ||
    action.type === INTERVIEW_UPDATE_SUCCESS
  ) {
    const interviewId = selectn('payload.result.interview', action);
    const interview = selectn(
      `payload.entities.interviews.${interviewId}`,
      action,
    );
    if (interview && interview.candidacy_id) {
      dispatch(
        invalidateCandidacyStatus({
          id: interview.candidacy_id,
        }),
      );
    }
  } else if (action.type === INTERVIEW_DELETE_SUCCESS) {
    const interview = getInterview(getState(), action.payload.id);
    if (interview && interview.has('candidacy_id')) {
      dispatch(
        invalidateCandidacyStatus({
          id: interview.get('candidacy_id'),
        }),
      );
    }
  }
};

const afterResolveSuccess = (dispatch, action) => {
  dispatch(invalidateCandidacyStatusIfNeeded(action));
  // Ick. This is gross. Temporary measure until we normalize notifications.
  // This dispatches a second action but alters the payload in a way that
  // maybeDispatchNotificationsCreated expects.
  const interviewId = action.payload.result.interview;
  const interview = action.payload.entities.interviews[interviewId];
  dispatch(
    maybeDispatchNotificationsCreated(
      {
        type: action.type,
        payload: { data: { interview: interview } },
      },
      'interview',
    ),
  );
};

const invalidateNotification = () => (dispatch, getState) => {
  const state = getState();
  const contactNotifications = selectedContact(state)?.get('notifications');
  const contact = state.contacts.get('selectedContact');
  dispatch(
    fetchNotifications({
      limit: contactNotifications.get('data').size,
      contactId: contact.get('id'),
      filters: contactNotifications.getIn(['meta', 'filters']).toJS(),
    }),
  );
};

const {
  create: createInterview,
  delete: deleteInterview,
  fetch: fetchInterview,
  fetchAll: fetchInterviewList,
  invalidate: invalidateInterview,
  invalidateAll: invalidateInterviewList,
  update: updateInterview,
} = createCrudActions({
  getUrl: getEntityUrl,
  schema: { interview: interviewSchema },
  fetchAll: {
    getUrl: getCollectionUrl,
    schema: { interviews: [interviewSchema] },
    start: INTERVIEW_LIST_FETCH_START,
    success: INTERVIEW_LIST_FETCH_SUCCESS,
    failure: INTERVIEW_LIST_FETCH_FAILURE,
  },
  invalidateAll: INTERVIEW_LIST_INVALIDATE,
  fetch: {
    getUrl: routes.api_v1_interview,
    start: INTERVIEW_FETCH_START,
    success: INTERVIEW_FETCH_SUCCESS,
    failure: INTERVIEW_FETCH_FAILURE,
  },
  invalidate: INTERVIEW_INVALIDATE,
  create: {
    getUrl: getCollectionUrl,
    start: INTERVIEW_CREATE_START,
    success: INTERVIEW_CREATE_SUCCESS,
    failure: INTERVIEW_CREATE_FAILURE,
    afterResolveSuccess: (dispatch, action) => {
      afterResolveSuccess(dispatch, action);
      if (action.payload.interview.integration_id) {
        dispatch(
          toastSuccess(
            'The event has been successfully sent to all attendees',
            {
              title: 'Invitation Sent',
            },
          ),
        );
      }
    },
  },
  update: {
    start: INTERVIEW_UPDATE_START,
    success: INTERVIEW_UPDATE_SUCCESS,
    failure: INTERVIEW_UPDATE_FAILURE,
    afterResolveSuccess: (dispatch, action) => {
      afterResolveSuccess(dispatch, action);
      dispatch(invalidateNotification());
      if (action.payload.interview.integration_id) {
        dispatch(
          toastSuccess(
            'The event has been successfully updated for all attendees',
            { title: 'Event Updated' },
          ),
        );
      }
    },
  },
  delete: {
    schema: null,
    start: INTERVIEW_DELETE_START,
    success: INTERVIEW_DELETE_SUCCESS,
    failure: INTERVIEW_DELETE_FAILURE,
    afterResolveSuccess: (dispatch, action) => {
      dispatch(invalidateCandidacyStatusIfNeeded(action));
      dispatch(invalidateNotification());
    },
  },
});

export {
  fetchInterviewList,
  invalidateInterviewList,
  fetchInterview,
  invalidateInterview,
  createInterview,
  updateInterview,
  deleteInterview,
};

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import RevenueRangeValues from 'modules/currency/components/RevenueRangeValues';
import CompanyInvestmentStageNames from 'modules/tenant/components/CompanyInvestmentStageNames';

/**
 * Renders a contact's investor-related fields.
 */
const ContactInvestorView = ({ contact }) => (
  <div className='ContactInvestorView row'>
    {contact.get('preferred_investor_interest') && (
      <div className='col-12'>
        <LabeledContent label='Interested?'>Yes</LabeledContent>
      </div>
    )}
    {contact.get('preferred_investor_company_stages') &&
      contact.get('preferred_investor_company_stages').count() > 0 && (
        <div className='col-12'>
          <LabeledContent label='Company Stages'>
            <CompanyInvestmentStageNames
              ids={contact.get('preferred_investor_company_stages').toArray()}
            />
          </LabeledContent>
        </div>
      )}
    {(contact.get('preferred_investor_revenue_minimum') ||
      contact.get('preferred_investor_revenue_maximum')) && (
      <div className='col-12'>
        <LabeledContent label='Revenue Range'>
          <RevenueRangeValues
            maximum={contact.get('preferred_investor_revenue_maximum')}
            minimum={contact.get('preferred_investor_revenue_minimum')}
          />
        </LabeledContent>
      </div>
    )}
    {contact.get('preferred_investor_notes') && (
      <div className='col-12'>
        <LabeledContent label='Notes'>
          {contact.get('preferred_investor_notes')}
        </LabeledContent>
      </div>
    )}
  </div>
);

ContactInvestorView.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    preferred_investor_interest: PropTypes.bool,
    preferred_investor_company_stages: ImmutablePropTypes.listOf(
      PropTypes.number.isRequired,
    ),
    preferred_investor_revenue_minimum: PropTypes.number,
    preferred_investor_revenue_maximum: PropTypes.number,
    preferred_investor_notes: PropTypes.string,
  }).isRequired,
};

/**
 * Determines if the component will render anything when given the contact specified.
 * @param {Object} options
 * @param {Map} contact The contact record.
 */
ContactInvestorView.willRender = ({ contact }) =>
  Boolean(
    contact &&
      (contact.get('preferred_investor_interest') ||
        (contact.get('preferred_investor_company_stages') &&
          contact.get('preferred_investor_company_stages').count > 0) ||
        typeof contact.get('preferred_investor_revenue_minimum') === 'number' ||
        typeof contact.get('preferred_investor_revenue_maximum') === 'number' ||
        contact.get('preferred_investor_notes')),
  );

export default compose(
  setDisplayName('ContactInvestorView(enhanced)'),
  setStatic('willRender', ContactInvestorView.willRender),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  branch(
    ({ contact }) => !ContactInvestorView.willRender({ contact: contact }),
    renderNothing,
  ),
)(ContactInvestorView);

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { compose, withPropsOnChange, withProps } from 'recompose';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import ImmutablePropTypes from 'react-immutable-proptypes';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import { TYPE_JOB_SEARCH, TYPE_TALENT_POOL } from 'modules/searches/constants';
import ContactAffiliationsField from 'modules/contacts/components/ContactAffiliationsField';
import ContactJobSearchCandidacyListTableView from 'modules/candidacies/components/table/ContactJobSearchCandidacyListTableView';
import ContactTalentPoolCandidacyListTableView from 'modules/candidacies/components/table/ContactTalentPoolCandidacyListTableView';
import ContactDiversityEditableSection from 'modules/contact-diversity/components/ContactDiversityEditableSection';
import ContactConnectionsEditableSection from 'modules/contact-connections/components/ContactConnectionsEditableSection';
import InteractionLevelsEditableSection from 'modules/contacts/components/interactionLevels/InteractionLevelsEditableSection';
import ContactInvestorEditableSection from 'modules/contact-investor/components/ContactInvestorEditableSection';
import ContactJobOpportunityStatusEditableSection from 'modules/contact-job-opportunity-status/components/ContactJobOpportunityStatusEditableSection';
import ContactCommentsPanel from 'modules/contact-comments/components/ContactCommentsPanel';
import ContactCareerHighlightsEditableSection from 'modules/contacts/components/careerHighlights/ContactCareerHighlightsEditableSection';
import ContactCaslStatusEditableSection from 'modules/contacts/components/consentStatus/caslStatus/ContactCaslStatusEditableSection';
import ContactCitizenshipsForm from 'modules/contacts/components/ContactCitizenshipsForm';
import ContactCompanyInvestmentStagesForm from 'modules/contacts/components/ContactCompanyInvestmentStagesForm';
import ContactDesiredCompensationEditableModalSection from 'modules/contacts/components/desiredCompensation/ContactDesiredCompensationEditableModalSection';
import ContactInterimCompensationEditableSection from 'modules/contacts/components/interimCompensation/ContactInterimCompensationEditableSection';
import {
  CONTACT_DESIRED_INTERIM_COMPENSATION,
  CONTACT_CURRENT_INTERIM_COMPENSATION,
} from 'modules/contacts/components/interimCompensation/constants';
import ContactEducations from 'modules/contacts/components/educations/ContactEducations';
import ContactGdprStatusEditableSection from 'modules/contacts/components/consentStatus/gdprStatus/ContactGdprStatusEditableSection';
import ContactIndustriesForm from 'modules/contacts/components/ContactIndustriesForm';
import ContactInternalNotesEditableSection from 'modules/contacts/components/internalNotes/ContactInternalNotesEditableSection';
import ContactJobFunctionsForm from 'modules/contacts/components/ContactJobFunctionsForm';
import ContactLanguagesForm from 'modules/contacts/components/ContactLanguagesForm';
import ContactPortfolioCompaniesForm from 'modules/contacts/components/ContactPortfolioCompaniesForm';
import ContactPositionsPanel from 'modules/contacts/components/positions/ContactPositionsPanel';
import ContactSectorsForm from 'modules/contacts/components/ContactSectorsForm';
import ContactSkillsForm from 'modules/contacts/components/ContactSkillsForm';
import ContactDownloadReportButton from 'modules/contacts/components/contactSummaryReport/ContactDownloadReportButton';
import ContactUsVisaRequiredEditableSection from 'modules/contacts/components/usVisaRequired/ContactUsVisaRequiredEditableSection';
import mapContactIdToFieldGroupsLastUpdated from 'modules/contacts/components/mapContactIdToFieldGroupsLastUpdated';
import {
  CANDIDATE_USER_CASL_STATUS_OPTIONS,
  CASL_STATUS_OPTIONS,
} from 'modules/contacts/constants';
import CompensationEditableSection from 'modules/compensations/components/CompensationEditableSection';
import {
  NAME_EMPLOYMENT,
  NAME_BOARD_MEMBER,
} from 'modules/compensations/constants';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import ClipboardModalButton from 'modules/core/componentsLegacy/ClipboardModalButton';
import CollapsiblePanel from 'modules/core/componentsLegacy/CollapsiblePanel';
import DocumentTableView from 'modules/documents/components/DocumentTableView';
import ContactNetworksPanel from 'modules/networks/components/ContactNetworksPanel';
import ExternalLink from 'modules/links/ExternalLink';
import routes from 'modules/routing/routes';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import ContactCalledToTheBarEditableSection from 'modules/contacts/components/ContactCalledToTheBarEditableSection';
import ContactConnectionsSection from 'modules/connections/components/ContactConnectionsSection';
import ContactProductAreasField from 'modules/contacts/components/ContactProductAreasField';
import Tag from 'modules/core/components/Tag';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Icon from '@thrivetrm/ui/components/Icon';
import { getDuplicateContacts } from '../../domains/contacts/duplicateRecognition';
import AddToSearchForm from './searches/AddToSearchForm';
import BoardMemberInfo from './boardMemberInfo/BoardMemberInfo';
import CandidateContactHeader from './header/CandidateContactHeader';
import CompanyPreferences from './companyPreferences/CompanyPreferences';
import ContactActivity from './ContactActivity';
import ContactDuplicates from './basic/ContactDuplicates';
import ContactHeader from './header/ContactHeader';
import ContactSocial from './social/ContactSocial';
import ContactStatusHeader from './ContactStatusHeader';
import labels from '../../labels/index.json';
import SpecialNeeds from './internalNotes/SpecialNeeds';
import SpecialNeedsCandidate from './internalNotes/SpecialNeedsCandidate';
import withConnection from '../../containers/app/pages/contact/withConnection';
import withContactLoaded from '../../containers/app/pages/contact/withContactLoaded';

function ContactPage({
  clearTransaction,
  companies,
  companyActions,
  contact,
  contactActions,
  contactId,
  contactReviewActions,
  contacts,
  duplicateDetectionId,
  fieldGroupsLastUpdated,
  hasAffiliations,
  hasCalledToTheBar,
  hasCaslEnabled,
  hasCitizenship,
  hasConnectionsFeature,
  hasDiversity,
  hasDiversityBirthYear,
  hasTargetOptionProceedsFields,
  hasUsVisaRequired,
  introductionActions,
  tenant,
  transactions,
  user,
}) {
  const rocketreachTenant = tenant.get('rocketreach');
  const talentPoolsUser = user.get('can_view_talent_pools');
  const rocketreachUser = user.get('rocketreach');
  const slackIntegrated = user.get('slack_integrated');
  const gdprStatus = contact.getIn(['data', 'gdpr_status', 'label']);
  const hubspotId = contact.getIn(['data', 'hubspot_id']);
  const hubspotContactUrl = contact.getIn(['data', 'hubspot_contact_url']);
  const resumeUrl = contact.getIn(['data', 'resume', 'url']);
  const duplicates = getDuplicateContacts(duplicateDetectionId, contacts);
  const isCandidateUser = user.get('is_candidate');
  const isCrmUser = user.get('crm_user');
  const userType = isCandidateUser ? 'candidate' : 'noncandidate';
  const filteredDuplicates = key =>
    duplicates[key].filter(duplicate => duplicate.get('id') !== contactId);
  const companyStagesHeader = labels.companyStages[userType];
  const rolesHeader = labels.roles[userType];
  const industriesHeader = labels.industries[userType];
  const sectorsHeader = labels.sectors[userType];
  const productAreasHeader = labels.productAreas[userType];
  const portfolioCompaniesHeader = labels.portfolioCompanies[userType];
  const currentUserEmail = user.get('email');
  const productAreaIds = tenant
    .getIn(['tenantOptions', 'productAreas'])
    ?.toJS();
  const hasInterimPlacementFeatureEnabled = useFeatureCheck(
    'feature.interim_placements',
  );
  const hasLinkedInSkillsFeature = useFeatureCheck('feature.linkedin_skills');
  const [skillLimit, setSkillLimit] = useState(20);
  const [
    loadLinkedInSkills,
    loadingLinkedInSkills,
    _linkedInSkillsError,
    linkedInSkillsData,
  ] = useApiGet(
    routes.api_v1_contacts_contact_linkedin_skills({
      query: { contact_id: contactId, limit: skillLimit },
    }),
  );

  useEffect(() => {
    if (contact && hasLinkedInSkillsFeature) {
      loadLinkedInSkills({
        query: { contact_id: contactId, limit: skillLimit },
      });
    }
  }, [hasLinkedInSkillsFeature, loadLinkedInSkills, skillLimit]);
  const numberOfSkillsRemaining =
    Number(linkedInSkillsData?.metadata.total_linkedin_skills_count) -
    Number(linkedInSkillsData?.linkedin_skills.length);

  const contactLinkedInSkills = linkedInSkillsData?.linkedin_skills.map(
    ({ skill_name: skillName }) => <Tag key={skillName} name={skillName} />,
  );

  const renderLinkedInSkills = () => {
    if (contactLinkedInSkills?.length) {
      return contactLinkedInSkills;
    }
    return loadingLinkedInSkills
      ? 'Skills loading'
      : 'There were no skills on this LinkedIn profile.';
  };

  // Refetch the contact if it has been invalidated (aka "marked as stale" in new world Redux slices)
  // Actions in the Quick View Panel (e.g. adding/removing a connection) may require reloading the
  // data displayed in the main page to reflect the changes.
  const isInvalidated = contact.getIn(['meta', 'isInvalidated']);
  useEffect(() => {
    if (isInvalidated) {
      contactActions.fetchContact({ contactId: contactId });
    }
  }, [contactActions, contactId, isInvalidated]);

  const productAreasForm =
    productAreaIds?.length > 0 ? (
      <div className='row'>
        <div className='col-sm-6'>
          <h3 className='contact-form-section-header'>{productAreasHeader}</h3>
          <ContactProductAreasField
            className='u-marginBottom-16'
            contactId={contactId}
          />
        </div>
      </div>
    ) : null;

  return (
    <div className='container contact-form contact-form-view-mode u-marginTop-16'>
      {isCandidateUser ? null : (
        <ContactStatusHeader
          clearTransaction={clearTransaction}
          contact={contact}
          contactReviewActions={contactReviewActions}
          rocketreach={rocketreachTenant && rocketreachUser}
          rocketreachUser={rocketreachUser}
          transactions={transactions}
        />
      )}

      <div className='contact-form-basic'>
        <aside>
          <ContactDuplicates
            duplicates={{ name: filteredDuplicates('name') }}
          />

          {isCandidateUser ? (
            <CandidateContactHeader
              clearTransaction={clearTransaction}
              companies={companies}
              companyActions={companyActions}
              contact={contact}
              contactActions={contactActions}
              contacts={contacts}
              duplicateDetectionId={duplicateDetectionId}
              transactions={transactions}
              userType={userType}
            />
          ) : (
            <ContactHeader
              clearTransaction={clearTransaction}
              companies={companies}
              companyActions={companyActions}
              contactActions={contactActions}
              contactId={contactId}
              contacts={contacts}
              duplicateDetectionId={duplicateDetectionId}
              transactions={transactions}
            />
          )}

          {isCandidateUser ? null : (
            <div className='u-flex u-flexAlign-c u-paddingVertical-16 u-borderVertical u-noWrap u-marginVertical-8'>
              {resumeUrl ? (
                <ButtonSecondary
                  className='ContactPage__resumeButton'
                  icon='download'
                  isFullWidth={true}
                  label='Resume'
                  rel='noopener noreferrer'
                  size='small'
                  target='_blank'
                  url={resumeUrl}
                />
              ) : null}
              <ContactDownloadReportButton
                contactId={contactId}
                currentUserEmail={currentUserEmail}
              />
              {slackIntegrated ? (
                <ClipboardModalButton
                  buttonClassName='u-marginLeft-16 btn btn-social btn-social-icon-only--small btn-social-slack'
                  inputValue={`/thrive ${window.location.href}`}
                  placement='top'
                  prompt='Copy Slack Command'
                  text={`/thrive ${window.location.href}`}
                  title='Share in Slack'
                  tooltip='Share Profile in Slack'
                >
                  <i />
                </ClipboardModalButton>
              ) : null}
              {hubspotId ? (
                <ExternalLink
                  className='u-marginLeft-16 btn btn-social btn-social-icon-only--small btn-social-hubspot '
                  href={hubspotContactUrl}
                >
                  <i />
                </ExternalLink>
              ) : null}
            </div>
          )}
          {isCandidateUser ? null : (
            <ContactCommentsPanel
              contactId={contactId}
              popoverPlacement='left'
            />
          )}
        </aside>

        <div className='contact-form-basic-main'>
          <ContactDuplicates
            duplicates={{
              email: filteredDuplicates('email'),
              work_email: filteredDuplicates('work_email'),
            }}
          />
          <div className='contact-form__ContactSocial'>
            <ContactSocial
              clearTransaction={clearTransaction}
              contact={contact}
              contactActions={contactActions}
              contacts={contacts}
              duplicateDetectionId={duplicateDetectionId}
              transactions={transactions}
              userType={userType}
            />
          </div>
          {isCandidateUser ? null : (
            <div className='row'>
              <div className='col-sm-12 ContactPage__gdprStatusSection'>
                <ContactGdprStatusEditableSection
                  contactId={contactId}
                  lastUpdated={fieldGroupsLastUpdated.gdpr}
                />
              </div>
            </div>
          )}
          {hasCaslEnabled && !isCandidateUser ? (
            <div className='row'>
              <div className='col-sm-12 ContactPage__caslStatusSection'>
                <ContactCaslStatusEditableSection
                  contactId={contactId}
                  lastUpdated={fieldGroupsLastUpdated.casl}
                  statusOptions={CASL_STATUS_OPTIONS}
                />
              </div>
            </div>
          ) : null}
          {isCandidateUser ? null : (
            <div key='non-candidate-data'>
              <InteractionLevelsEditableSection contactId={contactId} />
              {hasConnectionsFeature ? (
                <ContactConnectionsSection contactId={contactId} />
              ) : (
                <ContactConnectionsEditableSection contactId={contactId} />
              )}
              <ContactCareerHighlightsEditableSection
                contactId={contactId}
                draftStoragePath={{
                  contact: contactId,
                  careerHighlight: 'content',
                }}
                lastUpdated={fieldGroupsLastUpdated.careerHighlights}
              />
              {hasCalledToTheBar ? (
                <ContactCalledToTheBarEditableSection contactId={contactId} />
              ) : null}
              <h3 className='u-marginTop-16 u-marginBottom-8'>Compensation</h3>
              <h4 className='u-marginBottom-4'>Full Time Compensation</h4>
              <ContactDesiredCompensationEditableModalSection
                contactId={contactId}
                lastUpdated={fieldGroupsLastUpdated.desiredCompensation}
                title='Desired Compensation'
              />
              {tenant.get('has_current_compensation_enabled') ? (
                <CompensationEditableSection
                  className='CurrentCompensationEditableSection'
                  compensationId={contact.getIn([
                    'data',
                    'employee_compensation',
                  ])}
                  compensationName={NAME_EMPLOYMENT}
                  contactId={contactId}
                  lastUpdated={fieldGroupsLastUpdated.currentCompensation}
                  legalDisclaimer='Refer to local legislation prior to recording candidate compensation.'
                  showTargetOptionProceeds={hasTargetOptionProceedsFields}
                />
              ) : null}
              {tenant.get('has_board_member_compensation_enabled') ? (
                <CompensationEditableSection
                  className='BoardMemberCompensationEditableSection'
                  compensationId={contact.getIn([
                    'data',
                    'board_member_compensation',
                  ])}
                  compensationName={NAME_BOARD_MEMBER}
                  contactId={contactId}
                  lastUpdated={fieldGroupsLastUpdated.boardMemberCompensation}
                />
              ) : null}
              {hasInterimPlacementFeatureEnabled ? (
                <>
                  <h4 className='u-marginTop-24'>Interim Compensation</h4>
                  <ContactInterimCompensationEditableSection
                    className='ContactDesiredInterimCompensationEditableSection'
                    contactId={contactId}
                    interimCompensationId={contact.getIn([
                      'data',
                      'contact_desired_interim_placement',
                    ])}
                    interimCompensationName={
                      CONTACT_DESIRED_INTERIM_COMPENSATION
                    }
                  />
                  <ContactInterimCompensationEditableSection
                    className='ContactCurrentInterimCompensationEditableSection'
                    contactId={contactId}
                    interimCompensationId={contact.getIn([
                      'data',
                      'contact_current_interim_placement',
                    ])}
                    interimCompensationName={
                      CONTACT_CURRENT_INTERIM_COMPENSATION
                    }
                  />
                </>
              ) : null}
              <div className='row'>
                <div className='col-sm-12'>
                  <h3 className='contact-form-section-header'>Skills</h3>
                  <ContactSkillsForm contactId={contactId} />
                </div>
              </div>
              {hasLinkedInSkillsFeature ? (
                <div className='row'>
                  <div className='col-sm-12 u-marginBottom-8'>
                    <h3 className='contact-form-section-header'>
                      LinkedIn Skills
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={uniqueId()}>
                            Imported from LinkedIn. Self-reported by candidate;
                            not editable.
                          </Tooltip>
                        }
                        placement='top'
                      >
                        <i className='linkedInSkillsTooltipIcon fa fa-info-circle btn-tooltip' />
                      </OverlayTrigger>
                    </h3>
                    {renderLinkedInSkills()}
                  </div>
                  <div className='col-sm-12'>
                    {numberOfSkillsRemaining > 0 && !loadingLinkedInSkills ? (
                      <ButtonLink
                        label={
                          <>
                            <Icon
                              className='u-marginRight-8'
                              color='blue'
                              type='chevronDown'
                            />
                            Load {numberOfSkillsRemaining} more
                          </>
                        }
                        onClick={() =>
                          setSkillLimit(
                            linkedInSkillsData.metadata
                              .total_linkedin_skills_count,
                          )
                        }
                      />
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className='row'>
                <div className='col-sm-12'>
                  <h3 className='contact-form-section-header'>Languages</h3>
                  <ContactLanguagesForm contactId={contactId} />
                </div>
              </div>
              {hasCitizenship ? (
                <div className='row'>
                  <div className='col-sm-12'>
                    <h3 className='contact-form-section-header'>Citizenship</h3>
                    <ContactCitizenshipsForm contactId={contactId} />
                  </div>
                </div>
              ) : null}
              {hasDiversity || hasDiversityBirthYear ? (
                <div className='row'>
                  <div className='col-sm-12'>
                    <ContactDiversityEditableSection
                      contactId={contactId}
                      lastUpdated={fieldGroupsLastUpdated.diversity}
                    />
                  </div>
                </div>
              ) : null}
              {hasUsVisaRequired ? (
                <div className='row'>
                  <div className='col-sm-12'>
                    <ContactUsVisaRequiredEditableSection
                      contactId={contactId}
                    />
                  </div>
                </div>
              ) : null}
              {hasAffiliations ? (
                <ContactAffiliationsField contact={contact} tenant={tenant} />
              ) : null}
            </div>
          )}
        </div>
      </div>

      {isCandidateUser ? null : (
        <CollapsiblePanel
          className='contact-activity-panel'
          isInitiallyExpanded={true}
          key='activity'
          title='Activity Timeline'
        >
          <ContactActivity
            clearTransaction={clearTransaction}
            companies={companies}
            companyActions={companyActions}
            contact={contact}
            contactActions={contactActions}
            contacts={contacts}
            introductionActions={introductionActions}
            limit={5}
            transactions={transactions}
            user={user}
          />
        </CollapsiblePanel>
      )}

      {isCandidateUser ? (
        <CollapsiblePanel
          className='ContactPage__jobPreferences'
          isInitiallyExpanded={true}
          key='job-preferences-candidate'
          title='Job Preferences'
        >
          <div className='row'>
            <div className='col-sm-12'>
              <div className='contact-form__ContactJobOpportunityStatusEditableSection'>
                <ContactJobOpportunityStatusEditableSection
                  contactId={contactId}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='contact-form__CompanyPreferences--isCandidateUser'>
                <CompanyPreferences
                  clearTransaction={clearTransaction}
                  contact={contact}
                  contactActions={contactActions}
                  transactions={transactions}
                  userType={userType}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <ContactDesiredCompensationEditableModalSection
                contactId={contactId}
                title='What is your desired compensation?'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <h3 className='contact-form-section-header'>
                {companyStagesHeader}
              </h3>
              <ContactCompanyInvestmentStagesForm contactId={contactId} />
            </div>
            <div className='col-sm-6'>
              <h3 className='contact-form-section-header'>{rolesHeader}</h3>
              <ContactJobFunctionsForm contactId={contactId} />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <h3 className='contact-form-section-header'>
                {industriesHeader}
              </h3>
              <ContactIndustriesForm contactId={contactId} />
            </div>
            <div className='col-sm-6'>
              <h3 className='contact-form-section-header'>{sectorsHeader}</h3>
              <ContactSectorsForm contactId={contactId} />
            </div>
          </div>
          {productAreasForm}
          {tenant.get('tenant_type') === 'venture_capital' ? (
            <div className='row'>
              <div className='col-sm-6'>
                <h3 className='contact-form-section-header'>
                  {portfolioCompaniesHeader}
                </h3>
                <ContactPortfolioCompaniesForm contactId={contactId} />
              </div>
            </div>
          ) : null}
          <SpecialNeedsCandidate
            clearTransaction={clearTransaction}
            contact={contact}
            contactActions={contactActions}
            transactions={transactions}
          />
          {hasDiversity || hasDiversityBirthYear ? (
            <div className='row'>
              <div className='col-sm-12'>
                <ContactDiversityEditableSection contactId={contactId} />
              </div>
            </div>
          ) : null}
        </CollapsiblePanel>
      ) : null}
      <CollapsiblePanel
        className='ContactPage__experience'
        key='experience'
        title='Experience'
      >
        <ContactPositionsPanel contactId={contactId} />
      </CollapsiblePanel>

      <CollapsiblePanel
        className='ContactPage__education'
        key='education'
        title='Education'
      >
        <ContactEducations contactId={contactId} />
      </CollapsiblePanel>

      {isCandidateUser ? null : (
        <CollapsiblePanel
          className='ContactPage__jobPreferences'
          key='job-preferences'
          title='Job Preferences'
        >
          <div className='row'>
            <div className='col-sm-12'>
              <div className='contact-form__ContactJobOpportunityStatusEditableSection'>
                <ContactJobOpportunityStatusEditableSection
                  contactId={contactId}
                />
              </div>
            </div>
            <div className='col-sm-12'>
              <div className='contact-form__CompanyPreferences'>
                <CompanyPreferences
                  clearTransaction={clearTransaction}
                  contact={contact}
                  contactActions={contactActions}
                  transactions={transactions}
                  userType={userType}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <h3 className='contact-form-section-header'>Company Stages</h3>
              <ContactCompanyInvestmentStagesForm contactId={contactId} />
            </div>
            <div className='col-sm-6'>
              <h3 className='contact-form-section-header'>Roles</h3>
              <ContactJobFunctionsForm contactId={contactId} />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <h3 className='contact-form-section-header'>Industries</h3>
              <ContactIndustriesForm contactId={contactId} />
            </div>
            <div className='col-sm-6'>
              <h3 className='contact-form-section-header'>Sectors</h3>
              <ContactSectorsForm contactId={contactId} />
            </div>
          </div>
          {productAreasForm}
          {tenant.get('tenant_type') === 'venture_capital' ? (
            <div className='row'>
              <div className='col-sm-6'>
                <h3 className='contact-form-section-header'>
                  Portfolio Companies
                </h3>
                <ContactPortfolioCompaniesForm contactId={contactId} />
              </div>
            </div>
          ) : null}
          <ContactInternalNotesEditableSection contactId={contactId} />
          <SpecialNeeds contact={contact} />
          <div className='row'>
            <div className='col-sm-6'>
              <ContactInvestorEditableSection contactId={contactId} />
            </div>
            <div className='col-sm-6'>
              <BoardMemberInfo
                clearTransaction={clearTransaction}
                contact={contact}
                contactActions={contactActions}
                transactions={transactions}
              />
            </div>
          </div>
        </CollapsiblePanel>
      )}
      {!isCandidateUser && !isCrmUser ? (
        <CollapsiblePanel
          className='ContactPage__searches'
          key='searches'
          title='Searches'
        >
          <div className='contact-table-add row'>
            <div className='col-12'>
              <AddToSearchForm contact={contact} searchType={TYPE_JOB_SEARCH} />
            </div>

            <div className='col-12'>
              <ContactJobSearchCandidacyListTableView contactId={contactId} />
            </div>
          </div>
        </CollapsiblePanel>
      ) : null}

      {!isCandidateUser && talentPoolsUser ? (
        <CollapsiblePanel
          className='ContactPage__talentPools'
          key='talentpools'
          title='Talent Pools'
        >
          <div className='contact-table-add row'>
            <div className='col-12'>
              <AddToSearchForm
                contact={contact}
                searchType={TYPE_TALENT_POOL}
              />
            </div>

            <div className='col-12'>
              <ContactTalentPoolCandidacyListTableView contactId={contactId} />
            </div>
          </div>
        </CollapsiblePanel>
      ) : null}

      {isCandidateUser ? null : (
        <CollapsiblePanel key='network' title='Networks'>
          <ContactNetworksPanel contactId={contactId} />
        </CollapsiblePanel>
      )}

      <CollapsiblePanel
        className='ContactPage__documents'
        key='documents'
        title='Documents'
      >
        <DocumentTableView
          defaultLabel={isCandidateUser ? 'Resume' : null}
          ownerId={contactId}
          ownerType='Contact'
        />
      </CollapsiblePanel>
      {isCandidateUser ? (
        <CollapsiblePanel
          className='ContactPage__dataPreferences'
          key='data_privacy'
          title='Data Privacy Preferences'
        >
          <div className='EditableSection EditableSection--has-content'>
            <h4 className='EditableSection__header'>
              <span className='EditableSection__title'>
                Information Sharing (GDPR)
              </span>
              <button
                className='EditableSection__toggle btn-link'
                type='button'
              >
                <a href={routes.contact_consent({ contact_id: contactId })}>
                  <i className='fa fa-pencil' />
                </a>
              </button>
            </h4>
            <div className='EditableSection__content'>{gdprStatus}</div>
          </div>

          {hasCaslEnabled ? (
            <div className='row'>
              <div className='col-sm-12 ContactPage__caslStatusSection'>
                <ContactCaslStatusEditableSection
                  contactId={contactId}
                  statusOptions={CANDIDATE_USER_CASL_STATUS_OPTIONS}
                  title='Email Consent (CASL)'
                />
              </div>
            </div>
          ) : null}
        </CollapsiblePanel>
      ) : null}
    </div>
  );
}

ContactPage.propTypes = {
  clearTransaction: PropTypes.func.isRequired,

  companies: ImmutablePropTypes.map.isRequired, // eslint-disable-line react/forbid-prop-types

  companyActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }).isRequired,

  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.map,
    meta: ImmutablePropTypes.map,
  }),

  contactActions: PropTypes.shape({
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    fetchContact: PropTypes.func,
    queryContacts: PropTypes.func.isRequired,
  }).isRequired,

  contactId: PropTypes.number.isRequired,

  contactReviewActions: PropTypes.shape({
    createContactReview: PropTypes.func.isRequired,
  }),

  contacts: ImmutablePropTypes.map.isRequired, // eslint-disable-line react/forbid-prop-types

  duplicateDetectionId: PropTypes.string.isRequired,

  /**
   * Object containing dates contact fields were last updated.
   */
  fieldGroupsLastUpdated: PropTypes.objectOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      user_email: PropTypes.string,
    }),
  ),

  /**
   * True to display affiliations section on contact page
   */
  hasAffiliations: PropTypes.bool,

  /**
   * True to display called to the bar field on contact page
   */
  hasCalledToTheBar: PropTypes.bool,

  /**
   * True to display CASL email status on contact page
   */
  hasCaslEnabled: PropTypes.bool,

  /**
   * True to display citizenship section on contact page
   */
  hasCitizenship: PropTypes.bool,

  /**
   * True to display new Connections feature button on contact page
   */
  hasConnectionsFeature: PropTypes.bool,

  /**
   * True to display diversity section on contact page
   */
  hasDiversity: PropTypes.bool,

  /**
   * True to display diversity section on contact page
   */
  hasDiversityBirthYear: PropTypes.bool,

  /**
   * True to display and allow editing of the `target_option_proceeds` value of the compensation
   * record.
   */
  hasTargetOptionProceedsFields: PropTypes.bool,

  /**
   * True to display US Visa Required section on contact page
   */
  hasUsVisaRequired: PropTypes.bool,

  introductionActions: PropTypes.shape({
    createIntroduction: PropTypes.func.isRequired,
    updateIntroduction: PropTypes.func.isRequired,
  }),

  tenant: ImmutablePropTypes.mapContains({
    /**
     * Whether or not the meetings feature has been enabled for this tenant.
     */
    has_meetings_enabled: PropTypes.bool,

    tenant_type: PropTypes.string.isRequired,
  }),

  transactions: transactionsState.isRequired,

  user: ImmutablePropTypes.mapContains({
    rocketreach: PropTypes.bool,
    is_candidate: PropTypes.bool,
  }).isRequired,
};

export default compose(
  withConnection,
  withContactLoaded,
  withPropsOnChange(
    () => false,
    () => ({
      duplicateDetectionId: uniqueId(),
    }),
  ),
  withFeatureCheck(
    'field.contact.target_option_proceeds',
    'hasTargetOptionProceedsFields',
  ),
  withFeatureCheck('feature.casl', 'hasCaslEnabled'),
  withFeatureCheck('feature.connections_gate', 'hasConnectionsFeature'),
  withFeatureCheck('feature.diversity', 'hasDiversity'),
  withFeatureCheck('feature.diversity.birth_year', 'hasDiversityBirthYear'),
  withFeatureCheck('field.contact.affiliations', 'hasAffiliations'),
  withFeatureCheck('field.contact.called_to_the_bar', 'hasCalledToTheBar'),
  withFeatureCheck('field.contact.us_visa_required', 'hasUsVisaRequired'),
  withFeatureCheck('feature.citizenship', 'hasCitizenship'),
  withProps(({ contact }) => ({
    contactId: contact.getIn(['data', 'id']),
  })),
  mapContactIdToFieldGroupsLastUpdated,
)(ContactPage);

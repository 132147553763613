/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
// ^ Accommodate legacy code. This should be cleaned up.
import React from 'react';

/**
 * Provides triggering the show state of an overlay based on the mouse click/hover/focus
 * @param {Array<String>} triggers An array of triggers that will cause `showOverlay` to be set.
 *   Can be any combination of: 'click', 'hover', 'focus'
 *
 * Provides two props:
 * * `showOverlay` {Boolean} value indicating whether or not the overlay should be shown (usually
 *   passed as the `show` prop to an <Overlay /> component),
 * * `overlayTriggerProps` {Object} Props that should be passed down to a component that will
 *   trigger the overlay. This component needs to provide callbacks for the various trigger
 *   handlers, depending on what `triggers` is set to:
 *    * `onClick` if triggers includes 'click';
 *    * `onMouseOver` and `onMouseOut` if triggers includes 'hover';
 *    * `onFocus` and `onBlur` if triggers includes 'focus';
 *   Any standard DOM component works best (div, button, a, etc)
 *
 * @example
 * ```js
 *
 * const ComponentWithAnOverlay = ({ id, showOverlay, overlayTriggerProps }) => (
 *   <div id={id}>
 *     <button {...overlayTriggerProps} type='button'>I'm the trigger!</button>
 *     <Overlay
 *       container={getScrollContainer}
 *       placement='top'
 *       show={showOverlay}
 *       target={this}
 *     >
 *       <Popover id={`${id}-popover`}>
 *         I get shown when the button is hovered, focused, or clicked!
 *       </Popover>
 *     </Overlay>
 *   </div>
 * );
 *
 * const TriggeredComponent =
 *    withOverlayTrigger({ triggers: ['focus', 'hover', 'click'] })(ComponentWithAnOverlay);
 * ```
 */
export default ({
  triggers = ['hover', 'focus'],
  showOverlayPropName = 'showOverlay',
  setShowOverlayPropName = 'setShowOverlay',
  overlayTriggerPropsPropName = 'overlayTriggerProps',
} = {}) => BaseComponent =>
  class WithOverlayTrigger extends React.Component {
    state = {
      showOverlay: false,
    };

    onBlur = e => {
      const { onBlur } = this.props;
      if (onBlur) {
        onBlur(e);
      }

      if (triggers.includes('focus')) {
        this.setState({ showOverlay: false });
      }
    };

    onFocus = e => {
      const { onFocus } = this.props;
      if (onFocus) {
        onFocus(e);
      }

      if (triggers.includes('focus')) {
        this.setState({ showOverlay: true });
      }
    };

    onClick = e => {
      const { onClick } = this.props;
      if (onClick) {
        onClick(e);
      }

      if (triggers.includes('click')) {
        this.setState(({ showOverlay }) => ({ showOverlay: !showOverlay }));
      }
    };

    onMouseOut = e => {
      const { onMouseOut } = this.props;
      if (onMouseOut) {
        onMouseOut(e);
      }

      if (triggers.includes('hover')) {
        this.setState({ showOverlay: false });
      }
    };

    onMouseOver = e => {
      const { onMouseOver } = this.props;
      if (onMouseOver) {
        onMouseOver(e);
      }

      if (triggers.includes('hover')) {
        this.setState({ showOverlay: true });
      }
    };

    setShowOverlay = showOverlay => {
      this.setState({ showOverlay: showOverlay });
    };

    render() {
      const { showOverlay } = this.state;
      const withOverlayTriggerProps = {
        [showOverlayPropName]: showOverlay,
        [setShowOverlayPropName]: this.setShowOverlay,
        [overlayTriggerPropsPropName]: {
          onBlur: this.onBlur,
          onFocus: this.onFocus,
          onClick: this.onClick,
          onMouseOut: this.onMouseOut,
          onMouseOver: this.onMouseOver,
        },
      };

      return <BaseComponent {...this.props} {...withOverlayTriggerProps} />;
    }
  };

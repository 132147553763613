import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import connectNoteActions from 'modules/notes/components/connectNoteActions';
import NoteView from 'modules/notes/components/NoteView';
import NoteForm from 'modules/notes/components/NoteForm';
import transactionsState from 'modules/transactions/propTypes/transactionsState';

import Notification from './Notification';

/**
 * Renders a notification which has a resource of type `note`
 */
class NotificationItemTypeNote extends Component {
  state = {
    isEditing: false,
  };

  handleEdit = () => {
    this.setState({ isEditing: true });
  };

  handleDelete = () => {
    const { noteActions, notification } = this.props;
    noteActions.deleteNote({
      id: notification.getIn(['data', 'resource', 'data', 'id']),
    });
  };

  handleCancel = () => {
    this.setState({ isEditing: false });
  };

  handleSaved = () => {
    this.setState({ isEditing: false });
  };

  render() {
    const {
      allowDelete,
      allowEdit,
      excludeSearchField,
      notification,
      ...props
    } = this.props;
    const note = notification.getIn(['data', 'resource']);
    const noteId = note.getIn(['data', 'id']);
    const recordType = note.getIn(['data', 'notable_type']).toLowerCase();
    const recordId = note.getIn(['data', 'notable_id']);
    const { isEditing } = this.state;

    return (
      <Notification
        canDelete={allowDelete && note.getIn(['data', 'permissions', 'delete'])}
        canEdit={allowEdit && note.getIn(['data', 'permissions', 'edit'])}
        notification={notification}
        onDelete={this.handleDelete}
        onEdit={this.handleEdit}
        {...props}
      >
        {props.showDetails && !isEditing && (
          <NoteView noteId={noteId} renderDate={false} />
        )}
        {isEditing && (
          <NoteForm
            asRichTextForm={true}
            draftStoragePath={{
              [recordType]: recordId,
              note: noteId,
            }}
            errorDisplay='tooltip'
            excludeSearchField={excludeSearchField}
            formActionProps={{
              submitButtonIcon: 'fa-edit',
              submitLabel: 'Update Note',
              fillWidth: false,
            }}
            noteId={noteId}
            onCancel={this.handleCancel}
            onSaved={this.handleSaved}
          />
        )}
      </Notification>
    );
  }
}

NotificationItemTypeNote.propTypes = {
  /**
   * True to allow the note item to be deleted if the user is permitted.
   */
  allowDelete: PropTypes.bool,

  /**
   * True to allow the note item to be edited inline if the user is permitted.
   */
  allowEdit: PropTypes.bool,

  clearTransaction: requiredIf(PropTypes.func, props => props.allowEdit),

  excludeSearchField: PropTypes.bool,

  noteActions: PropTypes.shape({
    deleteNote: PropTypes.func,
  }),

  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains(NoteView.propTypes.note),
      }),
      type: PropTypes.oneOf(['note']),
    }),
  }).isRequired,

  /**
   * When true, the note details will be displayed.
   * @type {[type]}
   */
  showDetails: PropTypes.bool,

  transactions: requiredIf(transactionsState, props => props.allowEdit),
};

export default connectNoteActions(NotificationItemTypeNote);

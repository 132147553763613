import getRatingProperty from './getRatingProperty';

/**
 * Determines if a particular rating was made by a particular user
 * (and not attributed to a contact)
 * @param {*} state
 * @param {Number} ratingId The ID of the rating record to check
 * @param {Number} userId The ID of the user to check
 * @returns {Boolean} True if the rating record with the given ID was made by the specific
 *   user (and not attributed to another contact)
 */
export default (state, ratingId, userId) => {
  if (!userId) {
    return false;
  }

  if (
    getRatingProperty(state, ratingId, 'user') === userId &&
    !getRatingProperty(state, ratingId, 'attributed_to')
  ) {
    return true;
  }

  return false;
};

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { Component } from 'react';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';

class ContactDuplicate extends Component {
  handleAddButtonClick = () => {
    const { duplicate, handleDuplicateSubmit, network, search } = this.props;
    const contactId = duplicate.get('id');
    const networkId = network && network.id;
    const searchId = search && search.id;
    if (handleDuplicateSubmit) {
      handleDuplicateSubmit({
        contactId: contactId,
        networkId: networkId,
        searchId: searchId,
      });
    }
  };

  handleUpdate = () => {
    const { duplicate, onUpdate } = this.props;
    const contactId = duplicate.get('id');
    onUpdate(contactId);
  };

  render() {
    const {
      duplicate,
      hideProfileLink,
      network,
      showAddButton,
      showUpdateButton,
    } = this.props;

    return (
      <div className='contact-duplicate'>
        <div className='row'>
          <div
            className={
              showAddButton || showUpdateButton ? 'col-sm-9' : 'col-sm-12'
            }
          >
            <div className='dup-content'>
              <div>
                <strong>{duplicate.getIn(['data', 'name'])}</strong>
              </div>
              <div>{duplicate.get('title')}</div>
              <div>{duplicate.get('company')}</div>
              <div>
                {!hideProfileLink && (
                  <a
                    href={duplicate.getIn(['links', 'show'])}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Review Profile
                  </a>
                )}
              </div>
            </div>
          </div>
          {showAddButton && (
            <div className='col-sm-3'>
              <div className='pull-right'>
                <button
                  className='btn btn-sm btn-primary'
                  onClick={this.handleAddButtonClick}
                  type='button'
                >
                  Add to {network ? 'Network' : 'Search'}
                </button>
              </div>
            </div>
          )}
          {showUpdateButton && (
            <div className='col-sm-3'>
              <div className='pull-right'>
                <button
                  className='btn btn-sm btn-primary'
                  onClick={this.handleUpdate}
                  type='button'
                >
                  Review and Update
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ContactDuplicate.propTypes = {
  duplicate: ImmutablePropTypes.mapContains({
    company: PropTypes.string,
    links: PropTypes.shape({
      show: PropTypes.string,
    }),
    name: PropTypes.string,
    title: PropTypes.string,
  }),

  handleDuplicateSubmit: PropTypes.func,

  hideProfileLink: PropTypes.bool,

  network: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),

  onUpdate: requiredIf(PropTypes.func, props =>
    Boolean(props.showUpdateButton),
  ),

  search: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),

  showAddButton: PropTypes.bool,

  showUpdateButton: PropTypes.bool,
};

export default ContactDuplicate;

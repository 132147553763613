import PropTypes from 'prop-types';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import mapSearchIdToSearchProperty from 'modules/searches/components/mapSearchIdToSearchProperty';
import JobFunctionName from 'modules/tenant/components/JobFunctionName';
import mapDatumToCandidacyId from './mapDatumToCandidacyId';
import mapCandidacyIdToSearchId from '../mapCandidacyIdToSearchId';

/**
 * A candidacy table cell that renders the job function name for the search that the candidacy
 * record is associated with.
 */
export default compose(
  setDisplayName('CandidacySearchJobFunctionNameCell'),
  setPropTypes({
    /**
     * The candidacy ID
     */
    datum: PropTypes.number.isRequired,
  }),
  mapDatumToCandidacyId,
  mapCandidacyIdToSearchId,
  mapSearchIdToSearchProperty('job_function', 'jobFunctionId'),
  branch(({ jobFunctionId }) => !jobFunctionId, renderNothing),
)(JobFunctionName);

import React from 'react';
import PropTypes from 'prop-types';
import ExpandableActivityView from './ExpandableActivityView';

const NoteActivityView = ({ content, subject }) => (
  <div className='u-marginTop-4 u-fontSize-small'>
    {subject ? (
      <p className='u-marginBottom-n'>
        <strong>Subject:</strong> {subject}
      </p>
    ) : null}
    <ExpandableActivityView content={content} />
  </div>
);

NoteActivityView.propTypes = {
  content: PropTypes.string,
  subject: PropTypes.string,
};

export default NoteActivityView;

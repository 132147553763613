import PropTypes from 'prop-types';
import React from 'react';
import {
  branch,
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import isAdmin from 'modules/auth/selectors/isAdmin';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import Modal from 'modules/core/componentsLegacy/Modal';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import CancelButton from 'modules/forms/components/CancelButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import fetchStageListIfNeeded from 'modules/search-stages/components/fetchStageListIfNeeded';
import mapStageTypeToStageList from 'modules/search-stages/components/mapStageTypeToStageList';
import mapStageIdsToStages from 'modules/search-stages/components/mapStageIdsToStages';
import {
  STAGE_TYPE_TALENT_POOL,
  STAGE_TYPE_JOB_SEARCH,
} from 'modules/search-stages/constants';
import {
  SEARCH_TYPES,
  SEARCH_TYPE_LABELS,
  TYPE_TALENT_POOL,
} from 'modules/searches/constants';
import CandidaciesCreateModalField from './CandidaciesCreateModalField';
import withCandidaciesCreateForm from './withCandidaciesCreateForm';

/**
 * Renders a modal with a form for selecting a search to add a contact or list of contacts to.
 */
const CandidaciesCreateModalForm = ({
  contactIds,
  // props provided by `withCandidaciesCreateForm`
  formState,
  isLoading,
  message,

  // Props passed through/expected from parent component.
  onClose,
  onFieldStateChange,
  onSubmit,
  show,
  submitButtonLabel,
  title,

  // Any other props are passed through to the CandidaciesCreateModalField component.
  ...fieldProps
}) => (
  <Modal className='CandidaciesCreateModalForm' onHide={onClose} show={show}>
    <Modal.Header closeButton={true}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    {isLoading ? (
      <LoadingIndicator />
    ) : (
      <Form formState={formState} onSubmit={onSubmit}>
        <Modal.Body>
          {message && (
            <div className='CandidaciesCreateModalForm__Message'>{message}</div>
          )}
          <CandidaciesCreateModalField
            {...fieldProps}
            contactIds={contactIds}
            disabled={formState.isSubmitting()}
            fieldState={formState.getFieldState()}
            onChange={onFieldStateChange}
            showErrors={formState.wasSubmitted() || 'blurred'}
          />
          <FormErrorMessage formState={formState} />
        </Modal.Body>
        <Modal.Footer>
          <CancelButton bsSize='lg' onClick={onClose} />
          <SubmitButton bsSize='lg' formState={formState} onClick={onSubmit}>
            {submitButtonLabel}
          </SubmitButton>
        </Modal.Footer>
      </Form>
    )}
  </Modal>
);

CandidaciesCreateModalForm.propTypes = {
  contactIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  formState: formStatePropType,
  hasRecommendationsFeature: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onFieldStateChange: PropTypes.func,
  onSubmit: PropTypes.func,
  searchType: PropTypes.oneOf(SEARCH_TYPES).isRequired,
  show: PropTypes.bool,
  submitButtonLabel: PropTypes.node,
  title: PropTypes.node.isRequired,
};

CandidaciesCreateModalForm.defaultProps = {
  message: null,
  show: false,
  submitButtonLabel: 'Add',
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('CandidaciesCreateModalForm(enhanced)'),
  setPropTypes({
    /**
     * The IDs of the contacts who should be added to the selected search.
     */
    contactIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    /**
     * Optional content to render in the modal above the form
     */
    message: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  }),

  /**
   * Search/stage type forking to accommodate "searches" vs "talent pools"
   */
  withProps(({ searchType }) => ({
    searchTypeLabel: SEARCH_TYPE_LABELS[searchType],
    stageType:
      searchType === TYPE_TALENT_POOL
        ? STAGE_TYPE_TALENT_POOL
        : STAGE_TYPE_JOB_SEARCH,
  })),
  /**
   * Modal Title
   */
  withProps(({ contactIds, searchTypeLabel }) => ({
    title:
      contactIds.length > 1
        ? `Add ${contactIds.length} Contacts to a ${searchTypeLabel}`
        : `Add Contact to a ${searchTypeLabel}`,
  })),
  /**
   * Stages
   */
  fetchStageListIfNeeded,
  mapStageTypeToStageList,
  withProps(({ stageList }) => ({
    stageIds: stageList && stageList.get('ids'),
  })),
  mapStageIdsToStages,
  withProps(({ stages }) => {
    // Find the tenant's 'initial' stage so we can set it the as the default option in the dropdown.
    const defaultStage = stages
      ? stages.find(stage => stage.get('initial'))
      : null;
    return {
      defaultStageId: defaultStage ? defaultStage.get('id') : null,
      searchStageOptions:
        stages &&
        stages
          .filter(
            stage =>
              stage.get('user_assignable') && stage.get('allow_as_initial'),
          )
          .sortBy(stage => stage.get('position'))
          .toJS(),
    };
  }),
  /**
   * Candidicies Create Form
   */
  withProps(({ onClose }) => ({
    // Map the form handlers to our modal close handler
    onSaved: onClose,
    onCancel: onClose,
  })),
  withFeatureCheck(
    'feature.candidacy_visibility.default_stage_invisible',
    'isCandidacyHidden',
  ),
  withFeatureCheck(
    'feature.candidacy_visibility',
    'hasCandidacyVisibilityFeature',
  ),
  withFeatureCheck('feature.recommend_candidates', 'hasRecommendationsFeature'),
  connect((state, { hasRecommendationsFeature }) => {
    const isAdminUser = isAdmin(state);
    return {
      shouldShowRecommend: isAdminUser && hasRecommendationsFeature,
    };
  }),
  withProps(({ stages }) => ({
    isLoading: !stages,
  })),
  branch(
    // Wait until data has been fetched before trying to create field state with it
    ({ isLoading }) => !isLoading,
    withCandidaciesCreateForm(({ defaultStageId, isCandidacyHidden }) =>
      CandidaciesCreateModalField.createFieldState(
        'candidaciesCreateModalField',
        {
          stageId: defaultStageId,
          isCandidacyHidden: isCandidacyHidden,
        },
      ),
    ),
  ),
  /**
   * Prevent props from being passed on to the modal
   */
  /* eslint-disable no-unused-vars */
  mapProps(
    ({
      defaultStageId,
      onCancel,
      onFormStateChange,
      onResetFormState,
      onSaved,
      stages,
      ...rest
    }) => ({ ...rest }),
  ),
  /* eslint-enable no-unused-vars */
)(CandidaciesCreateModalForm);

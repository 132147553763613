import React from 'react';
import PropTypes from 'prop-types';
import SidebarModal from 'modules/core/componentsLegacy/SidebarModal';
import Modal from 'modules/core/componentsLegacy/Modal';
import CandidacyAssessmentList from './CandidacyAssessmentList';

/**
 * A modal that displays the list of candidacy assessments that belong to a particular contact.
 */
const CandidacyAssessmentModal = ({ contactId, ...props }) => (
  <SidebarModal {...props} className='CandidacyAssessmentModal'>
    <Modal.Header closeButton={true}>
      <Modal.Title>Search Related Assessments</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h3 className='CandidacyAssessmentModal_header'>
        Overall Search Assessment
      </h3>
      <CandidacyAssessmentList contactId={contactId} readOnly={false} />
    </Modal.Body>
  </SidebarModal>
);

CandidacyAssessmentModal.propTypes = {
  // The id of the contact whose assessement should be shown.
  contactId: PropTypes.number.isRequired,
};

export default CandidacyAssessmentModal;

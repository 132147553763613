import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { branch, compose, setDisplayName, setStatic } from 'recompose';
import { Creatable } from 'react-select-legacy';
import KeyCode from 'keycode-js';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import * as validators from 'modules/forms/validators';

class DomainField extends Component {
  static createFieldState = (name, value, validator, ...rest) =>
    FieldState.create(
      name,
      value ? [].concat(value) : [],
      validator
        ? validators.combineValidators(validator, validators.domainName)
        : validators.domainName,
      ...rest,
    );

  handleChange = value => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setValue(value.map(item => item.label)));
  };

  handleBlur = event => {
    const { fieldState, onChange } = this.props;
    if (event.target.value) {
      onChange(
        fieldState.setValue(fieldState.getValue().concat(event.target.value)),
      );
    }
  };

  shouldKeyDownEventCreateNewOption = ({ keyCode }) =>
    [
      KeyCode.KEY_SPACE,
      KeyCode.KEY_ENTER,
      KeyCode.KEY_TAB,
      KeyCode.KEY_COMMA,
    ].indexOf(keyCode) >= 0;

  render() {
    const {
      fieldState,
      id,
      inputProps,
      // eslint-disable-next-line no-unused-vars
      onChange,
      ...otherProps
    } = this.props;

    return (
      <div className={classnames('react-select')}>
        <Creatable
          {...otherProps}
          inputProps={{
            ...inputProps,
            id: id,
          }}
          menuRenderer={() => null}
          multi={true}
          name={fieldState.getName()}
          noResultsText={false}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          options={[]}
          placeholder='Enter domains ...'
          promptTextCreator={label => label}
          shouldKeyDownEventCreateNewOption={
            this.shouldKeyDownEventCreateNewOption
          }
          value={fieldState
            .getValue()
            .map(domain => ({ label: domain, value: domain }))}
        />
      </div>
    );
  }
}

DomainField.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  id: PropTypes.string.isRequired,

  inputProps: PropTypes.shape({
    id: PropTypes.string,
  }),

  onChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('DomainField'),
  setStatic('createFieldState', DomainField.createFieldState),
  withFormGroup,

  branch(({ id }) => !id, withComponentId('DomainField', 'id')),
)(DomainField);

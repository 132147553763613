import React from 'react';
import { useSelector } from 'react-redux';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useQuickView from 'modules/quick-view/useQuickView';
import ErrorBoundary from 'modules/core/components/ErrorBoundary';
import FilterInput from './FilterInput';
import PersistentFilterInputs from './PersistentFilterInputs';

const SelectableFilterInputs = () => {
  const { availableFilterInputs, availableFilterSets } = useSelector(
    state => state.recordIndex,
  );

  const { navigateTo } = useQuickView();

  const selectedFilterSetIds = useSelector(
    state => state.recordIndex.savedView?.filters?.selectedFilterSetIds || [],
  );

  const { filterSetErrors } = useSelector(state => state.recordIndex);

  return (
    <div>
      <h2 className='u-margin-n'>Keyword Search</h2>
      <PersistentFilterInputs />
      <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c u-marginBottom-8'>
        <h2 className='u-margin-n'>Filters</h2>
        <ButtonSecondary
          label='Manage Filters'
          onClick={() => navigateTo('/filter_sets')}
          size='small'
        />
      </div>

      {selectedFilterSetIds.map(filterSetId => (
        <div className='u-marginBottom-8' key={filterSetId}>
          {/* The below filters are configuration driven.  To add/remove or update filters see filter_set_helper.rb.
             We expect that the filter sets will be updated over time.  To prevent the entire page from crashing
             when a single filter set is broken, we wrap each filter set in an ErrorBoundary.  This greatly simplifies
             identifying and resolving issues with filter sets.
          */}
          <ErrorBoundary>
            {availableFilterSets?.[filterSetId]?.label ? (
              <h3 className='u-margin-n'>
                {availableFilterSets[filterSetId].label}
              </h3>
            ) : null}
            {availableFilterSets?.[filterSetId]?.filterInputs.map(
              filterName => (
                <FilterInput
                  key={filterName}
                  label={availableFilterInputs[filterName].label}
                  name={availableFilterInputs[filterName].name}
                  type={availableFilterInputs[filterName].type}
                />
              ),
            )}
            {filterSetErrors[filterSetId] ? (
              <div className='u-marginVertical-4 u-textColor-red'>
                {filterSetErrors[filterSetId]}
              </div>
            ) : null}
          </ErrorBoundary>
        </div>
      ))}
    </div>
  );
};

export default SelectableFilterInputs;

import PropTypes from 'prop-types';
import React from 'react';
import { compose, onlyUpdateForPropTypes } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import RelativeTimeLabel from 'modules/datetime/components/RelativeTimeLabel';
import UserName from 'modules/users/components/UserName';

import mapTargetCompanyCommentIdToTargetCompanyComment from './mapTargetCompanyCommentIdToTargetCompanyComment';

const TargetCompanyComment = ({ targetCompanyComment }) => (
  <div className='target-companies--target-company-comment'>
    {targetCompanyComment.get('user') && (
      <strong>
        <UserName userId={targetCompanyComment.get('user')} />
      </strong>
    )}
    <div className='u-wordBreak'>{targetCompanyComment.get('comment')}</div>
    <em>
      <RelativeTimeLabel time={targetCompanyComment.get('updated_at')} />
    </em>
  </div>
);

TargetCompanyComment.propTypes = {
  targetCompanyComment: ImmutablePropTypes.mapContains({
    comment: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    user: PropTypes.number,
  }).isRequired,
};

export default compose(
  mapTargetCompanyCommentIdToTargetCompanyComment,
  onlyUpdateForPropTypes,
)(TargetCompanyComment);

import getEntity from 'modules/entities/selectors/getEntity';
import introductionSchema from '../schema';

/**
 * Gets a introduction record by it's ID
 * @param {Object} state
 * @param {Number} introductionId The introduction ID
 * @return {Immutable.Map|undefined} The introduction record, if found
 */
export default (state, introductionId) =>
  getEntity(state, introductionSchema.key, introductionId);

import shouldFetchEntity from 'modules/entities/selectors/shouldFetchEntity';
import tagSchema from '../schemas/tag';

/**
 * Determines if a tag should be fetched from the server.
 * @param {Object} state
 * @param {Number} contactId The ID of the tag
 * @return {Boolean} True if the tag needs to be fetched from the server; otherwise,
 *   false
 */
export default (state, tagId) => shouldFetchEntity(state, tagSchema.key, tagId);

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  defaultProps,
  setPropTypes,
  withProps,
} from 'recompose';
import withAddressIdsSortedByPrimary from './withAddressIdsSortedByPrimary';
import getAddressProperty from '../selectors/getAddressProperty';

import { SORT_BY_COUNTRY } from '../constants';

/**
 * A list of properties which can be sorted based on the result of calling
 * `getAddressProperty`.
 */
const PROPERTY_SORTS = [SORT_BY_COUNTRY];

/**
 * A higher order component that provides sorting of a list of address IDs provided by an
 * `addressIds` prop, based on the prop values of `sortBy` and `sortAscending`.
 */
export default compose(
  defaultProps({
    sortAscending: true,
    sortBy: SORT_BY_COUNTRY,
  }),
  setPropTypes({
    addressIds: ImmutablePropTypes.listOf(PropTypes.number),
    primaryAddressId: PropTypes.number,
    sortAscending: PropTypes.bool.isRequired,
    sortBy: PropTypes.oneOf([SORT_BY_COUNTRY]).isRequired,
  }),

  // Sorts that are in PROPERTY_SORTS can be handled generically by calling
  // `getAddressProperty`
  branch(
    ({ sortBy }) => PROPERTY_SORTS.includes(sortBy),
    connect(
      (state, { addressIds, sortBy }) => ({
        addressIds:
          addressIds &&
          addressIds.sortBy(id =>
            (getAddressProperty(state, id, sortBy) || '').toLowerCase(),
          ),
      }),
      {},
    ),
  ),

  withAddressIdsSortedByPrimary,
  // Reverse the sort if needed based on the `sortAscending` prop value.
  withProps(({ addressIds, sortAscending }) => ({
    addressIds:
      addressIds && !sortAscending ? addressIds.reverse() : addressIds,
  })),
);

import { NAME, DEFAULT_CLIENT_TYPE_NAME } from '../constants';

/**
 * Gets the current tenant's "client name" -- usually either "Hiring Manager" or
 * simply "Client"
 * @param {Object} state
 * @return {String} The name of the current recruiter.
 */
export const getClientTypeName = state =>
  state[NAME].get('client_or_hiring_manager', DEFAULT_CLIENT_TYPE_NAME);

export default getClientTypeName;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, withHandlers, setDisplayName, setPropTypes } from 'recompose';

import isLimitedAccessRole from 'modules/auth/selectors/isLimitedAccessRole';

import ContactLink from './ContactLink';
import mapContactIdToContact from './mapContactIdToContact';

/**
 * Renders a single matching contact duplicate.
 */
export const ContactDuplicate = ({
  canSelect,
  contact,
  onSelect,
  renderProfileLink,
}) => (
  <div className='ContactDuplicate'>
    <strong>{contact.get('name') || contact.get('full_name')}</strong>
    <div>{contact.get('primary_title')}</div>
    <div>{contact.get('primary_company_name')}</div>
    {renderProfileLink && (
      <ContactLink
        className='ContactDuplicate__profile-link'
        contactId={contact.get('id')}
        target='_blank'
        useDefaultLinkBehavior={true}
      >
        Review Profile
      </ContactLink>
    )}
    {canSelect && (
      <button
        className='ContactDuplicate__select-btn btn btn-link'
        onClick={onSelect}
        type='button'
      >
        Use this contact
      </button>
    )}
  </div>
);

ContactDuplicate.propTypes = {
  /**
   * Whether the use should be able to click a "Use this contact" button.
   */
  canSelect: PropTypes.bool,

  /**
   * The contact record
   */
  contact: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    full_name: PropTypes.string,
    primary_title: PropTypes.string,
    primary_company_name: PropTypes.string,
  }),

  /**
   * Called when the user clicks the "Use this contact" button (if `canSelect` is true)
   */
  onSelect: PropTypes.func,

  /**
   * False to not render a link to the contact profile.
   */
  renderProfileLink: PropTypes.bool,
};

ContactDuplicate.defaultProps = {
  canSelect: false,
  renderProfileLink: true,
};

export default compose(
  setDisplayName('ContactDuplicate(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
    onSelect: PropTypes.func,
  }),
  mapContactIdToContact,
  withHandlers({
    // Send the contactId with the onSelect callback.
    onSelect: ({ contactId, onSelect }) => () =>
      onSelect && onSelect(contactId),
  }),
  connect(
    state => ({
      renderProfileLink: !isLimitedAccessRole(state),
    }),
    {},
  ),
)(ContactDuplicate);

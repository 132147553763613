import PropTypes from 'prop-types';
import React from 'react';
import groupBy from 'lodash/groupBy';
import Menu from '@thrivetrm/ui/components/Menu';
import documentShape from 'modules/documents/documentShape';

const CandidacyExtraDocumentsMenu = ({ documents, isPinnedRight }) => {
  // Transform a single array of documents into arrays of documents keyed by label
  // so that we can group them into sections with headers in the menu.
  // {
  //   Resume: [{ id: 1, ...}, { id: 2, ...}]}
  //   Other: [{ id: 7, ...}]
  // }
  const documentsByLabel = groupBy(
    documents,
    document => document.document_label.name,
  );
  // changed URL into a protocol-relative to the URLs HTTPS vs HTTP
  return (
    <Menu className='CandidacyExtraDocumentsMenu' isPinnedRight={isPinnedRight}>
      {Object.entries(documentsByLabel).map(
        ([documentLabel, documentItems]) => (
          <React.Fragment key={documentLabel}>
            <Menu.Heading>{documentLabel}</Menu.Heading>
            {documentItems.map(document => (
              <Menu.Item key={document.id} onClick={() => null}>
                <a
                  href={`${document.url?.replace(/^https?:/i, '')}`}
                  rel='noreferrer'
                  target='_blank'
                >
                  {document.file_name}
                </a>
              </Menu.Item>
            ))}
          </React.Fragment>
        ),
      )}
    </Menu>
  );
};

CandidacyExtraDocumentsMenu.defaultProps = {
  isPinnedRight: false,
};

CandidacyExtraDocumentsMenu.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape(documentShape)).isRequired,
  isPinnedRight: PropTypes.bool,
};

export default CandidacyExtraDocumentsMenu;

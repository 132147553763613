import { connect } from 'react-redux';
import getActivity from '../selectors/getActivity';

/**
 * A higher order component which maps a `activityId` prop value to it's activity record as
 * a `activity` prop.`
 */
export default connect(
  (state, { activityId }) => ({
    activity: getActivity(state, activityId),
  }),
  {},
);

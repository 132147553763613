import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';
import CurrencyTypeSelectField from 'modules/currency/components/CurrencyTypeSelectField';
import CurrencyInputField from 'modules/currency/components/CurrencyInputField';
import * as validators from 'modules/forms/validators';
import { DEFAULT_CURRENCY } from 'modules/user/constants';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import { Map } from 'immutable';

class ContactCurrentInterimCompensationField extends PureComponent {
  static createFieldState = (
    name = 'contact-current-interim-compensation',
    interimCompensation,
  ) => {
    const values = new Map({
      currency: null,
      day_rate: null,
      notes: null,
    }).merge(interimCompensation);

    return FieldState.createNested(name, [
      CurrencyTypeSelectField.createFieldState(
        'currency',
        values.get('currency') || DEFAULT_CURRENCY,
        validators.requiredField('Currency'),
      ),
      CurrencyInputField.createFieldState('day_rate', values.get('day_rate')),
      TextAreaField.createFieldState('notes', values.get('notes')),
    ]);
  };

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const { fieldState } = this.props;
    return (
      <>
        <div className='row'>
          <div className='col-sm-6 ContactInterimCompensationForm__currency'>
            <CurrencyTypeSelectField
              clearable={true}
              fieldState={fieldState.getNestedField('currency')}
              label='Currency'
              name='currency'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className='col-sm-6'>
            <CurrencyInputField
              currency={fieldState.getNestedField('currency').getValue()}
              data-testid='interim-compensation-day-rate-field'
              fieldState={fieldState.getNestedField('day_rate')}
              label='Day Rate'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <TextAreaField
              data-testid='interim-compensation-notes-field'
              fieldState={fieldState.getNestedField('notes')}
              key='notes'
              label='Notes'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
      </>
    );
  }
}

ContactCurrentInterimCompensationField.propTypes = {
  /**
   * The field state containing the nested value.
   */
  fieldState: fieldStatePropType.isRequired,
  /**
   * Called when a nested field is changed.
   */
  onChange: PropTypes.func.isRequired,
};

export default ContactCurrentInterimCompensationField;

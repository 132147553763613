import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import hasLoadedTenantOptions from 'modules/tenant/selectors/hasLoadedTenantOptions';

import FieldState from 'modules/forms/FieldState';
import InputGroupField from 'modules/forms/components/InputGroupField';
import * as validators from 'modules/forms/validators';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import InputField from 'modules/forms/components/InputField';

/**
 * Displays a field for editing an externalId on a Contact.
 * Optionally validates numericality based on 'field.external_id.validate_numericality'
 */
const ContactExternalIdInputField = ({
  fieldState,
  ghireFlipperEnabled,
  onChange,
  showTooltip,
  /* eslint-disable no-unused-vars */
  validateExternalIdNumberOnly,
  /* eslint-enable no-unused-vars */

  ...otherProps
}) => {
  const tooltipContent = ghireFlipperEnabled
    ? 'Enter GHire ID (i.e. 123456)'
    : 'Enter External ID (i.e. 123456)';

  return (
    <div className='ContactExternalIdInputField'>
      <TooltipTrigger
        placement='bottom'
        tooltip={tooltipContent}
        trigger={showTooltip ? ['hover', 'focus'] : []}
      >
        {ghireFlipperEnabled ? (
          <InputGroupField
            {...otherProps}
            fieldState={fieldState}
            key='external_id'
            label='GHire ID'
            onChange={onChange}
            prependComponent={<i className='fa fa-google' />}
          />
        ) : (
          <InputField
            {...otherProps}
            fieldState={fieldState}
            key='external_id'
            label='External ID'
            onChange={onChange}
          />
        )}
      </TooltipTrigger>
    </div>
  );
};

ContactExternalIdInputField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  ghireFlipperEnabled: PropTypes.bool,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  /**
   * controls whether or not this input will appear with a tooltip
   */
  showTooltip: PropTypes.bool,

  /**
   * controls whether or not number validator runs
   */
  validateExternalIdNumberOnly: PropTypes.bool,
};

export default compose(
  setDisplayName('ContactExternalIdInputField(enhanced)'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', InputGroupField.createFieldState),
  connect(
    state => ({
      tenantOptionsLoaded: hasLoadedTenantOptions(state),
    }),
    {},
  ),
  branch(
    ({ tenantOptionsLoaded }) => !tenantOptionsLoaded,
    renderComponent(LoadingIndicator),
  ),
  withFeatureCheck(
    'field.external_id.validate_numericality',
    'validateExternalIdNumberOnly',
  ),
  withFeatureCheck('field.contact.external_id_ghire', 'ghireFlipperEnabled'),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      if (this.props.validateExternalIdNumberOnly) {
        this.props.onChange(
          this.props.fieldState.setValidator(validators.isDigits),
        );
      }
    },
  }),
  withPropsRemoved('tenantOptionsLoaded'),
)(ContactExternalIdInputField);

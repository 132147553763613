import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import Modal from 'modules/core/componentsLegacy/Modal';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import CompanyDetailsFields from './CompanyDetailsFields';
import withCompanyDetailsForm from './withCompanyDetailsForm';

/**
 * A modal that displays the configuration details for a customer
 */

const CompanyDetailsModalForm = ({
  formState,
  hasCustomerConfiguration,
  onFieldStateChange,
  onHide,
  onSubmit,
  show,
  ...fieldProps
}) => (
  <Modal className='CompanyDetailsModalForm form' onHide={onHide} show={show}>
    <Modal.Header closeButton={true}>
      <Modal.Title>Company Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {hasCustomerConfiguration ? (
        <CompanyDetailsFields
          {...fieldProps}
          disabled={formState.isSubmitting()}
          fieldState={formState.getFieldState()}
          onChange={onFieldStateChange}
          showErrors={formState.wasSubmitted() || 'blurred'}
        />
      ) : (
        <LoadingIndicator />
      )}
      <FormErrorMessage formState={formState} />
    </Modal.Body>
    <Modal.Footer>
      <CancelButton bsSize='lg' onClick={onHide} />
      <SubmitButton
        bsSize='lg'
        disabled={!hasCustomerConfiguration}
        formState={formState}
        onClick={onSubmit}
      />
    </Modal.Footer>
  </Modal>
);

CompanyDetailsModalForm.propTypes = {
  formState: formStatePropType.isRequired,
  hasCustomerConfiguration: PropTypes.bool.isRequired,
  onFieldStateChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('CompanyDetailsModalForm(enhanced)'),
  setPropTypes({
    onHide: CompanyDetailsModalForm.propTypes.onHide,
    show: CompanyDetailsModalForm.propTypes.show,
  }),
  withProps(({ onHide }) => ({
    onSaved: onHide,
  })),
  withCompanyDetailsForm(CompanyDetailsFields),
  mapProps(({ onFormStateChange: _, ...props }) => props),
)(CompanyDetailsModalForm);

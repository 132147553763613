import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';

import connectJobApplicationActions from './connectJobApplicationActions';
import mapSearchIdToJobApplicationList from './mapSearchIdToJobApplicationList';

/**
 * A button that will refresh the list of job applications when clicked
 */
export const JobApplicationListRefreshButton = ({ isFetching, ...props }) => (
  <button className='btn btn-link' type='button' {...props}>
    <i
      className={classnames('fa fa-refresh', {
        'fa-spin': isFetching,
      })}
    />
  </button>
);

JobApplicationListRefreshButton.propTypes = {
  /**
   * True when currently fetching the job application list.
   */
  isFetching: PropTypes.bool,
};

JobApplicationListRefreshButton.defaultProps = {
  isFetching: false,
};

export default compose(
  setDisplayName('JobApplicationListRefreshButton(enhanced)'),
  setPropTypes({
    /**
     * The search ID whose job applications will be fetched.
     */
    searchId: PropTypes.number.isRequired,
  }),
  mapSearchIdToJobApplicationList,
  connectJobApplicationActions,
  withHandlers({
    onClick: ({ jobApplicationActions, searchId }) => e => {
      e.preventDefault();
      jobApplicationActions.fetchJobApplicationList({ searchId: searchId });
    },
  }),
  mapProps(
    ({
      disabled,
      jobApplicationActions: _jobApplicationActions,
      jobApplicationList,
      searchId: _searchId,
      ...rest
    }) => ({
      disabled:
        disabled ||
        !jobApplicationList ||
        jobApplicationList.getIn(['_meta', 'isFetching']),
      isFetching:
        jobApplicationList && jobApplicationList.getIn(['_meta', 'isFetching']),
      ...rest,
    }),
  ),
)(JobApplicationListRefreshButton);

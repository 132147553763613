import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import fetchExistingCandidaciesCountActionCreator from '../../searches/actions/existingCandidaciesCounts/fetchExistingCandidaciesCount';
import shouldFetchExistingCandidaciesCountSelector from '../../searches/selectors/shouldFetchExistingCandidaciesCount';
import getExistingCandidaciesCountSelector from '../../searches/selectors/getExistingCandidaciesCount';

/**
 * Fetches the count of existing candidacies in a search based on a list of contactIds
 */
export default ({
  contactIdsPropName = 'contactIds',
  countPropName = 'existingCandidaciesCount',
  searchIdPropName = 'searchId',
} = {}) =>
  compose(
    setDisplayName('withExistingCandidaciesCount'),
    mapProps(props => ({
      incomingProps: props,
      searchId: props[searchIdPropName],
      contactIds: props[contactIdsPropName],
    })),
    connect(
      (state, { contactIds, searchId }) => ({
        shouldFetchExistingCandidaciesCount: shouldFetchExistingCandidaciesCountSelector(
          state,
          searchId,
          contactIds,
        ),
        existingCandidaciesCount: getExistingCandidaciesCountSelector(
          state,
          searchId,
          contactIds,
        ),
      }),
      {
        fetchExistingCandidaciesCount: fetchExistingCandidaciesCountActionCreator,
      },
    ),
    withHandlers({
      fetchExistingCandidaciesCount: ({
        fetchExistingCandidaciesCount,
      }) => () => fetchExistingCandidaciesCount(),

      fetchExistingCandidaciesCountIfNeeded: ({
        contactIds,
        fetchExistingCandidaciesCount,
        searchId,
        shouldFetchExistingCandidaciesCount,
      }) => () =>
        searchId &&
        contactIds &&
        shouldFetchExistingCandidaciesCount &&
        fetchExistingCandidaciesCount({
          searchId: searchId,
          contactIds: contactIds,
        }),
    }),

    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchExistingCandidaciesCountIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchExistingCandidaciesCountIfNeeded();
      },
    }),
    mapProps(({ existingCandidaciesCount, incomingProps }) => ({
      [countPropName]: existingCandidaciesCount,
      ...incomingProps,
    })),
  );

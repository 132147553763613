import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { isValidEmail } from 'modules/core/validators';
import Form from '@thrivetrm/ui/components/Form';
import Grid from '@thrivetrm/ui/components/Grid';
import useFormValues from '@thrivetrm/ui/hooks/useFormValues';
import Card from '@thrivetrm/ui/components/Card';
import { useLazySearchContactQuery } from 'services/apiV1/contact';
import DuplicateContactsView from './DuplicateContactsView';

/**
 * Renders the Contact form in a modal.
 */
const DEBOUNCE_INTERVAL_MS = 200;
const CreateContactFormV2 = ({ createContactError, onDuplicateSelect }) => {
  const formState = useFormValues();
  const validateEmail = value =>
    value && !isValidEmail(value) ? 'Please enter a valid email address' : null;

  const [
    getContactsByName,
    { data: duplicateContactsWithName },
  ] = useLazySearchContactQuery();
  const [
    getContactsByPersonalEmail,
    { data: duplicateContactsWithPersonalEmail },
  ] = useLazySearchContactQuery();
  const [
    getContactsByWorkEmail,
    { data: duplicateContactsWithWorkEmail },
  ] = useLazySearchContactQuery();

  const debounceGetContacts = useCallback(
    debounce((getContacts, term) => {
      getContacts({
        query: { resource: 'contact', term: term },
      });
    }, DEBOUNCE_INTERVAL_MS),
    [],
  );

  useEffect(() => {
    if (formState.firstName && formState.lastName) {
      debounceGetContacts(
        getContactsByName,
        `${formState?.firstName} ${formState?.lastName}`,
      );
    } else {
      // if first name or last name is removed, then the data should be removed.
      debounceGetContacts(getContactsByName, null);
    }
  }, [formState.firstName, formState.lastName]);

  useEffect(() => {
    debounceGetContacts(getContactsByPersonalEmail, formState?.personalEmail);
  }, [formState?.personalEmail]);

  useEffect(() => {
    debounceGetContacts(getContactsByWorkEmail, formState?.workEmail);
  }, [formState?.workEmail]);

  const validatePersonalEmail = value => {
    if (value && !isValidEmail(value)) {
      return 'Please enter a valid email address';
    } else if (
      duplicateContactsWithPersonalEmail?.find(
        contactData => contactData.email === value,
      )
    ) {
      return 'Email already exist';
    }
    return null;
  };

  return (
    <>
      {duplicateContactsWithName?.length > 0 ||
      duplicateContactsWithPersonalEmail?.length > 0 ||
      duplicateContactsWithWorkEmail?.length > 0 ? (
        <Card
          className='u-border-n u-marginTop-24 u-padding-24 u-marginHorizontal-24'
          data-testid='duplicateContacts'
          isCentered={false}
          type='warning'
        >
          <DuplicateContactsView
            duplicateContactsWithName={duplicateContactsWithName}
            duplicateContactsWithPersonalEmail={
              duplicateContactsWithPersonalEmail
            }
            duplicateContactsWithWorkEmail={duplicateContactsWithWorkEmail}
            onDuplicateSelect={onDuplicateSelect}
          />
        </Card>
      ) : null}

      <Grid size={12}>
        <Grid.Column className='u-marginTop-8' size={6}>
          <Form.TextInput
            inputWidth='full'
            label='First Name'
            name='firstName'
            placeholder='Jane'
            rules={{
              required: {
                value: true,
                message: 'First name is required',
              },
            }}
          />
        </Grid.Column>
        <Grid.Column className='u-marginTop-8' size={6}>
          <Form.TextInput
            inputWidth='full'
            label='Last Name'
            name='lastName'
            placeholder='Smith'
            rules={{
              required: {
                value: true,
                message: 'Last name is required',
              },
            }}
          />
        </Grid.Column>
        <Grid.Column className='u-marginTop-16' size={6}>
          <Form.TextInput
            inputWidth='full'
            label='Personal Email'
            name='personalEmail'
            placeholder='jsmith123@hmail.com'
            rules={{ validate: validatePersonalEmail }}
          />
        </Grid.Column>
        <Grid.Column className='u-marginTop-16' size={6}>
          <Form.TextInput
            inputWidth='full'
            label='Work Email'
            name='workEmail'
            placeholder='jsmith456@hmail.com'
            rules={{ validate: validateEmail }}
          />
        </Grid.Column>
        <Grid.Column
          className={classnames('u-marginTop-16', {
            'u-hidden': !formState.personalEmail || !formState.workEmail,
          })}
          size={12}
        >
          <Form.RadioButtonGroup
            initialValue={true}
            label='Preferred Email'
            name='preferred'
            options={[
              { label: 'Personal Email', value: true },
              { label: 'Work Email', value: false },
            ]}
            size='medium'
          />
        </Grid.Column>
      </Grid>
      {createContactError?.data?.error ? (
        <Card className='u-marginTop-16' type='error'>
          {createContactError?.data?.error}
        </Card>
      ) : null}
    </>
  );
};

CreateContactFormV2.propTypes = {
  createContactError: PropTypes.shape({
    data: PropTypes.shape({
      error: PropTypes.string,
    }),
  }),
  /**
   * When provided and duplicate contacts are found, the duplicate contact will display a
   * "use this contact" link. When that is click, this callback is called with the contact ID
   * of the contact that was selected.
   */
  onDuplicateSelect: PropTypes.func,
};

export default CreateContactFormV2;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import moment from 'moment';

import ContactSelectField from 'modules/contacts/components/ContactSelectField';
import DateInputField from 'modules/datetime/components/DateInputField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import TimeIntervalField from 'modules/forms/components/TimeIntervalField';
import * as validators from 'modules/forms/validators';
import { FILTER_EMPLOYEES } from 'modules/users/constants';
import UserSelectField from 'modules/users/components/UserSelectField';

import RichTextField from '../../../components/forms/richtext/RichTextField';

/**
 * Identifies the wrapper classes to apply to each field for different "layouts", so that we
 * can arrange fields differently as needed.
 */
const FIELD_LAYOUTS = {
  wide: {
    assignedTo: 'col-md-6 col-12',
    relatedContact: 'col-md-6 col-12',
    date: 'col-md-2 col-6',
    time: 'col-md-2 col-6',
    subject: 'col-12',
    notes: 'col-12',
  },
  narrow: {
    assignedTo: 'col-12',
    relatedContact: 'col-12',
    date: 'col-6',
    time: 'col-6',
    subject: 'col-12',
    notes: 'col-12',
  },
};

/**
 * A field that can be used for editing a Task record, but doesn't render a rich text
 * field for the notes. Should be used with a form component that renders it.
 */
class TaskField extends PureComponent {
  static createFieldState(name = 'task', props) {
    const {
      assigned_to: userId,
      contact: contactId,
      notes,
      reminder,
      subject,
    } = props;

    let reminderMoment = moment(reminder || 'invalid', moment.ISO_8601);

    if (!reminderMoment.isValid()) {
      // Use the current time, rounded up to the next hour. moment's 'end of hour'
      // puts us at :59, and we need an even number since we are constraining time to
      // 15 minute intervals.
      reminderMoment = moment().endOf('hour').add(1, 'm');
    }

    return FieldState.createNested(
      name,
      [
        UserSelectField.createFieldState(
          'assigned_to',
          userId,
          validators.requiredField('Name'),
        ),
        ContactSelectField.createFieldState('contact', contactId),
        RichTextField.createFieldState('notes', notes || ''),
        InputField.createFieldState(
          'subject',
          subject || '',
          validators.requiredField('A task name'),
        ),
        DateInputField.createFieldState('date', reminderMoment),
        TimeIntervalField.createFieldState('time', {
          hours: reminderMoment.get('hour'),
          minutes: reminderMoment.get('minute'),
        }),
      ],
      null,
      ({ date, time, ...value }) => {
        // Merges the `date` and `time` fields into `reminder`
        const dateMoment = moment(date, moment.ISO_8601);
        dateMoment.set({
          hour: time.hours,
          minute: time.minutes,
          // don't need seconds
          second: 0,
        });

        return {
          ...value,
          reminder: dateMoment.format(),
        };
      },
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      draft,
      fieldLayout,
      fieldState,
      onDraftChange,
      showContactSelect,
    } = this.props;

    const fieldClasses = FIELD_LAYOUTS[fieldLayout];

    return (
      <div className='task-edit task-item'>
        <div className='row'>
          <div className={fieldClasses.subject}>
            <InputField
              fieldState={fieldState.getNestedField('subject')}
              key='subject'
              label='*Task'
              onChange={this.handleFieldChange}
              placeholder='Enter Task'
            />
          </div>
        </div>
        <div className='row'>
          <div className={fieldClasses.assignedTo}>
            <UserSelectField
              fieldState={fieldState.getNestedField('assigned_to')}
              filter={FILTER_EMPLOYEES}
              label='Assigned To'
              onChange={this.handleFieldChange}
              placeholder='* Assigned To ...'
            />
          </div>
          <div className={fieldClasses.date}>
            <DateInputField
              fieldState={fieldState.getNestedField('date')}
              label='*Due Date'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className={fieldClasses.time}>
            <TimeIntervalField
              fieldState={fieldState.getNestedField('time')}
              label='*Time'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className={fieldClasses.relatedContact}>
            {showContactSelect && (
              <ContactSelectField
                fieldState={fieldState.getNestedField('contact')}
                isCreatable={false}
                label='Associated Contact'
                onChange={this.handleFieldChange}
              />
            )}
          </div>
        </div>
        <div className='row'>
          <div className={fieldClasses.notes}>
            <RichTextField
              btnClass='btn-link'
              fieldState={fieldState.getNestedField('notes')}
              label='Notes'
              lastUpdatedTime={draft?.lastUpdatedTime}
              onChange={this.handleFieldChange}
              onDraftChange={onDraftChange}
              placeholder='Enter notes...'
            />
          </div>
        </div>
      </div>
    );
  }
}

TaskField.propTypes = {
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),
  /**
   * How to layout the form fields (which col classes to apply)
   */
  fieldLayout: PropTypes.oneOf(Object.keys(FIELD_LAYOUTS)),

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,
  onDraftChange: PropTypes.func,

  showContactSelect: PropTypes.bool,
};

TaskField.defaultProps = {
  fieldLayout: 'narrow',
};

export default TaskField;

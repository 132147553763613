import { connect } from 'react-redux';
import getAppointment from '../selectors/getAppointment';

/**
 * A higher order component which maps a `interviewId` prop value to it's interview record.
 */

const mapStateToProps = (state, { interviewId, meetingId }) => {
  if (interviewId) {
    return { interview: getAppointment(state, { interviewId: interviewId }) };
  }
  if (meetingId) {
    return { meeting: getAppointment(state, { meetingId: meetingId }) };
  }

  return {};
};

export default connect(mapStateToProps, {});

import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import { compose, setDisplayName, setStatic } from 'recompose';
import { useSelector } from 'react-redux';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import DateInputField from 'modules/datetime/components/DateInputField';
import CurrencyInputField from 'modules/currency/components/CurrencyInputField';
import CurrencyTypeSelectField from 'modules/currency/components/CurrencyTypeSelectField';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import { DEFAULT_CURRENCY } from 'modules/user/constants';
import InputField from 'modules/forms/components/InputField';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import getCompanyProperty from 'modules/companies/selectors/getCompanyProperty';
import CompanySelectField from '../CompanySelectField';

/**
 * A field for editing the financial secion of a company
 */
const CompanyFinancialsField = ({
  companyId,
  fieldState,
  handleNestedFieldChange,

  // prevent onChange from being passed through to the input.
  onChange: _onChange,
  title,

  ...otherProps
}) => {
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const isTickerEnriched = useSelector(state =>
    getCompanyProperty(state, companyId, 'company_enriched_attributes'),
  )?.toJS()?.ticker_enriched;
  const isTickerFieldDisabled = hasSkyminyrFeature && isTickerEnriched;

  return (
    <div className='CompanyFinancialsField'>
      {title ? <h3>{title}</h3> : null}
      <div className='row'>
        <div className='col-sm-6'>
          <div className='row'>
            <Tooltip
              className='u-block col-sm-12'
              content={
                isTickerFieldDisabled
                  ? 'This field is automatically enriched, and in sync with the golden record.'
                  : null
              }
              size='large'
            >
              <CheckboxField
                className='CompanyFinancialsField__publicly_traded'
                {...otherProps}
                checked={fieldState
                  .getNestedField('publicly_traded')
                  .getValue()}
                disabled={isTickerFieldDisabled}
                fieldState={fieldState.getNestedField('publicly_traded')}
                onChange={handleNestedFieldChange}
                text='Publicly Traded'
              />
            </Tooltip>
            {fieldState.getNestedFieldValue('publicly_traded') ? (
              <DateInputField
                className='CompanyFinancialsField__ipo_date col-md-7 col-sm-12'
                {...otherProps}
                fieldState={fieldState.getNestedField('ipo_date')}
                label='IPO Date'
                name='ipo_date'
                onChange={handleNestedFieldChange}
              />
            ) : null}
            {fieldState.getNestedFieldValue('publicly_traded') ? (
              <Tooltip
                className='u-block col-md-7 col-sm-12'
                content={
                  isTickerFieldDisabled
                    ? 'This field is automatically enriched, and in sync with the golden record.'
                    : null
                }
                size='large'
              >
                <InputField
                  {...otherProps}
                  className='CompanyFinancialsField__ticker'
                  disabled={isTickerFieldDisabled}
                  fieldState={fieldState.getNestedField('ticker')}
                  label='Ticker'
                  name='ticker'
                  onChange={handleNestedFieldChange}
                />
              </Tooltip>
            ) : null}
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='row'>
            <CheckboxField
              className='CompanyFinancialsField__acquired col-12'
              {...otherProps}
              checked={fieldState.getNestedField('acquired').getValue()}
              fieldState={fieldState.getNestedField('acquired')}
              onChange={handleNestedFieldChange}
              text='Acquired'
            />

            {fieldState.getNestedFieldValue('acquired') ? (
              <div>
                <CompanySelectField
                  className='CompanyFinancialsField__acquired_by_company col-sm-12'
                  {...otherProps}
                  allowCreate={true}
                  fieldState={fieldState.getNestedField('acquired_by_company')}
                  label='Acquired By'
                  onChange={handleNestedFieldChange}
                />
                <CurrencyInputField
                  className='CompanyFinancialsField__acquisition_amount col-md-8 col-sm-12'
                  {...otherProps}
                  currency={fieldState
                    .getNestedField('acquisition_currency')
                    .getValue()}
                  fieldState={fieldState.getNestedField('acquisition_amount')}
                  label='Acquisition Amount'
                  onChange={handleNestedFieldChange}
                />
                <CurrencyTypeSelectField
                  {...otherProps}
                  className='CompanyFinancialsField__acquisition_currency col-md-4 col-sm-12'
                  clearable={true}
                  fieldState={fieldState.getNestedField('acquisition_currency')}
                  label='Currency'
                  name='acquisition_currency'
                  onChange={handleNestedFieldChange}
                />
                <DateInputField
                  className='CompanyFinancialsField__date_acquired col-md-8 col-sm-12'
                  {...otherProps}
                  fieldState={fieldState.getNestedField('date_acquired')}
                  label='Date Acquired'
                  name='date_acquired'
                  onChange={handleNestedFieldChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyFinancialsField.createFieldState = (
  name = 'financials',
  company,
  ...rest
) => {
  const values = fromJS({
    date_acquired: '',
    publicly_traded: '',
    acquired: '',
    ipo_date: '',
    acquired_by_company: '',
    acquisition_currency: '',
    acquisition_amount: '',
    ticker: '',
  }).merge(company);

  return FieldState.createNested(
    name,
    [
      CheckboxField.createFieldState(
        'publicly_traded',
        values.get('publicly_traded'),
      ),
      DateInputField.createFieldState('ipo_date', values.get('ipo_date')),
      CheckboxField.createFieldState('acquired', values.get('acquired')),
      CompanySelectField.createFieldState(
        'acquired_by_company',
        values.get('acquired_by_company'),
      ),
      CurrencyInputField.createFieldState(
        'acquisition_amount',
        values.get('acquisition_amount'),
        { minValue: 0, float: true },
      ),
      CurrencyTypeSelectField.createFieldState(
        'acquisition_currency',
        values.get('acquisition_currency') || DEFAULT_CURRENCY,
      ),
      DateInputField.createFieldState(
        'date_acquired',
        values.get('date_acquired'),
      ),
      InputField.createFieldState('ticker', values.get('ticker')),
    ],
    ...rest,
  );
};

CompanyFinancialsField.propTypes = {
  companyId: PropTypes.number.isRequired,
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  /**
   * An optional title to display at the top of the field.
   */
  title: PropTypes.node,
};

export default compose(
  setDisplayName('CompanyFinancialsField(enhanced)'),
  setStatic('createFieldState', CompanyFinancialsField.createFieldState),
  withNestedFieldChangeHandler,
)(CompanyFinancialsField);

import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import genderSchema from '../schemas/genders';

/**
 * Renders the display name for a given gender ID.
 */
const GenderName = ({ genderId }) => (
  <EntityProperty
    entityId={genderId}
    entityType={genderSchema.key}
    propertyName='name'
  />
);

GenderName.propTypes = {
  genderId: PropTypes.number.isRequired,
};

export default GenderName;

import getTalentPoolId from 'modules/contacts/selectors/contacts/getTalentPoolId';
import getJobFunctionName from 'modules/tenant/selectors/getJobFunctionName';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getJobFunctionId from '../selectors/getJobFunctionId';

export default state => contactId => {
  const talentPoolId = getTalentPoolId(state, contactId);

  if (talentPoolId) {
    const jobFunctionId = getJobFunctionId(state, talentPoolId);
    if (jobFunctionId) {
      return stringUtils.toSortableString(
        getJobFunctionName(state, jobFunctionId),
      );
    }
  }

  return '';
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import CompanyInvestmentStageTagSelectField from 'modules/tenant/components/CompanyInvestmentStageTagSelectField';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import TextAreaField from 'modules/forms/components/TextAreaField';
import { REVENUE_MIN_OPTIONS, REVENUE_MAX_OPTIONS } from '../constants';

class BoardMemberInfoField extends PureComponent {
  static createFieldState(name = 'board-member-info', contact) {
    return FieldState.createNested(name, [
      CheckboxField.createFieldState(
        'preferred_board_member_interest',
        contact.getIn(['data', 'preferred_board_member_interest']),
      ),
      ReactSelectField.createFieldState(
        'preferred_board_member_revenue_minimum',
        contact.getIn(['data', 'preferred_board_member_revenue_minimum']),
      ),
      ReactSelectField.createFieldState(
        'preferred_board_member_revenue_maximum',
        contact.getIn(['data', 'preferred_board_member_revenue_maximum']),
      ),
      TextAreaField.createFieldState(
        'preferred_board_member_notes',
        contact.getIn(['data', 'preferred_board_member_notes']),
      ),
      CompanyInvestmentStageTagSelectField.createFieldState(
        'preferred_board_member_company_stage_ids',
        contact.getIn(['data', 'preferred_board_member_company_stages']) || [],
      ),
    ]);
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const { fieldState } = this.props;
    return (
      <div className='section'>
        <div className='row'>
          <div className='col-sm-12'>
            <CheckboxField
              checked={fieldState
                .getNestedField('preferred_board_member_interest')
                .getValue()}
              fieldState={fieldState.getNestedField(
                'preferred_board_member_interest',
              )}
              key='preferred_board_member_interest'
              label='Interested?'
              onChange={this.handleFieldChange}
              text='Yes'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <CompanyInvestmentStageTagSelectField
              fieldState={fieldState.getNestedField(
                'preferred_board_member_company_stage_ids',
              )}
              label='Company Stages'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <ReactSelectField
              clearable={true}
              fieldState={fieldState.getNestedField(
                'preferred_board_member_revenue_minimum',
              )}
              key='preferred_board_member_revenue_minimum'
              label='Company Revenue Min'
              name='preferred_board_member_revenue_minimum'
              onChange={this.handleFieldChange}
              options={REVENUE_MIN_OPTIONS}
              placeholder='Select Revenue...'
              simpleValue={true}
            />
          </div>
          <div className='col-sm-6'>
            <ReactSelectField
              clearable={true}
              fieldState={fieldState.getNestedField(
                'preferred_board_member_revenue_maximum',
              )}
              key='preferred_board_member_revenue_maximum'
              label='Company Revenue Max'
              name='preferred_board_member_revenue_maximum'
              onChange={this.handleFieldChange}
              options={REVENUE_MAX_OPTIONS}
              placeholder='Select Revenue...'
              simpleValue={true}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <TextAreaField
              fieldState={fieldState.getNestedField(
                'preferred_board_member_notes',
              )}
              key='preferred_board_member_notes'
              label='Notes'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

BoardMemberInfoField.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  onChange: PropTypes.func,
};

export default BoardMemberInfoField;

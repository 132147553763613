import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@thrivetrm/ui/components/Icon';
import { RecordTypesWithConnections } from 'services/apiV1/connections';
import useQuickView from 'modules/quick-view/useQuickView';

const ContactCurrentlyReportsTo = ({ contactId, contactName, reportsTo }) => {
  const { navigateTo } = useQuickView();

  const handleAddManagerClick = () => {
    navigateTo(
      `/contact/${contactId}/connections/add-${RecordTypesWithConnections.CONTACT}/?selectedTab=REPORTING RELATIONSHIPS&&closePanelOnCancel=true`,
      { title: contactName },
    );
  };

  const renderReportsTo = (data, index) => {
    const isLastDataToRender = reportsTo.length === index + 1;

    return (
      <span key={index}>
        {data.user ? (
          <span>
            {data.user.full_name}
            {data.user.disabled ? ' (deactivated)' : ''}
            {isLastDataToRender ? '' : ', '}
          </span>
        ) : (
          <>
            <a href={`/contacts/${data.contact.id}`}>
              {data.contact.full_name}
            </a>
            {isLastDataToRender ? '' : ', '}
          </>
        )}
      </span>
    );
  };

  return reportsTo.length ? (
    reportsTo.map(renderReportsTo)
  ) : (
    <button
      className='btn-link u-flex u-flexAlign-c'
      data-testid='add-manager-button'
      onClick={handleAddManagerClick}
      type='button'
    >
      <Icon className='u-marginRight-4' color='blue' size='small' type='add' />
      Add Manager
    </button>
  );
};

ContactCurrentlyReportsTo.propTypes = {
  contactId: PropTypes.number.isRequired,
  contactName: PropTypes.string.isRequired,
  reportsTo: PropTypes.arrayOf(
    PropTypes.shape({
      contact: PropTypes.shape({
        full_name: PropTypes.string,
        id: PropTypes.number,
      }),
      user: PropTypes.shape({
        disabled: PropTypes.bool,
        full_name: PropTypes.string,
      }),
    }),
  ),
};

export default ContactCurrentlyReportsTo;

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import ContactDateModified from 'modules/contacts/components/ContactDateModified';
import CandidatePortalInviteModalButton from 'modules/contacts/components/candidatePortalInviteModal/CandidatePortalInviteModalButton';
import ContactJobOpportunityStatus from 'modules/contact-job-opportunity-status/components/ContactJobOpportunityStatus';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import { useApiGet } from 'modules/core/hooks/useApi';
import routes from 'modules/routing/routes';
import ContactUpdateDropdown from './resume/ContactUpdateDropdown';

function ContactStatusHeader({
  clearTransaction,
  contact,
  contactReviewActions,
  hasCandidatePortal,
  rocketreach,
  transactions,
}) {
  const contactId = contact.getIn(['data', 'id']);

  const [
    loadCustomersConfiguration,
    _isLoadingCustomersConfiguration,
    _customersConfigurationLoadError,
    customersConfiguration,
  ] = useApiGet(routes.api_v1_customers_configuration());

  useEffect(() => {
    if (hasCandidatePortal) {
      loadCustomersConfiguration();
    }
  }, [hasCandidatePortal, loadCustomersConfiguration]);

  return (
    <div className='contact-status-header'>
      <div className='contact-status-header-col'>
        <ContactUpdateDropdown
          clearTransaction={clearTransaction}
          contact={contact}
          contactReviewActions={contactReviewActions}
          rocketreach={rocketreach}
          transactions={transactions}
        />
      </div>
      <div className='contact-status-header-col'>
        <strong>Last updated: </strong>
        <ContactDateModified contactId={contactId} relative={true} />
      </div>
      <ContactJobOpportunityStatus
        className='contact-status-header-col'
        contactId={contactId}
      />
      {customersConfiguration?.customer_configuration
        ?.candidate_portal_operational && (
        <div className='u-marginLeft-8'>
          <CandidatePortalInviteModalButton contactId={contactId} />
        </div>
      )}
    </div>
  );
}

ContactStatusHeader.propTypes = {
  clearTransaction: PropTypes.func.isRequired,

  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      id: PropTypes.number,
    }),
  }).isRequired,

  contactActions: PropTypes.shape({
    createUser: PropTypes.func.isRequired,
  }),

  contactReviewActions: PropTypes.shape({
    createContactReview: PropTypes.func.isRequired,
  }).isRequired,

  hasCandidatePortal: PropTypes.bool,

  rocketreach: PropTypes.bool,

  transactions: transactionsState.isRequired,
};

export default compose(
  setDisplayName('ContactStatusHeader(enhanced)'),
  setPropTypes(ContactStatusHeader.propTypes),
  withFeatureCheck('feature.candidate_portal', 'hasCandidatePortal'),
)(ContactStatusHeader);

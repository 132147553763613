import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {
  branch,
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import DateTime from 'modules/datetime/components/DateTime';
import RelativeTimeLabel from 'modules/datetime/components/RelativeTimeLabel';

import mapSearchIdToSearch from './mapSearchIdToSearch';
import { STATUS_CLOSED, STATUS_BIZ_DEV } from '../constants';

const SearchStatusTime = ({
  referenceDate,
  status,
  statusDate,
  statusText,
}) => (
  <span className='SearchStatusTime'>
    <span className='SearchStatusTime__status' key='status'>
      {statusText}
    </span>{' '}
    {status !== STATUS_BIZ_DEV && (
      <span className='SearchStatusTime__date' key='date'>
        <DateTime format='LL' value={statusDate} />
      </span>
    )}{' '}
    <span className='SearchStatusTime__relativetime' key='relative-date'>
      {status === STATUS_BIZ_DEV && <span>Open for </span>}
      {moment().isSame(statusDate, 'day') ? (
        'Today'
      ) : (
        <RelativeTimeLabel
          affix={status !== STATUS_BIZ_DEV}
          referenceTime={referenceDate}
          time={statusDate}
          titleFormat='l'
        />
      )}
    </span>
  </span>
);

SearchStatusTime.propTypes = {
  referenceDate: PropTypes.string,
  status: PropTypes.string,
  statusDate: PropTypes.string,
  statusText: PropTypes.string,
};

export default compose(
  setDisplayName('SearchStatusTime(enhanced)'),
  setPropTypes({
    /**
     * The search ID of the search to show the location for.
     */
    searchId: PropTypes.number.isRequired,
  }),
  mapSearchIdToSearch,

  // Default to displaying "Opened" status.
  mapProps(({ search }) => ({
    search: search,
    status: search && search.get('status'),
    statusText: 'Opened',
    statusDate: search && search.get('start_date'),
  })),

  // If status is closed, replace props with the closed data instead.
  branch(
    ({ status }) => status === STATUS_CLOSED,
    withProps(({ search }) => ({
      statusText: 'Closed',
      statusDate: search.get('end_date'),
    })),
  ),

  // If BizDev, we display different data.
  branch(
    ({ status }) => status === STATUS_BIZ_DEV,
    withProps(({ search }) => ({
      statusText: 'Business Development',
      statusDate: search.getIn(['biz_dev_status', 'start_date']),
      referenceDate: search.getIn(['biz_dev_status', 'end_date']),
    })),
  ),
)(SearchStatusTime);

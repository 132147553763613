import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import getSearchName from 'modules/searches/selectors/getSearchName';

/**
 * Wraps a base component in a div and includes a Helmet component that sets the page title
 * to the search name.
 */
/* eslint-disable react/prop-types */
const withSearchPageTitle = BaseComponent => ({ pageTitle, ...props }) => (
  <div>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
    <BaseComponent {...props} />
  </div>
);

export default compose(
  connect(
    (state, { searchId }) => ({
      pageTitle: getSearchName(state, searchId),
    }),
    {},
  ),
  withSearchPageTitle,
);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import SearchTitle from 'modules/searches/components/SearchTitle';
import mapCandidacyIdToSearchId from '../mapCandidacyIdToSearchId';
import mapDatumToCandidacyId from './mapDatumToCandidacyId';
import SearchCandidacyLink from '../SearchCandidacyLink';

/**
 * A table cell for rendering the title of a searcha and linking to the main search page.
 */
const CandidacySearchTitleCell = ({ candidacyId, searchId }) => (
  <SearchCandidacyLink candidacyId={candidacyId} useDefaultLinkBehavior={true}>
    <SearchTitle searchId={searchId} />
  </SearchCandidacyLink>
);

CandidacySearchTitleCell.propTypes = {
  candidacyId: PropTypes.number,
  searchId: PropTypes.number,
};

export default compose(
  setDisplayName('CandidacySearchTitleCell(enhanced)'),
  setPropTypes({
    /**
     * The candidacy ID
     */
    datum: PropTypes.number.isRequired,
  }),
  mapDatumToCandidacyId,
  mapCandidacyIdToSearchId,
)(CandidacySearchTitleCell);

import invalidateCandidacyStatus from 'modules/candidacies/actions/invalidateCandidacyStatus';

/**
 * Invalidates the underlying candidacies that are related to an outreach
 * @param {Object} outreach The normalized outreach object returned by the server.
 * @param {Object.<String, Number>} outreach.candidacy_ids an object which maps this outreach's
 *   related search IDs to the associated candidacy ID.
 * NOTE: This should eventually be moved into the candidacy module using an actor.
 */
export default ({ candidacy_ids: candidacyIdMap }) => dispatch => {
  const candidacyIds = candidacyIdMap ? Object.values(candidacyIdMap) : null;

  if (candidacyIds && candidacyIds.length) {
    candidacyIds.forEach(id => dispatch(invalidateCandidacyStatus({ id: id })));
  }
};

import { connect } from 'react-redux';
import getResidency from '../selectors/getResidency';

/**
 * A higher order component which maps an `residencyId` prop value to it's residency record.
 */
export default connect(
  (state, { residencyId }) => ({
    residency: getResidency(state, residencyId),
  }),
  {},
);

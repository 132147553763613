import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  compose,
  defaultProps,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { PARENT_TYPES, LIST_TYPES, LIST_ALL } from '../constants';
import fetchOutreachListActionCreator from '../actions/outreaches/fetchOutreachList';
import getShouldFetchOutreachList from '../selectors/shouldFetchOutreaches';
import getOutreachList from '../selectors/getOutreaches';

/**
 * When provided a parentId, parentType and listType via props, fetches the outreaches
 * for that parent if they need to be fetched so they are available for a component.
 */
export default compose(
  setDisplayName('withOutreachListFetched'),
  setPropTypes({
    parentId: PropTypes.number,
    parentType: PropTypes.oneOf(PARENT_TYPES),
    listType: PropTypes.oneOf(LIST_TYPES).isRequired,
  }),
  defaultProps({
    listType: LIST_ALL,
  }),
  connect(
    (state, { listType, parentId, parentType }) => ({
      shouldFetchOutreachList: getShouldFetchOutreachList(
        state,
        parentType,
        parentId,
        listType,
      ),
      outreachList:
        parentType && getOutreachList(state, parentType, parentId, listType),
    }),
    dispatch => ({
      fetchOutreachList: bindActionCreators(
        fetchOutreachListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchOutreachList: ({
      fetchOutreachList,
      listType,
      parentId,
      parentType,
    }) => () => {
      fetchOutreachList({
        parentId: parentId,
        parentType: parentType,
        listType: listType,
      });
    },

    /**
     * A handler that, when called, only fetches the introduction list if needed.
     */
    fetchOutreachListIfNeeded: ({
      fetchOutreachList,
      listType,
      parentId,
      parentType,
      shouldFetchOutreachList,
    }) => () =>
      shouldFetchOutreachList &&
      fetchOutreachList({
        parentId: parentId,
        parentType: parentType,
        listType: listType,
      }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchOutreachListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchOutreachListIfNeeded();
    },
  }),
);

import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Form from '@thrivetrm/ui/components/Form';
import Modal from '@thrivetrm/ui/components/Modal';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import { isValidEmail } from 'modules/core/validators';
import routes from 'modules/routing/routes';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FormSelect from 'modules/core/components/FormSelect';
import { useApiPost } from '@thrivetrm/ui/hooks/useApi';
import { useGetCompaniesOptionQuery } from 'services/apiV1/generateReferences';

const LEVELS = [
  'Advisor',
  'Associate Vice President',
  'Board Member',
  'Chairman/Chairperson',
  'Director',
  'Executive Vice President',
  'Founder',
  'General Manager',
  'Head of',
  'Lead',
  'Manager',
  'Managing Director',
  'Non Executive',
  'Partner',
  'Principal',
  'Senior Vice President',
  'Vice President',
];

const GenerateReference = ({ contactId }) => {
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const countriesOption = useSelector(state => state.tenant)
    ?.get('countries')
    ?.map(country => ({
      value: country.id,
      label: country.name,
    }));

  const [
    generateRecommendedReferences,
    isGeneratingRecommendedReferences,
  ] = useApiPost(
    routes.generate_recommended_references_api_v1_contact_references({
      contactId: contactId,
    }),
  );

  const {
    data: companiesOption,
    isFetching: isLoadingCompaniesOption,
  } = useGetCompaniesOptionQuery(contactId, {
    skip: !isModalOpen,
  });

  const handleSubmit = formData => {
    const payload = {
      backchannel_filters: {
        emails: formData?.emails,
        company_ids: formData?.companies?.map(company => company.value) || [],
        country_codes: formData?.countries?.map(company => company.value) || [],
        levels: formData?.levels?.map(level => level.value) || [],
      },
    };
    generateRecommendedReferences(payload, {
      onSuccess: data => setSuccessMessage(data?.message),
      onError: data => setError(data),
    });
  };

  const handleModalClose = () => {
    setSuccessMessage('');
    setError('');
    closeModal();
  };

  return (
    <>
      <ButtonPrimary
        className='u-marginBottom-16'
        isFullWidth={true}
        label='Generate Recommended References'
        onClick={openModal}
      />
      <Modal
        className='GenerateReference'
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title='Generate Recommended References'
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {successMessage ? (
              <div className='alert alert-success' role='alert'>
                {successMessage}
              </div>
            ) : (
              <>
                <h2 className='u-marginTop-n'>Filter References</h2>
                <p className='u-marginTop-12 u-marginBottom-n'>
                  Filter references by company of overlapping position with
                  candidate.
                </p>
                <FormSelect
                  isFetchingOptions={isLoadingCompaniesOption}
                  isMulti={true}
                  name='companies'
                  options={companiesOption?.map(company => ({
                    label: company?.name,
                    value: company?.id,
                  }))}
                  placeholder='Select company...'
                />
                <p className='u-marginTop-12 u-marginBottom-n'>
                  Filter references by country.
                </p>
                <FormSelect
                  isMulti={true}
                  name='countries'
                  options={countriesOption}
                  placeholder='Select country...'
                />
                <p className='u-marginTop-12 u-marginBottom-n'>
                  Filter references by level of overlapping position with
                  candidate.
                </p>
                <FormSelect
                  isMulti={true}
                  name='levels'
                  options={LEVELS.map(level => ({
                    label: level,
                    value: level,
                  }))}
                  placeholder='Select level...'
                />
                <h2>Recipients</h2>
                <Form.EmailRecipientsInput
                  autoComplete='email'
                  label='Emails'
                  name='emails'
                  rules={{
                    required: {
                      value: true,
                      message: 'An email address is required',
                    },
                    validate: values => {
                      const invalidEmails = values.filter(
                        value => !isValidEmail(value),
                      );
                      return invalidEmails.length
                        ? `Invalid emails: ${invalidEmails.join(', ')}`
                        : null;
                    },
                  }}
                />

                {error ? (
                  <div
                    className='alert alert-danger u-marginTop-12'
                    role='alert'
                  >
                    <button
                      aria-label='Close'
                      className='close'
                      onClick={() => setError('')}
                      type='button'
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                    {error}
                  </div>
                ) : null}
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <ButtonPrimary
              isOutline={true}
              label={successMessage ? 'Close' : 'Cancel'}
              onClick={handleModalClose}
            />
            {successMessage ? null : (
              <Form.SubmitButton
                isLoading={isGeneratingRecommendedReferences}
                label='Generate References'
              />
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

GenerateReference.propTypes = {
  contactId: PropTypes.number.isRequired,
};

export default GenerateReference;

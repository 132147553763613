import PropTypes from 'prop-types';
import React from 'react';
import { OrderedMap } from 'immutable';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Menu from '@thrivetrm/ui/components/Menu';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import ConnectionField from 'modules/contacts/components/contactConnection/ConnectionField';
import FieldState from 'modules/forms/FieldState';
import {
  RecordTypesWithConnections,
  useGetConnectionStrengthsQuery,
  useGetConnectionTypesQuery,
} from 'services/apiV1/connections';

const ContactConnectionListField = ({
  fieldState,
  onChange,
  ...otherProps
}) => {
  /**
   * Creates a fieldState for the ContactConnectionListField.
   * @param {String} [name='connectionList'] The field name
   * @param {Immutable.List} connectionList The list of connection objects. Each connection in the list
   *   should be a connection record itself (an Immutable.Map) with a unique ID.
   */

  const { data: connectionStrengths } = useGetConnectionStrengthsQuery();
  const { data: connectionTypes } = useGetConnectionTypesQuery();

  const handleFieldChange = childFieldState => {
    onChange(fieldState.setNestedField(childFieldState));
  };

  const createNewConnection = connectionType => {
    const id = uniqueId('NEW');

    const newMap = new OrderedMap([
      [id, ConnectionField.createFieldState(id, {}, connectionType)],
    ]);

    onChange(fieldState.setValue(newMap.concat(fieldState.getValue())));
  };

  const removeConnection = (event, childField) => {
    onChange(
      fieldState.setValue(fieldState.getValue().delete(childField.getName())),
    );
  };

  return (
    <div className='ContactConnectionListField'>
      <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween u-marginBottom-16'>
        <div>
          <h2 className='u-flexItem-grow'>Connections</h2>
          <span>
            Associate other contacts to this contact, either as a peer, manager,
            referral, or relationship manager.
          </span>
        </div>
        <div>
          <Menu
            button={
              <ButtonSecondary icon='add' label='Add Connection' size='small' />
            }
            className='u-flex'
            isPinnedRight={true}
          >
            <Menu.Item
              onClick={() =>
                createNewConnection(RecordTypesWithConnections.USER)
              }
            >
              To User
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                createNewConnection(RecordTypesWithConnections.CONTACT)
              }
            >
              To Contact
            </Menu.Item>
          </Menu>
        </div>
      </div>
      <ul className='list-unstyled'>
        {fieldState
          .getValue()
          .map(childFieldState => (
            <li
              className='ContactConnectionListField__list-item u-padding-24'
              key={childFieldState.getName()}
            >
              <div className='u-textAlign-r'>
                <ConfirmationPopoverButton
                  className='Button Button--secondary Button--medium Button--hasIconOnly'
                  iconClass='fa fa-trash'
                  key='delete'
                  onConfirm={removeConnection}
                  title='Are you sure you want to delete this connection?'
                  value={childFieldState}
                />
              </div>
              <ConnectionField
                connectionStrengths={connectionStrengths}
                connectionTypes={connectionTypes}
                fieldState={childFieldState}
                onChange={handleFieldChange}
                {...otherProps}
              />
            </li>
          ))
          .toArray()}
      </ul>
    </div>
  );
};

ContactConnectionListField.propTypes = {
  connectionList: PropTypes.arrayOf(PropTypes.node),
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  onChange: PropTypes.func.isRequired,
  setConnectionList: PropTypes.func,
};

const createFieldState = (name = 'connectionList', connectionList) => {
  if (!connectionList || connectionList.size < 1) {
    return FieldState.create(name, new OrderedMap());
  }

  return FieldState.create(
    name,
    new OrderedMap(
      connectionList.map(connection => [connection.get('id'), connection]),
    ).map((connection, id) =>
      ConnectionField.createFieldState(
        id,
        connection.withMutations(map => {
          map.set('connected_user_id', map.getIn(['connected_to_user', 'id']));
          map.set(
            'connected_contact_id',
            map.getIn(['connected_to_contact', 'id']),
          );
          map.set('connected_user_id', map.getIn(['connected_to_user', 'id']));
          map.set('connection_type_id', map.getIn(['connection_type', 'id']));
          map.set(
            'connection_strength_id',
            map.getIn(['connection_strength', 'id']),
          );
          map.set('referral_company_id', map.getIn(['referral_company', 'id']));
        }),
        connection.get('connected_to_contact')
          ? RecordTypesWithConnections.CONTACT
          : RecordTypesWithConnections.USER,
      ),
    ),
  );
};

ContactConnectionListField.createFieldState = createFieldState;

export default ContactConnectionListField;

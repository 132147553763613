import { NAME } from '../constants';

/**
 * Gets the revenue list state for a company
 * @param {*} state
 * @param {Number} companyId The company ID to get the revenue list state for.
 * @returns {Immutable.Map} The revenue list state for the company, if found.
 */
export default (state, companyId) =>
  state[NAME].getIn(['revenueListsByCompanyId', companyId]);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setPropTypes, withHandlers } from 'recompose';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';

import {
  SELECTOR_VIEW_MODES,
  VIEW_MODE_LABELS,
  VIEW_MODE_ICONS,
} from '../constants';

/**
 * Renders a group of buttons that can be used to select the view type to display for a
 * target company view.
 */
const TargetCompaniesViewModeSelect = ({
  componentId,
  onViewModeClick,
  value,
}) => (
  <div className='TargetCompaniesViewModeSelect btn-group btn-group-sm'>
    {SELECTOR_VIEW_MODES.map(viewMode => (
      <OverlayTrigger
        key={viewMode}
        overlay={
          <Tooltip id={`${componentId}-${viewMode}-tooltip`}>
            {VIEW_MODE_LABELS[viewMode]}
          </Tooltip>
        }
        placement='top'
      >
        <button
          className={classnames(
            'btn',
            'u-inlineFlex',
            'u-flexJustify-c',
            'u-flexAlign-c',
            'btn-default',
            'btn-toggle',
            {
              active: value === viewMode,
            },
          )}
          onClick={onViewModeClick}
          title={VIEW_MODE_LABELS[viewMode]}
          type='button'
          value={viewMode}
        >
          <i className={classnames('fa', VIEW_MODE_ICONS[viewMode])} />
        </button>
      </OverlayTrigger>
    ))}
  </div>
);

TargetCompaniesViewModeSelect.propTypes = {
  componentId: PropTypes.string.isRequired,

  /**
   * Callback called when one of the view mode buttons is clicked.
   */
  onViewModeClick: PropTypes.func.isRequired,

  /**
   * The currently selected view mode value.
   */
  value: PropTypes.oneOf(SELECTOR_VIEW_MODES).isRequired,
};

export default compose(
  setPropTypes({
    /**
     * Callback called when the selected value changes.
     */
    onChange: PropTypes.func.isRequired,

    value: PropTypes.oneOf(SELECTOR_VIEW_MODES).isRequired,
  }),
  withComponentId(),
  withHandlers({
    // Expose an `onChange` callback that works more simply than `onViewModeClick`:
    // 1. Called only when the actual value changes;
    // 2. Called with the new value itself, rather than the event.
    onViewModeClick: ({ onChange, value }) => e => {
      const nextValue = e.currentTarget.value;

      if (nextValue !== value) {
        onChange(nextValue);
      }
    },
  }),
)(TargetCompaniesViewModeSelect);

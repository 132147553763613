import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import DateTime from 'modules/datetime/components/DateTime';

import ExpandableText from '@thrivetrm/ui/components/ExpandableText';

import mapIntroductionIdToIntroduction from './mapIntroductionIdToIntroduction';
import IntroductionTitle from './IntroductionTitle';

/**
 * Renders the view mode of a introduction item.
 */
export const IntroductionView = ({
  introduction,
  linkToCandidacy,
  renderDate,
}) => (
  <div className='introductions--introduction-view'>
    {renderDate && (
      <div className='introductions--introduction-view-date' key='date'>
        <DateTime format='L LT' value={introduction.get('date')} />
      </div>
    )}
    <div className='introductions--introduction-view-introduced-to'>
      <IntroductionTitle
        className='u-fontSize-small'
        introductionId={introduction.get('id')}
        linkToCandidacy={linkToCandidacy}
      />
    </div>
    {introduction.get('notes') && (
      <ExpandableText
        characterLimit={150}
        className='u-marginTop-12'
        content={introduction.get('notes')}
        isSanitizedHtml={true}
      />
    )}
  </div>
);

IntroductionView.propTypes = {
  /**
   * The introduction to render.
   */
  introduction: ImmutablePropTypes.mapContains({
    introduced_to: PropTypes.number.isRequired,
    contact: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    notes: PropTypes.string,
    notes_partial: PropTypes.string,
  }).isRequired,

  linkToCandidacy: PropTypes.bool,

  renderDate: PropTypes.bool,
};

IntroductionView.defaultProps = {
  renderDate: true,
};

/**
 * The default export requires only a `introductionId`, while
 * the named export requires the introduction record.
 */
export default compose(
  setDisplayName('IntroductionView(enhanced)'),
  setPropTypes({
    introductionId: PropTypes.number.isRequired,
    renderDate: PropTypes.bool,
  }),
  mapIntroductionIdToIntroduction,
)(IntroductionView);

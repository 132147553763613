import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import { removeSubsidiaryCompany } from './subsidiaryCompaniesSlice';

const SubsidiaryCompanyDeleteButton = ({
  parentCompanyId,
  subsidiaryCompanyId,
}) => {
  const dispatch = useDispatch();
  const { isRemovingSubsidiaryCompany } = useSelector(
    state => state.subsidiaryCompanies,
  );

  const handleRemove = () => {
    dispatch(removeSubsidiaryCompany(subsidiaryCompanyId, parentCompanyId));
  };

  return (
    <ConfirmationPopoverButton
      className='Button Button--secondary Button--hasIconOnly Button--small'
      data-testid='remove-subsidiary-company-button'
      disabled={isRemovingSubsidiaryCompany}
      iconClass='fa fa-times'
      onConfirm={handleRemove}
      popoverPlacement='left'
    >
      Are you sure you want to remove this Subsidiary Company?
    </ConfirmationPopoverButton>
  );
};

SubsidiaryCompanyDeleteButton.propTypes = {
  parentCompanyId: PropTypes.number.isRequired,
  subsidiaryCompanyId: PropTypes.number.isRequired,
};

export default SubsidiaryCompanyDeleteButton;

import { connect } from 'react-redux';
import getCandidacyCount from '../selectors/getCandidacyCount';

/**
 * A higher order component which provides a `candidacyCount` prop value given a `talentPoolId`
 * prop, which represents the number of candidacies in a particular talent pool. If a `talentPoolId`
 * is not provided (if there is no talent pool assocaited with a contact, for example) then
 * the `candidacyCount` prop is passed a value of `null`
 */
export default connect(
  (state, { talentPoolId }) => ({
    candidacyCount: talentPoolId
      ? getCandidacyCount(state, talentPoolId)
      : null,
  }),
  {},
);

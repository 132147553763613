import { USER_STATE_KEY } from '../constants';

/**
 * Returns a value indicating if the current user is a client or external recruiter user.
 * @param {*} state
 * @returns {Boolean} true if the current user is either in the  "client" or "external recruiter"
 *   role;  otherwise, returns false.
 */
const isClientOrExternalRecruiterUser = state =>
  state[USER_STATE_KEY].get('client_or_external_recruiter', false);

export default isClientOrExternalRecruiterUser;

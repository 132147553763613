import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import moment from 'moment';
import { compose, mapProps, setDisplayName, setStatic } from 'recompose';

import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import ContactMultiSelectField from 'modules/contacts/components/ContactMultiSelectField';
import CurrencyInputField from 'modules/currency/components/CurrencyInputField';
import DateInputField from 'modules/datetime/components/DateInputField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import PercentageInputField from 'modules/forms/components/PercentageInputField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import BooleanRadioSelectField from 'modules/forms/components/BooleanRadioSelectField';
import FundMultiSelectField from 'modules/funds/components/FundMultiSelectField';
import withFundListFetched from 'modules/funds/components/withFundListFetched';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import companyPriorities from 'modules/tenant/schemas/companyPriorities';
import getCompanyPriorityOrder from 'modules/tenant/selectors/getCompanyPriorityOrder';
import revenueGrowths from 'modules/tenant/schemas/revenueGrowths';
import getRevenueGrowthOrder from 'modules/tenant/selectors/getRevenueGrowthOrder';
import withTenantName from 'modules/tenant/components/withTenantName';

import { STAGE_PORTFOLIO, STAGE_EXITED } from '../constants';
import CompanyPortfolioStageSelectField from './CompanyPortfolioStageSelectField';

const createFieldState = (name = 'company', company) => {
  const values = fromJS({
    board_of_directors: null,
    exit_date: null,
    funds: [],
    initial_investment_date: null,
    investment_value: null,
    off_limits_status: null,
    partners: [],
    percent_owned: null,
  }).merge(company);

  const investmentDateMoment = moment(
    values.get('initial_investment_date'),
    moment.ISO_8601,
  );
  const exitDateMoment = moment(values.get('exit_date'), moment.ISO_8601);

  return FieldState.createNested(
    name,
    [
      CompanyPortfolioStageSelectField.createFieldState(
        'off_limits_status',
        values.get('off_limits_status'),
      ),
      BooleanRadioSelectField.createFieldState(
        'control_deal',
        values.get('control_deal'),
      ),
      ContactMultiSelectField.createFieldState(
        'partners',
        values.get('partners') || [],
      ),
      InputField.createFieldState(
        'board_of_directors',
        values.get('board_of_directors'),
      ),
      TenantOptionsSelectField.createFieldState(
        'company_priority_id',
        values.get('company_priority'),
      ),
      FundMultiSelectField.createFieldState('funds', values.get('funds') || []),
      PercentageInputField.createFieldState(
        'percent_owned',
        values.get('percent_owned'),
        { minValue: 0, maxValue: 100 },
      ),
      CurrencyInputField.createFieldState(
        'investment_value',
        values.get('investment_value'),
        {
          float: true,
        },
      ),
      TenantOptionsSelectField.createFieldState(
        'revenue_growth_id',
        values.get('revenue_growth'),
      ),
      DateInputField.createFieldState(
        'initial_investment_date',
        investmentDateMoment.isValid() ? investmentDateMoment : null,
      ),
      DateInputField.createFieldState(
        'exit_date',
        exitDateMoment.isValid() ? exitDateMoment : null,
      ),
    ],
    null,
    ({ funds, partners, ...rest }) => ({
      ...rest,
      fund_ids: funds,
      partner_ids: partners,
    }),
  );
};

/**
 * A field for editing the basic information about a company
 */
const CompanyPortfolioDetailsField = ({
  allowBoardOfDirectors,
  allowRevenueGrowth,
  fieldState,
  fundListLoaded,
  handleNestedFieldChange,
  hasFunds,

  // prevent onChange from being passed through to the input.
  onChange: _onChange,
  tenantName,
  title,

  ...otherProps
}) => {
  const offLimitsStatus = fieldState.getNestedFieldValue('off_limits_status');
  const showFunds = !fundListLoaded || hasFunds;

  return (
    <div className='CompanyPortfolioDetailsField'>
      {title && <h3>{title}</h3>}
      <div className='row'>
        <div className='col-sm-6'>
          <CompanyPortfolioStageSelectField
            {...otherProps}
            className='CompanyPortfolioDetailsField__off_limits_status'
            fieldState={fieldState.getNestedField('off_limits_status')}
            name='off_limits_status'
            onChange={handleNestedFieldChange}
          />
        </div>
      </div>
      {(offLimitsStatus === STAGE_PORTFOLIO ||
        offLimitsStatus === STAGE_EXITED) && (
        <div className='row'>
          <div className='col-sm-12'>
            <BooleanRadioSelectField
              {...otherProps}
              className='CompanyPortfolioDetailsField__control_deal'
              falseLabel='No'
              fieldState={fieldState.getNestedField('control_deal')}
              label='Control Deal?'
              onChange={handleNestedFieldChange}
              trueLabel='Yes'
            />
          </div>
          <div className='col-sm-6'>
            <ContactMultiSelectField
              {...otherProps}
              allowCreate={true}
              className='CompanyPortfolioDetailsField__partners'
              fieldState={fieldState.getNestedField('partners')}
              label={`${tenantName} Partners`}
              noValueText='No partners selected'
              onChange={handleNestedFieldChange}
              placeholder='No partners Selected'
              summaryLabel='{0} partners selected'
            />
          </div>
          <TenantOptionsSelectField
            className='col-sm-6 CompanyPortfolioDetailsField__company_priority_id'
            clearable={true}
            fieldState={fieldState.getNestedField('company_priority_id')}
            label='Priority'
            onChange={handleNestedFieldChange}
            placeholder='Select Priority...'
            renderIfNoOptions={false}
            schema={companyPriorities}
            sortSelector={getCompanyPriorityOrder}
          />
          {allowBoardOfDirectors && (
            <div className='col-sm-6'>
              <InputField
                {...otherProps}
                className='CompanyPortfolioDetailsField__board_of_directors'
                fieldState={fieldState.getNestedField('board_of_directors')}
                label={`${tenantName} Board of Directors`}
                name='board_of_directors'
                onChange={handleNestedFieldChange}
              />
            </div>
          )}
          {showFunds && (
            <div className='col-sm-6'>
              <FundMultiSelectField
                {...otherProps}
                className='CompanyPortfolioDetailsField__funds'
                fieldState={fieldState.getNestedField('funds')}
                label='Funds'
                onChange={handleNestedFieldChange}
              />
            </div>
          )}
          <div className='col-sm-6'>
            <PercentageInputField
              {...otherProps}
              className='CompanyPortfolioDetailsField__percent_owned'
              fieldState={fieldState.getNestedField('percent_owned')}
              label='% Owned'
              onChange={handleNestedFieldChange}
            />
          </div>
          <div className='col-sm-6'>
            <CurrencyInputField
              {...otherProps}
              className='CompanyPortfolioDetailsField__investment_value'
              fieldState={fieldState.getNestedField('investment_value')}
              label='Projected Value'
              onChange={handleNestedFieldChange}
            />
          </div>
          {allowRevenueGrowth && (
            <TenantOptionsSelectField
              className='col-sm-6 CompanyPortfolioDetailsField__revenue_growth_id'
              clearable={true}
              fieldState={fieldState.getNestedField('revenue_growth_id')}
              label='Revenue Growth'
              onChange={handleNestedFieldChange}
              placeholder='Revenue Growth...'
              renderIfNoOptions={false}
              schema={revenueGrowths}
              sortSelector={getRevenueGrowthOrder}
            />
          )}
          <div className='col-sm-3'>
            <DateInputField
              {...otherProps}
              className='CompanyPortfolioDetailsField__initial_investment_date'
              fieldState={fieldState.getNestedField('initial_investment_date')}
              label='Initial Investment'
              name='initial_investment_date'
              onChange={handleNestedFieldChange}
              placeholderText='Select Date'
            />
          </div>
          {offLimitsStatus === STAGE_EXITED && (
            <div className='col-sm-3'>
              <DateInputField
                {...otherProps}
                className='CompanyPortfolioDetailsField__exit_date'
                fieldState={fieldState.getNestedField('exit_date')}
                label='Exited Date'
                name='exit_date'
                onChange={handleNestedFieldChange}
                placeholderText='Select Date'
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

CompanyPortfolioDetailsField.propTypes = {
  /**
   * Whether the board of directors field should be shown
   */
  allowBoardOfDirectors: PropTypes.bool,

  /**
   * Whether the revenue growth field should be shown
   */
  allowRevenueGrowth: PropTypes.bool,

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * True if the list of funds has been loaded, otherwise false.
   */
  fundListLoaded: PropTypes.bool.isRequired,

  /**
   * Called when a nested field has changed
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * True if there are any funds in state
   */
  hasFunds: PropTypes.bool.isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  /**
   * The name of the current tenant. Used to customize the label of the "Partners" field
   */
  tenantName: PropTypes.string,

  /**
   * An optional title to display at the top of the field.
   */
  title: PropTypes.node,
};

CompanyPortfolioDetailsField.defaultProps = {
  allowBoardOfDirectors: false,
  allowRevenueGrowth: false,
};

export default compose(
  setDisplayName('CompanyPortfolioDetailsField(enhanced'),
  setStatic('createFieldState', createFieldState),
  withTenantName(),

  // The fund selector should be hidden if the current tenant doesn't actually have any funds.
  // So we make sure we have fetched them, then provide some props to the
  // `CompanyPortfolioDetailsField` so it can decide when to show/hide the funds field.
  // We also pull off some extra props that `withFundListFetched` gives us because they aren't
  // used by the underlying component.
  withFundListFetched,
  withFeatureCheck('field.company.board_of_directors', 'allowBoardOfDirectors'),
  withFeatureCheck('field.company.revenue_growth', 'allowRevenueGrowth'),
  mapProps(
    ({
      fetchFundList: _fetchFundList,
      fetchFundListIfNeeded: _fetchFundListIfNeeded,
      fundList,
      shouldFetchFundList: _shouldFetchFundList,
      ...rest
    }) => ({
      ...rest,
      fundListLoaded: fundList ? fundList.has('ids') : false,
      hasFunds:
        fundList && fundList.get('ids')
          ? fundList.get('ids').count() > 0
          : false,
    }),
  ),
  withNestedFieldChangeHandler,
)(CompanyPortfolioDetailsField);

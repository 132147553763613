import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName, setStatic } from 'recompose';
import SearchStageListSelectField from 'modules/search-stages/components/SearchStageListSelectField';
import RejectionReasonSelectField from 'modules/search-stages/components/RejectionReasonSelectField';
import withRejectedStageIds from 'modules/search-stages/components/withRejectedStageIds';
import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactSelectField from 'modules/forms/components/ReactSelectField';

/**
 * A component to select from among a candidacies stages and if 'rejected' is selected.
 * the RejectionReasonSelectField and TextAreaField are shown.
 */
const CandidacyMoveStageField = ({
  currentStageId,
  fieldState,
  handleNestedFieldChange,
  onChange,
  rejectedStageIds,
  stageType,
}) => {
  const shouldShowRejectionFields = rejectedStageIds?.includes(
    fieldState.getNestedField('stage_id')?.getValue(),
  );

  const handleSearchStateChange = value => {
    if (rejectedStageIds?.includes(value?.getValue())) {
      handleNestedFieldChange(value);
    } else {
      onChange(
        fieldState
          .setNestedFieldValue('stage_id', value.getValue())
          .setNestedFieldValue('rejection_comment', null)
          .setNestedFieldValue('rejection_reason', null),
      );
    }
  };

  return (
    <div className='CandidacyMoveStageField'>
      <SearchStageListSelectField
        currentStageId={currentStageId}
        fieldState={fieldState.getNestedField('stage_id')}
        onChange={handleSearchStateChange}
        stageType={stageType}
      />
      {shouldShowRejectionFields ? (
        <div className='RejectionField'>
          <RejectionReasonSelectField
            fieldState={fieldState.getNestedField('rejection_reason')}
            onChange={handleNestedFieldChange}
            shouldShowLabel={true}
          />
          <TextAreaField
            fieldState={fieldState.getNestedField('rejection_comment')}
            label='Comments'
            onChange={handleNestedFieldChange}
          />
        </div>
      ) : null}
    </div>
  );
};

CandidacyMoveStageField.propTypes = {
  /**
   * The ID of the stage the candidacy is currently assigned to.
   */
  currentStageId: PropTypes.number.isRequired,

  /**
   * The field state containing the nested values.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when a nested field is changed.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  onChange: PropTypes.func,

  rejectedStageIds: ImmutablePropTypes.listOf(PropTypes.number),
  /**
   * The stageType to display stage list for.
   */
  stageType: PropTypes.string,
};

export default compose(
  setDisplayName('CandidacyMoveStageField(enhanced)'),
  setPropTypes({
    /**
     * The ID of the stage the candidacy is currently assigned to.
     */
    currentStageId: PropTypes.number.isRequired,

    /**
     * The FieldState that manages the value of the control.
     */
    fieldState: PropTypes.instanceOf(FieldState).isRequired,

    onChange: PropTypes.func.isRequired,

    stageType: PropTypes.string.isRequired,
  }),
  setStatic('createFieldState', (name = 'candidacy', { candidacy }) =>
    FieldState.createNested(name, [
      ReactSelectField.createFieldState(
        'stage_id',
        candidacy.get('candidacy_stage'),
      ),
      RejectionReasonSelectField.createFieldState(
        'rejection_reason',
        candidacy.get('rejection_reason'),
      ),
      TextAreaField.createFieldState(
        'rejection_comment',
        candidacy.get('rejection_comment'),
      ),
    ]),
  ),
  withNestedFieldChangeHandler,
  withRejectedStageIds(),
)(CandidacyMoveStageField);

import { connect } from 'react-redux';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getEmailTemplateName from '../selectors/getEmailTemplateName';

/**
 * A Higher order component that sorts a list of emailTemplateIds on in alphabetical
 * order by name.
 */
export default connect(
  (state, { emailTemplateIds }) => ({
    emailTemplateIds:
      emailTemplateIds &&
      emailTemplateIds.sortBy(emailTemplateId =>
        stringUtils.toSortableString(
          getEmailTemplateName(state, emailTemplateId),
        ),
      ),
  }),
  {},
);

import PropTypes from 'prop-types';
import React from 'react';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import { connect } from 'react-redux';
import GenerateReportButton from 'modules/reports/components/GenerateReportButton';

import CompensationReportModal from './CompensationReportModal';
import canViewCompensationReportSelector from '../../selectors/canViewCompensationReport';

/**
 * Renders a button that, when clicked, displays the
 * CompensationReportModal dialog allowing a user to request a search compensation report.
 */
const CompensationReportButton = ({
  currentUserEmail,
  handleHideModal,
  handleShowModal,
  isModalOpen,
  searchId,
}) => (
  <div className='CompensationReportButton'>
    <GenerateReportButton onClick={handleShowModal}>
      Search Compensation Report
    </GenerateReportButton>
    {isModalOpen && (
      <CompensationReportModal
        currentUserEmail={currentUserEmail}
        onHide={handleHideModal}
        searchId={searchId}
        show={isModalOpen}
      />
    )}
  </div>
);

CompensationReportButton.propTypes = {
  currentUserEmail: PropTypes.string.isRequired,
  /**
   * Called when the modal should be hidden
   * (provided by withProps HOC, below)
   */
  handleHideModal: PropTypes.func.isRequired,

  /**
   * Called when the modal should be shown
   * (provided by withProps HOC, below)
   */
  handleShowModal: PropTypes.func.isRequired,

  /**
   * Determines whether the modal form is shown
   * (managed by withState HOC, below)
   */
  isModalOpen: PropTypes.bool.isRequired,

  /**
   * The ID of the search to generate the report for.
   */
  searchId: PropTypes.number.isRequired,
};

export default compose(
  setDisplayName('CompensationReportButton(enhanced)'),
  setPropTypes({
    /**
     * Whether or not we should check if the user has the required permission for viewing/generating
     * a search summary report. (default: true).
     */
    checkPermissions: PropTypes.bool.isRequired,

    /**
     * The ID of the search to generate the report for.
     */
    searchId: PropTypes.number.isRequired,
  }),

  // By default we check permissions.
  defaultProps({
    checkPermissions: true,
  }),

  // Lookup permission if needed
  connect(
    (state, { checkPermissions }) => ({
      canViewCompensation:
        !checkPermissions || canViewCompensationReportSelector(state),
    }),
    {},
  ),

  // Bail if the user doesn't have permission.
  branch(({ canViewCompensation }) => !canViewCompensation, renderNothing),

  // Manage the state of the modal.
  withState('isModalOpen', 'setIsModalOpen', false),
  withHandlers({
    handleShowModal: ({ setIsModalOpen }) => () => setIsModalOpen(true),
    handleHideModal: ({ setIsModalOpen }) => () => setIsModalOpen(false),
  }),
)(CompensationReportButton);

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import ConditionalTooltipTrigger from 'modules/core/componentsLegacy/ConditionalTooltipTrigger';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import isCandidateUser from 'modules/auth/selectors/isCandidateUser';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import FieldState from 'modules/forms/FieldState';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import disabilityStatusSchema from 'modules/tenant/schemas/disabilityStatuses';
import genderSchema from 'modules/tenant/schemas/genders';
import raceEthnicitySchema from 'modules/tenant/schemas/raceEthnicities';
import demographicSourceSchema from 'modules/tenant/schemas/demographicSources';
import sexualOrientationSchema from 'modules/tenant/schemas/sexualOrientations';
import veteranStatusSchema from 'modules/tenant/schemas/veteranStatuses';
import withTenantOptionsEntityIdsFetched from 'modules/tenant/components/withTenantOptionsEntityIdsFetched';
import MultiValueInputField from 'modules/forms/components/MultiValueInputField';
import AdvancedSelectField from 'modules/forms/components/AdvancedSelectField';
import SourceIndicator from 'modules/contact-diversity/components/SourceIndicator';
import { displayDate } from '@thrivetrm/ui/utilities/dateTimeUtils';

export const getSelfReportedTooltip = (
  candidateName,
  userIsCandidate,
  isSelfReported,
  category,
  createdAt,
) => {
  const propertySelfReportedDate = displayDate(createdAt);
  return !userIsCandidate && isSelfReported
    ? `${
        candidateName || 'Candidate'
      } self reported on ${propertySelfReportedDate}. Unable to modify ${category}`
    : null;
};

/**
 * A field for editing a contact's diversity
 */
const ContactDiversityField = ({
  candidateName,
  demographicSourceIds,
  demographicsLastUpdatedAt,
  disabilityStatusIds,
  disabilityStatusSelfReported,
  fieldState,
  genderIds,
  genderSelfReported,
  handleNestedFieldChange,
  hasDemographicSource,
  hasDiversity,
  hasDiversityBirthYear,
  raceEthnicityIds,
  raceEthnicitySelfReported,
  sexualOrientationIds,
  sexualOrientationSelfReported,
  veteranStatusIds,
  veteranStatusSelfReported,
}) => {
  const userIsCandidate = useSelector(state => isCandidateUser(state));
  const currentYear = new Date().getFullYear();
  const years = range(currentYear - 18, currentYear - 101, -1);
  const yearOptions = years.map(yearValue => ({
    value: yearValue,
    label: yearValue,
  }));

  const genderOptions = useSelector(state =>
    genderIds?.map(id => ({
      value: id,
      label: getEntityProperty(state, genderSchema.key, id, 'name'),
    })),
  );

  const raceEthnicityOptions = useSelector(state =>
    raceEthnicityIds?.map(id => ({
      value: id,
      label: getEntityProperty(state, raceEthnicitySchema.key, id, 'name'),
    })),
  );

  const veteranStatusOptions = useSelector(state =>
    veteranStatusIds?.map(id => ({
      value: id,
      label: getEntityProperty(state, veteranStatusSchema.key, id, 'name'),
    })),
  );

  const disabilityStatusOptions = useSelector(state =>
    disabilityStatusIds?.map(id => ({
      value: id,
      label: getEntityProperty(state, disabilityStatusSchema.key, id, 'name'),
    })),
  );

  const sexualOrientationOptions = useSelector(state =>
    sexualOrientationIds?.map(id => ({
      value: id,
      label: getEntityProperty(state, sexualOrientationSchema.key, id, 'name'),
    })),
  );

  const genderSourceOptions = useSelector(state =>
    demographicSourceIds
      // exclude the race ethinicty sources and veteran status sources from the dropdown
      ?.filter(id =>
        getEntityProperty(
          state,
          demographicSourceSchema.key,
          id,
          'applies_to_gender',
        ),
      )
      .map(id => ({
        value: id,
        label: getEntityProperty(
          state,
          demographicSourceSchema.key,
          id,
          'name',
        ),
      })),
  );

  const raceEthnicitySourceOptions = useSelector(state =>
    demographicSourceIds
      // exclude the gender sources and race ethinicty sources from the dropdown
      ?.filter(id =>
        getEntityProperty(
          state,
          demographicSourceSchema.key,
          id,
          'applies_to_race_ethnicity',
        ),
      )
      .map(id => ({
        value: id,
        label: getEntityProperty(
          state,
          demographicSourceSchema.key,
          id,
          'name',
        ),
      })),
  );

  const veteranStatusSourceOptions = useSelector(state =>
    demographicSourceIds
      // exclude the gender sources and veteran status sources from the dropdown
      ?.filter(id =>
        getEntityProperty(
          state,
          demographicSourceSchema.key,
          id,
          'applies_to_veteran_status',
        ),
      )
      .map(id => ({
        value: id,
        label: getEntityProperty(
          state,
          demographicSourceSchema.key,
          id,
          'name',
        ),
      })),
  );

  return (
    <div className='ContactDiversityField row'>
      {hasDiversity && genderIds?.length > 0 ? (
        <div className='col-sm-6 u-marginBottom-8'>
          <ConditionalTooltipTrigger
            nested={true}
            placement='top'
            tooltip={getSelfReportedTooltip(
              candidateName,
              userIsCandidate,
              genderSelfReported,
              'gender',
              demographicsLastUpdatedAt?.gender,
            )}
          >
            <AdvancedSelectField
              className='ContactDiversityField__gender u-marginBottom-n'
              fieldState={fieldState.getNestedField('gender_id')}
              isClearable={true}
              isDisabled={!userIsCandidate && genderSelfReported}
              label={userIsCandidate ? 'What is your gender?' : 'Gender'}
              onChange={handleNestedFieldChange}
              options={genderOptions}
              placeholder='Select Gender...'
            />
          </ConditionalTooltipTrigger>
          {!userIsCandidate &&
          hasDemographicSource &&
          genderSourceOptions.length &&
          fieldState.getNestedField('gender_id').getValue() ? (
            <div className='u-flex u-flexAlign-c'>
              <SourceIndicator isDisabled={genderSelfReported} />
              <MultiValueInputField
                canUseCustomOptions={false}
                className='ContactDiversityField__genderSources u-marginBottom-n u-width-100'
                fieldState={fieldState.getNestedField('gender_source_ids')}
                isDisabled={genderSelfReported}
                onChange={handleNestedFieldChange}
                options={genderSourceOptions}
                placeholder='+ Add Gender Source'
                shouldRenderAsInput={false}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {hasDiversity && raceEthnicityIds?.length > 0 ? (
        <div className='col-sm-6 u-marginBottom-8'>
          <ConditionalTooltipTrigger
            nested={true}
            placement='top'
            tooltip={getSelfReportedTooltip(
              candidateName,
              userIsCandidate,
              raceEthnicitySelfReported,
              'race/ethnicity',
              demographicsLastUpdatedAt?.raceEthnicity,
            )}
          >
            <MultiValueInputField
              canUseCustomOptions={false}
              className='ContactDiversityField__raceEthnicity u-marginBottom-n'
              fieldState={fieldState.getNestedField('race_ethnicity_ids')}
              isDisabled={!userIsCandidate && raceEthnicitySelfReported}
              label={
                userIsCandidate
                  ? 'What is your racial or ethnic identification?'
                  : 'Race-Ethnicity'
              }
              onChange={handleNestedFieldChange}
              options={raceEthnicityOptions}
              placeholder='Select Race-Ethnicity...'
            />
          </ConditionalTooltipTrigger>
          {!userIsCandidate &&
          hasDemographicSource &&
          raceEthnicitySourceOptions.length &&
          fieldState.getNestedField('race_ethnicity_ids').getValue().length ? (
            <div className='u-flex u-flexAlign-c'>
              <SourceIndicator isDisabled={raceEthnicitySelfReported} />
              <MultiValueInputField
                canUseCustomOptions={false}
                className='ContactDiversityField__raceEthnicitySources u-marginBottom-n u-width-100'
                fieldState={fieldState.getNestedField(
                  'race_ethnicity_source_ids',
                )}
                isDisabled={raceEthnicitySelfReported}
                onChange={handleNestedFieldChange}
                options={raceEthnicitySourceOptions}
                placeholder='+ Add Race-Ethnicity Source'
                shouldRenderAsInput={false}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {hasDiversity && sexualOrientationIds?.length > 0 ? (
        <div className='col-sm-6'>
          <ConditionalTooltipTrigger
            nested={true}
            placement='top'
            tooltip={getSelfReportedTooltip(
              candidateName,
              userIsCandidate,
              sexualOrientationSelfReported,
              'sexual orientation',
              demographicsLastUpdatedAt?.sexualOrientation,
            )}
          >
            <AdvancedSelectField
              className='ContactDiversityField__sexualOrientation'
              fieldState={fieldState.getNestedField('sexual_orientation_id')}
              isClearable={true}
              isDisabled={!userIsCandidate && sexualOrientationSelfReported}
              label={
                userIsCandidate
                  ? 'What is your sexual orientation?'
                  : 'Sexual Orientation'
              }
              onChange={handleNestedFieldChange}
              options={sexualOrientationOptions}
              placeholder='Select Sexual Orientation...'
            />
          </ConditionalTooltipTrigger>
        </div>
      ) : null}
      {hasDiversityBirthYear ? (
        <div className='col-sm-6'>
          <AdvancedSelectField
            className='ContactDiversityField__birthYear'
            fieldState={fieldState.getNestedField('birth_year')}
            isClearable={true}
            label='Birth Year'
            onChange={handleNestedFieldChange}
            options={yearOptions}
            placeholder='Select birth year...'
          />
        </div>
      ) : null}
      {disabilityStatusIds && disabilityStatusIds.length > 0 ? (
        <div className='col-sm-6'>
          <ConditionalTooltipTrigger
            nested={true}
            placement='top'
            tooltip={getSelfReportedTooltip(
              candidateName,
              userIsCandidate,
              disabilityStatusSelfReported,
              'disability status',
              demographicsLastUpdatedAt?.disabilityStatus,
            )}
          >
            <AdvancedSelectField
              className='ContactDiversityField__disabilityStatus'
              fieldState={fieldState.getNestedField('disability_status_id')}
              isClearable={true}
              isDisabled={!userIsCandidate && disabilityStatusSelfReported}
              label={
                userIsCandidate
                  ? 'What is your disability status?'
                  : 'Disability Status'
              }
              onChange={handleNestedFieldChange}
              options={disabilityStatusOptions}
              placeholder='Select Disability Status...'
              shouldRenderAsInput={false}
            />
          </ConditionalTooltipTrigger>
        </div>
      ) : null}
      {veteranStatusIds && veteranStatusIds.length > 0 ? (
        <div className='col-sm-6'>
          <ConditionalTooltipTrigger
            nested={true}
            placement='top'
            tooltip={getSelfReportedTooltip(
              candidateName,
              userIsCandidate,
              veteranStatusSelfReported,
              'veteran status',
              demographicsLastUpdatedAt?.veteranStatus,
            )}
          >
            <AdvancedSelectField
              className='ContactDiversityField__veteranStatus u-marginBottom-n'
              fieldState={fieldState.getNestedField('veteran_status_id')}
              isClearable={true}
              isDisabled={!userIsCandidate && veteranStatusSelfReported}
              label={
                userIsCandidate
                  ? 'What is your veteran status?'
                  : 'Veteran Status'
              }
              onChange={handleNestedFieldChange}
              options={veteranStatusOptions}
              placeholder='Add Veteran Status'
              shouldRenderAsInput={false}
            />
          </ConditionalTooltipTrigger>
          {!userIsCandidate &&
          hasDemographicSource &&
          veteranStatusSourceOptions.length &&
          fieldState.getNestedField('veteran_status_id').getValue() ? (
            <div className='u-flex u-flexAlign-c '>
              <SourceIndicator isDisabled={veteranStatusSelfReported} />
              <MultiValueInputField
                canUseCustomOptions={false}
                className='ContactDiversityField__veteranStatusSources u-width-100 u-marginBottom-n'
                fieldState={fieldState.getNestedField(
                  'veteran_status_source_ids',
                )}
                isDisabled={veteranStatusSelfReported}
                onChange={handleNestedFieldChange}
                options={veteranStatusSourceOptions}
                placeholder='+ Add Veteran Status Source'
                shouldRenderAsInput={false}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

ContactDiversityField.propTypes = {
  /**
   * Candidate name
   */
  candidateName: PropTypes.string,

  /**
   * Last updated time of Demographics field
   */
  demographicsLastUpdatedAt: PropTypes.shape({
    disabilityStatus: PropTypes.string,
    gender: PropTypes.string,
    raceEthnicity: PropTypes.string,
    sexualOrientation: PropTypes.string,
    veteranStatus: PropTypes.string,
  }),

  demographicSourceIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * A list of disabilityStatus ids from the tenant. Only render the
   * disability status select if the tenant has disability status options
   */
  disabilityStatusIds: PropTypes.arrayOf(PropTypes.number),

  disabilityStatusSelfReported: PropTypes.bool.isRequired,
  /**
   * The field state containing the nested value.
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * A list of gender ids from the tenant. Only render the
   * gender select if the tenant has gender options
   */
  genderIds: PropTypes.arrayOf(PropTypes.number),
  genderSelfReported: PropTypes.bool.isRequired,

  /**
   * Called when a nested field is changed.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * True to display demographic source fields
   */
  hasDemographicSource: PropTypes.bool,
  /**
   * True to display diversity section on contact page
   */
  hasDiversity: PropTypes.bool,

  /**
   * Determines if the flag for birth year is on or not.
   */
  hasDiversityBirthYear: PropTypes.bool,

  /**
   * A list of race-ethnicity ids from the tenant. Only render the
   * race ethnicity select if the tenant has race ethnicity options
   */
  raceEthnicityIds: PropTypes.arrayOf(PropTypes.number),

  raceEthnicitySelfReported: PropTypes.bool.isRequired,

  sexualOrientationIds: PropTypes.arrayOf(PropTypes.number),
  sexualOrientationSelfReported: PropTypes.bool.isRequired,

  /**
   * A list of veteranStatus ids from the tenant. Only render the
   * veteran status select if the tenant has veteran status options
   */
  veteranStatusIds: PropTypes.arrayOf(PropTypes.number),
  veteranStatusSelfReported: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('ContactDiversityField(enhanced)'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', (name = 'ContactDiversityForm', contact) =>
    FieldState.createNested(
      name,
      [
        AdvancedSelectField.createFieldState(
          'gender_id',
          contact.get('gender'),
        ),
        MultiValueInputField.createFieldState(
          'gender_source_ids',
          contact.get('gender_sources')?.toJS(),
        ),
        MultiValueInputField.createFieldState(
          'race_ethnicity_ids',
          contact.get('race_ethnicities')?.toJS(),
        ),
        MultiValueInputField.createFieldState(
          'race_ethnicity_source_ids',
          contact.get('race_ethnicity_sources')?.toJS(),
        ),
        AdvancedSelectField.createFieldState(
          'sexual_orientation_id',
          contact.get('sexual_orientation'),
        ),
        AdvancedSelectField.createFieldState(
          'birth_year',
          contact.get('birth_year'),
        ),
        AdvancedSelectField.createFieldState(
          'disability_status_id',
          contact.get('disability_status'),
        ),
        AdvancedSelectField.createFieldState(
          'veteran_status_id',
          contact.get('veteran_status'),
        ),
        MultiValueInputField.createFieldState(
          'veteran_status_source_ids',
          contact.get('veteran_status_sources')?.toJS(),
        ),
      ],
      null,
      fieldState => ({
        ...fieldState,
        // track demographic source fields if we have demographic category fields
        gender_source_ids: fieldState.gender_id
          ? fieldState.gender_source_ids
          : [],
        race_ethnicity_source_ids:
          fieldState.race_ethnicity_ids.length > 0
            ? fieldState.race_ethnicity_source_ids
            : [],
        veteran_status_source_ids: fieldState.veteran_status_id
          ? fieldState.veteran_status_source_ids
          : [],
      }),
    ),
  ),
  withNestedFieldChangeHandler,
  withTenantOptionsEntityIdsFetched({
    schema: demographicSourceSchema,
    entityIdsPropName: 'demographicSourceIds',
  }),
  withTenantOptionsEntityIdsFetched({
    schema: genderSchema,
    entityIdsPropName: 'genderIds',
  }),
  withTenantOptionsEntityIdsFetched({
    schema: sexualOrientationSchema,
    entityIdsPropName: 'sexualOrientationIds',
  }),
  withTenantOptionsEntityIdsFetched({
    schema: raceEthnicitySchema,
    entityIdsPropName: 'raceEthnicityIds',
  }),
  withTenantOptionsEntityIdsFetched({
    schema: veteranStatusSchema,
    entityIdsPropName: 'veteranStatusIds',
  }),
  withTenantOptionsEntityIdsFetched({
    schema: disabilityStatusSchema,
    entityIdsPropName: 'disabilityStatusIds',
  }),
  withFeatureCheck('feature.diversity.birth_year', 'hasDiversityBirthYear'),
  withFeatureCheck('feature.demographic_source', 'hasDemographicSource'),
  withFeatureCheck('feature.diversity', 'hasDiversity'),
)(ContactDiversityField);

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  compose,
  componentFromProp,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import identity from '@thrivetrm/ui/utilities/identity';
import getSearchProperty from '../selectors/getSearchProperty';

export default compose(
  setDisplayName('SearchProperty'),
  setPropTypes({
    /**
     * The type of component to render (default to a <span />)
     */
    component: PropTypes.string.isRequired,

    /**
     * A function that can format or modify the search property value.
     * By default an identity function is used so the property is
     * rendered as-is, unmodified.
     */
    formatter: PropTypes.func.isRequired,

    /**
     * The name of the property to render (will be passed to the formater first)
     */
    propertyName: PropTypes.string.isRequired,

    /**
     * The search ID of the search to show the title of.
     */
    searchId: PropTypes.number.isRequired,
  }),
  defaultProps({
    formatter: identity,
    component: 'span',
  }),
  connect(
    (state, { className, formatter, propertyName, searchId }) => ({
      children: formatter(getSearchProperty(state, searchId, propertyName)),
      className: classnames(
        'SearchProperty',
        `SearchProperty--${propertyName}`,
        className,
      ),
    }),
    {},
  ),
  mapProps(({ // eslint-disable-next-line no-unused-vars
    formatter, propertyName, searchId, ...props }) => ({
    ...props,
  })),
)(componentFromProp('component'));

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Toast from '@thrivetrm/ui/components/Toast';
import { removeToast } from './toastNotificationsSlice';

const ToastNotifications = () => {
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toastNotifications);

  return (
    <TransitionGroup className='ToastNotifications'>
      {toasts.map(toast => (
        <CSSTransition
          appear={true}
          classNames='ToastNotifications__transition'
          key={toast.id}
          timeout={200}
        >
          <Toast
            onClose={() => dispatch(removeToast(toast.id))}
            status={toast.status}
            title={toast.title}
          >
            {toast.message}
          </Toast>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default ToastNotifications;

import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import * as compensationActions from '../actions';
import CompensationField from './CompensationField';
import mapCompensationIdToCompensation from './mapCompensationIdToCompensation';
import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';
import { NAME_BOARD_MEMBER, NAME_EMPLOYMENT } from '../constants';

export const createRootFieldState = ({ compensation }) =>
  CompensationField.createFieldState('compensation', compensation);

export const handleSubmit = (formState, props) => {
  const {
    compensation,
    compensationActions: { createCompensation, updateCompensation },
    compensationName,
    contactId,
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (
    compensation || fromJS({ contact_id: contactId, name: compensationName })
  ).merge(fieldValue);

  const action = values.get('id') ? updateCompensation : createCompensation;

  action({
    contactId: contactId,
    compensation: values.toJS(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export const connectForm = compose(
  setPropTypes({
    compensationId: PropTypes.number,

    /**
     * The ID of the contact this compensation should be associated with. This is
     * required when creating a new compensation
     */
    contactId: requiredIf(
      PropTypes.number,
      ({ compensation }) => !compensation,
    ),

    compensationName: requiredIf(
      PropTypes.oneOf([NAME_EMPLOYMENT, NAME_BOARD_MEMBER]),
      ({ compensation }) => !compensation,
    ),
  }),
  mapCompensationIdToCompensation,
  connectTransactions,
  connect(null, dispatch => ({
    compensationActions: bindActionCreators(compensationActions, dispatch),
  })),
);

export const omitProps = [
  'contactId',
  'formState',
  'onSaved',
  'compensation',
  'compensationId',
  'compensationActions',
  'compensationName',
  'searchId',
  'transactionActions',
];

export default compose(
  setDisplayName('CompensationForm'),
  connectForm,
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'CompensationForm',
    omitProps: omitProps,
  }),
)(CompensationField);

import { branch, compose, renderNothing, setDisplayName } from 'recompose';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import withFeatureCheck from './withFeatureCheck';

/**
 * An HOC that only renders the base component if the current user has the feature enabled.
 * @param {String} featureName The name of the feature to check.
 * @returns {Function} A function that creates a higher order component that includes a
 *   `hasFeature` prop which will be true or false, depending on whether the current user
 *   has the feature specified.
 */
export default featureName =>
  compose(
    setDisplayName('hideIfFeatureNotEnabled'),
    withFeatureCheck(featureName, 'hasFeature'),
    branch(({ hasFeature }) => !hasFeature, renderNothing),
    withPropsRemoved('hasFeature'),
  );

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';

function SpecialNeeds({ contact }) {
  const data = contact.get('data');

  if (!data.get('special_needs')) {
    return null;
  }
  return (
    <div className='row'>
      <div className='ContactSpecialNeeds editable-section col-sm-12'>
        <h3 className='editable-section-header'>
          Additional Job Preferences (Entered by Contact)
        </h3>
        <div>
          <ExpandableText
            characterLimit={300}
            content={data.get('special_needs')}
            isSanitizedHtml={true}
          />
        </div>
      </div>
    </div>
  );
}

SpecialNeeds.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      special_needs: PropTypes.string,
    }).isRequired,
  }),
};

export default SpecialNeeds;

import fetchEntity from 'modules/entities/actions/fetchEntity';
import routes from 'modules/routing/routes';
import getTenantId from 'modules/tenant/selectors/getTenantId';
import customerConfigurationSchema from '../schemas/customerConfiguration';

/**
 * Creates an action for fetching the customer configuration.
 */
export default () => (dispatch, getState) =>
  dispatch(
    fetchEntity({
      entityType: customerConfigurationSchema.key,
      id: getTenantId(getState()),
      responseSchema: { customer_configuration: customerConfigurationSchema },
      url: routes.api_v1_customers_configuration(),
    }),
  );

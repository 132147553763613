import React from 'react';
import { useSelector } from 'react-redux';
import ErrorBoundary from 'modules/core/components/ErrorBoundary';
import RecordIndexTable from './components/RecordIndexTable';
import RecordIndexBulkActions from './components/RecordIndexBulkActions';
import RecordIndexPageV5Header from './RecordIndexPageV5Header';
import RecordIndexSidebarV5 from './components/RecordIndexSidebarV5';

const RecordIndexPageV5 = () => {
  const { selectedRecordIds } = useSelector(state => state.recordIndex);

  return (
    <div className='RecordIndexPage'>
      <ErrorBoundary>
        <RecordIndexPageV5Header />
      </ErrorBoundary>
      <div className='u-flex'>
        <ErrorBoundary>
          <RecordIndexSidebarV5 />
        </ErrorBoundary>
        <ErrorBoundary>
          <RecordIndexTable />
        </ErrorBoundary>
      </div>
      {selectedRecordIds?.length ? (
        <ErrorBoundary>
          <RecordIndexBulkActions />
        </ErrorBoundary>
      ) : null}
    </div>
  );
};

export default RecordIndexPageV5;

import { NAME } from '../constants';
/**
 * Gets the addresses that belong to a parent.
 * @param {Object} state
 * @param {Number} parentId The parent ID
 * @param {Number} parentType The parent type ("Company", "Contact")
 * @returns {Immutable.List.<Number>} An immutable list of the address IDs that belong to the
 *   contact
 */
export default (state, parentType, parentId) =>
  state[NAME].getIn(['addressLists', parentType, parentId]);

import { connect } from 'react-redux';

/**
 * A Higher order component that sorts a list of notes by pinned
 as the primary sort, and timestamp as a the secondary sort.
 */
export default connect((state, { notes }) => {
  const sortedByDate =
    notes &&
    notes
      .sortBy(note => note.get('noted_on') || note.get('created_at'))
      .reverse();
  const sortedByPinned =
    sortedByDate && sortedByDate.sortBy(note => !note.get('pinned'));

  return { notes: sortedByPinned };
});

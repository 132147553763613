import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const SearchClosedTimeStatsListItem = ({ label, openMoment, value }) => {
  const diffMoment = moment.utc(value, moment.ISO_8601);

  if (!diffMoment.isValid()) {
    return null;
  }

  return (
    <li>
      <span className='description'>{label}</span>
      <span className='value'>{diffMoment.diff(openMoment, 'days')}</span>
    </li>
  );
};

SearchClosedTimeStatsListItem.propTypes = {
  label: PropTypes.string,

  openMoment: (props, propName, componentName) => {
    const { [propName]: openMoment } = props;

    if (!moment.isMoment(openMoment)) {
      return new Error(
        `Invalid prop \`openMoment\` supplied to \`${componentName}\`. ` +
          'Expected a moment instance.',
      );
    }

    if (!openMoment.isValid()) {
      return new Error(
        `Invalid prop \`openMoment\` supplied to \`${componentName}\`. ` +
          'Expected a valid moment instance.',
      );
    }

    return null;
  },

  value: PropTypes.string,
};

export default SearchClosedTimeStatsListItem;

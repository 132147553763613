import { PARENT_TYPES } from '../constants';
import getIntroductionList from './getIntroductionList';

/**
 * Determines if a list of introductions should be fetched.
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CONTACT or PARENT_SEARCH)
 * @param {Number} parentId The parent ID (a contact ID or search ID, depending on
 *   the value of `parentType`)
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default (state, parentType, parentId) => {
  if (!PARENT_TYPES.includes(parentType)) {
    throw new Error(`Invalid parentType: ${parentType}`);
  }

  if (!parentId) {
    return false;
  }

  const introductions = getIntroductionList(state, parentType, parentId);

  if (!introductions) {
    return true;
  }

  if (
    introductions.getIn(['_meta', 'isFetching']) ||
    introductions.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return (
    !introductions.has('ids') ||
    Boolean(introductions.getIn(['_meta', 'isInvalidated']))
  );
};

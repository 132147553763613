import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, onlyUpdateForPropTypes, setDisplayName } from 'recompose';
import getContact from 'modules/contacts/selectors/contacts/getContact';
import ContactIconLinkList from 'modules/contacts/components/ContactIconLinkList';
import ContactAvatar from 'modules/contacts/components/ContactAvatar';
import getContactIdFromTask from '../selectors/getContactIdFromTask';

/**
 * Renders a contact's avatar, name with link to profile, and social links.
 */
export const AssociatedContact = ({ contact }) => {
  const contactId = contact && contact.get('id');
  const contactInfo = [
    'phone',
    'preferred_email',
    'work_email',
    'twitter_handle',
    'linkedin_url',
    'github_url',
    'other_url',
    'skype_handle',
    'mobile_phone',
    'work_phone',
  ];
  const showContactLinks = contactInfo.find(
    info => contact && contact.get(info),
  );
  if (contactId) {
    return (
      <div className='tasks-associated-contact u-flex u-flexAlign-c u-margin-n'>
        <ContactAvatar url={contact.get('avatar_url')} />
        <div className='tasks-associated-contact-info u-marginLeft-16'>
          <a
            className='tasks-associated-contact-link'
            href={`/contacts/${contact.get('id')}`}
          >
            {contact.get('full_name')}
          </a>
          {showContactLinks && <ContactIconLinkList contactId={contactId} />}
        </div>
      </div>
    );
  }
  return null;
};

AssociatedContact.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    avatar_url: PropTypes.string,
    full_name: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default compose(
  setDisplayName('AssociatedContact(enhanced)'),
  connect((state, { taskId }) => ({
    contact: getContact(state, getContactIdFromTask(state, taskId)),
  })),
  onlyUpdateForPropTypes,
)(AssociatedContact);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import TaskForm from 'modules/tasks/components/TaskForm';
import TaskView from 'modules/tasks/components/TaskView';
import connectTaskActions from 'modules/tasks/components/connectTaskActions';
import Notification from './Notification';

/**
 * Renders a notification item of type `task`
 */
const NotificationItemTypeTask = ({
  allowDelete,
  allowEdit,
  handleCancel,
  handleDelete,
  handleEdit,
  handleSaved,
  isEditing,
  notification,
  showDetails,
  ...props
}) => {
  const taskId = notification.getIn(['data', 'resource', 'data', 'id']);
  const task = notification.getIn(['data', 'resource']);

  return (
    <Notification
      canDelete={allowDelete && task.getIn(['data', 'permissions', 'delete'])}
      canEdit={allowEdit && task.getIn(['data', 'permissions', 'edit'])}
      notification={notification}
      onDelete={handleDelete}
      onEdit={handleEdit}
      {...props}
    >
      {showDetails && !isEditing && <TaskView taskId={taskId} />}
      {isEditing && (
        <TaskForm
          asRichTextForm={true}
          draftStoragePath={{
            contact: task.contact,
            task: taskId,
          }}
          errorDisplay='tooltip'
          fieldLayout='wide'
          formActionProps={{
            submitButtonIcon: 'fa-check-square-o',
            submitLabel: 'Update Task',
            fillWidth: false,
          }}
          onCancel={handleCancel}
          onSaved={handleSaved}
          taskId={taskId}
        />
      )}
    </Notification>
  );
};

NotificationItemTypeTask.propTypes = {
  /**
   * True to allow the task item to be deleted if the user is permitted.
   */
  allowDelete: PropTypes.bool,

  /**
   * True to allow the task item to be edited inline if the user is permitted.
   */
  allowEdit: PropTypes.bool,

  handleCancel: PropTypes.func.isRequired,

  handleDelete: PropTypes.func.isRequired,

  handleEdit: PropTypes.func.isRequired,

  handleSaved: PropTypes.func.isRequired,

  /**
   * True to show the task form, false to show the task view.
   */
  isEditing: PropTypes.bool,

  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          id: PropTypes.number,
        }),
      }),
      type: PropTypes.oneOf(['task']),
    }),
  }).isRequired,

  /**
   * When true, the note details will be displayed.
   * @type {[type]}
   */
  showDetails: PropTypes.bool,

  taskActions: PropTypes.shape({
    deleteTask: PropTypes.func,
  }),
};

export default compose(
  withState('isEditing', 'setEditing', false),
  withHandlers({
    handleEdit: ({ setEditing }) => () => setEditing(true),
    handleCancel: ({ setEditing }) => () => setEditing(false),
    handleSaved: ({ setEditing }) => () => setEditing(false),
  }),
  connectTaskActions,
  withHandlers({
    handleDelete: ({ notification, taskActions }) => () => {
      taskActions.deleteTask({
        id: notification.getIn(['data', 'resource', 'data', 'id']),
      });
    },
  }),
)(NotificationItemTypeTask);

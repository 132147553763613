import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ClipboardTrigger from 'modules/core/componentsLegacy/ClipboardTrigger';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Overlay from 'react-bootstrap/lib/Overlay';

const RocketReachListItem = ({ icon, label, value }) => {
  const tooltipId = uniqueId();
  const tooltipTargetRef = useRef(null);
  const [tooltipTimeoutId, setTooltipTimeoutId] = useState(null);
  const [isTooltipVisible, showTooltip, hideTooltip] = useToggle(false);

  useEffect(() => {
    return () => clearTimeout(tooltipTimeoutId);
  });

  const handleCopy = () => {
    showTooltip();
    clearTimeout(tooltipTimeoutId);
    const newTooltipTimeoutId = setTimeout(hideTooltip, 1000);
    setTooltipTimeoutId(newTooltipTimeoutId);
  };

  return (
    <li className='rocketreach-li'>
      <div className='rocketreach-title rocketreach-attribute-label'>
        {label ? `${label}:` : null}
        {icon}
        <ClipboardTrigger onSuccess={handleCopy} text={value}>
          <i className='fa fa-clipboard pull-right' ref={tooltipTargetRef} />
        </ClipboardTrigger>
        <Overlay
          placement='left'
          show={isTooltipVisible}
          target={tooltipTargetRef.current}
        >
          <Tooltip id={tooltipId}>Copied!</Tooltip>
        </Overlay>
      </div>
      <div className='rocketreach-item-text'>{value}</div>
    </li>
  );
};

RocketReachListItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  value: PropTypes.string,
};

export default RocketReachListItem;

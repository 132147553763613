import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import ConnectionsCardGroup from 'modules/quick-view/connections-panels/ConnectionsCardGroup';
import { useGetContactQuery } from 'services/apiV1/contact';
import { useGetConnectionsQuery } from 'services/apiV1/connections';
import useQuickView from '../useQuickView';
import { QuickViewEmptyState, QuickViewErrorState } from '../QuickViewState';

const ConnectionsList = ({ connectionFilterOptions, ...rest }) => {
  const { pathParams } = useQuickView();
  const { contactId } = pathParams;
  const [loadMore, setLoadMore] = useState(false);

  const {
    data: contactData,
    isFetching: isLoadingContact,
  } = useGetContactQuery(contactId);

  const {
    data: Connections,
    error: ConnectionsError,
    isFetching: isLoadingConnections,
  } = useGetConnectionsQuery({
    contactId: contactId,
    params: {
      limit: loadMore ? null : 20,
      filter: {
        connection_strength_id: connectionFilterOptions?.ConnectionStrength?.filter(
          item => item.isChecked === true,
        )?.map(item => item.value),
        connection_type_id: connectionFilterOptions?.ConnectionType?.filter(
          item => item.isChecked === true,
        )?.map(item => item.value),
      },
    },
  });

  const hasConnections = Boolean(Connections?.totalCount);
  const isLoading = isLoadingContact || isLoadingConnections;
  const contactFirstName = contactData?.firstName || '';

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  return (
    <SidePanel.Body className='u-paddingTop-n' {...rest}>
      <LoadingContainer isLoading={isLoading} minHeight='80%' size='large'>
        {hasConnections && !isLoading && Connections?.totalCount ? (
          <ConnectionsCardGroup
            className='u-marginTop-24'
            connections={Connections.data}
            contactFirstName={contactFirstName}
            contactId={contactId}
            onLoadMore={handleLoadMore}
            totalCount={Connections.totalCount}
          />
        ) : null}

        {!hasConnections && !isLoading ? (
          <QuickViewEmptyState
            heading={`${contactFirstName} doesn’t have any Connections yet.`}
            image='avatarWithEnvelope'
            subHeading={
              <>
                To add a Connection for {contactFirstName} by clicking on the{' '}
                <b className='u-noWrap'>+ Add Connection</b> button above.
              </>
            }
          />
        ) : null}

        {ConnectionsError && !isLoading ? <QuickViewErrorState /> : null}
      </LoadingContainer>
    </SidePanel.Body>
  );
};

const connectionFilterOptionShape = PropTypes.shape({
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number,
});

ConnectionsList.propTypes = {
  connectionFilterOptions: PropTypes.shape({
    ConnectionStrength: PropTypes.arrayOf(connectionFilterOptionShape),
    ConnectionType: PropTypes.arrayOf(connectionFilterOptionShape),
  }),
};

export default ConnectionsList;

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setStatic } from 'recompose';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import SelectAllCheckbox from 'modules/searches/components/searchSummaryReport/SelectAllCheckbox';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { CONTACT_INFORMATION_FIELDS } from './constants';
import withContactSummaryReportFieldLabels from './withContactSummaryReportFieldLabels';

const handleFieldChange = (fieldState, onChange) => childFieldState => {
  const nextFieldState = fieldState.setNestedField(childFieldState);
  onChange(nextFieldState);
};

const handleToggleAll = (fieldState, onChange, hasContactAliases) => e => {
  const values = fieldState.getValue();

  // set all values to the checked value.
  const newValues = values.map(field =>
    // do not toggle aliases field if feature.contact_aliases is not enabled
    !hasContactAliases && field.getName() === 'aliases'
      ? field
      : field.setValue(e.target.checked),
  );

  onChange(fieldState.setValue(newValues));
};

/**
 * A field for selecting which contact information fields to include in the contact summary report.
 */
const ContactSummaryReportContactInfoField = ({
  disabled,
  fieldLabels,
  fieldState,
  onBlur,
  onChange,
  onFocus,
}) => {
  const hasContactAliases = useFeatureCheck('feature.contact_aliases');
  const excludeFields = {
    aliases: !hasContactAliases,
  };
  // Determine which fields should be displayed based on whether we're
  // showing expanded fields or not, and whether compenstaions is allowed.
  const displayedFields = CONTACT_INFORMATION_FIELDS.filter(
    // filter out the fields based on the flipper status
    fieldName => !excludeFields[fieldName],
  );

  const isAllSelected = displayedFields.every(fieldName =>
    fieldState.getNestedField(fieldName).getValue(),
  );

  return (
    <div className='ContactSummaryReportContactInfoField'>
      <h2>
        Contact Information
        <SelectAllCheckbox
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleToggleAll(fieldState, onChange, hasContactAliases)}
          onFocus={onFocus}
          value={isAllSelected}
        />
      </h2>
      <div className='row'>
        {displayedFields.map(
          fieldName =>
            fieldState.getNestedField(fieldName) && (
              <CheckboxField
                checked={fieldState.getNestedField(fieldName).getValue()}
                className='col-6'
                disabled={disabled}
                fieldState={fieldState.getNestedField(fieldName)}
                key={fieldName}
                onBlur={onBlur}
                onChange={handleFieldChange(fieldState, onChange)}
                onFocus={onFocus}
                text={fieldLabels[fieldName] || fieldName}
              />
            ),
        )}
      </div>
    </div>
  );
};

ContactSummaryReportContactInfoField.propTypes = {
  /**
   * True to disable the entire field.
   */
  disabled: PropTypes.bool,

  /**
   * a mapping of contact summary report field names to their labels
   */
  fieldLabels: PropTypes.objectOf(PropTypes.string),

  /**
   * The current value of this field.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is blurred
   */
  onBlur: PropTypes.func.isRequired,

  /**
   * Called when the fieldstate is changed
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Called when the field is focused.
   */
  onFocus: PropTypes.func.isRequired,
};

ContactSummaryReportContactInfoField.createFieldState = (
  name = 'contactInfo',
  { defaultValue = false } = {},
  validator,
  // Ignored, we implement our own.
  _convertToRaw,
  ...rest
) =>
  FieldState.createNested(
    name,
    // Create an FieldState that includes all fields initially toggled off.
    CONTACT_INFORMATION_FIELDS.map(fieldName =>
      CheckboxField.createFieldState(fieldName, defaultValue),
    ),
    // Passthrough any validator
    validator,
    // convertToRaw returns an array of field names based on whether isExpanded is true or false.
    fieldMap =>
      CONTACT_INFORMATION_FIELDS
        // Next filter out only fields that are set to true.
        .filter(fieldName => fieldMap[fieldName]),
    ...rest,
  );

export default compose(
  setDisplayName('ContactSummaryReportContactInfoField(enhanced)'),
  setStatic(
    'createFieldState',
    ContactSummaryReportContactInfoField.createFieldState,
  ),
  withFormGroup,
  withContactSummaryReportFieldLabels,
)(ContactSummaryReportContactInfoField);

import { mapProps } from 'recompose';

/**
 * Renames the `datum` property to `candidacyId`. For use with rendering cells for the
 * CandidaciesTableView.
 */
export default mapProps(({ datum: candidacyId, ...props }) => ({
  candidacyId: candidacyId,
  ...props,
}));

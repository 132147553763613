import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import getInvestmentListSelector from '../selectors/getInvestmentList';
import shouldFetchInvestmentListSelector from '../selectors/shouldFetchInvestmentList';
import fetchInvestmentListActionCreator from '../actions/fetchInvestmentList';

/**
 * Provides a `investmentList` prop -- the state of a list of investments
 * for a particular company ID. Fetches the list if needed.
 */
export default compose(
  setDisplayName('withInvestmentListFetched'),
  setPropTypes({
    companyId: PropTypes.number,
  }),
  connect(
    (state, props) => ({
      shouldFetchInvestmentList: shouldFetchInvestmentListSelector(
        state,
        props,
      ),
      investmentList: getInvestmentListSelector(state, props.companyId),
    }),
    dispatch => ({
      fetchInvestmentList: bindActionCreators(
        fetchInvestmentListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchInvestmentListIfNeeded: ({
      companyId,
      fetchInvestmentList,
      shouldFetchInvestmentList,
    }) => () => {
      if (shouldFetchInvestmentList) {
        fetchInvestmentList({ companyId: companyId });
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchInvestmentListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchInvestmentListIfNeeded();
    },
  }),
);

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useApiGet } from 'modules/core/hooks/useApi';
import {
  LABEL_COMPANY_PRESENTATION,
  LABEL_POSITION_DESCRIPTION,
} from 'modules/documents/constants';
import DocumentLink from 'modules/documents/components/DocumentLink';

export const SearchDetailsDocumentList = ({ searchId }) => {
  const [positionDescription, setPositionDescription] = useState(null);
  const [companyPresentation, setCompanyPresentation] = useState(null);

  const [
    loadDocuments,
    _isLoadingDocuments,
    _documentLoadError,
    documents,
  ] = useApiGet(`/api/v1/searches/${searchId}/documents`);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  useEffect(() => {
    if (documents?.documents?.length > 0) {
      const sortedDocs = [...documents.documents].sort((a, b) =>
        b.created_at.localeCompare(a.created_at),
      );

      const mostRecentCompanyPresentation = sortedDocs.find(
        doc => doc.document_label?.name === LABEL_COMPANY_PRESENTATION,
      );

      const mostRecentPositionDescription = sortedDocs.find(
        doc => doc.document_label?.name === LABEL_POSITION_DESCRIPTION,
      );

      if (mostRecentCompanyPresentation) {
        setCompanyPresentation(mostRecentCompanyPresentation);
      }

      if (mostRecentPositionDescription) {
        setPositionDescription(mostRecentPositionDescription);
      }
    }
  }, [documents]);

  return (
    <ul className='list-unstyled'>
      {companyPresentation ? (
        <li className='u-marginBottom-8'>
          <DocumentLink
            className='btn btn-sm btn-secondary-outline'
            documentUuid={companyPresentation.uuid}
            externalUrl={companyPresentation.external_url}
            key={companyPresentation.id}
          >
            {LABEL_COMPANY_PRESENTATION}
          </DocumentLink>
        </li>
      ) : null}
      {positionDescription ? (
        <li>
          <DocumentLink
            className='btn btn-sm btn-secondary'
            documentUuid={positionDescription.uuid}
            externalUrl={positionDescription.external_url}
            key={positionDescription.id}
          >
            {LABEL_POSITION_DESCRIPTION}
          </DocumentLink>
        </li>
      ) : null}
    </ul>
  );
};

SearchDetailsDocumentList.propTypes = {
  searchId: PropTypes.number.isRequired,
};

export default SearchDetailsDocumentList;

import getCalendarIntegrations from './getCalendarIntegrations';

/**
 * Gets information about a user's permitted calendar integration, if it exists.
 * This should contain: 'permitted', 'integrated', 'list' (a list of available
 * calendars by 'id' and 'name') and 'type' (currently either 'google' or 'outlook').
 *
 * @param  {*} state
 * @return {Immutable.Map}  A map of calendar options
 */
const getPermittedCalendarIntegration = state => {
  const calendarIntegrations = getCalendarIntegrations(state);
  return (
    calendarIntegrations &&
    calendarIntegrations.find(item => item.get('permitted'))
  );
};

export default getPermittedCalendarIntegration;

import { compose, withProps } from 'recompose';
import mapContactIdToContact from '../mapContactIdToContact';

/**
 * Maps a `contactId` propto an array of rating IDs (`ratingIds`)
 */
export default compose(
  mapContactIdToContact,
  withProps(({ contact }) => ({
    ratingIds: contact && contact.get('ratings'),
  })),
);

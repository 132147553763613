import getInterview from 'modules/interviews/selectors/getInterview';
import getMeeting from 'modules/meetings/selectors/getMeeting';

/**
 * Gets an appointment record by it's ID
 * @param {Object} state
 // * @param {Number} id The interview ID
 * @return {Immutable.Map|undefined} The interview record, if found.
 */
const getAppointment = (state, { interviewId, meetingId }) => {
  if (interviewId) {
    return getInterview(state, interviewId);
  }
  if (meetingId) {
    return getMeeting(state, meetingId);
  }

  return null;
};

export default getAppointment;

import React from 'react';
import PageHeader from 'modules/core/components/PageHeader';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import getCompanyColumnPreferences from 'modules/user/selectors/getCompanyColumnPreferences';

/**
 * Renders a blank page where Company Index 4.0 will go
 */
const CompanyIndexPage = () => (
  <div>
    <Helmet>
      <title>Companies</title>
    </Helmet>
    <PageHeader icon='company' title='Companies' />
    <strong>Welcome to Company Index 4.0!</strong>
  </div>
);

const mapState = state => ({
  columnPreference: getCompanyColumnPreferences(state),
});

export default connect(mapState)(CompanyIndexPage);

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import DateRange from '@thrivetrm/ui/components/DateRange';
import isAdmin from 'modules/auth/selectors/isAdmin';
import Notification from './Notification';

/**
 * Renders a notification with a resource type of `EventContact`
 */
const NotificationItemTypeEvent = ({ notification }) => {
  const eventData = notification.getIn(['data', 'resource', 'data']).toJS();

  const hasAdminPermissions = useSelector(isAdmin);
  /**
   * eventDescription and guestNotes are converted to strings
   * to be used within ExpandableText
   */
  const eventDescription = eventData.description
    ? ` <b class='u-textColor-gray60'>Event Description: </b>
    ${eventData.description}`
    : ``;
  const spacing = eventDescription ? `<br />` : '';
  const guestNotes = eventData.event_contact_notes
    ? `${spacing} <b class='u-textColor-gray60'>Guest Notes: </b> ${eventData.event_contact_notes}`
    : ``;

  return (
    <Notification
      notification={notification}
      primaryAction={
        hasAdminPermissions ? `/admin/events/${eventData.id}` : null
      }
      showTitleLink={hasAdminPermissions}
    >
      {eventData.end_time || eventData.start_time ? (
        <>
          <b className='u-textColor-gray60'>Event Date: </b>
          <DateRange
            endDate={eventData.end_time}
            format='dateTime'
            startDate={eventData.start_time}
            timezone={eventData.timezone}
          />
        </>
      ) : null}
      {eventData.role ? (
        <>
          <br /> <b className='u-textColor-gray60'>Role: </b>
          {eventData.role}
        </>
      ) : null}
      {eventData.status ? (
        <>
          <br /> <b className='u-textColor-gray60'>Status: </b>
          {eventData.status}
        </>
      ) : null}
      {eventDescription || guestNotes ? (
        <>
          <br />
          <ExpandableText
            characterLimit={300}
            content={`${eventDescription} ${guestNotes}`}
            isSanitizedHtml={true}
          />
        </>
      ) : null}
    </Notification>
  );
};

NotificationItemTypeEvent.propTypes = {
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          end_time: PropTypes.string,
          start_time: PropTypes.string,
          role: PropTypes.string,
          status: PropTypes.string,
          description: PropTypes.string,
          notes: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export default NotificationItemTypeEvent;

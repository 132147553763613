import { connect } from 'react-redux';
import getEmployeeCount from '../selectors/getEmployeeCount';

/**
 * A higher order component which maps a list of `employeeCountIds`
 * prop values to thier employeeCount record.
 */
export default connect((state, { employeeCountIds }) => ({
  employeeCounts: employeeCountIds
    ? employeeCountIds.map(id => getEmployeeCount(state, id))
    : [],
}));

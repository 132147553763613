import { connect } from 'react-redux';
import getInterview from '../selectors/getInterview';

/**
 * A higher order component which maps a `interviewId` prop value to it's interview record.
 */
export default connect(
  (state, { interviewId }) => ({
    interview: getInterview(state, interviewId),
  }),
  {},
);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { fromJS } from 'immutable';
import { compose, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import { connect, useSelector } from 'react-redux';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import getEntity from 'modules/entities/selectors/getEntity';
import contactAliasSchema from 'modules/tenant/schemas/contactAliases';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { companyAliasSchema } from 'modules/companies/companySchema';
import './AliasInput.scss';

const EntityTypes = {
  contact: contactAliasSchema.key,
  company: companyAliasSchema.key,
};

/**
 * An input for modifying a company alias.
 * This allows for changing the alias name, as well as marking it to be deleted.
 * When marked for deletion, the delete button can be clicked to "undo" the marking for deletion.
 */
const AliasInput = ({
  disabled,
  onDelete,
  onNameChange,
  onRestore,
  value: { _destroy, id, name },
}) => {
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const companyAliases = useSelector(state => state.entities)?.toJS()
    ?.companyAliases;
  const isEnriched = hasSkyminyrFeature && companyAliases?.[id]?.enriched;

  return (
    <Tooltip
      className='u-block'
      content={
        isEnriched
          ? 'This field is automatically enriched, and in sync with the golden record.'
          : null
      }
      size='large'
    >
      <div
        className={classnames('AliasInput', {
          'AliasInput--deleted': _destroy,
        })}
      >
        <div className='input-group'>
          <input
            className={classnames('form-control', {
              'u-textColor-red': Boolean(_destroy),
              'u-borderRadius': isEnriched,
            })}
            disabled={disabled || Boolean(_destroy) || isEnriched}
            onChange={onNameChange}
            type='text'
            value={name}
          />
          {!isEnriched ? (
            <span className='input-group-btn'>
              <ButtonSecondary
                className='AliasInput__actionButton'
                icon={_destroy ? 'refresh' : 'delete'}
                onClick={_destroy ? onRestore : onDelete}
              />
            </span>
          ) : null}
        </div>
      </div>
    </Tooltip>
  );
};

AliasInput.propTypes = {
  /**
   * True to disable this input.
   */
  disabled: PropTypes.bool,

  /**
   * Called when the delete button is clicked.
   */
  onDelete: PropTypes.func.isRequired,

  /**
   * Called when the alias name is changed.
   */
  onNameChange: PropTypes.func.isRequired,

  /**
   * Called when the restore button is clicked.
   */
  onRestore: PropTypes.func.isRequired,
  /**
   * The alias value.
   */
  value: PropTypes.shape({
    _destroy: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  setDisplayName('AliasInput(enhanced)'),
  setPropTypes({
    disabled: PropTypes.bool,

    /**
     * Called when the alias value is changed in some way (the name is changed, it is marked for
     * deletion, or unmarked for deletion)
     */
    onChange: PropTypes.func.isRequired,

    recordType: PropTypes.oneOf(['contact', 'company']),
    /**
     * The input value should either be the ID of the company alias, or an object describing the
     * alias. When editing an existing alias, initially passing in the ID is sufficent. This will
     * cause the name to be looked up on state. If at any point the value is changed, the onChange
     * callback will convert the ID to an object, so that we canspecifiy the new values.
     */
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        _destoy: PropTypes.bool,
        _new: PropTypes.bool,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
      }),
    ]),
  }),
  connect(
    (state, { recordType, value }) => ({
      /**
       * When the value is simply a number, it will be the alias ID -- in which case we need to
       * look up the alias record from state to get the actual name.
       */
      value:
        typeof value === 'number'
          ? (
              getEntity(state, EntityTypes[recordType], value) ||
              fromJS({ id: value })
            ).toJS()
          : value,
    }),
    {},
  ),

  // Note: At this point `value` is _always_ the complete record, i.e. : `{ id, name, ... }`

  withHandlers({
    /**
     * Called when the name of the alias is changed.
     */
    onNameChange: ({ onChange, value }) => e => {
      onChange({ ...value, name: e.target.value });
    },

    /**
     * Called when this item should be marked for deletion.
     */
    onDelete: ({ onChange, value }) => () => {
      // Add a `_destroy` attribute to the value, which when passed to the backend indicates
      // it should be removed from the parent (the company)
      onChange({ ...value, _destroy: true });
    },

    /**
     * Called when the item is already marked for deletion, and should be "restored" (i.e.
     * unmarked for deletion).
     */
    onRestore: ({ onChange, value: { _destroy, ...value } }) => () => {
      // Remove the `_destroy` attribute on the value.
      onChange({ ...value });
    },
  }),
)(AliasInput);

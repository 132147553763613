import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  withHandlers,
} from 'recompose';
import Dropzone from 'react-dropzone';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import FieldState from 'modules/forms/FieldState';
import AttachmentsListItem from './AttachmentsListItem';

class AttachmentsField extends Component {
  constructor(...args) {
    super(...args);
    this.previews = [];
  }

  componentWillUnmount() {
    this.previews.forEach(preview => URL.revokeObjectURL(preview));
  }

  setDropzoneRef = element => {
    this.dropzoneElement = element;
  };

  handleFilesDropped = droppedFiles => {
    const { onFilesDropped } = this.props;
    const previews = droppedFiles.map(file => URL.createObjectURL(file));
    previews.forEach(preview => this.previews.push(preview));
    onFilesDropped(droppedFiles, previews);
  };

  handleAttachFileClick = () => {
    this.dropzoneElement.open();
  };

  render() {
    const { children, disabled, fieldState, onNestedFieldChange } = this.props;

    if (disabled) {
      return (
        <div className='AttachmentsField AttachmentsField--disabled'>
          {children}
        </div>
      );
    }

    return (
      <Dropzone
        activeClassName='AttachmentsField--attachments-dropzone-active'
        className='AttachmentsField'
        disableClick={true}
        multiple={true}
        onDrop={this.handleFilesDropped}
        ref={this.setDropzoneRef}
      >
        {children}
        <div className='AttachmentsField__attachments-button-section'>
          <button
            className='AttachmentsField__attachments-button btn btn-link'
            onClick={this.handleAttachFileClick}
            type='button'
          >
            <i className='fa fa-paperclip' /> Add Attachments
          </button>
        </div>
        <div className='AttachmentsField__items'>
          {fieldState
            .getValue()
            .toArray()
            .map(document => (
              <AttachmentsListItem
                attachmentFieldState={document}
                destroy={document.getNestedFieldValue('destroy')}
                fileName={document.getNestedFieldValue('file_name')}
                fileSize={document.getNestedFieldValue('file_size')}
                key={document.getName()}
                onChange={onNestedFieldChange}
                url={document.getNestedFieldValue('url')}
              />
            ))}
        </div>
      </Dropzone>
    );
  }
}

AttachmentsField.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  onFilesDropped: PropTypes.func.isRequired,
  onNestedFieldChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('AttachmentsField(enhanced)'),
  setPropTypes({
    disabled: PropTypes.bool,
    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', (name, values) =>
    FieldState.createNested(
      name,
      values.map(document =>
        FieldState.createNested(document.get('id'), [
          FieldState.create('destroy', document.get('destroy')),
          FieldState.create('file_name', document.get('file_name')),
          FieldState.create('file_size', document.get('file_size')),
          FieldState.create('id', document.get('id')),
          FieldState.create('label', document.get('label')),
          FieldState.create('url', document.get('url')),
        ]),
      ),
    ),
  ),
  withHandlers({
    onNestedFieldChange: ({ fieldState, onChange }) => childFieldState => {
      if (
        childFieldState.getNestedField('file') &&
        childFieldState.getNestedField('destroy')
      ) {
        onChange(fieldState.removeNestedField(childFieldState.getName()));
        return;
      }
      onChange(fieldState.setNestedField(childFieldState));
    },
    onFilesDropped: ({ fieldState, onChange }) => (droppedFiles, previews) => {
      const updatedFieldState = droppedFiles.reduce(
        (nextFieldState, file, index) => {
          const id = uniqueId();
          return nextFieldState.setNestedField(
            FieldState.createNested(id, [
              FieldState.create('destroy', false),
              FieldState.create('file_name', file.name),
              FieldState.create('file_size', file.size),
              FieldState.create('label', 'Other'),
              FieldState.create('url', previews[index]),
              FieldState.create('file', file),
            ]),
          );
        },
        fieldState,
      );
      onChange(updatedFieldState);
    },
  }),
)(AttachmentsField);

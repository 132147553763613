import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, withProps, withState } from 'recompose';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';
import ContactLink from 'modules/contacts/components/ContactLink';

/**
 * Renders a link to a contact page. Clicking the link, howver, intially displays a popover
 * message telling the user that the contact is no longer a candidate in the current search.
 * The popover message contains a link which is the one that actually takes the user to the
 * contact profile page.
 */
export const MissingCandidacyContactLink = ({
  children,
  componentId,
  contactId,
  getScrollContainer,
  getTargetRef,
  hideOverlay,
  isOverlayShown,
  setTargetRef,
  toggleOverlay,
}) => (
  <div
    className='outreaches--missing-candidacy-contact-link'
    ref={setTargetRef}
  >
    <ContactLink
      contactId={contactId}
      onClick={toggleOverlay}
      useDefaultLinkBehavior={true}
    >
      {children}
    </ContactLink>
    {isOverlayShown && (
      <Overlay
        container={getScrollContainer}
        containerPadding={20}
        id={`${componentId}-overlay`}
        onHide={hideOverlay}
        placement='bottom'
        rootClose={true}
        show={isOverlayShown}
        target={getTargetRef}
      >
        <Popover
          className='outreaches--missing-candidacy-contact-link-popover'
          id={`${componentId}-popover`}
        >
          <div>This contact is no longer a candidate in this search.</div>
          <div>
            <ContactLink
              contactId={contactId}
              onClick={hideOverlay}
              target='_blank'
              useDefaultLinkBehavior={true}
            >
              Go to their full profile
            </ContactLink>
          </div>
        </Popover>
      </Overlay>
    )}
  </div>
);

MissingCandidacyContactLink.propTypes = {
  /**
   * Props provided by withScrollContainerRef (getScrollContainer, getTargetRef, setTargetRef)
   */
  ...withScrollContainerRef.propTypes,

  /**
   * The content to render inside the link as the link text
   */
  children: PropTypes.node.isRequired,

  /**
   * A unique component ID (provided by connected component via withComponentId())
   */
  componentId: PropTypes.string.isRequired,

  /**
   * The ID of the contac to link to.
   */
  contactId: PropTypes.number.isRequired,

  /**
   * A function that hides the overlay by setting `isOverlayShown` to false (provided by connected
   * component via withProps)
   */
  hideOverlay: PropTypes.func.isRequired,

  /**
   * A value indicating whether the overlay should be rendered or not (provided by connected
   * component via withState)
   */
  isOverlayShown: PropTypes.bool.isRequired,

  /**
   * A function that toggles the `isOverlayShown` value (provided by connected
   * component via withProps)
   */
  toggleOverlay: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('MissingCandidacyContactLink(enhanced)'),
  withState('isOverlayShown', 'setShowOverlay', false),
  withProps(({ isOverlayShown, setShowOverlay }) => ({
    hideOverlay: () => setShowOverlay(false),
    toggleOverlay: e => {
      e.preventDefault();
      setShowOverlay(!isOverlayShown);
    },
  })),
  withComponentId(),
  withScrollContainerRef,
)(MissingCandidacyContactLink);

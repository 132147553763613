import { NAME } from '../constants';
import { shouldFetchDefault } from '../../../actions/fetchIfNeeded';

/**
 * Determines if the user has a permission set
 * @param {Object} state
 * @param {String} key The permission key
 * @param {Boolean=false} defaultValue The value to return if the permission is not set/found.
 * @return {Boolean} Whether the current user has the requested permission
 */
const shouldFetchUserOptions = state =>
  shouldFetchDefault(state[NAME].get('userOptions'), {
    dataKey: 'data',
    metaKey: 'meta',
  });

export default shouldFetchUserOptions;

import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import RatingLegacy from 'modules/core/componentsLegacy/RatingLegacy';
import withRatingDefinitionsListFetched from './withRatingDefinitionsListFetched';
import mapRatingDefinitionsListToRatingDefinitions from './mapRatingDefinitionsListToRatingDefinitions';

/**
 * Augments the Rating component specifically for displaying/editing a rating for a contact.
 * Adds tooltips that use the rating defintions for content, and styles the component
 * based on the `isUserRated` value.
 */
export default compose(
  setDisplayName('ContactRating'),
  setPropTypes({
    isUserRated: PropTypes.bool.isRequired,
    value: PropTypes.number,
  }),
  defaultProps({
    isUserRated: false,
  }),
  withRatingDefinitionsListFetched,
  mapRatingDefinitionsListToRatingDefinitions,
  mapProps(
    ({
      className,
      fetchRatingDefinitionsList: _fetchRatingDefinitionsList,
      fetchRatingDefinitionsListIfNeeded: _fetchRatingDefinitionsListIfNeeded,
      // We don't need these props supplied by withRatingDefinitionsListFetched.
      isUserRated,
      ratingDefinitions,
      ratingDefinitionsList: _ratingDefinitionsList,
      shouldFetchRatingDefinitionsList: _shouldFetchRatingDefinitionsList,
      ...rest
    }) => ({
      className: classnames('ContactRating', className, {
        'ContactRating--isUserRated': isUserRated,
      }),
      tooltips: ratingDefinitions
        ? ratingDefinitions
            .map(ratingDefinition => ({
              value: ratingDefinition.get('score'),
              content: ratingDefinition.get('name'),
            }))
            .toArray()
        : null,
      ...rest,
    }),
  ),
)(RatingLegacy);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import QuerySelect from '../../../components/forms/QuerySelect';

import getById from '../selectors/contacts/getById';
import getQueriesById from '../selectors/contacts/getQueriesById';
import getContact from '../selectors/contacts/getContact';
import connectContactActions from './connectContactActions';
import ContactCreateModalButton from './ContactCreateModalButton';
import fetchContactIfNeeded from './fetchContactIfNeeded';

/**
 * A custom filter option for react-select. If present, the contact's
 * preferred_name will be included in their full_name, wrapped in double quotes.
 * This custom filter allows us to
 * 1. Ignore the quotes, so that users can filter on preferred_name
 * without having to type quotes.
 * 2. Ignore the preferred_name entirely. This way a user can still filter
 * on just first_name and last_name and receive matching results.
 */
const customNameFilter = (option, searchText) => {
  const query = searchText.trim().toLowerCase();
  const name = option.name.toLowerCase();
  const nameWithoutMiddleName = name.replace(/\s[a-z]{1}\./, '');
  const matchesPreferredName = name
    .replace(/"/g, '')
    .includes(query.replace(/"/g, ''));
  const matchesGivenName = name.replace(/ ".*?"/, '').includes(query);
  const matchesNameWithoutMiddleName = nameWithoutMiddleName
    .replace(/ ".*?"/, '')
    .includes(query);

  return (
    matchesPreferredName || matchesGivenName || matchesNameWithoutMiddleName
  );
};

/**
 * Generates the options to pass into ReactSelect, but prefers using the `full_name` field
 * as the name, if it's available.
 */
export const createOptions = ({ data, isMinimumLength }) =>
  isMinimumLength && data
    ? data.toJS().map(({ full_name: fullName, id, name }) => ({
        id: id,
        name: fullName || name,
      }))
    : [];

/**
 * A control for selecting zero or one contact.
 */
export const ContactSelect = ({
  allowCreate,
  componentId,
  contact,
  contactActions,
  contactsById,
  disabled,
  onChange,
  queriesById,
  queryParams,
  sidebarDisplay,
  transactionActions,
  transactions,
  ...props
}) => (
  <div
    className={classnames('ContactSelect', {
      'ContactSelect--is-creatable': allowCreate,
    })}
  >
    <QuerySelect
      {...props}
      clearTransaction={transactionActions.clearTransaction}
      createOptions={createOptions}
      disabled={disabled}
      filterOption={customNameFilter}
      isCreatable={false}
      isLoading={contact && contact.getIn(['_meta', 'isFetching'])}
      itemsById={contactsById}
      onChange={onChange}
      onCreate={contactActions.createContactsQuery}
      onDestroy={contactActions.destroyContactsQuery}
      onQuery={query =>
        contactActions.fetchContactsQuery({
          ...query,
          queryParams: queryParams,
        })
      }
      queriesById={queriesById}
      transactions={transactions}
      value={
        contact && contact.get('id')
          ? {
              id: contact.get('id'),
              name: contact.get('full_name') || contact.get('name'),
            }
          : contact
      }
    />
    {allowCreate && !disabled && (
      <ContactCreateModalButton
        onCreated={onChange}
        sidebarDisplay={sidebarDisplay}
      >
        <OverlayTrigger
          overlay={<Tooltip id={componentId}>Create New Contact</Tooltip>}
          placement='top'
        >
          <i className='fa fa-user-plus' />
        </OverlayTrigger>
      </ContactCreateModalButton>
    )}
  </div>
);

ContactSelect.propTypes = {
  allowCreate: PropTypes.bool,

  /**
   * A unique ID for the component, required for create button tooltip
   */
  componentId: PropTypes.string.isRequired,

  /**
   * The currently selected contact.
   */
  contact: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
    id: PropTypes.number,
    full_name: PropTypes.string,
    name: PropTypes.string,
  }),

  /**
   * The actions used for querying for contacts
   */
  contactActions: PropTypes.shape({
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    fetchContactsQuery: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * The map of all contact records by their IDs.
   */
  contactsById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.map,
    PropTypes.number,
  ).isRequired,

  /**
   * True to disable the input and prevent it from being changed.
   */
  disabled: PropTypes.bool,

  /**
   * Called when the selected contact changes
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The map of all contact queries by their unique IDs.
   */
  queriesById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.map,
    PropTypes.string,
  ).isRequired,

  queryParams: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  /**
   * If True the modal is sidebar display otherwise its a regular
   * modal
   */
  sidebarDisplay: PropTypes.bool,

  /**
   * The action for clearing a transaction state.
   */
  transactionActions: PropTypes.shape({
    clearTransaction: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * The map of all transactions in state by their ID.
   */
  transactions: transactionsState.isRequired,
};

ContactSelect.defaultProps = {
  allowCreate: false,
  disabled: false,
  queryParams: {},
  sidebarDisplay: false,
};

/**
 * The connected version of this component expects only a number as it's value. That number
 * should be the selected contacts ID.
 */
export default compose(
  setDisplayName('connect(ContactSelect)'),
  setPropTypes({
    onChange: PropTypes.func.isRequired,

    /**
     * The selected contact ID
     */
    value: PropTypes.number,
  }),
  connectContactActions,
  connectTransactions,
  withComponentId(),
  connect(
    (state, { onChange, value }) => ({
      contactId: value,
      contact: typeof value === 'number' ? getContact(state, value) : value,
      onChange: newValue => onChange((newValue && newValue.id) || newValue),
      queriesById: getQueriesById(state),
      contactsById: getById(state),
    }),
    {},
  ),
  fetchContactIfNeeded(),
)(ContactSelect);

import { connect } from 'react-redux';
import getDateGroup from '../selectors/getDateGroup';

/**
 * A Higher order component that filters a list of interviewIds by an date group (DATE_GROUP_PAST
 * or DATE_GROUP_UPCOMING).  This expects an `interviewIds` prop. If a `filterByDateGroup` prop
 * is also provided, the interviewIds will be filtered to only include interviews whose
 * `date` falls within the provided date group.  If no `filterByDateGroup` value is given,
 * the interviewIds are simply passed through.
 */
export default connect(
  (state, { filterByDateGroup, interviewIds }) => ({
    interviewIds:
      interviewIds && filterByDateGroup
        ? interviewIds.filter(
            id => getDateGroup(state, id) === filterByDateGroup,
          )
        : interviewIds,
  }),
  {},
);

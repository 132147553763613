/* eslint-disable react/prop-types */
import React from 'react';
import CompanySnapshot from 'modules/companies/components/CompanySnapshot';
import TagList from 'modules/core/components/TagList';
import SubsidiaryCompanyDeleteButton from './SubsidiaryCompanyDeleteButton';

export default [
  {
    key: 'primary_identifier',
    title: 'Name',
    isSortable: true,
    Cell: ({ datum: company }) => (
      <CompanySnapshot
        id={company.id}
        name={company.name}
        offLimitsReason={company.off_limits?.status}
        offLimitsSeverity={company.off_limits?.category}
        url={company.id}
      />
    ),
  },
  {
    key: 'location',
    title: 'Location',
    isSortable: true,
    Cell: ({ datum: company }) => company.location,
  },
  {
    key: 'industry_category_list',
    title: 'Industry',
    isSortable: false,
    Cell: ({ datum: company }) => (
      <TagList limit={1} tags={company.industry_category_list} />
    ),
  },
  {
    key: 'sector_list',
    title: 'Sector',
    isSortable: false,
    Cell: ({ datum: company }) => (
      <TagList limit={1} tags={company.sector_list} />
    ),
  },
  {
    key: 'latest_employee_count',
    title: 'Latest Employee Count',
    isSortable: true,
    Cell: ({ datum: company }) => company.latest_employee_count,
  },
  {
    key: 'latest_revenue',
    title: 'Latest Revenue',
    isSortable: true,
    Cell: ({ datum: company }) => company.latest_revenue,
  },
  {
    className: 'u-textAlign-c u-paddingRight-16',
    key: 'delete',
    title: 'Remove?',
    isSortable: false,
    Cell: ({ datum: company, parentCompanyId }) => (
      <SubsidiaryCompanyDeleteButton
        parentCompanyId={parentCompanyId}
        subsidiaryCompanyId={company.id}
      />
    ),
  },
];

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import TextArea from '@thrivetrm/ui/components/TextArea';
import classnames from 'classnames';
import {
  registerFilterInput,
  selectFilterInputData,
  selectHasRegisteredFilterInput,
  debouncedRequestRecordList,
  setAndValidateSingleFilterValue,
} from '../../recordIndexSlice';

const TextAreaFilter = ({ label, name }) => {
  const dispatch = useDispatch();

  const inputValue = useSelector(
    state => state.recordIndex.savedView.filters?.[name] || '',
  );
  const {
    inputRegex,
    isPairedInput,
    leadsPair,
    placeholder,
  } = useSelector(state => selectFilterInputData(state, name));

  const handleChange = value => {
    if (!inputRegex || (inputRegex && new RegExp(inputRegex).test(value))) {
      dispatch(
        setAndValidateSingleFilterValue({
          name: name,
          value: value,
        }),
      );
      return dispatch(debouncedRequestRecordList());
    }
    return null;
  };

  const hasRegisteredFilterInput = useSelector(state =>
    selectHasRegisteredFilterInput(state, name),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  const containerClass = classnames('u-marginTop-8', {
    'u-paddingRight-8': isPairedInput && leadsPair,
    'u-paddingLeft-8': isPairedInput && !leadsPair,
    'u-inlineBlock': isPairedInput,
  });

  return (
    <div
      className={containerClass}
      style={{
        width: isPairedInput ? '50%' : '100%',
      }}
    >
      <TextArea
        className='u-marginTop-8'
        label={label}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        value={inputValue}
        width='full'
      />
    </div>
  );
};

TextAreaFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

TextAreaFilter.defaultProps = {
  label: null,
};

export default TextAreaFilter;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';

/**
 * A field for editing the question answer
 */
class QuestionAnswerField extends PureComponent {
  static createFieldState(name = 'question_answer', questionAnswer) {
    const values = fromJS({
      answer_content: null,
      question_content: null,
    }).merge(questionAnswer);

    return FieldState.createNested(name, [
      FieldState.create('id', values.get('id')),
      FieldState.create('question_content', values.get('question_content')),
      TextAreaField.createFieldState(
        'answer_content',
        values.get('answer_content'),
      ),
    ]);
  }

  handleFieldChange = value => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(value));
  };

  render() {
    const {
      fieldState,

      // prevent onChange from being passed through to the input.
      onChange: _onChange,

      ...otherProps
    } = this.props;

    return (
      <TextAreaField
        {...otherProps}
        fieldState={fieldState.getNestedField('answer_content')}
        label={fieldState.getNestedFieldValue('question_content')}
        onChange={this.handleFieldChange}
        rows={4}
      />
    );
  }
}

QuestionAnswerField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,
};

export default QuestionAnswerField;

import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withPropsOnChange,
} from 'recompose';
import ContactAverageRating from 'modules/contacts/components/ratings/ContactAverageRating';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import ContactRatingsModalButton from './ContactRatingsModalButton';

/**
 * Renders a button that shows a contact's current average rating. When clicked,
 * the ContactRatingsModal is shown.
 */
export default compose(
  setDisplayName('ContactAverageRatingModalButton'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  }),
  withClassName('ContactAverageRatingModalButton'),
  withPropsOnChange(
    ['contactId', 'tooltipPlacement'],
    ({ contactId, tooltipPlacement }) => ({
      // For the content of the button, render the contact's average rating.
      children: (
        <ContactAverageRating
          contactId={contactId}
          notRatedText='Add a Rating'
          tooltipPlacement={tooltipPlacement}
        />
      ),

      // Render the button in a link style instead of the default button style.
      className: 'ContactAverageRatingModalButton btn btn-link',
    }),
  ),

  // Don't pass the tooltipPlacement prop down.
  mapProps(({ tooltipPlacement: _, ...props }) => props),
)(ContactRatingsModalButton);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setStatic } from 'recompose';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import RadioSelectField from 'modules/forms/components/RadioSelectField';
import {
  TYPE_BRIEF,
  TYPE_OVERVIEW,
  TYPE_EXPANDED,
  LAYOUT_LEADERSHIP,
} from './constants';

/**
 * A field for selecting the type
 * (["overview", "expanded", "brief", "leadership"])
 * for the search summary report.
 */

const SearchSummaryReportTypeField = ({ showLeadershipReport, ...props }) => {
  const DEFAULT_LAYOUTS = [
    {
      label: (
        <div>
          <span>Overview </span>
          <TooltipTrigger
            placement='bottom'
            tooltip='Overview of candidates grouped by stage'
          >
            <i className='fa fa-info-circle' />
          </TooltipTrigger>
        </div>
      ),
      value: TYPE_OVERVIEW,
    },
    {
      label: (
        <div>
          <span>Brief </span>
        </div>
      ),
      value: TYPE_BRIEF,
    },
    {
      label: (
        <div>
          <span>Expanded </span>
          <TooltipTrigger
            placement='bottom'
            tooltip='Summary of candidates with full-page candidate reports'
          >
            <i className='fa fa-info-circle' />
          </TooltipTrigger>
        </div>
      ),
      value: TYPE_EXPANDED,
    },
  ];

  const LEADERSHIP_REPORT = {
    label: <div>Status Report</div>,
    value: LAYOUT_LEADERSHIP,
  };

  const options = showLeadershipReport
    ? DEFAULT_LAYOUTS.concat(LEADERSHIP_REPORT)
    : DEFAULT_LAYOUTS;

  return (
    <div className='SearchSummaryReportTypeField'>
      <h3>Type</h3>
      <RadioSelectField {...props} options={options} />
    </div>
  );
};

SearchSummaryReportTypeField.propTypes = {
  showLeadershipReport: PropTypes.bool.isRequired,
};

SearchSummaryReportTypeField.createFieldState = (
  name = 'type',
  value = TYPE_OVERVIEW,
  ...rest
) => RadioSelectField.createFieldState(name, value, ...rest);

export default compose(
  setStatic('createFieldState', SearchSummaryReportTypeField.createFieldState),
  withFeatureCheck('report.leadership_report', 'showLeadershipReport'),
)(SearchSummaryReportTypeField);

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Form from '@thrivetrm/ui/components/Form';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import getSearchName from 'modules/searches/selectors/getSearchName';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import {
  useCreateCandidacyReferenceMutation,
  useCreateContactReferenceMutation,
} from 'services/apiV1/references';
import {
  useGetConnectionTypesQuery,
  getConnectionTypeLabel,
} from 'services/apiV1/connections';
import ReferenceFormV2, { FORM_LAYOUT } from './ReferenceFormV2';

const ReferenceAddFormV2 = ({
  candidacyId,
  contactId,
  layout,
  onCancel,
  onSuccess,
  searchId,
}) => {
  const dispatch = useDispatch();
  const hasConnectionFeature = useFeatureCheck('feature.connections_gate');
  const searchName = useSelector(state => getSearchName(state, searchId));
  const [
    createCandidacyReference,
    { isLoading: isCreatingCandidacyReference },
  ] = useCreateCandidacyReferenceMutation();
  const [
    createContactReference,
    { isLoading: isCreatingContactReference },
  ] = useCreateContactReferenceMutation();
  const { data: connectionTypes } = useGetConnectionTypesQuery();

  const handleSubmit = formState => {
    const payload = {
      reference: {
        contact_id: contactId,
        referee_id: formState.referee.id,
        description: formState.description,
        search_id: formState.relatedSearch?.id || null,
        connection_type_id: formState.connectionTypeId,
        referral_company_id: formState.referralCompany?.id || null,
        reference_type: formState.referenceType || null,
        /* set relationship field to same as connection type when the feature.connections_gate is enabled
         as the relationship field is hidden on the form and it can't be blank */
        relationship: hasConnectionFeature
          ? getConnectionTypeLabel(
              connectionTypes?.find(
                ({ id }) => id === formState.connectionTypeId,
              ),
              formState.contactName,
            )
          : formState.relationship,
      },
    };

    const createReference = candidacyId
      ? createCandidacyReference
      : createContactReference;

    createReference({
      candidacyId: candidacyId ?? null,
      contactId: contactId,
      payload: payload,
    })
      .unwrap()
      .then(() => {
        onSuccess?.();
        dispatch(toastSuccess('Successfully created reference'));
      })
      .catch(() => {
        dispatch(toastError('Could not create the reference'));
      });
  };

  return (
    <Form onSubmit={() => {}}>
      <ReferenceFormV2
        contactId={contactId}
        initialValues={{
          referee: null,
          referenceType: null,
          connectionTypeId: null,
          referralCompany: null,
          relatedSearch:
            searchId && searchName ? { id: searchId, name: searchName } : null,
          description: null,
          relationship: null,
        }}
        isCreatingOrUpdating={
          isCreatingCandidacyReference || isCreatingContactReference
        }
        layout={layout}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        searchId={searchId}
      />
    </Form>
  );
};

ReferenceAddFormV2.propTypes = {
  candidacyId: PropTypes.number,
  contactId: PropTypes.number,
  layout: PropTypes.oneOf(Object.values(FORM_LAYOUT)),
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  searchId: PropTypes.number,
};

export default ReferenceAddFormV2;

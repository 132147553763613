import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { connect } from 'react-redux';
import getContactProperty from '../../selectors/contacts/getContactProperty';
import ContactExternalLink from './ContactExternalLink';

/**
 * Maps a contact's phone fields the icon to use when rendering the link.
 */
const DEFAULT_ICONS = {
  phone: 'fa-phone',
  mobile_phone: 'fa-mobile',
};

/**
 * Renders a contact phone number as a link.
 */
export default compose(
  setDisplayName('ContactPhoneLink'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,

    /**
     * The icon to use. If `true`, it will be automatically selected based on the phone number
     * type. If false, no icon will be rendered, and a string value will allow a custom icon class.
     */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,

    /**
     * True to display the phone number value as the tooltip, false otherwise.
     */
    numberAsTooltip: PropTypes.bool.isRequired,

    /**
     * The phone number field to render: `phone` or `mobile_phone` or `work_phone`
     */
    type: PropTypes.oneOf(['phone', 'mobile_phone', 'work_phone']).isRequired,
  }),
  defaultProps({
    icon: true,
    numberAsTooltip: false,
    target: null,
    tooltip: 'Phone',
    type: 'phone',
  }),
  connect((state, { contactId, icon, numberAsTooltip, tooltip, type }) => {
    const phone = getContactProperty(state, contactId, type);

    return {
      children: phone,
      icon: icon === true ? DEFAULT_ICONS[type] : icon,
      url: phone && `tel:${phone}`,
      tooltip: numberAsTooltip ? phone : tooltip,
    };
  }, {}),

  // Prevent these from being passed into the ContactExternalLink and being inadvertantly
  // added to the componentClass as props.
  // eslint-disable-next-line no-unused-vars
  mapProps(({ contactId, numberAsTooltip, ...props }) => props),
)(ContactExternalLink);

import getEntity from 'modules/entities/selectors/getEntity';
import getTenantId from 'modules/tenant/selectors/getTenantId';
import customerConfigurationSchema from '../schemas/customerConfiguration';

/**
 * Gets the customerDetails configuration record for the current tenant.
 */

export default state =>
  getEntity(state, customerConfigurationSchema.key, getTenantId(state));

import { connect } from 'react-redux';
import getReference from '../selectors/getReference';

/**
 * A higher order component which maps a `referenceId` prop value to it's reference record.
 */
export default connect(
  (state, { referenceId }) => ({
    reference: getReference(state, referenceId),
  }),
  {},
);

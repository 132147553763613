import startTransaction from 'modules/transactions/actions/startTransaction';
import finishTransaction from 'modules/transactions/actions/finishTransaction';
import createContactReview from './createContactReview';
import {
  CONTACT_REVIEW_CREATE_FAILURE,
  CONTACT_REVIEW_CREATE_BULK_START,
  CONTACT_REVIEW_CREATE_BULK_SUCCESS,
  CONTACT_REVIEW_CREATE_BULK_FAILURE,
} from './constants';

const isFailureAction = ({ type }) => type === CONTACT_REVIEW_CREATE_FAILURE;

/**
 * Determines the correct action type based on the result
 * of all dispatched actions
 * @param  {Array<Object>} actions An array of actions
 * @return {String} An action type
 */
const resolutionTypeFor = actions => {
  if (actions.some(isFailureAction)) {
    return CONTACT_REVIEW_CREATE_BULK_FAILURE;
  }

  return CONTACT_REVIEW_CREATE_BULK_SUCCESS;
};

/**
 * Creates a bunch of contact reviews in a single action.
 * @param {Object} payload
 * @param {Array} payload.files An array of files to upload
 * @param {String} payload.parser Either `linkedin` or `sovren`. Tells the backend
 * how to parse the resume file.
 */
export default function createContactReviewsBulk(payload) {
  const { _ajax, files, network, parser, search, transactionId } = payload;

  // When submitting with multiple resume upload enabled, files come in as an
  // array of File objects e.g. [File, File] or [File].
  // With single upload, files come in as File.
  const transformFiles = files.length ? files : [files];

  const actionCreators = transformFiles.map(file =>
    createContactReview({
      _ajax: _ajax,
      parser: parser,
      file: file,
      search: search,
      network: network,
    }),
  );

  return dispatch => {
    if (transactionId) {
      dispatch(startTransaction(transactionId, payload));
    }

    dispatch({
      type: CONTACT_REVIEW_CREATE_BULK_START,
      payload: payload,
    });

    // Wait until all async actions have completed before dispatching success
    return Promise.all(actionCreators.map(dispatch)).then(actions => {
      const successfulActions = actions.filter(
        action => !isFailureAction(action),
      );
      const ids = successfulActions.map(action => action.payload.data.id);
      const allErrors = actions
        .map(action => action.payload.error)
        .filter(errorMsg => errorMsg);
      const payloadWithIdsAndErrors = {
        ...payload,
        error: allErrors[0],
        ids: ids,
      };

      dispatch({
        payload: payloadWithIdsAndErrors,
        type: resolutionTypeFor(actions),
      });

      if (transactionId) {
        dispatch(finishTransaction(transactionId, payloadWithIdsAndErrors));
      }

      return actions;
    });
  };
}

import getTalentPoolId from 'modules/contacts/selectors/contacts/getTalentPoolId';
import getPrimaryTitle from 'modules/contacts/selectors/contacts/getPrimaryTitle';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getJobTitle from '../selectors/getJobTitle';

export default state => contactId => {
  const talentPoolId = getTalentPoolId(state, contactId);

  return stringUtils.toSortableString(
    talentPoolId
      ? getJobTitle(state, talentPoolId)
      : getPrimaryTitle(state, contactId),
  );
};

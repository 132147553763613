import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

/**
 * A dropdown menu that shows a list of options available for sorting a dataset on.
 */
const SortByDropdownMenu = ({
  hasSortIndicator,
  isAscending,
  onChange,
  options,
  value,
}) => {
  const handleOptionClick = event => {
    event.preventDefault();
    onChange(event.currentTarget.dataset.value);
  };

  const getOptionSortIndicator = sortOption => {
    if (hasSortIndicator && sortOption === value) {
      const content = isAscending ? 'ascending' : 'descending';
      return <span>({content})</span>;
    }
    return null;
  };

  return (
    <ul className='dropdown-menu dropdown-menu-right'>
      <li className='dropdown-header' key='header'>
        Sort By
      </li>
      <li className='divider' key='seperator' role='separator' />
      {Object.keys(options).map(sortOption => (
        <li
          className={classnames({ active: sortOption === value })}
          key={sortOption}
        >
          <a
            data-value={sortOption}
            href={`#${sortOption}`}
            onClick={handleOptionClick}
          >
            {options[sortOption]} {getOptionSortIndicator(sortOption)}
          </a>
        </li>
      ))}
    </ul>
  );
};

SortByDropdownMenu.defaultProps = {
  hasSortIndicator: true,
  isAscending: false,
  value: null,
};

SortByDropdownMenu.propTypes = {
  /* eslint-disable-next-line react/boolean-prop-naming */
  hasSortIndicator: PropTypes.bool,

  /**
   * If provided as a boolean value, will show a caret indicating the current
   * sort direction.
   */
  isAscending: PropTypes.bool,

  /**
   * Called when the selected option is changed, with the key of the newly
   * selected option.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The list of available sort options (key-label pairs) to display.
   * i.e.:
   * ```
   * options={{
   *   a: 'Apple',
   *   b: 'Banana',
   * }}
   * ```
   * The key represents a particular option's underlying value, while the value represents the
   * label that will be rendered.
   */
  options: PropTypes.objectOf(PropTypes.string).isRequired,

  /**
   * The currently active/selected sort value (will be highlighted)
   */
  value: (props, propName, componentName) => {
    const { options, sortOptions, [propName]: value } = props;
    if (value && options && !options[value]) {
      return new Error(
        `Invalid prop \`value\` supplied to \`${componentName}\`, ` +
          `value must match a key in the prop \`options\`. \`${value}\` ` +
          `not found in: ["${Object.keys(sortOptions).join('", "')}"]`,
      );
    }

    return null;
  },
};

export default SortByDropdownMenu;

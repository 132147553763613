import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import Menu from '@thrivetrm/ui/components/Menu';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import {
  ALL_RECORDS_NETWORK_ID,
  setNetworkId,
  requestRecordList,
} from 'modules/recordIndex/recordIndexSlice';
import {
  toastSuccess,
  toastError,
} from 'modules/toast-notifications/toastNotificationsSlice';
import {
  useGetNetworksQuery,
  useDeleteNetworkMutation,
} from 'services/apiV1/networks';
import isSuperAdmin from 'modules/auth/selectors/isSuperAdmin';
import isAdmin from 'modules/auth/selectors/isAdmin';
import NetworkSidePanel from './NetworkSidePanel';

const RecordIndexSidebarNetworks = ({ className, pageName }) => {
  const { recordLabel, recordLabelPlural, savedView } = useSelector(
    state => state.recordIndex,
  );
  const dispatch = useDispatch();

  const isSuperAdminUser = useSelector(isSuperAdmin);
  const isAdminUser = useSelector(isAdmin);

  const [
    isNetworkSidePanelOpen,
    openNetworkSidePanel,
    closeNetworkSidePanel,
  ] = useToggle(false);
  const [
    isDeleteNetworkConfirmationOpen,
    openDeleteNetworkConfirmation,
    closeDeleteNetworkConfirmation,
  ] = useToggle(false);
  const [isCreateNetworkSelected, setIsCreateNetworkSelected] = useState(false);

  const { data: networks } = useGetNetworksQuery(recordLabel);
  const [deleteNetwork] = useDeleteNetworkMutation();

  const getNetworkItems = () => {
    const totalEntityCount = networks?.total_entity_count;
    const items = [
      <SelectMenu.Item key='all' value={ALL_RECORDS_NETWORK_ID}>
        {`All ${recordLabelPlural} (${totalEntityCount || 0})`}
      </SelectMenu.Item>,
    ];

    if (networks?.user_networks?.length > 0) {
      items.push(
        <SelectMenu.Heading key='privateNetworks'>
          My Networks
        </SelectMenu.Heading>,
      );

      networks?.user_networks.forEach(network =>
        items.push(
          <SelectMenu.Item key={network.id} value={network.id}>
            {`${network.name} (${network.network_members_count || 0})`}
          </SelectMenu.Item>,
        ),
      );
    }

    if (networks?.public_networks?.length > 0) {
      items.push(
        <SelectMenu.Heading key='publicNetworks'>
          Shared With Me
        </SelectMenu.Heading>,
      );

      networks?.public_networks.forEach(network => {
        const menuItemLabel = `${network.name}`;
        const menuItemUser = `${network.user?.name || 'Deactivated User'}`;
        const filterTerm = `${menuItemLabel} - ${menuItemUser}`;
        items.push(
          <SelectMenu.Item
            data-filter-term={filterTerm}
            key={network.id}
            style={{ height: '50px' }}
            value={network.id}
          >
            <div className='networkDetails'>
              <div className='menuItemLabel'>{menuItemLabel}</div>
              <div className='menuItemUser'>{menuItemUser}</div>
            </div>
          </SelectMenu.Item>,
        );
      });
    }

    const privateNetworks = networks?.private_network ?? [];
    if (privateNetworks.length > 0) {
      items.push(
        <SelectMenu.Heading key='superAdminNetwork'>
          Private Networks (SuperAdmin)
        </SelectMenu.Heading>,
      );

      privateNetworks.forEach(network => {
        const menuItemLabel = `${network.name}`;
        const menuItemUser = `${network.user?.name || 'Deactivated User'}`;
        const filterTerm = `${menuItemLabel} - ${menuItemUser}`;
        items.push(
          <SelectMenu.Item
            data-filter-term={filterTerm}
            key={network.id}
            style={{ height: '50px' }}
            value={network.id}
          >
            <div className='networkDetails'>
              <div className='menuItemLabel'>{menuItemLabel}</div>
              <div className='menuItemUser'>{menuItemUser}</div>
            </div>
          </SelectMenu.Item>,
        );
      });
    }
    return items;
  };

  const userSelectedNetwork = networks?.user_networks?.find(
    network => network.id === savedView.networkId,
  );

  let selectedNetworkDetail = {};
  if (isSuperAdminUser || isAdminUser) {
    const allNetworks = networks
      ? [
          ...networks.user_networks,
          ...networks.public_networks,
          ...(networks.private_network ?? []),
        ]
      : [];
    selectedNetworkDetail = allNetworks.find(
      network => network.id === savedView.networkId,
    );
  } else {
    selectedNetworkDetail = userSelectedNetwork;
  }

  const handleDeleteNetworkConfirm = () => {
    deleteNetwork({
      networkId: selectedNetworkDetail?.id || ALL_RECORDS_NETWORK_ID,
    })
      // unwrap to access the error or success payload immediately after a mutation.
      .unwrap()
      .then(() => {
        dispatch(setNetworkId(null));
        dispatch(requestRecordList());
        dispatch(
          toastSuccess(
            `The network has been removed. Any associated saved views have been updated to use the all ${recordLabelPlural.toLowerCase()} network`,
          ),
        );
      })
      .catch(() => {
        dispatch(toastError(`Could not delete network.`));
      });
  };

  const handleSelectedNetworkChange = networkId => {
    closeNetworkSidePanel();
    dispatch(setNetworkId(networkId || null));
    dispatch(requestRecordList());
  };

  const handleCloseNetworkModal = networkId => {
    if (networkId) {
      handleSelectedNetworkChange(networkId);
    }
    closeNetworkSidePanel();
  };

  const handleOpenNetworkSidePanel = isCreateNetwork => {
    openNetworkSidePanel(true);
    setIsCreateNetworkSelected(isCreateNetwork);
  };

  return (
    <div className={className}>
      <h2 className='u-margin-n u-marginBottom-8'>Network</h2>
      <span>{`Select from a network of ${pageName}`}</span>
      <div className='u-flex u-marginTop-4'>
        <SelectMenu
          className='u-flexItem-grow u-marginRight-8'
          inputWidth='full'
          onChange={handleSelectedNetworkChange}
          value={savedView.networkId || ALL_RECORDS_NETWORK_ID}
        >
          {getNetworkItems()}
        </SelectMenu>
        <Menu
          button={
            <ButtonSecondary aria-label='Network Options' icon='options' />
          }
          isPinnedRight={true}
        >
          <Menu.Item
            icon='add'
            onClick={() => handleOpenNetworkSidePanel(true)}
          >
            Create New
          </Menu.Item>
          {userSelectedNetwork && (
            <Menu.Item
              icon='edit'
              onClick={() => handleOpenNetworkSidePanel(false)}
            >
              Edit
            </Menu.Item>
          )}

          {selectedNetworkDetail ? (
            <Menu.Item icon='delete' onClick={openDeleteNetworkConfirmation}>
              Delete
            </Menu.Item>
          ) : null}
        </Menu>
      </div>
      <ConfirmationDialog
        cancelLabel='No, Keep Working'
        confirmLabel='Yes, Delete Network'
        isOpen={isDeleteNetworkConfirmationOpen}
        onClose={closeDeleteNetworkConfirmation}
        onConfirm={handleDeleteNetworkConfirm}
        title='Delete Network?'
      >
        <div>{`You are about to delete the ${
          selectedNetworkDetail?.name ? selectedNetworkDetail.name : ''
        } network. This cannot be undone.`}</div>
        <div>
          <b>Do you want to continue?</b>
        </div>
      </ConfirmationDialog>
      {isNetworkSidePanelOpen ? (
        <NetworkSidePanel
          onClose={handleCloseNetworkModal}
          selectedNetwork={isCreateNetworkSelected ? null : userSelectedNetwork}
          title={isCreateNetworkSelected ? 'Create Network' : 'Edit Network'}
        />
      ) : null}
    </div>
  );
};

RecordIndexSidebarNetworks.propTypes = {
  className: PropTypes.string,
  pageName: PropTypes.string.isRequired,
};

RecordIndexSidebarNetworks.defaultProps = {
  className: null,
};

export default RecordIndexSidebarNetworks;

import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import DateTime from 'modules/datetime/components/DateTime';
import mapCandidacyIdToCandidacy from './mapCandidacyIdToCandidacy';

/**
 * Renders the date that a candidacy record was created.
 */
export default compose(
  setDisplayName('CandidacyDateAdded'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  mapCandidacyIdToCandidacy,
  mapProps(({ candidacy }) => ({
    value: candidacy && candidacy.get('created_at'),
  })),
)(DateTime);

import PropTypes from 'prop-types';
import React from 'react';
import { onlyUpdateForPropTypes } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';

import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import TalentPoolCardLarge from './TalentPoolCardLarge';

/**
 * Renders a collection of contact/talent pool records i which each record is rendered as a "card"
 * (<TalentPoolCardLarge />)
 */
const TalentPoolsCardView = ({
  contactIds,
  isFetching,
  onCreateTalentPool,
}) => (
  <div className='talent-pools-collection'>
    {isFetching ? (
      <LoadingIndicator />
    ) : (
      <div className='row'>
        {contactIds &&
          contactIds
            .map(contactId => (
              <div className='col-6 col-sm-4 col-lg-3' key={contactId}>
                <TalentPoolCardLarge
                  contactId={contactId}
                  onCreateTalentPool={onCreateTalentPool}
                />
              </div>
            ))
            .toArray()}
      </div>
    )}
  </div>
);

TalentPoolsCardView.propTypes = {
  /**
   * The collection of contacts to render
   */
  contactIds: ImmutablePropTypes.listOf(PropTypes.number),

  /**
   * True if the contact records are currently being fetched, in which case a loading indicator
   * is shown.
   */
  isFetching: PropTypes.bool,

  /**
   * Called when the new talent pool form should be shown.
   */
  onCreateTalentPool: PropTypes.func.isRequired,
};

TalentPoolsCardView.defaultProps = {
  isFetching: false,
};

export default onlyUpdateForPropTypes(TalentPoolsCardView);

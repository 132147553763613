import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'modules/core/componentsLegacy/Modal';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import CancelButton from 'modules/forms/components/CancelButton';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import ContactDesiredCompensationField from './ContactDesiredCompensationField';
import withContactForm from '../withContactForm';

/**
 * A modal for editing a contact's "Desired Compensation" fields.
 */
const ContactDesiredCompensationModalForm = ({
  formState,
  onCancel,
  onFieldStateChange,

  onSubmit,
  show,
  ...fieldProps
}) => (
  <Modal
    className='ContactDesiredCompensationModalForm'
    onHide={onCancel}
    show={show}
  >
    <Form formState={formState} onSubmit={onSubmit}>
      <Modal.Header closeButton={true}>
        <Modal.Title>Add Desired Compensation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactDesiredCompensationField
          {...fieldProps}
          disabled={formState.isSubmitting()}
          fieldState={formState.getFieldState()}
          onChange={onFieldStateChange}
          showErrors={formState.wasSubmitted() || 'blurred'}
        />
        <FormErrorMessage formState={formState} />
      </Modal.Body>
      <Modal.Footer>
        <CancelButton bsSize='lg' onClick={onCancel} />
        <SubmitButton bsSize='lg' formState={formState} onClick={onSubmit} />
      </Modal.Footer>
    </Form>
  </Modal>
);

ContactDesiredCompensationModalForm.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the user clicks the cancel button or otherwise wants to close the modal
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * True if the modal should be displayed, otherwise false.
   */
  show: PropTypes.bool.isRequired,
};

/**
 * The connected version of this component includes a FormState/FieldState and
 * handlers for submitting the form.
 */
export default compose(
  setDisplayName('ContactDesiredCompensationModalForm(enhanced)'),
  setPropTypes({
    /**
     * The ID of the contact for which the desired compensation will be edited.
     */
    contactId: PropTypes.number,

    /**
     * Called when the modal should be closed.
     */
    onCancel: PropTypes.func.isRequired,

    /**
     * Called when the modal should be closed.
     */
    onSaved: PropTypes.func.isRequired,

    /**
     * Whether the modal should be rendered.
     */
    show: PropTypes.bool.isRequired,
  }),

  // Provides the field state, form state, and submission handling.
  withContactForm(ContactDesiredCompensationField),

  /**
   * Prevent props from being passed on to the underlying fieldState
   */
  /* eslint-disable no-unused-vars */
  mapProps(
    ({ contactId, onFormStateChange, onResetFormState, onSaved, ...rest }) => ({
      ...rest,
    }),
  ),
  /* eslint-enable no-unused-vars */
)(ContactDesiredCompensationModalForm);

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import Select, { components } from 'react-select';
import classNames from 'classnames';
import Icon from '@thrivetrm/ui/components/Icon';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import { EMPTY_STYLES } from 'modules/recordIndex/components/v5-filters/common-react-select-constants';
import { uniqueId } from 'lodash';

const CrossIcon = () => <Icon type='close' />;
const Loader = () => <div className='u-marginVertical-4'>Loading...</div>;
// eslint-disable-next-line react/prop-types
const MultiValueRemove = ({ onClick, ...props }) => (
  <components.MultiValueRemove {...props}>
    <ButtonSecondary
      className='u-marginHorizontal-4'
      icon='close'
      onClick={onClick}
      size='small'
    />
  </components.MultiValueRemove>
);

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <Icon className='u-marginRight-8' type='close' />
  </components.ClearIndicator>
);

const SelectDropdown = ({
  className,
  errorMessage,
  isClearable,
  isFetchingOptions,
  isMulti,
  isRequired,
  label,
  menuListFooter,
  onChange,
  options,
  placeholder,
  value,
}) => {
  const inputId = uniqueId('selectInput');
  const asyncSelectRef = useRef(inputId);
  const containerClass = classNames('AsyncSelect', className, {
    'MultiValueInput--isInvalid': Boolean(errorMessage),
  });
  const MenuList = menuListProps => (
    <>
      <components.MenuList {...menuListProps}>
        {isFetchingOptions ? <Loader /> : menuListProps.children}
      </components.MenuList>
      {menuListFooter ?? null}
    </>
  );

  return (
    <div className={containerClass}>
      {label ? (
        <label className='MultiValueInput__label' htmlFor={inputId}>
          {isRequired ? <span aria-hidden='true'>* </span> : null}
          {label}
        </label>
      ) : null}

      <Select
        classNamePrefix='MultiValueInput'
        components={{
          // Custom components used to replace the react-select defaults
          CrossIcon: CrossIcon,
          IndicatorSeparator: null,
          MenuList: MenuList,
          MultiValueRemove: MultiValueRemove,
          ClearIndicator: ClearIndicator,
        }}
        isClearable={isClearable}
        isMulti={isMulti}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        ref={asyncSelectRef}
        styles={EMPTY_STYLES}
        value={value}
      />
      {errorMessage ? (
        <div className='MultiValueInput__errorMessage' role='alert'>
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
};

SelectDropdown.defaultProps = {
  className: null,
  errorMessage: null,
  isClearable: true,
  isFetchingOptions: false,
  isMulti: false,
  isRequired: false,
  label: null,
  menuListFooter: null,
  options: [],
  placeholder: 'Select...',
  value: null,
};

SelectDropdown.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  isClearable: PropTypes.bool,
  isFetchingOptions: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  menuListFooter: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
};

export default SelectDropdown;

import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  withProps,
} from 'recompose';
import currencySchema from 'modules/tenant/schemas/currencies';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';

/**
 * A dropdown/select component for choosing from the list of currency types.
 */
export default compose(
  setDisplayName('CurrencyTypeSelectField'),
  setStatic('createFieldState', TenantOptionsSelectField.createFieldState),
  setPropTypes({
    /**
     * A FieldState created from CurrencyTypeSelectField.createFieldState
     */
    fieldState: fieldStatePropType.isRequired,

    /**
     * Called when the field's value is changed.
     */
    onChange: PropTypes.func.isRequired,
  }),
  withProps({
    schema: currencySchema,
    labelPropertyName: 'id',
    sortProperty: 'id',
  }),
  withClassName('CurrencyTypeSelectField'),
)(TenantOptionsSelectField);

import { fromJS } from 'immutable';
import AppointmentField from './AppointmentField';
import {
  TYPE_INTERVIEW_SCHEDULED,
  TYPE_INTERVIEW_SCHEDULING,
  TYPE_MEETING,
} from '../constants';

/**
 * Given props, creates the root FieldState for a new appointment
 * (either an interview or a meeting).
 * */
const createRootFieldState = ({
  candidacyId,
  contactId,
  draft,
  interview,
  meeting,
  recruiterName,
  searchId,
}) => {
  let type = TYPE_MEETING;
  if (candidacyId) {
    // The presence of a candidacyId means this is an Interview form,
    // and our options are going to be Interview options only.
    // Default to 'Scheduled'.
    type = TYPE_INTERVIEW_SCHEDULED;
  }

  let interviewValue;
  // No need to guess because we have an existing interview!
  if (interview) {
    type = TYPE_INTERVIEW_SCHEDULED;
    if (interview.get('scheduling')) {
      type = TYPE_INTERVIEW_SCHEDULING;
    }

    // Set draft content on existing interview
    interviewValue = interview.withMutations(immutableInterview => {
      if (draft?.content) {
        immutableInterview.set('description', draft?.content);
      }
    });
  }

  let meetingValue;
  if (meeting) {
    type = TYPE_MEETING;

    // Set draft content on existing meeting
    meetingValue = meeting.withMutations(immutableMeeting => {
      if (draft?.content) {
        immutableMeeting.set('description', draft?.content);
      }
    });
  }

  const appointment = fromJS({
    contact_id: contactId,
    candidacy_id: candidacyId,
    description: draft?.content,
    attendees: contactId ? [contactId] : [],
    searches: searchId ? [searchId] : [],
    type: type,
  }).merge(interviewValue || meetingValue);

  return AppointmentField.createFieldState('appointment', {
    appointment: appointment,
    recruiterName: recruiterName,
  });
};

export default createRootFieldState;

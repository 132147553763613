import { NAME } from '../constants';

/**
 * Gets the invite token for a candidacy by it's candidacy ID.
 * @param {Object} state
 * @param {Number} candidacyId The ID of the candidacy.
 * @return {Immutable.Map|undefined} The invite token state, if found.
 */
export default (state, candidacyId) =>
  state[NAME].getIn(['candidacyInviteTokens', candidacyId]);

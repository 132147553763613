import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { fromJS } from 'immutable';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import CompanyMultiSelectField from 'modules/companies/components/CompanyMultiSelectField';
import CurrencyInputField from 'modules/currency/components/CurrencyInputField';
import DateInputField from 'modules/datetime/components/DateInputField';
import FieldState from 'modules/forms/FieldState';
import * as validators from 'modules/forms/validators';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import companyInvestmentStages from 'modules/tenant/schemas/companyInvestmentStages';
import getCompanyInvestmentStageOrder from 'modules/tenant/selectors/getCompanyInvestmentStageOrder';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import NewCompanyFormModal from 'modules/companies/components/NewCompanyFormModal';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import SkyminyrLegacyCompanySelect from 'modules/companies/components/skyminyr/SkyminyrLegacyCompanySelect';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import { getSkyminyrCompanyCreatePayload } from 'modules/companies/components/skyminyr/getSkyminyrCompanyCreatePayload';

/**
 * A field for creating/editing a funding round
 */
const FundingRoundField = ({
  className,
  fieldState,
  onChange,
  title,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const [companyName, setCompanyName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFieldChange = value => {
    onChange(fieldState.setNestedField(value));
  };

  const onSearchValueChange = value => {
    setCompanyName(value);
  };

  return (
    <div className={classNames('FundingRoundField', className)}>
      {title ? <h3>{title}</h3> : null}
      <div className='row'>
        <div className='col-sm-4'>
          <DateInputField
            {...otherProps}
            fieldState={fieldState.getNestedField('date')}
            isRequired={true}
            label='Date *'
            name='date'
            onChange={handleFieldChange}
          />
        </div>
        <div className='col-sm-4'>
          <TenantOptionsSelectField
            {...otherProps}
            fieldState={fieldState.getNestedField('round')}
            label='Investment Round *'
            onChange={handleFieldChange}
            schema={companyInvestmentStages}
            sortSelector={getCompanyInvestmentStageOrder}
          />
        </div>
        <div className='col-sm-4 form-group FundingRoundField__amount'>
          <CurrencyInputField
            {...otherProps}
            fieldState={fieldState.getNestedField('amount')}
            isRequired={true}
            label='Amount'
            name='amount'
            onChange={handleFieldChange}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          {hasSkyminyrFeature ? (
            <SkyminyrLegacyCompanySelect
              fieldState={fieldState.getNestedField('investors')}
              isClearable={false}
              isMulti={true}
              label='Investors'
              onChange={handleFieldChange}
              placeholder='Select Investors'
            />
          ) : (
            <CompanyMultiSelectField
              {...otherProps}
              fieldState={fieldState.getNestedField('investors')}
              label='Investors'
              name='investors'
              noCompaniesFoundElement={
                <>
                  <p>No companies found</p>
                  <ButtonPrimary
                    className='u-noWrap'
                    icon='add'
                    key='button'
                    label={`Create "${companyName}"`}
                    onClick={() => setIsModalOpen(true)}
                    size='small'
                  />
                </>
              }
              onChange={handleFieldChange}
              onSearchValueChange={onSearchValueChange}
              placeholder='Select Investors'
            />
          )}
        </div>
      </div>
      {isModalOpen ? (
        <NewCompanyFormModal
          companyName={companyName}
          key='modal'
          onClose={data => {
            // checking for the data type as it returns the comnpany id on success and it is a number
            if (typeof data === 'number') {
              dispatch(toastSuccess('Company created successfully'));
            }
            setIsModalOpen(false);
          }}
          shouldNavigateToNewCompany={false}
          show={true}
        />
      ) : null}
    </div>
  );
};

FundingRoundField.createFieldState = (
  name = 'funding_round',
  fundingRound,
  hasSkyminyrFeature,
) => {
  const values = fromJS({
    date: moment(),
    amount: 0,
    round: null,
    investors: [],
  }).merge(fundingRound);
  const dateMoment = moment(values.get('date'), moment.ISO_8601);
  return FieldState.createNested(
    name,
    [
      DateInputField.createFieldState(
        'date',
        dateMoment.isValid() ? dateMoment : moment(),
        validators.requiredField('Date'),
      ),
      CurrencyInputField.createFieldState('amount', values.get('amount'), {
        minValue: 0,
        required: 'Amount',
      }),
      TenantOptionsSelectField.createFieldState(
        'round',
        values.get('round'),
        validators.requiredField('Investment Round'),
      ),
      hasSkyminyrFeature
        ? SkyminyrLegacyCompanySelect.createFieldState(
            'investors',
            values.get('investors').toArray(),
          )
        : CompanyMultiSelectField.createFieldState(
            'investors',
            values.get('investors').toArray(),
          ),
    ],
    null,
    ({ investors, ...formState }) => ({
      investors: hasSkyminyrFeature
        ? investors
            ?.map(investor =>
              isNumber(investor) ? investor : investor.data?.id,
            )
            ?.filter(Boolean)
        : investors,
      investors_attributes: hasSkyminyrFeature
        ? investors
            ?.filter(investor => investor.data?.skyminyr_id)
            ?.map(investor => getSkyminyrCompanyCreatePayload(investor.data))
        : [],
      ...formState,
    }),
  );
};

FundingRoundField.propTypes = {
  className: PropTypes.string,
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  /**
   * An optional title to display at the top of the field.
   */
  title: PropTypes.node,
};

export default FundingRoundField;

import React from 'react';
import PropTypes from 'prop-types';
import { SEARCH_TYPE_LABELS } from 'modules/searches/constants';

const RESOURCE_TYPE_TAB_MAP = {
  comment: 'comments',
  interview: 'interviews',
  assessment: 'assessments',
  outreach: 'outreach',
  reference: 'references',
  note: 'notes',
};

const ActivitySearchDetails = ({
  candidacyId: candidacy,
  resource,
  search,
  type,
}) => {
  const searchData = search || resource?.search;
  const searchesData = resource?.searches;
  const showRelatedSearches = searchData || searchesData?.length;

  const getUrl = searchId => {
    const candidacyId = candidacy ?? resource?.candidacyIds?.[searchId];

    if (candidacyId) {
      if (RESOURCE_TYPE_TAB_MAP[type]) {
        return `/searches/${searchId}/candidates/${candidacyId}/${RESOURCE_TYPE_TAB_MAP[type]}`;
      }
      return `/searches/${searchId}/candidates/${candidacyId}`;
    }
    return `/searches/${searchId}`;
  };

  const renderSearchDetails = (
    { clientCompany, codeName, confidential, id, jobTitle, links, name },
    shouldAddComma,
  ) => (
    <h6 className='u-margin-n' key={id}>
      <a href={resource?.links?.candidatePanel ?? links?.self ?? getUrl(id)}>
        {confidential ? codeName : jobTitle || name}
        {!confidential && clientCompany ? (
          <span>
            {' at '}
            {clientCompany.name}
          </span>
        ) : null}
      </a>
      {shouldAddComma ? ', ' : null}
    </h6>
  );

  return showRelatedSearches ? (
    <div className='u-flex u-flexAlign-c u-marginVertical-4'>
      <h6 className='u-margin-n u-marginRight-4'>
        {/* Using searchesData[0] to get type of search */}
        {/* Checking type from searchesData when searchData is not available */}
        {SEARCH_TYPE_LABELS[searchData?.type || searchesData[0].type]}:
      </h6>
      {searchData
        ? renderSearchDetails(searchData)
        : searchesData?.map((eachSearch, index) =>
            renderSearchDetails(eachSearch, index + 1 < searchesData.length),
          )}
    </div>
  ) : null;
};

ActivitySearchDetails.propTypes = {
  candidacyId: PropTypes.number,
  resource: PropTypes.shape({
    candidacyIds: PropTypes.shape({
      id: PropTypes.number,
    }),
    links: PropTypes.shape({
      candidatePanel: PropTypes.string,
    }),
    search: PropTypes.shape({
      name: PropTypes.string,
    }),
    searches: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }),
  search: PropTypes.shape({
    name: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
};

export default ActivitySearchDetails;

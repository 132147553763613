import PropTypes from 'prop-types';
import selectn from 'selectn';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withFormState from 'modules/forms/components/withFormState';
import withTransaction from 'modules/transactions/components/withTransaction';
import createCandidacyAction from '../actions/createCandidacy';
import createRecommendedCandidaciesAction from '../actions/createRecommendedCandidacies';
import { RECOMMENDED_STAGE_OPTION } from '../constants';

/**
 * Provides props and form submission for adding contacts to a search.
 *
 * @param {Function} createRootFieldState A function that will be called with the current
 *   props and should return a new FieldState that will be used as the root FieldState
 *   for the underlying form's FormState.
 *
 * Provides the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying `RootField` fieldstate
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default createRootFieldState =>
  compose(
    setDisplayName('withCandidaciesCreateForm'),
    setPropTypes({
      /**
       * The IDs of the contacts who should be added to the selected search.
       */
      contactIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,

      /**
       * Called when the form has been successfully saved.
       */
      onSaved: PropTypes.func.isRequired,
    }),

    // Create a temporary prop (`withCandidaciesCreateFormProps`) that holds any incoming props
    // that aren't required by us. This ensures we don't overwrite/delete/clobber any props that may
    // need to be passed through.
    withProps(({ onSaved: _onSaved, ...withCandidaciesCreateFormProps }) => ({
      withCandidaciesCreateFormProps: withCandidaciesCreateFormProps,
    })),

    /**
     * Create a FormState, initializing it with an empty comment field.
     * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
     */
    withFormState(props => createRootFieldState(props)),

    /**
     * Watch for any save transaction to complete
     */
    withHandlers({
      /**
       * This gets called by `withTransaction`, below, any time our transaction started
       * with `startTransaction` is called.
       */
      onTransactionComplete: ({
        formState,
        onFormStateChange,
        onResetFormState,
        onSaved,
      }) => transaction => {
        const error = selectn('payload.error', transaction);
        onFormStateChange(formState.endSubmit(error));

        if (!error) {
          onResetFormState();
          onSaved(selectn('payload.result.candidacies', transaction));
        }
      },
    }),

    /**
     * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
     * when the transaction used with `startTransaction` is finished.
     */
    withTransaction,

    /**
     * Connect the action we need to dispatch for adding contacts to a network
     */
    connect(null, {
      createCandidacy: createCandidacyAction,
      createRecommendedCandidacies: createRecommendedCandidaciesAction,
    }),

    /**
     * Add a callback to handle submitting the action form.
     */
    withHandlers({
      // Called when the form should be submitted.
      onSubmit: ({
        contactIds,
        createCandidacy,
        createRecommendedCandidacies,
        formState,
        onFormStateChange,
        startTransaction,
      }) => e => {
        const transactionId = startTransaction();
        if (
          formState.getFieldValue().stage !== RECOMMENDED_STAGE_OPTION.value
        ) {
          createCandidacy({
            contactId: contactIds,
            hidden: formState.getFieldValue().hidden,
            searchId: formState.getFieldValue().search,
            stageId: formState.getFieldValue().stage,
            rejectionComment: formState.getFieldValue().rejection_comment,
            rejectionReason: formState.getFieldValue().rejection_reason,
            transactionId: transactionId,
          });
        } else {
          createRecommendedCandidacies({
            contactId: contactIds,
            searchId: formState.getFieldValue().search,
            transactionId: transactionId,
          });
        }
        onFormStateChange(formState.startSubmit(transactionId));

        // Prevent default browser behavior, which could cause the browser to attempt
        // to submit a form on it's own.
        return preventDefaultHandler(e);
      },
    }),

    // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
    mapProps(
      ({
        formState,
        onCancel,
        onFieldStateChange,
        onFormStateChange,
        onResetFormState,
        onSubmit,
        withCandidaciesCreateFormProps,
      }) => ({
        ...withCandidaciesCreateFormProps,
        searchId: formState.getFieldValue().search,
        formState: formState,
        onFieldStateChange: onFieldStateChange,
        onFormStateChange: onFormStateChange,
        onResetFormState: onResetFormState,
        onSubmit: onSubmit,
        onCancel: onCancel,
      }),
    ),
  );

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Percentage from '@thrivetrm/ui/components/Percentage';
import Currency from '@thrivetrm/ui/components/Currency';

const BonusViewHelper = ({ compensation }) => {
  const base = compensation.get('base');
  const bonus = compensation.get('percentage_bonus');
  const bonusHelper = base ? (
    <>
      <Percentage maxFractionDigits={2} value={bonus} /> of base
      <p>
        (
        <Currency
          currency={compensation.get('currency')}
          value={Math.round((Number(base) * Number(bonus)) / 100)}
        />
        )
      </p>
    </>
  ) : (
    <>
      <Percentage maxFractionDigits={2} value={bonus} /> of base
    </>
  );

  return <div>{bonusHelper}</div>;
};

BonusViewHelper.propTypes = {
  compensation: ImmutablePropTypes.mapContains({
    base: PropTypes.number,
    bonus: PropTypes.number.isRequired,
  }).isRequired,
};

export default BonusViewHelper;

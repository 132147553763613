import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { compose, setDisplayName } from 'recompose';
import * as actions from '../actions';

/**
 * Connects the outreach actions to the current dispatcher and adds
 * them to props as `outreachActions`
 */
export default compose(
  setDisplayName('connectOutreachActions'),
  connect(null, dispatch => ({
    outreachActions: bindActionCreators(actions, dispatch),
  })),
);

import { NAME } from '../constants';

/**
 * Gets the value of a particular preference key (set by dispatching a `setPreference` action)
 * @param {Object} state
 * @param {String|String[]} key A unique key (or key path) identifying the preference
 * @param {*} defaultValue A value to return if the key wasn't found.
 * @return {*} The value at the key, or `defaultValue` if not found.
 */
export default (state, key, defaultValue) => {
  const keyPath = Array.isArray(key)
    ? ['preferences', ...key]
    : ['preferences', key];
  return state[NAME].getIn(keyPath, defaultValue);
};

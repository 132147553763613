import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import Modal from '@thrivetrm/ui/components/Modal';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import { useApiPost } from '@thrivetrm/ui/hooks/useApi';
import routes from 'modules/routing/routes';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import Form from '@thrivetrm/ui/components/Form';
import { useDispatch } from 'react-redux';
import TextInput from '@thrivetrm/ui/components/TextInput';
import useCopyToClipboard from '@thrivetrm/ui/hooks/useCopyToClipboard';

const CandidacyCreateGuestAssessmentInviteButton = ({
  candidacyId,
  searchId,
}) => {
  const dispatch = useDispatch();
  const { copyToClipboard } = useCopyToClipboard();
  const [isOpen, openModal, closeModal] = useToggle(false);

  const [sendGuestInvite] = useApiPost(routes.api_v1_invite_tokens());
  const [
    getGuestAssessmentUrl,
    _isLoading,
    _isError,
    guestAssessmentUrl,
  ] = useApiPost(
    routes.api_candidacy_invite_tokens({
      candidacy_id: candidacyId,
    }),
  );

  const handleSendInvite = formState => {
    sendGuestInvite(
      {
        resource_id: candidacyId,
        email: formState.guestEmailId,
      },
      {
        onSuccess: response => {
          dispatch(toastSuccess(response.message));
          closeModal();
        },
        onError: error => {
          dispatch(toastError(error));
        },
      },
    );
  };

  const handleButtonClick = () => {
    getGuestAssessmentUrl({
      invite_token: { candidacy_id: candidacyId, search_id: searchId },
    });
    openModal();
  };

  const handleCopyAndCloseButton = () => {
    copyToClipboard(guestAssessmentUrl?.url);
    dispatch(toastSuccess('Copied!'));
    closeModal();
  };

  return (
    <div className='CandidacyCreateGuestAssessmentInviteButton u-flex u-flexJustify-r'>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        size='small'
        title='Guest Assessment Invite Link'
      >
        <Form onSubmit={handleSendInvite}>
          <Modal.Body className='u-paddingVertical-24 u-paddingHorizontal-64'>
            <p className='u-textAlign-c u-marginBottom-24 CandidacyCreateGuestAssessmentInviteButton__note'>
              Share the link below to invite anyone to submit an assessment.
            </p>
            <TextInput
              className='CandidacyCreateGuestAssessmentInviteButton__textInput'
              inputWidth='full'
              isDisabled={true}
              onChange={() => {}}
              value={guestAssessmentUrl?.url}
            />
            <h3 className='u-textAlign-c u-marginTop-32 CandidacyCreateGuestAssessmentInviteButton__note'>
              The guest assessment link can be used multiple times if needed,
              but will expire in 45 days.
            </h3>
            <p className='u-textAlign-c u-marginTop-24 CandidacyCreateGuestAssessmentInviteButton__note'>
              The guest assessor will be able to get a copy of their assessment
              after submission.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <ButtonPrimary
              isDisabled={!guestAssessmentUrl?.url}
              label='Copy Link & Close'
              onClick={handleCopyAndCloseButton}
            />
          </Modal.Footer>
        </Form>
      </Modal>
      <Tooltip
        content='Generate Guest Assessment Invite Link'
        position='left'
        size='medium'
      >
        <button
          className='btn btn-link'
          onClick={handleButtonClick}
          type='button'
        >
          <i className='btn-tooltip' data-icon='U' />
        </button>
      </Tooltip>
    </div>
  );
};

CandidacyCreateGuestAssessmentInviteButton.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  searchId: PropTypes.number.isRequired,
};

export default CandidacyCreateGuestAssessmentInviteButton;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Form from '@thrivetrm/ui/components/Form';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import {
  useGetReferenceQuery,
  useUpdateReferenceMutation,
} from 'services/apiV1/references';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import {
  getConnectionTypeLabel,
  useGetConnectionTypesQuery,
} from 'services/apiV1/connections';
import ReferenceFormV2, { FORM_LAYOUT } from './ReferenceFormV2';

const ReferenceEditFormV2 = ({
  formHeader,
  layout,
  onCancel,
  onSuccess,
  referenceId,
  submitButtonLabel,
}) => {
  const dispatch = useDispatch();
  const hasConnectionFeature = useFeatureCheck('feature.connections_gate');
  const {
    data: referenceData,
    isFetching: isLoadingReference,
  } = useGetReferenceQuery(referenceId);
  const { candidacyId, contactId, searchId } = referenceData || {};
  const [
    updateReference,
    { isLoading: isUpdatingReference },
  ] = useUpdateReferenceMutation();
  const { data: connectionTypes } = useGetConnectionTypesQuery();

  const handleSubmit = formState => {
    const payload = {
      reference: {
        contact_id: contactId,
        candidacy_id: candidacyId,
        referee_id: formState.referee.id,
        description: formState.description,
        search_id: formState.relatedSearch?.id || null,
        connection_type_id: formState.connectionTypeId,
        referral_company_id: formState.referralCompany?.id || null,
        reference_type: formState.referenceType || null,
        /* set relationship field to same as connection type when the feature.connections_gate is enabled
         as the relationship field is hidden on the form and it can't be blank */
        relationship: hasConnectionFeature
          ? getConnectionTypeLabel(
              connectionTypes?.find(
                ({ id }) => id === formState.connectionTypeId,
              ),
              formState.contactName,
            )
          : formState.relationship,
      },
    };

    updateReference({ referenceId: referenceId, payload: payload })
      .unwrap()
      .then(() => {
        onSuccess?.();
        dispatch(toastSuccess('Successfully edited reference'));
      })
      .catch(() => {
        dispatch(toastError('Could not update the reference'));
      });
  };

  return (
    <LoadingContainer isLoading={isLoadingReference} minHeight='80px'>
      {referenceData ? (
        <Form onSubmit={() => {}}>
          <ReferenceFormV2
            contactId={contactId}
            formHeader={formHeader}
            initialValues={{
              referee: referenceData?.referee?.id
                ? {
                    id: referenceData.referee.id,
                    name: referenceData.referee.fullName,
                  }
                : null,
              connectionTypeId: referenceData.connection?.id || null,
              referenceType: referenceData.referenceType || null,
              relatedSearch: referenceData.search || null,
              description: referenceData.description,
              referralCompany: referenceData.referralCompany || null,
              relationship: referenceData.relationship,
            }}
            isCreatingOrUpdating={isUpdatingReference}
            layout={layout}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            searchId={searchId}
            submitButtonLabel={submitButtonLabel}
          />
        </Form>
      ) : null}
    </LoadingContainer>
  );
};

ReferenceEditFormV2.defaultProps = {
  formHeader: 'Edit Reference',
  submitButtonLabel: 'Save Reference',
};

ReferenceEditFormV2.propTypes = {
  formHeader: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(FORM_LAYOUT)),
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  referenceId: PropTypes.number.isRequired,
  submitButtonLabel: PropTypes.string,
};

export default ReferenceEditFormV2;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect, useSelector } from 'react-redux';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import isEmployeeUser from 'modules/auth/selectors/isEmployeeUser';
import { compose, setDisplayName } from 'recompose';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import CandidacyCommentCount from 'modules/comments/components/CandidacyCommentCount';
import { TYPE_TALENT_POOL } from 'modules/searches/constants';
import isClientUser from 'modules/auth/selectors/isClientUser';
import isClientNoCompUser from 'modules/auth/selectors/isClientNoCompUser';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import AssessmentCount from './assessments/AssessmentCount';
import CandidacyRankingPopoverButton from './ranking/CandidacyRankingPopoverButton';
import CandidacyVoteButton from './voting/CandidacyVoteButton';
import SearchCandidacyLink from './SearchCandidacyLink';
import getCandidacyStatus from '../selectors/getCandidacyStatus';

/**
 * The action controls for each candidacy card.
 * Renders:
 *   * Rank button
 *   * Priority Vote button
 *   * Not a Fit button
 *   * Comment button
 *   * Assessment button
 */

const CandidacyActionButtonsV2 = ({
  allowRanking,
  candidacyId,
  className,
  componentId,
  employeeUser,
  firstCandidacyId,
  hasAssessmentTemplates,
  lastCandidacyId,
  nextCandidacyId,
  previousCandidacyId,
  searchType,
  shouldRenderAssessmentActions,
  smallCard,
}) => {
  const outreachCount = useSelector(state =>
    getCandidacyStatus(state, candidacyId)?.get('outreach_count'),
  );

  const hasCandidateMetadataFeature = useFeatureCheck(
    'feature.display_candidate_metadata',
  );

  const canViewOutreachCount = useSelector(
    state =>
      !isClientUser(state) &&
      !isClientNoCompUser(state) &&
      hasCandidateMetadataFeature,
  );

  return (
    <div
      className={classnames(
        className,
        'CandidacyActionButtonsV2 btn-group btn-group-sm btn-group-vertical',
      )}
    >
      {employeeUser && (
        <CandidacyRankingPopoverButton
          allowRanking={allowRanking}
          candidacyId={candidacyId}
          firstCandidacyId={firstCandidacyId}
          lastCandidacyId={lastCandidacyId}
          nextCandidacyId={nextCandidacyId}
          previousCandidacyId={previousCandidacyId}
        />
      )}
      {!smallCard && (
        <>
          <CandidacyVoteButton
            candidacyId={candidacyId}
            showTooltip={true}
            value={1}
          />
          <CandidacyVoteButton
            candidacyId={candidacyId}
            showTooltip={true}
            value={-1}
          />
        </>
      )}
      <OverlayTrigger
        overlay={
          <Tooltip id={`${componentId}-comments`}>
            <span>View/Add Comments</span>
          </Tooltip>
        }
        placement='left'
      >
        <SearchCandidacyLink
          candidacyId={candidacyId}
          className='CandidacyActionButtonsV2__CommentsLink btn btn-default'
          tab='comments'
        >
          <CandidacyCommentCount
            candidacyId={candidacyId}
            iconClassName='fa fa-commenting'
            noText={true}
          />
        </SearchCandidacyLink>
      </OverlayTrigger>
      {searchType !== TYPE_TALENT_POOL && !smallCard && canViewOutreachCount ? (
        <OverlayTrigger
          overlay={
            <Tooltip id={`${componentId}-outreach`}>
              <span>View Outreach</span>
            </Tooltip>
          }
          placement='left'
        >
          <SearchCandidacyLink
            candidacyId={candidacyId}
            className='btn btn-default'
            tab='outreach'
          >
            <div className='CandidacyActionButtonsV2__OutreachCount u-flex u-flexJustify-c'>
              <div className='u-marginTop-2'>
                <i className='icon-nav-outreach' />
              </div>
              {outreachCount}
            </div>
          </SearchCandidacyLink>
        </OverlayTrigger>
      ) : null}
      {shouldRenderAssessmentActions &&
      ((smallCard && !employeeUser) || !smallCard) ? (
        <OverlayTrigger
          overlay={
            <Tooltip id={`${componentId}-assessments`}>
              <span>View/Add Assessments</span>
            </Tooltip>
          }
          placement='left'
        >
          <SearchCandidacyLink
            candidacyId={candidacyId}
            className='CandidacyActionButtonsV2__AssessmentLink btn btn-default'
            tab={
              hasAssessmentTemplates ? 'candidacy_assessments' : 'assessments'
            }
          >
            <span className='CandidacyActionButtonsV2__AssessmentCount'>
              <i className='fa fa-star-half-o' />
              <AssessmentCount candidacyId={candidacyId} />
            </span>
          </SearchCandidacyLink>
        </OverlayTrigger>
      ) : null}
    </div>
  );
};

CandidacyActionButtonsV2.defaultProps = {
  shouldRenderAssessmentActions: true,
};

CandidacyActionButtonsV2.propTypes = {
  /**
   * whether or not to enable candidate ranking
   */
  allowRanking: PropTypes.bool.isRequired,

  /**
   * The ID of the candidacy that the actions will apply to.
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * An optional class name to add to the outer div of the component.
   */
  className: PropTypes.string,

  /**
   * unique ID for the tooltip
   */
  componentId: PropTypes.string.isRequired,

  /**
   * determines if the user is employee user or not and if the user
   * can see the rank button.
   */
  employeeUser: PropTypes.bool,

  /**
   * ID of first candidacy in list or group
   */
  firstCandidacyId: PropTypes.number,

  hasAssessmentTemplates: PropTypes.bool,

  /**
   * ID of last candidacy in list or group
   */
  lastCandidacyId: PropTypes.number,

  /**
   * ID of next candidacy in list or group
   */
  nextCandidacyId: PropTypes.number,

  /**
   * ID of previous candidacy in list or group
   */
  previousCandidacyId: PropTypes.number,
  searchType: PropTypes.string.isRequired,

  shouldRenderAssessmentActions: PropTypes.bool,
  /**
   * Show a modified number of buttons (rank and comment).
   */
  smallCard: PropTypes.bool,
};

export default compose(
  setDisplayName('CandidacyActionButtonsV2(enhanced)'),
  withComponentId(),
  connect(
    state => ({
      employeeUser: isEmployeeUser(state),
    }),
    {},
  ),
  withFeatureCheck('feature.assessment_templates', 'hasAssessmentTemplates'),
)(CandidacyActionButtonsV2);

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/lib/Alert';
import setPreference from 'modules/user/actions/setPreference';
import getPreference from 'modules/user/selectors/getPreference';
import StateProvider from './app/StateProvider';

const PREFERENCE_KEY = 'dismissibleAlerts';

const DismissibleAlert = ({ alertKey, message }) => {
  const wasDismissed = useSelector(state =>
    getPreference(state, [PREFERENCE_KEY, alertKey], false),
  );
  const dispatch = useDispatch();
  const onDismiss = () =>
    dispatch(setPreference([PREFERENCE_KEY, alertKey], true));

  return !wasDismissed ? (
    <Alert bsStyle='danger' onDismiss={onDismiss}>
      {message}
    </Alert>
  ) : null;
};

DismissibleAlert.propTypes = {
  alertKey: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default props => (
  <StateProvider>
    <DismissibleAlert {...props} />
  </StateProvider>
);

import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import getFundingRoundListSelector from '../selectors/getFundingRoundList';
import shouldFetchFundingRoundListSelector from '../selectors/shouldFetchFundingRoundList';
import fetchFundingRoundListActionCreator from '../actions/fetchFundingRoundList';

/**
 * Provides a `fundingRoundList` prop -- the state of a list of funding rounds
 * for a particular company ID. Fetches the list if needed.
 */
export default compose(
  setDisplayName('withFundingRoundListFetched'),
  setPropTypes({
    companyId: PropTypes.number,
  }),
  connect(
    (state, { companyId }) => ({
      shouldFetchFundingRoundList: shouldFetchFundingRoundListSelector(
        state,
        companyId,
      ),
      fundingRoundList: getFundingRoundListSelector(state, companyId),
    }),
    dispatch => ({
      fetchFundingRoundList: bindActionCreators(
        fetchFundingRoundListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchFundingRoundListIfNeeded: ({
      companyId,
      fetchFundingRoundList,
      shouldFetchFundingRoundList,
    }) => () => {
      if (shouldFetchFundingRoundList) {
        fetchFundingRoundList({ companyId: companyId });
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchFundingRoundListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchFundingRoundListIfNeeded();
    },
  }),
);

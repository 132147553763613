import createEntity from 'modules/entities/actions/createEntity';
import documentSchema from '../schema';
import getDocumentListUrl from './getDocumentListUrl';

/**
 * Creates an action for creating a document.
 * @param {Object} payload
 * @param {File} payload.file The file to upload
 * @param {String} payload.documentLabelId The id of the document label
 * @param {String} payload.ownerId The ID of the owning model (search or contact ID, depending on
 *   the ownerType)
 * @param {String} payload.ownerType The type of owning model ("Search", "Contact")
 * @param {String} payload.transactionId A unique ID used to track the request.
 */
export default ({
  documentLabelId,
  file,
  ownerId,
  ownerType,
  transactionId,
}) => {
  // Binary data is submitted via FormData. This also requires us to set contentType to false
  // so that it will get automatically generated with the correct boundary information when the
  // file is encoded and sent to the server.
  const body = new FormData();
  body.append('document[file]', file);

  if (documentLabelId) {
    body.append('document[document_label_id]', documentLabelId);
  }

  return createEntity({
    ownerId: ownerId,
    ownerType: ownerType,
    transactionId: transactionId,

    entityType: documentSchema.key,
    responseSchema: { document: documentSchema },

    url: getDocumentListUrl({ ownerType: ownerType, ownerId: ownerId }),
    body: body,

    // Let content-type header be generated automatically so it contains the correct
    // multi-part boundary ID
    contentType: false,
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  defaultProps,
  mapProps,
  renderComponent,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose';
import { connect } from 'react-redux';
import {
  FILTER_KEY_COMPANY,
  FILTER_KEY_TARGET_COMPANY,
  FILTER_TARGET_COMPANY_NONE,
} from 'modules/candidacies/constants';
import CandidaciesSearchPage from 'modules/candidacy-views/searchPage/CandidaciesSearchPage';
import JobApplicationsSearchPage from 'modules/job-applications/components/searchPage/JobApplicationsSearchPage';
import {
  SEARCH_TYPES,
  TYPE_JOB_SEARCH,
  TYPE_PIPELINE,
  TYPE_TALENT_POOL,
} from 'modules/searches/constants';
import getSearchProperty from 'modules/searches/selectors/getSearchProperty';
import SearchPageViewLink from 'modules/searches/components/SearchPageViewLink';
import TargetCompaniesSearchPage from 'modules/target-companies/components/searchPage/TargetCompaniesSearchPage';
import getJobListingBySearchId from 'modules/job-listings/selectors/getJobListingBySearchId';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import RecommendationsSearchPage from 'modules/recommendations/components/searchPage/RecommendationsSearchPage';
import canEditSearchDetailsSelector from 'modules/searches/selectors/canEditSearchDetails';
import withSearchPageTitle from './withSearchPageTitle';
import SearchPageNotFound from './SearchPageNotFound';

/**
 * These are all possible page tabs keyed by an ID.
 * A withProps HOC below filters which tabs will be shown based on certain conditions.
 * @const {Object.<String, Object>}
 */
const PAGE_TABS = {
  candidates: {
    id: 'candidates',
    title: 'Candidates',
    linkComponent: (
      <SearchPageViewLink page='candidates' title='Candidates'>
        Candidates
      </SearchPageViewLink>
    ),
  },
  people: {
    id: 'people',
    title: 'People',
    linkComponent: (
      <SearchPageViewLink page='people' title='People'>
        People
      </SearchPageViewLink>
    ),
  },
  applicants: {
    id: 'applicants',
    title: 'Applicants',
    linkComponent: (
      <SearchPageViewLink page='applicants' title='Applicants'>
        Applicants
      </SearchPageViewLink>
    ),
  },
  targetCompanies: {
    id: 'target_companies',
    title: 'Target Companies',
    linkComponent: (
      <SearchPageViewLink page='target_companies' title='Target Companies'>
        Target Companies
      </SearchPageViewLink>
    ),
  },
  addRecommendations: {
    id: 'recommendations',
    title: 'Recommendations',
    linkComponent: (
      <SearchPageViewLink page='recommendations' title='Recommendations'>
        Recommendations
      </SearchPageViewLink>
    ),
  },
};

/**
 * The layout is a little unusual for these pages because, so each underyling page component
 * should render a specialized `SearchPageContainer`.
 * See `modules/searches/searchPage/SearchPageContainer.js` for more information.
 */

export default compose(
  setDisplayName('SearchPageContent'),
  setPropTypes({
    searchType: PropTypes.oneOf(SEARCH_TYPES).isRequired,
    searchId: PropTypes.number.isRequired,
    hasRecommendationsFeature: PropTypes.bool,
  }),
  defaultProps({
    searchType: TYPE_JOB_SEARCH,
    selectedTab: 'candidates',
    hasRecommendationsFeature: false,
  }),
  withFeatureCheck('feature.recommend_candidates', 'hasRecommendationsFeature'),
  connect(
    (state, { searchId }) => ({
      recommendationsEnabled: getSearchProperty(
        state,
        searchId,
        'enable_recommendations',
      ),
      jobListing: getJobListingBySearchId(state, searchId),
      user: state.user,
      canEditSearchDetails: canEditSearchDetailsSelector(state),
    }),
    {},
  ),

  // Determine which tabs should be available.
  withPropsOnChange(
    [
      'jobListing',
      'searchType',
      'selectedTab',
      'user',
      'hasRecommendationsFeature',
      'recommendationsEnabled',
    ],
    ({
      canEditSearchDetails,
      hasRecommendationsFeature,
      jobListing,
      recommendationsEnabled,
      searchId,
      searchType,
      selectedTab,
      user,
    }) => {
      const tabs = [];
      const editSearchTab = {
        id: 'edit',
        title: 'Edit Search',
        linkComponent: <a href={`/searches/${searchId}/edit`}>Edit Search</a>,
      };
      const editSuccessionPlanningTab = {
        id: 'edit',
        title: 'Edit Succession Planning',
        linkComponent: (
          <a href={`/talent_pools/${searchId}/edit`}>
            Edit Succession Planning
          </a>
        ),
      };
      const editPipelineTab = {
        id: 'edit',
        title: `Edit Pipeline`,
        linkComponent: (
          <a href={`/pipelines/${searchId}/edit`}>Edit Pipeline</a>
        ),
      };

      // Pipelines will only see the People, Target Companies, and Edit Pipeline tabs
      // Talent Pools will only see the Candidates tab
      // Job Searches will see Candidates and other conditionally rendered tabs
      if (searchType === TYPE_PIPELINE) {
        tabs.push(PAGE_TABS.people);
        tabs.push(PAGE_TABS.targetCompanies);

        if (canEditSearchDetails) {
          tabs.push(editPipelineTab);
        }
      } else {
        tabs.push(PAGE_TABS.candidates);
      }

      if (searchType === TYPE_JOB_SEARCH) {
        // Only show "applicants" tab if either:
        // 1) The job listing is published; or
        // 2) There are pending applicants (from the listing being previously published, presumably)
        if (
          jobListing &&
          (jobListing.get('published') ||
            jobListing.get('applications_pending_count')) &&
          user.get('can_view_applicants_tab')
        ) {
          tabs.push(PAGE_TABS.applicants);
        }

        if (hasRecommendationsFeature && recommendationsEnabled) {
          tabs.push(PAGE_TABS.addRecommendations);
        }

        tabs.push(PAGE_TABS.targetCompanies);
        // Only show edit search when the user has access to edit a search
        if (canEditSearchDetails) {
          tabs.push(editSearchTab);
        }
      }

      if (searchType === TYPE_TALENT_POOL) {
        tabs.push(editSuccessionPlanningTab);
      }

      return {
        // Make sure the selected tab we pass down is one that is available. Otherwise this will
        // set selectedTab to false and the SearchPageNotFound will be rendered.
        selectedTab:
          tabs.findIndex(({ id }) => id === selectedTab) >= 0
            ? selectedTab
            : 'candidates',
        tabs: tabs,
      };
    },
  ),

  // `pageStates` maintains the state of each "tab" page so when we navigate from one to another
  // then back to the original, we can restore the state (like any filter text or sort selection)
  withState('pageStates', 'setPageStates', {}),

  withHandlers({
    onStoreState: ({ pageStates, setPageStates }) => (selectedTab, state) =>
      setPageStates({
        ...pageStates,
        [selectedTab]: state,
      }),
    onTargetCompanySelect: ({ pageStates, setPageStates }) => targetCompany => {
      const { candidates, ...otherPageStates } = pageStates;
      const { filterText: _, ...otherCandidatesStates } = candidates || {};

      // If there is a target company value, then we should filter on it using the `company:"foo"`
      // keyword (because using `targetcompany:"foo"` will only work with ACTIVE target companies)
      // But if there is no value, that means we should filter on the lack of ANY target company --
      // and in that case we need to use `targetcompany:"none"`.
      const newFilterText = targetCompany
        ? `${FILTER_KEY_COMPANY}:"${targetCompany.get('name')}"`
        : `${FILTER_KEY_TARGET_COMPANY}:${FILTER_TARGET_COMPANY_NONE}`;

      setPageStates({
        ...otherPageStates,
        candidates: {
          ...otherCandidatesStates,
          filterText: newFilterText,
          isPriorityOnly: false,
        },
      });
    },
  }),

  // Pull out only the props that are needed for the XxxxSearchPage components.
  mapProps(
    ({
      hasRecommendationsFeature,
      onStoreState,
      onTargetCompanySelect,
      pageStates,
      recommendationsEnabled,
      searchId,
      selectedTab,
      tabs,
    }) => ({
      hasRecommendationsFeature: hasRecommendationsFeature,
      initialState: pageStates[selectedTab],
      onStoreState: onStoreState,
      onTargetCompanySelect: onTargetCompanySelect,
      recommendationsEnabled: recommendationsEnabled,
      searchId: searchId,
      selectedTab: selectedTab,
      tabs: tabs,
    }),
  ),
  withSearchPageTitle,

  // Render the appropriate page based on the selected tab
  branch(
    ({ selectedTab }) => selectedTab === 'candidates',
    renderComponent(CandidaciesSearchPage),
  ),
  branch(
    ({ selectedTab }) => selectedTab === 'people',
    renderComponent(CandidaciesSearchPage),
  ),
  branch(
    ({ selectedTab }) => selectedTab === 'applicants',
    renderComponent(JobApplicationsSearchPage),
  ),
  branch(
    ({ selectedTab }) => selectedTab === 'target_companies',
    renderComponent(TargetCompaniesSearchPage),
  ),
  branch(
    ({ selectedTab }) => selectedTab === 'recommendations',
    renderComponent(RecommendationsSearchPage),
  ),

  // Fall back to the SearchPageNotFound if nothing matches.
)(SearchPageNotFound);

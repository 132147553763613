import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import moment from 'moment';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import routes from 'modules/routing/routes';
import {
  useGetConnectionStrengthsQuery,
  useGetConnectionTypesQuery,
  CONNECTION_TYPE_GROUPS,
  getConnectionTypeLabel,
  RecordTypesWithConnections,
} from 'services/apiV1/connections';

const ConnectionForm = ({
  connectionRecordType,
  contactData,
  initialValues,
}) => {
  const { data: connectionStrengths } = useGetConnectionStrengthsQuery();
  const { data: connectionTypes } = useGetConnectionTypesQuery();

  const referralConnectionTypeIds = connectionTypes
    ?.filter(connection => connection.group === 'Referrals')
    .map(connection => connection.id);

  const yearRange = range(
    new Date().getFullYear(),
    initialValues.year - 100,
    -1,
  );
  const months = moment
    .months()
    .map((label, value) => ({ label: label, value: value }));

  const getConnectionTypesItems = () => {
    const items = [];
    CONNECTION_TYPE_GROUPS.forEach(connectionTypeGroup => {
      const groupItems = connectionTypes?.filter(
        item =>
          item.group === connectionTypeGroup &&
          (connectionRecordType === 'contact'
            ? item.name !== 'Relationship Manager'
            : true),
      );
      if (groupItems?.length) {
        items.push(
          <SelectMenu.Heading key={connectionTypeGroup}>
            {connectionTypeGroup}
          </SelectMenu.Heading>,
        );
        groupItems.forEach(connectionTypeOption => {
          items.push(
            <SelectMenu.Item
              key={connectionTypeOption.id}
              value={connectionTypeOption.id}
            >
              {getConnectionTypeLabel(
                connectionTypeOption,
                contactData?.firstName,
              )}
            </SelectMenu.Item>,
          );
        });
      }
    });
    return items;
  };

  const getFormattedConnectingToOption = option => {
    const { company_name: companyName, email, name } = option;
    if (connectionRecordType === 'user') {
      return name ? `${name} (${email})` : email;
    }
    // connectionRecordType is `contact`
    return companyName ? `${name} (${companyName})` : name;
  };

  return (
    <>
      <Card className='u-padding-24 u-marginBottom-24' isCentered={false}>
        <ContactSnapshot
          avatarUrl={contactData?.avatarUrl}
          className='u-marginBottom-16'
          company={
            contactData?.primaryCompanyName || contactData?.primaryPosition
              ? {
                  name:
                    contactData?.primaryPosition?.company?.name ||
                    contactData?.primaryCompanyName,
                }
              : null
          }
          id={contactData?.id}
          name={contactData?.fullName}
          title={contactData?.primaryTitle}
        />
        <Form.Autocomplete
          className='u-marginBottom-16 u-block'
          formatOption={getFormattedConnectingToOption}
          initialValue={
            initialValues.connectedRecord || {
              id: initialValues?.suggestedUser?.id,
              name: initialValues?.suggestedUser?.name,
            }
          }
          isDisabled={Boolean(initialValues.connectionId)}
          label={`Who are you connecting to ${contactData?.firstName}?`}
          name='connectedRecord'
          onSelect={_val => {}}
          placeholder={`Select a ${connectionRecordType}…`}
          rules={{
            required: {
              value: true,
              message: `Selecting a ${connectionRecordType} is required`,
            },
          }}
          selectedValueDisplayPropertyFallbacks={['name', 'email']}
          size='small'
          url={query =>
            routes.api_v1_autocomplete_query({
              query: {
                query: {
                  // Exclude the user roles from the search results
                  excluded_user_roles: ['Client', 'Client (No Comp)'],
                  // Exclude the current contact from search results
                  excluded_ids:
                    connectionRecordType === 'contact' ? contactData?.id : null,
                  resource: connectionRecordType,
                  term: query || initialValues.connectedRecord?.id,
                },
              },
            })
          }
        />
        <Form.SelectMenu
          data-testid='connectionType'
          initialValue={initialValues.connectionType}
          inputWidth='full'
          label={`How is this ${connectionRecordType} connected to ${contactData?.firstName}?`}
          name='connectionType'
          placeholder='Select connection type'
          rules={{
            required: {
              value: true,
              message: 'Selecting a connection type is required',
            },
          }}
          size='small'
        >
          {getConnectionTypesItems()}
        </Form.SelectMenu>
      </Card>
      <Form.FieldDependentContent
        shouldRender={fields =>
          !referralConnectionTypeIds?.includes(fields.connectionType)
        }
      >
        <h4 className='u-marginBottom-4'>Connection Date</h4>
      </Form.FieldDependentContent>
      <Form.FieldDependentContent
        shouldRender={fields =>
          referralConnectionTypeIds?.includes(fields.connectionType)
        }
      >
        <h4 className='u-marginBottom-4'>Referral Date</h4>
      </Form.FieldDependentContent>
      <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween u-marginBottom-24'>
        <Form.SelectMenu
          className='u-marginRight-8 u-flexItem-grow'
          initialValue={initialValues.month}
          inputWidth='full'
          name='month'
          placeholder='Month'
        >
          {months.map(m => (
            <SelectMenu.Item key={m.value} value={m.value}>
              {m.label}
            </SelectMenu.Item>
          ))}
        </Form.SelectMenu>
        <Form.SelectMenu
          className='u-marginRight-8 u-flexItem-grow'
          initialValue={initialValues.year}
          inputWidth='full'
          isPinnedRight={true}
          name='year'
          placeholder='Year'
        >
          {yearRange.map(y => (
            <SelectMenu.Item key={y} value={y}>
              {
                /* Convert integer to string to trigger the removal of the placeholder text shade */
                y.toString()
              }
            </SelectMenu.Item>
          ))}
        </Form.SelectMenu>
      </div>
      <Form.FieldDependentContent
        shouldRender={fields =>
          referralConnectionTypeIds?.includes(fields.connectionType)
        }
      >
        <Form.Autocomplete
          className='u-block u-marginBottom-16'
          formatOption={option => option.name}
          initialValue={initialValues.referralCompany}
          label='Referral Company'
          name='referralCompany'
          onSelect={_val => {}}
          placeholder='Select a Company…'
          url={query =>
            routes.api_v1_autocomplete_query({
              query: {
                query: {
                  resource: 'company',
                  term: query || initialValues.referralCompany?.id,
                },
              },
            })
          }
        />
      </Form.FieldDependentContent>
      <Form.SelectMenu
        className='u-marginBottom-16'
        initialValue={initialValues.strength}
        inputWidth='full'
        label='Strength'
        name='strength'
      >
        {connectionStrengths?.map(item => (
          <SelectMenu.Item key={item.id} value={item.id}>
            {item.name}
          </SelectMenu.Item>
        ))}
      </Form.SelectMenu>
      <Form.TextArea
        className='u-block'
        initialValue={initialValues.notes}
        label='Notes'
        name='notes'
        placeholder='Enter notes'
        width='full'
      />
    </>
  );
};

ConnectionForm.propTypes = {
  connectionRecordType: PropTypes.oneOf(
    Object.values(RecordTypesWithConnections),
  ),
  contactData: PropTypes.shape({
    avatarUrl: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    primaryCompanyName: PropTypes.string,
    primaryPosition: PropTypes.shape({
      company: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    primaryTitle: PropTypes.string,
  }),
  initialValues: PropTypes.shape({
    connectedRecord: PropTypes.shape({
      email: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    connectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    connectionType: PropTypes.number,
    month: PropTypes.number,
    notes: PropTypes.string,
    referralCompany: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    strength: PropTypes.number,
    suggestedUser: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    year: PropTypes.number,
  }).isRequired,
};

export default ConnectionForm;

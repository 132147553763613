import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setStatic, withHandlers } from 'recompose';

import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';

import SelectAllCheckbox from './SelectAllCheckbox';
import withSearchSummaryReportFieldLabels from './withSearchSummaryReportFieldLabels';

/**
 * A field for selecting rejection fields to include in the search summary report.
 */
const SearchSummaryReportRejectionInfoField = ({
  fieldLabels,
  fieldState,
  handleFieldChange,
  handleToggleAll,
}) => {
  const fields = fieldState.getValue();
  const isAllSelected =
    fields.count() && fields.every(field => field.getValue());

  return (
    <div className='SearchSummaryReportRejectionInfoField'>
      <h2>
        Rejection Details
        <SelectAllCheckbox
          onChange={handleToggleAll}
          value={Boolean(isAllSelected)}
        />
      </h2>
      <div className='row'>
        {fields
          .map(field => (
            <CheckboxField
              checked={field.getValue()}
              className='col-4'
              fieldState={field}
              key={field.getName()}
              onChange={handleFieldChange}
              text={fieldLabels[field.getName()]}
            />
          ))
          .toArray()}
      </div>
    </div>
  );
};

SearchSummaryReportRejectionInfoField.propTypes = {
  /**
   * A mapping of search summary report field names to their labels
   */
  fieldLabels: PropTypes.objectOf(PropTypes.string),

  /**
   * The current value of this field.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the fieldState changes for a nested field of the `fieldState`.
   */
  handleFieldChange: PropTypes.func.isRequired,

  /**
   * Called when the "all" option is toggled. Argument is the event which triggered the
   * toggle, in which the `checked` property of the target is set to the desired value.
   */
  handleToggleAll: PropTypes.func.isRequired,
};

SearchSummaryReportRejectionInfoField.createFieldState = (
  name = 'rejectionInfo',
  values = {},
  validator,
  _convertToRaw,
  ...rest
) =>
  FieldState.createNested(
    name,
    [
      CheckboxField.createFieldState(
        'rejection_comments',
        Boolean(values.commentsChecked),
      ),
      CheckboxField.createFieldState(
        'rejection_reason',
        Boolean(values.reasonChecked),
      ),
      CheckboxField.createFieldState(
        'stage_at_rejection',
        Boolean(values.stageAtRejectionChecked),
      ),
    ],
    validator,

    // convertToRaw implementation returns an array of strings of the checked options.
    fieldValues =>
      Object.keys(fieldValues).filter(fieldName => fieldValues[fieldName]),
    ...rest,
  );

export default compose(
  setDisplayName('SearchSummaryReportRejectionInfoField(enhanced)'),
  setStatic(
    'createFieldState',
    SearchSummaryReportRejectionInfoField.createFieldState,
  ),
  withHandlers({
    handleFieldChange: ({ fieldState, onChange }) => childFieldState =>
      onChange(fieldState.setNestedField(childFieldState)),

    handleToggleAll: ({ fieldState, onChange }) => e => {
      const values = fieldState.getValue();
      onChange(
        fieldState.setValue(
          values.map(field => field.setValue(e.target.checked)),
        ),
      );
    },
  }),
  withSearchSummaryReportFieldLabels,
)(SearchSummaryReportRejectionInfoField);

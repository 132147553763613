import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@thrivetrm/ui/components/Icon';
import DuplicateContactsViewCard from './DuplicateContactsViewCard';
/**
 * Renders the Contact form in a modal.
 */
const DuplicateContactsView = ({
  duplicateContactsWithName,
  duplicateContactsWithPersonalEmail,
  duplicateContactsWithWorkEmail,
  onDuplicateSelect,
}) => {
  return (
    <>
      <div className='u-inlineFlex u-backgroundColor-gray50 u-marginRight-8 Icon--hasBackground'>
        <Icon className='u-margin-8' color='white' size='small' type='alert' />
      </div>
      <span className='u-textColor-gray60'>
        Hold on, matches were found for this person.{' '}
        <span className='u-fontWeight-bold'>
          Please review to not create a duplicate contact.
        </span>
      </span>
      {duplicateContactsWithName?.length > 0 ? (
        <DuplicateContactsViewCard
          duplicateContactData={duplicateContactsWithName}
          matchTypeName='Name'
          onDuplicateSelect={onDuplicateSelect}
        />
      ) : null}
      {duplicateContactsWithWorkEmail?.length > 0 ? (
        <DuplicateContactsViewCard
          duplicateContactData={duplicateContactsWithWorkEmail}
          matchTypeName='Work Email'
          onDuplicateSelect={onDuplicateSelect}
        />
      ) : null}
      {duplicateContactsWithPersonalEmail?.length > 0 ? (
        <DuplicateContactsViewCard
          duplicateContactData={duplicateContactsWithPersonalEmail}
          matchTypeName='Personal Email'
          onDuplicateSelect={onDuplicateSelect}
        />
      ) : null}
    </>
  );
};

const duplicateContactDataPropTypes = {
  companyName: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  positionName: PropTypes.string,
  workEmail: PropTypes.string,
};

DuplicateContactsView.propTypes = {
  duplicateContactsWithName: PropTypes.arrayOf(
    PropTypes.shape(duplicateContactDataPropTypes),
  ),
  duplicateContactsWithPersonalEmail: PropTypes.arrayOf(
    PropTypes.shape(duplicateContactDataPropTypes),
  ),
  duplicateContactsWithWorkEmail: PropTypes.arrayOf(
    PropTypes.shape(duplicateContactDataPropTypes),
  ),
  /**
   * When provided and duplicate contacts are found, the duplicate contact will display a
   * "use this contact" link. When that is click, this callback is called with the contact ID
   * of the contact that was selected.
   */
  onDuplicateSelect: PropTypes.func,
};

export default DuplicateContactsView;

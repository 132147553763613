import { mapProps } from 'recompose';
import { PARENT_CANDIDACY, PARENT_SEARCH } from '../constants';

/**
 * A higher-order component that provides a `parentId` and `parentType` value based on whether
 * it is given a `searchId` or `candidacyId` prop.
 */
export default mapProps(({ candidacyId, searchId, ...props }) => ({
  ...props,
  parentType: candidacyId ? PARENT_CANDIDACY : PARENT_SEARCH,
  parentId: candidacyId || searchId,
}));

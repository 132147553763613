import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import Modal from '@thrivetrm/ui/components/Modal';
import TextInput from '@thrivetrm/ui/components/TextInput';
import CopyButton from '@thrivetrm/ui/components/CopyButton';
import Card from '@thrivetrm/ui/components/Card';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import { useApiPost } from '@thrivetrm/ui/hooks/useApi';
import routes from 'modules/routing/routes';
import successImage from '../../../images/icons/png/icons-success.png';

const SkyminyrIntegrationPage = ({ initialToken }) => {
  const [isModalOpen, openModal, closeModal] = useToggle(false);

  const [fetchToken, isFetching, fetchError, skyminyrToken] = useApiPost(
    routes.api_integrations_skyminyr_residencies(),
  );

  const handleGenerateToken = () => {
    fetchToken();
    openModal();
  };

  const latestToken = skyminyrToken?.skyminyr_residency_token || initialToken;

  return (
    <div className='SkyminyrIntegrationPage'>
      <h2 className='u-marginBottom-8'>HelloSky + Thrive Integration</h2>
      <p className='u-textColor-gray60'>
        Once configured, this integration will allow you to take people from
        HelloSky and add them into Thrive.
      </p>

      <h3 className='u-marginBottom-4 u-marginTop-32'>1. Generate API Token</h3>
      <p className='u-textColor-gray60'>
        First, generate a Thrive API token that will let HelloSky talk to Thrive
        and send over contacts.
      </p>
      {!latestToken ? (
        <ButtonPrimary
          className='u-marginTop-8'
          icon='add'
          isDisabled={isFetching}
          isOutline={true}
          label='Generate API Token'
          onClick={handleGenerateToken}
        />
      ) : (
        <Card className='u-backgroundColor-gray5 u-border-n' isCentered={false}>
          <h3 className='u-margin-n'>Thrive API Token</h3>
          <div className='u-flex u-marginTop-8'>
            <TextInput
              className='u-flexItem-grow'
              inputWidth='full'
              onChange={() => {}}
              value={latestToken}
            />
            <CopyButton className='u-marginLeft-16' textToCopy={latestToken} />
            <ButtonSecondary
              icon='refresh'
              isDisabled={isFetching}
              isLoading={isFetching}
              label='Regenerate Thrive API Token'
              onClick={handleGenerateToken}
            />
          </div>
        </Card>
      )}

      <h3 className='u-marginBottom-4 u-marginTop-32'>
        2. Set up integration in HelloSky
      </h3>
      <p className='u-textColor-gray60'>
        Next, use the Thrive API Token above to set up the integration in
        HelloSky.
      </p>

      {isModalOpen ? (
        <Modal
          isOpen={true}
          onClose={closeModal}
          size='small'
          title='Thrive API Token'
        >
          <Modal.Body className='u-paddingTop-12'>
            <LoadingContainer isLoading={isFetching} minHeight='25vh'>
              {fetchError && !isFetching ? (
                <Card type='error'>
                  There was an error while creating API Token.
                </Card>
              ) : null}
              {!(isFetching || fetchError) ? (
                <>
                  <div className='u-flex u-flexAlign-c u-flexJustify-c'>
                    <img
                      alt='success'
                      className='SkyminyrIntegrationPage__successImage'
                      src={successImage}
                    />
                    <h2 className='u-marginTop-12 u-marginBottom-16 u-marginLeft-8'>
                      Success!
                    </h2>
                  </div>
                  <p className='u-marginVertical-8'>
                    Below is your newly generated Thrive API Token
                  </p>
                  <Card
                    className='u-backgroundColor-gray5 u-border-n'
                    isCentered={false}
                  >
                    <h3 className='u-margin-n'>Thrive API Token</h3>
                    <div className='u-flex u-marginTop-8'>
                      <TextInput
                        className='u-flexItem-grow'
                        inputWidth='full'
                        onChange={() => {}}
                        value={latestToken}
                      />
                      <CopyButton
                        className='u-marginLeft-16'
                        textToCopy={latestToken}
                      />
                    </div>
                  </Card>
                </>
              ) : null}
            </LoadingContainer>
          </Modal.Body>
        </Modal>
      ) : null}
    </div>
  );
};

SkyminyrIntegrationPage.defaultProps = {
  initialToken: null,
};

SkyminyrIntegrationPage.propTypes = {
  initialToken: PropTypes.string,
};

export default SkyminyrIntegrationPage;

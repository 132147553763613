import React from 'react';
import PropTypes from 'prop-types';
import CandidacyMoveStageModalButton from '../stages/CandidacyMoveStageModalButton';

/**
 * A table cell that renders the move stage button for a candidacy ID.
 */
const CandidacyMoveStageButtonCell = ({ datum: candidacyId }) => (
  <div className='CandidacyMoveStageButtonCell btn-group btn-group-sm'>
    <CandidacyMoveStageModalButton candidacyId={candidacyId} />
  </div>
);

CandidacyMoveStageButtonCell.propTypes = {
  /**
   * The Candidacy ID
   */
  datum: PropTypes.number,
};

export default CandidacyMoveStageButtonCell;

import selectn from 'selectn';
import { compose, withHandlers, withProps } from 'recompose';
import withTransaction from 'modules/transactions/components/withTransaction';
import withContactActions from '../withContactActions';

import withFormState from '../../../../components/forms/withFormState';
import ContactSummaryReportField from './ContactSummaryReportField';

/**
 * A higher-order component generator providing props for creating a component that takes
 * a ContactSummaryReportField's fieldState and provides its underlying form functionality
 */
export default compose(
  withContactActions,
  // Create a formState from the `ContactSummaryReportField`'s fieldState creator.
  withFormState(props =>
    ContactSummaryReportField.createFieldState(
      'contactSummaryReport',
      props.currentUserEmail,
    ),
  ),

  withHandlers({
    // Handles the underlying fieldState change.
    handleFieldStateChange: ({ formState, onChange }) => fieldState =>
      onChange(formState.setFieldState(fieldState)),

    // Called by withTransaction when a transaction we started (in `handleSubmit`)
    // has been completed.
    onTransactionComplete: ({ formState, onChange }) => transaction =>
      onChange(formState.endSubmit(selectn('payload.error', transaction))),
  }),

  // provides `startTransaction` and `onTransactionComplete` props to track our submission
  withTransaction,

  withHandlers({
    // Submits the request by displaying the `createReport` action with the values of the fieldState
    handleSubmit: ({
      contactActions,
      contactId,
      formState,
      onChange,
      startTransaction,
    }) => e => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }

      const transactionId = startTransaction();
      const { emails, ...options } = formState.getFieldValue();
      contactActions.createReport({
        report: 'contact_summary_report',
        options: { ...options },
        emails: emails,
        contactId: contactId,
        transactionId: transactionId,
      });
      onChange(formState.startSubmit(transactionId));
    },
  }),

  // Some helper props that can be used by the wrapped component.
  withProps(({ formState }) => ({
    isComplete:
      formState.wasSubmitted() &&
      !formState.getError() &&
      !formState.isSubmitting(),
    isSubmitting: formState.isSubmitting(),
    wasSubmitted: formState.wasSubmitted(),
    error: formState.getError(),
  })),
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import transactionsState from 'modules/transactions/propTypes/transactionsState';

export default function withEditableSection(WrappedComponent, options = {}) {
  class EditableSection extends Component {
    constructor(props) {
      super(props);

      this.componentId = uniqueId();
      this.state = { isEditing: false };
    }

    setEditing = isEditing => {
      this.setState({ isEditing: isEditing });
    };

    handleToggle = event => {
      if (event && event.preventDefault) {
        event.preventDefault();
      }

      this.setState(({ isEditing }) => ({ isEditing: !isEditing }));
    };

    renderTitle() {
      const {
        buttons: propButtons,
        sectionId,
        sectionNotPresent,
        sectionTitle,
        sectionToolTip,
        titleClassName,
      } = this.props;
      const { isEditing } = this.state;

      if (!sectionTitle) {
        return null;
      }

      const buttons = [];
      const addEditButton = (
        <button
          className='editable-section-toggle btn btn-link'
          key='add-edit'
          onClick={this.handleToggle}
          type='button'
        >
          <i
            className={classnames('fa', {
              'fa-plus-circle': sectionNotPresent,
              'fa-pencil': !sectionNotPresent,
            })}
            id={this.componentId}
          />
        </button>
      );

      if (sectionNotPresent && sectionToolTip) {
        const key = `editable-section-${sectionId}-overlay`;

        const tooltip = (
          <Tooltip id={this.componentId}>
            <div>{sectionToolTip}</div>
          </Tooltip>
        );

        const overlayTrigger = (
          <OverlayTrigger key={key} overlay={tooltip} placement='right'>
            {addEditButton}
          </OverlayTrigger>
        );

        buttons.push(overlayTrigger);
      } else {
        buttons.push(addEditButton);
      }

      if (propButtons) {
        propButtons.forEach(button => buttons.push(button));
      }

      return (
        <h3
          className={classnames(
            'editable-section-header',
            'u-margin-n',
            'u-marginBottom-8',
            titleClassName,
          )}
        >
          <span>{sectionTitle}</span>
          {isEditing ? null : buttons}
        </h3>
      );
    }

    render() {
      const {
        /* eslint-disable no-unused-vars */
        buttons,
        isInitiallyEditing,
        onRemoveSection,
        sectionClassName,
        sectionId,
        sectionNotPresent,
        sectionTitle,
        sectionToolTip,
        /* eslint-enable no-unused-vars */
        ...props
      } = this.props;
      const { isEditing } = this.state;

      const className = classnames('editable-section', sectionClassName, {
        'is-editing': isEditing,
        'is-without-content': sectionNotPresent,
      });

      const isShowingContent = isEditing || (!isEditing && !sectionNotPresent);

      return (
        <div className={className}>
          {this.renderTitle()}
          {!isShowingContent ? null : (
            <div className='editable-section-content'>
              <WrappedComponent
                {...props}
                isEditing={isEditing}
                setEditing={this.setEditing}
                toggleEditing={this.handleToggle}
              />
            </div>
          )}
        </div>
      );
    }
  }

  EditableSection.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.node),

    clearTransaction: PropTypes.func,

    contact: ImmutablePropTypes.mapContains({
      data: ImmutablePropTypes.mapContains({
        id: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,

    isInitiallyEditing: PropTypes.bool,

    onRemoveSection: PropTypes.func,

    sectionClassName: PropTypes.string,

    sectionId: PropTypes.string,

    sectionNotPresent: PropTypes.bool,

    sectionTitle: PropTypes.node,

    sectionToolTip: PropTypes.string,

    titleClassName: PropTypes.string,

    transactions: transactionsState,
  };

  EditableSection.defaultProps = {
    isInitiallyEditing: false,
    sectionClassName: options.sectionClassName,
    sectionId: options.sectionId,
    sectionTitle: options.title,
    titleClassName: options.titleClassName,
  };

  return EditableSection;
}

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, setPropTypes, setDisplayName, withHandlers } from 'recompose';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';

/**
 * Renders the delete button for a single company revenue record field.
 */
export const CompanyRevenueDeleteToggle = ({
  disabled,
  fieldState,
  onClick,
}) => (
  <button
    className='CompanyRevenueDeleteToggle btn btn-link'
    disabled={disabled}
    onClick={onClick}
    type='button'
  >
    <i
      className={classnames('fa', {
        'fa-trash': !fieldState.getNestedFieldValue('_destroy'),
        'fa-undo': Boolean(fieldState.getNestedFieldValue('_destroy')),
      })}
    />
  </button>
);

CompanyRevenueDeleteToggle.propTypes = {
  /**
   * True to disable button
   */
  disabled: PropTypes.bool,

  /**
   * The field state containing the nested values
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * Called when the button is clicked.
   */
  onClick: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CompanyRevenueDeleteToggle(enhanced)'),
  setPropTypes({
    /**
     * Whether the button should be disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Called when the underlying fieldState is changed (when the `_destroy` property should be
     * toggled).
     */
    onChange: PropTypes.func.isRequired,
  }),

  // Create an onClick handler that toggles the value of the `_destroy` property.
  withHandlers({
    onClick: ({ fieldState, onChange }) => e => {
      e.preventDefault();
      if (fieldState.getNestedFieldValue('_destroy')) {
        onChange(fieldState.setNestedFieldValue('_destroy', false));
      } else {
        onChange(fieldState.setNestedFieldValue('_destroy', true));
      }
    },
  }),
)(CompanyRevenueDeleteToggle);

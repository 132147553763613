import { schema } from 'normalizr';
import userSchema from 'modules/users/schema';
import { contactSchema, contactReviewSchema } from 'modules/contacts/schema';

const networkSchema = new schema.Entity('networks', {
  user: userSchema,
});

contactSchema.define({
  networks: [networkSchema],
});

contactReviewSchema.define({
  networks: [networkSchema],
});

export default networkSchema;

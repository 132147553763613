import PropTypes from 'prop-types';
import React from 'react';
import RocketReachListItem from './RocketReachListItem';

const FA = {
  googleplus: 'google-plus',
  stackexchange: 'stack-exchange',
  stackoverflow: 'stack-overflow',
  wikipedia: 'wikipedia-w',
  ycombinator: 'y-combinator',
};

/**
 * Renders a list of contact links within the RocketReach component.
 * List items consist of a contact link accompanied by the associated
 * Font Awesome icon or the globe if the icon is not listed in the fa object.
 */
const RocketReachLinkList = ({ data }) => (
  <div className='rocketreach-contact-attributes-ul'>
    <span className='rocketreach-title'>Social/Professional Links:</span>
    <ul className='rocketreach-ul'>
      {Object.keys(data).map(link => (
        <RocketReachListItem
          icon={<i className={`link fa fa-globe fa-${FA[link] || link}`} />}
          key={link}
          value={data[link]}
        />
      ))}
    </ul>
  </div>
);

RocketReachLinkList.propTypes = {
  data: PropTypes.objectOf(PropTypes.string),
};

export default RocketReachLinkList;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import EditableSection from 'modules/forms/components/EditableSection';
import ContactJobOpportunityStatusForm from './ContactJobOpportunityStatusForm';
import ContactJobOpportunityStatusView from './ContactJobOpportunityStatusView';

/**
 * Renders an EditableSection that displays and allows editing of a contact's
 * job opportunity status related fields.
 */
export default compose(
  setDisplayName('ContactJobOpportunityStatusEditableSection'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  mapProps(({ contact, contactId }) => ({
    contactId: contactId,
    children: ContactJobOpportunityStatusView.willRender({
      contact: contact,
    }) && <ContactJobOpportunityStatusView contactId={contactId} />,
    formComponent: ContactJobOpportunityStatusForm,
    title: 'Job/Opportunity Status',
  })),
)(EditableSection);

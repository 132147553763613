import PropTypes from 'prop-types';
import { compose, mapProps, setPropTypes } from 'recompose';
import withFilteredTaskIds from './withFilteredTaskIds';

export default compose(
  setPropTypes({
    currentFilter: PropTypes.string.isRequired,
    currentView: PropTypes.string.isRequired,
  }),
  withFilteredTaskIds,
  mapProps(({ taskIds }) => ({
    className: 'tasks-filter-count',
    children: `(${taskIds.size})`,
  })),
)('div');

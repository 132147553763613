import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import {
  FUND_LIST_FETCH_START,
  FUND_LIST_FETCH_SUCCESS,
  FUND_LIST_FETCH_FAILURE,
} from './actionTypes';
import fundSchema from '../schema';

/**
 * Creates an action for fetching a list of all funds for the current tenant
 */
export default compose(
  fetchAction,
  normalizeResponse({ funds: [fundSchema] }),
  createAsyncActions({
    startActionType: FUND_LIST_FETCH_START,
    successActionType: FUND_LIST_FETCH_SUCCESS,
    failureActionType: FUND_LIST_FETCH_FAILURE,
  }),
  withOptions({
    url: routes.api_v1_funds(),
  }),
);

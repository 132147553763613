import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import UserName from 'modules/users/components/UserName';
import mapBulkImportIdToBulkImport from './mapBulkImportIdToBulkImport';

export default compose(
  setDisplayName('BulkImportUserName(enhanced)'),
  setPropTypes({
    bulkImportId: PropTypes.number.isRequired,
  }),
  mapBulkImportIdToBulkImport,
  mapProps(({ bulkImport }) => ({
    userId: bulkImport && bulkImport.get('created_by_id'),
  })),
  // don't render anything if there's no userId
  branch(({ userId }) => !userId, renderNothing),
)(UserName);

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'modules/core/componentsLegacy/Modal';
import TaskForm from './TaskForm';
import getTask from '../selectors/getTask';

const TaskFormModal = ({ onHide, onSaved, taskId }) => {
  const task = useSelector(state => getTask(state, taskId));

  return (
    <Modal aria-labelledby='modal-title' onHide={onHide} show={true}>
      <Modal.Header closeButton={true}>
        <Modal.Title id='modal-title'>
          {taskId ? 'Update' : 'Add'} Task
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TaskForm
          draftStoragePath={{
            task: taskId || 'new',
          }}
          onCancel={onHide}
          onSaved={onSaved}
          showContactSelect={true}
          task={task}
        />
      </Modal.Body>
    </Modal>
  );
};

TaskFormModal.propTypes = {
  onHide: PropTypes.func,
  onSaved: PropTypes.func,
  taskId: PropTypes.number,
};

export default TaskFormModal;

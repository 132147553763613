import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, setPropTypes } from 'recompose';
import getTargetCompany from '../selectors/targetCompanies/getTargetCompany';

/**
 * A Higher order component that filters a list of targetCompanyIds to only include those that have
 * a positive `priority_vote_count`.
 */
export default compose(
  connect((state, { targetCompanyIds }) => {
    if (!targetCompanyIds || targetCompanyIds.size < 1) {
      return {};
    }

    return {
      targetCompanyIds: targetCompanyIds.filter(
        targetCompanyId =>
          getTargetCompany(state, targetCompanyId).get('priority_vote_count') >
          0,
      ),
    };
  }),
  setPropTypes({
    targetCompanyIds: ImmutablePropTypes.listOf(PropTypes.number),
  }),
);

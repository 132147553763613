import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { compose, lifecycle, setDisplayName, withHandlers } from 'recompose';
import { fetchTasks as fetchTasksActionCreator } from '../actions';
import getShouldFetchTasks from '../selectors/shouldFetchTasks';

/**
 * Fetches (the current user's) tasks
 * if they need to be fetched. Connects taskActions if they are
 * not already connected.
 */

export default compose(
  setDisplayName('withTasksFetched'),
  connect(
    state => ({
      shouldFetchTasks: getShouldFetchTasks(state),
    }),
    dispatch => ({
      fetchTasks: bindActionCreators(fetchTasksActionCreator, dispatch),
    }),
  ),
  withHandlers({
    /**
     * A handler that, when called, only fetches the introduction list if needed.
     */
    fetchTasksIfNeeded: ({ fetchTasks, shouldFetchTasks }) => () =>
      shouldFetchTasks && fetchTasks(),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchTasksIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchTasksIfNeeded();
    },
  }),
);

import { List } from 'immutable';
import { debounce } from 'lodash';

const QUERY_NAMES = ['name', 'email', 'work_email'];
const DELAY = 300;

const intermittently = debounce(fn => fn(), DELAY);

/**
 * Query IDs are the combination of a *unique* component ID.
 * @private
 * @param  {String} componentId The unique ID of a component.
 * @param  {String} fieldName The name of the field that query results will be associated with.
 * @return {String} A concatenation of the two arguments.
 */
const getQueryId = (componentId, fieldName) => `${componentId}-${fieldName}`;

/**
 * Maps over all possible field names, and returns the query IDs for each.
 * @public
 * @param  {String} componentId [description]
 * @return {String}             [description]
 */
const getDuplicateContactQueryIds = componentId =>
  QUERY_NAMES.map(getQueryId.bind(null, componentId));

/**
 * @private
 * @param  {Object} payload The query parameters that will be sent to the server.
 * @return {Boolean} Whether or not the payload is valid.
 */
const isValidQuery = payload =>
  payload &&
  Object.keys(payload.term).every(
    key => payload.term[key] && payload.term[key].trim(),
  );

/**
 * Builds a payload that will be dispatched with `queryDuplicateContacts`.
 * @private
 * @param  {String} queryId The identifier for the `query`.
 * @param  {String} fieldName The name fo the field that changed.
 * @param  {Object} values The current representation of the contact record.
 * @return {Object|null} An object with a `queryId` and `term`. Term is an object
 *   that will be sent to the server as query parameters. The `term` is used
 *   to prevent queries from colliding. In the case of an unknown field, this
 *   function will return null.
 */
const getPayload = (queryId, fieldName, values) => {
  switch (fieldName) {
    case 'first_name':
    case 'last_name':
    case 'name':
      return {
        queryId: `${queryId}-name`,
        term: {
          first_name: values.first_name,
          last_name: values.last_name,
        },
      };
    case 'email':
      return {
        queryId: `${queryId}-email`,
        term: { email: values.email },
      };
    case 'work_email':
      return {
        queryId: `${queryId}-work_email`,
        term: { email: values.work_email },
      };
    default:
      return null;
  }
};

/**
 * This function builds a payload to be sent to the server. If the query is valid,
 * and the user has stopped typing, we'll invoke the action, which will trigger a
 * request.
 * @public
 * @param  {String} componentId The unique identifier for the component
 * @param  {String} fieldName The name of the field that has changed
 * @param  {Object} values The current representation of the contact
 * @param  {Function} invokeQuery A function to invoke the query action. This function
 *   will receive a payload containing `term` and `queryId`.
 * @param {boolean} immediately will determine if the payload should be called with
 *  a delay or not
 * @return {undefined}
 */
const queryDuplicateContacts = (
  componentId,
  fieldName,
  values,
  invokeQuery,
  immediately,
) => {
  const payload = getPayload(componentId, fieldName, values);

  if (payload && isValidQuery(payload)) {
    if (immediately) {
      invokeQuery(payload);
    }
    intermittently(() => invokeQuery(payload));
  }
};

/**
 * Check for duplicates on all fields. This is used for immediate dup recognition
 * on page load.
 */
const queryAllDuplicateContacts = (
  componentId,
  values,
  invokeQuery,
  immediately,
) =>
  QUERY_NAMES.forEach(fieldName =>
    queryDuplicateContacts(
      componentId,
      fieldName,
      values,
      invokeQuery,
      immediately,
    ),
  );

/**
 * Builds an object keyed on fieldName, with values as Lists containing matching
 * contact records. If the query results aren't present, we'll just return an empty
 * List. This makes component logic much simpler.
 * @public
 * @param  {String} componentId The unique identifier for the component
 * @param  {Immutable.Map} contacts The entire contacts state
 * @return {Object<Immutable.List>} A mapping of fieldNames and lists of contacts
 */
const getDuplicateContacts = (componentId, contacts) =>
  QUERY_NAMES.reduce(
    (acc, name) => ({
      ...acc,
      [name]: contacts.getIn(
        ['duplicatesById', getQueryId(componentId, name), 'data'],
        new List(),
      ),
    }),
    {},
  );

export {
  getDuplicateContacts,
  getDuplicateContactQueryIds,
  queryDuplicateContacts,
  queryAllDuplicateContacts,
};

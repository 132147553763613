import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import { SEARCH_PAGE_BASE_PATHS } from 'modules/searches/constants';

import mapCandidacyIdToSearchId from './mapCandidacyIdToSearchId';
import mapCandidacyIdToSearchType from './mapCandidacyIdToSearchType';

/**
 * Adds an href property that links to a particular candidacy in tab in the search sidebar.
 */
export default compose(
  setDisplayName('withSearchCandidacyHref'),
  setPropTypes({
    candidacyId: PropTypes.number,
    tab: PropTypes.string,
  }),
  mapCandidacyIdToSearchId,
  mapCandidacyIdToSearchType,
  mapProps(({ candidacyId, searchId, searchType, tab, ...rest }) => {
    // The search sidebar uses the URL for various states, as does the actual search PAGE content.
    // In some cases, this page is the search edit page -- and the routes get totally fucked up and
    // start making absolutely no sense because two different unrelated parts of the page are
    // competing to control the same parts of the URL. It's ridiculous. This needs to be fixed
    // because it makes no sense. But for now we have to do this hacky bullshit to make it work.
    // When we're on the "edit" page, we don't know the `searchType` or `searchId` because it's not
    // sent down with the candidacy data we have. So we have to try to get it out of the existing
    // URL. Which is completely absurd because it's going to break without warning if the URLs
    // ever happen to change it some way. Which they most definitely will.

    if (!searchType || !searchId) {
      // HACK: Assumes we're at some URL with the format `/<searchType>/<searchId>`
      return {
        ...rest,

        href:
          `${window.location.pathname.split('/', 3).join('/')}/` +
          `candidates/${candidacyId}/${tab || 'overview'}`,
      };
    }

    const base = searchType === 'Pipeline' ? 'people' : 'candidates';

    return {
      ...rest,

      href:
        `${SEARCH_PAGE_BASE_PATHS[searchType]}/${searchId}/` +
        `${base}/${candidacyId}/${tab || 'overview'}`,
    };
  }),
);

export const shouldFetchDefault = (
  state,
  { dataKey = 'ids', metaKey = '_meta' } = {},
) => {
  if (!state) {
    return true;
  }

  if (state.getIn([metaKey, 'isFetching'])) {
    return false;
  }

  if (!state.get(dataKey) && !state.hasIn([metaKey, 'error'])) {
    return true;
  }

  return state.getIn([metaKey, 'isInvalidated']);
};

export default function fetchIfNeeded(
  invokeFetch,
  selector,
  { shouldFetch = shouldFetchDefault } = {},
) {
  return (...payload) => (dispatch, getState) => {
    if (shouldFetch(selector(getState(), ...payload))) {
      return dispatch(invokeFetch(...payload));
    }

    return false;
  };
}

import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getJobApplicationById from './getJobApplicationById';

/**
 * Gets a sortable value for the job applicant's name
 * @param {Object} state
 * @param {Number} id The job application ID
 * @return {?String} The name of the job application appropriate for use in sorting.
 */
const getJobApplicationSortName = (state, jobApplicationId) => {
  const jobApplication = getJobApplicationById(state, jobApplicationId);
  return (
    jobApplication &&
    stringUtils.toSortableString(
      [jobApplication.get('last_name'), jobApplication.get('first_name')].join(
        ' ',
      ),
    )
  );
};

export default getJobApplicationSortName;

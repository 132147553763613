import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';

import mapTargetCompanyIdToTargetCompany from './mapTargetCompanyIdToTargetCompany';
import TargetCompanyCommentsView from './TargetCompanyCommentsView';

/**
 *
 * Renders a button that displays a comment icon and the number of comments for the
 * target company supplied. When clicked, an overlay is shown that displays the comments
 */
const TargetCompanyCommentsButton = ({
  btnClassName,
  className,
  componentId,
  disabled,
  getScrollContainer,
  getTargetRef,
  isOpen,
  onClick,
  onClose,

  // withScrollContainerRef props
  setTargetRef,
  size,
  targetCompany,
}) => (
  <div className={className}>
    <button
      aria-expanded={isOpen}
      aria-haspopup='true'
      className={classnames(btnClassName, 'btn', {
        [`btn-${size}`]: Boolean(size),
      })}
      disabled={Boolean(disabled)}
      onClick={onClick}
      ref={setTargetRef}
      type='button'
    >
      <i className='icon-comment-bubble btn-icon' />
      <span className='comment-count btn-label'>
        {targetCompany.get('search_target_company_comments_count') || 0}
      </span>
    </button>
    <Overlay
      container={getScrollContainer}
      containerPadding={20}
      id={`${componentId}-overlay`}
      onHide={onClose}
      placement='bottom'
      rootClose={true}
      shouldUpdatePosition={true}
      show={isOpen}
      target={getTargetRef}
    >
      <Popover
        className='company-comment-button-popover'
        id={`${componentId}-popover`}
        title='Comments'
      >
        {isOpen && (
          <TargetCompanyCommentsView
            targetCompanyId={targetCompany.get('id')}
          />
        )}
      </Popover>
    </Overlay>
  </div>
);

TargetCompanyCommentsButton.propTypes = {
  /**
   * Props provided by withScrollContainerRef (getScrollContainer, getTargetRef, setTargetRef)
   */
  ...withScrollContainerRef.propTypes,

  /**
   * The class name to apply to the button element.
   */
  btnClassName: PropTypes.string,

  /**
   * The class name to apply to the outer component.
   */
  className: PropTypes.string,

  /**
   * A unique ID for this component instance.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * True to disable the button
   */
  disabled: PropTypes.bool,

  /**
   * True when the user is viewing the comments popover
   */
  isOpen: PropTypes.bool,

  /**
   * Called when the comments button is clicked.
   */
  onClick: PropTypes.func,

  /**
   * Called when the overlay should be hidden.
   */
  onClose: PropTypes.func.isRequired,

  /**
   * The button size to render
   */
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),

  targetCompany: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
    search_target_company_comments_count: PropTypes.number.isRequired,
  }).isRequired,
};

export default compose(
  withComponentId(),
  withScrollContainerRef,
  mapTargetCompanyIdToTargetCompany,
)(TargetCompanyCommentsButton);

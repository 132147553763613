import some from 'lodash/some';

export default options =>
  // eslint-disable-next-line func-names
  $(document).on('submit', options.form, function (_e) {
    const inputs = $(this).find(options.search);
    const sortField = $(this).find(options.sort);

    if (some(inputs, el => Boolean($(el).val()))) {
      sortField.val('relevance');
    }
    if (!sortField.val()) {
      sortField.val('');
    }

    return true;
  });

import mapDatumToCandidacyId from './mapDatumToCandidacyId';
import CandidacyDateAdded from '../CandidacyDateAdded';
import CandidacyMoveStageButtonCell from './CandidacyMoveStageButtonCell';
import CandidacySearchClientCompanyNameCell from './CandidacySearchClientCompanyNameCell';
import CandidacySearchCurrentContactCell from './CandidacySearchCurrentContactCell';
import CandidacySearchFullNameCell from './CandidacySearchFullNameCell';
import CandidacySearchLeadNameCell from './CandidacySearchLeadNameCell';
import CandidacySearchIndustryListCell from './CandidacySearchIndustryListCell';
import CandidacySearchJobFunctionNameCell from './CandidacySearchJobFunctionNameCell';
import CandidacySearchSectorListCell from './CandidacySearchSectorListCell';
import CandidacySearchStatusCell from './CandidacySearchStatusCell';
import CandidacySearchTitleCell from './CandidacySearchTitleCell';
import CandidacyStageDetailsCell from './CandidacyStageDetailsCell';
import { SORT_BY_AGE, SORT_BY_LEAD, SORT_BY_STAGE } from '../../constants';

/**
 * Predefined columns for the CandidaciesTableView component.
 */
export default {
  DateAdded: {
    Cell: mapDatumToCandidacyId(CandidacyDateAdded),
    className: 'CandidacyDateAddedColumn',
    isSortable: true,
    key: 'CandidacyDateAddedColumn',
    sortKey: SORT_BY_AGE,
    title: 'Date Added',
  },
  MoveStage: {
    Cell: CandidacyMoveStageButtonCell,
    className: 'CandidacyMoveStageColumn',
    isSortable: false,
    key: 'CandidacyMoveStageColumn',
    title: 'Move Stage',
  },
  SearchIndustryList: {
    Cell: CandidacySearchIndustryListCell,
    className: 'CandidacySearchIndustryListColumn',
    isSortable: false,
    key: 'CandidacySearchIndustryListColumn',
    title: 'Industry',
  },
  SearchJobFunctionName: {
    Cell: CandidacySearchJobFunctionNameCell,
    className: 'CandidacySearchJobFunctionNameColumn',
    isSortable: false,
    key: 'CandidacySearchJobFunctionNameColumn',
    title: 'Function',
  },
  SearchSectorList: {
    Cell: CandidacySearchSectorListCell,
    className: 'CandidacySearchSectorListColumn',
    isSortable: false,
    key: 'CandidacySearchSectorListColumn',
    title: 'Sector',
  },
  SearchStatus: {
    Cell: CandidacySearchStatusCell,
    className: 'CandidacySearchStatusColumn',
    isSortable: false,
    key: 'CandidacySearchStatusColumn',
    title: 'Status',
  },
  StageDetails: {
    Cell: mapDatumToCandidacyId(CandidacyStageDetailsCell),
    className: 'CandidacyStageDetailsColumn',

    // We _could_ enabled this for sorting, but sometimes it isn't correct if there are 2 stages
    // with the same name (one for job searches and another for talent pools). So for now we'll
    // disable it and we'll reenable it when that whole mess is fixed.
    isSortable: false,

    key: 'CandidacyStageDetailsColumn',
    sortKey: SORT_BY_STAGE,
    title: 'Stage',
  },
  SearchClientCompanyName: {
    Cell: CandidacySearchClientCompanyNameCell,
    className: 'CandidacySearchClientCompanyNameColumn',
    isSortable: false,
    key: 'CandidacySearchClientCompanyNameColumn',
    title: 'Company',
  },
  SearchCurrentContact: {
    Cell: CandidacySearchCurrentContactCell,
    className: 'CandidacySearchCurrentContactColumn',
    key: 'CandidacySearchCurrentContactColumn',
    isSortable: false,
    title: 'Incumbent',
  },
  SearchFullName: {
    Cell: CandidacySearchFullNameCell,
    className: 'CandidacySearchFullNameColumn',
    key: 'CandidacySearchFullNameColumn',
    isSortable: false,
    title: 'Name',
  },
  SearchLeadName: {
    Cell: mapDatumToCandidacyId(CandidacySearchLeadNameCell),
    className: 'CandidacySearchLeadNameColumn',
    isSortable: true,
    key: 'CandidacySearchLeadNameColumn',
    sortKey: SORT_BY_LEAD,
    title: 'Search Lead',
  },
  SearchTitle: {
    Cell: CandidacySearchTitleCell,
    className: 'CandidacySearchTitleColumn',
    key: 'CandidacySearchTitleColumn',
    isSortable: false,
    title: 'Name',
  },
};

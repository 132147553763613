import { PARENT_TYPES } from '../constants';
import getAddressesList from './getAddressesList';

/**
 * Gets a value indicating whether addresses need to be fetched for a given parent.
 * @param {Object} state
 * @param {Number} parentId The parent's ID
 * @param {Number} parentType The parent's type ("Company", "Contact")
 * @returns {Boolean} True if the addresses should be fetched from the server; false otherwise.
 */
export default (state, parentType, parentId) => {
  if (!PARENT_TYPES.includes(parentType)) {
    throw new Error(`Invalid parentType: ${parentType}`);
  }

  const addressesList = getAddressesList(state, parentType, parentId);

  if (!addressesList) {
    return true;
  }

  if (addressesList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (addressesList.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !addressesList.has('ids') ||
    Boolean(addressesList.getIn(['_meta', 'isInvalidated']))
  );
};

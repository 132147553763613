import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import TimeLabel from 'modules/datetime/components/TimeLabel';
import UserName from 'modules/users/components/UserName';
import UserAvatar from 'modules/users/components/UserAvatar';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import getJobFunctionName from 'modules/tenant/selectors/getJobFunctionName';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import mapRatingIdToRating from './mapRatingIdToRating';
import ContactRating from './ContactRating';
import ContactRatingRatedByName from './ContactRatingRatedByName';
import ContactRatingAvatar from './ContactRatingAvatar';
import isRatingByCurrentUser from '../../selectors/ratings/isRatingByCurrentUser';
import ContactName from '../ContactName';

const ContactRatingView = ({
  componentId,
  isCurrentUserRating,
  jobFunctionName,
  rating,
}) => (
  <div className='ContactRatingView'>
    <div className='ContactRatingView__Avatar'>
      <ContactRatingAvatar rating={rating} />
    </div>
    <div className='ContactRatingView__Content'>
      <ContactRating
        isUserRated={isCurrentUserRating}
        readOnly={true}
        value={rating.get('score')}
      />
      <div className='ContactRatingView__AddedBy' key='added_by'>
        Rated by <ContactRatingRatedByName rating={rating} />
      </div>
      <div className='ContactRatingView__Timestamp_AddBy_Wrapper'>
        <div className='ContactRatingView__Timestamp' key='timestamp'>
          <TimeLabel
            adjustTime={6000}
            data-placement='right'
            data-toggle='tooltip'
            time={rating.get('created_at')}
          />
        </div>
        {rating.get('attributed_to') && (
          <OverlayTrigger
            overlay={
              <Popover id={`${componentId}-overlay`}>
                <div>
                  <UserName userId={rating.get('user')} />
                  <span> added this rating on behalf of </span>
                  <ContactName contactId={rating.get('attributed_to')} />
                </div>
              </Popover>
            }
            placement='top'
            trigger={['hover', 'focus']}
          >
            <div className='ContactRatingView__AddedBy_Avatar'>
              <UserAvatar size={15} userId={rating.get('user')} />
            </div>
          </OverlayTrigger>
        )}
      </div>
      {jobFunctionName && (
        <div className='ContactRatingView__JobFunction'>
          <strong>Evaluated for:</strong> {jobFunctionName}
        </div>
      )}
      <div className='ContactRatingView__Comments' key='comments'>
        <HtmlText htmlString={rating.get('description')} />
      </div>
    </div>
  </div>
);

ContactRatingView.propTypes = {
  componentId: PropTypes.string.isRequired,
  isCurrentUserRating: PropTypes.bool.isRequired,
  jobFunctionName: PropTypes.string,

  rating: ImmutablePropTypes.mapContains({
    attributed_to: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    description: PropTypes.string,
    score: PropTypes.number.isRequired,
    user: PropTypes.number,
  }),
};

export default compose(
  setDisplayName('ContactRatingView(enhanced)'),
  setPropTypes({
    ratingId: PropTypes.number.isRequired,
  }),
  mapRatingIdToRating,
  connect(
    (state, { rating }) => ({
      isCurrentUserRating: isRatingByCurrentUser(state, rating.get('id')),
      jobFunctionName: getJobFunctionName(state, rating.get('job_function')),
    }),
    {},
  ),
  withComponentId('ContactRatingView'),
)(ContactRatingView);

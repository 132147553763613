import select2Autocomplete from '../../lib/select2_autocomplete';
import initEmail from '../../lib/select2_email';
import initSelectsInModal from '../../lib/modal_selects';
import relevanceFilter from '../../lib/relevance_filter';

export default {
  initialize: function () {
    relevanceFilter({
      form: '#new_filters',
      search: '.contact-filter-elastic',
      sort: '#filters_sort_field',
    });
    relevanceFilter({
      form: '.table-filter-forms .advanced-search-simple-search-form',
      search: '.company-filter-elastic',
      sort: '#company_network_filter_sort_field',
    });
    relevanceFilter({
      form: '.table-filter-forms .advanced-search-simple-search-form',
      search: '.search-filter-elastic',
      sort: '#q_s',
    });

    initSelectsInModal('.advanced-search-modal');
    initEmail($('#report_emails'));

    // Company networks autocompletes
    select2Autocomplete('#filters .js-cities-filter', {
      dropdownParent: $('#company-filter-cities'),
    });
    select2Autocomplete('#filters .js-filter-parent-companies', {
      dropdownParent: $('#company-filter-parent-companies'),
    });

    // Search networks autocompletes
    select2Autocomplete('#filters .js-cities-filter', {
      dropdownParent: $('#search-filter-cities'),
    });
    select2Autocomplete('#filters .js-source-of-business-filter', {
      dropdownParent: $('#search-filter-source-of-business'),
    });
    select2Autocomplete('#filters .js-investor-source-of-business-filter', {
      dropdownParent: $('#search-filter-investor-source-of-business'),
    });

    // Contact networks autocompletes
    select2Autocomplete('#filters .js-searches-filter', {
      dropdownParent: $('#contact-filter-searches'),
    });
    select2Autocomplete('#filters .js-relationship-manager-filter', {
      dropdownParent: $('#contact-filter-relationship-manager'),
    });
    select2Autocomplete('#filters .js-cities-filter', {
      dropdownParent: $('#contact-filter-cities'),
    });
  },
};

import { connect } from 'react-redux';
import getEmailTemplate from '../selectors/getEmailTemplate';

/**
 * A higher order component which maps a `networkIds` prop value to it's network records.
 */
export default connect(
  (state, { emailTemplateIds }) => ({
    emailTemplates:
      emailTemplateIds &&
      emailTemplateIds.map(emailTemplateId =>
        getEmailTemplate(state, emailTemplateId),
      ),
  }),
  {},
);

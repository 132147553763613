import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import CompensationForm from './CompensationForm';
import { CompensationView } from './CompensationView';
import mapCompensationIdToCompensation from './mapCompensationIdToCompensation';
import { COMPENSATION_NAMES, COMPENSATION_TITLES } from '../constants';

/**
 * Determines if a compensation record has any underlying data to be displayed.
 * In some cases we may have a compensation record assocaited with a contact, but it's underlying
 * data is empty, in which case we want it to appear (to the user) as if there is no record at all.
 * @param {Immutable.Map} [compensation] The compensation record
 * @returns {Boolean} True if the compensation record has data, false if it should be considered
 *   "empty"
 */
const hasData = compensation =>
  compensation &&
  (typeof compensation.get('base') === 'number' ||
    typeof compensation.get('bonus') === 'number' ||
    typeof compensation.get('percentage_bonus') === 'number' ||
    typeof compensation.get('equity') === 'number' ||
    typeof compensation.get('additional_equity') === 'number' ||
    typeof compensation.get('vesting_date') === 'string' ||
    typeof compensation.get('total_compensation') === 'number' ||
    typeof compensation.get('equity_value') === 'number' ||
    typeof compensation.get('share_price') === 'number' ||
    typeof compensation.get('target_option_proceeds') === 'number' ||
    typeof compensation.get('fully_diluted_ownership') === 'number' ||
    compensation.get('notes'));

/**
 * Renders an EditableSection that displays and allows editing of a compensation record
 * details fields.
 */
export default compose(
  setDisplayName('CompensationEditableSection'),
  setPropTypes({
    /**
     * The ID of the compensation, if an underlying record exists.
     */
    compensationId: PropTypes.number,

    /**
     * The name of the compensation type.
     */
    compensationName: PropTypes.oneOf(COMPENSATION_NAMES).isRequired,

    /**
     * The ID of the contact the compensation record belongs to (or will belong to, if one is
     * created and doesn't yet exist).
     */
    contactId: PropTypes.number.isRequired,

    /**
     * Time passed since the compensation field was last updated. Only present if compensation
     * values exist.
     */
    lastUpdated: PropTypes.shape({
      created_at: PropTypes.string,
      user_email: PropTypes.string,
    }),

    /**
     * An optional legal disclaimer to display when editing the compensation record.
     */
    legalDisclaimer: PropTypes.string,

    /**
     * The title of the editable section.
     */
    title: PropTypes.string,
  }),
  mapCompensationIdToCompensation,
  mapProps(({ compensation, compensationName, ...rest }) => ({
    ...rest,
    children: hasData(compensation) && (
      <CompensationView compensation={compensation} />
    ),
    compensationName: compensationName,
    formComponent: CompensationForm,
    title: COMPENSATION_TITLES[compensationName],
  })),
)(EditableSection);

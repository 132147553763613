import {
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
} from './actionTypes';
import userSchema from '../schema';
import createAjaxAction from '../../../actions/createAjaxAction';
import reduceFieldErrors from '../../../actions/contacts/reduceFieldErrors';

const updateUser = createAjaxAction({
  getUrl: ({ userId }) => `/users/${userId}.json`,
  getRequestOptions: ({ user }) => ({
    method: 'PATCH',
    body: JSON.stringify({ user: user }),
  }),
  schema: { user: userSchema },
  start: USER_UPDATE_START,
  success: USER_UPDATE_SUCCESS,
  failure: USER_UPDATE_FAILURE,
  createErrorPayload: reduceFieldErrors,
});

export default updateUser;

import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { List } from 'immutable';
import sorters from '../sorters';

const identity = i => i;

export default compose(
  connect((state, { sortBy, taskIds }) => {
    if (!taskIds || taskIds.size < 1) {
      return {};
    }

    const createSorter = sorters[sortBy];
    return {
      taskIds: List(taskIds).sortBy(
        createSorter ? createSorter(state) : identity,
      ),
    };
  }),
  withProps(({ sortAscending, taskIds }) => ({
    taskIds: !taskIds || sortAscending ? taskIds : taskIds.reverse(),
  })),
);

import TableView from 'modules/core/componentsLegacy/Table/TableView';
import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import {
  COLUMN_NAME,
  COLUMN_CREATED_BY,
  COLUMN_DATE_CREATED,
  COLUMN_DATE_MODIFIED,
  COLUMN_EDIT_DELETE_BUTTONS,
} from '../../constants';
import withEmailTemplatesListFetched from '../withEmailTemplatesListFetched';
import withEmailTemplateIdsSortedByName from '../withEmailTemplateIdsSortedByName';
import columnDefinitions from './columnDefinitions';

/**
 * Renders a table that shows the list of all email templates.
 */
export default compose(
  setDisplayName('EmailTemplatesTable'),
  setPropTypes({
    /**
     * Determines if -- when there are no companies to be displayed -- we show a "Refresh"
     * button to allow refetching the templates.
     */
    allowRefreshWhenEmpty: PropTypes.bool.isRequired,

    /**
     * The names of the columns to render.
     */
    columns: PropTypes.arrayOf(
      PropTypes.oneOf(Object.keys(columnDefinitions)).isRequired,
    ).isRequired,
  }),
  defaultProps({
    allowRefreshWhenEmpty: false,
    columns: [
      COLUMN_NAME,
      COLUMN_CREATED_BY,
      COLUMN_DATE_CREATED,
      COLUMN_DATE_MODIFIED,
      COLUMN_EDIT_DELETE_BUTTONS,
    ],
    sortable: false,
  }),
  // Handle fetching of the templates and pulling out the IDs as `emailTemplateIds`
  withEmailTemplatesListFetched,

  // Pull of the `emailTemplateIds` and `isFetching` state from the emailTemplatesList
  withProps(({ emailTemplatesList }) => ({
    emailTemplateIds: emailTemplatesList && emailTemplatesList.get('ids'),
    isFetching:
      emailTemplatesList && emailTemplatesList.getIn(['_meta', 'isFetching']),
  })),

  // For now we always sort by name and default `sortable` to false so the headers of the table
  // aren't clickable.
  withEmailTemplateIdsSortedByName,

  // Pull out the properties needed for the TableView component.
  mapProps(
    ({
      allowRefreshWhenEmpty,
      columns,
      emailTemplateIds,
      emailTemplatesList,
      fetchEmailTemplatesList,
      isFetching,
      sortable,
    }) => ({
      // Map the column names to the actual column definitions (which includes the titles,
      // how to render the values, etc)
      columns: columns
        .map(columnName => columnDefinitions[columnName])
        .filter(column => column),
      data: emailTemplateIds,

      // Render an empty state component when we don't have any templates.
      emptyState: !isFetching &&
        emailTemplateIds &&
        emailTemplateIds.count() === 0 && {
          buttonText: allowRefreshWhenEmpty && 'Refresh',
          onButtonClick: fetchEmailTemplatesList,
          message: 'No email templates found',
        },

      // Show an error and allow retrying the fetch is it failed.
      errorAlert: !isFetching &&
        emailTemplatesList &&
        emailTemplatesList.getIn(['_meta', 'error']) && {
          error: emailTemplatesList.getIn(['_meta', 'error']),
          onRetry: fetchEmailTemplatesList,
          dismissable: false,
          title: 'There was an error fetching email templates',
        },

      // Show a loading indicator if we are fetching.
      loadingIndicator: isFetching && {},

      sortable: sortable,
    }),
  ),
)(TableView);

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { mapProps } from 'recompose';

// Renders a circle filled with a solid color
const SearchStageIndicatorDot = mapProps(({ color, stage }) => ({
  style: {
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: color || stage.get('color'),
    height: '0.8em',
    width: '0.8em',
    marginRight: '0.25em',
  },
}))('span');

SearchStageIndicatorDot.propTypes = {
  /**
   * The stage's color. Either this or the `stage` prop is required
   */
  color: PropTypes.string,

  /**
   * The stage itself. Either this or a `color` prop is required
   */
  stage: ImmutablePropTypes.mapContains({
    color: PropTypes.string,
  }),
};

export default SearchStageIndicatorDot;

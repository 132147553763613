import { PARENT_TYPES } from '../constants';
import getNotesForParent from './getNotesForParent';

export default (state, parentType, parentId) => {
  if (!PARENT_TYPES.includes(parentType)) {
    throw new Error(`Invalid parentType: ${parentType}`);
  }

  if (!parentId) {
    return false;
  }

  const notes = getNotesForParent(state, parentType, parentId);

  if (!notes) {
    return true;
  }

  if (notes.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (notes.hasIn(['_meta', 'error'])) {
    return false;
  }

  return !notes.has('ids') || Boolean(notes.getIn(['_meta', 'isInvalidated']));
};

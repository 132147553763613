import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import { useGetSuggestedConnectionQuery } from 'services/apiV1/suggestedConnection';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import SuggestedConnectionCard from './SuggestedConnectionCard';
import { QuickViewEmptyState, QuickViewErrorState } from '../QuickViewState';
import useQuickView from '../useQuickView';

const offsetLimit = 5;

const ContactSuggestedConnections = ({
  createdSuggestedConnection,
  ...rest
}) => {
  const { pathParams, title } = useQuickView();
  const contactFirstName = title.split(' ')[0];
  const { contactId } = pathParams;
  const [suggestedConnections, setSuggestedConnections] = useState([]);
  const [offset, setOffset] = useState(0);

  const {
    data: suggestedConnectionsData,
    error: suggestedConnectionsError,
    isLoading: isSuggestedConnectionsLoading,
    refetch,
  } = useGetSuggestedConnectionQuery({ contactId: contactId, offset: offset });

  const refetchSuggestedConnections = useCallback(() => {
    setOffset(0);
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (parseInt(createdSuggestedConnection)) {
      refetchSuggestedConnections();
    }
  }, [createdSuggestedConnection]);

  useEffect(() => {
    if (suggestedConnectionsData) {
      setSuggestedConnections(oldValue =>
        offset > 0
          ? [...oldValue, ...suggestedConnectionsData.suggestedConnections]
          : suggestedConnectionsData.suggestedConnections,
      );
    }
  }, [suggestedConnectionsData]);

  const handleLoadMore = () => {
    setOffset(oldValue => oldValue + offsetLimit);
  };

  return (
    <SidePanel.Body className='u-paddingTop-24' {...rest}>
      <LoadingContainer
        isLoading={isSuggestedConnectionsLoading}
        minHeight='80%'
        size='large'
      >
        {suggestedConnections?.map(suggestedConnection => (
          <SuggestedConnectionCard
            key={`${suggestedConnection.userName}-${suggestedConnection.suggested_connection}`}
            refetchSuggestedConnections={refetchSuggestedConnections}
            setSuggestedConnections={setSuggestedConnections}
            suggestedConnection={suggestedConnection}
          />
        ))}
        {suggestedConnectionsData?.metadata.totalSuggestedConnections >
        suggestedConnections.length ? (
          <ButtonPrimary
            className='loadMore'
            disabled={isSuggestedConnectionsLoading}
            label='Load more'
            onClick={handleLoadMore}
          />
        ) : null}
        {!isSuggestedConnectionsLoading &&
        !suggestedConnectionsError &&
        suggestedConnectionsData?.metadata.totalSuggestedConnections === 0 ? (
          <QuickViewEmptyState
            heading={`${contactFirstName} doesn’t have any suggested connection.`}
            image='avatarWithEnvelope'
            subHeading={
              <>
                Add a connection by clicking on the{' '}
                <b className='u-noWrap'>+ Add Connection</b> button.
              </>
            }
          />
        ) : null}
        {!isSuggestedConnectionsLoading && suggestedConnectionsError ? (
          <QuickViewErrorState />
        ) : null}
      </LoadingContainer>
    </SidePanel.Body>
  );
};

ContactSuggestedConnections.propTypes = {
  createdSuggestedConnection: PropTypes.string,
};

export default ContactSuggestedConnections;

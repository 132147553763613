import { USER_STATE_KEY } from '../constants';

/**
 * Gets a value indicating whether the current user is in a limited access role.
 * @param {Object} state
 * @return {Boolean} True if the user is in a limited access role, otherwise false.
 */
const isLimitedAccessRole = state =>
  state[USER_STATE_KEY].get('limited_access_roles', true);

export default isLimitedAccessRole;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import TableHeaderCell from 'modules/core/components/TableHeaderCell';
import Modal from '@thrivetrm/ui/components/Modal';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SearchInput from '@thrivetrm/ui/components/SearchInput';
import TableScrollContainer from '@thrivetrm/ui/components/TableScrollContainer';
import PluralText from '@thrivetrm/ui/components/PluralText';
import { stringifyQueryObject } from '@thrivetrm/ui/utilities/urlUtils';
import {
  useGetCandidateTagsQuery,
  useGetTagsQuery,
  useUpdateCandidateTagMutation,
} from 'services/apiV1/candidateTags';
import CandidacyAddTagForm from './CandidacyAddTagForm';
import CandidateSearchTagModalRow from './CandidacySearchTagModalRow';
import CandidacyName from '../CandidacyName';

const COLUMNS_LIST = [
  { label: '', name: 'checkbox' },
  { label: 'Color', name: 'color' },
  { label: 'Label', name: 'label', width: 'large' },
  { label: 'Created By', name: 'createdBy', width: 'large' },
  { label: 'Actions', name: 'actions', width: 'small' },
];
const MAX_TAG_SELECTION_LIMIT = 100;
const OFFSET_LIMIT = 100;
const DEBOUNCE_INTERVAL_MS = 500;

const CandidacySearchTagModal = ({
  candidateIds,
  onHide,
  onSuccess,
  searchId,
  show,
}) => {
  const dispatch = useDispatch();
  const enableTagCreation = useFeatureCheck('feature.candidacy_tags.create');

  // newTags will contain newly created tags after opening modal
  const [newTags, setNewTags] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [params, setParams] = useState({
    is_created_by_current_user: '1',
    name_or_creator: '',
    limit: OFFSET_LIMIT,
    candidacy_ids: candidateIds,
  });

  const currentUserName = useSelector(state => state.user.get('full_name'));

  const { data: tagsData, isFetching: isLoadingTags } = useGetTagsQuery(
    stringifyQueryObject(params),
  );
  const { data: candidateTagsList } = useGetCandidateTagsQuery(searchId);
  const [
    updateCandidateTag,
    { isLoading: isUpdatingCandidateTag },
  ] = useUpdateCandidateTagMutation();

  useEffect(() => {
    const candidateTags =
      candidateTagsList?.tags.filter(({ candidacyIds }) =>
        candidateIds.find(candidate => candidacyIds.includes(candidate)),
      ) || [];
    const tagsList = (selectedTags.length ? selectedTags : candidateTags).map(
      candidateTag => {
        const tagDetails = tagsData?.tags.find(
          tag => tag.id === candidateTag.id,
        );
        return {
          ...candidateTag,
          isIndeterminate:
            candidateTag?.isIndeterminate || tagDetails?.partiallyApplied,
          isSelected: candidateTag?.isSelected || tagDetails?.fullyApplied,
        };
      },
    );
    setSelectedTags(tagsList);

    if (tagsData?.isCreatedByCurrentUser) {
      setSearchValue(currentUserName);
    }
  }, [candidateTagsList, tagsData]);

  // debounce updating params to reduce number of requests to the backend
  const debounceUpdateParams = useCallback(
    debounce(
      searchTerm =>
        setParams({
          ...params,
          is_created_by_current_user: '0',
          name_or_creator: searchTerm,
          limit: OFFSET_LIMIT,
        }),
      DEBOUNCE_INTERVAL_MS,
    ),
    [],
  );

  const handleSelectTag = (tagId, candidateTag, tagDetails) => {
    if (candidateTag?.isSelected) {
      setSelectedTags(tagsList => tagsList.filter(({ id }) => id !== tagId));
    } else if (candidateTag?.isIndeterminate) {
      setSelectedTags(tagsList =>
        tagsList.map(tag => ({
          ...tag,
          isSelected: tag.id === tagId || tag.isSelected,
          isIndeterminate: tag.id === tagId ? false : tag.isIndeterminate,
        })),
      );
    } else {
      setSelectedTags(tagsList => [
        ...tagsList,
        ...[
          {
            ...tagDetails,
            isSelected: !tagDetails.partiallyApplied,
            isIndeterminate: tagDetails.partiallyApplied,
          },
        ],
      ]);
    }
  };

  const handleCreateTag = tag => {
    setNewTags([...newTags, ...[tag]]);
    setSelectedTags(tagsList => [
      ...tagsList,
      ...[{ ...tag, isSelected: true }],
    ]);
  };

  const handleOnSubmit = () => {
    updateCandidateTag({
      payload: {
        candidacy_ids: candidateIds,
        tag_data: selectedTags.map(({ candidacyIds, id, isSelected }) => ({
          id: id,
          applied_to_ids: isSelected ? candidateIds : candidacyIds,
        })),
      },
    })
      .unwrap()
      .then(() => {
        const candidacyCount = candidateIds.length;
        dispatch(
          toastSuccess(
            `Successfully updated the tags for ${candidacyCount} ${
              candidacyCount > 1 ? 'candidates' : 'candidate'
            }`,
          ),
        );
        onHide();
        onSuccess?.();
      })
      .catch(() =>
        dispatch(toastError('Something went wrong. The tags were not updated')),
      );
  };

  const handleDeleteSuccess = tagId => {
    const isNewTag = newTags.find(({ id }) => id === tagId);
    if (isNewTag) {
      setNewTags(newTags.filter(({ id }) => id !== tagId));
    }
    setSelectedTags(selectedTags.filter(({ id }) => id !== tagId));
  };

  const handleEditSuccess = tag => {
    const isNewTag = newTags.find(({ id }) => tag.tag.id === id);
    if (isNewTag) {
      setNewTags(
        newTags.map(tagData => (tagData.id === tag.tag.id ? tag.tag : tagData)),
      );
    }
  };

  // when user updates newly created tag
  // tags list will be updated as we have used RTKQ
  // newly created tags will be present in latest response
  // so filtering the list to avoid duplication
  const getTagsList = () => {
    const tagsList = (tagsData?.tags || []).filter(
      ({ id }) => !newTags.find(tag => id === tag.id),
    );
    return [...newTags, ...tagsList];
  };

  const renderTagTableRow = tagData => {
    const { color, createdBy, id, name } = tagData;
    const candidateTag = selectedTags.find(tag => tag.id === id);

    return (
      <CandidateSearchTagModalRow
        color={color}
        createdBy={createdBy}
        handleSelectTag={() => handleSelectTag(id, candidateTag, tagData)}
        id={id}
        isDisabled={
          selectedTags.length >= MAX_TAG_SELECTION_LIMIT &&
          !candidateTag?.isIndeterminate &&
          !candidateTag?.isSelected
        }
        isIndeterminate={Boolean(candidateTag?.isIndeterminate)}
        isSelected={Boolean(candidateTag?.isSelected)}
        key={id}
        label={name}
        onDeleteSuccess={handleDeleteSuccess}
        onEditSuccess={handleEditSuccess}
      />
    );
  };

  return (
    <Modal
      className='CandidacySearchTagModal'
      isOpen={show}
      onClose={onHide}
      subTitle={
        <p className='u-marginTop-4 u-marginBottom-n u-textAlign-l'>
          {candidateIds.length === 1 ? (
            <CandidacyName candidacyId={candidateIds[0]} />
          ) : (
            <span className='u-textColor-gray60'>
              <PluralText
                quantity={candidateIds.length}
                shouldIncludeQuantity={true}
                text='Candidate'
              />{' '}
              Selected
            </span>
          )}
        </p>
      }
      title='Candidate Tags'
    >
      <Modal.Body>
        {enableTagCreation ? (
          <CandidacyAddTagForm onCreate={handleCreateTag} />
        ) : null}
        <SearchInput
          className='u-marginVertical-8 u-width-100'
          onChange={search => {
            setSearchValue(search);
            debounceUpdateParams(search);
          }}
          onClear={() => {
            setSearchValue('');
            debounceUpdateParams('');
          }}
          placeholder='Filter by Tag Label or Creator'
          value={searchValue}
        />
        <LoadingContainer isLoading={isLoadingTags}>
          <TableScrollContainer className='CandidacySearchTagModal__tableScrollContainer u-paddingBottom-12'>
            <table>
              <thead className='CandidacySearchTagModal__tableHead'>
                <tr>
                  {COLUMNS_LIST.map(({ label, name, width }) => (
                    <TableHeaderCell
                      key={name}
                      label={label}
                      name={name}
                      width={width}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>{getTagsList().map(renderTagTableRow)}</tbody>
            </table>
            {tagsData?.tags?.length < tagsData?.totalCount ? (
              <div className='u-flex u-flexJustify-c u-marginTop-12'>
                <ButtonPrimary
                  className='u-noWrap'
                  isDisabled={isLoadingTags}
                  isLoading={isLoadingTags}
                  isOutline={true}
                  label='Load More'
                  onClick={() => {
                    setParams({
                      ...params,
                      limit: params.limit + OFFSET_LIMIT,
                    });
                  }}
                  size='small'
                />
              </div>
            ) : null}
          </TableScrollContainer>
          {selectedTags.length >= MAX_TAG_SELECTION_LIMIT ? (
            <div className='u-marginTop-16 u-textColor-red'>
              A maximum of 100 tags can be selected at a time.
            </div>
          ) : null}
        </LoadingContainer>
      </Modal.Body>
      <Modal.Footer>
        <ButtonSecondary label='Cancel' onClick={onHide} />
        <ButtonPrimary
          isDisabled={isUpdatingCandidateTag}
          isLoading={isUpdatingCandidateTag}
          label='Submit'
          onClick={handleOnSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};

CandidacySearchTagModal.propTypes = {
  candidateIds: PropTypes.arrayOf(PropTypes.number),
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  searchId: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CandidacySearchTagModal;

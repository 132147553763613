import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import getCandidacy from 'modules/candidacies/selectors/getCandidacy';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';

const AssessmentCount = ({ candidacyId }) => {
  const hasAssessmentTemplates = useFeatureCheck(
    'feature.assessment_templates',
  );
  const candidacy = useSelector(state => getCandidacy(state, candidacyId));

  const { candidacyAssessments } = useSelector(
    state => state.candidacyAssessments,
  );

  const assessments = candidacyAssessments?.filter(
    assessment => assessment.candidacy_id === candidacyId,
  );

  return hasAssessmentTemplates ? (
    <span>{assessments && assessments.length}</span>
  ) : (
    <span>{candidacy && candidacy.get('assessments_count')}</span>
  );
};

AssessmentCount.propTypes = {
  candidacyId: PropTypes.number,
};

export default AssessmentCount;

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const PageHeader = ({ children, className, icon, title, ...props }) => {
  const containerClassName = classnames(
    className,
    'page-header',
    'container-full-width',
  );

  return (
    <div {...props} className={containerClassName}>
      <div className='page-title'>
        <h1>
          {icon && (
            <span className='page-header-icon'>
              <i className={`fa type fa-${icon}`} />
            </span>
          )}
          {title}
        </h1>
      </div>
      <div className='page-actions'>{children}</div>
    </div>
  );
};

PageHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
};

PageHeader.defaultProps = {
  children: null,
  className: '',
  icon: null,
};

export default PageHeader;

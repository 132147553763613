import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Clipboard from 'clipboard';

/**
 * Wraps a component in a trigger so that when it is clicked it copies some underlying
 * data to the clipboard.
 */
class ClipboardTrigger extends Component {
  setCopyLinkRef = element => {
    if (element) {
      this.clipboard = new Clipboard(element);
      this.clipboard.on('success', this.handleSuccess);
    } else {
      this.clipboard.destroy();
    }
  };

  handleSuccess = () => {
    const { onSuccess } = this.props;
    if (onSuccess) {
      onSuccess();
    }
  };

  render() {
    const { children, target, text } = this.props;
    return (
      <div
        className='ClipboardTrigger'
        data-clipboard-target={target}
        data-clipboard-text={text}
        ref={this.setCopyLinkRef}
      >
        {children}
      </div>
    );
  }
}

ClipboardTrigger.defaultProps = {
  children: null,
  onSuccess: null,
  target: null,
  text: null,
};

ClipboardTrigger.propTypes = {
  /**
   * Contents of the clipboard trigger.
   */
  children: PropTypes.node,

  /**
   * Optional callback to be performed after a successful save to clipboard.
   */
  onSuccess: PropTypes.func,

  /**
   * Selector for an input. If provided, its text will be copied to the
   * clipboard when the ClipboardTrigger component is clicked.
   */
  target: PropTypes.string,

  /**
   * Optional text to be copied to the clipboard when this component is clicked
   * Either this or `target` should be specified so something can actually be copied to the
   * clipboard.
   */
  text: PropTypes.string,
};

export default ClipboardTrigger;

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  componentFromProp,
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import UserName from 'modules/users/components/UserName';
import ContactName from '../ContactName';

/**
 * Renders the name of the person who made the rating.
 * If this is a rating attributed to a contact, then the contact's name is rendered;
 * If this is the current user's rating, then "Me" is rendered;
 * Otherwise, the name of the user this is attributed to is rendered.
 */
export default compose(
  setDisplayName('ContactRatingRatedByName'),
  setPropTypes({
    rating: ImmutablePropTypes.mapContains({
      attributed_to: PropTypes.number,
      user: PropTypes.number,
    }),
  }),
  connect(
    (state, { rating }) => ({
      isCurrentUser: rating.get('user') === getCurrentUserId(state),
    }),
    {},
  ),
  mapProps(({ isCurrentUser, rating }) => {
    if (rating.get('attributed_to')) {
      return {
        component: ContactName,
        contactId: rating.get('attributed_to'),
      };
    }

    if (isCurrentUser) {
      return {
        component: 'span',
        children: 'Me',
      };
    }

    return {
      component: UserName,
      userId: rating.get('user'),
    };
  }),
)(componentFromProp('component'));

import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  renderNothing,
} from 'recompose';

export default compose(
  setDisplayName('PrimaryAddressCheckIcon'),
  setPropTypes({
    addressId: PropTypes.number.isRequired,
    primaryAddressId: PropTypes.number,
  }),
  branch(
    ({ addressId, primaryAddressId }) => addressId !== primaryAddressId,
    renderNothing,
  ),
  mapProps(
    ({
      addressId: _addressId,
      primaryAddressId: _primaryAddressId,
      ...rest
    }) => ({
      className: 'fa fa-check',
      ...rest,
    }),
  ),
)('i');

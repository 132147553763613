import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import useQuickView from 'modules/quick-view/useQuickView';
import getContact from 'modules/contacts/selectors/contacts/getContact';

const ConnectionsButtonOverlay = ({
  buttonWrapperClassName,
  children,
  connectionsCount,
  contactId,
  contactName,
}) => {
  const wrapperClass = classnames(
    'ConnectionsButtonOverlay__buttonWrapper',
    buttonWrapperClassName,
  );

  const { navigateTo } = useQuickView();
  const contactData = useSelector(state =>
    getContact(state, Number(contactId)),
  );

  /**
   * This overlay may appear on pages which have not yet fetched data
   * for a specific contact.  Before opening the ConnectionsList view of
   * the QuickViewPanel, make sure to fetch contact data and wait for
   * it to resolve
   */
  const openConnectionsQuickView = () =>
    navigateTo(`/contact/${contactId}/connections`, {
      title: contactData?.get('full_name') || contactName,
    });

  return connectionsCount ? (
    // The plain div is needed to prevent flexbox styles from changing the container height
    <div>
      <div className='ConnectionsButtonOverlay'>
        {children}
        <div className={wrapperClass}>
          <ButtonPrimary
            icon='connections'
            onClick={openConnectionsQuickView}
            size='small'
          />
        </div>
      </div>
    </div>
  ) : (
    children
  );
};

ConnectionsButtonOverlay.defaultProps = {
  buttonWrapperClassName: null,
  connectionsCount: 0,
  contactName: '',
};

ConnectionsButtonOverlay.propTypes = {
  buttonWrapperClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  connectionsCount: PropTypes.number,
  contactId: PropTypes.number.isRequired,
  contactName: PropTypes.string,
};

export default ConnectionsButtonOverlay;

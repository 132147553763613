import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import ExternalLink from 'modules/links/ExternalLink';
import ApplyCustomOffLimitsIndicator from 'modules/off-limits/ApplyCustomOffLimitsIndicator';
import OffLimitsIndicator from 'modules/off-limits/OffLimitsIndicator';
import getOffLimits from 'modules/companies/selectors/getOffLimits';
import CompanyAliasList from '../aliases/CompanyAliasList';
import CompanyName from '../CompanyName';
import CompanyProperty from '../CompanyProperty';
import CompanyTelephoneLink from '../CompanyTelephoneLink';
import getCompany from '../../selectors/getCompany';

const CompanyBasicsView = ({ companyId }) => {
  const offLimits = useSelector(state => getOffLimits(state, companyId));
  const company = useSelector(state => getCompany(state, companyId));

  return (
    <div className='CompanyBasicsView'>
      {offLimits ? (
        <OffLimitsIndicator
          reason={offLimits.get('status')}
          recordId={companyId}
          recordName={company.get('name')}
          recordType='company'
          severity={offLimits.get('category')}
        />
      ) : null}
      {/*
        Apply Off Limits should only display for V2. Once all tenants have off
        limits v2, this check can be removed and the display of the indicators
        can become a ternary.
      */}
      {!offLimits && (
        <ApplyCustomOffLimitsIndicator
          recordId={companyId}
          recordName={company.get('name')}
          recordType='company'
        />
      )}
      {company?.get('parent_company') ? (
        <LabeledContent
          className='CompanyBasicsView__parent_company'
          inline={true}
          label='Parent Company'
          shouldIndentOnWrap={true}
        >
          <CompanyName
            companyId={company.get('parent_company')}
            linkToCompany={true}
          />
        </LabeledContent>
      ) : null}
      {company?.get('external_url') ? (
        <LabeledContent
          className='CompanyBasicsView__external_url'
          inline={true}
          key='external_url'
          label='External URL'
        >
          <ExternalLink href={company.get('external_url')}>
            {company.get('external_url')}
          </ExternalLink>
        </LabeledContent>
      ) : null}
      {company?.get('aliases')?.count() > 0 ? (
        <LabeledContent
          className='CompanyBasicsView__aliases u-wordBreak'
          inline={true}
          label='AKA'
          shouldIndentOnWrap={true}
        >
          <CompanyAliasList companyId={companyId} />
        </LabeledContent>
      ) : null}
      {company?.get('main_phone') ? (
        <LabeledContent className='CompanyBasicsView__main_phone' inline={true}>
          <CompanyTelephoneLink companyId={companyId} propertyName='main_phone'>
            <i className='fa fa-fw fa-phone-square' title='Phone Number' />
            &nbsp;
            <CompanyProperty companyId={companyId} propertyName='main_phone' />
          </CompanyTelephoneLink>
        </LabeledContent>
      ) : null}
      {company?.get('work_phone') ? (
        <LabeledContent className='CompanyBasicsView__work_phone' inline={true}>
          <CompanyTelephoneLink companyId={companyId} propertyName='work_phone'>
            <i className='fa fa-fw fa-phone' title='Alternative Phone Number' />
            &nbsp;
            <CompanyProperty companyId={companyId} propertyName='work_phone' />
          </CompanyTelephoneLink>
        </LabeledContent>
      ) : null}
    </div>
  );
};

CompanyBasicsView.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default CompanyBasicsView;

import React, { useState } from 'react';
import { compose } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import withAllEmailTemplates from 'modules/email-templates/components/withAllEmailTemplates';
import PropTypes from 'prop-types';
import Modal from 'modules/core/componentsLegacy/Modal';
import OutreachEmailForm from './OutreachEmailForm';

const LbiOutreachEmailModalButton = ({ contact, emailTemplates }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const lbiTemplateList =
    emailTemplates &&
    emailTemplates.filter(template =>
      ['Legitimate Business Interest Notice', 'LBI Notice'].includes(
        template.get('name'),
      ),
    );

  const lbiTemplate = lbiTemplateList && lbiTemplateList.first();

  const showButton =
    contact.getIn(['gdpr_status', 'label']) === 'LBI Notice Needed';

  return (
    <div className='LbiOutreachEmailModalButton'>
      {showButton && (
        <button
          className='btn-link u-padding-n'
          onClick={toggleOpen}
          type='button'
        >
          <i className='fa fa-envelope-square' />
        </button>
      )}
      <Modal
        className='LbiOutreachEmailModal'
        dialogClassName='modal-lg'
        onHide={toggleOpen}
        show={isOpen}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Send LBI Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OutreachEmailForm
            contactId={contact.get('id')}
            formActionProps={{
              submitButtonIcon: 'fa-send',
              submitLabel: 'Send Email',
            }}
            lbiNoticeSent={true}
            lbiTemplate={lbiTemplate}
            onCancel={toggleOpen}
            onSaved={toggleOpen}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

LbiOutreachEmailModalButton.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    gdpr_status: ImmutablePropTypes.mapContains({
      description: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number,
  }).isRequired,
  emailTemplates: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      content: PropTypes.string,
      id: PropTypes.number,
      subject: PropTypes.string,
    }),
  ),
};

export default compose(withAllEmailTemplates)(LbiOutreachEmailModalButton);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import classnames from 'classnames';
import {
  ASSESSMENT_TYPES,
  TYPE_RECRUITER,
} from 'modules/assessments/constants';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';
import DateInputField from 'modules/datetime/components/DateInputField';
import TimeInputField from 'modules/datetime/components/TimeInputField';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import assessmentQuestionSets from 'modules/tenant/schemas/assessmentQuestionSets';

import moment from 'moment';
import RichTextField, {
  requiredField as requiredRichTextField,
} from '../../../../components/forms/richtext/RichTextField';
import AttributesField from './AttributesField';

/**
 * The field for editing or creating an assessment.
 */
class AssessmentField extends PureComponent {
  /**
   * Creates a new FieldState that can be passed to this field for rendering.
   * @param {String} [name='assessment'] The name of the field
   * @param {List} assessmentAttrbutes The attributes that can be rated on.
   * @param {Map|Object} initialValues The initial values to populate the field with.
   * @returns {FieldState} A new FieldState that can be passed to this field for rendering.
   */
  static createFieldState(
    name = 'assessment',
    {
      assessment,
      assessmentType,
      hasRecruiterAssessmentCommentRequired,
      ratings,
    },
  ) {
    const values = fromJS({
      comment: '',
      added_by: '',
      question_set: '',
    }).merge(assessment);

    const requireComment =
      assessmentType === TYPE_RECRUITER &&
      hasRecruiterAssessmentCommentRequired;

    const assessedOnMoment = values.get('assessed_on')
      ? moment(values.get('assessed_on'), moment.ISO_8601)
      : moment();

    return FieldState.createNested(
      name,
      [
        AttributesField.createFieldState('ratings', ratings),
        RichTextField.createFieldState(
          'comment',
          values.get('comment'),
          requireComment && requiredRichTextField('A comment'),
        ),
        TenantOptionsSelectField.createFieldState(
          'question_set_id',
          values.get('question_set'),
        ),
        InputField.createFieldState(
          'added_by',
          values.get('added_by'),
          validators.requiredField('Added by'),
        ),
        DateInputField.createFieldState('date', assessedOnMoment),
        TimeInputField.createFieldState('time', assessedOnMoment.format('LT')),
      ],
      null,
      ({ date, time, ...other }) => {
        // Merges the `date` and `time` fields into `assessed_on`
        const timeMoment = moment(time, 'LT');
        const dateMoment = moment(date, moment.ISO_8601);
        dateMoment.set({
          hour: timeMoment.get('hour'),
          minute: timeMoment.get('minute'),
          second: timeMoment.get('second'),
        });

        return {
          ...other,
          assessed_on: dateMoment.format(),
        };
      },
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      assessmentType,
      candidacyId,
      draft,
      fieldState,
      hasAnswerContent,
      hasRecruiterAssessmentCommentRequired,
      isGuestAssessment,
      onChange: _onChange,
      onDraftChange,
      searchId,
      ...otherProps
    } = this.props;

    const requireComment =
      assessmentType === TYPE_RECRUITER &&
      hasRecruiterAssessmentCommentRequired;

    return (
      <div className={classnames('assessment-field', 'assessment-item')}>
        <div className='row'>
          <div className='col-12'>
            <AttributesField
              {...otherProps}
              candidacyId={candidacyId}
              fieldState={fieldState.getNestedField('ratings')}
              key='ratings'
              onChange={this.handleFieldChange}
              searchId={searchId}
            />
          </div>
          <div className='col-12'>
            <RichTextField
              {...otherProps}
              fieldState={fieldState.getNestedField('comment')}
              key='comment'
              label={requireComment ? '* Comments' : 'Comments'}
              lastUpdatedTime={draft?.lastUpdatedTime}
              onChange={this.handleFieldChange}
              onDraftChange={onDraftChange}
            />
          </div>
          {isGuestAssessment ? null : (
            <div className='col-12'>
              <TenantOptionsSelectField
                {...otherProps}
                clearable={true}
                disabled={hasAnswerContent}
                fieldState={fieldState.getNestedField('question_set_id')}
                label='Assessment Template'
                onChange={this.handleFieldChange}
                renderIfNoOptions={false}
                schema={assessmentQuestionSets}
              />
            </div>
          )}
          <div className='col-12'>
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('added_by')}
              key='added_by'
              label='Added by*'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className='col-6'>
            <DateInputField
              fieldState={fieldState.getNestedField('date')}
              label='Date'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className='col-6'>
            <TimeInputField
              fieldState={fieldState.getNestedField('time')}
              label='Time'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

AssessmentField.defaultProps = {
  draft: null,
  isGuestAssessment: false,
};

AssessmentField.propTypes = {
  /**
   * The assessment type
   */
  assessmentType: PropTypes.oneOf(ASSESSMENT_TYPES),
  /**
   * The candidacy ID that this assessment belongs to (or will be created for).
   * This is needed to determine which assessment attribute/options to display
   * for ratings.
   */
  candidacyId: PropTypes.number.isRequired,
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * When true, this prop will disable the question set select input
   */
  hasAnswerContent: PropTypes.bool,

  /**
   * True if the TYPE_RECRUITER's comments should be required
   */
  hasRecruiterAssessmentCommentRequired: PropTypes.bool,

  /**
   * True if this is a guest assement
   */
  isGuestAssessment: PropTypes.bool,
  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  onDraftChange: PropTypes.func,

  searchId: PropTypes.number,
};

export default AssessmentField;

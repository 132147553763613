import getExistingCandidaciesCount from './getExistingCandidaciesCount';
import getExistingCandidaciesCountMeta from './getExistingCandidaciesCountMeta';

/**
 * Determines if the count of existing candidates needs to be fetched from the server.
 * @param {Object} state
 * @return {Boolean} True if the count of existing candidates needs to be fetched from the server;
 *   Othewise, false.
 */
const shouldFetchExistingCandidaciesCount = (state, searchId, contactIds) => {
  const existingCandidaciesCount = getExistingCandidaciesCount(
    state,
    searchId,
    contactIds,
  );
  const meta = getExistingCandidaciesCountMeta(state, searchId, contactIds);
  if (!meta) {
    return true;
  }

  if (meta.get('isFetching') || meta.has('error')) {
    return false;
  }

  return (
    existingCandidaciesCount === null || Boolean(meta.get('isInvalidated'))
  );
};

export default shouldFetchExistingCandidaciesCount;

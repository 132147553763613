import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withProps,
  withStateHandlers,
} from 'recompose';
import TableView from 'modules/core/componentsLegacy/Table/TableView';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import withPaginationState from 'modules/core/componentsLegacy/withPaginationState';
import updateUserPreferenceFromProp from 'modules/user/components/updateUserPreferenceFromProp';
import withUserPreference from 'modules/user/components/withUserPreference';
import columnDefinitions from './columnDefinitions';
import invalidateBulkImportListAction from '../actions/invalidateBulkImportList';
import mapBulkImportIdsToBulkImports from './mapBulkImportIdsToBulkImports';
import withBulkImportListFetched from './withBulkImportListFetched';
import { COLUMNS, COLUMN_START_TIME } from '../constants';

let pollingCount = 0;
let pollTimeoutId;
const LONG_POLL_THRESHOLD = 6;
const SHORT_POLL_INTERVAL = 10000;
const LONG_POLL_INTERVAL = 30000;

export default compose(
  setDisplayName('BulkImportTable'),
  setPropTypes({
    columns: PropTypes.arrayOf(PropTypes.oneOf(COLUMNS)),
    /**
     * A unique key used to maintain the state of this table across sessions. Currently
     * this just includes the number of results shown per page.
     */
    stateKey: PropTypes.string,
  }),
  defaultProps({
    columns: COLUMNS,
    stateKey: 'default',
  }),

  // Load the user's preferred results-per-page value from user preferences, if any.
  withUserPreference(
    'initialLimit',
    ({ stateKey }) => ['BulkImportTable', stateKey, 'limit'],
    ({ initialLimit }) => initialLimit || 25,
  ),

  // (*) If the parent component provides limit or page props directly, those take
  // precedence/override the state provided by withPaginationState. This pulls them off before
  // withPaginationState has a chance to change them, and saves them to potentially restore below.
  mapProps(({ limit, page, ...rest }) => ({
    ...rest,
    withPaginationStateOverrides: { limit: limit, page: page },
  })),

  // Provides `page`, `limit`, `onPageChange`, and `onLimitChange`
  withPaginationState(({ initialLimit }) => ({ initialLimit: initialLimit })),

  // (*) withPaginationStateOverrides will contain the limit and page props explicitly set above,
  // which should override any values set by withPaginationState, only if provided.
  mapProps(
    ({ limit, maxLimit, page, withPaginationStateOverrides, ...rest }) => ({
      ...rest,

      // Enforce a maxmimum limit of 100
      limit: Math.min(
        withPaginationStateOverrides.limit || limit,
        maxLimit || 100,
      ),
      page: Math.max(1, withPaginationStateOverrides.page || page),
    }),
  ),

  // Whenever the limit is changed, store that in the user's preferences so it can be automatically
  // loaded next time around (i.e. above, using withUserPreference).
  updateUserPreferenceFromProp('limit', ({ stateKey }) => [
    'BulkImportTable',
    stateKey,
    'limit',
  ]),
  // sorting state is provided here, but can be override using `filters`, below.
  withStateHandlers(
    ({ initialSortAscending, initialSortBy }) => ({
      sortBy: initialSortBy || COLUMN_START_TIME,
      sortAscending: Boolean(initialSortAscending),
      isPollingEnabled: true,
    }),
    {
      onSortChange: (_, { onSortChange }) => (sortBy, sortAscending) => {
        // If a callback was provided by the parent component, it wants to know when the sort
        // changes, so notify them first.
        if (onSortChange) {
          onSortChange(sortBy, sortAscending);
        }

        return { sortBy: sortBy, sortAscending: sortAscending };
      },
      handleBrowserFocus: () => () => ({ isPollingEnabled: true }),
      handleBrowserBlur: () => () => ({ isPollingEnabled: false }),
    },
  ),
  withBulkImportListFetched(),
  withProps(({ bulkImportList }) => ({
    bulkImportIds: bulkImportList && bulkImportList.get('ids'),
    isFetching: bulkImportList && bulkImportList.getIn(['_meta', 'isFetching']),
    totalPages:
      bulkImportList && bulkImportList.getIn(['pagination', 'total_pages']),
    currentPage:
      bulkImportList && bulkImportList.getIn(['pagination', 'current_page']),
    totalCount:
      bulkImportList && bulkImportList.getIn(['pagination', 'total_count']),
  })),
  mapBulkImportIdsToBulkImports,
  mapProps(({ bulkImportIds, columns, isFetching, ...props }) => ({
    ...props,
    columns: columns
      .map(column => columnDefinitions[column])
      .filter(column => column),

    data: bulkImportIds,

    // Render an empty state component when we don't have any imports.
    emptyState: !isFetching &&
      bulkImportIds &&
      bulkImportIds.count() === 0 && {
        message: 'No Bulk Imports',
      },
    loadingIndicator: isFetching && {},
  })),
  withClassName('BulkImportTable'),
  connect(null, {
    invalidateBulkImportList: invalidateBulkImportListAction,
  }),
  lifecycle({
    componentDidUpdate: function ({
      bulkImports,
      invalidateBulkImportList,
      isPollingEnabled,
    }) {
      const hasPendingImports =
        bulkImports &&
        bulkImports.toJS().some(bulkImport => !bulkImport.end_time);
      if (isPollingEnabled && hasPendingImports) {
        pollingCount += 1;

        // Poll every SHORT_POLL_INTERVAL LONG_POLL_THRESHOLDx, then every LONG_POLL_INTERVAL  after
        const timeoutDuration =
          pollingCount < LONG_POLL_THRESHOLD
            ? SHORT_POLL_INTERVAL
            : LONG_POLL_INTERVAL;
        clearTimeout(pollTimeoutId);
        pollTimeoutId = setTimeout(invalidateBulkImportList, timeoutDuration);
      }
    },
    componentDidMount: function () {
      window.addEventListener('focus', this.props.handleBrowserFocus);
      window.addEventListener('blur', this.props.handleBrowserBlur);
    },
    componentWillUnmount: function () {
      window.removeEventListener('focus', this.props.handleBrowserFocus);
      window.removeEventListener('blur', this.props.handleBrowserBlur);
    },
  }),
)(TableView);

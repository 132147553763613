import getFilteredInterviewListForParent from './getFilteredInterviewListForParent';
import getAllInterviewListForParent from './getAllInterviewListForParent';

/**
 * Gets the list of interviews that belong to a particular parent (parent type + id), optionally
 * for a specific filter (either DATE_GROUP_PAST or DATE_GROUP_UPCOMING)
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CONTACT or PARENT_CANDIDACY)
 * @param {Number} parentId The parent ID -- either a contact ID when
 *   parentType === PARENT_CONTACT, or a candidacy ID when parentType === PARENT_CANDIDACY
 * @param {Filter} [filter] The filter type (DATE_GROUP_PAST or DATE_GROUP_UPCOMING)
 * @return {Immutable.Map|undefined} The interviews state, if it's been created (it won't be created
 *   until an action related to it has been dispatched)
 */
export default (state, parentType, parentId, filter) =>
  filter
    ? getFilteredInterviewListForParent(state, parentType, parentId, filter)
    : getAllInterviewListForParent(state, parentType, parentId);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import getTenantId from 'modules/tenant/selectors/getTenantId';
import customerConfigurationSchema from '../schemas/customerConfiguration';

/**
 * Returns a value indicating whether the customer details configuration has been fetched for the current
 * tenant.
 */
export default state =>
  Boolean(
    getEntityProperty(
      state,
      customerConfigurationSchema.key,
      getTenantId(state),
      'name',
    ),
  );

import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
  withProps,
} from 'recompose';
import Modal from 'modules/core/componentsLegacy/Modal';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import mapStageIdToStage from 'modules/search-stages/components/mapStageIdToStage';
import CandidacyMoveStageField from './CandidacyMoveStageField';
import mapCandidacyIdToProperty from '../mapCandidacyIdToProperty';
import withCandidacyUpdateForm from '../withCandidacyUpdateForm';
import mapCandidacyIdToCandidacy from '../mapCandidacyIdToCandidacy';

/**
 * A modal for changing a candidacy's stage.
 */
const CandidacyMoveStageModalForm = ({
  currentStageId,
  formState,
  isRedirecting,
  onFieldStateChange,
  onHide,

  onSubmit,
  show,
  stageType,
  ...fieldProps
}) => (
  <Modal className='CandidacyMoveStageModalForm' onHide={onHide} show={show}>
    <Form formState={formState} onSubmit={onSubmit}>
      <Modal.Header closeButton={true}>
        <Modal.Title>Select a New Stage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CandidacyMoveStageField
          {...fieldProps}
          currentStageId={currentStageId}
          disabled={isRedirecting || formState.isSubmitting()}
          fieldState={formState.getFieldState()}
          onChange={onFieldStateChange}
          showErrors={formState.wasSubmitted() || 'blurred'}
          stageType={stageType}
        />
        <FormErrorMessage formState={formState} />
        {isRedirecting && 'Redirecting to offer form...'}
      </Modal.Body>
      <Modal.Footer>
        <CancelButton bsSize='lg' onClick={onHide} />
        <SubmitButton bsSize='lg' formState={formState} onClick={onSubmit} />
      </Modal.Footer>
    </Form>
  </Modal>
);

CandidacyMoveStageModalForm.propTypes = {
  /**
   * The ID of the stage the candidacy is currently assigned to.
   */
  currentStageId: PropTypes.number.isRequired,

  /**
   * THe form state for the modal's form.
   */
  formState: formStatePropType.isRequired,

  /**
   * If the user is being redirected to the offers page.
   */
  isRedirecting: PropTypes.bool,

  /**
   * Called when the root fieldstate of the formState is changed
   * (handled by withCandidacyUpdateForm)
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Called when the user clicks the cancel button or otherwise wants to close the modal
   */
  onHide: PropTypes.func.isRequired,

  /**
   * Called when the form should be submitted (handled by withCandidacyUpdateForm)
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * True if the modal should be displayed, otherwise false.
   */
  show: PropTypes.bool.isRequired,

  /**
   * The type of stages to show in the form (`JobSearchStage` vs `TalentPoolStage`)
   */
  stageType: PropTypes.string,
};

export default compose(
  setDisplayName('CandidacyMoveStageModalForm(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,

    /**
     * Called when the modal should be closed.
     */
    onHide: PropTypes.func.isRequired,

    /**
     * Whether the modal should be rendered.
     */
    show: PropTypes.bool.isRequired,
  }),

  mapCandidacyIdToProperty('candidacy_stage', 'stageId'),
  mapStageIdToStage,
  mapCandidacyIdToCandidacy,
  withProps(({ candidacy, stage }) => ({
    currentStageId: stage.get('id'),
    stageName: stage.get('name'),
    stageType: candidacy && candidacy.get('stage_type'),
  })),
  withStateHandlers(
    {
      isRedirecting: false,
    },
    {
      /**
       * Redirects to the offers page of the search when offered stage
       * is selected.
       */
      onSaved: (_, { candidacy, onHide, stage }) => () => {
        if (stage && stage.get('offer')) {
          const searchId = candidacy.get('search_id');
          window.location = `/searches/${searchId}/edit#offers`;
          return { isRedirecting: true };
        }
        onHide();
        return {};
      },
    },
  ),

  withCandidacyUpdateForm(CandidacyMoveStageField),
)(CandidacyMoveStageModalForm);

import { USER_STATE_KEY } from '../constants';

/**
 * Returns a value indicating if the current user is a hiring manager user.
 * @param {*} state
 * @returns {Boolean} true if the current user is in the "hiring manager" role; otherwise, returns false.
 */
const isHiringManagerUser = state =>
  state[USER_STATE_KEY].get('hiring_manager', false);

export default isHiringManagerUser;

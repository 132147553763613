import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import CancelButton from 'modules/forms/components/CancelButton';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withContactForm from '../../withContactForm';
import ContactCaslStatusField from './ContactCaslStatusField';

/**
 * A form for editing a contact's "casl status", which consists of a single
 * rich text edit field.
 */
const ContactCaslStatusForm = ({
  formState,
  onCancel,
  onFieldStateChange,
  onSubmit,
  statusOptions,
  ...fieldProps
}) => (
  <Form formState={formState} onSubmit={onSubmit}>
    <div className='u-marginTop-12'>
      <ContactCaslStatusField
        {...fieldProps}
        clearable={false}
        fieldState={formState.getFieldState()}
        key='casl_status'
        name='casl_status'
        onChange={onFieldStateChange}
        options={statusOptions}
        placeholder='Select Status ...'
        simpleValue={true}
      />
    </div>
    <FormErrorMessage formState={formState} />
    <div className='Form__footer'>
      <CancelButton onClick={onCancel} />
      <SubmitButton formState={formState} onClick={onSubmit} />
    </div>
  </Form>
);

ContactCaslStatusForm.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the cancel button is clicked.
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Called when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * The CASL status options list to supply to the field.
   */
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

/**
 * The connected version of this component includes a FormState/FieldState and
 * handlers for submitting the form.
 */
export default compose(
  setDisplayName('ContactCaslStatusForm(enhanced))'),
  setPropTypes({
    /**
     * The ID of the contact for which the casl status will be edited.
     */
    contactId: PropTypes.number,

    /**
     * Called when the cancel button on the form is clicked.
     */
    onCancel: PropTypes.func.isRequired,

    /**
     * Called when the form has been successfully saved.
     */
    onSaved: PropTypes.func.isRequired,
  }),

  // Provides the field state, form state, and submission handling.
  withContactForm(ContactCaslStatusField),

  /**
   * Prevent props from being passed on to the underlying fieldState
   */
  withPropsRemoved(
    'contactId',
    'onFormStateChange',
    'onResetFormState',
    'onSaved',
  ),
)(ContactCaslStatusForm);

import { compose, lifecycle, setDisplayName, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import fetchResidencyListActionCreator from '../actions/fetchResidencyList';
import shouldFetchResidencyListSelector from '../selectors/shouldFetchResidencyList';
import getResidencyList from '../selectors/getResidencyList';

/**
 * Fetches the residency list if needed and provides the `residencyList` prop.
 * */
export default compose(
  setDisplayName('withResidencyListFetched'),
  connect(
    (state, { companyId }) => ({
      residencyList: getResidencyList(state, companyId),
      shouldFetchResidencyList: shouldFetchResidencyListSelector(
        state,
        companyId,
      ),
    }),
    dispatch => ({
      fetchResidencyList: bindActionCreators(
        fetchResidencyListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchResidencyListIfNeeded: ({
      fetchResidencyList,
      shouldFetchResidencyList,
    }) => () => {
      if (shouldFetchResidencyList) {
        fetchResidencyList();
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchResidencyListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchResidencyListIfNeeded();
    },
  }),
);

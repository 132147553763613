import getUserPermission from 'modules/auth/selectors/getUserPermission';

/**
 * Gets a value indicating whether the current user has permission to create interviews
 * @param {Object} state
 * @return {Boolean} True if the current user is allowed to create interviews; otherwise, false.
 */
const canCreateInterview = state =>
  getUserPermission(state, 'create_interview', false);

export default canCreateInterview;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import Select, { components } from 'react-select';
import SegmentedControl from '@thrivetrm/ui/components/SegmentedControl';
import { useSelector, useDispatch } from 'react-redux';
import { stringToCamelCase, stringToSnakeCase } from 'modules/core/jsonUtils';
import { isEqual } from 'lodash';
import {
  registerFilterInput,
  selectFilterInputData,
  setAndValidateSingleFilterValue,
  requestRecordList,
} from '../../recordIndexSlice';
import { EMPTY_STYLES } from './common-react-select-constants';

const Control = props => {
  /**
   * Once the component has mounted a changing saved view value does not trigger a state update
   * in the react-select component after page refresh.  To remain in sync with savedView.filters
   *  the library provided `setValue()` prop is used to sync the value.
   * See https://react-select.com/components#defining-components for explanation of issues.
   */
  const { filterInputName, getValue, options, setValue } = props;
  const savedViewValue = useSelector(
    state => state.recordIndex.savedView?.filters?.[filterInputName] || [],
  );

  const formInputValue = getValue?.()?.map(option => `${option.value}`);
  const savedViewInputValue = savedViewValue?.values?.map(String);

  const isFilterInputSyncedWSavedView = isEqual(
    formInputValue,
    savedViewInputValue,
  );

  useEffect(() => {
    if (!isFilterInputSyncedWSavedView) {
      setValue(
        savedViewValue?.values?.map(stringValue =>
          options.find(option => `${option.value}` === stringValue),
        ),
        'set-value',
      );
    }
  }, [isFilterInputSyncedWSavedView, options, savedViewValue, setValue]);

  return <components.Control {...props} />;
};

Control.propTypes = {
  filterInputName: PropTypes.string.isRequired,
  getValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  setValue: PropTypes.func.isRequired,
};

const MultiValueSelectBooleanFilter = ({ label, name, onChange }) => {
  const dispatch = useDispatch();

  const hasRegisteredFilterInput = useSelector(state =>
    Boolean(state.recordIndex?.savedView?.filters?.[stringToCamelCase(name)]),
  );

  const { placeholder } = useSelector(state =>
    selectFilterInputData(state, name),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  const filterInputValue = useSelector(
    state => state.recordIndex?.savedView?.filters?.[name],
  );

  const options = useSelector(state => {
    return state.recordIndex.availableFilterInputs[
      stringToSnakeCase(name)
    ]?.options?.map(option => ({
      label: option.name,
      value: option.id,
    }));
  });

  const handleChange = newValue => {
    const normalizedValue = newValue?.map(option => option.value) || [];

    if (onChange) onChange(newValue);

    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: {
          values: normalizedValue,
          operator: filterInputValue.operator,
        },
      }),
    );

    dispatch(requestRecordList());
  };

  const handleToggle = newBooleanOperator => {
    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: {
          values: filterInputValue?.values,
          operator: newBooleanOperator,
        },
      }),
    );
    dispatch(requestRecordList());
  };

  // Wait for saved view to be seeded before rendering
  if (!hasRegisteredFilterInput) return null;

  return (
    <div>
      {label && (
        <label className='MultiValueTextBooleanFilter__label'>{label}</label>
      )}
      <div className='MultiValueSelectBooleanFilter'>
        <Select
          className='MultiValueTextBooleanFilter__creatableContainer'
          classNamePrefix='MultiValueSelectBooleanFilter'
          components={{
            // eslint-disable-next-line react/jsx-props-no-spreading
            Control: props => <Control {...props} filterInputName={name} />,
            DropdownIndicator: null,
          }}
          isClearable={false}
          isMulti={true}
          onChange={handleChange}
          options={options}
          placeholder={placeholder || 'Select...'}
          styles={EMPTY_STYLES}
          value={filterInputValue?.values?.map(optionId =>
            options.find(option => option.value === optionId),
          )}
        />
        <SegmentedControl
          className='MultiValueTextBooleanFilter__booleanOperator'
          onChange={handleToggle}
          segments={['AND', 'OR']}
          selectedSegmentLabel={filterInputValue?.operator}
        />
      </div>
    </div>
  );
};

MultiValueSelectBooleanFilter.defaultProps = {
  label: null,
  onChange: null,
};

MultiValueSelectBooleanFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default MultiValueSelectBooleanFilter;

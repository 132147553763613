import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import {
  requestRecordList,
  setColumns,
} from 'modules/recordIndex/recordIndexSlice';
import RecordIndexSidebarSavedViews from './RecordIndexSidebarSavedViews';
import RecordIndexSidebarNetworks from './RecordIndexSidebarNetworks';
import RecordIndexSidebarFilters from './RecordIndexSidebarFilters';
import ConfigureColumnsModal from './ConfigureColumnsModal';
import ExportModal from './ExportModal';

const RecordIndexSidebar = () => {
  const dispatch = useDispatch();
  const { isSidebarExpandedKey, recordType } = useSelector(
    state => state.recordIndex,
  );

  const isSidebarExpandedInitialValue =
    JSON.parse(window.localStorage.getItem(isSidebarExpandedKey)) ?? true;

  const [isSidebarExpanded, _open, _close, toggleSidebar] = useToggle(
    isSidebarExpandedInitialValue,
  );

  useEffect(() => {
    window.localStorage.setItem(isSidebarExpandedKey, isSidebarExpanded);
  }, [isSidebarExpanded, isSidebarExpandedKey]);

  const [isExportModalOpen, openExportModal, closeExportModal] = useToggle(
    false,
  );

  const [
    isConfigureColumnsModalOpen,
    openConfigureColumnsModal,
    closeConfigureColumnsModal,
  ] = useToggle(false);

  const handleConfigureColumnsSave = columnKeys => {
    dispatch(setColumns(columnKeys));
    dispatch(requestRecordList());
    closeConfigureColumnsModal();
  };

  // The filters on the record index page are changed via a legacy modal that is not connected to react
  // At some point in the future we will want to refactor this work.  To guard against miss removing one of the magic function
  // buttons, a single implementation is used.
  const legacyFilterButton = (title = null) => (
    <ButtonSecondary
      data-backdrop='static'
      data-target='#filters'
      data-testid='add button'
      data-toggle='modal'
      icon='funnel'
      size='small'
      title={title}
    />
  );

  const containerClass = classnames('RecordIndexPage__sidebar', {
    'RecordIndexPage__sidebar--collapsed': !isSidebarExpanded,
  });

  const renderExpandedSidebar = () => (
    <div className={containerClass}>
      <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween u-marginBottom-16'>
        <h2 className='u-margin-n u-marginRight-8'>View</h2>
        <div>
          <ButtonSecondary
            icon='export'
            label='Export'
            onClick={openExportModal}
            size='small'
          />
          <ButtonSecondary
            className='u-marginLeft-8 u-noWrap'
            icon='editSpreadsheet'
            label='Edit Columns'
            onClick={openConfigureColumnsModal}
            size='small'
          />
          <ButtonSecondary
            className='u-marginLeft-8'
            icon='chevronLeft'
            onClick={toggleSidebar}
            size='small'
            title='Collapse'
          />
        </div>
      </div>
      <RecordIndexSidebarSavedViews />
      <RecordIndexSidebarNetworks
        className='u-borderBottom u-paddingVertical-24'
        pageName={recordType}
      />
      <RecordIndexSidebarFilters filterButton={legacyFilterButton()} />
    </div>
  );

  const renderCollapsedSidebar = () => (
    <div className={containerClass}>
      <ButtonSecondary
        icon='chevronRight'
        onClick={toggleSidebar}
        size='small'
        title='Expand'
      />
      <div className='u-marginVertical-8'>
        <ButtonSecondary
          icon='export'
          onClick={openExportModal}
          size='small'
          title='Export'
        />
      </div>
      <ButtonSecondary
        icon='editSpreadsheet'
        onClick={openConfigureColumnsModal}
        size='small'
        title='Edit Columns'
      />
      <div className='u-borderBottom u-marginVertical-16' />
      {legacyFilterButton('Edit Filters')}
    </div>
  );

  return (
    <>
      {isSidebarExpanded ? renderExpandedSidebar() : renderCollapsedSidebar()}
      {isExportModalOpen ? <ExportModal onClose={closeExportModal} /> : null}
      {isConfigureColumnsModalOpen ? (
        <ConfigureColumnsModal
          isOpen={true}
          onClose={closeConfigureColumnsModal}
          onSave={handleConfigureColumnsSave}
          recordType={recordType}
        />
      ) : null}
    </>
  );
};

export default RecordIndexSidebar;

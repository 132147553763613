import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@thrivetrm/ui/components/Avatar';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Grid from '@thrivetrm/ui/components/Grid';
import Loader from '@thrivetrm/ui/components/Loader';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import {
  toggleCandidacySelection,
  selectHasStartedBulkUpdate,
  selectDataForSelectedCandidacies,
  reset,
} from './candidacyBulkActionsSlice';
import CandidacyStageTag from './CandidacyStageTag';

const BulkUpdateCandidacySummary = () => {
  const dispatch = useDispatch();

  const data = useSelector(selectDataForSelectedCandidacies);

  const {
    failedCandidacyIds,
    selectedCandidacyIds,
    updatedCandidacyIds,
  } = useSelector(state => state.candidacyBulkActions);

  const hasStartedBulkUpdate = useSelector(selectHasStartedBulkUpdate);

  const handleClear = candidacyId => {
    if (selectedCandidacyIds.length === 1) {
      dispatch(reset());
    } else {
      dispatch(toggleCandidacySelection(candidacyId));
    }
  };

  return (
    <>
      <Grid className='u-textColor-gray60 u-marginTop-12'>
        <Grid.Column className='u-flex u-flexAlign-c u-fontSize-large' size={2}>
          <strong style={{ textDecoration: 'underline' }}>Status</strong>
        </Grid.Column>
        <Grid.Column
          className='u-flex u-flexAlign-c u-fontSize-large'
          size-={8}
        >
          <strong style={{ textDecoration: 'underline' }}>Candidate</strong>
        </Grid.Column>
        <Grid.Column
          className='u-flex u-flexAlign-c u-fontSize-large u-flexJustify-r'
          size={2}
        >
          <strong style={{ textDecoration: 'underline' }}>Deselect</strong>
        </Grid.Column>
      </Grid>
      {selectedCandidacyIds.map((selectedCandidacyId, index) => {
        const { contact, position, stage } = data[selectedCandidacyId];

        return (
          <Grid className='u-marginVertical-12' key={selectedCandidacyId}>
            <Grid.Column size={2}>
              <div className='u-inlineBlock u-padding-12'>
                {updatedCandidacyIds.length + failedCandidacyIds.length <
                  index || !hasStartedBulkUpdate ? (
                  <div className='u-textAlign-c u-noWrap'>Queued</div>
                ) : null}
                {hasStartedBulkUpdate &&
                updatedCandidacyIds.length + failedCandidacyIds.length ===
                  index ? (
                  <Loader size='small' />
                ) : null}
                {updatedCandidacyIds.includes(selectedCandidacyId) ? (
                  <StatusIndicator size='large' status='success' />
                ) : null}
                {failedCandidacyIds.includes(selectedCandidacyId) ? (
                  <StatusIndicator size='large' status='error' />
                ) : null}
              </div>
            </Grid.Column>
            <Grid.Column className='u-flex u-flexAlign-c' size={8}>
              <Avatar
                image={contact.avatar_url}
                key={contact.avatar_url}
                name={contact.full_name}
                size='small'
              />

              <div className='u-marginLeft-12 u-flexItem-grow'>
                <p className='u-margin-n u-textColor-gray60 u-fontWeight-bold'>
                  {contact.full_name}
                </p>
                {position?.name || position?.company_name ? (
                  <p className='u-margin-n u-fontSize-small u-truncate'>
                    {position.name}
                    {position.name && position.company_name ? ' at ' : null}
                    {position.company_name}
                  </p>
                ) : null}
                <CandidacyStageTag
                  className='u-padding-4'
                  color={stage.color}
                  name={stage.name}
                />
              </div>
            </Grid.Column>
            <Grid.Column
              className='u-flex u-flexAlign-c u-flexJustify-r'
              size={2}
            >
              <ButtonSecondary
                icon='clear'
                isDisabled={hasStartedBulkUpdate}
                onClick={() => handleClear(selectedCandidacyId)}
                size='small'
              />
            </Grid.Column>
          </Grid>
        );
      })}
    </>
  );
};

export default BulkUpdateCandidacySummary;

import invalidateEntity from 'modules/entities/actions/invalidateEntity';
import noteSchema from '../schema';

/**
 * Invalidates a note entity so it is refetched from the server the next time
 * it is needed.
 * @param {Object} payload
 * @param {Number} payload.id The note ID
 */
export default ({ id }) =>
  invalidateEntity({
    entityType: noteSchema.key,
    id: id,
  });

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import * as validators from 'modules/forms/validators';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import TimezoneSelectField from 'modules/datetime/components/TimezoneSelectField';

const handleFieldChange = (fieldState, onChange) => childFieldState => {
  const nextFieldState = fieldState.setNestedField(childFieldState);
  onChange(nextFieldState);
};

const formattedEmailOptions = contact => {
  const personalEmail = contact.get('email');
  const workEmail = contact.get('work_email');
  const workEmailPreferred = contact.get('work_email_preferred');

  const options = [personalEmail, workEmail]
    .filter(email => email !== null)
    .map(email => ({ value: email, label: email }));

  return workEmailPreferred ? options.reverse() : options;
};

const CandidatePortalInviteField = props => {
  const { contact, fieldState, onChange, ...otherProps } = props;
  return (
    <div className='CandidatePortalInviteField'>
      <span className='help-block'>
        Once {contact.get('first_name') || 'the contact'} accepts the invitation
        to the candidate portal, they’ll be able to log in to Thrive to update
        their contact profile and provide additional job preferences. To learn
        more, visit the help center.
      </span>
      <div className='row'>
        <div className='col-sm-12'>
          <ReactSelectField
            {...otherProps}
            clearable={false}
            fieldState={fieldState.getNestedField('email')}
            label='Email Address'
            onChange={handleFieldChange(fieldState, onChange)}
            options={formattedEmailOptions(contact)}
            searchable={false}
            simpleValue={true}
          />
        </div>
        <div className='hidden'>
          <TimezoneSelectField
            fieldState={fieldState.getNestedField('time_zone')}
            label='Timezone'
            name='time_zone'
            onChange={handleFieldChange(fieldState, onChange)}
          />
        </div>
        <div className='hidden'>
          <InputField
            {...otherProps}
            fieldState={fieldState.getNestedField('role')}
            label='User Role'
            onChange={handleFieldChange(fieldState, onChange)}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  );
};

CandidatePortalInviteField.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    email: PropTypes.string,
    work_email: PropTypes.string,
  }),

  fieldState: fieldStatePropType.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CandidatePortalInviteField'),
  setStatic('createFieldState', (name = 'invite-to-portal', contact) =>
    FieldState.createNested(name, [
      ReactSelectField.createFieldState(
        'email',
        contact.get('preferred_email') ||
          contact.get('email') ||
          contact.get('work_email') ||
          '',
        validators.requiredField('Email Address'),
      ),
      TimezoneSelectField.createFieldState(
        'time_zone',
        'Pacific Time (US & Canada)',
      ),
      InputField.createFieldState('role', 'Candidate'),
    ]),
  ),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
)(CandidatePortalInviteField);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  onlyUpdateForPropTypes,
  renderNothing,
} from 'recompose';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import TargetCompanyIndicatorIcon from 'modules/target-companies/components/TargetCompanyIndicatorIcon';
import getCandidacyProperty from '../selectors/getCandidacyProperty';
import SearchCandidacyLink from './SearchCandidacyLink';

/**
 * Renders a count of the number of target companies along with the target
 * company icon (bullseye). A tooltip shows a list of the target company
 * names when hovered,
 */
const CandidacyTargetCompanyCount = ({
  candidacyId,
  componentId,
  targetCompanyNames,
}) => (
  <div className='CandidacyTargetCompanyCount'>
    <OverlayTrigger
      overlay={
        <Tooltip id={componentId}>
          <ul className='list-unstyled'>
            {targetCompanyNames.map(company => (
              <li key={company}>{company}</li>
            ))}
          </ul>
        </Tooltip>
      }
      placement='top'
    >
      <SearchCandidacyLink candidacyId={candidacyId}>
        <small>{targetCompanyNames.count()}</small>
        <TargetCompanyIndicatorIcon />
      </SearchCandidacyLink>
    </OverlayTrigger>
  </div>
);

CandidacyTargetCompanyCount.propTypes = {
  /**
   * The ID of the candidacy to show the indicator for.
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * A unique ID for this component's tooltip
   */
  componentId: PropTypes.string.isRequired,

  /**
   * A list the names of the target companies themselves, which will
   * be shown in a tooltip when the component is hovered.
   */
  targetCompanyNames: ImmutablePropTypes.listOf(PropTypes.string.isRequired),
};

export default compose(
  withComponentId(),
  connect(
    (state, { candidacyId }) => ({
      targetCompanyNames: getCandidacyProperty(
        state,
        candidacyId,
        'active_target_companies',
      ),
    }),
    {},
  ),
  branch(
    ({ targetCompanyNames }) =>
      !targetCompanyNames || !targetCompanyNames.count(),
    renderNothing,
  ),
  onlyUpdateForPropTypes,
)(CandidacyTargetCompanyCount);

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RecordIndexTable from './components/RecordIndexTable';
import RecordIndexHeader from './components/RecordIndexHeader';
import { clearLegacyFilters, setLegacyFilters } from './recordIndexSlice';
import RecordIndexBulkActions from './components/RecordIndexBulkActions';
import RecordIndexSidebar from './components/RecordIndexSidebar';

const RecordIndexPage = () => {
  const dispatch = useDispatch();
  const { filterAdapter, selectedRecordIds } = useSelector(
    state => state.recordIndex,
  );

  // The filter modal exists outside of react.  To keep the state current, we listen for the form being submitted
  // or the reset link being clicked and then sync the store.
  useEffect(() => {
    const handleSubmit = event => dispatch(setLegacyFilters(event));
    const handleClear = event => dispatch(clearLegacyFilters(event));

    filterAdapter.formEl().addEventListener('submit', handleSubmit);
    filterAdapter.resetLinkEl().addEventListener('click', handleClear);
    return () => {
      filterAdapter.formEl().removeEventListener('submit', handleSubmit);
      filterAdapter.resetLinkEl().removeEventListener('click', handleClear);
    };
  }, [dispatch, filterAdapter]);

  return (
    <div className='RecordIndexPage'>
      <RecordIndexHeader />
      <div className='RecordIndexPage__content'>
        <RecordIndexSidebar />
        <RecordIndexTable />
      </div>
      {selectedRecordIds?.length ? <RecordIndexBulkActions /> : null}
    </div>
  );
};

export default RecordIndexPage;

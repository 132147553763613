import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import NotificationsFilterDropdown from 'components/notifications/NotificationsFilterDropdown';
import 'modules/company-pages/CompanyPage.scss';
import routes from 'modules/routing/routes';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import { NOTIFICATIONS_FILTERABLE_TYPES } from 'domains/notifications/constants';
import NotificationItem from './types/NotificationItem';

const OFFSET_LIMIT = 5;

const CompanyActivity = ({ companyId, filterValue, setFilterValue }) => {
  const [beforeValue, setBeforeValue] = useState(null);
  const [companyActivityData, setCompanyActivityData] = useState(null);

  const getTypesForFilter = useMemo(
    () =>
      Object.keys(filterValue)
        .filter(key => filterValue[key])
        .flatMap(key => NOTIFICATIONS_FILTERABLE_TYPES[key].filter),
    [filterValue],
  );

  const [
    loadCompanyNotifications,
    _isCompanyNotificationsLoading,
    _companyNotificationsError,
    companyNotifications,
  ] = useApiGet(
    routes.api_notifications({
      query: {
        company_id: companyId,
        limit: OFFSET_LIMIT,
        before: beforeValue,
        limited_visibility: true,
        types: getTypesForFilter,
      },
    }),
  );

  useEffect(() => {
    loadCompanyNotifications();
  }, [loadCompanyNotifications]);

  useEffect(() => {
    // If no filter is selected, we do not want to show anything for that setting companyActivityData to null.
    if (!getTypesForFilter.length) {
      setCompanyActivityData(null);
      return;
    }
    if (!companyNotifications?.notifications) return;

    // If 'beforeValue' is absent (used for fetching next data),
    // replace the current data with the new 'companyNotifications'.
    // When the user clicks "load more" and 'beforeValue' becomes available,
    // display both previous and new data together.
    if (!beforeValue) {
      setCompanyActivityData(companyNotifications);
    } else {
      setCompanyActivityData(oldValue => ({
        ...oldValue,
        notifications: [
          ...oldValue.notifications,
          ...companyNotifications.notifications,
        ],
      }));
    }
  }, [companyNotifications?.notifications]);

  const handleFilterChange = filters => {
    setBeforeValue(null);
    setFilterValue(oldValue => ({
      notes: filters?.notes ?? oldValue.notes,
      outreach: filters?.outreach ?? oldValue.outreach,
      intros: filters?.intros ?? oldValue.intros,
      references: filters?.references ?? oldValue.references,
      meetings: filters?.meetings ?? oldValue.meetings,
      interviews: filters?.interviews ?? oldValue.interviews,
    }));
  };

  const handleFetchNextPage = () => {
    // To fetch the next data, we want the sequential_id of the last element in the array.
    setBeforeValue(
      companyActivityData?.notifications.slice(-1)?.[0]?.sequential_id || '',
    );
  };

  return (
    <>
      <div className='row u-paddingBottom-16'>
        <div className='col-md-4'>
          <NotificationsFilterDropdown
            className='dropdown-block'
            isCompanyActivity={true}
            onChange={handleFilterChange}
            value={filterValue}
          />
        </div>
      </div>

      <div className='company-activity-body'>
        <ul className='list-group' key='notifications'>
          {companyActivityData?.notifications.map(notification => (
            <li
              className='list-group-item notification-list-item'
              key={notification.id}
            >
              <NotificationItem notification={notification} />
            </li>
          ))}
        </ul>
      </div>

      {companyNotifications?.meta?.count >
      companyActivityData?.notifications.length ? (
        <p className='text-center'>
          <button
            className='btn btn-primary-outline'
            onClick={() => handleFetchNextPage()}
            type='button'
          >
            Load More Activity
          </button>
        </p>
      ) : null}
    </>
  );
};

CompanyActivity.propTypes = {
  companyId: PropTypes.number,
  filterValue: PropTypes.shape({
    interviews: PropTypes.bool,
    intros: PropTypes.bool,
    meetings: PropTypes.bool,
    notes: PropTypes.bool,
    outreach: PropTypes.bool,
    references: PropTypes.bool,
  }),
  setFilterValue: PropTypes.func,
};

export default CompanyActivity;

import React from 'react';
import PropTypes from 'prop-types';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import TargetCompanyName from './TargetCompanyName';
import TargetCompanyCandidateCount from './TargetCompanyCandidateCount';
import TargetCompanyCommentsButton from './TargetCompanyCommentsButton';

const TargetCompanyNameColumn = ({
  onCandidateCountClick,
  targetCompanyId,
}) => {
  const [isOpen, openComment, closeComment] = useToggle(false);

  return (
    <>
      <TargetCompanyName targetCompanyId={targetCompanyId} />
      <div className='u-flex u-flexAlign-c'>
        <TargetCompanyCandidateCount
          indicatorPosition='left'
          onClick={onCandidateCountClick}
          targetCompanyId={targetCompanyId}
        />
        <TargetCompanyCommentsButton
          btnClassName='btn-link'
          className='company-card-comments u-marginLeft-16'
          isOpen={isOpen}
          onClick={openComment}
          onClose={closeComment}
          targetCompanyId={targetCompanyId}
        />
      </div>
    </>
  );
};

TargetCompanyNameColumn.propTypes = {
  onCandidateCountClick: PropTypes.func,
  targetCompanyId: PropTypes.number,
};

export default TargetCompanyNameColumn;

import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, setPropTypes } from 'recompose';

import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';

import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';
import withDraftStorage from '../../../components/forms/withDraftStorage';

import ReferenceField from './ReferenceField';
import connectReferenceActions from './connectReferenceActions';
import mapReferenceIdToReference from './mapReferenceIdToReference';

export const createRootFieldState = ({
  candidacyId,
  contactId,
  draft,
  hasConnectionsFeature,
  hasReferencesConnectionsFeature,
  reference,
  searchId,
}) => {
  let referenceValue;
  if (reference) {
    // When editing an existing reference
    referenceValue = reference.withMutations(immutableReference => {
      if (draft?.content) {
        immutableReference.set('description', draft?.content);
      }
    });
  } else {
    // When creating a new reference
    referenceValue = fromJS({
      contact_id: contactId,
      candidacy_id: candidacyId,
      description: draft?.content,
      search: searchId,
    });
  }
  return ReferenceField.createFieldState(
    'reference',
    referenceValue,
    hasConnectionsFeature,
    hasReferencesConnectionsFeature,
  );
};

export const handleSubmit = (formState, props) => {
  const {
    candidacyId,
    contactId,
    reference,
    referenceActions: { createReference, updateReference },
  } = props;

  const transactionId = uniqueId();

  // The name property is deprecated and we shouldn't send it along any more.
  const { name: _name, ...fieldValue } = formState.getFieldValue();
  const values = (
    reference || fromJS({ contact_id: contactId, candidacy_id: candidacyId })
  ).merge(fieldValue);

  const action = values.get('id') ? updateReference : createReference;

  action({
    reference: values.toJS(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export const connectForm = compose(
  setPropTypes({
    /**
     * If editing an reference
     */
    reference: ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),

    referenceId: PropTypes.number,

    /**
     * The ID of the candidacy this reference should be associated with. This or candidacyId is
     * required when creating a new reference
     */
    candidacyId: requiredIf(
      PropTypes.number,
      ({ contactId, reference, referenceId }) =>
        !(reference || referenceId || contactId),
    ),

    /**
     * The ID of the contact this reference should be associated with. This or candidacyId is
     * required when creating a new reference
     */
    contactId: requiredIf(
      PropTypes.number,
      ({ candidacyId, reference, referenceId }) =>
        !(reference || referenceId || candidacyId),
    ),
  }),
  branch(
    props => props.referenceId && !props.reference,
    mapReferenceIdToReference,
  ),
  connectTransactions,
  connectReferenceActions,
);

export default compose(
  connectForm,
  withDraftStorage(),
  withFeatureCheck('feature.connections_gate', 'hasConnectionsFeature'),
  withFeatureCheck(
    'feature.references_connections',
    'hasReferencesConnectionsFeature',
  ),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'reference-form',
    omitProps: [
      'candidacyId',
      'formState',
      'onSaved',
      'reference',
      'referenceActions',
      'searchId',
      'transactionActions',
    ],
  }),
)(ReferenceField);

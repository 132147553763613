import { connect } from 'react-redux';
import getOutreach from '../selectors/getOutreach';

/**
 * A higher order component which maps a list of `outreachIds` to
 * their outreach records.
 */
export default connect(
  (state, { outreachIds }) => ({
    outreaches: outreachIds && outreachIds.map(id => getOutreach(state, id)),
  }),
  {},
);

import React from 'react';
import PropTypes from 'prop-types';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import AttachmentsListItem from 'modules/documents/components/AttachmentsListItem';
import Notification from './Notification';

const Outreach = ({ notification }) => {
  return (
    <Notification notification={notification}>
      <div className='outreaches--outreach-view'>
        {notification?.resource?.subject ? (
          <div className='outreaches--outreach-view-subject'>
            <strong>Subject:</strong> {notification?.resource?.subject}
          </div>
        ) : null}
        {notification?.resource?.notes && (
          <ExpandableText
            characterLimit={150}
            content={notification?.resource?.notes}
            isSanitizedHtml={true}
          />
        )}

        <div className='AttachmentsView'>
          {notification?.resource?.documents &&
            notification?.resource?.documents.map(document => (
              <AttachmentsListItem
                destroy={document?.destroy}
                fileName={document?.file_name}
                fileSize={document?.file_size}
                key={document?.id}
                url={document?.url}
              />
            ))}
        </div>
      </div>
    </Notification>
  );
};

Outreach.propTypes = {
  notification: PropTypes.shape({
    resource: PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          destroy: PropTypes.bool,
          file_name: PropTypes.string,
          id: PropTypes.number,
          label: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
      notes: PropTypes.string,
      subject: PropTypes.string,
    }),
  }),
};
export default Outreach;

import { fromJS, Iterable } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import getPosition from 'modules/positions/selectors/getPosition';
import * as companyActionCreators from 'modules/companies/actions';
import getEducation from 'modules/contacts/selectors/educations/getEducation';
import clearTransaction from 'modules/transactions/actions/clearTransaction';
import getEntity from 'modules/entities/selectors/getEntity';
import { connectionsSchema } from 'modules/contacts/schema';
import * as contactActionCreators from '../../../../actions/contacts';
import * as contactReviewActionCreators from '../../../../actions/contactReviews';
import selectedContact from '../../../../selectors/contact/selectedContact';

const mapStateToProps = state => {
  const contact = selectedContact(state);

  // Hackityy-hack-hack-hacky fix for passing position and education records into the ContactForm
  // for contact reviews.
  const contactPositionIds =
    (contact && contact.getIn(['data', 'positions'])) || fromJS([]);
  const contactPositions = contactPositionIds.map(idOrData =>
    Iterable.isIterable(idOrData) ? idOrData : getPosition(state, idOrData),
  );

  const contactEducationIds =
    (contact && contact.getIn(['data', 'educations'])) || fromJS([]);
  const contactEducations = contactEducationIds.map(idOrData =>
    Iterable.isIterable(idOrData) ? idOrData : getEducation(state, idOrData),
  );

  const contactConnectionIds =
    (contact && contact.getIn(['data', 'connections'])) || fromJS([]);

  const connectionList = contactConnectionIds.map(idOrData =>
    Iterable.isIterable(idOrData)
      ? idOrData
      : getEntity(state, connectionsSchema.key, idOrData),
  );

  return {
    contact: contact,
    connectionList: connectionList,
    contactEducations: contactEducations,
    contactPositions: contactPositions,
    companies: state.companies,
    contactReviews: state.contactReviews,
    contacts: state.contacts.merge(state.contacts_new),
    tenant: state.tenant,
    transactions: state.transactions,
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => ({
  companyActions: bindActionCreators(companyActionCreators, dispatch),
  contactReviewActions: bindActionCreators(
    contactReviewActionCreators,
    dispatch,
  ),
  contactActions: bindActionCreators(contactActionCreators, dispatch),
  clearTransaction: bindActionCreators(clearTransaction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);

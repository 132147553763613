import getPathName from 'modules/routing/selectors/getPathName';
import getQueryValue from 'modules/routing/selectors/getQueryValue';
import { DASHBOARD_VIEW_DEFAULT } from '../constants';

/**
 * Gets the current view type selected for the dashboard page based on the URL
 * @param {Object} state
 * @return {String} The current `view` value of the dashboard page, if currently on the dashboard
 *   page, otherwise `DASHBOARD_VIEW_DEFAULT`
 */
export default state =>
  getPathName(state).indexOf('/talent_pools') === 0
    ? getQueryValue(state, 'view', DASHBOARD_VIEW_DEFAULT)
    : DASHBOARD_VIEW_DEFAULT;

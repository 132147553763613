import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import getSearchLeadRecruiterName from '../selectors/getSearchLeadRecruiterName';
import getSearchPrimaryRecruiterName from '../selectors/getSearchPrimaryRecruiterName';
import {
  USER_RELATION_LEAD_RECRUITER,
  USER_RELATION_PRIMARY_RECRUITER,
} from '../constants';

/**
 * Renders the name of user related to this search.
 * Can be either the "Lead Recruiter" (identified by USER_RELATION_LEAD_RECRUITER)
 * or "Primary Recruiter" (identified by USER_RELATION_PRIMARY_RECRUITER).
 */
export default compose(
  setDisplayName('SearchRelatedUserName'),
  setPropTypes({
    /**
     * The search ID of the search to show the location for.
     */
    searchId: PropTypes.number.isRequired,

    /**
     * The user relation type.
     */
    relatedType: PropTypes.oneOf([
      USER_RELATION_LEAD_RECRUITER,
      USER_RELATION_PRIMARY_RECRUITER,
    ]).isRequired,
  }),
  connect(
    (state, { relatedType, searchId }) => ({
      // These props get passed inot the span.
      children:
        relatedType === USER_RELATION_LEAD_RECRUITER
          ? getSearchLeadRecruiterName(state, searchId)
          : getSearchPrimaryRecruiterName(state, searchId),
      className: `SearchRelatedUserName SearchRelatedUserName-${relatedType}`,
    }),
    {},
  ),
)(({ relatedType: _relatedType, searchId: _searchId, ...props }) => (
  <span {...props} />
));

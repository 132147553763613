import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import selectn from 'selectn';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import SearchStageListPopover from 'modules/search-stages/components/SearchStageListPopover';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import withTransaction from 'modules/transactions/components/withTransaction';
import updateCandidacyAction from '../../actions/updateCandidacy';
import deleteCandidacyAction from '../../actions/deleteCandidacy';

/**
 * Renders a popover to allow changing the stage a candidacy belongs to.
 */
export default compose(
  setDisplayName('CandidacyStageListPopover'),
  setPropTypes({
    /**
     * The candidacy record, including the current stage and search it belongs to.
     */
    candidacy: ImmutablePropTypes.mapContains({
      search_id: PropTypes.number.isRequired,
      candidacy_stage: PropTypes.number,
    }),

    /**
     * Called when the popover has been closed/cancelled without selecting a new stage.
     */
    onCancel: PropTypes.func.isRequired,

    /**
     * Called when a "rejected" stage is selected, which typically requires a reason to be
     * selected before submitting.
     */
    onRejection: PropTypes.func.isRequired,

    /**
     * Called when a stage is selected and successfully changed.
     */
    onSaved: PropTypes.func.isRequired,

    /**
     * When true, if the candidate is moved to the "offer" stage, they
     * will be redirected to the offer form.
     */
    redirectOnOffer: PropTypes.bool.isRequired,

    /**
     * The title to show in the header of the popover.
     */
    title: PropTypes.string,
  }),
  defaultProps({
    redirectOnOffer: true,
    title: 'Move Stage',
  }),
  connect(null, {
    updateCandidacy: updateCandidacyAction,
    deleteCandidacy: deleteCandidacyAction,
  }),
  connectTransactions,

  withState('isSubmitting', 'setIsSubmitting', false),
  withState('submitError', 'setSubmitError'),

  withHandlers({
    onTransactionComplete: ({
      onSaved,
      setIsSubmitting,
      setSubmitError,
    }) => transaction => {
      setIsSubmitting(false);
      const error = selectn('payload.error', transaction);
      if (error) {
        setSubmitError(error);
      } else {
        onSaved();
      }
    },
  }),
  withTransaction,
  withHandlers({
    onDelete: ({
      candidacy,
      deleteCandidacy,
      setIsSubmitting,
      startTransaction,
    }) => () => {
      const transactionId = startTransaction();
      deleteCandidacy({
        transactionId: transactionId,
        id: candidacy.get('id'),
      });
      setIsSubmitting(true);
    },

    onSelectStage: ({
      candidacy,
      onRejection,
      redirectOnOffer,
      setIsSubmitting,
      startTransaction,
      updateCandidacy,
    }) => stage => {
      if (stage.get('rejected')) {
        onRejection(stage);
      } else {
        const transactionId = startTransaction();
        const searchId = candidacy.get('search_id');
        updateCandidacy({
          transactionId: transactionId,
          id: candidacy.get('id'),
          searchId: searchId,
          candidacy: {
            stage: stage.get('name'),
          },
          redirectAfterSuccess:
            redirectOnOffer && stage.get('offer')
              ? `/searches/${searchId}/edit#offers`
              : false,
        });
        setIsSubmitting(true);
      }
    },
  }),

  // These props aren't needed by the component, and we don't
  // want them being passed down into the Popover, so pull them off.
  /* eslint-disable no-unused-vars */
  mapProps(
    ({
      candidacy,
      clearTransaction,
      deleteCandidacy,
      onCancel,
      onRejection,
      onSaved,
      onTransactionComplete,
      redirectOnOffer,
      setIsSubmitting,
      setSubmitError,
      startTransaction,
      transaction,
      transactionActions,
      transactionId,
      transactions,
      updateCandidacy,
      ...rest
    }) => ({
      stageType: candidacy.get('stage_type'),
      value: candidacy.get('candidacy_stage'),
      ...rest,
    }),
    /* eslint-enable no-unused-vars */
  ),
)(SearchStageListPopover);

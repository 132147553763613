import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MailchimpIntegrationApiKey from './MailchimpIntegrationApiKey';
import MailchimpIntegrationGenerateToken from './MailchimpIntegrationGenerateToken';
import MailchimpIntegrationCopyWebhookUrl from './MailchimpIntegrationCopyWebhookUrl';

const MailchimpIntegrationPage = ({ initialApiKey, initialResidencyToken }) => {
  const [apiKey, setApiKey] = useState(initialApiKey);
  const [residencyToken, setResidencyToken] = useState(initialResidencyToken);

  return (
    <div className='MailchimpIntegrationPage'>
      <h2 className='u-marginBottom-16'>Mailchimp + Thrive Integration</h2>
      <p>
        Once configured, this integration will log all of your Mailchimp
        communications as outreaches in Thrive.
      </p>
      <MailchimpIntegrationApiKey apiKey={apiKey} onApiKeyChange={setApiKey} />
      <MailchimpIntegrationGenerateToken
        isDisabled={!apiKey}
        onTokenChange={setResidencyToken}
      />
      <MailchimpIntegrationCopyWebhookUrl
        isDisabled={!residencyToken}
        residencyToken={residencyToken}
      />
    </div>
  );
};

MailchimpIntegrationPage.defaultProps = {
  initialApiKey: '',
  initialResidencyToken: '',
};

MailchimpIntegrationPage.propTypes = {
  initialApiKey: PropTypes.string,
  initialResidencyToken: PropTypes.string,
};

export default MailchimpIntegrationPage;

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';

import TalentPoolLink from './TalentPoolLink';
import mapContactIdToTalentPoolId from './mapContactIdToTalentPoolId';

/**
 * When the associated contact has a talent pool, renders a link to that talent pool; otherwise,
 * renders a button to create a new talent pool for that contact.
 */
const ViewOrCreateTalentPoolLink = ({
  contactId,
  createTalentPoolLabel,
  onCreateTalentPool,
  talentPoolId,
  viewTalentPoolLabel,
}) => {
  if (!talentPoolId) {
    return (
      <button
        className='talent-pools--view-or-create-talent-pool btn btn-primary btn-sm'
        onClick={onCreateTalentPool}
        type='button'
        value={contactId}
      >
        {createTalentPoolLabel}
      </button>
    );
  }

  return (
    <TalentPoolLink talentPoolId={talentPoolId}>
      {viewTalentPoolLabel}
    </TalentPoolLink>
  );
};

ViewOrCreateTalentPoolLink.propTypes = {
  /**
   * The ID of the related contact.
   */
  contactId: PropTypes.number.isRequired,

  /**
   * The text to display for the create talent pool button.
   * @type {[type]}
   */
  createTalentPoolLabel: PropTypes.string,

  /**
   * Called when the create talent pool button is clicked. `event.target.value` will contain the
   * contact's ID.
   */
  onCreateTalentPool: PropTypes.func.isRequired,

  /**
   * When provided, the talent pool to link to. When not provided, the creact talent pool button
   * is shown instead.
   */
  talentPoolId: PropTypes.number,

  /**
   * The text to display for the view talent pool link
   */
  viewTalentPoolLabel: PropTypes.string,
};

ViewOrCreateTalentPoolLink.defaultProps = {
  createTalentPoolLabel: 'Create Talent Pool',
  viewTalentPoolLabel: 'View Talent Pool',
};

export default compose(
  setDisplayName('connect(ViewOrCreateTalentPoolLink)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToTalentPoolId,
  onlyUpdateForPropTypes,
)(ViewOrCreateTalentPoolLink);

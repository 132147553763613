import reduce from 'lodash/reduce';
// requires jquery

const getRange = (first, last) => {
  const range = [];
  const isAscending = first < last;
  if (isAscending) {
    for (let step = first; step <= last; step += 1) {
      range.push(step);
    }
  } else {
    for (let step = first; step >= last; step -= 1) {
      range.push(step);
    }
  }
  return range;
};

const elData = (el, attr) => {
  return JSON.parse(el.getAttribute(`data-${attr}`));
};

const buildCharts = (selector, chartFn, dataAttribute, ...rest) => {
  // Maps over the result of the selector, pulls
  // the data from the specified attribute, and
  // pipes that data into the specified chart function
  return $(selector).map((index, el) => {
    const data = elData(el, dataAttribute);
    return chartFn(data, el, ...Array.from(rest));
  });
};

const destroyCharts = collection => {
  // Destroys jQuery array of c3 instances
  // by calling the destroy method and
  // returns a blank array
  $.map(collection, instance => instance.destroy());
  return [];
};

const toColumns = (data, key, label) => {
  return reduce(
    data,
    (result, value) => {
      result.push(value[key]);
      return result;
    },
    [label],
  );
};

const generateTicksForColumns = (columns, ticks) => {
  const t = ticks - 1;
  const powTen = n => 10 ** n;
  const data = columns.slice(1);
  // Keep max above 100
  const maxValue = Math.max.apply(null, data.concat([100]));
  const interval = maxValue / t;
  const flog = Math.floor(Math.log10(interval));
  const roundedInterval = Math.ceil(interval / powTen(flog)) * powTen(flog);
  return getRange(0, t).map(val => val * roundedInterval);
};

export {
  buildCharts,
  destroyCharts,
  elData,
  generateTicksForColumns,
  toColumns,
};

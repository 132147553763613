import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import ContactSelect from './ContactSelect';

/**
 * A FieldState-wrapped version of the ContactSelect. The underlying fieldState value
 * should contain only the contactId of the selected contact.
 */
export default compose(
  setDisplayName('ContactSelectField'),
  setPropTypes({
    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', FieldState.create),
  withFormGroup,
  asField(),
)(ContactSelect);

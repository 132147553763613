import getUserPermission from 'modules/auth/selectors/getUserPermission';
import canViewCurrentCompensation from './canViewCurrentCompensation';

/**
 * Gets a value indicating whether the current user can generate/view compensation reports.
 * @param {Object} state
 * @return {Boolean} True if the current user can generate and view compensation reports;
 *   Otherwise, false.
 */
const canViewCompensationReport = state =>
  canViewCurrentCompensation(state) &&
  getUserPermission(state, 'view_candidate_compensation_report', false);

export default canViewCompensationReport;

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import Currency from '@thrivetrm/ui/components/Currency';
import DateTime from 'modules/datetime/components/DateTime';

const InterimCompensationView = ({ interimCompensation }) => {
  return (
    <div className='row' data-testid='contact-interim-compensation-view'>
      {interimCompensation.get('currency') ? (
        <LabeledContent
          className='col-sm-3 ContactInterimCompensationView__currency'
          label='Currency'
        >
          {interimCompensation.get('currency')}
        </LabeledContent>
      ) : null}
      {Number.isFinite(interimCompensation.get('day_rate')) ? (
        <LabeledContent
          className='col-sm-3 ContactInterimCompensationView__day_rate'
          label='Day Rate'
        >
          <Currency
            currency={interimCompensation.get('currency')}
            fallbackContent='No day rate'
            value={interimCompensation.get('day_rate')}
          />
        </LabeledContent>
      ) : null}
      {Number.isFinite(interimCompensation.get('day_rate_min')) ||
      Number.isFinite(interimCompensation.get('day_rate_max')) ? (
        <LabeledContent
          className='col-sm-3 ContactInterimCompensationView__day_rate_range'
          label='Day Rate'
        >
          <Currency
            currency={interimCompensation.get('currency')}
            fallbackContent='No min'
            value={interimCompensation.get('day_rate_min')}
          />
          <span> - </span>
          <Currency
            currency={interimCompensation.get('currency')}
            fallbackContent='No max'
            value={interimCompensation.get('day_rate_max')}
          />
        </LabeledContent>
      ) : null}
      {interimCompensation.get('start_date') ? (
        <LabeledContent className='col-sm-3' label='Start Date'>
          <DateTime value={interimCompensation.get('start_date')} />
        </LabeledContent>
      ) : null}
      {interimCompensation.get('end_date') ? (
        <LabeledContent className='col-sm-3' label='End Date'>
          <DateTime value={interimCompensation.get('end_date')} />
        </LabeledContent>
      ) : null}
      {interimCompensation.get('length_of_term') ? (
        <LabeledContent
          className='col-sm-3 ContactInterimCompensationView__length_of_term'
          label='Length of Term'
        >
          {interimCompensation.get('length_of_term')} Months
        </LabeledContent>
      ) : null}
      {interimCompensation.get('notes') ? (
        <LabeledContent
          className='col-sm-12 ContactInterimCompensationView__notes'
          label='Notes'
        >
          {interimCompensation.get('notes')}
        </LabeledContent>
      ) : null}
    </div>
  );
};

InterimCompensationView.propTypes = {
  interimCompensation: ImmutablePropTypes.mapContains({
    currency: PropTypes.string,
    day_rate: PropTypes.number,
    day_rate_min: PropTypes.number,
    day_rate_max: PropTypes.number,
    length_of_term: PropTypes.number,
    notes: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
};

export default InterimCompensationView;

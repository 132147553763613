import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import {
  NOTE_LIST_FETCH_START,
  NOTE_LIST_FETCH_SUCCESS,
  NOTE_LIST_FETCH_FAILURE,
} from './actionTypes';
import noteSchema from '../schema';
import getNoteListUrl from './getNoteListUrl';

/**
 * Creates an action for fetching a list of notes
 * @param {Object} payload
 * @param {String} payload.parentType The type of parent to fetch notes for (PARENT_CONTACT or
 *   PARENT_SEARCH)
 * @param {Number} payload.parentId The parent ID (contact ID or search ID, depending on the
 *   parentType)
 */
export default compose(
  fetchAction,
  normalizeResponse({ notes: [noteSchema] }),
  createAsyncActions({
    startActionType: NOTE_LIST_FETCH_START,
    successActionType: NOTE_LIST_FETCH_SUCCESS,
    failureActionType: NOTE_LIST_FETCH_FAILURE,
  }),
  withOptions(({ parentId, parentType }) => ({
    url: getNoteListUrl({ parentType: parentType, parentId: parentId }),
  })),
);

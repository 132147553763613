import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, lifecycle, setDisplayName, setPropTypes } from 'recompose';
import { bindActionCreators } from '@reduxjs/toolkit';

import * as actions from 'modules/assessments/actions';
import getCandidacyInviteToken from 'modules/assessments/selectors/getCandidacyInviteToken';
import getShouldCreateCandidacyInviteToken from 'modules/assessments/selectors/shouldCreateCandidacyInviteToken';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import ClipboardModalButton from 'modules/core/componentsLegacy/ClipboardModalButton';
import { TYPE_CLIENT } from 'modules/candidacy-assessments/constants';

/**
 * Renders a button that will create a guest assessment invite token/link
 * and show it in a modal when the button is clicked while also copying
 * the link to the clipboard (this functionality seems very hit-or-miss currently)
 */
export const CandidacyCreateGuestAssessmentInviteButton = ({
  candidacyId,
  candidacyInviteToken,
  hasAssessmentTemplate,
  searchId,
}) => {
  /**
   * If the user has the new assessment template feature enabled, use the new assessment url
   * instead.  The backend still sends the legacy url so we revert to that when the feature
   * is not enabled.
   */
  const url = hasAssessmentTemplate
    ? `${candidacyInviteToken?.get(
        'url',
      )}&search_id=${searchId}&candidacy_id=${candidacyId}&type=${TYPE_CLIENT}`
    : candidacyInviteToken?.get('url');
  const isFetching =
    candidacyInviteToken && candidacyInviteToken.getIn(['_meta', 'isFetching']);
  const error =
    candidacyInviteToken && candidacyInviteToken.getIn(['_meta', 'error']);

  return (
    <div className='CandidacyCreateGuestAssessmentInviteButton'>
      <ClipboardModalButton
        buttonClassName='btn btn-link'
        buttonLabel='Copy Link & Close'
        inputValue={
          url ||
          (isFetching && 'Loading...') ||
          error ||
          'Error creating invite token!'
        }
        text={url}
        title='Guest Assessment Invite Link'
        tooltip='Generate Quick Assessment Link'
      >
        <i className='btn-tooltip' data-icon='U' />
      </ClipboardModalButton>
    </div>
  );
};

CandidacyCreateGuestAssessmentInviteButton.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  /**
   * The invite token state object.
   */
  candidacyInviteToken: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
      error: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  hasAssessmentTemplate: PropTypes.bool,
  searchId: PropTypes.number.isRequired,
};

export default compose(
  setDisplayName('CandidacyCreateGuestAssessmentInviteButton(enhanced)'),

  // The connected version of this component only requires the candidacyId
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),

  // Look up the current token and bind the assessment actions
  // so we can dispatch the create action if necessary.
  connect(
    (state, { candidacyId }) => ({
      candidacyInviteToken: getCandidacyInviteToken(state, candidacyId),
      shouldCreateCandidacyInviteToken: getShouldCreateCandidacyInviteToken(
        state,
        candidacyId,
      ),
    }),
    dispatch => ({
      assessmentActions: bindActionCreators(actions, dispatch),
    }),
  ),
  withFeatureCheck('feature.assessment_templates', 'hasAssessmentTemplate'),

  // When we mount or change props, make sure we have a token available for
  // the current candidacy.
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const {
        assessmentActions,
        candidacyId,
        shouldCreateCandidacyInviteToken,
      } = this.props;
      if (shouldCreateCandidacyInviteToken) {
        assessmentActions.createCandidacyInviteToken({
          candidacyId: candidacyId,
        });
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const {
        assessmentActions,
        candidacyId,
        shouldCreateCandidacyInviteToken,
      } = nextProps;
      if (shouldCreateCandidacyInviteToken) {
        assessmentActions.createCandidacyInviteToken({
          candidacyId: candidacyId,
        });
      }
    },
  }),
)(CandidacyCreateGuestAssessmentInviteButton);

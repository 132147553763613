import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withPropsOnChange,
} from 'recompose';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';
import Option from './Option';

/**
 * Renders the options list for the MultiSelect using virtual rendering (only rendering
 * visible items to the DOM, instead of rendering all items at once)
 */
const VirtualOptionList = ({
  cellMeasurerCache,
  focusedIndex,
  rowCount,
  rowRenderer,
}) => (
  <div className='multi-select-option-list multi-select-option-list--virtualized'>
    <AutoSizer>
      {({ height, width }) => (
        <List
          deferredMeasurementCache={cellMeasurerCache}
          height={height}
          rowCount={rowCount}
          rowHeight={cellMeasurerCache.rowHeight}
          rowRenderer={rowRenderer}
          scrollToIndex={focusedIndex}
          width={width}
        />
      )}
    </AutoSizer>
  </div>
);

VirtualOptionList.propTypes = {
  /**
   * The CellMeasurerCache instance used to measure the size of the rows.
   */
  cellMeasurerCache: PropTypes.shape({
    rowHeight: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * The index of the item that currently has focus.
   */
  focusedIndex: PropTypes.number,

  /**
   * The total number of options/rows.
   */
  rowCount: PropTypes.number.isRequired,

  /**
   * The function called to render a single row.
   */
  rowRenderer: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('VirtualOptionList(enhanced)'),
  setPropTypes({
    /**
     * The ID of the currently focused option.
     */
    focusedId: PropTypes.string,

    /**
     * A function that returns the ID for a single option value.
     */
    idFromOption: PropTypes.func.isRequired,

    /**
     * Called when an option should be focused. Called with the option ID as the first parameter.
     */
    onFocus: PropTypes.func.isRequired,

    /**
     * Called when an option's selected value is changed. Called with the option ID as the first
     * parameter.
     */
    onSelect: PropTypes.func.isRequired,

    /**
     * The list of options to display.
     */
    options: PropTypes.array, // eslint-disable-line react/forbid-prop-types

    /**
     * A function that renders the label for an option.
     */
    renderOptionLabel: PropTypes.func.isRequired,

    /**
     * The currently selected values
     */
    value: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  }),

  // Generate an array of IDs to match the options, so we don't have to ccall idFromOptions
  // many, many times.
  withPropsOnChange(
    ['idFromOption', 'options'],
    ({ idFromOption, options }) => ({
      optionIds: options && options.map(option => idFromOption(option)),
    }),
  ),

  withPropsOnChange(['focusedId', 'optionIds'], ({ focusedId, optionIds }) => ({
    focusedIndex: optionIds && optionIds.indexOf(focusedId),
  })),

  // Do the same for the selected values.
  withPropsOnChange(['idFromOption', 'value'], ({ idFromOption, value }) => ({
    selectedOptionIds: value.map(option => idFromOption(option)),
  })),

  withPropsOnChange(['options'], ({ options }) => ({
    rowCount: options ? options.length : 0,
    cellMeasurerCache: new CellMeasurerCache({
      fixedWidth: true,
    }),
  })),

  withHandlers({
    handleOptionChange: ({ onSelect }) => event => {
      onSelect(event.currentTarget.value);
    },
    handleOptionMouseOver: ({ onFocus }) => (event, optionProps) => {
      onFocus(optionProps.value);
    },
  }),

  withHandlers({
    rowRenderer: ({
      cellMeasurerCache,
      focusedId,
      handleOptionChange,
      handleOptionMouseOver,
      optionIds,
      options,
      renderOptionLabel,
      selectedOptionIds,
    }) => row => {
      const option = options[row.index];
      const id = optionIds[row.index];
      return (
        <CellMeasurer
          cache={cellMeasurerCache}
          columnIndex={0}
          key={row.key}
          parent={row.parent}
          rowIndex={row.index}
        >
          <div style={row.style}>
            <Option
              isFocused={focusedId === id}
              isSelected={selectedOptionIds.indexOf(id) !== -1}
              key={id}
              label={renderOptionLabel(option, row.index)}
              onChange={handleOptionChange}
              onFocus={handleOptionMouseOver}
              onMouseOver={handleOptionMouseOver}
              value={id}
            />
          </div>
        </CellMeasurer>
      );
    },
  }),
)(VirtualOptionList);

import { createSelector } from '@reduxjs/toolkit';
import {
  CONTACT_SELECT,
  CONTACT_REVIEW_SELECT,
} from '../../actions/contacts/constants';

const getSelected = state => state.contacts.get('selectedContact');
const getContactsById = state => state.contacts.get('contactsById');
const getContactReviewsById = state => state.contactReviews;

const selectedContact = createSelector(
  [getSelected, getContactsById, getContactReviewsById],
  (selected, contactsById, contactReviewsById) => {
    switch (selected && selected.get('type')) {
      case CONTACT_SELECT:
        return contactsById.get(selected.get('id'));
      case CONTACT_REVIEW_SELECT:
        return contactReviewsById.getIn([
          'data',
          selected.get('id'),
          'data',
          'applied',
        ]);
      default:
        return null;
    }
  },
);

export default selectedContact;

import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import DateTime from 'modules/datetime/components/DateTime';
import React from 'react';
import PropTypes from 'prop-types';
import CommaSeparatedList from '@thrivetrm/ui/components/CommaSeparatedList';
import Notification from './Notification';

const Appointment = ({ notification }) => {
  return (
    <Notification notification={notification}>
      <div className='AppointmentView'>
        {notification?.resource?.subject && (
          <div className='AppointmentView__Subject'>
            <strong>Event Title:</strong> {notification?.resource?.subject}
          </div>
        )}
        {notification?.resource?.location && (
          <div className='AppointmentView__Location'>
            <strong>Location:</strong> {notification?.resource?.subject}
          </div>
        )}
        {notification?.resource?.start_time ||
        notification?.params.start_time ? (
          <div className='AppointmentView__StartTime'>
            <strong>When:</strong>{' '}
            <DateTime
              format='L LT'
              value={
                notification?.resource?.start_time ||
                notification?.params.start_time
              }
            />
          </div>
        ) : null}
        {notification?.resource?.attendees.length > 0 ? (
          <div>
            <strong>Attendees:</strong>{' '}
            <CommaSeparatedList
              items={notification?.resource?.attendees.map(
                attendee => attendee?.full_name,
              )}
            />
          </div>
        ) : null}
        {notification?.resource?.description ? (
          <ExpandableText
            characterLimit={150}
            content={notification?.resource?.description}
            isSanitizedHtml={true}
          />
        ) : null}
      </div>
    </Notification>
  );
};

Appointment.propTypes = {
  notification: PropTypes.shape({
    params: PropTypes.shape({ start_time: PropTypes.string }),
    resource: PropTypes.shape({
      attendees: PropTypes.arrayOf(
        PropTypes.shape({
          full_name: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      description: PropTypes.string,
      location: PropTypes.string,
      start_time: PropTypes.string,
      subject: PropTypes.string,
    }),
  }),
};

export default Appointment;

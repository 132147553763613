import PropTypes from 'prop-types';
import {
  componentFromProp,
  compose,
  mapProps,
  nest,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import SchemeEnsuredExternalLink from 'modules/links/SchemeEnsuredExternalLink';
import mapCompanyIdToProperty from './mapCompanyIdToProperty';

/**
 * Renders a link to a company's website, if available. if not, just renders a <span />.
 * This can be used to wrap a component in a link to the company:
 *
 * @example
 * ```js
 * <CompanyWebsiteLink companyId={123} >
 *   <CompanyName companyId={123} />
 * </CompanyWebsiteLink>
 * ```
 */
export default compose(
  setDisplayName('CompanyWebsiteLink'),
  setPropTypes({
    /**
     * The ID of the company whose website url should be used.
     */
    companyId: PropTypes.number.isRequired,

    /**
     * Determines if the external link icon is displayed.
     */
    indicator: PropTypes.oneOf([true, false, 'before', 'after']),
  }),
  mapCompanyIdToProperty('website_url', 'href'),
  mapProps(({ companyId: _, href, indicator, ...rest }) =>
    href
      ? {
          ...rest,

          // Only passthrough the indicator and href prop if we're actually rendering a link
          // to prevent it from being added to the span tag.
          indicator: indicator,
          href: href,
          component: nest(
            withProps({
              placement: 'top',
              tooltip: 'Visit Website',
            })(TooltipTrigger),
            SchemeEnsuredExternalLink,
          ),
        }
      : {
          ...rest,
          component: 'span',
        },
  ),
)(componentFromProp('component'));

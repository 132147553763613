import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@thrivetrm/ui/components/Icon';
import RadioButtonGroup from '@thrivetrm/ui/components/RadioButtonGroup';
import DuplicateMergeAttributeCell from './DuplicateMergeAttributeCell';
import { updateAttributeSelection } from '../duplicateMergeSlice';

const DuplicateMergeAttributeRow = ({ attribute, avatarShape, ...props }) => {
  const dispatch = useDispatch();

  const handleRecordSelection = selectedRecord => {
    dispatch(
      updateAttributeSelection({
        attributeName: attribute.name,
        selectedRecord: selectedRecord,
      }),
    );
  };

  const getSelectedRecordData = () =>
    attribute.selected_record
      ? attribute[`record_${attribute.selected_record}`]
      : null;

  const getCellClassNameForRecord = record => {
    return classnames(
      'DuplicateMergeAttributeRow__cell',
      `DuplicateMergeAttributeRow__cell--${record}`,
      {
        'DuplicateMergeAttributeRow__text--isDisabled': attribute.is_equal,
        'DuplicateMergeAttributeRow__text--isDiscarded':
          attribute.selected_record &&
          attribute.selected_record !== record &&
          !attribute.is_equal,
      },
    );
  };

  return (
    <tr {...props} className='DuplicateMergeAttributeRow' key={attribute.name}>
      <td className='DuplicateMergeAttributeRow__cell DuplicateMergeAttributeRow__cell--attribute'>
        {attribute.label}
      </td>
      <td className={getCellClassNameForRecord('a')}>
        <DuplicateMergeAttributeCell
          avatarShape={avatarShape}
          data={attribute.record_a}
          dataType={attribute.type}
        />
        {attribute.is_equal ? (
          <div className='DuplicateMergeAttributeRow__noSelectionNeeded'>
            <Icon color='blue' size='medium' type='task' />
          </div>
        ) : (
          <RadioButtonGroup
            className='DuplicateMergeAttributeRow__radio'
            isLabeled={false}
            name={attribute.name}
            onChange={record => handleRecordSelection(record)}
            options={['a', 'b']}
            value={attribute.selected_record || ''}
          />
        )}
      </td>
      <td className={getCellClassNameForRecord('b')}>
        <DuplicateMergeAttributeCell
          avatarShape={avatarShape}
          data={attribute.record_b}
          dataType={attribute.type}
        />
      </td>
      <td className='DuplicateMergeAttributeRow__cell DuplicateMergeAttributeRow__cell--finalResult'>
        <DuplicateMergeAttributeCell
          avatarShape={avatarShape}
          data={getSelectedRecordData()}
          dataType={attribute.type}
          isSelectionRequired={!attribute.selected_record}
        />
      </td>
    </tr>
  );
};

DuplicateMergeAttributeRow.defaultProps = {
  attribute: null,
};

DuplicateMergeAttributeRow.propTypes = {
  attribute: PropTypes.shape({
    is_equal: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    /* eslint-disable react/forbid-prop-types */
    record_a: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]).isRequired,
    record_b: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]).isRequired,
    selected_record: PropTypes.string,
    type: PropTypes.string.isRequired,
  }),
  avatarShape: PropTypes.string.isRequired,
};

export default DuplicateMergeAttributeRow;

import React from 'react';
import PropTypes from 'prop-types';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import Card from '@thrivetrm/ui/components/Card';
import classnames from 'classnames';
import { employeePropType } from 'services/apiV1/reportingRelationships';

const ContactReportingCardsDepthTwo = ({ employeeTreeDepthOfTwo }) => {
  return employeeTreeDepthOfTwo.map((employeeDepthTwo, index) => (
    <Card
      className={classnames('u-backgroundColor-gray5', {
        'u-marginBottom-8': index + 1 !== employeeTreeDepthOfTwo.length,
      })}
      isCentered={false}
      key={employeeDepthTwo.employeeName}
    >
      <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c'>
        <ContactSnapshot
          avatarUrl={employeeDepthTwo.avatarUrl}
          company={{ name: employeeDepthTwo.employeeCompany }}
          name={employeeDepthTwo.employeeName}
          title={employeeDepthTwo.employeePosition}
          url={`/contacts/${employeeDepthTwo.employeeId}`}
        />
      </div>
    </Card>
  ));
};

export default ContactReportingCardsDepthTwo;

ContactReportingCardsDepthTwo.propTypes = {
  employeeTreeDepthOfTwo: PropTypes.arrayOf(employeePropType).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import Currency from '@thrivetrm/ui/components/Currency';
import FieldState from 'modules/forms/FieldState';

const BonusFieldHelper = ({ fieldState }) => {
  const percentageOfBonus = fieldState
    .getNestedField('percentage_bonus')
    .getValue();
  const base = fieldState.getNestedField('base').getValue().replace(/,/g, '');
  const currency = fieldState.getNestedField('currency').getValue();

  const bonusHelper =
    base && percentageOfBonus ? (
      <>
        {percentageOfBonus}% of base salary ={' '}
        <strong>
          <Currency
            currency={currency}
            value={Math.round((Number(base) * Number(percentageOfBonus)) / 100)}
          />{' '}
          bonus
        </strong>
      </>
    ) : (
      <>
        {percentageOfBonus}% of base salary <strong>(base not set)</strong>
      </>
    );

  return (
    <div className='BonusFieldHelper' data-testid='bonus-helper'>
      {bonusHelper}
    </div>
  );
};

BonusFieldHelper.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
};

export default BonusFieldHelper;

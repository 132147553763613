import { CONTACT_SELECT, CONTACT_REVIEW_SELECT } from './constants';

function selectContactWithType(id, type) {
  return {
    type: type,
    payload: {
      id: id,
    },
  };
}

export const selectContact = id => selectContactWithType(id, CONTACT_SELECT);
export const selectContactReview = id =>
  selectContactWithType(id, CONTACT_REVIEW_SELECT);

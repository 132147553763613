import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const STRENGTHS = ['low', 'medium', 'high'];

const StrengthIndicator = ({ className, strength, ...props }) => {
  const containerClass = classnames(
    'StrengthIndicator',
    {
      [`StrengthIndicator--${strength}`]: STRENGTHS.includes(strength),
    },
    className,
  );
  return (
    <div {...props} className={containerClass}>
      <div className='StrengthIndicator__bar' />
      <div className='StrengthIndicator__bar' />
      <div className='StrengthIndicator__bar' />
    </div>
  );
};

StrengthIndicator.defaultProps = {
  className: null,
  strength: null,
};

StrengthIndicator.propTypes = {
  /** class name to apply to the root node */
  className: PropTypes.string,
  /** The strength to render (how many bars to fill). If no strength is provided, all bars will render empty. */
  strength: PropTypes.oneOf(STRENGTHS),
};

export default StrengthIndicator;

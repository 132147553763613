import React from 'react';
import PropTypes from 'prop-types';
import EntityPropertyList from 'modules/entities/components/EntityPropertyList';
import companyInvestmentStageSchema from '../schemas/companyInvestmentStages';

/**
 * Renders a list of the company investment stage names from their IDs, in a comma delimited list.
 */
const CompanyInvestmentStageNames = ({ ids }) => (
  <EntityPropertyList
    entityIds={ids}
    entityType={companyInvestmentStageSchema.key}
    propertyName='name'
  />
);

CompanyInvestmentStageNames.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

export default CompanyInvestmentStageNames;

import { compose } from 'recompose';
import routes from 'modules/routing/routes';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import {
  EMAIL_TEMPLATES_FETCH_START,
  EMAIL_TEMPLATES_FETCH_SUCCESS,
  EMAIL_TEMPLATES_FETCH_FAILURE,
} from './actionTypes';
import emailTemplateSchema from '../schema';

/**
 * Creates an action for fetching a list of email templates
 */
export default compose(
  fetchAction,
  normalizeResponse({ email_templates: [emailTemplateSchema] }),
  createAsyncActions({
    startActionType: EMAIL_TEMPLATES_FETCH_START,
    successActionType: EMAIL_TEMPLATES_FETCH_SUCCESS,
    failureActionType: EMAIL_TEMPLATES_FETCH_FAILURE,
  }),
  withOptions({
    url: routes.api_v1_email_templates(),
  }),
);

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';
import SeekingJobReasonName from 'modules/tenant/components/SeekingJobReasonName';
import PositionFunctionName from 'modules/tenant/components/PositionFunctionName';

/**
 * Renders a contact's job opportunity status related fields.
 */
const ContactJobOpportunityStatusView = ({ contact }) => {
  const hasAvailableTalentFunction = useFeatureCheck(
    'field.contact.available_talent_function',
  );
  return (
    <div className='ContactJobOpportunityStatusView row'>
      {contact.get('seeking_job_status') && (
        <div className='col-sm-6'>
          <LabeledContent
            className='ContactJobOpportunityStatusView__seeking_job_status'
            label='Availability'
          >
            {`${
              contact.get('seeking_job_status') === 'active'
                ? 'Actively'
                : 'Passively'
            } Looking`}
          </LabeledContent>
        </div>
      )}
      {contact.get('seeking_job_reason') && (
        <div className='col-sm-6'>
          <LabeledContent
            className='ContactJobOpportunityStatusView__seeking_job_reason'
            label='Reason'
          >
            <SeekingJobReasonName id={contact.get('seeking_job_reason')} />
          </LabeledContent>
        </div>
      )}
      {contact.get('available_talent_function') &&
      hasAvailableTalentFunction ? (
        <div className='col-sm-6'>
          <LabeledContent label='Available Talent Function'>
            <PositionFunctionName
              id={contact.get('available_talent_function')}
            />
          </LabeledContent>
        </div>
      ) : null}
    </div>
  );
};

ContactJobOpportunityStatusView.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    seeking_job_status: PropTypes.string,
    seeking_job_reason: PropTypes.number,
  }).isRequired,
};

/**
 * Determines if the component will render anything when given the contact specified.
 * @param {Object} options
 * @param {Map} contact The contact record.
 */
ContactJobOpportunityStatusView.willRender = ({ contact }) =>
  Boolean(
    contact &&
      (contact.get('seeking_job_status') ||
        typeof contact.get('seeking_job_reason') === 'number' ||
        typeof contact.get('available_talent_function') === 'number'),
  );

export default compose(
  setDisplayName('ContactJobOpportunityStatusView(enhanced)'),
  setStatic('willRender', ContactJobOpportunityStatusView.willRender),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  branch(
    ({ contact }) =>
      !ContactJobOpportunityStatusView.willRender({ contact: contact }),
    renderNothing,
  ),
)(ContactJobOpportunityStatusView);

import { convertToCamelCase } from 'modules/core/jsonUtils';
import { markAsStale } from 'modules/recordIndex/recordIndexSlice';
import invalidateCandidacy from 'modules/candidacies/actions/invalidateCandidacy';
import invalidateContactsList from 'modules/contacts/actions/contacts/invalidateContactsList';
import { stringifyQueryObject } from '@thrivetrm/ui/utilities/urlUtils';
import apiV1 from './index';
import queryTags from './queryTags';

const { CONTACT, INDEX_TABLE_RECORDS, REFERENCES } = queryTags;

const contact = apiV1.injectEndpoints({
  endpoints: builder => ({
    getContact: builder.query({
      query: contactId => ({
        url: `/contacts/${contactId}`,
        method: 'GET',
      }),
      transformResponse: response => convertToCamelCase(response?.contact),
      providesTags: [CONTACT],
    }),
    updateContact: builder.mutation({
      query: ({ contactId, payload }) => ({
        url: `/contacts/${contactId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [CONTACT, INDEX_TABLE_RECORDS, REFERENCES],
      transformResponse: response => convertToCamelCase(response?.contact),
      onQueryStarted: async function (
        { candidacyId, contactListId },
        { dispatch, queryFulfilled },
      ) {
        try {
          await queryFulfilled;
          // changes via record index page
          dispatch(markAsStale());
          if (candidacyId) {
            // changes via candidate portal
            dispatch(invalidateCandidacy({ id: candidacyId }));
          }
          if (contactListId) {
            // changes via company profile page
            dispatch(invalidateContactsList({ listId: contactListId }));
          }
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    createContact: builder.mutation({
      query: ({ payload }) => ({
        url: `/contacts`,
        method: 'POST',
        body: payload,
      }),
    }),
    searchContact: builder.query({
      query: params => ({
        url: `/autocomplete/query`,
        method: 'GET',
        params: stringifyQueryObject(params),
      }),
      providesTags: [CONTACT],
      transformResponse: response => convertToCamelCase(response),
      /* added onQueryStarted because, when we add name or email it returns duplicate contacts , 
      but on removing name or email, data was still displaying from cache entry. 
      when there is no query term, API returns 15 records by default on empty query term. 
      so we have manually replaced cache entry */
      onQueryStarted: async function (params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // only updates cache entry to null if the query param is not entered
          if (params?.query.term === '' || params?.query.term === null) {
            dispatch(
              apiV1.util.updateQueryData('searchContact', params, () => null),
            );
          }
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
});

export const {
  useCreateContactMutation,
  useGetContactQuery,
  useLazySearchContactQuery,
  useSearchContactQuery,
  useUpdateContactMutation,
} = contact;

import routes from 'modules/routing/routes';
import {
  CONTACT_USER_CREATE_START,
  CONTACT_USER_CREATE_SUCCESS,
  CONTACT_USER_CREATE_FAILURE,
} from './constants';
import createAjaxAction from '../createAjaxAction';
import reduceFieldErrors from './reduceFieldErrors';

export const getUrl = ({ contactId }) => {
  if (!contactId) {
    throw new Error('createUser requires a `contactId`');
  }

  return routes.api_v1_contact_users({ contactId: contactId });
};

export const getRequestOptions = ({ user }) => ({
  method: 'POST',
  body: JSON.stringify({ user: user }),
});

const createUser = createAjaxAction({
  getUrl: getUrl,
  getRequestOptions: getRequestOptions,
  start: CONTACT_USER_CREATE_START,
  success: CONTACT_USER_CREATE_SUCCESS,
  failure: CONTACT_USER_CREATE_FAILURE,
  createErrorPayload: reduceFieldErrors,
});

export default createUser;

import { connect } from 'react-redux';
import getAssessment from '../selectors/getAssessment';

/**
 * A higher order component which maps a list of `assessmentIds` to
 * their assessment records.
 */
export default connect(
  (state, { assessmentIds }) => ({
    assessments:
      assessmentIds && assessmentIds.map(id => getAssessment(state, id)),
  }),
  {},
);

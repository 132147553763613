import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import sorters from '../sorters';

const identity = i => i;

export default compose(
  connect((state, { contactIds, sortBy }) => {
    if (!contactIds || contactIds.size < 1) {
      return {};
    }

    const createSorter = sorters[sortBy];

    return {
      contactIds: contactIds.sortBy(
        createSorter ? createSorter(state) : identity,
      ),
    };
  }, {}),
  withProps(({ contactIds, sortAscending }) => ({
    contactIds:
      !contactIds || sortAscending ? contactIds : contactIds.reverse(),
  })),
);

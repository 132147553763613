import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  withHandlers,
} from 'recompose';
import fetchCustomerConfigurationActionCreator from '../actions/fetchCustomer';
import shouldFetchCustomerConfigurationSelector from '../selectors/shouldFetchCustomer';
import getCustomerConfigurationSelector from '../selectors/getCustomer';
import isCustomerConfigurationFetchedSelector from '../selectors/isCustomerFetched';

/**
 * Ensures that the current tenant's data for the customer detail modal has been loaded.
 * @param {Object} options
 */
export default () =>
  compose(
    setDisplayName('withCustomerFetched'),
    mapProps(incomingProps => ({
      withCustomerFetchedProps: incomingProps,
    })),

    connect(
      state => ({
        shouldFetchCustomerConfiguration: shouldFetchCustomerConfigurationSelector(
          state,
        ),
        customerConfiguration: getCustomerConfigurationSelector(state),
        isCustomerConfigurationFetched: isCustomerConfigurationFetchedSelector(
          state,
        ),
      }),
      {
        fetchCustomerConfiguration: fetchCustomerConfigurationActionCreator,
      },
    ),

    withHandlers({
      fetchCustomerConfigurationIfNeeded: ({
        fetchCustomerConfiguration,
        shouldFetchCustomerConfiguration,
      }) => () =>
        shouldFetchCustomerConfiguration && fetchCustomerConfiguration(),
    }),

    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchCustomerConfigurationIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchCustomerConfigurationIfNeeded();
      },
    }),
    mapProps(
      ({
        customerConfiguration,
        isCustomerConfigurationFetched,
        shouldFetchCustomerConfiguration,
        withCustomerFetchedProps,
      }) => ({
        ...withCustomerFetchedProps,
        customerConfiguration: customerConfiguration,
        hasCustomerConfiguration:
          !shouldFetchCustomerConfiguration && isCustomerConfigurationFetched,
      }),
    ),
  );

import { connect } from 'react-redux';
import getCandidacyRejectionReason from '../selectors/getCandidacyRejectionReason';

/**
 * A higher order component which maps a `candidacyId` prop value to it's
 * rejection reason value (if any) as a `rejectionReason` prop.
 */
export default connect(
  (state, { candidacyId }) => ({
    rejectionReason: getCandidacyRejectionReason(state, candidacyId),
  }),
  {},
);

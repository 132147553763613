import React from 'react';
import Card from '@thrivetrm/ui/components/Card';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import { employeePropType } from 'services/apiV1/reportingRelationships';

const ContactReportingReportsTo = ({ employee }) => {
  return (
    <Card className='u-marginBottom-8' isCentered={false}>
      <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c'>
        <ContactSnapshot
          avatarUrl={employee.avatarUrl}
          company={{ name: employee.employeeCompany }}
          name={
            employee.disabled
              ? `${employee.employeeName} (deactivated)`
              : employee.employeeName
          }
          title={employee.employeePosition}
          url={
            employee.recordType === 'Contact'
              ? `/contacts/${employee.employeeId}`
              : null
          }
        />
      </div>
    </Card>
  );
};

export default ContactReportingReportsTo;

ContactReportingReportsTo.propTypes = {
  employee: employeePropType.isRequired,
};

import { CONTACTS_LIST_INVALIDATE } from './actionTypes';

/**
 * Dispatches an action to flag a contacts list collection as invalid.
 * @param {Object} options
 * @param {String} options.listId The ID of the list to invalidate.
 */
export default ({ listId }) => ({
  type: CONTACTS_LIST_INVALIDATE,
  payload: { listId: listId },
});

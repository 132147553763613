import React from 'react';
import PropTypes from 'prop-types';

/**
 * A utility function to convert a hexadecimal color to rgb string
 */
const convertHexToRGBColor = h => {
  let r = 0;
  let g = 0;
  let b = 0;

  r = `0x${h[1]}${h[2]}`;
  g = `0x${h[3]}${h[4]}`;
  b = `0x${h[5]}${h[6]}`;

  return `${Number(r)},${Number(g)},${Number(b)}`;
};

const CandidacyStageTag = ({ className, color, name }) => {
  /* The stage color is chosen by users and as such we need to be careful 
    not to render text on such a dark background that it is unreadable.  
    */
  const fadedStageColor = `rgba(${convertHexToRGBColor(color)}, 0.6)`;

  return (
    <span
      className={`u-textColor-gray60 u-noWrap ${className}`}
      style={{
        border: `1px solid ${color}`,
        display: 'inline-block',
        backgroundColor: fadedStageColor,
        borderRadius: '4px',
      }}
    >
      {name}
    </span>
  );
};

CandidacyStageTag.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
};

export default CandidacyStageTag;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApiGet, useApiPost } from 'modules/core/hooks/useApi';
import Modal from '@thrivetrm/ui/components/Modal';
import Form from '@thrivetrm/ui/components/Form';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import Card from '@thrivetrm/ui/components/Card';
import { isValidEmail } from 'modules/core/validators';

const ContactCustomReportModal = ({
  contactId,
  initialRecipients,
  onClose,
}) => {
  const [
    loadTemplates,
    isLoadingTemplates,
    loadTemplatesError,
    templates,
  ] = useApiGet(`/api/v1/contacts/${contactId}/custom_report_templates`);

  const [reportRecipients, setReportRecipients] = useState(initialRecipients);
  const [hasSubmittedReportRequest, setHasSubmittedReportRequest] = useState(
    false,
  );

  const [createReport, isCreatingReport, createReportError] = useApiPost(
    `/api/v1/contacts/${contactId}/custom_report`,
  );

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  const handleSubmit = formData => {
    setReportRecipients(formData.recipients);
    // The js-report microservice expects the payload to have this exact shape
    // All keys are required.
    createReport(
      {
        emails: formData.recipients,
        report_template_id: formData.reportTemplateId,
      },
      { onSuccess: () => setHasSubmittedReportRequest(true) },
    );
  };

  return (
    <Modal
      className='ContactCustomReportModal'
      isOpen={true}
      onClose={onClose}
      size='small'
      title='Generate Custom Report'
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Body className='ContactCustomReportModal__body'>
          <LoadingContainer isLoading={isLoadingTemplates} minHeight={512}>
            {templates?.length && !hasSubmittedReportRequest ? (
              <>
                <h1 className='u-margin-n'>Report Options</h1>
                <Form.SelectMenu
                  className='u-marginTop-16'
                  initialValue={templates[0]?.id}
                  inputWidth='full'
                  label='Type'
                  name='reportTemplateId'
                  placeholder='Select a type...'
                  rules={{
                    required: { value: true, message: 'A type is required' },
                  }}
                  size='small'
                >
                  {templates.map(type => (
                    <SelectMenu.Item key={type.id} value={type.id}>
                      {type.name}
                    </SelectMenu.Item>
                  ))}
                </Form.SelectMenu>
                <h1>Recipients</h1>
                <Form.EmailRecipientsInput
                  className='u-marginTop-16'
                  initialValue={initialRecipients}
                  label='Send To (Email Addresses)'
                  name='recipients'
                  rules={{
                    required: {
                      value: true,
                      message: 'An email address is required',
                    },
                    validate: values => {
                      const invalidEmails = values.filter(
                        value => !isValidEmail(value),
                      );
                      return invalidEmails.length
                        ? `Invalid emails: ${invalidEmails.join(', ')}`
                        : null;
                    },
                  }}
                />
                {createReportError ? (
                  <Card
                    className='u-marginVertical-16 u-textAlign-c'
                    data-testid='custom-report-error-message'
                    isCentered={false}
                    type='error'
                  >
                    <h4>
                      We are having trouble generating your custom report.
                      Please try again.{' '}
                    </h4>
                    <p>
                      If you continue to see this error, please reach out to{' '}
                      <a
                        href='mailto:support@thrivetrm.com'
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        support@thrivetrm.com
                      </a>
                    </p>
                    <p className='u-textAlign-c'>{createReportError}</p>
                  </Card>
                ) : null}
              </>
            ) : null}

            {!loadTemplatesError && templates?.length === 0 ? (
              <div
                className='u-margin-64 u-textAlign-c'
                data-testid='custom-report-error-message'
              >
                <h4>We are unable to find any templates for custom reports.</h4>
                <p className='u-fontSize-small'>
                  If you continue to see this error, please reach out to{' '}
                  <a
                    href='mailto:support@thrivetrm.com'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    support@thrivetrm.com
                  </a>
                </p>
              </div>
            ) : null}

            {loadTemplatesError ? (
              <div
                className='u-margin-64 u-textAlign-c'
                data-testid='custom-report-error-message'
              >
                <h4>
                  We are having trouble loading your custom report templates.
                  Please try again.
                </h4>
                <p className='u-fontSize-small'>
                  If you continue to see this error, please reach out to{' '}
                  <a
                    href='mailto:support@thrivetrm.com'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    support@thrivetrm.com
                  </a>
                </p>
              </div>
            ) : null}
            {hasSubmittedReportRequest ? (
              <div
                className='u-margin-64 u-textAlign-c'
                data-testid='custom-report-success-message'
              >
                <h4>We‘re on it.</h4>
                <p>
                  We will generate your report and send it to{' '}
                  {reportRecipients.join(', ')}
                </p>
              </div>
            ) : null}
          </LoadingContainer>
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary
            isOutline={!hasSubmittedReportRequest}
            label={hasSubmittedReportRequest ? 'Finish' : 'Cancel'}
            onClick={onClose}
          />
          {hasSubmittedReportRequest ? null : (
            <Form.SubmitButton
              isLoading={isCreatingReport}
              label='Create Report'
            />
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ContactCustomReportModal.propTypes = {
  contactId: PropTypes.number.isRequired,
  initialRecipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ContactCustomReportModal;

import { contactSchema } from 'modules/contacts/schema';
import { searchSchema } from 'modules/searches/schema';

/**
 * A talent pool is just a specific type of search, so it uses the same schema as a search
 */

// This is defined here because the circular dependency between the talentPoolSchema
// and the contactSchema is causing an issue when we try to add this in the
// `modules/contacts/schema.js` file.
contactSchema.define({ talent_pool: searchSchema });

export default searchSchema;

const notify = require('../lib/notify').default;
const initSelectsInModal = require('../lib/modal_selects');

exports.initialize = () => {
  $('#target-companies').on(
    'ajax:before ajax:complete',
    '.js-add-company,.js-remove-company',
    status => {
      const icon = $(this).hasClass('js-add-company') ? 'plus' : 'minus';
      $(this).find('.fa').toggleClass(`fa-${icon} fa-circle-o-notch fa-spin`);
      return $(this).attr('disabled', status == null);
    },
  );

  $('#target-companies').on(
    'ajax:success',
    '.js-add-company, .js-remove-company',
    function targetCompanyById() {
      const id = $(this).data('company-id');
      return $(`[data-company-id=${id}]`).toggle();
    },
  );

  $('#target-companies').on(
    'ajax:error',
    '.js-add-company,.js-remove-company',
    () => notify('There was a problem sending your request', 'error'),
  );

  return initSelectsInModal('.advanced-search-modal');
};

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { stringToCamelCase } from 'modules/core/jsonUtils';
import {
  selectFilterInputData,
  FilterInputTypes,
} from 'modules/recordIndex/recordIndexSlice';
import Integer from '@thrivetrm/ui/components/Integer';
import Percentage from '@thrivetrm/ui/components/Percentage';

const FilterInputAsString = ({ name, type }) => {
  const normalizedName = stringToCamelCase(name);

  const inputValue = useSelector(
    state => state.recordIndex.savedView.filters?.[normalizedName],
  );

  const { isPairedInput, label, leadsPair, options } = useSelector(state =>
    selectFilterInputData(state, name),
  );

  const normalizedOptions =
    options?.map(option => {
      if (option.value) {
        return option;
      } else if (option?.id) {
        return {
          label: option.name,
          value: option.id,
        };
      } else if (Array.isArray(option)) {
        const [optionLabel, optionValue] = option;
        return {
          label: optionLabel,
          value: optionValue,
        };
      } else {
        return {
          label: option,
          value: option,
        };
      }
    }) || [];

  const getMultiSelectValueItemString = itemVal =>
    normalizedOptions.find(option => `${option.value}` === `${itemVal}`)?.label;

  const valueItemClass = classnames('FilterInputAsString__valueItem', {
    'FilterInputAsString__valueItem--AND': inputValue?.operator === 'AND',
    'FilterInputAsString__valueItem--OR': inputValue?.operator === 'OR',
  });

  const getDisplayValue = () => {
    switch (type) {
      case 'text_area':
      case 'text':
      case 'date':
        return inputValue;
      case 'percent':
        return Number.isFinite(inputValue) ? (
          <Percentage value={inputValue} />
        ) : null;
      case 'number':
        return Number.isInteger(inputValue) ? (
          <Integer value={inputValue} />
        ) : null;
      case 'checkbox':
      case 'radio':
        /** The current choice by the business is to not include the checkbox filters in the summary */
        return null;
      case 'single_value_select':
        return inputValue;
      case FilterInputTypes.MINIMUM_STAGED_REACHED:
        return normalizedOptions.find(
          option => `${option.value}` === `${inputValue}`,
        )?.label;
      case 'multi_value_number':
        return inputValue?.length ? (
          <span className={valueItemClass}>
            {(Array.isArray(inputValue) ? inputValue : [inputValue]).join(', ')}
          </span>
        ) : null;
      case 'multi_autocomplete':
      case 'multi_value_select':
        return inputValue?.length ? (
          <span className={valueItemClass}>
            {inputValue
              .map(inputItem => getMultiSelectValueItemString(inputItem))
              .join(', ')}
          </span>
        ) : null;
      case 'multi_value_select_boolean':
        return inputValue?.values?.length ? (
          <span className={valueItemClass}>
            {inputValue.values
              .map(
                inputItem =>
                  normalizedOptions?.find(item => item.value === inputItem)
                    ?.label,
              )
              .join(', ')}
          </span>
        ) : null;
      case 'multi_value_text_boolean':
        return inputValue?.values?.length ? (
          <span className={valueItemClass}>{inputValue.values.join(', ')}</span>
        ) : null;
      default:
        throw new Error(
          `Unknown filter type "${type}" provided to FilterInputAsString "${name}"`,
        );
    }
  };
  const valueClass = classnames('FilterInputAsString__value', {
    'FilterInputAsString__value--isRangeMin': isPairedInput && leadsPair,
    'FilterInputAsString__value--isRangeMax': isPairedInput && !leadsPair,
  });

  return getDisplayValue() ? (
    <>
      {label ? (
        <span className='FilterInputAsString__label'>{label}: </span>
      ) : null}
      <span className={valueClass}>{getDisplayValue()}</span>
    </>
  ) : null;
};

FilterInputAsString.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'checkbox',
    'date',
    'multi_autocomplete',
    'multi_value_number',
    'multi_value_select_boolean',
    'multi_value_select',
    'multi_value_text_boolean',
    'number',
    'percent',
    'radio',
    'single_value_select',
    'text_area',
    'text',
  ]).isRequired,
};

export default FilterInputAsString;

import { branch, compose, mapProps } from 'recompose';
import FormState from '../../../forms/FormState';
import withContactFetched from '../withContactFetched';

export const emailIsNullOrEmptyString = contact => {
  const email = contact.get('email') || contact.get('work_email');
  return email === null || email === '';
};

export const updateFormStateForContact = (formState, contact) => {
  let newFormState = formState;
  if (emailIsNullOrEmptyString(contact)) {
    const message = 'Email address required to send an invitation';
    newFormState = new FormState(
      formState.getImmutable().merge({
        error: new Error(message),
      }),
    );
    // TODO: set this error on the part of the form allowing
    // Recruiters to select which Email to send the Invitation to
    const newFieldState = newFormState.getFieldState().setError(message);
    newFormState = newFormState.setFieldState(newFieldState);
  }

  return newFormState;
};

/**
 * Provides props for validating the presence of Personal Email on a form for creating an
 * invite.
 *
 * Wraps the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying CandidatePortalInviteInviteField FieldState
 */
export default compose(
  branch(props => props.contactId && !props.contact, withContactFetched),
  mapProps(({ contact, formState, ...rest }) => ({
    ...rest,
    formState: updateFormStateForContact(formState, contact),
  })),
);

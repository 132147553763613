import { normalize } from 'normalizr';
import userSchema from '../schema';
import { USER_INITIALIZE } from './actionTypes';

const initializeUser = ({ user }) => ({
  type: USER_INITIALIZE,
  payload: normalize(user, userSchema),
});

export default initializeUser;

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withCompanyRevenueListForm from './withCompanyRevenueListForm';
import CompanyRevenueListField from './CompanyRevenueListField';

/**
 * Renders a form for bulk editing a company's revenue records.
 */
const CompanyRevenueListForm = ({
  // props provided by `withCompanyRevenueListForm`
  formState,
  onCancel,
  onFieldStateChange,

  // Props passed through/expected from parent component.
  onSubmit,

  // Any other props are passed through to the CompanyRevenueListField component.
  ...fieldProps
}) => (
  <Form
    className='CompanyRevenueListForm'
    formState={formState}
    onSubmit={onSubmit}
  >
    <CompanyRevenueListField
      {...fieldProps}
      disabled={formState.isSubmitting()}
      fieldState={formState.getFieldState()}
      onChange={onFieldStateChange}
      showErrors={formState.wasSubmitted() || 'blurred'}
    />
    <FormErrorMessage formState={formState} />
    <div className='Form__footer'>
      <CancelButton onClick={onCancel} />
      <SubmitButton formState={formState} onClick={onSubmit} />
    </div>
  </Form>
);

CompanyRevenueListForm.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the modal should be closed.
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * The label for the submit button.
   */
  submitButtonLabel: PropTypes.node,
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('CompanyRevenueListForm(enhanced))'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
    ...withCompanyRevenueListForm.propTypes,
    onSaved: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }),

  withCompanyRevenueListForm,

  withHandlers({
    onCancel: ({ formState, onCancel }) => () => onCancel(formState),
  }),

  /**
   * Prevent props from being passed on to the modal
   */
  mapProps(
    ({
      /* eslint-disable no-unused-vars */
      onFormStateChange,
      onResetFormState,
      /* eslint-enable no-unused-vars */
      ...rest
    }) => rest,
  ),
)(CompanyRevenueListForm);

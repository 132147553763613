import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import {
  registerFilterInput,
  selectHasRegisteredFilterInput,
  setAndValidateSingleFilterValue,
  requestRecordList,
} from '../../recordIndexSlice';

const CheckboxFilter = ({ label, name }) => {
  const dispatch = useDispatch();

  const inputValue = useSelector(state =>
    Boolean(parseInt(state.recordIndex.savedView.filters?.[name])),
  );

  const handleChange = value => {
    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        // Convert to legacy checkbox filter syntax
        value: value === true ? '1' : '0',
      }),
    );
    return dispatch(requestRecordList());
  };

  const hasRegisteredFilterInput = useSelector(state =>
    selectHasRegisteredFilterInput(state, name),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  return (
    <Checkbox
      className='u-inlineBlock u-marginHorizontal-4 u-marginTop-4'
      isChecked={inputValue}
      label={label}
      name={name}
      onChange={handleChange}
      size='small'
    />
  );
};

CheckboxFilter.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default CheckboxFilter;

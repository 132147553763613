import React from 'react';
import { Route } from 'react-router-dom';
import {
  TYPE_JOB_SEARCH,
  TYPE_PIPELINE,
  TYPE_TALENT_POOL,
} from 'modules/searches/constants';
import SearchPageContent from './SearchPageContent';

const searchType = match => {
  if (match.params.searchType === 'talent_pools') {
    return TYPE_TALENT_POOL;
  } else if (match.params.searchType === 'pipelines') {
    return TYPE_PIPELINE;
  } else {
    return TYPE_JOB_SEARCH;
  }
};

export default () => (
  <Route
    path='/:searchType(searches|pipelines|talent_pools)/:searchId/:selectedTab?/:selectedTabId?/:activeSection?/(.*)?'
    render={({ match }) => (
      <SearchPageContent
        searchId={parseInt(match.params.searchId)}
        searchType={searchType(match)}
        selectedTab={match.params.selectedTab}
      />
    )}
  />
);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName } from 'recompose';

import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import ContactAvatar from 'modules/contacts/components/ContactAvatar';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import CandidaciesPerStageDonutChart from 'modules/searches/components/candidacyStats/CandidaciesPerStageDonutChart';

import CompanyName from './CompanyName';
import DashboardLink from './DashboardLink';
import IncumbentLink from './IncumbentLink';
import JobTitle from './JobTitle';
import ViewOrCreateTalentPoolLink from './ViewOrCreateTalentPoolLink';
import mapContactIdToTalentPoolId from './mapContactIdToTalentPoolId';

/**
 * Renders the "large" style contact/talent pool card which is shown when the "card" view
 * is selected.
 */
export const TalentPoolCardLarge = ({
  contact,
  contactId,
  isFetching,
  onCreateTalentPool,
  talentPoolId,
}) => {
  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className='talent-pools--talent-pool-card talent-pools--talent-pool-card-large'>
      <div
        className='talent-pools--talent-pool-card-section talent-pools--talent-pool-card-top'
        key='top'
      >
        <div className='talent-pools--talent-pool-card-header' key='header'>
          <div
            className='talent-pools--talent-pool-card-header-left'
            key='left'
          />
          <div
            className='talent-pools--talent-pool-card-header-center'
            key='center'
          >
            <ContactAvatar contactId={contactId} useLegacyStyles={true} />
          </div>
          <div
            className='talent-pools--talent-pool-card-header-right'
            key='right'
          >
            {contact && contact.get('direct_reports_count') > 0 && (
              <DashboardLink contactId={contactId}>
                <i className='fa fa-sitemap' />{' '}
                {contact.get('direct_reports_count')}
              </DashboardLink>
            )}
          </div>
        </div>
        <IncumbentLink contactId={contactId} />
        <JobTitle contactId={contactId} />
        <CompanyName contactId={contactId} />
      </div>
      <div
        className='talent-pools--talent-pool-card-section talent-pools--talent-pool-card-bottom'
        key='bottom'
      >
        <ViewOrCreateTalentPoolLink
          contactId={contactId}
          onCreateTalentPool={onCreateTalentPool}
        />
        {talentPoolId && (
          <CandidaciesPerStageDonutChart searchId={talentPoolId} />
        )}
      </div>
    </div>
  );
};

TalentPoolCardLarge.defaultProps = {
  isFetching: false,
};

TalentPoolCardLarge.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    direct_reports_count: PropTypes.number,
  }),

  contactId: PropTypes.number.isRequired,

  isFetching: PropTypes.bool,

  onCreateTalentPool: PropTypes.func.isRequired,

  talentPoolId: PropTypes.number,
};

export default compose(
  setDisplayName('connect(TalentPoolCardLarge)'),
  mapContactIdToTalentPoolId,
  mapContactIdToContact,
)(TalentPoolCardLarge);

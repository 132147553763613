import React, { useState, useEffect } from 'react';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useQuickView from 'modules/quick-view/useQuickView';
import { convertToCamelCase } from 'modules/core/jsonUtils';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import routes from 'modules/routing/routes';
import NotificationsFilterDropdown from 'components/notifications/NotificationsFilterDropdown';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { useSelector } from 'react-redux';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import {
  NOTIFICATIONS_FILTER_SECTIONS,
  NOTIFICATIONS_FILTERABLE_TYPES,
  NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_LEGACY_ASSESSMENTS,
  NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_SEARCH_ACTIVITY,
} from 'domains/notifications/constants';
import { QuickViewEmptyState, QuickViewErrorState } from '../QuickViewState';
import ContactQvpHeader from '../ContactQvpHeader';
import QuickViewPanelHeader from '../QuickViewPanelHeader';
import Activity from './Activity';

const OFFSET_LIMIT = 10;

const ActivitiesList = () => {
  const { pathParams, title } = useQuickView();
  const { contactId } = pathParams;
  const { navigateTo } = useQuickView();

  const hasAssessmentTemplates = useFeatureCheck(
    'feature.assessment_templates',
  );
  const isCrmUser = useSelector(isCrmUserSelector);

  const [activityList, setActivityList] = useState([]);
  // using this sequentialId to fetch next n no of data
  const [sequentialId, setSequentialId] = useState();

  const getNotificationsFilters = () => {
    if (isCrmUser) {
      return NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_SEARCH_ACTIVITY;
    } else if (hasAssessmentTemplates) {
      return NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_LEGACY_ASSESSMENTS;
    } else {
      return NOTIFICATIONS_FILTER_SECTIONS;
    }
  };

  const getFiltersValue = () => {
    const allFilterTypes = getNotificationsFilters()
      .map(filter => filter.types)
      .flat(1);
    // Used reduce method to convert data as mentioned below
    // From :- ['notes','comments']
    // To :- { notes: true, comments: true }
    const filterObj = allFilterTypes.reduce((filterKey, key) => {
      return { ...filterKey, [key]: true };
    }, {});

    const nonFilterableTypes = {
      add_candidate_to_search: true,
      assessments: true,
      remove_candidate_from_search: true,
    };
    return { ...filterObj, ...nonFilterableTypes };
  };
  const [filterValue, setFilterValue] = useState(getFiltersValue());

  const getTypesForFilter = () =>
    Object.keys(filterValue)
      .filter(key => filterValue[key])
      .map(key => NOTIFICATIONS_FILTERABLE_TYPES[key].filter)
      .flat(1);

  const [
    loadActivities,
    isLoadingActivities,
    activitiesLoadError,
    activities,
  ] = useApiGet(
    routes.api_notifications({
      query: {
        contact_id: contactId,
        limit: OFFSET_LIMIT,
        before: sequentialId,
        limited_visibility: true,
        types: getTypesForFilter(),
      },
    }),
  );

  useEffect(() => {
    loadActivities(
      {},
      {
        onSuccess: data =>
          setActivityList(
            getTypesForFilter().length
              ? [...activityList, ...convertToCamelCase(data.notifications)]
              : [],
          ),
      },
    );
  }, [contactId, sequentialId, filterValue]);

  return (
    <>
      <ContactQvpHeader
        contactId={contactId}
        onBack={() => navigateTo(`/contacts/${contactId}`)}
      />
      <QuickViewPanelHeader title='Activity Timeline' />
      <SidePanel.Body className='u-paddingTop-12'>
        <div className='u-flex u-flexAlign-c'>
          <p className='u-margin-n u-marginRight-8'>Filter:</p>
          <NotificationsFilterDropdown
            className='dropdown-block  ActivitiesList__filterDropdown'
            excludeSearchRelatedValues={isCrmUser}
            hasAssessmentTemplates={hasAssessmentTemplates}
            onChange={filters => {
              setActivityList([]);
              setSequentialId(null);
              setFilterValue(filters);
            }}
            placeholder='Select a Category...'
            value={filterValue}
          />
        </div>
        <LoadingContainer
          className='u-paddingTop-8'
          isLoading={isLoadingActivities}
          minHeight='100vh'
          size='large'
        >
          {!activityList.length &&
          !isLoadingActivities &&
          !activitiesLoadError ? (
            <QuickViewEmptyState
              heading={`${title} doesn’t have any Activity.`}
              image='avatarWithEnvelope'
            />
          ) : null}
          {activitiesLoadError && !isLoadingActivities ? (
            <QuickViewErrorState />
          ) : null}
          {activityList?.map(({ key, ...activity }) => (
            <Activity key={activity.id} notificationKey={key} {...activity} />
          ))}
        </LoadingContainer>
      </SidePanel.Body>
      {activityList.length && activityList.length < activities?.meta.count ? (
        <SidePanel.Footer className='u-flexJustify-c u-border-n'>
          <ButtonSecondary
            className='ActivitiesList__loadMore'
            isLoading={isLoadingActivities}
            label='Load More'
            onClick={() =>
              // fetching next n no data using last activity's sequential_id
              setSequentialId(
                activityList[activityList.length - 1].sequentialId,
              )
            }
            size='small'
          />
        </SidePanel.Footer>
      ) : null}
    </>
  );
};

export default ActivitiesList;

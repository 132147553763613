import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';

import ActivityListView from 'modules/activities/components/ActivityListView';
import { PARENT_SEARCH } from 'modules/activities/constants';
import RecentInterviews from 'modules/interviews/components/RecentInterviews';
import {
  DATE_GROUP_LABELS,
  DATE_GROUP_PAST,
  DATE_GROUP_UPCOMING,
} from 'modules/interviews/constants';
import RecentSearchIntroductions from 'modules/introductions/components/RecentSearchIntroductions';
import RecentSearchNotes from 'modules/notes/components/RecentSearchNotes';
import RecentSearchOutreaches from 'modules/outreaches/components/RecentSearchOutreaches';
import {
  TYPE_TALENT_POOL,
  TYPE_JOB_SEARCH,
  TYPE_PIPELINE,
  SEARCH_TYPES,
} from 'modules/searches/constants';
import mapSearchIdToSearchType from 'modules/searches/components/mapSearchIdToSearchType';
import { useSelector } from 'react-redux';
import getSearchProperty from 'modules/searches/selectors/getSearchProperty';
import CandidacyStageTag from 'modules/candidacy-views/searchPage/CandidacyStageTag';

import SidebarSearchContentSectionTitle from './SidebarSearchContentSectionTitle';
import SidebarSearchContentHeader from './SidebarSearchContentHeader';

/**
 * The "content" area of the search sidebar when displaying the summary for the current search.
 * This renders the following sections:
 *   - "header": candidates-per-stage chart, average compensations, details popover toggle.
 *   - "notes": recent notes related to the search
 *   - "upcoming interviews": upcoming interviews related to the search
 *   - "recent interviews": most recent interviews with past dates related to the search
 *   - "outreaches": recent outreaches related to the search
 *   - "activity": recent activity related to the search
 *
 * Each section has a link to expand the popover to include more detailed information.
 * The popover content in those cases is found in the ../popovers directory.
 */
const SidebarSearchContent = ({ searchId, searchType }) => {
  const searchPage = searchType === TYPE_PIPELINE ? 'people' : 'candidates';
  const searchTypeForDisplay =
    searchType === TYPE_PIPELINE ? 'Pipeline' : 'Search';
  const smartTags = useSelector(state =>
    getSearchProperty(state, searchId, 'smart_tags'),
  )?.toJS();

  return (
    <div className='SidebarSearchContent'>
      <SidebarSearchContentHeader
        searchId={searchId}
        searchPage={searchPage}
        searchType={searchType}
      />
      {searchType === TYPE_PIPELINE && smartTags?.length > 0 ? (
        <>
          <h3 className='u-borderBottom u-paddingBottom-4'>Criteria</h3>
          {smartTags.map(smartTag => (
            <CandidacyStageTag
              className='u-paddingHorizontal-4 u-marginRight-8 u-marginBottom-4'
              color={smartTag.color}
              key={smartTag.id}
              name={smartTag.name}
            />
          ))}
        </>
      ) : null}
      {searchType !== TYPE_TALENT_POOL && (
        <RecentSearchNotes
          limit={3}
          readOnly={true}
          searchId={searchId}
          searchType={searchType}
          title={
            <SidebarSearchContentSectionTitle
              popover='notes'
              searchId={searchId}
              searchPage={searchPage}
              title={searchType === TYPE_JOB_SEARCH ? 'Search Notes' : 'Notes'}
            />
          }
        />
      )}

      <RecentInterviews
        filter={DATE_GROUP_UPCOMING}
        hideIfEmpty={true}
        readOnly={true}
        searchId={searchId}
        title={
          <SidebarSearchContentSectionTitle
            popover='interviews/upcoming'
            searchId={searchId}
            searchPage={searchPage}
            title={DATE_GROUP_LABELS[DATE_GROUP_UPCOMING]}
          />
        }
      />

      <RecentInterviews
        filter={DATE_GROUP_PAST}
        hideIfEmpty={true}
        readOnly={true}
        searchId={searchId}
        title={
          <SidebarSearchContentSectionTitle
            popover='interviews/recent'
            searchId={searchId}
            searchPage={searchPage}
            title={DATE_GROUP_LABELS[DATE_GROUP_PAST]}
          />
        }
      />

      <RecentSearchOutreaches
        hideIfEmpty={true}
        readOnly={true}
        searchId={searchId}
        title={
          <SidebarSearchContentSectionTitle
            popover='outreach'
            searchId={searchId}
            searchPage={searchPage}
            title={`${searchTypeForDisplay} Outreach`}
          />
        }
      />

      <RecentSearchIntroductions
        hideIfEmpty={true}
        limit={3}
        readOnly={true}
        searchId={searchId}
        title={
          <SidebarSearchContentSectionTitle
            popover='introductions'
            searchId={searchId}
            searchPage={searchPage}
            title={`${searchTypeForDisplay} Introductions`}
          />
        }
      />
      <ActivityListView
        hideIfEmpty={true}
        limit={3}
        parentId={searchId}
        parentType={PARENT_SEARCH}
        title={
          <SidebarSearchContentSectionTitle
            popover='activity'
            searchId={searchId}
            searchPage={searchPage}
            title='Recent Activity'
          />
        }
      />
    </div>
  );
};

SidebarSearchContent.propTypes = {
  searchId: PropTypes.number.isRequired,
  searchType: PropTypes.oneOf(SEARCH_TYPES),
};

export default compose(
  setDisplayName('SidebarSearchContent(enhanced)'),
  setPropTypes({
    searchId: PropTypes.number.isRequired,
    searchPage: PropTypes.string,
  }),
  mapSearchIdToSearchType,
)(SidebarSearchContent);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Notification from './Notification';
import ActionButton from '../ActionButton';
import notificationHandler from '../notificationHandler';

/**
 * Renders a notification item of type `introduction_request`
 */
class NotificationItemTypeIntroductionRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // This feature is temporarily disabled.
  //
  // handleLogIntroduction = () => {
  //  const { notification, onNotificationAction } = this.props;
  //  const id = notification.getIn(['data', 'resource', 'data', 'id']);
  //  onNotificationAction('log_introduction', id);
  // }

  render() {
    const { notification, onPrimaryAction } = this.props;

    return (
      <Notification
        {...this.props}
        actions={
          notification.getIn(['data', 'resource', 'data', 'introduction_id'])
            ? null
            : [
                <ActionButton
                  href={notification.getIn(['data', 'links', 'self'])}
                  isPrimary={true}
                  key='introduce'
                  label='Make Introduction'
                  notification={notification}
                  onClick={onPrimaryAction}
                />,
                // <ActionButton
                //  notification={notification}
                //  key='log'
                //  onClick={this.handleLogIntroduction}
                //  label='Log Introduction'
                // />,
              ]
        }
      >
        {notification.getIn(['data', 'resource', 'data', 'notes'])}
      </Notification>
    );
  }
}

NotificationItemTypeIntroductionRequest.propTypes = {
  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      type: PropTypes.oneOf(['introduction_request']),
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          id: PropTypes.number.isRequired,
          introduction_id: PropTypes.number,
          introduced_to_email: PropTypes.string,
          introduced_to_name: PropTypes.string,
          notes: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,

  /**
   * The callback that is called when the "Log Introduction" action is clicked.
   */
  onNotificationAction: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types

  /**
   * The callback that is called when the "Make Introduction" action is clicked
   */
  onPrimaryAction: PropTypes.func.isRequired,
};

export default notificationHandler(NotificationItemTypeIntroductionRequest);

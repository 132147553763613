/* eslint-disable camelcase */
// ^ accommodate API exchange format
import selectn from 'selectn';
import routes from 'modules/routing/routes';
import createCrudActions from '../../../../actions/createCrudActions';
import {
  RATINGS_INVALIDATE,
  RATINGS_FETCH_START,
  RATINGS_FETCH_SUCCESS,
  RATINGS_FETCH_FAILURE,
  RATING_CREATE_START,
  RATING_CREATE_SUCCESS,
  RATING_CREATE_FAILURE,
  RATING_UPDATE_START,
  RATING_UPDATE_SUCCESS,
  RATING_UPDATE_FAILURE,
  RATING_DELETE_START,
  RATING_DELETE_SUCCESS,
  RATING_DELETE_FAILURE,
} from './actionTypes';
import { ratingSchema, contactSchema } from '../../schema';

const getCollectionUrl = ({ contactId, rating }) => {
  if (rating || contactId) {
    return routes.api_v1_contact_ratings({
      contactId: selectn('contact_id', rating) || contactId,
    });
  }

  throw new Error('Rating collection requires a contactId');
};

const getEntityUrl = ({ id, rating }) =>
  routes.api_v1_rating({ id: id || rating.id });

// This denormalizes the fields we normalized when we recieved the rating.
const createSubmitPayload = ({
  rating: { job_function, ...ratingProps },
  ...options
}) => ({
  rating: {
    job_function_id: job_function,
    ...ratingProps,
  },
  ...options,
});

const {
  create: createRating,
  delete: deleteRating,
  fetchAll: fetchRatings,
  invalidateAll: invalidateRatings,
  update: updateRating,
} = createCrudActions({
  getUrl: getEntityUrl,
  schema: { rating: ratingSchema },
  invalidateAll: RATINGS_INVALIDATE,
  fetchAll: {
    getUrl: getCollectionUrl,
    start: RATINGS_FETCH_START,
    success: RATINGS_FETCH_SUCCESS,
    failure: RATINGS_FETCH_FAILURE,
    schema: { ratings: [ratingSchema] },
  },
  create: {
    getUrl: getCollectionUrl,
    createStartPayload: createSubmitPayload,
    start: RATING_CREATE_START,
    success: RATING_CREATE_SUCCESS,
    failure: RATING_CREATE_FAILURE,
  },
  update: {
    start: RATING_UPDATE_START,
    createStartPayload: createSubmitPayload,
    success: RATING_UPDATE_SUCCESS,
    failure: RATING_UPDATE_FAILURE,
  },
  delete: {
    schema: { contact: contactSchema },
    start: RATING_DELETE_START,
    success: RATING_DELETE_SUCCESS,
    failure: RATING_DELETE_FAILURE,
  },
});

export {
  fetchRatings,
  invalidateRatings,
  createRating,
  updateRating,
  deleteRating,
};

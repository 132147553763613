import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import ExternalLink from 'modules/links/ExternalLink';
import FieldState from 'modules/forms/FieldState';

import AttachmentDeleteToggle from './AttachmentDeleteToggle';

const AttachmentsListItem = ({
  attachmentFieldState,
  destroy,
  fileName,
  fileSize,
  onChange,
  url,
}) => (
  <div
    className={classnames('AttachmentsListItem', {
      'AttachmentsListItem--pending-delete': destroy,
    })}
  >
    <div className='AttachmentsListItem__name'>
      <ExternalLink href={url}>
        <i className='fa fa-file' /> {fileName}
      </ExternalLink>
      {fileSize > 0 && (
        <div className='AttachmentsListItem__size'>
          Size: {Math.trunc(fileSize / 1000)} KB
        </div>
      )}
    </div>
    {onChange && (
      <AttachmentDeleteToggle
        fieldState={attachmentFieldState}
        onChange={onChange}
      />
    )}
  </div>
);

AttachmentsListItem.propTypes = {
  /**
   * The field state of the attachment.
   */
  attachmentFieldState: PropTypes.instanceOf(FieldState),
  /**
   * Flag to destroy a document that exists in the database.
   */
  destroy: PropTypes.bool,
  /**
   * Name of the file.
   */
  fileName: PropTypes.string,
  /**
   * Size of the file.
   */
  fileSize: PropTypes.number,
  /**
   * Function to handle the delete button/toggle for a newly attached file/existing attachment.
   */
  onChange: PropTypes.func,
  /**
   * Preview url for a newly attached file.
   */
  url: PropTypes.string,
};

export default AttachmentsListItem;

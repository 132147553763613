import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import OutreachListItem, {
  defaultProps as OutreachListItemDefaultProps,
  propTypes as OutreachListItemPropTypes,
} from './OutreachListItem';
import sortOutreachesByMostRecent from './sortOutreachesByMostRecent';

/**
 * Renders a list of outreaches, with the ability to edit or delete them
 * inline if the user has permission (based on `permissions.edit` and `permissions.delete`
 * values of the outreach item).
 */
export const OutreachList = ({
  candidacyId,
  limit,
  outreaches,
  readOnly,
  renderDates,
  renderDescriptions,
  renderRelatedSearches,
  renderTitlesAsLinks,
  searchId,
}) => (
  <ul className='list-generic outreaches--outreach-list'>
    {(limit ? outreaches.take(limit) : outreaches)
      .map(outreach => (
        <OutreachListItem
          candidacyId={candidacyId}
          key={outreach.get('id')}
          outreach={outreach}
          readOnly={readOnly}
          renderDate={renderDates}
          renderDescription={renderDescriptions}
          renderRelatedSearches={renderRelatedSearches}
          renderTitleAsLink={renderTitlesAsLinks}
          searchId={searchId}
        />
      ))
      .toArray()}
  </ul>
);

export const propTypes = {
  candidacyId: PropTypes.number,

  /**
   * When provided, limits the number of outreaches shown to this amount.
   */
  limit: PropTypes.number,

  /**
   * The outreaches to be rendered
   */
  outreaches: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      contacted_on: PropTypes.string,
      created_at: PropTypes.string,
    }),
  ).isRequired,

  /**
   * When true, the underlying list items cannot be edited/deleted, regardless
   * of the outreach record permissions.
   */
  readOnly: PropTypes.bool,

  /**
   * True to render the dates for each outreach, false to exclude them.
   */
  renderDates: OutreachListItemPropTypes.renderDate,

  /**
   * True to render the description field for each outreach, false to exclude it.
   */
  renderDescriptions: OutreachListItemPropTypes.renderDescription,

  /**
   * True to render a list of searches related to the outreach, false to exclude them.
   */
  renderRelatedSearches: OutreachListItemPropTypes.renderRelatedSearches,

  /**
   * True to render the outreach title as a link to the candidacy page's outreach
   * tab (if the contact is no longer a candidate, it will link to the contact's
   * profile page. If false, will render titles as plain text without a link.
   */
  renderTitlesAsLinks: OutreachListItemPropTypes.renderTitleAsLink,

  /**
   * If displaying this list in the context of a specific search, the ID of that search.
   */
  searchId: OutreachListItemPropTypes.searchId,
};

export const defaultProps = {
  readOnly: OutreachListItemDefaultProps.readOnly,
  renderDates: OutreachListItemDefaultProps.renderDate,
  renderDescriptions: OutreachListItemDefaultProps.renderDescription,
  renderRelatedSearches: OutreachListItemDefaultProps.renderRelatedSearches,
  renderTitlesAsLinks: OutreachListItemDefaultProps.renderTitleAsLink,
};

OutreachList.propTypes = propTypes;
OutreachList.defaultProps = defaultProps;

export default sortOutreachesByMostRecent(OutreachList);

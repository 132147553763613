import getEmployeeCountList from './getEmployeeCountList';
/**
 *  Determines if a employeeCount list needs to be fetched from the server.
 * @param {*} state
 * @param {Number} companyId The ID of company entity
 * @return {Boolean} true if the employeeCount
 * list should be fetched from the server; otherwise, false.
 */
export default (state, companyId) => {
  if (!companyId) {
    return false;
  }

  const employeeCountList = getEmployeeCountList(state, companyId);

  if (!employeeCountList) {
    return true;
  }

  if (
    employeeCountList.getIn(['_meta', 'isFetching']) ||
    employeeCountList.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return Boolean(employeeCountList.getIn(['_meta', 'isInvalidated']));
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import classNames from 'classnames';
import mapTaskIdToTask from './mapTaskIdToTask';
import withTasksFetched from './withTasksFetched';
import connectTaskActions from './connectTaskActions';

/**
 * More or less standalone button that changes the "completed" status of a task.
 */
class TaskStatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  onClick = () => {
    const { handleUpdate, task, taskActions } = this.props;
    taskActions.updateTask({
      task: {
        id: task.get('id'),
        completed: !task.get('completed'),
      },
    });

    if (handleUpdate) {
      handleUpdate(task.get('id'));
    }
  };

  handleMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  render() {
    const { task } = this.props;
    const { hovered } = this.state;
    const checked = task.get('completed');

    return (
      <button
        className={classNames(
          'btn',
          'btn-link',
          'task-status-button',
          'u-marginRight-32',
          {
            hovered: hovered,
          },
        )}
        onClick={this.onClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        type='button'
      >
        <i
          className={classNames({
            fa: true,
            'fa-check-circle': checked !== hovered,
            'fa-circle-thin': checked === hovered,
          })}
        />
      </button>
    );
  }
}

TaskStatusButton.propTypes = {
  handleUpdate: PropTypes.func,
  /**
   * The task object. Provided by mapTaskIdToTask
   */
  task: ImmutablePropTypes.mapContains({
    completed: PropTypes.bool.isRequired,
  }).isRequired,

  taskActions: PropTypes.shape({
    updateTask: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(
  setDisplayName('TaskStatusButton(enhanced)'),
  setPropTypes({
    handleUpdate: PropTypes.func,
    taskId: PropTypes.number.isRequired,
  }),
  withTasksFetched,
  mapTaskIdToTask,
  connectTaskActions,
)(TaskStatusButton);

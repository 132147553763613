import routes from 'modules/routing/routes';
import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import employeeCountSchema from '../schema';
import {
  EMPLOYEE_COUNT_LIST_FETCH_START,
  EMPLOYEE_COUNT_LIST_FETCH_SUCCESS,
  EMPLOYEE_COUNT_LIST_FETCH_FAILURE,
} from './actionTypes';

/**
 * Creates an action for fetching a list of employeeStatistics for a company
 * @param {Object} payload
 * @param {String} payload.companyId The ID of the company to fetch the employeeStatistics for.
 */
export default compose(
  fetchAction,
  normalizeResponse({ employee_counts: [employeeCountSchema] }),
  createAsyncActions({
    startActionType: EMPLOYEE_COUNT_LIST_FETCH_START,
    successActionType: EMPLOYEE_COUNT_LIST_FETCH_SUCCESS,
    failureActionType: EMPLOYEE_COUNT_LIST_FETCH_FAILURE,
  }),
  withOptions(({ companyId }) => ({
    url: routes.api_v1_company_employee_counts({ companyId: companyId }),
  })),
);

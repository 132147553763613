import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import mapRatingIdsToRatings from 'modules/assessments/components/mapRatingIdsToRatings';
import mapAssessmentIdToAssessment from 'modules/assessments/components/mapAssessmentIdToAssessment';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import DateTime from 'modules/datetime/components/DateTime';
import HelpText from '@thrivetrm/ui/components/HelpText';
import RelativeTimeLabel from 'modules/datetime/components/RelativeTimeLabel';

import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import QuestionAnswersModalButton from '../../../question-answers/components/QuestionAnswersModalButton';
import Attributes from './Attributes';
import getEntityProperty from '../../../entities/selectors/getEntityProperty';
import assessmentQuestionSetSchema from '../../../tenant/schemas/assessmentQuestionSets';

/**
 * Renders the read-only view of a single assessment
 */
export const AssessmentView = ({
  assessment,
  ratings,
  renderDate,
  renderQuestionAnswers,
}) => {
  const hasAssessmentTemplates = useFeatureCheck(
    'feature.assessment_templates',
  );
  const questionName = `Assessment Template - ${assessment.get(
    'question_set_name',
  )}`;
  // if we cannot locate the questionSet in entities state, that means it has been destroyed.
  // It it still associated with this assessment, however, and thus we use this boolean to
  // determine whether or not to append "(Discontinued)" to the question set name.
  const isDiscontinued = !useSelector(state =>
    getEntityProperty(
      state,
      assessmentQuestionSetSchema.key,
      assessment.get('question_set'),
      'name',
    ),
  );
  const questionSetTitle = isDiscontinued
    ? `${questionName} (Discontinued)`
    : questionName;
  const renderQuestionAnswersModalButton =
    renderQuestionAnswers &&
    (assessment.get('question_set_name') ||
      assessment.get('has_answer_content'));

  return (
    <div className='AssessmentView'>
      <div className='u-inlineFlex u-flexAlign-c u-marginBottom-8'>
        {assessment.get('added_by') || assessment.get('assessed_by_name') ? (
          <div className='AssessmentView__added-by' key='added-by'>
            Assessed by{' '}
            {hasAssessmentTemplates
              ? assessment.get('assessed_by_name')
              : assessment.get('added_by')}
            &nbsp;&nbsp;
          </div>
        ) : null}
        {renderDate ? (
          <div className='title AssessmentView__date' key='date'>
            <TooltipTrigger
              placement='top'
              tooltip={
                <DateTime
                  format='ddd MMM D, YYYY [at] h:mma'
                  value={
                    assessment.get('assessed_on') ||
                    assessment.get('updated_at')
                  }
                />
              }
            >
              <HelpText>
                <RelativeTimeLabel
                  adjustTime={6000}
                  shouldAddTitleAttribute={false}
                  time={
                    assessment.get('assessed_on') ||
                    assessment.get('updated_at')
                  }
                />
              </HelpText>
            </TooltipTrigger>
          </div>
        ) : null}
      </div>
      <div className='AssessmentView__ratings' key='ratings'>
        <Attributes
          candidacyId={assessment.get('candidacy_id')}
          ratings={ratings}
          readOnly={true}
          searchId={assessment.get('search_id')}
        />
      </div>
      <div className='AssessmentView__comments u-marginTop-8' key='comments'>
        <ExpandableText
          characterLimit={150}
          content={
            hasAssessmentTemplates
              ? assessment.get('notes')
              : assessment.get('comment')
          }
          isSanitizedHtml={true}
        />
      </div>

      {renderQuestionAnswersModalButton && (
        <QuestionAnswersModalButton
          parentId={assessment.get('id')}
          parentType='assessment'
          title={questionSetTitle}
        />
      )}
    </div>
  );
};

AssessmentView.propTypes = {
  /**
   * The comment to render.
   */
  assessment: ImmutablePropTypes.mapContains({
    added_by: PropTypes.string,
    comment: PropTypes.string,
    truncate_comment: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,

  /**
   * The ratings records for the assessment being rendered.
   */
  ratings: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number,
      score: PropTypes.number,
      assessment_option_id: PropTypes.number.isRequired,
    }),
  ),

  /**
   * True to render the date field, false to omit it.
   */
  renderDate: PropTypes.bool,

  /**
   * True to render the QuestionAnswersModalButton, false to omit it.
   */
  renderQuestionAnswers: PropTypes.bool,
};

AssessmentView.defaultProps = {
  renderDate: true,
  renderQuestionAnswers: false,
};

/**
 * The default export requires only a `interviewId`, while
 * the named export requires the interview record.
 */
export default compose(
  setDisplayName('AssessmentView(enhanced)'),

  // Either `assessment` or `assessmentId` is required. If only an `assessmentId`
  // is specified, the assessment will be fetched from state.
  // Ratings are always fetched from state for the connected component.
  setPropTypes({
    assessment: requiredIf(
      ImmutablePropTypes.mapContains({
        id: PropTypes.number,
      }),
      ({ assessmentId }) => !assessmentId,
    ),

    assessmentId: requiredIf(PropTypes.number, ({ assessment }) => !assessment),

    renderDate: PropTypes.bool,
  }),
  branch(
    props => props.assessmentId && !props.assessment,
    mapAssessmentIdToAssessment,
  ),
  withProps(({ assessment }) => ({
    ratingIds: assessment && assessment.get('ratings'),
  })),
  branch(({ ratings }) => !ratings, mapRatingIdsToRatings),
)(AssessmentView);

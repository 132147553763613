import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import classnames from 'classnames';
import {
  branch,
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import ColorField from 'modules/color/components/ColorField';
import Checkbox from 'modules/core/componentsLegacy/Checkbox';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import InputField from 'modules/forms/components/InputField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import UserName from 'modules/users/components/UserName';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import { SEARCH_TYPES, TYPE_PIPELINE } from 'modules/searches/constants';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import withTagForm from './withTagForm';
import TagColorSquare from './TagColorSquare';
import TagName from './TagName';
import getTagProperty from '../selectors/getTagProperty';
import TagField from './TagField';
import TagTableActionsCell from './TagTableActionsCell';
import deleteTagActionCreator from '../actions/deleteTag';
import smartTagSchema from '../schemas/smartTag';

/* eslint-disable jsx-a11y/label-has-associated-control */

/**
 * Renders a single row for the TagTable component.
 */
const TagTableRow = ({
  checked,
  className,
  componentId,
  createdById,
  disabled,
  editing,
  fieldState,
  formState,
  handleNestedFieldChange,
  loading,
  onCancelEdit,
  onCheckedChange,
  onDelete,
  onEdit,
  onSubmit,
  saving,
  searchType,
  tagId,
  ...props
}) => [
  <tr
    className={classnames(className, 'TagTableRow', { disabled: disabled })}
    key='data'
  >
    <td className='TagTableRow__checkboxColumn'>
      <Checkbox
        checked={checked}
        className='inline-form-control'
        disabled={disabled || editing}
        id={componentId}
        onChange={onCheckedChange}
        value={tagId}
      />
    </td>
    <td className='TagTableRow__colorColumn'>
      {editing ? (
        <ColorField
          {...props}
          className='TagField__color'
          disabled={saving}
          fieldState={fieldState.getNestedField('color')}
          onChange={handleNestedFieldChange}
          placeholder='Color'
        />
      ) : (
        <label htmlFor={componentId}>
          <TagColorSquare
            className='TagTableRow__TagColorSquare'
            searchType={searchType}
            size='2em'
            tagId={tagId}
          />
        </label>
      )}
    </td>
    <td className='TagTableRow__labelColumn'>
      {editing ? (
        <InputField
          {...props}
          className='TagField__name'
          disabled={saving}
          fieldState={fieldState.getNestedField('name')}
          onChange={handleNestedFieldChange}
          placeholder='Label'
        />
      ) : (
        <label htmlFor={componentId}>
          <TagName
            className='TagTableRow__TagName'
            searchType={searchType}
            tagId={tagId}
          />
        </label>
      )}
    </td>
    {searchType === TYPE_PIPELINE ? null : (
      <>
        <td className='TagTableRow__creatorColumn'>
          <label htmlFor={componentId}>
            <UserName userId={createdById} />
          </label>
        </td>
        <TagTableActionsCell
          className='TagTableRow__actionsColumn'
          editing={editing}
          loading={loading}
          onCancelEdit={onCancelEdit}
          onDelete={onDelete}
          onEdit={onEdit}
          onSubmit={onSubmit}
          saving={saving}
          tagId={tagId}
        />
      </>
    )}
  </tr>,
  editing && formState && formState.getError() && (
    <tr className='TagTableRow TagTableRow--error' key='error'>
      <td colSpan={5}>
        <FormErrorMessage formState={formState} />
      </td>
    </tr>
  ),
];

TagTableRow.propTypes = {
  /**
   * True if the checkbox should be checked.
   */
  checked: PropTypes.bool,

  /**
   * A ID for this component (NOT the tag ID!).
   */
  componentId: PropTypes.string,

  /**
   * The ID of the user who created the tag
   */
  createdById: PropTypes.number.isRequired,

  /**
   * True if the row is currently being edited.
   */
  editing: PropTypes.bool,

  /**
   * The type of search (talent pool or job search or pipeline)
   */
  searchType: PropTypes.oneOf(SEARCH_TYPES),
  /**
   * The tag ID to render.
   */
  tagId: PropTypes.number.isRequired,
};

TagTableRow.defaultProps = {
  checked: false,
  editing: false,
};

export default compose(
  setDisplayName('TagTableRow(enhanced)'),
  setPropTypes({
    tagId: TagTableRow.propTypes.tagId,
    checked: TagTableRow.propTypes.checked,
    searchType: TagTableRow.propTypes.searchType,
  }),
  withComponentId(),
  connect(
    (state, { searchType, tagId }) => ({
      createdById:
        searchType === TYPE_PIPELINE
          ? getEntityProperty(state, smartTagSchema.key, tagId, 'created_by')
          : getTagProperty(state, tagId, 'created_by'),
    }),
    dispatch => ({
      deleteTag: bindActionCreators(deleteTagActionCreator, dispatch),
    }),
  ),
  /**
   * Manage the editing state of the row.
   */
  withStateHandlers(
    {
      editing: false,
    },
    {
      onEdit: () => () => ({
        editing: true,
      }),
      onCancelEdit: () => () => ({
        editing: false,
      }),
      onSaved: () => () => ({
        editing: false,
      }),
      onDelete: (_, { deleteTag, tagId }) => () => {
        const transactionId = uniqueId();
        deleteTag({ transactionId: transactionId, id: tagId });
      },
    },
  ),

  branch(({ editing }) => editing, withTagForm(TagField)),
  mapProps(
    ({
      deleteTag: _deleteTag,
      formState,
      onFieldStateChange,
      onFormStateChange: _onFormStateChange,
      ...rest
    }) => ({
      ...rest,
      formState: formState,
      onChange: onFieldStateChange,
      saving: Boolean(formState) && formState.isSubmitting(),
      fieldState: formState && formState.getFieldState(),
    }),
  ),
  withNestedFieldChangeHandler,
)(TagTableRow);

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import { deletePendingContact } from './pendingContactsSlice';

const PendingContactDiscardButton = ({ id }) => {
  const dispatch = useDispatch();
  const handleConfirm = () => dispatch(deletePendingContact(id));

  const { isDeleting } = useSelector(state => state.pendingContacts);

  /**
   * The legacy `ConfirmationPopOverButton` component is used to enable a confirmation flow
   * when discarding pending contacts.  To force the button to appear as a v4 button, the `.Button`
   *  classes have been manually added.
   */

  return (
    <ConfirmationPopoverButton
      className='Button Button--medium Button--primaryOutline'
      disabled={isDeleting}
      label='Discard'
      onConfirm={handleConfirm}
      title='Are you sure?'
    />
  );
};

PendingContactDiscardButton.propTypes = {
  id: PropTypes.number.isRequired,
};

export default PendingContactDiscardButton;

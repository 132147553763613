import {
  NOTIFICATIONS_UPDATE_ALL_READ_START,
  NOTIFICATIONS_UPDATE_ALL_READ_SUCCESS,
  NOTIFICATIONS_UPDATE_ALL_READ_FAILURE,
  BASE_URL,
} from '../../domains/notifications/constants';
import { createPutAction } from '../createAjaxAction';

export const getUrl = ({ lteq }) => {
  if (lteq) {
    return `${BASE_URL}/clear?lteq=${lteq}&global=true`;
  }

  return `${BASE_URL}/clear&global=true`;
};

const updateAllAsRead = createPutAction({
  getUrl: getUrl,
  start: NOTIFICATIONS_UPDATE_ALL_READ_START,
  success: NOTIFICATIONS_UPDATE_ALL_READ_SUCCESS,
  failure: NOTIFICATIONS_UPDATE_ALL_READ_FAILURE,
});

export default updateAllAsRead;

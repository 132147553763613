import React from 'react';
import { useSelector } from 'react-redux';
import { relativeDate } from '@thrivetrm/ui/utilities/dateTimeUtils';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import getSearchType from 'modules/searches/selectors/getSearchType';
import useQuickView from 'modules/quick-view/useQuickView';
import {
  TYPE_CLIENT,
  TYPE_RECRUITER,
  TYPE_RESEARCHER,
} from 'modules/candidacy-assessments/constants';
import { TYPE_TALENT_POOL } from 'modules/searches/constants';
import {
  useGetCandidateAssessmentQuery,
  useGetTemplateQuery,
} from 'services/apiV1/assessment';
import { QuickViewErrorState } from '../QuickViewState';
import Criteria from './component/Criteria';
import ContactQvpHeader from '../ContactQvpHeader';
import QuickViewPanelHeader from '../QuickViewPanelHeader';

const ASSESSMENT_TYPES = {
  [TYPE_RECRUITER]: 'Recruiter Assessment',
  [TYPE_RESEARCHER]: 'Researcher Assessment',
  [TYPE_CLIENT]: 'Client Assessment',
};

const ViewFullAssessment = () => {
  const { pathParams } = useQuickView();
  const { candidateAssessmentId, contactId } = pathParams;

  const {
    data: candidateAssessment,
    isError: hasErrorInCandidateAssessment,
    isFetching: isLoadingCandidacyAssessment,
  } = useGetCandidateAssessmentQuery(candidateAssessmentId);

  const {
    data: template,
    isError: hasErrorInTemplate,
    isFetching: isLoadingTemplate,
  } = useGetTemplateQuery(candidateAssessment?.assessmentTemplateId, {
    skip: !candidateAssessment?.assessmentTemplateId,
  });

  const hasError = hasErrorInCandidateAssessment || hasErrorInTemplate;
  const searchType = useSelector(state =>
    getSearchType(state, candidateAssessment?.searchId),
  );
  const candidacyAssessmentUrl = `/${
    searchType === TYPE_TALENT_POOL ? 'talent_pools' : 'searches'
  }/${candidateAssessment?.searchId}/candidates/${
    candidateAssessment?.candidacyId
  }/candidacy_assessments`;

  return (
    <>
      <ContactQvpHeader contactId={contactId} />
      {candidateAssessment?.type ? (
        <QuickViewPanelHeader
          title={ASSESSMENT_TYPES[candidateAssessment?.type]}
        />
      ) : null}
      <SidePanel.Body className='u-paddingTop-8'>
        <LoadingContainer
          isLoading={isLoadingCandidacyAssessment || isLoadingTemplate}
          minHeight='100vh'
          size='large'
        >
          {hasError ? <QuickViewErrorState /> : null}
          {!hasError && candidateAssessment && template ? (
            <>
              <p className='u-margin-n u-paddingBottom-12'>
                Assessed by{' '}
                <strong>{candidateAssessment.assessedByName}</strong>{' '}
                {relativeDate(candidateAssessment.assessedOn)}
              </p>
              {template.criteria.map(({ id, name }) => {
                const assessmentCriteria = candidateAssessment.candidacyAssessmentAnswersAttributes?.find(
                  criteriaDetails =>
                    criteriaDetails.assessmentCriteriumId === id,
                );
                return (
                  <Criteria
                    key={id}
                    name={name}
                    notes={assessmentCriteria?.notes}
                    rating={assessmentCriteria?.ratingValue}
                  />
                );
              })}
              {template.categories.map(({ criteria, id, name }) => {
                const criteriaAverage = candidateAssessment.candidacyAssessmentAnswersAttributes?.find(
                  criteriaData =>
                    criteriaData.assessmentCategoryId === id &&
                    criteriaData.criteriaAverage,
                )?.criteriaAverage;

                return (
                  <div key={id}>
                    <h4 className='u-marginVertical-12 u u-fontSize-small'>
                      Category: {name}{' '}
                      {criteria?.length
                        ? `- ${(criteriaAverage || 0).toFixed(2)} Average`
                        : null}
                    </h4>
                    {criteria.map(criteriaData => {
                      const assessmentCriteria = candidateAssessment.candidacyAssessmentAnswersAttributes?.find(
                        criteriaDetails =>
                          criteriaDetails.assessmentCriteriumId ===
                            criteriaData.id &&
                          criteriaDetails.assessmentCategoryId === id,
                      );
                      return (
                        <Criteria
                          key={criteriaData.id}
                          name={criteriaData.name}
                          notes={assessmentCriteria?.notes}
                          rating={assessmentCriteria?.ratingValue}
                        />
                      );
                    })}
                  </div>
                );
              })}
              <h4 className='u-margin-n u-fontSize-small u-marginTop-16 u-marginBottom-8'>
                Summary Notes:
              </h4>
              <HtmlText
                className='u-fontSize-small'
                htmlString={candidateAssessment.notes}
              />
            </>
          ) : null}
        </LoadingContainer>
      </SidePanel.Body>
      {!hasError && candidateAssessment?.permissions?.edit ? (
        <SidePanel.Footer>
          <ButtonPrimary
            label='Edit'
            onClick={() => {
              window.location.href = `${candidacyAssessmentUrl}/${candidateAssessment.id}/edit`;
            }}
            size='small'
          />
        </SidePanel.Footer>
      ) : null}
    </>
  );
};

export default ViewFullAssessment;

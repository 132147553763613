import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {
  compose,
  defaultProps,
  mapProps,
  setPropTypes,
  setDisplayName,
} from 'recompose';
import { connect } from 'react-redux';
import mapContactIdToContact from '../mapContactIdToContact';
import mapContactIdToRatings from './mapContactIdToRatings';
import ContactRating from './ContactRating';
import isRatingByCurrentUser from '../../selectors/ratings/isRatingByCurrentUser';

/**
 * Shows the average stars rating of a contact from a contact ID,
 * with summary text below.
 */
export default compose(
  setDisplayName('ContactAverageRating'),
  setPropTypes({
    /**
     * The ID of the contact to display the average rating information for.
     */
    contactId: PropTypes.number.isRequired,

    /**
     * Optional prop that indicates if the current user has rated the contact. This is currently
     * only passed in by ContactAverageRatingContainer.
     */
    isUserRated: PropTypes.bool,

    /**
     * The text to display if the contact has never been rated
     */
    notRatedText: PropTypes.string,

    /**
     * The position of the tooltips shown when hovering over ratings.
     */
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  }),
  defaultProps({
    isUserRated: false,
    notRatedText: 'Not rated',
  }),

  mapContactIdToContact,
  mapContactIdToRatings,

  // Determine if any of the ratings were created by the current user.
  // Use isUserRated contact property if it's in state (if on the contacts index page)
  connect(
    (state, { contact, ratings }) => ({
      isUserRated:
        contact.get('isUserRated') ||
        Boolean(
          ratings &&
            ratings.find(
              rating =>
                rating && isRatingByCurrentUser(state, rating.get('id')),
            ),
        ),
    }),
    {},
  ),
  mapProps(({ contact, isUserRated, notRatedText, tooltipPlacement }) => {
    const value = (contact && contact.get('average_rating')) || 0;
    const count = (contact && contact.get('ratings_count')) || 0;

    return {
      children: value
        ? `${value.toFixed(1)} based on ${count} ${pluralize('rating', count)}`
        : notRatedText,
      isUserRated: isUserRated,
      readOnly: true,
      tooltipPlacement: tooltipPlacement,
      value: value,
      className: 'ContactAverageRating',
    };
  }),
)(ContactRating);

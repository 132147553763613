import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { PARENT_TYPES } from '../constants';
import getReferenceList from '../selectors/getReferenceList';
import getShouldFetchReferenceList from '../selectors/shouldFetchReferences';
import fetchReferenceListActionCreator from '../actions/fetchReferenceList';

/**
 * When provided a parentId, parentType via props, fetches the references
 * for that parent if they need to be fetched so they are available for a component.
 */
export default compose(
  setDisplayName('withReferenceListFetched'),
  setPropTypes({
    parentId: PropTypes.number,
    parentType: PropTypes.oneOf(PARENT_TYPES),
  }),

  connect(
    (state, { parentId, parentType }) => ({
      shouldFetchReferenceList: getShouldFetchReferenceList(
        state,
        parentType,
        parentId,
      ),
      referenceList:
        parentType && getReferenceList(state, parentType, parentId),
    }),
    dispatch => ({
      fetchReferenceList: bindActionCreators(
        fetchReferenceListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchReferenceList: ({
      fetchReferenceList,
      parentId,
      parentType,
    }) => () => {
      fetchReferenceList({ parentId: parentId, parentType: parentType });
    },

    /**
     * A handler that, when called, only fetches the references list if needed.
     */
    fetchReferenceListIfNeeded: ({
      fetchReferenceList,
      parentId,
      parentType,
      shouldFetchReferenceList,
    }) => () =>
      shouldFetchReferenceList &&
      fetchReferenceList({ parentId: parentId, parentType: parentType }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchReferenceListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchReferenceListIfNeeded();
    },
  }),
);

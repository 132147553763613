import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { OrderedMap } from 'immutable';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import FieldState from 'modules/forms/FieldState';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import EducationField from './EducationField';

/**
 * This is a field for editing a collection of multiple educations at once.
 * This is used when we can't submit educations as they are created (in the case of, for example,
 * when an underlying contact record doesn't exist yet).
 */
class EducationListField extends Component {
  /**
   * Creates a fieldState for the EducationListField.
   * @param {String} [name='educations'] The field name
   * @param {Immutable.List} educations The list of education objects. Each education in the list
   *   should be a education record itself (an Immutable.Map) with a unique ID.
   */
  static createFieldState = (name = 'educations', educations) => {
    if (!educations || educations.size < 1) {
      return FieldState.create(name, new OrderedMap());
    }

    return FieldState.create(
      name,
      // The underlying fieldstate value is an ordered map -- we need to make sure we show
      // educations with the most recent one first.
      new OrderedMap(
        educations.map(education => [education.get('id'), education]),
      )
        .sortBy(education => [
          education.get('end_year') === null,
          education.get('start_year'),
          education.get('end_year'),
        ])
        .reverse()
        .map((education, id) => EducationField.createFieldState(id, education)),
    );
  };

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  handleAdd = () => {
    const { fieldState, onChange } = this.props;

    // When addinga  new ID, we generate a random unique ID and in order to ensure it shows
    // at the top of the list, we create a new OrderedMap and concat it to the existing one.
    const id = uniqueId('NEW');
    const newMap = new OrderedMap([[id, EducationField.createFieldState(id)]]);
    onChange(fieldState.setValue(newMap.concat(fieldState.getValue())));
  };

  handleRemove = (event, childField) => {
    const { fieldState, onChange } = this.props;
    onChange(
      fieldState.setValue(fieldState.getValue().delete(childField.getName())),
    );
  };

  render() {
    const {
      fieldState,
      onChange: _onChange,
      title,
      ...otherProps
    } = this.props;

    return (
      <div className='EducationListField'>
        <div className='u-flex u-flexAlign-c u-marginBottom-16'>
          <h2 className='u-flexItem-grow'>{title}</h2>
          <div className='u-paddingTop-8'>
            <ButtonSecondary
              icon='add'
              label='New Education'
              onClick={this.handleAdd}
              size='small'
            />
          </div>
        </div>
        <ul className='EducationListField__list list-unstyled'>
          {fieldState
            .getValue()
            .map(childFieldState => (
              <li
                className='EducationListField__list-item u-padding-24'
                key={childFieldState.getName()}
              >
                <div className='u-textAlign-r'>
                  <ConfirmationPopoverButton
                    className='Button Button--secondary Button--medium Button--hasIconOnly'
                    iconClass='fa fa-trash'
                    key='delete'
                    onConfirm={this.handleRemove}
                    title='Are you sure you want to delete this education?'
                    value={childFieldState}
                  />
                </div>
                <EducationField
                  fieldState={childFieldState}
                  onChange={this.handleFieldChange}
                  {...otherProps}
                />
              </li>
            ))
            .toArray()}
        </ul>
      </div>
    );
  }
}

EducationListField.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  onChange: PropTypes.func.isRequired,

  title: PropTypes.string,
};

EducationListField.defaultProps = {
  title: 'Education',
};

export default EducationListField;

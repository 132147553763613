import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import TargetFunctionsTagSelectField from 'modules/tenant/components/TargetFunctionsTagSelectField';
import connectCompanyActions from './connectCompanyActions';
import mapCompanyIdToProperty from './mapCompanyIdToProperty';

/**
 * Renders a form for tagging a company with target functions.
 * This form auto-submits whenever the underlying fieldState is changed, so there is no
 * "submit" button here, and it doesn't have/need a formState as is typical of a "standard"
 * form.
 */
export default compose(
  setDisplayName('CompanyTargetFunctionsForm'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToProperty('target_functions', 'companyTargetFunctions'),
  connectCompanyActions,
  withStateHandlers(
    ({ companyTargetFunctions }) => ({
      fieldState: TargetFunctionsTagSelectField.createFieldState(
        'targetFunctions',
        companyTargetFunctions,
      ),
    }),
    {
      onChange: (_, { companyActions, companyId }) => fieldState => {
        // Whenever the fieldState is changed, we update the company with the new IDs immediately.
        companyActions.updateCompany({
          company: {
            id: companyId,
            target_function_ids: fieldState.getValue(),
          },
        });

        return { fieldState: fieldState };
      },
    },
  ),
)(TargetFunctionsTagSelectField);

import isClientUser from 'modules/auth/selectors/isClientUser';

/**
 * Gets a value indicating whether the current user has permission to view/generate a search
 * summary report.
 * @param {Object} state
 * @return {Boolean} True if the current user is allowed to view search summary reports;
 *   otherwise, false.
 */
const canViewSearchSummaryReport = state => !isClientUser(state);

export default canViewSearchSummaryReport;

import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import SearchStageName from 'modules/search-stages/components/SearchStageName';
import mapCandidacyIdToStageId from './mapCandidacyIdToStageId';

/**
 * Renders the stage name that a candidacy record is in, given it's candidacyId
 *
 * @example
 * ```jsx
 * <CandidacyStageName candidacyId={42} />
 * ```
 */
export default compose(
  setDisplayName('CandidacyStageName'),
  setPropTypes({
    candidacyId: PropTypes.number,
  }),
  mapCandidacyIdToStageId,
)(SearchStageName);

import getJobApplicationsBySearchId from './getJobApplicationsBySearchId';

const shouldFetchJobApplicationsList = (state, searchId) => {
  if (!searchId) {
    return false;
  }

  const applications = getJobApplicationsBySearchId(state, searchId);

  if (!applications) {
    return true;
  }

  if (
    applications.getIn(['_meta', 'isFetching']) ||
    applications.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return (
    !applications.has('ids') ||
    Boolean(applications.getIn(['_meta', 'isInvalidated']))
  );
};

export default shouldFetchJobApplicationsList;

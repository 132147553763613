import {
  CONTACT_CREATE_START,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_FAILURE,
} from './constants';
import createAjaxAction from '../createAjaxAction';
import reduceFieldErrors from './reduceFieldErrors';

export const getUrl = () => '/api/v1/contacts';

export const getRequestOptions = ({ contact }) => ({
  method: 'POST',
  body: JSON.stringify({ contact: contact }),
});

export const createSuccessPayload = (payload, { contact }) => ({
  ...payload,
  data: contact,
  contactId: contact.id,
});

const createContact = createAjaxAction({
  getUrl: getUrl,
  getRequestOptions: getRequestOptions,
  createSuccessPayload: createSuccessPayload,
  start: CONTACT_CREATE_START,
  success: CONTACT_CREATE_SUCCESS,
  failure: CONTACT_CREATE_FAILURE,
  createErrorPayload: reduceFieldErrors,
});

export default createContact;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import RocketReachBody from './RocketReachBody';

const COMPLETED_STATUS = 'complete';

/**
 * Shows a modal and fetches matching contacts.
 */
class RocketReachPopover extends Component {
  state = {
    isFetching: false,
    error: null,
    data: null,
    attemptCount: 0,
  };

  UNSAFE_componentWillMount() {
    this.fetchResults();
  }

  componentWillUnmount() {
    if (this.currentXHR) {
      this.currentXHR.abort();
      this.currentXHR = null;
    }

    if (this.refreshId) {
      clearTimeout(this.refreshId);
    }
  }

  incrementAttemptCount = () => {
    this.setState(prevState => ({
      attemptCount: prevState.attemptCount + 1,
    }));
  };

  resetAttemptCount = () => {
    this.setState({
      attemptCount: 0,
    });
  };

  checkStatus = rocketreachId => {
    const {
      contact: { id },
    } = this.props;

    const url = `/api/rocketreach/${id}/checkstatus/${rocketreachId}`;

    this.processRequest(url);
  };

  fetchResults = () => {
    const {
      contact: { id },
    } = this.props;
    const url = `/api/rocketreach/${id}`;

    this.setState({ isFetching: true });

    this.processRequest(url);
  };

  processRequest = url => {
    this.incrementAttemptCount();

    // PLEASE, SOMEBODY GET RID OF THIS!!!
    this.currentXHR = jQuery
      .getJSON(url)
      .done(this.handleSuccess)
      .fail(this.handleFail);
  };

  handleSuccess = ([data]) => {
    this.setState({
      data: data,
      error: null,
      isFetching: data.status !== COMPLETED_STATUS,
    });

    const { attemptLimit, refreshDelay } = this.props;

    const { attemptCount } = this.state;

    if (data.status !== COMPLETED_STATUS && attemptCount <= attemptLimit) {
      this.refreshId = setTimeout(this.checkStatus(data.id), refreshDelay);
    } else {
      this.resetAttemptCount();
      this.setState({ isFetching: false });
    }

    this.currentXHR = null;
  };

  handleFail = () => {
    this.setState({
      error: 'Contact not found.',
      isFetching: false,
    });
  };

  render() {
    const { onClose } = this.props;
    const { data, error, isFetching } = this.state;

    return (
      <div className='rocketreach-popover'>
        <h4 className='rocketreach-popover-header'>
          <button
            className='rocketreach-close-button pull-right btn btn-link'
            onClick={onClose}
            type='button'
          >
            <i className='fa fa-times' />
          </button>
          <i className='fa fa-rocket' />
          RocketReach: Contact Search
        </h4>

        <div className='rocketreach-component'>
          <div className='popover-content rocketreach-popover-content'>
            {isFetching && (
              <LoadingIndicator
                message={
                  data ? 'Finding more data . . .' : 'Looking up contact . . .'
                }
                size={data ? 32 : 64}
              />
            )}

            {error && <h2 className='rocketreach-warning'>{error}</h2>}

            {data && <RocketReachBody data={data} />}
          </div>
        </div>
      </div>
    );
  }
}

RocketReachPopover.propTypes = {
  attemptLimit: PropTypes.number,

  /* eslint-disable react/forbid-prop-types */
  contact: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */

  onClose: PropTypes.func.isRequired,

  refreshDelay: PropTypes.number,
};

RocketReachPopover.defaultProps = {
  attemptLimit: 10,
  refreshDelay: 5000,
};

export default RocketReachPopover;

import getInterviewProperty from './getInterviewProperty';

/**
 * Returns a list of the ids of an interview's attendees, minus the candidate,
 * for an interview by its ID.
 *
 * This does not return the legacy field 'interviewers' - for that, use getLegacyInterviewers
 */

export default (state, interviewId) => {
  const attendees = getInterviewProperty(state, interviewId, 'attendees');
  const candidateId = getInterviewProperty(state, interviewId, 'candidate');
  return attendees ? attendees.filter(id => id !== candidateId) : null;
};

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ConnectedSearchTitle, { SearchTitle } from './SearchTitle';

const SearchTitles = ({ notification, search, searchLinks, searches }) => (
  <div className='notification-related-to-searches'>
    <h6>Related to </h6>
    {search ? ( // eslint-disable-line no-nested-ternary
      typeof search === 'number' ? (
        <ConnectedSearchTitle notification={notification} searchId={search} />
      ) : (
        <SearchTitle
          url={searchLinks.get('candidate_panel') || searchLinks.get('self')}
          {...search.toJS()}
        />
      )
    ) : (
      searches &&
      searches.map(eachSearch =>
        typeof eachSearch === 'number' ? (
          <ConnectedSearchTitle
            key={eachSearch}
            notification={notification}
            searchId={eachSearch}
          />
        ) : (
          <SearchTitle
            key={eachSearch.get('id')}
            tab={
              notification.getIn(['data', 'type']) === 'outreach'
                ? '/outreach'
                : null
            }
            url={eachSearch.getIn(['links', 'self'])}
            {...eachSearch.toJS()}
          />
        ),
      )
    )}
  </div>
);

export default SearchTitles;

SearchTitles.propTypes = {
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      candidacy_id: PropTypes.number,
      type: PropTypes.string,
    }),
  }),
  search: ImmutablePropTypes.mapContains({
    client_company: PropTypes.shape({
      name: PropTypes.string,
    }),
    job_title: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  searches: ImmutablePropTypes.listOf(
    PropTypes.oneOfType([
      PropTypes.number,
      ImmutablePropTypes.mapContains({
        client_company: PropTypes.shape({
          name: PropTypes.string,
        }),
        job_title: PropTypes.string,
        url: PropTypes.string,
      }),
    ]),
  ),
  searchLinks: ImmutablePropTypes.mapContains({
    candidate_panel: PropTypes.string,
    self: PropTypes.string,
  }),
};

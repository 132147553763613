import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import searchStageSchema from '../schema';

/**
 * Gets a stage's position from it's ID
 * @param {Object} state
 * @param {Number} id The stage ID
 * @return {Number} The position value
 */
export default (state, id) =>
  getEntityProperty(state, searchStageSchema.key, id, 'position');

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes, withState } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import FieldState from 'modules/forms/FieldState';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import getContact from '../../selectors/contacts/getContact';
import createDirectReportAction from '../../actions/directReports/createDirectReport';
import ContactSelectField from '../ContactSelectField';
import mapContactIdToContact from '../mapContactIdToContact';

/**
 * A select and "Add" button for choosing a contact and
 * adding them as a direct report (reports_to).
 * NOTE: This code is outdated. Do not use this as an example of accomplishing something similar.
 * TODO: Rework `single-input-form` and related classes into a component.
 */
export class AddDirectReportForm extends Component {
  state = {
    /**
     * Any pending "add" requests
     * @type {Array}
     */
    transactionIds: [],
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { transactionActions, transactions } = nextProps;
    const { transactionIds: pendingTransactionIds } = this.state;

    // Check to see if any of our pending transactions have been completed,
    // and if so make sure you clear the transaction from our state and
    // update our internal list of transactionIds
    const finishedTransactions = pendingTransactionIds.filter(
      id => !transactions.has(id) || transactions.get(id).isFinished,
    );

    if (finishedTransactions.length) {
      finishedTransactions.forEach(transactionActions.clearTransaction);
      this.setState(({ transactionIds }) => ({
        transactionIds: transactionIds.filter(
          id => !finishedTransactions.includes(id),
        ),
      }));
    }
  }

  componentWillUnmount() {
    const { transactionActions } = this.props;
    const { transactionIds } = this.state;
    transactionIds.forEach(transactionActions.clearTransaction);
  }

  handleAddClick = () => {
    const {
      contactId,
      createDirectReport,
      fieldState,
      setFieldState,
    } = this.props;
    const reporteeId = fieldState.getValue();

    if (!reporteeId) {
      return;
    }

    const transactionId = uniqueId();

    createDirectReport({
      contactId: contactId,
      id: reporteeId,
      transactionId: transactionId,
    });

    this.setState(prevState => ({
      transactionIds: [...prevState.transactionIds, transactionId],
    }));
    setFieldState(ContactSelectField.createFieldState());
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  render() {
    const {
      contact,
      contactId,
      fieldState,
      label,
      selectedContact,
      selectedContactReportsTo,
      setFieldState,
    } = this.props;
    const { transactionIds } = this.state;
    const value = fieldState.getValue();
    const alreadyReportsTo =
      selectedContact && selectedContact.get('reports_to') === contactId;
    const isSelf = value === contactId;
    const isEmpty = !value;

    const contactName =
      contact && (contact.get('name') || contact.get('full_name'));
    const selectedContactName =
      selectedContact &&
      (selectedContact.get('name') || selectedContact.get('full_name'));
    const selectedContactReportsToName =
      selectedContactReportsTo &&
      (selectedContactReportsTo.get('name') ||
        selectedContactReportsTo.get('full_name'));

    let error;
    if (alreadyReportsTo) {
      error = `${selectedContactName} already reports to ${contactName}`;
    } else if (isSelf) {
      error = `${contactName} can't report to themselves!`;
    }

    return (
      <form className='form single-input-form' onSubmit={this.handleSubmit}>
        <div className='single-input-form-input'>
          <ContactSelectField
            error={error}
            errorDisplay='tooltip'
            fieldState={fieldState}
            isCreatable={false}
            label={
              label && (
                <span>
                  {label}
                  &nbsp;
                  {transactionIds.length > 0 && (
                    <i className='fa fa-fw fa-spin fa-spinner' />
                  )}
                </span>
              )
            }
            onChange={setFieldState}
            queryParams={{
              fields: ['reports_to'],
              // Don't allow people to report to themselves!
              exclude: [contactId],
            }}
            // always show errors.
            showErrors={true}
          />
        </div>
        <div className='single-input-form-actions'>
          <ConfirmationPopoverButton
            className='btn btn-secondary btn-sm'
            disabled={alreadyReportsTo || isSelf || isEmpty}
            isConfirmationEnabled={Boolean(selectedContactReportsTo)}
            label='Add'
            onConfirm={this.handleAddClick}
            popoverPlacement='bottom'
            title='Update direct report?'
          >
            <p>
              {selectedContactName || 'This person'} currently reports to{' '}
              <strong>{selectedContactReportsToName || 'someone else'}</strong>.
              Would you like to update their reporting relationship
              {contactName && (
                <span>
                  so they report to <strong>{contactName}</strong> instead
                </span>
              )}
              ?
            </p>
          </ConfirmationPopoverButton>
        </div>
      </form>
    );
  }
}

AddDirectReportForm.propTypes = {
  /**
   * The contact that direct reports will be added to
   */
  contact: ImmutablePropTypes.mapContains({
    name: PropTypes.string,
    full_name: PropTypes.string,
  }),

  /**
   * The ID of the contact whose direct reports are being displayed/managed.
   * @type {[type]}
   */
  contactId: PropTypes.number.isRequired,

  /**
   * The action required to create a direct report
   */
  createDirectReport: PropTypes.func.isRequired,

  /**
   * The underlying FieldState for this form.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * The label displayed above the select control.
   */
  label: PropTypes.string,

  /**
   * The contact record for the contact that is currently selected in the dropdown.
   */
  selectedContact: ImmutablePropTypes.mapContains({
    id: PropTypes.number,
  }),

  /**
   * The contact record of the contact that the currently selected contact (`selectedContact`)
   * reports to.
   */
  selectedContactReportsTo: ImmutablePropTypes.mapContains({
    id: PropTypes.number,
  }),

  /**
   * A function that when called updates the current `fieldState` prop.
   */
  setFieldState: PropTypes.func.isRequired,

  transactionActions: PropTypes.shape({
    clearTransaction: PropTypes.func.isRequired,
  }).isRequired,

  transactions: transactionsState.isRequired,
};

export default compose(
  setDisplayName('AddDirectReportForm(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  connectTransactions,
  withState(
    'fieldState',
    'setFieldState',
    ContactSelectField.createFieldState(),
  ),
  connect(
    (state, { fieldState }) => {
      const selectedContactId = fieldState.getValue();
      const selectedContact =
        selectedContactId && getContact(state, selectedContactId);
      return {
        selectedContact: selectedContact,
        selectedContactReportsTo:
          selectedContact &&
          selectedContact.get('reports_to') &&
          getContact(state, selectedContact.get('reports_to')),
      };
    },
    { createDirectReport: createDirectReportAction },
  ),
)(AddDirectReportForm);

import PropTypes from 'prop-types';
import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';

const TabbedHeaderTabsCollapsed = ({ children, componentId, title }) => (
  <DropdownButton
    className='TabbedHeaderTabsCollapsed'
    id={componentId}
    title={title}
  >
    {children}
  </DropdownButton>
);

TabbedHeaderTabsCollapsed.propTypes = {
  children: PropTypes.node,
  componentId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

TabbedHeaderTabsCollapsed.defaultProps = {
  title: 'Menu',
};

export default withComponentId()(TabbedHeaderTabsCollapsed);

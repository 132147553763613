import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextFilter from './TextFilter';
import CheckboxFilter from './CheckboxFilter';
import { setSortParams, DEFAULT_SORT_FIELD } from '../../recordIndexSlice';
import MultiValueTextBooleanFilter from './MultiValueTextBooleanFilter';

const PersistentFilterInputs = () => {
  const dispatch = useDispatch();

  const { filters } = useSelector(state => state.recordIndex.savedView);

  const isFilteringOnPosition = useSelector(
    state =>
      state.recordIndex.savedView?.filters?.positionBoolean?.values?.length > 0,
  );

  const isFilteringOnCompany = useSelector(
    state =>
      state.recordIndex.savedView?.filters?.companyBoolean?.values?.length > 0,
  );

  const [positionFilterHasFocus, setPositionFilterHasFocus] = useState(false);
  const [companyFilterHasFocus, setCompanyFilterHasFocus] = useState(false);

  // Wait for filter values to be loaded from saved view
  if (!filters) return null;

  const handleRelevanceFilterChange = value => {
    if (value.length) {
      dispatch(setSortParams({ sortDirection: null, sortField: 'relevance' }));
    } else {
      dispatch(
        setSortParams({ sortDirection: 'asc', sortField: DEFAULT_SORT_FIELD }),
      );
    }
  };

  return (
    <div className='u-marginVertical-12 u-paddingBottom-12 u-borderBottom'>
      <MultiValueTextBooleanFilter label='Keywords' name='keywordBoolean' />

      <TextFilter
        label='Name'
        name='name'
        onChange={handleRelevanceFilterChange}
      />
      {/* The business has specifically requested that we hide the checkboxes when either the
        filter set section does not have focus or the input is empty.  */}
      <div
        onBlur={() => setPositionFilterHasFocus(false)}
        onFocus={() => setPositionFilterHasFocus(true)}
      >
        <MultiValueTextBooleanFilter
          label='Job Title'
          name='positionBoolean'
          onChange={handleRelevanceFilterChange}
        />
        {isFilteringOnPosition || positionFilterHasFocus ? (
          <>
            <CheckboxFilter
              label='Current'
              name='filterCurrentPositionBoolean'
            />
            <CheckboxFilter label='Past' name='filterPastPositionBoolean' />
          </>
        ) : null}
      </div>
      <div
        onBlur={() => setCompanyFilterHasFocus(false)}
        onFocus={() => setCompanyFilterHasFocus(true)}
      >
        <MultiValueTextBooleanFilter
          label='Company'
          name='companyBoolean'
          onChange={handleRelevanceFilterChange}
        />
        {isFilteringOnCompany || companyFilterHasFocus ? (
          <>
            <CheckboxFilter
              label='Current'
              name='filterCurrentCompanyBoolean'
            />
            <CheckboxFilter label='Past' name='filterPastCompanyBoolean' />
            <CheckboxFilter
              label='Incl. Subsidiaries'
              name='filterSubsidiaryCompanyBoolean'
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PersistentFilterInputs;

import React from 'react';
import TenantOptionsAdvancedSelectField from './TenantOptionsAdvancedSelectField';
import contactSourceSchema from '../schemas/contactSources';

/**
 * A dropdown/select component for choosing from the list of contact_sources.
 */
const ContactSourceSelectField = ({ ...props }) => (
  <TenantOptionsAdvancedSelectField {...props} schema={contactSourceSchema} />
);

ContactSourceSelectField.createFieldState =
  TenantOptionsAdvancedSelectField.createFieldState;

export default ContactSourceSelectField;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EntityTagSelect from 'modules/entities/components/EntityTagSelect';
import { useDispatch, useSelector } from 'react-redux';
import getTenantOptionsIds from 'modules/tenant/selectors/getTenantOptionsIds';
import productAreas from 'modules/tenant/schemas/productAreas';
import { updateContact } from 'actions/contacts';
import getContactProperty from '../selectors/contacts/getContactProperty';

const ContactProductAreasField = ({ className, contactId }) => {
  const dispatch = useDispatch();

  const contactProductAreaIds = useSelector(state =>
    getContactProperty(state, contactId, 'preferred_product_areas'),
  )?.toJS();

  const productAreaIds = useSelector(state =>
    getTenantOptionsIds(state, 'productAreas'),
  )?.toJS();

  const [value, setValue] = useState(contactProductAreaIds ?? []);

  const handleChange = newValue => {
    dispatch(
      updateContact({
        id: contactId,
        contactId: contactId,
        contact: { product_area_ids: newValue },
      }),
    );
    setValue(newValue);
  };

  return (
    <EntityTagSelect
      className={className}
      entityIds={productAreaIds}
      entityType={productAreas.key}
      onChange={handleChange}
      value={value}
    />
  );
};

ContactProductAreasField.propTypes = {
  className: PropTypes.string,
  contactId: PropTypes.number.isRequired,
};

export default ContactProductAreasField;

import { compose, lifecycle, setDisplayName, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import fetchRatingDefinitionsListAction from '../../actions/ratings/fetchRatingDefinitionsList';
import shouldFetchRatingDefinitionsListSelector from '../../selectors/ratings/shouldFetchRatingDefinitionsList';
import getRatingDefinitionsList from '../../selectors/ratings/getRatingDefinitionsList';

/**
 * Fetches rating definitions if they need to be fetched from the server and
 * provides to the child component:
 * * `shouldFetchRatingDefinitionsList` {Boolean} whether the definition list need to
 *   be fetched from the server
 * * `ratingDefinitionsList` {Map} The state of the list of rating definitions.
 * * `fetchRatingDefinitionsList` {Function} A function that will initiate a fetch of the
 *   rating definitions.
 * * `fetchRatingDefinitionsListIfNeeded` {Function} A function that will initiate a fetch
 *   of the rating definitions only if they are needed from the server.
 * */
export default compose(
  setDisplayName('withRatingDefinitionsListFetched'),
  connect(
    state => ({
      shouldFetchRatingDefinitionsList: shouldFetchRatingDefinitionsListSelector(
        state,
      ),
      ratingDefinitionsList: getRatingDefinitionsList(state),
    }),
    dispatch => ({
      fetchRatingDefinitionsList: bindActionCreators(
        fetchRatingDefinitionsListAction,
        dispatch,
      ),
    }),
  ),

  withHandlers({
    fetchRatingDefinitionsListIfNeeded: ({
      fetchRatingDefinitionsList,
      shouldFetchRatingDefinitionsList,
    }) => () => {
      if (shouldFetchRatingDefinitionsList) {
        fetchRatingDefinitionsList();
      }
    },
  }),

  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchRatingDefinitionsListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchRatingDefinitionsListIfNeeded();
    },
  }),
);

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import objectToFormData from 'object-to-formdata';
import { outreachSchema } from '../../schema';

/**
 * A file provided by the `react-dropzone` component.
 * This is added by the Dropzone component
 * @typedef {File} DropzoneFile @see {@link https://developer.mozilla.org/en-US/docs/Web/API/File}
 */

/**
 * @typedef {OutreachDocument} OutreachUpdateDocument
 * @property {Boolean} destroy If the document should be destroyed in the database on update
 */

/**
 * Creates an action for updating an outreach
 * This renames `searches` (which is an array of IDs after being normalized) to `search_ids`
 * and `contact_method` to `contact_method_id` (which the server expects)
 * @param {Object} payload
 * @param {Object} payload.outreach The outreach properties
 * @param {Number} payload.outreach.id The outreach ID
 * @param {Number[]} payload.outreach.searches The array of search IDs associated with the outreach
 * @param {OutreachUpdateDocument{}<string, OutreachUpdateDocument>} [payload.outreach.documents]
 * An object of documents objects or uploaded files with an optional destroy flag
 * for existing documents
 */
export default ({
  outreach: { contact_method, documents, searches, ...outreachProps },
  ...rest
}) => {
  const formattedDocuments = Object.entries(documents)
    .filter(([_key, document]) => document.file || document.destroy)
    .map(([_key, document]) => ({
      ...(document.id ? { id: document.id } : {}),
      ...(document.file ? { file: document.file } : {}),
      _destroy: document.destroy,
    }))
    .reduce((o, document, index) => ({ ...o, [index]: document }), {});

  const outreachPayload = {
    contact_method_id: contact_method,
    documents_attributes: formattedDocuments,
    search_ids: searches,
    ...outreachProps,
  };

  // Appends all remaining outreach params to the FormData.
  // If the param is an object then convert it to JSON before appending.
  // Appends documents to the FormData as uploaded files (if file present) or JSON string.
  const body = objectToFormData({ outreach: outreachPayload });

  return updateEntity({
    ...rest,
    body: body,
    contentType: false,
    entityType: outreachSchema.key,
    id: outreachProps.id,
    responseSchema: { outreach: outreachSchema },
    url: routes.api_v1_outreach({
      id: outreachProps.id,
      query: {
        include_notifications: true,
      },
    }),
  });
};

import { selectContact } from './selectContact';
import fetchContact from './fetchContact';

const fetchAndSelectContact = payload => dispatch => {
  const id = payload.contactId;
  dispatch(selectContact(id));
  return dispatch(fetchContact(payload));
};

export default fetchAndSelectContact;

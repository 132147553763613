import React from 'react';
import PropTypes from 'prop-types';
import EditableSection from 'modules/forms/components/EditableSection';
import { useSelector } from 'react-redux';
import getCompensation from 'modules/compensations/selectors/getCompensation';
import ContactDesiredInterimCompensationForm from './ContactDesiredInterimCompensationForm';
import ContactCurrentInterimCompensationForm from './ContactCurrentInterimCompensationForm';
import ContactInterimCompensationView from './ContactInterimCompensationView';
import {
  CONTACT_CURRENT_INTERIM_COMPENSATION,
  CONTACT_DESIRED_INTERIM_COMPENSATION,
  CONTACT_INTERIM_COMPENSATION_TITLES,
  CONTACT_INTERIM_COMPENSATION_NAMES,
} from './constants';

const InterimCompensationEditableSection = ({
  contactId,
  interimCompensationId,
  interimCompensationName,
  ...rest
}) => {
  const interimCompensation = useSelector(state =>
    getCompensation(state, interimCompensationId),
  );

  const interimCompensationForm = {
    [CONTACT_CURRENT_INTERIM_COMPENSATION]: ContactCurrentInterimCompensationForm,
    [CONTACT_DESIRED_INTERIM_COMPENSATION]: ContactDesiredInterimCompensationForm,
  };

  const shouldShowCompensation = Boolean(
    interimCompensation &&
      (interimCompensation.get('currency') ||
        interimCompensation.get('notes') ||
        interimCompensation.get('length_of_term') ||
        interimCompensation.get('start_date') ||
        interimCompensation.get('end_date') ||
        Number.isFinite(interimCompensation.get('day_rate')) ||
        Number.isFinite(interimCompensation.get('day_rate_min')) ||
        Number.isFinite(interimCompensation.get('day_rate_max'))),
  );

  return (
    <EditableSection
      {...rest}
      contactId={contactId}
      formComponent={interimCompensationForm[interimCompensationName]}
      interimCompensation={interimCompensation}
      interimCompensationName={interimCompensationName}
      lastUpdated={interimCompensation?.get('last_updated')}
      title={CONTACT_INTERIM_COMPENSATION_TITLES[interimCompensationName]}
    >
      {shouldShowCompensation ? (
        <ContactInterimCompensationView
          interimCompensation={interimCompensation}
        />
      ) : null}
    </EditableSection>
  );
};

InterimCompensationEditableSection.propTypes = {
  /**
   * The ID of the contact to show interim compensation data for.
   */
  contactId: PropTypes.number.isRequired,
  /**
   * The ID of the interim compensation record.
   */
  interimCompensationId: PropTypes.number,
  /**
   * The interim compensation name type.
   */
  interimCompensationName: PropTypes.oneOf(CONTACT_INTERIM_COMPENSATION_NAMES)
    .isRequired,
};

export default InterimCompensationEditableSection;

import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  withHandlers,
} from 'recompose';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonSecondary';
import Card from '@thrivetrm/ui/components/Card';
import CompanyAliasInput from './AliasInput';

const ALIASES_LIMIT = 10;
/**
 * Renders a list of inputs for editing the aliases associated with a company.
 */
const AliasListField = ({
  disabled,
  onAliasAdd,
  onAliasChange,
  recordType,
  value,
}) => (
  <div className='AliasListField'>
    <ul className='list-unstyled'>
      {value.map(aliasOrId => (
        <li
          className='u-marginVertical-4'
          key={typeof aliasOrId === 'number' ? aliasOrId : aliasOrId.id}
        >
          <CompanyAliasInput
            disabled={disabled}
            onChange={onAliasChange}
            recordType={recordType}
            value={aliasOrId}
          />
        </li>
      ))}
    </ul>
    {/* do not allow the user to add more than 10 contact aliases */}
    {recordType === 'contact' && value.length >= ALIASES_LIMIT ? (
      <Card type='warning'>Limited to 10 aliases.</Card>
    ) : (
      <ButtonPrimary
        className='CompanyAliasListField__add'
        icon='add'
        isDisabled={disabled}
        isOutline={true}
        label='Add Alias'
        onClick={onAliasAdd}
        size='small'
      />
    )}
  </div>
);

AliasListField.propTypes = {
  /**
   * True to disable this input list.
   */
  disabled: PropTypes.bool,

  /**
   * Called when the "add alias" button is clicked"
   */
  onAliasAdd: PropTypes.func.isRequired,

  /**
   * Called when one of the underlying alias values is changed in some way.
   */
  onAliasChange: PropTypes.func.isRequired,

  recordType: PropTypes.oneOf(['contact', 'company']),
  /**
   * The alias values to render. These may be alias IDs, or an object describing the alias.
   */
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        /**
         * "New" items will be given a random string as an ID. Existing items will have a numeric
         * ID from the server.
         */
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
      }),
    ]).isRequired,
  ).isRequired,
};

/**
 * Creates the fieldstate for editing a company's aliases.
 * @param {String} name The name of the FieldState
 * @param {Number[]} values The values to initialize the field state with. These should be the IDs
 *   of the alias records.
 */
AliasListField.createFieldState = (name = 'aliases', values, validator) =>
  FieldState.create(name, values, validator, result =>
    result.map(alias => {
      if (typeof alias === 'number') {
        return { id: alias };
      }

      // eslint-disable-next-line no-underscore-dangle
      if (alias._new) {
        return { name: alias.name };
      }

      return alias;
    }),
  );

export default compose(
  setDisplayName('AliasListField'),
  setStatic('createFieldState', AliasListField.createFieldState),
  setPropTypes({
    disabled: PropTypes.bool,
  }),
  withFormGroup,
  asField(),
  withHandlers({
    onAliasAdd: ({ onChange, value }) => () => {
      onChange(value.concat({ _new: true, id: uniqueId(), name: '' }));
    },
    onAliasChange: ({ onChange, value }) => alias => {
      // eslint-disable-next-line no-underscore-dangle
      if (alias._new && alias._destroy) {
        // If we're destroying a new alias, just completely remove it as opposed to marking it
        // for deletion.
        onChange(value.filter(({ id }) => id !== alias.id));
        return;
      }

      // Find the matching alias in our list of values and replace it with the incoming changed
      // alias value
      onChange(
        value.map(aliasOrId => {
          const id = typeof aliasOrId === 'number' ? aliasOrId : aliasOrId.id;
          return alias.id === id ? alias : aliasOrId;
        }),
      );
    },
  }),
)(AliasListField);

import { compose } from 'recompose';
import routes from 'modules/routing/routes';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import {
  TAG_LIST_FETCH_START,
  TAG_LIST_FETCH_SUCCESS,
  TAG_LIST_FETCH_FAILURE,
} from './actionTypes';
import tagSchema from '../schemas/tag';
import smartTagSchema from '../schemas/smartTag';
/**
 * Creates an action for fetching the list of tags for the current tenant.
 */
export default compose(
  fetchAction,
  normalizeResponse({ smart_tags: [smartTagSchema], tags: [tagSchema] }),
  createAsyncActions({
    startActionType: TAG_LIST_FETCH_START,
    successActionType: TAG_LIST_FETCH_SUCCESS,
    failureActionType: TAG_LIST_FETCH_FAILURE,
  }),
  withOptions(({ searchType }) => ({
    url:
      searchType === TYPE_PIPELINE
        ? routes.api_v1_smart_tags()
        : routes.api_v1_tags(),
  })),
);

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, onlyUpdateForPropTypes } from 'recompose';
import classnames from 'classnames';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import withComponentId from './withComponentId';
import SortByDropdownMenu from './SortByDropdownMenu';

/**
 * A dropdown style button that, when clicked, shows a menu for choosing a sort direction for
 * a dataset. The button includes an icon indicating current sort direction.
 * Also displays a tooltip when hovered.
 */
const SortByDropdownButton = ({
  children,
  className,
  componentId,
  isAscending,
  isDisabled,
  onChange,
  options,
  tooltip,
  value,
}) => (
  <div className={className}>
    <SortByDropdownMenu
      isAscending={isAscending}
      onChange={onChange}
      options={options}
      value={value}
    />
    <OverlayTrigger
      overlay={<Tooltip id={`${componentId}-tooltip-sort`}>{tooltip}</Tooltip>}
      placement='top'
    >
      <button
        aria-expanded='false'
        aria-haspopup='true'
        className='btn btn-default u-inlineFlex u-flexJustify-c u-flexAlign-c'
        data-toggle='dropdown'
        disabled={isDisabled}
        title='Sort By'
        type='button'
      >
        <i
          className={classnames('fa', {
            'fa-sort-amount-asc': isAscending,
            'fa-sort-amount-desc': !isAscending,
          })}
        />
      </button>
    </OverlayTrigger>
    {children}
  </div>
);

SortByDropdownButton.propTypes = {
  /**
   * Additonal content to display after the dropdown button.
   * TODO: This is kinda weird. We should remove this probably, especially now that we can
   * just return the `SortByDropdownMenu` and `OverlayTrigger` side-by-side without the need for
   * a wrapping `div` component.
   * @deprecated Don't use this moving forward if possible.
   */
  children: PropTypes.node,

  /**
   * An optional class name to apply to the outer component.
   * @deprecated See `children`, same information applies here.
   */
  className: PropTypes.string,

  /**
   * A unique ID for this component instance (needed for the tooltip display)
   */
  componentId: PropTypes.string.isRequired,

  /**
   * True if the current sorting is in descending order; false otherwise.
   */
  isAscending: PropTypes.bool.isRequired,
  /**
   * if True the Sort button would be disabled.
   */
  isDisabled: PropTypes.bool,

  /**
   * Called when the sort selection has been changed.
   * This will be called with the selected key value from the `options` prop.
   * Note that this will be called even if the current `value` matches the option selected,
   * because that should indicate that the sort order should be toggled.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * A list of sort options (key-label pairs).
   * i.e.:
   * ```
   * options={{
   *   a: 'Apple',
   *   b: 'Banana',
   * }}
   * ```
   * The key represents a particular option's underlying value, while the value represents the
   * label that will be rendered.
   */
  options: PropTypes.objectOf(PropTypes.string).isRequired,

  /**
   * A tooltip shown when hovering over the button.
   */
  tooltip: PropTypes.string,

  /**
   * The currently selected sort value -- should match a key in the `options` prop.
   */
  value: PropTypes.string.isRequired,
};

SortByDropdownButton.defaultProps = {
  children: null,
  className: null,
  isDisabled: false,
  tooltip: 'Sort By',
};

export default compose(
  setDisplayName('SortByDropdownButton(enhanced)'),
  withComponentId(),
  onlyUpdateForPropTypes,
)(SortByDropdownButton);

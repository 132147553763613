import getContactProperty from './getContactProperty';

/**
 * TODO: Is this still being used? It shouldn't be I don't think...
 * Gets the primary company name for a contact
 * @param {Object} state
 * @param {Number} id The contact ID
 * @returns {String} The primary company name for the contact
 */
export default (state, id) =>
  getContactProperty(state, id, 'primary_company_name');

/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Table from './Table';
import DatasetView from '../DatasetView';

/**
 * An extension of the DatasetView component which renders a table as it's child to display
 * the underlying data.
 */
const TableView = ({
  className,
  currentPage,
  data,
  emptyState,
  errorAlert,
  limit,
  loadingIndicator,
  onLimitChange,
  onPageChange,
  overlayLoadingIndicator,
  totalCount,
  totalPages,

  ...tableProps
}) => (
  <DatasetView
    className={classnames('TableView', className)}
    currentPage={currentPage}
    data={data}
    emptyState={emptyState}
    errorAlert={errorAlert}
    hasData={
      Boolean(data) && (Array.isArray(data) ? data.length : data.count()) > 0
    }
    limit={limit}
    loadingIndicator={loadingIndicator}
    onLimitChange={onLimitChange}
    onPageChange={onPageChange}
    overlayLoadingIndicator={overlayLoadingIndicator}
    totalCount={totalCount}
    totalPages={totalPages}
  >
    <Table {...tableProps} className='table--rowHighlight' data={data || []} />
  </DatasetView>
);

TableView.propTypes = {
  ...Table.propTypes,

  /**
   * Optional class name to apply to the component.
   */
  className: PropTypes.string,

  /**
   * @see DatasetView.propTypes.currentPage
   */
  currentPage: DatasetView.propTypes.currentPage,

  /**
   * The data for the underlying table.
   */
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      count: PropTypes.func.isRequired,
      map: PropTypes.func.isRequired,
    }),
  ]),

  /**
   * @see DatasetView.propTypes.emptyState
   */
  emptyState: DatasetView.propTypes.emptyState,

  /**
   * @see DatasetView.propTypes.errorAlert
   */
  errorAlert: DatasetView.propTypes.errorAlert,

  /**
   * @see DatasetView.propTypes.limit
   */
  limit: DatasetView.propTypes.limit,

  /**
   * @see DatasetView.propTypes.loadingIndicator
   */
  loadingIndicator: DatasetView.propTypes.loadingIndicator,

  /**
   * @see DatasetView.propTypes.onLimitChange
   */
  onLimitChange: DatasetView.propTypes.onLimitChange,

  /**
   * @see DatasetView.propTypes.onPageChange
   */
  onPageChange: DatasetView.propTypes.onPageChange,

  /**
   * @see DatasetView.propTypes.overlayLoadingIndicator
   */
  overlayLoadingIndicator: DatasetView.propTypes.overlayLoadingIndicator,

  /**
   * @see DatasetView.propTypes.totalCount
   */
  totalCount: DatasetView.propTypes.totalCount,

  /**
   * @see DatasetView.propTypes.totalPages
   */
  totalPages: DatasetView.propTypes.totalPages,
};

TableView.defaultProps = {
  ...Table.defaultProps,
};

export default TableView;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose, setStatic, withHandlers } from 'recompose';
import Dropzone from 'react-dropzone';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import * as validators from 'modules/forms/validators';

/**
 * Wrap the Dropzone, because it still returns an array when multiple is false.
 */
const ResumeDropzone = withHandlers({
  onDrop: ({ maxFiles, multiple, onDrop, setErrorMessage }) => files => {
    const existingFiles = files || [];
    const currentFiles = multiple ? existingFiles : [existingFiles[0]];
    const totalFiles = currentFiles.length;
    if (totalFiles > maxFiles) {
      setErrorMessage(`You cannot upload more than ${maxFiles} files at once.`);
      return;
    }

    setErrorMessage('');
    onDrop(multiple ? currentFiles : currentFiles[0]);
  },
})(Dropzone);

const ResumeField = ({ maxFiles, multiple, onChange, parser, value }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const files = value ? [].concat(value) : [];
  const isEmpty = !files.length;

  return (
    <div>
      <ResumeDropzone
        activeClassName='contact-file-uploader-dropzone-active'
        className='contact-file-uploader-dropzone'
        maxFiles={maxFiles}
        multiple={multiple}
        onDrop={onChange}
        setErrorMessage={setErrorMessage}
      >
        <div className='text-center'>
          <div className='row'>
            {files.map((file, i) => (
              /* eslint-disable react/no-array-index-key */
              <div className='contact-resume-upload-file col-4' key={i}>
                <p className='fa fa-file-text-o fa-3x' />
                <small>{file.name}</small>
              </div>
              /* eslint-enable react/no-array-index-key */
            ))}
          </div>
          {isEmpty && (
            <div className='contact-document-upload-placeholder'>
              <span className='btn btn-link'>Choose a file</span>
              {parser === 'sovren' ? (
                <span> or drop a resume here.</span>
              ) : (
                <span>
                  &nbsp;or drop a LinkedIn PDF here.
                  <br />
                  Note: Supports LinkedIn Recruiter resumes.
                  <br />
                  Use our resume parser for other formats.
                </span>
              )}
            </div>
          )}
        </div>
      </ResumeDropzone>
      {errorMessage && (
        <p className='error' style={{ color: 'red' }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

ResumeField.propTypes = {
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  parser: PropTypes.oneOf(['sovren', 'linkedin']).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  ]),
};

ResumeField.defaultProps = {
  maxFiles: 10,
  multiple: false,
};

export default compose(
  setStatic('createFieldState', (name, value) =>
    FieldState.create(name, value, validators.requiredField('resume')),
  ),
  withFormGroup,
  asField(v => v, null),
)(ResumeField);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import investmentSchema from '../schema';

/**
 * Gets a property value from an investment record
 * @param {*} state
 * @param {Number} investmentId The ID of the investment entity.
 * @param {String|String[]} propertyName The property name or keypath of the investment entity.
 * @return {*} The property value, if found.
 */
export default (state, investmentId, propertyName) =>
  getEntityProperty(state, investmentSchema.key, investmentId, propertyName);

import { connect } from 'react-redux';

/**
 * A Higher order component that sorts a list of outreaches by the outreach date, ordering most
 * recent outreaches first.
 */
export default connect(
  (state, { outreaches }) => ({
    outreaches:
      outreaches &&
      outreaches
        .sortBy(
          outreach =>
            outreach.get('contacted_on') || outreach.get('created_at'),
        )
        .reverse(),
  }),
  {},
);

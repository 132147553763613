/* eslint-disable func-names */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Clipboard from 'clipboard';
import notify from './notify';
import autocomplete from './autocomplete';
import initSelect2 from './select2';
import initSelectsInModal from './modal_selects';

// Before reading the rest of this, you must visit this url:
//   http://www.clipartbest.com/cliparts/xTg/oqE/xTgoqE4Lc.png

const searchTeamSelects = [
  '.js-lead-internal-team-member-select',
  '.js-internal-team-members-select',
  '.js-client-team-members-select',
  '.js-origination-team-members-select',
];

const toggleOfficeLocation = function (role) {
  if (role === 'Partner' || role === 'Recruiter') {
    return $('.partner-recruiter-only').fadeIn();
  } else {
    return $('.partner-recruiter-only').fadeOut();
  }
};

const handleOfficeLocation = () =>
  $('select#user_role').change(() =>
    toggleOfficeLocation($('select#user_role option:selected').val()),
  );

const replaceModal = function (userForm) {
  $('#create-user-modal .modal-content').html(userForm);
  initSelectsInModal('#create-user-modal');
  handleOfficeLocation();
  return $('#create-user-modal input[data-autocomplete-path]').each(
    function () {
      return autocomplete($(this));
    },
  );
};

const displaySuccess = function () {
  $('#create-user-modal').modal('hide');
  return notify('New user successfully created.');
};

const handleInviteLink = function (inviteLink) {
  const $modal = $('#show-invite-link-modal');
  $modal.modal({ backdrop: 'static' });
  $modal.find('.invite-link').val(inviteLink);
  const clipboard = new Clipboard('.copy-link-button');
  $modal.find('.copy-link-button').on('click', e => e.preventDefault());
  return clipboard.on('success', _e => $modal.find('.copied-message').fadeIn());
};

export default {
  bindCopyLinkButton: () =>
    $('body').on('click', '#show-link-button', function (_e) {
      return $(this).parents('form').find('#user_skip_invitation').val(true);
    }),
  handleOfficeLocation: handleOfficeLocation,

  replaceUserModal: function (
    userPersisted,
    resource,
    userForm,
    resourceForm,
    inviteLink,
  ) {
    replaceModal(userForm);
    if (userPersisted) {
      if (resource === 'search') {
        $('#search-team').html(resourceForm);
        initSelect2('#search-team', searchTeamSelects);
      }

      displaySuccess();
      if (inviteLink) {
        handleInviteLink(inviteLink);
      }
    }
  },
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import Form from '@thrivetrm/ui/components/Form';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import { dateToISODateString } from '@thrivetrm/ui/utilities/dateTimeUtils';
import OffLimitsStatus from 'modules/off-limits/OffLimitsStatus';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import {
  useGetOffLimitsReasonsQuery,
  useUpdateOffLimitsCustomReasonMutation,
} from 'services/apiV1/offLimits';
import ContactQvpHeader from '../ContactQvpHeader';
import QuickViewPanelHeader from '../QuickViewPanelHeader';
import useQuickView from '../useQuickView';

const SEVERITY_TO_CATEGORY_MAP = {
  Minor: 'info',
  Moderate: 'warning',
  Major: 'danger',
};
const OffLimitsArchiveForm = () => {
  const dispatch = useDispatch();
  const { close, navigateTo, pathParams, title } = useQuickView();
  const { reasonId, recordId, recordType } = pathParams;
  const candidacyId = window.location.pathname.includes('searches')
    ? window.location.pathname.split('/')[4]
    : null;
  const [currentReason, setCurrentReason] = useState(null);
  const [
    updateOffLimitsCustomReason,
    { isLoading: isArchivingOffLimits },
  ] = useUpdateOffLimitsCustomReasonMutation();
  const {
    data: reasons,
    error: _loadReasonsError,
    isLoading: isLoadingReasons,
  } = useGetOffLimitsReasonsQuery({
    recordId: recordId,
    recordType: recordType,
  });

  useEffect(() => {
    const offLimitReason = reasons?.find(reason => reason.id === reasonId);

    const localizedStartDate = offLimitReason?.startDate
      ? dateToISODateString(new Date(offLimitReason.startDate))
      : null;
    const localizedEndDate = offLimitReason?.endDate
      ? dateToISODateString(new Date(offLimitReason.endDate))
      : dateToISODateString(new Date());

    setCurrentReason({
      reasonName: offLimitReason?.reasonName,
      severity: offLimitReason?.severity,
      endDate: localizedEndDate,
      startDate: localizedStartDate,
      search: offLimitReason?.search,
    });
  }, [reasons, reasonId]);

  const navigateBack = () => {
    navigateTo(`/${recordType}/${recordId}/off-limits`);
  };

  const handleSubmit = formState => {
    updateOffLimitsCustomReason({
      reasonData: { end_date: formState.endDate },
      reasonId: reasonId,
      recordId: recordId,
      recordType: recordType,
      candidacyId: candidacyId,
    })
      .unwrap()
      .then(() => {
        navigateBack();
        dispatch(toastSuccess('The off limits has been archived'));
      })
      .catch(() => {
        dispatch(toastError('Could not archive the off limits'));
      });
  };

  return (
    <>
      {recordType === 'company' ? (
        <SidePanel.Header
          className='u-marginBottom-12'
          onBack={navigateBack}
          onClose={close}
          title={title}
        />
      ) : (
        <ContactQvpHeader contactId={recordId} onBack={navigateBack} />
      )}
      <QuickViewPanelHeader title='Archive Off Limits' />
      <Form onSubmit={handleSubmit}>
        <SidePanel.Body>
          <LoadingContainer isLoading={isLoadingReasons}>
            {currentReason?.reasonName ? (
              <>
                <div className='u-flex u-flexAlign-c'>
                  <OffLimitsStatus
                    severity={SEVERITY_TO_CATEGORY_MAP[currentReason.severity]}
                    status={currentReason.reasonName}
                  />
                  {currentReason?.search?.stage ? (
                    <span className='u-marginLeft-16 u-fontSize-small'>
                      {`(${currentReason.search.name}) - ${currentReason.search.stage}`}
                    </span>
                  ) : null}
                </div>
                <div className='u-flex u-marginVertical-16'>
                  <Form.DateInput
                    className='u-marginRight-8'
                    initialValue={currentReason.startDate}
                    inputWidth='full'
                    isDisabled={true}
                    label='Applied On'
                    name='startDate'
                    rules={{ required: { value: true } }}
                  />
                  <Form.DateInput
                    className='u-marginLeft-8'
                    data-testid='off-limits-expires-on-field'
                    initialValue={currentReason.endDate}
                    inputWidth='full'
                    isPinnedRight={true}
                    label='Expires On'
                    minDate={currentReason.startDate}
                    name='endDate'
                    placeholderText='Select a date'
                    rules={{
                      required: {
                        value: true,
                        message: 'Selecting an expires on date is required',
                      },
                    }}
                  />
                </div>
              </>
            ) : null}
          </LoadingContainer>
        </SidePanel.Body>
        <SidePanel.Footer>
          <ButtonPrimary
            isOutline={true}
            label='Cancel'
            onClick={navigateBack}
          />
          <Form.SubmitButton isLoading={isArchivingOffLimits} label='Archive' />
        </SidePanel.Footer>
      </Form>
    </>
  );
};

OffLimitsArchiveForm.propTypes = {};

export default OffLimitsArchiveForm;

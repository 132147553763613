/* eslint-disable react/prop-types */
// ^ We're doing weird component stuff inside of the objects in this file.
import React from 'react';
import { useSelector } from 'react-redux';
import getCompanyProperty from 'modules/companies/selectors/getCompanyProperty';
import getCompanyId from 'modules/target-companies/selectors/targetCompanies/getCompanyId';
import TargetCompanyCandidateCount from '../TargetCompanyCandidateCount';
import TargetCompanyIndustryNames from '../TargetCompanyIndustryNames';
import TargetCompanyName from '../TargetCompanyName';
import TargetCompanyLocationName from '../TargetCompanyLocationName';
import NoTargetCompanyCandidateCount from '../NoTargetCompanyCandidateCount';
import {
  COLUMN_COMPANY_NAME,
  COLUMN_LOCATION_NAME,
  COLUMN_INDUSTRY,
  COLUMN_CANDIDATE_COUNT,
  COLUMN_COMPANY_NAME_WITH_CANDIDATE_COUNT,
  COLUMN_LATEST_EMPLOYEE_COUNT,
  SORT_BY_COMPANY,
  SORT_BY_COMPANY_LOCATION_NAME,
  SORT_BY_CANDIDATES_COUNT,
  SORT_BY_LATEST_EMPLOYEE_COUNT,
} from '../../constants';
import TargetCompanyNameColumn from '../TargetCompanyNameColumn';

/**
 * Defines all of the available columns that can be used by the Searches table.
 * Note that each `key` should be unique -- otherwise if two columns that have the same
 * key are used it will cause an error because it used to set the `key` on the react
 * component.
 */
export default {
  [COLUMN_COMPANY_NAME]: {
    key: SORT_BY_COMPANY,
    title: 'Company',
    isSortable: true,
    Cell: ({ datum }) =>
      datum === 0 ? (
        'No Target Company'
      ) : (
        <TargetCompanyName targetCompanyId={datum} />
      ),
  },

  [COLUMN_LOCATION_NAME]: {
    key: SORT_BY_COMPANY_LOCATION_NAME,
    title: 'Location',
    isSortable: true,
    Cell: ({ datum }) =>
      datum === 0 ? null : (
        <TargetCompanyLocationName targetCompanyId={datum} />
      ),
  },

  [COLUMN_INDUSTRY]: {
    key: COLUMN_INDUSTRY,
    title: 'Industry',
    isSortable: false,
    Cell: ({ datum }) =>
      datum === 0 ? null : (
        <TargetCompanyIndustryNames targetCompanyId={datum} />
      ),
  },

  [COLUMN_CANDIDATE_COUNT]: {
    key: SORT_BY_CANDIDATES_COUNT,
    className: 'TargetCompanyTableColumn--CandidateCount',
    title: 'Candidates',
    isSortable: true,
    Cell: ({ datum }) =>
      datum === 0 ? (
        '0'
      ) : (
        <TargetCompanyCandidateCount targetCompanyId={datum} />
      ),
  },

  [COLUMN_COMPANY_NAME_WITH_CANDIDATE_COUNT]: {
    key: COLUMN_COMPANY_NAME_WITH_CANDIDATE_COUNT,
    sortKey: SORT_BY_COMPANY,
    className: 'TargetCompanyTableColumn--CompanyNameCandidateCount',
    title: 'Company',
    isSortable: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ datum, onTargetCompanySelect, orphanCount }) =>
      datum === 0 ? (
        <div>
          <span className='TargetCompanyName'>No Target Company</span>
          <NoTargetCompanyCandidateCount
            onClick={onTargetCompanySelect}
            value={orphanCount}
          />
        </div>
      ) : (
        <TargetCompanyNameColumn
          onCandidateCountClick={onTargetCompanySelect}
          targetCompanyId={datum}
        />
      ),
  },

  [COLUMN_LATEST_EMPLOYEE_COUNT]: {
    key: SORT_BY_LATEST_EMPLOYEE_COUNT,
    title: 'Latest Employee Count',
    isSortable: true,
    Cell: ({ datum }) => {
      const companyId = useSelector(state => getCompanyId(state, datum));
      const latestEmployeeCount = useSelector(state =>
        getCompanyProperty(state, companyId, 'latest_employee_count'),
      );
      return companyId && latestEmployeeCount ? (
        <span>{latestEmployeeCount}</span>
      ) : null;
    },
  },
};

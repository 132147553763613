// this function is consumed by the searches/index and companies/index
// handles whether or not the submit event for the simple search input
// gets processed, depending on input presence and the previous search term
module.exports = (inputSelector, formSelector) => {
  jQuery(() => {
    const $simpleSearchInput = $(inputSelector);
    const $simpleSearchForm = $(formSelector);
    $simpleSearchForm.on('submit', event => {
      const inputValue = $simpleSearchInput.val();
      const inputEmpty = !inputValue.length;
      let lastSearchTerm = $simpleSearchInput.data('lastSearchTerm') || '';
      lastSearchTerm = lastSearchTerm.toString();
      const lastSearchTermPresent = lastSearchTerm && lastSearchTerm.length;
      const lastSearchTermEqual = lastSearchTerm === inputValue;
      const emptyWithoutLastSearchTerm = inputEmpty && !lastSearchTermPresent;

      if (emptyWithoutLastSearchTerm || lastSearchTermEqual) {
        event.preventDefault();
      }
    });
  });
};

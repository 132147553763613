import getUserPermission from 'modules/auth/selectors/getUserPermission';

/**
 * Gets a value indicating whether the current user has permission to edit the details of
 * a search.
 * @param {Object} state
 * @return {Boolean} True if the current user is allowed to edit search details;
 *   otherwise, false.
 */
const canViewSearchDetails = state =>
  getUserPermission(state, 'view_details_tab', false);

export default canViewSearchDetails;

import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import CandidacyListTableView from './CandidacyListTableView';
import {
  PARENT_CONTACT,
  SORT_ASCENDING_DEFAULTS,
  SORT_BY_AGE,
} from '../../constants';

/**
 * A CandidacyListTableView for displaying candidacies a contact belongs.
 */
export default compose(
  setDisplayName('ContactCandidacyListTableView'),
  setPropTypes({
    /**
     * The columns to render
     */
    columns: PropTypes.array.isRequired,

    /**
     * The ID of the contact to show candidacies for.
     */
    contactId: PropTypes.number.isRequired,
  }),

  defaultProps({
    initialSortAscending: SORT_ASCENDING_DEFAULTS[SORT_BY_AGE],
    initialSortBy: SORT_BY_AGE,
  }),

  withClassName('ContactCandidacyListTableView'),

  withProps(({ contactId }) => ({
    parentId: contactId,
    parentType: PARENT_CONTACT,
  })),
)(CandidacyListTableView);

import deleteEntity from 'modules/entities/actions/deleteEntity';
import routes from 'modules/routing/routes';
import noteSchema from '../schema';

/**
 * Creates an action for deleting a note
 * @param {Object} payload
 * @param {Number} payload.id The note ID.
 */
export default ({ id, ...rest }) =>
  deleteEntity({
    ...rest,
    id: id,
    entityType: noteSchema.key,
    url: routes.api_v1_note({ id: id }),
  });

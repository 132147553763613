import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import taskSchema from '../schema';

/**
 * Gets the title of a task by it's ID
 * @param {*} state
 * @param {Number} taskId The ID of the task.
 * @return {String} The task title
 */
export default (state, taskId) =>
  getEntityProperty(state, taskSchema.key, taskId, 'subject');

import {
  compose,
  defaultProps,
  setDisplayName,
  setStatic,
  withProps,
} from 'recompose';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import provinceSchema from 'modules/tenant/schemas/provinces';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';

/**
 * A dropdown/select component for choosing from the list of Canadian provinces.
 */
export default compose(
  setDisplayName('CanadaProvinceSelectField'),
  withClassName('CanadaProvinceSelectField'),
  setStatic('createFieldState', TenantOptionsSelectField.createFieldState),
  defaultProps({
    clearable: true,
  }),
  withProps(({ shouldRequireFields }) => ({
    schema: provinceSchema,
    label: `${shouldRequireFields ? '*' : ''}Province`,
  })),
)(TenantOptionsSelectField);

import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setStatic } from 'recompose';
import FieldState from 'modules/forms/FieldState';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import QuestionAnswerField from './QuestionAnswerField';

/**
 * Renders a list of QuestionAnswerField for editing the questionAnswers.
 */
const QuestionAnswerListField = ({
  fieldState,
  onChange,
  questionAnswers,
  ...fieldProps
}) => {
  const handleNestedFieldChange = nestedField => {
    onChange(fieldState.setNestedField(nestedField));
  };

  return (
    <div className='QuestionAnswerListField'>
      {questionAnswers
        .map(questionAnswer => {
          const questionAnswerId = questionAnswer.get('id');

          // Since order is not preserved when creating nested fieldState from an Immutable List,
          // loop directly over the questionAnswers List and then find the corresponding fieldState to
          // pass through to QuestionAnswerField
          const nestedField = fieldState.getNestedField(
            String(questionAnswerId),
          );

          return (
            <div key={questionAnswerId}>
              <QuestionAnswerField
                {...fieldProps}
                fieldState={nestedField}
                onChange={handleNestedFieldChange}
              />
            </div>
          );
        })
        .toArray()}
    </div>
  );
};

QuestionAnswerListField.propTypes = {
  fieldState: fieldStatePropType.isRequired,
  onChange: PropTypes.func.isRequired,
  questionAnswers: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

/**
 * Creates the fieldstate for editing a questionAnswer list.
 * @param {String} name The name of the FieldState
 * @param {Number[]} values The values to initialize the fieldlist state with.
 * These should be the IDs of the questionAnswers records.
 */
QuestionAnswerListField.createFieldState = (
  name = 'question_answers',
  values,
) =>
  FieldState.createNested(
    name,
    values.map(questionAnswer =>
      QuestionAnswerField.createFieldState(
        String(questionAnswer.get('id')),
        questionAnswer,
      ),
    ),
    null,
    result => Object.keys(result).map(id => result[id]),
  );

export default compose(
  setDisplayName('QuestionAnswerListField(enhanced)'),
  setStatic('createFieldState', QuestionAnswerListField.createFieldState),
)(QuestionAnswerListField);

import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import {
  TYPE_JOB_SEARCH,
  SEARCH_TYPES,
  TYPE_PIPELINE,
} from 'modules/searches/constants';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import CandidaciesPerStageDonutChart from 'modules/searches/components/candidacyStats/CandidaciesPerStageDonutChart';
import SearchCompensationAveragesList from 'modules/searches/components/SearchCompensationAveragesList';
import SearchCompensationFullList from 'modules/searches/components/SearchCompensationFullList';
import SearchSidebarLink from 'modules/searches/components/SearchSidebarLink';
import canViewHiddenCandidatesSelector from 'modules/searches/selectors/canViewHiddenCandidates';
import StagesFilterToggleButton from 'modules/search-stages/components/StagesFilterToggleButton';
import isClientUser from 'modules/auth/selectors/isClientUser';

/**
 * Renders just the header portion of the search sidebar's search content:
 *  - The donut chart that displays the candidates per stage breakdown
 *  - A toggle link to show/hide the details popover
 *  - The average compensation listing (if permitted)
 */
const SidebarSearchContentHeader = ({
  activeStagesOnly,
  canViewHiddenCandidates,
  handleOnChartData,
  hasAllCompensations,
  searchId,
  searchPage,
  searchType,
  setActiveStagesOnly,
}) => {
  const isClient = useSelector(state => isClientUser(state));

  return (
    <div className='SidebarSearchContentHeader row'>
      <div className='col-6' key='chart'>
        <CandidaciesPerStageDonutChart
          activeOnly={activeStagesOnly !== false}
          onData={activeStagesOnly === null && handleOnChartData}
          searchId={searchId}
        />
        {searchType === TYPE_PIPELINE ? null : (
          <StagesFilterToggleButton
            bsSize='xs'
            disabled={activeStagesOnly === null}
            onChange={setActiveStagesOnly}
            value={activeStagesOnly !== false}
          />
        )}
      </div>
      <div className='col-6' key='other'>
        <div className='SidebarSearchContentHeader__detailToggle'>
          <SearchSidebarLink
            aria-label='Close'
            className='btn btn-secondary btn-block'
            isToggle={true}
            popover='details'
            searchId={searchId}
            searchPage={searchPage}
          >
            {searchType === TYPE_JOB_SEARCH ? 'Search Details' : 'Details'}
          </SearchSidebarLink>
        </div>
        {hasAllCompensations && !isClient ? (
          <SearchCompensationFullList
            activeOnly={activeStagesOnly !== false}
            canViewHiddenCandidates={canViewHiddenCandidates}
            searchId={searchId}
          />
        ) : (
          <SearchCompensationAveragesList
            activeOnly={activeStagesOnly !== false}
            canViewHiddenCandidates={canViewHiddenCandidates}
            searchId={searchId}
          />
        )}
      </div>
    </div>
  );
};

SidebarSearchContentHeader.propTypes = {
  activeStagesOnly: PropTypes.bool,
  hasAllCompensations: PropTypes.bool.isRequired,
  searchId: PropTypes.number.isRequired,
  searchPage: PropTypes.string.isRequired,
  searchType: PropTypes.oneOf(SEARCH_TYPES),
  setActiveStagesOnly: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('SidebarSearchContentHeader(enhanced)'),
  setPropTypes({
    searchId: SidebarSearchContentHeader.propTypes.searchId,
    searchPage: SidebarSearchContentHeader.propTypes.searchPage,
    searchType: SidebarSearchContentHeader.propTypes.searchType,
  }),

  connect(
    state => ({
      canViewHiddenCandidates: canViewHiddenCandidatesSelector(state),
    }),
    {},
  ),

  // We want this state value initialized to true unless there are no current candidates in
  // an active stage. But we won't know that until the stats have been loaded. So we
  // initialize this to `null`, then use the onData handler from the chart to check to see
  // if there are any active candidates, and initialize the value then.
  withState('activeStagesOnly', 'setActiveStagesOnly', null),
  withHandlers({
    handleOnChartData: ({ activeStagesOnly, setActiveStagesOnly }) => ({
      totalActiveCandidates,
    }) =>
      activeStagesOnly === null &&
      setActiveStagesOnly(totalActiveCandidates !== 0),
  }),
  withFeatureCheck(
    'field.search.display_all_average_compensation',
    'hasAllCompensations',
  ),
)(SidebarSearchContentHeader);

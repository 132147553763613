import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import addressSchema from '../addressSchema';

/**
 * An action for updating an address.
 * @param {Object} options
 * @param {Object} options.address The address to create
 * @param {String} options.address.address_line_1
 * @param {String} options.address.address_line_2
 * @param {String} options.address.city
 * @param {String} options.address.country_code
 * @param {String} options.address.label
 * @param {String} options.address.postal_code
 * @param {String} options.address.state
 * @param {String} [options.transactionId] A unique ID used to track the request.
 */
export default ({ address, addressId, transactionId }) =>
  updateEntity({
    body: { address: address },
    transactionId: transactionId,
    entityType: addressSchema.key,
    responseSchema: { address: addressSchema },
    url: routes.api_v1_address({ id: addressId }),
  });

import getRatingDefinitionsList from './getRatingDefinitionsList';

/**
 * Determines if the list of rating definitions should be fetched from the server.
 * @param {Object} state
 * @returns {Boolean} True if the list needs to be fetched from the server;
 *   Otherwise, false.
 */
export default state => {
  const definitions = getRatingDefinitionsList(state);

  if (!definitions) {
    return true;
  }

  if (
    definitions.getIn(['_meta', 'isFetching']) ||
    definitions.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return (
    !definitions.has('ids') ||
    Boolean(definitions.getIn(['_meta', 'isInvalidated']))
  );
};

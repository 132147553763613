import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName } from 'recompose';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import RichTextField from 'components/forms/richtext/RichTextField';
import withDraftStorage from 'components/forms/withDraftStorage';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import { useApiPatch, useApiPost } from '@thrivetrm/ui/hooks/useApi';
import routes from 'modules/routing/routes';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addEmailSignature } from 'modules/emailSignature/action/emailSignatureAction';
import withEmailSignatureForm from './withEmailSignatureForm';

const srcRegex = /src="[^"]*"/;
const EmailSignatureForm = ({
  clearDraftStorage,
  draft,
  formState,
  onDraftChange,
  onFieldStateChange,
  ...fieldProps
}) => {
  const dispatch = useDispatch();
  const emailSignature = useSelector(state =>
    state.user.get('email_signature'),
  );

  const currentEmailSignature = useSelector(
    state => state.currentEmailSignature?.emailSignature,
  );

  const [createEmailSignature, isCreatingEmailSignature] = useApiPost(
    routes.api_v1_email_signatures(),
  );
  const [editEmailSignature, isEditingEmailSignature] = useApiPatch(
    routes.api_v1_email_signature({
      id: emailSignature?.id || currentEmailSignature?.id,
    }),
  );

  const handleSubmit = clearSignature => {
    const srcExists = srcRegex.test(formState.getFieldValue());
    if (clearSignature) {
      onFieldStateChange(RichTextField.createFieldState('email_signature', ''));
    }
    const payload = {
      email_signature: {
        content: clearSignature ? '' : formState.getFieldValue(),
        ...(srcExists ? {} : { remove_image: true }),
      },
    };
    if (emailSignature || currentEmailSignature) {
      editEmailSignature(payload, {
        onSuccess: data => {
          dispatch(addEmailSignature(data));
          dispatch(
            toastSuccess(
              clearSignature
                ? 'Email signature cleared successfully'
                : 'Email signature updated successfully',
            ),
          );
          clearDraftStorage();
        },
        onError: data => {
          dispatch(toastError(data || 'Something went wrong'));
        },
      });
    } else {
      createEmailSignature(payload, {
        onSuccess: data => {
          dispatch(addEmailSignature(data));
          dispatch(toastSuccess('Email signature added successfully'));
          clearDraftStorage();
        },
        onError: data => {
          dispatch(toastError(data || 'Something went wrong'));
        },
      });
    }
  };

  return (
    <Form formState={formState} onSubmit={handleSubmit}>
      <h2 className='u-marginBottom-16'>Set Email Signature</h2>
      <p>
        Your email signature will be appended to all outgoing emails sent using
        Thrive. The email signature you use in your email client will not be
        effected.
      </p>
      <RichTextField
        {...fieldProps}
        disabled={formState.isSubmitting()}
        fieldState={formState.getFieldState()}
        label='Signature'
        lastUpdatedTime={draft?.content && draft?.lastUpdatedTime}
        onChange={onFieldStateChange}
        onDraftChange={onDraftChange}
        shouldShowImageOption={true}
        shouldShowLinkOption={true}
      />
      <FormErrorMessage formState={formState} />
      <div className='Form__footer u-flex'>
        <ButtonSecondary
          isDisabled={
            formState.getFieldValue() === '' ||
            isCreatingEmailSignature ||
            isEditingEmailSignature
          }
          label='Clear'
          onClick={() => handleSubmit(true)}
        />
        <ButtonPrimary
          isDisabled={
            !draft?.content ||
            isCreatingEmailSignature ||
            isEditingEmailSignature
          }
          isLoading={isCreatingEmailSignature || isEditingEmailSignature}
          label='Save'
          onClick={handleSubmit}
        />
      </div>
    </Form>
  );
};

EmailSignatureForm.propTypes = {
  clearDraftStorage: PropTypes.func,
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),
  formState: formStatePropType.isRequired,
  onDraftChange: PropTypes.func,
  onFieldStateChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('EmailSignatureForm(enhanced))'),
  withDraftStorage(),
  withEmailSignatureForm,
  mapProps(({ clearDraftStorage, ...rest }) => ({
    clearDraftStorage: clearDraftStorage,
    ...rest,
  })),
)(EmailSignatureForm);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import routes from 'modules/routing/routes';
import { useApiGet } from '@thrivetrm/ui/hooks/useApi';
import {
  TYPE_CLIENT,
  TYPE_RECRUITER,
  TYPE_RESEARCHER,
} from 'modules/candidacy-assessments/constants';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import getSearchProperty from 'modules/searches/selectors/getSearchProperty';
import isClient from 'modules/auth/selectors/isClientUser';
import { useSelector } from 'react-redux';
import canCreateGuestAssessmentInviteSelector from 'modules/candidacies/selectors/canCreateGuestAssessmentInvite';
import { TYPE_JOB_SEARCH, TYPE_TALENT_POOL } from 'modules/searches/constants';
import CandidacyCreateGuestAssessmentInviteButton from 'modules/candidacy-views/guestAssessments/CandidacyCreateGuestAssessmentInviteButton';
import CandidacyCreateGuestAssessmentLinkButton from 'modules/candidacy-views/guestAssessments/CandidacyCreateGuestAssessmentLinkButton';
import getCandidacyProperty from 'modules/candidacies/selectors/getCandidacyProperty';
import isFetchingEntity from 'modules/entities/selectors/isFetchingEntity';
import TrueBrowserData from 'modules/core/components/TrueBrowserData';
import { useGetTemplateQuery } from 'services/apiV1/assessment';
import CandidacyAssessmentList from './CandidacyAssessmentList';

const CandidacyAssessmentsPanel = ({ candidacyId, contactId, searchId }) => {
  const hasResearcherAssessments = useFeatureCheck(
    'feature.researcher_assessments',
  );
  const canCreateGuestAssessmentInvite = useSelector(
    canCreateGuestAssessmentInviteSelector,
  );
  const hasAssessmentTemplates = useFeatureCheck(
    'feature.assessment_templates',
  );

  const searchType = useSelector(state =>
    getCandidacyProperty(state, candidacyId, 'search_type'),
  );
  const templateId = useSelector(state =>
    getSearchProperty(state, searchId, 'assessment_template_id'),
  );
  const shouldShowCreateGuestAssessmentInviteButton =
    canCreateGuestAssessmentInvite &&
    searchType === TYPE_JOB_SEARCH &&
    hasAssessmentTemplates;
  const shouldShowCreateGuestAssessmentLinkButton =
    canCreateGuestAssessmentInvite && searchType === TYPE_JOB_SEARCH;
  const searchAssessmentId = useSelector(state =>
    getSearchProperty(state, searchId, 'assessment_template_id'),
  );
  const isLoadingSearch = useSelector(state =>
    isFetchingEntity(state, 'searches', searchId),
  );
  const isClientUser = useSelector(state => isClient(state));
  const [
    loadCandidacyAssessments,
    isLoadingCandidacyAssessments,
    _candidacyAssessmentsLoadError,
    candidacyAssessments,
  ] = useApiGet(
    routes.api_v1_candidacy_candidacy_assessments({
      candidacy_id: candidacyId,
    }),
  );
  const { data: template, isFetching: isLoadingTemplate } = useGetTemplateQuery(
    templateId,
    {
      skip: !templateId,
    },
  );

  const clientAssessments = candidacyAssessments?.assessments.filter(
    assessment => assessment.type === TYPE_CLIENT,
  );

  const recruiterAssessments = candidacyAssessments?.assessments.filter(
    assessment => assessment.type === TYPE_RECRUITER,
  );

  const reasearcherAssessments = candidacyAssessments?.assessments.filter(
    assessment => assessment.type === TYPE_RESEARCHER,
  );

  useEffect(() => {
    if (searchAssessmentId) {
      loadCandidacyAssessments();
    }
  }, [searchAssessmentId, loadCandidacyAssessments, candidacyId]);

  return (
    <div
      className='CandidacyAssessmentsPanel'
      data-testid='candidacy-assessments-panel'
    >
      <LoadingContainer
        isLoading={Boolean(
          isLoadingCandidacyAssessments || isLoadingSearch || isLoadingTemplate,
        )}
        minHeight='48vh'
        overlayColor='gray5'
      >
        {!isLoadingCandidacyAssessments && searchAssessmentId && template ? (
          <>
            {shouldShowCreateGuestAssessmentInviteButton ? (
              <CandidacyCreateGuestAssessmentInviteButton
                candidacyId={candidacyId}
                searchId={searchId}
              />
            ) : null}
            {!shouldShowCreateGuestAssessmentInviteButton &&
            shouldShowCreateGuestAssessmentLinkButton ? (
              <CandidacyCreateGuestAssessmentLinkButton
                candidacyId={candidacyId}
                searchId={searchId}
              />
            ) : null}
            {hasResearcherAssessments ? (
              <TrueBrowserData
                dataPath={{
                  search: searchId,
                  candidacy: candidacyId,
                  assessmentType: TYPE_RESEARCHER,
                }}
              >
                <CandidacyAssessmentList
                  assessments={reasearcherAssessments}
                  assessmentType={TYPE_RESEARCHER}
                  canAddNewAssessment={!reasearcherAssessments?.length}
                  candidacyId={candidacyId}
                  contactId={contactId}
                  loadCandidacyAssessments={loadCandidacyAssessments}
                  searchId={searchId}
                  template={template}
                />
              </TrueBrowserData>
            ) : null}
            <TrueBrowserData
              dataPath={{
                search: searchId,
                candidacy: candidacyId,
                assessmentType: TYPE_RECRUITER,
              }}
            >
              <CandidacyAssessmentList
                assessments={recruiterAssessments}
                assessmentType={TYPE_RECRUITER}
                canAddNewAssessment={
                  !recruiterAssessments?.length && !isClientUser
                }
                candidacyId={candidacyId}
                contactId={contactId}
                loadCandidacyAssessments={loadCandidacyAssessments}
                searchId={searchId}
                template={template}
              />
            </TrueBrowserData>
            <TrueBrowserData
              dataPath={{
                search: searchId,
                candidacy: candidacyId,
                assessmentType: TYPE_CLIENT,
              }}
            >
              <CandidacyAssessmentList
                assessments={clientAssessments}
                assessmentType={TYPE_CLIENT}
                candidacyId={candidacyId}
                contactId={contactId}
                loadCandidacyAssessments={loadCandidacyAssessments}
                searchId={searchId}
                template={template}
              />
            </TrueBrowserData>
          </>
        ) : null}
        {!searchAssessmentId && !isLoadingSearch ? (
          <div className='u-textAlign-c u-backgroundColor-gray20 u-padding-12'>
            <p className='u-margin-n'>
              {isClientUser ? (
                'There is no assessment template set up for this search, please contact the search team to set it up'
              ) : (
                <>
                  There is no assessment template set up for this search,{' '}
                  <a
                    href={`/${
                      searchType === TYPE_TALENT_POOL
                        ? 'talent_pools'
                        : 'searches'
                    }/${searchId}/edit#assessments`}
                  >
                    please click here
                  </a>{' '}
                  to set one up.
                </>
              )}
            </p>
          </div>
        ) : null}
      </LoadingContainer>
    </div>
  );
};

CandidacyAssessmentsPanel.propTypes = {
  candidacyId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
  searchId: PropTypes.number.isRequired,
};

export default CandidacyAssessmentsPanel;

import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import { SortDirections } from './tableConstants';
import TableHeaderCell from './TableHeaderCell';

const TableHeader = ({
  hasSelectedAll,
  hasSelectedSome,
  headers,
  isLoading,
  onSelect,
  onSortChange,
  sortDirection,
  sortKey,
}) => (
  <thead>
    <tr>
      {onSelect && (
        <th className='table__selection-cell u-paddingTop-4'>
          <Checkbox
            data-testid='table header checkbox'
            isChecked={hasSelectedAll}
            isDisabled={isLoading}
            isIndeterminate={hasSelectedSome}
            onChange={onSelect}
          />
        </th>
      )}
      {headers.map(({ isSortable, label, name, width }, index) => (
        <TableHeaderCell
          isFixed={index === 0}
          isSortable={onSortChange ? isSortable : false}
          key={name}
          label={label}
          name={name}
          onSortChange={onSortChange}
          sortDirection={onSortChange ? sortDirection : null}
          sortKey={onSortChange ? sortKey : null}
          width={width}
        />
      ))}
    </tr>
  </thead>
);

TableHeader.defaultProps = {
  hasSelectedAll: false,
  hasSelectedSome: false,
  headers: null,
  isLoading: false,
  onSelect: null,
  onSortChange: null,
  sortDirection: null,
  sortKey: null,
};

TableHeader.propTypes = {
  hasSelectedAll: PropTypes.bool,
  hasSelectedSome: PropTypes.bool,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      isSortable: PropTypes.bool,
      label: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onSortChange: PropTypes.func,
  sortDirection: PropTypes.oneOf(Object.values(SortDirections)),
  sortKey: PropTypes.string,
};

export default TableHeader;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import classnames from 'classnames';
import { compose, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';

import connectTaskActions from './connectTaskActions';
import withTasksFetched from './withTasksFetched';
import SortAndFilterToolbar from './SortAndFilterToolbar';
import TaskFilters from './TaskFilters';
import TaskFormModal from './TaskFormModal';
import TaskMessage from './TaskMessage';
import TaskTableView from './TaskTableView';
import { MY_TASKS, ASSIGNED_TASKS, SORT_BY_DUE_DATE } from '../constants';
import getAllTasks from '../selectors/getAllTasks';

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFilter: 'open',
      filterText: '',
      pendingIds: [],
      showMessage: null,
      showTaskFormModal: false,
      currentView: MY_TASKS,
      sortBy: SORT_BY_DUE_DATE,
      sortAscending: props.defaultSortAscending,
    };
  }

  onNewTaskLinkClick = task => () => {
    if (this.showTaskMessageLink(task)) {
      this.setState(({ currentView }) => ({
        currentView: MY_TASKS === currentView ? ASSIGNED_TASKS : MY_TASKS,
      }));
    }

    this.setState({
      // A newly created task will always be open
      currentFilter: 'open',
      // Hide the message.
      showMessage: null,
    });
  };

  setView = e => {
    const view = e.currentTarget.getAttribute('data-view');
    this.setState({
      currentView: view,
      pendingIds: [],
      showMessage: null,
    });
  };

  showTaskMessageLink = task => {
    if (!task) {
      return false;
    }

    const { currentView } = this.state;
    const byAndForCreator = task.get('assigned_to') === task.get('created_by');
    const viewingMyTasks = MY_TASKS === currentView;
    return viewingMyTasks !== byAndForCreator;
  };

  handleSortChange = newSortBy => {
    const { sortAscending, sortBy } = this.state;
    if (newSortBy === sortBy) {
      this.setState({ sortAscending: !sortAscending });
    } else {
      const { defaultSortAscending } = this.props;
      this.setState({ sortBy: newSortBy, sortAscending: defaultSortAscending });
    }
  };

  handleFilterChange = filter =>
    this.setState({
      currentFilter: filter,
      pendingIds: [],
      showMessage: null,
    });

  handleShowNewTaskModal = () => {
    this.setState({ showTaskFormModal: true });
  };

  handleEditTask = id => {
    this.setState({ showTaskFormModal: id });
  };

  handleDeleteTask = id => {
    const { taskActions } = this.props;
    taskActions.deleteTask({ id: id });
  };

  handleHideTaskModal = () => {
    this.setState({ showTaskFormModal: false });
  };

  handleCreatedTask = response => {
    const taskId = response?.result?.task;
    // Only show a flash message for new tasks, not updates
    this.setState({ showMessage: response?.task.id ? false : taskId });
    this.handleHideTaskModal();
  };

  handleFilterTextChange = filterText => {
    this.setState({ filterText: filterText });
  };

  handleUpdate = id => {
    this.setState(({ pendingIds }) => {
      let newIds = pendingIds;
      if (newIds.includes(id)) {
        newIds.splice(newIds.indexOf(id), 1);
      } else {
        newIds = newIds.concat(id);
      }
      return { pendingIds: newIds };
    });
  };

  render() {
    const { currentUserId, taskList } = this.props;
    const {
      currentFilter,
      currentView,
      filterText,
      pendingIds,
      showMessage,
      showTaskFormModal,
      sortAscending,
      sortBy,
    } = this.state;

    const isFetching = Boolean(
      taskList && taskList.getIn(['_meta', 'isFetching']),
    );

    const taskIds = taskList.get('ids') || new List();

    return (
      <div className='tasks-dashboard'>
        <Helmet>
          <title>Tasks</title>
        </Helmet>
        {showTaskFormModal && (
          <TaskFormModal
            onHide={this.handleHideTaskModal}
            onSaved={this.handleCreatedTask}
            taskId={
              typeof showTaskFormModal === 'number' ? showTaskFormModal : null
            }
          />
        )}
        <div className='tasks-dashboard-sidebar'>
          <TaskFilters
            currentFilter={currentFilter}
            currentView={currentView}
            filterTasks={this.filterTasks}
            filterText={filterText}
            onFilterClick={this.handleFilterChange}
            onSortChange={this.handleSortChange}
            taskIds={taskIds}
          />
        </div>
        <div className='tasks-dashboard-content'>
          <div className='tasks-view-by-type'>
            <button
              className={classnames({
                'btn btn-link': true,
                selected: currentView === MY_TASKS,
              })}
              data-view={MY_TASKS}
              onClick={this.setView}
              type='button'
            >
              My Tasks
            </button>
            <button
              className={classnames({
                'btn btn-link': true,
                selected: currentView === ASSIGNED_TASKS,
              })}
              data-view={ASSIGNED_TASKS}
              onClick={this.setView}
              type='button'
            >
              Assigned Tasks
            </button>
          </div>
          <SortAndFilterToolbar
            filterText={filterText}
            handleShowTaskModal={this.handleShowNewTaskModal}
            isAscending={sortAscending}
            onFilterTextChange={this.handleFilterTextChange}
            onSortChange={this.handleSortChange}
            sortBy={sortBy}
          />
          {showMessage && (
            <div>
              <TaskMessage
                onClick={this.onNewTaskLinkClick}
                showLink={this.showTaskMessageLink}
                taskId={showMessage}
              />
            </div>
          )}
          <TaskTableView
            currentFilter={currentFilter}
            currentUser={currentUserId}
            currentView={currentView}
            filterText={filterText}
            handleUpdate={this.handleUpdate}
            isFetching={isFetching}
            onClearFilterText={this.handleClearFilterText}
            onDelete={this.handleDeleteTask}
            onEdit={this.handleEditTask}
            onSortChange={this.handleSortChange}
            pendingIds={pendingIds}
            sortAscending={sortAscending}
            sortBy={sortBy}
            taskIds={taskIds}
          />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  currentUserId: PropTypes.number,
  /**
   * Whether sorting is done in ascending order by default (true), or descending by default (false)
   */
  defaultSortAscending: PropTypes.bool,

  taskActions: PropTypes.shape({
    deleteTask: PropTypes.func.isRequired,
  }).isRequired,

  taskList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),
};

Dashboard.defaultProps = {
  defaultSortAscending: true,
};

export default compose(
  setDisplayName('Dashboard(enhanced)'),
  connectTaskActions,
  withTasksFetched,
  connect(
    state => ({
      taskList: getAllTasks(state),
      currentUserId: getCurrentUserId(state),
    }),
    {},
  ),
)(Dashboard);

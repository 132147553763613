import PropTypes from 'prop-types';
import React from 'react';

/**
 * A container component that all specific candidacy status
 * component should render into.
 */
const CandidacyStatusContainer = ({ children, className }) => (
  <div className={`CandidacyStatusContainer ${className}`}>{children}</div>
);

CandidacyStatusContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default CandidacyStatusContainer;

import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import sectorSchema from '../schemas/sectors';

/**
 * A tag select component for choosing tags from the list of sectors.
 */

const SectorsTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField {...props} schema={sectorSchema} />
);

SectorsTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default SectorsTagSelectField;

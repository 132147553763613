import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  mapProps,
  pure,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import Link from 'modules/routing/components/Link';
import getTalentPoolUrl from '../selectors/getTalentPoolUrl';

/**
 * Renders a link to a talent pool page.
 */
export default compose(
  setDisplayName('TalentPoolLink'),
  setPropTypes({
    talentPoolId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { talentPoolId }) => ({
      href: getTalentPoolUrl(state, talentPoolId),
      useDefaultLinkBehavior: true,
      className: 'talent-pools--talent-pool-link',
    }),
    {},
  ),

  // Remove the talentPoolId prop so it doesn't get added to the <a/> element.
  mapProps(({ talentPoolId, ...props }) => props), // eslint-disable-line no-unused-vars
  pure,
)(Link);

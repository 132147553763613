import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import InterviewTypeRadioButtonGroup from 'modules/interviews/components/InterviewTypeRadioButtonGroup';
import InterviewTable from 'modules/interviews/components/InterviewTable';
import InterviewsFetchErrorAlert from 'modules/interviews/components/InterviewsFetchErrorAlert';
import InterviewsLoadingIndicator from 'modules/interviews/components/InterviewsLoadingIndicator';
import {
  DATE_GROUP_LABELS,
  DATE_GROUP_PAST,
  DATE_GROUP_UPCOMING,
  DATE_GROUPS,
  SORT_BY_DATE,
  SORT_BY_VALUES,
} from 'modules/interviews/constants';
import SearchPageSidebarPopover from './SearchPageSidebarPopover';

/**
 * Renders the "interviews" popover content, which shows all interviews of a particular "category"
 * in a table, with filter buttons at the top which can filter on the interview "type."
 */
const InterviewsPopover = ({
  category,
  handleFilterChange,
  handleSortChange,
  interviewTypeFilter,
  searchId,
  searchPage,
  sortBy,
  sortDescending,
}) => (
  <SearchPageSidebarPopover
    className='InterviewsPopover'
    searchId={searchId}
    searchPage={searchPage}
  >
    <h2 className='search-sidebar-interviews-header'>
      <span className='search-sidebar-interviews-title'>
        {DATE_GROUP_LABELS[category]}
      </span>
    </h2>
    <div className='search-sidebar-interviews-filter'>
      <InterviewTypeRadioButtonGroup
        bsSize='xs'
        onChange={handleFilterChange}
        value={interviewTypeFilter}
      />
    </div>
    <InterviewTable
      filterByDateGroup={category}
      filterByInterviewType={interviewTypeFilter}
      onSortChanged={handleSortChange}
      searchId={searchId}
      sortBy={sortBy}
      sortDescending={sortDescending}
    />
    <InterviewsLoadingIndicator searchId={searchId} />
    <InterviewsFetchErrorAlert searchId={searchId} />
  </SearchPageSidebarPopover>
);

InterviewsPopover.propTypes = {
  /**
   * The category of interviews that should be loaded.
   */
  category: PropTypes.oneOf(DATE_GROUPS).isRequired,

  /**
   * Called when the filter should be changed.
   */
  handleFilterChange: PropTypes.func.isRequired,

  /**
   * Called when the sort field and order should be changed.
   */
  handleSortChange: PropTypes.func.isRequired,

  /**
   * If set, filters interviews to only show the type specified.
   */
  interviewTypeFilter: PropTypes.string,

  /**
   * The ID of the search
   */
  searchId: PropTypes.number.isRequired,

  /**
   * The current search page selected and displayed in the content portion of the search page
   * ("candidates", "applicants", "target_companies", etc)
   */
  searchPage: PropTypes.string.isRequired,

  /**
   * The current field being sorted on.
   */
  sortBy: PropTypes.oneOf(SORT_BY_VALUES).isRequired,

  /**
   * True if sorting no the `sortBy` value is in descending order; false if in ascending order.
   */
  sortDescending: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('InterviewsPopover(enhanced)'),
  setPropTypes({
    /**
     * The filter determing which group of interviews to display.
     */
    filter: PropTypes.oneOf(['upcoming', 'recent']),

    /**
     * The ID of the search
     */
    searchId: PropTypes.number.isRequired,

    /**
     * The current search page selected and displayed in the content portion of the search page
     * ("candidates", "applicants", "target_companies", etc)
     */
    searchPage: PropTypes.string.isRequired,
  }),

  // Map the filter to the associated category. This is necessary because they are not a one-to-one
  // match: The url shows '/recent' when the DATE_GROUP_PAST, whose value is "past" should be shown.
  mapProps(({ filter, ...rest }) => ({
    ...rest,
    category: filter === 'upcoming' ? DATE_GROUP_UPCOMING : DATE_GROUP_PAST,
  })),

  /**
   * The current sorted state of the interviews table.
   * @type {String}
   */
  withState('sortBy', 'setSortBy', SORT_BY_DATE),

  /**
   * Whether the sort field specified in `sortBy` should
   * be sorted descending (true) or ascending (false)
   * @type {Boolean}
   */
  withState('sortDescending', 'setSortDescending', true),

  /**
   * The type of interviews to show ('Client', 'Recruiter', etc; '' for no filter)
   * @type {String}
   */
  withState('interviewTypeFilter', 'setInterviewTypeFilter', ''),

  withHandlers({
    handleFilterChange: ({ setInterviewTypeFilter }) => event => {
      setInterviewTypeFilter(event.currentTarget.value);
    },
    handleSortChange: ({ setSortBy, setSortDescending }) => (
      sortBy,
      sortDescending,
    ) => {
      setSortBy(sortBy);
      setSortDescending(sortDescending);
    },
  }),
)(InterviewsPopover);

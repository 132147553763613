import invalidateCandidacyStatus from 'modules/candidacies/actions/invalidateCandidacyStatus';
import deleteEntity from 'modules/entities/actions/deleteEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import apiV1 from 'services/apiV1';
import queryTags from 'services/apiV1/queryTags';

import referenceSchema from '../schema';
import getReference from '../selectors/getReference';

/**
 * Creates an action for deleting a reference
 * @param {Object} payload
 * @param {Number} payload.id The reference ID.
 */
export default ({ id, ...rest }) => (dispatch, getState) => {
  const reference = getReference(getState(), id);

  return dispatch(
    deleteEntity({
      ...rest,
      id: id,
      entityType: referenceSchema.key,
      url: routes.api_v1_reference({ id: id }),
    }),
  ).then(action => {
    if (
      action.type === entityActionTypes.DELETE_SUCCESS &&
      reference &&
      reference.get('candidacy_id')
    ) {
      dispatch(
        invalidateCandidacyStatus({
          id: reference.get('candidacy_id'),
        }),
      );
    }
    if (action.type === entityActionTypes.DELETE_SUCCESS && reference) {
      dispatch(apiV1.util.invalidateTags([queryTags.CONNECTIONS]));
    }

    return action;
  });
};

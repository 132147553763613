import url from 'url';
import initSelect2 from '../../../lib/select2';

// Charts
import commissionsChart from '../../../lib/commissions_chart';
import { buildCharts } from '../../../lib/c3_helpers';

const initRegionSelect = () => {
  const containerEl = document.querySelector('.scope-select');
  const triggerEl = containerEl.querySelector('.trigger');
  const menuEl = containerEl.querySelector('.options');

  triggerEl.addEventListener('click', () => {
    menuEl.classList.toggle('is-visible');
  });
};

const initializeSelect = () => {
  initSelect2('.commission_management', ['.js-select-user-commissions']);
  $('.js-select-user-commissions').on('select2:select', event => {
    const userId = event.target.value;
    const today = new Date();
    window.location = url.format({
      pathname: '/searches/my_commissions',
      query: { view: 'invoice', year: today.getFullYear(), user_id: userId },
    });
  });
};

const initialize = () => {
  $("table#searches [data-toggle='popover']").popover({ html: true });
  buildCharts('.commissions-bar', commissionsChart, 'commissions');
  initRegionSelect();
  $('.nav.nav-tabs.nav-main li').removeClass('active');
  return initializeSelect();
};

export default {
  initialize: initialize,
};

import { compose, lifecycle, setDisplayName, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import fetchTimezoneListActionCreator from '../actions/fetchTimezoneList';
import shouldFetchTimezoneListSelector from '../selectors/shouldFetchTimezoneList';
import getTimezoneList from '../selectors/getTimezoneList';

/**
 * Fetches the timezone list if needed and provides the `timezoneList` prop.
 * */
export default compose(
  setDisplayName('withTimezoneListFetched'),
  connect(
    state => ({
      timezoneList: getTimezoneList(state),
      shouldFetchTimezoneList: shouldFetchTimezoneListSelector(state),
    }),
    dispatch => ({
      fetchTimezoneList: bindActionCreators(
        fetchTimezoneListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchTimezoneListIfNeeded: ({
      fetchTimezoneList,
      shouldFetchTimezoneList,
    }) => () => {
      if (shouldFetchTimezoneList) {
        fetchTimezoneList();
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchTimezoneListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchTimezoneListIfNeeded();
    },
  }),
);

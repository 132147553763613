import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import FieldState from 'modules/forms/FieldState';
import {
  queryDuplicateContacts,
  getDuplicateContacts,
  getDuplicateContactQueryIds,
  queryAllDuplicateContacts,
} from '../../domains/contacts/duplicateRecognition';

const withContactDuplicates = () => BaseComponent => {
  class ContactDuplicatesHandler extends Component {
    componentDidMount() {
      const {
        contactActions,
        duplicateDetectionId,
        fieldState,
        queryDuplicates,
      } = this.props;
      const queryNames = getDuplicateContactQueryIds(duplicateDetectionId);
      queryNames.forEach(contactActions.createDuplicateContactsQuery);

      if (queryDuplicates) {
        queryAllDuplicateContacts(
          duplicateDetectionId,
          fieldState.getFieldValue(),
          contactActions.queryDuplicateContacts,
          true,
        );
      }
    }

    UNSAFE_componentWillReceiveProps({ fieldState, onChange }) {
      const emailField = fieldState.getNestedField('email');
      const email = emailField.getValue();
      const hasEmailError = emailField.hasErrors();

      if (email) {
        if (email.size && !hasEmailError) {
          onChange(
            fieldState.setNestedFieldError('email', 'Email must be unique'),
          );
        }
      }
    }

    componentWillUnmount() {
      const { contactActions, duplicateDetectionId } = this.props;
      const queryNames = getDuplicateContactQueryIds(duplicateDetectionId);
      queryNames.forEach(contactActions.destroyDuplicateContactsQuery);
    }

    /**
     * Called when the FieldState of the BaseComponent is changed
     * @param {FieldState} fieldState The new, updated FieldState value
     * @param {String|null} childFieldName The name of the child field (if any) that caused the
     *   FieldState change.
     */
    handleFieldChange = (fieldState, childFieldName) => {
      const { contactActions, duplicateDetectionId, onChange } = this.props;

      queryDuplicateContacts(
        duplicateDetectionId,
        childFieldName,
        fieldState.getFieldValue(),
        contactActions.queryDuplicateContacts,
      );

      onChange(fieldState);
    };

    render() {
      const {
        contacts,
        duplicateDetectionId,
        onChange: _onChange,
        ...baseComponentProps
      } = this.props;

      const duplicates = getDuplicateContacts(duplicateDetectionId, contacts);
      const keys = Object.keys(duplicates);
      const contactId = contacts.getIn(['selectedContact', 'id']);
      const filteredDuplicates = {};
      keys.forEach(key => {
        filteredDuplicates[key] = duplicates[key].filter(
          duplicate => duplicate.get('id') !== contactId,
        );
      });

      return (
        <BaseComponent
          duplicates={filteredDuplicates}
          onChange={this.handleFieldChange}
          {...baseComponentProps}
        />
      );
    }
  }

  ContactDuplicatesHandler.propTypes = {
    contactActions: PropTypes.shape({
      createContactsQuery: PropTypes.func.isRequired,
      createDuplicateContactsQuery: PropTypes.func.isRequired,
      destroyContactsQuery: PropTypes.func.isRequired,
      destroyDuplicateContactsQuery: PropTypes.func.isRequired,
      queryContacts: PropTypes.func.isRequired,
      queryDuplicateContacts: PropTypes.func.isRequired,
    }).isRequired,

    contacts: ImmutablePropTypes.mapContains({
      queriesById: ImmutablePropTypes.map.isRequired,
    }).isRequired,

    duplicateDetectionId: PropTypes.string.isRequired,

    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    onChange: PropTypes.func,
    queryDuplicates: PropTypes.bool,
  };

  return ContactDuplicatesHandler;
};

export default withContactDuplicates;

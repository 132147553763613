import companySchema from 'modules/companies/companySchema';
import merge from 'modules/entities/actions/merge';
import { PROVIDE_DATA } from './actionTypes';

/**
 * An action that injects application data into our state
 * This is used to inject data from a server-side render into our store. The data must follow
 * the expected schema.
 * @param {Object} data The data to inject.
 * @return {Object} The initialize action.
 */
export default data =>
  merge({
    data: data,

    // NOTE: This must match the shape expected in `components/DataProvider`! If you add or change
    // this, be sure to update the expected propType there to match!
    schema: {
      company: companySchema,
    },
    type: PROVIDE_DATA,
  });

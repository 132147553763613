import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';

import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import mapStageTypeToStageList from './mapStageTypeToStageList';
import fetchStageListIfNeeded from './fetchStageListIfNeeded';
import mapStageIdsToStages from './mapStageIdsToStages';
import SearchStageListMenuItem from './SearchStageListMenuItem';
import SearchStageListStageMenuItem from './SearchStageListStageMenuItem';
import {
  STAGE_TYPES,
  STAGE_TYPE_JOB_SEARCH,
  STAGE_TYPE_PIPELINE,
  STAGE_TYPE_TALENT_POOL,
} from '../constants';
import withFeatureCheck from '../../auth/components/withFeatureCheck';
/**
 * Renders a list of candidacy stages, allowing the user to select a new
 * stage to put a candidacy into. Optionally includes a "Remove from Search"
 * option, as well.
 */
const SearchStageListMenu = ({
  disabled,
  hasJobSearchStageGates,
  isFetching,
  onChange,
  onDelete,
  stageType,
  stages,
  value,
}) => {
  const shouldHideRemoveFromSearchBtn = useFeatureCheck(
    'permission.remove_from_search',
  );

  const actionMap = {
    [STAGE_TYPE_PIPELINE]: 'Remove from Pipeline',
    [STAGE_TYPE_TALENT_POOL]: 'Remove from Talent Pool',
    default: 'Remove from Search',
  };

  const disabledFromCandidacyStage = stage => {
    return hasJobSearchStageGates && stageType === STAGE_TYPE_JOB_SEARCH
      ? !stage.get('allowed_from_candidacy_stage_ids').toArray().includes(value)
      : false;
  };
  return (
    <ul className='SearchStageListMenu'>
      {stages?.map(stage => (
        <SearchStageListStageMenuItem
          checked={stage.get('id') === value}
          disabled={disabled || disabledFromCandidacyStage(stage)}
          key={`stage-${stage.get('id')}`}
          onClick={onChange}
          stage={stage}
        />
      ))}
      {!shouldHideRemoveFromSearchBtn && onDelete ? (
        <>
          {stages?.count() ? (
            <li className='SearchStageListMenu__separator' />
          ) : null}
          <SearchStageListMenuItem onClick={onDelete}>
            {actionMap[stageType] || actionMap.default}
          </SearchStageListMenuItem>
        </>
      ) : null}
      {isFetching && (
        <li key='loading'>
          <LoadingIndicator />
        </li>
      )}
    </ul>
  );
};

SearchStageListMenu.propTypes = {
  /**
   * True to disable selecting a stage.
   */
  disabled: PropTypes.bool,

  hasJobSearchStageGates: PropTypes.bool,
  /**
   * True if currently fetching the list of stages
   */
  isFetching: PropTypes.bool,

  /**
   * Called when a menu item button is clicked.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Called when the "Remove from Search" option is selected. To suppress this
   * option, omit the `onDelete` prop entirely.
   */
  onDelete: PropTypes.func,

  /**
   * The list of stages to display
   */
  stages: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
    }),
  ),
  stageType: PropTypes.oneOf(STAGE_TYPES).isRequired,
  /**
   * The ID of the currently active stage.
   */
  value: PropTypes.number,
};

SearchStageListMenu.defaultProps = {
  disabled: false,
  isFetching: false,
};

export default compose(
  setDisplayName('SearchStageListMenu(enhanced)'),
  setPropTypes({
    /**
     * The stage type to display stages for.
     */
    stageType: PropTypes.oneOf(STAGE_TYPES).isRequired,

    /**
     * Called when a stage is selected.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Called when the "Remove" option is selected.
     * The presence of this prop controls whether the "Remove" option is shown
     * or not.
     */
    onDelete: PropTypes.func,
    /**
     * The currently selected stage. This will be highlighted
     * but unselectable.
     */
    value: PropTypes.number,
  }),
  mapStageTypeToStageList,
  withProps(({ stageList }) => ({
    stageIds: stageList && stageList.get('ids'),
    isFetching: stageList && stageList.getIn(['_meta', 'isFetching']),
  })),
  withFeatureCheck('feature.job_search_stage_gates', 'hasJobSearchStageGates'),
  mapStageIdsToStages,
  fetchStageListIfNeeded,
  withProps(({ stages, value }) => ({
    stages:
      stages &&
      stages
        .sortBy(stage => stage.get('position'))
        // Don't include stages that are not user-assignable, and don't include the
        // currently selected stage in the list.
        .filter(
          stage => stage.get('user_assignable') || stage.get('id') === value,
        ),
  })),
)(SearchStageListMenu);

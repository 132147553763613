import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setPropTypes } from 'recompose';

import mapCompanyIdToCompany from 'modules/companies/components/mapCompanyIdToCompany';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import SearchPageViewLink from 'modules/searches/components/SearchPageViewLink';

import mapTargetCompanyIdToCompanyId from '../mapTargetCompanyIdToCompanyId';
import mapTargetCompanyIdToTargetCompany from '../mapTargetCompanyIdToTargetCompany';
import TargetCompanyCommentsButton from '../TargetCompanyCommentsButton';
import TargetCompanyIndicatorIcon from '../TargetCompanyIndicatorIcon';
import TargetCompanyStatusToggleButton from '../TargetCompanyStatusToggleButton';
import TargetCompanyVoteButton from '../TargetCompanyVoteButton';

const POPOVER_VOTE_FORM = 'POPOVER_VOTE_FORM';
const POPOVER_COMMENTS = 'POPOVER_COMMENTS';

export class TargetCompanyCard extends PureComponent {
  state = {
    /**
     * The current popover that is open, or false if none is open.
     * @type {Boolean|String}
     */
    openedPopover: false,
  };

  handlePriorityVoteClick = () => {
    const { openedPopover } = this.state;
    this.openPopover(
      openedPopover === POPOVER_VOTE_FORM ? false : POPOVER_VOTE_FORM,
    );
  };

  handlePriorityVoteClose = () => {
    this.openPopover(false);
  };

  handleCommentsClick = () => {
    const { openedPopover } = this.state;
    this.openPopover(
      openedPopover === POPOVER_COMMENTS ? false : POPOVER_COMMENTS,
    );
  };

  handleCommentsClose = () => {
    this.openPopover(false);
  };

  handleCandidacyCountClick = () => {
    const { onCandidacyCountClick, targetCompany } = this.props;
    onCandidacyCountClick(targetCompany);
  };

  openPopover(openedPopover) {
    this.setState({ openedPopover: openedPopover });
  }

  render() {
    const { company, targetCompany } = this.props;
    const { openedPopover } = this.state;

    if (!company || !targetCompany) {
      return <LoadingIndicator />;
    }

    const priorityVoteCount = targetCompany.get('priority_vote_count', 0);
    const targetCompanyId = targetCompany.get('id');
    const targetCandidatesCount = targetCompany.get('candidates_count');
    const logoUrl = company.get('logo_url');

    return (
      <div
        className={classnames('target-companies--target-company-card', {
          priority: priorityVoteCount > 0,
          inactive: !targetCompany.get('active', false),
        })}
      >
        <div>
          <span className='company-card-toggle-btn'>
            <TargetCompanyStatusToggleButton
              targetCompanyId={targetCompanyId}
            />
          </span>
          <span className='btn btn-link target-company-count pull-right'>
            <SearchPageViewLink
              onClick={this.handleCandidacyCountClick}
              page='candidates'
            >
              <small className='target-company-count-value'>
                {targetCandidatesCount}
              </small>
              <TargetCompanyIndicatorIcon />
            </SearchPageViewLink>
          </span>
        </div>
        <div className='company-card-logo'>
          {logoUrl ? (
            <img alt='Logo' role='presentation' src={logoUrl} />
          ) : (
            <i className='fa fa-building fa-3x' />
          )}
        </div>
        <h5 className='company-card-title'>{company.get('name')}</h5>
        <div className='company-card-content'>
          <TargetCompanyVoteButton
            className='company-card-vote'
            isFormOpen={openedPopover === POPOVER_VOTE_FORM}
            onClick={this.handlePriorityVoteClick}
            onClose={this.handlePriorityVoteClose}
            priorityCount={priorityVoteCount}
            targetCompanyId={targetCompanyId}
          />
          <TargetCompanyCommentsButton
            btnClassName='btn-link'
            className='company-card-comments'
            isOpen={openedPopover === POPOVER_COMMENTS}
            onClick={this.handleCommentsClick}
            onClose={this.handleCommentsClose}
            targetCompanyId={targetCompanyId}
          />
        </div>
      </div>
    );
  }
}

TargetCompanyCard.propTypes = {
  company: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
    logo_url: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),

  onCandidacyCountClick: PropTypes.func.isRequired,

  targetCompany: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isUpdating: PropTypes.bool,
    }),
    active: PropTypes.bool.isRequired,
    search_target_company_comments_count: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    priority_vote_count: PropTypes.number,
  }),
};

export default compose(
  setPropTypes({
    targetCompanyId: PropTypes.number.isRequired,
    onCandidacyCountClick: PropTypes.func.isRequired,
  }),
  mapTargetCompanyIdToTargetCompany,
  mapTargetCompanyIdToCompanyId,
  mapCompanyIdToCompany,
)(TargetCompanyCard);

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useSelector } from 'react-redux';
import CommaDelimitedList from 'modules/core/componentsLegacy/CommaDelimitedList';
import getEntityProperty from '../selectors/getEntityProperty';

/**
 * Renders a list of entity properties, separated by commas
 */
const EntityPropertyList = ({
  entityIds,
  entityType,
  propertyName,
  ...props
}) => {
  const ids = entityIds.toArray ? entityIds.toArray() : entityIds;
  const listItems = useSelector(state =>
    ids.map(entityId =>
      getEntityProperty(state, entityType, entityId, propertyName),
    ),
  );

  return (
    <CommaDelimitedList {...props}>
      {listItems.map(item => (
        <li key={item}>{item}</li>
      ))}
    </CommaDelimitedList>
  );
};

EntityPropertyList.defaultProps = {
  propertyName: 'name',
};

EntityPropertyList.propTypes = {
  /**
   * The collection of entityIds to render.
   */
  entityIds: PropTypes.oneOfType([
    ImmutablePropTypes.listOf(PropTypes.number.isRequired),
    PropTypes.arrayOf(PropTypes.number.isRequired),
  ]).isRequired,

  /**
   * The entity type of the entity IDs.
   */
  entityType: PropTypes.string.isRequired,

  /**
   * The property name to extract from the entity and render.
   */
  propertyName: PropTypes.string,
};

export default EntityPropertyList;

import React from 'react';
import FullPageTakeoverHeader from '@thrivetrm/ui/components/FullPageTakeoverHeader';
import Card from '@thrivetrm/ui/components/Card';
import Tag from 'modules/core/components/Tag';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import { useSelector } from 'react-redux';
import poweredByThriveImage from '../../../../images/powered_by_thrive_trm.png';

const GuestAssessmentLinkExpired = () => {
  const tenant = useSelector(state => state.tenant);

  return (
    <>
      <FullPageTakeoverHeader
        className='u-paddingVertical-12'
        title={
          <img
            alt='tenant_image'
            className='GuestAssessmentLinkExpired__tenantImage'
            src={tenant.get('logo')}
          />
        }
      />
      <div className='u-flex u-width-100 u-flexJustify-c GuestAssessmentLinkExpired__cardView'>
        <Card
          className='u-marginTop-64 u-textAlign-c u-paddingHorizontal-64'
          isCentered={false}
        >
          <Tag
            className='GuestAssessmentLinkExpired__tag u-fontSize-small u-textColor-white u-marginTop-24'
            name='Token Expired'
          />
          <h1 className='u-textColor-gray50'>Your link has expired.</h1>
          <p className='u-textColor-gray50 u-fontSize-large u-marginTop-24'>
            Please reach out to your contact on <br /> the search team for a new
            assessment link.
          </p>
        </Card>
      </div>
      <div className='GuestAssessmentLinkExpired__primary u-backgroundColor-darkBlue' />
      <div className='GuestAssessmentLinkExpired__footer u-flex u-paddingHorizontal-32 u-paddingBottom-16 u-flexJustify-spaceBetween u-width-100 u-flexAlign-c'>
        <p className='u-width-100 u-textColor-gray50 u-fontSize-small u-margin-n'>
          Copyright © {new Date().getFullYear()} Thrive TRM LLC. All Rights
          Reserved.
        </p>
        <div className='u-width-100 u-flex u-fontSize-small u-flexJustify-c'>
          <ButtonLink
            label='Terms of Use'
            onClick={() => {
              window.location.href =
                'https://thrivetrm.com/terms-and-conditions/';
            }}
          />
          <ButtonLink
            className='u-marginHorizontal-24'
            label='Privacy Policy'
            onClick={() => {
              window.location.href = 'https://thrivetrm.com/privacy-policy/';
            }}
          />
          <ButtonLink
            label='Help Center'
            onClick={() => {
              window.location.href = 'https://helpcenter.thrivetrm.com/';
            }}
          />
        </div>
        <div className='u-width-100 u-textAlign-r'>
          <img
            alt='powered_by_thrive'
            className='GuestAssessmentLinkExpired__poweredByThriveImage'
            src={poweredByThriveImage}
          />
        </div>
      </div>
    </>
  );
};

export default GuestAssessmentLinkExpired;

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import FieldState from '../FieldState';
import asField from './asField';
import withFormGroup from './withFormGroup';

/**
 * A field for editing a value using a standard `<input>` element.
 */
const InputGroup = ({ appendComponent, prependComponent, ...inputProps }) => (
  <div className='input-group'>
    {prependComponent && (
      <span className='input-group-addon'>{prependComponent}</span>
    )}
    <input className='form-control' {...inputProps} />
    {appendComponent && (
      <span className='input-group-addon'>{appendComponent}</span>
    )}
  </div>
);

InputGroup.propTypes = {
  /**
   * A component or string to append to the field
   */
  appendComponent: PropTypes.node,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * A component or string to prepend to the field.
   */
  prependComponent: PropTypes.node,

  /**
   * The type passed to the underlying `<input />` (defaults to 'text')
   */
  type: PropTypes.string,

  /**
   * The input's value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

InputGroup.defaultProps = {
  type: 'text',
};

export default compose(
  setDisplayName('InputGroupField'),
  setPropTypes({
    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', FieldState.create),
  withFormGroup,
  asField({
    handleValueChange: event => event.currentTarget.value,
    convertInputValue: value => value || '',
  }),
)(InputGroup);

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import fetchStageListIfNeeded from 'modules/search-stages/components/fetchStageListIfNeeded';
import mapStageIdsToStages from 'modules/search-stages/components/mapStageIdsToStages';
import mapStageTypeToStageList from 'modules/search-stages/components/mapStageTypeToStageList';
import withStageIdsSortedByPosition from 'modules/search-stages/components/withStageIdsSortedByPosition';
import withSearchFetched from '../withSearchFetched';
import withCandidacyStatsFetched from './withCandidacyStatsFetched';
import getStageType from '../../selectors/getStageType';
import getCandidacyStats from '../../selectors/getCandidacyStats';

/**
 * A higher-order component that gathers the candidacy-related stats and stage information
 * for a particular search.
 */
export default compose(
  setDisplayName('withCandidacyPerStageStats'),
  setPropTypes({
    /**
     * The ID of the search to gather the stats for.
     */
    searchId: PropTypes.number.isRequired,
  }),

  // Make sure we have both the search and it's stats fetched and up-to-date.
  withSearchFetched,
  withCandidacyStatsFetched,

  // Gind the stageType and stats from the searchId.
  connect(
    (state, { searchId }) => ({
      stageType: getStageType(state, searchId),
      candidacyStats: getCandidacyStats(state, searchId),
    }),
    {},
  ),

  // Fetch and lookup the list of stages for the `stageType` of the search.
  fetchStageListIfNeeded,
  mapStageTypeToStageList,

  // Pull off the `stageIds` so they can be sorted, filtered, and mapped to the stage records
  withProps(({ stageList }) => ({
    stageIds: stageList && stageList.get('ids'),
  })),

  // Make sure they're sorted by the position
  withStageIdsSortedByPosition,

  // Map the ID values (`stageIds`) to stage records (`stages`)
  mapStageIdsToStages,

  withProps(({ candidacyStats, stages }) => ({
    // Pull out values from stats and expose them as props for our component.
    rejectionReasons: candidacyStats && candidacyStats.get('rejection_reasons'),

    stageCounts: candidacyStats && candidacyStats.get('stage_counts'),

    stages: stages,

    activeStages: stages && stages.filter(stage => stage.get('active')),
  })),

  withProps(({ activeStages, stageCounts, stages }) => ({
    totalCandidates:
      stages &&
      stageCounts &&
      stages.reduce(
        (total, stage) => total + stageCounts.get(`${stage.get('id')}`, 0),
        0,
      ),
    totalActiveCandidates:
      activeStages &&
      stageCounts &&
      activeStages.reduce(
        (total, stage) => total + stageCounts.get(`${stage.get('id')}`, 0),
        0,
      ),
  })),
);

import getAddress from './getAddress';
/**
 * Formats an address for displaying as a single line of text.
 * Joins any defined parts of the address itself, separating them by commas,
 * and prefixes everything by the label. The label and address parts are separated
 * by a semi-colon if both exists, i.e.:
 * * "Home: 123 Main St., Anytown, ST, USA" (label and address parts)
 * * "Home" (only a label)
 * * "123 Main St., Anytown, ST, USA" (no label)
 */
export default (state, addressId) => {
  const address = getAddress(state, addressId);
  const label = address.get('label') || '';
  const addressParts = [
    address.get('address_line_1'),
    address.get('address_line_2'),
    address.get('city'),
    address.get('state'),
    address.get('country_code'),
  ]
    .filter(part => Boolean(part))
    .join(', ');

  return [label, addressParts].filter(part => Boolean(part)).join(': ');
};

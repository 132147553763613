import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import AssessmentTypeGroupName from 'modules/assessments/components/AssessmentTypeGroupName';
import connectAssessmentActions from 'modules/assessments/components/connectAssessmentActions';
import mapAssessmentIdsToAssessments from 'modules/assessments/components/mapAssessmentIdsToAssessments';
import withAssessmentIdsFilteredByType from 'modules/assessments/components/withAssessmentIdsFilteredByType';
import withAssessmentListFetched from 'modules/assessments/components/withAssessmentListFetched';
import {
  PARENT_CANDIDACY,
  TYPE_CLIENT,
  TYPE_RECRUITER,
  TYPE_RESEARCHER,
} from 'modules/assessments/constants';

import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import AssessmentForm from 'modules/candidacies/components/assessments/AssessmentForm';
import AssessmentList from 'modules/candidacies/components/assessments/AssessmentList';
import withCanCreateAssessment from 'modules/candidacies/components/assessments/withCanCreateAssessment';
import withFormToggleButton from '../../../components/forms/withFormToggleButton';

const AddAssessmentButton = withFormToggleButton(AssessmentForm);

const CandidacyAssessmentsGroup = ({
  assessments,
  canCreateAssessment,
  candidacyId,
  searchId,
  type,
}) => {
  const isLoading = !assessments;
  return (
    <div
      className={classnames(
        'CandidacyAssessmentsGroup',
        `CandidacyAssessmentsGroup--${type}`,
      )}
    >
      <h3>
        <AssessmentTypeGroupName type={type} />
      </h3>
      {canCreateAssessment && isLoading && <LoadingIndicator />}
      {isLoading ? null : (
        <AddAssessmentButton
          assessmentType={type}
          candidacyId={candidacyId}
          // We must hide the form via CSS rather than not rendering it.
          // Only one recruiter and one researcher assessment are permitted.
          // Once one of these assessment types is saved, this form does not display
          // in the UI. When it is not rendered, it is unmounted before the form's
          // `onSaved` handler is called and will not remove the localstorage draft
          className={canCreateAssessment ? null : 'u-hidden'}
          draftStoragePath={{
            search: searchId,
            candidate: candidacyId,
            [type]: 'new',
          }}
        >
          Add Assessments
        </AddAssessmentButton>
      )}
      {assessments && (
        <AssessmentList
          assessments={assessments}
          candidacyId={candidacyId}
          searchId={searchId}
        />
      )}
    </div>
  );
};

CandidacyAssessmentsGroup.propTypes = {
  assessments: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),
  ),

  canCreateAssessment: PropTypes.bool,

  candidacyId: PropTypes.number.isRequired,

  type: PropTypes.oneOf([TYPE_CLIENT, TYPE_RECRUITER, TYPE_RESEARCHER])
    .isRequired,
};

CandidacyAssessmentsGroup.defaultProps = {
  canCreateAssessment: false,
};

export default compose(
  setDisplayName('CandidacyAssessmentGroup(enhanced)'),
  setPropTypes({
    candidacyId: CandidacyAssessmentsGroup.propTypes.candidacyId,
    type: CandidacyAssessmentsGroup.propTypes.type,
  }),
  withProps({
    parentType: PARENT_CANDIDACY,
  }),
  withAssessmentListFetched({
    parentIdPropName: 'candidacyId',
  }),
  withProps(({ assessmentList }) => ({
    assessmentIds: assessmentList && assessmentList.get('ids'),
  })),
  withAssessmentIdsFilteredByType({
    filterTypePropName: 'type',
  }),
  mapAssessmentIdsToAssessments,
  connectAssessmentActions,
  withCanCreateAssessment,
)(CandidacyAssessmentsGroup);

import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import getJobApplicationById from '../selectors/getJobApplicationById';

export default compose(
  setDisplayName('mapJobApplicationIdToJobApplication'),
  connect(
    (state, { jobApplicationId }) => ({
      jobApplication: getJobApplicationById(state, jobApplicationId),
    }),
    {},
  ),
);

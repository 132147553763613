import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  branch,
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import connectTransactions from 'modules/transactions/components/connectTransactions';

import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';
import withDraftStorage from '../../../components/forms/withDraftStorage';

import { PARENT_TYPES } from '../constants';
import connectNoteActions from './connectNoteActions';
import handleSubmit from './handleSubmit';
import mapNoteIdToNote from './mapNoteIdToNote';
import NoteField from './NoteField';

export const createRootFieldState = ({ contactId, draft, note, searchId }) => {
  let noteValue;
  // When editing an existing note
  if (note) {
    noteValue = note.withMutations(immutableNote => {
      immutableNote.set('searchId', searchId);
      if (draft?.content) {
        immutableNote.set('content', draft?.content);
      }
    });
  } else {
    // When creating a new note
    noteValue = fromJS({
      contact_id: contactId,
      content: draft?.content,
      searchId: searchId,
      searches: searchId ? [searchId] : [],
    });
  }
  return NoteField.createFieldState('note', noteValue);
};

export const propTypes = {
  /**
   * The note record if editing an existing note. Either this or `noteId` may be
   * supplied -- it's not necessary to supply both.
   */
  note: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
  }),

  /**
   * The ID of an note to edit an existing note. Either this or `note` may be
   * supplied -- it's not necessary to supply both.
   */
  noteId: PropTypes.number,

  /**
   * The note's parent type. Required when creating a new note.
   */
  parentType: requiredIf(
    PropTypes.oneOf(PARENT_TYPES),
    ({ note, noteId }) => !(note || noteId),
  ),

  /**
   * THe note's parent ID. Required when creating a new note
   */
  parentId: requiredIf(
    PropTypes.number,
    ({ note, noteId }) => !(note || noteId),
  ),

  searchId: PropTypes.number,
};

export default compose(
  setDisplayName('NoteForm'),

  // At least ONE of the following props are required: An `note` itself or an `noteId`
  // (if editing an existing note), otherwise a `parentId` and `parentType` is required in order
  // to know which type of note we are creating.
  setPropTypes(propTypes),

  // If no note record was given but an noteId was, get the note record by ID.
  branch(props => props.noteId && !props.note, mapNoteIdToNote),

  // Make sure the parentType and parentId is provided as a prop for the NotField (get it from
  // the note record if we're editing an existing note).
  withProps(({ note, parentType }) => ({
    parentType: parentType || (note && note.get('notable_type')),
  })),

  connectNoteActions,
  connectTransactions,
  withDraftStorage(),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'note-form',
    omitProps: [
      'contactId',
      'formState',
      'note',
      'noteActions',
      'noteId',
      'parentId',
      'transactionActions',
    ],
  }),
)(NoteField);

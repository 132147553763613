import selectn from 'selectn';
import { compose, withHandlers, withProps } from 'recompose';
import withReportActions from 'modules/reports/components/withReportActions';
import withTransaction from 'modules/transactions/components/withTransaction';

import withFormState from '../../../../components/forms/withFormState';

import CompensationReportField from './CompensationReportField';
import { REPORT_CANDIDATE_COMPENSATION } from '../../constants';

/**
 * A higher-order component generator providing props for creating a component that takes
 * a CompensationReportField's fieldState and provides it's underlying form functionality
 */
export default compose(
  withReportActions,

  // Create a formState from the `CompensationReportField`'s fieldState creator.
  withFormState(props =>
    CompensationReportField.createFieldState(
      'compensationReport',
      props.currentUserEmail,
    ),
  ),

  withHandlers({
    // Handles the underlying fieldState change.
    handleFieldStateChange: ({ formState, onChange }) => fieldState =>
      onChange(formState.setFieldState(fieldState)),

    // Called by withTransaction when a transaction we started (in `handleSubmit`)
    // has been completed.
    onTransactionComplete: ({ formState, onChange }) => transaction =>
      onChange(formState.endSubmit(selectn('payload.error', transaction))),
  }),

  // provides `startTransaction` and `onTransactionComplete` props to track our submission
  withTransaction,

  withHandlers({
    // Submits the request by displaying the `createReport` action with the values of the fieldState
    handleSubmit: ({
      formState,
      onChange,
      reportActions,
      searchId,
      startTransaction,
    }) => e => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }

      const transactionId = startTransaction();
      const { emails } = formState.getFieldValue();
      reportActions.createReport({
        report: {
          name: REPORT_CANDIDATE_COMPENSATION,
          search_id: searchId,
          emails: emails,
        },
        transactionId: transactionId,
      });

      onChange(formState.startSubmit(transactionId));
    },
  }),

  // Some helper props that can be used by the wrapped component.
  withProps(({ formState }) => ({
    isComplete:
      formState.wasSubmitted() &&
      !formState.getError() &&
      !formState.isSubmitting(),
    isSubmitting: formState.isSubmitting(),
    wasSubmitted: formState.wasSubmitted(),
    error: formState.getError(),
  })),
);

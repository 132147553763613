import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName, setStatic } from 'recompose';
import { List, Map } from 'immutable';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import Attributes from './Attributes';

/**
 * The Attributes component as a Field so we can use this with our
 * standard `FieldState` and `onChange` props.
 */
export class AttributesField extends Component {
  handleChange = (value, option) => {
    const { fieldState, onChange } = this.props;
    const optionId = option.get('id');
    const previousValue = fieldState.getValue();

    // See if we already have a rating value in our FieldState for this particular
    // option.
    const index = previousValue.findIndex(
      rating => rating.get('assessment_option_id') === optionId,
    );

    if (index >= 0) {
      // Already rated, just changing the rating, so update the existing rating value.
      onChange(
        fieldState.setValue(previousValue.setIn([index, 'score'], value)),
      );
      return;
    }

    // This option doesn't have a rating yet, so create a new rating object and add it to the
    // current fieldstate.
    onChange(
      fieldState.setValue(
        previousValue.push(
          new Map({
            score: value,
            assessment_option_id: optionId,
          }),
        ),
      ),
    );
  };

  render() {
    const { fieldState, readOnly, searchId, ...props } = this.props;

    return (
      <Attributes
        {...props}
        onChange={this.handleChange}
        ratings={fieldState.getValue()}
        readOnly={readOnly}
        searchId={searchId}
      />
    );
  }
}

AttributesField.defaultProps = {
  readOnly: false,
};

AttributesField.propTypes = {
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  searchId: PropTypes.number,
};

AttributesField.createFieldState = (
  name,
  value,
  validator,
  _convertToRaw,
  ...other
) =>
  FieldState.create(
    name,
    new List(value),
    validator,

    // The `convertToRaw` implementation for this field ensures that we
    // return an array of ratings. If the list is empty the FieldState.getRawValue
    // implementation doesn't correctly identify the value as not being a nested
    // fieldstate and converts it to an array already, which is why the
    // Array.isArray check is here.
    list => (Array.isArray(list) ? list : list.toJS()),
    ...other,
  );

export default compose(
  setDisplayName('AttributesField(enhanced)'),
  setStatic('createFieldState', AttributesField.createFieldState),
  withFormGroup,
)(AttributesField);

import selectn from 'selectn';
import { fromJS } from 'immutable';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import withFormState from 'modules/forms/components/withFormState';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withTransaction from 'modules/transactions/components/withTransaction';
import CandidatePortalInviteField from './CandidatePortalInviteField';
import createUserActionCreator from '../../actions/contacts/createUser';
import withValidatedContactEmailFormState from './withValidatedContactEmailFormState';

/**
 * Provides props for managing a form for creating a user.
 *
 * Creates the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying CandidatePortalInviteField FieldState
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default compose(
  setDisplayName('withCandidatePortalInviteForm'),
  setPropTypes({
    /**
     * An optional formstate to initial the form with (handled by withFormState)
     */
    initialFormState: formStatePropType,
  }),

  // Create a temporary prop (`incomingProps`) that holds any incoming props
  // that aren't required by us. This ensures we don't overwrite/delete/clobber any props that may
  // need to be passed through.
  withProps(({ initialFormState: _initialFormState, ...incomingProps }) => ({
    incomingProps: incomingProps,
  })),

  /**
   * Create a FormState, initializing it with the value from `initialValue`,
   * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
   */
  withFormState(({ contact }) =>
    CandidatePortalInviteField.createFieldState('invite-to-portal', contact),
  ),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onHide,
      onResetFormState,
      onSuccess,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();
        onHide(selectn('payload.result', transaction));
        onSuccess();
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  connect(null, dispatch => ({
    createUser: bindActionCreators(createUserActionCreator, dispatch),
  })),

  /**
   * Add a callback to handle submitting the action form.
   */
  withHandlers({
    // Called when the form should be submitted.
    onSubmit: ({
      contactId,
      createUser,
      formState,
      onFormStateChange,
      startTransaction,
    }) => e => {
      const transactionId = startTransaction();
      const fieldValue = formState.getFieldValue();
      const values = fromJS({})
        .merge(fieldValue)
        .merge({ contact_id: contactId });
      const action = createUser;

      action({
        contactId: contactId,
        user: values.toJS(),
        transactionId: transactionId,
      });

      onFormStateChange(formState.startSubmit(transactionId));

      // Prevent default browser behavior, which could cause the browser to attempt
      // to submit a form on it's own.
      return preventDefaultHandler(e);
    },
  }),

  withValidatedContactEmailFormState,

  // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
  mapProps(
    ({
      formState,
      incomingProps,
      onCancel,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      onSubmit,
    }) => ({
      ...incomingProps,
      formState: formState,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onResetFormState: onResetFormState,
      onSubmit: onSubmit,
      onCancel: onCancel,
    }),
  ),
);

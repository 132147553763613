import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@thrivetrm/ui/components/Menu';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';

const AssessmentCategoryDrawerAction = ({
  dependentAssessmentsCount,
  id,
  isLocked,
  name,
  onRemove,
}) => {
  const [isDialogOpen, openDialog, closeDialog] = useToggle(false);

  return (
    <>
      <ConfirmationDialog
        cancelLabel='No, Keep Category'
        confirmLabel='Yes, Remove Category'
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onConfirm={() => onRemove(id)}
        title='Are you sure?'
      >
        <div>
          This <b>{name}</b> category has been used on{' '}
          <b>{dependentAssessmentsCount}</b> candidate assessments. If you
          delete it, that data will be removed from those candidate assessments
          and will not be recoverable.
        </div>
        <br />
        <div>Do you still want to delete it?</div>
      </ConfirmationDialog>
      <Menu
        button={
          <ButtonPrimary
            className='u-border-n'
            icon='options'
            isDarkMode={true}
            isOutline={true}
            size='small'
          />
        }
        isPinnedRight={true}
      >
        <Menu.Item onClick={() => (isLocked ? openDialog() : onRemove(id))}>
          Remove
        </Menu.Item>
      </Menu>
    </>
  );
};

AssessmentCategoryDrawerAction.propTypes = {
  dependentAssessmentsCount: PropTypes.number,
  id: PropTypes.number.isRequired,
  isLocked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

export default AssessmentCategoryDrawerAction;

import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import GenerateReportModal from 'modules/reports/components/GenerateReportModal';
import SearchSummaryReportField from './SearchSummaryReportField';
import withSearchSummaryReportForm from './withSearchSummaryReportForm';

export default compose(
  setDisplayName('SearchSummaryReportModal'),
  setPropTypes({
    reportType: PropTypes.string,
    searchId: PropTypes.number.isRequired,
  }),
  withSearchSummaryReportForm,
  withProps(
    ({ formState, handleFieldStateChange, isSubmitting, searchId }) => ({
      children: (
        <SearchSummaryReportField
          className='form'
          disabled={isSubmitting}
          fieldState={formState.getFieldState()}
          onChange={handleFieldStateChange}
          searchId={searchId}
        />
      ),
      sentTo: formState.getFieldState().getNestedField('emails').getValue(),
      title: 'Generate Summary Report',
    }),
  ),
)(GenerateReportModal);

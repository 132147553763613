import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import currencySchema from 'modules/tenant/schemas/currencies';

/**
 * Gets the symbol for a currency type.
 * @param {*} state
 * @param {String} id The symbol ID/key ("USD", etc..)
 * @return {String} The currency symbol ("$", etc....)
 */
export default (state, id) =>
  getEntityProperty(state, currencySchema.key, id, 'symbol');

import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import isCandidateUser from 'modules/auth/selectors/isCandidateUser';
import FieldState from 'modules/forms/FieldState';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import TenantOptionsSelectField from 'modules/tenant/components/TenantOptionsSelectField';
import seekingJobReasons from 'modules/tenant/schemas/seekingJobReasons';
import positionFunctions from 'modules/tenant/schemas/positionFunctions';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { SEEKING_JOB_OPTIONS } from '../constants';
import labels from '../../../labels/index.json';

/**
 * A field for editing a contact's job opportunity status related values
 */
const ContactJobOpportunityStatusField = ({
  fieldState,
  handleNestedFieldChange,
  isCandidate,
}) => {
  const hasAvailableTalentFunction = useFeatureCheck(
    'field.contact.available_talent_function',
  );
  const seekingJobLabel =
    labels.seekingJob[isCandidate ? 'candidate' : 'noncandidate'];

  return (
    <div className='ContactJobOpportunityStatusField row'>
      <div className='col-sm-3'>
        <ReactSelectField
          className='ContactJobOpportunityStatusField__seeking_job_status'
          clearable={true}
          fieldState={fieldState.getNestedField('seeking_job_status')}
          key='seeking_job_status'
          label={seekingJobLabel}
          name='seeking_job_status'
          onChange={handleNestedFieldChange}
          options={SEEKING_JOB_OPTIONS}
          placeholder='Select status...'
          simpleValue={true}
        />
      </div>
      <div className='col-sm-3'>
        <TenantOptionsSelectField
          className='ContactJobOpportunityStatusField__seeking_job_reason'
          clearable={true}
          fieldState={fieldState.getNestedField('seeking_job_reason_id')}
          label='Reason'
          name='seeking_job_reason_id'
          onChange={handleNestedFieldChange}
          placeholder='Select Reason...'
          schema={seekingJobReasons}
        />
      </div>
      {hasAvailableTalentFunction ? (
        <div className='col-sm-3'>
          <TenantOptionsSelectField
            clearable={true}
            fieldState={fieldState.getNestedField(
              'available_talent_function_id',
            )}
            key='available_talent_function'
            label='Available Talent Function'
            onChange={handleNestedFieldChange}
            placeholder='Select a function...'
            schema={positionFunctions}
          />
        </div>
      ) : null}
    </div>
  );
};

ContactJobOpportunityStatusField.propTypes = {
  /**
   * The field state containing the nested value.
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * Called when a nested field is changed.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * Determines whether or not current_user is a candidate user
   */
  isCandidate: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('ContactJobOpportunityStatusField(enhanced)'),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic(
    'createFieldState',
    (
      name = 'ContactJobOpportunityStatusForm',
      contact,
      validator,
      _,
      ...rest
    ) => {
      const values = new Map({
        seeking_job_status: null,
        seeking_job_reason_id: null,
      }).merge(contact);

      return FieldState.createNested(
        name,
        [
          ReactSelectField.createFieldState(
            'seeking_job_status',
            values.get('seeking_job_status'),
          ),
          TenantOptionsSelectField.createFieldState(
            'seeking_job_reason_id',
            values.get('seeking_job_reason'),
          ),
          TenantOptionsSelectField.createFieldState(
            'available_talent_function_id',
            values.get('available_talent_function'),
          ),
        ],
        ...rest,
      );
    },
  ),
  withNestedFieldChangeHandler,
  connect(state => ({ isCandidate: isCandidateUser(state) }), {}),
)(ContactJobOpportunityStatusField);

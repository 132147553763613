import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import pronounSchema from '../schemas/pronouns';

/**
 * A tag select component for choosing tags from the list of pronouns.
 */
const PronounTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField
    {...props}
    schema={pronounSchema}
    shouldRenderAsInput={true}
  />
);

PronounTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default PronounTagSelectField;

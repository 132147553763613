import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import taskSchema from '../schema';

/**
 * Gets the ID of a task's contact
 * @param {*} state
 * @param {Number} taskId The ID of the task.
 * @return {Number} The ID of the task's contact
 */
export default (state, taskId) =>
  getEntityProperty(state, taskSchema.key, taskId, 'contact');

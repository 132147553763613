import React from 'react';
import PropTypes from 'prop-types';
import OffersPanel from 'modules/search-offers/OffersPanel';
import { withStateProvider } from './app/StateProvider';

const OffersContainer = ({ companyLogoUrl, companyName, searchId }) => (
  <OffersPanel
    companyLogoUrl={companyLogoUrl}
    companyName={companyName}
    searchId={searchId}
  />
);

OffersContainer.propTypes = {
  companyLogoUrl: PropTypes.string,
  companyName: PropTypes.string,
  searchId: PropTypes.number,
};

export default withStateProvider(OffersContainer);

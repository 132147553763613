import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders an message if selected contacts are already in a search.
 */
const ContactsInSearchMessage = ({
  contactIds,
  contactsInSearch,
  searchTypeLabel,
}) => {
  const getMessage = () => {
    let subject = `${contactsInSearch} of the ${contactIds.length} selected contacts are`;
    if (contactsInSearch === 1) {
      subject = 'The contact is';
    } else if (contactsInSearch === contactIds.length) {
      subject = 'All of the selected contacts are';
    }
    return `${subject} already in this ${searchTypeLabel.toLowerCase()} and will not be updated.`;
  };

  return contactsInSearch ? (
    <p className='alert alert-danger'>{getMessage()}</p>
  ) : null;
};

ContactsInSearchMessage.propTypes = {
  /**
   * The array of selected contactIds
   */
  contactIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,

  /**
   * The number of contacts already in search
   */
  contactsInSearch: PropTypes.number,
  searchTypeLabel: PropTypes.string.isRequired,
};

export default ContactsInSearchMessage;

import getPathName from 'modules/routing/selectors/getPathName';
import getQueryValue from 'modules/routing/selectors/getQueryValue';

/**
 * Gets the ID of the contact currently selected in the dashboard page.
 * @param {Object} state
 * @return {Number|null} The ID of the select contact (or "incumbent"), or null if no contact ID
 *   is selected.
 */
export default state =>
  (getPathName(state).indexOf('/talent_pools') === 0 &&
    parseInt(getQueryValue(state, 'contact'))) ||
  null;

import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import companySchema from '../companySchema';

/**
 * Renders a property value for a company, given the property name and company ID
 */
export default compose(
  setDisplayName('CompanyProperty'),
  setPropTypes({
    component: EntityProperty.propTypes.component,
    formatter: EntityProperty.propTypes.formatter,
    companyId: PropTypes.number.isRequired,
    propertyName: EntityProperty.propTypes.propertyName,
  }),
  defaultProps(EntityProperty.defaultProps),
  mapProps(({ companyId, ...rest }) => ({
    ...rest,
    entityType: companySchema.key,
    entityId: companyId,
  })),
)(EntityProperty);

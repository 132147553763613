import getPriorityVoteCount from './getPriorityVoteCount';
import getSortByCompanyName from './getSortByCompanyName';

/**
 * Gets a value to sort on a target companies priority vote count, and secondarily sorting on
 * the company name, given a target company ID
 */
export default (state, targetCompanyId) => [
  -getPriorityVoteCount(state, targetCompanyId),
  getSortByCompanyName(state, targetCompanyId),
];

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import DateTime from 'modules/datetime/components/DateTime';
import getBulkImportProperty from '../selectors/getBulkImportProperty';

const BulkImportDateTime = ({ timestamp }) =>
  timestamp ? <DateTime format='L LT' value={timestamp} /> : <i>Pending</i>;

BulkImportDateTime.propTypes = {
  timestamp: PropTypes.string,
};

export default compose(
  setDisplayName('BulkImportDateTime(enhanced)'),
  setPropTypes({
    bulkImportId: PropTypes.number.isRequired,
    timestampProp: PropTypes.oneOf(['start_time', 'end_time']).isRequired,
  }),
  connect((state, { bulkImportId, timestampProp }) => ({
    timestamp: getBulkImportProperty(state, bulkImportId, timestampProp),
  })),
)(BulkImportDateTime);

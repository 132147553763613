import getEntity from 'modules/entities/selectors/getEntity';
import revenueSchema from '../schema';

/**
 * Gets a revenue entity from it's ID
 * @param {*} state
 * @param {Number} revenueId The ID of the revenue entity.
 * @return {Map} The revenue entity record, if in state.
 */
export default (state, revenueId) =>
  getEntity(state, revenueSchema.key, revenueId);

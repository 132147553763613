import { compose, pure, setDisplayName } from 'recompose';
import Link from 'modules/routing/components/Link';

import withSearchSidebarHref from './withSearchSidebarHref';

/**
 * Renders a link, optionally to a specific tab, on the current search page.
 */
export default compose(
  setDisplayName('SearchSidebarLink'),
  withSearchSidebarHref,
  pure,
)(Link);

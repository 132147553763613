import { NAME } from '../constants';

/**
 * Gets the current tenant's default company's id
 * @param {Object} state
 * @return {String} The current tenant's name.
 */
const getDefaultCompany = state => state[NAME].get('default_company_id');

export default getDefaultCompany;

import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import { useSelector } from 'react-redux';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';
import ContactSelectField from 'modules/contacts/components/ContactSelectField';
import UserSelectField from 'modules/users/components/UserSelectField';
import CompanySelectField from 'modules/companies/components/CompanySelectField';
import MonthYearSelectField from 'modules/datetime/components/MonthYearSelectField';
import * as validators from 'modules/forms/validators';
import SelectMenuField from 'modules/contacts/components/contactConnection/SelectMenuField';
import {
  CONNECTION_TYPE_GROUPS,
  getConnectionTypeLabel,
  RecordTypesWithConnections,
} from 'services/apiV1/connections';
import selectedContact from 'selectors/contact/selectedContact';
import './ContactConnectionListField.scss';
import moment from 'moment';

/**
 * Displays a field for editing a connection record.
 */

const ConnectionField = ({
  connectionStrengths,
  connectionTypes,
  fieldState,
  onChange,
}) => {
  const contact = useSelector(state => selectedContact(state));
  const contactName =
    contact.getIn(['data', 'first_name']) ||
    contact.getIn(['data', 'full_name']);

  const connectionRecordType = fieldState
    .getNestedField('connection_type')
    .getValue();

  const isExistingConnection = Boolean(
    fieldState.getNestedField('id').getValue(),
  );

  const referralConnectionTypeIds = connectionTypes
    ?.filter(connection => connection.group === 'Referrals')
    .map(connection => connection.id);

  const handleFieldChange = childFieldState => {
    onChange(fieldState.setNestedField(childFieldState));
  };

  const getConnectionTypesItems = () => {
    const items = [];
    CONNECTION_TYPE_GROUPS.forEach(connectionTypeGroup => {
      const groupItems = connectionTypes?.filter(
        item =>
          item.group === connectionTypeGroup &&
          (connectionRecordType === RecordTypesWithConnections.CONTACT
            ? item.name !== 'Relationship Manager'
            : true),
      );
      if (groupItems?.length) {
        items.push(
          <SelectMenu.Heading key={connectionTypeGroup}>
            {connectionTypeGroup}
          </SelectMenu.Heading>,
        );
        groupItems.forEach(connectionTypeOption => {
          items.push(
            <SelectMenu.Item
              key={connectionTypeOption.id}
              value={connectionTypeOption.id}
            >
              {getConnectionTypeLabel(connectionTypeOption, contactName)}
            </SelectMenu.Item>,
          );
        });
      }
    });
    return items;
  };

  const handleConnectionTypeChange = newFieldState => {
    const connectionTypeId = fieldState.getValue();

    if (referralConnectionTypeIds?.includes(connectionTypeId)) {
      onChange(onChange(fieldState.setNestedField(newFieldState)));
    } else {
      // sets referral company field to null when the user selects general type of connection
      onChange(
        fieldState
          .setNestedFieldValue('connection_type_id', newFieldState.getValue())
          .setNestedFieldValue('referral_company_id', null),
      );
    }
  };

  return (
    <div className='row'>
      {connectionRecordType === RecordTypesWithConnections.CONTACT ? (
        <div className='col-6'>
          <ContactSelectField
            clearable={true}
            disabled={isExistingConnection}
            fieldState={fieldState.getNestedField('connected_contact_id')}
            isCreatable={false}
            label='Contact Name*'
            multi={false}
            name='Contact name'
            onChange={handleFieldChange}
            placeholder='Select a contact'
          />
        </div>
      ) : null}
      {connectionRecordType === RecordTypesWithConnections.USER ? (
        <div className='col-6'>
          <UserSelectField
            clearable={true}
            disabled={isExistingConnection}
            fieldState={fieldState.getNestedField('connected_user_id')}
            label='User Name*'
            multi={false}
            name='User name'
            onChange={handleFieldChange}
            placeholder='Select a user'
          />
        </div>
      ) : null}
      <div className='col-6'>
        <SelectMenuField
          fieldState={fieldState.getNestedField('connection_type_id')}
          inputWidth='full'
          label='Connection Type*'
          name='Select Connection type'
          onChange={handleConnectionTypeChange}
          placeholder='Select connection type'
        >
          {getConnectionTypesItems()}
        </SelectMenuField>
      </div>
      <div className='col-6'>
        <MonthYearSelectField
          fieldState={fieldState.getNestedField('connected_on')}
          label='Connection Date'
          minYear={-65}
          name='connection date'
          onChange={handleFieldChange}
        />
      </div>
      <div className='col-6'>
        <SelectMenuField
          fieldState={fieldState.getNestedField('connection_strength_id')}
          inputWidth='full'
          label='Strength'
          name='strength'
          onChange={handleFieldChange}
          placeholder='Select connection strength'
        >
          {connectionStrengths?.map(item => (
            <SelectMenu.Item key={item.id} value={item.id}>
              {item.name}
            </SelectMenu.Item>
          ))}
        </SelectMenuField>
      </div>
      {referralConnectionTypeIds?.includes(
        fieldState.getNestedField('connection_type_id').getValue(),
      ) ? (
        <div className='col-6'>
          <CompanySelectField
            allowCreate={false}
            clearable={true}
            fieldState={fieldState.getNestedField('referral_company_id')}
            label='Referral Company'
            name='Referral Company'
            onChange={handleFieldChange}
            placeholder='Select a company'
          />
        </div>
      ) : null}
      <div className='col-12'>
        <TextAreaField
          fieldState={fieldState.getNestedField('notes')}
          label='Notes'
          onChange={handleFieldChange}
          placeholder='Enter notes about this connection'
        />
      </div>
    </div>
  );
};

ConnectionField.propTypes = {
  connectionStrengths: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),
  connectionTypes: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),

  /**
   * The FieldState that manages the value of the individual connection form control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the value is changed, with the updated fieldState.
   */
  onChange: PropTypes.func.isRequired,
};

const createFieldState = (
  name = 'connection',
  connection,
  connectionRecordType,
) => {
  const values = fromJS({
    connected_user_id: null,
    connected_contact_id: null,
    notes: '',
    connection_type_id: '',
    connection_strength_id: '',
    connected_on: moment().toISOString(),
    referral_company_id: null,
  }).merge(connection);

  return FieldState.createNested(
    name,
    [
      UserSelectField.createFieldState(
        'connected_user_id',
        values.get('connected_user_id'),
        connectionRecordType === RecordTypesWithConnections.USER
          ? validators.requiredField('User name')
          : null,
      ),
      ContactSelectField.createFieldState(
        'connected_contact_id',
        values.get('connected_contact_id'),
        connectionRecordType === RecordTypesWithConnections.CONTACT
          ? validators.requiredField('Contact name')
          : null,
      ),
      SelectMenuField.createFieldState(
        'connection_type_id',
        values.get('connection_type_id'),
        validators.requiredField('Connection type'),
      ),
      SelectMenuField.createFieldState(
        'connection_strength_id',
        values.get('connection_strength_id'),
      ),
      MonthYearSelectField.createFieldState(
        'connected_on',
        values.get('connected_on'),
      ),
      CompanySelectField.createFieldState(
        'referral_company_id',
        values.get('referral_company_id'),
      ),
      TextAreaField.createFieldState('notes', values.get('notes')),
      FieldState.create('connection_type', connectionRecordType),
      FieldState.create('id', values.get('id')),
    ],
    null,
    ({ connected_on, ...other }) => ({
      ...other,
      connected_on: moment(connected_on).toISOString(),
    }),
  );
};

ConnectionField.createFieldState = createFieldState;

export default ConnectionField;

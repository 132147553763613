import getEmailTemplatesList from './getEmailTemplatesList';

/**
 * Determines if the email template records need to be fetched from the server.
 * @param {Object} state
 * @return {Boolean} True if the email template records need to be fetched from the server;
 *   Otherwise, false.
 */
export default state => {
  const emailTemplatesList = getEmailTemplatesList(state);

  if (
    emailTemplatesList.getIn(['_meta', 'isFetching']) ||
    emailTemplatesList.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  if (!emailTemplatesList.has('ids')) {
    return true;
  }

  return Boolean(emailTemplatesList.getIn(['_meta', 'isInvalidated']));
};

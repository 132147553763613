import React from 'react';
import PropTypes from 'prop-types';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import ContactSnapshot from 'modules/contacts/components/ContactSnapshot';
import { useGetContactQuery } from 'services/apiV1/contact';
import useQuickView from './useQuickView';

const ContactQvpHeader = ({ contactId, onBack }) => {
  const { close, title } = useQuickView();

  const { data: contact, isFetching: isLoadingContact } = useGetContactQuery(
    contactId,
  );

  return (
    <SidePanel.Header
      className='u-border-n'
      onBack={onBack}
      onClose={close}
      title={title}
    >
      <ContactSnapshot
        avatarUrl={contact?.avatarUrl}
        className='u-flexItem-grow'
        company={
          isLoadingContact
            ? null
            : {
                id: contact?.primaryPosition?.company?.id,
                name:
                  contact?.primaryPosition?.company?.name ||
                  contact?.primaryPosition?.companyName,
              }
        }
        id={contactId}
        name={title}
        shouldRenderAsHeadings={true}
        title={isLoadingContact ? null : contact?.primaryTitle}
      />
    </SidePanel.Header>
  );
};

ContactQvpHeader.propTypes = {
  contactId: PropTypes.number.isRequired,
  onBack: PropTypes.func,
};

export default ContactQvpHeader;

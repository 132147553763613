import isInterviewPast from './isInterviewPast';
import { DATE_GROUP_PAST, DATE_GROUP_UPCOMING } from '../constants';

/**
 * Returns the date group that an interview belongs to.
 * @param {Object} state
 * @param {Number} interviewId The interview ID.
 * @return {String} Either DATE_GROUP_PAST or DATE_GROUP_UPCOMING, depending on the date value
 *   of the interview.
 */
export default (state, interviewId) =>
  isInterviewPast(state, interviewId) ? DATE_GROUP_PAST : DATE_GROUP_UPCOMING;

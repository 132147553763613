import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, withProps, setPropTypes } from 'recompose';
import mapCandidacyIdToSearchId from 'modules/candidacies/components/mapCandidacyIdToSearchId';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import withFormToggleButton from '../../../components/forms/withFormToggleButton';

import ReferenceForm from './ReferenceForm';
import ReferenceList from './ReferenceList';
import withReferenceListFetched from './withReferenceListFetched';
import { PARENT_CANDIDACY, PARENT_CONTACT } from '../constants';
import canCreateReference from '../selectors/canCreateReference';

const AddReferenceFormToggleButton = withFormToggleButton(ReferenceForm);

/**
 * A panel that displays a collection of references, allowing for editing, deleting, replying, and
 * creating new references.
 */
const ReferencesPanel = ({
  canCreateReference: allowCreate,
  candidacyId,
  contactId,
  handleRefresh,
  popoverPlacement,
  referenceList,
  searchId,
}) => {
  const isFetching =
    referenceList && referenceList.getIn(['_meta', 'isFetching']);
  const hasError =
    referenceList && Boolean(referenceList.getIn(['_meta', 'error']));

  return (
    <div className='references--references-panel'>
      {allowCreate && (
        <AddReferenceFormToggleButton
          candidacyId={candidacyId}
          contactId={contactId}
          draftStoragePath={{
            search: searchId,
            candidate: candidacyId,
            reference: 'new',
          }}
          errorDisplay='tooltip'
          excludeSearchField={!contactId}
          searchId={searchId}
          title='Add reference'
        >
          New Reference
        </AddReferenceFormToggleButton>
      )}
      <div className='references--references-panel-container'>
        <div className='references--references-panel-scroll-container'>
          {referenceList && referenceList.get('ids') && (
            <ReferenceList
              candidacyId={candidacyId}
              contactId={contactId}
              popoverPlacement={popoverPlacement}
              referenceIds={referenceList.get('ids')}
              searchId={searchId}
            />
          )}
          {hasError && !isFetching && (
            <ErrorAlert
              onRetry={handleRefresh}
              title='There was an error fetching references'
            />
          )}
          {isFetching && <LoadingIndicator />}
        </div>
      </div>
    </div>
  );
};

export const propTypes = {
  canCreateReference: PropTypes.bool,

  candidacyId: requiredIf(
    PropTypes.number,
    ({ parentType }) => parentType === PARENT_CANDIDACY,
  ),

  contactId: requiredIf(
    PropTypes.number,
    ({ parentType }) => parentType === PARENT_CONTACT,
  ),

  handleRefresh: PropTypes.func.isRequired,

  /**
   * Optional string to set orientation of delete confirm popovers (if none is
   * passed it defaults to top)
   */
  popoverPlacement: PropTypes.string,

  referenceList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      error: PropTypes.any,
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),

  searchId: requiredIf(
    PropTypes.number,
    ({ parentType }) => parentType === PARENT_CANDIDACY,
  ),
};

export const defaultProps = {
  canCreateReference: false,
};

ReferencesPanel.propTypes = propTypes;
ReferencesPanel.defaultProps = defaultProps;

export default compose(
  setPropTypes({
    candidacyId: requiredIf(PropTypes.number, ({ contactId }) => !contactId),
    contactId: requiredIf(PropTypes.number, ({ candidacyId }) => !candidacyId),
    searchId: requiredIf(PropTypes.number, ({ contactId }) => !contactId),
  }),
  withProps(({ candidacyId, contactId }) => ({
    parentType: candidacyId ? PARENT_CANDIDACY : PARENT_CONTACT,
    parentId: candidacyId || contactId,
  })),
  connect(
    state => ({
      canCreateReference: canCreateReference(state),
    }),
    {},
  ),
  mapCandidacyIdToSearchId,
  withReferenceListFetched,
  withProps(({ fetchReferenceList }) => ({
    handleRefresh: fetchReferenceList,
  })),
)(ReferencesPanel);

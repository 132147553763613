import routes from 'modules/routing/routes';
import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import {
  INVESTMENT_LIST_FETCH_START,
  INVESTMENT_LIST_FETCH_SUCCESS,
  INVESTMENT_LIST_FETCH_FAILURE,
} from './actionTypes';
import investmentSchema from '../schema';

/**
 * Creates an action for fetching a list of investments for a company
 * @param {Object} payload
 * @param {String} payload.companyId The ID of the company to fetch the investments for.
 */
export default compose(
  fetchAction,
  normalizeResponse({ investments: [investmentSchema] }),
  createAsyncActions({
    startActionType: INVESTMENT_LIST_FETCH_START,
    successActionType: INVESTMENT_LIST_FETCH_SUCCESS,
    failureActionType: INVESTMENT_LIST_FETCH_FAILURE,
  }),
  withOptions(({ companyId }) => ({
    url: routes.api_v1_company_investments({ companyId: companyId }),
  })),
);

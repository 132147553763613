import invalidateCompany from 'modules/companies/actions/invalidateCompany';
import updateEntity from 'modules/entities/actions/updateEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import getInvestmentProperty from 'modules/company-investments/selectors/getInvestmentProperty';
import investmentSchema from 'modules/company-investments/schema';
import routes from 'modules/routing/routes';

/**
 * Creates an action for updating a funding round
 * @param {Object} payload
 * @param {Object} payload.fundingRound The funding round (investment) properties
 * @param {Number} payload.fundingRound.round The ID of the company stage that applies to this
 *  round.
 * @param {Number} payload.fundingRound.id The funding round ID that is being updated
 */
export default ({ fundingRound: { round, ...fundingRound }, ...rest }) => (
  dispatch,
  getState,
) =>
  dispatch(
    updateEntity({
      ...rest,
      body: {
        funding_round: {
          round_id: round,
          ...fundingRound,
        },
      },
      entityType: investmentSchema.key,
      id: fundingRound.id,
      responseSchema: { funding_round: investmentSchema },
      url: routes.api_v1_funding_round({ id: fundingRound.id }),
    }),
  ).then(action => {
    // If the update was success, the company needs to be refreshed because it may
    // have a new total funding amount.
    if (action.type === entityActionTypes.UPDATE_SUCCESS) {
      const companyId = getInvestmentProperty(
        getState(),
        fundingRound.id,
        'venture',
      );
      if (companyId) {
        dispatch(invalidateCompany({ id: companyId }));
      }
    }

    return action;
  });

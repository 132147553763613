import getInterviewProperty from './getInterviewProperty';

/**
 * Gets the `interview_type` field value for an interview by it's ID
 * @param {*} state
 * @param {Number} interviewId
 * @return {String} The interview type.
 */
export default (state, interviewId) =>
  getInterviewProperty(state, interviewId, 'label');

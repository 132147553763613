import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AssessmentListItem from './AssessmentListItem';

// We'll need to provide a specific sorting algorithm to sort assessments so they match the behavior
// provided by the backend. Without some reworking of the FE and BE, we cannot rely on the BE's
// order for returned records, as the UI allows for adding new assessments. We need to be able to
// properly order those assessments after creation.
//
// The expected order result is:
// - All records should be split into 2 groups, those with assessed_on values, and those without
// - The assessed_on group should be ordered first
// - Within each group, the records should be sorted from latest to oldest (reverse chronological)
const assessmentSorter = (a1, a2) => {
  const a1AssessedOn = a1.get('assessed_on');
  const a2AssessedOn = a2.get('assessed_on');

  // If both records have an assessed_on value, we'll compare them in reverse chron order
  if (a1AssessedOn && a2AssessedOn) {
    return Date.parse(a2AssessedOn) - Date.parse(a1AssessedOn);
  }

  // If a2 does not have an assessed_on value, we'll bubble a1 up
  if (a1AssessedOn && !a2AssessedOn) {
    return -1;
  }

  // If a1 does not have an assessed_on value, we'll bubble a2 up
  if (!a1AssessedOn && a2AssessedOn) {
    return 1;
  }

  // For records without assessed_on values, we'll fall back to comparing their updated_at dates
  return Date.parse(a2.get('updated_at')) - Date.parse(a1.get('updated_at'));
};

/**
 * Renders a list of assessments, with the ability to edit or delete them
 * inline if `readOnly` is false and the user has permission (based on the
 * `permissions.edit` and `permissions.delete` values of the assessment item).
 */
export const AssessmentList = ({
  assessments,
  candidacyId,
  readOnly,
  searchId,
}) => {
  return (
    <ul className='list-generic'>
      {assessments
        .sort(assessmentSorter)
        .map(assessment => {
          return (
            <AssessmentListItem
              assessment={assessment}
              assessments={assessments}
              candidacyId={candidacyId}
              key={assessment.get('id')}
              readOnly={readOnly}
              searchId={searchId}
            />
          );
        })
        .toArray()}
    </ul>
  );
};

AssessmentList.defaultProps = {
  readOnly: false,
};

AssessmentList.propTypes = {
  /**
   * The assessments to be rendered
   */
  assessments: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,

  candidacyId: PropTypes.number,
  /**
   * When true, the underlying list items cannot be edited/deleted, regardless
   * of the assessment record permissions.
   */
  readOnly: PropTypes.bool,

  searchId: PropTypes.number,
};

export default AssessmentList;

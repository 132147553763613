import startTransaction from 'modules/transactions/actions/startTransaction';
import finishTransaction from 'modules/transactions/actions/finishTransaction';
import {
  NOTIFICATIONS_UPDATE_READ_START,
  NOTIFICATIONS_UPDATE_READ_SUCCESS,
  NOTIFICATIONS_UPDATE_READ_FAILURE,
  BASE_URL,
} from '../../domains/notifications/constants';
import ajax from '../ajax';

export default function updateRead(id, toRead, transactionId) {
  // If `toRead` was not supplied, default to making this item read,
  // otherwise use the value given.
  const read = typeof toRead === 'undefined' ? true : Boolean(toRead);

  return dispatch => {
    const payload = {
      id: id,
      read: read,
    };

    dispatch({
      type: NOTIFICATIONS_UPDATE_READ_START,
      payload: payload,
    });

    if (transactionId) {
      dispatch(startTransaction(transactionId, payload));
    }

    ajax(`${BASE_URL}/${id}/read`, {
      method: read ? 'post' : 'delete',
    })
      .then(data => ({
        type: NOTIFICATIONS_UPDATE_READ_SUCCESS,
        payload: { ...payload, data: data },
      }))
      .catch(error => ({
        type: NOTIFICATIONS_UPDATE_READ_FAILURE,
        payload: { ...payload, error: error },
      }))
      .then(dispatch)
      .then(action => {
        if (transactionId) {
          dispatch(finishTransaction(transactionId, action.payload));
        }
      });
  };
}

import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, setDisplayName, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import connectTransactions from 'modules/transactions/components/connectTransactions';

import connectOutreachActions from './connectOutreachActions';
import handleSubmit from './handleSubmit';
import mapOutreachIdToOutreach from './mapOutreachIdToOutreach';
import OutreachField from './OutreachField';

import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';
import withDraftStorage from '../../../components/forms/withDraftStorage';

export const createRootFieldState = ({
  contactId,
  draft,
  outreach,
  searchId,
}) => {
  let outreachValue;
  if (outreach) {
    // When editing an existing outreach
    outreachValue = outreach.withMutations(immutableOutreach => {
      if (draft?.content) {
        immutableOutreach.set('notes', draft?.content);
      }
    });
  } else {
    // When creating a new outreach
    outreachValue = fromJS({
      contact_id: contactId,
      notes: draft?.content,
      searches: searchId ? [searchId] : [],
    });
  }

  return OutreachField.createFieldState('outreach', outreachValue);
};

export const propTypes = {
  /**
   * THe ID of the contact this outreach should be associated with. Required when creating a new
   * outreach
   */
  contactId: requiredIf(
    PropTypes.number,
    ({ outreach, outreachId }) => !(outreach || outreachId),
  ),

  /**
   * The outreach record if editing an existing outreach. Either this or `outreachId` may be
   * supplied -- it's not necessary to supply both.
   */
  outreach: ImmutablePropTypes.mapContains({
    id: PropTypes.number.isRequired,
  }),

  /**
   * The ID of an outreach to edit an existing outreach. Either this or `outreach` may be
   * supplied -- it's not necessary to supply both.
   */
  outreachId: PropTypes.number,

  searchId: PropTypes.number,
};

export default compose(
  setDisplayName('OutreachField(enhanced)'),

  // At least ONE of the following props are required: An `outreach` itself or an `outreachId`
  // (if editing an existing outreach), otherwise a is `contactId` required.
  setPropTypes(propTypes),

  // If no outreach record was given but an outreachId was, get the outreach record by ID and
  // add it as a prop.
  branch(props => props.outreachId && !props.outreach, mapOutreachIdToOutreach),

  connectOutreachActions,
  connectTransactions,
  withDraftStorage(),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'OutreachForm',
    omitProps: [
      'dispatch',
      'contactId',
      'outreach',
      'outreachId',
      'outreachActions',
      'formState',
      'transactionActions',
    ],
  }),
)(OutreachField);

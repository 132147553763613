import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Form from '@thrivetrm/ui/components/Form';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import { useApiPatch } from '@thrivetrm/ui/hooks/useApi';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import EditIconButton from 'modules/forms/components/EditIconButton';
import routes from 'modules/routing/routes';
import { toastError } from 'modules/toast-notifications/toastNotificationsSlice';
import getContactProperty from '../selectors/contacts/getContactProperty';

const ContactCalledToTheBarEditableSection = ({ contactId }) => {
  const dispatch = useDispatch();
  const [updateContact, isUpdating] = useApiPatch(
    routes.api_v1_contact({ id: contactId }),
  );
  const [isEditing, showEditing, closeEditing] = useToggle(false);

  const contactsCalledToThebar =
    useSelector(state =>
      getContactProperty(state, contactId, 'called_to_the_bar'),
    ) || '';

  const [calledToTheBar, setCalledToTheBar] = useState(contactsCalledToThebar);

  const handleSubmit = formData => {
    const { called_to_the_bar } = formData;
    updateContact(
      { id: contactId, contact: { called_to_the_bar: called_to_the_bar } },
      {
        onSuccess: () => {
          closeEditing();
        },
        onError: errorMessage => {
          dispatch(toastError(errorMessage));
        },
      },
    );
    setCalledToTheBar(called_to_the_bar);
  };
  return (
    <div className='u-marginBottom-16'>
      <h4 className='u-flexAlign-c u-inlineFlex u-margin-n'>
        <span className='u-textColor-gray50'>Called to the Bar</span>

        <EditIconButton
          className='u-marginLeft-8 u-padding-n btn-link'
          disabled={isEditing}
          iconClassName='fa-pencil'
          onClick={showEditing}
        />
      </h4>
      <div>
        {isEditing ? (
          <Form onSubmit={handleSubmit}>
            <Form.TextInput
              className='u-marginTop-12 u-marginBottom-16'
              initialValue={calledToTheBar}
              inputWidth='large'
              name='called_to_the_bar'
            />
            <div className='u-flex u-flexJustify-r'>
              <ButtonPrimary
                isOutline={true}
                label='Cancel'
                onClick={closeEditing}
                size='small'
              />
              <Form.SubmitButton
                isLoading={isUpdating}
                label='Save'
                size='small'
              />
            </div>
          </Form>
        ) : (
          <span>{calledToTheBar}</span>
        )}
      </div>
    </div>
  );
};

ContactCalledToTheBarEditableSection.propTypes = {
  contactId: PropTypes.number.isRequired,
};

export default ContactCalledToTheBarEditableSection;

import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'modules/core/componentsLegacy/Modal';
import TalentPoolForm from './TalentPoolForm';

const TalentPoolFormModal = ({ contactId, onHide }) => (
  <Modal aria-labelledby='modal-title' onHide={onHide} show={true}>
    <Modal.Header closeButton={true}>
      <Modal.Title id='modal-title'>Add Talent Pool</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <TalentPoolForm
        contactId={contactId}
        onCancel={onHide}
        onSaved={onHide}
      />
    </Modal.Body>
  </Modal>
);

TalentPoolFormModal.propTypes = {
  contactId: PropTypes.number,
  onHide: PropTypes.func,
};

export default TalentPoolFormModal;

import PropTypes from 'prop-types';
import React from 'react';
import useQuickView from 'modules/quick-view/useQuickView';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import PluralText from '@thrivetrm/ui/components/PluralText';

/**
 * Renders the duplicate contact details.
 */
const DuplicateContactsViewCard = ({
  duplicateContactData,
  matchTypeName,
  onDuplicateSelect,
}) => {
  const { navigateTo } = useQuickView();
  return (
    <div className='u-marginTop-8'>
      {duplicateContactData ? (
        <>
          <div className='u-margin-8'>
            <span className='u-fontSize-medium u-textColor-gray60 u-fontWeight-bold'>
              {duplicateContactData.length}{' '}
              <PluralText
                quantity={duplicateContactData.length}
                text='Contact'
              />{' '}
              with matching {matchTypeName}
            </span>
          </div>
          <div className='u-margin-8'>
            {duplicateContactData.map(contact => (
              <div
                className='u-fontSize-medium u-textColor-gray60'
                key={contact.id}
              >
                {contact.name ? (
                  <ButtonLink
                    className='u-fontWeight-bold'
                    label={contact.name}
                    onClick={() => {
                      navigateTo(`/contacts/${contact.id}`, {
                        title: contact.name,
                      });
                    }}
                    type='button'
                  />
                ) : null}
                {contact.positionName ? (
                  <span className='u-fontWeight-normal'>
                    {', '}
                    {contact.positionName}
                  </span>
                ) : null}
                {contact.companyName ? (
                  <span className='u-marginLeft-4 u-fontWeight-normal'>
                    {'- '}
                    {contact.companyName}
                  </span>
                ) : null}
                {contact.email || contact.workEmail ? (
                  <span className='u-fontWeight-normal'>
                    {', '}
                    {contact.email ?? contact.workEmail}
                  </span>
                ) : null}
                <ButtonLink
                  className='u-marginLeft-4 u-fontWeight-bold'
                  label='Use this contact'
                  onClick={() => onDuplicateSelect(contact.id)}
                  type='button'
                />
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

DuplicateContactsViewCard.propTypes = {
  duplicateContactData: PropTypes.arrayOf(
    PropTypes.shape({
      companyName: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      positionName: PropTypes.string,
      workEmail: PropTypes.string,
    }),
  ),
  matchTypeName: PropTypes.string,
  /**
   * When provided and duplicate contacts are found, the duplicate contact will display a
   * "use this contact" link. When that is click, this callback is called with the contact ID
   * of the contact that was selected.
   */
  onDuplicateSelect: PropTypes.func,
};

export default DuplicateContactsViewCard;

import selectn from 'selectn';
import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import withTransaction from 'modules/transactions/components/withTransaction';
import getName from 'modules/contacts/selectors/contacts/getName';
import getPrimaryTitle from 'modules/contacts/selectors/contacts/getPrimaryTitle';
import withFormState from '../../../../components/forms/withFormState';
import TalentPoolReportField from './TalentPoolReportField';
import withTalentPoolActions from '../withTalentPoolActions';

/**
 * A higher-order component generator providing props for creating a component that takes
 * a TalentPoolReportField's fieldState and provides it's underlying form functionality
 */
export default compose(
  withTalentPoolActions,

  // need the name of the incumbent to prepopulate the report with.
  connect(
    (state, { contactId }) => ({
      incumbentName: getName(state, contactId),
      incumbentPrimaryTitle: getPrimaryTitle(state, contactId),
    }),
    {},
  ),

  // Create a formState from the `TalentPoolReportField`'s fieldState creator.
  withFormState(({ incumbentName, incumbentPrimaryTitle }) =>
    TalentPoolReportField.createFieldState('talent_pool', {
      incumbentPrimaryTitle: incumbentPrimaryTitle,
      incumbentName: incumbentName,
    }),
  ),

  withHandlers({
    // Handles the underlying fieldState change.
    handleFieldStateChange: ({ formState, onChange }) => fieldState =>
      onChange(formState.setFieldState(fieldState)),

    // Called by withTransaction when a transaction we started (in `handleSubmit`)
    // has been completed.
    onTransactionComplete: ({ formState, onChange }) => transaction =>
      onChange(formState.endSubmit(selectn('payload.error', transaction))),
  }),

  // provides `startTransaction` and `onTransactionComplete` props to track our submission
  withTransaction,

  withHandlers({
    handleSubmit: ({
      formState,
      onChange,
      startTransaction,
      talentPoolActions,
      talentPoolId,
    }) => e => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }

      const transactionId = startTransaction();
      const { emails, reportName } = formState.getFieldValue();
      talentPoolActions.createReport({
        title: reportName,
        emails: emails,
        talentPoolId: talentPoolId,
        transactionId: transactionId,
      });

      onChange(formState.startSubmit(transactionId));
    },
  }),

  // Some helper props that can be used by the wrapped component.
  withProps(({ formState }) => ({
    isComplete:
      formState.wasSubmitted() &&
      !formState.getError() &&
      !formState.isSubmitting(),
    isSubmitting: formState.isSubmitting(),
    wasSubmitted: formState.wasSubmitted(),
    error: formState.getError(),
  })),
);

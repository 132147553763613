import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, mapProps, withProps, setDisplayName } from 'recompose';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import { STAGE_TYPE_JOB_SEARCH, STAGE_TYPES } from '../constants';
import fetchStageListIfNeeded from './fetchStageListIfNeeded';
import mapStageTypeToStageList from './mapStageTypeToStageList';
import mapStageIdsToStages from './mapStageIdsToStages';
import withFeatureCheck from '../../auth/components/withFeatureCheck';

const SearchStageListSelectField = ({
  currentStageId,
  fieldState,
  hasJobSearchStageGates,
  onChange,
  stageType,
  stages,
}) => {
  const disabledFromCandidacyStage = stage => {
    if (stage.get('id') === currentStageId) {
      return false;
    }
    return hasJobSearchStageGates && stageType === STAGE_TYPE_JOB_SEARCH
      ? !stage
          .get('allowed_from_candidacy_stage_ids')
          .toArray()
          .includes(currentStageId)
      : false;
  };
  const searchStageOptions = stages
    ?.sortBy(stage => stage.get('position'))
    // Usually we only want to show stages flagged as "user_assignable", but
    // if the currently selected stage is NOT user_assignable, we still need to show it,
    // otherwise stage will not be selected and it won't be clear what the current
    // value is.
    .filter(
      stage =>
        stage.get('user_assignable') ||
        stage.get('id') === fieldState.getValue() ||
        stage.get('id') === currentStageId,
    )
    .map(stage => ({
      label: stage.get('name'),
      value: stage.get('id'),
      disabled: disabledFromCandidacyStage(stage),
    }))
    .toArray();

  const showDisabledText =
    stages.filter(stage => stage.get('user_assignable')).size >
    searchStageOptions.filter(stage => !stage.disabled).length;

  const disabledText = showDisabledText ? (
    <div className='u-marginBottom-12 u-marginTop-4 u-fontSize-small'>
      Some stages do not meet the progression requirements
    </div>
  ) : null;

  return (
    <>
      <ReactSelectField
        className='SearchStageListSelectField'
        clearable={false}
        fieldState={fieldState}
        label='Stage'
        onChange={onChange}
        options={searchStageOptions}
        simpleValue={true}
      />
      {disabledText}
    </>
  );
};

SearchStageListSelectField.propTypes = {
  /**
   * The ID of the candidacy's currently assigned stage.
   */
  currentStageId: PropTypes.number.isRequired,
  /**
   * The fieldState representing the selected value
   */
  fieldState: fieldStatePropType.isRequired,

  hasJobSearchStageGates: PropTypes.bool,
  /**
   * Called when a stage is selected.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * All of the stages for a tenant type
   */
  stages: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
    }).isRequired,
  ),
  stageType: PropTypes.oneOf(STAGE_TYPES).isRequired,
};

export default compose(
  setDisplayName('SearchStageListSelectField(enhanced)'),
  mapStageTypeToStageList,
  withFeatureCheck('feature.job_search_stage_gates', 'hasJobSearchStageGates'),
  withProps(({ stageList }) => ({
    stageIds: stageList && stageList.get('ids'),
  })),
  mapStageIdsToStages,
  fetchStageListIfNeeded,
  /* eslint-disable no-unused-vars */
  mapProps(({ searchStageActions, stageIds, stageList, ...rest }) => ({
    ...rest,
  })),
  /* eslint-enable no-unused-vars */
)(SearchStageListSelectField);

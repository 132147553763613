import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { omit } from 'lodash';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import { connect } from 'react-redux';
import withDraftStorage from '../../../components/forms/withDraftStorage';
import connectOutreachActions from './connectOutreachActions';
import handleSubmit from './handleSubmit';
import OutreachEmailField from './OutreachEmailField';
import OutreachEmailFormOverlay from './OutreachEmailFormOverlay';
import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';

export const createRootFieldState = ({
  contact,
  draft,
  emailSignature,
  lbiTemplate,
  searchId,
}) => {
  // Prefill the email field with one of the contact's email addresses if
  // available, in the following order of preference: preferred, work, or
  // personal email.
  //
  // This initial value is the object ({ id, email, preferred, label, name })
  // that ContactEmailSelectField expects as an option.
  let emailValue = null;
  const name = contact.get('full_name') || contact.get('name');
  const id = contact.get('id');
  if (contact.get('preferred_email')) {
    emailValue = {
      id: id,
      email: contact.get('preferred_email'),
      preferred: true,
      label: contact.get('work_email_preferred') ? 'Work' : 'Personal',
      name: name,
    };
  } else if (contact.get('email')) {
    emailValue = {
      id: id,
      email: contact.get('email'),
      label: 'Personal',
      name: name,
    };
  } else if (contact.get('work_email')) {
    emailValue = {
      id: id,
      email: contact.get('work_email'),
      label: 'Work',
      name: name,
    };
  }

  const outreach = fromJS({
    to: emailValue,
    contact_id: contact.get('id'),
    searches: searchId ? [searchId] : [],
    notes: draft?.content || emailSignature,
  });

  // An existing outreach can't be changed to an "email", so we always create a new record here.
  return OutreachEmailField.createFieldState('outreach', {
    lbiTemplate: lbiTemplate,
    outreach: outreach,
  });
};

const OutreachEmailForm = ({ contactId, ...props }) => (
  <>
    <OutreachEmailField {...props} />
    <OutreachEmailFormOverlay contactId={contactId} />
  </>
);

OutreachEmailForm.propTypes = {
  contactId: PropTypes.number.isRequired,
};

export default compose(
  setDisplayName('OutreachEmailForm'),
  setPropTypes({
    /**
     * The contact to associate the outreach record with.
     */
    contactId: PropTypes.number.isRequired,

    /**
     * An optional search ID to automatically select in the "Related Searches" field.
     */
    searchId: PropTypes.number,
  }),
  defaultProps({
    lbiNoticeSent: false,
  }),
  connect(state => ({
    emailSignature: state.user.toJS().email_signature?.content,
  })),
  connectOutreachActions,
  connectTransactions,
  mapContactIdToContact,
  withDraftStorage(),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'OutreachEmailFormOverlay OutreachEmailForm',
  }),
  mapProps(props =>
    omit(props, [
      'contact',
      'formState',
      'outreachActions',
      'onSaved',
      'lbiTemplate',
      'lbiNoticeSent',
      'transactionActions',
    ]),
  ),
)(OutreachEmailForm);

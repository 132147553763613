import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withProps } from 'recompose';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import ContactIconLinkList from 'modules/contacts/components/ContactIconLinkList';
import ContactSocialForm from './ContactSocialForm';
import withEditableSection from '../withEditableSection';

function ContactSocial({
  clearTransaction,
  contact,
  contactActions,
  contacts,
  duplicateDetectionId,
  isEditing,
  toggleEditing,
  transactions,
  userType,
}) {
  if (!isEditing) {
    return (
      <div className='contact-details'>
        <div className='row'>
          <div className='col-sm-6'>
            <ContactIconLinkList
              contactId={contact.getIn(['data', 'id'])}
              shouldShowContactSource={true}
            />
          </div>
          {contact.getIn(['data', 'location']) && (
            <div className='col-sm-6'>
              <div className='contact-social-location'>
                {contact.getIn(['data', 'location'])}
                <i className='fa fa-map-marker' />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <ContactSocialForm
      clearTransaction={clearTransaction}
      contact={contact}
      contactActions={contactActions}
      contacts={contacts}
      duplicateDetectionId={duplicateDetectionId}
      onCancel={toggleEditing}
      onSaved={toggleEditing}
      transactions={transactions}
      userType={userType}
    />
  );
}

ContactSocial.propTypes = {
  clearTransaction: PropTypes.func.isRequired,

  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      aboveboard_url: PropTypes.string,
      email: PropTypes.string,
      github_url: PropTypes.string,
      external_id: PropTypes.string,
      linkedin_url: PropTypes.string,
      location: PropTypes.string,
      other_url: PropTypes.string,
      phone: PropTypes.string,
      skype_handle: PropTypes.string,
      twitter_handle: PropTypes.string,
      work_email: PropTypes.string,
      work_phone: PropTypes.string,
    }),
  }).isRequired,

  contactActions: PropTypes.shape({
    updateContact: PropTypes.func.isRequired,
  }).isRequired,

  contacts: ImmutablePropTypes.mapContains({
    duplicatesById: ImmutablePropTypes.map.isRequired,
  }),

  duplicateDetectionId: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,

  /**
   * A callback with no arguments. Switches to edit mode.
   */
  toggleEditing: PropTypes.func.isRequired,

  transactions: transactionsState.isRequired,

  userType: PropTypes.string.isRequired,
};

const SECTION_PRESENCE_FIELDS = [
  'aboveboard_url',
  'address_line_1',
  'address_line_2',
  'city',
  'contact_source',
  'country_code',
  'email',
  'external_id',
  'github_url',
  'linkedin_url',
  'other_url',
  'phone',
  'postal_code',
  'skype_handle',
  'state',
  'twitter_handle',
  'work_email',
  'work_phone',
];

const withSectionNotPresent = withProps(({ contact }) => ({
  sectionNotPresent:
    !contact ||
    SECTION_PRESENCE_FIELDS.every(
      fieldName => !contact.getIn(['data', fieldName]),
    ),
}));

export default withSectionNotPresent(
  withEditableSection(ContactSocial, {
    sectionId: 'contactSocial',
    title: 'Contact Details',
  }),
);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import getSearchProperty from '../selectors/getSearchProperty';

const SearchLocation = ({ location }) => (
  <span className='SearchLocation'>{location}</span>
);

SearchLocation.propTypes = {
  location: PropTypes.string,
};

export default compose(
  setDisplayName('SearchLocation(enhanced)'),
  setPropTypes({
    /**
     * The search ID of the search to show the location for.
     */
    searchId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { searchId }) => ({
      location: getSearchProperty(state, searchId, 'location'),
    }),
    null,
  ),
)(SearchLocation);

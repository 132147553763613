/* eslint-disable no-restricted-syntax, no-unused-vars */
// ^ `for` loops in this file should be replaced by array iterators
export function validUrl(opts = {}) {
  return () => {
    if (!opts.url.match(/^https?:\/\/[^\s$.?#].[^\s]*$/)) {
      return 'Must be a complete URL such as http://www.example.com';
    }
    return null;
  };
}

export function email() {
  return element => {
    const invalidEmails = [];
    const emails = element.val();
    if (emails && emails.length) {
      for (const item of emails) {
        if (!item.match(/.@./)) {
          invalidEmails.push(item);
        }
      }
    }
    if (invalidEmails.length) {
      return `There are invalid emails: ${invalidEmails.join(', ')}`;
    }
    return null;
  };
}

export function presence() {
  return element => {
    if (!element.val()) {
      return "Can't be blank";
    }
    return null;
  };
}

export function rangePresence(rangeElement) {
  return element => {
    const elementValueMissing = element.val() === '';
    const rangeValueMissing = $(rangeElement).val() === '';
    const valuesDifferent = elementValueMissing !== rangeValueMissing;
    if (elementValueMissing && valuesDifferent) {
      return 'Range needed';
    }
    return null;
  };
}

export function range(opts = {}) {
  return () => {
    const min = parseInt($(opts.min).val());
    const max = parseInt($(opts.max).val());

    if (min >= max) {
      return 'Max value must be greater than min value.';
    }

    return null;
  };
}

export function positiveRange(opts = {}) {
  return () => {
    const min = parseInt($(opts.min).val());
    const max = parseInt($(opts.max).val());

    if (min >= max) {
      return 'Max value must be greater than min value.';
    }

    if (Math.sign(min) === -1 || Math.sign(max) === -1) {
      return 'Entered values cannot be negative.';
    }

    return null;
  };
}

export function equityRange(opts = {}) {
  return () => {
    const min = parseInt($(opts.min).val());
    const max = parseInt($(opts.max).val());

    if (min >= max) {
      return 'Max value must be greater than min value.';
    }
    if (min > 100 || max > 100) {
      return 'Value must be less than 100.';
    }

    return null;
  };
}

export function dateRange(opts = {}) {
  return () => {
    const startDate = $(opts.start).val();
    const endDate = $(opts.end).val();
    if (Date.parse(startDate) > Date.parse(endDate)) {
      return 'Start date must be before end date.';
    }
    return null;
  };
}

export function ratingRange(opts = {}) {
  const outOfRange = value => value < 1 || value > 5;

  return () => {
    const min = parseFloat($(opts.min).val());
    const max = parseFloat($(opts.max).val());

    if (outOfRange(min) || outOfRange(max)) {
      return 'Values must be between 1 and 5.';
    }
    if (min > max) {
      return 'Max value must be greater than min value.';
    }

    return null;
  };
}

// Check to see if a filter has a value. A filter value can be one of:
//   * a string (for keyword/text inputs)
//   * an array (for multi-select fields containing a list of ID values)
//   * undefined (if the filter doesn't exist, such as when behind a feature flag)
const hasFieldValue = fieldSelector => {
  const fieldValue = $(fieldSelector).val();
  if (!fieldValue) {
    return false;
  } else if (Array.isArray(fieldValue)) {
    return fieldValue.length > 0;
  } else {
    // In the case of a truthy string value, ensure users can't hack around the filter combination
    // enforcement by entering only empty spaces as the value.
    return fieldValue.trim().length > 0;
  }
};

export function diversityRequirements(opts = {}) {
  return () => {
    const diversityFilterValue = $(opts.diversityField).val();
    const hasDiversityFilter = Boolean(diversityFilterValue?.length);

    if (!hasDiversityFilter) {
      return null;
    }

    // Diversity filters must be combined with at least one other filter (such as keyword, skills, etc.)
    // so that users can't just find a list of all people of a certain race, gender, etc.
    const hasCombinedFilter = opts.positionRelatedField.some(hasFieldValue);

    if (hasCombinedFilter) {
      return null;
    } else {
      const message =
        'This option must be combined with one of the following filters: Searches, Skills, Industries,  ' +
        'Sectors, Position Functions, Search Networks, Keyword,';
      return opts.affiliationsError
        ? `${message} Job Title, or Affiliations.`
        : `${message} or Job Title.`;
    }
  };
}

export function selections() {
  return element => {
    if (!element.find('input:checkbox:checked').length) {
      return "Fields haven't been selected.";
    }
    return null;
  };
}

export default function createValidator(parent) {
  const validations = {};

  const add = (selector, validate) => {
    if (!validations[selector]) {
      validations[selector] = [];
    }
    return validations[selector].push(validate);
  };

  const run = () => {
    const errors = {};
    for (const selector of Object.keys(validations)) {
      const elementValidations = validations[selector];
      const element = parent.find(selector);
      for (const validation of elementValidations) {
        if (validation(element)) {
          const error = validation(element);
          if (!errors[selector]) {
            errors[selector] = [];
          }
          errors[selector].push(error);
        }
      }
    }
    return errors;
  };

  return {
    validations: validations,
    add: add,
    run: run,
  };
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Avatar from '@thrivetrm/ui/components/Avatar';
import Grid from '@thrivetrm/ui/components/Grid';
import EmailLink from '@thrivetrm/ui/components/EmailLink';
import Form from '@thrivetrm/ui/components/Form';
import Card from '@thrivetrm/ui/components/Card';
import Icon from '@thrivetrm/ui/components/Icon';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import getSearchProperty from 'modules/searches/selectors/getSearchProperty';
import getSearchTitle from 'modules/searches/selectors/getSearchTitle';
import DocumentEditor from 'modules/document-editor/DocumentEditor';
import { useGetDocumentTypesQuery } from 'services/apiV1/documents';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import {
  useGetOfferQuery,
  useUpdateOfferMutation,
} from 'services/apiV1/offers';
import CompensationOffer from './CompensationOffer';
import InterimOffer from './InterimOffer';
import './OfferEdit.scss';

const OfferEdit = ({
  companyLogoUrl,
  companyName,
  editOfferId,
  onNavigateBackToOfferList,
  searchId,
}) => {
  const dispatch = useDispatch();
  const [compensationOfferData, setCompensationOfferData] = useState({});
  const [editDocumentsForOfferId, setEditDocumentsForOfferId] = useState(null);
  const [offerSaveError, setOfferSaveError] = useState('');
  const [
    updateOffer,
    { error: hasUpdateOfferError, isLoading: isUpdateOfferLoading },
  ] = useUpdateOfferMutation();

  const {
    data: offer,
    error: offerLoadError,
    isFetching: isLoadingOffer,
    refetch,
  } = useGetOfferQuery({
    offerId: editOfferId,
    searchId: searchId,
  });
  const hasSearchPlacementFeatureEnabled = useFeatureCheck(
    'feature.search_placements',
  );
  const shouldHideOfferComp = useFeatureCheck('feature.hide_offer_comp');
  const companySearchTitle = useSelector(state =>
    getSearchTitle(state, searchId),
  );

  const isInterimPlacementSearch = useSelector(state =>
    getSearchProperty(state, searchId, 'placement_type')?.get('interim'),
  );

  const isConfidential = useSelector(state =>
    getSearchProperty(state, searchId, 'confidential'),
  );
  const defaultEmailMessage = `${offer?.candidacy?.fullName} has been placed for ${companySearchTitle} at ${companyName}`;

  useEffect(() => {
    setCompensationOfferData({
      base: offer?.compensation?.base,
      bonus: offer?.compensation?.bonus,
      percentageBonus: offer?.compensation?.percentageBonus,
      bonusType: offer?.compensation?.bonusType || 'fixed',
    });
  }, [offer]);

  const handleChange = (value, name) => {
    if (name === 'bonusType') {
      const fixedBonus = Number(
        typeof compensationOfferData?.bonus === 'string'
          ? compensationOfferData?.bonus?.replace(/,/g, '')
          : compensationOfferData?.bonus,
      );

      const shouldTrackBonusAsPercentage = value === 'percentage';
      const percentageOfBonus = compensationOfferData?.percentageBonus;

      const isBaseBonusAvailable =
        compensationOfferData?.base && (fixedBonus || percentageOfBonus);
      setCompensationOfferData(
        isBaseBonusAvailable
          ? {
              ...compensationOfferData,
              [name]: value,
              percentageBonus: shouldTrackBonusAsPercentage
                ? Number(
                    ((fixedBonus / compensationOfferData.base) * 100).toFixed(
                      2,
                    ),
                  )
                : null,
              bonus: shouldTrackBonusAsPercentage
                ? Math.round(fixedBonus)
                : Math.round(
                    (compensationOfferData.base * percentageOfBonus) / 100,
                  ),
            }
          : {
              ...compensationOfferData,
              [name]: value,
            },
      );
    } else {
      setCompensationOfferData({
        ...compensationOfferData,
        [name]: value,
      });
    }
  };

  const handleSubmit = formData => {
    const finalPayload = {
      ...formData,
      ...(isInterimPlacementSearch
        ? {
            interim_placement_compensation: {
              currency: formData?.currency,
              day_rate: formData?.day_rate,
            },
          }
        : {
            compensation: {
              currency: formData?.currency,
              base: compensationOfferData?.base,
              percentage_bonus: compensationOfferData?.percentageBonus,
              bonus: compensationOfferData?.bonus,
              bonus_type: compensationOfferData?.bonusType,
              equity: formData?.equity,
              relocation_bonus: formData?.relocation_bonus,
              equity_value: formData?.equity_value,
              signing_bonus: formData?.signing_bonus,
              initial_equity_shares: formData?.initial_equity_shares,
              total_compensation: formData?.total_compensation,
            },
          }),
    };
    updateOffer({
      offerId: editOfferId,
      payload: { offer: finalPayload },
      searchId: searchId,
    })
      .unwrap()
      .then(() => {
        dispatch(toastSuccess('Successfully updated offer.'));
        onNavigateBackToOfferList();
        // Reloading the page when an offer is accepted because, after accepting the offer,
        // the search status should be changed to closed on the details page.
        // The details page is in the SLIM template, so changes are not being reflected there, so it is needed to reload the page.
        if (finalPayload.status === 'accepted') {
          window.location.reload();
        }
      })
      .catch(e => {
        const error = Array.isArray(e?.data?.error)
          ? e?.data?.error.join(', ')
          : '';
        setOfferSaveError(error);
        dispatch(toastError('Could not update offer. '.concat(error)));
      });
  };

  const backButtonText = () => (
    <>
      <Icon
        className='u-margin-8'
        color='gray'
        size='small'
        type='chevronLeft'
      />
      <span>Back to Candidate Offers</span>
    </>
  );

  const { data } = useGetDocumentTypesQuery('offer');
  const documentTypes = data?.length ? data : [];

  const documentTypesForSearch =
    isInterimPlacementSearch && documentTypes?.length
      ? documentTypes
      : documentTypes?.filter(type => type.name !== 'Insurance');

  const getOfferSection = () =>
    isInterimPlacementSearch ? (
      <InterimOffer offer={offer} />
    ) : (
      <CompensationOffer
        compensationOfferData={compensationOfferData}
        offer={offer}
        onChange={handleChange}
      />
    );

  return (
    <LoadingContainer className='u-marginTop-16' isLoading={isLoadingOffer}>
      <ButtonLink
        className='u-marginBottom-8'
        label={backButtonText()}
        onClick={onNavigateBackToOfferList}
      />
      <Card isCentered={false} type='shadow'>
        {offerLoadError && !isLoadingOffer ? (
          <Card className='u-marginVertical-12' isCentered={false} type='error'>
            <p>
              It looks like we had a problem loading that offer. Let’s try to{' '}
              <ButtonLink label='load that again' onClick={refetch} />.
            </p>
            <p>
              If that doesn’t work, please contact{' '}
              <EmailLink emailAddress='support@thrivetrm.com' />.
            </p>
          </Card>
        ) : null}
        {hasUpdateOfferError && !isUpdateOfferLoading && !offerSaveError ? (
          <Card className='u-marginVertical-12' isCentered={false} type='error'>
            <p>
              It looks like we had a problem updating that offer. Let’s try to{' '}
              <ButtonLink label='update that again' onClick={handleSubmit} />.
            </p>
            <p>
              If that doesn’t work, please contact{' '}
              <EmailLink emailAddress='support@thrivetrm.com' />.
            </p>
          </Card>
        ) : null}
        {offerSaveError ? (
          <Card className='u-marginVertical-12' isCentered={false} type='error'>
            <p>Please address the following issues before continuing:</p>
            <p>{offerSaveError}</p>
          </Card>
        ) : null}
        <Form onSubmit={handleSubmit}>
          <div className='u-flex u-marginBottom-16'>
            <div className='u-flexItem-grow u-flex u-flexAlign-c'>
              <Avatar
                className='u-marginRight-16'
                image={offer?.candidacy?.avatarUrl}
                name={offer?.candidacy?.fullName}
                shape='circle'
                size='medium'
              />
              <h2 className='u-margin-n u-textColor-gray60 u-fontWeight-bold'>
                {offer?.candidacy?.fullName}
              </h2>
            </div>
            <div className='u-marginLeft-12'>
              <Card className='u-backgroundColor-gray5 u-padding-12'>
                <Form.RadioButtonGroup
                  initialValue={offer?.status}
                  label='Offer Status'
                  name='status'
                  options={[
                    { label: 'Accepted', value: 'accepted' },
                    { label: 'Declined', value: 'declined' },
                  ]}
                />
              </Card>
            </div>
          </div>

          <Grid>
            <Grid.Column className='u-paddingLeft-n'>
              <div className='u-flexItem-grow u-marginTop-8'>
                <Form.DateInput
                  data-testid='offer-extended-date'
                  initialValue={offer?.extendedDate}
                  inputWidth='full'
                  label='Offer Extended Date'
                  name='extended_date'
                  placeholderText='Offer Extended Date'
                />
              </div>
              <Form.FieldDependentContent
                shouldRender={fields => fields.status === 'accepted'}
              >
                <div className='u-flexItem-grow u-marginTop-8'>
                  <Form.DateInput
                    initialValue={offer?.acceptedDate}
                    inputWidth='full'
                    isPinnedRight={true}
                    label='Acceptance Date'
                    name='accepted_date'
                    placeholderText='Acceptance Date'
                  />
                </div>
              </Form.FieldDependentContent>
            </Grid.Column>
            <Grid.Column className=''>
              <div className='u-marginTop-8'>
                <Form.DateInput
                  data-testid='offer-start-date'
                  initialValue={offer?.startDate}
                  inputWidth='full'
                  label='Candidate Start Date'
                  name='start_date'
                  placeholderText='Start Date'
                />
              </div>
              {isInterimPlacementSearch ? (
                <div className='u-marginTop-8'>
                  <Form.DateInput
                    data-testid='offer-end-date'
                    initialValue={offer?.endDate}
                    inputWidth='full'
                    isPinnedRight={true}
                    label='Candidate End Date'
                    name='end_date'
                    placeholderText='Candidate End Date'
                  />
                </div>
              ) : null}
              <Form.FieldDependentContent
                shouldRender={fields => {
                  return (
                    fields.status === 'accepted' &&
                    hasSearchPlacementFeatureEnabled &&
                    !isConfidential
                  );
                }}
              >
                <p className='u-marginBottom-8 u-marginTop-16'>
                  Would you like to notify the firm by email that this placement
                  has been made?
                </p>
                <Form.RadioButtonGroup
                  className='u-marginRight-8'
                  initialValue={false}
                  name='send_email'
                  options={[
                    { label: 'Send Email', value: true },
                    { label: `Don't Send Email`, value: false },
                  ]}
                />
                <Form.FieldDependentContent
                  shouldRender={fields => fields.send_email === true}
                >
                  <Form.TextArea
                    initialValue={defaultEmailMessage}
                    label='Email Message'
                    name='email_message'
                    width='full'
                  />
                </Form.FieldDependentContent>
              </Form.FieldDependentContent>
            </Grid.Column>
          </Grid>

          {offer?.status === 'accepted' ? (
            <>
              <h3>Candidate Success</h3>
              <div className='u-marginVertical-8'>
                <Form.Checkbox
                  initialValue={offer?.lastedOneYear}
                  label='Lasted 12 Months?'
                  name='lasted_one_year'
                />
              </div>
              <div className='u-marginVertical-8'>
                <Form.Checkbox
                  initialValue={offer?.terminated}
                  label='Terminated?'
                  name='terminated'
                />
              </div>
            </>
          ) : null}
          <Form.FieldDependentContent
            shouldRender={fields => fields.terminated === true}
          >
            <Card className='u-backgroundColor-gray5' isCentered={false}>
              <Grid className='u-marginBottom-16' hasGutters={false}>
                <Grid.Column size={4}>
                  <Form.DateInput
                    className='u-marginRight-8'
                    data-testid='offer-termination-date'
                    initialValue={offer?.terminatedDate}
                    inputWidth='full'
                    label='Termination Date'
                    name='terminated_date'
                    placeholderText='Termination Date'
                  />
                </Grid.Column>
                <Grid.Column size={8}>
                  <Form.TextArea
                    initialValue={offer?.terminatedNotes}
                    label='Termination Notes'
                    name='terminated_notes'
                    width='full'
                  />
                </Grid.Column>
              </Grid>
            </Card>
          </Form.FieldDependentContent>
          {!shouldHideOfferComp ? getOfferSection() : null}
          <Form.TextArea
            className='u-marginTop-16'
            initialValue={offer?.notes}
            inputClassName='OfferEdit__notes'
            label='Offer Notes'
            name='notes'
            width='full'
          />

          <div className='u-flex u-flexAlign-c u-flexJustify-r u-marginTop-16'>
            <ButtonPrimary
              isOutline={true}
              label='Cancel'
              onClick={onNavigateBackToOfferList}
            />
            <ButtonPrimary
              isOutline={true}
              label='Offer Documents'
              onClick={() => setEditDocumentsForOfferId(editOfferId)}
            />
            <Form.SubmitButton isLoading={isUpdateOfferLoading} label='Save' />
          </div>
        </Form>
      </Card>
      {editDocumentsForOfferId === editOfferId ? (
        <DocumentEditor
          company={{
            avatarUrl: companyLogoUrl,
            primaryCompanyName: companyName,
            searchTitle: companySearchTitle,
          }}
          contact={{
            avatarUrl: offer.candidacy.avatarUrl,
            fullName: offer.candidacy.fullName,
            primaryPositionName: offer.candidacy.positionName,
          }}
          documentTypes={documentTypesForSearch}
          isOpen={true}
          location={offer.location}
          onClose={() => setEditDocumentsForOfferId(null)}
          ownerId={editDocumentsForOfferId}
          ownerType='offer'
        />
      ) : null}
    </LoadingContainer>
  );
};

OfferEdit.propTypes = {
  companyLogoUrl: PropTypes.string,
  companyName: PropTypes.string,
  editOfferId: PropTypes.number,
  onNavigateBackToOfferList: PropTypes.func,
  searchId: PropTypes.number,
};

export default OfferEdit;

import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import { compose, setDisplayName, setStatic } from 'recompose';
import CurrencyInputField from 'modules/currency/components/CurrencyInputField';
import CurrencyTypeSelectField from 'modules/currency/components/CurrencyTypeSelectField';
import FieldState from 'modules/forms/FieldState';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import { DEFAULT_CURRENCY } from 'modules/user/constants';

/**
 * A field for editing the market cap secion of a company
 */
const CompanyMarketCapField = ({
  fieldState,
  handleNestedFieldChange,

  // prevent onChange from being passed through to the input.
  onChange: _onChange,

  ...otherProps
}) => (
  <div className='CompanyMarketCapField'>
    <div className='row'>
      <div className='col-sm-6'>
        <div className='row'>
          <CurrencyInputField
            className='CompanyMarketCapField__market_cap col-md-8 col-sm-12'
            {...otherProps}
            currency={fieldState
              .getNestedField('market_cap_currency')
              .getValue()}
            fieldState={fieldState.getNestedField('market_cap_amount')}
            label='Market Cap'
            onChange={handleNestedFieldChange}
          />
          <CurrencyTypeSelectField
            {...otherProps}
            className='CompanyMarketCapField__market_cap_currency col-md-4 col-sm-12'
            clearable={true}
            fieldState={fieldState.getNestedField('market_cap_currency')}
            label='Currency'
            name='market_cap_currency'
            onChange={handleNestedFieldChange}
          />
        </div>
      </div>
    </div>
  </div>
);

CompanyMarketCapField.createFieldState = (
  name = 'market_cap_amount',
  company,
  ...rest
) => {
  const values = fromJS({
    market_cap_currency: '',
    market_cap_amount: '',
  }).merge(company);

  return FieldState.createNested(
    name,
    [
      CurrencyInputField.createFieldState(
        'market_cap_amount',
        values.get('market_cap_amount'),
        { minValue: 0, float: true },
      ),
      CurrencyTypeSelectField.createFieldState(
        'market_cap_currency',
        values.get('market_cap_currency') || DEFAULT_CURRENCY,
      ),
    ],
    ...rest,
  );
};

CompanyMarketCapField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,
};

export default compose(
  setDisplayName('CompanyFinancialsField(enhanced)'),
  setStatic('createFieldState', CompanyMarketCapField.createFieldState),
  withNestedFieldChangeHandler,
)(CompanyMarketCapField);

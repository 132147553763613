import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { fromJS } from 'immutable';
import FieldState from 'modules/forms/FieldState';
import * as validators from 'modules/forms/validators';
import DateRangeField from 'modules/datetime/components/DateRangeField';
import UserMultiSelect from 'modules/users/components/UserMultiSelect';
import { FILTER_EMPLOYEES } from 'modules/users/constants';
import EmailField from '../../../../components/forms/EmailField';
/**
 * A field for entering preferences for generating a commissions report.
 */
class CommissionsReportField extends Component {
  static createFieldState(name = 'commissionsReport', currentUserEmail) {
    const values = fromJS({});

    return FieldState.createNested(name, [
      EmailField.createFieldState('emails', currentUserEmail),
      DateRangeField.createFieldState(
        'dateRange',
        {
          startDate: values.get('startDate'),
          endDate: values.get('endDate'),
        },
        validators.dateRange,
      ),
      UserMultiSelect.createFieldState('userIds', values.get('userIds')),
    ]);
  }

  handleFieldChange = nestedFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(nestedFieldState));
  };

  render() {
    const {
      dateRangeInnerClassName,
      dateRangeWrapperClassName,
      emailWrapperClassName,
      fieldState,
    } = this.props;

    return (
      <div className='CommissionReport'>
        <h2>Date Range</h2>
        <p>
          Select the date range you would like to review. This range will be
          used to filter all available commission data against the Invoice Sent
          date.
        </p>
        <div className='row'>
          <div className='col-sm-12'>
            <DateRangeField
              fieldState={fieldState.getNestedField('dateRange')}
              innerClassName={dateRangeInnerClassName}
              onChange={this.handleFieldChange}
              wrapperClassName={dateRangeWrapperClassName}
            />
          </div>
        </div>
        <h2>Filter Options</h2>
        <p>
          Select the users that you would like to include in your Commissions
          Report
        </p>
        <UserMultiSelect
          fieldState={fieldState.getNestedField('userIds')}
          filter={FILTER_EMPLOYEES}
          key='users'
          label=''
          onChange={this.handleFieldChange}
          summaryLabel='{0} users selected'
        />
        <h2>Recipients</h2>
        <EmailField
          clearable={false}
          fieldState={fieldState.getNestedField('emails')}
          label='Emails*'
          onChange={this.handleFieldChange}
          wrapperClassName={emailWrapperClassName}
        />
      </div>
    );
  }
}

CommissionsReportField.propTypes = {
  dateRangeInnerClassName: PropTypes.string,
  dateRangeWrapperClassName: PropTypes.string,
  emailWrapperClassName: PropTypes.string,
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CommissionsReportField;

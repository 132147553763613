import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { pure } from 'recompose';
import ContactExternalIdLink from 'modules/contacts/components/ContactExternalIdLink';
import ContactIconLink from './ContactIconLink';
import ContactPopoverIconLink from './ContactPopoverIconLink';

/** Deprecated in favor of the connected version in module/contacts.
 * Renders the collection of contact link icons for a contact.
 * Will only render those links that have values provided.
 */
const ContactIconLinkList = ({ contact, onlyShowOnCard }) => {
  const preferredEmail = contact.getIn(['data', 'preferred_email']);
  const email = contact.getIn(['data', 'email']);
  const workEmail = contact.getIn(['data', 'work_email']);
  const phone = contact.getIn(['data', 'phone']);
  const mobilePhone = contact.getIn(['data', 'mobile_phone']);
  const workPhone = contact.getIn(['data', 'work_phone']);
  const linkedInUrl = contact.getIn(['data', 'linkedin_url']);
  const twitterHandle = contact.getIn(['data', 'twitter_handle']);
  const skypeUsername = contact.getIn(['data', 'skype_handle']);
  const githubUrl = contact.getIn(['data', 'github_url']);
  const otherUrl = contact.getIn(['data', 'other_url']);
  const externalId = contact.getIn(['data', 'external_id']);
  const aboveBoardUrl = contact.getIn(['data', 'aboveboard_url']);

  return (
    <ul className='contact-icon-link-list'>
      {((preferredEmail && onlyShowOnCard) ||
        ((email || workEmail) && !onlyShowOnCard)) && (
        <li key='email'>
          <ContactPopoverIconLink
            href={`mailto:${email || workEmail || preferredEmail}`}
            linkTitle={email}
            linkType='email'
            popoverTitle='Email'
          >
            <div className='list-unstyled'>
              {preferredEmail && (
                <div>
                  <strong>Preferred Email:</strong>{' '}
                  <div>
                    <a
                      href={`mailto:${preferredEmail}`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      {preferredEmail}
                    </a>
                  </div>
                </div>
              )}
              {workEmail && workEmail !== preferredEmail && !onlyShowOnCard && (
                <div>
                  <strong>Work Email:</strong>{' '}
                  <div>
                    <a
                      href={`mailto:${workEmail}`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      {workEmail}
                    </a>
                  </div>
                </div>
              )}
              {email && email !== preferredEmail && !onlyShowOnCard && (
                <div>
                  <strong>Personal Email:</strong>{' '}
                  <div>
                    <a
                      href={`mailto:${email}`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      {email}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </ContactPopoverIconLink>
        </li>
      )}
      {(((mobilePhone || phone) && onlyShowOnCard) ||
        ((phone || mobilePhone || workPhone) && !onlyShowOnCard)) && (
        <li key='phone'>
          <ContactPopoverIconLink
            href={`tel:${phone || mobilePhone || workPhone}`}
            linkType='phone'
            popoverTitle='Phone Numbers'
          >
            <div className='list-unstyled'>
              {phone && (
                <div>
                  <strong>Phone:</strong>{' '}
                  <div>
                    <a href={`tel:${phone}`}>{phone}</a>
                  </div>
                </div>
              )}
              {workPhone && !onlyShowOnCard && (
                <div>
                  <strong>Work:</strong>{' '}
                  <div>
                    <a href={`tel:${workPhone}`}>{workPhone}</a>
                  </div>
                </div>
              )}
              {mobilePhone && (
                <div>
                  <strong>Mobile:</strong>{' '}
                  <div>
                    <a href={`tel:${mobilePhone}`}>{mobilePhone}</a>
                  </div>
                </div>
              )}
            </div>
          </ContactPopoverIconLink>
        </li>
      )}
      {linkedInUrl && (
        <li key='linkedin'>
          <ContactIconLink
            href={linkedInUrl}
            linkType='linkedin'
            rel='noopener noreferrer'
            target='_blank'
          />
        </li>
      )}
      {twitterHandle && (
        <li key='twitter'>
          <ContactIconLink
            href={`https://www.twitter.com/${twitterHandle}`}
            linkType='twitter'
            rel='noopener noreferrer'
            target='_blank'
          />
        </li>
      )}
      {skypeUsername && (
        <li key='skype'>
          <ContactIconLink
            href={`skype:${skypeUsername}?call`}
            linkType='skype'
          />
        </li>
      )}
      {githubUrl && (
        <li key='github'>
          <ContactIconLink
            href={githubUrl}
            linkType='github'
            rel='noopener noreferrer'
            target='_blank'
          />
        </li>
      )}
      {aboveBoardUrl && (
        <li key='aboveboard'>
          <ContactIconLink
            href={aboveBoardUrl}
            linkType='aboveboard'
            rel='noopener noreferrer'
            target='_blank'
          />
        </li>
      )}
      {otherUrl && (
        <li key='other'>
          <ContactIconLink
            href={otherUrl}
            linkType='other'
            rel='noopener noreferrer'
            target='_blank'
          />
        </li>
      )}

      {externalId && (
        <li key='external_id'>
          <ContactExternalIdLink externalId={externalId} icon='fa-google' />
        </li>
      )}
    </ul>
  );
};

ContactIconLinkList.propTypes = {
  /**
   * The candidacy record to render the links for.
   */
  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      email: PropTypes.string,
      external_id: PropTypes.string,
      github_url: PropTypes.string,
      linkedin_url: PropTypes.string,
      mobile_phone: PropTypes.string,
      phone: PropTypes.string,
      skype_handle: PropTypes.string,
      twitter_handle: PropTypes.string,
    }),
  }),

  onlyShowOnCard: PropTypes.bool,
};

export default pure(ContactIconLinkList);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';

/**
 * The field for editing the gdpr status for a contact.
 * This just wraps a ReactSelectField instance. The createFieldState instance uses a custom
 * converter to return the value as `{ consent: {type: 'GDPR', status: [content]} }` instead
 * of returning the content directly, so that this can be used with `withContactForm`.
 */
export default compose(
  setDisplayName('ContactGdprStatusField'),
  setStatic(
    'createFieldState',
    (name = 'gdpr_status', contact, validator, _, ...rest) =>
      ReactSelectField.createFieldState(
        name,
        (contact && contact.getIn(['gdpr_status', 'label'])) || '',
        validator,
        value => ({
          consent: {
            status: value,
            type: 'GDPR',
          },
        }),
        ...rest,
      ),
  ),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),

  // Note that we're creating a new function component here so that the `setStatic` call above
  // doesn't overwrite the RichTextField's createFieldState function!
)(props => <ReactSelectField {...props} />);

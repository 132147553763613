import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import ButtonDestroy from '@thrivetrm/ui/components/ButtonDestroy';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import routes from 'modules/routing/routes';
import { useApiDelete } from '@thrivetrm/ui/hooks/useApi';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import classnames from 'classnames';

const EmailIntegrationWithAccount = ({
  buttonName,
  description,
  googleDataPolicy,
  iconClassName,
  integrationInfo,
  title,
}) => {
  const dispatch = useDispatch();
  const [isDialogOpen, openDialog, closeDialog] = useToggle(false);
  const [tokenInfo, setTokenInfo] = useState(integrationInfo);
  const [deleteToken, isDeletingToken] = useApiDelete(
    routes.auth_token({ id: tokenInfo?.token?.id }),
  );

  const handleConfirm = () => {
    deleteToken(
      {},
      {
        onSuccess: response => {
          dispatch(toastSuccess(response.notice));
          setTokenInfo({ ...tokenInfo, token: null });
        },
      },
    );
  };
  return (
    <div className='u-marginTop-4'>
      <h2>{title}</h2>
      <div className='u-flex u-flexAlign-t u-flexJustify-spaceBetween'>
        <div className='AccountDesc'>
          <p>{description}</p>
          {googleDataPolicy}
        </div>
        <div className='AccountToken_box'>
          <ul className='list-group'>
            {tokenInfo?.token ? (
              <li className='list-group-item'>
                <div className='u-flex u-flexAlign-c u-flexJustify-spaceBetween '>
                  <div className='TokenDesc'>
                    {tokenInfo?.token?.description}
                  </div>
                  <ButtonDestroy
                    isLoading={isDeletingToken}
                    label='Remove'
                    onClick={openDialog}
                    size='small'
                  />
                </div>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      <a
        className={classnames(
          'btn',
          'btn-social',
          { 'Button--isDisabled': Boolean(tokenInfo?.token) },
          iconClassName,
        )}
        href={tokenInfo?.oauthPath}
      >
        <i />
        {buttonName}
      </a>
      <ConfirmationDialog
        cancelLabel='No, Keep the token'
        confirmLabel='Yes, Delete Token'
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onConfirm={handleConfirm}
        title='Revoke Token?'
      >
        Are you sure you want to revoke {tokenInfo?.token?.description}?
      </ConfirmationDialog>
    </div>
  );
};

EmailIntegrationWithAccount.propTypes = {
  buttonName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  googleDataPolicy: PropTypes.node,
  iconClassName: PropTypes.string,
  integrationInfo: PropTypes.shape({
    oauthPath: PropTypes.string,
    token: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      provider: PropTypes.string,
      uid: PropTypes.string,
      user_id: PropTypes.number,
    }),
  }),
  title: PropTypes.string.isRequired,
};

export default EmailIntegrationWithAccount;

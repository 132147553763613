import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import {
  SEARCH_TYPES,
  SEARCH_PAGE_BASE_PATHS,
  TYPE_PIPELINE,
} from 'modules/searches/constants';

const ConnectionReferenceView = ({
  contactFirstName,
  contactId,
  hasInverseReference,
  refereeFirstName,
  refereeId,
  reference,
}) => {
  const hasReferencesConnectionV2 = useFeatureCheck(
    'development.references_connection_v2',
  );
  const searchPageBase = SEARCH_PAGE_BASE_PATHS[reference.searchType];
  const candidatePageBase =
    reference.searchType === TYPE_PIPELINE ? 'people' : 'candidates';

  const referenceURL = hasReferencesConnectionV2
    ? `${searchPageBase}/${reference.searchId}/${candidatePageBase}/${reference.candidacyId}/referencesV2`
    : `${searchPageBase}/${reference.searchId}/${candidatePageBase}/${reference.candidacyId}/references`;

  const referenceSearch = reference.searchId ? (
    <>
      for the{' '}
      <a
        href={
          reference.candidacyId
            ? referenceURL
            : `${searchPageBase}/${reference.searchId}/${candidatePageBase}`
        }
      >
        {reference.searchName}
        {reference.clientCompanyName
          ? ` at ${reference.clientCompanyName}`
          : null}
      </a>{' '}
      search{' '}
    </>
  ) : null;

  const refereeLink = <a href={`/contacts/${refereeId}`}>{refereeFirstName}</a>;
  const contactLink = <a href={`/contacts/${contactId}`}>{contactFirstName}</a>;
  return (
    <h6 className='u-marginVertical-2 u-fontWeight-normal'>
      {hasInverseReference ? (
        <>
          {contactLink} gave a reference to {refereeLink}{' '}
        </>
      ) : (
        <>
          {contactLink} received a reference from {refereeLink}{' '}
        </>
      )}
      {referenceSearch} on {moment(reference.createdAt).format('MMMM YYYY')}
    </h6>
  );
};

ConnectionReferenceView.propTypes = {
  contactFirstName: PropTypes.string.isRequired,
  contactId: PropTypes.number.isRequired,
  hasInverseReference: PropTypes.bool,
  refereeFirstName: PropTypes.string.isRequired,
  refereeId: PropTypes.number.isRequired,
  reference: PropTypes.shape({
    candidacyId: PropTypes.number,
    clientCompanyName: PropTypes.string,
    contactId: PropTypes.number,
    contactName: PropTypes.string,
    createdAt: PropTypes.string,
    searchId: PropTypes.number,
    searchName: PropTypes.string,
    searchType: PropTypes.oneOf(SEARCH_TYPES).isRequired,
  }).isRequired,
};

export default ConnectionReferenceView;

import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import withTransactionTrackedForm from '../../forms/withTransactionTrackedForm';
import ResumeField from './ResumeField';

const createRootFieldState = () => ResumeField.createFieldState('file', null);

const handleSubmit = (
  formState,
  { contactId, contactReviewActions, parser },
) => {
  const transactionId = uniqueId();

  contactReviewActions.createContactReview({
    parser: parser,
    file: formState.getFieldValue(),
    transactionId: transactionId,
    contactId: contactId,
  });

  return formState.startSubmit(transactionId);
};

export default withTransactionTrackedForm(
  createRootFieldState,
  handleSubmit,
  {},
)(ResumeField);

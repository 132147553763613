import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import RichTextField from '../../../../components/forms/richtext/RichTextField';

/**
 * The field for editing the description for a company.
 * This just wraps a RichTextField instance. The createFieldState instance uses a custom
 * converter to return the value as `{ description: '[content]' }` instead of returning the
 * content directly, so that this can be used with `withCompanyUpdateForm`.
 */
export default compose(
  setDisplayName('CompanyDescriptionField'),
  setStatic(
    'createFieldState',
    (name = 'description', company, validator, _, ...rest) =>
      RichTextField.createFieldState(
        name,
        (company && company.get('description')) || '',
        validator,
        value => ({
          description: value,
        }),
        ...rest,
      ),
  ),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    onChange: PropTypes.func.isRequired,
  }),

  // Note that we're creating a new function component here so that the `setStatic` call above
  // doesn't overwrite the RichTextField's createFieldState function!
)(props => <RichTextField {...props} />);

import { connect } from 'react-redux';
import getCandidacyList from 'modules/candidacies/selectors/getCandidacyList';
import getCandidacyProperty from 'modules/candidacies/selectors/getCandidacyProperty';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_PIPELINE } from 'modules/searches/constants';
/**
 * A Higher order component that filters a list of tagIds based on their presence on the
 * candidacies of the search.
 * @param {*} state
 * @param {!number} searchId the id of the search by which we want to scope tags
 * @return {List} tag ids filtered by their presence on the candidacies of the search
 */

export default connect((state, { searchId }) => {
  const searchType = getSearchType(state, searchId);
  const candidacyList = getCandidacyList(
    state,
    searchType === TYPE_PIPELINE ? 'pipeline' : 'search',
    searchId,
  );
  const candidacyIds = candidacyList && candidacyList.get('ids');
  const candidaciesTagIds =
    candidacyIds &&
    candidacyIds
      .flatMap(candidacyId =>
        getCandidacyProperty(
          state,
          candidacyId,
          searchType === TYPE_PIPELINE ? 'smart_tag_ids' : 'tag_ids',
        ),
      )
      .toOrderedSet()
      .toList();

  return {
    tagIds: candidaciesTagIds,
  };
}, {});

import getAllTalentPools from './getAllTalentPools';

export default state => {
  const allTalentPools = getAllTalentPools(state);

  if (!allTalentPools) {
    return true;
  }

  if (allTalentPools.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (allTalentPools.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !allTalentPools.has('ids') ||
    Boolean(allTalentPools.getIn(['_meta', 'isInvalidated']))
  );
};

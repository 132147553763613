import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import EntityTagSelect from './EntityTagSelect';

export default compose(
  setDisplayName('EntityTagSelectField'),
  setPropTypes({
    /**
     * An array of IDs of all entities to allow/display in the list
     */
    entityIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,

    /**
     * The name of the property on the entity that is used as the tag "label"
     */
    entityNameProperty: PropTypes.string.isRequired,

    /**
     * The entity type (schema key) we are showing here.
     */
    entityType: PropTypes.string.isRequired,

    /**
     * A FieldState with an underlying value that is an array of target market IDs.
     */
    fieldState: fieldStatePropType.isRequired,

    /**
     * Called when the fieldState is changed by the user adding/removing target markets.
     */
    onChange: PropTypes.func.isRequired,
  }),

  setStatic('createFieldState', (name, value = [], ...rest) =>
    FieldState.create(name, value.toArray ? value.toArray() : value, ...rest),
  ),

  defaultProps({
    entityIds: [],
    entityNameProperty: 'name',
  }),

  // Wrap in a formgroup and provide conversion between our fieldState and it's underlying value.
  withFormGroup,
  asField(),
)(EntityTagSelect);

import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {
  compose,
  defaultProps,
  lifecycle,
  setDisplayName,
  setStatic,
  withStateHandlers,
  setPropTypes,
} from 'recompose';
import asField from './asField';
import withFormGroup from './withFormGroup';
import FieldState from '../FieldState';

export const DefaultImageComponent = props => (
  <img
    alt='Logo'
    className='ImageUploadField__DefaultImageComponent'
    {...props}
  />
);

export const DefaultEmptyComponent = props => (
  <i
    className='ImageUploadField__DefaultEmptyComponent fa fa-file-image-o'
    {...props}
  />
);

export const ImageUploadField = ({
  disabled,
  emptyComponent: EmptyComponent,
  handleLogoChange,
  handleLogoRemove,
  height,
  imageComponent: ImageComponent,
  preview,
  value,
}) => (
  <div className='ImageUploadField'>
    <div className='ImageUploadField__placeholder'>
      <Dropzone
        activeClassName='ImageUploadField__uploader-dropzone-active'
        className='ImageUploadField__uploader-dropzone'
        multiple={false}
        name='image'
        onDrop={handleLogoChange}
      >
        <div className='ImageUploadField__text'>
          {!disabled ? (
            <span>
              {!value && 'Choose a Logo'}
              {typeof value === 'string' && 'Current Logo'}
              {value && typeof value === 'object' && 'Preview Logo'}
            </span>
          ) : (
            <span>Loading</span>
          )}
        </div>
        <div
          className='ImageUploadField__logo'
          style={height ? { height: height } : {}}
        >
          {value ? (
            <ImageComponent src={typeof value === 'string' ? value : preview} />
          ) : (
            <EmptyComponent />
          )}
        </div>
        <div className='ImageUploadField__text'>
          {!disabled && value && (
            <button
              className='ImageUploadField__remove btn btn-link'
              onClick={handleLogoRemove}
              type='button'
            >
              Remove
            </button>
          )}
        </div>
        <div className='ImageUploadField__text'>
          <span className='btn btn-link'>Choose a file</span>
          <span> or drag / drop a logo here.</span>
        </div>
      </Dropzone>
    </div>
  </div>
);

ImageUploadField.propTypes = {
  /**
   * Determines if the form is submitting or not
   */
  disabled: PropTypes.bool,

  /**
   * A component that renders font awesome empty image icon
   */
  emptyComponent: PropTypes.func.isRequired,

  /**
   * Handles when a new logo is uploaded
   */
  handleLogoChange: PropTypes.func,

  /**
   * Handles when a logo is removed
   */
  handleLogoRemove: PropTypes.func,

  /**
   * A component that renders the image
   */
  imageComponent: PropTypes.func.isRequired,

  /**
   * Either the image object or the image url path
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default compose(
  setDisplayName('ImageUploadField'),
  setPropTypes({
    emptyComponent: ImageUploadField.propTypes.emptyComponent,
    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    imageComponent: ImageUploadField.propTypes.imageComponent,
    onChange: PropTypes.func,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  setStatic('createFieldState', FieldState.create),
  defaultProps({
    imageComponent: DefaultImageComponent,
    emptyComponent: DefaultEmptyComponent,
  }),
  withFormGroup,
  asField(),
  withStateHandlers(
    { preview: null },
    {
      handleLogoChange: ({ preview }, { onChange }) => acceptedFiles => {
        onChange(acceptedFiles[0]);

        if (preview) {
          URL.revokeObjectURL(preview);
        }

        return { preview: URL.createObjectURL(acceptedFiles[0]) };
      },
      handleLogoRemove: ({ preview }, { onChange }) => e => {
        e.stopPropagation();
        if (preview) {
          URL.revokeObjectURL(preview);
        }

        onChange(null);

        return { preview: null };
      },
    },
  ),
  lifecycle({
    componentWillUnmount: function () {
      if (this.props.preview) {
        URL.revokeObjectURL(this.props.preview);
      }
    },
  }),
)(ImageUploadField);

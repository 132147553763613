import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

const handleSubmit = (formState, props) => {
  const transactionId = uniqueId();
  const {
    task,
    taskActions: { createTask, updateTask },
  } = props;
  const taskId = task && task.get('id');

  const action = taskId ? updateTask : createTask;

  const { ...values } = formState.getFieldValue();

  action({
    task: {
      ...values,
      id: taskId,
    },
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export default handleSubmit;

import React from 'react';
import PropTypes from 'prop-types';
import ConditionalTooltipTrigger from 'modules/core/componentsLegacy/ConditionalTooltipTrigger';

const SelfReportedIndicator = ({ tooltip }) => (
  <ConditionalTooltipTrigger nested={true} placement='top' tooltip={tooltip}>
    <i className='fa fa-check-circle-o u-marginRight-4' />
  </ConditionalTooltipTrigger>
);

SelfReportedIndicator.propTypes = {
  tooltip: PropTypes.string.isRequired,
};

export default SelfReportedIndicator;

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import RadioButtonGroup from 'modules/core/componentsLegacy/RadioButtonGroup';

import { STAGE_FILTER_ACTIVE, STAGE_FILTER_NONE } from '../constants';

/**
 * A button group for toggling being "Active" and "All" stages.
 */
const StagesFilterToggleButton = ({
  activeLabel,
  allLabel,
  onChange,
  value,
  ...rest
}) => (
  <RadioButtonGroup
    {...rest}
    onChange={onChange}
    options={{
      [STAGE_FILTER_ACTIVE]: activeLabel,
      [STAGE_FILTER_NONE]: allLabel,
    }}
    value={value}
  />
);

StagesFilterToggleButton.propTypes = {
  /**
   * The label to render for the "Active" button.
   */
  activeLabel: PropTypes.string,

  /**
   * The label to render for the "All" button.
   */
  allLabel: PropTypes.string,

  /**
   * Called when the value should be changed. Called with the SyntheticEvent
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The value to be selected.
   */
  value: PropTypes.oneOf([STAGE_FILTER_ACTIVE, STAGE_FILTER_NONE]),
};

StagesFilterToggleButton.defaultProps = {
  activeLabel: 'Active',
  allLabel: 'All',
};

export default compose(
  setDisplayName('StagesFilterToggleButton(enhanced)'),
  setPropTypes({
    /**
     * The enhanced version takes an `onChange` that is called only with a true or false value --
     * where true indicates "active" should be selected, false indicates "all" should be selected.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * True if "active" should be selected; false if "all" should be selected.
     */
    value: PropTypes.bool.isRequired,
  }),
  withProps(({ value }) => ({
    value: value ? STAGE_FILTER_ACTIVE : STAGE_FILTER_NONE,
  })),
  withHandlers({
    // Override onChange so it gets called with the a true or falue value instead of the event.
    onChange: ({ onChange }) => e =>
      onChange(e.currentTarget.value === STAGE_FILTER_ACTIVE),
  }),
)(StagesFilterToggleButton);

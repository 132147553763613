import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import getJobApplicationsBySearchId from '../selectors/getJobApplicationsBySearchId';

export default compose(
  setDisplayName('mapSearchIdToJobApplicationList'),
  connect(
    (state, { searchId }) => ({
      jobApplicationList: getJobApplicationsBySearchId(state, searchId),
    }),
    {},
  ),
);

import { connect } from 'react-redux';
import getCompanyProperty from '../selectors/getCompanyProperty';

/**
 * A higher order component which maps a `companyId` prop value to one of it's data properties.
 * @param {String|String[]} propertyName The property name or keypath to the property
 *   on the company entity.
 * @param {String} propName The name of the prop to supply to the component. By default
 *   the property name is used.
 */
export default (propertyName, propName) =>
  connect(
    (state, { companyId }) => ({
      [propName || propertyName]: getCompanyProperty(
        state,
        companyId,
        propertyName,
      ),
    }),
    {},
  );

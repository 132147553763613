import mapEntityIdsToEntities from 'modules/entities/components/mapEntityIdsToEntities';
import noteSchema from '../schema';

/**
 * A higher order component which maps a list of `noteIds` to
 * their note records.
 */
export default mapEntityIdsToEntities(noteSchema.key, {
  idsProp: 'noteIds',
  entitiesProp: 'notes',
});

import getEntity from 'modules/entities/selectors/getEntity';
import employeeCountSchema from '../schema';

/**
 * Gets an employeeCount entity from it's ID
 * @param {*} state
 * @param {Number} employeeCountId The ID of the employeeCount entity.
 * @return {Map} The employeeCount entity record, if in state.
 */
export default (state, employeeCountId) =>
  getEntity(state, employeeCountSchema.key, employeeCountId);

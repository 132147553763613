import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import ColorInput from './ColorInput';

/**
 * A ColorInput wrapped in a Field component.
 */
export default compose(
  setDisplayName('ColorField'),
  setPropTypes({
    /**
     * The underlying fieldState that supplies the field's value, validations, etc.
     */
    fieldState: PropTypes.instanceOf(FieldState).isRequired,

    /**
     * Called when the fieldState is changed.
     */
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', FieldState.create),
  asField({
    handleValueChange: color => color.hex,
  }),
)(ColorInput);

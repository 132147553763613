import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import { connect } from 'react-redux';
import FieldState from 'modules/forms/FieldState';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import getAddressOneLiner from '../selectors/getAddressOneLiner';
/**
 * Displays a field for editing a resource's primary address.
 */
class PrimaryAddressSelectField extends PureComponent {
  static createFieldState(name = 'social', address) {
    const values = new Map({
      primary_address: '',
    }).merge(address);

    return FieldState.createNested(name, [
      ReactSelectField.createFieldState(
        'primary_address_id',
        values.get('primary_address'),
      ),
    ]);
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      fieldState,
      // Ignored fields to exclude from ...otherProps
      // eslint-disable-next-line no-unused-vars
      onChange,
      options,
      ...otherProps
    } = this.props;
    return (
      <div className='PrimaryAddressSelectField'>
        <ReactSelectField
          {...otherProps}
          fieldState={fieldState.getNestedField('primary_address_id')}
          key='primary_address_id'
          label='Primary Address'
          name='primary_address_id'
          onChange={this.handleFieldChange}
          options={options}
          placeholder='Select Address...'
          simpleValue={true}
        />
      </div>
    );
  }
}

PrimaryAddressSelectField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  /**
   * the options list to supply to the ReactSelectField
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

export default compose(
  setDisplayName('PrimaryAddressSelectField(enhanced)'),
  setPropTypes({
    addressIds: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  connect(
    (state, { addressIds }) => ({
      options:
        addressIds &&
        addressIds
          .map(addressId => ({
            value: addressId,
            label: getAddressOneLiner(state, addressId),
          }))
          .toArray(),
    }),
    {},
  ),
)(PrimaryAddressSelectField);

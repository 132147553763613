import ReactDOM from 'react-dom';
import { createElement } from 'react';

const mountComponents = components => {
  const elements = document.querySelectorAll('[data-component]');
  elements.forEach(element => {
    const name = element.getAttribute('data-component');
    const props = JSON.parse(element.getAttribute('data-props') || '{}');

    if (components[name]) {
      ReactDOM.render(createElement(components[name], props), element);
    } else {
      throw new Error(`Failed to mount "${name}". No such component.`);
    }
  });
};

export default mountComponents;

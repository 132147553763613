import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import routes from 'modules/routing/routes';
import { toastInfo } from 'modules/toast-notifications/toastNotificationsSlice';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import Api from 'modules/core/Api';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Modal from '@thrivetrm/ui/components/Modal';
import TextArea from '@thrivetrm/ui/components/TextArea';
import useToggle from '@thrivetrm/ui/hooks/useToggle';

const toggleContactIndexFilter = feedback => {
  Api.patch(routes.api_v1_user_permissions_toggle(), {
    permission: {
      display_name: 'filter redesign',
      feature_name: 'development.contacts_index_page_v5',
      feedback: feedback,
    },
  }).then(() => window.location.reload());
};

const FeatureOptInDialog = () => {
  const dispatch = useDispatch();
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const [feedbackValue, setFeedbackValue] = useState('');
  const hasContactIndexFilter = useFeatureCheck(
    'development.contacts_index_page_v5',
  );

  useEffect(() => {
    const optInContent = (
      <>
        <ButtonLink
          label='Launch Advanced Search'
          onClick={() => toggleContactIndexFilter(null)}
        />
        <p>
          Ready for faster, better search results? Click the link above to get
          started.
        </p>
      </>
    );

    const optOutContent = (
      <>
        <ButtonLink label='Return' onClick={openModal} /> to the original search
        view.
      </>
    );

    dispatch(
      toastInfo(hasContactIndexFilter ? optOutContent : optInContent, {
        isSticky: true,
      }),
    );
  }, [dispatch, hasContactIndexFilter, openModal]);

  return isModalOpen ? (
    <Modal
      data-testid='featureOptInDialog'
      isOpen={true}
      onClose={closeModal}
      size='small'
      title='Advanced Search Feedback'
    >
      <Modal.Body>
        <TextArea
          label='Why Are You Reverting?'
          onChange={setFeedbackValue}
          placeholder='Share your feedback with our product team so we can improve searching in Thrive.'
          value={feedbackValue}
          width='full'
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary isOutline={true} label='Cancel' onClick={closeModal} />
        <ButtonPrimary
          label='Opt Out'
          onClick={() => toggleContactIndexFilter(feedbackValue)}
        />
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default FeatureOptInDialog;

import React from 'react';
import PropTypes from 'prop-types';
import Notes from './Notes';
import Outreach from './Outreach';
import Appointment from './Appointment';
import Reference from './Reference';
import Introduction from './Introduction';
import Notification from './Notification';

const NOTIFICATION_TYPES = {
  note: Notes,
  outreach: Outreach,
  meeting: Appointment,
  interview: Appointment,
  reference: Reference,
  introduction: Introduction,
};

const NotificationItem = ({ notification }) => {
  const NotificationComponent =
    NOTIFICATION_TYPES[notification.type] || Notification;

  return <NotificationComponent notification={notification} />;
};

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    type: PropTypes.string,
  }),
};

export default NotificationItem;

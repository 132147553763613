import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import ActivityListView from 'modules/activities/components/ActivityListView';
import { PARENT_CANDIDACY } from 'modules/activities/constants';

export default compose(
  setDisplayName('CandidacySearchSidebarActivity'),
  setPropTypes({
    candidacyId: PropTypes.number,
  }),
  mapProps(({ candidacyId }) => ({
    parentId: candidacyId,
    parentType: PARENT_CANDIDACY,
  })),
)(ActivityListView);

import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import DataProvider from 'modules/thrive-app/components/DataProvider';
import getApplicationTitle from 'modules/tenant/selectors/getApplicationTitle';
import store, { history } from '../../store';
import AppPages from './AppPages';

const ThriveApp = ({ data }) => {
  const title = getApplicationTitle(store.getState());

  return (
    <Provider store={store}>
      <div className='ThriveApp'>
        {data && <DataProvider data={data} />}
        <Helmet defaultTitle={title} titleTemplate={`%s - ${title}`} />
        <Router history={history}>
          <AppPages />
        </Router>
      </div>
    </Provider>
  );
};

ThriveApp.defaultProps = {
  data: null,
};

ThriveApp.propTypes = {
  data: DataProvider.propTypes.data,
};

export default ThriveApp;

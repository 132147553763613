import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  componentFromProp,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import getEmailTemplateName from '../selectors/getEmailTemplateName';

export default compose(
  setDisplayName('EmailTemplateName(enhanced)'),
  setPropTypes({
    /**
     * The template ID of the template to show the title of.
     */
    emailTemplateId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { emailTemplateId }) => ({
      children: getEmailTemplateName(state, emailTemplateId),
    }),
    {},
  ),
  mapProps(({ emailTemplateId: _emailTemplateId, ...rest }) => ({
    component: 'span',
    ...rest,
  })),
)(componentFromProp('component'));

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Overlay from 'react-bootstrap/lib/Overlay';
import ColorPickerPopover from './ColorPickerPopover';

/**
 * Renders a button filled with a selected color. When the button is clicked
 * a popover displays a color picker.
 */
const ColorInput = ({
  className,
  disableAlpha,
  onChange,
  popoverPlacement,
  value,
  ...otherProps
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const buttonRef = useRef(null);

  const handleButtonClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleHideColorPicker = () => {
    setDisplayColorPicker(false);
  };

  return (
    <div className={classnames(className, 'ColorInput')}>
      <button
        aria-label={value}
        className='ColorInput__button'
        onClick={handleButtonClick}
        ref={buttonRef}
        style={{ backgroundColor: value }}
        type='button'
      />
      <Overlay
        onHide={handleHideColorPicker}
        placement={popoverPlacement}
        rootClose={true}
        show={displayColorPicker}
        target={buttonRef.current}
      >
        <ColorPickerPopover
          {...otherProps}
          color={value}
          disableAlpha={disableAlpha}
          onChangeComplete={onChange}
        />
      </Overlay>
    </div>
  );
};

ColorInput.defaultProps = {
  disableAlpha: true,
  popoverPlacement: 'bottom',
  value: '#DBDBDB',
};

ColorInput.propTypes = {
  /**
   * An optional class name to add to the outer container.
   */
  className: PropTypes.string,

  /**
   * True to remove alpha slider and options from picker. Default true.
   */
  disableAlpha: PropTypes.bool,

  /**
   * Called when the selected color is changed: (colorObject, event)
   * @see react-color docs on onChangeComplete.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Where to place the color popover relative to the button.
   */
  popoverPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),

  /**
   * The selected color as a hex value (i.e. #AA1122)
   */
  value: PropTypes.string,
};

export default ColorInput;

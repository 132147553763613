import { Map } from 'immutable';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import withTransactionTrackedForm from '../../forms/withTransactionTrackedForm';
import BasicField from './BasicField';

const DEFAULT_CONTACT = new Map({
  data: new Map(),
});

/**
 * Creates the root field state for a contact edit/create form.
 * @param {Object} values Initial values to populate the field values with. This should be a
 * contact-type object, i.e.:
 * ```
 * {
 *   first_name: 'Jane',
 *   last_name: 'Doe',
 * }
 * ```
 * @return {FieldState} A `FieldState` usable with the `BasicForm` component.
 */
export const createRootFieldState = values =>
  BasicField.createFieldState(
    'contact',
    DEFAULT_CONTACT.merge({ data: values }),
  );

export const handleSubmit = (
  formState,
  { contactActions, network, search },
) => {
  const transactionId = uniqueId();

  const contact = { ...formState.getFieldValue() };
  if (network) contact.network_ids = [network.id];
  if (search) contact.search_ids = [search.id];

  contactActions.createContact({
    contact: contact,
    transactionId: transactionId,
  });
  return formState.startSubmit(transactionId);
};

export default withTransactionTrackedForm(
  createRootFieldState,
  handleSubmit,
  {},
)(BasicField);

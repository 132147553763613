import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, mapProps, setPropTypes } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import CompanyRevenueView from './CompanyRevenueView';
import withRevenueListFetched from './withRevenueListFetched';
import withSortedRevenueIds from './withSortedRevenueIds';

/**
 * Renders a list of company revenue records for display.
 */
export const CompanyRevenueListView = ({ isLoading, revenueIds, ...props }) => (
  <div className='CompanyRevenueListView'>
    {revenueIds &&
      revenueIds
        .map(revenueId => (
          <CompanyRevenueView
            {...props}
            key={revenueId}
            revenueId={revenueId}
          />
        ))
        .toArray()}
    {isLoading && <LoadingIndicator />}
  </div>
);

CompanyRevenueListView.propTypes = {
  /**
   * True if the revenues are in the proccess of fetching.
   */
  isLoading: PropTypes.bool,

  /**
   * The IDs of the revenue records to.
   */
  revenueIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
};

export default compose(
  setDisplayName('CompanyRevenueListView(enhanced)'),
  setPropTypes({
    /**
     * The ID of the company to display the list of revenues for.
     */
    companyId: PropTypes.number.isRequired,
  }),
  withRevenueListFetched,
  mapProps(({ revenueList }) => ({
    revenueIds: revenueList && revenueList.get('ids'),
    isLoading: revenueList && revenueList.getIn(['_meta', 'isFetching']),
  })),
  withSortedRevenueIds,
)(CompanyRevenueListView);

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import talentPoolsSchema from '../schema';

/**
 * Gets the number of candidacies currently associated with a talent pool
 * @param {Object} state
 * @param {Number} id The talent pool ID
 * @return {Number} The number of candidacies assocaited with the talent pool.
 */
export default (state, id) =>
  getEntityProperty(state, talentPoolsSchema.key, id, 'candidacies_count');

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import {
  compose,
  withHandlers,
  withProps,
  withState,
  setStatic,
} from 'recompose';
import BooleanRadioSelectField from 'modules/forms/components/BooleanRadioSelectField';
import FieldState from 'modules/forms/FieldState';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import withNestedFieldChangeHandler from 'modules/forms/components/withNestedFieldChangeHandler';
import * as validators from 'modules/forms/validators';
import getSearchProperty from 'modules/searches/selectors/getSearchProperty';
import SearchSelectField from 'modules/searches/components/SearchSelectField';
import RejectionReasonSelectField from 'modules/search-stages/components/RejectionReasonSelectField';
import TextAreaField from 'modules/forms/components/TextAreaField';
import ContactsInSearchMessage from './ContactsInSearchMessage';
import withExistingCandidaciesCount from './withExistingCandidaciesCount';
import { RECOMMENDED_STAGE_OPTION } from '../constants';

/**
 * A field for selecting the search and search stage to add a contact or list of contacts to.
 */
class CandidaciesCreateModalField extends Component {
  static createFieldState(name = 'candidaciesCreateModalField', selections) {
    const values = fromJS({ isCandidacyHidden: false, stageId: null }).merge(
      selections,
    );

    return FieldState.createNested(name, [
      SearchSelectField.createFieldState(
        'search',
        null,
        validators.requiredField('Search'),
      ),
      ReactSelectField.createFieldState(
        'stage',
        values.get('stageId'),
        validators.requiredField('Stage'),
      ),
      BooleanRadioSelectField.createFieldState(
        'hidden',
        values.get('isCandidacyHidden'),
      ),
      RejectionReasonSelectField.createFieldState(
        'rejection_reason',
        values.get('rejection_reason'),
      ),
      TextAreaField.createFieldState(
        'rejection_comment',
        values.get('rejection_comment'),
      ),
    ]);
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      contactIds,
      existingCandidaciesCount,
      fieldState,
      handleNestedFieldChange,
      onStageFieldChange,
      searchStageOptions,
      searchType,
      searchTypeLabel,
      shouldShowCandidacyVisibilityControl,
    } = this.props;

    const rejectedStage =
      searchStageOptions?.filter(stage => stage.rejected) || [];

    const isRejectedReason = rejectedStage?.some(
      stage => fieldState.getNestedField('stage').getValue() === stage.id,
    );

    return (
      <div>
        <SearchSelectField
          className='SearchListSelectField'
          fieldState={fieldState.getNestedField('search')}
          label={searchTypeLabel}
          onChange={handleNestedFieldChange}
          searchType={searchType}
        />
        <ContactsInSearchMessage
          contactIds={contactIds}
          contactsInSearch={existingCandidaciesCount}
          searchTypeLabel={searchTypeLabel}
        />

        <ReactSelectField
          className='StageListSelectField'
          fieldState={fieldState.getNestedField('stage')}
          label='Stage'
          onChange={onStageFieldChange}
          options={searchStageOptions?.map(stage => ({
            label: stage?.name,
            value: stage?.id,
          }))}
          simpleValue={true}
        />

        {isRejectedReason ? (
          <>
            <RejectionReasonSelectField
              fieldState={fieldState.getNestedField('rejection_reason')}
              key='rejection_reason'
              label='Rejection reason'
              onChange={this.handleFieldChange}
            />
            <TextAreaField
              fieldState={fieldState.getNestedField('rejection_comment')}
              key='rejection_comment'
              label='Comments'
              onChange={this.handleFieldChange}
            />
          </>
        ) : null}
        {shouldShowCandidacyVisibilityControl && (
          <BooleanRadioSelectField
            falseLabel='Visible'
            fieldState={fieldState.getNestedField('hidden')}
            label='Candidate Visibility'
            onChange={handleNestedFieldChange}
            trueLabel='Hidden'
          />
        )}
      </div>
    );
  }
}

CandidaciesCreateModalField.propTypes = {
  contactIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  /**
   * The count of selected contacts that are already in the
   * selected search
   */
  existingCandidaciesCount: PropTypes.number,
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  handleNestedFieldChange: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onStageFieldChange: PropTypes.func.isRequired,
  searchStageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  searchType: PropTypes.string.isRequired,
  searchTypeLabel: PropTypes.string.isRequired,
  shouldShowCandidacyVisibilityControl: PropTypes.bool.isRequired,
};

function mapStateToProps(state, { searchId }) {
  return {
    recommendationsEnabled: getSearchProperty(
      state,
      searchId,
      'enable_recommendations',
    ),
  };
}

export default compose(
  setStatic('createFieldState', CandidaciesCreateModalField.createFieldState),
  withNestedFieldChangeHandler,
  withExistingCandidaciesCount(),
  connect(mapStateToProps),
  withState(
    'shouldShowCandidacyVisibilityControl',
    'setShouldShowCandidacyVisibilityControl',
    true,
  ),
  withHandlers({
    onStageFieldChange: ({
      handleNestedFieldChange,
      setShouldShowCandidacyVisibilityControl,
    }) => nestedField => {
      handleNestedFieldChange(nestedField);
      setShouldShowCandidacyVisibilityControl(
        nestedField.getValue() !== RECOMMENDED_STAGE_OPTION.value,
      );
    },
  }),
  withProps(
    ({ recommendationsEnabled, searchStageOptions, shouldShowRecommend }) => {
      const isRecommendPresent = searchStageOptions.some(
        option => option.label === RECOMMENDED_STAGE_OPTION.label,
      );
      return {
        searchStageOptions:
          !isRecommendPresent && shouldShowRecommend && recommendationsEnabled
            ? [...searchStageOptions, RECOMMENDED_STAGE_OPTION]
            : searchStageOptions,
      };
    },
  ),
)(CandidaciesCreateModalField);

import React from 'react';
import PropTypes from 'prop-types';

const AboveBoardIcon = ({ backgroundColor, color }) => (
  <svg
    style={{ position: 'relative', top: '1px', width: '14px' }}
    viewBox='0 0 14 14'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.4 14H1.6C.7 14 0 13.3 0 12.4V1.6C0 .7.7 0 1.6 0h10.9c.8 0 1.5.7 1.5 1.6v10.9c0 .8-.7 1.5-1.6 1.5z'
      fill={backgroundColor}
    />
    <path
      d='M5.5916 4.0398a.1498.1498 0 00-.1328-.0784.1507.1507 0 00-.133.0788L3.4203 7.397a.1632.1632 0 00-.001.161c.0135.0242.0533.0807.134.0807h4.0922l-2.054-3.599zM3.5533 8.1513c-.2354 0-.4458-.1268-.5627-.339a.6875.6875 0 01.0042-.6755L4.9003 3.78c.1175-.2073.326-.3312.5577-.3314h.0008c.2312 0 .4397.1232.5577.3299l2.203 3.8602h1.1312c.0807 0 .1205-.0567.134-.0809a.1628.1628 0 00-.0014-.1612L5.5954.5912a.1534.1534 0 00-.1338-.0786.1511.1511 0 00-.133.0803L.5154 9.2468a.1633.1633 0 000 .1603c.0137.0242.0535.0801.1338.0801l7.7782-.0035c.0811 0 .121-.0566.1342-.081a.1624.1624 0 00-.0014-.161L7.938 8.1514H3.5533zM.088 9.6634a.6878.6878 0 010-.673L4.901.3365c.1167-.2092.325-.335.5579-.3364h.0038c.2312 0 .4394.123.5575.3297l3.888 6.8054a.6876.6876 0 01.0059.6763c-.1167.213-.3275.34-.5633.34H8.512l.4733.829a.6877.6877 0 01.0054.6761c-.1167.2127-.327.3397-.5631.34L.6494 10H.6492c-.2344 0-.444-.1257-.5613-.3366z'
      fill={color}
      transform='translate(2 2)'
    />
  </svg>
);

AboveBoardIcon.defaultProps = {
  backgroundColor: 'currentColor',
  color: '#FFF',
};

AboveBoardIcon.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

export default AboveBoardIcon;

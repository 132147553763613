import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, withHandlers, setStatic } from 'recompose';
import ReactSelectField from './ReactSelectField';

/**
 * A dropdown select styled to look like a linked title.
 */
const TitleDropdownField = ({ onClose, onOpen, valueRenderer, ...props }) => (
  <ReactSelectField
    {...props}
    className='title-dropdown-field'
    clearable={false}
    onClose={onClose}
    onOpen={onOpen}
    searchable={false}
    simpleValue={true}
    valueRenderer={valueRenderer}
  />
);

TitleDropdownField.propTypes = {
  // Handler functions to update the menu status (open or closed) in state, so
  // we know which direction to render the caret.
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  /**
   * A function that, given a react-select option ({ value, label }) and a direction,
   * appends a Font Awesome caret to the label text and returns the new label
   */
  valueRenderer: PropTypes.func.isRequired,
};

export default compose(
  setStatic('createFieldState', ReactSelectField.createFieldState),
  withState('isMenuOpen', 'setMenuOpenValue', false),
  withHandlers({
    onClose: ({ setMenuOpenValue }) => () => setMenuOpenValue(false),
    onOpen: ({ setMenuOpenValue }) => () => setMenuOpenValue(true),
    valueRenderer: ({ isMenuOpen }) => option => {
      let { label } = option;
      if (option.id === 0) {
        label = 'Templates';
      }
      return (
        <div>
          <span>{label} </span>
          <i className={`fa fa-caret-${isMenuOpen ? 'up' : 'down'}`} />
        </div>
      );
    },
  }),
)(TitleDropdownField);

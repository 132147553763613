import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import ModalButton from 'modules/core/componentsLegacy/ModalButton';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import CandidacyMoveStageModalForm from './CandidacyMoveStageModalForm';

/**
 * Renders a button that, when clicked, shows a modal where candidacy's stages
 * can be moved.
 */
export default compose(
  setDisplayName('CandidacyMoveStageModalButton'),
  setPropTypes({
    /**
     * The ID of the candidacy whose stage will be moved.
     */
    candidacyId: PropTypes.number.isRequired,
  }),
  withProps({
    modal: CandidacyMoveStageModalForm,
    children: <i className='fa fa-share' />,
  }),
  withClassName('CandidacyMoveStageModalButton btn btn-default btn-sm'),
)(ModalButton);

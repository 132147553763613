import React from 'react';
import PropTypes from 'prop-types';
import Date from '@thrivetrm/ui/components/Date';
import ExpandableActivityView from './ExpandableActivityView';

const InterviewActivityView = ({
  createdBy,
  description,
  location,
  startTime,
  subject,
}) => (
  <div className='u-marginTop-4 u-fontSize-small'>
    {createdBy?.name ? (
      <p className='u-margin-n'>
        <strong>Organized by:</strong> {createdBy.name}
      </p>
    ) : null}
    {subject ? (
      <p className='u-margin-n'>
        <strong>Event Title:</strong> {subject}
      </p>
    ) : null}
    {location ? (
      <p className='u-margin-n'>
        <strong>Location:</strong> {location}
      </p>
    ) : null}
    {startTime ? (
      <p className='u-margin-n'>
        <strong>When:</strong> <Date date={startTime} format='dateTime' />
      </p>
    ) : null}
    <ExpandableActivityView content={description} />
  </div>
);

InterviewActivityView.propTypes = {
  createdBy: PropTypes.shape({
    name: PropTypes.string,
  }),
  description: PropTypes.string,
  location: PropTypes.string,
  startTime: PropTypes.string,
  subject: PropTypes.string,
};

export default InterviewActivityView;

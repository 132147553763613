import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';

import withDefaultRejectionReason from 'modules/search-stages/components/withDefaultRejectionReason';
import mapCandidacyIdToCandidacy from '../mapCandidacyIdToCandidacy';
import withCandidacyFetched from '../withCandidacyFetched';
import CandidacyStageListPopover from './CandidacyStageListPopover';
import CandidacyRejectionForm from './CandidacyRejectionForm';

/**
 * Used to signify that the "Stage list" popover should be shown
 * @const {String}
 */
const POPOVER_STAGE_LIST = 'POPOVER_STAGE_LIST';

/**
 * Used to signify that the "rejection form" popover should be shown
 * @const {String}
 */
const POPOVER_REJECT_FORM = 'POPOVER_REJECT_FORM';

/**
 * Renders a button that can be used to change the stage that a candidacy is currently
 * assigned to. Initially shows a list of stages that the candidacy can be moved to,
 * and if the "rejected" stage is selected, a rejection form is shown requiring the user
 * to fill out a rejection reason and comment before changing stages.
 */
const CandidacyMoveStageButton = ({
  btnClassName,
  candidacy,
  children,
  componentId,
  defaultRejectionReason,
  getScrollContainer,
  getTargetRef,
  handleHidePopover,
  handleShowRejectionForm,
  handleTogglePopover,
  popoverForm,
  rejectionStageId,
  setTargetRef,
}) => {
  const isUpdating = candidacy && candidacy.getIn(['_meta', 'isUpdating']);
  return [
    <button
      aria-expanded={Boolean(popoverForm)}
      aria-haspopup='true'
      className={classnames('CandidacyMoveStageButton btn', btnClassName)}
      disabled={isUpdating || !candidacy}
      key='button'
      onClick={handleTogglePopover}
      ref={setTargetRef}
      type='button'
    >
      {children || (
        <i
          className={classnames('fa', {
            'fa-share': !isUpdating,
            'fa-refresh': isUpdating,
            'fa-spin': isUpdating,
          })}
        />
      )}
    </button>,
    candidacy && popoverForm && (
      <Overlay
        animation={false}
        container={getScrollContainer}
        containerPadding={20}
        id={componentId}
        key='overlay'
        onHide={handleHidePopover}
        placement='bottom'
        rootClose={true}
        shouldUpdatePosition={true}
        show={Boolean(popoverForm)}
        target={getTargetRef}
      >
        {popoverForm === POPOVER_STAGE_LIST ? (
          <CandidacyStageListPopover
            candidacy={candidacy}
            id={`${componentId}-stage-list-popover`}
            onCancel={handleHidePopover}
            onRejection={handleShowRejectionForm}
            onSaved={handleHidePopover}
          />
        ) : (
          <Popover
            className='CandidacyMoveStageButton__rejectionPopover'
            id={`${componentId}-reject-form-popover`}
            title='Rejection Reason'
          >
            <CandidacyRejectionForm
              candidacyId={candidacy.get('id')}
              defaultRejectionReason={defaultRejectionReason}
              onCancel={handleHidePopover}
              onSaved={handleHidePopover}
              stageId={rejectionStageId}
            />
          </Popover>
        )}
      </Overlay>
    ),
  ];
};

CandidacyMoveStageButton.propTypes = {
  /**
   * Props provided by withScrollContainerRef (getScrollContainer, getTargetRef, setTargetRef)
   */
  ...withScrollContainerRef.propTypes,

  /**
   * Classname for the button, it defaults to btn-default
   */
  btnClassName: PropTypes.string,

  candidacy: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isUpdating: PropTypes.bool,
    }),
  }),

  /**
   * Takes the place of share icon if children are sent in to the
   * component.
   */
  children: PropTypes.node,

  /**
   * A unique ID for the component instance (provided by `withComponentId`)
   */
  componentId: PropTypes.string.isRequired,

  /**
   * Called when the popover should be hidden (causing `popoverForm` to be set to `null`)
   */
  handleHidePopover: PropTypes.func.isRequired,

  /**
   * Called when the rejection form popover should be displayed.
   */
  handleShowRejectionForm: PropTypes.func.isRequired,

  /**
   * Called when the button is clicked, causing the `popoverForm` value to be toggled
   * between null and POPOVER_STAGE_LIST,
   */
  handleTogglePopover: PropTypes.func.isRequired,

  /**
   * Which popover should be rendered (if any).
   */
  popoverForm: PropTypes.oneOf([POPOVER_STAGE_LIST, POPOVER_REJECT_FORM]),

  /**
   * The ID of rejection stage to be submitted with the rejection form.
   */
  rejectionStageId: PropTypes.number,
};

CandidacyMoveStageButton.defaultProps = {
  btnClassName: 'btn-default',
};

export default compose(
  setDisplayName('CandidacyMoveStageButton(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),

  mapCandidacyIdToCandidacy,
  withCandidacyFetched,

  withComponentId(),
  withScrollContainerRef,
  withDefaultRejectionReason,

  withState('popoverForm', 'setPopoverForm'),
  withState('rejectionStageId', 'setRejectionStageId'),
  withHandlers({
    /**
     * Used by the main button, toggles the visibility of the popover
     */
    handleTogglePopover: ({ popoverForm, setPopoverForm }) => () =>
      setPopoverForm(popoverForm ? null : POPOVER_STAGE_LIST),

    /**
     * Hides the popover entirely
     */
    handleHidePopover: ({ setPopoverForm }) => () => setPopoverForm(null),

    /**
     * Shows the rejection for popover
     */
    handleShowRejectionForm: ({
      setPopoverForm,
      setRejectionStageId,
    }) => stage => {
      setRejectionStageId(stage.get('id'));
      setPopoverForm(POPOVER_REJECT_FORM);
    },
  }),
)(CandidacyMoveStageButton);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * A layout for rendering a "profile page".
 * This page uses a patterned background at the top of the page, and shows various cnotent
 * sections. It may contain one or more `ProfilePageSection`
 * components.
 *
 * @example
 * ```jsx
 * <ProfilePage>
 *   <header>
 *     This gets displayed with a white background.
 *   </header>
 *   <ProfilePageSection>
 *     This is a section -- it has a border and a white background
 *   </ProfilePageSection>
 *   <div>
 *    Any other component are allowed, but will need to be styled as-needed.
 *   </div>
 * </ProfilePage>
 * ```
 *
 * Note the outer component is used to show the backdrop pattern full width, and the inner
 * container is used to create a standard fixed-width wrapper.
 */
const ProfilePage = ({ children, className }) => (
  <div className={classnames('ProfilePage', className)}>
    <div className='ProfilePage__container container'>{children}</div>
  </div>
);

ProfilePage.propTypes = {
  /**
   * The contents of the page.
   */
  children: PropTypes.node,

  /**
   * An optional additional class name to apply to the page component.
   */
  className: PropTypes.string,
};

export default ProfilePage;

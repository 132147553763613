import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import PopoverTrigger from '../componentsLegacy/PopoverTrigger';

const CommaSeparatedLinkList = ({
  className,
  items,
  limit,
  title,
  ...props
}) => {
  let listItems = [...items];
  let popoverListItems = null;

  if (limit && listItems.length > limit) {
    listItems = listItems.slice(0, limit);
    const additionalListItems = items.slice(limit);

    const popoverContent = (
      <ul className='u-textAlign-l u-margin-n u-paddingHorizontal-16 u-paddingVertical-4'>
        {additionalListItems.map(item => (
          <li className='u-margin-n' key={item.name}>
            <a href={item.path}>{item.name}</a>
          </li>
        ))}
      </ul>
    );

    popoverListItems = (
      <PopoverTrigger
        content={popoverContent}
        placement='left'
        rootClose={true}
        title={title}
        trigger='click'
      >
        <span className='CommaSeparatedLinkList__additionalText'>
          {', '}+{additionalListItems.length} more
        </span>
      </PopoverTrigger>
    );
  }

  const containerClass = classnames('CommaSeparatedLinkList', className);
  return (
    <div {...props} className={containerClass}>
      {listItems?.length
        ? listItems.map((item, index) => (
            <a
              className='CommaSeparatedLinkList__link'
              href={item.path}
              key={item.path}
            >
              {item.name}
              {index < listItems.length - 1 ? ', ' : null}
            </a>
          ))
        : null}
      {popoverListItems}
    </div>
  );
};

CommaSeparatedLinkList.defaultProps = {
  className: null,
  limit: null,
  title: null,
};

CommaSeparatedLinkList.propTypes = {
  /** Classname to render on the root node */
  className: PropTypes.string,
  /** A collection of Link to render */
  items: PropTypes.arrayOf(
    PropTypes.PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /** Number of links to render. Any remaining links are rendered in a popover. */
  limit: PropTypes.number,
  /** The title to show in the popover header. */
  title: PropTypes.string,
};

export default CommaSeparatedLinkList;

import getAllContactMethods from './getAllContactMethods';

/**
 * Determines if the list of all contact methods should be fetched.
 * @param {Object} state
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default state => {
  const contactMethods = getAllContactMethods(state);

  if (!contactMethods) {
    return true;
  }

  if (contactMethods.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (contactMethods.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !contactMethods.has('ids') ||
    Boolean(contactMethods.getIn(['_meta', 'isInvalidated']))
  );
};

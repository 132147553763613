import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@thrivetrm/ui/components/Menu';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import FullPageTakeoverHeader from '@thrivetrm/ui/components/FullPageTakeoverHeader';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import { useTemporaryBrowserData } from '@thrivetrm/ui/components/TemporaryBrowserData';
import TemporaryBrowserDataStatus from '@thrivetrm/ui/components/TemporaryBrowserDataStatus';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import useFormValues from '@thrivetrm/ui/hooks/useFormValues';
import { ViewTypes } from './CandidateAssessmentFormContent';

const TEMPORARY_DATA_TTL_DAYS = 45;

const EditCandidateAssessmentFormHeader = ({
  assessmentId,
  candidacyId,
  categories,
  criteria,
  hasBeenSubmitted,
  isBrowserDataSameAsSaved,
  isConsentChecked,
  isFromActiveAdmin,
  isGuestAssessment,
  isSubmitting,
  isTalentPool,
  onSubmit,
  onViewTypeChange,
  search,
  shouldShowConsentPage,
  updateConsent,
  viewType,
}) => {
  const [
    isNavigateBackDialogOpen,
    openNavigateBackDialog,
    closeNavigateBackDialog,
  ] = useToggle(false);

  const [
    isSubmitConfirmationDialogOpen,
    openSubmitConfirmationDialog,
    closeSubmitConfirmationDialog,
  ] = useToggle(false);

  const formState = useFormValues();

  const { deleteData } = useTemporaryBrowserData();

  const assessmentAnswers = categories?.length
    ? categories
        ?.map(category =>
          category.criteria.map(criterion => ({
            notes: formState[`${category.id}_${criterion.id}_notes`],
            rating_value: formState[`${category.id}_${criterion.id}_rating`],
          })),
        )
        .flat()
    : criteria?.map(criterion => ({
        notes: formState[`${criterion.id}_notes`],
        rating_value: formState[`${criterion.id}_rating`],
      }));

  const completedAnswerCount =
    assessmentAnswers?.filter(
      answer => answer.notes?.length || answer.rating_value,
    )?.length || 0;

  const isMissingCriteriaAnswers =
    completedAnswerCount !== assessmentAnswers?.length;
  // eslint-disable-next-line no-underscore-dangle
  const isNewUser = formState?.assessed_by?.__isNew__;
  const hasValidUser = isNewUser
    ? formState?.assessed_by?.value.trim()
    : formState?.assessed_by;
  const hasRequiredValues =
    formState?.summary_notes && (hasValidUser || formState?.assessed_by_name);
  const shouldConfirmBeforeSubmit =
    isMissingCriteriaAnswers || isGuestAssessment;

  const goBack = () => {
    window.location.href = isFromActiveAdmin
      ? '/admin/candidacy_assessments'
      : `/${isTalentPool ? 'talent_pools' : 'searches'}/${
          search?.id
        }/candidates/${candidacyId}/candidacy_assessments`;
  };
  const openNavigateBackDialogOrNavigateBack = () => {
    if (isBrowserDataSameAsSaved) {
      deleteData?.();
      goBack();
    } else {
      openNavigateBackDialog();
    }
  };

  return (
    <>
      <FullPageTakeoverHeader
        actionButtons={
          !shouldShowConsentPage ? (
            <>
              <Menu
                button={
                  <ButtonPrimary
                    className='u-noWrap u-marginRight-16'
                    isOutline={true}
                    label='Toggle View'
                    size='small'
                  />
                }
                isPinnedRight={true}
              >
                <Menu.Item
                  isDisabled={viewType === ViewTypes.ASSESSMENT_ONLY}
                  onClick={() => onViewTypeChange(ViewTypes.ASSESSMENT_ONLY)}
                >
                  {ViewTypes.ASSESSMENT_ONLY}
                </Menu.Item>
                <Menu.Item
                  isDisabled={viewType === ViewTypes.SPLIT_SCREEN}
                  onClick={() => onViewTypeChange(ViewTypes.SPLIT_SCREEN)}
                >
                  {ViewTypes.SPLIT_SCREEN}
                </Menu.Item>
                <Menu.Item
                  isDisabled={viewType === ViewTypes.RAW_NOTES_ONLY}
                  onClick={() => onViewTypeChange(ViewTypes.RAW_NOTES_ONLY)}
                >
                  {ViewTypes.RAW_NOTES_ONLY}
                </Menu.Item>
              </Menu>
              {!hasBeenSubmitted && !shouldShowConsentPage ? (
                <ButtonPrimary
                  isDisabled={!hasRequiredValues || isSubmitting}
                  label={assessmentId ? 'Update' : 'Submit'}
                  onClick={
                    shouldConfirmBeforeSubmit
                      ? openSubmitConfirmationDialog
                      : () => onSubmit(formState)
                  }
                  size='small'
                />
              ) : null}
            </>
          ) : (
            <ButtonPrimary
              isDisabled={!isConsentChecked}
              label='Continue'
              onClick={updateConsent}
              size='small'
            />
          )
        }
        leftText={
          !isBrowserDataSameAsSaved ? (
            <TemporaryBrowserDataStatus
              shouldAllowDiscardChanges={true}
              temporaryDataLifespan={TEMPORARY_DATA_TTL_DAYS}
              tooltipPosition='bottomRight'
            />
          ) : null
        }
        onBackClick={
          isGuestAssessment ? undefined : openNavigateBackDialogOrNavigateBack
        }
        rightText={
          !shouldShowConsentPage &&
          categories &&
          criteria &&
          !hasBeenSubmitted &&
          assessmentAnswers?.length ? (
            <p className='u-margin-n u-textColor-gray40'>
              {completedAnswerCount} out of {assessmentAnswers.length} criteria
              completed
            </p>
          ) : null
        }
        subTitle={search?.name || 'Loading...'}
        title={
          assessmentId
            ? 'Edit Assessment'
            : `Add ${isGuestAssessment ? 'Guest ' : ''}Assessment`
        }
      />
      <ConfirmationDialog
        cancelLabel={isGuestAssessment ? 'Cancel' : 'No, Continue Editing'}
        confirmLabel={isGuestAssessment ? 'Save' : 'Yes, Save Assessment'}
        isOpen={isSubmitConfirmationDialogOpen}
        key='submit'
        onClose={closeSubmitConfirmationDialog}
        onConfirm={() => onSubmit(formState)}
        title={isGuestAssessment ? 'Ready to Submit?' : 'Incomplete Assessment'}
      >
        <p>
          {isGuestAssessment
            ? 'Are you sure you want to submit? Once submitted, you will not be able to make edits.'
            : 'Are you sure you want to save this assessment?'}
        </p>
        {isMissingCriteriaAnswers ? (
          <p>There are criteria missing Notes and/or Star Ratings.</p>
        ) : null}
      </ConfirmationDialog>
      <ConfirmationDialog
        cancelLabel='No, Keep Editing'
        confirmLabel='Yes, Save Draft & Leave'
        isOpen={isNavigateBackDialogOpen}
        onClose={closeNavigateBackDialog}
        onConfirm={goBack}
        title='Save Draft and Leave?'
      >
        <p className='u-textAlign-c'>
          If you leave without submitting, the draft will be saved locally to
          your browser for 7 days before being deleted.
        </p>
        <p className='u-textAlign-c'>
          Are you sure you want to leave without submitting the assessment?
        </p>
      </ConfirmationDialog>
    </>
  );
};

EditCandidateAssessmentFormHeader.defaultProps = {
  hasBeenSubmitted: false,
  isGuestAssessment: false,
};

EditCandidateAssessmentFormHeader.propTypes = {
  assessment: PropTypes.shape({
    assessed_by: PropTypes.shape({
      email: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    assessed_by_name: PropTypes.string,
    assessed_on: PropTypes.string,
    summary_notes: PropTypes.string,
  }),
  assessmentId: PropTypes.number,
  candidacyId: PropTypes.number,
  // Ideally this is not disabled, fix is to define the category object shape
  // eslint-disable-next-line react/forbid-prop-types
  categories: PropTypes.arrayOf(PropTypes.object),
  // Ideally this is not disabled, fix is to define the criteria object shape
  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.arrayOf(PropTypes.object),
  hasBeenSubmitted: PropTypes.bool,
  isBrowserDataSameAsSaved: PropTypes.bool,
  isConsentChecked: PropTypes.bool,
  isFromActiveAdmin: PropTypes.bool,
  isGuestAssessment: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isTalentPool: PropTypes.bool,
  onSubmit: PropTypes.func,
  onViewTypeChange: PropTypes.func,
  search: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  shouldShowConsentPage: PropTypes.bool,
  updateConsent: PropTypes.func,
  viewType: PropTypes.string,
};

export default EditCandidateAssessmentFormHeader;

import fetchEntity from 'modules/entities/actions/fetchEntity';
import userSchema from '../schema';

/**
 * Creates an action for fetching a user
 * @param {Object} payload
 * @param {String} payload.id The ID of the user
 */
export default ({ id }) =>
  fetchEntity({
    entityType: userSchema.key,
    id: id,
    responseSchema: userSchema,
    url: `/api/users/${id}`,
  });

import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import revenueSchema from '../schema';

/**
 * Gets the year value for a particular revenue record
 * @param {*} state
 * @param {Number} revenueId The ID of the revenue record
 * @returns {?Number} The year value of the revenue record, if found.
 */
export default (state, revenueId) =>
  getEntityProperty(state, revenueSchema.key, revenueId, 'year');

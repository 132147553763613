import { NAME } from '../constants';

/**
 * Gets the maximum rating for assesssments for the current tenant.
 * @param {Object} state
 * @return {String} The current tenant's maximum assessment rating value (default: 5)
 */
const getMaxRating = state => state[NAME].get('rating_stars', 5);

export default getMaxRating;

import apiV1 from './index';
import queryTags from './queryTags';

const { NETWORKS } = queryTags;

const networks = apiV1.injectEndpoints({
  endpoints: builder => ({
    getNetworks: builder.query({
      query: recordType => ({
        url: '/networks',
        method: 'GET',
        params: { type: recordType },
      }),
      providesTags: [NETWORKS],
    }),
    createNetwork: builder.mutation({
      query: ({ payload, recordType }) => ({
        url: '/networks',
        method: 'POST',
        params: { type: recordType },
        body: payload,
      }),
      invalidatesTags: [NETWORKS],
    }),
    updateNetwork: builder.mutation({
      query: ({ networkId, payload }) => ({
        url: `/networks/${networkId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [NETWORKS],
    }),
    deleteNetwork: builder.mutation({
      query: ({ networkId }) => ({
        url: `/networks/${networkId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [NETWORKS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateNetworkMutation,
  useDeleteNetworkMutation,
  useGetNetworksQuery,
  useUpdateNetworkMutation,
} = networks;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import mapCompanyIdToCompany from 'modules/companies/components/mapCompanyIdToCompany';
import EditableSection from 'modules/forms/components/EditableSection';
import CompanyPortfolioDetailsForm from './CompanyPortfolioDetailsForm';
import CompanyPortfolioDetailsView from './CompanyPortfolioDetailsView';

/**
 * Renders an EditableSection that displays and allows editing of a company's portfolio company
 * details fields.
 */
export default compose(
  setDisplayName('CompanyPortfolioDetailsEditableSection'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
  mapProps(({ company, companyId }) => ({
    companyId: companyId,
    children: CompanyPortfolioDetailsView.willRender({ company: company }) && (
      <CompanyPortfolioDetailsView companyId={companyId} />
    ),
    className: 'CompanyPortfolioDetailsEditableSection',
    formComponent: CompanyPortfolioDetailsForm,
    title: 'Portfolio Company Details',
  })),
)(EditableSection);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import DateInput from '@thrivetrm/ui/components/DateInput';
import {
  registerFilterInput,
  requestRecordList,
  selectFilterInputData,
  selectHasRegisteredFilterInput,
  setAndValidateSingleFilterValue,
} from '../../recordIndexSlice';

const DateFilter = ({ label, name }) => {
  const dispatch = useDispatch();

  // Convert '0' or '1' to be compatible w/ core component
  const inputValue = useSelector(
    state => state.recordIndex.savedView.filters?.[name],
  );

  const { isPairedInput, leadsPair, placeholder } = useSelector(state =>
    selectFilterInputData(state, name),
  );

  const handleChange = value => {
    let newValue = null;

    if (value) {
      const [year, month, day] = value.split('-');
      newValue = `${month}/${day}/${year}`;
    }

    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: newValue,
      }),
    );
    return dispatch(requestRecordList());
  };

  const hasRegisteredFilterInput = useSelector(state =>
    selectHasRegisteredFilterInput(state, name),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  const containerClass = classnames('u-marginTop-8', {
    'u-paddingRight-8': isPairedInput && leadsPair,
    'u-paddingLeft-8': isPairedInput && !leadsPair,
    'u-inlineBlock': isPairedInput,
  });

  const [month, day, year] = inputValue?.split('/') || [];
  return (
    <div
      className={containerClass}
      style={{
        width: isPairedInput ? '50%' : '100%',
      }}
    >
      <DateInput
        inputWidth='full'
        label={label}
        name={name}
        onChange={handleChange}
        placeholderText={placeholder}
        size='small'
        value={inputValue?.length ? `${year}-${month}-${day}` : undefined}
      />
    </div>
  );
};

DateFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

DateFilter.defaultProps = {
  label: null,
};

export default DateFilter;

import { NAME } from '../../constants';

/**
 * Determines if a contact record is currently being fetched from the server
 * @param {*} state
 * @param {Number} id The ID of the contact
 * @returns {Boolean} True if the contact is currently being fetched from the server;
 *   Otherwise, false.
 */
export default (state, id) =>
  state[NAME].getIn(['byId', id, '_meta', 'isFetching'], false);

import { Component } from 'react';
import PropTypes from 'prop-types';

export const Alignments = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

export const Widths = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
};

/**
 * The columns to render in for a table.
 */
export const columns = PropTypes.arrayOf(
  PropTypes.shape({
    /**
     * Left, center, or right alignment for both the <th> and <td> elements.
     */
    alignment: PropTypes.oneOf(Object.values(Alignments)),

    /**
     * A component for rendering the cell for this column. Props passed include:
     *   - `datum` - the record (as retrieved from data.map) for this row.
     *   - Any additional props passed into the Table that are not specified in this propTypes spec.
     */
    Cell: PropTypes.oneOfType([
      // A function component.
      PropTypes.func,
      // or a React component.
      PropTypes.instanceOf(Component),
    ]).isRequired,

    /**
     * An optional class name that can be applied to each cell's <td /> element.
     */
    className: PropTypes.string,

    /**
     * A component for rendering the header cell of this column. This or `title` is required.
     * Typically it is simpler to just use the `title` prop, but a component can be specified if
     * something more complex must be rendered.
     */
    Header: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.instanceOf(Component),
    ]),

    /**
     * True if the column is sortable.
     */
    isSortable: PropTypes.bool,

    /**
     * A unique key identifying the column. This MUST be unique in the collection of all
     * coumns provided. Also used as the `sortKey` when `sortKey` is not explicitly provided.
     */
    key: PropTypes.string.isRequired,

    /**
     * True if text should be truncated when it is wider than the <td> containing it
     */
    shouldTruncateText: PropTypes.bool,

    /**
     * True if text should wrap when it is wider than the <td> containing it
     */
    shouldWrapText: PropTypes.bool,

    /**
     * A key that identifies when this particular column is currently sorted on. This value
     * is compared to the Table's `sortBy` property to determine if the column is sorted.
     * Since the sort value defaults to the `key` prop, `sortKey` should be used only in rare
     * circumstances when the `sortKey` explicitly needs to diverge from the `key`.
     */
    sortKey: PropTypes.string,

    /**
     * A simple title to render for the column. Either this or a `Header` component is required.
     */
    title: PropTypes.node,

    /**
     * Applies a standardized width increment to the <th>. Technically, min-width is used because
     * tables will collapse `width` when the table is not wide enough to render its content. The
     * value is only applied to the <th> since doing so sets the width for the entire column,
     * including <td>'s.
     */
    width: PropTypes.oneOf(Object.values(Widths)),
  }),
);

/**
 * The data to display in the table. Typically this is an array or list of some sort,
 * but anything that implements a `map` function that allows mapping a collection will work
 * here (i.e. Immutable.List, or some custom implementation)
 */
export const data = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.shape({
    every: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }),
]);

/**
 * Represents a selection of rows/data within the table.
 * The underlying items in the array will vary depending on the `data` prop,
 * as this should be a subset of `data`.
 */
export const selection = PropTypes.array;

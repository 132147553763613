import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import Card from '@thrivetrm/ui/components/Card';

import NoteView from './NoteView';
import NoteForm from './NoteForm';
import connectNoteActions from './connectNoteActions';

import InlineEditActions from '../../../components/forms/InlineEditActions';

/**
 * Renders a single list item from an `NoteList`
 */
export class NoteListItem extends Component {
  state = {
    /**
     * True when the item is being edited inline, false when being viewed.
     * @type {Boolean}
     */
    isEditing: false,
  };

  /**
   * Called when the 'Yes' button of the delete confirmation is clicked.
   * Calls the action to delete the note and resets the action.
   */
  handleDeleteClick = () => {
    const { note, noteActions } = this.props;
    this.setState({ isEditing: false });
    noteActions.deleteNote({ id: note.get('id') });
  };

  /**
   * Called when the edit link is clicked, shows the edit form in place of
   * the note view.
   */
  handleEditClick = () => {
    this.setState({ isEditing: true });
  };

  /**
   * Called when the edit form is closed, removes the edit form and shows
   * the note view instead.
   */
  handleFormClose = () => {
    this.setState({ isEditing: false });
  };

  render() {
    const {
      candidacyId,
      note,
      pinnedNoteId,
      readOnly,
      renderRelatedSearches,
      searchId,
    } = this.props;
    const { isEditing } = this.state;
    const isDeleting = note.getIn(['_meta', 'isDeleting']);
    const canDelete = note.getIn(['permissions', 'delete']);
    const canEdit = note.getIn(['permissions', 'edit']);
    const noteId = note.get('id');
    const pinned = noteId === pinnedNoteId;

    return (
      <li className='notes--note-list-item' data-testid='note list item'>
        <Card isCentered={false}>
          {isEditing ? (
            <NoteForm
              alwaysShowFieldErrors={true}
              draftStoragePath={{
                search: searchId,
                candidacy: candidacyId,
                note: noteId,
              }}
              errorDisplay='tooltip'
              note={note}
              onCancel={this.handleFormClose}
              onSaved={this.handleFormClose}
              pinned={pinned}
              pinnedNoteId={pinnedNoteId}
              searchId={searchId}
              title='Edit Note'
            />
          ) : (
            <NoteView
              note={note}
              pinned={pinned}
              renderRelatedSearches={renderRelatedSearches}
            />
          )}
          <InlineEditActions
            canDelete={!isEditing && !readOnly && canDelete}
            canEdit={!isEditing && !readOnly && canEdit}
            deleteConfirmation='Are you sure you want to delete this note?'
            disabled={isDeleting}
            onDelete={this.handleDeleteClick}
            onEdit={this.handleEditClick}
          />
        </Card>
      </li>
    );
  }
}

NoteListItem.defaultProps = {
  readOnly: false,
  renderRelatedSearches: true,
};

NoteListItem.propTypes = {
  note: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isDeleting: PropTypes.bool,
    }),
    permissions: ImmutablePropTypes.mapContains({
      delete: PropTypes.bool,
      edit: PropTypes.bool,
    }),
  }).isRequired,

  noteActions: requiredIf(
    PropTypes.shape({
      deleteNote: PropTypes.func.isRequired,
    }),
    props => !props.readOnly,
  ),

  readOnly: PropTypes.bool,

  renderRelatedSearches: PropTypes.bool,
};

export default compose(
  setDisplayName('NoteListItem(enhanced)'),
  // The connected version of this component does not require
  // the noteActions propType.
  setPropTypes({
    note: NoteListItem.propTypes.note,
    readOnly: NoteListItem.propTypes.readOnly,
  }),
  connectNoteActions,
)(NoteListItem);

import PropTypes from 'prop-types';
import React from 'react';
import NoTargetCompanyCandidateCount from '../NoTargetCompanyCandidateCount';

const NoTargetCompanyCard = ({ onOrphanCountClick, orphanCount }) => (
  <div className='target-companies--target-company-card target-companies--target-company-card--none'>
    <div>
      <span className='target-company-count pull-right'>
        <NoTargetCompanyCandidateCount
          onClick={onOrphanCountClick}
          value={orphanCount}
        />
      </span>
    </div>
    <div className='no-company-card-logo'>
      <i className='fa fa-building-o fa-3x' />
    </div>
    <h5 className='no-company-card-title'>No Target Company</h5>
  </div>
);

NoTargetCompanyCard.propTypes = {
  onOrphanCountClick: PropTypes.func.isRequired,
  orphanCount: PropTypes.number.isRequired,
};

export default NoTargetCompanyCard;

import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import SelectedFilterSetList from 'modules/recordIndex/components/v5-filters/SelectedFilterSetList';
import { RecordTypesWithConnections } from 'services/apiV1/connections';
import useQuickView from './useQuickView';
import ConnectionAddForm from './connections-panels/ConnectionAddForm';
import ConnectionEditForm from './connections-panels/ConnectionEditForm';
import ContactProfilePanel from './contact-profile-panels/ContactProfilePanel';
import ContactConnections from './connections-panels/ContactConnections';
import RecruitmentRatingsList from './recruitment-rating-panels/RecruitmentRatingsList';
import RecruitmentRatingsAddForm from './recruitment-rating-panels/RecruitmentRatingsAddForm';
import RecruitmentRatingsEditForm from './recruitment-rating-panels/RecruitmentRatingsEditForm';
import RecruitmentRatingDetails from './recruitment-rating-panels/RecruitmentRatingDetails';
import OffLimitsList from './off-limits-panels/OffLimitsList';
import OffLimitsHistoricalList from './off-limits-panels/OffLimitsHistoricalList';
import OffLimitsApplyForm from './off-limits-panels/OffLimitsApplyForm';
import OffLimitsEditForm from './off-limits-panels/OffLimitsEditForm';
import OffLimitsArchiveForm from './off-limits-panels/OffLimitsArchiveForm';
import ContactProfileEditForm from './contact-profile-panels/ContactProfileEditForm';
import ViewFullAssessment from './view-assessment/ViewFullAssessment';
import ActivitiesList from './contact-activity/ActivitiesList';
import './QuickViewPanel.scss';

const QuickViewPanel = () => {
  const history = useHistory();
  const { currentPath } = useQuickView();

  // Allow redux to drive the router
  // https://charlypoly.com/publications/react-memory-router-pattern
  useEffect(() => {
    if (currentPath) {
      history.push(currentPath);
    }
  }, [currentPath, history]);

  const header = document.querySelector('#global-header');
  const globalNavHeight = header ? getComputedStyle(header)?.height : 0;
  const sidePanelHeight = `calc(100% - ${globalNavHeight})`;

  useEffect(() => {
    if (currentPath && header) {
      // adding a class to global nav-bar to update the z-index when the QVP is open
      header.classList.add('global-nav__withQVP');
    } else {
      header?.classList.remove('global-nav__withQVP');
    }
  }, [currentPath]);

  // Add a new route for each panel. Then that panel can be navigated to from
  // any part of the app by calling `navigateTo` with a matching path:
  //
  //   import useQuickView from 'modules/quick-view/useQuickView';
  //   ...
  //   const { navigateTo } = useQuickView();
  //   navigateTo('/contact/1/connections');
  //
  return currentPath ? (
    <SidePanel
      className='QuickViewPanel'
      isOpen={true}
      style={{ height: sidePanelHeight, top: 'initial' }}
    >
      <Switch>
        <Route
          component={ContactProfilePanel}
          exact={true}
          path='/contacts/:contactId'
        />
        <Route
          component={ContactProfileEditForm}
          exact={true}
          path='/contacts/:contactId/edit'
        />
        <Route
          component={ActivitiesList}
          exact={true}
          path='/contact/:contactId/activity'
        />
        <Route
          component={RecruitmentRatingsList}
          exact={true}
          path='/company/:companyId/recruitment_ratings'
        />
        <Route
          component={RecruitmentRatingsAddForm}
          exact={true}
          path='/company/:companyId/search/:searchId/recruitment_ratings/add'
        />
        <Route
          component={RecruitmentRatingDetails}
          exact={true}
          path='/company/:companyId/recruitment_ratings/:recruitmentRatingId'
        />
        <Route
          component={RecruitmentRatingsEditForm}
          exact={true}
          path='/company/:companyId/recruitment_ratings/:recruitmentRatingId/edit'
        />
        <Route
          component={ContactConnections}
          exact={true}
          path='/contact/:contactId/connections'
        />
        <Route exact={true} path='/contact/:contactId/connections/add-user'>
          <ConnectionAddForm
            connectionRecordType={RecordTypesWithConnections.USER}
          />
        </Route>
        <Route exact={true} path='/contact/:contactId/connections/add-contact'>
          <ConnectionAddForm
            connectionRecordType={RecordTypesWithConnections.CONTACT}
          />
        </Route>
        <Route
          component={ConnectionEditForm}
          exact={true}
          path='/contact/:contactId/connections/:connectionId/edit'
        />
        {/* OFF LIMITS */}
        <Route
          component={OffLimitsList}
          exact={true}
          path='/:recordType/:recordId/off-limits'
        />
        <Route
          component={OffLimitsHistoricalList}
          exact={true}
          path='/:recordType/:recordId/off-limits/historical'
        />
        <Route
          component={OffLimitsApplyForm}
          exact={true}
          path='/:recordType/:recordId/off-limits/apply-reason'
        />
        <Route
          component={OffLimitsEditForm}
          exact={true}
          path='/:recordType/:recordId/off-limits/:reasonId'
        />
        <Route
          component={OffLimitsArchiveForm}
          exact={true}
          path='/:recordType/:recordId/archive-off-limits/:reasonId'
        />
        <Route
          component={SelectedFilterSetList}
          exact={true}
          path='/filter_sets'
        />

        {/* view assessment */}
        <Route
          component={ViewFullAssessment}
          exact={true}
          path='/contact/:contactId/candidate-assessment/:candidateAssessmentId'
        />
      </Switch>
    </SidePanel>
  ) : null;
};

export default QuickViewPanel;

import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import EditableSection from 'modules/forms/components/EditableSection';
import mapContactIdToContact from '../mapContactIdToContact';
import ContactUsVisaRequiredForm from './ContactUsVisaRequiredForm';

/**
 * Renders an EditableSection that displays and allows editing of
 * a contact's "is visa required field".
 */
export default compose(
  setDisplayName('ContactUsVisaRequiredEditableSection'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  mapProps(({ contact, contactId }) => ({
    contactId: contactId,
    children: contact.get('us_visa_required') !== null && (
      <div className='ContactUsVisaRequiredView'>
        {contact.get('us_visa_required') === false ? 'No' : 'Yes'}
      </div>
    ),
    formComponent: ContactUsVisaRequiredForm,
    title: 'Visa required to work in the US?',
    className: 'ContactUsVisaRequiredEditableSection',
  })),
)(EditableSection);

import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import { SEARCH_TYPES, TYPE_PIPELINE } from 'modules/searches/constants';
import tagSchema from '../schemas/tag';
import smartTagSchema from '../schemas/smartTag';

/**
 * Renders the display name of a tag
 */
export default compose(
  setDisplayName('TagName'),
  setPropTypes({
    tagId: PropTypes.number.isRequired,
    searchType: PropTypes.oneOf(SEARCH_TYPES).isRequired,
  }),
  mapProps(({ searchType, tagId, ...rest }) => ({
    ...rest,
    entityType:
      searchType === TYPE_PIPELINE ? smartTagSchema.key : tagSchema.key,
    entityId: tagId,
    propertyName: 'name',
  })),
)(EntityProperty);

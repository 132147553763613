import getContactProperty from '../contacts/getContactProperty';
import isRatingByCurrentUser from './isRatingByCurrentUser';

/**
 * Determines if the current has rated a contact
 * @param {*} state
 * @param {Number} contactId The ID of the contact to check
 * @returns {Boolean} true if the curent user has rated the contact; otherwise, false.
 */
export default (state, contactId) => {
  const ratingIds = getContactProperty(state, contactId, 'ratings');

  if (ratingIds) {
    return ratingIds.findIndex(id => isRatingByCurrentUser(state, id)) !== -1;
  }

  return false;
};

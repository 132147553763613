import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BulkActionTable from 'modules/core/components/BulkActionTable';
import Pagination from '@thrivetrm/ui/components/Pagination';
import SegmentedControl from '@thrivetrm/ui/components/SegmentedControl';
import ScrollableContainer from '@thrivetrm/ui/components/ScrollableContainer';
import RecordIndexNoDataTable from './RecordIndexNoDataTable';
import FilterSummary from './v5-filters/FilterSummary';
import {
  requestRecordList,
  setCurrentPage,
  setResultsPerPage,
  setSelectedRecords,
  setSortParams,
} from '../recordIndexSlice';

// eslint-disable-next-line no-magic-numbers
const RESULTS_PER_PAGE_OPTIONS = [5, 25, 100];
const DEFAULT_SORT_FIELD = 'primary_identifier';
const DEFAULT_SORT_DIRECTION = 'asc';
const DEFAULT_RESULTS_PER_PAGE = 25;

const RecordIndexTable = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    filterSetErrors,
    isStale,
    recordList,
    recordListRequestIsInflight,
    recordType,
    savedView: currentSavedView,
    selectedColumns,
    selectedRecordIds,
    shouldRequestFreshData,
    totalPages,
    totalResults,
  } = useSelector(state => state.recordIndex);

  const handleSortDirectionChange = (newSortKey, newSortDirection) => {
    dispatch(
      setSortParams({
        sortDirection: newSortDirection,
        sortField: newSortKey,
      }),
    );
    dispatch(requestRecordList());
  };

  const handlePageChange = page => {
    dispatch(setCurrentPage(page));
    dispatch(requestRecordList());
  };

  const handleResultsPerPageChange = newResultsPerPage => {
    dispatch(setResultsPerPage(newResultsPerPage));
    dispatch(requestRecordList());
  };

  const handleRecordSelections = newSelections => {
    dispatch(setSelectedRecords(newSelections));
  };

  useEffect(() => {
    if (isStale) {
      dispatch(requestRecordList());
    }
  }, [dispatch, isStale]);

  const getTableContent = () => (
    <>
      <BulkActionTable
        headers={selectedColumns}
        isLoading={recordListRequestIsInflight || shouldRequestFreshData}
        onSelect={handleRecordSelections}
        onSortChange={handleSortDirectionChange}
        rows={recordList}
        selections={selectedRecordIds}
        sortDirection={currentSavedView.sortDirection || DEFAULT_SORT_DIRECTION}
        sortKey={currentSavedView.sortField || DEFAULT_SORT_FIELD}
      />
      <div className='u-flex u-flexJustify-spaceBetween u-flexAlign-c u-marginVertical-24'>
        <Pagination
          currentPage={currentPage}
          onPageChange={handlePageChange}
          resultsPerPage={currentSavedView.resultsPerPage}
          totalPages={totalPages}
          totalResults={totalResults}
        />
        <SegmentedControl
          onChange={handleResultsPerPageChange}
          segments={RESULTS_PER_PAGE_OPTIONS}
          selectedSegmentLabel={
            currentSavedView.resultsPerPage || DEFAULT_RESULTS_PER_PAGE
          }
          size='small'
        />
      </div>
    </>
  );

  const getNoDataContent = () => (
    <RecordIndexNoDataTable
      headers={selectedColumns}
      isLoading={recordListRequestIsInflight || shouldRequestFreshData}
    />
  );

  const appHeaderHeight = '48px';
  const appFooterHeight = '72px';
  const pageHeaderHeight = '98px';
  const containerHeight = `calc(100vh - ${appHeaderHeight} - ${appFooterHeight} - ${pageHeaderHeight})`;

  const hasV5Enabled = Boolean(document.querySelector('[data-filter-sets]'));

  // A business requirement is to prevent users from searching only by gender
  // on the client side.  When this is the case the data is hidden.
  const hasCriticalFilterError = filterSetErrors?.Gender?.length;

  if (hasV5Enabled) {
    return (
      <div className='RecordIndexTable'>
        <ScrollableContainer maxHeight={containerHeight}>
          {recordType === 'contacts' ? (
            <div className='u-marginBottom-12'>
              <FilterSummary />
            </div>
          ) : null}
          {!hasCriticalFilterError &&
          recordList?.length &&
          selectedColumns?.length
            ? getTableContent()
            : getNoDataContent()}
        </ScrollableContainer>
      </div>
    );
  } else {
    return (
      <div className='RecordIndexTable'>
        {recordList?.length && selectedColumns?.length
          ? getTableContent()
          : getNoDataContent()}
      </div>
    );
  }
};

export default RecordIndexTable;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import fetchStageListIfNeeded from './fetchStageListIfNeeded';
import mapStageTypeToStageList from './mapStageTypeToStageList';
import isRejectedStage from '../selectors/isRejectedStage';

/**
 * Provides a prop (`rejectedStageIds`, by default) containing a collection of all stage IDs
 * which represent stages that are flagged as "rejected".
 * @param {Object} options
 * @param {String} [options.propName="setPropTypes"] The name of the prop to provide as a list
 *   of rejected stage IDs.
 */
export default ({ propName = 'rejectedStageIds' } = {}) =>
  compose(
    setDisplayName('withRejectedStageIds'),
    setPropTypes({
      stageType: PropTypes.string,
    }),
    mapProps(withRejectedStageIdsSavedProps => ({
      stageType: withRejectedStageIdsSavedProps.stageType,
      withRejectedStageIdsSavedProps: withRejectedStageIdsSavedProps,
    })),
    fetchStageListIfNeeded,
    mapStageTypeToStageList,
    connect(
      (state, { stageList }) => ({
        stageIds:
          stageList &&
          stageList.get('ids') &&
          stageList.get('ids').filter(id => isRejectedStage(state, id)),
      }),
      {},
    ),
    mapProps(({ stageIds, withRejectedStageIdsSavedProps }) => ({
      ...withRejectedStageIdsSavedProps,
      [propName]: stageIds,
    })),
  );

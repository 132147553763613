import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import taskSchema from '../schema';

/**
 * Gets the ID of the user that a task has been assigned to
 * @param {*} state
 * @param {Number} taskId The ID of the task.
 * @return {Number} The ID of the user the task is assigned to.
 */
export default (state, taskId) =>
  getEntityProperty(state, taskSchema.key, taskId, 'assigned_to');

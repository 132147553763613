import PropTypes from 'prop-types';
import React from 'react';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';

/* eslint-disable jsx-a11y/label-has-associated-control */

/**
 * A checkbox for selecting all or none of a set of options.
 */
const SelectAllCheckbox = ({ componentId, onChange, value, ...props }) => (
  <div className='SelectAllCheckbox'>
    <input
      {...props}
      checked={value}
      className='inline-form-control'
      id={componentId}
      onChange={onChange}
      type='checkbox'
    />
    <label htmlFor={componentId}>Select All</label>
  </div>
);

SelectAllCheckbox.propTypes = {
  /**
   * A unique component ID for this particular instance.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * Called when the value is changed, with the event as the argument
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The current checked value
   */
  value: PropTypes.bool.isRequired,
};

export default withComponentId('SelectAllCheckbox')(SelectAllCheckbox);

import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import CompanyIndustryNames from 'modules/companies/components/industries/CompanyIndustryNames';
import mapTargetCompanyIdToCompanyId from './mapTargetCompanyIdToCompanyId';

export default compose(
  setDisplayName('TargetCompanyIndustryNames'),
  setPropTypes({
    targetCompanyId: PropTypes.number.isRequired,
  }),
  mapTargetCompanyIdToCompanyId,
)(CompanyIndustryNames);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import hasFeature from 'modules/auth/selectors/hasFeature';
import NotificationsPopover from './NotificationsPopover';

/**
 * The top (vertical) and right (horizontal) offset to add to the popover
 * panel relative to the indicator.
 * Note that if these offsets are adjusted, you may need to adjust the position
 * of the popover arrow in notifications.scss (.notifications-popover:before)
 */
const POPOVER_VERTICAL_OFFSET = 12;
const POPOVER_HORIZONTAL_OFFSET = 60;

/**
 * A component that displays an icon and, when there are unread messages,
 * displays a badge indicating the number unread. When the icon is clicked,
 * a popover is displayed showing the unread messages.
 */
class NotificationsIndicator extends Component {
  state = {
    isOpen: false,
  };

  UNSAFE_componentWillMount() {
    const {
      fetchNotifications,
      initialCount,
      initializeTotalCount,
      notifications,
    } = this.props;

    if (
      typeof initialCount !== 'number' &&
      !notifications.get('data') &&
      !notifications.getIn(['meta', 'isFetching'])
    ) {
      // If we weren't supplied with the number of unread notifications as a
      // property, we'll need to actually fetch the notifications, which will
      // give us the unread count.
      fetchNotifications({
        category: notifications.getIn(['meta', 'category']),
      });
    } else {
      initializeTotalCount({
        category: notifications.getIn(['meta', 'category']),
        count: initialCount,
      });
    }
  }

  setContainerRef = element => {
    this._containerElement = element;
  };

  /**
   * Handles clicking the indicator, which toggles the open state of the
   * popover.
   */
  handleIndicatorClick = event => {
    const { isOpen } = this.state;
    event.preventDefault();

    const node = this._containerElement;

    this.setState({
      isOpen: !isOpen,
      indicatorBounds: node && node.getBoundingClientRect(),
    });
  };

  /**
   * Called when the popover wants to be closed.
   */
  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  /**
   * Handles a request to getch notifications
   */
  handleFetchNotifications = () => {
    const { fetchNotificationsIfNeeded, notifications } = this.props;
    fetchNotificationsIfNeeded(notifications);
  };

  /**
   * Handles a request to refresh (completely reload) notifications
   */
  handleRefreshNotifications = () => {
    const { fetchNotifications, notifications } = this.props;
    fetchNotifications({ category: notifications.getIn(['meta', 'category']) });
  };

  /**
   * Handles a request to fetch the next page of notifications.
   */
  handleFetchNextPage = () => {
    const { fetchNextPageIfHasMore, notifications } = this.props;
    fetchNextPageIfHasMore(notifications);
  };

  /**
   * Handles a request to fetch any new notifications that may have
   * come in since the last fetch.
   */
  handleFetchLatest = () => {
    const { fetchLatest, notifications } = this.props;
    fetchLatest(notifications);
  };

  handleUpdateAllRead = () => {
    const { notifications, updateSeenAsRead } = this.props;
    updateSeenAsRead(notifications);
  };

  handleUpdateRead = (notification, toRead) => {
    const { updateRead } = this.props;
    updateRead(notification.getIn(['data', 'id']), toRead);
  };

  /**
   * Called when somewhere outside of the popover is clicked. Can be used to modify
   * whether the event actually causes the popover to close.
   * We need to ignore clicks on the indicator itself, otherwise the
   * popup will close and then just reopen immediately from our
   * click handler.
   * @param {SyntheticEvent} event The event generated by the mouse click.
   * @param {Boolean} willClose Whether the current click will cause the popover to close
   * @return {Boolean} Whether the current click should cause the popover close.
   */
  shouldCloseOnOutsideClick = (event, willClose) =>
    willClose &&
    this._containerElement &&
    !this._containerElement.contains(event.target);

  render() {
    const {
      doNotificationAction,
      hasMaxNotificationCount,
      notifications,
    } = this.props;
    const { indicatorBounds, isOpen } = this.state;
    const count = notifications.getIn(['meta', 'count']);
    const maxCount = thv.user.notifications_max_count;
    let countBadge = null;

    // The notification panel will only show the past 25 (ATTOW) most recent notifications, so we'll
    // floor the notification count displayed to that maximum, appending a `+` to it, if there's more.
    if (hasMaxNotificationCount && count > maxCount) {
      countBadge = (
        <span className='global-nav__notifications-badge'>
          {maxCount}
          <span className='global-nav__notifications-badge-appendage'>+</span>
        </span>
      );
    } else if (count > 0) {
      countBadge = (
        <span className='global-nav__notifications-badge'>{count}</span>
      );
    }

    let popover = null;

    if (isOpen) {
      // Position the popover based onthe location of the indicator icon
      const popoverStyle = {
        top: indicatorBounds.bottom + POPOVER_VERTICAL_OFFSET,
        right: POPOVER_HORIZONTAL_OFFSET,
      };

      popover = (
        <NotificationsPopover
          closeOnOutsideClick={this.shouldCloseOnOutsideClick}
          notifications={notifications}
          onClose={this.handleClose}
          onFetchLatest={this.handleFetchLatest}
          onFetchNextPage={this.handleFetchNextPage}
          onFetchNotifications={this.handleFetchNotifications}
          onNotificationAction={doNotificationAction}
          onRefreshNotifications={this.handleRefreshNotifications}
          onUpdateAllRead={this.handleUpdateAllRead}
          onUpdateRead={this.handleUpdateRead}
          style={popoverStyle}
        />
      );
    }

    return (
      <div ref={this.setContainerRef}>
        <a
          aria-expanded={Boolean(isOpen)}
          className='global-nav__action-button u-marginRight-8'
          href='#notifications'
          onClick={this.handleIndicatorClick}
          role='button'
        >
          <i className='fa fa-bell-o fa-md' />
          {countBadge}
        </a>
        {popover}
      </div>
    );
  }
}

NotificationsIndicator.propTypes = {
  doNotificationAction: PropTypes.func.isRequired,

  fetchLatest: PropTypes.func.isRequired,

  fetchNextPageIfHasMore: PropTypes.func.isRequired,

  fetchNotifications: PropTypes.func.isRequired,

  fetchNotificationsIfNeeded: PropTypes.func.isRequired,

  // Controls whether the displayed notification count should be capped to a hard limit (25
  // ATTOW). Any count over that limited will be displayed with a `+` appendage, e.g. `25+`.
  hasMaxNotificationCount: PropTypes.bool,

  /**
   * Provides the initial count of available notifications. When provided,
   * prevents us from having to make an initial ajax request to get the count.
   */
  initialCount: PropTypes.number,

  initializeTotalCount: PropTypes.func.isRequired,

  notifications: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.orderedMap,
    meta: ImmutablePropTypes.mapContains({
      count: PropTypes.number,
      isFetching: PropTypes.bool,
    }),
  }),

  updateRead: PropTypes.func.isRequired,

  updateSeenAsRead: PropTypes.func.isRequired,
};

export default connect(state => {
  return {
    hasMaxNotificationCount: hasFeature(
      state,
      'development.constrain_notifications_by_date',
    ),
  };
})(NotificationsIndicator);

import routes from 'modules/routing/routes';
import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import investmentSchema from 'modules/company-investments/schema';
import {
  FUNDING_ROUND_LIST_FETCH_START,
  FUNDING_ROUND_LIST_FETCH_SUCCESS,
  FUNDING_ROUND_LIST_FETCH_FAILURE,
} from './actionTypes';

/**
 * Creates an action for fetching a list of funding rounds for a company
 * @param {Object} payload
 * @param {String} payload.companyId The ID of the company to fetch the funding rounds for.
 */
export default compose(
  fetchAction,
  normalizeResponse({ funding_rounds: [investmentSchema] }),
  createAsyncActions({
    startActionType: FUNDING_ROUND_LIST_FETCH_START,
    successActionType: FUNDING_ROUND_LIST_FETCH_SUCCESS,
    failureActionType: FUNDING_ROUND_LIST_FETCH_FAILURE,
  }),
  withOptions(({ companyId }) => ({
    url: routes.api_v1_company_funding_rounds({ companyId: companyId }),
  })),
);

/* eslint-disable func-names */
const url = require('url');
const commissionsChart = require('../../../lib/my_commissions_chart').default;
const { buildCharts } = require('../../../lib/c3_helpers');
const initSelect2 = require('../../../lib/select2');

exports.replace = function (sectionId, sectionContent) {
  $(sectionId).html(sectionContent);
  $('[data-section-toggle-container]')
    .addClass('dont-toggle')
    .data('plugin_sectionToggle', null);
  $('[data-section-toggle-container]').sectionToggle();
  $('.tooltip').hide();
};

const initializeSelect = () => {
  initSelect2('.commission_details', ['.js-select-user-commissions']);
  $('.js-select-user-commissions').on('select2:select', event => {
    const userId = event.target.value;
    const today = new Date();
    window.location = url.format({
      pathname: '/searches/my_commissions',
      query: { view: 'invoice', year: today.getFullYear(), user_id: userId },
    });
  });
};

exports.initialize = function () {
  $("[data-toggle='popover']").popover({ html: true });
  $('.commission-index-buttons > .btn').tooltip({ container: 'body' });
  buildCharts('.my-commissions-bar', commissionsChart, 'commissions');
  $('.nav.nav-tabs.nav-main li').removeClass('active');
  initializeSelect();
};

import selectn from 'selectn';
import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withFormState from 'modules/forms/components/withFormState';
import withTransaction from 'modules/transactions/components/withTransaction';
import { connect } from 'react-redux';
import { updateContact as updateContactActionCreator } from '../../actions/contacts';
import mapContactIdToContact from '../mapContactIdToContact';
import RichTextField from '../../../../components/forms/richtext/RichTextField';

/**
 * Provides props for editing a contact's career highlights in a form.
 *
 * Takes the following incoming props:
 * * `onSaved` {Function} Will be called  when the form has been successfully saved. The first
 *   argument will be the ID of the residency that was created.
 *
 * Creates the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying ResidencyField FieldState
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 *
 */
export default compose(
  setDisplayName('withContactCareerHighlightsForm'),
  setPropTypes({
    /**
     * The contact ID whos career highlights we're editing.
     */
    contactId: PropTypes.number.isRequired,
  }),

  // Create a temporary prop (`withCareerHighlightsFormProps`) that holds any incoming props
  // so we don't overwrite/delete any incoming props that may need to be passed through.
  withProps(props => ({
    withCareerHighlightsFormProps: props,
  })),

  mapContactIdToContact,

  /**
   * Create a FormState. Provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
   */
  withFormState(({ contact, draft }) =>
    RichTextField.createFieldState(
      'career_highlights',
      draft?.content || (contact && contact.get('career_highlights')) || '',
    ),
  ),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onResetFormState,
      onSaved,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();
        // Call onSaved with the ID of the saved contact.
        onSaved(selectn('payload.result.contact', transaction));
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  /**
   * Include a dispatch-connected version of `updateContact`
   */
  connect(null, {
    updateContact: updateContactActionCreator,
  }),
  /**
   * Add a callback to handle submitting the form.
   */
  withHandlers({
    onSubmit: ({
      contactId,
      formState,
      onFormStateChange,
      startTransaction,
      updateContact,
    }) => e => {
      // Prevent default browser behavior, which could cause the browser to attempt
      // to submit a form on it's own.
      preventDefaultHandler(e);

      const transactionId = startTransaction();

      updateContact({
        id: contactId,
        contact: {
          career_highlights: formState.getFieldValue(),
        },
        transactionId: transactionId,
      });

      onFormStateChange(formState.startSubmit(transactionId));
    },
  }),

  // Prevent any props we used/created from bleeding down to the child.
  mapProps(
    ({
      formState,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      onSubmit,
      withCareerHighlightsFormProps,
    }) => ({
      ...withCareerHighlightsFormProps,
      formState: formState,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onResetFormState: onResetFormState,
      onSubmit: onSubmit,
    }),
  ),
);

import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import targetMarketSchema from '../schemas/targetMarkets';

/**
 * A tag select component for choosing from the list of target markets.
 */

const TargetMarketsTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField {...props} schema={targetMarketSchema} />
);

TargetMarketsTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default TargetMarketsTagSelectField;

import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import getContactProperty from 'modules/contacts/selectors/contacts/getContactProperty';
import routes from 'modules/routing/routes';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetContactNetworksQuery } from 'services/apiV1/contactNetworks';
import RemoveContactFromNetworkButton from './RemoveContactFromNetworkButton';

export const ContactNetworksTable = ({ contactId }) => {
  const {
    data: contactsNetworks,
    error: loadContactNetworksError,
    isFetching: isLoadingContactNetworks,
  } = useGetContactNetworksQuery(contactId);

  const defaultContactsSavedViewId = useSelector(state =>
    state.user.get('contacts_default_saved_view_id'),
  );

  const contactName = useSelector(state =>
    getContactProperty(state, contactId, 'full_name'),
  );

  return (
    <LoadingContainer isLoading={isLoadingContactNetworks}>
      {contactsNetworks?.length ? (
        <table className='table table-striped table--rowHighlight'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Created By</th>
              <th className='u-textAlign-c'>Count</th>
              <th
                aria-label='actions'
                className='u-textAlign-c u-paddingRight-n'
              >
                Remove?
              </th>
            </tr>
          </thead>
          <tbody>
            {contactsNetworks.map(contactNetwork => (
              <tr key={contactNetwork.id}>
                <td className='u-verticalAlign-m'>
                  <a
                    href={`${routes.saved_view_contacts({
                      id: defaultContactsSavedViewId,
                    })}?network_id=${contactNetwork.id}`}
                  >
                    {contactNetwork.name}
                  </a>
                </td>
                <td>{contactNetwork?.user.name}</td>
                <td className='u-textAlign-c'>
                  {contactNetwork.network_members_count}
                </td>
                <td className='u-textAlign-c u-paddingRight-n'>
                  <RemoveContactFromNetworkButton
                    contactId={contactId}
                    networkId={contactNetwork.id}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
      {!contactsNetworks?.length && !isLoadingContactNetworks ? (
        <div className='u-marginVertical-8 u-textAlign-c u-dashedBorder u-borderRadius'>
          <img alt='no contact networks' src='/card_screen.svg' />
          <div className='u-fontWeight-bold u-fontSize-large'>
            {contactName} doesn&apos;t have any <br />
            Networks yet.
          </div>
          <p className='u-marginTop-12'>
            To add {contactName} to a Network by clicking on the{' '}
            <b>+ Add to Network</b> button above
          </p>
        </div>
      ) : null}
      {!isLoadingContactNetworks && loadContactNetworksError ? (
        <div className='u-paddingVertical-12 u-textAlign-c u-dashedBorder u-borderRadius'>
          <img alt='no ratings' src='/envelope.svg' />
          <div className='u-fontWeight-bold u-fontSize-large u-marginTop-8'>
            We are experiencing an internal error
          </div>
        </div>
      ) : null}
    </LoadingContainer>
  );
};

ContactNetworksTable.propTypes = {
  contactId: PropTypes.number.isRequired,
};

export default ContactNetworksTable;

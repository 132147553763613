import React from 'react';
import PropTypes from 'prop-types';
import EntityProperty from 'modules/entities/components/EntityProperty';
import companyPrioritySchema from '../schemas/companyPriorities';

/**
 * Renders the display name for a given company priority ID.
 */
const CompanyPriorityName = ({ companyPriorityId }) => (
  <EntityProperty
    entityId={companyPriorityId}
    entityType={companyPrioritySchema.key}
    propertyName='name'
  />
);

CompanyPriorityName.propTypes = {
  companyPriorityId: PropTypes.number.isRequired,
};

export default CompanyPriorityName;

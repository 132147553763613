import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import CancelButton from 'modules/forms/components/CancelButton';
import EditIconButton from 'modules/forms/components/EditIconButton';
import getTagProperty from '../selectors/getTagProperty';

/**
 * Renders the "actions" cell for the TagTable/TagTableRow.
 *
 * In it's "normal" state this renders:
 * * A loading indicator when "loading" is true (typically used when toggling the checked
 *   state of the tag for a particular candidacy)
 * * An edit button, to enter into an editing state for the current row (if the
 *   user has permission to edit this tag)
 * * A delete button, for deleting the current tag (if the user has permission to delete it)
 *
 * When `editing === true` renders a save/cancel button.
 */
const TagTableActionsCell = ({
  className,
  deleteable,
  deleting,
  editable,
  editing,
  loading,
  onCancelEdit,
  onDelete,
  onEdit,
  onSubmit,
  saving,
}) => (
  <td className={classnames('TagTableActionsCell', className)}>
    {loading && <i className='fa fa-spinner fa-spin' />}
    {editing && (
      <ConfirmationPopoverButton
        className='btn btn-link'
        disabled={saving}
        iconClass={classnames('fa', {
          'fa-spin': saving,
          'fa-spinner': saving,
        })}
        key='save'
        label={!saving && 'Save'}
        onConfirm={onSubmit}
        popoverPlacement='left'
        title='Update this tag?'
      >
        This will update the tag for all candidates.
      </ConfirmationPopoverButton>
    )}
    {editing && (
      <CancelButton
        className='TagTableRow__CancelButton btn-link'
        disabled={saving}
        onClick={onCancelEdit}
      >
        <i className='fa fa-undo' />
      </CancelButton>
    )}
    {!editing && !loading && editable && (
      <EditIconButton
        className='TagTableRow__editButton btn-link btn-sm'
        disabled={saving || deleting}
        onClick={onEdit}
      />
    )}
    {!editing && !loading && deleteable && (
      <ConfirmationPopoverButton
        className='btn btn-link'
        disabled={deleting || saving}
        iconClass={classnames('fa', {
          'fa-spin': deleting,
          'fa-spinner': deleting,
          'fa-trash': !deleting,
        })}
        key='delete'
        onConfirm={onDelete}
        popoverPlacement='left'
        title='Are you sure?'
      >
        This tag will be deleted from all candidates.
      </ConfirmationPopoverButton>
    )}
  </td>
);

TagTableActionsCell.propTypes = {
  /**
   * Additional class names to apply to the <td> element.
   */
  className: PropTypes.string,

  /**
   * True if the delete button should be available.
   */
  deleteable: PropTypes.bool,

  /**
   * True if deletion is in progress (i.e. the network request is being made)
   */
  deleting: PropTypes.bool,

  /**
   * True if the edit button should be avialable.
   */
  editable: PropTypes.bool,

  /**
   * True if the row is currently in edit mode.
   */
  editing: PropTypes.bool,

  /**
   * True if a the tag is being loaded in some way and the loading indicator should be shown.
   */
  loading: PropTypes.bool,

  /**
   * Called when in edit mode and the cancel button is clicked (so changes should be reverted and
   * not saved)
   */
  onCancelEdit: PropTypes.func.isRequired,

  /**
   * Called when the tag should be deleted.
   */
  onDelete: PropTypes.func.isRequired,

  /**
   * Called when the edit button is clicked and the row should enter edit mode.
   */
  onEdit: PropTypes.func.isRequired,

  /**
   * Called when the submit button is clicked while editing, indicating the tag should be saved
   * with it's new values.
   */
  onSubmit: PropTypes.func,

  /**
   * True if saving/updating is currently in progress.
   */
  saving: PropTypes.bool,
};

TagTableActionsCell.defaultProps = {
  deleteable: false,
  deleting: false,
  editable: false,
  editing: false,
  loading: false,
};

export default compose(
  setDisplayName('TagTableActionsCell(enhanced)'),
  setPropTypes({
    /**
     * The ID of the tag this cell refers to. Used to determine editing/deleting permissions.
     */
    tagId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { tagId }) => ({
      deleteable: getTagProperty(state, tagId, ['permissions', 'delete']),
      deleting: getTagProperty(state, tagId, ['_meta', 'isDeleting']),
      editable: getTagProperty(state, tagId, ['permissions', 'edit']),
    }),
    {},
  ),
)(TagTableActionsCell);

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Drawer from '@thrivetrm/ui/components/Drawer';
import Rating from '@thrivetrm/ui/components/Rating';
import HtmlText from '@thrivetrm/ui/components/HtmlText';

const Criteria = ({ name, notes, rating }) => {
  const ratingStars = useSelector(state => state.tenant)?.get('rating_stars');

  return (
    <Drawer
      className='u-marginBottom-4'
      drawerButtonClass='u-backgroundColor-transparent u-textColor-gray40'
      drawerHeaderClass='u-padding-n u-backgroundColor-transparent'
      header={
        <div className='u-flex u-flexAlign-c'>
          <Rating maxRating={ratingStars} value={rating} />
          <span className='u-marginLeft-4 u-fontSize-small'>{name}</span>
        </div>
      }
      isOpenByDefault={false}
    >
      {notes ? (
        <>
          <h4 className='u-margin-n u-fontSize-small'>Notes:</h4>
          <HtmlText
            className='u-marginTop-4 u-fontSize-small u-wordBreak'
            htmlString={notes}
          />
        </>
      ) : null}
    </Drawer>
  );
};

Criteria.propTypes = {
  name: PropTypes.string,
  notes: PropTypes.node,
  rating: PropTypes.number,
};

export default Criteria;

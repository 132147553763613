import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, setDisplayName, setPropTypes } from 'recompose';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import { connect } from 'react-redux';
import IntroductionField from './IntroductionField';
import connectIntroductionActions from './connectIntroductionActions';
import mapIntroductionIdToIntroduction from './mapIntroductionIdToIntroduction';
import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';
import withDraftStorage from '../../../components/forms/withDraftStorage';

export const createRootFieldState = ({
  contactId,
  currentUserId,
  draft,
  introduction,
  searchId,
}) => {
  let introductionValue;
  if (introduction) {
    // When editing an existing introduction
    introductionValue = introduction.withMutations(immutableIntroduction => {
      if (draft?.content) {
        immutableIntroduction.set('notes', draft?.content);
      }
    });
  } else {
    // When creating a new introduction
    introductionValue = fromJS({
      contact: contactId,
      notes: draft?.content,
      searches: [searchId],
    });
  }

  return IntroductionField.createFieldState(
    'introduction',
    introductionValue,
    currentUserId,
  );
};

export const handleSubmit = (formState, props) => {
  const {
    introduction,
    introductionActions: { createIntroduction, updateIntroduction },
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (introduction || fromJS({})).merge(fieldValue);

  const action = values.get('id') ? updateIntroduction : createIntroduction;

  action({
    introduction: values.toJS(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export const connectForm = compose(
  setPropTypes({
    /**
     * If editing an introduction
     */
    introduction: ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),

    introductionId: PropTypes.number,
  }),
  branch(
    props => props.introductionId && !props.introduction,
    mapIntroductionIdToIntroduction,
  ),
  connectTransactions,
  connectIntroductionActions,
);

export default compose(
  connect(state => ({
    currentUserId: getCurrentUserId(state),
  })),
  setDisplayName('IntroductionForm'),
  connectForm,
  withDraftStorage(),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'introduction-form',
    omitProps: [
      'contactId',
      'candidacyId',
      'currentUserId',
      'draft?.content',
      'formState',
      'onSaved',
      'introductionId',
      'introductionActions',
      'searchId',
      'transactionActions',
    ],
  }),
)(IntroductionField);

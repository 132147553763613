import getFundList from './getFundList';

/**
 * Determines if the list of funds for the current tenant should be fetched from the server.
 */
export default state => {
  const fundList = getFundList(state);

  if (!fundList) {
    return false;
  }

  // Don't fetch if we're already fetching, or if we fetched and got an error.
  if (fundList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (fundList.getIn(['_meta', 'error'])) {
    return false;
  }

  // Fetch if we don't have IDs (meaning we haven't fetched yet) or we've been invalidated.
  return !fundList.has('ids') || fundList.getIn(['_meta', 'isInvalidated']);
};

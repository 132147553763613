import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  mapProps,
  pure,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import Link from 'modules/routing/components/Link';
import getSearchPageViewLink from '../selectors/getSearchPageViewLink';

/**
 * Renders a link to a search page.
 */
export default compose(
  setDisplayName('SearchPageViewLink'),
  setPropTypes({
    page: PropTypes.string.isRequired,
    pageOnly: PropTypes.bool.isRequired,
  }),
  defaultProps({
    pageOnly: false,
  }),
  connect(
    (state, { page, pageOnly }) => ({
      href: getSearchPageViewLink(state, page, pageOnly),
    }),
    {},
  ),

  // Remove the `page` prop so it doesn't get added to the <a/> element.
  mapProps(({ page, pageOnly, ...props }) => props), // eslint-disable-line no-unused-vars
  pure,
)(Link);

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import { INTERVIEW_TYPE_HIRING_MANAGER } from '../constants';

/**
 * Renders an abbreviation for an interview's type.
 * This renders the first letter of the interview type, except in the case
 * of the tenant type being TENANT_TYPE_ENTERPRISE, where we instead want to display
 * "HM" (for "Hiring Manager").
 */
const InterviewTypeAbbreviation = ({ interviewType }) => (
  <span className='interviews--interview-type-abbreviation'>
    {interviewType === INTERVIEW_TYPE_HIRING_MANAGER
      ? 'HM'
      : interviewType[0] || '?'}
  </span>
);

InterviewTypeAbbreviation.propTypes = {
  /**
   * The interview type name
   */
  interviewType: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('InterviewTypeAbbreviation(enhanced)'),
  setPropTypes({
    interviewType: InterviewTypeAbbreviation.propTypes.interviewType,
  }),
  onlyUpdateForPropTypes,
)(InterviewTypeAbbreviation);

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Menu from '@thrivetrm/ui/components/Menu';
import PluralText from '@thrivetrm/ui/components/PluralText';
import BottomPanel from '@thrivetrm/ui/components/BottomPanel';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { STAGE_TYPE_JOB_SEARCH } from 'modules/search-stages/constants';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import getSearchType from 'modules/searches/selectors/getSearchType';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import AddToSearchModal from 'modules/recordIndex/components/AddToSearchModal';
import { toastSuccess } from 'modules/toast-notifications/toastNotificationsSlice';
import getCandidacyContactId from 'modules/candidacies/selectors/getCandidacyContactId';
import CandidacySearchTagModal from 'modules/candidacies/components/tags/CandidacySearchTagModal';
import BulkRemoveFromSearchButton from './BulkRemoveFromSearchButton';
import {
  setTargetStageId,
  selectSelectedStageIds,
  reset,
} from './candidacyBulkActionsSlice';
import { selectDataBySearchId } from '../legacySearchDataSelectors';
import './BulkUpdateCandidacyStageBottomPanel.scss';

const BulkUpdateCandidacyStageBottomPanel = ({ searchId }) => {
  const dispatch = useDispatch();
  const [isSearchModalOpen, openSearchModal, closeSearchModal] = useToggle(
    false,
  );
  const [isOpenTagsModal, openTagsModal, closeTagsModal] = useToggle(false);

  const selectedCandidacyIds = useSelector(
    state => state.candidacyBulkActions.selectedCandidacyIds,
  );

  const selectedCandidacyContactIds = useSelector(state =>
    selectedCandidacyIds?.map(candidacyId =>
      getCandidacyContactId(state, candidacyId),
    ),
  );
  const selectedCandidaciesCount = selectedCandidacyIds?.length;

  const { stages } = useSelector(state =>
    selectDataBySearchId(state, searchId),
  );

  const hasJobStageGates = useFeatureCheck('feature.job_search_stage_gates');
  const selectedStageIds = useSelector(selectSelectedStageIds);
  const searchType = useSelector(state => getSearchType(state, searchId));

  const handleAddToSearchSuccess = selectedSearch => {
    closeSearchModal();
    dispatch(
      toastSuccess(
        `Successfully added ${selectedCandidaciesCount} ${
          selectedCandidaciesCount > 1 ? 'candidates' : 'candidate'
        } to ${selectedSearch.label}`,
      ),
    );
    dispatch(reset());
  };

  const handleCandidateTagsSuccess = () => {
    dispatch(reset());
  };

  const isValidBulkTargetStage = stage => {
    if (stage.type !== STAGE_TYPE_JOB_SEARCH) {
      return true;
    } else if (stage.offer || stage.placed) {
      return false;
    } else if (hasJobStageGates) {
      return selectedStageIds.every(stageId =>
        stage.allowed_from_candidacy_stage_ids.includes(stageId),
      );
    } else {
      return true;
    }
  };

  return (
    <BottomPanel isOpen={selectedCandidaciesCount > 0}>
      <div className='BulkUpdateCandidacyStageBottomPanel__section'>
        <PluralText
          quantity={selectedCandidaciesCount}
          shouldIncludeQuantity={true}
          text={searchType === TYPE_PIPELINE ? 'people' : 'candidates'}
        />{' '}
        selected
      </div>
      <div>
        {stages ? (
          <Menu
            button={
              hasJobStageGates && searchType !== TYPE_PIPELINE ? (
                <Tooltip
                  content={
                    <div className='u-noWrap'>
                      Disabled stages do not meet the
                      <br />
                      progression requirements
                    </div>
                  }
                  position='left'
                >
                  <ButtonSecondary label='Move Stage' />
                </Tooltip>
              ) : (
                <ButtonSecondary label='Move Stage' />
              )
            }
            isPinnedBottom={true}
          >
            {stages
              .filter(stage => stage.user_assignable)
              .map(stage => (
                <Menu.Item
                  isDisabled={!isValidBulkTargetStage(stage)}
                  key={stage.id}
                  onClick={() => {
                    dispatch(setTargetStageId(stage.id));
                  }}
                >
                  {stage.name}
                </Menu.Item>
              ))}
          </Menu>
        ) : null}

        {/* Add to search button and modal */}
        <ButtonSecondary
          className='u-marginLeft-16'
          label='Add to Search'
          onClick={openSearchModal}
        />
        <AddToSearchModal
          contactIds={selectedCandidacyContactIds ?? []}
          isOpen={isSearchModalOpen}
          onClose={closeSearchModal}
          onSuccess={handleAddToSearchSuccess}
        />

        {searchType === TYPE_PIPELINE ? null : (
          <ButtonSecondary
            className='u-marginLeft-16'
            label='Candidate Tags'
            onClick={openTagsModal}
          />
        )}
        {isOpenTagsModal ? (
          <CandidacySearchTagModal
            candidateIds={selectedCandidacyIds}
            onHide={closeTagsModal}
            onSuccess={handleCandidateTagsSuccess}
            searchId={searchId}
            show={true}
          />
        ) : null}
      </div>
      <div className='BulkUpdateCandidacyStageBottomPanel__section u-flex u-flexJustify-r'>
        <BulkRemoveFromSearchButton searchId={searchId} />
      </div>
    </BottomPanel>
  );
};

BulkUpdateCandidacyStageBottomPanel.propTypes = {
  searchId: PropTypes.number,
};

export default BulkUpdateCandidacyStageBottomPanel;

/* eslint-disable camelcase */
// ^ accommodate API exchange format
import selectn from 'selectn';
import createEntity from 'modules/entities/actions/createEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import objectToFormData from 'object-to-formdata';
import { updateContact } from 'modules/contacts/actions/contacts/index';
import { CONSENT_STATUS_OPTIONS } from 'modules/contacts/constants';
import { outreachSchema } from '../../schema';
import getOutreachListUrl from './getOutreachListUrl';
import { PARENT_CONTACT } from '../../constants';
import invalidateOutreachCandidacyStatuses from './invalidateOutreachCandidacyStatuses';
import maybeDispatchNotificationsCreated from '../../../../actions/notifications/maybeDispatchNotificationsCreated';

/**
 * A file provided by the `react-dropzone` component.
 * @typedef {File} DropzoneFile @see {@link https://developer.mozilla.org/en-US/docs/Web/API/File}
 * @property {String} preview - A data URL for a preview of the dropped image, if available.
 * This is added by the Dropzone component (unless `disablePreview` has been set to true on
 * the `Dropzone`'s props)
 */

/**
 * An object with file properties and optionally an unsaved DropzoneFile
 * @typedef {Object} OutreachDocument
 * @property {File} [DropzoneFile] {@link https://developer.mozilla.org/en-US/docs/Web/API/File}
 * @property {Number} fileSize Size of the document's file in bits
 * @property {String} fileName Name of the document's file
 * @property {String} url Url to the document's file on S3 (or locally in development)
 */

/**
 * An action for creating or updating an outreach
 * This renames `searches` (which is an array of IDs after being normalized) to `search_ids`
 * and `contact_method` to `contact_method_id` (which the server expects)
 * @param {Object} payload
 * @param {Object} payload.outreach The outreach record to create
 * @param {Number} payload.outreach.id The outreach ID
 * @param {Number[]} payload.outreach.searches The array of search IDs associated with the outreach
 * @param {OutreachDocument{}.<string, OutreachDocument>} [payload.outreach.documents] An object
 * of documents objects or uploaded files
 * @param {Object} [payload.email] Optional email parameters used when creating an outreach
 *   and sending it as an email
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({
  email,
  lbiNoticeSent,
  outreach: { contact_method, documents, searches, ...outreachProps },
  transactionId,
}) => dispatch => {
  const formattedDocuments = Object.entries(documents)
    .filter(([_key, document]) => document.file)
    .map(([_key, document]) => ({
      file: document.file,
    }))
    .reduce((o, document, index) => ({ ...o, [index]: document }), {});

  const outreachPayload = {
    contact_method_id: contact_method,
    documents_attributes: formattedDocuments,
    search_ids: searches,
    ...outreachProps,
  };

  // Appends all remaining outreach params to the FormData.
  // If the param is an object then convert it to JSON before appending.
  // Appends documents to the FormData as uploaded files (if file present) or JSON string.
  // Note: The objectToFormData method will convert a null value to the string 'null'.
  const body = email
    ? objectToFormData({ outreach: outreachPayload, email: email })
    : objectToFormData({ outreach: outreachPayload });

  dispatch(
    createEntity({
      body: body,
      contentType: false,
      entityType: outreachSchema.key,
      responseSchema: { outreach: outreachSchema },
      url: getOutreachListUrl({
        parentType: PARENT_CONTACT,
        parentId: outreachProps.contact_id,
      }),
      parentType: PARENT_CONTACT,
      parentId: outreachProps.contact_id,
      transactionId: transactionId,
    }),
  ).then(action => {
    if (action.type === entityActionTypes.CREATE_SUCCESS) {
      // Ick. This is gross. Temporary measure until we normalize notifications.
      // This dispatches a second action but alters the payload in a way that
      // maybeDispatchNotificationsCreated expects.
      const outreachId = selectn('payload.result.outreach', action);
      const outreach = selectn(
        `payload.entities.${outreachSchema.key}.${outreachId}`,
        action,
      );
      if (outreach) {
        dispatch(invalidateOutreachCandidacyStatuses(outreach));
        dispatch(
          maybeDispatchNotificationsCreated(
            {
              type: action.type,
              payload: { data: { outreach: outreach } },
            },
            'outreach',
          ),
        );
      }

      if (lbiNoticeSent && outreach && outreach.contact_id) {
        dispatch(
          updateContact({
            id: outreach.contact_id,
            contact: {
              consent: {
                status: CONSENT_STATUS_OPTIONS.lbi.value,
                type: 'GDPR',
              },
            },
            transactionId: transactionId,
          }),
        );
      }
    }

    return action;
  });
};

import CompanyPreferencesField from './CompanyPreferencesField';
import createContactForm from '../createContactForm';

const createRootFieldState = props =>
  CompanyPreferencesField.createFieldState('contact', props.contact);

const CompanyPreferencesForm = createContactForm(
  CompanyPreferencesField,
  createRootFieldState,
  'CompanyPreferencesForm',
);

export default CompanyPreferencesForm;

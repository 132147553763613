import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColorInput from 'modules/color/components/ColorInput';
import TextInput from '@thrivetrm/ui/components/TextInput';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import { useCreateTagMutation } from 'services/apiV1/candidateTags';
import './CandidacyAddTagForm.scss';

const CandidacyAddTagForm = ({ onCreate }) => {
  const [tagColor, setTagColor] = useState('#DBDBDB');
  // handled tagName manually instead of Form as we need to clear input value after submit
  const [tagName, setTagName] = useState('');

  const [
    createTag,
    { error: addTagError, isLoading: isCreatingTag },
  ] = useCreateTagMutation();

  const handleOnSubmit = () => {
    createTag({
      payload: { name: tagName, color: tagColor },
    })
      .unwrap()
      .then(tag => {
        onCreate(tag.tag);
        setTagColor('#DBDBDB');
        setTagName('');
      });
  };

  const getErrorMessages = errors => `Name ${errors.data.error?.name[0]}`;

  return (
    <div className='u-flex'>
      <div className='u-flex u-flexItem-grow CandidacyAddTagForm__textInputView'>
        <div className='u-noWrap'>Create New Tag:</div>
        <ColorInput
          className='u-marginLeft-8'
          onChange={data => setTagColor(data.hex)}
          value={tagColor}
        />
        <TextInput
          className='u-flexItem-grow u-marginHorizontal-8'
          errorMessage={addTagError ? getErrorMessages(addTagError) : ''}
          inputWidth='full'
          onChange={setTagName}
          placeholder='Enter Tag Label'
          value={tagName}
        />
      </div>
      <ButtonPrimary
        icon='add'
        isDisabled={!tagName.trim().length || isCreatingTag}
        isLoading={isCreatingTag}
        label='Add'
        onClick={handleOnSubmit}
      />
    </div>
  );
};

CandidacyAddTagForm.propTypes = {
  onCreate: PropTypes.func,
};

export default CandidacyAddTagForm;

import PropTypes from 'prop-types';
import { compose, defaultProps, setDisplayName, setPropTypes } from 'recompose';
import withClassName from 'modules/core/componentsLegacy/withClassName';
import { TYPE_JOB_SEARCH } from 'modules/searches/constants';
import ContactCandidacyListTableView from './ContactCandidacyListTableView';
import columns from './CandidaciesTableView.columns';

/**
 * A listing of Job Search candidacies in a table view for a specific contact.
 */
export default compose(
  setDisplayName('ContactJobSearchCandidacyListTableView'),
  setPropTypes({
    /**
     * The ID of the contact to show candidacies for.
     */
    contactId: PropTypes.number.isRequired,
  }),

  defaultProps({
    columns: [
      columns.SearchFullName,
      columns.SearchLeadName,
      columns.SearchIndustryList,
      columns.SearchSectorList,
      columns.SearchStatus,
      columns.StageDetails,
      columns.MoveStage,
      columns.DateAdded,
    ],
    searchType: TYPE_JOB_SEARCH,
  }),

  withClassName('ContactJobSearchCandidacyListTableView'),
)(ContactCandidacyListTableView);

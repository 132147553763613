import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import CommaDelimitedList from 'modules/core/componentsLegacy/CommaDelimitedList';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import mapCompanyIdToProperty from '../mapCompanyIdToProperty';
import CompanyAliasName from './CompanyAliasName';

/**
 * Renders a list of company aliases, separated by commas
 */
const CompanyAliasList = ({ companyAliasIds }) => (
  <CommaDelimitedList className='CompanyAliasList'>
    {companyAliasIds.map(companyAliasId => (
      <li key={companyAliasId}>
        <CompanyAliasName companyAliasId={companyAliasId} />
      </li>
    ))}
  </CommaDelimitedList>
);

CompanyAliasList.propTypes = {
  /**
   * The list of alias IDs
   */
  companyAliasIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired)
    .isRequired,
};

export default compose(
  setDisplayName('CompanyAliasList(enhanced)'),
  setPropTypes({
    /**
     * The ID of the company to render the aliases for.
     */
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToProperty('aliases', 'companyAliasIds'),

  // Don't render anything if there are no aliases.
  branch(
    ({ companyAliasIds }) => !companyAliasIds || !companyAliasIds.count(),
    renderNothing,
  ),
)(CompanyAliasList);

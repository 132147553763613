import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AssessmentView from 'modules/candidacies/components/assessments/AssessmentView';
import Notification from './Notification';

/**
 * Renders a notification which has a resource type of `assessment`
 */
const NotificationItemTypeAssessment = ({
  notification,
  showDetails,
  ...props
}) => (
  <Notification
    {...props}
    notification={notification}
    showDetails={showDetails}
  >
    {showDetails && (
      <AssessmentView
        assessmentId={notification.getIn(['data', 'resource', 'data', 'id'])}
        renderDate={false}
      />
    )}
  </Notification>
);

NotificationItemTypeAssessment.propTypes = {
  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          ratings: ImmutablePropTypes.listOf(
            ImmutablePropTypes.contains({
              assessment_option: ImmutablePropTypes.contains({
                description: PropTypes.string,
                name: PropTypes.string.isRequired,
              }),
              score: PropTypes.number,
            }),
          ),
          updated_at: PropTypes.string.isRequired,
        }),
      }),
      type: PropTypes.oneOf([
        'assessment',
        'recruiter_assessment',
        'client_assessment',
        'researcher_assessment',
      ]),
    }),
  }).isRequired,

  /**
   * When true, the note details will be displayed.
   * @type {[type]}
   */
  showDetails: PropTypes.bool,
};

export default NotificationItemTypeAssessment;

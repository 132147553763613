import isClientOrExternalRecruiterUser from 'modules/auth/selectors/isClientOrExternalRecruiterUser';

/**
 * Gets a value indicating whether the current user has permission to download
 * the candidacy summary
 * @param {Object} state
 * @return {Boolean} True if the current user is allowed to download the candidacy
 *   summary report; otherwise, false.
 */
export default state => !isClientOrExternalRecruiterUser(state);

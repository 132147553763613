import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import IntroductionList from './IntroductionList';
import withIntroductionIdsGroupedBySearchRelated from './withIntroductionIdsGroupedBySearchRelated';

/**
 * eslint incorrectly identifies our `map` function as being called on an array, but it's actually
 * and Immutable.Map, and what it thinks is the index is actually a key, so we have to disable
 * this rule here:
 */
/* eslint-disable react/no-array-index-key */

/**
 * Renders a list of introductions, grouped by whether or not they are related to
 * a search (provided by the `searchId` prop)
 */
const IntroductionListGrouped = ({
  introductionGroups,
  searchType,
  ...props
}) => (
  <ul className='list-generic'>
    {introductionGroups &&
      introductionGroups
        // Make sure "Search Related" are shown first.
        .sortBy((ids, group) => !group)
        .map((introductionIds, group) => (
          <li key={group}>
            <h3>{group ? `${searchType} Related` : 'Other'}</h3>
            <IntroductionList {...props} introductionIds={introductionIds} />
          </li>
        ))
        .toArray()}
  </ul>
);

IntroductionListGrouped.propTypes = {
  introductionGroups: ImmutablePropTypes.mapContains({
    [true]: ImmutablePropTypes.listOf(PropTypes.number),
    [false]: ImmutablePropTypes.listOf(PropTypes.number),
  }).isRequired,
  searchType: PropTypes.string.isRequired,
};

export default withIntroductionIdsGroupedBySearchRelated(
  IntroductionListGrouped,
);

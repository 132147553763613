import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { compose, onlyUpdateForPropTypes, setDisplayName } from 'recompose';
import classNames from 'classnames';
import UserName from 'modules/users/components/UserName';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';

import HtmlText from '@thrivetrm/ui/components/HtmlText';
import mapTaskIdToTask from './mapTaskIdToTask';
import TaskStatusButton from './TaskStatusButton';

class TaskTitle extends Component {
  createNotesMarkup = () => {
    const { task } = this.props;
    return task && task.get('notes');
  };

  /**
   * Whether to show "Assigned To: " in the card. i.e.
   * Has the task has been assigned to a user who is not also its creator?
   */
  showAssigned = () => {
    const { task } = this.props;
    const assignedId = task.get('assigned_to');
    const createdId = task.get('created_by');
    return assignedId !== createdId;
  };

  render() {
    const { componentId, currentUser, handleUpdate, task, taskId } = this.props;
    const popover = (
      <Popover id={componentId} style={{ maxWidth: '80vw', maxHeight: '80vh' }}>
        <HtmlText htmlString={this.createNotesMarkup()} />
      </Popover>
    );

    return (
      <div className='tasks-title u-flex u-flexAlign-c' id={taskId}>
        <TaskStatusButton handleUpdate={handleUpdate} taskId={taskId} />
        <span className='task-title-text'>
          <div
            className={classNames('task-title-subject', {
              'u-inlineBlock': !this.showAssigned(),
            })}
          >
            {task.get('subject')}
          </div>
          {this.showAssigned() && currentUser !== task.get('assigned_to') && (
            <div className='task-assigned-to'>
              <span> Assigned to: </span>
              <UserName userId={task.get('assigned_to')} />
              &nbsp;
            </div>
          )}
          {this.showAssigned() && currentUser === task.get('assigned_to') && (
            <div className='task-assigned-to'>
              <span> Assigned by: </span>
              <UserName userId={task.get('created_by')} />
              &nbsp;
            </div>
          )}
          {task.get('notes') && task.get('notes').length > 0 && (
            <OverlayTrigger
              overlay={popover}
              placement='top'
              trigger={['hover', 'focus']}
            >
              <i className='fa fa-sticky-note' />
            </OverlayTrigger>
          )}
        </span>
      </div>
    );
  }
}

TaskTitle.propTypes = {
  // Unique identifier for popover
  componentId: PropTypes.string.isRequired,
  currentUser: PropTypes.number,
  handleUpdate: PropTypes.func,
  task: ImmutablePropTypes.mapContains({
    subject: PropTypes.string.isRequired,
  }).isRequired,
  taskId: PropTypes.number.isRequired,
};

export default compose(
  withComponentId(),
  setDisplayName('TaskTitle(enhanced)'),
  mapTaskIdToTask,
  onlyUpdateForPropTypes,
)(TaskTitle);

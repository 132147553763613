import React from 'react';
import PropTypes from 'prop-types';
import {
  ASSESSMENT_TYPES,
  TYPE_CLIENT,
  TYPE_RECRUITER,
  TYPE_RESEARCHER,
} from 'modules/candidacy-assessments/constants';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import { useSelector } from 'react-redux';
import getClientTypeName from 'modules/tenant/selectors/getClientTypeName';
import getRecruiterName from 'modules/tenant/selectors/getRecruiterName';
import DateTime from 'modules/datetime/components/DateTime';
import DeleteCandidacyAssessmentModal from 'modules/candidacy-assessments/DeleteCandidacyAssessmentModal';
import moment from 'moment';
import HelpText from '@thrivetrm/ui/components/HelpText';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Card from '@thrivetrm/ui/components/Card';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import Rating from '@thrivetrm/ui/components/Rating';
import {
  getTemporaryDataForPath,
  useTemporaryBrowserData,
} from '@thrivetrm/ui/components/TemporaryBrowserData';
import getTenantId from 'modules/tenant/selectors/getTenantId';
import getCurrentUserId from 'modules/user/selectors/getCurrentUserId';
import { isDraftAssessmentDataSameAsSaved } from 'modules/assessments/components/CandidateAssessment';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { TYPE_TALENT_POOL } from 'modules/searches/constants';
import useQuickView from 'modules/quick-view/useQuickView';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { useGetContactQuery } from 'services/apiV1/contact';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import './CandidacyAssessmentList.scss';

const getAssessmentName = ({ clientTypeName, recruiterName, type }) => {
  switch (type) {
    case TYPE_CLIENT:
      return `${clientTypeName} Assessments`;
    case TYPE_RECRUITER:
      return `${recruiterName} Assessment`;
    case TYPE_RESEARCHER:
      return 'Researcher Assessment';
    default:
      return null;
  }
};

const CandidacyAssessmentList = ({
  assessmentType,
  assessments,
  canAddNewAssessment,
  candidacyId,
  contactId,
  loadCandidacyAssessments,
  searchId,
  template,
}) => {
  const clientTypeName = useSelector(state => getClientTypeName(state));
  const recruiterName = useSelector(state => getRecruiterName(state));
  const searchType = useSelector(state => getSearchType(state, searchId));
  const candidacyAssessmentUrl = `/${
    searchType === TYPE_TALENT_POOL ? 'talent_pools' : 'searches'
  }/${searchId}/candidates/${candidacyId}/candidacy_assessments`;

  const { data: contact } = useGetContactQuery(contactId);

  /**
   * Currently there is an issue where a local storage item will be created even when the user
   * has not interacted with the assessment form.  This check will determine if every value in
   * local storage is the same as every matching field on the saved assessment.  When they are
   * all equal, we don't want to show the draft status message.  In the event a new assessment
   * is being created, we want to check & see if more than just assessed_on & assessed_by have
   * been populated because these two fields are always given a default value with the exception
   * of guest assessment where only assessed_on is populated
   */
  const { data } = useTemporaryBrowserData();
  const draftIsSameAsSaved = isDraftAssessmentDataSameAsSaved(
    data?.content,
    {},
  );

  const tenantId = useSelector(getTenantId);
  const currentUserId = useSelector(getCurrentUserId);
  const { navigateTo } = useQuickView();
  const hasAssessmentSummaryViewFeature = useFeatureCheck(
    'development.assessment_v2_summary_view',
  );
  const ratingStars = useSelector(state => state.tenant)?.get('rating_stars');

  return canAddNewAssessment || assessments?.length ? (
    <div
      className='u-borderBottom u-paddingBottom-12'
      data-testid={assessmentType}
    >
      <h3>
        {getAssessmentName({
          type: assessmentType,
          clientTypeName: clientTypeName,
          recruiterName: recruiterName,
        })}
      </h3>
      {canAddNewAssessment ? (
        <div className='u-flex u-flexJustify-c'>
          <ButtonPrimary
            className='u-marginBottom-12'
            isFullWidth={true}
            label={
              data && !draftIsSameAsSaved
                ? 'Resume Assessment'
                : 'Add Assessment'
            }
            url={`${candidacyAssessmentUrl}/new?type=${assessmentType}`}
          />
        </div>
      ) : null}
      {assessments?.map(assessment => {
        const dataPath = {
          tenant: tenantId,
          user: currentUserId,
          search: searchId,
          candidacy: candidacyId,
          assessment: assessment.id,
        };
        const draftData = getTemporaryDataForPath(dataPath);
        const {
          assessed_by: _assessedBy,
          assessed_by_name: _assessedByName,
          assessed_on: _assessedOn,
          ...restOfDraft
        } = draftData?.content ?? {};
        const isDraftSameAsSaved =
          isDraftAssessmentDataSameAsSaved(draftData?.content, assessment) &&
          Object.keys(restOfDraft).length === 0;

        return (
          <Card
            className='u-marginVertical-16'
            isCentered={false}
            key={assessment.id}
          >
            <div className='u-marginBottom-8 u-wordBreak'>
              Assessed by <strong>{assessment.assessed_by_name}</strong>{' '}
              <TooltipTrigger
                placement='top'
                tooltip={
                  <DateTime
                    format='ddd MMM D, YYYY [at] h:mma'
                    value={assessment.assessed_on}
                  />
                }
              >
                <HelpText>{moment(assessment.assessed_on).fromNow()}</HelpText>
              </TooltipTrigger>
            </div>
            {hasAssessmentSummaryViewFeature ? (
              <div className='u-paddingBottom-4'>
                {template.criteria.map(({ id, name }) => {
                  const ratingValue = assessment.candidacy_assessment_answers_attributes?.find(
                    criteriaDetails =>
                      criteriaDetails.assessment_criterium_id === id,
                  )?.rating_value;
                  return (
                    <div
                      className='u-flex u-flexAlign-c u-paddingVertical-4'
                      key={id}
                    >
                      <Rating maxRating={ratingStars} value={ratingValue} />
                      <span className='u-marginLeft-8 u-fontSize-small'>
                        {name}
                      </span>
                    </div>
                  );
                })}
                {template.categories.length ? (
                  <>
                    <span className='u-fontSize-small'>Category Averages:</span>
                    {template.categories.map(({ id, name }) => {
                      const criteriaAverage = assessment.candidacy_assessment_answers_attributes?.find(
                        criteriaData =>
                          criteriaData.assessment_category_id === id &&
                          criteriaData.criteria_average,
                      )?.criteria_average;

                      return (
                        <div className='u-paddingVertical-4 u-flex' key={id}>
                          <Rating
                            maxRating={ratingStars}
                            value={Math.round(criteriaAverage * 2) / 2}
                          />
                          <p className='u-margin-n u-marginLeft-8 u-fontSize-small'>
                            {name}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
            ) : null}
            {isDraftSameAsSaved ? null : (
              <div className='u-marginBottom-8'>
                <em>Unsaved changes</em>
              </div>
            )}
            {assessment.notes ? (
              <>
                <strong>Summary Notes:</strong>
                {hasAssessmentSummaryViewFeature ? (
                  <span
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    <HtmlText htmlString={assessment.notes} />
                  </span>
                ) : (
                  <ExpandableText
                    characterLimit={100}
                    className='u-wordBreak'
                    content={assessment.notes}
                    isSanitizedHtml={true}
                  />
                )}
              </>
            ) : null}
            <div className='u-flex u-flexJustify-r u-marginTop-8'>
              {hasAssessmentSummaryViewFeature ? (
                <ButtonLink
                  label='View Full Assessment'
                  onClick={() => {
                    navigateTo(
                      `/contact/${contactId}/candidate-assessment/${assessment.id}`,
                      { title: contact?.fullName },
                    );
                  }}
                />
              ) : (
                <ButtonLink
                  label='View'
                  onClick={() => {
                    window.location.href = `${candidacyAssessmentUrl}/${assessment.id}`;
                  }}
                />
              )}
              {assessment.permissions.edit ? (
                <ButtonLink
                  className='u-marginLeft-12'
                  label={!isDraftSameAsSaved ? 'Continue Editing' : 'Edit'}
                  onClick={() => {
                    window.location.href = `${candidacyAssessmentUrl}/${assessment.id}/edit`;
                  }}
                />
              ) : null}
              {assessment.permissions.delete ? (
                <DeleteCandidacyAssessmentModal
                  assessmentId={assessment.id}
                  candidacyId={candidacyId}
                  onSuccess={loadCandidacyAssessments}
                />
              ) : null}
            </div>
          </Card>
        );
      })}
    </div>
  ) : null;
};

CandidacyAssessmentList.defaultProps = {
  canAddNewAssessment: true,
};

CandidacyAssessmentList.propTypes = {
  assessments: PropTypes.arrayOf(
    PropTypes.shape({
      assessed_by_name: PropTypes.string.isRequired,
      assessed_on: PropTypes.string,
      id: PropTypes.number.isRequired,
      notes: PropTypes.string,
      permissions: PropTypes.shape({
        delete: PropTypes.bool.isRequired,
        edit: PropTypes.bool.isRequired,
      }),
      type: PropTypes.oneOf(ASSESSMENT_TYPES).isRequired,
    }),
  ),
  assessmentType: PropTypes.oneOf(ASSESSMENT_TYPES).isRequired,
  canAddNewAssessment: PropTypes.bool,
  candidacyId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
  loadCandidacyAssessments: PropTypes.func.isRequired,
  searchId: PropTypes.number.isRequired,
  template: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        criteria: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
          }),
        ),
        name: PropTypes.string,
      }),
    ),
    criteria: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    id: PropTypes.number.isRequired,
  }),
};

export default CandidacyAssessmentList;

import PropTypes from 'prop-types';
import React from 'react';
import LabeledContent from 'modules/core/componentsLegacy/LabeledContent';

const getMinValue = value => {
  if (value === 0) {
    return '0';
  }
  if (value > 0) {
    return `${value}M`;
  }
  return null;
};

const getMaxValue = value => {
  if (value === 999999) {
    return '100M+';
  }
  if (value > 0) {
    return `${value}M`;
  }
  return null;
};

const formatRevenues = (min, max) => {
  if (max) {
    return `${min || '0'} - ${max}`;
  }
  if (min) {
    return min;
  }
  return null;
};

/**
 * @deprecated Use RevenueRangeValues in `modules/currency`
 */
function RevenueRange({ hideIfEmpty, maxRevenue, minRevenue }) {
  const min = getMinValue(minRevenue);
  const max = getMaxValue(maxRevenue);
  if (
    !hideIfEmpty ||
    typeof minRevenue === 'number' ||
    typeof maxRevenue === 'number'
  ) {
    return (
      <LabeledContent label='Revenue Range'>
        {formatRevenues(min, max)}
      </LabeledContent>
    );
  }
}

RevenueRange.propTypes = {
  hideIfEmpty: PropTypes.bool,
  maxRevenue: PropTypes.number,
  minRevenue: PropTypes.number,
};

export default RevenueRange;

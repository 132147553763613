import getIntroduction from './getIntroduction';

/**
 * Determines if an introduction record is related to a particular search
 * @param {Object} state
 * @param {Number} introductionId The introduction record ID.
 * @param {Number} searchId The ID of the search to check.
 * @return {Boolean} True if the introduction is related to the search.
 */
export default (state, introductionId, searchId) => {
  if (!searchId) {
    return false;
  }

  const introduction = getIntroduction(state, introductionId);

  if (!introduction) {
    return false;
  }

  const searches = introduction.get('searches');

  if (!searches || !searches.count()) {
    return false;
  }

  return searches.contains(searchId);
};

import React from 'react';
import PropTypes from 'prop-types';
import FundingRoundsTableView from './FundingRoundsTableView';
import CompanyFundingRoundsTotalAmount from './CompanyFundingRoundsTotalAmount';
import FundingRoundFormToggleButton from './FundingRoundFormToggleButton';

/**
 * A component that displays a company's funding rounds (i.e. investmenst where the company is
 * the venture) in a table, allowing adding new funding rounds, and editing and deleting existing
 * funding rounds. It includes a header that shows the total funding.
 */
const CompanyFundingRounds = ({ companyId }) => (
  <div className='CompanyFundingRounds'>
    <div className='CompanyFundingRounds__total'>
      <span className='label'>Total Funding: </span>
      <CompanyFundingRoundsTotalAmount companyId={companyId} />
    </div>
    <FundingRoundFormToggleButton
      btnClassName='FundingRoundFormToggleButton btn-link'
      companyId={companyId}
      title='Add Funding Round'
    >
      <i className='fa fa-plus-circle' />
      <span> Funding Round</span>
    </FundingRoundFormToggleButton>
    <FundingRoundsTableView companyId={companyId} />
  </div>
);

CompanyFundingRounds.propTypes = {
  /**
   * The ID of the company to render the funding rounds for.
   */
  companyId: PropTypes.number.isRequired,
};

export default CompanyFundingRounds;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

/**
 * Renders "show more" link to see the rest of the assessments
 */
export const ShowMoreAttributesText = ({ maxOptions, moreText, options }) => (
  <span>
    {options && maxOptions && moreText && options.size > maxOptions && (
      <span className='more-text'>
        {moreText.replace('{##}', options.size)}
      </span>
    )}
  </span>
);

ShowMoreAttributesText.propTypes = {
  /**
   * The maximum number of asseessment options/attributes to render.
   */
  maxOptions: PropTypes.number,

  /**
   * The text that should be rendered.
   */
  moreText: PropTypes.string,

  /**
   * The list of all assessement attributes
   */
  options: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.number.isRequired,
    }),
  ),
};

export default ShowMoreAttributesText;

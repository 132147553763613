import { connect } from 'react-redux';
import getCurrentUserId from 'modules/auth/selectors/getCurrentUserId';
import canAddClientAssessment from 'modules/assessments/selectors/canAddClientAssessment';
import canAddManyClientAssessments from 'modules/assessments/selectors/canAddManyClientAssessments';
import canEditRecruiterAssessment from 'modules/assessments/selectors/canEditRecruiterAssessment';
import canCreateGuestAssessmentInvite from '../../selectors/canCreateGuestAssessmentInvite';

/**
 * A higher order component which fetches assessment-related permissions
 * and user properties from state and provides them as props.
 */
export default connect(
  state => ({
    currentUserId: getCurrentUserId(state),
    canAddManyClientAssessments: canAddManyClientAssessments(state),
    canAddClientAssessment: canAddClientAssessment(state),
    canEditRecruiterAssessment: canEditRecruiterAssessment(state),
    canCreateGuestAssessmentInvite: canCreateGuestAssessmentInvite(state),
  }),
  {},
);

import { compose, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import connectTransactions from 'modules/transactions/components/connectTransactions';

import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';

import connectTalentPoolActions from './connectTalentPoolActions';
import TalentPoolField from './TalentPoolField';

export const createRootFieldState = ({
  contact,
  contactId,
  leadId,
  talentPool,
}) =>
  TalentPoolField.createFieldState(
    'talent_pool',
    talentPool
      ? talentPool.toJS()
      : {
          current_contact: contactId,
          client_company:
            contact &&
            (contact.get('primary_company') ||
              contact.get('primary_company_id')),
          job_title: contact && contact.get('primary_title'),
          lead_internal_team_member: leadId,
        },
  );

export const handleSubmit = (formState, props) => {
  const transactionId = uniqueId();

  props.talentPoolActions.createTalentPool({
    talent_pool: formState.getFieldValue(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

const TalentPoolForm = compose(
  setDisplayName('TalentPoolForm'),
  connectTalentPoolActions,
  connectTransactions,
  mapContactIdToContact,
  connect(
    ({ tenant }) => ({
      leadId: tenant.get('talent_pool_lead_id'),
    }),
    {},
  ),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'talent-pool-form',
    formActionProps: { submitLabel: 'Send Report' },
    omitProps: [
      'transactionActions',
      'talentPoolActions',
      'formState',
      'leadId',
    ],
  }),
)(TalentPoolField);

export default TalentPoolForm;

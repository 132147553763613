import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { onlyUpdateForPropTypes } from 'recompose';
import SortByDropdownButton from 'modules/core/componentsLegacy/SortByDropdownButton';
import PriorityOnlyToggleButton from './PriorityOnlyToggleButton';
import {
  SORT_BY_LABELS,
  SORT_BY_DEFAULT,
  SORT_ASCENDING_DEFAULT,
} from '../constants';

class SortAndFilterToolbar extends Component {
  handleChange = changes => {
    const {
      filterText,
      isPriorityOnly,
      onChange,
      sortAscending,
      sortBy,
    } = this.props;
    onChange({
      filterText: filterText,
      isPriorityOnly: isPriorityOnly,
      sortAscending: sortAscending,
      sortBy: sortBy,
      ...changes,
    });
  };

  handleFilterTextChange = event => {
    this.handleChange({ filterText: event.currentTarget.value });
  };

  handleSortChange = sortBy => {
    const { sortAscending, sortBy: prevSortBy } = this.props;

    if (prevSortBy === sortBy) {
      this.handleChange({ sortAscending: !sortAscending });
    } else {
      this.handleChange({ sortBy: sortBy });
    }
  };

  handleIsPriorityOnlyToggle = () => {
    const { isPriorityOnly } = this.props;
    this.handleChange({ isPriorityOnly: !isPriorityOnly });
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  render() {
    const {
      filterText,
      isPriorityOnly,
      sortAscending,
      sortBy,
      sortOptions,
    } = this.props;

    return (
      <form
        className='form target-companies--sort-and-filter-toolbar'
        onSubmit={this.handleSubmit}
        role='search'
      >
        <div className='input-group'>
          <input
            className='form-control'
            onChange={this.handleFilterTextChange}
            placeholder='Filter by name'
            type='text'
            value={filterText}
          />
          <SortByDropdownButton
            className='input-group-btn'
            isAscending={sortAscending}
            onChange={this.handleSortChange}
            options={sortOptions}
            value={sortBy}
          >
            <PriorityOnlyToggleButton
              isSelected={isPriorityOnly}
              onToggle={this.handleIsPriorityOnlyToggle}
            />
          </SortByDropdownButton>
        </div>
      </form>
    );
  }
}

SortAndFilterToolbar.propTypes = {
  /**
   * The current text being filtered on and displayed in the input
   */
  filterText: PropTypes.string,

  /**
   * True if the priority only toggle is selected; otherwise, false.
   */
  isPriorityOnly: PropTypes.bool,

  /**
   * Called when an of the filter or sorting values are changed, where the first parameter is
   * an object of filter values:
   *   `onChange({ filterText, isPriorityOnly, sortAscending, sortBy })`
   */
  onChange: PropTypes.func.isRequired,

  /**
   * True if sorting should be in ascending order, false to sort in descending order.
   */
  sortAscending: PropTypes.bool,

  /**
   * The value to sort on
   */
  sortBy: (props, propName, componentName) => {
    const { sortOptions, [propName]: value } = props;
    if (!value || (sortOptions && !sortOptions[value])) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`, ` +
          `value must match a key in the prop \`sortOptions\`. \`${value}\` ` +
          `not found in: ["${Object.keys(sortOptions).join('", "')}"]`,
      );
    }

    return null;
  },

  /**
   * An array of valid sort options for the `sortBy` property.
   */
  sortOptions: PropTypes.objectOf(PropTypes.string),
};

SortAndFilterToolbar.defaultProps = {
  filterText: '',
  isPriorityOnly: false,
  sortAscending: SORT_ASCENDING_DEFAULT,
  sortBy: SORT_BY_DEFAULT,
  sortOptions: SORT_BY_LABELS,
};

export default onlyUpdateForPropTypes(SortAndFilterToolbar);

import React, { Component } from 'react';
import RocketReachPopover from './RocketReachPopover';

/**
 * Renders a button to toggle the RocketReach popover.
 */
class RocketReachButton extends Component {
  state = {
    isPopoverOpen: false,
  };

  handleToggle = () => {
    const { isPopoverOpen } = this.state;
    this.setState({ isPopoverOpen: !isPopoverOpen });
  };

  render() {
    const { isPopoverOpen } = this.state;

    return (
      <div className='rocketreach-button'>
        <button
          className='btn btn-link'
          onClick={this.handleToggle}
          type='button'
        >
          <i className='fa fa-rocket fa-2x rocketreach-button-icon' />
        </button>

        {isPopoverOpen && (
          <RocketReachPopover {...this.props} onClose={this.handleToggle} />
        )}
      </div>
    );
  }
}

export default RocketReachButton;

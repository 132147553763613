import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import isHiringManagerUser from 'modules/auth/selectors/isHiringManagerUser';
import CandidacyMoveStageButton from './stages/CandidacyMoveStageButton';
import CandidacyStageName from './stages/CandidacyStageName';
import canUpdateCandidacyStage from '../selectors/canUpdateCandidacyStage';

/**
 * Renders:
 *   * Move stage button rendered with the current stage name for employee users
 *   * Stage name rendered for client users
 */
const CandidacyStageDropdown = ({ canMoveStage, candidacyId }) => (
  <div className='CandidacyStageDropdown'>
    {canMoveStage || isHiringManagerUser ? (
      <CandidacyMoveStageButton
        btnClassName='btn-link'
        candidacyId={candidacyId}
      >
        <div className='CandidacyStageDropdown__wrapper_div'>
          <CandidacyStageName
            candidacyId={candidacyId}
            renderIfMissing={true}
          />
          <i className='fa fa-caret-down' />
        </div>
      </CandidacyMoveStageButton>
    ) : (
      <CandidacyStageName candidacyId={candidacyId} renderIfMissing={true} />
    )}
  </div>
);

CandidacyStageDropdown.propTypes = {
  /**
   * The ID of the candidacy
   */
  candidacyId: PropTypes.number.isRequired,

  /**
   * True to enable moving the stage the candidacy currently belongs to. When false (the default),
   * the move stage button will not be rendered and the stage name will be rendered instead.
   */
  canMoveStage: PropTypes.bool,
};

CandidacyStageDropdown.defaultProps = {
  canMoveStage: false,
};

export default compose(
  setDisplayName('CandidacyStageDropdown(enhanced)'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  connect(
    state => ({
      canMoveStage: canUpdateCandidacyStage(state),
    }),
    {},
  ),
)(CandidacyStageDropdown);

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import CandidaciesCreateModalForm from 'modules/candidacies/components/CandidaciesCreateModalForm';
import {
  TYPE_JOB_SEARCH,
  SEARCH_TYPE_LABELS,
  SEARCH_TYPES,
} from 'modules/searches/constants';
import OffLimitsIndicator from 'modules/off-limits/OffLimitsIndicator';
import getOffLimits from 'modules/contacts/selectors/contacts/getOffLimits';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';

const AddToSearchForm = ({ contact, searchType }) => {
  const [isModalOpen, openModal, closeModal] = useToggle(false);

  const contactName = contact.getIn(['data', 'full_name'], 'a contact');

  const offLimits = useSelector(state =>
    getOffLimits(state, contact.getIn(['data', 'id'])),
  );

  const contactId = contact.getIn(['data', 'id']);

  const offLimitsModalMessage = () => {
    return offLimits && searchType === TYPE_JOB_SEARCH ? (
      <>
        You are about to add {contactName} to a search, but they are <br />
        <OffLimitsIndicator
          reason={offLimits.get('status')}
          recordId={contactId}
          recordName={contactName}
          recordType='contact'
          severity={offLimits.get('category')}
        />
      </>
    ) : null;
  };

  return (
    <div className='AddToSearchForm'>
      {offLimits && searchType === TYPE_JOB_SEARCH && (
        <div className='AddToSearchForm__OffLimitsStatus'>
          <OffLimitsIndicator
            reason={offLimits.get('status')}
            recordId={contactId}
            recordName={contactName}
            recordType='contact'
            severity={offLimits.get('category')}
          />
        </div>
      )}
      <ButtonPrimary
        className='AddToSearchForm__AddButton'
        icon='add'
        isOutline={true}
        label={`Add to ${SEARCH_TYPE_LABELS[searchType]}`}
        onClick={openModal}
        size='small'
      />
      <CandidaciesCreateModalForm
        contactIds={[contactId]}
        message={offLimitsModalMessage()}
        onClose={closeModal}
        searchType={searchType}
        show={isModalOpen}
      />
    </div>
  );
};

AddToSearchForm.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.map,
    meta: ImmutablePropTypes.map,
  }).isRequired,
  searchType: PropTypes.oneOf(SEARCH_TYPES).isRequired,
};

export default AddToSearchForm;

import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withProps,
  setStatic,
  setPropTypes,
  setDisplayName,
} from 'recompose';
import mapContactIdToContact from 'modules/contacts/components/mapContactIdToContact';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import FieldState from 'modules/forms/FieldState';
import ReactSelectField from 'modules/forms/components/ReactSelectField';

/**
 * A component to select from among a contact's candidacies. The dropdown displays
 * the names of the searches, but the underlying value is the candidacy id.
 */
const CandidacySelectField = ({
  className,
  componentId,
  fieldState,
  firstName,
  fullName,
  onChange,
  options,
}) => (
  <ReactSelectField
    className={className}
    fieldState={fieldState}
    isLoading={!options}
    label={
      <div>
        <span>*Related Search </span>
        <OverlayTrigger
          overlay={
            <Tooltip id={`${componentId}-tooltip`}>
              <span>
                {`Available searches where ${fullName} is a candidate. If your desired search does not appear, add ${firstName} to the search as a candidate.`}
              </span>
            </Tooltip>
          }
          placement='right'
        >
          <i className='fa fa-info-circle btn-tooltip' />
        </OverlayTrigger>
      </div>
    }
    onChange={onChange}
    options={options}
  />
);

CandidacySelectField.propTypes = {
  className: PropTypes.string,
  /**
   * The FieldState that manages the value of the control.
   */
  componentId: PropTypes.string.isRequired,

  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  firstName: PropTypes.string.isRequired,

  fullName: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
};

export default compose(
  setDisplayName('CandidacySelectField'),
  setPropTypes({
    contactId: requiredIf(PropTypes.number, ({ contact }) => !contact),
    contact: requiredIf(PropTypes.number, ({ contactId }) => !contactId),
  }),
  setStatic('createFieldState', ReactSelectField.createFieldState),
  withComponentId(),
  mapContactIdToContact,
  withProps(({ contact }) => ({
    options:
      contact &&
      contact
        .get('candidacies')
        .map(candidacy => ({
          value: candidacy.get('id'),
          label: candidacy.getIn(['search', 'name']),
        }))
        .toJS(),
    firstName: contact && contact.get('first_name'),
    fullName: contact && contact.get('full_name'),
  })),
)(CandidacySelectField);

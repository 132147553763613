import routes from 'modules/routing/routes';
import createCrudActions from '../../../actions/createCrudActions';
import {
  NETWORKS_FETCH_START,
  NETWORKS_FETCH_SUCCESS,
  NETWORKS_FETCH_FAILURE,
  NETWORKS_INVALIDATE,
  NETWORK_FETCH_START,
  NETWORK_FETCH_SUCCESS,
  NETWORK_FETCH_FAILURE,
  NETWORK_INVALIDATE,
  NETWORK_CREATE_START,
  NETWORK_CREATE_SUCCESS,
  NETWORK_CREATE_FAILURE,
  NETWORK_UPDATE_START,
  NETWORK_UPDATE_SUCCESS,
  NETWORK_UPDATE_FAILURE,
  NETWORK_DELETE_START,
  NETWORK_DELETE_SUCCESS,
  NETWORK_DELETE_FAILURE,
} from './actionTypes';
import shouldFetchNetworks from '../selectors/shouldFetchNetworks';
import shouldFetchNetworksByContact from '../selectors/shouldFetchNetworksByContact';
import networkSchema from '../schema';

const getCollectionUrl = ({ contactId, type }) => {
  if (contactId) {
    return routes.api_v1_contact_networks({ contactId: contactId });
  }

  return routes.api_v1_networks({
    query: {
      type: type,
    },
  });
};

const getEntityUrl = ({ id, network }) =>
  routes.api_v1_network({ id: id || network.id });

const {
  create: createNetwork,
  delete: deleteNetwork,
  fetch: fetchNetwork,
  fetchAll: fetchNetworks,
  invalidate: invalidateNetwork,
  invalidateAll: invalidateNetworks,
  update: updateNetwork,
} = createCrudActions({
  getUrl: getEntityUrl,
  schema: { network: networkSchema },
  fetchAll: {
    getUrl: getCollectionUrl,
    schema: { networks: [networkSchema] },
    start: NETWORKS_FETCH_START,
    success: NETWORKS_FETCH_SUCCESS,
    failure: NETWORKS_FETCH_FAILURE,
  },
  invalidateAll: NETWORKS_INVALIDATE,
  fetch: {
    start: NETWORK_FETCH_START,
    success: NETWORK_FETCH_SUCCESS,
    failure: NETWORK_FETCH_FAILURE,
  },
  invalidate: NETWORK_INVALIDATE,
  create: {
    getUrl: getCollectionUrl,
    start: NETWORK_CREATE_START,
    success: NETWORK_CREATE_SUCCESS,
    failure: NETWORK_CREATE_FAILURE,
  },
  update: {
    start: NETWORK_UPDATE_START,
    success: NETWORK_UPDATE_SUCCESS,
    failure: NETWORK_UPDATE_FAILURE,
  },
  delete: {
    schema: null,
    start: NETWORK_DELETE_START,
    success: NETWORK_DELETE_SUCCESS,
    failure: NETWORK_DELETE_FAILURE,
  },
});

export const fetchNetworksIfNeeded = params => (dispatch, getState) => {
  const shouldFetch =
    params && params.contactId
      ? shouldFetchNetworksByContact(getState(), params.contactId)
      : shouldFetchNetworks(getState());

  if (shouldFetch) {
    dispatch(fetchNetworks(params));
  }
};

export {
  fetchNetworks,
  invalidateNetworks,
  fetchNetwork,
  invalidateNetwork,
  createNetwork,
  updateNetwork,
  deleteNetwork,
};

import { defaultTo } from 'lodash';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import companyInvestmentStageSchema from '../schemas/companyInvestmentStages';

/**
 * Gets a number indicating the order that a company stage should be sorted
 * in.
 * @param {*} state
 * @param {Number} id The company stage ID
 * @return {Number} The order of the compant stage for sorting.
 */
export default (state, id) =>
  defaultTo(
    getEntityProperty(state, companyInvestmentStageSchema.key, id, 'ordinal'),
    Number.MAX_SAFE_INTEGER,
  );

import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import CompanyMarketCapField from './CompanyMarketCapField';
import withCompanyUpdateForm from '../withCompanyUpdateForm';

/**
 * Renders a form for editing a company's market cap.
 */
const CompanyMarketCapForm = ({
  // props provided by `withCompanyUpdateForm`
  formState,
  onCancel,
  onFieldStateChange,

  // Props passed through/expected from parent component.
  onSubmit,
  submitButtonLabel,
  title,

  ...fieldProps
}) => (
  <Form
    className='CompanyMarketCapForm'
    formState={formState}
    onSubmit={onSubmit}
  >
    {title && <h3>{title}</h3>}
    <CompanyMarketCapField
      {...fieldProps}
      disabled={formState.isSubmitting()}
      fieldState={formState.getFieldState()}
      onChange={onFieldStateChange}
      showErrors={formState.wasSubmitted() || 'blurred'}
    />
    <FormErrorMessage formState={formState} />
    <div className='Form__footer'>
      <CancelButton onClick={onCancel} />
      <SubmitButton formState={formState} onClick={onSubmit}>
        {submitButtonLabel}
      </SubmitButton>
    </div>
  </Form>
);

CompanyMarketCapForm.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the modal should be closed.
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * The label for the submit button.
   */
  submitButtonLabel: PropTypes.node,

  /**
   * The text to display in the header.
   */
  title: PropTypes.node,
};

CompanyMarketCapForm.defaultProps = {
  submitButtonLabel: 'Save',
  title: null,
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('CompanyMarketCapForm(enhanced))'),
  setPropTypes({
    // ...withCompanyUpdateForm.propTypes,

    /**
     * Called when the record was saved.
     */
    onSaved: PropTypes.func.isRequired,

    /**
     * Called when the cancel button is clicked. Called with the current formState
     */
    onCancel: PropTypes.func.isRequired,
  }),

  withCompanyUpdateForm(CompanyMarketCapField),

  withHandlers({
    onCancel: ({ formState, onCancel }) => () => onCancel(formState),
  }),

  /**
   * Prevent props from being passed on to the modal
   */
  /* eslint-disable no-unused-vars */
  mapProps(({ companyId, onFormStateChange, onResetFormState, ...rest }) => ({
    ...rest,
  })),
  /* eslint-enable no-unused-vars */
)(CompanyMarketCapForm);

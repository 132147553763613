import React, { useState, useEffect } from 'react';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Form from '@thrivetrm/ui/components/Form';
import {
  useGetContactQuery,
  useUpdateContactMutation,
} from 'services/apiV1/contact';
import { useDispatch, useSelector } from 'react-redux';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import Card from '@thrivetrm/ui/components/Card';
import { capitalize } from '@thrivetrm/ui/utilities/stringUtils';
import selectedContact from 'selectors/contact/selectedContact';
import { fetchNotifications } from 'actions/contacts';
import useQuickView from '../useQuickView';
import PersonalInformationForm from './PersonalInformationForm';
import ContactDetailsForm from './ContactDetailsForm';
import ContactSocialForm from './ContactSocialForm';
import ContactAddressForm from './ContactAddressForm';
import ContactQvpHeader from '../ContactQvpHeader';

const preferredEmailValues = {
  /*
   true: sets work email as the preferred email
   false: sets personal email as the preferred email
   null: resets the preferred email 
  */
  PERSONAL: false,
  WORK: true,
  NONE: null,
};

const getErrorMessages = errors => {
  const { data } = errors || {};
  // shape of the error object that endpoint returns
  // errors: {email: ['has already been taken'], work_email: ['has already been taken']}
  if (data && data.errors) {
    // converts the error object into list
    //  <ul>
    //    <li>Email has already been taken</li>
    //    <li>Work email has already been taken</li>
    //  </ul>
    return (
      <ul className='u-marginBottom-n u-paddingLeft-24'>
        {Object.keys(data.errors).map(error => (
          <li key={error}>
            {`${capitalize(error.replace('_', ' '))} ${data.errors[error][0]}`}
          </li>
        ))}
      </ul>
    );
  }
  return null;
};

const ContactProfileEditForm = () => {
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const { navigateTo, pathParams } = useQuickView();
  const { contactId } = pathParams;

  const candidacyId = pathname.includes('searches')
    ? pathname.split('/')[4]
    : null;
  const companyId = pathname.includes('companies')
    ? pathname.split('/')[2]
    : null;

  const {
    data: contact,
    isLoading: isLoadingContactDetails,
  } = useGetContactQuery(contactId);

  const [
    updateContact,
    { error: updateContactError },
  ] = useUpdateContactMutation();

  const [preferredEmail, setPreferredEmail] = useState('NONE');
  const errorMessages = getErrorMessages(updateContactError);
  const legacyContactData = useSelector(state => selectedContact(state));
  const referenceNotifications =
    legacyContactData
      ?.getIn(['notifications', 'data'])
      ?.find(
        notification => notification.getIn(['data', 'type']) === 'reference',
      ) ?? null;

  useEffect(() => {
    setPreferredEmail(
      Object.keys(preferredEmailValues).find(
        key => preferredEmailValues[key] === contact?.workEmailPreferred,
      ),
    );
  }, [contact?.workEmailPreferred]);

  const handleSubmit = formData => {
    updateContact({
      candidacyId: candidacyId,
      contactId: contactId,
      contactListId: `CompanyContactsTable-${companyId}`,
      payload: {
        contact: {
          ...formData,
          work_email_preferred: preferredEmailValues[preferredEmail],
        },
      },
    })
      .unwrap()
      .then(newContact => {
        dispatch(toastSuccess('Successfully updated contact details.'));
        navigateTo(`/contacts/${contactId}`, { title: newContact?.fullName });
        // invalidates the contact activity timeline if the reference type of notification exists
        if (referenceNotifications?.size) {
          dispatch(
            fetchNotifications({
              limit: legacyContactData.getIn(['notifications', 'data']).size,
              contactId: legacyContactData.getIn(['data', 'id']),
              filters: legacyContactData
                .getIn(['notifications', 'meta', 'filters'])
                .toJS(),
            }),
          );
        }
      })
      .catch(() => {
        dispatch(toastError('Could not update contact details.'));
      });
  };

  return (
    <>
      <ContactQvpHeader
        contactId={contactId}
        onBack={contactId ? () => navigateTo(`/contacts/${contactId}`) : null}
      />
      <Form onSubmit={handleSubmit}>
        <SidePanel.Body className='u-paddingTop-n'>
          <LoadingContainer
            className='u-paddingTop-4'
            isLoading={isLoadingContactDetails}
          >
            {errorMessages ? (
              <Card
                className='u-marginBottom-16'
                isCentered={false}
                type='error'
              >
                There was an error updating this contact.
                {errorMessages}
              </Card>
            ) : null}
            <PersonalInformationForm contactDetails={contact} />
            <ContactDetailsForm
              contactDetails={contact}
              preferredEmail={preferredEmail}
              setPreferredEmail={setPreferredEmail}
            />
            <ContactSocialForm contactDetails={contact} />
            <ContactAddressForm contactDetails={contact} />
          </LoadingContainer>
        </SidePanel.Body>
        <SidePanel.Footer>
          <ButtonPrimary
            isOutline={true}
            label='Cancel'
            onClick={() => navigateTo(`/contacts/${contactId}`)}
          />
          <Form.SubmitButton label='Save' />
        </SidePanel.Footer>
      </Form>
    </>
  );
};

export default ContactProfileEditForm;

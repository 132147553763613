import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import { compose, setPropTypes, setDisplayName, withHandlers } from 'recompose';

export const CompanyEmployeeCountDeleteToggle = ({
  disabled,
  fieldState,
  onClick,
}) => (
  <button
    className='CompanyEmployeeCountDeleteToggle btn btn-link'
    disabled={disabled}
    onClick={onClick}
    type='button'
  >
    <i
      className={classnames('fa', {
        'fa-trash': !fieldState.getNestedFieldValue('_destroy'),
        'fa-undo': Boolean(fieldState.getNestedFieldValue('_destroy')),
      })}
    />
  </button>
);

CompanyEmployeeCountDeleteToggle.propTypes = {
  /**
   * True to disable this btn.
   */
  disabled: PropTypes.bool,

  /**
   * The field state containing the nested values.
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * Called delete btn is clicked.
   */
  onClick: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CompanyEmployeeCountDeleteToggle(enhanced)'),
  setPropTypes({
    disabled: PropTypes.bool,

    /**
     * Called when the companyStatistics value is changed in some way
     * (the name is changed, it is marked for deletion, or unmarked for deletion)
     */
    onChange: PropTypes.func.isRequired,
  }),

  withHandlers({
    onClick: ({ fieldState, onChange }) => e => {
      e.preventDefault();
      if (fieldState.getNestedFieldValue('_destroy')) {
        onChange(fieldState.setNestedFieldValue('_destroy', false));
      } else {
        onChange(fieldState.setNestedFieldValue('_destroy', true));
      }
    },
  }),
)(CompanyEmployeeCountDeleteToggle);

import PropTypes from 'prop-types';
import React from 'react';
import Popover from 'react-bootstrap/lib/Popover';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import { STAGE_TYPES } from '../constants';
import SearchStageListMenu from './SearchStageListMenu';

/**
 * A popover that displays a list of stages in a menu, allowing a stage to be selected
 * from the list by clicking it.
 */
const SearchStageListPopover = ({
  // className is needed because the Overlay passes in some classes
  // as well. So don't remove this -- it seems like it's not being used, but it is!
  className,
  isSubmitting,
  onDelete,
  onSelectStage,
  stageType,
  submitError,
  value,

  ...popoverProps
}) => (
  <Popover className={`SearchStageListPopover ${className}`} {...popoverProps}>
    <SearchStageListMenu
      disabled={isSubmitting}
      onChange={onSelectStage}
      onDelete={onDelete}
      stageType={stageType}
      value={value}
    />
    {submitError && (
      <ErrorAlert error={submitError} title='Unable to move candidate:' />
    )}
    {isSubmitting && <LoadingIndicator />}
  </Popover>
);

SearchStageListPopover.propTypes = {
  className: PropTypes.string,

  /**
   * True when currently submitting the stage change.
   */
  isSubmitting: PropTypes.bool.isRequired,

  /**
   * Called when the "Remove" option is selected.
   * The presence of this prop controls whether the "Remove" option is shown
   * or not.
   */
  onDelete: PropTypes.func,

  /**
   * Called when a new stage is selected.
   */
  onSelectStage: PropTypes.func.isRequired,

  /**
   * The stage type to show the stage list for.
   */
  stageType: PropTypes.oneOf(STAGE_TYPES).isRequired,

  /**
   * If a submit error occurs, it will be here.
   * @type {Error}
   */
  submitError: PropTypes.object, // eslint-disable-line react/forbid-prop-types

  /**
   * The currently "selected" stage ID.
   */
  value: PropTypes.number,
};

export default SearchStageListPopover;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

const RejectionReasonsList = ({ rejectionReasons }) => (
  <ul className='line-item-list RejectionReasonsList'>
    {rejectionReasons &&
      rejectionReasons
        .map(rejectionReason => (
          <li key={rejectionReason.get('reason')}>
            <span className='description'>{rejectionReason.get('reason')}</span>
            <span className='value'>{rejectionReason.get('count')}</span>
          </li>
        ))
        .toArray()}
  </ul>
);

RejectionReasonsList.propTypes = {
  rejectionReasons: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      reason: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
};

export default RejectionReasonsList;

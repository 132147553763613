import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@thrivetrm/ui/components/Card';
import Rating from '@thrivetrm/ui/components/Rating';
import Tag from 'modules/core/components/Tag';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import Menu from '@thrivetrm/ui/components/Menu';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import AssessmentCriteriaStarRatingDefinitions from './AssessmentCriteriaStarRatingDefinitions';

const CriteriaLabels = {
  CUSTOM: 'Custom Criteria',
  RECOMMENDED: 'Recommended Criteria',
};
const CriteriaTag = ({ label }) => {
  if (label === CriteriaLabels.CUSTOM) {
    <Tooltip
      content='Custom criteria are created at the search level and only available to use for that search. If removed with X, they are deleted forever.'
      size='large'
    >
      <Tag className='u-marginVertical-n' name={CriteriaLabels.CUSTOM} />
    </Tooltip>;
  }
  return <Tag className='u-marginVertical-n' name={label} />;
};
CriteriaTag.propTypes = {
  label: PropTypes.oneOf(Object.values(CriteriaLabels)).isRequired,
};

const CriteriaMenu = ({
  criteriaName,
  dependentAssessmentsCount,
  isCriteriaLocked,
  onEdit,
  onRemove,
}) => {
  const [isDialogOpen, openDialog, closeDialog] = useToggle(false);
  const menuHasOptions = onRemove || onEdit;

  return menuHasOptions ? (
    <>
      <Menu
        button={
          <ButtonSecondary
            aria-label='Criteria Options'
            icon='options'
            size='small'
          />
        }
        className='AssessmentCriteriaCard__criteriaMenu'
        isPinnedRight={true}
      >
        {onEdit ? (
          <Menu.Item icon='edit' onClick={onEdit}>
            Edit
          </Menu.Item>
        ) : null}
        {onRemove ? (
          <Menu.Item
            icon='delete'
            onClick={isCriteriaLocked ? openDialog : onRemove}
          >
            Remove
          </Menu.Item>
        ) : null}
      </Menu>
      <ConfirmationDialog
        cancelLabel='No, Keep Criteria'
        confirmLabel='Yes, Remove Criteria'
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onConfirm={onRemove}
        title='Are you sure?'
      >
        <div>
          This <b>{criteriaName}</b> criteria has been used on{' '}
          <b>{dependentAssessmentsCount}</b> candidate assessments. If you
          delete it, that data will be removed from those candidate assessments
          and will not be recoverable.
        </div>
        <br />
        <div>Do you still want to delete it?</div>
      </ConfirmationDialog>
    </>
  ) : null;
};

CriteriaMenu.defaultProps = {
  isCriteriaLocked: false,
};
CriteriaMenu.propTypes = {
  criteriaName: PropTypes.string,
  dependentAssessmentsCount: PropTypes.number,
  isCriteriaLocked: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

const AssessmentCriteriaCard = ({
  className,
  criteriaName,
  dependentAssessmentsCount,
  description,
  isCriteriaLocked,
  label,
  notes,
  notesInput,
  onEdit,
  onRatingChange,
  onRemove,
  rating,
  ratingDefinitions,
  ratingInput,
}) => {
  const renderNotes = () => {
    if (notesInput || notes) {
      return (
        notesInput || (
          <>
            <h4 className='u-fontWeight-bold u-marginTop-16 u-marginBottom-4'>
              Notes
            </h4>
            <HtmlText className='u-margin-n' htmlString={notes} />
          </>
        )
      );
    }
    return null;
  };

  /**
   * Make sure to only show definitions if AT LEAST ONE exists.  Previously the backend did not
   * return an array of rating definitions if none were defined.  This caused problems when
   * implementing edit functionality so we now need to make sure that at least one definition
   * is defined
   */
  const definitionsExist = ratingDefinitions?.some(
    definition => definition.rating_definition,
  );

  return (
    <Card
      className={classNames('AssessmentCriteriaCard', className)}
      isCentered={false}
    >
      <div className='u-flex u-flexJustify-spaceBetween u-paddingVertical-8'>
        <h3 className='AssessmentCriteriaCard__criteriaName'>{criteriaName}</h3>
        <div className='AssessmentCriteriaCard__pillAndRemoveContainer'>
          {label ? <CriteriaTag label={label} /> : null}
          <CriteriaMenu
            criteriaName={criteriaName}
            dependentAssessmentsCount={dependentAssessmentsCount}
            isCriteriaLocked={isCriteriaLocked}
            onEdit={onEdit}
            onRemove={onRemove}
          />
        </div>
      </div>
      <div className='u-inlineBlock'>
        {definitionsExist ? (
          <AssessmentCriteriaStarRatingDefinitions
            definitions={ratingDefinitions}
            rating={rating}
            ratingInput={ratingInput}
          />
        ) : (
          ratingInput || (
            <Rating
              maxRating={ratingDefinitions?.length}
              onChange={onRatingChange}
              value={rating}
            />
          )
        )}
      </div>
      {description || notes || notesInput ? (
        <hr className='u-marginVertical-16 u-border-n u-borderBottom' />
      ) : null}
      {description ? (
        <>
          <h4 className='u-fontWeight-bold u-margin-n u-marginBottom-4'>
            Description
          </h4>
          <p className='u-margin-n u-marginBottom-8'>{description}</p>
        </>
      ) : null}
      {renderNotes()}
    </Card>
  );
};

AssessmentCriteriaCard.defaultProps = {
  ...CriteriaMenu.defaultProps,
  criteriaName: null,
  description: null,
  label: null,
  notes: null,
  notesInput: null,
  rating: 0,
  ratingDefinitions: null,
  ratingInput: null,
};

AssessmentCriteriaCard.propTypes = {
  ...CriteriaMenu.propTypes,
  className: PropTypes.string,
  criteriaName: PropTypes.string,
  dependentAssessmentsCount: PropTypes.number,
  description: PropTypes.string,
  label: PropTypes.string,
  notes: PropTypes.string,
  notesInput: PropTypes.node,
  onRatingChange: PropTypes.func,
  rating: PropTypes.number,
  ratingDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      rating_definition: PropTypes.string,
      rating_value: PropTypes.number,
    }),
  ),
  ratingInput: PropTypes.node,
};

export default AssessmentCriteriaCard;

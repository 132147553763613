import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import jobFunctionSchema from '../schemas/jobFunctions';

/**
 * A tag select component for choosing from the list of job functions (aka "Roles")
 */

const JobFunctionTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField schema={jobFunctionSchema} {...props} />
);

JobFunctionTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default JobFunctionTagSelectField;

import PropTypes from 'prop-types';
import Currency from '@thrivetrm/ui/components/Currency';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import mapInvestmentIdToProperty from './mapInvestmentIdToProperty';

/**
 * Renders the amount of an investment from it's ID in a currency format, or renders null
 * if there is currently no value.
 */
export default compose(
  setDisplayName('InvestmentAmount'),
  setPropTypes({
    investmentId: PropTypes.number.isRequired,
  }),
  mapInvestmentIdToProperty('amount', 'value'),
  branch(({ value }) => typeof value !== 'number', renderNothing),

  // Don't pass investmentId down into the Currency component.
  mapProps(({ investmentId: _, ...props }) => props),
)(Currency);

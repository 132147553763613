import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Helmet } from 'react-helmet';
import * as companyActionCreators from 'modules/companies/actions';
import PageHeader from 'modules/core/components/PageHeader';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import clearTransactionAction from 'modules/transactions/actions/clearTransaction';
import * as reportsActionCreators from '../../../actions/reports';
import * as contactsActionCreators from '../../../actions/contacts';
import ReportsIndex from '../../../components/report/ReportsIndex';

const INTRODUCTION_REPORT = 'Introduction Report';

const ReportsPage = ({
  clearTransaction,
  companies,
  companiesActions,
  contacts,
  contactsActions,
  currentUserEmail,
  reportList,
  reportsActions,
  transactions,
}) => (
  <div className='container u-paddingTop-24 reports-index'>
    <ReportsIndex
      clearTransaction={clearTransaction}
      companies={companies}
      companiesActions={companiesActions}
      contacts={contacts}
      contactsActions={contactsActions}
      currentUserEmail={currentUserEmail}
      reportList={reportList}
      reportsActions={reportsActions}
      transactions={transactions}
    />
  </div>
);

ReportsPage.propTypes = {
  clearTransaction: PropTypes.func,

  companies: ImmutablePropTypes.mapContains({
    queriesById: ImmutablePropTypes.map,
  }),

  companiesActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }).isRequired,

  contacts: ImmutablePropTypes.mapContains({
    queriesById: ImmutablePropTypes.map,
  }),

  contactsActions: PropTypes.shape({
    createContactsQuery: PropTypes.func.isRequired,
    destroyContactsQuery: PropTypes.func.isRequired,
    queryContacts: PropTypes.func.isRequired,
  }).isRequired,

  currentUserEmail: PropTypes.string.isRequired,

  reportList: PropTypes.arrayOf(PropTypes.string),

  reportsActions: PropTypes.shape({
    createIntroductionsReport: PropTypes.func.isRequired,
  }).isRequired,

  transactions: transactionsState,
};

const mapStateToProps = state => {
  const reportList = [];
  const currentUserEmail = state.user.get('email');
  const userIsLimitedAccess = state.user.get('limited_access_roles');
  const userIsClientOrCandidate =
    state.user.get('client') || state.user.get('candidate');
  if (!(userIsLimitedAccess || userIsClientOrCandidate)) {
    reportList.push(INTRODUCTION_REPORT);
  }

  return {
    contacts: state.contacts,
    companies: state.companies,
    currentUserEmail: currentUserEmail,
    reportList: reportList,
    transactions: state.transactions,
  };
};

const mapDispatchToProps = dispatch => ({
  clearTransaction: bindActionCreators(clearTransactionAction, dispatch),
  contactsActions: bindActionCreators(contactsActionCreators, dispatch),
  companiesActions: bindActionCreators(companyActionCreators, dispatch),
  reportsActions: bindActionCreators(reportsActionCreators, dispatch),
});

const WrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsPage);

export default props => (
  <div>
    <Helmet>
      <title>Reports</title>
    </Helmet>
    <PageHeader title='Reports' />
    <WrappedComponent {...props} />
  </div>
);

import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import languageSchema from '../schemas/languages';

/**
 * A tag select component for choosing tags from the list of languages.
 */

const LanguageTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField {...props} schema={languageSchema} />
);

LanguageTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default LanguageTagSelectField;

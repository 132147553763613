import { convertToCamelCase } from 'modules/core/jsonUtils';
import apiV1 from './index';
import queryTags from './queryTags';

const { OFFERS } = queryTags;

const offers = apiV1.injectEndpoints({
  endpoints: builder => ({
    getOffers: builder.query({
      query: searchId => ({
        url: `searches/${searchId}/offers`,
      }),
      transformResponse: response => {
        const { offers: offerList } = response;
        return convertToCamelCase(offerList);
      },
      providesTags: [OFFERS],
    }),
    getOffer: builder.query({
      query: ({ offerId, searchId }) => ({
        url: `searches/${searchId}/offers/${offerId}`,
      }),
      providesTags: [OFFERS],
      transformResponse: response => {
        const { offer: offerData } = response;
        return convertToCamelCase(offerData);
      },
    }),
    updateOffer: builder.mutation({
      query: ({ offerId, payload, searchId }) => ({
        url: `searches/${searchId}/offers/${offerId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [OFFERS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOfferQuery,
  useGetOffersQuery,
  useUpdateOfferMutation,
} = offers;

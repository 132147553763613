import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import withUserActions from './withUserActions';
import shouldFetchUserOptionsSelector from '../selectors/shouldFetchUserOptions';
import isFetchingUserOptions from '../selectors/isFetchingUserOptions';

/**
 * A higher order component that fetches user options if they are not already fetched.
 */
export default compose(
  withUserActions,
  connect(
    state => ({
      shouldFetchUserOptions: shouldFetchUserOptionsSelector(state),
      isFetchingUserOptions: isFetchingUserOptions(state),
    }),
    {},
  ),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const { shouldFetchUserOptions, userActions } = this.props;
      if (shouldFetchUserOptions) {
        userActions.fetchUserOptions();
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const { shouldFetchUserOptions, userActions } = nextProps;
      if (shouldFetchUserOptions) {
        userActions.fetchUserOptions();
      }
    },
  }),
);

import PropTypes from 'prop-types';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import EntityPropertyList from 'modules/entities/components/EntityPropertyList';
import industrySchema from 'modules/tenant/schemas/industries';
import mapCompanyIdToCompany from '../mapCompanyIdToCompany';

/**
 * Renders a list of the industries related to a company
 */
export default compose(
  setDisplayName('CompanyIndustryNames'),
  setPropTypes({
    /**
     * The ID of the company to show the industries for.
     */
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
  mapProps(({ company }) => ({
    entityIds: company && company.get('industries'),
    entityType: industrySchema.key,
  })),
  branch(({ entityIds }) => !entityIds, renderNothing),
)(EntityPropertyList);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Helmet } from 'react-helmet';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import PageHeader from 'modules/core/components/PageHeader';
import NotificationsScrollList from './NotificationsScrollList';

/**
 * A component that displays an icon and, when there are unread messages,
 * displays a bade indicating the number unread. When the icon is clicked,
 * a popover is displayed showing the unread messages.
 */
class NotificationsPage extends Component {
  UNSAFE_componentWillMount() {
    const { fetchNotifications, initialCount, notifications } = this.props;

    if (
      typeof initialCount !== 'number' &&
      !notifications.getIn(['meta', 'isFetching'])
    ) {
      // If we weren't supplied with the number of unread notifications as a
      // property, we'll need to actually fetch the notifications, which will
      // give us the unread count.
      fetchNotifications({
        category: notifications.getIn(['meta', 'category']),
      });
    }
  }

  /**
   * Handles a request to getch notifications
   */
  handleFetchNotifications = () => {
    const { fetchNotificationsIfNeeded, notifications } = this.props;
    fetchNotificationsIfNeeded(notifications);
  };

  /**
   * Handles a request to refresh (completely reload) notifications
   */
  handleRefreshNotifications = () => {
    const { fetchNotifications, notifications } = this.props;
    fetchNotifications({ category: notifications.getIn(['meta', 'category']) });
  };

  /**
   * Handles a request to fetch the next page of notifications.
   */
  handleFetchNextPage = () => {
    const { fetchNextPageIfHasMore, notifications } = this.props;
    fetchNextPageIfHasMore(notifications);
  };

  /**
   * Handles a request to fetch any new notifications that may have
   * come in since the last fetch.
   */
  handleFetchLatest = () => {
    const { fetchLatest, notifications } = this.props;
    fetchLatest(notifications);
  };

  handleUpdateAllRead = () => {
    const { notifications, updateSeenAsRead } = this.props;
    updateSeenAsRead(notifications);
  };

  handleUpdateRead = (notification, toRead) => {
    const { updateRead } = this.props;
    updateRead(notification.getIn(['data', 'id']), toRead);
  };

  render() {
    const { doNotificationAction, notifications } = this.props;

    const isFetching = notifications.getIn(['meta', 'isFetching']);
    const error = notifications.getIn(['meta', 'error']);

    return (
      <div className='notifications'>
        <Helmet>
          <title>Notifications</title>
        </Helmet>
        <PageHeader title='Notifications' />
        <div className='container u-paddingTop-16'>
          <p>
            <button
              className='btn btn-link'
              onClick={this.handleUpdateAllRead}
              type='button'
            >
              Mark all as read
            </button>
          </p>
          {error && !isFetching ? (
            <ErrorAlert
              onRetry={this.handleRefreshNotifications}
              title='There was an error fetching notifications.'
            />
          ) : null}
          <NotificationsScrollList
            actionPosition='bottom'
            key='list'
            notifications={notifications}
            onFetchNextPage={this.handleFetchNextPage}
            onNotificationAction={doNotificationAction}
            onUpdateRead={this.handleUpdateRead}
          />
        </div>
      </div>
    );
  }
}

NotificationsPage.propTypes = {
  doNotificationAction: PropTypes.func.isRequired,

  fetchLatest: PropTypes.func.isRequired,

  fetchNextPageIfHasMore: PropTypes.func.isRequired,

  fetchNotifications: PropTypes.func.isRequired,

  fetchNotificationsIfNeeded: PropTypes.func.isRequired,

  /**
   * Provides the initial count of available notifications. When provided,
   * prevents us from having to make an initial ajax request to get the count.
   */
  initialCount: PropTypes.number,

  notifications: ImmutablePropTypes.mapContains({
    meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
      isFetchingNew: PropTypes.bool,
    }),
  }),

  updateRead: PropTypes.func.isRequired,

  updateSeenAsRead: PropTypes.func.isRequired,
};

export default NotificationsPage;

import PropTypes from 'prop-types';
import { List } from 'immutable';
import React from 'react';
import {
  branch,
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';

import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import { SORT_BY_VALUES, VIEW_MODE_GRID, VIEW_MODE_TABLE } from '../constants';
import withTargetCompanyListFetched from './withTargetCompanyListFetched';
import TargetCompanyGrid from './gridView/TargetCompanyGrid';
import TargetCompanyTable from './tableView/TargetCompanyTable';
import filteredByPriorityOnly from './filteredByPriorityOnly';
import filteredByText from './filteredByText';
import withTargetCompanyIdsSorted from './withTargetCompanyIdsSorted';

export const TargetCompaniesView = ({
  cardsPerRow,
  fetchError,
  fetchTargetCompanyList,
  isFetching,
  onSortChange,
  onTargetCompanySelect,
  orphanCandidatesCount,
  sortAscending,
  sortBy,
  targetCompanyIds,
  viewMode,
}) => (
  <div className='target-companies--target-companies-view'>
    {!isFetching && fetchError && (
      <ErrorAlert
        onRetry={fetchTargetCompanyList}
        title='There was an error fetching target companies'
      />
    )}
    {(orphanCandidatesCount || targetCompanyIds) &&
      viewMode === VIEW_MODE_GRID && (
        <TargetCompanyGrid
          cardsPerRow={cardsPerRow}
          onTargetCompanySelect={onTargetCompanySelect}
          orphanCount={orphanCandidatesCount}
          targetCompanyIds={targetCompanyIds || new List()}
        />
      )}
    {(orphanCandidatesCount || targetCompanyIds) &&
      viewMode === VIEW_MODE_TABLE && (
        <TargetCompanyTable
          onSortChange={onSortChange}
          onTargetCompanySelect={onTargetCompanySelect}
          orphanCount={orphanCandidatesCount}
          sortAscending={sortAscending}
          sortBy={sortBy}
          targetCompanyIds={targetCompanyIds || new List()}
        />
      )}
    {isFetching && (
      <LoadingIndicator message='Loading target companies' size={64} />
    )}
  </div>
);

TargetCompaniesView.propTypes = {
  cardsPerRow: PropTypes.number,
  fetchError: PropTypes.shape({
    message: PropTypes.string,
  }),
  fetchTargetCompanyList: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  onSortChange: PropTypes.func.isRequired,
  onTargetCompanySelect: PropTypes.func.isRequired,
  orphanCandidatesCount: PropTypes.number,
  sortAscending: PropTypes.bool,
  sortBy: PropTypes.oneOf(SORT_BY_VALUES),
  targetCompanyIds: ImmutablePropTypes.listOf(PropTypes.number),
  viewMode: PropTypes.oneOf([VIEW_MODE_TABLE, VIEW_MODE_GRID]).isRequired,
};

TargetCompaniesView.defaultProps = {
  cardsPerRow: 3,
  isFetching: false,
  sortAscending: true,
};

export default compose(
  setDisplayName('TargetCopnmaniesView(enhanced)'),
  setPropTypes({
    cardsPerRow: PropTypes.number.isRequired,
    filterText: PropTypes.string,
    isPriorityOnly: PropTypes.bool.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onTargetCompanySelect: PropTypes.func.isRequired,
    searchId: PropTypes.number,
    sortAscending: PropTypes.bool.isRequired,
    sortBy: PropTypes.oneOf(SORT_BY_VALUES),
    viewMode: PropTypes.oneOf([VIEW_MODE_TABLE, VIEW_MODE_GRID]).isRequired,
  }),
  withTargetCompanyListFetched,
  withProps(({ targetCompanyList }) => ({
    isFetching:
      targetCompanyList && targetCompanyList.getIn(['_meta', 'isFetching']),
    fetchError:
      targetCompanyList && targetCompanyList.getIn(['_meta', 'error']),
    targetCompanyIds: targetCompanyList && targetCompanyList.get('ids'),
    orphanCandidatesCount:
      targetCompanyList && targetCompanyList.get('orphanCandidatesCount'),
  })),
  branch(({ isPriorityOnly }) => isPriorityOnly, filteredByPriorityOnly),
  filteredByText,
  withTargetCompanyIdsSorted,
)(TargetCompaniesView);

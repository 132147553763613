import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import mapContactIdToContact from '../mapContactIdToContact';

/**
 * Renders a single item for the DirectReportsList component, which simply renders the
 * contact's name.
 */
const DirectReportsListItem = ({ contact }) => (
  <li className='DirectReportsListItem list-group-item'>
    {contact.get('name') || contact.get('full_name')}
  </li>
);

DirectReportsListItem.propTypes = {
  /**
   * The contact record.
   */
  contact: ImmutablePropTypes.mapContains({
    name: PropTypes.string,
    full_name: PropTypes.string,
  }).isRequired,
};

export default compose(
  setDisplayName('connect(DirectReportsListItem)'),
  setPropTypes({
    /**
     * The ID of the direct report
     */
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
)(DirectReportsListItem);

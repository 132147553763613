import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import mapAssessmentIdToAssessment from 'modules/assessments/components/mapAssessmentIdToAssessment';
import getName from 'modules/contacts/selectors/contacts/getName';
import SearchTitle from 'modules/searches/components/SearchTitle';
import getCandidacyFullName from '../../selectors/getCandidacyFullName';
import AssessmentView from './AssessmentView';

/**
 * Displays information about an assessment, but renders it in a way that it looks similar to
 * our "notifications"/"activity timeline" layout. We have to do this for the time being
 * because the notifications code has not been reworked into a module and isn't reusable without
 * a pretty large effort - so for now this works.
 */
const FakeCandidacyAssessmentNotificationView = ({ assessment, fullName }) => (
  <div className='notification-item notification-item--type-assessment'>
    <div className='notification-icon' key='icon'>
      <i className='fa fa-fw fa-2x fa-star-half-o' />
    </div>
    <div className='notification-content' key='content'>
      <div
        className='notification-heading list-group-item-heading'
        key='heading'
      >
        <h5 className='notification-title'>
          {`${fullName} was assessed `}
          {assessment.get('added_by') && `by ${assessment.get('added_by')}`}
          {assessment.get('assessed_by_name')
            ? `by ${assessment.get('assessed_by_name')}`
            : null}
        </h5>
        <div className='notification-related-to-searches'>
          <h6>
            <span>Related to: </span>
            <a href={`/searches/${assessment.get('search_id')}`}>
              <SearchTitle
                jobTitle={assessment.get('job_title')}
                searchId={assessment.get('search_id')}
              />
            </a>
          </h6>
        </div>
        <AssessmentView assessment={assessment} />
      </div>
    </div>
  </div>
);

FakeCandidacyAssessmentNotificationView.propTypes = {
  assessment: ImmutablePropTypes.mapContains({
    added_by: PropTypes.string,
    candidacy_id: PropTypes.number.isRequired,
  }),

  fullName: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('FakeCandidacyAssessmentNotificationView(enhanced)'),
  setPropTypes({
    assessmentId: PropTypes.number.isRequired,
  }),
  mapAssessmentIdToAssessment,
  // Use the contactId prop to get name (if in props) until contacts are fully normalized.
  connect(
    (state, { assessment, contactId }) => ({
      fullName:
        getName(state, contactId) ||
        getCandidacyFullName(state, assessment.get('candidacy_id')),
    }),
    {},
  ),
)(FakeCandidacyAssessmentNotificationView);

import createEntity from 'modules/entities/actions/createEntity';
import routes from 'modules/routing/routes';
import userSchema from 'modules/users/schema';

/**
 * An action for creating an invite
 * @param {Object} payload
 * @param {Object} payload.invite The invite record to create
 */
export default ({ contactId, user, ...rest }) => dispatch =>
  dispatch(
    createEntity({
      ...rest,
      body: {
        user: user,
      },
      entityType: userSchema.key,
      responseSchema: { user: userSchema },
      url: routes.api_v1_contact_users({ contactId: contactId }),
    }),
  ).then(action => action);

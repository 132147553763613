import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import isJobApplicationRejected from '../selectors/isJobApplicationRejected';

/**
 * A Higher order component that sorts a list of jobApplicationIds in order of the applicant's
 * email address.
 */
export default compose(
  setDisplayName('withJobApplicationIdsRejectedLast'),
  connect(
    (state, { jobApplicationIds }) => ({
      jobApplicationIds:
        jobApplicationIds &&
        jobApplicationIds
          .groupBy(id => (isJobApplicationRejected(state, id) ? 1 : 0))
          .sortBy((_, group) => group)
          .reduce((reduction, value) => reduction.concat(value)),
    }),
    {},
  ),
);

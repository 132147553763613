import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../../actions/ratings';

/**
 * A higher order component that connects the contact module
 * actions and provides them as a `ratingActions` prop.
 */
export default connect(null, dispatch => ({
  ratingActions: bindActionCreators(actions, dispatch),
}));

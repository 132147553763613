import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setPropTypes } from 'recompose';
import UserName from 'modules/users/components/UserName';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import QuestionAnswersModalButton from '../../question-answers/components/QuestionAnswersModalButton';
import getEntityProperty from '../../entities/selectors/getEntityProperty';
import mapInterviewIdIdToInterview from './mapInterviewIdToInterview';
import InterviewTitle from './InterviewTitle';
import interviewQuestionSetSchema from '../../tenant/schemas/interviewQuestionSets';

/**
 * Renders the view mode of a interview list item.
 */
export const InterviewView = ({
  interview,
  renderCandidateName,
  renderCreatedBy,
  renderDescription,
  renderInterviewEditLink,
  renderSubject,
  renderTitle,
  renderTitleAsLink,
  renderWhere,
}) => {
  const questionName = `Interview Template - ${interview.get(
    'question_set_name',
  )}`;
  // if we cannot locate the questionSet in entities state, that means it has been destroyed.
  // It it still associated with this interview, however, and thus we use this boolean to
  // determine whether or not to append "(Discontinued)" to the question set name.
  const isDiscontinued = !useSelector(state =>
    getEntityProperty(
      state,
      interviewQuestionSetSchema.key,
      interview.get('question_set'),
      'name',
    ),
  );
  const questionSetTitle = isDiscontinued
    ? `${questionName} (Discontinued)`
    : questionName;
  const renderQuestionAnswersModalButton =
    renderInterviewEditLink &&
    (interview.get('question_set_name') || interview.get('has_answer_content'));

  return (
    <div className='InterviewView'>
      {renderTitle && (
        <InterviewTitle
          interview={interview}
          renderAsLink={renderTitleAsLink}
          renderCandidateName={renderCandidateName}
        />
      )}
      {renderCreatedBy && (
        <div className='interviews--organized-by'>
          Organized by: <UserName userId={interview.getIn(['created_by'])} />
        </div>
      )}
      {renderSubject && interview.get('subject') && (
        <div>
          <strong>Event Title:</strong> {interview.get('subject')}
        </div>
      )}
      {renderWhere && interview.get('location') && (
        <div className='interviews--where'>
          <strong>Location:</strong> {interview.get('location')}
        </div>
      )}
      {renderDescription && interview.get('description') ? (
        <ExpandableText
          characterLimit={150}
          content={interview.get('description')}
          isSanitizedHtml={true}
        />
      ) : null}
      {renderQuestionAnswersModalButton && (
        <QuestionAnswersModalButton
          parentId={interview.get('id')}
          parentType='interview'
          title={questionSetTitle}
        />
      )}
    </div>
  );
};

InterviewView.propTypes = {
  interview: ImmutablePropTypes.mapContains({
    created_by: PropTypes.number,
    description: PropTypes.string,
    location: PropTypes.string,
    subject: PropTypes.string,
    question_set: PropTypes.number,
  }).isRequired,

  /**
   * When true, the cadidates name will be rendered in the title; otherwise it will be omitted.
   */
  renderCandidateName: PropTypes.bool,
  renderDescription: PropTypes.bool,
  renderInterviewEditLink: PropTypes.bool,
  renderSubject: PropTypes.bool,

  /**
   * When true, the title portion of the interview display will be rendered as a link to the
   * page displaying the candidacy's interview tab.
   */
  renderTitleAsLink: PropTypes.bool,
  renderWhere: PropTypes.bool,
};

InterviewView.defaultProps = {
  renderCandidateName: false,
  renderDescription: false,
  renderInterviewEditLink: false,
  renderSubject: false,
  renderTitleAsLink: false,
  renderWhere: false,
};

/**
 * The default export requires only a `interviewId`, while
 * the named export requires the interview record.
 */
export default compose(
  setPropTypes({
    interviewId: PropTypes.number.isRequired,
    showNotes: InterviewView.propTypes.showNotes,
  }),
  mapInterviewIdIdToInterview,
)(InterviewView);

import { connect } from 'react-redux';
import getInterview from '../selectors/getInterview';

/**
 * A higher order component which maps a collection `interviewIds` to their interview records.
 */
export default connect(
  (state, { interviewIds }) => ({
    interviews: interviewIds && interviewIds.map(id => getInterview(state, id)),
  }),
  {},
);

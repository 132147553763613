import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BottomPanel from '@thrivetrm/ui/components/BottomPanel';
import ButtonDestroy from '@thrivetrm/ui/components/ButtonDestroy';
import isAdmin from 'modules/auth/selectors/isAdmin';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import { useApiDelete } from 'modules/core/hooks/useApi';
import PluralText from '@thrivetrm/ui/components/PluralText';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import isCrmUser from 'modules/auth/selectors/isCrmUser';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import { useGetNetworksQuery } from 'services/apiV1/networks';
import AddToSearchModal from './AddToSearchModal';
import AddToNetworkModal from './AddToNetworkModal';
import AddToPipelineModal from './AddToPipelineModal';
import BulkEditTagModal from './BulkEditTagModal';
import { removeRecordsFromNetwork } from '../addToNetworkSlice';
import { BulkActionTypes } from '../constants';
import {
  ALL_RECORDS_NETWORK_ID,
  requestRecordList,
  setCurrentPage,
  setSelectedRecords,
  resetRecordId,
} from '../recordIndexSlice';
import './RecordIndexBulkActions.scss';

const {
  ADD_TO_NETWORK,
  ADD_TO_PIPELINE,
  ADD_TO_SEARCH,
  BULK_EDIT_TAGS,
  DELETE,
} = BulkActionTypes;

const RecordIndexBulkActions = () => {
  const dispatch = useDispatch();

  const {
    currentPage,
    endpoints,
    recordLabel,
    recordLabelPlural,
    recordList,
    savedView,
    selectedRecordIds,
    supportedBulkActions,
    totalResults,
  } = useSelector(state => state.recordIndex);

  const networkId = savedView?.networkId || ALL_RECORDS_NETWORK_ID;
  const isAdminUser = useSelector(isAdmin);
  const crmUser = useSelector(isCrmUser);
  const hasBulkTaggingFeature = useFeatureCheck('feature.bulk_tagging');
  const hasPipelineFeature = useFeatureCheck('feature.pipeline_mvp');

  const { data: networks, refetch: refetchNetworks } = useGetNetworksQuery(
    recordLabel,
  );
  const networkOptions = [
    ...(networks?.user_networks || []),
    ...(networks?.public_networks || []),
    ...(networks?.private_network || []),
  ];
  const networkName = networkOptions.find(network => network.id === networkId)
    ?.name;

  const [isSearchModalOpen, openSearchModal, closeSearchModal] = useToggle(
    false,
  );
  const [isNetworkModalOpen, openNetworkModal, closeNetworkModal] = useToggle(
    false,
  );
  const [
    isPipelineModalOpen,
    openPipelineModal,
    closePipelineModal,
  ] = useToggle(false);
  const [
    isBulkEditTagModalOpen,
    openBulkEditTagModal,
    closeBulkEditTagModal,
  ] = useToggle(false);
  const [
    isConfirmationDialogOpen,
    openConfirmationDialog,
    closeConfirmationDialog,
  ] = useToggle(false);

  const [
    isRemoveFromNetworkConfirmationDialogOpen,
    openRemoveFromNetworkConfirmationDialog,
    closeRemoveFromNetworkConfirmationDialog,
  ] = useToggle(false);

  const selectedRecordCount = selectedRecordIds?.length || 0;
  const formattedRecordLabel =
    selectedRecordIds?.length === 1 ? recordLabel : recordLabelPlural;

  const [deleteRecords, isDeletingRecords] = useApiDelete(
    endpoints.bulkActions(selectedRecordIds),
  );

  const handleDeleteRecords = () => {
    const hasSelectedAllRecordsOnPage =
      selectedRecordCount === recordList?.length;
    const hasSelectedAllTotalRecords = selectedRecordCount === totalResults;

    deleteRecords(null, {
      onSuccess: () => {
        // When deleting all records on the last page of results, reset the current
        // page to the penultimate page to prevent "no records found" message
        if (
          hasSelectedAllRecordsOnPage &&
          !hasSelectedAllTotalRecords &&
          currentPage > 1
        ) {
          dispatch(setCurrentPage(currentPage - 1));
        }
        dispatch(toastSuccess('Selected records successfully deleted'));
        refetchNetworks();
        dispatch(setSelectedRecords([]));
        dispatch(requestRecordList());
      },
      onError: error => {
        dispatch(toastError(error));
      },
    });
  };

  const handleAddToSearchSuccess = selectedSearch => {
    dispatch(requestRecordList());
    closeSearchModal();
    dispatch(
      toastSuccess(
        `Selected records successfully added to ${selectedSearch.label}`,
      ),
    );
    dispatch(resetRecordId());
  };

  const handleAddToNetworkSuccess = selectedNetwork => {
    closeNetworkModal();
    dispatch(
      toastSuccess(
        `Selected records successfully added to ${selectedNetwork.label}`,
      ),
    );
    refetchNetworks();
  };

  const handleAddToPipelineSuccess = ({ name }) => {
    dispatch(requestRecordList());
    closePipelineModal();
    dispatch(toastSuccess(`Selected records successfully added to ${name}`));
  };

  const handleEditTagSuccess = () => {
    dispatch(requestRecordList());
    closeBulkEditTagModal();
  };

  const handleRemoveFromNetwork = () => {
    const onSuccess = () => {
      dispatch(requestRecordList());
      closeRemoveFromNetworkConfirmationDialog();
      dispatch(
        toastSuccess(
          `Selected ${formattedRecordLabel.toLowerCase()} successfully removed from ${networkName}`,
        ),
      );
      refetchNetworks();
      dispatch(setSelectedRecords([]));
      window.location.reload(true);
    };
    dispatch(removeRecordsFromNetwork(networkId, selectedRecordIds, onSuccess));
  };

  return (
    <BottomPanel className='u-flexJustify-spaceBetween' isOpen={true}>
      <div className='RecordIndexBulkActions__section'>
        <PluralText
          case='lowerCase'
          quantity={selectedRecordCount}
          shouldIncludeQuantity={true}
          text={formattedRecordLabel}
        />{' '}
        selected
      </div>

      <div>
        {hasPipelineFeature &&
        supportedBulkActions.includes(ADD_TO_PIPELINE) &&
        !crmUser ? (
          <>
            <ButtonSecondary
              label='Add to Pipeline'
              onClick={openPipelineModal}
            />
            <AddToPipelineModal
              contactIds={selectedRecordIds ?? []}
              isOpen={isPipelineModalOpen}
              onClose={closePipelineModal}
              onSuccess={handleAddToPipelineSuccess}
            />
          </>
        ) : null}
        {supportedBulkActions.includes(ADD_TO_SEARCH) && !crmUser ? (
          <>
            <ButtonSecondary label='Add to Search' onClick={openSearchModal} />
            <AddToSearchModal
              contactIds={selectedRecordIds ?? []}
              isOpen={isSearchModalOpen}
              onClose={closeSearchModal}
              onSuccess={handleAddToSearchSuccess}
            />
          </>
        ) : null}
        {supportedBulkActions.includes(ADD_TO_NETWORK) && networkName ? (
          <>
            <ButtonSecondary
              label='Remove from Network'
              onClick={openRemoveFromNetworkConfirmationDialog}
            />
            <ConfirmationDialog
              cancelLabel='No, Keep Working'
              confirmLabel={`Yes, Remove ${formattedRecordLabel}`}
              isOpen={isRemoveFromNetworkConfirmationDialogOpen}
              onClose={closeRemoveFromNetworkConfirmationDialog}
              onConfirm={handleRemoveFromNetwork}
              title='Remove From Network?'
            >
              <div>
                Are you sure you want to remove the selected{' '}
                <PluralText
                  case='lowerCase'
                  quantity={selectedRecordCount}
                  shouldIncludeQuantity={true}
                  text={recordLabel}
                />{' '}
                from this Network?
              </div>
            </ConfirmationDialog>
          </>
        ) : null}
        {supportedBulkActions.includes(ADD_TO_NETWORK) ? (
          <>
            <ButtonSecondary
              label={networkId ? 'Add to Another Network' : 'Add to Network'}
              onClick={openNetworkModal}
            />
            <AddToNetworkModal
              currentNetworkId={networkId}
              isOpen={isNetworkModalOpen}
              onClose={closeNetworkModal}
              onSuccess={handleAddToNetworkSuccess}
              recordIds={selectedRecordIds}
            />
          </>
        ) : null}
        {hasBulkTaggingFeature &&
        supportedBulkActions.includes(BULK_EDIT_TAGS) ? (
          <>
            <ButtonSecondary label='Edit Tags' onClick={openBulkEditTagModal} />
            <BulkEditTagModal
              isOpen={isBulkEditTagModalOpen}
              onClose={closeBulkEditTagModal}
              onSuccess={handleEditTagSuccess}
              recordIds={selectedRecordIds ?? []}
            />
          </>
        ) : null}
      </div>
      <div className='u-flex u-flexJustify-r RecordIndexBulkActions__section'>
        {isAdminUser && supportedBulkActions.includes(DELETE) ? (
          <>
            <ButtonDestroy
              isLoading={isDeletingRecords}
              label={`Delete ${formattedRecordLabel}`}
              onClick={openConfirmationDialog}
            />
            <ConfirmationDialog
              cancelLabel='No, Keep Working'
              confirmLabel={`Yes, Delete ${formattedRecordLabel}`}
              isOpen={isConfirmationDialogOpen}
              onClose={closeConfirmationDialog}
              onConfirm={handleDeleteRecords}
              title={`Delete ${formattedRecordLabel}?`}
            >
              <div>
                You are about to delete{' '}
                <PluralText
                  case='lowerCase'
                  quantity={selectedRecordCount}
                  shouldIncludeQuantity={true}
                  text={recordLabel}
                />
                .
              </div>
              <div>
                <b>This cannot be undone</b>
              </div>
              <div>Are you sure you want to proceed?</div>
            </ConfirmationDialog>
          </>
        ) : null}
      </div>
    </BottomPanel>
  );
};

export default RecordIndexBulkActions;

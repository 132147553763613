// Adapted from: http://docs.appsignal.com/front-end/error-handling.html

const getTrace = (value, transform) =>
  typeof value !== 'undefined' && value !== null ? transform(value) : undefined;

const getUser = () =>
  window.thv && window.thv.user && window.thv.user.current_user_id;

export const sendError = (error, opts = {}) => {
  const data = {
    action: opts.action || '',
    message: error.message,
    name: error.name,
    backtrace: getTrace(error.stack, x => x.split('\n')),
    path: window.location.pathname,
    tags: opts.tags || {},
    environment: {
      agent: navigator.userAgent,
      platform: navigator.platform,
      vendor: navigator.vendor,
      screen_width: screen.width, // eslint-disable-line no-restricted-globals
      screen_height: screen.height, // eslint-disable-line no-restricted-globals
      user: getUser(),
    },
  };

  const xhr = new XMLHttpRequest();
  xhr.open('POST', '/appsignal_error_catcher', true);
  xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
  xhr.send(JSON.stringify(data));
};

const handleErrorWithAppsignal = (message, filename, lineno, colno, error) => {
  if (error) {
    return sendError(error);
  }

  return sendError(
    new Error('Null error raised, no exception message available'),
  );
};

export default handleErrorWithAppsignal;

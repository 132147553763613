import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  setStatic,
  withProps,
} from 'recompose';
import ReactSelect from 'react-select-legacy';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import { REVENUE_MAXIMUM_OPTIONS } from '../constants';

/**
 * A dropdown for selecting a value from a predefined list of maximum revenue values.
 */
export default compose(
  setDisplayName('RevenueMaximumSelectField'),
  setStatic('createFieldState', FieldState.create),
  setPropTypes({
    /**
     * A FieldState representing the value selected.
     */
    fieldState: fieldStatePropType.isRequired,

    /**
     * Called when the fieldState is changed.
     */
    onChange: PropTypes.func.isRequired,
  }),
  defaultProps({
    clearable: true,
    labelKey: 'label',
    placeholder: 'Select Maximum Revenue...',
    simpleValue: true,
    valueKey: 'value',
  }),
  withProps({
    options: REVENUE_MAXIMUM_OPTIONS,
  }),
  withFormGroup,
  asField(),
)(ReactSelect);

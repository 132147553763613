import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import fetchUserOptionsIfNeeded from 'modules/user/components/fetchUserOptionsIfNeeded';
import getEmailIntegrations from 'modules/user/selectors/getEmailIntegrations';
import getContact from 'modules/contacts/selectors/contacts/getContact';

const OutreachEmailFormOverlay = ({
  contactEmail,
  contactName,
  isEmailConfigured,
  isLoading,
  isOutlookAddinConfigured,
}) => {
  const getOpenOutlookPrompt = () => (
    <div className='OutreachEmailFormOverlay__overlay'>
      <div className='OutreachEmailFormOverlay__message'>
        <h2>Please Use Outlook</h2>
        <p>{contactName}</p>
        <p>{contactEmail}</p>
        <a className='btn btn-secondary' href={`mailto:${contactEmail}`}>
          Open Email
        </a>
      </div>
    </div>
  );

  const getConfigureEmailPrompt = () => (
    <div className='OutreachEmailFormOverlay__overlay'>
      <div className='OutreachEmailFormOverlay__message'>
        <h3>Connect your email account</h3>
        <p>
          Your email account has not been connected. You must enable it before
          sending emails from Thrive TRM.
        </p>
        <a className='btn btn-secondary' href='/users/integrations/email'>
          Enable
        </a>
      </div>
    </div>
  );

  const getLoadingContent = () => (
    <div className='OutreachEmailFormOverlay__overlay'>
      <LoadingIndicator />
    </div>
  );

  const getOverlayContent = () => {
    if (isLoading) {
      return getLoadingContent();
    }
    if (isOutlookAddinConfigured) {
      return getOpenOutlookPrompt();
    }
    if (!isEmailConfigured) {
      return getConfigureEmailPrompt();
    }
    return null;
  };

  return getOverlayContent();
};

OutreachEmailFormOverlay.propTypes = {
  contactEmail: PropTypes.string,
  contactName: PropTypes.string,
  isEmailConfigured: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOutlookAddinConfigured: PropTypes.bool,
};

export default compose(
  setDisplayName('OutreachEmailFormOverlay(enhanced)'),
  fetchUserOptionsIfNeeded,
  connect(
    (state, { contactId, isFetchingUserOptions, shouldFetchUserOptions }) => {
      const emailIntegrations = getEmailIntegrations(state) || [];
      const isEmailConfigured = emailIntegrations.some(
        item => item.get('permitted') && item.get('integrated'),
      );
      const isLoading = isFetchingUserOptions || shouldFetchUserOptions;
      const isOutlookAddinConfigured = state.user.get(
        'outlook_addin_configured',
      );
      const selectedContactId =
        contactId || state.contacts.getIn(['selectedContact', 'id']);
      const contact = getContact(state, selectedContactId);
      return {
        contactEmail: contact.get('email'),
        contactName: contact.get('full_name'),
        isEmailConfigured: isEmailConfigured,
        isLoading: isLoading,
        isOutlookAddinConfigured: isOutlookAddinConfigured,
      };
    },
  ),
  withPropsRemoved(
    'emailIntegration',
    'isFetchingUserOptions',
    'shouldFetchUserOptions',
    'userActions',
  ),
)(OutreachEmailFormOverlay);

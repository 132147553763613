import React from 'react';
import { compose, setDisplayName, setStatic } from 'recompose';
import RadioSelectField from 'modules/forms/components/RadioSelectField';
import hideIfFeatureNotEnabled from 'modules/auth/components/hideIfFeatureNotEnabled';
import { FORMAT_PDF, FORMAT_DOCX } from './constants';

/**
 * A field for selecting the format (pdf vs docx) for the search summary report.
 */

const SearchSummaryReportFormatField = ({ ...props }) => {
  const options = [
    {
      label: (
        <div>
          <span>PDF </span>
          <i className='fa fa-file-pdf-o' />
        </div>
      ),
      value: FORMAT_PDF,
    },
    {
      label: (
        <div>
          <span>DOCX (Word) </span>
          <i className='fa fa-file-word-o' />
        </div>
      ),
      value: FORMAT_DOCX,
    },
  ];

  return (
    <div className='SearchSummaryReportFormatField'>
      <h3>Format</h3>
      <RadioSelectField {...props} options={options} />
    </div>
  );
};

SearchSummaryReportFormatField.createFieldState = (
  name = 'format',
  value = FORMAT_PDF,
  ...rest
) => RadioSelectField.createFieldState(name, value, ...rest);

export default compose(
  setDisplayName('SearchSummaryReportFormatField(enhanced)'),
  setStatic(
    'createFieldState',
    SearchSummaryReportFormatField.createFieldState,
  ),
  hideIfFeatureNotEnabled('feature.docx'),
)(SearchSummaryReportFormatField);

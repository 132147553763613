import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import withFormToggleButton from '../../../components/forms/withFormToggleButton';

import withNoteListFetched from './withNoteListFetched';
import mapNoteIdsToNotes from './mapNoteIdsToNotes';
import NoteList from './NoteList';
import NoteForm from './NoteForm';
import { PARENT_TYPES } from '../constants';

const AddNoteButton = withFormToggleButton(NoteForm);

const NotesPanel = ({
  allowCreate,
  createButtonLabel,
  noteList,
  notes,
  parentId,
  parentType,
  title,
}) => {
  const pinnedNoteId = notes?.toJSON().find(note => note.pinned)?.id;

  return (
    <div className='notes--notes-panel' data-testid='notes panel'>
      {title && <h2>{title}</h2>}
      {/* Need to add a blank `btnClassName` to prevent `btn-secondary` from being applied to it */}
      {allowCreate && (
        <AddNoteButton
          btnClassName=''
          btnIcon='add'
          btnLabel='Add Note'
          className='u-flex u-flexJustify-r'
          draftStoragePath={{
            search: parentId,
            note: 'new',
          }}
          parentId={parentId}
          parentType={parentType}
          pinnedNoteId={pinnedNoteId}
          shouldRenderV4Button={true}
        >
          {createButtonLabel}
        </AddNoteButton>
      )}
      {notes && (
        <NoteList
          notes={notes}
          parentType={parentType}
          pinnedNoteId={pinnedNoteId}
          renderRelatedSearches={false}
          searchId={parentId}
        />
      )}
      {noteList && noteList.getIn(['_meta', 'isFetching']) && (
        <LoadingIndicator />
      )}
    </div>
  );
};

NotesPanel.propTypes = {
  allowCreate: PropTypes.bool,
  createButtonLabel: PropTypes.node,
  noteList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),
  notes: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),
  ),
  parentId: PropTypes.number.isRequired,
  parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,
  title: PropTypes.node,
};

NotesPanel.defaultProps = {
  allowCreate: true,
  createButtonLabel: <i className='fa fa-plus-circle' />,
};

export default compose(
  setDisplayName('NotesPanel(enhanced)'),

  // The connected version of this component requires a parentId and parentType,
  // everything else will be fetched from state.
  setPropTypes({
    parentId: PropTypes.number.isRequired,
    parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,
  }),

  // If the notes for the candidacy have not been loaded or are stale,
  // fetch them.
  withNoteListFetched,

  // Add a prop so we can manually trigger refreshes if desired.
  withProps(({ fetchNoteList, noteList }) => ({
    noteIds: noteList && noteList.get('ids'),
    handlRefreshNotes: fetchNoteList,
  })),

  mapNoteIdsToNotes,
)(NotesPanel);

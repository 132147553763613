import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import moment from 'moment';
import { compose, setStatic } from 'recompose';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import DateInputField from 'modules/datetime/components/DateInputField';
import TimeInputField from 'modules/datetime/components/TimeInputField';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import SearchMultiSelectField from 'modules/searches/components/SearchMultiSelectField';
import { TYPE_JOB_SEARCH, TYPE_PIPELINE } from 'modules/searches/constants';
import getSearchType from 'modules/searches/selectors/getSearchType';
import { connect } from 'react-redux';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import RichTextField, {
  requiredField as requiredRichTextField,
} from '../../../components/forms/richtext/RichTextField';
import { PARENT_TYPES, PARENT_CONTACT, PARENT_SEARCH } from '../constants';

/**
 * Identifies the wrapper classes to apply to each field for different "layouts", so that we
 * can arrange fields differently as needed.
 */
const FIELD_LAYOUT = {
  wide: {
    is_private: 'col-12',
    pinned: 'col-12',
    leadership_report: 'col-12',
    searches: 'col-6',
    date: 'col-3',
    time: 'col-3',
    subject: 'col-12',
    content: 'col-12',
  },
  default: {
    is_private: 'col-12',
    pinned: 'col-12',
    leadership_report: 'col-12',
    searches: 'col-12',
    date: 'col-6',
    time: 'col-6',
    subject: 'col-12',
    content: 'col-12',
  },
};

/**
 * A field that can be used for editing a note record, but doesn't render a rich text
 * field for the notes. Should be used with a form component that renders it.
 */
class NoteField extends PureComponent {
  static createFieldState(name = 'note', note) {
    const values = fromJS({
      is_private: false,
      leadership_report: false,
      pinned: false,
      content: '',
      searchId: '',
      searches: [],
    }).merge(note);

    const notedOnMoment = values.get('noted_on')
      ? moment(values.get('noted_on'), moment.ISO_8601)
      : moment();

    return FieldState.createNested(
      name,
      [
        CheckboxField.createFieldState('is_private', values.get('is_private')),
        CheckboxField.createFieldState('pinned', values.get('pinned')),
        CheckboxField.createFieldState(
          'leadership_report',
          values.get('leadership_report'),
        ),
        RichTextField.createFieldState(
          'content',
          values.get('content'),
          requiredRichTextField('Notes', 'are'),
        ),
        SearchMultiSelectField.createFieldState(
          'searches',
          values.get('searches'),
        ),
        /**
          search_id is being passed along in the POST to the backend in a "hidden" input.
          We need to know the search ID so we can properly validate that there is only one
          pinned note in the context of the search.
        */
        InputField.createFieldState('search_id', values.get('searchId')),
        InputField.createFieldState('subject', values.get('subject')),
        DateInputField.createFieldState('date', notedOnMoment),
        TimeInputField.createFieldState('time', notedOnMoment.format('LT')),
      ],
      null,
      ({ date, time, ...other }) => {
        // Merges the `date` and `time` fields into `noted_on`
        const timeMoment = time ? moment(time, 'LT') : moment();
        const dateMoment = moment(date, moment.ISO_8601);
        dateMoment.set({
          hour: timeMoment.get('hour'),
          minute: timeMoment.get('minute'),
          second: timeMoment.get('second'),
        });

        return {
          ...other,
          noted_on: dateMoment.format(),
        };
      },
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      draft,
      excludeContentField,
      excludeSearchField,
      fieldLayout,
      fieldState,
      leadershipReport,
      onChange: _onChange,
      onDraftChange,
      parentType,
      pinned,
      pinnedNoteId,
      searchType,
      ...otherProps
    } = this.props;
    const isPipeline = searchType === TYPE_PIPELINE;
    const layout = FIELD_LAYOUT[fieldLayout];
    // Show the leadership report checkbox only if the leadership report flipper
    // has been turned on, and the note is a contact note associated with a search.
    const showLeadershipReportOption =
      leadershipReport &&
      parentType === PARENT_CONTACT &&
      fieldState.getNestedField('searches').getValue().length > 0;
    const disablePinnedInput = !pinned && Boolean(pinnedNoteId);
    const pinnedToolTip = disablePinnedInput
      ? 'Only one Note can be pinned at a time.'
      : 'This Note will be pinned to the top of the Notes section. A pinned Note can also be added to a Brief Search Summary Report.';

    const pinnedToolTipForSearch = disablePinnedInput
      ? 'Only one Note can be pinned at a time.'
      : 'Pin this note to the top.';

    return (
      <div className='notes--note-field'>
        <div className='row'>
          {parentType === PARENT_CONTACT ? (
            <div className={layout.is_private}>
              <CheckboxField
                {...otherProps}
                checked={fieldState.getNestedField('is_private').getValue()}
                className='note-field-checkbox'
                fieldState={fieldState.getNestedField('is_private')}
                key='is_private'
                onChange={this.handleFieldChange}
                text='Make Private'
              />
            </div>
          ) : null}
          {parentType === PARENT_CONTACT &&
          fieldState.getNestedFieldValue('searches').length > 0 ? (
            <div className={layout.pinned}>
              <TooltipTrigger
                nested={true}
                placement='right'
                tooltip={pinnedToolTip}
              >
                <div className='u-inlineBlock'>
                  <CheckboxField
                    {...otherProps}
                    checked={fieldState.getNestedField('pinned').getValue()}
                    className='note-field-checkbox'
                    disabled={disablePinnedInput}
                    fieldState={fieldState.getNestedField('pinned')}
                    key='pinned'
                    onChange={this.handleFieldChange}
                    text='Pin this Note'
                    value={fieldState.getNestedField('pinned').getValue()}
                  />
                </div>
              </TooltipTrigger>
            </div>
          ) : null}
          {showLeadershipReportOption ? (
            <div className={layout.leadership_report}>
              <CheckboxField
                {...otherProps}
                checked={fieldState
                  .getNestedField('leadership_report')
                  .getValue()}
                className='note-field-checkbox'
                fieldState={fieldState.getNestedField('leadership_report')}
                key='leadership_report'
                onChange={this.handleFieldChange}
                text='Status Report'
              />
            </div>
          ) : null}
          {!excludeSearchField && parentType === PARENT_CONTACT ? (
            <div className={layout.searches}>
              <SearchMultiSelectField
                {...otherProps}
                fieldState={fieldState.getNestedField('searches')}
                isCreatable={false}
                label={isPipeline ? 'Related Pipelines' : 'Related Searches'}
                noValueText={
                  isPipeline ? 'No Pipelines Selected' : 'No Searches Selected'
                }
                onChange={this.handleFieldChange}
                placeholder={
                  isPipeline ? 'Related to a Pipeline' : 'Related to a Search'
                }
                searchType={isPipeline ? TYPE_PIPELINE : TYPE_JOB_SEARCH}
                summaryLabel={
                  isPipeline
                    ? '{0} Pipelines Selected'
                    : '{0} Searches Selected'
                }
              />
            </div>
          ) : null}
          <div className={layout.date}>
            <DateInputField
              {...otherProps}
              fieldState={fieldState.getNestedField('date')}
              label='Date'
              onChange={this.handleFieldChange}
            />
          </div>
          <div className={layout.time}>
            <TimeInputField
              {...otherProps}
              fieldState={fieldState.getNestedField('time')}
              label='Time'
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className={layout.subject}>
            <InputField
              {...otherProps}
              fieldState={fieldState.getNestedField('subject')}
              key='subject'
              label='Subject'
              onChange={this.handleFieldChange}
              placeholder='Add a Subject'
            />
          </div>
        </div>
        {excludeContentField ? null : (
          <div className='row'>
            <div className={layout.content}>
              <RichTextField
                {...otherProps}
                fieldState={fieldState.getNestedField('content')}
                label='* Notes'
                lastUpdatedTime={draft?.lastUpdatedTime}
                onChange={this.handleFieldChange}
                onDraftChange={onDraftChange}
                placeholder='Enter notes...'
              />
            </div>
          </div>
        )}
        {parentType === PARENT_SEARCH ? (
          <div className='row'>
            <div className={layout.pinned}>
              <TooltipTrigger
                nested={true}
                placement='right'
                tooltip={pinnedToolTipForSearch}
              >
                <div className='u-inlineBlock'>
                  <CheckboxField
                    {...otherProps}
                    checked={fieldState.getNestedField('pinned').getValue()}
                    className='note-field-checkbox'
                    disabled={disablePinnedInput}
                    fieldState={fieldState.getNestedField('pinned')}
                    key='pinned'
                    onChange={this.handleFieldChange}
                    text='Pin this Note'
                    value={fieldState.getNestedField('pinned').getValue()}
                  />
                </div>
              </TooltipTrigger>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

NoteField.propTypes = {
  draft: PropTypes.shape({
    content: PropTypes.string,
    lastUpdatedTime: PropTypes.number,
  }),
  /**
   * If true, the content rich text field will not be rendered
   */
  excludeContentField: PropTypes.bool,

  excludeSearchField: PropTypes.bool,

  fieldLayout: PropTypes.oneOf(Object.keys(FIELD_LAYOUT)),

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Whether or not the leadership report flipper has been turned on.
   */
  leadershipReport: PropTypes.bool,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  onDraftChange: PropTypes.func,

  parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,

  /**
   *  whether or not the current note is pinned. Used in conjunction with pinnedNoteId
   *  to determine whether or not we need to disable the pinned checkbox
   *
   *  TODO: if this component gets refactored into a functional component, useSelector() hook
   *  can be used to check the redux store for the pinned value instead of passing it down.
   */
  pinned: PropTypes.bool,

  /**
   *  Used to enable or disable pin checkbox based on the pinned state of a note.
   */
  pinnedNoteId: PropTypes.number,
  searchType: PropTypes.string,
};

NoteField.defaultProps = {
  draft: null,
  fieldLayout: 'default',
};

export default compose(
  setStatic('createFieldState', NoteField.createFieldState),
  connect(
    (state, { searchId }) => ({
      searchType: getSearchType(state, searchId),
    }),
    {},
  ),
  withPropsRemoved('searchId'),
  withFeatureCheck('report.leadership_report', 'leadershipReport'),
)(NoteField);

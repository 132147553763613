import { SET_PREFERENCE } from './actionTypes';

/**
 * Sets a persistent preference value that will be saved across page reloads.
 * @param {String|String[]} key A unique key or key path for the preference.
 * @param {*} value The value to set.
 */
export default (key, value) => ({
  type: SET_PREFERENCE,
  payload: {
    key: key,
    value: value,
  },
});

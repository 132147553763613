import PropTypes from 'prop-types';
import selectn from 'selectn';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import withFormState from 'modules/forms/components/withFormState';
import withTransaction from 'modules/transactions/components/withTransaction';
import updateCustomerActionCreator from '../actions/updateCustomer';
import withCustomerFetched from './withCustomerFetched';

/**
 * Provides props for managing a form for updating the customer configuration for a tenant.
 *
 * Creates the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying FundingRoundField FieldState
 * * `hasCustomerConfiguration` {Boolean} indicate whether the CustomerDetails configuration has
 *   actually been loaded and is ready to load into the form (if not, we shouldn't allow editing
 *   it becuase we don't even know it's current values)
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default RootField =>
  compose(
    setDisplayName('withCompanyDetailsForm'),
    setPropTypes({
      /**
       * Called when the form has been successfully saved.
       */
      onSaved: PropTypes.func.isRequired,
    }),

    // Create a temporary prop (`withCustomerDetailsFormProps`) that holds any incoming props
    // that aren't required by us. This ensures we don't overwrite/delete/clobber any props that may
    // need to be passed through.
    withProps(({ onSaved: _onSaved, ...withCustomerDetailsFormProps }) => ({
      withCustomerDetailsFormProps: withCustomerDetailsFormProps,
    })),

    /**
     * Fetch the current configuration if needed. Will also supply an `hasCustomerConfiguration`
     * prop that will only be set to true if the configuration has been loaded - this is used to:
     * 1. Show a loading indicator to the user in the meantime; and
     * 2. Ensure our formState is up-to-date (see the `lifecycle` HoC below).
     */
    withCustomerFetched(),

    /**
     * Create a FormState, initializing it with the value from `initialValue`,
     * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
     */
    withFormState(
      ({ customerConfiguration, hasCandidatePortalEnabled, hasCaslEnabled }) =>
        RootField.createFieldState('customer-configuration', {
          customerConfiguration: customerConfiguration,
          hasCaslEnabled: hasCaslEnabled,
          hasCandidatePortalEnabled: hasCandidatePortalEnabled,
        }),
    ),

    // `customerDetails` gets loaded on demand, and may not be available when we first create
    // our form state. If this happens we will need to regenerate the formState once the
    // configuration is loaded. withFormState provides an `onResetFormState` prop to facilitate this.
    lifecycle({
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        if (
          !this.props.hasCustomerConfiguration &&
          nextProps.hasCustomerConfiguration
        ) {
          nextProps.onResetFormState();
        }
      },
    }),

    /**
     * Watch for any save transaction to complete
     */
    withHandlers({
      /**
       * This gets called by `withTransaction`, below, any time our transaction started
       * with `startTransaction` is called.
       */
      onTransactionComplete: ({
        formState,
        onFormStateChange,
        onResetFormState,
        onSaved,
      }) => transaction => {
        const error = selectn('payload.error', transaction);
        onFormStateChange(formState.endSubmit(error));

        if (!error) {
          onResetFormState();
          onSaved(selectn('payload.result', transaction));
        }
      },
    }),

    /**
     * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
     * when the transaction used with `startTransaction` is finished.
     */
    withTransaction,

    connect(null, {
      updateCustomer: updateCustomerActionCreator,
    }),

    /**
     * Add a callback to handle submitting the action form.
     */
    withHandlers({
      // Called when the form should be submitted.
      onSubmit: ({
        formState,
        onFormStateChange,
        startTransaction,
        updateCustomer,
      }) => e => {
        const transactionId = startTransaction();

        updateCustomer({
          customer_configuration: formState.getFieldValue(),
          transactionId: transactionId,
        });

        onFormStateChange(formState.startSubmit(transactionId));

        // Prevent default browser behavior, which could cause the browser to attempt
        // to submit a form on it's own.
        return preventDefaultHandler(e);
      },
    }),

    // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
    mapProps(
      ({
        formState,
        hasCaslEnabled,
        hasCustomerConfiguration,
        onFieldStateChange,
        onFormStateChange,
        onSubmit,
        withCustomerDetailsFormProps,
      }) => ({
        ...withCustomerDetailsFormProps,
        formState: formState,
        hasCustomerConfiguration: hasCustomerConfiguration,
        onFieldStateChange: onFieldStateChange,
        onFormStateChange: onFormStateChange,
        onSubmit: onSubmit,
        hasCaslEnabled: hasCaslEnabled,
      }),
    ),
  );

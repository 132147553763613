import getName from 'modules/users/selectors/getName';
import getSearchProperty from './getSearchProperty';

/**
 * Gets the name of the "External Primary" recruiter of a search ("external_primary_recruiter").
 * Not to be confused with the "External Primary" recruiter (@see {getLeadRecruiterTeamMember}), which
 * uses the "external_primary_recruiter" field.
 * @param {Immutable.Map} state
 * @param {Number} searchId The ID of the search.
 */
const getSearchPrimaryRecruiterName = (state, searchId) => {
  const recruiterName = getSearchProperty(
    state,
    searchId,
    'external_primary_recruiter',
  );
  const leadRecruiterTeamMember = getSearchProperty(
    state,
    searchId,
    'lead_recruiter_team_member',
  );
  return (
    (leadRecruiterTeamMember && getName(state, leadRecruiterTeamMember)) ||
    recruiterName
  );
};

export default getSearchPrimaryRecruiterName;

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose, setPropTypes } from 'recompose';
import getCompanyName from 'modules/companies/selectors/getCompanyName';
import * as filterUtils from 'modules/core/filterUtils';
import getTargetCompany from '../selectors/targetCompanies/getTargetCompany';

/**
 * A Higher order component that filters a list of targetCompanyIds based on a `filterText`
 * value.
 */
export default compose(
  connect((state, { filterText, targetCompanyIds }) => {
    if (
      !filterText ||
      !filterText.trim() ||
      !targetCompanyIds ||
      targetCompanyIds.count() < 1
    ) {
      return {};
    }

    const filterRegex = filterUtils.createMatchAllKeywordsRegex(filterText);

    return {
      targetCompanyIds: targetCompanyIds.filter(targetCompanyId => {
        const targetCompany = getTargetCompany(state, targetCompanyId);
        const companyId = targetCompany && targetCompany.get('company');
        const companyName = companyId && getCompanyName(state, companyId);

        return companyName && filterRegex.test(companyName);
      }),
    };
  }),
  setPropTypes({
    filterText: PropTypes.string,
    targetCompanyIds: ImmutablePropTypes.listOf(PropTypes.number),
  }),
);

import PropTypes from 'prop-types';
import { compose, pure, setDisplayName, setPropTypes } from 'recompose';
import Link from 'modules/routing/components/Link';

import withSearchCandidacyHref from './withSearchCandidacyHref';

/**
 * Renders a link, optionally to a specific tab, to the current search page and a specific.
 * candidate.
 */
export default compose(
  setDisplayName('SearchCandidacyLink'),
  setPropTypes({
    /**
     * The ID of the candidacy to be select in the search sidebar
     */
    candidacyId: PropTypes.number,

    /**
     * The tab to select in the candidacy sidebar
     */
    tab: PropTypes.string,
  }),
  withSearchCandidacyHref,
  pure,
)(Link);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import RadioButtonGroup from 'modules/core/componentsLegacy/RadioButtonGroup';
import getClientTypeName from 'modules/tenant/selectors/getClientTypeName';
import getRecruiterName from 'modules/tenant/selectors/getRecruiterName';
import { INTERVIEW_TYPE_CLIENT } from '../constants';

const InterviewTypeRadioButtonGroup = ({
  bsSize,
  clientTypeName,
  onChange,
  recruiterName,
  value,
}) => (
  <RadioButtonGroup
    bsSize={bsSize}
    onChange={onChange}
    options={{
      '': 'All',
      [INTERVIEW_TYPE_CLIENT]:
        // Special care for shortening "Hiring Manaegr" to "HM"
        // so it fits in the UI.
        clientTypeName === 'Hiring Manager' ? 'HM' : clientTypeName,
      [recruiterName]: recruiterName,
    }}
    value={value}
  />
);

InterviewTypeRadioButtonGroup.propTypes = {
  bsSize: PropTypes.oneOf(['large', 'lg', 'small', 'sm', 'xsmall', 'xs']),
  clientTypeName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  recruiterName: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default compose(
  connect(
    state => ({
      clientTypeName: getClientTypeName(state),
      recruiterName: getRecruiterName(state),
    }),
    null,
  ),
)(InterviewTypeRadioButtonGroup);

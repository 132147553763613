import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Error boundaries are React class components that catch JavaScript errors
 * anywhere in their child component tree, log those errors, and display
 * a fallback UI instead of the component tree that crashed.
 * https://reactjs.org/docs/error-boundaries.html
 */

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className='u-padding-8' style={{ border: '1px dashed red' }}>
          <p className='u-fontSize-small u-fontWeight-bold u-margin-n u-textColor-red'>
            Error Details:
          </p>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <summary className='u-fontSize-small u-noWrap'>
              View Message
            </summary>
            {this.state.error?.toString()}
          </details>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <summary className='u-fontSize-small u-noWrap'>View Trace</summary>
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;

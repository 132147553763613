import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import DateTime from 'modules/datetime/components/DateTime';
import getEmailTemplateDateCreated from '../selectors/getEmailTemplateDateCreated';

/**
 * Renders the date and/or time that an email template was created.
 */
export default compose(
  setDisplayName('EmailTemplateDateCreated'),
  setPropTypes({
    /**
     * The template ID of the template to show the title of.
     */
    emailTemplateId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { emailTemplateId }) => ({
      value: getEmailTemplateDateCreated(state, emailTemplateId),
    }),
    {},
  ),
  branch(({ value }) => !value, renderNothing),
)(DateTime);

import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import { connect } from 'react-redux';
import EntityTagSelectField from 'modules/entities/components/EntityTagSelectField';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import ensureTenantOptionsFetched from 'modules/tenant/components/ensureTenantOptionsFetched';
import companySchema from '../companySchema';
import getPortfolioCompanyIds from '../selectors/getPortfolioCompanyIds';

/**
 * A select control for choosing from a list of portfolio companies.
 */
export default compose(
  setDisplayName('PortfolioCompaniesTagSelectField'),
  setPropTypes({
    /**
     * A FieldState with an underlying value that is an array of target market IDs.
     */
    fieldState: fieldStatePropType.isRequired,

    /**
     * Called when the fieldState is changed by the user adding/removing target markets.
     */
    onChange: PropTypes.func.isRequired,
  }),

  setStatic('createFieldState', EntityTagSelectField.createFieldState),
  ensureTenantOptionsFetched,
  connect(state => {
    const ids = getPortfolioCompanyIds(state);
    return {
      entityIds: ids ? ids.toArray() : [],
      entityType: companySchema.key,
    };
  }),
)(EntityTagSelectField);

import { USER_STATE_KEY } from '../constants';

/**
 * Gets the email address of the current user.
 * @param {Object} state
 * @returns {String} The currently logged in user's email address.
 */
const getCurrentUserEmail = state => state[USER_STATE_KEY].get('email');

export default getCurrentUserEmail;

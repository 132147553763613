const Clipboard = require('clipboard');
const relevanceFilter = require('../lib/relevance_filter').default;
const formUserModal = require('../lib/form_user_modal').default;
const initSelect2 = require('../lib/select2');
const initSelectsInModal = require('../lib/modal_selects');
const initEmail = require('../lib/select2_email');
const select2Autocomplete = require('../lib/select2_autocomplete');
const introductions = require('./introductions');

exports.replaceUserModal = formUserModal.replaceUserModalonUserIndex;

const showTooltip = el => {
  el.tooltip({ trigger: 'manual', title: 'Copied!' });
  el.tooltip('show');
  const hide = () => el.tooltip('hide');
  return setTimeout(hide, 2000);
};

const initializeFilters = () => {
  initSelectsInModal('.contacts .advanced-search-modal');
  select2Autocomplete('.js-searches-filter', {
    dropdownParent: $('#contact-filter-searches'),
  });
  select2Autocomplete('.js-pipelines-filter', {
    dropdownParent: $('#contact-filter-pipelines'),
  });
  select2Autocomplete('.js-connected-to-user-filter', {
    dropdownParent: $('.filters_connected_to_user_ids'),
  });
  select2Autocomplete('.js-connected-to-contact-filter', {
    dropdownParent: $('.filters_connected_to_contact_ids'),
  });
  select2Autocomplete('.js-connected-to-company-contact-filter', {
    dropdownParent: $('.filters_connected_to_company_ids'),
  });
  select2Autocomplete('.js-linkedin-skills-filter', {
    dropdownParent: $('.filters_contact_linkedin_skills'),
  });
  select2Autocomplete('.js-relationship-manager-filter', {
    dropdownParent: $('#contact-filter-relationship-manager'),
  });
  select2Autocomplete('.js-contact-relationship_manager-filter', {
    dropdownParent: $('#contact-filter-relationship-manager'),
  });
  return select2Autocomplete('.js-cities-filter', {
    dropdownParent: $('#contact-filter-cities'),
  });
};

exports.initializeFilters = initializeFilters;

exports.initialize = () => {
  // Initialize introductions
  introductions.initialize();

  formUserModal.bindCopyLinkButton();

  relevanceFilter({
    form: '#new_filters',
    search: '.contact-filter-elastic',
    sort: '#filters_sort_field',
  });

  new Clipboard('.copy-to-clipboard').on('success', event =>
    showTooltip($(event.trigger)),
  );

  $("[data-toggle='popover']").popover({ html: true });

  initSelect2('.network-form', ['.js-network-select']);
  initEmail($('#report_emails'));
  initializeFilters();
};

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  withHandlers,
} from 'recompose';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import Tooltip from '@thrivetrm/ui/components/Tooltip';
import FieldState from 'modules/forms/FieldState';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import mapCompanyIdToCompany from 'modules/companies/components/mapCompanyIdToCompany';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import CompanyEmployeeCountField from './CompanyEmployeeCountField';
import CompanyEmployeeCountDeleteToggle from './CompanyEmployeeCountDeleteToggle';
import CompanyEmployeeCountLegacyInput from './CompanyEmployeeCountLegacyInput';

/**
 * Renders a list of CompanyEmployeeCountField for editing the employeeCounts.
 */
const CompanyEmployeeCountListField = ({
  company,
  companyId,
  disabled,
  fieldState,
  handleNestedFieldChange,
  onEmployeeCountAdd,
  ...fieldProps
}) => {
  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const employeeCounts = useSelector(state => state.entities)?.toJS()
    ?.employeeCounts;

  return (
    <div className='CompanyEmployeeCountListField'>
      {company.get('number_of_employees') && (
        <CompanyEmployeeCountLegacyInput
          companyId={companyId}
          destroyInput={fieldState.getNestedFields().size >= 1}
        />
      )}
      <ul className='list-unstyled'>
        {fieldState
          .getNestedFields()
          .map(nestedField => {
            const isEnriched =
              hasSkyminyrFeature &&
              employeeCounts?.[nestedField?.getNestedFieldValue('id')]
                ?.enriched;
            return (
              <li
                className={classnames('row', {
                  'CompanyEmployeeCountListField__item--deleted': nestedField.getNestedFieldValue(
                    '_destroy',
                  ),
                })}
                key={nestedField.getName()}
              >
                <Tooltip
                  className='u-block col-sm-11'
                  content={
                    isEnriched
                      ? 'These fields are automatically enriched, and in sync with the golden record.'
                      : null
                  }
                  position='left'
                  size='large'
                >
                  <CompanyEmployeeCountField
                    disabled={
                      nestedField.getNestedFieldValue('_destroy') || isEnriched
                    }
                    {...fieldProps}
                    fieldState={nestedField}
                    onChange={handleNestedFieldChange}
                  />
                </Tooltip>
                {!isEnriched ? (
                  <div className='col-sm-1 CompanyEmployeeCountDeleteToggle__wrapper'>
                    <CompanyEmployeeCountDeleteToggle
                      disabled={disabled}
                      fieldState={nestedField}
                      onChange={handleNestedFieldChange}
                    />
                  </div>
                ) : null}
              </li>
            );
          })
          .toArray()}
      </ul>
      <button
        className='CompanyEmployeeCountListField__add btn btn-link btn-sm'
        disabled={disabled}
        onClick={onEmployeeCountAdd}
        type='button'
      >
        <i className='fa fa-plus' /> Add Employee Count
      </button>
    </div>
  );
};

CompanyEmployeeCountListField.propTypes = {
  /**
   * Company - needed to show legacy data
   */
  company: ImmutablePropTypes.mapContains({
    number_of_employees: PropTypes.string,
  }).isRequired,

  /**
   * ID company - needed to show legacy form
   */
  companyId: PropTypes.number,

  /**
   * True to disable this input list.
   */
  disabled: PropTypes.bool,

  /**
   * The field state containing the nested values.
   */
  fieldState: fieldStatePropType.isRequired,

  /**
   * Called when a nested field is changed.
   */
  handleNestedFieldChange: PropTypes.func.isRequired,

  /**
   * Called when the "add alias" button is clicked"
   */
  onEmployeeCountAdd: PropTypes.func.isRequired,
};

/**
 * Creates the fieldstate for editing a company's employeeCount list.
 * @param {String} name The name of the FieldState
 * @param {Number[]} values The values to initialize the fieldlist state with.
 * These should be the IDs of the employeeCounts records.
 */
CompanyEmployeeCountListField.createFieldState = (
  name = 'employee_counts',
  values,
) =>
  FieldState.createNested(
    name,
    values.map(employeeCount =>
      CompanyEmployeeCountField.createFieldState(
        String(employeeCount.get('id')),
        employeeCount,
      ),
    ),
    fieldState => {
      // Get an array containing only a list of years that have been specified
      // more than once.
      const duplicateYears = fieldState
        .getNestedFields()
        // ignore any fields that are being deleted
        .filter(field => !field.getNestedFieldValue('_destroy'))
        // Count up the number of times each year was set
        .countBy(nestedField => nestedField.getNestedFieldValue('year'))
        // Only look at years specified more than once where we actually
        // have a year set
        .filter((count, key) => typeof key === 'number' && count > 1)
        .keySeq()
        .toArray();

      // We need to fully revalidate every single "year" value and return an error
      // (or lack of error) for each. Otherwise we may end up setting an error
      // but not removing it once the field ultimately passes validation.
      // Additionally, if OUR validation passes (duplicate checking), we need to
      // manually check for any validator set on the year field itself --
      // otherwise we will end up removing it.
      return fieldState
        .getNestedFields()
        .map(nestedField => ({
          year: duplicateYears.includes(nestedField.getNestedFieldValue('year'))
            ? `${nestedField.getNestedFieldValue('year')} is already used`
            : nestedField.getNestedField('year').validate().getError(),
        }))
        .toJS();
    },
    result => Object.keys(result).map(id => result[id]),
  );

export default compose(
  setDisplayName('CompanyEmployeeCountListField(enhanced)'),
  setStatic('createFieldState', CompanyEmployeeCountListField.createFieldState),
  setPropTypes({
    disabled: PropTypes.bool,
  }),
  withHandlers({
    onEmployeeCountAdd: ({ fieldState, onChange }) => e => {
      e.preventDefault();
      onChange(
        fieldState.setNestedField(
          CompanyEmployeeCountField.createFieldState(uniqueId(), {
            year: null,
            amount: '',
          }),
        ),
      );
    },
    handleNestedFieldChange: ({ fieldState, onChange }) => nestedField => {
      if (
        !nestedField.getNestedFieldValue('id') &&
        nestedField.getNestedFieldValue('_destroy')
      ) {
        onChange(fieldState.removeNestedField(nestedField.getName()));
        return;
      }

      onChange(fieldState.setNestedField(nestedField));
    },
  }),
  mapCompanyIdToCompany,
)(CompanyEmployeeCountListField);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '@thrivetrm/ui/components/Checkbox';
import TableCell from './TableCell';

const TableRow = ({ attributes, id, isLoading, isSelected, onSelect }) => {
  const rowClass = classNames('TableRow', {
    'TableRow--isSelected': isSelected,
  });
  return (
    <tr className={rowClass}>
      {onSelect && (
        <td className='table__selection-cell u-paddingTop-12'>
          <Checkbox
            data-testid={`table row checkbox ${id}`}
            isChecked={isSelected}
            onChange={() => onSelect(id)}
          />
        </td>
      )}
      {!isLoading &&
        attributes.map((attribute, index) => (
          <TableCell
            data={attribute}
            isFixed={index === 0}
            key={attribute.name}
          />
        ))}
    </tr>
  );
};

TableRow.defaultProps = {
  isLoading: false,
  isSelected: false,
  onSelect: null,
};

TableRow.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
        PropTypes.bool,
      ]),
    }),
  ).isRequired,
  id: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default TableRow;

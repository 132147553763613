import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import SidePanel from '@thrivetrm/ui/components/SidePanel';
import Form from '@thrivetrm/ui/components/Form';
import RecruitmentRatingsForm from 'modules/quick-view/recruitment-rating-panels/RecruitmentRatingForm';
import getCurrentUserId from 'modules/user/selectors/getCurrentUserId';
import fetchUser from 'modules/users/actions/fetchUser';
import getUserDisplayName from 'modules/users/selectors/getUserDisplayName';
import getCurrentUserEmail from 'modules/auth/selectors/getCurrentUserEmail';
import getCurrentUserName from 'modules/auth/selectors/getCurrentUserName';
import { useGetSearchQuery } from 'services/apiV1/searches';
import { useCreateRecruitmentRatingMutation } from 'services/apiV1/recruitmentRatings';
import LoadingContainer from '@thrivetrm/ui/components/LoadingContainer';
import useQuickView from '../useQuickView';
import QuickViewPanelHeader from '../QuickViewPanelHeader';

const RecruitmentRatingsAddForm = () => {
  const { close, navigateTo, pathParams, title } = useQuickView();
  const { companyId, searchId } = pathParams;
  const dispatch = useDispatch();
  const [createRecruitingRating] = useCreateRecruitmentRatingMutation();

  /**
   * On some pages, not all of the current user's data is available in Redux.
   *
   * e.g. on the Company Profile page we do not have the current user's `name`!
   * This value is required to pre-populate the initial value for the Rated By
   * field on the `RecruitmentRatingForm`.  Additionally, state.entities does not
   * have data either so future uses of `getUser` or related will fail as well
   */
  const currentUserId = useSelector(getCurrentUserId);
  useEffect(() => {
    if (currentUserId) {
      dispatch(fetchUser({ id: currentUserId }));
    }
  }, [currentUserId, dispatch]);

  const goToListView = () => {
    navigateTo(`/company/${companyId}/recruitment_ratings`);
  };

  const {
    data: searchData,
    error: _loadSearchError,
    isLoading: isSearchLoading,
  } = useGetSearchQuery(searchId);

  const handleSubmit = formState => {
    createRecruitingRating({
      companyId: companyId,
      payload: {
        search_id: searchId,
        company: companyId,
        rated_by_id: formState.ratedBy.id,
        created_by_id: currentUserId,
        updated_by_id: currentUserId,
        rating: formState.rating,
        notes: formState.notes,
      },
    }).then(() => {
      goToListView();
    });
  };

  /**
   * getUserDisplayName returns user data from `state.entities.users` which does not always
   * have data on the current user.  As a fallback, we refer to `state.user`, specifically the
   * name & email fields
   */
  const userDisplayName = useSelector(
    state =>
      getUserDisplayName(state, currentUserId) ||
      getCurrentUserName(state) ||
      getCurrentUserEmail(state),
  );

  // useMemo is used to avoid reinitialize of component value on each re-render
  const initialRatedBy = useMemo(
    () => ({ id: currentUserId, name: userDisplayName }),
    [currentUserId, userDisplayName],
  );

  return (
    <>
      <SidePanel.Header
        className='u-marginBottom-12'
        onBack={goToListView}
        onClose={close}
        title={title}
      />
      <QuickViewPanelHeader title='Add Rating' />
      <Form onSubmit={handleSubmit}>
        <SidePanel.Body className='u-paddingTop-n'>
          <LoadingContainer
            className='u-marginTop-16'
            isLoading={isSearchLoading}
          >
            <RecruitmentRatingsForm
              endDate={searchData?.endDate}
              initialValues={{
                ratedBy: initialRatedBy,
              }}
              milestone={searchData?.milestone}
              searchId={searchId}
              searchName={searchData?.name}
            />
          </LoadingContainer>
        </SidePanel.Body>
        <SidePanel.Footer>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={close} />
          <Form.SubmitButton isLoading={false} label='Save' />
        </SidePanel.Footer>
      </Form>
    </>
  );
};

export default RecruitmentRatingsAddForm;

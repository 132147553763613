import mapEntityIdToEntity from 'modules/entities/components/mapEntityIdToEntity';
import noteSchema from '../schema';

/**
 * A higher order component which maps a `noteId` to it's note record.
 */
export default mapEntityIdToEntity(noteSchema.key, {
  idProp: 'noteId',
  entityProp: 'note',
});

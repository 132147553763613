import getCandidacyProperty from './getCandidacyProperty';

/**
 * Given the current app state and candidacy ID, returns the rejection
 * reason for the candidacy with that ID, if any.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {String|undefined} The rejection reason, if the candidacy is
 *   in the rejected state.
 */
export default (state, candidacyId) =>
  getCandidacyProperty(state, candidacyId, 'rejection_reason');

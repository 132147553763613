import getCandidacyProperty from './getCandidacyProperty';

/**
 * Gest the search ID of the search a candidacy belongs to.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?Number} The job applicant ID of the candidacy, if it's record exists.
 */
export default (state, candidacyId) =>
  getCandidacyProperty(state, candidacyId, 'job_application_id');

import routes from 'modules/routing/routes';
import createRecommendedCandidacies from 'modules/recommendations/actions/createRecommendedCandidacies';

/**
 * Creates an action for creating a candidacy (adding a contact to a search).
 * @param {Object} payload
 * @param {String|String[]} payload.contactId The ID of the contact to add to the search, or an
 *   array of contact IDs to create multiple candidacies at once.
 * @param {String} payload.searchId The ID of the search to add the contact to.
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ contactId, searchId, transactionId }) => dispatch => {
  /*
    Dispatching the action to call the endpoint to convert a contact to a recommendation
    for the selected search.
  */
  dispatch(
    createRecommendedCandidacies({
      transactionId: transactionId,
      contactId: contactId,
      searchId: searchId,
      url: routes.recommend_api_v1_search_recommended_candidacies({
        search_id: searchId,
        query: {
          contact_ids: contactId.join(','),
        },
      }),
    }),
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import { RESOURCE_TYPE_LABELS } from '../constants';
import getBulkImportProperty from '../selectors/getBulkImportProperty';

const BulkImportResourceType = ({ resourceTypeLabel }) => (
  <span>{resourceTypeLabel}</span>
);

BulkImportResourceType.propTypes = {
  resourceTypeLabel: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('BulkImportResourceType(enhanced)'),
  setPropTypes({ bulkImportId: PropTypes.number.isRequired }),
  connect((state, { bulkImportId }) => {
    const resourceType = getBulkImportProperty(
      state,
      bulkImportId,
      'resource_type',
    );
    return {
      resourceTypeLabel: RESOURCE_TYPE_LABELS[resourceType],
    };
  }),
)(BulkImportResourceType);

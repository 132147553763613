import url from 'modules/routing/url';
import { DASHBOARD_VIEW_DEFAULT, DASHBOARD_VIEW_TYPES } from '../constants';
import getDashboardSelectedView from './getDashboardSelectedView';

/**
 * Gets the URL for the talent pool dashboard page.
 * @param {Object} state
 * @param {Object} params The params for the dashboard page
 * @param {Number} [params.contactId] An optional contact ID to set as the selected incumbent.
 * @param {String} [params.view] The optional view type (one of `DASHBOARD_VIEW_TYPES`)
 */
export default (state, { contactId, view: _view }) => {
  const view = _view || getDashboardSelectedView(state);

  return url('/talent_pools', {
    ...(view !== DASHBOARD_VIEW_DEFAULT && DASHBOARD_VIEW_TYPES.includes(view)
      ? { view: view }
      : null),
    ...(contactId && Number.isFinite(parseInt(contactId))
      ? { contact: contactId }
      : null),
  });
};

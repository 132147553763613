import apiV1 from './index';

const generateRecommendedReferences = apiV1.injectEndpoints({
  endpoints: builder => ({
    getCompaniesOption: builder.query({
      query: contactId => ({
        url: `contacts/${contactId}/references/backchannel_companies`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCompaniesOptionQuery } = generateRecommendedReferences;

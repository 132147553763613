import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

export default compose(
  setDisplayName('connectJobApplicationActions'),
  connect(null, dispatch => ({
    jobApplicationActions: bindActionCreators(actions, dispatch),
  })),
);

import dueDate from './dueDate';
import associatedRecord from './contactName';
import title from './title';

import {
  SORT_BY_DUE_DATE,
  SORT_BY_ASSOCIATED_RECORD,
  SORT_BY_TITLE,
} from '../constants';

export default {
  [SORT_BY_DUE_DATE]: dueDate,
  [SORT_BY_ASSOCIATED_RECORD]: associatedRecord,
  [SORT_BY_TITLE]: title,
};

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, setDisplayName, setPropTypes } from 'recompose';
import { connect } from 'react-redux';
import withOptionsFetched from 'modules/assessments/components/withOptionsFetched';
import withAttributesFetched from 'modules/assessments/components/withAttributesFetched';
import mapRatingsToRatingsByOptionId from 'modules/assessments/components/mapRatingsToRatingsByOptionId';
import AssessmentRating from 'modules/assessments/components/AssessmentRating';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import { TYPE_JOB_SEARCH, SEARCH_TYPES } from 'modules/searches/constants';
import getRecruiterName from 'modules/tenant/selectors/getRecruiterName';

import ShowMoreAttributesText from './ShowMoreAttributesText';
import mapCandidacyIdToSearchId from '../mapCandidacyIdToSearchId';
import mapSearchIdToSearchAttributes from './mapSearchIdToSearchAttributes';
import mapSortedSearchAttributesToOptions from './mapSortedSearchAttributesToOptions';

/**
 * Renders a list of assessement attributes with their rating values.
 */
export const Attributes = ({
  isFetchingOptions,
  maxOptions,
  moreText,
  onChange,
  options,
  ratingsByOptionId,
  readOnly,
  recruiterName,
  searchType,
  showMoreTop,
}) => {
  const ratingOptions = maxOptions ? options?.take(maxOptions) : options;

  return (
    <div className='assessments--attributes'>
      {isFetchingOptions && options.some(option => !option) ? (
        <LoadingIndicator size={24} />
      ) : null}
      {showMoreTop ? (
        <h6 className='u-marginVertical-8'>
          {searchType === TYPE_JOB_SEARCH ? recruiterName : ''} Assessment
          <ShowMoreAttributesText
            maxOptions={maxOptions}
            moreText={moreText}
            options={options}
          />
        </h6>
      ) : null}
      {ratingOptions
        ?.map(
          option =>
            option && (
              <AssessmentRating
                key={option.get('id')}
                onChange={onChange}
                option={option}
                rating={ratingsByOptionId?.get(option.get('id'))}
                readOnly={readOnly}
              />
            ),
        )
        .toArray()}
      {showMoreTop ? null : (
        <ShowMoreAttributesText
          maxOptions={maxOptions}
          moreText={moreText}
          options={options}
        />
      )}
    </div>
  );
};

Attributes.propTypes = {
  isFetchingOptions: PropTypes.bool,

  /**
   * The maximum number of options to render.
   */
  maxOptions: PropTypes.number,

  /**
   * Called when any of the rating values are changed (only) when `readOnly` is
   * false). Called with the following signature:
   *
   *   `onChange(newValue, attribute, assessmentRatingComponent)`
   *
   * Where:
   *  @param {Number} newValue the new rating value selected,
   *  @param {Map} attribute The assessemnt attribute passed into the
   *    `attribute` prop.
   *  @param {AssessmentRating} assessmentRatingComponent - a reference to the
   *    instance of the `AssessmentRating` component that initially triggered
   *    the callback.
   */
  onChange: PropTypes.func,

  /**
   * The list of all assessement attributes (for the current search) that
   * will be displayed. This determines which attributes are shown in the
   * list, while `ratings` determines the rating _values_ for those attributes.
   */
  options: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.number.isRequired,
    }),
  ),

  /**
   * The list of that contains the rating values to display. This list
   * may not be different from those in the `assessementAttributes` list.
   * The `ratings` are used only to obtain the underlying value,
   * while the `assessementAttributes` are used to determine which list
   * of attributes are actually displayed.
   */
  ratingsByOptionId: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      score: PropTypes.number,
    }),
    PropTypes.number,
  ),

  /**
   * False to allow the ratings to be change, true to render in readOnly view.
   */
  readOnly: PropTypes.bool,

  /**
   * The current tenant's "recruiter" name
   */
  recruiterName: PropTypes.string,

  /**
   * The type of search this candidacy is for.
   */
  searchType: PropTypes.oneOf(SEARCH_TYPES),
};

Attributes.defaultProps = {
  isFetchingOptions: false,
  readOnly: false,
};

export default compose(
  setDisplayName('Attributes(enhanced)'),

  setPropTypes({
    candidacyId: PropTypes.number.isRequired,

    ratings: ImmutablePropTypes.listOf(
      ImmutablePropTypes.mapContains({
        id: PropTypes.number,
        score: PropTypes.number,
        assessment_option_id: PropTypes.number.isRequired,
      }),
    ),

    readOnly: Attributes.propTypes.readOnly,

    // The search ID is only required if it can not be fetched from the
    // current candidacy record in state.
    searchId: PropTypes.number,
  }),

  // If the searchId was not provided, try to pull it from the candidacy record.
  branch(({ searchId }) => !searchId, mapCandidacyIdToSearchId),

  // With the search ID, we can get a list of the attributes that belong to it.
  mapSearchIdToSearchAttributes,

  // If those attributes aren't loaded yet, fetch them.
  withAttributesFetched,

  // Now that we have the attributes, we can sort them in the correct order
  // (by `position`) and map them to their option values.
  mapSortedSearchAttributesToOptions,

  // If any of the options we need to render haven't been fetch, fetch all options.
  branch(
    ({ options }) => options && options.some(option => !option),
    withOptionsFetched,
  ),

  // Create a map having the option ID as it's key and the rating as it's value.
  mapRatingsToRatingsByOptionId,
  connect(
    state => ({
      recruiterName: getRecruiterName(state),
    }),
    {},
  ),
)(Attributes);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Modal from '@thrivetrm/ui/components/Modal';
import Card from '@thrivetrm/ui/components/Card';
import Form from '@thrivetrm/ui/components/Form';
import routes from 'modules/routing/routes';
import { useApiGet, useApiPost } from '@thrivetrm/ui/hooks/useApi';
import { toastError } from 'modules/toast-notifications/toastNotificationsSlice';

const AddToPipelineModal = ({ contactIds, isOpen, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const [selectedPipeline, setSelectedPipeline] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setSelectedPipeline('');
    }
  }, [isOpen]);

  const [addToPipeline, isAddingToPipeline] = useApiPost(
    routes.bulk_api_v1_pipeline_people({
      pipeline_id: selectedPipeline.id,
    }),
  );

  const [
    getExistingCandidacies,
    _isLoadingExistingCandidacies,
    _existingCandidaciesLoadError,
    existingCandidacies,
  ] = useApiGet(
    routes.api_v1_search_existing_candidacies_count({
      search_id: selectedPipeline.id,
      query: {
        contact_ids: contactIds,
      },
    }),
  );

  useEffect(() => {
    if (selectedPipeline) {
      getExistingCandidacies();
    }
  }, [getExistingCandidacies, selectedPipeline]);

  const handleSubmit = ({ pipeline }) => {
    addToPipeline(
      { contact_id: contactIds },
      {
        onSuccess: () => onSuccess(pipeline),
        onError: () => {
          dispatch(
            toastError(
              `Selected records were unsuccessfully added to pipeline ${pipeline.name}`,
            ),
          );
        },
      },
    );
  };

  const hasExistingCandidacies = existingCandidacies?.count > 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='small'
      title='Add to Pipeline'
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Body className='ModalBody--noClip'>
          {hasExistingCandidacies && selectedPipeline && (
            <Card
              className='u-marginBottom-12'
              isCentered={false}
              type='warning'
            >
              One or more of the selected people are already in this pipeline.
              If applicable, click submit to add the other selected people.
            </Card>
          )}
          <Form.Autocomplete
            formatOption={option => option.name}
            label='Pipeline Name'
            name='pipeline'
            noResultsText='No results found'
            onClear={() => setSelectedPipeline('')}
            onSelect={pipeline => setSelectedPipeline(pipeline)}
            placeholder='Select pipeline...'
            rules={{
              required: {
                value: true,
                message: 'Selecting a pipeline is required',
              },
            }}
            url={query =>
              routes.api_v1_autocomplete_query({
                query: {
                  query: {
                    resource: 'pipeline',
                    term: query,
                  },
                },
              })
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
          <Form.SubmitButton isLoading={isAddingToPipeline} label='Submit' />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AddToPipelineModal.defaultProps = {
  isOpen: false,
};

AddToPipelineModal.propTypes = {
  contactIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddToPipelineModal;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import TextInput from '@thrivetrm/ui/components/TextInput';
import classnames from 'classnames';
import {
  registerFilterInput,
  selectFilterInputData,
  selectHasRegisteredFilterInput,
  debouncedRequestRecordList,
  setAndValidateSingleFilterValue,
} from '../../recordIndexSlice';

const TextFilter = ({ isNumeric, isPercent, label, name, onChange }) => {
  const dispatch = useDispatch();

  const inputValue = useSelector(
    state => state.recordIndex.savedView.filters?.[name] || '',
  );
  const { isPairedInput, leadsPair, placeholder } = useSelector(state =>
    selectFilterInputData(state, name),
  );

  const handleChange = value => {
    dispatch(
      setAndValidateSingleFilterValue({
        name: name,
        value: value,
      }),
    );

    if (onChange) onChange(value);
    return dispatch(debouncedRequestRecordList());
  };

  const hasRegisteredFilterInput = useSelector(state =>
    selectHasRegisteredFilterInput(state, name),
  );

  useEffect(() => {
    if (!hasRegisteredFilterInput) {
      dispatch(registerFilterInput({ name: name }));
    }
  }, [dispatch, hasRegisteredFilterInput, name]);

  const containerClass = classnames('u-marginTop-8', {
    'u-paddingRight-8': isPairedInput && leadsPair,
    'u-paddingLeft-8': isPairedInput && !leadsPair,
    'u-inlineBlock': isPairedInput,
  });

  return (
    <div
      className={containerClass}
      style={{
        width: isPairedInput ? '50%' : '100%',
      }}
    >
      <TextInput
        append={isPercent ? '%' : null}
        className='u-marginTop-8'
        format={isNumeric ? 'number' : null}
        inputWidth='full'
        label={label}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        size='small'
        value={inputValue}
      />
    </div>
  );
};

TextFilter.propTypes = {
  isNumeric: PropTypes.bool,
  isPercent: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

TextFilter.defaultProps = {
  isNumeric: false,
  isPercent: false,
  label: null,
  onChange: null,
};

export default TextFilter;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HelpText from '@thrivetrm/ui/components/HelpText';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Rating from '@thrivetrm/ui/components/Rating';
import { useGetTemplateQuery } from 'services/apiV1/assessment';
import {
  TYPE_CLIENT,
  TYPE_RECRUITER,
  TYPE_RESEARCHER,
  ASSESSMENT_TYPES,
} from 'modules/candidacy-assessments/constants';
import DateTime from 'modules/datetime/components/DateTime';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import getCandidacySearchId from 'modules/candidacies/selectors/getCandidacySearchId';
import getClientTypeName from 'modules/tenant/selectors/getClientTypeName';
import getRecruiterName from 'modules/tenant/selectors/getRecruiterName';
import useQuickView from 'modules/quick-view/useQuickView';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';

// to avoid vertical scroll
const MAX_RAW_RENDER_COUNT = 4;

const AssessmentView = ({ assessment, assessmentName }) => {
  const { navigateTo } = useQuickView();
  const hasAssessmentSummaryViewFeature = useFeatureCheck(
    'development.assessment_v2_summary_view',
  );
  const ratingStars = useSelector(state => state.tenant)?.get('rating_stars');

  const { data: template } = useGetTemplateQuery(
    assessment.assessment_template_id,
  );

  const shouldShowViewFullButton =
    (template?.categories.length || template?.criteria.length) &&
    hasAssessmentSummaryViewFeature;

  return (
    <div className='u-width-100' data-testid={assessment.type}>
      <p className='u-marginTop-8 u-marginBottom-n u-fontWeight-bold'>
        {assessmentName}{' '}
        {shouldShowViewFullButton ? (
          <ButtonLink
            className='CandidacyListItemSectionAssessments__viewFullButton'
            label='- view full'
            onClick={() =>
              navigateTo(
                `/contact/${assessment.contact_id}/candidate-assessment/${assessment.id}`,
                { title: assessment.full_name },
              )
            }
          />
        ) : null}
      </p>

      {template?.categories.length && hasAssessmentSummaryViewFeature ? (
        <>
          <p className='u-margin-n'>Category Averages:</p>
          {template.categories
            .slice(0, MAX_RAW_RENDER_COUNT)
            .map(({ id, name }) => {
              const average = assessment.candidacy_assessment_answers_attributes?.find(
                answer =>
                  answer.assessment_category_id === id &&
                  answer.criteria_average,
              )?.criteria_average;

              return (
                <div
                  className='u-flex u-paddingVertical-2 u-paddingRight-4'
                  key={id}
                >
                  <Rating
                    maxRating={ratingStars}
                    value={Math.round(average * 2) / 2}
                  />
                  <TooltipTrigger placement='top' tooltip={name}>
                    <p className='u-margin-n u-marginLeft-2 u-truncate'>
                      {name}
                    </p>
                  </TooltipTrigger>
                </div>
              );
            })}
        </>
      ) : null}

      {hasAssessmentSummaryViewFeature && template?.criteria.length
        ? template.criteria
            .slice(0, MAX_RAW_RENDER_COUNT)
            .map(({ id, name }) => {
              const ratingValue = assessment.candidacy_assessment_answers_attributes?.find(
                answer => answer.assessment_criterium_id === id,
              )?.rating_value;

              return (
                <div
                  className='u-flex u-paddingVertical-2 u-paddingRight-4'
                  key={id}
                >
                  <Rating maxRating={ratingStars} value={ratingValue} />
                  <TooltipTrigger placement='top' tooltip={name}>
                    <p className='u-margin-n u-marginLeft-2 u-truncate'>
                      {name}
                    </p>
                  </TooltipTrigger>
                </div>
              );
            })
        : null}

      {!shouldShowViewFullButton ? (
        <>
          <p className='u-margin-n'>
            Assessed by {assessment.assessed_by_name}
          </p>
          <TooltipTrigger
            placement='top'
            tooltip={
              <DateTime
                format='ddd MMM D, YYYY [at] h:mma'
                value={assessment.assessed_on}
              />
            }
          >
            <HelpText>{moment(assessment.assessed_on).fromNow()}</HelpText>
          </TooltipTrigger>
        </>
      ) : null}
    </div>
  );
};

const CandidacyListItemSectionAssessments = ({ candidacyId }) => {
  const history = useHistory();
  const { navigateTo } = useQuickView();
  const hasAssessmentSummaryViewFeature = useFeatureCheck(
    'development.assessment_v2_summary_view',
  );
  const searchId = useSelector(state =>
    getCandidacySearchId(state, candidacyId),
  );
  const clientTypeName = useSelector(state => getClientTypeName(state));
  const recruiterName = useSelector(state => getRecruiterName(state));

  const { candidacyAssessments } = useSelector(
    state => state.candidacyAssessments,
  );

  const recruiterAssessment = candidacyAssessments?.find(
    assessment =>
      assessment.type === TYPE_RECRUITER &&
      assessment.candidacy_id === candidacyId,
  );
  const researcherAssessment = candidacyAssessments?.find(
    assessment =>
      assessment.type === TYPE_RESEARCHER &&
      assessment.candidacy_id === candidacyId,
  );
  const clientAssessment = candidacyAssessments?.find(
    assessment =>
      assessment.type === TYPE_CLIENT &&
      assessment.candidacy_id === candidacyId,
  );

  const { data: template } = useGetTemplateQuery(
    recruiterAssessment?.assessment_template_id,
    {
      skip: !recruiterAssessment?.assessment_template_id,
    },
  );

  const hasAssessments = hasAssessmentSummaryViewFeature
    ? Boolean(recruiterAssessment)
    : Boolean(clientAssessment || recruiterAssessment || researcherAssessment);

  return hasAssessments ? (
    <div className='CandidacyListItemSectionAssessments'>
      <button
        className='CandidacyListItemSectionAssessments__view'
        data-testid='candidacy-assessments'
        onClick={() => {
          if (
            hasAssessmentSummaryViewFeature &&
            !(template.categories.length || template.criteria.length)
          ) {
            navigateTo(
              `/contact/${recruiterAssessment.contact_id}/candidate-assessment/${recruiterAssessment.id}`,
              { title: recruiterAssessment.full_name },
            );
          }
          history.push(
            `/searches/${searchId}/candidates/${candidacyId}/candidacy_assessments`,
          );
        }}
        type='button'
      >
        {researcherAssessment && !hasAssessmentSummaryViewFeature ? (
          <AssessmentView
            assessment={researcherAssessment}
            assessmentName='Researcher Assessment'
          />
        ) : null}
        {recruiterAssessment ? (
          <AssessmentView
            assessment={recruiterAssessment}
            assessmentName={`${recruiterName} Assessment`}
          />
        ) : null}
        {clientAssessment && !hasAssessmentSummaryViewFeature ? (
          <AssessmentView
            assessment={clientAssessment}
            assessmentName={`${clientTypeName} Assessment`}
          />
        ) : null}
      </button>
    </div>
  ) : null;
};

AssessmentView.propTypes = {
  assessment: PropTypes.shape({
    assessed_by_name: PropTypes.string,
    assessed_on: PropTypes.string,
    assessment_template_id: PropTypes.number.isRequired,
    candidacy_assessment_answers_attributes: PropTypes.arrayOf(
      PropTypes.shape({
        assessment_category_id: PropTypes.number,
        criteria_average: PropTypes.number,
      }),
    ),
    contact_id: PropTypes.number.isRequired,
    full_name: PropTypes.string,
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(ASSESSMENT_TYPES),
  }),
  assessmentName: PropTypes.string.isRequired,
};

CandidacyListItemSectionAssessments.propTypes = {
  candidacyId: PropTypes.number.isRequired,
};

export default CandidacyListItemSectionAssessments;

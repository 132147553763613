import PropTypes from 'prop-types';
import React, { Component } from 'react';
import selectn from 'selectn';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import { compose } from 'recompose';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import transactionsState from 'modules/transactions/propTypes/transactionsState';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

import { OWNER_TYPES } from '../constants';
import connectDocumentActions from './connectDocumentActions';

/**
 * This control allows for uploading documents. It supports uploading multiple docuemnts at a time.
 *
 * Each upload is stored on state (`state.uploads`), which is a map where each key is the underlying
 * upload's transaction ID, and the value is the filename. The status of each upload is displayed
 * in a list. If the underlying transaction for an upload is cleared, it will be automatically
 * removed from the list (see `componentWillReceiveProps` implementation). In addition,
 * whenever an upload is completed successuly, we automatically remove the upload from the list
 * by clearing it's transaction -- but we do this on a delay (specified by the `clearDelay`)
 * prop, so that we breifly display the success status.
 */
class DocumentUploader extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      /**
       * A map of transaction IDs to uploaded file names for all current uploads (this includes
       * any uploads whose status is currently being displayed -- whether completed or not)
       * @type {Object.<String, String>}
       */
      uploads: {},
    };

    // Whenever an upload successfully completed, we delay the removal of the list item showing
    // the upload status (using setTimeout). Those timeout IDs are stored here, so we can clear
    // them before the component unmounts.
    this.timeouts = [];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { transactions: nextTransactions } = nextProps;
    const { clearDelay, transactions } = this.props;
    const { uploads: currentUploads } = this.state;

    // An array of transactionIDs for uploads which have just completed.
    const completedUploads = [];

    // An array of transactionIDs for uploads wwhich no longer have an associated transaction state.
    const removedUploads = [];

    if (transactions !== nextTransactions) {
      // Only need to check if the underlying transactions state has changed.
      Object.keys(currentUploads).forEach(transactionId => {
        const nextTransaction = nextTransactions.get(transactionId);
        const transaction = transactions.get(transactionId);

        // Find any uploads whose transactions have been removed.
        if (transaction && !nextTransaction) {
          removedUploads.push(transactionId);
        }

        // Find any transactions whose `isFinished` value has just changed true, and doesn't
        // have an error - these are successfully completed uploads.
        if (
          nextTransaction &&
          nextTransaction.isFinished &&
          !nextTransaction.payload.error &&
          transaction &&
          !transaction.isFinished
        ) {
          completedUploads.push(transactionId);
        }
      });
    }

    if (removedUploads.length) {
      // Update the state, filtering out any uploads whose transactions have been cleared.
      this.setState(({ uploads }) => ({
        uploads: Object.keys(uploads)
          .filter(transactionId => !removedUploads.includes(transactionId))
          .reduce(
            (updatedUploads, transactionId) => ({
              ...updatedUploads,
              [transactionId]: uploads[transactionId],
            }),
            {},
          ),
      }));
    }

    if (completedUploads.length) {
      // Set a timer which will clear the transaction associated with these uploads (thus
      // causing them to get removed from our state).
      this.timeouts.push(
        setTimeout(this.handleClearSuccessfulUploads, clearDelay),
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { transactions: nextTransactions } = nextProps;
    const { fetchDocuments, transactions } = this.props;
    const { uploads: nextUploads } = nextState;
    const { uploads } = this.state;

    // If the uploads state itself has changed, we always need to update.
    if (uploads !== nextUploads) {
      fetchDocuments();
      return true;
    }

    // Otherwise, we only need to update if the transaction state for any of the underlying upload
    // transactions has changed.
    if (transactions !== nextTransactions) {
      return Object.keys(nextUploads).some(
        transactionId =>
          transactions.get(transactionId) !==
          nextTransactions.get(transactionId),
      );
    }

    return false;
  }

  componentWillUnmount() {
    const { transactionActions } = this.props;
    const { uploads } = this.state;

    // Any pending timeouts need to be cleared. Any invalid timeouts (those that have already
    // fired, for example) are ignored by clearTimeout.
    this.timeouts.forEach(timeoutId => clearTimeout(timeoutId));

    // Clear out any of the transactions we're currently monitoring.
    Object.keys(uploads).forEach(transactionId =>
      transactionActions.clearTransaction(transactionId),
    );
  }

  /**
   * Clears any successfully finished uploads that have completed longer ago than the `clearDelay`
   * value
   */
  handleClearSuccessfulUploads = () => {
    const { clearDelay, transactionActions, transactions } = this.props;
    const { uploads } = this.state;
    const threshold = Date.now() - clearDelay;

    // Find any uploads that have successfully finished longer ago than the `clearDelay` value.
    const uploadsToClear = Object.keys(uploads).filter(transactionId => {
      const transaction = transactions.get(transactionId);
      return (
        transaction &&
        transaction.isFinished &&
        !transaction.payload.error &&
        transaction.finishTime <= threshold
      );
    });

    if (uploadsToClear.length) {
      // Clearing their transactions will cause them to be removed on the next
      // `componentWillReceiveProps` call.
      uploadsToClear.forEach(transactionId =>
        transactionActions.clearTransaction(transactionId),
      );
    }
  };

  handleClearUploadClick = e => {
    const { value } = e.currentTarget;
    const { transactionActions } = this.props;
    transactionActions.clearTransaction(value);
  };

  submitUpload = file => {
    const {
      bulkImportResourceType,
      bulkImportUploadCsvFile,
      documentActions,
      documentLabelId,
      ownerId,
      ownerType,
    } = this.props;

    const transactionId = uniqueId();

    // TODO: we're overloading the DocumentUploader to handle bulk import CSV files. This should
    // be factored out into a dedicated BulkImportUploader or similar in the future.
    if (!ownerId) {
      bulkImportUploadCsvFile({
        file: file,
        resourceType: bulkImportResourceType,
        transactionId: transactionId,
      });

      return transactionId;
    }

    documentActions.createDocument({
      documentLabelId: documentLabelId,
      ownerId: ownerId,
      ownerType: ownerType,
      file: file,
      transactionId: transactionId,
    });

    return transactionId;
  };

  handleDrop = files => {
    const newUploads = files.reduce(
      (map, file) => ({
        ...map,
        [this.submitUpload(file)]: file.name,
      }),
      {},
    );

    this.setState(({ uploads }) => ({
      uploads: {
        ...newUploads,
        ...uploads,
      },
    }));
  };

  render() {
    const { uploads } = this.state;
    const { transactions } = this.props;

    return (
      <div className='documents--document-uploader'>
        <Dropzone
          activeClassName='documents--document-uploader-dropzone-active'
          className='documents--document-uploader-dropzone'
          name='document'
          onDrop={this.handleDrop}
        >
          <div className='documents--document-uploader-placeholder text-center'>
            <span className='btn btn-link'>Choose a file</span>
            <span> or drag / drop document here.</span>
          </div>
        </Dropzone>

        <ul className='list-group documents--document-uploader-uploads'>
          {Object.keys(uploads).map(transactionId => {
            const transaction = transactions.get(transactionId);
            const filename = uploads[transactionId];
            const isFinished = selectn('isFinished', transaction);
            const error = selectn('payload.error', transaction);
            /**
             * (error.message || error.toString()) evaluates to something like:
             * 'file You are not allowed to upload "xlsx" files...'
             * The below uses regex to remove the 'file ' prefix
             */
            const errorMessage =
              error && (error.message || error.toString()).match(/^file\s(.+)/);

            return (
              <li
                className={classnames('list-group-item', {
                  'list-group-item-success': isFinished && !error,
                  'list-group-item-danger': isFinished && error,
                })}
                key={transactionId}
              >
                <div
                  className='documents--document-uploader-upload-progress'
                  key='progress'
                >
                  <div className='progress'>
                    <div
                      className={classnames('progress-bar', {
                        active: !isFinished,
                        'progress-bar-striped': !isFinished,
                        'progress-bar-info': !isFinished,
                        'progress-bar-success': isFinished && !error,
                        'progress-bar-danger': isFinished && error,
                      })}
                      role='progressbar'
                      style={{ width: '100%' }}
                    >
                      <span>
                        {!isFinished && 'Uploading...'}
                        {isFinished && error && 'Upload failed'}
                        {isFinished && !error && 'Upload complete'}
                      </span>
                    </div>
                  </div>
                  <div className='filename'>
                    {filename}
                    <button
                      className='close'
                      onClick={this.handleClearUploadClick}
                      type='button'
                      value={transactionId}
                    >
                      &times;
                    </button>
                  </div>
                </div>
                {errorMessage && (
                  <div
                    className='documents--document-uploader-upload-error'
                    key='error'
                  >
                    {
                      /**
                       * the result of `.match` will be an array which includes the full matched
                       * string, but we only want to render the capture group,
                       * which will be the last element of the array
                       */
                      errorMessage[errorMessage.length - 1]
                    }
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

DocumentUploader.propTypes = {
  bulkImportResourceType: PropTypes.string,
  bulkImportUploadCsvFile: PropTypes.func,

  clearDelay: PropTypes.number,

  documentActions: PropTypes.shape({
    createDocument: PropTypes.func.isRequired,
  }).isRequired,

  documentLabelId: PropTypes.number,
  fetchDocuments: PropTypes.func.isRequired,

  ownerId: PropTypes.number,

  ownerType: PropTypes.oneOf(OWNER_TYPES),

  transactionActions: PropTypes.shape({
    clearTransaction: PropTypes.func.isRequired,
  }).isRequired,

  transactions: transactionsState.isRequired,
};

DocumentUploader.defaultProps = {
  clearDelay: 3000,
};

export default compose(
  connectTransactions,
  connectDocumentActions,
)(DocumentUploader);

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';

import {
  SORT_BY_TYPES,
  SORT_BY_DEFAULT,
  SORT_ASCENDING_DEFAULT,
  SORT_BY_NAME,
  SORT_BY_SCORE,
  SORT_BY_RECOMMENDED_DATE,
} from '../constants';

/**
 * A higher order component that provides sorting of a list of recommendations.
 */
export default compose(
  setDisplayName('withRecommendationsSorted'),
  setPropTypes({
    sortActiveLast: PropTypes.bool.isRequired,
    isSortAscending: PropTypes.bool.isRequired,
    sortBy: PropTypes.oneOf(SORT_BY_TYPES).isRequired,
    results: PropTypes.any,
  }),
  defaultProps({
    sortActiveLast: true,
    isSortAscending: SORT_ASCENDING_DEFAULT,
    sortBy: SORT_BY_DEFAULT,
  }),
  connect((state, { results = [], sortBy }) => {
    if (Object.keys(results).length === 0) {
      return state;
    }

    const sortedResults = {};

    if (sortBy === SORT_BY_NAME) {
      sortedResults.notAFitCandidates = results.notAFitCandidates.sort((a, b) =>
        a.contact.full_name > b.contact.full_name ? 1 : -1,
      );
      sortedResults.recommendedCandidates = results.recommendedCandidates.sort(
        (a, b) => (a.contact.full_name > b.contact.full_name ? 1 : -1),
      );
    } else if (sortBy === SORT_BY_SCORE) {
      sortedResults.recommendedCandidates = results.recommendedCandidates.sort(
        (a, b) => (a.score > b.score ? 1 : -1),
      );
      sortedResults.notAFitCandidates = results.notAFitCandidates.sort((a, b) =>
        a.score > b.score ? 1 : -1,
      );
    } else if (sortBy === SORT_BY_RECOMMENDED_DATE) {
      sortedResults.recommendedCandidates = results.recommendedCandidates.sort(
        (a, b) => (a.created_at > b.created_at ? 1 : -1),
      );
      sortedResults.notAFitCandidates = results.notAFitCandidates.sort((a, b) =>
        a.created_at > b.created_at ? 1 : -1,
      );
    }

    return { results: sortedResults };
  }),
  // Reverse the sort if needed based on the `isSortAscending` prop value.
  withProps(({ isSortAscending, results }) => {
    const sortedReverse = {};
    if (results) {
      if (
        results.recommendedCandidates &&
        results.recommendedCandidates.length > 0
      ) {
        sortedReverse.recommendedCandidates = [
          ...results.recommendedCandidates,
        ];
        sortedReverse.recommendedCandidates.reverse();
      }

      if (results.notAFitCandidates && results.notAFitCandidates.length > 0) {
        sortedReverse.notAFitCandidates = [...results.notAFitCandidates];
        sortedReverse.notAFitCandidates.reverse();
      }
    }

    return {
      results: results && !isSortAscending ? sortedReverse : results,
    };
  }),
);

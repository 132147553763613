import React from 'react';
import Card from '@thrivetrm/ui/components/Card';
import Avatar from '@thrivetrm/ui/components/Avatar';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import PropTypes from 'prop-types';
import {
  RecordTypesWithConnections,
  useGetConnectionStrengthsQuery,
} from 'services/apiV1/connections';
import { useDismissSuggestedConnectionMutation } from 'services/apiV1/suggestedConnection';
import useQuickView from '../useQuickView';

const professionalConnection = 'Professional Connection';

const SuggestedConnectionCard = ({
  refetchSuggestedConnections,
  suggestedConnection,
}) => {
  const {
    avatarUrl,
    contactId,
    ownerId,
    suggestedMessage,
    userEmail,
    userName,
  } = suggestedConnection;
  const { data: connectionStrengths } = useGetConnectionStrengthsQuery();
  const { navigateTo } = useQuickView();
  const selectedTab = 'SUGGESTED CONNECTIONS';
  const mediumStrengthId = connectionStrengths?.find(
    strength => strength.name === 'Medium',
  )?.id;

  const [
    dismissSuggestedConnection,
    { isLoading: isDismissSuggestedConnection },
  ] = useDismissSuggestedConnectionMutation();

  const handleDismissSuggestedConnection = () => {
    dismissSuggestedConnection({ contactId: contactId, userId: ownerId }).then(
      () => {
        refetchSuggestedConnections();
      },
    );
  };

  return (
    <Card className='u-marginBottom-16' isCentered={false} type='shadow'>
      <div className='u-flex'>
        <Avatar
          className='u-marginRight-12'
          image={avatarUrl}
          name={userName}
          size='small'
        />
        <div>
          <a href={`mailto:${userEmail}`}>{userName}</a>
          <div className='u-flex u-flexAlign-c'>
            <i className='fa fa-envelope-square u-paddingRight-4' />
            <div>{userEmail}</div>
          </div>
        </div>
      </div>
      <div className='u-fontWeight-bold u-paddingTop-16'>
        Suggested because:
      </div>
      <div className='u-paddingTop-4'>{suggestedMessage}</div>

      <div className='u-flex u-flexJustify-r u-paddingTop-16'>
        <ButtonSecondary
          className='u-marginRight-4'
          isDisabled={isDismissSuggestedConnection}
          label='Dismiss'
          onClick={handleDismissSuggestedConnection}
          size='small'
        />
        <ButtonPrimary
          isDisabled={isDismissSuggestedConnection}
          label='Create'
          onClick={() =>
            navigateTo(
              `/contact/${contactId}/connections/add-${
                RecordTypesWithConnections.USER
              }/?selectedTab=${selectedTab}&&strength=${mediumStrengthId}&&connectionType=${professionalConnection}&&suggestedUser=${JSON.stringify(
                { id: ownerId, name: userName },
              )}`,
            )
          }
          size='small'
        />
      </div>
    </Card>
  );
};

SuggestedConnectionCard.propTypes = {
  refetchSuggestedConnections: PropTypes.func,
  suggestedConnection: PropTypes.shape({
    avatarUrl: PropTypes.string,
    contactId: PropTypes.number,
    ownerId: PropTypes.number,
    suggestedMessage: PropTypes.string,
    userEmail: PropTypes.string,
    userName: PropTypes.string,
  }),
};

export default SuggestedConnectionCard;

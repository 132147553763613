import PropTypes from 'prop-types';
import React from 'react';
import Popover from 'react-bootstrap/lib/Popover';

/**
 * Renders a Popover component displaying a list of job application actions
 * ("add to search", "reject")
 */
const JobApplicationActionListPopover = ({
  // className is needed because the Overlay passes in some classes
  // as well. So don't remove this -- it seems like it's not being used, but it is!
  className,
  isJobApplicationRejected,
  onAddToSearch,
  onReject,

  ...popoverProps
}) => (
  <Popover
    className={`JobApplicationActionListPopover ${className}`}
    title='Move Stage'
    {...popoverProps}
  >
    <ul className='JobApplicationActionListPopover__menu'>
      <li className='JobApplicationActionListPopover__menuItem' key='add'>
        <button onClick={onAddToSearch} type='button'>
          Add to Search
        </button>
      </li>
      <li className='JobApplicationActionListPopover__menuItem' key='reject'>
        <button onClick={onReject} type='button'>
          {isJobApplicationRejected ? (
            <div>
              {'Rejected '}
              <i className='fa fa-pencil' />
            </div>
          ) : (
            'Reject'
          )}
        </button>
      </li>
    </ul>
  </Popover>
);

JobApplicationActionListPopover.propTypes = {
  className: PropTypes.string,

  /**
   * True of the application is in a rejected state
   */
  isJobApplicationRejected: PropTypes.bool.isRequired,

  /**
   * Called when "add to search" is selected
   */
  onAddToSearch: PropTypes.func.isRequired,

  /**
   * Called when "Reject" (or "Rejected") is selected
   */
  onReject: PropTypes.func.isRequired,
};

export default JobApplicationActionListPopover;

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import TargetCompanyComment from './TargetCompanyComment';
import withSortedTargetCompanyCommentIds from './withSortedTargetCompanyCommentIds';

const TargetCompanyCommentList = ({ targetCompanyCommentIds }) => {
  if (targetCompanyCommentIds.size === 0) {
    return <span>There are no comments for this target company</span>;
  }

  return (
    <ul className='list-unstyled target-companies--target-company-comment-list'>
      {targetCompanyCommentIds
        .map(targetCompanyCommentId => (
          <li key={targetCompanyCommentId}>
            <TargetCompanyComment
              targetCompanyCommentId={targetCompanyCommentId}
            />
          </li>
        ))
        .toArray()}
    </ul>
  );
};

TargetCompanyCommentList.propTypes = {
  targetCompanyCommentIds: ImmutablePropTypes.listOf(PropTypes.number)
    .isRequired,
};

export default withSortedTargetCompanyCommentIds(TargetCompanyCommentList);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import { connect, useSelector } from 'react-redux';
import getSearchType from 'modules/searches/selectors/getSearchType';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import { useGetCandidateTagsQuery } from 'services/apiV1/candidateTags';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import TagCheckbox from './TagCheckbox';
import withTagIdsFilteredByText from './withTagIdsFilteredByText';
import withTagIdsFromSearchCandidacies from './withTagIdsFromSearchCandidacies';
/**
 * Renders a list of `TagCheckbox` components
 */
const TagCheckboxList = ({
  className,
  disabledTagIds,
  filterPlaceholder,
  isFetching,
  onChange,
  onTagFilterTextChange,
  searchId,
  selectedTagIds,
  setSelectedTagIds,
  showPlaceholder,
  tagFilterText,
  tagIds,
  ...props
}) => {
  const { data: candidateTags } = useGetCandidateTagsQuery(searchId);
  const searchType = useSelector(state => getSearchType(state, searchId));

  useEffect(() => {
    const filteredSelectedTagIds = selectedTagIds.filter(tagId =>
      searchType === TYPE_PIPELINE
        ? tagIds.includes(tagId)
        : candidateTags?.tags.find(({ id }) => id === tagId),
    );
    if (filteredSelectedTagIds.length !== selectedTagIds.length) {
      setSelectedTagIds(filteredSelectedTagIds);
    }
  }, [selectedTagIds, setSelectedTagIds, tagIds]);

  return (
    <div className='TagCheckboxList'>
      <input
        className='TagCheckboxList__filter'
        onChange={onTagFilterTextChange}
        placeholder={filterPlaceholder}
        type='text'
        value={tagFilterText}
      />
      <ul {...props} className={classnames('TagCheckboxList__list', className)}>
        {searchType === TYPE_PIPELINE
          ? tagIds
              ?.map(tagId => {
                const isDisabledOrLoading = disabledTagIds.includes(tagId);
                return (
                  <li className='TagCheckboxList__item' key={tagId}>
                    <TagCheckbox
                      checked={selectedTagIds.includes(tagId)}
                      disabled={isDisabledOrLoading}
                      loading={isDisabledOrLoading}
                      onChange={onChange}
                      searchType={searchType}
                      tagId={tagId}
                      value={tagId}
                    />
                  </li>
                );
              })
              .toArray()
          : candidateTags?.tags
              .filter(({ name }) =>
                tagFilterText
                  ? name
                      .toLowerCase()
                      .includes(tagFilterText.trim().toLowerCase())
                  : true,
              )
              .map(({ color, id, name }) => (
                <li className='TagCheckboxList__item' key={id}>
                  <TagCheckbox
                    checked={selectedTagIds.includes(id)}
                    color={color}
                    name={name}
                    onChange={onChange}
                    searchType={searchType}
                    tagId={id}
                    value={id}
                  />
                </li>
              ))}
      </ul>
      {isFetching ? <LoadingIndicator /> : null}
      {showPlaceholder ? (
        <p className='TagCheckboxList__placeholder'>No tagged candidates.</p>
      ) : null}
    </div>
  );
};

TagCheckboxList.propTypes = {
  /**
   * The tag IDs of the tags that are in a disabled state.
   */
  disabledTagIds: PropTypes.arrayOf(PropTypes.number.isRequired),

  /**
   * placeholder for the tag filter
   */
  filterPlaceholder: PropTypes.string,

  /**
   * True if currently fetching the tag list; otherwise, false.
   */
  isFetching: PropTypes.bool,

  /**
   * Called when a checkbox's checked value is changed.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Called when the `tagFilterText` value is changed.
   */
  onTagFilterTextChange: PropTypes.func.isRequired,

  /**
   * The tag IDs of the tags that are currently assigned.
   */
  selectedTagIds: PropTypes.arrayOf(PropTypes.number.isRequired),

  /**
   * True if there are tagged candidacies in the search (withTagIdsFromSearchCandidacies)
   * and the filter field is empty. Checking that the filter field is empty prevents the
   * placeholder from showing when no results are returned while filtering on text.
   */
  showPlaceholder: PropTypes.bool,

  /**
   * The text that is currently filtering the tag list.
   */
  tagFilterText: PropTypes.string.isRequired,

  /**
   * The list of tag IDs to render
   */
  tagIds: ImmutablePropTypes.listOf(PropTypes.number.isRequired),
};

TagCheckboxList.defaultProps = {
  disabledTagIds: [],
  filterPlaceholder: 'Filter by Tag Label or Creator',
  selectedTagIds: [],
};

export default compose(
  connect(
    (state, { searchId }) => ({
      searchType: getSearchType(state, searchId),
    }),
    {},
  ),
  setDisplayName('TagCheckboxList(enhanced)'),
  setPropTypes({
    disabledTagIds: TagCheckboxList.propTypes.selectedTagIds,
    onCheckboxChange: PropTypes.func.isRequired,
    selectedTagIds: TagCheckboxList.propTypes.selectedTagIds,
  }),
  withTagIdsFromSearchCandidacies,
  withStateHandlers(
    { tagFilterText: '' },
    {
      onTagFilterTextChange: () => e => ({ tagFilterText: e.target.value }),
      onChange: (_, { onCheckboxChange }) => e => {
        onCheckboxChange({
          e: e,
          tagId: parseInt(e.target.value),
          checked: e.target.checked,
        });
      },
    },
  ),
  mapProps(
    ({ onCheckboxChange: _, tagFilterText, tagIds, tagList, ...props }) => ({
      ...props,
      isFetching: Boolean(tagList && tagList.getIn(['_meta', 'isFetching'])),
      showPlaceholder: !(tagIds && tagIds.size) && !tagFilterText,
      tagFilterText: tagFilterText,
      tagIds: tagIds,
    }),
  ),
  withTagIdsFilteredByText(),
  withPropsRemoved('searchType'),
)(TagCheckboxList);

import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import Notification from './Notification';

/**
 * Renders a notification with a resource type of `comment`
 */
const NotificationItemTypeCandidacy = ({ notification, ...props }) => {
  const candidacy = fromJS(notification.getIn(['data', 'resource']));

  return (
    <Notification {...props} notification={notification}>
      <ExpandableText
        characterLimit={150}
        content={candidacy.getIn(['data', 'formatted_comment'])}
        isSanitizedHtml={true}
      />
    </Notification>
  );
};

NotificationItemTypeCandidacy.propTypes = {
  /**
   * The notification object
   */
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          formatted_comment: PropTypes.string,
          truncated_comment: PropTypes.string,
        }),
      }),
      type: PropTypes.oneOf(['candidacy']),
    }),
  }).isRequired,
};

export default NotificationItemTypeCandidacy;

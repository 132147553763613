import getCompanyName from 'modules/companies/selectors/getCompanyName';
import getSearchTitle from './getSearchTitle';
import getSearchProperty from './getSearchProperty';

const getSearchName = (state, id, ignoreConfidentialFlag) => {
  const searchTitle = getSearchTitle(state, id, ignoreConfidentialFlag);
  const companyId = getSearchProperty(state, id, 'client_company');
  const companyName = companyId && getCompanyName(state, companyId);

  if (!searchTitle) {
    return null;
  }

  return companyName ? `${companyName} - ${searchTitle}` : searchTitle;
};

export default getSearchName;

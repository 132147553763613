import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import IndustriesTagSelectField from 'modules/tenant/components/IndustriesTagSelectField';
import connectCompanyActions from '../connectCompanyActions';
import mapCompanyIdToProperty from '../mapCompanyIdToProperty';

/**
 * Renders a form for tagging a company with industries.
 * This form auto-submits whenever the underlying fieldState is changed, so there is no
 * "submit" button here, and it doesn't have/need a formState as is typical of a "standard"
 * form.
 */
export default compose(
  setDisplayName('CompanyIndustriesForm'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToProperty('industries'),
  connectCompanyActions,
  withStateHandlers(
    ({ industries }) => ({
      fieldState: IndustriesTagSelectField.createFieldState(
        'industries',
        industries,
      ),
    }),
    {
      onChange: (_, { companyActions, companyId }) => fieldState => {
        // Whenever the fieldState is changed, we update the company with the new IDs immediately.
        companyActions.updateCompany({
          company: {
            id: companyId,
            industry_ids: fieldState.getValue(),
          },
        });

        return { fieldState: fieldState };
      },
    },
  ),
)(IndustriesTagSelectField);

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

/**
 * A button used to open a report modal. Displays the supplied text (`children`) along with
 * an icon.
 */
const GenerateReportButton = ({ children, className, ...props }) => (
  <button
    className={classnames('GenerateReportButton', className)}
    type='button'
    {...props}
  >
    {children} <i className='GenerateReportButton__icon fa fa-file-pdf-o' />
  </button>
);

GenerateReportButton.propTypes = {
  /**
   * The text or title of the button.
   */
  children: PropTypes.node,

  /**
   * Optional class name to apply to the button.
   */
  className: PropTypes.string,
};

GenerateReportButton.defaultProps = {
  className: 'btn btn-link',
};

export default GenerateReportButton;

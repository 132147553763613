import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import fetchCandidacyStatusAction from '../../actions/fetchCandidacyStatus';
import shouldFetchCandidacyStatusSelector from '../../selectors/shouldFetchCandidacyStatus';
import getCandidacyStatusSelector from '../../selectors/getCandidacyStatus';

/**
 * Fetches a candidacy's status, given a candidacyId, if it needs to be fetched from the server.
 * */
export default ({
  candidacyIdProp = 'candidacyId',
  candidacyStatusProp = 'candidacyStatus',
} = {}) =>
  compose(
    setDisplayName('withCandidacyStatusFetched'),
    setPropTypes({
      /**
       * The ID of the candidacy record to fetch
       */
      [candidacyIdProp]: PropTypes.number.isRequired,
    }),
    mapProps(incomingProps => ({
      incomingProps: incomingProps,
      candidacyId: incomingProps[candidacyIdProp],
    })),
    connect(
      (state, { candidacyId }) => ({
        candidacyStatus: getCandidacyStatusSelector(state, candidacyId),
        shouldFetchCandidacyStatus: shouldFetchCandidacyStatusSelector(
          state,
          candidacyId,
        ),
      }),
      {
        fetchCandidacyStatus: fetchCandidacyStatusAction,
      },
    ),
    withHandlers({
      fetchCandidacyStatusIfNeeded: ({
        candidacyId,
        fetchCandidacyStatus,
        shouldFetchCandidacyStatus,
      }) => () => {
        if (shouldFetchCandidacyStatus) {
          fetchCandidacyStatus({ id: candidacyId });
        }
      },
    }),
    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchCandidacyStatusIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchCandidacyStatusIfNeeded();
      },
    }),
    mapProps(({ candidacyStatus, incomingProps }) => ({
      ...incomingProps,
      [candidacyStatusProp]: candidacyStatus,
    })),
  );
